import { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import { carregarRemocoesMotoristasCarga } from '../../../../services/cargaService'
import Typography from '@mui/material/Typography';
import Excel from '../Excel';



export default function TableRemoesMotoristaCarga(props) {
    const { carregamentoId } = props.props;
    const [listaRemocoesMotoristas, setListaRemocoesMotoristas] = useState([]);
    const [pagina, setPage] = useState(1);
    const [search, setSearch] = useState('');

    useEffect(() => {
        atualizar();
    }, [props]);

    function atualizar() {
        setPage(1);
        setSearch('');
        carregaEndPointsRemocoesMotorista(1, search)
    }

    function buscarRemocoesMotoristas(page, search = '') {
        setPage(page++);
        carregaEndPointsRemocoesMotorista(page, search);
    }

    function carregaEndPointsRemocoesMotorista(page, search) {
        let promisseCargas = carregarRemocoesMotoristasCarga(carregamentoId, page, search)
            .then(response => {
                let body = response.data;
                setListaRemocoesMotoristas(body);
            })
            .catch(erros => {
                console.error(erros)
                toast.error('Erro ao conectar remoções da carga selecionada .');
            });

        toast.promise(promisseCargas, { loading: 'Carregando remoções da carga..' })
    }

    function exibirMapa(remocaoReboquista) {
        if (remocaoReboquista.status === "Aguardando andamento") {
            toast.error("Veiculo não foi iniciado andamento ☹️");
            return
        }
        props.carregaMaparRemocao(remocaoReboquista);
        return;
    }

    return (
        <div>
            <Typography sx={{ margin: 2 }} variant="h5" component="h2">
                Remoções {listaRemocoesMotoristas.length ? ' da carga ' + listaRemocoesMotoristas[0].loadName : ''}
            </Typography>

            <Excel loadId={carregamentoId} />

            <Box
                sx={{
                    position: 'relative',
                    top: '10px',
                    width: '50%',
                    margin: 2,
                    display: {
                        xs: 'none',
                        md: 'flex',
                        justifyContent: 'space-between'
                    },
                }}
            >
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow
                            hover>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="right">Motorista</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Criado em</TableCell>
                                <TableCell align="right">Atualizado em</TableCell>
                                <TableCell align="right">Obs</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                listaRemocoesMotoristas.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => exibirMapa(row)}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row?.name}
                                        </TableCell>
                                        <TableCell align="right">

                                            <Tooltip title={row.driver}>
                                                <div>
                                                    {
                                                        row?.driver?.split(' ')[0] ?? row.driver
                                                    }
                                                </div>
                                            </Tooltip>

                                        </TableCell>
                                        <TableCell align="right">{row?.status}</TableCell>
                                        <TableCell align="right">{row?.createdAt}</TableCell>
                                        <TableCell align="right">{row?.updatedAt}</TableCell>
                                        <TableCell align="right">
                                            {
                                                row.obs ? <Tooltip title={row.obs}>
                                                    <div>Possui obs</div>
                                                </Tooltip>
                                                    : <div>N/D</div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                    <Box sx={{ display: 'flex' }}>
                        <Button sx={{ margin: 1 }} variant="contained" onClick={() => { atualizar() }} >Atualizar {pagina}</Button>
                        <Button sx={{ margin: 1 }} variant="contained" onClick={() => { buscarRemocoesMotoristas(pagina - 1) }}> Voltar</Button>
                        <Button sx={{ margin: 1 }} variant="contained" onClick={() => { buscarRemocoesMotoristas(pagina + 1) }}> Próximo</Button>
                    </Box>
                </TableContainer>

            </Box>
        </div>
    );
}