import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import {urlApi} from "../../plugins/urlApi";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Apresentacao(isCloseBanner) {
    const [open, setOpen] = React.useState(false);



    useEffect(() => {
        if (isCloseBanner) {
            localStorage.setItem('banner', true);
            setOpen(true);
        }
    }, [])


    const handleClose = () => {
        localStorage.setItem('banner', false);
        setOpen(false);
    };



    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Entendi
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    }}
                >
                    <CssBaseline />
                    <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
                    <img src={`${urlApi}images/logo.png`} alt="logo" height={550} width={550} />
                    </Container>
                </Box>
            </Dialog>
        </div>
    );
}