import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CarRentalIcon from '@mui/icons-material/CarRental';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { carregarRemocoesPorSituacoes } from "../../services/mapaService";

function ResponsiveAppBar(props) {
    let leftBar = props.abertoForm ? '31%' : '1%';
    const [contadorTotalRemocao, setContadorTotalRemocao] = useState(0);
    const [contadorTotalAgendado, setContadorTotalAgendado] = useState(0);
    const [contadorTotalVencido, setContadorTotalVencido] = useState(0);
    const [contadorTotalAguardando, setContadorTotalAguardando] = useState(0);
    const [contadorMotoristaVipAtivos, setContadorMotoristaVipAtivos] = useState(0);

    useEffect(() => {
        let { remocao,
            motoristasVipPositron
        } = props;

        let { remocoesAgendado, remocoesAguardando, remocoesVencido } = carregarRemocoesPorSituacoes(remocao);

        let totalRemocao = remocao?.length ?? 0
        let totalRemocaoAgendado = remocoesAgendado?.length ?? 0
        let totalRemocaoVencido = remocoesAguardando?.length ?? 0
        let totalRemocaoAguardando = remocoesVencido?.length ?? 0
        let totalMotoristasAtivos = motoristasVipPositron?.filter(m => m.igniciao === true).length ?? 0;

        setContadorTotalAgendado(totalRemocaoAgendado);
        setContadorTotalVencido(totalRemocaoVencido);
        setContadorTotalAguardando(totalRemocaoAguardando);

        setContadorTotalRemocao(totalRemocao);
        setContadorMotoristaVipAtivos(totalMotoristasAtivos);
    });

    function carrecarRemocoesOriginais() {
        props.listarRemocoesOriginais();
    }




    return (

        <Box sx={{
            position: 'absolute',
            zIndex: 9999,
            top: '10px',
            left: leftBar,
            display: {
                xs: 'none',
                md: 'flex',
                justifyContent: 'space-between'
            },
        }}>
            <Box
                sx={{
                    float: 'left',
                    margin: '2px',
                    padding: 0,
                    backgroundColor: 'white',
                    marginRight: '10px',
                    borderRadius: 30
                }}
                title={contadorTotalRemocao}>
                <Tooltip title="Carregar remoções">
                    <Button  onClick={(e) => carrecarRemocoesOriginais()}>
                        <Badge badgeContent={contadorTotalRemocao} max={1000} color="primary">
                            <CarRentalIcon color="inherit" />
                            <Chip color='primary' label="Total remoções" />
                        </Badge>
                    </Button>
                </Tooltip>
            </Box>

            <Box
                sx={{
                    float: 'left',
                    margin: '2px',
                    padding: 1,
                    backgroundColor: 'white',
                    marginRight: '10px',
                    borderRadius: 40
                }}

                title={contadorTotalAgendado}>
                <Badge badgeContent={contadorTotalAgendado} max={1000} color="primary" >
                    <CarRentalIcon
                        style={{ color: '#24ab63' }}
                        color="inherit" />
                    <Chip color='primary' label="Agendado" />
                </Badge>
            </Box>

            <Box
                sx={{
                    float: 'left',
                    margin: '2px',
                    padding: 1,
                    backgroundColor: 'white',
                    marginRight: '10px',
                    borderRadius: 40

                }}
                title={contadorTotalVencido}>
                <Badge badgeContent={contadorTotalVencido} max={1000} color="primary">
                    <CarRentalIcon
                        style={{ color: '#90423b' }}
                        color="inherit" />
                    <Chip color='primary' label="Vencido" />
                </Badge>
            </Box>

            <Box
                sx={{
                    float: 'left',
                    margin: '2px',
                    padding: 1,
                    backgroundColor: 'white',
                    borderRadius: 40,
                    marginRight: '10px'

                }}
                title={contadorTotalAguardando}>
                <Badge badgeContent={contadorTotalAguardando} max={1000} color="primary">
                    <CarRentalIcon
                        style={{ color: '#2c84bc' }}
                        color="Vencido" />
                    <Chip color='primary' label="Aguardando" />
                </Badge>
            </Box>

            <Box
                sx={{
                    float: 'left',
                    margin: '2px',
                    padding: 1,
                    backgroundColor: 'white',
                    borderRadius: 40
                }}
                title={'Com ignição ligada: ' + contadorMotoristaVipAtivos}>
                <Badge badgeContent={contadorMotoristaVipAtivos} max={1000} color="primary">
                    <NoCrashIcon color="inherit" />
                    <Chip color='primary' label="Motorista ativos" />
                </Badge>
            </Box>



        </Box>





    );
}
export default ResponsiveAppBar;