import { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { gerarExcelCargas, gerarExcelRemocoesCarga } from '../../../../services/cargaService'
import dayjs from "dayjs";
import toast from 'react-hot-toast';
import { ptBR } from '@mui/x-date-pickers/locales';


export default function Excel({ loadId }) {

    const possuiCarregamento = loadId ?? false
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    function gerar() {

        let de = dayjs(dateFrom).format('YYYY-MM-DD');

        let ate = dayjs(dateTo).format('YYYY-MM-DD');

        let service = possuiCarregamento ? gerarExcelRemocoesCarga(loadId, de, ate) : gerarExcelCargas(de, ate);
        let promisse = service
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${possuiCarregamento ? 'remocoes' : 'cargas'}-${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(e => {
                console.error(e);
                toast.error('Não foi possivel gerar excel')
            }).finally(() => {
                setDateFrom(null);
                setDateTo(null);
            });

        toast.promise(promisse, { loading: 'Gerando excel..' })
    }


    return (
        <Box
            sx={{
                margin: 2,
                marginBottom: 5
            }}
        >

            <Box sx={{
                display: 'flex',
                gap: 10
            }}>
                <LocalizationProvider
                    adapterLocale={'pt-br'}
                    dateAdapter={AdapterDayjs}>
                    <DatePicker
                        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                        label="De"
                        name="dateFrom"
                        value={dateFrom}
                        inputFormat="dd-MM-yyyy"
                        onChange={setDateFrom}
                    />
                    <DatePicker
                        label="Até"
                        name="dateTo"
                        value={dateTo}
                        inputFormat="dd-MM-yyyy"
                        onChange={setDateTo}
                    />
                </LocalizationProvider>

                {
                    dateFrom && dateTo &&
                    <Button color='primary' variant="contained" onClick={() => gerar()} >Gerar excel</Button>
                }

            </Box>
        </Box>
    )

}