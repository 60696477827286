import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
    carregarRoterizacoes,
    atualizarStatusRoterizacao,
    excluirRoterizacao
} from '../../../../services/roterizacaoService';
import toast, { Toaster } from 'react-hot-toast';
import { Button, Card, Checkbox, Chip, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Label } from '@mui/icons-material';
import ModalAlterarVeiculoRoterizacao from '../ModalAlterarVeiculoRoterizacao';
import { http } from '../../../../plugins/http';
export default function TableRoterizacao() {
    const [listaRoterizacoes, setListaRoterizacoes] = useState([]);
    const [pagina, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [minhas, setMinhas] = useState(true);
    const [modalUpdateVeiculos, setModalUpdateVeiculos] = useState(false);
    const [modalUpdateVeiculosData, setModalUpdateVeiculosData] = useState(null);
    const [veiculosMapa, setVeiculosMapa] = useState([]);


    useEffect(() => {
        atualizar();
        carregarVeiculosRemocao();
    }, []);

    async function carregarVeiculosRemocao() {
        var body = await http.get("veiculos-remocao");

        let veiculosRemocao = body.data.estoqueRemocaoMapaGeral;

        setVeiculosMapa(veiculosRemocao);
    }

    function atualizar(somenteMinhas = true) {
        setPage(1);
        setSearch('');
        setMinhas(somenteMinhas);
        carregaEndPointsRoterizacao(somenteMinhas, 1, search)
    }


    function buscarRoterizacoes(page, search = '') {
        setPage(page++);
        carregaEndPointsRoterizacao(minhas, page, search);
    }

    function carregaEndPointsRoterizacao(minhas = true, page = 1, search = '') {
        let promisseRoterizacoes = carregarRoterizacoes(minhas, page, 15, search)
            .then(response => {
                let body = response.data;
                setListaRoterizacoes(body);
            })
            .catch(erros => {
                console.error(erros)
                toast.error('Erro ao conectar roterização .');
            });

        toast.promise(promisseRoterizacoes, { loading: 'Carregando roterizações..' })
    }

    function atualizarStatusRoterizacaoRascunho(id) {
        let promisseAtualizacaoStatus = atualizarStatusRoterizacao(id)
            .then(() => {
                toast.success("atualizado com sucesso");
                atualizar();
            }).catch((e) => {
                toast.error("erro ao atualizar status")
            })

        toast.promise(promisseAtualizacaoStatus, { loading: 'atualizando roterização..' })
    }

    function abrirMudancaVeiculo(payload) {
        setModalUpdateVeiculos(true);
        setModalUpdateVeiculosData(payload);
    }

    function fecharMudancaVeiculo(sucesso) {
        setModalUpdateVeiculos(false);
        setModalUpdateVeiculosData(null);

        if (sucesso) {
            atualizar();
        }
    }

    function excluir(roterizacaoId) {
        if (window.confirm('Tem certeza que deseja excluir ?')) {
            excluirRoterizacao(roterizacaoId).then(() => {
                atualizar();
                toast.success('Roterização excluida com sucesso');
            }).catch((e) => { toast.error('Erro ao excluir roterização'); console.error(e) })

        }

    }

    return (
        <Card elevation={10} sx={{ m: 2, p: 4 }}>
            <Box
                sx={{ m: 2 }}>
                <Toaster />
                <FormControlLabel
                    control={
                        <Checkbox checked={minhas} onChange={(event) => atualizar(event.target.checked)} />
                    }
                    label="Minhas roterizações ?" />
                <Box
                    sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                        sx={{
                            position: 'relative',
                            top: '10px',
                            width: '100%',
                            margin: 2,
                            display: {
                                xs: 'none',
                                md: 'flex',
                                justifyContent: 'space-between'
                            },
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow hover>
                                        <TableCell>Roterização</TableCell>
                                        <TableCell>Carga</TableCell>
                                        <TableCell align="right">Motorista - documento</TableCell>
                                        <TableCell align="right">Placa reboque</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Criado em</TableCell>
                                        <TableCell align="right">Filial origem</TableCell>
                                        <TableCell align="right">Filial destino</TableCell>
                                        <TableCell align="right">Veiculos</TableCell>
                                        <TableCell align="right">Por</TableCell>
                                        <TableCell align="right">#</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        listaRoterizacoes.map((row, i) => (
                                            <TableRow
                                                hover
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                style={{ cursor: 'pointer' }}
                                                data-item={row.id}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.number}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.loadNames ? row.loadNames : "Carga não associada 😟"}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.driver}
                                                </TableCell>
                                                <TableCell align="right">{row.plateDriver}</TableCell>
                                                <TableCell align="right">
                                                    <Chip size='small' label={row.status} color={row.status == "Completo" ? 'success' : 'secondary'} />
                                                </TableCell>
                                                <TableCell align="right">{row.createdAt}</TableCell>
                                                <TableCell align="right">{row.branchOrigem}</TableCell>
                                                <TableCell align="right">{row.branchDestination}</TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title={row.vehicles.map(v => v.name).join(', ')}>
                                                        <div>
                                                            {
                                                                row.vehicles.length
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title={row.userCreated}>
                                                        <div>
                                                            {
                                                                row.userCreated.split(' ')[0] ?? row.userCreated
                                                            }
                                                        </div>
                                                    </Tooltip>

                                                </TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        sx={{ margin: 1 }}
                                                        disabled={row.status !== "Em rascunho"}
                                                        size='small'
                                                        color='primary' variant="contained"
                                                        onClick={() => atualizarStatusRoterizacaoRascunho(row.id)}
                                                    > {row.status === "Em rascunho" ? "Completar" : "completo"}
                                                    </Button>

                                                    {
                                                        row.status === "Completo" && row.loadNames &&
                                                        <Button
                                                            sx={{ margin: 1 }}
                                                            size='small'
                                                            color='primary' variant="contained"
                                                            onClick={() => abrirMudancaVeiculo({
                                                                carga: row.loadNames,
                                                                cargaVeiculos: row.vehicles,
                                                                documentoMotorista: row.driver,
                                                                roterizacaoId: row.id,
                                                                id: i,
                                                                cargaId:row.loadId,
                                                                veiculosMapa
                                                            })}
                                                        >
                                                            Alterar
                                                        </Button>
                                                    }

                                                    <Button
                                                        sx={{ margin: 1 }}
                                                        size='small'
                                                        color='error' variant="contained"
                                                        onClick={() => excluir(row.id)}
                                                    >
                                                        Excluir
                                                    </Button>

                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                            <Box sx={{ display: 'flex' }}>
                                <Button sx={{ margin: 1 }} variant="contained" onClick={() => { atualizar() }} >Atualizar {pagina}</Button>
                                <Button sx={{ margin: 1 }} variant="contained" onClick={() => { buscarRoterizacoes(pagina - 1) }}> Voltar</Button>
                                <Button sx={{ margin: 1 }} variant="contained" onClick={() => { buscarRoterizacoes(pagina + 1) }}> Próximo</Button>
                            </Box>
                        </TableContainer>
                        {
                            modalUpdateVeiculos && modalUpdateVeiculosData &&
                            <ModalAlterarVeiculoRoterizacao fecharModal={(e) => fecharMudancaVeiculo(e)} props={modalUpdateVeiculosData} />
                        }
                    </Box>
                </Box>

            </Box>
        </Card>
    )
}