import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CardRemocao } from "../Card";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useMap } from "react-leaflet";
import { maxZoom } from "../../plugins/leafletUtils";
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';

export default function AlertDialogMarker(props) {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);


    const estaLogado = queryParams.has('url')
    const urlCentral = `${queryParams.get('url')}&Menu=N&EstoqueCodMap=${props.props.remocao.estoque}`.replace('RemocaoListagem', 'RemocaoEditar');

    const map = useMap();
    let abrir = props ? true : false;
    // const styleDialog = {sx:{position:'fixed', top:5, left:5, m:10}}; // esquerdo
    const styleDialog = { sx: { position: 'fixed', top: 55, right: 0, m: 2 } }; // direito
    const [open, setOpen] = React.useState(abrir);

    // if (abrir && props.props.remocao.coordenada) {
    //     localizaVeiculo(null, props.props.remocao.coordenada.lat, props.props.remocao.coordenada.lng)
    // }

    const handleClose = () => {
        setOpen(false);
        props.handleCloseDialog();
    };

    const handleCopyStock = (code) => {
        try {
            navigator.clipboard.writeText(code);
            toast.success("Copiado");
        } catch (error) {
            toast.error("Não foi possivel copiar codigo estoque")
        }
    };

    function localizaVeiculo(e, lat, lng) {
        map.flyTo([lat, lng], maxZoom);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={styleDialog}
            >
                <DialogTitle id="alert-dialog-title">
                    Detalhamento de veiculo
                </DialogTitle>
                <DialogContent>
                    <CardRemocao props={props.props.remocao} />
                </DialogContent>
                <DialogActions>
                    <Tooltip title="Localizar veiculo">
                        <Button onClick={(e) => localizaVeiculo(e, props.props.remocao.coordenada.lat, props.props.remocao.coordenada.lng)} autoFocus>
                            <MyLocationIcon />
                        </Button>
                    </Tooltip>
                    {
                        estaLogado &&
                        <Tooltip title="Você deve está logado central e acessado controle 3">
                            <Button color='primary' variant="contained" href={`${urlCentral}`} target="_blank" autoFocus>
                                Visualizar veiculo
                            </Button>
                        </Tooltip>
                    }

                    <Button color='primary' variant="contained" href={`https://maps.google.com/maps?q=&layer=c&cbll=${props.props.remocao.coordenada.lat},${props.props.remocao.coordenada.lng}&cbp=11,0,0,0,0`} target="_blank" autoFocus>
                        Street View
                    </Button>

                    <Button color='primary' variant="contained" onClick={(e) => handleCopyStock(props.props.remocao.estoque)} autoFocus>
                        Estoque
                    </Button>

                    <Button color='primary' variant="contained" onClick={handleClose} autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}