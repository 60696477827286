import { useEffect, useState, useRef } from "react";
import { MapContainer, ZoomControl, Marker, Popup } from "react-leaflet";
import "./index.css";
import MoveMaker from "../../../../components/MoveMaker";
import MovePolyLine from "../../../../components/MovePolyLine";
import FullscreenControl from "../../../../plugins/LeafletCustom/FullscreenControl";
import Tiles from "../../../../components/Tiles";
import { Icon } from "leaflet";



export default function MapMicro({ point, labelMapPopUp, localizacaoMotoristaGeoJson  }) {
    let labelPopup = labelMapPopUp ?? '';
    const mapMicro = useRef();
    const { locationStart, locationEnd } = point;
    const estaIniciadoEfinalizdo = locationStart?.length && locationEnd?.length;
    const [positionMarker, setPositionMarker] = useState([]);
    const [positionPolyLine, setPositionPolyLine] = useState([]);
     console.log(localizacaoMotoristaGeoJson);

    useEffect(() => {
        if (point) {
            carregarMap(locationStart, locationEnd);
        }
    }, [point]);

    function carregarMap(pointStart, pointEnd) {
        setPositionMarker([]);
        setPositionPolyLine([]);
        if (estaIniciadoEfinalizdo) {
            setPositionPolyLine([...[pointStart, pointEnd]]);
        } else {
            setPositionMarker([...pointStart]);
        }
    }


    return (

        <MapContainer
            id="mapMicro"
            ref={mapMicro}
            center={[-10.217234, -48.330526]}
            zoom={5}
            zoomControl={false}
            scrollWheelZoom={true}
        >
            <Tiles />

            <ZoomControl position='bottomright' />
            <FullscreenControl />

            {
                localizacaoMotoristaGeoJson &&           
                <Marker
                      icon={new Icon({ iconUrl: `https://api-fleet.provedor.space/icons/bus-driver.png`, iconSize: [35, 35], iconAnchor: [35, 35] })}
                    position={localizacaoMotoristaGeoJson.coordinates}>
                        <Popup>Motorista</Popup>
                </Marker>
            }

            {
                estaIniciadoEfinalizdo && positionPolyLine.length &&
                <MovePolyLine
                    props={{
                        polyline: [positionPolyLine],
                        label: labelPopup
                    }}
                />
            }

            {
                !estaIniciadoEfinalizdo && positionMarker.length &&
                <MoveMaker
                    props={{ cords: positionMarker, label: labelPopup + ' Iniciado' }}
                />
            }

        </MapContainer>


    )
}