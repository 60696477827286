import { useEffect } from "react";
import { Popup, useMap, Polyline, Marker } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
const limeOptions = { color: 'purple' }

export default function MovePolyLine({ props }) {
    const map = useMap();
    const { polyline, label } = props;
    const locationStart = polyline[0][0];
    const locationEnd = polyline[0][1];

    useEffect(() => {
        if (polyline.length) {
            map.fitBounds(polyline, map.getZoom());
        }
    }, [props])



    return (
        <div>
            <Marker
                icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
                position={locationStart}>
                <Popup>Iniciado {label}</Popup>
            </Marker>

            <Marker
                icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
                position={locationEnd}>
                <Popup>Finalizado {label}</Popup>
            </Marker>
            <Polyline
                weight={10}
                pathOptions={limeOptions}
                positions={polyline} />

        </div>
    )
}
