export const CamadaEstados = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": 1,
        "nome": "Acre",
        "sigla": "AC",
        "regiao_id": 3,
        "codigo_ibg": 12
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.60817781573536,
                -7.201941549680493
              ],
              [
                -72.86962989956928,
                -7.528509200373606
              ],
              [
                -72.66303513685244,
                -7.622898656567378
              ],
              [
                -72.66092563877051,
                -7.623752629444335
              ],
              [
                -72.65881616568842,
                -7.624607149319393
              ],
              [
                -72.18913949229152,
                -7.72011527709537
              ],
              [
                -72.18089602817429,
                -7.721790748451541
              ],
              [
                -72.17265204007053,
                -7.723466231803219
              ],
              [
                -72.00461221246174,
                -7.774469859812434
              ],
              [
                -71.512459150868,
                -7.886919382685335
              ],
              [
                -71.2410807547645,
                -7.949828841493083
              ],
              [
                -70.93688749455353,
                -8.018712739116392
              ],
              [
                -70.57531950995747,
                -8.09556002925157
              ],
              [
                -70.3689973480435,
                -8.14102410221085
              ],
              [
                -69.76075820409547,
                -8.46585170825103
              ],
              [
                -69.55280649359838,
                -8.57799600544819
              ],
              [
                -69.43525594337109,
                -8.641214625418778
              ],
              [
                -69.1777129865615,
                -8.76757584716868
              ],
              [
                -68.98907224537352,
                -8.866178734902755
              ],
              [
                -68.78448108724885,
                -8.972206078004074
              ],
              [
                -68.72752078450011,
                -8.999688913043613
              ],
              [
                -68.62005522973276,
                -9.047748105323132
              ],
              [
                -68.18841905735415,
                -9.2323148193784
              ],
              [
                -67.93982213005718,
                -9.33683157684107
              ],
              [
                -67.49611852114475,
                -9.52419174846593
              ],
              [
                -67.32750227603216,
                -9.594167741035141
              ],
              [
                -67.13334660412046,
                -9.676443658778208
              ],
              [
                -67.1053472276056,
                -9.68772409235482
              ],
              [
                -66.80596557128627,
                -9.814127147409357
              ],
              [
                -66.68926159191987,
                -9.862459322798605
              ],
              [
                -66.61930138743952,
                -9.893602095770973
              ],
              [
                -66.62230535873077,
                -9.896706072751682
              ],
              [
                -66.62590636019058,
                -9.900374586725231
              ],
              [
                -66.62393785131412,
                -9.906717532320428
              ],
              [
                -66.6285793286472,
                -9.909211028105066
              ],
              [
                -66.63388978966326,
                -9.913566053462537
              ],
              [
                -66.62979180255435,
                -9.917825514911026
              ],
              [
                -66.63078778169452,
                -9.922686988511147
              ],
              [
                -66.63344274616315,
                -9.927309472644021
              ],
              [
                -66.63141676229576,
                -9.928346995726141
              ],
              [
                -66.62706977874888,
                -9.930571954885522
              ],
              [
                -66.62695930354478,
                -9.935258463308188
              ],
              [
                -66.6298827525802,
                -9.936320946339098
              ],
              [
                -66.63282473895877,
                -9.937121930315962
              ],
              [
                -66.63425824842801,
                -9.940488453871449
              ],
              [
                -66.63562072920617,
                -9.944480415127712
              ],
              [
                -66.6379436933648,
                -9.949046419943217
              ],
              [
                -66.64221820381243,
                -9.948915916351279
              ],
              [
                -66.65003312923277,
                -9.945058431538314
              ],
              [
                -66.65085816673577,
                -9.941726955519465
              ],
              [
                -66.6519301466933,
                -9.937393965401943
              ],
              [
                -66.65526562062874,
                -9.94094247142381
              ],
              [
                -66.6571491478303,
                -9.935892993125204
              ],
              [
                -66.6629866003898,
                -9.940186990611446
              ],
              [
                -66.66656006762163,
                -9.945882473534441
              ],
              [
                -66.6639955670508,
                -9.948915952773227
              ],
              [
                -66.66564708148782,
                -9.951661952874506
              ],
              [
                -66.66698354232005,
                -9.953884442292397
              ],
              [
                -66.67089301664146,
                -9.95672341774964
              ],
              [
                -66.67431350919973,
                -9.957230943548039
              ],
              [
                -66.67607800220263,
                -9.957492933507206
              ],
              [
                -66.67613651641176,
                -9.95840243943008
              ],
              [
                -66.67629799247774,
                -9.960906923957873
              ],
              [
                -66.6758939811131,
                -9.96411942209608
              ],
              [
                -66.67580946958171,
                -9.96770239846403
              ],
              [
                -66.67883399096665,
                -9.967664904524584
              ],
              [
                -66.69030391258346,
                -9.9675059137751
              ],
              [
                -66.69517788305572,
                -9.96743843595545
              ],
              [
                -66.69451138476037,
                -9.969564905460723
              ],
              [
                -66.69409337857749,
                -9.970896897138969
              ],
              [
                -66.70173584496678,
                -9.973637932057178
              ],
              [
                -66.7063758213557,
                -9.975513911095756
              ],
              [
                -66.71140030216586,
                -9.977219907356966
              ],
              [
                -66.71474824913224,
                -9.978307403036963
              ],
              [
                -66.71510327314323,
                -9.977866908149062
              ],
              [
                -66.71779076965764,
                -9.974531448139988
              ],
              [
                -66.72064973484713,
                -9.978117921328748
              ],
              [
                -66.72106074499217,
                -9.980443432910988
              ],
              [
                -66.72146223927635,
                -9.982712424675224
              ],
              [
                -66.72388123145016,
                -9.982173916498096
              ],
              [
                -66.7274586954403,
                -9.981377928107609
              ],
              [
                -66.73118267005351,
                -9.980382944647548
              ],
              [
                -66.73358867682408,
                -9.983135943006928
              ],
              [
                -66.7340926410046,
                -9.98817539431617
              ],
              [
                -66.73454366891852,
                -9.988717425169659
              ],
              [
                -66.73918462617404,
                -9.994287904403032
              ],
              [
                -66.74150159045934,
                -9.996387400900144
              ],
              [
                -66.74565009879461,
                -9.995532417653944
              ],
              [
                -66.74677156775012,
                -9.995300887291924
              ],
              [
                -66.75038108168246,
                -9.994455418130261
              ],
              [
                -66.75275606583506,
                -9.99706890094249
              ],
              [
                -66.75560153524366,
                -9.999962378555795
              ],
              [
                -66.76218299588348,
                -9.999666895350458
              ],
              [
                -66.76406349480617,
                -10.00625587257421
              ],
              [
                -66.76746297720103,
                -10.00814940657519
              ],
              [
                -66.77086893350905,
                -10.010076879471743
              ],
              [
                -66.77138192073737,
                -10.012649357395283
              ],
              [
                -66.77173842103029,
                -10.01443734678243
              ],
              [
                -66.77282741398248,
                -10.019698840334325
              ],
              [
                -66.77798939253174,
                -10.01759036510327
              ],
              [
                -66.77941589660507,
                -10.017007383461621
              ],
              [
                -66.78380536591536,
                -10.019081353474812
              ],
              [
                -66.79131482752653,
                -10.023919873039326
              ],
              [
                -66.79314979492544,
                -10.026152846321466
              ],
              [
                -66.79550179677096,
                -10.029016331272508
              ],
              [
                -66.79952173586572,
                -10.032291368542143
              ],
              [
                -66.80568372321781,
                -10.038191833258614
              ],
              [
                -66.80488269734812,
                -10.03923233734476
              ],
              [
                -66.8020522208773,
                -10.04290783198437
              ],
              [
                -66.80711019493211,
                -10.047985807748088
              ],
              [
                -66.81597812838027,
                -10.05079478863136
              ],
              [
                -66.82238859621312,
                -10.058075279041132
              ],
              [
                -66.82571308249817,
                -10.05966080504271
              ],
              [
                -66.82738155677059,
                -10.060060293411885
              ],
              [
                -66.83133355410109,
                -10.061005782658722
              ],
              [
                -66.83551904450391,
                -10.062429780640974
              ],
              [
                -66.83492952102124,
                -10.066201765657771
              ],
              [
                -66.83486003161495,
                -10.066643778018552
              ],
              [
                -66.8398534722294,
                -10.070979774297392
              ],
              [
                -66.83824399566893,
                -10.082485201977088
              ],
              [
                -66.84411195666299,
                -10.084673712123339
              ],
              [
                -66.84758592777237,
                -10.08534173255864
              ],
              [
                -66.85163639998312,
                -10.086080244704268
              ],
              [
                -66.85181741629196,
                -10.08061925981428
              ],
              [
                -66.85309488851833,
                -10.080981761674368
              ],
              [
                -66.85481290141009,
                -10.081468731833256
              ],
              [
                -66.86202737946508,
                -10.082741240362983
              ],
              [
                -66.86629884937625,
                -10.084219256328694
              ],
              [
                -66.87266131076031,
                -10.081205772207339
              ],
              [
                -66.87129029828546,
                -10.086608250351505
              ],
              [
                -66.87145729621697,
                -10.08973625986436
              ],
              [
                -66.87111229727921,
                -10.093658242800624
              ],
              [
                -66.87133378201831,
                -10.094366712729547
              ],
              [
                -66.87324526450146,
                -10.100481725857366
              ],
              [
                -66.87634627227527,
                -10.098863212553024
              ],
              [
                -66.87743526945245,
                -10.098609225227658
              ],
              [
                -66.88042724820315,
                -10.097911236576467
              ],
              [
                -66.88169720991374,
                -10.101571737092847
              ],
              [
                -66.88352219724575,
                -10.106834201025388
              ],
              [
                -66.88546972330762,
                -10.104194695320244
              ],
              [
                -66.88644770982678,
                -10.102869732492271
              ],
              [
                -66.88734070509612,
                -10.105495733946123
              ],
              [
                -66.88818518083767,
                -10.107979690809303
              ],
              [
                -66.89083717854105,
                -10.113027694860156
              ],
              [
                -66.89523262880908,
                -10.110982724164504
              ],
              [
                -66.8952386307852,
                -10.110979715184772
              ],
              [
                -66.89524014876442,
                -10.110987713159819
              ],
              [
                -66.8968791320327,
                -10.118000169796582
              ],
              [
                -66.89815060585356,
                -10.119808179697387
              ],
              [
                -66.89815463282743,
                -10.119812186690277
              ],
              [
                -66.89815664181017,
                -10.119816653678267
              ],
              [
                -66.89970861760531,
                -10.122023661675897
              ],
              [
                -66.89971212758077,
                -10.122028185666155
              ],
              [
                -66.89971411256445,
                -10.1220321626558
              ],
              [
                -66.90218257641835,
                -10.12632564101558
              ],
              [
                -66.90827905390744,
                -10.125818180870079
              ],
              [
                -66.90993002049417,
                -10.130824152439274
              ],
              [
                -66.91373852681585,
                -10.129839660136359
              ],
              [
                -66.91617099598596,
                -10.130499671910508
              ],
              [
                -66.91791400579217,
                -10.130973148180395
              ],
              [
                -66.9177879877416,
                -10.132544677583768
              ],
              [
                -66.91761151087995,
                -10.134739158768184
              ],
              [
                -66.91503751660174,
                -10.136319626076055
              ],
              [
                -66.91085904371792,
                -10.138688628014647
              ],
              [
                -66.91144000202378,
                -10.146307085864349
              ],
              [
                -66.91624398313238,
                -10.1449811293938
              ],
              [
                -66.92336395787875,
                -10.143191622358989
              ],
              [
                -66.92736094957337,
                -10.145000649805745
              ],
              [
                -66.92780142710583,
                -10.145205650835823
              ],
              [
                -66.93309341046616,
                -10.147666111208709
              ],
              [
                -66.93317040276335,
                -10.151993622521506
              ],
              [
                -66.93322939880709,
                -10.155340096164577
              ],
              [
                -66.93540135224455,
                -10.154814622577339
              ],
              [
                -66.93889535386256,
                -10.153969596283188
              ],
              [
                -66.94208682243205,
                -10.155782600390495
              ],
              [
                -66.94598679345539,
                -10.160625086317838
              ],
              [
                -66.94634678007502,
                -10.165132583502535
              ],
              [
                -66.94760880419078,
                -10.170323557856793
              ],
              [
                -66.947764295417,
                -10.170927577051035
              ],
              [
                -66.948997758348,
                -10.175716547737688
              ],
              [
                -66.95127372734966,
                -10.185745526265391
              ],
              [
                -66.95512723890586,
                -10.18804252585256
              ],
              [
                -66.95856720223067,
                -10.185180022371743
              ],
              [
                -66.96070070598928,
                -10.183405031994614
              ],
              [
                -66.96274116818613,
                -10.185751540387948
              ],
              [
                -66.96860913199939,
                -10.192199521176873
              ],
              [
                -66.97591058763612,
                -10.194704020830985
              ],
              [
                -66.97942107565031,
                -10.195945029464836
              ],
              [
                -66.98362856454006,
                -10.197642034710732
              ],
              [
                -66.99189603229435,
                -10.20264400248504
              ],
              [
                -66.9922135245557,
                -10.199248534587225
              ],
              [
                -66.99562751517918,
                -10.19923503534169
              ],
              [
                -66.99521398847335,
                -10.20257502526977
              ],
              [
                -66.99385598360603,
                -10.206323498226782
              ],
              [
                -66.99339700637336,
                -10.210097999599968
              ],
              [
                -66.9979874628874,
                -10.216213483457906
              ],
              [
                -66.99521745703304,
                -10.216892972504587
              ],
              [
                -66.98965451642877,
                -10.218257473539312
              ],
              [
                -66.98832651225918,
                -10.220922455237272
              ],
              [
                -66.9940629491934,
                -10.2235604238667
              ],
              [
                -66.99625094976595,
                -10.227023429744932
              ],
              [
                -66.99916393457772,
                -10.228893437264434
              ],
              [
                -67.00429890942229,
                -10.235030419996747
              ],
              [
                -67.00441388433218,
                -10.240020915214666
              ],
              [
                -67.00508687049864,
                -10.244461902241634
              ],
              [
                -67.00707085233921,
                -10.247166884376766
              ],
              [
                -67.00874886028241,
                -10.25008536827468
              ],
              [
                -67.01130584522426,
                -10.25404234912357
              ],
              [
                -67.01719181125428,
                -10.256207346664281
              ],
              [
                -67.0222392610501,
                -10.255026386163504
              ],
              [
                -67.02427427647216,
                -10.257832351056429
              ],
              [
                -67.02790472961144,
                -10.25750436727659
              ],
              [
                -67.03017172608357,
                -10.257299876783993
              ],
              [
                -67.03279169400969,
                -10.260508361300273
              ],
              [
                -67.03515820568445,
                -10.263405868449262
              ],
              [
                -67.03686367118183,
                -10.263009879663374
              ],
              [
                -67.04015018672335,
                -10.262247882779995
              ],
              [
                -67.04317013927037,
                -10.26455988602002
              ],
              [
                -67.04794612887336,
                -10.266011386137848
              ],
              [
                -67.05017112649367,
                -10.268172368557224
              ],
              [
                -67.0522161089749,
                -10.270916864697657
              ],
              [
                -67.05145911613711,
                -10.271771364525842
              ],
              [
                -67.05017457880788,
                -10.273220332450023
              ],
              [
                -67.04778262958581,
                -10.275369856132905
              ],
              [
                -67.05426207525764,
                -10.277847354656867
              ],
              [
                -67.05821306978498,
                -10.277573845243834
              ],
              [
                -67.06347852319854,
                -10.280101850557855
              ],
              [
                -67.06920349248695,
                -10.282005350766784
              ],
              [
                -67.07530196512293,
                -10.283250346838967
              ],
              [
                -67.08150690848302,
                -10.288430847779638
              ],
              [
                -67.08616840083263,
                -10.288617870015035
              ],
              [
                -67.0878689075917,
                -10.285768346522488
              ],
              [
                -67.0894608868346,
                -10.28526437691332
              ],
              [
                -67.09827032645168,
                -10.282474878209683
              ],
              [
                -67.09971233417458,
                -10.286179365119786
              ],
              [
                -67.10451830637078,
                -10.28548091659153
              ],
              [
                -67.10741880076316,
                -10.287645369173246
              ],
              [
                -67.11507525562052,
                -10.292003393380108
              ],
              [
                -67.12006171261358,
                -10.292670885548347
              ],
              [
                -67.12404121259323,
                -10.290919414182389
              ],
              [
                -67.13062417586357,
                -10.29280338594293
              ],
              [
                -67.13632811640855,
                -10.297550905564352
              ],
              [
                -67.13874361153053,
                -10.308759872851995
              ],
              [
                -67.13741810887345,
                -10.309915837712184
              ],
              [
                -67.13604858736136,
                -10.311109850369252
              ],
              [
                -67.13264661912397,
                -10.317195338094848
              ],
              [
                -67.1357730874289,
                -10.318784336032994
              ],
              [
                -67.1388020704472,
                -10.316756827995652
              ],
              [
                -67.14180007232936,
                -10.322999809037384
              ],
              [
                -67.14583453884181,
                -10.324851829635206
              ],
              [
                -67.15201148809564,
                -10.327294306879772
              ],
              [
                -67.15715546987362,
                -10.328242803407313
              ],
              [
                -67.16307092376108,
                -10.329890312893157
              ],
              [
                -67.16908641080582,
                -10.33695278734098
              ],
              [
                -67.17995635435739,
                -10.338996329919686
              ],
              [
                -67.18710629813499,
                -10.340201847006929
              ],
              [
                -67.18871431421631,
                -10.336876834907368
              ],
              [
                -67.19037029647629,
                -10.333893342741439
              ],
              [
                -67.19276629411334,
                -10.329227888481421
              ],
              [
                -67.19305031367557,
                -10.328697370745672
              ],
              [
                -67.19519080637575,
                -10.324698901810313
              ],
              [
                -67.2008507747787,
                -10.32272693003087
              ],
              [
                -67.20700275069939,
                -10.323149901039134
              ],
              [
                -67.20988322569046,
                -10.323305941398552
              ],
              [
                -67.21701219570907,
                -10.323874927575336
              ],
              [
                -67.2180061618321,
                -10.323954449993753
              ],
              [
                -67.21824566132393,
                -10.323587928630094
              ],
              [
                -67.22304067211378,
                -10.316269484322257
              ],
              [
                -67.2267521428392,
                -10.316606489480215
              ],
              [
                -67.23066564809045,
                -10.316661973925731
              ],
              [
                -67.23543910383242,
                -10.316957001025369
              ],
              [
                -67.23897257663468,
                -10.319479990552226
              ],
              [
                -67.24196954235667,
                -10.32179300187717
              ],
              [
                -67.2428425331342,
                -10.322407963295642
              ],
              [
                -67.24591953472633,
                -10.324575960246285
              ],
              [
                -67.25361597336679,
                -10.328449461323046
              ],
              [
                -67.25717896205806,
                -10.329802472837107
              ],
              [
                -67.26036493987147,
                -10.330967476342783
              ],
              [
                -67.26605290722568,
                -10.331872481967824
              ],
              [
                -67.26831941829808,
                -10.327909015083643
              ],
              [
                -67.26883341502929,
                -10.32722850923409
              ],
              [
                -67.27005140902604,
                -10.325614009705827
              ],
              [
                -67.27392389333436,
                -10.324031503573268
              ],
              [
                -67.27810438259019,
                -10.322211533756473
              ],
              [
                -67.28568236441282,
                -10.319971535105411
              ],
              [
                -67.28902633054996,
                -10.32168105805784
              ],
              [
                -67.29153332093179,
                -10.323238571100948
              ],
              [
                -67.29312129700675,
                -10.324032051141447
              ],
              [
                -67.29445229952631,
                -10.32469705817602
              ],
              [
                -67.29498230758068,
                -10.32378705711833
              ],
              [
                -67.29628629925654,
                -10.321546561822927
              ],
              [
                -67.30123125429145,
                -10.322964563470963
              ],
              [
                -67.30262475897517,
                -10.323364081499724
              ],
              [
                -67.30300524397768,
                -10.323473085780961
              ],
              [
                -67.30995224166499,
                -10.321329578731891
              ],
              [
                -67.31471219048942,
                -10.319916097530152
              ],
              [
                -67.32162713696287,
                -10.322037590175023
              ],
              [
                -67.32315414720124,
                -10.32579707221166
              ],
              [
                -67.32580361405287,
                -10.335936075863458
              ],
              [
                -67.32339312704077,
                -10.339088047247278
              ],
              [
                -67.31558563593674,
                -10.347277016640342
              ],
              [
                -67.3170606179792,
                -10.353585495096182
              ],
              [
                -67.31779664280964,
                -10.35742796152839
              ],
              [
                -67.31826312736602,
                -10.359861471204235
              ],
              [
                -67.31539312681616,
                -10.363423468433506
              ],
              [
                -67.31297961848693,
                -10.36906141151399
              ],
              [
                -67.31070861843585,
                -10.37242292042997
              ],
              [
                -67.31267210655261,
                -10.376762913303311
              ],
              [
                -67.31682861528122,
                -10.376484916319601
              ],
              [
                -67.32019907518223,
                -10.378830395249693
              ],
              [
                -67.32469504132742,
                -10.380261920149522
              ],
              [
                -67.32939805019451,
                -10.381289889749754
              ],
              [
                -67.33420449408067,
                -10.379154918063808
              ],
              [
                -67.33749050490866,
                -10.377181921242004
              ],
              [
                -67.33902046377186,
                -10.377107920098615
              ],
              [
                -67.3419639530434,
                -10.3769649645964
              ],
              [
                -67.3449989360954,
                -10.377848435832824
              ],
              [
                -67.34982743304349,
                -10.379071461000612
              ],
              [
                -67.35115443231156,
                -10.381042927703788
              ],
              [
                -67.3518193984371,
                -10.382029432555814
              ],
              [
                -67.35280491918778,
                -10.385801907685474
              ],
              [
                -67.35063390068433,
                -10.392003905343003
              ],
              [
                -67.35632835974755,
                -10.391950897249178
              ],
              [
                -67.36085587192541,
                -10.385258441241621
              ],
              [
                -67.36565936094814,
                -10.379623955182202
              ],
              [
                -67.36813183326937,
                -10.376722967049943
              ],
              [
                -67.37210782245651,
                -10.378992974286838
              ],
              [
                -67.37505831652682,
                -10.380678474719753
              ],
              [
                -67.38018778570881,
                -10.380961006537706
              ],
              [
                -67.38286175768692,
                -10.380009484264782
              ],
              [
                -67.38681025941159,
                -10.378605497670245
              ],
              [
                -67.38731626132629,
                -10.378425492132058
              ],
              [
                -67.3907722400287,
                -10.37722803000929
              ],
              [
                -67.39361672269573,
                -10.375993524964928
              ],
              [
                -67.39771020783148,
                -10.373962545696484
              ],
              [
                -67.40634767149557,
                -10.37362354256134
              ],
              [
                -67.41225813203552,
                -10.380205025806175
              ],
              [
                -67.41446608411151,
                -10.383342049168954
              ],
              [
                -67.41773506995776,
                -10.387169506058358
              ],
              [
                -67.42064507066658,
                -10.39090301365159
              ],
              [
                -67.42274903363179,
                -10.393810526609757
              ],
              [
                -67.41979255920039,
                -10.396349992135466
              ],
              [
                -67.4159990714978,
                -10.39959747488042
              ],
              [
                -67.41340608490373,
                -10.401817452083808
              ],
              [
                -67.41333905634914,
                -10.406897461128157
              ],
              [
                -67.41338154380146,
                -10.409957432058127
              ],
              [
                -67.41343354898586,
                -10.422864389377523
              ],
              [
                -67.41335905290681,
                -10.427110866182579
              ],
              [
                -67.41928850430773,
                -10.430259884926057
              ],
              [
                -67.42566147661883,
                -10.429979382789925
              ],
              [
                -67.42978496241295,
                -10.429797902466134
              ],
              [
                -67.4323734438126,
                -10.433420872920179
              ],
              [
                -67.43638439469659,
                -10.439200881687492
              ],
              [
                -67.43629491225717,
                -10.442544846475132
              ],
              [
                -67.43614638928294,
                -10.448061859977674
              ],
              [
                -67.43876584598046,
                -10.45028632312722
              ],
              [
                -67.44111736605758,
                -10.452447332028278
              ],
              [
                -67.44388382480393,
                -10.454247839836594
              ],
              [
                -67.4509543044518,
                -10.456466328671455
              ],
              [
                -67.46178975734935,
                -10.459225333208503
              ],
              [
                -67.46282473699067,
                -10.459489322118642
              ],
              [
                -67.46822069164112,
                -10.461101332087708
              ],
              [
                -67.47080419624083,
                -10.46348232068095
              ],
              [
                -67.47490014623807,
                -10.467257840283256
              ],
              [
                -67.4909205633095,
                -10.473063820754655
              ],
              [
                -67.49347054609765,
                -10.47258884071508
              ],
              [
                -67.49610203352823,
                -10.472098856865175
              ],
              [
                -67.50081400364222,
                -10.471887873682538
              ],
              [
                -67.50996295922356,
                -10.475985842967503
              ],
              [
                -67.51397793625955,
                -10.478261836403771
              ],
              [
                -67.51475691837325,
                -10.480651359886803
              ],
              [
                -67.51497294118639,
                -10.481313841080318
              ],
              [
                -67.51794441807021,
                -10.487038837380657
              ],
              [
                -67.51991438818419,
                -10.490207818363151
              ],
              [
                -67.52849836870598,
                -10.48962134411628
              ],
              [
                -67.5365678264082,
                -10.489181839497503
              ],
              [
                -67.53913032143222,
                -10.489118363131933
              ],
              [
                -67.54314379367655,
                -10.489019349389302
              ],
              [
                -67.54719227014469,
                -10.49096935997994
              ],
              [
                -67.55359572934975,
                -10.494144380623245
              ],
              [
                -67.55374520251453,
                -10.4942183506388
              ],
              [
                -67.55419920997703,
                -10.49444335568509
              ],
              [
                -67.5584752016759,
                -10.496224362232486
              ],
              [
                -67.56328768548212,
                -10.498212866030128
              ],
              [
                -67.56729315059697,
                -10.499350352226278
              ],
              [
                -67.57278309517474,
                -10.50079638598037
              ],
              [
                -67.57925456937569,
                -10.502671390030251
              ],
              [
                -67.58759552440111,
                -10.509852379945588
              ],
              [
                -67.5898535139102,
                -10.513730847158332
              ],
              [
                -67.59199700806327,
                -10.517548847375288
              ],
              [
                -67.59441798103434,
                -10.521860337463158
              ],
              [
                -67.59685046209556,
                -10.526088831848083
              ],
              [
                -67.59928695362343,
                -10.530606288300742
              ],
              [
                -67.60221040538569,
                -10.535953293893025
              ],
              [
                -67.60421738142993,
                -10.539624292379331
              ],
              [
                -67.60699337004519,
                -10.54487777003554
              ],
              [
                -67.60795035673266,
                -10.548150774006839
              ],
              [
                -67.60932986685867,
                -10.552871749986448
              ],
              [
                -67.61016335985123,
                -10.5557347410873
              ],
              [
                -67.61221981163875,
                -10.563351202801634
              ],
              [
                -67.61748228652358,
                -10.567486210469225
              ],
              [
                -67.6255102550182,
                -10.573521693766981
              ],
              [
                -67.63407717825994,
                -10.579962175705814
              ],
              [
                -67.63490618479858,
                -10.583085172976533
              ],
              [
                -67.63574115849028,
                -10.586666690766284
              ],
              [
                -67.63657013725081,
                -10.59022414462639
              ],
              [
                -67.63785113055984,
                -10.59396316568691
              ],
              [
                -67.64634106504444,
                -10.602145625882088
              ],
              [
                -67.65291905401996,
                -10.608924614329263
              ],
              [
                -67.66017799906676,
                -10.616110126667389
              ],
              [
                -67.66281747049977,
                -10.614524637437484
              ],
              [
                -67.66357546190393,
                -10.613984614518277
              ],
              [
                -67.66867194644304,
                -10.610358643214152
              ],
              [
                -67.67619393027587,
                -10.604671674841365
              ],
              [
                -67.69004977332303,
                -10.650976529877246
              ],
              [
                -67.69165627598373,
                -10.65643503201205
              ],
              [
                -67.69326224864098,
                -10.661894000150843
              ],
              [
                -67.70739110407264,
                -10.708130846466505
              ],
              [
                -67.71117257049836,
                -10.708591876652974
              ],
              [
                -67.71537557550577,
                -10.709298363791829
              ],
              [
                -67.71882754189859,
                -10.710082360355452
              ],
              [
                -67.72602800497631,
                -10.706844387522759
              ],
              [
                -67.72729252023056,
                -10.709512371143616
              ],
              [
                -67.72912200352256,
                -10.712906883419866
              ],
              [
                -67.73279046641916,
                -10.712300372857024
              ],
              [
                -67.7363179794951,
                -10.711407904967137
              ],
              [
                -67.74050742454884,
                -10.708810917743534
              ],
              [
                -67.74369892736655,
                -10.706720445121714
              ],
              [
                -67.74429694276002,
                -10.701625447611361
              ],
              [
                -67.74427342420431,
                -10.6981144738962
              ],
              [
                -67.74426294928804,
                -10.696431947306088
              ],
              [
                -67.74731991403468,
                -10.696392978824386
              ],
              [
                -67.74784992367594,
                -10.69632395298203
              ],
              [
                -67.75093742693159,
                -10.695924491716996
              ],
              [
                -67.75591236177917,
                -10.695285990552012
              ],
              [
                -67.75549638675926,
                -10.692495506817203
              ],
              [
                -67.7550103869114,
                -10.689235988473078
              ],
              [
                -67.75988237449263,
                -10.685130031304087
              ],
              [
                -67.76414734499663,
                -10.687086029513978
              ],
              [
                -67.76585685859048,
                -10.687309022808572
              ],
              [
                -67.76820032706897,
                -10.687614027956604
              ],
              [
                -67.77215232452475,
                -10.68597355121109
              ],
              [
                -67.77567729157616,
                -10.684003056774939
              ],
              [
                -67.77665081879852,
                -10.68190555625042
              ],
              [
                -67.77700428651774,
                -10.68114307133068
              ],
              [
                -67.78081529572869,
                -10.678341580073448
              ],
              [
                -67.78707977073753,
                -10.676498103048438
              ],
              [
                -67.78713677545467,
                -10.676507087119937
              ],
              [
                -67.79132425364664,
                -10.677184119315159
              ],
              [
                -67.79497573039488,
                -10.673947613171222
              ],
              [
                -67.79799372508911,
                -10.670720652878797
              ],
              [
                -67.79873319705368,
                -10.66993015672665
              ],
              [
                -67.80043221102575,
                -10.669420640358645
              ],
              [
                -67.80413767169757,
                -10.668310669456064
              ],
              [
                -67.80790617765321,
                -10.667436189905356
              ],
              [
                -67.80884067077704,
                -10.664939190589331
              ],
              [
                -67.81026716396144,
                -10.661129180366583
              ],
              [
                -67.81246816129837,
                -10.663006183195908
              ],
              [
                -67.81496615879398,
                -10.665135174738758
              ],
              [
                -67.81818463743183,
                -10.663646197195064
              ],
              [
                -67.82031361791972,
                -10.658955224038118
              ],
              [
                -67.82193462976701,
                -10.655383217385795
              ],
              [
                -67.82464111972942,
                -10.652819739395369
              ],
              [
                -67.83031460678713,
                -10.65195176616305
              ],
              [
                -67.83665205583107,
                -10.649368766613945
              ],
              [
                -67.84299952713435,
                -10.65107180429471
              ],
              [
                -67.84946101012079,
                -10.64876280607792
              ],
              [
                -67.85045098640319,
                -10.648816801644527
              ],
              [
                -67.8543194709746,
                -10.649025294774704
              ],
              [
                -67.85590948982743,
                -10.647760315635413
              ],
              [
                -67.85802645197617,
                -10.64607532477176
              ],
              [
                -67.86519644580922,
                -10.640905860982961
              ],
              [
                -67.86801492799009,
                -10.641837340941827
              ],
              [
                -67.87187889236077,
                -10.642732867854296
              ],
              [
                -67.87595538612769,
                -10.643970848041974
              ],
              [
                -67.87668736231488,
                -10.644193378613846
              ],
              [
                -67.87673638075084,
                -10.643823383888055
              ],
              [
                -67.87730087376586,
                -10.63956489155522
              ],
              [
                -67.88043885074052,
                -10.639784872361508
              ],
              [
                -67.88407786637124,
                -10.640010394030677
              ],
              [
                -67.88725583825453,
                -10.6401793950717
              ],
              [
                -67.8915902956744,
                -10.640390410010246
              ],
              [
                -67.88981483391787,
                -10.643748401114287
              ],
              [
                -67.8898002939372,
                -10.643775366002162
              ],
              [
                -67.89267530431312,
                -10.64612739450926
              ],
              [
                -67.89576676866103,
                -10.64626440750469
              ],
              [
                -67.89743576108002,
                -10.645042406358519
              ],
              [
                -67.90037827324109,
                -10.642887923441231
              ],
              [
                -67.90445824208126,
                -10.641859914908894
              ],
              [
                -67.90822823542851,
                -10.641354920153919
              ],
              [
                -67.91078871451522,
                -10.644688399968096
              ],
              [
                -67.91309718734756,
                -10.647702921365429
              ],
              [
                -67.91329719394894,
                -10.647962909883958
              ],
              [
                -67.91627168154086,
                -10.649941907774322
              ],
              [
                -67.92016664493222,
                -10.65131790821812
              ],
              [
                -67.92467762906783,
                -10.65291091805312
              ],
              [
                -67.92813509112126,
                -10.65449739805782
              ],
              [
                -67.93312508179795,
                -10.655664928103304
              ],
              [
                -67.93637507963629,
                -10.655686434755163
              ],
              [
                -67.94217501592063,
                -10.654981451219998
              ],
              [
                -67.94608150002342,
                -10.652605430696195
              ],
              [
                -67.94759951162386,
                -10.651681970321492
              ],
              [
                -67.95441099128121,
                -10.650940484679275
              ],
              [
                -67.95821697020388,
                -10.650596477476673
              ],
              [
                -67.96128193580843,
                -10.650677965609763
              ],
              [
                -67.96571143154027,
                -10.653834476318092
              ],
              [
                -67.96582491686713,
                -10.653915459259073
              ],
              [
                -67.96865540706867,
                -10.655939975774082
              ],
              [
                -67.97378587229638,
                -10.660345460738645
              ],
              [
                -67.97657535978215,
                -10.662318477353272
              ],
              [
                -67.98072984914656,
                -10.658616477490552
              ],
              [
                -67.98516230771159,
                -10.655753023437551
              ],
              [
                -67.99262328779126,
                -10.652569503734963
              ],
              [
                -67.99743977961937,
                -10.650240043647003
              ],
              [
                -68.0049162495626,
                -10.649323554731067
              ],
              [
                -68.00791071014599,
                -10.64961204808198
              ],
              [
                -68.0119452203147,
                -10.648561055535083
              ],
              [
                -68.01546068316968,
                -10.650136069702247
              ],
              [
                -68.01997565007272,
                -10.650220063383399
              ],
              [
                -68.02407911401323,
                -10.654679059401571
              ],
              [
                -68.02717409908169,
                -10.655555085062762
              ],
              [
                -68.02848012635866,
                -10.655925085185054
              ],
              [
                -68.03022511059837,
                -10.659072566236228
              ],
              [
                -68.0310550910531,
                -10.66056956693236
              ],
              [
                -68.03151558962135,
                -10.664619056852098
              ],
              [
                -68.03642806903107,
                -10.667289060016525
              ],
              [
                -68.04040601265535,
                -10.669471055090321
              ],
              [
                -68.04475249514917,
                -10.669670052124875
              ],
              [
                -68.04632099763154,
                -10.66980753545466
              ],
              [
                -68.04990750244482,
                -10.670121073785024
              ],
              [
                -68.05424094686346,
                -10.66984458330954
              ],
              [
                -68.05763193433336,
                -10.673625078277048
              ],
              [
                -68.06315242324486,
                -10.67668154131062
              ],
              [
                -68.06362688017373,
                -10.678827536331493
              ],
              [
                -68.06390788958201,
                -10.68009605279856
              ],
              [
                -68.06550186221494,
                -10.68341004708927
              ],
              [
                -68.06866586765439,
                -10.683912063085256
              ],
              [
                -68.0688238438768,
                -10.683937541283475
              ],
              [
                -68.07403984635641,
                -10.684140544855046
              ],
              [
                -68.07743230953626,
                -10.688631031599959
              ],
              [
                -68.0809832852366,
                -10.692426549837103
              ],
              [
                -68.08423025314272,
                -10.695780011944464
              ],
              [
                -68.08721926067531,
                -10.697788524864226
              ],
              [
                -68.08936372453792,
                -10.700117030277669
              ],
              [
                -68.0915262086326,
                -10.703928522025901
              ],
              [
                -68.09615967916884,
                -10.704494039437646
              ],
              [
                -68.0948261830158,
                -10.708538510263685
              ],
              [
                -68.0987126878894,
                -10.7097224863979
              ],
              [
                -68.10140566883501,
                -10.712811484387622
              ],
              [
                -68.10290314076505,
                -10.713994011297478
              ],
              [
                -68.1038671549249,
                -10.714756014593775
              ],
              [
                -68.10460113681526,
                -10.721769456697032
              ],
              [
                -68.10778209481381,
                -10.724684959114304
              ],
              [
                -68.10412562753204,
                -10.726445944055468
              ],
              [
                -68.1060015886147,
                -10.728782462992022
              ],
              [
                -68.10545461264226,
                -10.732364938688516
              ],
              [
                -68.10245411404824,
                -10.74033491715127
              ],
              [
                -68.10097359502493,
                -10.742466406779572
              ],
              [
                -68.09985860026606,
                -10.744071879720577
              ],
              [
                -68.09769211665571,
                -10.748271882586746
              ],
              [
                -68.09861659191093,
                -10.751278377723022
              ],
              [
                -68.10148059421807,
                -10.753170380834536
              ],
              [
                -68.10229206344597,
                -10.75592837255971
              ],
              [
                -68.10304105410418,
                -10.759706845950152
              ],
              [
                -68.10516956426459,
                -10.761899845808781
              ],
              [
                -68.1045960418595,
                -10.766324839806977
              ],
              [
                -68.10335454750991,
                -10.77460779643042
              ],
              [
                -68.1061105394831,
                -10.77695328593519
              ],
              [
                -68.11137247546928,
                -10.78116028703515
              ],
              [
                -68.11482795652225,
                -10.782237293798744
              ],
              [
                -68.11488797220544,
                -10.782258777837987
              ],
              [
                -68.11797646292258,
                -10.783354787888522
              ],
              [
                -68.12011394880669,
                -10.7867692709304
              ],
              [
                -68.1220804343336,
                -10.789716278145097
              ],
              [
                -68.12276540155705,
                -10.791145251861513
              ],
              [
                -68.12371839352075,
                -10.793132764296011
              ],
              [
                -68.12514788307507,
                -10.795780245501991
              ],
              [
                -68.1304103803163,
                -10.799846235087466
              ],
              [
                -68.13634932258063,
                -10.804924732702492
              ],
              [
                -68.13971331184692,
                -10.807760717786179
              ],
              [
                -68.14516877513108,
                -10.81459523903111
              ],
              [
                -68.14797174312586,
                -10.817902203642035
              ],
              [
                -68.15214621748159,
                -10.820282698626842
              ],
              [
                -68.15229820462103,
                -10.82036920562687
              ],
              [
                -68.15570172388911,
                -10.822558710835862
              ],
              [
                -68.15725967133486,
                -10.82493723014816
              ],
              [
                -68.15919818422968,
                -10.827202688499582
              ],
              [
                -68.16098717505278,
                -10.829338223993428
              ],
              [
                -68.16479515613244,
                -10.833883701536942
              ],
              [
                -68.16722913245353,
                -10.836304191298117
              ],
              [
                -68.16864961768617,
                -10.836963191775817
              ],
              [
                -68.17078911398777,
                -10.837955687496208
              ],
              [
                -68.17224111354554,
                -10.840537665994178
              ],
              [
                -68.17508805410982,
                -10.845599161215514
              ],
              [
                -68.17856806255263,
                -10.850223177953946
              ],
              [
                -68.18051602373534,
                -10.852849661213325
              ],
              [
                -68.18223699724156,
                -10.855325641539388
              ],
              [
                -68.18644947620385,
                -10.8567396496741
              ],
              [
                -68.18848847699259,
                -10.857176666967863
              ],
              [
                -68.19475546260949,
                -10.858521162016164
              ],
              [
                -68.19618444715296,
                -10.8649696563818
              ],
              [
                -68.19884342223503,
                -10.872385096940885
              ],
              [
                -68.20006841376134,
                -10.876089123548773
              ],
              [
                -68.20494885878307,
                -10.878515588740637
              ],
              [
                -68.20389488616561,
                -10.884198603058891
              ],
              [
                -68.20338634645339,
                -10.886940597563294
              ],
              [
                -68.20378535181844,
                -10.892249080675532
              ],
              [
                -68.2078372871241,
                -10.902543515792566
              ],
              [
                -68.21238580513985,
                -10.904715015214492
              ],
              [
                -68.21534025377503,
                -10.906200045907315
              ],
              [
                -68.21715076510561,
                -10.907457050248885
              ],
              [
                -68.2184617623804,
                -10.908367019773447
              ],
              [
                -68.22342872680798,
                -10.910898031838835
              ],
              [
                -68.22489622418642,
                -10.913525002287576
              ],
              [
                -68.22780170108271,
                -10.918775494148683
              ],
              [
                -68.22838867969897,
                -10.921843488635526
              ],
              [
                -68.22853967947822,
                -10.927526978175424
              ],
              [
                -68.22872317892339,
                -10.930068984577376
              ],
              [
                -68.22893666347872,
                -10.933015953771339
              ],
              [
                -68.23063912624328,
                -10.937002943419094
              ],
              [
                -68.2308241309389,
                -10.941560456543769
              ],
              [
                -68.23330260731477,
                -10.948676426853433
              ],
              [
                -68.23567557566098,
                -10.952296419875093
              ],
              [
                -68.23769157787542,
                -10.956333384955009
              ],
              [
                -68.24594951159398,
                -10.960231894797069
              ],
              [
                -68.24851200054391,
                -10.96144190068087
              ],
              [
                -68.25013601628142,
                -10.963477393293642
              ],
              [
                -68.25699745069306,
                -10.972077366000223
              ],
              [
                -68.26030491261552,
                -10.974146868574667
              ],
              [
                -68.26378192719827,
                -10.975442886864306
              ],
              [
                -68.26849390229509,
                -10.977707894389608
              ],
              [
                -68.26882490147585,
                -10.977866864497322
              ],
              [
                -68.27170488927418,
                -10.978913376481628
              ],
              [
                -68.27311087038409,
                -10.981286878665149
              ],
              [
                -68.27541533353086,
                -10.985178357770959
              ],
              [
                -68.27797082212818,
                -10.989280338684907
              ],
              [
                -68.2829847810294,
                -10.98983484408879
              ],
              [
                -68.28905124836665,
                -10.988097881519097
              ],
              [
                -68.2939977318592,
                -10.989579386925698
              ],
              [
                -68.29878021146685,
                -10.990332876294147
              ],
              [
                -68.30374119220544,
                -10.991114374902386
              ],
              [
                -68.30769416720257,
                -10.99209838804935
              ],
              [
                -68.31122063207991,
                -10.994198399963498
              ],
              [
                -68.31265164894754,
                -10.99503289998431
              ],
              [
                -68.31998210429144,
                -10.999307911098098
              ],
              [
                -68.32577253410044,
                -11.00926388683832
              ],
              [
                -68.33211002408446,
                -11.012427369612807
              ],
              [
                -68.33477249511932,
                -11.011218370209034
              ],
              [
                -68.33633900937497,
                -11.007232899391441
              ],
              [
                -68.33915648803239,
                -11.006380898164222
              ],
              [
                -68.34324996355538,
                -11.00514292645645
              ],
              [
                -68.34863595394309,
                -11.0045509331074
              ],
              [
                -68.3538639029926,
                -11.003995442356805
              ],
              [
                -68.36053436443726,
                -11.006647441328191
              ],
              [
                -68.36977684719939,
                -11.010847446221113
              ],
              [
                -68.37577880068741,
                -11.013517949933396
              ],
              [
                -68.38431925810735,
                -11.017491454269207
              ],
              [
                -68.38687124096344,
                -11.020937442305568
              ],
              [
                -68.39079820365428,
                -11.028582434919835
              ],
              [
                -68.39029019378678,
                -11.029754419380104
              ],
              [
                -68.387789726427,
                -11.03552788301981
              ],
              [
                -68.3891172018174,
                -11.042208887868867
              ],
              [
                -68.39273815598096,
                -11.047136370324525
              ],
              [
                -68.39583714441204,
                -11.050530332552587
              ],
              [
                -68.40291060304702,
                -11.051042356933708
              ],
              [
                -68.40995457951652,
                -11.047989885225421
              ],
              [
                -68.41316055728453,
                -11.042954897582176
              ],
              [
                -68.4139780514963,
                -11.041912916283563
              ],
              [
                -68.41529707560761,
                -11.040233438863579
              ],
              [
                -68.41963202365724,
                -11.040822919985105
              ],
              [
                -68.42260553228809,
                -11.041227432184218
              ],
              [
                -68.42416001856971,
                -11.039949932957198
              ],
              [
                -68.42502504094529,
                -11.039238442727767
              ],
              [
                -68.42789651971636,
                -11.033418966873452
              ],
              [
                -68.43189699209118,
                -11.031455973225931
              ],
              [
                -68.43538297322583,
                -11.034612488901038
              ],
              [
                -68.43897245826871,
                -11.039164955481985
              ],
              [
                -68.43969443462412,
                -11.039880488605485
              ],
              [
                -68.44140542561831,
                -11.041575449531624
              ],
              [
                -68.44387890447902,
                -11.044680444525595
              ],
              [
                -68.45597837250298,
                -11.045027950619374
              ],
              [
                -68.46056083111944,
                -11.045793489664543
              ],
              [
                -68.46407781234153,
                -11.048298964422898
              ],
              [
                -68.46926278664051,
                -11.048302982911242
              ],
              [
                -68.47111677143717,
                -11.04869298711288
              ],
              [
                -68.47238925912194,
                -11.04896001462616
              ],
              [
                -68.48023874480407,
                -11.049738009627264
              ],
              [
                -68.48736219392696,
                -11.050454519488875
              ],
              [
                -68.49917713797721,
                -11.059505989446674
              ],
              [
                -68.5015455897799,
                -11.061838989654865
              ],
              [
                -68.5047291011281,
                -11.064030977792955
              ],
              [
                -68.50591957099972,
                -11.06812097847137
              ],
              [
                -68.50703205672788,
                -11.071943470827998
              ],
              [
                -68.50938752922536,
                -11.075530459191414
              ],
              [
                -68.51593598078557,
                -11.083259939613459
              ],
              [
                -68.51899249394546,
                -11.088509427205334
              ],
              [
                -68.52586494176487,
                -11.088952943495446
              ],
              [
                -68.52704391368596,
                -11.08932544552791
              ],
              [
                -68.53355990409474,
                -11.091382959241157
              ],
              [
                -68.53900737616712,
                -11.094612957417938
              ],
              [
                -68.53918086893096,
                -11.09484893801784
              ],
              [
                -68.54231284562809,
                -11.0991014568215
              ],
              [
                -68.54281633809471,
                -11.108756412327216
              ],
              [
                -68.7156129089511,
                -11.143103615480895
              ],
              [
                -68.71089546908584,
                -11.128996654291695
              ],
              [
                -68.71435348035659,
                -11.124284652900208
              ],
              [
                -68.72240647468476,
                -11.113308720891375
              ],
              [
                -68.72094845754009,
                -11.103135229898223
              ],
              [
                -68.72871994663996,
                -11.097699761638463
              ],
              [
                -68.73407442883223,
                -11.09271429055561
              ],
              [
                -68.74036839780753,
                -11.095648789309005
              ],
              [
                -68.74183940585476,
                -11.096335310951622
              ],
              [
                -68.74731835627301,
                -11.0919968281373
              ],
              [
                -68.75129234988701,
                -11.086394365355174
              ],
              [
                -68.7572003203728,
                -11.084171372944052
              ],
              [
                -68.756324355259,
                -11.077855374373001
              ],
              [
                -68.7590193446818,
                -11.074354910890575
              ],
              [
                -68.76441881822188,
                -11.066961934120744
              ],
              [
                -68.76299933087726,
                -11.061530438890184
              ],
              [
                -68.75992387204887,
                -11.056610473078475
              ],
              [
                -68.75987688726696,
                -11.056607451001248
              ],
              [
                -68.75571039166115,
                -11.056310468239197
              ],
              [
                -68.76020587915546,
                -11.054284457615466
              ],
              [
                -68.76209787520938,
                -11.053431955664779
              ],
              [
                -68.76510534242286,
                -11.049286507698824
              ],
              [
                -68.75986540149412,
                -11.04057701436523
              ],
              [
                -68.75893340266511,
                -11.040067500193043
              ],
              [
                -68.75124446133337,
                -11.035861527787652
              ],
              [
                -68.75102044977334,
                -11.031910020316301
              ],
              [
                -68.75051545182647,
                -11.02777455088798
              ],
              [
                -68.75068345742436,
                -11.023857038036839
              ],
              [
                -68.74854099240966,
                -11.019969538863926
              ],
              [
                -68.75129400386285,
                -11.013094593694102
              ],
              [
                -68.75235600603385,
                -11.01044358665498
              ],
              [
                -68.75643097720686,
                -11.010127113070853
              ],
              [
                -68.75758845846222,
                -11.005067113488913
              ],
              [
                -68.75780398527016,
                -11.000914653440741
              ],
              [
                -68.76993041500084,
                -11.00650266273324
              ],
              [
                -68.77280639887493,
                -11.006040632392398
              ],
              [
                -68.77891138114073,
                -11.005059159529155
              ],
              [
                -68.77941138903358,
                -11.00497863968736
              ],
              [
                -68.77944435423055,
                -11.004794179304817
              ],
              [
                -68.78024739510143,
                -11.00026065946768
              ],
              [
                -68.7834518614762,
                -11.002477685633677
              ],
              [
                -68.78370933894529,
                -11.002674196511263
              ],
              [
                -68.78797583956951,
                -11.005934680474118
              ],
              [
                -68.78970981889637,
                -11.002651704558033
              ],
              [
                -68.79548327780674,
                -10.99946618973635
              ],
              [
                -68.79554732256172,
                -10.99944268592443
              ],
              [
                -68.79835927980706,
                -10.998409714185746
              ],
              [
                -68.79614329661008,
                -10.995312736480773
              ],
              [
                -68.79159232314416,
                -10.991578732434617
              ],
              [
                -68.798325795509,
                -10.991175729959735
              ],
              [
                -68.80176276098211,
                -10.99492273493371
              ],
              [
                -68.81110672897383,
                -10.995378768645278
              ],
              [
                -68.80601724377003,
                -10.999201214805888
              ],
              [
                -68.80394926718937,
                -11.00075422833754
              ],
              [
                -68.81208171920149,
                -11.001448240124455
              ],
              [
                -68.81601870865045,
                -11.005605208797505
              ],
              [
                -68.823242149973,
                -11.00352823627922
              ],
              [
                -68.82875763093685,
                -11.007351749857571
              ],
              [
                -68.8305671190979,
                -11.000783783953825
              ],
              [
                -68.82746863496008,
                -10.998705748544905
              ],
              [
                -68.82609014890693,
                -10.997781263808678
              ],
              [
                -68.83413461117641,
                -10.99429728502121
              ],
              [
                -68.83880159389857,
                -10.99923127269827
              ],
              [
                -68.84457758387997,
                -10.998695275881323
              ],
              [
                -68.84111308193079,
                -10.996858773071686
              ],
              [
                -68.84248909314996,
                -10.993967292295686
              ],
              [
                -68.84752556188879,
                -10.99373933219411
              ],
              [
                -68.8500390539479,
                -10.995137319583671
              ],
              [
                -68.85510051205347,
                -11.00105829502857
              ],
              [
                -68.85956149490863,
                -11.006420285068991
              ],
              [
                -68.8597394923796,
                -11.008406802421787
              ],
              [
                -68.86013096338915,
                -11.01279027096037
              ],
              [
                -68.86358194835675,
                -11.016249253888558
              ],
              [
                -68.86978643164645,
                -11.015067772811308
              ],
              [
                -68.87165443461701,
                -11.012736303239238
              ],
              [
                -68.87409142835767,
                -11.00969581683897
              ],
              [
                -68.8823418570338,
                -11.009803313632345
              ],
              [
                -68.88426586454237,
                -11.013444292226264
              ],
              [
                -68.88441784731968,
                -11.013731302643224
              ],
              [
                -68.88161385751224,
                -11.01715030623895
              ],
              [
                -68.88759083518417,
                -11.017917284895896
              ],
              [
                -68.88878383395542,
                -11.013585317084058
              ],
              [
                -68.88942783344733,
                -11.011248324278673
              ],
              [
                -68.89544679024701,
                -11.006524847483469
              ],
              [
                -68.89822878612,
                -11.008985349197186
              ],
              [
                -68.90289827772851,
                -11.013458331337656
              ],
              [
                -68.90343725820779,
                -11.014042854572729
              ],
              [
                -68.90745573796329,
                -11.018397829883735
              ],
              [
                -68.91422771790974,
                -11.018727846315217
              ],
              [
                -68.92122119549556,
                -11.014466882907296
              ],
              [
                -68.92426265163479,
                -11.017765860603015
              ],
              [
                -68.92905764084473,
                -11.012578878930606
              ],
              [
                -68.9305186277192,
                -11.010749893086485
              ],
              [
                -68.93509813692665,
                -11.00501391465731
              ],
              [
                -68.93779061318546,
                -11.00695993476386
              ],
              [
                -68.93911209982981,
                -11.007717940916313
              ],
              [
                -68.94267657098847,
                -11.00976344532683
              ],
              [
                -68.94723304065661,
                -11.012362420903386
              ],
              [
                -68.94751707875703,
                -11.0084359451725
              ],
              [
                -68.94766758196687,
                -11.004768946421835
              ],
              [
                -68.95505054372056,
                -11.00216896972893
              ],
              [
                -68.95455403648617,
                -10.997618990433612
              ],
              [
                -68.95440153479295,
                -10.996220980337919
              ],
              [
                -68.9519455772842,
                -10.990636493557282
              ],
              [
                -68.95715604155407,
                -10.991892524334274
              ],
              [
                -68.96054502537358,
                -10.992948000379547
              ],
              [
                -68.97170848681242,
                -10.98894904477523
              ],
              [
                -68.97617295040352,
                -10.992359546749558
              ],
              [
                -68.9813934050725,
                -10.997121024078169
              ],
              [
                -68.98764990120988,
                -10.998094046631637
              ],
              [
                -68.98753591341351,
                -10.998790552342392
              ],
              [
                -68.98703289188886,
                -11.00186902322622
              ],
              [
                -68.98409736516909,
                -11.008724499371956
              ],
              [
                -68.98931085620342,
                -11.010110492793848
              ],
              [
                -68.99423334231652,
                -11.010554011497653
              ],
              [
                -68.99500734927702,
                -11.003405519253059
              ],
              [
                -68.99859081024277,
                -11.004994020080437
              ],
              [
                -69.00147181794202,
                -11.006270519553103
              ],
              [
                -69.00746577669963,
                -11.005952041492815
              ],
              [
                -69.01311376672228,
                -11.004723056513106
              ],
              [
                -69.0146592637657,
                -11.001016067400542
              ],
              [
                -69.01106177951138,
                -10.99587957612971
              ],
              [
                -69.01070078109294,
                -10.995365094003319
              ],
              [
                -69.01186726924256,
                -10.991436106862295
              ],
              [
                -69.01917973046892,
                -10.991459636182679
              ],
              [
                -69.02178324580228,
                -10.991468104925135
              ],
              [
                -69.02484171186798,
                -10.990001653898664
              ],
              [
                -69.0255052286243,
                -10.988015645035508
              ],
              [
                -69.02709422443273,
                -10.98325965212669
              ],
              [
                -69.031753199053,
                -10.984613638616155
              ],
              [
                -69.03319667480895,
                -10.985033676006031
              ],
              [
                -69.03488920522759,
                -10.98290618144582
              ],
              [
                -69.03581420226989,
                -10.981743657604005
              ],
              [
                -69.03878417639295,
                -10.976725175997805
              ],
              [
                -69.0433436757375,
                -10.979536710958993
              ],
              [
                -69.04584663052401,
                -10.979549212501318
              ],
              [
                -69.04694212161641,
                -10.979554697489387
              ],
              [
                -69.04727312480856,
                -10.979196222163091
              ],
              [
                -69.0509411508715,
                -10.975215718732372
              ],
              [
                -69.05367060718302,
                -10.977611719587868
              ],
              [
                -69.05947858267373,
                -10.982709200037156
              ],
              [
                -69.06471656117387,
                -10.977960258759659
              ],
              [
                -69.06794254400593,
                -10.974095256165926
              ],
              [
                -69.06795405099294,
                -10.97407476524798
              ],
              [
                -69.06939155535792,
                -10.967782290608316
              ],
              [
                -69.07262754217766,
                -10.966034785725874
              ],
              [
                -69.07498801108098,
                -10.97006979503171
              ],
              [
                -69.07715551864334,
                -10.973144276847428
              ],
              [
                -69.07803699736448,
                -10.974394776739553
              ],
              [
                -69.08030499923098,
                -10.973331291051888
              ],
              [
                -69.08141049126694,
                -10.972812782616346
              ],
              [
                -69.08631147947793,
                -10.970096291659457
              ],
              [
                -69.09277543217935,
                -10.974072789659449
              ],
              [
                -69.09491091918667,
                -10.975387287659514
              ],
              [
                -69.10175540076756,
                -10.97959181469331
              ],
              [
                -69.10475638939043,
                -10.974785795461381
              ],
              [
                -69.10653035480198,
                -10.971944817146001
              ],
              [
                -69.10997784524365,
                -10.971508855747434
              ],
              [
                -69.11291184281974,
                -10.972757837405936
              ],
              [
                -69.1138728162126,
                -10.97346333506937
              ],
              [
                -69.1170513146661,
                -10.9757973469567
              ],
              [
                -69.12074780153085,
                -10.975613841266417
              ],
              [
                -69.12163378575993,
                -10.971783835254577
              ],
              [
                -69.1228203120763,
                -10.966656885642829
              ],
              [
                -69.12997325297454,
                -10.969978894867142
              ],
              [
                -69.13320724475076,
                -10.971480364329588
              ],
              [
                -69.13690622837586,
                -10.971957894681378
              ],
              [
                -69.1377652395268,
                -10.968767899713235
              ],
              [
                -69.13769223871388,
                -10.968307905943906
              ],
              [
                -69.13722824926583,
                -10.965379889779857
              ],
              [
                -69.14014423178612,
                -10.96777691200375
              ],
              [
                -69.14255372034123,
                -10.969758411536024
              ],
              [
                -69.14793015751852,
                -10.970760894401145
              ],
              [
                -69.1501051860872,
                -10.9684909291082
              ],
              [
                -69.14768520425835,
                -10.966144908635352
              ],
              [
                -69.1449771912364,
                -10.963518906468684
              ],
              [
                -69.1541466584059,
                -10.962451934397894
              ],
              [
                -69.15301865912447,
                -10.957806964104245
              ],
              [
                -69.15921862873643,
                -10.962500959526702
              ],
              [
                -69.1600596402337,
                -10.962906467863073
              ],
              [
                -69.16321411634438,
                -10.964427969124603
              ],
              [
                -69.16800710482268,
                -10.962929471328316
              ],
              [
                -69.1749835804114,
                -10.958856992143618
              ],
              [
                -69.18135304304309,
                -10.955138528794848
              ],
              [
                -69.18468155144417,
                -10.94915205259816
              ],
              [
                -69.18901651968855,
                -10.948875034336943
              ],
              [
                -69.19716698547221,
                -10.948868073246734
              ],
              [
                -69.20087196200423,
                -10.948869583010822
              ],
              [
                -69.20377893615597,
                -10.95043605868938
              ],
              [
                -69.20448143325527,
                -10.95085254374144
              ],
              [
                -69.21232340672599,
                -10.955495558349867
              ],
              [
                -69.21512389060943,
                -10.956391565822404
              ],
              [
                -69.22003986313177,
                -10.949792088305898
              ],
              [
                -69.22176036643937,
                -10.9482171101027
              ],
              [
                -69.22281437156508,
                -10.947252095879378
              ],
              [
                -69.22664685547012,
                -10.944087623228594
              ],
              [
                -69.23250531395153,
                -10.940619656171163
              ],
              [
                -69.2354793233072,
                -10.945135135268831
              ],
              [
                -69.23638981733873,
                -10.946165614889892
              ],
              [
                -69.23957326647553,
                -10.9497656290808
              ],
              [
                -69.24283177267951,
                -10.950480623926468
              ],
              [
                -69.25144775086272,
                -10.947059671764892
              ],
              [
                -69.25536273053585,
                -10.945437671702313
              ],
              [
                -69.25617721855473,
                -10.945100658184348
              ],
              [
                -69.25073672646103,
                -10.943080664203467
              ],
              [
                -69.2500932295531,
                -10.940280174290713
              ],
              [
                -69.26215318599722,
                -10.940857178614332
              ],
              [
                -69.27164063520408,
                -10.944460219326226
              ],
              [
                -69.27317564073998,
                -10.946951174792222
              ],
              [
                -69.27408061198092,
                -10.948419683120257
              ],
              [
                -69.27080212229686,
                -10.951738192351131
              ],
              [
                -69.27453160820822,
                -10.953639700570049
              ],
              [
                -69.27884109873528,
                -10.947432683728769
              ],
              [
                -69.28132858442267,
                -10.943848737829573
              ],
              [
                -69.28581506724775,
                -10.944544208980009
              ],
              [
                -69.29024004453024,
                -10.947813722443309
              ],
              [
                -69.290747024584,
                -10.948188225267906
              ],
              [
                -69.29424553783711,
                -10.947790211834725
              ],
              [
                -69.29791552236964,
                -10.947372739772057
              ],
              [
                -69.30403898858877,
                -10.947732754907632
              ],
              [
                -69.30436548210719,
                -10.94775224844723
              ],
              [
                -69.3044249648841,
                -10.947730758619182
              ],
              [
                -69.30981395368401,
                -10.945781270203184
              ],
              [
                -69.32027290544629,
                -10.942556810798077
              ],
              [
                -69.3241794119363,
                -10.938939795565771
              ],
              [
                -69.32618840133676,
                -10.943062292124477
              ],
              [
                -69.32972837622026,
                -10.947582312320003
              ],
              [
                -69.33000834631378,
                -10.947939287783653
              ],
              [
                -69.33125283849641,
                -10.947573313134011
              ],
              [
                -69.33419683210272,
                -10.946706816061365
              ],
              [
                -69.33670834753757,
                -10.941077821179924
              ],
              [
                -69.33942830453122,
                -10.944808340199751
              ],
              [
                -69.34065382584325,
                -10.947089314745591
              ],
              [
                -69.34330677187187,
                -10.95202529711401
              ],
              [
                -69.3471152881712,
                -10.947151332379647
              ],
              [
                -69.34696030505276,
                -10.94651233597096
              ],
              [
                -69.34606930087473,
                -10.942835330131006
              ],
              [
                -69.3450733014864,
                -10.93951735004811
              ],
              [
                -69.3559682513355,
                -10.937922380637598
              ],
              [
                -69.35662975414495,
                -10.94340836375593
              ],
              [
                -69.37213817197853,
                -10.940034377998066
              ],
              [
                -69.37306166552787,
                -10.943438884710357
              ],
              [
                -69.37323817053525,
                -10.944086890134496
              ],
              [
                -69.37318167156562,
                -10.947407350303306
              ],
              [
                -69.3769761353997,
                -10.951899341088904
              ],
              [
                -69.37805662081053,
                -10.945398396104402
              ],
              [
                -69.37885565624083,
                -10.94168340444698
              ],
              [
                -69.3788656322811,
                -10.94163838459708
              ],
              [
                -69.37899662469826,
                -10.941640888829241
              ],
              [
                -69.3846061067458,
                -10.941746908774835
              ],
              [
                -69.38622861388824,
                -10.93944941446881
              ],
              [
                -69.38675712495565,
                -10.938701923623778
              ],
              [
                -69.38787258844576,
                -10.933672923389624
              ],
              [
                -69.3945630902628,
                -10.93456544100135
              ],
              [
                -69.39362709644678,
                -10.937207448556576
              ],
              [
                -69.39164859171885,
                -10.942791901592882
              ],
              [
                -69.39455805350127,
                -10.943530913750665
              ],
              [
                -69.3975880538389,
                -10.938883920890133
              ],
              [
                -69.39885758034404,
                -10.935622951754008
              ],
              [
                -69.39996405178563,
                -10.932779464098033
              ],
              [
                -69.40269605118526,
                -10.9314719819161
              ],
              [
                -69.40471052485597,
                -10.933848978641352
              ],
              [
                -69.41053197333157,
                -10.940719453186734
              ],
              [
                -69.41342249364259,
                -10.938021477360724
              ],
              [
                -69.41446948405446,
                -10.933198989376137
              ],
              [
                -69.41470148903319,
                -10.932129499927713
              ],
              [
                -69.41574097455515,
                -10.9277979824948
              ],
              [
                -69.41593199620384,
                -10.927001013174644
              ],
              [
                -69.41677950657095,
                -10.926946512881992
              ],
              [
                -69.42351096372194,
                -10.926513034444092
              ],
              [
                -69.42630443759114,
                -10.933367982510642
              ],
              [
                -69.4286639179317,
                -10.936715483042194
              ],
              [
                -69.43539188283859,
                -10.934291025423565
              ],
              [
                -69.45006381688994,
                -10.936084037011469
              ],
              [
                -69.45225231920833,
                -10.940382532471954
              ],
              [
                -69.45306779901094,
                -10.943909994674227
              ],
              [
                -69.45332177983755,
                -10.945006989939474
              ],
              [
                -69.45523676802719,
                -10.948946020957578
              ],
              [
                -69.45953373693932,
                -10.945308020429506
              ],
              [
                -69.4595467509018,
                -10.94529752348392
              ],
              [
                -69.45955324384884,
                -10.945310526457886
              ],
              [
                -69.46121372084782,
                -10.94888249808442
              ],
              [
                -69.46525072733162,
                -10.94981951274445
              ],
              [
                -69.47247368567747,
                -10.94709203291437
              ],
              [
                -69.47425169111673,
                -10.946421049123591
              ],
              [
                -69.47465315853022,
                -10.947392548028722
              ],
              [
                -69.4770391731903,
                -10.953177542550714
              ],
              [
                -69.48020662901699,
                -10.95437552286494
              ],
              [
                -69.4839481520746,
                -10.950907542812853
              ],
              [
                -69.48825362729961,
                -10.950295053480133
              ],
              [
                -69.4915996191645,
                -10.949991574490786
              ],
              [
                -69.49597355533066,
                -10.949253059649184
              ],
              [
                -69.49761109829245,
                -10.94543658574992
              ],
              [
                -69.4979180942879,
                -10.944719590397744
              ],
              [
                -69.50091756089643,
                -10.937363638288074
              ],
              [
                -69.50749702933935,
                -10.940918125986993
              ],
              [
                -69.51247002649697,
                -10.938786614285023
              ],
              [
                -69.51741701095662,
                -10.935489162922293
              ],
              [
                -69.52590597961061,
                -10.935536149314958
              ],
              [
                -69.53090344262822,
                -10.93556518637314
              ],
              [
                -69.53856738643594,
                -10.935870694507564
              ],
              [
                -69.54177585939888,
                -10.937453177784153
              ],
              [
                -69.5425068475499,
                -10.943131175642135
              ],
              [
                -69.54259437989016,
                -10.943811653827629
              ],
              [
                -69.54174286227227,
                -10.950397144159414
              ],
              [
                -69.54633833468272,
                -10.95169563881263
              ],
              [
                -69.55098683452896,
                -10.95104117622821
              ],
              [
                -69.55537082668785,
                -10.945509679298803
              ],
              [
                -69.55961380429396,
                -10.944868189929517
              ],
              [
                -69.56055678250445,
                -10.944685201186953
              ],
              [
                -69.56287927916969,
                -10.944235680743374
              ],
              [
                -69.56708275719146,
                -10.943467698667204
              ],
              [
                -69.57232721833546,
                -10.942870735001083
              ],
              [
                -69.58496518220757,
                -10.941687764570762
              ],
              [
                -69.5853006494968,
                -10.945548737007876
              ],
              [
                -69.58533714754431,
                -10.945971247851714
              ],
              [
                -69.58806665620602,
                -10.945645223795758
              ],
              [
                -69.59163063740195,
                -10.945220246554683
              ],
              [
                -69.60202308519425,
                -10.94187628126519
              ],
              [
                -69.6120020506872,
                -10.946595259902884
              ],
              [
                -69.612271024611,
                -10.950271277772508
              ],
              [
                -69.61262952185339,
                -10.955165269289319
              ],
              [
                -69.61715099572628,
                -10.952158275266985
              ],
              [
                -69.61811701237234,
                -10.951694778376945
              ],
              [
                -69.62086350783588,
                -10.950377772216159
              ],
              [
                -69.62574846466488,
                -10.952409279305924
              ],
              [
                -69.62730244743608,
                -10.953173798948251
              ],
              [
                -69.6311924528382,
                -10.95508776755787
              ],
              [
                -69.6354894125491,
                -10.956956290048291
              ],
              [
                -69.64496436506559,
                -10.954873327437303
              ],
              [
                -69.65258333493641,
                -10.95319784624289
              ],
              [
                -69.65913129727659,
                -10.952169834215468
              ],
              [
                -69.66419176663149,
                -10.956722841381477
              ],
              [
                -69.66978624275431,
                -10.961755310157969
              ],
              [
                -69.67588973653996,
                -10.960468822065355
              ],
              [
                -69.67826022399964,
                -10.960042329644079
              ],
              [
                -69.679181195293,
                -10.959876836810878
              ],
              [
                -69.68085719840418,
                -10.960715857473422
              ],
              [
                -69.68808566707045,
                -10.964334834336674
              ],
              [
                -69.6913791403353,
                -10.963469874871887
              ],
              [
                -69.69845410486326,
                -10.971242849529814
              ],
              [
                -69.70106210444155,
                -10.96595735550704
              ],
              [
                -69.70115607538936,
                -10.965767360225518
              ],
              [
                -69.70212907326965,
                -10.966234370671158
              ],
              [
                -69.71085204237731,
                -10.970420386676773
              ],
              [
                -69.71558802601291,
                -10.972401863693792
              ],
              [
                -69.71947649706321,
                -10.969751885445836
              ],
              [
                -69.72281100927978,
                -10.965639418374218
              ],
              [
                -69.73355691554275,
                -10.97198591591932
              ],
              [
                -69.7369494155506,
                -10.97422891672696
              ],
              [
                -69.74094837951822,
                -10.972437915212877
              ],
              [
                -69.74112187984932,
                -10.968674416317931
              ],
              [
                -69.74008191777065,
                -10.963682443716797
              ],
              [
                -69.74644140266112,
                -10.961787942829876
              ],
              [
                -69.74728339797522,
                -10.958078485008619
              ],
              [
                -69.74833738011604,
                -10.95343747524762
              ],
              [
                -69.7514658847528,
                -10.95011400751689
              ],
              [
                -69.76232632528279,
                -10.94491806033448
              ],
              [
                -69.77368532782778,
                -10.938569092355
              ],
              [
                -69.77196781734334,
                -10.934280106493643
              ],
              [
                -69.78145528135781,
                -10.934151092251513
              ],
              [
                -69.78859824672368,
                -10.931276133573533
              ],
              [
                -69.79324070967584,
                -10.931215137255336
              ],
              [
                -69.796511194283,
                -10.930784156481899
              ],
              [
                -69.8008981817666,
                -10.92623717953027
              ],
              [
                -69.81132417432046,
                -10.92527418038314
              ],
              [
                -69.81445763892195,
                -10.929965700710358
              ],
              [
                -69.81615961487023,
                -10.930321176813358
              ],
              [
                -69.82200210723371,
                -10.931541675033715
              ],
              [
                -69.83317705453575,
                -10.932732194118723
              ],
              [
                -69.8330050413793,
                -10.928950721601232
              ],
              [
                -69.83296257232736,
                -10.928010729207775
              ],
              [
                -69.83945453397368,
                -10.928655225265027
              ],
              [
                -69.84174900012763,
                -10.9254047197487
              ],
              [
                -69.85333496349284,
                -10.924567747365597
              ],
              [
                -69.85538442498012,
                -10.928103241036485
              ],
              [
                -69.85632894483003,
                -10.929732756120526
              ],
              [
                -69.85935740940818,
                -10.9320252311362
              ],
              [
                -69.86134139639314,
                -10.92852126176327
              ],
              [
                -69.86215991056373,
                -10.927607279869378
              ],
              [
                -69.86353339349724,
                -10.926071781764373
              ],
              [
                -69.86663990400085,
                -10.926628278871373
              ],
              [
                -69.86737238149428,
                -10.926806763705128
              ],
              [
                -69.87495534420063,
                -10.928652796344641
              ],
              [
                -69.87892785580183,
                -10.925032297939726
              ],
              [
                -69.88014685461195,
                -10.92392079533982
              ],
              [
                -69.88495232056617,
                -10.922988822800194
              ],
              [
                -69.89094379398193,
                -10.92174335232518
              ],
              [
                -69.89988773448744,
                -10.920043881549917
              ],
              [
                -69.90469671705264,
                -10.92091884787186
              ],
              [
                -69.91466166019326,
                -10.922935873397735
              ],
              [
                -69.9155726582086,
                -10.92509236293738
              ],
              [
                -69.9158721605814,
                -10.925802387468307
              ],
              [
                -69.91743915714775,
                -10.930947857718866
              ],
              [
                -69.92109715932426,
                -10.930956859403013
              ],
              [
                -69.92362462131445,
                -10.9283423714658
              ],
              [
                -69.92534464783014,
                -10.925707893103723
              ],
              [
                -69.92602760884536,
                -10.924660893330179
              ],
              [
                -69.93151861230693,
                -10.922434392720378
              ],
              [
                -69.93509108974007,
                -10.920909892597896
              ],
              [
                -69.93902955408544,
                -10.922853900297511
              ],
              [
                -69.94656154817493,
                -10.92466241697381
              ],
              [
                -69.95049752377253,
                -10.925948428542878
              ],
              [
                -69.95413599383821,
                -10.927137443843469
              ],
              [
                -69.95582196211825,
                -10.931100900698041
              ],
              [
                -69.9562584904112,
                -10.93207141174758
              ],
              [
                -69.95734195020846,
                -10.934480902905813
              ],
              [
                -69.96032094315291,
                -10.934588904067368
              ],
              [
                -69.96214745014927,
                -10.934654939232711
              ],
              [
                -69.96168944665617,
                -10.935436425177553
              ],
              [
                -69.95924393036495,
                -10.939607374869109
              ],
              [
                -69.96393244598731,
                -10.939687893250486
              ],
              [
                -69.96770289124434,
                -10.942069916428537
              ],
              [
                -69.97067438420014,
                -10.944849389017348
              ],
              [
                -69.97432885545632,
                -10.947498417234373
              ],
              [
                -69.97786234806318,
                -10.945456898504906
              ],
              [
                -69.97846083913308,
                -10.945111412582243
              ],
              [
                -69.9821973403709,
                -10.942801911981979
              ],
              [
                -69.98486733213682,
                -10.945313422782634
              ],
              [
                -69.9851073065092,
                -10.94987890731106
              ],
              [
                -69.98512781068285,
                -10.950270888240226
              ],
              [
                -69.98720831161206,
                -10.95351239589983
              ],
              [
                -69.99071927403612,
                -10.953217931188801
              ],
              [
                -69.99494223850577,
                -10.952864427953747
              ],
              [
                -69.9963932242282,
                -10.956594413082668
              ],
              [
                -69.99676574082015,
                -10.95755142206419
              ],
              [
                -69.99873172728236,
                -10.962893389592521
              ],
              [
                -70.00284121622123,
                -10.960431399108744
              ],
              [
                -70.00369172551825,
                -10.959921894112657
              ],
              [
                -70.00761218825,
                -10.962777894265074
              ],
              [
                -70.01043316756494,
                -10.964653885163473
              ],
              [
                -70.01443865829332,
                -10.967318423020872
              ],
              [
                -70.01953862663123,
                -10.968753429366313
              ],
              [
                -70.02140260251664,
                -10.969331891168528
              ],
              [
                -70.0257420983023,
                -10.970676897370787
              ],
              [
                -70.0260500747757,
                -10.971313435143923
              ],
              [
                -70.02852105450987,
                -10.976416384316568
              ],
              [
                -70.03194757365318,
                -10.973827924925287
              ],
              [
                -70.03308154339122,
                -10.972971406428162
              ],
              [
                -70.03692303402923,
                -10.970235429214316
              ],
              [
                -70.03759350933466,
                -10.974373427794282
              ],
              [
                -70.03783650357843,
                -10.976338925706736
              ],
              [
                -70.03802052398262,
                -10.977823931864153
              ],
              [
                -70.04153398569775,
                -10.977915434084414
              ],
              [
                -70.04376248800422,
                -10.977973406030053
              ],
              [
                -70.04727095247203,
                -10.979267904855936
              ],
              [
                -70.04864846059006,
                -10.980304920478046
              ],
              [
                -70.05012894411901,
                -10.981419419072708
              ],
              [
                -70.0529884225972,
                -10.985244442587934
              ],
              [
                -70.05700542541737,
                -10.985726924643284
              ],
              [
                -70.06497786205713,
                -10.986307425723526
              ],
              [
                -70.06500438116288,
                -10.990950920723378
              ],
              [
                -70.07082284987192,
                -10.992162418063026
              ],
              [
                -70.07873583075977,
                -10.989583927914548
              ],
              [
                -70.07916730594114,
                -10.989036951246954
              ],
              [
                -70.08164581624253,
                -10.98589294164968
              ],
              [
                -70.08675878983446,
                -10.988777466990484
              ],
              [
                -70.09362325285147,
                -10.988521452382251
              ],
              [
                -70.09660974200725,
                -10.9927644689957
              ],
              [
                -70.0983767216243,
                -10.996885958702949
              ],
              [
                -70.09865419320882,
                -10.99753298040164
              ],
              [
                -70.10014120146609,
                -10.997723953614026
              ],
              [
                -70.10479618436064,
                -10.998321975540078
              ],
              [
                -70.1049706725355,
                -10.994135988594444
              ],
              [
                -70.10892714685167,
                -10.995039967370374
              ],
              [
                -70.10841613752335,
                -11.001652948897735
              ],
              [
                -70.10836465147338,
                -11.002323957922782
              ],
              [
                -70.11122511846061,
                -11.003243959634364
              ],
              [
                -70.11226013233455,
                -11.00360645153283
              ],
              [
                -70.11713411119544,
                -11.005314475763186
              ],
              [
                -70.11616961391766,
                -11.006588444412763
              ],
              [
                -70.11474109946802,
                -11.008475471487564
              ],
              [
                -70.11978058420051,
                -11.010404446411554
              ],
              [
                -70.12347205104645,
                -11.012158954333007
              ],
              [
                -70.12717153285301,
                -11.013916963262188
              ],
              [
                -70.12880404347572,
                -11.016225945826857
              ],
              [
                -70.13011050436808,
                -11.018074962075714
              ],
              [
                -70.13302199661896,
                -11.020852435703139
              ],
              [
                -70.1349730081066,
                -11.020931958094524
              ],
              [
                -70.13933847206309,
                -11.021109465684466
              ],
              [
                -70.14028048657534,
                -11.023981450405936
              ],
              [
                -70.14057445161666,
                -11.024876435450501
              ],
              [
                -70.14315293987258,
                -11.029455947439272
              ],
              [
                -70.14997041843041,
                -11.031686953850404
              ],
              [
                -70.15649836141044,
                -11.033825443988922
              ],
              [
                -70.16058734498499,
                -11.039568428557534
              ],
              [
                -70.16375133015663,
                -11.038665441946298
              ],
              [
                -70.16866631201958,
                -11.040294955525093
              ],
              [
                -70.17315627364911,
                -11.041427969701498
              ],
              [
                -70.17358278535377,
                -11.041149459269063
              ],
              [
                -70.17695128411873,
                -11.038952472641265
              ],
              [
                -70.18059476066651,
                -11.04308697488907
              ],
              [
                -70.18984119074462,
                -11.044333969585631
              ],
              [
                -70.19031521178626,
                -11.047450466793755
              ],
              [
                -70.19045070950884,
                -11.048342469563567
              ],
              [
                -70.20044012724593,
                -11.05335895217237
              ],
              [
                -70.21190960373903,
                -11.049415010447367
              ],
              [
                -70.21531506265782,
                -11.05179449916486
              ],
              [
                -70.22225954178967,
                -11.050326514147441
              ],
              [
                -70.22616302604642,
                -11.049314533210973
              ],
              [
                -70.22880601406514,
                -11.048629514023787
              ],
              [
                -70.22929149657158,
                -11.049375524855089
              ],
              [
                -70.23108901763804,
                -11.052136533532787
              ],
              [
                -70.23489098744105,
                -11.050018529476452
              ],
              [
                -70.2359314759775,
                -11.049505024834943
              ],
              [
                -70.23784449561057,
                -11.048560526010462
              ],
              [
                -70.24168547527104,
                -11.045906544510073
              ],
              [
                -70.24661395588323,
                -11.047669583777411
              ],
              [
                -70.252956911644,
                -11.04933854993811
              ],
              [
                -70.25796337089355,
                -11.05059658275734
              ],
              [
                -70.26432334065059,
                -11.050660583402726
              ],
              [
                -70.26738584380233,
                -11.054224564226976
              ],
              [
                -70.26879232946669,
                -11.055452060444127
              ],
              [
                -70.2696483043956,
                -11.056198087970857
              ],
              [
                -70.27361527720305,
                -11.057920081583825
              ],
              [
                -70.27693980080794,
                -11.057514071891912
              ],
              [
                -70.27793678625119,
                -11.057151100750861
              ],
              [
                -70.28044576629924,
                -11.056238585942557
              ],
              [
                -70.28665374657795,
                -11.057430082195559
              ],
              [
                -70.28826574335484,
                -11.059190105328273
              ],
              [
                -70.28929470882721,
                -11.060314580134197
              ],
              [
                -70.29294571312339,
                -11.063540070014271
              ],
              [
                -70.30626511397436,
                -11.068484105159246
              ],
              [
                -70.31142060492748,
                -11.069585613721694
              ],
              [
                -70.32098207637453,
                -11.066897124948861
              ],
              [
                -70.32357053175649,
                -11.066167118783213
              ],
              [
                -70.32666154927014,
                -11.065296633940443
              ],
              [
                -70.32734105329607,
                -11.06534063908454
              ],
              [
                -70.3340765118156,
                -11.065779176419454
              ],
              [
                -70.33810347640203,
                -11.065932175497998
              ],
              [
                -70.34403346960262,
                -11.066242187689125
              ],
              [
                -70.3501679388702,
                -11.064493685930938
              ],
              [
                -70.35608091465129,
                -11.058780214674576
              ],
              [
                -70.36122490992707,
                -11.059238208990166
              ],
              [
                -70.3648693783287,
                -11.059301249601882
              ],
              [
                -70.37000284996783,
                -11.059596243348059
              ],
              [
                -70.37444981986432,
                -11.060237751863797
              ],
              [
                -70.37983681976259,
                -11.057217282200398
              ],
              [
                -70.3804808238234,
                -11.052607282076798
              ],
              [
                -70.3805938418799,
                -11.051801788502463
              ],
              [
                -70.3862532945609,
                -11.047240818574862
              ],
              [
                -70.38939027580945,
                -11.04642380465645
              ],
              [
                -70.39381777747604,
                -11.045664325979503
              ],
              [
                -70.39907526293894,
                -11.044740838296192
              ],
              [
                -70.40077422971481,
                -11.045275840986024
              ],
              [
                -70.40484773600039,
                -11.04655733504256
              ],
              [
                -70.41083471200939,
                -11.042156373266087
              ],
              [
                -70.4137796986258,
                -11.041055376766046
              ],
              [
                -70.41887518544551,
                -11.039789370716726
              ],
              [
                -70.42284915447773,
                -11.038808390798353
              ],
              [
                -70.42625412738137,
                -11.038636417600987
              ],
              [
                -70.4320845978543,
                -11.039598407802535
              ],
              [
                -70.43726059795354,
                -11.039064906890493
              ],
              [
                -70.44126957816144,
                -11.033195920438542
              ],
              [
                -70.43866061017054,
                -11.02796246094613
              ],
              [
                -70.44082710893275,
                -11.021165471618605
              ],
              [
                -70.44728507586719,
                -11.007348016519208
              ],
              [
                -70.44896762039724,
                -11.003320555692474
              ],
              [
                -70.45009909975269,
                -11.000612045223884
              ],
              [
                -70.45266108547008,
                -10.996104093344785
              ],
              [
                -70.4655780698708,
                -10.987652602460164
              ],
              [
                -70.46623106183755,
                -10.987796118275295
              ],
              [
                -70.46970501970648,
                -10.988557113619441
              ],
              [
                -70.47288203542354,
                -10.983733154732787
              ],
              [
                -70.47474454033976,
                -10.980151646003764
              ],
              [
                -70.47606253657194,
                -10.977617186394554
              ],
              [
                -70.48030351832347,
                -10.97092618759252
              ],
              [
                -70.48740297519356,
                -10.964130224356126
              ],
              [
                -70.49061146317163,
                -10.963849749053738
              ],
              [
                -70.49221148908184,
                -10.9634967239782
              ],
              [
                -70.49441295770407,
                -10.963010746378329
              ],
              [
                -70.49933693668112,
                -10.958375790182224
              ],
              [
                -70.50436442712949,
                -10.953789805038268
              ],
              [
                -70.50697943260249,
                -10.951403814406271
              ],
              [
                -70.50840891963533,
                -10.950839810590875
              ],
              [
                -70.51521988998097,
                -10.948148319537589
              ],
              [
                -70.51762688923895,
                -10.94475436127907
              ],
              [
                -70.51832289847064,
                -10.943606830707525
              ],
              [
                -70.5202613843232,
                -10.940414368031153
              ],
              [
                -70.52316589336147,
                -10.938672358160687
              ],
              [
                -70.53104385384513,
                -10.934590877866729
              ],
              [
                -70.62157632191153,
                -10.999458342353398
              ],
              [
                -70.62220845940551,
                -10.92122409639807
              ],
              [
                -70.62254801728699,
                -10.879098220943119
              ],
              [
                -70.62389832337091,
                -10.711801218836662
              ],
              [
                -70.62540277164663,
                -10.457226985386937
              ],
              [
                -70.62595942029616,
                -10.362991311832662
              ],
              [
                -70.62518737434546,
                -10.076511205829865
              ],
              [
                -70.62196460249284,
                -9.96583854472216
              ],
              [
                -70.62184158423196,
                -9.961619520490064
              ],
              [
                -70.62184008851216,
                -9.959122529575769
              ],
              [
                -70.62184007052933,
                -9.959112557604062
              ],
              [
                -70.62201013205714,
                -9.930297122741093
              ],
              [
                -70.62201062507302,
                -9.930286612771805
              ],
              [
                -70.6226533160889,
                -9.82140398800148
              ],
              [
                -70.61650781767912,
                -9.81555397251589
              ],
              [
                -70.6097629075606,
                -9.803300497359789
              ],
              [
                -70.60673540804783,
                -9.800443532517525
              ],
              [
                -70.60175642516278,
                -9.79969501041771
              ],
              [
                -70.59833396982462,
                -9.800097016603186
              ],
              [
                -70.59685243814455,
                -9.800624500641671
              ],
              [
                -70.59188647497923,
                -9.802392474361904
              ],
              [
                -70.58908799071833,
                -9.795846985469858
              ],
              [
                -70.5885529860816,
                -9.79339600960372
              ],
              [
                -70.58756702279643,
                -9.788884539895275
              ],
              [
                -70.58581353234551,
                -9.783408032687811
              ],
              [
                -70.58186207191414,
                -9.779236056118156
              ],
              [
                -70.57852859049653,
                -9.777087041775333
              ],
              [
                -70.57153560415081,
                -9.781061508830083
              ],
              [
                -70.57046758861041,
                -9.781669035323603
              ],
              [
                -70.56099513695479,
                -9.787858472918591
              ],
              [
                -70.55140017840881,
                -9.780600478901707
              ],
              [
                -70.54673069959514,
                -9.777272498751218
              ],
              [
                -70.54428673478587,
                -9.77433349716195
              ],
              [
                -70.54261972796068,
                -9.771843508567242
              ],
              [
                -70.54087875344361,
                -9.769354531849826
              ],
              [
                -70.53776426398476,
                -9.764901526517733
              ],
              [
                -70.53680329927758,
                -9.758777046548976
              ],
              [
                -70.53763778767228,
                -9.753460066219287
              ],
              [
                -70.53783931277601,
                -9.752294559904403
              ],
              [
                -70.53884981131775,
                -9.746444070401797
              ],
              [
                -70.53581631721273,
                -9.745154602122074
              ],
              [
                -70.53314581032605,
                -9.744019585996371
              ],
              [
                -70.53324583064379,
                -9.738790609216169
              ],
              [
                -70.5334268544413,
                -9.73546312609587
              ],
              [
                -70.5340228304674,
                -9.732750596887207
              ],
              [
                -70.53416386094766,
                -9.732108134969272
              ],
              [
                -70.53393633638065,
                -9.731822138419203
              ],
              [
                -70.53169537249629,
                -9.729004130855632
              ],
              [
                -70.52776789246893,
                -9.720925656687912
              ],
              [
                -70.52896589071005,
                -9.718362141043025
              ],
              [
                -70.52594991100045,
                -9.71318867601252
              ],
              [
                -70.53443687865241,
                -9.702798712882064
              ],
              [
                -70.5434573700402,
                -9.679176803769918
              ],
              [
                -70.54621235779366,
                -9.67778082129867
              ],
              [
                -70.54900085544652,
                -9.676361335949023
              ],
              [
                -70.55161285956063,
                -9.670891346992416
              ],
              [
                -70.55378633597111,
                -9.666953364899658
              ],
              [
                -70.56042784538829,
                -9.666181846965259
              ],
              [
                -70.56100432207873,
                -9.666116387094975
              ],
              [
                -70.56673280113466,
                -9.665464385324622
              ],
              [
                -70.56894980516633,
                -9.662249879208458
              ],
              [
                -70.57103678036296,
                -9.659867926479233
              ],
              [
                -70.573435780023,
                -9.656416400340866
              ],
              [
                -70.57561979867744,
                -9.653511914273967
              ],
              [
                -70.5755657765689,
                -9.648881451534097
              ],
              [
                -70.58132978881933,
                -9.642589958061299
              ],
              [
                -70.58547975861555,
                -9.638848992602227
              ],
              [
                -70.58690674889714,
                -9.633246002076413
              ],
              [
                -70.58880177232867,
                -9.628784056022795
              ],
              [
                -70.59066724480411,
                -9.62497456903952
              ],
              [
                -70.59247175995877,
                -9.620301061447892
              ],
              [
                -70.59436926563023,
                -9.615076089597371
              ],
              [
                -70.59691474062174,
                -9.609221593614137
              ],
              [
                -70.59757525079176,
                -9.605619112076317
              ],
              [
                -70.59465328147536,
                -9.602919128124798
              ],
              [
                -70.59094180787575,
                -9.59936264836864
              ],
              [
                -70.59025728682285,
                -9.598706613152348
              ],
              [
                -70.58697833174593,
                -9.589475143491917
              ],
              [
                -70.58763733894264,
                -9.585851645024437
              ],
              [
                -70.58892184068704,
                -9.585775654323676
              ],
              [
                -70.59224829907991,
                -9.585577693281346
              ],
              [
                -70.58756834013622,
                -9.581650177049049
              ],
              [
                -70.58651333785119,
                -9.575990688693917
              ],
              [
                -70.58659487755631,
                -9.571088715992238
              ],
              [
                -70.59380684426326,
                -9.566875720824243
              ],
              [
                -70.59673682778117,
                -9.565176738467791
              ],
              [
                -70.60085580525964,
                -9.56290625768189
              ],
              [
                -70.600273808468,
                -9.557555262201905
              ],
              [
                -70.59491633181513,
                -9.55150227405015
              ],
              [
                -70.59126136683214,
                -9.549846287941945
              ],
              [
                -70.58524240052999,
                -9.551380252795232
              ],
              [
                -70.58657038435656,
                -9.556026763498672
              ],
              [
                -70.58945087431363,
                -9.564554225495014
              ],
              [
                -70.58499739314513,
                -9.565665707086675
              ],
              [
                -70.57668989453354,
                -9.562261717511447
              ],
              [
                -70.57603593571577,
                -9.562602203470252
              ],
              [
                -70.57279491953335,
                -9.564288733355887
              ],
              [
                -70.56695845324886,
                -9.569623180490346
              ],
              [
                -70.56290296679776,
                -9.572947183315884
              ],
              [
                -70.5578079721856,
                -9.571714689647106
              ],
              [
                -70.55544550918776,
                -9.566170696882224
              ],
              [
                -70.5567014954444,
                -9.56219119343603
              ],
              [
                -70.55833449350357,
                -9.557365718048963
              ],
              [
                -70.56344749520277,
                -9.55606023408193
              ],
              [
                -70.56854446951728,
                -9.55442273204854
              ],
              [
                -70.56942697006359,
                -9.548776245821399
              ],
              [
                -70.56497849234765,
                -9.538606771109093
              ],
              [
                -70.56545049440297,
                -9.537974304702068
              ],
              [
                -70.56902197925551,
                -9.533187807323387
              ],
              [
                -70.57119499510407,
                -9.530154328611452
              ],
              [
                -70.56486102848417,
                -9.527808823215652
              ],
              [
                -70.56272104434633,
                -9.53032480747794
              ],
              [
                -70.56114553214532,
                -9.532042798961365
              ],
              [
                -70.55820602623453,
                -9.535247295938353
              ],
              [
                -70.55620002449567,
                -9.539010251798201
              ],
              [
                -70.54749908820054,
                -9.538882272153511
              ],
              [
                -70.54244511879232,
                -9.535586263576452
              ],
              [
                -70.53907614878672,
                -9.532001281557566
              ],
              [
                -70.53907014501881,
                -9.527588258358993
              ],
              [
                -70.5467336153178,
                -9.525472813827536
              ],
              [
                -70.5466051113923,
                -9.523306810907426
              ],
              [
                -70.54629910708752,
                -9.518151332381015
              ],
              [
                -70.54567965065357,
                -9.510811330698614
              ],
              [
                -70.53805966596647,
                -9.511829343503132
              ],
              [
                -70.53501169109468,
                -9.51223633042505
              ],
              [
                -70.53206220683226,
                -9.512630310542729
              ],
              [
                -70.53203320459842,
                -9.506711338699962
              ],
              [
                -70.53202969788195,
                -9.505932335958883
              ],
              [
                -70.53143621290813,
                -9.505603836961239
              ],
              [
                -70.52930921275586,
                -9.504425336973963
              ],
              [
                -70.5225877443447,
                -9.502970342419994
              ],
              [
                -70.51689527768421,
                -9.495944339734404
              ],
              [
                -70.51488328921639,
                -9.493434841814327
              ],
              [
                -70.51118729681275,
                -9.497079816277331
              ],
              [
                -70.50871382328798,
                -9.506243793631615
              ],
              [
                -70.50527683385059,
                -9.506169276332741
              ],
              [
                -70.50543784259243,
                -9.502841327280816
              ],
              [
                -70.50375184995482,
                -9.495295835552533
              ],
              [
                -70.5118413360437,
                -9.484175882904315
              ],
              [
                -70.51859430911557,
                -9.475902922810604
              ],
              [
                -70.51898981587186,
                -9.475645919191978
              ],
              [
                -70.52142078706625,
                -9.468001950346254
              ],
              [
                -70.52595577166518,
                -9.471923421175909
              ],
              [
                -70.52997227432633,
                -9.470369429123975
              ],
              [
                -70.52675079982386,
                -9.46483545908949
              ],
              [
                -70.52538778257808,
                -9.462347981157333
              ],
              [
                -70.52534129591183,
                -9.462262488332147
              ],
              [
                -70.52384932860193,
                -9.459530956907138
              ],
              [
                -70.52065082848331,
                -9.457399995009046
              ],
              [
                -70.5179013104537,
                -9.463925939597699
              ],
              [
                -70.51689731380489,
                -9.466309936045755
              ],
              [
                -70.51165887092385,
                -9.464386923279267
              ],
              [
                -70.50685389527246,
                -9.46243495629387
              ],
              [
                -70.50371738176221,
                -9.461625448644547
              ],
              [
                -70.50677390280187,
                -9.454907964120427
              ],
              [
                -70.50730892546875,
                -9.445074492665334
              ],
              [
                -70.50306941720476,
                -9.443884478378758
              ],
              [
                -70.49924894397842,
                -9.444057471782555
              ],
              [
                -70.4955564471705,
                -9.43931099675812
              ],
              [
                -70.49444547586931,
                -9.431865514739856
              ],
              [
                -70.49397949997623,
                -9.426199035557428
              ],
              [
                -70.50013947143151,
                -9.425987025979362
              ],
              [
                -70.50306096035695,
                -9.42462357261153
              ],
              [
                -70.50569992738197,
                -9.42145556306461
              ],
              [
                -70.50949594972984,
                -9.42313307220356
              ],
              [
                -70.5153848796917,
                -9.42580107478415
              ],
              [
                -70.51985439569253,
                -9.427803078054989
              ],
              [
                -70.52027885452674,
                -9.432994051584435
              ],
              [
                -70.52618583716155,
                -9.434583037358818
              ],
              [
                -70.5271908285076,
                -9.434852554173913
              ],
              [
                -70.52723331696028,
                -9.434462064380362
              ],
              [
                -70.52765932848152,
                -9.430556582448627
              ],
              [
                -70.53110181622989,
                -9.431054076479924
              ],
              [
                -70.5347738167706,
                -9.433561082019569
              ],
              [
                -70.53481029557149,
                -9.433589587994561
              ],
              [
                -70.53806676879027,
                -9.436137569756708
              ],
              [
                -70.53794278600988,
                -9.433229574034037
              ],
              [
                -70.53786578924907,
                -9.431424571172283
              ],
              [
                -70.5429772584832,
                -9.42914810094713
              ],
              [
                -70.5474997689367,
                -9.427029129322396
              ],
              [
                -70.54980372846437,
                -9.431864592904931
              ],
              [
                -70.5502097403851,
                -9.432716590070186
              ],
              [
                -70.55309920245594,
                -9.43136308961485
              ],
              [
                -70.55827619666974,
                -9.428937639922903
              ],
              [
                -70.56134920511353,
                -9.42749810900755
              ],
              [
                -70.56547917877324,
                -9.426898145330103
              ],
              [
                -70.56898716462304,
                -9.430891111296965
              ],
              [
                -70.57415761189147,
                -9.434646142611959
              ],
              [
                -70.58275506157467,
                -9.441018640792329
              ],
              [
                -70.5878490491068,
                -9.444811119899262
              ],
              [
                -70.58883955441023,
                -9.44144261026466
              ],
              [
                -70.59499051023958,
                -9.440466124911293
              ],
              [
                -70.5981575220882,
                -9.443491649177979
              ],
              [
                -70.59606500212534,
                -9.448879105198293
              ],
              [
                -70.5961005185816,
                -9.449123101546661
              ],
              [
                -70.59725698890696,
                -9.457045091401056
              ],
              [
                -70.60342946589749,
                -9.456537107736509
              ],
              [
                -70.6042874534206,
                -9.456467114310339
              ],
              [
                -70.60714245528298,
                -9.45843609216153
              ],
              [
                -70.61085143506273,
                -9.45640212798615
              ],
              [
                -70.6118374111702,
                -9.461810082883128
              ],
              [
                -70.61363341746326,
                -9.46686109911545
              ],
              [
                -70.61373637956363,
                -9.467150081442723
              ],
              [
                -70.61601388065587,
                -9.471081583695197
              ],
              [
                -70.61674135308941,
                -9.473867561789092
              ],
              [
                -70.61958136381686,
                -9.472879061198118
              ],
              [
                -70.62193785229466,
                -9.472058571345968
              ],
              [
                -70.62472982622619,
                -9.477226558852442
              ],
              [
                -70.62881781912144,
                -9.478465580813198
              ],
              [
                -70.63160031707264,
                -9.479337587746633
              ],
              [
                -70.63266777871672,
                -9.484193557408306
              ],
              [
                -70.63637178285066,
                -9.48688956154798
              ],
              [
                -70.63546876050833,
                -9.49003456100577
              ],
              [
                -70.63435977186151,
                -9.493894545066148
              ],
              [
                -70.63584473308187,
                -9.497931530778336
              ],
              [
                -70.64095370772073,
                -9.49977951263845
              ],
              [
                -70.64377772482867,
                -9.499222016782646
              ],
              [
                -70.64426072296614,
                -9.499126540833833
              ],
              [
                -70.65180018388422,
                -9.499486047899069
              ],
              [
                -70.65123217499745,
                -9.507008497267101
              ],
              [
                -70.6511436584595,
                -9.508178530747086
              ],
              [
                -70.65160365394743,
                -9.511606490591376
              ],
              [
                -70.65456612099219,
                -9.511962989324712
              ],
              [
                -70.65703114697689,
                -9.513630500476175
              ],
              [
                -70.66313211324946,
                -9.516235987771704
              ],
              [
                -70.66603110712646,
                -9.517473517866245
              ],
              [
                -70.67253656165288,
                -9.52256799464985
              ],
              [
                -70.67675050681358,
                -9.524559499695535
              ],
              [
                -70.68043151370426,
                -9.528077999489282
              ],
              [
                -70.68154651409317,
                -9.529276469834295
              ],
              [
                -70.6835574821687,
                -9.531438474849626
              ],
              [
                -70.68523998540566,
                -9.531281987010885
              ],
              [
                -70.6880809709892,
                -9.531016477352313
              ],
              [
                -70.69183644212778,
                -9.534194991257989
              ],
              [
                -70.69374141939832,
                -9.535897511430974
              ],
              [
                -70.6984449259069,
                -9.540100963926742
              ],
              [
                -70.70235390061012,
                -9.543154961453004
              ],
              [
                -70.70459139352043,
                -9.541788469990015
              ],
              [
                -70.70526487038602,
                -9.541377500257504
              ],
              [
                -70.71496833688101,
                -9.545081969271331
              ],
              [
                -70.71649381353706,
                -9.548736970238469
              ],
              [
                -70.7186993036864,
                -9.554021452630323
              ],
              [
                -70.72269677167664,
                -9.556678448464094
              ],
              [
                -70.72377925108539,
                -9.560995460828934
              ],
              [
                -70.72938671461183,
                -9.5665509549654
              ],
              [
                -70.73418421939054,
                -9.562435959520805
              ],
              [
                -70.73563870890258,
                -9.560871451357205
              ],
              [
                -70.73763822110791,
                -9.558720482755135
              ],
              [
                -70.74317866701152,
                -9.556913003890289
              ],
              [
                -70.74766066576387,
                -9.559104479855804
              ],
              [
                -70.7513526641616,
                -9.558641520150232
              ],
              [
                -70.75618459233918,
                -9.56639047677715
              ],
              [
                -70.75071563423148,
                -9.568131950942936
              ],
              [
                -70.75273311296218,
                -9.570490953454481
              ],
              [
                -70.75499859438534,
                -9.573625952149076
              ],
              [
                -70.75527861178848,
                -9.577538439406897
              ],
              [
                -70.75738507062376,
                -9.584469941991266
              ],
              [
                -70.75758257620113,
                -9.588480424842126
              ],
              [
                -70.75759805461072,
                -9.588799934953604
              ],
              [
                -70.75702104451362,
                -9.595173884793002
              ],
              [
                -70.75830403285231,
                -9.59961840217063
              ],
              [
                -70.75901104311205,
                -9.603742389532847
              ],
              [
                -70.76220751949828,
                -9.602115893371673
              ],
              [
                -70.76779600968854,
                -9.599427918125286
              ],
              [
                -70.76858149942687,
                -9.604883391818932
              ],
              [
                -70.77539397424326,
                -9.607829375476577
              ],
              [
                -70.7772639614229,
                -9.608463899704537
              ],
              [
                -70.77956242958206,
                -9.609243883214281
              ],
              [
                -70.78271640518308,
                -9.610631879380882
              ],
              [
                -70.78708841783751,
                -9.612556888998183
              ],
              [
                -70.78822939631702,
                -9.616662390146958
              ],
              [
                -70.78745137792575,
                -9.61825085435234
              ],
              [
                -70.78670490450881,
                -9.619775351791354
              ],
              [
                -70.79244083580403,
                -9.625143877825895
              ],
              [
                -70.79290536763729,
                -9.628310858558887
              ],
              [
                -70.79387534808585,
                -9.635878825581983
              ],
              [
                -70.79778780115319,
                -9.63508182322866
              ],
              [
                -70.79950081449513,
                -9.64024180933118
              ],
              [
                -70.80304280503225,
                -9.639488815250433
              ],
              [
                -70.80734528292045,
                -9.639033837561094
              ],
              [
                -70.81070826454629,
                -9.638678848054946
              ],
              [
                -70.81225773238485,
                -9.638515335046735
              ],
              [
                -70.81418275473196,
                -9.638311364765931
              ],
              [
                -70.81419324869003,
                -9.638310360785887
              ],
              [
                -70.8215412233955,
                -9.639396858679122
              ],
              [
                -70.82485769572149,
                -9.640551866805179
              ],
              [
                -70.82934718109641,
                -9.640551878128171
              ],
              [
                -70.83583862318332,
                -9.641752357307423
              ],
              [
                -70.84001210564652,
                -9.654556847767026
              ],
              [
                -70.84708808447382,
                -9.655663330188192
              ],
              [
                -70.84889403993031,
                -9.655095332750156
              ],
              [
                -70.85303405093256,
                -9.65379285420812
              ],
              [
                -70.85682851001978,
                -9.656316862251174
              ],
              [
                -70.85992000123666,
                -9.658108375225343
              ],
              [
                -70.86579498737332,
                -9.661435871400982
              ],
              [
                -70.87021646127897,
                -9.664314339478867
              ],
              [
                -70.87042395126423,
                -9.668620354625473
              ],
              [
                -70.87458690492606,
                -9.674092808949085
              ],
              [
                -70.87895638704265,
                -9.676973328954379
              ],
              [
                -70.88170885811844,
                -9.67909430746388
              ],
              [
                -70.88158538165096,
                -9.681484831500946
              ],
              [
                -70.88152388392211,
                -9.68267631803085
              ],
              [
                -70.88291736717879,
                -9.683261825659036
              ],
              [
                -70.88510085461208,
                -9.684179803642538
              ],
              [
                -70.88758184931066,
                -9.686601321865004
              ],
              [
                -70.88971083103903,
                -9.688678821484968
              ],
              [
                -70.89552379597755,
                -9.70008076081819
              ],
              [
                -70.89559579644359,
                -9.700222295536815
              ],
              [
                -70.89762176405277,
                -9.7068192482424
              ],
              [
                -70.90033173397072,
                -9.712730235017439
              ],
              [
                -70.90356923338342,
                -9.712248253699883
              ],
              [
                -70.9091336955941,
                -9.721687238238431
              ],
              [
                -70.91298716531408,
                -9.726078727206422
              ],
              [
                -70.92166463197354,
                -9.734721197161672
              ],
              [
                -70.92540260769498,
                -9.739394719170509
              ],
              [
                -70.92781057950882,
                -9.737147221464468
              ],
              [
                -70.93318656451738,
                -9.738208241342933
              ],
              [
                -70.93808652859862,
                -9.74099873557621
              ],
              [
                -70.9465459683228,
                -9.741200217962842
              ],
              [
                -70.94778949713847,
                -9.741229726931119
              ],
              [
                -70.95284995649608,
                -9.741676738022742
              ],
              [
                -70.95737892590746,
                -9.742209746997217
              ],
              [
                -70.96341992106366,
                -9.746928258719514
              ],
              [
                -70.96732237690478,
                -9.75053822603263
              ],
              [
                -70.97112886376031,
                -9.752588240568578
              ],
              [
                -70.97463633478552,
                -9.757654717156809
              ],
              [
                -70.97722480205361,
                -9.763640226659184
              ],
              [
                -70.99124273701938,
                -9.787549169930045
              ],
              [
                -70.9923411951998,
                -9.791282152310837
              ],
              [
                -70.99314717982097,
                -9.796060139287704
              ],
              [
                -70.993607176615,
                -9.798615119908222
              ],
              [
                -70.99440218088529,
                -9.803031617883269
              ],
              [
                -70.9968491567779,
                -9.810743095400857
              ],
              [
                -70.99859765372761,
                -9.813610079298808
              ],
              [
                -71.01012507582898,
                -9.812072621751938
              ],
              [
                -71.01627055610801,
                -9.815273102037697
              ],
              [
                -71.02015254707722,
                -9.815299626418467
              ],
              [
                -71.02207153945898,
                -9.817498108478663
              ],
              [
                -71.02663250915698,
                -9.816034122145728
              ],
              [
                -71.02845400564716,
                -9.81544964780415
              ],
              [
                -71.03394298601863,
                -9.816654619574189
              ],
              [
                -71.03760046261984,
                -9.816860651083287
              ],
              [
                -71.04572944935113,
                -9.813800677125487
              ],
              [
                -71.05126642203206,
                -9.814124168432208
              ],
              [
                -71.05548637174134,
                -9.817636181675761
              ],
              [
                -71.05882288945175,
                -9.821521162418176
              ],
              [
                -71.06036587706862,
                -9.823317642989256
              ],
              [
                -71.06497184323956,
                -9.824438175540847
              ],
              [
                -71.07487828675988,
                -9.82607567448407
              ],
              [
                -71.07858228450665,
                -9.82668814995081
              ],
              [
                -71.07912428805427,
                -9.827412171842315
              ],
              [
                -71.08761320700614,
                -9.838734168555186
              ],
              [
                -71.1093030871503,
                -9.852187643452638
              ],
              [
                -71.1425389176363,
                -9.861749157540034
              ],
              [
                -71.14441994074367,
                -9.862446662758222
              ],
              [
                -71.14581542614751,
                -9.862963665663552
              ],
              [
                -71.1643643170286,
                -9.875396661387725
              ],
              [
                -71.16646581188698,
                -9.880996134468328
              ],
              [
                -71.16769229929581,
                -9.884264624514064
              ],
              [
                -71.16991925843774,
                -9.88587363281272
              ],
              [
                -71.17307074465991,
                -9.888149123826993
              ],
              [
                -71.17820621556444,
                -9.903429092370972
              ],
              [
                -71.18059669762718,
                -9.910542563813637
              ],
              [
                -71.18289665388477,
                -9.91775356984755
              ],
              [
                -71.18799962558141,
                -9.92151054610385
              ],
              [
                -71.1907256169974,
                -9.923517057177092
              ],
              [
                -71.19151163140018,
                -9.926076526467819
              ],
              [
                -71.19532056156186,
                -9.938483516810603
              ],
              [
                -71.20708751143063,
                -9.947241519624272
              ],
              [
                -71.21234845569272,
                -9.966583437499047
              ],
              [
                -71.2285383609818,
                -9.969241461596628
              ],
              [
                -71.23307386147513,
                -9.968071480467307
              ],
              [
                -71.23988932783038,
                -9.965642997633116
              ],
              [
                -71.24003282234872,
                -9.965582506041098
              ],
              [
                -71.24363932225171,
                -9.964058008306655
              ],
              [
                -71.24750981903463,
                -9.962559516945904
              ],
              [
                -71.25421127390241,
                -9.963601048427362
              ],
              [
                -71.25604375876213,
                -9.967475022941912
              ],
              [
                -71.25842376263843,
                -9.972944998028678
              ],
              [
                -71.26072673512903,
                -9.97823801047601
              ],
              [
                -71.27207966421552,
                -9.978600023710323
              ],
              [
                -71.28053813218504,
                -9.981244002825271
              ],
              [
                -71.28371313031379,
                -9.982366009145215
              ],
              [
                -71.2906450945281,
                -9.985002541706578
              ],
              [
                -71.2944130666281,
                -9.986483011060164
              ],
              [
                -71.29975352822105,
                -9.988604538336805
              ],
              [
                -71.3094820185704,
                -9.992108543298707
              ],
              [
                -71.31865094664957,
                -9.991303058039016
              ],
              [
                -71.32098543228457,
                -9.989488069931516
              ],
              [
                -71.32434844411586,
                -9.986873576739724
              ],
              [
                -71.33988587836582,
                -9.972706126562365
              ],
              [
                -71.34993683535193,
                -9.973384673737675
              ],
              [
                -71.35434930685763,
                -9.976615638439727
              ],
              [
                -71.35586631289391,
                -9.981720142158085
              ],
              [
                -71.35631528827157,
                -9.98334264051707
              ],
              [
                -71.3578053039248,
                -9.988729143432318
              ],
              [
                -71.36492224725993,
                -9.985694139731002
              ],
              [
                -71.36887724959001,
                -9.98952512105371
              ],
              [
                -71.37673417262509,
                -10.000566123480406
              ],
              [
                -71.81988026167448,
                -10.000168322272163
              ],
              [
                -71.87996250283597,
                -10.000105407808512
              ],
              [
                -71.99976848844726,
                -9.999979599394988
              ],
              [
                -72.06129826077589,
                -9.9999152105299
              ],
              [
                -72.18040876087012,
                -9.999790392175948
              ],
              [
                -72.1814362432934,
                -9.99204842984693
              ],
              [
                -72.18143175934141,
                -9.992030910884367
              ],
              [
                -72.17956128872349,
                -9.983936947524509
              ],
              [
                -72.16943531449165,
                -9.978709445727427
              ],
              [
                -72.16215636298733,
                -9.97412544014208
              ],
              [
                -72.1572693726404,
                -9.968653444933636
              ],
              [
                -72.15548990327527,
                -9.963656490798684
              ],
              [
                -72.15562890921682,
                -9.960486480216424
              ],
              [
                -72.15968790948858,
                -9.95343649831406
              ],
              [
                -72.1604904134024,
                -9.93951255462716
              ],
              [
                -72.16804638526413,
                -9.939225558193014
              ],
              [
                -72.17286636173543,
                -9.934957075390436
              ],
              [
                -72.1740468808021,
                -9.922960616363083
              ],
              [
                -72.166740456515,
                -9.913055130578062
              ],
              [
                -72.16100946042292,
                -9.906994627548581
              ],
              [
                -72.15649250535894,
                -9.90221765926669
              ],
              [
                -72.15379751355938,
                -9.897781652191625
              ],
              [
                -72.15218754507393,
                -9.893011682820793
              ],
              [
                -72.14254758171792,
                -9.882637692422241
              ],
              [
                -72.14046461166825,
                -9.877523708167537
              ],
              [
                -72.14282308271326,
                -9.875015215649805
              ],
              [
                -72.14813006756425,
                -9.869370219233563
              ],
              [
                -72.15199158145096,
                -9.86526325938864
              ],
              [
                -72.1548785932406,
                -9.860525263535537
              ],
              [
                -72.15579859431142,
                -9.854870797746457
              ],
              [
                -72.15521460701143,
                -9.847031825096632
              ],
              [
                -72.15485260472734,
                -9.842178803079701
              ],
              [
                -72.157434104244,
                -9.834778849620937
              ],
              [
                -72.15918360942455,
                -9.832411867702131
              ],
              [
                -72.16193557676179,
                -9.831750856038463
              ],
              [
                -72.16526858502812,
                -9.828039875263455
              ],
              [
                -72.1636025963348,
                -9.820550893904914
              ],
              [
                -72.15947413532734,
                -9.815972391870641
              ],
              [
                -72.1567146413629,
                -9.812912409193997
              ],
              [
                -72.1542466542189,
                -9.808698918007513
              ],
              [
                -72.1515187028454,
                -9.804042935541847
              ],
              [
                -72.15108666789153,
                -9.801485916467128
              ],
              [
                -72.15067617763673,
                -9.799055425100486
              ],
              [
                -72.15779516711481,
                -9.791175963499818
              ],
              [
                -72.17105062075704,
                -9.784935018883518
              ],
              [
                -72.17706458200146,
                -9.782103542291008
              ],
              [
                -72.18370857730358,
                -9.780143562482921
              ],
              [
                -72.18683554958874,
                -9.77924155104935
              ],
              [
                -72.1907610412947,
                -9.778018039783571
              ],
              [
                -72.2024415224875,
                -9.774533577335403
              ],
              [
                -72.20330899713522,
                -9.774508580848897
              ],
              [
                -72.20887848361909,
                -9.774346112570964
              ],
              [
                -72.21447847535026,
                -9.773381612423536
              ],
              [
                -72.22490594820341,
                -9.765348634654226
              ],
              [
                -72.22819394064621,
                -9.762815153706443
              ],
              [
                -72.23026590387668,
                -9.762621157665638
              ],
              [
                -72.23848386606632,
                -9.761852664365689
              ],
              [
                -72.24512082377036,
                -9.765608685713977
              ],
              [
                -72.24886284318494,
                -9.7608736892142
              ],
              [
                -72.25332134041807,
                -9.754003714431972
              ],
              [
                -72.25751281201727,
                -9.752845244417323
              ],
              [
                -72.26369428621724,
                -9.751471752274416
              ],
              [
                -72.26797575177231,
                -9.75013625686456
              ],
              [
                -72.27099327016303,
                -9.749449259669978
              ],
              [
                -72.27231275502521,
                -9.742945771688342
              ],
              [
                -72.26970329587783,
                -9.736636809666573
              ],
              [
                -72.26504530914626,
                -9.730073832905823
              ],
              [
                -72.26341933381237,
                -9.726332308891582
              ],
              [
                -72.26505084056096,
                -9.720342325118652
              ],
              [
                -72.26561936367602,
                -9.71303337999919
              ],
              [
                -72.26117535974883,
                -9.707261862582827
              ],
              [
                -72.26020986163117,
                -9.706007365233615
              ],
              [
                -72.25228645369008,
                -9.690488907362717
              ],
              [
                -72.2528449294388,
                -9.68280145024779
              ],
              [
                -72.2530029294814,
                -9.680624432162935
              ],
              [
                -72.25336746545136,
                -9.675629462739812
              ],
              [
                -72.25280344352332,
                -9.674515968699682
              ],
              [
                -72.24846348685725,
                -9.665943459804236
              ],
              [
                -72.24618299409171,
                -9.656802488807942
              ],
              [
                -72.25212150475714,
                -9.650925504891996
              ],
              [
                -72.2547199731686,
                -9.647610030800156
              ],
              [
                -72.2549039758448,
                -9.64737455371599
              ],
              [
                -72.2592344705745,
                -9.642220577258524
              ],
              [
                -72.25912448777858,
                -9.63697658871613
              ],
              [
                -72.25908297324408,
                -9.635001595785276
              ],
              [
                -72.25372204148907,
                -9.61385561901053
              ],
              [
                -72.25535154370202,
                -9.61051565638787
              ],
              [
                -72.25984104830775,
                -9.611041157394663
              ],
              [
                -72.26289550113094,
                -9.612396148885177
              ],
              [
                -72.26663051064162,
                -9.61294765058706
              ],
              [
                -72.26880097006003,
                -9.613616654412851
              ],
              [
                -72.27184097164192,
                -9.614553164972437
              ],
              [
                -72.28071995203749,
                -9.607952693731118
              ],
              [
                -72.28892540666361,
                -9.60099270630504
              ],
              [
                -72.28899743623188,
                -9.595681755234933
              ],
              [
                -72.2870734404326,
                -9.58143976913683
              ],
              [
                -72.2869169534337,
                -9.573985815285688
              ],
              [
                -72.28686146805865,
                -9.571332288104502
              ],
              [
                -72.28697398822453,
                -9.566151334781795
              ],
              [
                -72.28524851702592,
                -9.539563910260162
              ],
              [
                -72.29075651463211,
                -9.537108418646405
              ],
              [
                -72.29485851117485,
                -9.53498143488048
              ],
              [
                -72.29793200498682,
                -9.533904946697476
              ],
              [
                -72.30337046639728,
                -9.536572447613596
              ],
              [
                -72.30596693119392,
                -9.537838418550267
              ],
              [
                -72.3088489556497,
                -9.539243439592012
              ],
              [
                -72.31217842231966,
                -9.540675937293962
              ],
              [
                -72.31702838232897,
                -9.543162441736252
              ],
              [
                -72.3197468791689,
                -9.541703464969093
              ],
              [
                -72.32662686075349,
                -9.53666296830711
              ],
              [
                -72.33096936113245,
                -9.533481473664619
              ],
              [
                -72.33923584155936,
                -9.519837022625252
              ],
              [
                -72.34369933687105,
                -9.517017565225787
              ],
              [
                -72.34005136066642,
                -9.51179903686429
              ],
              [
                -72.34266185329534,
                -9.505271061097
              ],
              [
                -72.3472783154582,
                -9.50217912164744
              ],
              [
                -72.34802031539515,
                -9.501682121146592
              ],
              [
                -72.35200882892796,
                -9.502272099094856
              ],
              [
                -72.35409329814071,
                -9.495413140324244
              ],
              [
                -72.35685280413068,
                -9.493770623079236
              ],
              [
                -72.36302027871712,
                -9.494660138783301
              ],
              [
                -72.36596277098982,
                -9.489382689283826
              ],
              [
                -72.37018625121189,
                -9.492044162222555
              ],
              [
                -72.37592022161311,
                -9.493468670833519
              ],
              [
                -72.38296419427236,
                -9.491049693557313
              ],
              [
                -72.39054667984132,
                -9.4856012160164
              ],
              [
                -72.39612817200667,
                -9.48450524392066
              ],
              [
                -72.39989465969296,
                -9.481294756365621
              ],
              [
                -72.40246261934365,
                -9.479105753212849
              ],
              [
                -72.40894913059074,
                -9.477051280064893
              ],
              [
                -72.41579557180432,
                -9.480858255297782
              ],
              [
                -72.42168304994725,
                -9.479678301909166
              ],
              [
                -72.42560201816686,
                -9.48247675801656
              ],
              [
                -72.4286020187675,
                -9.478702303663704
              ],
              [
                -72.4313725088029,
                -9.479456318205358
              ],
              [
                -72.43405948520058,
                -9.480187299671961
              ],
              [
                -72.43825849838362,
                -9.476887794028872
              ],
              [
                -72.44304894871303,
                -9.480616299149078
              ],
              [
                -72.44320195692433,
                -9.480735816088032
              ],
              [
                -72.4487344396903,
                -9.480635806325438
              ],
              [
                -72.4488639503096,
                -9.480562797724515
              ],
              [
                -72.45186391749752,
                -9.478868845975095
              ],
              [
                -72.4549844104618,
                -9.474597865085931
              ],
              [
                -72.45643840349892,
                -9.477235849621247
              ],
              [
                -72.46113289016598,
                -9.477956363374979
              ],
              [
                -72.46515288061366,
                -9.478573342303148
              ],
              [
                -72.46380687455091,
                -9.473265872839802
              ],
              [
                -72.47147484521979,
                -9.474907872033148
              ],
              [
                -72.48125782391931,
                -9.471573908513733
              ],
              [
                -72.4828797802429,
                -9.480329881536528
              ],
              [
                -72.48300978858146,
                -9.481033859930346
              ],
              [
                -72.48443377056701,
                -9.486202843901804
              ],
              [
                -72.48802275395573,
                -9.487267370984995
              ],
              [
                -72.50131572032959,
                -9.483215398024996
              ],
              [
                -72.50537222277387,
                -9.482551416323906
              ],
              [
                -72.51015067039616,
                -9.484946408912998
              ],
              [
                -72.51717266535145,
                -9.48007093787549
              ],
              [
                -72.51803563901103,
                -9.479471921819346
              ],
              [
                -72.52500762179446,
                -9.47360646824059
              ],
              [
                -72.52821511220766,
                -9.469490473042775
              ],
              [
                -72.52585261552454,
                -9.476651426771545
              ],
              [
                -72.52979458978814,
                -9.474553456570113
              ],
              [
                -72.53457357376796,
                -9.474922469375835
              ],
              [
                -72.53473860798934,
                -9.46954148149217
              ],
              [
                -72.53789407963647,
                -9.470876996173622
              ],
              [
                -72.54026058628503,
                -9.4673745000247
              ],
              [
                -72.5426285573274,
                -9.465452502809997
              ],
              [
                -72.54722455166535,
                -9.46664702918937
              ],
              [
                -72.54898353816039,
                -9.467092508896132
              ],
              [
                -72.55147253554541,
                -9.467720522317803
              ],
              [
                -72.55399150384393,
                -9.465292521645608
              ],
              [
                -72.55812749626276,
                -9.471772515698094
              ],
              [
                -72.55890699904316,
                -9.465829542235749
              ],
              [
                -72.5632410021658,
                -9.465344047511433
              ],
              [
                -72.56765446529526,
                -9.46163055120841
              ],
              [
                -72.5736789553146,
                -9.465163037902805
              ],
              [
                -72.5743804661629,
                -9.456317082758634
              ],
              [
                -72.57671342039774,
                -9.458625576609814
              ],
              [
                -72.57989241247482,
                -9.45844356522898
              ],
              [
                -72.58406742096086,
                -9.456901611953166
              ],
              [
                -72.58721240078356,
                -9.455739601031622
              ],
              [
                -72.59064737102827,
                -9.455469105291137
              ],
              [
                -72.59094639091789,
                -9.455445624835889
              ],
              [
                -72.59230285688184,
                -9.455338588308281
              ],
              [
                -72.59526736787528,
                -9.455105629709017
              ],
              [
                -72.59785634722499,
                -9.454317602924933
              ],
              [
                -72.5992958704154,
                -9.453879638380338
              ],
              [
                -72.6022123306734,
                -9.452991643382234
              ],
              [
                -72.60498831314526,
                -9.452302631645761
              ],
              [
                -72.60801730474874,
                -9.451551651477388
              ],
              [
                -72.61214182203997,
                -9.450866636913693
              ],
              [
                -72.61796328317538,
                -9.452400638412728
              ],
              [
                -72.62132228041999,
                -9.44562268121672
              ],
              [
                -72.62529326961712,
                -9.447794701082357
              ],
              [
                -72.62657375790106,
                -9.444630710258897
              ],
              [
                -72.63182072868075,
                -9.445906214487039
              ],
              [
                -72.6364917264015,
                -9.444911687595082
              ],
              [
                -72.64176670947954,
                -9.442900714280483
              ],
              [
                -72.64430618614952,
                -9.445699195231773
              ],
              [
                -72.6495321599341,
                -9.44703020828993
              ],
              [
                -72.65247262143313,
                -9.447172227688723
              ],
              [
                -72.65346464892347,
                -9.44821224563671
              ],
              [
                -72.65563613286317,
                -9.45048871433571
              ],
              [
                -72.65678363403498,
                -9.447708718299241
              ],
              [
                -72.65683411304202,
                -9.447586743692716
              ],
              [
                -72.65893361963441,
                -9.443547221416532
              ],
              [
                -72.6627575964381,
                -9.44509974664047
              ],
              [
                -72.66749860628236,
                -9.44083725020576
              ],
              [
                -72.67182107911192,
                -9.436951270127597
              ],
              [
                -72.67637807193499,
                -9.43861679724764
              ],
              [
                -72.67872606336013,
                -9.436538303354189
              ],
              [
                -72.68150855175544,
                -9.434074327146616
              ],
              [
                -72.68479602308763,
                -9.43656629310382
              ],
              [
                -72.68941852596494,
                -9.43500582056492
              ],
              [
                -72.69311498186765,
                -9.438027303837755
              ],
              [
                -72.69432098061222,
                -9.435372832560088
              ],
              [
                -72.6959069944301,
                -9.434225341052938
              ],
              [
                -72.69684998494323,
                -9.433543333317903
              ],
              [
                -72.69837746564203,
                -9.430776320846006
              ],
              [
                -72.70521343937989,
                -9.43146434614726
              ],
              [
                -72.70945643913022,
                -9.429045878174087
              ],
              [
                -72.71198993724546,
                -9.42368087194528
              ],
              [
                -72.71651690463358,
                -9.418381386768255
              ],
              [
                -72.71660091665474,
                -9.410844438118692
              ],
              [
                -72.72406939041456,
                -9.410669941617783
              ],
              [
                -72.72601939104342,
                -9.41062393688246
              ],
              [
                -72.72983838960744,
                -9.410534968273318
              ],
              [
                -72.73315037608981,
                -9.410457948815397
              ],
              [
                -72.73587785478365,
                -9.410393971380788
              ],
              [
                -72.73785584331051,
                -9.410347466691931
              ],
              [
                -72.73936583560521,
                -9.410312470218413
              ],
              [
                -72.74118881571825,
                -9.410269977269184
              ],
              [
                -72.74420532132464,
                -9.410198984319058
              ],
              [
                -72.74753481275012,
                -9.410120962892101
              ],
              [
                -72.75242276129237,
                -9.410006992072338
              ],
              [
                -73.06930397326177,
                -9.410731476331758
              ],
              [
                -73.20754093878857,
                -9.411047188289803
              ],
              [
                -73.2105734185022,
                -9.411023660258337
              ],
              [
                -73.21481540281553,
                -9.410930688356677
              ],
              [
                -73.21504590808928,
                -9.407194693940106
              ],
              [
                -73.20762994401466,
                -9.402177712313003
              ],
              [
                -73.20138295728015,
                -9.397951699639346
              ],
              [
                -73.19782547651751,
                -9.395544709831293
              ],
              [
                -73.19696150073523,
                -9.3851747230535
              ],
              [
                -73.19574203222221,
                -9.370547764244199
              ],
              [
                -73.19054604957493,
                -9.36018128751857
              ],
              [
                -73.18161561053569,
                -9.3555382856371
              ],
              [
                -73.17549063906714,
                -9.35454028425205
              ],
              [
                -73.16176069008162,
                -9.352302284709104
              ],
              [
                -73.1572122218831,
                -9.34385029337075
              ],
              [
                -73.15315427735494,
                -9.336309312578448
              ],
              [
                -73.14758829687354,
                -9.332819812312385
              ],
              [
                -73.14206531469011,
                -9.329049809821262
              ],
              [
                -73.13194989340968,
                -9.313157823154565
              ],
              [
                -73.12791441521118,
                -9.312123333267705
              ],
              [
                -73.12092544851419,
                -9.310209321845342
              ],
              [
                -73.1179539258961,
                -9.30944433599471
              ],
              [
                -73.11682793758283,
                -9.309157819906428
              ],
              [
                -73.10725897541636,
                -9.306725302657116
              ],
              [
                -73.10405398479573,
                -9.305889299614424
              ],
              [
                -73.1005695275932,
                -9.304209809231724
              ],
              [
                -73.10093454267621,
                -9.298995808835654
              ],
              [
                -73.10420652343586,
                -9.29527083736933
              ],
              [
                -73.10992200199256,
                -9.28477386071554
              ],
              [
                -73.11076449333675,
                -9.283226392925576
              ],
              [
                -73.11123753270059,
                -9.279431887158264
              ],
              [
                -73.1079045336407,
                -9.275448895791724
              ],
              [
                -73.10389408033298,
                -9.272572417585192
              ],
              [
                -73.09487609861799,
                -9.266103911388877
              ],
              [
                -73.09181961833782,
                -9.261613426758487
              ],
              [
                -73.08369868026709,
                -9.242106957704546
              ],
              [
                -73.08340368451697,
                -9.241397480014392
              ],
              [
                -73.0817741951664,
                -9.239203976925168
              ],
              [
                -73.08177220518034,
                -9.239199959932098
              ],
              [
                -73.08013971484692,
                -9.237002466849884
              ],
              [
                -73.0660177942845,
                -9.22929847176566
              ],
              [
                -73.06072481589362,
                -9.226362977742124
              ],
              [
                -73.05598680239984,
                -9.225306948880817
              ],
              [
                -73.05289733615979,
                -9.225838967643275
              ],
              [
                -73.05110135717938,
                -9.228161968954144
              ],
              [
                -73.04450236727062,
                -9.23669789401976
              ],
              [
                -73.04033588092905,
                -9.234841918071856
              ],
              [
                -73.02585144161804,
                -9.227887419590372
              ],
              [
                -73.02389894174232,
                -9.220557422958908
              ],
              [
                -73.0237824788832,
                -9.220119926876654
              ],
              [
                -73.01924197554735,
                -9.218519949713654
              ],
              [
                -73.01913346801693,
                -9.218481913637627
              ],
              [
                -73.00945002511587,
                -9.214351930712704
              ],
              [
                -73.00515306435165,
                -9.210581927422215
              ],
              [
                -73.00450804281202,
                -9.208068432745678
              ],
              [
                -73.00334807942616,
                -9.20354894332075
              ],
              [
                -73.001063584203,
                -9.199698455432316
              ],
              [
                -72.99852161158294,
                -9.197949949830672
              ],
              [
                -72.99684561746317,
                -9.193849968542178
              ],
              [
                -73.00909358023917,
                -9.1882705019867
              ],
              [
                -73.01092504667515,
                -9.187435993986657
              ],
              [
                -73.01739505267466,
                -9.18345503325483
              ],
              [
                -73.02027901623386,
                -9.17693652626568
              ],
              [
                -73.0065401254746,
                -9.164722048476339
              ],
              [
                -72.99339618905171,
                -9.159676044546927
              ],
              [
                -72.97857225001603,
                -9.153985572635534
              ],
              [
                -72.97690027189235,
                -9.153628036911853
              ],
              [
                -72.97281827168244,
                -9.152755039704859
              ],
              [
                -72.96415532754706,
                -9.146993033703625
              ],
              [
                -72.95861784232287,
                -9.138272086149023
              ],
              [
                -72.9524623799665,
                -9.128579094121822
              ],
              [
                -72.94654242848073,
                -9.114616117361315
              ],
              [
                -72.94543096318178,
                -9.107358128096847
              ],
              [
                -72.94362645908919,
                -9.102249167283851
              ],
              [
                -72.94373897380389,
                -9.092049173444465
              ],
              [
                -72.94507547045035,
                -9.087182173934178
              ],
              [
                -72.9450839867058,
                -9.087000193411221
              ],
              [
                -72.9452424826038,
                -9.083521706524103
              ],
              [
                -72.94560100967531,
                -9.075653230142182
              ],
              [
                -72.93993652752783,
                -9.069026229214748
              ],
              [
                -72.9398100267183,
                -9.06603775064305
              ],
              [
                -72.93962453032378,
                -9.061657271533337
              ],
              [
                -72.94507556007217,
                -9.046835294849792
              ],
              [
                -72.94581506760464,
                -9.035179341764508
              ],
              [
                -72.94590957258222,
                -9.033693354707033
              ],
              [
                -72.94690104647167,
                -9.030106351409115
              ],
              [
                -72.94813153680947,
                -9.02565736968458
              ],
              [
                -72.94975655917952,
                -9.023423381912405
              ],
              [
                -72.95507553156972,
                -9.016110413841057
              ],
              [
                -72.95740656832805,
                -9.009374423662749
              ],
              [
                -72.95325106310624,
                -9.006760936909638
              ],
              [
                -72.9528810788098,
                -9.006564919838087
              ],
              [
                -72.94832407579712,
                -9.00414842396505
              ],
              [
                -72.938990642448,
                -8.996860441177757
              ],
              [
                -72.93676864317699,
                -8.988329981587349
              ],
              [
                -72.94402815360661,
                -8.985867482103954
              ],
              [
                -72.95639807897469,
                -8.982624508501992
              ],
              [
                -72.96221255799865,
                -8.980725998333655
              ],
              [
                -72.96275007024542,
                -8.980550014613376
              ],
              [
                -72.96611055132901,
                -8.979422017684515
              ],
              [
                -72.97096154338676,
                -8.974069064857167
              ],
              [
                -72.97359005239439,
                -8.967709070173928
              ],
              [
                -72.97804256466648,
                -8.95611862467689
              ],
              [
                -72.97922803978244,
                -8.953173620034864
              ],
              [
                -72.98368502702647,
                -8.942217681918775
              ],
              [
                -72.98458304430672,
                -8.939860193330928
              ],
              [
                -72.98740855018768,
                -8.932439695659422
              ],
              [
                -72.99211751714937,
                -8.920387727730757
              ],
              [
                -72.99570349866997,
                -8.919079735577055
              ],
              [
                -72.99938899322403,
                -8.917509751244978
              ],
              [
                -73.00907497149336,
                -8.913808758558906
              ],
              [
                -73.01217497508293,
                -8.912997309384592
              ],
              [
                -73.01560193305386,
                -8.913082795417543
              ],
              [
                -73.02018494963244,
                -8.91319729714814
              ],
              [
                -73.02367140490195,
                -8.912948786126167
              ],
              [
                -73.02945389666665,
                -8.90874533872528
              ],
              [
                -73.0321368973482,
                -8.905665322702761
              ],
              [
                -73.0402528954979,
                -8.89231390523023
              ],
              [
                -73.04069888558995,
                -8.890523382486531
              ],
              [
                -73.04419336912126,
                -8.876498436663741
              ],
              [
                -73.04711089636803,
                -8.868584468344055
              ],
              [
                -73.05338687452245,
                -8.859676012683769
              ],
              [
                -73.05345486404146,
                -8.857225514051747
              ],
              [
                -73.05363189813652,
                -8.850885541531824
              ],
              [
                -73.05388989242584,
                -8.841615056635716
              ],
              [
                -73.05672437439485,
                -8.839264057959564
              ],
              [
                -73.07016582498137,
                -8.835981102785894
              ],
              [
                -73.0758568275624,
                -8.831466097973099
              ],
              [
                -73.07594632933568,
                -8.831395105290483
              ],
              [
                -73.07900731633043,
                -8.827827144057855
              ],
              [
                -73.08538728181145,
                -8.820391157744345
              ],
              [
                -73.08772731233545,
                -8.81432618979258
              ],
              [
                -73.08752631924611,
                -8.790701269897143
              ],
              [
                -73.08967133104007,
                -8.785572757261347
              ],
              [
                -73.09574782499028,
                -8.777236812769413
              ],
              [
                -73.09880282512277,
                -8.773578799740672
              ],
              [
                -73.10212281966241,
                -8.77096982942596
              ],
              [
                -73.10654179440104,
                -8.7674983579732
              ],
              [
                -73.11357677168971,
                -8.750007403320911
              ],
              [
                -73.11517981876143,
                -8.736801455529987
              ],
              [
                -73.11650232634764,
                -8.725906462412388
              ],
              [
                -73.11828330041556,
                -8.72146547745927
              ],
              [
                -73.12677679551823,
                -8.711639524363067
              ],
              [
                -73.13139178584525,
                -8.706624526121734
              ],
              [
                -73.13768473567653,
                -8.70175507101585
              ],
              [
                -73.14133476384741,
                -8.698568582638421
              ],
              [
                -73.14486025157728,
                -8.693997610615734
              ],
              [
                -73.14838923479722,
                -8.690416608061394
              ],
              [
                -73.15087621222392,
                -8.686628653475996
              ],
              [
                -73.15346620745815,
                -8.68403715098082
              ],
              [
                -73.16385367880187,
                -8.68791116060032
              ],
              [
                -73.1642406691382,
                -8.688055654809558
              ],
              [
                -73.17059163010224,
                -8.685618681537063
              ],
              [
                -73.1749326423732,
                -8.682653186606688
              ],
              [
                -73.1786041054553,
                -8.68014469550476
              ],
              [
                -73.18377461079987,
                -8.678395183700871
              ],
              [
                -73.20032701175782,
                -8.68654271464329
              ],
              [
                -73.21146348610822,
                -8.685309205444348
              ],
              [
                -73.22499044225025,
                -8.677668749138975
              ],
              [
                -73.23340993354556,
                -8.665969791520187
              ],
              [
                -73.23382692386733,
                -8.665389818620183
              ],
              [
                -73.23983491759016,
                -8.66002382925407
              ],
              [
                -73.24694488829437,
                -8.64928887819877
              ],
              [
                -73.2472459061526,
                -8.64398388516277
              ],
              [
                -73.2475514041003,
                -8.638604926324636
              ],
              [
                -73.24914091522997,
                -8.630585931128266
              ],
              [
                -73.25288588988457,
                -8.628198441780434
              ],
              [
                -73.25750540160253,
                -8.62826795946872
              ],
              [
                -73.26134035151394,
                -8.627488967152784
              ],
              [
                -73.26118888708928,
                -8.620146965634751
              ],
              [
                -73.263316390633,
                -8.61450750616508
              ],
              [
                -73.26914083803601,
                -8.6124720329942
              ],
              [
                -73.2743313163651,
                -8.613397030339165
              ],
              [
                -73.27458383534908,
                -8.613449034581413
              ],
              [
                -73.2878297850675,
                -8.616177042295986
              ],
              [
                -73.29056628220424,
                -8.61464502525547
              ],
              [
                -73.29337276807769,
                -8.600425068601139
              ],
              [
                -73.29359528692154,
                -8.595296596984895
              ],
              [
                -73.29219827254096,
                -8.590322591580943
              ],
              [
                -73.28984329217526,
                -8.584368625261728
              ],
              [
                -73.28950180616353,
                -8.583216133692892
              ],
              [
                -73.28755882215872,
                -8.576660644526196
              ],
              [
                -73.28684732676847,
                -8.569304139229649
              ],
              [
                -73.2851738516405,
                -8.56013367614962
              ],
              [
                -73.28394137296722,
                -8.550891223917953
              ],
              [
                -73.2837238932524,
                -8.538455255349204
              ],
              [
                -73.28124992202845,
                -8.525924254727297
              ],
              [
                -73.27937044884294,
                -8.52063677448346
              ],
              [
                -73.27752594937785,
                -8.515447315046863
              ],
              [
                -73.27766145241443,
                -8.51238031909038
              ],
              [
                -73.27816898150482,
                -8.500902332201546
              ],
              [
                -73.27799499718719,
                -8.496816865403316
              ],
              [
                -73.27743900450054,
                -8.483775378982601
              ],
              [
                -73.2772739988355,
                -8.47989442268264
              ],
              [
                -73.27980550588384,
                -8.474840404327807
              ],
              [
                -73.28419349005323,
                -8.475207431795225
              ],
              [
                -73.28452699377407,
                -8.475235417210603
              ],
              [
                -73.28454698169836,
                -8.47523641223724
              ],
              [
                -73.29698993113821,
                -8.47549845073808
              ],
              [
                -73.29701692903662,
                -8.475499439774982
              ],
              [
                -73.30657289629711,
                -8.475700934216498
              ],
              [
                -73.32342380689742,
                -8.474302486402635
              ],
              [
                -73.32922329635267,
                -8.475021490037133
              ],
              [
                -73.33107280317282,
                -8.471851520832642
              ],
              [
                -73.33754978555629,
                -8.468206013595946
              ],
              [
                -73.34936320902219,
                -8.470110523981916
              ],
              [
                -73.35594871008789,
                -8.467780062539017
              ],
              [
                -73.36044166977783,
                -8.466211556095733
              ],
              [
                -73.36889966234968,
                -8.464276582370372
              ],
              [
                -73.37163965313724,
                -8.465012081495122
              ],
              [
                -73.37464810782839,
                -8.465819070830445
              ],
              [
                -73.3778066088802,
                -8.466005581965655
              ],
              [
                -73.38126009414249,
                -8.468705085135081
              ],
              [
                -73.3891615710932,
                -8.469287593190666
              ],
              [
                -73.39485006916746,
                -8.461082105367067
              ],
              [
                -73.40148953978205,
                -8.447395678864906
              ],
              [
                -73.40210353849082,
                -8.445370674911436
              ],
              [
                -73.40549156640769,
                -8.434184732307594
              ],
              [
                -73.40651555062195,
                -8.419179753996216
              ],
              [
                -73.41215856433995,
                -8.410612763002293
              ],
              [
                -73.41529354421269,
                -8.41031380031075
              ],
              [
                -73.42392550561452,
                -8.407418295199927
              ],
              [
                -73.4290275097759,
                -8.408805824068157
              ],
              [
                -73.43092148504128,
                -8.409320840505051
              ],
              [
                -73.44467794073796,
                -8.407006831339407
              ],
              [
                -73.45347290358426,
                -8.40205836466431
              ],
              [
                -73.45626589050629,
                -8.40054988454427
              ],
              [
                -73.45850237203946,
                -8.39934139185485
              ],
              [
                -73.46104037970314,
                -8.397968904018049
              ],
              [
                -73.4617168685187,
                -8.397759878528987
              ],
              [
                -73.47235785115393,
                -8.394481898264988
              ],
              [
                -73.48500180030432,
                -8.39061446138645
              ],
              [
                -73.48915030410802,
                -8.38578197064789
              ],
              [
                -73.48907427199684,
                -8.383993455074357
              ],
              [
                -73.48881081394141,
                -8.377838003309238
              ],
              [
                -73.49279925570455,
                -8.378234978067931
              ],
              [
                -73.50230023077233,
                -8.378252521759284
              ],
              [
                -73.50623772230112,
                -8.378259010435618
              ],
              [
                -73.51008120712002,
                -8.374875015567001
              ],
              [
                -73.51452119851537,
                -8.370965528888602
              ],
              [
                -73.51601217995538,
                -8.369652052370238
              ],
              [
                -73.52056469577312,
                -8.3631085685219
              ],
              [
                -73.52313017172418,
                -8.359421068566453
              ],
              [
                -73.52383820195166,
                -8.3588535910256
              ],
              [
                -73.52707115086207,
                -8.35626010525913
              ],
              [
                -73.52855366656645,
                -8.35272260735842
              ],
              [
                -73.52910218945463,
                -8.351415109461522
              ],
              [
                -73.53023468057636,
                -8.350537135318085
              ],
              [
                -73.53674115204024,
                -8.345494150467397
              ],
              [
                -73.53601067466538,
                -8.340020666280802
              ],
              [
                -73.53448420584336,
                -8.32858820005873
              ],
              [
                -73.52948871606017,
                -8.31346524524227
              ],
              [
                -73.53160223646609,
                -8.307823734591397
              ],
              [
                -73.53522973514578,
                -8.303684265297642
              ],
              [
                -73.53712320932422,
                -8.301523765494222
              ],
              [
                -73.53724120628881,
                -8.30124876836118
              ],
              [
                -73.53990170952197,
                -8.29502431096921
              ],
              [
                -73.53951872357366,
                -8.28764330715789
              ],
              [
                -73.5379367308103,
                -8.283177804234814
              ],
              [
                -73.53584724596578,
                -8.277280850228141
              ],
              [
                -73.53618526335832,
                -8.269894360481342
              ],
              [
                -73.54010024426982,
                -8.26275739020742
              ],
              [
                -73.5474352349329,
                -8.255418411317322
              ],
              [
                -73.55023923478923,
                -8.250452422932636
              ],
              [
                -73.5537627304937,
                -8.247238443117904
              ],
              [
                -73.55707069304756,
                -8.246074434785225
              ],
              [
                -73.56003922560808,
                -8.23531048936081
              ],
              [
                -73.56365117271858,
                -8.231585495961783
              ],
              [
                -73.57026968565334,
                -8.230075513203975
              ],
              [
                -73.57142817879277,
                -8.229810514523338
              ],
              [
                -73.57269516597404,
                -8.227143510098074
              ],
              [
                -73.57343416249519,
                -8.22558854809774
              ],
              [
                -73.57394616258736,
                -8.22071953719447
              ],
              [
                -73.57424118652446,
                -8.217910548750481
              ],
              [
                -73.57757416530117,
                -8.21373259509476
              ],
              [
                -73.57757415576155,
                -8.210610576028168
              ],
              [
                -73.58236614504628,
                -8.208640109828158
              ],
              [
                -73.5891281222571,
                -8.20728158986323
              ],
              [
                -73.58958213572765,
                -8.207190108739006
              ],
              [
                -73.59303462551644,
                -8.206201595142051
              ],
              [
                -73.59691810452618,
                -8.205357105789245
              ],
              [
                -73.58962215731299,
                -8.18634066477013
              ],
              [
                -73.5884841598581,
                -8.172717699804329
              ],
              [
                -73.59440468841802,
                -8.142270794632251
              ],
              [
                -73.58936623768746,
                -8.133190821679769
              ],
              [
                -73.58606474117201,
                -8.127240329210716
              ],
              [
                -73.59709675098954,
                -8.097370419839631
              ],
              [
                -73.60245023070185,
                -8.092915440687985
              ],
              [
                -73.61021421643228,
                -8.086456000009322
              ],
              [
                -73.61909318618535,
                -8.069252034270862
              ],
              [
                -73.6342536652791,
                -8.061118100130937
              ],
              [
                -73.63494962984076,
                -8.05747961837384
              ],
              [
                -73.62710369337461,
                -8.04039613803815
              ],
              [
                -73.62660971367916,
                -8.03932161909471
              ],
              [
                -73.63005870004872,
                -8.021453700500544
              ],
              [
                -73.63161469043948,
                -8.019972205440226
              ],
              [
                -73.63366770267776,
                -8.018017229277037
              ],
              [
                -73.64943014511748,
                -8.0030112644062
              ],
              [
                -73.65803662479941,
                -8.005856759048166
              ],
              [
                -73.66180411258345,
                -8.006929274524776
              ],
              [
                -73.66342310204455,
                -8.007390774588028
              ],
              [
                -73.666591092461,
                -8.008142799054331
              ],
              [
                -73.67115506321903,
                -8.007155786885482
              ],
              [
                -73.6750370627398,
                -8.005969785278916
              ],
              [
                -73.67827052069626,
                -8.004731307907571
              ],
              [
                -73.68176752311919,
                -7.992405330148243
              ],
              [
                -73.68299252886227,
                -7.989383339541431
              ],
              [
                -73.6854300353442,
                -7.983372392225856
              ],
              [
                -73.68846005096383,
                -7.971378403979108
              ],
              [
                -73.69232202338526,
                -7.963825441555705
              ],
              [
                -73.6974010419271,
                -7.960208470767145
              ],
              [
                -73.7011714883602,
                -7.96010797621096
              ],
              [
                -73.70489399185729,
                -7.960088943381195
              ],
              [
                -73.71066599590534,
                -7.960073480362446
              ],
              [
                -73.71721044454618,
                -7.959774484128683
              ],
              [
                -73.72041393534572,
                -7.960183475495496
              ],
              [
                -73.72351943813592,
                -7.962301490378895
              ],
              [
                -73.72433591249482,
                -7.962789973259793
              ],
              [
                -73.7325833657261,
                -7.967718972079492
              ],
              [
                -73.73401085771815,
                -7.964708000701071
              ],
              [
                -73.7341018630154,
                -7.96136751232057
              ],
              [
                -73.7360228677004,
                -7.95944950584165
              ],
              [
                -73.7367103731562,
                -7.958763015533323
              ],
              [
                -73.73922587286249,
                -7.958161023525095
              ],
              [
                -73.74043837286638,
                -7.957870021933136
              ],
              [
                -73.7409958630287,
                -7.957736512039558
              ],
              [
                -73.74432333587609,
                -7.956351018139133
              ],
              [
                -73.74628883698007,
                -7.952569533454288
              ],
              [
                -73.7467858352691,
                -7.948888556494813
              ],
              [
                -73.74718383890287,
                -7.945939563539113
              ],
              [
                -73.74664234355176,
                -7.939654096778958
              ],
              [
                -73.74626286801185,
                -7.935248111465278
              ],
              [
                -73.75070788125417,
                -7.923386640737241
              ],
              [
                -73.75878234378757,
                -7.913365158290895
              ],
              [
                -73.76056882936511,
                -7.911147701376803
              ],
              [
                -73.77124730115695,
                -7.905710216810991
              ],
              [
                -73.77179330028402,
                -7.901997710998728
              ],
              [
                -73.77093429900081,
                -7.898646709348717
              ],
              [
                -73.77036583810424,
                -7.896430754209786
              ],
              [
                -73.76583885684695,
                -7.895452719517189
              ],
              [
                -73.75814288701595,
                -7.893791223240899
              ],
              [
                -73.75579886592676,
                -7.892032215521803
              ],
              [
                -73.76103487551019,
                -7.8836067571251
              ],
              [
                -73.76939436265351,
                -7.87015582677566
              ],
              [
                -73.76603887589953,
                -7.862049331867516
              ],
              [
                -73.75873689897027,
                -7.857942811413089
              ],
              [
                -73.74938746343798,
                -7.865564788259728
              ],
              [
                -73.74707095025286,
                -7.86745378228915
              ],
              [
                -73.74502445081336,
                -7.872466251715493
              ],
              [
                -73.74184096671189,
                -7.872938762176413
              ],
              [
                -73.71524855125303,
                -7.875107731435961
              ],
              [
                -73.70711110305538,
                -7.872737707419581
              ],
              [
                -73.70373709047833,
                -7.872617716134835
              ],
              [
                -73.70037162708644,
                -7.869418230742813
              ],
              [
                -73.69779113882107,
                -7.866964743513286
              ],
              [
                -73.69598814085687,
                -7.863686731454936
              ],
              [
                -73.69382417109836,
                -7.859753749587174
              ],
              [
                -73.69079468659676,
                -7.856470756883222
              ],
              [
                -73.68856972316034,
                -7.85246175413702
              ],
              [
                -73.68329522030383,
                -7.842959258349477
              ],
              [
                -73.6836852371573,
                -7.836889283451
              ],
              [
                -73.68626274678847,
                -7.828806828681336
              ],
              [
                -73.68650474534304,
                -7.82407684514884
              ],
              [
                -73.686680266384,
                -7.820635341220666
              ],
              [
                -73.68441276545782,
                -7.811191878407936
              ],
              [
                -73.68280577235727,
                -7.803781385278858
              ],
              [
                -73.68280527336384,
                -7.803777876287192
              ],
              [
                -73.68199178164006,
                -7.79838038406297
              ],
              [
                -73.6816798305756,
                -7.789667946043445
              ],
              [
                -73.68359833099471,
                -7.776066952609597
              ],
              [
                -73.68472982819311,
                -7.77509548162977
              ],
              [
                -73.6889303027975,
                -7.771485981559747
              ],
              [
                -73.69090780116505,
                -7.767370989801628
              ],
              [
                -73.69628930517035,
                -7.763248011631368
              ],
              [
                -73.70512624670965,
                -7.758321047157931
              ],
              [
                -73.71299824508871,
                -7.755378084280034
              ],
              [
                -73.72188019263166,
                -7.748114585849914
              ],
              [
                -73.7318801742585,
                -7.74223762833946
              ],
              [
                -73.73982213090983,
                -7.733720177840929
              ],
              [
                -73.74322265390151,
                -7.733481174002183
              ],
              [
                -73.74889462211408,
                -7.732161679974153
              ],
              [
                -73.75571208346437,
                -7.73063168501552
              ],
              [
                -73.75654558970308,
                -7.730439178623744
              ],
              [
                -73.75945357707269,
                -7.729765676239103
              ],
              [
                -73.76904253755357,
                -7.727376208183808
              ],
              [
                -73.77815249164513,
                -7.725089742218154
              ],
              [
                -73.77816650359846,
                -7.72508673224459
              ],
              [
                -73.78609148059158,
                -7.723097753928084
              ],
              [
                -73.7877264801282,
                -7.722761261974052
              ],
              [
                -73.78936045966773,
                -7.722425745016044
              ],
              [
                -73.79140345836973,
                -7.722357260920441
              ],
              [
                -73.808838378123,
                -7.721769275715245
              ],
              [
                -73.814117372603,
                -7.721355309825748
              ],
              [
                -73.81772635913921,
                -7.72092731574079
              ],
              [
                -73.82199433520245,
                -7.71833530306596
              ],
              [
                -73.82660981620691,
                -7.715598815221318
              ],
              [
                -73.83488731446423,
                -7.699079862473098
              ],
              [
                -73.8399503037113,
                -7.688975913037472
              ],
              [
                -73.84746730433278,
                -7.675696443973647
              ],
              [
                -73.85285076438987,
                -7.676091966113723
              ],
              [
                -73.85691076036069,
                -7.676384472759811
              ],
              [
                -73.85693426127396,
                -7.676385957787224
              ],
              [
                -73.86223222931741,
                -7.676279998096022
              ],
              [
                -73.87972920106033,
                -7.662950041350937
              ],
              [
                -73.88222566498388,
                -7.659262545072109
              ],
              [
                -73.88490068361563,
                -7.655310562703336
              ],
              [
                -73.88593368053013,
                -7.650326577792181
              ],
              [
                -73.88607319072736,
                -7.64228059651752
              ],
              [
                -73.88620668344929,
                -7.634600140308327
              ],
              [
                -73.88809468106483,
                -7.631276650291811
              ],
              [
                -73.89554265492514,
                -7.627007679030214
              ],
              [
                -73.90354912054578,
                -7.625934674339801
              ],
              [
                -73.90861210623915,
                -7.625255173757577
              ],
              [
                -73.9113821064534,
                -7.623615709596599
              ],
              [
                -73.92998755015108,
                -7.609606275860576
              ],
              [
                -73.93197905898253,
                -7.608106767307254
              ],
              [
                -73.9357700374556,
                -7.603505274030431
              ],
              [
                -73.93577205445098,
                -7.603503279038126
              ],
              [
                -73.93694704513676,
                -7.602062766257157
              ],
              [
                -73.93715755772202,
                -7.60180478519191
              ],
              [
                -73.93752953159333,
                -7.60164829208045
              ],
              [
                -73.9430155099487,
                -7.599340790181929
              ],
              [
                -73.94522399738676,
                -7.595280799434309
              ],
              [
                -73.95035149542822,
                -7.585856834192529
              ],
              [
                -73.95638200337955,
                -7.578587880636917
              ],
              [
                -73.9613254672308,
                -7.573593391850953
              ],
              [
                -73.96132748322619,
                -7.573591396858633
              ],
              [
                -73.96812947704294,
                -7.567359931654887
              ],
              [
                -73.97108496385378,
                -7.567051925313082
              ],
              [
                -73.97167442582186,
                -7.566990412242284
              ],
              [
                -73.97218995104446,
                -7.566936928054192
              ],
              [
                -73.97867343220973,
                -7.566632921325504
              ],
              [
                -73.98187341694135,
                -7.566483461899849
              ],
              [
                -73.98482541925435,
                -7.560488483028145
              ],
              [
                -73.98757339575006,
                -7.55490696483495
              ],
              [
                -73.98987441181438,
                -7.54096601034361
              ],
              [
                -73.99023840505706,
                -7.535974031535106
              ],
              [
                -73.98734841457016,
                -7.531270553748548
              ],
              [
                -73.97994795996125,
                -7.5313600378662
              ],
              [
                -73.97834694857346,
                -7.531454539536664
              ],
              [
                -73.9765619768303,
                -7.531560522937819
              ],
              [
                -73.97317698088314,
                -7.531619551371467
              ],
              [
                -73.9687950135786,
                -7.530864045583106
              ],
              [
                -73.9593705497186,
                -7.528306034822898
              ],
              [
                -73.95080858596013,
                -7.526373002599398
              ],
              [
                -73.9472955827407,
                -7.526238513364278
              ],
              [
                -73.94299060511064,
                -7.519419050192137
              ],
              [
                -73.941288620971,
                -7.513488048148685
              ],
              [
                -73.93259969036613,
                -7.494171113317353
              ],
              [
                -73.92898873190636,
                -7.477594149131917
              ],
              [
                -73.91910176438387,
                -7.465302177885857
              ],
              [
                -73.92257676417032,
                -7.465094185911849
              ],
              [
                -73.9251627455614,
                -7.460716171493486
              ],
              [
                -73.92720978093284,
                -7.451671205361298
              ],
              [
                -73.93068178705325,
                -7.424144801557224
              ],
              [
                -73.94073676877427,
                -7.410328342988313
              ],
              [
                -73.94404073476491,
                -7.405788375896845
              ],
              [
                -73.95544725617624,
                -7.382972439157924
              ],
              [
                -73.95843322408443,
                -7.377816477231273
              ],
              [
                -73.96068572283097,
                -7.372494967791426
              ],
              [
                -73.96154071735488,
                -7.370474486078512
              ],
              [
                -73.96287772575381,
                -7.364077510235426
              ],
              [
                -73.96693172567706,
                -7.35981650836357
              ],
              [
                -73.9659597326334,
                -7.351624533196703
              ],
              [
                -73.9635012550287,
                -7.348035535301907
              ],
              [
                -73.96174226461014,
                -7.345469064669297
              ],
              [
                -73.95856727053602,
                -7.345825540711724
              ],
              [
                -73.9539572968453,
                -7.346780031387793
              ],
              [
                -73.95056879559536,
                -7.347094047266971
              ],
              [
                -73.94431882498506,
                -7.357290519043061
              ],
              [
                -73.9421578240598,
                -7.360948503866343
              ],
              [
                -73.93445033932781,
                -7.351866514450736
              ],
              [
                -73.93068186977399,
                -7.351929487490194
              ],
              [
                -73.9185718957515,
                -7.365621452744998
              ],
              [
                -73.91682540930829,
                -7.36852495202783
              ],
              [
                -73.91332091143443,
                -7.374352434523779
              ],
              [
                -73.90930091557702,
                -7.377709401730318
              ],
              [
                -73.89124798706446,
                -7.378034860828435
              ],
              [
                -73.88668401135065,
                -7.378137369733655
              ],
              [
                -73.87837756082915,
                -7.378457858294919
              ],
              [
                -73.87294404437955,
                -7.378463847338851
              ],
              [
                -73.86943208803842,
                -7.378452342882726
              ],
              [
                -73.86670608628596,
                -7.377335330290371
              ],
              [
                -73.86267860251932,
                -7.373644354698616
              ],
              [
                -73.85968413046463,
                -7.36883388832995
              ],
              [
                -73.8549351665136,
                -7.35167142773874
              ],
              [
                -73.8520711728481,
                -7.346958908311982
              ],
              [
                -73.85204568199411,
                -7.346916439389685
              ],
              [
                -73.85157767186432,
                -7.34677242516793
              ],
              [
                -73.847514196105,
                -7.345520905246605
              ],
              [
                -73.84257421185225,
                -7.343999432913608
              ],
              [
                -73.83969624341633,
                -7.342283419709147
              ],
              [
                -73.83628273532089,
                -7.34024692865846
              ],
              [
                -73.83224878493589,
                -7.337840407783809
              ],
              [
                -73.8294282722523,
                -7.336158449571384
              ],
              [
                -73.82428029774672,
                -7.335434410916847
              ],
              [
                -73.82046281406512,
                -7.335007905179252
              ],
              [
                -73.81712031218208,
                -7.335750923004139
              ],
              [
                -73.80803988479384,
                -7.338239400002441
              ],
              [
                -73.80702385707674,
                -7.338542378923957
              ],
              [
                -73.79644842226362,
                -7.34169087329784
              ],
              [
                -73.79336991843532,
                -7.340868383528464
              ],
              [
                -73.78908744494676,
                -7.339271375248298
              ],
              [
                -73.78731792518337,
                -7.338611373720422
              ],
              [
                -73.77967248872912,
                -7.334759368052986
              ],
              [
                -73.77272150373288,
                -7.331722392152757
              ],
              [
                -73.76740401603251,
                -7.331672384538146
              ],
              [
                -73.76129306068755,
                -7.332031860848649
              ],
              [
                -73.75894806448981,
                -7.334926863316826
              ],
              [
                -73.75903557041264,
                -7.337094834769088
              ],
              [
                -73.75920404649479,
                -7.341261822107971
              ],
              [
                -73.76229002625695,
                -7.34680730056084
              ],
              [
                -73.7584455615328,
                -7.346523787414245
              ],
              [
                -73.75573704780481,
                -7.345372801975604
              ],
              [
                -73.74670658567841,
                -7.343666297957166
              ],
              [
                -73.72854667322048,
                -7.335230329949569
              ],
              [
                -73.71707173713844,
                -7.327909799555836
              ],
              [
                -73.71487524733631,
                -7.325367816429061
              ],
              [
                -73.71170675749094,
                -7.321699311025359
              ],
              [
                -73.70994378854353,
                -7.318032343402105
              ],
              [
                -73.70873779710259,
                -7.31552433744926
              ],
              [
                -73.70026631610719,
                -7.305065882166284
              ],
              [
                -73.69871184340218,
                -7.29902439005793
              ],
              [
                -73.69920684550183,
                -7.292785406060273
              ],
              [
                -73.70340882922635,
                -7.285688441989944
              ],
              [
                -73.70799431695811,
                -7.28143893192253
              ],
              [
                -73.71170080585051,
                -7.278003939606165
              ],
              [
                -73.71549182468925,
                -7.267233476649475
              ],
              [
                -73.71563084934218,
                -7.257583525165314
              ],
              [
                -73.71559582563724,
                -7.257450027471966
              ],
              [
                -73.71285286682938,
                -7.246921549694295
              ],
              [
                -73.71312586573944,
                -7.235058098224402
              ],
              [
                -73.72368582213687,
                -7.220952641516584
              ],
              [
                -73.73215880539028,
                -7.215394653707699
              ],
              [
                -73.74448476248713,
                -7.210005706248296
              ],
              [
                -73.74834774040842,
                -7.206880705274762
              ],
              [
                -73.75298173675534,
                -7.201182253018238
              ],
              [
                -73.75308275254525,
                -7.20105773447115
              ],
              [
                -73.75373522755399,
                -7.199960730165373
              ],
              [
                -73.75496123270811,
                -7.197885727142584
              ],
              [
                -73.75530174642968,
                -7.196320738677273
              ],
              [
                -73.75606726004995,
                -7.192802762871475
              ],
              [
                -73.75646374688885,
                -7.190980263152674
              ],
              [
                -73.75793524495614,
                -7.172202821329936
              ],
              [
                -73.76584772055948,
                -7.163172367882792
              ],
              [
                -73.77173922875889,
                -7.158740394832024
              ],
              [
                -73.77503972490807,
                -7.156257901443879
              ],
              [
                -73.77582568778678,
                -7.154066382176262
              ],
              [
                -73.77698519560701,
                -7.150832888109355
              ],
              [
                -73.77705322130498,
                -7.150071889193607
              ],
              [
                -73.77746122949515,
                -7.145502905707766
              ],
              [
                -73.78181970981782,
                -7.14221392374103
              ],
              [
                -73.78187621462601,
                -7.142203945837112
              ],
              [
                -73.78546869042897,
                -7.141570436942
              ],
              [
                -73.79630166029521,
                -7.132686973662424
              ],
              [
                -73.79529966381467,
                -7.123871510589199
              ],
              [
                -73.80369916030826,
                -7.111057054613886
              ],
              [
                -73.79614469973451,
                -7.114987550982297
              ],
              [
                -73.76384576470076,
                -7.131794462951677
              ],
              [
                -73.75314331975218,
                -7.137184409547063
              ],
              [
                -73.74244133481824,
                -7.142573884128667
              ],
              [
                -73.60817781573536,
                -7.201941549680493
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 2,
        "nome": "Alagoas",
        "sigla": "AL",
        "regiao_id": 4,
        "codigo_ibg": 27
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -35.46516145422198,
                -8.825491626791191
              ],
              [
                -35.46625993726577,
                -8.827836592544438
              ],
              [
                -35.461251488680965,
                -8.830012587396144
              ],
              [
                -35.45887497168748,
                -8.83752699709912
              ],
              [
                -35.45035807471137,
                -8.841007997831932
              ],
              [
                -35.43537070871882,
                -8.84049997797052
              ],
              [
                -35.43393471025256,
                -8.83871199161176
              ],
              [
                -35.43141621974129,
                -8.835576496782059
              ],
              [
                -35.41619036505324,
                -8.83840648283707
              ],
              [
                -35.41214839528744,
                -8.836479986334709
              ],
              [
                -35.408516438223025,
                -8.837556972880913
              ],
              [
                -35.40755694477614,
                -8.83886648290258
              ],
              [
                -35.399483995329305,
                -8.849879884568203
              ],
              [
                -35.38602560668485,
                -8.852720350038608
              ],
              [
                -35.35027392183859,
                -8.860266265956836
              ],
              [
                -35.31107226939235,
                -8.870111645064512
              ],
              [
                -35.28713849305121,
                -8.876123082956095
              ],
              [
                -35.27707110448567,
                -8.874728586192404
              ],
              [
                -35.25226878386917,
                -8.886802487246051
              ],
              [
                -35.235451438634186,
                -8.882979505589628
              ],
              [
                -35.21959006840255,
                -8.888839919523546
              ],
              [
                -35.21194667203985,
                -8.88846795289259
              ],
              [
                -35.21093314627402,
                -8.888418952408765
              ],
              [
                -35.21058316707546,
                -8.8888509537532
              ],
              [
                -35.208572703169295,
                -8.891331399763047
              ],
              [
                -35.20263323909296,
                -8.888950433198893
              ],
              [
                -35.19974677127197,
                -8.887792926733116
              ],
              [
                -35.17622195814781,
                -8.888406422910316
              ],
              [
                -35.15519267205165,
                -8.902222774569235
              ],
              [
                -35.152970665988484,
                -8.91059871955953
              ],
              [
                -35.15293668122262,
                -8.910684744861765
              ],
              [
                -35.152042684379005,
                -8.912951225480377
              ],
              [
                -35.15365714110021,
                -8.917149661225512
              ],
              [
                -35.155707637073306,
                -8.922260640255029
              ],
              [
                -35.16080106980241,
                -8.928095086271634
              ],
              [
                -35.1608555832536,
                -8.928157594832593
              ],
              [
                -35.16812602506421,
                -8.936486056348746
              ],
              [
                -35.17133748871139,
                -8.951719409777528
              ],
              [
                -35.17219096345323,
                -8.955765892085031
              ],
              [
                -35.18150937387198,
                -8.977638753278429
              ],
              [
                -35.189044283925675,
                -8.984036683112054
              ],
              [
                -35.20974959105499,
                -9.001236592532099
              ],
              [
                -35.220340985381156,
                -9.015628953079922
              ],
              [
                -35.223002449069895,
                -9.019245940341365
              ],
              [
                -35.22478844046978,
                -9.025405894135726
              ],
              [
                -35.22592790268868,
                -9.029333861676388
              ],
              [
                -35.229295408512996,
                -9.034608343716672
              ],
              [
                -35.22936090383024,
                -9.034709314990945
              ],
              [
                -35.23096988904892,
                -9.037199316090039
              ],
              [
                -35.23828979560365,
                -9.048633208852047
              ],
              [
                -35.2377728214602,
                -9.052990192649068
              ],
              [
                -35.23742631403324,
                -9.055910680723972
              ],
              [
                -35.2368797840594,
                -9.06037614265847
              ],
              [
                -35.23998976645781,
                -9.064226110545158
              ],
              [
                -35.2385617660452,
                -9.068045585720364
              ],
              [
                -35.24351323291256,
                -9.076230513650883
              ],
              [
                -35.251449668949626,
                -9.082999481164897
              ],
              [
                -35.25388063806968,
                -9.085071965242681
              ],
              [
                -35.254287148076074,
                -9.085418964911394
              ],
              [
                -35.256669607462726,
                -9.087687449431693
              ],
              [
                -35.256945608202166,
                -9.089675433285155
              ],
              [
                -35.2571120876295,
                -9.090877410127208
              ],
              [
                -35.25840156069856,
                -9.10018636020941
              ],
              [
                -35.267191475508135,
                -9.112003788464527
              ],
              [
                -35.26990846092175,
                -9.122086677849643
              ],
              [
                -35.27604342183967,
                -9.124838672945302
              ],
              [
                -35.27609988930496,
                -9.12486415779772
              ],
              [
                -35.28239483660705,
                -9.127804181585004
              ],
              [
                -35.28356281092832,
                -9.135776105970491
              ],
              [
                -35.283722328514,
                -9.136864104698743
              ],
              [
                -35.285226820723274,
                -9.147141010573971
              ],
              [
                -35.287877261543905,
                -9.154036475608661
              ],
              [
                -35.29448620752599,
                -9.15525597100238
              ],
              [
                -35.294676223856285,
                -9.158559925637608
              ],
              [
                -35.294329197474184,
                -9.164755909454653
              ],
              [
                -35.29421869111858,
                -9.169636348648908
              ],
              [
                -35.29954964816254,
                -9.18151177461502
              ],
              [
                -35.301143629889815,
                -9.184662232689611
              ],
              [
                -35.30706608480504,
                -9.1929066972557
              ],
              [
                -35.31099005260862,
                -9.194710674797191
              ],
              [
                -35.31137904095568,
                -9.19485118705643
              ],
              [
                -35.31416652077763,
                -9.195859679736303
              ],
              [
                -35.31816047726963,
                -9.1973051531112
              ],
              [
                -35.322519425817944,
                -9.206146604720711
              ],
              [
                -35.32408390954913,
                -9.209582557587431
              ],
              [
                -35.326046399102346,
                -9.213651060928171
              ],
              [
                -35.32616738477952,
                -9.213902557094617
              ],
              [
                -35.32771237189538,
                -9.217104039759368
              ],
              [
                -35.33116381548216,
                -9.223912471288555
              ],
              [
                -35.3371727931098,
                -9.227131441791592
              ],
              [
                -35.34345122416568,
                -9.230495442421473
              ],
              [
                -35.34305520742185,
                -9.234196423522913
              ],
              [
                -35.34267521667071,
                -9.237742887831864
              ],
              [
                -35.35357561028743,
                -9.255000734465758
              ],
              [
                -35.365738460694004,
                -9.268656163066085
              ],
              [
                -35.368952453296814,
                -9.272264646130624
              ],
              [
                -35.37369840845479,
                -9.277594111309758
              ],
              [
                -35.381206351967286,
                -9.282496071262612
              ],
              [
                -35.384231809756905,
                -9.284472043749963
              ],
              [
                -35.38628578392614,
                -9.285813546256042
              ],
              [
                -35.38692927409297,
                -9.28691753932925
              ],
              [
                -35.38912176981017,
                -9.290678989324013
              ],
              [
                -35.39419470693524,
                -9.299380439866654
              ],
              [
                -35.39605370219131,
                -9.302568416989109
              ],
              [
                -35.407498596673705,
                -9.307859395531102
              ],
              [
                -35.41283754181662,
                -9.311685887875294
              ],
              [
                -35.41492301736908,
                -9.312292854077919
              ],
              [
                -35.4167574992618,
                -9.312827343613076
              ],
              [
                -35.420901449592314,
                -9.315161336377011
              ],
              [
                -35.41915197556264,
                -9.31612631737876
              ],
              [
                -35.41442550400887,
                -9.318733294068505
              ],
              [
                -35.423951905025,
                -9.321668311053445
              ],
              [
                -35.42594288677179,
                -9.321905319021305
              ],
              [
                -35.429274862225014,
                -9.322302313965244
              ],
              [
                -35.43277732541135,
                -9.324588266532018
              ],
              [
                -35.4378733091892,
                -9.327941268328903
              ],
              [
                -35.43813278976507,
                -9.32911727951729
              ],
              [
                -35.44020776438674,
                -9.33851167313766
              ],
              [
                -35.445965694020295,
                -9.339836176145417
              ],
              [
                -35.45386415794325,
                -9.341517177346208
              ],
              [
                -35.456755116197485,
                -9.342132193226725
              ],
              [
                -35.459487097468305,
                -9.344303157002956
              ],
              [
                -35.463313038032084,
                -9.347347629054665
              ],
              [
                -35.47164197039529,
                -9.356309086706668
              ],
              [
                -35.48141589224224,
                -9.366824491755358
              ],
              [
                -35.484012831162495,
                -9.369617487651354
              ],
              [
                -35.493342265619525,
                -9.386007849229095
              ],
              [
                -35.4941742453915,
                -9.393279316153023
              ],
              [
                -35.49521172164041,
                -9.402347727477705
              ],
              [
                -35.49564021030582,
                -9.406093739112697
              ],
              [
                -35.496006719033474,
                -9.409295180873194
              ],
              [
                -35.49666271080299,
                -9.415026654481517
              ],
              [
                -35.497138691655486,
                -9.419181614028805
              ],
              [
                -35.50184113806206,
                -9.418115132496139
              ],
              [
                -35.50228362814797,
                -9.418015130666577
              ],
              [
                -35.503050645747514,
                -9.419590634275753
              ],
              [
                -35.509624043745454,
                -9.433091520688928
              ],
              [
                -35.512872015159715,
                -9.439764478470188
              ],
              [
                -35.51914946697757,
                -9.441220474037548
              ],
              [
                -35.521170942240786,
                -9.441690965276297
              ],
              [
                -35.52476841489745,
                -9.442527955145042
              ],
              [
                -35.52602689340865,
                -9.445867454688143
              ],
              [
                -35.52701589902044,
                -9.448492909461887
              ],
              [
                -35.52704692066353,
                -9.44857490586156
              ],
              [
                -35.52839986009699,
                -9.452151913674415
              ],
              [
                -35.53016934273418,
                -9.456831864416172
              ],
              [
                -35.535284819668895,
                -9.457648376840051
              ],
              [
                -35.53801078176919,
                -9.460042834982856
              ],
              [
                -35.542093250321884,
                -9.46380230690932
              ],
              [
                -35.55302416002219,
                -9.47386624081663
              ],
              [
                -35.55372664803716,
                -9.475613742073223
              ],
              [
                -35.55490362165795,
                -9.478541710722839
              ],
              [
                -35.556344091974665,
                -9.482455198067214
              ],
              [
                -35.55802858849561,
                -9.48699665382917
              ],
              [
                -35.56337552699265,
                -9.491404146988856
              ],
              [
                -35.568361981049414,
                -9.495492612267489
              ],
              [
                -35.571981942517155,
                -9.498462088862306
              ],
              [
                -35.57409992572846,
                -9.500198581517111
              ],
              [
                -35.58034288445065,
                -9.505318028075916
              ],
              [
                -35.58635082747799,
                -9.51024600789399
              ],
              [
                -35.58877430406516,
                -9.519309956765794
              ],
              [
                -35.589256264015454,
                -9.521112925414553
              ],
              [
                -35.59026424836159,
                -9.524883901491965
              ],
              [
                -35.591589257727925,
                -9.529838371810618
              ],
              [
                -35.60235015942492,
                -9.530839349097763
              ],
              [
                -35.604742647570994,
                -9.531061373610964
              ],
              [
                -35.60970308918671,
                -9.53586384446689
              ],
              [
                -35.61360603333081,
                -9.53964129719046
              ],
              [
                -35.61390104771554,
                -9.540648800759298
              ],
              [
                -35.61600251097149,
                -9.547813261924297
              ],
              [
                -35.622810961638116,
                -9.54966624306583
              ],
              [
                -35.625978409192186,
                -9.550510245549177
              ],
              [
                -35.62643443094676,
                -9.5506382559933
              ],
              [
                -35.62995840113806,
                -9.55162974268082
              ],
              [
                -35.639076791363266,
                -9.560444677768473
              ],
              [
                -35.645956229875345,
                -9.567094635344132
              ],
              [
                -35.647671726344804,
                -9.567111137811603
              ],
              [
                -35.64938672181905,
                -9.567127617278938
              ],
              [
                -35.65813961931521,
                -9.580209054493585
              ],
              [
                -35.6624740739963,
                -9.58668697405886
              ],
              [
                -35.6649940556177,
                -9.590483950413756
              ],
              [
                -35.66753750575232,
                -9.592455938727584
              ],
              [
                -35.66839651235257,
                -9.593123952428085
              ],
              [
                -35.67540943077715,
                -9.598577390335704
              ],
              [
                -35.68384537349308,
                -9.610192857560177
              ],
              [
                -35.68629882307269,
                -9.613605817809663
              ],
              [
                -35.688627824524325,
                -9.61833777388303
              ],
              [
                -35.696701722945306,
                -9.634734148369988
              ],
              [
                -35.69888817766746,
                -9.647875063214876
              ],
              [
                -35.69790071419258,
                -9.651446026062459
              ],
              [
                -35.697190194166026,
                -9.65401699379478
              ],
              [
                -35.69553621641527,
                -9.659999959630971
              ],
              [
                -35.69434573807303,
                -9.664303935703808
              ],
              [
                -35.7042676348759,
                -9.66481593312481
              ],
              [
                -35.70737760061985,
                -9.664961929935114
              ],
              [
                -35.708412118220366,
                -9.665010943533993
              ],
              [
                -35.709772082280374,
                -9.666733920681558
              ],
              [
                -35.719327502331296,
                -9.678836354455985
              ],
              [
                -35.72298597304143,
                -9.683245306314786
              ],
              [
                -35.72233845979764,
                -9.676029384675497
              ],
              [
                -35.722311453327734,
                -9.675730349928216
              ],
              [
                -35.730482404408754,
                -9.670564907971647
              ],
              [
                -35.74966772869104,
                -9.676134384613569
              ],
              [
                -35.754159195582055,
                -9.677723867741053
              ],
              [
                -35.76091961396868,
                -9.68349335818302
              ],
              [
                -35.76508156053822,
                -9.687483818728834
              ],
              [
                -35.76591157526837,
                -9.688287313395337
              ],
              [
                -35.774272470958046,
                -9.696385270650724
              ],
              [
                -35.77699443605659,
                -9.699832246989931
              ],
              [
                -35.78774133153921,
                -9.710682662595678
              ],
              [
                -35.79233529458233,
                -9.712196661426951
              ],
              [
                -35.79413078279333,
                -9.712788640623826
              ],
              [
                -35.79761976375275,
                -9.716450631074068
              ],
              [
                -35.81234361598938,
                -9.731779033476327
              ],
              [
                -35.816513551986404,
                -9.740421975735664
              ],
              [
                -35.81664907949204,
                -9.740701966736259
              ],
              [
                -35.81863203379707,
                -9.744621451830895
              ],
              [
                -35.82374001070849,
                -9.750783890857043
              ],
              [
                -35.83369491425392,
                -9.762722298631727
              ],
              [
                -35.83966935175037,
                -9.769484755961667
              ],
              [
                -35.84281579688469,
                -9.773046229915302
              ],
              [
                -35.84775425021251,
                -9.779312194048467
              ],
              [
                -35.85539968645238,
                -9.788298148132512
              ],
              [
                -35.8604701354098,
                -9.795768081283725
              ],
              [
                -35.861055120238106,
                -9.796683082899213
              ],
              [
                -35.86634208946074,
                -9.804948018242824
              ],
              [
                -35.871319005044505,
                -9.812629473733772
              ],
              [
                -35.87468346258018,
                -9.817849943359786
              ],
              [
                -35.8810189039954,
                -9.827471860466604
              ],
              [
                -35.88504137587439,
                -9.833417807245155
              ],
              [
                -35.889062811761306,
                -9.839361803047185
              ],
              [
                -35.891278305820805,
                -9.844413230886758
              ],
              [
                -35.89674123530979,
                -9.856870171737992
              ],
              [
                -35.90230017365186,
                -9.869595561682756
              ],
              [
                -35.90591766260109,
                -9.873056059960362
              ],
              [
                -35.91227760027148,
                -9.879086016431328
              ],
              [
                -35.91521306304034,
                -9.881869501188561
              ],
              [
                -35.933703907902554,
                -9.899401357352643
              ],
              [
                -35.936759374413576,
                -9.902359354926123
              ],
              [
                -35.93981533491918,
                -9.905317835499645
              ],
              [
                -35.96195607680856,
                -9.941062595644588
              ],
              [
                -35.97424797581774,
                -9.965346914084419
              ],
              [
                -35.98560833561843,
                -9.980138804440646
              ],
              [
                -35.99016778757407,
                -9.98607675205635
              ],
              [
                -35.994761267176806,
                -9.992057751390778
              ],
              [
                -35.99808470488214,
                -9.996385204974413
              ],
              [
                -36.00350965089876,
                -10.003448661972032
              ],
              [
                -36.005281666906704,
                -10.006461616969183
              ],
              [
                -36.007862137249276,
                -10.010848111396374
              ],
              [
                -36.03006641318341,
                -10.04859133454005
              ],
              [
                -36.032219867062835,
                -10.051279823394266
              ],
              [
                -36.04250476740875,
                -10.064117728777898
              ],
              [
                -36.043995748132,
                -10.068920688056984
              ],
              [
                -36.04605773413221,
                -10.075284151147637
              ],
              [
                -36.05847009442803,
                -10.084983594361496
              ],
              [
                -36.068906995248355,
                -10.08406312262702
              ],
              [
                -36.07045402352758,
                -10.084829097390545
              ],
              [
                -36.08279989905176,
                -10.090944570574967
              ],
              [
                -36.08674234453913,
                -10.092897559780683
              ],
              [
                -36.09852373983347,
                -10.11120194071236
              ],
              [
                -36.10545114149203,
                -10.121964387630268
              ],
              [
                -36.107907148486795,
                -10.125780359370744
              ],
              [
                -36.11053060977703,
                -10.129787318845537
              ],
              [
                -36.1176365414983,
                -10.134943786146685
              ],
              [
                -36.11970552033398,
                -10.136438265971284
              ],
              [
                -36.1208574831065,
                -10.13727076785996
              ],
              [
                -36.121230483154854,
                -10.13785177386338
              ],
              [
                -36.126433934022565,
                -10.145959227102145
              ],
              [
                -36.133923382679406,
                -10.15760713303508
              ],
              [
                -36.13673435014225,
                -10.15682664070863
              ],
              [
                -36.140556834775886,
                -10.155766160496636
              ],
              [
                -36.14535428490187,
                -10.154434658294898
              ],
              [
                -36.14915474012356,
                -10.156924127387384
              ],
              [
                -36.15818014578168,
                -10.162837124989773
              ],
              [
                -36.16207112112712,
                -10.165387071728892
              ],
              [
                -36.17031052839353,
                -10.17078504543643
              ],
              [
                -36.17384300262858,
                -10.176607529254353
              ],
              [
                -36.17572248851044,
                -10.179728491705642
              ],
              [
                -36.17756296380759,
                -10.18278595459699
              ],
              [
                -36.18637185677345,
                -10.197144879662192
              ],
              [
                -36.198370768061416,
                -10.204600320779372
              ],
              [
                -36.207651659563204,
                -10.210366814857663
              ],
              [
                -36.21308612315832,
                -10.213743293003578
              ],
              [
                -36.232252415724,
                -10.235976633637419
              ],
              [
                -36.235969398819016,
                -10.240287624082587
              ],
              [
                -36.23682086614707,
                -10.241264112625075
              ],
              [
                -36.238670343308996,
                -10.243386594588822
              ],
              [
                -36.250196264297365,
                -10.254292013539912
              ],
              [
                -36.2577611578076,
                -10.26144946958943
              ],
              [
                -36.2680700599308,
                -10.271203408999918
              ],
              [
                -36.27171604356882,
                -10.274629891045999
              ],
              [
                -36.27698947114788,
                -10.281351318086674
              ],
              [
                -36.291705350344266,
                -10.30002770629709
              ],
              [
                -36.29208181816655,
                -10.300788714987457
              ],
              [
                -36.29862726150965,
                -10.314030114618134
              ],
              [
                -36.30221822869589,
                -10.326048038422254
              ],
              [
                -36.301861220925126,
                -10.331797482077603
              ],
              [
                -36.30163573170085,
                -10.3416419152646
              ],
              [
                -36.299758714288345,
                -10.344814372636408
              ],
              [
                -36.29836724436059,
                -10.347166360636473
              ],
              [
                -36.29513925661261,
                -10.351772322914606
              ],
              [
                -36.2932742695967,
                -10.354459798923717
              ],
              [
                -36.296118733592024,
                -10.357718777480052
              ],
              [
                -36.299921695860576,
                -10.36202974516095
              ],
              [
                -36.302747685690534,
                -10.362732248805395
              ],
              [
                -36.30696412664548,
                -10.363780255293102
              ],
              [
                -36.32776091815863,
                -10.392755569251353
              ],
              [
                -36.32884790978944,
                -10.394269538067354
              ],
              [
                -36.335374839485695,
                -10.403390974701741
              ],
              [
                -36.34180976982631,
                -10.415533907723947
              ],
              [
                -36.35083616666006,
                -10.43258980781779
              ],
              [
                -36.352155668161394,
                -10.43508277259028
              ],
              [
                -36.35347518066187,
                -10.437575742364219
              ],
              [
                -36.365444531102945,
                -10.460192110156687
              ],
              [
                -36.37141195749343,
                -10.471466527333611
              ],
              [
                -36.37141397047162,
                -10.471470044309482
              ],
              [
                -36.375079913159375,
                -10.478395964518045
              ],
              [
                -36.37771941813083,
                -10.483382924113277
              ],
              [
                -36.37933487134849,
                -10.486448899956741
              ],
              [
                -36.3822708320044,
                -10.494871864285908
              ],
              [
                -36.3910057492492,
                -10.500689845050927
              ],
              [
                -36.393427238250545,
                -10.498066845093957
              ],
              [
                -36.39358023207438,
                -10.497878334656257
              ],
              [
                -36.402995189694856,
                -10.486277929797005
              ],
              [
                -36.420405579304735,
                -10.444539767657497
              ],
              [
                -36.43111198688499,
                -10.423852432838617
              ],
              [
                -36.43433047008013,
                -10.420886477261378
              ],
              [
                -36.45574276803944,
                -10.407212077252554
              ],
              [
                -36.459410238562185,
                -10.406756102435923
              ],
              [
                -36.46307772508685,
                -10.406299578623486
              ],
              [
                -36.48835148344461,
                -10.42294749576934
              ],
              [
                -36.5011918650699,
                -10.428650976607786
              ],
              [
                -36.509581788767974,
                -10.432377958563846
              ],
              [
                -36.51119277425869,
                -10.432415443954552
              ],
              [
                -36.52178066790578,
                -10.432661461099269
              ],
              [
                -36.521036188852655,
                -10.430524456227685
              ],
              [
                -36.51885422006768,
                -10.42426500354387
              ],
              [
                -36.51073929717554,
                -10.421212012861268
              ],
              [
                -36.53812603048294,
                -10.421927045905742
              ],
              [
                -36.54248749058878,
                -10.42640300212716
              ],
              [
                -36.54510698122556,
                -10.429090998848078
              ],
              [
                -36.55130791919193,
                -10.424708544876344
              ],
              [
                -36.55385538922784,
                -10.422907554913834
              ],
              [
                -36.563759790723516,
                -10.415908125243675
              ],
              [
                -36.56031484689094,
                -10.39295277346597
              ],
              [
                -36.567302336518395,
                -10.365104476962918
              ],
              [
                -36.566489849275314,
                -10.332292229478165
              ],
              [
                -36.56892787462025,
                -10.325403281303474
              ],
              [
                -36.571365844961115,
                -10.318515347124448
              ],
              [
                -36.58287324775427,
                -10.307019412590256
              ],
              [
                -36.58850369713031,
                -10.301394461278338
              ],
              [
                -36.59078720978271,
                -10.286913586530524
              ],
              [
                -36.61140603943511,
                -10.271041228936037
              ],
              [
                -36.623312427559505,
                -10.25773031032406
              ],
              [
                -36.628844904931015,
                -10.254879375229217
              ],
              [
                -36.64268677858745,
                -10.252253388950916
              ],
              [
                -36.64317477178138,
                -10.252667891360813
              ],
              [
                -36.657592614777045,
                -10.264919804827771
              ],
              [
                -36.66885050978611,
                -10.26788927824155
              ],
              [
                -36.68063292396117,
                -10.270996801176457
              ],
              [
                -36.68310740433412,
                -10.27334577022374
              ],
              [
                -36.702752228318694,
                -10.269540302703415
              ],
              [
                -36.71993955467836,
                -10.264468344086376
              ],
              [
                -36.72629301404292,
                -10.25943441307027
              ],
              [
                -36.736136919046935,
                -10.251634487217789
              ],
              [
                -36.749301301823884,
                -10.249107505518042
              ],
              [
                -36.75268431021679,
                -10.2484579948194
              ],
              [
                -36.75632427366342,
                -10.243642057390607
              ],
              [
                -36.75587075498587,
                -10.243390534797804
              ],
              [
                -36.75140679753261,
                -10.240914054656686
              ],
              [
                -36.75186382920791,
                -10.235389112249413
              ],
              [
                -36.776430083648705,
                -10.225497176022522
              ],
              [
                -36.81087278926891,
                -10.218991275689618
              ],
              [
                -36.83283213723938,
                -10.204246921858791
              ],
              [
                -36.836552586573305,
                -10.201748437252514
              ],
              [
                -36.843006537452375,
                -10.192543994333471
              ],
              [
                -36.8449460200935,
                -10.184876067371167
              ],
              [
                -36.84633354239631,
                -10.179389095619717
              ],
              [
                -36.84635754627841,
                -10.179296111336217
              ],
              [
                -36.84714403348091,
                -10.17618611728372
              ],
              [
                -36.85035352178834,
                -10.161748740014866
              ],
              [
                -36.864619397396986,
                -10.15776979717023
              ],
              [
                -36.87655080229233,
                -10.14569437120391
              ],
              [
                -36.88126827031707,
                -10.14455488348887
              ],
              [
                -36.891172672293216,
                -10.14216593735439
              ],
              [
                -36.8915866416981,
                -10.142065927520271
              ],
              [
                -36.89490815185179,
                -10.141264938863689
              ],
              [
                -36.910463514774314,
                -10.137513474620954
              ],
              [
                -36.920639938289696,
                -10.127470047716123
              ],
              [
                -36.92067643705076,
                -10.127386036378429
              ],
              [
                -36.92227091265817,
                -10.123672588634763
              ],
              [
                -36.9223089204099,
                -10.123584570328408
              ],
              [
                -36.93014586030807,
                -10.105340719076164
              ],
              [
                -36.94066075532492,
                -10.094790807281152
              ],
              [
                -36.94302775264604,
                -10.092415319365935
              ],
              [
                -36.94412625413368,
                -10.0901938640169
              ],
              [
                -36.944365249498404,
                -10.089711334851376
              ],
              [
                -36.9474567173582,
                -10.083456408550624
              ],
              [
                -36.951971187481625,
                -10.074323984117127
              ],
              [
                -36.954001167598676,
                -10.070214980767242
              ],
              [
                -36.958516159595824,
                -10.046626171002057
              ],
              [
                -36.957692182615766,
                -10.036254240431447
              ],
              [
                -36.95699368688213,
                -10.027461825234157
              ],
              [
                -36.95725568800574,
                -10.026037826108796
              ],
              [
                -36.957318181396474,
                -10.02024188636095
              ],
              [
                -36.96112467299186,
                -10.006034460079585
              ],
              [
                -36.96390417157347,
                -9.999800018346912
              ],
              [
                -36.96668461814521,
                -9.993564578624525
              ],
              [
                -36.991600951018825,
                -9.9767757138186
              ],
              [
                -37.00091284364804,
                -9.976101257200924
              ],
              [
                -37.0096047785302,
                -9.977661252308986
              ],
              [
                -37.0190671953193,
                -9.981399207968519
              ],
              [
                -37.03743502610779,
                -9.988653674409985
              ],
              [
                -37.04334745565941,
                -9.987419690554274
              ],
              [
                -37.04926192119671,
                -9.986185694700401
              ],
              [
                -37.075106686662416,
                -9.965853871128594
              ],
              [
                -37.132688260762286,
                -9.913935320622375
              ],
              [
                -37.15254556073533,
                -9.900427930121115
              ],
              [
                -37.196209184496404,
                -9.898125992998324
              ],
              [
                -37.2261654181025,
                -9.899020499373883
              ],
              [
                -37.22880891686948,
                -9.89776749534901
              ],
              [
                -37.237060352475545,
                -9.893856538725275
              ],
              [
                -37.237491855064,
                -9.89343907826419
              ],
              [
                -37.24555628531029,
                -9.885634111424425
              ],
              [
                -37.26378563849397,
                -9.850010880789267
              ],
              [
                -37.285004963674915,
                -9.830535072954275
              ],
              [
                -37.2967963704154,
                -9.827070074511463
              ],
              [
                -37.302243838641225,
                -9.82546812187012
              ],
              [
                -37.32070818412243,
                -9.820038690699688
              ],
              [
                -37.34526296482973,
                -9.796576869786797
              ],
              [
                -37.35048895548284,
                -9.791583413155488
              ],
              [
                -37.35644538619391,
                -9.789020432160353
              ],
              [
                -37.362402317904284,
                -9.78645745516442
              ],
              [
                -37.365349326543914,
                -9.785666455984314
              ],
              [
                -37.39719054659836,
                -9.77711804728456
              ],
              [
                -37.407876939338394,
                -9.769645631512688
              ],
              [
                -37.41856336108814,
                -9.762173165735469
              ],
              [
                -37.46645195461728,
                -9.749100802586174
              ],
              [
                -37.548217743271216,
                -9.741001439137593
              ],
              [
                -37.57208655498141,
                -9.736968986857955
              ],
              [
                -37.57675198640369,
                -9.735259521200657
              ],
              [
                -37.58141744782806,
                -9.73355002154288
              ],
              [
                -37.656431860890926,
                -9.683216452925508
              ],
              [
                -37.659924826419505,
                -9.676847022693618
              ],
              [
                -37.664375315849455,
                -9.6681230728998
              ],
              [
                -37.665695782777085,
                -9.66553608342338
              ],
              [
                -37.66699426485742,
                -9.66299011362064
              ],
              [
                -37.67044625714338,
                -9.656221201319614
              ],
              [
                -37.68660164033021,
                -9.644268275098826
              ],
              [
                -37.698983506609665,
                -9.637573838072942
              ],
              [
                -37.711365421904084,
                -9.630879406040048
              ],
              [
                -37.74413863451692,
                -9.625729973684729
              ],
              [
                -37.747830103159615,
                -9.626476482792922
              ],
              [
                -37.75152357378591,
                -9.627222953904358
              ],
              [
                -37.75975200868645,
                -9.630629934755442
              ],
              [
                -37.77827583538679,
                -9.638300411177505
              ],
              [
                -37.781552801525095,
                -9.638226894970702
              ],
              [
                -37.7848302466603,
                -9.638153416764366
              ],
              [
                -37.788971225613345,
                -9.628696966029414
              ],
              [
                -37.792698733261105,
                -9.620185552876722
              ],
              [
                -37.79968314887801,
                -9.614936085741492
              ],
              [
                -37.80666858049138,
                -9.609685135615662
              ],
              [
                -37.80836509513955,
                -9.607073144675919
              ],
              [
                -37.817849008085254,
                -9.59247227240315
              ],
              [
                -37.823374965920394,
                -9.590876806700171
              ],
              [
                -37.825392935335636,
                -9.591652776934078
              ],
              [
                -37.83271137293834,
                -9.594466294284949
              ],
              [
                -37.83691937386963,
                -9.583435372294915
              ],
              [
                -37.841631815934285,
                -9.579802404905468
              ],
              [
                -37.88577145572284,
                -9.549747665442357
              ],
              [
                -37.893396896977514,
                -9.540257234373737
              ],
              [
                -37.89363791615191,
                -9.539957719833584
              ],
              [
                -37.91674471190245,
                -9.548536708937501
              ],
              [
                -37.96006282194677,
                -9.532816335198653
              ],
              [
                -37.988931090581666,
                -9.526938923207785
              ],
              [
                -37.99754653027734,
                -9.519381457714742
              ],
              [
                -38.00301997588579,
                -9.514579996695483
              ],
              [
                -38.00008252795587,
                -9.503347605082613
              ],
              [
                -37.99850501085661,
                -9.497316613254178
              ],
              [
                -38.009356955422064,
                -9.480152785197996
              ],
              [
                -38.0282587875363,
                -9.467618889689186
              ],
              [
                -38.03551222343211,
                -9.467899399717556
              ],
              [
                -38.042766647324136,
                -9.468180390745097
              ],
              [
                -38.057160545038016,
                -9.45878494551855
              ],
              [
                -38.06744893923667,
                -9.458086471778374
              ],
              [
                -38.069965952656446,
                -9.45048152562862
              ],
              [
                -38.07139993034808,
                -9.446148575160377
              ],
              [
                -38.07864537327568,
                -9.440461616419665
              ],
              [
                -38.108464114309314,
                -9.435219696460553
              ],
              [
                -38.11298956999193,
                -9.435761194875845
              ],
              [
                -38.114511071679345,
                -9.43771366189382
              ],
              [
                -38.11772801430218,
                -9.441842155446928
              ],
              [
                -38.13257290761834,
                -9.43903866576314
              ],
              [
                -38.14153333655951,
                -9.437346678077587
              ],
              [
                -38.1480447755358,
                -9.440173181518201
              ],
              [
                -38.15455720150562,
                -9.443000149962192
              ],
              [
                -38.17086659008017,
                -9.430075281680073
              ],
              [
                -38.177310531554106,
                -9.424968321818328
              ],
              [
                -38.187357932488844,
                -9.422778327877957
              ],
              [
                -38.197066863334236,
                -9.420661360063923
              ],
              [
                -38.20289281358729,
                -9.418237393719357
              ],
              [
                -38.201443836458054,
                -9.399944518786098
              ],
              [
                -38.195719896138364,
                -9.39547954827137
              ],
              [
                -38.19378392463186,
                -9.393970046564647
              ],
              [
                -38.19406343993,
                -9.385127149361562
              ],
              [
                -38.19991236765393,
                -9.37898317659608
              ],
              [
                -38.20924581635715,
                -9.358087822553188
              ],
              [
                -38.22221372377365,
                -9.344664450667867
              ],
              [
                -38.23708408244278,
                -9.329271080225675
              ],
              [
                -38.23060865666793,
                -9.322759104220966
              ],
              [
                -38.224133229889674,
                -9.316248163223868
              ],
              [
                -38.20256742455499,
                -9.296474800040825
              ],
              [
                -38.193007500651696,
                -9.294245276348475
              ],
              [
                -38.18511010378186,
                -9.292403294392198
              ],
              [
                -38.17859465110794,
                -9.289208818816505
              ],
              [
                -38.15796786392721,
                -9.26390697485832
              ],
              [
                -38.1567618734969,
                -9.25263855743089
              ],
              [
                -38.15672084422813,
                -9.252257567222777
              ],
              [
                -38.15666335225437,
                -9.25172208214702
              ],
              [
                -38.15591487514761,
                -9.242143641617947
              ],
              [
                -38.14351900763943,
                -9.217796331762624
              ],
              [
                -38.11884274423274,
                -9.201402912135132
              ],
              [
                -38.10218542442842,
                -9.1797885531565
              ],
              [
                -38.09197947624064,
                -9.172369615696745
              ],
              [
                -38.091894500046976,
                -9.172307602077794
              ],
              [
                -38.07885310873871,
                -9.170120591968322
              ],
              [
                -38.07286015318015,
                -9.171388077827729
              ],
              [
                -38.06436473510941,
                -9.173184092369485
              ],
              [
                -38.052681339184275,
                -9.167584623997033
              ],
              [
                -38.044138926488465,
                -9.16840011480586
              ],
              [
                -38.02988153153955,
                -9.164540102048251
              ],
              [
                -38.0289890591916,
                -9.162661121211535
              ],
              [
                -38.02539658004056,
                -9.155097188209472
              ],
              [
                -38.0106162055115,
                -9.155610137376737
              ],
              [
                -37.999068817685384,
                -9.15183318061534
              ],
              [
                -37.99058641229209,
                -9.154960644249083
              ],
              [
                -37.98382445466889,
                -9.150894691187512
              ],
              [
                -37.978946495389266,
                -9.147961178464803
              ],
              [
                -37.975522530072276,
                -9.13797223578752
              ],
              [
                -37.97069158604518,
                -9.123875850473425
              ],
              [
                -37.963587153826666,
                -9.119392378256835
              ],
              [
                -37.96016118603113,
                -9.117230395175715
              ],
              [
                -37.95895768934421,
                -9.116470910714556
              ],
              [
                -37.954878745185816,
                -9.108128963190012
              ],
              [
                -37.95380523573893,
                -9.104867002550865
              ],
              [
                -37.95123580078357,
                -9.097055539503303
              ],
              [
                -37.94664581772187,
                -9.094373052236259
              ],
              [
                -37.942278374578656,
                -9.091821086206586
              ],
              [
                -37.94009488380813,
                -9.08654411160118
              ],
              [
                -37.93843592521564,
                -9.082534131021784
              ],
              [
                -37.90746218492694,
                -9.051808333838194
              ],
              [
                -37.90452974106721,
                -9.03981991282337
              ],
              [
                -37.88935339878562,
                -9.03438943632313
              ],
              [
                -37.88921438592636,
                -9.034477466534453
              ],
              [
                -37.88056995787852,
                -9.039938919582434
              ],
              [
                -37.87483200222071,
                -9.024218025967986
              ],
              [
                -37.876640016895024,
                -9.020402539229732
              ],
              [
                -37.87919995744131,
                -9.015000599075234
              ],
              [
                -37.86686507999039,
                -9.009742627001707
              ],
              [
                -37.86377012448239,
                -9.008423625009824
              ],
              [
                -37.851877224675206,
                -8.997281173479971
              ],
              [
                -37.8500452750481,
                -8.992761722660953
              ],
              [
                -37.849018757075704,
                -8.990642232599225
              ],
              [
                -37.846031289164294,
                -8.984476269062323
              ],
              [
                -37.845484805431404,
                -8.983991294192805
              ],
              [
                -37.838700860817,
                -8.977969331073133
              ],
              [
                -37.833919414884136,
                -8.959834451688938
              ],
              [
                -37.83027547485141,
                -8.946038085852518
              ],
              [
                -37.82746451572882,
                -8.936250130727997
              ],
              [
                -37.825824026838006,
                -8.932318173734352
              ],
              [
                -37.8291204814194,
                -8.929508686858643
              ],
              [
                -37.83090248213807,
                -8.927990192897555
              ],
              [
                -37.832544953016416,
                -8.925471197318965
              ],
              [
                -37.824999544665424,
                -8.91880623842417
              ],
              [
                -37.82088655826512,
                -8.915173798923385
              ],
              [
                -37.82150258789852,
                -8.90972481941165
              ],
              [
                -37.82162707582306,
                -8.90862482778743
              ],
              [
                -37.82312554732242,
                -8.901405867383732
              ],
              [
                -37.82635654159133,
                -8.898467892414551
              ],
              [
                -37.82857751166183,
                -8.896709931655414
              ],
              [
                -37.8286925217415,
                -8.893372961826955
              ],
              [
                -37.825559554630445,
                -8.891971459484761
              ],
              [
                -37.82172256701334,
                -8.890253457877016
              ],
              [
                -37.808478194152556,
                -8.884324513303811
              ],
              [
                -37.801877776315116,
                -8.88430048444611
              ],
              [
                -37.80094778851062,
                -8.884296991621643
              ],
              [
                -37.79944528708312,
                -8.882851482111892
              ],
              [
                -37.78076497225434,
                -8.864877609182713
              ],
              [
                -37.77562901505039,
                -8.862119123244616
              ],
              [
                -37.76894607647259,
                -8.863707625202544
              ],
              [
                -37.75990564212273,
                -8.857214142811193
              ],
              [
                -37.74887124635742,
                -8.869167557823625
              ],
              [
                -37.745699773798876,
                -8.882728470918204
              ],
              [
                -37.74119731231391,
                -8.887247904811796
              ],
              [
                -37.7347933478095,
                -8.887203928292292
              ],
              [
                -37.73404634778621,
                -8.895437857676031
              ],
              [
                -37.73388235856029,
                -8.8972458489279
              ],
              [
                -37.730961879520116,
                -8.899179824711489
              ],
              [
                -37.7241679342597,
                -8.903680276648197
              ],
              [
                -37.72625039506619,
                -8.9056597846659
              ],
              [
                -37.72523291326627,
                -8.907573742340462
              ],
              [
                -37.7211849599091,
                -8.915188217370849
              ],
              [
                -37.71825747431005,
                -8.917794196092078
              ],
              [
                -37.707477054488045,
                -8.927390617047216
              ],
              [
                -37.704538583660046,
                -8.937893529377023
              ],
              [
                -37.704528561715925,
                -8.937928522104704
              ],
              [
                -37.704367560602655,
                -8.93850300563752
              ],
              [
                -37.70177409685287,
                -8.947788429445813
              ],
              [
                -37.70123457527784,
                -8.948147937247368
              ],
              [
                -37.68960570665459,
                -8.955901366269783
              ],
              [
                -37.684249233856654,
                -8.966708795097606
              ],
              [
                -37.687398164715354,
                -8.976753716497248
              ],
              [
                -37.69111567089865,
                -8.97891772166058
              ],
              [
                -37.702934028217236,
                -8.985796157877463
              ],
              [
                -37.70175405676248,
                -8.988863638743851
              ],
              [
                -37.69844458598082,
                -8.992052105735693
              ],
              [
                -37.689615161786186,
                -8.990011126919198
              ],
              [
                -37.68561767534879,
                -8.998264052222977
              ],
              [
                -37.67280431902112,
                -8.992265079469902
              ],
              [
                -37.66124190511548,
                -8.986852140489004
              ],
              [
                -37.6604848992065,
                -8.993915549699658
              ],
              [
                -37.663110856972736,
                -8.999331046423066
              ],
              [
                -37.66363837829958,
                -9.000420027726511
              ],
              [
                -37.658747414261136,
                -9.005993486326878
              ],
              [
                -37.643234027100235,
                -9.017871867728896
              ],
              [
                -37.62131522169441,
                -9.011391898180321
              ],
              [
                -37.611589830856495,
                -9.005742921675767
              ],
              [
                -37.61635629790382,
                -9.000233969496813
              ],
              [
                -37.614402801508646,
                -8.994494029860904
              ],
              [
                -37.61163782849595,
                -8.986371099408586
              ],
              [
                -37.60043494136264,
                -8.975704143351683
              ],
              [
                -37.58340312225297,
                -8.969839161841202
              ],
              [
                -37.572708176011226,
                -8.971694159057465
              ],
              [
                -37.562013782777,
                -8.973549124273628
              ],
              [
                -37.55585234439012,
                -8.969058645428214
              ],
              [
                -37.54076898388031,
                -8.958063716405817
              ],
              [
                -37.52969556949523,
                -8.96265618766555
              ],
              [
                -37.524918111273905,
                -8.964221170498323
              ],
              [
                -37.52390111895505,
                -8.964554178057702
              ],
              [
                -37.52065967718475,
                -8.965011656641003
              ],
              [
                -37.509599772514036,
                -8.966572625746675
              ],
              [
                -37.50609177974262,
                -8.966342622265975
              ],
              [
                -37.48985040334218,
                -8.965276654424988
              ],
              [
                -37.4882259151339,
                -8.970214106724944
              ],
              [
                -37.4866014549233,
                -8.975151585026284
              ],
              [
                -37.49329935244919,
                -9.00017838362031
              ],
              [
                -37.468963563490064,
                -9.02725718127886
              ],
              [
                -37.44555474360006,
                -9.026197152703526
              ],
              [
                -37.43955480725369,
                -9.027746136509839
              ],
              [
                -37.43355736688961,
                -9.02929459832169
              ],
              [
                -37.42420943780718,
                -9.037687028481175
              ],
              [
                -37.417593493832634,
                -9.043626983631741
              ],
              [
                -37.40892254589852,
                -9.047590975772469
              ],
              [
                -37.40025162397191,
                -9.051554916910606
              ],
              [
                -37.33614319350625,
                -9.080834166095404
              ],
              [
                -37.331715212149064,
                -9.086821107879443
              ],
              [
                -37.327287738786815,
                -9.092808048663722
              ],
              [
                -37.32414925434968,
                -9.093079563715765
              ],
              [
                -37.315103352799696,
                -9.093861058463034
              ],
              [
                -37.31031586853121,
                -9.099958996071852
              ],
              [
                -37.31051140067584,
                -9.104360437077743
              ],
              [
                -37.31063485797772,
                -9.107139451247093
              ],
              [
                -37.3007299431072,
                -9.116403858254554
              ],
              [
                -37.2983659969934,
                -9.118614835401962
              ],
              [
                -37.29628798859551,
                -9.120558308829969
              ],
              [
                -37.294853016735,
                -9.123312793741825
              ],
              [
                -37.28172657547066,
                -9.148505610730465
              ],
              [
                -37.27186616170881,
                -9.154053537768608
              ],
              [
                -37.26200575595621,
                -9.159601497801946
              ],
              [
                -37.2428204306578,
                -9.18561778194015
              ],
              [
                -37.245607889680926,
                -9.189051288674051
              ],
              [
                -37.246360361127905,
                -9.189978751389594
              ],
              [
                -37.238750453447565,
                -9.202903148946048
              ],
              [
                -37.23862441849663,
                -9.202976645274887
              ],
              [
                -37.23314195313315,
                -9.206171649093779
              ],
              [
                -37.231928976003054,
                -9.221908498453509
              ],
              [
                -37.22837000455032,
                -9.225119994943688
              ],
              [
                -37.23210797677612,
                -9.229966941948746
              ],
              [
                -37.23347746793593,
                -9.231742939860442
              ],
              [
                -37.2335599487862,
                -9.233237424687175
              ],
              [
                -37.233920448408284,
                -9.23974739902115
              ],
              [
                -37.20929968198179,
                -9.219696003961534
              ],
              [
                -37.19812526550095,
                -9.215147554787496
              ],
              [
                -37.18996286039054,
                -9.220000489603516
              ],
              [
                -37.15853410685349,
                -9.238685838450252
              ],
              [
                -37.16786851185477,
                -9.25142123424421
              ],
              [
                -37.16798649769073,
                -9.253650715562319
              ],
              [
                -37.16838200508228,
                -9.261122176662962
              ],
              [
                -37.168598516265284,
                -9.265219637009888
              ],
              [
                -37.1689574916088,
                -9.272021596129337
              ],
              [
                -37.163921035244364,
                -9.275289061799283
              ],
              [
                -37.14197673548252,
                -9.267657602683457
              ],
              [
                -37.13573226580854,
                -9.263621118238236
              ],
              [
                -37.12948984611637,
                -9.25958565679542
              ],
              [
                -37.12533489464051,
                -9.253685204368917
              ],
              [
                -37.116061981534536,
                -9.24051526296653
              ],
              [
                -37.110957014449006,
                -9.23994626148765
              ],
              [
                -37.105851549371316,
                -9.239376288016949
              ],
              [
                -37.08358623847226,
                -9.251898149799436
              ],
              [
                -37.056783978902956,
                -9.281366437532242
              ],
              [
                -37.06116489608228,
                -9.30330026931088
              ],
              [
                -37.059182925782224,
                -9.313728207869323
              ],
              [
                -37.05849193545971,
                -9.317368662789995
              ],
              [
                -37.05679594720377,
                -9.31248268701064
              ],
              [
                -37.0549049542136,
                -9.30703622127687
              ],
              [
                -37.0542404525811,
                -9.307607752345506
              ],
              [
                -37.048077527362196,
                -9.312909208602582
              ],
              [
                -37.045109518338435,
                -9.315461166581345
              ],
              [
                -37.02387021949743,
                -9.315293137890094
              ],
              [
                -37.002962890407076,
                -9.352184871170335
              ],
              [
                -36.99728291028515,
                -9.353954332482601
              ],
              [
                -36.98854401349285,
                -9.356676792728662
              ],
              [
                -36.977735104696805,
                -9.360912283617914
              ],
              [
                -36.976861092152944,
                -9.361254787212534
              ],
              [
                -36.9520078025629,
                -9.382120085506392
              ],
              [
                -36.943585354791544,
                -9.381919098069261
              ],
              [
                -36.93669694302012,
                -9.376846137809558
              ],
              [
                -36.9323424973244,
                -9.37213467022454
              ],
              [
                -36.93001201650051,
                -9.369612674044104
              ],
              [
                -36.936075948539916,
                -9.361164219464232
              ],
              [
                -36.936343974508496,
                -9.360790720533187
              ],
              [
                -36.937453432631756,
                -9.352399784857557
              ],
              [
                -36.92898252886316,
                -9.345311829354335
              ],
              [
                -36.9218445911532,
                -9.33933988170262
              ],
              [
                -36.9205191188869,
                -9.332065923345835
              ],
              [
                -36.92255159892404,
                -9.329808440288847
              ],
              [
                -36.92689105384277,
                -9.324988503733417
              ],
              [
                -36.91540019626834,
                -9.298606170130274
              ],
              [
                -36.89932085372857,
                -9.282570306231719
              ],
              [
                -36.893721885282616,
                -9.28293276926958
              ],
              [
                -36.88936141987776,
                -9.283214756071231
              ],
              [
                -36.877229549106666,
                -9.277820796520267
              ],
              [
                -36.87436855881003,
                -9.276548799467832
              ],
              [
                -36.88035004131432,
                -9.275679821609193
              ],
              [
                -36.869812104324566,
                -9.269239855471493
              ],
              [
                -36.867795137372994,
                -9.268106877158973
              ],
              [
                -36.84759831929447,
                -9.276737773103592
              ],
              [
                -36.84343884416739,
                -9.282264727442195
              ],
              [
                -36.83927936203904,
                -9.287792697772911
              ],
              [
                -36.79670924021457,
                -9.292608123694153
              ],
              [
                -36.75834162198559,
                -9.270976248706974
              ],
              [
                -36.69339567918019,
                -9.2749081906763
              ],
              [
                -36.67848230279707,
                -9.289381071193217
              ],
              [
                -36.663411411688635,
                -9.304006911395302
              ],
              [
                -36.65895993439901,
                -9.30832639053607
              ],
              [
                -36.64929352251756,
                -9.314980359141833
              ],
              [
                -36.64804652189899,
                -9.314729844879963
              ],
              [
                -36.64424258562075,
                -9.313963342149837
              ],
              [
                -36.641006614038396,
                -9.31874431192014
              ],
              [
                -36.6404616151495,
                -9.319550797303426
              ],
              [
                -36.639075601260544,
                -9.31984581477915
              ],
              [
                -36.63496863614772,
                -9.320720783330112
              ],
              [
                -36.63474065967927,
                -9.32231829101526
              ],
              [
                -36.63414264608101,
                -9.326499732781418
              ],
              [
                -36.62439372869163,
                -9.331316174218985
              ],
              [
                -36.623664733739425,
                -9.328634703855265
              ],
              [
                -36.622998254013844,
                -9.326179725835015
              ],
              [
                -36.61907329712444,
                -9.328280703264525
              ],
              [
                -36.61338833857586,
                -9.323380264108398
              ],
              [
                -36.61431081651957,
                -9.330599680262127
              ],
              [
                -36.61240933878012,
                -9.333505681474156
              ],
              [
                -36.60728337505664,
                -9.333039655234002
              ],
              [
                -36.6041934003661,
                -9.339739096595707
              ],
              [
                -36.60378591817647,
                -9.340622111525947
              ],
              [
                -36.6032094206356,
                -9.340300611424906
              ],
              [
                -36.592639006736235,
                -9.33440314027368
              ],
              [
                -36.59104802924754,
                -9.335164631022257
              ],
              [
                -36.58583057255954,
                -9.337660620250789
              ],
              [
                -36.57564167755207,
                -9.331454158816124
              ],
              [
                -36.56691573765747,
                -9.331407638056785
              ],
              [
                -36.5634717569924,
                -9.322356727483157
              ],
              [
                -36.56776472682886,
                -9.317848757652978
              ],
              [
                -36.569624714526796,
                -9.318553259037307
              ],
              [
                -36.572322218434145,
                -9.319575238792343
              ],
              [
                -36.571398733458516,
                -9.292158461873742
              ],
              [
                -36.50287185087234,
                -9.270551058585923
              ],
              [
                -36.499067892568775,
                -9.263434111143631
              ],
              [
                -36.49100149179542,
                -9.261272101141184
              ],
              [
                -36.49222995974899,
                -9.253818671907123
              ],
              [
                -36.489359474924825,
                -9.252231682326483
              ],
              [
                -36.487482035999626,
                -9.243355744058968
              ],
              [
                -36.486997526467206,
                -9.24106726900853
              ],
              [
                -36.48080410188262,
                -9.238950768409028
              ],
              [
                -36.47849159345663,
                -9.240164766056507
              ],
              [
                -36.47366363232363,
                -9.242699725351313
              ],
              [
                -36.46226322892987,
                -9.238908245711217
              ],
              [
                -36.458548297515534,
                -9.234261288639852
              ],
              [
                -36.457544791667964,
                -9.23300628126383
              ],
              [
                -36.45322382237093,
                -9.231920799557043
              ],
              [
                -36.45377083919989,
                -9.225752845972325
              ],
              [
                -36.440218435305184,
                -9.218493400788372
              ],
              [
                -36.43656447934292,
                -9.211682954268595
              ],
              [
                -36.42285610383735,
                -9.215238394674088
              ],
              [
                -36.423913082760734,
                -9.225603814759008
              ],
              [
                -36.42350259414157,
                -9.22592331095201
              ],
              [
                -36.419360131260014,
                -9.229147806622475
              ],
              [
                -36.41485817548416,
                -9.229264305613443
              ],
              [
                -36.41085172248285,
                -9.224838816625244
              ],
              [
                -36.408761735347774,
                -9.222530356282707
              ],
              [
                -36.408008734865355,
                -9.22169834992659
              ],
              [
                -36.40594124416602,
                -9.221939322201186
              ],
              [
                -36.402502275607844,
                -9.222339853006753
              ],
              [
                -36.38959440731656,
                -9.224450807131458
              ],
              [
                -36.37542802284782,
                -9.21627384644423
              ],
              [
                -36.365258637511936,
                -9.22220229700561
              ],
              [
                -36.357898172506886,
                -9.217863815325309
              ],
              [
                -36.35315721331131,
                -9.219698304022735
              ],
              [
                -36.34844127057306,
                -9.214310349761558
              ],
              [
                -36.349670267398636,
                -9.210151367568109
              ],
              [
                -36.35230977198757,
                -9.201216939047644
              ],
              [
                -36.340914328078064,
                -9.205627891050462
              ],
              [
                -36.33965684990281,
                -9.20611492419298
              ],
              [
                -36.3344774189395,
                -9.204385403652573
              ],
              [
                -36.327196988952586,
                -9.200341954838038
              ],
              [
                -36.324705993061805,
                -9.194081998288498
              ],
              [
                -36.321072055057776,
                -9.18495303558313
              ],
              [
                -36.311876116550266,
                -9.179491584874647
              ],
              [
                -36.30947363569174,
                -9.17231812140573
              ],
              [
                -36.30656868099538,
                -9.17639659966063
              ],
              [
                -36.300067231265345,
                -9.175353100719079
              ],
              [
                -36.2967502650931,
                -9.176357602046265
              ],
              [
                -36.294526785025326,
                -9.172017107141372
              ],
              [
                -36.28732935477484,
                -9.172900629872405
              ],
              [
                -36.2853083718841,
                -9.169655618799323
              ],
              [
                -36.27372046139998,
                -9.165776657898508
              ],
              [
                -36.270644499558095,
                -9.170613601196704
              ],
              [
                -36.25968661109399,
                -9.170378594060503
              ],
              [
                -36.26107258371376,
                -9.176853551898217
              ],
              [
                -36.25553564508101,
                -9.18155502699657
              ],
              [
                -36.252050639029115,
                -9.180821537433786
              ],
              [
                -36.25266764177985,
                -9.172883574580489
              ],
              [
                -36.24741669072524,
                -9.17743505208008
              ],
              [
                -36.237402302349395,
                -9.174443571841612
              ],
              [
                -36.2244979077144,
                -9.170589074586442
              ],
              [
                -36.22461839402171,
                -9.166891613930755
              ],
              [
                -36.231012344695806,
                -9.16354414527866
              ],
              [
                -36.2386067791766,
                -9.159566691521382
              ],
              [
                -36.2405052939955,
                -9.152076750435638
              ],
              [
                -36.24178276914048,
                -9.147038767066377
              ],
              [
                -36.246252727159685,
                -9.142519821617423
              ],
              [
                -36.24853521654612,
                -9.141569830934062
              ],
              [
                -36.25726713051603,
                -9.137936831564527
              ],
              [
                -36.26309209654111,
                -9.125581955183586
              ],
              [
                -36.2602336310152,
                -9.11809898676881
              ],
              [
                -36.26573758154522,
                -9.111620044223091
              ],
              [
                -36.26639159848313,
                -9.101956119648232
              ],
              [
                -36.245069783813626,
                -9.098619649976671
              ],
              [
                -36.24300628340848,
                -9.099631627387092
              ],
              [
                -36.236003878119625,
                -9.103065102848884
              ],
              [
                -36.22201497916391,
                -9.086007222707655
              ],
              [
                -36.221816502203204,
                -9.081315741409062
              ],
              [
                -36.22146002305358,
                -9.07287932361696
              ],
              [
                -36.21297808028604,
                -9.072760286932485
              ],
              [
                -36.20988362109967,
                -9.072716805494691
              ],
              [
                -36.20393216301973,
                -9.067748316182415
              ],
              [
                -36.1968192402365,
                -9.048542991808837
              ],
              [
                -36.196173251608535,
                -9.04680098656727
              ],
              [
                -36.19528726671912,
                -9.044411499069007
              ],
              [
                -36.19470677581744,
                -9.044536010597938
              ],
              [
                -36.188487338443906,
                -9.045869475840846
              ],
              [
                -36.17900041236102,
                -9.04060801466695
              ],
              [
                -36.17467597137131,
                -9.043728500916478
              ],
              [
                -36.16366656752767,
                -9.040065508157127
              ],
              [
                -36.15431363298194,
                -9.0395485067857
              ],
              [
                -36.151264665910425,
                -9.04226949622676
              ],
              [
                -36.141317732379065,
                -9.038792505005105
              ],
              [
                -36.140442266111954,
                -9.03782700462307
              ],
              [
                -36.138479785687956,
                -9.035662544460484
              ],
              [
                -36.12365591188763,
                -9.030058046224662
              ],
              [
                -36.11103255080391,
                -9.017248649207326
              ],
              [
                -36.114602507539786,
                -9.01300717189576
              ],
              [
                -36.11330804195656,
                -9.008786219113231
              ],
              [
                -36.1090025643289,
                -9.008014215216285
              ],
              [
                -36.103866601996955,
                -8.998503265610863
              ],
              [
                -36.111721552394755,
                -8.987373391936808
              ],
              [
                -36.12042547072963,
                -8.983438923815836
              ],
              [
                -36.12637392170583,
                -8.980750418698547
              ],
              [
                -36.12874942834664,
                -8.968472016988056
              ],
              [
                -36.12645544044755,
                -8.955590643800754
              ],
              [
                -36.08635180532805,
                -8.943653176120081
              ],
              [
                -36.06347803337667,
                -8.914917372794154
              ],
              [
                -36.03033985798308,
                -8.908406404831467
              ],
              [
                -36.01218301811194,
                -8.911919355933389
              ],
              [
                -36.004921054828,
                -8.9125763685284
              ],
              [
                -35.994111149099815,
                -8.906848890134805
              ],
              [
                -35.988272235343324,
                -8.903755408833833
              ],
              [
                -35.97366833893847,
                -8.901212413651702
              ],
              [
                -35.973280849376195,
                -8.901266435897732
              ],
              [
                -35.96471744134448,
                -8.902470398158394
              ],
              [
                -35.962830441852105,
                -8.898466416330068
              ],
              [
                -35.96053598378647,
                -8.89359698081242
              ],
              [
                -35.96015099031954,
                -8.893517973085023
              ],
              [
                -35.95021555150121,
                -8.891477990131733
              ],
              [
                -35.94206813235638,
                -8.885174529574032
              ],
              [
                -35.93265474193947,
                -8.884173525090874
              ],
              [
                -35.92324079453574,
                -8.883172530611208
              ],
              [
                -35.917986882088854,
                -8.873763094533462
              ],
              [
                -35.910831938728194,
                -8.860949692062015
              ],
              [
                -35.90128554380955,
                -8.861909679383494
              ],
              [
                -35.90051804664995,
                -8.861987147120757
              ],
              [
                -35.8998910562294,
                -8.860919658805413
              ],
              [
                -35.895731075877265,
                -8.853840701770686
              ],
              [
                -35.88987063674964,
                -8.859413664728184
              ],
              [
                -35.88647766946679,
                -8.862640145913161
              ],
              [
                -35.861457367857234,
                -8.857942676456938
              ],
              [
                -35.84866750026404,
                -8.87138556670395
              ],
              [
                -35.84452050801309,
                -8.87207203981509
              ],
              [
                -35.8365780998423,
                -8.8683170828936
              ],
              [
                -35.83108513080164,
                -8.868911559549858
              ],
              [
                -35.825195203302535,
                -8.86954906153049
              ],
              [
                -35.8242146981386,
                -8.864976594956515
              ],
              [
                -35.82250173555738,
                -8.856989159102293
              ],
              [
                -35.807420858829644,
                -8.859961614122671
              ],
              [
                -35.795050964996115,
                -8.847706701026615
              ],
              [
                -35.79014201362505,
                -8.852985635034635
              ],
              [
                -35.78712054684553,
                -8.850704649035125
              ],
              [
                -35.78084707579751,
                -8.860538589697112
              ],
              [
                -35.77550216016355,
                -8.858029616448883
              ],
              [
                -35.77643814428159,
                -8.86883852481454
              ],
              [
                -35.78125407291902,
                -8.877929957811684
              ],
              [
                -35.78319606520556,
                -8.881595430206644
              ],
              [
                -35.780031581092324,
                -8.886886883632522
              ],
              [
                -35.77890209261714,
                -8.888776380073525
              ],
              [
                -35.7795615743074,
                -8.890333837627656
              ],
              [
                -35.78249105283469,
                -8.897251297797496
              ],
              [
                -35.777711608856066,
                -8.898459270330559
              ],
              [
                -35.77275912412902,
                -8.905717228037062
              ],
              [
                -35.76050926487518,
                -8.907643693537638
              ],
              [
                -35.760438740501,
                -8.907655186387723
              ],
              [
                -35.754178801962084,
                -8.912188663973145
              ],
              [
                -35.74791934442112,
                -8.916723137549427
              ],
              [
                -35.721719075301095,
                -8.908080152507582
              ],
              [
                -35.71321115730125,
                -8.904366178802405
              ],
              [
                -35.69973828426889,
                -8.906163676246752
              ],
              [
                -35.68783388730455,
                -8.898595742381323
              ],
              [
                -35.68701940210662,
                -8.898078232673525
              ],
              [
                -35.67826100056447,
                -8.896447727689196
              ],
              [
                -35.66950254602938,
                -8.894817238709722
              ],
              [
                -35.66808556442566,
                -8.892977757696734
              ],
              [
                -35.659245674230405,
                -8.88150281273368
              ],
              [
                -35.65149875094188,
                -8.881743808183959
              ],
              [
                -35.64047782812409,
                -8.882087792014111
              ],
              [
                -35.64043334152439,
                -8.882089301964058
              ],
              [
                -35.629593418870414,
                -8.879238813651334
              ],
              [
                -35.61743254260367,
                -8.867832884378194
              ],
              [
                -35.613946072929565,
                -8.86456293867022
              ],
              [
                -35.611205121185414,
                -8.863980913813954
              ],
              [
                -35.60282669539059,
                -8.862201441372978
              ],
              [
                -35.59623524513288,
                -8.86080194553308
              ],
              [
                -35.59010778800509,
                -8.85212950237894
              ],
              [
                -35.589750309673384,
                -8.851622992991993
              ],
              [
                -35.58203788718598,
                -8.846027037685346
              ],
              [
                -35.57413145365623,
                -8.843656071257957
              ],
              [
                -35.57390095779806,
                -8.84358707159448
              ],
              [
                -35.56843598158542,
                -8.841946565610096
              ],
              [
                -35.56757001294216,
                -8.840176066576493
              ],
              [
                -35.563189056274425,
                -8.831217640195925
              ],
              [
                -35.547829718910535,
                -8.827893160801871
              ],
              [
                -35.53996826076382,
                -8.821155700277522
              ],
              [
                -35.53466131147345,
                -8.819121226507283
              ],
              [
                -35.531289349093946,
                -8.827050659184078
              ],
              [
                -35.5309533644474,
                -8.827840646775877
              ],
              [
                -35.52961335949856,
                -8.826750663075716
              ],
              [
                -35.51754398004817,
                -8.816934201839139
              ],
              [
                -35.51009103073057,
                -8.817719686389006
              ],
              [
                -35.503764089338034,
                -8.821869146823554
              ],
              [
                -35.49988111827649,
                -8.819737660031903
              ],
              [
                -35.49986912242384,
                -8.822010652401358
              ],
              [
                -35.49985564836771,
                -8.824534164828346
              ],
              [
                -35.495257149055455,
                -8.827872628022797
              ],
              [
                -35.493023700762535,
                -8.82495762371212
              ],
              [
                -35.48162729413621,
                -8.825721615059933
              ],
              [
                -35.48081181534872,
                -8.822430142883983
              ],
              [
                -35.48008729742039,
                -8.81950666293414
              ],
              [
                -35.4670764223197,
                -8.814568693135316
              ],
              [
                -35.46184047636677,
                -8.81840017795574
              ],
              [
                -35.46516145422198,
                -8.825491626791191
              ]
            ],
            [
              [
                -35.29098126056792,
                -9.148233011195131
              ],
              [
                -35.29095074869167,
                -9.149527020166706
              ],
              [
                -35.28924427952651,
                -9.148000005468045
              ],
              [
                -35.290185752892,
                -9.146986501130018
              ],
              [
                -35.29103276002365,
                -9.146074536924074
              ],
              [
                -35.29098126056792,
                -9.148233011195131
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 3,
        "nome": "Amazonas",
        "sigla": "AM",
        "regiao_id": 3,
        "codigo_ibg": 13
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -67.32553004987152,
                2.030119216697711
              ],
              [
                -67.32234259897888,
                2.017409306808878
              ],
              [
                -67.31685062184067,
                1.995514889626392
              ],
              [
                -67.31269465465891,
                1.978946979666407
              ],
              [
                -67.30979115997324,
                1.967370026495152
              ],
              [
                -67.29963971428838,
                1.92707721980433
              ],
              [
                -67.29779124872374,
                1.921400280059003
              ],
              [
                -67.29590276538029,
                1.915599782885261
              ],
              [
                -67.29424824771245,
                1.910518307386159
              ],
              [
                -67.29097877014229,
                1.900476852826285
              ],
              [
                -67.28834028245738,
                1.894143911934761
              ],
              [
                -67.28620030606375,
                1.889007424544896
              ],
              [
                -67.2832903240189,
                1.884695939016681
              ],
              [
                -67.28311930489751,
                1.884443433156916
              ],
              [
                -67.27752535164143,
                1.876154961596253
              ],
              [
                -67.26598940856474,
                1.87001751804004
              ],
              [
                -67.25943243835887,
                1.866526529522815
              ],
              [
                -67.25345297228816,
                1.863569528664245
              ],
              [
                -67.24338550500318,
                1.858598557081609
              ],
              [
                -67.22827108709433,
                1.861270526794502
              ],
              [
                -67.21466366428864,
                1.860184032756616
              ],
              [
                -67.20516970709647,
                1.859421550451956
              ],
              [
                -67.19575976047541,
                1.858820532410582
              ],
              [
                -67.18986279729393,
                1.858443543387334
              ],
              [
                -67.17376986823903,
                1.857418548310007
              ],
              [
                -67.15648445999133,
                1.849256072735396
              ],
              [
                -67.14782048609834,
                1.838123134913724
              ],
              [
                -67.13915754319196,
                1.826990681108978
              ],
              [
                -67.12348513115344,
                1.799322833682023
              ],
              [
                -67.115007669198,
                1.767955467965352
              ],
              [
                -67.0968122869011,
                1.73301063941105
              ],
              [
                -67.10078926954492,
                1.706640788525023
              ],
              [
                -67.0998837892067,
                1.694387332320705
              ],
              [
                -67.09897830184391,
                1.682134408101557
              ],
              [
                -67.08906783039394,
                1.664398470355354
              ],
              [
                -67.08570283888896,
                1.65837652694939
              ],
              [
                -67.08332735475466,
                1.648482074151999
              ],
              [
                -67.08095088260997,
                1.638587123354063
              ],
              [
                -67.08047989615898,
                1.607077266345587
              ],
              [
                -67.08048238945815,
                1.605888768968713
              ],
              [
                -67.0805033794787,
                1.59391631860414
              ],
              [
                -67.08050991338789,
                1.590290859751302
              ],
              [
                -67.08051338296602,
                1.587993848614488
              ],
              [
                -67.07947841878095,
                1.545134090926235
              ],
              [
                -67.07884440809887,
                1.523812182475283
              ],
              [
                -67.07814441920003,
                1.500255821510655
              ],
              [
                -67.0766624459665,
                1.492962854564361
              ],
              [
                -67.07267447051653,
                1.473333448211585
              ],
              [
                -67.0717579720363,
                1.443022077949498
              ],
              [
                -67.07984242548426,
                1.401672797681206
              ],
              [
                -67.08152792986567,
                1.387008849108857
              ],
              [
                -67.08393643820484,
                1.366060473236975
              ],
              [
                -67.08825739974529,
                1.325392648419534
              ],
              [
                -67.08817440180367,
                1.308289756761988
              ],
              [
                -67.08807542640645,
                1.28872634462456
              ],
              [
                -67.08773041173775,
                1.280575400822328
              ],
              [
                -67.0875989337299,
                1.273976426773687
              ],
              [
                -67.08733142475614,
                1.260574976617368
              ],
              [
                -67.08709642826105,
                1.248841061626592
              ],
              [
                -67.0867599582519,
                1.231982140638196
              ],
              [
                -67.08228296595402,
                1.203760755060633
              ],
              [
                -67.0841534598553,
                1.189800846855572
              ],
              [
                -67.08489347343358,
                1.184279868868621
              ],
              [
                -67.08758993696834,
                1.167322460819222
              ],
              [
                -66.85635160203478,
                1.230625593267209
              ],
              [
                -66.59871641936948,
                1.000108199866544
              ],
              [
                -66.31791636054113,
                0.755434896939526
              ],
              [
                -66.21302090673392,
                0.781945720135571
              ],
              [
                -66.20119245419443,
                0.781873746966031
              ],
              [
                -66.18819052861127,
                0.779973736401735
              ],
              [
                -66.18283855400223,
                0.768956803483741
              ],
              [
                -66.18213506560166,
                0.767507819334243
              ],
              [
                -66.16276768613358,
                0.756492870678821
              ],
              [
                -66.16054718411758,
                0.746744907103133
              ],
              [
                -66.1505442170132,
                0.744856936927444
              ],
              [
                -66.1399712711259,
                0.747734390846024
              ],
              [
                -66.13521780314963,
                0.748708380594849
              ],
              [
                -66.13143080348797,
                0.749540886139026
              ],
              [
                -66.12138739805238,
                0.752415383118653
              ],
              [
                -66.11770590930466,
                0.758005843842224
              ],
              [
                -66.1139154230647,
                0.763762836742799
              ],
              [
                -66.10167846165164,
                0.764135323425701
              ],
              [
                -66.10121747691768,
                0.763980325112331
              ],
              [
                -66.0879160573174,
                0.75949581999239
              ],
              [
                -66.075737104588,
                0.768672798376557
              ],
              [
                -66.0778251060608,
                0.779418229990092
              ],
              [
                -66.0766161092451,
                0.785072694651169
              ],
              [
                -66.07594711411159,
                0.788202677515569
              ],
              [
                -66.0739560934281,
                0.799114638779744
              ],
              [
                -66.06947463647666,
                0.805427614822609
              ],
              [
                -66.0553812206279,
                0.802596631593729
              ],
              [
                -66.03742881201488,
                0.812413074954842
              ],
              [
                -66.02707036740362,
                0.806416072477098
              ],
              [
                -66.01671089780888,
                0.800418608018023
              ],
              [
                -66.00001399111449,
                0.802985115457814
              ],
              [
                -65.9925095372487,
                0.807397599187984
              ],
              [
                -65.98273359117745,
                0.806647099520128
              ],
              [
                -65.9772221184275,
                0.812190043039045
              ],
              [
                -65.97077012675767,
                0.806454599873031
              ],
              [
                -65.96387417907543,
                0.809872081462089
              ],
              [
                -65.96414915737796,
                0.820160534809716
              ],
              [
                -65.95130572207255,
                0.836700417150457
              ],
              [
                -65.94537277492566,
                0.852962834697632
              ],
              [
                -65.93277984826369,
                0.862917790768757
              ],
              [
                -65.93346881337389,
                0.866598765050294
              ],
              [
                -65.93371831096454,
                0.867932782628802
              ],
              [
                -65.93437530324334,
                0.871508764413118
              ],
              [
                -65.93511280794917,
                0.876334226159917
              ],
              [
                -65.93603280356419,
                0.882516677362009
              ],
              [
                -65.92482784518747,
                0.891647630519754
              ],
              [
                -65.92012389135998,
                0.890902147446777
              ],
              [
                -65.91594890092674,
                0.890260161950281
              ],
              [
                -65.91176991950263,
                0.889710672005795
              ],
              [
                -65.91103795210604,
                0.889620138338186
              ],
              [
                -65.90784294383585,
                0.889225159788564
              ],
              [
                -65.90055900130947,
                0.884071199715622
              ],
              [
                -65.89657799862162,
                0.892626125663017
              ],
              [
                -65.88091957684587,
                0.903973063325555
              ],
              [
                -65.87715260399155,
                0.913214543913643
              ],
              [
                -65.87428114112569,
                0.919772489646219
              ],
              [
                -65.8734766248046,
                0.921609992603145
              ],
              [
                -65.87906459465306,
                0.933057948858386
              ],
              [
                -65.86093467611813,
                0.930315939420895
              ],
              [
                -65.8398153048548,
                0.937423880652208
              ],
              [
                -65.83264182189488,
                0.93898188097671
              ],
              [
                -65.83030633630463,
                0.939489379150147
              ],
              [
                -65.82868435208461,
                0.94079688554043
              ],
              [
                -65.8215599062594,
                0.946540361495835
              ],
              [
                -65.81578992074586,
                0.952455336809903
              ],
              [
                -65.80960493258488,
                0.949835828604373
              ],
              [
                -65.80858345067858,
                0.949403328552099
              ],
              [
                -65.79876850257332,
                0.952160830600352
              ],
              [
                -65.7932760172582,
                0.954508817307499
              ],
              [
                -65.78816206144606,
                0.960983766955448
              ],
              [
                -65.78715757819667,
                0.96225577259813
              ],
              [
                -65.78402456649268,
                0.963135762816779
              ],
              [
                -65.77941759498772,
                0.964428269791482
              ],
              [
                -65.77351914181209,
                0.959506781863039
              ],
              [
                -65.76984566050797,
                0.962139259450143
              ],
              [
                -65.76664215994028,
                0.964437751736319
              ],
              [
                -65.76522017630417,
                0.968586245275419
              ],
              [
                -65.76352119790756,
                0.973523217919679
              ],
              [
                -65.76333020176934,
                0.974032187405918
              ],
              [
                -65.76175768586486,
                0.978211176763502
              ],
              [
                -65.76047819066517,
                0.981429668851808
              ],
              [
                -65.75021923502624,
                0.982835175348277
              ],
              [
                -65.73882430849683,
                0.999981070761744
              ],
              [
                -65.72968085142689,
                1.001127559667849
              ],
              [
                -65.72108041973861,
                1.001970557143069
              ],
              [
                -65.71761494208673,
                1.000474558892158
              ],
              [
                -65.71158146329407,
                0.997869573648094
              ],
              [
                -65.70933947905405,
                0.999804557819938
              ],
              [
                -65.70523699074393,
                1.003344554834782
              ],
              [
                -65.69492205051385,
                1.004471525633459
              ],
              [
                -65.68802755729806,
                1.000011542335136
              ],
              [
                -65.68758306081062,
                0.99804355789476
              ],
              [
                -65.68603407556215,
                0.991182607221346
              ],
              [
                -65.67746964907823,
                0.990015115553443
              ],
              [
                -65.67295264328253,
                0.997067060297526
              ],
              [
                -65.67032365069774,
                1.000699064084085
              ],
              [
                -65.66886618158006,
                1.002713037982657
              ],
              [
                -65.66166519950691,
                1.006947533060963
              ],
              [
                -65.65219375451278,
                1.012160005959983
              ],
              [
                -65.64419929614152,
                1.005546540064571
              ],
              [
                -65.64161130327808,
                1.003508556631876
              ],
              [
                -65.63745082239726,
                1.000233052011243
              ],
              [
                -65.62442541659543,
                0.989520123428179
              ],
              [
                -65.6256183880722,
                0.999634567019404
              ],
              [
                -65.6258853923866,
                1.001895524973746
              ],
              [
                -65.62656089678894,
                1.009649490050605
              ],
              [
                -65.61693845035374,
                1.010194491794442
              ],
              [
                -65.60971247942811,
                1.014625956854347
              ],
              [
                -65.60605548512238,
                1.00491150593644
              ],
              [
                -65.59798002636704,
                1.003504521522987
              ],
              [
                -65.59154805236123,
                1.002802520919525
              ],
              [
                -65.59049807558455,
                1.002688539307839
              ],
              [
                -65.59024108880901,
                1.002996021755987
              ],
              [
                -65.58498158686734,
                1.00929197597273
              ],
              [
                -65.5804516276724,
                1.000972527097488
              ],
              [
                -65.57781614648313,
                0.996352063364786
              ],
              [
                -65.57160917500018,
                0.985471123809558
              ],
              [
                -65.56867670769755,
                0.990825082037645
              ],
              [
                -65.56661569532258,
                0.994587555218834
              ],
              [
                -65.55721424611345,
                0.993702077049487
              ],
              [
                -65.5608252533864,
                0.986200120498134
              ],
              [
                -65.56034371700322,
                0.984763114481784
              ],
              [
                -65.55749173549613,
                0.976249166858574
              ],
              [
                -65.55196078896518,
                0.976131165559866
              ],
              [
                -65.54135682164275,
                0.975905155986736
              ],
              [
                -65.5366593812157,
                0.961451728289389
              ],
              [
                -65.53029938056827,
                0.956476742750089
              ],
              [
                -65.53331890324449,
                0.952079795829525
              ],
              [
                -65.53582535535331,
                0.948430292150872
              ],
              [
                -65.5257084189626,
                0.93945734869035
              ],
              [
                -65.52391094713235,
                0.937862864251849
              ],
              [
                -65.52303543190881,
                0.935022852075389
              ],
              [
                -65.52140693978976,
                0.929741877779889
              ],
              [
                -65.51759095732459,
                0.925884435159871
              ],
              [
                -65.50774950870449,
                0.915937962567505
              ],
              [
                -65.5103650210753,
                0.906562025028792
              ],
              [
                -65.51335298077389,
                0.895854077069593
              ],
              [
                -65.51168700651499,
                0.8856281270507
              ],
              [
                -65.50399704846043,
                0.887580606352019
              ],
              [
                -65.49557259104051,
                0.889719599625354
              ],
              [
                -65.49307909746763,
                0.882490625787313
              ],
              [
                -65.50468704866067,
                0.868914193126357
              ],
              [
                -65.50348953655819,
                0.86211222535511
              ],
              [
                -65.50192306156603,
                0.853243767674824
              ],
              [
                -65.50069806643474,
                0.847536818519518
              ],
              [
                -65.49969855303829,
                0.842859346341104
              ],
              [
                -65.50827653625791,
                0.843881838499692
              ],
              [
                -65.51380250564152,
                0.837599367078226
              ],
              [
                -65.51624997634111,
                0.838626869371738
              ],
              [
                -65.52078295256153,
                0.840529856656932
              ],
              [
                -65.52714093289617,
                0.833679397115138
              ],
              [
                -65.52366444029533,
                0.825194949242068
              ],
              [
                -65.52357844275039,
                0.824984920260175
              ],
              [
                -65.53050191489515,
                0.805600548178077
              ],
              [
                -65.53449540106487,
                0.805576028821611
              ],
              [
                -65.54016635090922,
                0.805542048731266
              ],
              [
                -65.53914786001957,
                0.797353101702171
              ],
              [
                -65.5391318901152,
                0.797225572324617
              ],
              [
                -65.54410885303861,
                0.784352144991205
              ],
              [
                -65.54798981393577,
                0.775057204972371
              ],
              [
                -65.55501879171236,
                0.777755189659928
              ],
              [
                -65.55578728486168,
                0.778050170314108
              ],
              [
                -65.5637222482797,
                0.771687717434553
              ],
              [
                -65.57302069500906,
                0.764752265504922
              ],
              [
                -65.57344420227696,
                0.761745254255057
              ],
              [
                -65.57401968304401,
                0.756647784238641
              ],
              [
                -65.57478117608413,
                0.749892821340043
              ],
              [
                -65.57532069200423,
                0.744926850668208
              ],
              [
                -65.58283565068022,
                0.737011911234244
              ],
              [
                -65.5906100968639,
                0.722275466100099
              ],
              [
                -65.58436265666722,
                0.71506549255861
              ],
              [
                -65.58232267186506,
                0.714339028867107
              ],
              [
                -65.57575617369476,
                0.712001527515274
              ],
              [
                -65.57219819858248,
                0.70105505554182
              ],
              [
                -65.56933973771741,
                0.692502128964677
              ],
              [
                -65.56925873814619,
                0.692259604139297
              ],
              [
                -65.56695524424445,
                0.686188158513481
              ],
              [
                -65.56352977333721,
                0.676136715187256
              ],
              [
                -65.5574163052969,
                0.670343233790664
              ],
              [
                -65.55302531033435,
                0.658653299363734
              ],
              [
                -65.54524535224566,
                0.655858808169668
              ],
              [
                -65.53992239236331,
                0.649259828813224
              ],
              [
                -65.53390042208564,
                0.660491293339283
              ],
              [
                -65.53250892171776,
                0.663112253390304
              ],
              [
                -65.5299154230766,
                0.667998235245837
              ],
              [
                -65.52071946858958,
                0.669559225619295
              ],
              [
                -65.51233003817728,
                0.670474739230923
              ],
              [
                -65.51192752017286,
                0.670518738971968
              ],
              [
                -65.49992209362718,
                0.672559213685111
              ],
              [
                -65.4964946092609,
                0.676322712904017
              ],
              [
                -65.4943656225928,
                0.678660696233894
              ],
              [
                -65.48942915511036,
                0.678943677304618
              ],
              [
                -65.48410465855963,
                0.679248668224413
              ],
              [
                -65.4829306751695,
                0.681354682788866
              ],
              [
                -65.47965019184294,
                0.687237153633631
              ],
              [
                -65.47460322623932,
                0.684633656815089
              ],
              [
                -65.47163072719842,
                0.683099663994265
              ],
              [
                -65.45218735768232,
                0.685830149458318
              ],
              [
                -65.44244540070454,
                0.6903031224504
              ],
              [
                -65.43824639756212,
                0.699802560409325
              ],
              [
                -65.43787539331616,
                0.700641564253502
              ],
              [
                -65.4356139244575,
                0.701752061550675
              ],
              [
                -65.42939095211877,
                0.704808528849785
              ],
              [
                -65.42301797347751,
                0.70834902874976
              ],
              [
                -65.42120048132631,
                0.727551930300117
              ],
              [
                -65.42072849009561,
                0.732535886778145
              ],
              [
                -65.41695350960485,
                0.735153868474423
              ],
              [
                -65.4099670528637,
                0.739999378840873
              ],
              [
                -65.412375029421,
                0.74367135209517
              ],
              [
                -65.41523951361765,
                0.748040300982899
              ],
              [
                -65.41324351066642,
                0.755527798957679
              ],
              [
                -65.40873354132542,
                0.754104286398524
              ],
              [
                -65.39688660786065,
                0.750366312319958
              ],
              [
                -65.39496464644995,
                0.758622275501002
              ],
              [
                -65.39912910959285,
                0.767483236453559
              ],
              [
                -65.39440414026878,
                0.774715684297596
              ],
              [
                -65.39923208636105,
                0.781326656395024
              ],
              [
                -65.39968158704177,
                0.781943151420204
              ],
              [
                -65.39880261715227,
                0.784124141581919
              ],
              [
                -65.39607612689969,
                0.790882588988757
              ],
              [
                -65.39890360423507,
                0.795292061660632
              ],
              [
                -65.40010859598482,
                0.797171582570164
              ],
              [
                -65.40582055839306,
                0.805766523030948
              ],
              [
                -65.41237952133976,
                0.816134957887279
              ],
              [
                -65.40464456550271,
                0.819242940567141
              ],
              [
                -65.39915309340763,
                0.822921422729775
              ],
              [
                -65.3975476052742,
                0.823995927225546
              ],
              [
                -65.39547061289356,
                0.829592389393553
              ],
              [
                -65.39495961721205,
                0.831325390791504
              ],
              [
                -65.3924471296063,
                0.839840336516984
              ],
              [
                -65.39106965785106,
                0.844491306419183
              ],
              [
                -65.38412167784476,
                0.849471786924787
              ],
              [
                -65.37819071617305,
                0.851473300241303
              ],
              [
                -65.37650072753077,
                0.852044296192977
              ],
              [
                -65.37586170141608,
                0.854272248121913
              ],
              [
                -65.37473520850101,
                0.858196233620415
              ],
              [
                -65.37305421508458,
                0.864052728509044
              ],
              [
                -65.36552075420457,
                0.867611217899429
              ],
              [
                -65.36439728075416,
                0.868046689592997
              ],
              [
                -65.3580638240429,
                0.870500169594341
              ],
              [
                -65.35135434615924,
                0.873357168545203
              ],
              [
                -65.35465481441815,
                0.882270111023065
              ],
              [
                -65.35569579982017,
                0.885081626294555
              ],
              [
                -65.35018934579644,
                0.888821599037868
              ],
              [
                -65.3427343643228,
                0.893886071948715
              ],
              [
                -65.33963040266948,
                0.901986028483207
              ],
              [
                -65.3383693854211,
                0.905789503505611
              ],
              [
                -65.33811342159115,
                0.906541988749709
              ],
              [
                -65.3371859028307,
                0.909266486149778
              ],
              [
                -65.33599390036457,
                0.912172482612051
              ],
              [
                -65.33397044674886,
                0.91715391868985
              ],
              [
                -65.32852295079337,
                0.931927863824548
              ],
              [
                -65.32128199552615,
                0.928390391227255
              ],
              [
                -65.30217458898672,
                0.942538282312029
              ],
              [
                -65.27690070809868,
                0.938589299012309
              ],
              [
                -65.26935377213105,
                0.94367528365572
              ],
              [
                -65.2635637865252,
                0.934437353560476
              ],
              [
                -65.26661778885028,
                0.930250364772901
              ],
              [
                -65.26884026817001,
                0.927204378201019
              ],
              [
                -65.26294728375201,
                0.920033423848863
              ],
              [
                -65.25569233232751,
                0.925293380695301
              ],
              [
                -65.24866638085248,
                0.923184388096221
              ],
              [
                -65.24058891745362,
                0.92245090652367
              ],
              [
                -65.23514494836371,
                0.925067368731706
              ],
              [
                -65.2328384367661,
                0.926176393884323
              ],
              [
                -65.22480098981364,
                0.927963866807504
              ],
              [
                -65.2208310097043,
                0.928132882371186
              ],
              [
                -65.20824656869664,
                0.929178347286263
              ],
              [
                -65.20605260375696,
                0.922098407105973
              ],
              [
                -65.20463308955638,
                0.917516415640473
              ],
              [
                -65.20685610489204,
                0.90728446875482
              ],
              [
                -65.19655263704561,
                0.911198967804949
              ],
              [
                -65.18127521196023,
                0.923526374306139
              ],
              [
                -65.17601826009383,
                0.932122323798122
              ],
              [
                -65.17582325402599,
                0.932441341182969
              ],
              [
                -65.1757942671649,
                0.93248634195496
              ],
              [
                -65.17066728872616,
                0.940495310361804
              ],
              [
                -65.1644003226533,
                0.950859226859511
              ],
              [
                -65.16367230827485,
                0.957657195929326
              ],
              [
                -65.16272782567488,
                0.966412653218201
              ],
              [
                -65.16228432326741,
                0.970522619695648
              ],
              [
                -65.16067732461633,
                0.975206120132193
              ],
              [
                -65.1602058287717,
                0.976581587211134
              ],
              [
                -65.15439383631997,
                0.987215057345208
              ],
              [
                -65.15135588115407,
                0.996297494622012
              ],
              [
                -65.1494238659451,
                1.002078978507668
              ],
              [
                -65.14832638496834,
                1.005166456946124
              ],
              [
                -65.14761237881984,
                1.009753442972879
              ],
              [
                -65.1566143404483,
                1.010426937091069
              ],
              [
                -65.15743832528038,
                1.010584916439006
              ],
              [
                -65.16470579252292,
                1.011984408659264
              ],
              [
                -65.168853263559,
                1.018878890002382
              ],
              [
                -65.16643876830302,
                1.027374336282444
              ],
              [
                -65.15842981450488,
                1.033773816055389
              ],
              [
                -65.15775432132763,
                1.037233795696433
              ],
              [
                -65.15591182302047,
                1.046655217417696
              ],
              [
                -65.15470984023908,
                1.051569212711126
              ],
              [
                -65.15350732945667,
                1.056483671997644
              ],
              [
                -65.15346535061359,
                1.080210046630248
              ],
              [
                -65.15349184097033,
                1.083158032924515
              ],
              [
                -65.15354385370219,
                1.08895300701425
              ],
              [
                -65.15139286875961,
                1.099000967478928
              ],
              [
                -65.14981337016293,
                1.107722897650036
              ],
              [
                -65.15205483628166,
                1.116773883856686
              ],
              [
                -65.15439933695356,
                1.125360823397713
              ],
              [
                -65.13740493889286,
                1.128303785590713
              ],
              [
                -65.1292799771186,
                1.131919770028498
              ],
              [
                -65.12919546353702,
                1.131957781822968
              ],
              [
                -65.12196498328633,
                1.135480268881935
              ],
              [
                -65.12143050866364,
                1.137201742174503
              ],
              [
                -65.1184250400225,
                1.146889693160688
              ],
              [
                -65.11014604676345,
                1.151998671055634
              ],
              [
                -65.10250309832327,
                1.157025158466481
              ],
              [
                -65.09260567448011,
                1.149962675976585
              ],
              [
                -65.0875871744221,
                1.146381717974146
              ],
              [
                -65.08611821378152,
                1.146613211542114
              ],
              [
                -65.07805922416284,
                1.147882205688602
              ],
              [
                -65.07066675633894,
                1.153798180670583
              ],
              [
                -65.06080784922408,
                1.136453253756749
              ],
              [
                -65.06270581178005,
                1.130230271284392
              ],
              [
                -65.06303832930004,
                1.129140298805704
              ],
              [
                -65.06508232343103,
                1.121144324230764
              ],
              [
                -65.06075881884104,
                1.112132374584074
              ],
              [
                -65.05355935143442,
                1.116162369098639
              ],
              [
                -65.04519839541274,
                1.117289337939914
              ],
              [
                -65.02163601668116,
                1.115381373245917
              ],
              [
                -65.01679505873145,
                1.123182817238765
              ],
              [
                -65.01576308164444,
                1.124846309705721
              ],
              [
                -65.0113731024533,
                1.126865285775712
              ],
              [
                -65.00609311668676,
                1.129294271622032
              ],
              [
                -64.99105769073195,
                1.150872171498695
              ],
              [
                -64.96962678091376,
                1.173357027446183
              ],
              [
                -64.96022234733779,
                1.190042934698399
              ],
              [
                -64.96073383110536,
                1.198667914385654
              ],
              [
                -64.96254735021799,
                1.202385899022694
              ],
              [
                -64.9626928104222,
                1.202697890480531
              ],
              [
                -64.96470683440414,
                1.207010376164833
              ],
              [
                -64.96720130964141,
                1.21293534282681
              ],
              [
                -64.96868329912938,
                1.218169806766525
              ],
              [
                -64.9644798161079,
                1.224753280787062
              ],
              [
                -64.9602343544533,
                1.230530242848228
              ],
              [
                -64.95105639162003,
                1.231926719984582
              ],
              [
                -64.94522444037979,
                1.225592760741467
              ],
              [
                -64.94330143571514,
                1.217380797739473
              ],
              [
                -64.94300293647365,
                1.216106815099614
              ],
              [
                -64.93466496654467,
                1.211679332771578
              ],
              [
                -64.9257530230208,
                1.215062299971824
              ],
              [
                -64.91851055718459,
                1.222888287084847
              ],
              [
                -64.91450207674913,
                1.226693736097691
              ],
              [
                -64.91279708707093,
                1.228312245594895
              ],
              [
                -64.90911712733627,
                1.235342201394029
              ],
              [
                -64.90509014958016,
                1.24123468885091
              ],
              [
                -64.90654711211859,
                1.251678608427611
              ],
              [
                -64.89772267357593,
                1.253036110786702
              ],
              [
                -64.8905017090703,
                1.248607115692596
              ],
              [
                -64.88837121136437,
                1.241101661167148
              ],
              [
                -64.88800022350429,
                1.239794690692717
              ],
              [
                -64.88605522785753,
                1.232239734453135
              ],
              [
                -64.87800177636002,
                1.228898235724717
              ],
              [
                -64.86799682345622,
                1.232273229686487
              ],
              [
                -64.86823333645107,
                1.241421664509459
              ],
              [
                -64.85657739935802,
                1.257716597784713
              ],
              [
                -64.85212389571376,
                1.263942544389535
              ],
              [
                -64.86098336756864,
                1.269751017825787
              ],
              [
                -64.85165940083574,
                1.274985982427822
              ],
              [
                -64.84484943523755,
                1.280752468841343
              ],
              [
                -64.83659547709256,
                1.285950412817103
              ],
              [
                -64.82454854333295,
                1.295480354040058
              ],
              [
                -64.81946657081006,
                1.301988342999403
              ],
              [
                -64.80991361970541,
                1.31474426732099
              ],
              [
                -64.80272317307325,
                1.310874779417221
              ],
              [
                -64.78855723324585,
                1.286113920007278
              ],
              [
                -64.79383169465866,
                1.280042445883091
              ],
              [
                -64.79382121173632,
                1.279922961486654
              ],
              [
                -64.79295273821624,
                1.269774021750913
              ],
              [
                -64.77989779487088,
                1.258659560422097
              ],
              [
                -64.77641231273479,
                1.253026597270065
              ],
              [
                -64.77292733659284,
                1.247394103118674
              ],
              [
                -64.76327488293535,
                1.231144700556922
              ],
              [
                -64.75688793533638,
                1.226641225118894
              ],
              [
                -64.74588197558671,
                1.225626230086357
              ],
              [
                -64.73630753231495,
                1.2309867069976
              ],
              [
                -64.72848158341394,
                1.2349656762497
              ],
              [
                -64.72496859188314,
                1.237179660311763
              ],
              [
                -64.72188011628947,
                1.238887648024431
              ],
              [
                -64.72123711462554,
                1.243650115717309
              ],
              [
                -64.72070759054584,
                1.247576595674522
              ],
              [
                -64.71906211975063,
                1.248501090648594
              ],
              [
                -64.71382713985571,
                1.25144206565635
              ],
              [
                -64.70910766625335,
                1.269712497872791
              ],
              [
                -64.7038256938355,
                1.276403462779602
              ],
              [
                -64.70425570678279,
                1.280623939412991
              ],
              [
                -64.70462168418287,
                1.284213407239967
              ],
              [
                -64.70129068864334,
                1.291672379620107
              ],
              [
                -64.68672176926954,
                1.285373888626735
              ],
              [
                -64.68406431006717,
                1.28422490692098
              ],
              [
                -64.67598832995141,
                1.285881906815274
              ],
              [
                -64.66880036447672,
                1.291603358199431
              ],
              [
                -64.66798887548764,
                1.292248841744294
              ],
              [
                -64.66788735800736,
                1.292242375756044
              ],
              [
                -64.65927692910462,
                1.291644372006107
              ],
              [
                -64.65147695949533,
                1.298699313443842
              ],
              [
                -64.64383550120779,
                1.305129795074353
              ],
              [
                -64.6395850234886,
                1.312051264899293
              ],
              [
                -64.63433905151292,
                1.320593704232113
              ],
              [
                -64.62350360308592,
                1.324515155919816
              ],
              [
                -64.61075718781562,
                1.336149090815585
              ],
              [
                -64.60126372725205,
                1.336788602504674
              ],
              [
                -64.60051174108494,
                1.336860089977546
              ],
              [
                -64.59016780181724,
                1.337843081726726
              ],
              [
                -64.58025686022103,
                1.348781030691012
              ],
              [
                -64.5754928583994,
                1.354444519702716
              ],
              [
                -64.58156381922865,
                1.359457473403358
              ],
              [
                -64.58070983689332,
                1.36272747949899
              ],
              [
                -64.57937933560041,
                1.36781892117438
              ],
              [
                -64.58215883461129,
                1.37586688663635
              ],
              [
                -64.5734053632282,
                1.385925333240133
              ],
              [
                -64.5671044126474,
                1.394316807888608
              ],
              [
                -64.56178794402015,
                1.402697734789182
              ],
              [
                -64.55734443912348,
                1.410140701670074
              ],
              [
                -64.55251795029906,
                1.417073686059569
              ],
              [
                -64.54024854032535,
                1.420921154539611
              ],
              [
                -64.53589707332884,
                1.422285622549115
              ],
              [
                -64.52995308088484,
                1.426376122214187
              ],
              [
                -64.53016957721691,
                1.428832098670904
              ],
              [
                -64.53076607861789,
                1.435592057143844
              ],
              [
                -64.52740610482786,
                1.444426516052411
              ],
              [
                -64.51961513317684,
                1.438395068184727
              ],
              [
                -64.51700964141497,
                1.4389625536693
              ],
              [
                -64.51186468455865,
                1.440083542724043
              ],
              [
                -64.50316971069236,
                1.44223254067562
              ],
              [
                -64.49993373711892,
                1.443038501786071
              ],
              [
                -64.49382278734923,
                1.448016487802485
              ],
              [
                -64.48625381172914,
                1.454468437893027
              ],
              [
                -64.48412733394876,
                1.457501455811474
              ],
              [
                -64.48061682781876,
                1.462506406267413
              ],
              [
                -64.47604286995464,
                1.46834986415343
              ],
              [
                -64.45298600158314,
                1.480592803713337
              ],
              [
                -64.44882151762798,
                1.473636355517552
              ],
              [
                -64.44867849436761,
                1.473618343576303
              ],
              [
                -64.43793606093182,
                1.472257363033146
              ],
              [
                -64.43062263297597,
                1.478985826657606
              ],
              [
                -64.42405966218249,
                1.485618765933022
              ],
              [
                -64.42127764752873,
                1.493943236383164
              ],
              [
                -64.41395369396768,
                1.499229190380886
              ],
              [
                -64.4115037174598,
                1.503923154594897
              ],
              [
                -64.41021022152646,
                1.506400163512734
              ],
              [
                -64.40854821221573,
                1.51418261998458
              ],
              [
                -64.40204625202132,
                1.521120584616481
              ],
              [
                -64.39685328829081,
                1.527207522941626
              ],
              [
                -64.38298586227673,
                1.520807583566859
              ],
              [
                -64.37407942038064,
                1.524087549454313
              ],
              [
                -64.36700895659696,
                1.520896088201007
              ],
              [
                -64.36149096800433,
                1.521064585980811
              ],
              [
                -64.35801999505789,
                1.520416568478695
              ],
              [
                -64.35072704208791,
                1.518677082681664
              ],
              [
                -64.3484090579269,
                1.502485166755823
              ],
              [
                -64.35286451933958,
                1.492422701789579
              ],
              [
                -64.35674951524909,
                1.484194779193475
              ],
              [
                -64.37063894721243,
                1.473247841980464
              ],
              [
                -64.3725649376405,
                1.471729322268878
              ],
              [
                -64.37817840651363,
                1.463995393475966
              ],
              [
                -64.37619439953397,
                1.46056688768599
              ],
              [
                -64.37410041516327,
                1.456950392840379
              ],
              [
                -64.37687939476231,
                1.448624953408134
              ],
              [
                -64.38181786158908,
                1.443074979159904
              ],
              [
                -64.38411287740179,
                1.440291031037056
              ],
              [
                -64.38685285404507,
                1.436967017796434
              ],
              [
                -64.38882234343853,
                1.434601538436702
              ],
              [
                -64.39100883880172,
                1.431850043112995
              ],
              [
                -64.39695181677904,
                1.424855112499314
              ],
              [
                -64.39298633173829,
                1.418053148613016
              ],
              [
                -64.3949898161034,
                1.410555661666185
              ],
              [
                -64.3973433177973,
                1.402431699011522
              ],
              [
                -64.39937928490625,
                1.395258261381956
              ],
              [
                -64.39298835522098,
                1.388922293550877
              ],
              [
                -64.38439037475432,
                1.38782230429085
              ],
              [
                -64.37687742465806,
                1.386947781115303
              ],
              [
                -64.37440942827563,
                1.387406270204196
              ],
              [
                -64.3588200199981,
                1.390300772815439
              ],
              [
                -64.35628003097935,
                1.381736833360901
              ],
              [
                -64.3548165489696,
                1.379712812460913
              ],
              [
                -64.34520959241311,
                1.366425895776362
              ],
              [
                -64.33740565120594,
                1.363962918750439
              ],
              [
                -64.33465664383233,
                1.371138866172718
              ],
              [
                -64.34127510365263,
                1.37587535122893
              ],
              [
                -64.34215411218268,
                1.384708286909612
              ],
              [
                -64.3368376370127,
                1.391962267335421
              ],
              [
                -64.32908418027931,
                1.395320754282625
              ],
              [
                -64.32544768450117,
                1.402287198538022
              ],
              [
                -64.3243806960356,
                1.411124168712801
              ],
              [
                -64.32580270502808,
                1.414102640670064
              ],
              [
                -64.32850166882747,
                1.419753109134023
              ],
              [
                -64.32543118490274,
                1.436396024547188
              ],
              [
                -64.31741122435145,
                1.44056450417349
              ],
              [
                -64.31544024282552,
                1.44363196087785
              ],
              [
                -64.31243276828015,
                1.44831245300743
              ],
              [
                -64.31242924220021,
                1.448739428801387
              ],
              [
                -64.31236475568815,
                1.45674242144915
              ],
              [
                -64.30381579187681,
                1.460893866995493
              ],
              [
                -64.30148380014226,
                1.468551329872159
              ],
              [
                -64.2865108859339,
                1.475263792624726
              ],
              [
                -64.28125940455072,
                1.477501276805609
              ],
              [
                -64.2781914201025,
                1.4788077923179
              ],
              [
                -64.27710894410582,
                1.481330249009944
              ],
              [
                -64.27516644808239,
                1.485855720130553
              ],
              [
                -64.2723839705272,
                1.485602238777901
              ],
              [
                -64.26685598222893,
                1.485097251071943
              ],
              [
                -64.26520500571381,
                1.489558197585999
              ],
              [
                -64.26512150709257,
                1.489782699403796
              ],
              [
                -64.26401550710847,
                1.492757212739752
              ],
              [
                -64.26257502269232,
                1.49640865948826
              ],
              [
                -64.260988507975,
                1.5002901570071
              ],
              [
                -64.24683110322357,
                1.508625622406185
              ],
              [
                -64.23960913685929,
                1.511701093715496
              ],
              [
                -64.2308461947373,
                1.509529610845302
              ],
              [
                -64.2227027353824,
                1.507511095337707
              ],
              [
                -64.21477426908565,
                1.509000112694636
              ],
              [
                -64.20172335622489,
                1.519288545139722
              ],
              [
                -64.194329872088,
                1.525298498134822
              ],
              [
                -64.19365537743086,
                1.5341764558712
              ],
              [
                -64.19351189277337,
                1.535824954275108
              ],
              [
                -64.19284388735534,
                1.543528396101467
              ],
              [
                -64.1854039098813,
                1.551841365065879
              ],
              [
                -64.17966694818452,
                1.557687345252752
              ],
              [
                -64.17638496233577,
                1.561194811198793
              ],
              [
                -64.17478948918726,
                1.562900314932106
              ],
              [
                -64.17437147524451,
                1.563346301508503
              ],
              [
                -64.17321748022067,
                1.571431252179333
              ],
              [
                -64.16983550307323,
                1.574190219977105
              ],
              [
                -64.16714852646314,
                1.576381707898909
              ],
              [
                -64.15937504019783,
                1.574748227430403
              ],
              [
                -64.15175809672645,
                1.578732198771738
              ],
              [
                -64.14354115335315,
                1.582809167465237
              ],
              [
                -64.13932814990939,
                1.580073216640089
              ],
              [
                -64.1349251874843,
                1.57721419141061
              ],
              [
                -64.12597120955995,
                1.578902704349717
              ],
              [
                -64.1195102484364,
                1.585617648728407
              ],
              [
                -64.11181528610965,
                1.590804123739797
              ],
              [
                -64.11068282812045,
                1.594267124400094
              ],
              [
                -64.10925231095186,
                1.600598078029833
              ],
              [
                -64.10830632715256,
                1.603416051094527
              ],
              [
                -64.10776783721957,
                1.606293060954677
              ],
              [
                -64.10732032593745,
                1.608679516395061
              ],
              [
                -64.10239033868491,
                1.612107501243482
              ],
              [
                -64.09464741255688,
                1.617491474150834
              ],
              [
                -64.08934390209791,
                1.633159381998892
              ],
              [
                -64.08683043824203,
                1.640587341559479
              ],
              [
                -64.08088047456353,
                1.646817313456912
              ],
              [
                -64.07492947188989,
                1.653048274333001
              ],
              [
                -64.0646085494264,
                1.676423673402852
              ],
              [
                -64.06603803092769,
                1.684167102297859
              ],
              [
                -64.06305104886845,
                1.693899061590273
              ],
              [
                -64.06075654063606,
                1.701983501672566
              ],
              [
                -64.06601651364468,
                1.707978490745629
              ],
              [
                -64.06631653099103,
                1.708320484038565
              ],
              [
                -64.06924100019252,
                1.717836950051346
              ],
              [
                -64.07155697887227,
                1.726162875124935
              ],
              [
                -64.06824050635383,
                1.728992383398501
              ],
              [
                -64.06571500890485,
                1.73114735341873
              ],
              [
                -64.06460404217287,
                1.740291320242024
              ],
              [
                -64.0682645199867,
                1.748033774729857
              ],
              [
                -64.07132998164232,
                1.749458248131817
              ],
              [
                -64.07603894453746,
                1.751646236999677
              ],
              [
                -64.07742796014941,
                1.759423727674798
              ],
              [
                -64.07307346353392,
                1.767547147907427
              ],
              [
                -64.06618502249661,
                1.781351591636074
              ],
              [
                -64.06186852351405,
                1.790002034068805
              ],
              [
                -64.06178053958821,
                1.794877005491878
              ],
              [
                -64.06173204402454,
                1.797745981439289
              ],
              [
                -64.06172553495577,
                1.798106490547626
              ],
              [
                -64.06155403590238,
                1.80142548209932
              ],
              [
                -64.06132001686221,
                1.805830458937908
              ],
              [
                -64.06073501958411,
                1.806958933851879
              ],
              [
                -64.05742603997777,
                1.813341404423549
              ],
              [
                -64.05968952310332,
                1.817143379143673
              ],
              [
                -64.06208151149652,
                1.821160350775038
              ],
              [
                -64.05908351355518,
                1.823049850989112
              ],
              [
                -64.05465405080582,
                1.8258408430529
              ],
              [
                -64.05493503993237,
                1.834159805490025
              ],
              [
                -64.05544352417246,
                1.841420274542451
              ],
              [
                -64.06060849970186,
                1.843427759534626
              ],
              [
                -64.06464847821708,
                1.844998218489668
              ],
              [
                -64.07048096558916,
                1.852775213417664
              ],
              [
                -64.06925847670213,
                1.857023677763585
              ],
              [
                -64.065843977949,
                1.868958607109886
              ],
              [
                -64.06531396921666,
                1.870615086256217
              ],
              [
                -64.06234950689574,
                1.879872036767503
              ],
              [
                -64.06127449552606,
                1.883110012441188
              ],
              [
                -64.06188750103162,
                1.887477008694462
              ],
              [
                -64.06349598822294,
                1.898940422978279
              ],
              [
                -64.06157049291389,
                1.906674394764788
              ],
              [
                -64.05992600419835,
                1.914193871753559
              ],
              [
                -64.06058898162317,
                1.92118830220276
              ],
              [
                -64.06088297604421,
                1.924631784196797
              ],
              [
                -64.06002547745429,
                1.931356263588408
              ],
              [
                -64.05274454597476,
                1.932605763764145
              ],
              [
                -64.04479157682098,
                1.934450250599536
              ],
              [
                -64.04089509445176,
                1.943120686782965
              ],
              [
                -64.03256665640806,
                1.944504677905822
              ],
              [
                -64.02831715787629,
                1.946684167110655
              ],
              [
                -64.01866272665856,
                1.951634152037033
              ],
              [
                -64.0179477030919,
                1.955815602796229
              ],
              [
                -64.01736622406935,
                1.959214096717718
              ],
              [
                -64.01074224847635,
                1.962825561614701
              ],
              [
                -64.00103479320437,
                1.97529252386298
              ],
              [
                -63.99578483924371,
                1.979573463634195
              ],
              [
                -63.989969869587576,
                1.979532474005606
              ],
              [
                -63.986819888027284,
                1.979509980125053
              ],
              [
                -63.98543387581434,
                1.980926459215682
              ],
              [
                -63.97491543732138,
                1.991675390168353
              ],
              [
                -63.967148999823415,
                1.988611907857329
              ],
              [
                -63.960756004886875,
                1.992777910830422
              ],
              [
                -63.95377605960213,
                1.988752922852563
              ],
              [
                -63.95353955988019,
                1.988616431497791
              ],
              [
                -63.951781575030694,
                1.98871339742582
              ],
              [
                -63.949673575003764,
                1.988829430141665
              ],
              [
                -63.94509510084223,
                1.989071900402623
              ],
              [
                -63.94010064879086,
                1.989525426416082
              ],
              [
                -63.93510415123561,
                1.98844891251055
              ],
              [
                -63.934944144093855,
                1.988378395832105
              ],
              [
                -63.930002203608204,
                1.98618541884395
              ],
              [
                -63.92438023052192,
                1.984496444979782
              ],
              [
                -63.92146924478634,
                1.984342927865878
              ],
              [
                -63.913500776387586,
                1.987712390524392
              ],
              [
                -63.91309127945627,
                1.98794288917567
              ],
              [
                -63.906107309740946,
                1.991872882174135
              ],
              [
                -63.897939363159026,
                1.992839883455111
              ],
              [
                -63.89016191281546,
                1.989770884208312
              ],
              [
                -63.88780843284659,
                1.9843079453564
              ],
              [
                -63.887149410774825,
                1.982778431237445
              ],
              [
                -63.875521981065916,
                1.981388919899195
              ],
              [
                -63.87090601043068,
                1.980750922811471
              ],
              [
                -63.868299031192834,
                1.980390432892794
              ],
              [
                -63.8643815398032,
                1.980078435302418
              ],
              [
                -63.860715079085345,
                1.979817438522074
              ],
              [
                -63.853535106977404,
                1.978903438086314
              ],
              [
                -63.85315909215079,
                1.97825193441659
              ],
              [
                -63.84938562096154,
                1.971706469878007
              ],
              [
                -63.84352665877697,
                1.964896019090012
              ],
              [
                -63.83635120985947,
                1.966567496982297
              ],
              [
                -63.82971075808158,
                1.968444004953026
              ],
              [
                -63.82503026666147,
                1.984316409375959
              ],
              [
                -63.82422177314387,
                1.986727397341558
              ],
              [
                -63.8230187553239,
                1.990315372941893
              ],
              [
                -63.81374380719551,
                1.989566875957265
              ],
              [
                -63.8067968712272,
                1.987675891774135
              ],
              [
                -63.799369389156496,
                1.98779439278423
              ],
              [
                -63.79848891289391,
                1.987419876490779
              ],
              [
                -63.79647740171679,
                1.986565381383942
              ],
              [
                -63.792013929738694,
                1.984655378098285
              ],
              [
                -63.78718046964824,
                1.982926894734675
              ],
              [
                -63.784367990723574,
                1.98192841214059
              ],
              [
                -63.776974512938395,
                1.987031867712131
              ],
              [
                -63.77380103645557,
                1.993857313471845
              ],
              [
                -63.773301037869885,
                1.994527836752689
              ],
              [
                -63.768675069204626,
                2.000734314321358
              ],
              [
                -63.75964110347721,
                2.004460276639559
              ],
              [
                -63.75730463345975,
                2.002221791782425
              ],
              [
                -63.751271648982,
                1.99644231555714
              ],
              [
                -63.73992470847439,
                2.00005780372095
              ],
              [
                -63.742146720996395,
                2.008901241429341
              ],
              [
                -63.74050971549358,
                2.01238873336482
              ],
              [
                -63.73849123873549,
                2.016690206846592
              ],
              [
                -63.7304597614267,
                2.018361672368634
              ],
              [
                -63.72764277357898,
                2.02669214014914
              ],
              [
                -63.717288841306136,
                2.035196110540283
              ],
              [
                -63.70265791258226,
                2.046683523611111
              ],
              [
                -63.69325446077879,
                2.041399063685715
              ],
              [
                -63.68821799016745,
                2.032454081692357
              ],
              [
                -63.687933500827306,
                2.031948594292425
              ],
              [
                -63.691591457246105,
                2.023586635010939
              ],
              [
                -63.67436657855041,
                2.021683648580996
              ],
              [
                -63.66737060679523,
                2.017307173641536
              ],
              [
                -63.66158966015971,
                2.02386914080708
              ],
              [
                -63.660201131351336,
                2.03363156231925
              ],
              [
                -63.660517645094494,
                2.035467081636719
              ],
              [
                -63.66436361162403,
                2.057786945882944
              ],
              [
                -63.66154062787376,
                2.065853402930176
              ],
              [
                -63.653252654727915,
                2.068369885768524
              ],
              [
                -63.64612220411444,
                2.065908401549715
              ],
              [
                -63.64436422557958,
                2.065301399209279
              ],
              [
                -63.63744476563847,
                2.069663359642047
              ],
              [
                -63.63355578867819,
                2.077208330070561
              ],
              [
                -63.62986481456656,
                2.085059277915334
              ],
              [
                -63.622132837894235,
                2.089483268705026
              ],
              [
                -63.61797586144218,
                2.096702238739795
              ],
              [
                -63.61779487687329,
                2.098285690216406
              ],
              [
                -63.61680485022828,
                2.106936652643372
              ],
              [
                -63.606069916568,
                2.107868145051647
              ],
              [
                -63.60591240739484,
                2.107881640926489
              ],
              [
                -63.60554793118592,
                2.108280645670134
              ],
              [
                -63.60034346376301,
                2.113971610479123
              ],
              [
                -63.59382397897971,
                2.120568577982699
              ],
              [
                -63.58488002639626,
                2.120010087944852
              ],
              [
                -63.5682181107218,
                2.125073049195495
              ],
              [
                -63.5612946517354,
                2.132743499758518
              ],
              [
                -63.55363318239433,
                2.132260492739694
              ],
              [
                -63.54409674401816,
                2.131654508743754
              ],
              [
                -63.538961273232516,
                2.131485984900865
              ],
              [
                -63.53575481022416,
                2.131388000336
              ],
              [
                -63.52801433231388,
                2.127978507986994
              ],
              [
                -63.51493391156867,
                2.121883058242557
              ],
              [
                -63.51380692170882,
                2.12135804167725
              ],
              [
                -63.5087649388645,
                2.113959097659771
              ],
              [
                -63.49939901205422,
                2.115144585142192
              ],
              [
                -63.491864545720674,
                2.1158415588609
              ],
              [
                -63.483997600594954,
                2.121220545332235
              ],
              [
                -63.47587064392626,
                2.126379018883335
              ],
              [
                -63.4749151207862,
                2.126985498302424
              ],
              [
                -63.473724647291135,
                2.138309948072886
              ],
              [
                -63.46603069003638,
                2.141366910024737
              ],
              [
                -63.459570727815944,
                2.136804458339039
              ],
              [
                -63.45517773218194,
                2.133603970043683
              ],
              [
                -63.45397376685983,
                2.132727459344975
              ],
              [
                -63.44994726319357,
                2.129796978727238
              ],
              [
                -63.44629380338649,
                2.127355000613183
              ],
              [
                -63.44353281263703,
                2.125540491430777
              ],
              [
                -63.43349734546685,
                2.12674800753889
              ],
              [
                -63.428147371370365,
                2.134150440913905
              ],
              [
                -63.4271238913251,
                2.135567445944047
              ],
              [
                -63.4248929205798,
                2.134329956841652
              ],
              [
                -63.42034141858277,
                2.131805456875016
              ],
              [
                -63.41283598522981,
                2.138329934216729
              ],
              [
                -63.405195034642546,
                2.144725390186835
              ],
              [
                -63.39712606462879,
                2.147285384645063
              ],
              [
                -63.39325258684009,
                2.157214816839604
              ],
              [
                -63.39330257430143,
                2.158019287523389
              ],
              [
                -63.39380256990213,
                2.166083242254618
              ],
              [
                -63.3912695777941,
                2.173590720928646
              ],
              [
                -63.38825407746621,
                2.183356645250087
              ],
              [
                -63.39463604666356,
                2.188880134701079
              ],
              [
                -63.394508062477215,
                2.200075564309665
              ],
              [
                -63.385903107234384,
                2.202837031403452
              ],
              [
                -63.376061144295136,
                2.209288021151159
              ],
              [
                -63.371834199798315,
                2.212057991718376
              ],
              [
                -63.359284746598654,
                2.197405070379701
              ],
              [
                -63.31026503445203,
                2.165311725612163
              ],
              [
                -63.28225670643485,
                2.154188786076203
              ],
              [
                -63.27218524458091,
                2.155774277054055
              ],
              [
                -63.26211329574586,
                2.157358273034848
              ],
              [
                -63.19360768919844,
                2.181522616572985
              ],
              [
                -63.16493283379709,
                2.180620619524909
              ],
              [
                -63.15810287339435,
                2.174575128961935
              ],
              [
                -63.14097998663032,
                2.172756144920238
              ],
              [
                -63.13706850743288,
                2.161383694303872
              ],
              [
                -63.13776898217383,
                2.142165298835752
              ],
              [
                -63.119303117132915,
                2.096762550915989
              ],
              [
                -63.11301863759706,
                2.088209600217303
              ],
              [
                -63.11301616761098,
                2.088207594227375
              ],
              [
                -63.1067331870607,
                2.07965714052513
              ],
              [
                -63.09824772080151,
                2.072658207691767
              ],
              [
                -63.08501830623944,
                2.061745734550369
              ],
              [
                -63.07769486795847,
                2.051019313356398
              ],
              [
                -63.070371385667606,
                2.040292871175547
              ],
              [
                -63.02325418255477,
                2.014975981433551
              ],
              [
                -62.98026739654862,
                2.018093476741134
              ],
              [
                -62.94795458916264,
                2.031347878250856
              ],
              [
                -62.91929525738904,
                2.025291402019397
              ],
              [
                -62.83830469883577,
                2.016776418525762
              ],
              [
                -62.83334374212054,
                2.015195450574343
              ],
              [
                -62.82838176041426,
                2.013614430625223
              ],
              [
                -62.81271935879307,
                1.991987580768616
              ],
              [
                -62.741953778266556,
                1.966978197290946
              ],
              [
                -62.72039189469874,
                1.955033745950342
              ],
              [
                -62.71294642332451,
                1.94753230070256
              ],
              [
                -62.70550146194687,
                1.940031347464133
              ],
              [
                -62.69819652989428,
                1.912265481173816
              ],
              [
                -62.703650488571974,
                1.874255690670518
              ],
              [
                -62.7305388629717,
                1.818541503789542
              ],
              [
                -62.72321343083686,
                1.712695614723409
              ],
              [
                -62.72806042689127,
                1.700297181542494
              ],
              [
                -62.732960371653185,
                1.695413720441317
              ],
              [
                -62.75510126877951,
                1.676538339030091
              ],
              [
                -62.75564075998073,
                1.676078325674409
              ],
              [
                -62.76535419059549,
                1.671617859495101
              ],
              [
                -62.77136317717415,
                1.659780438404072
              ],
              [
                -62.77296915230836,
                1.656616458014299
              ],
              [
                -62.79581953535632,
                1.630980115144165
              ],
              [
                -62.80616848115051,
                1.604416264871372
              ],
              [
                -62.80504101496271,
                1.597540786859528
              ],
              [
                -62.803913508767735,
                1.590665819842357
              ],
              [
                -62.781252130661095,
                1.572825403886263
              ],
              [
                -62.76424022880242,
                1.555884523537872
              ],
              [
                -62.75626427587797,
                1.547940044664075
              ],
              [
                -62.75551729422016,
                1.546696558225181
              ],
              [
                -62.74925633560684,
                1.536282623176998
              ],
              [
                -62.747585852836124,
                1.535494611044752
              ],
              [
                -62.735821913841185,
                1.529942668306496
              ],
              [
                -62.731297438133026,
                1.505490293846729
              ],
              [
                -62.730271450989584,
                1.49994480167452
              ],
              [
                -62.725275460339475,
                1.494397362572766
              ],
              [
                -62.720279519688184,
                1.488849384479533
              ],
              [
                -62.69803512523404,
                1.475756461279889
              ],
              [
                -62.67637325642824,
                1.454943047186697
              ],
              [
                -62.64397343028211,
                1.441351644544632
              ],
              [
                -62.64041047944413,
                1.437693634597052
              ],
              [
                -62.636847480606136,
                1.434036164649235
              ],
              [
                -62.61970808631035,
                1.404079842470211
              ],
              [
                -62.61998907883779,
                1.39467086661661
              ],
              [
                -62.62141408558466,
                1.392431418087422
              ],
              [
                -62.62581657217347,
                1.385513457601815
              ],
              [
                -62.61998707965919,
                1.36300308548079
              ],
              [
                -62.61513662906958,
                1.357992604622573
              ],
              [
                -62.61028515448517,
                1.352982135769127
              ],
              [
                -62.58637578719515,
                1.255793160861494
              ],
              [
                -62.55414251178522,
                1.175181121398097
              ],
              [
                -62.54567056891562,
                1.124816886524514
              ],
              [
                -62.52913815907173,
                1.089134081723533
              ],
              [
                -62.52484769185978,
                1.086632080524594
              ],
              [
                -62.52055718764981,
                1.084130601325629
              ],
              [
                -62.4894178801898,
                1.084951107552729
              ],
              [
                -62.489076902023754,
                1.085098110698081
              ],
              [
                -62.47971243339867,
                1.089130103248204
              ],
              [
                -62.47108948785411,
                1.086598090488994
              ],
              [
                -62.469980494396566,
                1.077733155340201
              ],
              [
                -62.4768624609275,
                1.059310738299418
              ],
              [
                -62.477755451451415,
                1.056921260388783
              ],
              [
                -62.478591457308184,
                1.048283322300467
              ],
              [
                -62.476467447461864,
                1.044779320921251
              ],
              [
                -62.47389545217722,
                1.040536863467729
              ],
              [
                -62.47565346864332,
                1.034011881079787
              ],
              [
                -62.4772009621269,
                1.028264442441326
              ],
              [
                -62.47416549153507,
                1.016615981992862
              ],
              [
                -62.46109556817784,
                0.995224597677757
              ],
              [
                -62.44525762737644,
                0.977169216923439
              ],
              [
                -62.443850651328226,
                0.968768254170269
              ],
              [
                -62.44244265227488,
                0.960367312413036
              ],
              [
                -62.453043109168775,
                0.934950440462659
              ],
              [
                -62.46167606731595,
                0.926317003394611
              ],
              [
                -62.470308492466266,
                0.917684020292646
              ],
              [
                -62.485534449500676,
                0.866852325812455
              ],
              [
                -62.48302695277056,
                0.854919407830559
              ],
              [
                -62.48051947601901,
                0.842986973840225
              ],
              [
                -62.46817053482375,
                0.831781009635886
              ],
              [
                -62.465540554477805,
                0.82939402716395
              ],
              [
                -62.463875054117075,
                0.825090554154593
              ],
              [
                -62.462209078756594,
                0.820786069150989
              ],
              [
                -62.45720459246817,
                0.785514780667151
              ],
              [
                -62.459841585588784,
                0.781351801428053
              ],
              [
                -62.4624785677078,
                0.777188820183621
              ],
              [
                -62.499963343765735,
                0.748262500416227
              ],
              [
                -62.528040198114894,
                0.710526716464487
              ],
              [
                -62.53498316910647,
                0.694151306153242
              ],
              [
                -62.53775915398299,
                0.67551542145935
              ],
              [
                -62.51777079077817,
                0.584129409462365
              ],
              [
                -62.5272102196559,
                0.546867123870679
              ],
              [
                -62.532480207065454,
                0.50914131816237
              ],
              [
                -62.527456242328846,
                0.497512877950136
              ],
              [
                -62.522431778577776,
                0.485884933739188
              ],
              [
                -62.52101477232243,
                0.472095532357411
              ],
              [
                -62.51812978803564,
                0.44402119819528
              ],
              [
                -62.51341631383926,
                0.442112190066575
              ],
              [
                -62.508702341648494,
                0.440204205934799
              ],
              [
                -62.484715459376915,
                0.454402144323983
              ],
              [
                -62.46721057874851,
                0.426353260157718
              ],
              [
                -62.46262558703256,
                0.42218130716907
              ],
              [
                -62.45804111731435,
                0.418009821182199
              ],
              [
                -62.4557361413545,
                0.394837469982189
              ],
              [
                -62.455524659638634,
                0.392709984346393
              ],
              [
                -62.455029145383605,
                0.392030470949568
              ],
              [
                -62.445813204429975,
                0.379402055922278
              ],
              [
                -62.45247717939022,
                0.373853557985845
              ],
              [
                -62.451377181872,
                0.365510118523921
              ],
              [
                -62.460523609669295,
                0.353004183874337
              ],
              [
                -62.46344311327756,
                0.343142742713174
              ],
              [
                -62.466362089875005,
                0.333281306532405
              ],
              [
                -62.45805763115232,
                0.313847887867186
              ],
              [
                -62.467757565974445,
                0.276075123873982
              ],
              [
                -62.45388414495576,
                0.243874805014909
              ],
              [
                -62.45220717437397,
                0.20773698157324
              ],
              [
                -62.4451327031777,
                0.201478509693139
              ],
              [
                -62.438057756985735,
                0.195220048822721
              ],
              [
                -62.42804132428145,
                0.173852162396283
              ],
              [
                -62.422772844764474,
                0.144997316981962
              ],
              [
                -62.42554580290316,
                0.131921900672022
              ],
              [
                -62.42831831201881,
                0.118847484326842
              ],
              [
                -62.42387481672248,
                0.091908118606517
              ],
              [
                -62.41914786965379,
                0.083304176315412
              ],
              [
                -62.41442189357181,
                0.074700203029355
              ],
              [
                -62.390264008727364,
                0.059958801211498
              ],
              [
                -62.37027164108653,
                0.05440431859977
              ],
              [
                -62.362272662926586,
                0.042756384559471
              ],
              [
                -62.36111120729123,
                0.041064417560885
              ],
              [
                -62.35470920434548,
                0.036320943780306
              ],
              [
                -62.34608426657595,
                0.029929988770065
              ],
              [
                -62.32220938930857,
                -0.023923722482976
              ],
              [
                -62.32439088834365,
                -0.028282719287587
              ],
              [
                -62.32582289148834,
                -0.031144201063606
              ],
              [
                -62.32349389822454,
                -0.034631687489845
              ],
              [
                -62.321375398808776,
                -0.037804168592688
              ],
              [
                -62.322790395873376,
                -0.061678528569427
              ],
              [
                -62.32608087185859,
                -0.061164504335518
              ],
              [
                -62.331673866241665,
                -0.060291014034852
              ],
              [
                -62.32973535977186,
                -0.068612471761646
              ],
              [
                -62.32523440741004,
                -0.069388469585955
              ],
              [
                -62.3216759028915,
                -0.070001462286605
              ],
              [
                -62.31740344412127,
                -0.084194906748433
              ],
              [
                -62.318304938058844,
                -0.093198350886164
              ],
              [
                -62.319205910986824,
                -0.102200815040402
              ],
              [
                -62.29921952313686,
                -0.122198197376829
              ],
              [
                -62.293403564899194,
                -0.128017640321174
              ],
              [
                -62.291733571939744,
                -0.133572646751795
              ],
              [
                -62.29006458597045,
                -0.139127085185921
              ],
              [
                -62.2675632087805,
                -0.163560953740774
              ],
              [
                -62.26185323108228,
                -0.164400453129221
              ],
              [
                -62.25812327953239,
                -0.164948960115393
              ],
              [
                -62.2445058440126,
                -0.174392891518478
              ],
              [
                -62.24145536356474,
                -0.181337858489234
              ],
              [
                -62.24645683487146,
                -0.18966880132683
              ],
              [
                -62.244784829372804,
                -0.21133067610558
              ],
              [
                -62.25112082338712,
                -0.217673161610736
              ],
              [
                -62.25283830790363,
                -0.219392156535617
              ],
              [
                -62.252561308823026,
                -0.236337056504891
              ],
              [
                -62.24919281798777,
                -0.244915524813053
              ],
              [
                -62.24645485074533,
                -0.251888472671988
              ],
              [
                -62.23784789896926,
                -0.25244448337703
              ],
              [
                -62.24117988035647,
                -0.260500441722093
              ],
              [
                -62.24739881899614,
                -0.265997410790687
              ],
              [
                -62.253122291374495,
                -0.271055865179685
              ],
              [
                -62.253675778980586,
                -0.279387836983346
              ],
              [
                -62.24496184214824,
                -0.294092246558178
              ],
              [
                -62.247843826982006,
                -0.301608699811428
              ],
              [
                -62.23895739847084,
                -0.30771966896242
              ],
              [
                -62.23534189087498,
                -0.316888617135749
              ],
              [
                -62.22590245770775,
                -0.316888617664991
              ],
              [
                -62.22328945017591,
                -0.314495115238485
              ],
              [
                -62.21923100064787,
                -0.310776636777635
              ],
              [
                -62.187840171167956,
                -0.330494042490252
              ],
              [
                -62.190620160683714,
                -0.334387027959286
              ],
              [
                -62.19339963120113,
                -0.338278983438798
              ],
              [
                -62.19373913299941,
                -0.344602950879791
              ],
              [
                -62.19478712216446,
                -0.364114355371539
              ],
              [
                -62.20048007772857,
                -0.366682845069737
              ],
              [
                -62.20339756462014,
                -0.367999339253873
              ],
              [
                -62.205904557345605,
                -0.37660879673256
              ],
              [
                -62.20459205925945,
                -0.381674765786463
              ],
              [
                -62.20173107531736,
                -0.392716704056398
              ],
              [
                -62.20828454613403,
                -0.395511701643731
              ],
              [
                -62.21672950651532,
                -0.399113147082385
              ],
              [
                -62.21397400330879,
                -0.404620129723781
              ],
              [
                -62.21228502237474,
                -0.407995109730501
              ],
              [
                -62.217397007251,
                -0.408633600675658
              ],
              [
                -62.22117996044151,
                -0.409106615412966
              ],
              [
                -62.22340446280241,
                -0.415677067751846
              ],
              [
                -62.22672941789962,
                -0.425499505952761
              ],
              [
                -62.23436739775706,
                -0.428530008421445
              ],
              [
                -62.24284435899444,
                -0.431892971196109
              ],
              [
                -62.24812529750076,
                -0.439386958906788
              ],
              [
                -62.27340367080927,
                -0.453829363320085
              ],
              [
                -62.27756715318213,
                -0.464943807894886
              ],
              [
                -62.273130162280225,
                -0.468185768441547
              ],
              [
                -62.27034218842298,
                -0.47022328447077
              ],
              [
                -62.27451017296873,
                -0.478282217209488
              ],
              [
                -62.281241112116476,
                -0.477490252898713
              ],
              [
                -62.283958596240055,
                -0.477170232792879
              ],
              [
                -62.289789075777385,
                -0.483831199066222
              ],
              [
                -62.28800360023751,
                -0.48817067802242
              ],
              [
                -62.28590208736906,
                -0.493278148899914
              ],
              [
                -62.294229057619106,
                -0.507443567804825
              ],
              [
                -62.30062451751489,
                -0.508104054832175
              ],
              [
                -62.30228899337973,
                -0.508276053058256
              ],
              [
                -62.30867348495417,
                -0.513833051267763
              ],
              [
                -62.30577147194125,
                -0.525141970406954
              ],
              [
                -62.303398509182436,
                -0.534388406466054
              ],
              [
                -62.308717468879045,
                -0.536291401884449
              ],
              [
                -62.31117596733604,
                -0.537169927462566
              ],
              [
                -62.31009494289512,
                -0.541503398535322
              ],
              [
                -62.30895846073657,
                -0.546058347436959
              ],
              [
                -62.30988195162281,
                -0.546693858167914
              ],
              [
                -62.31784341954011,
                -0.552169316012714
              ],
              [
                -62.307847482996735,
                -0.567162237164167
              ],
              [
                -62.30923594197258,
                -0.583001151882758
              ],
              [
                -62.304135504670384,
                -0.585746129960308
              ],
              [
                -62.30201200120292,
                -0.586888635748219
              ],
              [
                -62.29034558376054,
                -0.601888550669399
              ],
              [
                -62.291455062892766,
                -0.610226488873029
              ],
              [
                -62.29597404710805,
                -0.610375510522893
              ],
              [
                -62.2997910211761,
                -0.610501028229186
              ],
              [
                -62.301179491770604,
                -0.618829971529074
              ],
              [
                -62.29401504272747,
                -0.622046929898983
              ],
              [
                -62.2931185356027,
                -0.622449422692737
              ],
              [
                -62.28700758557848,
                -0.638007374206932
              ],
              [
                -62.290344058456,
                -0.646330326750908
              ],
              [
                -62.295897027455794,
                -0.651890287071482
              ],
              [
                -62.30368000060325,
                -0.653836776635492
              ],
              [
                -62.308675451548936,
                -0.66050522809408
              ],
              [
                -62.317565405760895,
                -0.661330232683517
              ],
              [
                -62.319775880791966,
                -0.65997624402879
              ],
              [
                -62.32617734113295,
                -0.656055768293114
              ],
              [
                -62.33145283864601,
                -0.661892230178355
              ],
              [
                -62.33225730972609,
                -0.666875716086571
              ],
              [
                -62.332841821148996,
                -0.670496176133644
              ],
              [
                -62.34082027493616,
                -0.675583663266301
              ],
              [
                -62.34393976909062,
                -0.673389671115837
              ],
              [
                -62.348322718423724,
                -0.670306200766504
              ],
              [
                -62.35331569942942,
                -0.676698155747877
              ],
              [
                -62.370260100758316,
                -0.676419662015861
              ],
              [
                -62.372209081067666,
                -0.68614310123069
              ],
              [
                -62.36887310835816,
                -0.695034549244534
              ],
              [
                -62.366170138991905,
                -0.696433560612377
              ],
              [
                -62.36082417593709,
                -0.699201019512118
              ],
              [
                -62.35832016481764,
                -0.706693481944532
              ],
              [
                -62.366654627885055,
                -0.709746487868205
              ],
              [
                -62.37953804079183,
                -0.706409495796243
              ],
              [
                -62.38164653532265,
                -0.705863482892236
              ],
              [
                -62.38998097239018,
                -0.709195969361876
              ],
              [
                -62.391250471817585,
                -0.714907462587193
              ],
              [
                -62.39220299213223,
                -0.719195928234675
              ],
              [
                -62.40637489580691,
                -0.726971868139547
              ],
              [
                -62.409983897983984,
                -0.710027499245346
              ],
              [
                -62.41305537657221,
                -0.70669047905835
              ],
              [
                -62.41637484983506,
                -0.703084035307227
              ],
              [
                -62.41886680332924,
                -0.702118503637794
              ],
              [
                -62.42259280213665,
                -0.700676033600538
              ],
              [
                -62.42498629016649,
                -0.699749048716782
              ],
              [
                -62.42804427025021,
                -0.69252959584022
              ],
              [
                -62.45526411676415,
                -0.691695594175688
              ],
              [
                -62.46331755424537,
                -0.687809117377075
              ],
              [
                -62.46564904927738,
                -0.690140108441902
              ],
              [
                -62.46914852981289,
                -0.693639082530737
              ],
              [
                -62.47643651364655,
                -0.688264101422635
              ],
              [
                -62.486095932741904,
                -0.68114064169899
              ],
              [
                -62.49248542426155,
                -0.687255624434781
              ],
              [
                -62.494985410860615,
                -0.703639034128505
              ],
              [
                -62.50775934411931,
                -0.713921948870922
              ],
              [
                -62.50553833234375,
                -0.730589872633679
              ],
              [
                -62.50164037639464,
                -0.732238346581697
              ],
              [
                -62.498319861328596,
                -0.733642848868097
              ],
              [
                -62.49442588362358,
                -0.75030627046043
              ],
              [
                -62.50161986695929,
                -0.754229766846783
              ],
              [
                -62.5097068003784,
                -0.758640240811462
              ],
              [
                -62.503870352384865,
                -0.765308683338303
              ],
              [
                -62.50054235661159,
                -0.781138596398543
              ],
              [
                -62.480265471466836,
                -0.794748018917037
              ],
              [
                -62.472860024214455,
                -0.791790051417697
              ],
              [
                -62.46498104557556,
                -0.788642556856341
              ],
              [
                -62.46228305919585,
                -0.797525026172742
              ],
              [
                -62.461100554162144,
                -0.801417468715558
              ],
              [
                -62.45276613935748,
                -0.803642456050976
              ],
              [
                -62.44776865086263,
                -0.809746443562512
              ],
              [
                -62.44427817454807,
                -0.804695453478769
              ],
              [
                -62.44220717422716,
                -0.801697466865233
              ],
              [
                -62.432758225567824,
                -0.803638461535415
              ],
              [
                -62.42776378379093,
                -0.811969919397719
              ],
              [
                -62.39526643530856,
                -0.820025375139208
              ],
              [
                -62.39359645033147,
                -0.828637810920624
              ],
              [
                -62.37553704909906,
                -0.836140276343993
              ],
              [
                -62.37997901886451,
                -0.843358740207434
              ],
              [
                -62.37303907715914,
                -0.848359224151315
              ],
              [
                -62.36943207948553,
                -0.85891668649665
              ],
              [
                -62.3709190651355,
                -0.860535177241291
              ],
              [
                -62.37581557263913,
                -0.865865104062734
              ],
              [
                -62.366537604993546,
                -0.868774597594743
              ],
              [
                -62.36609409840088,
                -0.868913600807757
              ],
              [
                -62.36596111004099,
                -0.869559597414334
              ],
              [
                -62.36443060440595,
                -0.876976052456973
              ],
              [
                -62.3604571342899,
                -0.875912570464264
              ],
              [
                -62.356098176299625,
                -0.874745561960863
              ],
              [
                -62.3461837251344,
                -0.892385487402664
              ],
              [
                -62.33970573198496,
                -0.90391240725533
              ],
              [
                -62.32804180923914,
                -0.915305365051101
              ],
              [
                -62.32566134431729,
                -0.915015863220806
              ],
              [
                -62.316647368847974,
                -0.913916854666588
              ],
              [
                -62.31342287983904,
                -0.925621302017468
              ],
              [
                -62.3122093938519,
                -0.930026274816641
              ],
              [
                -62.29776047149751,
                -0.940586212490009
              ],
              [
                -62.29331452780897,
                -0.947801681109107
              ],
              [
                -62.289668038087335,
                -0.945849177773011
              ],
              [
                -62.28554153803683,
                -0.943639206714591
              ],
              [
                -62.27165262902051,
                -0.951692658523156
              ],
              [
                -62.26512317506149,
                -0.963640578045968
              ],
              [
                -62.2585932000901,
                -0.975588520559677
              ],
              [
                -62.23612731867538,
                -0.973831537304975
              ],
              [
                -62.22692336732917,
                -0.973111530663728
              ],
              [
                -62.216682942435,
                -0.980665492539555
              ],
              [
                -62.213032957078305,
                -0.983357461879868
              ],
              [
                -62.21220496763876,
                -0.983285946126246
              ],
              [
                -62.20332100657611,
                -0.982521975753338
              ],
              [
                -62.201701511361456,
                -0.989805424353797
              ],
              [
                -62.199429532270145,
                -1.000024381481385
              ],
              [
                -62.178582150253085,
                -1.004156361551483
              ],
              [
                -62.171376186666905,
                -1.005584322930105
              ],
              [
                -62.16956769896379,
                -1.00975130580706
              ],
              [
                -62.167759699255754,
                -1.013918804680932
              ],
              [
                -62.15442528574087,
                -1.025857745976403
              ],
              [
                -62.144980336375916,
                -1.02808369978385
              ],
              [
                -62.142478870722734,
                -1.03697318278066
              ],
              [
                -62.13411090679946,
                -1.036973164416437
              ],
              [
                -62.1249884360432,
                -1.036973161929255
              ],
              [
                -62.1108150353801,
                -1.06002952568284
              ],
              [
                -62.11465051277525,
                -1.068927476670999
              ],
              [
                -62.11775800545341,
                -1.076136936404739
              ],
              [
                -62.114144504200944,
                -1.083074921429443
              ],
              [
                -62.10498057418673,
                -1.086138396810109
              ],
              [
                -62.09914908959051,
                -1.096412845952187
              ],
              [
                -62.08747917336371,
                -1.0997488234494
              ],
              [
                -62.071093742165466,
                -1.09725234371447
              ],
              [
                -62.06636977204016,
                -1.098361319081484
              ],
              [
                -62.06164580891802,
                -1.0994708054444
              ],
              [
                -62.03914745291356,
                -1.11807619088298
              ],
              [
                -62.016926562567406,
                -1.14141259144069
              ],
              [
                -61.98886621178211,
                -1.201410258372092
              ],
              [
                -61.98817422820806,
                -1.208909198892682
              ],
              [
                -61.987481692628435,
                -1.216408173417477
              ],
              [
                -61.97331477166208,
                -1.233936565734298
              ],
              [
                -61.96637332142115,
                -1.242525020326003
              ],
              [
                -61.961373875858676,
                -1.2540494478857
              ],
              [
                -61.95637337928551,
                -1.265574390439103
              ],
              [
                -61.94942443257695,
                -1.296688706865239
              ],
              [
                -61.92000055958268,
                -1.330899045412586
              ],
              [
                -61.91429910937191,
                -1.339626467451296
              ],
              [
                -61.90859663816033,
                -1.348353928482443
              ],
              [
                -61.906439165253076,
                -1.366742825607878
              ],
              [
                -61.904978156705326,
                -1.379189758050121
              ],
              [
                -61.90053569663766,
                -1.387106233595918
              ],
              [
                -61.89609370556102,
                -1.395022173140547
              ],
              [
                -61.86886737320143,
                -1.401411642674759
              ],
              [
                -61.83877803724063,
                -1.401411624065573
              ],
              [
                -61.826372117152424,
                -1.401411623341153
              ],
              [
                -61.82151161539908,
                -1.40029864211903
              ],
              [
                -61.81665063765183,
                -1.399185630898883
              ],
              [
                -61.81422267566241,
                -1.39679366693228
              ],
              [
                -61.797476249297326,
                -1.380297252988619
              ],
              [
                -61.78968032200342,
                -1.378634756046427
              ],
              [
                -61.76915243636964,
                -1.382521694160148
              ],
              [
                -61.74025611626546,
                -1.391685643647264
              ],
              [
                -61.71748073729662,
                -1.409460047047943
              ],
              [
                -61.657479544495615,
                -1.42974794878936
              ],
              [
                -61.64623062786873,
                -1.431688921033557
              ],
              [
                -61.646226626890716,
                -1.431690419024776
              ],
              [
                -61.63498169826079,
                -1.433630905266351
              ],
              [
                -61.604422381873604,
                -1.44779834259335
              ],
              [
                -61.57164603941689,
                -1.477796150666685
              ],
              [
                -61.56539710144855,
                -1.486268116467114
              ],
              [
                -61.559147609478536,
                -1.49474004525904
              ],
              [
                -61.54415019332679,
                -1.525292910341964
              ],
              [
                -61.49052898807229,
                -1.574468095575703
              ],
              [
                -61.48220306435424,
                -1.580299554708012
              ],
              [
                -61.480148048986045,
                -1.579874571447283
              ],
              [
                -61.479950546104,
                -1.579833568615315
              ],
              [
                -61.474144101973444,
                -1.578631597537575
              ],
              [
                -61.47498008151861,
                -1.552796237355136
              ],
              [
                -61.46997461432899,
                -1.545575758341087
              ],
              [
                -61.46942563925909,
                -1.537792810390561
              ],
              [
                -61.472759128981615,
                -1.527517367566054
              ],
              [
                -61.480814569135326,
                -1.517515422413483
              ],
              [
                -61.515949883722044,
                -1.50209799825867
              ],
              [
                -61.532482767637234,
                -1.464740206681467
              ],
              [
                -61.5388667646152,
                -1.443630822813578
              ],
              [
                -61.53886323864162,
                -1.44360084797137
              ],
              [
                -61.537763267138004,
                -1.433072385440615
              ],
              [
                -61.54747970258507,
                -1.429185407183005
              ],
              [
                -61.555216155326946,
                -1.428936912224926
              ],
              [
                -61.57331405016967,
                -1.428355427340168
              ],
              [
                -61.57734402172776,
                -1.418772962907078
              ],
              [
                -61.581373512277295,
                -1.409189536477028
              ],
              [
                -61.60372740008379,
                -1.405453564145698
              ],
              [
                -61.60796935552498,
                -1.404744568824476
              ],
              [
                -61.614150330520296,
                -1.4022425684023
              ],
              [
                -61.61859080838379,
                -1.394467131460003
              ],
              [
                -61.59942491931294,
                -1.367248273236605
              ],
              [
                -61.59535593049256,
                -1.365214265129025
              ],
              [
                -61.58720097695426,
                -1.361137316966819
              ],
              [
                -61.581642003735865,
                -1.353072829479196
              ],
              [
                -61.584975497147056,
                -1.338353938119369
              ],
              [
                -61.60052042795211,
                -1.320170012642153
              ],
              [
                -61.61109285136248,
                -1.307803084298613
              ],
              [
                -61.628317274461395,
                -1.301135632109117
              ],
              [
                -61.62637077583733,
                -1.293637170408318
              ],
              [
                -61.61284786662045,
                -1.282461243871321
              ],
              [
                -61.60720587707522,
                -1.277799241442638
              ],
              [
                -61.60671138672338,
                -1.257951872536948
              ],
              [
                -61.6064099057449,
                -1.245860942582685
              ],
              [
                -61.60083242910344,
                -1.234469497917557
              ],
              [
                -61.595253463455016,
                -1.22307753928913
              ],
              [
                -61.594353470078346,
                -1.219941582760069
              ],
              [
                -61.58776202822164,
                -1.196966196493648
              ],
              [
                -61.58220256174615,
                -1.189190739758223
              ],
              [
                -61.58079054878184,
                -1.188439238488448
              ],
              [
                -61.573316105322334,
                -1.184460773243029
              ],
              [
                -61.57810857663416,
                -1.172792812138337
              ],
              [
                -61.58164205642066,
                -1.164189386512882
              ],
              [
                -61.57830958849113,
                -1.150580936258823
              ],
              [
                -61.57360409069507,
                -1.145608468858659
              ],
              [
                -61.56858612270513,
                -1.140304530176378
              ],
              [
                -61.56609464105783,
                -1.132251039584603
              ],
              [
                -61.570536625327655,
                -1.120027139418348
              ],
              [
                -61.572388122301554,
                -1.118101125992355
              ],
              [
                -61.577481603719505,
                -1.112802170080439
              ],
              [
                -61.574146096803396,
                -1.104472200838565
              ],
              [
                -61.57233562211709,
                -1.103385734309928
              ],
              [
                -61.565813653272976,
                -1.099472752021331
              ],
              [
                -61.57296610940782,
                -1.092654272553725
              ],
              [
                -61.57776107035326,
                -1.088083781706526
              ],
              [
                -61.576094613207495,
                -1.078917867233372
              ],
              [
                -61.56720317273623,
                -1.074195880889965
              ],
              [
                -61.55783571940226,
                -1.072714895210065
              ],
              [
                -61.54609276944253,
                -1.070858371139513
              ],
              [
                -61.54484127118176,
                -1.066414399624052
              ],
              [
                -61.543588296926444,
                -1.061970442112263
              ],
              [
                -61.54303129540179,
                -1.035030072725117
              ],
              [
                -61.54525430492825,
                -1.028918636693121
              ],
              [
                -61.547477771447326,
                -1.022806667663918
              ],
              [
                -61.55311023086409,
                -1.016789698669119
              ],
              [
                -61.55970071207824,
                -1.009749713277694
              ],
              [
                -61.561306693962905,
                -1.004135244489372
              ],
              [
                -61.562482190019956,
                -1.000026284600283
              ],
              [
                -61.56019722169339,
                -0.98772186291832
              ],
              [
                -61.56964915479694,
                -0.987773333102375
              ],
              [
                -61.57553462586674,
                -0.987805338840094
              ],
              [
                -61.583031069781924,
                -0.982245383659224
              ],
              [
                -61.57914358151073,
                -0.955582532370026
              ],
              [
                -61.584703060175606,
                -0.936690113065561
              ],
              [
                -61.579700598113405,
                -0.930022670944858
              ],
              [
                -61.57803060333195,
                -0.909746298035726
              ],
              [
                -61.568172178664476,
                -0.901276835911694
              ],
              [
                -61.55831374100083,
                -0.892807380821713
              ],
              [
                -61.55792672894806,
                -0.872378466064085
              ],
              [
                -61.55776122404413,
                -0.863641527799071
              ],
              [
                -61.55368326629728,
                -0.860409540549654
              ],
              [
                -61.5430368330092,
                -0.851971605306253
              ],
              [
                -61.54302932421258,
                -0.843087151892618
              ],
              [
                -61.549420777977694,
                -0.838879655269647
              ],
              [
                -61.55248426042934,
                -0.810585348251485
              ],
              [
                -61.538314375366305,
                -0.755865118978219
              ],
              [
                -61.527202917205706,
                -0.750585668011985
              ],
              [
                -61.51609148305962,
                -0.745306707064589
              ],
              [
                -61.4826931824386,
                -0.71718032745297
              ],
              [
                -61.478559232443494,
                -0.709715884156312
              ],
              [
                -61.47442575443968,
                -0.702251936872768
              ],
              [
                -61.47361873880911,
                -0.694421953936377
              ],
              [
                -61.472478765378135,
                -0.683359513380013
              ],
              [
                -61.4641402991193,
                -0.66447111525317
              ],
              [
                -61.4563673495272,
                -0.656696173355874
              ],
              [
                -61.445154912387395,
                -0.657414676332301
              ],
              [
                -61.43469945802126,
                -0.658084168648519
              ],
              [
                -61.42608754191824,
                -0.652805202247094
              ],
              [
                -61.428848505073525,
                -0.646189725223766
              ],
              [
                -61.43025948848499,
                -0.642809240607354
              ],
              [
                -61.42831452528272,
                -0.633914804415991
              ],
              [
                -61.408236131329474,
                -0.631343306318513
              ],
              [
                -61.40664313430109,
                -0.631139306263492
              ],
              [
                -61.39831067480277,
                -0.624194361966022
              ],
              [
                -61.389977226313114,
                -0.617249895689272
              ],
              [
                -61.37520782598265,
                -0.610725409551968
              ],
              [
                -61.36609237263571,
                -0.606698468469692
              ],
              [
                -61.361837418925674,
                -0.6029299594589
              ],
              [
                -61.356367924149865,
                -0.598086475159662
              ],
              [
                -61.347938467856366,
                -0.595194006033925
              ],
              [
                -61.32553611277556,
                -0.59753250828643
              ],
              [
                -61.2977582592391,
                -0.575862610216895
              ],
              [
                -61.28525885889887,
                -0.57364362213645
              ],
              [
                -61.28234284728537,
                -0.574273108459752
              ],
              [
                -61.274975394692426,
                -0.575862625173068
              ],
              [
                -61.230537169543354,
                -0.563643691602465
              ],
              [
                -61.22706267535634,
                -0.561419197391474
              ],
              [
                -61.22358619718167,
                -0.559194194186018
              ],
              [
                -61.223587710433904,
                -0.544327784035757
              ],
              [
                -61.22358821288142,
                -0.538918831457876
              ],
              [
                -61.22227922235965,
                -0.537889837945888
              ],
              [
                -61.21581227730655,
                -0.532807862057547
              ],
              [
                -61.219265236363434,
                -0.525902400920153
              ],
              [
                -61.222756201206344,
                -0.518920423200064
              ],
              [
                -61.22248173082795,
                -0.505309518260762
              ],
              [
                -61.21636474280884,
                -0.499750047050406
              ],
              [
                -61.208304795450246,
                -0.498362529980616
              ],
              [
                -61.20135932832112,
                -0.503246494836878
              ],
              [
                -61.19802985095612,
                -0.505588502817905
              ],
              [
                -61.17303101611864,
                -0.507811504733136
              ],
              [
                -61.15705058495305,
                -0.51499896027632
              ],
              [
                -61.1549171287342,
                -0.505950011454725
              ],
              [
                -61.15470113002759,
                -0.505032990439352
              ],
              [
                -61.14830863567361,
                -0.498921024281305
              ],
              [
                -61.12858627444845,
                -0.497255027845759
              ],
              [
                -61.11914633373919,
                -0.49947203200989
              ],
              [
                -61.117586814448,
                -0.501091011048331
              ],
              [
                -61.11192284507724,
                -0.506970977496619
              ],
              [
                -61.097399438920334,
                -0.499842523296931
              ],
              [
                -61.09608597141373,
                -0.499198021716367
              ],
              [
                -61.09150599435092,
                -0.499478034830775
              ],
              [
                -61.08692549729421,
                -0.499758536942156
              ],
              [
                -61.06331513916864,
                -0.512807942750279
              ],
              [
                -61.054698219994066,
                -0.538360321355255
              ],
              [
                -61.04553574238661,
                -0.545590259061584
              ],
              [
                -61.03331331750825,
                -0.548645239319444
              ],
              [
                -61.03046983579101,
                -0.546991241122594
              ],
              [
                -61.02470038083116,
                -0.543637266978792
              ],
              [
                -61.008296483117896,
                -0.554146203086812
              ],
              [
                -60.999979026920585,
                -0.559475175207608
              ],
              [
                -60.98748060683424,
                -0.560863690520849
              ],
              [
                -60.97498167777465,
                -0.562252161829272
              ],
              [
                -60.95775776292839,
                -0.560026157521659
              ],
              [
                -60.95635378505576,
                -0.55839066736072
              ],
              [
                -60.95275530388629,
                -0.554198717020802
              ],
              [
                -60.94804233240233,
                -0.557730689257465
              ],
              [
                -60.946085839410415,
                -0.559196196055967
              ],
              [
                -60.93792939079516,
                -0.55961966902669
              ],
              [
                -60.93525138702685,
                -0.55975867203208
              ],
              [
                -60.92970391305837,
                -0.554476220516561
              ],
              [
                -60.92025598880679,
                -0.555035695634934
              ],
              [
                -60.91192153702712,
                -0.580866069235767
              ],
              [
                -60.91372803035321,
                -0.585170522796596
              ],
              [
                -60.91553350068294,
                -0.589473011372575
              ],
              [
                -60.91581150621094,
                -0.610031869714894
              ],
              [
                -60.91089755185916,
                -0.613666876328461
              ],
              [
                -60.90830954842126,
                -0.615580863592439
              ],
              [
                -60.90804753355592,
                -0.619365338827465
              ],
              [
                -60.907752044831845,
                -0.62364532234529
              ],
              [
                -60.88220269896624,
                -0.648918149322817
              ],
              [
                -60.86053282522079,
                -0.641971689240805
              ],
              [
                -60.85414384711811,
                -0.647528189123971
              ],
              [
                -60.84887091056556,
                -0.653919624532441
              ],
              [
                -60.846924418692126,
                -0.663639061021786
              ],
              [
                -60.8489208778045,
                -0.668504549544234
              ],
              [
                -60.85136886922406,
                -0.674470533085527
              ],
              [
                -60.842757922705026,
                -0.690306433352304
              ],
              [
                -60.81163909322044,
                -0.691695423401487
              ],
              [
                -60.804419150989915,
                -0.696137902247468
              ],
              [
                -60.79719919576459,
                -0.700580371085778
              ],
              [
                -60.793973710024765,
                -0.720549753148289
              ],
              [
                -60.79275620614731,
                -0.728085201669419
              ],
              [
                -60.799146679792464,
                -0.735585155255672
              ],
              [
                -60.79664120610233,
                -0.744470631241024
              ],
              [
                -60.78830575773532,
                -0.744474616257792
              ],
              [
                -60.78259427005401,
                -0.747334097912977
              ],
              [
                -60.780532779720026,
                -0.748365591015818
              ],
              [
                -60.77664431508712,
                -0.755861542442923
              ],
              [
                -60.76831435890734,
                -0.754195052611606
              ],
              [
                -60.760537899575574,
                -0.761140028590675
              ],
              [
                -60.76608934886605,
                -0.777801932843751
              ],
              [
                -60.77149183556983,
                -0.781186913932283
              ],
              [
                -60.77275083627686,
                -0.781975882758467
              ],
              [
                -60.77331434298697,
                -0.790856866135243
              ],
              [
                -60.766367854490625,
                -0.792650328450516
              ],
              [
                -60.75608891695828,
                -0.795304310632995
              ],
              [
                -60.7585149093911,
                -0.80908674635944
              ],
              [
                -60.75858243395719,
                -0.809471758257109
              ],
              [
                -60.757191933615424,
                -0.811855225015416
              ],
              [
                -60.752750943070296,
                -0.81946967070976
              ],
              [
                -60.75621492262188,
                -0.824504165553092
              ],
              [
                -60.75886739896248,
                -0.828359131764533
              ],
              [
                -60.75581994528794,
                -0.83704907175008
              ],
              [
                -60.75409943150042,
                -0.84195507164303
              ],
              [
                -60.75139394098019,
                -0.849672032004761
              ],
              [
                -60.75157044964949,
                -0.852138513514223
              ],
              [
                -60.75219393494099,
                -0.860856943834638
              ],
              [
                -60.7341860563115,
                -0.858392466941524
              ],
              [
                -60.73192055431957,
                -0.85808246333964
              ],
              [
                -60.72913559798211,
                -0.860209445310533
              ],
              [
                -60.71664214725136,
                -0.86975041033546
              ],
              [
                -60.71087317465009,
                -0.867032421462563
              ],
              [
                -60.707201197910614,
                -0.865301941460048
              ],
              [
                -60.67525939391397,
                -0.873082882475313
              ],
              [
                -60.669416938742174,
                -0.878077334271716
              ],
              [
                -60.67069392883809,
                -0.882408804672881
              ],
              [
                -60.67220241249815,
                -0.887522791815704
              ],
              [
                -60.66748144865948,
                -0.894188243590152
              ],
              [
                -60.654145013689,
                -0.882803296248021
              ],
              [
                -60.63553413640086,
                -0.875856369872168
              ],
              [
                -60.63448014094162,
                -0.872169886984956
              ],
              [
                -60.63331113419412,
                -0.868079383305304
              ],
              [
                -60.627959683686285,
                -0.869761392342423
              ],
              [
                -60.6235876936005,
                -0.871135873199441
              ],
              [
                -60.62219372701611,
                -0.868023901118021
              ],
              [
                -60.61997972937972,
                -0.863082925984994
              ],
              [
                -60.60914628647608,
                -0.863918916941812
              ],
              [
                -60.60675379405012,
                -0.865178890692554
              ],
              [
                -60.593868369167794,
                -0.871967383622893
              ],
              [
                -60.59233839920791,
                -0.862523404375645
              ],
              [
                -60.59080839823477,
                -0.853080481136918
              ],
              [
                -60.557450091117275,
                -0.859742938039885
              ],
              [
                -60.55619759720438,
                -0.868210383236923
              ],
              [
                -60.555832613976406,
                -0.870676387607162
              ],
              [
                -60.54971666234463,
                -0.869230368749297
              ],
              [
                -60.547201156893315,
                -0.868635394688929
              ],
              [
                -60.546150154805616,
                -0.869643386995161
              ],
              [
                -60.54053517439259,
                -0.875026342584721
              ],
              [
                -60.53053524097175,
                -0.874747836767868
              ],
              [
                -60.50414089931906,
                -0.848911976713681
              ],
              [
                -60.502752434317394,
                -0.841133516453851
              ],
              [
                -60.50798940547136,
                -0.838691565608377
              ],
              [
                -60.519423808316986,
                -0.833360584497612
              ],
              [
                -60.506089914630856,
                -0.821135661257419
              ],
              [
                -60.50248791832606,
                -0.82152316166139
              ],
              [
                -60.49831245731844,
                -0.821973142647259
              ],
              [
                -60.493307484393796,
                -0.811971699246247
              ],
              [
                -60.49525396917287,
                -0.803919237966533
              ],
              [
                -60.492275475085194,
                -0.797737259749472
              ],
              [
                -60.491640501903106,
                -0.796420793944785
              ],
              [
                -60.497477440153425,
                -0.789193310591656
              ],
              [
                -60.49358647339852,
                -0.782244882514587
              ],
              [
                -60.47859007718248,
                -0.770587423161583
              ],
              [
                -60.466639130293224,
                -0.768637930524536
              ],
              [
                -60.462301660005494,
                -0.771136929184083
              ],
              [
                -60.457475210506026,
                -0.773917426225143
              ],
              [
                -60.43413982329701,
                -0.772253926648472
              ],
              [
                -60.42663938288829,
                -0.778079900493583
              ],
              [
                -60.42108641955871,
                -0.772804420044311
              ],
              [
                -60.420049442976406,
                -0.769120435326181
              ],
              [
                -60.418584937036556,
                -0.763919474964064
              ],
              [
                -60.40052954304967,
                -0.757251497797917
              ],
              [
                -60.39794055096379,
                -0.757574487704954
              ],
              [
                -60.38275514146082,
                -0.759469990418314
              ],
              [
                -60.37927116675324,
                -0.74934453517688
              ],
              [
                -60.37692465808621,
                -0.742525093742449
              ],
              [
                -60.35302733824883,
                -0.742246589553271
              ],
              [
                -60.3509958358931,
                -0.743151083298458
              ],
              [
                -60.345530379222836,
                -0.745585057155673
              ],
              [
                -60.34082688620703,
                -0.738665598048226
              ],
              [
                -60.3408093933111,
                -0.738639611190569
              ],
              [
                -60.31664203292748,
                -0.72253117699174
              ],
              [
                -60.31535253735387,
                -0.722865679988231
              ],
              [
                -60.30914208012431,
                -0.724475184345713
              ],
              [
                -60.303100628576885,
                -0.710940234963957
              ],
              [
                -60.30164261492478,
                -0.693085870159552
              ],
              [
                -60.30759859478317,
                -0.689855383773182
              ],
              [
                -60.30830408773935,
                -0.6894723679797
              ],
              [
                -60.3073681054681,
                -0.686505911389826
              ],
              [
                -60.30330811730318,
                -0.673640484581067
              ],
              [
                -60.31245305755498,
                -0.661921032770496
              ],
              [
                -60.31414255099378,
                -0.659756056995952
              ],
              [
                -60.31399206111748,
                -0.657233549025029
              ],
              [
                -60.313032083263174,
                -0.641139169553978
              ],
              [
                -60.3194215307157,
                -0.636698198916566
              ],
              [
                -60.32218101363261,
                -0.637878163616447
              ],
              [
                -60.327198492391744,
                -0.640023662164084
              ],
              [
                -60.33735793072431,
                -0.62856725294013
              ],
              [
                -60.34025342028804,
                -0.625302764394129
              ],
              [
                -60.345393897716626,
                -0.623637759157784
              ],
              [
                -60.350533863151696,
                -0.621973253916356
              ],
              [
                -60.35432082912397,
                -0.612730819721905
              ],
              [
                -60.357475808597265,
                -0.605030360883248
              ],
              [
                -60.36469826160901,
                -0.596698394933509
              ],
              [
                -60.36525277420527,
                -0.587808964471619
              ],
              [
                -60.37373071812444,
                -0.588747958013394
              ],
              [
                -60.38275716288008,
                -0.589747469270914
              ],
              [
                -60.38748312634563,
                -0.581141515586621
              ],
              [
                -60.379553167563714,
                -0.576541540480435
              ],
              [
                -60.37887020154451,
                -0.576145553624051
              ],
              [
                -60.38191915755907,
                -0.557249163094495
              ],
              [
                -60.39004611984069,
                -0.554541645865902
              ],
              [
                -60.39442059769746,
                -0.5530841843532
              ],
              [
                -60.39747557668162,
                -0.545584204363756
              ],
              [
                -60.39942305731398,
                -0.509758902029399
              ],
              [
                -60.39337608560123,
                -0.505031942892089
              ],
              [
                -60.3905336201893,
                -0.502809960053143
              ],
              [
                -60.39055611665961,
                -0.494907990094581
              ],
              [
                -60.39055863472148,
                -0.493892502754821
              ],
              [
                -60.38883963589065,
                -0.490691037469607
              ],
              [
                -60.38250468635692,
                -0.478893610772999
              ],
              [
                -60.382227677279985,
                -0.474447611547676
              ],
              [
                -60.381950683199946,
                -0.470001133327567
              ],
              [
                -60.37478170502233,
                -0.464362683265713
              ],
              [
                -60.344167911650494,
                -0.440284815560603
              ],
              [
                -60.34139142338801,
                -0.430012366772294
              ],
              [
                -60.34421438351971,
                -0.422962430356478
              ],
              [
                -60.34528290351185,
                -0.420292914345087
              ],
              [
                -60.343891410034615,
                -0.412787988203134
              ],
              [
                -60.349449356258546,
                -0.406959508133825
              ],
              [
                -60.34275089533549,
                -0.401410051740333
              ],
              [
                -60.33553444005507,
                -0.385019148972227
              ],
              [
                -60.32958249768021,
                -0.381688166256712
              ],
              [
                -60.321640017891,
                -0.377244186662003
              ],
              [
                -60.32164254238063,
                -0.36835574441614
              ],
              [
                -60.316640073881864,
                -0.358361289095239
              ],
              [
                -60.31878207003302,
                -0.34818583019787
              ],
              [
                -60.321083060288096,
                -0.337253427553369
              ],
              [
                -60.318032565293485,
                -0.330306932312026
              ],
              [
                -60.312352109212206,
                -0.329440955875606
              ],
              [
                -60.30164464727593,
                -0.3278089704802
              ],
              [
                -60.302248659632866,
                -0.310219575124785
              ],
              [
                -60.302474640631715,
                -0.30364459300634
              ],
              [
                -60.29594620367854,
                -0.29822762608683
              ],
              [
                -60.28941721773052,
                -0.292810675181258
              ],
              [
                -60.29965066630578,
                -0.273485262072949
              ],
              [
                -60.30442115505628,
                -0.264476332828076
              ],
              [
                -60.301643656307796,
                -0.260172364871802
              ],
              [
                -60.298866175557556,
                -0.25586788292415
              ],
              [
                -60.29108322461883,
                -0.228920024970005
              ],
              [
                -60.29207571894872,
                -0.226687024549825
              ],
              [
                -60.29886369815297,
                -0.211413643590244
              ],
              [
                -60.293032724109956,
                -0.205303666737807
              ],
              [
                -60.28525278200818,
                -0.210305151377622
              ],
              [
                -60.280413784164416,
                -0.206293167775653
              ],
              [
                -60.275532816566034,
                -0.202245706379585
              ],
              [
                -60.263866884551284,
                -0.189188777471422
              ],
              [
                -60.26438738363386,
                -0.185721785993886
              ],
              [
                -60.26553487619584,
                -0.178075340053266
              ],
              [
                -60.257753942424216,
                -0.173077845970247
              ],
              [
                -60.2553499243468,
                -0.173528354370038
              ],
              [
                -60.24887097287314,
                -0.174743365355959
              ],
              [
                -60.24942398687632,
                -0.16669088671187
              ],
              [
                -60.23219708125989,
                -0.146143516052233
              ],
              [
                -60.22386715165858,
                -0.142530038235822
              ],
              [
                -60.21997715438525,
                -0.135032576426443
              ],
              [
                -60.22692011024015,
                -0.128369100142041
              ],
              [
                -60.226510609648095,
                -0.126696120567748
              ],
              [
                -60.22467864741586,
                -0.119196672826547
              ],
              [
                -60.22997907682911,
                -0.110037723578238
              ],
              [
                -60.22830510568647,
                -0.10085879432851
              ],
              [
                -60.22168213636089,
                -0.082735380505712
              ],
              [
                -60.21370318378374,
                -0.071972950172289
              ],
              [
                -60.20572423719874,
                -0.061209994880805
              ],
              [
                -60.20274975066545,
                -0.033267661762526
              ],
              [
                -60.18113739622904,
                -0.009601790538951
              ],
              [
                -60.181568874725464,
                -0.007907830125237
              ],
              [
                -60.183578879057286,
                -0.000023361745137
              ],
              [
                -60.17467441977563,
                0.000347150157526
              ],
              [
                -60.17047946114475,
                0.000521153172174
              ],
              [
                -60.160197523865335,
                0.016602522088735
              ],
              [
                -60.138037148634915,
                0.029094988214698
              ],
              [
                -60.14269760149537,
                0.037737908236745
              ],
              [
                -60.14123361997486,
                0.042982908487983
              ],
              [
                -60.13990163068655,
                0.047753363426071
              ],
              [
                -60.137595648074736,
                0.050389846455762
              ],
              [
                -60.134116168275646,
                0.054368321860437
              ],
              [
                -60.13554764787545,
                0.062990282959397
              ],
              [
                -60.136017170117796,
                0.065816270930348
              ],
              [
                -60.13486465680563,
                0.067192759108491
              ],
              [
                -60.1229947506798,
                0.081373666485205
              ],
              [
                -60.12744822168408,
                0.088587134588886
              ],
              [
                -60.131054194684864,
                0.090634610014287
              ],
              [
                -60.135748140351915,
                0.093299588951556
              ],
              [
                -60.137417661499995,
                0.103016530811901
              ],
              [
                -60.11992024590507,
                0.123854898143764
              ],
              [
                -60.11784227890078,
                0.128703371549854
              ],
              [
                -60.11784077090942,
                0.128708378521338
              ],
              [
                -60.11576078591383,
                0.133560838899038
              ],
              [
                -60.09856787384823,
                0.139078821164498
              ],
              [
                -60.086322461049825,
                0.143009295540162
              ],
              [
                -60.072720521098766,
                0.150233749117977
              ],
              [
                -60.05855062033519,
                0.163318667265663
              ],
              [
                -60.04630218964332,
                0.232453779761894
              ],
              [
                -60.03743425115737,
                0.263836604292945
              ],
              [
                -59.43302887654151,
                0.263858070763602
              ],
              [
                -59.19175386270916,
                0.263858069069289
              ],
              [
                -58.89488970613707,
                0.263852054215423
              ],
              [
                -58.894529714479006,
                -0.010635306601473
              ],
              [
                -58.89513119386614,
                -0.011911304131641
              ],
              [
                -58.89705669329918,
                -0.01599727721476
              ],
              [
                -58.89780867478131,
                -0.017591257885676
              ],
              [
                -58.89591669913039,
                -0.020265241227434
              ],
              [
                -58.893505686592185,
                -0.023672738272743
              ],
              [
                -58.88619975540951,
                -0.033997640801518
              ],
              [
                -58.886077748998524,
                -0.055328042962601
              ],
              [
                -58.886057762088235,
                -0.058860989290213
              ],
              [
                -58.885036745210684,
                -0.059763491000451
              ],
              [
                -58.880041297166066,
                -0.06417848812023
              ],
              [
                -58.87156184309196,
                -0.064168983093446
              ],
              [
                -58.86336241120205,
                -0.077706396776641
              ],
              [
                -58.866285396678535,
                -0.137464553298785
              ],
              [
                -58.86628986164979,
                -0.137559031746585
              ],
              [
                -58.866238860954155,
                -0.137655044184297
              ],
              [
                -58.86262639551259,
                -0.144445473414154
              ],
              [
                -58.86257690244061,
                -0.160611890928985
              ],
              [
                -58.880513772751335,
                -0.196255166246509
              ],
              [
                -58.878791279052585,
                -0.229011473046407
              ],
              [
                -58.87124333222151,
                -0.260760313589653
              ],
              [
                -58.87008184616631,
                -0.261574299796966
              ],
              [
                -58.86487336231033,
                -0.265224273303678
              ],
              [
                -58.86501939708854,
                -0.273766729495162
              ],
              [
                -58.87017936782372,
                -0.280243182952734
              ],
              [
                -58.874461808444245,
                -0.295507072177141
              ],
              [
                -58.87154484471297,
                -0.342665325349289
              ],
              [
                -58.846063995645515,
                -0.364740673435785
              ],
              [
                -58.83561657122071,
                -0.368860145861801
              ],
              [
                -58.82348511389999,
                -0.37364412430999
              ],
              [
                -58.822292152013446,
                -0.374732104903432
              ],
              [
                -58.81729616580432,
                -0.379285574087367
              ],
              [
                -58.80722223633921,
                -0.380422076885327
              ],
              [
                -58.801609748071584,
                -0.381055071872536
              ],
              [
                -58.80049077569562,
                -0.382963551683628
              ],
              [
                -58.797549294106865,
                -0.387980549269148
              ],
              [
                -58.79261332492691,
                -0.385990555580113
              ],
              [
                -58.79031932378686,
                -0.385065555838942
              ],
              [
                -58.78161387893715,
                -0.389412012986645
              ],
              [
                -58.77278693545748,
                -0.400001457711861
              ],
              [
                -58.765524468665426,
                -0.408714397460473
              ],
              [
                -58.7545135597302,
                -0.412914369282413
              ],
              [
                -58.74350311880776,
                -0.417114356092332
              ],
              [
                -58.7380926386818,
                -0.428686297241478
              ],
              [
                -58.736497144078136,
                -0.432098753232067
              ],
              [
                -58.73317469601835,
                -0.433409260370038
              ],
              [
                -58.72924620659758,
                -0.434959250069944
              ],
              [
                -58.72469674556921,
                -0.441894696307672
              ],
              [
                -58.73485969331064,
                -0.471191026167881
              ],
              [
                -58.72239524322879,
                -0.501743357179737
              ],
              [
                -58.71874477101059,
                -0.504780345203053
              ],
              [
                -58.716203271175765,
                -0.506894327688455
              ],
              [
                -58.71022881311131,
                -0.521141739194171
              ],
              [
                -58.709636807330575,
                -0.538230642578059
              ],
              [
                -58.71002680163462,
                -0.542494580770897
              ],
              [
                -58.7104163269388,
                -0.546758067968862
              ],
              [
                -58.71803726726286,
                -0.555498508703629
              ],
              [
                -58.72104976381155,
                -0.558952506848066
              ],
              [
                -58.72866270467706,
                -0.561796501938434
              ],
              [
                -58.73152668812531,
                -0.576944888034537
              ],
              [
                -58.72814322200178,
                -0.58294086485317
              ],
              [
                -58.7276142201091,
                -0.583878875349243
              ],
              [
                -58.72666520772862,
                -0.60751072180429
              ],
              [
                -58.73010668380853,
                -0.609425198988011
              ],
              [
                -58.733478185314986,
                -0.611300698394268
              ],
              [
                -58.731479667907415,
                -0.627030080767066
              ],
              [
                -58.71815677526483,
                -0.637248032096028
              ],
              [
                -58.71811425951805,
                -0.637281040900027
              ],
              [
                -58.718100253595985,
                -0.63734954750045
              ],
              [
                -58.71643477685471,
                -0.6455389877321
              ],
              [
                -58.71275127794239,
                -0.646837470824078
              ],
              [
                -58.70909882884569,
                -0.648124983981622
              ],
              [
                -58.70680782876201,
                -0.657379915953284
              ],
              [
                -58.70341984884169,
                -0.671059842097948
              ],
              [
                -58.70465884860413,
                -0.678644285147071
              ],
              [
                -58.68774596398479,
                -0.685112768809441
              ],
              [
                -58.68308047195757,
                -0.686897248952366
              ],
              [
                -58.678209502825,
                -0.692064215406832
              ],
              [
                -58.665997563196655,
                -0.705017634809656
              ],
              [
                -58.6635165843565,
                -0.712774061449988
              ],
              [
                -58.65618613930597,
                -0.715748556365524
              ],
              [
                -58.64883518793692,
                -0.71356558326587
              ],
              [
                -58.64859966935082,
                -0.713647580763655
              ],
              [
                -58.634371776781634,
                -0.718576552555475
              ],
              [
                -58.61956486247633,
                -0.744191368918748
              ],
              [
                -58.61324840543974,
                -0.755119313640393
              ],
              [
                -58.60204796330609,
                -0.762704764233317
              ],
              [
                -58.59978448382034,
                -0.764237281057153
              ],
              [
                -58.59211452513723,
                -0.764884759404834
              ],
              [
                -58.5905330498214,
                -0.772585205417962
              ],
              [
                -58.590363522752156,
                -0.77340819961002
              ],
              [
                -58.57808910685671,
                -0.78338465412197
              ],
              [
                -58.577879122120684,
                -0.783442151762571
              ],
              [
                -58.57088266423724,
                -0.785352614817108
              ],
              [
                -58.56741669038953,
                -0.792373609546368
              ],
              [
                -58.56080870390042,
                -0.796500070739927
              ],
              [
                -58.56077370706589,
                -0.796891053460291
              ],
              [
                -58.5601147211729,
                -0.804289017328576
              ],
              [
                -58.520134475489215,
                -0.827624891573191
              ],
              [
                -58.518806983561696,
                -0.83547534169951
              ],
              [
                -58.51173050495376,
                -0.839447792677304
              ],
              [
                -58.507858032231695,
                -0.832977834864747
              ],
              [
                -58.50630405157156,
                -0.833622818913184
              ],
              [
                -58.500479097583955,
                -0.836039808102661
              ],
              [
                -58.49145362787768,
                -0.83146035864589
              ],
              [
                -58.49316761480991,
                -0.844879772686242
              ],
              [
                -58.4932831379953,
                -0.845786247421004
              ],
              [
                -58.48702815520055,
                -0.859396168348069
              ],
              [
                -58.47953670365836,
                -0.859245174257102
              ],
              [
                -58.47566274312948,
                -0.86725662008577
              ],
              [
                -58.467683291827186,
                -0.872777111881308
              ],
              [
                -58.46728231266583,
                -0.869648101057381
              ],
              [
                -58.46654930485154,
                -0.863926657296444
              ],
              [
                -58.45852636257266,
                -0.86366264979048
              ],
              [
                -58.43553247663428,
                -0.883137041256667
              ],
              [
                -58.433371018637835,
                -0.891640974398868
              ],
              [
                -58.44256995438356,
                -0.894624455249647
              ],
              [
                -58.45255839731399,
                -0.897863431728335
              ],
              [
                -58.44803289960649,
                -0.906647373947417
              ],
              [
                -58.450309380182986,
                -0.925751797006217
              ],
              [
                -58.4294985209485,
                -0.951522127015997
              ],
              [
                -58.42889251723764,
                -0.954284613844406
              ],
              [
                -58.42708802502453,
                -0.962515572662366
              ],
              [
                -58.42128858098053,
                -0.964415052761703
              ],
              [
                -58.41424413244681,
                -0.966722021304092
              ],
              [
                -58.41467059171641,
                -0.974615472404528
              ],
              [
                -58.41022863478184,
                -0.980974963723401
              ],
              [
                -58.422557564439416,
                -0.99077487451056
              ],
              [
                -58.4229730708667,
                -0.99110488665218
              ],
              [
                -58.424530560104245,
                -0.999895321737188
              ],
              [
                -58.424015536095624,
                -1.000817328295293
              ],
              [
                -58.41897606136101,
                -1.009838790046
              ],
              [
                -58.42316403790899,
                -1.01654322168466
              ],
              [
                -58.42956301148416,
                -1.026785663105687
              ],
              [
                -58.41116911459253,
                -1.043908587671037
              ],
              [
                -58.40582013392618,
                -1.038557612953102
              ],
              [
                -58.399344188492016,
                -1.043683583120886
              ],
              [
                -58.398926711067396,
                -1.043440054471929
              ],
              [
                -58.39217625471366,
                -1.039491098387699
              ],
              [
                -58.388166747443854,
                -1.050089546092085
              ],
              [
                -58.36797289979892,
                -1.06569145609357
              ],
              [
                -58.36230741980806,
                -1.093468256561054
              ],
              [
                -58.35738393341221,
                -1.095630253172796
              ],
              [
                -58.355226947382754,
                -1.096577754304621
              ],
              [
                -58.356426958793755,
                -1.09833772226392
              ],
              [
                -58.36080993107316,
                -1.104767705588682
              ],
              [
                -58.35167648347063,
                -1.111920654460731
              ],
              [
                -58.3480000101364,
                -1.110155187115814
              ],
              [
                -58.343896012439615,
                -1.108184681894954
              ],
              [
                -58.33604257382395,
                -1.122797585557904
              ],
              [
                -58.335748570522725,
                -1.123343595331642
              ],
              [
                -58.335163585020254,
                -1.123726078007508
              ],
              [
                -58.32018817756532,
                -1.133523031461418
              ],
              [
                -58.3193691934629,
                -1.134059026202706
              ],
              [
                -58.322767658259984,
                -1.142962956978397
              ],
              [
                -58.273308484370226,
                -1.13868049742314
              ],
              [
                -58.26978850260312,
                -1.13411802940294
              ],
              [
                -58.26783001497293,
                -1.131578525866707
              ],
              [
                -58.25425058668121,
                -1.132258504593045
              ],
              [
                -58.24284914216408,
                -1.156269890678438
              ],
              [
                -58.214760349054245,
                -1.1653483268536
              ],
              [
                -58.19408944633855,
                -1.199372609784204
              ],
              [
                -58.18735197293379,
                -1.202568079935885
              ],
              [
                -58.180615534528165,
                -1.205763081085266
              ],
              [
                -58.174018086341874,
                -1.21417102585062
              ],
              [
                -58.162128655279325,
                -1.229324911289297
              ],
              [
                -58.15877615612179,
                -1.227434938694502
              ],
              [
                -58.155423693965076,
                -1.225545458099079
              ],
              [
                -58.12730639238018,
                -1.189688654325481
              ],
              [
                -58.118783910178564,
                -1.186039694125994
              ],
              [
                -58.121109914962524,
                -1.174490262032628
              ],
              [
                -58.1231689021341,
                -1.16426081318358
              ],
              [
                -58.10487852652968,
                -1.16171880085432
              ],
              [
                -58.09377759676939,
                -1.160175828946082
              ],
              [
                -58.0814496744996,
                -1.147175888885289
              ],
              [
                -58.081979678668134,
                -1.144728917299741
              ],
              [
                -58.08372714232772,
                -1.136654480865725
              ],
              [
                -58.081338666718885,
                -1.126150530960809
              ],
              [
                -58.041729415296366,
                -1.103009652945925
              ],
              [
                -58.03203499807168,
                -1.101020678009911
              ],
              [
                -58.017029583336345,
                -1.105818637379759
              ],
              [
                -58.00925461891545,
                -1.118751043202975
              ],
              [
                -58.01525060145957,
                -1.128754487528942
              ],
              [
                -58.00663962350054,
                -1.145394886464258
              ],
              [
                -57.97866529476404,
                -1.154071343745675
              ],
              [
                -57.97150784732488,
                -1.16223779558211
              ],
              [
                -57.970391357291135,
                -1.173192225109553
              ],
              [
                -57.97273936388116,
                -1.178870712209225
              ],
              [
                -57.97847531421988,
                -1.192739605886876
              ],
              [
                -57.97172486052946,
                -1.199246060523978
              ],
              [
                -57.96497387484304,
                -1.205752518152553
              ],
              [
                -57.96383991472619,
                -1.222922925267002
              ],
              [
                -57.96895087547862,
                -1.227611907717952
              ],
              [
                -57.97406182423162,
                -1.232301367175784
              ],
              [
                -57.990172211212084,
                -1.291688521472685
              ],
              [
                -57.99033971113266,
                -1.312717401487193
              ],
              [
                -57.993653672120686,
                -1.316139386126848
              ],
              [
                -57.99747566388744,
                -1.32008586180499
              ],
              [
                -58.00042063189684,
                -1.329841799355004
              ],
              [
                -57.99586966203637,
                -1.343987197764369
              ],
              [
                -57.98355824425705,
                -1.355866638841988
              ],
              [
                -57.98120877458178,
                -1.356384126339878
              ],
              [
                -57.970361331728554,
                -1.358774597159388
              ],
              [
                -57.97545029117925,
                -1.365177582766016
              ],
              [
                -57.97603129246304,
                -1.365908083614959
              ],
              [
                -57.976584269289525,
                -1.374676034483991
              ],
              [
                -57.96283737769532,
                -1.379796486720595
              ],
              [
                -57.9549804173764,
                -1.382723446984681
              ],
              [
                -57.95622292777015,
                -1.387462957535259
              ],
              [
                -57.959835871728096,
                -1.401240354766365
              ],
              [
                -57.95122992472278,
                -1.405535829855156
              ],
              [
                -57.94042250634661,
                -1.39978135222323
              ],
              [
                -57.93704501948265,
                -1.397982879031493
              ],
              [
                -57.920162150357456,
                -1.40253832785073
              ],
              [
                -57.90997818647305,
                -1.409896290665379
              ],
              [
                -57.91521416695901,
                -1.416139242255612
              ],
              [
                -57.91801066405864,
                -1.419473731348262
              ],
              [
                -57.91159967863854,
                -1.428296177390145
              ],
              [
                -57.90351922490255,
                -1.425692684891099
              ],
              [
                -57.89370230272889,
                -1.433198143887266
              ],
              [
                -57.87935087423714,
                -1.422931701895698
              ],
              [
                -57.845804605539634,
                -1.429175156182595
              ],
              [
                -57.84353909677238,
                -1.432615638545214
              ],
              [
                -57.84127412600116,
                -1.436054631915607
              ],
              [
                -57.83900663988328,
                -1.436485632904011
              ],
              [
                -57.823523730693765,
                -1.43942511035369
              ],
              [
                -57.82063426221226,
                -1.450188534662966
              ],
              [
                -57.812166300027236,
                -1.452053033901076
              ],
              [
                -57.810691318131944,
                -1.461313958314429
              ],
              [
                -57.80933882763676,
                -1.469801417291363
              ],
              [
                -57.80148488022438,
                -1.473732378532785
              ],
              [
                -57.80114237829987,
                -1.473903402455259
              ],
              [
                -57.80091785755569,
                -1.474496887928459
              ],
              [
                -57.79678291467777,
                -1.4854183160261
              ],
              [
                -57.79075594078852,
                -1.490305271054288
              ],
              [
                -57.789514948539704,
                -1.489877313285381
              ],
              [
                -57.78335348902646,
                -1.487754319354977
              ],
              [
                -57.78114397957574,
                -1.497227262350691
              ],
              [
                -57.78058950047228,
                -1.499603720301523
              ],
              [
                -57.783174468729776,
                -1.507669693625096
              ],
              [
                -57.77281152951241,
                -1.508465679626494
              ],
              [
                -57.760586134418986,
                -1.502020220653766
              ],
              [
                -57.750005202785886,
                -1.506409193545029
              ],
              [
                -57.74711322325209,
                -1.503686690858782
              ],
              [
                -57.744322222073706,
                -1.501058721643925
              ],
              [
                -57.73783777431046,
                -1.504760703476355
              ],
              [
                -57.72935379965275,
                -1.509603654159908
              ],
              [
                -57.72100236690674,
                -1.50670916026074
              ],
              [
                -57.712650414172906,
                -1.5038146793697
              ],
              [
                -57.712025908975484,
                -1.530894535331847
              ],
              [
                -57.71192441660724,
                -1.535299506469092
              ],
              [
                -57.70829893174198,
                -1.540937473565841
              ],
              [
                -57.70467296587704,
                -1.546575445659975
              ],
              [
                -57.70208347653804,
                -1.561200855281628
              ],
              [
                -57.70265995852336,
                -1.563155339954538
              ],
              [
                -57.70633896389204,
                -1.575619753736371
              ],
              [
                -57.69364355031949,
                -1.575954246789198
              ],
              [
                -57.6781651098507,
                -1.59400266428969
              ],
              [
                -57.66866119505007,
                -1.588067184847004
              ],
              [
                -57.65654377081523,
                -1.580499231389368
              ],
              [
                -57.653231303502054,
                -1.588291175876856
              ],
              [
                -57.65320579164616,
                -1.58835015352472
              ],
              [
                -57.65230977522197,
                -1.588227183034113
              ],
              [
                -57.61094705274419,
                -1.582551702582191
              ],
              [
                -57.609647058649806,
                -1.574594759032187
              ],
              [
                -57.60206959068492,
                -1.569962765480519
              ],
              [
                -57.5896146756225,
                -1.57976521793113
              ],
              [
                -57.59370915164435,
                -1.586600682715815
              ],
              [
                -57.595204158792725,
                -1.589096650399753
              ],
              [
                -57.59287015985364,
                -1.599359600531684
              ],
              [
                -57.58539122304366,
                -1.595878112212093
              ],
              [
                -57.56361335400541,
                -1.604851534263189
              ],
              [
                -57.557426388388414,
                -1.604868545683796
              ],
              [
                -57.55295142115384,
                -1.60488156553722
              ],
              [
                -57.54517446116616,
                -1.610179504512161
              ],
              [
                -57.53872101005069,
                -1.62352194347692
              ],
              [
                -57.52468661149207,
                -1.626432901956073
              ],
              [
                -57.52660056228035,
                -1.640278842970838
              ],
              [
                -57.528274073956666,
                -1.652385743181202
              ],
              [
                -57.51539864157348,
                -1.645559280169159
              ],
              [
                -57.50666470694722,
                -1.640928830276446
              ],
              [
                -57.501142744566344,
                -1.652034256573745
              ],
              [
                -57.498560772254415,
                -1.653496249335557
              ],
              [
                -57.49313077924944,
                -1.656570720905426
              ],
              [
                -57.492251812736484,
                -1.656470223280107
              ],
              [
                -57.48006437482919,
                -1.655074736490184
              ],
              [
                -57.481240872028785,
                -1.661173697878481
              ],
              [
                -57.481804365811946,
                -1.664094658825229
              ],
              [
                -57.474691389699245,
                -1.677664581190139
              ],
              [
                -57.45576000489873,
                -1.671698129596967
              ],
              [
                -57.43815815176895,
                -1.66615165189749
              ],
              [
                -57.434924152598185,
                -1.675504573988669
              ],
              [
                -57.436412649303406,
                -1.679689552705952
              ],
              [
                -57.43942163348478,
                -1.688153041609066
              ],
              [
                -57.42706018559588,
                -1.691462015011632
              ],
              [
                -57.41595128100825,
                -1.678307555739356
              ],
              [
                -57.41142380951764,
                -1.672947109208873
              ],
              [
                -57.402949862736435,
                -1.675191590866506
              ],
              [
                -57.40343833428933,
                -1.680892059379033
              ],
              [
                -57.40367283015452,
                -1.68362155234631
              ],
              [
                -57.3971158980799,
                -1.691344988169442
              ],
              [
                -57.39564789808763,
                -1.691894481560751
              ],
              [
                -57.38747944521464,
                -1.694952952471746
              ],
              [
                -57.38909193162235,
                -1.701153439321446
              ],
              [
                -57.39044841786627,
                -1.706369919913674
              ],
              [
                -57.400533352562654,
                -1.712274375678355
              ],
              [
                -57.39217641397982,
                -1.722738292885511
              ],
              [
                -57.37259303826938,
                -1.713774372721399
              ],
              [
                -57.3515556835999,
                -1.732831716952999
              ],
              [
                -57.34350972432509,
                -1.722785789144576
              ],
              [
                -57.34522471392223,
                -1.717663308805301
              ],
              [
                -57.34655671661635,
                -1.713686835606949
              ],
              [
                -57.327452326314884,
                -1.715565335654099
              ],
              [
                -57.32071188128597,
                -1.71988231845011
              ],
              [
                -57.30499995780545,
                -1.729945239012459
              ],
              [
                -57.30105001184711,
                -1.720531281594831
              ],
              [
                -57.29709950688137,
                -1.711116856200841
              ],
              [
                -57.273888674274765,
                -1.709400349451424
              ],
              [
                -57.273124166143695,
                -1.713015825884433
              ],
              [
                -57.272000173828474,
                -1.718331809174805
              ],
              [
                -57.263971749455244,
                -1.716487304032135
              ],
              [
                -57.26003974378232,
                -1.709494353381596
              ],
              [
                -57.25850776803843,
                -1.706769870105592
              ],
              [
                -57.26170226160185,
                -1.69664240881149
              ],
              [
                -57.25338479275162,
                -1.695961946742767
              ],
              [
                -57.243199883424225,
                -1.70395887085992
              ],
              [
                -57.248054319245064,
                -1.711279824779242
              ],
              [
                -57.248667827178046,
                -1.712204340466443
              ],
              [
                -57.2443678710643,
                -1.720130791487055
              ],
              [
                -57.230234451058145,
                -1.725585238608119
              ],
              [
                -57.22163401237552,
                -1.723497759759461
              ],
              [
                -57.21775051785297,
                -1.718797784584029
              ],
              [
                -57.21517006578147,
                -1.715673809418314
              ],
              [
                -57.203240600691345,
                -1.710612845352017
              ],
              [
                -57.201136649155224,
                -1.721362257163758
              ],
              [
                -57.20069515234914,
                -1.72361724269925
              ],
              [
                -57.193946675630066,
                -1.727394220608221
              ],
              [
                -57.188762721123304,
                -1.723260257763615
              ],
              [
                -57.187049733191245,
                -1.721893748419825
              ],
              [
                -57.184936217929454,
                -1.723846734312052
              ],
              [
                -57.178483274822604,
                -1.729808202348065
              ],
              [
                -57.17526628050213,
                -1.727690731067115
              ],
              [
                -57.16438987342328,
                -1.7205342766921
              ],
              [
                -57.16128686479576,
                -1.728706227468686
              ],
              [
                -57.168191831917284,
                -1.742988612624321
              ],
              [
                -57.17130681876148,
                -1.749432089266239
              ],
              [
                -57.162894369163986,
                -1.754442555405903
              ],
              [
                -57.160729392393705,
                -1.755732044204856
              ],
              [
                -57.16112838724371,
                -1.758261533326431
              ],
              [
                -57.16270238380697,
                -1.768244485616575
              ],
              [
                -57.14868095926222,
                -1.761999993934009
              ],
              [
                -57.12886707896977,
                -1.763828005925642
              ],
              [
                -57.115315644749366,
                -1.791446813673286
              ],
              [
                -57.10585525137656,
                -1.782665876215157
              ],
              [
                -57.097345294813465,
                -1.774767407806616
              ],
              [
                -57.08481284564186,
                -1.781704878359825
              ],
              [
                -57.083952350952266,
                -1.793520290054954
              ],
              [
                -57.07891790362062,
                -1.800850740242946
              ],
              [
                -57.070028461030375,
                -1.802324245132571
              ],
              [
                -57.06339599212895,
                -1.808158711751982
              ],
              [
                -57.059977030668385,
                -1.815433660682495
              ],
              [
                -57.06967544363889,
                -1.827219609405308
              ],
              [
                -57.07306044028926,
                -1.831333063936339
              ],
              [
                -57.067560460159584,
                -1.852331438978551
              ],
              [
                -57.06155848020579,
                -1.858365918567418
              ],
              [
                -57.05555652525193,
                -1.864400867147027
              ],
              [
                -57.0483450816393,
                -1.88230224005968
              ],
              [
                -57.0366986607826,
                -1.911216072475508
              ],
              [
                -57.0314081735298,
                -1.912983571506941
              ],
              [
                -57.02611820427737,
                -1.914751561533222
              ],
              [
                -57.021965734615186,
                -1.913891049456293
              ],
              [
                -56.98784143914549,
                -1.906821599728514
              ],
              [
                -56.98248299781691,
                -1.917686010767998
              ],
              [
                -56.97970151373271,
                -1.923326484521881
              ],
              [
                -56.974082042803744,
                -1.927893973829739
              ],
              [
                -56.96581407693549,
                -1.934615401598057
              ],
              [
                -56.9622781097144,
                -1.946874343867063
              ],
              [
                -56.96061710052849,
                -1.952634280225581
              ],
              [
                -56.95643812979685,
                -1.956263767491686
              ],
              [
                -56.95226118005339,
                -1.959892748758441
              ],
              [
                -56.92865083436463,
                -1.964663708605912
              ],
              [
                -56.912537929943,
                -1.967919217602212
              ],
              [
                -56.90470198317889,
                -1.971902191676288
              ],
              [
                -56.896865006428186,
                -1.975885128743066
              ],
              [
                -56.87579166063168,
                -1.992009556815616
              ],
              [
                -56.858536240587775,
                -2.015587391682268
              ],
              [
                -56.843771840368035,
                -2.020310869227775
              ],
              [
                -56.83332791803414,
                -2.021191867878482
              ],
              [
                -56.79270020080029,
                -2.020657330941756
              ],
              [
                -56.766486364107244,
                -2.008360433328433
              ],
              [
                -56.75693090412167,
                -2.009761920153413
              ],
              [
                -56.747374980152,
                -2.01116437996903
              ],
              [
                -56.73402604651949,
                -2.021675303395984
              ],
              [
                -56.73006057729445,
                -2.026148291569073
              ],
              [
                -56.726094630072055,
                -2.030621253739239
              ],
              [
                -56.72082263204397,
                -2.048788657946799
              ],
              [
                -56.721860647753914,
                -2.057884604095193
              ],
              [
                -56.72289812545519,
                -2.066981057252488
              ],
              [
                -56.73225804082374,
                -2.091399376804406
              ],
              [
                -56.75273589316231,
                -2.111337797585167
              ],
              [
                -56.768922302815625,
                -2.140333127397625
              ],
              [
                -56.76803180357842,
                -2.165412457223081
              ],
              [
                -56.76045434687136,
                -2.173717914501298
              ],
              [
                -56.75108991223172,
                -2.176465388188731
              ],
              [
                -56.743489467289045,
                -2.176546888266209
              ],
              [
                -56.725911559466084,
                -2.176734863510468
              ],
              [
                -56.70726069137441,
                -2.183563318947984
              ],
              [
                -56.705319684543504,
                -2.190227788721032
              ],
              [
                -56.70206072160539,
                -2.201414211201873
              ],
              [
                -56.67856486597452,
                -2.212225124322311
              ],
              [
                -56.66803292103702,
                -2.211237641761644
              ],
              [
                -56.65750000712168,
                -2.210250166204251
              ],
              [
                -56.65610299611763,
                -2.209817148324757
              ],
              [
                -56.6349186625612,
                -2.203259187456025
              ],
              [
                -56.62392471702023,
                -2.204144174623008
              ],
              [
                -56.62091872861664,
                -2.205628182813981
              ],
              [
                -56.61240628628396,
                -2.209830632030996
              ],
              [
                -56.60701034019731,
                -2.20463667420388
              ],
              [
                -56.60363734149232,
                -2.189343750196752
              ],
              [
                -56.601957387576554,
                -2.181726825034838
              ],
              [
                -56.597283908495754,
                -2.174616374907282
              ],
              [
                -56.591687446127764,
                -2.174294869033153
              ],
              [
                -56.58884394523364,
                -2.174131846095354
              ],
              [
                -56.58814444955315,
                -2.17451784257072
              ],
              [
                -56.57990402844318,
                -2.179064820826205
              ],
              [
                -56.56908806861262,
                -2.174418348068974
              ],
              [
                -56.55670615512742,
                -2.162205414975335
              ],
              [
                -56.54852170550433,
                -2.157851921356713
              ],
              [
                -56.54033628289382,
                -2.153499456744435
              ],
              [
                -56.53962126367667,
                -2.152739468055591
              ],
              [
                -56.526558886049564,
                -2.138856043850792
              ],
              [
                -56.51897093357843,
                -2.137965538786277
              ],
              [
                -56.5071239782529,
                -2.143363023805006
              ],
              [
                -56.50189901403242,
                -2.148049996146017
              ],
              [
                -56.47738817612676,
                -2.170037327949341
              ],
              [
                -56.47023374372918,
                -2.16668585770727
              ],
              [
                -56.46813726572106,
                -2.16463837328315
              ],
              [
                -56.460537779440216,
                -2.157215929262493
              ],
              [
                -56.443675420980234,
                -2.153583938656005
              ],
              [
                -56.436308961765874,
                -2.157253890360666
              ],
              [
                -56.429867983567036,
                -2.164406849505122
              ],
              [
                -56.423781539231726,
                -2.171165336116014
              ],
              [
                -56.413377612149574,
                -2.175609780192779
              ],
              [
                -56.39219972545677,
                -2.158867399727448
              ],
              [
                -56.386245796050666,
                -2.150493953022152
              ],
              [
                -56.38153281618013,
                -2.143864968271703
              ],
              [
                -56.36912838440495,
                -2.137170995513866
              ],
              [
                -56.350645515850324,
                -2.148530923568089
              ],
              [
                -56.32545217370144,
                -2.145428948251658
              ],
              [
                -56.320854697947304,
                -2.142607970741784
              ],
              [
                -56.3162572571949,
                -2.139786489239716
              ],
              [
                -56.31041080170479,
                -2.126343554149274
              ],
              [
                -56.307949787171566,
                -2.120684590380191
              ],
              [
                -56.306590310948415,
                -2.120394096703031
              ],
              [
                -56.29930835996559,
                -2.118837618793786
              ],
              [
                -56.29893384302706,
                -2.110125646037603
              ],
              [
                -56.298717870942646,
                -2.105095700205466
              ],
              [
                -56.29695237763642,
                -2.100467735482909
              ],
              [
                -56.28016900728195,
                -2.092731776848315
              ],
              [
                -56.267910060399565,
                -2.090354775392968
              ],
              [
                -56.25565066054071,
                -2.087978298944122
              ],
              [
                -56.22787233993809,
                -2.055882970834838
              ],
              [
                -56.20713896358015,
                -2.059642445925712
              ],
              [
                -56.196565533219186,
                -2.056582952191585
              ],
              [
                -56.19458657342852,
                -2.051427500694552
              ],
              [
                -56.19348057536813,
                -2.048547013739872
              ],
              [
                -56.18620011180938,
                -2.042482543152583
              ],
              [
                -56.17202571172196,
                -2.03884855085502
              ],
              [
                -56.162437270386945,
                -2.041567039579763
              ],
              [
                -56.16224878557956,
                -2.041620531200482
              ],
              [
                -56.146284363683634,
                -2.039131066471544
              ],
              [
                -56.14458287867452,
                -2.038866046565147
              ],
              [
                -56.0981362002957,
                -2.026720596183932
              ],
              [
                -56.097871201276156,
                -2.029182585212289
              ],
              [
                -56.097053202292976,
                -2.036796037917544
              ],
              [
                -56.10191368187646,
                -2.047681497539639
              ],
              [
                -56.141424869686986,
                -2.099873683245466
              ],
              [
                -56.168924192450035,
                -2.145167403649434
              ],
              [
                -56.19144252508018,
                -2.170958754427964
              ],
              [
                -56.21979436272182,
                -2.19550260982966
              ],
              [
                -56.262995061163316,
                -2.220715954314737
              ],
              [
                -56.33113311878269,
                -2.251024293886764
              ],
              [
                -56.36370088024324,
                -2.2589472512188
              ],
              [
                -56.383811758235616,
                -2.270134715972051
              ],
              [
                -56.38874321512089,
                -2.27818316249241
              ],
              [
                -56.412251558786885,
                -2.321375407375841
              ],
              [
                -56.417966518996096,
                -2.354849184565588
              ],
              [
                -56.42155597872415,
                -2.361831167160228
              ],
              [
                -56.42514496644977,
                -2.368813113767853
              ],
              [
                -56.42741844890178,
                -2.371884593244472
              ],
              [
                -56.46509020317358,
                -2.422774812363258
              ],
              [
                -56.463980688252605,
                -2.431252754517755
              ],
              [
                -56.454810763020845,
                -2.435896731605605
              ],
              [
                -56.43962433057961,
                -2.439289199978206
              ],
              [
                -56.43695086440555,
                -2.439886197466692
              ],
              [
                -56.43517588825178,
                -2.441201196002018
              ],
              [
                -56.424925926890175,
                -2.448795140110681
              ],
              [
                -56.417853976827516,
                -2.449211635088669
              ],
              [
                -56.407426045103286,
                -2.449825629683165
              ],
              [
                -56.401654088619495,
                -2.456276585179692
              ],
              [
                -56.43269235516656,
                -2.516019206974783
              ],
              [
                -56.4871169808857,
                -2.632617553559015
              ],
              [
                -56.76737442902434,
                -3.242511987180984
              ],
              [
                -56.77677685793867,
                -3.262701873345802
              ],
              [
                -56.809408113793836,
                -3.33277147354667
              ],
              [
                -56.88672900944428,
                -3.498697003105335
              ],
              [
                -56.9132458454288,
                -3.561948159510044
              ],
              [
                -56.93983364134262,
                -3.621644332333255
              ],
              [
                -56.95594099151714,
                -3.657809608339279
              ],
              [
                -57.0184010375009,
                -3.797953320301577
              ],
              [
                -57.54757147313159,
                -4.921232684713111
              ],
              [
                -57.860868479183814,
                -5.60074160854476
              ],
              [
                -58.1517191277412,
                -6.229921924575374
              ],
              [
                -58.262031250113225,
                -6.468554726396585
              ],
              [
                -58.26703218444147,
                -6.474722206083145
              ],
              [
                -58.2720331887655,
                -6.48089066377876
              ],
              [
                -58.29258604741675,
                -6.493514108998912
              ],
              [
                -58.31947533349171,
                -6.494628627402756
              ],
              [
                -58.322294333122976,
                -6.496776611972229
              ],
              [
                -58.325113326754554,
                -6.498924598544116
              ],
              [
                -58.32706228320339,
                -6.508769562225988
              ],
              [
                -58.33178523291339,
                -6.532630455766413
              ],
              [
                -58.33917066840998,
                -6.54817086825453
              ],
              [
                -58.34936362033627,
                -6.562029810303855
              ],
              [
                -58.370290462035754,
                -6.574361766941538
              ],
              [
                -58.4267825923789,
                -6.615798092231726
              ],
              [
                -58.449012888347056,
                -6.653049410791556
              ],
              [
                -58.456844365173865,
                -6.661361362693524
              ],
              [
                -58.46467477900468,
                -6.669672839625304
              ],
              [
                -58.477948700196954,
                -6.699242171402188
              ],
              [
                -58.481231566173356,
                -6.781051253273709
              ],
              [
                -58.47531208848502,
                -6.792647192556127
              ],
              [
                -58.46939212678598,
                -6.804243120834033
              ],
              [
                -58.462363654202626,
                -6.836907959593417
              ],
              [
                -58.434031742434044,
                -6.908435523632333
              ],
              [
                -58.40648038405977,
                -6.940058338471819
              ],
              [
                -58.40004644639371,
                -6.944893312915368
              ],
              [
                -58.39361248173182,
                -6.949727793355449
              ],
              [
                -58.393003482182465,
                -6.950950273436747
              ],
              [
                -58.38603599220028,
                -6.964945205233235
              ],
              [
                -58.37972954538792,
                -6.970133169973487
              ],
              [
                -58.368074100217356,
                -6.970679145968113
              ],
              [
                -58.355087181162226,
                -6.971287609247928
              ],
              [
                -58.33826379542914,
                -6.983550028559363
              ],
              [
                -58.33230480950938,
                -6.991604002882531
              ],
              [
                -58.326344823592315,
                -6.999657959198647
              ],
              [
                -58.28773401939917,
                -7.06130308244346
              ],
              [
                -58.26805314231369,
                -7.079149454802394
              ],
              [
                -58.258090702897995,
                -7.081618453527162
              ],
              [
                -58.2523132328785,
                -7.083050937023491
              ],
              [
                -58.24536424977857,
                -7.088509890556332
              ],
              [
                -58.22930785555764,
                -7.109084781613135
              ],
              [
                -58.225782365324136,
                -7.116493218739802
              ],
              [
                -58.22225685708429,
                -7.123902169862774
              ],
              [
                -58.21703740398238,
                -7.127834163061423
              ],
              [
                -58.20900046194447,
                -7.133888637324872
              ],
              [
                -58.204074950720965,
                -7.144644574719197
              ],
              [
                -58.19914946948512,
                -7.155399982114651
              ],
              [
                -58.18279908592653,
                -7.177899852826617
              ],
              [
                -58.16756563334839,
                -7.23096057457013
              ],
              [
                -58.174291020258785,
                -7.271053862924469
              ],
              [
                -58.16948550008844,
                -7.312848154202917
              ],
              [
                -58.1464371281144,
                -7.342857448986973
              ],
              [
                -58.139136686541505,
                -7.352451376747789
              ],
              [
                -58.136364688613405,
                -7.356093369015849
              ],
              [
                -58.139579636374435,
                -7.361896361795213
              ],
              [
                -58.140162644616176,
                -7.362948338043146
              ],
              [
                -58.17274739944242,
                -7.393905704422417
              ],
              [
                -58.21248558704221,
                -7.458079419529906
              ],
              [
                -58.21261558405621,
                -7.492936229781529
              ],
              [
                -58.222183484514346,
                -7.523603580173085
              ],
              [
                -58.21641495924927,
                -7.555152908918266
              ],
              [
                -58.201945028400786,
                -7.596367184636434
              ],
              [
                -58.20176649567538,
                -7.608455597904593
              ],
              [
                -58.20158699193224,
                -7.620545048183272
              ],
              [
                -58.23068427283584,
                -7.645696941576629
              ],
              [
                -58.2393242180473,
                -7.653164905546094
              ],
              [
                -58.24610666400908,
                -7.665558349408768
              ],
              [
                -58.25288961294783,
                -7.677952312313609
              ],
              [
                -58.27210845274597,
                -7.697778735924905
              ],
              [
                -58.27749392617488,
                -7.703335193985051
              ],
              [
                -58.2789774066192,
                -7.706291669720299
              ],
              [
                -58.28046090906283,
                -7.709247683460243
              ],
              [
                -58.2812633814624,
                -7.718425105907276
              ],
              [
                -58.28385285704943,
                -7.748024478073782
              ],
              [
                -58.289091786865264,
                -7.759976397698106
              ],
              [
                -58.29433173065498,
                -7.771928357359654
              ],
              [
                -58.333439970690705,
                -7.803945726314305
              ],
              [
                -58.37122521865927,
                -7.820913193044897
              ],
              [
                -58.38242512589698,
                -7.838706594589048
              ],
              [
                -58.382565084139024,
                -7.852134532161912
              ],
              [
                -58.37903662871037,
                -7.85726648817486
              ],
              [
                -58.37419063809424,
                -7.864313973990997
              ],
              [
                -58.372867621618354,
                -7.906827759574258
              ],
              [
                -58.35460767365184,
                -7.94369355054606
              ],
              [
                -58.33089079083354,
                -7.968301894559131
              ],
              [
                -58.327366821081554,
                -7.980279811143699
              ],
              [
                -58.32384181931429,
                -7.992259759721726
              ],
              [
                -58.32461231160412,
                -8.003837193481694
              ],
              [
                -58.32497829972368,
                -8.009324674358538
              ],
              [
                -58.32307477415593,
                -8.016991102569857
              ],
              [
                -58.321171806578334,
                -8.024656077791924
              ],
              [
                -58.31594081336583,
                -8.03322201948549
              ],
              [
                -58.291121438651714,
                -8.073870785456362
              ],
              [
                -58.286076445618804,
                -8.087061204141229
              ],
              [
                -58.28982390662786,
                -8.098465175462582
              ],
              [
                -58.291543903641774,
                -8.10370064228735
              ],
              [
                -58.289174406194036,
                -8.115166079903162
              ],
              [
                -58.28649491479515,
                -8.128132003626108
              ],
              [
                -58.28851888255219,
                -8.132031478542327
              ],
              [
                -58.29054238331006,
                -8.13593097846207
              ],
              [
                -58.29765932248998,
                -8.140830457588917
              ],
              [
                -58.31694967246832,
                -8.154109890638235
              ],
              [
                -58.31950965917057,
                -8.16186335695939
              ],
              [
                -58.3220691398663,
                -8.169617336290628
              ],
              [
                -58.318526616131756,
                -8.183810754940609
              ],
              [
                -58.31229514156999,
                -8.2087750974326
              ],
              [
                -58.31689509005936,
                -8.220228548839092
              ],
              [
                -58.3214950585309,
                -8.23168099728251
              ],
              [
                -58.320683061352966,
                -8.252703918273836
              ],
              [
                -58.31995502031439,
                -8.271546801299023
              ],
              [
                -58.32367301394177,
                -8.278344275986452
              ],
              [
                -58.32751798049356,
                -8.285372260694977
              ],
              [
                -58.32537397114895,
                -8.293783172074907
              ],
              [
                -58.320528992670376,
                -8.299597143049867
              ],
              [
                -58.31521150238658,
                -8.305978620602064
              ],
              [
                -58.31437151858916,
                -8.322669541317833
              ],
              [
                -58.31803296307744,
                -8.32980649230699
              ],
              [
                -58.33174939034816,
                -8.337217481510702
              ],
              [
                -58.34757527922518,
                -8.345767937720739
              ],
              [
                -58.350595746648565,
                -8.353691927059826
              ],
              [
                -58.349361259659,
                -8.36048386071247
              ],
              [
                -58.34783425143181,
                -8.368880318017878
              ],
              [
                -58.35229719215296,
                -8.379016309199837
              ],
              [
                -58.354477182472955,
                -8.383967287317835
              ],
              [
                -58.3537506935849,
                -8.388190760403443
              ],
              [
                -58.35302367169753,
                -8.392414232489596
              ],
              [
                -58.37554298504142,
                -8.428736543444622
              ],
              [
                -58.38226342577431,
                -8.434194022856428
              ],
              [
                -58.38898338051239,
                -8.439650532287592
              ],
              [
                -58.39435533113819,
                -8.454019457832102
              ],
              [
                -58.39801630310921,
                -8.463811889933472
              ],
              [
                -58.401388270165725,
                -8.465550393599154
              ],
              [
                -58.40476027822374,
                -8.467288380269697
              ],
              [
                -58.41665466662704,
                -8.491858787713687
              ],
              [
                -58.41583816207048,
                -8.503185219610987
              ],
              [
                -58.41502164249414,
                -8.514511678520998
              ],
              [
                -58.407384676317264,
                -8.526209110884382
              ],
              [
                -58.404704693482245,
                -8.530314077146636
              ],
              [
                -58.40425768852992,
                -8.534725531750464
              ],
              [
                -58.40381117757417,
                -8.539135044366272
              ],
              [
                -58.41008259408652,
                -8.569981893905373
              ],
              [
                -58.398297662189414,
                -8.579464817881696
              ],
              [
                -58.39802817656368,
                -8.579681815462394
              ],
              [
                -58.39071020770793,
                -8.59186275483852
              ],
              [
                -58.39082217958691,
                -8.602949697199351
              ],
              [
                -58.40059160411657,
                -8.615545627602055
              ],
              [
                -58.40306508686694,
                -8.630896080024213
              ],
              [
                -58.40858354853253,
                -8.63733505339799
              ],
              [
                -58.413848996136636,
                -8.64347851491729
              ],
              [
                -58.43689576728484,
                -8.703034225484004
              ],
              [
                -58.43302929782846,
                -8.710009693103828
              ],
              [
                -58.41052742321334,
                -8.719758118699302
              ],
              [
                -58.380506642019554,
                -8.705190174242459
              ],
              [
                -58.35051132584197,
                -8.701191657826541
              ],
              [
                -58.327811451716684,
                -8.7117240762226
              ],
              [
                -58.32555098123305,
                -8.719611007337473
              ],
              [
                -58.331458404445314,
                -8.72597699064132
              ],
              [
                -58.35407126372369,
                -8.736946973128054
              ],
              [
                -58.394897949663964,
                -8.779818773635007
              ],
              [
                -58.41535531205457,
                -8.792461252440772
              ],
              [
                -58.419965767417025,
                -8.78968528716072
              ],
              [
                -58.42221474639956,
                -8.788331293778556
              ],
              [
                -58.42955772368818,
                -8.789523784602007
              ],
              [
                -58.437091683943315,
                -8.795117260069754
              ],
              [
                -58.442355639392865,
                -8.799025744828615
              ],
              [
                -58.49983324502063,
                -8.802438305019542
              ],
              [
                -59.12673583774949,
                -8.802964062270183
              ],
              [
                -59.70437577240947,
                -8.801280307261461
              ],
              [
                -59.85649987534332,
                -8.80031799294456
              ],
              [
                -60.40786150253395,
                -8.796317719334901
              ],
              [
                -60.419469947849514,
                -8.796233719408018
              ],
              [
                -60.74696050807253,
                -8.797464619164685
              ],
              [
                -60.84834089556066,
                -8.797789754293834
              ],
              [
                -61.18446390138973,
                -8.798193175853939
              ],
              [
                -61.57996957998542,
                -8.798309688735568
              ],
              [
                -61.582423046102214,
                -8.798310180093035
              ],
              [
                -61.583311533796845,
                -8.797788665621596
              ],
              [
                -61.589570503467684,
                -8.794116678467484
              ],
              [
                -61.60549141412354,
                -8.79433370830119
              ],
              [
                -61.62276137784076,
                -8.757876416435312
              ],
              [
                -61.62588985281303,
                -8.755226449444782
              ],
              [
                -61.62901734879155,
                -8.752576455451525
              ],
              [
                -61.62842435951874,
                -8.74794496916878
              ],
              [
                -61.625931883371464,
                -8.728473549101308
              ],
              [
                -61.630018872741665,
                -8.72158108222152
              ],
              [
                -61.63782284652858,
                -8.720946608634819
              ],
              [
                -61.64554378678516,
                -8.720318594906129
              ],
              [
                -61.64806226525551,
                -8.716169652717463
              ],
              [
                -61.649877758105404,
                -8.713179156438356
              ],
              [
                -61.673044143054305,
                -8.70463623672845
              ],
              [
                -61.6969865017435,
                -8.709204240985391
              ],
              [
                -61.70482493736732,
                -8.707148748720728
              ],
              [
                -61.70860943592742,
                -8.697738291499165
              ],
              [
                -61.71273743870786,
                -8.68747433252054
              ],
              [
                -61.725435365290664,
                -8.693221324292828
              ],
              [
                -61.74131623465106,
                -8.694817372761497
              ],
              [
                -61.74390522999527,
                -8.702171814764036
              ],
              [
                -61.74027425099659,
                -8.70833229860858
              ],
              [
                -61.73959473124276,
                -8.709484801592383
              ],
              [
                -61.74385070792082,
                -8.717028246042547
              ],
              [
                -61.74834315888224,
                -8.723391214044849
              ],
              [
                -61.7607960869323,
                -8.727639738231494
              ],
              [
                -61.763075101034936,
                -8.728417215902061
              ],
              [
                -61.76283107753935,
                -8.729776214571963
              ],
              [
                -61.76157707396845,
                -8.736774180981184
              ],
              [
                -61.774069501400305,
                -8.747850650154696
              ],
              [
                -61.78948639489402,
                -8.744782661713682
              ],
              [
                -61.80571079604513,
                -8.747401700312611
              ],
              [
                -61.82757071506574,
                -8.732246811866613
              ],
              [
                -61.83596367777916,
                -8.732345286871867
              ],
              [
                -61.8435725888413,
                -8.74700174175835
              ],
              [
                -61.84316956810164,
                -8.76293666312695
              ],
              [
                -61.84276354811796,
                -8.779030079823364
              ],
              [
                -61.849314517279765,
                -8.784116067453624
              ],
              [
                -61.855151973217055,
                -8.788648550550008
              ],
              [
                -61.860489927004586,
                -8.803234019838497
              ],
              [
                -61.85757441914645,
                -8.813471446896676
              ],
              [
                -61.856325451062354,
                -8.817854925940876
              ],
              [
                -61.859655886123555,
                -8.820900424162794
              ],
              [
                -61.862046384658555,
                -8.823086407864785
              ],
              [
                -61.8632083819967,
                -8.831082381393692
              ],
              [
                -61.86286588513071,
                -8.833083374145907
              ],
              [
                -61.859499865608434,
                -8.852733770340636
              ],
              [
                -61.86913181701029,
                -8.857397760215267
              ],
              [
                -61.87876322242559,
                -8.862061244109237
              ],
              [
                -61.88343571707855,
                -8.862150265184885
              ],
              [
                -61.90246360982309,
                -8.862510769940373
              ],
              [
                -61.904077096677725,
                -8.869008282745892
              ],
              [
                -61.9053490863699,
                -8.874129236107345
              ],
              [
                -61.924123468604805,
                -8.87876373787408
              ],
              [
                -61.937569865060745,
                -8.881033228611207
              ],
              [
                -61.97072169064124,
                -8.874432825418209
              ],
              [
                -61.978604131719926,
                -8.872863350198644
              ],
              [
                -61.980403121425894,
                -8.874450323770578
              ],
              [
                -61.98468662315482,
                -8.878227812237345
              ],
              [
                -61.989687071517146,
                -8.872280350112579
              ],
              [
                -61.99333059391473,
                -8.851730940798392
              ],
              [
                -61.993906108235315,
                -8.848484976757423
              ],
              [
                -61.997819563644896,
                -8.8404005244453
              ],
              [
                -62.001733556043455,
                -8.832316567130052
              ],
              [
                -62.02079798214744,
                -8.823218623141438
              ],
              [
                -62.03052493606108,
                -8.799329233772394
              ],
              [
                -62.040256381084156,
                -8.797610256664324
              ],
              [
                -62.04998833911937,
                -8.7958912675536
              ],
              [
                -62.073767210171376,
                -8.798191782253578
              ],
              [
                -62.08561763484692,
                -8.803365302039072
              ],
              [
                -62.097467561539816,
                -8.808539773844114
              ],
              [
                -62.124437380281826,
                -8.801290849552371
              ],
              [
                -62.15449332895245,
                -8.739262181665044
              ],
              [
                -62.15649679006903,
                -8.731832708738255
              ],
              [
                -62.15287481768354,
                -8.725713730421344
              ],
              [
                -62.14826737207553,
                -8.717930762016953
              ],
              [
                -62.153657864915786,
                -8.710060322650778
              ],
              [
                -62.1616302953577,
                -8.705837844912832
              ],
              [
                -62.167271275080125,
                -8.702850340612398
              ],
              [
                -62.171237287238064,
                -8.694694883526875
              ],
              [
                -62.168809307580986,
                -8.688998908026132
              ],
              [
                -62.16455230698552,
                -8.679015470726393
              ],
              [
                -62.17902175459508,
                -8.659981583314156
              ],
              [
                -62.18090674050931,
                -8.657501057679763
              ],
              [
                -62.19999717196132,
                -8.643915157745841
              ],
              [
                -62.195271710435655,
                -8.63645019287097
              ],
              [
                -62.18584626977663,
                -8.636496667913022
              ],
              [
                -62.176109806860865,
                -8.636543663533116
              ],
              [
                -62.17415635263439,
                -8.627823697921173
              ],
              [
                -62.18785580471469,
                -8.590181883636061
              ],
              [
                -62.19523026373965,
                -8.592374371996994
              ],
              [
                -62.20260472777118,
                -8.594567403361953
              ],
              [
                -62.22807107824873,
                -8.590189938744777
              ],
              [
                -62.230090576948896,
                -8.588781458602297
              ],
              [
                -62.24227450285103,
                -8.580282986032355
              ],
              [
                -62.26835334979924,
                -8.575382055456355
              ],
              [
                -62.27374481215446,
                -8.582554519490898
              ],
              [
                -62.28882123544253,
                -8.588524516822474
              ],
              [
                -62.2927491860211,
                -8.595614507288847
              ],
              [
                -62.29377515046733,
                -8.605060947612154
              ],
              [
                -62.282477214679254,
                -8.62296487653822
              ],
              [
                -62.278800736107684,
                -8.62879133024469
              ],
              [
                -62.28077072030173,
                -8.638211807982072
              ],
              [
                -62.28896816715451,
                -8.638717785891656
              ],
              [
                -62.33524243022673,
                -8.609107487041896
              ],
              [
                -62.34055138991308,
                -8.60245054509757
              ],
              [
                -62.34146591673128,
                -8.594464558986992
              ],
              [
                -62.33579794516134,
                -8.585608595788019
              ],
              [
                -62.327189508104176,
                -8.572158646615495
              ],
              [
                -62.325270547712904,
                -8.563329669401524
              ],
              [
                -62.3306165199877,
                -8.552356223276515
              ],
              [
                -62.33380201120856,
                -8.545816283978361
              ],
              [
                -62.33309402499404,
                -8.534087311023486
              ],
              [
                -62.332689543290314,
                -8.527384356632393
              ],
              [
                -62.33475755735103,
                -8.521823391586674
              ],
              [
                -62.33682555540846,
                -8.51626190654343
              ],
              [
                -62.34217752193284,
                -8.514671407615106
              ],
              [
                -62.35344245611403,
                -8.511324943221647
              ],
              [
                -62.359548928529435,
                -8.505986975587371
              ],
              [
                -62.36551891174919,
                -8.493185042217076
              ],
              [
                -62.36318742325787,
                -8.477849108813263
              ],
              [
                -62.36874896653595,
                -8.445744775920826
              ],
              [
                -62.36471848181494,
                -8.434528822419173
              ],
              [
                -62.360687532076426,
                -8.423313374944025
              ],
              [
                -62.3594360402412,
                -8.406620942045237
              ],
              [
                -62.36027056786996,
                -8.398170496990891
              ],
              [
                -62.36655101703935,
                -8.389221541290953
              ],
              [
                -62.378771959020554,
                -8.380536572250765
              ],
              [
                -62.388713420732984,
                -8.376282132878613
              ],
              [
                -62.39443640542852,
                -8.375293635715888
              ],
              [
                -62.400855347834586,
                -8.376152639417992
              ],
              [
                -62.40771028476176,
                -8.377070123441525
              ],
              [
                -62.43171217446115,
                -8.371653183607993
              ],
              [
                -62.444798115924144,
                -8.362107226359088
              ],
              [
                -62.4466390906175,
                -8.3592032784152
              ],
              [
                -62.45323606301302,
                -8.348796828364197
              ],
              [
                -62.464942012951134,
                -8.339362392772305
              ],
              [
                -62.47243749929846,
                -8.34037639318777
              ],
              [
                -62.4783829230288,
                -8.345296851585816
              ],
              [
                -62.48197040425912,
                -8.351344338001232
              ],
              [
                -62.4864143818543,
                -8.358834818282684
              ],
              [
                -62.4933548280388,
                -8.362292819382052
              ],
              [
                -62.49343035257176,
                -8.362331327314065
              ],
              [
                -62.50029529722749,
                -8.365751311488893
              ],
              [
                -62.500338787926346,
                -8.365797815344168
              ],
              [
                -62.51793916636662,
                -8.384362720952671
              ],
              [
                -62.525603131427246,
                -8.38267674737065
              ],
              [
                -62.52519111101979,
                -8.377918259464689
              ],
              [
                -62.52493562934176,
                -8.3749707789126
              ],
              [
                -62.53083511405525,
                -8.371072818589488
              ],
              [
                -62.53146959712058,
                -8.370653802242291
              ],
              [
                -62.53217361084613,
                -8.369449337393164
              ],
              [
                -62.53902409470243,
                -8.357732874224363
              ],
              [
                -62.54181805030705,
                -8.357753373809048
              ],
              [
                -62.55099199376612,
                -8.357819878583715
              ],
              [
                -62.54881455195285,
                -8.343315940621343
              ],
              [
                -62.548582024282,
                -8.341766476037417
              ],
              [
                -62.550420043536086,
                -8.33771398402893
              ],
              [
                -62.55525250404798,
                -8.327060033585264
              ],
              [
                -62.554928537532454,
                -8.321983580187409
              ],
              [
                -62.55473901649747,
                -8.31901109783831
              ],
              [
                -62.556166539426904,
                -8.317664070552418
              ],
              [
                -62.560629024449405,
                -8.313454126660684
              ],
              [
                -62.5591585081585,
                -8.311462607379648
              ],
              [
                -62.55540754247466,
                -8.306381639529334
              ],
              [
                -62.561386046544186,
                -8.283631240114566
              ],
              [
                -62.56869200509356,
                -8.286903246433138
              ],
              [
                -62.573660490500686,
                -8.280276769678292
              ],
              [
                -62.577311945767875,
                -8.279265776825536
              ],
              [
                -62.58097095699886,
                -8.278252311992443
              ],
              [
                -62.58205194852777,
                -8.276373780554534
              ],
              [
                -62.584854416529275,
                -8.271502326349257
              ],
              [
                -62.58792791296252,
                -8.272787824771779
              ],
              [
                -62.59223936891827,
                -8.274591828558396
              ],
              [
                -62.59952584249104,
                -8.271864835879978
              ],
              [
                -62.60983330923102,
                -8.259422403271943
              ],
              [
                -62.649413598946104,
                -8.236650561433098
              ],
              [
                -62.654470590643534,
                -8.22868010454153
              ],
              [
                -62.652861102179884,
                -8.225835122789123
              ],
              [
                -62.650494601608635,
                -8.221653650853996
              ],
              [
                -62.659356110231364,
                -8.19925624945283
              ],
              [
                -62.6661760483525,
                -8.195807761217864
              ],
              [
                -62.672995537482755,
                -8.192358788979119
              ],
              [
                -62.68613295316878,
                -8.173399406134873
              ],
              [
                -62.68472147034283,
                -8.16534395125528
              ],
              [
                -62.678633007083725,
                -8.160401454866582
              ],
              [
                -62.67719505265071,
                -8.156777460744575
              ],
              [
                -62.67249859268645,
                -8.144937527109798
              ],
              [
                -62.673580079567465,
                -8.136575045811021
              ],
              [
                -62.68416603019446,
                -8.117510152171032
              ],
              [
                -62.68496906166173,
                -8.116063649481179
              ],
              [
                -62.68447204686227,
                -8.11569115046352
              ],
              [
                -62.67830707056472,
                -8.111069693658802
              ],
              [
                -62.69002204699583,
                -8.100526717432158
              ],
              [
                -62.69175003580871,
                -8.092533783366294
              ],
              [
                -62.712298912201575,
                -8.080207851107618
              ],
              [
                -62.743439799138116,
                -8.044910064001789
              ],
              [
                -62.78566808771533,
                -8.025968680744677
              ],
              [
                -62.809466447168255,
                -8.024966715227126
              ],
              [
                -62.814969408071875,
                -8.019544245699665
              ],
              [
                -62.82047238797615,
                -8.014121772166723
              ],
              [
                -62.832302339093644,
                -7.993993394364542
              ],
              [
                -62.84107881650553,
                -7.994598913815834
              ],
              [
                -62.84452431245223,
                -7.986157948743922
              ],
              [
                -62.850209764591,
                -7.986862434859808
              ],
              [
                -62.852829761910435,
                -7.987186943757645
              ],
              [
                -62.857629236145264,
                -7.982954471146043
              ],
              [
                -62.86608718755482,
                -7.975495526116155
              ],
              [
                -63.50000962514407,
                -7.975945754213744
              ],
              [
                -63.552474342979835,
                -7.972880810690214
              ],
              [
                -63.6202674757854,
                -7.968920926363654
              ],
              [
                -63.62592989771248,
                -8.015739231424762
              ],
              [
                -63.625868407811225,
                -8.015917229597404
              ],
              [
                -63.621259403231896,
                -8.029235174683114
              ],
              [
                -63.62609736957879,
                -8.040809591191904
              ],
              [
                -63.638237297222645,
                -8.046818611476015
              ],
              [
                -63.65035217791866,
                -8.066065042180199
              ],
              [
                -63.66648010252445,
                -8.0812259703004
              ],
              [
                -63.66843156579376,
                -8.091528431618785
              ],
              [
                -63.666530577387455,
                -8.094237413814023
              ],
              [
                -63.66637008394437,
                -8.094465924649375
              ],
              [
                -63.66192956734442,
                -8.100793887399789
              ],
              [
                -63.6693785279813,
                -8.121366808819245
              ],
              [
                -63.672642479640785,
                -8.124427307223765
              ],
              [
                -63.67590596530263,
                -8.12748778163222
              ],
              [
                -63.69290136616071,
                -8.157973180140345
              ],
              [
                -63.72952860210667,
                -8.179734144921055
              ],
              [
                -63.730553095907304,
                -8.181075640660005
              ],
              [
                -63.73157709771115,
                -8.182416617401113
              ],
              [
                -63.74651548167743,
                -8.198206581065085
              ],
              [
                -63.74804550333664,
                -8.207490035388801
              ],
              [
                -63.7425939826713,
                -8.216285462621077
              ],
              [
                -63.722242079325454,
                -8.249121307174791
              ],
              [
                -63.74604342656241,
                -8.26979274446507
              ],
              [
                -63.752794349831696,
                -8.2802597218517
              ],
              [
                -63.75279736680839,
                -8.28026519683289
              ],
              [
                -63.75954532108666,
                -8.290727682270258
              ],
              [
                -63.76055080380112,
                -8.292944167392433
              ],
              [
                -63.77298972125362,
                -8.320355077082102
              ],
              [
                -63.78107765898598,
                -8.328864536522772
              ],
              [
                -63.79071356958301,
                -8.332838517844124
              ],
              [
                -63.824819428076154,
                -8.305774698186376
              ],
              [
                -63.83569786589406,
                -8.300223216575848
              ],
              [
                -63.83571338382255,
                -8.300215244629399
              ],
              [
                -63.84660733058391,
                -8.2946562480566
              ],
              [
                -63.87024471184113,
                -8.288442825023864
              ],
              [
                -63.8814876491942,
                -8.290852821923576
              ],
              [
                -63.894410554494264,
                -8.30165327239142
              ],
              [
                -63.90244050524522,
                -8.318732742509695
              ],
              [
                -63.90382747197031,
                -8.321683238172776
              ],
              [
                -63.90707446949248,
                -8.324915218147268
              ],
              [
                -63.91032142901379,
                -8.32814770912424
              ],
              [
                -63.9153603895786,
                -8.327270195439697
              ],
              [
                -63.93153334297029,
                -8.324453224543467
              ],
              [
                -63.94360827173227,
                -8.330810742392043
              ],
              [
                -63.943788245724356,
                -8.347249145925218
              ],
              [
                -63.944859679937686,
                -8.387448978920903
              ],
              [
                -63.976439475755285,
                -8.40935892822847
              ],
              [
                -63.97690996123439,
                -8.414556908523231
              ],
              [
                -63.97737993871223,
                -8.419754407823246
              ],
              [
                -63.96391652278731,
                -8.424117864811608
              ],
              [
                -63.953212567543794,
                -8.427587313169637
              ],
              [
                -63.938576149336875,
                -8.441171751674938
              ],
              [
                -63.94032310654558,
                -8.451887194025115
              ],
              [
                -63.9583584824869,
                -8.466489156454134
              ],
              [
                -63.972662905237456,
                -8.478070109323525
              ],
              [
                -63.97399087422431,
                -8.486861100104978
              ],
              [
                -63.9656608853224,
                -8.505010002470907
              ],
              [
                -63.96004441365757,
                -8.510332969042931
              ],
              [
                -63.94327452535384,
                -8.526226365142664
              ],
              [
                -63.944190979099616,
                -8.538173328458605
              ],
              [
                -63.94510847581588,
                -8.550120290796498
              ],
              [
                -63.932680533992475,
                -8.556973754775811
              ],
              [
                -63.93088153501353,
                -8.55732173789465
              ],
              [
                -63.9226550582674,
                -8.558916203131906
              ],
              [
                -63.92405553047696,
                -8.575146649585182
              ],
              [
                -63.94544039854269,
                -8.609105547009626
              ],
              [
                -63.949509372609405,
                -8.607989519159355
              ],
              [
                -63.95357836467921,
                -8.606873054309851
              ],
              [
                -63.96508680564044,
                -8.595548599368653
              ],
              [
                -63.96544728133299,
                -8.593675136516037
              ],
              [
                -63.96663530160464,
                -8.587505643348983
              ],
              [
                -63.97202676367693,
                -8.582073183930529
              ],
              [
                -63.97974973338211,
                -8.580309704712763
              ],
              [
                -63.98569518319099,
                -8.585254700665569
              ],
              [
                -63.989905640025604,
                -8.612649050654134
              ],
              [
                -63.99057963538101,
                -8.617035572690508
              ],
              [
                -63.99375211193176,
                -8.618996044060808
              ],
              [
                -64.00432856109859,
                -8.625531017982022
              ],
              [
                -64.00460100558189,
                -8.63852346542337
              ],
              [
                -64.00466053830297,
                -8.641369981910865
              ],
              [
                -64.0075444803575,
                -8.644058470941129
              ],
              [
                -64.01619095355433,
                -8.652119922061418
              ],
              [
                -64.01912241189032,
                -8.660157417406646
              ],
              [
                -64.01765343706079,
                -8.663428377067106
              ],
              [
                -64.0159134068146,
                -8.667302860896925
              ],
              [
                -64.01811589802928,
                -8.670293343780836
              ],
              [
                -64.02140487445637,
                -8.674758357176444
              ],
              [
                -64.03497128819907,
                -8.693177283130469
              ],
              [
                -64.03116427773787,
                -8.704231722941696
              ],
              [
                -64.02735681326207,
                -8.715286189753646
              ],
              [
                -64.06535160590295,
                -8.710537763896042
              ],
              [
                -64.06688660209565,
                -8.710346261808553
              ],
              [
                -64.07076404802231,
                -8.711536753377416
              ],
              [
                -64.0746405489553,
                -8.712727754944757
              ],
              [
                -64.09425592332798,
                -8.727984710469018
              ],
              [
                -64.11074884114365,
                -8.724789745400026
              ],
              [
                -64.11425281686249,
                -8.722567751275918
              ],
              [
                -64.1240832469965,
                -8.716335317190056
              ],
              [
                -64.13035973536589,
                -8.721781267912052
              ],
              [
                -64.14255815588466,
                -8.742651723595186
              ],
              [
                -64.14425359170443,
                -8.776066567073553
              ],
              [
                -64.12894713530419,
                -8.804783444711378
              ],
              [
                -64.13209860691828,
                -8.812395909446183
              ],
              [
                -64.13524906652809,
                -8.82000888419212
              ],
              [
                -64.14790250814373,
                -8.82978585864609
              ],
              [
                -64.15469542299212,
                -8.835034839099901
              ],
              [
                -64.15619493658585,
                -8.838910839614327
              ],
              [
                -64.15769341918484,
                -8.842785290137176
              ],
              [
                -64.15399341778183,
                -8.854549754558633
              ],
              [
                -64.1482824197517,
                -8.872708159409532
              ],
              [
                -64.14973741878802,
                -8.884780126923097
              ],
              [
                -64.15119339579387,
                -8.896852575459123
              ],
              [
                -64.14727386621223,
                -8.91571948706208
              ],
              [
                -64.14125987700372,
                -8.944669872200311
              ],
              [
                -64.1429238569933,
                -8.953150831533181
              ],
              [
                -64.14894231255774,
                -8.958555315459751
              ],
              [
                -64.17450618398847,
                -8.960189327521428
              ],
              [
                -64.23269930578347,
                -8.991926761059506
              ],
              [
                -64.26456264965852,
                -8.988000357567364
              ],
              [
                -64.29717543768885,
                -8.99581336934749
              ],
              [
                -64.31081338199913,
                -8.99608884289008
              ],
              [
                -64.32478132025547,
                -8.987686928451415
              ],
              [
                -64.32556781113175,
                -8.979451466371067
              ],
              [
                -64.3142364152677,
                -8.954017545436935
              ],
              [
                -64.31253242771575,
                -8.950193049242898
              ],
              [
                -64.30906297113296,
                -8.93411062542217
              ],
              [
                -64.31506444392868,
                -8.929164660736959
              ],
              [
                -64.32289439401684,
                -8.928479658663383
              ],
              [
                -64.37942056314637,
                -8.946351178400663
              ],
              [
                -64.4079588872033,
                -8.960270653023969
              ],
              [
                -64.43273875677264,
                -8.968100152553081
              ],
              [
                -64.43854421924165,
                -8.971751142422496
              ],
              [
                -64.44607317816471,
                -8.976487130480526
              ],
              [
                -64.454959592486,
                -8.977476626348796
              ],
              [
                -64.46427956155686,
                -8.97503765842217
              ],
              [
                -64.47068103170534,
                -8.973362167268258
              ],
              [
                -64.48327696535324,
                -8.976018195882402
              ],
              [
                -64.49587290702475,
                -8.97867417451048
              ],
              [
                -64.51556777787094,
                -8.977200224699624
              ],
              [
                -64.53312718363046,
                -8.975885730180206
              ],
              [
                -64.54962559011094,
                -8.977887765041084
              ],
              [
                -64.55993604974392,
                -8.983301256542925
              ],
              [
                -64.56838348613867,
                -8.991391220829275
              ],
              [
                -64.57796992615923,
                -8.988163257383729
              ],
              [
                -64.57835742134142,
                -8.988032742457067
              ],
              [
                -64.5787679211852,
                -8.99763870920224
              ],
              [
                -64.57890593574635,
                -9.000870695670281
              ],
              [
                -64.58746235133249,
                -9.009125694531834
              ],
              [
                -64.6105797284241,
                -9.016070703656245
              ],
              [
                -64.64104205713058,
                -9.00626428227871
              ],
              [
                -64.6552100058509,
                -9.007636269387325
              ],
              [
                -64.6572119672161,
                -9.007830270522442
              ],
              [
                -64.67088289090435,
                -9.01441527447141
              ],
              [
                -64.68035086150132,
                -8.997667369872238
              ],
              [
                -64.68285685503706,
                -8.993234889884043
              ],
              [
                -64.69310179915814,
                -8.989789913199576
              ],
              [
                -64.70296074166794,
                -8.98950643055384
              ],
              [
                -64.70888873370231,
                -8.991808428095512
              ],
              [
                -64.72393514092262,
                -9.0055668989611
              ],
              [
                -64.73179207386349,
                -9.012751352217448
              ],
              [
                -64.75307692993883,
                -9.020366843286276
              ],
              [
                -64.76068991094458,
                -9.02020036797836
              ],
              [
                -64.77355686291659,
                -9.000236956635652
              ],
              [
                -64.77747132664659,
                -8.994162518039065
              ],
              [
                -64.78681129046386,
                -8.988069035357025
              ],
              [
                -64.79896874624812,
                -8.985081543601222
              ],
              [
                -64.81598765294787,
                -8.986264599583599
              ],
              [
                -64.83883702352823,
                -8.99372756752458
              ],
              [
                -64.87007883449806,
                -9.011809059366907
              ],
              [
                -64.88674621291435,
                -9.028827998383457
              ],
              [
                -64.89361267833974,
                -9.043317451078407
              ],
              [
                -64.90105562874567,
                -9.046302440326384
              ],
              [
                -64.90644756687385,
                -9.045086458899753
              ],
              [
                -64.91678103730449,
                -9.042755997992247
              ],
              [
                -64.91886401167687,
                -9.050179967225324
              ],
              [
                -64.91519301698234,
                -9.074230344606836
              ],
              [
                -64.932981347948,
                -9.119957704588282
              ],
              [
                -64.93944379553263,
                -9.124183709738025
              ],
              [
                -64.93984181189923,
                -9.131972172210773
              ],
              [
                -64.93882877232237,
                -9.147919588084328
              ],
              [
                -64.93407080810657,
                -9.153018571369767
              ],
              [
                -64.93313979438975,
                -9.154017055139075
              ],
              [
                -64.928506281418,
                -9.177252967535214
              ],
              [
                -64.92938476962328,
                -9.17945045134698
              ],
              [
                -64.93142126355409,
                -9.18454243269642
              ],
              [
                -64.92729778770781,
                -9.191211402848381
              ],
              [
                -64.9232527706463,
                -9.19775285160556
              ],
              [
                -64.92490627868165,
                -9.202073350377153
              ],
              [
                -64.92605677026567,
                -9.20508081847475
              ],
              [
                -64.92260078377959,
                -9.212546801538574
              ],
              [
                -64.91919828118826,
                -9.219895755131954
              ],
              [
                -64.92010128553261,
                -9.223899751024334
              ],
              [
                -64.92100376387691,
                -9.227903746918953
              ],
              [
                -64.92434872525371,
                -9.231532226895409
              ],
              [
                -64.93233968920214,
                -9.240202182354823
              ],
              [
                -64.93431366314246,
                -9.24944167169065
              ],
              [
                -64.93559265454813,
                -9.255426663541066
              ],
              [
                -64.9401976109769,
                -9.262017136027264
              ],
              [
                -64.94553055812041,
                -9.265663608945848
              ],
              [
                -64.95317053105407,
                -9.27088709124963
              ],
              [
                -64.95337952146714,
                -9.27651359091839
              ],
              [
                -64.95345499401114,
                -9.278540558235056
              ],
              [
                -64.96239495620007,
                -9.292391047355872
              ],
              [
                -64.97650986265747,
                -9.291880058445013
              ],
              [
                -64.98570179808524,
                -9.291547052482446
              ],
              [
                -65.00003421245137,
                -9.304230028281294
              ],
              [
                -65.00121170565629,
                -9.315916496232589
              ],
              [
                -65.00137368039199,
                -9.317516997339581
              ],
              [
                -65.00291970614262,
                -9.319051977776274
              ],
              [
                -65.01222514244829,
                -9.328292471345806
              ],
              [
                -65.01026413401242,
                -9.332538419132876
              ],
              [
                -65.00536512390794,
                -9.343144878135995
              ],
              [
                -65.01789356387057,
                -9.34506090066263
              ],
              [
                -65.02153904036811,
                -9.34561888001682
              ],
              [
                -65.0232010224974,
                -9.351098399551868
              ],
              [
                -65.02393104308453,
                -9.353505863440983
              ],
              [
                -65.02601851918762,
                -9.354303372536592
              ],
              [
                -65.03883142317804,
                -9.359197373142337
              ],
              [
                -65.03701694480255,
                -9.366814324288606
              ],
              [
                -65.03520193542073,
                -9.374431295436475
              ],
              [
                -65.0447158756033,
                -9.384980283519926
              ],
              [
                -65.04643185491199,
                -9.386882773851088
              ],
              [
                -65.04664637274533,
                -9.389535279048188
              ],
              [
                -65.0471543605199,
                -9.395807238873957
              ],
              [
                -65.05306680515915,
                -9.401754715133567
              ],
              [
                -65.0707317131811,
                -9.400915228106207
              ],
              [
                -65.07745215534396,
                -9.400595739509404
              ],
              [
                -65.08463415020522,
                -9.404798248381937
              ],
              [
                -65.09181406508314,
                -9.4089997442677
              ],
              [
                -65.09059209959055,
                -9.41467271980982
              ],
              [
                -65.0884330781755,
                -9.424695154370564
              ],
              [
                -65.09737302606938,
                -9.432130166664788
              ],
              [
                -65.1104169441671,
                -9.432344672688917
              ],
              [
                -65.11324045568801,
                -9.432391690805094
              ],
              [
                -65.11592043702528,
                -9.433681175979727
              ],
              [
                -65.14240428128645,
                -9.44642416389818
              ],
              [
                -65.1485297235236,
                -9.441597707549704
              ],
              [
                -65.15465523276278,
                -9.436771732193359
              ],
              [
                -65.17011463832436,
                -9.429443274528433
              ],
              [
                -65.17684912295782,
                -9.426250296884962
              ],
              [
                -65.18414905224813,
                -9.42660378964915
              ],
              [
                -65.186349597498,
                -9.41463785837023
              ],
              [
                -65.17520365318609,
                -9.395181411283797
              ],
              [
                -65.1741791841517,
                -9.393392899521503
              ],
              [
                -65.17362467837485,
                -9.389228445495176
              ],
              [
                -65.17307020059503,
                -9.38506393447191
              ],
              [
                -65.17710965382363,
                -9.383243952501488
              ],
              [
                -65.19568508413039,
                -9.374875540392978
              ],
              [
                -65.19651709289496,
                -9.367052568349523
              ],
              [
                -65.19236612558831,
                -9.35623410116194
              ],
              [
                -65.18785116956798,
                -9.344465649064535
              ],
              [
                -65.20168014125437,
                -9.320865232603346
              ],
              [
                -65.20848311635424,
                -9.309255302968616
              ],
              [
                -65.21054261799614,
                -9.300642360808965
              ],
              [
                -65.20819113580083,
                -9.296140850397475
              ],
              [
                -65.20668163787039,
                -9.293251378122752
              ],
              [
                -65.20755313460441,
                -9.28524691788087
              ],
              [
                -65.2113006516354,
                -9.278606432762224
              ],
              [
                -65.24618897471959,
                -9.257276077101318
              ],
              [
                -65.28371275717706,
                -9.269881565373684
              ],
              [
                -65.29183970420453,
                -9.278284035678757
              ],
              [
                -65.29996563423312,
                -9.286687035011449
              ],
              [
                -65.31771455397057,
                -9.296090521042334
              ],
              [
                -65.34222190705901,
                -9.30907451477108
              ],
              [
                -65.34649784699893,
                -9.315898986414378
              ],
              [
                -65.3507738099337,
                -9.32272296807479
              ],
              [
                -65.37704770998944,
                -9.324037988714183
              ],
              [
                -65.37908215282535,
                -9.332458933016836
              ],
              [
                -65.38474712924771,
                -9.337641956023628
              ],
              [
                -65.39305608884266,
                -9.339207966668246
              ],
              [
                -65.40527152177563,
                -9.326352513549237
              ],
              [
                -65.40934603274407,
                -9.32206454685121
              ],
              [
                -65.4397543705513,
                -9.311357635004827
              ],
              [
                -65.44655433916051,
                -9.315989601819059
              ],
              [
                -65.44863880511002,
                -9.324188607117042
              ],
              [
                -65.44492533985253,
                -9.328108561768714
              ],
              [
                -65.44316735588536,
                -9.329964043135643
              ],
              [
                -65.445468310886,
                -9.338708524723446
              ],
              [
                -65.44722879975089,
                -9.345398507232343
              ],
              [
                -65.44644925958417,
                -9.357850455252928
              ],
              [
                -65.4456692573935,
                -9.370302400288292
              ],
              [
                -65.44822573902977,
                -9.377755879172188
              ],
              [
                -65.45613666358166,
                -9.4008192836312
              ],
              [
                -65.45419913993969,
                -9.408218752929193
              ],
              [
                -65.4503501813096,
                -9.412139216365146
              ],
              [
                -65.44286869029895,
                -9.419759198387238
              ],
              [
                -65.43163670740321,
                -9.458246526927574
              ],
              [
                -65.43425370623008,
                -9.465947008075759
              ],
              [
                -65.44221164058936,
                -9.464309996392895
              ],
              [
                -65.45619906694156,
                -9.470129999024556
              ],
              [
                -65.46013807219056,
                -9.466188025812242
              ],
              [
                -65.46742003376859,
                -9.458901586230603
              ],
              [
                -65.4723379937183,
                -9.466139546679965
              ],
              [
                -65.47650645015801,
                -9.472274032124469
              ],
              [
                -65.49234086076588,
                -9.473273537662529
              ],
              [
                -65.50270480226708,
                -9.46958658832457
              ],
              [
                -65.5029243366463,
                -9.46091012807519
              ],
              [
                -65.50303433284974,
                -9.456559143501622
              ],
              [
                -65.5060893358124,
                -9.447309671742723
              ],
              [
                -65.5142787737496,
                -9.449120189502487
              ],
              [
                -65.52195325514134,
                -9.446515201971463
              ],
              [
                -65.5551791108119,
                -9.422065860017753
              ],
              [
                -65.58765294299663,
                -9.41338492193866
              ],
              [
                -65.59198745215005,
                -9.413430431379082
              ],
              [
                -65.59632091831102,
                -9.413476431816402
              ],
              [
                -65.60723285899844,
                -9.419624421557902
              ],
              [
                -65.61781528563746,
                -9.425587422512429
              ],
              [
                -65.62701122227351,
                -9.427660411845624
              ],
              [
                -65.63432119804291,
                -9.429307911886893
              ],
              [
                -65.64118266178116,
                -9.433076438362246
              ],
              [
                -65.64295411529822,
                -9.436040921051394
              ],
              [
                -65.64943356097318,
                -9.446883371679213
              ],
              [
                -65.65673551924776,
                -9.448887385418917
              ],
              [
                -65.66340549375776,
                -9.447634896284654
              ],
              [
                -65.67232797064553,
                -9.445958932169603
              ],
              [
                -65.67898493245491,
                -9.451002400654868
              ],
              [
                -65.68006738026578,
                -9.48488577270356
              ],
              [
                -65.68055083121247,
                -9.50001922039082
              ],
              [
                -65.68809228375615,
                -9.503650230565247
              ],
              [
                -65.69563376630603,
                -9.507281188751076
              ],
              [
                -65.70176320666413,
                -9.518747666794265
              ],
              [
                -65.72310103718928,
                -9.558663542372528
              ],
              [
                -65.72952050374592,
                -9.56268055250919
              ],
              [
                -65.73671847167284,
                -9.557003574604526
              ],
              [
                -65.74233344255575,
                -9.552575614633513
              ],
              [
                -65.74988439476697,
                -9.553231610916367
              ],
              [
                -65.75445737216675,
                -9.564501065483189
              ],
              [
                -65.75610533152864,
                -9.568563580077376
              ],
              [
                -65.76107780866552,
                -9.567299577450475
              ],
              [
                -65.77027079640585,
                -9.564963605318416
              ],
              [
                -65.77393124152348,
                -9.571051567594923
              ],
              [
                -65.7775862096763,
                -9.57713153490408
              ],
              [
                -65.79108015246422,
                -9.585252528023622
              ],
              [
                -65.7995795750091,
                -9.584632039524326
              ],
              [
                -65.80309159547188,
                -9.575132591888138
              ],
              [
                -65.80494407913052,
                -9.570120626183702
              ],
              [
                -65.81025005827199,
                -9.564017156851708
              ],
              [
                -65.8124350463795,
                -9.563376148598895
              ],
              [
                -65.81758602605969,
                -9.561866197141821
              ],
              [
                -65.81910204438735,
                -9.554951193900417
              ],
              [
                -65.82463705347661,
                -9.529701816277248
              ],
              [
                -65.83665146074826,
                -9.532773294578877
              ],
              [
                -65.84036093076719,
                -9.53372181483557
              ],
              [
                -65.84780989945321,
                -9.531932321937925
              ],
              [
                -65.84663692415526,
                -9.520556852909143
              ],
              [
                -65.84616893260386,
                -9.516019374858283
              ],
              [
                -65.8521114239426,
                -9.510310429015549
              ],
              [
                -65.8569489207786,
                -9.510351413335675
              ],
              [
                -65.85991587796032,
                -9.510376445825836
              ],
              [
                -65.88047032909581,
                -9.487072537127734
              ],
              [
                -65.89758570145406,
                -9.48865708268891
              ],
              [
                -65.91219863706695,
                -9.4833576266522
              ],
              [
                -65.92203611524432,
                -9.470504198946141
              ],
              [
                -65.92560560630002,
                -9.46166371786509
              ],
              [
                -65.93144561982042,
                -9.447202288893696
              ],
              [
                -65.93961805352055,
                -9.442515325633527
              ],
              [
                -65.95120404032632,
                -9.435870352781526
              ],
              [
                -65.95202302551883,
                -9.431846365795513
              ],
              [
                -65.95273001641215,
                -9.428370906626728
              ],
              [
                -65.96661894292822,
                -9.4197134566541
              ],
              [
                -65.9698664476001,
                -9.412766466099717
              ],
              [
                -65.97996892551366,
                -9.412747995627585
              ],
              [
                -65.98601135956416,
                -9.412736994915518
              ],
              [
                -66.00214678233547,
                -9.403867050098064
              ],
              [
                -66.0340961447001,
                -9.400241107228688
              ],
              [
                -66.04198308396172,
                -9.401980106930063
              ],
              [
                -66.04603554608329,
                -9.408954121633522
              ],
              [
                -66.04841756282447,
                -9.410545617463153
              ],
              [
                -66.05931498559775,
                -9.41782609155577
              ],
              [
                -66.10073977536763,
                -9.413611172410613
              ],
              [
                -66.11091823536681,
                -9.410218197372812
              ],
              [
                -66.11580168478206,
                -9.408590208785975
              ],
              [
                -66.1234676522536,
                -9.408386199271042
              ],
              [
                -66.15393949273387,
                -9.420080191417236
              ],
              [
                -66.15800544235593,
                -9.425431707198591
              ],
              [
                -66.15857596137523,
                -9.42618171034798
              ],
              [
                -66.17321436699659,
                -9.434025673328984
              ],
              [
                -66.19843226243312,
                -9.427180754916193
              ],
              [
                -66.2043292078919,
                -9.42558024077906
              ],
              [
                -66.22014464509766,
                -9.424908777501454
              ],
              [
                -66.22621458665992,
                -9.427202779509535
              ],
              [
                -66.23489255586375,
                -9.430482290961072
              ],
              [
                -66.2430005293126,
                -9.42899431081017
              ],
              [
                -66.25735145205837,
                -9.412756864641057
              ],
              [
                -66.25925646605072,
                -9.410601417367895
              ],
              [
                -66.26332395002352,
                -9.40998539283742
              ],
              [
                -66.2673929309917,
                -9.409369399308659
              ],
              [
                -66.29002829459101,
                -9.412098937162767
              ],
              [
                -66.28748878561207,
                -9.420091906367025
              ],
              [
                -66.28377780562772,
                -9.431770836448912
              ],
              [
                -66.2912532478875,
                -9.435001338273194
              ],
              [
                -66.30708767091724,
                -9.436450381402757
              ],
              [
                -66.31444913853373,
                -9.433419893667748
              ],
              [
                -66.31319667075421,
                -9.425676906691141
              ],
              [
                -66.31356416961033,
                -9.425254400781379
              ],
              [
                -66.31850764222153,
                -9.41957143589412
              ],
              [
                -66.33129357478862,
                -9.42453843461793
              ],
              [
                -66.337415531072,
                -9.42691693245139
              ],
              [
                -66.34145353363087,
                -9.42412844771502
              ],
              [
                -66.3529004608677,
                -9.416223509806153
              ],
              [
                -66.36290839898155,
                -9.422556485363513
              ],
              [
                -66.3664023684258,
                -9.42476700277304
              ],
              [
                -66.37437284252594,
                -9.42597150769336
              ],
              [
                -66.38828827178882,
                -9.418043544626135
              ],
              [
                -66.40845770604525,
                -9.406552129956367
              ],
              [
                -66.41364866315811,
                -9.413299092665572
              ],
              [
                -66.41432115700073,
                -9.42155407202346
              ],
              [
                -66.4005646933067,
                -9.433060497492958
              ],
              [
                -66.39570722571906,
                -9.437124001403392
              ],
              [
                -66.39267823238357,
                -9.4461794381799
              ],
              [
                -66.38964870603866,
                -9.455234920956418
              ],
              [
                -66.39201315309299,
                -9.500084730536454
              ],
              [
                -66.42317844279901,
                -9.525392206204774
              ],
              [
                -66.43854483248285,
                -9.554001085782135
              ],
              [
                -66.46707662602569,
                -9.581815556034066
              ],
              [
                -66.49452345628856,
                -9.601185512001647
              ],
              [
                -66.49668940119753,
                -9.625978905179108
              ],
              [
                -66.49845489484764,
                -9.629572889173815
              ],
              [
                -66.50022038549584,
                -9.63316687317209
              ],
              [
                -66.51563030557824,
                -9.637408899348419
              ],
              [
                -66.5364991816964,
                -9.643153431818721
              ],
              [
                -66.5437371351119,
                -9.650231877117497
              ],
              [
                -66.55097410853298,
                -9.657308879443212
              ],
              [
                -66.57261845901776,
                -9.665517896538262
              ],
              [
                -66.57661744312803,
                -9.667034370488315
              ],
              [
                -66.58126990557993,
                -9.670096897003688
              ],
              [
                -66.58592239403255,
                -9.67315988252355
              ],
              [
                -66.59215336247472,
                -9.670828376618513
              ],
              [
                -66.60906429042639,
                -9.664500439711555
              ],
              [
                -66.61642425688513,
                -9.672459912225175
              ],
              [
                -66.6237831983451,
                -9.680419379764523
              ],
              [
                -66.63906608244643,
                -9.688924874958385
              ],
              [
                -66.68456830887648,
                -9.71424786899384
              ],
              [
                -66.68830577482294,
                -9.72473181608672
              ],
              [
                -66.69142827052497,
                -9.733489781288393
              ],
              [
                -66.70093021714995,
                -9.742957254185184
              ],
              [
                -66.72501706087328,
                -9.755670788020346
              ],
              [
                -66.75004293448993,
                -9.758915784616315
              ],
              [
                -66.7589418792403,
                -9.758426804540868
              ],
              [
                -66.7620573704516,
                -9.75825581311524
              ],
              [
                -66.77499631293463,
                -9.752687363251605
              ],
              [
                -66.78177428227866,
                -9.758170318909723
              ],
              [
                -66.77688525452743,
                -9.768253813858072
              ],
              [
                -66.7802117414512,
                -9.790991223767698
              ],
              [
                -66.80596557128627,
                -9.814127147409357
              ],
              [
                -67.1053472276056,
                -9.68772409235482
              ],
              [
                -67.13334660412046,
                -9.676443658778208
              ],
              [
                -67.32750227603216,
                -9.594167741035141
              ],
              [
                -67.49611852114475,
                -9.52419174846593
              ],
              [
                -67.93982213005718,
                -9.33683157684107
              ],
              [
                -68.18841905735415,
                -9.2323148193784
              ],
              [
                -68.62005522973276,
                -9.047748105323132
              ],
              [
                -68.72752078450011,
                -8.999688913043613
              ],
              [
                -68.78448108724885,
                -8.972206078004074
              ],
              [
                -68.98907224537352,
                -8.866178734902755
              ],
              [
                -69.1777129865615,
                -8.76757584716868
              ],
              [
                -69.43525594337109,
                -8.641214625418778
              ],
              [
                -69.55280649359838,
                -8.57799600544819
              ],
              [
                -69.76075820409547,
                -8.46585170825103
              ],
              [
                -70.3689973480435,
                -8.14102410221085
              ],
              [
                -70.57531950995747,
                -8.09556002925157
              ],
              [
                -70.93688749455353,
                -8.018712739116392
              ],
              [
                -71.2410807547645,
                -7.949828841493083
              ],
              [
                -71.512459150868,
                -7.886919382685335
              ],
              [
                -72.00461221246174,
                -7.774469859812434
              ],
              [
                -72.17265204007053,
                -7.723466231803219
              ],
              [
                -72.18089602817429,
                -7.721790748451541
              ],
              [
                -72.18913949229152,
                -7.72011527709537
              ],
              [
                -72.65881616568842,
                -7.624607149319393
              ],
              [
                -72.66092563877051,
                -7.623752629444335
              ],
              [
                -72.66303513685244,
                -7.622898656567378
              ],
              [
                -72.86962989956928,
                -7.528509200373606
              ],
              [
                -73.60817781573536,
                -7.201941549680493
              ],
              [
                -73.74244133481824,
                -7.142573884128667
              ],
              [
                -73.75314331975218,
                -7.137184409547063
              ],
              [
                -73.76384576470076,
                -7.131794462951677
              ],
              [
                -73.79614469973451,
                -7.114987550982297
              ],
              [
                -73.80369916030826,
                -7.111057054613886
              ],
              [
                -73.79888269016035,
                -7.102569594003356
              ],
              [
                -73.78149375386498,
                -7.088843108780331
              ],
              [
                -73.77655280491861,
                -7.084614599863597
              ],
              [
                -73.77639730964465,
                -7.084481114024768
              ],
              [
                -73.77260981816131,
                -7.080552104741204
              ],
              [
                -73.76943783066844,
                -7.077261609534003
              ],
              [
                -73.74543145189041,
                -7.050009674178153
              ],
              [
                -73.74302595759283,
                -7.048377160562969
              ],
              [
                -73.7374360064655,
                -7.044583160788416
              ],
              [
                -73.72549205948705,
                -7.021811208587657
              ],
              [
                -73.72586105225494,
                -7.017615734161997
              ],
              [
                -73.72598706453697,
                -7.016186263106258
              ],
              [
                -73.72690357662908,
                -7.01173426202728
              ],
              [
                -73.72690458862908,
                -7.011731271036469
              ],
              [
                -73.72873758979958,
                -7.006125286130791
              ],
              [
                -73.73327604822947,
                -7.002116291265616
              ],
              [
                -73.7372970569794,
                -6.99856432555741
              ],
              [
                -73.7366495692527,
                -6.992849844932773
              ],
              [
                -73.73647406267362,
                -6.989725823009538
              ],
              [
                -73.7370980625137,
                -6.981384342901503
              ],
              [
                -73.73965906299674,
                -6.974145403209541
              ],
              [
                -73.74156805971417,
                -6.964518910071144
              ],
              [
                -73.7461550511237,
                -6.95539093683578
              ],
              [
                -73.75329603739844,
                -6.941181519161105
              ],
              [
                -73.75220506117915,
                -6.935498017949837
              ],
              [
                -73.75167555740536,
                -6.932743010635042
              ],
              [
                -73.75160305512236,
                -6.932364536553838
              ],
              [
                -73.7513100696286,
                -6.931993025189652
              ],
              [
                -73.74868409812771,
                -6.928663538890118
              ],
              [
                -73.7460466046838,
                -6.925320537617
              ],
              [
                -73.74349762406796,
                -6.919351535439874
              ],
              [
                -73.74215960066704,
                -6.916217551176573
              ],
              [
                -73.74044264668994,
                -6.909698574472544
              ],
              [
                -73.7402141315556,
                -6.908831074508636
              ],
              [
                -73.73914263197977,
                -6.907539571666135
              ],
              [
                -73.7341551822253,
                -6.90152858971596
              ],
              [
                -73.72015322164295,
                -6.888378128069898
              ],
              [
                -73.716567256301,
                -6.873557640356852
              ],
              [
                -73.71563128429587,
                -6.869690189572009
              ],
              [
                -73.71239827654192,
                -6.86504069915097
              ],
              [
                -73.71024030805275,
                -6.861936679883144
              ],
              [
                -73.71009829446237,
                -6.860342179976131
              ],
              [
                -73.7095203073004,
                -6.853822722718324
              ],
              [
                -73.70687431989744,
                -6.842857236910214
              ],
              [
                -73.70591183111301,
                -6.838867744445562
              ],
              [
                -73.69950888351839,
                -6.838705259304983
              ],
              [
                -73.69935635807614,
                -6.83870125813521
              ],
              [
                -73.69604739608525,
                -6.838692763243257
              ],
              [
                -73.68918140502655,
                -6.833592738784182
              ],
              [
                -73.68396446392005,
                -6.826011760939956
              ],
              [
                -73.6806954857498,
                -6.818420800440361
              ],
              [
                -73.68024047964767,
                -6.817364806736679
              ],
              [
                -73.67413101442664,
                -6.810071796108603
              ],
              [
                -73.67163451688113,
                -6.808043305614802
              ],
              [
                -73.66877454600325,
                -6.805719815487774
              ],
              [
                -73.66615505328457,
                -6.801670808283059
              ],
              [
                -73.66006059650358,
                -6.79225082843992
              ],
              [
                -73.65053064842735,
                -6.777521359890634
              ],
              [
                -73.65042615415076,
                -6.777228388556958
              ],
              [
                -73.64477466320727,
                -6.761424935513246
              ],
              [
                -73.63550220928202,
                -6.754273927958907
              ],
              [
                -73.62836577066797,
                -6.748769950479669
              ],
              [
                -73.61775131184308,
                -6.740583437210819
              ],
              [
                -73.61465332071748,
                -6.740069945987819
              ],
              [
                -73.60775884530963,
                -6.737931943734239
              ],
              [
                -73.60029938444067,
                -6.732812473896489
              ],
              [
                -73.59197142897365,
                -6.728351964280528
              ],
              [
                -73.5852404556606,
                -6.724779456130267
              ],
              [
                -73.58240947476173,
                -6.7249669453308
              ],
              [
                -73.57746698339996,
                -6.725294449696738
              ],
              [
                -73.56156854789648,
                -6.720559960062516
              ],
              [
                -73.55649058976186,
                -6.712524954983062
              ],
              [
                -73.55233108858243,
                -6.705942500042189
              ],
              [
                -73.53545720141,
                -6.691952531553447
              ],
              [
                -73.52812070481109,
                -6.685386020952915
              ],
              [
                -73.52520524422188,
                -6.683867029722975
              ],
              [
                -73.5212132542172,
                -6.68178703478068
              ],
              [
                -73.52095125246781,
                -6.681533524169128
              ],
              [
                -73.5153002714432,
                -6.676065020556362
              ],
              [
                -73.49997536281946,
                -6.67145503246248
              ],
              [
                -73.4969468783404,
                -6.671075046008943
              ],
              [
                -73.48936540500131,
                -6.671153534055311
              ],
              [
                -73.48601690362987,
                -6.670834013068814
              ],
              [
                -73.4842969171178,
                -6.670669515535937
              ],
              [
                -73.48372691282482,
                -6.670132040348095
              ],
              [
                -73.47790694245822,
                -6.66465001163242
              ],
              [
                -73.4746914582173,
                -6.664667548881935
              ],
              [
                -73.4685064884605,
                -6.665031503764302
              ],
              [
                -73.46582599810277,
                -6.665188990246749
              ],
              [
                -73.4604285251076,
                -6.664998009554614
              ],
              [
                -73.45735050858782,
                -6.664828495474824
              ],
              [
                -73.44281908729172,
                -6.656657017146503
              ],
              [
                -73.44050460070217,
                -6.654119526444198
              ],
              [
                -73.43723861080346,
                -6.65053854251434
              ],
              [
                -73.43216864203016,
                -6.650871032777764
              ],
              [
                -73.42916563582816,
                -6.651068024787285
              ],
              [
                -73.41910169526317,
                -6.64542802171921
              ],
              [
                -73.41311222248372,
                -6.64175303494577
              ],
              [
                -73.40580724910471,
                -6.641947024033188
              ],
              [
                -73.38234886076242,
                -6.634627027289683
              ],
              [
                -73.38212988083262,
                -6.631777509889337
              ],
              [
                -73.38159687372753,
                -6.624847044377103
              ],
              [
                -73.38143136979599,
                -6.622697061113576
              ],
              [
                -73.38096337048026,
                -6.616607074367105
              ],
              [
                -73.376486400053,
                -6.61039208439467
              ],
              [
                -73.37134393533518,
                -6.607452608637006
              ],
              [
                -73.36159098160361,
                -6.601876597910457
              ],
              [
                -73.35621899809094,
                -6.594238609894816
              ],
              [
                -73.35323550737591,
                -6.593980094212848
              ],
              [
                -73.34491556465707,
                -6.593432098257281
              ],
              [
                -73.33879307285429,
                -6.59287909481863
              ],
              [
                -73.3349505715336,
                -6.595942584967066
              ],
              [
                -73.33215511330383,
                -6.598171083616291
              ],
              [
                -73.32827862685309,
                -6.595315057107791
              ],
              [
                -73.32794662127407,
                -6.595142573207403
              ],
              [
                -73.32459762060844,
                -6.59340256821349
              ],
              [
                -73.31699218543372,
                -6.592731588417119
              ],
              [
                -73.31173918355255,
                -6.592089080220501
              ],
              [
                -73.30724467836178,
                -6.592777074199336
              ],
              [
                -73.30225223092614,
                -6.593541559398549
              ],
              [
                -73.29412073500625,
                -6.589985549012905
              ],
              [
                -73.28670227510638,
                -6.586740576585919
              ],
              [
                -73.28207882017477,
                -6.584998067170621
              ],
              [
                -73.27920681114531,
                -6.584686576772421
              ],
              [
                -73.27435735310956,
                -6.585536065901456
              ],
              [
                -73.2710493566844,
                -6.586115040533096
              ],
              [
                -73.26132741387048,
                -6.585918044032776
              ],
              [
                -73.25780741217557,
                -6.583911031616123
              ],
              [
                -73.25446094372622,
                -6.582004022121282
              ],
              [
                -73.24789044774464,
                -6.58315153346531
              ],
              [
                -73.23429949536917,
                -6.577609033473491
              ],
              [
                -73.23207652461197,
                -6.574076523797332
              ],
              [
                -73.23181304328723,
                -6.573460042217042
              ],
              [
                -73.23048953869458,
                -6.570369543332696
              ],
              [
                -73.22335506400789,
                -6.565184054712469
              ],
              [
                -73.22077257288727,
                -6.564029581009441
              ],
              [
                -73.21955259585722,
                -6.568049026411444
              ],
              [
                -73.2196050916185,
                -6.569853044437224
              ],
              [
                -73.21969908261158,
                -6.573080522539229
              ],
              [
                -73.212849077614,
                -6.57607402641843
              ],
              [
                -73.21001209666899,
                -6.572957522899483
              ],
              [
                -73.20681710649676,
                -6.569448023076432
              ],
              [
                -73.20197263749384,
                -6.564127554450007
              ],
              [
                -73.20554713878515,
                -6.556569581611334
              ],
              [
                -73.20578265547353,
                -6.556072554266433
              ],
              [
                -73.20479363854749,
                -6.555714081149715
              ],
              [
                -73.19923267945337,
                -6.553701581487448
              ],
              [
                -73.19824469303872,
                -6.549347586544062
              ],
              [
                -73.19790217695113,
                -6.547894106221491
              ],
              [
                -73.1969912116951,
                -6.544028614003537
              ],
              [
                -73.19452818979347,
                -6.544065584120191
              ],
              [
                -73.1909902358646,
                -6.544118610978681
              ],
              [
                -73.18356224424934,
                -6.539855597529647
              ],
              [
                -73.18385826479144,
                -6.535738632390742
              ],
              [
                -73.18392575358776,
                -6.534809614068274
              ],
              [
                -73.18580625348883,
                -6.526921643276989
              ],
              [
                -73.17333933825472,
                -6.518502160847736
              ],
              [
                -73.16665532780965,
                -6.524974639180752
              ],
              [
                -73.16661581991372,
                -6.525012636029734
              ],
              [
                -73.1609093690409,
                -6.524194616903857
              ],
              [
                -73.15807237983415,
                -6.523974116330975
              ],
              [
                -73.15210089331903,
                -6.519274146807326
              ],
              [
                -73.14813692316625,
                -6.510274658628256
              ],
              [
                -73.14132846376103,
                -6.50557615319152
              ],
              [
                -73.13712747770698,
                -6.497262197859138
              ],
              [
                -73.13972747488658,
                -6.492033686476464
              ],
              [
                -73.14049498075819,
                -6.490490196676929
              ],
              [
                -73.13795999345352,
                -6.483098247638183
              ],
              [
                -73.13279502593643,
                -6.47659325317843
              ],
              [
                -73.1321570238914,
                -6.467106769182935
              ],
              [
                -73.1273730735743,
                -6.464523776127309
              ],
              [
                -73.12468656119025,
                -6.46307328522138
              ],
              [
                -73.12404508721356,
                -6.457112806309445
              ],
              [
                -73.12371358187335,
                -6.454033300623442
              ],
              [
                -73.12213508923442,
                -6.452698293631967
              ],
              [
                -73.11795712471816,
                -6.449164823952628
              ],
              [
                -73.11772060896078,
                -6.445238338768593
              ],
              [
                -73.11967810309223,
                -6.438553349807753
              ],
              [
                -73.12182362023138,
                -6.431223388876616
              ],
              [
                -73.11908062067383,
                -6.422888396370384
              ],
              [
                -73.11688864585201,
                -6.421969881537622
              ],
              [
                -73.11179567418006,
                -6.419835387930282
              ],
              [
                -73.1088347017993,
                -6.409347416300935
              ],
              [
                -73.11213969203786,
                -6.400693952419054
              ],
              [
                -73.11453017923888,
                -6.396971480582714
              ],
              [
                -73.11735517847745,
                -6.394468496775461
              ],
              [
                -73.12449914875829,
                -6.390949015599602
              ],
              [
                -73.13000012526831,
                -6.388239492318308
              ],
              [
                -73.13399863171016,
                -6.387040517110248
              ],
              [
                -73.1327691109583,
                -6.378243560619495
              ],
              [
                -73.13651961481517,
                -6.371132575844179
              ],
              [
                -73.13714661367348,
                -6.3673135963297
              ],
              [
                -73.1379601220788,
                -6.362360588227416
              ],
              [
                -73.14324059670483,
                -6.356642629186205
              ],
              [
                -73.14424511416088,
                -6.355554641363443
              ],
              [
                -73.14510712030234,
                -6.351583666536221
              ],
              [
                -73.14596411643804,
                -6.347632679647629
              ],
              [
                -73.14566360104733,
                -6.342608682528573
              ],
              [
                -73.1454346200749,
                -6.338785675093208
              ],
              [
                -73.14604560093692,
                -6.334999200473986
              ],
              [
                -73.14698813879943,
                -6.329162701018938
              ],
              [
                -73.15199362107978,
                -6.321924731962227
              ],
              [
                -73.15501060293602,
                -6.323697225236476
              ],
              [
                -73.15741758745054,
                -6.325111241861538
              ],
              [
                -73.1598290508412,
                -6.326624221213075
              ],
              [
                -73.16260456552706,
                -6.317445277200507
              ],
              [
                -73.16313909821852,
                -6.298418331622011
              ],
              [
                -73.16763707064034,
                -6.289999380337168
              ],
              [
                -73.16598558517202,
                -6.286862877426111
              ],
              [
                -73.16361961782451,
                -6.282369879585741
              ],
              [
                -73.16705208759079,
                -6.273571396225337
              ],
              [
                -73.1722740656708,
                -6.26850643022093
              ],
              [
                -73.17710106623767,
                -6.261568972087486
              ],
              [
                -73.18388003076244,
                -6.256296490338861
              ],
              [
                -73.18672553923065,
                -6.256271494480458
              ],
              [
                -73.18735003897659,
                -6.256212980320113
              ],
              [
                -73.19168102934594,
                -6.255807483141966
              ],
              [
                -73.19501701596673,
                -6.24652702801121
              ],
              [
                -73.1953610095944,
                -6.244758016390239
              ],
              [
                -73.19649299064822,
                -6.238944040071398
              ],
              [
                -73.20009899129042,
                -6.236139085894122
              ],
              [
                -73.20316749047736,
                -6.233752094953003
              ],
              [
                -73.20417798257522,
                -6.232966101264874
              ],
              [
                -73.20949498746334,
                -6.225887611018642
              ],
              [
                -73.2197209583986,
                -6.221386629738469
              ],
              [
                -73.21978746312357,
                -6.217680640297593
              ],
              [
                -73.22203695333852,
                -6.211565655016859
              ],
              [
                -73.22007695086518,
                -6.206642665853516
              ],
              [
                -73.21850495090891,
                -6.202694700350112
              ],
              [
                -73.22211996496821,
                -6.17789325347772
              ],
              [
                -73.22710544559045,
                -6.172173297000979
              ],
              [
                -73.23965492442738,
                -6.157775853150826
              ],
              [
                -73.24896688335606,
                -6.144205891485922
              ],
              [
                -73.24852939232404,
                -6.140095414670198
              ],
              [
                -73.24813391271871,
                -6.136377433788967
              ],
              [
                -73.2500579091307,
                -6.132092457971872
              ],
              [
                -73.2513798903519,
                -6.129147437718878
              ],
              [
                -73.24923243536468,
                -6.113908991663394
              ],
              [
                -73.24893194871754,
                -6.111780514384901
              ],
              [
                -73.24952295452,
                -6.104188551550346
              ],
              [
                -73.24418397203473,
                -6.098702552501315
              ],
              [
                -73.24338697086083,
                -6.096915032738447
              ],
              [
                -73.24052399418929,
                -6.090497563955786
              ],
              [
                -73.23676899363252,
                -6.085206090047161
              ],
              [
                -73.24045250829656,
                -6.078243114704322
              ],
              [
                -73.23337102605508,
                -6.072936109368951
              ],
              [
                -73.23629653508284,
                -6.050086192443478
              ],
              [
                -73.23704804441738,
                -6.044214191942915
              ],
              [
                -73.23624407295584,
                -6.031169762251271
              ],
              [
                -73.23156759711064,
                -6.030352740711822
              ],
              [
                -73.223832127001,
                -6.023361754593767
              ],
              [
                -73.22335211111218,
                -6.023039783012963
              ],
              [
                -73.21837715599644,
                -6.019700250368774
              ],
              [
                -73.21842962279487,
                -6.016812262662722
              ],
              [
                -73.2184541500885,
                -6.015475761501495
              ],
              [
                -73.21773466717721,
                -6.011202785948352
              ],
              [
                -73.2102346989641,
                -6.004428804512537
              ],
              [
                -73.2065771875563,
                -6.004388811837388
              ],
              [
                -73.2033047277203,
                -6.004352298551288
              ],
              [
                -73.19000776679263,
                -5.998756797777838
              ],
              [
                -73.19053076067658,
                -5.995053315911879
              ],
              [
                -73.19225976493291,
                -5.991498834867216
              ],
              [
                -73.19256129652963,
                -5.990805855161224
              ],
              [
                -73.19282126902353,
                -5.983569839131616
              ],
              [
                -73.18878781680986,
                -5.977905854179904
              ],
              [
                -73.1828213195519,
                -5.969527908022107
              ],
              [
                -73.18049536484374,
                -5.962063893014485
              ],
              [
                -73.18353832918775,
                -5.958524425287116
              ],
              [
                -73.18665384234794,
                -5.95490142787139
              ],
              [
                -73.18460136783959,
                -5.946230948616432
              ],
              [
                -73.18657133289152,
                -5.938058988067286
              ],
              [
                -73.18298238444072,
                -5.931950532912637
              ],
              [
                -73.18151288552144,
                -5.922509548501116
              ],
              [
                -73.17889937501945,
                -5.922702024278128
              ],
              [
                -73.17471592422649,
                -5.92300901212196
              ],
              [
                -73.17499440684927,
                -5.919421055709218
              ],
              [
                -73.17419290347229,
                -5.914855047004535
              ],
              [
                -73.1682569298088,
                -5.908652570771343
              ],
              [
                -73.16975995230176,
                -5.900709094134848
              ],
              [
                -73.16987395049293,
                -5.900104094990091
              ],
              [
                -73.17053095739064,
                -5.891887628281903
              ],
              [
                -73.16313451399365,
                -5.886803138392412
              ],
              [
                -73.1559830055211,
                -5.881887137282575
              ],
              [
                -73.15914600023355,
                -5.878470676327433
              ],
              [
                -73.16110701309611,
                -5.876351675416279
              ],
              [
                -73.1569130283633,
                -5.868733698105105
              ],
              [
                -73.15106207102441,
                -5.862853209128423
              ],
              [
                -73.14326309482878,
                -5.862025685627347
              ],
              [
                -73.13809713878018,
                -5.858299198153719
              ],
              [
                -73.13156714100705,
                -5.856356710161737
              ],
              [
                -73.12736166863782,
                -5.855367218770541
              ],
              [
                -73.12513818643221,
                -5.854843708036698
              ],
              [
                -73.11654370597225,
                -5.853277710882566
              ],
              [
                -73.1169647277418,
                -5.840015251634494
              ],
              [
                -73.11705822635165,
                -5.837069740243472
              ],
              [
                -73.11753222710261,
                -5.827584294146948
              ],
              [
                -73.11059125564564,
                -5.823868772921222
              ],
              [
                -73.10646479105,
                -5.822820305811337
              ],
              [
                -73.1039912797118,
                -5.822364309647567
              ],
              [
                -73.10002680219924,
                -5.821633800782194
              ],
              [
                -73.09454335218363,
                -5.821064294926897
              ],
              [
                -73.09504284635871,
                -5.816031785006627
              ],
              [
                -73.09542284077166,
                -5.812202813481285
              ],
              [
                -73.07951290417985,
                -5.805041823307243
              ],
              [
                -73.06399796734692,
                -5.788643878408279
              ],
              [
                -73.05648500053894,
                -5.788467364423439
              ],
              [
                -73.05623104762776,
                -5.779271869905183
              ],
              [
                -73.0560070405035,
                -5.771171894240342
              ],
              [
                -73.04971206974204,
                -5.76536492487726
              ],
              [
                -73.04659907441508,
                -5.755204440367945
              ],
              [
                -73.04260261883408,
                -5.748622465578698
              ],
              [
                -73.03561813225977,
                -5.749273967758082
              ],
              [
                -73.035327644318,
                -5.749300446392988
              ],
              [
                -73.02648219903661,
                -5.743536982437427
              ],
              [
                -73.02094119496722,
                -5.739244470478597
              ],
              [
                -73.02177171772318,
                -5.733300485649143
              ],
              [
                -73.02210021381505,
                -5.730946001846856
              ],
              [
                -73.01657874052879,
                -5.727810027550898
              ],
              [
                -73.00932977866674,
                -5.723693030424551
              ],
              [
                -73.00376629006563,
                -5.721045024677392
              ],
              [
                -73.00168181524866,
                -5.717594528711681
              ],
              [
                -72.998616330172,
                -5.714069058003059
              ],
              [
                -73.00097480905923,
                -5.711312042389843
              ],
              [
                -73.00407428702474,
                -5.707689548035897
              ],
              [
                -72.99633533089357,
                -5.70469654918209
              ],
              [
                -72.99314635302832,
                -5.703463060657604
              ],
              [
                -72.99240235613921,
                -5.694955591835988
              ],
              [
                -72.9859388884913,
                -5.693755093009448
              ],
              [
                -72.98812688973179,
                -5.685132605418637
              ],
              [
                -72.98613541645766,
                -5.676650661329164
              ],
              [
                -72.98778792035716,
                -5.673489176213661
              ],
              [
                -72.99029690364684,
                -5.6686896617367
              ],
              [
                -72.98601592477236,
                -5.661380210992649
              ],
              [
                -72.97924794511165,
                -5.659306204476022
              ],
              [
                -72.97766145452114,
                -5.658820205355245
              ],
              [
                -72.97231748491409,
                -5.657388689347092
              ],
              [
                -72.96595650993838,
                -5.660260667710798
              ],
              [
                -72.9640715216823,
                -5.65749716798982
              ],
              [
                -72.96165952919381,
                -5.653915209162932
              ],
              [
                -72.96590503122155,
                -5.644713246335968
              ],
              [
                -72.96708549474893,
                -5.636507742599759
              ],
              [
                -72.96781502346846,
                -5.628803802955839
              ],
              [
                -72.96920152322812,
                -5.627820291192164
              ],
              [
                -72.9750000044982,
                -5.623705305910654
              ],
              [
                -72.97310050528192,
                -5.607449369874516
              ],
              [
                -72.96968405644594,
                -5.599783357131945
              ],
              [
                -72.96626707160382,
                -5.592116904405866
              ],
              [
                -72.96564805825648,
                -5.591767910837014
              ],
              [
                -72.95175163681125,
                -5.583940419519383
              ],
              [
                -72.95525412072884,
                -5.577637454468926
              ],
              [
                -72.95670262177872,
                -5.574006457565475
              ],
              [
                -72.95845111842223,
                -5.569623458166921
              ],
              [
                -72.9549231378052,
                -5.567354473472744
              ],
              [
                -72.9523771319068,
                -5.56571747285929
              ],
              [
                -72.9547881485444,
                -5.560828010594536
              ],
              [
                -72.95711462733156,
                -5.556110533740083
              ],
              [
                -72.95442413870364,
                -5.543204073256641
              ],
              [
                -72.95364865814861,
                -5.539482077505061
              ],
              [
                -72.9543136602163,
                -5.537842560980781
              ],
              [
                -72.9604031676551,
                -5.522836126099425
              ],
              [
                -72.96201465416287,
                -5.508532151886381
              ],
              [
                -72.96288364747181,
                -5.505821177721596
              ],
              [
                -72.9636496665165,
                -5.497441698211974
              ],
              [
                -72.9580666771563,
                -5.491359741907534
              ],
              [
                -72.95860068900424,
                -5.481963741204617
              ],
              [
                -72.95660820899506,
                -5.464365817406817
              ],
              [
                -72.95449723932839,
                -5.461776337086995
              ],
              [
                -72.94885325951516,
                -5.452115821397868
              ],
              [
                -72.94831727938089,
                -5.451198843611831
              ],
              [
                -72.94850474623885,
                -5.450599852563109
              ],
              [
                -72.95066726360116,
                -5.443685381086302
              ],
              [
                -72.94462327920796,
                -5.441581897654109
              ],
              [
                -72.9434107861446,
                -5.441160393767241
              ],
              [
                -72.94159431216227,
                -5.43771986826908
              ],
              [
                -72.94002831930993,
                -5.433131385414936
              ],
              [
                -72.93912032064829,
                -5.429981415912107
              ],
              [
                -72.93583135559876,
                -5.418565949725033
              ],
              [
                -72.93210088172069,
                -5.415188963272684
              ],
              [
                -72.9293923681528,
                -5.412736458032093
              ],
              [
                -72.92918489818797,
                -5.409227473265307
              ],
              [
                -72.92891587342869,
                -5.40466500057394
              ],
              [
                -72.92032290662233,
                -5.389575511456299
              ],
              [
                -72.92050093964039,
                -5.381255567380178
              ],
              [
                -72.91380446523601,
                -5.37748806639008
              ],
              [
                -72.91334046621756,
                -5.377227043737517
              ],
              [
                -72.90767198090151,
                -5.374608563287024
              ],
              [
                -72.9087939881978,
                -5.370803083663386
              ],
              [
                -72.9091244821526,
                -5.366247102544538
              ],
              [
                -72.90918599470176,
                -5.365399584126955
              ],
              [
                -72.90479900993867,
                -5.355856614522508
              ],
              [
                -72.90306703089853,
                -5.352089130160397
              ],
              [
                -72.89862206659889,
                -5.345531656627687
              ],
              [
                -72.89855457533996,
                -5.341725677923731
              ],
              [
                -72.89844757926444,
                -5.33569318583149
              ],
              [
                -72.89079911037133,
                -5.327611208942124
              ],
              [
                -72.8858476097456,
                -5.322378726035761
              ],
              [
                -72.88645163292193,
                -5.319698741599806
              ],
              [
                -72.888105120397,
                -5.312364263039313
              ],
              [
                -72.88215264032186,
                -5.310644747734918
              ],
              [
                -72.88071416810473,
                -5.310228765663306
              ],
              [
                -72.87056719476759,
                -5.298647267051837
              ],
              [
                -72.87444818528303,
                -5.294130285116696
              ],
              [
                -72.87559270119043,
                -5.292798793146714
              ],
              [
                -72.87305572022446,
                -5.291145292785277
              ],
              [
                -72.8696322421147,
                -5.288914322347745
              ],
              [
                -72.86759474864058,
                -5.281334825201543
              ],
              [
                -72.8641462664862,
                -5.272621861183805
              ],
              [
                -72.86854973554877,
                -5.266404370821776
              ],
              [
                -72.86898325347262,
                -5.265791397052782
              ],
              [
                -72.87150326090799,
                -5.252583909940832
              ],
              [
                -72.86750077990129,
                -5.245971446158976
              ],
              [
                -72.86336628858018,
                -5.239142462922096
              ],
              [
                -72.86691629340683,
                -5.232264982771252
              ],
              [
                -72.86372180254429,
                -5.216486042288299
              ],
              [
                -72.86648630208583,
                -5.208725080090829
              ],
              [
                -72.86824329898896,
                -5.208157055373925
              ],
              [
                -72.87403875988248,
                -5.20628259820379
              ],
              [
                -72.86985328565868,
                -5.200622593458924
              ],
              [
                -72.86878278397191,
                -5.199175128850544
              ],
              [
                -72.87109680087582,
                -5.195133116071332
              ],
              [
                -72.8769192920451,
                -5.184962674847618
              ],
              [
                -72.87817280575818,
                -5.176603706095834
              ],
              [
                -72.88411375123829,
                -5.174580220475707
              ],
              [
                -72.8854522628683,
                -5.165939744643229
              ],
              [
                -72.8775177844552,
                -5.161662742448314
              ],
              [
                -72.87293982514821,
                -5.155448750079445
              ],
              [
                -72.86595686067547,
                -5.155125763850827
              ],
              [
                -72.86092189320091,
                -5.147650270899677
              ],
              [
                -72.85642641020046,
                -5.143002792920944
              ],
              [
                -72.84851791580046,
                -5.142029764849801
              ],
              [
                -72.844684931071,
                -5.147870279840413
              ],
              [
                -72.84387694902497,
                -5.14910126441178
              ],
              [
                -72.83421246261862,
                -5.147713243029759
              ],
              [
                -72.82705150413278,
                -5.144814275433263
              ],
              [
                -72.81678555946144,
                -5.138381271773378
              ],
              [
                -72.81555157048474,
                -5.123163320679063
              ],
              [
                -72.8144515965205,
                -5.109593869748069
              ],
              [
                -72.80352065434181,
                -5.112298854943173
              ],
              [
                -72.79715818594717,
                -5.11387334457563
              ],
              [
                -72.78965169739564,
                -5.11256831691948
              ],
              [
                -72.78357722388805,
                -5.111906343578656
              ],
              [
                -72.78017823880131,
                -5.102656872625356
              ],
              [
                -72.77689374515816,
                -5.10040936455573
              ],
              [
                -72.77458478325138,
                -5.098829375320663
              ],
              [
                -72.77383077785301,
                -5.099108845811087
              ],
              [
                -72.76609331655413,
                -5.101978354311805
              ],
              [
                -72.7607188247574,
                -5.097500854183195
              ],
              [
                -72.75773932550887,
                -5.09004889719805
              ],
              [
                -72.75756434655146,
                -5.089610871375068
              ],
              [
                -72.75347685100603,
                -5.082702912782421
              ],
              [
                -72.75008189119879,
                -5.084578878110958
              ],
              [
                -72.73997793051873,
                -5.090162372292223
              ],
              [
                -72.73672694889561,
                -5.082021872200714
              ],
              [
                -72.73062796567916,
                -5.077739397897455
              ],
              [
                -72.73426696979166,
                -5.073282917637572
              ],
              [
                -72.73563295007996,
                -5.071609943920961
              ],
              [
                -72.73415897145566,
                -5.063767453504333
              ],
              [
                -72.72592299430211,
                -5.064110420282335
              ],
              [
                -72.72112050916652,
                -5.058686459621678
              ],
              [
                -72.71234155235597,
                -5.057562933404366
              ],
              [
                -72.70769709280043,
                -5.053094477989316
              ],
              [
                -72.70079760963667,
                -5.058045946884448
              ],
              [
                -72.69913059791315,
                -5.059242424783509
              ],
              [
                -72.6917391584388,
                -5.063246904128826
              ],
              [
                -72.67959718123845,
                -5.055399949515983
              ],
              [
                -72.67827418799237,
                -5.054544945977082
              ],
              [
                -72.67342522029978,
                -5.054629942500789
              ],
              [
                -72.67342172331301,
                -5.054629931497785
              ],
              [
                -72.66856874464013,
                -5.054714433019461
              ],
              [
                -72.65325480099604,
                -5.062495408904937
              ],
              [
                -72.64935129448537,
                -5.054781414104699
              ],
              [
                -72.6487063350347,
                -5.053507401442261
              ],
              [
                -72.64219987472086,
                -5.050049430372304
              ],
              [
                -72.64063984052531,
                -5.05365790596988
              ],
              [
                -72.63930235392804,
                -5.056751879335592
              ],
              [
                -72.62983090059798,
                -5.051315898749107
              ],
              [
                -72.61170299588326,
                -5.036945446080257
              ],
              [
                -72.60946199247975,
                -5.015916008693572
              ],
              [
                -72.60714254107619,
                -5.013843023059858
              ],
              [
                -72.60339954617572,
                -5.010498543108156
              ],
              [
                -72.60211556014993,
                -5.002215042459101
              ],
              [
                -72.60234355263225,
                -5.001806559910773
              ],
              [
                -72.60630402462694,
                -4.994722082090902
              ],
              [
                -72.59271159689757,
                -4.990537094291417
              ],
              [
                -72.58651511338006,
                -4.984786099675889
              ],
              [
                -72.58093514877268,
                -4.984604599452314
              ],
              [
                -72.57783718765182,
                -4.984504095106809
              ],
              [
                -72.56959320238445,
                -4.980581590129126
              ],
              [
                -72.5637307294962,
                -4.97378612906046
              ],
              [
                -72.56122426626472,
                -4.971174631971271
              ],
              [
                -72.55282877168443,
                -4.962425144798841
              ],
              [
                -72.54789732289451,
                -4.958399639021638
              ],
              [
                -72.54205131876459,
                -4.960073133869692
              ],
              [
                -72.53948833091589,
                -4.960807121417144
              ],
              [
                -72.5287208866276,
                -4.957692660863085
              ],
              [
                -72.52677742632342,
                -4.952637649836793
              ],
              [
                -72.5262038957748,
                -4.951145179963918
              ],
              [
                -72.52358942412147,
                -4.944794178384531
              ],
              [
                -72.52165192314892,
                -4.93812269538188
              ],
              [
                -72.51181398626564,
                -4.939826682756321
              ],
              [
                -72.50640100450701,
                -4.942703659251036
              ],
              [
                -72.50690451184065,
                -4.944767676287239
              ],
              [
                -72.5082834927973,
                -4.950418629964006
              ],
              [
                -72.49871101804798,
                -4.953152628352899
              ],
              [
                -72.49453604035037,
                -4.943275169402102
              ],
              [
                -72.4930585510222,
                -4.940875686583472
              ],
              [
                -72.49074407503893,
                -4.937116683271098
              ],
              [
                -72.48802457545591,
                -4.939460157698071
              ],
              [
                -72.48507305959131,
                -4.94200364630637
              ],
              [
                -72.48842654770037,
                -4.950373128212723
              ],
              [
                -72.48216859584946,
                -4.952470134388931
              ],
              [
                -72.47598860565316,
                -4.945169122773132
              ],
              [
                -72.4755076358361,
                -4.935282166037831
              ],
              [
                -72.4752321396573,
                -4.92962369836427
              ],
              [
                -72.46775815814269,
                -4.927370187031257
              ],
              [
                -72.46626917921704,
                -4.926921204165024
              ],
              [
                -72.45842870048989,
                -4.925411188219821
              ],
              [
                -72.457926718201,
                -4.917333216897688
              ],
              [
                -72.45775670115256,
                -4.914592733271991
              ],
              [
                -72.46330921502376,
                -4.909682250220294
              ],
              [
                -72.46805666305822,
                -4.903479772498976
              ],
              [
                -72.46413921531854,
                -4.895983788503024
              ],
              [
                -72.4557197252463,
                -4.895137286054323
              ],
              [
                -72.45218972916038,
                -4.90302376555054
              ],
              [
                -72.44489676571087,
                -4.901080279455834
              ],
              [
                -72.44353726783221,
                -4.903570741560514
              ],
              [
                -72.44038380806828,
                -4.909349247920138
              ],
              [
                -72.43380030523424,
                -4.910606742453717
              ],
              [
                -72.43003482496735,
                -4.90188726143026
              ],
              [
                -72.42036738778259,
                -4.89739074830373
              ],
              [
                -72.42075439503283,
                -4.89292326655379
              ],
              [
                -72.42072988861975,
                -4.888740290573016
              ],
              [
                -72.42072038108117,
                -4.887032790888476
              ],
              [
                -72.4268293680643,
                -4.880558336198016
              ],
              [
                -72.41932389217288,
                -4.877749822665718
              ],
              [
                -72.41279442735028,
                -4.88358032000516
              ],
              [
                -72.4124879122973,
                -4.883854315893284
              ],
              [
                -72.39924148249438,
                -4.874990311459038
              ],
              [
                -72.38610052560156,
                -4.871781808485249
              ],
              [
                -72.38360205468265,
                -4.867585319513714
              ],
              [
                -72.38302207712508,
                -4.855273889528453
              ],
              [
                -72.38319609284639,
                -4.854803872143632
              ],
              [
                -72.38486008617767,
                -4.85031188658093
              ],
              [
                -72.38187259550713,
                -4.844439429467136
              ],
              [
                -72.38147509267925,
                -4.84365840258058
              ],
              [
                -72.38162608204958,
                -4.842508911303376
              ],
              [
                -72.38322008695661,
                -4.83036045965253
              ],
              [
                -72.37987709607302,
                -4.828807981740826
              ],
              [
                -72.37653361319315,
                -4.827254960832581
              ],
              [
                -72.3757821063575,
                -4.824488975873746
              ],
              [
                -72.37097465237902,
                -4.806777549405169
              ],
              [
                -72.35803369393383,
                -4.805769524887491
              ],
              [
                -72.35544224306064,
                -4.805568009381625
              ],
              [
                -72.34759376181205,
                -4.804867011107099
              ],
              [
                -72.33658932259895,
                -4.796982051803671
              ],
              [
                -72.32471037588182,
                -4.796288537202595
              ],
              [
                -72.32452185187276,
                -4.792296038602623
              ],
              [
                -72.3238888716302,
                -4.788232038864376
              ],
              [
                -72.32371139424278,
                -4.787094071298049
              ],
              [
                -72.32735938077653,
                -4.780261099788369
              ],
              [
                -72.32111688509399,
                -4.777455098496621
              ],
              [
                -72.30841394403805,
                -4.782331548723506
              ],
              [
                -72.29985648213268,
                -4.776045591504391
              ],
              [
                -72.27796105088719,
                -4.782993560641756
              ],
              [
                -72.28295753687281,
                -4.790029525560391
              ],
              [
                -72.28279553317164,
                -4.790428525169456
              ],
              [
                -72.27977856874712,
                -4.797841506320736
              ],
              [
                -72.27167607857591,
                -4.79469851356156
              ],
              [
                -72.26622260800706,
                -4.785655533606919
              ],
              [
                -72.26288664239472,
                -4.780123039331244
              ],
              [
                -72.25905416881683,
                -4.771984078890624
              ],
              [
                -72.25452267045156,
                -4.765663578150466
              ],
              [
                -72.24802321322495,
                -4.772215074129093
              ],
              [
                -72.24732721240511,
                -4.776525051938957
              ],
              [
                -72.246743193712,
                -4.780145022021856
              ],
              [
                -72.23177277172493,
                -4.776256551042182
              ],
              [
                -72.21700283593587,
                -4.767524039585066
              ],
              [
                -72.21565985286489,
                -4.761694049948624
              ],
              [
                -72.21433086568301,
                -4.75592707013048
              ],
              [
                -72.20662938951195,
                -4.750948109628824
              ],
              [
                -72.20149542101811,
                -4.75509809428809
              ],
              [
                -72.19966940788933,
                -4.756574083119395
              ],
              [
                -72.19278994003064,
                -4.760878551856615
              ],
              [
                -72.18782447929195,
                -4.753435592413643
              ],
              [
                -72.19066246704585,
                -4.748811579396826
              ],
              [
                -72.1925634661918,
                -4.745713120775232
              ],
              [
                -72.18416349682565,
                -4.741872116131684
              ],
              [
                -72.16964754787878,
                -4.734673139202881
              ],
              [
                -72.16784305866454,
                -4.734931130917928
              ],
              [
                -72.16194460884799,
                -4.735776137442516
              ],
              [
                -72.16085410901039,
                -4.730897122070305
              ],
              [
                -72.1600021003845,
                -4.727085667500464
              ],
              [
                -72.14565817510504,
                -4.720763142000948
              ],
              [
                -72.14103468264514,
                -4.713793165456853
              ],
              [
                -72.13326370391461,
                -4.716147167676081
              ],
              [
                -72.13244371062451,
                -4.716736150136434
              ],
              [
                -72.12702472627146,
                -4.722199622342862
              ],
              [
                -72.1062208409226,
                -4.706211664522132
              ],
              [
                -72.10034085210476,
                -4.702057686043625
              ],
              [
                -72.09623040749136,
                -4.695300208318109
              ],
              [
                -72.09556491495339,
                -4.694206205277998
              ],
              [
                -72.09104190412287,
                -4.688470231972781
              ],
              [
                -72.08969592706934,
                -4.687578247742791
              ],
              [
                -72.07705198093673,
                -4.679199739392731
              ],
              [
                -72.07950799829568,
                -4.675538762076618
              ],
              [
                -72.08196297365595,
                -4.671879788751736
              ],
              [
                -72.06254104905575,
                -4.653219319997709
              ],
              [
                -72.0555146083152,
                -4.652085823029982
              ],
              [
                -72.05212860093644,
                -4.651539342083731
              ],
              [
                -72.0457141162663,
                -4.651109810349574
              ],
              [
                -72.0417731668057,
                -4.643292839298859
              ],
              [
                -72.0406836723307,
                -4.641673859629646
              ],
              [
                -72.03734018228418,
                -4.636705875924499
              ],
              [
                -72.03891917876537,
                -4.623207923535492
              ],
              [
                -72.01873878142553,
                -4.630861366939252
              ],
              [
                -72.01558525841067,
                -4.635069370905716
              ],
              [
                -72.0133702847199,
                -4.638026331638299
              ],
              [
                -72.00628732311654,
                -4.640869333866315
              ],
              [
                -72.0051403128882,
                -4.631621866705046
              ],
              [
                -72.00431731598941,
                -4.630493887681808
              ],
              [
                -72.00030585097613,
                -4.624997896191409
              ],
              [
                -71.99269989106175,
                -4.62323486984427
              ],
              [
                -71.9919223782402,
                -4.623054377809912
              ],
              [
                -71.98504891607736,
                -4.624339354227367
              ],
              [
                -71.9812234271111,
                -4.616593891161361
              ],
              [
                -71.98083393724032,
                -4.615820414346787
              ],
              [
                -71.97665744507185,
                -4.607519937812516
              ],
              [
                -71.96991498852385,
                -4.604840918136357
              ],
              [
                -71.96738800075599,
                -4.605666434479639
              ],
              [
                -71.96225852249937,
                -4.607341903025899
              ],
              [
                -71.94570909453525,
                -4.604408900445438
              ],
              [
                -71.94955458946956,
                -4.596763952175469
              ],
              [
                -71.94212812854073,
                -4.592911451783826
              ],
              [
                -71.9449656113521,
                -4.587894968231508
              ],
              [
                -71.94708060399076,
                -4.584157502975178
              ],
              [
                -71.9454776220508,
                -4.575442018900285
              ],
              [
                -71.93670616675276,
                -4.576249506415952
              ],
              [
                -71.93597915904301,
                -4.575678493693988
              ],
              [
                -71.92999668111827,
                -4.570981524211784
              ],
              [
                -71.93054317203726,
                -4.567044025387004
              ],
              [
                -71.93135669239514,
                -4.561189538979258
              ],
              [
                -71.92711518268688,
                -4.561598554342297
              ],
              [
                -71.92362223010593,
                -4.561935025524467
              ],
              [
                -71.92142070866244,
                -4.554278584613955
              ],
              [
                -71.92190772849142,
                -4.553327092077111
              ],
              [
                -71.9265222313928,
                -4.544308608898069
              ],
              [
                -71.92346073232268,
                -4.543092133459468
              ],
              [
                -71.9184177656962,
                -4.540994618338552
              ],
              [
                -71.9118662699547,
                -4.538935645924781
              ],
              [
                -71.91088229234684,
                -4.543422627604638
              ],
              [
                -71.90993930672269,
                -4.54771858193673
              ],
              [
                -71.9028923085819,
                -4.543528592046587
              ],
              [
                -71.90305933082178,
                -4.538483101550735
              ],
              [
                -71.90336431274186,
                -4.529250133758516
              ],
              [
                -71.89688335304231,
                -4.534584112412356
              ],
              [
                -71.89344186635454,
                -4.537416122544156
              ],
              [
                -71.88577139652031,
                -4.533422117563173
              ],
              [
                -71.88700688858046,
                -4.528348640006589
              ],
              [
                -71.88888239514431,
                -4.520649170480371
              ],
              [
                -71.88294741890726,
                -4.516117174623134
              ],
              [
                -71.87399744422997,
                -4.51846067608215
              ],
              [
                -71.87265345019814,
                -4.520162680506317
              ],
              [
                -71.86822249228008,
                -4.525773126826167
              ],
              [
                -71.8582180419211,
                -4.514896173485539
              ],
              [
                -71.85642803544457,
                -4.512950160439477
              ],
              [
                -71.85623702135398,
                -4.512742685967576
              ],
              [
                -71.83948011700048,
                -4.505169696714468
              ],
              [
                -71.83525664385147,
                -4.503539171776009
              ],
              [
                -71.83498814798627,
                -4.503435696906563
              ],
              [
                -71.83229963843053,
                -4.502293689559918
              ],
              [
                -71.82416567605995,
                -4.498839704565871
              ],
              [
                -71.81930271777911,
                -4.498068686339936
              ],
              [
                -71.81112823793472,
                -4.496773185280608
              ],
              [
                -71.80573325654855,
                -4.493657700309153
              ],
              [
                -71.800338289165,
                -4.490542734342966
              ],
              [
                -71.78854982360832,
                -4.490522687353722
              ],
              [
                -71.77748687275951,
                -4.487165693843068
              ],
              [
                -71.7746094054226,
                -4.490679677133178
              ],
              [
                -71.77130292537643,
                -4.494717687376323
              ],
              [
                -71.77181488295224,
                -4.504410159052309
              ],
              [
                -71.76151695163628,
                -4.503002150728968
              ],
              [
                -71.75203696941715,
                -4.494645668803643
              ],
              [
                -71.75169649376434,
                -4.493330151852207
              ],
              [
                -71.74986000742086,
                -4.486233176699606
              ],
              [
                -71.75492000087773,
                -4.476479239548165
              ],
              [
                -71.74890801073484,
                -4.472350756483946
              ],
              [
                -71.74120203154857,
                -4.484976678178302
              ],
              [
                -71.73898204410439,
                -4.497053662875973
              ],
              [
                -71.72878410235272,
                -4.498391145648037
              ],
              [
                -71.72374760939849,
                -4.503428628249409
              ],
              [
                -71.72095911528253,
                -4.506217604953355
              ],
              [
                -71.71472564287306,
                -4.511498066825624
              ],
              [
                -71.70410869942151,
                -4.512047550833929
              ],
              [
                -71.69939018595467,
                -4.508292580524299
              ],
              [
                -71.69715621823649,
                -4.506401593013221
              ],
              [
                -71.69555221544564,
                -4.505270588492723
              ],
              [
                -71.69436422578465,
                -4.504433600327348
              ],
              [
                -71.69024826315541,
                -4.501701080137203
              ],
              [
                -71.67843031544095,
                -4.50363957167058
              ],
              [
                -71.6761983236816,
                -4.504417561394448
              ],
              [
                -71.66853783197016,
                -4.507088052713246
              ],
              [
                -71.66441935171915,
                -4.503898567034555
              ],
              [
                -71.65916086865994,
                -4.499825569392945
              ],
              [
                -71.65480941167345,
                -4.496191598011832
              ],
              [
                -71.65426539598579,
                -4.501226579016032
              ],
              [
                -71.65359739891072,
                -4.50740505016258
              ],
              [
                -71.63652047093622,
                -4.496495064960967
              ],
              [
                -71.63620649432085,
                -4.496312074322833
              ],
              [
                -71.62704851571824,
                -4.490966585915689
              ],
              [
                -71.63024954047232,
                -4.482490130326963
              ],
              [
                -71.62630855134842,
                -4.474271663426438
              ],
              [
                -71.61970257780135,
                -4.469996171492016
              ],
              [
                -71.614163570133,
                -4.47875910132598
              ],
              [
                -71.61577505603655,
                -4.486256605810263
              ],
              [
                -71.62233202546967,
                -4.501404067871306
              ],
              [
                -71.62456802765136,
                -4.506569526560333
              ],
              [
                -71.63381050628671,
                -4.51010303203384
              ],
              [
                -71.63395246988378,
                -4.519033468724432
              ],
              [
                -71.62587000532803,
                -4.525050475659619
              ],
              [
                -71.61440204093005,
                -4.532738946453708
              ],
              [
                -71.61036907564066,
                -4.533127925048787
              ],
              [
                -71.60499158479399,
                -4.533385432035661
              ],
              [
                -71.5970571042141,
                -4.530904423075197
              ],
              [
                -71.5827936784911,
                -4.519697445039471
              ],
              [
                -71.56958377576493,
                -4.506953000967389
              ],
              [
                -71.56664076162866,
                -4.501507518709658
              ],
              [
                -71.56323577097847,
                -4.495206522936394
              ],
              [
                -71.55404785144763,
                -4.480864062368891
              ],
              [
                -71.54916336901609,
                -4.482010045835617
              ],
              [
                -71.54221040245488,
                -4.483641033110331
              ],
              [
                -71.54210241394543,
                -4.475660561473362
              ],
              [
                -71.5420648912141,
                -4.472866591705073
              ],
              [
                -71.53724143275544,
                -4.467145097992173
              ],
              [
                -71.52265349037211,
                -4.469116089325604
              ],
              [
                -71.52134201275896,
                -4.470223597649774
              ],
              [
                -71.51055102885537,
                -4.479340048150722
              ],
              [
                -71.50300255276473,
                -4.487485519335582
              ],
              [
                -71.49512508269817,
                -4.486812019538265
              ],
              [
                -71.49463011728295,
                -4.478110526061864
              ],
              [
                -71.49749907826683,
                -4.470951566036667
              ],
              [
                -71.50435007061256,
                -4.467426598973066
              ],
              [
                -71.50602454485453,
                -4.466212577282257
              ],
              [
                -71.50710207014873,
                -4.465432103695927
              ],
              [
                -71.50875904420911,
                -4.456614124248643
              ],
              [
                -71.50876408172127,
                -4.44795717101575
              ],
              [
                -71.4982591268148,
                -4.44901566552036
              ],
              [
                -71.49134464069829,
                -4.456570108147344
              ],
              [
                -71.48895564125941,
                -4.459179610652752
              ],
              [
                -71.48047766249267,
                -4.454681111166142
              ],
              [
                -71.48077619946868,
                -4.447829639191038
              ],
              [
                -71.48101367761468,
                -4.44239417746104
              ],
              [
                -71.47330720577979,
                -4.439205669235528
              ],
              [
                -71.46023376021053,
                -4.438919139286157
              ],
              [
                -71.45182881651672,
                -4.448587605692474
              ],
              [
                -71.44945330395127,
                -4.451320115781972
              ],
              [
                -71.44580833464165,
                -4.451160590545244
              ],
              [
                -71.43769236236028,
                -4.450804614568385
              ],
              [
                -71.43727135102344,
                -4.454809074895079
              ],
              [
                -71.4366983632024,
                -4.460255569299661
              ],
              [
                -71.43039188398754,
                -4.46612753291936
              ],
              [
                -71.41787992265601,
                -4.465330036046963
              ],
              [
                -71.41500447857372,
                -4.459497552321842
              ],
              [
                -71.4131209715635,
                -4.456252559721431
              ],
              [
                -71.40403550945422,
                -4.440971094867423
              ],
              [
                -71.40203804597893,
                -4.437612107580654
              ],
              [
                -71.39503256530169,
                -4.431937122254489
              ],
              [
                -71.38914409715096,
                -4.428929646864338
              ],
              [
                -71.37554515078571,
                -4.432760597767288
              ],
              [
                -71.36971616810287,
                -4.43550059418647
              ],
              [
                -71.36895017390455,
                -4.435860602401672
              ],
              [
                -71.36750866201915,
                -4.435417108796206
              ],
              [
                -71.35934520465408,
                -4.432903613041884
              ],
              [
                -71.35396772415794,
                -4.42766912951979
              ],
              [
                -71.34712276427047,
                -4.433484101857093
              ],
              [
                -71.34706076130287,
                -4.433772605843115
              ],
              [
                -71.34483276052252,
                -4.444053047698913
              ],
              [
                -71.33623380077186,
                -4.441765541853269
              ],
              [
                -71.32641233639205,
                -4.442873556698553
              ],
              [
                -71.3199723713825,
                -4.444054541857764
              ],
              [
                -71.31408389471206,
                -4.448492018502819
              ],
              [
                -71.31437989462027,
                -4.457649968997195
              ],
              [
                -71.3033994435408,
                -4.451323999891536
              ],
              [
                -71.30735842341983,
                -4.439626544171014
              ],
              [
                -71.3178953801816,
                -4.432803593049315
              ],
              [
                -71.31882540291335,
                -4.43084409633053
              ],
              [
                -71.3235038804942,
                -4.420988624950018
              ],
              [
                -71.31061745003728,
                -4.41731413758422
              ],
              [
                -71.30145797207035,
                -4.431738068208783
              ],
              [
                -71.29805298874331,
                -4.437099541619727
              ],
              [
                -71.28604854113365,
                -4.442307024034097
              ],
              [
                -71.27244509108222,
                -4.434073525435636
              ],
              [
                -71.26665609667458,
                -4.428368067266596
              ],
              [
                -71.26892513417958,
                -4.415476114383608
              ],
              [
                -71.27004362523844,
                -4.406680635846969
              ],
              [
                -71.271343617612,
                -4.396462181245972
              ],
              [
                -71.27761908205517,
                -4.387429733375577
              ],
              [
                -71.26877012675494,
                -4.384642211155338
              ],
              [
                -71.25729167366333,
                -4.386436215527713
              ],
              [
                -71.25033321963107,
                -4.389231195896941
              ],
              [
                -71.23921727130525,
                -4.386724199046369
              ],
              [
                -71.22965579550565,
                -4.387213680246989
              ],
              [
                -71.22967930153864,
                -4.391083161196637
              ],
              [
                -71.2297153019734,
                -4.397031129139619
              ],
              [
                -71.2305997898138,
                -4.407228112381801
              ],
              [
                -71.22092283358376,
                -4.404912590934939
              ],
              [
                -71.21930936252623,
                -4.392233643551989
              ],
              [
                -71.2185963715611,
                -4.386632693944252
              ],
              [
                -71.21642385159784,
                -4.379524687346498
              ],
              [
                -71.20728242964589,
                -4.379330213184306
              ],
              [
                -71.20228541893216,
                -4.388629163003816
              ],
              [
                -71.20808389637877,
                -4.398237613858567
              ],
              [
                -71.20885539731309,
                -4.399515610117468
              ],
              [
                -71.2012583928536,
                -4.425167533736428
              ],
              [
                -71.19222394872016,
                -4.420662546152594
              ],
              [
                -71.18860247305432,
                -4.414369546702226
              ],
              [
                -71.18884996260162,
                -4.406930579044554
              ],
              [
                -71.1891419829642,
                -4.398140622996189
              ],
              [
                -71.18471799515216,
                -4.39234164830671
              ],
              [
                -71.17276255268683,
                -4.394762110166957
              ],
              [
                -71.16723509939413,
                -4.390246139327507
              ],
              [
                -71.16068459478592,
                -4.394430126252685
              ],
              [
                -71.16065659488623,
                -4.39444759617253
              ],
              [
                -71.16006658224337,
                -4.405244553153188
              ],
              [
                -71.15045765739517,
                -4.399510582378607
              ],
              [
                -71.15231863688967,
                -4.391398608269138
              ],
              [
                -71.15237562014663,
                -4.390741635538916
              ],
              [
                -71.15305865623759,
                -4.382844649823959
              ],
              [
                -71.1380702155411,
                -4.386048632766779
              ],
              [
                -71.12933025666014,
                -4.391820104650554
              ],
              [
                -71.1267332533106,
                -4.398378083451095
              ],
              [
                -71.12553274692175,
                -4.401408064269453
              ],
              [
                -71.11734027903313,
                -4.409586518369937
              ],
              [
                -71.10922631731839,
                -4.400999561439133
              ],
              [
                -71.1097653114873,
                -4.393793565318915
              ],
              [
                -71.11054180903254,
                -4.383418622148268
              ],
              [
                -71.10012788104596,
                -4.376954647356828
              ],
              [
                -71.08840341677988,
                -4.375374616998659
              ],
              [
                -71.07978796181685,
                -4.37933759409333
              ],
              [
                -71.08233795733126,
                -4.383572601581793
              ],
              [
                -71.08588591856297,
                -4.389467076176378
              ],
              [
                -71.07906893780019,
                -4.395441548756378
              ],
              [
                -71.05876254709203,
                -4.386245586889193
              ],
              [
                -71.05865307201134,
                -4.38560507598949
              ],
              [
                -71.05650454804957,
                -4.373020591270276
              ],
              [
                -71.04824261458724,
                -4.374427589325235
              ],
              [
                -71.04612161475491,
                -4.383155080990041
              ],
              [
                -71.04725757959886,
                -4.386582558154164
              ],
              [
                -71.05019606608018,
                -4.395447534140343
              ],
              [
                -71.042686107115,
                -4.400152485489677
              ],
              [
                -71.03073065056685,
                -4.396341497535084
              ],
              [
                -71.02394422031844,
                -4.385361059923884
              ],
              [
                -71.02337621330979,
                -4.384442531634825
              ],
              [
                -71.01605424883344,
                -4.383462053521193
              ],
              [
                -71.01265724669555,
                -4.384766553533866
              ],
              [
                -71.00698627217118,
                -4.38694451786564
              ],
              [
                -70.99560430788165,
                -4.383765014238311
              ],
              [
                -70.9884068638799,
                -4.369318582272674
              ],
              [
                -70.98951937720507,
                -4.361368603293403
              ],
              [
                -70.98999388251316,
                -4.361040606767471
              ],
              [
                -70.99966634702817,
                -4.354355123804792
              ],
              [
                -70.99621084685897,
                -4.345154686722671
              ],
              [
                -70.98487041518099,
                -4.351807638566696
              ],
              [
                -70.97786390154853,
                -4.360227615553969
              ],
              [
                -70.9760884392165,
                -4.362360593935454
              ],
              [
                -70.97262592580215,
                -4.375286544119759
              ],
              [
                -70.96848696909565,
                -4.376078534329244
              ],
              [
                -70.96126898844908,
                -4.377551532914404
              ],
              [
                -70.95241152477695,
                -4.37865201255183
              ],
              [
                -70.9493165421044,
                -4.379063524837886
              ],
              [
                -70.94285057506515,
                -4.38505949747746
              ],
              [
                -70.93685510509272,
                -4.374849022008647
              ],
              [
                -70.93688258645338,
                -4.368786051818057
              ],
              [
                -70.93690910269648,
                -4.362879550094217
              ],
              [
                -70.93170064489291,
                -4.356184586202178
              ],
              [
                -70.92693713895875,
                -4.359438089508104
              ],
              [
                -70.92472964086762,
                -4.360946553694796
              ],
              [
                -70.91549970794236,
                -4.353659102878426
              ],
              [
                -70.91248922075037,
                -4.344796621097879
              ],
              [
                -70.90835022459065,
                -4.33260965093284
              ],
              [
                -70.89876877095088,
                -4.324144186999804
              ],
              [
                -70.89275280598825,
                -4.323554686607335
              ],
              [
                -70.88012437256553,
                -4.32231666559184
              ],
              [
                -70.88227187189914,
                -4.308408247076446
              ],
              [
                -70.88290638817878,
                -4.303070238932857
              ],
              [
                -70.8832843679364,
                -4.299893760154933
              ],
              [
                -70.87890340955303,
                -4.292044778010806
              ],
              [
                -70.87552342952277,
                -4.29047729295064
              ],
              [
                -70.85780202303154,
                -4.282261834389286
              ],
              [
                -70.84975851899242,
                -4.278098346917226
              ],
              [
                -70.85841050730797,
                -4.268710854623648
              ],
              [
                -70.86049248159912,
                -4.266182395865997
              ],
              [
                -70.86565499696269,
                -4.259912400259845
              ],
              [
                -70.86811496000915,
                -4.249988944312963
              ],
              [
                -70.8564405218366,
                -4.247178446595999
              ],
              [
                -70.8445510575534,
                -4.252763401702016
              ],
              [
                -70.84368457881304,
                -4.25317040066856
              ],
              [
                -70.83280115185086,
                -4.24979543048179
              ],
              [
                -70.83062012947549,
                -4.241858464377422
              ],
              [
                -70.83001067356003,
                -4.23964096561465
              ],
              [
                -70.82786364858477,
                -4.237934445976784
              ],
              [
                -70.82026070908589,
                -4.231891464436305
              ],
              [
                -70.82771966487148,
                -4.221529534689235
              ],
              [
                -70.83007267254669,
                -4.218260550700724
              ],
              [
                -70.82762068627747,
                -4.210271577626346
              ],
              [
                -70.8251681890013,
                -4.202281104570607
              ],
              [
                -70.81283475706236,
                -4.195978109633367
              ],
              [
                -70.80755679173897,
                -4.183209663194616
              ],
              [
                -70.79709382677717,
                -4.184510161242653
              ],
              [
                -70.79124035630335,
                -4.185237647554763
              ],
              [
                -70.78543587605701,
                -4.178269145650924
              ],
              [
                -70.77532195215107,
                -4.166127190712609
              ],
              [
                -70.76745347824433,
                -4.160781719753597
              ],
              [
                -70.75871602698918,
                -4.159616220639601
              ],
              [
                -70.7483815779049,
                -4.164745201469048
              ],
              [
                -70.74044158660638,
                -4.173251138211482
              ],
              [
                -70.73598011760984,
                -4.178030121395717
              ],
              [
                -70.72940013158549,
                -4.18387460635748
              ],
              [
                -70.72387815624145,
                -4.185414106068711
              ],
              [
                -70.7186851747013,
                -4.186734586774986
              ],
              [
                -70.71341220874368,
                -4.187702556651774
              ],
              [
                -70.7099337066713,
                -4.188279055168316
              ],
              [
                -70.70908870917381,
                -4.18825056666697
              ],
              [
                -70.70088674017612,
                -4.187975047797755
              ],
              [
                -70.69794776783489,
                -4.191459558548296
              ],
              [
                -70.69191277466824,
                -4.198613019287517
              ],
              [
                -70.68087131494507,
                -4.198996000082816
              ],
              [
                -70.6760593673447,
                -4.189730029020408
              ],
              [
                -70.67393187976737,
                -4.176037108360405
              ],
              [
                -70.67245738151463,
                -4.166550618482609
              ],
              [
                -70.6745743960329,
                -4.144743723252375
              ],
              [
                -70.6656139321258,
                -4.146022699473359
              ],
              [
                -70.66393695404646,
                -4.15461515321954
              ],
              [
                -70.66318192845816,
                -4.15848213415486
              ],
              [
                -70.65568948202066,
                -4.166157599003275
              ],
              [
                -70.65436899523684,
                -4.147918172924575
              ],
              [
                -70.65435598706893,
                -4.145370201838666
              ],
              [
                -70.65431249725864,
                -4.136747212012019
              ],
              [
                -70.65280298743292,
                -4.126768246919071
              ],
              [
                -70.64764304082131,
                -4.126662774675895
              ],
              [
                -70.63946604460426,
                -4.126669245928246
              ],
              [
                -70.63226157962073,
                -4.126326235087817
              ],
              [
                -70.62385163203469,
                -4.13115973023889
              ],
              [
                -70.62592212445156,
                -4.142682188269108
              ],
              [
                -70.63443005759272,
                -4.157978655633376
              ],
              [
                -70.63861804500881,
                -4.165507628213179
              ],
              [
                -70.63864604820306,
                -4.179306553911194
              ],
              [
                -70.6323345647084,
                -4.18723802766271
              ],
              [
                -70.62440310508866,
                -4.192082014063759
              ],
              [
                -70.61482013868057,
                -4.190730988983161
              ],
              [
                -70.60362666794478,
                -4.186553981671272
              ],
              [
                -70.59361821940311,
                -4.1822585316339
              ],
              [
                -70.58595674747544,
                -4.17750452588611
              ],
              [
                -70.58045628392928,
                -4.173706047327252
              ],
              [
                -70.56552434933295,
                -4.168685040405202
              ],
              [
                -70.56110987101326,
                -4.161007074279357
              ],
              [
                -70.55286692851531,
                -4.15482959419641
              ],
              [
                -70.54607594323785,
                -4.149741594321494
              ],
              [
                -70.54848594520028,
                -4.136883171283603
              ],
              [
                -70.53401203750167,
                -4.139406644241469
              ],
              [
                -70.52176657313483,
                -4.142574108483224
              ],
              [
                -70.51647760178878,
                -4.150193090909076
              ],
              [
                -70.51027962403218,
                -4.163790532613965
              ],
              [
                -70.51488508779057,
                -4.175356485115977
              ],
              [
                -70.51603005801461,
                -4.178232458796799
              ],
              [
                -70.52223653269851,
                -4.190367422474554
              ],
              [
                -70.51466807946093,
                -4.195576917758914
              ],
              [
                -70.50331210407488,
                -4.196386919830982
              ],
              [
                -70.49853816062456,
                -4.179761460020584
              ],
              [
                -70.49449417631351,
                -4.16568152680491
              ],
              [
                -70.4876192008166,
                -4.161514020656283
              ],
              [
                -70.48111275277225,
                -4.16880549133401
              ],
              [
                -70.48017322394243,
                -4.169858478954296
              ],
              [
                -70.47807675320124,
                -4.171944475111065
              ],
              [
                -70.4726407745997,
                -4.176531940075784
              ],
              [
                -70.46342081778624,
                -4.172521951711671
              ],
              [
                -70.4607583313557,
                -4.164692480486142
              ],
              [
                -70.46001532797804,
                -4.162507505681529
              ],
              [
                -70.45410884698214,
                -4.163348987542305
              ],
              [
                -70.44808587146615,
                -4.164206507262748
              ],
              [
                -70.44346039113294,
                -4.165065481962579
              ],
              [
                -70.43729695558663,
                -4.158240010756516
              ],
              [
                -70.44114793892719,
                -4.150524541761027
              ],
              [
                -70.441393437205,
                -4.150093533458422
              ],
              [
                -70.44638840151462,
                -4.141312078033542
              ],
              [
                -70.43899643547776,
                -4.133879112157015
              ],
              [
                -70.42927897883051,
                -4.13408660461752
              ],
              [
                -70.42449800896013,
                -4.136678070090021
              ],
              [
                -70.418643520164,
                -4.139852064742767
              ],
              [
                -70.39579814128432,
                -4.141564047653827
              ],
              [
                -70.391918153737,
                -4.139757550340602
              ],
              [
                -70.38677418087816,
                -4.137362537232761
              ],
              [
                -70.37661720513388,
                -4.141911517968869
              ],
              [
                -70.36974973208302,
                -4.153024966698404
              ],
              [
                -70.36726223393812,
                -4.159527467868249
              ],
              [
                -70.36662223744324,
                -4.161200962477968
              ],
              [
                -70.36079527833942,
                -4.169002420680216
              ],
              [
                -70.35082479109259,
                -4.180315853477429
              ],
              [
                -70.3403383370088,
                -4.185931338115742
              ],
              [
                -70.33224589654932,
                -4.177842865117127
              ],
              [
                -70.32942241150565,
                -4.167652906336817
              ],
              [
                -70.32958792249583,
                -4.166676903922951
              ],
              [
                -70.3314868963421,
                -4.155522456917113
              ],
              [
                -70.32561292550756,
                -4.146079988374649
              ],
              [
                -70.3179299597575,
                -4.15600142067796
              ],
              [
                -70.311985994298,
                -4.162232416323632
              ],
              [
                -70.29861653897305,
                -4.165571383018914
              ],
              [
                -70.29076409341708,
                -4.17623582550947
              ],
              [
                -70.29087207539347,
                -4.184174786324754
              ],
              [
                -70.29361405365748,
                -4.20014974742759
              ],
              [
                -70.28450658323004,
                -4.233500105336719
              ],
              [
                -70.29161153285676,
                -4.241350582531639
              ],
              [
                -70.29935900229528,
                -4.24428260067929
              ],
              [
                -70.30006301015507,
                -4.244549063238694
              ],
              [
                -70.30877794101667,
                -4.248228562438657
              ],
              [
                -70.31211643392861,
                -4.249775048351093
              ],
              [
                -70.31558539780025,
                -4.259012032080052
              ],
              [
                -70.30948195822086,
                -4.264803014137894
              ],
              [
                -70.30571896345978,
                -4.268328996913539
              ],
              [
                -70.30268747347651,
                -4.270679999383258
              ],
              [
                -70.29424849392569,
                -4.27474893984505
              ],
              [
                -70.29192651929206,
                -4.278045924465329
              ],
              [
                -70.28927850669169,
                -4.281805931204999
              ],
              [
                -70.29047502882604,
                -4.282986918881318
              ],
              [
                -70.29776645908399,
                -4.2901798836546
              ],
              [
                -70.28618552450979,
                -4.294515354865979
              ],
              [
                -70.27861954381443,
                -4.290944383042737
              ],
              [
                -70.27448707088634,
                -4.288532904604052
              ],
              [
                -70.25537967109231,
                -4.277383406369673
              ],
              [
                -70.25008467965974,
                -4.286656888569524
              ],
              [
                -70.25681167015645,
                -4.295345339576732
              ],
              [
                -70.25902314641219,
                -4.295983835915531
              ],
              [
                -70.26398663854532,
                -4.297415842437218
              ],
              [
                -70.27139658841752,
                -4.300232349826368
              ],
              [
                -70.26622960491673,
                -4.311551780886028
              ],
              [
                -70.25495863965381,
                -4.317867778239585
              ],
              [
                -70.24373821010302,
                -4.320174267852013
              ],
              [
                -70.2431136866332,
                -4.320302759939242
              ],
              [
                -70.23375924724039,
                -4.322639743799584
              ],
              [
                -70.22202830090784,
                -4.32352273608466
              ],
              [
                -70.21621781172752,
                -4.318850751452366
              ],
              [
                -70.20845984604385,
                -4.327551705832334
              ],
              [
                -70.20811136441327,
                -4.334563671637514
              ],
              [
                -70.20790232524035,
                -4.338757658569848
              ],
              [
                -70.20645536582657,
                -4.349047116923266
              ],
              [
                -70.20540233380505,
                -4.360611551042783
              ],
              [
                -70.19875838188513,
                -4.365905548325697
              ],
              [
                -70.18919293067411,
                -4.365187525818424
              ],
              [
                -70.18575641742008,
                -4.364809043628508
              ],
              [
                -70.17986494551113,
                -4.35759158096182
              ],
              [
                -70.17853395159898,
                -4.350087101691102
              ],
              [
                -70.17792098570246,
                -4.346630620545063
              ],
              [
                -70.1697500336629,
                -4.340400119733193
              ],
              [
                -70.16405303820235,
                -4.339666106168816
              ],
              [
                -70.15936757238894,
                -4.339062138884478
              ],
              [
                -70.1520551031132,
                -4.335078121726685
              ],
              [
                -70.15203460115207,
                -4.331016154196601
              ],
              [
                -70.1520115874238,
                -4.326644153772372
              ],
              [
                -70.15200409551915,
                -4.325178184995668
              ],
              [
                -70.15356211477004,
                -4.324048688163225
              ],
              [
                -70.1590955943531,
                -4.32003718351261
              ],
              [
                -70.16780454283882,
                -4.314257238478374
              ],
              [
                -70.16647203434174,
                -4.308881218677366
              ],
              [
                -70.1655750556208,
                -4.305421749362683
              ],
              [
                -70.16435204696879,
                -4.30238224831512
              ],
              [
                -70.15791610537514,
                -4.28393734047367
              ],
              [
                -70.15140065788563,
                -4.279696839902458
              ],
              [
                -70.14182116744446,
                -4.282252824839309
              ],
              [
                -70.13952717575796,
                -4.284148833403255
              ],
              [
                -70.1356652137552,
                -4.287339808202274
              ],
              [
                -70.12841621214318,
                -4.293173272096608
              ],
              [
                -70.11943779339337,
                -4.289899789289506
              ],
              [
                -70.11274331175197,
                -4.282810801800323
              ],
              [
                -70.11281532335619,
                -4.281545824379005
              ],
              [
                -70.11343030650212,
                -4.270747357470736
              ],
              [
                -70.10780285990074,
                -4.264268854608323
              ],
              [
                -70.10247986731997,
                -4.27553181743518
              ],
              [
                -70.10286835416596,
                -4.284607783221485
              ],
              [
                -70.10301834501878,
                -4.28810529781067
              ],
              [
                -70.09733738026303,
                -4.296103244063533
              ],
              [
                -70.08747990294655,
                -4.293288731988326
              ],
              [
                -70.0814754355631,
                -4.291573759777268
              ],
              [
                -70.07131999804031,
                -4.29638924715639
              ],
              [
                -70.07696196138421,
                -4.303043217413784
              ],
              [
                -70.08114593913135,
                -4.307978201782664
              ],
              [
                -70.07918091747014,
                -4.324519606131603
              ],
              [
                -70.07268647269892,
                -4.328980096418511
              ],
              [
                -70.06785899703492,
                -4.329095114476732
              ],
              [
                -70.06225502064731,
                -4.32922810690278
              ],
              [
                -70.05004104275517,
                -4.334165059281374
              ],
              [
                -70.04434057680913,
                -4.342527021143368
              ],
              [
                -70.04356206325747,
                -4.351925504900648
              ],
              [
                -70.03254964492591,
                -4.354630490113337
              ],
              [
                -70.01784119838125,
                -4.344852981367714
              ],
              [
                -70.01272671583727,
                -4.338290025167072
              ],
              [
                -70.01102573763548,
                -4.336107014756647
              ],
              [
                -70.0029987685735,
                -4.333492020270612
              ],
              [
                -70.00025430386297,
                -4.332598048473532
              ],
              [
                -69.98079836750365,
                -4.326968528483232
              ],
              [
                -69.97549739385497,
                -4.318894569652773
              ],
              [
                -69.96813093245484,
                -4.307379596727913
              ],
              [
                -69.96365648364065,
                -4.300191646359155
              ],
              [
                -69.95631353079877,
                -4.28354818500681
              ],
              [
                -69.95573953436765,
                -4.277787207360065
              ],
              [
                -69.9550160306434,
                -4.270520249041279
              ],
              [
                -69.95456405280724,
                -4.265982766080302
              ],
              [
                -69.95098457602391,
                -4.226596416674747
              ],
              [
                -69.94326463922384,
                -4.216140943951986
              ],
              [
                -69.94233113120386,
                -4.216123968349161
              ],
              [
                -69.93500217045808,
                -4.215987938626644
              ],
              [
                -69.8950789871222,
                -3.999546746925077
              ],
              [
                -69.8391724117045,
                -3.695943946452079
              ],
              [
                -69.83826241757127,
                -3.691107941733806
              ],
              [
                -69.83825540762523,
                -3.691068952873337
              ],
              [
                -69.83733844454068,
                -3.686195958291365
              ],
              [
                -69.83717841575951,
                -3.685325460405653
              ],
              [
                -69.83656893440083,
                -3.68200946627027
              ],
              [
                -69.83578692434916,
                -3.677761508471439
              ],
              [
                -69.835171942027,
                -3.674417500440482
              ],
              [
                -69.83473243236783,
                -3.67202954298853
              ],
              [
                -69.83469095568503,
                -3.671801012806999
              ],
              [
                -69.83330046224962,
                -3.664243544867318
              ],
              [
                -69.83218247273435,
                -3.658171576615317
              ],
              [
                -69.83055446708283,
                -3.649323092320242
              ],
              [
                -69.8290720033159,
                -3.641266136200874
              ],
              [
                -69.82559951458747,
                -3.62239170490191
              ],
              [
                -69.8234500328201,
                -3.610708768838695
              ],
              [
                -69.81977056355298,
                -3.590713337668641
              ],
              [
                -69.8158865987576,
                -3.569603934576236
              ],
              [
                -69.81089912215242,
                -3.542497005164308
              ],
              [
                -69.79576873185623,
                -3.460267830981497
              ],
              [
                -69.77983885131914,
                -3.373690167767927
              ],
              [
                -69.76881145291343,
                -3.313756397752325
              ],
              [
                -69.74981260238752,
                -3.210498840774732
              ],
              [
                -69.7250337710662,
                -3.075824373294991
              ],
              [
                -69.71123686864262,
                -2.999866146691426
              ],
              [
                -69.70642339046405,
                -2.973364271756767
              ],
              [
                -69.69722447473274,
                -2.920761966936264
              ],
              [
                -69.61311905250393,
                -2.439843431221617
              ],
              [
                -69.6128290483999,
                -2.438244423288753
              ],
              [
                -69.61193156326213,
                -2.433302458042279
              ],
              [
                -69.61077454881486,
                -2.42692846523647
              ],
              [
                -69.60984557987375,
                -2.421811980662412
              ],
              [
                -69.60862455783109,
                -2.415086515204123
              ],
              [
                -69.60681410061659,
                -2.405113542093488
              ],
              [
                -69.60627761210556,
                -2.402159056321396
              ],
              [
                -69.60469862236712,
                -2.393460589387116
              ],
              [
                -69.60308563083562,
                -2.384578157165278
              ],
              [
                -69.60152162797424,
                -2.375964684933742
              ],
              [
                -69.5994366564726,
                -2.364478725650144
              ],
              [
                -69.59787663555853,
                -2.355884239376298
              ],
              [
                -69.59587865745712,
                -2.344880307295452
              ],
              [
                -69.59433917938374,
                -2.33639784162367
              ],
              [
                -69.59132369179234,
                -2.319786400968417
              ],
              [
                -69.58609924131055,
                -2.29101150580835
              ],
              [
                -69.5799817753882,
                -2.25731515661584
              ],
              [
                -69.5755407976413,
                -2.232852759248027
              ],
              [
                -69.56913733520811,
                -2.197581398433651
              ],
              [
                -69.56265689805666,
                -2.161887075458065
              ],
              [
                -69.55766939935393,
                -2.134415671065252
              ],
              [
                -69.55381092048214,
                -2.113163761852029
              ],
              [
                -69.54833047862755,
                -2.082976875158005
              ],
              [
                -69.54286099255799,
                -2.05284849839479
              ],
              [
                -69.54266201279113,
                -2.051748011643764
              ],
              [
                -69.53617706582209,
                -2.016031677647544
              ],
              [
                -69.53332956353394,
                -1.999748246674257
              ],
              [
                -69.53219008860854,
                -1.993235232895923
              ],
              [
                -69.5278841002842,
                -1.968612365313777
              ],
              [
                -69.52788308229103,
                -1.968604851343005
              ],
              [
                -69.51768515044824,
                -1.909113607371737
              ],
              [
                -69.51765615962634,
                -1.908946590021042
              ],
              [
                -69.48369237581765,
                -1.710811422757225
              ],
              [
                -69.45286707443918,
                -1.530988198690822
              ],
              [
                -69.45359704378549,
                -1.521122229308146
              ],
              [
                -69.45478304962306,
                -1.513331253754312
              ],
              [
                -69.45559504945162,
                -1.50799980527542
              ],
              [
                -69.45703705795748,
                -1.493005866597954
              ],
              [
                -69.44989159640802,
                -1.478572934557013
              ],
              [
                -69.44286714423178,
                -1.472653424510389
              ],
              [
                -69.44036565556515,
                -1.470545460332431
              ],
              [
                -69.4348676485358,
                -1.457428507524946
              ],
              [
                -69.4350716525042,
                -1.44560654795506
              ],
              [
                -69.4364416654055,
                -1.421135659406028
              ],
              [
                -69.43667316737962,
                -1.416998163057257
              ],
              [
                -69.4352571537598,
                -1.407385196287906
              ],
              [
                -69.43525666576377,
                -1.407377204319852
              ],
              [
                -69.43383918013708,
                -1.397756255596885
              ],
              [
                -69.42868721205556,
                -1.386383294052594
              ],
              [
                -69.42379422016572,
                -1.382303832298991
              ],
              [
                -69.42022225227902,
                -1.379448838939943
              ],
              [
                -69.41703827251524,
                -1.377461360188531
              ],
              [
                -69.4141942711309,
                -1.376129343883658
              ],
              [
                -69.40848280646942,
                -1.373454359317012
              ],
              [
                -69.39952683698382,
                -1.370255863114179
              ],
              [
                -69.39947085302317,
                -1.353468925645966
              ],
              [
                -69.40894682547207,
                -1.333364542704656
              ],
              [
                -69.4081648107157,
                -1.292748686111254
              ],
              [
                -69.41072431408126,
                -1.281299760806605
              ],
              [
                -69.41638179178776,
                -1.270932294809023
              ],
              [
                -69.41860828101093,
                -1.26685183873184
              ],
              [
                -69.4248957749356,
                -1.256668877112709
              ],
              [
                -69.42829722565718,
                -1.244931403145342
              ],
              [
                -69.4295812421648,
                -1.239781935175382
              ],
              [
                -69.43080574634575,
                -1.232582979458165
              ],
              [
                -69.4289942510214,
                -1.223568521441056
              ],
              [
                -69.42517124526165,
                -1.210212569548492
              ],
              [
                -69.41961628455137,
                -1.198959600851628
              ],
              [
                -69.41899077351079,
                -1.19769213284391
              ],
              [
                -69.41353032641231,
                -1.186242162000759
              ],
              [
                -69.41354931475877,
                -1.1789686753963
              ],
              [
                -69.41356381977234,
                -1.173458730668377
              ],
              [
                -69.41278032029925,
                -1.157259771005532
              ],
              [
                -69.40921035241064,
                -1.153839813143131
              ],
              [
                -69.40606885458823,
                -1.150831326704339
              ],
              [
                -69.40435487532339,
                -1.149190313012188
              ],
              [
                -69.39830440747039,
                -1.144234355901639
              ],
              [
                -69.39524491500904,
                -1.132137904290068
              ],
              [
                -69.39588693234471,
                -1.126182403530738
              ],
              [
                -69.39622993245294,
                -1.123001947477203
              ],
              [
                -69.40041740708232,
                -1.113489983793526
              ],
              [
                -69.40390186679876,
                -1.109121520140082
              ],
              [
                -69.42461128388246,
                -1.083165131089658
              ],
              [
                -69.4279222914613,
                -1.072530161764631
              ],
              [
                -69.42559779079971,
                -1.059644701553031
              ],
              [
                -69.42366980101363,
                -1.048954749538884
              ],
              [
                -69.42767027741881,
                -1.044851268878
              ],
              [
                -69.43297926339586,
                -1.039405794882077
              ],
              [
                -69.44275471369501,
                -1.036080335065978
              ],
              [
                -69.44359719954016,
                -1.027399841389788
              ],
              [
                -69.4361257350085,
                -1.026459367911117
              ],
              [
                -69.43147328322108,
                -1.020046887112735
              ],
              [
                -69.42800228304493,
                -1.0152618999278
              ],
              [
                -69.4205598388387,
                -1.01174442993157
              ],
              [
                -69.42119431106043,
                -0.999968480838273
              ],
              [
                -69.44049622653071,
                -0.994151517683322
              ],
              [
                -69.44711719746675,
                -0.996802504038964
              ],
              [
                -69.44958017665655,
                -0.997788971451735
              ],
              [
                -69.45519464624114,
                -0.99264499225045
              ],
              [
                -69.463700604946,
                -0.9837655436667
              ],
              [
                -69.46308461590479,
                -0.975794062887613
              ],
              [
                -69.46308913405973,
                -0.97471759825376
              ],
              [
                -69.46312011919042,
                -0.966877113058275
              ],
              [
                -69.4696195932388,
                -0.959855670591349
              ],
              [
                -69.4761970552638,
                -0.95715468260619
              ],
              [
                -69.47949355145674,
                -0.954470681020019
              ],
              [
                -69.48661303444169,
                -0.949928215578307
              ],
              [
                -69.49151402066265,
                -0.947563206948279
              ],
              [
                -69.4939990091495,
                -0.946227712757678
              ],
              [
                -69.49697948554119,
                -0.944625714725081
              ],
              [
                -69.50173496241986,
                -0.942110215674759
              ],
              [
                -69.50304545512064,
                -0.93984824904884
              ],
              [
                -69.50734493573094,
                -0.932427784797826
              ],
              [
                -69.51288991074128,
                -0.932931284628254
              ],
              [
                -69.51598990433176,
                -0.933212759974864
              ],
              [
                -69.52309787030136,
                -0.92927130305462
              ],
              [
                -69.52692734199316,
                -0.921495338146581
              ],
              [
                -69.52496585203137,
                -0.904462402854776
              ],
              [
                -69.52281836006159,
                -0.885814481126802
              ],
              [
                -69.52769184022124,
                -0.877881012021068
              ],
              [
                -69.53338331387253,
                -0.869793064655443
              ],
              [
                -69.53807280386094,
                -0.861520595884392
              ],
              [
                -69.54106276902763,
                -0.861203588603523
              ],
              [
                -69.54771626547546,
                -0.860498090428164
              ],
              [
                -69.57327515812807,
                -0.841754661049204
              ],
              [
                -69.56586018937368,
                -0.83209273015904
              ],
              [
                -69.56395317495647,
                -0.829364231953533
              ],
              [
                -69.55915619354316,
                -0.822501247116096
              ],
              [
                -69.56266067691038,
                -0.811833816866804
              ],
              [
                -69.5737686516207,
                -0.800631853811227
              ],
              [
                -69.58164560168748,
                -0.801049838178789
              ],
              [
                -69.58324110981548,
                -0.801133854053475
              ],
              [
                -69.58668606608383,
                -0.792983405560079
              ],
              [
                -69.58776658177524,
                -0.790425391075589
              ],
              [
                -69.58894708580755,
                -0.789608395545172
              ],
              [
                -69.59216955124752,
                -0.787378920012019
              ],
              [
                -69.59903853952444,
                -0.78125592574142
              ],
              [
                -69.61163998987158,
                -0.769739485066067
              ],
              [
                -69.6094965021775,
                -0.761108021776496
              ],
              [
                -69.61947092564165,
                -0.75872803270046
              ],
              [
                -69.61614044302826,
                -0.750080597328603
              ],
              [
                -69.62574341390409,
                -0.74922408801329
              ],
              [
                -69.6177254344069,
                -0.740522638280692
              ],
              [
                -69.62196891827668,
                -0.731619163910958
              ],
              [
                -69.61151799416929,
                -0.723241184615481
              ],
              [
                -69.6062970197501,
                -0.721960699176549
              ],
              [
                -69.60344303109548,
                -0.721260714670912
              ],
              [
                -69.6041675304347,
                -0.717483732091671
              ],
              [
                -69.60491549468556,
                -0.713582237021405
              ],
              [
                -69.60199653806703,
                -0.706466752565714
              ],
              [
                -69.59926151728486,
                -0.702492290384345
              ],
              [
                -69.59706956808235,
                -0.699250314296196
              ],
              [
                -69.59300557117368,
                -0.693855838739987
              ],
              [
                -69.59195358785635,
                -0.692459838290977
              ],
              [
                -69.58786909397527,
                -0.687571853686428
              ],
              [
                -69.58480410256571,
                -0.683949353062103
              ],
              [
                -69.5834686244046,
                -0.67423792440844
              ],
              [
                -69.58296762079607,
                -0.672118397974009
              ],
              [
                -69.58163213716759,
                -0.666467438815239
              ],
              [
                -69.57831715117803,
                -0.659620987308225
              ],
              [
                -69.57335616063058,
                -0.649375022476326
              ],
              [
                -69.56385420658424,
                -0.639350061283572
              ],
              [
                -69.57482413871963,
                -0.636247554102469
              ],
              [
                -69.57285064832202,
                -0.625632620140775
              ],
              [
                -69.57963365675676,
                -0.619761660774907
              ],
              [
                -69.58143160996798,
                -0.620316627701576
              ],
              [
                -69.58782759626412,
                -0.622292130323719
              ],
              [
                -69.59432856898465,
                -0.60599621837258
              ],
              [
                -69.60452502381726,
                -0.599541727697257
              ],
              [
                -69.59843805294207,
                -0.589724280079496
              ],
              [
                -69.59780254777354,
                -0.588698772195058
              ],
              [
                -69.59532004860165,
                -0.576734356714572
              ],
              [
                -69.59257459631189,
                -0.567163871471831
              ],
              [
                -69.59953006243612,
                -0.556291916466493
              ],
              [
                -69.60095554251683,
                -0.55406343368769
              ],
              [
                -69.60335201962556,
                -0.549648443915324
              ],
              [
                -69.60588303022644,
                -0.544434962410294
              ],
              [
                -69.61425348568402,
                -0.53988447873095
              ],
              [
                -69.61347599779529,
                -0.531216033009901
              ],
              [
                -69.61296099437996,
                -0.526979552243457
              ],
              [
                -69.61238099411814,
                -0.524152069725927
              ],
              [
                -69.61172500521336,
                -0.520956098706548
              ],
              [
                -69.61066352045707,
                -0.512995623095869
              ],
              [
                -69.6142334787519,
                -0.50559465160561
              ],
              [
                -69.62194493999667,
                -0.501338656627187
              ],
              [
                -69.63289090229043,
                -0.49800917613151
              ],
              [
                -69.63542989137115,
                -0.498299661159867
              ],
              [
                -69.64333487738152,
                -0.499204688133933
              ],
              [
                -69.64414036337212,
                -0.493809696197344
              ],
              [
                -69.64453736990755,
                -0.490900203093682
              ],
              [
                -69.6445628359502,
                -0.489037717690465
              ],
              [
                -69.64457936497595,
                -0.487848223542399
              ],
              [
                -69.64455934648862,
                -0.482562733096127
              ],
              [
                -69.6500408341546,
                -0.480369273486219
              ],
              [
                -69.65277832050644,
                -0.479231272347932
              ],
              [
                -69.6623187849835,
                -0.474454785588087
              ],
              [
                -69.67011523782658,
                -0.471191782509158
              ],
              [
                -69.67305874525957,
                -0.470610814110396
              ],
              [
                -69.67711619593902,
                -0.469809802695899
              ],
              [
                -69.68589417443602,
                -0.468595793335726
              ],
              [
                -69.68878266137023,
                -0.464690837467955
              ],
              [
                -69.69075714312183,
                -0.462020846496991
              ],
              [
                -69.69838312364662,
                -0.459783353201593
              ],
              [
                -69.70300709894849,
                -0.458575371477926
              ],
              [
                -69.70792755699095,
                -0.457289847091136
              ],
              [
                -69.71711600588574,
                -0.454616882676374
              ],
              [
                -69.73284846628064,
                -0.445740415984326
              ],
              [
                -69.73882194617507,
                -0.439969949901428
              ],
              [
                -69.74033392807263,
                -0.435043451050986
              ],
              [
                -69.74204340716886,
                -0.429472002838514
              ],
              [
                -69.74770089515852,
                -0.427206011464115
              ],
              [
                -69.74906589136619,
                -0.426658998787138
              ],
              [
                -69.75440635199546,
                -0.420414008566076
              ],
              [
                -69.75542485974516,
                -0.418989521431188
              ],
              [
                -69.76166081171843,
                -0.410267086338632
              ],
              [
                -69.7649098049909,
                -0.408155090151299
              ],
              [
                -69.76677330211956,
                -0.40691810330923
              ],
              [
                -69.76765280540472,
                -0.406333079748301
              ],
              [
                -69.76886878728841,
                -0.40523509529598
              ],
              [
                -69.7706147999419,
                -0.403659604820848
              ],
              [
                -69.77485774708049,
                -0.399970127108699
              ],
              [
                -69.77951622718828,
                -0.400178136577523
              ],
              [
                -69.78225673448777,
                -0.400299628268774
              ],
              [
                -69.78905617951094,
                -0.39988461941417
              ],
              [
                -69.79206569405069,
                -0.394216640645832
              ],
              [
                -69.79366367043315,
                -0.391205668986084
              ],
              [
                -69.79913114328451,
                -0.38867916161097
              ],
              [
                -69.80445612374487,
                -0.386189156074291
              ],
              [
                -69.80701062595529,
                -0.384654661473391
              ],
              [
                -69.80846862679736,
                -0.383778688125821
              ],
              [
                -69.81352658243401,
                -0.380781702623925
              ],
              [
                -69.8231375335037,
                -0.380553698167042
              ],
              [
                -69.8351174936814,
                -0.361251290275448
              ],
              [
                -69.83608798964887,
                -0.359600289036879
              ],
              [
                -69.84447543678539,
                -0.3453388544321
              ],
              [
                -69.85310839999546,
                -0.346305860011045
              ],
              [
                -69.86167139048499,
                -0.347799857451049
              ],
              [
                -69.87023482714974,
                -0.34644436145058
              ],
              [
                -69.87460633560994,
                -0.345680351803992
              ],
              [
                -69.88300126503232,
                -0.343916884441716
              ],
              [
                -69.88587527273329,
                -0.345430345474855
              ],
              [
                -69.8920412493501,
                -0.348676849681337
              ],
              [
                -69.8969102008006,
                -0.346414842130499
              ],
              [
                -69.90610168906404,
                -0.341307388350629
              ],
              [
                -69.91023015468873,
                -0.338651398343193
              ],
              [
                -69.91307561971412,
                -0.336820392919563
              ],
              [
                -69.91369112312401,
                -0.336424883555956
              ],
              [
                -69.91465413807157,
                -0.335804895120966
              ],
              [
                -69.92276660691142,
                -0.331057422797743
              ],
              [
                -69.93347304430407,
                -0.316106509903405
              ],
              [
                -69.93425555711904,
                -0.313565511230769
              ],
              [
                -69.93663802841566,
                -0.305831516664317
              ],
              [
                -69.94418699957792,
                -0.302526052432617
              ],
              [
                -69.94772500865903,
                -0.300976566884218
              ],
              [
                -69.9526549511685,
                -0.292845577038574
              ],
              [
                -69.95334995027922,
                -0.291700075708899
              ],
              [
                -69.95415647088954,
                -0.291125098075591
              ],
              [
                -69.96343690688973,
                -0.284507115307485
              ],
              [
                -69.97419288304773,
                -0.269163200894352
              ],
              [
                -69.97638634491803,
                -0.265643701233627
              ],
              [
                -69.98102133062199,
                -0.258208251523283
              ],
              [
                -69.9855178067409,
                -0.254697741921392
              ],
              [
                -69.98988879938805,
                -0.251284771915557
              ],
              [
                -69.99168929995422,
                -0.246848280938985
              ],
              [
                -69.99677628093626,
                -0.234317328843351
              ],
              [
                -70.0048952489627,
                -0.224767882794156
              ],
              [
                -70.01501118437945,
                -0.2197264125675
              ],
              [
                -70.01905915334306,
                -0.217708924876475
              ],
              [
                -70.0296036110863,
                -0.20929945426185
              ],
              [
                -70.03683060274241,
                -0.203977471030464
              ],
              [
                -70.05161903767876,
                -0.19259951351509
              ],
              [
                -70.05705998691369,
                -0.186322542055736
              ],
              [
                -70.0575184827994,
                -0.157789696415
              ],
              [
                -70.05291501938933,
                -0.151544219556334
              ],
              [
                -70.04831053398073,
                -0.145296742719695
              ],
              [
                -70.04128758111845,
                -0.123698333006568
              ],
              [
                -70.04183054803067,
                -0.113358886902842
              ],
              [
                -70.0419575565258,
                -0.111689888667413
              ],
              [
                -70.04249005040444,
                -0.104666408136599
              ],
              [
                -70.04410555622077,
                -0.057810109150575
              ],
              [
                -70.0450535674218,
                -0.030307229780442
              ],
              [
                -70.04543306384636,
                -0.024511250315483
              ],
              [
                -70.0457025687242,
                -0.020389268054877
              ],
              [
                -70.04582804620892,
                -0.015667778230161
              ],
              [
                -70.0458705516606,
                0.06875535050431
              ],
              [
                -70.04271805029387,
                0.559422204909723
              ],
              [
                -70.03532358630315,
                0.561774164507646
              ],
              [
                -70.02601509892513,
                0.558601194718427
              ],
              [
                -70.0247816366044,
                0.553560699402675
              ],
              [
                -70.02235062778632,
                0.543622256184499
              ],
              [
                -70.01518216741997,
                0.539914771825893
              ],
              [
                -70.0035766973339,
                0.54165826756863
              ],
              [
                -69.99473626112247,
                0.548335726177479
              ],
              [
                -69.99072576796313,
                0.560545176352808
              ],
              [
                -69.99050426883738,
                0.561219168546198
              ],
              [
                -69.99792272358171,
                0.570439623147965
              ],
              [
                -69.9857062922013,
                0.571187148879465
              ],
              [
                -69.97099883944253,
                0.57142664846916
              ],
              [
                -69.96651289820272,
                0.563368669378267
              ],
              [
                -69.96544041092588,
                0.561441659249052
              ],
              [
                -69.95826593353145,
                0.55916366599581
              ],
              [
                -69.95424093971081,
                0.557824170159684
              ],
              [
                -69.94873747816393,
                0.556429188415843
              ],
              [
                -69.94167001324966,
                0.55506421940352
              ],
              [
                -69.92511257749348,
                0.565532140472053
              ],
              [
                -69.9140601254868,
                0.585421080926813
              ],
              [
                -69.90590366045669,
                0.581863061885366
              ],
              [
                -69.89509619320428,
                0.583379578527718
              ],
              [
                -69.89311270280307,
                0.581589592764017
              ],
              [
                -69.88645773165425,
                0.575585110044172
              ],
              [
                -69.86867632474933,
                0.58060656044983
              ],
              [
                -69.85981387444933,
                0.580491566059248
              ],
              [
                -69.85242090050765,
                0.584529077544141
              ],
              [
                -69.84757991284846,
                0.587172541070103
              ],
              [
                -69.83714548153397,
                0.584370564696721
              ],
              [
                -69.83116947439929,
                0.590291050461644
              ],
              [
                -69.8189580428364,
                0.585940553364659
              ],
              [
                -69.81282059524017,
                0.583334054615376
              ],
              [
                -69.8121695781768,
                0.581658086532529
              ],
              [
                -69.80880159736255,
                0.572992099299909
              ],
              [
                -69.79964864168528,
                0.580222063288145
              ],
              [
                -69.79723914877881,
                0.58208104430429
              ],
              [
                -69.79153869265917,
                0.586488550368917
              ],
              [
                -69.78693817660718,
                0.593358025264404
              ],
              [
                -69.78514569998863,
                0.596034485920561
              ],
              [
                -69.78198572430287,
                0.597714494593658
              ],
              [
                -69.77331424588327,
                0.601946991053274
              ],
              [
                -69.76666229298316,
                0.604873448158746
              ],
              [
                -69.7639492864454,
                0.606067451895647
              ],
              [
                -69.76383030493966,
                0.60620345931552
              ],
              [
                -69.74978935925724,
                0.622321378516518
              ],
              [
                -69.74135990784583,
                0.616759414888518
              ],
              [
                -69.73938090743674,
                0.615453909141463
              ],
              [
                -69.72490948110155,
                0.617384896560539
              ],
              [
                -69.72092098024895,
                0.626174351360706
              ],
              [
                -69.71759499632388,
                0.63712483216312
              ],
              [
                -69.71662901311794,
                0.640305310740694
              ],
              [
                -69.71529002437441,
                0.644714290130142
              ],
              [
                -69.70933303347276,
                0.648310776414384
              ],
              [
                -69.70560907527027,
                0.64958227167903
              ],
              [
                -69.69984308773454,
                0.651550242798296
              ],
              [
                -69.69150212319462,
                0.653828714327623
              ],
              [
                -69.6911741103314,
                0.656285724987558
              ],
              [
                -69.68986012287522,
                0.666131687542706
              ],
              [
                -69.67946019388434,
                0.670917170289758
              ],
              [
                -69.67053622432103,
                0.6690991649139
              ],
              [
                -69.66516922445567,
                0.667896150360677
              ],
              [
                -69.6608527800322,
                0.667226182688727
              ],
              [
                -69.655826291673,
                0.666404184576586
              ],
              [
                -69.64772579996493,
                0.661379714779396
              ],
              [
                -69.64255386014173,
                0.652291729391215
              ],
              [
                -69.63373540163819,
                0.636794312545533
              ],
              [
                -69.62660092424571,
                0.636917789261038
              ],
              [
                -69.6178949643432,
                0.630070837109353
              ],
              [
                -69.60643200859413,
                0.629894822632658
              ],
              [
                -69.60464001031556,
                0.639162298463035
              ],
              [
                -69.59446805701327,
                0.639271294909047
              ],
              [
                -69.58975859825614,
                0.648616233063712
              ],
              [
                -69.58992807697724,
                0.653703210664447
              ],
              [
                -69.59012107551389,
                0.659512691222726
              ],
              [
                -69.58552210997252,
                0.662357680728412
              ],
              [
                -69.57817364609159,
                0.666701171606194
              ],
              [
                -69.57463764609453,
                0.668551167412015
              ],
              [
                -69.56980117261782,
                0.671080631203938
              ],
              [
                -69.56645620481521,
                0.672816618506063
              ],
              [
                -69.5661431927297,
                0.676673630206146
              ],
              [
                -69.56551770054926,
                0.684385100611069
              ],
              [
                -69.55407174697312,
                0.682944581370574
              ],
              [
                -69.5427048210771,
                0.681514112102241
              ],
              [
                -69.53376834928957,
                0.683982591651925
              ],
              [
                -69.53114786953833,
                0.693087536888697
              ],
              [
                -69.53202634550713,
                0.695224042965156
              ],
              [
                -69.53426583422772,
                0.700669996219659
              ],
              [
                -69.52710087722623,
                0.708128982848098
              ],
              [
                -69.51881038329518,
                0.71373996513246
              ],
              [
                -69.51361544273246,
                0.71338846899502
              ],
              [
                -69.50983394406786,
                0.713131948626037
              ],
              [
                -69.50610895433357,
                0.719840436792204
              ],
              [
                -69.5050679595988,
                0.721715427730928
              ],
              [
                -69.49710449315813,
                0.728292881915296
              ],
              [
                -69.48079109563136,
                0.735938349518701
              ],
              [
                -69.46888712608676,
                0.735494341926816
              ],
              [
                -69.46213466997902,
                0.729533864366889
              ],
              [
                -69.46108316300301,
                0.725548387137079
              ],
              [
                -69.45856469802654,
                0.715997948322637
              ],
              [
                -69.4486827318515,
                0.714988433413417
              ],
              [
                -69.44553172551022,
                0.714666451400018
              ],
              [
                -69.43719177209093,
                0.710247971143084
              ],
              [
                -69.43676827586745,
                0.702182986304265
              ],
              [
                -69.43637229245243,
                0.694654031189635
              ],
              [
                -69.42833484249223,
                0.692243025477929
              ],
              [
                -69.42702932823843,
                0.691382551976107
              ],
              [
                -69.41876136463318,
                0.685936069124741
              ],
              [
                -69.40949742186251,
                0.675896098664309
              ],
              [
                -69.40880392087952,
                0.666783651249352
              ],
              [
                -69.40838742828434,
                0.661321171375917
              ],
              [
                -69.3988734521107,
                0.655529714892218
              ],
              [
                -69.39180498250205,
                0.648350726578712
              ],
              [
                -69.38478754466642,
                0.6412237670615
              ],
              [
                -69.38063605504334,
                0.637571794113798
              ],
              [
                -69.37635457995546,
                0.634194310990838
              ],
              [
                -69.37161010590788,
                0.630510819121405
              ],
              [
                -69.36839861109121,
                0.628017838364224
              ],
              [
                -69.36720509745034,
                0.626245329762853
              ],
              [
                -69.35801516870525,
                0.612601875724351
              ],
              [
                -69.35478715354252,
                0.614221868513637
              ],
              [
                -69.35155766738751,
                0.61584137630281
              ],
              [
                -69.34899718518959,
                0.618841378295992
              ],
              [
                -69.3411997270828,
                0.627977306664412
              ],
              [
                -69.33966472353035,
                0.639539273357468
              ],
              [
                -69.32821277373147,
                0.634966285574519
              ],
              [
                -69.32440181144132,
                0.633444794638909
              ],
              [
                -69.31093637545044,
                0.638064781544336
              ],
              [
                -69.30956435924655,
                0.63963025573427
              ],
              [
                -69.30094792664481,
                0.649465704926337
              ],
              [
                -69.28704897936029,
                0.64684824656217
              ],
              [
                -69.29152243696129,
                0.636925779313454
              ],
              [
                -69.29200395398013,
                0.635857259915661
              ],
              [
                -69.29128497129791,
                0.634039790579134
              ],
              [
                -69.28567597316369,
                0.619872351319269
              ],
              [
                -69.2897079711371,
                0.614896354923107
              ],
              [
                -69.2900594656527,
                0.614462888804589
              ],
              [
                -69.29242447567027,
                0.611490373700644
              ],
              [
                -69.29415295140775,
                0.608986891532294
              ],
              [
                -69.2974089257548,
                0.603979440170922
              ],
              [
                -69.29247544123994,
                0.603632434146737
              ],
              [
                -69.2839789852271,
                0.603275913802415
              ],
              [
                -69.27746651842207,
                0.604636914347339
              ],
              [
                -69.2685580549002,
                0.60750290023448
              ],
              [
                -69.26516409356275,
                0.60859490723576
              ],
              [
                -69.26054810281781,
                0.607002913547328
              ],
              [
                -69.25440564677591,
                0.604884402949817
              ],
              [
                -69.25059666221378,
                0.606381897179618
              ],
              [
                -69.24354520565038,
                0.609152890645977
              ],
              [
                -69.24325768193852,
                0.608800416119551
              ],
              [
                -69.23838873275072,
                0.602838417047018
              ],
              [
                -69.2122423300124,
                0.610053865596478
              ],
              [
                -69.20310086430898,
                0.605912918344798
              ],
              [
                -69.19976639766273,
                0.617718847574028
              ],
              [
                -69.20032338249638,
                0.62486981407834
              ],
              [
                -69.20087839433286,
                0.631994802689308
              ],
              [
                -69.1952404023311,
                0.637764259426816
              ],
              [
                -69.19265393310357,
                0.651968216407205
              ],
              [
                -69.18020049499702,
                0.656391167115627
              ],
              [
                -69.1728140236072,
                0.653181686040853
              ],
              [
                -69.17023251900584,
                0.652060175559031
              ],
              [
                -69.15617909845771,
                0.651697203575319
              ],
              [
                -69.14458965480748,
                0.645366211435214
              ],
              [
                -69.13303620220083,
                0.646690212508268
              ],
              [
                -69.12736624165707,
                0.640611239955117
              ],
              [
                -69.11621775893553,
                0.636036747374616
              ],
              [
                -69.11436476751962,
                0.650391176604656
              ],
              [
                -69.11987824838283,
                0.659622133607829
              ],
              [
                -69.12468524350182,
                0.667288133253865
              ],
              [
                -69.13063371726639,
                0.678114064478378
              ],
              [
                -69.14398014604392,
                0.702966954390149
              ],
              [
                -69.14842211220734,
                0.706503426741747
              ],
              [
                -69.15286307737628,
                0.71003993309701
              ],
              [
                -69.16581604966092,
                0.719355886689862
              ],
              [
                -69.16945853056892,
                0.720840866761516
              ],
              [
                -69.17956696192056,
                0.724962369315912
              ],
              [
                -69.18562394842402,
                0.733630824894067
              ],
              [
                -69.18654045244119,
                0.749758255854469
              ],
              [
                -69.17225449911163,
                0.763494191102972
              ],
              [
                -69.1676970331605,
                0.762404696196347
              ],
              [
                -69.16042406216184,
                0.760667199726924
              ],
              [
                -69.15184410402344,
                0.765758179760378
              ],
              [
                -69.14445014008064,
                0.783483072619749
              ],
              [
                -69.1465296179009,
                0.79181106008683
              ],
              [
                -69.14821358783371,
                0.797160032310127
              ],
              [
                -69.14867509262214,
                0.798625522069916
              ],
              [
                -69.14971861543675,
                0.803319983021629
              ],
              [
                -69.14975658423437,
                0.803593510850361
              ],
              [
                -69.15070110420395,
                0.810334466985588
              ],
              [
                -69.15202506725758,
                0.811510471107569
              ],
              [
                -69.15926257275238,
                0.81793992244494
              ],
              [
                -69.16534350068095,
                0.828911398100257
              ],
              [
                -69.16518702531027,
                0.84374431527292
              ],
              [
                -69.1654285290524,
                0.852306794464171
              ],
              [
                -69.15351157380283,
                0.854683274527311
              ],
              [
                -69.15221957747984,
                0.854481253211802
              ],
              [
                -69.14164513895477,
                0.852823251836803
              ],
              [
                -69.13569966535661,
                0.864115230329231
              ],
              [
                -69.13525514835348,
                0.877623144040944
              ],
              [
                -69.14325810600852,
                0.886787106721125
              ],
              [
                -69.15315758347083,
                0.888490095856324
              ],
              [
                -69.15370705605476,
                0.888584623530921
              ],
              [
                -69.1616150444723,
                0.895460080089789
              ],
              [
                -69.16208504935751,
                0.895869094398196
              ],
              [
                -69.16656249124557,
                0.899538047265864
              ],
              [
                -69.17103997813444,
                0.903207063137324
              ],
              [
                -69.18454291615092,
                0.903245530019452
              ],
              [
                -69.18421140969701,
                0.915865995621929
              ],
              [
                -69.18898390677437,
                0.922985944693544
              ],
              [
                -69.18981188358096,
                0.933252895598754
              ],
              [
                -69.1852584054589,
                0.939720856014886
              ],
              [
                -69.1778244556109,
                0.941732885168663
              ],
              [
                -69.17037399784287,
                0.943749355295333
              ],
              [
                -69.1629905286906,
                0.946404333672838
              ],
              [
                -69.17270644803263,
                0.960363792010765
              ],
              [
                -69.1828704180105,
                0.964300258675018
              ],
              [
                -69.19621185394506,
                0.969408237817852
              ],
              [
                -69.20526430888214,
                0.972874724353259
              ],
              [
                -69.21999572858651,
                0.985426153692147
              ],
              [
                -69.21166127675812,
                0.992622640294617
              ],
              [
                -69.20318833651743,
                0.999938583333033
              ],
              [
                -69.20529730755419,
                1.010510550090482
              ],
              [
                -69.21244328267218,
                1.02064900357717
              ],
              [
                -69.22433221115858,
                1.030440959392637
              ],
              [
                -69.23758565546356,
                1.041636905422454
              ],
              [
                -69.2450291189407,
                1.047925884615959
              ],
              [
                -69.24955309954817,
                1.051858860459934
              ],
              [
                -69.25282408080412,
                1.054702854779895
              ],
              [
                -69.26478904794385,
                1.064718302745284
              ],
              [
                -69.27088299220071,
                1.065943811555468
              ],
              [
                -69.27697696946336,
                1.067169304368126
              ],
              [
                -69.27714246675322,
                1.0671127936412
              ],
              [
                -69.28887541642347,
                1.063072343140702
              ],
              [
                -69.2949508986988,
                1.070437301523565
              ],
              [
                -69.29514739180205,
                1.070675785532222
              ],
              [
                -69.2970073856885,
                1.07082130924048
              ],
              [
                -69.30565385697882,
                1.071498280883407
              ],
              [
                -69.30732333741861,
                1.07881076071608
              ],
              [
                -69.30753881331292,
                1.079755746688178
              ],
              [
                -69.32120424797859,
                1.096133186706591
              ],
              [
                -69.32544175061557,
                1.095893700517767
              ],
              [
                -69.32967873925772,
                1.095654203328521
              ],
              [
                -69.34831112067305,
                1.093998704876707
              ],
              [
                -69.36049656672363,
                1.088930739890712
              ],
              [
                -69.37030704553729,
                1.092474216469932
              ],
              [
                -69.37872249350298,
                1.095514190964267
              ],
              [
                -69.38543545765329,
                1.083101240473795
              ],
              [
                -69.3912279325432,
                1.077590791167506
              ],
              [
                -69.39353593593593,
                1.075399801982901
              ],
              [
                -69.39425692494572,
                1.074653810312648
              ],
              [
                -69.39550991174906,
                1.073357788096937
              ],
              [
                -69.40332387035211,
                1.065213844417097
              ],
              [
                -69.41588082811565,
                1.053119385471959
              ],
              [
                -69.42523078024118,
                1.055480404020214
              ],
              [
                -69.43458023638262,
                1.057840868577707
              ],
              [
                -69.43898824076848,
                1.072267796613423
              ],
              [
                -69.43893122097042,
                1.07251832253065
              ],
              [
                -69.43699871980677,
                1.081026760751379
              ],
              [
                -69.44732920359033,
                1.084080743557528
              ],
              [
                -69.45640963261819,
                1.082548279771492
              ],
              [
                -69.46456760113465,
                1.083852777677254
              ],
              [
                -69.48236002278301,
                1.086697754760446
              ],
              [
                -69.48452699737119,
                1.087013763805913
              ],
              [
                -69.49309645215888,
                1.088264744027753
              ],
              [
                -69.50783190242484,
                1.083627276532556
              ],
              [
                -69.50866541887729,
                1.083364763805639
              ],
              [
                -69.5205903570318,
                1.08018278956166
              ],
              [
                -69.53082181593302,
                1.080195780366952
              ],
              [
                -69.54152576870422,
                1.080834804588626
              ],
              [
                -69.55501117955498,
                1.081277275157047
              ],
              [
                -69.56033866662163,
                1.081282778103698
              ],
              [
                -69.56566513569749,
                1.081287787052255
              ],
              [
                -69.58671604074084,
                1.089090776665629
              ],
              [
                -69.59243901927465,
                1.093841748490117
              ],
              [
                -69.60641546408895,
                1.105444705687664
              ],
              [
                -69.6209043893274,
                1.10287670930948
              ],
              [
                -69.62515386267117,
                1.099657214790458
              ],
              [
                -69.63054334028013,
                1.095574749145031
              ],
              [
                -69.63933428153194,
                1.095688234285636
              ],
              [
                -69.64866225049205,
                1.095808240498145
              ],
              [
                -69.65924519379593,
                1.092024767517616
              ],
              [
                -69.67080815993953,
                1.088112263249514
              ],
              [
                -69.67100214613681,
                1.087954296955416
              ],
              [
                -69.68406958807019,
                1.07728784658523
              ],
              [
                -69.6977675262143,
                1.083953301823332
              ],
              [
                -69.70532699908851,
                1.082664825664336
              ],
              [
                -69.70870147820507,
                1.090642787477723
              ],
              [
                -69.70823346357032,
                1.094150771525875
              ],
              [
                -69.70573098883615,
                1.112909160541763
              ],
              [
                -69.71038998833029,
                1.126694114964162
              ],
              [
                -69.72067741837579,
                1.121041630879114
              ],
              [
                -69.72404739626961,
                1.119393131502504
              ],
              [
                -69.72997139547637,
                1.116494149902973
              ],
              [
                -69.73446085489793,
                1.118561148992502
              ],
              [
                -69.7396108324423,
                1.120931646924084
              ],
              [
                -69.75397477740974,
                1.119088163442901
              ],
              [
                -69.757755244606,
                1.117238672983675
              ],
              [
                -69.76447024661563,
                1.113542171887714
              ],
              [
                -69.77616667696383,
                1.106614714374901
              ],
              [
                -69.78405114419957,
                1.101819717111215
              ],
              [
                -69.79457560132244,
                1.09076280376694
              ],
              [
                -69.79778159900813,
                1.083258309041035
              ],
              [
                -69.80173854779929,
                1.073993881864246
              ],
              [
                -69.80664752824646,
                1.071892391620041
              ],
              [
                -69.81155602369898,
                1.069791379369816
              ],
              [
                -69.82938844725047,
                1.072444886404486
              ],
              [
                -69.83350891062472,
                1.078464842777637
              ],
              [
                -69.83381540123919,
                1.078912347947156
              ],
              [
                -69.83570739695485,
                1.080180350947268
              ],
              [
                -69.8397448702776,
                1.082885351283483
              ],
              [
                -69.83974939125773,
                1.082888833269646
              ],
              [
                -69.83975789422053,
                1.08289434724797
              ],
              [
                -69.8397663901834,
                1.082899817226489
              ],
              [
                -69.84384588032367,
                1.085632837455268
              ],
              [
                -69.84434528389292,
                1.500303954935659
              ],
              [
                -69.84401376691707,
                1.575960585376874
              ],
              [
                -69.84150422878864,
                1.720918457086622
              ],
              [
                -69.82642331326451,
                1.716588473258981
              ],
              [
                -69.82861081420803,
                1.712165466839388
              ],
              [
                -69.8297788009049,
                1.709805483284931
              ],
              [
                -69.82106184721394,
                1.705701504352516
              ],
              [
                -69.80431439629679,
                1.71030849078218
              ],
              [
                -69.79454194998513,
                1.710283960067656
              ],
              [
                -69.78551299581666,
                1.705689005176323
              ],
              [
                -69.78440752073195,
                1.715525471652558
              ],
              [
                -69.78230749702274,
                1.717875432959421
              ],
              [
                -69.77708654563378,
                1.723718899360214
              ],
              [
                -69.77201203910455,
                1.724395410979732
              ],
              [
                -69.76843008126279,
                1.724872886887253
              ],
              [
                -69.76242560660876,
                1.729388385740954
              ],
              [
                -69.75696764370045,
                1.733633856903794
              ],
              [
                -69.75018814414403,
                1.738907843247582
              ],
              [
                -69.74248419299484,
                1.742623811006107
              ],
              [
                -69.72749523873013,
                1.747299799476397
              ],
              [
                -69.71845678804299,
                1.745078808376418
              ],
              [
                -69.71643180685241,
                1.744581283922954
              ],
              [
                -69.71458332328372,
                1.746229803280394
              ],
              [
                -69.7106963239104,
                1.749697277201201
              ],
              [
                -69.69999039526937,
                1.744448281660443
              ],
              [
                -69.68292644748132,
                1.747473766598175
              ],
              [
                -69.68186695076272,
                1.741474795151397
              ],
              [
                -69.68093745727009,
                1.736206819589734
              ],
              [
                -69.66893651596813,
                1.73876380304965
              ],
              [
                -69.65682358728642,
                1.737462327116347
              ],
              [
                -69.64527061200425,
                1.743835779217295
              ],
              [
                -69.6358656681685,
                1.737622326289588
              ],
              [
                -69.62923319378136,
                1.744051280574148
              ],
              [
                -69.62850918776446,
                1.747926774616281
              ],
              [
                -69.62794471130839,
                1.750950237384979
              ],
              [
                -69.62083273430734,
                1.752817719214466
              ],
              [
                -69.62025223375629,
                1.752970244383721
              ],
              [
                -69.60224483771084,
                1.764660685486816
              ],
              [
                -69.58128343069745,
                1.77197064559658
              ],
              [
                -69.57708392916851,
                1.773992615583583
              ],
              [
                -69.57342193140104,
                1.775770139787331
              ],
              [
                -69.56678197134288,
                1.777919096487165
              ],
              [
                -69.56436547302843,
                1.780340607266245
              ],
              [
                -69.56109249114645,
                1.783621591058903
              ],
              [
                -69.55780602237266,
                1.786734047573658
              ],
              [
                -69.55167303024382,
                1.791909544275617
              ],
              [
                -69.51866870013687,
                1.77959107282408
              ],
              [
                -69.51511422218802,
                1.77714909836555
              ],
              [
                -69.51155923824268,
                1.774706093913639
              ],
              [
                -69.50311077515761,
                1.773125100259123
              ],
              [
                -69.50184178770314,
                1.772888127910017
              ],
              [
                -69.50128926219749,
                1.772514615368077
              ],
              [
                -69.494232829054,
                1.767748619975704
              ],
              [
                -69.49182732996901,
                1.769343106330149
              ],
              [
                -69.48719884204841,
                1.772411114614652
              ],
              [
                -69.48666534576326,
                1.767612624315508
              ],
              [
                -69.48609384573989,
                1.762467656509445
              ],
              [
                -69.47820289230323,
                1.760804666399246
              ],
              [
                -69.47556791037792,
                1.765265121223025
              ],
              [
                -69.4735824219679,
                1.768626108016086
              ],
              [
                -69.46945244011084,
                1.764195130060384
              ],
              [
                -69.46777593288154,
                1.762396160387647
              ],
              [
                -69.4568849696605,
                1.759966665729459
              ],
              [
                -69.45458999510527,
                1.761594157967655
              ],
              [
                -69.45043800919339,
                1.764539142918852
              ],
              [
                -69.44576402684281,
                1.763042643046216
              ],
              [
                -69.44043755845625,
                1.761072629044091
              ],
              [
                -69.43137762533124,
                1.758745659494991
              ],
              [
                -69.42546562234182,
                1.757283672114656
              ],
              [
                -69.41670317950357,
                1.753056166945494
              ],
              [
                -69.41159618726228,
                1.747391697123869
              ],
              [
                -69.39881175312216,
                1.738799226822336
              ],
              [
                -69.39308678100322,
                1.735068737411011
              ],
              [
                -69.39295677624428,
                1.732714763634369
              ],
              [
                -69.39252931232046,
                1.724967815276091
              ],
              [
                -69.38415283316644,
                1.730051280682779
              ],
              [
                -69.17804677503396,
                1.733878201952368
              ],
              [
                -69.10400161908473,
                1.733610169586653
              ],
              [
                -68.99931761577888,
                1.733245154765504
              ],
              [
                -68.50317994095556,
                1.732550020029654
              ],
              [
                -68.15617562715404,
                1.73211043780056
              ],
              [
                -68.15726961427832,
                1.742301376582344
              ],
              [
                -68.15873708325111,
                1.750545347358551
              ],
              [
                -68.16373406131814,
                1.751433854757489
              ],
              [
                -68.17852699145152,
                1.754063347069719
              ],
              [
                -68.17456301625202,
                1.761601802495214
              ],
              [
                -68.17253002148777,
                1.765003776364143
              ],
              [
                -68.16889253922099,
                1.771088761501852
              ],
              [
                -68.17214401785935,
                1.773184749887492
              ],
              [
                -68.17520050938862,
                1.775261717304182
              ],
              [
                -68.18030748299792,
                1.779463704625009
              ],
              [
                -68.18708846559066,
                1.785123172793264
              ],
              [
                -68.20187585708618,
                1.786746186782089
              ],
              [
                -68.20842033079862,
                1.778678226539611
              ],
              [
                -68.20878183720717,
                1.77851973436979
              ],
              [
                -68.21306382235724,
                1.776646746181255
              ],
              [
                -68.2196623063143,
                1.773761740289071
              ],
              [
                -68.23595570178722,
                1.772950249801333
              ],
              [
                -68.23855771124069,
                1.779011220951533
              ],
              [
                -68.23977871185215,
                1.781957725886095
              ],
              [
                -68.24431916836642,
                1.795349644205254
              ],
              [
                -68.24343066158319,
                1.798067651550404
              ],
              [
                -68.23745471627738,
                1.80935107030925
              ],
              [
                -68.23329420021544,
                1.822382501607605
              ],
              [
                -68.23285271479546,
                1.830431489745646
              ],
              [
                -68.24353966204367,
                1.831714468151954
              ],
              [
                -68.2454456415199,
                1.831382467248046
              ],
              [
                -68.26626857143435,
                1.827759514114829
              ],
              [
                -68.26604157082056,
                1.846416906993161
              ],
              [
                -68.26288206461788,
                1.861129323918507
              ],
              [
                -68.25254909578122,
                1.879221737133168
              ],
              [
                -68.24262315067442,
                1.927168511930227
              ],
              [
                -68.23876416136804,
                1.932742991199048
              ],
              [
                -68.23490466806142,
                1.938317481457184
              ],
              [
                -68.22485523351259,
                1.944721928901999
              ],
              [
                -68.22298822958611,
                1.945911939850196
              ],
              [
                -68.22072323613018,
                1.948138894921435
              ],
              [
                -68.22037872854985,
                1.948577389802205
              ],
              [
                -68.21659874812805,
                1.953382378573568
              ],
              [
                -68.21032029851295,
                1.959772850258857
              ],
              [
                -68.20798730636395,
                1.962002833280875
              ],
              [
                -68.20139733836054,
                1.961587852029208
              ],
              [
                -68.19821385585384,
                1.961387320908264
              ],
              [
                -68.19414735089074,
                1.965710815761068
              ],
              [
                -68.18779838448997,
                1.972460272732152
              ],
              [
                -68.18218740172456,
                1.979261726688948
              ],
              [
                -68.16553149234704,
                1.985213694875975
              ],
              [
                -68.16006701764772,
                1.980613226193994
              ],
              [
                -68.15778104265057,
                1.978688740277026
              ],
              [
                -68.15737754759282,
                1.978348731705328
              ],
              [
                -68.15541304225556,
                1.979133244455786
              ],
              [
                -68.14259710978762,
                1.984235720790069
              ],
              [
                -68.13932212224528,
                1.985516206824077
              ],
              [
                -68.13327413356738,
                1.979850728864721
              ],
              [
                -68.12849268629536,
                1.97368976660687
              ],
              [
                -68.12598519571364,
                1.970533278291224
              ],
              [
                -68.12194419147272,
                1.966227771756317
              ],
              [
                -68.11830973613688,
                1.956476842386104
              ],
              [
                -68.11388427006176,
                1.94460541250604
              ],
              [
                -68.10817579926795,
                1.940690906650221
              ],
              [
                -68.10246728947688,
                1.936776946797692
              ],
              [
                -68.09831632619262,
                1.924692990003576
              ],
              [
                -68.0934968465401,
                1.910662066944864
              ],
              [
                -68.08669989423335,
                1.901835607348864
              ],
              [
                -68.07861743567408,
                1.902946094686775
              ],
              [
                -68.064685003553,
                1.898337113472225
              ],
              [
                -68.05373955313746,
                1.899224578933112
              ],
              [
                -68.0427941237417,
                1.900111579393066
              ],
              [
                -68.02405219663025,
                1.885858643102961
              ],
              [
                -68.00348529920463,
                1.86072975540149
              ],
              [
                -67.97292746611248,
                1.833194911841967
              ],
              [
                -67.96823298870291,
                1.832739408510313
              ],
              [
                -67.95997701068201,
                1.831937400691835
              ],
              [
                -67.9460000907731,
                1.831347901154693
              ],
              [
                -67.94056510468357,
                1.831186370245587
              ],
              [
                -67.93011015929963,
                1.835251867305486
              ],
              [
                -67.92326819390497,
                1.84423431375652
              ],
              [
                -67.92217669984063,
                1.851032796033723
              ],
              [
                -67.9219126865487,
                1.852680294345256
              ],
              [
                -67.91619674213173,
                1.854611281667281
              ],
              [
                -67.91122025640914,
                1.856292245368491
              ],
              [
                -67.90604376995908,
                1.86663770096464
              ],
              [
                -67.90738576597232,
                1.872635195661857
              ],
              [
                -67.90932473942199,
                1.881303140200081
              ],
              [
                -67.91023124492197,
                1.898550540741337
              ],
              [
                -67.90111778928582,
                1.899780051179326
              ],
              [
                -67.88995183582595,
                1.901793044339484
              ],
              [
                -67.88655537620326,
                1.902405031433904
              ],
              [
                -67.88192440215583,
                1.90329404385484
              ],
              [
                -67.85887449441844,
                1.920702947890919
              ],
              [
                -67.85496000698913,
                1.925118903169616
              ],
              [
                -67.85466652323481,
                1.925439395589861
              ],
              [
                -67.85409151667518,
                1.926067920491826
              ],
              [
                -67.84985503865344,
                1.930703894637497
              ],
              [
                -67.84118207366382,
                1.939545323835748
              ],
              [
                -67.83499560308209,
                1.945793804841245
              ],
              [
                -67.83362510590267,
                1.950407284981345
              ],
              [
                -67.83146160850691,
                1.957687724475654
              ],
              [
                -67.82258214533923,
                1.96378269927576
              ],
              [
                -67.81739967300689,
                1.967340185055173
              ],
              [
                -67.8125096816874,
                1.975982653285527
              ],
              [
                -67.8116071873186,
                1.97757815257205
              ],
              [
                -67.80685870950191,
                1.98572011315091
              ],
              [
                -67.80458072783883,
                1.989207069181298
              ],
              [
                -67.77876233266912,
                2.031483871733246
              ],
              [
                -67.76827240819138,
                2.039764301590525
              ],
              [
                -67.74967499113515,
                2.041871807462799
              ],
              [
                -67.7238255986755,
                2.034689338249446
              ],
              [
                -67.69680725751687,
                2.025081381036039
              ],
              [
                -67.69179275702119,
                2.024003882388222
              ],
              [
                -67.686777278534,
                2.0229263847418
              ],
              [
                -67.66930939974738,
                2.021312376432149
              ],
              [
                -67.65880394445539,
                2.020821873206797
              ],
              [
                -67.6537129650658,
                2.020584860610474
              ],
              [
                -67.64900598071397,
                2.02092535544018
              ],
              [
                -67.64178900091342,
                2.021446356583337
              ],
              [
                -67.62516010019577,
                2.023390363904926
              ],
              [
                -67.61913862981962,
                2.024123859448661
              ],
              [
                -67.60644167970392,
                2.035848799216143
              ],
              [
                -67.59739123355433,
                2.056942684193952
              ],
              [
                -67.58491026136704,
                2.086476550232251
              ],
              [
                -67.57818781147135,
                2.092480002694966
              ],
              [
                -67.57146584757537,
                2.098482960143093
              ],
              [
                -67.55743439967821,
                2.107114915657583
              ],
              [
                -67.55600391383487,
                2.114293872377882
              ],
              [
                -67.5545734209837,
                2.121472370091477
              ],
              [
                -67.55024090586431,
                2.142174721088843
              ],
              [
                -67.54475444182839,
                2.149285190644642
              ],
              [
                -67.53926849178696,
                2.156395682182421
              ],
              [
                -67.52126257132261,
                2.170731585133264
              ],
              [
                -67.49867264423106,
                2.182145517119268
              ],
              [
                -67.4785582741356,
                2.192307970822275
              ],
              [
                -67.47016678563058,
                2.196623461371563
              ],
              [
                -67.4701418007422,
                2.196636462301015
              ],
              [
                -67.4617248323599,
                2.20096490676598
              ],
              [
                -67.44750342284675,
                2.208534396757267
              ],
              [
                -67.44257893323521,
                2.221095309478962
              ],
              [
                -67.44123091220084,
                2.224710288861943
              ],
              [
                -67.4400349196054,
                2.22791629323532
              ],
              [
                -67.43784344367194,
                2.233792235589097
              ],
              [
                -67.43012699059149,
                2.242089691409651
              ],
              [
                -67.40687260734344,
                2.246621157286132
              ],
              [
                -67.40398409893056,
                2.246309683690653
              ],
              [
                -67.38870467391487,
                2.244417179998263
              ],
              [
                -67.36392629961179,
                2.230723747853965
              ],
              [
                -67.34292941273209,
                2.205865372238467
              ],
              [
                -67.34248943935798,
                2.198722889007979
              ],
              [
                -67.3411194217076,
                2.176485514118101
              ],
              [
                -67.33967197277931,
                2.152994623107586
              ],
              [
                -67.33799796009917,
                2.125822269432713
              ],
              [
                -67.33636247690188,
                2.099280900717194
              ],
              [
                -67.33631400439947,
                2.098490378447896
              ],
              [
                -67.33570798882856,
                2.096750897483747
              ],
              [
                -67.32939203354353,
                2.0786139822732
              ],
              [
                -67.32905702141055,
                2.071825539330367
              ],
              [
                -67.32900751997364,
                2.070845026959919
              ],
              [
                -67.32897654632713,
                2.070228027873133
              ],
              [
                -67.32856454200997,
                2.062058584442233
              ],
              [
                -67.32681403136377,
                2.043658680011101
              ],
              [
                -67.32553004987152,
                2.030119216697711
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 4,
        "nome": "Amapá",
        "sigla": "AP",
        "regiao_id": 3,
        "codigo_ibg": 16
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -51.181678246202495,
                4.008886561940245
              ],
              [
                -51.178999267092955,
                3.998118632982843
              ],
              [
                -51.176320765964235,
                3.987351748014373
              ],
              [
                -51.17601480199111,
                3.976449329324321
              ],
              [
                -51.17587729659343,
                3.971549368415103
              ],
              [
                -51.1757512808979,
                3.967047386572766
              ],
              [
                -51.17565479641848,
                3.963614921851817
              ],
              [
                -51.17557681366984,
                3.960436925261997
              ],
              [
                -51.176226304827445,
                3.957090451305455
              ],
              [
                -51.176506811601115,
                3.955641956144496
              ],
              [
                -51.17794977828002,
                3.948206527780023
              ],
              [
                -51.17854578866932,
                3.945131031789512
              ],
              [
                -51.1662953856167,
                3.936318614247759
              ],
              [
                -51.158563440107066,
                3.931790130529641
              ],
              [
                -51.158032421055836,
                3.931478659544363
              ],
              [
                -51.14923799545245,
                3.926327671861906
              ],
              [
                -51.134340600245466,
                3.917602226323121
              ],
              [
                -51.12847216189412,
                3.914165257571229
              ],
              [
                -51.12260420754263,
                3.910727286831599
              ],
              [
                -51.101568358765235,
                3.910348750461639
              ],
              [
                -51.09851936854362,
                3.910294272246109
              ],
              [
                -51.09801438715103,
                3.910284756048411
              ],
              [
                -51.093956404138666,
                3.910212268430736
              ],
              [
                -51.08241651530155,
                3.899117339260797
              ],
              [
                -51.080680989996715,
                3.892797383020749
              ],
              [
                -51.07514905346358,
                3.890906408066627
              ],
              [
                -51.07484957982602,
                3.878987994931875
              ],
              [
                -51.07463656674236,
                3.870549070137086
              ],
              [
                -51.07426756198607,
                3.85589615613167
              ],
              [
                -51.0742015919836,
                3.851018710104543
              ],
              [
                -51.07554957189399,
                3.84612874590526
              ],
              [
                -51.07650258188066,
                3.842670255930299
              ],
              [
                -51.0772920737259,
                3.83980627148032
              ],
              [
                -51.079375051760046,
                3.832248360342593
              ],
              [
                -51.07941105795129,
                3.817764454236405
              ],
              [
                -51.07942155308109,
                3.813466490951964
              ],
              [
                -51.07943606911456,
                3.807342539138404
              ],
              [
                -51.079483559742506,
                3.787819673166132
              ],
              [
                -51.07961157283176,
                3.783884682252259
              ],
              [
                -51.081663552717885,
                3.77891372496249
              ],
              [
                -51.0820295405569,
                3.778027241686904
              ],
              [
                -51.084236056527644,
                3.772681280235021
              ],
              [
                -51.082756056538656,
                3.765868825704762
              ],
              [
                -51.08185355107823,
                3.761717356850087
              ],
              [
                -51.083912558726155,
                3.755110914600992
              ],
              [
                -51.08545852820006,
                3.750148959962275
              ],
              [
                -51.08506905089861,
                3.744352483486084
              ],
              [
                -51.08485406603685,
                3.741163532879151
              ],
              [
                -51.08462807334088,
                3.737798032566897
              ],
              [
                -51.084264581649414,
                3.732390104234826
              ],
              [
                -51.08683203268967,
                3.727691121158823
              ],
              [
                -51.087304036755704,
                3.726826638767039
              ],
              [
                -51.08937354488928,
                3.72303965271418
              ],
              [
                -51.08953702920883,
                3.716068698151513
              ],
              [
                -51.089805025998004,
                3.704639309475446
              ],
              [
                -51.09009155802412,
                3.692430892531658
              ],
              [
                -51.09037753603409,
                3.680222462571816
              ],
              [
                -51.082319637791066,
                3.632858814221094
              ],
              [
                -51.07907516580847,
                3.593994614573988
              ],
              [
                -51.07809767742414,
                3.582292195172329
              ],
              [
                -51.0774856953161,
                3.574959238804727
              ],
              [
                -51.07675619571359,
                3.566218834725458
              ],
              [
                -51.074900234574315,
                3.543984992300213
              ],
              [
                -51.07135577795046,
                3.501536302579125
              ],
              [
                -51.07103275507435,
                3.497666345859666
              ],
              [
                -51.07093126537034,
                3.496448847756636
              ],
              [
                -51.07079125115742,
                3.494770366021988
              ],
              [
                -51.07054525229577,
                3.491822388563586
              ],
              [
                -51.07413925940441,
                3.477408464142544
              ],
              [
                -51.07532223218001,
                3.472665496534383
              ],
              [
                -51.07774172544243,
                3.46296159693243
              ],
              [
                -51.07855724014705,
                3.45968960734017
              ],
              [
                -51.079477221549524,
                3.459766122210201
              ],
              [
                -51.08287268520116,
                3.460047112741138
              ],
              [
                -51.088868655164944,
                3.454179668627108
              ],
              [
                -51.09012814245785,
                3.447117194054886
              ],
              [
                -51.088071673655556,
                3.409051996409445
              ],
              [
                -51.08715319896907,
                3.392058618634243
              ],
              [
                -51.0860802139906,
                3.389051135198206
              ],
              [
                -51.08079022944947,
                3.374229261471382
              ],
              [
                -51.077604756207634,
                3.365305299455759
              ],
              [
                -51.07326779662287,
                3.353154884573385
              ],
              [
                -51.06990584983968,
                3.343734474117003
              ],
              [
                -51.0695358339444,
                3.342697988548434
              ],
              [
                -51.0654343823515,
                3.331205555947809
              ],
              [
                -51.060463400030706,
                3.317278140804876
              ],
              [
                -51.058545412105346,
                3.311905217327434
              ],
              [
                -51.05828293230503,
                3.311169687601047
              ],
              [
                -51.05584046877052,
                3.304325251674235
              ],
              [
                -51.051690499529506,
                3.292697329043386
              ],
              [
                -51.04842151389742,
                3.283538893706475
              ],
              [
                -51.04623801817222,
                3.277420951570129
              ],
              [
                -51.044243533861064,
                3.271832487637488
              ],
              [
                -51.04399506193982,
                3.271136483627535
              ],
              [
                -51.04129408553365,
                3.263568052890462
              ],
              [
                -51.03992909894949,
                3.259742575317761
              ],
              [
                -51.03813761092896,
                3.254723130305121
              ],
              [
                -51.034157127200054,
                3.243571695255767
              ],
              [
                -51.03098116573592,
                3.23467325805323
              ],
              [
                -51.03022615904256,
                3.232558273216541
              ],
              [
                -51.02907767663521,
                3.229340316287672
              ],
              [
                -51.02668818858855,
                3.222646852276057
              ],
              [
                -51.021597235083924,
                3.208381466550919
              ],
              [
                -51.02439172448838,
                3.19358956809494
              ],
              [
                -51.02596222583431,
                3.185279116625009
              ],
              [
                -51.02696172126754,
                3.183951132779451
              ],
              [
                -51.02881321110202,
                3.18149314957374
              ],
              [
                -51.03354214802112,
                3.175232197443604
              ],
              [
                -51.03142169958058,
                3.157753835455781
              ],
              [
                -51.0312116868118,
                3.156024835018894
              ],
              [
                -51.03298218454211,
                3.148065915014978
              ],
              [
                -51.03336118855833,
                3.146358418670199
              ],
              [
                -51.03395418044972,
                3.143696429399709
              ],
              [
                -51.03530015539447,
                3.137644976245359
              ],
              [
                -51.02737122458078,
                3.117150110805201
              ],
              [
                -51.00665739306204,
                3.094068774921013
              ],
              [
                -51.006156898583924,
                3.079699391814463
              ],
              [
                -51.009497394702905,
                3.072502947835274
              ],
              [
                -51.012428851375134,
                3.066186009244114
              ],
              [
                -51.01437384686047,
                3.045763633342905
              ],
              [
                -51.01049488778077,
                3.040300696671991
              ],
              [
                -51.01013937952298,
                3.039799706187226
              ],
              [
                -51.00554840593187,
                3.033334726550609
              ],
              [
                -51.00345845715617,
                3.027668278107604
              ],
              [
                -50.99987996864002,
                3.017963842566342
              ],
              [
                -50.99416850431945,
                3.010797878603924
              ],
              [
                -50.98845756899213,
                3.003631941647594
              ],
              [
                -50.9843215990244,
                2.974947162576198
              ],
              [
                -50.985923082182666,
                2.968429691834539
              ],
              [
                -50.9859586049872,
                2.968283196918969
              ],
              [
                -50.98763257773789,
                2.961474750324429
              ],
              [
                -50.98076561734119,
                2.937290931203741
              ],
              [
                -50.978563642123994,
                2.934608960913275
              ],
              [
                -50.97247119357122,
                2.927151513953461
              ],
              [
                -50.97142171557201,
                2.925866497921477
              ],
              [
                -50.96713775592987,
                2.916274596271318
              ],
              [
                -50.96510024126722,
                2.911712627779543
              ],
              [
                -50.962435274632426,
                2.90574516730393
              ],
              [
                -50.94390440729452,
                2.88448529227895
              ],
              [
                -50.94196042470101,
                2.854526534246834
              ],
              [
                -50.944515908886856,
                2.845488605198827
              ],
              [
                -50.94707192105955,
                2.836449641144055
              ],
              [
                -50.9409974737262,
                2.814632830999114
              ],
              [
                -50.94138795094621,
                2.809292856103783
              ],
              [
                -50.94178696915118,
                2.803820422174433
              ],
              [
                -50.933531006192545,
                2.806393861265737
              ],
              [
                -50.92649557165972,
                2.808587851583907
              ],
              [
                -50.907788720196365,
                2.801179924542816
              ],
              [
                -50.90151675439799,
                2.794913949901357
              ],
              [
                -50.90015976350389,
                2.785807020855178
              ],
              [
                -50.90172376176353,
                2.781926552762966
              ],
              [
                -50.90309124524553,
                2.778534076032913
              ],
              [
                -50.90494823267724,
                2.773925111360029
              ],
              [
                -50.90419472750467,
                2.767347649065983
              ],
              [
                -50.903762754840486,
                2.763979685475195
              ],
              [
                -50.90268326128786,
                2.756596232934396
              ],
              [
                -50.89536332858041,
                2.754049753782077
              ],
              [
                -50.89118582599727,
                2.752596751826422
              ],
              [
                -50.8907108574558,
                2.752431250857003
              ],
              [
                -50.8813774261638,
                2.741716854560489
              ],
              [
                -50.88166543395616,
                2.711962044713982
              ],
              [
                -50.881917949550704,
                2.685920263693312
              ],
              [
                -50.875061500709805,
                2.682844760625832
              ],
              [
                -50.86820704985919,
                2.67976979856016
              ],
              [
                -50.852736659678996,
                2.659880923994996
              ],
              [
                -50.85170665713363,
                2.654019471362686
              ],
              [
                -50.85150716996487,
                2.652882461581155
              ],
              [
                -50.8500636772051,
                2.644671029931053
              ],
              [
                -50.84793220091076,
                2.643403570409418
              ],
              [
                -50.84056876317082,
                2.639025096702573
              ],
              [
                -50.841008270094335,
                2.601705358886224
              ],
              [
                -50.84187826244302,
                2.600045381288424
              ],
              [
                -50.84460423273405,
                2.59484390814589
              ],
              [
                -50.84244973194208,
                2.586802480978732
              ],
              [
                -50.83977978268141,
                2.582097509323384
              ],
              [
                -50.83889877852409,
                2.580545513322721
              ],
              [
                -50.83713580016913,
                2.577577041334091
              ],
              [
                -50.835434790333856,
                2.574712542610162
              ],
              [
                -50.83109236260428,
                2.533164363719324
              ],
              [
                -50.83298385286655,
                2.524862924807999
              ],
              [
                -50.833950829372185,
                2.520623959997778
              ],
              [
                -50.835187814342305,
                2.51519547993505
              ],
              [
                -50.83662730515658,
                2.508879524395481
              ],
              [
                -50.83669832275238,
                2.508568554682844
              ],
              [
                -50.85242319457705,
                2.504433063105415
              ],
              [
                -50.852902223124644,
                2.501515591493421
              ],
              [
                -50.853443689450316,
                2.497921140826261
              ],
              [
                -50.854452193522526,
                2.491053165126863
              ],
              [
                -50.85170423064002,
                2.486692193923492
              ],
              [
                -50.85052420727007,
                2.49514963000309
              ],
              [
                -50.84424375517636,
                2.498288108048881
              ],
              [
                -50.836209845424484,
                2.493082165218659
              ],
              [
                -50.82903439207914,
                2.498238112276085
              ],
              [
                -50.82236543023219,
                2.503030089141927
              ],
              [
                -50.814233971510944,
                2.508873041849033
              ],
              [
                -50.81367999334515,
                2.50927151475984
              ],
              [
                -50.80800351913792,
                2.508871539747712
              ],
              [
                -50.80232706393488,
                2.508471523736699
              ],
              [
                -50.791040638196726,
                2.501297570166714
              ],
              [
                -50.78909065533958,
                2.497790624051353
              ],
              [
                -50.78356120831529,
                2.48771769155431
              ],
              [
                -50.78326719769408,
                2.48388522736755
              ],
              [
                -50.783211191335305,
                2.483161728617871
              ],
              [
                -50.78293019955827,
                2.479511230108886
              ],
              [
                -50.78271223005849,
                2.476675273688317
              ],
              [
                -50.77592426559828,
                2.467911322242554
              ],
              [
                -50.76798332388618,
                2.457659401258173
              ],
              [
                -50.76569484121998,
                2.451770431383501
              ],
              [
                -50.76436236595998,
                2.448136482404463
              ],
              [
                -50.7639748674927,
                2.439690010778069
              ],
              [
                -50.76348635745383,
                2.42904210313848
              ],
              [
                -50.770094836567935,
                2.412064235872931
              ],
              [
                -50.76068239958042,
                2.397912324801754
              ],
              [
                -50.75978589703586,
                2.394695856917699
              ],
              [
                -50.75886340870588,
                2.391386385701771
              ],
              [
                -50.75633993885272,
                2.381790931708045
              ],
              [
                -50.756791411547,
                2.378859447182027
              ],
              [
                -50.75724593822544,
                2.375905495819133
              ],
              [
                -50.75967242587445,
                2.366282544598948
              ],
              [
                -50.75015447529884,
                2.359347621008717
              ],
              [
                -50.74885200793631,
                2.358398609497594
              ],
              [
                -50.745779506560275,
                2.350142676577679
              ],
              [
                -50.74399351987024,
                2.345342718926565
              ],
              [
                -50.74344053563445,
                2.336379287946888
              ],
              [
                -50.743246027172276,
                2.332708814580401
              ],
              [
                -50.74317806206216,
                2.331414797969905
              ],
              [
                -50.742719060045246,
                2.322743364885567
              ],
              [
                -50.74229757253041,
                2.314780930651117
              ],
              [
                -50.74212957271627,
                2.311600946719713
              ],
              [
                -50.74018006738798,
                2.309355969434205
              ],
              [
                -50.737081088710184,
                2.305787011417758
              ],
              [
                -50.73273962538188,
                2.300788014414626
              ],
              [
                -50.725605666447805,
                2.284770132664366
              ],
              [
                -50.723332218309245,
                2.279664182084434
              ],
              [
                -50.72205171349644,
                2.276366218666333
              ],
              [
                -50.722356701218786,
                2.273365715572222
              ],
              [
                -50.722756697542415,
                2.269426747327726
              ],
              [
                -50.724020206232744,
                2.256987854121608
              ],
              [
                -50.72179773201234,
                2.237505480018001
              ],
              [
                -50.71676976384647,
                2.238290994792044
              ],
              [
                -50.712388310358435,
                2.234673510756708
              ],
              [
                -50.70804883655975,
                2.231090021489952
              ],
              [
                -50.703057347550676,
                2.223763580230382
              ],
              [
                -50.700559383561654,
                2.220096608128822
              ],
              [
                -50.69285096022001,
                2.208782697041383
              ],
              [
                -50.685561508260925,
                2.189256856756958
              ],
              [
                -50.683734506693916,
                2.181292910079203
              ],
              [
                -50.68190805111479,
                2.173328957399085
              ],
              [
                -50.68318600929791,
                2.154750574714525
              ],
              [
                -50.68782898175399,
                2.148534646200265
              ],
              [
                -50.68825550485642,
                2.147962655477061
              ],
              [
                -50.695860423874706,
                2.145875161835265
              ],
              [
                -50.70181490260976,
                2.144253169332709
              ],
              [
                -50.70192089652125,
                2.141900174442876
              ],
              [
                -50.702066889397834,
                2.138660220001681
              ],
              [
                -50.69867790742393,
                2.139684705587533
              ],
              [
                -50.68802050085697,
                2.143367159775862
              ],
              [
                -50.669918628013356,
                2.149622144111923
              ],
              [
                -50.66533517446723,
                2.151213095226339
              ],
              [
                -50.57905325659184,
                2.189018826070226
              ],
              [
                -50.53220061702978,
                2.205043190496992
              ],
              [
                -50.526871641857596,
                2.206866670625467
              ],
              [
                -50.52154318068547,
                2.20868915175855
              ],
              [
                -50.502846337118065,
                2.205983185937203
              ],
              [
                -50.493490903902135,
                2.20462918903728
              ],
              [
                -50.484136961686836,
                2.203275197141407
              ],
              [
                -50.46430011445151,
                2.203904184677766
              ],
              [
                -50.45578116681539,
                2.204046676115097
              ],
              [
                -50.451070699387195,
                2.201946695047159
              ],
              [
                -50.44594473101104,
                2.199661710209025
              ],
              [
                -50.44486474088412,
                2.195937708070953
              ],
              [
                -50.443073252598154,
                2.189762758611076
              ],
              [
                -50.440295293061894,
                2.19171223756519
              ],
              [
                -50.437741314956426,
                2.193505722722093
              ],
              [
                -50.43179882827444,
                2.188469767733493
              ],
              [
                -50.429099845403165,
                2.186182790634979
              ],
              [
                -50.42198591345945,
                2.180154345556769
              ],
              [
                -50.419159437532926,
                2.174319383325744
              ],
              [
                -50.417365465540996,
                2.170616415833438
              ],
              [
                -50.41722595963054,
                2.17032740890249
              ],
              [
                -50.41556945956339,
                2.166909443370216
              ],
              [
                -50.41204898304587,
                2.159641974396403
              ],
              [
                -50.391977147222455,
                2.13383766096683
              ],
              [
                -50.3852696840667,
                2.125215239987941
              ],
              [
                -50.3853121880443,
                2.124205727368839
              ],
              [
                -50.385517195930845,
                2.119348793877918
              ],
              [
                -50.37996474081507,
                2.116476797262315
              ],
              [
                -50.37817974595883,
                2.115554294489018
              ],
              [
                -50.37652077693424,
                2.111986312062084
              ],
              [
                -50.37564879075378,
                2.110110852504255
              ],
              [
                -50.37447927889995,
                2.107594354541316
              ],
              [
                -50.371519293044976,
                2.101226915180529
              ],
              [
                -50.370037311716956,
                2.101324893047615
              ],
              [
                -50.36557383986042,
                2.101621396624872
              ],
              [
                -50.351989432979956,
                2.087118987671813
              ],
              [
                -50.34949646763268,
                2.077520582039574
              ],
              [
                -50.35186145883177,
                2.072867622658924
              ],
              [
                -50.355435913945975,
                2.065836674961647
              ],
              [
                -50.34881900164016,
                2.062517696349748
              ],
              [
                -50.34540450972491,
                2.053629234270917
              ],
              [
                -50.347155498786776,
                2.051023270768724
              ],
              [
                -50.34300003249206,
                2.044788820118508
              ],
              [
                -50.337101576528376,
                2.035799359097243
              ],
              [
                -50.33542009245946,
                2.029171435998359
              ],
              [
                -50.333942102700746,
                2.023342977120508
              ],
              [
                -50.3331830859851,
                2.020351502739248
              ],
              [
                -50.32443517992402,
                2.013848034820714
              ],
              [
                -50.31668123048782,
                2.008083584788749
              ],
              [
                -50.31619772585601,
                2.004793091667026
              ],
              [
                -50.31361225296219,
                1.988063701023202
              ],
              [
                -50.31196578565728,
                1.977410806290904
              ],
              [
                -50.30802831708618,
                1.973692842366714
              ],
              [
                -50.307262786816324,
                1.972937318675078
              ],
              [
                -50.30569581454516,
                1.971391848533532
              ],
              [
                -50.30015888181371,
                1.958907440133768
              ],
              [
                -50.29900888788157,
                1.955992444092437
              ],
              [
                -50.272919554866775,
                1.887741939026113
              ],
              [
                -50.23771484408046,
                1.803558046832327
              ],
              [
                -50.23077190906336,
                1.80464604122804
              ],
              [
                -50.22382995304591,
                1.805732037636641
              ],
              [
                -50.19478317921544,
                1.810434020331115
              ],
              [
                -50.19111117166613,
                1.811027488113727
              ],
              [
                -50.181053260124905,
                1.812655992798542
              ],
              [
                -50.16916033782072,
                1.814580460874535
              ],
              [
                -50.1460275113929,
                1.806483024362434
              ],
              [
                -50.13902758071678,
                1.804033527551098
              ],
              [
                -50.13717606929323,
                1.803385042837998
              ],
              [
                -50.13388212344732,
                1.802232061460204
              ],
              [
                -50.10543282111414,
                1.792273615332614
              ],
              [
                -50.098363892457655,
                1.791997128659366
              ],
              [
                -50.09129493480783,
                1.791721111983367
              ],
              [
                -50.072658080137884,
                1.78285718628438
              ],
              [
                -50.05585019302022,
                1.774863209699074
              ],
              [
                -50.04460074916366,
                1.77288074352279
              ],
              [
                -50.03335383330465,
                1.770899261345946
              ],
              [
                -49.92129969521627,
                1.704260233758885
              ],
              [
                -49.91460325483819,
                1.695828277943994
              ],
              [
                -49.91285026239401,
                1.687751841646353
              ],
              [
                -49.91510324728765,
                1.681919372825069
              ],
              [
                -49.91686821845076,
                1.677350915641243
              ],
              [
                -49.921865179693754,
                1.674583930996671
              ],
              [
                -49.914003235419564,
                1.672279939097666
              ],
              [
                -49.911047770118714,
                1.671427959672184
              ],
              [
                -49.9077458043632,
                1.670475456905659
              ],
              [
                -49.90253133735187,
                1.652024605723871
              ],
              [
                -49.90132733104035,
                1.647767138600404
              ],
              [
                -49.899767378590816,
                1.64224617163968
              ],
              [
                -49.898387878685995,
                1.637365726032935
              ],
              [
                -49.89682287426971,
                1.631828263190076
              ],
              [
                -49.89308690834918,
                1.589891054003531
              ],
              [
                -49.891930408469776,
                1.576915665593334
              ],
              [
                -49.891927941493115,
                1.576889673782748
              ],
              [
                -49.891867423074814,
                1.576208645747083
              ],
              [
                -49.89083793195659,
                1.5646477430134
              ],
              [
                -49.88873746606069,
                1.541076436788491
              ],
              [
                -49.88606697999544,
                1.528732997403285
              ],
              [
                -49.886063997019974,
                1.528719024504767
              ],
              [
                -49.88339649591092,
                1.516389091014348
              ],
              [
                -49.87967353443048,
                1.49918474689767
              ],
              [
                -49.87814156697835,
                1.49210428428951
              ],
              [
                -49.8771285402719,
                1.487423815260228
              ],
              [
                -49.87576554242611,
                1.481127346958073
              ],
              [
                -49.8764755421637,
                1.476451408231875
              ],
              [
                -49.87741403352566,
                1.47026892754335
              ],
              [
                -49.87829453723117,
                1.464470464035435
              ],
              [
                -49.877398055013906,
                1.457937519537543
              ],
              [
                -49.87640457963302,
                1.450693592206036
              ],
              [
                -49.87747555918832,
                1.443567126410523
              ],
              [
                -49.87943805336168,
                1.430510225039026
              ],
              [
                -49.88366600580828,
                1.402383442955051
              ],
              [
                -49.88280054394552,
                1.397842481900577
              ],
              [
                -49.88255102302028,
                1.396437965093791
              ],
              [
                -49.88158252407045,
                1.390988502641734
              ],
              [
                -49.88284801202089,
                1.384596553469951
              ],
              [
                -49.88441849971875,
                1.378352119272516
              ],
              [
                -49.886864006667466,
                1.368626186598281
              ],
              [
                -49.88806799676103,
                1.363836725716054
              ],
              [
                -49.88993498749462,
                1.35641279014292
              ],
              [
                -49.89125796379855,
                1.35115133671029
              ],
              [
                -49.89231647283832,
                1.346942333559565
              ],
              [
                -49.89471594505279,
                1.337399918488055
              ],
              [
                -49.89618195537525,
                1.331727447056541
              ],
              [
                -49.90206440315219,
                1.31680355874872
              ],
              [
                -49.90519137676072,
                1.308870134041224
              ],
              [
                -49.90589586694072,
                1.307081133184346
              ],
              [
                -49.906397375508604,
                1.305811164514185
              ],
              [
                -49.90993382630452,
                1.296839703412215
              ],
              [
                -49.90608238214649,
                1.274281887868829
              ],
              [
                -49.898939431021795,
                1.232482671669237
              ],
              [
                -49.89668746240891,
                1.226325229070025
              ],
              [
                -49.8955739660043,
                1.223281768015984
              ],
              [
                -49.89435398642064,
                1.219945772071366
              ],
              [
                -49.89255900027254,
                1.215038813454491
              ],
              [
                -49.8925814966649,
                1.211549829822288
              ],
              [
                -49.89268748638128,
                1.208480340152747
              ],
              [
                -49.893209476970995,
                1.19333398732619
              ],
              [
                -49.89466148384461,
                1.190501009144841
              ],
              [
                -49.89611445871026,
                1.187668495958299
              ],
              [
                -49.90503489647469,
                1.17028013968645
              ],
              [
                -49.912296342792665,
                1.156124270615958
              ],
              [
                -49.9146073452215,
                1.14789529071717
              ],
              [
                -49.91480931186018,
                1.147176830963989
              ],
              [
                -49.91756033031255,
                1.137383394474318
              ],
              [
                -49.921008283881015,
                1.132985404916046
              ],
              [
                -49.92295976404397,
                1.130555451845016
              ],
              [
                -49.930831227228744,
                1.120753513147563
              ],
              [
                -49.93604719423658,
                1.114288555822262
              ],
              [
                -49.93769364831095,
                1.113945579554415
              ],
              [
                -49.942217634544576,
                1.113002588065001
              ],
              [
                -49.94521060946229,
                1.108311111531072
              ],
              [
                -49.94149062731634,
                1.102813145852239
              ],
              [
                -49.94851058208435,
                1.040199114668088
              ],
              [
                -49.94324312542987,
                1.032576169160951
              ],
              [
                -49.9431801359536,
                1.032092648654848
              ],
              [
                -49.94194464920806,
                1.022697218540979
              ],
              [
                -49.9402031796433,
                1.009448803256051
              ],
              [
                -49.94038266640485,
                0.99375094194841
              ],
              [
                -49.942774161647414,
                0.988874458568753
              ],
              [
                -49.94286865298531,
                0.988681461978376
              ],
              [
                -49.94544861990446,
                0.983421509393342
              ],
              [
                -49.95648652902251,
                0.972684094539648
              ],
              [
                -49.966050965668636,
                0.963405652900054
              ],
              [
                -49.967475974484564,
                0.96202366807934
              ],
              [
                -49.97008446684189,
                0.959500169665596
              ],
              [
                -49.97617940061164,
                0.958935698527316
              ],
              [
                -49.9949672638992,
                0.928185436179099
              ],
              [
                -49.99839024016935,
                0.918808494350949
              ],
              [
                -50.00181320742957,
                0.909432072501231
              ],
              [
                -50.01176863522218,
                0.891637200201205
              ],
              [
                -50.01802961005324,
                0.880456274654047
              ],
              [
                -50.024288557887694,
                0.869277872056587
              ],
              [
                -50.03713248466588,
                0.860187428120912
              ],
              [
                -50.041897434455635,
                0.856814460994619
              ],
              [
                -50.04253742507588,
                0.854452458102295
              ],
              [
                -50.04339490720651,
                0.851289499009645
              ],
              [
                -50.04554492460285,
                0.85126197645238
              ],
              [
                -50.04814039676709,
                0.851228496988692
              ],
              [
                -50.05223385411538,
                0.850700502260724
              ],
              [
                -50.05285183508326,
                0.846646016562725
              ],
              [
                -50.05567384902243,
                0.84286902810987
              ],
              [
                -50.058058325071364,
                0.839677065386658
              ],
              [
                -50.05345533286251,
                0.836183614053348
              ],
              [
                -50.05282384649844,
                0.835704600435747
              ],
              [
                -50.05389435186495,
                0.834468588464542
              ],
              [
                -50.05485781799478,
                0.833355599594246
              ],
              [
                -50.061144310194464,
                0.825839171461634
              ],
              [
                -50.056728819502084,
                0.823401690540917
              ],
              [
                -50.05394036690587,
                0.821862216329108
              ],
              [
                -50.05357535698051,
                0.817920727693764
              ],
              [
                -50.05308384475345,
                0.80664879586389
              ],
              [
                -50.05717183841255,
                0.803561316547995
              ],
              [
                -50.06061829415248,
                0.805914822962949
              ],
              [
                -50.06324126592856,
                0.807705812343072
              ],
              [
                -50.064799761506514,
                0.80876978884646
              ],
              [
                -50.06858524681235,
                0.801481868750224
              ],
              [
                -50.0695522289926,
                0.799620853257487
              ],
              [
                -50.06871624647951,
                0.79560988605596
              ],
              [
                -50.06817825870485,
                0.792532404162515
              ],
              [
                -50.07746669046213,
                0.791139929171955
              ],
              [
                -50.08037565540528,
                0.790703411622224
              ],
              [
                -50.08260315151149,
                0.788163431143949
              ],
              [
                -50.0857301302543,
                0.784065483972996
              ],
              [
                -50.09243258079973,
                0.782539999654636
              ],
              [
                -50.09266008048515,
                0.779281500130548
              ],
              [
                -50.092770570764664,
                0.768683597409468
              ],
              [
                -50.093460572184256,
                0.702403560184471
              ],
              [
                -50.15698662747045,
                0.705429571420342
              ],
              [
                -50.1975562890956,
                0.701214590490922
              ],
              [
                -50.31418194991546,
                0.678303781437194
              ],
              [
                -50.34678071852447,
                0.667161342089598
              ],
              [
                -50.40940377246521,
                0.623660154310228
              ],
              [
                -50.43609056998518,
                0.600422830301801
              ],
              [
                -50.45188496344478,
                0.571264538423879
              ],
              [
                -50.46682686574081,
                0.531211343851771
              ],
              [
                -50.47575229377955,
                0.481819692987558
              ],
              [
                -50.480643248274625,
                0.470522270658948
              ],
              [
                -50.4855352327487,
                0.459224347305399
              ],
              [
                -50.503543092532894,
                0.435407509671145
              ],
              [
                -50.52343695373907,
                0.372709488939
              ],
              [
                -50.53881835736112,
                0.346118655300169
              ],
              [
                -50.55569173671295,
                0.307037951030928
              ],
              [
                -50.5794685652057,
                0.270123210340427
              ],
              [
                -50.59920893106431,
                0.249675370695341
              ],
              [
                -50.62318173043725,
                0.232104977692274
              ],
              [
                -50.6443620800445,
                0.20906662807763
              ],
              [
                -50.6677389138981,
                0.185354325085077
              ],
              [
                -50.74305937099368,
                0.130820191056685
              ],
              [
                -50.78433708765161,
                0.112434824100611
              ],
              [
                -50.92061958764702,
                0.076426099673165
              ],
              [
                -50.92716552593658,
                0.073215637243613
              ],
              [
                -50.92716806091846,
                0.073214103254412
              ],
              [
                -50.93371148723039,
                0.070004134814577
              ],
              [
                -50.974388717140705,
                0.042103839523449
              ],
              [
                -50.97724917975352,
                0.038662374625442
              ],
              [
                -50.98010918536912,
                0.035221389720164
              ],
              [
                -50.98298963484693,
                0.029678930514684
              ],
              [
                -50.99645103389964,
                0.003774095734502
              ],
              [
                -51.00001101451546,
                -0.000023379713934
              ],
              [
                -51.00221048783259,
                -0.000023364714034
              ],
              [
                -51.0070149755776,
                -0.000023345714155
              ],
              [
                -51.065214058487655,
                -0.075900850221512
              ],
              [
                -51.08445693534774,
                -0.087215731544621
              ],
              [
                -51.09286185251083,
                -0.087784229682178
              ],
              [
                -51.09657083510856,
                -0.088035231977266
              ],
              [
                -51.10353628649296,
                -0.091587706308927
              ],
              [
                -51.10539776823422,
                -0.092537200716992
              ],
              [
                -51.11274172092915,
                -0.096282206722797
              ],
              [
                -51.118068676019654,
                -0.096966668025381
              ],
              [
                -51.17443675510463,
                -0.104212146364097
              ],
              [
                -51.215899960334816,
                -0.118289534116239
              ],
              [
                -51.26524459746677,
                -0.195126500801543
              ],
              [
                -51.28913144210776,
                -0.227748766397016
              ],
              [
                -51.295575392026834,
                -0.240033176519191
              ],
              [
                -51.30201887293461,
                -0.252317107682616
              ],
              [
                -51.32716315114902,
                -0.268365495516628
              ],
              [
                -51.34731803317048,
                -0.299287759482626
              ],
              [
                -51.35065348515705,
                -0.308742702280016
              ],
              [
                -51.35398945812938,
                -0.31819764609581
              ],
              [
                -51.36204392179548,
                -0.367781790079962
              ],
              [
                -51.40330761623758,
                -0.414409948702228
              ],
              [
                -51.41178508085344,
                -0.421538404068227
              ],
              [
                -51.42026197747605,
                -0.428666884456145
              ],
              [
                -51.43201592364447,
                -0.473015542873369
              ],
              [
                -51.45732474504501,
                -0.512905783248058
              ],
              [
                -51.48623953739534,
                -0.545281068937866
              ],
              [
                -51.51465079919258,
                -0.565714934864726
              ],
              [
                -51.52020276144744,
                -0.572793381747031
              ],
              [
                -51.525754238703044,
                -0.579872313642282
              ],
              [
                -51.571178897671956,
                -0.622809029216641
              ],
              [
                -51.595071215836754,
                -0.650110360489225
              ],
              [
                -51.66501373440768,
                -0.761564585192081
              ],
              [
                -51.67873961233277,
                -0.784921929459035
              ],
              [
                -51.68114811272352,
                -0.791071885822075
              ],
              [
                -51.683556097113545,
                -0.797221847193253
              ],
              [
                -51.68774004085347,
                -0.822876668964349
              ],
              [
                -51.69002054976017,
                -0.892181675700817
              ],
              [
                -51.6950500058344,
                -0.920958984702953
              ],
              [
                -51.690517037998156,
                -0.981070060784104
              ],
              [
                -51.68929653742131,
                -0.997254940738185
              ],
              [
                -51.68852403447082,
                -1.000121941170813
              ],
              [
                -51.68517955932011,
                -1.012536342445384
              ],
              [
                -51.6867630319674,
                -1.035677199639309
              ],
              [
                -51.703871398052094,
                -1.067408979917972
              ],
              [
                -51.778905362038536,
                -1.137259494410408
              ],
              [
                -51.77841835324282,
                -1.138670019798502
              ],
              [
                -51.7779323964396,
                -1.140079991190542
              ],
              [
                -51.778726884790494,
                -1.140512008394356
              ],
              [
                -51.808627649228654,
                -1.156768885258515
              ],
              [
                -51.88681958609857,
                -1.166064823005183
              ],
              [
                -51.89176707575448,
                -1.16389033545877
              ],
              [
                -51.896716540399346,
                -1.161715863910192
              ],
              [
                -51.90352397178744,
                -1.154680906421096
              ],
              [
                -51.92548932338468,
                -1.131984051880156
              ],
              [
                -51.92919780400502,
                -1.13457055902393
              ],
              [
                -51.93290627562631,
                -1.137157543167859
              ],
              [
                -51.93472777627225,
                -1.140997498575781
              ],
              [
                -51.94643616539754,
                -1.165672350211397
              ],
              [
                -51.951733129732304,
                -1.169266302861606
              ],
              [
                -51.95660811866167,
                -1.168851843436567
              ],
              [
                -51.96107006271053,
                -1.167023816460971
              ],
              [
                -51.96545852921188,
                -1.159272376348832
              ],
              [
                -51.96413904676317,
                -1.150904945476911
              ],
              [
                -51.96148856192863,
                -1.13410457320483
              ],
              [
                -51.96512406480154,
                -1.125475107898339
              ],
              [
                -51.97451550085253,
                -1.12028515430912
              ],
              [
                -51.98486541728957,
                -1.121114150323276
              ],
              [
                -51.992930848700006,
                -1.128642601883531
              ],
              [
                -51.98872289529485,
                -1.147057978264302
              ],
              [
                -51.986565407913254,
                -1.156502423358894
              ],
              [
                -51.98751189259065,
                -1.167257858135036
              ],
              [
                -51.99065437092927,
                -1.171481309225447
              ],
              [
                -52.00941921927068,
                -1.167569352562367
              ],
              [
                -52.01086073423553,
                -1.167268350818568
              ],
              [
                -52.04043003306676,
                -1.169800847553613
              ],
              [
                -52.05459589496443,
                -1.17661729704077
              ],
              [
                -52.0619473404018,
                -1.184021230502372
              ],
              [
                -52.0627708525754,
                -1.190653199096947
              ],
              [
                -52.05577889274395,
                -1.201044612160918
              ],
              [
                -52.047094477319405,
                -1.213951047045154
              ],
              [
                -52.04659644096858,
                -1.218975988219662
              ],
              [
                -52.05417889848298,
                -1.233886884400049
              ],
              [
                -52.069990307699854,
                -1.235651387250711
              ],
              [
                -52.099931579161755,
                -1.226163464979412
              ],
              [
                -52.10953153284662,
                -1.215879014568464
              ],
              [
                -52.110319516247515,
                -1.204342617073609
              ],
              [
                -52.10629353786767,
                -1.182734763377035
              ],
              [
                -52.116178482123026,
                -1.149623485197003
              ],
              [
                -52.11798497480767,
                -1.147744987093214
              ],
              [
                -52.119791471492206,
                -1.145866995985409
              ],
              [
                -52.12424293535595,
                -1.146302499766273
              ],
              [
                -52.146261760381954,
                -1.148458498786025
              ],
              [
                -52.15623619127122,
                -1.13906855235408
              ],
              [
                -52.161917152479546,
                -1.133720094124535
              ],
              [
                -52.173102083091216,
                -1.129800640162977
              ],
              [
                -52.20695085369359,
                -1.132864119914096
              ],
              [
                -52.22167825588691,
                -1.134197115288934
              ],
              [
                -52.22993416902444,
                -1.130052116350447
              ],
              [
                -52.238191141161835,
                -1.125907149404574
              ],
              [
                -52.272850367547385,
                -1.128199638456195
              ],
              [
                -52.30051369803727,
                -1.115577238222403
              ],
              [
                -52.33251644853942,
                -1.115557728294733
              ],
              [
                -52.35155182515124,
                -1.069441561591844
              ],
              [
                -52.35461881636841,
                -1.06584656809245
              ],
              [
                -52.35768577258537,
                -1.062252122587783
              ],
              [
                -52.374023690923096,
                -1.056628159599711
              ],
              [
                -52.38265013445046,
                -1.053658666717625
              ],
              [
                -52.39117706799522,
                -1.048479220590391
              ],
              [
                -52.39970499553958,
                -1.043299754454245
              ],
              [
                -52.41093893684107,
                -1.048421211849916
              ],
              [
                -52.419593355222396,
                -1.052366699745051
              ],
              [
                -52.42656632011982,
                -1.050462702482475
              ],
              [
                -52.42876180505744,
                -1.042309757271359
              ],
              [
                -52.421529861634134,
                -1.032411805373278
              ],
              [
                -52.41631990478653,
                -1.025280362298169
              ],
              [
                -52.413991888214944,
                -1.008939979222136
              ],
              [
                -52.41774288859611,
                -0.998692528272865
              ],
              [
                -52.4194558751557,
                -0.96563227365942
              ],
              [
                -52.43615726861594,
                -0.947951385176618
              ],
              [
                -52.43895324989877,
                -0.934961962457238
              ],
              [
                -52.43720674883029,
                -0.928544019182087
              ],
              [
                -52.42623232719415,
                -0.918721099531354
              ],
              [
                -52.416927407754734,
                -0.918672591670959
              ],
              [
                -52.41348391564976,
                -0.918655078349693
              ],
              [
                -52.40427250434018,
                -0.912521113266339
              ],
              [
                -52.40082152141002,
                -0.887191808559765
              ],
              [
                -52.40175350456968,
                -0.8820038484466
              ],
              [
                -52.402686000722085,
                -0.876817349325281
              ],
              [
                -52.41937839755264,
                -0.854368522809172
              ],
              [
                -52.42552884828503,
                -0.850935532548056
              ],
              [
                -52.43167878902469,
                -0.847502067285538
              ],
              [
                -52.44384972918829,
                -0.833839182277526
              ],
              [
                -52.449033699448265,
                -0.832032181986928
              ],
              [
                -52.45504463601105,
                -0.829936685722583
              ],
              [
                -52.46343108206928,
                -0.83698165349002
              ],
              [
                -52.46728903195553,
                -0.840223628221803
              ],
              [
                -52.496364363446176,
                -0.84920658148254
              ],
              [
                -52.5064967827293,
                -0.853784552043141
              ],
              [
                -52.51645920620968,
                -0.858286010110611
              ],
              [
                -52.52831710804956,
                -0.858796001113166
              ],
              [
                -52.5381845632528,
                -0.854476547172686
              ],
              [
                -52.53456806177001,
                -0.841709109133001
              ],
              [
                -52.51562422537183,
                -0.820212265788096
              ],
              [
                -52.509376251432506,
                -0.813122291544087
              ],
              [
                -52.51650621991783,
                -0.805373861223595
              ],
              [
                -52.52070968031575,
                -0.800804882282758
              ],
              [
                -52.52225116567364,
                -0.791361950679248
              ],
              [
                -52.51776619846342,
                -0.775190564494666
              ],
              [
                -52.50476879585513,
                -0.752418718700538
              ],
              [
                -52.50135283412933,
                -0.746433776463931
              ],
              [
                -52.49740183711708,
                -0.730420374428798
              ],
              [
                -52.49693685826052,
                -0.710955000941437
              ],
              [
                -52.50126080609164,
                -0.692431135640501
              ],
              [
                -52.504912781339286,
                -0.686702677427162
              ],
              [
                -52.50991526042935,
                -0.678854746560922
              ],
              [
                -52.512040252010486,
                -0.664776327235111
              ],
              [
                -52.515376231049935,
                -0.663242309837749
              ],
              [
                -52.51146574862245,
                -0.657786353097288
              ],
              [
                -52.510572256922,
                -0.656540393378978
              ],
              [
                -52.51270925637867,
                -0.619319639480152
              ],
              [
                -52.522703666710115,
                -0.588745821688448
              ],
              [
                -52.52870412977126,
                -0.581251900521259
              ],
              [
                -52.534705594822896,
                -0.573757957348002
              ],
              [
                -52.56974835520192,
                -0.575735941862824
              ],
              [
                -52.58115778007225,
                -0.578551930876808
              ],
              [
                -52.59256819895255,
                -0.581368402897541
              ],
              [
                -52.61251655500076,
                -0.594562808031385
              ],
              [
                -52.62871146018393,
                -0.593519319364865
              ],
              [
                -52.63953485314134,
                -0.584576366193164
              ],
              [
                -52.667290158647354,
                -0.543808654625101
              ],
              [
                -52.669375178695816,
                -0.537869189595214
              ],
              [
                -52.66860518430137,
                -0.533926232969819
              ],
              [
                -52.66681818930214,
                -0.524786297116844
              ],
              [
                -52.67664660787959,
                -0.518949835962837
              ],
              [
                -52.681353592592984,
                -0.51615436404343
              ],
              [
                -52.69495997810236,
                -0.49607499161815
              ],
              [
                -52.692750999992946,
                -0.4864535269792
              ],
              [
                -52.69054250786805,
                -0.476832095356144
              ],
              [
                -52.702206946547314,
                -0.458905251355449
              ],
              [
                -52.678677101056984,
                -0.42334747153771
              ],
              [
                -52.67963958566308,
                -0.419053522426783
              ],
              [
                -52.68060307825992,
                -0.414758535323752
              ],
              [
                -52.68344359191451,
                -0.410225563927027
              ],
              [
                -52.69809647109393,
                -0.386841729775895
              ],
              [
                -52.69678597453434,
                -0.379258275639055
              ],
              [
                -52.69547698595659,
                -0.37167582350452
              ],
              [
                -52.68137509182762,
                -0.339640040105174
              ],
              [
                -52.683697062956696,
                -0.313987723710724
              ],
              [
                -52.68964801237648,
                -0.302593298604814
              ],
              [
                -52.700425958430344,
                -0.292516349828708
              ],
              [
                -52.71220836616483,
                -0.29240438206157
              ],
              [
                -52.715768846611304,
                -0.29237037643519
              ],
              [
                -52.73973819898172,
                -0.276280987551373
              ],
              [
                -52.74684563126476,
                -0.269071030286536
              ],
              [
                -52.75395308454754,
                -0.261861587009613
              ],
              [
                -52.75996404797457,
                -0.237248730680554
              ],
              [
                -52.83562250757539,
                -0.185824088538352
              ],
              [
                -52.844292467563776,
                -0.159403774274879
              ],
              [
                -52.847680422364526,
                -0.15462079749177
              ],
              [
                -52.85106841216347,
                -0.14983735070981
              ],
              [
                -52.87634622850997,
                -0.142432879975534
              ],
              [
                -52.88971962944198,
                -0.149971331618632
              ],
              [
                -52.897122097487674,
                -0.15414382776114
              ],
              [
                -52.90353553941324,
                -0.154613832744301
              ],
              [
                -52.92829535654418,
                -0.145300883764301
              ],
              [
                -52.93076287166149,
                -0.142247389302755
              ],
              [
                -52.93322882678902,
                -0.139194905833354
              ],
              [
                -52.944816269807,
                -0.105593632529129
              ],
              [
                -52.95953916125574,
                -0.062896944337369
              ],
              [
                -52.965041607578684,
                -0.052843009891304
              ],
              [
                -52.970545559881494,
                -0.042787076454153
              ],
              [
                -52.97628154465433,
                -0.007305296795699
              ],
              [
                -52.97681555095606,
                0.024119475033284
              ],
              [
                -52.98079001567053,
                0.03004143624317
              ],
              [
                -52.984764989378874,
                0.035963878452645
              ],
              [
                -52.99298593697808,
                0.039630867848892
              ],
              [
                -53.003206866866634,
                0.044191321260205
              ],
              [
                -53.008847827140386,
                0.052103268129199
              ],
              [
                -53.01448929240643,
                0.060015207004
              ],
              [
                -53.013660279022275,
                0.067649660688043
              ],
              [
                -53.01065182433495,
                0.095367999307871
              ],
              [
                -53.02453221509324,
                0.102770916723263
              ],
              [
                -53.028789170887514,
                0.105041429519981
              ],
              [
                -53.03603861197839,
                0.120139325125602
              ],
              [
                -53.0383846216909,
                0.132418722598349
              ],
              [
                -53.03913111350407,
                0.136324211348642
              ],
              [
                -53.04230159571687,
                0.139786193128269
              ],
              [
                -53.045835547431565,
                0.143646644237306
              ],
              [
                -53.04762152411066,
                0.147951118326366
              ],
              [
                -53.05164952831477,
                0.15766007811693
              ],
              [
                -53.069622880951144,
                0.169865494448392
              ],
              [
                -53.082899808582795,
                0.188938845524418
              ],
              [
                -53.08835775213572,
                0.192283813177467
              ],
              [
                -53.093815233694436,
                0.195628810834189
              ],
              [
                -53.1059301367178,
                0.232064566172408
              ],
              [
                -53.09364869883632,
                0.259279861637459
              ],
              [
                -53.11145511195501,
                0.286851180740216
              ],
              [
                -53.13387143307839,
                0.300498089815613
              ],
              [
                -53.13492543032281,
                0.313425999855669
              ],
              [
                -53.135980424537436,
                0.326353410884955
              ],
              [
                -53.147961844584756,
                0.348531749141423
              ],
              [
                -53.155875802199965,
                0.354437707770297
              ],
              [
                -53.16379021881577,
                0.360344691401714
              ],
              [
                -53.17483364423896,
                0.382038039935803
              ],
              [
                -53.160201247135184,
                0.402256385969598
              ],
              [
                -53.15925024928996,
                0.408414368428822
              ],
              [
                -53.15876126093112,
                0.420646749976755
              ],
              [
                -53.167267691273956,
                0.446048592295023
              ],
              [
                -53.15465079229893,
                0.480499360170057
              ],
              [
                -53.15906776056943,
                0.488541301219422
              ],
              [
                -53.160800745661724,
                0.489475812034036
              ],
              [
                -53.16929017633317,
                0.494054258735079
              ],
              [
                -53.159152774214796,
                0.514129630569276
              ],
              [
                -53.162235228525276,
                0.52280459424539
              ],
              [
                -53.15625326802753,
                0.528089041134568
              ],
              [
                -53.1515842956406,
                0.532214008938724
              ],
              [
                -53.1479033470795,
                0.541977954729518
              ],
              [
                -53.147678309831555,
                0.552374384495229
              ],
              [
                -53.15183979184266,
                0.559400305362141
              ],
              [
                -53.15436928421993,
                0.563671788708476
              ],
              [
                -53.15301280745873,
                0.576783708021165
              ],
              [
                -53.1468443476852,
                0.600653047200496
              ],
              [
                -53.12001251430607,
                0.63429581233567
              ],
              [
                -53.11033357745159,
                0.668401573567212
              ],
              [
                -53.108870076160656,
                0.67168706817894
              ],
              [
                -53.10526609706487,
                0.679778494031114
              ],
              [
                -53.105819109854686,
                0.684299958454159
              ],
              [
                -53.12363797467448,
                0.697661371669422
              ],
              [
                -53.13257591984264,
                0.694555884573729
              ],
              [
                -53.13637141401336,
                0.693237397871013
              ],
              [
                -53.14339535266399,
                0.696578376933854
              ],
              [
                -53.15059730515965,
                0.719718732958716
              ],
              [
                -53.153756259082755,
                0.724544205600129
              ],
              [
                -53.153763269033774,
                0.7245547125297
              ],
              [
                -53.15692925490586,
                0.729390651102809
              ],
              [
                -53.18412957914668,
                0.747820549126773
              ],
              [
                -53.18979503925353,
                0.768698877361366
              ],
              [
                -53.201956422397934,
                0.776106846517614
              ],
              [
                -53.20316092424153,
                0.775460854022556
              ],
              [
                -53.20651188955067,
                0.773664854546122
              ],
              [
                -53.21143738503276,
                0.781887299417421
              ],
              [
                -53.213928837570315,
                0.786046284534443
              ],
              [
                -53.240866660992474,
                0.801573659428378
              ],
              [
                -53.25466856693526,
                0.809529130150266
              ],
              [
                -53.26450348396308,
                0.817384567118005
              ],
              [
                -53.264507496935344,
                0.817388055094869
              ],
              [
                -53.27434791392982,
                0.825248012047959
              ],
              [
                -53.28250436177223,
                0.838617433542718
              ],
              [
                -53.287041350971876,
                0.846052854769451
              ],
              [
                -53.294428303834486,
                0.852682310794765
              ],
              [
                -53.3018152146987,
                0.859311779829137
              ],
              [
                -53.32860203639395,
                0.874199174384675
              ],
              [
                -53.35445536157644,
                0.888568098418986
              ],
              [
                -53.357663366250996,
                0.892497565252037
              ],
              [
                -53.360871319924996,
                0.896428033080277
              ],
              [
                -53.362693310372116,
                0.8976350271493
              ],
              [
                -53.411014485547454,
                0.929659816888017
              ],
              [
                -53.413259941921936,
                0.947735698016473
              ],
              [
                -53.410373464404955,
                0.95640462802692
              ],
              [
                -53.41018847758924,
                0.956959635249738
              ],
              [
                -53.4103224582584,
                0.982359944506435
              ],
              [
                -53.4169864229536,
                0.989584408568905
              ],
              [
                -53.41828141208823,
                0.990020893797227
              ],
              [
                -53.42634887486357,
                0.992742400517963
              ],
              [
                -53.42945234293785,
                1.020127185737633
              ],
              [
                -53.42578485031607,
                1.024031669794954
              ],
              [
                -53.420868907992904,
                1.029263115686498
              ],
              [
                -53.4218479006503,
                1.03405359441049
              ],
              [
                -53.42698633633364,
                1.043631527342748
              ],
              [
                -53.4342108155403,
                1.048327502626846
              ],
              [
                -53.43548029221759,
                1.059894927530404
              ],
              [
                -53.45111316936553,
                1.070765833304315
              ],
              [
                -53.45222965366355,
                1.071541862221239
              ],
              [
                -53.453786647626224,
                1.074612805674936
              ],
              [
                -53.455344156584395,
                1.077683791128845
              ],
              [
                -53.455399643190994,
                1.110678082797133
              ],
              [
                -53.45906512961607,
                1.127847956109896
              ],
              [
                -53.456763115562126,
                1.138607871867348
              ],
              [
                -53.44387670305722,
                1.151632782543459
              ],
              [
                -53.43902824782131,
                1.152812788759863
              ],
              [
                -53.434180279585185,
                1.153992776974672
              ],
              [
                -53.43090630508873,
                1.152447268919434
              ],
              [
                -53.42079538059285,
                1.147675314632385
              ],
              [
                -53.41164892774537,
                1.14795382026649
              ],
              [
                -53.403929486683715,
                1.151485780065057
              ],
              [
                -53.399398034346625,
                1.15872325123627
              ],
              [
                -53.39559952779698,
                1.179607098911395
              ],
              [
                -53.40251048937201,
                1.194245502717446
              ],
              [
                -53.411082941193804,
                1.199493460544563
              ],
              [
                -53.41695788264098,
                1.203089952131166
              ],
              [
                -53.42482284601239,
                1.22179431353734
              ],
              [
                -53.42506785261837,
                1.226077755507415
              ],
              [
                -53.4260403370986,
                1.243034144574364
              ],
              [
                -53.43006331465458,
                1.243572664622627
              ],
              [
                -53.433123784292256,
                1.235189734058047
              ],
              [
                -53.43326527239699,
                1.234801719716158
              ],
              [
                -53.438288264656364,
                1.232446730570816
              ],
              [
                -53.4732590138006,
                1.239646173816657
              ],
              [
                -53.524729159091805,
                1.212029370862521
              ],
              [
                -53.53553457169067,
                1.212584877947859
              ],
              [
                -53.54484602296058,
                1.221861802739872
              ],
              [
                -53.54584151002213,
                1.228822288787456
              ],
              [
                -53.53262008427529,
                1.242948687816054
              ],
              [
                -53.53009462994141,
                1.245647144093008
              ],
              [
                -53.52284615233082,
                1.261651060370644
              ],
              [
                -53.53003660137706,
                1.273935477398923
              ],
              [
                -53.55222845866599,
                1.280601941221153
              ],
              [
                -53.554217926772075,
                1.282859905282801
              ],
              [
                -53.55620694088095,
                1.285118382341603
              ],
              [
                -53.56215139364637,
                1.306895750869159
              ],
              [
                -53.5607603713039,
                1.311142715831274
              ],
              [
                -53.543624524631646,
                1.31595069206559
              ],
              [
                -53.54340749107854,
                1.316088180093129
              ],
              [
                -53.53290457610313,
                1.322742627013944
              ],
              [
                -53.53806652366899,
                1.341174516961062
              ],
              [
                -53.54801196177442,
                1.348846458806506
              ],
              [
                -53.56337735636218,
                1.350006428863799
              ],
              [
                -53.56413036512953,
                1.345354489549917
              ],
              [
                -53.56498783730543,
                1.340060996601995
              ],
              [
                -53.57020780937272,
                1.337616514159265
              ],
              [
                -53.59473463923205,
                1.358467408496388
              ],
              [
                -53.63276438869233,
                1.347671993888893
              ],
              [
                -53.64139984354956,
                1.335280567409597
              ],
              [
                -53.650483259835724,
                1.336535041633181
              ],
              [
                -53.66259569438641,
                1.349807982112495
              ],
              [
                -53.652690753398915,
                1.355177931877296
              ],
              [
                -53.64856776887992,
                1.357413394955183
              ],
              [
                -53.64304080000214,
                1.364199363949248
              ],
              [
                -53.644648797989525,
                1.380555262500146
              ],
              [
                -53.63995282043584,
                1.392669181525485
              ],
              [
                -53.644123810629175,
                1.405861088974372
              ],
              [
                -53.64981926554941,
                1.408564575789667
              ],
              [
                -53.657286715847334,
                1.404309595096736
              ],
              [
                -53.662110693371176,
                1.388043196207032
              ],
              [
                -53.666455642793814,
                1.373391301246428
              ],
              [
                -53.67998657336821,
                1.37283281465088
              ],
              [
                -53.68153756351063,
                1.379956243747367
              ],
              [
                -53.68256352466346,
                1.384667229065877
              ],
              [
                -53.68701301629245,
                1.386109707174841
              ],
              [
                -53.692501965772095,
                1.383012235208456
              ],
              [
                -53.70097693639041,
                1.378229258220291
              ],
              [
                -53.71097882261139,
                1.384276221270801
              ],
              [
                -53.711622331475475,
                1.388341226901812
              ],
              [
                -53.71295283484936,
                1.396746139309718
              ],
              [
                -53.695320962786,
                1.418454507899068
              ],
              [
                -53.696346431903315,
                1.423241965695418
              ],
              [
                -53.71670629150837,
                1.43207340502464
              ],
              [
                -53.72356973182833,
                1.428541425314226
              ],
              [
                -53.71554031213825,
                1.418247482353488
              ],
              [
                -53.71492931174193,
                1.405689103201587
              ],
              [
                -53.730872716875915,
                1.396091142277357
              ],
              [
                -53.731206711253876,
                1.392830177393628
              ],
              [
                -53.73184471015159,
                1.386599241648538
              ],
              [
                -53.75462052769452,
                1.394886178109816
              ],
              [
                -53.75607051837202,
                1.397607145006031
              ],
              [
                -53.76198598125234,
                1.408706056163831
              ],
              [
                -53.76599696692644,
                1.399747647504153
              ],
              [
                -53.767571952979516,
                1.396229158590892
              ],
              [
                -53.77412889552126,
                1.397214164231314
              ],
              [
                -53.783237831872405,
                1.403167124818921
              ],
              [
                -53.78426184561301,
                1.404942117031461
              ],
              [
                -53.79120779636415,
                1.416982019080951
              ],
              [
                -53.80350769001193,
                1.423836968246486
              ],
              [
                -53.8151816335894,
                1.422194973687732
              ],
              [
                -53.82229108623581,
                1.414145546465016
              ],
              [
                -53.81552812887893,
                1.408915583422566
              ],
              [
                -53.80700618965708,
                1.402324619227493
              ],
              [
                -53.80457221401273,
                1.397540167049505
              ],
              [
                -53.80795016131783,
                1.391931213584158
              ],
              [
                -53.82311806840386,
                1.389926725100311
              ],
              [
                -53.83028901522475,
                1.39416318692545
              ],
              [
                -53.84985638970699,
                1.392152199352811
              ],
              [
                -53.85234787954604,
                1.394046689066451
              ],
              [
                -53.85059638841522,
                1.398837647404554
              ],
              [
                -53.8487998913924,
                1.399508165525522
              ],
              [
                -53.84112493656517,
                1.402374131667482
              ],
              [
                -53.84381994356503,
                1.406617597571982
              ],
              [
                -53.85448835858437,
                1.401906159478361
              ],
              [
                -53.87581771470512,
                1.407843584681133
              ],
              [
                -53.878503714161226,
                1.405051132044535
              ],
              [
                -53.88234317908328,
                1.40105913972064
              ],
              [
                -53.88726463755421,
                1.408098608245556
              ],
              [
                -53.88394164628473,
                1.416449557279015
              ],
              [
                -53.87625819018651,
                1.435762397477331
              ],
              [
                -53.88023966140485,
                1.440546397017285
              ],
              [
                -53.89276259942955,
                1.443692367357068
              ],
              [
                -53.895513581523204,
                1.455437297706824
              ],
              [
                -53.91704342021858,
                1.457215773157954
              ],
              [
                -53.920592406456954,
                1.446760864369325
              ],
              [
                -53.9220148727308,
                1.442571395898866
              ],
              [
                -53.935377303046124,
                1.43646443877934
              ],
              [
                -53.93986574958846,
                1.437760932959404
              ],
              [
                -53.939985287600166,
                1.443632387426534
              ],
              [
                -53.93187233310739,
                1.448948817938405
              ],
              [
                -53.92989983611741,
                1.450241306822288
              ],
              [
                -53.93010034218675,
                1.458011778490246
              ],
              [
                -53.925400354222404,
                1.465788704090311
              ],
              [
                -53.92827784709847,
                1.469490700730539
              ],
              [
                -53.93526830249999,
                1.467362195535067
              ],
              [
                -53.9430257554368,
                1.471613160498629
              ],
              [
                -53.953597680012834,
                1.468281195157319
              ],
              [
                -53.95542166028444,
                1.480205127187057
              ],
              [
                -53.96659355622422,
                1.484707108199328
              ],
              [
                -53.97071453966174,
                1.479191655226161
              ],
              [
                -53.97564853385645,
                1.472588680738466
              ],
              [
                -53.9856764670532,
                1.479351631289175
              ],
              [
                -53.99698187475521,
                1.476081658679705
              ],
              [
                -54.00298432959149,
                1.489717065133875
              ],
              [
                -54.00239234808711,
                1.492023069480858
              ],
              [
                -53.999040850863956,
                1.505080977826884
              ],
              [
                -54.00856378872614,
                1.519977360533486
              ],
              [
                -54.019789208153675,
                1.516075394231879
              ],
              [
                -54.027093176873784,
                1.517146891589953
              ],
              [
                -54.02070869987365,
                1.50712845667162
              ],
              [
                -54.021750688328,
                1.504984975325614
              ],
              [
                -54.02409370160718,
                1.500167997253242
              ],
              [
                -54.03951258874716,
                1.506471469117608
              ],
              [
                -54.055454961401225,
                1.493800054770695
              ],
              [
                -54.061952917531656,
                1.495056061702335
              ],
              [
                -54.0686743981239,
                1.501279515277282
              ],
              [
                -54.07895329844112,
                1.495213553254872
              ],
              [
                -54.07747830726172,
                1.486000590879836
              ],
              [
                -54.085980248690674,
                1.488609093142446
              ],
              [
                -54.08826123761888,
                1.4923095707526
              ],
              [
                -54.08467776382099,
                1.505774989466923
              ],
              [
                -54.08854172816571,
                1.513989933061292
              ],
              [
                -54.087070753139,
                1.51818438154013
              ],
              [
                -54.084103772231735,
                1.526644821000447
              ],
              [
                -54.098292675854054,
                1.550834177404073
              ],
              [
                -54.10829011572799,
                1.551278674618846
              ],
              [
                -54.11403753914425,
                1.551534172168412
              ],
              [
                -54.1170400265018,
                1.558484613095219
              ],
              [
                -54.11270957930066,
                1.568638541844057
              ],
              [
                -54.10987006186652,
                1.575297490416622
              ],
              [
                -54.10146115117734,
                1.584614921836947
              ],
              [
                -54.101512113426644,
                1.590938382292981
              ],
              [
                -54.11059706010484,
                1.588306399057465
              ],
              [
                -54.11567204207094,
                1.583878952989486
              ],
              [
                -54.11806452349833,
                1.581791450570673
              ],
              [
                -54.12234401272517,
                1.591178388390341
              ],
              [
                -54.127869949592565,
                1.591623389647379
              ],
              [
                -54.13089842404596,
                1.597218877695962
              ],
              [
                -54.12360497401277,
                1.601270808778723
              ],
              [
                -54.122093010957094,
                1.602110824782557
              ],
              [
                -54.12259699431775,
                1.607710802217546
              ],
              [
                -54.13300940033516,
                1.617450738068737
              ],
              [
                -54.12931792852899,
                1.624229185605499
              ],
              [
                -54.13962436961064,
                1.627996657637207
              ],
              [
                -54.14187386535184,
                1.628819136624217
              ],
              [
                -54.14195386064652,
                1.629559634660379
              ],
              [
                -54.143177848837254,
                1.640923052481569
              ],
              [
                -54.1463663137496,
                1.644078553991931
              ],
              [
                -54.151983771143755,
                1.639644080142004
              ],
              [
                -54.15639624080036,
                1.643070042122778
              ],
              [
                -54.1684301829267,
                1.64431404756854
              ],
              [
                -54.17564563570072,
                1.648278029597489
              ],
              [
                -54.17787512157333,
                1.657758952354011
              ],
              [
                -54.181730071621885,
                1.65838444005657
              ],
              [
                -54.18706953761066,
                1.646633041337908
              ],
              [
                -54.187057545029404,
                1.645169555175112
              ],
              [
                -54.186924541800295,
                1.628295677583142
              ],
              [
                -54.19061500680389,
                1.6252191711162
              ],
              [
                -54.19698148362978,
                1.632074650516816
              ],
              [
                -54.202454448032256,
                1.649319014889435
              ],
              [
                -54.218811809784896,
                1.65714997310669
              ],
              [
                -54.22786477326022,
                1.665618909335688
              ],
              [
                -54.228337741435716,
                1.668441411480554
              ],
              [
                -54.22965225080158,
                1.676282353098417
              ],
              [
                -54.23934115999136,
                1.676389363692439
              ],
              [
                -54.243958166112364,
                1.676440330452441
              ],
              [
                -54.24784162571372,
                1.682528780474077
              ],
              [
                -54.24884911360516,
                1.684108297103014
              ],
              [
                -54.24972161582542,
                1.68388678880058
              ],
              [
                -54.26317303273188,
                1.680472299961573
              ],
              [
                -54.262256001911794,
                1.68869324252142
              ],
              [
                -54.26875745807343,
                1.694553228724895
              ],
              [
                -54.26555901111712,
                1.695929225709105
              ],
              [
                -54.26517851662068,
                1.696092733518745
              ],
              [
                -54.26909197631105,
                1.705121149813643
              ],
              [
                -54.26631248110194,
                1.708375649271992
              ],
              [
                -54.2740069361069,
                1.710803619833919
              ],
              [
                -54.278163910838266,
                1.708712638893424
              ],
              [
                -54.279594888451804,
                1.707993128074895
              ],
              [
                -54.291872322191004,
                1.709055135931819
              ],
              [
                -54.2843883777381,
                1.715025576006606
              ],
              [
                -54.28245536127792,
                1.71656707517915
              ],
              [
                -54.28115287614069,
                1.724158531860173
              ],
              [
                -54.28940232283039,
                1.720892565825414
              ],
              [
                -54.29270029349132,
                1.726305493276678
              ],
              [
                -54.298284259609,
                1.72462704292363
              ],
              [
                -54.29995528057268,
                1.724124520709329
              ],
              [
                -54.30212722946958,
                1.726597012638723
              ],
              [
                -54.3047892299586,
                1.729626989945935
              ],
              [
                -54.308157701454235,
                1.729687500370181
              ],
              [
                -54.30929121088176,
                1.729707978512206
              ],
              [
                -54.31022020242722,
                1.730744975777862
              ],
              [
                -54.312925187632935,
                1.733764449170071
              ],
              [
                -54.305287223783495,
                1.737209429144655
              ],
              [
                -54.30881620220611,
                1.741360398140039
              ],
              [
                -54.31639313966807,
                1.737100431627307
              ],
              [
                -54.32295358970527,
                1.737811937475285
              ],
              [
                -54.3290700353381,
                1.744124410612569
              ],
              [
                -54.33285051458966,
                1.742172936653481
              ],
              [
                -54.33609300336443,
                1.740498911695996
              ],
              [
                -54.34417443733938,
                1.740937927755922
              ],
              [
                -54.34386297042463,
                1.744981381540705
              ],
              [
                -54.34370846896182,
                1.74698837003158
              ],
              [
                -54.35033990954988,
                1.747698863915986
              ],
              [
                -54.35120441213564,
                1.754379319296571
              ],
              [
                -54.37722923820756,
                1.763996785292421
              ],
              [
                -54.3820917000906,
                1.754730853670744
              ],
              [
                -54.386954667961746,
                1.745464895027664
              ],
              [
                -54.39904708373394,
                1.756164339305986
              ],
              [
                -54.40345705538256,
                1.760066316250263
              ],
              [
                -54.41472548795055,
                1.75748983435237
              ],
              [
                -54.4174054692508,
                1.756877347130185
              ],
              [
                -54.419126934540614,
                1.757861828963704
              ],
              [
                -54.42417391720998,
                1.760749806875967
              ],
              [
                -54.42912485602165,
                1.757594338265954
              ],
              [
                -54.43412033854626,
                1.754409362859411
              ],
              [
                -54.44691624682267,
                1.756565361624848
              ],
              [
                -54.46431864727461,
                1.759498316353237
              ],
              [
                -54.470122581509486,
                1.756058865839906
              ],
              [
                -54.47025410984294,
                1.755216851510074
              ],
              [
                -54.47078658714219,
                1.751813868425077
              ],
              [
                -54.47914605892239,
                1.754267356095929
              ],
              [
                -54.484233008715584,
                1.755760363378972
              ],
              [
                -54.48474547989965,
                1.753366860526531
              ],
              [
                -54.48512251482758,
                1.75160538540949
              ],
              [
                -54.49545642217686,
                1.747252406120842
              ],
              [
                -54.5005368985123,
                1.75082691047705
              ],
              [
                -54.501343401009585,
                1.751394406882399
              ],
              [
                -54.50404937229821,
                1.750261895135902
              ],
              [
                -54.508064341020656,
                1.748581902377764
              ],
              [
                -54.51192929805676,
                1.753722370962406
              ],
              [
                -54.52347923264541,
                1.756318858495719
              ],
              [
                -54.52632221522801,
                1.762453318187316
              ],
              [
                -54.52761769592264,
                1.76524929881571
              ],
              [
                -54.536507162529524,
                1.770560278547347
              ],
              [
                -54.56290094779511,
                1.776242719254486
              ],
              [
                -54.57059742627651,
                1.777900234133972
              ],
              [
                -54.570568919184666,
                1.779018727651951
              ],
              [
                -54.5704219117145,
                1.784762184229404
              ],
              [
                -54.577023885999225,
                1.78034169945514
              ],
              [
                -54.58056383809686,
                1.777971713192279
              ],
              [
                -54.58924927372674,
                1.780849204180021
              ],
              [
                -54.60142520691465,
                1.784883167341513
              ],
              [
                -54.607534652208045,
                1.781607713776608
              ],
              [
                -54.611229638195475,
                1.779626713946898
              ],
              [
                -54.618410597395965,
                1.780298237293626
              ],
              [
                -54.625395539908034,
                1.780952199709108
              ],
              [
                -54.63148550156491,
                1.776821734830087
              ],
              [
                -54.64315142820788,
                1.768909296590387
              ],
              [
                -54.650534882555235,
                1.771756286470336
              ],
              [
                -54.65107037995447,
                1.77196226523222
              ],
              [
                -54.65828234071149,
                1.769758792759328
              ],
              [
                -54.67070423135604,
                1.765964342208842
              ],
              [
                -54.6785341896784,
                1.769200294618674
              ],
              [
                -54.691671589926976,
                1.768179833751105
              ],
              [
                -54.697698574598206,
                1.773848765501565
              ],
              [
                -54.700005529778835,
                1.776018758627439
              ],
              [
                -54.70094053404937,
                1.774184774086956
              ],
              [
                -54.701757041045695,
                1.772583277966311
              ],
              [
                -54.71803942556988,
                1.771377817134462
              ],
              [
                -54.744288222711575,
                1.77610928230364
              ],
              [
                -54.74595425000435,
                1.786759222819906
              ],
              [
                -54.75217318580189,
                1.790988180257784
              ],
              [
                -54.75393115836329,
                1.798118125236068
              ],
              [
                -54.746428224953874,
                1.806075085305746
              ],
              [
                -54.75017918897054,
                1.814554032803559
              ],
              [
                -54.744718248592456,
                1.820369972635923
              ],
              [
                -54.73353280050608,
                1.832283413317221
              ],
              [
                -54.740122743889145,
                1.832359902539457
              ],
              [
                -54.73928876852677,
                1.839586355157716
              ],
              [
                -54.74334871929887,
                1.84100933374548
              ],
              [
                -54.74799220315989,
                1.842637335122644
              ],
              [
                -54.752699683763225,
                1.851282772754087
              ],
              [
                -54.75261218083909,
                1.872144649675269
              ],
              [
                -54.752596177944866,
                1.875911102560671
              ],
              [
                -54.7570636325006,
                1.886788517243846
              ],
              [
                -54.75857110754931,
                1.890460019172228
              ],
              [
                -54.763039580375505,
                1.89279551481321
              ],
              [
                -54.7613735848668,
                1.905891391034616
              ],
              [
                -54.76542657750938,
                1.907957373366189
              ],
              [
                -54.771096544063184,
                1.940806164835623
              ],
              [
                -54.76453508249715,
                1.944251148022377
              ],
              [
                -54.7633525776706,
                1.946592638068303
              ],
              [
                -54.757871627912486,
                1.957446056099352
              ],
              [
                -54.75964358534385,
                1.960510014146038
              ],
              [
                -54.755373133557,
                1.96061202926376
              ],
              [
                -54.753311153283896,
                1.967476488855712
              ],
              [
                -54.75550764179144,
                1.974513930490003
              ],
              [
                -54.76350955659005,
                1.979099416144285
              ],
              [
                -54.77165252285545,
                1.978722901970132
              ],
              [
                -54.76853304146859,
                1.996511797318432
              ],
              [
                -54.78006344930282,
                1.99982475150534
              ],
              [
                -54.78038193017153,
                1.999916777982377
              ],
              [
                -54.79047939626126,
                2.007562226847985
              ],
              [
                -54.79448234808015,
                2.017122652183169
              ],
              [
                -54.80331328314729,
                2.02612907762285
              ],
              [
                -54.80675977305012,
                2.037675500546802
              ],
              [
                -54.810843728472626,
                2.039743502936311
              ],
              [
                -54.80597774486283,
                2.052686413089549
              ],
              [
                -54.80547678278452,
                2.054018896043656
              ],
              [
                -54.80654123734066,
                2.055430388931679
              ],
              [
                -54.81233971478871,
                2.063120340292499
              ],
              [
                -54.81158772034765,
                2.071335275206025
              ],
              [
                -54.808770219212406,
                2.073880288368687
              ],
              [
                -54.80567724682402,
                2.076674748780155
              ],
              [
                -54.80877024647625,
                2.086543189776859
              ],
              [
                -54.8133386959674,
                2.101120090756499
              ],
              [
                -54.80877021109386,
                2.104653065768626
              ],
              [
                -54.80420124822461,
                2.108186541771403
              ],
              [
                -54.806616725886265,
                2.116129005415686
              ],
              [
                -54.80872771185305,
                2.123069447664378
              ],
              [
                -54.80373974927562,
                2.131463901019419
              ],
              [
                -54.80308774325212,
                2.132560387488213
              ],
              [
                -54.80339177301395,
                2.133318361513077
              ],
              [
                -54.80576224855903,
                2.139234838678241
              ],
              [
                -54.80047726437924,
                2.142857823825274
              ],
              [
                -54.79803678119566,
                2.150446764313992
              ],
              [
                -54.792988323505966,
                2.153896709675287
              ],
              [
                -54.78915183206114,
                2.156518186949099
              ],
              [
                -54.78993183420382,
                2.158812199841169
              ],
              [
                -54.79089881794101,
                2.161657682101134
              ],
              [
                -54.78659586960923,
                2.164176658908849
              ],
              [
                -54.78098839966811,
                2.167460136190165
              ],
              [
                -54.78259087808309,
                2.173931096375522
              ],
              [
                -54.78273086798301,
                2.174497576627336
              ],
              [
                -54.78228187189037,
                2.174694595168358
              ],
              [
                -54.76740099626353,
                2.181217537844067
              ],
              [
                -54.76898597871492,
                2.187893990628907
              ],
              [
                -54.762354022065544,
                2.192746471042993
              ],
              [
                -54.76261999022941,
                2.202623872956645
              ],
              [
                -54.77014493855318,
                2.208744363334434
              ],
              [
                -54.77461992422663,
                2.220646267019845
              ],
              [
                -54.78109389546137,
                2.223702753607488
              ],
              [
                -54.794384297241415,
                2.240665639229257
              ],
              [
                -54.79438628522716,
                2.240669127206536
              ],
              [
                -54.79845626467663,
                2.245863109722499
              ],
              [
                -54.80469171914798,
                2.256272531003912
              ],
              [
                -54.81092816060184,
                2.266683476282961
              ],
              [
                -54.8085921815615,
                2.286729322200566
              ],
              [
                -54.80325221382638,
                2.289871789384427
              ],
              [
                -54.80242771011714,
                2.290357299858989
              ],
              [
                -54.803173215748885,
                2.291716774991569
              ],
              [
                -54.806727684150786,
                2.298199755706178
              ],
              [
                -54.803116712773296,
                2.305055695551748
              ],
              [
                -54.801539219217915,
                2.308050681948774
              ],
              [
                -54.79375575602375,
                2.313039662911964
              ],
              [
                -54.80456068718615,
                2.329529037922709
              ],
              [
                -54.805814665840046,
                2.33575350259495
              ],
              [
                -54.807069182484526,
                2.341979456255555
              ],
              [
                -54.826673541124634,
                2.362486821325935
              ],
              [
                -54.865589278416245,
                2.403196540631926
              ],
              [
                -54.86720475168946,
                2.409241020656918
              ],
              [
                -54.87420219232468,
                2.415628458249077
              ],
              [
                -54.87575567016393,
                2.426999378533869
              ],
              [
                -54.87552568254241,
                2.427402872747508
              ],
              [
                -54.87170822442017,
                2.434101849481547
              ],
              [
                -54.79680872123457,
                2.439450266362048
              ],
              [
                -54.74347156570047,
                2.471857047639262
              ],
              [
                -54.684357955727776,
                2.447190193882442
              ],
              [
                -54.68460645010114,
                2.444246210800375
              ],
              [
                -54.68550097483361,
                2.433653775455919
              ],
              [
                -54.68740496695692,
                2.411101440969767
              ],
              [
                -54.69029243355066,
                2.376527182646973
              ],
              [
                -54.69107843650385,
                2.367120749196088
              ],
              [
                -54.691524931337646,
                2.361771292331398
              ],
              [
                -54.661689156466146,
                2.327363003761576
              ],
              [
                -54.6011355447469,
                2.337540931017813
              ],
              [
                -54.436022214407146,
                2.210261754447977
              ],
              [
                -54.429999273739405,
                2.209566745236766
              ],
              [
                -54.18852389068504,
                2.179193886126134
              ],
              [
                -54.00047015853752,
                2.229862992902096
              ],
              [
                -53.99846617955681,
                2.231305977439008
              ],
              [
                -53.992947226413996,
                2.235277955631865
              ],
              [
                -53.98919622900052,
                2.237293410709879
              ],
              [
                -53.98519127425237,
                2.23626092047392
              ],
              [
                -53.98300426818226,
                2.235545443655458
              ],
              [
                -53.98046028255005,
                2.234712925521797
              ],
              [
                -53.974319838438845,
                2.232815942503413
              ],
              [
                -53.972134346182806,
                2.235864910030427
              ],
              [
                -53.96979039492261,
                2.239134919997491
              ],
              [
                -53.96698738083953,
                2.242118896765235
              ],
              [
                -53.96103794921541,
                2.244173366865414
              ],
              [
                -53.9537429909683,
                2.245212855454809
              ],
              [
                -53.95018349529631,
                2.244013361501715
              ],
              [
                -53.94693104369745,
                2.242375392637861
              ],
              [
                -53.94180557805511,
                2.242806361068532
              ],
              [
                -53.94215706150746,
                2.252910321257998
              ],
              [
                -53.93847258527356,
                2.256176778795417
              ],
              [
                -53.936193115224754,
                2.260574753057748
              ],
              [
                -53.93687057684724,
                2.263154731670312
              ],
              [
                -53.937381099794294,
                2.265098723568998
              ],
              [
                -53.93913105816284,
                2.27093018733806
              ],
              [
                -53.94142904025468,
                2.278591126798204
              ],
              [
                -53.936488604095395,
                2.286133552719657
              ],
              [
                -53.9377060738557,
                2.289393559861204
              ],
              [
                -53.938927072738714,
                2.29219351814441
              ],
              [
                -53.934798096846,
                2.297434484021216
              ],
              [
                -53.93137562405656,
                2.293790514786652
              ],
              [
                -53.92715316394203,
                2.292360515177732
              ],
              [
                -53.92168817056507,
                2.292895017751691
              ],
              [
                -53.915888721912,
                2.291973529160221
              ],
              [
                -53.91573423418013,
                2.288145567252844
              ],
              [
                -53.91564422450233,
                2.28591008449006
              ],
              [
                -53.915953237959485,
                2.281101082429553
              ],
              [
                -53.915800740052745,
                2.277761103186694
              ],
              [
                -53.907217778918074,
                2.274426150190773
              ],
              [
                -53.905063290368425,
                2.277754104768028
              ],
              [
                -53.90276033473954,
                2.28132757961708
              ],
              [
                -53.897576845459646,
                2.288194037033397
              ],
              [
                -53.89207738579867,
                2.284165577774163
              ],
              [
                -53.88867040896656,
                2.286624569869711
              ],
              [
                -53.89107790885423,
                2.292524993332905
              ],
              [
                -53.88958141504945,
                2.295288504962193
              ],
              [
                -53.88898991368385,
                2.296380484307423
              ],
              [
                -53.88721493273841,
                2.299193969501762
              ],
              [
                -53.887741427641124,
                2.30400342380258
              ],
              [
                -53.88807591831717,
                2.307318408253977
              ],
              [
                -53.88383692804582,
                2.316077864986809
              ],
              [
                -53.87870347746529,
                2.316616854617082
              ],
              [
                -53.87512800258491,
                2.316547838915584
              ],
              [
                -53.87204453935923,
                2.316573841725907
              ],
              [
                -53.868559047329846,
                2.31510384563516
              ],
              [
                -53.86631854168787,
                2.314316845282723
              ],
              [
                -53.864121593747846,
                2.313545341839082
              ],
              [
                -53.86194307049328,
                2.316450325256135
              ],
              [
                -53.85901461496985,
                2.3203547985872
              ],
              [
                -53.85722663118899,
                2.319849324457501
              ],
              [
                -53.855767122163826,
                2.319436303803438
              ],
              [
                -53.854479624446036,
                2.317583821053486
              ],
              [
                -53.85233916987703,
                2.31450433341803
              ],
              [
                -53.85186168035297,
                2.310622358817425
              ],
              [
                -53.84863219341024,
                2.309756880681482
              ],
              [
                -53.84179121200706,
                2.308329886207359
              ],
              [
                -53.838685763479994,
                2.309792375185578
              ],
              [
                -53.835631768584676,
                2.311323874706928
              ],
              [
                -53.82932532003275,
                2.314705328500462
              ],
              [
                -53.82351336018032,
                2.317989317116411
              ],
              [
                -53.81999489201475,
                2.317699324947233
              ],
              [
                -53.81639089936062,
                2.317617337326249
              ],
              [
                -53.816385880907255,
                2.322200787943563
              ],
              [
                -53.81860788435989,
                2.326978236963863
              ],
              [
                -53.820045876649424,
                2.330070734263229
              ],
              [
                -53.82412183419527,
                2.32992374161401
              ],
              [
                -53.82515384624436,
                2.329886727207768
              ],
              [
                -53.83237177960518,
                2.329714251769111
              ],
              [
                -53.83649524140259,
                2.333966191024727
              ],
              [
                -53.83440925848824,
                2.340038678764291
              ],
              [
                -53.834100253651556,
                2.342850646410322
              ],
              [
                -53.827750304858846,
                2.341723647021102
              ],
              [
                -53.82478831801504,
                2.341198148637617
              ],
              [
                -53.818319395354486,
                2.342143659018463
              ],
              [
                -53.81434442497073,
                2.342778129355128
              ],
              [
                -53.81399192133113,
                2.342834654851064
              ],
              [
                -53.81387841991488,
                2.34339065400518
              ],
              [
                -53.812674897106675,
                2.349273088312207
              ],
              [
                -53.81217341241418,
                2.352548570707077
              ],
              [
                -53.81142941813213,
                2.356506030347314
              ],
              [
                -53.80851243446118,
                2.35759303392679
              ],
              [
                -53.80490546469481,
                2.357933548390122
              ],
              [
                -53.79807451642466,
                2.365146493676934
              ],
              [
                -53.79233153597104,
                2.365830990062986
              ],
              [
                -53.783535116953914,
                2.373117420160228
              ],
              [
                -53.77274015956999,
                2.373993393528677
              ],
              [
                -53.77127320233923,
                2.377424900500222
              ],
              [
                -53.76666622683727,
                2.379297383106405
              ],
              [
                -53.762803761446825,
                2.377745366453907
              ],
              [
                -53.76015228570082,
                2.376721372587352
              ],
              [
                -53.75743778946137,
                2.375456402353615
              ],
              [
                -53.753011314408816,
                2.375342895645498
              ],
              [
                -53.74746385193244,
                2.375235894516468
              ],
              [
                -53.742512406380094,
                2.3752854075138
              ],
              [
                -53.73860992650509,
                2.373030914676339
              ],
              [
                -53.74050189161554,
                2.37031894092251
              ],
              [
                -53.74395589837148,
                2.366992460905927
              ],
              [
                -53.745827374019214,
                2.366097469673168
              ],
              [
                -53.7514768357335,
                2.363395988096511
              ],
              [
                -53.74636537402698,
                2.361120492000252
              ],
              [
                -53.74129991698049,
                2.361973987452974
              ],
              [
                -53.73867091660426,
                2.362415990542049
              ],
              [
                -53.73405596014538,
                2.358334499009637
              ],
              [
                -53.73096799012294,
                2.354985544962278
              ],
              [
                -53.72743999613473,
                2.351455067016137
              ],
              [
                -53.72327653652965,
                2.353751539871261
              ],
              [
                -53.724901999807784,
                2.34989056490926
              ],
              [
                -53.72791600764631,
                2.343129628302994
              ],
              [
                -53.73118646926495,
                2.340903132662083
              ],
              [
                -53.73307098092711,
                2.339674627713857
              ],
              [
                -53.73608193321534,
                2.337711162178894
              ],
              [
                -53.739110913386305,
                2.335736186726829
              ],
              [
                -53.73836092555937,
                2.332351170697325
              ],
              [
                -53.737521437153084,
                2.329527716786224
              ],
              [
                -53.73747491753813,
                2.329310697259414
              ],
              [
                -53.73643445316983,
                2.324392230650626
              ],
              [
                -53.73933941336204,
                2.324923742960181
              ],
              [
                -53.74045093278324,
                2.325127249929887
              ],
              [
                -53.74632238818279,
                2.321796264700228
              ],
              [
                -53.749790375384976,
                2.319828793327999
              ],
              [
                -53.748189370346324,
                2.313209816181722
              ],
              [
                -53.74485739915211,
                2.312316815214336
              ],
              [
                -53.73872193610815,
                2.310802847589295
              ],
              [
                -53.734646958737066,
                2.310539828061633
              ],
              [
                -53.72879151669686,
                2.312469323916338
              ],
              [
                -53.71913908024165,
                2.311590821787389
              ],
              [
                -53.7125511229832,
                2.310995343720331
              ],
              [
                -53.70711063162382,
                2.308371858943183
              ],
              [
                -53.70526714792394,
                2.308894359756879
              ],
              [
                -53.69777071694629,
                2.311019332728908
              ],
              [
                -53.69598472327705,
                2.310248341436742
              ],
              [
                -53.693950727320505,
                2.309370346798364
              ],
              [
                -53.6928352334021,
                2.307710860823258
              ],
              [
                -53.69223925371978,
                2.306824345712961
              ],
              [
                -53.69177926105212,
                2.306140344257188
              ],
              [
                -53.69126426678307,
                2.305373861349612
              ],
              [
                -53.690832773955215,
                2.304588386599631
              ],
              [
                -53.688319296429924,
                2.300018399144517
              ],
              [
                -53.68478132030136,
                2.297112432938536
              ],
              [
                -53.6799993215682,
                2.297400422410135
              ],
              [
                -53.68159834540767,
                2.30157438028101
              ],
              [
                -53.67938485437598,
                2.30160790733119
              ],
              [
                -53.675781881741635,
                2.301662905782317
              ],
              [
                -53.670423894294856,
                2.303874377857707
              ],
              [
                -53.6630809367697,
                2.304590880548523
              ],
              [
                -53.660632463796695,
                2.300073398772203
              ],
              [
                -53.6527750411206,
                2.302797387512366
              ],
              [
                -53.65357951733851,
                2.297609399402742
              ],
              [
                -53.654104024804575,
                2.294433440382345
              ],
              [
                -53.65435250560366,
                2.292928924794052
              ],
              [
                -53.65516600907766,
                2.289857477147272
              ],
              [
                -53.65554949346541,
                2.286774991435769
              ],
              [
                -53.648290571586166,
                2.28373301198041
              ],
              [
                -53.637796146910674,
                2.282939500046622
              ],
              [
                -53.633098179776916,
                2.282797985504591
              ],
              [
                -53.630357675103454,
                2.283543499500521
              ],
              [
                -53.626437731319136,
                2.284609486914011
              ],
              [
                -53.625290220365734,
                2.283754007421037
              ],
              [
                -53.62375773511218,
                2.282611018779391
              ],
              [
                -53.62223172281364,
                2.281472019112787
              ],
              [
                -53.62127476050364,
                2.280823505259974
              ],
              [
                -53.62073524127536,
                2.280458005597375
              ],
              [
                -53.61927727746832,
                2.27946903692265
              ],
              [
                -53.61418930093115,
                2.279511002997521
              ],
              [
                -53.61380679027548,
                2.280290026521752
              ],
              [
                -53.611329833454796,
                2.285335484053697
              ],
              [
                -53.60603483983466,
                2.286920450452999
              ],
              [
                -53.60033889047764,
                2.286806982394947
              ],
              [
                -53.59662192941282,
                2.284486494146891
              ],
              [
                -53.59422042587992,
                2.28075549302021
              ],
              [
                -53.59219643028759,
                2.278595530216707
              ],
              [
                -53.59009545024317,
                2.276353525953232
              ],
              [
                -53.58741546930774,
                2.273532556484733
              ],
              [
                -53.58357652465272,
                2.26937708882007
              ],
              [
                -53.58060901622132,
                2.2711265838428
              ],
              [
                -53.57763504573416,
                2.273193066682328
              ],
              [
                -53.574287573578715,
                2.272741563713559
              ],
              [
                -53.56694162471584,
                2.271751059170517
              ],
              [
                -53.56533014425966,
                2.269813590978154
              ],
              [
                -53.564625150309645,
                2.268966577577334
              ],
              [
                -53.56055616968245,
                2.266527581049132
              ],
              [
                -53.55448472944072,
                2.261556109301416
              ],
              [
                -53.55000573270571,
                2.258776667990286
              ],
              [
                -53.545364765388086,
                2.258170648680848
              ],
              [
                -53.54010231928141,
                2.257595651961035
              ],
              [
                -53.53579884835207,
                2.258030663598748
              ],
              [
                -53.53069238018606,
                2.257479639764724
              ],
              [
                -53.52657891280636,
                2.261620618960809
              ],
              [
                -53.52577940402723,
                2.262276621191585
              ],
              [
                -53.523995923674406,
                2.263738089565157
              ],
              [
                -53.519694441886,
                2.266883591542975
              ],
              [
                -53.50935200752352,
                2.262413124014769
              ],
              [
                -53.5072180471642,
                2.261941109583973
              ],
              [
                -53.50456455837027,
                2.261353612782961
              ],
              [
                -53.50150858204845,
                2.261600117112113
              ],
              [
                -53.494796138904924,
                2.260768119702453
              ],
              [
                -53.4930296406165,
                2.261684603828808
              ],
              [
                -53.48873914806323,
                2.263910607131544
              ],
              [
                -53.48572570221112,
                2.264944067052618
              ],
              [
                -53.48479821341258,
                2.265262073566396
              ],
              [
                -53.478040243715085,
                2.260871615653688
              ],
              [
                -53.472529293346916,
                2.257115118776697
              ],
              [
                -53.45813388082365,
                2.261476085150556
              ],
              [
                -53.45444638689431,
                2.264647578119257
              ],
              [
                -53.455487406847794,
                2.270889034434381
              ],
              [
                -53.45595937774367,
                2.273721522062319
              ],
              [
                -53.45891935917403,
                2.275133493278155
              ],
              [
                -53.46245283762018,
                2.276818997793652
              ],
              [
                -53.463156346731765,
                2.27715447770704
              ],
              [
                -53.46520232751515,
                2.278130471636796
              ],
              [
                -53.46747628525664,
                2.28380894623138
              ],
              [
                -53.46875029422347,
                2.288128421870735
              ],
              [
                -53.46077384740488,
                2.291384386855309
              ],
              [
                -53.45814385074426,
                2.289939906965204
              ],
              [
                -53.45465838705213,
                2.288018419088969
              ],
              [
                -53.45020442101642,
                2.285876441422872
              ],
              [
                -53.446662966645064,
                2.284151932173918
              ],
              [
                -53.44609694058172,
                2.283875956894794
              ],
              [
                -53.44158998096552,
                2.281559951416131
              ],
              [
                -53.4330795477119,
                2.288276910355963
              ],
              [
                -53.42123813764136,
                2.290234400037552
              ],
              [
                -53.415783656116936,
                2.295365839871335
              ],
              [
                -53.413546656666455,
                2.297470331625745
              ],
              [
                -53.41162069973941,
                2.297572346299148
              ],
              [
                -53.40450076007271,
                2.297948830398983
              ],
              [
                -53.40243575306896,
                2.301258803883055
              ],
              [
                -53.40150174188941,
                2.302935777004112
              ],
              [
                -53.3983587814722,
                2.308582248002209
              ],
              [
                -53.39050882785118,
                2.308762735206677
              ],
              [
                -53.38512738844937,
                2.308886758602609
              ],
              [
                -53.38063240441342,
                2.313998199837437
              ],
              [
                -53.37935390265165,
                2.315451710381021
              ],
              [
                -53.376606931467634,
                2.318215683392205
              ],
              [
                -53.374675447435365,
                2.318791676783149
              ],
              [
                -53.36705700058963,
                2.321063153603316
              ],
              [
                -53.35944405022229,
                2.334127580820049
              ],
              [
                -53.35943853818878,
                2.337428028003372
              ],
              [
                -53.35943305033732,
                2.340163512091238
              ],
              [
                -53.35942604723523,
                2.343697514657321
              ],
              [
                -53.359420043229406,
                2.346909975445546
              ],
              [
                -53.35941453235508,
                2.349704940119445
              ],
              [
                -53.35251907111018,
                2.353447911958018
              ],
              [
                -53.34781711763394,
                2.355019429533591
              ],
              [
                -53.34313515664698,
                2.354687901276915
              ],
              [
                -53.337633185378664,
                2.353967909436621
              ],
              [
                -53.33579368777804,
                2.351329424080419
              ],
              [
                -53.33100923106443,
                2.346192955033477
              ],
              [
                -53.32699975445705,
                2.346003983018913
              ],
              [
                -53.322792790661495,
                2.347462442541046
              ],
              [
                -53.319843812821055,
                2.344225482965732
              ],
              [
                -53.31644133233711,
                2.340174015878611
              ],
              [
                -53.3158333309828,
                2.335552519654865
              ],
              [
                -53.31555533854688,
                2.333489568836575
              ],
              [
                -53.31510932465912,
                2.330181077580048
              ],
              [
                -53.315596858625554,
                2.326188116363109
              ],
              [
                -53.31273985766691,
                2.324451607442777
              ],
              [
                -53.30848790396618,
                2.317297673547094
              ],
              [
                -53.30547491697248,
                2.315870177442048
              ],
              [
                -53.293838004812855,
                2.308172708918514
              ],
              [
                -53.29055702587854,
                2.309158210035642
              ],
              [
                -53.28763255397979,
                2.308688702336225
              ],
              [
                -53.28701256524157,
                2.308588725826997
              ],
              [
                -53.28227557806886,
                2.310122222678278
              ],
              [
                -53.27697509769897,
                2.299292761909769
              ],
              [
                -53.273251634725206,
                2.297355778113279
              ],
              [
                -53.26674067242765,
                2.293375310563841
              ],
              [
                -53.26371971168,
                2.291383322378893
              ],
              [
                -53.26111171387197,
                2.290153838050192
              ],
              [
                -53.2615612227059,
                2.284158390691421
              ],
              [
                -53.26161072057794,
                2.283495878292529
              ],
              [
                -53.26176122751325,
                2.280454896386849
              ],
              [
                -53.26154372198986,
                2.277316920033621
              ],
              [
                -53.25646278805159,
                2.276116920714429
              ],
              [
                -53.24530786303937,
                2.27348293938395
              ],
              [
                -53.234132922618876,
                2.275801429765371
              ],
              [
                -53.23110394843685,
                2.269038469625572
              ],
              [
                -53.22993196653039,
                2.265574488236134
              ],
              [
                -53.22897494894874,
                2.262765536379789
              ],
              [
                -53.231706453864305,
                2.260942545867577
              ],
              [
                -53.235985411531075,
                2.258102068887234
              ],
              [
                -53.240546400332036,
                2.255074561286452
              ],
              [
                -53.24485286279816,
                2.252285603949689
              ],
              [
                -53.248336345811204,
                2.249732133719605
              ],
              [
                -53.252612811467735,
                2.246998138985431
              ],
              [
                -53.25300831367878,
                2.247283641134987
              ],
              [
                -53.25936374286359,
                2.251869612415191
              ],
              [
                -53.259853270634835,
                2.248304624231682
              ],
              [
                -53.26054925789309,
                2.245067166845874
              ],
              [
                -53.262033721907464,
                2.238163189065641
              ],
              [
                -53.26219675350231,
                2.23588271588882
              ],
              [
                -53.26234275813907,
                2.233839741063514
              ],
              [
                -53.26255074461734,
                2.230940247180363
              ],
              [
                -53.26269773624831,
                2.228889759406112
              ],
              [
                -53.26681821259146,
                2.224144812503676
              ],
              [
                -53.27088669287015,
                2.224002797756035
              ],
              [
                -53.27179768966329,
                2.22397081626162
              ],
              [
                -53.2751486458333,
                2.223853308120379
              ],
              [
                -53.27913512167544,
                2.223713829329376
              ],
              [
                -53.27692562855473,
                2.221107834653367
              ],
              [
                -53.27809661649409,
                2.214838375352309
              ],
              [
                -53.28005711430171,
                2.207719445160345
              ],
              [
                -53.28318958893995,
                2.20114847953529
              ],
              [
                -53.28641359220795,
                2.197010985116776
              ],
              [
                -53.283113101541424,
                2.191041551339377
              ],
              [
                -53.279478613519046,
                2.187152564088861
              ],
              [
                -53.275835670919705,
                2.188652560606413
              ],
              [
                -53.27570516089704,
                2.188366556542338
              ],
              [
                -53.27400768260667,
                2.184651095691547
              ],
              [
                -53.27164767227473,
                2.179484125666433
              ],
              [
                -53.26766170410845,
                2.17075766373686
              ],
              [
                -53.266925209619465,
                2.169146706641596
              ],
              [
                -53.26691172772067,
                2.169116201848245
              ],
              [
                -53.26377972827911,
                2.171828167157594
              ],
              [
                -53.27224467155524,
                2.191509026765171
              ],
              [
                -53.268780688538776,
                2.190373551545838
              ],
              [
                -53.26713173292957,
                2.189920544169238
              ],
              [
                -53.266859229811914,
                2.189846057600187
              ],
              [
                -53.26479073510127,
                2.189278036889733
              ],
              [
                -53.26075777521203,
                2.190643537212657
              ],
              [
                -53.25695230263561,
                2.192457524503838
              ],
              [
                -53.25540128287353,
                2.196890989379507
              ],
              [
                -53.25496328748229,
                2.198141476599828
              ],
              [
                -53.25194182884106,
                2.199017478611277
              ],
              [
                -53.24633085265147,
                2.200643956633135
              ],
              [
                -53.245792850667065,
                2.202797971572587
              ],
              [
                -53.24502037299504,
                2.205893426928353
              ],
              [
                -53.242643382245696,
                2.209091922072373
              ],
              [
                -53.232454955813,
                2.205846942359843
              ],
              [
                -53.2304114658005,
                2.202471950072603
              ],
              [
                -53.227861987497725,
                2.198260995412236
              ],
              [
                -53.225259003639245,
                2.197070980840875
              ],
              [
                -53.22007355497876,
                2.19405148913027
              ],
              [
                -53.21701405120604,
                2.196263985882211
              ],
              [
                -53.214824088775,
                2.194266520026729
              ],
              [
                -53.20899761049734,
                2.197833483552283
              ],
              [
                -53.20804763781107,
                2.198414956235839
              ],
              [
                -53.19893967364305,
                2.199749967187547
              ],
              [
                -53.198058184690616,
                2.199673464445064
              ],
              [
                -53.1960337135805,
                2.199496476045492
              ],
              [
                -53.1919137256687,
                2.199729459161858
              ],
              [
                -53.18430629164654,
                2.199806474281914
              ],
              [
                -53.18005232413839,
                2.201741935568852
              ],
              [
                -53.177571322680656,
                2.203118923268163
              ],
              [
                -53.174852872806746,
                2.204627900978919
              ],
              [
                -53.17108039038495,
                2.205338429892047
              ],
              [
                -53.16551240414629,
                2.206368404036551
              ],
              [
                -53.16266843140727,
                2.206986400876116
              ],
              [
                -53.15958795127138,
                2.207655378288792
              ],
              [
                -53.149535019368734,
                2.209838889048631
              ],
              [
                -53.14316706251158,
                2.211221876492282
              ],
              [
                -53.13817512133568,
                2.212306352429047
              ],
              [
                -53.13228312657598,
                2.21256333682116
              ],
              [
                -53.11722222937926,
                2.213480337794048
              ],
              [
                -53.10362685020645,
                2.21430732184293
              ],
              [
                -53.09842688926518,
                2.216100318792714
              ],
              [
                -53.083465987286964,
                2.214300804636527
              ],
              [
                -53.078494527979224,
                2.212227329483811
              ],
              [
                -53.06582562214849,
                2.201203425071121
              ],
              [
                -53.060281637554695,
                2.193261452482205
              ],
              [
                -53.057979668269745,
                2.190164981265027
              ],
              [
                -53.05684168752879,
                2.188646981450989
              ],
              [
                -53.05195870396602,
                2.182132533166678
              ],
              [
                -53.045746767829094,
                2.177883076762968
              ],
              [
                -53.03983980052121,
                2.173908614549089
              ],
              [
                -53.02902189505669,
                2.172715606540661
              ],
              [
                -53.01881894541845,
                2.171428121379079
              ],
              [
                -53.01604096062034,
                2.170938104937795
              ],
              [
                -53.01482547302249,
                2.170723107061633
              ],
              [
                -53.008051514834136,
                2.169583100922004
              ],
              [
                -53.00282104535158,
                2.167474646978813
              ],
              [
                -53.000941567474186,
                2.166717122670435
              ],
              [
                -52.99138813862015,
                2.161402654692464
              ],
              [
                -52.987322182318536,
                2.158743702934351
              ],
              [
                -52.983961680711865,
                2.157642687568681
              ],
              [
                -52.98042522770546,
                2.158585204953877
              ],
              [
                -52.974761741133676,
                2.160094165760184
              ],
              [
                -52.972515758328456,
                2.160846669853114
              ],
              [
                -52.97170377482211,
                2.161118168721228
              ],
              [
                -52.968740777983676,
                2.161726663597527
              ],
              [
                -52.964123339391286,
                2.1627216402872
              ],
              [
                -52.95922235468199,
                2.163940640332697
              ],
              [
                -52.95527639138397,
                2.165269620900945
              ],
              [
                -52.9498049083092,
                2.167457125036781
              ],
              [
                -52.94362546023245,
                2.169206605000133
              ],
              [
                -52.941627977644146,
                2.173578050995054
              ],
              [
                -52.941632966632355,
                2.17350308151792
              ],
              [
                -52.93899600805321,
                2.179145515473876
              ],
              [
                -52.93894750131589,
                2.179380012828087
              ],
              [
                -52.938942482327846,
                2.17945502330487
              ],
              [
                -52.93773650313588,
                2.184360507816149
              ],
              [
                -52.93895349840268,
                2.188906449565531
              ],
              [
                -52.93610652092353,
                2.192342920787656
              ],
              [
                -52.933424026671624,
                2.191294447260709
              ],
              [
                -52.930574063925526,
                2.189073964837592
              ],
              [
                -52.925776586160666,
                2.191494925524746
              ],
              [
                -52.92008360952533,
                2.190701454303976
              ],
              [
                -52.912272190545096,
                2.189613430486776
              ],
              [
                -52.90924269089638,
                2.187871479685194
              ],
              [
                -52.90490924784599,
                2.187345469022721
              ],
              [
                -52.903882743457736,
                2.192130423399623
              ],
              [
                -52.90030974752066,
                2.197093884748702
              ],
              [
                -52.89697280495256,
                2.202078350016609
              ],
              [
                -52.894916294041344,
                2.205539815278567
              ],
              [
                -52.892237797459906,
                2.208825797567702
              ],
              [
                -52.89151681047419,
                2.211921283784724
              ],
              [
                -52.89073133195299,
                2.214944243485405
              ],
              [
                -52.886239871461875,
                2.219458241649172
              ],
              [
                -52.88577285730748,
                2.223917185438271
              ],
              [
                -52.88578634886328,
                2.225062692461215
              ],
              [
                -52.885812364987316,
                2.227332688652814
              ],
              [
                -52.88586333927859,
                2.231749129895067
              ],
              [
                -52.885903361933714,
                2.235221123713437
              ],
              [
                -52.880637376869906,
                2.23614259764822
              ],
              [
                -52.87966839236817,
                2.236684117571643
              ],
              [
                -52.87642243913587,
                2.238501601890141
              ],
              [
                -52.87422993583471,
                2.242980546983526
              ],
              [
                -52.87338642574093,
                2.245877558523634
              ],
              [
                -52.87309893541333,
                2.246863542559788
              ],
              [
                -52.87236444268515,
                2.249383518760704
              ],
              [
                -52.871522461580454,
                2.252274499339979
              ],
              [
                -52.86903596707562,
                2.257458949407534
              ],
              [
                -52.864320484306994,
                2.25814595713079
              ],
              [
                -52.86130101893837,
                2.258614436911927
              ],
              [
                -52.861221009484446,
                2.258628963785355
              ],
              [
                -52.85804855313524,
                2.259208930740281
              ],
              [
                -52.85337108816424,
                2.259728439641877
              ],
              [
                -52.851736583012325,
                2.267423874441192
              ],
              [
                -52.85218908168796,
                2.26809537189961
              ],
              [
                -52.85337356498605,
                2.269852360016689
              ],
              [
                -52.85566857250733,
                2.275226340251297
              ],
              [
                -52.85159958480618,
                2.277475321267026
              ],
              [
                -52.84587960615419,
                2.277550309926176
              ],
              [
                -52.8410086467348,
                2.27739281147766
              ],
              [
                -52.84088014462087,
                2.277388307468266
              ],
              [
                -52.839782665968364,
                2.278050323499112
              ],
              [
                -52.837270179789776,
                2.279565799122894
              ],
              [
                -52.83851966816459,
                2.282818780814724
              ],
              [
                -52.839479656132305,
                2.287337243580687
              ],
              [
                -52.834571710155636,
                2.286598243173728
              ],
              [
                -52.82510624643086,
                2.289459713174589
              ],
              [
                -52.82069879220453,
                2.291296204942421
              ],
              [
                -52.80476240522804,
                2.296832666173124
              ],
              [
                -52.804236410794076,
                2.297015155729722
              ],
              [
                -52.80070092195838,
                2.29762814731459
              ],
              [
                -52.79808894607691,
                2.300401123101989
              ],
              [
                -52.79704946788954,
                2.301504623057677
              ],
              [
                -52.79266399223307,
                2.304265096358867
              ],
              [
                -52.785882031602505,
                2.308573580064793
              ],
              [
                -52.78311103783165,
                2.308162083049699
              ],
              [
                -52.781846573606366,
                2.30797505795033
              ],
              [
                -52.78101605192625,
                2.30924755178745
              ],
              [
                -52.7772240992134,
                2.315059530931599
              ],
              [
                -52.77459760111118,
                2.312573526466793
              ],
              [
                -52.77326110572745,
                2.311308033884655
              ],
              [
                -52.77041262458054,
                2.313771036744216
              ],
              [
                -52.76727763846669,
                2.316054011767803
              ],
              [
                -52.765482663425544,
                2.317361495044277
              ],
              [
                -52.76407367509601,
                2.317501006613575
              ],
              [
                -52.76216418520201,
                2.317690011674962
              ],
              [
                -52.76003622035368,
                2.31932449455504
              ],
              [
                -52.75949571094831,
                2.31973949447749
              ],
              [
                -52.756866710753144,
                2.320755457520789
              ],
              [
                -52.7430558293003,
                2.326093950699003
              ],
              [
                -52.74089834558331,
                2.327966417895955
              ],
              [
                -52.740457326503204,
                2.328348403079661
              ],
              [
                -52.737261882433785,
                2.331820909738962
              ],
              [
                -52.73373689495236,
                2.331199894941056
              ],
              [
                -52.73115492091275,
                2.330744915287615
              ],
              [
                -52.72406996693874,
                2.33342987719015
              ],
              [
                -52.71600302799915,
                2.338444352454378
              ],
              [
                -52.71592700829468,
                2.342261323698917
              ],
              [
                -52.7111745317955,
                2.346297298880361
              ],
              [
                -52.70360309798313,
                2.3527282183535
              ],
              [
                -52.69917213764837,
                2.355587203869708
              ],
              [
                -52.6947001425901,
                2.358472672182638
              ],
              [
                -52.692349152331516,
                2.359989688779044
              ],
              [
                -52.68879119915509,
                2.362286161514267
              ],
              [
                -52.68488471402252,
                2.365702630280895
              ],
              [
                -52.67969225544148,
                2.370135611491755
              ],
              [
                -52.674878285985216,
                2.372307079677604
              ],
              [
                -52.67204180411439,
                2.373732094748209
              ],
              [
                -52.66986281345685,
                2.375908054768363
              ],
              [
                -52.66437834374519,
                2.380809506575485
              ],
              [
                -52.66186237477543,
                2.384940981759654
              ],
              [
                -52.65944237819269,
                2.388915457072674
              ],
              [
                -52.65710141032415,
                2.39513342166547
              ],
              [
                -52.65829539499368,
                2.39841038806891
              ],
              [
                -52.658412412058794,
                2.398793396420329
              ],
              [
                -52.66023389550327,
                2.404756360184805
              ],
              [
                -52.65602490906016,
                2.409373834369691
              ],
              [
                -52.64830045404548,
                2.416605281015932
              ],
              [
                -52.643284503154106,
                2.421301230358063
              ],
              [
                -52.64226900619824,
                2.427229196389724
              ],
              [
                -52.64161250945634,
                2.431062643247266
              ],
              [
                -52.64050800993391,
                2.437510624587618
              ],
              [
                -52.63354706094244,
                2.443814046946828
              ],
              [
                -52.62851309626047,
                2.448239040145348
              ],
              [
                -52.62611760750598,
                2.446212548569311
              ],
              [
                -52.620361141162135,
                2.446662023452792
              ],
              [
                -52.61717016014677,
                2.446911030617376
              ],
              [
                -52.61272919498085,
                2.446558033587667
              ],
              [
                -52.60729871506971,
                2.450939506941572
              ],
              [
                -52.6054342429777,
                2.453876994655658
              ],
              [
                -52.61102972636712,
                2.45951994989594
              ],
              [
                -52.61460668940632,
                2.463126924762993
              ],
              [
                -52.614108691621745,
                2.469704381350958
              ],
              [
                -52.61119969869417,
                2.475733814957186
              ],
              [
                -52.59993429885063,
                2.475073828722255
              ],
              [
                -52.599495773893445,
                2.475047817754353
              ],
              [
                -52.5959423235911,
                2.474719331842476
              ],
              [
                -52.59289033367938,
                2.474806340180742
              ],
              [
                -52.58766586673082,
                2.475104814284279
              ],
              [
                -52.585999888849344,
                2.485117750264133
              ],
              [
                -52.58505786643551,
                2.490775235129589
              ],
              [
                -52.58487488632496,
                2.491876714315258
              ],
              [
                -52.584507880109236,
                2.494083706657277
              ],
              [
                -52.576388440556215,
                2.502102148403294
              ],
              [
                -52.564780514264385,
                2.512707060695158
              ],
              [
                -52.56342300552558,
                2.513106557408038
              ],
              [
                -52.55949706231028,
                2.514262550896181
              ],
              [
                -52.55406960305499,
                2.516692525884821
              ],
              [
                -52.55336310712078,
                2.5219744734308
              ],
              [
                -52.55219307756068,
                2.526772960216319
              ],
              [
                -52.55579107052307,
                2.527120933027626
              ],
              [
                -52.55675408182221,
                2.527213441713977
              ],
              [
                -52.559566064330454,
                2.535901397498011
              ],
              [
                -52.56156854093212,
                2.540298857223321
              ],
              [
                -52.5629294978471,
                2.545057845175266
              ],
              [
                -52.55821405807434,
                2.549130787808766
              ],
              [
                -52.555003081018455,
                2.552819250678489
              ],
              [
                -52.55016359408787,
                2.55694372689217
              ],
              [
                -52.548932095669684,
                2.556794233508564
              ],
              [
                -52.54539863529396,
                2.556365740274444
              ],
              [
                -52.54090168627686,
                2.556841252325871
              ],
              [
                -52.539626675890375,
                2.560311716405945
              ],
              [
                -52.53573971190182,
                2.571431117616449
              ],
              [
                -52.52699525542892,
                2.577260575376378
              ],
              [
                -52.52856825543952,
                2.58034007621718
              ],
              [
                -52.53051721458371,
                2.584154053012215
              ],
              [
                -52.53202370573922,
                2.58811650960144
              ],
              [
                -52.534684184957705,
                2.594719463978877
              ],
              [
                -52.53992066118181,
                2.598922443217531
              ],
              [
                -52.541703642319504,
                2.600353911763321
              ],
              [
                -52.55227006573788,
                2.607046359376982
              ],
              [
                -52.54910060725493,
                2.613840319303803
              ],
              [
                -52.54824611451588,
                2.615672302069522
              ],
              [
                -52.54734261807846,
                2.617608315082683
              ],
              [
                -52.545942621696966,
                2.620608797403911
              ],
              [
                -52.54980609938002,
                2.62493072831728
              ],
              [
                -52.55614352692841,
                2.632021689600688
              ],
              [
                -52.55707652099531,
                2.636090690233001
              ],
              [
                -52.554074029918,
                2.641255640687913
              ],
              [
                -52.55199304181028,
                2.645445104358928
              ],
              [
                -52.54984207875909,
                2.648057586130323
              ],
              [
                -52.546971597234055,
                2.649179056158973
              ],
              [
                -52.54509058720066,
                2.649352565242242
              ],
              [
                -52.540988635478655,
                2.649731577057619
              ],
              [
                -52.53445517037513,
                2.647994069920867
              ],
              [
                -52.52968969222971,
                2.656687512783148
              ],
              [
                -52.52911271985366,
                2.660452969979126
              ],
              [
                -52.52814121157434,
                2.663220976071688
              ],
              [
                -52.526609751355174,
                2.668226950148456
              ],
              [
                -52.52569524359714,
                2.671216396693769
              ],
              [
                -52.524273759742776,
                2.675863369340001
              ],
              [
                -52.523281750426015,
                2.679105844064645
              ],
              [
                -52.51547330800224,
                2.688724265193459
              ],
              [
                -52.51481929823836,
                2.68952929426023
              ],
              [
                -52.51157480806424,
                2.694034222209507
              ],
              [
                -52.51056282499075,
                2.696977217029271
              ],
              [
                -52.50954584093462,
                2.699964199534583
              ],
              [
                -52.50888885077355,
                2.701894675641801
              ],
              [
                -52.50725536662919,
                2.705852643048177
              ],
              [
                -52.503736377855006,
                2.714379600435247
              ],
              [
                -52.50147589505048,
                2.721022525606442
              ],
              [
                -52.49953039041111,
                2.724044517498664
              ],
              [
                -52.49763692038065,
                2.727145494849351
              ],
              [
                -52.495861906600645,
                2.730052464614658
              ],
              [
                -52.49279993995417,
                2.735066935979826
              ],
              [
                -52.48653497454616,
                2.745327369997972
              ],
              [
                -52.48541098292091,
                2.749097841886086
              ],
              [
                -52.48676046745342,
                2.752033327625748
              ],
              [
                -52.488370476963766,
                2.755535798452455
              ],
              [
                -52.48631497939185,
                2.757725798166077
              ],
              [
                -52.48426347892203,
                2.759572751307783
              ],
              [
                -52.48171749771295,
                2.761866754094026
              ],
              [
                -52.481552020384356,
                2.768395215812947
              ],
              [
                -52.48189451229745,
                2.770267195691261
              ],
              [
                -52.48312599919515,
                2.776995624526821
              ],
              [
                -52.48124501441953,
                2.781777098945034
              ],
              [
                -52.480797502089395,
                2.782915092713839
              ],
              [
                -52.48030652301813,
                2.784164570676208
              ],
              [
                -52.477726543407805,
                2.790722542234691
              ],
              [
                -52.47258406207477,
                2.79857298863402
              ],
              [
                -52.46651809121682,
                2.808834409572723
              ],
              [
                -52.46178413798934,
                2.816811336195828
              ],
              [
                -52.461465657016525,
                2.817284852715379
              ],
              [
                -52.45781064927698,
                2.822727283709675
              ],
              [
                -52.454020171865935,
                2.832317217237073
              ],
              [
                -52.453932186372086,
                2.832586228295173
              ],
              [
                -52.45070170995462,
                2.842455155044809
              ],
              [
                -52.4480222056827,
                2.847252626964073
              ],
              [
                -52.44672521177934,
                2.849574125986206
              ],
              [
                -52.44486523338895,
                2.852905077623856
              ],
              [
                -52.44100827446114,
                2.859810022112111
              ],
              [
                -52.43900379596974,
                2.863399003852926
              ],
              [
                -52.42646683781533,
                2.886764330022704
              ],
              [
                -52.423103887362416,
                2.888807829168219
              ],
              [
                -52.41874193173063,
                2.891151306781045
              ],
              [
                -52.41603941492731,
                2.892602776390685
              ],
              [
                -52.41177195166256,
                2.894895269398252
              ],
              [
                -52.40888598704856,
                2.89404628527064
              ],
              [
                -52.40237654203339,
                2.892132761253077
              ],
              [
                -52.39624456705702,
                2.893609257306552
              ],
              [
                -52.391951099491266,
                2.899662732573835
              ],
              [
                -52.38253865939473,
                2.911009132148952
              ],
              [
                -52.38168616068544,
                2.915106111682361
              ],
              [
                -52.38452562406769,
                2.914807130954082
              ],
              [
                -52.38923509453286,
                2.914310600384621
              ],
              [
                -52.391179590866784,
                2.914690603468873
              ],
              [
                -52.39248508069205,
                2.914945616183601
              ],
              [
                -52.395348082084276,
                2.919226570915401
              ],
              [
                -52.4008410416894,
                2.924781556659649
              ],
              [
                -52.39438054995973,
                2.936296447189317
              ],
              [
                -52.39530505217965,
                2.939662434636412
              ],
              [
                -52.39083357719554,
                2.944771905486694
              ],
              [
                -52.38788108989435,
                2.949283888197631
              ],
              [
                -52.38446111355202,
                2.954510342630161
              ],
              [
                -52.382535117947526,
                2.959428274862014
              ],
              [
                -52.37937814403891,
                2.968919721046595
              ],
              [
                -52.373478711037286,
                2.983674104629118
              ],
              [
                -52.37331170476547,
                2.987177593626023
              ],
              [
                -52.37331467681467,
                2.989449560457175
              ],
              [
                -52.37332367544562,
                2.997511999074481
              ],
              [
                -52.36800520608688,
                3.00320347734814
              ],
              [
                -52.366511728216096,
                3.011107400450441
              ],
              [
                -52.36545775068197,
                3.018041342634509
              ],
              [
                -52.364668741943255,
                3.021004354203356
              ],
              [
                -52.35881428078723,
                3.025523793563484
              ],
              [
                -52.35557579327531,
                3.028023806397849
              ],
              [
                -52.35355732304714,
                3.033487237628638
              ],
              [
                -52.3505908230795,
                3.039745191793114
              ],
              [
                -52.34822884539833,
                3.044814166676731
              ],
              [
                -52.34572036039844,
                3.050686103769563
              ],
              [
                -52.34289889626586,
                3.05729007350422
              ],
              [
                -52.34092589487441,
                3.059961044628288
              ],
              [
                -52.33941541531445,
                3.062512526798121
              ],
              [
                -52.33319543618362,
                3.073014953270263
              ],
              [
                -52.33097944930281,
                3.076107428272711
              ],
              [
                -52.32839598736979,
                3.080644399812501
              ],
              [
                -52.330951466420764,
                3.083403375223195
              ],
              [
                -52.340736865859554,
                3.093967302057657
              ],
              [
                -52.34378887727043,
                3.097160766588138
              ],
              [
                -52.3449363677774,
                3.098361283517632
              ],
              [
                -52.346540860558584,
                3.100848248468072
              ],
              [
                -52.346889846735955,
                3.104130240209054
              ],
              [
                -52.34650783371321,
                3.10808020287011
              ],
              [
                -52.34615584871962,
                3.111466697561617
              ],
              [
                -52.3447318397758,
                3.113463682702125
              ],
              [
                -52.341360867212664,
                3.118191629517253
              ],
              [
                -52.33940989039005,
                3.121463107332248
              ],
              [
                -52.340759367705765,
                3.124489100323477
              ],
              [
                -52.34401636616465,
                3.124206583747794
              ],
              [
                -52.34416435613541,
                3.124205583818929
              ],
              [
                -52.34970481160962,
                3.12415656956468
              ],
              [
                -52.35187029159073,
                3.12638508760265
              ],
              [
                -52.35258777134898,
                3.129303565091862
              ],
              [
                -52.350574788041556,
                3.13701851117276
              ],
              [
                -52.34672681157177,
                3.137578995502127
              ],
              [
                -52.341923843410946,
                3.138925489806513
              ],
              [
                -52.340753879365394,
                3.139358967204457
              ],
              [
                -52.33860088100329,
                3.140157962566175
              ],
              [
                -52.338942860589086,
                3.142562440552699
              ],
              [
                -52.339022877789304,
                3.143126937558276
              ],
              [
                -52.3399448732106,
                3.145827421684043
              ],
              [
                -52.34045336303807,
                3.149617903848976
              ],
              [
                -52.33753338264796,
                3.153577394310044
              ],
              [
                -52.33375740541648,
                3.159380841226472
              ],
              [
                -52.33299591869056,
                3.166365271020725
              ],
              [
                -52.33262091656093,
                3.172676249787763
              ],
              [
                -52.32788492489717,
                3.174116710420137
              ],
              [
                -52.32289848598556,
                3.175543699036776
              ],
              [
                -52.32007849920606,
                3.17648619406478
              ],
              [
                -52.317488530859805,
                3.177351690743237
              ],
              [
                -52.31380504272999,
                3.176832674832192
              ],
              [
                -52.31019205711125,
                3.176313200955946
              ],
              [
                -52.30712557648998,
                3.17625120905147
              ],
              [
                -52.3074450937278,
                3.179784654943147
              ],
              [
                -52.307967063575674,
                3.185562626883109
              ],
              [
                -52.309092052301644,
                3.198013053398005
              ],
              [
                -52.309235067725545,
                3.199338028991018
              ],
              [
                -52.309822545259244,
                3.204756978517684
              ],
              [
                -52.30355610177682,
                3.211856424983869
              ],
              [
                -52.29738063728585,
                3.219804381405485
              ],
              [
                -52.29418966872373,
                3.228385810608707
              ],
              [
                -52.29140867684694,
                3.233468271011024
              ],
              [
                -52.27962776908121,
                3.237618233048822
              ],
              [
                -52.27303530494568,
                3.242288203676153
              ],
              [
                -52.268542346180205,
                3.244710685322815
              ],
              [
                -52.267664351819036,
                3.248042139082163
              ],
              [
                -52.26626183822438,
                3.253368119325228
              ],
              [
                -52.26264037119429,
                3.256220111277255
              ],
              [
                -52.2573364084953,
                3.255476105217276
              ],
              [
                -52.253689914649826,
                3.253824094406238
              ],
              [
                -52.25163344209382,
                3.251334112311963
              ],
              [
                -52.250102966807376,
                3.248986136437782
              ],
              [
                -52.248455457415346,
                3.246460149786233
              ],
              [
                -52.24445351853018,
                3.243726691565379
              ],
              [
                -52.24081402510855,
                3.243271183196031
              ],
              [
                -52.23655554115868,
                3.24472366088465
              ],
              [
                -52.23151308979824,
                3.250408618901407
              ],
              [
                -52.22244466293787,
                3.264126021537948
              ],
              [
                -52.21814418703071,
                3.270630475980997
              ],
              [
                -52.21722118084482,
                3.272027460550982
              ],
              [
                -52.21636269125282,
                3.273325946855627
              ],
              [
                -52.21468220481989,
                3.275909424576729
              ],
              [
                -52.20460977955783,
                3.290479824541887
              ],
              [
                -52.20166777120279,
                3.294667797169318
              ],
              [
                -52.20087827549208,
                3.295156786302928
              ],
              [
                -52.197969802977504,
                3.296959283050549
              ],
              [
                -52.19155735856902,
                3.301757245710722
              ],
              [
                -52.19148936076046,
                3.304599215290473
              ],
              [
                -52.19218334217491,
                3.308452699959939
              ],
              [
                -52.19227336132154,
                3.30895019043167
              ],
              [
                -52.192284342039486,
                3.313822641476513
              ],
              [
                -52.187153384470115,
                3.321287102560764
              ],
              [
                -52.177827937783,
                3.334020496875171
              ],
              [
                -52.174020464796506,
                3.339659457634064
              ],
              [
                -52.170322471588854,
                3.344104931005491
              ],
              [
                -52.16788400227728,
                3.347036387826511
              ],
              [
                -52.162186037620664,
                3.354572839058247
              ],
              [
                -52.15723307309156,
                3.365733741575831
              ],
              [
                -52.154612068500256,
                3.372033732086092
              ],
              [
                -52.154098089546466,
                3.373214719359725
              ],
              [
                -52.15313657224444,
                3.375424694029431
              ],
              [
                -52.15013412120382,
                3.381957632674099
              ],
              [
                -52.13363221557947,
                3.414121412654417
              ],
              [
                -52.130825242766164,
                3.419330375858062
              ],
              [
                -52.130822748781746,
                3.41933434782831
              ],
              [
                -52.128013242982014,
                3.424546833998666
              ],
              [
                -52.11974830948921,
                3.44004669953746
              ],
              [
                -52.11656133272211,
                3.446527138373719
              ],
              [
                -52.113374843947234,
                3.45300809319658
              ],
              [
                -52.111115850817654,
                3.454967582011221
              ],
              [
                -52.09849144392406,
                3.465921496095003
              ],
              [
                -52.096617466584455,
                3.475304945597783
              ],
              [
                -52.09599996043549,
                3.478397429020376
              ],
              [
                -52.09573844664265,
                3.47970690445974
              ],
              [
                -52.09535945739249,
                3.481602882616385
              ],
              [
                -52.09481145133234,
                3.485583370670285
              ],
              [
                -52.09354044520484,
                3.489820825522239
              ],
              [
                -52.08532151925941,
                3.494937286688187
              ],
              [
                -52.08352704628065,
                3.496054272771717
              ],
              [
                -52.07696956122869,
                3.506374711199861
              ],
              [
                -52.07615557760427,
                3.509131686926169
              ],
              [
                -52.07611957679803,
                3.509253187032577
              ],
              [
                -52.075419069822615,
                3.515254625414584
              ],
              [
                -52.075323083622905,
                3.519152620255765
              ],
              [
                -52.07196360752255,
                3.520420109484328
              ],
              [
                -52.06757413379663,
                3.521345077682563
              ],
              [
                -52.06726261794532,
                3.521410598058771
              ],
              [
                -52.06376267004834,
                3.5222270694703
              ],
              [
                -52.06081568911354,
                3.523395052613534
              ],
              [
                -52.05388972623234,
                3.536604477928934
              ],
              [
                -52.05156674135452,
                3.541036942806041
              ],
              [
                -52.0492437564749,
                3.545469414678251
              ],
              [
                -52.04853078085048,
                3.546742892137341
              ],
              [
                -52.04327178111821,
                3.55614183070887
              ],
              [
                -52.037842820990136,
                3.566729759587625
              ],
              [
                -52.0365153150219,
                3.56932823716808
              ],
              [
                -52.035835853133285,
                3.570656723238765
              ],
              [
                -52.03543084862378,
                3.571366714911384
              ],
              [
                -52.03279734282014,
                3.575976664315822
              ],
              [
                -52.030633352919786,
                3.580190159810644
              ],
              [
                -52.02451639993988,
                3.592100072734474
              ],
              [
                -52.012918980590406,
                3.603509482522905
              ],
              [
                -52.003824529915526,
                3.612456909312449
              ],
              [
                -52.00181557231569,
                3.615863354678433
              ],
              [
                -51.998715575879245,
                3.62227232078071
              ],
              [
                -51.99569159985246,
                3.626767791753835
              ],
              [
                -51.99451710630649,
                3.632381750553765
              ],
              [
                -51.9930631083447,
                3.644785154081684
              ],
              [
                -51.992425621107785,
                3.650225100395653
              ],
              [
                -51.99161960003215,
                3.655697576385463
              ],
              [
                -51.99108962195323,
                3.659295061081306
              ],
              [
                -51.99065313353263,
                3.662261033393466
              ],
              [
                -51.98980561059594,
                3.668019990279267
              ],
              [
                -51.98859263435239,
                3.675504436483477
              ],
              [
                -51.98423363693195,
                3.683439371822821
              ],
              [
                -51.98117416568947,
                3.684820371271162
              ],
              [
                -51.98047717341834,
                3.685135338636741
              ],
              [
                -51.979072201549975,
                3.68855681714491
              ],
              [
                -51.97864818854737,
                3.690496809879023
              ],
              [
                -51.976548706430016,
                3.700102729232431
              ],
              [
                -51.97237771468934,
                3.715910128578829
              ],
              [
                -51.97124423481718,
                3.719504101955697
              ],
              [
                -51.96477626434226,
                3.725100057087249
              ],
              [
                -51.96061279338592,
                3.729353524120695
              ],
              [
                -51.95713282247172,
                3.731961983424807
              ],
              [
                -51.95230234178268,
                3.731131985962957
              ],
              [
                -51.949776878773655,
                3.730596991545993
              ],
              [
                -51.94474740260447,
                3.729533020680579
              ],
              [
                -51.93891395914763,
                3.728337005362091
              ],
              [
                -51.92999250106191,
                3.729714518796658
              ],
              [
                -51.9250945445198,
                3.735461968591181
              ],
              [
                -51.92087108612595,
                3.747317852398574
              ],
              [
                -51.92453004027706,
                3.759369779829314
              ],
              [
                -51.928595009870676,
                3.772761686742936
              ],
              [
                -51.92371704539418,
                3.78387211256117
              ],
              [
                -51.91812756525856,
                3.786543594257089
              ],
              [
                -51.91225410638295,
                3.788706553493303
              ],
              [
                -51.90897513964257,
                3.790190035012321
              ],
              [
                -51.90556815898362,
                3.791322550012174
              ],
              [
                -51.902802669732,
                3.79649701298632
              ],
              [
                -51.90062820068106,
                3.799054986270141
              ],
              [
                -51.89574124588707,
                3.799285987040418
              ],
              [
                -51.893362227506174,
                3.799463974505178
              ],
              [
                -51.892284249036955,
                3.799544478357421
              ],
              [
                -51.88544131490308,
                3.80380744680718
              ],
              [
                -51.88296730055428,
                3.805218446260028
              ],
              [
                -51.87880934997502,
                3.806751396944697
              ],
              [
                -51.87541586217494,
                3.807996918116774
              ],
              [
                -51.86254296461276,
                3.825383272961392
              ],
              [
                -51.84448307201275,
                3.845625106200622
              ],
              [
                -51.841083102155224,
                3.84899756086286
              ],
              [
                -51.83662910552972,
                3.850807066339134
              ],
              [
                -51.83509412899852,
                3.851430068993118
              ],
              [
                -51.83222813153842,
                3.852607553906763
              ],
              [
                -51.827887197923125,
                3.854797045671894
              ],
              [
                -51.82511019942531,
                3.860573487146757
              ],
              [
                -51.82353921975897,
                3.863841971515702
              ],
              [
                -51.82294869526701,
                3.865070974253443
              ],
              [
                -51.8221242271647,
                3.866786439324439
              ],
              [
                -51.82021573875498,
                3.870274913907346
              ],
              [
                -51.81594474669166,
                3.87238588526395
              ],
              [
                -51.81372976752815,
                3.87380537374769
              ],
              [
                -51.80496881821606,
                3.879419341191312
              ],
              [
                -51.801801360282965,
                3.881738812605391
              ],
              [
                -51.79545289773489,
                3.891633227118414
              ],
              [
                -51.79260141896655,
                3.896995215514179
              ],
              [
                -51.787543951326924,
                3.90687413979167
              ],
              [
                -51.77935698015604,
                3.925299979030628
              ],
              [
                -51.77848700663248,
                3.935870417459075
              ],
              [
                -51.77823899262871,
                3.941005864861845
              ],
              [
                -51.77803448479734,
                3.945242348843858
              ],
              [
                -51.77773600058213,
                3.951420782603884
              ],
              [
                -51.776588517325266,
                3.955768251259415
              ],
              [
                -51.77549250590309,
                3.95974523064333
              ],
              [
                -51.76613157350737,
                3.973322134455055
              ],
              [
                -51.75571062609232,
                3.987777028222879
              ],
              [
                -51.75047916751414,
                3.992267487564262
              ],
              [
                -51.74604120514754,
                3.995282458110975
              ],
              [
                -51.74281072798068,
                3.996995951823466
              ],
              [
                -51.734267265140076,
                3.998927941025546
              ],
              [
                -51.73118278675213,
                3.99916340461432
              ],
              [
                -51.7201218862628,
                4.000008897372325
              ],
              [
                -51.71615090243316,
                3.999688406538393
              ],
              [
                -51.711868450164715,
                4.000540391963183
              ],
              [
                -51.70859145674754,
                4.001504879115772
              ],
              [
                -51.70050152281734,
                4.006985853602531
              ],
              [
                -51.697378528460405,
                4.009526318309667
              ],
              [
                -51.69294406275516,
                4.015001299829696
              ],
              [
                -51.69190757236891,
                4.016280271904532
              ],
              [
                -51.690275584468694,
                4.018868767071983
              ],
              [
                -51.68664210285844,
                4.022975227029884
              ],
              [
                -51.68494559836403,
                4.025652200509401
              ],
              [
                -51.67301819242625,
                4.027654691279206
              ],
              [
                -51.66531323509392,
                4.028947692565997
              ],
              [
                -51.65371932791622,
                4.038103590135675
              ],
              [
                -51.64874887385375,
                4.04202856464605
              ],
              [
                -51.64593887208366,
                4.044961060614933
              ],
              [
                -51.644151879610895,
                4.052404499106991
              ],
              [
                -51.64235539841169,
                4.063088919841597
              ],
              [
                -51.643419893599905,
                4.070870842420855
              ],
              [
                -51.64389139736451,
                4.074319336417105
              ],
              [
                -51.63886989921856,
                4.082637769659007
              ],
              [
                -51.63881441456689,
                4.082716253052869
              ],
              [
                -51.63560245072455,
                4.087271220874172
              ],
              [
                -51.632541463472755,
                4.097840160688254
              ],
              [
                -51.62562298299175,
                4.121774455298867
              ],
              [
                -51.62474748348116,
                4.124803928585604
              ],
              [
                -51.620645527128524,
                4.137879848348144
              ],
              [
                -51.61766352374711,
                4.144018293621163
              ],
              [
                -51.61173156814329,
                4.155592185319072
              ],
              [
                -51.609827581840904,
                4.158706189380451
              ],
              [
                -51.604496114592365,
                4.167425596337026
              ],
              [
                -51.599902629380246,
                4.179218508125613
              ],
              [
                -51.598591155138074,
                4.181884010801348
              ],
              [
                -51.59491165949898,
                4.191826909667997
              ],
              [
                -51.596330632946724,
                4.215538763286531
              ],
              [
                -51.59669965880901,
                4.221705220189658
              ],
              [
                -51.5972106483043,
                4.230239141773895
              ],
              [
                -51.5923466825888,
                4.23563608429389
              ],
              [
                -51.58889367468227,
                4.248353488806591
              ],
              [
                -51.588389206177865,
                4.250211502853574
              ],
              [
                -51.58812367335461,
                4.251426477766623
              ],
              [
                -51.58709169892121,
                4.256156946385713
              ],
              [
                -51.58567021062199,
                4.261954901924544
              ],
              [
                -51.58554572420878,
                4.262462382120166
              ],
              [
                -51.58400472196929,
                4.273060336280943
              ],
              [
                -51.58132221322284,
                4.291505692026734
              ],
              [
                -51.57804070949576,
                4.313664511043138
              ],
              [
                -51.57720971824661,
                4.319003463257855
              ],
              [
                -51.575208725784464,
                4.331979875553468
              ],
              [
                -51.574765754210965,
                4.334882856919073
              ],
              [
                -51.57440573337007,
                4.337241344341495
              ],
              [
                -51.574050737513524,
                4.339564821023927
              ],
              [
                -51.573245251099756,
                4.344846271657758
              ],
              [
                -51.56580179230332,
                4.370980575737991
              ],
              [
                -51.56478030331971,
                4.374706537677172
              ],
              [
                -51.56352478848373,
                4.37928500419248
              ],
              [
                -51.56126330858038,
                4.387531442071754
              ],
              [
                -51.55680833041601,
                4.403776316664013
              ],
              [
                -51.553550363665835,
                4.415152760830199
              ],
              [
                -51.54902089195026,
                4.424777163125395
              ],
              [
                -51.53978692277865,
                4.430651602211825
              ],
              [
                -51.52701052512429,
                4.434149587669335
              ],
              [
                -51.51847757332336,
                4.436180063516998
              ],
              [
                -51.51357210755182,
                4.437029059278903
              ],
              [
                -51.50939415307638,
                4.433810570503468
              ],
              [
                -51.5001802446032,
                4.426710629336099
              ],
              [
                -51.48995978803383,
                4.423334651083392
              ],
              [
                -51.4523465823847,
                4.390220387961756
              ],
              [
                -51.42721078132425,
                4.37389049553872
              ],
              [
                -51.388763585882074,
                4.336981234357678
              ],
              [
                -51.38323460224127,
                4.331672284313647
              ],
              [
                -51.3831510998815,
                4.331591275862861
              ],
              [
                -51.377704640607526,
                4.326362824278286
              ],
              [
                -51.36023428444818,
                4.309592441909614
              ],
              [
                -51.34562791134733,
                4.295569001971844
              ],
              [
                -51.33687447713616,
                4.285905073269878
              ],
              [
                -51.31924462465341,
                4.266443209828359
              ],
              [
                -51.29225330963092,
                4.236646938421012
              ],
              [
                -51.28483086009184,
                4.228453477704297
              ],
              [
                -51.2848268691231,
                4.228448481738953
              ],
              [
                -51.27934641354867,
                4.22239804330825
              ],
              [
                -51.275471460544445,
                4.218120579699532
              ],
              [
                -51.25154565073638,
                4.191708753243373
              ],
              [
                -51.25133614094707,
                4.188666274613721
              ],
              [
                -51.251126636155945,
                4.18562478097609
              ],
              [
                -51.24402820281412,
                4.176499343390858
              ],
              [
                -51.23045881919344,
                4.159057461624655
              ],
              [
                -51.228028816640155,
                4.149865550193076
              ],
              [
                -51.22642934340927,
                4.143814611013295
              ],
              [
                -51.22272288032942,
                4.139274621480236
              ],
              [
                -51.22017889417868,
                4.136158133082549
              ],
              [
                -51.20981997299715,
                4.123468257056635
              ],
              [
                -51.20683050209664,
                4.11480079044181
              ],
              [
                -51.20488301809317,
                4.109155831071615
              ],
              [
                -51.20487552815898,
                4.109133347233593
              ],
              [
                -51.20198003042268,
                4.100738918652839
              ],
              [
                -51.20090205282614,
                4.097613416148775
              ],
              [
                -51.19370561656898,
                4.076751063304433
              ],
              [
                -51.19203064316414,
                4.071896122247178
              ],
              [
                -51.19034813582231,
                4.067018637352215
              ],
              [
                -51.189207639756305,
                4.063713169142667
              ],
              [
                -51.18939862611151,
                4.062406183903717
              ],
              [
                -51.190295133075445,
                4.056282732633949
              ],
              [
                -51.187103166377426,
                4.053415252057342
              ],
              [
                -51.18442919873559,
                4.051013259328881
              ],
              [
                -51.183917192662406,
                4.043164332026083
              ],
              [
                -51.1825631975674,
                4.022438968352268
              ],
              [
                -51.181678246202495,
                4.008886561940245
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 5,
        "nome": "Bahia",
        "sigla": "BA",
        "regiao_id": 4,
        "codigo_ibg": 29
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -39.288197156495286,
                -8.562900583535829
              ],
              [
                -39.282290730112166,
                -8.567705532558456
              ],
              [
                -39.27430978159463,
                -8.580154448095001
              ],
              [
                -39.273754772375376,
                -8.584493890467154
              ],
              [
                -39.2736522795169,
                -8.585297907422351
              ],
              [
                -39.27225276847672,
                -8.596239342164079
              ],
              [
                -39.26643032827735,
                -8.609757219786927
              ],
              [
                -39.25550542667567,
                -8.617379669335346
              ],
              [
                -39.2535349074411,
                -8.618754155351477
              ],
              [
                -39.24648297547211,
                -8.627319569482069
              ],
              [
                -39.241436012516864,
                -8.640867979595964
              ],
              [
                -39.2462659767006,
                -8.647340910175139
              ],
              [
                -39.24516392201163,
                -8.681823187193288
              ],
              [
                -39.23339502876915,
                -8.705363481284087
              ],
              [
                -39.22347009843542,
                -8.710925422954723
              ],
              [
                -39.217238155054154,
                -8.708067974281446
              ],
              [
                -39.21338670085073,
                -8.708641954459898
              ],
              [
                -39.21048521455942,
                -8.709073949570582
              ],
              [
                -39.20040228643047,
                -8.710576434094794
              ],
              [
                -39.185063435790035,
                -8.70571496179532
              ],
              [
                -39.1765500320367,
                -8.703015967855718
              ],
              [
                -39.16872108037772,
                -8.691755045900315
              ],
              [
                -39.15705717771343,
                -8.70423091878615
              ],
              [
                -39.15408768745858,
                -8.707406392538742
              ],
              [
                -39.15097623310489,
                -8.70879888235003
              ],
              [
                -39.06379847944644,
                -8.729320659562894
              ],
              [
                -39.032058710610904,
                -8.7369360967069
              ],
              [
                -39.02435077876426,
                -8.744102024471745
              ],
              [
                -39.016644339908794,
                -8.751266982241479
              ],
              [
                -38.99168504267762,
                -8.767079332907889
              ],
              [
                -38.97704867265162,
                -8.792675620689398
              ],
              [
                -38.96751571552009,
                -8.793640128655356
              ],
              [
                -38.96468874179996,
                -8.793926105900933
              ],
              [
                -38.956063324307884,
                -8.800716043562266
              ],
              [
                -38.95302486444277,
                -8.803107522013262
              ],
              [
                -38.94330593917036,
                -8.803841507507066
              ],
              [
                -38.9277275687428,
                -8.796162552875836
              ],
              [
                -38.9261360954207,
                -8.795259057088352
              ],
              [
                -38.9245441011028,
                -8.794356090297017
              ],
              [
                -38.884380973983745,
                -8.789209073066642
              ],
              [
                -38.86771459026051,
                -8.783473613113621
              ],
              [
                -38.85455821112008,
                -8.784043100665315
              ],
              [
                -38.811448081311774,
                -8.788072039728572
              ],
              [
                -38.80929259987644,
                -8.788273525231697
              ],
              [
                -38.803859152230935,
                -8.790321494982848
              ],
              [
                -38.79842671258032,
                -8.79236899473734
              ],
              [
                -38.73942115538111,
                -8.844335057014204
              ],
              [
                -38.71671384224274,
                -8.85274898736236
              ],
              [
                -38.711432899955014,
                -8.857303927612527
              ],
              [
                -38.70615193166873,
                -8.861859379857213
              ],
              [
                -38.69748550307025,
                -8.8852617280115
              ],
              [
                -38.69323150681384,
                -8.918308476774618
              ],
              [
                -38.691799005147935,
                -8.937397323823273
              ],
              [
                -38.68893301285901,
                -8.943982793282215
              ],
              [
                -38.68606802255883,
                -8.95056775674768
              ],
              [
                -38.67058063178979,
                -8.974454534010688
              ],
              [
                -38.640323874809155,
                -8.986867441378012
              ],
              [
                -38.63029399130903,
                -8.98477393942723
              ],
              [
                -38.62353206149982,
                -8.979335459384348
              ],
              [
                -38.60878968285328,
                -8.952354656721731
              ],
              [
                -38.607203243108486,
                -8.892868107080707
              ],
              [
                -38.60532279550225,
                -8.886529651432127
              ],
              [
                -38.603442805886985,
                -8.880191668788171
              ],
              [
                -38.57084361242303,
                -8.831191507951413
              ],
              [
                -38.56095720086763,
                -8.826750023850382
              ],
              [
                -38.55107182130875,
                -8.822309070760271
              ],
              [
                -38.508607667790635,
                -8.832225452582204
              ],
              [
                -38.47944990213732,
                -8.84968779347036
              ],
              [
                -38.47451492104188,
                -8.857382249567912
              ],
              [
                -38.46958046793976,
                -8.865076667666523
              ],
              [
                -38.47289342437127,
                -8.889136529553861
              ],
              [
                -38.47600837932636,
                -8.895320945409056
              ],
              [
                -38.479122849282476,
                -8.901504430279925
              ],
              [
                -38.499275157599165,
                -8.916973807972875
              ],
              [
                -38.50889460157876,
                -8.924358781032684
              ],
              [
                -38.512869558870015,
                -8.929625731581273
              ],
              [
                -38.51684453215867,
                -8.934892695138132
              ],
              [
                -38.5180094814914,
                -8.941740645314967
              ],
              [
                -38.5190649823845,
                -8.947946123172777
              ],
              [
                -38.51347753119063,
                -8.9595340427923
              ],
              [
                -38.503115599293594,
                -8.981009356447233
              ],
              [
                -38.48302276276058,
                -9.001073215428075
              ],
              [
                -38.45424346942719,
                -9.02193950422073
              ],
              [
                -38.425195720065794,
                -9.034909381394003
              ],
              [
                -38.4166327984443,
                -9.036506375432317
              ],
              [
                -38.40807037882702,
                -9.038102850474175
              ],
              [
                -38.38550708833356,
                -9.024415939944136
              ],
              [
                -38.352962358678234,
                -8.99554164617314
              ],
              [
                -38.34002847610177,
                -8.99007364974394
              ],
              [
                -38.32024515077276,
                -8.990658628827097
              ],
              [
                -38.31658117726481,
                -9.000764550117228
              ],
              [
                -38.29599135295584,
                -9.022489863968985
              ],
              [
                -38.28932888857068,
                -9.037978738360101
              ],
              [
                -38.289726896536386,
                -9.046938703026832
              ],
              [
                -38.29903480764007,
                -9.056724129973102
              ],
              [
                -38.30701974213178,
                -9.061488104064257
              ],
              [
                -38.32065809887584,
                -9.069625537420368
              ],
              [
                -38.32547153708019,
                -9.077593980710246
              ],
              [
                -38.31986407845272,
                -9.090878906605226
              ],
              [
                -38.31836658606502,
                -9.09140539627029
              ],
              [
                -38.30421173129639,
                -9.096378337865541
              ],
              [
                -38.32100056780976,
                -9.116557187791857
              ],
              [
                -38.31825804666042,
                -9.140595020558594
              ],
              [
                -38.31122911661936,
                -9.14745546588655
              ],
              [
                -38.28720929495711,
                -9.173471760676964
              ],
              [
                -38.279845824866996,
                -9.206088527392817
              ],
              [
                -38.28347781385519,
                -9.211408960377257
              ],
              [
                -38.2871097788414,
                -9.216728926373072
              ],
              [
                -38.25014400136347,
                -9.290076881510823
              ],
              [
                -38.23708408244278,
                -9.329271080225675
              ],
              [
                -38.22221372377365,
                -9.344664450667867
              ],
              [
                -38.20924581635715,
                -9.358087822553188
              ],
              [
                -38.19991236765393,
                -9.37898317659608
              ],
              [
                -38.19406343993,
                -9.385127149361562
              ],
              [
                -38.19378392463186,
                -9.393970046564647
              ],
              [
                -38.195719896138364,
                -9.39547954827137
              ],
              [
                -38.201443836458054,
                -9.399944518786098
              ],
              [
                -38.20289281358729,
                -9.418237393719357
              ],
              [
                -38.197066863334236,
                -9.420661360063923
              ],
              [
                -38.187357932488844,
                -9.422778327877957
              ],
              [
                -38.177310531554106,
                -9.424968321818328
              ],
              [
                -38.17086659008017,
                -9.430075281680073
              ],
              [
                -38.15455720150562,
                -9.443000149962192
              ],
              [
                -38.1480447755358,
                -9.440173181518201
              ],
              [
                -38.14153333655951,
                -9.437346678077587
              ],
              [
                -38.13257290761834,
                -9.43903866576314
              ],
              [
                -38.11772801430218,
                -9.441842155446928
              ],
              [
                -38.114511071679345,
                -9.43771366189382
              ],
              [
                -38.11298956999193,
                -9.435761194875845
              ],
              [
                -38.108464114309314,
                -9.435219696460553
              ],
              [
                -38.07864537327568,
                -9.440461616419665
              ],
              [
                -38.07139993034808,
                -9.446148575160377
              ],
              [
                -38.069965952656446,
                -9.45048152562862
              ],
              [
                -38.06744893923667,
                -9.458086471778374
              ],
              [
                -38.057160545038016,
                -9.45878494551855
              ],
              [
                -38.042766647324136,
                -9.468180390745097
              ],
              [
                -38.03551222343211,
                -9.467899399717556
              ],
              [
                -38.0282587875363,
                -9.467618889689186
              ],
              [
                -38.009356955422064,
                -9.480152785197996
              ],
              [
                -37.99850501085661,
                -9.497316613254178
              ],
              [
                -38.00008252795587,
                -9.503347605082613
              ],
              [
                -38.00301997588579,
                -9.514579996695483
              ],
              [
                -38.01273086708372,
                -9.527901408526045
              ],
              [
                -38.01349084673969,
                -9.534550381007692
              ],
              [
                -38.02146077354059,
                -9.540583816173742
              ],
              [
                -38.02149877252465,
                -9.54924377906543
              ],
              [
                -38.02126627835609,
                -9.54945827124701
              ],
              [
                -38.01783979234924,
                -9.552617743459853
              ],
              [
                -38.018575779009375,
                -9.563437180061245
              ],
              [
                -38.024834746958284,
                -9.567372653116285
              ],
              [
                -38.027219202437905,
                -9.574862594027955
              ],
              [
                -38.03034018548309,
                -9.569376612731775
              ],
              [
                -38.03132766853795,
                -9.567639157572346
              ],
              [
                -38.0400165790074,
                -9.572665612972004
              ],
              [
                -38.03812709326139,
                -9.578008069557477
              ],
              [
                -38.03668363285664,
                -9.58209003391561
              ],
              [
                -38.04010755252562,
                -9.602121888094686
              ],
              [
                -38.037500588371955,
                -9.607174360126738
              ],
              [
                -38.0348941022136,
                -9.612225843167339
              ],
              [
                -38.02605668913796,
                -9.618038771374637
              ],
              [
                -38.01830924619677,
                -9.623135217963032
              ],
              [
                -38.01632677246385,
                -9.627277222350124
              ],
              [
                -38.01551926786816,
                -9.628963685071346
              ],
              [
                -38.014022267542835,
                -9.629464668874405
              ],
              [
                -38.00834781058862,
                -9.631364162171005
              ],
              [
                -38.00777830952638,
                -9.635393624797858
              ],
              [
                -38.00769181967162,
                -9.636002648206896
              ],
              [
                -38.00688984449721,
                -9.636236601677101
              ],
              [
                -37.98971396869897,
                -9.641245571505138
              ],
              [
                -37.98747947658702,
                -9.646968030945269
              ],
              [
                -37.991600958855976,
                -9.65627047525271
              ],
              [
                -37.996677422524414,
                -9.649974493883077
              ],
              [
                -38.00661181267517,
                -9.65131151091011
              ],
              [
                -38.02141717337457,
                -9.666016931985526
              ],
              [
                -38.021777662837174,
                -9.66637441370383
              ],
              [
                -38.021818165243296,
                -9.666607911018561
              ],
              [
                -38.02459064171307,
                -9.682458790655994
              ],
              [
                -38.03279556838792,
                -9.68450128178753
              ],
              [
                -38.03950699359627,
                -9.686171777175044
              ],
              [
                -38.04158697768106,
                -9.690689762889457
              ],
              [
                -38.04220895274894,
                -9.715636066302954
              ],
              [
                -38.037526460788726,
                -9.749038827050793
              ],
              [
                -38.02780453908027,
                -9.775655637864348
              ],
              [
                -38.023148071973765,
                -9.776745601114406
              ],
              [
                -38.01972108233508,
                -9.777548112728185
              ],
              [
                -38.01786960058998,
                -9.783435560426193
              ],
              [
                -38.016749114796255,
                -9.786997030022476
              ],
              [
                -37.99725625109711,
                -9.802962878516594
              ],
              [
                -37.97488392305835,
                -9.812865312794752
              ],
              [
                -37.96627552508785,
                -9.815631278661721
              ],
              [
                -37.96603001429754,
                -9.819466754118364
              ],
              [
                -37.97119647713563,
                -9.82588221204014
              ],
              [
                -37.97420442676466,
                -9.829617664545438
              ],
              [
                -37.97619489875713,
                -9.841678098638173
              ],
              [
                -37.973780950923306,
                -9.84565506586217
              ],
              [
                -37.97253443978592,
                -9.847710055443702
              ],
              [
                -37.97357392416036,
                -9.854920006350971
              ],
              [
                -37.97879187767802,
                -9.863110444298584
              ],
              [
                -37.98214582565533,
                -9.868374407926627
              ],
              [
                -37.98373030775826,
                -9.87890731593788
              ],
              [
                -37.98531376585936,
                -9.889438258980638
              ],
              [
                -37.987901247374104,
                -9.893935728501267
              ],
              [
                -37.99733616313544,
                -9.91033410941419
              ],
              [
                -37.997260162594955,
                -9.916291586482059
              ],
              [
                -37.99198420818542,
                -9.91908854648523
              ],
              [
                -37.98750425520095,
                -9.921464025405923
              ],
              [
                -37.97980529157655,
                -9.937272419148828
              ],
              [
                -37.96769236037748,
                -9.962143199678732
              ],
              [
                -37.95969043087156,
                -9.969856659689283
              ],
              [
                -37.95107952181563,
                -9.970819121742478
              ],
              [
                -37.936376149872544,
                -9.96571066220826
              ],
              [
                -37.92703122689144,
                -9.965165666609805
              ],
              [
                -37.92079478353083,
                -9.973181101190988
              ],
              [
                -37.91780978762923,
                -9.974367569385892
              ],
              [
                -37.88101060322167,
                -9.980305518809383
              ],
              [
                -37.87801864465396,
                -9.979333518890495
              ],
              [
                -37.875027681078194,
                -9.978361524973527
              ],
              [
                -37.831385017337695,
                -10.000499826008314
              ],
              [
                -37.822717580406575,
                -10.019104662039503
              ],
              [
                -37.822751085246814,
                -10.019927677012575
              ],
              [
                -37.823331065275205,
                -10.034063539514838
              ],
              [
                -37.82057656961478,
                -10.056870883768227
              ],
              [
                -37.79787873360133,
                -10.108605004568847
              ],
              [
                -37.777468356818524,
                -10.134321791184243
              ],
              [
                -37.77635540017258,
                -10.135724761707104
              ],
              [
                -37.77516787608764,
                -10.140047238682005
              ],
              [
                -37.77398140899329,
                -10.14436821267051
              ],
              [
                -37.77456189127439,
                -10.146802213377917
              ],
              [
                -37.782530293244406,
                -10.180214945102705
              ],
              [
                -37.779760301033214,
                -10.187444909081675
              ],
              [
                -37.77013937820949,
                -10.192996835216324
              ],
              [
                -37.76529943104426,
                -10.19578934562961
              ],
              [
                -37.74244253537774,
                -10.310322962765701
              ],
              [
                -37.741064523119775,
                -10.317228935656763
              ],
              [
                -37.73615553503825,
                -10.331607299016182
              ],
              [
                -37.762128312707205,
                -10.34400123773174
              ],
              [
                -37.771049713935206,
                -10.344751744711415
              ],
              [
                -37.78358310487974,
                -10.345805737311126
              ],
              [
                -37.78720157293987,
                -10.354028677926726
              ],
              [
                -37.78739408910937,
                -10.354145704274437
              ],
              [
                -37.807280884033844,
                -10.366225124002614
              ],
              [
                -37.80989438315062,
                -10.366006631387762
              ],
              [
                -37.812508330264095,
                -10.365788153773364
              ],
              [
                -37.82165777268754,
                -10.371893122846311
              ],
              [
                -37.823682759590135,
                -10.37757456143106
              ],
              [
                -37.83029766237886,
                -10.37454610265184
              ],
              [
                -37.83367616388962,
                -10.379694545589576
              ],
              [
                -37.838582113540994,
                -10.378739548815672
              ],
              [
                -37.833195131451355,
                -10.384918012861862
              ],
              [
                -37.84022757528622,
                -10.389473493055535
              ],
              [
                -37.842133052048084,
                -10.398073931194373
              ],
              [
                -37.84137757133219,
                -10.398437932724077
              ],
              [
                -37.837031604485055,
                -10.400530403771338
              ],
              [
                -37.844351536962584,
                -10.403077897978026
              ],
              [
                -37.85297794039374,
                -10.406079897267432
              ],
              [
                -37.85120697864491,
                -10.413771344143536
              ],
              [
                -37.850771478427724,
                -10.415662297854361
              ],
              [
                -37.852413942799565,
                -10.42123876785643
              ],
              [
                -37.85336143689751,
                -10.424456229358357
              ],
              [
                -37.85871589480932,
                -10.426041756518067
              ],
              [
                -37.82526564455714,
                -10.477181837927779
              ],
              [
                -37.820661648550264,
                -10.484219790561458
              ],
              [
                -37.8198666470859,
                -10.488306262859346
              ],
              [
                -37.81709618190848,
                -10.502548153871063
              ],
              [
                -37.81690817650588,
                -10.503515142607624
              ],
              [
                -37.812650207173554,
                -10.508062117809356
              ],
              [
                -37.81671614929282,
                -10.517933555139894
              ],
              [
                -37.82188258970286,
                -10.530480473159388
              ],
              [
                -37.81963113733285,
                -10.5388823804132
              ],
              [
                -37.8239465563331,
                -10.537804899951512
              ],
              [
                -37.827924534504156,
                -10.54017587296544
              ],
              [
                -37.82900801520106,
                -10.540821388430889
              ],
              [
                -37.82860954480551,
                -10.542556864338438
              ],
              [
                -37.82754455108722,
                -10.547199834314261
              ],
              [
                -37.82869001172962,
                -10.548301337522242
              ],
              [
                -37.83138850397337,
                -10.55089581052665
              ],
              [
                -37.8401739271821,
                -10.549135338901204
              ],
              [
                -37.839262929027704,
                -10.555642297460073
              ],
              [
                -37.832713963963414,
                -10.560165263364837
              ],
              [
                -37.83493495561591,
                -10.570697191986376
              ],
              [
                -37.83024199004485,
                -10.572591177074672
              ],
              [
                -37.83112897658916,
                -10.578535615702464
              ],
              [
                -37.825495539895535,
                -10.5799330953851
              ],
              [
                -37.830028500950796,
                -10.584272058688475
              ],
              [
                -37.81352903514442,
                -10.690616812133076
              ],
              [
                -37.84484524372361,
                -10.702307761537288
              ],
              [
                -37.972213086402874,
                -10.754100510220656
              ],
              [
                -37.99848233541168,
                -10.763594434568459
              ],
              [
                -37.99946582531575,
                -10.763949442088704
              ],
              [
                -38.0188201478041,
                -10.763488996947128
              ],
              [
                -38.02293113443869,
                -10.762646486624293
              ],
              [
                -38.02839559745902,
                -10.7377431791742
              ],
              [
                -38.04309949127863,
                -10.71860783819609
              ],
              [
                -38.052293404173014,
                -10.709700875956234
              ],
              [
                -38.05789738044797,
                -10.708145411440302
              ],
              [
                -38.077082179323526,
                -10.711441877805775
              ],
              [
                -38.08329965443798,
                -10.706145455080975
              ],
              [
                -38.08454413565335,
                -10.70508544314216
              ],
              [
                -38.09234958035473,
                -10.701908009818089
              ],
              [
                -38.10439647469123,
                -10.710145935484373
              ],
              [
                -38.10496243325742,
                -10.710532954308476
              ],
              [
                -38.10571795072464,
                -10.710413961008538
              ],
              [
                -38.11766033547024,
                -10.708532977882317
              ],
              [
                -38.120506299100136,
                -10.712285440861468
              ],
              [
                -38.1251287773941,
                -10.718379887858246
              ],
              [
                -38.140639614043884,
                -10.717941935259262
              ],
              [
                -38.15343603246835,
                -10.71591395115847
              ],
              [
                -38.160846450195024,
                -10.71055599517607
              ],
              [
                -38.16112244498694,
                -10.710356988923156
              ],
              [
                -38.16361494705057,
                -10.710316514985
              ],
              [
                -38.171817357868434,
                -10.710181483075328
              ],
              [
                -38.179298297093396,
                -10.70208907995585
              ],
              [
                -38.196702145098776,
                -10.706978052911644
              ],
              [
                -38.19840315866254,
                -10.706486048362203
              ],
              [
                -38.20307908146631,
                -10.7073455503414
              ],
              [
                -38.211549030232845,
                -10.708730536739878
              ],
              [
                -38.21280298164459,
                -10.711839544645887
              ],
              [
                -38.2166109753355,
                -10.721281454597175
              ],
              [
                -38.216055924078354,
                -10.749477774129039
              ],
              [
                -38.21434443295133,
                -10.754043240220744
              ],
              [
                -38.212633482816784,
                -10.758609693307209
              ],
              [
                -38.220942862167995,
                -10.777317072434434
              ],
              [
                -38.23051824677038,
                -10.791271992376814
              ],
              [
                -38.23082176358693,
                -10.791714994518342
              ],
              [
                -38.23650121350931,
                -10.791730494774455
              ],
              [
                -38.24146266203463,
                -10.800221953038585
              ],
              [
                -38.238918172467024,
                -10.813272839984428
              ],
              [
                -38.24526160116837,
                -10.822303293951776
              ],
              [
                -38.240775660278096,
                -10.826152255135463
              ],
              [
                -38.23937067227417,
                -10.827358216853815
              ],
              [
                -38.23183318981251,
                -10.847238562949276
              ],
              [
                -38.238769125592505,
                -10.851637567039932
              ],
              [
                -38.23913814307,
                -10.851871064772078
              ],
              [
                -38.24120609817656,
                -10.862838485020195
              ],
              [
                -38.24040810869808,
                -10.876257894375902
              ],
              [
                -38.23227515963163,
                -10.895063763634832
              ],
              [
                -38.23187665407885,
                -10.895984244550208
              ],
              [
                -38.23051016181876,
                -10.905739690705287
              ],
              [
                -38.229144139544225,
                -10.915495583867745
              ],
              [
                -38.21815472024092,
                -10.926878519400189
              ],
              [
                -38.213133290481686,
                -10.929502485799471
              ],
              [
                -38.21131079838903,
                -10.926204015494918
              ],
              [
                -38.2098573232651,
                -10.923573018802319
              ],
              [
                -38.20468237439013,
                -10.924023029691334
              ],
              [
                -38.20226136468703,
                -10.93182295474511
              ],
              [
                -38.20029338167783,
                -10.938164423824448
              ],
              [
                -38.18400602599061,
                -10.941940374129485
              ],
              [
                -38.1822615415021,
                -10.946998830702231
              ],
              [
                -38.188357948757336,
                -10.964135726188575
              ],
              [
                -38.18332549200497,
                -10.96684816392835
              ],
              [
                -38.18067300115517,
                -10.97307464806812
              ],
              [
                -38.1793555191773,
                -10.976166110305527
              ],
              [
                -38.17564655175435,
                -10.984872057391305
              ],
              [
                -38.14756428551146,
                -10.998700924806343
              ],
              [
                -38.14722828128493,
                -10.998866403231585
              ],
              [
                -38.1471317719724,
                -10.999007403106202
              ],
              [
                -38.137976864178256,
                -11.012386337322937
              ],
              [
                -38.13017744012865,
                -11.014035794545826
              ],
              [
                -38.12886993940539,
                -11.010845308030264
              ],
              [
                -38.12614744720697,
                -11.004205348748808
              ],
              [
                -38.11121909188224,
                -11.009498822600637
              ],
              [
                -38.10605814353839,
                -11.01543525895243
              ],
              [
                -38.10922857361976,
                -11.021347210986281
              ],
              [
                -38.10836109410177,
                -11.023212720560473
              ],
              [
                -38.10324313544058,
                -11.03421862945569
              ],
              [
                -38.105256115279715,
                -11.039664083544501
              ],
              [
                -38.099073133645696,
                -11.046718039686983
              ],
              [
                -38.09432421205102,
                -11.047193506833574
              ],
              [
                -38.094076185214014,
                -11.047218008373541
              ],
              [
                -38.09404019295263,
                -11.047712507771843
              ],
              [
                -38.09350219904132,
                -11.05510495093198
              ],
              [
                -38.088819726428206,
                -11.058509423063839
              ],
              [
                -38.087666727078876,
                -11.061526404024203
              ],
              [
                -38.086315737668286,
                -11.061824918328782
              ],
              [
                -38.08244326188946,
                -11.062680411737228
              ],
              [
                -38.078600291541875,
                -11.074023830676037
              ],
              [
                -38.08786369988343,
                -11.091704194057924
              ],
              [
                -38.0886341973431,
                -11.093174706362092
              ],
              [
                -38.09536015456308,
                -11.096857659588956
              ],
              [
                -38.09525114978832,
                -11.102579120308043
              ],
              [
                -38.086036687044356,
                -11.115465547030544
              ],
              [
                -38.08391072801902,
                -11.116159004598043
              ],
              [
                -38.07014385642749,
                -11.12065046845917
              ],
              [
                -38.06831135079292,
                -11.130682925193321
              ],
              [
                -38.07160631896778,
                -11.13719737714085
              ],
              [
                -38.072577789109815,
                -11.139117830451143
              ],
              [
                -38.06827330586846,
                -11.146987798905956
              ],
              [
                -38.071998774459466,
                -11.152457251881717
              ],
              [
                -38.062622858053714,
                -11.158638186633244
              ],
              [
                -38.06255434503153,
                -11.163393146374073
              ],
              [
                -38.047639989875464,
                -11.176955051670706
              ],
              [
                -38.03486706395052,
                -11.17857052330385
              ],
              [
                -38.03412707983634,
                -11.178284019508771
              ],
              [
                -38.0276691329331,
                -11.175782548035444
              ],
              [
                -38.01257175555735,
                -11.18756845586042
              ],
              [
                -38.01099779602948,
                -11.18879743420581
              ],
              [
                -38.00271185069315,
                -11.195265381127491
              ],
              [
                -38.000339358616,
                -11.193641874058766
              ],
              [
                -37.99604641909467,
                -11.190703919224886
              ],
              [
                -37.9873289967016,
                -11.195392872435015
              ],
              [
                -37.978852070835835,
                -11.191939884477794
              ],
              [
                -37.97396361268047,
                -11.194838844981103
              ],
              [
                -37.9807955336573,
                -11.198668341330785
              ],
              [
                -37.97885904640257,
                -11.20490827621974
              ],
              [
                -37.9814505087224,
                -11.209744270443286
              ],
              [
                -37.97716556561585,
                -11.21483622087579
              ],
              [
                -37.97738653630239,
                -11.221873662537403
              ],
              [
                -37.985963465744085,
                -11.22576265551558
              ],
              [
                -37.98883041462252,
                -11.228908604227156
              ],
              [
                -37.98947991302525,
                -11.229621122859236
              ],
              [
                -37.98858592252808,
                -11.231649090249292
              ],
              [
                -37.986184960306716,
                -11.237094556334197
              ],
              [
                -37.98802691803208,
                -11.237907065631742
              ],
              [
                -37.9931778987241,
                -11.24018004427459
              ],
              [
                -37.989814411112135,
                -11.248064994723197
              ],
              [
                -37.98973990981824,
                -11.24802599291691
              ],
              [
                -37.98646292290297,
                -11.246286978607115
              ],
              [
                -37.98401045951262,
                -11.250572451972005
              ],
              [
                -37.99169137012439,
                -11.256766405397794
              ],
              [
                -37.99499485670029,
                -11.259430405102485
              ],
              [
                -37.99282235907302,
                -11.261829402348353
              ],
              [
                -37.985974395684025,
                -11.269390323087153
              ],
              [
                -37.993020332334495,
                -11.272709804445322
              ],
              [
                -37.99131234888672,
                -11.277373793969925
              ],
              [
                -37.99693481937063,
                -11.28300925905712
              ],
              [
                -38.002979730604494,
                -11.289069209601688
              ],
              [
                -38.003007722459955,
                -11.2965036732799
              ],
              [
                -37.9930218025193,
                -11.31350003967097
              ],
              [
                -37.990864804217445,
                -11.31717250980337
              ],
              [
                -37.99142933249364,
                -11.321111951200091
              ],
              [
                -37.99203181003239,
                -11.321216979150488
              ],
              [
                -37.99783627141704,
                -11.322229970665832
              ],
              [
                -37.99113532058929,
                -11.328202420992865
              ],
              [
                -37.98786032508888,
                -11.331121393226296
              ],
              [
                -37.98761581099262,
                -11.331339399376708
              ],
              [
                -37.988355318098776,
                -11.331626374182878
              ],
              [
                -37.990584800315524,
                -11.332491397585244
              ],
              [
                -37.9980482437426,
                -11.33538788554135
              ],
              [
                -37.999439699724434,
                -11.340128362175639
              ],
              [
                -37.992918281921554,
                -11.343085812335364
              ],
              [
                -37.987680825369544,
                -11.34136930051512
              ],
              [
                -37.98743982517591,
                -11.34164231427514
              ],
              [
                -37.98206087549383,
                -11.347735265283834
              ],
              [
                -37.97809889311898,
                -11.365579124695623
              ],
              [
                -37.97245040574224,
                -11.373396072031204
              ],
              [
                -37.96923144412413,
                -11.373509086445306
              ],
              [
                -37.96855544270378,
                -11.377168541417792
              ],
              [
                -37.968263436189105,
                -11.381401000734837
              ],
              [
                -37.97278392638274,
                -11.382857510601033
              ],
              [
                -37.980557350496206,
                -11.385361989779183
              ],
              [
                -37.977461370524644,
                -11.393149961332991
              ],
              [
                -37.97135741280912,
                -11.391322452257105
              ],
              [
                -37.967537442034896,
                -11.390178437968826
              ],
              [
                -37.96470146762417,
                -11.393110924620233
              ],
              [
                -37.965111968161686,
                -11.402076894849321
              ],
              [
                -37.960643991498756,
                -11.401459366021959
              ],
              [
                -37.95972701683008,
                -11.396319424780543
              ],
              [
                -37.95245006231516,
                -11.395491400166431
              ],
              [
                -37.95130107611767,
                -11.404089832187994
              ],
              [
                -37.955635509273996,
                -11.406930326927696
              ],
              [
                -37.95224808100367,
                -11.409653791427694
              ],
              [
                -37.948608570722485,
                -11.41258078217216
              ],
              [
                -37.94250314590153,
                -11.417491237801416
              ],
              [
                -37.9434656174408,
                -11.423221718872282
              ],
              [
                -37.9364827057722,
                -11.42203620714798
              ],
              [
                -37.93491021900824,
                -11.415172749484913
              ],
              [
                -37.934795201634394,
                -11.414670757947414
              ],
              [
                -37.92743528745377,
                -11.410055278377044
              ],
              [
                -37.92879525492799,
                -11.402989324630115
              ],
              [
                -37.91550988951217,
                -11.403980800917054
              ],
              [
                -37.91018144200264,
                -11.410406242585395
              ],
              [
                -37.9046824961335,
                -11.410927734385716
              ],
              [
                -37.90367646929969,
                -11.419834664339636
              ],
              [
                -37.903378491755724,
                -11.422472166079938
              ],
              [
                -37.906377441802626,
                -11.426906122821089
              ],
              [
                -37.90170647797453,
                -11.427940633910096
              ],
              [
                -37.898469512272854,
                -11.436548575396886
              ],
              [
                -37.893321032859575,
                -11.433304080595025
              ],
              [
                -37.89103406773681,
                -11.436121558705942
              ],
              [
                -37.8843906276208,
                -11.42705708587959
              ],
              [
                -37.88301917204674,
                -11.425185616687012
              ],
              [
                -37.87574070715567,
                -11.423906634304107
              ],
              [
                -37.86552830678611,
                -11.428021592788843
              ],
              [
                -37.87512572286208,
                -11.428666593444834
              ],
              [
                -37.87666820562908,
                -11.429949074061636
              ],
              [
                -37.88034864028111,
                -11.43301006344107
              ],
              [
                -37.86948726963543,
                -11.436320023930977
              ],
              [
                -37.86888527775389,
                -11.436504031903363
              ],
              [
                -37.868703763414175,
                -11.437290028053663
              ],
              [
                -37.8676172493701,
                -11.441990964067202
              ],
              [
                -37.865105297956056,
                -11.441743965882347
              ],
              [
                -37.85020942590441,
                -11.440278492868245
              ],
              [
                -37.84865293042921,
                -11.4446529306638
              ],
              [
                -37.85111292069396,
                -11.44870843047496
              ],
              [
                -37.85304687966015,
                -11.451908411805467
              ],
              [
                -37.85649483046637,
                -11.452401406330404
              ],
              [
                -37.862477319344976,
                -11.453256886242269
              ],
              [
                -37.86276830280311,
                -11.464783316958647
              ],
              [
                -37.85726331089133,
                -11.4695382873858
              ],
              [
                -37.84759591186046,
                -11.469395772008268
              ],
              [
                -37.83943097293402,
                -11.481271689249455
              ],
              [
                -37.8326530538076,
                -11.482338147607917
              ],
              [
                -37.83169852907383,
                -11.483333650345621
              ],
              [
                -37.82425308375165,
                -11.49110009088772
              ],
              [
                -37.81407567409026,
                -11.491946074810416
              ],
              [
                -37.80874174208963,
                -11.496367534817434
              ],
              [
                -37.81837762497419,
                -11.503409512721607
              ],
              [
                -37.813349659904965,
                -11.513873924785763
              ],
              [
                -37.79932877500807,
                -11.520797340590086
              ],
              [
                -37.794926833400716,
                -11.518088386799592
              ],
              [
                -37.78994787194431,
                -11.523584828517956
              ],
              [
                -37.77878497600577,
                -11.523671810693934
              ],
              [
                -37.77496501462301,
                -11.523935813285
              ],
              [
                -37.773423492476546,
                -11.526273806702543
              ],
              [
                -37.770371017965694,
                -11.53090525589334
              ],
              [
                -37.76497759241841,
                -11.528003285317766
              ],
              [
                -37.75903914207227,
                -11.5248077952078
              ],
              [
                -37.75199667687536,
                -11.532854717187252
              ],
              [
                -37.74756671009156,
                -11.537916178659755
              ],
              [
                -37.738798788180155,
                -11.537788156207684
              ],
              [
                -37.73003287525878,
                -11.537660163760325
              ],
              [
                -37.72907637366799,
                -11.541849139590243
              ],
              [
                -37.72667638121468,
                -11.552360061385698
              ],
              [
                -37.71893395206894,
                -11.549881540994305
              ],
              [
                -37.71142501365718,
                -11.551672518266988
              ],
              [
                -37.71004752418672,
                -11.549798063079841
              ],
              [
                -37.702997593537354,
                -11.540209109522277
              ],
              [
                -37.69597665620771,
                -11.544954550182448
              ],
              [
                -37.698049632784404,
                -11.554811017934426
              ],
              [
                -37.69956511853575,
                -11.56201645305371
              ],
              [
                -37.68816570731115,
                -11.565806905376157
              ],
              [
                -37.683482740994215,
                -11.561677448450567
              ],
              [
                -37.674401332061215,
                -11.567198889099473
              ],
              [
                -37.669876871729095,
                -11.56357139976667
              ],
              [
                -37.67175235002305,
                -11.558656450244978
              ],
              [
                -37.66242296111464,
                -11.544595525116366
              ],
              [
                -37.66150146500127,
                -11.543205541004442
              ],
              [
                -37.66439194470743,
                -11.532971099878095
              ],
              [
                -37.656878524292175,
                -11.532362103386705
              ],
              [
                -37.65266558300211,
                -11.528052653359703
              ],
              [
                -37.65058259830016,
                -11.518343731625642
              ],
              [
                -37.64829310469513,
                -11.51997521121164
              ],
              [
                -37.642938171721845,
                -11.523790677500667
              ],
              [
                -37.63708319828045,
                -11.51763322083102
              ],
              [
                -37.63163777124316,
                -11.518043698471066
              ],
              [
                -37.62776630326037,
                -11.52913960620662
              ],
              [
                -37.6193878398207,
                -11.534927552759713
              ],
              [
                -37.6155748735684,
                -11.537562059342333
              ],
              [
                -37.612383409840895,
                -11.536850525691454
              ],
              [
                -37.60856243088805,
                -11.535999054304929
              ],
              [
                -37.605427488310504,
                -11.53805101286979
              ],
              [
                -37.602734009153714,
                -11.539814001030548
              ],
              [
                -37.60096103067741,
                -11.538379542246116
              ],
              [
                -37.588374638081014,
                -11.52819258662092
              ],
              [
                -37.578545232579025,
                -11.53317552023867
              ],
              [
                -37.58377965013415,
                -11.539641990932159
              ],
              [
                -37.585292663396196,
                -11.541510989285035
              ],
              [
                -37.578166706335516,
                -11.551015930593223
              ],
              [
                -37.572092751083524,
                -11.548003927075507
              ],
              [
                -37.57461723781871,
                -11.543696939999744
              ],
              [
                -37.56985526550423,
                -11.540168986744417
              ],
              [
                -37.56208984382077,
                -11.54252244168467
              ],
              [
                -37.55583241378424,
                -11.551418369376714
              ],
              [
                -37.55258193124528,
                -11.546875399199509
              ],
              [
                -37.517547760562316,
                -11.547579359893138
              ],
              [
                -37.51511078923603,
                -11.537696431105346
              ],
              [
                -37.51473476042972,
                -11.53617244862522
              ],
              [
                -37.51407779550173,
                -11.53351097099789
              ],
              [
                -37.50514085892284,
                -11.525422510692119
              ],
              [
                -37.462473745363155,
                -11.521226022836439
              ],
              [
                -37.45321433103787,
                -11.518651494521995
              ],
              [
                -37.45317383741283,
                -11.518639989557363
              ],
              [
                -37.44387342047313,
                -11.516054504282181
              ],
              [
                -37.44122195222968,
                -11.514304012785816
              ],
              [
                -37.411483245126256,
                -11.494665621521007
              ],
              [
                -37.39405089841355,
                -11.479871713851974
              ],
              [
                -37.37270361689985,
                -11.45649135768918
              ],
              [
                -37.37146763854828,
                -11.45513787602142
              ],
              [
                -37.35836874028793,
                -11.445233431303798
              ],
              [
                -37.3482323505378,
                -11.442910961303953
              ],
              [
                -37.342502905042956,
                -11.443447934773356
              ],
              [
                -37.346768873464626,
                -11.447215409516762
              ],
              [
                -37.34543785507466,
                -11.44828089827581
              ],
              [
                -37.342853878673445,
                -11.450348897339174
              ],
              [
                -37.3457398693103,
                -11.4617328044899
              ],
              [
                -37.387388950261744,
                -11.503705562196446
              ],
              [
                -37.39407587769066,
                -11.514434477666665
              ],
              [
                -37.41185469167681,
                -11.542963311932697
              ],
              [
                -37.41615411307834,
                -11.551443241908341
              ],
              [
                -37.42045306647505,
                -11.559923689897369
              ],
              [
                -37.430617963514685,
                -11.579979032505555
              ],
              [
                -37.43684139110593,
                -11.592258971489704
              ],
              [
                -37.43833337522433,
                -11.595202465076015
              ],
              [
                -37.44076485571085,
                -11.59999892344606
              ],
              [
                -37.44372131525165,
                -11.605832383984426
              ],
              [
                -37.44944525146262,
                -11.617124810555007
              ],
              [
                -37.464494091050355,
                -11.64681660600117
              ],
              [
                -37.46732705895846,
                -11.652406084212394
              ],
              [
                -37.4701600228623,
                -11.657996042427824
              ],
              [
                -37.477563438969476,
                -11.672602922344778
              ],
              [
                -37.481389902599,
                -11.680152898701818
              ],
              [
                -37.484094378942345,
                -11.68548836562867
              ],
              [
                -37.487574354488814,
                -11.692354827501095
              ],
              [
                -37.489850307684236,
                -11.696844265002754
              ],
              [
                -37.514729053812,
                -11.741571977020872
              ],
              [
                -37.55589510649517,
                -11.83543788670645
              ],
              [
                -37.55791907370446,
                -11.840053343158887
              ],
              [
                -37.564382972709495,
                -11.854791720646201
              ],
              [
                -37.56569396651532,
                -11.857602720148138
              ],
              [
                -37.57294839941412,
                -11.873162628785598
              ],
              [
                -37.5735893889815,
                -11.874537630742658
              ],
              [
                -37.57423088454357,
                -11.875913081697604
              ],
              [
                -37.60255204392097,
                -11.93665369970004
              ],
              [
                -37.60850598777884,
                -11.949422605934174
              ],
              [
                -37.612543437882444,
                -11.958080562159195
              ],
              [
                -37.616376399350315,
                -11.966301995262063
              ],
              [
                -37.62203433072582,
                -11.994964810043125
              ],
              [
                -37.65294501157422,
                -12.038837548821341
              ],
              [
                -37.669970323372084,
                -12.07141681723681
              ],
              [
                -37.68562913752931,
                -12.091233690833104
              ],
              [
                -37.68990460656712,
                -12.107554086265164
              ],
              [
                -37.6978460029428,
                -12.120718499686667
              ],
              [
                -37.70531343015306,
                -12.131831461116779
              ],
              [
                -37.72598972439968,
                -12.162590770749704
              ],
              [
                -37.74314053071936,
                -12.188104585394141
              ],
              [
                -37.76155485027653,
                -12.215499933493167
              ],
              [
                -37.76301033254037,
                -12.21764091716657
              ],
              [
                -37.763610311052275,
                -12.218524391667003
              ],
              [
                -37.76272082694859,
                -12.219318416935463
              ],
              [
                -37.76114682815237,
                -12.22072336602443
              ],
              [
                -37.78039762578178,
                -12.24994671649439
              ],
              [
                -37.83635204542395,
                -12.322094753551719
              ],
              [
                -37.88439254700282,
                -12.393459310673352
              ],
              [
                -37.893188447621746,
                -12.404139229719378
              ],
              [
                -37.89666291414788,
                -12.403039277889231
              ],
              [
                -37.89643444734247,
                -12.408220222144411
              ],
              [
                -37.91630321626776,
                -12.43713803215237
              ],
              [
                -37.94384494140028,
                -12.47164235701542
              ],
              [
                -37.951246844442025,
                -12.482994285818442
              ],
              [
                -37.95864828447511,
                -12.494344694668035
              ],
              [
                -37.97320464319329,
                -12.516668563802554
              ],
              [
                -37.976316076130246,
                -12.524440527314846
              ],
              [
                -37.97804458298313,
                -12.528756991280927
              ],
              [
                -37.97951203202681,
                -12.532422463477769
              ],
              [
                -37.980808554160696,
                -12.535660942449999
              ],
              [
                -37.982837521329365,
                -12.5407284085514
              ],
              [
                -37.98453347857073,
                -12.544964388055226
              ],
              [
                -37.98941494821913,
                -12.553120325215456
              ],
              [
                -37.99310941407899,
                -12.559292801751603
              ],
              [
                -38.00364276175766,
                -12.576892203136783
              ],
              [
                -38.00677224309208,
                -12.576764678086086
              ],
              [
                -38.017540645691085,
                -12.581692157554254
              ],
              [
                -38.02235762319137,
                -12.585900144351665
              ],
              [
                -38.02694654598796,
                -12.589908103259962
              ],
              [
                -38.03099801419517,
                -12.593446605761482
              ],
              [
                -38.0325840192244,
                -12.594833083120996
              ],
              [
                -38.038111968070474,
                -12.602996027200705
              ],
              [
                -38.04403190364102,
                -12.611739001753563
              ],
              [
                -38.044580374191064,
                -12.617351959219276
              ],
              [
                -38.04462636210669,
                -12.617852443779775
              ],
              [
                -38.04558133253237,
                -12.628282377106139
              ],
              [
                -38.049108828815655,
                -12.634460849516735
              ],
              [
                -38.056897247786175,
                -12.63799232199153
              ],
              [
                -38.05990070497011,
                -12.642509796343663
              ],
              [
                -38.06194917858709,
                -12.64559076249099
              ],
              [
                -38.064537157307385,
                -12.649483251676347
              ],
              [
                -38.07295756936808,
                -12.653968234345871
              ],
              [
                -38.07743055737279,
                -12.656351225547617
              ],
              [
                -38.084714952166955,
                -12.666269644817682
              ],
              [
                -38.08572497232274,
                -12.667644657539778
              ],
              [
                -38.09113437930193,
                -12.674963619531038
              ],
              [
                -38.117772145558746,
                -12.699951973120424
              ],
              [
                -38.121160081132885,
                -12.703121442467507
              ],
              [
                -38.12454807370581,
                -12.70629093381846
              ],
              [
                -38.147036310173014,
                -12.734648768780696
              ],
              [
                -38.17646002693356,
                -12.771763021233355
              ],
              [
                -38.18462245029934,
                -12.77841098977918
              ],
              [
                -38.18668494167128,
                -12.780090995822308
              ],
              [
                -38.18857392777946,
                -12.781628976624853
              ],
              [
                -38.19708484365804,
                -12.788559952693165
              ],
              [
                -38.20757619441105,
                -12.804597834111322
              ],
              [
                -38.21473861971496,
                -12.815546795535075
              ],
              [
                -38.22363605948742,
                -12.823676236880626
              ],
              [
                -38.22454855923533,
                -12.824508751310988
              ],
              [
                -38.22696803770259,
                -12.826716710192
              ],
              [
                -38.23281595940549,
                -12.83205317890984
              ],
              [
                -38.24748580540336,
                -12.853566580110709
              ],
              [
                -38.262700143189655,
                -12.86738097356538
              ],
              [
                -38.26688909293532,
                -12.871034960815905
              ],
              [
                -38.271577045422916,
                -12.873122952596217
              ],
              [
                -38.27825351505735,
                -12.876077957025188
              ],
              [
                -38.28282546716315,
                -12.881345395619912
              ],
              [
                -38.29511832744894,
                -12.895454308288125
              ],
              [
                -38.313471160633426,
                -12.9191106880423
              ],
              [
                -38.31810760015305,
                -12.925088640881233
              ],
              [
                -38.32387254690477,
                -12.930171106444071
              ],
              [
                -38.338652405561334,
                -12.943203047138605
              ],
              [
                -38.34101437369337,
                -12.945286028907173
              ],
              [
                -38.34747931511049,
                -12.950453014869941
              ],
              [
                -38.35231674299683,
                -12.95273899857336
              ],
              [
                -38.357012219694326,
                -12.954631497809796
              ],
              [
                -38.36309417734311,
                -12.95134604271279
              ],
              [
                -38.363181182636495,
                -12.951299507151694
              ],
              [
                -38.36624315779199,
                -12.949645526708753
              ],
              [
                -38.37932003955754,
                -12.950542554116055
              ],
              [
                -38.38072202131782,
                -12.953261019215086
              ],
              [
                -38.386404429938096,
                -12.954680546060404
              ],
              [
                -38.39527287627918,
                -12.956836041117748
              ],
              [
                -38.40343531348302,
                -12.962131985535603
              ],
              [
                -38.42440160351662,
                -12.975737456878289
              ],
              [
                -38.427731053937926,
                -12.978631412398126
              ],
              [
                -38.436160977789484,
                -12.986049358593496
              ],
              [
                -38.4386934652088,
                -12.988277876639641
              ],
              [
                -38.44083493557672,
                -12.990162859529343
              ],
              [
                -38.44207341906709,
                -12.991252333687546
              ],
              [
                -38.448304862121645,
                -12.996735324292946
              ],
              [
                -38.45092984860571,
                -12.999044822915614
              ],
              [
                -38.453367796978036,
                -13.001190317418425
              ],
              [
                -38.45687825651685,
                -13.004278769872819
              ],
              [
                -38.47185661993816,
                -13.011975731103712
              ],
              [
                -38.4750150972516,
                -13.01247276296108
              ],
              [
                -38.481216548206596,
                -13.013252763996674
              ],
              [
                -38.48873196344029,
                -13.014092755404251
              ],
              [
                -38.492862948822086,
                -13.011275262427922
              ],
              [
                -38.4929489321437,
                -13.011216257951281
              ],
              [
                -38.498060928781676,
                -13.00772981291561
              ],
              [
                -38.501609877433545,
                -13.009794305503231
              ],
              [
                -38.50757783925364,
                -13.009313790913462
              ],
              [
                -38.50886480000165,
                -13.009210298373276
              ],
              [
                -38.50991078885713,
                -13.009125823374951
              ],
              [
                -38.51330326219734,
                -13.00885331185718
              ],
              [
                -38.5169442343679,
                -13.008559821821427
              ],
              [
                -38.52465469296626,
                -13.007939350563175
              ],
              [
                -38.527561633556836,
                -13.007705355121809
              ],
              [
                -38.53271661550047,
                -13.007289877983066
              ],
              [
                -38.58074820122175,
                -12.999603956150766
              ],
              [
                -38.58281565139524,
                -12.99927347823492
              ],
              [
                -38.55925195099166,
                -12.953817770418219
              ],
              [
                -38.55817095093102,
                -12.932207403953178
              ],
              [
                -38.56175996699433,
                -12.919511487362449
              ],
              [
                -38.57006788505069,
                -12.921251498577433
              ],
              [
                -38.570407894942356,
                -12.921322980543579
              ],
              [
                -38.61080953471403,
                -12.92978446181027
              ],
              [
                -38.61585547760844,
                -12.930841982348507
              ],
              [
                -38.612117976606434,
                -12.94083139446372
              ],
              [
                -38.60547253636996,
                -12.958590780124903
              ],
              [
                -38.60954147209486,
                -12.966599205487654
              ],
              [
                -38.613178938826294,
                -12.973758173129252
              ],
              [
                -38.61354695054787,
                -12.974483660633895
              ],
              [
                -38.61552392957016,
                -12.978375647523748
              ],
              [
                -38.615400894003635,
                -12.983088631914114
              ],
              [
                -38.619835381497325,
                -12.983292637532974
              ],
              [
                -38.637427194805795,
                -13.004597499865223
              ],
              [
                -38.641264144297445,
                -13.009244985125449
              ],
              [
                -38.642286625159514,
                -13.010725454336777
              ],
              [
                -38.643308653025265,
                -13.012205938548105
              ],
              [
                -38.65491949751732,
                -13.029024347754092
              ],
              [
                -38.66579139192777,
                -13.036399329911953
              ],
              [
                -38.66909285748075,
                -13.036582798159275
              ],
              [
                -38.67300835155934,
                -13.036799822015054
              ],
              [
                -38.68420171801023,
                -13.044965796217001
              ],
              [
                -38.690118678572404,
                -13.049282243664981
              ],
              [
                -38.69803359240242,
                -13.05505574485286
              ],
              [
                -38.705597530365566,
                -13.057296703822178
              ],
              [
                -38.71404194618248,
                -13.059797700218743
              ],
              [
                -38.726463303684625,
                -13.071542656654756
              ],
              [
                -38.728960804497135,
                -13.077233619044478
              ],
              [
                -38.73049927789751,
                -13.080740610103017
              ],
              [
                -38.7364547352705,
                -13.08774856922223
              ],
              [
                -38.740131193493134,
                -13.087956566844339
              ],
              [
                -38.743643658178826,
                -13.088155565303557
              ],
              [
                -38.75034857910802,
                -13.094339018127828
              ],
              [
                -38.763077429011034,
                -13.117846392570032
              ],
              [
                -38.7987221238187,
                -13.135544316540692
              ],
              [
                -38.796120130534646,
                -13.13711680918834
              ],
              [
                -38.796643112167956,
                -13.137637791344915
              ],
              [
                -38.805306556252,
                -13.146276235201036
              ],
              [
                -38.81901140208926,
                -13.14847675608566
              ],
              [
                -38.821102411147116,
                -13.148812228681408
              ],
              [
                -38.833366274756706,
                -13.155155713261621
              ],
              [
                -38.83346426381901,
                -13.155206208051915
              ],
              [
                -38.84592314756778,
                -13.161650697238725
              ],
              [
                -38.85845104051856,
                -13.171695116952353
              ],
              [
                -38.86184352269883,
                -13.176462599084806
              ],
              [
                -38.863415002641986,
                -13.178671115177476
              ],
              [
                -38.865237478860614,
                -13.181232073212541
              ],
              [
                -38.867982444178836,
                -13.185010533214532
              ],
              [
                -38.88227430638415,
                -13.19102205600544
              ],
              [
                -38.8853512743582,
                -13.192321037410817
              ],
              [
                -38.905190088511915,
                -13.20533045420064
              ],
              [
                -38.90939754742437,
                -13.211522413812451
              ],
              [
                -38.91550848627599,
                -13.211693929148103
              ],
              [
                -38.921607426297584,
                -13.21182393675167
              ],
              [
                -38.924707399574764,
                -13.213467435859396
              ],
              [
                -38.93212031646645,
                -13.217420914225539
              ],
              [
                -38.93825578904114,
                -13.225906862924411
              ],
              [
                -38.94465870971143,
                -13.23476281649701
              ],
              [
                -38.94665966396438,
                -13.237584796059629
              ],
              [
                -38.9483726615237,
                -13.24194076977103
              ],
              [
                -38.95901402727099,
                -13.269003610326047
              ],
              [
                -38.96284600626499,
                -13.278747547358842
              ],
              [
                -38.96295047595554,
                -13.279013527694717
              ],
              [
                -38.96459047540641,
                -13.283183488610428
              ],
              [
                -38.966061458161306,
                -13.29090098115272
              ],
              [
                -38.96763285732033,
                -13.340736629423368
              ],
              [
                -38.96773535963458,
                -13.343994599422142
              ],
              [
                -38.96070338390248,
                -13.366968440390405
              ],
              [
                -38.94882299321805,
                -13.38565081070771
              ],
              [
                -38.946543002142086,
                -13.389176780537642
              ],
              [
                -38.94270049628931,
                -13.395121731730505
              ],
              [
                -38.938518574796454,
                -13.391314751688325
              ],
              [
                -38.935467092812026,
                -13.38853725925228
              ],
              [
                -38.93382360051649,
                -13.387041289097803
              ],
              [
                -38.928314638509256,
                -13.38202629240626
              ],
              [
                -38.92595817845935,
                -13.379881326661481
              ],
              [
                -38.92250121159514,
                -13.376733830181891
              ],
              [
                -38.917438746045,
                -13.37212634036997
              ],
              [
                -38.91395778584229,
                -13.375477324685102
              ],
              [
                -38.90900184168741,
                -13.380169779801827
              ],
              [
                -38.90584637851752,
                -13.377463819752364
              ],
              [
                -38.90432534371475,
                -13.389861232330226
              ],
              [
                -38.903657366603895,
                -13.395303205399458
              ],
              [
                -38.904928837471736,
                -13.401354152070434
              ],
              [
                -38.90226683390774,
                -13.407508595491608
              ],
              [
                -38.90096934059247,
                -13.412782070827589
              ],
              [
                -38.90192035423631,
                -13.416147050308627
              ],
              [
                -38.9022878300769,
                -13.417446042002728
              ],
              [
                -38.90473779678308,
                -13.426031974141434
              ],
              [
                -38.896931863727175,
                -13.430082442593076
              ],
              [
                -38.89458087868591,
                -13.431302422982231
              ],
              [
                -38.891702416459346,
                -13.437158884132826
              ],
              [
                -38.89021492130489,
                -13.44141885309575
              ],
              [
                -38.887969918635875,
                -13.44784681226487
              ],
              [
                -38.89130537889286,
                -13.452777280507107
              ],
              [
                -38.88918441146715,
                -13.456941763227684
              ],
              [
                -38.89112537232635,
                -13.46167419984839
              ],
              [
                -38.89788830232767,
                -13.469967164144276
              ],
              [
                -38.90368924177842,
                -13.476936155081056
              ],
              [
                -38.9045242607217,
                -13.476722123718163
              ],
              [
                -38.90988219544111,
                -13.475350667626408
              ],
              [
                -38.91577662960856,
                -13.473856682127042
              ],
              [
                -38.91799810388974,
                -13.484046598156228
              ],
              [
                -38.91116916841739,
                -13.483892091506702
              ],
              [
                -38.907771198580015,
                -13.483786067401184
              ],
              [
                -38.90489320712894,
                -13.483695589928935
              ],
              [
                -38.904409733195976,
                -13.487204582437531
              ],
              [
                -38.90915818503672,
                -13.489380053424517
              ],
              [
                -38.907512171750454,
                -13.493978537895787
              ],
              [
                -38.90977667725595,
                -13.497682510993071
              ],
              [
                -38.911702623990045,
                -13.50055097227847
              ],
              [
                -38.91659909415838,
                -13.503643990268175
              ],
              [
                -38.919953584389134,
                -13.505763457669604
              ],
              [
                -38.92124656575897,
                -13.506579980973434
              ],
              [
                -38.926608010339464,
                -13.50999795043253
              ],
              [
                -38.929533941122486,
                -13.5183254031582
              ],
              [
                -38.93164395577969,
                -13.524852863932056
              ],
              [
                -38.93198494443385,
                -13.528436812135139
              ],
              [
                -38.932427419603414,
                -13.533086283266503
              ],
              [
                -38.934125367017394,
                -13.550928173842978
              ],
              [
                -38.934920876529176,
                -13.55928110941954
              ],
              [
                -38.932659353527725,
                -13.569977558773079
              ],
              [
                -38.92928441282181,
                -13.572997040508154
              ],
              [
                -38.92638493296732,
                -13.575313999678235
              ],
              [
                -38.923044941599485,
                -13.575225010504935
              ],
              [
                -38.91646949594032,
                -13.575049488292523
              ],
              [
                -38.91509000304412,
                -13.584472421529208
              ],
              [
                -38.91438051108262,
                -13.588638393315177
              ],
              [
                -38.908855552586886,
                -13.592853369879448
              ],
              [
                -38.90198012465185,
                -13.597986817292686
              ],
              [
                -38.90484305106646,
                -13.60486279085535
              ],
              [
                -38.905784070366416,
                -13.607124260899482
              ],
              [
                -38.905690057146444,
                -13.615169709326146
              ],
              [
                -38.90552653059865,
                -13.621170178496028
              ],
              [
                -38.901417081952054,
                -13.623143659252495
              ],
              [
                -38.89796308639001,
                -13.624533634896002
              ],
              [
                -38.89633609778647,
                -13.625188134134964
              ],
              [
                -38.88903569740445,
                -13.620449667729902
              ],
              [
                -38.89014516238444,
                -13.623929627773045
              ],
              [
                -38.888432201236434,
                -13.630124569402588
              ],
              [
                -38.88835368339788,
                -13.635147042311965
              ],
              [
                -38.888232149749896,
                -13.642959010295039
              ],
              [
                -38.88906915028695,
                -13.646332956677105
              ],
              [
                -38.892319113709725,
                -13.658881917487916
              ],
              [
                -38.89891854757692,
                -13.663407387381499
              ],
              [
                -38.91007192257807,
                -13.671003336088246
              ],
              [
                -38.913561889313506,
                -13.673277341745631
              ],
              [
                -38.91708637853978,
                -13.671702346461354
              ],
              [
                -38.91912037131213,
                -13.666556877157042
              ],
              [
                -38.92152235723385,
                -13.66047893468528
              ],
              [
                -38.923000837982,
                -13.656615458921596
              ],
              [
                -38.93661623898379,
                -13.655094475783732
              ],
              [
                -38.94042019711728,
                -13.658592979618636
              ],
              [
                -38.94368664574231,
                -13.661596946025531
              ],
              [
                -38.9479226154596,
                -13.665492929806888
              ],
              [
                -38.9482005856354,
                -13.665737923552383
              ],
              [
                -38.95105059668428,
                -13.668245895717964
              ],
              [
                -38.955963017372035,
                -13.668858904654416
              ],
              [
                -38.95922450295252,
                -13.669266420601346
              ],
              [
                -38.96635494063822,
                -13.670156900863207
              ],
              [
                -38.96534942645814,
                -13.67682987536117
              ],
              [
                -38.965211461304385,
                -13.681054842640304
              ],
              [
                -38.965025913358,
                -13.68674482496455
              ],
              [
                -38.972255378152944,
                -13.693605254098427
              ],
              [
                -38.976137295983776,
                -13.698826242475818
              ],
              [
                -38.987828700914235,
                -13.71458162710199
              ],
              [
                -38.9914276305448,
                -13.725538100435426
              ],
              [
                -38.99651307712766,
                -13.74104749626492
              ],
              [
                -38.99684906660425,
                -13.742073494837971
              ],
              [
                -38.99750409479026,
                -13.744070466330886
              ],
              [
                -38.997689550240445,
                -13.747307430788592
              ],
              [
                -38.99847902830277,
                -13.761113867921095
              ],
              [
                -38.999451509855284,
                -13.77812125279427
              ],
              [
                -38.99973397485129,
                -13.78305571198075
              ],
              [
                -38.99859299336669,
                -13.78605218114766
              ],
              [
                -38.99443303246538,
                -13.796980121530282
              ],
              [
                -38.993039037198336,
                -13.800642071852266
              ],
              [
                -38.986451579003855,
                -13.817945440805158
              ],
              [
                -38.98403607066274,
                -13.824288908600058
              ],
              [
                -38.981972590070335,
                -13.826793392740448
              ],
              [
                -38.969030690442096,
                -13.842496756212453
              ],
              [
                -38.951154827305395,
                -13.871352551011153
              ],
              [
                -38.94661732320595,
                -13.878675485131028
              ],
              [
                -38.93617741322695,
                -13.884737461100357
              ],
              [
                -38.93165042264167,
                -13.896883875800098
              ],
              [
                -38.93057495172692,
                -13.899769840821817
              ],
              [
                -38.92987743602744,
                -13.901639810228206
              ],
              [
                -38.929201962220866,
                -13.903452311053451
              ],
              [
                -38.92808393850599,
                -13.906452303247546
              ],
              [
                -38.92988293326708,
                -13.920344217503647
              ],
              [
                -38.93002740927789,
                -13.921458205228609
              ],
              [
                -38.93062792983989,
                -13.926096160940778
              ],
              [
                -38.93117291955592,
                -13.930301134484361
              ],
              [
                -38.930113398013376,
                -13.93284664182664
              ],
              [
                -38.9275874010276,
                -13.938912086368482
              ],
              [
                -38.92901140743521,
                -13.944109044545506
              ],
              [
                -38.92982039973399,
                -13.947062011897364
              ],
              [
                -38.93295536038341,
                -13.958502446667751
              ],
              [
                -38.93667930648792,
                -13.972093367894415
              ],
              [
                -38.937676295054835,
                -13.975731838938072
              ],
              [
                -38.93833728348536,
                -13.978143816721586
              ],
              [
                -38.93833778647472,
                -13.978147811695555
              ],
              [
                -38.94065721589321,
                -13.986608772328163
              ],
              [
                -38.94473416483258,
                -14.001487186514835
              ],
              [
                -38.944151672241105,
                -14.007786121405717
              ],
              [
                -38.943560180575375,
                -14.014181575640837
              ],
              [
                -38.94446015261494,
                -14.018701556658755
              ],
              [
                -38.94604814321166,
                -14.026676499532782
              ],
              [
                -38.947617594105026,
                -14.03456045500094
              ],
              [
                -38.94940508501599,
                -14.043117911289295
              ],
              [
                -38.9511065472101,
                -14.048756871018998
              ],
              [
                -38.95205253309711,
                -14.051811843949508
              ],
              [
                -38.95372752787842,
                -14.057219335200736
              ],
              [
                -38.95656447854336,
                -14.06637825205272
              ],
              [
                -38.95652748513443,
                -14.07006672829491
              ],
              [
                -38.95642645138343,
                -14.080121179816679
              ],
              [
                -38.958936442692774,
                -14.087495627168007
              ],
              [
                -38.96137988097317,
                -14.094620106105294
              ],
              [
                -38.96277138133806,
                -14.098676572022555
              ],
              [
                -38.96415887675331,
                -14.102722063010896
              ],
              [
                -38.966358357287135,
                -14.109135494366125
              ],
              [
                -38.96918979634836,
                -14.11739045235157
              ],
              [
                -38.97667721797407,
                -14.139222837499474
              ],
              [
                -38.977254178751664,
                -14.145048773413594
              ],
              [
                -38.97783068853193,
                -14.15087274334562
              ],
              [
                -38.9796831538195,
                -14.169581133097305
              ],
              [
                -38.98299659460052,
                -14.17589109689466
              ],
              [
                -38.98560108094498,
                -14.180739043406623
              ],
              [
                -38.98912603345239,
                -14.18729899886925
              ],
              [
                -38.98964303973212,
                -14.190573979775213
              ],
              [
                -38.99084649732257,
                -14.198691923379126
              ],
              [
                -38.99206949752998,
                -14.206937890169145
              ],
              [
                -38.99231148382057,
                -14.20856989463934
              ],
              [
                -38.99266797288959,
                -14.210969378159646
              ],
              [
                -38.99033346002561,
                -14.218287802824557
              ],
              [
                -38.991570953617476,
                -14.222387271325728
              ],
              [
                -38.992223925532606,
                -14.228927753669103
              ],
              [
                -38.994501409149166,
                -14.241754637529464
              ],
              [
                -38.994838384921756,
                -14.243811157320573
              ],
              [
                -38.99685837957688,
                -14.256141053141297
              ],
              [
                -38.99521436103039,
                -14.262531007071797
              ],
              [
                -38.99251339313256,
                -14.271262478809462
              ],
              [
                -38.99048341363587,
                -14.27534993951294
              ],
              [
                -38.99038738173667,
                -14.27519142242498
              ],
              [
                -38.98817643708752,
                -14.27153647446019
              ],
              [
                -38.98489793039937,
                -14.275778930251036
              ],
              [
                -38.985569945696604,
                -14.285735348906913
              ],
              [
                -38.98579040296906,
                -14.290015341718934
              ],
              [
                -38.981772469142875,
                -14.291528827583898
              ],
              [
                -38.98196245080299,
                -14.295732806858393
              ],
              [
                -38.985957877717205,
                -14.305017716023022
              ],
              [
                -38.98845887426719,
                -14.30647372709486
              ],
              [
                -38.990170362536205,
                -14.307469717041455
              ],
              [
                -38.98959737696127,
                -14.31040021465993
              ],
              [
                -38.98922885223077,
                -14.312287176537252
              ],
              [
                -38.99023135088123,
                -14.31258617305807
              ],
              [
                -38.99250932963638,
                -14.313265686968926
              ],
              [
                -38.997579253760016,
                -14.32963759361955
              ],
              [
                -39.00065871277842,
                -14.339527508449061
              ],
              [
                -39.00624314395203,
                -14.340401030077803
              ],
              [
                -39.00470717363375,
                -14.349147947557478
              ],
              [
                -39.00306368169229,
                -14.357622929691415
              ],
              [
                -39.001468172709835,
                -14.365852358538504
              ],
              [
                -39.00870806546807,
                -14.39289616970656
              ],
              [
                -39.01030053590518,
                -14.398820149753101
              ],
              [
                -39.0150244979725,
                -14.416397549145437
              ],
              [
                -39.01594998027197,
                -14.419838017700135
              ],
              [
                -39.01787295802999,
                -14.43121294308431
              ],
              [
                -39.02197188885224,
                -14.455459286375552
              ],
              [
                -39.02376083101323,
                -14.466043703871447
              ],
              [
                -39.024687804090185,
                -14.471466664309059
              ],
              [
                -39.02586927527057,
                -14.474799179006201
              ],
              [
                -39.02712027951851,
                -14.478327655512285
              ],
              [
                -39.02911975973795,
                -14.483968599154963
              ],
              [
                -39.030519770983545,
                -14.487918568100888
              ],
              [
                -39.03516470074625,
                -14.501022994323856
              ],
              [
                -39.03566265563713,
                -14.516342403558651
              ],
              [
                -39.035798637692444,
                -14.520519892087004
              ],
              [
                -39.0359241333688,
                -14.524368834778537
              ],
              [
                -39.0360351478553,
                -14.527794344255122
              ],
              [
                -39.03619862029062,
                -14.53282031321244
              ],
              [
                -39.04645847837638,
                -14.564379128001665
              ],
              [
                -39.04687696858458,
                -14.565666598123832
              ],
              [
                -39.04818146053208,
                -14.569678065581424
              ],
              [
                -39.051441908142614,
                -14.582946995803244
              ],
              [
                -39.053037906469214,
                -14.589441468300205
              ],
              [
                -39.059975308153284,
                -14.617671267338977
              ],
              [
                -39.0621292708078,
                -14.626438224723683
              ],
              [
                -39.06224777097514,
                -14.626919231728133
              ],
              [
                -39.06463823495639,
                -14.636648172138605
              ],
              [
                -39.06501267361003,
                -14.678948364396652
              ],
              [
                -39.0651626405397,
                -14.695909759699205
              ],
              [
                -39.06520612809008,
                -14.700836256263871
              ],
              [
                -39.065243608779,
                -14.705098211206362
              ],
              [
                -39.064708604121414,
                -14.711445659507328
              ],
              [
                -39.06445362642195,
                -14.714471662153338
              ],
              [
                -39.0624935846541,
                -14.7377214918033
              ],
              [
                -39.06190160136781,
                -14.744744452588762
              ],
              [
                -39.06130960307427,
                -14.751768393373856
              ],
              [
                -39.053141609728954,
                -14.777755208426232
              ],
              [
                -39.04386268224068,
                -14.783069169956661
              ],
              [
                -39.04097223421675,
                -14.784679155844685
              ],
              [
                -39.03918874939394,
                -14.785672666519387
              ],
              [
                -39.036591764203536,
                -14.782767650610435
              ],
              [
                -39.03186827479512,
                -14.784767162074852
              ],
              [
                -39.03220127133005,
                -14.804501032600177
              ],
              [
                -39.0329417717179,
                -14.805135012580484
              ],
              [
                -39.03604221584504,
                -14.807790004935498
              ],
              [
                -39.033511248835744,
                -14.811096474211256
              ],
              [
                -39.03191527097238,
                -14.809288003628609
              ],
              [
                -39.02774431675858,
                -14.804560023256748
              ],
              [
                -39.02436832890098,
                -14.805650990803345
              ],
              [
                -39.02465332253602,
                -14.81464792099493
              ],
              [
                -39.02514080077539,
                -14.830057848294853
              ],
              [
                -39.02532626639338,
                -14.83595180878704
              ],
              [
                -39.025374262463345,
                -14.837467286887069
              ],
              [
                -39.02546478093024,
                -14.840329259191726
              ],
              [
                -39.02514224088251,
                -14.879540529723084
              ],
              [
                -39.02269874604404,
                -14.88347147804183
              ],
              [
                -39.022372753945255,
                -14.88768697681162
              ],
              [
                -39.0222492363872,
                -14.889286936097964
              ],
              [
                -39.02177471139976,
                -14.895432900946322
              ],
              [
                -39.02133072681265,
                -14.901171885522663
              ],
              [
                -39.02102074189775,
                -14.905185868651522
              ],
              [
                -39.01977870409755,
                -14.920123262533645
              ],
              [
                -39.01326825072179,
                -14.937048138098291
              ],
              [
                -39.01093673332083,
                -14.943112069596294
              ],
              [
                -39.01008074321363,
                -14.945337050634336
              ],
              [
                -39.007938748952654,
                -14.950905023691506
              ],
              [
                -39.006767757145376,
                -14.95582749051939
              ],
              [
                -39.00392628645643,
                -14.96777193960963
              ],
              [
                -39.00280828354016,
                -14.972472873983358
              ],
              [
                -39.001756780501815,
                -14.976891847315015
              ],
              [
                -39.00030480220417,
                -14.98299579795648
              ],
              [
                -38.997779306893975,
                -14.993613748281744
              ],
              [
                -38.99745315106057,
                -15.093032080011103
              ],
              [
                -38.99731408158485,
                -15.13023685424735
              ],
              [
                -38.9970489771198,
                -15.201629395037571
              ],
              [
                -38.99793847854726,
                -15.214980820246454
              ],
              [
                -38.99882791895116,
                -15.228332737479885
              ],
              [
                -38.99432495390373,
                -15.250124558001936
              ],
              [
                -38.99375992327065,
                -15.252859049248485
              ],
              [
                -38.993085457815845,
                -15.261341990808232
              ],
              [
                -38.99279241313834,
                -15.265028492283413
              ],
              [
                -38.9927854140566,
                -15.265112959721051
              ],
              [
                -38.992515927666744,
                -15.26850693614438
              ],
              [
                -38.991807902779854,
                -15.277408896933617
              ],
              [
                -38.990797907090304,
                -15.29010529150147
              ],
              [
                -38.993144861659665,
                -15.295702289781207
              ],
              [
                -38.98947589094854,
                -15.29999775286845
              ],
              [
                -38.98672993787466,
                -15.303213247482955
              ],
              [
                -38.979420427253025,
                -15.349152913311475
              ],
              [
                -38.97665293702957,
                -15.366549794535988
              ],
              [
                -38.97502792295507,
                -15.376760247426423
              ],
              [
                -38.973903414822864,
                -15.383824688617178
              ],
              [
                -38.97359792024223,
                -15.385744156628043
              ],
              [
                -38.97271242854444,
                -15.388988650075982
              ],
              [
                -38.96939542717401,
                -15.401134064659473
              ],
              [
                -38.967995449812356,
                -15.406260015035535
              ],
              [
                -38.95696348138171,
                -15.446661265344096
              ],
              [
                -38.953681463852334,
                -15.458680169869703
              ],
              [
                -38.95425597119329,
                -15.462468150178307
              ],
              [
                -38.955467927594086,
                -15.470457612217915
              ],
              [
                -38.952266980384955,
                -15.473183089299907
              ],
              [
                -38.95083049306214,
                -15.474406051015864
              ],
              [
                -38.94215846686237,
                -15.527769192257374
              ],
              [
                -38.942864936355704,
                -15.553797537473434
              ],
              [
                -38.94295942647016,
                -15.557273031077615
              ],
              [
                -38.94296940671953,
                -15.557653507625355
              ],
              [
                -38.94308441032651,
                -15.561889510331184
              ],
              [
                -38.943992856971,
                -15.595336770912283
              ],
              [
                -38.936470306280555,
                -15.65640935690263
              ],
              [
                -38.93519734498745,
                -15.661342352871667
              ],
              [
                -38.93287134092633,
                -15.670356788690423
              ],
              [
                -38.93139182205927,
                -15.67609325912546
              ],
              [
                -38.92842783133164,
                -15.687582181893244
              ],
              [
                -38.924726350146976,
                -15.701929588964916
              ],
              [
                -38.92265084028506,
                -15.708970536996084
              ],
              [
                -38.919254383243,
                -15.710867006127996
              ],
              [
                -38.91432993333144,
                -15.713616501221907
              ],
              [
                -38.9088039333177,
                -15.729712372000154
              ],
              [
                -38.90581898164383,
                -15.738407298846882
              ],
              [
                -38.90542347714422,
                -15.739559310744953
              ],
              [
                -38.90323150045702,
                -15.745944252842538
              ],
              [
                -38.898359981918134,
                -15.760135151056232
              ],
              [
                -38.89955146333998,
                -15.764642137896432
              ],
              [
                -38.894636516877796,
                -15.76822608060565
              ],
              [
                -38.893097539752034,
                -15.771548095590985
              ],
              [
                -38.891907005844544,
                -15.774117069019784
              ],
              [
                -38.887491049434864,
                -15.78364850312188
              ],
              [
                -38.88487406581806,
                -15.789297952288052
              ],
              [
                -38.88316759354274,
                -15.792982428658357
              ],
              [
                -38.86916214813103,
                -15.823213695184126
              ],
              [
                -38.86762767097292,
                -15.824829688203895
              ],
              [
                -38.86651718381744,
                -15.826000182803648
              ],
              [
                -38.8639922026576,
                -15.828659678442097
              ],
              [
                -38.86062871341966,
                -15.83220413797503
              ],
              [
                -38.860834224527856,
                -15.836189624582225
              ],
              [
                -38.86376718466142,
                -15.838264112056233
              ],
              [
                -38.86638617199286,
                -15.84011609544753
              ],
              [
                -38.865401162739694,
                -15.849737035710623
              ],
              [
                -38.86229116086166,
                -15.851233512886385
              ],
              [
                -38.85999318242458,
                -15.85233951293103
              ],
              [
                -38.85674922663066,
                -15.85390049946748
              ],
              [
                -38.85633919927319,
                -15.86011494567811
              ],
              [
                -38.8638626302268,
                -15.868483404185362
              ],
              [
                -38.86575212126091,
                -15.872476876564775
              ],
              [
                -38.884393872718746,
                -15.911860673705261
              ],
              [
                -38.886905839829424,
                -15.917166655710835
              ],
              [
                -38.887441852159334,
                -15.91878412918535
              ],
              [
                -38.88797781449104,
                -15.920400613666661
              ],
              [
                -38.9136834672265,
                -15.997951166661636
              ],
              [
                -38.914815992982085,
                -16.001367637602467
              ],
              [
                -38.91594844073671,
                -16.0047841065456
              ],
              [
                -38.92595931731614,
                -16.0281674991386
              ],
              [
                -38.93023128523218,
                -16.038133913326504
              ],
              [
                -38.931906754235406,
                -16.042040899063103
              ],
              [
                -38.93710118301752,
                -16.054157833036705
              ],
              [
                -38.94217863437889,
                -16.070955749820083
              ],
              [
                -38.94317812048599,
                -16.0742617373006
              ],
              [
                -38.9440725721569,
                -16.07722022883481
              ],
              [
                -38.94638955862113,
                -16.084885181591776
              ],
              [
                -38.9484460350294,
                -16.091650630687635
              ],
              [
                -38.94856703265042,
                -16.091815639834138
              ],
              [
                -38.95057999068656,
                -16.094573094557607
              ],
              [
                -38.95309149509056,
                -16.097982088949877
              ],
              [
                -38.957711891298985,
                -16.124789414999558
              ],
              [
                -38.95662139888742,
                -16.13259887713559
              ],
              [
                -38.955531374464755,
                -16.140408342279503
              ],
              [
                -38.948641920070415,
                -16.155810206992214
              ],
              [
                -38.953135364776735,
                -16.16084666537414
              ],
              [
                -38.954263845397286,
                -16.162111184195155
              ],
              [
                -38.95649136097367,
                -16.164600183045188
              ],
              [
                -38.959560280912356,
                -16.17375661166407
              ],
              [
                -38.9642847410563,
                -16.18785353451535
              ],
              [
                -38.96715919623887,
                -16.189026533899426
              ],
              [
                -38.96942870227638,
                -16.189952518835796
              ],
              [
                -38.970988653777184,
                -16.195752474402372
              ],
              [
                -38.97104063994652,
                -16.195953492205586
              ],
              [
                -38.97520356840053,
                -16.212057915345483
              ],
              [
                -38.978559050080435,
                -16.213889388354573
              ],
              [
                -38.982972013443565,
                -16.216197392122076
              ],
              [
                -38.986502967528125,
                -16.21804437733606
              ],
              [
                -38.991483429280485,
                -16.220649352180136
              ],
              [
                -39.0091742544565,
                -16.24188028578081
              ],
              [
                -39.01002372115968,
                -16.245001231310432
              ],
              [
                -39.011033702354226,
                -16.24870872437277
              ],
              [
                -39.01295521917479,
                -16.249810199625053
              ],
              [
                -39.01724116139219,
                -16.252268723257767
              ],
              [
                -39.01974611696663,
                -16.269218595408578
              ],
              [
                -39.01975209356448,
                -16.279770056128473
              ],
              [
                -39.0201055924937,
                -16.282993023182783
              ],
              [
                -39.02058207328196,
                -16.287328000359732
              ],
              [
                -39.021114069623444,
                -16.289455987704237
              ],
              [
                -39.022356051407975,
                -16.29442345316556
              ],
              [
                -39.02208452785018,
                -16.30203590218109
              ],
              [
                -39.02206304692221,
                -16.30264538725998
              ],
              [
                -39.02074650725731,
                -16.316476811867478
              ],
              [
                -39.02017499932518,
                -16.322477289659346
              ],
              [
                -39.01685055477553,
                -16.325875763306055
              ],
              [
                -39.01340705533644,
                -16.329249233907596
              ],
              [
                -39.005028152132056,
                -16.332009702944077
              ],
              [
                -39.0058066301025,
                -16.33965364658859
              ],
              [
                -39.00931108081711,
                -16.34669260577771
              ],
              [
                -39.01199452391367,
                -16.35316104120094
              ],
              [
                -39.008165013843396,
                -16.37500441757412
              ],
              [
                -39.01342048299808,
                -16.376559412721775
              ],
              [
                -39.019245440757444,
                -16.378280930793387
              ],
              [
                -39.02515188374679,
                -16.380026401856878
              ],
              [
                -39.02971133341503,
                -16.38467239215543
              ],
              [
                -39.03873972171512,
                -16.393876825178232
              ],
              [
                -39.04261117791189,
                -16.400474291909745
              ],
              [
                -39.04648265810121,
                -16.40707229065011
              ],
              [
                -39.05819498830437,
                -16.427110143564217
              ],
              [
                -39.060027475905656,
                -16.44813005226074
              ],
              [
                -39.06027896483619,
                -16.44857253288691
              ],
              [
                -39.063962389910664,
                -16.45503502024147
              ],
              [
                -39.061091413036664,
                -16.45693347922238
              ],
              [
                -39.059757444316,
                -16.457816462308998
              ],
              [
                -39.05941544109009,
                -16.463345963624253
              ],
              [
                -39.06054492107037,
                -16.47244340185451
              ],
              [
                -39.06499236698383,
                -16.4790718695121
              ],
              [
                -39.066364336913345,
                -16.481117354916183
              ],
              [
                -39.066536319218365,
                -16.486570319629738
              ],
              [
                -39.06671780986872,
                -16.492326782439797
              ],
              [
                -39.068935269056354,
                -16.498223760897602
              ],
              [
                -39.06952278489259,
                -16.499785733014672
              ],
              [
                -39.070566788389655,
                -16.502558699247903
              ],
              [
                -39.075045221170015,
                -16.51445064265354
              ],
              [
                -39.07874264278362,
                -16.524269595865835
              ],
              [
                -39.082858117205106,
                -16.535394043559304
              ],
              [
                -39.08426658059315,
                -16.549301464947153
              ],
              [
                -39.08503756444298,
                -16.55691587808721
              ],
              [
                -39.08509656682448,
                -16.557498386502814
              ],
              [
                -39.08574955190467,
                -16.563947345823323
              ],
              [
                -39.08747899740072,
                -16.58103576171275
              ],
              [
                -39.088034958124815,
                -16.586527193945635
              ],
              [
                -39.08803696507676,
                -16.586543219847794
              ],
              [
                -39.08859096084521,
                -16.5920181901864
              ],
              [
                -39.08875246847094,
                -16.592513677334736
              ],
              [
                -39.0938648793244,
                -16.608183104188985
              ],
              [
                -39.092770896854965,
                -16.614165044463324
              ],
              [
                -39.0919118986968,
                -16.61886300726753
              ],
              [
                -39.091454888076704,
                -16.621364008661622
              ],
              [
                -39.09036187439898,
                -16.62744745030484
              ],
              [
                -39.09247387712344,
                -16.63455943706395
              ],
              [
                -39.09499731454779,
                -16.643058387793484
              ],
              [
                -39.09588481340337,
                -16.646352357542995
              ],
              [
                -39.09422329506506,
                -16.652289312136748
              ],
              [
                -39.097214266969594,
                -16.65777677272826
              ],
              [
                -39.09915525540771,
                -16.661065771365728
              ],
              [
                -39.10093772276942,
                -16.664085767426315
              ],
              [
                -39.10288369152071,
                -16.667183720284257
              ],
              [
                -39.103007213584455,
                -16.67133071032594
              ],
              [
                -39.1031786623715,
                -16.681746135897765
              ],
              [
                -39.10325516189583,
                -16.68639659568933
              ],
              [
                -39.10333515405128,
                -16.691224069373604
              ],
              [
                -39.105373644483905,
                -16.700916030821187
              ],
              [
                -39.106011639127956,
                -16.7039010211173
              ],
              [
                -39.10721661296879,
                -16.70938449365738
              ],
              [
                -39.11327856258631,
                -16.711587466431205
              ],
              [
                -39.11449855483849,
                -16.712029970787864
              ],
              [
                -39.114934523899784,
                -16.71468996778581
              ],
              [
                -39.11563002163589,
                -16.718934434254155
              ],
              [
                -39.12002896924473,
                -16.724237412575704
              ],
              [
                -39.125810881323325,
                -16.731205854849865
              ],
              [
                -39.12659688216337,
                -16.73654381860533
              ],
              [
                -39.12675089080045,
                -16.73758983628732
              ],
              [
                -39.12723086653044,
                -16.741264803984112
              ],
              [
                -39.12762085737579,
                -16.744248802876463
              ],
              [
                -39.12804586178229,
                -16.747298287419724
              ],
              [
                -39.13059834576349,
                -16.748979270335486
              ],
              [
                -39.13328580238946,
                -16.750738285999546
              ],
              [
                -39.1341777763046,
                -16.751321738899367
              ],
              [
                -39.13756128412955,
                -16.749701791069292
              ],
              [
                -39.13895674753242,
                -16.75239526557244
              ],
              [
                -39.14100570408273,
                -16.756670745271652
              ],
              [
                -39.141714192680546,
                -16.766693695433094
              ],
              [
                -39.14313416168169,
                -16.786806058391353
              ],
              [
                -39.143470149610195,
                -16.791563014062774
              ],
              [
                -39.143953098247806,
                -16.798403490894508
              ],
              [
                -39.14383013528779,
                -16.803676947514514
              ],
              [
                -39.14361561343055,
                -16.810895423747464
              ],
              [
                -39.1428310451237,
                -16.837235237804254
              ],
              [
                -39.14245006904689,
                -16.85003365672245
              ],
              [
                -39.14195001209966,
                -16.86539757034162
              ],
              [
                -39.13207760408798,
                -16.88018494795227
              ],
              [
                -39.128229129986515,
                -16.885680939607056
              ],
              [
                -39.1271866411764,
                -16.886274900024905
              ],
              [
                -39.11920569088434,
                -16.890819860302763
              ],
              [
                -39.111526272231956,
                -16.895192318198166
              ],
              [
                -39.11415420748713,
                -16.896871829327264
              ],
              [
                -39.1173181882851,
                -16.89889482025536
              ],
              [
                -39.12413461185361,
                -16.903282306844456
              ],
              [
                -39.136311986763054,
                -16.919991710693743
              ],
              [
                -39.15219230570541,
                -16.941784617375923
              ],
              [
                -39.15319577525654,
                -16.95344354010823
              ],
              [
                -39.153872784552846,
                -16.961555974494406
              ],
              [
                -39.15418224861976,
                -16.96526897178936
              ],
              [
                -39.15449374763283,
                -16.968999456980686
              ],
              [
                -39.15502072071583,
                -16.975319415343474
              ],
              [
                -39.15537022249032,
                -16.979512372713163
              ],
              [
                -39.15713769863955,
                -16.98315234108455
              ],
              [
                -39.160444155599684,
                -16.989592830682984
              ],
              [
                -39.17296298136797,
                -17.013980208514845
              ],
              [
                -39.17332892019047,
                -17.05208696993333
              ],
              [
                -39.16872145908668,
                -17.058445924917685
              ],
              [
                -39.16550000537172,
                -17.062335366824453
              ],
              [
                -39.17262339556724,
                -17.064409882668986
              ],
              [
                -39.17495638039139,
                -17.065073858715866
              ],
              [
                -39.17581290425044,
                -17.065317362734913
              ],
              [
                -39.17847986619911,
                -17.076207799511003
              ],
              [
                -39.17965979693028,
                -17.081196285483337
              ],
              [
                -39.179920814438915,
                -17.088468235549733
              ],
              [
                -39.180639263033335,
                -17.108472135987515
              ],
              [
                -39.18444970912867,
                -17.11759257495434
              ],
              [
                -39.18728171341546,
                -17.119825539134105
              ],
              [
                -39.18991363290986,
                -17.12184506528644
              ],
              [
                -39.19774356107965,
                -17.127853003952257
              ],
              [
                -39.20495699521582,
                -17.140793436308627
              ],
              [
                -39.20614548125408,
                -17.14504943293097
              ],
              [
                -39.20733393229187,
                -17.149304424563237
              ],
              [
                -39.211239399959034,
                -17.16328734351121
              ],
              [
                -39.21259887025625,
                -17.1681538136606
              ],
              [
                -39.21260487625148,
                -17.17119128175429
              ],
              [
                -39.21257188858275,
                -17.174234757741257
              ],
              [
                -39.21252237783121,
                -17.17892573444106
              ],
              [
                -39.21244334914685,
                -17.18626518260186
              ],
              [
                -39.21234234154081,
                -17.195699625690597
              ],
              [
                -39.21199176456422,
                -17.228332406018723
              ],
              [
                -39.21188376064527,
                -17.238439341970093
              ],
              [
                -39.21414722539478,
                -17.246554818628585
              ],
              [
                -39.2151832209551,
                -17.250190302913467
              ],
              [
                -39.21652319168926,
                -17.254892284125596
              ],
              [
                -39.2170271968615,
                -17.258095749133545
              ],
              [
                -39.218999129988404,
                -17.270880179286117
              ],
              [
                -39.221211611852446,
                -17.285226588205795
              ],
              [
                -39.22069958242551,
                -17.291308550504485
              ],
              [
                -39.22018756899287,
                -17.297391018804873
              ],
              [
                -39.21851405765692,
                -17.31727687431541
              ],
              [
                -39.21769556973756,
                -17.327002349472398
              ],
              [
                -39.21764753704122,
                -17.327571322854478
              ],
              [
                -39.21692455050979,
                -17.33615827026142
              ],
              [
                -39.20371405177932,
                -17.40471784907969
              ],
              [
                -39.19091009441695,
                -17.44646605306011
              ],
              [
                -39.191151064359225,
                -17.456437009792335
              ],
              [
                -39.19124002920378,
                -17.460122468150246
              ],
              [
                -39.19136804146177,
                -17.465427434561928
              ],
              [
                -39.191530512556575,
                -17.472158903216865
              ],
              [
                -39.19257594658732,
                -17.51543011760792
              ],
              [
                -39.19144793146101,
                -17.52702608787769
              ],
              [
                -39.191235953987785,
                -17.529207545009765
              ],
              [
                -39.19053240479362,
                -17.536438504043794
              ],
              [
                -39.18955842944115,
                -17.546447936426343
              ],
              [
                -39.189174416954636,
                -17.550397940325244
              ],
              [
                -39.188132397787854,
                -17.561107865275282
              ],
              [
                -39.18971585757511,
                -17.565585826561964
              ],
              [
                -39.191272341752175,
                -17.569988797266088
              ],
              [
                -39.19444332225576,
                -17.578954240810596
              ],
              [
                -39.193573805494026,
                -17.582216243074537
              ],
              [
                -39.184441826126815,
                -17.61365300725561
              ],
              [
                -39.18319085367191,
                -17.617959490382773
              ],
              [
                -39.18156734255681,
                -17.62028499403364
              ],
              [
                -39.18087937674243,
                -17.621271971672428
              ],
              [
                -39.158214489713146,
                -17.653747252423052
              ],
              [
                -39.149275566124636,
                -17.666555662886882
              ],
              [
                -39.13692862027795,
                -17.68424751093328
              ],
              [
                -39.13539612809978,
                -17.687681477929118
              ],
              [
                -39.13963408745947,
                -17.69388247661769
              ],
              [
                -39.145606028878575,
                -17.69927041655207
              ],
              [
                -39.17564069840156,
                -17.72569531890305
              ],
              [
                -39.176307230224005,
                -17.726280810545468
              ],
              [
                -39.18456663006878,
                -17.733639778705644
              ],
              [
                -39.183083636815915,
                -17.738841776954636
              ],
              [
                -39.18272359956066,
                -17.74006625275467
              ],
              [
                -39.18206461291882,
                -17.74230971873237
              ],
              [
                -39.18009163468187,
                -17.749174187851537
              ],
              [
                -39.1992519608729,
                -17.762273662173996
              ],
              [
                -39.20246891649358,
                -17.764472652684137
              ],
              [
                -39.20462485536391,
                -17.76926261629978
              ],
              [
                -39.20487039102897,
                -17.769816594357213
              ],
              [
                -39.20744235206976,
                -17.775630565470504
              ],
              [
                -39.23509605689314,
                -17.80441546366114
              ],
              [
                -39.259887270382364,
                -17.83024084191904
              ],
              [
                -39.2642072155166,
                -17.839543790056247
              ],
              [
                -39.26562820246739,
                -17.84260975595218
              ],
              [
                -39.2677401971581,
                -17.84716374203915
              ],
              [
                -39.27012863613757,
                -17.852314708996897
              ],
              [
                -39.271267636720175,
                -17.855329206686697
              ],
              [
                -39.268891158572565,
                -17.8580021758485
              ],
              [
                -39.26376118679341,
                -17.86377211986654
              ],
              [
                -39.27076961572895,
                -17.87113112003152
              ],
              [
                -39.29067041827955,
                -17.879757563780394
              ],
              [
                -39.32545857914708,
                -17.89472306697206
              ],
              [
                -39.33701848855397,
                -17.89658505944323
              ],
              [
                -39.34129642461824,
                -17.894424091730862
              ],
              [
                -39.342560910587295,
                -17.8937856000215
              ],
              [
                -39.35541329774274,
                -17.898398562147563
              ],
              [
                -39.363457714137546,
                -17.903935055560027
              ],
              [
                -39.368396155811595,
                -17.907334039156513
              ],
              [
                -39.372441613524416,
                -17.908299058924047
              ],
              [
                -39.37706808290861,
                -17.909321041445764
              ],
              [
                -39.379924075983496,
                -17.909952570999547
              ],
              [
                -39.3928644274743,
                -17.915001038707317
              ],
              [
                -39.39727287237762,
                -17.916721022568705
              ],
              [
                -39.43837898195718,
                -17.941741445038634
              ],
              [
                -39.442742933292465,
                -17.944412965055573
              ],
              [
                -39.453902812091535,
                -17.9550894202514
              ],
              [
                -39.470489140428135,
                -17.970965851181894
              ],
              [
                -39.49225392314585,
                -17.997490204359494
              ],
              [
                -39.50208430030326,
                -18.01175515043858
              ],
              [
                -39.520801049172036,
                -18.040195495523008
              ],
              [
                -39.522130549940165,
                -18.042738510633995
              ],
              [
                -39.523461023696534,
                -18.045282494742676
              ],
              [
                -39.53288392533851,
                -18.063396906903012
              ],
              [
                -39.54049881241856,
                -18.078036295744802
              ],
              [
                -39.54325628259012,
                -18.08333726890606
              ],
              [
                -39.54943371730922,
                -18.09521271880816
              ],
              [
                -39.55169319443126,
                -18.090750769178655
              ],
              [
                -39.552368180180494,
                -18.089418265547675
              ],
              [
                -39.55459918465386,
                -18.084909786146895
              ],
              [
                -39.559129638248315,
                -18.086250802719487
              ],
              [
                -39.56719306619263,
                -18.089973299654034
              ],
              [
                -39.56076761714646,
                -18.08978829443435
              ],
              [
                -39.565188049008476,
                -18.094328276425333
              ],
              [
                -39.56306458341995,
                -18.107944691887766
              ],
              [
                -39.55943710599964,
                -18.10600516365922
              ],
              [
                -39.5588816196012,
                -18.105708176390014
              ],
              [
                -39.553670665969335,
                -18.102531709613366
              ],
              [
                -39.56399105159076,
                -18.11723063645136
              ],
              [
                -39.56697799044172,
                -18.12121609707347
              ],
              [
                -39.5675400118276,
                -18.12196610461542
              ],
              [
                -39.571651931439106,
                -18.127451048335182
              ],
              [
                -39.58542530617045,
                -18.14589599128943
              ],
              [
                -39.608079007114505,
                -18.18571727837349
              ],
              [
                -39.61856289816511,
                -18.20415467934109
              ],
              [
                -39.62314782700587,
                -18.212217656561176
              ],
              [
                -39.62414982829449,
                -18.213979638870747
              ],
              [
                -39.62672930456992,
                -18.218515126505245
              ],
              [
                -39.63556468379434,
                -18.234361549064932
              ],
              [
                -39.6365121647174,
                -18.240289496166692
              ],
              [
                -39.63702162649567,
                -18.244835484754073
              ],
              [
                -39.63747712177569,
                -18.24890093813625
              ],
              [
                -39.63936559121369,
                -18.253907935628877
              ],
              [
                -39.6409165729693,
                -18.2578344129819
              ],
              [
                -39.64502055309905,
                -18.268223360370673
              ],
              [
                -39.65159144680452,
                -18.28485775398182
              ],
              [
                -39.65265540453203,
                -18.28755126783751
              ],
              [
                -39.65240692501333,
                -18.289777234955988
              ],
              [
                -39.65179839983918,
                -18.29523020395334
              ],
              [
                -39.65964729113763,
                -18.317430088678027
              ],
              [
                -39.65773930268086,
                -18.323774042809422
              ],
              [
                -39.66119428584172,
                -18.33167250705432
              ],
              [
                -39.662769739022245,
                -18.335274980470423
              ],
              [
                -39.670192140698134,
                -18.348743440010594
              ],
              [
                -39.666460705268435,
                -18.33185303123857
              ],
              [
                -39.67382115656238,
                -18.32425806024744
              ],
              [
                -39.675954652662696,
                -18.323690593818466
              ],
              [
                -39.67666363971439,
                -18.323502084334063
              ],
              [
                -39.68853953408689,
                -18.32034462447007
              ],
              [
                -39.8276209545705,
                -18.23193689483236
              ],
              [
                -39.911767826119316,
                -18.17844833904563
              ],
              [
                -39.919896269060175,
                -18.172590407059023
              ],
              [
                -40.2220174780753,
                -17.97994758218094
              ],
              [
                -40.2264249708689,
                -17.95777819889495
              ],
              [
                -40.234096418795225,
                -17.954931240862955
              ],
              [
                -40.24176735373605,
                -17.95208327883525
              ],
              [
                -40.26168271411294,
                -17.935486410123538
              ],
              [
                -40.2640056932197,
                -17.931362956396246
              ],
              [
                -40.263081715238584,
                -17.92150699083261
              ],
              [
                -40.249719344504435,
                -17.914534007895927
              ],
              [
                -40.23302601199754,
                -17.90582255101381
              ],
              [
                -40.21234322228996,
                -17.895029072015255
              ],
              [
                -40.177294612244296,
                -17.85514322772505
              ],
              [
                -40.175997609849404,
                -17.853670266109045
              ],
              [
                -40.17470063045402,
                -17.852197257494197
              ],
              [
                -40.186011565307204,
                -17.832046377759184
              ],
              [
                -40.193279984722274,
                -17.828743926649
              ],
              [
                -40.195534478860374,
                -17.827720426155516
              ],
              [
                -40.20371143196213,
                -17.828359436023952
              ],
              [
                -40.20163446222547,
                -17.816984500628855
              ],
              [
                -40.201383451330315,
                -17.81561052043384
              ],
              [
                -40.20411945658718,
                -17.804848575609864
              ],
              [
                -40.21767736455177,
                -17.795178669983642
              ],
              [
                -40.21398543342958,
                -17.7764637728354
              ],
              [
                -40.215357414198834,
                -17.770749811958748
              ],
              [
                -40.21703640391255,
                -17.763756857395826
              ],
              [
                -40.216360429855456,
                -17.761865846517857
              ],
              [
                -40.21326247041931,
                -17.75319790892623
              ],
              [
                -40.22354341014898,
                -17.733587043071093
              ],
              [
                -40.23391133492312,
                -17.73325508092221
              ],
              [
                -40.23927429173873,
                -17.73308408622227
              ],
              [
                -40.248547699565336,
                -17.729715128324514
              ],
              [
                -40.25101770023137,
                -17.725610128845613
              ],
              [
                -40.25257369683021,
                -17.72302466443994
              ],
              [
                -40.25537115111492,
                -17.71962919230305
              ],
              [
                -40.26728503596076,
                -17.724705655454052
              ],
              [
                -40.270802005673566,
                -17.72620416514176
              ],
              [
                -40.27538196415906,
                -17.72425520368052
              ],
              [
                -40.27996243664293,
                -17.72230620222012
              ],
              [
                -40.28209095242489,
                -17.71432578347901
              ],
              [
                -40.28584693349126,
                -17.700245352698566
              ],
              [
                -40.292172874213705,
                -17.699404378881468
              ],
              [
                -40.295114852972624,
                -17.699013358872342
              ],
              [
                -40.29770381578434,
                -17.695777394368488
              ],
              [
                -40.298234865216955,
                -17.68322296721391
              ],
              [
                -40.2986043531229,
                -17.674485012711337
              ],
              [
                -40.302968316150505,
                -17.66983605514129
              ],
              [
                -40.307333315174375,
                -17.66518510158508
              ],
              [
                -40.30775979065438,
                -17.665062108143115
              ],
              [
                -40.32242770159819,
                -17.660835135708833
              ],
              [
                -40.325452657997815,
                -17.65209169431719
              ],
              [
                -40.32399768152996,
                -17.64888873989381
              ],
              [
                -40.32177471636958,
                -17.6439947452276
              ],
              [
                -40.327126160772316,
                -17.637847793597018
              ],
              [
                -40.333655131811845,
                -17.631674336373585
              ],
              [
                -40.34732854902491,
                -17.61423246189949
              ],
              [
                -40.34815451740341,
                -17.630287884425456
              ],
              [
                -40.3487089989526,
                -17.63151388407475
              ],
              [
                -40.35426193331498,
                -17.64379528747422
              ],
              [
                -40.361047898086916,
                -17.63619035737856
              ],
              [
                -40.370010835661894,
                -17.61555351018319
              ],
              [
                -40.37156385634567,
                -17.611978532751806
              ],
              [
                -40.375550781284225,
                -17.611402551839586
              ],
              [
                -40.37953877521529,
                -17.610826562929688
              ],
              [
                -40.381056261129814,
                -17.61211205905732
              ],
              [
                -40.39641358032726,
                -17.625123982775797
              ],
              [
                -40.40224957408534,
                -17.61484507100297
              ],
              [
                -40.404516051336245,
                -17.610854598433892
              ],
              [
                -40.40614455837941,
                -17.610643589817688
              ],
              [
                -40.414060448542,
                -17.60961812212617
              ],
              [
                -40.41736446160432,
                -17.596001701681693
              ],
              [
                -40.41561000294211,
                -17.585956774026563
              ],
              [
                -40.41172402098403,
                -17.588512726170023
              ],
              [
                -40.41161751178009,
                -17.588582754543687
              ],
              [
                -40.40940056349181,
                -17.58514876106853
              ],
              [
                -40.414124019366845,
                -17.575682799286113
              ],
              [
                -40.41028158775364,
                -17.56774888192729
              ],
              [
                -40.43168139206149,
                -17.56708040974385
              ],
              [
                -40.43947731829213,
                -17.57378889206637
              ],
              [
                -40.44429678212218,
                -17.57793586320403
              ],
              [
                -40.46025464935469,
                -17.56820543242272
              ],
              [
                -40.47083960051231,
                -17.542804095075606
              ],
              [
                -40.474798577227496,
                -17.533301677047092
              ],
              [
                -40.48182900635574,
                -17.528861224273466
              ],
              [
                -40.4858240183137,
                -17.526338221129674
              ],
              [
                -40.48791248380917,
                -17.52395577950601
              ],
              [
                -40.48866197379109,
                -17.519315276982713
              ],
              [
                -40.48601250482741,
                -17.512226307797597
              ],
              [
                -40.48348355112811,
                -17.505458847908894
              ],
              [
                -40.48260209087356,
                -17.4880359426205
              ],
              [
                -40.48355809171996,
                -17.487898970265462
              ],
              [
                -40.49031253311606,
                -17.486930985954647
              ],
              [
                -40.495043470772636,
                -17.486252988045422
              ],
              [
                -40.49642799154039,
                -17.481899021014346
              ],
              [
                -40.497458485132135,
                -17.478659035572882
              ],
              [
                -40.50405991416488,
                -17.47561906250335
              ],
              [
                -40.50511742384064,
                -17.475132065458215
              ],
              [
                -40.50641239540573,
                -17.47368308068377
              ],
              [
                -40.52054330436656,
                -17.457872720170275
              ],
              [
                -40.52025136449228,
                -17.446925777859327
              ],
              [
                -40.550952575148536,
                -17.43608537770672
              ],
              [
                -40.55372356874004,
                -17.435106905884574
              ],
              [
                -40.55835004470758,
                -17.429403923167744
              ],
              [
                -40.565725491428445,
                -17.420310988177327
              ],
              [
                -40.57536340903053,
                -17.414225559277238
              ],
              [
                -40.578318906262716,
                -17.413608090617714
              ],
              [
                -40.58239587121095,
                -17.41513155713161
              ],
              [
                -40.59567421802792,
                -17.42009407230366
              ],
              [
                -40.60746864734945,
                -17.41756858896056
              ],
              [
                -40.62307852773935,
                -17.405502711548007
              ],
              [
                -40.610441165130936,
                -17.397182217906355
              ],
              [
                -40.59934073890766,
                -17.389873234093933
              ],
              [
                -40.60122375538777,
                -17.382056289973153
              ],
              [
                -40.59731227241518,
                -17.37641631170883
              ],
              [
                -40.59857881267958,
                -17.364032918087972
              ],
              [
                -40.60345177994305,
                -17.359563441373552
              ],
              [
                -40.60455226732472,
                -17.35855394656883
              ],
              [
                -40.60259376402388,
                -17.35638146502166
              ],
              [
                -40.59232988572925,
                -17.344997485581732
              ],
              [
                -40.59871436167297,
                -17.34199253485352
              ],
              [
                -40.602342304231996,
                -17.340284538057627
              ],
              [
                -40.60860179641328,
                -17.32647313557348
              ],
              [
                -40.6044123255595,
                -17.322921667187845
              ],
              [
                -40.59452543025369,
                -17.32308863951291
              ],
              [
                -40.590118481706334,
                -17.312499701401517
              ],
              [
                -40.58408302759802,
                -17.31115171119436
              ],
              [
                -40.58182006455635,
                -17.310645691996378
              ],
              [
                -40.577658114147575,
                -17.303819221560857
              ],
              [
                -40.57184717777004,
                -17.294288777442045
              ],
              [
                -40.567368725716385,
                -17.292077289430356
              ],
              [
                -40.55595631670762,
                -17.28644180615846
              ],
              [
                -40.55479185113384,
                -17.28586731945525
              ],
              [
                -40.54735290602658,
                -17.2832288014815
              ],
              [
                -40.56157681959116,
                -17.272028904252668
              ],
              [
                -40.56316982270303,
                -17.257720499157852
              ],
              [
                -40.56455382073474,
                -17.25663149037433
              ],
              [
                -40.570767753981144,
                -17.251742030754077
              ],
              [
                -40.56951276860083,
                -17.241137587828888
              ],
              [
                -40.565090820235305,
                -17.239805610621165
              ],
              [
                -40.564068359862425,
                -17.239497599573617
              ],
              [
                -40.55799840819431,
                -17.23019965370082
              ],
              [
                -40.56152140038016,
                -17.21138525471263
              ],
              [
                -40.55201100180478,
                -17.198707806017964
              ],
              [
                -40.55346350159147,
                -17.188554901521798
              ],
              [
                -40.565588943167,
                -17.183472420144877
              ],
              [
                -40.574213343595375,
                -17.179857462283156
              ],
              [
                -40.57830486438676,
                -17.148330182847502
              ],
              [
                -40.57042395338399,
                -17.137111718456126
              ],
              [
                -40.56254357835715,
                -17.125893781102057
              ],
              [
                -40.557440135672955,
                -17.095417944393258
              ],
              [
                -40.55638768659082,
                -17.089128011800717
              ],
              [
                -40.56102616321758,
                -17.08195553318736
              ],
              [
                -40.56590260970044,
                -17.080774568464875
              ],
              [
                -40.569515596684084,
                -17.079899070528185
              ],
              [
                -40.569628604818135,
                -17.061430200425345
              ],
              [
                -40.55588377451939,
                -17.02812487326531
              ],
              [
                -40.552181848042615,
                -17.0191554072042
              ],
              [
                -40.55295734263589,
                -17.01395446242477
              ],
              [
                -40.55373283722812,
                -17.00875246665487
              ],
              [
                -40.54508196714101,
                -16.983822589071988
              ],
              [
                -40.53984300469881,
                -16.98207608406703
              ],
              [
                -40.534605052247535,
                -16.98033062306115
              ],
              [
                -40.51587624966627,
                -16.9629371839183
              ],
              [
                -40.50429890654124,
                -16.928754352966408
              ],
              [
                -40.49229756998397,
                -16.90363751587888
              ],
              [
                -40.49199008972077,
                -16.899588538150184
              ],
              [
                -40.49080709999478,
                -16.88401712651574
              ],
              [
                -40.47959570678844,
                -16.876350663987683
              ],
              [
                -40.45948888794284,
                -16.87823411052736
              ],
              [
                -40.4562089155453,
                -16.879879570405
              ],
              [
                -40.45370395709946,
                -16.881136084092798
              ],
              [
                -40.44785947665515,
                -16.891679500624225
              ],
              [
                -40.43659959371644,
                -16.892707967642373
              ],
              [
                -40.43257711936931,
                -16.887531025057367
              ],
              [
                -40.417225723247995,
                -16.898775915821076
              ],
              [
                -40.40716232395685,
                -16.898218399781808
              ],
              [
                -40.405808838579716,
                -16.896346427799855
              ],
              [
                -40.403124855560435,
                -16.89263342669123
              ],
              [
                -40.401361897908004,
                -16.890194963443005
              ],
              [
                -40.39628295153901,
                -16.887713938379985
              ],
              [
                -40.39114099195165,
                -16.887141923471617
              ],
              [
                -40.375502620128906,
                -16.88539992652765
              ],
              [
                -40.37273116276883,
                -16.878704445624557
              ],
              [
                -40.37096219579077,
                -16.87443048667238
              ],
              [
                -40.366089233163706,
                -16.873696479268204
              ],
              [
                -40.3479028649892,
                -16.88757188062573
              ],
              [
                -40.3480608497644,
                -16.891536849526602
              ],
              [
                -40.34835084678945,
                -16.89883329918356
              ],
              [
                -40.339487940517536,
                -16.898011779000704
              ],
              [
                -40.32668348892312,
                -16.908864693793188
              ],
              [
                -40.32253656093685,
                -16.903573721741694
              ],
              [
                -40.31647312336789,
                -16.904596700344086
              ],
              [
                -40.30732969534562,
                -16.89631073559065
              ],
              [
                -40.30235374211844,
                -16.891801777238193
              ],
              [
                -40.29981027786822,
                -16.895184227764876
              ],
              [
                -40.29365228800062,
                -16.903373168093992
              ],
              [
                -40.28124793965272,
                -16.900710658785954
              ],
              [
                -40.275300979230586,
                -16.886960244582355
              ],
              [
                -40.2733015450856,
                -16.877716310869456
              ],
              [
                -40.27157356306748,
                -16.86972484695082
              ],
              [
                -40.264541132265926,
                -16.864556869962854
              ],
              [
                -40.265629112952944,
                -16.85983989302171
              ],
              [
                -40.26072117342154,
                -16.859747916635133
              ],
              [
                -40.25171875115984,
                -16.859579885250763
              ],
              [
                -40.25142975437885,
                -16.848558947681358
              ],
              [
                -40.24650930125019,
                -16.84526745901499
              ],
              [
                -40.25101431003303,
                -16.838385030674953
              ],
              [
                -40.25312978827861,
                -16.82417161621599
              ],
              [
                -40.25636327180089,
                -16.821603620946686
              ],
              [
                -40.25807325038532,
                -16.820245647438025
              ],
              [
                -40.257856765325016,
                -16.815642156451165
              ],
              [
                -40.257407307057434,
                -16.80606970671316
              ],
              [
                -40.26967869483084,
                -16.794857288234613
              ],
              [
                -40.279588625299546,
                -16.800045804229935
              ],
              [
                -40.28415257738322,
                -16.80243528178688
              ],
              [
                -40.293411982050316,
                -16.796681340133365
              ],
              [
                -40.29423148782644,
                -16.796171859767565
              ],
              [
                -40.29581946933404,
                -16.79642535309201
              ],
              [
                -40.323207245427575,
                -16.80079484598241
              ],
              [
                -40.330555172515,
                -16.79273139408268
              ],
              [
                -40.33080419585776,
                -16.792457910222428
              ],
              [
                -40.33508166338743,
                -16.787762975960565
              ],
              [
                -40.345099080053714,
                -16.786795462158327
              ],
              [
                -40.33544067293564,
                -16.779240015180157
              ],
              [
                -40.3197328657322,
                -16.745808667961608
              ],
              [
                -40.316070901218794,
                -16.742109193161838
              ],
              [
                -40.3097689701691,
                -16.735742244056574
              ],
              [
                -40.31008098324594,
                -16.72302582218536
              ],
              [
                -40.31018499154587,
                -16.718814832394727
              ],
              [
                -40.30715352623164,
                -16.71472135120193
              ],
              [
                -40.30195806967493,
                -16.707704390169525
              ],
              [
                -40.3034205827535,
                -16.70413241289924
              ],
              [
                -40.3066320447425,
                -16.696291460188974
              ],
              [
                -40.31065650809893,
                -16.69536696018562
              ],
              [
                -40.31228003411808,
                -16.67825160896377
              ],
              [
                -40.306464621916696,
                -16.667664655951206
              ],
              [
                -40.29788621779539,
                -16.652048751113146
              ],
              [
                -40.29167581650415,
                -16.62161292341031
              ],
              [
                -40.28343390558643,
                -16.604302992853107
              ],
              [
                -40.28500641649133,
                -16.601329038116504
              ],
              [
                -40.28724188098311,
                -16.597101061346404
              ],
              [
                -40.27511454424717,
                -16.57355668558182
              ],
              [
                -40.26074716509752,
                -16.57390512563789
              ],
              [
                -40.25457871853345,
                -16.56918264388218
              ],
              [
                -40.254293235469156,
                -16.568964164726303
              ],
              [
                -40.254083745095414,
                -16.569077662646066
              ],
              [
                -40.24663030701162,
                -16.573088646381912
              ],
              [
                -40.24562933064903,
                -16.56747665342465
              ],
              [
                -40.24545930098661,
                -16.56652218104539
              ],
              [
                -40.236427917005805,
                -16.563687684456752
              ],
              [
                -40.23408291301209,
                -16.558315700616163
              ],
              [
                -40.215055063262355,
                -16.570463106079266
              ],
              [
                -40.21164259113686,
                -16.5726415934338
              ],
              [
                -40.20083219233306,
                -16.574758537913883
              ],
              [
                -40.1991282119479,
                -16.57185355790722
              ],
              [
                -40.197936721362254,
                -16.569822079393834
              ],
              [
                -40.18978927288787,
                -16.57049205563928
              ],
              [
                -40.18900480276514,
                -16.57103106988524
              ],
              [
                -40.17351743083397,
                -16.581660450098823
              ],
              [
                -40.1715369299393,
                -16.57717296843749
              ],
              [
                -40.15945755932708,
                -16.579836961250987
              ],
              [
                -40.16059203138351,
                -16.571493013147606
              ],
              [
                -40.15548561103316,
                -16.561813556193627
              ],
              [
                -40.16095605694944,
                -16.55809208111867
              ],
              [
                -40.16166708936922,
                -16.552531128972298
              ],
              [
                -40.161168619532134,
                -16.543803669364372
              ],
              [
                -40.1656040560659,
                -16.54086719255555
              ],
              [
                -40.16906903492259,
                -16.538573723012522
              ],
              [
                -40.17444798471545,
                -16.53742223478532
              ],
              [
                -40.169820024809155,
                -16.533101269396667
              ],
              [
                -40.17081803709252,
                -16.52367932179928
              ],
              [
                -40.16744957859124,
                -16.52065282162003
              ],
              [
                -40.161771621974076,
                -16.52308880332705
              ],
              [
                -40.16157062058113,
                -16.523174803433122
              ],
              [
                -40.16135165570735,
                -16.522551291922753
              ],
              [
                -40.15809219321874,
                -16.513279356819275
              ],
              [
                -40.13990135641801,
                -16.495096453633046
              ],
              [
                -40.12031856951281,
                -16.466094103563314
              ],
              [
                -40.119394104048524,
                -16.465893112173948
              ],
              [
                -40.115377609135656,
                -16.465019101488217
              ],
              [
                -40.11369817566581,
                -16.45730261963464
              ],
              [
                -40.112671669709066,
                -16.45258715222843
              ],
              [
                -40.10290530540935,
                -16.436538252051477
              ],
              [
                -40.09757785773026,
                -16.422169826316207
              ],
              [
                -40.09188090711324,
                -16.434186737187648
              ],
              [
                -40.09277789447892,
                -16.437193704004475
              ],
              [
                -40.09597180292677,
                -16.447897180857318
              ],
              [
                -40.08796338835053,
                -16.45168461301172
              ],
              [
                -40.08358692652206,
                -16.463457026776517
              ],
              [
                -40.06792956118504,
                -16.45793756244125
              ],
              [
                -40.061122645165575,
                -16.439595142908576
              ],
              [
                -40.061453661543716,
                -16.43733516461097
              ],
              [
                -40.0623061610886,
                -16.431515714472535
              ],
              [
                -40.05672219367888,
                -16.431347210637366
              ],
              [
                -40.05212525950666,
                -16.4312081923669
              ],
              [
                -40.04064938640127,
                -16.411397310884066
              ],
              [
                -40.041181362150375,
                -16.407474341352312
              ],
              [
                -40.041488896735316,
                -16.40520833206469
              ],
              [
                -40.036405425646564,
                -16.397797893426123
              ],
              [
                -40.03210449748456,
                -16.391527404054425
              ],
              [
                -40.02824254501558,
                -16.373860529787844
              ],
              [
                -40.02443260159338,
                -16.35643111818578
              ],
              [
                -40.02082914553395,
                -16.35212111282969
              ],
              [
                -40.01704769044594,
                -16.34759765450381
              ],
              [
                -40.011085758448516,
                -16.34580216125814
              ],
              [
                -40.01077777521003,
                -16.33995068537618
              ],
              [
                -40.010759755018114,
                -16.339606698499754
              ],
              [
                -39.99603291071046,
                -16.330740717167703
              ],
              [
                -39.99035396929663,
                -16.312976345574178
              ],
              [
                -39.978053582195905,
                -16.31067633341218
              ],
              [
                -39.9790110880437,
                -16.316361778429698
              ],
              [
                -39.97606661225366,
                -16.3173087713213
              ],
              [
                -39.971879151685144,
                -16.31865576252195
              ],
              [
                -39.968271137586854,
                -16.32659720837681
              ],
              [
                -39.9601177503054,
                -16.321429729477103
              ],
              [
                -39.952651340056605,
                -16.299721345611545
              ],
              [
                -39.951857833397426,
                -16.29741435970329
              ],
              [
                -39.943126422458924,
                -16.291576882059477
              ],
              [
                -39.94227195034985,
                -16.291925393374562
              ],
              [
                -39.938844941984094,
                -16.293325385581504
              ],
              [
                -39.936768485355714,
                -16.290110906132636
              ],
              [
                -39.93226004826604,
                -16.28313192007709
              ],
              [
                -39.91628866687558,
                -16.283372387622535
              ],
              [
                -39.919328167546404,
                -16.265488516330425
              ],
              [
                -39.919705666370255,
                -16.26326850294491
              ],
              [
                -39.92285065249988,
                -16.261183555546125
              ],
              [
                -39.92814560731516,
                -16.257672572867385
              ],
              [
                -39.9354885770705,
                -16.244533654290542
              ],
              [
                -39.91184382333616,
                -16.21590932711047
              ],
              [
                -39.91223332308795,
                -16.20991133654065
              ],
              [
                -39.90178493485634,
                -16.208033846167584
              ],
              [
                -39.89800546853663,
                -16.200897876521903
              ],
              [
                -39.89407752748092,
                -16.19915138168698
              ],
              [
                -39.88299463854496,
                -16.194224394447147
              ],
              [
                -39.88254018006276,
                -16.16330910748664
              ],
              [
                -39.88584117399848,
                -16.15357165560621
              ],
              [
                -39.88687965851706,
                -16.150507685727145
              ],
              [
                -39.89329258599735,
                -16.149911709606958
              ],
              [
                -39.89656058844015,
                -16.141132240632864
              ],
              [
                -39.89003416098266,
                -16.137941778441274
              ],
              [
                -39.88231971195989,
                -16.14394720919397
              ],
              [
                -39.86834586664107,
                -16.131394767175383
              ],
              [
                -39.860402415017795,
                -16.12425829646602
              ],
              [
                -39.858418967622995,
                -16.118810358425613
              ],
              [
                -39.85643447623599,
                -16.113360851399978
              ],
              [
                -39.864391957406156,
                -16.10338896112321
              ],
              [
                -39.8662104206245,
                -16.09512651144487
              ],
              [
                -39.87030140828851,
                -16.093618503036446
              ],
              [
                -39.877012330609496,
                -16.091144047257266
              ],
              [
                -39.87659186383166,
                -16.08329158812543
              ],
              [
                -39.87635935891139,
                -16.07894562417604
              ],
              [
                -39.882745821615934,
                -16.066983677767844
              ],
              [
                -39.88885778839144,
                -16.063881746910546
              ],
              [
                -39.89979270712327,
                -16.058332783831904
              ],
              [
                -39.89905821040697,
                -16.049223329120558
              ],
              [
                -39.89905119962646,
                -16.04913785064859
              ],
              [
                -39.89925121320169,
                -16.048953331159414
              ],
              [
                -39.92180405354543,
                -16.02815948643006
              ],
              [
                -39.931503478379426,
                -16.026863507331594
              ],
              [
                -39.935290450585,
                -16.023278039512405
              ],
              [
                -39.925535063556616,
                -16.01299511070072
              ],
              [
                -39.92532105886,
                -16.012769125760883
              ],
              [
                -39.92531557391852,
                -16.01276362678621
              ],
              [
                -39.9227155618952,
                -16.01002211464204
              ],
              [
                -39.91647415304766,
                -16.003442646504336
              ],
              [
                -39.91571664379333,
                -16.000144157060873
              ],
              [
                -39.92383260308669,
                -15.994771207996187
              ],
              [
                -39.94699538198443,
                -15.991971259535655
              ],
              [
                -39.94758739689631,
                -15.991899762006016
              ],
              [
                -39.95374584197018,
                -15.991155788303143
              ],
              [
                -39.96273924765008,
                -15.997157753843139
              ],
              [
                -39.96311374590481,
                -16.00065975235722
              ],
              [
                -39.96356273722367,
                -16.004855709616802
              ],
              [
                -39.9798655980863,
                -16.005093244197734
              ],
              [
                -39.97964158858988,
                -15.99828827080071
              ],
              [
                -39.97960510796184,
                -15.997174282775918
              ],
              [
                -39.99085948832562,
                -15.994706295748653
              ],
              [
                -39.99092952273691,
                -15.994690838966891
              ],
              [
                -39.99165448664141,
                -15.994531837219846
              ],
              [
                -39.99187348698362,
                -15.994927332098683
              ],
              [
                -39.991892491753624,
                -15.994961303916845
              ],
              [
                -39.993220474629744,
                -15.997363296031468
              ],
              [
                -39.99468248488021,
                -16.000006280856493
              ],
              [
                -40.00403887392678,
                -16.00137082036171
              ],
              [
                -40.00141690500965,
                -15.995951309069595
              ],
              [
                -40.000575442628126,
                -15.99421083661284
              ],
              [
                -40.004147383945885,
                -15.994325836039646
              ],
              [
                -40.00803884042895,
                -15.994452341944836
              ],
              [
                -40.00968835820087,
                -15.991028869407485
              ],
              [
                -40.01329634020161,
                -15.98354043491834
              ],
              [
                -40.02092824550653,
                -15.984339425015364
              ],
              [
                -40.02093525744381,
                -15.984339925024287
              ],
              [
                -40.022143253755026,
                -15.98979790463818
              ],
              [
                -40.02776519186194,
                -15.985114915894956
              ],
              [
                -40.02696622832566,
                -15.980536970428414
              ],
              [
                -40.026876699051876,
                -15.980027456491992
              ],
              [
                -40.02855171839595,
                -15.979431986137248
              ],
              [
                -40.0322956818757,
                -15.978099975996892
              ],
              [
                -40.0320531700522,
                -15.976991008582665
              ],
              [
                -40.03114119077172,
                -15.97281251240238
              ],
              [
                -40.04027161722404,
                -15.971262033914183
              ],
              [
                -40.04585357881989,
                -15.970314061511957
              ],
              [
                -40.04391708080078,
                -15.968721074237495
              ],
              [
                -40.04310910613819,
                -15.968056060046264
              ],
              [
                -40.04537709251395,
                -15.961888110905408
              ],
              [
                -40.052609034421806,
                -15.962662128464494
              ],
              [
                -40.06076792699237,
                -15.963535634994763
              ],
              [
                -40.06580090066797,
                -15.959743171606622
              ],
              [
                -40.06423042213122,
                -15.955045194569433
              ],
              [
                -40.05996145303717,
                -15.953233699663492
              ],
              [
                -40.058738504755,
                -15.952715202833994
              ],
              [
                -40.0606539452902,
                -15.951956204923503
              ],
              [
                -40.06581292264154,
                -15.949911193715765
              ],
              [
                -40.06576144265039,
                -15.942534767218042
              ],
              [
                -40.06573894979751,
                -15.93929627563674
              ],
              [
                -40.06800941348347,
                -15.938388269276714
              ],
              [
                -40.07029339006036,
                -15.937475286971234
              ],
              [
                -40.07843633892269,
                -15.94045830913355
              ],
              [
                -40.07651034997197,
                -15.933870813429664
              ],
              [
                -40.07062642731759,
                -15.933561339269076
              ],
              [
                -40.06845392864543,
                -15.933447335254726
              ],
              [
                -40.065949954084196,
                -15.924510894416722
              ],
              [
                -40.07126690712338,
                -15.92569436707346
              ],
              [
                -40.06565800432114,
                -15.909423972270227
              ],
              [
                -40.07193442257791,
                -15.91134145892356
              ],
              [
                -40.074453912292874,
                -15.919583437162528
              ],
              [
                -40.077207875686675,
                -15.921422418273425
              ],
              [
                -40.080515340533225,
                -15.923630397005958
              ],
              [
                -40.08330931515873,
                -15.918612924507398
              ],
              [
                -40.086372292304254,
                -15.913111480529938
              ],
              [
                -40.08132837174428,
                -15.904349536241922
              ],
              [
                -40.084627333813295,
                -15.896660058507775
              ],
              [
                -40.09484224308983,
                -15.89931708024375
              ],
              [
                -40.09623671885839,
                -15.906934512501659
              ],
              [
                -40.10495112167201,
                -15.911588524067444
              ],
              [
                -40.11260408176378,
                -15.910826515030621
              ],
              [
                -40.11683406875218,
                -15.903094080141175
              ],
              [
                -40.127764456228235,
                -15.907134081404363
              ],
              [
                -40.13699088254078,
                -15.905809591626044
              ],
              [
                -40.14017787308061,
                -15.89842213975353
              ],
              [
                -40.14564933304736,
                -15.89893264593159
              ],
              [
                -40.148683250176774,
                -15.904491129050125
              ],
              [
                -40.14890575247288,
                -15.904898618860031
              ],
              [
                -40.14985977750981,
                -15.904123631392999
              ],
              [
                -40.15371424938279,
                -15.90099017780193
              ],
              [
                -40.15606969919463,
                -15.899075671937963
              ],
              [
                -40.159432160259186,
                -15.90799813839538
              ],
              [
                -40.16435111978739,
                -15.909762098723826
              ],
              [
                -40.16646362179442,
                -15.904551137245575
              ],
              [
                -40.17033609116685,
                -15.907053643112944
              ],
              [
                -40.174722567968374,
                -15.902509666335048
              ],
              [
                -40.16544663782626,
                -15.89360573658876
              ],
              [
                -40.16561615168344,
                -15.877059827357474
              ],
              [
                -40.16570969562523,
                -15.867911404301521
              ],
              [
                -40.16964067015102,
                -15.86896787737442
              ],
              [
                -40.17357212567286,
                -15.870024866446542
              ],
              [
                -40.18540602048717,
                -15.861961437684245
              ],
              [
                -40.192263963808855,
                -15.85728897196265
              ],
              [
                -40.19376095090332,
                -15.858248489471825
              ],
              [
                -40.19967540300936,
                -15.862041453672285
              ],
              [
                -40.198892927819585,
                -15.857163003134245
              ],
              [
                -40.20646934204237,
                -15.854020043984102
              ],
              [
                -40.20571387695182,
                -15.847863076569352
              ],
              [
                -40.2054418513966,
                -15.8456475750951
              ],
              [
                -40.20840085137687,
                -15.844029083392716
              ],
              [
                -40.21240983418982,
                -15.841836603116116
              ],
              [
                -40.21251882797427,
                -15.83815315456743
              ],
              [
                -40.212603327459135,
                -15.835296650755105
              ],
              [
                -40.214517819640406,
                -15.835302175002663
              ],
              [
                -40.21843577822494,
                -15.835312148657653
              ],
              [
                -40.21811280646297,
                -15.830172696566507
              ],
              [
                -40.21785831708576,
                -15.826132724651039
              ],
              [
                -40.2207487772884,
                -15.825817235597642
              ],
              [
                -40.222871748076486,
                -15.825585241701628
              ],
              [
                -40.222288259805694,
                -15.822518733073277
              ],
              [
                -40.222318770011384,
                -15.822259745761649
              ],
              [
                -40.2224737510422,
                -15.820951772290382
              ],
              [
                -40.22286428048159,
                -15.821024278501433
              ],
              [
                -40.22410575916077,
                -15.821255741166372
              ],
              [
                -40.22731473310302,
                -15.817913781777186
              ],
              [
                -40.23025421820872,
                -15.804282889935937
              ],
              [
                -40.230468740138534,
                -15.803290390575
              ],
              [
                -40.230680238231194,
                -15.803318390760097
              ],
              [
                -40.23535666904797,
                -15.803943873813685
              ],
              [
                -40.25529699392039,
                -15.810047864408201
              ],
              [
                -40.262197924466896,
                -15.808356887912982
              ],
              [
                -40.26260343806228,
                -15.81264838250776
              ],
              [
                -40.26851835784942,
                -15.812277895986659
              ],
              [
                -40.273657817201254,
                -15.816958858246489
              ],
              [
                -40.27753729995907,
                -15.812702387769937
              ],
              [
                -40.28224727509981,
                -15.807534935468013
              ],
              [
                -40.292041153081485,
                -15.814707407990218
              ],
              [
                -40.294014162134445,
                -15.81615191325901
              ],
              [
                -40.29993262588204,
                -15.815797407651422
              ],
              [
                -40.31115350172334,
                -15.815125417148286
              ],
              [
                -40.31424997849234,
                -15.8168084438455
              ],
              [
                -40.317828940549894,
                -15.818754442714305
              ],
              [
                -40.327153378589806,
                -15.817780940865973
              ],
              [
                -40.336302754005835,
                -15.823446430855215
              ],
              [
                -40.33851879005878,
                -15.817185445141266
              ],
              [
                -40.338833745930145,
                -15.816294984297464
              ],
              [
                -40.34668720513301,
                -15.81682296245877
              ],
              [
                -40.35632561650104,
                -15.812550526954086
              ],
              [
                -40.36598503872755,
                -15.816989019572658
              ],
              [
                -40.371100991555124,
                -15.813908017784923
              ],
              [
                -40.372916948966235,
                -15.817005035387037
              ],
              [
                -40.37646390679412,
                -15.823054465364315
              ],
              [
                -40.38098487544647,
                -15.815828549671075
              ],
              [
                -40.38211040288685,
                -15.814029555942716
              ],
              [
                -40.38436435886699,
                -15.8152705309977
              ],
              [
                -40.391882780416296,
                -15.81941052684256
              ],
              [
                -40.39693777818461,
                -15.81339310144671
              ],
              [
                -40.39996973691301,
                -15.81269459606131
              ],
              [
                -40.401021231149464,
                -15.812452099397264
              ],
              [
                -40.4010347279093,
                -15.812518607001454
              ],
              [
                -40.40177172663627,
                -15.816234565859858
              ],
              [
                -40.41418710655825,
                -15.806076669200992
              ],
              [
                -40.41834860065691,
                -15.807965639458812
              ],
              [
                -40.42463953657692,
                -15.805242667429575
              ],
              [
                -40.43210401087828,
                -15.778163338858223
              ],
              [
                -40.45239286142294,
                -15.759542976030763
              ],
              [
                -40.456312350858624,
                -15.756413019482089
              ],
              [
                -40.45631981580609,
                -15.756406046538858
              ],
              [
                -40.46024679718883,
                -15.75327006104042
              ],
              [
                -40.46734821902501,
                -15.76039603132644
              ],
              [
                -40.4814650995886,
                -15.774563441342979
              ],
              [
                -40.4887520222005,
                -15.773284453922345
              ],
              [
                -40.49172202368621,
                -15.769214998650359
              ],
              [
                -40.49448048749028,
                -15.765435508189183
              ],
              [
                -40.50834785738355,
                -15.76753603680065
              ],
              [
                -40.51873328398181,
                -15.780401957693293
              ],
              [
                -40.51844975321988,
                -15.785979953110347
              ],
              [
                -40.51788623181695,
                -15.79704687052319
              ],
              [
                -40.530778642093026,
                -15.795419886968519
              ],
              [
                -40.53504010182782,
                -15.794882909688477
              ],
              [
                -40.53940906565097,
                -15.794331901682238
              ],
              [
                -40.53974456629746,
                -15.794560902820475
              ],
              [
                -40.55246841109287,
                -15.80325737808729
              ],
              [
                -40.56165735959628,
                -15.802818397696962
              ],
              [
                -40.57273228380276,
                -15.787610498374779
              ],
              [
                -40.57099080083132,
                -15.783904548667179
              ],
              [
                -40.56702333501133,
                -15.775460562917036
              ],
              [
                -40.58096624211941,
                -15.77278561975251
              ],
              [
                -40.59268361590351,
                -15.770538124067622
              ],
              [
                -40.59222115989623,
                -15.76185269586968
              ],
              [
                -40.60355356848342,
                -15.758830245385665
              ],
              [
                -40.600768078704355,
                -15.750714800610396
              ],
              [
                -40.599834115851756,
                -15.74799429210907
              ],
              [
                -40.60620006172014,
                -15.736298365610988
              ],
              [
                -40.609513049126996,
                -15.735572390874568
              ],
              [
                -40.61377750361134,
                -15.73463790108587
              ],
              [
                -40.62899791729139,
                -15.715249561187552
              ],
              [
                -40.632074883073116,
                -15.717086559922429
              ],
              [
                -40.633020885783246,
                -15.71765103699861
              ],
              [
                -40.63562384789661,
                -15.725383003854455
              ],
              [
                -40.63711530175695,
                -15.729813451565496
              ],
              [
                -40.63748828763305,
                -15.729741948656692
              ],
              [
                -40.64455274846108,
                -15.728391998297225
              ],
              [
                -40.66080563548674,
                -15.70666463284227
              ],
              [
                -40.66766106451141,
                -15.704031678180007
              ],
              [
                -40.68191497422787,
                -15.698555715106473
              ],
              [
                -40.68150349022423,
                -15.69396475826114
              ],
              [
                -40.68526348090389,
                -15.688247796665241
              ],
              [
                -40.696865881027406,
                -15.670604419531772
              ],
              [
                -40.698961884223706,
                -15.668160961495662
              ],
              [
                -40.70681681480515,
                -15.665945493909591
              ],
              [
                -40.72004218249585,
                -15.672528446229533
              ],
              [
                -40.7317900667798,
                -15.674353472941
              ],
              [
                -40.743136978726795,
                -15.684281395068115
              ],
              [
                -40.7426229511515,
                -15.685947908715406
              ],
              [
                -40.74261496117256,
                -15.685974386535333
              ],
              [
                -40.74006047523657,
                -15.69426284107938
              ],
              [
                -40.73815601049587,
                -15.70044378798287
              ],
              [
                -40.74863337585833,
                -15.706624263215682
              ],
              [
                -40.76247327097059,
                -15.712237283828742
              ],
              [
                -40.767277687591246,
                -15.713611764484707
              ],
              [
                -40.76945321388995,
                -15.711842799335363
              ],
              [
                -40.779724610765,
                -15.703490845448227
              ],
              [
                -40.785548114487256,
                -15.6918134207651
              ],
              [
                -40.78178265018846,
                -15.683686474294825
              ],
              [
                -40.77994214050178,
                -15.679713502070312
              ],
              [
                -40.785600596987464,
                -15.673844055658309
              ],
              [
                -40.791259092475755,
                -15.667974084248778
              ],
              [
                -40.81524788721384,
                -15.648014237825167
              ],
              [
                -40.82891076371007,
                -15.647798782664898
              ],
              [
                -40.83196424440643,
                -15.65365876113118
              ],
              [
                -40.83106123386342,
                -15.67024165251017
              ],
              [
                -40.83106024383256,
                -15.670263147373651
              ],
              [
                -40.831166215808636,
                -15.670318620208029
              ],
              [
                -40.83916415157287,
                -15.674482123861356
              ],
              [
                -40.83980415207247,
                -15.676624130527564
              ],
              [
                -40.84163810284327,
                -15.682763058184024
              ],
              [
                -40.848438076196615,
                -15.680846090926185
              ],
              [
                -40.85506048583445,
                -15.68669505066883
              ],
              [
                -40.88116377931815,
                -15.694010063859942
              ],
              [
                -40.88895621684943,
                -15.691128091369173
              ],
              [
                -40.89163766935467,
                -15.690136624209947
              ],
              [
                -40.893229152169326,
                -15.69032460977967
              ],
              [
                -40.902176586425696,
                -15.691380110616578
              ],
              [
                -40.90803307678352,
                -15.676006719033989
              ],
              [
                -40.913920534997516,
                -15.660554322015527
              ],
              [
                -40.92614245188295,
                -15.655592858177966
              ],
              [
                -40.92822091291422,
                -15.658263850025042
              ],
              [
                -40.93002340399417,
                -15.660580356619448
              ],
              [
                -40.932831849668545,
                -15.658359371377909
              ],
              [
                -40.93558683173115,
                -15.656180374780734
              ],
              [
                -40.948823261468995,
                -15.655773903155557
              ],
              [
                -40.961884150505036,
                -15.64842696570157
              ],
              [
                -40.973027521566586,
                -15.665913870435396
              ],
              [
                -40.97718592116832,
                -15.682904289287894
              ],
              [
                -40.979216422241876,
                -15.691201231891489
              ],
              [
                -40.982149388235435,
                -15.69203173377046
              ],
              [
                -40.985082345229756,
                -15.69286221965053
              ],
              [
                -41.00044122158993,
                -15.70817215151362
              ],
              [
                -41.003904674136415,
                -15.711624664935405
              ],
              [
                -41.005346168001296,
                -15.712500150962008
              ],
              [
                -41.00678762686643,
                -15.713375655988981
              ],
              [
                -41.01059658979561,
                -15.713373135607442
              ],
              [
                -41.03587938132788,
                -15.713354182567265
              ],
              [
                -41.0444493134983,
                -15.719000666171212
              ],
              [
                -41.05301973666366,
                -15.724647651790692
              ],
              [
                -41.060624673057355,
                -15.722814653439325
              ],
              [
                -41.07716253094042,
                -15.718827204048573
              ],
              [
                -41.0848294244707,
                -15.72852167587557
              ],
              [
                -41.09249785597191,
                -15.738218098724678
              ],
              [
                -41.11928211815214,
                -15.742264133108101
              ],
              [
                -41.121162129321675,
                -15.742548134610981
              ],
              [
                -41.124702555133005,
                -15.744426630067641
              ],
              [
                -41.12824304894505,
                -15.746304624529923
              ],
              [
                -41.12879852626562,
                -15.74784613089147
              ],
              [
                -41.135223437411945,
                -15.76567802900023
              ],
              [
                -41.14384686506035,
                -15.771364515632833
              ],
              [
                -41.15028080266632,
                -15.76578204361902
              ],
              [
                -41.155891785080776,
                -15.760914090718869
              ],
              [
                -41.1674101796319,
                -15.760166093471268
              ],
              [
                -41.16906116452261,
                -15.760058588021217
              ],
              [
                -41.19815694131315,
                -15.748820228743227
              ],
              [
                -41.2065188760658,
                -15.742805250768077
              ],
              [
                -41.21488181981616,
                -15.736790827788347
              ],
              [
                -41.23699611983555,
                -15.737050362754681
              ],
              [
                -41.245883533226475,
                -15.74224334996511
              ],
              [
                -41.25310744682871,
                -15.746464810327714
              ],
              [
                -41.268168838148945,
                -15.748241831593702
              ],
              [
                -41.28028923863388,
                -15.741061389407959
              ],
              [
                -41.281560714035066,
                -15.740307902313258
              ],
              [
                -41.29255414019975,
                -15.73854441848347
              ],
              [
                -41.3043390270002,
                -15.742969427584821
              ],
              [
                -41.32440836209947,
                -15.741527956635153
              ],
              [
                -41.32728231503875,
                -15.741102472304604
              ],
              [
                -41.330808810743235,
                -15.744211953161939
              ],
              [
                -41.33122428562275,
                -15.740205495770676
              ],
              [
                -41.34191687208957,
                -15.636929632528723
              ],
              [
                -41.35579097466051,
                -15.50212101681033
              ],
              [
                -41.35602646482683,
                -15.499830987510558
              ],
              [
                -41.35808449049629,
                -15.49795550876564
              ],
              [
                -41.36217193003829,
                -15.49422905907294
              ],
              [
                -41.463532722987765,
                -15.401699295170824
              ],
              [
                -41.632367510667954,
                -15.250203997534289
              ],
              [
                -41.788227414010294,
                -15.11140558425237
              ],
              [
                -41.80031380882902,
                -15.100641668048192
              ],
              [
                -41.80898025659372,
                -15.100641674762501
              ],
              [
                -41.815945206814575,
                -15.107934170028722
              ],
              [
                -41.8461464222834,
                -15.115102638604803
              ],
              [
                -41.85410983370051,
                -15.116992640354704
              ],
              [
                -41.87058170235262,
                -15.116079661083932
              ],
              [
                -41.87630814677409,
                -15.130247594445798
              ],
              [
                -41.8783266004787,
                -15.135242057735237
              ],
              [
                -41.89774342363533,
                -15.151657994513158
              ],
              [
                -41.9084688451302,
                -15.153721494966266
              ],
              [
                -41.90938781294823,
                -15.153898002435788
              ],
              [
                -41.91051379493244,
                -15.155213979159734
              ],
              [
                -41.9147222770223,
                -15.160131481714698
              ],
              [
                -41.91731375376944,
                -15.160176963859213
              ],
              [
                -41.92622967021391,
                -15.160333993117012
              ],
              [
                -41.92866912156847,
                -15.165178446119274
              ],
              [
                -41.93256410222945,
                -15.17291243062848
              ],
              [
                -41.95303493198394,
                -15.175209434360633
              ],
              [
                -41.97892969234275,
                -15.16708051126144
              ],
              [
                -41.97945318977885,
                -15.167129548852479
              ],
              [
                -41.98739511354833,
                -15.167871541833286
              ],
              [
                -41.992069594843144,
                -15.163328060096344
              ],
              [
                -41.99660254909846,
                -15.158922602257705
              ],
              [
                -42.002674011858794,
                -15.160221624570672
              ],
              [
                -42.03080774256551,
                -15.16624361531623
              ],
              [
                -42.07289988874611,
                -15.175246618570347
              ],
              [
                -42.08431477259041,
                -15.186072085964733
              ],
              [
                -42.09122670444632,
                -15.18615108034896
              ],
              [
                -42.095673198075886,
                -15.181989131816753
              ],
              [
                -42.09793768546166,
                -15.167865701367274
              ],
              [
                -42.106582107679415,
                -15.166225215387861
              ],
              [
                -42.11332659080334,
                -15.149912342226266
              ],
              [
                -42.115909570123584,
                -15.14366387445096
              ],
              [
                -42.12851849110776,
                -15.131256475102987
              ],
              [
                -42.13127998173951,
                -15.130604468883337
              ],
              [
                -42.14065187483518,
                -15.12839099968571
              ],
              [
                -42.14951134072084,
                -15.119893595622164
              ],
              [
                -42.15347680867262,
                -15.110106665182578
              ],
              [
                -42.153383323092974,
                -15.108109157426734
              ],
              [
                -42.152949855473935,
                -15.098906725838217
              ],
              [
                -42.16406875825857,
                -15.096940756071948
              ],
              [
                -42.172924175521125,
                -15.08549882028193
              ],
              [
                -42.17940315291554,
                -15.087372319727502
              ],
              [
                -42.181817094977845,
                -15.096401791791564
              ],
              [
                -42.182562562569316,
                -15.099189776759099
              ],
              [
                -42.19220301312027,
                -15.102664775696502
              ],
              [
                -42.20242340857225,
                -15.099619301101795
              ],
              [
                -42.215993284886935,
                -15.105179267853618
              ],
              [
                -42.22071623760911,
                -15.102453782855239
              ],
              [
                -42.2270327004327,
                -15.098808847289936
              ],
              [
                -42.24678702313345,
                -15.108766822405784
              ],
              [
                -42.249085489779645,
                -15.110848771442395
              ],
              [
                -42.26439783418642,
                -15.124719713769524
              ],
              [
                -42.26999730283286,
                -15.118505773885863
              ],
              [
                -42.27469629032616,
                -15.113291323262263
              ],
              [
                -42.27508679379216,
                -15.112857315621753
              ],
              [
                -42.28074120469008,
                -15.11559381237798
              ],
              [
                -42.28302321325935,
                -15.116698321455425
              ],
              [
                -42.29071612582938,
                -15.109536363032289
              ],
              [
                -42.292151129050794,
                -15.108199861775443
              ],
              [
                -42.29448263094315,
                -15.107248875669752
              ],
              [
                -42.315348970917206,
                -15.098736984218846
              ],
              [
                -42.323294408585156,
                -15.093104528742773
              ],
              [
                -42.326080395065766,
                -15.088059546770474
              ],
              [
                -42.33080384344507,
                -15.079506598851838
              ],
              [
                -42.336390287101125,
                -15.079971126557899
              ],
              [
                -42.35452362764478,
                -15.097725538766381
              ],
              [
                -42.37568400778832,
                -15.070794218803954
              ],
              [
                -42.385885899973225,
                -15.074166740439583
              ],
              [
                -42.396089802149234,
                -15.077539223092066
              ],
              [
                -42.42057760213527,
                -15.06545934088312
              ],
              [
                -42.42140260486972,
                -15.065052346817541
              ],
              [
                -42.431961010674605,
                -15.062601883106721
              ],
              [
                -42.44251944549287,
                -15.060151902392162
              ],
              [
                -42.44190697443146,
                -15.034980559062724
              ],
              [
                -42.439151999357115,
                -15.028621601694947
              ],
              [
                -42.436397073276424,
                -15.02226263233719
              ],
              [
                -42.47551125365751,
                -15.016586208483957
              ],
              [
                -42.49540461421224,
                -14.994367910035672
              ],
              [
                -42.51587945306708,
                -14.983077511944897
              ],
              [
                -42.5186209132432,
                -14.982847985057228
              ],
              [
                -42.52132490473335,
                -14.98262098908992
              ],
              [
                -42.52588688772362,
                -14.980049029810111
              ],
              [
                -42.5312343148619,
                -14.9770340536144
              ],
              [
                -42.531619322940536,
                -14.97574107527228
              ],
              [
                -42.534482832074154,
                -14.966129124640972
              ],
              [
                -42.553931677473145,
                -14.958840175000818
              ],
              [
                -42.55710712085647,
                -14.9603407071495
              ],
              [
                -42.56028312323477,
                -14.961841700298121
              ],
              [
                -42.57199003051981,
                -14.945568322981918
              ],
              [
                -42.5771215109591,
                -14.938435854877124
              ],
              [
                -42.57997450523339,
                -14.937642857658386
              ],
              [
                -42.58426945902209,
                -14.9364498903779
              ],
              [
                -42.58497095043644,
                -14.934594404055126
              ],
              [
                -42.58674694989571,
                -14.929892919175474
              ],
              [
                -42.60214433078975,
                -14.9242234835503
              ],
              [
                -42.60797874376676,
                -14.931215934270485
              ],
              [
                -42.61303320402992,
                -14.937273923451688
              ],
              [
                -42.62489057448301,
                -14.94401241907966
              ],
              [
                -42.63450352836545,
                -14.934493970356833
              ],
              [
                -42.65481836884745,
                -14.923802574178602
              ],
              [
                -42.65214791482293,
                -14.92027059444182
              ],
              [
                -42.656509379234095,
                -14.918444605183934
              ],
              [
                -42.657072879559685,
                -14.916734625716101
              ],
              [
                -42.65929736794183,
                -14.909987154224842
              ],
              [
                -42.66230734428996,
                -14.910095656697415
              ],
              [
                -42.66304782197138,
                -14.913999630833159
              ],
              [
                -42.66339929564803,
                -14.915853141978577
              ],
              [
                -42.66965677287588,
                -14.910794659937121
              ],
              [
                -42.67945168836667,
                -14.902876236613228
              ],
              [
                -42.68246365181564,
                -14.904584237204688
              ],
              [
                -42.68331768171977,
                -14.905068743670158
              ],
              [
                -42.692400609420076,
                -14.902034244943776
              ],
              [
                -42.69996054087844,
                -14.894087810962663
              ],
              [
                -42.69986903498393,
                -14.892796801783847
              ],
              [
                -42.69954805086713,
                -14.888272848192214
              ],
              [
                -42.705154007803316,
                -14.882006416491727
              ],
              [
                -42.71420495265616,
                -14.879604940785097
              ],
              [
                -42.71325745923775,
                -14.883717407754892
              ],
              [
                -42.713236456250876,
                -14.88380841015667
              ],
              [
                -42.72295784367123,
                -14.885159913410549
              ],
              [
                -42.725340375987535,
                -14.87882194563663
              ],
              [
                -42.735538773175044,
                -14.876972484478575
              ],
              [
                -42.739107766375014,
                -14.87179551955471
              ],
              [
                -42.739216744624194,
                -14.871701536321405
              ],
              [
                -42.74169574755054,
                -14.869560557780424
              ],
              [
                -42.74822618457773,
                -14.863919568777526
              ],
              [
                -42.75473666597125,
                -14.845968695799595
              ],
              [
                -42.790622885781595,
                -14.838904797638362
              ],
              [
                -42.83588652865052,
                -14.805764586480677
              ],
              [
                -42.838160035287345,
                -14.80198208371531
              ],
              [
                -42.84195050667837,
                -14.795673131138745
              ],
              [
                -42.84432098731265,
                -14.795328667302412
              ],
              [
                -42.849412458721254,
                -14.794588151545376
              ],
              [
                -42.860250895318416,
                -14.78015225115945
              ],
              [
                -42.87198677083699,
                -14.774909330511457
              ],
              [
                -42.87142080229816,
                -14.771184859553884
              ],
              [
                -42.86704832977277,
                -14.770278321717313
              ],
              [
                -42.87108781396481,
                -14.76569485890189
              ],
              [
                -42.87214879518658,
                -14.764490887143788
              ],
              [
                -42.87362179337871,
                -14.76526837584511
              ],
              [
                -42.87745227947097,
                -14.76729086286536
              ],
              [
                -42.885878207739104,
                -14.76659037552275
              ],
              [
                -42.895757121805744,
                -14.762533930377248
              ],
              [
                -42.89633709988275,
                -14.761441431110452
              ],
              [
                -42.89957207658454,
                -14.755343992268262
              ],
              [
                -42.90104006268598,
                -14.758425981730587
              ],
              [
                -42.90223158315481,
                -14.760928946300542
              ],
              [
                -42.90548401836535,
                -14.760592941907444
              ],
              [
                -42.90883801993198,
                -14.751787540985475
              ],
              [
                -42.9117949889672,
                -14.744023061964295
              ],
              [
                -42.91533399415863,
                -14.746256571184865
              ],
              [
                -42.916564968397914,
                -14.747034062475834
              ],
              [
                -42.91979545702847,
                -14.743785070030503
              ],
              [
                -42.9266498654911,
                -14.743689112273545
              ],
              [
                -42.92499491610672,
                -14.735506161993735
              ],
              [
                -42.92457292340346,
                -14.73341866916959
              ],
              [
                -42.934575833556075,
                -14.722622225840684
              ],
              [
                -42.937009829777274,
                -14.719995256198946
              ],
              [
                -42.93710131538448,
                -14.71922676010109
              ],
              [
                -42.93848033064393,
                -14.707577818403996
              ],
              [
                -42.9522902147606,
                -14.707951372524235
              ],
              [
                -42.976926550435614,
                -14.69793595617843
              ],
              [
                -42.98094249721582,
                -14.699857930123935
              ],
              [
                -42.98495895499155,
                -14.701780436069923
              ],
              [
                -43.002042842752886,
                -14.695962009984049
              ],
              [
                -43.00239883497735,
                -14.695840504338243
              ],
              [
                -43.0024647993448,
                -14.695883986181817
              ],
              [
                -43.01054575385628,
                -14.701211982016124
              ],
              [
                -43.0264096440559,
                -14.693091047064351
              ],
              [
                -43.026418142580965,
                -14.692200055576677
              ],
              [
                -43.02647264205237,
                -14.686657108874565
              ],
              [
                -43.037907042657615,
                -14.682357651810511
              ],
              [
                -43.04816794612743,
                -14.689787130392245
              ],
              [
                -43.05022544947524,
                -14.689990602631187
              ],
              [
                -43.05526637722903,
                -14.690489125117708
              ],
              [
                -43.05636987687345,
                -14.68942063558196
              ],
              [
                -43.06598230881141,
                -14.680108704337725
              ],
              [
                -43.07126474312051,
                -14.687541137472655
              ],
              [
                -43.071871745489155,
                -14.68839613623186
              ],
              [
                -43.07901119451592,
                -14.690074159016525
              ],
              [
                -43.08336962451029,
                -14.697544113378836
              ],
              [
                -43.09112411672467,
                -14.682863717048532
              ],
              [
                -43.0941215696053,
                -14.680605235063314
              ],
              [
                -43.102143007092465,
                -14.674561296946457
              ],
              [
                -43.10761647944737,
                -14.672717814606278
              ],
              [
                -43.10935447701987,
                -14.675974785483119
              ],
              [
                -43.11073396580264,
                -14.678561270885702
              ],
              [
                -43.115897404742114,
                -14.67840427662714
              ],
              [
                -43.12290186543402,
                -14.671442818428007
              ],
              [
                -43.12877132363293,
                -14.665608884350188
              ],
              [
                -43.13993122574364,
                -14.66574639645843
              ],
              [
                -43.15109311685251,
                -14.665883912574838
              ],
              [
                -43.15766208722868,
                -14.661711467438547
              ],
              [
                -43.1759459337,
                -14.650098070031563
              ],
              [
                -43.178520417803874,
                -14.656965519108766
              ],
              [
                -43.17963941807137,
                -14.659949008637934
              ],
              [
                -43.18802632597696,
                -14.654802541580668
              ],
              [
                -43.19648178139402,
                -14.64961260790393
              ],
              [
                -43.20438416925578,
                -14.655689555921462
              ],
              [
                -43.207263155150756,
                -14.65790455418227
              ],
              [
                -43.228541485948135,
                -14.66006408508366
              ],
              [
                -43.23228447696584,
                -14.658180571039074
              ],
              [
                -43.236026937988726,
                -14.656297600989683
              ],
              [
                -43.28014103811805,
                -14.665413122043223
              ],
              [
                -43.28244052631168,
                -14.67128108890286
              ],
              [
                -43.28329452524213,
                -14.67345957897327
              ],
              [
                -43.287919452554206,
                -14.675662072327283
              ],
              [
                -43.297736365405484,
                -14.675639077210228
              ],
              [
                -43.29911686405255,
                -14.674970084673832
              ],
              [
                -43.310822272277576,
                -14.669298122472899
              ],
              [
                -43.31926770181659,
                -14.677506582475946
              ],
              [
                -43.33009811227282,
                -14.679676612640879
              ],
              [
                -43.33193109034963,
                -14.683331083340946
              ],
              [
                -43.33387504609741,
                -14.68720658187766
              ],
              [
                -43.34609793365161,
                -14.695620031146944
              ],
              [
                -43.36395329800855,
                -14.698541048767106
              ],
              [
                -43.39862300078492,
                -14.709771013289895
              ],
              [
                -43.40364092171272,
                -14.714260026390793
              ],
              [
                -43.41428381750711,
                -14.72378046033953
              ],
              [
                -43.42036577007442,
                -14.72134696666944
              ],
              [
                -43.42490773740214,
                -14.71952949958578
              ],
              [
                -43.42826070134027,
                -14.721759514686623
              ],
              [
                -43.42776322796551,
                -14.725917958380556
              ],
              [
                -43.43448416681821,
                -14.72259349425965
              ],
              [
                -43.43423315379878,
                -14.725969996165036
              ],
              [
                -43.440069133598314,
                -14.72786295959862
              ],
              [
                -43.44542753663558,
                -14.736238940556973
              ],
              [
                -43.455817458531044,
                -14.73970642820098
              ],
              [
                -43.460864396345976,
                -14.74577691675798
              ],
              [
                -43.45899089618506,
                -14.750079858229281
              ],
              [
                -43.45584944432824,
                -14.757293821725025
              ],
              [
                -43.46243234752363,
                -14.76543676028605
              ],
              [
                -43.466655835534134,
                -14.764173299278584
              ],
              [
                -43.474868283393256,
                -14.761716825428259
              ],
              [
                -43.480849217011595,
                -14.76744628272526
              ],
              [
                -43.47221926076182,
                -14.77697575064565
              ],
              [
                -43.46856479294821,
                -14.781010697700726
              ],
              [
                -43.46896028677844,
                -14.784231664709287
              ],
              [
                -43.47413921086226,
                -14.789527146300962
              ],
              [
                -43.4778456801713,
                -14.789925138264353
              ],
              [
                -43.4850586415019,
                -14.79070064897369
              ],
              [
                -43.49385405071559,
                -14.790327162430597
              ],
              [
                -43.49704503094938,
                -14.795325660430407
              ],
              [
                -43.49806601550375,
                -14.796925632428975
              ],
              [
                -43.50434947074936,
                -14.796529181697785
              ],
              [
                -43.51075341700666,
                -14.796125669218625
              ],
              [
                -43.51057039992765,
                -14.797554676183971
              ],
              [
                -43.509342923662054,
                -14.807151086520992
              ],
              [
                -43.514958356259264,
                -14.804144134566636
              ],
              [
                -43.52082180489849,
                -14.80545913167953
              ],
              [
                -43.52307026287368,
                -14.809976602017375
              ],
              [
                -43.524843272562656,
                -14.81353808136706
              ],
              [
                -43.53098520088784,
                -14.814848591000619
              ],
              [
                -43.538789656209524,
                -14.805285654798586
              ],
              [
                -43.53667117884811,
                -14.791069748823988
              ],
              [
                -43.53653372068785,
                -14.79014226824333
              ],
              [
                -43.53354075908551,
                -14.788270742489466
              ],
              [
                -43.53676422869143,
                -14.783055282869023
              ],
              [
                -43.56296052801247,
                -14.769930433142436
              ],
              [
                -43.573374442644685,
                -14.764712960933966
              ],
              [
                -43.575812939628236,
                -14.759596012347886
              ],
              [
                -43.580212400748934,
                -14.750363572246718
              ],
              [
                -43.590376807870335,
                -14.750424082405646
              ],
              [
                -43.59834026330796,
                -14.754902050837428
              ],
              [
                -43.60565068086155,
                -14.75901305139203
              ],
              [
                -43.61459159553667,
                -14.759443077206184
              ],
              [
                -43.62508254824963,
                -14.75277160097532
              ],
              [
                -43.629587502923506,
                -14.749907149207528
              ],
              [
                -43.63811795176988,
                -14.748295645751499
              ],
              [
                -43.65862074200044,
                -14.744423734733907
              ],
              [
                -43.663062743550746,
                -14.739731259986378
              ],
              [
                -43.665959236000376,
                -14.736671286625933
              ],
              [
                -43.668058203729764,
                -14.736554789957982
              ],
              [
                -43.67060167880402,
                -14.736412785212739
              ],
              [
                -43.673756676551896,
                -14.73200881248373
              ],
              [
                -43.67364065016416,
                -14.731105309787266
              ],
              [
                -43.67338715186468,
                -14.72913485535353
              ],
              [
                -43.67505014693219,
                -14.729729348601056
              ],
              [
                -43.68067960538613,
                -14.731741337059416
              ],
              [
                -43.685508086085974,
                -14.727401374825156
              ],
              [
                -43.68901405776717,
                -14.7269218637951
              ],
              [
                -43.68691906131972,
                -14.721371408988619
              ],
              [
                -43.70300990398182,
                -14.724871892430675
              ],
              [
                -43.709320384214465,
                -14.729940870461464
              ],
              [
                -43.719452787428764,
                -14.729091389128072
              ],
              [
                -43.71920577921867,
                -14.720458478252809
              ],
              [
                -43.711822860457865,
                -14.71453248159644
              ],
              [
                -43.71773734371513,
                -14.710973028473008
              ],
              [
                -43.71778232060395,
                -14.710828513430558
              ],
              [
                -43.720598322569124,
                -14.701818091158332
              ],
              [
                -43.72584778335632,
                -14.697713085207246
              ],
              [
                -43.74920859230791,
                -14.69974863508738
              ],
              [
                -43.7552130304139,
                -14.700271630259683
              ],
              [
                -43.75918502377462,
                -14.69620917483674
              ],
              [
                -43.763155988143865,
                -14.692147200408277
              ],
              [
                -43.77407089501569,
                -14.691800229341142
              ],
              [
                -43.77573937623972,
                -14.691747203541198
              ],
              [
                -43.77688486213646,
                -14.690966726266156
              ],
              [
                -43.78524679298324,
                -14.685271768340693
              ],
              [
                -43.794390235188686,
                -14.68625576912034
              ],
              [
                -43.800096183646,
                -14.686869776226263
              ],
              [
                -43.8202205144369,
                -14.680266340104325
              ],
              [
                -43.84645781116099,
                -14.671657463709813
              ],
              [
                -43.852622750968735,
                -14.672753463680836
              ],
              [
                -43.85878769877947,
                -14.673849960652023
              ],
              [
                -43.86279069677369,
                -14.662185028467315
              ],
              [
                -43.86290269143792,
                -14.661858537645353
              ],
              [
                -43.86308022391665,
                -14.661884521793478
              ],
              [
                -43.87149313379226,
                -14.663137535684509
              ],
              [
                -43.872851156703184,
                -14.653141591791432
              ],
              [
                -43.87548262926471,
                -14.651819098367195
              ],
              [
                -43.87870807201406,
                -14.6575795959101
              ],
              [
                -43.88309705831386,
                -14.6527811096445
              ],
              [
                -43.878876092347916,
                -14.645631142836177
              ],
              [
                -43.88329158337326,
                -14.642510202418652
              ],
              [
                -43.87820261432684,
                -14.640464694089898
              ],
              [
                -43.87673612756876,
                -14.639875706145752
              ],
              [
                -43.87846813497731,
                -14.63889371510014
              ],
              [
                -43.88537055779765,
                -14.634982214771806
              ],
              [
                -43.87900210926665,
                -14.635744218172185
              ],
              [
                -43.8742141629673,
                -14.636316209449763
              ],
              [
                -43.879892600431724,
                -14.630482258706541
              ],
              [
                -43.881344618089024,
                -14.628990765276999
              ],
              [
                -43.88026962834576,
                -14.628255276900168
              ],
              [
                -43.87063121631093,
                -14.621659293442432
              ],
              [
                -43.87403817594337,
                -14.613989851966128
              ],
              [
                -43.86965574623115,
                -14.612381862217813
              ],
              [
                -43.87647416833188,
                -14.608691396395194
              ],
              [
                -43.875091205121876,
                -14.592508453518246
              ],
              [
                -43.880987186265,
                -14.589708493684832
              ],
              [
                -43.88309962699414,
                -14.597298469113232
              ],
              [
                -43.883272616433956,
                -14.597919458630926
              ],
              [
                -43.88519363336994,
                -14.594122995036335
              ],
              [
                -43.88224818697242,
                -14.58629904860477
              ],
              [
                -43.886121144420514,
                -14.583214051032252
              ],
              [
                -43.880678190961184,
                -14.571391099685004
              ],
              [
                -43.87676524378179,
                -14.56289316673817
              ],
              [
                -43.8794857292991,
                -14.56229018207254
              ],
              [
                -43.88090273010978,
                -14.561976192413088
              ],
              [
                -43.87347035621696,
                -14.520136404695826
              ],
              [
                -43.84194068258564,
                -14.461786210114132
              ],
              [
                -43.83601826851877,
                -14.44905428785114
              ],
              [
                -43.836016255543505,
                -14.449049784875237
              ],
              [
                -43.83009185547848,
                -14.436312850680476
              ],
              [
                -43.818988004197266,
                -14.388892126009363
              ],
              [
                -43.80420966892929,
                -14.371971707659503
              ],
              [
                -43.782788890582395,
                -14.338798863878338
              ],
              [
                -43.790745340535054,
                -14.336547410088144
              ],
              [
                -43.79229332346426,
                -14.336109394381772
              ],
              [
                -43.79336030828413,
                -14.336292927053421
              ],
              [
                -43.79967574894963,
                -14.337379419029766
              ],
              [
                -43.80643570026059,
                -14.332073459951834
              ],
              [
                -43.80770869349877,
                -14.327106008555765
              ],
              [
                -43.80824167775892,
                -14.325027007202271
              ],
              [
                -43.810659693361195,
                -14.325217014108269
              ],
              [
                -43.8144091337336,
                -14.32551149261593
              ],
              [
                -43.81467966056968,
                -14.322643012660478
              ],
              [
                -43.8149096519779,
                -14.32020552899473
              ],
              [
                -43.81828662193576,
                -14.321351048655274
              ],
              [
                -43.82190709672771,
                -14.322579550218501
              ],
              [
                -43.82141108324071,
                -14.320660552149414
              ],
              [
                -43.81919261983588,
                -14.312078095045282
              ],
              [
                -43.82592857949303,
                -14.315734088961191
              ],
              [
                -43.83573749404675,
                -14.310704149311103
              ],
              [
                -43.841511441400534,
                -14.316219624217986
              ],
              [
                -43.84681788541871,
                -14.321289103083238
              ],
              [
                -43.84646137529233,
                -14.315126132255385
              ],
              [
                -43.84631840484301,
                -14.31266111412422
              ],
              [
                -43.847985895265346,
                -14.314779629946296
              ],
              [
                -43.851015340328196,
                -14.318629123455322
              ],
              [
                -43.85504334776484,
                -14.317594634579653
              ],
              [
                -43.85169886610783,
                -14.313860636831004
              ],
              [
                -43.85104136185911,
                -14.31312665322253
              ],
              [
                -43.85229438144941,
                -14.307490678877409
              ],
              [
                -43.86001177783491,
                -14.311802665441617
              ],
              [
                -43.86112428051079,
                -14.3124246625033
              ],
              [
                -43.86954273373366,
                -14.309598676997195
              ],
              [
                -43.872428183543896,
                -14.313140186157847
              ],
              [
                -43.88051463268315,
                -14.311357208702942
              ],
              [
                -43.880982615566644,
                -14.306975702333503
              ],
              [
                -43.888445081940695,
                -14.306290734097947
              ],
              [
                -43.888885585922715,
                -14.304804754942749
              ],
              [
                -43.89171055037953,
                -14.29528032804967
              ],
              [
                -43.89207102702146,
                -14.30057379322588
              ],
              [
                -43.90124446007825,
                -14.29828181171204
              ],
              [
                -43.90401444618715,
                -14.29316684270778
              ],
              [
                -43.904848424428785,
                -14.299859284118794
              ],
              [
                -43.91057340768249,
                -14.297218339934794
              ],
              [
                -43.90950290157034,
                -14.300029817913384
              ],
              [
                -43.90871339543369,
                -14.302105314872973
              ],
              [
                -43.91118988751154,
                -14.304593303809327
              ],
              [
                -43.91497285037612,
                -14.302223327695046
              ],
              [
                -43.91589636560943,
                -14.298902825583747
              ],
              [
                -43.9168443417974,
                -14.29549285706243
              ],
              [
                -43.92355728948723,
                -14.295895856900303
              ],
              [
                -43.924057293988845,
                -14.297791334136479
              ],
              [
                -43.92601778057678,
                -14.30522679791824
              ],
              [
                -43.93356870651924,
                -14.296496378087905
              ],
              [
                -43.93465919872458,
                -14.295234857647985
              ],
              [
                -43.93541817965162,
                -14.296244367746551
              ],
              [
                -43.94172762151923,
                -14.304645837956269
              ],
              [
                -43.94436663472031,
                -14.29502639727886
              ],
              [
                -43.94588062896504,
                -14.289506443618102
              ],
              [
                -43.950534596360924,
                -14.289549961190998
              ],
              [
                -43.95212154296621,
                -14.292517907698738
              ],
              [
                -43.95572552218563,
                -14.299258876520218
              ],
              [
                -43.95493153908059,
                -14.291741926182572
              ],
              [
                -43.961514496683165,
                -14.290561938495905
              ],
              [
                -43.964146491231986,
                -14.284729489623423
              ],
              [
                -43.96917395435855,
                -14.273584581301632
              ],
              [
                -43.970719451182525,
                -14.277757550362468
              ],
              [
                -43.97329291203331,
                -14.278804527288507
              ],
              [
                -43.98042533187668,
                -14.28170652254415
              ],
              [
                -43.98218884858602,
                -14.27532556055903
              ],
              [
                -43.98478980189685,
                -14.274292098262844
              ],
              [
                -43.99018180308157,
                -14.272150112449191
              ],
              [
                -43.98596280890754,
                -14.267108619197167
              ],
              [
                -44.00573964465079,
                -14.266921660635125
              ],
              [
                -44.00887560891949,
                -14.27188912252975
              ],
              [
                -44.0150700680868,
                -14.271101668781043
              ],
              [
                -44.01505855215314,
                -14.27281165330351
              ],
              [
                -44.015036079578586,
                -14.276063123380968
              ],
              [
                -44.025158974343704,
                -14.2763611526196
              ],
              [
                -44.03409688105167,
                -14.28842506994839
              ],
              [
                -44.038018852287514,
                -14.2818516077396
              ],
              [
                -44.04112634363766,
                -14.285775587000517
              ],
              [
                -44.04901328297339,
                -14.28434862102766
              ],
              [
                -44.05674173004009,
                -14.283816144322241
              ],
              [
                -44.05686870438526,
                -14.28077564311296
              ],
              [
                -44.057018231550046,
                -14.277167199413288
              ],
              [
                -44.05938220991699,
                -14.280040176847265
              ],
              [
                -44.06093367974818,
                -14.281926643939828
              ],
              [
                -44.068018634894436,
                -14.279280671059523
              ],
              [
                -44.07298607631318,
                -14.285216634186279
              ],
              [
                -44.07279008351769,
                -14.279816185840268
              ],
              [
                -44.07754756294282,
                -14.277712214720092
              ],
              [
                -44.07632207072952,
                -14.275084738701267
              ],
              [
                -44.07404108724646,
                -14.27019575471975
              ],
              [
                -44.08204353105397,
                -14.26854824828134
              ],
              [
                -44.08200300474333,
                -14.273422754437915
              ],
              [
                -44.08199000505313,
                -14.274999709784147
              ],
              [
                -44.08787495476738,
                -14.277631233644472
              ],
              [
                -44.08707097350655,
                -14.27194025104353
              ],
              [
                -44.08651099715499,
                -14.267980772282968
              ],
              [
                -44.09039193779711,
                -14.270968757580107
              ],
              [
                -44.09323694041177,
                -14.273158748006386
              ],
              [
                -44.098988400166796,
                -14.27301224660749
              ],
              [
                -44.10045489547328,
                -14.271649278404881
              ],
              [
                -44.10562235131377,
                -14.266848815437028
              ],
              [
                -44.10687283147909,
                -14.258676858449647
              ],
              [
                -44.11218378231996,
                -14.258284388802995
              ],
              [
                -44.12320720125943,
                -14.273526787352635
              ],
              [
                -44.12392968352059,
                -14.274525806475603
              ],
              [
                -44.135204585623015,
                -14.281740282501948
              ],
              [
                -44.14320501014214,
                -14.278048813541936
              ],
              [
                -44.13557708448197,
                -14.27445981755062
              ],
              [
                -44.13559957363204,
                -14.27427080174195
              ],
              [
                -44.13685358803842,
                -14.263704895336083
              ],
              [
                -44.16014441510861,
                -14.257909938013292
              ],
              [
                -44.162356859495446,
                -14.264871421280823
              ],
              [
                -44.16239836506418,
                -14.264921433045837
              ],
              [
                -44.16470683303973,
                -14.267723901851582
              ],
              [
                -44.17253227833807,
                -14.26611242690054
              ],
              [
                -44.176084765295194,
                -14.263148465977839
              ],
              [
                -44.182515696771404,
                -14.257784502552449
              ],
              [
                -44.20087655700763,
                -14.254031546448008
              ],
              [
                -44.200823078378335,
                -14.25069154272213
              ],
              [
                -44.208710003273445,
                -14.246226093266593
              ],
              [
                -44.208857532783014,
                -14.244126594316711
              ],
              [
                -44.20941501023765,
                -14.236206140555367
              ],
              [
                -44.214657468968994,
                -14.232850706862896
              ],
              [
                -44.21764943657561,
                -14.244535118670179
              ],
              [
                -44.24019975430693,
                -14.240255672835248
              ],
              [
                -44.234663295857914,
                -14.246494145465462
              ],
              [
                -44.23923826439205,
                -14.248049148719437
              ],
              [
                -44.23898227791527,
                -14.250631117554638
              ],
              [
                -44.23829524226574,
                -14.257559563181657
              ],
              [
                -44.24662322037421,
                -14.251516615074452
              ],
              [
                -44.24920616637746,
                -14.253043107142009
              ],
              [
                -44.244020227684324,
                -14.25665960634399
              ],
              [
                -44.247091209020745,
                -14.259672554185057
              ],
              [
                -44.25401212432579,
                -14.251609125001915
              ],
              [
                -44.25556511059468,
                -14.251010140275868
              ],
              [
                -44.268154530517116,
                -14.246148670168145
              ],
              [
                -44.27357946292199,
                -14.250035673670395
              ],
              [
                -44.27631444991055,
                -14.251995148365369
              ],
              [
                -44.2802119178724,
                -14.250834683022973
              ],
              [
                -44.28160638878588,
                -14.249485696594423
              ],
              [
                -44.28395439448816,
                -14.247213181400914
              ],
              [
                -44.28627438335685,
                -14.248914682966058
              ],
              [
                -44.290860328607565,
                -14.252279181243821
              ],
              [
                -44.301922755593836,
                -14.252797704807978
              ],
              [
                -44.309269205017124,
                -14.246103737974725
              ],
              [
                -44.31606763206736,
                -14.239909298171296
              ],
              [
                -44.32035412294951,
                -14.244315266613015
              ],
              [
                -44.34018245050636,
                -14.248956269945499
              ],
              [
                -44.340703909371186,
                -14.256170232961278
              ],
              [
                -44.3407138980343,
                -14.256313218108813
              ],
              [
                -44.3411999129583,
                -14.256356239670637
              ],
              [
                -44.35142383120021,
                -14.257272727422404
              ],
              [
                -44.349135852123695,
                -14.259462734231915
              ],
              [
                -44.3456313819823,
                -14.262817207900392
              ],
              [
                -44.35200781224925,
                -14.260722207443786
              ],
              [
                -44.35262629252226,
                -14.260519227725934
              ],
              [
                -44.352613306793394,
                -14.260988210853315
              ],
              [
                -44.352482307403996,
                -14.265738682757805
              ],
              [
                -44.3611767250529,
                -14.272365677234939
              ],
              [
                -44.3694111854278,
                -14.271179672412742
              ],
              [
                -44.37323913253141,
                -14.274210687497286
              ],
              [
                -44.37575110435131,
                -14.271138678420657
              ],
              [
                -44.37706860038914,
                -14.269527713441894
              ],
              [
                -44.37738910759561,
                -14.271857678833351
              ],
              [
                -44.377924085258044,
                -14.275744680133165
              ],
              [
                -44.389100489683216,
                -14.280031680080109
              ],
              [
                -44.389489466953336,
                -14.276409193735335
              ],
              [
                -44.389566985596026,
                -14.275692210220505
              ],
              [
                -44.39415747089407,
                -14.276785680377108
              ],
              [
                -44.39673640538607,
                -14.273034239533372
              ],
              [
                -44.39908392048349,
                -14.277850181282423
              ],
              [
                -44.409452307572565,
                -14.281579199265023
              ],
              [
                -44.410267291329944,
                -14.281882686808334
              ],
              [
                -44.43016811649621,
                -14.289290164704463
              ],
              [
                -44.439261029464255,
                -14.302865610860229
              ],
              [
                -44.453026915002994,
                -14.31142408043993
              ],
              [
                -44.461453852667724,
                -14.312592089728028
              ],
              [
                -44.464588299496214,
                -14.310595078170659
              ],
              [
                -44.465071818075046,
                -14.310287612857696
              ],
              [
                -44.46633077352327,
                -14.312077079163018
              ],
              [
                -44.46915727009761,
                -14.316094048649708
              ],
              [
                -44.47044174247813,
                -14.315574094989904
              ],
              [
                -44.47195124317183,
                -14.31496407625933
              ],
              [
                -44.48002368784111,
                -14.319990563589261
              ],
              [
                -44.48272564762803,
                -14.321672560015605
              ],
              [
                -44.486761104985845,
                -14.328074535146124
              ],
              [
                -44.49803002893826,
                -14.327238061453349
              ],
              [
                -44.50119151306874,
                -14.323846574378686
              ],
              [
                -44.50302447154847,
                -14.321880580407454
              ],
              [
                -44.50838140967765,
                -14.325156573729485
              ],
              [
                -44.508452420017306,
                -14.32519958059056
              ],
              [
                -44.50847092291396,
                -14.325172569785572
              ],
              [
                -44.51042994108915,
                -14.322244589849777
              ],
              [
                -44.51593586764648,
                -14.327386080144139
              ],
              [
                -44.518484321446266,
                -14.329766062100072
              ],
              [
                -44.521061813228194,
                -14.329242052674678
              ],
              [
                -44.52873675003166,
                -14.327681080232923
              ],
              [
                -44.54012666298828,
                -14.33132810564649
              ],
              [
                -44.542413628063365,
                -14.336973064426557
              ],
              [
                -44.54470111712856,
                -14.342618526212036
              ],
              [
                -44.5504640640086,
                -14.339744558445592
              ],
              [
                -44.55678649980334,
                -14.336592081318129
              ],
              [
                -44.559455997663726,
                -14.340056074950796
              ],
              [
                -44.56515042773325,
                -14.339617076349995
              ],
              [
                -44.564568915527104,
                -14.344601542236182
              ],
              [
                -44.56415391752467,
                -14.348158017037676
              ],
              [
                -44.56918940575203,
                -14.346193040532723
              ],
              [
                -44.57188637352277,
                -14.345140555509708
              ],
              [
                -44.57346884698912,
                -14.347666552960591
              ],
              [
                -44.57468034256328,
                -14.349601510347842
              ],
              [
                -44.58171330932187,
                -14.352125013156934
              ],
              [
                -44.58574973860204,
                -14.353573034330415
              ],
              [
                -44.58747872871965,
                -14.35562501090388
              ],
              [
                -44.597268647461895,
                -14.367240959574286
              ],
              [
                -44.604552061991456,
                -14.365989460625395
              ],
              [
                -44.61032202746811,
                -14.364997984510154
              ],
              [
                -44.61488146345324,
                -14.372542956816952
              ],
              [
                -44.61618096891081,
                -14.37469393107353
              ],
              [
                -44.61820746288859,
                -14.37491893219696
              ],
              [
                -44.625191883225995,
                -14.375693440523271
              ],
              [
                -44.627860380281206,
                -14.3818139222009
              ],
              [
                -44.63315130494194,
                -14.39395036113465
              ],
              [
                -44.63767677845114,
                -14.389748877243012
              ],
              [
                -44.63992576077968,
                -14.390431883994825
              ],
              [
                -44.64337623359871,
                -14.391480376611577
              ],
              [
                -44.64275771790377,
                -14.392454884674631
              ],
              [
                -44.640683720987305,
                -14.39572183741839
              ],
              [
                -44.645402672816495,
                -14.397648858927825
              ],
              [
                -44.648902172041375,
                -14.397147865969794
              ],
              [
                -44.65112364641146,
                -14.396829358712573
              ],
              [
                -44.652040130605705,
                -14.393127879591443
              ],
              [
                -44.658462604014375,
                -14.395312864481411
              ],
              [
                -44.66296654143527,
                -14.407267336234948
              ],
              [
                -44.662515022807746,
                -14.407999298049168
              ],
              [
                -44.65762856148645,
                -14.415918260942362
              ],
              [
                -44.664428489526976,
                -14.417699244942067
              ],
              [
                -44.66756545647297,
                -14.423398228045116
              ],
              [
                -44.66915144835765,
                -14.415752779803404
              ],
              [
                -44.67379390825364,
                -14.424048723874668
              ],
              [
                -44.679022386661096,
                -14.42115977328138
              ],
              [
                -44.687936828572106,
                -14.41623532129404
              ],
              [
                -44.6874343188612,
                -14.421095788179045
              ],
              [
                -44.69381926401726,
                -14.423044293473362
              ],
              [
                -44.69274926219122,
                -14.432172229715578
              ],
              [
                -44.69779122191798,
                -14.431606727824034
              ],
              [
                -44.69873067840607,
                -14.437004188985567
              ],
              [
                -44.700878667358225,
                -14.449340629522162
              ],
              [
                -44.70484712268287,
                -14.446138151636326
              ],
              [
                -44.71014907645179,
                -14.447678651543505
              ],
              [
                -44.71072306963171,
                -14.446645663745317
              ],
              [
                -44.712462057083684,
                -14.443518175551759
              ],
              [
                -44.71865450453921,
                -14.450563646915036
              ],
              [
                -44.723403954200045,
                -14.448612672855184
              ],
              [
                -44.72485996486011,
                -14.455427143428427
              ],
              [
                -44.72523943651274,
                -14.457204623406138
              ],
              [
                -44.741405795327836,
                -14.466424592024582
              ],
              [
                -44.743030800085045,
                -14.465843101331648
              ],
              [
                -44.74762273049287,
                -14.464199113158536
              ],
              [
                -44.74860524261829,
                -14.469038068808597
              ],
              [
                -44.749071704606266,
                -14.471335059827545
              ],
              [
                -44.75337365924066,
                -14.475250033785912
              ],
              [
                -44.75632464417885,
                -14.477935536785985
              ],
              [
                -44.75973311181163,
                -14.474907066872294
              ],
              [
                -44.75986360695546,
                -14.474791043794873
              ],
              [
                -44.760120616312925,
                -14.475086059470081
              ],
              [
                -44.7624310855531,
                -14.477740043550961
              ],
              [
                -44.765103061317824,
                -14.477393038268083
              ],
              [
                -44.76895454870964,
                -14.476893069949227
              ],
              [
                -44.78163092136784,
                -14.482148578418942
              ],
              [
                -44.78592190732371,
                -14.487033045575593
              ],
              [
                -44.78714837373453,
                -14.48842903233449
              ],
              [
                -44.788497851064356,
                -14.48822603289545
              ],
              [
                -44.79394833997045,
                -14.487406056278369
              ],
              [
                -44.79393131829046,
                -14.491132540907106
              ],
              [
                -44.79390731031225,
                -14.496144499819753
              ],
              [
                -44.80307424336807,
                -14.49611651491992
              ],
              [
                -44.80437772271241,
                -14.496112495209758
              ],
              [
                -44.817840627243235,
                -14.489750549735852
              ],
              [
                -44.80988768228783,
                -14.499035006275093
              ],
              [
                -44.81074268798398,
                -14.50029598720601
              ],
              [
                -44.81339913207489,
                -14.504214493348508
              ],
              [
                -44.8165121150314,
                -14.503440488400566
              ],
              [
                -44.83281947610307,
                -14.499384540063822
              ],
              [
                -44.834153957144956,
                -14.507046004502754
              ],
              [
                -44.82563201542241,
                -14.512722919673035
              ],
              [
                -44.82267102802047,
                -14.514695415705752
              ],
              [
                -44.82813551768043,
                -14.518318911524068
              ],
              [
                -44.830518485292366,
                -14.519898907215127
              ],
              [
                -44.83587344789913,
                -14.518047928624263
              ],
              [
                -44.8316494445223,
                -14.526173865621315
              ],
              [
                -44.83137695478656,
                -14.526698883003805
              ],
              [
                -44.83447042436626,
                -14.535473836878186
              ],
              [
                -44.82601800168037,
                -14.533740324514975
              ],
              [
                -44.82393497217229,
                -14.538934786792652
              ],
              [
                -44.83246043277472,
                -14.54201776821378
              ],
              [
                -44.83799337258498,
                -14.540353796822705
              ],
              [
                -44.839481356239595,
                -14.539906291096106
              ],
              [
                -44.84668529588238,
                -14.545043771933216
              ],
              [
                -44.84650980024874,
                -14.557577189668676
              ],
              [
                -44.84954372769448,
                -14.562325185579947
              ],
              [
                -44.8545382161224,
                -14.562180211175331
              ],
              [
                -44.853711699564535,
                -14.566693144752396
              ],
              [
                -44.858377672330484,
                -14.571077626704563
              ],
              [
                -44.85166173078736,
                -14.570783637717083
              ],
              [
                -44.84968323112659,
                -14.570696630777547
              ],
              [
                -44.847119240100035,
                -14.57503261537544
              ],
              [
                -44.84348529871687,
                -14.565864674829502
              ],
              [
                -44.843013271832646,
                -14.580372070278209
              ],
              [
                -44.8502892003254,
                -14.5771210914374
              ],
              [
                -44.85892163442299,
                -14.578375620043186
              ],
              [
                -44.861566638088874,
                -14.578759095380175
              ],
              [
                -44.86725957016038,
                -14.587093556553825
              ],
              [
                -44.8755384770855,
                -14.599213497677985
              ],
              [
                -44.87579648087733,
                -14.596020540194912
              ],
              [
                -44.876054980665714,
                -14.592826526720163
              ],
              [
                -44.889966341028845,
                -14.59711354850572
              ],
              [
                -44.894997308866216,
                -14.603626487455161
              ],
              [
                -44.896065793574216,
                -14.605010015072992
              ],
              [
                -44.8978612743607,
                -14.604761003708614
              ],
              [
                -44.90979217292131,
                -14.603107518505471
              ],
              [
                -44.90834768790769,
                -14.609492996876488
              ],
              [
                -44.90756818289626,
                -14.612939983946223
              ],
              [
                -44.91656962305444,
                -14.614175972378742
              ],
              [
                -44.91626010125601,
                -14.619211446806332
              ],
              [
                -44.93972741590011,
                -14.627107446979453
              ],
              [
                -44.94149239842614,
                -14.626059937325131
              ],
              [
                -44.94617137535856,
                -14.623283484096921
              ],
              [
                -44.94396785391163,
                -14.62729743130376
              ],
              [
                -44.94260836642192,
                -14.629773912156434
              ],
              [
                -44.950996319359845,
                -14.631149914712381
              ],
              [
                -44.95104879591455,
                -14.63115891475106
              ],
              [
                -44.95107680259672,
                -14.631206921514373
              ],
              [
                -44.9546462493232,
                -14.637198891102532
              ],
              [
                -44.95695525588613,
                -14.63535691813732
              ],
              [
                -44.9619082327748,
                -14.6314054493887
              ],
              [
                -44.980180531946715,
                -14.643056418186974
              ],
              [
                -44.975825082054904,
                -14.64867885904732
              ],
              [
                -44.973518068355396,
                -14.651656360259706
              ],
              [
                -44.979774526625285,
                -14.651467368411506
              ],
              [
                -44.98293099603345,
                -14.651371845543688
              ],
              [
                -44.98363800801099,
                -14.654194865991325
              ],
              [
                -44.985515955733035,
                -14.661690303707086
              ],
              [
                -44.99232690603634,
                -14.659592840199124
              ],
              [
                -44.9962103945627,
                -14.658396823164027
              ],
              [
                -44.99588387505454,
                -14.66171331286116
              ],
              [
                -44.99532739607098,
                -14.667366279257823
              ],
              [
                -45.0145012234255,
                -14.67281579071132
              ],
              [
                -45.039107000618074,
                -14.679809787633692
              ],
              [
                -45.039420970374124,
                -14.687676734461148
              ],
              [
                -45.03953099531867,
                -14.690439219249319
              ],
              [
                -45.044515945333394,
                -14.695428185445666
              ],
              [
                -45.045460443407165,
                -14.692334708485426
              ],
              [
                -45.04639292850321,
                -14.689281232267065
              ],
              [
                -45.053707866640536,
                -14.699020213394142
              ],
              [
                -45.063024239651334,
                -14.725741563354651
              ],
              [
                -45.083055547477024,
                -14.748568452601845
              ],
              [
                -45.08904846498304,
                -14.75049896181781
              ],
              [
                -45.09504142049009,
                -14.752430431032687
              ],
              [
                -45.13155013324734,
                -14.745858520892853
              ],
              [
                -45.15246196894289,
                -14.742094578379005
              ],
              [
                -45.165801878651614,
                -14.734981645207721
              ],
              [
                -45.16318239670267,
                -14.731937182565963
              ],
              [
                -45.171597844862845,
                -14.734230190965585
              ],
              [
                -45.17382728624227,
                -14.740864637679328
              ],
              [
                -45.17453428791741,
                -14.7411666321512
              ],
              [
                -45.18148271876973,
                -14.744128643000261
              ],
              [
                -45.182682708623275,
                -14.743796637100688
              ],
              [
                -45.189182670079674,
                -14.742000171299422
              ],
              [
                -45.19039415845169,
                -14.747192620750745
              ],
              [
                -45.190878163609796,
                -14.749266613349366
              ],
              [
                -45.19323661995543,
                -14.748444618411252
              ],
              [
                -45.20515255076822,
                -14.74429215818983
              ],
              [
                -45.21623542880699,
                -14.75744559220323
              ],
              [
                -45.22884182646173,
                -14.765277102429637
              ],
              [
                -45.22974031158066,
                -14.76876204245174
              ],
              [
                -45.23123476948142,
                -14.77456202987067
              ],
              [
                -45.2415396638876,
                -14.786082991272758
              ],
              [
                -45.246298158965615,
                -14.783544501760439
              ],
              [
                -45.247015621852675,
                -14.783161018306057
              ],
              [
                -45.258292533943425,
                -14.789075481571581
              ],
              [
                -45.26093149388225,
                -14.796797444743152
              ],
              [
                -45.26262445573822,
                -14.801753915542186
              ],
              [
                -45.26495047564503,
                -14.800787916402868
              ],
              [
                -45.27076038895312,
                -14.798374944032613
              ],
              [
                -45.27354885555585,
                -14.80932489446821
              ],
              [
                -45.27398888070395,
                -14.811053908064597
              ],
              [
                -45.2764183368244,
                -14.812173872816453
              ],
              [
                -45.28831774466269,
                -14.817660355814285
              ],
              [
                -45.28649075364508,
                -14.82128832616286
              ],
              [
                -45.28952170490396,
                -14.825030804551748
              ],
              [
                -45.28973071771608,
                -14.82528833540964
              ],
              [
                -45.28526471725449,
                -14.832028263694674
              ],
              [
                -45.293371161375205,
                -14.827787317221532
              ],
              [
                -45.29504164945017,
                -14.826912831369654
              ],
              [
                -45.302151098832304,
                -14.828395836394238
              ],
              [
                -45.305577576537104,
                -14.83389729515158
              ],
              [
                -45.309739020252486,
                -14.836243302813122
              ],
              [
                -45.316727975342815,
                -14.83155133098456
              ],
              [
                -45.31112250192564,
                -14.840499254245753
              ],
              [
                -45.31101100049414,
                -14.840677259997669
              ],
              [
                -45.313945977986975,
                -14.845756737376083
              ],
              [
                -45.31762242900915,
                -14.85211968854334
              ],
              [
                -45.32207289078631,
                -14.851617206502484
              ],
              [
                -45.32968533675812,
                -14.85905968141717
              ],
              [
                -45.338025239770566,
                -14.86074418751991
              ],
              [
                -45.3524471380294,
                -14.863658227352094
              ],
              [
                -45.34949163849421,
                -14.867624164718107
              ],
              [
                -45.350071634410085,
                -14.869919669270748
              ],
              [
                -45.35151511179063,
                -14.875636627273389
              ],
              [
                -45.355145074583106,
                -14.877059625453835
              ],
              [
                -45.357398575244304,
                -14.875500134678733
              ],
              [
                -45.36354903838154,
                -14.87124466376687
              ],
              [
                -45.37166944376724,
                -14.880502637019589
              ],
              [
                -45.3710854326563,
                -14.88619460153708
              ],
              [
                -45.37031643360974,
                -14.893686044158153
              ],
              [
                -45.380708865171634,
                -14.897360030350432
              ],
              [
                -45.39937817490184,
                -14.917050464555794
              ],
              [
                -45.4206214890094,
                -14.92613644872573
              ],
              [
                -45.42169697387329,
                -14.924780967617787
              ],
              [
                -45.42549746719176,
                -14.91999000857951
              ],
              [
                -45.43450588304679,
                -14.922087995591259
              ],
              [
                -45.44207179126504,
                -14.93480641878068
              ],
              [
                -45.4507931868253,
                -14.938276925263922
              ],
              [
                -45.44953469648455,
                -14.940651409077145
              ],
              [
                -45.44869071427943,
                -14.942243877221504
              ],
              [
                -45.454912130539824,
                -14.948625884140561
              ],
              [
                -45.45470816827806,
                -14.953270343582535
              ],
              [
                -45.469586527419445,
                -14.949689379546149
              ],
              [
                -45.48963033439684,
                -14.960312870796141
              ],
              [
                -45.49472433176854,
                -14.951629426836929
              ],
              [
                -45.49806631161063,
                -14.94593146823416
              ],
              [
                -45.51108721676192,
                -14.952310427578146
              ],
              [
                -45.513562667357846,
                -14.95352342698395
              ],
              [
                -45.5203941110397,
                -14.952966436654176
              ],
              [
                -45.52262311264018,
                -14.950536485909677
              ],
              [
                -45.5330430150004,
                -14.939179072233701
              ],
              [
                -45.54734544237123,
                -14.93851058411876
              ],
              [
                -45.553229871921346,
                -14.944632075037914
              ],
              [
                -45.56570526125994,
                -14.944415066981703
              ],
              [
                -45.5666242314348,
                -14.95604700971407
              ],
              [
                -45.58799155016257,
                -14.973270439077035
              ],
              [
                -45.5870570278505,
                -14.975801927603378
              ],
              [
                -45.586353524872045,
                -14.977708413197654
              ],
              [
                -45.58826152587446,
                -14.976952923082951
              ],
              [
                -45.5930539877175,
                -14.975055460887479
              ],
              [
                -45.59392495356152,
                -14.982365385832434
              ],
              [
                -45.59409346746985,
                -14.983778409897733
              ],
              [
                -45.59643845254564,
                -14.985297381314796
              ],
              [
                -45.60228538787943,
                -14.989085401889922
              ],
              [
                -45.60067288676815,
                -14.99538684120547
              ],
              [
                -45.60056538582455,
                -14.995808327551863
              ],
              [
                -45.6008558724448,
                -14.995822342000343
              ],
              [
                -45.60812532588932,
                -14.996176360205899
              ],
              [
                -45.615697236458736,
                -15.013302252231737
              ],
              [
                -45.62226719373902,
                -15.019254234571369
              ],
              [
                -45.639080044382105,
                -15.0229917495623
              ],
              [
                -45.64308249709644,
                -15.016221274303971
              ],
              [
                -45.6465229431277,
                -15.031250709110662
              ],
              [
                -45.647211981746416,
                -15.023410237007319
              ],
              [
                -45.654950395408186,
                -15.02583873403718
              ],
              [
                -45.66014232192503,
                -15.037938685141773
              ],
              [
                -45.65794433821812,
                -15.04283215664734
              ],
              [
                -45.65545087461936,
                -15.048385636773121
              ],
              [
                -45.67431615238946,
                -15.08004248437376
              ],
              [
                -45.67840761694861,
                -15.079184955875379
              ],
              [
                -45.67928158525418,
                -15.083612971758614
              ],
              [
                -45.68679302094306,
                -15.085938432063134
              ],
              [
                -45.68781355033427,
                -15.085605444873737
              ],
              [
                -45.69346248622144,
                -15.083761486970506
              ],
              [
                -45.69333645715783,
                -15.088423445667557
              ],
              [
                -45.69328847406435,
                -15.090209955206113
              ],
              [
                -45.69844143978009,
                -15.091027956937666
              ],
              [
                -45.710466816777824,
                -15.092937961981377
              ],
              [
                -45.71254978735061,
                -15.09733645328977
              ],
              [
                -45.71656977346836,
                -15.093423443398288
              ],
              [
                -45.71522979626831,
                -15.099594407122671
              ],
              [
                -45.714824769909065,
                -15.101457402567135
              ],
              [
                -45.72176422652325,
                -15.104151893718239
              ],
              [
                -45.723466215468804,
                -15.104812408022584
              ],
              [
                -45.723315712720606,
                -15.105306914876827
              ],
              [
                -45.72139169594444,
                -15.111624863685083
              ],
              [
                -45.738783547137274,
                -15.116824371604192
              ],
              [
                -45.73967554240277,
                -15.117091358701751
              ],
              [
                -45.75119845410634,
                -15.121761351894895
              ],
              [
                -45.75631340694556,
                -15.118084864639316
              ],
              [
                -45.788726654693704,
                -15.11363544323838
              ],
              [
                -45.79411363299444,
                -15.115770452819268
              ],
              [
                -45.80129203343927,
                -15.118613938611292
              ],
              [
                -45.80441153106246,
                -15.11666096067934
              ],
              [
                -45.811236500295706,
                -15.112389013014573
              ],
              [
                -45.82053890742108,
                -15.117879997413757
              ],
              [
                -45.82496185752284,
                -15.115929512873084
              ],
              [
                -45.82906331728881,
                -15.119563507423385
              ],
              [
                -45.827434821992945,
                -15.12234395732606
              ],
              [
                -45.832174807230416,
                -15.123614458737512
              ],
              [
                -45.83338077338434,
                -15.130303923271684
              ],
              [
                -45.834572251808154,
                -15.136911909258453
              ],
              [
                -45.84530063488026,
                -15.144713894006998
              ],
              [
                -45.852896579453045,
                -15.144257910708568
              ],
              [
                -45.85840603262859,
                -15.143927383822051
              ],
              [
                -45.85746405217129,
                -15.14752389229309
              ],
              [
                -45.8572380523011,
                -15.148386857887584
              ],
              [
                -45.87255092593738,
                -15.15743135001589
              ],
              [
                -45.91803056692256,
                -15.133061581038042
              ],
              [
                -45.953320766599575,
                -15.13908008772762
              ],
              [
                -45.96268169622352,
                -15.146674582295741
              ],
              [
                -45.97204311783906,
                -15.154270032887307
              ],
              [
                -45.97169557731773,
                -15.165095477849627
              ],
              [
                -45.97157359484971,
                -15.168876443835192
              ],
              [
                -45.97450054541721,
                -15.170827927032727
              ],
              [
                -45.9791475148618,
                -15.173927411818056
              ],
              [
                -45.97774001492738,
                -15.175588425631716
              ],
              [
                -45.976428039445224,
                -15.177136896271234
              ],
              [
                -45.98407495756676,
                -15.195507824711646
              ],
              [
                -45.99265189165031,
                -15.195411844241494
              ],
              [
                -45.99395437080163,
                -15.200146290418688
              ],
              [
                -45.998212300411005,
                -15.197154347572416
              ],
              [
                -46.00048782124041,
                -15.195554845017762
              ],
              [
                -46.01009623216351,
                -15.195393356851671
              ],
              [
                -46.027620065156725,
                -15.200896858861332
              ],
              [
                -46.03037303698458,
                -15.209491806573054
              ],
              [
                -46.03165002081204,
                -15.213477779040026
              ],
              [
                -46.03483797725739,
                -15.214934766615313
              ],
              [
                -46.03909347514146,
                -15.216879805380493
              ],
              [
                -46.03863895306376,
                -15.22330975557811
              ],
              [
                -46.038132425956796,
                -15.23046719850362
              ],
              [
                -46.04343388351276,
                -15.233875181833032
              ],
              [
                -46.046595883022896,
                -15.23590768606928
              ],
              [
                -46.051961812066246,
                -15.245359132826076
              ],
              [
                -46.05554277771096,
                -15.2516671003115
              ],
              [
                -46.052202756547295,
                -15.258741558447403
              ],
              [
                -46.07665107683082,
                -15.264228580763914
              ],
              [
                -46.07937757590378,
                -15.253657637518243
              ],
              [
                -46.08082107240145,
                -15.248064167316288
              ],
              [
                -46.08451753196956,
                -15.245389199586722
              ],
              [
                -46.08724249552604,
                -15.246152708307873
              ],
              [
                -46.091924990241125,
                -15.24746571054315
              ],
              [
                -46.09755743290602,
                -15.239421238241796
              ],
              [
                -46.09803545661411,
                -15.226044324896838
              ],
              [
                -46.09838298006901,
                -15.216338378257165
              ],
              [
                -46.106390406277605,
                -15.210479446856196
              ],
              [
                -46.118483354563296,
                -15.201631499241472
              ],
              [
                -46.11881537741295,
                -15.19177605743937
              ],
              [
                -46.09726102398576,
                -15.192161039993266
              ],
              [
                -46.09240307077152,
                -15.184725061647304
              ],
              [
                -46.086984609064935,
                -15.183355554410571
              ],
              [
                -46.08023469773977,
                -15.181648559634127
              ],
              [
                -46.07915720062373,
                -15.17799807260755
              ],
              [
                -46.07828920541735,
                -15.175057604892626
              ],
              [
                -46.07482025637991,
                -15.175030088586292
              ],
              [
                -46.061449344176616,
                -15.174923086288446
              ],
              [
                -46.04944644751037,
                -15.165938578614893
              ],
              [
                -46.04351756362333,
                -15.144944720408565
              ],
              [
                -46.04471304436192,
                -15.141716243217045
              ],
              [
                -46.048976524718505,
                -15.130204306420692
              ],
              [
                -46.043290110113944,
                -15.117680847993759
              ],
              [
                -46.037044138615904,
                -15.116034345891599
              ],
              [
                -46.02804523670643,
                -15.113661847872063
              ],
              [
                -46.02762174345589,
                -15.101778423582303
              ],
              [
                -46.019099334079755,
                -15.092592491761497
              ],
              [
                -46.018573351847266,
                -15.086711498706457
              ],
              [
                -46.0180473486116,
                -15.080829533662504
              ],
              [
                -45.97509228131442,
                -15.038461691975025
              ],
              [
                -45.97449083922636,
                -15.00714139696155
              ],
              [
                -45.97808733977228,
                -14.998151426580186
              ],
              [
                -45.97380890014496,
                -14.99510645033044
              ],
              [
                -45.96929693772371,
                -14.991893971625926
              ],
              [
                -45.96565300394085,
                -14.965196114488672
              ],
              [
                -45.971318957371814,
                -14.948677727549727
              ],
              [
                -45.980351417946565,
                -14.943890764830629
              ],
              [
                -45.98653735351748,
                -14.940611273160608
              ],
              [
                -45.995407344469136,
                -14.927022385118544
              ],
              [
                -46.006801215007066,
                -14.92485042156167
              ],
              [
                -46.00487676503229,
                -14.91165500247657
              ],
              [
                -46.003447323499685,
                -14.901847565691456
              ],
              [
                -46.02085070630777,
                -14.883331177806467
              ],
              [
                -46.033435604729526,
                -14.879390705630126
              ],
              [
                -46.03698609429731,
                -14.873636251461171
              ],
              [
                -46.032454631571845,
                -14.857128870876917
              ],
              [
                -46.04406057367436,
                -14.841877944466011
              ],
              [
                -46.052396021781,
                -14.830924533170665
              ],
              [
                -46.04508260330305,
                -14.826376072233224
              ],
              [
                -46.03776917582755,
                -14.821827088311451
              ],
              [
                -46.02290181151486,
                -14.81756856497644
              ],
              [
                -46.02009084557179,
                -14.810042109557903
              ],
              [
                -46.00762843694154,
                -14.792752696333244
              ],
              [
                -46.008698986995334,
                -14.776233305267201
              ],
              [
                -46.00885099525495,
                -14.773893321142955
              ],
              [
                -46.00895746966616,
                -14.773753823146796
              ],
              [
                -46.01220694373468,
                -14.769476842896927
              ],
              [
                -46.00940799896016,
                -14.764917840325287
              ],
              [
                -46.007488489370736,
                -14.761792370018089
              ],
              [
                -46.007154555326174,
                -14.74125048587451
              ],
              [
                -46.00859254466741,
                -14.73868552139628
              ],
              [
                -46.011741516089316,
                -14.733066025781698
              ],
              [
                -46.00789205427543,
                -14.731365028717454
              ],
              [
                -46.00653958128636,
                -14.730767530748759
              ],
              [
                -46.00191414077461,
                -14.712778633086382
              ],
              [
                -46.00399213810083,
                -14.706991702527583
              ],
              [
                -46.006069632427746,
                -14.701204714970048
              ],
              [
                -46.00695614228408,
                -14.70068973856804
              ],
              [
                -46.02233401071947,
                -14.69176129829275
              ],
              [
                -46.02817849922714,
                -14.681535863366616
              ],
              [
                -46.02545849562077,
                -14.677569894558024
              ],
              [
                -46.0231095338747,
                -14.672634412069677
              ],
              [
                -46.02210156999685,
                -14.670516934587614
              ],
              [
                -46.024238043906,
                -14.664690477374867
              ],
              [
                -46.02063808667796,
                -14.663714953576505
              ],
              [
                -46.01244966657838,
                -14.659440959741602
              ],
              [
                -46.007876186856045,
                -14.657053502426649
              ],
              [
                -46.006821207057186,
                -14.662992450915818
              ],
              [
                -45.999868249642304,
                -14.664195912388866
              ],
              [
                -45.989323336733435,
                -14.658745447190086
              ],
              [
                -45.98857035529523,
                -14.645296011264872
              ],
              [
                -45.994803841557754,
                -14.638777083485582
              ],
              [
                -45.98440595597606,
                -14.620366649233205
              ],
              [
                -45.991378908588146,
                -14.612095736011414
              ],
              [
                -46.00060035917569,
                -14.60115881737782
              ],
              [
                -45.9889494403194,
                -14.588695864274207
              ],
              [
                -45.98893594445625,
                -14.588680830337852
              ],
              [
                -45.9848780304419,
                -14.573105430059103
              ],
              [
                -45.98730198927234,
                -14.567107491438469
              ],
              [
                -45.988218508210615,
                -14.566947485011799
              ],
              [
                -45.99537396008157,
                -14.565698490100617
              ],
              [
                -45.990686981340154,
                -14.563253483008811
              ],
              [
                -45.989155039158064,
                -14.556080039192684
              ],
              [
                -45.99345899181742,
                -14.553300558124976
              ],
              [
                -45.98904703928683,
                -14.550098073963907
              ],
              [
                -45.988714538411465,
                -14.549857059779058
              ],
              [
                -45.98921654354087,
                -14.549239577284839
              ],
              [
                -45.99485351330031,
                -14.542309628857291
              ],
              [
                -45.9903950262854,
                -14.54328113522031
              ],
              [
                -45.979297613889194,
                -14.545698578280161
              ],
              [
                -45.97665513985021,
                -14.538397126262607
              ],
              [
                -45.98051014175574,
                -14.529949665544592
              ],
              [
                -45.99428901401503,
                -14.523594727276981
              ],
              [
                -45.999246499495364,
                -14.521308270485513
              ],
              [
                -46.00384046674853,
                -14.515373798378882
              ],
              [
                -45.998438022577275,
                -14.509276344419051
              ],
              [
                -46.000987516450664,
                -14.504294873100699
              ],
              [
                -45.99853652573137,
                -14.502909369842811
              ],
              [
                -45.9937420913165,
                -14.500198865168237
              ],
              [
                -45.993238592203014,
                -14.494948407998013
              ],
              [
                -45.99678756355928,
                -14.487020449710798
              ],
              [
                -46.004817519101394,
                -14.48113848341565
              ],
              [
                -46.012833962738895,
                -14.475267044030513
              ],
              [
                -46.01595896136965,
                -14.469008076217609
              ],
              [
                -46.01182551728801,
                -14.4541761547108
              ],
              [
                -46.009399520239654,
                -14.4528501831702
              ],
              [
                -46.00109759336618,
                -14.448310206031751
              ],
              [
                -46.0004041504743,
                -14.434093268097484
              ],
              [
                -46.006535080178935,
                -14.428240812255222
              ],
              [
                -46.01635504384503,
                -14.418867387576269
              ],
              [
                -46.00804013170151,
                -14.406991958465778
              ],
              [
                -46.001434643718284,
                -14.422020362677907
              ],
              [
                -45.99839315322671,
                -14.428942291721103
              ],
              [
                -45.97461035267168,
                -14.431361744416163
              ],
              [
                -45.95992945473645,
                -14.426450749227355
              ],
              [
                -45.95643200711859,
                -14.423075268839948
              ],
              [
                -45.94951107615008,
                -14.416397303781993
              ],
              [
                -45.94221662314967,
                -14.415646782289892
              ],
              [
                -45.934919686177274,
                -14.414895274802417
              ],
              [
                -45.927213249448144,
                -14.409871810678084
              ],
              [
                -45.92567879559075,
                -14.385753426634155
              ],
              [
                -45.922094335628216,
                -14.379699485887256
              ],
              [
                -45.91170895267835,
                -14.37452796498089
              ],
              [
                -45.91240045476686,
                -14.366087049824824
              ],
              [
                -45.906861009640856,
                -14.352935107421612
              ],
              [
                -45.91315848546579,
                -14.346757644835588
              ],
              [
                -45.92853285306985,
                -14.34992865223095
              ],
              [
                -45.92965435463158,
                -14.350160138843117
              ],
              [
                -45.94254674371226,
                -14.342250720029545
              ],
              [
                -45.94575925695133,
                -14.33087730555414
              ],
              [
                -45.946038734538426,
                -14.329886782870247
              ],
              [
                -45.94631525965973,
                -14.329701804444118
              ],
              [
                -45.953649193856386,
                -14.324785349245197
              ],
              [
                -45.95854270423629,
                -14.303850456969721
              ],
              [
                -45.962840686649805,
                -14.285462567697751
              ],
              [
                -45.96921914616578,
                -14.28127763550474
              ],
              [
                -45.975597588687464,
                -14.277092156312262
              ],
              [
                -45.97771307843334,
                -14.277242652133966
              ],
              [
                -45.994868442530944,
                -14.278464176018067
              ],
              [
                -45.99302695795861,
                -14.269969241762379
              ],
              [
                -45.99137647722128,
                -14.262355251670956
              ],
              [
                -45.9956239674056,
                -14.256781812905228
              ],
              [
                -46.007019396238526,
                -14.255566324007571
              ],
              [
                -46.01602928050202,
                -14.258542327278274
              ],
              [
                -46.020502274911955,
                -14.260019848423159
              ],
              [
                -46.02545922659271,
                -14.256529358634689
              ],
              [
                -46.02650221936278,
                -14.253198363052094
              ],
              [
                -46.02885171532763,
                -14.24569893827627
              ],
              [
                -46.04194810260415,
                -14.244469457433205
              ],
              [
                -46.04588007876637,
                -14.244100979483903
              ],
              [
                -46.053238541853304,
                -14.238089536714755
              ],
              [
                -46.06059749294222,
                -14.232077542945788
              ],
              [
                -46.05720701573484,
                -14.220857133005683
              ],
              [
                -46.05224061771389,
                -14.204417710051828
              ],
              [
                -46.06306852668805,
                -14.193117783456533
              ],
              [
                -46.076630935490584,
                -14.1950588077088
              ],
              [
                -46.090955273082855,
                -14.205241762835223
              ],
              [
                -46.09579527268232,
                -14.205047778434581
              ],
              [
                -46.10123773241868,
                -14.192347838622272
              ],
              [
                -46.10677168605443,
                -14.190200861912112
              ],
              [
                -46.11562060951745,
                -14.18676891854509
              ],
              [
                -46.114096143076466,
                -14.176212958960129
              ],
              [
                -46.11363417564054,
                -14.173011983981452
              ],
              [
                -46.11770764399854,
                -14.15993107303461
              ],
              [
                -46.133294033338466,
                -14.15279712816747
              ],
              [
                -46.142084467437506,
                -14.156389126363132
              ],
              [
                -46.15087590253247,
                -14.15998215156649
              ],
              [
                -46.18521363864827,
                -14.151416251807275
              ],
              [
                -46.19103509604363,
                -14.147087259398726
              ],
              [
                -46.196856028444714,
                -14.142759277980861
              ],
              [
                -46.1970825596083,
                -14.136218347813609
              ],
              [
                -46.19743357033905,
                -14.126086384976787
              ],
              [
                -46.201762054363755,
                -14.11918694707216
              ],
              [
                -46.20933497749285,
                -14.119384445096193
              ],
              [
                -46.216429932470426,
                -14.11956948736334
              ],
              [
                -46.23596979374166,
                -14.106798098434462
              ],
              [
                -46.255738639683585,
                -14.103630126458913
              ],
              [
                -46.26535207310245,
                -14.097523154065614
              ],
              [
                -46.25305219920418,
                -14.08661520575839
              ],
              [
                -46.24732172650724,
                -14.093433679722011
              ],
              [
                -46.24669422974911,
                -14.093306690376863
              ],
              [
                -46.241555789670784,
                -14.092269163538107
              ],
              [
                -46.22477344478281,
                -14.069182760133094
              ],
              [
                -46.230657901380965,
                -14.058974362400175
              ],
              [
                -46.23232642332465,
                -14.05608086131815
              ],
              [
                -46.24243532543147,
                -14.059477350873275
              ],
              [
                -46.24798528528113,
                -14.056096394388426
              ],
              [
                -46.22949645603465,
                -14.04557393623988
              ],
              [
                -46.22545998940347,
                -14.039996930072771
              ],
              [
                -46.212330607191106,
                -14.021855515176352
              ],
              [
                -46.20957365133622,
                -14.012411575048688
              ],
              [
                -46.21595010434644,
                -14.005278122100227
              ],
              [
                -46.21663060429161,
                -14.004517165759859
              ],
              [
                -46.21843959233449,
                -14.002040653477849
              ],
              [
                -46.2177696020661,
                -13.995756714352794
              ],
              [
                -46.21603564733868,
                -13.97949480522131
              ],
              [
                -46.22082814788766,
                -13.97240933526185
              ],
              [
                -46.25021790528047,
                -13.95417050344043
              ],
              [
                -46.26770531236891,
                -13.943317595535785
              ],
              [
                -46.26476084190357,
                -13.935532131365662
              ],
              [
                -46.26181639042989,
                -13.92774615721271
              ],
              [
                -46.26173442913679,
                -13.902172826935475
              ],
              [
                -46.23405824613506,
                -13.848548614247807
              ],
              [
                -46.23447872318394,
                -13.84608911650645
              ],
              [
                -46.234899226232734,
                -13.84362913076865
              ],
              [
                -46.24924164966285,
                -13.830312704997853
              ],
              [
                -46.252186128691505,
                -13.830027754710576
              ],
              [
                -46.26913946045054,
                -13.828388256355232
              ],
              [
                -46.27085599039459,
                -13.820767320330264
              ],
              [
                -46.26610551258445,
                -13.81387385997501
              ],
              [
                -46.26215256534413,
                -13.808137366152723
              ],
              [
                -46.272208489104166,
                -13.802019934475023
              ],
              [
                -46.28201994256008,
                -13.79605049449906
              ],
              [
                -46.277670968501944,
                -13.791408480538411
              ],
              [
                -46.27332150244646,
                -13.786766039587889
              ],
              [
                -46.243615278186425,
                -13.778391043584426
              ],
              [
                -46.22825688237119,
                -13.774061530101607
              ],
              [
                -46.22571142814541,
                -13.771548027661398
              ],
              [
                -46.22316494392817,
                -13.769034052224907
              ],
              [
                -46.2367398604692,
                -13.74439071927811
              ],
              [
                -46.2483272956507,
                -13.746887707150018
              ],
              [
                -46.254824232372876,
                -13.748287232889993
              ],
              [
                -46.266462161537476,
                -13.73988679439829
              ],
              [
                -46.26448266252871,
                -13.733570321452051
              ],
              [
                -46.23852141876366,
                -13.716484890702743
              ],
              [
                -46.23829941679309,
                -13.716338402195293
              ],
              [
                -46.23795893168937,
                -13.715106892920542
              ],
              [
                -46.23711393583746,
                -13.71205190160458
              ],
              [
                -46.236823921007414,
                -13.711002902334117
              ],
              [
                -46.23593593677341,
                -13.707791417876049
              ],
              [
                -46.23786592721553,
                -13.700543957125413
              ],
              [
                -46.23796095108883,
                -13.700187485400482
              ],
              [
                -46.25554532484342,
                -13.693455053070188
              ],
              [
                -46.26032629951168,
                -13.686802084608255
              ],
              [
                -46.25336936871371,
                -13.681845108250444
              ],
              [
                -46.242086462378325,
                -13.658200748513764
              ],
              [
                -46.214339759487665,
                -13.631552847062785
              ],
              [
                -46.18725146138779,
                -13.617385891761128
              ],
              [
                -46.16153672467082,
                -13.590491509692955
              ],
              [
                -46.162943708103526,
                -13.587261053289675
              ],
              [
                -46.164351226531046,
                -13.584030587887705
              ],
              [
                -46.18386806532561,
                -13.566624174237463
              ],
              [
                -46.214040329698,
                -13.571815689848844
              ],
              [
                -46.22015777890775,
                -13.56783575678251
              ],
              [
                -46.228067237674686,
                -13.568522237946317
              ],
              [
                -46.235071666454076,
                -13.562470785686834
              ],
              [
                -46.23825915961853,
                -13.554147860550747
              ],
              [
                -46.23423570552411,
                -13.54050140604656
              ],
              [
                -46.24090169331408,
                -13.525097019905193
              ],
              [
                -46.23023479460765,
                -13.516312578414013
              ],
              [
                -46.22637430590508,
                -13.513133061914116
              ],
              [
                -46.218159407100664,
                -13.500073124045027
              ],
              [
                -46.22151191439524,
                -13.497197167775074
              ],
              [
                -46.21992740622175,
                -13.487307715092182
              ],
              [
                -46.222041400723704,
                -13.485959751649183
              ],
              [
                -46.22982136837399,
                -13.480997766185865
              ],
              [
                -46.22535139081284,
                -13.476490779619361
              ],
              [
                -46.21777996757004,
                -13.474510283833284
              ],
              [
                -46.20945953564209,
                -13.472333302979704
              ],
              [
                -46.205379079136385,
                -13.464854332818238
              ],
              [
                -46.213297527156406,
                -13.456720399512422
              ],
              [
                -46.22207198249272,
                -13.447933454520072
              ],
              [
                -46.22532497566859,
                -13.434030036896472
              ],
              [
                -46.23480038360504,
                -13.435532568649894
              ],
              [
                -46.242293822877656,
                -13.436720578996814
              ],
              [
                -46.24245682910377,
                -13.429489101417074
              ],
              [
                -46.221801003847446,
                -13.413154655691764
              ],
              [
                -46.21798704343639,
                -13.410138189391866
              ],
              [
                -46.215961069087214,
                -13.405209220480964
              ],
              [
                -46.213934589742074,
                -13.400278228587114
              ],
              [
                -46.191357818007624,
                -13.390183267593404
              ],
              [
                -46.18067540286453,
                -13.386853746854484
              ],
              [
                -46.1806713769,
                -13.386851776859586
              ],
              [
                -46.169985487796914,
                -13.383521283134462
              ],
              [
                -46.153884112950806,
                -13.383050242373733
              ],
              [
                -46.14922964573633,
                -13.380913260482137
              ],
              [
                -46.14303418012365,
                -13.378012778622843
              ],
              [
                -46.13955073909943,
                -13.377314785055852
              ],
              [
                -46.1332488000288,
                -13.375176765464714
              ],
              [
                -46.129032842129455,
                -13.368500310498359
              ],
              [
                -46.12696084800595,
                -13.365967823253024
              ],
              [
                -46.12554836523718,
                -13.364241324268175
              ],
              [
                -46.12455888184999,
                -13.363821326154204
              ],
              [
                -46.111511968417815,
                -13.358285340840364
              ],
              [
                -46.10189957134144,
                -13.343571420229068
              ],
              [
                -46.094953169977344,
                -13.332939441575853
              ],
              [
                -46.08881269078755,
                -13.331876456867464
              ],
              [
                -46.082672259603406,
                -13.330812446168153
              ],
              [
                -46.056782516876105,
                -13.31406051525457
              ],
              [
                -46.05203503268862,
                -13.311183015756464
              ],
              [
                -46.05203101472496,
                -13.311180534764759
              ],
              [
                -46.051264051641645,
                -13.310715554300995
              ],
              [
                -46.04804606466275,
                -13.308764537748491
              ],
              [
                -46.04375161945013,
                -13.290304147642397
              ],
              [
                -46.04113567813439,
                -13.280447195615821
              ],
              [
                -46.041737695313984,
                -13.27223622996725
              ],
              [
                -46.063381535924925,
                -13.260868851661025
              ],
              [
                -46.112207100297546,
                -13.283847784448156
              ],
              [
                -46.11598154837788,
                -13.283158287766382
              ],
              [
                -46.12249250749577,
                -13.281968793565785
              ],
              [
                -46.127694456424074,
                -13.288763260310985
              ],
              [
                -46.12884943763913,
                -13.290272282148917
              ],
              [
                -46.17187659784251,
                -13.311838223424353
              ],
              [
                -46.204625810223384,
                -13.319868195150438
              ],
              [
                -46.21120075949929,
                -13.31833320015166
              ],
              [
                -46.21777620277712,
                -13.316798236152737
              ],
              [
                -46.237182533694174,
                -13.335789158467687
              ],
              [
                -46.26512726212637,
                -13.34752562337144
              ],
              [
                -46.26912976217931,
                -13.345841157039786
              ],
              [
                -46.271136242669726,
                -13.344996168398751
              ],
              [
                -46.27272320118332,
                -13.345496664025985
              ],
              [
                -46.278958675193266,
                -13.347464134558845
              ],
              [
                -46.2829006439905,
                -13.333422247957545
              ],
              [
                -46.28830364707714,
                -13.314178866865891
              ],
              [
                -46.29737808873195,
                -13.313121869123856
              ],
              [
                -46.30247453722523,
                -13.310200404974358
              ],
              [
                -46.315490942555165,
                -13.30273847299226
              ],
              [
                -46.30094607362571,
                -13.284394538730117
              ],
              [
                -46.29854812786834,
                -13.281370591874555
              ],
              [
                -46.29964213049166,
                -13.274757629223366
              ],
              [
                -46.3005391336399,
                -13.269334629134583
              ],
              [
                -46.30104363285058,
                -13.266282187222727
              ],
              [
                -46.298160649052804,
                -13.253245246499693
              ],
              [
                -46.32037148556718,
                -13.252634284571197
              ],
              [
                -46.32973840657888,
                -13.252376794473072
              ],
              [
                -46.330711422443514,
                -13.249102827650058
              ],
              [
                -46.32185250429633,
                -13.240048863292344
              ],
              [
                -46.32074001607798,
                -13.238911386275865
              ],
              [
                -46.31376958033821,
                -13.224918428611542
              ],
              [
                -46.313225608262734,
                -13.221045438907918
              ],
              [
                -46.31182310143478,
                -13.211050498461532
              ],
              [
                -46.30935865023618,
                -13.209160542752121
              ],
              [
                -46.30406818118724,
                -13.205101537419878
              ],
              [
                -46.30038271976722,
                -13.193171618877432
              ],
              [
                -46.30260575140528,
                -13.181811174586203
              ],
              [
                -46.30571469162013,
                -13.180681182428541
              ],
              [
                -46.31404614756727,
                -13.177652202166756
              ],
              [
                -46.3035232386761,
                -13.166752258352313
              ],
              [
                -46.30938021575385,
                -13.157545323092416
              ],
              [
                -46.29950631705351,
                -13.153504858251916
              ],
              [
                -46.29319536557718,
                -13.15092234947501
              ],
              [
                -46.29164788479094,
                -13.147428875916134
              ],
              [
                -46.294548378759806,
                -13.13087195497908
              ],
              [
                -46.29830584097473,
                -13.125507516276055
              ],
              [
                -46.30174234130907,
                -13.124539031668391
              ],
              [
                -46.30829128140296,
                -13.122693044380084
              ],
              [
                -46.31302725415494,
                -13.10945161953385
              ],
              [
                -46.31351427958467,
                -13.108090635492648
              ],
              [
                -46.31787523159202,
                -13.102970658296806
              ],
              [
                -46.32223620659926,
                -13.097850710099495
              ],
              [
                -46.30439937288116,
                -13.07909279384663
              ],
              [
                -46.30114590248396,
                -13.075671828134123
              ],
              [
                -46.295892490819575,
                -13.064019871657306
              ],
              [
                -46.295890473839805,
                -13.064017382669013
              ],
              [
                -46.295656950567604,
                -13.063498375410962
              ],
              [
                -46.290869040447134,
                -13.052880929501345
              ],
              [
                -46.289636546167245,
                -13.051732432416678
              ],
              [
                -46.28026812925479,
                -13.04300046980957
              ],
              [
                -46.280876149646794,
                -13.034523028785923
              ],
              [
                -46.280938638611914,
                -13.033651544129617
              ],
              [
                -46.27991114049038,
                -13.032628523628
              ],
              [
                -46.27515119225649,
                -13.027886576489392
              ],
              [
                -46.27444520304336,
                -13.023603090129829
              ],
              [
                -46.27294873514143,
                -13.014521665381091
              ],
              [
                -46.25286489861233,
                -13.005625164643305
              ],
              [
                -46.245097966792756,
                -12.996978219252824
              ],
              [
                -46.240343534862745,
                -12.999204196201706
              ],
              [
                -46.23987453522384,
                -12.999423676125707
              ],
              [
                -46.239414539297876,
                -12.999173702892953
              ],
              [
                -46.2318705801223,
                -12.995068706514392
              ],
              [
                -46.22680061727052,
                -12.998755181152283
              ],
              [
                -46.220015185976145,
                -13.003689620510263
              ],
              [
                -46.2121522350823,
                -13.002742633587802
              ],
              [
                -46.19657684367406,
                -12.996323128289909
              ],
              [
                -46.19342039833487,
                -12.992395638914816
              ],
              [
                -46.190986440748304,
                -12.989366677282987
              ],
              [
                -46.17674556059725,
                -12.984233678422513
              ],
              [
                -46.17368855475631,
                -12.98313167317419
              ],
              [
                -46.17286108867151,
                -12.9823326926708
              ],
              [
                -46.16582265000881,
                -12.9755291994679
              ],
              [
                -46.159415206438204,
                -12.975864730180911
              ],
              [
                -46.154156712828716,
                -12.976140207097057
              ],
              [
                -46.14287032598486,
                -12.968133729395369
              ],
              [
                -46.13846437125503,
                -12.965008227232161
              ],
              [
                -46.13193992348977,
                -12.963026244775957
              ],
              [
                -46.125414994732495,
                -12.961044243324089
              ],
              [
                -46.1133706088649,
                -12.931379392492069
              ],
              [
                -46.11388862666913,
                -12.917537000079117
              ],
              [
                -46.125266560306976,
                -12.909550043534992
              ],
              [
                -46.138535444677096,
                -12.908956082258765
              ],
              [
                -46.18396458835473,
                -12.92337654548762
              ],
              [
                -46.19770695859122,
                -12.924870578394142
              ],
              [
                -46.22008827631836,
                -12.94012600603104
              ],
              [
                -46.26632142558544,
                -12.93191814248547
              ],
              [
                -46.275238828955956,
                -12.9348066193296
              ],
              [
                -46.27678030379548,
                -12.937763609966057
              ],
              [
                -46.279268799090076,
                -12.942537077169739
              ],
              [
                -46.30435760037893,
                -12.949443064736302
              ],
              [
                -46.29198922502121,
                -12.919118734716692
              ],
              [
                -46.294025213924506,
                -12.914261793259813
              ],
              [
                -46.297129675577295,
                -12.906854307893083
              ],
              [
                -46.28978775180536,
                -12.899053349227813
              ],
              [
                -46.28577028877931,
                -12.89478435857489
              ],
              [
                -46.28410233777574,
                -12.87802995903544
              ],
              [
                -46.29229179426968,
                -12.858984083062962
              ],
              [
                -46.272908965403516,
                -12.846716656338964
              ],
              [
                -46.267394560952546,
                -12.838979676334
              ],
              [
                -46.26188059249011,
                -12.831243242345765
              ],
              [
                -46.27201452543551,
                -12.8115673793834
              ],
              [
                -46.274004517042314,
                -12.810106392371695
              ],
              [
                -46.2804174738812,
                -12.805399936992602
              ],
              [
                -46.27765102397047,
                -12.792547494341733
              ],
              [
                -46.282495493065696,
                -12.783693042568975
              ],
              [
                -46.276080569146295,
                -12.77628058027387
              ],
              [
                -46.27456156708378,
                -12.774524603499312
              ],
              [
                -46.27554606952695,
                -12.767051157285053
              ],
              [
                -46.27655260306585,
                -12.759408209137309
              ],
              [
                -46.2793480633244,
                -12.751811754536597
              ],
              [
                -46.27990155839477,
                -12.75030776651527
              ],
              [
                -46.272838654843184,
                -12.743955768838424
              ],
              [
                -46.26713470150354,
                -12.738826792912276
              ],
              [
                -46.264491732926636,
                -12.716518919915035
              ],
              [
                -46.27624067087461,
                -12.705374509933794
              ],
              [
                -46.28626208425288,
                -12.704829541991606
              ],
              [
                -46.28578611893135,
                -12.698144089757829
              ],
              [
                -46.29282154150833,
                -12.692385119714173
              ],
              [
                -46.28425816134148,
                -12.678936174795576
              ],
              [
                -46.275389707246816,
                -12.67368222574542
              ],
              [
                -46.26514131643477,
                -12.667610232513116
              ],
              [
                -46.25639639619047,
                -12.655691293146122
              ],
              [
                -46.261008382369134,
                -12.645970373371076
              ],
              [
                -46.27113030007323,
                -12.642880397961582
              ],
              [
                -46.27318379631483,
                -12.637619414107213
              ],
              [
                -46.289768666967134,
                -12.631327483228398
              ],
              [
                -46.272775371913355,
                -12.593528187242738
              ],
              [
                -46.27520332792547,
                -12.59026571084938
              ],
              [
                -46.27951479208752,
                -12.584471261774803
              ],
              [
                -46.265908944268276,
                -12.571906826966805
              ],
              [
                -46.25477559025894,
                -12.546644439319335
              ],
              [
                -46.22600179107547,
                -12.537611464670702
              ],
              [
                -46.21364642136519,
                -12.533731955150555
              ],
              [
                -46.20129251765756,
                -12.529852474650713
              ],
              [
                -46.19431355552902,
                -12.529563485605813
              ],
              [
                -46.16023633742427,
                -12.528154434456335
              ],
              [
                -46.15688490715161,
                -12.52247345892182
              ],
              [
                -46.156224900191425,
                -12.521351955742267
              ],
              [
                -46.1515549399785,
                -12.513428496863629
              ],
              [
                -46.16162437826249,
                -12.495126635092667
              ],
              [
                -46.153544465138246,
                -12.482796212897844
              ],
              [
                -46.158733453505086,
                -12.474911736033587
              ],
              [
                -46.18260772186574,
                -12.476824284149581
              ],
              [
                -46.22740534559204,
                -12.497913218679349
              ],
              [
                -46.245192224732214,
                -12.495258248346511
              ],
              [
                -46.2476281939943,
                -12.494893270335142
              ],
              [
                -46.25099666708522,
                -12.494388266618078
              ],
              [
                -46.25433263323958,
                -12.492756299715937
              ],
              [
                -46.25315419828756,
                -12.459786956816039
              ],
              [
                -46.24973224969709,
                -12.452095534438367
              ],
              [
                -46.24735078211999,
                -12.446742034418131
              ],
              [
                -46.248552791811704,
                -12.436561101366182
              ],
              [
                -46.256004254871414,
                -12.423908226013673
              ],
              [
                -46.264520682919816,
                -12.4122362973117
              ],
              [
                -46.27340711568653,
                -12.41399280824777
              ],
              [
                -46.282990550876434,
                -12.410758801698595
              ],
              [
                -46.285179500134355,
                -12.412264819876688
              ],
              [
                -46.29242194681453,
                -12.417248289619188
              ],
              [
                -46.30059789805473,
                -12.415350307761296
              ],
              [
                -46.30427089224947,
                -12.405108373851395
              ],
              [
                -46.295492953658986,
                -12.396383935578081
              ],
              [
                -46.293901518480645,
                -12.384283985953394
              ],
              [
                -46.289571538275496,
                -12.381454995576794
              ],
              [
                -46.28524156207162,
                -12.378625519207787
              ],
              [
                -46.312466376382346,
                -12.355927202435938
              ],
              [
                -46.31838135972571,
                -12.355810715200608
              ],
              [
                -46.32429830705877,
                -12.355693712972315
              ],
              [
                -46.33247925584899,
                -12.343970788023677
              ],
              [
                -46.34000071902867,
                -12.336563842725415
              ],
              [
                -46.35196410369046,
                -12.33697638450289
              ],
              [
                -46.351420133683725,
                -12.32642395005667
              ],
              [
                -46.351106641745126,
                -12.320361483574711
              ],
              [
                -46.345829661860435,
                -12.314438487669774
              ],
              [
                -46.342136699862465,
                -12.313314015897616
              ],
              [
                -46.32473887693231,
                -12.308017034700276
              ],
              [
                -46.32405986057797,
                -12.302138533572855
              ],
              [
                -46.32800285337245,
                -12.298327561863157
              ],
              [
                -46.335892302259225,
                -12.296847080930283
              ],
              [
                -46.34405620257137,
                -12.296896106074112
              ],
              [
                -46.34999416552586,
                -12.296932107905993
              ],
              [
                -46.372457508979814,
                -12.289846676407862
              ],
              [
                -46.37460247840254,
                -12.286427685548553
              ],
              [
                -46.36956105699718,
                -12.279623738409672
              ],
              [
                -46.36641105061002,
                -12.275370761585027
              ],
              [
                -46.36571759050521,
                -12.266212815464916
              ],
              [
                -46.37173905887504,
                -12.258583356227891
              ],
              [
                -46.38000999093434,
                -12.248102948823217
              ],
              [
                -46.373041585574015,
                -12.244579466765531
              ],
              [
                -46.36607312421714,
                -12.241055985716788
              ],
              [
                -46.359818704424946,
                -12.22464205457125
              ],
              [
                -46.366201153758944,
                -12.216462629260288
              ],
              [
                -46.367884169825864,
                -12.214306125997295
              ],
              [
                -46.36799864413243,
                -12.209060193251961
              ],
              [
                -46.36820866762148,
                -12.199375718804383
              ],
              [
                -46.37664508740914,
                -12.197140264242975
              ],
              [
                -46.39056849810401,
                -12.193451314870332
              ],
              [
                -46.385007556312765,
                -12.18561332939665
              ],
              [
                -46.38348408571072,
                -12.183465840229884
              ],
              [
                -46.380297591912644,
                -12.178975878882984
              ],
              [
                -46.3836320678009,
                -12.17433241933103
              ],
              [
                -46.38403756947489,
                -12.173768920391284
              ],
              [
                -46.38927503944836,
                -12.166479451902307
              ],
              [
                -46.380243125133525,
                -12.161161481815844
              ],
              [
                -46.37888066215475,
                -12.160358997672102
              ],
              [
                -46.36963025188837,
                -12.13427413245673
              ],
              [
                -46.37024123792955,
                -12.12792318128162
              ],
              [
                -46.37029223435694,
                -12.127388696632806
              ],
              [
                -46.37080324352102,
                -12.122107711756415
              ],
              [
                -46.365942809453806,
                -12.110827278578535
              ],
              [
                -46.36814130378361,
                -12.098116334795398
              ],
              [
                -46.37776174109784,
                -12.094660884414926
              ],
              [
                -46.385187664545555,
                -12.091992934911012
              ],
              [
                -46.38369569468709,
                -12.08853842484746
              ],
              [
                -46.381078706780535,
                -12.082459469189876
              ],
              [
                -46.38801767294247,
                -12.072123046964645
              ],
              [
                -46.38269375958745,
                -12.065282089942452
              ],
              [
                -46.37996225559587,
                -12.061772106382243
              ],
              [
                -46.37999077660458,
                -12.06162061635416
              ],
              [
                -46.3816752542687,
                -12.052572163381388
              ],
              [
                -46.3906877022308,
                -12.045335696251893
              ],
              [
                -46.39743666827513,
                -12.03991723758034
              ],
              [
                -46.39621518976283,
                -12.032888313879432
              ],
              [
                -46.39499419023973,
                -12.025859321188408
              ],
              [
                -46.38384377783887,
                -12.020331370479974
              ],
              [
                -46.37318788216094,
                -12.0150488550242
              ],
              [
                -46.373147915049636,
                -12.009474890196076
              ],
              [
                -46.37310691994455,
                -12.003899433379788
              ],
              [
                -46.35576405606597,
                -11.98104906599399
              ],
              [
                -46.34919462463813,
                -11.978014054892821
              ],
              [
                -46.342624679217394,
                -11.974979073798039
              ],
              [
                -46.32527587025631,
                -11.958062153133378
              ],
              [
                -46.28733766480585,
                -11.948558158453977
              ],
              [
                -46.247638489167045,
                -11.94710460967494
              ],
              [
                -46.21008577960246,
                -11.92997266487897
              ],
              [
                -46.20110935938405,
                -11.929522682410918
              ],
              [
                -46.192133460171554,
                -11.929072643947276
              ],
              [
                -46.17468759698595,
                -11.909600753510665
              ],
              [
                -46.17084114917816,
                -11.900563805697962
              ],
              [
                -46.179744611989264,
                -11.886477400859818
              ],
              [
                -46.18894854962662,
                -11.883474945944343
              ],
              [
                -46.201127929828786,
                -11.887652953027754
              ],
              [
                -46.20705986486901,
                -11.892916918230835
              ],
              [
                -46.212991805907244,
                -11.898181357443189
              ],
              [
                -46.237708625146745,
                -11.902183396130722
              ],
              [
                -46.26494892960283,
                -11.894264490126956
              ],
              [
                -46.274760822619825,
                -11.889891500558818
              ],
              [
                -46.28457178665537,
                -11.885519036980833
              ],
              [
                -46.31061403167291,
                -11.895426515255176
              ],
              [
                -46.31754746784903,
                -11.898064036222129
              ],
              [
                -46.32191943964429,
                -11.897172522164716
              ],
              [
                -46.32629244043394,
                -11.896281058108242
              ],
              [
                -46.35085223786114,
                -11.882132156502456
              ],
              [
                -46.36504761585335,
                -11.884019166804523
              ],
              [
                -46.3741560719807,
                -11.868177768807099
              ],
              [
                -46.37091164006103,
                -11.854034865172009
              ],
              [
                -46.37299211957721,
                -11.836249453852544
              ],
              [
                -46.368075674365635,
                -11.834290996705843
              ],
              [
                -46.35968473233794,
                -11.839111461660577
              ],
              [
                -46.3549642795178,
                -11.841822936007413
              ],
              [
                -46.34709884917885,
                -11.835526933284099
              ],
              [
                -46.350482320253,
                -11.825740019618609
              ],
              [
                -46.35123281460506,
                -11.82357001010603
              ],
              [
                -46.34498937810513,
                -11.820141044105839
              ],
              [
                -46.33746644977589,
                -11.816010032571164
              ],
              [
                -46.33432650375352,
                -11.809272597556827
              ],
              [
                -46.33118853070713,
                -11.802536649547672
              ],
              [
                -46.33271500008549,
                -11.796276157436926
              ],
              [
                -46.335131003927074,
                -11.786367249161122
              ],
              [
                -46.33108353579346,
                -11.783118759309634
              ],
              [
                -46.32427358882278,
                -11.777652266131414
              ],
              [
                -46.32229312349692,
                -11.770320307544088
              ],
              [
                -46.338664994483075,
                -11.76611436342497
              ],
              [
                -46.34696046198143,
                -11.753457450725808
              ],
              [
                -46.35479787502588,
                -11.756162939440348
              ],
              [
                -46.367307806758895,
                -11.760482435013644
              ],
              [
                -46.373041237169176,
                -11.750717995680327
              ],
              [
                -46.36574580586303,
                -11.745323515364076
              ],
              [
                -46.32894013018556,
                -11.736879022945242
              ],
              [
                -46.30854630602394,
                -11.703648728892219
              ],
              [
                -46.30625784041442,
                -11.702102707142584
              ],
              [
                -46.29496992699686,
                -11.69447423000841
              ],
              [
                -46.294462972176994,
                -11.678261864694226
              ],
              [
                -46.30735741333221,
                -11.65338302248968
              ],
              [
                -46.30808288895141,
                -11.652475028164453
              ],
              [
                -46.31467335815463,
                -11.644226073801534
              ],
              [
                -46.31453085968573,
                -11.631832146413423
              ],
              [
                -46.30419147456204,
                -11.624950701171198
              ],
              [
                -46.29104804642299,
                -11.6283041494324
              ],
              [
                -46.2769331930119,
                -11.622869682809347
              ],
              [
                -46.265346796106456,
                -11.623866155940867
              ],
              [
                -46.255850373786785,
                -11.62075116760309
              ],
              [
                -46.22132109592853,
                -11.644301955667567
              ],
              [
                -46.18442186250028,
                -11.6553578458224
              ],
              [
                -46.179706408539495,
                -11.653528367390232
              ],
              [
                -46.17499244356803,
                -11.651699346960498
              ],
              [
                -46.16940249294064,
                -11.652967844024278
              ],
              [
                -46.15357162696396,
                -11.656559785891867
              ],
              [
                -46.143416696812515,
                -11.651551823394497
              ],
              [
                -46.133262302662914,
                -11.64654433291357
              ],
              [
                -46.130966295167056,
                -11.646330848937174
              ],
              [
                -46.0865696740814,
                -11.642212289714797
              ],
              [
                -46.0827687108383,
                -11.635702857780512
              ],
              [
                -46.08596169270958,
                -11.627458375703103
              ],
              [
                -46.087092676058006,
                -11.624537912513325
              ],
              [
                -46.08633319083466,
                -11.621993938266654
              ],
              [
                -46.08624971090899,
                -11.621714439887604
              ],
              [
                -46.090642193473535,
                -11.61806944489005
              ],
              [
                -46.12663491725927,
                -11.603854591018536
              ],
              [
                -46.14074377377424,
                -11.60774458304026
              ],
              [
                -46.156647160114225,
                -11.606885096205632
              ],
              [
                -46.16597160198677,
                -11.598964181860746
              ],
              [
                -46.18662443155652,
                -11.605716654497979
              ],
              [
                -46.20234682339159,
                -11.602170195117807
              ],
              [
                -46.213062225868434,
                -11.595233774616249
              ],
              [
                -46.21788269922149,
                -11.580675355035282
              ],
              [
                -46.23585609331952,
                -11.564916972760289
              ],
              [
                -46.25840691870913,
                -11.556330054427553
              ],
              [
                -46.26415589980032,
                -11.556653559647277
              ],
              [
                -46.26990635688389,
                -11.556977058870531
              ],
              [
                -46.28945967930177,
                -11.550936630398736
              ],
              [
                -46.31302550566413,
                -11.539068742850997
              ],
              [
                -46.34582523982467,
                -11.54445423137201
              ],
              [
                -46.352729193164045,
                -11.539824306995513
              ],
              [
                -46.35963313350848,
                -11.535194328615642
              ],
              [
                -46.39219637582212,
                -11.534407368131724
              ],
              [
                -46.398844817738066,
                -11.538927346627466
              ],
              [
                -46.40143781469592,
                -11.540689324419022
              ],
              [
                -46.41090522381562,
                -11.541530345778376
              ],
              [
                -46.42660963146567,
                -11.525628961857697
              ],
              [
                -46.445105484498896,
                -11.514237076957176
              ],
              [
                -46.45788640623183,
                -11.513956084995964
              ],
              [
                -46.459589401785436,
                -11.514657590087342
              ],
              [
                -46.468048801026335,
                -11.518143581604692
              ],
              [
                -46.47888572088876,
                -11.515971079586885
              ],
              [
                -46.484365718838404,
                -11.507076670528873
              ],
              [
                -46.48584919570234,
                -11.504669200565703
              ],
              [
                -46.49560211692631,
                -11.501271231572945
              ],
              [
                -46.50182358414539,
                -11.499104220898612
              ],
              [
                -46.51963496447357,
                -11.483129353313812
              ],
              [
                -46.52302344568704,
                -11.474366921428205
              ],
              [
                -46.5264119368924,
                -11.465604979542691
              ],
              [
                -46.52527597768555,
                -11.441824111278784
              ],
              [
                -46.5248259890308,
                -11.432390685112923
              ],
              [
                -46.5262859710818,
                -11.42723870713106
              ],
              [
                -46.52774648812638,
                -11.42208624515467
              ],
              [
                -46.54845981885258,
                -11.407978375073412
              ],
              [
                -46.548880360909365,
                -11.380299532319398
              ],
              [
                -46.55535283731637,
                -11.377356056754046
              ],
              [
                -46.56182525972896,
                -11.374412593186102
              ],
              [
                -46.5858805808682,
                -11.366056672097477
              ],
              [
                -46.59356405054271,
                -11.356411240790328
              ],
              [
                -46.60124799421164,
                -11.346766291476332
              ],
              [
                -46.60495498389801,
                -11.32141546395882
              ],
              [
                -46.609496965821286,
                -11.307897571243334
              ],
              [
                -46.615517456408014,
                -11.301781591685337
              ],
              [
                -46.616667928819865,
                -11.289032165431156
              ],
              [
                -46.608729022981386,
                -11.284302687612394
              ],
              [
                -46.599225637456975,
                -11.265001801113133
              ],
              [
                -46.609202036822424,
                -11.25550439112508
              ],
              [
                -46.59657014403734,
                -11.251310389452867
              ],
              [
                -46.581634292462105,
                -11.246351387325067
              ],
              [
                -46.49988146706594,
                -11.206513551946847
              ],
              [
                -46.49935499432149,
                -11.20643253771718
              ],
              [
                -46.48770958545387,
                -11.204645564611472
              ],
              [
                -46.47193219738922,
                -11.190765133179031
              ],
              [
                -46.45295042660332,
                -11.155774827168424
              ],
              [
                -46.44605854876869,
                -11.089550210488028
              ],
              [
                -46.421314328642126,
                -11.035508014700397
              ],
              [
                -46.41351139244711,
                -11.027391557849299
              ],
              [
                -46.40570845524685,
                -11.019275617020702
              ],
              [
                -46.403552976832685,
                -11.01170364653654
              ],
              [
                -46.39850653638533,
                -10.993976244801813
              ],
              [
                -46.388005630500594,
                -10.98721030390469
              ],
              [
                -46.37750621360675,
                -10.980445338029677
              ],
              [
                -46.360112874204006,
                -10.961348930232344
              ],
              [
                -46.32829414646256,
                -10.942204504277539
              ],
              [
                -46.32260973568827,
                -10.934692037802447
              ],
              [
                -46.31692577390459,
                -10.927179588346462
              ],
              [
                -46.30426988941474,
                -10.919404597114685
              ],
              [
                -46.28299456520422,
                -10.906334678598478
              ],
              [
                -46.28304908117002,
                -10.901715718763727
              ],
              [
                -46.283103091135565,
                -10.897097733924863
              ],
              [
                -46.284948066175154,
                -10.8930632758325
              ],
              [
                -46.29531552980191,
                -10.870389924676825
              ],
              [
                -46.292438065274936,
                -10.858937989969553
              ],
              [
                -46.28956057772973,
                -10.847486071287568
              ],
              [
                -46.27546324722461,
                -10.82320069751625
              ],
              [
                -46.23343360839599,
                -10.804368746066157
              ],
              [
                -46.23126360058481,
                -10.79920178880537
              ],
              [
                -46.22909314277436,
                -10.794034308553655
              ],
              [
                -46.22719269455134,
                -10.766379981822693
              ],
              [
                -46.23847011076283,
                -10.764084034384737
              ],
              [
                -46.24953501060766,
                -10.76183101938949
              ],
              [
                -46.25100351608668,
                -10.756660059034937
              ],
              [
                -46.216382296643,
                -10.731992695958137
              ],
              [
                -46.204259910884055,
                -10.725640220057445
              ],
              [
                -46.19213802812961,
                -10.719288265182003
              ],
              [
                -46.18764860261226,
                -10.68455297831709
              ],
              [
                -46.20735745653154,
                -10.661344118490428
              ],
              [
                -46.20895245211312,
                -10.655299156918643
              ],
              [
                -46.210548448682,
                -10.649254717347377
              ],
              [
                -46.18876464456031,
                -10.631367799090617
              ],
              [
                -46.187463689879095,
                -10.626120813674513
              ],
              [
                -46.16880683384418,
                -10.619575864680275
              ],
              [
                -46.16190987709337,
                -10.62490882674972
              ],
              [
                -46.15593890800287,
                -10.629525269582963
              ],
              [
                -46.1369065844964,
                -10.621869782179692
              ],
              [
                -46.1258826607458,
                -10.620211294232348
              ],
              [
                -46.11485875500908,
                -10.61855280929291
              ],
              [
                -46.09550243783025,
                -10.60440837082812
              ],
              [
                -46.076626071879325,
                -10.606693302547427
              ],
              [
                -46.06573464598507,
                -10.602920833279745
              ],
              [
                -46.05905124100401,
                -10.593049394362003
              ],
              [
                -46.059221236254146,
                -10.585123437046907
              ],
              [
                -46.059458263129365,
                -10.574078513690313
              ],
              [
                -46.02735151376157,
                -10.566678535918888
              ],
              [
                -46.0246030668535,
                -10.542364673345094
              ],
              [
                -46.019874107401215,
                -10.539233184161862
              ],
              [
                -46.01514613594115,
                -10.53610220298215
              ],
              [
                -46.00501470214784,
                -10.536029175271668
              ],
              [
                -45.993900787147005,
                -10.535949188330946
              ],
              [
                -45.98821286383824,
                -10.53240617254979
              ],
              [
                -45.98371189816059,
                -10.529602726596602
              ],
              [
                -45.98091893966584,
                -10.529292206950759
              ],
              [
                -45.970765005495274,
                -10.528161182984231
              ],
              [
                -45.961662084514636,
                -10.509293288688367
              ],
              [
                -45.90626757354716,
                -10.473271462402906
              ],
              [
                -45.90326510777395,
                -10.466853013614895
              ],
              [
                -45.90026365898669,
                -10.46043506283535
              ],
              [
                -45.8875872572712,
                -10.44910461307561
              ],
              [
                -45.87598285917046,
                -10.44378662421931
              ],
              [
                -45.86437897107629,
                -10.43846814638878
              ],
              [
                -45.84399609909334,
                -10.439611122872035
              ],
              [
                -45.83388371450038,
                -10.440178605242691
              ],
              [
                -45.830591233636504,
                -10.437861591875244
              ],
              [
                -45.827298727772984,
                -10.435544640510221
              ],
              [
                -45.81968533168718,
                -10.409896287337078
              ],
              [
                -45.82506079268124,
                -10.401775843346343
              ],
              [
                -45.8304372906654,
                -10.39365438936214
              ],
              [
                -45.82897781065473,
                -10.375686523874297
              ],
              [
                -45.82663781679594,
                -10.370621026423805
              ],
              [
                -45.82429835692894,
                -10.365556074976864
              ],
              [
                -45.792481616493674,
                -10.352222604140552
              ],
              [
                -45.750709445693545,
                -10.351564575230366
              ],
              [
                -45.74436450122266,
                -10.346798102840546
              ],
              [
                -45.738020557744974,
                -10.342031121466603
              ],
              [
                -45.72363172857526,
                -10.316809762652273
              ],
              [
                -45.714080836597475,
                -10.28407746955595
              ],
              [
                -45.707595878504485,
                -10.273639043695194
              ],
              [
                -45.705186433616696,
                -10.269761037673938
              ],
              [
                -45.697110489217714,
                -10.263154579123961
              ],
              [
                -45.69772102801192,
                -10.215034406567462
              ],
              [
                -45.705496014971125,
                -10.187865569831402
              ],
              [
                -45.69885907991835,
                -10.166440206027332
              ],
              [
                -45.70458402946178,
                -10.16645721706198
              ],
              [
                -45.71543297533095,
                -10.166489736393341
              ],
              [
                -45.71695994041489,
                -10.16397375656142
              ],
              [
                -45.722469905237816,
                -10.154895801114307
              ],
              [
                -45.71582595536787,
                -10.152204832226715
              ],
              [
                -45.70348707072247,
                -10.156578296565916
              ],
              [
                -45.69556964448248,
                -10.155062784496199
              ],
              [
                -45.68615721847886,
                -10.153261798415054
              ],
              [
                -45.66888536513876,
                -10.144953318665133
              ],
              [
                -45.64834453808559,
                -10.126923426859614
              ],
              [
                -45.64358304961853,
                -10.126357424612811
              ],
              [
                -45.63882112415766,
                -10.125791421366667
              ],
              [
                -45.63748911758909,
                -10.12511841007293
              ],
              [
                -45.60276490963739,
                -10.10757448672887
              ],
              [
                -45.59280997883924,
                -10.109887970396466
              ],
              [
                -45.578932597472836,
                -10.121523847734222
              ],
              [
                -45.56704064980831,
                -10.139110739923549
              ],
              [
                -45.56602817891856,
                -10.149339161340595
              ],
              [
                -45.56501567701518,
                -10.159568125765157
              ],
              [
                -45.57042706755814,
                -10.184328953037378
              ],
              [
                -45.566848095648695,
                -10.201137328688345
              ],
              [
                -45.55615217318022,
                -10.216489228892456
              ],
              [
                -45.52997586947676,
                -10.23374109340325
              ],
              [
                -45.525878363645,
                -10.23644155378116
              ],
              [
                -45.524157892772834,
                -10.241585546307892
              ],
              [
                -45.52243690390353,
                -10.246728500842014
              ],
              [
                -45.52226090200776,
                -10.257130425260552
              ],
              [
                -45.521937366115615,
                -10.27624232012547
              ],
              [
                -45.519325386570905,
                -10.281188765825796
              ],
              [
                -45.51671389102057,
                -10.286135245526651
              ],
              [
                -45.49553405034621,
                -10.298094140468752
              ],
              [
                -45.49068210347642,
                -10.30083459162075
              ],
              [
                -45.48176464547114,
                -10.307878544791874
              ],
              [
                -45.47284871145959,
                -10.314922998954893
              ],
              [
                -45.4564048086202,
                -10.338156309818547
              ],
              [
                -45.429904015970955,
                -10.359055665051907
              ],
              [
                -45.42785702094952,
                -10.366298097584245
              ],
              [
                -45.42581054292023,
                -10.37353904813022
              ],
              [
                -45.4274154873462,
                -10.38651398720666
              ],
              [
                -45.42951547461448,
                -10.403491891049088
              ],
              [
                -45.426797975648526,
                -10.414784289571106
              ],
              [
                -45.42407998267149,
                -10.426077203098561
              ],
              [
                -45.414561051619536,
                -10.433000646221045
              ],
              [
                -45.39601918805247,
                -10.446485568416234
              ],
              [
                -45.39931065578935,
                -10.45570149103209
              ],
              [
                -45.40260211151424,
                -10.464917967662993
              ],
              [
                -45.430667831493395,
                -10.487956830727592
              ],
              [
                -45.44683921156146,
                -10.507007222501748
              ],
              [
                -45.4423007189042,
                -10.516057642288942
              ],
              [
                -45.43776326123322,
                -10.525107581081887
              ],
              [
                -45.4399542275541,
                -10.541250482788865
              ],
              [
                -45.443434179144205,
                -10.548586941967562
              ],
              [
                -45.44691414672789,
                -10.555922900162994
              ],
              [
                -45.436420705639335,
                -10.576272275482637
              ],
              [
                -45.43550167389293,
                -10.619460480109781
              ],
              [
                -45.43146767305673,
                -10.62703242912969
              ],
              [
                -45.427433700215595,
                -10.634604897146865
              ],
              [
                -45.412384810716816,
                -10.644131305282164
              ],
              [
                -45.39671593389473,
                -10.647856743947559
              ],
              [
                -45.38798497987338,
                -10.649932747240147
              ],
              [
                -45.37447858662867,
                -10.667355624487332
              ],
              [
                -45.35780211898964,
                -10.731791159011653
              ],
              [
                -45.32195539206,
                -10.75984945357822
              ],
              [
                -45.28890466027466,
                -10.76523435962498
              ],
              [
                -45.28013719548478,
                -10.769681833508821
              ],
              [
                -45.27137129169265,
                -10.774128278396553
              ],
              [
                -45.260121326543974,
                -10.803344571576638
              ],
              [
                -45.25706833833043,
                -10.811273025543352
              ],
              [
                -45.252351862187716,
                -10.816412005273328
              ],
              [
                -45.24763491204938,
                -10.82155096300104
              ],
              [
                -45.23528851058656,
                -10.819589437383947
              ],
              [
                -45.21880465117779,
                -10.816969938574768
              ],
              [
                -45.21739015850596,
                -10.822921410397361
              ],
              [
                -45.21597666882155,
                -10.828873372220784
              ],
              [
                -45.16263609122165,
                -10.837215270326231
              ],
              [
                -45.158006602378805,
                -10.837939236572417
              ],
              [
                -45.158001095422286,
                -10.837939736561953
              ],
              [
                -45.153377669534194,
                -10.838663238818947
              ],
              [
                -45.150570674880214,
                -10.838790246308632
              ],
              [
                -45.116103959338176,
                -10.840354675905122
              ],
              [
                -45.11049346930432,
                -10.843377674036992
              ],
              [
                -45.10488250327792,
                -10.846400629166473
              ],
              [
                -45.090446147390075,
                -10.841041626754683
              ],
              [
                -45.08645665841462,
                -10.8395611660641
              ],
              [
                -45.079638234870664,
                -10.839782637678718
              ],
              [
                -45.07282127331941,
                -10.840005117289577
              ],
              [
                -45.030276101864665,
                -10.866235401080605
              ],
              [
                -45.01618171398405,
                -10.88418177969462
              ],
              [
                -45.0017177830209,
                -10.886601252026907
              ],
              [
                -45.000014830727295,
                -10.885850733629255
              ],
              [
                -44.994632860207005,
                -10.88347827686007
              ],
              [
                -44.97348551269365,
                -10.892838667569576
              ],
              [
                -44.9308063292605,
                -10.928338917020778
              ],
              [
                -44.909571525943086,
                -10.911536980569691
              ],
              [
                -44.900791098268485,
                -10.909603969497311
              ],
              [
                -44.900767603465134,
                -10.909598483501805
              ],
              [
                -44.9007610985357,
                -10.909584976580549
              ],
              [
                -44.89703363808049,
                -10.902522037336302
              ],
              [
                -44.88463973172931,
                -10.901922005906034
              ],
              [
                -44.88401674078881,
                -10.901891990280431
              ],
              [
                -44.88358373272794,
                -10.901557493874062
              ],
              [
                -44.86324589873899,
                -10.885849598767752
              ],
              [
                -44.85504200512411,
                -10.884161092924277
              ],
              [
                -44.85462047763772,
                -10.884074584930863
              ],
              [
                -44.85262548777417,
                -10.890726536252254
              ],
              [
                -44.851158489018935,
                -10.89561801866445
              ],
              [
                -44.845441536110975,
                -10.899326979144625
              ],
              [
                -44.83118468095266,
                -10.884522044271431
              ],
              [
                -44.82306174877239,
                -10.87608711325087
              ],
              [
                -44.82246275043705,
                -10.875465089495226
              ],
              [
                -44.81853031160104,
                -10.873635607192972
              ],
              [
                -44.81274935582447,
                -10.87094761103434
              ],
              [
                -44.808031871392615,
                -10.864589676059957
              ],
              [
                -44.80991039491075,
                -10.846167263942808
              ],
              [
                -44.78764911926122,
                -10.808103489839143
              ],
              [
                -44.78270364814253,
                -10.805857998977896
              ],
              [
                -44.77775770303009,
                -10.803612008123162
              ],
              [
                -44.758508351318746,
                -10.80437498212287
              ],
              [
                -44.75747939760464,
                -10.792359069842192
              ],
              [
                -44.75708541290132,
                -10.78775612423716
              ],
              [
                -44.75079847166297,
                -10.781710122363412
              ],
              [
                -44.713003278359146,
                -10.761628206956544
              ],
              [
                -44.69990086341519,
                -10.765207172705363
              ],
              [
                -44.68679898248807,
                -10.768786646446603
              ],
              [
                -44.667273139327335,
                -10.759639182732343
              ],
              [
                -44.66237319246556,
                -10.75014623122559
              ],
              [
                -44.66090923188436,
                -10.73227633775978
              ],
              [
                -44.66165423195363,
                -10.728417864864483
              ],
              [
                -44.66866221812754,
                -10.692112616549815
              ],
              [
                -44.66730722672462,
                -10.685525174766967
              ],
              [
                -44.65972029247398,
                -10.677551183038402
              ],
              [
                -44.659478321442414,
                -10.677540682796415
              ],
              [
                -44.64593694063384,
                -10.67693567236961
              ],
              [
                -44.644764432176785,
                -10.676882692211183
              ],
              [
                -44.62777606952516,
                -10.675304169726273
              ],
              [
                -44.61226921758912,
                -10.664822738293473
              ],
              [
                -44.60665876031925,
                -10.65918476893671
              ],
              [
                -44.5977058328189,
                -10.64531982107867
              ],
              [
                -44.589107916709885,
                -10.632004399137497
              ],
              [
                -44.58323898984786,
                -10.62917894612424
              ],
              [
                -44.57737052198454,
                -10.626353428117863
              ],
              [
                -44.548940738920635,
                -10.629435901708813
              ],
              [
                -44.52468596288722,
                -10.647311728805047
              ],
              [
                -44.5175465026654,
                -10.649618742596552
              ],
              [
                -44.50206012020945,
                -10.64948521670867
              ],
              [
                -44.47914281108355,
                -10.640048746278838
              ],
              [
                -44.463221974812825,
                -10.61484237618646
              ],
              [
                -44.452877568354886,
                -10.607334425271171
              ],
              [
                -44.44868110398257,
                -10.606848919127422
              ],
              [
                -44.43803867496033,
                -10.609356375172462
              ],
              [
                -44.42322628947396,
                -10.612846840472654
              ],
              [
                -44.40096949001094,
                -10.606586871309343
              ],
              [
                -44.395905537597095,
                -10.603091406833377
              ],
              [
                -44.352996918125775,
                -10.556223659515789
              ],
              [
                -44.34409502314911,
                -10.549383694280277
              ],
              [
                -44.3352426040415,
                -10.548553692587985
              ],
              [
                -44.32155167724929,
                -10.553911116091708
              ],
              [
                -44.315997236532596,
                -10.55608510378825
              ],
              [
                -44.31132378119106,
                -10.560183562929772
              ],
              [
                -44.303310840392854,
                -10.569381499303214
              ],
              [
                -44.29632835995401,
                -10.581893913165382
              ],
              [
                -44.28264095943666,
                -10.606420743519932
              ],
              [
                -44.27312749218224,
                -10.615336665835548
              ],
              [
                -44.26361507492373,
                -10.624253099141844
              ],
              [
                -44.236940802254736,
                -10.626134069984387
              ],
              [
                -44.2258083992414,
                -10.620339062032654
              ],
              [
                -44.21517548583773,
                -10.614804615019573
              ],
              [
                -44.208243055066234,
                -10.61491110155387
              ],
              [
                -44.19256665542161,
                -10.62816547744309
              ],
              [
                -44.177677282684115,
                -10.63191896288216
              ],
              [
                -44.16890134252282,
                -10.639311367083332
              ],
              [
                -44.16513486406493,
                -10.642483857418169
              ],
              [
                -44.15706691874061,
                -10.643239866222158
              ],
              [
                -44.14631203015347,
                -10.641012844279556
              ],
              [
                -44.13408610039161,
                -10.635816873053562
              ],
              [
                -44.12981768235904,
                -10.632552370174704
              ],
              [
                -44.11843328285617,
                -10.611777027793636
              ],
              [
                -44.110215843079125,
                -10.59678060437668
              ],
              [
                -44.10528290376872,
                -10.594043608227672
              ],
              [
                -44.10034845247256,
                -10.591306111084798
              ],
              [
                -44.09486500525131,
                -10.59043361194356
              ],
              [
                -44.08187858868079,
                -10.588368105243195
              ],
              [
                -44.077244130622006,
                -10.584329134089078
              ],
              [
                -44.07261069555316,
                -10.580291151936743
              ],
              [
                -44.066124252543254,
                -10.558340302835132
              ],
              [
                -44.0506054590561,
                -10.505819149785571
              ],
              [
                -44.04115105363044,
                -10.492821218040287
              ],
              [
                -44.036602564129396,
                -10.489332236477821
              ],
              [
                -44.0292151515291,
                -10.483666265807102
              ],
              [
                -44.02543918523334,
                -10.47505579314895
              ],
              [
                -44.035359652369486,
                -10.444299013271989
              ],
              [
                -44.028916739649496,
                -10.415975200085523
              ],
              [
                -44.01766834647098,
                -10.404737274738338
              ],
              [
                -44.017569322275996,
                -10.404738752606267
              ],
              [
                -44.00730242970523,
                -10.404928749671036
              ],
              [
                -43.9915135165911,
                -10.411921665767851
              ],
              [
                -43.97165320343354,
                -10.42071760985325
              ],
              [
                -43.96291374582617,
                -10.424588064361496
              ],
              [
                -43.95212535417038,
                -10.425104546603867
              ],
              [
                -43.9413369255285,
                -10.425621542844441
              ],
              [
                -43.916815644671374,
                -10.423993525382405
              ],
              [
                -43.91610514097271,
                -10.422006519708374
              ],
              [
                -43.915394163277384,
                -10.420019564034211
              ],
              [
                -43.915348142746076,
                -10.418357070025102
              ],
              [
                -43.91438568316364,
                -10.383691802282152
              ],
              [
                -43.90642828055876,
                -10.375353320962018
              ],
              [
                -43.899868847704035,
                -10.368480358632533
              ],
              [
                -43.89831535079412,
                -10.36415839648468
              ],
              [
                -43.89469689192006,
                -10.354091964042572
              ],
              [
                -43.88449748927103,
                -10.343609512336238
              ],
              [
                -43.87128011112306,
                -10.330024100653603
              ],
              [
                -43.85401226582252,
                -10.319826641516487
              ],
              [
                -43.84734080820902,
                -10.315886163643167
              ],
              [
                -43.84255235207523,
                -10.309647677398
              ],
              [
                -43.837763419944025,
                -10.303407224178017
              ],
              [
                -43.8336464629755,
                -10.277284904440783
              ],
              [
                -43.836664463103546,
                -10.268581961187168
              ],
              [
                -43.837680938429564,
                -10.26565099998305
              ],
              [
                -43.83693347299012,
                -10.250724574715903
              ],
              [
                -43.79744732664684,
                -10.20842983056577
              ],
              [
                -43.7786855337661,
                -10.180055500782252
              ],
              [
                -43.766189665345394,
                -10.133847772208329
              ],
              [
                -43.76660221381703,
                -10.114266941915552
              ],
              [
                -43.76701470975092,
                -10.113194447596518
              ],
              [
                -43.77271316120737,
                -10.098370522763716
              ],
              [
                -43.76563023380336,
                -10.079640664915923
              ],
              [
                -43.76222827190068,
                -10.0769171531351
              ],
              [
                -43.75882631799788,
                -10.07419368735741
              ],
              [
                -43.754635337653085,
                -10.073856687633652
              ],
              [
                -43.729529046294665,
                -10.071841654307624
              ],
              [
                -43.69251633873183,
                -10.076981606815837
              ],
              [
                -43.69149436845808,
                -10.064271195975499
              ],
              [
                -43.69106336546953,
                -10.058901214540047
              ],
              [
                -43.68988189684403,
                -10.044222304774028
              ],
              [
                -43.669069575832424,
                -10.030320370606399
              ],
              [
                -43.661876166399864,
                -10.003924533675466
              ],
              [
                -43.66374866454048,
                -9.996871585343772
              ],
              [
                -43.66562215766773,
                -9.989818668016527
              ],
              [
                -43.680790546871364,
                -9.983748211725255
              ],
              [
                -43.684272510064154,
                -9.982354736200422
              ],
              [
                -43.69341194529642,
                -9.974028800979834
              ],
              [
                -43.702550386540665,
                -9.965703846744864
              ],
              [
                -43.69561646797746,
                -9.951689940921948
              ],
              [
                -43.689165041280646,
                -9.938653025134363
              ],
              [
                -43.690052545424116,
                -9.929087072599273
              ],
              [
                -43.69243951263666,
                -9.925117095133485
              ],
              [
                -43.69839095882902,
                -9.923566108545552
              ],
              [
                -43.70577591175234,
                -9.921642146146171
              ],
              [
                -43.708636890881216,
                -9.912807709992723
              ],
              [
                -43.70507592511676,
                -9.905970235887626
              ],
              [
                -43.69602500325061,
                -9.900793283195373
              ],
              [
                -43.69422053681602,
                -9.890840324163964
              ],
              [
                -43.68599158477179,
                -9.888577366820726
              ],
              [
                -43.677046172825776,
                -9.886117373977577
              ],
              [
                -43.66599527188612,
                -9.868637965997038
              ],
              [
                -43.66542831130896,
                -9.862053017763408
              ],
              [
                -43.66486131572648,
                -9.855468037536465
              ],
              [
                -43.663693329526986,
                -9.85441006931904
              ],
              [
                -43.653137914134895,
                -9.844846618614344
              ],
              [
                -43.65284694782907,
                -9.838643656153671
              ],
              [
                -43.664445334125254,
                -9.823244771562333
              ],
              [
                -43.67898125179609,
                -9.81686482146553
              ],
              [
                -43.68246719510183,
                -9.815334356832642
              ],
              [
                -43.68776366697995,
                -9.810089368370752
              ],
              [
                -43.69305962386303,
                -9.80484490590248
              ],
              [
                -43.701582569910975,
                -9.805101921024349
              ],
              [
                -43.70799702226997,
                -9.805295422137984
              ],
              [
                -43.717418453157414,
                -9.802010948203083
              ],
              [
                -43.718938911224356,
                -9.798193002728304
              ],
              [
                -43.72102739832402,
                -9.792950038528652
              ],
              [
                -43.74638823968019,
                -9.783170611828563
              ],
              [
                -43.76116511872065,
                -9.777472668341364
              ],
              [
                -43.76441858905402,
                -9.773303206228759
              ],
              [
                -43.76677307937413,
                -9.770285739305383
              ],
              [
                -43.774758524581046,
                -9.766545283756919
              ],
              [
                -43.78451943128119,
                -9.761973295862585
              ],
              [
                -43.797262369920496,
                -9.727271067694536
              ],
              [
                -43.79398489502789,
                -9.714963620004543
              ],
              [
                -43.7933804216062,
                -9.7126946626288
              ],
              [
                -43.798317383257036,
                -9.694737751541942
              ],
              [
                -43.82624770211777,
                -9.649431090508617
              ],
              [
                -43.82806521900923,
                -9.647956631544092
              ],
              [
                -43.841551120748946,
                -9.637018696821606
              ],
              [
                -43.8397681119016,
                -9.626114260464075
              ],
              [
                -43.83530517974639,
                -9.62311529064778
              ],
              [
                -43.83390915920935,
                -9.622177813394172
              ],
              [
                -43.83357719029495,
                -9.617522840477962
              ],
              [
                -43.836446164921306,
                -9.615781845509476
              ],
              [
                -43.84224762470395,
                -9.612260855906692
              ],
              [
                -43.841138171443454,
                -9.585642028602372
              ],
              [
                -43.85218158595851,
                -9.56172974083719
              ],
              [
                -43.850736600819445,
                -9.554686782845161
              ],
              [
                -43.849292147669026,
                -9.547644311859308
              ],
              [
                -43.83294730106708,
                -9.51950848487027
              ],
              [
                -43.81803295391153,
                -9.493835147015114
              ],
              [
                -43.799048619262884,
                -9.479244711623021
              ],
              [
                -43.78552526664856,
                -9.457111343618923
              ],
              [
                -43.783058787128226,
                -9.454139367028988
              ],
              [
                -43.781647793560175,
                -9.452438898992108
              ],
              [
                -43.77901079167962,
                -9.45012840172696
              ],
              [
                -43.76932689025336,
                -9.441642939516747
              ],
              [
                -43.75102002858291,
                -9.43366449028373
              ],
              [
                -43.686415075860936,
                -9.417647040271948
              ],
              [
                -43.6241966568607,
                -9.37327178648249
              ],
              [
                -43.57418160964428,
                -9.31768360663294
              ],
              [
                -43.57154111974771,
                -9.316319105054411
              ],
              [
                -43.56890063885144,
                -9.314954605477162
              ],
              [
                -43.5624591843085,
                -9.313342104428816
              ],
              [
                -43.54340334743339,
                -9.308572126138758
              ],
              [
                -43.53428194699246,
                -9.304106142672945
              ],
              [
                -43.5251610195532,
                -9.299640185221445
              ],
              [
                -43.50573272161313,
                -9.280570271409728
              ],
              [
                -43.497124296389536,
                -9.272120814833789
              ],
              [
                -43.49109133075717,
                -9.268500342037939
              ],
              [
                -43.485058400126235,
                -9.26487987724965
              ],
              [
                -43.46126959172488,
                -9.261372365380476
              ],
              [
                -43.440976253924134,
                -9.264976344624543
              ],
              [
                -43.42745686173899,
                -9.272158277716784
              ],
              [
                -43.423944859687815,
                -9.276529243949621
              ],
              [
                -43.404134526777035,
                -9.3011815574841
              ],
              [
                -43.37361772774941,
                -9.35096669316093
              ],
              [
                -43.35315986597148,
                -9.368552051313577
              ],
              [
                -43.34347794487201,
                -9.369020543479557
              ],
              [
                -43.33379653178022,
                -9.36948851865079
              ],
              [
                -43.319463622219786,
                -9.384017414403072
              ],
              [
                -43.31455566095782,
                -9.388991870939766
              ],
              [
                -43.30535021223929,
                -9.392830326530584
              ],
              [
                -43.299039266113276,
                -9.395461791567522
              ],
              [
                -43.29520132016041,
                -9.39856627208166
              ],
              [
                -43.29262080256444,
                -9.403750246733662
              ],
              [
                -43.28472235770041,
                -9.419618111356336
              ],
              [
                -43.27836892579279,
                -9.42416608420692
              ],
              [
                -43.272469470252155,
                -9.422370602002449
              ],
              [
                -43.26989448444814,
                -9.417854096103879
              ],
              [
                -43.26627301628848,
                -9.41150013064838
              ],
              [
                -43.247163681291525,
                -9.41148162174876
              ],
              [
                -43.24194623316518,
                -9.411477125040143
              ],
              [
                -43.23820376671277,
                -9.40897015610925
              ],
              [
                -43.22859936580829,
                -9.402537182658987
              ],
              [
                -43.22810384800474,
                -9.402425674879263
              ],
              [
                -43.21823544557443,
                -9.400213198213306
              ],
              [
                -43.205769549602095,
                -9.402406151482284
              ],
              [
                -43.2011920768328,
                -9.411742112413101
              ],
              [
                -43.19833206284251,
                -9.417576042253975
              ],
              [
                -43.191304140896655,
                -9.420026031720628
              ],
              [
                -43.173532300316815,
                -9.41081457738741
              ],
              [
                -43.164706372842694,
                -9.391101176023655
              ],
              [
                -43.15822745520227,
                -9.376629801310562
              ],
              [
                -43.15252347352704,
                -9.376266297567826
              ],
              [
                -43.14682103984277,
                -9.375902791828013
              ],
              [
                -43.14520404375214,
                -9.377159768423875
              ],
              [
                -43.13275316246196,
                -9.386838187307381
              ],
              [
                -43.12901518128527,
                -9.379570757152228
              ],
              [
                -43.127518693879296,
                -9.379319241243998
              ],
              [
                -43.123468237969504,
                -9.378637233501644
              ],
              [
                -43.11960276399405,
                -9.372130274999677
              ],
              [
                -43.11129331055519,
                -9.380100235134593
              ],
              [
                -43.10702185095185,
                -9.378083238330253
              ],
              [
                -43.10370237446025,
                -9.386036666027934
              ],
              [
                -43.09534297230882,
                -9.385111148247868
              ],
              [
                -43.08480505840378,
                -9.391129622344469
              ],
              [
                -43.08249155003272,
                -9.396917571020248
              ],
              [
                -43.074166604113564,
                -9.399152529541908
              ],
              [
                -43.06670270033433,
                -9.393976557965875
              ],
              [
                -43.06621818969796,
                -9.393640572746463
              ],
              [
                -43.05926175543985,
                -9.396851071056965
              ],
              [
                -43.05807874386653,
                -9.406147479830759
              ],
              [
                -43.05664973015699,
                -9.417380418029339
              ],
              [
                -43.05417478332417,
                -9.414882925487854
              ],
              [
                -43.01557511079367,
                -9.408252422822436
              ],
              [
                -43.01173112121475,
                -9.407591911161411
              ],
              [
                -43.008896126486604,
                -9.40858291823956
              ],
              [
                -43.006062157750385,
                -9.409574420315087
              ],
              [
                -43.00128322261689,
                -9.407352426379317
              ],
              [
                -42.98746983663955,
                -9.40092992948917
              ],
              [
                -42.982965342977906,
                -9.403464432115117
              ],
              [
                -42.981205852776505,
                -9.404106932767993
              ],
              [
                -42.980274372081304,
                -9.404447428405314
              ],
              [
                -42.97149694792228,
                -9.407653879730837
              ],
              [
                -42.95800604612076,
                -9.428463727678327
              ],
              [
                -42.957472065677656,
                -9.431027706440496
              ],
              [
                -42.95693756223808,
                -9.43359169720248
              ],
              [
                -42.95921951888803,
                -9.443052651577027
              ],
              [
                -42.96249949968693,
                -9.456646539624558
              ],
              [
                -42.96212249068222,
                -9.463028493298824
              ],
              [
                -42.96174597466858,
                -9.469410438978434
              ],
              [
                -42.95599700080532,
                -9.484900331098704
              ],
              [
                -42.951416515801526,
                -9.497241761170864
              ],
              [
                -42.95125601099139,
                -9.501828210454196
              ],
              [
                -42.950971515216125,
                -9.509971654146714
              ],
              [
                -42.94585454144215,
                -9.51800562125811
              ],
              [
                -42.92802319673786,
                -9.526835540834368
              ],
              [
                -42.91663827723144,
                -9.526401527229913
              ],
              [
                -42.90525339373977,
                -9.525967500629795
              ],
              [
                -42.87618507308379,
                -9.55961076205489
              ],
              [
                -42.87399709531443,
                -9.562143227212326
              ],
              [
                -42.87225860090323,
                -9.562834234532403
              ],
              [
                -42.87052162748094,
                -9.563524235861014
              ],
              [
                -42.86602867046422,
                -9.560989718312912
              ],
              [
                -42.845466833458964,
                -9.549390769347708
              ],
              [
                -42.83674692990688,
                -9.549961768769233
              ],
              [
                -42.829593484936275,
                -9.558077211991627
              ],
              [
                -42.82942093834177,
                -9.57676310120613
              ],
              [
                -42.82938343505573,
                -9.580843555089253
              ],
              [
                -42.825259973239646,
                -9.589641010986197
              ],
              [
                -42.825246486318505,
                -9.589670009771773
              ],
              [
                -42.8211360064214,
                -9.598438930881539
              ],
              [
                -42.82112750428466,
                -9.598622433609815
              ],
              [
                -42.82015997946482,
                -9.618821776611066
              ],
              [
                -42.81118256712437,
                -9.620236789880629
              ],
              [
                -42.80220513979336,
                -9.621652262146329
              ],
              [
                -42.76477492455695,
                -9.616339250040664
              ],
              [
                -42.76049647025146,
                -9.609006809758021
              ],
              [
                -42.75904799871559,
                -9.599720375087042
              ],
              [
                -42.757356050944644,
                -9.588870443928595
              ],
              [
                -42.743466151183,
                -9.582429478901659
              ],
              [
                -42.74181016875694,
                -9.581661977355143
              ],
              [
                -42.736934719408666,
                -9.575182498605075
              ],
              [
                -42.73693223243317,
                -9.575179009626371
              ],
              [
                -42.73205475610168,
                -9.568696058911186
              ],
              [
                -42.71650188828666,
                -9.555869633156288
              ],
              [
                -42.71766439000031,
                -9.546638683104003
              ],
              [
                -42.71965890020505,
                -9.53079828457448
              ],
              [
                -42.70953046432324,
                -9.531479272720894
              ],
              [
                -42.6908721190452,
                -9.54474367563209
              ],
              [
                -42.67421027211249,
                -9.548307626668612
              ],
              [
                -42.65421943397234,
                -9.540992148149172
              ],
              [
                -42.647247986446516,
                -9.542168154352275
              ],
              [
                -42.639827055568134,
                -9.543420137535266
              ],
              [
                -42.6319616268368,
                -9.537998163331181
              ],
              [
                -42.621248213519124,
                -9.540822610030885
              ],
              [
                -42.60280288968535,
                -9.51553777853256
              ],
              [
                -42.59647344483392,
                -9.50686482257099
              ],
              [
                -42.59489197036121,
                -9.504697850826382
              ],
              [
                -42.59464294780644,
                -9.504356346915756
              ],
              [
                -42.586087543659715,
                -9.496360408863996
              ],
              [
                -42.57079815162046,
                -9.500764372657994
              ],
              [
                -42.56878768665978,
                -9.50134335645092
              ],
              [
                -42.55878078596276,
                -9.49840584383206
              ],
              [
                -42.54877436227148,
                -9.495467884226976
              ],
              [
                -42.521609552936475,
                -9.502543309531392
              ],
              [
                -42.491783812167654,
                -9.493047333729951
              ],
              [
                -42.48567388654419,
                -9.483452402566831
              ],
              [
                -42.48801186127326,
                -9.476987425932956
              ],
              [
                -42.49082837248656,
                -9.469198512001569
              ],
              [
                -42.487505381799714,
                -9.461309561086662
              ],
              [
                -42.473201508399605,
                -9.458794563971844
              ],
              [
                -42.45231070292594,
                -9.461569012009528
              ],
              [
                -42.451375668559834,
                -9.461693008132583
              ],
              [
                -42.44694973193377,
                -9.459343534625017
              ],
              [
                -42.44252476329972,
                -9.45699503511533
              ],
              [
                -42.427889388345726,
                -9.439377652538495
              ],
              [
                -42.425411430382326,
                -9.427148727787149
              ],
              [
                -42.42283098180889,
                -9.414411822480918
              ],
              [
                -42.42074299181095,
                -9.411999309987035
              ],
              [
                -42.39849518455603,
                -9.396678399461628
              ],
              [
                -42.36211101432638,
                -9.379136002303799
              ],
              [
                -42.35875655685831,
                -9.37397650360736
              ],
              [
                -42.356819067771305,
                -9.36223060129529
              ],
              [
                -42.35471261318301,
                -9.349463185939184
              ],
              [
                -42.332068789477056,
                -9.33667126279781
              ],
              [
                -42.32545836753786,
                -9.332936793751477
              ],
              [
                -42.32211490545646,
                -9.326391835797416
              ],
              [
                -42.31877144237015,
                -9.319846865854213
              ],
              [
                -42.28953966533173,
                -9.309091890675328
              ],
              [
                -42.27936776194469,
                -9.309167404305972
              ],
              [
                -42.269196356566056,
                -9.309242383943134
              ],
              [
                -42.250398005399724,
                -9.294169479159553
              ],
              [
                -42.2433395862958,
                -9.288509509185657
              ],
              [
                -42.23455715219915,
                -9.285968031608782
              ],
              [
                -42.22577574410053,
                -9.283427030037336
              ],
              [
                -42.21828529686094,
                -9.286825990333673
              ],
              [
                -42.20604838586988,
                -9.292378434536275
              ],
              [
                -42.193846499767034,
                -9.291276436280157
              ],
              [
                -42.18692206273891,
                -9.288260473013114
              ],
              [
                -42.17910660855947,
                -9.2848574925142
              ],
              [
                -42.13652595972236,
                -9.286697403495147
              ],
              [
                -42.100143259856296,
                -9.280619917499834
              ],
              [
                -42.066128588646535,
                -9.255574575017233
              ],
              [
                -42.06161362132697,
                -9.255595082111464
              ],
              [
                -42.051394215623986,
                -9.25564105801229
              ],
              [
                -42.034577848011565,
                -9.255714548768452
              ],
              [
                -42.02776989405933,
                -9.25274356743028
              ],
              [
                -42.020962474105374,
                -9.249773083096057
              ],
              [
                -41.99955214419169,
                -9.265540429925869
              ],
              [
                -41.97188038198626,
                -9.248229510274253
              ],
              [
                -41.96483741670159,
                -9.254959485616036
              ],
              [
                -41.95779500641486,
                -9.261689413955043
              ],
              [
                -41.93867213427076,
                -9.266458378334718
              ],
              [
                -41.937052663387576,
                -9.266861860796038
              ],
              [
                -41.93626615626973,
                -9.267515352378881
              ],
              [
                -41.92438224615282,
                -9.277389285522082
              ],
              [
                -41.91754832062225,
                -9.278057258629046
              ],
              [
                -41.90920386946239,
                -9.273363312807298
              ],
              [
                -41.90286796046133,
                -9.260258369211318
              ],
              [
                -41.90201245050579,
                -9.258489395742508
              ],
              [
                -41.89328102747532,
                -9.255775406639252
              ],
              [
                -41.88454908845589,
                -9.253061427543392
              ],
              [
                -41.88405161920925,
                -9.252507928912008
              ],
              [
                -41.870135749167034,
                -9.237025500181456
              ],
              [
                -41.85932382450451,
                -9.23720502058778
              ],
              [
                -41.851363405305314,
                -9.240840966666502
              ],
              [
                -41.845984946740835,
                -9.243297454746665
              ],
              [
                -41.837579020668976,
                -9.241863937022906
              ],
              [
                -41.81951921421671,
                -9.188696295512248
              ],
              [
                -41.79974140812984,
                -9.164753965694064
              ],
              [
                -41.79971191438135,
                -9.1561870290721
              ],
              [
                -41.79968192162823,
                -9.147620090459133
              ],
              [
                -41.77541362873339,
                -9.125911192516565
              ],
              [
                -41.76698419143069,
                -9.121083236890554
              ],
              [
                -41.75855426513608,
                -9.116254752281574
              ],
              [
                -41.73479951819098,
                -9.094294899937394
              ],
              [
                -41.73062955203515,
                -9.069411562553757
              ],
              [
                -41.73160105126585,
                -9.057487148874433
              ],
              [
                -41.7325730474761,
                -9.04556272121138
              ],
              [
                -41.72840759738935,
                -9.020430913776385
              ],
              [
                -41.72581365790175,
                -9.016783414946106
              ],
              [
                -41.72322017740906,
                -9.013135941120067
              ],
              [
                -41.71200728476245,
                -9.012808431007764
              ],
              [
                -41.68006503862482,
                -9.011875926066491
              ],
              [
                -41.66951863616747,
                -9.00307395969467
              ],
              [
                -41.669391621785316,
                -8.999621990027794
              ],
              [
                -41.66902616607966,
                -8.989674558157644
              ],
              [
                -41.64600335329834,
                -8.986718079742579
              ],
              [
                -41.62861299355043,
                -8.984484553939652
              ],
              [
                -41.623713037982974,
                -8.981267075795023
              ],
              [
                -41.62156657223518,
                -8.973235151605202
              ],
              [
                -41.62082355931988,
                -8.970455662575194
              ],
              [
                -41.61068415474444,
                -8.967230664199
              ],
              [
                -41.60954964063857,
                -8.966870185609562
              ],
              [
                -41.609405147204804,
                -8.96652517391214
              ],
              [
                -41.60541122248344,
                -8.956997723504365
              ],
              [
                -41.596526773552,
                -8.95849523189372
              ],
              [
                -41.58963435105971,
                -8.954944722141601
              ],
              [
                -41.56966603108652,
                -8.954638224154616
              ],
              [
                -41.56748801627041,
                -8.961600178501897
              ],
              [
                -41.565301054494746,
                -8.968591628633572
              ],
              [
                -41.544135225964425,
                -8.960144162261313
              ],
              [
                -41.54442073744033,
                -8.940660796018477
              ],
              [
                -41.54446574920401,
                -8.937576807986284
              ],
              [
                -41.537714809161635,
                -8.932440363707041
              ],
              [
                -41.53096336112352,
                -8.927304363436265
              ],
              [
                -41.525792413582224,
                -8.913525477245416
              ],
              [
                -41.52253144080545,
                -8.904836547791524
              ],
              [
                -41.51840100086986,
                -8.899559560200965
              ],
              [
                -41.51597600888095,
                -8.897951581214377
              ],
              [
                -41.50706959572467,
                -8.89204509933533
              ],
              [
                -41.50886956307583,
                -8.882674683846457
              ],
              [
                -41.510097570219315,
                -8.876279221610263
              ],
              [
                -41.502095643315485,
                -8.8635658111598
              ],
              [
                -41.49566822666822,
                -8.861262317156273
              ],
              [
                -41.49415623992572,
                -8.860719818512964
              ],
              [
                -41.493461246570945,
                -8.858931819558217
              ],
              [
                -41.48746330353806,
                -8.843497952561867
              ],
              [
                -41.4784128820868,
                -8.843042919798567
              ],
              [
                -41.47180191200737,
                -8.842711429584133
              ],
              [
                -41.46275799773978,
                -8.829094996684855
              ],
              [
                -41.46226153766503,
                -8.82834752552265
              ],
              [
                -41.43153779780317,
                -8.796671223108804
              ],
              [
                -41.427689343484204,
                -8.799366707058777
              ],
              [
                -41.423840368170396,
                -8.80206267300376
              ],
              [
                -41.411672515036855,
                -8.77351385388552
              ],
              [
                -41.39993011222915,
                -8.745971547174491
              ],
              [
                -41.3970546402286,
                -8.736089601009235
              ],
              [
                -41.394179685211334,
                -8.726208162859864
              ],
              [
                -41.39198419584429,
                -8.72301820852596
              ],
              [
                -41.381959801604296,
                -8.70845478891161
              ],
              [
                -41.37942732801544,
                -8.707343285389813
              ],
              [
                -41.37891533054668,
                -8.707118299499376
              ],
              [
                -41.36935090950158,
                -8.705645791689703
              ],
              [
                -41.357936490023796,
                -8.707229271199616
              ],
              [
                -41.34833208795851,
                -8.715256210345284
              ],
              [
                -41.33397067402893,
                -8.727259123363561
              ],
              [
                -41.31465986087084,
                -8.726054637090007
              ],
              [
                -41.30953491036011,
                -8.725735116364786
              ],
              [
                -41.30206295366327,
                -8.730437061378167
              ],
              [
                -41.29459200096273,
                -8.735139532385034
              ],
              [
                -41.28115013269165,
                -8.73569302926342
              ],
              [
                -41.26959923507383,
                -8.730608058397072
              ],
              [
                -41.26615075988594,
                -8.726817072193299
              ],
              [
                -41.260232818192065,
                -8.720312123036194
              ],
              [
                -41.252206374205514,
                -8.7199596147263
              ],
              [
                -41.23818450103963,
                -8.719343582456608
              ],
              [
                -41.23902499273353,
                -8.713477628342254
              ],
              [
                -41.23945550606237,
                -8.710473673305389
              ],
              [
                -41.226597618563396,
                -8.704302711039787
              ],
              [
                -41.20452128407091,
                -8.70907811527422
              ],
              [
                -41.194151371407024,
                -8.707197154674883
              ],
              [
                -41.189140927925976,
                -8.708982646981354
              ],
              [
                -41.187276455003925,
                -8.709647629392911
              ],
              [
                -41.18524595499199,
                -8.708778643653485
              ],
              [
                -41.182054997260444,
                -8.7074136233458
              ],
              [
                -41.17928900507942,
                -8.70896163754079
              ],
              [
                -41.176023025843854,
                -8.710789106242359
              ],
              [
                -41.17464153086511,
                -8.709403593844096
              ],
              [
                -41.172051581276925,
                -8.70680561297561
              ],
              [
                -41.16244463664483,
                -8.709568580788726
              ],
              [
                -41.113255560832805,
                -8.703597104860796
              ],
              [
                -41.11154757165088,
                -8.708292057461088
              ],
              [
                -41.12025548450684,
                -8.716026023357674
              ],
              [
                -41.12281947361488,
                -8.718302526498118
              ],
              [
                -41.121602485366786,
                -8.722904969251664
              ],
              [
                -41.12133449263363,
                -8.723917953714217
              ],
              [
                -41.1219009673467,
                -8.724428951595264
              ],
              [
                -41.12646442875322,
                -8.728547436459598
              ],
              [
                -41.12831042490073,
                -8.74099433287694
              ],
              [
                -41.12624891674956,
                -8.746633298376942
              ],
              [
                -41.12418791459191,
                -8.752271765882593
              ],
              [
                -41.12033095184462,
                -8.754625733214148
              ],
              [
                -41.11327899116253,
                -8.758928683429003
              ],
              [
                -41.114614003509494,
                -8.762345666202673
              ],
              [
                -41.11515549256676,
                -8.763730657789573
              ],
              [
                -41.110774524688516,
                -8.769685123758522
              ],
              [
                -41.10906801595575,
                -8.769836107004629
              ],
              [
                -41.105575074159475,
                -8.77014512136811
              ],
              [
                -41.10404059399481,
                -8.774319582898464
              ],
              [
                -41.101378608319955,
                -8.781560051317005
              ],
              [
                -41.08173873794305,
                -8.783923996106035
              ],
              [
                -41.078158267800994,
                -8.788370973669663
              ],
              [
                -41.07274782475585,
                -8.784103975007332
              ],
              [
                -41.070983862041906,
                -8.782713500264913
              ],
              [
                -41.06514337589341,
                -8.781215505304896
              ],
              [
                -41.05817295841194,
                -8.784659488745378
              ],
              [
                -41.054445467103264,
                -8.786500943869266
              ],
              [
                -41.04812104301895,
                -8.785042464153333
              ],
              [
                -41.04193960169678,
                -8.783616964343619
              ],
              [
                -41.03585614030569,
                -8.785471964062593
              ],
              [
                -41.02457719606665,
                -8.807316775079366
              ],
              [
                -41.030939152207566,
                -8.834553591739907
              ],
              [
                -41.02589966255586,
                -8.838854574903399
              ],
              [
                -41.02086022590539,
                -8.84315554606515
              ],
              [
                -41.00505386574206,
                -8.831987567704974
              ],
              [
                -40.99996742135139,
                -8.828393614519758
              ],
              [
                -40.995051946648964,
                -8.828692580542171
              ],
              [
                -40.98937749532662,
                -8.829037617488131
              ],
              [
                -40.98582053442421,
                -8.827028599428061
              ],
              [
                -40.97871010058973,
                -8.823012638303526
              ],
              [
                -40.9657606720147,
                -8.82824106201629
              ],
              [
                -40.96052523923822,
                -8.830355565678312
              ],
              [
                -40.958401750363144,
                -8.841282991059785
              ],
              [
                -40.946109847158816,
                -8.84458744622702
              ],
              [
                -40.928177980783225,
                -8.837782975520238
              ],
              [
                -40.92095756866181,
                -8.835043005132752
              ],
              [
                -40.91777458263667,
                -8.842082926384807
              ],
              [
                -40.91459161060675,
                -8.849123902630943
              ],
              [
                -40.91236311549466,
                -8.851127866031778
              ],
              [
                -40.90187868894942,
                -8.860557780919557
              ],
              [
                -40.89602373855409,
                -8.870614192854664
              ],
              [
                -40.89532123230151,
                -8.879853647743095
              ],
              [
                -40.89464921817812,
                -8.888695067531915
              ],
              [
                -40.883072800586795,
                -8.90442498005309
              ],
              [
                -40.88084431067832,
                -8.915222874258653
              ],
              [
                -40.87396687541396,
                -8.918787367840682
              ],
              [
                -40.86590244260266,
                -8.922967327822247
              ],
              [
                -40.86285496129726,
                -8.938081206209848
              ],
              [
                -40.86093296202918,
                -8.947614119821793
              ],
              [
                -40.85607351798705,
                -8.949856602889488
              ],
              [
                -40.85121504193982,
                -8.952098599960452
              ],
              [
                -40.8509185302249,
                -8.96724947084187
              ],
              [
                -40.850577008543226,
                -8.984703859168613
              ],
              [
                -40.845854029401316,
                -8.995811290718862
              ],
              [
                -40.840501104771334,
                -9.001793714432667
              ],
              [
                -40.834613145075465,
                -9.008375687306657
              ],
              [
                -40.83278914104454,
                -9.022720089528308
              ],
              [
                -40.83175761858491,
                -9.03083001029858
              ],
              [
                -40.828255663055025,
                -9.035019962726889
              ],
              [
                -40.8247541765216,
                -9.03920946615824
              ],
              [
                -40.82387068405197,
                -9.05198986657907
              ],
              [
                -40.823198168870526,
                -9.061723788080965
              ],
              [
                -40.820092212258906,
                -9.066614262884318
              ],
              [
                -40.818768724372326,
                -9.068698239607691
              ],
              [
                -40.818943702484795,
                -9.071059733846868
              ],
              [
                -40.819606675763424,
                -9.079993164450007
              ],
              [
                -40.81157277887513,
                -9.084000108632212
              ],
              [
                -40.80372182032295,
                -9.097929525844165
              ],
              [
                -40.79498888208823,
                -9.098303010365616
              ],
              [
                -40.78907743102625,
                -9.098555482598673
              ],
              [
                -40.78721693231829,
                -9.101992952072209
              ],
              [
                -40.78571095526654,
                -9.104776447593098
              ],
              [
                -40.78315946324876,
                -9.103488460257692
              ],
              [
                -40.776820556348056,
                -9.100287985824181
              ],
              [
                -40.76288414739311,
                -9.106491906280487
              ],
              [
                -40.75638121858711,
                -9.104578928451495
              ],
              [
                -40.75655021125546,
                -9.11329687708098
              ],
              [
                -40.75666970197568,
                -9.119430809203894
              ],
              [
                -40.75000923854801,
                -9.117487835420963
              ],
              [
                -40.732118411303745,
                -9.123627257327925
              ],
              [
                -40.707475592237664,
                -9.14077811739394
              ],
              [
                -40.69609070085713,
                -9.138997119674858
              ],
              [
                -40.68470578248938,
                -9.137216631959976
              ],
              [
                -40.67734282117569,
                -9.150840509737602
              ],
              [
                -40.66702489992447,
                -9.158669462117308
              ],
              [
                -40.671712344388155,
                -9.17199936320526
              ],
              [
                -40.670253342064704,
                -9.188298740795267
              ],
              [
                -40.67632128396924,
                -9.192654213714668
              ],
              [
                -40.676680279650164,
                -9.192912233228672
              ],
              [
                -40.68159524307142,
                -9.204350646200892
              ],
              [
                -40.68032776518356,
                -9.211795091540823
              ],
              [
                -40.69411012700911,
                -9.233056958203875
              ],
              [
                -40.690610140136805,
                -9.233677926184647
              ],
              [
                -40.6861716793108,
                -9.242943882269163
              ],
              [
                -40.68607965989341,
                -9.243137373788997
              ],
              [
                -40.68877364904361,
                -9.256596280133765
              ],
              [
                -40.68025122139242,
                -9.258673259543203
              ],
              [
                -40.68622865411434,
                -9.27643765844554
              ],
              [
                -40.70592297326991,
                -9.284443594329744
              ],
              [
                -40.70702096446698,
                -9.284890082260983
              ],
              [
                -40.70715044343932,
                -9.286742585136746
              ],
              [
                -40.70771545221293,
                -9.294835517806852
              ],
              [
                -40.715506882738126,
                -9.301756982295885
              ],
              [
                -40.71971834302282,
                -9.305497966868037
              ],
              [
                -40.729005753720514,
                -9.301013014496862
              ],
              [
                -40.73902616356493,
                -9.296173554410029
              ],
              [
                -40.743034131666356,
                -9.304638006005618
              ],
              [
                -40.74359664164496,
                -9.305825979090763
              ],
              [
                -40.7470320905963,
                -9.306633466853704
              ],
              [
                -40.75119857115496,
                -9.307612955140632
              ],
              [
                -40.755094033130334,
                -9.313274926680533
              ],
              [
                -40.74197812815496,
                -9.329999797622659
              ],
              [
                -40.73689864656799,
                -9.33647674109189
              ],
              [
                -40.73931761290257,
                -9.342300219975698
              ],
              [
                -40.74101558618236,
                -9.346388199521284
              ],
              [
                -40.73938061271942,
                -9.35055317107416
              ],
              [
                -40.73770961846651,
                -9.35480861994429
              ],
              [
                -40.741404600721395,
                -9.361790598893284
              ],
              [
                -40.746086042624086,
                -9.370637026562472
              ],
              [
                -40.744118047821175,
                -9.376855961114478
              ],
              [
                -40.7421500590168,
                -9.383072910683165
              ],
              [
                -40.7422900466924,
                -9.400291311911122
              ],
              [
                -40.74647902327303,
                -9.40484027980056
              ],
              [
                -40.7520064547015,
                -9.407275753171128
              ],
              [
                -40.76459184984131,
                -9.41281971871828
              ],
              [
                -40.76094186952387,
                -9.423560164303193
              ],
              [
                -40.75920687587568,
                -9.42866611407687
              ],
              [
                -40.76690930877432,
                -9.445295521541585
              ],
              [
                -40.771303268097235,
                -9.449368483100356
              ],
              [
                -40.77632919542472,
                -9.454027481150199
              ],
              [
                -40.76196632792065,
                -9.448957462268952
              ],
              [
                -40.74492248123545,
                -9.442942019354524
              ],
              [
                -40.72181868600292,
                -9.445544483668465
              ],
              [
                -40.71273727226222,
                -9.450270428470734
              ],
              [
                -40.68094399830282,
                -9.466817290191313
              ],
              [
                -40.62278898792014,
                -9.482495601483135
              ],
              [
                -40.59446323169353,
                -9.467004203546816
              ],
              [
                -40.57333190330309,
                -9.46653219388092
              ],
              [
                -40.56190003665821,
                -9.460954719836547
              ],
              [
                -40.54887365531246,
                -9.449034263518495
              ],
              [
                -40.54065524345073,
                -9.42905140094004
              ],
              [
                -40.53745827589096,
                -9.421277959254933
              ],
              [
                -40.53320731513868,
                -9.417476007061712
              ],
              [
                -40.52895632138681,
                -9.413673531878002
              ],
              [
                -40.51033751843908,
                -9.406768062050778
              ],
              [
                -40.50181357693124,
                -9.406979543717624
              ],
              [
                -40.5004975893162,
                -9.407782030608068
              ],
              [
                -40.496952606441035,
                -9.408848529301205
              ],
              [
                -40.49550613832545,
                -9.409283526688766
              ],
              [
                -40.489280676423974,
                -9.412159979642153
              ],
              [
                -40.47880576339245,
                -9.41732044283288
              ],
              [
                -40.476215792068146,
                -9.418679912412582
              ],
              [
                -40.467924372337094,
                -9.420104419624236
              ],
              [
                -40.455309972400315,
                -9.405429022752628
              ],
              [
                -40.44705904887321,
                -9.387237133670977
              ],
              [
                -40.43550619808913,
                -9.361763794518595
              ],
              [
                -40.420507824146014,
                -9.352161335033799
              ],
              [
                -40.411266892400015,
                -9.353753341118427
              ],
              [
                -40.394478533337335,
                -9.368590727347312
              ],
              [
                -40.39090106660132,
                -9.37175316596516
              ],
              [
                -40.37099372684261,
                -9.378315598379096
              ],
              [
                -40.35773180777024,
                -9.376783617749126
              ],
              [
                -40.334653555279544,
                -9.353472784564179
              ],
              [
                -40.33502957581507,
                -9.340226382177057
              ],
              [
                -40.33540508033397,
                -9.326980946804179
              ],
              [
                -40.31865576096585,
                -9.286853730445674
              ],
              [
                -40.31304481010286,
                -9.278921766847018
              ],
              [
                -40.307434365229504,
                -9.270989831267237
              ],
              [
                -40.285430616930896,
                -9.181475457335846
              ],
              [
                -40.29363009113211,
                -9.151656684771948
              ],
              [
                -40.28631968978779,
                -9.11691743432932
              ],
              [
                -40.2713388214763,
                -9.080578678041535
              ],
              [
                -40.25032654768986,
                -9.060099287318597
              ],
              [
                -40.24370758677012,
                -9.056625792896186
              ],
              [
                -40.233599198099824,
                -9.054744295505206
              ],
              [
                -40.23351019087809,
                -9.05472730754009
              ],
              [
                -40.22729520909703,
                -9.055661309628185
              ],
              [
                -40.20860138172561,
                -9.063925744296439
              ],
              [
                -40.17324217609144,
                -9.091055486840393
              ],
              [
                -40.16893270043274,
                -9.098566949184073
              ],
              [
                -40.16462321077076,
                -9.106078866525149
              ],
              [
                -40.12832952342716,
                -9.109192834921291
              ],
              [
                -40.076336010203796,
                -9.076978997856736
              ],
              [
                -40.07079903075725,
                -9.06988055586854
              ],
              [
                -40.06526260130217,
                -9.062781600900358
              ],
              [
                -40.03224637417578,
                -9.060947078650747
              ],
              [
                -40.020172994377184,
                -9.060276575618785
              ],
              [
                -40.00451011558224,
                -9.059406071508691
              ],
              [
                -39.9915912186889,
                -9.059121074856145
              ],
              [
                -39.97884784349077,
                -9.054636105894152
              ],
              [
                -39.95764802581793,
                -9.048202136804486
              ],
              [
                -39.91604892453506,
                -8.998136454233595
              ],
              [
                -39.90281104912274,
                -8.977941058377162
              ],
              [
                -39.896338639410885,
                -8.972144109302706
              ],
              [
                -39.889866198698776,
                -8.966346641245307
              ],
              [
                -39.87357236741595,
                -8.930906880775519
              ],
              [
                -39.87903581923867,
                -8.901500595553564
              ],
              [
                -39.90149817151185,
                -8.86028644493977
              ],
              [
                -39.90137068502694,
                -8.854705980549873
              ],
              [
                -39.901243172537804,
                -8.84912648515709
              ],
              [
                -39.894253262816775,
                -8.83130362959292
              ],
              [
                -39.88986978508029,
                -8.8275501498099
              ],
              [
                -39.87268096779664,
                -8.822236667180333
              ],
              [
                -39.82125038709457,
                -8.813383688764253
              ],
              [
                -39.80993148762417,
                -8.816213180268376
              ],
              [
                -39.8014065488298,
                -8.818344141545754
              ],
              [
                -39.78862917429554,
                -8.818712115661565
              ],
              [
                -39.74739354495754,
                -8.793635772955605
              ],
              [
                -39.7050358990229,
                -8.799146193336457
              ],
              [
                -39.69825146852237,
                -8.797977714454579
              ],
              [
                -39.69146803801733,
                -8.796809705573105
              ],
              [
                -39.674206191311555,
                -8.786662777583523
              ],
              [
                -39.67302967213748,
                -8.782800803788119
              ],
              [
                -39.67185272996579,
                -8.778938312999044
              ],
              [
                -39.673613184757635,
                -8.772725368232967
              ],
              [
                -39.67717717610282,
                -8.76014995484649
              ],
              [
                -39.67488119472826,
                -8.750863033810674
              ],
              [
                -39.67346822934064,
                -8.74514656042239
              ],
              [
                -39.67463871571889,
                -8.736276608619274
              ],
              [
                -39.68349366777219,
                -8.708667835613824
              ],
              [
                -39.69265061505007,
                -8.68011957183887
              ],
              [
                -39.691702640812075,
                -8.671020603783319
              ],
              [
                -39.69075462956414,
                -8.661922187736272
              ],
              [
                -39.660425399201216,
                -8.656968184801695
              ],
              [
                -39.653015947059714,
                -8.657932696792363
              ],
              [
                -39.645606036927454,
                -8.658898178775331
              ],
              [
                -39.63110167401127,
                -8.656537166597428
              ],
              [
                -39.625345207453755,
                -8.65560017911749
              ],
              [
                -39.61627480155864,
                -8.655592663711861
              ],
              [
                -39.60720386267705,
                -8.655585151307603
              ],
              [
                -39.569213695776675,
                -8.636577289833818
              ],
              [
                -39.56502322507389,
                -8.631041812701854
              ],
              [
                -39.56414776670123,
                -8.62988533140559
              ],
              [
                -39.56145628340886,
                -8.629332830974995
              ],
              [
                -39.557130819510796,
                -8.628444810499005
              ],
              [
                -39.55644882810773,
                -8.626609345385317
              ],
              [
                -39.554798849486104,
                -8.622168353567504
              ],
              [
                -39.53707199498764,
                -8.616036396310664
              ],
              [
                -39.525829115526705,
                -8.612147405558078
              ],
              [
                -39.5220326272866,
                -8.607829923869579
              ],
              [
                -39.51823516505498,
                -8.603510964197344
              ],
              [
                -39.475718535009406,
                -8.592400497866988
              ],
              [
                -39.46085716519853,
                -8.581649559509517
              ],
              [
                -39.46022270470733,
                -8.579460109087567
              ],
              [
                -39.45717370578299,
                -8.568939662953618
              ],
              [
                -39.451734259408056,
                -8.562794730329525
              ],
              [
                -39.44022537762055,
                -8.562875697155036
              ],
              [
                -39.43511341669287,
                -8.562911700192176
              ],
              [
                -39.42745148810905,
                -8.558268217459887
              ],
              [
                -39.41800507990127,
                -8.545008329782044
              ],
              [
                -39.415666586117425,
                -8.541725835901682
              ],
              [
                -39.41174664956433,
                -8.539975847247392
              ],
              [
                -39.40782717100782,
                -8.538226356592004
              ],
              [
                -39.38335337988306,
                -8.533222883198054
              ],
              [
                -39.37585594228553,
                -8.533577361720642
              ],
              [
                -39.362686072772966,
                -8.539245290749372
              ],
              [
                -39.35698558917865,
                -8.546634236873683
              ],
              [
                -39.34325220714453,
                -8.558158658047148
              ],
              [
                -39.30302654918745,
                -8.559798618072396
              ],
              [
                -39.288197156495286,
                -8.562900583535829
              ]
            ],
            [
              [
                -39.57615297965489,
                -18.088199316608872
              ],
              [
                -39.57203999330224,
                -18.091149278841822
              ],
              [
                -39.572874005741554,
                -18.083572825333295
              ],
              [
                -39.573559019714416,
                -18.080662845276045
              ],
              [
                -39.58119794234646,
                -18.082542343560625
              ],
              [
                -39.57615297965489,
                -18.088199316608872
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 6,
        "nome": "Ceará",
        "sigla": "CE",
        "regiao_id": 4,
        "codigo_ibg": 23
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -40.01804260828007,
                -2.836984811421101
              ],
              [
                -40.0058727082065,
                -2.841174779032817
              ],
              [
                -39.999410267540895,
                -2.842736750495392
              ],
              [
                -39.99857625742675,
                -2.843350777309662
              ],
              [
                -39.99466482572073,
                -2.846232232974479
              ],
              [
                -39.97590897624718,
                -2.854667165454381
              ],
              [
                -39.981186913942196,
                -2.849871696580891
              ],
              [
                -39.98376440879457,
                -2.847529217180594
              ],
              [
                -39.98635588853236,
                -2.845174257884147
              ],
              [
                -39.99014433872599,
                -2.844090247759156
              ],
              [
                -39.999410267540895,
                -2.842736750495392
              ],
              [
                -39.99209481185596,
                -2.841887264042827
              ],
              [
                -39.987328376214045,
                -2.842138275550305
              ],
              [
                -39.96086559050938,
                -2.855938135569169
              ],
              [
                -39.95315916067478,
                -2.859956616912137
              ],
              [
                -39.95314164818793,
                -2.858774637394544
              ],
              [
                -39.95311464197687,
                -2.856958649963519
              ],
              [
                -39.9429097348021,
                -2.859532601120883
              ],
              [
                -39.93855927524417,
                -2.861114595063855
              ],
              [
                -39.93536231951984,
                -2.863913567595084
              ],
              [
                -39.93232084151781,
                -2.866577056261603
              ],
              [
                -39.9302458535726,
                -2.868393562029433
              ],
              [
                -39.92338441580266,
                -2.871709512261024
              ],
              [
                -39.912358976776254,
                -2.877026473118072
              ],
              [
                -39.90864503052234,
                -2.876359489310143
              ],
              [
                -39.904554574488365,
                -2.875624972927389
              ],
              [
                -39.900903097281,
                -2.879558446195627
              ],
              [
                -39.89663162713225,
                -2.884159404907753
              ],
              [
                -39.89408114617637,
                -2.882933401952619
              ],
              [
                -39.89191216692347,
                -2.881890941643933
              ],
              [
                -39.886303704798145,
                -2.884359416058696
              ],
              [
                -39.857535947478404,
                -2.902797758905009
              ],
              [
                -39.8320846682186,
                -2.923803563110366
              ],
              [
                -39.82419872318815,
                -2.927211531226396
              ],
              [
                -39.82029827381924,
                -2.928897539440837
              ],
              [
                -39.81260931942908,
                -2.93450446694757
              ],
              [
                -39.81147282283139,
                -2.935333474925177
              ],
              [
                -39.802491382139266,
                -2.941882425444762
              ],
              [
                -39.79748942152515,
                -2.945529388545725
              ],
              [
                -39.79301196157273,
                -2.94879385788543
              ],
              [
                -39.783289578182185,
                -2.952227347184074
              ],
              [
                -39.77376813511387,
                -2.955590796104882
              ],
              [
                -39.75683777422994,
                -2.971101675022401
              ],
              [
                -39.74124641035146,
                -2.985386051202355
              ],
              [
                -39.73566843453367,
                -2.986307022995268
              ],
              [
                -39.73478498588382,
                -2.986836541453836
              ],
              [
                -39.73135300643837,
                -2.988892021823675
              ],
              [
                -39.72896999926595,
                -2.990319022583404
              ],
              [
                -39.72362554773508,
                -2.993520967117496
              ],
              [
                -39.72092109623884,
                -2.995140466224303
              ],
              [
                -39.71359663018832,
                -2.999527954583245
              ],
              [
                -39.705534709353266,
                -2.997912953943481
              ],
              [
                -39.70418220595733,
                -2.997641949682447
              ],
              [
                -39.69351780746083,
                -2.995505948390787
              ],
              [
                -39.68752085792133,
                -2.994304978100766
              ],
              [
                -39.68388489816808,
                -2.996553431842554
              ],
              [
                -39.680709410585,
                -2.998517444019905
              ],
              [
                -39.6751399728135,
                -3.002290402873042
              ],
              [
                -39.665907023965694,
                -3.013162805445176
              ],
              [
                -39.662124081756524,
                -3.017617796390128
              ],
              [
                -39.65378013801597,
                -3.023290226052815
              ],
              [
                -39.646819214499104,
                -3.022775732912416
              ],
              [
                -39.64553021668777,
                -3.022145251560878
              ],
              [
                -39.6431562422946,
                -3.020983727125446
              ],
              [
                -39.63782077242021,
                -3.018959276663549
              ],
              [
                -39.62938985722279,
                -3.022222730660139
              ],
              [
                -39.61905093614177,
                -3.025966709162843
              ],
              [
                -39.616751434280374,
                -3.030485668059586
              ],
              [
                -39.61227296460238,
                -3.039287079795444
              ],
              [
                -39.609637500385816,
                -3.04446653726971
              ],
              [
                -39.596285096442976,
                -3.065257870646614
              ],
              [
                -39.58948518373109,
                -3.070441318706491
              ],
              [
                -39.58089925170532,
                -3.071211829660632
              ],
              [
                -39.57338482257883,
                -3.071886817737917
              ],
              [
                -39.55743745393238,
                -3.077035259027198
              ],
              [
                -39.5504070150785,
                -3.079585264175506
              ],
              [
                -39.54653103819108,
                -3.082502244418253
              ],
              [
                -39.5404700753261,
                -3.087312185701992
              ],
              [
                -39.53740009872098,
                -3.089748678075869
              ],
              [
                -39.53295814446627,
                -3.093272632241688
              ],
              [
                -39.52118372678625,
                -3.102875553043888
              ],
              [
                -39.51559827471174,
                -3.105147520893535
              ],
              [
                -39.506087845472535,
                -3.109458515018372
              ],
              [
                -39.49912790884865,
                -3.112612947300371
              ],
              [
                -39.487098534755134,
                -3.118064419390334
              ],
              [
                -39.4775246041802,
                -3.128084335531566
              ],
              [
                -39.473780608849715,
                -3.132001818742792
              ],
              [
                -39.472915130939256,
                -3.132907808159758
              ],
              [
                -39.46457869922823,
                -3.141632238132887
              ],
              [
                -39.46079973824676,
                -3.142417709532634
              ],
              [
                -39.4575147741182,
                -3.142989218821131
              ],
              [
                -39.45216329852547,
                -3.143920689512503
              ],
              [
                -39.443628396950366,
                -3.145406186665662
              ],
              [
                -39.437310941563524,
                -3.146506169671408
              ],
              [
                -39.43312597570517,
                -3.148357151353256
              ],
              [
                -39.42890799612556,
                -3.150223163901762
              ],
              [
                -39.42524652487397,
                -3.151843123618812
              ],
              [
                -39.419947589375866,
                -3.154187123950176
              ],
              [
                -39.414880123969866,
                -3.15930208504997
              ],
              [
                -39.398313269953825,
                -3.176024422780183
              ],
              [
                -39.386046342601205,
                -3.182639364330642
              ],
              [
                -39.383965368015744,
                -3.183761347585174
              ],
              [
                -39.37760343856615,
                -3.180377910650794
              ],
              [
                -39.37353246111385,
                -3.178213419689016
              ],
              [
                -39.36454553859167,
                -3.173434941105295
              ],
              [
                -39.34496772496007,
                -3.179669383204105
              ],
              [
                -39.32449338882975,
                -3.201252198325915
              ],
              [
                -39.313414481438755,
                -3.207737180273739
              ],
              [
                -39.309275484022585,
                -3.207275666566758
              ],
              [
                -39.306528504639864,
                -3.206969661086568
              ],
              [
                -39.3046265263578,
                -3.208620645130277
              ],
              [
                -39.29719510977195,
                -3.215073108583121
              ],
              [
                -39.278870265822086,
                -3.220467524792503
              ],
              [
                -39.26900985278599,
                -3.21892805379421
              ],
              [
                -39.26375337408348,
                -3.21810704759732
              ],
              [
                -39.25215347719049,
                -3.221754530193289
              ],
              [
                -39.24036656973613,
                -3.234674932968141
              ],
              [
                -39.233125151226204,
                -3.239190385059096
              ],
              [
                -39.2258857107034,
                -3.243705345150494
              ],
              [
                -39.18729800621304,
                -3.293416448963731
              ],
              [
                -39.169503142325375,
                -3.323279686008621
              ],
              [
                -39.16212470229197,
                -3.324157165314924
              ],
              [
                -39.159626228554984,
                -3.32746416177699
              ],
              [
                -39.16083520885348,
                -3.331245107740108
              ],
              [
                -39.15660626314658,
                -3.33401308492898
              ],
              [
                -39.14586285581249,
                -3.341045029431184
              ],
              [
                -39.145351855077614,
                -3.341379529553204
              ],
              [
                -39.1393008895837,
                -3.34533999647577
              ],
              [
                -39.13565044306945,
                -3.344862999014717
              ],
              [
                -39.134672929521855,
                -3.344676001172273
              ],
              [
                -39.132261443373984,
                -3.344215008025696
              ],
              [
                -39.12739702443836,
                -3.343285000783229
              ],
              [
                -39.112348124252506,
                -3.34700797139605
              ],
              [
                -39.10751616327853,
                -3.356716398407936
              ],
              [
                -39.10590567895116,
                -3.359951888750808
              ],
              [
                -39.10334920106527,
                -3.365090347415846
              ],
              [
                -39.10296069712228,
                -3.365869830993597
              ],
              [
                -39.09653224613843,
                -3.374738249202488
              ],
              [
                -39.09022980813178,
                -3.383433683848448
              ],
              [
                -39.08696832651976,
                -3.387932631411817
              ],
              [
                -39.083156882356135,
                -3.393191107654366
              ],
              [
                -39.0805964037565,
                -3.394813574651536
              ],
              [
                -39.07336746517876,
                -3.39939304212453
              ],
              [
                -39.07282846068408,
                -3.399734553176648
              ],
              [
                -39.06989747718356,
                -3.401591548147121
              ],
              [
                -39.06579600387322,
                -3.403062999799209
              ],
              [
                -39.06232554420358,
                -3.401592031395367
              ],
              [
                -39.056614072742214,
                -3.405791474473404
              ],
              [
                -39.052760626770855,
                -3.408752988202398
              ],
              [
                -39.03944773036995,
                -3.410830449615692
              ],
              [
                -39.03177280393734,
                -3.406279982513518
              ],
              [
                -39.0256063531634,
                -3.402801508324153
              ],
              [
                -39.020703912235824,
                -3.402932998485956
              ],
              [
                -39.01630642497715,
                -3.403050495943747
              ],
              [
                -39.013719955948226,
                -3.400969529789466
              ],
              [
                -39.007658021786966,
                -3.396090558948319
              ],
              [
                -38.99457609985265,
                -3.39709102714447
              ],
              [
                -38.96604286420744,
                -3.419137841915769
              ],
              [
                -38.954114460867665,
                -3.435490214634398
              ],
              [
                -38.95226598284587,
                -3.438023695519317
              ],
              [
                -38.94884298758146,
                -3.442716148410156
              ],
              [
                -38.94737848977605,
                -3.4438186469794
              ],
              [
                -38.93806208135374,
                -3.450835594954147
              ],
              [
                -38.93273462825489,
                -3.464352468953287
              ],
              [
                -38.924785693413725,
                -3.481359344840376
              ],
              [
                -38.92092820057092,
                -3.489611778853697
              ],
              [
                -38.917695239976304,
                -3.493444758741476
              ],
              [
                -38.913464789528255,
                -3.498460695717434
              ],
              [
                -38.90865080684582,
                -3.504168166897828
              ],
              [
                -38.897035392648384,
                -3.504577158274351
              ],
              [
                -38.89089747739289,
                -3.504792644251144
              ],
              [
                -38.87459360232612,
                -3.519412534241458
              ],
              [
                -38.856565250543184,
                -3.535577893095458
              ],
              [
                -38.85113131450532,
                -3.540492843409549
              ],
              [
                -38.848195328812984,
                -3.540424830855148
              ],
              [
                -38.83822791129174,
                -3.544076831648733
              ],
              [
                -38.83624592210635,
                -3.544762283373778
              ],
              [
                -38.833239949608995,
                -3.545801800857042
              ],
              [
                -38.82627050762689,
                -3.545834778970059
              ],
              [
                -38.81871458870102,
                -3.545870799838429
              ],
              [
                -38.81524509350283,
                -3.543417804328912
              ],
              [
                -38.81194363489104,
                -3.54087433761528
              ],
              [
                -38.80555467440753,
                -3.547666764397351
              ],
              [
                -38.80348671219242,
                -3.55043823325294
              ],
              [
                -38.792540270037414,
                -3.56510611275679
              ],
              [
                -38.78695884033565,
                -3.572584558298833
              ],
              [
                -38.78563686606389,
                -3.574357059495389
              ],
              [
                -38.78270185988291,
                -3.578290027647369
              ],
              [
                -38.763184522262435,
                -3.604444818189823
              ],
              [
                -38.754578576878316,
                -3.611261255906223
              ],
              [
                -38.74727967362038,
                -3.617042715467361
              ],
              [
                -38.744828162022685,
                -3.618984684860123
              ],
              [
                -38.73926770434419,
                -3.620639176380728
              ],
              [
                -38.733312783026754,
                -3.622409667812786
              ],
              [
                -38.73079580844967,
                -3.623158655804256
              ],
              [
                -38.72854682159247,
                -3.623827130547908
              ],
              [
                -38.72016988116744,
                -3.628242594703347
              ],
              [
                -38.715281414355374,
                -3.630818559038797
              ],
              [
                -38.7132434605268,
                -3.631892586588572
              ],
              [
                -38.70427901206399,
                -3.636616516019787
              ],
              [
                -38.68898063524877,
                -3.651850381181447
              ],
              [
                -38.666507334568614,
                -3.674369227750026
              ],
              [
                -38.65449391419783,
                -3.679763681549145
              ],
              [
                -38.649766466177546,
                -3.681929158234529
              ],
              [
                -38.638854558466534,
                -3.686928106642357
              ],
              [
                -38.625117666902575,
                -3.687418596337841
              ],
              [
                -38.61814425604818,
                -3.687628610929379
              ],
              [
                -38.61239479164144,
                -3.687801071300396
              ],
              [
                -38.60835880341948,
                -3.688019071970237
              ],
              [
                -38.60108337917237,
                -3.690859543202999
              ],
              [
                -38.594918430490374,
                -3.693301044087183
              ],
              [
                -38.592769461854246,
                -3.691233070951022
              ],
              [
                -38.589393478767185,
                -3.691894526295198
              ],
              [
                -38.585498010130934,
                -3.692657035620718
              ],
              [
                -38.5798525794851,
                -3.693763039490221
              ],
              [
                -38.57310710437648,
                -3.697388487579909
              ],
              [
                -38.566145690051094,
                -3.701238951764575
              ],
              [
                -38.5635182001978,
                -3.702692939995059
              ],
              [
                -38.55658827261261,
                -3.706525927328508
              ],
              [
                -38.54122589012201,
                -3.715022351688751
              ],
              [
                -38.537891908521836,
                -3.716116341546543
              ],
              [
                -38.53468346185372,
                -3.717168340792136
              ],
              [
                -38.529710479218984,
                -3.718799312668925
              ],
              [
                -38.52606151430616,
                -3.719996798565402
              ],
              [
                -38.52175355307998,
                -3.718650789754757
              ],
              [
                -38.519508569765264,
                -3.717949805540528
              ],
              [
                -38.51090665346531,
                -3.719729307794673
              ],
              [
                -38.50957715274667,
                -3.720288792753008
              ],
              [
                -38.49876325451244,
                -3.724841230727625
              ],
              [
                -38.48548987667448,
                -3.721387276448482
              ],
              [
                -38.48119838523486,
                -3.720270791795342
              ],
              [
                -38.476919942784626,
                -3.718894776246894
              ],
              [
                -38.476740448259335,
                -3.713930846311063
              ],
              [
                -38.471724479213194,
                -3.707322393791568
              ],
              [
                -38.46622602930633,
                -3.713750332666513
              ],
              [
                -38.463792057641015,
                -3.718213814622211
              ],
              [
                -38.45963957362521,
                -3.725825712447452
              ],
              [
                -38.455933608059546,
                -3.73261916006578
              ],
              [
                -38.43902873428718,
                -3.763609916864417
              ],
              [
                -38.437045755028166,
                -3.76724587768906
              ],
              [
                -38.43539976309411,
                -3.77026238165436
              ],
              [
                -38.43050432547428,
                -3.777902802956868
              ],
              [
                -38.42855334116747,
                -3.780948294566784
              ],
              [
                -38.42634386794491,
                -3.78438323392623
              ],
              [
                -38.42449185084541,
                -3.787263238912772
              ],
              [
                -38.41926189792091,
                -3.795397147092044
              ],
              [
                -38.407334987862754,
                -3.81394452643981
              ],
              [
                -38.40144804921172,
                -3.82309993009856
              ],
              [
                -38.39761808501632,
                -3.829050880475266
              ],
              [
                -38.39573961512466,
                -3.83196884414287
              ],
              [
                -38.379137721633356,
                -3.857758633081438
              ],
              [
                -38.371502279024924,
                -3.869620070165977
              ],
              [
                -38.363866855409185,
                -3.88148243624132
              ],
              [
                -38.352401925952925,
                -3.893400869299696
              ],
              [
                -38.34695846584193,
                -3.899060303364431
              ],
              [
                -38.34566698149216,
                -3.90040278799313
              ],
              [
                -38.34280800606898,
                -3.903374250823319
              ],
              [
                -38.32500368280522,
                -3.919646613094906
              ],
              [
                -38.32150321185295,
                -3.922845121822944
              ],
              [
                -38.32140119369944,
                -3.922938590026066
              ],
              [
                -38.318105224050846,
                -3.925949594351643
              ],
              [
                -38.30363984063497,
                -3.935396514094471
              ],
              [
                -38.299679865756566,
                -3.940833965542058
              ],
              [
                -38.30792432706097,
                -3.938867975820555
              ],
              [
                -38.30259935251569,
                -3.943085937557097
              ],
              [
                -38.297159916750005,
                -3.942941948475821
              ],
              [
                -38.29966539846027,
                -3.936971987731254
              ],
              [
                -38.28928246183754,
                -3.941077925284266
              ],
              [
                -38.2883744857127,
                -3.941079433897754
              ],
              [
                -38.277664059614665,
                -3.941095433353437
              ],
              [
                -38.270868139486865,
                -3.946196890333055
              ],
              [
                -38.251868277650765,
                -3.969836189483649
              ],
              [
                -38.248624293142555,
                -3.973870660543479
              ],
              [
                -38.24538182462066,
                -3.977905626599241
              ],
              [
                -38.23651391703538,
                -3.988939061776072
              ],
              [
                -38.22902096022071,
                -3.998260948347711
              ],
              [
                -38.22253201320595,
                -4.006334404420045
              ],
              [
                -38.2053041654616,
                -4.028755198096946
              ],
              [
                -38.20341266885993,
                -4.031216691420544
              ],
              [
                -38.20199817337468,
                -4.033058188952155
              ],
              [
                -38.19849172587865,
                -4.035325167166256
              ],
              [
                -38.19743321478534,
                -4.03600966219194
              ],
              [
                -38.19288676904157,
                -4.038949617530998
              ],
              [
                -38.1717469506253,
                -4.06766387268905
              ],
              [
                -38.16535049551475,
                -4.076406315373628
              ],
              [
                -38.15895502739358,
                -4.085147740064727
              ],
              [
                -38.15091261162842,
                -4.096140647871103
              ],
              [
                -38.14716313304017,
                -4.101266094884789
              ],
              [
                -38.14385415668495,
                -4.106245088268725
              ],
              [
                -38.12248084776163,
                -4.138406816441858
              ],
              [
                -38.12042685229591,
                -4.14149778560493
              ],
              [
                -38.117898849645364,
                -4.145301749807914
              ],
              [
                -38.11492939138238,
                -4.147822236180171
              ],
              [
                -38.11179192351934,
                -4.150485692324996
              ],
              [
                -38.09552253806113,
                -4.164295092812731
              ],
              [
                -38.0802616910259,
                -4.179998473429672
              ],
              [
                -38.07140425713803,
                -4.189112397008484
              ],
              [
                -38.06796728250766,
                -4.192650335953334
              ],
              [
                -38.06598479287245,
                -4.194689342630601
              ],
              [
                -38.048911421806224,
                -4.212258204362546
              ],
              [
                -38.047442467932115,
                -4.213769666519839
              ],
              [
                -38.04597395605402,
                -4.215281647672942
              ],
              [
                -38.01332771277037,
                -4.246088400678511
              ],
              [
                -38.006106281733274,
                -4.250448884298807
              ],
              [
                -38.002109833472865,
                -4.252861837058944
              ],
              [
                -38.00088534581087,
                -4.253600829553576
              ],
              [
                -38.00088232783609,
                -4.25360334353191
              ],
              [
                -37.993571413561874,
                -4.258017290674803
              ],
              [
                -37.989313924509396,
                -4.260588277040396
              ],
              [
                -37.98296100280334,
                -4.267494728482643
              ],
              [
                -37.97974852723033,
                -4.271024684567381
              ],
              [
                -37.978430525072575,
                -4.272473177291785
              ],
              [
                -37.97258356117158,
                -4.278898645835731
              ],
              [
                -37.95863018795605,
                -4.294234500854761
              ],
              [
                -37.91812453697116,
                -4.327979720397159
              ],
              [
                -37.910797583122886,
                -4.334083151848675
              ],
              [
                -37.90906459358685,
                -4.335527145415951
              ],
              [
                -37.8982711866757,
                -4.344518570993869
              ],
              [
                -37.88752378538607,
                -4.353472501886058
              ],
              [
                -37.88168533348084,
                -4.3575374534446
              ],
              [
                -37.88053184318073,
                -4.35834045944299
              ],
              [
                -37.87238639967938,
                -4.364011414994064
              ],
              [
                -37.857363044029036,
                -4.374471817770373
              ],
              [
                -37.85413754815831,
                -4.376717272185591
              ],
              [
                -37.85091210228767,
                -4.378963270595769
              ],
              [
                -37.83526921834072,
                -4.382146731811451
              ],
              [
                -37.82004182635905,
                -4.390737164553356
              ],
              [
                -37.81404237819612,
                -4.394122143500677
              ],
              [
                -37.81032390370684,
                -4.396219632877036
              ],
              [
                -37.806803948587294,
                -4.398092103159453
              ],
              [
                -37.80227750356981,
                -4.397049124524719
              ],
              [
                -37.79619105533517,
                -4.395646127090322
              ],
              [
                -37.77784869909836,
                -4.403151038148184
              ],
              [
                -37.7696322827455,
                -4.401160078495916
              ],
              [
                -37.76832578110265,
                -4.405709540154247
              ],
              [
                -37.767077809039456,
                -4.410054009496167
              ],
              [
                -37.763472295727624,
                -4.422874402312237
              ],
              [
                -37.76446779578339,
                -4.423425885313068
              ],
              [
                -37.764632317305086,
                -4.423517378649315
              ],
              [
                -37.76638228758206,
                -4.42448739161509
              ],
              [
                -37.76907826536516,
                -4.425969873875319
              ],
              [
                -37.751926896692325,
                -4.442427730137101
              ],
              [
                -37.751290921970096,
                -4.44303771992025
              ],
              [
                -37.74276195359074,
                -4.471271508060199
              ],
              [
                -37.73999498641367,
                -4.476403473359111
              ],
              [
                -37.72898709621648,
                -4.496820277481874
              ],
              [
                -37.72682661209135,
                -4.499000760882748
              ],
              [
                -37.72628511857123,
                -4.499547743217263
              ],
              [
                -37.723582626930686,
                -4.502275260951476
              ],
              [
                -37.72334762787505,
                -4.502512241929886
              ],
              [
                -37.705623288523626,
                -4.520401074324175
              ],
              [
                -37.70300879552802,
                -4.522236072297862
              ],
              [
                -37.70002381667006,
                -4.524290041331279
              ],
              [
                -37.699769824809316,
                -4.524465061800476
              ],
              [
                -37.69912534223759,
                -4.524909049917039
              ],
              [
                -37.68818791936514,
                -4.532441472027054
              ],
              [
                -37.66156865458892,
                -4.563719242157686
              ],
              [
                -37.659854667639834,
                -4.565733224101139
              ],
              [
                -37.65395220502697,
                -4.572668168367036
              ],
              [
                -37.63983481711476,
                -4.586357532257818
              ],
              [
                -37.627908425859204,
                -4.596166954475986
              ],
              [
                -37.6073265990105,
                -4.61309382215268
              ],
              [
                -37.60723361078834,
                -4.613170286491615
              ],
              [
                -37.59827117175958,
                -4.620540758767727
              ],
              [
                -37.5980401796919,
                -4.620730747125022
              ],
              [
                -37.59492070578715,
                -4.623296719938916
              ],
              [
                -37.59256920345836,
                -4.625231192212582
              ],
              [
                -37.5663719353118,
                -4.639002567622836
              ],
              [
                -37.56218397461718,
                -4.639779576357764
              ],
              [
                -37.551533559953306,
                -4.641756555329444
              ],
              [
                -37.54309611811196,
                -4.643322546670369
              ],
              [
                -37.54006564639035,
                -4.643884536690318
              ],
              [
                -37.53713517848095,
                -4.642994026483031
              ],
              [
                -37.536977673883094,
                -4.642946543791325
              ],
              [
                -37.52176081437358,
                -4.638323562873681
              ],
              [
                -37.49867202671378,
                -4.625534149206914
              ],
              [
                -37.49479757561585,
                -4.627767629326622
              ],
              [
                -37.49092310451882,
                -4.630002145436926
              ],
              [
                -37.48821060674278,
                -4.633289101595196
              ],
              [
                -37.47911018830312,
                -4.64431652018559
              ],
              [
                -37.47308174315205,
                -4.648538448210554
              ],
              [
                -37.4682257792958,
                -4.649438954624044
              ],
              [
                -37.46620579082731,
                -4.649813970632653
              ],
              [
                -37.460925349659014,
                -4.650792927209481
              ],
              [
                -37.44646846347675,
                -4.657014397055073
              ],
              [
                -37.443519981731995,
                -4.658282863400299
              ],
              [
                -37.44057199598365,
                -4.65955137574495
              ],
              [
                -37.41095178574782,
                -4.672296741612049
              ],
              [
                -37.400896853776466,
                -4.674797720594731
              ],
              [
                -37.399592894063545,
                -4.675121718352867
              ],
              [
                -37.396405382655026,
                -4.675914706420144
              ],
              [
                -37.390118945074306,
                -4.677478203777694
              ],
              [
                -37.376473581210774,
                -4.680872148818358
              ],
              [
                -37.36832513305436,
                -4.684396620427371
              ],
              [
                -37.361556196078446,
                -4.687324121519683
              ],
              [
                -37.357475244062876,
                -4.689089118295851
              ],
              [
                -37.357374205724454,
                -4.689563099415158
              ],
              [
                -37.356836238245485,
                -4.692088562738626
              ],
              [
                -37.351540799287605,
                -4.693025553626121
              ],
              [
                -37.35069529975156,
                -4.692939064920396
              ],
              [
                -37.341556382431676,
                -4.692007554075908
              ],
              [
                -37.33258043486521,
                -4.697011006314397
              ],
              [
                -37.329385950068925,
                -4.698792023380214
              ],
              [
                -37.32490851119987,
                -4.701287470052447
              ],
              [
                -37.319332071587816,
                -4.706712442504193
              ],
              [
                -37.31069064543672,
                -4.715196853738672
              ],
              [
                -37.3055291753527,
                -4.720264318275585
              ],
              [
                -37.30223967470404,
                -4.72349430357155
              ],
              [
                -37.28679282816392,
                -4.742788152100344
              ],
              [
                -37.28353484206906,
                -4.748452100726569
              ],
              [
                -37.28027738996777,
                -4.754116027353479
              ],
              [
                -37.270977926893764,
                -4.784763279081433
              ],
              [
                -37.27006696144356,
                -4.787769267341313
              ],
              [
                -37.26935095059054,
                -4.790133263885537
              ],
              [
                -37.266517482956786,
                -4.799482158946164
              ],
              [
                -37.26495646802996,
                -4.80287163079175
              ],
              [
                -37.25646856462739,
                -4.821374479121751
              ],
              [
                -37.25631204483671,
                -4.82171599428559
              ],
              [
                -37.25157408044322,
                -4.83204342151852
              ],
              [
                -37.256510026330034,
                -4.837607350000613
              ],
              [
                -37.3564396610761,
                -4.860604739781483
              ],
              [
                -37.3573736357688,
                -4.86082023850698
              ],
              [
                -37.357384176675026,
                -4.860822718492187
              ],
              [
                -37.4450078676546,
                -4.881039617182775
              ],
              [
                -37.466232210063794,
                -4.885936593360293
              ],
              [
                -37.638911172223544,
                -4.92578982488398
              ],
              [
                -37.63994516805295,
                -4.92602884248889
              ],
              [
                -37.67530532278209,
                -5.04720090096638
              ],
              [
                -37.6762833067265,
                -5.05009886726323
              ],
              [
                -37.677261301670036,
                -5.052996821561867
              ],
              [
                -37.689726681961375,
                -5.056162336698628
              ],
              [
                -37.711581996892036,
                -5.061711271662409
              ],
              [
                -37.723671864119645,
                -5.069471244868108
              ],
              [
                -37.72925334667402,
                -5.080297132167886
              ],
              [
                -37.73303428318773,
                -5.100265006432683
              ],
              [
                -37.764566011006046,
                -5.13923869954616
              ],
              [
                -37.77592038756741,
                -5.186741823235727
              ],
              [
                -37.78964173690395,
                -5.210563663490451
              ],
              [
                -37.78633575595,
                -5.219623584432472
              ],
              [
                -37.78349480460678,
                -5.227412005771725
              ],
              [
                -37.78303781392874,
                -5.228665493526395
              ],
              [
                -37.78305580430204,
                -5.231401465712585
              ],
              [
                -37.78312877972179,
                -5.242450407629126
              ],
              [
                -37.78507678603703,
                -5.247379365377013
              ],
              [
                -37.79080971909161,
                -5.261884738834326
              ],
              [
                -37.789352234088255,
                -5.272501652411401
              ],
              [
                -37.78672873326249,
                -5.277596597373564
              ],
              [
                -37.782220261929,
                -5.286350560146225
              ],
              [
                -37.78271878617619,
                -5.29443748597162
              ],
              [
                -37.78593324379404,
                -5.296788478994579
              ],
              [
                -37.811761487232,
                -5.316567303594342
              ],
              [
                -37.83532429221389,
                -5.335007186762827
              ],
              [
                -37.841203735567156,
                -5.337326682546557
              ],
              [
                -37.847083704918745,
                -5.339645648338725
              ],
              [
                -37.862884047840545,
                -5.375987880039206
              ],
              [
                -37.86063904436204,
                -5.386982289384186
              ],
              [
                -37.85892905165435,
                -5.395355221874115
              ],
              [
                -37.86009204740521,
                -5.406306133486844
              ],
              [
                -37.88074585451211,
                -5.435804407722833
              ],
              [
                -37.88356981716656,
                -5.440586358338015
              ],
              [
                -37.886394303814505,
                -5.445368354958831
              ],
              [
                -37.88776279891923,
                -5.45417029885093
              ],
              [
                -37.89183673057077,
                -5.480379080144682
              ],
              [
                -37.89722816696279,
                -5.484836543836502
              ],
              [
                -37.8980301695834,
                -5.485499529031864
              ],
              [
                -37.89928365732551,
                -5.489583989340427
              ],
              [
                -37.89972716563372,
                -5.491030005121413
              ],
              [
                -37.90159813783677,
                -5.497133442770976
              ],
              [
                -37.90439610734424,
                -5.500761405588386
              ],
              [
                -37.917102488988036,
                -5.504982387353737
              ],
              [
                -37.9246754135037,
                -5.503854898610014
              ],
              [
                -37.93224836602521,
                -5.502727399865611
              ],
              [
                -37.943765773075306,
                -5.526280734762516
              ],
              [
                -37.94638272817614,
                -5.531633189851722
              ],
              [
                -37.951208174117326,
                -5.535023663757393
              ],
              [
                -37.95603415805419,
                -5.538414650664355
              ],
              [
                -37.989650360947515,
                -5.555416030374555
              ],
              [
                -37.99757377322978,
                -5.57035040575186
              ],
              [
                -37.99757677913415,
                -5.570470931799854
              ],
              [
                -37.997939774627405,
                -5.58493631655883
              ],
              [
                -38.009717640921785,
                -5.591776264283576
              ],
              [
                -38.013567603043214,
                -5.594012262834331
              ],
              [
                -38.01891808184898,
                -5.605272663902836
              ],
              [
                -38.03388394599342,
                -5.636770915680485
              ],
              [
                -38.04344134902708,
                -5.641246390919821
              ],
              [
                -38.05299875006688,
                -5.645721871171856
              ],
              [
                -38.07620654720605,
                -5.66367224307841
              ],
              [
                -38.079270527001725,
                -5.667919689357908
              ],
              [
                -38.08233498579131,
                -5.67216815663462
              ],
              [
                -38.082455451511734,
                -5.698020955722456
              ],
              [
                -38.08930689006043,
                -5.714463345102484
              ],
              [
                -38.08856640466323,
                -5.727705737244359
              ],
              [
                -38.086780900782955,
                -5.730140734982529
              ],
              [
                -38.07285555231662,
                -5.723188753511159
              ],
              [
                -38.07222754873778,
                -5.723280783411788
              ],
              [
                -38.0613821463691,
                -5.724863762475168
              ],
              [
                -38.057115679906275,
                -5.729363716443231
              ],
              [
                -38.046690272437,
                -5.730027702000312
              ],
              [
                -38.05136023585631,
                -5.73987914407866
              ],
              [
                -38.05393167520349,
                -5.745303579834389
              ],
              [
                -38.05397319078936,
                -5.745391097169016
              ],
              [
                -38.057533151270675,
                -5.75290003808658
              ],
              [
                -38.05772164690737,
                -5.755785003453425
              ],
              [
                -38.075850481893106,
                -5.780754327522007
              ],
              [
                -38.10430671412076,
                -5.811834589891763
              ],
              [
                -38.11047066416914,
                -5.818831532543149
              ],
              [
                -38.118479583128064,
                -5.832451939209807
              ],
              [
                -38.11612208029063,
                -5.846293333877916
              ],
              [
                -38.11589860544384,
                -5.84760330243659
              ],
              [
                -38.12455352887596,
                -5.887228999107372
              ],
              [
                -38.142167843773215,
                -5.901258400653477
              ],
              [
                -38.142364869635486,
                -5.901944900380385
              ],
              [
                -38.14633432254475,
                -5.915794309016922
              ],
              [
                -38.15899367016622,
                -5.92403276511565
              ],
              [
                -38.16330367128676,
                -5.929433691366142
              ],
              [
                -38.16354264102043,
                -5.929732721165808
              ],
              [
                -38.163532640990475,
                -5.929922685668125
              ],
              [
                -38.16310562841495,
                -5.938511620970287
              ],
              [
                -38.16433212129072,
                -5.945698594309958
              ],
              [
                -38.16537910172625,
                -5.946414079342168
              ],
              [
                -38.16546661792694,
                -5.94647359892916
              ],
              [
                -38.17504705940917,
                -5.953019516491371
              ],
              [
                -38.17965952133862,
                -5.951221540461966
              ],
              [
                -38.18319798536738,
                -5.949841576485349
              ],
              [
                -38.189499921325485,
                -5.956540021836027
              ],
              [
                -38.19198189707117,
                -5.959178987673457
              ],
              [
                -38.19880681814214,
                -5.961571989141857
              ],
              [
                -38.20563175821697,
                -5.963964454619292
              ],
              [
                -38.244117901245076,
                -5.999075191401473
              ],
              [
                -38.2476998746581,
                -6.004521177979675
              ],
              [
                -38.25128185906837,
                -6.009967637562029
              ],
              [
                -38.25147485200541,
                -6.016964561880786
              ],
              [
                -38.252183824656,
                -6.042718884663183
              ],
              [
                -38.25978226634621,
                -6.049329839709895
              ],
              [
                -38.258799256339664,
                -6.053395294268998
              ],
              [
                -38.2626202218826,
                -6.053706795246262
              ],
              [
                -38.26459321560754,
                -6.053867296237402
              ],
              [
                -38.27412460964281,
                -6.064957229492421
              ],
              [
                -38.27451559995189,
                -6.065412697176734
              ],
              [
                -38.276624576968736,
                -6.069567194011968
              ],
              [
                -38.29040748099529,
                -6.072767164724506
              ],
              [
                -38.304417835325594,
                -6.086582089602087
              ],
              [
                -38.31197577872062,
                -6.081618118216244
              ],
              [
                -38.319533738119475,
                -6.076655151818761
              ],
              [
                -38.33637757347985,
                -6.080012142287709
              ],
              [
                -38.33888554375242,
                -6.076727661552073
              ],
              [
                -38.346399997638514,
                -6.066893759184635
              ],
              [
                -38.34679499541093,
                -6.076029179039508
              ],
              [
                -38.34698046307764,
                -6.080314629669085
              ],
              [
                -38.35072545848012,
                -6.081938137366415
              ],
              [
                -38.35575441236545,
                -6.084118124535456
              ],
              [
                -38.36486983759559,
                -6.088069614473405
              ],
              [
                -38.37206225257735,
                -6.082546149201519
              ],
              [
                -38.379391685968834,
                -6.072932228965487
              ],
              [
                -38.378294712705454,
                -6.067887267679687
              ],
              [
                -38.37798123734418,
                -6.066446269738347
              ],
              [
                -38.38287917568102,
                -6.065008267084298
              ],
              [
                -38.385308139020445,
                -6.064295290197167
              ],
              [
                -38.38515165083326,
                -6.063581268675835
              ],
              [
                -38.38401016804697,
                -6.058385335545125
              ],
              [
                -38.38274717066099,
                -6.052640890627884
              ],
              [
                -38.394496589047385,
                -6.055003346620908
              ],
              [
                -38.39456460246955,
                -6.054982366827968
              ],
              [
                -38.39631405960086,
                -6.054452860074417
              ],
              [
                -38.40071405912497,
                -6.053249865263393
              ],
              [
                -38.4044959969445,
                -6.056906859096596
              ],
              [
                -38.412803430106905,
                -6.057935366337481
              ],
              [
                -38.43105723854228,
                -6.075223728959323
              ],
              [
                -38.446933609477774,
                -6.084893144618058
              ],
              [
                -38.443593654813874,
                -6.093793583036136
              ],
              [
                -38.44211213816282,
                -6.097742052281788
              ],
              [
                -38.446709609101084,
                -6.101072539240088
              ],
              [
                -38.45322405050264,
                -6.105791014604704
              ],
              [
                -38.45686152122519,
                -6.108425504387692
              ],
              [
                -38.46934589758681,
                -6.129044318633053
              ],
              [
                -38.476034858864644,
                -6.14402920414789
              ],
              [
                -38.47602482894497,
                -6.144039710059516
              ],
              [
                -38.4716843698526,
                -6.148362698570871
              ],
              [
                -38.47275185737307,
                -6.15179066254758
              ],
              [
                -38.500378089716975,
                -6.179298946168929
              ],
              [
                -38.50094357850339,
                -6.190558898875058
              ],
              [
                -38.51541645798001,
                -6.191919882695749
              ],
              [
                -38.52682237628297,
                -6.192992878768545
              ],
              [
                -38.52890531994761,
                -6.213354713709022
              ],
              [
                -38.52802733901147,
                -6.214207719500221
              ],
              [
                -38.51933089998464,
                -6.222651120138008
              ],
              [
                -38.527909840104805,
                -6.23704902177866
              ],
              [
                -38.53808374360267,
                -6.246510969889417
              ],
              [
                -38.54385569226422,
                -6.245362487607725
              ],
              [
                -38.54546919847262,
                -6.245041483162549
              ],
              [
                -38.55404160028026,
                -6.263010862150736
              ],
              [
                -38.56255153845905,
                -6.267539310576383
              ],
              [
                -38.578216868459656,
                -6.279808741621125
              ],
              [
                -38.57115344593985,
                -6.284047189038189
              ],
              [
                -38.57046794018965,
                -6.292668139641992
              ],
              [
                -38.56991494139451,
                -6.299609066384849
              ],
              [
                -38.56903844664706,
                -6.300141058673566
              ],
              [
                -38.565643494738886,
                -6.302202054421534
              ],
              [
                -38.57396487761957,
                -6.309624486322043
              ],
              [
                -38.58091533020509,
                -6.315823953822867
              ],
              [
                -38.58103830820955,
                -6.323241914339913
              ],
              [
                -38.581161810203525,
                -6.330659829864676
              ],
              [
                -38.57669386104921,
                -6.347042221789326
              ],
              [
                -38.573248874058365,
                -6.348363210240901
              ],
              [
                -38.563914958663574,
                -6.35194065725212
              ],
              [
                -38.56400744828686,
                -6.355795141411944
              ],
              [
                -38.56447794063744,
                -6.356643139148321
              ],
              [
                -38.56697292598189,
                -6.361138598946475
              ],
              [
                -38.571956364809,
                -6.362528611500969
              ],
              [
                -38.5754068312252,
                -6.363491598342648
              ],
              [
                -38.57453436139098,
                -6.367084568894212
              ],
              [
                -38.572725851095925,
                -6.374531005937656
              ],
              [
                -38.579004316314446,
                -6.374984010636726
              ],
              [
                -38.587864250015876,
                -6.375622993627575
              ],
              [
                -38.59208521089563,
                -6.377861499107616
              ],
              [
                -38.59456216382134,
                -6.386182433274898
              ],
              [
                -38.601666107143245,
                -6.389236923380428
              ],
              [
                -38.59374717897046,
                -6.396476345949561
              ],
              [
                -38.588974692282456,
                -6.395127376190483
              ],
              [
                -38.581163758233025,
                -6.396345852488901
              ],
              [
                -38.58069479941541,
                -6.400635794924265
              ],
              [
                -38.57290184019134,
                -6.403377761423013
              ],
              [
                -38.5575124964131,
                -6.399417316759787
              ],
              [
                -38.5573059632413,
                -6.399364321031671
              ],
              [
                -38.55188753820878,
                -6.397969815197134
              ],
              [
                -38.55114152181317,
                -6.397777829183787
              ],
              [
                -38.549798065083486,
                -6.39835930677133
              ],
              [
                -38.5423326307128,
                -6.401592800675389
              ],
              [
                -38.54021264708984,
                -6.400145290473135
              ],
              [
                -38.52981122216257,
                -6.39304382655431
              ],
              [
                -38.51752830052819,
                -6.408345717608759
              ],
              [
                -38.52413625725994,
                -6.406695218858531
              ],
              [
                -38.536530665440786,
                -6.417395166243806
              ],
              [
                -38.5445415792286,
                -6.424311606036055
              ],
              [
                -38.54464059731164,
                -6.424396614444068
              ],
              [
                -38.55388298069103,
                -6.432376057850706
              ],
              [
                -38.554544973834034,
                -6.436975495658864
              ],
              [
                -38.55602395603441,
                -6.447256417007449
              ],
              [
                -38.56943434173265,
                -6.454488374089586
              ],
              [
                -38.569679860320065,
                -6.460793822425222
              ],
              [
                -38.56971483440332,
                -6.461696326460165
              ],
              [
                -38.57002984115438,
                -6.469807282869391
              ],
              [
                -38.591028657288014,
                -6.495664085062622
              ],
              [
                -38.594594087348185,
                -6.500054043536933
              ],
              [
                -38.59753508834731,
                -6.502220049793261
              ],
              [
                -38.59775258835061,
                -6.502380035704492
              ],
              [
                -38.61190845239195,
                -6.512803462785543
              ],
              [
                -38.61515291352946,
                -6.525500380840051
              ],
              [
                -38.615729413978016,
                -6.52775587079938
              ],
              [
                -38.61223744648146,
                -6.5303613262561
              ],
              [
                -38.60945796816976,
                -6.532435314310742
              ],
              [
                -38.62171635468653,
                -6.547151695007324
              ],
              [
                -38.62622531083688,
                -6.55256566228394
              ],
              [
                -38.62586231265274,
                -6.554496678113448
              ],
              [
                -38.62528081755796,
                -6.557594136780253
              ],
              [
                -38.62892628232493,
                -6.559924122298397
              ],
              [
                -38.63225075101885,
                -6.562048582184922
              ],
              [
                -38.63270121742639,
                -6.568821552180083
              ],
              [
                -38.64247266337799,
                -6.580790477518738
              ],
              [
                -38.63341821137633,
                -6.588773901593442
              ],
              [
                -38.632553708346165,
                -6.589536377105985
              ],
              [
                -38.6317112153846,
                -6.592804874284373
              ],
              [
                -38.62877873990751,
                -6.604191262338026
              ],
              [
                -38.645228610747914,
                -6.614908225077512
              ],
              [
                -38.64627809516843,
                -6.615591712534949
              ],
              [
                -38.64682208666722,
                -6.618147700191235
              ],
              [
                -38.64843606437472,
                -6.625730629810172
              ],
              [
                -38.64874804464392,
                -6.627197608710334
              ],
              [
                -38.650124548251334,
                -6.627846617664921
              ],
              [
                -38.65488450839849,
                -6.630091083676544
              ],
              [
                -38.65670095838164,
                -6.641899520867795
              ],
              [
                -38.65162152124334,
                -6.647884971149153
              ],
              [
                -38.64658454877209,
                -6.653819906848388
              ],
              [
                -38.646835546724866,
                -6.663605857563063
              ],
              [
                -38.651582020093166,
                -6.672793288066042
              ],
              [
                -38.659649937495516,
                -6.67367325695837
              ],
              [
                -38.66197993510839,
                -6.67392724864008
              ],
              [
                -38.661418428981804,
                -6.675342774334513
              ],
              [
                -38.660200924378735,
                -6.678413730808172
              ],
              [
                -38.66408241373612,
                -6.679802216840404
              ],
              [
                -38.67277029337424,
                -6.696831107755973
              ],
              [
                -38.66966082956813,
                -6.70216856144179
              ],
              [
                -38.67222730020515,
                -6.706558025444321
              ],
              [
                -38.67113131807708,
                -6.710277995016287
              ],
              [
                -38.66450836600767,
                -6.723698899961136
              ],
              [
                -38.65559294297327,
                -6.729854326233701
              ],
              [
                -38.65666442554907,
                -6.737076279378613
              ],
              [
                -38.65715293297067,
                -6.74036927536898
              ],
              [
                -38.652004474075184,
                -6.74818268654786
              ],
              [
                -38.64959098140565,
                -6.751845682627897
              ],
              [
                -38.64999599109073,
                -6.752970678254566
              ],
              [
                -38.65128896731421,
                -6.756562625521306
              ],
              [
                -38.6510534391609,
                -6.758241625427869
              ],
              [
                -38.65082494097835,
                -6.759875613686233
              ],
              [
                -38.645776522553334,
                -6.761593581863645
              ],
              [
                -38.63988956420253,
                -6.763598064236645
              ],
              [
                -38.62863364107863,
                -6.777324443534249
              ],
              [
                -38.613774243136916,
                -6.782794423811708
              ],
              [
                -38.616634740526386,
                -6.792274328875583
              ],
              [
                -38.61702774118226,
                -6.793577324126709
              ],
              [
                -38.623277176448376,
                -6.79147086281132
              ],
              [
                -38.62908961140588,
                -6.789511351050832
              ],
              [
                -38.629420129520625,
                -6.789534861106274
              ],
              [
                -38.641143515180445,
                -6.790381365977805
              ],
              [
                -38.6361485813522,
                -6.797600326851012
              ],
              [
                -38.63593155901117,
                -6.797913291287335
              ],
              [
                -38.635078078887254,
                -6.804230269068899
              ],
              [
                -38.64673645558908,
                -6.807200249579588
              ],
              [
                -38.64883795597047,
                -6.80773522197191
              ],
              [
                -38.65529338656119,
                -6.813857217517334
              ],
              [
                -38.66091431555882,
                -6.822524140907424
              ],
              [
                -38.67366871840185,
                -6.830172574284895
              ],
              [
                -38.676740688983465,
                -6.832628087623225
              ],
              [
                -38.67252222277958,
                -6.839275505488957
              ],
              [
                -38.670143230709606,
                -6.843024987954772
              ],
              [
                -38.67098223140174,
                -6.844449970608316
              ],
              [
                -38.67148472542623,
                -6.845302953415465
              ],
              [
                -38.676820156897286,
                -6.853938919905953
              ],
              [
                -38.675037667929836,
                -6.861438846992438
              ],
              [
                -38.68630958028132,
                -6.865400802715611
              ],
              [
                -38.69213351343942,
                -6.869988297699797
              ],
              [
                -38.69795699260257,
                -6.874574745700472
              ],
              [
                -38.70147694578197,
                -6.874978776151448
              ],
              [
                -38.71377983206793,
                -6.876391256234259
              ],
              [
                -38.713699357046316,
                -6.881507206902207
              ],
              [
                -38.71363285653213,
                -6.88575567924203
              ],
              [
                -38.72289576573198,
                -6.886419172882966
              ],
              [
                -38.72505523189641,
                -6.886573672267343
              ],
              [
                -38.72829222566398,
                -6.886804200851971
              ],
              [
                -38.73189067459912,
                -6.889368652790093
              ],
              [
                -38.7309536762953,
                -6.89269964054842
              ],
              [
                -38.72991518360615,
                -6.896393116452307
              ],
              [
                -38.738203604166905,
                -6.898564600829534
              ],
              [
                -38.755896466420324,
                -6.903200075171453
              ],
              [
                -38.76374538869461,
                -6.909632028576743
              ],
              [
                -38.76483287961797,
                -6.910523539535271
              ],
              [
                -38.76139190608906,
                -6.913736522386606
              ],
              [
                -38.75933392151936,
                -6.915657995150234
              ],
              [
                -38.758792432842576,
                -6.916163482878776
              ],
              [
                -38.75838544597968,
                -6.924889434681048
              ],
              [
                -38.758622915861004,
                -6.92497189422264
              ],
              [
                -38.76202839847906,
                -6.926153903653679
              ],
              [
                -38.75709495188599,
                -6.934815327646955
              ],
              [
                -38.75486244236013,
                -6.938734295971607
              ],
              [
                -38.75628742106718,
                -6.940016288190245
              ],
              [
                -38.76475834504372,
                -6.947639762982062
              ],
              [
                -38.7569219305005,
                -6.957785174485063
              ],
              [
                -38.75442844668775,
                -6.95836467721151
              ],
              [
                -38.7465865033256,
                -6.960187150480844
              ],
              [
                -38.747691987226055,
                -6.966316583326242
              ],
              [
                -38.74250953687265,
                -6.974835525238733
              ],
              [
                -38.75150694000319,
                -6.988190949566385
              ],
              [
                -38.7565968882686,
                -6.989015935006231
              ],
              [
                -38.76058334423432,
                -6.989661932003086
              ],
              [
                -38.764620338397485,
                -6.993504386562223
              ],
              [
                -38.76407881980183,
                -6.993895413167838
              ],
              [
                -38.758304879762484,
                -6.998067354954544
              ],
              [
                -38.749947462559824,
                -6.997599861355586
              ],
              [
                -38.74952044627967,
                -6.997575869223638
              ],
              [
                -38.747256487687366,
                -6.999223335933459
              ],
              [
                -38.730203124355135,
                -7.011635259262998
              ],
              [
                -38.721670166109924,
                -7.014663210755112
              ],
              [
                -38.71313775186801,
                -7.017690673248436
              ],
              [
                -38.69106041518054,
                -7.028748606186041
              ],
              [
                -38.69041145755106,
                -7.033136053096445
              ],
              [
                -38.68942093016235,
                -7.039837013035329
              ],
              [
                -38.678328537322685,
                -7.043992440969268
              ],
              [
                -38.672735073304246,
                -7.046088433759103
              ],
              [
                -38.66946959973421,
                -7.047311417964861
              ],
              [
                -38.669688619034716,
                -7.053754397775318
              ],
              [
                -38.672863582204485,
                -7.059488854217587
              ],
              [
                -38.676860523128454,
                -7.066708771905907
              ],
              [
                -38.671071584808395,
                -7.070138772327621
              ],
              [
                -38.66695011937306,
                -7.079782198415276
              ],
              [
                -38.66618913657225,
                -7.080325174690069
              ],
              [
                -38.65942116570987,
                -7.085151153687185
              ],
              [
                -38.65602668502341,
                -7.092035093445554
              ],
              [
                -38.6617736308201,
                -7.109785451873305
              ],
              [
                -38.66936708278261,
                -7.120589375894829
              ],
              [
                -38.676960481735854,
                -7.131393312949181
              ],
              [
                -38.67491001146637,
                -7.155325125385051
              ],
              [
                -38.68481189726292,
                -7.160949574858155
              ],
              [
                -38.676500469664745,
                -7.168530023631293
              ],
              [
                -38.682628928121076,
                -7.175582966356133
              ],
              [
                -38.68744787552276,
                -7.189765874625201
              ],
              [
                -38.661310113591384,
                -7.184283384719294
              ],
              [
                -38.650976188642126,
                -7.183859384137401
              ],
              [
                -38.64325275395249,
                -7.183541876719579
              ],
              [
                -38.64065578377427,
                -7.187141346232914
              ],
              [
                -38.63616981401349,
                -7.193358792299413
              ],
              [
                -38.627410894468255,
                -7.191544803534695
              ],
              [
                -38.625684390736836,
                -7.191186818964733
              ],
              [
                -38.6194139510536,
                -7.196654756403099
              ],
              [
                -38.622669897695516,
                -7.20727417267649
              ],
              [
                -38.619741427816784,
                -7.215574137002037
              ],
              [
                -38.61825542619401,
                -7.220210079435009
              ],
              [
                -38.61626744202138,
                -7.226038053376183
              ],
              [
                -38.614987452279344,
                -7.229790006725221
              ],
              [
                -38.60504404256741,
                -7.236480438019138
              ],
              [
                -38.60322005903115,
                -7.235675945779266
              ],
              [
                -38.600261573735324,
                -7.234371484497907
              ],
              [
                -38.59774758149825,
                -7.239718925708509
              ],
              [
                -38.594731619805614,
                -7.24613388837815
              ],
              [
                -38.58270022595706,
                -7.247986835042744
              ],
              [
                -38.5788697682876,
                -7.246631847481165
              ],
              [
                -38.55729945863511,
                -7.238999386415554
              ],
              [
                -38.551433017735455,
                -7.24280837581965
              ],
              [
                -38.55406345727225,
                -7.251437319834158
              ],
              [
                -38.553391966541696,
                -7.254797791626264
              ],
              [
                -38.55171898568615,
                -7.263165702369443
              ],
              [
                -38.54572703057115,
                -7.264759184612195
              ],
              [
                -38.545631013386185,
                -7.264785200339974
              ],
              [
                -38.541827082636544,
                -7.267175167162176
              ],
              [
                -38.542248563676594,
                -7.277997611743211
              ],
              [
                -38.534204590814774,
                -7.293396985872302
              ],
              [
                -38.536965593023396,
                -7.299609426507016
              ],
              [
                -38.53972604123281,
                -7.305821878149871
              ],
              [
                -38.54011453158404,
                -7.306172394769645
              ],
              [
                -38.54997496097133,
                -7.315068805373524
              ],
              [
                -38.54959293891247,
                -7.325928748126215
              ],
              [
                -38.54950896478854,
                -7.328329232727453
              ],
              [
                -38.54929544396242,
                -7.33438819528978
              ],
              [
                -38.56079935172734,
                -7.357868524906316
              ],
              [
                -38.5682507628195,
                -7.367023940802418
              ],
              [
                -38.57570121191408,
                -7.376179368723579
              ],
              [
                -38.58243313828192,
                -7.395550746203238
              ],
              [
                -38.58321811669401,
                -7.397808735595726
              ],
              [
                -38.583650109789964,
                -7.397998223486981
              ],
              [
                -38.59000156539065,
                -7.400785701176368
              ],
              [
                -38.59517051499527,
                -7.414157090273481
              ],
              [
                -38.590435029563245,
                -7.414927104710936
              ],
              [
                -38.58412708160543,
                -7.415953565966836
              ],
              [
                -38.58491857625043,
                -7.434418937848482
              ],
              [
                -38.5946349904577,
                -7.433540476139767
              ],
              [
                -38.59569096659287,
                -7.438232436216923
              ],
              [
                -38.59598998684975,
                -7.438414928060487
              ],
              [
                -38.605421388328026,
                -7.444175871557976
              ],
              [
                -38.605213882271755,
                -7.44526986606246
              ],
              [
                -38.60294588760318,
                -7.457195795452789
              ],
              [
                -38.63660111552297,
                -7.459157273939151
              ],
              [
                -38.644537528191826,
                -7.459619294657087
              ],
              [
                -38.645791526674145,
                -7.462941774356064
              ],
              [
                -38.64364403771898,
                -7.463722249728131
              ],
              [
                -38.641309565335916,
                -7.464570259439068
              ],
              [
                -38.631334638963594,
                -7.484896079909025
              ],
              [
                -38.63847308433912,
                -7.493211525271717
              ],
              [
                -38.645611994706144,
                -7.501526975656915
              ],
              [
                -38.648011984304546,
                -7.511031902270468
              ],
              [
                -38.64727199186306,
                -7.513388391766087
              ],
              [
                -38.64609247712788,
                -7.517144854269247
              ],
              [
                -38.649845442807845,
                -7.51928986793204
              ],
              [
                -38.65427543429707,
                -7.521822344188275
              ],
              [
                -38.651118452300494,
                -7.527388782370129
              ],
              [
                -38.65110893436966,
                -7.52740579423326
              ],
              [
                -38.65242943030992,
                -7.539433202861605
              ],
              [
                -38.65319141563721,
                -7.540750693453949
              ],
              [
                -38.65610789126689,
                -7.545795645432953
              ],
              [
                -38.65463140271322,
                -7.550034114051962
              ],
              [
                -38.653190397932,
                -7.554169570482972
              ],
              [
                -38.65874734380163,
                -7.560222031923442
              ],
              [
                -38.65494389291577,
                -7.565144528498492
              ],
              [
                -38.66860526668365,
                -7.568288476509331
              ],
              [
                -38.674883726974116,
                -7.569732977547465
              ],
              [
                -38.67941215690603,
                -7.574378471886535
              ],
              [
                -38.684624102337835,
                -7.579722935472899
              ],
              [
                -38.68567759631825,
                -7.58080339211245
              ],
              [
                -38.688532582546316,
                -7.580765415682514
              ],
              [
                -38.702252448514095,
                -7.580583920027266
              ],
              [
                -38.71064289209921,
                -7.586235370850368
              ],
              [
                -38.710720401634056,
                -7.590951369128403
              ],
              [
                -38.71094486608498,
                -7.5929398272211
              ],
              [
                -38.7126408722629,
                -7.6079537106831
              ],
              [
                -38.7179168326326,
                -7.608956707310417
              ],
              [
                -38.71471681828535,
                -7.621519625464638
              ],
              [
                -38.717209309619534,
                -7.626492113764321
              ],
              [
                -38.7233317494538,
                -7.626484116751441
              ],
              [
                -38.72530872933254,
                -7.627662068411929
              ],
              [
                -38.72709020300256,
                -7.628724590790782
              ],
              [
                -38.73528962084633,
                -7.633612063346175
              ],
              [
                -38.73459466253167,
                -7.637748520434763
              ],
              [
                -38.734527124791335,
                -7.638152014322205
              ],
              [
                -38.74144257977037,
                -7.647987430368318
              ],
              [
                -38.74240704770591,
                -7.659923352718299
              ],
              [
                -38.75408946673915,
                -7.65934987150996
              ],
              [
                -38.756791419621145,
                -7.657701383181134
              ],
              [
                -38.76021491559811,
                -7.655611918775245
              ],
              [
                -38.77504878560201,
                -7.662047855037196
              ],
              [
                -38.7799852324579,
                -7.661185396563405
              ],
              [
                -38.784993177997244,
                -7.66609736143169
              ],
              [
                -38.78897665524499,
                -7.670004325085136
              ],
              [
                -38.79287510863711,
                -7.669729826323063
              ],
              [
                -38.81181046642224,
                -7.668396357767177
              ],
              [
                -38.81543593181653,
                -7.663672866449794
              ],
              [
                -38.81931240256326,
                -7.664438884795429
              ],
              [
                -38.82384683820529,
                -7.677957277198777
              ],
              [
                -38.81814388476871,
                -7.683972712061905
              ],
              [
                -38.81472342149646,
                -7.687581200985728
              ],
              [
                -38.81462889362357,
                -7.696991630730857
              ],
              [
                -38.821571832118956,
                -7.714411514665536
              ],
              [
                -38.830268255088285,
                -7.720000460515572
              ],
              [
                -38.8457081281478,
                -7.720014979018029
              ],
              [
                -38.84880158632104,
                -7.717574985984028
              ],
              [
                -38.85137009070789,
                -7.715549017391956
              ],
              [
                -38.86936794925063,
                -7.711145060366603
              ],
              [
                -38.87485588123748,
                -7.717758518886122
              ],
              [
                -38.88051532556362,
                -7.724579478992629
              ],
              [
                -38.88101381473564,
                -7.747048831729096
              ],
              [
                -38.89443066793796,
                -7.750054788050857
              ],
              [
                -38.89616365635513,
                -7.748310316641182
              ],
              [
                -38.899959149607696,
                -7.744488849601511
              ],
              [
                -38.9151984998539,
                -7.744088857129262
              ],
              [
                -38.917864002528475,
                -7.746784820973472
              ],
              [
                -38.916599510922694,
                -7.753521797099991
              ],
              [
                -38.91573248061956,
                -7.758140266540773
              ],
              [
                -38.91969045148398,
                -7.754310270691812
              ],
              [
                -38.919823967433494,
                -7.754181288774757
              ],
              [
                -38.92723890680537,
                -7.753421302604625
              ],
              [
                -38.93367281890257,
                -7.758443267013582
              ],
              [
                -38.93920079805674,
                -7.758381280030115
              ],
              [
                -38.94202877252537,
                -7.76325024463751
              ],
              [
                -38.940647253060106,
                -7.771019158914553
              ],
              [
                -38.94561822803387,
                -7.781819094588707
              ],
              [
                -38.942623248670685,
                -7.788217060885168
              ],
              [
                -38.93962827330471,
                -7.794614984183351
              ],
              [
                -38.95247664304286,
                -7.810161381661651
              ],
              [
                -38.95365612668683,
                -7.821118823091711
              ],
              [
                -38.95736757216413,
                -7.823955277807135
              ],
              [
                -38.964554010319226,
                -7.829446740418423
              ],
              [
                -38.96054056997763,
                -7.836756698035884
              ],
              [
                -38.959407068070135,
                -7.838822175540127
              ],
              [
                -38.961292534276005,
                -7.841761158979442
              ],
              [
                -38.96187252194244,
                -7.842130172683688
              ],
              [
                -38.965739019386376,
                -7.844590150381386
              ],
              [
                -38.97425341630104,
                -7.842593154503931
              ],
              [
                -38.97671042217,
                -7.843606165924085
              ],
              [
                -38.98186886670755,
                -7.845733167210337
              ],
              [
                -39.00102072587641,
                -7.839727197363351
              ],
              [
                -39.004206684797346,
                -7.83553724154746
              ],
              [
                -39.00615815203723,
                -7.830593773375059
              ],
              [
                -39.01235362851406,
                -7.814899406651099
              ],
              [
                -39.01749107918884,
                -7.813520906293391
              ],
              [
                -39.026017503413996,
                -7.824248838642175
              ],
              [
                -39.053379757064334,
                -7.818910883559431
              ],
              [
                -39.05893423554331,
                -7.829016809169697
              ],
              [
                -39.06448917900803,
                -7.839122740805766
              ],
              [
                -39.090918408684495,
                -7.857785117612002
              ],
              [
                -39.095704368511626,
                -7.851573666285676
              ],
              [
                -39.094700373152264,
                -7.848056218877235
              ],
              [
                -39.090327956164096,
                -7.840683765630536
              ],
              [
                -39.09020793037225,
                -7.840481262052333
              ],
              [
                -39.087253470001514,
                -7.835626791074978
              ],
              [
                -39.09034145240307,
                -7.827144359413513
              ],
              [
                -39.09119444798786,
                -7.82480188373416
              ],
              [
                -39.09036045357088,
                -7.808825512163647
              ],
              [
                -39.10146038345503,
                -7.786210674459835
              ],
              [
                -39.10164238195925,
                -7.782517719388398
              ],
              [
                -39.10222689378184,
                -7.770655293110567
              ],
              [
                -39.10590784681411,
                -7.759998383336972
              ],
              [
                -39.12346772923458,
                -7.742855013834568
              ],
              [
                -39.135256650797835,
                -7.722985677074598
              ],
              [
                -39.146099039353125,
                -7.717843230699047
              ],
              [
                -39.17062632491733,
                -7.714847771423015
              ],
              [
                -39.188888179745106,
                -7.699648375791596
              ],
              [
                -39.19830211875529,
                -7.694432922762822
              ],
              [
                -39.198307630710545,
                -7.69442942079367
              ],
              [
                -39.20772753267941,
                -7.689211980779529
              ],
              [
                -39.2209264243709,
                -7.695700422820187
              ],
              [
                -39.222359409418296,
                -7.69640495369996
              ],
              [
                -39.233458813607854,
                -7.696404952826365
              ],
              [
                -39.23435930983533,
                -7.696404966566827
              ],
              [
                -39.23442382023392,
                -7.696458925214128
              ],
              [
                -39.24463669995126,
                -7.705087887748101
              ],
              [
                -39.24681818425722,
                -7.704927384751031
              ],
              [
                -39.252170142395755,
                -7.704534382112611
              ],
              [
                -39.250694667121714,
                -7.699708443171208
              ],
              [
                -39.247087214060706,
                -7.690263013210224
              ],
              [
                -39.24784518813346,
                -7.688308994524274
              ],
              [
                -39.25095369292352,
                -7.680295087332635
              ],
              [
                -39.26496405500555,
                -7.669418152613776
              ],
              [
                -39.26842905080082,
                -7.662512228382056
              ],
              [
                -39.27161251036307,
                -7.660052239488439
              ],
              [
                -39.27449449273987,
                -7.660977729890693
              ],
              [
                -39.27792145427257,
                -7.662077739428325
              ],
              [
                -39.28337188881777,
                -7.663827239743195
              ],
              [
                -39.30651021856117,
                -7.6646857252678
              ],
              [
                -39.311061206367256,
                -7.654918299407656
              ],
              [
                -39.30439223461785,
                -7.647934857438337
              ],
              [
                -39.30252124936268,
                -7.645975369637978
              ],
              [
                -39.30266526398121,
                -7.642495872915477
              ],
              [
                -39.30325477661187,
                -7.628229515670559
              ],
              [
                -39.29599082685361,
                -7.617730076712849
              ],
              [
                -39.294155352345214,
                -7.615698096502332
              ],
              [
                -39.287675398094585,
                -7.60852564418632
              ],
              [
                -39.28410193314511,
                -7.604571158034671
              ],
              [
                -39.277615019432,
                -7.606601664476112
              ],
              [
                -39.28460796441895,
                -7.600118693953192
              ],
              [
                -39.29090988200947,
                -7.598972699701691
              ],
              [
                -39.29573184223326,
                -7.595539740455626
              ],
              [
                -39.308111775821956,
                -7.575520405167477
              ],
              [
                -39.31189323520184,
                -7.569405927253934
              ],
              [
                -39.31269123963357,
                -7.564107977778071
              ],
              [
                -39.31326471678217,
                -7.560305504865537
              ],
              [
                -39.315479243930966,
                -7.548934116264262
              ],
              [
                -39.31637273284889,
                -7.544474145566054
              ],
              [
                -39.317182732777745,
                -7.540885664239922
              ],
              [
                -39.32751163264423,
                -7.531153223859587
              ],
              [
                -39.35444489895245,
                -7.510916414941146
              ],
              [
                -39.35881437896464,
                -7.507633927173805
              ],
              [
                -39.36356183659213,
                -7.500798002465297
              ],
              [
                -39.3683093112181,
                -7.493962053756379
              ],
              [
                -39.39629059063685,
                -7.474889722831979
              ],
              [
                -39.40247752973317,
                -7.47444171116741
              ],
              [
                -39.40584099397399,
                -7.474198707694814
              ],
              [
                -39.413513460289884,
                -7.468782278625844
              ],
              [
                -39.417251911236306,
                -7.466142274497972
              ],
              [
                -39.447924158793526,
                -7.454328404001545
              ],
              [
                -39.4785479093022,
                -7.445708480391135
              ],
              [
                -39.48382036117099,
                -7.440467011050457
              ],
              [
                -39.489093319036044,
                -7.435226060704396
              ],
              [
                -39.489968303690794,
                -7.43375758545391
              ],
              [
                -39.49888923199123,
                -7.418786215243345
              ],
              [
                -39.508120671505324,
                -7.413947229024717
              ],
              [
                -39.5173531100199,
                -7.409107295809065
              ],
              [
                -39.54490038035103,
                -7.390271444487573
              ],
              [
                -39.56977916501434,
                -7.374758582992597
              ],
              [
                -39.59794297288225,
                -7.34371384891997
              ],
              [
                -39.606926893030234,
                -7.335816895452764
              ],
              [
                -39.6144438123817,
                -7.33471592468433
              ],
              [
                -39.61952825833326,
                -7.333971432308679
              ],
              [
                -39.62676921103186,
                -7.332910457020083
              ],
              [
                -39.64846355047085,
                -7.323347038118627
              ],
              [
                -39.65532550081206,
                -7.316777576956261
              ],
              [
                -39.662187952150994,
                -7.310208109790985
              ],
              [
                -39.68684621172847,
                -7.315187617823931
              ],
              [
                -39.75739863773347,
                -7.320572136202267
              ],
              [
                -39.788851330525056,
                -7.331616546233116
              ],
              [
                -39.814985125375195,
                -7.328319085736525
              ],
              [
                -39.82165255183446,
                -7.330152078281523
              ],
              [
                -39.82832002429735,
                -7.33198508683029
              ],
              [
                -39.84221239288055,
                -7.327609133696808
              ],
              [
                -39.85106982230512,
                -7.324818656664986
              ],
              [
                -39.85630878764068,
                -7.325865154980631
              ],
              [
                -39.86154821397491,
                -7.326911640298667
              ],
              [
                -39.86374021944328,
                -7.327885144742159
              ],
              [
                -39.88975448067147,
                -7.339438595849181
              ],
              [
                -39.90291434984425,
                -7.33853257812736
              ],
              [
                -39.903026850888175,
                -7.338525100273023
              ],
              [
                -39.904168873184524,
                -7.338446578771742
              ],
              [
                -39.91516725364609,
                -7.337808125323025
              ],
              [
                -39.94369399747352,
                -7.369341397012193
              ],
              [
                -39.96109682523636,
                -7.388577767050307
              ],
              [
                -39.972738734530175,
                -7.391323754871991
              ],
              [
                -39.98438112683262,
                -7.394069720704752
              ],
              [
                -39.99149057155703,
                -7.392255235991311
              ],
              [
                -40.006380975425344,
                -7.388454788382179
              ],
              [
                -40.01463635891656,
                -7.393404763029389
              ],
              [
                -40.022891829410185,
                -7.398354718690023
              ],
              [
                -40.07830835145873,
                -7.39942226840945
              ],
              [
                -40.14950073549862,
                -7.413814198592497
              ],
              [
                -40.246060382781586,
                -7.433331618597106
              ],
              [
                -40.271669707639106,
                -7.39172697511838
              ],
              [
                -40.27903564824866,
                -7.387654983401718
              ],
              [
                -40.2839775893521,
                -7.387450511925385
              ],
              [
                -40.290265555047675,
                -7.387191022948866
              ],
              [
                -40.31895081269919,
                -7.373985121464431
              ],
              [
                -40.37242034004631,
                -7.384984090951551
              ],
              [
                -40.42126241782666,
                -7.390050581947278
              ],
              [
                -40.50097278758812,
                -7.388055143589104
              ],
              [
                -40.54808086541305,
                -7.392315162360444
              ],
              [
                -40.540161482606806,
                -7.34445701998132
              ],
              [
                -40.54064545961175,
                -7.338165542073068
              ],
              [
                -40.54112999760751,
                -7.331874097169324
              ],
              [
                -40.523644612289665,
                -7.317849204192844
              ],
              [
                -40.51785067720202,
                -7.313202200039504
              ],
              [
                -40.51482269865578,
                -7.30730724839515
              ],
              [
                -40.51179426211099,
                -7.301410784770061
              ],
              [
                -40.51475372364591,
                -7.273303008057178
              ],
              [
                -40.51014680017071,
                -7.226575357901793
              ],
              [
                -40.50091890096511,
                -7.188260619762759
              ],
              [
                -40.49866393683067,
                -7.186074645254985
              ],
              [
                -40.49441348516038,
                -7.181954187576947
              ],
              [
                -40.49521298713409,
                -7.170906758494683
              ],
              [
                -40.49533398543801,
                -7.169226765104813
              ],
              [
                -40.49422799163153,
                -7.165676302680724
              ],
              [
                -40.483992088420585,
                -7.13280803156806
              ],
              [
                -40.486677095350146,
                -7.125554582753477
              ],
              [
                -40.48936255427182,
                -7.118300619945503
              ],
              [
                -40.47005624146124,
                -7.093579823225444
              ],
              [
                -40.4690452719882,
                -7.087254846632669
              ],
              [
                -40.463775811725895,
                -7.082106386945207
              ],
              [
                -40.45901532721158,
                -7.078151414754945
              ],
              [
                -40.44639597500461,
                -7.059299042697441
              ],
              [
                -40.44388100827457,
                -7.05554057381511
              ],
              [
                -40.443393513458645,
                -7.051623582694186
              ],
              [
                -40.44290551864553,
                -7.047706140578515
              ],
              [
                -40.44053152647888,
                -7.045538148933559
              ],
              [
                -40.42734164578381,
                -7.033491736730953
              ],
              [
                -40.426563133818426,
                -7.029070752171104
              ],
              [
                -40.430417126008045,
                -7.02402981282818
              ],
              [
                -40.436803094269024,
                -7.015676842196093
              ],
              [
                -40.427637652199216,
                -7.018087814097962
              ],
              [
                -40.42747613998748,
                -7.014961848342231
              ],
              [
                -40.42578315996547,
                -7.014176357908261
              ],
              [
                -40.405874848113704,
                -7.004936401656561
              ],
              [
                -40.41069730199131,
                -6.998685461124574
              ],
              [
                -40.42793564489596,
                -6.994483513817142
              ],
              [
                -40.428932671621006,
                -6.994240001405383
              ],
              [
                -40.42891817248434,
                -6.990684050987767
              ],
              [
                -40.428759703911766,
                -6.95152432383116
              ],
              [
                -40.42874619463889,
                -6.948112852352095
              ],
              [
                -40.424643747576404,
                -6.93488147025897
              ],
              [
                -40.43243567704109,
                -6.899991717443604
              ],
              [
                -40.43271219563648,
                -6.898752725933348
              ],
              [
                -40.43064770357967,
                -6.891612255852336
              ],
              [
                -40.42858372851296,
                -6.884471321784805
              ],
              [
                -40.42814173786545,
                -6.863867982839892
              ],
              [
                -40.41897832900871,
                -6.859392511468844
              ],
              [
                -40.41275888908571,
                -6.856355045548827
              ],
              [
                -40.409011921648535,
                -6.85269656215467
              ],
              [
                -40.40184399435602,
                -6.839603646947608
              ],
              [
                -40.38629963541268,
                -6.828829220099973
              ],
              [
                -40.38121267881712,
                -6.819216299425125
              ],
              [
                -40.3713482783673,
                -6.808813375129223
              ],
              [
                -40.37020179900761,
                -6.807603872352415
              ],
              [
                -40.37021027063185,
                -6.802665408438593
              ],
              [
                -40.388414641607646,
                -6.796612449488448
              ],
              [
                -40.403761513236205,
                -6.8021024387517
              ],
              [
                -40.412388924908015,
                -6.808922895026799
              ],
              [
                -40.41646087993924,
                -6.812141858925625
              ],
              [
                -40.43179327450594,
                -6.812590356052294
              ],
              [
                -40.438613711361576,
                -6.806298927353374
              ],
              [
                -40.44225368811237,
                -6.790771552555674
              ],
              [
                -40.442555186516856,
                -6.789485540429109
              ],
              [
                -40.447102141226395,
                -6.786438584685689
              ],
              [
                -40.451652118913564,
                -6.783389598958219
              ],
              [
                -40.45488309700124,
                -6.770680205724569
              ],
              [
                -40.458440067264185,
                -6.756687797373377
              ],
              [
                -40.464611028185544,
                -6.750976345825781
              ],
              [
                -40.4688820059106,
                -6.747023863662802
              ],
              [
                -40.47156245902806,
                -6.744542895273498
              ],
              [
                -40.475860443329694,
                -6.734121464679153
              ],
              [
                -40.51701108352921,
                -6.730107026332987
              ],
              [
                -40.56277523049514,
                -6.719837138307102
              ],
              [
                -40.60674433983262,
                -6.713685707032425
              ],
              [
                -40.632042149020585,
                -6.698516857448734
              ],
              [
                -40.641225579792184,
                -6.688443436725823
              ],
              [
                -40.65041000655572,
                -6.678371028989771
              ],
              [
                -40.70979852740268,
                -6.675743580561131
              ],
              [
                -40.731554326867524,
                -6.653683257798165
              ],
              [
                -40.72162743249558,
                -6.617296994043313
              ],
              [
                -40.72843539038589,
                -6.608555559533757
              ],
              [
                -40.73524335727459,
                -6.599814143021177
              ],
              [
                -40.74547526713978,
                -6.565161422221119
              ],
              [
                -40.784933966455974,
                -6.525961236758498
              ],
              [
                -40.78728796244414,
                -6.523397237686313
              ],
              [
                -40.78996695557976,
                -6.516837329809271
              ],
              [
                -40.791747426705626,
                -6.512476821792347
              ],
              [
                -40.79029245499815,
                -6.501376926980867
              ],
              [
                -40.79020544421033,
                -6.500714411886904
              ],
              [
                -40.790648433090745,
                -6.49846945804379
              ],
              [
                -40.7962094254183,
                -6.470316640237862
              ],
              [
                -40.79489093116714,
                -6.463873218639803
              ],
              [
                -40.7935729289067,
                -6.457430240045782
              ],
              [
                -40.79571093004447,
                -6.427799966998727
              ],
              [
                -40.804776906487,
                -6.392746720701021
              ],
              [
                -40.799710960319956,
                -6.381319846955123
              ],
              [
                -40.7968784772361,
                -6.378464851401415
              ],
              [
                -40.789971054434616,
                -6.371503388829888
              ],
              [
                -40.779145140901655,
                -6.34295510082665
              ],
              [
                -40.77994515185961,
                -6.333408177193671
              ],
              [
                -40.78074515380769,
                -6.323861251570223
              ],
              [
                -40.786849105855765,
                -6.304509901441093
              ],
              [
                -40.810125929965494,
                -6.286680035868126
              ],
              [
                -40.82319832077125,
                -6.257880244699578
              ],
              [
                -40.85184712384668,
                -6.223839016788193
              ],
              [
                -40.84934914521857,
                -6.213235622884614
              ],
              [
                -40.846851664573336,
                -6.202632203001404
              ],
              [
                -40.85274264588028,
                -6.164817468887994
              ],
              [
                -40.84653022481864,
                -6.131713237142674
              ],
              [
                -40.85709365200226,
                -6.104917426374715
              ],
              [
                -40.87294654055046,
                -6.055878320017652
              ],
              [
                -40.878424470258146,
                -6.051643830657856
              ],
              [
                -40.88390244596683,
                -6.047410374287864
              ],
              [
                -40.88419843745869,
                -6.047433381313398
              ],
              [
                -40.901829307068176,
                -6.048817866734557
              ],
              [
                -40.90515374597326,
                -6.045997401247644
              ],
              [
                -40.90672724083736,
                -6.041362929265936
              ],
              [
                -40.905499765609356,
                -6.033254010616125
              ],
              [
                -40.90356830841082,
                -6.020495569590794
              ],
              [
                -40.890978406462615,
                -6.005442698786752
              ],
              [
                -40.88697845111793,
                -5.98895932758318
              ],
              [
                -40.88144002092192,
                -5.982687377266134
              ],
              [
                -40.87406206959671,
                -5.974331924480646
              ],
              [
                -40.8797595547666,
                -5.918038356748192
              ],
              [
                -40.89714291564676,
                -5.900918978632282
              ],
              [
                -40.89518894074651,
                -5.892285025644161
              ],
              [
                -40.89323445784163,
                -5.883651609664614
              ],
              [
                -40.90311440426992,
                -5.855118817986831
              ],
              [
                -40.905268395182496,
                -5.848898371460497
              ],
              [
                -40.90697236254247,
                -5.837026951411204
              ],
              [
                -40.90867687088298,
                -5.82515555037485
              ],
              [
                -40.906174899728605,
                -5.794468781112127
              ],
              [
                -40.909378402469585,
                -5.76532451895488
              ],
              [
                -40.9173768485282,
                -5.744417184513888
              ],
              [
                -40.91527039100081,
                -5.735392216571207
              ],
              [
                -40.91316340646827,
                -5.726367788638282
              ],
              [
                -40.92687826711871,
                -5.705838466020202
              ],
              [
                -40.92999925751622,
                -5.685836127686428
              ],
              [
                -40.9304322482932,
                -5.685172601993118
              ],
              [
                -40.938391711049505,
                -5.672964221627201
              ],
              [
                -40.92874478860854,
                -5.662753297998269
              ],
              [
                -40.920684871262324,
                -5.659792824379892
              ],
              [
                -40.9108559422113,
                -5.656182829584434
              ],
              [
                -40.90731447791488,
                -5.641967933265731
              ],
              [
                -40.90068755626243,
                -5.615392118937458
              ],
              [
                -40.902176549120824,
                -5.609954693173483
              ],
              [
                -40.903666023971994,
                -5.604517737407986
              ],
              [
                -40.90464202681581,
                -5.602821234905818
              ],
              [
                -40.91195946616128,
                -5.590096827142526
              ],
              [
                -40.91298048063988,
                -5.578690909448165
              ],
              [
                -40.914002467095486,
                -5.567285990760003
              ],
              [
                -40.91988543440156,
                -5.561154070012618
              ],
              [
                -40.93200634025858,
                -5.548520157541345
              ],
              [
                -40.9313763302252,
                -5.536052265929831
              ],
              [
                -40.935978297761636,
                -5.525901327942189
              ],
              [
                -40.93105184758843,
                -5.520347858138249
              ],
              [
                -40.928499863808625,
                -5.517471390444057
              ],
              [
                -40.928380877476044,
                -5.506571463273231
              ],
              [
                -40.92954835669359,
                -5.504878480866934
              ],
              [
                -40.93267687015881,
                -5.500341500295995
              ],
              [
                -40.928743404081416,
                -5.492390576373173
              ],
              [
                -40.928780882509706,
                -5.482958157164306
              ],
              [
                -40.91813950872249,
                -5.473526201451453
              ],
              [
                -40.9154390191146,
                -5.465807774560433
              ],
              [
                -40.918739502659925,
                -5.457059362171178
              ],
              [
                -40.92138946662587,
                -5.450035902256039
              ],
              [
                -40.92887292331571,
                -5.444059422302103
              ],
              [
                -40.93635786099686,
                -5.438082503347565
              ],
              [
                -40.94207929888056,
                -5.419857116588747
              ],
              [
                -40.93804085202229,
                -5.416207165947905
              ],
              [
                -40.92476847173281,
                -5.415990665587532
              ],
              [
                -40.921036479148036,
                -5.415929165804423
              ],
              [
                -40.91736702701759,
                -5.415903126789106
              ],
              [
                -40.91117458343031,
                -5.407002204877661
              ],
              [
                -40.91232556201432,
                -5.403227248337869
              ],
              [
                -40.91610405865804,
                -5.390836836039996
              ],
              [
                -40.923437003366914,
                -5.381858899869261
              ],
              [
                -40.9277114676013,
                -5.376545435931036
              ],
              [
                -40.92793144991504,
                -5.376271949147402
              ],
              [
                -40.928977440897434,
                -5.374970949690512
              ],
              [
                -40.93572591575826,
                -5.365587550253718
              ],
              [
                -40.93333191707744,
                -5.350116155774763
              ],
              [
                -40.927347490252544,
                -5.345279181093107
              ],
              [
                -40.92310652891061,
                -5.341894715380086
              ],
              [
                -40.92269500956479,
                -5.341566202640728
              ],
              [
                -40.918074549592575,
                -5.337879726011065
              ],
              [
                -40.91727206014417,
                -5.334829745800036
              ],
              [
                -40.915257081613106,
                -5.3271693040454
              ],
              [
                -40.913134612219025,
                -5.319102376339475
              ],
              [
                -40.91305559661867,
                -5.316158887754471
              ],
              [
                -40.912911604832594,
                -5.307662961507789
              ],
              [
                -40.91589058923586,
                -5.305200484062709
              ],
              [
                -40.91988055659036,
                -5.301972993052763
              ],
              [
                -40.92088206464751,
                -5.301162537829759
              ],
              [
                -40.92366204765158,
                -5.298825031311321
              ],
              [
                -40.9255220248297,
                -5.294035087967165
              ],
              [
                -40.92350606178252,
                -5.288911115889483
              ],
              [
                -40.92168404784964,
                -5.284218141641232
              ],
              [
                -40.92159606172194,
                -5.283991640318614
              ],
              [
                -40.922770064775555,
                -5.279001196108276
              ],
              [
                -40.923863558238814,
                -5.274463232397692
              ],
              [
                -40.91143566181013,
                -5.269166262547271
              ],
              [
                -40.91821060989627,
                -5.260528327490034
              ],
              [
                -40.923081572280765,
                -5.254815873971537
              ],
              [
                -40.92503654616632,
                -5.252552880399494
              ],
              [
                -40.93012350260236,
                -5.248786929138015
              ],
              [
                -40.93245998397201,
                -5.247056427720906
              ],
              [
                -40.93254049233206,
                -5.246993436249088
              ],
              [
                -40.94159192637734,
                -5.239919987560574
              ],
              [
                -40.93841943431741,
                -5.237717516529688
              ],
              [
                -40.930350996377236,
                -5.232117048602704
              ],
              [
                -40.92866954118626,
                -5.230950077537703
              ],
              [
                -40.921654098974656,
                -5.226080601655841
              ],
              [
                -40.92503406750941,
                -5.21754015788366
              ],
              [
                -40.92638854515374,
                -5.214032196478346
              ],
              [
                -40.93033202571782,
                -5.204150778285338
              ],
              [
                -40.93044153996907,
                -5.203853271622628
              ],
              [
                -40.93157703120954,
                -5.200761802908848
              ],
              [
                -40.93141152089139,
                -5.20025728866898
              ],
              [
                -40.92513259068069,
                -5.181108939420034
              ],
              [
                -40.92860903809425,
                -5.171297014452637
              ],
              [
                -40.937370967375045,
                -5.166217047344986
              ],
              [
                -40.94503994082762,
                -5.166407069308205
              ],
              [
                -40.94894439996763,
                -5.166503548819291
              ],
              [
                -40.95403134642562,
                -5.160925090397035
              ],
              [
                -40.964255277953754,
                -5.149712215002385
              ],
              [
                -40.97320718103815,
                -5.149233715801984
              ],
              [
                -40.9745616857036,
                -5.149161197134355
              ],
              [
                -40.98208761221881,
                -5.153150687964049
              ],
              [
                -40.988227587099374,
                -5.138374279436522
              ],
              [
                -41.011458386693214,
                -5.123228912510157
              ],
              [
                -41.01225538860208,
                -5.11689995734352
              ],
              [
                -41.01369936699025,
                -5.105434049822394
              ],
              [
                -41.02303979692313,
                -5.108381049619759
              ],
              [
                -41.02602729031585,
                -5.109323520120237
              ],
              [
                -41.027802780885644,
                -5.104970082409601
              ],
              [
                -41.03362521711174,
                -5.090693687860189
              ],
              [
                -41.039888191380115,
                -5.091011684991048
              ],
              [
                -41.0432181385756,
                -5.094307167695402
              ],
              [
                -41.052035576654795,
                -5.103032578035126
              ],
              [
                -41.069343421831164,
                -5.09534215066193
              ],
              [
                -41.07974732719541,
                -5.090719187906478
              ],
              [
                -41.07992285670393,
                -5.083684259754988
              ],
              [
                -41.08005683261116,
                -5.078321281809622
              ],
              [
                -41.08091134382071,
                -5.077656817372218
              ],
              [
                -41.08382381067685,
                -5.075391804333174
              ],
              [
                -41.08697128766595,
                -5.072944338820907
              ],
              [
                -41.08787879257004,
                -5.068488389385267
              ],
              [
                -41.089193765784586,
                -5.064513398506475
              ],
              [
                -41.095488242850664,
                -5.06124493504801
              ],
              [
                -41.096626723070166,
                -5.052776997404756
              ],
              [
                -41.13142495025866,
                -5.047693548914096
              ],
              [
                -41.12104255178684,
                -5.023573224371883
              ],
              [
                -41.122031511924035,
                -5.010276826565796
              ],
              [
                -41.12222253575719,
                -5.007704858335475
              ],
              [
                -41.13470594896397,
                -4.997269454105554
              ],
              [
                -41.135179442217336,
                -4.996872952401692
              ],
              [
                -41.13519641913462,
                -4.996765925229406
              ],
              [
                -41.137142924587906,
                -4.984596531359292
              ],
              [
                -41.14048638165822,
                -4.984448529357077
              ],
              [
                -41.1433633524523,
                -4.988236523002669
              ],
              [
                -41.146091820598485,
                -4.991828482067962
              ],
              [
                -41.15054231636582,
                -4.991738989240305
              ],
              [
                -41.17984059366627,
                -4.947174322246024
              ],
              [
                -41.19707843070834,
                -4.937448445156778
              ],
              [
                -41.199000905622164,
                -4.94106839854756
              ],
              [
                -41.203414372096404,
                -4.949379350468011
              ],
              [
                -41.20923484446882,
                -4.9455903676566
              ],
              [
                -41.210210319352974,
                -4.938243915347855
              ],
              [
                -41.2202797516516,
                -4.937209926828198
              ],
              [
                -41.22257224855714,
                -4.926139543709114
              ],
              [
                -41.23455164371052,
                -4.905373667056613
              ],
              [
                -41.231137670307895,
                -4.89799626258228
              ],
              [
                -41.22772372089968,
                -4.890618776120854
              ],
              [
                -41.23714864338366,
                -4.880827354167606
              ],
              [
                -41.248797542522496,
                -4.868724985095355
              ],
              [
                -41.24393307816208,
                -4.861448044095065
              ],
              [
                -41.23906814780166,
                -4.85417057111315
              ],
              [
                -41.23987113308199,
                -4.839380207715521
              ],
              [
                -41.24084962861131,
                -4.821353317208826
              ],
              [
                -41.23590417087589,
                -4.817826344531292
              ],
              [
                -41.2324572070312,
                -4.815368863481242
              ],
              [
                -41.232257716962586,
                -4.80929043891124
              ],
              [
                -41.232089203117944,
                -4.804168934035223
              ],
              [
                -41.222238313320425,
                -4.789604569276039
              ],
              [
                -41.21761632228465,
                -4.787181068358055
              ],
              [
                -41.20960289157665,
                -4.782979089250254
              ],
              [
                -41.20763941351137,
                -4.776400642606805
              ],
              [
                -41.21238737830628,
                -4.77165819049228
              ],
              [
                -41.22949174438997,
                -4.767552720104372
              ],
              [
                -41.24023615388757,
                -4.764973741610844
              ],
              [
                -41.24653209594378,
                -4.761749269679666
              ],
              [
                -41.24763458502348,
                -4.761184291597116
              ],
              [
                -41.249961583178184,
                -4.756257820581037
              ],
              [
                -41.234855197874005,
                -4.746159890898417
              ],
              [
                -41.236272196133015,
                -4.738386440230281
              ],
              [
                -41.22827229258656,
                -4.727894041412224
              ],
              [
                -41.21435941535881,
                -4.713867117606507
              ],
              [
                -41.20823646682751,
                -4.707695690710097
              ],
              [
                -41.202114994282574,
                -4.701524701824589
              ],
              [
                -41.17390327534878,
                -4.667094976306855
              ],
              [
                -41.18479667892253,
                -4.661805042628718
              ],
              [
                -41.18666115145786,
                -4.659611053451858
              ],
              [
                -41.1925551063548,
                -4.651473092026112
              ],
              [
                -41.19265062962415,
                -4.651340594093492
              ],
              [
                -41.1951745953092,
                -4.647855135177753
              ],
              [
                -41.197718081844215,
                -4.644343159475356
              ],
              [
                -41.20243705272963,
                -4.637826728979852
              ],
              [
                -41.21607392044959,
                -4.628519269550851
              ],
              [
                -41.21928191774409,
                -4.626329796031507
              ],
              [
                -41.22895382324818,
                -4.619728875742715
              ],
              [
                -41.23274878493263,
                -4.609186951670454
              ],
              [
                -41.22797132776146,
                -4.599691527138609
              ],
              [
                -41.22446139469066,
                -4.592716077918651
              ],
              [
                -41.22107841847911,
                -4.580012167798086
              ],
              [
                -41.224839892744676,
                -4.576630190721871
              ],
              [
                -41.235029820191855,
                -4.5733651990451
              ],
              [
                -41.242161239690454,
                -4.571146243756343
              ],
              [
                -41.24036177329536,
                -4.568087258339066
              ],
              [
                -41.227018362233714,
                -4.567390265854411
              ],
              [
                -41.22162340247761,
                -4.567148238949543
              ],
              [
                -41.213734490000554,
                -4.552310388987301
              ],
              [
                -41.210805018692064,
                -4.549968391500665
              ],
              [
                -41.20787653937497,
                -4.547627403009077
              ],
              [
                -41.20632704277603,
                -4.540833432472046
              ],
              [
                -41.20341256461058,
                -4.528055529283391
              ],
              [
                -41.196903633311074,
                -4.521609095579824
              ],
              [
                -41.19039520200678,
                -4.515162137895403
              ],
              [
                -41.18970568028978,
                -4.502186746421016
              ],
              [
                -41.188969230394115,
                -4.488315343844131
              ],
              [
                -41.18690573449706,
                -4.484634883145837
              ],
              [
                -41.17970228717365,
                -4.471786985478159
              ],
              [
                -41.17764330822984,
                -4.468113499743558
              ],
              [
                -41.17752233408812,
                -4.464400028290256
              ],
              [
                -41.176757833132584,
                -4.440897213005185
              ],
              [
                -41.15931350223037,
                -4.410190936083916
              ],
              [
                -41.159635003748505,
                -4.381133137395975
              ],
              [
                -41.14863061570527,
                -4.367033273814233
              ],
              [
                -41.1371652083857,
                -4.346984888001741
              ],
              [
                -41.133045259352286,
                -4.339779959641063
              ],
              [
                -41.11979284091386,
                -4.330201532200655
              ],
              [
                -41.11905686711011,
                -4.323891070603044
              ],
              [
                -41.11886438000821,
                -4.31287414864619
              ],
              [
                -41.121445861305695,
                -4.300524264343487
              ],
              [
                -41.13552625538985,
                -4.233147282085231
              ],
              [
                -41.10161059453018,
                -4.18562813893237
              ],
              [
                -41.098848097458635,
                -4.181758154614998
              ],
              [
                -41.09294364777367,
                -4.173405736560854
              ],
              [
                -41.09051416770993,
                -4.169968234061098
              ],
              [
                -41.10235407458981,
                -4.1677652516538
              ],
              [
                -41.1179524633462,
                -4.164862794413884
              ],
              [
                -41.12577337360764,
                -4.152468888063584
              ],
              [
                -41.1272948637004,
                -4.141991966936885
              ],
              [
                -41.12773539440778,
                -4.138960514628483
              ],
              [
                -41.12975835140797,
                -4.136935015259654
              ],
              [
                -41.132920350399516,
                -4.133769053254302
              ],
              [
                -41.14279624429136,
                -4.123881633430762
              ],
              [
                -41.14099276939133,
                -4.121679648659512
              ],
              [
                -41.13735531204966,
                -4.119015657623634
              ],
              [
                -41.12185896294457,
                -4.07682549559735
              ],
              [
                -41.12678039789623,
                -4.061027119382929
              ],
              [
                -41.120207490080254,
                -4.056204148811947
              ],
              [
                -41.113739530353044,
                -4.051457652705367
              ],
              [
                -41.1138000116787,
                -4.04738371134723
              ],
              [
                -41.11385253484034,
                -4.043819214033678
              ],
              [
                -41.114012030093306,
                -4.040281264563927
              ],
              [
                -41.11996798017339,
                -4.035978271653147
              ],
              [
                -41.14272029856847,
                -4.036450307276602
              ],
              [
                -41.15957566284627,
                -4.031348340476131
              ],
              [
                -41.16137763772094,
                -4.031464850386604
              ],
              [
                -41.171439042274116,
                -4.032116352880784
              ],
              [
                -41.18165396692943,
                -4.025235917856886
              ],
              [
                -41.181826462008196,
                -4.024071394966209
              ],
              [
                -41.182672959486865,
                -4.018352936699293
              ],
              [
                -41.192230882697864,
                -4.020296949931182
              ],
              [
                -41.19263287339718,
                -4.020161930159502
              ],
              [
                -41.19909633132654,
                -4.017995969869573
              ],
              [
                -41.2029602856999,
                -4.020955437664535
              ],
              [
                -41.20323929827145,
                -4.021169961127087
              ],
              [
                -41.20852574226554,
                -4.025220422110435
              ],
              [
                -41.23427252824299,
                -4.029308884060119
              ],
              [
                -41.249802405849664,
                -4.037391811469862
              ],
              [
                -41.254308376102635,
                -4.035393369993089
              ],
              [
                -41.25401938214838,
                -4.02730691550458
              ],
              [
                -41.24489193162781,
                -4.020117968079725
              ],
              [
                -41.23906401637684,
                -4.015527994020408
              ],
              [
                -41.242957459419294,
                -4.009766526427636
              ],
              [
                -41.245353957978566,
                -4.006221074985139
              ],
              [
                -41.24886341609982,
                -3.989506687119349
              ],
              [
                -41.243812469843036,
                -3.986030243808718
              ],
              [
                -41.243983477356736,
                -3.979395792325114
              ],
              [
                -41.23974051205523,
                -3.978836283767726
              ],
              [
                -41.232575054906825,
                -3.967580859861882
              ],
              [
                -41.2322620955067,
                -3.96761535845492
              ],
              [
                -41.22864659154252,
                -3.968014845745637
              ],
              [
                -41.222635181881344,
                -3.949625989740798
              ],
              [
                -41.22124916332691,
                -3.945386515025241
              ],
              [
                -41.219862197779186,
                -3.941145586324035
              ],
              [
                -41.221426703057894,
                -3.935776089689793
              ],
              [
                -41.22715663276829,
                -3.916109758871772
              ],
              [
                -41.228536627301494,
                -3.911370304272287
              ],
              [
                -41.23852104190743,
                -3.90210485758484
              ],
              [
                -41.24513650130487,
                -3.89596490814924
              ],
              [
                -41.24706698772217,
                -3.883163023388645
              ],
              [
                -41.25848091510049,
                -3.869917637208727
              ],
              [
                -41.25920690761139,
                -3.866262671906027
              ],
              [
                -41.2595784033145,
                -3.864390665604486
              ],
              [
                -41.260905864439984,
                -3.863897191012899
              ],
              [
                -41.26738082739949,
                -3.861491197506355
              ],
              [
                -41.2701577909771,
                -3.852721774808479
              ],
              [
                -41.26987982682333,
                -3.851498285189657
              ],
              [
                -41.26820431597213,
                -3.844132841668993
              ],
              [
                -41.27647676195624,
                -3.825260978824592
              ],
              [
                -41.282296220859166,
                -3.826452461071761
              ],
              [
                -41.28708766779715,
                -3.821897500512339
              ],
              [
                -41.28956815900131,
                -3.824450480748856
              ],
              [
                -41.294783109177295,
                -3.829818452303329
              ],
              [
                -41.29656511182291,
                -3.828597461552411
              ],
              [
                -41.3004380456269,
                -3.82594247883678
              ],
              [
                -41.28383170307343,
                -3.809918616172577
              ],
              [
                -41.26737433921198,
                -3.794038714545469
              ],
              [
                -41.26445137381172,
                -3.791218733220463
              ],
              [
                -41.253744956190815,
                -3.774680367259888
              ],
              [
                -41.260751442937,
                -3.75736749887405
              ],
              [
                -41.259531449891576,
                -3.748354066472011
              ],
              [
                -41.25698545998398,
                -3.729543227760246
              ],
              [
                -41.2542494771937,
                -3.726263716135874
              ],
              [
                -41.2515105164292,
                -3.722980782540218
              ],
              [
                -41.239052103580576,
                -3.720336256917407
              ],
              [
                -41.23910112153246,
                -3.712208831212426
              ],
              [
                -41.24136159219616,
                -3.710889855423792
              ],
              [
                -41.255056000120085,
                -3.702899918330607
              ],
              [
                -41.27316535169184,
                -3.700997458672659
              ],
              [
                -41.278958301579685,
                -3.700388443822174
              ],
              [
                -41.28381626095518,
                -3.695704004299191
              ],
              [
                -41.29042521541007,
                -3.689330526645688
              ],
              [
                -41.30728706568831,
                -3.684447576636118
              ],
              [
                -41.31834795578743,
                -3.685920068764429
              ],
              [
                -41.326165903132214,
                -3.686960545912817
              ],
              [
                -41.33507132041512,
                -3.683127611421085
              ],
              [
                -41.336842299955265,
                -3.682365121086087
              ],
              [
                -41.341369770990376,
                -3.680415620125679
              ],
              [
                -41.343314286740416,
                -3.675615654265822
              ],
              [
                -41.33872132032574,
                -3.670062196567059
              ],
              [
                -41.33096040161141,
                -3.624460042286285
              ],
              [
                -41.34452678396585,
                -3.588382333733692
              ],
              [
                -41.35465370783091,
                -3.585090881457247
              ],
              [
                -41.361624629611214,
                -3.582824379920863
              ],
              [
                -41.37024909002546,
                -3.574509952142969
              ],
              [
                -41.37022959570965,
                -3.568182504410319
              ],
              [
                -41.37022556629856,
                -3.566787530272751
              ],
              [
                -41.366967603510176,
                -3.561633071110536
              ],
              [
                -41.36427165057118,
                -3.557644587097684
              ],
              [
                -41.33649136085958,
                -3.553535116995671
              ],
              [
                -41.325300466111486,
                -3.536054724791834
              ],
              [
                -41.324876494319696,
                -3.526727298351898
              ],
              [
                -41.32445197552244,
                -3.517400389918729
              ],
              [
                -41.32370350145101,
                -3.502869506962108
              ],
              [
                -41.322291517076295,
                -3.50069899333784
              ],
              [
                -41.32021053168219,
                -3.497501523463496
              ],
              [
                -41.298837217660875,
                -3.490665600405986
              ],
              [
                -41.30501165950531,
                -3.489732576111711
              ],
              [
                -41.31283260571453,
                -3.488550599405579
              ],
              [
                -41.32226401047852,
                -3.492557575843519
              ],
              [
                -41.32378800151417,
                -3.493205051390897
              ],
              [
                -41.3276364583232,
                -3.488297126194079
              ],
              [
                -41.328384960190895,
                -3.477708703104625
              ],
              [
                -41.329250941707194,
                -3.46546176701605
              ],
              [
                -41.335212927650005,
                -3.46114431103526
              ],
              [
                -41.347616802674814,
                -3.452161905962133
              ],
              [
                -41.34353585832319,
                -3.440140507204835
              ],
              [
                -41.34380885260764,
                -3.433457558479041
              ],
              [
                -41.344402325196306,
                -3.418962155879459
              ],
              [
                -41.347591321393914,
                -3.414268207757969
              ],
              [
                -41.355650750641445,
                -3.410474738474783
              ],
              [
                -41.363378213081766,
                -3.410838714601302
              ],
              [
                -41.38861700626812,
                -3.412028726013066
              ],
              [
                -41.391921944038394,
                -3.40332328122281
              ],
              [
                -41.39348294759178,
                -3.399211339914327
              ],
              [
                -41.39692490831378,
                -3.398150847509836
              ],
              [
                -41.40318886685405,
                -3.396221854963468
              ],
              [
                -41.40172538562627,
                -3.386768931182868
              ],
              [
                -41.40029488603326,
                -3.377529485761856
              ],
              [
                -41.40232487294717,
                -3.362039094474826
              ],
              [
                -41.41042830689907,
                -3.366135098568191
              ],
              [
                -41.41833926149616,
                -3.370133058363725
              ],
              [
                -41.42313921644886,
                -3.367651592556399
              ],
              [
                -41.41841874908908,
                -3.356140660623996
              ],
              [
                -41.41553526574026,
                -3.349108709437701
              ],
              [
                -41.41193931696829,
                -3.340342790534983
              ],
              [
                -41.40306887889182,
                -3.3322153666795
              ],
              [
                -41.39758693043091,
                -3.327192388862885
              ],
              [
                -41.39894994304652,
                -3.321878931888486
              ],
              [
                -41.39901343060947,
                -3.321630931850001
              ],
              [
                -41.39980593715223,
                -3.318539963297998
              ],
              [
                -41.397032446708316,
                -3.311921979975845
              ],
              [
                -41.386422534775164,
                -3.28839618392383
              ],
              [
                -41.32210910716039,
                -3.179437513497655
              ],
              [
                -41.32493507751779,
                -3.171175578271768
              ],
              [
                -41.32833455767684,
                -3.161238641384637
              ],
              [
                -41.31897214479346,
                -3.149723737350057
              ],
              [
                -41.318780157053965,
                -3.144989768408897
              ],
              [
                -41.28511694699014,
                -3.112958031903056
              ],
              [
                -41.28544242374607,
                -3.102922099808687
              ],
              [
                -41.27666349751619,
                -3.101391579764497
              ],
              [
                -41.27191404842979,
                -3.100563111614849
              ],
              [
                -41.267585613984586,
                -3.09348765667855
              ],
              [
                -41.256274684376216,
                -3.087662695508441
              ],
              [
                -41.25544119291313,
                -3.080137265347413
              ],
              [
                -41.25615270898464,
                -3.071338847731463
              ],
              [
                -41.256935671706664,
                -3.070588820895701
              ],
              [
                -41.26097267033781,
                -3.066719869691991
              ],
              [
                -41.260401176193774,
                -3.054713986852105
              ],
              [
                -41.26006668335197,
                -3.047738999563251
              ],
              [
                -41.25553221460682,
                -3.040607064079329
              ],
              [
                -41.25339021162149,
                -3.037237097843994
              ],
              [
                -41.25422220583347,
                -3.030848630360563
              ],
              [
                -41.24940477376642,
                -3.028686162725723
              ],
              [
                -41.25557871478681,
                -3.018141244753001
              ],
              [
                -41.25616272123035,
                -3.011229319313617
              ],
              [
                -41.2561637032263,
                -3.011216798412401
              ],
              [
                -41.25674971465205,
                -3.004292355076783
              ],
              [
                -41.26916710455539,
                -2.984728495157079
              ],
              [
                -41.27181810780929,
                -2.971531618872924
              ],
              [
                -41.2719406019889,
                -2.970921631713427
              ],
              [
                -41.283135005798904,
                -2.967298134959574
              ],
              [
                -41.29277592622511,
                -2.967776164416334
              ],
              [
                -41.29808935682267,
                -2.968039635117567
              ],
              [
                -41.31868572771795,
                -2.956990750884176
              ],
              [
                -41.32062118293411,
                -2.952878775867668
              ],
              [
                -41.322557191143986,
                -2.948767804843593
              ],
              [
                -41.32253167730128,
                -2.945889817471557
              ],
              [
                -41.322306192320816,
                -2.92097103943241
              ],
              [
                -41.318142234835285,
                -2.918513529401783
              ],
              [
                -41.286283513594235,
                -2.899714674961133
              ],
              [
                -41.280211538396394,
                -2.896131689200153
              ],
              [
                -41.278862556071346,
                -2.894820198090524
              ],
              [
                -41.27320860700488,
                -2.889321276566893
              ],
              [
                -41.25841623011088,
                -2.884045779331331
              ],
              [
                -41.250179311281265,
                -2.885793763886525
              ],
              [
                -41.24444832371666,
                -2.887009273448724
              ],
              [
                -41.23619789401162,
                -2.888759771975642
              ],
              [
                -41.233000448481235,
                -2.88943826559017
              ],
              [
                -41.19077980852127,
                -2.890876238553612
              ],
              [
                -41.18798431605121,
                -2.890305247150195
              ],
              [
                -41.183867867700954,
                -2.889465241440882
              ],
              [
                -41.183193355378556,
                -2.88932824230283
              ],
              [
                -41.1731579378312,
                -2.887366246528147
              ],
              [
                -41.119519873396875,
                -2.892523203454422
              ],
              [
                -41.101338511808436,
                -2.900598600800584
              ],
              [
                -41.098677065512035,
                -2.896083637586798
              ],
              [
                -41.09597409058319,
                -2.891500186905503
              ],
              [
                -41.09230711683501,
                -2.892786658558385
              ],
              [
                -41.08902064894855,
                -2.893940661379942
              ],
              [
                -41.08489267500644,
                -2.895389141602419
              ],
              [
                -41.07902071807878,
                -2.895508147759402
              ],
              [
                -41.07254826417388,
                -2.895639649623199
              ],
              [
                -41.05880339706536,
                -2.895919163961499
              ],
              [
                -41.05745139336771,
                -2.89594665030633
              ],
              [
                -41.05046845598576,
                -2.895341161828726
              ],
              [
                -41.043485510609116,
                -2.894736157348641
              ],
              [
                -41.008809296801985,
                -2.891732147879274
              ],
              [
                -40.962095698480965,
                -2.880794240341483
              ],
              [
                -40.95829423581255,
                -2.876116787136892
              ],
              [
                -40.95804622098709,
                -2.875811768471282
              ],
              [
                -40.95422424449745,
                -2.871108806469044
              ],
              [
                -40.94243636807523,
                -2.871689288096856
              ],
              [
                -40.937306908975934,
                -2.871941781454342
              ],
              [
                -40.91973805493646,
                -2.872807765968597
              ],
              [
                -40.914576108121814,
                -2.873062279299358
              ],
              [
                -40.90793115094563,
                -2.869515317264506
              ],
              [
                -40.90739966123183,
                -2.866888817898837
              ],
              [
                -40.90570368309485,
                -2.85850841574434
              ],
              [
                -40.89969819937078,
                -2.861898863969111
              ],
              [
                -40.87943739227359,
                -2.861829346058236
              ],
              [
                -40.87414442862871,
                -2.858759368701911
              ],
              [
                -40.868818466272586,
                -2.862005854273804
              ],
              [
                -40.867990969053764,
                -2.862510345011381
              ],
              [
                -40.86730147005647,
                -2.861813360315917
              ],
              [
                -40.861474520784355,
                -2.855925891129371
              ],
              [
                -40.85811506427177,
                -2.861297347478547
              ],
              [
                -40.8560211017808,
                -2.864644830274958
              ],
              [
                -40.85228010705381,
                -2.864989815346854
              ],
              [
                -40.849966142913146,
                -2.869905311641522
              ],
              [
                -40.84440268584422,
                -2.881724179174915
              ],
              [
                -40.84283171022146,
                -2.884253663621173
              ],
              [
                -40.83408975312386,
                -2.879360221607556
              ],
              [
                -40.8296142944722,
                -2.876855239034007
              ],
              [
                -40.82536382912084,
                -2.87691822817382
              ],
              [
                -40.8153894377838,
                -2.877066215807522
              ],
              [
                -40.794616607756325,
                -2.868834277463768
              ],
              [
                -40.78630467162891,
                -2.862209815375737
              ],
              [
                -40.78430518291857,
                -2.860616330384473
              ],
              [
                -40.78036072302698,
                -2.857472371080952
              ],
              [
                -40.77593824326909,
                -2.853948398646063
              ],
              [
                -40.75958090494388,
                -2.849789404477599
              ],
              [
                -40.755999929911745,
                -2.850138924568652
              ],
              [
                -40.751431451145486,
                -2.850585398576998
              ],
              [
                -40.7438875192863,
                -2.851321909339623
              ],
              [
                -40.73210511191465,
                -2.852472411471173
              ],
              [
                -40.690698981370446,
                -2.850988901152363
              ],
              [
                -40.68635901814227,
                -2.850073382055957
              ],
              [
                -40.68276652559724,
                -2.849267900322337
              ],
              [
                -40.67150261309435,
                -2.846742431839389
              ],
              [
                -40.65592526354722,
                -2.848490901020856
              ],
              [
                -40.65219527378801,
                -2.848909892511631
              ],
              [
                -40.65106081029019,
                -2.849036882143661
              ],
              [
                -40.645061870538655,
                -2.849710885889776
              ],
              [
                -40.643334854130195,
                -2.849504878982654
              ],
              [
                -40.59158880148116,
                -2.843330942789088
              ],
              [
                -40.5868788466068,
                -2.841793446544639
              ],
              [
                -40.572469469858326,
                -2.838762980140445
              ],
              [
                -40.562731026025546,
                -2.828543048465454
              ],
              [
                -40.55683310036264,
                -2.826372551907486
              ],
              [
                -40.54756068650622,
                -2.82296058118894
              ],
              [
                -40.53734977066946,
                -2.819202621943592
              ],
              [
                -40.53326528054854,
                -2.817666601910094
              ],
              [
                -40.52565387326257,
                -2.80923069577053
              ],
              [
                -40.51939040316028,
                -2.802288225165836
              ],
              [
                -40.52026091680195,
                -2.7925552910265
              ],
              [
                -40.520403429088134,
                -2.790959803790738
              ],
              [
                -40.51677595609806,
                -2.789490828384662
              ],
              [
                -40.50967551859683,
                -2.787283846795485
              ],
              [
                -40.49942258696483,
                -2.784096374061217
              ],
              [
                -40.486458693506016,
                -2.789739311062291
              ],
              [
                -40.48343070761906,
                -2.791082829412639
              ],
              [
                -40.47003481773057,
                -2.797024765881117
              ],
              [
                -40.454634960484604,
                -2.803856686616948
              ],
              [
                -40.45122347186625,
                -2.805116705508275
              ],
              [
                -40.44331557265951,
                -2.808038668745743
              ],
              [
                -40.44269153485174,
                -2.808269173713223
              ],
              [
                -40.43552912044834,
                -2.810916131372371
              ],
              [
                -40.404699374687354,
                -2.813669097842504
              ],
              [
                -40.40145888813923,
                -2.813314612667806
              ],
              [
                -40.3922474611804,
                -2.812306605860997
              ],
              [
                -40.39080700638486,
                -2.81214961366824
              ],
              [
                -40.38501802043589,
                -2.811516132933574
              ],
              [
                -40.37773608414208,
                -2.810719125043792
              ],
              [
                -40.37211066281482,
                -2.810104123214628
              ],
              [
                -40.329201505561834,
                -2.805408640470129
              ],
              [
                -40.28896834610517,
                -2.809849598549108
              ],
              [
                -40.26130459056678,
                -2.811001101798785
              ],
              [
                -40.260028580528,
                -2.810430093972473
              ],
              [
                -40.25591162189379,
                -2.808589594425373
              ],
              [
                -40.24439772472037,
                -2.810293585241246
              ],
              [
                -40.23689179384963,
                -2.811404105039787
              ],
              [
                -40.23604478697365,
                -2.811530060770588
              ],
              [
                -40.23181830552387,
                -2.812155582460877
              ],
              [
                -40.2164059491848,
                -2.814415546617228
              ],
              [
                -40.21069450143255,
                -2.814624562180913
              ],
              [
                -40.20588055810116,
                -2.814801554277988
              ],
              [
                -40.20045507693923,
                -2.815000557010063
              ],
              [
                -40.19681913177631,
                -2.814752543873194
              ],
              [
                -40.18718121952406,
                -2.814095539159163
              ],
              [
                -40.174364294260215,
                -2.82301147381019
              ],
              [
                -40.16007391416509,
                -2.832951890785759
              ],
              [
                -40.14724403689515,
                -2.839069853808037
              ],
              [
                -40.139042594178285,
                -2.83632137727136
              ],
              [
                -40.13171463703876,
                -2.833677856171186
              ],
              [
                -40.13214013734894,
                -2.830700874150839
              ],
              [
                -40.132469668265884,
                -2.828392929741748
              ],
              [
                -40.12772918832925,
                -2.82538341938529
              ],
              [
                -40.11508229315509,
                -2.826294924169934
              ],
              [
                -40.10528988282619,
                -2.827181926032628
              ],
              [
                -40.09549746250791,
                -2.828068906894432
              ],
              [
                -40.08423457453137,
                -2.83263835878126
              ],
              [
                -40.07894460169677,
                -2.834784355939607
              ],
              [
                -40.07646213401421,
                -2.840382304296168
              ],
              [
                -40.066999706897995,
                -2.841372775417325
              ],
              [
                -40.06254272462762,
                -2.838259313994886
              ],
              [
                -40.0610257628096,
                -2.837199839018929
              ],
              [
                -40.053100305054926,
                -2.836972816381809
              ],
              [
                -40.04962385111733,
                -2.838296323690745
              ],
              [
                -40.04739086578512,
                -2.839146791178145
              ],
              [
                -40.04339939715526,
                -2.840666298754265
              ],
              [
                -40.02211506987569,
                -2.843080275786193
              ],
              [
                -40.01901110762263,
                -2.838434824088557
              ],
              [
                -40.01804260828007,
                -2.836984811421101
              ]
            ]
          ],
          [
            [
              [
                -40.02463805123169,
                -2.834112845503342
              ],
              [
                -40.01804260828007,
                -2.836984811421101
              ],
              [
                -40.02713653654838,
                -2.835738320237405
              ],
              [
                -40.02463805123169,
                -2.834112845503342
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 7,
        "nome": "Distrito Federal",
        "sigla": "DF",
        "regiao_id": 5,
        "codigo_ibg": 53
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -47.30860927476364,
                -16.03549091157155
              ],
              [
                -47.31094571371496,
                -16.040856365870347
              ],
              [
                -47.30814775317286,
                -16.041645352914422
              ],
              [
                -47.30836671828127,
                -16.050077809151272
              ],
              [
                -47.31824165111172,
                -16.05006582910756
              ],
              [
                -47.415967378211725,
                -16.04994701079553
              ],
              [
                -47.425937301848975,
                -16.049741542039737
              ],
              [
                -47.43590722449843,
                -16.049536569284392
              ],
              [
                -47.46259702608174,
                -16.04979112678674
              ],
              [
                -47.537066395771305,
                -16.049536763683395
              ],
              [
                -47.61724529676963,
                -16.049947395724594
              ],
              [
                -47.68303177226522,
                -16.05017451681686
              ],
              [
                -47.81435372185951,
                -16.05020726526191
              ],
              [
                -47.93875574253867,
                -16.05089347971175
              ],
              [
                -47.98246039391709,
                -16.051023583086167
              ],
              [
                -48.02185207632702,
                -16.05113963680689
              ],
              [
                -48.051469872460416,
                -16.051217695834886
              ],
              [
                -48.086235074446975,
                -16.051308737340168
              ],
              [
                -48.16145148105207,
                -16.050787893892974
              ],
              [
                -48.25803073575665,
                -16.05078809361071
              ],
              [
                -48.268352148984,
                -16.05094408792477
              ],
              [
                -48.27867409021901,
                -16.05110112623863
              ],
              [
                -48.271179175638736,
                -16.02825873234986
              ],
              [
                -48.27179170899901,
                -16.01814129587197
              ],
              [
                -48.272404216343126,
                -16.008024348404017
              ],
              [
                -48.26432632823952,
                -15.98834493153625
              ],
              [
                -48.259798366569385,
                -15.977314465536736
              ],
              [
                -48.260224885884455,
                -15.971975015090342
              ],
              [
                -48.260651869191705,
                -15.966635544648296
              ],
              [
                -48.260402397958465,
                -15.966257527170862
              ],
              [
                -48.257527408842016,
                -15.961901047720954
              ],
              [
                -48.25208898014994,
                -15.953658580944412
              ],
              [
                -48.25151100570947,
                -15.94259863323372
              ],
              [
                -48.25171550735157,
                -15.940190649599186
              ],
              [
                -48.25204450622457,
                -15.936309666140794
              ],
              [
                -48.26073645289294,
                -15.928776239339156
              ],
              [
                -48.262971439975146,
                -15.929932227668283
              ],
              [
                -48.2677593803076,
                -15.932408248094342
              ],
              [
                -48.2763158317125,
                -15.9300927689529
              ],
              [
                -48.27651334033781,
                -15.92219330682753
              ],
              [
                -48.27284089852727,
                -15.920537806262127
              ],
              [
                -48.270181930387636,
                -15.911252860798134
              ],
              [
                -48.27690288272748,
                -15.90587590237942
              ],
              [
                -48.2739819072435,
                -15.892605950846686
              ],
              [
                -48.276759900871426,
                -15.891104496565772
              ],
              [
                -48.27524191619385,
                -15.887640985124827
              ],
              [
                -48.27520494866476,
                -15.8875564915046
              ],
              [
                -48.272013484275206,
                -15.880278009208647
              ],
              [
                -48.27764745174873,
                -15.873659085268383
              ],
              [
                -48.27905095167187,
                -15.869109123610842
              ],
              [
                -48.28242295179572,
                -15.85738166259443
              ],
              [
                -48.286647910675356,
                -15.842687258296479
              ],
              [
                -48.286223924922034,
                -15.841322761788915
              ],
              [
                -48.28241748597586,
                -15.829078818061214
              ],
              [
                -48.26746613611065,
                -15.806451432852322
              ],
              [
                -48.24570133492539,
                -15.79651041657682
              ],
              [
                -48.241532852664236,
                -15.7982369138485
              ],
              [
                -48.23897689722947,
                -15.78862893757271
              ],
              [
                -48.237819942585006,
                -15.784280470725522
              ],
              [
                -48.23576445344565,
                -15.78202050179058
              ],
              [
                -48.22563955518971,
                -15.770889531540375
              ],
              [
                -48.21151418450689,
                -15.755360106079728
              ],
              [
                -48.208567248842535,
                -15.748182129981464
              ],
              [
                -48.2056202721722,
                -15.741003181901027
              ],
              [
                -48.20644027829754,
                -15.727682721635937
              ],
              [
                -48.20704130455244,
                -15.717893790832887
              ],
              [
                -48.21767373079998,
                -15.707583869973089
              ],
              [
                -48.24037306735187,
                -15.706534414171257
              ],
              [
                -48.23597518282069,
                -15.658615174846837
              ],
              [
                -48.23341673429856,
                -15.643784743110926
              ],
              [
                -48.23253773397044,
                -15.63869228696325
              ],
              [
                -48.21851690715917,
                -15.622183333523614
              ],
              [
                -48.20919498652818,
                -15.621797823026382
              ],
              [
                -48.19987205691744,
                -15.62141179053397
              ],
              [
                -48.19916669952951,
                -15.546251231507181
              ],
              [
                -48.19770826813022,
                -15.512461893464408
              ],
              [
                -48.19719228530369,
                -15.500510966507264
              ],
              [
                -48.130852794323076,
                -15.499760834426588
              ],
              [
                -48.052785944841304,
                -15.499745711355363
              ],
              [
                -48.0341335765238,
                -15.50109068220475
              ],
              [
                -47.61869683790587,
                -15.50029992105853
              ],
              [
                -47.52712756417582,
                -15.499699754236312
              ],
              [
                -47.495515290693874,
                -15.499848699639841
              ],
              [
                -47.46580804312529,
                -15.500099678232447
              ],
              [
                -47.46141409978342,
                -15.500078131772923
              ],
              [
                -47.41690644298272,
                -15.499863065106101
              ],
              [
                -47.41650740283805,
                -15.510121025489441
              ],
              [
                -47.41610841067541,
                -15.52037995788102
              ],
              [
                -47.417289326287126,
                -15.546380804224057
              ],
              [
                -47.410831900610816,
                -15.546187804659628
              ],
              [
                -47.404375938926236,
                -15.545995781094536
              ],
              [
                -47.394551995178,
                -15.559558701783526
              ],
              [
                -47.389179018113,
                -15.566976646200553
              ],
              [
                -47.38553905286488,
                -15.566961161159655
              ],
              [
                -47.38001308751967,
                -15.566936622477217
              ],
              [
                -47.37738908903473,
                -15.571827588260177
              ],
              [
                -47.37414510202689,
                -15.577876553418482
              ],
              [
                -47.3569527494843,
                -15.580874028148427
              ],
              [
                -47.35460976413436,
                -15.579361533936131
              ],
              [
                -47.353238795803755,
                -15.578476017155863
              ],
              [
                -47.34095987748079,
                -15.58292648146126
              ],
              [
                -47.32539600541597,
                -15.588676921126138
              ],
              [
                -47.321265508992695,
                -15.585822901852271
              ],
              [
                -47.31543007210147,
                -15.593973863259151
              ],
              [
                -47.32204098648614,
                -15.597516353582963
              ],
              [
                -47.32225899439764,
                -15.598171354381652
              ],
              [
                -47.33268236570257,
                -15.62946269557366
              ],
              [
                -47.329629375342165,
                -15.638297155691411
              ],
              [
                -47.32615288075059,
                -15.648358608193274
              ],
              [
                -47.32756883447265,
                -15.652222076604913
              ],
              [
                -47.32875731728947,
                -15.655465059004491
              ],
              [
                -47.32653882674133,
                -15.673153453826316
              ],
              [
                -47.33109776676574,
                -15.681232400144824
              ],
              [
                -47.32756528859231,
                -15.687466873728544
              ],
              [
                -47.32010835525564,
                -15.686642384593164
              ],
              [
                -47.31931684269511,
                -15.686554864517609
              ],
              [
                -47.312265873596324,
                -15.694094784946357
              ],
              [
                -47.31192735575021,
                -15.706718244513018
              ],
              [
                -47.31761381886413,
                -15.716673184733207
              ],
              [
                -47.31942977682063,
                -15.719852177758767
              ],
              [
                -47.31715180804026,
                -15.724978132951021
              ],
              [
                -47.31593878288285,
                -15.727708125478928
              ],
              [
                -47.3168962693898,
                -15.729571622079382
              ],
              [
                -47.3183992773524,
                -15.732494577908582
              ],
              [
                -47.3166492899826,
                -15.734016076050137
              ],
              [
                -47.312480802302204,
                -15.737641554795426
              ],
              [
                -47.313709790323706,
                -15.746781529764867
              ],
              [
                -47.31623174142058,
                -15.745341038702787
              ],
              [
                -47.31885123666138,
                -15.755322473794843
              ],
              [
                -47.326541661879205,
                -15.764790447779411
              ],
              [
                -47.32829563345429,
                -15.766949419343993
              ],
              [
                -47.32860011503016,
                -15.770271923622538
              ],
              [
                -47.32940158722512,
                -15.779012875009663
              ],
              [
                -47.33296707425478,
                -15.779856354425483
              ],
              [
                -47.333812056152134,
                -15.780056360997587
              ],
              [
                -47.33483252327742,
                -15.783276340269499
              ],
              [
                -47.3382889748866,
                -15.794180809024443
              ],
              [
                -47.343341459008805,
                -15.798875769181379
              ],
              [
                -47.34814588257812,
                -15.80334024512512
              ],
              [
                -47.34723991365255,
                -15.80574176209891
              ],
              [
                -47.34659338013638,
                -15.80745373538593
              ],
              [
                -47.350248854970935,
                -15.810413237347323
              ],
              [
                -47.351922839866646,
                -15.811768731208435
              ],
              [
                -47.35216584651121,
                -15.81338919876847
              ],
              [
                -47.353425325747274,
                -15.82178816603085
              ],
              [
                -47.35697879562747,
                -15.820860688200078
              ],
              [
                -47.35962478717902,
                -15.820170182260826
              ],
              [
                -47.36101172826249,
                -15.8262766714095
              ],
              [
                -47.36551021961137,
                -15.827736662322067
              ],
              [
                -47.36415018431165,
                -15.836238092841015
              ],
              [
                -47.36896316460151,
                -15.840791578372198
              ],
              [
                -47.36725417510541,
                -15.849733522790226
              ],
              [
                -47.36662515507287,
                -15.85302802742045
              ],
              [
                -47.36921214270518,
                -15.857692978925042
              ],
              [
                -47.372317610245574,
                -15.863293471326852
              ],
              [
                -47.37076259503764,
                -15.863997469357013
              ],
              [
                -47.36923060166829,
                -15.864691963485157
              ],
              [
                -47.37012709230167,
                -15.871870431816944
              ],
              [
                -47.37234156677627,
                -15.875167910113095
              ],
              [
                -47.375757043950614,
                -15.88025189799401
              ],
              [
                -47.375256492833365,
                -15.896234286204077
              ],
              [
                -47.37073353917495,
                -15.899708286088865
              ],
              [
                -47.37226500106085,
                -15.906272245104075
              ],
              [
                -47.36933452365849,
                -15.907007238210644
              ],
              [
                -47.36323057664842,
                -15.908538194602881
              ],
              [
                -47.36711751835909,
                -15.917229182653966
              ],
              [
                -47.36523801425424,
                -15.920489643995209
              ],
              [
                -47.36131405076819,
                -15.919325164539542
              ],
              [
                -47.36034205297804,
                -15.931246564150968
              ],
              [
                -47.36304204862179,
                -15.929846107241625
              ],
              [
                -47.36278701287386,
                -15.933010059365776
              ],
              [
                -47.370978942251355,
                -15.93574505475827
              ],
              [
                -47.37206644987549,
                -15.936108555941473
              ],
              [
                -47.37109994873521,
                -15.936941548435618
              ],
              [
                -47.367095003017695,
                -15.940393052477354
              ],
              [
                -47.37178691093273,
                -15.960468441765634
              ],
              [
                -47.3735628982642,
                -15.96122692216853
              ],
              [
                -47.37629688414236,
                -15.962395417245547
              ],
              [
                -47.3732684021023,
                -15.966121408742529
              ],
              [
                -47.37315637269239,
                -15.966259391761277
              ],
              [
                -47.3732148807849,
                -15.966466893741057
              ],
              [
                -47.37644984859436,
                -15.977941864334747
              ],
              [
                -47.37112237243069,
                -15.979942341674466
              ],
              [
                -47.371066872824514,
                -15.979963334448
              ],
              [
                -47.37110989735229,
                -15.980024853197197
              ],
              [
                -47.37540380520267,
                -15.986171816134753
              ],
              [
                -47.370981342090516,
                -15.994128766652357
              ],
              [
                -47.368120862800744,
                -15.993622768676671
              ],
              [
                -47.36820538024142,
                -16.001485204770606
              ],
              [
                -47.363617879084224,
                -16.000295200068315
              ],
              [
                -47.35940341642467,
                -16.007274160360357
              ],
              [
                -47.350022003509736,
                -16.005941143802968
              ],
              [
                -47.34734551650306,
                -16.00556116850511
              ],
              [
                -47.34520352642119,
                -16.00929813072054
              ],
              [
                -47.344315522117554,
                -16.010847615444284
              ],
              [
                -47.34276451806831,
                -16.01099762050383
              ],
              [
                -47.33690358023384,
                -16.011563077621567
              ],
              [
                -47.338063072098045,
                -16.015266092659513
              ],
              [
                -47.3390885589345,
                -16.018544069759795
              ],
              [
                -47.336338566645054,
                -16.017173561738826
              ],
              [
                -47.334786600590824,
                -16.016400060856576
              ],
              [
                -47.32883761617598,
                -16.02546848519687
              ],
              [
                -47.32795861025036,
                -16.026808975083053
              ],
              [
                -47.32743864192094,
                -16.027016010902464
              ],
              [
                -47.323554650344484,
                -16.02856047762467
              ],
              [
                -47.32139716045984,
                -16.025754504661435
              ],
              [
                -47.32067615995983,
                -16.030499977204695
              ],
              [
                -47.31698170036652,
                -16.031356462077394
              ],
              [
                -47.31867970142412,
                -16.036235445758578
              ],
              [
                -47.315240210204216,
                -16.035980923230614
              ],
              [
                -47.30860927476364,
                -16.03549091157155
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 8,
        "nome": "Espírito Santo",
        "sigla": "ES",
        "regiao_id": 2,
        "codigo_ibg": 32
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -40.723829024505264,
                -20.841778138453748
              ],
              [
                -40.72804048991529,
                -20.84208567427709
              ],
              [
                -40.72905901258123,
                -20.842160648166
              ],
              [
                -40.74063236927322,
                -20.846236676817984
              ],
              [
                -40.74436485935732,
                -20.847962173729304
              ],
              [
                -40.74848078980881,
                -20.852885627844728
              ],
              [
                -40.75608370798257,
                -20.861313121515018
              ],
              [
                -40.75863366468003,
                -20.864207091311677
              ],
              [
                -40.76136262273229,
                -20.87452203455366
              ],
              [
                -40.761772120087734,
                -20.876069044943502
              ],
              [
                -40.7584326593569,
                -20.879069515813892
              ],
              [
                -40.76184610955565,
                -20.88458848909701
              ],
              [
                -40.76465507109293,
                -20.888970975287744
              ],
              [
                -40.76905604072553,
                -20.890478474954715
              ],
              [
                -40.77135951087141,
                -20.890939002640234
              ],
              [
                -40.772576009330685,
                -20.891181962060102
              ],
              [
                -40.773382485066946,
                -20.894305947659884
              ],
              [
                -40.77598944270535,
                -20.904407929872114
              ],
              [
                -40.78480281018331,
                -20.914294882389925
              ],
              [
                -40.786552328515775,
                -20.916225359726536
              ],
              [
                -40.792649228482894,
                -20.922954338508337
              ],
              [
                -40.7952527287171,
                -20.925829337590187
              ],
              [
                -40.79795616998194,
                -20.9314487947882
              ],
              [
                -40.803538081972185,
                -20.943056748575827
              ],
              [
                -40.80975899539608,
                -20.96698416408022
              ],
              [
                -40.80989145812753,
                -20.973595598019667
              ],
              [
                -40.810024441847425,
                -20.980207082966828
              ],
              [
                -40.81080391250724,
                -20.991555507356324
              ],
              [
                -40.81016789694277,
                -20.994753503327825
              ],
              [
                -40.80947490464675,
                -20.99611247327759
              ],
              [
                -40.805400924546426,
                -21.00409941408734
              ],
              [
                -40.80062799696452,
                -21.00465092116395
              ],
              [
                -40.806321912292226,
                -21.007184913234614
              ],
              [
                -40.808995383786474,
                -21.01355990031333
              ],
              [
                -40.812947296803486,
                -21.035362770608565
              ],
              [
                -40.8196972298315,
                -21.040519277736763
              ],
              [
                -40.82313617237854,
                -21.043055762681526
              ],
              [
                -40.82773865025187,
                -21.046450758581184
              ],
              [
                -40.83350004353845,
                -21.063003656974725
              ],
              [
                -40.835759012388316,
                -21.06949412856173
              ],
              [
                -40.837969987017715,
                -21.075847605799364
              ],
              [
                -40.84375788677546,
                -21.092478505937976
              ],
              [
                -40.853211756102866,
                -21.11964240090114
              ],
              [
                -40.85596471274246,
                -21.127553880932425
              ],
              [
                -40.85653420466911,
                -21.1279218722275
              ],
              [
                -40.85849015781604,
                -21.12918334082045
              ],
              [
                -40.861016127883275,
                -21.130813375708183
              ],
              [
                -40.88046094855086,
                -21.14336131180318
              ],
              [
                -40.883823921819605,
                -21.147777298403515
              ],
              [
                -40.88499590527542,
                -21.149316314689536
              ],
              [
                -40.887198382938585,
                -21.152209791946714
              ],
              [
                -40.89070183445548,
                -21.156810794872914
              ],
              [
                -40.90111773515254,
                -21.163236280764206
              ],
              [
                -40.907666639799714,
                -21.167276264792847
              ],
              [
                -40.91236110426881,
                -21.17353673643549
              ],
              [
                -40.92558941230317,
                -21.191176687695
              ],
              [
                -40.93311933367377,
                -21.203769586408047
              ],
              [
                -40.93505929379428,
                -21.206994616307743
              ],
              [
                -40.94272518450979,
                -21.219739032568164
              ],
              [
                -40.94410769405852,
                -21.222038023239026
              ],
              [
                -40.945490659602136,
                -21.224337018912482
              ],
              [
                -40.950119610818156,
                -21.23203251470417
              ],
              [
                -40.95322359638436,
                -21.23719199479129
              ],
              [
                -40.95548302126059,
                -21.243590451186655
              ],
              [
                -40.958159976581335,
                -21.25143140970793
              ],
              [
                -40.95940496879742,
                -21.25507837474561
              ],
              [
                -40.96009096434298,
                -21.257088399397663
              ],
              [
                -40.96042195499841,
                -21.26064384082018
              ],
              [
                -40.96080594986377,
                -21.26621334041162
              ],
              [
                -40.96148889557736,
                -21.276126796077268
              ],
              [
                -40.96168790917087,
                -21.279013760840023
              ],
              [
                -40.961073888063005,
                -21.282278239665796
              ],
              [
                -40.96029991114207,
                -21.28639372949516
              ],
              [
                -40.95941037664353,
                -21.291119703739678
              ],
              [
                -40.95718386734529,
                -21.302958636225565
              ],
              [
                -40.964311838415306,
                -21.299534641378546
              ],
              [
                -40.965443331823586,
                -21.295159202783058
              ],
              [
                -40.96791028873669,
                -21.293625197845433
              ],
              [
                -40.97108480049927,
                -21.293908193675396
              ],
              [
                -40.971167278059006,
                -21.293794718483472
              ],
              [
                -40.97572776673591,
                -21.287511735213176
              ],
              [
                -40.97629224426476,
                -21.285394268031464
              ],
              [
                -40.977583771487915,
                -21.28054378539242
              ],
              [
                -40.97951473917638,
                -21.282052804670258
              ],
              [
                -40.985165647807875,
                -21.286468781784777
              ],
              [
                -40.994867101392565,
                -21.285098317750535
              ],
              [
                -40.99671407480551,
                -21.28283482533623
              ],
              [
                -40.99805211598076,
                -21.265730397396705
              ],
              [
                -41.00203856877864,
                -21.25870196385007
              ],
              [
                -41.006026058565055,
                -21.251673480308625
              ],
              [
                -41.02474540321236,
                -21.24580255363145
              ],
              [
                -41.0328633327389,
                -21.250235562365162
              ],
              [
                -41.04098174626161,
                -21.254668563113952
              ],
              [
                -41.05507564563431,
                -21.238121192725842
              ],
              [
                -41.05546616534359,
                -21.237663206120505
              ],
              [
                -41.07014704869203,
                -21.228233248417187
              ],
              [
                -41.0720660319452,
                -21.229580779546282
              ],
              [
                -41.07301902664123,
                -21.230250276119317
              ],
              [
                -41.074613525955904,
                -21.228507757286163
              ],
              [
                -41.081033987960936,
                -21.221490334309415
              ],
              [
                -41.09064738973453,
                -21.22115936340713
              ],
              [
                -41.09203037926607,
                -21.21815886691728
              ],
              [
                -41.1022532754043,
                -21.221689872453144
              ],
              [
                -41.10389576314257,
                -21.219247873528726
              ],
              [
                -41.1121186790426,
                -21.225109843776988
              ],
              [
                -41.11883210029755,
                -21.22989536838194
              ],
              [
                -41.124756585144034,
                -21.2284248701232
              ],
              [
                -41.13322498489439,
                -21.226322408203547
              ],
              [
                -41.13704498062195,
                -21.227488422748216
              ],
              [
                -41.13888042905635,
                -21.232080889090433
              ],
              [
                -41.141339382782434,
                -21.23823284742639
              ],
              [
                -41.15680473929294,
                -21.24015287996621
              ],
              [
                -41.16056571245598,
                -21.245511848653745
              ],
              [
                -41.16362267948327,
                -21.24272237388899
              ],
              [
                -41.17640307981597,
                -21.244352402784415
              ],
              [
                -41.174080094540514,
                -21.240596896738083
              ],
              [
                -41.18069902983801,
                -21.24040293918827
              ],
              [
                -41.18116051289002,
                -21.239201453774875
              ],
              [
                -41.182456548227414,
                -21.235826954083095
              ],
              [
                -41.18487848287975,
                -21.239484947888574
              ],
              [
                -41.18582397264278,
                -21.240912443351313
              ],
              [
                -41.18313698663092,
                -21.243952426621984
              ],
              [
                -41.187739493893204,
                -21.24303292431642
              ],
              [
                -41.1904954441028,
                -21.247993426850236
              ],
              [
                -41.19248592364857,
                -21.242197432890933
              ],
              [
                -41.19916886101636,
                -21.243653953554368
              ],
              [
                -41.203670831101384,
                -21.239368971251675
              ],
              [
                -41.204865341703254,
                -21.24341446811379
              ],
              [
                -41.21182177871352,
                -21.23540301171382
              ],
              [
                -41.221263208065764,
                -21.232846073545538
              ],
              [
                -41.22053570731367,
                -21.230299582648378
              ],
              [
                -41.22030323355019,
                -21.22948457852303
              ],
              [
                -41.22220269704096,
                -21.229683592866067
              ],
              [
                -41.225907149894944,
                -21.23007158938734
              ],
              [
                -41.22689816765734,
                -21.227948575196
              ],
              [
                -41.22803865193164,
                -21.22550512508893
              ],
              [
                -41.23436408196829,
                -21.225663608954424
              ],
              [
                -41.24087055704748,
                -21.220665159178797
              ],
              [
                -41.25689189661803,
                -21.22529017943348
              ],
              [
                -41.259169360386764,
                -21.23052814737783
              ],
              [
                -41.26166432987313,
                -21.236269125113207
              ],
              [
                -41.26527931430163,
                -21.232598626405704
              ],
              [
                -41.268055298180606,
                -21.232718133227962
              ],
              [
                -41.27191573124782,
                -21.232884159326336
              ],
              [
                -41.27141726530811,
                -21.23680362595229
              ],
              [
                -41.27538321628689,
                -21.235893658121803
              ],
              [
                -41.27666469959764,
                -21.23975415022
              ],
              [
                -41.28346863288098,
                -21.234450179782385
              ],
              [
                -41.28561763103957,
                -21.23733168720278
              ],
              [
                -41.288417098718384,
                -21.233680206486046
              ],
              [
                -41.296904014229526,
                -21.23393719388625
              ],
              [
                -41.308167441801245,
                -21.222566275664672
              ],
              [
                -41.31072692387255,
                -21.219200297842384
              ],
              [
                -41.31103242121055,
                -21.218798336729268
              ],
              [
                -41.311521441365485,
                -21.218978802892977
              ],
              [
                -41.317273888374125,
                -21.22110131982299
              ],
              [
                -41.31699388755275,
                -21.217451827912438
              ],
              [
                -41.31667287352951,
                -21.213261372835383
              ],
              [
                -41.325726307464954,
                -21.212597373533146
              ],
              [
                -41.325764825950486,
                -21.214924381034923
              ],
              [
                -41.32581629126019,
                -21.218027866367844
              ],
              [
                -41.33069176979016,
                -21.213483376317857
              ],
              [
                -41.334146761493194,
                -21.21026189679921
              ],
              [
                -41.36324300667558,
                -21.20240602713737
              ],
              [
                -41.367700956237805,
                -21.202657004178295
              ],
              [
                -41.37261242168875,
                -21.202933515140447
              ],
              [
                -41.373821931382274,
                -21.200866525136906
              ],
              [
                -41.377108894962376,
                -21.195248570178418
              ],
              [
                -41.38101588334732,
                -21.19591459969025
              ],
              [
                -41.38184786391845,
                -21.199687563234026
              ],
              [
                -41.38186583354595,
                -21.199767547843578
              ],
              [
                -41.39208476049916,
                -21.20161609769681
              ],
              [
                -41.39807222377794,
                -21.199854613190094
              ],
              [
                -41.402967147950484,
                -21.198414638396116
              ],
              [
                -41.40431914390187,
                -21.199919112422293
              ],
              [
                -41.40643111283054,
                -21.202269601652564
              ],
              [
                -41.40884512342236,
                -21.1999656327362
              ],
              [
                -41.410259576391915,
                -21.198616609326823
              ],
              [
                -41.41218356304779,
                -21.200571645256005
              ],
              [
                -41.412835549475936,
                -21.201234121199267
              ],
              [
                -41.41351905222493,
                -21.200916620510398
              ],
              [
                -41.41689052032813,
                -21.199349154849983
              ],
              [
                -41.42079198540245,
                -21.202792644359697
              ],
              [
                -41.43521981795342,
                -21.21552508132057
              ],
              [
                -41.44752722327171,
                -21.213638148276342
              ],
              [
                -41.45084471892699,
                -21.210546133719397
              ],
              [
                -41.45373069735729,
                -21.20785715797839
              ],
              [
                -41.46439661597113,
                -21.20347372556889
              ],
              [
                -41.46985604529503,
                -21.201230254438745
              ],
              [
                -41.47947047537696,
                -21.193431812997815
              ],
              [
                -41.47883702305244,
                -21.18175138156154
              ],
              [
                -41.4822269948353,
                -21.183266377304015
              ],
              [
                -41.487949437909215,
                -21.179863890040473
              ],
              [
                -41.49489838910359,
                -21.18322287515096
              ],
              [
                -41.49819484651357,
                -21.184816901254653
              ],
              [
                -41.50172332030158,
                -21.181352402196598
              ],
              [
                -41.507660279929496,
                -21.1755234595265
              ],
              [
                -41.512520765589855,
                -21.17630197668609
              ],
              [
                -41.51370823445769,
                -21.17877096614082
              ],
              [
                -41.517438170640204,
                -21.186524922035144
              ],
              [
                -41.53050058249666,
                -21.17932997139027
              ],
              [
                -41.548028418439,
                -21.182364498031514
              ],
              [
                -41.558025848867594,
                -21.172445577897335
              ],
              [
                -41.56531125400824,
                -21.182236036172934
              ],
              [
                -41.568879749316295,
                -21.179209559836416
              ],
              [
                -41.57030676490245,
                -21.170834603315438
              ],
              [
                -41.574645214673424,
                -21.168067150384466
              ],
              [
                -41.58179565416722,
                -21.16577765945665
              ],
              [
                -41.58438367661437,
                -21.157706212019885
              ],
              [
                -41.5873956648017,
                -21.14831126871642
              ],
              [
                -41.60025955146396,
                -21.15168426062084
              ],
              [
                -41.613123905133534,
                -21.155057806542892
              ],
              [
                -41.62689331318236,
                -21.145119891316217
              ],
              [
                -41.628493302035096,
                -21.143964887283953
              ],
              [
                -41.63023627366762,
                -21.144295374582175
              ],
              [
                -41.638540203686375,
                -21.145872394522065
              ],
              [
                -41.64638214768414,
                -21.144115930341492
              ],
              [
                -41.65370859496793,
                -21.14247295034358
              ],
              [
                -41.65305057667877,
                -21.136883980907
              ],
              [
                -41.66060453893189,
                -21.1333675265305
              ],
              [
                -41.66451799131985,
                -21.132184505063137
              ],
              [
                -41.66406401941889,
                -21.130655034238835
              ],
              [
                -41.66225904062503,
                -21.1245705667923
              ],
              [
                -41.67122893686416,
                -21.126489577446083
              ],
              [
                -41.67195847266285,
                -21.127137554663438
              ],
              [
                -41.6773713768027,
                -21.131948033428138
              ],
              [
                -41.67698389470711,
                -21.125757576859193
              ],
              [
                -41.67783192162331,
                -21.124837608799112
              ],
              [
                -41.681784370930146,
                -21.120549621145653
              ],
              [
                -41.68788832137911,
                -21.12143663765579
              ],
              [
                -41.69228829240367,
                -21.115762671512595
              ],
              [
                -41.698508254405176,
                -21.117938658353985
              ],
              [
                -41.705315684649435,
                -21.120319655472862
              ],
              [
                -41.70416266805445,
                -21.126645633717743
              ],
              [
                -41.71758605273773,
                -21.12333066400435
              ],
              [
                -41.717534572165455,
                -21.115824198287754
              ],
              [
                -41.71178062238194,
                -21.11351020127202
              ],
              [
                -41.70475220227362,
                -21.11068370303836
              ],
              [
                -41.707456682999364,
                -21.106647747103487
              ],
              [
                -41.71069166391329,
                -21.106037241962525
              ],
              [
                -41.716696625637425,
                -21.104904277118603
              ],
              [
                -41.71706460055695,
                -21.104834792354094
              ],
              [
                -41.72341957035642,
                -21.10363579968572
              ],
              [
                -41.72861949705432,
                -21.10633127934748
              ],
              [
                -41.735717463848914,
                -21.102282822760802
              ],
              [
                -41.72129912924616,
                -21.073108445168437
              ],
              [
                -41.7229496303452,
                -21.06792949893783
              ],
              [
                -41.731539550427975,
                -21.066318021699356
              ],
              [
                -41.728580160574694,
                -21.05013958099409
              ],
              [
                -41.72393064851175,
                -21.05591206501113
              ],
              [
                -41.72313314622423,
                -21.05567105944773
              ],
              [
                -41.71999370458642,
                -21.054722049231895
              ],
              [
                -41.7162307544152,
                -21.046626598102954
              ],
              [
                -41.72139672197855,
                -21.043461641233247
              ],
              [
                -41.72214222148349,
                -21.040774644466456
              ],
              [
                -41.7251502305304,
                -21.029926208008856
              ],
              [
                -41.72330825070225,
                -21.024697697923322
              ],
              [
                -41.721026756743015,
                -21.024014226290188
              ],
              [
                -41.71576531957567,
                -21.02243723553077
              ],
              [
                -41.71510583801997,
                -21.016162226864733
              ],
              [
                -41.71953030661731,
                -21.01282128321449
              ],
              [
                -41.72242928169961,
                -21.010631775791918
              ],
              [
                -41.72188679414901,
                -21.002444829729445
              ],
              [
                -41.71572288551186,
                -20.99157637807018
              ],
              [
                -41.71483038370152,
                -20.991471892556305
              ],
              [
                -41.710368420637074,
                -20.990952889970732
              ],
              [
                -41.71153996868094,
                -20.980558921860208
              ],
              [
                -41.714879440858155,
                -20.976470463726503
              ],
              [
                -41.71567791435879,
                -20.97549245987005
              ],
              [
                -41.71621544956948,
                -20.969435011860163
              ],
              [
                -41.71754644935037,
                -20.954419098136228
              ],
              [
                -41.719299433054665,
                -20.953188095868043
              ],
              [
                -41.73340835010982,
                -20.943279179247043
              ],
              [
                -41.73600536136746,
                -20.927983761005148
              ],
              [
                -41.722731486939026,
                -20.922302250918744
              ],
              [
                -41.721766532402654,
                -20.915109807605997
              ],
              [
                -41.71929959159233,
                -20.896715392476622
              ],
              [
                -41.722242034463754,
                -20.896736909183282
              ],
              [
                -41.72494403247061,
                -20.89675689634103
              ],
              [
                -41.726721021291056,
                -20.892352944311277
              ],
              [
                -41.722584090079756,
                -20.883538472465645
              ],
              [
                -41.718586136849275,
                -20.87501900236355
              ],
              [
                -41.71199771757005,
                -20.871072512493463
              ],
              [
                -41.72685358227263,
                -20.864086589760753
              ],
              [
                -41.728810542566585,
                -20.869568049586817
              ],
              [
                -41.74132444797569,
                -20.871379096767146
              ],
              [
                -41.73978798074585,
                -20.865598108527696
              ],
              [
                -41.74518445445321,
                -20.85281969027266
              ],
              [
                -41.73698805882844,
                -20.83609824095623
              ],
              [
                -41.74066705215837,
                -20.82963830850045
              ],
              [
                -41.73888606797726,
                -20.82732633092164
              ],
              [
                -41.74256702407712,
                -20.825150819234036
              ],
              [
                -41.74258053397841,
                -20.82514281630867
              ],
              [
                -41.7425545524505,
                -20.825050315750346
              ],
              [
                -41.74050659454901,
                -20.817798881352935
              ],
              [
                -41.74734800563851,
                -20.814487900130374
              ],
              [
                -41.749839990932706,
                -20.81741139803174
              ],
              [
                -41.749988505161596,
                -20.8175859004285
              ],
              [
                -41.750265988152755,
                -20.81703241307243
              ],
              [
                -41.75526749498802,
                -20.80704747180212
              ],
              [
                -41.76190991018118,
                -20.806670969197526
              ],
              [
                -41.76351841418454,
                -20.806579488411888
              ],
              [
                -41.76560438842143,
                -20.803635004206715
              ],
              [
                -41.76711639362034,
                -20.801500529280872
              ],
              [
                -41.77540331779736,
                -20.799393556864317
              ],
              [
                -41.79406514311393,
                -20.798467086032154
              ],
              [
                -41.79984057316166,
                -20.804149563579113
              ],
              [
                -41.800786085363406,
                -20.80508006972196
              ],
              [
                -41.80208458595852,
                -20.805049075893844
              ],
              [
                -41.81344847416543,
                -20.80477458067379
              ],
              [
                -41.80918352839837,
                -20.799992600726082
              ],
              [
                -41.806648536254464,
                -20.7971501092723
              ],
              [
                -41.81142201184213,
                -20.798753637620283
              ],
              [
                -41.81645695888734,
                -20.800445642096903
              ],
              [
                -41.828113338156996,
                -20.796110685560443
              ],
              [
                -41.826102899277394,
                -20.790627677626908
              ],
              [
                -41.82517540109771,
                -20.788096716201224
              ],
              [
                -41.8369433204212,
                -20.781075281478852
              ],
              [
                -41.84773075789308,
                -20.77464131730171
              ],
              [
                -41.85343472320674,
                -20.763652394061268
              ],
              [
                -41.866143603695896,
                -20.76526440070641
              ],
              [
                -41.87450052309149,
                -20.76632441128902
              ],
              [
                -41.87855852506634,
                -20.760271956473666
              ],
              [
                -41.87779101834933,
                -20.757783977182836
              ],
              [
                -41.87713703366543,
                -20.755664486158537
              ],
              [
                -41.873412561086504,
                -20.74359755596949
              ],
              [
                -41.8631026957729,
                -20.741448511806816
              ],
              [
                -41.859145693998535,
                -20.748241472823842
              ],
              [
                -41.856145237256825,
                -20.747188980600537
              ],
              [
                -41.86436169420618,
                -20.723220145605758
              ],
              [
                -41.864945216488245,
                -20.721518647185746
              ],
              [
                -41.8636577053735,
                -20.7217056351998
              ],
              [
                -41.86088574480737,
                -20.722109118613385
              ],
              [
                -41.85604782711943,
                -20.71421468830185
              ],
              [
                -41.859320263911684,
                -20.712411168640458
              ],
              [
                -41.86188375795239,
                -20.71099719822904
              ],
              [
                -41.860643799264096,
                -20.706252734127066
              ],
              [
                -41.855900316628215,
                -20.70530619532932
              ],
              [
                -41.85420584947735,
                -20.70496770826077
              ],
              [
                -41.85085888642901,
                -20.69483127659981
              ],
              [
                -41.849933409892294,
                -20.69203027768372
              ],
              [
                -41.84789792421558,
                -20.686900788864317
              ],
              [
                -41.84756095540275,
                -20.686050798706827
              ],
              [
                -41.8457889577156,
                -20.685412305095454
              ],
              [
                -41.8389590104503,
                -20.682950301748807
              ],
              [
                -41.83287761691434,
                -20.670350839249025
              ],
              [
                -41.82374571087147,
                -20.662539380730408
              ],
              [
                -41.826152720474845,
                -20.657966903139215
              ],
              [
                -41.827234677249606,
                -20.65591193280643
              ],
              [
                -41.82563422467564,
                -20.6561864216339
              ],
              [
                -41.819074277713995,
                -20.657309881441986
              ],
              [
                -41.81282333972551,
                -20.652767410795523
              ],
              [
                -41.81601182167202,
                -20.646552458947806
              ],
              [
                -41.80838238135108,
                -20.643746951695803
              ],
              [
                -41.81104639815674,
                -20.63705499024874
              ],
              [
                -41.81432682217597,
                -20.640141973973954
              ],
              [
                -41.82005629161911,
                -20.6370330220573
              ],
              [
                -41.82078827718332,
                -20.63663549490241
              ],
              [
                -41.81964880908499,
                -20.632839524952736
              ],
              [
                -41.81700633921492,
                -20.62404155479675
              ],
              [
                -41.82533978614444,
                -20.624220575948435
              ],
              [
                -41.82869674930794,
                -20.62429308625945
              ],
              [
                -41.84635162379537,
                -20.6214396493278
              ],
              [
                -41.85251955877997,
                -20.618181171227924
              ],
              [
                -41.85573703619108,
                -20.614005700344823
              ],
              [
                -41.85273259669783,
                -20.607060727259633
              ],
              [
                -41.84533317107517,
                -20.606267728598265
              ],
              [
                -41.841138719559815,
                -20.600296249500474
              ],
              [
                -41.840891209656995,
                -20.599943746853015
              ],
              [
                -41.840745724426775,
                -20.595890298601375
              ],
              [
                -41.840391262117926,
                -20.586004315655355
              ],
              [
                -41.827220431584394,
                -20.5564979433737
              ],
              [
                -41.81565601863363,
                -20.55263946996958
              ],
              [
                -41.80789713281485,
                -20.550050957352823
              ],
              [
                -41.803904678715156,
                -20.543590979490865
              ],
              [
                -41.80388816750967,
                -20.537904003505083
              ],
              [
                -41.8111261670129,
                -20.520265134374938
              ],
              [
                -41.81179113531346,
                -20.51864413574822
              ],
              [
                -41.81037614741822,
                -20.51490717193249
              ],
              [
                -41.808961684514585,
                -20.51117067311879
              ],
              [
                -41.80823619874347,
                -20.503848718475087
              ],
              [
                -41.80725975568104,
                -20.493995763104174
              ],
              [
                -41.805696761870166,
                -20.490728265403735
              ],
              [
                -41.803071292139286,
                -20.485239277431955
              ],
              [
                -41.799178872381034,
                -20.477102313065494
              ],
              [
                -41.80240642586659,
                -20.442391541372384
              ],
              [
                -41.79782946139778,
                -20.434523545046776
              ],
              [
                -41.80310692454035,
                -20.429165085393407
              ],
              [
                -41.80310145538049,
                -20.428855606075988
              ],
              [
                -41.80296994634369,
                -20.42189613289761
              ],
              [
                -41.83466519787901,
                -20.409336752728926
              ],
              [
                -41.84832716905624,
                -20.374256501946988
              ],
              [
                -41.84916114308134,
                -20.374108981648604
              ],
              [
                -41.85837108706491,
                -20.372478021494086
              ],
              [
                -41.85499012312674,
                -20.363755551622337
              ],
              [
                -41.85209567768647,
                -20.356288081991437
              ],
              [
                -41.85223767042033,
                -20.347665121595234
              ],
              [
                -41.84399179951287,
                -20.340437176548004
              ],
              [
                -41.846733277495936,
                -20.32899275254942
              ],
              [
                -41.8414448385673,
                -20.32606024966504
              ],
              [
                -41.835925387256204,
                -20.329579726850064
              ],
              [
                -41.83388539729441,
                -20.325234715079493
              ],
              [
                -41.825265001145475,
                -20.31939025765359
              ],
              [
                -41.82525298627179,
                -20.319382242670425
              ],
              [
                -41.825245971388284,
                -20.319360751772507
              ],
              [
                -41.824550497016446,
                -20.31719876707058
              ],
              [
                -41.8226675394812,
                -20.3113507689273
              ],
              [
                -41.810705128142416,
                -20.308120258620214
              ],
              [
                -41.80653817173792,
                -20.30699478738309
              ],
              [
                -41.80529919241746,
                -20.30433476719931
              ],
              [
                -41.802679239798636,
                -20.298709805192313
              ],
              [
                -41.793164343980834,
                -20.29583731848631
              ],
              [
                -41.78062096019469,
                -20.292050801980512
              ],
              [
                -41.77665749387606,
                -20.284664829660517
              ],
              [
                -41.77922899676857,
                -20.28207334672048
              ],
              [
                -41.780033984727034,
                -20.281261858001432
              ],
              [
                -41.77933847255622,
                -20.278619875967408
              ],
              [
                -41.7743155591607,
                -20.259543943626245
              ],
              [
                -41.769344630493855,
                -20.254603988622506
              ],
              [
                -41.76675571826184,
                -20.229311637117803
              ],
              [
                -41.76670019513067,
                -20.228765610001812
              ],
              [
                -41.76623872273986,
                -20.228551634142487
              ],
              [
                -41.76362171588384,
                -20.227336119952348
              ],
              [
                -41.7643307266455,
                -20.225747119305158
              ],
              [
                -41.76537071936244,
                -20.223416164492086
              ],
              [
                -41.759479801931256,
                -20.212865686405085
              ],
              [
                -41.75667783428221,
                -20.213402189147175
              ],
              [
                -41.75634785692488,
                -20.206372241172698
              ],
              [
                -41.754295391030006,
                -20.206368729579296
              ],
              [
                -41.665533166530736,
                -20.206204061203138
              ],
              [
                -41.556417592854785,
                -20.206052828602164
              ],
              [
                -41.424463260161495,
                -20.206173576133335
              ],
              [
                -41.41129287378395,
                -20.20618602668821
              ],
              [
                -41.412480388360954,
                -20.20175157295629
              ],
              [
                -41.40868444535727,
                -20.197205084739338
              ],
              [
                -41.40125848531261,
                -20.198735064690442
              ],
              [
                -41.38170570858117,
                -20.18831807000456
              ],
              [
                -41.37537779033464,
                -20.172022646507294
              ],
              [
                -41.373685298203604,
                -20.167664664977217
              ],
              [
                -41.37552478439539,
                -20.161454219609183
              ],
              [
                -41.36742836783928,
                -20.15635673496693
              ],
              [
                -41.36148498130026,
                -20.141584791970867
              ],
              [
                -41.35893851397286,
                -20.140335805268908
              ],
              [
                -41.3554060065851,
                -20.138603802068147
              ],
              [
                -41.35592103318728,
                -20.134519303953855
              ],
              [
                -41.35611555230953,
                -20.132973847991266
              ],
              [
                -41.35403507624252,
                -20.13076383268068
              ],
              [
                -41.347113621854,
                -20.123413366265314
              ],
              [
                -41.35043109508523,
                -20.118736904676016
              ],
              [
                -41.35048110981987,
                -20.11866538618543
              ],
              [
                -41.34590268636326,
                -20.10297747845781
              ],
              [
                -41.34481322897409,
                -20.08849055981103
              ],
              [
                -41.340587752146334,
                -20.087387542606006
              ],
              [
                -41.33712530906327,
                -20.08648455897658
              ],
              [
                -41.33618483651439,
                -20.075950622655707
              ],
              [
                -41.335204369393345,
                -20.06497615272359
              ],
              [
                -41.331481416952364,
                -20.062724673057282
              ],
              [
                -41.33314992001234,
                -20.05834617918573
              ],
              [
                -41.328025442180575,
                -20.054803682635207
              ],
              [
                -41.32377650209508,
                -20.051865727650394
              ],
              [
                -41.3195555735267,
                -20.043766262560403
              ],
              [
                -41.316891614247936,
                -20.026724348893296
              ],
              [
                -41.31685212521652,
                -20.02647385320608
              ],
              [
                -41.31670109612269,
                -20.026243851158192
              ],
              [
                -41.30768821183945,
                -20.012525405967015
              ],
              [
                -41.30838825497875,
                -19.99657447671782
              ],
              [
                -41.3145502166158,
                -19.98634206753198
              ],
              [
                -41.319491682065184,
                -19.978136627321767
              ],
              [
                -41.3153897638426,
                -19.97470562249208
              ],
              [
                -41.311288294615885,
                -19.97127412567178
              ],
              [
                -41.30969384108185,
                -19.96138117454603
              ],
              [
                -41.307512372686524,
                -19.94784473855475
              ],
              [
                -41.299964960023495,
                -19.937874284830915
              ],
              [
                -41.29966197460858,
                -19.93791429094131
              ],
              [
                -41.288163565701474,
                -19.939440758136207
              ],
              [
                -41.271697714204166,
                -19.941625702737987
              ],
              [
                -41.25420386868412,
                -19.931651221284
              ],
              [
                -41.25012188963712,
                -19.935818207957414
              ],
              [
                -41.248518908827556,
                -19.93745520625928
              ],
              [
                -41.24377047052091,
                -19.936391172812176
              ],
              [
                -41.23310858435206,
                -19.919935244779992
              ],
              [
                -41.232174094166666,
                -19.91849325482696
              ],
              [
                -41.227970166153476,
                -19.90512533867691
              ],
              [
                -41.21087632696505,
                -19.900508346200724
              ],
              [
                -41.205150356985015,
                -19.902447308779095
              ],
              [
                -41.20464738302852,
                -19.902617280723685
              ],
              [
                -41.20382139476966,
                -19.902037307175426
              ],
              [
                -41.18763806099252,
                -19.890688323575713
              ],
              [
                -41.184476074450984,
                -19.88846884214458
              ],
              [
                -41.18360020612115,
                -19.848330055052966
              ],
              [
                -41.180142717896665,
                -19.84704757873212
              ],
              [
                -41.17873824561577,
                -19.846527068599755
              ],
              [
                -41.17528976786309,
                -19.838857601265122
              ],
              [
                -41.17866725898292,
                -19.835134624591937
              ],
              [
                -41.185888201345094,
                -19.82717470615554
              ],
              [
                -41.17504480724477,
                -19.82520919960382
              ],
              [
                -41.165770911841875,
                -19.809418228396165
              ],
              [
                -41.16554044324366,
                -19.791972831252064
              ],
              [
                -41.17289890589857,
                -19.78336892677112
              ],
              [
                -41.17128745358348,
                -19.777842924437238
              ],
              [
                -41.17449395850996,
                -19.76656401103313
              ],
              [
                -41.18739837215566,
                -19.757490575252678
              ],
              [
                -41.190393836505336,
                -19.746390129393916
              ],
              [
                -41.1843714076199,
                -19.742770667751962
              ],
              [
                -41.17910243296422,
                -19.739603681198275
              ],
              [
                -41.18160645791549,
                -19.73169422728725
              ],
              [
                -41.18161047691014,
                -19.7316817233666
              ],
              [
                -41.178621491781115,
                -19.719524763544893
              ],
              [
                -41.178553019541084,
                -19.719461281755123
              ],
              [
                -41.173900554188236,
                -19.71514380906447
              ],
              [
                -41.17578056693222,
                -19.708354821496545
              ],
              [
                -41.177319579715,
                -19.70279886422624
              ],
              [
                -41.17128412222716,
                -19.692418404835998
              ],
              [
                -41.17339560691365,
                -19.687281952445137
              ],
              [
                -41.16806919557537,
                -19.671731030874188
              ],
              [
                -41.159473311358184,
                -19.66191204485587
              ],
              [
                -41.15280337131537,
                -19.662795063450677
              ],
              [
                -41.14855787657249,
                -19.663357046099996
              ],
              [
                -41.138221995472094,
                -19.658233038789483
              ],
              [
                -41.13372603923421,
                -19.650362081658464
              ],
              [
                -41.129090126811974,
                -19.642247113625604
              ],
              [
                -41.12363717329069,
                -19.640514133685564
              ],
              [
                -41.119215682530324,
                -19.64090508593703
              ],
              [
                -41.11412024859816,
                -19.641357102390206
              ],
              [
                -41.10799481749468,
                -19.638187084153493
              ],
              [
                -41.101590410672806,
                -19.61299923918636
              ],
              [
                -41.099382952029195,
                -19.604320754673083
              ],
              [
                -41.095090506358844,
                -19.602529271605864
              ],
              [
                -41.09224900837827,
                -19.60134429221973
              ],
              [
                -41.09136403914038,
                -19.599289800977022
              ],
              [
                -41.08991556602794,
                -19.595926802951368
              ],
              [
                -41.085491106206945,
                -19.59635827699057
              ],
              [
                -41.073605666783614,
                -19.597515776872
              ],
              [
                -41.07242270364787,
                -19.58983381095227
              ],
              [
                -41.071511752874244,
                -19.583915846607375
              ],
              [
                -41.062906830652125,
                -19.584124332944235
              ],
              [
                -41.05584938880796,
                -19.584295787817027
              ],
              [
                -41.05655338070128,
                -19.577543834693994
              ],
              [
                -41.03642959218859,
                -19.568302332054927
              ],
              [
                -41.038353587994415,
                -19.55159096722095
              ],
              [
                -41.03937308824986,
                -19.549370457036716
              ],
              [
                -41.04456107218637,
                -19.538072039428258
              ],
              [
                -41.04126864207307,
                -19.517618134793643
              ],
              [
                -41.04083665428914,
                -19.514933673153255
              ],
              [
                -41.04148663581779,
                -19.51397117902357
              ],
              [
                -41.04748364577826,
                -19.50509524438797
              ],
              [
                -41.049257133163,
                -19.502529256791775
              ],
              [
                -41.045368207363424,
                -19.487491324134588
              ],
              [
                -41.0281517909887,
                -19.498419737091044
              ],
              [
                -41.00891094898754,
                -19.506661149024907
              ],
              [
                -40.97777223188975,
                -19.50508560150427
              ],
              [
                -40.97183479722808,
                -19.504804094435222
              ],
              [
                -40.971327312018424,
                -19.499626612878018
              ],
              [
                -40.971014306341665,
                -19.496446145349623
              ],
              [
                -40.970783824942345,
                -19.494104150216327
              ],
              [
                -40.969180839726924,
                -19.49217967577462
              ],
              [
                -40.953167547355584,
                -19.4729512403724
              ],
              [
                -40.948926045661814,
                -19.47266570896772
              ],
              [
                -40.95098404814827,
                -19.46517025914925
              ],
              [
                -40.94446962106224,
                -19.459625803946334
              ],
              [
                -40.95506104179562,
                -19.451301347036235
              ],
              [
                -40.957433038165426,
                -19.449436872733813
              ],
              [
                -40.95819002743461,
                -19.4409849156274
              ],
              [
                -40.95833553397551,
                -19.439363926198233
              ],
              [
                -40.958929062639925,
                -19.438547955122658
              ],
              [
                -40.967210493087855,
                -19.42716750976015
              ],
              [
                -40.96364502354783,
                -19.42300001999723
              ],
              [
                -40.96007356407365,
                -19.418826050264936
              ],
              [
                -40.96181159435461,
                -19.407938626200973
              ],
              [
                -40.95678164139248,
                -19.398424675929142
              ],
              [
                -40.9567336370327,
                -19.398333661347813
              ],
              [
                -40.95670166336226,
                -19.398314142391616
              ],
              [
                -40.928292931678236,
                -19.380747186665513
              ],
              [
                -40.931184420392476,
                -19.373369263036455
              ],
              [
                -40.92777995158592,
                -19.36741676291303
              ],
              [
                -40.93231642998995,
                -19.363743813556862
              ],
              [
                -40.93923391181667,
                -19.358142365287488
              ],
              [
                -40.9382209313786,
                -19.34725641550186
              ],
              [
                -40.92812056919327,
                -19.329254514309177
              ],
              [
                -40.92164212053751,
                -19.328444974256932
              ],
              [
                -40.919656650991726,
                -19.319126547498737
              ],
              [
                -40.91482167488801,
                -19.31783703468229
              ],
              [
                -40.9142796980328,
                -19.317692538366437
              ],
              [
                -40.90730077186868,
                -19.30832358138282
              ],
              [
                -40.91561420244508,
                -19.300509627767827
              ],
              [
                -40.92324116377372,
                -19.301811652393763
              ],
              [
                -40.924145618393204,
                -19.30196616341642
              ],
              [
                -40.9309245734166,
                -19.29935216572321
              ],
              [
                -40.93237659841754,
                -19.295562700529402
              ],
              [
                -40.930209085311105,
                -19.291000242134487
              ],
              [
                -40.92765464453667,
                -19.28562324960375
              ],
              [
                -40.93056310956672,
                -19.285703755276757
              ],
              [
                -40.937981541329705,
                -19.28590978274476
              ],
              [
                -40.94409451718132,
                -19.279058793955517
              ],
              [
                -40.93452661320413,
                -19.27126485451452
              ],
              [
                -40.929345679384596,
                -19.27115332623338
              ],
              [
                -40.919547738728795,
                -19.27094181479746
              ],
              [
                -40.91805727168485,
                -19.26163436311242
              ],
              [
                -40.91711629073238,
                -19.25576439385085
              ],
              [
                -40.91979330335394,
                -19.251330428961282
              ],
              [
                -40.927600241565656,
                -19.250299969318593
              ],
              [
                -40.928492719930325,
                -19.25018245987284
              ],
              [
                -40.938243630530394,
                -19.248892461816354
              ],
              [
                -40.932623192091505,
                -19.2421419897614
              ],
              [
                -40.93280070291742,
                -19.241971016117365
              ],
              [
                -40.940121135531214,
                -19.234907546108698
              ],
              [
                -40.94075462646997,
                -19.231670079043965
              ],
              [
                -40.934510240262284,
                -19.222463119821224
              ],
              [
                -40.930619266974674,
                -19.221932619688413
              ],
              [
                -40.92877826616992,
                -19.214686153479594
              ],
              [
                -40.92791328176295,
                -19.21128168223783
              ],
              [
                -40.92226535669159,
                -19.203599209556003
              ],
              [
                -40.92914181025876,
                -19.197370733826453
              ],
              [
                -40.92633637506493,
                -19.188793320283427
              ],
              [
                -40.93218031825973,
                -19.185797327788777
              ],
              [
                -40.93375533215362,
                -19.18027185088854
              ],
              [
                -40.935294299297865,
                -19.181228351614298
              ],
              [
                -40.937950779927455,
                -19.182879865687337
              ],
              [
                -40.940685250207395,
                -19.180083856514123
              ],
              [
                -40.94164127092301,
                -19.16537394217203
              ],
              [
                -40.94194779717706,
                -19.16065097800089
              ],
              [
                -40.94501274762528,
                -19.15695102072643
              ],
              [
                -40.94845672777961,
                -19.152793538880616
              ],
              [
                -40.94744772518585,
                -19.150867070856474
              ],
              [
                -40.94379478371828,
                -19.14389158936631
              ],
              [
                -40.94767428280457,
                -19.141116130477652
              ],
              [
                -40.95195624361797,
                -19.14209613580608
              ],
              [
                -40.96054764698991,
                -19.144063113486258
              ],
              [
                -40.95727970093101,
                -19.13457568026561
              ],
              [
                -40.9555397420442,
                -19.12952568670494
              ],
              [
                -40.96249066611172,
                -19.12107023696262
              ],
              [
                -40.9663001396277,
                -19.121831740552867
              ],
              [
                -40.96916509694168,
                -19.12240476925193
              ],
              [
                -40.973125088031146,
                -19.115010298132827
              ],
              [
                -40.98176252835782,
                -19.10907034442877
              ],
              [
                -40.982649035511734,
                -19.106049369685504
              ],
              [
                -40.98435701388156,
                -19.100227932795036
              ],
              [
                -40.99228097667724,
                -19.09940641111001
              ],
              [
                -41.00804731399764,
                -19.097770481533523
              ],
              [
                -41.00592435977987,
                -19.089996003867284
              ],
              [
                -41.00406240303358,
                -19.083176553257253
              ],
              [
                -41.010159333086065,
                -19.08707502155423
              ],
              [
                -41.014374777563496,
                -19.089770523850277
              ],
              [
                -41.01361180906456,
                -19.08469405249287
              ],
              [
                -41.0122013362473,
                -19.075313121585282
              ],
              [
                -41.01887376720934,
                -19.074895641945346
              ],
              [
                -41.02316175286059,
                -19.0781076344121
              ],
              [
                -41.02500722034285,
                -19.079490096310334
              ],
              [
                -41.03508663170979,
                -19.071072685880512
              ],
              [
                -41.053450482625145,
                -19.06837720382546
              ],
              [
                -41.052833497497566,
                -19.058688270334436
              ],
              [
                -41.060330460163364,
                -19.05569779823848
              ],
              [
                -41.064473915190995,
                -19.051529860909785
              ],
              [
                -41.065154420761225,
                -19.050845360275392
              ],
              [
                -41.062736981932815,
                -19.03881492552584
              ],
              [
                -41.07138743420931,
                -19.02336052065384
              ],
              [
                -41.06552848320029,
                -19.015894565453408
              ],
              [
                -41.06594101185908,
                -19.006227091052487
              ],
              [
                -41.05648611379,
                -19.00258062535736
              ],
              [
                -41.051437138885575,
                -19.000632594064236
              ],
              [
                -41.017181520174454,
                -18.976045203627343
              ],
              [
                -41.01796649211425,
                -18.973054208536823
              ],
              [
                -41.028685420507166,
                -18.973875205102004
              ],
              [
                -41.03546733458946,
                -18.979524211602623
              ],
              [
                -41.038368313037864,
                -18.979924213334268
              ],
              [
                -41.04201578387803,
                -18.97728824014739
              ],
              [
                -41.04558876725469,
                -18.974705758496242
              ],
              [
                -41.05031172468885,
                -18.97376876974007
              ],
              [
                -41.050954215503964,
                -18.965730313485516
              ],
              [
                -41.061313654132434,
                -18.966109843861172
              ],
              [
                -41.064379631800186,
                -18.950998901486994
              ],
              [
                -41.06569012106843,
                -18.944540458510474
              ],
              [
                -41.067084627899916,
                -18.943171466317477
              ],
              [
                -41.06785014486692,
                -18.942419956250028
              ],
              [
                -41.069373616801236,
                -18.943122465361633
              ],
              [
                -41.071538062396094,
                -18.944120986097587
              ],
              [
                -41.07821652234864,
                -18.947202973196784
              ],
              [
                -41.07873805628739,
                -18.9395685233645
              ],
              [
                -41.078841526862305,
                -18.938050027348726
              ],
              [
                -41.082591517335565,
                -18.937363014116205
              ],
              [
                -41.096076903977924,
                -18.934892077437038
              ],
              [
                -41.1032733084937,
                -18.93357406401898
              ],
              [
                -41.10321581635697,
                -18.929937122896384
              ],
              [
                -41.109461815464066,
                -18.927662626021608
              ],
              [
                -41.1123587757817,
                -18.922055188422092
              ],
              [
                -41.117769221814264,
                -18.92128466912429
              ],
              [
                -41.115884743275856,
                -18.9161307019628
              ],
              [
                -41.11433675465161,
                -18.911897222191076
              ],
              [
                -41.1200917288907,
                -18.90753529134496
              ],
              [
                -41.11918326039564,
                -18.902963289862093
              ],
              [
                -41.12644470685505,
                -18.894020366605933
              ],
              [
                -41.13077267745721,
                -18.89368234755051
              ],
              [
                -41.14385606612701,
                -18.88450292875244
              ],
              [
                -41.14530058377168,
                -18.88477244819665
              ],
              [
                -41.14861105116692,
                -18.885389459510993
              ],
              [
                -41.15255549470517,
                -18.886123442468257
              ],
              [
                -41.15790000618875,
                -18.867102563432205
              ],
              [
                -41.15880649459273,
                -18.863874573962942
              ],
              [
                -41.16238594905905,
                -18.860810591095344
              ],
              [
                -41.16596646151841,
                -18.8577461452323
              ],
              [
                -41.188034743892175,
                -18.863484128913868
              ],
              [
                -41.19319170316262,
                -18.863174661413783
              ],
              [
                -41.19913211463551,
                -18.86238468631654
              ],
              [
                -41.208023551234284,
                -18.854563743942304
              ],
              [
                -41.210417028680446,
                -18.863056682887336
              ],
              [
                -41.211923498156544,
                -18.868399141184224
              ],
              [
                -41.209368525931254,
                -18.873948627846822
              ],
              [
                -41.214383481400596,
                -18.877152136791967
              ],
              [
                -41.22970239268539,
                -18.85788426182379
              ],
              [
                -41.23598031666175,
                -18.856027267589713
              ],
              [
                -41.23895480157441,
                -18.858161764457297
              ],
              [
                -41.24197178167361,
                -18.85394881003789
              ],
              [
                -41.2409567822528,
                -18.851052314641734
              ],
              [
                -41.24013331689352,
                -18.84870134349732
              ],
              [
                -41.23932132235908,
                -18.846382339193486
              ],
              [
                -41.24076279629454,
                -18.84523637080257
              ],
              [
                -41.24347428936409,
                -18.843080368879843
              ],
              [
                -41.241638285643724,
                -18.840411884467358
              ],
              [
                -41.23741935083472,
                -18.834280419100274
              ],
              [
                -41.245076314749525,
                -18.821474519937173
              ],
              [
                -41.23214248404431,
                -18.79679362569517
              ],
              [
                -41.151355182620925,
                -18.796339452062295
              ],
              [
                -41.147701248868124,
                -18.796318952623288
              ],
              [
                -41.133727829764254,
                -18.79555541214806
              ],
              [
                -41.13256383665298,
                -18.79878891701955
              ],
              [
                -41.12962434494894,
                -18.806952372679323
              ],
              [
                -41.1207984397552,
                -18.809145837725076
              ],
              [
                -41.118803472213074,
                -18.809641826727248
              ],
              [
                -41.11898595062181,
                -18.811387831996203
              ],
              [
                -41.11959041808985,
                -18.81717477374679
              ],
              [
                -41.11010346317167,
                -18.838451130974374
              ],
              [
                -41.09707357339082,
                -18.841825110467543
              ],
              [
                -41.089249165847114,
                -18.838570109107838
              ],
              [
                -41.08330571592553,
                -18.836097609119932
              ],
              [
                -41.02502871589519,
                -18.83669948511188
              ],
              [
                -40.98749553579075,
                -18.838708914920772
              ],
              [
                -40.97117618390718,
                -18.840052885428758
              ],
              [
                -40.963512261190345,
                -18.840683860947113
              ],
              [
                -40.962918739568856,
                -18.837111393448584
              ],
              [
                -40.96267776900352,
                -18.835659411375868
              ],
              [
                -40.95690933254656,
                -18.8349703874709
              ],
              [
                -40.950674358344706,
                -18.83422486693378
              ],
              [
                -40.94770891925108,
                -18.82908288566275
              ],
              [
                -40.94532593371107,
                -18.82494991474194
              ],
              [
                -40.944232957832526,
                -18.82562642756093
              ],
              [
                -40.936797981080865,
                -18.830229380507884
              ],
              [
                -40.92750859621829,
                -18.819220939288872
              ],
              [
                -40.91654669777648,
                -18.81497194139331
              ],
              [
                -40.92069570276933,
                -18.79881653181575
              ],
              [
                -40.928232146592464,
                -18.79245258431151
              ],
              [
                -40.9262086722694,
                -18.78769361281138
              ],
              [
                -40.93748612306918,
                -18.77204919995408
              ],
              [
                -40.940003156847304,
                -18.73536990948214
              ],
              [
                -40.94233120472245,
                -18.700636640467547
              ],
              [
                -40.94323921724194,
                -18.687093714217806
              ],
              [
                -40.94744218571988,
                -18.68372473044977
              ],
              [
                -40.95164468520278,
                -18.680356252677342
              ],
              [
                -40.96703303689524,
                -18.678779798361997
              ],
              [
                -40.98762738587423,
                -18.676669854821515
              ],
              [
                -40.98834584990615,
                -18.673399855334885
              ],
              [
                -40.988495382120526,
                -18.67271939260376
              ],
              [
                -40.99804780364308,
                -18.669251933356716
              ],
              [
                -41.00482524538829,
                -18.666792461556852
              ],
              [
                -41.014993666529975,
                -18.658430528067694
              ],
              [
                -41.019812650952005,
                -18.65446802900696
              ],
              [
                -41.02179763030694,
                -18.654085054300726
              ],
              [
                -41.03182151725362,
                -18.65215358806973
              ],
              [
                -41.030060579387346,
                -18.645671602206455
              ],
              [
                -41.02995753914859,
                -18.645293120199497
              ],
              [
                -41.030249053460686,
                -18.645345110493444
              ],
              [
                -41.04401692553378,
                -18.647796111409075
              ],
              [
                -41.0411574739576,
                -18.639268664203076
              ],
              [
                -41.050488921299,
                -18.63366872090791
              ],
              [
                -41.05270588998136,
                -18.628238742063214
              ],
              [
                -41.04374246352675,
                -18.62578325302755
              ],
              [
                -41.04203147675394,
                -18.623497737841085
              ],
              [
                -41.038993049672776,
                -18.619438765274122
              ],
              [
                -41.03257261208386,
                -18.6177472720049
              ],
              [
                -41.03325110740304,
                -18.60693682839163
              ],
              [
                -41.04158705244841,
                -18.604919389179503
              ],
              [
                -41.02627284469567,
                -18.527617795967
              ],
              [
                -41.01538702678065,
                -18.476912560235967
              ],
              [
                -41.0145050457093,
                -18.472801578495748
              ],
              [
                -41.01947553364975,
                -18.463937630432056
              ],
              [
                -41.02353851398087,
                -18.45669171106463
              ],
              [
                -41.173944212897716,
                -18.443413039892345
              ],
              [
                -41.17515269451214,
                -18.443306044972726
              ],
              [
                -41.17529367950143,
                -18.443203533857623
              ],
              [
                -41.17904115664423,
                -18.44047508839994
              ],
              [
                -41.18162413313564,
                -18.43859360163161
              ],
              [
                -41.16626981628496,
                -18.414733691800166
              ],
              [
                -41.15580592698333,
                -18.413518699674842
              ],
              [
                -41.15337195501338,
                -18.41323566639279
              ],
              [
                -41.14509403102406,
                -18.406392714608188
              ],
              [
                -41.14433552908818,
                -18.405765698736516
              ],
              [
                -41.15005399660095,
                -18.404509738669027
              ],
              [
                -41.16004739262449,
                -18.402314775755887
              ],
              [
                -41.16214190697165,
                -18.390280833348957
              ],
              [
                -41.159196959532416,
                -18.380976880788882
              ],
              [
                -41.15236052167608,
                -18.379604382975536
              ],
              [
                -41.14770206970727,
                -18.378668376021274
              ],
              [
                -41.145484589939045,
                -18.373381387464608
              ],
              [
                -41.148608585449864,
                -18.360322969196893
              ],
              [
                -41.14975815053193,
                -18.335872612656484
              ],
              [
                -41.15201062256466,
                -18.33504664904225
              ],
              [
                -41.154262574600416,
                -18.334221659421136
              ],
              [
                -41.15843811732025,
                -18.308275314825284
              ],
              [
                -41.150508172130664,
                -18.302334332647707
              ],
              [
                -41.14602774491836,
                -18.298977333292363
              ],
              [
                -41.139707320930796,
                -18.289706886907673
              ],
              [
                -41.13888529881327,
                -18.288502364315157
              ],
              [
                -41.1270969280236,
                -18.276438930341804
              ],
              [
                -41.096072758727985,
                -18.25509698325369
              ],
              [
                -41.09900825458584,
                -18.2470675423124
              ],
              [
                -41.100399752726055,
                -18.243260566470074
              ],
              [
                -41.09865576224876,
                -18.238624103209343
              ],
              [
                -41.1023422315495,
                -18.236484112181138
              ],
              [
                -41.0988407914265,
                -18.231887620164468
              ],
              [
                -41.09627631779701,
                -18.231510116238333
              ],
              [
                -41.09159783543294,
                -18.230822120897283
              ],
              [
                -41.09132534398728,
                -18.227562647509007
              ],
              [
                -41.09622431100943,
                -18.22342619564676
              ],
              [
                -41.10179527837501,
                -18.21872172847007
              ],
              [
                -41.10404826558057,
                -18.21279677681609
              ],
              [
                -41.09613334386074,
                -18.209278264641203
              ],
              [
                -41.093714379478946,
                -18.208203285115925
              ],
              [
                -41.09305141027209,
                -18.201834799241198
              ],
              [
                -41.092348416257956,
                -18.19507982056677
              ],
              [
                -41.06648066730328,
                -18.180615360938866
              ],
              [
                -41.06488019394947,
                -18.180351868290508
              ],
              [
                -41.060636218788694,
                -18.17965285792241
              ],
              [
                -41.05605327890693,
                -18.17836087737027
              ],
              [
                -41.0538853036879,
                -18.173916406213035
              ],
              [
                -41.0530428018691,
                -18.172190400695907
              ],
              [
                -41.055643270266906,
                -18.166124431621427
              ],
              [
                -41.0349374696468,
                -18.162314445729947
              ],
              [
                -41.03698195244099,
                -18.168369400129848
              ],
              [
                -41.0377374369059,
                -18.17060787944888
              ],
              [
                -41.0334134506997,
                -18.17162887479873
              ],
              [
                -41.031400981520044,
                -18.16653840177775
              ],
              [
                -41.027707053413415,
                -18.157192930489405
              ],
              [
                -41.01418866451148,
                -18.15709944407557
              ],
              [
                -41.01240368663509,
                -18.16148738264311
              ],
              [
                -41.010194194214115,
                -18.166918362215732
              ],
              [
                -40.99281533465494,
                -18.165751834427123
              ],
              [
                -40.989075352921276,
                -18.17016928791679
              ],
              [
                -40.987645393035386,
                -18.156175867602773
              ],
              [
                -40.9866359144591,
                -18.146294928890434
              ],
              [
                -40.97769450547068,
                -18.141550466084126
              ],
              [
                -40.96711862160475,
                -18.144733404239062
              ],
              [
                -40.96551812685134,
                -18.147413884229273
              ],
              [
                -40.961771649149995,
                -18.15369033072038
              ],
              [
                -40.95413768763943,
                -18.154014322605146
              ],
              [
                -40.9484587807922,
                -18.145216364244845
              ],
              [
                -40.951152729138954,
                -18.141973411756226
              ],
              [
                -40.95110523943098,
                -18.14157192103264
              ],
              [
                -40.94960426942385,
                -18.128804961453707
              ],
              [
                -40.94451935737429,
                -18.129176470148437
              ],
              [
                -40.94011237839334,
                -18.135968913257987
              ],
              [
                -40.93963884255887,
                -18.135970397307467
              ],
              [
                -40.93180995141706,
                -18.136000919558935
              ],
              [
                -40.92474600582607,
                -18.128544435578572
              ],
              [
                -40.916899108918464,
                -18.12026097295389
              ],
              [
                -40.90888266834346,
                -18.114350464985645
              ],
              [
                -40.90414672366222,
                -18.115449993082226
              ],
              [
                -40.90295773013118,
                -18.11038300069172
              ],
              [
                -40.89877175189425,
                -18.112272976205823
              ],
              [
                -40.892710311852824,
                -18.107399497011023
              ],
              [
                -40.89349228961523,
                -18.11815994790676
              ],
              [
                -40.8913048098127,
                -18.12049541975108
              ],
              [
                -40.88860832996552,
                -18.12353488142106
              ],
              [
                -40.8853008599934,
                -18.12358437256298
              ],
              [
                -40.880291889956695,
                -18.12365938617726
              ],
              [
                -40.87715396482734,
                -18.118947887823577
              ],
              [
                -40.86602750709653,
                -18.129435331697334
              ],
              [
                -40.86383403936992,
                -18.135908293050733
              ],
              [
                -40.862904531606745,
                -18.13864975198877
              ],
              [
                -40.8556465810828,
                -18.13933472352711
              ],
              [
                -40.855627604245704,
                -18.139336742477457
              ],
              [
                -40.85561958629575,
                -18.13934622640547
              ],
              [
                -40.84946211803654,
                -18.146956190162534
              ],
              [
                -40.84032770266378,
                -18.14736714759428
              ],
              [
                -40.83210479897642,
                -18.1506731327091
              ],
              [
                -40.824595830843315,
                -18.14898814877331
              ],
              [
                -40.82378837035732,
                -18.14880662024484
              ],
              [
                -40.80481551168038,
                -18.15248806880368
              ],
              [
                -40.799580560170256,
                -18.156027056467458
              ],
              [
                -40.79434559266261,
                -18.15956651712758
              ],
              [
                -40.771098821718894,
                -18.155441000436678
              ],
              [
                -40.772902379582,
                -18.107080286584605
              ],
              [
                -40.90219599353945,
                -17.98699372215438
              ],
              [
                -40.900731481727746,
                -17.985460713363437
              ],
              [
                -40.89957702148868,
                -17.984252252258685
              ],
              [
                -40.8967055472273,
                -17.981246752435236
              ],
              [
                -40.89077109364688,
                -17.982552746991534
              ],
              [
                -40.882316687903455,
                -17.97007029544146
              ],
              [
                -40.8777122076228,
                -17.980679706385043
              ],
              [
                -40.87746621356879,
                -17.981246712534446
              ],
              [
                -40.87252225108625,
                -17.9803192143044
              ],
              [
                -40.8699022417633,
                -17.985614673705243
              ],
              [
                -40.86272581019905,
                -17.987822653461702
              ],
              [
                -40.85914337084488,
                -17.981264687347743
              ],
              [
                -40.844352049771466,
                -17.965121733142546
              ],
              [
                -40.836641125737096,
                -17.95670577300509
              ],
              [
                -40.830154660300025,
                -17.95370430310442
              ],
              [
                -40.82821318830541,
                -17.95701178162563
              ],
              [
                -40.824571187072294,
                -17.963216715580334
              ],
              [
                -40.81376282254731,
                -17.958430217803976
              ],
              [
                -40.80838037815441,
                -17.952277253828075
              ],
              [
                -40.80222489909033,
                -17.958012701627133
              ],
              [
                -40.79806843902258,
                -17.956468236652793
              ],
              [
                -40.79147796093215,
                -17.971002108263658
              ],
              [
                -40.79460595035455,
                -17.98126006141428
              ],
              [
                -40.78313206688845,
                -17.975475592262228
              ],
              [
                -40.78172357082562,
                -17.974765585717908
              ],
              [
                -40.7773481103012,
                -17.976206052554808
              ],
              [
                -40.77732706443135,
                -17.981825007094727
              ],
              [
                -40.77080064162747,
                -17.98058251566113
              ],
              [
                -40.77116610468066,
                -17.987910484796917
              ],
              [
                -40.77186109866835,
                -18.001827384416345
              ],
              [
                -40.75991219046535,
                -18.003482868073828
              ],
              [
                -40.750372276818055,
                -18.009429319955654
              ],
              [
                -40.745288332152676,
                -18.00593884871418
              ],
              [
                -40.74368435666464,
                -18.004837334111528
              ],
              [
                -40.739921893659194,
                -18.003994333728983
              ],
              [
                -40.72536900102335,
                -18.00073532351701
              ],
              [
                -40.715765579453986,
                -18.0041338124285
              ],
              [
                -40.71294357893735,
                -18.010850264920872
              ],
              [
                -40.71100211514611,
                -18.01547170161045
              ],
              [
                -40.70415014471946,
                -18.022871652118596
              ],
              [
                -40.68730131686874,
                -18.011951218990973
              ],
              [
                -40.683825339384825,
                -18.00969768157862
              ],
              [
                -40.67831389837562,
                -18.012336165048755
              ],
              [
                -40.67742989727076,
                -18.012759676791596
              ],
              [
                -40.67668391261448,
                -18.012406194430998
              ],
              [
                -40.672784946990866,
                -18.01056167775681
              ],
              [
                -40.666075011598764,
                -18.011788639278105
              ],
              [
                -40.661421522860856,
                -18.01263965407545
              ],
              [
                -40.651095623447915,
                -18.00638465506474
              ],
              [
                -40.6483401751516,
                -17.99967668162226
              ],
              [
                -40.64563022320644,
                -17.993080743612076
              ],
              [
                -40.635585803436776,
                -17.990965227534396
              ],
              [
                -40.62554089068038,
                -17.988849720465428
              ],
              [
                -40.623891927923744,
                -17.97543780621319
              ],
              [
                -40.61387353540244,
                -17.967016809843777
              ],
              [
                -40.61978149670995,
                -17.96163687049884
              ],
              [
                -40.62148550597543,
                -17.96008438309461
              ],
              [
                -40.6122135804605,
                -17.94902743795649
              ],
              [
                -40.6148985631033,
                -17.94323398078682
              ],
              [
                -40.61304758594785,
                -17.93924847796552
              ],
              [
                -40.59679474337595,
                -17.935552480302636
              ],
              [
                -40.59403778798025,
                -17.936373459017506
              ],
              [
                -40.586762351636374,
                -17.938539969875553
              ],
              [
                -40.584013345865856,
                -17.93528046498239
              ],
              [
                -40.58413839249009,
                -17.92789352036161
              ],
              [
                -40.584311881683924,
                -17.917684073711925
              ],
              [
                -40.56753055248285,
                -17.91554907276706
              ],
              [
                -40.547036754139086,
                -17.906815069087056
              ],
              [
                -40.526640475991286,
                -17.8914636112777
              ],
              [
                -40.52240049228897,
                -17.892492128877148
              ],
              [
                -40.518161026583414,
                -17.893521119474766
              ],
              [
                -40.515463538166514,
                -17.896474589547683
              ],
              [
                -40.50293164085102,
                -17.910199498010385
              ],
              [
                -40.49333070291562,
                -17.909181464433615
              ],
              [
                -40.48373079798072,
                -17.90816343286521
              ],
              [
                -40.47622886773343,
                -17.91551991522527
              ],
              [
                -40.46378692191736,
                -17.927720306970564
              ],
              [
                -40.459922999174104,
                -17.92156284433097
              ],
              [
                -40.45892298640336,
                -17.9199693419309
              ],
              [
                -40.45347156311979,
                -17.918329367157504
              ],
              [
                -40.4321787362199,
                -17.92085028467548
              ],
              [
                -40.42459830727915,
                -17.92370027386882
              ],
              [
                -40.417017327350955,
                -17.926550229060595
              ],
              [
                -40.40399047486943,
                -17.925052206735046
              ],
              [
                -40.391549064079385,
                -17.923620700159322
              ],
              [
                -40.38843561945978,
                -17.92607217841541
              ],
              [
                -40.38602110581715,
                -17.927973145329247
              ],
              [
                -40.378912680699926,
                -17.92753916413121
              ],
              [
                -40.37274724411071,
                -17.92716365941566
              ],
              [
                -40.372026245413906,
                -17.926731161610263
              ],
              [
                -40.364902801558515,
                -17.922463168388674
              ],
              [
                -40.359342371705495,
                -17.92676913576894
              ],
              [
                -40.35520986954524,
                -17.92996911555386
              ],
              [
                -40.35040791481374,
                -17.926796121273693
              ],
              [
                -40.345553485620044,
                -17.923588619104606
              ],
              [
                -40.33355607297988,
                -17.926880102086145
              ],
              [
                -40.32445315814402,
                -17.935631528910623
              ],
              [
                -40.32140065699259,
                -17.938565493382157
              ],
              [
                -40.31706822582852,
                -17.93883598235535
              ],
              [
                -40.31186523226887,
                -17.939161492310518
              ],
              [
                -40.309555749532564,
                -17.942096466215446
              ],
              [
                -40.305793289770286,
                -17.946877928219784
              ],
              [
                -40.29988735153387,
                -17.9466929008742
              ],
              [
                -40.29070692155931,
                -17.946405373795656
              ],
              [
                -40.29059543404315,
                -17.951367342793
              ],
              [
                -40.28912192693026,
                -17.952397843749175
              ],
              [
                -40.27527355826926,
                -17.962079264770786
              ],
              [
                -40.27128256916154,
                -17.961850271402866
              ],
              [
                -40.26590159655769,
                -17.961541275820128
              ],
              [
                -40.258904685408204,
                -17.967790218731583
              ],
              [
                -40.254954702988464,
                -17.971317666891668
              ],
              [
                -40.244430303551745,
                -17.974075136918778
              ],
              [
                -40.22874290109892,
                -17.978185081809883
              ],
              [
                -40.22258395836137,
                -17.979798568174534
              ],
              [
                -40.2220174780753,
                -17.97994758218094
              ],
              [
                -39.919896269060175,
                -18.172590407059023
              ],
              [
                -39.911767826119316,
                -18.17844833904563
              ],
              [
                -39.8276209545705,
                -18.23193689483236
              ],
              [
                -39.68853953408689,
                -18.32034462447007
              ],
              [
                -39.67666363971439,
                -18.323502084334063
              ],
              [
                -39.675954652662696,
                -18.323690593818466
              ],
              [
                -39.67382115656238,
                -18.32425806024744
              ],
              [
                -39.666460705268435,
                -18.33185303123857
              ],
              [
                -39.670192140698134,
                -18.348743440010594
              ],
              [
                -39.670356157320875,
                -18.352436895140094
              ],
              [
                -39.675869554706246,
                -18.364206341213382
              ],
              [
                -39.681147498260195,
                -18.37547178688709
              ],
              [
                -39.68884941696554,
                -18.391910706292762
              ],
              [
                -39.69243685537081,
                -18.39956815837645
              ],
              [
                -39.69376282932815,
                -18.40243515777612
              ],
              [
                -39.69970524545445,
                -18.41528307837356
              ],
              [
                -39.709836128487154,
                -18.44879691541884
              ],
              [
                -39.713283551989385,
                -18.460201822893616
              ],
              [
                -39.71456554334868,
                -18.46444180102832
              ],
              [
                -39.715660035851684,
                -18.468002799862518
              ],
              [
                -39.72433540325659,
                -18.49014367144122
              ],
              [
                -39.725781382311624,
                -18.493835655204737
              ],
              [
                -39.72609086782785,
                -18.49462614008624
              ],
              [
                -39.72815584891541,
                -18.499897125630252
              ],
              [
                -39.72805978039488,
                -18.531456427784995
              ],
              [
                -39.72799873350221,
                -18.5514718030922
              ],
              [
                -39.72919322320587,
                -18.552663804332965
              ],
              [
                -39.731692191389875,
                -18.555156802382722
              ],
              [
                -39.72909474773843,
                -18.557888774935584
              ],
              [
                -39.728284257152836,
                -18.55874077424615
              ],
              [
                -39.72827920975652,
                -18.56768621982486
              ],
              [
                -39.72826868497033,
                -18.58556062111526
              ],
              [
                -39.72621816535389,
                -18.59245208193472
              ],
              [
                -39.72416716773824,
                -18.599343030760497
              ],
              [
                -39.72992010397279,
                -18.620985900082584
              ],
              [
                -39.73312856627177,
                -18.632408836353413
              ],
              [
                -39.73633700855019,
                -18.643831770651538
              ],
              [
                -39.74547728256229,
                -18.69911147771962
              ],
              [
                -39.746019782498664,
                -18.702392959279656
              ],
              [
                -39.746562259433524,
                -18.70567445784157
              ],
              [
                -39.74736371011292,
                -18.738060259892656
              ],
              [
                -39.74746518117824,
                -18.742164224706737
              ],
              [
                -39.747566682239714,
                -18.746268716520248
              ],
              [
                -39.748469135734446,
                -18.782778511492317
              ],
              [
                -39.74851862690345,
                -18.78476798378167
              ],
              [
                -39.74856761307745,
                -18.786756951073542
              ],
              [
                -39.749915001628736,
                -18.841233136645386
              ],
              [
                -39.74953497584623,
                -18.84493512594054
              ],
              [
                -39.749518995638795,
                -18.84509364096897
              ],
              [
                -39.7491719812601,
                -18.84847859421104
              ],
              [
                -39.74659643870742,
                -18.87360545416952
              ],
              [
                -39.745334950724065,
                -18.885916889723973
              ],
              [
                -39.74407343672162,
                -18.89822829729786
              ],
              [
                -39.7389683708009,
                -18.948034011356672
              ],
              [
                -39.73859737817309,
                -18.951607488480356
              ],
              [
                -39.737013360866754,
                -18.963065936548276
              ],
              [
                -39.73605085999227,
                -18.970026874464832
              ],
              [
                -39.73293332367733,
                -18.992576740937672
              ],
              [
                -39.7320928366572,
                -18.998653179349482
              ],
              [
                -39.731737838108636,
                -19.001221697462768
              ],
              [
                -39.73135384035519,
                -19.003998150290368
              ],
              [
                -39.730570346738574,
                -19.009661146266936
              ],
              [
                -39.721384281506175,
                -19.07609522969904
              ],
              [
                -39.71857124411866,
                -19.096437617087993
              ],
              [
                -39.71638473200165,
                -19.11413199939793
              ],
              [
                -39.71487522709104,
                -19.12634744138563
              ],
              [
                -39.71263570739633,
                -19.144480317072308
              ],
              [
                -39.71122420234023,
                -19.155905229957565
              ],
              [
                -39.709813687258745,
                -19.167330174860865
              ],
              [
                -39.70322764853301,
                -19.210260406985956
              ],
              [
                -39.70117465875853,
                -19.223628811233237
              ],
              [
                -39.70116466469224,
                -19.22369783780725
              ],
              [
                -39.700462664661565,
                -19.22826879351708
              ],
              [
                -39.699834152057484,
                -19.232358268208046
              ],
              [
                -39.68945110009046,
                -19.299974879046573
              ],
              [
                -39.688552577871285,
                -19.305827840876102
              ],
              [
                -39.689404568474664,
                -19.31273630411157
              ],
              [
                -39.693327983605194,
                -19.344564629621672
              ],
              [
                -39.69486944417194,
                -19.357074046517532
              ],
              [
                -39.69619290221543,
                -19.367159967180772
              ],
              [
                -39.69759840340296,
                -19.371601459070718
              ],
              [
                -39.71326766320309,
                -19.41834971289813
              ],
              [
                -39.714655628199,
                -19.422491209555425
              ],
              [
                -39.71525364171735,
                -19.42427567636046
              ],
              [
                -39.715740132003944,
                -19.425727175881903
              ],
              [
                -39.71735108845713,
                -19.43053465011501
              ],
              [
                -39.723464012495086,
                -19.448771586213415
              ],
              [
                -39.72680445648225,
                -19.45873901492151
              ],
              [
                -39.72791194790749,
                -19.462041510933364
              ],
              [
                -39.729039429012914,
                -19.465403968639063
              ],
              [
                -39.74429722751431,
                -19.4997003191753
              ],
              [
                -39.747545155401966,
                -19.50700125135076
              ],
              [
                -39.750793104278046,
                -19.514303244533437
              ],
              [
                -39.764425448599546,
                -19.54494607137603
              ],
              [
                -39.7677133828612,
                -19.552337564190193
              ],
              [
                -39.76771787479758,
                -19.55234754414147
              ],
              [
                -39.771002329103375,
                -19.559729514017487
              ],
              [
                -39.79580450473188,
                -19.608361283980262
              ],
              [
                -39.80396589234216,
                -19.626347682577517
              ],
              [
                -39.80626637978551,
                -19.630997162395815
              ],
              [
                -39.80650937270521,
                -19.63653362677157
              ],
              [
                -39.80700035536129,
                -19.64766009223737
              ],
              [
                -39.81641925579895,
                -19.654378529971567
              ],
              [
                -39.819951201567164,
                -19.653691047349984
              ],
              [
                -39.82529817770582,
                -19.65312058584877
              ],
              [
                -39.84790695249487,
                -19.660181069258424
              ],
              [
                -39.87337168550307,
                -19.668135089546748
              ],
              [
                -39.87622818252868,
                -19.66952308949842
              ],
              [
                -39.88734407377464,
                -19.674926067532255
              ],
              [
                -39.88735104770365,
                -19.674929578526548
              ],
              [
                -39.88875304848141,
                -19.67561157852025
              ],
              [
                -39.89967843966311,
                -19.68092207371505
              ],
              [
                -39.91123532544507,
                -19.686538560488184
              ],
              [
                -39.930134123835174,
                -19.6986805219896
              ],
              [
                -39.94107053291464,
                -19.70697949902556
              ],
              [
                -39.94418350334656,
                -19.70934246692191
              ],
              [
                -39.9527648848092,
                -19.715855968356525
              ],
              [
                -39.97454113996595,
                -19.732385397799536
              ],
              [
                -39.98306607302522,
                -19.738855388442047
              ],
              [
                -39.989583000973006,
                -19.744975878883505
              ],
              [
                -40.00091888288291,
                -19.755625313403264
              ],
              [
                -40.01943216029528,
                -19.77301626930284
              ],
              [
                -40.02431711351374,
                -19.77760523820127
              ],
              [
                -40.02635809150842,
                -19.77999523375415
              ],
              [
                -40.03294353403975,
                -19.78770968226954
              ],
              [
                -40.045336390234425,
                -19.80222515997183
              ],
              [
                -40.05191429582924,
                -19.809930110587818
              ],
              [
                -40.05622225106563,
                -19.81922555326343
              ],
              [
                -40.05660973434844,
                -19.82227803253319
              ],
              [
                -40.05710421886414,
                -19.82820102252837
              ],
              [
                -40.05735572137498,
                -19.831671488111372
              ],
              [
                -40.0574507004207,
                -19.832978987797038
              ],
              [
                -40.05967765087947,
                -19.840782952672065
              ],
              [
                -40.06196913641167,
                -19.848728414884217
              ],
              [
                -40.06305014079334,
                -19.852474883657557
              ],
              [
                -40.06549010017346,
                -19.855704870289408
              ],
              [
                -40.070610507433805,
                -19.86230433027406
              ],
              [
                -40.0753699670406,
                -19.86843829817793
              ],
              [
                -40.07915493608388,
                -19.873446802480476
              ],
              [
                -40.08345489914281,
                -19.879021791636905
              ],
              [
                -40.08577885248456,
                -19.883120750064403
              ],
              [
                -40.086246844369924,
                -19.88728222418454
              ],
              [
                -40.08686882861234,
                -19.89281069579412
              ],
              [
                -40.08911679346737,
                -19.893198188361275
              ],
              [
                -40.090680264762355,
                -19.89346721814971
              ],
              [
                -40.090591282507994,
                -19.896429198971315
              ],
              [
                -40.09053075425017,
                -19.898445178280202
              ],
              [
                -40.092857758280125,
                -19.90139868030296
              ],
              [
                -40.09292223850475,
                -19.9014806749702
              ],
              [
                -40.09716222437282,
                -19.904414142190035
              ],
              [
                -40.09809266682713,
                -19.912876598660624
              ],
              [
                -40.098704151060886,
                -19.918438085088518
              ],
              [
                -40.10151916108626,
                -19.920387560923555
              ],
              [
                -40.10433461510611,
                -19.92233755475864
              ],
              [
                -40.116024461664274,
                -19.930417524430588
              ],
              [
                -40.11987793392907,
                -19.931705022288256
              ],
              [
                -40.12560987024414,
                -19.93427154683764
              ],
              [
                -40.13100134372783,
                -19.936375546313023
              ],
              [
                -40.13475130998346,
                -19.941822004150403
              ],
              [
                -40.139262235041684,
                -19.94866846761288
              ],
              [
                -40.13926870560346,
                -19.95551393145659
              ],
              [
                -40.13515573833702,
                -19.96063491235614
              ],
              [
                -40.132343248249924,
                -19.964136902301757
              ],
              [
                -40.129679292188655,
                -19.967487852429535
              ],
              [
                -40.13576070320523,
                -19.97011084044441
              ],
              [
                -40.13710572219648,
                -19.972578351211578
              ],
              [
                -40.13775720447133,
                -19.973774326766833
              ],
              [
                -40.13910216653668,
                -19.97746231755718
              ],
              [
                -40.141807639476255,
                -19.984856799074933
              ],
              [
                -40.14643357774484,
                -19.989494782477326
              ],
              [
                -40.14698503758543,
                -19.998330217164863
              ],
              [
                -40.14722453423576,
                -20.002161182788225
              ],
              [
                -40.1474170374661,
                -20.006342185313365
              ],
              [
                -40.151440015703045,
                -20.010745646790436
              ],
              [
                -40.15257748853355,
                -20.011968634246045
              ],
              [
                -40.15363347130822,
                -20.013103637029865
              ],
              [
                -40.15418044072186,
                -20.015878111358337
              ],
              [
                -40.15598693944829,
                -20.025040583917754
              ],
              [
                -40.15706341865018,
                -20.0304940450965
              ],
              [
                -40.158501864169416,
                -20.037785035571314
              ],
              [
                -40.163314810529016,
                -20.03875951135473
              ],
              [
                -40.18157015211184,
                -20.04134404085983
              ],
              [
                -40.18849556676756,
                -20.052991471333016
              ],
              [
                -40.190304552163525,
                -20.05603446587695
              ],
              [
                -40.18410760367443,
                -20.062347916542354
              ],
              [
                -40.172547165255615,
                -20.07412583250739
              ],
              [
                -40.171864150645305,
                -20.088111758325226
              ],
              [
                -40.171657139305424,
                -20.092750725446237
              ],
              [
                -40.17148064358184,
                -20.097019698743896
              ],
              [
                -40.17130460188653,
                -20.101274194127623
              ],
              [
                -40.17108710078352,
                -20.109160131741472
              ],
              [
                -40.173798067015,
                -20.119652099846235
              ],
              [
                -40.17998493595475,
                -20.140876984405903
              ],
              [
                -40.181973909517986,
                -20.147701444880393
              ],
              [
                -40.18396338606993,
                -20.15452541836863
              ],
              [
                -40.18406485931837,
                -20.16064338079722
              ],
              [
                -40.18417935962636,
                -20.16751032300168
              ],
              [
                -40.184286338954884,
                -20.174393812103002
              ],
              [
                -40.18735432048512,
                -20.179276287994607
              ],
              [
                -40.1879547897586,
                -20.180232278861915
              ],
              [
                -40.19005076465748,
                -20.183622759134312
              ],
              [
                -40.19206021008831,
                -20.1973981572296
              ],
              [
                -40.192575712859814,
                -20.200929648289545
              ],
              [
                -40.19497868348368,
                -20.2086351207432
              ],
              [
                -40.19738212909196,
                -20.216341063208255
              ],
              [
                -40.213166428054684,
                -20.239713999860957
              ],
              [
                -40.217962369518766,
                -20.24519347521051
              ],
              [
                -40.223105333970935,
                -20.25103495427359
              ],
              [
                -40.22506683087554,
                -20.25326343390701
              ],
              [
                -40.229384251041786,
                -20.258166896507202
              ],
              [
                -40.23312769615159,
                -20.267251379179672
              ],
              [
                -40.23845913053555,
                -20.2801863115254
              ],
              [
                -40.23722562790892,
                -20.28906573456212
              ],
              [
                -40.240473094261226,
                -20.290461760735592
              ],
              [
                -40.250066497818544,
                -20.294585766304415
              ],
              [
                -40.25353298038491,
                -20.288965802667995
              ],
              [
                -40.25134400245683,
                -20.282277811754224
              ],
              [
                -40.250438031385094,
                -20.279509323453535
              ],
              [
                -40.258315474196145,
                -20.266553915967453
              ],
              [
                -40.26257895210425,
                -20.26726241524104
              ],
              [
                -40.270535377287956,
                -20.268585448082124
              ],
              [
                -40.27615333621298,
                -20.27166542888851
              ],
              [
                -40.282108757577724,
                -20.274959908223174
              ],
              [
                -40.285524217228165,
                -20.276849930973952
              ],
              [
                -40.28714569298594,
                -20.279568395128127
              ],
              [
                -40.2896561780957,
                -20.283776875794363
              ],
              [
                -40.291960631899734,
                -20.28764087996457
              ],
              [
                -40.28880763402797,
                -20.293447829242226
              ],
              [
                -40.284210179889776,
                -20.294057819760695
              ],
              [
                -40.29110214047686,
                -20.301160289637483
              ],
              [
                -40.28413969028413,
                -20.29945830606439
              ],
              [
                -40.28362668221445,
                -20.299329779671655
              ],
              [
                -40.279260708166575,
                -20.29823929831115
              ],
              [
                -40.27730825891614,
                -20.300611767632613
              ],
              [
                -40.279987234141146,
                -20.30251727170294
              ],
              [
                -40.28286115927102,
                -20.304561764414807
              ],
              [
                -40.291595099805036,
                -20.30533375611779
              ],
              [
                -40.289602590839166,
                -20.308549760585947
              ],
              [
                -40.28340165725325,
                -20.307478752101478
              ],
              [
                -40.27982319681374,
                -20.30692225343578
              ],
              [
                -40.2774117282028,
                -20.310716692716493
              ],
              [
                -40.28366115156533,
                -20.312513719202496
              ],
              [
                -40.28833759395717,
                -20.315991210758988
              ],
              [
                -40.289231098746804,
                -20.322985174183888
              ],
              [
                -40.28332062943005,
                -20.324408649224786
              ],
              [
                -40.27585870599152,
                -20.319757655212022
              ],
              [
                -40.27367173613467,
                -20.324864635567128
              ],
              [
                -40.2687227756289,
                -20.324474607961374
              ],
              [
                -40.269313236973495,
                -20.326243091254327
              ],
              [
                -40.27045825524207,
                -20.32967508135722
              ],
              [
                -40.2729337315012,
                -20.329863608700407
              ],
              [
                -40.27476470268132,
                -20.33000310891281
              ],
              [
                -40.27466221770096,
                -20.331592092708927
              ],
              [
                -40.27452218764321,
                -20.33375707416839
              ],
              [
                -40.2759207032844,
                -20.332850583347337
              ],
              [
                -40.2769676895291,
                -20.332172110469497
              ],
              [
                -40.281704612133076,
                -20.335741569654342
              ],
              [
                -40.28240459500442,
                -20.339334550885766
              ],
              [
                -40.28340157146048,
                -20.34444904417488
              ],
              [
                -40.28477508070335,
                -20.352336495633008
              ],
              [
                -40.29581092460454,
                -20.3603784763819
              ],
              [
                -40.2962729436175,
                -20.36071548349109
              ],
              [
                -40.31529770413808,
                -20.391670812553173
              ],
              [
                -40.32381158041297,
                -20.420464680976814
              ],
              [
                -40.3221165560788,
                -20.423129151244595
              ],
              [
                -40.32209955916868,
                -20.423154670063475
              ],
              [
                -40.32016009353824,
                -20.426019661665
              ],
              [
                -40.32200906062383,
                -20.429365651883394
              ],
              [
                -40.32706448282615,
                -20.438379578241772
              ],
              [
                -40.33659936501133,
                -20.455342017753594
              ],
              [
                -40.34258578462796,
                -20.46603595877972
              ],
              [
                -40.348572696219826,
                -20.476729924839006
              ],
              [
                -40.35085718842288,
                -20.48367387184158
              ],
              [
                -40.35949105149259,
                -20.509920255435393
              ],
              [
                -40.3590340293675,
                -20.513231752830542
              ],
              [
                -40.358344531150145,
                -20.518224226275343
              ],
              [
                -40.36154448497573,
                -20.519178211987676
              ],
              [
                -40.36580744645996,
                -20.520443694301292
              ],
              [
                -40.37617832082018,
                -20.536224138669436
              ],
              [
                -40.37660733017727,
                -20.53693914960702
              ],
              [
                -40.39366162482077,
                -20.565347530074774
              ],
              [
                -40.40011054999123,
                -20.576071465283288
              ],
              [
                -40.40147600021627,
                -20.584664941179977
              ],
              [
                -40.401727513993485,
                -20.586247915873095
              ],
              [
                -40.4024034787277,
                -20.590717875342868
              ],
              [
                -40.40391093544729,
                -20.599793362879787
              ],
              [
                -40.40966786351897,
                -20.607826829699416
              ],
              [
                -40.417151781107854,
                -20.618225782145327
              ],
              [
                -40.42188175105785,
                -20.62479925689411
              ],
              [
                -40.42314470881145,
                -20.627542233363883
              ],
              [
                -40.42558366956775,
                -20.632840708163883
              ],
              [
                -40.42366818183381,
                -20.635220182601504
              ],
              [
                -40.42763816612557,
                -20.63521267747386
              ],
              [
                -40.429715619590866,
                -20.63793716686667
              ],
              [
                -40.43272059737175,
                -20.64199018290155
              ],
              [
                -40.43957155213804,
                -20.63465771712367
              ],
              [
                -40.445460499979674,
                -20.63631972593993
              ],
              [
                -40.44981196581264,
                -20.637521211911288
              ],
              [
                -40.44960844332427,
                -20.6372502359723
              ],
              [
                -40.44511751274231,
                -20.631271233389008
              ],
              [
                -40.44790296891435,
                -20.626797258590365
              ],
              [
                -40.46072236890338,
                -20.623090836823952
              ],
              [
                -40.46488081665362,
                -20.627809318711922
              ],
              [
                -40.469487292009134,
                -20.633467807354812
              ],
              [
                -40.46652329903016,
                -20.63690578755193
              ],
              [
                -40.469800285485725,
                -20.63852527180365
              ],
              [
                -40.466870798174014,
                -20.64236924881137
              ],
              [
                -40.464241321150496,
                -20.645820706679675
              ],
              [
                -40.467852769756526,
                -20.645784234927785
              ],
              [
                -40.4719802421527,
                -20.650348687637504
              ],
              [
                -40.47240672568898,
                -20.654176199220018
              ],
              [
                -40.47683516878245,
                -20.653808193144286
              ],
              [
                -40.47718319272514,
                -20.6537791860819
              ],
              [
                -40.48051764306806,
                -20.65285171969116
              ],
              [
                -40.49024904130944,
                -20.65692071567771
              ],
              [
                -40.494274521447345,
                -20.661552673811027
              ],
              [
                -40.49672595994551,
                -20.665307678332027
              ],
              [
                -40.49367350166209,
                -20.665996138681376
              ],
              [
                -40.49084100983987,
                -20.667304661062534
              ],
              [
                -40.496027447106954,
                -20.671706625081463
              ],
              [
                -40.49566493607321,
                -20.676585119065106
              ],
              [
                -40.501295398216236,
                -20.67627610435733
              ],
              [
                -40.507696325580135,
                -20.684237583264245
              ],
              [
                -40.509350298070615,
                -20.689246561040157
              ],
              [
                -40.51122777983721,
                -20.692555552794357
              ],
              [
                -40.51292175417079,
                -20.696689017547662
              ],
              [
                -40.5199756594575,
                -20.713427436085297
              ],
              [
                -40.522158137515746,
                -20.719485926235162
              ],
              [
                -40.52288960837241,
                -20.72129140982068
              ],
              [
                -40.52360811542729,
                -20.723064390558857
              ],
              [
                -40.522205083778374,
                -20.72633286422389
              ],
              [
                -40.5206046277366,
                -20.730062861878714
              ],
              [
                -40.5264380458075,
                -20.72866637571285
              ],
              [
                -40.525889042450544,
                -20.734315361045535
              ],
              [
                -40.53329399895723,
                -20.735461347255807
              ],
              [
                -40.53264799081309,
                -20.7397878187377
              ],
              [
                -40.53717742122429,
                -20.74486432564929
              ],
              [
                -40.53714243750032,
                -20.738956833431406
              ],
              [
                -40.537141936679525,
                -20.738887844814066
              ],
              [
                -40.54939984909918,
                -20.7406098487082
              ],
              [
                -40.56229717439684,
                -20.755896310646232
              ],
              [
                -40.567763092736755,
                -20.762408295726143
              ],
              [
                -40.57069457977544,
                -20.765799747465053
              ],
              [
                -40.57291303846741,
                -20.77027624057984
              ],
              [
                -40.573594537851946,
                -20.771650255478622
              ],
              [
                -40.576923479178404,
                -20.778243707339566
              ],
              [
                -40.57692998615686,
                -20.78019721550674
              ],
              [
                -40.57694747325471,
                -20.785606157514287
              ],
              [
                -40.576947480932645,
                -20.790453658603592
              ],
              [
                -40.57944691912079,
                -20.794127101833237
              ],
              [
                -40.58267741109969,
                -20.798836091964862
              ],
              [
                -40.58112742218182,
                -20.802316079167536
              ],
              [
                -40.58739983743496,
                -20.806384580707338
              ],
              [
                -40.58945585155105,
                -20.802219613440332
              ],
              [
                -40.592079282109296,
                -20.80255662147374
              ],
              [
                -40.59377678229481,
                -20.80277458508438
              ],
              [
                -40.59684225028373,
                -20.803346087812727
              ],
              [
                -40.601354199585344,
                -20.80418711830279
              ],
              [
                -40.60732667906886,
                -20.805300135574473
              ],
              [
                -40.6146175681909,
                -20.821136546196186
              ],
              [
                -40.614792579648,
                -20.821517033481687
              ],
              [
                -40.619240985025165,
                -20.83117948296322
              ],
              [
                -40.62354695256202,
                -20.834221509819344
              ],
              [
                -40.627279423099075,
                -20.840849447526026
              ],
              [
                -40.63090886267626,
                -20.84116395397273
              ],
              [
                -40.63183985537378,
                -20.83643799324637
              ],
              [
                -40.63328687010128,
                -20.82909802616604
              ],
              [
                -40.63056192165815,
                -20.8231905707496
              ],
              [
                -40.62884593818612,
                -20.819619083671142
              ],
              [
                -40.62852793273044,
                -20.818957591619984
              ],
              [
                -40.63547939427684,
                -20.81228515226238
              ],
              [
                -40.637516389508264,
                -20.811291147361267
              ],
              [
                -40.63859887113085,
                -20.810762149732195
              ],
              [
                -40.64597632103345,
                -20.807159676320783
              ],
              [
                -40.64988126772807,
                -20.805286719499463
              ],
              [
                -40.65384773394757,
                -20.80338421598033
              ],
              [
                -40.65297027166883,
                -20.79952323739895
              ],
              [
                -40.65082679611615,
                -20.796307249393767
              ],
              [
                -40.64868081350862,
                -20.792728254398416
              ],
              [
                -40.6465388473891,
                -20.789335278384556
              ],
              [
                -40.64529135505994,
                -20.786010282010945
              ],
              [
                -40.650454317005796,
                -20.788633779090397
              ],
              [
                -40.653833785278664,
                -20.79584726871705
              ],
              [
                -40.65655374435127,
                -20.79800474588812
              ],
              [
                -40.65884471217625,
                -20.80098623252882
              ],
              [
                -40.656375212386415,
                -20.805290232115414
              ],
              [
                -40.655747732109816,
                -20.808786187331876
              ],
              [
                -40.65549873381373,
                -20.810171685094765
              ],
              [
                -40.659122186666075,
                -20.81079918078747
              ],
              [
                -40.65997918358836,
                -20.810948182894286
              ],
              [
                -40.66563164141621,
                -20.81189020142175
              ],
              [
                -40.66579562556978,
                -20.81478067478267
              ],
              [
                -40.66586962355267,
                -20.816094679672425
              ],
              [
                -40.669584080777156,
                -20.817040692812043
              ],
              [
                -40.67123854584356,
                -20.817461691212984
              ],
              [
                -40.67140406308108,
                -20.817961668817862
              ],
              [
                -40.67240155844118,
                -20.820971161404998
              ],
              [
                -40.6765974889512,
                -20.82167866795601
              ],
              [
                -40.68193547318589,
                -20.823177673704905
              ],
              [
                -40.68474191198357,
                -20.825527174035248
              ],
              [
                -40.690603888494685,
                -20.827817160596638
              ],
              [
                -40.69422732387019,
                -20.8341131539436
              ],
              [
                -40.70152376656378,
                -20.834422660714324
              ],
              [
                -40.71206262507149,
                -20.841411637878043
              ],
              [
                -40.71514511289928,
                -20.84317013212358
              ],
              [
                -40.71993359281392,
                -20.841254153542852
              ],
              [
                -40.7195935735635,
                -20.83902714408697
              ],
              [
                -40.723829024505264,
                -20.841778138453748
              ]
            ],
            [
              [
                -40.526619235696295,
                -20.65517930552484
              ],
              [
                -40.528091684586336,
                -20.668621229865327
              ],
              [
                -40.523255738416275,
                -20.666099233127518
              ],
              [
                -40.51730678075278,
                -20.66496620916489
              ],
              [
                -40.51201431424259,
                -20.667384185868215
              ],
              [
                -40.50785737483248,
                -20.669283670997288
              ],
              [
                -40.50474190777461,
                -20.67050264024572
              ],
              [
                -40.502925907883125,
                -20.666224691051013
              ],
              [
                -40.51453183273098,
                -20.663366722890675
              ],
              [
                -40.52002126074996,
                -20.662029226601014
              ],
              [
                -40.52444673337718,
                -20.66345372753638
              ],
              [
                -40.526619235696295,
                -20.65517930552484
              ]
            ],
            [
              [
                -40.536145066206664,
                -20.685265159104024
              ],
              [
                -40.525336668379566,
                -20.689225112883346
              ],
              [
                -40.51980569482198,
                -20.690527593264548
              ],
              [
                -40.516551749232036,
                -20.685315589037934
              ],
              [
                -40.5208857267019,
                -20.684745606897696
              ],
              [
                -40.53039910775857,
                -20.684513142451568
              ],
              [
                -40.52971113489235,
                -20.677002672766164
              ],
              [
                -40.5354235771154,
                -20.675189704631677
              ],
              [
                -40.540506067520276,
                -20.674361727600974
              ],
              [
                -40.53905707197544,
                -20.677758179437742
              ],
              [
                -40.53200810476461,
                -20.67917465079404
              ],
              [
                -40.53451859115665,
                -20.682361152648408
              ],
              [
                -40.536145066206664,
                -20.685265159104024
              ]
            ]
          ],
          [
            [
              [
                -40.47499217168636,
                -20.660831648834034
              ],
              [
                -40.47240672568898,
                -20.654176199220018
              ],
              [
                -40.46470478414824,
                -20.652117665549888
              ],
              [
                -40.465894250244865,
                -20.655379167990773
              ],
              [
                -40.46751026555241,
                -20.659813146839817
              ],
              [
                -40.47112419939948,
                -20.66046615425082
              ],
              [
                -40.47499217168636,
                -20.660831648834034
              ]
            ]
          ],
          [
            [
              [
                -40.72443701760382,
                -20.846640623949824
              ],
              [
                -40.723829024505264,
                -20.841778138453748
              ],
              [
                -40.718564595281556,
                -20.845483607065134
              ],
              [
                -40.72443701760382,
                -20.846640623949824
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 9,
        "nome": "Goiás",
        "sigla": "GO",
        "regiao_id": 5,
        "codigo_ibg": 52
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -50.15776215684792,
                -12.41195190590751
              ],
              [
                -50.15901112980588,
                -12.429415314009447
              ],
              [
                -50.16499058495154,
                -12.43674580376709
              ],
              [
                -50.179239462690255,
                -12.439449790121248
              ],
              [
                -50.18379240855566,
                -12.454879734190836
              ],
              [
                -50.19614528954393,
                -12.458064199774245
              ],
              [
                -50.204794222828525,
                -12.465432707826995
              ],
              [
                -50.204547221753124,
                -12.475296135216375
              ],
              [
                -50.21557460016029,
                -12.485134608440807
              ],
              [
                -50.2194295345138,
                -12.517179404678854
              ],
              [
                -50.224346491373595,
                -12.524558871617074
              ],
              [
                -50.22926343722653,
                -12.531938350572501
              ],
              [
                -50.222489485066525,
                -12.54315976462602
              ],
              [
                -50.213323545678115,
                -12.54797924644561
              ],
              [
                -50.21231152205037,
                -12.555584203287166
              ],
              [
                -50.21158454300372,
                -12.561046651572639
              ],
              [
                -50.205908588109445,
                -12.559870185680838
              ],
              [
                -50.20562158408329,
                -12.553589221282467
              ],
              [
                -50.19745366214667,
                -12.555030673623268
              ],
              [
                -50.19271064202295,
                -12.563884625259876
              ],
              [
                -50.20296359818993,
                -12.563778137947097
              ],
              [
                -50.2035940845271,
                -12.563771645034524
              ],
              [
                -50.20788252246087,
                -12.572608590845068
              ],
              [
                -50.227938361981366,
                -12.585800065715114
              ],
              [
                -50.2316218258687,
                -12.581074061232243
              ],
              [
                -50.234495352958554,
                -12.577387112599242
              ],
              [
                -50.24524471843404,
                -12.596945025496732
              ],
              [
                -50.24134422566307,
                -12.608859932554154
              ],
              [
                -50.236514728835914,
                -12.623608868269395
              ],
              [
                -50.246882658088,
                -12.629891828647603
              ],
              [
                -50.248127160555015,
                -12.630646344532149
              ],
              [
                -50.254708111333734,
                -12.624587356315153
              ],
              [
                -50.256653592623884,
                -12.634533341188957
              ],
              [
                -50.26196201649959,
                -12.63729181373911
              ],
              [
                -50.259101021591135,
                -12.64539175884473
              ],
              [
                -50.269354447890144,
                -12.654429744773712
              ],
              [
                -50.266542957833956,
                -12.658950211896057
              ],
              [
                -50.263730486782976,
                -12.663471180013609
              ],
              [
                -50.27007042088153,
                -12.67718411040929
              ],
              [
                -50.2663549379406,
                -12.682029551207448
              ],
              [
                -50.27246685435669,
                -12.682778082341176
              ],
              [
                -50.277927828883996,
                -12.677640611102955
              ],
              [
                -50.28074181093276,
                -12.688574038066804
              ],
              [
                -50.28349931047487,
                -12.684590593854532
              ],
              [
                -50.28554778447652,
                -12.681631613752911
              ],
              [
                -50.290072270312656,
                -12.68258059910236
              ],
              [
                -50.29226671444018,
                -12.688084069218217
              ],
              [
                -50.2952632212723,
                -12.684069590576202
              ],
              [
                -50.294875740054,
                -12.677254135797702
              ],
              [
                -50.29964218615105,
                -12.680465610982006
              ],
              [
                -50.293823215418044,
                -12.695227040157116
              ],
              [
                -50.295131152238184,
                -12.704648496031147
              ],
              [
                -50.298515649603495,
                -12.706688472416078
              ],
              [
                -50.287765216069,
                -12.71476093142994
              ],
              [
                -50.28377025239918,
                -12.717760886020066
              ],
              [
                -50.288293198964254,
                -12.734822796934308
              ],
              [
                -50.2918216638665,
                -12.736510799535369
              ],
              [
                -50.29792910350277,
                -12.739432782656317
              ],
              [
                -50.29621411439655,
                -12.742168752571246
              ],
              [
                -50.30179457660276,
                -12.745279777760073
              ],
              [
                -50.29662858239253,
                -12.75123373097843
              ],
              [
                -50.29504161492954,
                -12.753062699143056
              ],
              [
                -50.29897654535496,
                -12.758424189072226
              ],
              [
                -50.305041482892555,
                -12.766688156530494
              ],
              [
                -50.30398401626429,
                -12.773761096522968
              ],
              [
                -50.30292648163139,
                -12.780833057525424
              ],
              [
                -50.30459097487301,
                -12.784480037139101
              ],
              [
                -50.310817414685225,
                -12.798122499131457
              ],
              [
                -50.30613045108151,
                -12.804528459672538
              ],
              [
                -50.30726989036399,
                -12.81769685568027
              ],
              [
                -50.30526542834148,
                -12.821579851764191
              ],
              [
                -50.30137394411827,
                -12.819911330374493
              ],
              [
                -50.29218396253719,
                -12.839492741282601
              ],
              [
                -50.28870902047346,
                -12.835998724702872
              ],
              [
                -50.28418204358166,
                -12.843019674104864
              ],
              [
                -50.282849559397974,
                -12.840230211278463
              ],
              [
                -50.274359110101656,
                -12.843741691350427
              ],
              [
                -50.273674604373625,
                -12.843065179929663
              ],
              [
                -50.27121163193984,
                -12.840631694207909
              ],
              [
                -50.2618251759118,
                -12.84436265453306
              ],
              [
                -50.26279967554093,
                -12.846703157229555
              ],
              [
                -50.26306670742293,
                -12.84734562512624
              ],
              [
                -50.25879572885204,
                -12.848042618979441
              ],
              [
                -50.250626781056575,
                -12.849377617648472
              ],
              [
                -50.25054227615473,
                -12.850804588598086
              ],
              [
                -50.250395277831906,
                -12.853294577551878
              ],
              [
                -50.245006792922204,
                -12.85265659089297
              ],
              [
                -50.24187585279967,
                -12.852285571607522
              ],
              [
                -50.2388158601673,
                -12.854727575854765
              ],
              [
                -50.235658862118036,
                -12.857247025506387
              ],
              [
                -50.235536859191406,
                -12.857152017824683
              ],
              [
                -50.23107590844018,
                -12.853677054472616
              ],
              [
                -50.23131791993659,
                -12.858026528779183
              ],
              [
                -50.22529093935329,
                -12.861013482939779
              ],
              [
                -50.2250324449857,
                -12.866249951477414
              ],
              [
                -50.224657442242595,
                -12.873873437592916
              ],
              [
                -50.21287849766082,
                -12.877809393660035
              ],
              [
                -50.21229750291057,
                -12.877280885596445
              ],
              [
                -50.21070803127025,
                -12.87583639888686
              ],
              [
                -50.20669156397214,
                -12.881530839464874
              ],
              [
                -50.20426356388133,
                -12.884973315237103
              ],
              [
                -50.20002158317756,
                -12.883954822632413
              ],
              [
                -50.19954809889383,
                -12.883841316452443
              ],
              [
                -50.199298615078234,
                -12.884217310942049
              ],
              [
                -50.19532514890865,
                -12.890223774858969
              ],
              [
                -50.18296119783455,
                -12.89634522378885
              ],
              [
                -50.18262370509259,
                -12.892544747280022
              ],
              [
                -50.17824476181154,
                -12.891868758542527
              ],
              [
                -50.17604474575209,
                -12.89152975666185
              ],
              [
                -50.17502177866664,
                -12.893040248537947
              ],
              [
                -50.17251879269195,
                -12.896735701768865
              ],
              [
                -50.168215805823415,
                -12.895513731188249
              ],
              [
                -50.160187874506136,
                -12.893234200109102
              ],
              [
                -50.15352194188068,
                -12.898948665018962
              ],
              [
                -50.14937244039414,
                -12.902505162197418
              ],
              [
                -50.12735511019602,
                -12.904663101582448
              ],
              [
                -50.12649361345088,
                -12.904747119643497
              ],
              [
                -50.125771123357254,
                -12.905008601957299
              ],
              [
                -50.10925372755827,
                -12.910979026580776
              ],
              [
                -50.104906765384946,
                -12.908446049210891
              ],
              [
                -50.10348477243274,
                -12.907617051382891
              ],
              [
                -50.07843196752857,
                -12.905513013260471
              ],
              [
                -50.07503201129912,
                -12.910879005640593
              ],
              [
                -50.07163200806852,
                -12.916244931020369
              ],
              [
                -50.06227308822883,
                -12.918772924977219
              ],
              [
                -50.04987365557884,
                -12.922121374174083
              ],
              [
                -50.046396683960346,
                -12.928594830255745
              ],
              [
                -50.04399617988522,
                -12.93306530630869
              ],
              [
                -50.03846221693607,
                -12.934840277977802
              ],
              [
                -50.023861837153916,
                -12.930632786391325
              ],
              [
                -50.017489882882536,
                -12.928795813710583
              ],
              [
                -50.010018939519064,
                -12.931058765354747
              ],
              [
                -50.00255000614928,
                -12.9333212480028
              ],
              [
                -50.00054799078168,
                -12.935170218296097
              ],
              [
                -49.98931606904559,
                -12.945544663383643
              ],
              [
                -49.9836946293855,
                -12.942486653777866
              ],
              [
                -49.97807369272255,
                -12.939429166176641
              ],
              [
                -49.96356726792383,
                -12.950656571892479
              ],
              [
                -49.9523063514045,
                -12.959372508120493
              ],
              [
                -49.94563437073022,
                -12.957936027696604
              ],
              [
                -49.9389634370533,
                -12.956499515278656
              ],
              [
                -49.912912108340564,
                -12.96556492122562
              ],
              [
                -49.91066612057571,
                -12.969796879813416
              ],
              [
                -49.90842164079913,
                -12.97402835640656
              ],
              [
                -49.827496684755495,
                -13.018734018648999
              ],
              [
                -49.82001374233751,
                -13.022867963768892
              ],
              [
                -49.81757723552142,
                -13.024570444098506
              ],
              [
                -49.81514176469907,
                -13.026272425432092
              ],
              [
                -49.608157152586905,
                -13.142727469483116
              ],
              [
                -49.456555635017956,
                -13.226060764664128
              ],
              [
                -49.3707222170357,
                -13.273243355852797
              ],
              [
                -49.36903723307815,
                -13.27416933974513
              ],
              [
                -49.34161847724368,
                -13.254698912274494
              ],
              [
                -49.34055897611572,
                -13.245347439856484
              ],
              [
                -49.33949951897307,
                -13.235996512449754
              ],
              [
                -49.34235298703984,
                -13.227886536860584
              ],
              [
                -49.34788199658801,
                -13.212170661556689
              ],
              [
                -49.34483754879749,
                -13.20370171481319
              ],
              [
                -49.343769546201734,
                -13.20072920264966
              ],
              [
                -49.34662155525812,
                -13.189269288879906
              ],
              [
                -49.34721603705893,
                -13.186880792310292
              ],
              [
                -49.347088024839195,
                -13.18642728363515
              ],
              [
                -49.34440305221183,
                -13.176892827532857
              ],
              [
                -49.35134253320643,
                -13.16050795147886
              ],
              [
                -49.35437356015135,
                -13.153350492887832
              ],
              [
                -49.35332006786061,
                -13.148456507564926
              ],
              [
                -49.352267584557566,
                -13.143563024245513
              ],
              [
                -49.34149019288976,
                -13.126776611077933
              ],
              [
                -49.3443766875653,
                -13.116997682004738
              ],
              [
                -49.348316638973756,
                -13.114118220948
              ],
              [
                -49.353522132485004,
                -13.110314223256681
              ],
              [
                -49.35393761317901,
                -13.102559795568776
              ],
              [
                -49.33701229018402,
                -13.065866484083825
              ],
              [
                -49.32938787433896,
                -13.058760011086484
              ],
              [
                -49.32176393548651,
                -13.051654020109513
              ],
              [
                -49.30041165360258,
                -13.023464145656266
              ],
              [
                -49.28343632471259,
                -12.991229816741214
              ],
              [
                -49.278405889356044,
                -12.98858778812938
              ],
              [
                -49.27526589771706,
                -12.980277359749044
              ],
              [
                -49.2773999172173,
                -12.978226354952643
              ],
              [
                -49.27930240007394,
                -12.976397868506295
              ],
              [
                -49.27893391260545,
                -12.972566393528844
              ],
              [
                -49.262683074000556,
                -12.939764568475617
              ],
              [
                -49.259888081624176,
                -12.940609536974605
              ],
              [
                -49.257370605299485,
                -12.941371035414257
              ],
              [
                -49.256800139392325,
                -12.93465808042866
              ],
              [
                -49.246017745712,
                -12.916475180148991
              ],
              [
                -49.24754224156379,
                -12.909414700695
              ],
              [
                -49.24906724040455,
                -12.902354750242816
              ],
              [
                -49.23492387318403,
                -12.89323427410043
              ],
              [
                -49.23696284772105,
                -12.883541346456552
              ],
              [
                -49.23100091424266,
                -12.883262861011842
              ],
              [
                -49.22812891599212,
                -12.877505358821807
              ],
              [
                -49.22491846048519,
                -12.876795364452983
              ],
              [
                -49.22216100138241,
                -12.876185375279022
              ],
              [
                -49.21714604395612,
                -12.876939856576271
              ],
              [
                -49.210571087913365,
                -12.8779283439258
              ],
              [
                -49.20837809994879,
                -12.873077845753759
              ],
              [
                -49.196047204048774,
                -12.86682285954332
              ],
              [
                -49.19348221377619,
                -12.859167418687939
              ],
              [
                -49.19265375834543,
                -12.856694428336114
              ],
              [
                -49.18462883502711,
                -12.848639956616045
              ],
              [
                -49.170188952194344,
                -12.840016496417444
              ],
              [
                -49.16370550494905,
                -12.836143984572207
              ],
              [
                -49.159516021297044,
                -12.831155032918927
              ],
              [
                -49.15091611111358,
                -12.820913573769488
              ],
              [
                -49.14724162145926,
                -12.820017581725981
              ],
              [
                -49.14548666147653,
                -12.81958905423146
              ],
              [
                -49.14445818604646,
                -12.817403597943626
              ],
              [
                -49.136488270673766,
                -12.80047214597299
              ],
              [
                -49.118631395011306,
                -12.789930701220555
              ],
              [
                -49.11458993565944,
                -12.802028142599433
              ],
              [
                -49.10968293936643,
                -12.820746000856113
              ],
              [
                -49.10576743409591,
                -12.835679407328012
              ],
              [
                -49.101016953154435,
                -12.835622899710131
              ],
              [
                -49.09626752520795,
                -12.835566389094723
              ],
              [
                -49.08265308783748,
                -12.845045812383102
              ],
              [
                -49.06706770564146,
                -12.846911310707325
              ],
              [
                -49.07187915779067,
                -12.85232627492443
              ],
              [
                -49.070154662944304,
                -12.859179242031471
              ],
              [
                -49.07343365379755,
                -12.869132161879406
              ],
              [
                -49.07704608215859,
                -12.872164169679117
              ],
              [
                -49.08485254462609,
                -12.878715641494303
              ],
              [
                -49.080606063655246,
                -12.883385118818474
              ],
              [
                -49.08048605033344,
                -12.883517608863727
              ],
              [
                -49.08073855192699,
                -12.883801117674773
              ],
              [
                -49.08723598100151,
                -12.891096092092099
              ],
              [
                -49.082136051940054,
                -12.892064562028516
              ],
              [
                -49.0847525056909,
                -12.894032570229843
              ],
              [
                -49.07762703653479,
                -12.903279002701002
              ],
              [
                -49.05569369807702,
                -12.907659419963887
              ],
              [
                -49.05332673818979,
                -12.90813242629937
              ],
              [
                -49.05145922748506,
                -12.910343413588611
              ],
              [
                -49.0495912287849,
                -12.912553889879591
              ],
              [
                -49.04693227361842,
                -12.911018415147222
              ],
              [
                -49.03309988440599,
                -12.903031919365766
              ],
              [
                -49.02406845539693,
                -12.906177392310404
              ],
              [
                -49.020353476059206,
                -12.911628860049522
              ],
              [
                -49.009430550792636,
                -12.916275331266966
              ],
              [
                -49.00680057418833,
                -12.924613262394281
              ],
              [
                -49.00443256703798,
                -12.932121713685927
              ],
              [
                -48.987298654013586,
                -12.943008125927195
              ],
              [
                -48.979736705002836,
                -12.947812580865072
              ],
              [
                -48.97745621347867,
                -12.952291569536838
              ],
              [
                -48.97517575095268,
                -12.956770514209138
              ],
              [
                -48.93351766711346,
                -12.896661821756581
              ],
              [
                -48.87925370304571,
                -12.81836418652967
              ],
              [
                -48.87563773056527,
                -12.81486520153995
              ],
              [
                -48.86812077467877,
                -12.815454191675945
              ],
              [
                -48.87356774494552,
                -12.806954240376152
              ],
              [
                -48.869439307455025,
                -12.80397723056099
              ],
              [
                -48.86240783590032,
                -12.80455621557085
              ],
              [
                -48.86212832689962,
                -12.809268218129246
              ],
              [
                -48.857052879850684,
                -12.804994228171848
              ],
              [
                -48.85631388294873,
                -12.805295704218558
              ],
              [
                -48.84812545943612,
                -12.808639689473141
              ],
              [
                -48.84631848190168,
                -12.80937816624347
              ],
              [
                -48.84657745509724,
                -12.812182136615922
              ],
              [
                -48.847097945431706,
                -12.817809635256722
              ],
              [
                -48.84171195728289,
                -12.830034544312605
              ],
              [
                -48.84487641252197,
                -12.84027599195623
              ],
              [
                -48.84190742370161,
                -12.843976966833432
              ],
              [
                -48.83219551794674,
                -12.842600470545719
              ],
              [
                -48.829690523614474,
                -12.84224545840892
              ],
              [
                -48.82545705091551,
                -12.845081452127449
              ],
              [
                -48.820490125783024,
                -12.843877433754232
              ],
              [
                -48.817572119009846,
                -12.853115373007176
              ],
              [
                -48.812083147173254,
                -12.858665343092712
              ],
              [
                -48.812812648469084,
                -12.860487320877905
              ],
              [
                -48.81409961511449,
                -12.863700314630785
              ],
              [
                -48.80662568110841,
                -12.865942290341676
              ],
              [
                -48.804849169706614,
                -12.866474799332549
              ],
              [
                -48.805033663986634,
                -12.867234799289381
              ],
              [
                -48.80558668083233,
                -12.86951325816847
              ],
              [
                -48.79937773510866,
                -12.872966224051467
              ],
              [
                -48.79090778480585,
                -12.88380318389101
              ],
              [
                -48.786304300331736,
                -12.884681150184814
              ],
              [
                -48.785984792195485,
                -12.882701651983941
              ],
              [
                -48.78557431273279,
                -12.880155686875106
              ],
              [
                -48.77055240156047,
                -12.893747061997049
              ],
              [
                -48.76337145258631,
                -12.902202527598508
              ],
              [
                -48.76011046976236,
                -12.90604249116797
              ],
              [
                -48.75856648481173,
                -12.906452973238938
              ],
              [
                -48.7540980267926,
                -12.907640457976232
              ],
              [
                -48.74843603929083,
                -12.917223912647053
              ],
              [
                -48.736125128245575,
                -12.920597856752085
              ],
              [
                -48.73420210695271,
                -12.932076318815929
              ],
              [
                -48.735855598746106,
                -12.937023280263878
              ],
              [
                -48.738760593480016,
                -12.94571421238664
              ],
              [
                -48.73371759701388,
                -12.9548471726775
              ],
              [
                -48.73026810442503,
                -12.961093627155194
              ],
              [
                -48.73213056505132,
                -12.968077086326259
              ],
              [
                -48.7339930676689,
                -12.975061048504445
              ],
              [
                -48.73029557888815,
                -12.989361479518697
              ],
              [
                -48.72477260836442,
                -12.990869429617282
              ],
              [
                -48.71735965763003,
                -13.001890883137738
              ],
              [
                -48.71195920330016,
                -12.999326857721268
              ],
              [
                -48.7070572342307,
                -13.004165326810945
              ],
              [
                -48.697076293999466,
                -12.99751134409597
              ],
              [
                -48.69448983874453,
                -12.995786879616828
              ],
              [
                -48.693533351915605,
                -12.997576345771726
              ],
              [
                -48.690919847315904,
                -13.002463343420866
              ],
              [
                -48.686185397174,
                -13.00204380886515
              ],
              [
                -48.684686898582605,
                -12.99805232118703
              ],
              [
                -48.682961926764605,
                -12.993460855566122
              ],
              [
                -48.67411998082523,
                -12.994843853803417
              ],
              [
                -48.667712545153066,
                -13.003189274272527
              ],
              [
                -48.663935055660495,
                -13.001086789962406
              ],
              [
                -48.6566595853386,
                -13.00501527225164
              ],
              [
                -48.65641610658419,
                -13.00822775345165
              ],
              [
                -48.65610458776403,
                -13.012346199352528
              ],
              [
                -48.65279860329634,
                -13.011607712023613
              ],
              [
                -48.647405157582796,
                -13.010403726853205
              ],
              [
                -48.63788372810711,
                -13.017948162654914
              ],
              [
                -48.6372942233639,
                -13.026093117032241
              ],
              [
                -48.6367052226075,
                -13.034238079418017
              ],
              [
                -48.62727625699635,
                -13.051726945449333
              ],
              [
                -48.61940730167838,
                -13.051977940755089
              ],
              [
                -48.6136013684451,
                -13.058023375371114
              ],
              [
                -48.61231787291166,
                -13.059358865564334
              ],
              [
                -48.60165244748644,
                -13.059894378524312
              ],
              [
                -48.602204901318714,
                -13.074681765817594
              ],
              [
                -48.60232391983146,
                -13.077858240841175
              ],
              [
                -48.59994943477076,
                -13.07797875614467
              ],
              [
                -48.596020468457546,
                -13.078177235379135
              ],
              [
                -48.5976359551251,
                -13.082722225096491
              ],
              [
                -48.59783041667404,
                -13.083271241283208
              ],
              [
                -48.59676692263234,
                -13.085076717156921
              ],
              [
                -48.588484484233135,
                -13.099141638694649
              ],
              [
                -48.59077796687242,
                -13.101315600133624
              ],
              [
                -48.59618391551892,
                -13.106441575952632
              ],
              [
                -48.58907747376218,
                -13.105926573882705
              ],
              [
                -48.58520500986892,
                -13.10564558394334
              ],
              [
                -48.58365100972405,
                -13.110207550219274
              ],
              [
                -48.57821603519294,
                -13.126160423780199
              ],
              [
                -48.581349982991135,
                -13.127993939601163
              ],
              [
                -48.581778943461565,
                -13.141175358965917
              ],
              [
                -48.58466243154903,
                -13.143361367358404
              ],
              [
                -48.59631633181755,
                -13.15219830962561
              ],
              [
                -48.59209636342663,
                -13.158232259992602
              ],
              [
                -48.58601640419864,
                -13.158644755316352
              ],
              [
                -48.58679089317059,
                -13.166622223056578
              ],
              [
                -48.58490889602426,
                -13.168059218651655
              ],
              [
                -48.57749193775064,
                -13.173718171726172
              ],
              [
                -48.583266386646926,
                -13.180458133042237
              ],
              [
                -48.57748394423387,
                -13.187036577641999
              ],
              [
                -48.580042888719746,
                -13.190952047635633
              ],
              [
                -48.57929636302169,
                -13.206974467894053
              ],
              [
                -48.58207935481566,
                -13.209179984046232
              ],
              [
                -48.58323683033183,
                -13.210097456782007
              ],
              [
                -48.58302032304738,
                -13.216823414031008
              ],
              [
                -48.582862336562556,
                -13.221717913834569
              ],
              [
                -48.58568780653783,
                -13.225323902080726
              ],
              [
                -48.593320237820066,
                -13.23506884852428
              ],
              [
                -48.60012215122162,
                -13.238197332300272
              ],
              [
                -48.59748968703124,
                -13.246246298918521
              ],
              [
                -48.59319121291604,
                -13.249615255368756
              ],
              [
                -48.58719022645087,
                -13.254319216297018
              ],
              [
                -48.59122619761757,
                -13.255976735748444
              ],
              [
                -48.58912571530538,
                -13.263485657359332
              ],
              [
                -48.60009559259122,
                -13.294729484172665
              ],
              [
                -48.585959636591724,
                -13.317181866093922
              ],
              [
                -48.577796232558335,
                -13.314677854332572
              ],
              [
                -48.56723277922641,
                -13.311435849663628
              ],
              [
                -48.56463482538061,
                -13.309611365597753
              ],
              [
                -48.55315791767574,
                -13.301547891854792
              ],
              [
                -48.553407458100516,
                -13.280080505646264
              ],
              [
                -48.55700042675085,
                -13.276067031678002
              ],
              [
                -48.55929041277927,
                -13.273510078174953
              ],
              [
                -48.55477198056904,
                -13.258811132273934
              ],
              [
                -48.55449046135488,
                -13.257895629015032
              ],
              [
                -48.55451749005325,
                -13.255702643570853
              ],
              [
                -48.554741520925056,
                -13.237388264450463
              ],
              [
                -48.55280103249293,
                -13.234737271269715
              ],
              [
                -48.54943554842709,
                -13.230140295769647
              ],
              [
                -48.551326039544904,
                -13.227556335743941
              ],
              [
                -48.55326252742329,
                -13.224908837159187
              ],
              [
                -48.55415858471385,
                -13.204538446002633
              ],
              [
                -48.54387765377989,
                -13.194738997492918
              ],
              [
                -48.53939318559662,
                -13.193900498658902
              ],
              [
                -48.529935284144045,
                -13.19213148869051
              ],
              [
                -48.52970929050698,
                -13.18442953133598
              ],
              [
                -48.5207948967393,
                -13.166345130627372
              ],
              [
                -48.52116991372194,
                -13.15340069532571
              ],
              [
                -48.52154442668511,
                -13.140455776047112
              ],
              [
                -48.508419034390364,
                -13.128421841741117
              ],
              [
                -48.50607756812942,
                -13.133074295135344
              ],
              [
                -48.50362958572756,
                -13.133727293254433
              ],
              [
                -48.49454763102382,
                -13.136149759015845
              ],
              [
                -48.487574192524654,
                -13.142348725708722
              ],
              [
                -48.48234122568496,
                -13.14174271632536
              ],
              [
                -48.48017422372992,
                -13.141492189094128
              ],
              [
                -48.479180248681146,
                -13.132783746310851
              ],
              [
                -48.47210628704963,
                -13.142345191556993
              ],
              [
                -48.46817828661545,
                -13.16193704366473
              ],
              [
                -48.47712520318161,
                -13.178285497216162
              ],
              [
                -48.47468719093491,
                -13.191257383827885
              ],
              [
                -48.477291170686456,
                -13.196508895197605
              ],
              [
                -48.47392020591766,
                -13.200241367112586
              ],
              [
                -48.47237369474115,
                -13.201953351688553
              ],
              [
                -48.47137820512878,
                -13.21112577451589
              ],
              [
                -48.47218768703255,
                -13.212193266785462
              ],
              [
                -48.4749041618609,
                -13.215776251909945
              ],
              [
                -48.47207166025083,
                -13.222727223333267
              ],
              [
                -48.47482714181386,
                -13.235304175105897
              ],
              [
                -48.47001665099407,
                -13.235697142664064
              ],
              [
                -48.46756965825396,
                -13.2438635758029
              ],
              [
                -48.465191716782655,
                -13.244822589266855
              ],
              [
                -48.45943821477918,
                -13.247142041198092
              ],
              [
                -48.46065772082408,
                -13.255922534079325
              ],
              [
                -48.46096318956941,
                -13.258123005022139
              ],
              [
                -48.4593247324867,
                -13.258486981146248
              ],
              [
                -48.455991741730976,
                -13.259226976229881
              ],
              [
                -48.45820174225668,
                -13.260647496880487
              ],
              [
                -48.46145668357426,
                -13.262739952475181
              ],
              [
                -48.45990168223715,
                -13.27917986687631
              ],
              [
                -48.446407785394655,
                -13.283301848265813
              ],
              [
                -48.44126628644904,
                -13.291938269126922
              ],
              [
                -48.43477184752656,
                -13.288431287066269
              ],
              [
                -48.43640586523618,
                -13.283801823318157
              ],
              [
                -48.43037991067723,
                -13.279755339155557
              ],
              [
                -48.43157842546654,
                -13.276392838426176
              ],
              [
                -48.43209540508219,
                -13.274941867604577
              ],
              [
                -48.429406444459445,
                -13.271150867690784
              ],
              [
                -48.419911007286196,
                -13.27342036550561
              ],
              [
                -48.41626651670643,
                -13.274291338303934
              ],
              [
                -48.412777539266436,
                -13.271602847727769
              ],
              [
                -48.40679160741459,
                -13.266989349909862
              ],
              [
                -48.4039136186084,
                -13.268619341674723
              ],
              [
                -48.40146114996513,
                -13.270008325543863
              ],
              [
                -48.39071473489363,
                -13.26751881746313
              ],
              [
                -48.388564761036676,
                -13.262095864839637
              ],
              [
                -48.38799175900205,
                -13.260651368132468
              ],
              [
                -48.38745426240085,
                -13.261624345646258
              ],
              [
                -48.38523478530474,
                -13.265642329861357
              ],
              [
                -48.38112329580786,
                -13.258924850313834
              ],
              [
                -48.37899136136624,
                -13.25543987663825
              ],
              [
                -48.37507286443032,
                -13.25545539887532
              ],
              [
                -48.37362989950126,
                -13.255461387383425
              ],
              [
                -48.37307688579033,
                -13.254310866031265
              ],
              [
                -48.370544895581254,
                -13.249043900890262
              ],
              [
                -48.3607105162327,
                -13.242774446077439
              ],
              [
                -48.350429579596266,
                -13.241427445311059
              ],
              [
                -48.350363564785376,
                -13.241606904170121
              ],
              [
                -48.34872509745458,
                -13.246074373775713
              ],
              [
                -48.34611961510768,
                -13.247972369464101
              ],
              [
                -48.34132362467351,
                -13.24926085591876
              ],
              [
                -48.33648769545618,
                -13.246653360640423
              ],
              [
                -48.3434811533996,
                -13.24119090185109
              ],
              [
                -48.3423836427069,
                -13.239568418287197
              ],
              [
                -48.34024317875786,
                -13.23640442979026
              ],
              [
                -48.34167866366094,
                -13.227970488594979
              ],
              [
                -48.32968076643354,
                -13.23159746241082
              ],
              [
                -48.317070875009165,
                -13.229529434857826
              ],
              [
                -48.3164183599966,
                -13.232935931203777
              ],
              [
                -48.31630734482255,
                -13.233516410684679
              ],
              [
                -48.307420428611834,
                -13.234944399395696
              ],
              [
                -48.30693543054583,
                -13.232002926452312
              ],
              [
                -48.31024542925701,
                -13.231913941583944
              ],
              [
                -48.30927539956645,
                -13.230301915187715
              ],
              [
                -48.30744541701239,
                -13.227261927527838
              ],
              [
                -48.299886979239304,
                -13.223237460801155
              ],
              [
                -48.292158577935474,
                -13.219122963314682
              ],
              [
                -48.29235157719998,
                -13.21756747757457
              ],
              [
                -48.29267707031873,
                -13.214951483149823
              ],
              [
                -48.289096602555766,
                -13.215118479119937
              ],
              [
                -48.28834708325846,
                -13.215152987650981
              ],
              [
                -48.28829861975279,
                -13.214517993215942
              ],
              [
                -48.287558612582465,
                -13.204810516724667
              ],
              [
                -48.284592141232835,
                -13.20546853091863
              ],
              [
                -48.280124203587626,
                -13.199236556160338
              ],
              [
                -48.276073732025154,
                -13.199074048236204
              ],
              [
                -48.279594699336165,
                -13.2062215191261
              ],
              [
                -48.2753852262195,
                -13.204800038163645
              ],
              [
                -48.26821178966631,
                -13.202379007929412
              ],
              [
                -48.26499080259688,
                -13.204559513941962
              ],
              [
                -48.262503840329586,
                -13.197414061807509
              ],
              [
                -48.26229281902303,
                -13.196807056940138
              ],
              [
                -48.261406855871144,
                -13.196788548547184
              ],
              [
                -48.25044392361866,
                -13.196559538312886
              ],
              [
                -48.24243600010379,
                -13.186985087840952
              ],
              [
                -48.24298349498082,
                -13.186366064327837
              ],
              [
                -48.24501198340654,
                -13.18407458493988
              ],
              [
                -48.23843604214619,
                -13.180019124161623
              ],
              [
                -48.23143561161375,
                -13.167740188014955
              ],
              [
                -48.224707665637055,
                -13.173290619703591
              ],
              [
                -48.216460229143635,
                -13.168157630338966
              ],
              [
                -48.21313175775341,
                -13.1715791270181
              ],
              [
                -48.2110677749149,
                -13.173700085320283
              ],
              [
                -48.19530837565506,
                -13.172336595578065
              ],
              [
                -48.19629938295582,
                -13.164405647943326
              ],
              [
                -48.196454888943755,
                -13.16316115337627
              ],
              [
                -48.17349158496873,
                -13.147674685958647
              ],
              [
                -48.168151149470965,
                -13.153171153268493
              ],
              [
                -48.164504155982954,
                -13.156925625474344
              ],
              [
                -48.162044678600914,
                -13.153693147974632
              ],
              [
                -48.15751019057175,
                -13.156525633001314
              ],
              [
                -48.14905479334405,
                -13.15389262095213
              ],
              [
                -48.14544231315673,
                -13.152767108364333
              ],
              [
                -48.15156825170704,
                -13.160888088830752
              ],
              [
                -48.15439522334629,
                -13.164636052977064
              ],
              [
                -48.15476571844228,
                -13.169791523871114
              ],
              [
                -48.15510270461886,
                -13.17447651742533
              ],
              [
                -48.15730719537233,
                -13.176901003165089
              ],
              [
                -48.16344810918044,
                -13.183656948583009
              ],
              [
                -48.16376107360991,
                -13.199664890867943
              ],
              [
                -48.16141409352976,
                -13.207710311552264
              ],
              [
                -48.159849104865735,
                -13.20982482272563
              ],
              [
                -48.152880634981926,
                -13.219240245704176
              ],
              [
                -48.160947055342504,
                -13.226877722372413
              ],
              [
                -48.16327854148022,
                -13.229084683613248
              ],
              [
                -48.168207980592236,
                -13.243186634792899
              ],
              [
                -48.16212554258628,
                -13.246516587316165
              ],
              [
                -48.15604256958716,
                -13.249847556830117
              ],
              [
                -48.15329105163384,
                -13.278764907775484
              ],
              [
                -48.169362876001856,
                -13.298918791234394
              ],
              [
                -48.164957401533684,
                -13.305356250736038
              ],
              [
                -48.16092493505218,
                -13.30286025721475
              ],
              [
                -48.160349951555325,
                -13.303947255987932
              ],
              [
                -48.157097464707434,
                -13.310099221089953
              ],
              [
                -48.15587400347909,
                -13.30258675318746
              ],
              [
                -48.15109401608823,
                -13.306105736819916
              ],
              [
                -48.12142778104965,
                -13.29699923770817
              ],
              [
                -48.117906787074205,
                -13.290307286228606
              ],
              [
                -48.11270685590165,
                -13.29159978095147
              ],
              [
                -48.10631991269607,
                -13.287358298511649
              ],
              [
                -48.10364342823387,
                -13.288552262090676
              ],
              [
                -48.097097960571965,
                -13.291472744154985
              ],
              [
                -48.09282349748465,
                -13.285869271158036
              ],
              [
                -48.08257257021976,
                -13.288347253476331
              ],
              [
                -48.077621614438385,
                -13.281567764122117
              ],
              [
                -48.080403115627725,
                -13.27893328802078
              ],
              [
                -48.077708655292305,
                -13.277313788778743
              ],
              [
                -48.07761815131496,
                -13.275999814195993
              ],
              [
                -48.07692965132886,
                -13.265988347720093
              ],
              [
                -48.07378270231157,
                -13.266729841110939
              ],
              [
                -48.07185370040004,
                -13.267184359220694
              ],
              [
                -48.07270270126239,
                -13.264118850329318
              ],
              [
                -48.07868270122543,
                -13.24252250296459
              ],
              [
                -48.075907220296735,
                -13.238185017280655
              ],
              [
                -48.07138272903757,
                -13.239990507212353
              ],
              [
                -48.06201331022125,
                -13.234967515337715
              ],
              [
                -48.05843182558783,
                -13.240235467888144
              ],
              [
                -48.05454288662734,
                -13.238526491169734
              ],
              [
                -48.055975339847784,
                -13.24687493243005
              ],
              [
                -48.05615836455969,
                -13.247937938608015
              ],
              [
                -48.05201738838629,
                -13.255390880608232
              ],
              [
                -48.04331845503128,
                -13.254560881666963
              ],
              [
                -48.032678541006305,
                -13.253545357515016
              ],
              [
                -48.02900603205229,
                -13.262796814915099
              ],
              [
                -48.028539030582444,
                -13.263973797333366
              ],
              [
                -48.03382001324739,
                -13.265411280985798
              ],
              [
                -48.03229200164155,
                -13.273569753335785
              ],
              [
                -48.0286510142303,
                -13.271045251726825
              ],
              [
                -48.02212254590105,
                -13.279405179436242
              ],
              [
                -47.998828257601346,
                -13.274496187281876
              ],
              [
                -47.99226780431458,
                -13.276195681357446
              ],
              [
                -47.98570733403483,
                -13.277894652434865
              ],
              [
                -47.98222684707214,
                -13.285748607197243
              ],
              [
                -47.97843240328252,
                -13.294309042351202
              ],
              [
                -47.97162642312959,
                -13.30040948059528
              ],
              [
                -47.97056691442627,
                -13.311088953163427
              ],
              [
                -47.96587746408334,
                -13.314825903636011
              ],
              [
                -47.956043554747374,
                -13.308278917728638
              ],
              [
                -47.9516740849859,
                -13.309729902936668
              ],
              [
                -47.947632597791426,
                -13.304905426934159
              ],
              [
                -47.9433796166503,
                -13.306629895761311
              ],
              [
                -47.93916318361229,
                -13.297957454697622
              ],
              [
                -47.93543473001129,
                -13.290290484670926
              ],
              [
                -47.929458762488814,
                -13.294123965396066
              ],
              [
                -47.92442879641521,
                -13.291295982215429
              ],
              [
                -47.9179043508414,
                -13.297001440189284
              ],
              [
                -47.90326695040665,
                -13.30196039172358
              ],
              [
                -47.902487967965826,
                -13.306754869691405
              ],
              [
                -47.90173543260187,
                -13.311388328636848
              ],
              [
                -47.89778648773448,
                -13.309379325551877
              ],
              [
                -47.89273800937232,
                -13.306810860840267
              ],
              [
                -47.89066253618761,
                -13.312049296040163
              ],
              [
                -47.888459518422884,
                -13.317607281177828
              ],
              [
                -47.88313257331638,
                -13.320077754862163
              ],
              [
                -47.86990416396465,
                -13.319420220226633
              ],
              [
                -47.86428571453182,
                -13.31600824043032
              ],
              [
                -47.860899776404324,
                -13.313951758584611
              ],
              [
                -47.85036235937687,
                -13.31660671036732
              ],
              [
                -47.8440194057179,
                -13.318204682372029
              ],
              [
                -47.823547044395674,
                -13.311434698866895
              ],
              [
                -47.80134469409355,
                -13.327567072859843
              ],
              [
                -47.80086169179949,
                -13.330419069529965
              ],
              [
                -47.80037871050418,
                -13.333271054200976
              ],
              [
                -47.67854192993624,
                -13.467279570272657
              ],
              [
                -47.67696396468753,
                -13.458037618058665
              ],
              [
                -47.67538597042694,
                -13.448795161862725
              ],
              [
                -47.66728859049882,
                -13.435822256172814
              ],
              [
                -47.6618431333588,
                -13.431604262326601
              ],
              [
                -47.656880154798685,
                -13.42776126013822
              ],
              [
                -47.65531067146212,
                -13.42018881034613
              ],
              [
                -47.65415220639852,
                -13.414599838767016
              ],
              [
                -47.64886427629139,
                -13.409103878633115
              ],
              [
                -47.64344632043453,
                -13.40347191507906
              ],
              [
                -47.63924135150221,
                -13.392554464257165
              ],
              [
                -47.638496883716165,
                -13.39062298219803
              ],
              [
                -47.638110363745845,
                -13.390610446614584
              ],
              [
                -47.63340289265789,
                -13.390450945548778
              ],
              [
                -47.626735491035234,
                -13.383959493903191
              ],
              [
                -47.62228955422327,
                -13.367229570484179
              ],
              [
                -47.63681594025703,
                -13.364363133737903
              ],
              [
                -47.649685852071926,
                -13.350816723174605
              ],
              [
                -47.655844339159586,
                -13.321912390433202
              ],
              [
                -47.65521685959946,
                -13.319891413112074
              ],
              [
                -47.65376487611896,
                -13.31521941379989
              ],
              [
                -47.656871839026586,
                -13.310636949668542
              ],
              [
                -47.661764816685896,
                -13.30341800686908
              ],
              [
                -47.65656687906683,
                -13.299458032109465
              ],
              [
                -47.65637593894279,
                -13.27646914141892
              ],
              [
                -47.649089468453816,
                -13.273701676243562
              ],
              [
                -47.6490739885847,
                -13.273695671252431
              ],
              [
                -47.64004258496662,
                -13.262774698583302
              ],
              [
                -47.647654026879906,
                -13.250161801769265
              ],
              [
                -47.646235062950964,
                -13.241571363379283
              ],
              [
                -47.65137401007719,
                -13.238096885231045
              ],
              [
                -47.65701297691146,
                -13.234283914890298
              ],
              [
                -47.65378501137689,
                -13.22949640733815
              ],
              [
                -47.653180002648384,
                -13.228599405544504
              ],
              [
                -47.65418451903772,
                -13.219903488855042
              ],
              [
                -47.657771003066514,
                -13.219384474891033
              ],
              [
                -47.665816456568685,
                -13.218220512159107
              ],
              [
                -47.66459695110469,
                -13.213607534968505
              ],
              [
                -47.668456943200034,
                -13.208973061420037
              ],
              [
                -47.662766986367956,
                -13.206711054055608
              ],
              [
                -47.66153748777601,
                -13.20622258984001
              ],
              [
                -47.66360895966494,
                -13.204510599279647
              ],
              [
                -47.66498247897309,
                -13.203374615195601
              ],
              [
                -47.663293455878666,
                -13.20120861197997
              ],
              [
                -47.660625490582476,
                -13.197786627442015
              ],
              [
                -47.66290049511858,
                -13.197089110313733
              ],
              [
                -47.66543295679232,
                -13.19631264307611
              ],
              [
                -47.662685019735235,
                -13.194828632118933
              ],
              [
                -47.657461559248716,
                -13.192007639808601
              ],
              [
                -47.65490358850168,
                -13.17744322739328
              ],
              [
                -47.66066605227756,
                -13.173675760981073
              ],
              [
                -47.66954597837953,
                -13.167871309645824
              ],
              [
                -47.66109554846016,
                -13.159580826852046
              ],
              [
                -47.65649911509987,
                -13.15847535062325
              ],
              [
                -47.65407259997287,
                -13.157890829980847
              ],
              [
                -47.65146165256073,
                -13.149662394606745
              ],
              [
                -47.64406572744687,
                -13.149428875632776
              ],
              [
                -47.64340621164313,
                -13.145356902286023
              ],
              [
                -47.64779219183265,
                -13.14001194077844
              ],
              [
                -47.64862719910902,
                -13.138994444106011
              ],
              [
                -47.647174190909574,
                -13.138701462393609
              ],
              [
                -47.64513171249906,
                -13.138288936396542
              ],
              [
                -47.64629669755644,
                -13.137071961438135
              ],
              [
                -47.65026917188289,
                -13.132922466266633
              ],
              [
                -47.6450687265798,
                -13.128145997475915
              ],
              [
                -47.64623325334638,
                -13.120173537943169
              ],
              [
                -47.635518840521264,
                -13.113877085874641
              ],
              [
                -47.63434036166147,
                -13.104064629215621
              ],
              [
                -47.628944904827314,
                -13.10855657901366
              ],
              [
                -47.62717641202714,
                -13.110028587476776
              ],
              [
                -47.625189419525164,
                -13.108842582100769
              ],
              [
                -47.617540473887885,
                -13.104275593068058
              ],
              [
                -47.59248967300001,
                -13.116236479551148
              ],
              [
                -47.57343582644309,
                -13.117845452480521
              ],
              [
                -47.56254440957279,
                -13.123406392868835
              ],
              [
                -47.560907378867874,
                -13.124241410265762
              ],
              [
                -47.56804330629144,
                -13.149088264918985
              ],
              [
                -47.562631786131796,
                -13.183799063166814
              ],
              [
                -47.54096197169155,
                -13.191936985462837
              ],
              [
                -47.53466100700682,
                -13.185469509719583
              ],
              [
                -47.53262454149938,
                -13.183379523530826
              ],
              [
                -47.523464584098924,
                -13.181505493198266
              ],
              [
                -47.515553178957674,
                -13.185976483871753
              ],
              [
                -47.50943070164234,
                -13.18943692843233
              ],
              [
                -47.5001557761422,
                -13.18872840809823
              ],
              [
                -47.49630182222117,
                -13.186399457284402
              ],
              [
                -47.49046437083865,
                -13.182872966169105
              ],
              [
                -47.478447937310115,
                -13.184708931397614
              ],
              [
                -47.48736434186177,
                -13.207689289916578
              ],
              [
                -47.4870223419742,
                -13.2137527499088
              ],
              [
                -47.48668082807768,
                -13.21981624490601
              ],
              [
                -47.46546897902152,
                -13.226956135716765
              ],
              [
                -47.46301052514184,
                -13.226411632788924
              ],
              [
                -47.44600064936668,
                -13.222646632370802
              ],
              [
                -47.43532020368565,
                -13.226668609002822
              ],
              [
                -47.43084625654411,
                -13.237605048566477
              ],
              [
                -47.44696360632883,
                -13.240168541538393
              ],
              [
                -47.44740412789943,
                -13.240738032946627
              ],
              [
                -47.45413055726005,
                -13.249434500381886
              ],
              [
                -47.43440517810095,
                -13.267793376062151
              ],
              [
                -47.43732514242556,
                -13.278600792818995
              ],
              [
                -47.42653417443928,
                -13.289006716927124
              ],
              [
                -47.415712818317104,
                -13.269895820447156
              ],
              [
                -47.39707644992803,
                -13.26066433220798
              ],
              [
                -47.391969504651456,
                -13.261898823437908
              ],
              [
                -47.392972024992076,
                -13.24976039612468
              ],
              [
                -47.39332051963165,
                -13.245536924418877
              ],
              [
                -47.389212543300694,
                -13.240604476415085
              ],
              [
                -47.383305107690184,
                -13.241029932051799
              ],
              [
                -47.377295649506216,
                -13.2301804995275
              ],
              [
                -47.37089172484129,
                -13.228847008644438
              ],
              [
                -47.367599248623925,
                -13.23570645397802
              ],
              [
                -47.355947305156775,
                -13.245180885032648
              ],
              [
                -47.35198483950578,
                -13.248402864543397
              ],
              [
                -47.3445998864011,
                -13.247145838564757
              ],
              [
                -47.33721497530048,
                -13.245889850584277
              ],
              [
                -47.331415996373124,
                -13.250629286131842
              ],
              [
                -47.32255254304428,
                -13.257872253888266
              ],
              [
                -47.31695011580498,
                -13.254449783577538
              ],
              [
                -47.311348157562406,
                -13.251027769272158
              ],
              [
                -47.29409830885936,
                -13.248497750297814
              ],
              [
                -47.29312527624273,
                -13.256098213114033
              ],
              [
                -47.28521337451332,
                -13.25587769719218
              ],
              [
                -47.286073342712285,
                -13.257069196642636
              ],
              [
                -47.2894888107618,
                -13.261798174625289
              ],
              [
                -47.28171288192555,
                -13.264476665929923
              ],
              [
                -47.27491892642336,
                -13.250489238603665
              ],
              [
                -47.26702803138227,
                -13.246912742415148
              ],
              [
                -47.26726502621528,
                -13.24133976150724
              ],
              [
                -47.26740950398498,
                -13.237944288671477
              ],
              [
                -47.26174556442312,
                -13.236723808390856
              ],
              [
                -47.25235616241682,
                -13.234698800626784
              ],
              [
                -47.24776867001645,
                -13.229689302393092
              ],
              [
                -47.2486096763745,
                -13.223384341814656
              ],
              [
                -47.25018521553979,
                -13.21156391285573
              ],
              [
                -47.230795365406046,
                -13.20341993446322
              ],
              [
                -47.22871591522714,
                -13.194438482798915
              ],
              [
                -47.223075927932925,
                -13.192961474116379
              ],
              [
                -47.217123470105186,
                -13.196100436776723
              ],
              [
                -47.21166305401122,
                -13.192542949629535
              ],
              [
                -47.19643464414032,
                -13.193193433527137
              ],
              [
                -47.18241377350327,
                -13.187672975746938
              ],
              [
                -47.174797298019676,
                -13.194706403720268
              ],
              [
                -47.180507779008956,
                -13.200647889235865
              ],
              [
                -47.1836972342819,
                -13.20396685400545
              ],
              [
                -47.17204382502641,
                -13.206023348555778
              ],
              [
                -47.16729334198577,
                -13.210800775557
              ],
              [
                -47.16168192843104,
                -13.205325813457
              ],
              [
                -47.152610477242526,
                -13.209538774601196
              ],
              [
                -47.134765136080986,
                -13.202051318067378
              ],
              [
                -47.13419512986999,
                -13.1891278602404
              ],
              [
                -47.134841157190145,
                -13.188904865624593
              ],
              [
                -47.139931099317764,
                -13.187149402400294
              ],
              [
                -47.132154198947696,
                -13.17807093500353
              ],
              [
                -47.11782180340479,
                -13.177444423968021
              ],
              [
                -47.10852235111682,
                -13.170606457892875
              ],
              [
                -47.10837084389397,
                -13.176648400018427
              ],
              [
                -47.10829536929213,
                -13.179661411130349
              ],
              [
                -47.10493189991069,
                -13.181263372118595
              ],
              [
                -47.10224641364472,
                -13.174492416596644
              ],
              [
                -47.10212743509482,
                -13.174192411168844
              ],
              [
                -47.10049144347327,
                -13.173875411331641
              ],
              [
                -47.09321249999753,
                -13.172466901598256
              ],
              [
                -47.089548515825875,
                -13.173578905979369
              ],
              [
                -47.08691101802729,
                -13.181988864013835
              ],
              [
                -47.07972061286841,
                -13.17768085752725
              ],
              [
                -47.08437655538189,
                -13.170106904908002
              ],
              [
                -47.08089909803108,
                -13.166990428546368
              ],
              [
                -47.07979961835404,
                -13.166004933544107
              ],
              [
                -47.08040759828444,
                -13.16152996595505
              ],
              [
                -47.071964159059775,
                -13.160062973664685
              ],
              [
                -47.06865519561451,
                -13.155068466682593
              ],
              [
                -47.062510271355606,
                -13.160001940411014
              ],
              [
                -47.061342244908694,
                -13.160940435941548
              ],
              [
                -47.050880875413554,
                -13.155476956929213
              ],
              [
                -47.04709639114877,
                -13.157206440468785
              ],
              [
                -47.044415398522986,
                -13.152942440228658
              ],
              [
                -47.041433916074695,
                -13.148200474322136
              ],
              [
                -47.036752977814665,
                -13.149952971247846
              ],
              [
                -47.03548549595124,
                -13.150427458353917
              ],
              [
                -47.035358483698495,
                -13.14940845516495
              ],
              [
                -47.0351234747643,
                -13.14753296885862
              ],
              [
                -47.02240259989581,
                -13.141563999163543
              ],
              [
                -47.02148508837224,
                -13.142571485696292
              ],
              [
                -47.01895463847629,
                -13.145349944102374
              ],
              [
                -47.00930069380472,
                -13.14039198650462
              ],
              [
                -47.00563973128625,
                -13.13355350591421
              ],
              [
                -47.00462827347053,
                -13.131663519427
              ],
              [
                -47.004966276764605,
                -13.124689549233903
              ],
              [
                -46.999966800053386,
                -13.121175084800859
              ],
              [
                -46.98796891004489,
                -13.121912562705146
              ],
              [
                -46.987974372805574,
                -13.123779539665886
              ],
              [
                -46.98798538399761,
                -13.12770450245863
              ],
              [
                -46.97843496614981,
                -13.131143483395809
              ],
              [
                -46.97814799449549,
                -13.121162030998823
              ],
              [
                -46.96326711559529,
                -13.111718063460406
              ],
              [
                -46.95352018793675,
                -13.110706574444393
              ],
              [
                -46.94795275748891,
                -13.102052094579411
              ],
              [
                -46.94590124506111,
                -13.098863608107994
              ],
              [
                -46.94233429236664,
                -13.097537634115701
              ],
              [
                -46.9286599225574,
                -13.092454132830172
              ],
              [
                -46.928537420757515,
                -13.086451687221004
              ],
              [
                -46.92845994183711,
                -13.08265619660203
              ],
              [
                -46.91719653164767,
                -13.075473238767856
              ],
              [
                -46.91035210212702,
                -13.068630262173658
              ],
              [
                -46.90190465156118,
                -13.066872765790414
              ],
              [
                -46.89248676558809,
                -13.052791334007079
              ],
              [
                -46.878076875815246,
                -13.049933330456732
              ],
              [
                -46.8793838631182,
                -13.056651786679744
              ],
              [
                -46.87982284882846,
                -13.0589087619908
              ],
              [
                -46.87544886848054,
                -13.061124753683506
              ],
              [
                -46.87115440158631,
                -13.06035375724848
              ],
              [
                -46.868688922524704,
                -13.067686197664779
              ],
              [
                -46.858241518005826,
                -13.066951202960217
              ],
              [
                -46.85454703879976,
                -13.066532679411225
              ],
              [
                -46.8545470488383,
                -13.06297972052259
              ],
              [
                -46.854547050000896,
                -13.05935272207571
              ],
              [
                -46.85454705901404,
                -13.0552237576138
              ],
              [
                -46.84826613054216,
                -13.050462261666256
              ],
              [
                -46.84702713323659,
                -13.053442748927258
              ],
              [
                -46.844465636502946,
                -13.049259769615192
              ],
              [
                -46.847071619145915,
                -13.045040789948803
              ],
              [
                -46.83973369647418,
                -13.034095877074028
              ],
              [
                -46.83560522286502,
                -13.033572868459343
              ],
              [
                -46.83380977138812,
                -13.033344831891117
              ],
              [
                -46.82982128282187,
                -13.022699898730648
              ],
              [
                -46.8277698272427,
                -13.017223921980701
              ],
              [
                -46.81846088569698,
                -13.013587949483627
              ],
              [
                -46.82270836921098,
                -13.007696493462683
              ],
              [
                -46.81926389889591,
                -13.002039005550458
              ],
              [
                -46.75017651837675,
                -12.968724106117614
              ],
              [
                -46.6334319330883,
                -12.96955394441553
              ],
              [
                -46.55461703912756,
                -12.970026813390023
              ],
              [
                -46.51080892316562,
                -12.970324239987463
              ],
              [
                -46.45422035549698,
                -12.970722153461727
              ],
              [
                -46.45256338683174,
                -12.964021717024957
              ],
              [
                -46.45090688215462,
                -12.95732223159204
              ],
              [
                -46.445588497204184,
                -12.935810874276479
              ],
              [
                -46.44245051751599,
                -12.935536847886343
              ],
              [
                -46.43695603783733,
                -12.935057878946632
              ],
              [
                -46.432267092056144,
                -12.929616397132511
              ],
              [
                -46.4226417763719,
                -12.861596295000686
              ],
              [
                -46.41863684494741,
                -12.833291940127383
              ],
              [
                -46.417186364374565,
                -12.82304202459605
              ],
              [
                -46.41104391362572,
                -12.827522958814225
              ],
              [
                -46.41075540626474,
                -12.836988916300353
              ],
              [
                -46.41066039687992,
                -12.840095407427702
              ],
              [
                -46.407333936358164,
                -12.838809915878008
              ],
              [
                -46.407069432622315,
                -12.838707904071669
              ],
              [
                -46.40706442125607,
                -12.838953387584173
              ],
              [
                -46.40691639901642,
                -12.845852350772008
              ],
              [
                -46.39762249562144,
                -12.850705798716687
              ],
              [
                -46.38777157613996,
                -12.84922181496719
              ],
              [
                -46.38326808795398,
                -12.848543295884372
              ],
              [
                -46.375223141720966,
                -12.852773256568929
              ],
              [
                -46.374278168847916,
                -12.857222740238772
              ],
              [
                -46.37382515490726,
                -12.859356234655438
              ],
              [
                -46.37371616165785,
                -12.859424723068877
              ],
              [
                -46.3657647154191,
                -12.86442269726537
              ],
              [
                -46.36871716597503,
                -12.877118590449621
              ],
              [
                -46.36382572229638,
                -12.881618577524272
              ],
              [
                -46.36664668879584,
                -12.887124553849345
              ],
              [
                -46.36678266127722,
                -12.887390042466759
              ],
              [
                -46.366547676940414,
                -12.887510553365413
              ],
              [
                -46.36159522798509,
                -12.890056534115661
              ],
              [
                -46.36504066406035,
                -12.897046986502927
              ],
              [
                -46.36755912960407,
                -12.902156467750158
              ],
              [
                -46.36553715475717,
                -12.90388993207632
              ],
              [
                -46.360269680025354,
                -12.908404926458157
              ],
              [
                -46.365101667425925,
                -12.912784910808004
              ],
              [
                -46.364156143502285,
                -12.931067283216766
              ],
              [
                -46.36798658808591,
                -12.93769425246697
              ],
              [
                -46.37181705966272,
                -12.944321696726858
              ],
              [
                -46.37109954802019,
                -12.95710913064286
              ],
              [
                -46.37581400089791,
                -12.962341088744603
              ],
              [
                -46.36959554555303,
                -12.969920546170464
              ],
              [
                -46.367826512981075,
                -12.972076554361475
              ],
              [
                -46.36936253063671,
                -12.975763032661964
              ],
              [
                -46.37293599895258,
                -12.984338990847489
              ],
              [
                -46.363443555454424,
                -12.990787925803716
              ],
              [
                -46.33512726657382,
                -12.98334439802298
              ],
              [
                -46.33366082104612,
                -12.980451939068553
              ],
              [
                -46.32882683944542,
                -12.980430912426158
              ],
              [
                -46.319974412772666,
                -12.980392917429603
              ],
              [
                -46.31117998164861,
                -12.980355398525855
              ],
              [
                -46.29391462902537,
                -12.973592900518138
              ],
              [
                -46.2799367351679,
                -12.968117910069331
              ],
              [
                -46.278338232511715,
                -12.969528406006804
              ],
              [
                -46.27674026385228,
                -12.970938407947896
              ],
              [
                -46.24199607169878,
                -12.96025744263157
              ],
              [
                -46.23748808623942,
                -12.962230417511003
              ],
              [
                -46.22326169182613,
                -12.956519923174438
              ],
              [
                -46.19412446796653,
                -12.956240383228039
              ],
              [
                -46.187435508720085,
                -12.949291909483485
              ],
              [
                -46.17946958102165,
                -12.946921398064875
              ],
              [
                -46.17657310089542,
                -12.939195434107402
              ],
              [
                -46.16090124990114,
                -12.933576479036269
              ],
              [
                -46.1486133506845,
                -12.936587443221377
              ],
              [
                -46.124005530219854,
                -12.927138951102847
              ],
              [
                -46.119495100998385,
                -12.925406935390836
              ],
              [
                -46.11388862666913,
                -12.917537000079117
              ],
              [
                -46.1133706088649,
                -12.931379392492069
              ],
              [
                -46.125414994732495,
                -12.961044243324089
              ],
              [
                -46.13193992348977,
                -12.963026244775957
              ],
              [
                -46.13846437125503,
                -12.965008227232161
              ],
              [
                -46.14287032598486,
                -12.968133729395369
              ],
              [
                -46.154156712828716,
                -12.976140207097057
              ],
              [
                -46.159415206438204,
                -12.975864730180911
              ],
              [
                -46.16582265000881,
                -12.9755291994679
              ],
              [
                -46.17286108867151,
                -12.9823326926708
              ],
              [
                -46.17368855475631,
                -12.98313167317419
              ],
              [
                -46.17674556059725,
                -12.984233678422513
              ],
              [
                -46.190986440748304,
                -12.989366677282987
              ],
              [
                -46.19342039833487,
                -12.992395638914816
              ],
              [
                -46.19657684367406,
                -12.996323128289909
              ],
              [
                -46.2121522350823,
                -13.002742633587802
              ],
              [
                -46.220015185976145,
                -13.003689620510263
              ],
              [
                -46.22680061727052,
                -12.998755181152283
              ],
              [
                -46.2318705801223,
                -12.995068706514392
              ],
              [
                -46.239414539297876,
                -12.999173702892953
              ],
              [
                -46.23987453522384,
                -12.999423676125707
              ],
              [
                -46.240343534862745,
                -12.999204196201706
              ],
              [
                -46.245097966792756,
                -12.996978219252824
              ],
              [
                -46.25286489861233,
                -13.005625164643305
              ],
              [
                -46.27294873514143,
                -13.014521665381091
              ],
              [
                -46.27444520304336,
                -13.023603090129829
              ],
              [
                -46.27515119225649,
                -13.027886576489392
              ],
              [
                -46.27991114049038,
                -13.032628523628
              ],
              [
                -46.280938638611914,
                -13.033651544129617
              ],
              [
                -46.280876149646794,
                -13.034523028785923
              ],
              [
                -46.28026812925479,
                -13.04300046980957
              ],
              [
                -46.289636546167245,
                -13.051732432416678
              ],
              [
                -46.290869040447134,
                -13.052880929501345
              ],
              [
                -46.295656950567604,
                -13.063498375410962
              ],
              [
                -46.295890473839805,
                -13.064017382669013
              ],
              [
                -46.295892490819575,
                -13.064019871657306
              ],
              [
                -46.30114590248396,
                -13.075671828134123
              ],
              [
                -46.30439937288116,
                -13.07909279384663
              ],
              [
                -46.32223620659926,
                -13.097850710099495
              ],
              [
                -46.31787523159202,
                -13.102970658296806
              ],
              [
                -46.31351427958467,
                -13.108090635492648
              ],
              [
                -46.31302725415494,
                -13.10945161953385
              ],
              [
                -46.30829128140296,
                -13.122693044380084
              ],
              [
                -46.30174234130907,
                -13.124539031668391
              ],
              [
                -46.29830584097473,
                -13.125507516276055
              ],
              [
                -46.294548378759806,
                -13.13087195497908
              ],
              [
                -46.29164788479094,
                -13.147428875916134
              ],
              [
                -46.29319536557718,
                -13.15092234947501
              ],
              [
                -46.29950631705351,
                -13.153504858251916
              ],
              [
                -46.30938021575385,
                -13.157545323092416
              ],
              [
                -46.3035232386761,
                -13.166752258352313
              ],
              [
                -46.31404614756727,
                -13.177652202166756
              ],
              [
                -46.30571469162013,
                -13.180681182428541
              ],
              [
                -46.30260575140528,
                -13.181811174586203
              ],
              [
                -46.30038271976722,
                -13.193171618877432
              ],
              [
                -46.30406818118724,
                -13.205101537419878
              ],
              [
                -46.30935865023618,
                -13.209160542752121
              ],
              [
                -46.31182310143478,
                -13.211050498461532
              ],
              [
                -46.313225608262734,
                -13.221045438907918
              ],
              [
                -46.31376958033821,
                -13.224918428611542
              ],
              [
                -46.32074001607798,
                -13.238911386275865
              ],
              [
                -46.32185250429633,
                -13.240048863292344
              ],
              [
                -46.330711422443514,
                -13.249102827650058
              ],
              [
                -46.32973840657888,
                -13.252376794473072
              ],
              [
                -46.32037148556718,
                -13.252634284571197
              ],
              [
                -46.298160649052804,
                -13.253245246499693
              ],
              [
                -46.30104363285058,
                -13.266282187222727
              ],
              [
                -46.3005391336399,
                -13.269334629134583
              ],
              [
                -46.29964213049166,
                -13.274757629223366
              ],
              [
                -46.29854812786834,
                -13.281370591874555
              ],
              [
                -46.30094607362571,
                -13.284394538730117
              ],
              [
                -46.315490942555165,
                -13.30273847299226
              ],
              [
                -46.30247453722523,
                -13.310200404974358
              ],
              [
                -46.29737808873195,
                -13.313121869123856
              ],
              [
                -46.28830364707714,
                -13.314178866865891
              ],
              [
                -46.2829006439905,
                -13.333422247957545
              ],
              [
                -46.278958675193266,
                -13.347464134558845
              ],
              [
                -46.27272320118332,
                -13.345496664025985
              ],
              [
                -46.271136242669726,
                -13.344996168398751
              ],
              [
                -46.26912976217931,
                -13.345841157039786
              ],
              [
                -46.26512726212637,
                -13.34752562337144
              ],
              [
                -46.237182533694174,
                -13.335789158467687
              ],
              [
                -46.21777620277712,
                -13.316798236152737
              ],
              [
                -46.21120075949929,
                -13.31833320015166
              ],
              [
                -46.204625810223384,
                -13.319868195150438
              ],
              [
                -46.17187659784251,
                -13.311838223424353
              ],
              [
                -46.12884943763913,
                -13.290272282148917
              ],
              [
                -46.127694456424074,
                -13.288763260310985
              ],
              [
                -46.12249250749577,
                -13.281968793565785
              ],
              [
                -46.11598154837788,
                -13.283158287766382
              ],
              [
                -46.112207100297546,
                -13.283847784448156
              ],
              [
                -46.063381535924925,
                -13.260868851661025
              ],
              [
                -46.041737695313984,
                -13.27223622996725
              ],
              [
                -46.04113567813439,
                -13.280447195615821
              ],
              [
                -46.04375161945013,
                -13.290304147642397
              ],
              [
                -46.04804606466275,
                -13.308764537748491
              ],
              [
                -46.051264051641645,
                -13.310715554300995
              ],
              [
                -46.05203101472496,
                -13.311180534764759
              ],
              [
                -46.05203503268862,
                -13.311183015756464
              ],
              [
                -46.056782516876105,
                -13.31406051525457
              ],
              [
                -46.082672259603406,
                -13.330812446168153
              ],
              [
                -46.08881269078755,
                -13.331876456867464
              ],
              [
                -46.094953169977344,
                -13.332939441575853
              ],
              [
                -46.10189957134144,
                -13.343571420229068
              ],
              [
                -46.111511968417815,
                -13.358285340840364
              ],
              [
                -46.12455888184999,
                -13.363821326154204
              ],
              [
                -46.12554836523718,
                -13.364241324268175
              ],
              [
                -46.12696084800595,
                -13.365967823253024
              ],
              [
                -46.129032842129455,
                -13.368500310498359
              ],
              [
                -46.1332488000288,
                -13.375176765464714
              ],
              [
                -46.13955073909943,
                -13.377314785055852
              ],
              [
                -46.14303418012365,
                -13.378012778622843
              ],
              [
                -46.14922964573633,
                -13.380913260482137
              ],
              [
                -46.153884112950806,
                -13.383050242373733
              ],
              [
                -46.169985487796914,
                -13.383521283134462
              ],
              [
                -46.1806713769,
                -13.386851776859586
              ],
              [
                -46.18067540286453,
                -13.386853746854484
              ],
              [
                -46.191357818007624,
                -13.390183267593404
              ],
              [
                -46.213934589742074,
                -13.400278228587114
              ],
              [
                -46.215961069087214,
                -13.405209220480964
              ],
              [
                -46.21798704343639,
                -13.410138189391866
              ],
              [
                -46.221801003847446,
                -13.413154655691764
              ],
              [
                -46.24245682910377,
                -13.429489101417074
              ],
              [
                -46.242293822877656,
                -13.436720578996814
              ],
              [
                -46.23480038360504,
                -13.435532568649894
              ],
              [
                -46.22532497566859,
                -13.434030036896472
              ],
              [
                -46.22207198249272,
                -13.447933454520072
              ],
              [
                -46.213297527156406,
                -13.456720399512422
              ],
              [
                -46.205379079136385,
                -13.464854332818238
              ],
              [
                -46.20945953564209,
                -13.472333302979704
              ],
              [
                -46.21777996757004,
                -13.474510283833284
              ],
              [
                -46.22535139081284,
                -13.476490779619361
              ],
              [
                -46.22982136837399,
                -13.480997766185865
              ],
              [
                -46.222041400723704,
                -13.485959751649183
              ],
              [
                -46.21992740622175,
                -13.487307715092182
              ],
              [
                -46.22151191439524,
                -13.497197167775074
              ],
              [
                -46.218159407100664,
                -13.500073124045027
              ],
              [
                -46.22637430590508,
                -13.513133061914116
              ],
              [
                -46.23023479460765,
                -13.516312578414013
              ],
              [
                -46.24090169331408,
                -13.525097019905193
              ],
              [
                -46.23423570552411,
                -13.54050140604656
              ],
              [
                -46.23825915961853,
                -13.554147860550747
              ],
              [
                -46.235071666454076,
                -13.562470785686834
              ],
              [
                -46.228067237674686,
                -13.568522237946317
              ],
              [
                -46.22015777890775,
                -13.56783575678251
              ],
              [
                -46.214040329698,
                -13.571815689848844
              ],
              [
                -46.18386806532561,
                -13.566624174237463
              ],
              [
                -46.164351226531046,
                -13.584030587887705
              ],
              [
                -46.162943708103526,
                -13.587261053289675
              ],
              [
                -46.16153672467082,
                -13.590491509692955
              ],
              [
                -46.18725146138779,
                -13.617385891761128
              ],
              [
                -46.214339759487665,
                -13.631552847062785
              ],
              [
                -46.242086462378325,
                -13.658200748513764
              ],
              [
                -46.25336936871371,
                -13.681845108250444
              ],
              [
                -46.26032629951168,
                -13.686802084608255
              ],
              [
                -46.25554532484342,
                -13.693455053070188
              ],
              [
                -46.23796095108883,
                -13.700187485400482
              ],
              [
                -46.23786592721553,
                -13.700543957125413
              ],
              [
                -46.23593593677341,
                -13.707791417876049
              ],
              [
                -46.236823921007414,
                -13.711002902334117
              ],
              [
                -46.23711393583746,
                -13.71205190160458
              ],
              [
                -46.23795893168937,
                -13.715106892920542
              ],
              [
                -46.23829941679309,
                -13.716338402195293
              ],
              [
                -46.23852141876366,
                -13.716484890702743
              ],
              [
                -46.26448266252871,
                -13.733570321452051
              ],
              [
                -46.266462161537476,
                -13.73988679439829
              ],
              [
                -46.254824232372876,
                -13.748287232889993
              ],
              [
                -46.2483272956507,
                -13.746887707150018
              ],
              [
                -46.2367398604692,
                -13.74439071927811
              ],
              [
                -46.22316494392817,
                -13.769034052224907
              ],
              [
                -46.22571142814541,
                -13.771548027661398
              ],
              [
                -46.22825688237119,
                -13.774061530101607
              ],
              [
                -46.243615278186425,
                -13.778391043584426
              ],
              [
                -46.27332150244646,
                -13.786766039587889
              ],
              [
                -46.277670968501944,
                -13.791408480538411
              ],
              [
                -46.28201994256008,
                -13.79605049449906
              ],
              [
                -46.272208489104166,
                -13.802019934475023
              ],
              [
                -46.26215256534413,
                -13.808137366152723
              ],
              [
                -46.26610551258445,
                -13.81387385997501
              ],
              [
                -46.27085599039459,
                -13.820767320330264
              ],
              [
                -46.26913946045054,
                -13.828388256355232
              ],
              [
                -46.252186128691505,
                -13.830027754710576
              ],
              [
                -46.24924164966285,
                -13.830312704997853
              ],
              [
                -46.234899226232734,
                -13.84362913076865
              ],
              [
                -46.23447872318394,
                -13.84608911650645
              ],
              [
                -46.23405824613506,
                -13.848548614247807
              ],
              [
                -46.26173442913679,
                -13.902172826935475
              ],
              [
                -46.26181639042989,
                -13.92774615721271
              ],
              [
                -46.26476084190357,
                -13.935532131365662
              ],
              [
                -46.26770531236891,
                -13.943317595535785
              ],
              [
                -46.25021790528047,
                -13.95417050344043
              ],
              [
                -46.22082814788766,
                -13.97240933526185
              ],
              [
                -46.21603564733868,
                -13.97949480522131
              ],
              [
                -46.2177696020661,
                -13.995756714352794
              ],
              [
                -46.21843959233449,
                -14.002040653477849
              ],
              [
                -46.21663060429161,
                -14.004517165759859
              ],
              [
                -46.21595010434644,
                -14.005278122100227
              ],
              [
                -46.20957365133622,
                -14.012411575048688
              ],
              [
                -46.212330607191106,
                -14.021855515176352
              ],
              [
                -46.22545998940347,
                -14.039996930072771
              ],
              [
                -46.22949645603465,
                -14.04557393623988
              ],
              [
                -46.24798528528113,
                -14.056096394388426
              ],
              [
                -46.24243532543147,
                -14.059477350873275
              ],
              [
                -46.23232642332465,
                -14.05608086131815
              ],
              [
                -46.230657901380965,
                -14.058974362400175
              ],
              [
                -46.22477344478281,
                -14.069182760133094
              ],
              [
                -46.241555789670784,
                -14.092269163538107
              ],
              [
                -46.24669422974911,
                -14.093306690376863
              ],
              [
                -46.24732172650724,
                -14.093433679722011
              ],
              [
                -46.25305219920418,
                -14.08661520575839
              ],
              [
                -46.26535207310245,
                -14.097523154065614
              ],
              [
                -46.255738639683585,
                -14.103630126458913
              ],
              [
                -46.23596979374166,
                -14.106798098434462
              ],
              [
                -46.216429932470426,
                -14.11956948736334
              ],
              [
                -46.20933497749285,
                -14.119384445096193
              ],
              [
                -46.201762054363755,
                -14.11918694707216
              ],
              [
                -46.19743357033905,
                -14.126086384976787
              ],
              [
                -46.1970825596083,
                -14.136218347813609
              ],
              [
                -46.196856028444714,
                -14.142759277980861
              ],
              [
                -46.19103509604363,
                -14.147087259398726
              ],
              [
                -46.18521363864827,
                -14.151416251807275
              ],
              [
                -46.15087590253247,
                -14.15998215156649
              ],
              [
                -46.142084467437506,
                -14.156389126363132
              ],
              [
                -46.133294033338466,
                -14.15279712816747
              ],
              [
                -46.11770764399854,
                -14.15993107303461
              ],
              [
                -46.11363417564054,
                -14.173011983981452
              ],
              [
                -46.114096143076466,
                -14.176212958960129
              ],
              [
                -46.11562060951745,
                -14.18676891854509
              ],
              [
                -46.10677168605443,
                -14.190200861912112
              ],
              [
                -46.10123773241868,
                -14.192347838622272
              ],
              [
                -46.09579527268232,
                -14.205047778434581
              ],
              [
                -46.090955273082855,
                -14.205241762835223
              ],
              [
                -46.076630935490584,
                -14.1950588077088
              ],
              [
                -46.06306852668805,
                -14.193117783456533
              ],
              [
                -46.05224061771389,
                -14.204417710051828
              ],
              [
                -46.05720701573484,
                -14.220857133005683
              ],
              [
                -46.06059749294222,
                -14.232077542945788
              ],
              [
                -46.053238541853304,
                -14.238089536714755
              ],
              [
                -46.04588007876637,
                -14.244100979483903
              ],
              [
                -46.04194810260415,
                -14.244469457433205
              ],
              [
                -46.02885171532763,
                -14.24569893827627
              ],
              [
                -46.02650221936278,
                -14.253198363052094
              ],
              [
                -46.02545922659271,
                -14.256529358634689
              ],
              [
                -46.020502274911955,
                -14.260019848423159
              ],
              [
                -46.01602928050202,
                -14.258542327278274
              ],
              [
                -46.007019396238526,
                -14.255566324007571
              ],
              [
                -45.9956239674056,
                -14.256781812905228
              ],
              [
                -45.99137647722128,
                -14.262355251670956
              ],
              [
                -45.99302695795861,
                -14.269969241762379
              ],
              [
                -45.994868442530944,
                -14.278464176018067
              ],
              [
                -45.97771307843334,
                -14.277242652133966
              ],
              [
                -45.975597588687464,
                -14.277092156312262
              ],
              [
                -45.96921914616578,
                -14.28127763550474
              ],
              [
                -45.962840686649805,
                -14.285462567697751
              ],
              [
                -45.95854270423629,
                -14.303850456969721
              ],
              [
                -45.953649193856386,
                -14.324785349245197
              ],
              [
                -45.94631525965973,
                -14.329701804444118
              ],
              [
                -45.946038734538426,
                -14.329886782870247
              ],
              [
                -45.94575925695133,
                -14.33087730555414
              ],
              [
                -45.94254674371226,
                -14.342250720029545
              ],
              [
                -45.92965435463158,
                -14.350160138843117
              ],
              [
                -45.92853285306985,
                -14.34992865223095
              ],
              [
                -45.91315848546579,
                -14.346757644835588
              ],
              [
                -45.906861009640856,
                -14.352935107421612
              ],
              [
                -45.91240045476686,
                -14.366087049824824
              ],
              [
                -45.91170895267835,
                -14.37452796498089
              ],
              [
                -45.922094335628216,
                -14.379699485887256
              ],
              [
                -45.92567879559075,
                -14.385753426634155
              ],
              [
                -45.927213249448144,
                -14.409871810678084
              ],
              [
                -45.934919686177274,
                -14.414895274802417
              ],
              [
                -45.94221662314967,
                -14.415646782289892
              ],
              [
                -45.94951107615008,
                -14.416397303781993
              ],
              [
                -45.95643200711859,
                -14.423075268839948
              ],
              [
                -45.95992945473645,
                -14.426450749227355
              ],
              [
                -45.97461035267168,
                -14.431361744416163
              ],
              [
                -45.99839315322671,
                -14.428942291721103
              ],
              [
                -46.001434643718284,
                -14.422020362677907
              ],
              [
                -46.00804013170151,
                -14.406991958465778
              ],
              [
                -46.01635504384503,
                -14.418867387576269
              ],
              [
                -46.006535080178935,
                -14.428240812255222
              ],
              [
                -46.0004041504743,
                -14.434093268097484
              ],
              [
                -46.00109759336618,
                -14.448310206031751
              ],
              [
                -46.009399520239654,
                -14.4528501831702
              ],
              [
                -46.01182551728801,
                -14.4541761547108
              ],
              [
                -46.01595896136965,
                -14.469008076217609
              ],
              [
                -46.012833962738895,
                -14.475267044030513
              ],
              [
                -46.004817519101394,
                -14.48113848341565
              ],
              [
                -45.99678756355928,
                -14.487020449710798
              ],
              [
                -45.993238592203014,
                -14.494948407998013
              ],
              [
                -45.9937420913165,
                -14.500198865168237
              ],
              [
                -45.99853652573137,
                -14.502909369842811
              ],
              [
                -46.000987516450664,
                -14.504294873100699
              ],
              [
                -45.998438022577275,
                -14.509276344419051
              ],
              [
                -46.00384046674853,
                -14.515373798378882
              ],
              [
                -45.999246499495364,
                -14.521308270485513
              ],
              [
                -45.99428901401503,
                -14.523594727276981
              ],
              [
                -45.98051014175574,
                -14.529949665544592
              ],
              [
                -45.97665513985021,
                -14.538397126262607
              ],
              [
                -45.979297613889194,
                -14.545698578280161
              ],
              [
                -45.9903950262854,
                -14.54328113522031
              ],
              [
                -45.99485351330031,
                -14.542309628857291
              ],
              [
                -45.98921654354087,
                -14.549239577284839
              ],
              [
                -45.988714538411465,
                -14.549857059779058
              ],
              [
                -45.98904703928683,
                -14.550098073963907
              ],
              [
                -45.99345899181742,
                -14.553300558124976
              ],
              [
                -45.989155039158064,
                -14.556080039192684
              ],
              [
                -45.990686981340154,
                -14.563253483008811
              ],
              [
                -45.99537396008157,
                -14.565698490100617
              ],
              [
                -45.988218508210615,
                -14.566947485011799
              ],
              [
                -45.98730198927234,
                -14.567107491438469
              ],
              [
                -45.9848780304419,
                -14.573105430059103
              ],
              [
                -45.98893594445625,
                -14.588680830337852
              ],
              [
                -45.9889494403194,
                -14.588695864274207
              ],
              [
                -46.00060035917569,
                -14.60115881737782
              ],
              [
                -45.991378908588146,
                -14.612095736011414
              ],
              [
                -45.98440595597606,
                -14.620366649233205
              ],
              [
                -45.994803841557754,
                -14.638777083485582
              ],
              [
                -45.98857035529523,
                -14.645296011264872
              ],
              [
                -45.989323336733435,
                -14.658745447190086
              ],
              [
                -45.999868249642304,
                -14.664195912388866
              ],
              [
                -46.006821207057186,
                -14.662992450915818
              ],
              [
                -46.007876186856045,
                -14.657053502426649
              ],
              [
                -46.01244966657838,
                -14.659440959741602
              ],
              [
                -46.02063808667796,
                -14.663714953576505
              ],
              [
                -46.024238043906,
                -14.664690477374867
              ],
              [
                -46.02210156999685,
                -14.670516934587614
              ],
              [
                -46.0231095338747,
                -14.672634412069677
              ],
              [
                -46.02545849562077,
                -14.677569894558024
              ],
              [
                -46.02817849922714,
                -14.681535863366616
              ],
              [
                -46.02233401071947,
                -14.69176129829275
              ],
              [
                -46.00695614228408,
                -14.70068973856804
              ],
              [
                -46.006069632427746,
                -14.701204714970048
              ],
              [
                -46.00399213810083,
                -14.706991702527583
              ],
              [
                -46.00191414077461,
                -14.712778633086382
              ],
              [
                -46.00653958128636,
                -14.730767530748759
              ],
              [
                -46.00789205427543,
                -14.731365028717454
              ],
              [
                -46.011741516089316,
                -14.733066025781698
              ],
              [
                -46.00859254466741,
                -14.73868552139628
              ],
              [
                -46.007154555326174,
                -14.74125048587451
              ],
              [
                -46.007488489370736,
                -14.761792370018089
              ],
              [
                -46.00940799896016,
                -14.764917840325287
              ],
              [
                -46.01220694373468,
                -14.769476842896927
              ],
              [
                -46.00895746966616,
                -14.773753823146796
              ],
              [
                -46.00885099525495,
                -14.773893321142955
              ],
              [
                -46.008698986995334,
                -14.776233305267201
              ],
              [
                -46.00762843694154,
                -14.792752696333244
              ],
              [
                -46.02009084557179,
                -14.810042109557903
              ],
              [
                -46.02290181151486,
                -14.81756856497644
              ],
              [
                -46.03776917582755,
                -14.821827088311451
              ],
              [
                -46.04508260330305,
                -14.826376072233224
              ],
              [
                -46.052396021781,
                -14.830924533170665
              ],
              [
                -46.04406057367436,
                -14.841877944466011
              ],
              [
                -46.032454631571845,
                -14.857128870876917
              ],
              [
                -46.03698609429731,
                -14.873636251461171
              ],
              [
                -46.04173405328119,
                -14.877183259579008
              ],
              [
                -46.05231595295266,
                -14.885088215122622
              ],
              [
                -46.05636090613592,
                -14.894556165691993
              ],
              [
                -46.06190882441109,
                -14.907544115661977
              ],
              [
                -46.06687276406056,
                -14.909329111421867
              ],
              [
                -46.07034825676584,
                -14.907968625659151
              ],
              [
                -46.079091673135956,
                -14.904547167467566
              ],
              [
                -46.082683159929445,
                -14.917630574363796
              ],
              [
                -46.08771105499971,
                -14.935945984685283
              ],
              [
                -46.109961366045525,
                -14.936569016929827
              ],
              [
                -46.11447935577474,
                -14.928836552901684
              ],
              [
                -46.1195408219417,
                -14.920173647247895
              ],
              [
                -46.13190223922175,
                -14.920929165562859
              ],
              [
                -46.1345037187339,
                -14.923815139665287
              ],
              [
                -46.13831918088323,
                -14.928048143219867
              ],
              [
                -46.14531763554858,
                -14.926068148504706
              ],
              [
                -46.15039259294162,
                -14.921367697966716
              ],
              [
                -46.159037010108214,
                -14.913361242069811
              ],
              [
                -46.16098954425305,
                -14.905726773744275
              ],
              [
                -46.16598150378018,
                -14.908003793751098
              ],
              [
                -46.16136948214354,
                -14.929451139453167
              ],
              [
                -46.176045836122874,
                -14.948604083917084
              ],
              [
                -46.20537559598142,
                -14.936716161434784
              ],
              [
                -46.21087205128569,
                -14.936519673678971
              ],
              [
                -46.21296505645668,
                -14.9396256886229
              ],
              [
                -46.21529253007152,
                -14.943078173004313
              ],
              [
                -46.22403795744319,
                -14.941284175441867
              ],
              [
                -46.227443431651274,
                -14.933830745668088
              ],
              [
                -46.233269940750795,
                -14.921080302884517
              ],
              [
                -46.24070335248853,
                -14.921431336529139
              ],
              [
                -46.24695481147461,
                -14.921900855319699
              ],
              [
                -46.25135078508641,
                -14.924553335119937
              ],
              [
                -46.25778269999389,
                -14.928434321593512
              ],
              [
                -46.286410983087116,
                -14.927699867522568
              ],
              [
                -46.29173496685747,
                -14.921327920012498
              ],
              [
                -46.29247647151031,
                -14.91742842283123
              ],
              [
                -46.29423697243188,
                -14.908167495403081
              ],
              [
                -46.29997090865424,
                -14.90964100946393
              ],
              [
                -46.30568033908787,
                -14.9111084895179
              ],
              [
                -46.315046284437344,
                -14.90014608186613
              ],
              [
                -46.31890328285843,
                -14.899995569825805
              ],
              [
                -46.31590085146411,
                -14.881689671732374
              ],
              [
                -46.313313356257076,
                -14.8801016961281
              ],
              [
                -46.308456894913704,
                -14.877121683380102
              ],
              [
                -46.30003998108494,
                -14.85763278226983
              ],
              [
                -46.31003190908048,
                -14.855067832375878
              ],
              [
                -46.31403940300154,
                -14.854039349651886
              ],
              [
                -46.31837987575816,
                -14.847076398747653
              ],
              [
                -46.32448381640201,
                -14.847342386401587
              ],
              [
                -46.31982787288679,
                -14.835861464061765
              ],
              [
                -46.32032137964097,
                -14.830330499860704
              ],
              [
                -46.32176738338815,
                -14.814134079928369
              ],
              [
                -46.32887333945787,
                -14.813142590643942
              ],
              [
                -46.333872809816036,
                -14.81244460081395
              ],
              [
                -46.33496979279571,
                -14.807396619937517
              ],
              [
                -46.33596131267503,
                -14.802835694060686
              ],
              [
                -46.350469683735426,
                -14.79606024566314
              ],
              [
                -46.35524467186304,
                -14.793829778255073
              ],
              [
                -46.35914961679838,
                -14.794267245868738
              ],
              [
                -46.36518759822212,
                -14.794943778003793
              ],
              [
                -46.36682206250665,
                -14.790101304916334
              ],
              [
                -46.36716356973228,
                -14.789088326382268
              ],
              [
                -46.3700705622727,
                -14.78816781000296
              ],
              [
                -46.37654949244785,
                -14.786116836669324
              ],
              [
                -46.38001947609112,
                -14.782813873055654
              ],
              [
                -46.38566193736925,
                -14.777440881346212
              ],
              [
                -46.40203136564742,
                -14.76185401011761
              ],
              [
                -46.40409884592711,
                -14.759885526242579
              ],
              [
                -46.41682626073071,
                -14.756017569774503
              ],
              [
                -46.428753669475576,
                -14.75239263144123
              ],
              [
                -46.43754060103609,
                -14.742162212450456
              ],
              [
                -46.44692802215077,
                -14.731232761579882
              ],
              [
                -46.45422896533266,
                -14.730999799234349
              ],
              [
                -46.461532435502356,
                -14.73076629189801
              ],
              [
                -46.467056400029676,
                -14.71278389762654
              ],
              [
                -46.4684098917001,
                -14.708379422486475
              ],
              [
                -46.47143988902728,
                -14.706559432498041
              ],
              [
                -46.47447036235171,
                -14.7047394505099
              ],
              [
                -46.48467926667103,
                -14.707992471349797
              ],
              [
                -46.4895812495739,
                -14.709553976280437
              ],
              [
                -46.4915362225596,
                -14.708734487565144
              ],
              [
                -46.50028016888949,
                -14.705066522633256
              ],
              [
                -46.502906623066565,
                -14.703965002765498
              ],
              [
                -46.50928454578777,
                -14.715622971226503
              ],
              [
                -46.50781955495047,
                -14.726277878189345
              ],
              [
                -46.507074551445676,
                -14.731698845613925
              ],
              [
                -46.509847006548554,
                -14.737815821457465
              ],
              [
                -46.52196040977819,
                -14.744656304213885
              ],
              [
                -46.52380142553356,
                -14.744950803883524
              ],
              [
                -46.53668078689446,
                -14.747011830566686
              ],
              [
                -46.54375672076992,
                -14.752568804544662
              ],
              [
                -46.543286206706625,
                -14.760235779597018
              ],
              [
                -46.54279571716392,
                -14.768228702746274
              ],
              [
                -46.550473614373324,
                -14.77422520234094
              ],
              [
                -46.565456006326315,
                -14.785928151565706
              ],
              [
                -46.562983974603846,
                -14.79852857666889
              ],
              [
                -46.56296999167748,
                -14.798548083531262
              ],
              [
                -46.56259898961982,
                -14.799071078847282
              ],
              [
                -46.55723503671011,
                -14.806628012609774
              ],
              [
                -46.55864651808114,
                -14.814038486657951
              ],
              [
                -46.55836901549542,
                -14.820629936312816
              ],
              [
                -46.53773215349283,
                -14.827586343452616
              ],
              [
                -46.532669706543935,
                -14.829292357353316
              ],
              [
                -46.526896707752684,
                -14.840011271200826
              ],
              [
                -46.52112424095016,
                -14.850729707053969
              ],
              [
                -46.52998860588527,
                -14.883264040728243
              ],
              [
                -46.53594907333294,
                -14.88942248941415
              ],
              [
                -46.541909493777176,
                -14.895580992114937
              ],
              [
                -46.54610142784828,
                -14.924997806332502
              ],
              [
                -46.54264742542953,
                -14.93264475015147
              ],
              [
                -46.539548416709586,
                -14.939507733118369
              ],
              [
                -46.53971091106771,
                -14.94172621371758
              ],
              [
                -46.540402900064684,
                -14.951157650008819
              ],
              [
                -46.530305476747664,
                -14.960273090119582
              ],
              [
                -46.51685255727133,
                -14.972418508657084
              ],
              [
                -46.51632254153042,
                -14.981571925287213
              ],
              [
                -46.520671012855836,
                -14.984101424883958
              ],
              [
                -46.526869968299835,
                -14.987706916765575
              ],
              [
                -46.530374876653475,
                -15.00483084534494
              ],
              [
                -46.52530490778631,
                -15.009063291718636
              ],
              [
                -46.520235476919034,
                -15.013294768097081
              ],
              [
                -46.50216950823438,
                -15.051809531304968
              ],
              [
                -46.50808598833523,
                -15.056192483290259
              ],
              [
                -46.51400291143223,
                -15.060575467286343
              ],
              [
                -46.52568832632914,
                -15.055922554657508
              ],
              [
                -46.53156280338514,
                -15.055948050469121
              ],
              [
                -46.54414566641106,
                -15.064824517273026
              ],
              [
                -46.5508836403981,
                -15.06342901881677
              ],
              [
                -46.57618936590037,
                -15.082926950880333
              ],
              [
                -46.5818798607355,
                -15.081801980936284
              ],
              [
                -46.58756829459058,
                -15.080677488985371
              ],
              [
                -46.592965252734714,
                -15.083610498363184
              ],
              [
                -46.59987218921001,
                -15.087365000892012
              ],
              [
                -46.604810133445945,
                -15.08554300851466
              ],
              [
                -46.610179107562786,
                -15.083562515846067
              ],
              [
                -46.61734853212407,
                -15.086224994098295
              ],
              [
                -46.62500648243516,
                -15.089069031238774
              ],
              [
                -46.64234733043239,
                -15.086444073660116
              ],
              [
                -46.64915731050755,
                -15.083752075738953
              ],
              [
                -46.65596823557842,
                -15.081061610809071
              ],
              [
                -46.679599581791926,
                -15.059655287889044
              ],
              [
                -46.726295771266486,
                -15.038871973506954
              ],
              [
                -46.739558679260064,
                -15.035586021003013
              ],
              [
                -46.75282157527915,
                -15.03229957549902
              ],
              [
                -46.78142038347086,
                -15.018878191292089
              ],
              [
                -46.82241553273512,
                -15.010289307758768
              ],
              [
                -46.85646029332835,
                -15.009818343116054
              ],
              [
                -46.8600967394334,
                -15.014879827188206
              ],
              [
                -46.86373220454373,
                -15.019940831269656
              ],
              [
                -46.87383159456465,
                -15.025395289237354
              ],
              [
                -46.91831670288293,
                -15.049420740488728
              ],
              [
                -46.92428314575295,
                -15.05786772285823
              ],
              [
                -46.924523130305964,
                -15.060652674539787
              ],
              [
                -46.925598093955465,
                -15.07311660599976
              ],
              [
                -46.91334868504182,
                -15.082191020586318
              ],
              [
                -46.89858877935701,
                -15.093125468906656
              ],
              [
                -46.895889313320694,
                -15.103379874779433
              ],
              [
                -46.888710841705254,
                -15.111190851045954
              ],
              [
                -46.89583025412378,
                -15.129739749524605
              ],
              [
                -46.91002510658294,
                -15.151656133423215
              ],
              [
                -46.912402097361884,
                -15.1553266161749
              ],
              [
                -46.91627950408924,
                -15.166547048118005
              ],
              [
                -46.920156472798,
                -15.17776852408275
              ],
              [
                -46.922764448721736,
                -15.181940475467032
              ],
              [
                -46.93690877197033,
                -15.204573368560892
              ],
              [
                -46.93537728040924,
                -15.21188285942517
              ],
              [
                -46.93303926949119,
                -15.223039286069245
              ],
              [
                -46.93462978322026,
                -15.224353265682002
              ],
              [
                -46.940486195993046,
                -15.229190271541555
              ],
              [
                -46.92721428296249,
                -15.254647096820362
              ],
              [
                -46.91239192026956,
                -15.24640959805067
              ],
              [
                -46.906614972534065,
                -15.237290172492571
              ],
              [
                -46.89176758330569,
                -15.234877622890044
              ],
              [
                -46.890261544899225,
                -15.259352515973982
              ],
              [
                -46.8894670621211,
                -15.272268940637025
              ],
              [
                -46.88888103751409,
                -15.281797846809313
              ],
              [
                -46.88616954191727,
                -15.28236086846974
              ],
              [
                -46.87336366728678,
                -15.285022337069115
              ],
              [
                -46.83646586146184,
                -15.322149042423344
              ],
              [
                -46.847503750716506,
                -15.346291947471984
              ],
              [
                -46.84875524194113,
                -15.346697445580716
              ],
              [
                -46.8605191326544,
                -15.35051241259377
              ],
              [
                -46.869039525549184,
                -15.361025380721845
              ],
              [
                -46.860240090785084,
                -15.364332865263151
              ],
              [
                -46.850282673357164,
                -15.368074797139338
              ],
              [
                -46.849039688805334,
                -15.372714792642652
              ],
              [
                -46.91255260196826,
                -15.412908678534459
              ],
              [
                -46.930029416292534,
                -15.442642526297673
              ],
              [
                -46.93199129950976,
                -15.482723295389446
              ],
              [
                -46.94377363387154,
                -15.517738654087195
              ],
              [
                -46.94865855496843,
                -15.554147926881312
              ],
              [
                -46.94590507432715,
                -15.559727393342538
              ],
              [
                -46.943151586682426,
                -15.565307355801744
              ],
              [
                -46.9190857013375,
                -15.5883622171681
              ],
              [
                -46.90785228815538,
                -15.590547176119879
              ],
              [
                -46.896618391994735,
                -15.592732149069871
              ],
              [
                -46.88164795724239,
                -15.609094029470967
              ],
              [
                -46.854352187121535,
                -15.619905408917244
              ],
              [
                -46.85671112895484,
                -15.631190849392095
              ],
              [
                -46.85817161984357,
                -15.638175293184782
              ],
              [
                -46.85492763969184,
                -15.644432751884898
              ],
              [
                -46.852512628253784,
                -15.649091750136149
              ],
              [
                -46.85516553932372,
                -15.681720060006251
              ],
              [
                -46.84201410839535,
                -15.714219345845326
              ],
              [
                -46.82702767656182,
                -15.729202226888273
              ],
              [
                -46.82702965316051,
                -15.738968697384994
              ],
              [
                -46.8270316717467,
                -15.748734144900697
              ],
              [
                -46.81271017938132,
                -15.786752396767554
              ],
              [
                -46.82307406568384,
                -15.809676285375883
              ],
              [
                -46.82136206564851,
                -15.81429124229881
              ],
              [
                -46.8205630634988,
                -15.816444751730538
              ],
              [
                -46.821539561560925,
                -15.819850720680584
              ],
              [
                -46.82278204955395,
                -15.824183209996756
              ],
              [
                -46.81940756355477,
                -15.830802660489667
              ],
              [
                -46.80506362577698,
                -15.858941475557794
              ],
              [
                -46.80565106710873,
                -15.868429945906744
              ],
              [
                -46.80751656540522,
                -15.871667406573243
              ],
              [
                -46.808231038892124,
                -15.873951411275206
              ],
              [
                -46.811722511512016,
                -15.885109363099698
              ],
              [
                -46.824426911837435,
                -15.885267365324134
              ],
              [
                -46.87057201712875,
                -15.896056401631581
              ],
              [
                -46.956607304552364,
                -15.91556292695218
              ],
              [
                -47.05234649452741,
                -15.937462980094859
              ],
              [
                -47.0865721877251,
                -15.96039491567801
              ],
              [
                -47.097223108382195,
                -15.95839246397591
              ],
              [
                -47.107873992054486,
                -15.956390481270619
              ],
              [
                -47.118314924144705,
                -15.94888302151154
              ],
              [
                -47.125619885337244,
                -15.943631087050415
              ],
              [
                -47.12729589046834,
                -15.944834083757982
              ],
              [
                -47.13412681179034,
                -15.949737565344746
              ],
              [
                -47.13272081861359,
                -15.942388615065374
              ],
              [
                -47.13107584897937,
                -15.933793131195998
              ],
              [
                -47.132740349856604,
                -15.93140267870076
              ],
              [
                -47.134902839303074,
                -15.92829717314259
              ],
              [
                -47.1415118083451,
                -15.9261851909639
              ],
              [
                -47.13880032330961,
                -15.93067366580094
              ],
              [
                -47.14199077069925,
                -15.93503564810314
              ],
              [
                -47.1453627687533,
                -15.931835685473846
              ],
              [
                -47.15073420233962,
                -15.934553173198552
              ],
              [
                -47.14920522062762,
                -15.938235657844732
              ],
              [
                -47.15216267293743,
                -15.942562639889292
              ],
              [
                -47.1569086615561,
                -15.94055515742464
              ],
              [
                -47.169090011280346,
                -15.953509602352078
              ],
              [
                -47.16601554806274,
                -15.955670108309118
              ],
              [
                -47.16988952162291,
                -15.95787408990621
              ],
              [
                -47.167179021419365,
                -15.962422050439567
              ],
              [
                -47.165686017917906,
                -15.96492654866131
              ],
              [
                -47.169811501468814,
                -15.972752019812203
              ],
              [
                -47.177812913236686,
                -15.976845989274935
              ],
              [
                -47.180018385449095,
                -15.971835522268604
              ],
              [
                -47.180694420367274,
                -15.970300057073688
              ],
              [
                -47.1864923801913,
                -15.97085406161856
              ],
              [
                -47.183849385002596,
                -15.974669039332905
              ],
              [
                -47.182451385778826,
                -15.976686987428993
              ],
              [
                -47.186305339854414,
                -15.978254985509276
              ],
              [
                -47.18481133552082,
                -15.982082463455644
              ],
              [
                -47.19270228077917,
                -15.981216979010526
              ],
              [
                -47.194819277484264,
                -15.980985000523983
              ],
              [
                -47.196142737046884,
                -15.983761996894419
              ],
              [
                -47.197583225154865,
                -15.986784462150862
              ],
              [
                -47.200313731364055,
                -15.986847489271515
              ],
              [
                -47.20735614416671,
                -15.9870099884803
              ],
              [
                -47.20733363258393,
                -15.993437947085692
              ],
              [
                -47.20732166421651,
                -15.996922428901772
              ],
              [
                -47.216349042158484,
                -16.004609890736667
              ],
              [
                -47.21908751775949,
                -16.01379037077857
              ],
              [
                -47.22120448448533,
                -16.011220381146945
              ],
              [
                -47.22790192461496,
                -16.01530787912842
              ],
              [
                -47.22795295839616,
                -16.012424405071332
              ],
              [
                -47.227957433902255,
                -16.01217190946745
              ],
              [
                -47.22851795776122,
                -16.01249689380679
              ],
              [
                -47.233340377536116,
                -16.015290389139253
              ],
              [
                -47.2370068751274,
                -16.013585897860075
              ],
              [
                -47.23810837749422,
                -16.013073902882855
              ],
              [
                -47.24087184942586,
                -16.01408188889337
              ],
              [
                -47.25363322828329,
                -16.018739393940873
              ],
              [
                -47.2574247156742,
                -16.01620644045989
              ],
              [
                -47.261216668062126,
                -16.013673928976417
              ],
              [
                -47.27679504592209,
                -16.011211473774043
              ],
              [
                -47.27609156029867,
                -16.00571201254469
              ],
              [
                -47.28059751871241,
                -16.011252501185147
              ],
              [
                -47.28730348694926,
                -16.009224523780805
              ],
              [
                -47.288400971800236,
                -16.01549097460476
              ],
              [
                -47.300057358103345,
                -16.016599515946584
              ],
              [
                -47.29929885449746,
                -16.022933966683905
              ],
              [
                -47.29896286612215,
                -16.02574193361115
              ],
              [
                -47.30970375835283,
                -16.032118446257847
              ],
              [
                -47.30860927476364,
                -16.03549091157155
              ],
              [
                -47.315240210204216,
                -16.035980923230614
              ],
              [
                -47.31867970142412,
                -16.036235445758578
              ],
              [
                -47.31698170036652,
                -16.031356462077394
              ],
              [
                -47.32067615995983,
                -16.030499977204695
              ],
              [
                -47.32139716045984,
                -16.025754504661435
              ],
              [
                -47.323554650344484,
                -16.02856047762467
              ],
              [
                -47.32743864192094,
                -16.027016010902464
              ],
              [
                -47.32795861025036,
                -16.026808975083053
              ],
              [
                -47.32883761617598,
                -16.02546848519687
              ],
              [
                -47.334786600590824,
                -16.016400060856576
              ],
              [
                -47.336338566645054,
                -16.017173561738826
              ],
              [
                -47.3390885589345,
                -16.018544069759795
              ],
              [
                -47.338063072098045,
                -16.015266092659513
              ],
              [
                -47.33690358023384,
                -16.011563077621567
              ],
              [
                -47.34276451806831,
                -16.01099762050383
              ],
              [
                -47.344315522117554,
                -16.010847615444284
              ],
              [
                -47.34520352642119,
                -16.00929813072054
              ],
              [
                -47.34734551650306,
                -16.00556116850511
              ],
              [
                -47.350022003509736,
                -16.005941143802968
              ],
              [
                -47.35940341642467,
                -16.007274160360357
              ],
              [
                -47.363617879084224,
                -16.000295200068315
              ],
              [
                -47.36820538024142,
                -16.001485204770606
              ],
              [
                -47.368120862800744,
                -15.993622768676671
              ],
              [
                -47.370981342090516,
                -15.994128766652357
              ],
              [
                -47.37540380520267,
                -15.986171816134753
              ],
              [
                -47.37110989735229,
                -15.980024853197197
              ],
              [
                -47.371066872824514,
                -15.979963334448
              ],
              [
                -47.37112237243069,
                -15.979942341674466
              ],
              [
                -47.37644984859436,
                -15.977941864334747
              ],
              [
                -47.3732148807849,
                -15.966466893741057
              ],
              [
                -47.37315637269239,
                -15.966259391761277
              ],
              [
                -47.3732684021023,
                -15.966121408742529
              ],
              [
                -47.37629688414236,
                -15.962395417245547
              ],
              [
                -47.3735628982642,
                -15.96122692216853
              ],
              [
                -47.37178691093273,
                -15.960468441765634
              ],
              [
                -47.367095003017695,
                -15.940393052477354
              ],
              [
                -47.37109994873521,
                -15.936941548435618
              ],
              [
                -47.37206644987549,
                -15.936108555941473
              ],
              [
                -47.370978942251355,
                -15.93574505475827
              ],
              [
                -47.36278701287386,
                -15.933010059365776
              ],
              [
                -47.36304204862179,
                -15.929846107241625
              ],
              [
                -47.36034205297804,
                -15.931246564150968
              ],
              [
                -47.36131405076819,
                -15.919325164539542
              ],
              [
                -47.36523801425424,
                -15.920489643995209
              ],
              [
                -47.36711751835909,
                -15.917229182653966
              ],
              [
                -47.36323057664842,
                -15.908538194602881
              ],
              [
                -47.36933452365849,
                -15.907007238210644
              ],
              [
                -47.37226500106085,
                -15.906272245104075
              ],
              [
                -47.37073353917495,
                -15.899708286088865
              ],
              [
                -47.375256492833365,
                -15.896234286204077
              ],
              [
                -47.375757043950614,
                -15.88025189799401
              ],
              [
                -47.37234156677627,
                -15.875167910113095
              ],
              [
                -47.37012709230167,
                -15.871870431816944
              ],
              [
                -47.36923060166829,
                -15.864691963485157
              ],
              [
                -47.37076259503764,
                -15.863997469357013
              ],
              [
                -47.372317610245574,
                -15.863293471326852
              ],
              [
                -47.36921214270518,
                -15.857692978925042
              ],
              [
                -47.36662515507287,
                -15.85302802742045
              ],
              [
                -47.36725417510541,
                -15.849733522790226
              ],
              [
                -47.36896316460151,
                -15.840791578372198
              ],
              [
                -47.36415018431165,
                -15.836238092841015
              ],
              [
                -47.36551021961137,
                -15.827736662322067
              ],
              [
                -47.36101172826249,
                -15.8262766714095
              ],
              [
                -47.35962478717902,
                -15.820170182260826
              ],
              [
                -47.35697879562747,
                -15.820860688200078
              ],
              [
                -47.353425325747274,
                -15.82178816603085
              ],
              [
                -47.35216584651121,
                -15.81338919876847
              ],
              [
                -47.351922839866646,
                -15.811768731208435
              ],
              [
                -47.350248854970935,
                -15.810413237347323
              ],
              [
                -47.34659338013638,
                -15.80745373538593
              ],
              [
                -47.34723991365255,
                -15.80574176209891
              ],
              [
                -47.34814588257812,
                -15.80334024512512
              ],
              [
                -47.343341459008805,
                -15.798875769181379
              ],
              [
                -47.3382889748866,
                -15.794180809024443
              ],
              [
                -47.33483252327742,
                -15.783276340269499
              ],
              [
                -47.333812056152134,
                -15.780056360997587
              ],
              [
                -47.33296707425478,
                -15.779856354425483
              ],
              [
                -47.32940158722512,
                -15.779012875009663
              ],
              [
                -47.32860011503016,
                -15.770271923622538
              ],
              [
                -47.32829563345429,
                -15.766949419343993
              ],
              [
                -47.326541661879205,
                -15.764790447779411
              ],
              [
                -47.31885123666138,
                -15.755322473794843
              ],
              [
                -47.31623174142058,
                -15.745341038702787
              ],
              [
                -47.313709790323706,
                -15.746781529764867
              ],
              [
                -47.312480802302204,
                -15.737641554795426
              ],
              [
                -47.3166492899826,
                -15.734016076050137
              ],
              [
                -47.3183992773524,
                -15.732494577908582
              ],
              [
                -47.3168962693898,
                -15.729571622079382
              ],
              [
                -47.31593878288285,
                -15.727708125478928
              ],
              [
                -47.31715180804026,
                -15.724978132951021
              ],
              [
                -47.31942977682063,
                -15.719852177758767
              ],
              [
                -47.31761381886413,
                -15.716673184733207
              ],
              [
                -47.31192735575021,
                -15.706718244513018
              ],
              [
                -47.312265873596324,
                -15.694094784946357
              ],
              [
                -47.31931684269511,
                -15.686554864517609
              ],
              [
                -47.32010835525564,
                -15.686642384593164
              ],
              [
                -47.32756528859231,
                -15.687466873728544
              ],
              [
                -47.33109776676574,
                -15.681232400144824
              ],
              [
                -47.32653882674133,
                -15.673153453826316
              ],
              [
                -47.32875731728947,
                -15.655465059004491
              ],
              [
                -47.32756883447265,
                -15.652222076604913
              ],
              [
                -47.32615288075059,
                -15.648358608193274
              ],
              [
                -47.329629375342165,
                -15.638297155691411
              ],
              [
                -47.33268236570257,
                -15.62946269557366
              ],
              [
                -47.32225899439764,
                -15.598171354381652
              ],
              [
                -47.32204098648614,
                -15.597516353582963
              ],
              [
                -47.31543007210147,
                -15.593973863259151
              ],
              [
                -47.321265508992695,
                -15.585822901852271
              ],
              [
                -47.32539600541597,
                -15.588676921126138
              ],
              [
                -47.34095987748079,
                -15.58292648146126
              ],
              [
                -47.353238795803755,
                -15.578476017155863
              ],
              [
                -47.35460976413436,
                -15.579361533936131
              ],
              [
                -47.3569527494843,
                -15.580874028148427
              ],
              [
                -47.37414510202689,
                -15.577876553418482
              ],
              [
                -47.37738908903473,
                -15.571827588260177
              ],
              [
                -47.38001308751967,
                -15.566936622477217
              ],
              [
                -47.38553905286488,
                -15.566961161159655
              ],
              [
                -47.389179018113,
                -15.566976646200553
              ],
              [
                -47.394551995178,
                -15.559558701783526
              ],
              [
                -47.404375938926236,
                -15.545995781094536
              ],
              [
                -47.410831900610816,
                -15.546187804659628
              ],
              [
                -47.417289326287126,
                -15.546380804224057
              ],
              [
                -47.41610841067541,
                -15.52037995788102
              ],
              [
                -47.41650740283805,
                -15.510121025489441
              ],
              [
                -47.41690644298272,
                -15.499863065106101
              ],
              [
                -47.46141409978342,
                -15.500078131772923
              ],
              [
                -47.46580804312529,
                -15.500099678232447
              ],
              [
                -47.495515290693874,
                -15.499848699639841
              ],
              [
                -47.52712756417582,
                -15.499699754236312
              ],
              [
                -47.61869683790587,
                -15.50029992105853
              ],
              [
                -48.0341335765238,
                -15.50109068220475
              ],
              [
                -48.052785944841304,
                -15.499745711355363
              ],
              [
                -48.130852794323076,
                -15.499760834426588
              ],
              [
                -48.19719228530369,
                -15.500510966507264
              ],
              [
                -48.19770826813022,
                -15.512461893464408
              ],
              [
                -48.19916669952951,
                -15.546251231507181
              ],
              [
                -48.19987205691744,
                -15.62141179053397
              ],
              [
                -48.20919498652818,
                -15.621797823026382
              ],
              [
                -48.21851690715917,
                -15.622183333523614
              ],
              [
                -48.23253773397044,
                -15.63869228696325
              ],
              [
                -48.23341673429856,
                -15.643784743110926
              ],
              [
                -48.23597518282069,
                -15.658615174846837
              ],
              [
                -48.24037306735187,
                -15.706534414171257
              ],
              [
                -48.21767373079998,
                -15.707583869973089
              ],
              [
                -48.20704130455244,
                -15.717893790832887
              ],
              [
                -48.20644027829754,
                -15.727682721635937
              ],
              [
                -48.2056202721722,
                -15.741003181901027
              ],
              [
                -48.208567248842535,
                -15.748182129981464
              ],
              [
                -48.21151418450689,
                -15.755360106079728
              ],
              [
                -48.22563955518971,
                -15.770889531540375
              ],
              [
                -48.23576445344565,
                -15.78202050179058
              ],
              [
                -48.237819942585006,
                -15.784280470725522
              ],
              [
                -48.23897689722947,
                -15.78862893757271
              ],
              [
                -48.241532852664236,
                -15.7982369138485
              ],
              [
                -48.24570133492539,
                -15.79651041657682
              ],
              [
                -48.26746613611065,
                -15.806451432852322
              ],
              [
                -48.28241748597586,
                -15.829078818061214
              ],
              [
                -48.286223924922034,
                -15.841322761788915
              ],
              [
                -48.286647910675356,
                -15.842687258296479
              ],
              [
                -48.28242295179572,
                -15.85738166259443
              ],
              [
                -48.27905095167187,
                -15.869109123610842
              ],
              [
                -48.27764745174873,
                -15.873659085268383
              ],
              [
                -48.272013484275206,
                -15.880278009208647
              ],
              [
                -48.27520494866476,
                -15.8875564915046
              ],
              [
                -48.27524191619385,
                -15.887640985124827
              ],
              [
                -48.276759900871426,
                -15.891104496565772
              ],
              [
                -48.2739819072435,
                -15.892605950846686
              ],
              [
                -48.27690288272748,
                -15.90587590237942
              ],
              [
                -48.270181930387636,
                -15.911252860798134
              ],
              [
                -48.27284089852727,
                -15.920537806262127
              ],
              [
                -48.27651334033781,
                -15.92219330682753
              ],
              [
                -48.2763158317125,
                -15.9300927689529
              ],
              [
                -48.2677593803076,
                -15.932408248094342
              ],
              [
                -48.262971439975146,
                -15.929932227668283
              ],
              [
                -48.26073645289294,
                -15.928776239339156
              ],
              [
                -48.25204450622457,
                -15.936309666140794
              ],
              [
                -48.25171550735157,
                -15.940190649599186
              ],
              [
                -48.25151100570947,
                -15.94259863323372
              ],
              [
                -48.25208898014994,
                -15.953658580944412
              ],
              [
                -48.257527408842016,
                -15.961901047720954
              ],
              [
                -48.260402397958465,
                -15.966257527170862
              ],
              [
                -48.260651869191705,
                -15.966635544648296
              ],
              [
                -48.260224885884455,
                -15.971975015090342
              ],
              [
                -48.259798366569385,
                -15.977314465536736
              ],
              [
                -48.26432632823952,
                -15.98834493153625
              ],
              [
                -48.272404216343126,
                -16.008024348404017
              ],
              [
                -48.27179170899901,
                -16.01814129587197
              ],
              [
                -48.271179175638736,
                -16.02825873234986
              ],
              [
                -48.27867409021901,
                -16.05110112623863
              ],
              [
                -48.268352148984,
                -16.05094408792477
              ],
              [
                -48.25803073575665,
                -16.05078809361071
              ],
              [
                -48.16145148105207,
                -16.050787893892974
              ],
              [
                -48.086235074446975,
                -16.051308737340168
              ],
              [
                -48.051469872460416,
                -16.051217695834886
              ],
              [
                -48.02185207632702,
                -16.05113963680689
              ],
              [
                -47.98246039391709,
                -16.051023583086167
              ],
              [
                -47.93875574253867,
                -16.05089347971175
              ],
              [
                -47.81435372185951,
                -16.05020726526191
              ],
              [
                -47.68303177226522,
                -16.05017451681686
              ],
              [
                -47.61724529676963,
                -16.049947395724594
              ],
              [
                -47.537066395771305,
                -16.049536763683395
              ],
              [
                -47.46259702608174,
                -16.04979112678674
              ],
              [
                -47.43590722449843,
                -16.049536569284392
              ],
              [
                -47.425937301848975,
                -16.049741542039737
              ],
              [
                -47.415967378211725,
                -16.04994701079553
              ],
              [
                -47.31824165111172,
                -16.05006582910756
              ],
              [
                -47.30836671828127,
                -16.050077809151272
              ],
              [
                -47.30394974439741,
                -16.060225781659394
              ],
              [
                -47.315289124284575,
                -16.066862749168255
              ],
              [
                -47.31027817248594,
                -16.067086223840956
              ],
              [
                -47.307761197046034,
                -16.0783841719028
              ],
              [
                -47.3162156183807,
                -16.079175171631217
              ],
              [
                -47.310724157828886,
                -16.081511144762835
              ],
              [
                -47.310898635128446,
                -16.087679136353692
              ],
              [
                -47.32189053418967,
                -16.08994112416902
              ],
              [
                -47.32287355951461,
                -16.087113166627653
              ],
              [
                -47.323690558126444,
                -16.084760658149893
              ],
              [
                -47.328860508919675,
                -16.089977134048354
              ],
              [
                -47.33269046141142,
                -16.085433687638993
              ],
              [
                -47.33670042100244,
                -16.093143647564492
              ],
              [
                -47.331126487602006,
                -16.093852625425562
              ],
              [
                -47.33131195264618,
                -16.101940595564933
              ],
              [
                -47.33142892372364,
                -16.10702407900312
              ],
              [
                -47.3376218656948,
                -16.113027533705363
              ],
              [
                -47.338524874649664,
                -16.11390302474639
              ],
              [
                -47.33834188925424,
                -16.114293020244443
              ],
              [
                -47.336115865612506,
                -16.119034997821196
              ],
              [
                -47.34104132467883,
                -16.12131299333916
              ],
              [
                -47.33731636961987,
                -16.128030446090452
              ],
              [
                -47.34424230913331,
                -16.1297129759217
              ],
              [
                -47.35162623178423,
                -16.13150698007669
              ],
              [
                -47.34586776383648,
                -16.140055889716727
              ],
              [
                -47.34992872615104,
                -16.147674864342015
              ],
              [
                -47.34419623757785,
                -16.14765785581277
              ],
              [
                -47.34051230777336,
                -16.147646862404592
              ],
              [
                -47.34418773037833,
                -16.15100586051354
              ],
              [
                -47.34817921885554,
                -16.15465233469916
              ],
              [
                -47.343368757607344,
                -16.155729808059675
              ],
              [
                -47.33998179777956,
                -16.15648932604318
              ],
              [
                -47.34202473970816,
                -16.159663271858275
              ],
              [
                -47.344566235002176,
                -16.163612785454674
              ],
              [
                -47.34075726069202,
                -16.163370779046684
              ],
              [
                -47.34023777587891,
                -16.163337263175524
              ],
              [
                -47.331511306723506,
                -16.171285681075044
              ],
              [
                -47.33095881504098,
                -16.17913265612031
              ],
              [
                -47.33459476831519,
                -16.18139713814571
              ],
              [
                -47.34006619356505,
                -16.184804656666383
              ],
              [
                -47.33365175578346,
                -16.184156156173888
              ],
              [
                -47.33034180273069,
                -16.18382162127603
              ],
              [
                -47.33087778814107,
                -16.189035593911957
              ],
              [
                -47.32979526059758,
                -16.19414457983553
              ],
              [
                -47.32951729294586,
                -16.19545555711783
              ],
              [
                -47.330190259305006,
                -16.1960570452041
              ],
              [
                -47.33587721719263,
                -16.20113652705731
              ],
              [
                -47.3295707477515,
                -16.20499350819435
              ],
              [
                -47.32329081513994,
                -16.208834491470277
              ],
              [
                -47.32899475620279,
                -16.213305453688754
              ],
              [
                -47.32974525997482,
                -16.213893476009158
              ],
              [
                -47.32881225790254,
                -16.21594146894229
              ],
              [
                -47.322015777211035,
                -16.230863381743116
              ],
              [
                -47.32849269803431,
                -16.248655270970833
              ],
              [
                -47.33047515884032,
                -16.248419271299074
              ],
              [
                -47.33338864151207,
                -16.24807228013054
              ],
              [
                -47.33435412204919,
                -16.25667876321601
              ],
              [
                -47.33446011714731,
                -16.257621747296064
              ],
              [
                -47.335121592394756,
                -16.258312732882185
              ],
              [
                -47.35041144528656,
                -16.274289193116452
              ],
              [
                -47.347668460488606,
                -16.28503060205776
              ],
              [
                -47.34721545618794,
                -16.28680561847352
              ],
              [
                -47.349219450962565,
                -16.288332599262652
              ],
              [
                -47.35576086120534,
                -16.29331757052274
              ],
              [
                -47.3542843810554,
                -16.299175045644382
              ],
              [
                -47.35252636367709,
                -16.306151019110292
              ],
              [
                -47.35765982896151,
                -16.310724974751082
              ],
              [
                -47.36582823059164,
                -16.33104440406161
              ],
              [
                -47.36954018921512,
                -16.332393886342608
              ],
              [
                -47.370860163702005,
                -16.3328738754424
              ],
              [
                -47.37481114584404,
                -16.340398871679163
              ],
              [
                -47.377973111540754,
                -16.346419353479664
              ],
              [
                -47.40317281810376,
                -16.375372203183307
              ],
              [
                -47.40789128778409,
                -16.37716222818686
              ],
              [
                -47.417459722997485,
                -16.380792728201282
              ],
              [
                -47.41887667053498,
                -16.390406162026853
              ],
              [
                -47.42672760507788,
                -16.393705680326118
              ],
              [
                -47.42328459138135,
                -16.418990010313536
              ],
              [
                -47.42317959450166,
                -16.419762501915134
              ],
              [
                -47.424433075839545,
                -16.42280300004362
              ],
              [
                -47.428652516737,
                -16.433039471357688
              ],
              [
                -47.4313934732827,
                -16.439689418042537
              ],
              [
                -47.42907397806808,
                -16.442205390639582
              ],
              [
                -47.439033413751154,
                -16.44736589731445
              ],
              [
                -47.44500786057249,
                -16.450462377925508
              ],
              [
                -47.44624084159116,
                -16.457313858438066
              ],
              [
                -47.447487292321,
                -16.464243815563037
              ],
              [
                -47.449684280380055,
                -16.465389314920436
              ],
              [
                -47.453622245636005,
                -16.467443323974468
              ],
              [
                -47.455706183727536,
                -16.48695070892326
              ],
              [
                -47.4544676896716,
                -16.488235722416874
              ],
              [
                -47.45254221047173,
                -16.490231702647066
              ],
              [
                -47.45470819037566,
                -16.494696659029827
              ],
              [
                -47.458135635485306,
                -16.501765138981455
              ],
              [
                -47.447208213239556,
                -16.516910039561
              ],
              [
                -47.44489870454835,
                -16.5289989545309
              ],
              [
                -47.441676237639555,
                -16.528744946206633
              ],
              [
                -47.443964682678384,
                -16.54031088756523
              ],
              [
                -47.43768622352373,
                -16.54160389953797
              ],
              [
                -47.43112175923337,
                -16.54084137800621
              ],
              [
                -47.430096786621064,
                -16.540721861520655
              ],
              [
                -47.430645263983024,
                -16.54130038253925
              ],
              [
                -47.433590258721445,
                -16.544401374931294
              ],
              [
                -47.42859128451368,
                -16.551923830956838
              ],
              [
                -47.425927303653154,
                -16.54788381821165
              ],
              [
                -47.42041436301054,
                -16.551162317060424
              ],
              [
                -47.42012135594253,
                -16.55133678150983
              ],
              [
                -47.413068401039496,
                -16.54766678764985
              ],
              [
                -47.40543344963983,
                -16.555235255932445
              ],
              [
                -47.411922915937616,
                -16.5580372263086
              ],
              [
                -47.412122415855166,
                -16.55915574268876
              ],
              [
                -47.41259336622611,
                -16.561792223444588
              ],
              [
                -47.410269423546126,
                -16.56094271219328
              ],
              [
                -47.40881242328788,
                -16.560409250040713
              ],
              [
                -47.40960139840197,
                -16.561586226331748
              ],
              [
                -47.4119273782081,
                -16.565054223461768
              ],
              [
                -47.40871892369369,
                -16.566371204681158
              ],
              [
                -47.4124268520854,
                -16.57540464567453
              ],
              [
                -47.40039896544433,
                -16.571826133939553
              ],
              [
                -47.398424979898394,
                -16.57236065694767
              ],
              [
                -47.39356849545932,
                -16.573676630740735
              ],
              [
                -47.394100486347625,
                -16.574969625871795
              ],
              [
                -47.395980491208604,
                -16.579541097123077
              ],
              [
                -47.38645304290664,
                -16.588407023458778
              ],
              [
                -47.38650154476354,
                -16.579366577332056
              ],
              [
                -47.38650606650484,
                -16.57857258362558
              ],
              [
                -47.376074617541065,
                -16.579776562417553
              ],
              [
                -47.37597061964311,
                -16.585460522528443
              ],
              [
                -47.37572710174928,
                -16.59877644318126
              ],
              [
                -47.36653067354194,
                -16.6006344229982
              ],
              [
                -47.36422570709804,
                -16.59426496256404
              ],
              [
                -47.362481732169634,
                -16.593266947318032
              ],
              [
                -47.35528076053497,
                -16.589144472576148
              ],
              [
                -47.35034083633433,
                -16.592896443039717
              ],
              [
                -47.34240885966926,
                -16.592723944459475
              ],
              [
                -47.34250636775341,
                -16.597700380796265
              ],
              [
                -47.3365149197183,
                -16.59751738231023
              ],
              [
                -47.338033400535984,
                -16.60337987282293
              ],
              [
                -47.33435142091131,
                -16.606884849234202
              ],
              [
                -47.333461939074674,
                -16.607731823809473
              ],
              [
                -47.33750889916222,
                -16.61120680348401
              ],
              [
                -47.333897906575224,
                -16.61667277646012
              ],
              [
                -47.326625464307085,
                -16.6104282940129
              ],
              [
                -47.318093563457154,
                -16.603102809799907
              ],
              [
                -47.314962063328316,
                -16.607119295589293
              ],
              [
                -47.31867552960925,
                -16.615703248974235
              ],
              [
                -47.31952998839546,
                -16.617679246087043
              ],
              [
                -47.317242530677696,
                -16.616734260422593
              ],
              [
                -47.315826067235754,
                -16.616149749627272
              ],
              [
                -47.304015138291454,
                -16.623621169679048
              ],
              [
                -47.30471363397819,
                -16.625746663658674
              ],
              [
                -47.30665559329402,
                -16.631659140788187
              ],
              [
                -47.30221713278118,
                -16.627542678758903
              ],
              [
                -47.301147122507196,
                -16.62654964989046
              ],
              [
                -47.29423420341572,
                -16.626106647877016
              ],
              [
                -47.29380518703597,
                -16.63359360955526
              ],
              [
                -47.29364917517537,
                -16.636313117547225
              ],
              [
                -47.291326202834796,
                -16.635776590600965
              ],
              [
                -47.28626624066246,
                -16.634606590370563
              ],
              [
                -47.28322527107869,
                -16.639398059157887
              ],
              [
                -47.285187716467355,
                -16.642080053768584
              ],
              [
                -47.28656624028459,
                -16.643964550469203
              ],
              [
                -47.28098674616732,
                -16.646395030710753
              ],
              [
                -47.27786929989322,
                -16.647753492874273
              ],
              [
                -47.277683303804864,
                -16.649787989501945
              ],
              [
                -47.27678924789853,
                -16.659590931717197
              ],
              [
                -47.27151179661985,
                -16.663252897935635
              ],
              [
                -47.26719235799768,
                -16.66056293144168
              ],
              [
                -47.265396359787864,
                -16.659443912735327
              ],
              [
                -47.263457868830706,
                -16.661833898786572
              ],
              [
                -47.258924408825905,
                -16.65517393729002
              ],
              [
                -47.25340496267428,
                -16.663206375397962
              ],
              [
                -47.259370902968335,
                -16.667333843563235
              ],
              [
                -47.249708944981805,
                -16.66635937065898
              ],
              [
                -47.247959972183736,
                -16.674685322051914
              ],
              [
                -47.255043926066634,
                -16.677259313948507
              ],
              [
                -47.25850838668777,
                -16.678518802386037
              ],
              [
                -47.25478339482358,
                -16.686293245645995
              ],
              [
                -47.252535413209955,
                -16.685177271971853
              ],
              [
                -47.24615646906217,
                -16.682009745743635
              ],
              [
                -47.2452564475869,
                -16.70051515698869
              ],
              [
                -47.24719542274604,
                -16.702033674849776
              ],
              [
                -47.2510343634409,
                -16.705041137653502
              ],
              [
                -47.248293912930556,
                -16.70828511542184
              ],
              [
                -47.24475992470986,
                -16.706211621215537
              ],
              [
                -47.24110043865438,
                -16.704064146148834
              ],
              [
                -47.22720504401779,
                -16.71302656472659
              ],
              [
                -47.222905074543064,
                -16.7222100111779
              ],
              [
                -47.22269556233652,
                -16.72925195774818
              ],
              [
                -47.22264507688585,
                -16.730960470426098
              ],
              [
                -47.23064898652127,
                -16.733918968229517
              ],
              [
                -47.226092990280804,
                -16.74122992326061
              ],
              [
                -47.23406194310371,
                -16.74422641280252
              ],
              [
                -47.23554340814412,
                -16.75492936421729
              ],
              [
                -47.23164942022685,
                -16.76462229581486
              ],
              [
                -47.222982979562566,
                -16.761365288187527
              ],
              [
                -47.22947944304602,
                -16.767033278270397
              ],
              [
                -47.23045839934612,
                -16.774952239659402
              ],
              [
                -47.22234495967232,
                -16.77771619573578
              ],
              [
                -47.216786488636956,
                -16.779609657864636
              ],
              [
                -47.21861993479026,
                -16.79650760468839
              ],
              [
                -47.21522994909139,
                -16.80166756177496
              ],
              [
                -47.21687096014448,
                -16.80338056599993
              ],
              [
                -47.21973441357384,
                -16.806369033928995
              ],
              [
                -47.20997499359943,
                -16.81637445153473
              ],
              [
                -47.20703751316268,
                -16.819385924141958
              ],
              [
                -47.208046507716475,
                -16.820007950915958
              ],
              [
                -47.212339432530975,
                -16.82265242071348
              ],
              [
                -47.21169445983564,
                -16.827425899663247
              ],
              [
                -47.2046475022585,
                -16.826411912288908
              ],
              [
                -47.19307759918847,
                -16.824748391899224
              ],
              [
                -47.203737485764236,
                -16.837312360702686
              ],
              [
                -47.20551697282889,
                -16.83940931816566
              ],
              [
                -47.20312147410158,
                -16.848128799202215
              ],
              [
                -47.19992595111833,
                -16.85975969889692
              ],
              [
                -47.20237643431988,
                -16.86120071231308
              ],
              [
                -47.20889589366456,
                -16.865035184441826
              ],
              [
                -47.20726036164635,
                -16.87560062018543
              ],
              [
                -47.20122342712504,
                -16.874992122716044
              ],
              [
                -47.20046041837799,
                -16.874915636517084
              ],
              [
                -47.200459952898235,
                -16.876885102926916
              ],
              [
                -47.20045793860876,
                -16.881850099229926
              ],
              [
                -47.19836494675964,
                -16.8820805945723
              ],
              [
                -47.1927334812191,
                -16.88270056635106
              ],
              [
                -47.19417948316968,
                -16.88688355591495
              ],
              [
                -47.196139941918425,
                -16.88759903520839
              ],
              [
                -47.2019258720042,
                -16.88971155107282
              ],
              [
                -47.20090289595969,
                -16.8941850128701
              ],
              [
                -47.19321543983318,
                -16.897862504864904
              ],
              [
                -47.196277385948825,
                -16.909036947290456
              ],
              [
                -47.18895294956047,
                -16.905424437218187
              ],
              [
                -47.18162804117868,
                -16.901811930155255
              ],
              [
                -47.17243008649606,
                -16.91803634151223
              ],
              [
                -47.16633210786415,
                -16.91507786552135
              ],
              [
                -47.15979667203007,
                -16.92162879049192
              ],
              [
                -47.167673069482234,
                -16.927649273793794
              ],
              [
                -47.17392800769081,
                -16.93243077733858
              ],
              [
                -47.16906955839363,
                -16.935072760863772
              ],
              [
                -47.16789805980432,
                -16.940732228982213
              ],
              [
                -47.163951605287814,
                -16.93766273611792
              ],
              [
                -47.16189110263943,
                -16.938573215865325
              ],
              [
                -47.156483645305634,
                -16.94096269658222
              ],
              [
                -47.15936610667092,
                -16.944308657707857
              ],
              [
                -47.1661450246155,
                -16.9521791357905
              ],
              [
                -47.155049136682884,
                -16.954115626886747
              ],
              [
                -47.15220816920972,
                -16.95461162220517
              ],
              [
                -47.153712140401986,
                -16.95715261374273
              ],
              [
                -47.15790558774181,
                -16.964238549575583
              ],
              [
                -47.151205129774624,
                -16.967042059320114
              ],
              [
                -47.149226638808756,
                -16.966495541061644
              ],
              [
                -47.14373470909767,
                -16.964977027578584
              ],
              [
                -47.142171190342836,
                -16.97163198052328
              ],
              [
                -47.1261608057067,
                -16.980105912071558
              ],
              [
                -47.131094242175585,
                -16.991088887556348
              ],
              [
                -47.135599219163026,
                -16.992423881948117
              ],
              [
                -47.15067957157899,
                -16.99689438796394
              ],
              [
                -47.13973068235538,
                -16.99671533568149
              ],
              [
                -47.13984015473971,
                -17.004015805735452
              ],
              [
                -47.13984365616807,
                -17.0042528124711
              ],
              [
                -47.139960157301395,
                -17.004225335865943
              ],
              [
                -47.14370311944713,
                -17.00334682752845
              ],
              [
                -47.144320084947935,
                -17.00926381809293
              ],
              [
                -47.14245363441335,
                -17.008579291800437
              ],
              [
                -47.13234570058452,
                -17.004873798216856
              ],
              [
                -47.13131522110178,
                -17.009977275640477
              ],
              [
                -47.143949565398906,
                -17.024727711361642
              ],
              [
                -47.1485375401877,
                -17.02368172972583
              ],
              [
                -47.146537550884524,
                -17.03032467785383
              ],
              [
                -47.15168299172151,
                -17.031673674567
              ],
              [
                -47.153548511386106,
                -17.02795321148024
              ],
              [
                -47.154594480835684,
                -17.025866216893522
              ],
              [
                -47.156288951516366,
                -17.03273970465194
              ],
              [
                -47.156651471247365,
                -17.034208208552254
              ],
              [
                -47.15721794935273,
                -17.033501213546995
              ],
              [
                -47.15998342922267,
                -17.030049196933376
              ],
              [
                -47.16072740292526,
                -17.03499069803209
              ],
              [
                -47.16129892209953,
                -17.036410694638615
              ],
              [
                -47.16304688516884,
                -17.040751154101045
              ],
              [
                -47.1657883729178,
                -17.039611165045432
              ],
              [
                -47.168015352281564,
                -17.038684663750445
              ],
              [
                -47.167617335325446,
                -17.04091519295271
              ],
              [
                -47.166526360948694,
                -17.04703415584933
              ],
              [
                -47.17154031459365,
                -17.043711177327808
              ],
              [
                -47.178594256079485,
                -17.03903621139053
              ],
              [
                -47.17986626010538,
                -17.03461474378311
              ],
              [
                -47.18400475005422,
                -17.035502250839794
              ],
              [
                -47.18153573045321,
                -17.050836138462362
              ],
              [
                -47.18129022289834,
                -17.052361651771086
              ],
              [
                -47.175780754699005,
                -17.053729624705614
              ],
              [
                -47.18164969437807,
                -17.063495585932483
              ],
              [
                -47.18698067928719,
                -17.05723212982558
              ],
              [
                -47.1898436172114,
                -17.06124161346935
              ],
              [
                -47.19445358737555,
                -17.05996911211005
              ],
              [
                -47.19576007378766,
                -17.059608127827918
              ],
              [
                -47.19576958174426,
                -17.060895598958435
              ],
              [
                -47.195810581242014,
                -17.06617657778779
              ],
              [
                -47.19119412746164,
                -17.066003598865667
              ],
              [
                -47.190594122951694,
                -17.069167562655494
              ],
              [
                -47.197649056604384,
                -17.07180206461384
              ],
              [
                -47.20526898056084,
                -17.067585598429645
              ],
              [
                -47.20742350112922,
                -17.06639362440301
              ],
              [
                -47.20870248060202,
                -17.070004081820834
              ],
              [
                -47.20992995882132,
                -17.07346807091495
              ],
              [
                -47.20723346448806,
                -17.079896057783987
              ],
              [
                -47.218229867148686,
                -17.0767140762792
              ],
              [
                -47.223344350789525,
                -17.075233602115755
              ],
              [
                -47.223341331346546,
                -17.079767067875075
              ],
              [
                -47.22334030200165,
                -17.082085041483012
              ],
              [
                -47.225237828896546,
                -17.08077607353298
              ],
              [
                -47.228431306523824,
                -17.078573584126715
              ],
              [
                -47.23373324959079,
                -17.081893588710514
              ],
              [
                -47.231732262141946,
                -17.084231068944035
              ],
              [
                -47.23030277867815,
                -17.08590155596283
              ],
              [
                -47.22394479582191,
                -17.085271556743454
              ],
              [
                -47.2292877661714,
                -17.089190016221863
              ],
              [
                -47.23478274107503,
                -17.08585407779067
              ],
              [
                -47.23910267869996,
                -17.08323156203495
              ],
              [
                -47.24265016217814,
                -17.086367568862997
              ],
              [
                -47.24728263094296,
                -17.081310598779112
              ],
              [
                -47.24758512728693,
                -17.09266555877471
              ],
              [
                -47.24772959457787,
                -17.098095513086818
              ],
              [
                -47.25387254417735,
                -17.09785452551511
              ],
              [
                -47.254512020560185,
                -17.09635054091312
              ],
              [
                -47.256070038623065,
                -17.092684583819686
              ],
              [
                -47.25889399150178,
                -17.100300538200937
              ],
              [
                -47.26401047417832,
                -17.097082070329463
              ],
              [
                -47.265022417625524,
                -17.108094994718208
              ],
              [
                -47.26548693822122,
                -17.113148466748118
              ],
              [
                -47.26725790848156,
                -17.11082900191647
              ],
              [
                -47.27314636242237,
                -17.103116558676618
              ],
              [
                -47.281510299578784,
                -17.11443350521678
              ],
              [
                -47.27470233801434,
                -17.117369453971275
              ],
              [
                -47.27516730305359,
                -17.120500472268155
              ],
              [
                -47.2754823026194,
                -17.12262494861312
              ],
              [
                -47.276846310916234,
                -17.122553961915088
              ],
              [
                -47.28172528162945,
                -17.122301939716497
              ],
              [
                -47.27864678446753,
                -17.124755418034947
              ],
              [
                -47.27590230926426,
                -17.126941911492594
              ],
              [
                -47.28297573554003,
                -17.13011892772007
              ],
              [
                -47.28226322883966,
                -17.14363983012373
              ],
              [
                -47.29320762726226,
                -17.139067901973615
              ],
              [
                -47.2953721101191,
                -17.138163887435407
              ],
              [
                -47.30372906727041,
                -17.146467859136646
              ],
              [
                -47.30336452385845,
                -17.148755868167772
              ],
              [
                -47.3025305263377,
                -17.15399034349979
              ],
              [
                -47.30692697071223,
                -17.15855330564904
              ],
              [
                -47.308806984866855,
                -17.152045355339517
              ],
              [
                -47.31059449800615,
                -17.14585889612239
              ],
              [
                -47.323235387702276,
                -17.155169865706444
              ],
              [
                -47.32723232883893,
                -17.14787390113029
              ],
              [
                -47.33113480005294,
                -17.15850337394514
              ],
              [
                -47.33345928481858,
                -17.16483332026416
              ],
              [
                -47.3415347142764,
                -17.16537134577113
              ],
              [
                -47.35154011608164,
                -17.166037872750668
              ],
              [
                -47.348039619293765,
                -17.175764287500442
              ],
              [
                -47.35275810739851,
                -17.17977127535707
              ],
              [
                -47.34836563073984,
                -17.182184283072072
              ],
              [
                -47.33972816144954,
                -17.18692722425834
              ],
              [
                -47.34234166659824,
                -17.18909070838646
              ],
              [
                -47.34917760092952,
                -17.185765245786197
              ],
              [
                -47.349923583400184,
                -17.186018746045587
              ],
              [
                -47.35622854221241,
                -17.18816324923026
              ],
              [
                -47.36259646849529,
                -17.186033282267267
              ],
              [
                -47.35640151971247,
                -17.194218699427143
              ],
              [
                -47.35447754479342,
                -17.194747705470057
              ],
              [
                -47.349786555126954,
                -17.196037708506147
              ],
              [
                -47.350679060497754,
                -17.204833151700043
              ],
              [
                -47.355623513333065,
                -17.202178196464523
              ],
              [
                -47.360396451717094,
                -17.204158667236733
              ],
              [
                -47.363731937916576,
                -17.21154615320011
              ],
              [
                -47.362198405117404,
                -17.222248063072836
              ],
              [
                -47.37028036880103,
                -17.217963599262077
              ],
              [
                -47.372089850640634,
                -17.217004606258268
              ],
              [
                -47.373121814632,
                -17.220767610513224
              ],
              [
                -47.37815679148668,
                -17.21951614202558
              ],
              [
                -47.38487122651635,
                -17.226588611999613
              ],
              [
                -47.381341231517794,
                -17.231756552957677
              ],
              [
                -47.37856673109363,
                -17.23581854741475
              ],
              [
                -47.38605369641569,
                -17.239276551256317
              ],
              [
                -47.38148320831245,
                -17.242897022175367
              ],
              [
                -47.386089653545596,
                -17.249346989935958
              ],
              [
                -47.39502512390815,
                -17.242743557270487
              ],
              [
                -47.39561758821981,
                -17.242305547873848
              ],
              [
                -47.40636203190378,
                -17.24522203265346
              ],
              [
                -47.41062195269623,
                -17.24877202905745
              ],
              [
                -47.4089059853518,
                -17.25388951922827
              ],
              [
                -47.40782196640293,
                -17.25711946276898
              ],
              [
                -47.41403192669689,
                -17.258527992751773
              ],
              [
                -47.422286855640486,
                -17.26040051270997
              ],
              [
                -47.417209910562285,
                -17.26400745461206
              ],
              [
                -47.41649339306669,
                -17.264516958361828
              ],
              [
                -47.417197897315184,
                -17.269266954734118
              ],
              [
                -47.42114534533642,
                -17.27079095121817
              ],
              [
                -47.434010719341906,
                -17.275758441809632
              ],
              [
                -47.43232171169732,
                -17.283027387681074
              ],
              [
                -47.4280492754827,
                -17.28311139197042
              ],
              [
                -47.425100783800104,
                -17.28316935326928
              ],
              [
                -47.42097031455306,
                -17.289171347547512
              ],
              [
                -47.42034629968198,
                -17.300584262805966
              ],
              [
                -47.42475922810778,
                -17.30418276235028
              ],
              [
                -47.428923208992195,
                -17.30757774843655
              ],
              [
                -47.42423574086582,
                -17.312428231604724
              ],
              [
                -47.4212612452837,
                -17.315506690863746
              ],
              [
                -47.423742704555046,
                -17.323567164636348
              ],
              [
                -47.43202465386759,
                -17.319660702288033
              ],
              [
                -47.43618763337774,
                -17.317696705717506
              ],
              [
                -47.44148305981107,
                -17.32255421355414
              ],
              [
                -47.4498975144369,
                -17.3235932213594
              ],
              [
                -47.450563489245475,
                -17.32863867815177
              ],
              [
                -47.45456796066501,
                -17.33022168450074
              ],
              [
                -47.44968745771053,
                -17.336059636046045
              ],
              [
                -47.44034002467939,
                -17.347241062645033
              ],
              [
                -47.44661246507943,
                -17.348809563018552
              ],
              [
                -47.45066092567744,
                -17.344658079754996
              ],
              [
                -47.45959035694534,
                -17.343689610310026
              ],
              [
                -47.45743087723906,
                -17.351262094630798
              ],
              [
                -47.46382730643003,
                -17.355046071593417
              ],
              [
                -47.47177629053091,
                -17.344754149227395
              ],
              [
                -47.487505660342194,
                -17.349046145847005
              ],
              [
                -47.490373611603665,
                -17.344365200790655
              ],
              [
                -47.49462309077898,
                -17.34438570394104
              ],
              [
                -47.49711910991668,
                -17.33804522482784
              ],
              [
                -47.498176104855126,
                -17.335360754293042
              ],
              [
                -47.49933209252186,
                -17.334999257717904
              ],
              [
                -47.51120198797772,
                -17.331282312177912
              ],
              [
                -47.51170599341509,
                -17.334483262391377
              ],
              [
                -47.504146040932646,
                -17.336834740519922
              ],
              [
                -47.521228878529705,
                -17.352351706932467
              ],
              [
                -47.51915438083326,
                -17.356232688952367
              ],
              [
                -47.513539435588854,
                -17.355750177248865
              ],
              [
                -47.513562907402836,
                -17.356595654843314
              ],
              [
                -47.51405639366361,
                -17.37426656579592
              ],
              [
                -47.52525176316212,
                -17.382516038792936
              ],
              [
                -47.525927250588765,
                -17.38175904125511
              ],
              [
                -47.53006275671324,
                -17.377124104697426
              ],
              [
                -47.531869233981,
                -17.380661087018105
              ],
              [
                -47.52836473484873,
                -17.38740152286997
              ],
              [
                -47.527977243157856,
                -17.3881480180928
              ],
              [
                -47.52869272744224,
                -17.388161001588117
              ],
              [
                -47.538214140382955,
                -17.388335554480236
              ],
              [
                -47.53837517709655,
                -17.388338539816576
              ],
              [
                -47.53355768119591,
                -17.40232346767931
              ],
              [
                -47.53131065784152,
                -17.403253953867534
              ],
              [
                -47.523562728798886,
                -17.40646043304418
              ],
              [
                -47.52426173370344,
                -17.410900887205116
              ],
              [
                -47.529889182265485,
                -17.409518919790596
              ],
              [
                -47.5368961086755,
                -17.407798441175284
              ],
              [
                -47.53532014558325,
                -17.412268402210206
              ],
              [
                -47.529426172798495,
                -17.41337088451019
              ],
              [
                -47.527265673722134,
                -17.423577324087535
              ],
              [
                -47.52670415522024,
                -17.42356234193677
              ],
              [
                -47.52097772308908,
                -17.42341184418977
              ],
              [
                -47.52565618856912,
                -17.428182321341012
              ],
              [
                -47.526728673446385,
                -17.42927578193905
              ],
              [
                -47.526770631810805,
                -17.432347305877375
              ],
              [
                -47.526873626025,
                -17.439902231376287
              ],
              [
                -47.530343598961444,
                -17.441367268279564
              ],
              [
                -47.539901016407114,
                -17.445402255024586
              ],
              [
                -47.54073500468131,
                -17.453843686495457
              ],
              [
                -47.53576153334396,
                -17.45504518027074
              ],
              [
                -47.53524302516699,
                -17.45517020447619
              ],
              [
                -47.513856685846775,
                -17.460343095372064
              ],
              [
                -47.5116132007602,
                -17.473323054228988
              ],
              [
                -47.50278424791719,
                -17.481314991847693
              ],
              [
                -47.50372024043633,
                -17.485523950786796
              ],
              [
                -47.50528217025053,
                -17.492550931300705
              ],
              [
                -47.50245216254242,
                -17.516092297449077
              ],
              [
                -47.49540319071285,
                -17.524837728025844
              ],
              [
                -47.49203974074443,
                -17.525978737633487
              ],
              [
                -47.484132800222255,
                -17.518211268023965
              ],
              [
                -47.477067394023344,
                -17.51127026994803
              ],
              [
                -47.470122378165115,
                -17.52555665962474
              ],
              [
                -47.46645242533093,
                -17.533105652899792
              ],
              [
                -47.45316099746879,
                -17.536733578594813
              ],
              [
                -47.44712607137296,
                -17.531356612567617
              ],
              [
                -47.44595707117006,
                -17.530315104469228
              ],
              [
                -47.413752857759576,
                -17.521061604326896
              ],
              [
                -47.39932748317605,
                -17.51247459082713
              ],
              [
                -47.38445660332345,
                -17.513883056768318
              ],
              [
                -47.37746516364924,
                -17.52034103242106
              ],
              [
                -47.37408919012257,
                -17.52345950859106
              ],
              [
                -47.35092935058013,
                -17.526480448836736
              ],
              [
                -47.34493289587439,
                -17.525863938921823
              ],
              [
                -47.33048103569422,
                -17.524378396034447
              ],
              [
                -47.32039306411181,
                -17.530699357587466
              ],
              [
                -47.31365960899988,
                -17.543631769658113
              ],
              [
                -47.310439108489234,
                -17.551682730166966
              ],
              [
                -47.30878462082196,
                -17.555818185746094
              ],
              [
                -47.306174639450994,
                -17.55799767253021
              ],
              [
                -47.2834147954934,
                -17.577118524777756
              ],
              [
                -47.275372799242966,
                -17.597392933300966
              ],
              [
                -47.271462805731694,
                -17.602301366847342
              ],
              [
                -47.266424331844135,
                -17.608626829447008
              ],
              [
                -47.263519841820816,
                -17.61999677418739
              ],
              [
                -47.26781975509617,
                -17.63725167685376
              ],
              [
                -47.27129724311715,
                -17.63941570213803
              ],
              [
                -47.27828767682485,
                -17.643765183680966
              ],
              [
                -47.28092963806147,
                -17.652784126086146
              ],
              [
                -47.275343662453984,
                -17.660377600830092
              ],
              [
                -47.27059169990693,
                -17.666836520379686
              ],
              [
                -47.26996717565226,
                -17.674010003292857
              ],
              [
                -47.28615201087726,
                -17.683751488887278
              ],
              [
                -47.29449948053527,
                -17.683604483121886
              ],
              [
                -47.29790294649002,
                -17.6835439989679
              ],
              [
                -47.29871343256893,
                -17.68871297261948
              ],
              [
                -47.299070931221664,
                -17.690992949440123
              ],
              [
                -47.300570886304634,
                -17.690965474903795
              ],
              [
                -47.305202364513974,
                -17.69087897760797
              ],
              [
                -47.31323627684654,
                -17.698553453115746
              ],
              [
                -47.31164876638593,
                -17.711052896020785
              ],
              [
                -47.317395679981566,
                -17.72617330246547
              ],
              [
                -47.336591518594346,
                -17.736960790524773
              ],
              [
                -47.34227993215311,
                -17.743996257303372
              ],
              [
                -47.34200795058381,
                -17.751328720300098
              ],
              [
                -47.337714450211834,
                -17.754081211346282
              ],
              [
                -47.33419747284278,
                -17.75633670771969
              ],
              [
                -47.33600647725484,
                -17.76052465981147
              ],
              [
                -47.3322639725122,
                -17.766155642009675
              ],
              [
                -47.3427568572575,
                -17.774592603186637
              ],
              [
                -47.34037440463266,
                -17.778575104045764
              ],
              [
                -47.34700181875846,
                -17.783086561192295
              ],
              [
                -47.34835432672356,
                -17.78400708538777
              ],
              [
                -47.35403077594519,
                -17.78707554897734
              ],
              [
                -47.35378326287061,
                -17.79327754399318
              ],
              [
                -47.351349748994956,
                -17.79505354428546
              ],
              [
                -47.347994787846154,
                -17.797502492005652
              ],
              [
                -47.35253973276209,
                -17.804413485000392
              ],
              [
                -47.35495672891124,
                -17.804638482211583
              ],
              [
                -47.357561180520314,
                -17.804881476746946
              ],
              [
                -47.35563019518271,
                -17.806428980353758
              ],
              [
                -47.35017773611715,
                -17.810797919361868
              ],
              [
                -47.35159073041935,
                -17.814670408272622
              ],
              [
                -47.359196633662656,
                -17.815907442771735
              ],
              [
                -47.36289463366637,
                -17.816508932877962
              ],
              [
                -47.36402860874027,
                -17.82303442231836
              ],
              [
                -47.372609514518295,
                -17.829484867784355
              ],
              [
                -47.36647605529709,
                -17.834908830767063
              ],
              [
                -47.357648053756094,
                -17.8579187119195
              ],
              [
                -47.35908655457952,
                -17.865581180147423
              ],
              [
                -47.356348550863245,
                -17.867873139063814
              ],
              [
                -47.35325856024143,
                -17.870459637655387
              ],
              [
                -47.3554195622098,
                -17.877330120643336
              ],
              [
                -47.35838248653375,
                -17.886751077135262
              ],
              [
                -47.35661697480471,
                -17.897325502039884
              ],
              [
                -47.35215952622234,
                -17.897408003624196
              ],
              [
                -47.34638659309735,
                -17.89751449113758
              ],
              [
                -47.34375307127034,
                -17.908508904906476
              ],
              [
                -47.33795611801295,
                -17.912023378589872
              ],
              [
                -47.341855585987155,
                -17.919730865150047
              ],
              [
                -47.34307006040467,
                -17.922131357361454
              ],
              [
                -47.3389530786867,
                -17.927699288107604
              ],
              [
                -47.34014453252542,
                -17.93694276761272
              ],
              [
                -47.33510758768724,
                -17.9373952429506
              ],
              [
                -47.33322656224213,
                -17.95105967553573
              ],
              [
                -47.32331161118732,
                -17.95691011679027
              ],
              [
                -47.31339719914342,
                -17.962759566046046
              ],
              [
                -47.31350817634841,
                -17.976578497313845
              ],
              [
                -47.319216130549776,
                -17.978670989250602
              ],
              [
                -47.32086910484048,
                -17.97927650681471
              ],
              [
                -47.322501564110404,
                -17.984028987743642
              ],
              [
                -47.31934757269732,
                -17.985511975928954
              ],
              [
                -47.31872708093992,
                -17.99557243216529
              ],
              [
                -47.315145611174614,
                -17.994928897454834
              ],
              [
                -47.31109309638601,
                -18.00559084584158
              ],
              [
                -47.30017420966257,
                -18.008071327348958
              ],
              [
                -47.29639975076241,
                -18.00892878138828
              ],
              [
                -47.296163222662535,
                -18.012585252823218
              ],
              [
                -47.29528519936733,
                -18.026126676375682
              ],
              [
                -47.3003341428954,
                -18.026556718515735
              ],
              [
                -47.29261921387524,
                -18.029714199702102
              ],
              [
                -47.286318750264606,
                -18.042320084918327
              ],
              [
                -47.28630775336985,
                -18.042313101929864
              ],
              [
                -47.283546744988946,
                -18.04048158323246
              ],
              [
                -47.28305324133491,
                -18.051277026964318
              ],
              [
                -47.28275624270597,
                -18.05776850353486
              ],
              [
                -47.292008118249825,
                -18.063595007124658
              ],
              [
                -47.302403052737155,
                -18.063307534089287
              ],
              [
                -47.305095026371404,
                -18.063233024555338
              ],
              [
                -47.3045825253925,
                -18.06488652380543
              ],
              [
                -47.30198455049317,
                -18.073268953381582
              ],
              [
                -47.30989696668153,
                -18.069005500407577
              ],
              [
                -47.31041399052072,
                -18.069014510528763
              ],
              [
                -47.322318405722214,
                -18.06922451333422
              ],
              [
                -47.342975209950595,
                -18.080343525306734
              ],
              [
                -47.34642614796099,
                -18.087774482555176
              ],
              [
                -47.35734558856929,
                -18.084556507958702
              ],
              [
                -47.360932517181695,
                -18.09574647604347
              ],
              [
                -47.35775105191426,
                -18.09603596133836
              ],
              [
                -47.354258569065735,
                -18.09635396478129
              ],
              [
                -47.35542856117057,
                -18.104645929434103
              ],
              [
                -47.36548193204861,
                -18.107349913194092
              ],
              [
                -47.36907092290113,
                -18.114788895777043
              ],
              [
                -47.38353327205639,
                -18.11727938766824
              ],
              [
                -47.385643249584454,
                -18.12074537950318
              ],
              [
                -47.38872971384785,
                -18.118752425825527
              ],
              [
                -47.393338727378435,
                -18.115775944694107
              ],
              [
                -47.39471067514008,
                -18.12313838268412
              ],
              [
                -47.39537065607211,
                -18.126679860847435
              ],
              [
                -47.40074460076147,
                -18.128831871905863
              ],
              [
                -47.40687105268797,
                -18.131285391117384
              ],
              [
                -47.40502108775524,
                -18.13479684674616
              ],
              [
                -47.40501055878844,
                -18.134817354616107
              ],
              [
                -47.396090155910144,
                -18.1369113385227
              ],
              [
                -47.396421614111375,
                -18.141398288055974
              ],
              [
                -47.407535033092586,
                -18.14106032004997
              ],
              [
                -47.411955501386124,
                -18.147241304123003
              ],
              [
                -47.41303349392855,
                -18.145970311146424
              ],
              [
                -47.417902452276664,
                -18.14023037689763
              ],
              [
                -47.42833687438598,
                -18.138818885907245
              ],
              [
                -47.434917785202714,
                -18.151519833206795
              ],
              [
                -47.426895331132684,
                -18.160836763806095
              ],
              [
                -47.42958931336862,
                -18.16534724062737
              ],
              [
                -47.435425737282806,
                -18.167128767705893
              ],
              [
                -47.44409120461867,
                -18.158460822207747
              ],
              [
                -47.45078665951071,
                -18.15870535118069
              ],
              [
                -47.46350600356203,
                -18.17689476622345
              ],
              [
                -47.468115461308145,
                -18.175864285044703
              ],
              [
                -47.47101243888938,
                -18.17958374345191
              ],
              [
                -47.479535891859854,
                -18.176756794464396
              ],
              [
                -47.4854223024645,
                -18.184925279746274
              ],
              [
                -47.485263306310905,
                -18.18629676631015
              ],
              [
                -47.485105824177374,
                -18.18765526194474
              ],
              [
                -47.48448078266893,
                -18.193062220635696
              ],
              [
                -47.48944875573359,
                -18.191964234630976
              ],
              [
                -47.496727175946646,
                -18.19863421385458
              ],
              [
                -47.5017376535583,
                -18.194383245200964
              ],
              [
                -47.504786647675076,
                -18.191796774490527
              ],
              [
                -47.51775451662121,
                -18.19840975801477
              ],
              [
                -47.517693017600976,
                -18.202214247275318
              ],
              [
                -47.517565510819075,
                -18.210131223205938
              ],
              [
                -47.53019685984464,
                -18.217381184745
              ],
              [
                -47.5270394010246,
                -18.225777656306803
              ],
              [
                -47.53328832696534,
                -18.230239618639033
              ],
              [
                -47.538735294872495,
                -18.227292641263542
              ],
              [
                -47.53967729246106,
                -18.21686869105811
              ],
              [
                -47.5380718358096,
                -18.211461229220113
              ],
              [
                -47.534118386661135,
                -18.198149285725922
              ],
              [
                -47.538270849580336,
                -18.194526834864675
              ],
              [
                -47.55728419125324,
                -18.207567780176184
              ],
              [
                -47.5671166402574,
                -18.203396827114858
              ],
              [
                -47.570501573030434,
                -18.21986027217249
              ],
              [
                -47.576035511665616,
                -18.22553573565619
              ],
              [
                -47.58077097258111,
                -18.22366674910376
              ],
              [
                -47.5881693882951,
                -18.226571755109717
              ],
              [
                -47.58302243593085,
                -18.22992622608454
              ],
              [
                -47.58100741770911,
                -18.231239242722165
              ],
              [
                -47.58134492548458,
                -18.23464120001165
              ],
              [
                -47.58843988351537,
                -18.235192704429092
              ],
              [
                -47.59033486903424,
                -18.235339707014777
              ],
              [
                -47.60653320362822,
                -18.246590191356308
              ],
              [
                -47.60492020987692,
                -18.25121316992316
              ],
              [
                -47.60280871151348,
                -18.2572626490253
              ],
              [
                -47.60684515084413,
                -18.263422127673284
              ],
              [
                -47.614330628855036,
                -18.260361627556755
              ],
              [
                -47.61469011735726,
                -18.260215130133478
              ],
              [
                -47.61411964352913,
                -18.25041770110541
              ],
              [
                -47.61883057730346,
                -18.25466366312122
              ],
              [
                -47.62476004760297,
                -18.2600071643115
              ],
              [
                -47.618567070706455,
                -18.271245095446492
              ],
              [
                -47.62945346998064,
                -18.276783605048948
              ],
              [
                -47.638317840060495,
                -18.28721106797181
              ],
              [
                -47.63231589896168,
                -18.291156527952726
              ],
              [
                -47.627680897249,
                -18.300941448151466
              ],
              [
                -47.6275964264724,
                -18.301119963042453
              ],
              [
                -47.62685992942232,
                -18.302675471378006
              ],
              [
                -47.610808505527636,
                -18.314924366682845
              ],
              [
                -47.6238108965526,
                -18.322168336505914
              ],
              [
                -47.625732834773395,
                -18.333830290218444
              ],
              [
                -47.632853272947806,
                -18.333057291583394
              ],
              [
                -47.6432012008084,
                -18.338056805848435
              ],
              [
                -47.64616668854385,
                -18.33948982535363
              ],
              [
                -47.64648318640352,
                -18.32984333144349
              ],
              [
                -47.652732143857094,
                -18.325780900633653
              ],
              [
                -47.65711962895096,
                -18.327338885772672
              ],
              [
                -47.67025244722915,
                -18.357009268341166
              ],
              [
                -47.67892689085114,
                -18.355923804917765
              ],
              [
                -47.686003307766455,
                -18.35698980481298
              ],
              [
                -47.68585932038615,
                -18.359171803333062
              ],
              [
                -47.685651317047274,
                -18.36232776573054
              ],
              [
                -47.69812519103927,
                -18.37677320579704
              ],
              [
                -47.70192265896588,
                -18.378673211881114
              ],
              [
                -47.710749087047624,
                -18.37694273712753
              ],
              [
                -47.71393756757919,
                -18.36829930961297
              ],
              [
                -47.71639954289782,
                -18.36162434437524
              ],
              [
                -47.74011638362514,
                -18.363373853280624
              ],
              [
                -47.73283740697954,
                -18.37543178393497
              ],
              [
                -47.73575436706679,
                -18.378460308238616
              ],
              [
                -47.74289079798483,
                -18.37698979925334
              ],
              [
                -47.75593317264822,
                -18.394510745148487
              ],
              [
                -47.747141732168004,
                -18.40531118374724
              ],
              [
                -47.747371699073085,
                -18.410131662723344
              ],
              [
                -47.74742017611834,
                -18.411141149693588
              ],
              [
                -47.74794669008545,
                -18.4110786412328
              ],
              [
                -47.75866961194756,
                -18.40981069755558
              ],
              [
                -47.76736303233974,
                -18.415864142898364
              ],
              [
                -47.770280517945444,
                -18.41789566732656
              ],
              [
                -47.77856340632609,
                -18.423665667186707
              ],
              [
                -47.78875785070055,
                -18.417191691791576
              ],
              [
                -47.79804127127984,
                -18.415842709203822
              ],
              [
                -47.79961226257668,
                -18.41042777639748
              ],
              [
                -47.79922678419551,
                -18.409818241603965
              ],
              [
                -47.79742631576424,
                -18.40697275290387
              ],
              [
                -47.80867121604504,
                -18.40040483741162
              ],
              [
                -47.81487466599207,
                -18.40579230939948
              ],
              [
                -47.82943702095199,
                -18.41295332379399
              ],
              [
                -47.83031752393037,
                -18.42472726999614
              ],
              [
                -47.83245697965874,
                -18.428357256522926
              ],
              [
                -47.836585440957165,
                -18.435361704536433
              ],
              [
                -47.83119445379354,
                -18.442031649119603
              ],
              [
                -47.83148295589577,
                -18.449315134800038
              ],
              [
                -47.8383368683433,
                -18.451292118711553
              ],
              [
                -47.84574434897087,
                -18.444042686755758
              ],
              [
                -47.84900480920195,
                -18.440851722543016
              ],
              [
                -47.8583627312593,
                -18.43989575116929
              ],
              [
                -47.86006821979964,
                -18.442598210420467
              ],
              [
                -47.8640876815993,
                -18.448958205499903
              ],
              [
                -47.865177665168616,
                -18.452387672636867
              ],
              [
                -47.87153460307739,
                -18.45573868744227
              ],
              [
                -47.872830064734764,
                -18.458884684503328
              ],
              [
                -47.86396615270087,
                -18.463763636105682
              ],
              [
                -47.863725669274615,
                -18.46389563887578
              ],
              [
                -47.86278716496547,
                -18.469276080399972
              ],
              [
                -47.86772560479812,
                -18.47392959131386
              ],
              [
                -47.87503251246532,
                -18.47520157790196
              ],
              [
                -47.88198299385592,
                -18.470271109101404
              ],
              [
                -47.885121477616586,
                -18.468045142702103
              ],
              [
                -47.88742596356969,
                -18.472237617825655
              ],
              [
                -47.88972993052517,
                -18.476429591955558
              ],
              [
                -47.904154792756565,
                -18.470250158911806
              ],
              [
                -47.913817261613794,
                -18.466110713419223
              ],
              [
                -47.91939869332345,
                -18.46843920964405
              ],
              [
                -47.92498065603087,
                -18.470767739875114
              ],
              [
                -47.926029126858886,
                -18.474999676893923
              ],
              [
                -47.92909409478563,
                -18.487374639408202
              ],
              [
                -47.93876598540122,
                -18.487603672840766
              ],
              [
                -47.94009349977938,
                -18.487635650780046
              ],
              [
                -47.94807989866266,
                -18.491420174294955
              ],
              [
                -47.95219786596058,
                -18.493372655043988
              ],
              [
                -47.95448133529035,
                -18.4998146108074
              ],
              [
                -47.9617392804933,
                -18.495605162059427
              ],
              [
                -47.9645013126077,
                -18.476892293143415
              ],
              [
                -47.968631775535954,
                -18.474278769010215
              ],
              [
                -47.975924207883615,
                -18.4773152896886
              ],
              [
                -47.97875420909825,
                -18.475496317497996
              ],
              [
                -47.9794502416163,
                -18.462224384245555
              ],
              [
                -47.97831873117395,
                -18.459266873565046
              ],
              [
                -47.97559529302919,
                -18.45214491824336
              ],
              [
                -47.980452262475985,
                -18.442393978919373
              ],
              [
                -47.99708311217063,
                -18.453872435652148
              ],
              [
                -47.99745461556831,
                -18.454128446224665
              ],
              [
                -48.00602404525119,
                -18.453880978481834
              ],
              [
                -48.00950950245504,
                -18.452383499190827
              ],
              [
                -48.01315049548987,
                -18.444228524924892
              ],
              [
                -48.02824538730567,
                -18.435851108511056
              ],
              [
                -48.03892180629962,
                -18.433275669445177
              ],
              [
                -48.04378679687545,
                -18.423857191411496
              ],
              [
                -48.0475857926074,
                -18.41650223846012
              ],
              [
                -48.05536674550621,
                -18.412777779435963
              ],
              [
                -48.06095418773847,
                -18.412588296423124
              ],
              [
                -48.064343672121296,
                -18.419140784207535
              ],
              [
                -48.067235101907805,
                -18.424730237707713
              ],
              [
                -48.073576554784005,
                -18.42664525883292
              ],
              [
                -48.09526489018524,
                -18.428162778805756
              ],
              [
                -48.11417025485604,
                -18.426097330387087
              ],
              [
                -48.128236145326746,
                -18.4156589169197
              ],
              [
                -48.1473385025218,
                -18.407745487457554
              ],
              [
                -48.15034303111701,
                -18.394724596178474
              ],
              [
                -48.15930446465224,
                -18.384027168078664
              ],
              [
                -48.15951546412858,
                -18.38240767574535
              ],
              [
                -48.16090048039412,
                -18.37176920568157
              ],
              [
                -48.17066492332015,
                -18.374075718803397
              ],
              [
                -48.17243038429978,
                -18.374493231812956
              ],
              [
                -48.19551324845343,
                -18.363729326957856
              ],
              [
                -48.22049856202141,
                -18.361817892865876
              ],
              [
                -48.226727502762806,
                -18.357157923893926
              ],
              [
                -48.225456030808196,
                -18.350115966446186
              ],
              [
                -48.226358039997905,
                -18.345300003842656
              ],
              [
                -48.254942831942486,
                -18.343523063438106
              ],
              [
                -48.26194881460884,
                -18.331418125801314
              ],
              [
                -48.27049772162952,
                -18.33123967962473
              ],
              [
                -48.27179519746472,
                -18.33121265878503
              ],
              [
                -48.27861465305179,
                -18.33106716741393
              ],
              [
                -48.287837547459716,
                -18.348216114517783
              ],
              [
                -48.31448828469809,
                -18.36369157384309
              ],
              [
                -48.31528177462814,
                -18.372591045927162
              ],
              [
                -48.311764304140944,
                -18.375795508585703
              ],
              [
                -48.304228840950174,
                -18.38265898444362
              ],
              [
                -48.31317324502378,
                -18.384374456734474
              ],
              [
                -48.34206505795384,
                -18.37047811217329
              ],
              [
                -48.35315200609876,
                -18.365606145589094
              ],
              [
                -48.400034086009725,
                -18.371410710121634
              ],
              [
                -48.40319609689275,
                -18.370720228989967
              ],
              [
                -48.404366088304485,
                -18.362298795004026
              ],
              [
                -48.40525858175483,
                -18.35587883532632
              ],
              [
                -48.41301302598587,
                -18.355778340972183
              ],
              [
                -48.4190619948049,
                -18.3610698355658
              ],
              [
                -48.421731960280034,
                -18.361660318851744
              ],
              [
                -48.43611281696644,
                -18.364842332554815
              ],
              [
                -48.44065632907756,
                -18.35983836629499
              ],
              [
                -48.44519979618713,
                -18.354835416028713
              ],
              [
                -48.45373772229852,
                -18.360965377273853
              ],
              [
                -48.4791829412603,
                -18.379235342341776
              ],
              [
                -48.485429914291984,
                -18.372961919414383
              ],
              [
                -48.48208946411344,
                -18.36340846644633
              ],
              [
                -48.48096199126185,
                -18.360184451960375
              ],
              [
                -48.484346969689895,
                -18.35316552006684
              ],
              [
                -48.49231440775636,
                -18.352510520012068
              ],
              [
                -48.5151861884968,
                -18.365950984298973
              ],
              [
                -48.53065013247926,
                -18.350611117353072
              ],
              [
                -48.54277853678555,
                -18.350011154786078
              ],
              [
                -48.54922498699435,
                -18.34969217249548
              ],
              [
                -48.55408095512568,
                -18.34648269894135
              ],
              [
                -48.553748454420926,
                -18.339991205650822
              ],
              [
                -48.55328448033533,
                -18.330932273912627
              ],
              [
                -48.56095246144127,
                -18.32342079647912
              ],
              [
                -48.57326181980508,
                -18.325385312474932
              ],
              [
                -48.58190326446641,
                -18.32676384382108
              ],
              [
                -48.59681015304474,
                -18.334347323825206
              ],
              [
                -48.633362362865846,
                -18.327183460347992
              ],
              [
                -48.64108876945198,
                -18.33295992961013
              ],
              [
                -48.64062878546094,
                -18.33979842237188
              ],
              [
                -48.64670722947935,
                -18.343427910514684
              ],
              [
                -48.667998538697375,
                -18.344962428852075
              ],
              [
                -48.68241193467354,
                -18.343068469159288
              ],
              [
                -48.69153137077448,
                -18.347135491299266
              ],
              [
                -48.697283328121145,
                -18.34970047602714
              ],
              [
                -48.70270028631452,
                -18.347136980541777
              ],
              [
                -48.708117248511904,
                -18.344573532054852
              ],
              [
                -48.716071659580216,
                -18.347546006936035
              ],
              [
                -48.73014702784632,
                -18.352806514824135
              ],
              [
                -48.742713950978235,
                -18.34836059653172
              ],
              [
                -48.75528084613129,
                -18.343915614230035
              ],
              [
                -48.76691823076448,
                -18.35296161757755
              ],
              [
                -48.76972722815705,
                -18.355145600320704
              ],
              [
                -48.77296669649251,
                -18.354111606092705
              ],
              [
                -48.78316714313552,
                -18.35085613830587
              ],
              [
                -48.80039147002284,
                -18.361098144932846
              ],
              [
                -48.805874891615595,
                -18.367498628038376
              ],
              [
                -48.815893308061085,
                -18.379192602557733
              ],
              [
                -48.82172677217735,
                -18.37698812526951
              ],
              [
                -48.827561221290914,
                -18.374783647982504
              ],
              [
                -48.831620744848756,
                -18.345670287199045
              ],
              [
                -48.872286449623026,
                -18.332381920016555
              ],
              [
                -48.87735645611936,
                -18.32538498471329
              ],
              [
                -48.882426934610585,
                -18.31838803741031
              ],
              [
                -48.88948937938639,
                -18.31765753166686
              ],
              [
                -48.90664776710877,
                -18.315882072887412
              ],
              [
                -48.915879677552866,
                -18.306797662730727
              ],
              [
                -48.91728817714526,
                -18.30541166292846
              ],
              [
                -48.93630953797792,
                -18.305743689066553
              ],
              [
                -48.95903729321077,
                -18.323014179958072
              ],
              [
                -48.9687637362619,
                -18.32887364485548
              ],
              [
                -48.975125169183855,
                -18.339504617197424
              ],
              [
                -48.97642415549797,
                -18.339722623184212
              ],
              [
                -48.98372806641427,
                -18.34094963035203
              ],
              [
                -48.982381550540815,
                -18.348426588149177
              ],
              [
                -48.98103405564478,
                -18.355912041907487
              ],
              [
                -48.989660974078426,
                -18.35797657107685
              ],
              [
                -48.99015597991476,
                -18.358095073660426
              ],
              [
                -48.99068847058532,
                -18.359322039847196
              ],
              [
                -48.99552940993775,
                -18.370477499102577
              ],
              [
                -49.00051338468047,
                -18.372185037447167
              ],
              [
                -49.00549731442063,
                -18.373894500785738
              ],
              [
                -49.010598812478314,
                -18.372048027986814
              ],
              [
                -49.023282185627025,
                -18.367455065704736
              ],
              [
                -49.03240764342576,
                -18.371750073072906
              ],
              [
                -49.041533537224524,
                -18.3760455994553
              ],
              [
                -49.0532953698925,
                -18.40211147863263
              ],
              [
                -49.06873422819964,
                -18.411532466787374
              ],
              [
                -49.076573648731035,
                -18.416315965811634
              ],
              [
                -49.08149061663776,
                -18.412798497826348
              ],
              [
                -49.09079054752327,
                -18.413090527468345
              ],
              [
                -49.09572153260441,
                -18.40447855562249
              ],
              [
                -49.096428534320786,
                -18.403244061385696
              ],
              [
                -49.09759303961613,
                -18.403108553817713
              ],
              [
                -49.10947594379975,
                -18.40172709783353
              ],
              [
                -49.11205293104364,
                -18.399699107944464
              ],
              [
                -49.10953594350948,
                -18.394364131263277
              ],
              [
                -49.1067185029618,
                -18.388393182012276
              ],
              [
                -49.10833999639468,
                -18.38338170656646
              ],
              [
                -49.12702033539715,
                -18.382909242204352
              ],
              [
                -49.15411858032125,
                -18.402287710093166
              ],
              [
                -49.15589856896991,
                -18.40785615691649
              ],
              [
                -49.15767755562057,
                -18.413424669744067
              ],
              [
                -49.18540883256539,
                -18.415964680198684
              ],
              [
                -49.189390274079365,
                -18.415032190254177
              ],
              [
                -49.205197675007376,
                -18.4113292430602
              ],
              [
                -49.21079313833267,
                -18.417928757946395
              ],
              [
                -49.21081109382281,
                -18.4230462028492
              ],
              [
                -49.199262679932744,
                -18.4351186290486
              ],
              [
                -49.19637719845294,
                -18.43813510535899
              ],
              [
                -49.19262418456998,
                -18.447322075295627
              ],
              [
                -49.200906578261936,
                -18.458394501676473
              ],
              [
                -49.2148679662893,
                -18.46624151347544
              ],
              [
                -49.21506198349714,
                -18.466350521403644
              ],
              [
                -49.22795534920044,
                -18.479390961235946
              ],
              [
                -49.24362713911117,
                -18.505830889160045
              ],
              [
                -49.248630051223174,
                -18.522200821003302
              ],
              [
                -49.27122038155229,
                -18.527270318317196
              ],
              [
                -49.28385525699593,
                -18.53657879309784
              ],
              [
                -49.286757230195285,
                -18.540481284986964
              ],
              [
                -49.29212662969184,
                -18.5635436842305
              ],
              [
                -49.302512045715055,
                -18.56597069432801
              ],
              [
                -49.32210141119562,
                -18.560894268160876
              ],
              [
                -49.32594988686031,
                -18.562974279259958
              ],
              [
                -49.32979932351553,
                -18.565055285359758
              ],
              [
                -49.34274814020166,
                -18.597534657194643
              ],
              [
                -49.364686444824315,
                -18.616894606802084
              ],
              [
                -49.37052786906215,
                -18.627629560669888
              ],
              [
                -49.37284983373649,
                -18.63189653652241
              ],
              [
                -49.3782842590945,
                -18.64188100310161
              ],
              [
                -49.39425615288093,
                -18.645811032583634
              ],
              [
                -49.40815003314285,
                -18.64434503227475
              ],
              [
                -49.41625997497205,
                -18.63808560026808
              ],
              [
                -49.43193292610465,
                -18.606279278609225
              ],
              [
                -49.48135767191906,
                -18.560566639879106
              ],
              [
                -49.485253158538086,
                -18.544044221559055
              ],
              [
                -49.48585919232835,
                -18.531765298714912
              ],
              [
                -49.48584772668241,
                -18.525233320452852
              ],
              [
                -49.485817778965675,
                -18.50779738320261
              ],
              [
                -49.49038026104927,
                -18.499330443364897
              ],
              [
                -49.4923837270579,
                -18.49611197583958
              ],
              [
                -49.494065724364646,
                -18.495617478285215
              ],
              [
                -49.49715868185517,
                -18.493194997501625
              ],
              [
                -49.535369920734695,
                -18.493182598343385
              ],
              [
                -49.547352776464386,
                -18.50293456574649
              ],
              [
                -49.546151287693995,
                -18.50825101902625
              ],
              [
                -49.54302329627074,
                -18.52208644429371
              ],
              [
                -49.541921255891204,
                -18.526959448975134
              ],
              [
                -49.543045248773474,
                -18.529014893699603
              ],
              [
                -49.5472407200698,
                -18.5366873775524
              ],
              [
                -49.54798671169544,
                -18.53805239972337
              ],
              [
                -49.549584688615035,
                -18.540975891383376
              ],
              [
                -49.55756359697795,
                -18.5458948447414
              ],
              [
                -49.57471246623263,
                -18.54187990055175
              ],
              [
                -49.57478298273193,
                -18.541863446801543
              ],
              [
                -49.58136594054965,
                -18.54313743849238
              ],
              [
                -49.587949356370046,
                -18.544410963190735
              ],
              [
                -49.627773549724715,
                -18.552118483926698
              ],
              [
                -49.647524381752575,
                -18.55840551418737
              ],
              [
                -49.650525316882906,
                -18.563953471595944
              ],
              [
                -49.65200279279359,
                -18.57136696630861
              ],
              [
                -49.64010288456784,
                -18.584145382738935
              ],
              [
                -49.63506491102745,
                -18.589555848389598
              ],
              [
                -49.639647835531655,
                -18.59975878114925
              ],
              [
                -49.647387783608295,
                -18.60135027619249
              ],
              [
                -49.6617301800869,
                -18.593817879309015
              ],
              [
                -49.6692601145732,
                -18.589863399641207
              ],
              [
                -49.686448006443776,
                -18.589741949833673
              ],
              [
                -49.69306345075484,
                -18.59113894110526
              ],
              [
                -49.70520436198883,
                -18.594607943763393
              ],
              [
                -49.72362516788326,
                -18.608950406218767
              ],
              [
                -49.730917604269685,
                -18.61287094200335
              ],
              [
                -49.735664062914594,
                -18.615422414219893
              ],
              [
                -49.741172512084795,
                -18.615205444630785
              ],
              [
                -49.749993929948566,
                -18.61154549067071
              ],
              [
                -49.75676742531588,
                -18.60873549764392
              ],
              [
                -49.762770358687035,
                -18.610396004210642
              ],
              [
                -49.76980779033826,
                -18.612342518915302
              ],
              [
                -49.772385281457744,
                -18.614981992461995
              ],
              [
                -49.77705669215426,
                -18.6304604203002
              ],
              [
                -49.783217141886446,
                -18.640880387141873
              ],
              [
                -49.794314537228374,
                -18.643228930833374
              ],
              [
                -49.81931884367961,
                -18.632114544074398
              ],
              [
                -49.827498317294925,
                -18.634170523086002
              ],
              [
                -49.835678725907364,
                -18.636227540102496
              ],
              [
                -49.849079669080695,
                -18.625332616039664
              ],
              [
                -49.85231614625274,
                -18.6227016335498
              ],
              [
                -49.85589708717076,
                -18.622540635031893
              ],
              [
                -49.86761953352604,
                -18.622014694069648
              ],
              [
                -49.86967601542259,
                -18.616897687644684
              ],
              [
                -49.87116950388586,
                -18.613184250110876
              ],
              [
                -49.87702397914467,
                -18.61085875451429
              ],
              [
                -49.88132194048773,
                -18.609607278974412
              ],
              [
                -49.88460190146669,
                -18.60991975944925
              ],
              [
                -49.888226377678464,
                -18.61139979815705
              ],
              [
                -49.90339424077351,
                -18.620539277194286
              ],
              [
                -49.90389723809986,
                -18.62084275096244
              ],
              [
                -49.9175371148149,
                -18.622185781721697
              ],
              [
                -49.93117802354338,
                -18.623529796491137
              ],
              [
                -49.96255178568299,
                -18.616148432273256
              ],
              [
                -49.973340707112435,
                -18.613854444540454
              ],
              [
                -49.97583121947123,
                -18.610700463713773
              ],
              [
                -49.98097767648283,
                -18.60418253146801
              ],
              [
                -50.01611344514537,
                -18.599376643044312
              ],
              [
                -50.02648534299595,
                -18.60076264067651
              ],
              [
                -50.02804684247929,
                -18.600971158483013
              ],
              [
                -50.02828533756593,
                -18.601003623908735
              ],
              [
                -50.03695672679939,
                -18.61046559577991
              ],
              [
                -50.04489461961747,
                -18.630736546239074
              ],
              [
                -50.04972757645347,
                -18.643078006080056
              ],
              [
                -50.05328854150327,
                -18.645564971901436
              ],
              [
                -50.06379744730805,
                -18.652905468536638
              ],
              [
                -50.07044884996971,
                -18.6608659588114
              ],
              [
                -50.07956775577683,
                -18.6717809150594
              ],
              [
                -50.163810621782396,
                -18.663888618930038
              ],
              [
                -50.1984023307691,
                -18.678617163685526
              ],
              [
                -50.20770575377305,
                -18.679338154083528
              ],
              [
                -50.217009683783076,
                -18.68005969148554
              ],
              [
                -50.23506403187948,
                -18.67861022167905
              ],
              [
                -50.2497829227501,
                -18.67742825142134
              ],
              [
                -50.25209791811747,
                -18.678156751647496
              ],
              [
                -50.269481264709945,
                -18.683627795383227
              ],
              [
                -50.28607561678625,
                -18.689675780485572
              ],
              [
                -50.30888044641243,
                -18.69798678118753
              ],
              [
                -50.316185337709996,
                -18.705006300926218
              ],
              [
                -50.3234912749941,
                -18.712025759689794
              ],
              [
                -50.33098817155895,
                -18.724667197356343
              ],
              [
                -50.35184445604494,
                -18.759833584632837
              ],
              [
                -50.37625861602069,
                -18.81191791236835
              ],
              [
                -50.3850215671295,
                -18.81709139871165
              ],
              [
                -50.39378498123542,
                -18.82226540907198
              ],
              [
                -50.40207338656686,
                -18.82392740080009
              ],
              [
                -50.41361981731297,
                -18.826242439543723
              ],
              [
                -50.42065624954426,
                -18.830699937034034
              ],
              [
                -50.42411571037574,
                -18.835752909858726
              ],
              [
                -50.44109392643866,
                -18.89032269175893
              ],
              [
                -50.465595672131144,
                -18.9164256164298
              ],
              [
                -50.46928815953302,
                -18.91842160528906
              ],
              [
                -50.46929563046518,
                -18.91842559528898
              ],
              [
                -50.47298109793198,
                -18.92041761415229
              ],
              [
                -50.499825868403555,
                -18.929231618894917
              ],
              [
                -50.50866232086267,
                -18.93696510578949
              ],
              [
                -50.51139523794983,
                -18.951501055655346
              ],
              [
                -50.49956774021683,
                -18.98069888661729
              ],
              [
                -50.50353866544303,
                -19.004017784667038
              ],
              [
                -50.4991856912594,
                -19.008098761704403
              ],
              [
                -50.49459371027265,
                -19.01240474009077
              ],
              [
                -50.49698617179564,
                -19.02620515868686
              ],
              [
                -50.498262146662235,
                -19.03356464855599
              ],
              [
                -50.50162007048927,
                -19.03954962707315
              ],
              [
                -50.52234235412567,
                -19.07647097513617
              ],
              [
                -50.5370396616842,
                -19.09893892760733
              ],
              [
                -50.540115651599166,
                -19.102051409886865
              ],
              [
                -50.540118622570986,
                -19.102053416884992
              ],
              [
                -50.54319259650431,
                -19.10516439817062
              ],
              [
                -50.54900853758599,
                -19.108271882391264
              ],
              [
                -50.572011822294996,
                -19.12056339233242
              ],
              [
                -50.578277761779844,
                -19.128744368177585
              ],
              [
                -50.58454368825541,
                -19.136924834049122
              ],
              [
                -50.645110757656084,
                -19.133467020188533
              ],
              [
                -50.66736508716959,
                -19.1350730420454
              ],
              [
                -50.67114253572887,
                -19.13534555934175
              ],
              [
                -50.67418353320618,
                -19.13728655205404
              ],
              [
                -50.67722549967765,
                -19.13922703877354
              ],
              [
                -50.674962468467264,
                -19.149711998536546
              ],
              [
                -50.672042975696435,
                -19.16323641459009
              ],
              [
                -50.67610791450631,
                -19.167984926936025
              ],
              [
                -50.68017286330864,
                -19.172734901284034
              ],
              [
                -50.732984437120486,
                -19.18534144807048
              ],
              [
                -50.73320094667517,
                -19.185626964303058
              ],
              [
                -50.74240134678545,
                -19.19774743877523
              ],
              [
                -50.7434758094551,
                -19.214496853304592
              ],
              [
                -50.74556673692545,
                -19.226084302240167
              ],
              [
                -50.7472401990654,
                -19.235358753787942
              ],
              [
                -50.77098195654014,
                -19.258887689018618
              ],
              [
                -50.779635406213714,
                -19.26055423442639
              ],
              [
                -50.788288303898895,
                -19.262221249837715
              ],
              [
                -50.816637524171874,
                -19.289362663599615
              ],
              [
                -50.82848788684456,
                -19.309994091291415
              ],
              [
                -50.8353467795456,
                -19.326655053508638
              ],
              [
                -50.835733272703465,
                -19.330142528525126
              ],
              [
                -50.836298750920236,
                -19.335241506622673
              ],
              [
                -50.837397710391414,
                -19.345154938051483
              ],
              [
                -50.86014442259834,
                -19.391620786962285
              ],
              [
                -50.86602638698587,
                -19.398641770019005
              ],
              [
                -50.87190879835955,
                -19.405663751091243
              ],
              [
                -50.87487022615279,
                -19.422370170476125
              ],
              [
                -50.87148273195133,
                -19.427914676458332
              ],
              [
                -50.86809578774662,
                -19.433458137446728
              ],
              [
                -50.83831540966806,
                -19.45713396369672
              ],
              [
                -50.8244579777651,
                -19.474574322406063
              ],
              [
                -50.82620495885921,
                -19.487001272246104
              ],
              [
                -50.827063920483475,
                -19.487649266509276
              ],
              [
                -50.84169181884167,
                -19.49868524299314
              ],
              [
                -50.935323155835626,
                -19.467213118144013
              ],
              [
                -50.93920764149171,
                -19.45604716796609
              ],
              [
                -50.94623411468146,
                -19.452436230535127
              ],
              [
                -50.9463661094015,
                -19.45218573901535
              ],
              [
                -50.95152508145924,
                -19.442393796877795
              ],
              [
                -50.96328653411477,
                -19.438339301677527
              ],
              [
                -50.984813871606335,
                -19.430917423990422
              ],
              [
                -50.98469191258746,
                -19.420431944359326
              ],
              [
                -50.984569943547484,
                -19.40994702774144
              ],
              [
                -50.99681183522135,
                -19.41162052769555
              ],
              [
                -51.00433779739845,
                -19.412649030429137
              ],
              [
                -51.00264729627363,
                -19.40737806503851
              ],
              [
                -51.001485815416885,
                -19.40375857750593
              ],
              [
                -51.00564128671639,
                -19.398624602570834
              ],
              [
                -51.011675758851766,
                -19.4008146041667
              ],
              [
                -51.01500970420006,
                -19.40202410826268
              ],
              [
                -51.030235616198304,
                -19.40097314230396
              ],
              [
                -51.03145412833374,
                -19.397363681852834
              ],
              [
                -51.02570566564348,
                -19.391463193858844
              ],
              [
                -51.02195721749672,
                -19.38778019694716
              ],
              [
                -51.01750974859087,
                -19.383411219361374
              ],
              [
                -51.023313225689314,
                -19.380987728336965
              ],
              [
                -51.03185219892191,
                -19.362200347803395
              ],
              [
                -51.03590919665097,
                -19.361374352005136
              ],
              [
                -51.04195113054958,
                -19.360145363150007
              ],
              [
                -51.042066154274494,
                -19.351887426020348
              ],
              [
                -51.0421451458764,
                -19.34621543503705
              ],
              [
                -51.05031164337547,
                -19.334607025879027
              ],
              [
                -51.053124089908394,
                -19.335392998533493
              ],
              [
                -51.05734108438645,
                -19.32898006156647
              ],
              [
                -51.06117608267768,
                -19.32925107919267
              ],
              [
                -51.06656049996785,
                -19.329632083303384
              ],
              [
                -51.06718803011563,
                -19.325467593869057
              ],
              [
                -51.06730050840094,
                -19.324719606562436
              ],
              [
                -51.07496297142532,
                -19.32057463412415
              ],
              [
                -51.08742841664987,
                -19.313831188851587
              ],
              [
                -51.08743689674397,
                -19.312723706912745
              ],
              [
                -51.08747540229962,
                -19.30786721511218
              ],
              [
                -51.09210288992696,
                -19.306633250618024
              ],
              [
                -51.09301088977631,
                -19.306740228464317
              ],
              [
                -51.098071830925896,
                -19.307335242761972
              ],
              [
                -51.10554126811726,
                -19.30821327496438
              ],
              [
                -51.11792371102712,
                -19.302520831680006
              ],
              [
                -51.12558963791718,
                -19.302993314234644
              ],
              [
                -51.13530407308018,
                -19.29410241462689
              ],
              [
                -51.143342549500154,
                -19.286745942729723
              ],
              [
                -51.16578786399759,
                -19.286920993608234
              ],
              [
                -51.16784035350121,
                -19.286936987810602
              ],
              [
                -51.17392432321447,
                -19.285004537227078
              ],
              [
                -51.180008798931176,
                -19.28307153964636
              ],
              [
                -51.181359273512065,
                -19.28035456745973
              ],
              [
                -51.187457757573526,
                -19.268087125863335
              ],
              [
                -51.20037265676587,
                -19.264452697547938
              ],
              [
                -51.20273666005377,
                -19.260565704277507
              ],
              [
                -51.20652812200998,
                -19.264613192630467
              ],
              [
                -51.20730809777236,
                -19.26544569285316
              ],
              [
                -51.210568613749174,
                -19.262813221184786
              ],
              [
                -51.215895592939475,
                -19.25851225440097
              ],
              [
                -51.22378552286965,
                -19.257720787260862
              ],
              [
                -51.229900950291956,
                -19.26389974992106
              ],
              [
                -51.236504893266726,
                -19.266840755546035
              ],
              [
                -51.240450845598005,
                -19.268597780717066
              ],
              [
                -51.24223085269326,
                -19.26772978523104
              ],
              [
                -51.24625531503876,
                -19.265767779477738
              ],
              [
                -51.24827778256919,
                -19.268668769517372
              ],
              [
                -51.25172477456496,
                -19.273613277955807
              ],
              [
                -51.256452741980546,
                -19.266969804242112
              ],
              [
                -51.274263625325894,
                -19.261654857137973
              ],
              [
                -51.27655260677353,
                -19.255115427672102
              ],
              [
                -51.27845661297182,
                -19.249676430229005
              ],
              [
                -51.282518074634424,
                -19.250320941744445
              ],
              [
                -51.28400609478771,
                -19.250556951498854
              ],
              [
                -51.297477493492444,
                -19.238323047584338
              ],
              [
                -51.307422926243994,
                -19.237479059963793
              ],
              [
                -51.304678480939536,
                -19.227962092060118
              ],
              [
                -51.30331400964304,
                -19.22323159800863
              ],
              [
                -51.307322506251204,
                -19.21806766372003
              ],
              [
                -51.31185492550475,
                -19.218677169591217
              ],
              [
                -51.32795281658953,
                -19.2208331761519
              ],
              [
                -51.33517324967472,
                -19.22180020231451
              ],
              [
                -51.339340222291746,
                -19.217629707915517
              ],
              [
                -51.34350672491177,
                -19.213459734511645
              ],
              [
                -51.34810219453178,
                -19.215188262481227
              ],
              [
                -51.36595204159352,
                -19.221900258940305
              ],
              [
                -51.36898250763859,
                -19.220228285297853
              ],
              [
                -51.364067586605735,
                -19.21245731285858
              ],
              [
                -51.36712404000795,
                -19.20988782934631
              ],
              [
                -51.370011530274766,
                -19.207459848758532
              ],
              [
                -51.36951106166791,
                -19.200204357337228
              ],
              [
                -51.374622536503935,
                -19.194176423765633
              ],
              [
                -51.39352391008378,
                -19.195512949235706
              ],
              [
                -51.39505836728039,
                -19.195621452684463
              ],
              [
                -51.39739937626132,
                -19.193330462067543
              ],
              [
                -51.39974134923252,
                -19.191040482448106
              ],
              [
                -51.41014980923698,
                -19.180857559268414
              ],
              [
                -51.40635435476601,
                -19.175894090004306
              ],
              [
                -51.41097532792026,
                -19.17246061240858
              ],
              [
                -51.416179272058066,
                -19.17383262154706
              ],
              [
                -51.41998626159762,
                -19.166075149439546
              ],
              [
                -51.42477272875028,
                -19.16537715987736
              ],
              [
                -51.42865917479365,
                -19.169201678532755
              ],
              [
                -51.43006518933836,
                -19.170585148877947
              ],
              [
                -51.437594106150804,
                -19.171232161269813
              ],
              [
                -51.44314308616253,
                -19.164709699025664
              ],
              [
                -51.44674805523095,
                -19.16047123545591
              ],
              [
                -51.45274652177312,
                -19.16173126614317
              ],
              [
                -51.45656148795626,
                -19.162532761305503
              ],
              [
                -51.45775548396929,
                -19.167126256589892
              ],
              [
                -51.46936389602827,
                -19.15801779557977
              ],
              [
                -51.47483089633091,
                -19.153728848005997
              ],
              [
                -51.47836337188037,
                -19.156909336685878
              ],
              [
                -51.48092330619027,
                -19.159213824835142
              ],
              [
                -51.49343220223644,
                -19.162532826942275
              ],
              [
                -51.50013467216151,
                -19.155840886392475
              ],
              [
                -51.500297193591024,
                -19.154206898194538
              ],
              [
                -51.500983195046345,
                -19.147315431105184
              ],
              [
                -51.5105081059689,
                -19.15044695239463
              ],
              [
                -51.510980602994984,
                -19.15060291790261
              ],
              [
                -51.51205512815554,
                -19.14983243614215
              ],
              [
                -51.52695304821907,
                -19.139146031660587
              ],
              [
                -51.533784986362726,
                -19.14119752692388
              ],
              [
                -51.53760344858726,
                -19.14234402554545
              ],
              [
                -51.53928490059363,
                -19.142849549578184
              ],
              [
                -51.544831890577925,
                -19.141299079818243
              ],
              [
                -51.55032135294969,
                -19.139765092835546
              ],
              [
                -51.55163033981791,
                -19.137076081337206
              ],
              [
                -51.55409335080553,
                -19.132016122507007
              ],
              [
                -51.575735696025745,
                -19.132103188658157
              ],
              [
                -51.57742265362958,
                -19.127812215354197
              ],
              [
                -51.57917567169768,
                -19.130223696970155
              ],
              [
                -51.59200959224612,
                -19.126951734676904
              ],
              [
                -51.595033014837746,
                -19.133234215098618
              ],
              [
                -51.5962904970263,
                -19.13584820053843
              ],
              [
                -51.60870591743397,
                -19.140416205834743
              ],
              [
                -51.612016900447514,
                -19.137355715136817
              ],
              [
                -51.61314690698865,
                -19.136312248743405
              ],
              [
                -51.620250331543296,
                -19.13718377106521
              ],
              [
                -51.62339778797573,
                -19.137570756408532
              ],
              [
                -51.625301792425994,
                -19.136289290075332
              ],
              [
                -51.63914869272308,
                -19.126967358652916
              ],
              [
                -51.648302156591186,
                -19.132212336544544
              ],
              [
                -51.65745604145761,
                -19.13745782545458
              ],
              [
                -51.69273083981099,
                -19.1177520058164
              ],
              [
                -51.69947726441749,
                -19.119218512556404
              ],
              [
                -51.7092822100551,
                -19.1119710693595
              ],
              [
                -51.71080920393116,
                -19.108018585064563
              ],
              [
                -51.70841325503876,
                -19.107217582512877
              ],
              [
                -51.70537078257111,
                -19.106201090270847
              ],
              [
                -51.712677229085706,
                -19.10237113327282
              ],
              [
                -51.714777692526226,
                -19.105160636118022
              ],
              [
                -51.71617667783528,
                -19.10701810735618
              ],
              [
                -51.72820659020161,
                -19.10776762589772
              ],
              [
                -51.7293871109062,
                -19.10408017051367
              ],
              [
                -51.73068961277208,
                -19.10001617813732
              ],
              [
                -51.7311460854303,
                -19.098590211723383
              ],
              [
                -51.73355458009439,
                -19.099090175662116
              ],
              [
                -51.739875008358574,
                -19.100401229005286
              ],
              [
                -51.74506200027388,
                -19.09460124441052
              ],
              [
                -51.74608002093852,
                -19.09346324814264
              ],
              [
                -51.742703058360185,
                -19.087371770863456
              ],
              [
                -51.759005419045636,
                -19.088113304397805
              ],
              [
                -51.76014989139016,
                -19.088165299103842
              ],
              [
                -51.762210393459966,
                -19.086621839334953
              ],
              [
                -51.77559634046234,
                -19.076595414783238
              ],
              [
                -51.778568778514035,
                -19.079010406562013
              ],
              [
                -51.78070878767294,
                -19.08074889224563
              ],
              [
                -51.79083869817287,
                -19.077665938117715
              ],
              [
                -51.789195719277,
                -19.073829931134252
              ],
              [
                -51.789070722036435,
                -19.073537442127602
              ],
              [
                -51.789743209326176,
                -19.073436955306118
              ],
              [
                -51.797432672464794,
                -19.072290450204598
              ],
              [
                -51.796518696801705,
                -19.068576968542676
              ],
              [
                -51.79592368608104,
                -19.066161495869242
              ],
              [
                -51.81524458319861,
                -19.054682107046077
              ],
              [
                -51.816841582282564,
                -19.053998595215823
              ],
              [
                -51.81843905436191,
                -19.053315589384376
              ],
              [
                -51.81943407055089,
                -19.053465111266444
              ],
              [
                -51.84082089269082,
                -19.056679144737235
              ],
              [
                -51.84339337141211,
                -19.052168166594452
              ],
              [
                -51.839794398340516,
                -19.049271654360975
              ],
              [
                -51.8351229546733,
                -19.045513176251184
              ],
              [
                -51.842268427074146,
                -19.041840198084167
              ],
              [
                -51.85039836085098,
                -19.043921228603057
              ],
              [
                -51.84959986609412,
                -19.04486570931297
              ],
              [
                -51.848286830432954,
                -19.046416723978943
              ],
              [
                -51.85436629651695,
                -19.05093018532506
              ],
              [
                -51.87065321573441,
                -19.035071817312545
              ],
              [
                -51.87156973863845,
                -19.029537854505293
              ],
              [
                -51.875037702195606,
                -19.030147332667166
              ],
              [
                -51.87971819520264,
                -19.02319887786499
              ],
              [
                -51.88226217605609,
                -19.01942092634959
              ],
              [
                -51.88287816608944,
                -19.019209428878835
              ],
              [
                -51.89073412650426,
                -19.016512941130966
              ],
              [
                -51.88949265020415,
                -19.01069248307538
              ],
              [
                -51.88889566155905,
                -19.0078939771093
              ],
              [
                -51.89831412989779,
                -18.993724573871482
              ],
              [
                -51.902664071642704,
                -18.993733585975995
              ],
              [
                -51.90701503438283,
                -18.993742601083913
              ],
              [
                -51.90934655674518,
                -18.990340120333677
              ],
              [
                -51.91425902665378,
                -18.98317015211022
              ],
              [
                -51.92246595043694,
                -18.983677180855818
              ],
              [
                -51.92514595612203,
                -18.983842662978507
              ],
              [
                -51.92539543695371,
                -18.982189678037333
              ],
              [
                -51.92633744067438,
                -18.97593670052062
              ],
              [
                -51.93321690233801,
                -18.975017745263617
              ],
              [
                -51.939822875737086,
                -18.96748779598004
              ],
              [
                -51.94556182085023,
                -18.974700277306436
              ],
              [
                -51.95181275026678,
                -18.97445928539983
              ],
              [
                -51.95169028467675,
                -18.975341267129167
              ],
              [
                -51.95100926582718,
                -18.980247755373707
              ],
              [
                -51.957561243822056,
                -18.9747782726953
              ],
              [
                -51.96778868131086,
                -18.96624035919116
              ],
              [
                -51.96667616185229,
                -18.97390381697026
              ],
              [
                -51.966535662154214,
                -18.974870825273722
              ],
              [
                -51.97250759184447,
                -18.974209337542238
              ],
              [
                -51.97770356001159,
                -18.98035180932248
              ],
              [
                -51.980138070676354,
                -18.973051857285018
              ],
              [
                -51.980899068508215,
                -18.970769858465363
              ],
              [
                -51.993132964802946,
                -18.97261590154286
              ],
              [
                -51.995458431614225,
                -18.972966403932702
              ],
              [
                -51.995684443179194,
                -18.972529875468105
              ],
              [
                -51.99840094194836,
                -18.96728344094048
              ],
              [
                -52.00409488638042,
                -18.96850894204144
              ],
              [
                -52.006995871622365,
                -18.972149903165818
              ],
              [
                -52.01510074389102,
                -18.982319896405006
              ],
              [
                -52.020182250213466,
                -18.978832920050802
              ],
              [
                -52.013958295883334,
                -18.972951411425736
              ],
              [
                -52.01927278751729,
                -18.967332469215542
              ],
              [
                -52.0193692680917,
                -18.967230961917476
              ],
              [
                -52.01815030697807,
                -18.963036496580298
              ],
              [
                -52.03467468896501,
                -18.95770503480721
              ],
              [
                -52.03535617895452,
                -18.9587560388475
              ],
              [
                -52.036767148373066,
                -18.960930555727536
              ],
              [
                -52.0411726355904,
                -18.959954557389818
              ],
              [
                -52.043085646519685,
                -18.95463509010873
              ],
              [
                -52.04473662632343,
                -18.950042599902417
              ],
              [
                -52.04942659125872,
                -18.95089913908467
              ],
              [
                -52.05614957068267,
                -18.94592617457468
              ],
              [
                -52.06126252011038,
                -18.949394665150404
              ],
              [
                -52.066398962318026,
                -18.952879636719913
              ],
              [
                -52.080868856383425,
                -18.950447706694273
              ],
              [
                -52.090298299812844,
                -18.94090323755736
              ],
              [
                -52.08897983617382,
                -18.936793293564534
              ],
              [
                -52.08845784862669,
                -18.935167277501964
              ],
              [
                -52.09013584697818,
                -18.935261294380467
              ],
              [
                -52.09548178268554,
                -18.93556028274033
              ],
              [
                -52.106538750503574,
                -18.92305037602645
              ],
              [
                -52.10239874929265,
                -18.92154738214823
              ],
              [
                -52.10445527524917,
                -18.91761188702301
              ],
              [
                -52.10850874688526,
                -18.91848389050037
              ],
              [
                -52.10725275383676,
                -18.91258041869897
              ],
              [
                -52.10573280622007,
                -18.905440947761342
              ],
              [
                -52.111213260446334,
                -18.898839491332136
              ],
              [
                -52.11295724146587,
                -18.901714465925767
              ],
              [
                -52.123928186317315,
                -18.896847521776003
              ],
              [
                -52.131015630876,
                -18.897269548021555
              ],
              [
                -52.13695707914488,
                -18.88945659917755
              ],
              [
                -52.14104605365045,
                -18.891000082732475
              ],
              [
                -52.14756398966779,
                -18.893460610403928
              ],
              [
                -52.15667945899937,
                -18.88797363326612
              ],
              [
                -52.15462799668684,
                -18.882103658270967
              ],
              [
                -52.16117694648167,
                -18.87819220551561
              ],
              [
                -52.158982956724046,
                -18.876037723541756
              ],
              [
                -52.16476040369913,
                -18.876203234575733
              ],
              [
                -52.16536995582383,
                -18.867124767742375
              ],
              [
                -52.165508452630704,
                -18.865058796339024
              ],
              [
                -52.1688959377459,
                -18.863263299029683
              ],
              [
                -52.17195143581107,
                -18.861643809083844
              ],
              [
                -52.17387540145087,
                -18.85781084714797
              ],
              [
                -52.17567689630619,
                -18.85422083981249
              ],
              [
                -52.17732939298284,
                -18.85463085420019
              ],
              [
                -52.179088878797465,
                -18.855067331743243
              ],
              [
                -52.179321881730345,
                -18.85412785654137
              ],
              [
                -52.18097738634871,
                -18.84745491162506
              ],
              [
                -52.18648285654458,
                -18.845422904778673
              ],
              [
                -52.19710125350458,
                -18.849638927047575
              ],
              [
                -52.198817243953954,
                -18.850320445384817
              ],
              [
                -52.20087824739499,
                -18.84868545396216
              ],
              [
                -52.20251071003039,
                -18.847390933921833
              ],
              [
                -52.20374821031687,
                -18.847960953535065
              ],
              [
                -52.207454662224386,
                -18.84966995536575
              ],
              [
                -52.20894465791529,
                -18.846599986898113
              ],
              [
                -52.2093686982575,
                -18.84572645288704
              ],
              [
                -52.218819111958794,
                -18.842097003259216
              ],
              [
                -52.227679062098005,
                -18.841277019562906
              ],
              [
                -52.25098037583883,
                -18.83911858075523
              ],
              [
                -52.25760485902823,
                -18.832773619023918
              ],
              [
                -52.26326382271123,
                -18.827353185692928
              ],
              [
                -52.26428631409734,
                -18.830227178469904
              ],
              [
                -52.29445659339697,
                -18.828820741857303
              ],
              [
                -52.29741006051031,
                -18.83456322478838
              ],
              [
                -52.29823354094751,
                -18.836163706754867
              ],
              [
                -52.30853545956341,
                -18.839256753311187
              ],
              [
                -52.32084389473258,
                -18.830558781575725
              ],
              [
                -52.3336252737389,
                -18.82778532262927
              ],
              [
                -52.33746079035431,
                -18.821487385510007
              ],
              [
                -52.34418771314514,
                -18.821078890532384
              ],
              [
                -52.34629271816871,
                -18.814883925525404
              ],
              [
                -52.353955668427005,
                -18.8122324359333
              ],
              [
                -52.35081320288919,
                -18.809958478035327
              ],
              [
                -52.35048023101115,
                -18.809717468258548
              ],
              [
                -52.353535215691856,
                -18.799748002508476
              ],
              [
                -52.36344663569398,
                -18.79914602451546
              ],
              [
                -52.369293094820904,
                -18.798791069036458
              ],
              [
                -52.36794614054611,
                -18.795295068175946
              ],
              [
                -52.367230652017234,
                -18.79344005861652
              ],
              [
                -52.37165910227169,
                -18.79211708782609
              ],
              [
                -52.37473306932051,
                -18.791198591772332
              ],
              [
                -52.37536608035736,
                -18.788472129541113
              ],
              [
                -52.37576509427217,
                -18.78675311322228
              ],
              [
                -52.376701058061215,
                -18.78687714305988
              ],
              [
                -52.37892907389569,
                -18.787172617433587
              ],
              [
                -52.37829957871856,
                -18.784967628653533
              ],
              [
                -52.37787307304543,
                -18.783475626214784
              ],
              [
                -52.3845320369876,
                -18.77752319275058
              ],
              [
                -52.39892746770203,
                -18.76465327486245
              ],
              [
                -52.3980959931673,
                -18.76132031659782
              ],
              [
                -52.39726449462933,
                -18.757987812333432
              ],
              [
                -52.402902447864705,
                -18.75637231891857
              ],
              [
                -52.40934490886129,
                -18.754525844584954
              ],
              [
                -52.41045193740865,
                -18.748599890825034
              ],
              [
                -52.410958917322944,
                -18.745885398219755
              ],
              [
                -52.41209842510616,
                -18.739787909312152
              ],
              [
                -52.42482137909941,
                -18.731183998106097
              ],
              [
                -52.42758938123382,
                -18.72747950167739
              ],
              [
                -52.44861977399986,
                -18.690506750122967
              ],
              [
                -52.461817211031864,
                -18.687801791767004
              ],
              [
                -52.475015130086035,
                -18.68509733340847
              ],
              [
                -52.50028046417502,
                -18.674820417502595
              ],
              [
                -52.52129380533128,
                -18.668860004522365
              ],
              [
                -52.52151231853281,
                -18.66750399411893
              ],
              [
                -52.52204732993126,
                -18.664180519285885
              ],
              [
                -52.53075775383125,
                -18.657565068912113
              ],
              [
                -52.5525361017083,
                -18.656531642907492
              ],
              [
                -52.55506606307947,
                -18.661231116423462
              ],
              [
                -52.55516807325362,
                -18.66126011255394
              ],
              [
                -52.560805043597185,
                -18.66287013373306
              ],
              [
                -52.566348500703725,
                -18.66445264980046
              ],
              [
                -52.58601480528266,
                -18.680471102654984
              ],
              [
                -52.58822377441992,
                -18.6835880774323
              ],
              [
                -52.592367747773395,
                -18.689435555015358
              ],
              [
                -52.597691219829436,
                -18.689474112417958
              ],
              [
                -52.616014057810254,
                -18.688898131703297
              ],
              [
                -52.6221245397953,
                -18.688705668143434
              ],
              [
                -52.62383049458631,
                -18.68969212411672
              ],
              [
                -52.63318191621211,
                -18.695101624973482
              ],
              [
                -52.64251036548081,
                -18.688245678191567
              ],
              [
                -52.64287137459411,
                -18.687980717288035
              ],
              [
                -52.67341417610744,
                -18.680135315010567
              ],
              [
                -52.678426150056424,
                -18.67961881908913
              ],
              [
                -52.68343959699981,
                -18.679101840173924
              ],
              [
                -52.69679296708195,
                -18.68361833924639
              ],
              [
                -52.71808129388285,
                -18.69081887273316
              ],
              [
                -52.72443773694633,
                -18.689639395192383
              ],
              [
                -52.730794706010904,
                -18.688460399650562
              ],
              [
                -52.73246421138804,
                -18.6886299071662
              ],
              [
                -52.749107068583704,
                -18.690316426237313
              ],
              [
                -52.75725600784728,
                -18.685510976311313
              ],
              [
                -52.76540449412163,
                -18.680706039377387
              ],
              [
                -52.773458943426036,
                -18.68075754573237
              ],
              [
                -52.78622980937867,
                -18.68083955901035
              ],
              [
                -52.79757976105347,
                -18.673681127644226
              ],
              [
                -52.80893020773979,
                -18.666523200267864
              ],
              [
                -52.8206431067945,
                -18.66968119125437
              ],
              [
                -52.82715306458729,
                -18.67143621014746
              ],
              [
                -52.83490302022297,
                -18.667207743622523
              ],
              [
                -52.83628699429962,
                -18.666453236490266
              ],
              [
                -52.83892048801898,
                -18.666511751963732
              ],
              [
                -52.84601091311567,
                -18.666668267399068
              ],
              [
                -52.84812340528727,
                -18.661158796109405
              ],
              [
                -52.872955799502634,
                -18.64573793160416
              ],
              [
                -52.91365551303546,
                -18.640193529048705
              ],
              [
                -52.91612346633365,
                -18.638706583908686
              ],
              [
                -52.91560300938417,
                -18.634339588824847
              ],
              [
                -52.91493503298784,
                -18.628731610838102
              ],
              [
                -52.91381806840078,
                -18.619363629290177
              ],
              [
                -52.92062500811327,
                -18.612943697976462
              ],
              [
                -52.92776694252562,
                -18.612891215437493
              ],
              [
                -52.939367392748316,
                -18.612806248425944
              ],
              [
                -52.93527241743234,
                -18.602110275126204
              ],
              [
                -52.933949936704806,
                -18.598655256987843
              ],
              [
                -52.94103542840497,
                -18.585461358248644
              ],
              [
                -52.947161908498146,
                -18.57995138717038
              ],
              [
                -52.95131440590503,
                -18.576217410224427
              ],
              [
                -52.95237440466293,
                -18.56785494981502
              ],
              [
                -52.9531249038119,
                -18.561934496848956
              ],
              [
                -52.95653089459162,
                -18.558209530959633
              ],
              [
                -52.96442537464135,
                -18.549572578171833
              ],
              [
                -52.96184790605055,
                -18.540364116254423
              ],
              [
                -52.94404609250445,
                -18.51902166926214
              ],
              [
                -52.91525983539519,
                -18.508446130925382
              ],
              [
                -52.907710452872024,
                -18.48877920283499
              ],
              [
                -52.903151476152516,
                -18.482577744746898
              ],
              [
                -52.898593018421764,
                -18.476376751671598
              ],
              [
                -52.880456181316866,
                -18.465039236007918
              ],
              [
                -52.873277352073885,
                -18.427667412733147
              ],
              [
                -52.79801143238765,
                -18.414406814757456
              ],
              [
                -52.7917889794813,
                -18.402131348756733
              ],
              [
                -52.785566566545796,
                -18.389856398796237
              ],
              [
                -52.78012962079847,
                -18.38558137417227
              ],
              [
                -52.76140079134106,
                -18.3708558917625
              ],
              [
                -52.758524876294516,
                -18.35196049091722
              ],
              [
                -52.75837488833141,
                -18.348348523676908
              ],
              [
                -52.766002843033824,
                -18.33924955448195
              ],
              [
                -52.78146876167183,
                -18.32513914734092
              ],
              [
                -52.78899770784887,
                -18.321221222734426
              ],
              [
                -52.79652666903252,
                -18.317303758122428
              ],
              [
                -52.82093952750451,
                -18.310605336278467
              ],
              [
                -52.871849132937825,
                -18.31418493408325
              ],
              [
                -52.876258590553846,
                -18.31290091787869
              ],
              [
                -52.88066809717102,
                -18.31161745267124
              ],
              [
                -52.92332429004206,
                -18.29946261392765
              ],
              [
                -52.93610970420424,
                -18.29779163647295
              ],
              [
                -52.936116222161274,
                -18.29779013549601
              ],
              [
                -52.94890812230309,
                -18.296118683060588
              ],
              [
                -52.97885139360523,
                -18.303874727803645
              ],
              [
                -53.00438264083835,
                -18.31961418010635
              ],
              [
                -53.00890860278511,
                -18.32265018502254
              ],
              [
                -53.01343556972351,
                -18.325686700944615
              ],
              [
                -53.01501606050216,
                -18.329240679156214
              ],
              [
                -53.02431794757117,
                -18.35015259886488
              ],
              [
                -53.029260874052106,
                -18.350818632377564
              ],
              [
                -53.03557833915274,
                -18.347675143238593
              ],
              [
                -53.04403278978222,
                -18.34346770219968
              ],
              [
                -53.06943309744257,
                -18.34242775986081
              ],
              [
                -53.09605851206586,
                -18.315090929017014
              ],
              [
                -53.098362460926325,
                -18.312388942785375
              ],
              [
                -53.10066698078264,
                -18.30968696355474
              ],
              [
                -53.10105203479577,
                -18.29309655100197
              ],
              [
                -53.101538044984856,
                -18.272168154967723
              ],
              [
                -53.10568156095555,
                -18.259887712826472
              ],
              [
                -53.10982504990787,
                -18.24760675469534
              ],
              [
                -53.10968413623531,
                -18.219110402783173
              ],
              [
                -53.1292346428384,
                -18.16025221817279
              ],
              [
                -53.12887418081919,
                -18.149141774661746
              ],
              [
                -53.12851420177307,
                -18.138031807167845
              ],
              [
                -53.13552871497478,
                -18.1154479491472
              ],
              [
                -53.14273524498626,
                -18.081386617742147
              ],
              [
                -53.1388308038206,
                -18.071186157460428
              ],
              [
                -53.12545489671366,
                -18.060260670879657
              ],
              [
                -53.103101069247515,
                -18.057411635009558
              ],
              [
                -53.08633871266791,
                -18.055275575885318
              ],
              [
                -53.07520829617299,
                -18.05034310526194
              ],
              [
                -53.071484380707645,
                -18.039018135614434
              ],
              [
                -53.069567528819334,
                -17.986086359609505
              ],
              [
                -53.076485028238466,
                -17.96824546259994
              ],
              [
                -53.08286797224082,
                -17.963458522817994
              ],
              [
                -53.085484449715096,
                -17.96149652407225
              ],
              [
                -53.099980920247255,
                -17.936364686443117
              ],
              [
                -53.130929718605834,
                -17.91639482128306
              ],
              [
                -53.127101793399646,
                -17.899567399214494
              ],
              [
                -53.132229801927686,
                -17.89087795079227
              ],
              [
                -53.13234278970058,
                -17.880986999400232
              ],
              [
                -53.136480308668936,
                -17.877009016422857
              ],
              [
                -53.14251226492541,
                -17.871209563273595
              ],
              [
                -53.13589790124296,
                -17.836606709206748
              ],
              [
                -53.16295277522187,
                -17.795061471137412
              ],
              [
                -53.160498323108015,
                -17.789345964784605
              ],
              [
                -53.15919133961734,
                -17.78630247525015
              ],
              [
                -53.16920184015641,
                -17.770064091732564
              ],
              [
                -53.16812183525279,
                -17.76581109619638
              ],
              [
                -53.17511826617188,
                -17.765397612191325
              ],
              [
                -53.17684478341732,
                -17.755415155272743
              ],
              [
                -53.18248075111863,
                -17.749745198549086
              ],
              [
                -53.18506923139087,
                -17.750933198546843
              ],
              [
                -53.18657423575729,
                -17.751624213126327
              ],
              [
                -53.18856973393208,
                -17.745816724105428
              ],
              [
                -53.1887932250648,
                -17.74516576357754
              ],
              [
                -53.189198245874,
                -17.744898248770845
              ],
              [
                -53.19782218923651,
                -17.739200301477425
              ],
              [
                -53.196737715931185,
                -17.737353314123283
              ],
              [
                -53.196076697667905,
                -17.73622781856378
              ],
              [
                -53.20032121205055,
                -17.730293828613412
              ],
              [
                -53.20736363887925,
                -17.727177361841825
              ],
              [
                -53.23783994346391,
                -17.713691483949134
              ],
              [
                -53.235626984123314,
                -17.706998003606685
              ],
              [
                -53.239457473280545,
                -17.70189057091318
              ],
              [
                -53.238924998222224,
                -17.696953587794873
              ],
              [
                -53.23869949239617,
                -17.69486258264033
              ],
              [
                -53.24003199899002,
                -17.694059590503358
              ],
              [
                -53.24637644973616,
                -17.6902341201881
              ],
              [
                -53.241166507335834,
                -17.686358122885736
              ],
              [
                -53.240457016818354,
                -17.685830135526754
              ],
              [
                -53.239568023051255,
                -17.679856667209364
              ],
              [
                -53.24278450293144,
                -17.67537270021759
              ],
              [
                -53.245265002352944,
                -17.67191567881239
              ],
              [
                -53.24350500176372,
                -17.67047969797549
              ],
              [
                -53.23976103667527,
                -17.66742420758413
              ],
              [
                -53.24431601933517,
                -17.66430874570363
              ],
              [
                -53.24362706964939,
                -17.65587077597699
              ],
              [
                -53.24479804117604,
                -17.65552826637193
              ],
              [
                -53.24806953829666,
                -17.654571801648256
              ],
              [
                -53.24547805411928,
                -17.65182831267382
              ],
              [
                -53.25034654065535,
                -17.643762362824912
              ],
              [
                -53.250321086598944,
                -17.627617401421247
              ],
              [
                -53.24676160020474,
                -17.62265491310066
              ],
              [
                -53.25070611763216,
                -17.61862846882693
              ],
              [
                -53.246011157704764,
                -17.610507983979236
              ],
              [
                -53.24928915056867,
                -17.605848001939513
              ],
              [
                -53.242431181484896,
                -17.601366531461736
              ],
              [
                -53.242374695558965,
                -17.592027066415255
              ],
              [
                -53.23979621922546,
                -17.590430556349485
              ],
              [
                -53.23817425448295,
                -17.589426568936723
              ],
              [
                -53.24021373621919,
                -17.585513080532653
              ],
              [
                -53.2417332510382,
                -17.58259860836202
              ],
              [
                -53.240533242854646,
                -17.58175358625913
              ],
              [
                -53.23867428661173,
                -17.58044411765169
              ],
              [
                -53.24122574549371,
                -17.57508464001319
              ],
              [
                -53.24240026459526,
                -17.572618169986768
              ],
              [
                -53.24157875678189,
                -17.571742653942323
              ],
              [
                -53.24011877332983,
                -17.570187149416547
              ],
              [
                -53.24207127557391,
                -17.5670716742059
              ],
              [
                -53.24748975059448,
                -17.558426734343858
              ],
              [
                -53.24336532163424,
                -17.554807744673578
              ],
              [
                -53.24061883163082,
                -17.552397737892555
              ],
              [
                -53.244025292397005,
                -17.54854874651827
              ],
              [
                -53.24532180297835,
                -17.547084268269142
              ],
              [
                -53.24408382080069,
                -17.541018789647538
              ],
              [
                -53.24257234110277,
                -17.533611348430167
              ],
              [
                -53.24334784850242,
                -17.533251334933247
              ],
              [
                -53.24615784083659,
                -17.531945870630715
              ],
              [
                -53.242359900971124,
                -17.522830377604013
              ],
              [
                -53.239306902068726,
                -17.515501415327694
              ],
              [
                -53.241160443802016,
                -17.51017493587768
              ],
              [
                -53.2429189314881,
                -17.505123985954082
              ],
              [
                -53.24257742115156,
                -17.50465894523018
              ],
              [
                -53.24011193560285,
                -17.501299986452825
              ],
              [
                -53.238667964093246,
                -17.49933297285502
              ],
              [
                -53.23930995634953,
                -17.49748551075793
              ],
              [
                -53.24106595604495,
                -17.49243353683988
              ],
              [
                -53.236237542647146,
                -17.482873560412088
              ],
              [
                -53.22345317037923,
                -17.457561641129058
              ],
              [
                -53.226873177629344,
                -17.45537814825905
              ],
              [
                -53.22686769174353,
                -17.445025690130525
              ],
              [
                -53.22808768740626,
                -17.444112229206574
              ],
              [
                -53.23293167824713,
                -17.44048474930346
              ],
              [
                -53.22820869212902,
                -17.43776573924721
              ],
              [
                -53.22982220023649,
                -17.426799802817737
              ],
              [
                -53.2234622531632,
                -17.425863801756776
              ],
              [
                -53.220509312980184,
                -17.414484841242103
              ],
              [
                -53.2141458778148,
                -17.408993835848943
              ],
              [
                -53.21211939486994,
                -17.407244862912915
              ],
              [
                -53.21297739673394,
                -17.406477357456673
              ],
              [
                -53.21495285521511,
                -17.404708871226546
              ],
              [
                -53.21157838459644,
                -17.40346388676713
              ],
              [
                -53.20606442833956,
                -17.401429866750732
              ],
              [
                -53.20768643928192,
                -17.395079877466436
              ],
              [
                -53.20817246075851,
                -17.393178386264374
              ],
              [
                -53.20909295501029,
                -17.385176957797455
              ],
              [
                -53.20553900692641,
                -17.383833937366298
              ],
              [
                -53.204290488085505,
                -17.383361941513535
              ],
              [
                -53.202766480919436,
                -17.389533916831002
              ],
              [
                -53.197017058544056,
                -17.380891924280142
              ],
              [
                -53.2009535729045,
                -17.371465986540706
              ],
              [
                -53.20383701173363,
                -17.37129149024779
              ],
              [
                -53.206219503068816,
                -17.371146989617444
              ],
              [
                -53.20371703388555,
                -17.370409482966846
              ],
              [
                -53.195877093973294,
                -17.368098977667827
              ],
              [
                -53.19512610051929,
                -17.361107014644315
              ],
              [
                -53.20254557269772,
                -17.36178054936406
              ],
              [
                -53.202660054852885,
                -17.361790560592908
              ],
              [
                -53.2033475619848,
                -17.353304561806418
              ],
              [
                -53.20185160292812,
                -17.352825602399896
              ],
              [
                -53.20020659106385,
                -17.352298086857342
              ],
              [
                -53.20070759915655,
                -17.343950103009632
              ],
              [
                -53.20370908810073,
                -17.343021640417266
              ],
              [
                -53.20629255270081,
                -17.342222154237465
              ],
              [
                -53.204077607513796,
                -17.341076651142778
              ],
              [
                -53.20173013345388,
                -17.33986215604564
              ],
              [
                -53.20390010449073,
                -17.333481650254765
              ],
              [
                -53.20569508410102,
                -17.332538680838464
              ],
              [
                -53.217248540236724,
                -17.326469715080037
              ],
              [
                -53.21282358752934,
                -17.32197372794113
              ],
              [
                -53.216110565007,
                -17.31966827228791
              ],
              [
                -53.210207136666355,
                -17.313704768300006
              ],
              [
                -53.21348512397926,
                -17.307625827793537
              ],
              [
                -53.21820208725984,
                -17.30917031004025
              ],
              [
                -53.21813907941519,
                -17.298857874810853
              ],
              [
                -53.20790768293837,
                -17.292912879435892
              ],
              [
                -53.20817722062592,
                -17.284556423129537
              ],
              [
                -53.198644306676094,
                -17.277632908921667
              ],
              [
                -53.195539825698454,
                -17.275378438789755
              ],
              [
                -53.19590281622717,
                -17.272620938221436
              ],
              [
                -53.197617834646266,
                -17.259577501761253
              ],
              [
                -53.18771493641618,
                -17.257653491939852
              ],
              [
                -53.18542895090834,
                -17.25720947551922
              ],
              [
                -53.18031698989698,
                -17.24385801825592
              ],
              [
                -53.1748440509242,
                -17.240409561969493
              ],
              [
                -53.17137311933809,
                -17.224649113672193
              ],
              [
                -53.17070511192816,
                -17.22161362494824
              ],
              [
                -53.169747625558145,
                -17.221309615061116
              ],
              [
                -53.16546565968265,
                -17.219949622097214
              ],
              [
                -53.166297170164604,
                -17.213563149251655
              ],
              [
                -53.16682267478267,
                -17.209528151937604
              ],
              [
                -53.157874292296356,
                -17.192204752889886
              ],
              [
                -53.155795307500675,
                -17.188179728367377
              ],
              [
                -53.155862799778745,
                -17.187104254446815
              ],
              [
                -53.1569298575287,
                -17.17019482934197
              ],
              [
                -53.149288893397724,
                -17.1673788441285
              ],
              [
                -53.14494094158099,
                -17.169297328046117
              ],
              [
                -53.141905443862136,
                -17.170636789722842
              ],
              [
                -53.14023797219548,
                -17.16345581265457
              ],
              [
                -53.13973349437052,
                -17.16128334240937
              ],
              [
                -53.138469496474606,
                -17.161259337526598
              ],
              [
                -53.13501403936624,
                -17.161192844650216
              ],
              [
                -53.13256608132289,
                -17.155553844688466
              ],
              [
                -53.127650605979234,
                -17.154192332294677
              ],
              [
                -53.1298690938625,
                -17.149208866381137
              ],
              [
                -53.131733082240125,
                -17.14502238097699
              ],
              [
                -53.12691210481851,
                -17.143816396102967
              ],
              [
                -53.12662961002141,
                -17.14374540176035
              ],
              [
                -53.11504225609516,
                -17.12529893597114
              ],
              [
                -53.11588923409215,
                -17.12371047025971
              ],
              [
                -53.11862573961087,
                -17.118581488255565
              ],
              [
                -53.115123255247106,
                -17.116398494003782
              ],
              [
                -53.11862574343997,
                -17.11277350290797
              ],
              [
                -53.108142352422405,
                -17.107301012289287
              ],
              [
                -53.10505085786936,
                -17.108350522176426
              ],
              [
                -53.10145437949726,
                -17.10957047408909
              ],
              [
                -53.098888903755714,
                -17.106083483050668
              ],
              [
                -53.10002241587263,
                -17.104032995142056
              ],
              [
                -53.10104643327049,
                -17.10217951907031
              ],
              [
                -53.09626542499198,
                -17.100806517102573
              ],
              [
                -53.087209503448406,
                -17.098205995699967
              ],
              [
                -53.089584505439035,
                -17.095070022709873
              ],
              [
                -53.09181251598345,
                -17.09212955247439
              ],
              [
                -53.0870185264235,
                -17.09286655178924
              ],
              [
                -53.0851750478987,
                -17.093150046141744
              ],
              [
                -53.08591354375336,
                -17.091917047549572
              ],
              [
                -53.08976203556405,
                -17.085494598139164
              ],
              [
                -53.08121808663829,
                -17.0878860580173
              ],
              [
                -53.07908857836709,
                -17.088482028260458
              ],
              [
                -53.07945010216262,
                -17.08636806683217
              ],
              [
                -53.08063812532557,
                -17.07942859354145
              ],
              [
                -53.07729413450539,
                -17.07894209890533
              ],
              [
                -53.07445413685718,
                -17.082078078794577
              ],
              [
                -53.07154316251627,
                -17.085293061152505
              ],
              [
                -53.072600653180324,
                -17.08048655275073
              ],
              [
                -53.07334065143192,
                -17.077127092946537
              ],
              [
                -53.07152216691865,
                -17.0773370996996
              ],
              [
                -53.06795219843291,
                -17.07774957939825
              ],
              [
                -53.0667382343632,
                -17.072940077670776
              ],
              [
                -53.06087177255122,
                -17.07719506828383
              ],
              [
                -53.06173025032746,
                -17.070935091113927
              ],
              [
                -53.0555168364028,
                -17.069923067154367
              ],
              [
                -53.05787534512481,
                -17.058666623527806
              ],
              [
                -53.0660942780076,
                -17.058768675382368
              ],
              [
                -53.066034782678464,
                -17.053556192240976
              ],
              [
                -53.06601529111512,
                -17.051864691983738
              ],
              [
                -53.06565328260569,
                -17.051904677948716
              ],
              [
                -53.0603493410826,
                -17.052496685756275
              ],
              [
                -53.06326031192974,
                -17.04513622649614
              ],
              [
                -53.06459129047229,
                -17.045895188127652
              ],
              [
                -53.072045244302544,
                -17.050147703062372
              ],
              [
                -53.06998329151911,
                -17.04169926112031
              ],
              [
                -53.06546232896858,
                -17.03851573116423
              ],
              [
                -53.06848331615529,
                -17.02952330468531
              ],
              [
                -53.06236985790575,
                -17.0291367911155
              ],
              [
                -53.061036894886726,
                -17.019892808592402
              ],
              [
                -53.060829386453506,
                -17.019860839252992
              ],
              [
                -53.05509594976634,
                -17.018969305913448
              ],
              [
                -53.06024389382559,
                -17.017422340120746
              ],
              [
                -53.06320386388587,
                -17.01653334716192
              ],
              [
                -53.06213538795788,
                -17.012419877619795
              ],
              [
                -53.0589679100585,
                -17.013437341500733
              ],
              [
                -53.05556794663788,
                -17.014528826494768
              ],
              [
                -53.05745794613261,
                -17.00899087246386
              ],
              [
                -53.04942853264339,
                -16.99238943923665
              ],
              [
                -53.05145401725463,
                -16.989971937136662
              ],
              [
                -53.05347902386907,
                -16.9875544840349
              ],
              [
                -53.05465306251675,
                -16.96299507716422
              ],
              [
                -53.05118161576543,
                -16.96085707992268
              ],
              [
                -53.04837211158604,
                -16.96612306903117
              ],
              [
                -53.044232658114154,
                -16.953486610733222
              ],
              [
                -53.04604568030795,
                -16.948263116107494
              ],
              [
                -53.041626205632745,
                -16.94561262703539
              ],
              [
                -53.03362475502106,
                -16.940814157536874
              ],
              [
                -53.03600975710973,
                -16.93876366458593
              ],
              [
                -53.03275831868255,
                -16.92991418892576
              ],
              [
                -53.035840768799154,
                -16.93063368678943
              ],
              [
                -53.03860075441449,
                -16.931278206248418
              ],
              [
                -53.03319983069667,
                -16.920310253386166
              ],
              [
                -53.039121313434,
                -16.911512772897574
              ],
              [
                -53.02948538555025,
                -16.90947427287594
              ],
              [
                -53.03131337610774,
                -16.904046300261662
              ],
              [
                -53.02412042678292,
                -16.90079481156295
              ],
              [
                -53.01842800201416,
                -16.89822080222909
              ],
              [
                -53.015843546012974,
                -16.884484376855198
              ],
              [
                -53.0113970497043,
                -16.884146861075838
              ],
              [
                -53.01524555955086,
                -16.88039737740513
              ],
              [
                -53.01726503202522,
                -16.878429894220524
              ],
              [
                -53.013625079499995,
                -16.876672411902362
              ],
              [
                -53.01240257473479,
                -16.87608238679497
              ],
              [
                -53.012589068888374,
                -16.874292416526522
              ],
              [
                -53.013221610547575,
                -16.86823446008336
              ],
              [
                -53.01361661318588,
                -16.86445745751484
              ],
              [
                -53.01082711641795,
                -16.857570005977134
              ],
              [
                -53.00565615100386,
                -16.863735454368012
              ],
              [
                -53.001014188544644,
                -16.8592274734977
              ],
              [
                -52.99778570703453,
                -16.865069429895794
              ],
              [
                -52.989389790108184,
                -16.8643004229609
              ],
              [
                -52.97579287465534,
                -16.863054870164657
              ],
              [
                -52.96669694502274,
                -16.868174829271542
              ],
              [
                -52.96370696251283,
                -16.866554836849907
              ],
              [
                -52.9641849463834,
                -16.858448901603776
              ],
              [
                -52.964210464283205,
                -16.858016388671835
              ],
              [
                -52.96315996532505,
                -16.858211366328035
              ],
              [
                -52.95347354827012,
                -16.860006842506102
              ],
              [
                -52.95468302856526,
                -16.856424384956725
              ],
              [
                -52.95673453206578,
                -16.850346412956842
              ],
              [
                -52.949233074777105,
                -16.85317186442548
              ],
              [
                -52.949539569535965,
                -16.8555873769115
              ],
              [
                -52.950095579601886,
                -16.85996182087559
              ],
              [
                -52.944150111626925,
                -16.85580835038062
              ],
              [
                -52.94441964133134,
                -16.85475435190454
              ],
              [
                -52.94694961097023,
                -16.84484690682383
              ],
              [
                -52.952486081698616,
                -16.84147843731868
              ],
              [
                -52.92661480448302,
                -16.840571380570626
              ],
              [
                -52.92857875610971,
                -16.838284917758184
              ],
              [
                -52.931678245125184,
                -16.83538492643047
              ],
              [
                -52.92845277927368,
                -16.82696945511516
              ],
              [
                -52.94392920588013,
                -16.812712557286766
              ],
              [
                -52.93518277930878,
                -16.810018533586955
              ],
              [
                -52.932148337002474,
                -16.802423569928255
              ],
              [
                -52.926491348182786,
                -16.804584061785793
              ],
              [
                -52.925680854223664,
                -16.804893553470205
              ],
              [
                -52.92585384435309,
                -16.810360517412917
              ],
              [
                -52.926052832185455,
                -16.81667150649091
              ],
              [
                -52.925196852220076,
                -16.81631249718145
              ],
              [
                -52.91432345859111,
                -16.811749501272672
              ],
              [
                -52.907178504643234,
                -16.80982600870761
              ],
              [
                -52.90560150824898,
                -16.798472043967312
              ],
              [
                -52.89189912836604,
                -16.7929290451498
              ],
              [
                -52.88803969565831,
                -16.78706404159002
              ],
              [
                -52.8910131613886,
                -16.785831076206225
              ],
              [
                -52.89376013151159,
                -16.78469208086073
              ],
              [
                -52.881786748886704,
                -16.775663608348115
              ],
              [
                -52.873153826485876,
                -16.778625061608814
              ],
              [
                -52.86687037223277,
                -16.78078056104978
              ],
              [
                -52.8628188842392,
                -16.774376070605094
              ],
              [
                -52.85846046664741,
                -16.767486583731262
              ],
              [
                -52.85467646847456,
                -16.771650079559894
              ],
              [
                -52.853598482268715,
                -16.772836034534798
              ],
              [
                -52.8523574637812,
                -16.771386542444223
              ],
              [
                -52.84813051239823,
                -16.766449076767923
              ],
              [
                -52.83593408637406,
                -16.769518014317015
              ],
              [
                -52.830764621278156,
                -16.77081900232207
              ],
              [
                -52.83059264525266,
                -16.768910521846724
              ],
              [
                -52.83020263780408,
                -16.764580520190236
              ],
              [
                -52.829785160283876,
                -16.759954585857216
              ],
              [
                -52.822821214932006,
                -16.757690068409527
              ],
              [
                -52.80690089374708,
                -16.740981611956002
              ],
              [
                -52.80145339167218,
                -16.74631908243858
              ],
              [
                -52.8006234226228,
                -16.747132044723383
              ],
              [
                -52.792073009510105,
                -16.739289596781877
              ],
              [
                -52.78548405227374,
                -16.740744565817803
              ],
              [
                -52.78706103699371,
                -16.72981512764695
              ],
              [
                -52.7881120504673,
                -16.7225281352088
              ],
              [
                -52.779208660545066,
                -16.707530691079597
              ],
              [
                -52.77150571971796,
                -16.706805171809656
              ],
              [
                -52.761255803805206,
                -16.70584068282265
              ],
              [
                -52.76223481741385,
                -16.681668289529934
              ],
              [
                -52.75470690684083,
                -16.6718108185909
              ],
              [
                -52.762343873715444,
                -16.671896331670307
              ],
              [
                -52.76871179475495,
                -16.671968370909855
              ],
              [
                -52.770761285636624,
                -16.668065890927828
              ],
              [
                -52.75431842826889,
                -16.662214369791094
              ],
              [
                -52.74947346965996,
                -16.657928384852994
              ],
              [
                -52.74604201680859,
                -16.654892388278526
              ],
              [
                -52.73993157716306,
                -16.656352868441555
              ],
              [
                -52.742388044798815,
                -16.650538420397297
              ],
              [
                -52.747509053781776,
                -16.638413484120143
              ],
              [
                -52.74636956099806,
                -16.630264493850586
              ],
              [
                -52.73917961736629,
                -16.629587476622238
              ],
              [
                -52.73876763942367,
                -16.629548995862898
              ],
              [
                -52.73228666766092,
                -16.634180969270314
              ],
              [
                -52.72404170312504,
                -16.646327879281575
              ],
              [
                -52.71520526215597,
                -16.638819407439723
              ],
              [
                -52.72559577887699,
                -16.614183042149286
              ],
              [
                -52.737122675030555,
                -16.61252809022682
              ],
              [
                -52.740597184948065,
                -16.608898080240415
              ],
              [
                -52.74032266224014,
                -16.603466621189447
              ],
              [
                -52.73062277737796,
                -16.59759813872729
              ],
              [
                -52.737316712444986,
                -16.59098318113889
              ],
              [
                -52.73895269964049,
                -16.58936670248019
              ],
              [
                -52.73072978119062,
                -16.585928196958793
              ],
              [
                -52.72483184458757,
                -16.588795656016956
              ],
              [
                -52.706252949126664,
                -16.597826592142297
              ],
              [
                -52.70692294310066,
                -16.585657130048194
              ],
              [
                -52.7074854970118,
                -16.575455193446146
              ],
              [
                -52.70364503700252,
                -16.570809199631643
              ],
              [
                -52.69117258518468,
                -16.58249162714418
              ],
              [
                -52.68976359109758,
                -16.58381112071298
              ],
              [
                -52.67500971049628,
                -16.584549063669733
              ],
              [
                -52.67020371837741,
                -16.58157256579139
              ],
              [
                -52.672034716318,
                -16.57415911695847
              ],
              [
                -52.67340373500452,
                -16.56861715224118
              ],
              [
                -52.667433266621,
                -16.56917711103004
              ],
              [
                -52.65967635969755,
                -16.56990461796894
              ],
              [
                -52.6638578218202,
                -16.56530613619324
              ],
              [
                -52.66981930090848,
                -16.55874969070871
              ],
              [
                -52.66812479930541,
                -16.550064241902287
              ],
              [
                -52.66220984754634,
                -16.55061521387366
              ],
              [
                -52.65573490678069,
                -16.556512163303857
              ],
              [
                -52.65076441752808,
                -16.561038657623868
              ],
              [
                -52.65036044668226,
                -16.55069320161467
              ],
              [
                -52.65026244851979,
                -16.548179212279365
              ],
              [
                -52.6475394889306,
                -16.546210692410895
              ],
              [
                -52.64651601210601,
                -16.5454707205888
              ],
              [
                -52.63526204222751,
                -16.551187666019338
              ],
              [
                -52.63419811622893,
                -16.537511724309283
              ],
              [
                -52.62565315077141,
                -16.533002721284305
              ],
              [
                -52.62926616360729,
                -16.517447295117794
              ],
              [
                -52.62952017437477,
                -16.51720179585544
              ],
              [
                -52.634730657096185,
                -16.512164839503047
              ],
              [
                -52.637064668861356,
                -16.502468909704582
              ],
              [
                -52.63207968689593,
                -16.49994589838005
              ],
              [
                -52.62125326559883,
                -16.500885375443286
              ],
              [
                -52.61209835762861,
                -16.5016793499774
              ],
              [
                -52.60539642258492,
                -16.481634446879816
              ],
              [
                -52.610628408545956,
                -16.473116968098225
              ],
              [
                -52.612815394422505,
                -16.469556492926095
              ],
              [
                -52.61134941511455,
                -16.46867751378765
              ],
              [
                -52.6039009945974,
                -16.464213502163705
              ],
              [
                -52.61198941786875,
                -16.457797048861696
              ],
              [
                -52.61623540103474,
                -16.461201552283875
              ],
              [
                -52.62023836942212,
                -16.464411541086484
              ],
              [
                -52.62090235749786,
                -16.456628562510048
              ],
              [
                -52.61999888887668,
                -16.45544308409734
              ],
              [
                -52.61565343397292,
                -16.44974562233773
              ],
              [
                -52.628714344397764,
                -16.442107689030873
              ],
              [
                -52.638068286286995,
                -16.43663719507281
              ],
              [
                -52.644649252161095,
                -16.42578576739584
              ],
              [
                -52.64257830289337,
                -16.42089432194877
              ],
              [
                -52.64062581409363,
                -16.41628231044777
              ],
              [
                -52.64295630894786,
                -16.412296842615355
              ],
              [
                -52.65001526597002,
                -16.408228876814025
              ],
              [
                -52.66167616514208,
                -16.40985138635498
              ],
              [
                -52.67614955715643,
                -16.411864910383372
              ],
              [
                -52.68790253130539,
                -16.395856012780165
              ],
              [
                -52.68207160197984,
                -16.387676063480463
              ],
              [
                -52.677510106217724,
                -16.381278074596274
              ],
              [
                -52.68479156654796,
                -16.374771118851097
              ],
              [
                -52.69248703279884,
                -16.367893674789705
              ],
              [
                -52.68923259188449,
                -16.35639970505983
              ],
              [
                -52.692706103634485,
                -16.350701220920524
              ],
              [
                -52.690169626135265,
                -16.34892825365074
              ],
              [
                -52.681815674242046,
                -16.343089236654322
              ],
              [
                -52.68520868496593,
                -16.32806783765632
              ],
              [
                -52.681181256750975,
                -16.32064487192705
              ],
              [
                -52.68643121347685,
                -16.31503788434619
              ],
              [
                -52.68109676368038,
                -16.303429462622425
              ],
              [
                -52.673306850733056,
                -16.29590097402484
              ],
              [
                -52.65656651585329,
                -16.279722509656718
              ],
              [
                -52.649329047108,
                -16.283812970004142
              ],
              [
                -52.64728604603619,
                -16.284967953937834
              ],
              [
                -52.63699415242313,
                -16.283148457623124
              ],
              [
                -52.623013723948844,
                -16.286933896358835
              ],
              [
                -52.6166382848002,
                -16.27942443093502
              ],
              [
                -52.610263373640606,
                -16.27191496353413
              ],
              [
                -52.602915433184776,
                -16.270385928441513
              ],
              [
                -52.5862185386971,
                -16.266910926801888
              ],
              [
                -52.58257109866778,
                -16.25698498108146
              ],
              [
                -52.58042611904462,
                -16.251148493188325
              ],
              [
                -52.57592914933622,
                -16.24951051200211
              ],
              [
                -52.56718621993726,
                -16.258882438744312
              ],
              [
                -52.561843212752805,
                -16.26460889847575
              ],
              [
                -52.55457528270805,
                -16.265197894468216
              ],
              [
                -52.54681134403955,
                -16.260846378976783
              ],
              [
                -52.54584835958838,
                -16.258076902074745
              ],
              [
                -52.55378531432778,
                -16.24846644769975
              ],
              [
                -52.56880376049775,
                -16.230281094078002
              ],
              [
                -52.56621426763833,
                -16.224617081412138
              ],
              [
                -52.55610387637378,
                -16.223813055787144
              ],
              [
                -52.54520393296405,
                -16.222945075719654
              ],
              [
                -52.54894744849881,
                -16.202975662641165
              ],
              [
                -52.54974347022919,
                -16.18889174286557
              ],
              [
                -52.54975348259174,
                -16.188708727764546
              ],
              [
                -52.54969700116836,
                -16.188637234981787
              ],
              [
                -52.54312653823487,
                -16.18032324925407
              ],
              [
                -52.54503804667257,
                -16.17433577718737
              ],
              [
                -52.548123061365985,
                -16.1646738293367
              ],
              [
                -52.5320156761862,
                -16.158009839640428
              ],
              [
                -52.52660022250913,
                -16.155769829405116
              ],
              [
                -52.526082226550535,
                -16.150571894202074
              ],
              [
                -52.52503978880913,
                -16.140115429085867
              ],
              [
                -52.51650585786136,
                -16.134803919731336
              ],
              [
                -52.51304188293548,
                -16.133487440401776
              ],
              [
                -52.50401192740273,
                -16.139666393774025
              ],
              [
                -52.500227436558134,
                -16.142255345976004
              ],
              [
                -52.49410749780633,
                -16.13627939516669
              ],
              [
                -52.48798706405388,
                -16.130303885369997
              ],
              [
                -52.48639558342009,
                -16.129926397671188
              ],
              [
                -52.470998679067016,
                -16.126276884237402
              ],
              [
                -52.457927301516264,
                -16.127053363661748
              ],
              [
                -52.43701049446187,
                -16.109663889255863
              ],
              [
                -52.39735028701176,
                -16.093000381267526
              ],
              [
                -52.37462395351578,
                -16.09795032439514
              ],
              [
                -52.369996002473286,
                -16.094754338643472
              ],
              [
                -52.369186006367805,
                -16.091760844313768
              ],
              [
                -52.367092060686126,
                -16.08402589005928
              ],
              [
                -52.36039059911023,
                -16.08189837561033
              ],
              [
                -52.34979665991243,
                -16.083498363607706
              ],
              [
                -52.34976767011259,
                -16.0835023345248
              ],
              [
                -52.32726787281746,
                -16.06815137533651
              ],
              [
                -52.32057395622313,
                -16.044691980172107
              ],
              [
                -52.32108447945898,
                -16.0430154804059
              ],
              [
                -52.32287145929035,
                -16.03714352974604
              ],
              [
                -52.31761899638158,
                -16.03068655751296
              ],
              [
                -52.31492703559,
                -16.027377056650845
              ],
              [
                -52.3099891080356,
                -16.006955637803767
              ],
              [
                -52.28964379659621,
                -15.984919716254588
              ],
              [
                -52.289109829835176,
                -15.975319234686076
              ],
              [
                -52.28857637105248,
                -15.965719787127533
              ],
              [
                -52.272574011866475,
                -15.95279984003397
              ],
              [
                -52.264817100547184,
                -15.946536365595662
              ],
              [
                -52.264194107021744,
                -15.941386855275855
              ],
              [
                -52.26365562625195,
                -15.93693838773517
              ],
              [
                -52.26118264191325,
                -15.934884898344588
              ],
              [
                -52.25776667421638,
                -15.932047917717439
              ],
              [
                -52.25098527804256,
                -15.90801003596452
              ],
              [
                -52.25186027868617,
                -15.897229567353342
              ],
              [
                -52.25188976531811,
                -15.896864565194752
              ],
              [
                -52.252231772600645,
                -15.892650575458594
              ],
              [
                -52.244696345896436,
                -15.890588105168693
              ],
              [
                -52.235287446744266,
                -15.888531071798926
              ],
              [
                -52.21648506158241,
                -15.889077049397267
              ],
              [
                -52.209881591950825,
                -15.889268517158026
              ],
              [
                -52.20118268316373,
                -15.885713533655208
              ],
              [
                -52.19248376038447,
                -15.882158035167361
              ],
              [
                -52.1280416808976,
                -15.894513353350279
              ],
              [
                -52.10827535183923,
                -15.882147344046816
              ],
              [
                -52.10161441502331,
                -15.88567583038764
              ],
              [
                -52.096820419948294,
                -15.888215301600251
              ],
              [
                -52.09244398674441,
                -15.883434309527614
              ],
              [
                -52.091318480749834,
                -15.88220483411049
              ],
              [
                -52.080935595269345,
                -15.880402329493325
              ],
              [
                -52.057620747395745,
                -15.886971235954038
              ],
              [
                -52.00755512263166,
                -15.885036626296715
              ],
              [
                -52.00170813442177,
                -15.878838175076803
              ],
              [
                -51.995861709201485,
                -15.872640704869257
              ],
              [
                -51.98279139416522,
                -15.843540804579826
              ],
              [
                -51.972204457232245,
                -15.837411301914972
              ],
              [
                -51.95931506046276,
                -15.839817293357887
              ],
              [
                -51.95364861764472,
                -15.83722177193051
              ],
              [
                -51.95466462064573,
                -15.827462322111204
              ],
              [
                -51.95533663156955,
                -15.82100286132893
              ],
              [
                -51.94634869989648,
                -15.81011989357508
              ],
              [
                -51.91154148132998,
                -15.81313032603422
              ],
              [
                -51.89177908495441,
                -15.829845178261838
              ],
              [
                -51.883636625481266,
                -15.826697188287431
              ],
              [
                -51.880826667440246,
                -15.825611171606749
              ],
              [
                -51.87911967138826,
                -15.824951194195037
              ],
              [
                -51.877813708974614,
                -15.819243214528399
              ],
              [
                -51.87374678627759,
                -15.801466807465983
              ],
              [
                -51.865786859749896,
                -15.793820804133158
              ],
              [
                -51.85782692721841,
                -15.78617432982872
              ],
              [
                -51.84404806137736,
                -15.765248918707224
              ],
              [
                -51.83894159905004,
                -15.756711960988248
              ],
              [
                -51.835036146383395,
                -15.750183976921589
              ],
              [
                -51.832734194824546,
                -15.74637900282302
              ],
              [
                -51.83109621026175,
                -15.74396351327325
              ],
              [
                -51.82577376402238,
                -15.737387016449194
              ],
              [
                -51.817458331868515,
                -15.7320115473148
              ],
              [
                -51.80329344795939,
                -15.72285604945174
              ],
              [
                -51.803167464895864,
                -15.715801575125118
              ],
              [
                -51.803040964830124,
                -15.708746138809135
              ],
              [
                -51.76248492346081,
                -15.645193349991494
              ],
              [
                -51.75319903413018,
                -15.609735528617263
              ],
              [
                -51.77543049259505,
                -15.557844849787744
              ],
              [
                -51.775810546070154,
                -15.537214429327948
              ],
              [
                -51.76136766481186,
                -15.531212450837115
              ],
              [
                -51.75393669357303,
                -15.54837083168732
              ],
              [
                -51.75157266158099,
                -15.553829327510698
              ],
              [
                -51.74162623449973,
                -15.55634426702541
              ],
              [
                -51.727322358723576,
                -15.5501612707212
              ],
              [
                -51.72551338379341,
                -15.542182827699522
              ],
              [
                -51.733703890383204,
                -15.526385913720022
              ],
              [
                -51.73556584383285,
                -15.522793944552888
              ],
              [
                -51.73498087973599,
                -15.510430007013651
              ],
              [
                -51.72548297277767,
                -15.502034036980145
              ],
              [
                -51.70419014704989,
                -15.496257013156335
              ],
              [
                -51.699495711441685,
                -15.484090544115576
              ],
              [
                -51.69909023743873,
                -15.473300620208922
              ],
              [
                -51.70793021835322,
                -15.456035701050634
              ],
              [
                -51.69239836530797,
                -15.432810803795315
              ],
              [
                -51.69104189711623,
                -15.426093845594563
              ],
              [
                -51.694497383343254,
                -15.421386350381622
              ],
              [
                -51.69746234766603,
                -15.417347409796097
              ],
              [
                -51.69714787924559,
                -15.40955142018572
              ],
              [
                -51.688909953961414,
                -15.397542982176704
              ],
              [
                -51.67089864448161,
                -15.371289074332113
              ],
              [
                -51.67315714857949,
                -15.356987649798318
              ],
              [
                -51.679142141962004,
                -15.351523216661471
              ],
              [
                -51.685763066985615,
                -15.345479237750897
              ],
              [
                -51.68781763563714,
                -15.308417454119008
              ],
              [
                -51.68306671730235,
                -15.29931847599781
              ],
              [
                -51.67831623695006,
                -15.290220007898263
              ],
              [
                -51.67823624265879,
                -15.290163019019253
              ],
              [
                -51.64645753026067,
                -15.267507080304664
              ],
              [
                -51.64706752168191,
                -15.260263097039758
              ],
              [
                -51.65749099082637,
                -15.256057658733823
              ],
              [
                -51.65928546470235,
                -15.254245165563736
              ],
              [
                -51.66754192172101,
                -15.245907210583766
              ],
              [
                -51.654027596771776,
                -15.210473876256028
              ],
              [
                -51.65242511163099,
                -15.206273400136755
              ],
              [
                -51.65202012385144,
                -15.192855457973527
              ],
              [
                -51.651614165049594,
                -15.179437517834025
              ],
              [
                -51.62838485859469,
                -15.165169053186053
              ],
              [
                -51.61773346857722,
                -15.164138043547151
              ],
              [
                -51.60668103158864,
                -15.163067512292386
              ],
              [
                -51.597763623215556,
                -15.155005530769833
              ],
              [
                -51.583059795646314,
                -15.129631164040397
              ],
              [
                -51.573551395631185,
                -15.113222721689526
              ],
              [
                -51.562907950783824,
                -15.109337706634467
              ],
              [
                -51.55226406295138,
                -15.105452219597542
              ],
              [
                -51.54141215881708,
                -15.0820447991748
              ],
              [
                -51.5354817617538,
                -15.069250879024667
              ],
              [
                -51.52176236628621,
                -15.057991420282605
              ],
              [
                -51.511898455554935,
                -15.054199906487828
              ],
              [
                -51.47726721532853,
                -15.056282812509496
              ],
              [
                -51.45193144543921,
                -15.033614898423748
              ],
              [
                -51.44657096690382,
                -15.03164339259188
              ],
              [
                -51.44121053537183,
                -15.029671400766782
              ],
              [
                -51.425682181306875,
                -15.007633499624097
              ],
              [
                -51.39787841574914,
                -15.003039972799925
              ],
              [
                -51.37632254657686,
                -15.012426868285258
              ],
              [
                -51.36955107890601,
                -15.00882888495746
              ],
              [
                -51.35822867377156,
                -15.002812372793363
              ],
              [
                -51.35678670598054,
                -15.0020464007919
              ],
              [
                -51.349577273884194,
                -14.991265924314197
              ],
              [
                -51.348565261538155,
                -14.989752433006533
              ],
              [
                -51.34359832882026,
                -14.982325445948842
              ],
              [
                -51.33731791391672,
                -14.972934502305645
              ],
              [
                -51.32212852563213,
                -14.971374996657888
              ],
              [
                -51.310094096385235,
                -14.980055908966623
              ],
              [
                -51.30140612240776,
                -14.986483872544785
              ],
              [
                -51.30044112699111,
                -14.995613331877058
              ],
              [
                -51.30027261082105,
                -14.997207819378055
              ],
              [
                -51.29757062198398,
                -15.022769185166082
              ],
              [
                -51.29001561262415,
                -15.033890602016742
              ],
              [
                -51.27142875263804,
                -15.037371529611855
              ],
              [
                -51.24212699404315,
                -15.034960011693059
              ],
              [
                -51.19818187026831,
                -15.0044485933159
              ],
              [
                -51.14973579623342,
                -14.982586105979616
              ],
              [
                -51.1454863263588,
                -14.977017642049333
              ],
              [
                -51.14554583531496,
                -14.975892628951435
              ],
              [
                -51.146431352558295,
                -14.95907770517753
              ],
              [
                -51.132786999363525,
                -14.94194078194367
              ],
              [
                -51.1088231672896,
                -14.929434794289346
              ],
              [
                -51.08553137955872,
                -14.91727933329073
              ],
              [
                -51.08253090796167,
                -14.905792374523223
              ],
              [
                -51.087821394835544,
                -14.896881442061714
              ],
              [
                -51.09106839148054,
                -14.891412459761243
              ],
              [
                -51.090624941957,
                -14.87236609215125
              ],
              [
                -51.086401960506734,
                -14.862192627243306
              ],
              [
                -51.089348987024614,
                -14.840725231979661
              ],
              [
                -51.0679002008224,
                -14.809865850830686
              ],
              [
                -51.043666948320904,
                -14.792699893562547
              ],
              [
                -51.04282592347259,
                -14.78842541802915
              ],
              [
                -51.04198595861244,
                -14.784151424498791
              ],
              [
                -51.04541146708284,
                -14.776172018618688
              ],
              [
                -51.05239691466436,
                -14.759902081741908
              ],
              [
                -51.05348544622577,
                -14.746225686768232
              ],
              [
                -51.04882001327938,
                -14.734874716388127
              ],
              [
                -51.026730214406975,
                -14.706359803731123
              ],
              [
                -51.031094741814,
                -14.679641490086329
              ],
              [
                -51.02606929962727,
                -14.66528751778429
              ],
              [
                -51.02163985040083,
                -14.662806037973741
              ],
              [
                -51.01854137782401,
                -14.661070551905127
              ],
              [
                -50.99130364925346,
                -14.613354237777475
              ],
              [
                -50.995042700370455,
                -14.58201092141658
              ],
              [
                -50.99587367533346,
                -14.580513952843912
              ],
              [
                -50.996705174294625,
                -14.579015963277595
              ],
              [
                -50.96240205173111,
                -14.526628653769194
              ],
              [
                -50.9724544998724,
                -14.500171329569756
              ],
              [
                -50.970100026946945,
                -14.491019380836985
              ],
              [
                -50.96774508401019,
                -14.481867901117878
              ],
              [
                -50.97532103742995,
                -14.46658251375807
              ],
              [
                -50.980947541102324,
                -14.461629030575226
              ],
              [
                -50.9865739807764,
                -14.456676086386462
              ],
              [
                -50.986320524887184,
                -14.445771613940371
              ],
              [
                -50.98611854105028,
                -14.437100159924242
              ],
              [
                -50.99235900774462,
                -14.427949226888936
              ],
              [
                -50.99859948443594,
                -14.418797806853638
              ],
              [
                -50.987903086975834,
                -14.395780899717156
              ],
              [
                -50.98937614411925,
                -14.362268583185344
              ],
              [
                -50.98473767585965,
                -14.35802408151133
              ],
              [
                -50.98010022459036,
                -14.353780109844818
              ],
              [
                -50.96881884768542,
                -14.333489184628013
              ],
              [
                -50.974791407616124,
                -14.290421925270692
              ],
              [
                -50.96081905793172,
                -14.248912640805141
              ],
              [
                -50.93657080150173,
                -14.209961784681688
              ],
              [
                -50.92572546663632,
                -14.183300898627664
              ],
              [
                -50.91929902387681,
                -14.176326443217935
              ],
              [
                -50.91287308610884,
                -14.169351988828666
              ],
              [
                -50.90988014651129,
                -14.149090096140505
              ],
              [
                -50.91815362676931,
                -14.125364715145516
              ],
              [
                -50.91701411996639,
                -14.114384286018362
              ],
              [
                -50.91334315841341,
                -14.11115026717323
              ],
              [
                -50.90432023771714,
                -14.112730758762048
              ],
              [
                -50.89657928542012,
                -14.120946219688916
              ],
              [
                -50.890996294777,
                -14.126871642800303
              ],
              [
                -50.8797873770168,
                -14.126035142023163
              ],
              [
                -50.868577498278384,
                -14.125199633245321
              ],
              [
                -50.849488655397174,
                -14.110872696004039
              ],
              [
                -50.84776114770211,
                -14.109576171607355
              ],
              [
                -50.84159770130696,
                -14.104950679469287
              ],
              [
                -50.83317732615573,
                -14.089411761916484
              ],
              [
                -50.83131885040644,
                -14.072950845675454
              ],
              [
                -50.85139927670301,
                -14.02869711414753
              ],
              [
                -50.84876087094903,
                -14.00033428073778
              ],
              [
                -50.86511125685647,
                -13.97978541547073
              ],
              [
                -50.86368581020222,
                -13.968138968660037
              ],
              [
                -50.862260806521974,
                -13.95649304086995
              ],
              [
                -50.84994445385032,
                -13.945455549536575
              ],
              [
                -50.845236462786445,
                -13.941236571184385
              ],
              [
                -50.84302952011513,
                -13.934041136337541
              ],
              [
                -50.840822537437774,
                -13.926844660507417
              ],
              [
                -50.84300303845384,
                -13.915026229457899
              ],
              [
                -50.84520605351493,
                -13.903089802090442
              ],
              [
                -50.84297758734128,
                -13.896210303569108
              ],
              [
                -50.84074861516571,
                -13.889329845062402
              ],
              [
                -50.84095865184427,
                -13.862489996436752
              ],
              [
                -50.856264119061315,
                -13.831114709043364
              ],
              [
                -50.85348063607642,
                -13.813816795134926
              ],
              [
                -50.853043648557524,
                -13.811098297827655
              ],
              [
                -50.854857141632586,
                -13.801578856964873
              ],
              [
                -50.85607215003187,
                -13.795197888256654
              ],
              [
                -50.8533422367903,
                -13.765343030592044
              ],
              [
                -50.87132066447464,
                -13.73266024926613
              ],
              [
                -50.86562920399491,
                -13.72556677570283
              ],
              [
                -50.859937764509155,
                -13.718473321157703
              ],
              [
                -50.83825946189811,
                -13.711783296123839
              ],
              [
                -50.830375011967966,
                -13.712287809952523
              ],
              [
                -50.822491076042965,
                -13.712792258783281
              ],
              [
                -50.80348974865506,
                -13.690535864107988
              ],
              [
                -50.787441419262386,
                -13.647971553937072
              ],
              [
                -50.78087903843197,
                -13.618689230128403
              ],
              [
                -50.77444861780709,
                -13.607651750696853
              ],
              [
                -50.76801815916495,
                -13.596614334300877
              ],
              [
                -50.762984317296436,
                -13.529582168723527
              ],
              [
                -50.74459901506553,
                -13.50715976026091
              ],
              [
                -50.71471074026933,
                -13.49126381303723
              ],
              [
                -50.70560082525073,
                -13.48338482213353
              ],
              [
                -50.696490900228916,
                -13.475506333256558
              ],
              [
                -50.69084897779963,
                -13.46221440589023
              ],
              [
                -50.682973570675756,
                -13.443662015988796
              ],
              [
                -50.67754010176733,
                -13.443115491185907
              ],
              [
                -50.66498919469866,
                -13.441854009473076
              ],
              [
                -50.66327622389394,
                -13.423930105357563
              ],
              [
                -50.66820175444159,
                -13.400994216307247
              ],
              [
                -50.66867575106669,
                -13.398787727099721
              ],
              [
                -50.666929260482284,
                -13.387248794449675
              ],
              [
                -50.665182814877376,
                -13.375709354825503
              ],
              [
                -50.65023095175141,
                -13.36411038897899
              ],
              [
                -50.645892978011474,
                -13.360745403480122
              ],
              [
                -50.64218703182238,
                -13.35296443106027
              ],
              [
                -50.63848057462885,
                -13.34518295265819
              ],
              [
                -50.62376120051327,
                -13.334258507723224
              ],
              [
                -50.61169379588659,
                -13.317368088970062
              ],
              [
                -50.60970382773543,
                -13.31458160756966
              ],
              [
                -50.60673685132566,
                -13.310428106863338
              ],
              [
                -50.60493793068678,
                -13.276857792143256
              ],
              [
                -50.587758087280235,
                -13.248771925550344
              ],
              [
                -50.58422814746603,
                -13.223157545805993
              ],
              [
                -50.58455418507632,
                -13.219339562181087
              ],
              [
                -50.586063694369265,
                -13.212026634687225
              ],
              [
                -50.58845615454714,
                -13.209394153248358
              ],
              [
                -50.6026575834436,
                -13.193765248428804
              ],
              [
                -50.60131812289696,
                -13.180360818127104
              ],
              [
                -50.59952513239886,
                -13.177481327670826
              ],
              [
                -50.594926183851456,
                -13.17009488286014
              ],
              [
                -50.5986496755366,
                -13.145948522108263
              ],
              [
                -50.58983032252819,
                -13.109248690001728
              ],
              [
                -50.610289261367484,
                -13.062646499358637
              ],
              [
                -50.608108782817624,
                -13.059683995749149
              ],
              [
                -50.605927799271335,
                -13.056720996144547
              ],
              [
                -50.60479529304355,
                -13.05648802344425
              ],
              [
                -50.57645853358386,
                -13.050659505955752
              ],
              [
                -50.56870761640874,
                -13.038704558892714
              ],
              [
                -50.57153756346216,
                -13.032110603912075
              ],
              [
                -50.5788120486879,
                -13.028304635394955
              ],
              [
                -50.58707346757441,
                -13.023983145411288
              ],
              [
                -50.59253895520639,
                -13.00243128889389
              ],
              [
                -50.57834158789552,
                -12.997443301581468
              ],
              [
                -50.5715371305468,
                -13.000510779973688
              ],
              [
                -50.564932691893894,
                -13.003486748211362
              ],
              [
                -50.556354729794,
                -13.002035752283213
              ],
              [
                -50.54328837332191,
                -12.984770313296877
              ],
              [
                -50.54177186812193,
                -12.982765811665132
              ],
              [
                -50.53361743439348,
                -12.979380836117967
              ],
              [
                -50.52546302667072,
                -12.975995831581661
              ],
              [
                -50.524687065207004,
                -12.944931987785917
              ],
              [
                -50.524490588791764,
                -12.93705154873243
              ],
              [
                -50.52045364273714,
                -12.92859856321311
              ],
              [
                -50.5164161666767,
                -12.920145136714135
              ],
              [
                -50.51554620455337,
                -12.91318565747312
              ],
              [
                -50.513901241210604,
                -12.900034741954437
              ],
              [
                -50.50949227122183,
                -12.89417524360245
              ],
              [
                -50.5017683465627,
                -12.883909809813119
              ],
              [
                -50.50200233895261,
                -12.872630383297238
              ],
              [
                -50.5106062994593,
                -12.86027795605533
              ],
              [
                -50.49522545776692,
                -12.841854015175793
              ],
              [
                -50.49059951479495,
                -12.828413573330883
              ],
              [
                -50.49277200948215,
                -12.821809638448034
              ],
              [
                -50.49371202361625,
                -12.818951157812588
              ],
              [
                -50.49068252940791,
                -12.813635164947044
              ],
              [
                -50.48916054753972,
                -12.813129164137925
              ],
              [
                -50.48437410169645,
                -12.811536675746698
              ],
              [
                -50.48764606942556,
                -12.802011231869509
              ],
              [
                -50.49340855459856,
                -12.785233348254359
              ],
              [
                -50.48420862763096,
                -12.77554639104157
              ],
              [
                -50.48355814693342,
                -12.775262882498982
              ],
              [
                -50.475295718280975,
                -12.771665877292158
              ],
              [
                -50.477012739458196,
                -12.759857449422162
              ],
              [
                -50.46806881509686,
                -12.754976469179061
              ],
              [
                -50.471460791568454,
                -12.75185100521066
              ],
              [
                -50.47572675386146,
                -12.75197348578328
              ],
              [
                -50.48273870075058,
                -12.752174519589605
              ],
              [
                -50.48554668212735,
                -12.748148554597893
              ],
              [
                -50.48192523824206,
                -12.738852093802238
              ],
              [
                -50.47223128665518,
                -12.73666007345887
              ],
              [
                -50.467842332802284,
                -12.735667568495623
              ],
              [
                -50.46765585820132,
                -12.720882173917234
              ],
              [
                -50.47071534315011,
                -12.719442185065073
              ],
              [
                -50.47832331229127,
                -12.71586320474698
              ],
              [
                -50.47759729178788,
                -12.709527217548569
              ],
              [
                -50.46393141699225,
                -12.70307424209857
              ],
              [
                -50.46131646247937,
                -12.693854780684218
              ],
              [
                -50.44334958180182,
                -12.696892763482296
              ],
              [
                -50.44080260885503,
                -12.697323244791654
              ],
              [
                -50.434908627408085,
                -12.693888769840166
              ],
              [
                -50.43209666774109,
                -12.689574757976253
              ],
              [
                -50.43555214809052,
                -12.679948833126755
              ],
              [
                -50.436378678019814,
                -12.677646349275888
              ],
              [
                -50.43543467428117,
                -12.675767852087487
              ],
              [
                -50.4319367003001,
                -12.668806917748064
              ],
              [
                -50.43508571354055,
                -12.663379919131076
              ],
              [
                -50.43653919918804,
                -12.660875460460076
              ],
              [
                -50.4328412275352,
                -12.656849958533442
              ],
              [
                -50.42838378493493,
                -12.651997480917709
              ],
              [
                -50.429527746752164,
                -12.651845507687671
              ],
              [
                -50.43356573687353,
                -12.65130699547679
              ],
              [
                -50.43774670065807,
                -12.657223476733202
              ],
              [
                -50.44040466068048,
                -12.652848513458364
              ],
              [
                -50.42690577224636,
                -12.642038056645669
              ],
              [
                -50.42460931905429,
                -12.644416040593068
              ],
              [
                -50.41804834364938,
                -12.651208959876389
              ],
              [
                -50.41124092246485,
                -12.645752985669173
              ],
              [
                -50.415777381545546,
                -12.631661088473528
              ],
              [
                -50.41818589279755,
                -12.62418114002332
              ],
              [
                -50.406706984878035,
                -12.614255172823865
              ],
              [
                -50.40067604438247,
                -12.613134660917018
              ],
              [
                -50.39195212165864,
                -12.611514667266055
              ],
              [
                -50.39093963626855,
                -12.606268202705568
              ],
              [
                -50.39407308844284,
                -12.604355689585859
              ],
              [
                -50.3979925606467,
                -12.6019627304524
              ],
              [
                -50.38684717709186,
                -12.589824251201726
              ],
              [
                -50.3817997329349,
                -12.576836353938859
              ],
              [
                -50.37461279688021,
                -12.575778809790108
              ],
              [
                -50.370303327829696,
                -12.5751448111041
              ],
              [
                -50.36604334345728,
                -12.57102632888316
              ],
              [
                -50.36764185434881,
                -12.565425894709122
              ],
              [
                -50.36931534013455,
                -12.559564424114459
              ],
              [
                -50.366122384943765,
                -12.554244958376643
              ],
              [
                -50.359619933272775,
                -12.55351295858895
              ],
              [
                -50.358066939055334,
                -12.553337914969383
              ],
              [
                -50.35891093633689,
                -12.552459445268234
              ],
              [
                -50.36546038071721,
                -12.545645482128137
              ],
              [
                -50.359260939130856,
                -12.541254996220765
              ],
              [
                -50.35152799286248,
                -12.54208246171112
              ],
              [
                -50.345875567605596,
                -12.542686972914304
              ],
              [
                -50.346766049909924,
                -12.53696601725955
              ],
              [
                -50.34714756932662,
                -12.534513542554626
              ],
              [
                -50.34437506673913,
                -12.5328260213315
              ],
              [
                -50.3431665712025,
                -12.533099004796293
              ],
              [
                -50.33169468554734,
                -12.53569351121802
              ],
              [
                -50.33363266737887,
                -12.522856070979415
              ],
              [
                -50.33406716312872,
                -12.51997957473379
              ],
              [
                -50.32978020409922,
                -12.514604125559144
              ],
              [
                -50.32699925572461,
                -12.51572910365949
              ],
              [
                -50.31367985094596,
                -12.52111704645015
              ],
              [
                -50.318353306576135,
                -12.50881962079958
              ],
              [
                -50.32020379956356,
                -12.508428616057845
              ],
              [
                -50.323929259367326,
                -12.507641631642576
              ],
              [
                -50.31172638833598,
                -12.497353687739004
              ],
              [
                -50.29639751412366,
                -12.490733177225296
              ],
              [
                -50.28927256913278,
                -12.487655686578718
              ],
              [
                -50.286146569428105,
                -12.483487202667474
              ],
              [
                -50.28302162071197,
                -12.47931971275844
              ],
              [
                -50.27684265108723,
                -12.478942719617706
              ],
              [
                -50.26416726123462,
                -12.478170219918422
              ],
              [
                -50.25828581600051,
                -12.469880768499944
              ],
              [
                -50.252404871752745,
                -12.461592283099636
              ],
              [
                -50.22973206140396,
                -12.455315793694226
              ],
              [
                -50.22209762042921,
                -12.445689828945271
              ],
              [
                -50.207148750566155,
                -12.448385799152176
              ],
              [
                -50.20771325923376,
                -12.442041818608187
              ],
              [
                -50.21527119907993,
                -12.440964848120581
              ],
              [
                -50.21187272255033,
                -12.436698870396762
              ],
              [
                -50.19821931726482,
                -12.43169486589273
              ],
              [
                -50.184267943633564,
                -12.429954841629668
              ],
              [
                -50.18368992404086,
                -12.429882865079769
              ],
              [
                -50.18375195339206,
                -12.42940786784429
              ],
              [
                -50.1842964524872,
                -12.425230374156829
              ],
              [
                -50.19749033985953,
                -12.418694443537808
              ],
              [
                -50.19612239584623,
                -12.412919486651363
              ],
              [
                -50.18857491418938,
                -12.413264487284689
              ],
              [
                -50.1881814441897,
                -12.420250917471954
              ],
              [
                -50.17740901688812,
                -12.422682390087358
              ],
              [
                -50.17463003341927,
                -12.423309389992003
              ],
              [
                -50.17076654441972,
                -12.419581904526495
              ],
              [
                -50.170795560651015,
                -12.417560923907661
              ],
              [
                -50.17093959048963,
                -12.407641976776437
              ],
              [
                -50.14221580713353,
                -12.395510480613217
              ],
              [
                -50.15776215684792,
                -12.41195190590751
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 10,
        "nome": "Maranhão",
        "sigla": "MA",
        "regiao_id": 4,
        "codigo_ibg": 21
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -47.03102090672557,
                -8.985490274307995
              ],
              [
                -47.02113098514497,
                -8.987369282981064
              ],
              [
                -47.02113400746841,
                -8.980081800020152
              ],
              [
                -47.01494004119835,
                -8.97591583290573
              ],
              [
                -47.01284006162078,
                -8.966155877550193
              ],
              [
                -47.015212057618484,
                -8.959708948862284
              ],
              [
                -47.017851048372755,
                -8.952536507162975
              ],
              [
                -47.012580094904756,
                -8.951907008282909
              ],
              [
                -47.01082412000565,
                -8.94620501013713
              ],
              [
                -47.004291679077205,
                -8.945780010520057
              ],
              [
                -46.99424575162798,
                -8.945126511422284
              ],
              [
                -46.99642174116478,
                -8.933369095862647
              ],
              [
                -46.996631249832696,
                -8.932235106428369
              ],
              [
                -46.995668237352845,
                -8.93218109269395
              ],
              [
                -46.98349335843042,
                -8.931503094380515
              ],
              [
                -46.981958865882795,
                -8.91957068681636
              ],
              [
                -46.9698594618414,
                -8.91409319066164
              ],
              [
                -46.97519144952603,
                -8.90263726876159
              ],
              [
                -46.97977841106053,
                -8.9025762732986
              ],
              [
                -46.98235689812559,
                -8.902541766412748
              ],
              [
                -46.98596835292908,
                -8.89832682172952
              ],
              [
                -46.984430853825614,
                -8.895690337064192
              ],
              [
                -46.988517858100586,
                -8.892219869096419
              ],
              [
                -46.98564035917159,
                -8.887994896211813
              ],
              [
                -46.992123315133675,
                -8.879003457649802
              ],
              [
                -46.97904392234497,
                -8.868465515240459
              ],
              [
                -46.96513153147285,
                -8.867268988453084
              ],
              [
                -46.964211547215626,
                -8.86849600647969
              ],
              [
                -46.95929708889345,
                -8.875049430551282
              ],
              [
                -46.941127767717816,
                -8.859080529718625
              ],
              [
                -46.917131927825146,
                -8.855450532506097
              ],
              [
                -46.9130219927237,
                -8.847427069956746
              ],
              [
                -46.91788546468063,
                -8.843278615270979
              ],
              [
                -46.91777494170833,
                -8.841348108667633
              ],
              [
                -46.91765745592204,
                -8.83929962482223
              ],
              [
                -46.91385347844869,
                -8.834820652644442
              ],
              [
                -46.91273798208158,
                -8.834835145310816
              ],
              [
                -46.90938053806653,
                -8.834878151300884
              ],
              [
                -46.90685553033768,
                -8.828027700939932
              ],
              [
                -46.9101660269267,
                -8.825123709457026
              ],
              [
                -46.91324698807062,
                -8.82242073041411
              ],
              [
                -46.90849003001943,
                -8.818795735658087
              ],
              [
                -46.90237709964106,
                -8.81413775910982
              ],
              [
                -46.904527592498646,
                -8.803837853350398
              ],
              [
                -46.90613157165397,
                -8.796156386994095
              ],
              [
                -46.90515509481013,
                -8.79473891911689
              ],
              [
                -46.90223713217104,
                -8.79050192640169
              ],
              [
                -46.90591259248712,
                -8.773646030931356
              ],
              [
                -46.910852091069486,
                -8.768373603626937
              ],
              [
                -46.90916209889514,
                -8.7641340863022
              ],
              [
                -46.91426056190834,
                -8.762753139892986
              ],
              [
                -46.916884047363,
                -8.762042609400115
              ],
              [
                -46.91752153383899,
                -8.750886692572022
              ],
              [
                -46.92443698009006,
                -8.743825775055727
              ],
              [
                -46.93085343776479,
                -8.726355379621706
              ],
              [
                -46.92816846226367,
                -8.723026389307554
              ],
              [
                -46.92213151733476,
                -8.72727688105578
              ],
              [
                -46.91760004893516,
                -8.725943371747693
              ],
              [
                -46.91682557319086,
                -8.725715371379495
              ],
              [
                -46.91360710220483,
                -8.722046901693853
              ],
              [
                -46.9138445902948,
                -8.712179973103973
              ],
              [
                -46.913941102968245,
                -8.708185488183167
              ],
              [
                -46.9001927351649,
                -8.694053072057692
              ],
              [
                -46.8940392920867,
                -8.692046551385882
              ],
              [
                -46.89032731420267,
                -8.690836582202525
              ],
              [
                -46.886522347942126,
                -8.686091085930393
              ],
              [
                -46.888745357110395,
                -8.669391201067485
              ],
              [
                -46.895804817360116,
                -8.664866738218302
              ],
              [
                -46.895265806614994,
                -8.659343769589501
              ],
              [
                -46.90204175629684,
                -8.66097278136413
              ],
              [
                -46.90346621687851,
                -8.661315281686282
              ],
              [
                -46.90298576312272,
                -8.658115277995144
              ],
              [
                -46.90219026611147,
                -8.652819323609226
              ],
              [
                -46.904493732456295,
                -8.653547834373123
              ],
              [
                -46.90574773030025,
                -8.653944824153523
              ],
              [
                -46.91596414564606,
                -8.648008359919675
              ],
              [
                -46.91126571563428,
                -8.639286933593622
              ],
              [
                -46.91666618521224,
                -8.632515964551766
              ],
              [
                -46.91146419320721,
                -8.626419517607138
              ],
              [
                -46.911466710313704,
                -8.619000574934521
              ],
              [
                -46.906825751934335,
                -8.619316061847107
              ],
              [
                -46.907469744184255,
                -8.61361359369682
              ],
              [
                -46.90548727673478,
                -8.615271072749437
              ],
              [
                -46.90460676475291,
                -8.616007584996494
              ],
              [
                -46.901471295571895,
                -8.612792100550363
              ],
              [
                -46.90493877450626,
                -8.608395133957757
              ],
              [
                -46.89830382562356,
                -8.607760613908678
              ],
              [
                -46.89735835530187,
                -8.604695150864286
              ],
              [
                -46.902118817209974,
                -8.603043643779806
              ],
              [
                -46.89729784122456,
                -8.600618146372641
              ],
              [
                -46.902276797208636,
                -8.596429701058678
              ],
              [
                -46.903535287611334,
                -8.595370712322056
              ],
              [
                -46.90935626542293,
                -8.598190202240946
              ],
              [
                -46.91012975069912,
                -8.595195220596345
              ],
              [
                -46.9159712152311,
                -8.595382234694558
              ],
              [
                -46.91227125019165,
                -8.59145074531589
              ],
              [
                -46.91339024026552,
                -8.586184768847604
              ],
              [
                -46.908524275543776,
                -8.590243737133257
              ],
              [
                -46.899647326131166,
                -8.590433240305474
              ],
              [
                -46.90182632020944,
                -8.5858797683452
              ],
              [
                -46.89713934562089,
                -8.583048265745708
              ],
              [
                -46.89740886583879,
                -8.576340819775037
              ],
              [
                -46.89315938593605,
                -8.578029319179311
              ],
              [
                -46.8913314426061,
                -8.570218865149645
              ],
              [
                -46.88882292370673,
                -8.57604732442825
              ],
              [
                -46.88860893782434,
                -8.576544318955836
              ],
              [
                -46.88553847058138,
                -8.571071831341735
              ],
              [
                -46.88291350901977,
                -8.566391879044838
              ],
              [
                -46.88244199503289,
                -8.566982353684491
              ],
              [
                -46.87886200991173,
                -8.571463848588524
              ],
              [
                -46.87736903543716,
                -8.564151889689045
              ],
              [
                -46.87246859738204,
                -8.56235540413647
              ],
              [
                -46.87416655259852,
                -8.557401935291944
              ],
              [
                -46.87502406466595,
                -8.55490093653764
              ],
              [
                -46.872628095460456,
                -8.555620455264274
              ],
              [
                -46.862413645327116,
                -8.558690404237398
              ],
              [
                -46.85786071482858,
                -8.551139455639067
              ],
              [
                -46.85965820558317,
                -8.544966988874547
              ],
              [
                -46.852390261402846,
                -8.542699498877916
              ],
              [
                -46.8516587576404,
                -8.53663903767716
              ],
              [
                -46.84665330868269,
                -8.532738541788753
              ],
              [
                -46.85040779647553,
                -8.525425090594332
              ],
              [
                -46.84292482872052,
                -8.522456139978578
              ],
              [
                -46.85137177939917,
                -8.515801672507186
              ],
              [
                -46.85173527788918,
                -8.515515670765106
              ],
              [
                -46.85107029973013,
                -8.514882192193069
              ],
              [
                -46.84649833988636,
                -8.510528723753549
              ],
              [
                -46.848453317454606,
                -8.50707221943583
              ],
              [
                -46.850575313044885,
                -8.503320762224398
              ],
              [
                -46.84591336567772,
                -8.501021258274486
              ],
              [
                -46.850437314489675,
                -8.47696543238851
              ],
              [
                -46.84069243120903,
                -8.473103947265892
              ],
              [
                -46.84011191416564,
                -8.46522951116191
              ],
              [
                -46.83470545940815,
                -8.468665986926759
              ],
              [
                -46.83190397254469,
                -8.464558979819245
              ],
              [
                -46.82981501687142,
                -8.46723949405744
              ],
              [
                -46.824692522550485,
                -8.473812403597424
              ],
              [
                -46.820358564544144,
                -8.465080458129263
              ],
              [
                -46.815106123056474,
                -8.4699614495972
              ],
              [
                -46.796133273701585,
                -8.44623408780343
              ],
              [
                -46.80154823849006,
                -8.443607598749178
              ],
              [
                -46.795054821874594,
                -8.429574167807235
              ],
              [
                -46.79643631574445,
                -8.427147219172083
              ],
              [
                -46.79918228458151,
                -8.42232473167769
              ],
              [
                -46.79829481208175,
                -8.412368318990321
              ],
              [
                -46.80640824581273,
                -8.39857590595906
              ],
              [
                -46.79457586035655,
                -8.404463851878091
              ],
              [
                -46.79184737762327,
                -8.402496341898347
              ],
              [
                -46.78658888946501,
                -8.398704405215886
              ],
              [
                -46.785291405488096,
                -8.394985406236652
              ],
              [
                -46.78930238690747,
                -8.388823460873981
              ],
              [
                -46.79129889767229,
                -8.385755476097646
              ],
              [
                -46.78622491420134,
                -8.381841980425962
              ],
              [
                -46.7861039344049,
                -8.374049542418314
              ],
              [
                -46.78200397896205,
                -8.367689579839496
              ],
              [
                -46.760789617658524,
                -8.378045473584399
              ],
              [
                -46.75080819335742,
                -8.374081505114646
              ],
              [
                -46.740346763900526,
                -8.376625481420742
              ],
              [
                -46.738302785247676,
                -8.377122969006534
              ],
              [
                -46.73218932409454,
                -8.385269923086561
              ],
              [
                -46.72639940404207,
                -8.382465940411372
              ],
              [
                -46.72122993036604,
                -8.368145022045667
              ],
              [
                -46.71230551518679,
                -8.362010554991638
              ],
              [
                -46.71045702413547,
                -8.35861658235835
              ],
              [
                -46.70638309968821,
                -8.351136620255195
              ],
              [
                -46.69053169336614,
                -8.345925621929563
              ],
              [
                -46.68499625930706,
                -8.344106142106751
              ],
              [
                -46.681425281258186,
                -8.340125675555493
              ],
              [
                -46.67785380021225,
                -8.336145198009497
              ],
              [
                -46.667065406179375,
                -8.334151706860624
              ],
              [
                -46.65924096253576,
                -8.332706686203029
              ],
              [
                -46.64702506677645,
                -8.325815715822666
              ],
              [
                -46.63480966302149,
                -8.318925771465828
              ],
              [
                -46.61157784594234,
                -8.32637319020622
              ],
              [
                -46.60638389161167,
                -8.328038194824181
              ],
              [
                -46.59017598798841,
                -8.327821155367943
              ],
              [
                -46.58496303299401,
                -8.325511165164466
              ],
              [
                -46.578429102900664,
                -8.322615686450263
              ],
              [
                -46.56841468170564,
                -8.323730157679117
              ],
              [
                -46.55838927160805,
                -8.324845648889115
              ],
              [
                -46.55129931293062,
                -8.32188815501937
              ],
              [
                -46.5442103692496,
                -8.318930701157676
              ],
              [
                -46.53699895957194,
                -8.306378746495167
              ],
              [
                -46.53344048177226,
                -8.300185279684436
              ],
              [
                -46.52698652893171,
                -8.295519809794595
              ],
              [
                -46.52053106810469,
                -8.290853829917573
              ],
              [
                -46.50716369962405,
                -8.27037496637936
              ],
              [
                -46.50868773141684,
                -8.237002681553198
              ],
              [
                -46.50538126910904,
                -8.228388231121551
              ],
              [
                -46.5020748007924,
                -8.219773300709628
              ],
              [
                -46.494190375226054,
                -8.21204832973091
              ],
              [
                -46.489544907134935,
                -8.207496860089698
              ],
              [
                -46.48834042564189,
                -8.20350690626423
              ],
              [
                -46.487135439153256,
                -8.199514412458248
              ],
              [
                -46.49809786269978,
                -8.184973505933005
              ],
              [
                -46.504453295177925,
                -8.183028022308012
              ],
              [
                -46.50970874888451,
                -8.181419554326283
              ],
              [
                -46.511769769577896,
                -8.178746599125246
              ],
              [
                -46.51421472994778,
                -8.166671654556357
              ],
              [
                -46.49154697123812,
                -8.115237467265914
              ],
              [
                -46.48874250734569,
                -8.097330085172034
              ],
              [
                -46.48196408368152,
                -8.087980653340695
              ],
              [
                -46.474963634397675,
                -8.08585814435543
              ],
              [
                -46.4699941576626,
                -8.084351144340001
              ],
              [
                -46.4670832043802,
                -8.078416703789596
              ],
              [
                -46.46618969614166,
                -8.06580228362713
              ],
              [
                -46.47608767326795,
                -8.053688364013269
              ],
              [
                -46.47776762925685,
                -8.051632386352761
              ],
              [
                -46.47855814976696,
                -8.047015931799063
              ],
              [
                -46.47934913427203,
                -8.042398440254432
              ],
              [
                -46.47932463572572,
                -8.04213845295629
              ],
              [
                -46.47653618203618,
                -8.011824139524956
              ],
              [
                -46.485439601319705,
                -8.003222233583047
              ],
              [
                -46.48596811371506,
                -8.002712229499991
              ],
              [
                -46.48585361674468,
                -8.000586733508879
              ],
              [
                -46.48550661094068,
                -7.994127277087103
              ],
              [
                -46.49170659751505,
                -7.981302861504273
              ],
              [
                -46.51830142305532,
                -7.958686055301305
              ],
              [
                -46.54339923207306,
                -7.948299137273299
              ],
              [
                -46.549027187465065,
                -7.949108155491308
              ],
              [
                -46.557304638887764,
                -7.938268714726094
              ],
              [
                -46.55742261575583,
                -7.934477250033688
              ],
              [
                -46.55769561735192,
                -7.92573728537931
              ],
              [
                -46.561961596740076,
                -7.920141330793366
              ],
              [
                -46.57188454161042,
                -7.914055380061984
              ],
              [
                -46.57320251671721,
                -7.914699381087472
              ],
              [
                -46.57560800683582,
                -7.915874879658667
              ],
              [
                -46.57959697581302,
                -7.910851928981931
              ],
              [
                -46.58198944520546,
                -7.907838933369117
              ],
              [
                -46.58632394152685,
                -7.906803932534309
              ],
              [
                -46.59041737766732,
                -7.905826949074954
              ],
              [
                -46.59333437321065,
                -7.902585483495589
              ],
              [
                -46.595788837838604,
                -7.899858496039969
              ],
              [
                -46.60424630613344,
                -7.895786522452112
              ],
              [
                -46.63014008240313,
                -7.898271045551901
              ],
              [
                -46.63731102817191,
                -7.903806013901296
              ],
              [
                -46.64033451035547,
                -7.906139516405434
              ],
              [
                -46.6502119132629,
                -7.908501511513008
              ],
              [
                -46.65407189750508,
                -7.90526252983011
              ],
              [
                -46.66013987144297,
                -7.900171081624045
              ],
              [
                -46.66773980790592,
                -7.903695543764269
              ],
              [
                -46.67050327782021,
                -7.909338007071232
              ],
              [
                -46.67280724723413,
                -7.914043499144753
              ],
              [
                -46.68345115207413,
                -7.912549002613082
              ],
              [
                -46.68502263309268,
                -7.912328488634025
              ],
              [
                -46.69079861321063,
                -7.91437051182408
              ],
              [
                -46.69657555632388,
                -7.916413012015965
              ],
              [
                -46.69834105067777,
                -7.915352489798591
              ],
              [
                -46.70802649430623,
                -7.909535556968055
              ],
              [
                -46.71260544054998,
                -7.913762009498304
              ],
              [
                -46.72166037551874,
                -7.914526036423737
              ],
              [
                -46.723797848798704,
                -7.91866397912885
              ],
              [
                -46.726106803412684,
                -7.9231354527993
              ],
              [
                -46.73097679916359,
                -7.919598001073108
              ],
              [
                -46.73243375591786,
                -7.918539491532756
              ],
              [
                -46.73414926652615,
                -7.920622483439192
              ],
              [
                -46.73703722361251,
                -7.924130961071318
              ],
              [
                -46.73920523856179,
                -7.923363000313108
              ],
              [
                -46.741575183016344,
                -7.922523974226128
              ],
              [
                -46.746279663734605,
                -7.92631099082582
              ],
              [
                -46.75574460366538,
                -7.92197151297268
              ],
              [
                -46.76167155311385,
                -7.935539933050129
              ],
              [
                -46.762910514661804,
                -7.938376911508241
              ],
              [
                -46.76675248795186,
                -7.938293919886284
              ],
              [
                -46.76699398108475,
                -7.938288424163299
              ],
              [
                -46.76784297458169,
                -7.935215942342356
              ],
              [
                -46.772431433829745,
                -7.941371880180011
              ],
              [
                -46.773438937662824,
                -7.942722388249103
              ],
              [
                -46.7813593722943,
                -7.944675396229048
              ],
              [
                -46.78299435929656,
                -7.950970825219267
              ],
              [
                -46.78616783823031,
                -7.951432840332415
              ],
              [
                -46.78874829886801,
                -7.946812370467272
              ],
              [
                -46.78879932056485,
                -7.946720387126572
              ],
              [
                -46.78886182495433,
                -7.946845875359016
              ],
              [
                -46.79191929709569,
                -7.952975332875941
              ],
              [
                -46.79972372953488,
                -7.955032825069041
              ],
              [
                -46.80127722805292,
                -7.950498358601489
              ],
              [
                -46.80159870950397,
                -7.949560350124605
              ],
              [
                -46.80644968525157,
                -7.950214868642059
              ],
              [
                -46.80712264880476,
                -7.950445339790558
              ],
              [
                -46.81057962682637,
                -7.951628374423151
              ],
              [
                -46.81376361714599,
                -7.953625348404259
              ],
              [
                -46.815780603429914,
                -7.954706357274993
              ],
              [
                -46.83076697723648,
                -7.962738801185377
              ],
              [
                -46.835385431864566,
                -7.960350803583577
              ],
              [
                -46.83771641002304,
                -7.959144819883041
              ],
              [
                -46.83833440216752,
                -7.961199309929344
              ],
              [
                -46.839515893060174,
                -7.965126308171928
              ],
              [
                -46.843512363288106,
                -7.964945285369414
              ],
              [
                -46.845898866604855,
                -7.962165824116638
              ],
              [
                -46.85035683130707,
                -7.956975371860398
              ],
              [
                -46.85853777021332,
                -7.959699353057535
              ],
              [
                -46.861344731924945,
                -7.963226824575504
              ],
              [
                -46.86588418702478,
                -7.968932780451966
              ],
              [
                -46.869149672955544,
                -7.96376284785385
              ],
              [
                -46.87069066448356,
                -7.961323840499057
              ],
              [
                -46.86898168705485,
                -7.956021895793219
              ],
              [
                -46.87232867900223,
                -7.955169392771275
              ],
              [
                -46.88224156468745,
                -7.963685839477749
              ],
              [
                -46.88224155291491,
                -7.96940932270422
              ],
              [
                -46.88224157455469,
                -7.972738773732527
              ],
              [
                -46.88888000624313,
                -7.974650258779692
              ],
              [
                -46.895785430811,
                -7.986513209457562
              ],
              [
                -46.90476989228143,
                -7.986517201461676
              ],
              [
                -46.907029347306086,
                -7.983049244601922
              ],
              [
                -46.90896484833267,
                -7.980079752129217
              ],
              [
                -46.9178607612024,
                -7.981371240550216
              ],
              [
                -46.92240774135453,
                -7.985002719178169
              ],
              [
                -46.92132725314291,
                -7.992481191793118
              ],
              [
                -46.92097173440199,
                -7.994940661223766
              ],
              [
                -46.922192234795766,
                -7.993128704395468
              ],
              [
                -46.92324174025496,
                -7.99157069372109
              ],
              [
                -46.940966082185994,
                -7.996485689173169
              ],
              [
                -46.945220037718116,
                -8.004936614786228
              ],
              [
                -46.949066523840344,
                -8.008023621332336
              ],
              [
                -46.95297098944742,
                -8.006252609934565
              ],
              [
                -46.95601995079774,
                -8.01617106169736
              ],
              [
                -46.95546793553349,
                -8.018662028738747
              ],
              [
                -46.95388546050335,
                -8.025805006112028
              ],
              [
                -46.96565835180149,
                -8.034325944936656
              ],
              [
                -46.96238787222588,
                -8.039094427244793
              ],
              [
                -46.97982423623576,
                -8.045170404939798
              ],
              [
                -46.98154471887609,
                -8.05012735408153
              ],
              [
                -46.985540708479476,
                -8.048669865721147
              ],
              [
                -46.988805169466175,
                -8.047478895865073
              ],
              [
                -46.990324644056244,
                -8.051068867799232
              ],
              [
                -46.996997597360725,
                -8.066836781916098
              ],
              [
                -47.00082754268302,
                -8.065940264703514
              ],
              [
                -47.00164107630337,
                -8.057236349741116
              ],
              [
                -47.008109013674684,
                -8.061785297414618
              ],
              [
                -47.00954100071423,
                -8.060707327954782
              ],
              [
                -47.01412197885045,
                -8.057259853267041
              ],
              [
                -47.016233446570645,
                -8.058206346192627
              ],
              [
                -47.016259932353755,
                -8.058218320140817
              ],
              [
                -47.02038741557559,
                -8.060069327172833
              ],
              [
                -47.0241383794079,
                -8.05634987942492
              ],
              [
                -47.04307424039651,
                -8.053195911387665
              ],
              [
                -47.04444073571419,
                -8.04647843490364
              ],
              [
                -47.05310066212749,
                -8.041317499597579
              ],
              [
                -47.05124119387855,
                -8.037013501985802
              ],
              [
                -47.05121268416656,
                -8.036947005393715
              ],
              [
                -47.05055369781093,
                -8.035422025744467
              ],
              [
                -47.05228220621907,
                -8.033705027777886
              ],
              [
                -47.05235719871563,
                -8.033631041340005
              ],
              [
                -47.0585836309589,
                -8.027447565276656
              ],
              [
                -47.06181813790449,
                -8.004950729382257
              ],
              [
                -47.061857628878336,
                -8.00467671722309
              ],
              [
                -47.084331990484124,
                -7.987912865087032
              ],
              [
                -47.09103144701922,
                -7.988681867792404
              ],
              [
                -47.09107293169412,
                -7.988686851801496
              ],
              [
                -47.1060528164036,
                -7.990405863625676
              ],
              [
                -47.09792039230204,
                -7.980383436268276
              ],
              [
                -47.09788487662059,
                -7.9803394395216
              ],
              [
                -47.09736087231766,
                -7.97969343523838
              ],
              [
                -47.098652892935334,
                -7.978132436798129
              ],
              [
                -47.09873986837095,
                -7.978027434575786
              ],
              [
                -47.10626485455279,
                -7.968932999917734
              ],
              [
                -47.10262037474876,
                -7.966870520803307
              ],
              [
                -47.10257785110051,
                -7.96684703491489
              ],
              [
                -47.10127437890033,
                -7.966109548450055
              ],
              [
                -47.10437235741563,
                -7.960767560676969
              ],
              [
                -47.101584864606444,
                -7.954150624369563
              ],
              [
                -47.105333858051964,
                -7.95280763996479
              ],
              [
                -47.10694634012064,
                -7.954287610856717
              ],
              [
                -47.10698435279161,
                -7.95432311766153
              ],
              [
                -47.111855312705,
                -7.958796592155963
              ],
              [
                -47.11549476232235,
                -7.952192124219946
              ],
              [
                -47.11166781877175,
                -7.945323685522598
              ],
              [
                -47.111648319957965,
                -7.945288157736563
              ],
              [
                -47.106802862101475,
                -7.936590724056606
              ],
              [
                -47.11607827064511,
                -7.938509718742318
              ],
              [
                -47.12110376897548,
                -7.921573831126987
              ],
              [
                -47.12510373093583,
                -7.924754329220467
              ],
              [
                -47.13129468939021,
                -7.921601827145954
              ],
              [
                -47.13193919019482,
                -7.921827851305397
              ],
              [
                -47.131977656884715,
                -7.921841341255192
              ],
              [
                -47.14066459486371,
                -7.924886319940417
              ],
              [
                -47.14485656405643,
                -7.912477898698823
              ],
              [
                -47.14365810002837,
                -7.90379196260387
              ],
              [
                -47.143649091163596,
                -7.903726483025397
              ],
              [
                -47.14236811536007,
                -7.894444059782803
              ],
              [
                -47.14995606650826,
                -7.893829534397359
              ],
              [
                -47.15000955710059,
                -7.893825043480251
              ],
              [
                -47.153606028682844,
                -7.89353405698337
              ],
              [
                -47.1630244645229,
                -7.879182672739713
              ],
              [
                -47.17485840588498,
                -7.870689224374369
              ],
              [
                -47.1796073405598,
                -7.861451297842676
              ],
              [
                -47.17920837003024,
                -7.861053310062943
              ],
              [
                -47.179184367239564,
                -7.861028804200171
              ],
              [
                -47.17407389970106,
                -7.855922328701411
              ],
              [
                -47.18360084223147,
                -7.857028824894296
              ],
              [
                -47.183645818880116,
                -7.857033812906209
              ],
              [
                -47.18672082084567,
                -7.857391304612659
              ],
              [
                -47.18674279183816,
                -7.854228851428915
              ],
              [
                -47.18674331788062,
                -7.854182325735369
              ],
              [
                -47.186822825717876,
                -7.842718934201319
              ],
              [
                -47.194063243148484,
                -7.847552401603162
              ],
              [
                -47.19409274890183,
                -7.847571884504315
              ],
              [
                -47.196082258259274,
                -7.848899888748333
              ],
              [
                -47.20054770012367,
                -7.844655435090663
              ],
              [
                -47.20029672975459,
                -7.841951919617828
              ],
              [
                -47.2002927258273,
                -7.841909942889902
              ],
              [
                -47.20010973020355,
                -7.839938467671843
              ],
              [
                -47.20279420274706,
                -7.839730945700221
              ],
              [
                -47.20285020032057,
                -7.839726452785323
              ],
              [
                -47.20702215453085,
                -7.839403946045844
              ],
              [
                -47.2065756797221,
                -7.836642973756544
              ],
              [
                -47.206570194801216,
                -7.836605966994433
              ],
              [
                -47.206188181095406,
                -7.834245993133497
              ],
              [
                -47.20923715447678,
                -7.83439847015461
              ],
              [
                -47.20928517510468,
                -7.834400990185677
              ],
              [
                -47.215016626710245,
                -7.834687010989755
              ],
              [
                -47.223133064609456,
                -7.826306556134061
              ],
              [
                -47.23413748471575,
                -7.823525576320708
              ],
              [
                -47.2299430231817,
                -7.81730314407355
              ],
              [
                -47.22992502434654,
                -7.817276625230072
              ],
              [
                -47.22924102061924,
                -7.816261634226393
              ],
              [
                -47.23032402560636,
                -7.816945617801344
              ],
              [
                -47.23035299736503,
                -7.816964139708251
              ],
              [
                -47.23385245924437,
                -7.81917262265366
              ],
              [
                -47.238772931069455,
                -7.813470653010167
              ],
              [
                -47.243877405366156,
                -7.814917655550337
              ],
              [
                -47.24577141515028,
                -7.808534203386818
              ],
              [
                -47.239147964989435,
                -7.805623726971589
              ],
              [
                -47.23910396634727,
                -7.805604208056454
              ],
              [
                -47.234734999862916,
                -7.803684227362445
              ],
              [
                -47.24015796557549,
                -7.802248219164708
              ],
              [
                -47.24021292416715,
                -7.802233737314208
              ],
              [
                -47.24663491446172,
                -7.800534261834764
              ],
              [
                -47.242276446822615,
                -7.795662288564207
              ],
              [
                -47.24223945614884,
                -7.795620276803954
              ],
              [
                -47.24046947172778,
                -7.793640798073763
              ],
              [
                -47.24442191425982,
                -7.788670843659243
              ],
              [
                -47.24450493572499,
                -7.788566318428593
              ],
              [
                -47.24701992151867,
                -7.785404344704441
              ],
              [
                -47.24610891445133,
                -7.783461366584559
              ],
              [
                -47.246077931753035,
                -7.783397373974917
              ],
              [
                -47.245136920979895,
                -7.781389895250668
              ],
              [
                -47.25460737961828,
                -7.764757503981228
              ],
              [
                -47.245801446887526,
                -7.758128014938779
              ],
              [
                -47.25524286955398,
                -7.758868549346926
              ],
              [
                -47.258528346597515,
                -7.755523544582619
              ],
              [
                -47.25961284752175,
                -7.756270040737423
              ],
              [
                -47.25965085120434,
                -7.756295537607061
              ],
              [
                -47.26632926331634,
                -7.760892542934489
              ],
              [
                -47.26223533244982,
                -7.751116075221045
              ],
              [
                -47.262211813685454,
                -7.751060588562995
              ],
              [
                -47.26088731899018,
                -7.747898106069051
              ],
              [
                -47.26082836547788,
                -7.740767149932716
              ],
              [
                -47.27187323803402,
                -7.744353624173149
              ],
              [
                -47.27046929259947,
                -7.734441191012729
              ],
              [
                -47.27312576192584,
                -7.730639235633352
              ],
              [
                -47.28202217268878,
                -7.734558223569174
              ],
              [
                -47.2929350897627,
                -7.727519772566525
              ],
              [
                -47.29867255685432,
                -7.732417228977993
              ],
              [
                -47.2986905717011,
                -7.732432256896772
              ],
              [
                -47.30107102040971,
                -7.734463721871526
              ],
              [
                -47.31269645906657,
                -7.730442278716152
              ],
              [
                -47.31506293291808,
                -7.717211858035887
              ],
              [
                -47.30649052243897,
                -7.713476359216308
              ],
              [
                -47.306445027808124,
                -7.713456374303271
              ],
              [
                -47.305491510541366,
                -7.713040883103659
              ],
              [
                -47.306822495437764,
                -7.711900885904728
              ],
              [
                -47.30688652899993,
                -7.711845895329089
              ],
              [
                -47.31364647876869,
                -7.706052427049213
              ],
              [
                -47.31560646856929,
                -7.701109481480579
              ],
              [
                -47.311076973924536,
                -7.698495470253936
              ],
              [
                -47.31103850124164,
                -7.698473505360913
              ],
              [
                -47.31007648617598,
                -7.697917989076505
              ],
              [
                -47.30552201091504,
                -7.703261964467528
              ],
              [
                -47.30224705768496,
                -7.701619948072406
              ],
              [
                -47.30210708246817,
                -7.688662553206941
              ],
              [
                -47.3173359654714,
                -7.686647574316979
              ],
              [
                -47.31738995206363,
                -7.686640582415615
              ],
              [
                -47.33108736465737,
                -7.684828082697147
              ],
              [
                -47.33310685215994,
                -7.677673141736747
              ],
              [
                -47.33055384567005,
                -7.673679665525476
              ],
              [
                -47.33728983384391,
                -7.666635694431073
              ],
              [
                -47.33010138741031,
                -7.6631797341817
              ],
              [
                -47.33006239772773,
                -7.66316123726554
              ],
              [
                -47.32913636927292,
                -7.662715738297142
              ],
              [
                -47.31977295251366,
                -7.664411713029912
              ],
              [
                -47.313776994638474,
                -7.660235734692667
              ],
              [
                -47.322968927522375,
                -7.654630793522404
              ],
              [
                -47.33535337797718,
                -7.636637909995789
              ],
              [
                -47.34807825383489,
                -7.630954964738502
              ],
              [
                -47.35448973857022,
                -7.618676036781793
              ],
              [
                -47.35779572611836,
                -7.616702551971864
              ],
              [
                -47.37169560736364,
                -7.620927548607587
              ],
              [
                -47.37244408768473,
                -7.624975513692373
              ],
              [
                -47.36432914812462,
                -7.635064944435189
              ],
              [
                -47.35299321874532,
                -7.641601385426952
              ],
              [
                -47.35280623545468,
                -7.642345897345839
              ],
              [
                -47.35279972247932,
                -7.642371902168376
              ],
              [
                -47.35177673635343,
                -7.64645087433251
              ],
              [
                -47.36466612366314,
                -7.655443800685142
              ],
              [
                -47.37307005304721,
                -7.654557816681604
              ],
              [
                -47.373113565717716,
                -7.654553337753372
              ],
              [
                -47.373120574664924,
                -7.654552335766742
              ],
              [
                -47.38147448443702,
                -7.653671354681166
              ],
              [
                -47.38615698615816,
                -7.632511466396758
              ],
              [
                -47.38180450907595,
                -7.618604076680729
              ],
              [
                -47.38168504062778,
                -7.602502683534315
              ],
              [
                -47.38168452068318,
                -7.602449673882751
              ],
              [
                -47.3816635226271,
                -7.599580180751056
              ],
              [
                -47.38756700667224,
                -7.596152223013302
              ],
              [
                -47.38767698489079,
                -7.596088240540623
              ],
              [
                -47.40374388672511,
                -7.586758790443098
              ],
              [
                -47.40154041195436,
                -7.581247328596208
              ],
              [
                -47.4015254091061,
                -7.581209324831939
              ],
              [
                -47.39780195665976,
                -7.571896900554051
              ],
              [
                -47.40683987959561,
                -7.563376448344366
              ],
              [
                -47.41551682760764,
                -7.562830479278897
              ],
              [
                -47.41556930420878,
                -7.562826957352542
              ],
              [
                -47.42054577336721,
                -7.562513961196511
              ],
              [
                -47.438188655291384,
                -7.556165533935284
              ],
              [
                -47.43150220526166,
                -7.548144096311159
              ],
              [
                -47.428198220838745,
                -7.544762103405724
              ],
              [
                -47.42816823109746,
                -7.544732082574619
              ],
              [
                -47.424916744219225,
                -7.541404618364949
              ],
              [
                -47.423910290211474,
                -7.530719686763358
              ],
              [
                -47.427257267714154,
                -7.525359216270806
              ],
              [
                -47.4385416653766,
                -7.521063282356477
              ],
              [
                -47.461039988122415,
                -7.518893783158103
              ],
              [
                -47.46668843672403,
                -7.528404761965312
              ],
              [
                -47.46821841053012,
                -7.530980221482985
              ],
              [
                -47.47478090235687,
                -7.53297670463549
              ],
              [
                -47.4796848479671,
                -7.525536277287179
              ],
              [
                -47.47977735477529,
                -7.506255412257618
              ],
              [
                -47.47984787614022,
                -7.491743006857213
              ],
              [
                -47.488685805677875,
                -7.485271036888534
              ],
              [
                -47.497524239219054,
                -7.478798096918764
              ],
              [
                -47.49856424271553,
                -7.465754690783301
              ],
              [
                -47.50010323933071,
                -7.446451820365986
              ],
              [
                -47.50427722909346,
                -7.436249391524862
              ],
              [
                -47.51985361524513,
                -7.431702940216126
              ],
              [
                -47.52879204110777,
                -7.43251093635608
              ],
              [
                -47.54994988295796,
                -7.43919291241136
              ],
              [
                -47.55639082475434,
                -7.4444033892313
              ],
              [
                -47.56283275954279,
                -7.449614843058348
              ],
              [
                -47.59043655354637,
                -7.439154454056822
              ],
              [
                -47.577053698122334,
                -7.41567907481593
              ],
              [
                -47.5702632613991,
                -7.41105912080487
              ],
              [
                -47.56347332767356,
                -7.406439637802589
              ],
              [
                -47.51225222337168,
                -7.38631573311658
              ],
              [
                -47.48544395095918,
                -7.366715844212716
              ],
              [
                -47.477399022353175,
                -7.336010041370571
              ],
              [
                -47.479971498289224,
                -7.328935580487755
              ],
              [
                -47.48254352022362,
                -7.321861141606056
              ],
              [
                -47.49935489709107,
                -7.293471835758201
              ],
              [
                -47.541471591185555,
                -7.265348531557618
              ],
              [
                -47.58866723009238,
                -7.263708597088495
              ],
              [
                -47.5978016695988,
                -7.267723067068583
              ],
              [
                -47.60693657610803,
                -7.27173857105501
              ],
              [
                -47.6118150342219,
                -7.278867997571167
              ],
              [
                -47.626576411402134,
                -7.300438865127178
              ],
              [
                -47.637402324928274,
                -7.304546369157352
              ],
              [
                -47.648083733887,
                -7.302969393502513
              ],
              [
                -47.65133821794949,
                -7.299871891935477
              ],
              [
                -47.655672217135034,
                -7.292108453101961
              ],
              [
                -47.673934591313326,
                -7.259393217564606
              ],
              [
                -47.69056998039904,
                -7.23792135442764
              ],
              [
                -47.736374167276544,
                -7.216373035675407
              ],
              [
                -47.74578508995131,
                -7.200837141663771
              ],
              [
                -47.74931858307772,
                -7.191042699425008
              ],
              [
                -47.748979102914994,
                -7.172310829515752
              ],
              [
                -47.74683560092438,
                -7.16721537012635
              ],
              [
                -47.74469311692328,
                -7.16211938174744
              ],
              [
                -47.715905877242704,
                -7.141368004254844
              ],
              [
                -47.70762092322197,
                -7.137161532447575
              ],
              [
                -47.693627019407415,
                -7.136579023527868
              ],
              [
                -47.67563167422217,
                -7.146826457464814
              ],
              [
                -47.67475068048061,
                -7.147327947349878
              ],
              [
                -47.66734572927335,
                -7.1491554245239
              ],
              [
                -47.65994079507253,
                -7.150983400692471
              ],
              [
                -47.644021406597126,
                -7.143227949384836
              ],
              [
                -47.63552048451329,
                -7.132212995416201
              ],
              [
                -47.62702005041596,
                -7.121198058487788
              ],
              [
                -47.617990142976005,
                -7.090773772502163
              ],
              [
                -47.594256361573414,
                -7.061539950573074
              ],
              [
                -47.584302933786866,
                -7.055747992973381
              ],
              [
                -47.57435002200232,
                -7.049956518391332
              ],
              [
                -47.545931772961914,
                -7.014430735458548
              ],
              [
                -47.53507436434002,
                -6.987096878175331
              ],
              [
                -47.529477427861984,
                -6.976145958910054
              ],
              [
                -47.52913796256706,
                -6.945629168329509
              ],
              [
                -47.50719063958083,
                -6.906806390079782
              ],
              [
                -47.50632816641567,
                -6.901987921377163
              ],
              [
                -47.50546617224414,
                -6.897168957682179
              ],
              [
                -47.506605682961656,
                -6.871628148662068
              ],
              [
                -47.52005910174434,
                -6.840090357385747
              ],
              [
                -47.52610955774294,
                -6.837545886600942
              ],
              [
                -47.528236053064504,
                -6.830014935599747
              ],
              [
                -47.51627019133124,
                -6.789879693610913
              ],
              [
                -47.49368738346367,
                -6.754451430405847
              ],
              [
                -47.49622088241123,
                -6.707465235232466
              ],
              [
                -47.495675422411736,
                -6.702979757722803
              ],
              [
                -47.49471591547248,
                -6.69509679455715
              ],
              [
                -47.49285745155882,
                -6.689366863251003
              ],
              [
                -47.495334437930524,
                -6.680800422594064
              ],
              [
                -47.49821191541097,
                -6.670849967529561
              ],
              [
                -47.483313058041,
                -6.643975649551431
              ],
              [
                -47.46541519307057,
                -6.611691346451637
              ],
              [
                -47.46293275155528,
                -6.603952389171187
              ],
              [
                -47.46389822797386,
                -6.585136043978629
              ],
              [
                -47.45766079806279,
                -6.5475507555831
              ],
              [
                -47.45685832122401,
                -6.542715796323018
              ],
              [
                -47.45605583338215,
                -6.537880862066625
              ],
              [
                -47.42881606617025,
                -6.499841057640778
              ],
              [
                -47.42489910383917,
                -6.496551590483107
              ],
              [
                -47.41827915108497,
                -6.487317146015499
              ],
              [
                -47.41636668291025,
                -6.484649672350326
              ],
              [
                -47.43239159611275,
                -6.435069008579481
              ],
              [
                -47.43096012630767,
                -6.424977574224985
              ],
              [
                -47.41950720222032,
                -6.401244224441794
              ],
              [
                -47.41558774638974,
                -6.388695333660631
              ],
              [
                -47.4116682945397,
                -6.376145895914994
              ],
              [
                -47.411440822570555,
                -6.34981808794175
              ],
              [
                -47.41139233945075,
                -6.344227602542817
              ],
              [
                -47.41017082887773,
                -6.337914633067169
              ],
              [
                -47.40894985929577,
                -6.331601208601882
              ],
              [
                -47.37838861093872,
                -6.27047858712515
              ],
              [
                -47.38052663806685,
                -6.249744722728815
              ],
              [
                -47.38273010102985,
                -6.239888279000436
              ],
              [
                -47.38493358598132,
                -6.230031883278069
              ],
              [
                -47.401045487496404,
                -6.208870019879932
              ],
              [
                -47.41616741762118,
                -6.177447726955384
              ],
              [
                -47.43100583420285,
                -6.119875160493848
              ],
              [
                -47.43404483403645,
                -6.092738838282342
              ],
              [
                -47.43089039154748,
                -6.066307001592207
              ],
              [
                -47.430054885614496,
                -6.057626068674819
              ],
              [
                -47.42812593374941,
                -6.037578194854039
              ],
              [
                -47.4383183551454,
                -6.012420394968831
              ],
              [
                -47.447532793930876,
                -5.992542041600577
              ],
              [
                -47.43009447863421,
                -5.935073885759962
              ],
              [
                -47.43063352314821,
                -5.86883684954657
              ],
              [
                -47.43742395073503,
                -5.857497426594257
              ],
              [
                -47.438277943097994,
                -5.852272486703891
              ],
              [
                -47.44185594601038,
                -5.830385630988199
              ],
              [
                -47.46298980825463,
                -5.786232433550531
              ],
              [
                -47.470029787326894,
                -5.771524049671973
              ],
              [
                -47.47665173747501,
                -5.763779110166003
              ],
              [
                -47.48327467961588,
                -5.75603267066581
              ],
              [
                -47.49294260374786,
                -5.730158339590541
              ],
              [
                -47.492804621252624,
                -5.719911415146901
              ],
              [
                -47.492667137739225,
                -5.709665490709826
              ],
              [
                -47.47327233737606,
                -5.661608308632073
              ],
              [
                -47.47721630943251,
                -5.634916475223705
              ],
              [
                -47.47730880717481,
                -5.63429050055344
              ],
              [
                -47.479225786826575,
                -5.621312085329298
              ],
              [
                -47.48397126920121,
                -5.595236244383866
              ],
              [
                -47.48282780067624,
                -5.564527450897444
              ],
              [
                -47.499690202766814,
                -5.525234729779613
              ],
              [
                -47.505261175533,
                -5.519196795886339
              ],
              [
                -47.51083412628431,
                -5.513158817990932
              ],
              [
                -47.55493832059492,
                -5.465365691873791
              ],
              [
                -47.56371923753229,
                -5.462767218720476
              ],
              [
                -47.57244718853408,
                -5.466429706831642
              ],
              [
                -47.58009514107949,
                -5.4696392012875
              ],
              [
                -47.6181873313454,
                -5.459356273956127
              ],
              [
                -47.66633799046876,
                -5.421419048905602
              ],
              [
                -47.68533336136699,
                -5.412351141831957
              ],
              [
                -47.74179393318758,
                -5.38092488081498
              ],
              [
                -47.75484935515922,
                -5.379378923333157
              ],
              [
                -47.76790425815618,
                -5.377833931841614
              ],
              [
                -47.79255604768535,
                -5.384341894568954
              ],
              [
                -47.827104280448445,
                -5.386219409658155
              ],
              [
                -47.84236770148132,
                -5.376452985561526
              ],
              [
                -47.84332516064685,
                -5.37584000738712
              ],
              [
                -47.867185016742624,
                -5.342453235676296
              ],
              [
                -47.86687953743165,
                -5.321349879913353
              ],
              [
                -47.86663603467065,
                -5.304561493901895
              ],
              [
                -47.873487514249476,
                -5.272789192670846
              ],
              [
                -47.8793114723428,
                -5.266561253982665
              ],
              [
                -47.88513593443315,
                -5.260332318297274
              ],
              [
                -47.93355307828666,
                -5.240579463301339
              ],
              [
                -47.93750355653293,
                -5.2403879542639
              ],
              [
                -47.937791553363894,
                -5.240373977552903
              ],
              [
                -47.946128993577865,
                -5.239966960935136
              ],
              [
                -47.95833137170284,
                -5.239370997204902
              ],
              [
                -47.98093921475203,
                -5.236448509291242
              ],
              [
                -47.99022614936923,
                -5.235247522704523
              ],
              [
                -48.00581254175082,
                -5.235018555757261
              ],
              [
                -48.01399945498565,
                -5.237877024863608
              ],
              [
                -48.04033827190062,
                -5.258941424697994
              ],
              [
                -48.04882919921171,
                -5.265731858392522
              ],
              [
                -48.05360666218473,
                -5.267152856995907
              ],
              [
                -48.06687406136959,
                -5.271100834231977
              ],
              [
                -48.066969025633945,
                -5.271128838106621
              ],
              [
                -48.078838465669115,
                -5.274660341232674
              ],
              [
                -48.10789023308542,
                -5.266391397992023
              ],
              [
                -48.10812372036964,
                -5.266324381604518
              ],
              [
                -48.17810520586526,
                -5.260407484171925
              ],
              [
                -48.18985662833171,
                -5.25302852706877
              ],
              [
                -48.206061512277856,
                -5.248615565906245
              ],
              [
                -48.207342499261394,
                -5.247683581078042
              ],
              [
                -48.20876047428075,
                -5.24665209001548
              ],
              [
                -48.2245298852936,
                -5.235184166250618
              ],
              [
                -48.24226176541246,
                -5.225422758243035
              ],
              [
                -48.27258000394011,
                -5.215598858114433
              ],
              [
                -48.28316494536614,
                -5.209601386784014
              ],
              [
                -48.293749869803555,
                -5.203603952442594
              ],
              [
                -48.32046468474872,
                -5.20102098687263
              ],
              [
                -48.352715457853726,
                -5.173524693242264
              ],
              [
                -48.35809239731355,
                -5.170768193459408
              ],
              [
                -48.363470356768424,
                -5.168012222671055
              ],
              [
                -48.46571508937456,
                -5.185075679171054
              ],
              [
                -48.485882937627096,
                -5.193453125319802
              ],
              [
                -48.519020659541155,
                -5.191767672003484
              ],
              [
                -48.52879958603066,
                -5.198157124605298
              ],
              [
                -48.53857851752572,
                -5.204546566229315
              ],
              [
                -48.560632821395785,
                -5.229843428103909
              ],
              [
                -48.5612248295079,
                -5.236430889240354
              ],
              [
                -48.561816302620365,
                -5.243015347402724
              ],
              [
                -48.56344230505212,
                -5.248028322833625
              ],
              [
                -48.56811025733785,
                -5.262424224331424
              ],
              [
                -48.56812026540162,
                -5.272495647711342
              ],
              [
                -48.56813124144474,
                -5.282567093103836
              ],
              [
                -48.5836930975768,
                -5.310925890522975
              ],
              [
                -48.6056909436664,
                -5.33613172575313
              ],
              [
                -48.611907407845294,
                -5.338059725147887
              ],
              [
                -48.62966076288549,
                -5.332590291112473
              ],
              [
                -48.637373693284275,
                -5.320916350663542
              ],
              [
                -48.638215717868825,
                -5.319641855784732
              ],
              [
                -48.644617671972746,
                -5.315356419923623
              ],
              [
                -48.65101962708026,
                -5.311070934058379
              ],
              [
                -48.67899840692163,
                -5.305454505006624
              ],
              [
                -48.68640883625609,
                -5.308829458531912
              ],
              [
                -48.69182081014523,
                -5.311293950777245
              ],
              [
                -48.69876724744417,
                -5.319758406937863
              ],
              [
                -48.704026200171675,
                -5.344358741020492
              ],
              [
                -48.71313211071744,
                -5.3522507025923
              ],
              [
                -48.719013087938215,
                -5.350637228479556
              ],
              [
                -48.7299934770757,
                -5.347626231260838
              ],
              [
                -48.740479925476855,
                -5.352449695336596
              ],
              [
                -48.75471282282593,
                -5.348801238641357
              ],
              [
                -48.64383069959006,
                -5.261723257850509
              ],
              [
                -48.452136238246396,
                -5.111183170366916
              ],
              [
                -48.43371736141686,
                -5.095868773555915
              ],
              [
                -48.43644384756912,
                -5.085823837188975
              ],
              [
                -48.437154856957314,
                -5.083202332362172
              ],
              [
                -48.43418539573788,
                -5.076308871966216
              ],
              [
                -48.42685993656973,
                -5.074929898460959
              ],
              [
                -48.42262946179916,
                -5.080268841606801
              ],
              [
                -48.41860601364648,
                -5.085346306653356
              ],
              [
                -48.39583418450706,
                -5.070008893320272
              ],
              [
                -48.21190415998666,
                -4.924645802120274
              ],
              [
                -48.203803231995025,
                -4.918243852519886
              ],
              [
                -48.194194319107716,
                -4.910649360097711
              ],
              [
                -48.05787539780959,
                -4.803840515198137
              ],
              [
                -48.02568163299203,
                -4.778939668635168
              ],
              [
                -47.81571782697564,
                -4.614364711870727
              ],
              [
                -47.804692939651716,
                -4.597007307965424
              ],
              [
                -47.79709047770372,
                -4.59107687236755
              ],
              [
                -47.78948854375306,
                -4.585147382779768
              ],
              [
                -47.77043767053664,
                -4.592951843747576
              ],
              [
                -47.753607826548354,
                -4.590458315046434
              ],
              [
                -47.751660321922415,
                -4.596255293902935
              ],
              [
                -47.75035882652225,
                -4.6001282664153
              ],
              [
                -47.741934408468914,
                -4.598283290181479
              ],
              [
                -47.74182290532832,
                -4.598259278281509
              ],
              [
                -47.741623401788885,
                -4.598348265549951
              ],
              [
                -47.724380521028,
                -4.606054202206132
              ],
              [
                -47.717079081920744,
                -4.603467217757245
              ],
              [
                -47.71485460125481,
                -4.60267922688578
              ],
              [
                -47.71132863786588,
                -4.60290974621645
              ],
              [
                -47.711441130814144,
                -4.604956699151216
              ],
              [
                -47.7114936058606,
                -4.605905206634079
              ],
              [
                -47.709909650009344,
                -4.605677728270474
              ],
              [
                -47.701134199321686,
                -4.604417207800128
              ],
              [
                -47.67950986617621,
                -4.608241157643254
              ],
              [
                -47.65216208801583,
                -4.589019317380545
              ],
              [
                -47.63724473475739,
                -4.571036394996186
              ],
              [
                -47.627670779585415,
                -4.567398938566742
              ],
              [
                -47.62269183250342,
                -4.565507429747844
              ],
              [
                -47.61068142226899,
                -4.555974012726295
              ],
              [
                -47.60753896854184,
                -4.548365071573853
              ],
              [
                -47.58104417546157,
                -4.519572250670931
              ],
              [
                -47.5629368414963,
                -4.47507602881026
              ],
              [
                -47.530739602191666,
                -4.443527252485062
              ],
              [
                -47.52377816193729,
                -4.435015801666983
              ],
              [
                -47.51823518383208,
                -4.426600860003498
              ],
              [
                -47.50207532774143,
                -4.403452514820286
              ],
              [
                -47.502118830184685,
                -4.398474057466479
              ],
              [
                -47.502162821620516,
                -4.393496590108965
              ],
              [
                -47.49532740511225,
                -4.38545264323641
              ],
              [
                -47.488292446249915,
                -4.377173164913556
              ],
              [
                -47.488119948630015,
                -4.371634202364166
              ],
              [
                -47.487947939001906,
                -4.366096262811764
              ],
              [
                -47.485809966381595,
                -4.36409479455283
              ],
              [
                -47.475716554430726,
                -4.354646830718252
              ],
              [
                -47.48138351004335,
                -4.349647362674578
              ],
              [
                -47.48138002007406,
                -4.349639868724835
              ],
              [
                -47.47600756191205,
                -4.340657434300733
              ],
              [
                -47.47734906698911,
                -4.336486962089774
              ],
              [
                -47.47790353571377,
                -4.334763480400521
              ],
              [
                -47.47943954989983,
                -4.327686522543459
              ],
              [
                -47.471442110803636,
                -4.320338587325657
              ],
              [
                -47.473016581241126,
                -4.314070094870933
              ],
              [
                -47.44876076920836,
                -4.298888211383887
              ],
              [
                -47.441221351399065,
                -4.286421780204585
              ],
              [
                -47.43260441544472,
                -4.283824310620426
              ],
              [
                -47.431036418902515,
                -4.284723807488811
              ],
              [
                -47.429083449173625,
                -4.285844295604851
              ],
              [
                -47.42910092830312,
                -4.283513313878321
              ],
              [
                -47.42916145720554,
                -4.275447855191199
              ],
              [
                -47.41967952127239,
                -4.277620846860729
              ],
              [
                -47.41805154547489,
                -4.277994340366924
              ],
              [
                -47.41552104636435,
                -4.275020376746274
              ],
              [
                -47.41094160216679,
                -4.269638410824659
              ],
              [
                -47.40713260052925,
                -4.269017415089277
              ],
              [
                -47.403055141963506,
                -4.26835192251944
              ],
              [
                -47.3972136856097,
                -4.260698936790887
              ],
              [
                -47.388774270884355,
                -4.249641536458571
              ],
              [
                -47.379400846055084,
                -4.244588574698142
              ],
              [
                -47.37840835390865,
                -4.244054061897348
              ],
              [
                -47.372054389142974,
                -4.244452059683482
              ],
              [
                -47.37611286256632,
                -4.23068216412123
              ],
              [
                -47.37924234737157,
                -4.220065219024999
              ],
              [
                -47.37346941368705,
                -4.21950173586411
              ],
              [
                -47.37119390815584,
                -4.219279719194558
              ],
              [
                -47.37269540133224,
                -4.216228264337551
              ],
              [
                -47.373408917663646,
                -4.214778287859357
              ],
              [
                -47.371375440367146,
                -4.210645272671072
              ],
              [
                -47.370704409209054,
                -4.209280802853857
              ],
              [
                -47.36936192089562,
                -4.206551834220787
              ],
              [
                -47.368946936121795,
                -4.20643533181345
              ],
              [
                -47.36585746014047,
                -4.205568830220481
              ],
              [
                -47.36659996103505,
                -4.202636323129783
              ],
              [
                -47.36855144524002,
                -4.194929413083767
              ],
              [
                -47.36043502750649,
                -4.188554439356357
              ],
              [
                -47.364995979597914,
                -4.179330019332829
              ],
              [
                -47.35370855584528,
                -4.177116017827033
              ],
              [
                -47.351762595911744,
                -4.178586487500129
              ],
              [
                -47.347610604925066,
                -4.181724464327401
              ],
              [
                -47.341798166743025,
                -4.177021522159628
              ],
              [
                -47.342066659439695,
                -4.168010088395864
              ],
              [
                -47.347019645509604,
                -4.156871658013839
              ],
              [
                -47.343270657848485,
                -4.147820726421566
              ],
              [
                -47.34692015988748,
                -4.14423473646346
              ],
              [
                -47.34983512115125,
                -4.141371768052527
              ],
              [
                -47.347348634315026,
                -4.135246303650448
              ],
              [
                -47.344209170293816,
                -4.127510857193869
              ],
              [
                -47.34805413463869,
                -4.120455416646212
              ],
              [
                -47.34931464822715,
                -4.118142451513529
              ],
              [
                -47.3503316274404,
                -4.116276460121213
              ],
              [
                -47.34755416404211,
                -4.107761510338626
              ],
              [
                -47.3451351963294,
                -4.100351047015569
              ],
              [
                -47.33794874628993,
                -4.094267593917391
              ],
              [
                -47.33038830226796,
                -4.087867629860375
              ],
              [
                -47.33271726749738,
                -4.082052177854687
              ],
              [
                -47.33366627347765,
                -4.079682690965333
              ],
              [
                -47.331963809360985,
                -4.070157751892018
              ],
              [
                -47.32524533069088,
                -4.068064251101841
              ],
              [
                -47.324878849233365,
                -4.056848864605954
              ],
              [
                -47.31846940239101,
                -4.046563913486015
              ],
              [
                -47.30944046248798,
                -4.052047880342892
              ],
              [
                -47.30891099136427,
                -4.052369388813197
              ],
              [
                -47.31085444417039,
                -4.055035870097361
              ],
              [
                -47.30715847802302,
                -4.057680827625673
              ],
              [
                -47.30293251812577,
                -4.052104389593865
              ],
              [
                -47.29394510553685,
                -4.052470376395101
              ],
              [
                -47.29268008670387,
                -4.043629918824332
              ],
              [
                -47.292594096666036,
                -4.043029930993813
              ],
              [
                -47.291858093188644,
                -4.043217448298698
              ],
              [
                -47.287105137854304,
                -4.044428407351232
              ],
              [
                -47.28561114447486,
                -4.040079955126644
              ],
              [
                -47.28419018542707,
                -4.035944958443816
              ],
              [
                -47.27582623009196,
                -4.030420997967259
              ],
              [
                -47.27220675417205,
                -4.031516996412325
              ],
              [
                -47.26697579631229,
                -4.033099986608843
              ],
              [
                -47.26700979172524,
                -4.027836527616094
              ],
              [
                -47.267026306893385,
                -4.025285031556764
              ],
              [
                -47.24460598087444,
                -4.011969148730434
              ],
              [
                -47.235885081579866,
                -3.993951247040799
              ],
              [
                -47.22687913468967,
                -3.997333706672648
              ],
              [
                -47.22590964791436,
                -3.997698219615778
              ],
              [
                -47.21941617459783,
                -3.995587233177133
              ],
              [
                -47.21527474403376,
                -3.988052303113396
              ],
              [
                -47.21365924285503,
                -3.985113308984971
              ],
              [
                -47.211882747259125,
                -3.985459309650686
              ],
              [
                -47.205965277909804,
                -3.986613297533129
              ],
              [
                -47.202188323072654,
                -3.978047835936336
              ],
              [
                -47.19893837460176,
                -3.970677398204885
              ],
              [
                -47.19647288495,
                -3.973678401825486
              ],
              [
                -47.19321538687054,
                -3.977643869252825
              ],
              [
                -47.19037042599753,
                -3.968888396491324
              ],
              [
                -47.18951942381055,
                -3.966270422506115
              ],
              [
                -47.188925931365176,
                -3.966236420447044
              ],
              [
                -47.18477595121436,
                -3.965998940032314
              ],
              [
                -47.18094248792048,
                -3.959189490086726
              ],
              [
                -47.18125251205306,
                -3.952147507869258
              ],
              [
                -47.18166100253672,
                -3.942867089484039
              ],
              [
                -47.176497035752654,
                -3.943415075038693
              ],
              [
                -47.17396355999345,
                -3.94368408387524
              ],
              [
                -47.17152156313923,
                -3.940717585567994
              ],
              [
                -47.1707925795631,
                -3.93294213502688
              ],
              [
                -47.169715096477766,
                -3.921450221531224
              ],
              [
                -47.155801703542245,
                -3.914046268847511
              ],
              [
                -47.146302274823206,
                -3.896502885948103
              ],
              [
                -47.14177133888195,
                -3.899782383560355
              ],
              [
                -47.13858584812506,
                -3.902087855711331
              ],
              [
                -47.13477240465673,
                -3.899328866302753
              ],
              [
                -47.134761900300305,
                -3.892048926695191
              ],
              [
                -47.13474638060381,
                -3.881431505659399
              ],
              [
                -47.12710694786959,
                -3.877724521084302
              ],
              [
                -47.1217800087983,
                -3.881497987812395
              ],
              [
                -47.12144449225058,
                -3.881734980973355
              ],
              [
                -47.116525533908074,
                -3.877612540676557
              ],
              [
                -47.106859620614024,
                -3.876141036326074
              ],
              [
                -47.10356365287349,
                -3.867857578246039
              ],
              [
                -47.101261651304824,
                -3.862071617013431
              ],
              [
                -47.09469670920345,
                -3.858696644661297
              ],
              [
                -47.08768976360045,
                -3.855094159709339
              ],
              [
                -47.08291330632195,
                -3.838224783685564
              ],
              [
                -47.085354786759474,
                -3.831713837927238
              ],
              [
                -47.08779577419595,
                -3.825203876162743
              ],
              [
                -47.07884833673465,
                -3.818905403396118
              ],
              [
                -47.07504689012983,
                -3.816229952491927
              ],
              [
                -47.07633535134939,
                -3.813958453189762
              ],
              [
                -47.07782634618841,
                -3.811329488515001
              ],
              [
                -47.073193885797906,
                -3.800585058333394
              ],
              [
                -47.06957394735553,
                -3.798839545949339
              ],
              [
                -47.06402396914076,
                -3.796164597220808
              ],
              [
                -47.06189448679304,
                -3.787039650816138
              ],
              [
                -47.059743504235406,
                -3.772348729843579
              ],
              [
                -47.06205050885337,
                -3.767506264249153
              ],
              [
                -47.06435797846617,
                -3.762662793662081
              ],
              [
                -47.058972526665144,
                -3.754291388415014
              ],
              [
                -47.05437906921246,
                -3.747150940838557
              ],
              [
                -47.05521705900946,
                -3.738167990907633
              ],
              [
                -47.05541355249131,
                -3.736061999929329
              ],
              [
                -47.06113253504074,
                -3.728889544475398
              ],
              [
                -47.05215208166374,
                -3.720690615372121
              ],
              [
                -47.04971562336695,
                -3.718466139999701
              ],
              [
                -47.04513265752096,
                -3.707636194657819
              ],
              [
                -47.051740124242,
                -3.680994881754618
              ],
              [
                -47.036081738780936,
                -3.671073435886392
              ],
              [
                -47.04271317720026,
                -3.661872525279155
              ],
              [
                -47.044662660219096,
                -3.664148995022571
              ],
              [
                -47.0481601355474,
                -3.668232993656544
              ],
              [
                -47.05149463598748,
                -3.661738546299163
              ],
              [
                -47.04774764330762,
                -3.656036581039751
              ],
              [
                -47.055826599914084,
                -3.646221639450341
              ],
              [
                -47.04693867028009,
                -3.645604631730196
              ],
              [
                -47.03965820509414,
                -3.641146190033809
              ],
              [
                -47.04563916767033,
                -3.63808170155105
              ],
              [
                -47.04434317090182,
                -3.633029242836431
              ],
              [
                -47.05071114345789,
                -3.632285219047581
              ],
              [
                -47.04484370465513,
                -3.625143289077165
              ],
              [
                -47.048196650635106,
                -3.619272841312775
              ],
              [
                -47.04055473644369,
                -3.614278380296526
              ],
              [
                -47.03660926429944,
                -3.604050426171525
              ],
              [
                -47.03438579139921,
                -3.603909420159776
              ],
              [
                -47.032111789888106,
                -3.603765420146449
              ],
              [
                -47.03326880054736,
                -3.600388468674569
              ],
              [
                -47.029662307303376,
                -3.600118940947652
              ],
              [
                -47.03543476706194,
                -3.586822557086125
              ],
              [
                -47.026892829807956,
                -3.579484595384529
              ],
              [
                -47.03049083688849,
                -3.580263600474884
              ],
              [
                -47.03646679035196,
                -3.57301116774446
              ],
              [
                -47.03519077626049,
                -3.568246685045902
              ],
              [
                -47.036683261585,
                -3.564310699708119
              ],
              [
                -47.037358772205536,
                -3.562528711684749
              ],
              [
                -47.03291881888721,
                -3.561040240269432
              ],
              [
                -47.0292208487748,
                -3.559800229423165
              ],
              [
                -47.02285689075521,
                -3.563799717108468
              ],
              [
                -47.019020901673464,
                -3.562631718687911
              ],
              [
                -47.01817944453563,
                -3.548234309739178
              ],
              [
                -47.01807992205319,
                -3.546535855779784
              ],
              [
                -47.01567942866023,
                -3.546062849068904
              ],
              [
                -47.00955200315932,
                -3.544854857918058
              ],
              [
                -47.00754699871579,
                -3.539922865122058
              ],
              [
                -47.005391044591256,
                -3.534618901911781
              ],
              [
                -46.99931658699732,
                -3.534975402658988
              ],
              [
                -46.99252312990186,
                -3.535373897785629
              ],
              [
                -46.990219646157385,
                -3.529507436529459
              ],
              [
                -46.990112654098525,
                -3.529233948429266
              ],
              [
                -46.98949466290854,
                -3.529071453310687
              ],
              [
                -46.976640258970306,
                -3.525684957698922
              ],
              [
                -46.97983022451498,
                -3.518866029692892
              ],
              [
                -46.98033072014837,
                -3.517796019537785
              ],
              [
                -46.9793147460816,
                -3.517472522390558
              ],
              [
                -46.969999315826996,
                -3.514503561404009
              ],
              [
                -46.975468786146486,
                -3.507073612772075
              ],
              [
                -46.98305722547584,
                -3.496766194577444
              ],
              [
                -46.97835523293322,
                -3.491272732649337
              ],
              [
                -46.96928683087529,
                -3.490360214152892
              ],
              [
                -46.96824635390102,
                -3.490255210442457
              ],
              [
                -46.966778344742025,
                -3.493499713667084
              ],
              [
                -46.96041040103563,
                -3.494755186906092
              ],
              [
                -46.9658578542182,
                -3.48108979273117
              ],
              [
                -46.96888233132416,
                -3.47350232515852
              ],
              [
                -46.9633793701333,
                -3.474413833243432
              ],
              [
                -46.948460979495735,
                -3.476885291067406
              ],
              [
                -46.9488404883368,
                -3.465942383289818
              ],
              [
                -46.955214446962344,
                -3.464738374668631
              ],
              [
                -46.95616794911581,
                -3.461351426245196
              ],
              [
                -46.95194248380229,
                -3.456098968868939
              ],
              [
                -46.9468275131468,
                -3.458168927866964
              ],
              [
                -46.9513614796899,
                -3.448156013287563
              ],
              [
                -46.94277654551717,
                -3.450553485439324
              ],
              [
                -46.94054856389177,
                -3.44758550464973
              ],
              [
                -46.9474445068286,
                -3.436336589931358
              ],
              [
                -46.94929548556647,
                -3.436481570700089
              ],
              [
                -46.950835479700686,
                -3.436602577505022
              ],
              [
                -46.95546743588241,
                -3.423289701529447
              ],
              [
                -46.94718051351732,
                -3.423136704037999
              ],
              [
                -46.94652150658587,
                -3.418432739335337
              ],
              [
                -46.945378524735176,
                -3.419880693504173
              ],
              [
                -46.94333357031568,
                -3.422470205133008
              ],
              [
                -46.9413875921645,
                -3.415644748025574
              ],
              [
                -46.94422156598115,
                -3.411993773317027
              ],
              [
                -46.94845803680799,
                -3.406536806106916
              ],
              [
                -46.944244536387636,
                -3.39653488972425
              ],
              [
                -46.94857652195305,
                -3.392300417825157
              ],
              [
                -46.94346355821382,
                -3.389855929095158
              ],
              [
                -46.94112859767709,
                -3.39091791350931
              ],
              [
                -46.936665601057626,
                -3.3929478850964
              ],
              [
                -46.93626962770017,
                -3.389164932951703
              ],
              [
                -46.93840809852664,
                -3.383890469548509
              ],
              [
                -46.94205259836513,
                -3.374901037331205
              ],
              [
                -46.93804861063353,
                -3.371228076870479
              ],
              [
                -46.93325065527273,
                -3.376380036010519
              ],
              [
                -46.927533689624276,
                -3.375234532759616
              ],
              [
                -46.92333571019389,
                -3.374394008978111
              ],
              [
                -46.92198173609379,
                -3.370815035983081
              ],
              [
                -46.920946747188815,
                -3.368080073092986
              ],
              [
                -46.92341472901382,
                -3.363886083122848
              ],
              [
                -46.91680679034628,
                -3.36479008985328
              ],
              [
                -46.91521677098624,
                -3.361373131608307
              ],
              [
                -46.91242729962415,
                -3.363210107289865
              ],
              [
                -46.90699785879627,
                -3.366784084428834
              ],
              [
                -46.903887882825885,
                -3.364035091765105
              ],
              [
                -46.9052148880935,
                -3.358192630109492
              ],
              [
                -46.8988359066731,
                -3.356739127801975
              ],
              [
                -46.899651410879656,
                -3.353239658177987
              ],
              [
                -46.899945421150235,
                -3.351980164311487
              ],
              [
                -46.90402986034355,
                -3.350671180402034
              ],
              [
                -46.889595479202875,
                -3.341002738476018
              ],
              [
                -46.88741501939754,
                -3.335122307638168
              ],
              [
                -46.8804710460157,
                -3.339208262472379
              ],
              [
                -46.87706607432845,
                -3.341211262702042
              ],
              [
                -46.878600090248725,
                -3.344322210084448
              ],
              [
                -46.867992187223706,
                -3.338322268558305
              ],
              [
                -46.86713416092944,
                -3.333244778546737
              ],
              [
                -46.86705816669942,
                -3.33279730871836
              ],
              [
                -46.872524130953266,
                -3.327422336495461
              ],
              [
                -46.8672111891492,
                -3.326484344981557
              ],
              [
                -46.86378820225275,
                -3.331748799850959
              ],
              [
                -46.857545248209114,
                -3.341350249480289
              ],
              [
                -46.848041320759336,
                -3.335271277008109
              ],
              [
                -46.85130530426049,
                -3.329072817770376
              ],
              [
                -46.85048581738284,
                -3.324675856917971
              ],
              [
                -46.84925830854456,
                -3.318090414574259
              ],
              [
                -46.84552734430549,
                -3.320274869364742
              ],
              [
                -46.839570393631774,
                -3.323762842883124
              ],
              [
                -46.82978847806105,
                -3.311067444757604
              ],
              [
                -46.82153654088009,
                -3.312531452820694
              ],
              [
                -46.81295160325355,
                -3.309188476201861
              ],
              [
                -46.81123662158647,
                -3.301552525233054
              ],
              [
                -46.82160751932107,
                -3.295585064328669
              ],
              [
                -46.827415474054746,
                -3.292243566695638
              ],
              [
                -46.818465059523874,
                -3.285725109722675
              ],
              [
                -46.80908563942279,
                -3.278894173834054
              ],
              [
                -46.81498858971045,
                -3.274818711503582
              ],
              [
                -46.823607520890725,
                -3.268867755740045
              ],
              [
                -46.818724591659816,
                -3.263375780123093
              ],
              [
                -46.81162613695603,
                -3.266491279852917
              ],
              [
                -46.80966264769928,
                -3.267352751864459
              ],
              [
                -46.803608186846596,
                -3.263422792569993
              ],
              [
                -46.804687679369046,
                -3.256482844812186
              ],
              [
                -46.80701968351242,
                -3.241499954294958
              ],
              [
                -46.79560775450444,
                -3.235924958658711
              ],
              [
                -46.7922222923674,
                -3.238820948486187
              ],
              [
                -46.78855379830963,
                -3.241958919459353
              ],
              [
                -46.78179887683947,
                -3.24052091802707
              ],
              [
                -46.77813088836438,
                -3.227340047194655
              ],
              [
                -46.77929688465035,
                -3.224222037064454
              ],
              [
                -46.78065886163916,
                -3.220578094791784
              ],
              [
                -46.7789399032339,
                -3.214659120614964
              ],
              [
                -46.77022996592767,
                -3.212911118649151
              ],
              [
                -46.76097105689771,
                -3.211053160256453
              ],
              [
                -46.75908004873507,
                -3.217821097853588
              ],
              [
                -46.75572305939562,
                -3.215744118420558
              ],
              [
                -46.75673107260944,
                -3.208325165149879
              ],
              [
                -46.766427978198585,
                -3.20284470945277
              ],
              [
                -46.77050097278671,
                -3.20054221864238
              ],
              [
                -46.764439504594726,
                -3.197580728486179
              ],
              [
                -46.75675207123205,
                -3.193823782400576
              ],
              [
                -46.75922903726693,
                -3.186046331311677
              ],
              [
                -46.75989406834952,
                -3.18554531718063
              ],
              [
                -46.77333295098983,
                -3.175410391427672
              ],
              [
                -46.76521402774684,
                -3.174636391746975
              ],
              [
                -46.75781007097045,
                -3.18154235012755
              ],
              [
                -46.75202912530897,
                -3.186934298039076
              ],
              [
                -46.74602517486145,
                -3.17847436947383
              ],
              [
                -46.74736615270988,
                -3.170465942612314
              ],
              [
                -46.74844613394504,
                -3.164015457444065
              ],
              [
                -46.746246156133765,
                -3.160815511589044
              ],
              [
                -46.73729524341932,
                -3.159785985436694
              ],
              [
                -46.72644230844044,
                -3.158538495100234
              ],
              [
                -46.723357336225604,
                -3.150802546557554
              ],
              [
                -46.73164229138345,
                -3.138219651414863
              ],
              [
                -46.731995285439325,
                -3.125983753646516
              ],
              [
                -46.73663474373282,
                -3.128554222965341
              ],
              [
                -46.74497120578073,
                -3.133172708003588
              ],
              [
                -46.7473951630547,
                -3.120527801979536
              ],
              [
                -46.7396692317307,
                -3.122517785618065
              ],
              [
                -46.73455929102517,
                -3.120021785582498
              ],
              [
                -46.731382782459875,
                -3.122625782584588
              ],
              [
                -46.73037080692573,
                -3.123455740210674
              ],
              [
                -46.729146317308995,
                -3.120989291490297
              ],
              [
                -46.72399935694707,
                -3.110621853139553
              ],
              [
                -46.719318401789295,
                -3.108527882392135
              ],
              [
                -46.71619540693108,
                -3.110849857443338
              ],
              [
                -46.71618693474143,
                -3.111507842699949
              ],
              [
                -46.71600143062371,
                -3.125791723739817
              ],
              [
                -46.71011046611846,
                -3.127995726548615
              ],
              [
                -46.704806486151654,
                -3.119776793670104
              ],
              [
                -46.70597198634399,
                -3.10385688983192
              ],
              [
                -46.706414515267575,
                -3.097817938523168
              ],
              [
                -46.69072060745456,
                -3.09174295720426
              ],
              [
                -46.69039412395272,
                -3.091784969774451
              ],
              [
                -46.67944072177475,
                -3.09318996738617
              ],
              [
                -46.682933200677525,
                -3.080683061929655
              ],
              [
                -46.683914680465485,
                -3.077165599676483
              ],
              [
                -46.681023209165524,
                -3.073429120507106
              ],
              [
                -46.67524475952621,
                -3.065962147140077
              ],
              [
                -46.67745423482671,
                -3.059873699915483
              ],
              [
                -46.679663720123,
                -3.053786261684553
              ],
              [
                -46.67854873175359,
                -3.035190378449801
              ],
              [
                -46.674221779814424,
                -3.033250890798288
              ],
              [
                -46.66869381933169,
                -3.03077340057578
              ],
              [
                -46.67190328212711,
                -3.024083473093457
              ],
              [
                -46.6737717781982,
                -3.020188497923256
              ],
              [
                -46.66808231732137,
                -3.014069045950688
              ],
              [
                -46.66396785967564,
                -3.00964356935242
              ],
              [
                -46.66585835163615,
                -3.008241559192726
              ],
              [
                -46.672382782184926,
                -3.003403620596628
              ],
              [
                -46.67222079886058,
                -2.996896659529964
              ],
              [
                -46.668803834690515,
                -2.992845703503864
              ],
              [
                -46.66059189336087,
                -2.994437689914152
              ],
              [
                -46.65166395950514,
                -2.996167656055442
              ],
              [
                -46.6486109848517,
                -2.98319475165299
              ],
              [
                -46.651661977956095,
                -2.978839779269663
              ],
              [
                -46.65916641282652,
                -2.976973795562758
              ],
              [
                -46.66161089521877,
                -2.976365802870726
              ],
              [
                -46.661444869818574,
                -2.972794839614068
              ],
              [
                -46.66036790615576,
                -2.972686333995898
              ],
              [
                -46.64751900563419,
                -2.971392827546949
              ],
              [
                -46.64761501259907,
                -2.961345929213315
              ],
              [
                -46.655297962558556,
                -2.955477935492154
              ],
              [
                -46.66560734168146,
                -2.953989480079804
              ],
              [
                -46.66586034174587,
                -2.95395297243762
              ],
              [
                -46.67124882387247,
                -2.946773509331423
              ],
              [
                -46.66686184370428,
                -2.93572910755347
              ],
              [
                -46.66515238610516,
                -2.935092101528652
              ],
              [
                -46.65338447636476,
                -2.930706646905209
              ],
              [
                -46.655997954081016,
                -2.922868174560432
              ],
              [
                -46.66060393320745,
                -2.91843071634725
              ],
              [
                -46.66275087541885,
                -2.916361252102752
              ],
              [
                -46.665189899646776,
                -2.905263299271709
              ],
              [
                -46.66513339609091,
                -2.905238799428314
              ],
              [
                -46.66214090861115,
                -2.903942328714939
              ],
              [
                -46.67056834618686,
                -2.894255901862107
              ],
              [
                -46.67219785030965,
                -2.892383410001339
              ],
              [
                -46.680276786179235,
                -2.892131408761461
              ],
              [
                -46.67922778211648,
                -2.881383489133958
              ],
              [
                -46.66821538404754,
                -2.875474513906735
              ],
              [
                -46.660681933578076,
                -2.876872023069383
              ],
              [
                -46.654204462049655,
                -2.878074530023424
              ],
              [
                -46.64600004411476,
                -2.884074472622265
              ],
              [
                -46.64044109108993,
                -2.880893480637549
              ],
              [
                -46.64139208115701,
                -2.876993536217161
              ],
              [
                -46.634445616879255,
                -2.873661540834645
              ],
              [
                -46.63459614004489,
                -2.869946563791459
              ],
              [
                -46.64002606958429,
                -2.868868579554825
              ],
              [
                -46.64072157258683,
                -2.865045609489222
              ],
              [
                -46.64091509747534,
                -2.863978624285637
              ],
              [
                -46.63538312111257,
                -2.858334633173561
              ],
              [
                -46.640019099434234,
                -2.852564189632366
              ],
              [
                -46.63624312714172,
                -2.850844711734762
              ],
              [
                -46.626131684509254,
                -2.855249649201248
              ],
              [
                -46.617692270257216,
                -2.85892562454127
              ],
              [
                -46.61000231706112,
                -2.854640660831666
              ],
              [
                -46.611260314752094,
                -2.845064230683652
              ],
              [
                -46.61167533066763,
                -2.841906762716418
              ],
              [
                -46.61810925030886,
                -2.835553790055307
              ],
              [
                -46.61351732515219,
                -2.828602867806492
              ],
              [
                -46.600394424402765,
                -2.834079326439149
              ],
              [
                -46.59859040479659,
                -2.835547310153923
              ],
              [
                -46.58747202334755,
                -2.844595721585442
              ],
              [
                -46.58141354973107,
                -2.839902746454869
              ],
              [
                -46.57538760381108,
                -2.841040738054207
              ],
              [
                -46.58382804532967,
                -2.833593833067622
              ],
              [
                -46.580638554163286,
                -2.830085824377067
              ],
              [
                -46.58255555438964,
                -2.827202846968359
              ],
              [
                -46.59011098263089,
                -2.831438807929408
              ],
              [
                -46.591021976159226,
                -2.829862837681807
              ],
              [
                -46.59391344161735,
                -2.824859881989315
              ],
              [
                -46.59009098166343,
                -2.823238873390767
              ],
              [
                -46.58902448204701,
                -2.822785887298378
              ],
              [
                -46.5900004732897,
                -2.817731912301256
              ],
              [
                -46.592724450111064,
                -2.815363929440057
              ],
              [
                -46.59367595261238,
                -2.815824952432359
              ],
              [
                -46.596641432241526,
                -2.817263946043827
              ],
              [
                -46.60016041168601,
                -2.81274695704749
              ],
              [
                -46.599358934891136,
                -2.804071528690106
              ],
              [
                -46.5985514441537,
                -2.795337112768948
              ],
              [
                -46.60191941143501,
                -2.788847144034562
              ],
              [
                -46.60733636582904,
                -2.788390169246174
              ],
              [
                -46.60885235055194,
                -2.792977125502405
              ],
              [
                -46.611007347673926,
                -2.799498067960023
              ],
              [
                -46.62697219201884,
                -2.804081033342916
              ],
              [
                -46.63622116247812,
                -2.793332124527721
              ],
              [
                -46.637160158585495,
                -2.78079022479872
              ],
              [
                -46.63624515442242,
                -2.778514244984426
              ],
              [
                -46.631532187777076,
                -2.766798296319746
              ],
              [
                -46.63549416055936,
                -2.754220404939922
              ],
              [
                -46.63986212728567,
                -2.750173444810455
              ],
              [
                -46.64277611546307,
                -2.754179893753234
              ],
              [
                -46.645015092158026,
                -2.757258399197657
              ],
              [
                -46.650443054562324,
                -2.756607403799497
              ],
              [
                -46.65663399804501,
                -2.738524509100735
              ],
              [
                -46.65590802179639,
                -2.7380570172422
              ],
              [
                -46.65254901240649,
                -2.735898053749396
              ],
              [
                -46.65243803157254,
                -2.729092589087242
              ],
              [
                -46.66092495733029,
                -2.731648065459004
              ],
              [
                -46.667663908736884,
                -2.733677555051809
              ],
              [
                -46.66980388536062,
                -2.730424602381446
              ],
              [
                -46.66599591575115,
                -2.724339645216263
              ],
              [
                -46.671469888213494,
                -2.71066424330108
              ],
              [
                -46.66749644164263,
                -2.702257306951557
              ],
              [
                -46.6635239460573,
                -2.693849865622587
              ],
              [
                -46.65251705495237,
                -2.690522866050323
              ],
              [
                -46.6465490985304,
                -2.688718895131423
              ],
              [
                -46.647640092798724,
                -2.686702887138312
              ],
              [
                -46.65030706889787,
                -2.681771924842897
              ],
              [
                -46.648579062201904,
                -2.678792969893228
              ],
              [
                -46.642144122576994,
                -2.682397440549852
              ],
              [
                -46.63557766794457,
                -2.68607589762063
              ],
              [
                -46.632609188292385,
                -2.681654924730308
              ],
              [
                -46.633037177696174,
                -2.676541483017879
              ],
              [
                -46.63335369551325,
                -2.672760999587014
              ],
              [
                -46.620211320088714,
                -2.665838033481832
              ],
              [
                -46.62150029529702,
                -2.64417620035315
              ],
              [
                -46.61069836778592,
                -2.643468182914433
              ],
              [
                -46.605553420983206,
                -2.638780233296058
              ],
              [
                -46.59839946709,
                -2.654137124251752
              ],
              [
                -46.59332849859353,
                -2.665025042395374
              ],
              [
                -46.58891555733296,
                -2.659844090591689
              ],
              [
                -46.58593856428611,
                -2.644663705002536
              ],
              [
                -46.58436260407202,
                -2.636629230925218
              ],
              [
                -46.57435567192977,
                -2.628414315390738
              ],
              [
                -46.563304773742054,
                -2.62665732453494
              ],
              [
                -46.561543746074875,
                -2.627430803324677
              ],
              [
                -46.55474982751433,
                -2.630416292353019
              ],
              [
                -46.53994194836475,
                -2.622457340421942
              ],
              [
                -46.504584203855046,
                -2.614570396270504
              ],
              [
                -46.50669220569221,
                -2.601843472712495
              ],
              [
                -46.50879918851772,
                -2.589117065162116
              ],
              [
                -46.50065725344567,
                -2.579550660271989
              ],
              [
                -46.49971827104889,
                -2.578446660018853
              ],
              [
                -46.49041382106045,
                -2.581074622847193
              ],
              [
                -46.48695935734714,
                -2.569765709217648
              ],
              [
                -46.48936535012684,
                -2.562336772184593
              ],
              [
                -46.49316482745205,
                -2.560233797745142
              ],
              [
                -46.501435265039696,
                -2.555655835790606
              ],
              [
                -46.49505230354346,
                -2.539125927352124
              ],
              [
                -46.49165634794615,
                -2.535376963630977
              ],
              [
                -46.4827764264984,
                -2.532276957438487
              ],
              [
                -46.47938794080843,
                -2.541385919874156
              ],
              [
                -46.477711441685145,
                -2.541616906654591
              ],
              [
                -46.46577152040254,
                -2.543261892845134
              ],
              [
                -46.41596793978064,
                -2.529056506796227
              ],
              [
                -46.415303935417654,
                -2.524247025736378
              ],
              [
                -46.42726584094202,
                -2.517824061451058
              ],
              [
                -46.433503298769374,
                -2.514475081888764
              ],
              [
                -46.43528278540943,
                -2.509989139230578
              ],
              [
                -46.42960033031851,
                -2.49712123650205
              ],
              [
                -46.42799935681296,
                -2.493496747498668
              ],
              [
                -46.43228380238717,
                -2.48800526898098
              ],
              [
                -46.43758276651783,
                -2.481215326268951
              ],
              [
                -46.4359377929551,
                -2.475595382848094
              ],
              [
                -46.43472580059035,
                -2.471453404759347
              ],
              [
                -46.431169821781936,
                -2.459302504525476
              ],
              [
                -46.436476776097415,
                -2.458204989185546
              ],
              [
                -46.439003285315984,
                -2.449163066094303
              ],
              [
                -46.43924677169809,
                -2.448292063539827
              ],
              [
                -46.43889777214348,
                -2.445821091507154
              ],
              [
                -46.43658077599866,
                -2.429415200823508
              ],
              [
                -46.43811026471727,
                -2.420952274213686
              ],
              [
                -46.43963977742743,
                -2.41248883561255
              ],
              [
                -46.42786287000542,
                -2.40054643148284
              ],
              [
                -46.426595392184225,
                -2.399260933513959
              ],
              [
                -46.42304239311191,
                -2.390946499330023
              ],
              [
                -46.42303992613261,
                -2.390940981369669
              ],
              [
                -46.41948444107391,
                -2.38262053824432
              ],
              [
                -46.40837154375479,
                -2.36980816080197
              ],
              [
                -46.41084500797486,
                -2.361795215249097
              ],
              [
                -46.42661039490355,
                -2.359044239061291
              ],
              [
                -46.43558831173392,
                -2.368549677088946
              ],
              [
                -46.44702972375827,
                -2.380663077788235
              ],
              [
                -46.45390164788535,
                -2.383295064569696
              ],
              [
                -46.46296310314218,
                -2.375612120507482
              ],
              [
                -46.464448091025844,
                -2.370999136715464
              ],
              [
                -46.4630526149943,
                -2.360109731021181
              ],
              [
                -46.43633280912752,
                -2.333662419888883
              ],
              [
                -46.433691333296096,
                -2.331047946277097
              ],
              [
                -46.43215435764094,
                -2.32595746013667
              ],
              [
                -46.430616845987366,
                -2.320865511011676
              ],
              [
                -46.43069935920616,
                -2.317880037919698
              ],
              [
                -46.431244857663785,
                -2.298006191780976
              ],
              [
                -46.42632839790864,
                -2.293618212545908
              ],
              [
                -46.421412445150104,
                -2.289229729322377
              ],
              [
                -46.41686348452811,
                -2.260025959286712
              ],
              [
                -46.41911146683195,
                -2.25758848680973
              ],
              [
                -46.42961090084981,
                -2.246205068306646
              ],
              [
                -46.431918397884054,
                -2.239473112359025
              ],
              [
                -46.41052556465223,
                -2.23074717543257
              ],
              [
                -46.401329627085396,
                -2.24399605857831
              ],
              [
                -46.39182717795533,
                -2.249790040364906
              ],
              [
                -46.387604206897244,
                -2.252365508264436
              ],
              [
                -46.381857749087224,
                -2.24968352934265
              ],
              [
                -46.37611131727992,
                -2.247002058421953
              ],
              [
                -46.37341330858581,
                -2.241824078700576
              ],
              [
                -46.36660890783058,
                -2.228763669757921
              ],
              [
                -46.35705094437607,
                -2.226594694036956
              ],
              [
                -46.352915483633105,
                -2.225656185784768
              ],
              [
                -46.341940571537855,
                -2.218462237624774
              ],
              [
                -46.341883089196465,
                -2.210365794143308
              ],
              [
                -46.337584599709274,
                -2.202125381561447
              ],
              [
                -46.330806662693405,
                -2.189131970285223
              ],
              [
                -46.323610250210976,
                -2.182421502697772
              ],
              [
                -46.31641427972559,
                -2.175710572131273
              ],
              [
                -46.316291300692896,
                -2.175654049513898
              ],
              [
                -46.29141747637132,
                -2.164219645966318
              ],
              [
                -46.28022206319472,
                -2.152558211854526
              ],
              [
                -46.28959149623933,
                -2.150219745598598
              ],
              [
                -46.29610647021165,
                -2.148594231321021
              ],
              [
                -46.29660446785341,
                -2.14293280415998
              ],
              [
                -46.27342812398224,
                -2.121611944117688
              ],
              [
                -46.27186314348333,
                -2.120171937319778
              ],
              [
                -46.27016716375433,
                -2.115839980810733
              ],
              [
                -46.26847065902787,
                -2.111505998320451
              ],
              [
                -46.26799269977783,
                -2.092029150838672
              ],
              [
                -46.272246173573826,
                -2.085233226076109
              ],
              [
                -46.27650063735898,
                -2.078437249312318
              ],
              [
                -46.272974641784714,
                -2.058399407242402
              ],
              [
                -46.26557322717769,
                -2.050302961099673
              ],
              [
                -46.26520070316695,
                -2.049895453012333
              ],
              [
                -46.25854376158384,
                -2.042614539061853
              ],
              [
                -46.24811187532353,
                -2.019044195485411
              ],
              [
                -46.24858387100856,
                -2.009605257333566
              ],
              [
                -46.24905635467915,
                -2.000165329198105
              ],
              [
                -46.23289048219281,
                -1.966537575771263
              ],
              [
                -46.21815862521864,
                -1.912881478193969
              ],
              [
                -46.2254680639353,
                -1.881275726930081
              ],
              [
                -46.233637017642636,
                -1.881146701801443
              ],
              [
                -46.242249943919326,
                -1.881009701836066
              ],
              [
                -46.22546555764099,
                -1.856571887902638
              ],
              [
                -46.20959020997283,
                -1.83345857552926
              ],
              [
                -46.21224565610966,
                -1.825795133943109
              ],
              [
                -46.21490114124019,
                -1.818130682366008
              ],
              [
                -46.22891204060543,
                -1.800261309006295
              ],
              [
                -46.25747982591902,
                -1.792573890390209
              ],
              [
                -46.282534645493975,
                -1.798649845984749
              ],
              [
                -46.28762558560029,
                -1.796247346922802
              ],
              [
                -46.29380156942057,
                -1.793332882893454
              ],
              [
                -46.29898052036848,
                -1.797631366219411
              ],
              [
                -46.3065834531035,
                -1.80394229619832
              ],
              [
                -46.31292988786145,
                -1.799948350196219
              ],
              [
                -46.31617789488426,
                -1.794695357816314
              ],
              [
                -46.3217083309392,
                -1.772604538621736
              ],
              [
                -46.32263381258485,
                -1.768903564234467
              ],
              [
                -46.32356031322092,
                -1.765203087844442
              ],
              [
                -46.31410290957988,
                -1.741352773456992
              ],
              [
                -46.31096444057013,
                -1.733437807430502
              ],
              [
                -46.30598644612791,
                -1.728650364838088
              ],
              [
                -46.30467746652953,
                -1.727392362880635
              ],
              [
                -46.290748072295244,
                -1.723080395848522
              ],
              [
                -46.276451183464786,
                -1.725663362617244
              ],
              [
                -46.26128632734979,
                -1.728403848022682
              ],
              [
                -46.25180490602868,
                -1.722662903573287
              ],
              [
                -46.24232394671053,
                -1.716922928134422
              ],
              [
                -46.235136020655524,
                -1.720512395953268
              ],
              [
                -46.22474161411989,
                -1.725703352190354
              ],
              [
                -46.21779564075557,
                -1.726688372383635
              ],
              [
                -46.212150217963426,
                -1.724616388551917
              ],
              [
                -46.20388077148578,
                -1.717988914989652
              ],
              [
                -46.20399377436755,
                -1.714400936659959
              ],
              [
                -46.20413527721094,
                -1.709937997836112
              ],
              [
                -46.20043328241605,
                -1.702801541057127
              ],
              [
                -46.19123134656981,
                -1.703792036745844
              ],
              [
                -46.17005901942192,
                -1.696045079832008
              ],
              [
                -46.153205652438906,
                -1.675555725636211
              ],
              [
                -46.152793668585,
                -1.661181332470259
              ],
              [
                -46.15586264904843,
                -1.654909901758206
              ],
              [
                -46.15914062697319,
                -1.648211417265822
              ],
              [
                -46.15957412643202,
                -1.624246122680119
              ],
              [
                -46.14426823219105,
                -1.609240234304922
              ],
              [
                -46.144329753290656,
                -1.601249294820608
              ],
              [
                -46.145668256215345,
                -1.599659777924089
              ],
              [
                -46.15000168859641,
                -1.594511320125271
              ],
              [
                -46.14918721126025,
                -1.587645390096455
              ],
              [
                -46.13969278090882,
                -1.582704897022617
              ],
              [
                -46.13155883386701,
                -1.578472443955133
              ],
              [
                -46.14212525981312,
                -1.520936851834165
              ],
              [
                -46.144978753722015,
                -1.515287399496765
              ],
              [
                -46.1478322426283,
                -1.509637453162623
              ],
              [
                -46.15429620681879,
                -1.502347500732873
              ],
              [
                -46.161953110000105,
                -1.493712078552063
              ],
              [
                -46.16213012003859,
                -1.491468616313904
              ],
              [
                -46.16258261057869,
                -1.48572915019776
              ],
              [
                -46.166204109001946,
                -1.482884664079523
              ],
              [
                -46.17621450877928,
                -1.475020705565848
              ],
              [
                -46.157586157096574,
                -1.459485847035812
              ],
              [
                -46.139090806373176,
                -1.444061955801429
              ],
              [
                -46.137501814175344,
                -1.435758019510257
              ],
              [
                -46.14605776098711,
                -1.428873068438583
              ],
              [
                -46.15557169450433,
                -1.42121663528686
              ],
              [
                -46.13111338798184,
                -1.400054763033199
              ],
              [
                -46.124530438948504,
                -1.377390942252606
              ],
              [
                -46.12069145160611,
                -1.372324980462717
              ],
              [
                -46.11685349325419,
                -1.367259034680629
              ],
              [
                -46.1146265054226,
                -1.350013129259223
              ],
              [
                -46.10383762040514,
                -1.336604761750143
              ],
              [
                -46.10573910937291,
                -1.332133775515422
              ],
              [
                -46.12125097076639,
                -1.333195771142896
              ],
              [
                -46.12888539641862,
                -1.333718769498148
              ],
              [
                -46.13237937792643,
                -1.329885817742271
              ],
              [
                -46.131560902945225,
                -1.319651905148552
              ],
              [
                -46.13078290355598,
                -1.309921942805121
              ],
              [
                -46.139821347587755,
                -1.308800981663452
              ],
              [
                -46.148859276633516,
                -1.307679995519738
              ],
              [
                -46.16248317165416,
                -1.2779691959431
              ],
              [
                -46.14027031546757,
                -1.249235426479482
              ],
              [
                -46.145497783796266,
                -1.236840501088004
              ],
              [
                -46.15072476211324,
                -1.224447592682346
              ],
              [
                -46.13015493143566,
                -1.206401220742573
              ],
              [
                -46.11331854751333,
                -1.197018798538011
              ],
              [
                -46.11235156709979,
                -1.196479813434146
              ],
              [
                -46.10660810317498,
                -1.199993285240888
              ],
              [
                -46.10554860130594,
                -1.200640770229
              ],
              [
                -46.10373813320012,
                -1.20174827665627
              ],
              [
                -46.07666631830988,
                -1.195909299403037
              ],
              [
                -46.07456184976829,
                -1.16057456119239
              ],
              [
                -46.07267837380184,
                -1.157748587129452
              ],
              [
                -46.0675539087019,
                -1.15005665212888
              ],
              [
                -46.06693991462938,
                -1.163038560599523
              ],
              [
                -46.06428343897184,
                -1.143941690568429
              ],
              [
                -46.06350043522249,
                -1.142750704398827
              ],
              [
                -46.057919473771825,
                -1.134261772350144
              ],
              [
                -46.05593852158319,
                -1.131249284694519
              ],
              [
                -46.053068506879434,
                -1.131289275990665
              ],
              [
                -46.05121903859757,
                -1.135990249424301
              ],
              [
                -46.04888455291022,
                -1.141923196537478
              ],
              [
                -46.04782155825131,
                -1.148470166221612
              ],
              [
                -46.043532084477334,
                -1.163354554843681
              ],
              [
                -46.04233309536733,
                -1.166354527146366
              ],
              [
                -46.03984963077814,
                -1.172569474125396
              ],
              [
                -46.036974624917306,
                -1.167046029177702
              ],
              [
                -46.043625580224564,
                -1.152981124747833
              ],
              [
                -46.04178760126343,
                -1.133343771972027
              ],
              [
                -46.04435660845358,
                -1.124994846049147
              ],
              [
                -46.04564560401286,
                -1.12080585569839
              ],
              [
                -46.03465017856349,
                -1.105496493213654
              ],
              [
                -46.03230417377412,
                -1.105682467493667
              ],
              [
                -46.0299581909852,
                -1.105868483773345
              ],
              [
                -46.02641475956163,
                -1.098243528603563
              ],
              [
                -46.019051292927,
                -1.082398141744368
              ],
              [
                -46.01552833711442,
                -1.083932641730694
              ],
              [
                -46.017613297141,
                -1.074581209340097
              ],
              [
                -46.01603480222571,
                -1.068402767601818
              ],
              [
                -46.01511684383279,
                -1.064810785500128
              ],
              [
                -46.0112408669897,
                -1.064664766086147
              ],
              [
                -46.00418390078737,
                -1.072899222201591
              ],
              [
                -46.00212893545255,
                -1.075298185880183
              ],
              [
                -46.00223194013686,
                -1.079195157577889
              ],
              [
                -46.00237292632881,
                -1.084561630228886
              ],
              [
                -45.999056449493644,
                -1.089274079334816
              ],
              [
                -45.997903959180704,
                -1.068678743120117
              ],
              [
                -45.99748846038094,
                -1.061257306909231
              ],
              [
                -45.99358549736754,
                -1.056398826959401
              ],
              [
                -45.98897755192992,
                -1.058553832135318
              ],
              [
                -45.98709006318597,
                -1.046349414754794
              ],
              [
                -45.97505164284429,
                -1.046302933541572
              ],
              [
                -45.96467722857116,
                -1.046261932503536
              ],
              [
                -45.96463123331291,
                -1.051058896373539
              ],
              [
                -45.96457170698258,
                -1.057204345090524
              ],
              [
                -45.97147367893768,
                -1.059826819252102
              ],
              [
                -45.97470765060279,
                -1.061158819650029
              ],
              [
                -45.97885810659761,
                -1.06664027593682
              ],
              [
                -45.969601693168634,
                -1.06240078462759
              ],
              [
                -45.964778725057116,
                -1.067307268051996
              ],
              [
                -45.96210173566927,
                -1.07659420178235
              ],
              [
                -45.95698629707698,
                -1.079635643202351
              ],
              [
                -45.95789526331965,
                -1.084791125514985
              ],
              [
                -45.967229691449226,
                -1.094019051323939
              ],
              [
                -45.96747119319932,
                -1.089313591767606
              ],
              [
                -45.967663702199935,
                -1.085570613963045
              ],
              [
                -45.970154188135005,
                -1.083209645067015
              ],
              [
                -45.97498315544922,
                -1.084064616282029
              ],
              [
                -45.97548214855472,
                -1.084156613656859
              ],
              [
                -45.9825540933622,
                -1.085467123747553
              ],
              [
                -45.985759573343884,
                -1.086085605525953
              ],
              [
                -45.98823503958902,
                -1.089825576722633
              ],
              [
                -45.974711141092534,
                -1.087620602485784
              ],
              [
                -45.97404314830432,
                -1.087511588216015
              ],
              [
                -45.97029019658614,
                -1.086900107311347
              ],
              [
                -45.97101267692478,
                -1.102113480932199
              ],
              [
                -45.97116467730988,
                -1.105309971903569
              ],
              [
                -45.97600564771033,
                -1.10482446321996
              ],
              [
                -45.973925659313224,
                -1.109102430751683
              ],
              [
                -45.98163358456529,
                -1.122124847869896
              ],
              [
                -45.975434621748875,
                -1.115149894468891
              ],
              [
                -45.967603696226625,
                -1.111139928552727
              ],
              [
                -45.96108223642834,
                -1.107800459780955
              ],
              [
                -45.95343079290118,
                -1.108352439575669
              ],
              [
                -45.9523922939605,
                -1.123160852004165
              ],
              [
                -45.95733925232969,
                -1.131339778164894
              ],
              [
                -45.95991074529423,
                -1.131505777277103
              ],
              [
                -45.97380563004791,
                -1.132401749489728
              ],
              [
                -45.9897840059987,
                -1.137750733522042
              ],
              [
                -45.98500305095623,
                -1.146561170612513
              ],
              [
                -45.99149948358672,
                -1.15248710099717
              ],
              [
                -45.99394598419585,
                -1.162084564222818
              ],
              [
                -45.985649058781505,
                -1.154712082436482
              ],
              [
                -45.981937084034364,
                -1.159114579813356
              ],
              [
                -45.98349404934314,
                -1.163182045467009
              ],
              [
                -45.984880045043916,
                -1.166803487451269
              ],
              [
                -45.980786571953885,
                -1.166352001251677
              ],
              [
                -45.97688211011105,
                -1.160879044823177
              ],
              [
                -45.97962557733236,
                -1.156894566167834
              ],
              [
                -45.98215407120034,
                -1.15308561616141
              ],
              [
                -45.97355614268492,
                -1.141763707055533
              ],
              [
                -45.96459221958384,
                -1.140697702802459
              ],
              [
                -45.963077725690354,
                -1.145547149118673
              ],
              [
                -45.95809976235202,
                -1.138956704978339
              ],
              [
                -45.95711376703204,
                -1.138910720184928
              ],
              [
                -45.94559538575769,
                -1.13837970755264
              ],
              [
                -45.941831386082065,
                -1.138205734330154
              ],
              [
                -45.949354336498715,
                -1.152637123842346
              ],
              [
                -45.94965783646683,
                -1.157371075283206
              ],
              [
                -45.94453537785495,
                -1.160786563860501
              ],
              [
                -45.94921184370608,
                -1.173014949583403
              ],
              [
                -45.94992432089253,
                -1.174878458676173
              ],
              [
                -45.94937733471744,
                -1.18479438004898
              ],
              [
                -45.94909532517121,
                -1.189892811681807
              ],
              [
                -45.95263729647923,
                -1.197573259478163
              ],
              [
                -45.95135581493055,
                -1.201134253524986
              ],
              [
                -45.94870933544518,
                -1.208486695875121
              ],
              [
                -45.95268679823353,
                -1.230267039832039
              ],
              [
                -45.95630126962268,
                -1.233439022557788
              ],
              [
                -45.94806332800832,
                -1.238292483838767
              ],
              [
                -45.94165387728032,
                -1.229170031390914
              ],
              [
                -45.93249945071471,
                -1.201180220370798
              ],
              [
                -45.93644942454943,
                -1.197177775047143
              ],
              [
                -45.936735445235755,
                -1.190931312050219
              ],
              [
                -45.926724006730744,
                -1.173330934935852
              ],
              [
                -45.90900565812666,
                -1.142180680777592
              ],
              [
                -45.90601868298924,
                -1.145055674713733
              ],
              [
                -45.90319768858162,
                -1.147769650884296
              ],
              [
                -45.90506069572723,
                -1.143082188433052
              ],
              [
                -45.90680767673363,
                -1.13868669776786
              ],
              [
                -45.89270177601035,
                -1.114039881390703
              ],
              [
                -45.89101329715138,
                -1.114137391423153
              ],
              [
                -45.88709833462182,
                -1.114363872176749
              ],
              [
                -45.88908831868804,
                -1.110171416032907
              ],
              [
                -45.890486792192355,
                -1.10722494142148
              ],
              [
                -45.8863898354654,
                -1.104610977553032
              ],
              [
                -45.874972418410124,
                -1.097327010882356
              ],
              [
                -45.88150986615131,
                -1.099689498968546
              ],
              [
                -45.88273984666829,
                -1.098940487780016
              ],
              [
                -45.88627632740297,
                -1.09678750948381
              ],
              [
                -45.886307820828854,
                -1.091772075280723
              ],
              [
                -45.86869998450816,
                -1.073083190790655
              ],
              [
                -45.86524999825831,
                -1.074131700426305
              ],
              [
                -45.860636043035484,
                -1.07553416123727
              ],
              [
                -45.86194404041493,
                -1.071157212416255
              ],
              [
                -45.857605544329665,
                -1.07040423051943
              ],
              [
                -45.861772525007915,
                -1.069199242158507
              ],
              [
                -45.86603998490891,
                -1.067964220036288
              ],
              [
                -45.87115746995416,
                -1.060836311460707
              ],
              [
                -45.86376100043803,
                -1.05434232746742
              ],
              [
                -45.84720462016513,
                -1.049121386697342
              ],
              [
                -45.850500111587905,
                -1.048235369810128
              ],
              [
                -45.84585616519417,
                -1.045085422975567
              ],
              [
                -45.840092684397476,
                -1.05079737812703
              ],
              [
                -45.83734722543006,
                -1.053704331834154
              ],
              [
                -45.834938241367894,
                -1.075581193461284
              ],
              [
                -45.8336167580856,
                -1.087587592718883
              ],
              [
                -45.838668212926,
                -1.100835993490367
              ],
              [
                -45.84085969728827,
                -1.097491025011186
              ],
              [
                -45.84198319975854,
                -1.095775019103301
              ],
              [
                -45.841781689858145,
                -1.099290498567791
              ],
              [
                -45.84165718253631,
                -1.101466477143743
              ],
              [
                -45.84458617225624,
                -1.10475996871193
              ],
              [
                -45.85387606741595,
                -1.115209898215658
              ],
              [
                -45.85119310500503,
                -1.11759787584668
              ],
              [
                -45.8511960859739,
                -1.11765537241374
              ],
              [
                -45.85152561433934,
                -1.125429794867046
              ],
              [
                -45.85545707453669,
                -1.126559303903278
              ],
              [
                -45.85879004742458,
                -1.12751629415718
              ],
              [
                -45.880339881622064,
                -1.133707737532294
              ],
              [
                -45.88233036531139,
                -1.139469711417996
              ],
              [
                -45.88988578534468,
                -1.147331145292721
              ],
              [
                -45.90512368640018,
                -1.16318600913244
              ],
              [
                -45.906011685888984,
                -1.167355510885119
              ],
              [
                -45.907908171840084,
                -1.176264438128797
              ],
              [
                -45.904679659747856,
                -1.177924923165011
              ],
              [
                -45.904010670909166,
                -1.178268927479175
              ],
              [
                -45.90438017673758,
                -1.180302406234033
              ],
              [
                -45.904591662921796,
                -1.181468394492761
              ],
              [
                -45.90675117530349,
                -1.193838804754035
              ],
              [
                -45.901733714487534,
                -1.193143782239974
              ],
              [
                -45.89980570980371,
                -1.177298898164064
              ],
              [
                -45.89938871654471,
                -1.17387345787839
              ],
              [
                -45.88449283333812,
                -1.154609599725271
              ],
              [
                -45.87933938402873,
                -1.150812620578176
              ],
              [
                -45.87490842601586,
                -1.147548160529939
              ],
              [
                -45.87288694697794,
                -1.146058639460396
              ],
              [
                -45.8652884671221,
                -1.146591146371141
              ],
              [
                -45.86135700211672,
                -1.151205599055579
              ],
              [
                -45.862651004264684,
                -1.156636085339864
              ],
              [
                -45.86892094639696,
                -1.163687030143456
              ],
              [
                -45.87207543687763,
                -1.163231028031402
              ],
              [
                -45.87242092310286,
                -1.163810029721699
              ],
              [
                -45.87708937660393,
                -1.171641463437527
              ],
              [
                -45.874600417518494,
                -1.174995924824115
              ],
              [
                -45.87110093315676,
                -1.172442964534475
              ],
              [
                -45.86912497376283,
                -1.171001470100316
              ],
              [
                -45.86512149484222,
                -1.171860441052323
              ],
              [
                -45.86082803896651,
                -1.167246479172837
              ],
              [
                -45.85911255353296,
                -1.172875947533731
              ],
              [
                -45.869495428295906,
                -1.188882840545802
              ],
              [
                -45.871418920873715,
                -1.191847808513307
              ],
              [
                -45.86353699869383,
                -1.189169822508361
              ],
              [
                -45.860787001264335,
                -1.188235811134645
              ],
              [
                -45.865318969984315,
                -1.20162621701583
              ],
              [
                -45.875001893703526,
                -1.207171182733819
              ],
              [
                -45.879099858833754,
                -1.213492153792024
              ],
              [
                -45.88100035889427,
                -1.214368647484117
              ],
              [
                -45.897232232313115,
                -1.221850070664333
              ],
              [
                -45.89432123727617,
                -1.226623041331649
              ],
              [
                -45.90432015499107,
                -1.222575095286088
              ],
              [
                -45.89844571192023,
                -1.22812002870261
              ],
              [
                -45.894759239292306,
                -1.23042650979867
              ],
              [
                -45.88505530711575,
                -1.22230357341026
              ],
              [
                -45.866869969843556,
                -1.222224580251674
              ],
              [
                -45.86504399225737,
                -1.221006571139379
              ],
              [
                -45.84711462079833,
                -1.209047669440526
              ],
              [
                -45.848348602404414,
                -1.214270112316123
              ],
              [
                -45.849630081678754,
                -1.219212604310448
              ],
              [
                -45.85034560198658,
                -1.219977078665127
              ],
              [
                -45.8636499951436,
                -1.234192475727337
              ],
              [
                -45.847909635548106,
                -1.222855580633939
              ],
              [
                -45.8447471235914,
                -1.220265075652127
              ],
              [
                -45.845096117585044,
                -1.22217706031471
              ],
              [
                -45.84646862675676,
                -1.229701516896365
              ],
              [
                -45.85235457606678,
                -1.235026992722609
              ],
              [
                -45.879846865993144,
                -1.25990429087273
              ],
              [
                -45.88033786846734,
                -1.264437248873416
              ],
              [
                -45.86733695523243,
                -1.261551274536939
              ],
              [
                -45.86185449815253,
                -1.260333801835734
              ],
              [
                -45.85682555552297,
                -1.252718839322482
              ],
              [
                -45.856621558172016,
                -1.2588497961798
              ],
              [
                -45.86203698814992,
                -1.270547705062989
              ],
              [
                -45.863458977821104,
                -1.278536631225267
              ],
              [
                -45.859776022731566,
                -1.283628603361727
              ],
              [
                -45.86203698814992,
                -1.270547705062989
              ],
              [
                -45.853044063631565,
                -1.261635289672644
              ],
              [
                -45.83677222131894,
                -1.229980005324574
              ],
              [
                -45.83526472202319,
                -1.210198666001704
              ],
              [
                -45.83438324359915,
                -1.198631742962557
              ],
              [
                -45.83102775052753,
                -1.193418273726577
              ],
              [
                -45.828264272704,
                -1.195983759997011
              ],
              [
                -45.82612728007738,
                -1.197984746610743
              ],
              [
                -45.82515631451686,
                -1.198893759620784
              ],
              [
                -45.8220733041388,
                -1.201779728427912
              ],
              [
                -45.81938584173021,
                -1.204295702079355
              ],
              [
                -45.81730637229093,
                -1.20876716509337
              ],
              [
                -45.81211040941022,
                -1.218287608615884
              ],
              [
                -45.820094841282796,
                -1.190054800448161
              ],
              [
                -45.823153300993575,
                -1.179239876367909
              ],
              [
                -45.81413639118639,
                -1.166235478047063
              ],
              [
                -45.806465953462364,
                -1.163138025287124
              ],
              [
                -45.801114476519004,
                -1.160977017807623
              ],
              [
                -45.796256526699544,
                -1.159015538897981
              ],
              [
                -45.791372079262054,
                -1.162894525951916
              ],
              [
                -45.793864546867844,
                -1.169535953242418
              ],
              [
                -45.794038046448,
                -1.169998451780998
              ],
              [
                -45.79363904958936,
                -1.169801954204572
              ],
              [
                -45.78816456469269,
                -1.167103490756936
              ],
              [
                -45.7900775597784,
                -1.174006409993838
              ],
              [
                -45.7915370686329,
                -1.179273385503998
              ],
              [
                -45.80084947482098,
                -1.187241827812015
              ],
              [
                -45.804070443697775,
                -1.207715180038807
              ],
              [
                -45.802708949462335,
                -1.213515623142401
              ],
              [
                -45.80024249429856,
                -1.216259615103008
              ],
              [
                -45.79785949250686,
                -1.218846601258019
              ],
              [
                -45.795178536991536,
                -1.22175856191941
              ],
              [
                -45.814430362811706,
                -1.254714831727392
              ],
              [
                -45.820396310005115,
                -1.262981255447206
              ],
              [
                -45.82245731948877,
                -1.265837262279816
              ],
              [
                -45.82395928666501,
                -1.266542740207137
              ],
              [
                -45.83160474848196,
                -1.270134706383903
              ],
              [
                -45.83912618973775,
                -1.283542107731075
              ],
              [
                -45.831284216181906,
                -1.275277688646578
              ],
              [
                -45.830663754083055,
                -1.274876682565099
              ],
              [
                -45.80598944701344,
                -1.258925788720804
              ],
              [
                -45.80446693416727,
                -1.250600366154085
              ],
              [
                -45.80138395835494,
                -1.249719361309894
              ],
              [
                -45.79423853779003,
                -1.251798854548505
              ],
              [
                -45.785698615789784,
                -1.24945234589681
              ],
              [
                -45.77960662300909,
                -1.264606742837074
              ],
              [
                -45.79697349857702,
                -1.27688316015103
              ],
              [
                -45.793913534179254,
                -1.27864263542548
              ],
              [
                -45.78193512004266,
                -1.269734706592675
              ],
              [
                -45.77608168293666,
                -1.274832674290591
              ],
              [
                -45.77448168711433,
                -1.270396718438152
              ],
              [
                -45.77776864265225,
                -1.269147701358401
              ],
              [
                -45.77960662300909,
                -1.264606742837074
              ],
              [
                -45.78437459493712,
                -1.244100892993877
              ],
              [
                -45.76869022447278,
                -1.216683599163443
              ],
              [
                -45.766478744500866,
                -1.191055800011559
              ],
              [
                -45.7631487603439,
                -1.192103284623665
              ],
              [
                -45.75932230277029,
                -1.195168279950074
              ],
              [
                -45.75932229683428,
                -1.20157020568318
              ],
              [
                -45.74145797178755,
                -1.17071245279103
              ],
              [
                -45.74283342709992,
                -1.163310989833532
              ],
              [
                -45.74348842648463,
                -1.159784548536355
              ],
              [
                -45.73817096832161,
                -1.157581050403109
              ],
              [
                -45.739764969824854,
                -1.150821104646652
              ],
              [
                -45.73557350347014,
                -1.151452601284539
              ],
              [
                -45.73251601296668,
                -1.147048615091846
              ],
              [
                -45.730098048664296,
                -1.148390109622797
              ],
              [
                -45.72903456088808,
                -1.148980592014616
              ],
              [
                -45.72951556532289,
                -1.147611106420662
              ],
              [
                -45.73085405240075,
                -1.143801651367143
              ],
              [
                -45.72740304485096,
                -1.140243687740954
              ],
              [
                -45.73199653040437,
                -1.137137190840058
              ],
              [
                -45.73603301125722,
                -1.134407718012445
              ],
              [
                -45.713935172919484,
                -1.12677376057268
              ],
              [
                -45.691180339602745,
                -1.127654248755167
              ],
              [
                -45.68702137920975,
                -1.134168727954097
              ],
              [
                -45.67956342299946,
                -1.137809702398484
              ],
              [
                -45.67928793471098,
                -1.148165594116393
              ],
              [
                -45.68249443388396,
                -1.146470113379229
              ],
              [
                -45.68728836884699,
                -1.157633530734964
              ],
              [
                -45.69043034260255,
                -1.155418040916502
              ],
              [
                -45.69106283004436,
                -1.159778530074569
              ],
              [
                -45.69438680230061,
                -1.158123037052346
              ],
              [
                -45.694622318476576,
                -1.158006016969737
              ],
              [
                -45.69473731049277,
                -1.158609541428434
              ],
              [
                -45.696207301910526,
                -1.166335475298541
              ],
              [
                -45.707611232012916,
                -1.178832866601524
              ],
              [
                -45.7091771928043,
                -1.178646868233245
              ],
              [
                -45.71087919553524,
                -1.178445368001759
              ],
              [
                -45.717508623252186,
                -1.188770296052024
              ],
              [
                -45.72015512508557,
                -1.192159784866289
              ],
              [
                -45.72087412747311,
                -1.198990725436187
              ],
              [
                -45.72547356532252,
                -1.200506231684971
              ],
              [
                -45.72515305544916,
                -1.209876662957331
              ],
              [
                -45.72502408490065,
                -1.213639118560941
              ],
              [
                -45.7268040719401,
                -1.214021608943088
              ],
              [
                -45.729522050623366,
                -1.214606106942662
              ],
              [
                -45.72824103118089,
                -1.217630095945679
              ],
              [
                -45.7274320682161,
                -1.219539083428173
              ],
              [
                -45.72563304766081,
                -1.223620059382513
              ],
              [
                -45.72860156206062,
                -1.226382517001431
              ],
              [
                -45.73327949573158,
                -1.231660465918197
              ],
              [
                -45.73803396079089,
                -1.237025428200055
              ],
              [
                -45.74558389083353,
                -1.237004952508024
              ],
              [
                -45.74415591238651,
                -1.24096292545937
              ],
              [
                -45.73803396079089,
                -1.237025428200055
              ],
              [
                -45.73746698858726,
                -1.241204903610196
              ],
              [
                -45.73071950753516,
                -1.232934492923623
              ],
              [
                -45.728822042705346,
                -1.230608481171683
              ],
              [
                -45.72531756704332,
                -1.230849493821839
              ],
              [
                -45.71897764150425,
                -1.231284475578182
              ],
              [
                -45.71476363698055,
                -1.240876412607216
              ],
              [
                -45.71150316032855,
                -1.248297353152331
              ],
              [
                -45.69483028865779,
                -1.267577693194604
              ],
              [
                -45.695103779165024,
                -1.28273509096839
              ],
              [
                -45.70208874007309,
                -1.29866296303794
              ],
              [
                -45.699416261397594,
                -1.308481406626282
              ],
              [
                -45.71852061832647,
                -1.313464348180871
              ],
              [
                -45.723665073919946,
                -1.321042297948487
              ],
              [
                -45.720988101932825,
                -1.326616783534722
              ],
              [
                -45.728211545212126,
                -1.34071416358963
              ],
              [
                -45.7296180013781,
                -1.345889643866752
              ],
              [
                -45.72107307842011,
                -1.350202103984476
              ],
              [
                -45.728211545212126,
                -1.34071416358963
              ],
              [
                -45.717899115746654,
                -1.328585266205712
              ],
              [
                -45.71752412729333,
                -1.318564316608719
              ],
              [
                -45.71294865975489,
                -1.314115845369982
              ],
              [
                -45.7044797356978,
                -1.315555370151833
              ],
              [
                -45.69761625376527,
                -1.31285937334947
              ],
              [
                -45.69224282727476,
                -1.315892867622009
              ],
              [
                -45.689169325018504,
                -1.317628314046465
              ],
              [
                -45.691386324845,
                -1.335233209778961
              ],
              [
                -45.68601136030759,
                -1.338605167492141
              ],
              [
                -45.68274788644977,
                -1.340857156988486
              ],
              [
                -45.67805893657547,
                -1.344091627846087
              ],
              [
                -45.677576911289236,
                -1.344424641257479
              ],
              [
                -45.6779399304282,
                -1.34456562125567
              ],
              [
                -45.69434730513102,
                -1.350946079927518
              ],
              [
                -45.70139623158801,
                -1.353704562332907
              ],
              [
                -45.7090221795937,
                -1.362181501793627
              ],
              [
                -45.716856113729776,
                -1.366118970476682
              ],
              [
                -45.71974560262817,
                -1.37525541221091
              ],
              [
                -45.730162009251345,
                -1.375284912757889
              ],
              [
                -45.738825432426395,
                -1.382166873463921
              ],
              [
                -45.73055802066034,
                -1.378248871526848
              ],
              [
                -45.717844602119854,
                -1.377401888736386
              ],
              [
                -45.71414511868503,
                -1.367473978821279
              ],
              [
                -45.70027975759153,
                -1.358070528274054
              ],
              [
                -45.70056922913387,
                -1.365273953091383
              ],
              [
                -45.70158574400582,
                -1.378337353930869
              ],
              [
                -45.70245122836481,
                -1.389467779312584
              ],
              [
                -45.71665661424805,
                -1.401916206102701
              ],
              [
                -45.718668585227235,
                -1.403679169190456
              ],
              [
                -45.71718409398409,
                -1.402751173913401
              ],
              [
                -45.7034622356658,
                -1.394173765074259
              ],
              [
                -45.699218740131215,
                -1.392388773776417
              ],
              [
                -45.685832859981275,
                -1.396937736237801
              ],
              [
                -45.69675826945679,
                -1.385912834091137
              ],
              [
                -45.69764227715717,
                -1.376352878197465
              ],
              [
                -45.69364679234985,
                -1.370581443966551
              ],
              [
                -45.69315430431793,
                -1.369869449243475
              ],
              [
                -45.692895312345925,
                -1.369846949369084
              ],
              [
                -45.667218987439085,
                -1.367618947810642
              ],
              [
                -45.67649944116701,
                -1.377867862199235
              ],
              [
                -45.66340352403886,
                -1.369023419588019
              ],
              [
                -45.63762873215195,
                -1.371850901931059
              ],
              [
                -45.62871231497622,
                -1.365427977830389
              ],
              [
                -45.61551192735566,
                -1.340563656085721
              ],
              [
                -45.61374594437556,
                -1.333183184457476
              ],
              [
                -45.61452143308106,
                -1.322202272413007
              ],
              [
                -45.62086787474349,
                -1.30768741494069
              ],
              [
                -45.61938739928342,
                -1.301666450129596
              ],
              [
                -45.61358893735752,
                -1.278081607200088
              ],
              [
                -45.611058950598675,
                -1.275229619331355
              ],
              [
                -45.60852948083541,
                -1.272378153461515
              ],
              [
                -45.60643149039211,
                -1.271487152859785
              ],
              [
                -45.59158511456704,
                -1.265184232136338
              ],
              [
                -45.5899941273363,
                -1.2696281743249
              ],
              [
                -45.58773814691745,
                -1.263650223123653
              ],
              [
                -45.58716314749331,
                -1.263165249697344
              ],
              [
                -45.579919191141286,
                -1.257056259722139
              ],
              [
                -45.578501237714306,
                -1.260510728404046
              ],
              [
                -45.57709474496674,
                -1.265458710805118
              ],
              [
                -45.58117218614725,
                -1.271265148580793
              ],
              [
                -45.57733222649376,
                -1.275903133946586
              ],
              [
                -45.5873231384123,
                -1.294216999228121
              ],
              [
                -45.58797562629003,
                -1.294307986645186
              ],
              [
                -45.597011557360645,
                -1.295560488630846
              ],
              [
                -45.59001563042144,
                -1.300005445958312
              ],
              [
                -45.58839012198204,
                -1.307349910256085
              ],
              [
                -45.58057819102695,
                -1.29543046599049
              ],
              [
                -45.56544931195977,
                -1.279650088773543
              ],
              [
                -45.558554866448006,
                -1.276469604717136
              ],
              [
                -45.55529538717524,
                -1.275190111872872
              ],
              [
                -45.55176891772074,
                -1.275679610620523
              ],
              [
                -45.53701103877198,
                -1.266338186913573
              ],
              [
                -45.53489854152843,
                -1.265000715695213
              ],
              [
                -45.530650106682174,
                -1.265866706487003
              ],
              [
                -45.530428589410796,
                -1.2659122191084
              ],
              [
                -45.52662161912139,
                -1.266688208648652
              ],
              [
                -45.53577254506877,
                -1.288290031803764
              ],
              [
                -45.53647052962324,
                -1.288143017025346
              ],
              [
                -45.5398490292657,
                -1.287431527937245
              ],
              [
                -45.54609144945156,
                -1.286866057198745
              ],
              [
                -45.54096700663068,
                -1.293022979864514
              ],
              [
                -45.53856403007154,
                -1.295470459989042
              ],
              [
                -45.54262749400528,
                -1.303274926674649
              ],
              [
                -45.55115542553885,
                -1.307454884470234
              ],
              [
                -45.55149842855289,
                -1.303036923895074
              ],
              [
                -45.55330141004464,
                -1.292803496485146
              ],
              [
                -45.554673399889424,
                -1.31432182217611
              ],
              [
                -45.55429390828959,
                -1.317899327098755
              ],
              [
                -45.562768317248796,
                -1.328273211152583
              ],
              [
                -45.55760987654829,
                -1.328899223582959
              ],
              [
                -45.559972367100634,
                -1.340883130504795
              ],
              [
                -45.551042922419036,
                -1.350799576174734
              ],
              [
                -45.55411991439979,
                -1.338053138902654
              ],
              [
                -45.55174442678177,
                -1.333277699564927
              ],
              [
                -45.548072463843376,
                -1.331434710878086
              ],
              [
                -45.54082348750211,
                -1.338394166138237
              ],
              [
                -45.53703703620586,
                -1.338157148304501
              ],
              [
                -45.52313965272487,
                -1.32191328372147
              ],
              [
                -45.52068765415167,
                -1.308066377948399
              ],
              [
                -45.51923168698754,
                -1.305383400989346
              ],
              [
                -45.51476721227646,
                -1.297155471460418
              ],
              [
                -45.50487228657157,
                -1.292456007410396
              ],
              [
                -45.50205429487106,
                -1.291117487082809
              ],
              [
                -45.5006218227053,
                -1.29363447982477
              ],
              [
                -45.508620237371225,
                -1.304018388603339
              ],
              [
                -45.509074757715155,
                -1.304608402216572
              ],
              [
                -45.509384751530845,
                -1.309362869331275
              ],
              [
                -45.5095812595108,
                -1.312377351580349
              ],
              [
                -45.50547728146876,
                -1.319951282677134
              ],
              [
                -45.51114522084519,
                -1.333587168567764
              ],
              [
                -45.513156213735236,
                -1.335735166670306
              ],
              [
                -45.51531471844342,
                -1.338041173605002
              ],
              [
                -45.51409821928059,
                -1.342325140044206
              ],
              [
                -45.511425729687566,
                -1.351736029518322
              ],
              [
                -45.514489213370574,
                -1.353599543953463
              ],
              [
                -45.52192565434518,
                -1.358122984034142
              ],
              [
                -45.52192513900043,
                -1.36627544247912
              ],
              [
                -45.515843680706055,
                -1.359964980108274
              ],
              [
                -45.514084208442874,
                -1.360286969382459
              ],
              [
                -45.511252245553855,
                -1.360804460000647
              ],
              [
                -45.5073642522328,
                -1.377622843346275
              ],
              [
                -45.5126042279642,
                -1.384756303379821
              ],
              [
                -45.520550645857995,
                -1.395574696080999
              ],
              [
                -45.52675560679562,
                -1.404022666400304
              ],
              [
                -45.518506662118256,
                -1.411756600616991
              ],
              [
                -45.515137704498564,
                -1.406052153078464
              ],
              [
                -45.50450476778778,
                -1.394614723573976
              ],
              [
                -45.49657384270647,
                -1.390209235476592
              ],
              [
                -45.48864590077831,
                -1.366819424779178
              ],
              [
                -45.487332404719346,
                -1.362943473839076
              ],
              [
                -45.48496992761498,
                -1.360697491411881
              ],
              [
                -45.48554142598403,
                -1.349447053512873
              ],
              [
                -45.47631851834153,
                -1.336966160316337
              ],
              [
                -45.47209052956354,
                -1.3365706676114
              ],
              [
                -45.47009853355831,
                -1.340438642044385
              ],
              [
                -45.470037563017165,
                -1.340557142138543
              ],
              [
                -45.46835405668638,
                -1.343840617040522
              ],
              [
                -45.47000303075923,
                -1.349923566334422
              ],
              [
                -45.47611400722639,
                -1.353621519401701
              ],
              [
                -45.48063547900055,
                -1.358290979870223
              ],
              [
                -45.481264974119476,
                -1.364010435761442
              ],
              [
                -45.482838437697154,
                -1.370541888681975
              ],
              [
                -45.49052138096503,
                -1.379467851559164
              ],
              [
                -45.48013947644981,
                -1.373015376541277
              ],
              [
                -45.47683301103532,
                -1.363039958351329
              ],
              [
                -45.46589207504852,
                -1.349643051772936
              ],
              [
                -45.46785308178451,
                -1.336652143299578
              ],
              [
                -45.469516054520675,
                -1.325636733853325
              ],
              [
                -45.465205092329974,
                -1.319421279151552
              ],
              [
                -45.457184168540344,
                -1.31758180976389
              ],
              [
                -45.45358869487758,
                -1.316756803422216
              ],
              [
                -45.44694974360187,
                -1.31901176729055
              ],
              [
                -45.44435277209964,
                -1.311977354086662
              ],
              [
                -45.43772482350004,
                -1.309494339806667
              ],
              [
                -45.43846179895183,
                -1.308015849112443
              ],
              [
                -45.44007128583416,
                -1.304787389799859
              ],
              [
                -45.44341825440767,
                -1.305830368444523
              ],
              [
                -45.44749225412407,
                -1.301525921665891
              ],
              [
                -45.44213578512079,
                -1.295364466435795
              ],
              [
                -45.41530050550807,
                -1.290342015194401
              ],
              [
                -45.409745543276166,
                -1.289302501186188
              ],
              [
                -45.40174759922397,
                -1.307364369150005
              ],
              [
                -45.40479655789015,
                -1.32863019666146
              ],
              [
                -45.40757956585163,
                -1.335999660344241
              ],
              [
                -45.41036252380791,
                -1.343368087045959
              ],
              [
                -45.41762696844899,
                -1.351537516442562
              ],
              [
                -45.42184342732322,
                -1.351721037753926
              ],
              [
                -45.42373541505707,
                -1.348154562044355
              ],
              [
                -45.4242868928986,
                -1.347114583002112
              ],
              [
                -45.427413896317255,
                -1.341219620109338
              ],
              [
                -45.42759490057632,
                -1.349315538902016
              ],
              [
                -45.42765339569063,
                -1.351921040205356
              ],
              [
                -45.43236336263436,
                -1.352487521703099
              ],
              [
                -45.432694354030346,
                -1.352527531455453
              ],
              [
                -45.43266835620763,
                -1.352690518218547
              ],
              [
                -45.4318603377032,
                -1.357835979172778
              ],
              [
                -45.44380923671894,
                -1.365233944235353
              ],
              [
                -45.444163245512215,
                -1.361781477394965
              ],
              [
                -45.444427235864985,
                -1.359210992872459
              ],
              [
                -45.44522524987972,
                -1.351447542702555
              ],
              [
                -45.44820423470363,
                -1.362405445353864
              ],
              [
                -45.45345416888998,
                -1.360139464360944
              ],
              [
                -45.467236580626384,
                -1.373112891627477
              ],
              [
                -45.468350575344836,
                -1.38234980302751
              ],
              [
                -45.46946406405704,
                -1.391586731439357
              ],
              [
                -45.470850557509316,
                -1.395568230603124
              ],
              [
                -45.477088005537574,
                -1.413482562396825
              ],
              [
                -45.47488001427076,
                -1.416877533380053
              ],
              [
                -45.473224520816316,
                -1.419423034872708
              ],
              [
                -45.47609398381428,
                -1.433850402462016
              ],
              [
                -45.476301477158934,
                -1.43401193327932
              ],
              [
                -45.479117955688096,
                -1.436210891178782
              ],
              [
                -45.48334543894427,
                -1.433869442596102
              ],
              [
                -45.4880709123453,
                -1.431250939190249
              ],
              [
                -45.481353955846565,
                -1.437969381301933
              ],
              [
                -45.47684549141455,
                -1.442479339467114
              ],
              [
                -45.475078505962664,
                -1.444247348810413
              ],
              [
                -45.47289203272737,
                -1.446433805920658
              ],
              [
                -45.47312600117519,
                -1.456184242380175
              ],
              [
                -45.47356200662286,
                -1.456935253791104
              ],
              [
                -45.4842359126434,
                -1.475320108015658
              ],
              [
                -45.47404451432092,
                -1.476697599776565
              ],
              [
                -45.471063507495565,
                -1.477845605575235
              ],
              [
                -45.46982405013158,
                -1.478322568751976
              ],
              [
                -45.46923802816137,
                -1.48147855283735
              ],
              [
                -45.46738406790155,
                -1.491467499150064
              ],
              [
                -45.47321851668369,
                -1.499488441728496
              ],
              [
                -45.46439907198044,
                -1.49331995762993
              ],
              [
                -45.45615163507543,
                -1.496648465007509
              ],
              [
                -45.45309617982265,
                -1.497881914140813
              ],
              [
                -45.44986418465646,
                -1.517152283187448
              ],
              [
                -45.45700512627628,
                -1.529894695434527
              ],
              [
                -45.47429600889735,
                -1.523127260697484
              ],
              [
                -45.480768928227526,
                -1.533396178512105
              ],
              [
                -45.487251877023965,
                -1.529979203483565
              ],
              [
                -45.48068595627643,
                -1.53662615415449
              ],
              [
                -45.480768928227526,
                -1.533396178512105
              ],
              [
                -45.472211498678725,
                -1.526196741168183
              ],
              [
                -45.44318123234695,
                -1.542735109010948
              ],
              [
                -45.4451732026291,
                -1.537849116228885
              ],
              [
                -45.45414363916135,
                -1.532933172986877
              ],
              [
                -45.446490220887355,
                -1.51846727364057
              ],
              [
                -45.44578619866746,
                -1.5006839026563
              ],
              [
                -45.460152131340806,
                -1.48198655935086
              ],
              [
                -45.45987110357369,
                -1.47625610153636
              ],
              [
                -45.4494477029475,
                -1.461669726736598
              ],
              [
                -45.448817203949794,
                -1.455704263654948
              ],
              [
                -45.4480622131334,
                -1.448550323528818
              ],
              [
                -45.44429472057413,
                -1.449233301701831
              ],
              [
                -45.44305424526807,
                -1.449458289782343
              ],
              [
                -45.428603358975565,
                -1.424756970794112
              ],
              [
                -45.41912144918956,
                -1.420167000819266
              ],
              [
                -45.41721995962383,
                -1.417210533829965
              ],
              [
                -45.415973465741345,
                -1.415271569266813
              ],
              [
                -45.41556597879551,
                -1.416114037829017
              ],
              [
                -45.41168449988529,
                -1.424133496546826
              ],
              [
                -45.41584247800726,
                -1.437036402780676
              ],
              [
                -45.41593897204231,
                -1.449654303476399
              ],
              [
                -45.41596447425334,
                -1.453001258199525
              ],
              [
                -45.42811834575466,
                -1.464875196704632
              ],
              [
                -45.4188974270174,
                -1.471129648817003
              ],
              [
                -45.410508985480384,
                -1.484461042633411
              ],
              [
                -45.40480755280115,
                -1.486913987072344
              ],
              [
                -45.4188974270174,
                -1.471129648817003
              ],
              [
                -45.42287340884494,
                -1.465305163514685
              ],
              [
                -45.41243751170285,
                -1.454350738376919
              ],
              [
                -45.412637496320514,
                -1.441886850568874
              ],
              [
                -45.4084210098408,
                -1.433746922327919
              ],
              [
                -45.39811158273602,
                -1.445491311761145
              ],
              [
                -45.398890592009955,
                -1.448965295649554
              ],
              [
                -45.40351156068777,
                -1.449190291771193
              ],
              [
                -45.40646301945541,
                -1.449529792731179
              ],
              [
                -45.405720518845406,
                -1.457701245867334
              ],
              [
                -45.403169544627026,
                -1.459099224850259
              ],
              [
                -45.404753536436786,
                -1.452033263513371
              ],
              [
                -45.40351156068777,
                -1.449190291771193
              ],
              [
                -45.39773962344192,
                -1.452410267415151
              ],
              [
                -45.394457145134844,
                -1.447186319304316
              ],
              [
                -45.40408157088243,
                -1.433905919362885
              ],
              [
                -45.4018780862826,
                -1.421770998689777
              ],
              [
                -45.396038128009785,
                -1.416698554016547
              ],
              [
                -45.39351712618144,
                -1.414508049139236
              ],
              [
                -45.387939700917315,
                -1.420698527366681
              ],
              [
                -45.38396471737444,
                -1.425110460316854
              ],
              [
                -45.38174924556736,
                -1.42051000871328
              ],
              [
                -45.3864987010557,
                -1.415916020275356
              ],
              [
                -45.39062266730872,
                -1.412184587204427
              ],
              [
                -45.37644327800196,
                -1.398486158356775
              ],
              [
                -45.376756290106606,
                -1.395157191592214
              ],
              [
                -45.36477187776622,
                -1.392082718804101
              ],
              [
                -45.36316289443566,
                -1.385963771831386
              ],
              [
                -45.36381787662166,
                -1.383986294906977
              ],
              [
                -45.36473987584456,
                -1.381201815134996
              ],
              [
                -45.36263639719547,
                -1.382243288897035
              ],
              [
                -45.35220195931108,
                -1.387410733017477
              ],
              [
                -45.349601980119786,
                -1.391103713624487
              ],
              [
                -45.35529545814917,
                -1.392493233080089
              ],
              [
                -45.34708252064524,
                -1.392720719956383
              ],
              [
                -45.35062496760402,
                -1.382032293458671
              ],
              [
                -45.36001689842453,
                -1.378357826859577
              ],
              [
                -45.36465638127854,
                -1.376541823387343
              ],
              [
                -45.37050383293575,
                -1.378984306891312
              ],
              [
                -45.367258836241824,
                -1.386061772785947
              ],
              [
                -45.36610985484696,
                -1.388567736628501
              ],
              [
                -45.372573813623916,
                -1.391216734687583
              ],
              [
                -45.372640300423186,
                -1.389307226145665
              ],
              [
                -45.37307981809013,
                -1.376653324965488
              ],
              [
                -45.370043846421645,
                -1.373749375429126
              ],
              [
                -45.372750816309534,
                -1.372838365779264
              ],
              [
                -45.37122880996953,
                -1.362546938412339
              ],
              [
                -45.36071890837492,
                -1.357431976379265
              ],
              [
                -45.356214432076996,
                -1.349424523260425
              ],
              [
                -45.34909100832234,
                -1.341601102333094
              ],
              [
                -45.346082515655525,
                -1.343492078515655
              ],
              [
                -45.34245604663713,
                -1.346664047892626
              ],
              [
                -45.338847567480386,
                -1.349820528388328
              ],
              [
                -45.34234356398629,
                -1.349941518050296
              ],
              [
                -45.34796801075621,
                -1.35013602250795
              ],
              [
                -45.35416296802259,
                -1.356584991701266
              ],
              [
                -45.34229505013844,
                -1.351343018427549
              ],
              [
                -45.338847567480386,
                -1.349820528388328
              ],
              [
                -45.33418760793809,
                -1.356941973676045
              ],
              [
                -45.3307236259912,
                -1.364067913127209
              ],
              [
                -45.32921917627094,
                -1.36131143275357
              ],
              [
                -45.33418760793809,
                -1.356941973676045
              ],
              [
                -45.328000671344505,
                -1.352200012568257
              ],
              [
                -45.334121104000516,
                -1.353651019591666
              ],
              [
                -45.331863124603636,
                -1.348415039879922
              ],
              [
                -45.34020857793127,
                -1.34278110193399
              ],
              [
                -45.33953106791879,
                -1.338694632778512
              ],
              [
                -45.34282254949025,
                -1.335987157828334
              ],
              [
                -45.35922594277232,
                -1.322493764715376
              ],
              [
                -45.35505897183966,
                -1.314168299112197
              ],
              [
                -45.342924539282514,
                -1.307268348446482
              ],
              [
                -45.337463607847354,
                -1.309485832764983
              ],
              [
                -45.32989614783651,
                -1.312558304262096
              ],
              [
                -45.32218820992679,
                -1.315688293296057
              ],
              [
                -45.31709377164589,
                -1.317757267790663
              ],
              [
                -45.309623826079566,
                -1.332079680011283
              ],
              [
                -45.311700816368685,
                -1.334440645455204
              ],
              [
                -45.31125280659688,
                -1.336262151576218
              ],
              [
                -45.31095778972242,
                -1.337461615436954
              ],
              [
                -45.309608308443075,
                -1.34294958562266
              ],
              [
                -45.30785782067888,
                -1.340038596397279
              ],
              [
                -45.30681633414844,
                -1.338306617353735
              ],
              [
                -45.30024087887877,
                -1.34431305874716
              ],
              [
                -45.30511535158759,
                -1.356322487537371
              ],
              [
                -45.30667884866107,
                -1.360173968613157
              ],
              [
                -45.30340084839265,
                -1.366504909096498
              ],
              [
                -45.30282985270047,
                -1.367606887651471
              ],
              [
                -45.303449839899855,
                -1.373190364452785
              ],
              [
                -45.30392133548708,
                -1.377430306411296
              ],
              [
                -45.3048483539888,
                -1.384667783743664
              ],
              [
                -45.30404635892799,
                -1.386858758011953
              ],
              [
                -45.30053535792343,
                -1.396446673793245
              ],
              [
                -45.30102886424874,
                -1.371313873262826
              ],
              [
                -45.29708740586999,
                -1.36753389724277
              ],
              [
                -45.294442446762595,
                -1.373015370264036
              ],
              [
                -45.29258244423816,
                -1.421274492401933
              ],
              [
                -45.29487041647303,
                -1.425752442895302
              ],
              [
                -45.30016637843659,
                -1.422194988752695
              ],
              [
                -45.29498290958986,
                -1.41409504318345
              ],
              [
                -45.304114352163566,
                -1.405982586191249
              ],
              [
                -45.304923846628775,
                -1.406978582788784
              ],
              [
                -45.310478810784815,
                -1.413809540028517
              ],
              [
                -45.315468248962326,
                -1.411431582894204
              ],
              [
                -45.31336328656972,
                -1.416939541833494
              ],
              [
                -45.310478810784815,
                -1.413809540028517
              ],
              [
                -45.30506033002233,
                -1.410108577112203
              ],
              [
                -45.30345336884576,
                -1.409010568148811
              ],
              [
                -45.30294586828634,
                -1.412235577641646
              ],
              [
                -45.30016637843659,
                -1.422194988752695
              ],
              [
                -45.30411735589705,
                -1.430716925929437
              ],
              [
                -45.31032778157576,
                -1.43353341070072
              ],
              [
                -45.312973266631985,
                -1.440111833381053
              ],
              [
                -45.35408492826739,
                -1.452837759406727
              ],
              [
                -45.370837300815246,
                -1.463243689739291
              ],
              [
                -45.37829077144617,
                -1.467873129094083
              ],
              [
                -45.37989122044684,
                -1.47588609983867
              ],
              [
                -45.387850691945616,
                -1.481401056619906
              ],
              [
                -45.38727019067601,
                -1.491555458816643
              ],
              [
                -45.38704417373481,
                -1.495513950879201
              ],
              [
                -45.37918674801315,
                -1.514356312138744
              ],
              [
                -45.384163195333635,
                -1.484588025875665
              ],
              [
                -45.37565277859812,
                -1.482252040974186
              ],
              [
                -45.3724287915261,
                -1.478889063797534
              ],
              [
                -45.364202365684015,
                -1.470307619158345
              ],
              [
                -45.34921100098526,
                -1.46743714215624
              ],
              [
                -45.345293018399715,
                -1.469544140521931
              ],
              [
                -45.34587399306921,
                -1.479432567874367
              ],
              [
                -45.346107988515655,
                -1.483418011791757
              ],
              [
                -45.3465849959322,
                -1.488060003793261
              ],
              [
                -45.358090907948856,
                -1.496758425166272
              ],
              [
                -45.35728989409115,
                -1.503098874115112
              ],
              [
                -45.356586929599004,
                -1.508668839905886
              ],
              [
                -45.36610585246865,
                -1.521433756258875
              ],
              [
                -45.36512134904271,
                -1.527719689606287
              ],
              [
                -45.36814881591992,
                -1.53493614668561
              ],
              [
                -45.37199228812321,
                -1.543535066494662
              ],
              [
                -45.37569077363189,
                -1.545877059517249
              ],
              [
                -45.37437576937824,
                -1.548989541775139
              ],
              [
                -45.36918732944488,
                -1.547347551184113
              ],
              [
                -45.3624503801728,
                -1.532332655271921
              ],
              [
                -45.362176360520436,
                -1.520458223893398
              ],
              [
                -45.35352094118461,
                -1.511434817442828
              ],
              [
                -45.35273793168031,
                -1.498575905450916
              ],
              [
                -45.34190403619955,
                -1.49094146716036
              ],
              [
                -45.338621051043766,
                -1.479581029436895
              ],
              [
                -45.33719205615901,
                -1.474636090563205
              ],
              [
                -45.330567117586554,
                -1.478244553081613
              ],
              [
                -45.327440131861835,
                -1.479948047634559
              ],
              [
                -45.326822676395125,
                -1.481736519999622
              ],
              [
                -45.32524615696782,
                -1.486305006170955
              ],
              [
                -45.32755364380245,
                -1.503037386101742
              ],
              [
                -45.33524609621712,
                -1.503692848564765
              ],
              [
                -45.326354146699515,
                -1.505960353787278
              ],
              [
                -45.3270446603006,
                -1.516736790468987
              ],
              [
                -45.32376118625671,
                -1.510490339068917
              ],
              [
                -45.326354146699515,
                -1.505960353787278
              ],
              [
                -45.32314368705778,
                -1.48317701943944
              ],
              [
                -45.32176568864,
                -1.473397610141898
              ],
              [
                -45.31203676903793,
                -1.479510550150695
              ],
              [
                -45.30949829830388,
                -1.483382023409794
              ],
              [
                -45.30293682769789,
                -1.490064951671646
              ],
              [
                -45.30030036806031,
                -1.492120971640258
              ],
              [
                -45.2976638914233,
                -1.494176415612402
              ],
              [
                -45.30999379845038,
                -1.51621928821279
              ],
              [
                -45.31000827582391,
                -1.529857664129296
              ],
              [
                -45.31795771044224,
                -1.539970608203147
              ],
              [
                -45.31663474142536,
                -1.547565524570892
              ],
              [
                -45.315214230062715,
                -1.555723482670644
              ],
              [
                -45.323358677202,
                -1.560116949038994
              ],
              [
                -45.32641214788611,
                -1.561764445180236
              ],
              [
                -45.338940557103186,
                -1.584366283947548
              ],
              [
                -45.33785155277566,
                -1.588984728875998
              ],
              [
                -45.33769804885693,
                -1.589636203928932
              ],
              [
                -45.337031061555116,
                -1.592462717465535
              ],
              [
                -45.33938205009798,
                -1.597531148673471
              ],
              [
                -45.34112851864219,
                -1.601297145596498
              ],
              [
                -45.34029301436022,
                -1.605670103434893
              ],
              [
                -45.338889024963294,
                -1.613021564689298
              ],
              [
                -45.33823102739734,
                -1.616796034081572
              ],
              [
                -45.34065900979282,
                -1.624548480079262
              ],
              [
                -45.342644497532454,
                -1.622793974709335
              ],
              [
                -45.34290149855715,
                -1.622566975472747
              ],
              [
                -45.35229594616576,
                -1.615226528707494
              ],
              [
                -45.34356200377206,
                -1.62558795281653
              ],
              [
                -45.3432420222122,
                -1.625967435890333
              ],
              [
                -45.34063403015127,
                -1.628803933974608
              ],
              [
                -45.354722904826055,
                -1.666903649549988
              ],
              [
                -45.35853787176181,
                -1.67227660983788
              ],
              [
                -45.35893937057897,
                -1.672414590880404
              ],
              [
                -45.36459431475257,
                -1.674355600414407
              ],
              [
                -45.372971748829286,
                -1.674876599209254
              ],
              [
                -45.374813764898555,
                -1.677142560517056
              ],
              [
                -45.36459431475257,
                -1.674355600414407
              ],
              [
                -45.37154427894222,
                -1.685203491119172
              ],
              [
                -45.375300248361604,
                -1.690454977340709
              ],
              [
                -45.37158476444839,
                -1.700690401480522
              ],
              [
                -45.37154427894222,
                -1.685203491119172
              ],
              [
                -45.36464431116312,
                -1.680246548038846
              ],
              [
                -45.36137185596189,
                -1.679822560559288
              ],
              [
                -45.35740239425738,
                -1.679308070618561
              ],
              [
                -45.36195733189545,
                -1.69677541196257
              ],
              [
                -45.362641333981664,
                -1.699397885351088
              ],
              [
                -45.365594811542515,
                -1.710213328511288
              ],
              [
                -45.3593998454161,
                -1.728478665665057
              ],
              [
                -45.352397411808795,
                -1.736271627501014
              ],
              [
                -45.34768395012351,
                -1.734980612219358
              ],
              [
                -45.33840601758076,
                -1.732438660391162
              ],
              [
                -45.338870027840784,
                -1.728106691117177
              ],
              [
                -45.339266028502365,
                -1.724401215111447
              ],
              [
                -45.34019302802041,
                -1.715734783589693
              ],
              [
                -45.340670021195024,
                -1.7112807962439
              ],
              [
                -45.335208072575604,
                -1.684776993827508
              ],
              [
                -45.332513586579736,
                -1.680735036739074
              ],
              [
                -45.329821094566896,
                -1.676693568651951
              ],
              [
                -45.32014817240039,
                -1.642903812507047
              ],
              [
                -45.31379124817617,
                -1.608364072885248
              ],
              [
                -45.30902727020776,
                -1.600268122067654
              ],
              [
                -45.29135743268244,
                -1.59245370162436
              ],
              [
                -45.27471455309592,
                -1.610585568410289
              ],
              [
                -45.27514153453818,
                -1.611604046801958
              ],
              [
                -45.27814701649075,
                -1.618782487182321
              ],
              [
                -45.27107908272427,
                -1.615587529912668
              ],
              [
                -45.26303713488649,
                -1.626395416687159
              ],
              [
                -45.25394819585478,
                -1.624152946817969
              ],
              [
                -45.247572756388536,
                -1.622344948210583
              ],
              [
                -45.249477235951844,
                -1.619519477934976
              ],
              [
                -45.25233470429011,
                -1.615280489526874
              ],
              [
                -45.25244672950374,
                -1.609659054022196
              ],
              [
                -45.25256873972281,
                -1.603584109949835
              ],
              [
                -45.25274073703441,
                -1.594966682105689
              ],
              [
                -45.25234772045332,
                -1.593275672809002
              ],
              [
                -45.25048773462868,
                -1.585275231917137
              ],
              [
                -45.24822725261214,
                -1.584273229053047
              ],
              [
                -45.24664474820213,
                -1.583572265045294
              ],
              [
                -45.24703276254172,
                -1.581513253685772
              ],
              [
                -45.248121252074526,
                -1.575744817505113
              ],
              [
                -45.23778485539202,
                -1.560143906492442
              ],
              [
                -45.23486285722468,
                -1.555732943298679
              ],
              [
                -45.2343258741245,
                -1.552149987299221
              ],
              [
                -45.23378837902695,
                -1.548566505305544
              ],
              [
                -45.20684359971779,
                -1.529597165789746
              ],
              [
                -45.19634516833241,
                -1.513667252457867
              ],
              [
                -45.19404121613185,
                -1.515575765577245
              ],
              [
                -45.18764276707815,
                -1.512609290861841
              ],
              [
                -45.187180739505294,
                -1.513774270952136
              ],
              [
                -45.185519254827476,
                -1.517968244877275
              ],
              [
                -45.183579268214466,
                -1.522857198487127
              ],
              [
                -45.180143817173594,
                -1.523443680405657
              ],
              [
                -45.17904683636368,
                -1.520464707766262
              ],
              [
                -45.17496436655768,
                -1.509373315035928
              ],
              [
                -45.17021190821206,
                -1.508240820740844
              ],
              [
                -45.173022868984276,
                -1.49763890359515
              ],
              [
                -45.17111339357411,
                -1.494626927072162
              ],
              [
                -45.168096393205154,
                -1.489866949598812
              ],
              [
                -45.16670990621882,
                -1.489378966045338
              ],
              [
                -45.16124547362806,
                -1.487454483640061
              ],
              [
                -45.16139845789467,
                -1.48481948564335
              ],
              [
                -45.16174345324032,
                -1.478863026863391
              ],
              [
                -45.15699849907013,
                -1.470803096121952
              ],
              [
                -45.15134003610895,
                -1.468322607917521
              ],
              [
                -45.15109804661376,
                -1.464944640493528
              ],
              [
                -45.14726407168238,
                -1.465807637262037
              ],
              [
                -45.143169602512536,
                -1.462671154321128
              ],
              [
                -45.14192659349202,
                -1.472953062108273
              ],
              [
                -45.14169660496701,
                -1.474855540637362
              ],
              [
                -45.13573366159601,
                -1.482605516781856
              ],
              [
                -45.1374771344125,
                -1.485053467532103
              ],
              [
                -45.14252260355585,
                -1.491073419797966
              ],
              [
                -45.13819464863582,
                -1.491295449326518
              ],
              [
                -45.1355446376069,
                -1.501947362072612
              ],
              [
                -45.13135518612325,
                -1.504791341739855
              ],
              [
                -45.12741820567155,
                -1.507516798350364
              ],
              [
                -45.141944623907705,
                -1.519749233299843
              ],
              [
                -45.145280084098104,
                -1.52255768063379
              ],
              [
                -45.13852264061619,
                -1.521219686519278
              ],
              [
                -45.1262417442275,
                -1.511478790092057
              ],
              [
                -45.12860270337281,
                -1.529148643572147
              ],
              [
                -45.122229256391584,
                -1.514095249512307
              ],
              [
                -45.12624773896361,
                -1.496121912546659
              ],
              [
                -45.124890753425085,
                -1.475116039635698
              ],
              [
                -45.1285637396319,
                -1.462865132241576
              ],
              [
                -45.129698717349456,
                -1.459078673174012
              ],
              [
                -45.12154328080862,
                -1.452517205517354
              ],
              [
                -45.12150327512987,
                -1.452484231760495
              ],
              [
                -45.121497299183105,
                -1.452449231025057
              ],
              [
                -45.11928030694997,
                -1.439341305127358
              ],
              [
                -45.11265736783803,
                -1.435548372760971
              ],
              [
                -45.114130823691326,
                -1.432805373845026
              ],
              [
                -45.10526291354712,
                -1.415825985246233
              ],
              [
                -45.1014894505406,
                -1.408601572473961
              ],
              [
                -45.10367843335999,
                -1.402187599576305
              ],
              [
                -45.10648041164207,
                -1.393976686436859
              ],
              [
                -45.11057338504049,
                -1.38393574642276
              ],
              [
                -45.11355685427473,
                -1.385350743175381
              ],
              [
                -45.111356867331565,
                -1.381112260009299
              ],
              [
                -45.1057608969572,
                -1.372129848331277
              ],
              [
                -45.10489291625472,
                -1.363320878142403
              ],
              [
                -45.10102495227351,
                -1.360215429106959
              ],
              [
                -45.098583456148226,
                -1.362557416897109
              ],
              [
                -45.092691002556144,
                -1.369094361219818
              ],
              [
                -45.097107999186235,
                -1.384324743193037
              ],
              [
                -45.092983513687436,
                -1.390523711383337
              ],
              [
                -45.097118971081954,
                -1.396373653630884
              ],
              [
                -45.10342240735811,
                -1.425795927204665
              ],
              [
                -45.10353792235427,
                -1.42633540312764
              ],
              [
                -45.094508499259426,
                -1.456988179780832
              ],
              [
                -45.0969064593677,
                -1.468195618127596
              ],
              [
                -45.09834795345335,
                -1.471261571114017
              ],
              [
                -45.100050947608345,
                -1.473578053837884
              ],
              [
                -45.10387593251062,
                -1.478782008032821
              ],
              [
                -45.10275092113565,
                -1.49135392343286
              ],
              [
                -45.09364250102536,
                -1.496711870118733
              ],
              [
                -45.09146749203323,
                -1.503087328345807
              ],
              [
                -45.0980929301995,
                -1.511702780202039
              ],
              [
                -45.09512498233182,
                -1.513249768919393
              ],
              [
                -45.09146749203323,
                -1.503087328345807
              ],
              [
                -45.090161007585436,
                -1.496196870391212
              ],
              [
                -45.09988395602906,
                -1.489806960649414
              ],
              [
                -45.09988192899811,
                -1.478884003538304
              ],
              [
                -45.09328250956447,
                -1.476050035858077
              ],
              [
                -45.078152599103824,
                -1.48076050937354
              ],
              [
                -45.08013008596962,
                -1.483728984196318
              ],
              [
                -45.07792912375665,
                -1.4869764751461
              ],
              [
                -45.068716181392666,
                -1.47615601863191
              ],
              [
                -45.068668690560386,
                -1.466032109502546
              ],
              [
                -45.06858521341957,
                -1.464896607111128
              ],
              [
                -45.068050197929665,
                -1.457576684610395
              ],
              [
                -45.070152678129325,
                -1.453052214349201
              ],
              [
                -45.06708020515581,
                -1.448597720642544
              ],
              [
                -45.06564271442293,
                -1.454767687519393
              ],
              [
                -45.064185240258304,
                -1.475564558308049
              ],
              [
                -45.06383223914866,
                -1.480599502011088
              ],
              [
                -45.06478770972359,
                -1.502231843950202
              ],
              [
                -45.078310120057324,
                -1.518438714444578
              ],
              [
                -45.06523122938187,
                -1.506875800781391
              ],
              [
                -45.06478770972359,
                -1.502231843950202
              ],
              [
                -45.0616212587607,
                -1.490883420525794
              ],
              [
                -45.06383223914866,
                -1.480599502011088
              ],
              [
                -45.06227775149131,
                -1.480189015847616
              ],
              [
                -45.0538087967397,
                -1.477952000309328
              ],
              [
                -45.049831334500105,
                -1.481539475345552
              ],
              [
                -45.04425239064646,
                -1.486572936107537
              ],
              [
                -45.04208088243517,
                -1.48856293359976
              ],
              [
                -45.041230426495545,
                -1.49221241972759
              ],
              [
                -45.03946740305364,
                -1.499780857927841
              ],
              [
                -45.03939692796285,
                -1.492265423991731
              ],
              [
                -45.03938043441172,
                -1.490489907473973
              ],
              [
                -45.01938556146676,
                -1.489628936385305
              ],
              [
                -45.01645409637778,
                -1.485397442005885
              ],
              [
                -45.01442462190173,
                -1.48263099666095
              ],
              [
                -45.004233699629076,
                -1.481370992397874
              ],
              [
                -45.000779716502585,
                -1.483693459122364
              ],
              [
                -44.995602265763694,
                -1.487304923736097
              ],
              [
                -44.99153079767402,
                -1.494335879556616
              ],
              [
                -44.98714982292982,
                -1.50190182325309
              ],
              [
                -44.97079196478791,
                -1.504234822527744
              ],
              [
                -44.964368009820426,
                -1.513866715127755
              ],
              [
                -44.95820704534677,
                -1.514847712535267
              ],
              [
                -44.95983555147639,
                -1.509320251857829
              ],
              [
                -44.96240303875475,
                -1.500606823577214
              ],
              [
                -44.962074036739395,
                -1.498476366716328
              ],
              [
                -44.961194519716436,
                -1.492775887903425
              ],
              [
                -44.95798107627006,
                -1.486350457186881
              ],
              [
                -44.95638208948164,
                -1.483152446221747
              ],
              [
                -44.94541064342659,
                -1.47640602556857
              ],
              [
                -44.942476693119886,
                -1.479259976327762
              ],
              [
                -44.93484774009436,
                -1.486870923045643
              ],
              [
                -44.946378158077756,
                -1.491548379550302
              ],
              [
                -44.94993561191088,
                -1.49176289356397
              ],
              [
                -44.9518066277289,
                -1.499493328111066
              ],
              [
                -44.951731127247264,
                -1.499929835777692
              ],
              [
                -44.9507511269741,
                -1.505589810556861
              ],
              [
                -44.94257719853882,
                -1.516982191424859
              ],
              [
                -44.940709694067266,
                -1.512800732878365
              ],
              [
                -44.95140410882442,
                -1.499976352364328
              ],
              [
                -44.9518066277289,
                -1.499493328111066
              ],
              [
                -44.94801414740525,
                -1.49565536160936
              ],
              [
                -44.946378158077756,
                -1.491548379550302
              ],
              [
                -44.94302916984636,
                -1.49552286371051
              ],
              [
                -44.93603524633852,
                -1.494494877258961
              ],
              [
                -44.91407642460439,
                -1.491266910835362
              ],
              [
                -44.90995742630283,
                -1.490640883853523
              ],
              [
                -44.90434998482057,
                -1.489787916330366
              ],
              [
                -44.912298434517844,
                -1.486523436615051
              ],
              [
                -44.916840901398324,
                -1.487556432574067
              ],
              [
                -44.92069785873459,
                -1.488416432726817
              ],
              [
                -44.92065835530874,
                -1.486950915872246
              ],
              [
                -44.92045485626135,
                -1.479407971241094
              ],
              [
                -44.914377916718415,
                -1.477237522669442
              ],
              [
                -44.91063144015608,
                -1.472725036348727
              ],
              [
                -44.907500463592825,
                -1.468952558510069
              ],
              [
                -44.89740755258501,
                -1.456795145294427
              ],
              [
                -44.89533504842072,
                -1.454298673942381
              ],
              [
                -44.89347257855019,
                -1.452054200709795
              ],
              [
                -44.88594162804536,
                -1.446896712673631
              ],
              [
                -44.8806161908261,
                -1.443249247530059
              ],
              [
                -44.87712522770383,
                -1.447596230794213
              ],
              [
                -44.86899276231476,
                -1.457724127188408
              ],
              [
                -44.8715647418055,
                -1.447096236626929
              ],
              [
                -44.872134268723215,
                -1.44463874045446
              ],
              [
                -44.873466751170334,
                -1.438884798538079
              ],
              [
                -44.86801679873991,
                -1.442199268324235
              ],
              [
                -44.86133134796331,
                -1.446265251162545
              ],
              [
                -44.86634331115959,
                -1.44120726059199
              ],
              [
                -44.87041224765693,
                -1.437101802593734
              ],
              [
                -44.871037242726686,
                -1.431186338790402
              ],
              [
                -44.858606870205726,
                -1.43077284678292
              ],
              [
                -44.864596325262404,
                -1.427995372996764
              ],
              [
                -44.86874377089883,
                -1.425249409648613
              ],
              [
                -44.864091314384495,
                -1.421414907077923
              ],
              [
                -44.854057394791695,
                -1.415741973607123
              ],
              [
                -44.846107940254484,
                -1.418595933474224
              ],
              [
                -44.84097599458235,
                -1.420437929541337
              ],
              [
                -44.84029500261709,
                -1.416663949209842
              ],
              [
                -44.84609697216474,
                -1.413744483474437
              ],
              [
                -44.824872140369905,
                -1.413154983331355
              ],
              [
                -44.83324955946365,
                -1.416392437069944
              ],
              [
                -44.82714212393685,
                -1.415820444384374
              ],
              [
                -44.821565135209035,
                -1.415267958641974
              ],
              [
                -44.815555715355615,
                -1.417988933847522
              ],
              [
                -44.81022922555411,
                -1.429735354300252
              ],
              [
                -44.82266965163093,
                -1.437671282923098
              ],
              [
                -44.82360964954267,
                -1.429763361407039
              ],
              [
                -44.83450805501389,
                -1.448333204686214
              ],
              [
                -44.82788607356037,
                -1.487595406209241
              ],
              [
                -44.83106907178194,
                -1.490763392641477
              ],
              [
                -44.83760003473435,
                -1.486942424938348
              ],
              [
                -44.84318146311947,
                -1.483631456173907
              ],
              [
                -44.84055451417619,
                -1.459520121465627
              ],
              [
                -44.84040450460435,
                -1.458146636909065
              ],
              [
                -44.84626293700279,
                -1.470716041146607
              ],
              [
                -44.84628896894889,
                -1.475517491561139
              ],
              [
                -44.846357943104636,
                -1.488455402991277
              ],
              [
                -44.8504339060724,
                -1.4870814131953
              ],
              [
                -44.85362189101995,
                -1.486006403960417
              ],
              [
                -44.8715442542087,
                -1.479911962611863
              ],
              [
                -44.87925020088776,
                -1.481733465124564
              ],
              [
                -44.87293125366836,
                -1.483068947816024
              ],
              [
                -44.856295386593594,
                -1.493047873803603
              ],
              [
                -44.85366740049416,
                -1.492534873235164
              ],
              [
                -44.85176339663744,
                -1.492162368727898
              ],
              [
                -44.85638985511941,
                -1.497099345960843
              ],
              [
                -44.85700736825467,
                -1.496961857120284
              ],
              [
                -44.86980775942277,
                -1.494114373130984
              ],
              [
                -44.87531470711193,
                -1.498114328672234
              ],
              [
                -44.86333529590045,
                -1.498368812555099
              ],
              [
                -44.86617777053009,
                -1.508722268232394
              ],
              [
                -44.872642255907635,
                -1.516696688707162
              ],
              [
                -44.8862876122819,
                -1.520095174355602
              ],
              [
                -44.87679319386586,
                -1.523189648049165
              ],
              [
                -44.875977194276466,
                -1.523455629873738
              ],
              [
                -44.88285515783499,
                -1.534363048141113
              ],
              [
                -44.87995668981672,
                -1.539570522975193
              ],
              [
                -44.89781353493425,
                -1.547989441276085
              ],
              [
                -44.89801053137662,
                -1.547982965362378
              ],
              [
                -44.906615449341864,
                -1.547698476144251
              ],
              [
                -44.902580472105264,
                -1.559300870151364
              ],
              [
                -44.90163050511043,
                -1.56203284019708
              ],
              [
                -44.903093470893005,
                -1.565451310481988
              ],
              [
                -44.90761546412182,
                -1.576017266016733
              ],
              [
                -44.90447745081021,
                -1.582036219661352
              ],
              [
                -44.901339501494924,
                -1.588055652301781
              ],
              [
                -44.902837484546595,
                -1.604215017779251
              ],
              [
                -44.90017602071014,
                -1.608865515920557
              ],
              [
                -44.89938950266856,
                -1.610239988322299
              ],
              [
                -44.89758353134982,
                -1.613393963002263
              ],
              [
                -44.89347254943689,
                -1.610529496964709
              ],
              [
                -44.88107164089369,
                -1.603747040095322
              ],
              [
                -44.86625176839172,
                -1.602813541307341
              ],
              [
                -44.85864333629316,
                -1.609768992935887
              ],
              [
                -44.855015842376375,
                -1.613084970011998
              ],
              [
                -44.85407284743315,
                -1.615205939699634
              ],
              [
                -44.84983090917258,
                -1.62474487733599
              ],
              [
                -44.84546691588821,
                -1.623949376524354
              ],
              [
                -44.845652942600545,
                -1.622995396814983
              ],
              [
                -44.846260931390276,
                -1.619883397598525
              ],
              [
                -44.844674939423555,
                -1.617447408811391
              ],
              [
                -44.82963755797604,
                -1.594347589599271
              ],
              [
                -44.82772559158539,
                -1.57611325299931
              ],
              [
                -44.82111412617798,
                -1.574970732432373
              ],
              [
                -44.81840316823205,
                -1.577216719816888
              ],
              [
                -44.80506523882199,
                -1.588271131104083
              ],
              [
                -44.80146279380213,
                -1.591256114685515
              ],
              [
                -44.80258226497512,
                -1.601484042044874
              ],
              [
                -44.803199776997445,
                -1.607126016222623
              ],
              [
                -44.79372332364551,
                -1.614676937901448
              ],
              [
                -44.795614335382425,
                -1.610923464839933
              ],
              [
                -44.79914479767502,
                -1.604309023874102
              ],
              [
                -44.799060800921715,
                -1.601282049898684
              ],
              [
                -44.797676303528796,
                -1.597970043842166
              ],
              [
                -44.79274435426129,
                -1.59983404169593
              ],
              [
                -44.79051038328385,
                -1.603402537095591
              ],
              [
                -44.78677638573527,
                -1.609362983993192
              ],
              [
                -44.777363466439205,
                -1.624428358465651
              ],
              [
                -44.78019096591092,
                -1.640355730801619
              ],
              [
                -44.78294292648273,
                -1.643468731660572
              ],
              [
                -44.789255366156375,
                -1.649999166233656
              ],
              [
                -44.79782881038468,
                -1.659171095184896
              ],
              [
                -44.783959904771194,
                -1.65183166023236
              ],
              [
                -44.78126094212743,
                -1.646968209697024
              ],
              [
                -44.77223852184737,
                -1.630711314628738
              ],
              [
                -44.77432949708364,
                -1.621387859014528
              ],
              [
                -44.77834196419959,
                -1.616603402224916
              ],
              [
                -44.78201392793557,
                -1.612226449264507
              ],
              [
                -44.78353643162638,
                -1.608282998583209
              ],
              [
                -44.78855940106344,
                -1.591296115891661
              ],
              [
                -44.78952287204699,
                -1.58803613790056
              ],
              [
                -44.78434490814811,
                -1.582466697318539
              ],
              [
                -44.77957796600462,
                -1.582109680123481
              ],
              [
                -44.77388049625436,
                -1.581683173279844
              ],
              [
                -44.768243562070445,
                -1.575738216489906
              ],
              [
                -44.766629063858346,
                -1.570497762109276
              ],
              [
                -44.76393159224463,
                -1.565181787103956
              ],
              [
                -44.762019088115956,
                -1.561406842509269
              ],
              [
                -44.76184960852217,
                -1.561073334018876
              ],
              [
                -44.76181511879366,
                -1.561083841932274
              ],
              [
                -44.7590181438117,
                -1.561947829818513
              ],
              [
                -44.75922163647171,
                -1.565829316276055
              ],
              [
                -44.75959910612148,
                -1.573055230283423
              ],
              [
                -44.76046410813154,
                -1.589600128393542
              ],
              [
                -44.76778506584968,
                -1.606492983144361
              ],
              [
                -44.769712522710805,
                -1.610939942657072
              ],
              [
                -44.75809264172964,
                -1.61674892315024
              ],
              [
                -44.757571622799674,
                -1.622228369322061
              ],
              [
                -44.75730463727434,
                -1.625535840084628
              ],
              [
                -44.750648689121746,
                -1.620074398365583
              ],
              [
                -44.74950220202925,
                -1.615866918185507
              ],
              [
                -44.75411815483033,
                -1.613742924265228
              ],
              [
                -44.75709515148535,
                -1.612373466276724
              ],
              [
                -44.764521082261446,
                -1.608931965934957
              ],
              [
                -44.76179861652228,
                -1.605434504040548
              ],
              [
                -44.754613140065615,
                -1.597287567701628
              ],
              [
                -44.741579243426216,
                -1.607807969026527
              ],
              [
                -44.749263190957386,
                -1.594870091084384
              ],
              [
                -44.747429707851374,
                -1.587768140842867
              ],
              [
                -44.72352039973269,
                -1.570761743896804
              ],
              [
                -44.71669897042119,
                -1.562417802229713
              ],
              [
                -44.71374898752809,
                -1.558675830211578
              ],
              [
                -44.71095701860578,
                -1.559095357484966
              ],
              [
                -44.708102016182934,
                -1.559523857677702
              ],
              [
                -44.70512355195473,
                -1.564163285726838
              ],
              [
                -44.70136407069579,
                -1.570019268347559
              ],
              [
                -44.701808081747615,
                -1.566947265863585
              ],
              [
                -44.70245608444323,
                -1.562462334196746
              ],
              [
                -44.7000710913125,
                -1.557421375201547
              ],
              [
                -44.6979430930387,
                -1.55292338511763
              ],
              [
                -44.69601914456564,
                -1.556928364199333
              ],
              [
                -44.69899458863566,
                -1.569309749309393
              ],
              [
                -44.701917596145115,
                -1.581475165082511
              ],
              [
                -44.72193391722731,
                -1.602877005780505
              ],
              [
                -44.719366941774155,
                -1.6123534540626
              ],
              [
                -44.71459598515182,
                -1.604453017344405
              ],
              [
                -44.70217307942768,
                -1.590389609154403
              ],
              [
                -44.6977700968363,
                -1.593221061716209
              ],
              [
                -44.694919120792136,
                -1.596758043195898
              ],
              [
                -44.691800157027714,
                -1.599495521719059
              ],
              [
                -44.6919746695124,
                -1.594937079512998
              ],
              [
                -44.6977700968363,
                -1.593221061716209
              ],
              [
                -44.69681912367239,
                -1.586443129219362
              ],
              [
                -44.69193414590106,
                -1.584054163501878
              ],
              [
                -44.69031915710675,
                -1.587297110458585
              ],
              [
                -44.68885269853371,
                -1.583149673810236
              ],
              [
                -44.68804819272451,
                -1.578892190131164
              ],
              [
                -44.6879846932923,
                -1.578554699693399
              ],
              [
                -44.68674520136645,
                -1.571996761484243
              ],
              [
                -44.67772277851334,
                -1.563904309517702
              ],
              [
                -44.67311728969637,
                -1.565962298941368
              ],
              [
                -44.6690278399504,
                -1.572463733552067
              ],
              [
                -44.66520538516141,
                -1.578540184455029
              ],
              [
                -44.666240368991396,
                -1.583576168226133
              ],
              [
                -44.66687736234686,
                -1.586672135726362
              ],
              [
                -44.66100541901962,
                -1.591726599033723
              ],
              [
                -44.65772640352485,
                -1.594549053867182
              ],
              [
                -44.650862465635754,
                -1.612346430754794
              ],
              [
                -44.64660399534693,
                -1.618131871788094
              ],
              [
                -44.64234554105632,
                -1.623917326819179
              ],
              [
                -44.647118006595754,
                -1.64721067010726
              ],
              [
                -44.656898940194154,
                -1.665965527085678
              ],
              [
                -44.662466887543395,
                -1.663090053122593
              ],
              [
                -44.66571534007795,
                -1.661412555560664
              ],
              [
                -44.6686368347044,
                -1.652179115521263
              ],
              [
                -44.66962482047397,
                -1.649058170507055
              ],
              [
                -44.67111128859413,
                -1.644359692617309
              ],
              [
                -44.67680178022797,
                -1.650359659019196
              ],
              [
                -44.67707575799549,
                -1.650649127867732
              ],
              [
                -44.67767227735432,
                -1.6501656426716
              ],
              [
                -44.682964708177245,
                -1.645881185379174
              ],
              [
                -44.68463669436494,
                -1.648614139884929
              ],
              [
                -44.68615819079516,
                -1.651101645232912
              ],
              [
                -44.69400911245011,
                -1.651201622039395
              ],
              [
                -44.69932808821563,
                -1.65126964758383
              ],
              [
                -44.69960209903895,
                -1.646203698234847
              ],
              [
                -44.69993307143909,
                -1.640974213145798
              ],
              [
                -44.70328705965996,
                -1.651940137267067
              ],
              [
                -44.73736980051126,
                -1.655495637012431
              ],
              [
                -44.74067875109442,
                -1.651287635712479
              ],
              [
                -44.74491072740491,
                -1.646828677505939
              ],
              [
                -44.74397274468471,
                -1.65270815655676
              ],
              [
                -44.741916737386084,
                -1.660819086398752
              ],
              [
                -44.753460175595634,
                -1.677001441573358
              ],
              [
                -44.75481712845175,
                -1.678903424377198
              ],
              [
                -44.75750313034354,
                -1.677938447265448
              ],
              [
                -44.761107069022955,
                -1.676642949854352
              ],
              [
                -44.760614607523415,
                -1.67865942641268
              ],
              [
                -44.759991589952335,
                -1.681205404916237
              ],
              [
                -44.76852602270036,
                -1.684063909911107
              ],
              [
                -44.77182599809977,
                -1.681257440931132
              ],
              [
                -44.77690597169158,
                -1.676934971842824
              ],
              [
                -44.779870938506335,
                -1.675333957622692
              ],
              [
                -44.78385492587351,
                -1.67058649354016
              ],
              [
                -44.793272324456545,
                -1.68436139770008
              ],
              [
                -44.779870938506335,
                -1.675333957622692
              ],
              [
                -44.77902244770729,
                -1.682924925715455
              ],
              [
                -44.77873443947027,
                -1.685505882027992
              ],
              [
                -44.772563986696746,
                -1.68895037557011
              ],
              [
                -44.77339149946129,
                -1.69228233439947
              ],
              [
                -44.78152791848332,
                -1.694602805423711
              ],
              [
                -44.782052933290764,
                -1.694752336394588
              ],
              [
                -44.78782438624769,
                -1.701101295311757
              ],
              [
                -44.7908803303382,
                -1.704463247385519
              ],
              [
                -44.798735305475944,
                -1.707410226610592
              ],
              [
                -44.80437223943377,
                -1.704312763317237
              ],
              [
                -44.80117627975902,
                -1.709222212345194
              ],
              [
                -44.788620856107286,
                -1.710046719476338
              ],
              [
                -44.78422390285306,
                -1.710583687483068
              ],
              [
                -44.78396790276523,
                -1.711156203078013
              ],
              [
                -44.78286942796901,
                -1.713617186142872
              ],
              [
                -44.78150941112585,
                -1.716661165721902
              ],
              [
                -44.786612390763956,
                -1.721072127258774
              ],
              [
                -44.78004394496221,
                -1.720461129532703
              ],
              [
                -44.77290246732327,
                -1.72656906861418
              ],
              [
                -44.75609511727955,
                -1.728142566134821
              ],
              [
                -44.75475714446013,
                -1.735049022346323
              ],
              [
                -44.76168208134235,
                -1.740808469022917
              ],
              [
                -44.76731653670061,
                -1.745411938225017
              ],
              [
                -44.78402240424535,
                -1.745410436770038
              ],
              [
                -44.78903586073211,
                -1.744307945206519
              ],
              [
                -44.78743986221413,
                -1.749899926386254
              ],
              [
                -44.78402240424535,
                -1.745410436770038
              ],
              [
                -44.76568452692598,
                -1.748836929850843
              ],
              [
                -44.755475127009866,
                -1.743485948361005
              ],
              [
                -44.751609669080594,
                -1.731882552759406
              ],
              [
                -44.75609511727955,
                -1.728142566134821
              ],
              [
                -44.77003399693501,
                -1.722367114956217
              ],
              [
                -44.771602993064846,
                -1.719596642344718
              ],
              [
                -44.77361696482764,
                -1.716039177808413
              ],
              [
                -44.77341099062876,
                -1.715221178984291
              ],
              [
                -44.772277994536566,
                -1.710712695025719
              ],
              [
                -44.7557421086637,
                -1.710720188541398
              ],
              [
                -44.74945768275024,
                -1.719284646103872
              ],
              [
                -44.74867718872201,
                -1.720348628849873
              ],
              [
                -44.73235329316126,
                -1.725469582496585
              ],
              [
                -44.72021790260252,
                -1.724730592583597
              ],
              [
                -44.712850974156844,
                -1.724282078458221
              ],
              [
                -44.70828499800021,
                -1.71647413492127
              ],
              [
                -44.70106408080201,
                -1.718965107462974
              ],
              [
                -44.69805407900161,
                -1.732023510457936
              ],
              [
                -44.69675811412317,
                -1.737646463398753
              ],
              [
                -44.70459752080209,
                -1.738100966589195
              ],
              [
                -44.71367497435261,
                -1.754408858458773
              ],
              [
                -44.71732592033912,
                -1.754598350793068
              ],
              [
                -44.72078239087247,
                -1.754777869162047
              ],
              [
                -44.71933392172122,
                -1.757851332482799
              ],
              [
                -44.71926439924193,
                -1.757998829346409
              ],
              [
                -44.71959691455206,
                -1.758242337565533
              ],
              [
                -44.74761968487583,
                -1.778796672328447
              ],
              [
                -44.7525086246337,
                -1.785659621241351
              ],
              [
                -44.77813542141839,
                -1.790294595589446
              ],
              [
                -44.78191842030726,
                -1.790846109222649
              ],
              [
                -44.78516486119976,
                -1.792570602834352
              ],
              [
                -44.79119481396306,
                -1.799243038493091
              ],
              [
                -44.7937573095971,
                -1.804125008996875
              ],
              [
                -44.7982292810362,
                -1.80465199697545
              ],
              [
                -44.80022575740378,
                -1.812997919078322
              ],
              [
                -44.80477420822711,
                -1.813581919647064
              ],
              [
                -44.813659649602904,
                -1.814537428352331
              ],
              [
                -44.80061225131103,
                -1.81773238723894
              ],
              [
                -44.78420888016249,
                -1.795438580852307
              ],
              [
                -44.76410703604378,
                -1.797800543541085
              ],
              [
                -44.741257727850694,
                -1.790469090265342
              ],
              [
                -44.71637192701324,
                -1.782483631570465
              ],
              [
                -44.71665741884821,
                -1.786698609592087
              ],
              [
                -44.72192286951304,
                -1.793940573690685
              ],
              [
                -44.72252686451865,
                -1.794879565685819
              ],
              [
                -44.72645983299495,
                -1.800997019055318
              ],
              [
                -44.73701274766761,
                -1.808400468114453
              ],
              [
                -44.75647861202414,
                -1.81408340122382
              ],
              [
                -44.759174564783066,
                -1.818036369802283
              ],
              [
                -44.73572776412009,
                -1.81182844179327
              ],
              [
                -44.72645983299495,
                -1.800997019055318
              ],
              [
                -44.71520391096039,
                -1.793347066742699
              ],
              [
                -44.71427542346448,
                -1.792715555341151
              ],
              [
                -44.71424843666492,
                -1.7927795578488
              ],
              [
                -44.710287959057624,
                -1.802271011847089
              ],
              [
                -44.70952147824427,
                -1.797143552651739
              ],
              [
                -44.7092479630285,
                -1.794286547308634
              ],
              [
                -44.70873000129801,
                -1.788877581313145
              ],
              [
                -44.70725099646009,
                -1.786897594045221
              ],
              [
                -44.6963320912403,
                -1.772282206826685
              ],
              [
                -44.690565645893315,
                -1.772872211102688
              ],
              [
                -44.68479816955848,
                -1.773462702374149
              ],
              [
                -44.66377283687396,
                -1.767062738564894
              ],
              [
                -44.66309937002806,
                -1.763243273487607
              ],
              [
                -44.662777865965396,
                -1.761419785296635
              ],
              [
                -44.659598396990674,
                -1.743395424820431
              ],
              [
                -44.65410941083029,
                -1.751825379484792
              ],
              [
                -44.653286930720995,
                -1.759659804672137
              ],
              [
                -44.652662444429666,
                -1.765607757265505
              ],
              [
                -44.65971237897152,
                -1.772515696200319
              ],
              [
                -44.66250384315115,
                -1.780287644662409
              ],
              [
                -44.66643381257606,
                -1.79136256025713
              ],
              [
                -44.668333784339396,
                -1.796716023948998
              ],
              [
                -44.67362275740808,
                -1.801038487225596
              ],
              [
                -44.66965879263186,
                -1.802226970323583
              ],
              [
                -44.66403632942718,
                -1.796916045493878
              ],
              [
                -44.661688865579904,
                -1.789875584541458
              ],
              [
                -44.658212378405835,
                -1.77949316378392
              ],
              [
                -44.64657247608997,
                -1.764269786150618
              ],
              [
                -44.64613498342914,
                -1.769656235055847
              ],
              [
                -44.64944144425288,
                -1.783370625389871
              ],
              [
                -44.65156292855493,
                -1.787334610684297
              ],
              [
                -44.64760098131298,
                -1.790685553329956
              ],
              [
                -44.64944144425288,
                -1.783370625389871
              ],
              [
                -44.64365151460161,
                -1.767560741475698
              ],
              [
                -44.64657247608997,
                -1.764269786150618
              ],
              [
                -44.64738199757619,
                -1.759909791513092
              ],
              [
                -44.64953694452483,
                -1.754821846704162
              ],
              [
                -44.6496759795938,
                -1.753993854037094
              ],
              [
                -44.651336953464074,
                -1.744107934654207
              ],
              [
                -44.64834197667938,
                -1.742071919525774
              ],
              [
                -44.64673400482775,
                -1.735450988605932
              ],
              [
                -44.63805355281551,
                -1.735333465680338
              ],
              [
                -44.648613973592674,
                -1.732072017732518
              ],
              [
                -44.65469643265847,
                -1.720516608006249
              ],
              [
                -44.65009294741868,
                -1.719577624200782
              ],
              [
                -44.64039704233663,
                -1.724971074099982
              ],
              [
                -44.62099667997889,
                -1.727465030053396
              ],
              [
                -44.59340889791629,
                -1.7436694347899
              ],
              [
                -44.59431390852029,
                -1.749433386056158
              ],
              [
                -44.60232384343263,
                -1.746596401364371
              ],
              [
                -44.60518231600971,
                -1.749952372398123
              ],
              [
                -44.60830978838224,
                -1.753622850096548
              ],
              [
                -44.610290260153356,
                -1.755947322808953
              ],
              [
                -44.63407405824207,
                -1.78385913235932
              ],
              [
                -44.634747586204135,
                -1.787078605000297
              ],
              [
                -44.636445567975954,
                -1.795200028559569
              ],
              [
                -44.62943760548891,
                -1.814569379644013
              ],
              [
                -44.62834513751595,
                -1.817588346434391
              ],
              [
                -44.62684961350284,
                -1.821721841656819
              ],
              [
                -44.63052211636097,
                -1.826120287004226
              ],
              [
                -44.63277556087563,
                -1.828773288320685
              ],
              [
                -44.63177059059563,
                -1.82999028384131
              ],
              [
                -44.630958581833404,
                -1.830973240184747
              ],
              [
                -44.62839861989793,
                -1.832247236927315
              ],
              [
                -44.61970517180043,
                -1.823936300234864
              ],
              [
                -44.61922719969872,
                -1.823479296606792
              ],
              [
                -44.61515569898509,
                -1.828409785193487
              ],
              [
                -44.61375525074612,
                -1.830106250976029
              ],
              [
                -44.60900525655148,
                -1.834458245811227
              ],
              [
                -44.60816476106409,
                -1.83522871876258
              ],
              [
                -44.599243325969795,
                -1.83987019946504
              ],
              [
                -44.597936849219856,
                -1.840494186426563
              ],
              [
                -44.59477587902031,
                -1.842002658244895
              ],
              [
                -44.59525287217612,
                -1.846783141974354
              ],
              [
                -44.59552236339379,
                -1.849684595957441
              ],
              [
                -44.598517860464916,
                -1.854721084308246
              ],
              [
                -44.60164834030695,
                -1.855940053736185
              ],
              [
                -44.605244298064356,
                -1.858896557052828
              ],
              [
                -44.61120626604264,
                -1.861726500867687
              ],
              [
                -44.61866018100792,
                -1.865265993628092
              ],
              [
                -44.63305707908789,
                -1.858538041024299
              ],
              [
                -44.63324155881451,
                -1.853155110994964
              ],
              [
                -44.639096027355066,
                -1.857400069032137
              ],
              [
                -44.61930169523363,
                -1.868297964717622
              ],
              [
                -44.60348781449645,
                -1.861292041436923
              ],
              [
                -44.598517860464916,
                -1.854721084308246
              ],
              [
                -44.59491238250244,
                -1.853041088282777
              ],
              [
                -44.58337397082397,
                -1.859487551647127
              ],
              [
                -44.590727420688914,
                -1.853442567291645
              ],
              [
                -44.59229040594492,
                -1.845810153717735
              ],
              [
                -44.59483889603609,
                -1.835086233893661
              ],
              [
                -44.595510356315216,
                -1.83226175453834
              ],
              [
                -44.59643085884254,
                -1.828387271230048
              ],
              [
                -44.593728394596916,
                -1.813341395166179
              ],
              [
                -44.58875592799665,
                -1.809346932490987
              ],
              [
                -44.58810294330202,
                -1.808822421342106
              ],
              [
                -44.587265954102406,
                -1.808149905280127
              ],
              [
                -44.586886958384156,
                -1.811540899377392
              ],
              [
                -44.5866549457804,
                -1.81361636252402
              ],
              [
                -44.586271952977235,
                -1.812916394769715
              ],
              [
                -44.58356746255055,
                -1.807971930828083
              ],
              [
                -44.57877951395274,
                -1.811091916023464
              ],
              [
                -44.577758533461186,
                -1.804648955867469
              ],
              [
                -44.57714001721911,
                -1.800745460462851
              ],
              [
                -44.56983007413298,
                -1.797126022445507
              ],
              [
                -44.56512863398446,
                -1.804316457649475
              ],
              [
                -44.572142076451605,
                -1.812429389386571
              ],
              [
                -44.56784159736227,
                -1.81378988208409
              ],
              [
                -44.56239115468589,
                -1.832338215631376
              ],
              [
                -44.567010617954786,
                -1.841407160604933
              ],
              [
                -44.56093913564825,
                -1.83959516505235
              ],
              [
                -44.566705601019486,
                -1.852133572881467
              ],
              [
                -44.568268571357294,
                -1.853175093303064
              ],
              [
                -44.57509052009205,
                -1.857721054231322
              ],
              [
                -44.57912349269729,
                -1.85916655313572
              ],
              [
                -44.571431069698164,
                -1.859974018264333
              ],
              [
                -44.56787007533011,
                -1.854066085431987
              ],
              [
                -44.566705601019486,
                -1.852133572881467
              ],
              [
                -44.56066915281099,
                -1.848625126241864
              ],
              [
                -44.55732567718158,
                -1.845115154219955
              ],
              [
                -44.55326568825283,
                -1.846153636408799
              ],
              [
                -44.55179522402304,
                -1.84127817820659
              ],
              [
                -44.548257262736634,
                -1.829551752730127
              ],
              [
                -44.54394430357454,
                -1.827159280002737
              ],
              [
                -44.54537128852132,
                -1.821173819912652
              ],
              [
                -44.54183280486963,
                -1.824889293829971
              ],
              [
                -44.52946340046989,
                -1.837879187140272
              ],
              [
                -44.53224138905761,
                -1.848370599841295
              ],
              [
                -44.544392269552525,
                -1.865600494359536
              ],
              [
                -44.54745825919092,
                -1.869940935007683
              ],
              [
                -44.55197469563883,
                -1.872709909950228
              ],
              [
                -44.5552161924322,
                -1.874579432453708
              ],
              [
                -44.56095615002783,
                -1.877891409547976
              ],
              [
                -44.56474458840199,
                -1.880076387780451
              ],
              [
                -44.569265092947155,
                -1.882292845942381
              ],
              [
                -44.569662587777245,
                -1.882329359754857
              ],
              [
                -44.57553101398071,
                -1.882867862991363
              ],
              [
                -44.58145449359292,
                -1.888552798095636
              ],
              [
                -44.58503796030218,
                -1.892072305145059
              ],
              [
                -44.58990339753267,
                -1.896850729919322
              ],
              [
                -44.59404036867029,
                -1.89670724895775
              ],
              [
                -44.599248843190004,
                -1.897009253854423
              ],
              [
                -44.59029339477519,
                -1.904157689448806
              ],
              [
                -44.58488795504548,
                -1.898485230340983
              ],
              [
                -44.5803679830149,
                -1.893956757745835
              ],
              [
                -44.57378853026424,
                -1.894303769602249
              ],
              [
                -44.57354054618954,
                -1.894512268959657
              ],
              [
                -44.56981707709975,
                -1.897634224360689
              ],
              [
                -44.57191454221743,
                -1.902926687586915
              ],
              [
                -44.57315005568235,
                -1.90604467715741
              ],
              [
                -44.57054255131928,
                -1.910891637695646
              ],
              [
                -44.571442550238075,
                -1.914936621142863
              ],
              [
                -44.567796595803074,
                -1.912463136110958
              ],
              [
                -44.56803108243899,
                -1.90586819232221
              ],
              [
                -44.56831755892297,
                -1.902508713940021
              ],
              [
                -44.56846156516169,
                -1.900814201861855
              ],
              [
                -44.562787624936796,
                -1.897982738099698
              ],
              [
                -44.5500147439936,
                -1.891608271679372
              ],
              [
                -44.54602627414606,
                -1.88975530387059
              ],
              [
                -44.541286294199416,
                -1.89270027037709
              ],
              [
                -44.543815785499326,
                -1.89965772400986
              ],
              [
                -44.545310273675575,
                -1.903769697064442
              ],
              [
                -44.54173478411334,
                -1.899415717374645
              ],
              [
                -44.5378483341101,
                -1.894683742496537
              ],
              [
                -44.53593233480725,
                -1.89712073251139
              ],
              [
                -44.53492633347335,
                -1.898623724842439
              ],
              [
                -44.528210376648055,
                -1.908658133934791
              ],
              [
                -44.52983987756653,
                -1.900337691632737
              ],
              [
                -44.52962039784074,
                -1.898006744323637
              ],
              [
                -44.5295628824366,
                -1.897395747960984
              ],
              [
                -44.528647900907586,
                -1.887687806648646
              ],
              [
                -44.52799589665014,
                -1.880767843182094
              ],
              [
                -44.5227684639705,
                -1.882046836258466
              ],
              [
                -44.50211061420679,
                -1.927110520463426
              ],
              [
                -44.493214666702244,
                -1.932950944939948
              ],
              [
                -44.48746972070042,
                -1.944880873794289
              ],
              [
                -44.48747420859348,
                -1.95810227117612
              ],
              [
                -44.48747670958067,
                -1.966633698260214
              ],
              [
                -44.49202466077669,
                -1.964856212861264
              ],
              [
                -44.505294057325045,
                -1.959670258452545
              ],
              [
                -44.50431558672002,
                -1.95278332161567
              ],
              [
                -44.503731602261624,
                -1.948944341686908
              ],
              [
                -44.506486545870935,
                -1.950906326406813
              ],
              [
                -44.511181006718004,
                -1.954248798083343
              ],
              [
                -44.51381848644877,
                -1.951116812529729
              ],
              [
                -44.51862145250799,
                -1.954150286585444
              ],
              [
                -44.51379901379751,
                -1.95887477351214
              ],
              [
                -44.5086865472667,
                -1.964154231146015
              ],
              [
                -44.48963269075609,
                -1.973686139116315
              ],
              [
                -44.48683770268746,
                -1.97508414281593
              ],
              [
                -44.48862270892313,
                -1.977413606519524
              ],
              [
                -44.48957119007789,
                -1.978651110331674
              ],
              [
                -44.492379657427996,
                -1.984092560611527
              ],
              [
                -44.48942467052822,
                -1.981691084172056
              ],
              [
                -44.487527200695,
                -1.981458591487592
              ],
              [
                -44.48434674411773,
                -1.981069078691533
              ],
              [
                -44.484398718687885,
                -1.985372539966976
              ],
              [
                -44.48444272847306,
                -1.989026536182776
              ],
              [
                -44.48461321697818,
                -2.002232443783495
              ],
              [
                -44.49222918151481,
                -2.005615902893388
              ],
              [
                -44.496251610054614,
                -2.007402903278796
              ],
              [
                -44.49315316909556,
                -2.010053372377326
              ],
              [
                -44.48948466956031,
                -2.013190831633788
              ],
              [
                -44.48651368163811,
                -2.015628341379969
              ],
              [
                -44.48215574957306,
                -2.018858321762407
              ],
              [
                -44.47494680029924,
                -2.021759256950432
              ],
              [
                -44.465552880581924,
                -2.015743832412649
              ],
              [
                -44.47002985038453,
                -2.022111767072473
              ],
              [
                -44.47407480173606,
                -2.040415625894388
              ],
              [
                -44.48071474216754,
                -2.043196136391772
              ],
              [
                -44.48357074012925,
                -2.044385593054434
              ],
              [
                -44.48679069015636,
                -2.045725590663642
              ],
              [
                -44.48803519811798,
                -2.04624409802997
              ],
              [
                -44.49325165304316,
                -2.048416082811493
              ],
              [
                -44.497513110673005,
                -2.050190548380812
              ],
              [
                -44.4999465695102,
                -2.053401041590284
              ],
              [
                -44.50002506530753,
                -2.10021669206027
              ],
              [
                -44.499196091502675,
                -2.141421879111671
              ],
              [
                -44.49338960334207,
                -2.139083390358536
              ],
              [
                -44.48758265218836,
                -2.136744908609333
              ],
              [
                -44.4778122355972,
                -2.138393394581642
              ],
              [
                -44.472965287992665,
                -2.13925036882807
              ],
              [
                -44.470483806650876,
                -2.139689389856059
              ],
              [
                -44.46391234225066,
                -2.142667856551071
              ],
              [
                -44.46036239612679,
                -2.144277352416028
              ],
              [
                -44.45794639185353,
                -2.146335339168054
              ],
              [
                -44.4531589529627,
                -2.150411805981526
              ],
              [
                -44.45045246794212,
                -2.152716264784999
              ],
              [
                -44.446331505886164,
                -2.156226272067877
              ],
              [
                -44.44227251835056,
                -2.159683217767777
              ],
              [
                -44.43358110372711,
                -2.167084662310446
              ],
              [
                -44.42864363718131,
                -2.178337576586151
              ],
              [
                -44.43216460242116,
                -2.177142091584523
              ],
              [
                -44.432231105875346,
                -2.177202586142717
              ],
              [
                -44.437726567778746,
                -2.182174068846429
              ],
              [
                -44.43876253030786,
                -2.190683466535275
              ],
              [
                -44.43530609367517,
                -2.183708030567287
              ],
              [
                -44.43041013184706,
                -2.183162525421655
              ],
              [
                -44.42604065306786,
                -2.185529019308771
              ],
              [
                -44.42526465714539,
                -2.185949529912456
              ],
              [
                -44.418443701569444,
                -2.18964447706681
              ],
              [
                -44.41258974221398,
                -2.197448416277523
              ],
              [
                -44.40936177083049,
                -2.201751913753532
              ],
              [
                -44.406917290471604,
                -2.205010865365671
              ],
              [
                -44.3955553819432,
                -2.213195807210528
              ],
              [
                -44.40365783322901,
                -2.217330270983985
              ],
              [
                -44.409486261961455,
                -2.22030425896733
              ],
              [
                -44.40938876534547,
                -2.229354186263049
              ],
              [
                -44.41444621439672,
                -2.22790971857959
              ],
              [
                -44.412775258177994,
                -2.222404749905545
              ],
              [
                -44.41576472366899,
                -2.221155243186479
              ],
              [
                -44.41444621439672,
                -2.22790971857959
              ],
              [
                -44.41180324756478,
                -2.231336674867601
              ],
              [
                -44.40938876534547,
                -2.229354186263049
              ],
              [
                -44.40083885044537,
                -2.225916216063193
              ],
              [
                -44.4025343114378,
                -2.220181769040605
              ],
              [
                -44.4022848394703,
                -2.22001974520378
              ],
              [
                -44.3994688334118,
                -2.218195258301018
              ],
              [
                -44.39258140316252,
                -2.222693246318159
              ],
              [
                -44.392155905406995,
                -2.227041681195972
              ],
              [
                -44.391544919624494,
                -2.233291651592872
              ],
              [
                -44.390968403655855,
                -2.239190616666487
              ],
              [
                -44.3906059265595,
                -2.242900096416773
              ],
              [
                -44.39050042111421,
                -2.243976593218829
              ],
              [
                -44.38853491543,
                -2.249085542917439
              ],
              [
                -44.388310448064324,
                -2.249670516417639
              ],
              [
                -44.38306045528993,
                -2.263315428454822
              ],
              [
                -44.37976800925323,
                -2.27187285162937
              ],
              [
                -44.378493510527605,
                -2.275184839153282
              ],
              [
                -44.38031399802147,
                -2.275113835189853
              ],
              [
                -44.38953141258057,
                -2.27475534243027
              ],
              [
                -44.37901451357815,
                -2.278114323070974
              ],
              [
                -44.37558350465784,
                -2.279209816820184
              ],
              [
                -44.35990414839788,
                -2.317254994898367
              ],
              [
                -44.35726214184097,
                -2.32684294044735
              ],
              [
                -44.355529683365724,
                -2.338766827543866
              ],
              [
                -44.36149761433997,
                -2.347361297064944
              ],
              [
                -44.366269082432716,
                -2.350321257974644
              ],
              [
                -44.36659708275582,
                -2.35053326446029
              ],
              [
                -44.36981304750969,
                -2.352612748607995
              ],
              [
                -44.37133154030631,
                -2.360062199581996
              ],
              [
                -44.37542350096266,
                -2.358955694127351
              ],
              [
                -44.37078353955462,
                -2.367628618090723
              ],
              [
                -44.36949906412948,
                -2.370030093528668
              ],
              [
                -44.3731570124353,
                -2.389491980135694
              ],
              [
                -44.37474350111259,
                -2.391823967928486
              ],
              [
                -44.37632950079341,
                -2.394155948722729
              ],
              [
                -44.38533841112948,
                -2.407401351025023
              ],
              [
                -44.39201036878257,
                -2.411295303479084
              ],
              [
                -44.39835232020317,
                -2.414730277319336
              ],
              [
                -44.39886030907197,
                -2.415004769389345
              ],
              [
                -44.40251627846792,
                -2.413054278212686
              ],
              [
                -44.39969680834607,
                -2.408213341034953
              ],
              [
                -44.40650174800399,
                -2.408426314393928
              ],
              [
                -44.409270726368376,
                -2.410345830669326
              ],
              [
                -44.41239219879452,
                -2.412708801693124
              ],
              [
                -44.37373801428826,
                -2.453823973262125
              ],
              [
                -44.325584377235394,
                -2.499677136948314
              ],
              [
                -44.3108329728202,
                -2.497116112907406
              ],
              [
                -44.30794153297112,
                -2.493560681956275
              ],
              [
                -44.3074495210806,
                -2.492955669389293
              ],
              [
                -44.30402502067656,
                -2.488766707080199
              ],
              [
                -44.293396640806506,
                -2.484655735038172
              ],
              [
                -44.283037228597216,
                -2.484622727200056
              ],
              [
                -44.269451830183584,
                -2.484597226344449
              ],
              [
                -44.2690563263452,
                -2.484596210234297
              ],
              [
                -44.265551834360046,
                -2.484589218243086
              ],
              [
                -44.26373687322733,
                -2.483370213938485
              ],
              [
                -44.26038891665271,
                -2.481121239982948
              ],
              [
                -44.2546474507229,
                -2.480543750651299
              ],
              [
                -44.24517850371065,
                -2.479593245039829
              ],
              [
                -44.24137303825235,
                -2.479210733808177
              ],
              [
                -44.2267571725886,
                -2.477683260046677
              ],
              [
                -44.22130021214819,
                -2.474573802005088
              ],
              [
                -44.21592476103979,
                -2.471526288523163
              ],
              [
                -44.212162291502736,
                -2.470280810855487
              ],
              [
                -44.20839480600509,
                -2.469042825130892
              ],
              [
                -44.205272853282025,
                -2.468017309986794
              ],
              [
                -44.192814946158194,
                -2.463923342367116
              ],
              [
                -44.18936597008868,
                -2.462789852950571
              ],
              [
                -44.17854904067643,
                -2.459295376283046
              ],
              [
                -44.17338609174213,
                -2.456748408096164
              ],
              [
                -44.17085262839025,
                -2.455479403984343
              ],
              [
                -44.16686763381557,
                -2.453669442554074
              ],
              [
                -44.152598742930984,
                -2.447188956577052
              ],
              [
                -44.14936278728315,
                -2.445661995226615
              ],
              [
                -44.148736312384834,
                -2.44536697028397
              ],
              [
                -44.14800979840599,
                -2.444662477422133
              ],
              [
                -44.14273735010222,
                -2.43955101170737
              ],
              [
                -44.13806088994411,
                -2.434722078029146
              ],
              [
                -44.14047286873104,
                -2.430888603856074
              ],
              [
                -44.130376948266075,
                -2.428524608884675
              ],
              [
                -44.12866845205844,
                -2.424661633745313
              ],
              [
                -44.110529120233366,
                -2.418135189102553
              ],
              [
                -44.10069667401742,
                -2.411110718676304
              ],
              [
                -44.09796721472451,
                -2.415193217843443
              ],
              [
                -44.09666023163944,
                -2.417148675595833
              ],
              [
                -44.09690420535674,
                -2.41479319857861
              ],
              [
                -44.09711673423814,
                -2.412743735225638
              ],
              [
                -44.093057256597326,
                -2.413344712484537
              ],
              [
                -44.091777749797004,
                -2.413534216674242
              ],
              [
                -44.08890576643091,
                -2.418377678009032
              ],
              [
                -44.092099270116556,
                -2.420901673737697
              ],
              [
                -44.08187083768873,
                -2.418814158656401
              ],
              [
                -44.080272862794715,
                -2.424764624936218
              ],
              [
                -44.07650639393202,
                -2.417955679636454
              ],
              [
                -44.084445805524695,
                -2.410138748387425
              ],
              [
                -44.08451979993379,
                -2.410131227465956
              ],
              [
                -44.08815028994414,
                -2.409755757367357
              ],
              [
                -44.094810222782165,
                -2.409018249894258
              ],
              [
                -44.092302771618044,
                -2.406409255016523
              ],
              [
                -44.06758095252298,
                -2.403754287111647
              ],
              [
                -44.02975024075388,
                -2.407738732918168
              ],
              [
                -44.02404982024754,
                -2.405034739869335
              ],
              [
                -44.023252291648134,
                -2.40149728057738
              ],
              [
                -44.02244330415291,
                -2.397911322653643
              ],
              [
                -44.01899986141766,
                -2.399181290996502
              ],
              [
                -44.01929183147684,
                -2.401295284980781
              ],
              [
                -44.02010385229609,
                -2.407171233467875
              ],
              [
                -44.02674877229389,
                -2.413139687935299
              ],
              [
                -44.024961811972425,
                -2.422451109533658
              ],
              [
                -44.02252079346755,
                -2.433228554789427
              ],
              [
                -44.02238329339602,
                -2.43383654212182
              ],
              [
                -44.0204973221329,
                -2.442157478242245
              ],
              [
                -44.02188280878859,
                -2.449883430850938
              ],
              [
                -44.02899927309782,
                -2.451967914071329
              ],
              [
                -44.02891226005152,
                -2.451083928771749
              ],
              [
                -44.02865974681862,
                -2.448518422218376
              ],
              [
                -44.03182272456421,
                -2.444648952584805
              ],
              [
                -44.034426203546786,
                -2.441647978178548
              ],
              [
                -44.03414721316221,
                -2.447272444301864
              ],
              [
                -44.0402131705889,
                -2.446998936154611
              ],
              [
                -44.045082117130995,
                -2.438012523942833
              ],
              [
                -44.04776112209817,
                -2.439919005218505
              ],
              [
                -44.046713627453514,
                -2.44354447333226
              ],
              [
                -44.052970051991565,
                -2.444554971471113
              ],
              [
                -44.0559030373371,
                -2.445027463733377
              ],
              [
                -44.059712510644765,
                -2.445538950955443
              ],
              [
                -44.06336146856652,
                -2.43798499747351
              ],
              [
                -44.067859945844006,
                -2.440268512416368
              ],
              [
                -44.072423915420494,
                -2.436226046489309
              ],
              [
                -44.0714294179661,
                -2.441179978525268
              ],
              [
                -44.064445961834096,
                -2.445053478029901
              ],
              [
                -44.06556044679463,
                -2.448875938286503
              ],
              [
                -44.07208239917363,
                -2.450036933366293
              ],
              [
                -44.07532690000046,
                -2.450605414993527
              ],
              [
                -44.08305283968052,
                -2.451959420962027
              ],
              [
                -44.094793724987596,
                -2.454017411759996
              ],
              [
                -44.096282210248134,
                -2.456813887942987
              ],
              [
                -44.098994679030255,
                -2.461912839990672
              ],
              [
                -44.10116768292271,
                -2.464300339488245
              ],
              [
                -44.094898224331956,
                -2.463718343064623
              ],
              [
                -44.09256274937373,
                -2.459160363017116
              ],
              [
                -44.088893759730205,
                -2.459324866688623
              ],
              [
                -44.07901536977788,
                -2.459766354429998
              ],
              [
                -44.073210377235185,
                -2.460026335747753
              ],
              [
                -44.062230982114016,
                -2.460518337780832
              ],
              [
                -44.05869053345494,
                -2.460676350539009
              ],
              [
                -44.063663974089366,
                -2.465798299057688
              ],
              [
                -44.07652185218579,
                -2.465197799409159
              ],
              [
                -44.07068190713402,
                -2.468173291070147
              ],
              [
                -44.06456746918812,
                -2.471484753093061
              ],
              [
                -44.061837493837146,
                -2.482559200101844
              ],
              [
                -44.05647351272254,
                -2.465865312430834
              ],
              [
                -44.03774018038963,
                -2.464368306302136
              ],
              [
                -44.031167232264,
                -2.463842320369931
              ],
              [
                -43.99472903008872,
                -2.465693773567472
              ],
              [
                -43.976763661027164,
                -2.466606286338577
              ],
              [
                -43.96759575340269,
                -2.47425921437546
              ],
              [
                -43.9584283007784,
                -2.481912157402849
              ],
              [
                -43.96800574176718,
                -2.51286294268068
              ],
              [
                -43.97819262416672,
                -2.545782197355525
              ],
              [
                -43.99958293764317,
                -2.578303931700122
              ],
              [
                -43.99621596695829,
                -2.577343959958728
              ],
              [
                -43.980141626382135,
                -2.572756470885229
              ],
              [
                -43.959778793151756,
                -2.541992210595923
              ],
              [
                -43.92459258914553,
                -2.510509427603901
              ],
              [
                -43.89972279132373,
                -2.461523295447948
              ],
              [
                -43.89163587318371,
                -2.434224026245832
              ],
              [
                -43.86666855476223,
                -2.406602693806289
              ],
              [
                -43.8616396116414,
                -2.397955257382482
              ],
              [
                -43.8566116475059,
                -2.389309347966289
              ],
              [
                -43.82680737615198,
                -2.360928050372089
              ],
              [
                -43.7883376969341,
                -2.342062173880038
              ],
              [
                -43.78409224224186,
                -2.34157716540971
              ],
              [
                -43.781226283078404,
                -2.346065153278295
              ],
              [
                -43.77986977841336,
                -2.348190115644194
              ],
              [
                -43.769340875346245,
                -2.35484358680417
              ],
              [
                -43.75581896586701,
                -2.363388007646347
              ],
              [
                -43.752226496662715,
                -2.367483975299282
              ],
              [
                -43.75191697505747,
                -2.367836976511458
              ],
              [
                -43.74740354497842,
                -2.372982936869994
              ],
              [
                -43.74818302314126,
                -2.367799976745487
              ],
              [
                -43.75092449886365,
                -2.364908472639635
              ],
              [
                -43.760669437230014,
                -2.354628575025628
              ],
              [
                -43.728151191389735,
                -2.287612054234573
              ],
              [
                -43.72249475725503,
                -2.282781122724022
              ],
              [
                -43.71683831512104,
                -2.277950127223263
              ],
              [
                -43.667175716928895,
                -2.257205800976614
              ],
              [
                -43.631855992429365,
                -2.22809949905887
              ],
              [
                -43.62331155254645,
                -2.223555530714341
              ],
              [
                -43.614769130652945,
                -2.21901207037834
              ],
              [
                -43.58703487386731,
                -2.229104004522183
              ],
              [
                -43.576166936316945,
                -2.237872918249606
              ],
              [
                -43.56529902077218,
                -2.246642364959844
              ],
              [
                -43.51760489443148,
                -2.342275591154309
              ],
              [
                -43.50112652796401,
                -2.366480899645661
              ],
              [
                -43.491541582155925,
                -2.371155882056294
              ],
              [
                -43.4902561190766,
                -2.369245381372605
              ],
              [
                -43.482944171191754,
                -2.358373968857057
              ],
              [
                -43.48132268796489,
                -2.355963978924867
              ],
              [
                -43.45271942770014,
                -2.336173122146263
              ],
              [
                -43.4364695395872,
                -2.330919678081253
              ],
              [
                -43.426079611659866,
                -2.330904173345941
              ],
              [
                -43.41795018147577,
                -2.330797154936326
              ],
              [
                -43.41774619912725,
                -2.330794686899245
              ],
              [
                -43.40651179609417,
                -2.330646150957231
              ],
              [
                -43.40232533097873,
                -2.330656171730639
              ],
              [
                -43.37974102740896,
                -2.324800224620122
              ],
              [
                -43.375500536481276,
                -2.325781209905438
              ],
              [
                -43.372463062526954,
                -2.331508171974106
              ],
              [
                -43.33997082855533,
                -2.339473094701782
              ],
              [
                -43.334598365327714,
                -2.334900618448847
              ],
              [
                -43.32465794555902,
                -2.336074118676915
              ],
              [
                -43.315811510970164,
                -2.33711858119793
              ],
              [
                -43.304081606671595,
                -2.338503571300231
              ],
              [
                -43.2720413839257,
                -2.347812020722376
              ],
              [
                -43.18477605116188,
                -2.373164300987476
              ],
              [
                -43.17357918215519,
                -2.380197711586548
              ],
              [
                -43.162382244158614,
                -2.387231669169258
              ],
              [
                -43.10531470892096,
                -2.408826503417947
              ],
              [
                -43.02147639276717,
                -2.452128125081495
              ],
              [
                -43.01534391399954,
                -2.454554110576448
              ],
              [
                -42.98783512722472,
                -2.4677780134648
              ],
              [
                -42.978145715138666,
                -2.471289955524027
              ],
              [
                -42.978142722162595,
                -2.471291466511478
              ],
              [
                -42.9761582501213,
                -2.472010489380607
              ],
              [
                -42.9704457780618,
                -2.474081448721627
              ],
              [
                -42.96075485600576,
                -2.477593415769562
              ],
              [
                -42.95592340886882,
                -2.479343919837687
              ],
              [
                -42.950845443717476,
                -2.481183895141161
              ],
              [
                -42.947587454926946,
                -2.482364888065828
              ],
              [
                -42.94360798881341,
                -2.484256386286559
              ],
              [
                -42.93978252646721,
                -2.486074867115329
              ],
              [
                -42.93678255750727,
                -2.487500851217728
              ],
              [
                -42.934153094579266,
                -2.488749849795757
              ],
              [
                -42.92575513988285,
                -2.492740311495349
              ],
              [
                -42.920281682752304,
                -2.495341263787514
              ],
              [
                -42.91480874862033,
                -2.497942258077104
              ],
              [
                -42.89453839412116,
                -2.507576179642951
              ],
              [
                -42.89100991341182,
                -2.509253146638551
              ],
              [
                -42.88701445044814,
                -2.511152138778889
              ],
              [
                -42.88400649456763,
                -2.512581117843365
              ],
              [
                -42.88329548426879,
                -2.512919637016233
              ],
              [
                -42.87405306338564,
                -2.517311080332177
              ],
              [
                -42.86914860871853,
                -2.519642077857851
              ],
              [
                -42.842499805478255,
                -2.532305956023242
              ],
              [
                -42.83790334225465,
                -2.534834449099117
              ],
              [
                -42.83330688203267,
                -2.537363446169211
              ],
              [
                -42.8081935790077,
                -2.5511773228118
              ],
              [
                -42.801908128316995,
                -2.554635271177068
              ],
              [
                -42.79277722440775,
                -2.559658244576308
              ],
              [
                -42.78496878491757,
                -2.560561241260999
              ],
              [
                -42.7741733697333,
                -2.561810214376006
              ],
              [
                -42.76921291708909,
                -2.562383209461698
              ],
              [
                -42.7612434567801,
                -2.563814702001714
              ],
              [
                -42.766761911141884,
                -2.56568167018751
              ],
              [
                -42.76136046573933,
                -2.56749466153616
              ],
              [
                -42.75125155587569,
                -2.565686187528793
              ],
              [
                -42.74591406815942,
                -2.563429722418164
              ],
              [
                -42.7458500897497,
                -2.563200686173375
              ],
              [
                -42.744240601598925,
                -2.557434736365056
              ],
              [
                -42.740935137379566,
                -2.561634212846399
              ],
              [
                -42.73902162995025,
                -2.56526467814969
              ],
              [
                -42.73837364588387,
                -2.566495188423359
              ],
              [
                -42.73936664940705,
                -2.574492123769035
              ],
              [
                -42.736700658049685,
                -2.578371582920096
              ],
              [
                -42.72106078963437,
                -2.589376987975029
              ],
              [
                -42.70696638665372,
                -2.590121991966248
              ],
              [
                -42.69876697651391,
                -2.59076697050362
              ],
              [
                -42.695466971175826,
                -2.598581910952976
              ],
              [
                -42.694132505765516,
                -2.603020397158839
              ],
              [
                -42.69097803542773,
                -2.613506805956932
              ],
              [
                -42.698140974914,
                -2.62325824359374
              ],
              [
                -42.699869928217495,
                -2.628424206109615
              ],
              [
                -42.702526917090175,
                -2.63636314844124
              ],
              [
                -42.70380892338687,
                -2.640389604656119
              ],
              [
                -42.697845436538415,
                -2.645728072491729
              ],
              [
                -42.69472397047627,
                -2.647677031442289
              ],
              [
                -42.69672796066451,
                -2.64247608932975
              ],
              [
                -42.69914993933052,
                -2.637609639755547
              ],
              [
                -42.691196511615,
                -2.626898695280748
              ],
              [
                -42.685255078597734,
                -2.618974267860964
              ],
              [
                -42.68669303863945,
                -2.612963320868634
              ],
              [
                -42.6881130318021,
                -2.607026349299827
              ],
              [
                -42.689639050226525,
                -2.600644918209239
              ],
              [
                -42.690843529876844,
                -2.595605920620967
              ],
              [
                -42.693021992766624,
                -2.58649853185729
              ],
              [
                -42.70161395041312,
                -2.576662610663037
              ],
              [
                -42.70567391490341,
                -2.577709066767148
              ],
              [
                -42.71502884872621,
                -2.580022076643355
              ],
              [
                -42.71644980200429,
                -2.58037356934546
              ],
              [
                -42.72550726907021,
                -2.576606602243167
              ],
              [
                -42.72507774839884,
                -2.573842111533605
              ],
              [
                -42.72463774283146,
                -2.571012633325596
              ],
              [
                -42.721262284829145,
                -2.569648147891262
              ],
              [
                -42.71811731489242,
                -2.56845667718625
              ],
              [
                -42.714776834576966,
                -2.567191183997776
              ],
              [
                -42.7115648652056,
                -2.565946680686067
              ],
              [
                -42.71281186763485,
                -2.560422204875883
              ],
              [
                -42.71833830039082,
                -2.560626208937338
              ],
              [
                -42.724393269383114,
                -2.555540266152958
              ],
              [
                -42.72946474153178,
                -2.556882249258015
              ],
              [
                -42.73849315506345,
                -2.559168260226125
              ],
              [
                -42.741114640685225,
                -2.555601262642549
              ],
              [
                -42.73749214882439,
                -2.550376284055631
              ],
              [
                -42.73339919437606,
                -2.550043805420027
              ],
              [
                -42.725073264708,
                -2.552526284713965
              ],
              [
                -42.722304769096,
                -2.553360261429629
              ],
              [
                -42.71042435917893,
                -2.556939259164306
              ],
              [
                -42.675701651809035,
                -2.592000456009615
              ],
              [
                -42.66908021499036,
                -2.598686407189283
              ],
              [
                -42.66683871865499,
                -2.600949395971563
              ],
              [
                -42.6516623482572,
                -2.616273290595612
              ],
              [
                -42.6440489112583,
                -2.623960726692811
              ],
              [
                -42.64154241101201,
                -2.626491685311014
              ],
              [
                -42.64019291275029,
                -2.627512676985711
              ],
              [
                -42.63633596044168,
                -2.630430660191076
              ],
              [
                -42.62882053824731,
                -2.636117131816721
              ],
              [
                -42.62443358015907,
                -2.639436570743344
              ],
              [
                -42.61154217475641,
                -2.649191006174628
              ],
              [
                -42.6091251669934,
                -2.65101999525338
              ],
              [
                -42.60612969283505,
                -2.653286461762173
              ],
              [
                -42.60612620986281,
                -2.653288984741519
              ],
              [
                -42.59772175375833,
                -2.659648425852537
              ],
              [
                -42.583029369564954,
                -2.667977369744715
              ],
              [
                -42.57982090729367,
                -2.669795854653422
              ],
              [
                -42.57654043660052,
                -2.67165584221724
              ],
              [
                -42.56677302193369,
                -2.677191771267271
              ],
              [
                -42.5582810698404,
                -2.679409751435701
              ],
              [
                -42.550072631454356,
                -2.681553231266609
              ],
              [
                -42.54751116674663,
                -2.683839232741698
              ],
              [
                -42.547204174178525,
                -2.684113715517492
              ],
              [
                -42.54402921933075,
                -2.686949197539339
              ],
              [
                -42.53043380948051,
                -2.684659195080621
              ],
              [
                -42.525770865968916,
                -2.683812736200628
              ],
              [
                -42.51919490825123,
                -2.682620240413591
              ],
              [
                -42.51774440222527,
                -2.682357245004586
              ],
              [
                -42.50924998535228,
                -2.68081622833061
              ],
              [
                -42.504074529082935,
                -2.679877232015325
              ],
              [
                -42.49227361387003,
                -2.696164629940487
              ],
              [
                -42.48247167810011,
                -2.709692985890044
              ],
              [
                -42.47874873101663,
                -2.711716978017116
              ],
              [
                -42.479829208240304,
                -2.708201503638417
              ],
              [
                -42.48127572322823,
                -2.702197567688609
              ],
              [
                -42.48133223083687,
                -2.70196354652259
              ],
              [
                -42.48370318341773,
                -2.692123151642173
              ],
              [
                -42.473769282865014,
                -2.692291120249794
              ],
              [
                -42.433808603454786,
                -2.706469506703866
              ],
              [
                -42.368442638124385,
                -2.710323457348352
              ],
              [
                -42.32903295938695,
                -2.692934092322788
              ],
              [
                -42.32379599332726,
                -2.683508655041638
              ],
              [
                -42.31609806124955,
                -2.684727172180991
              ],
              [
                -42.30781664153256,
                -2.690667612387207
              ],
              [
                -42.30341518576264,
                -2.693825587445695
              ],
              [
                -42.27439442132945,
                -2.694331573515205
              ],
              [
                -42.26962642918877,
                -2.692277078036559
              ],
              [
                -42.26485898204526,
                -2.690223613552954
              ],
              [
                -42.23822669116689,
                -2.667440275852183
              ],
              [
                -42.227809782128645,
                -2.666751767027274
              ],
              [
                -42.22030686176288,
                -2.667208279170633
              ],
              [
                -42.222436340797266,
                -2.678516188681194
              ],
              [
                -42.22437131673408,
                -2.688791592197752
              ],
              [
                -42.21244189513065,
                -2.701305007993824
              ],
              [
                -42.212984406856755,
                -2.690873567455521
              ],
              [
                -42.21614836287521,
                -2.690639066260615
              ],
              [
                -42.22058185947044,
                -2.690310588190132
              ],
              [
                -42.21701685842418,
                -2.685068608942812
              ],
              [
                -42.21452338815376,
                -2.684419642232297
              ],
              [
                -42.21169494166906,
                -2.683683145101226
              ],
              [
                -42.20311549176951,
                -2.685496112324935
              ],
              [
                -42.161062821490134,
                -2.694382524055582
              ],
              [
                -42.15743936911599,
                -2.695148028965312
              ],
              [
                -42.14795042243845,
                -2.694756525086962
              ],
              [
                -42.138314516984494,
                -2.694358537215255
              ],
              [
                -42.1242996476981,
                -2.693780517398162
              ],
              [
                -42.11783168010856,
                -2.693513019489383
              ],
              [
                -42.11136375152324,
                -2.6932460245774
              ],
              [
                -42.10002583782114,
                -2.690634542466416
              ],
              [
                -42.09656787758378,
                -2.68983806761903
              ],
              [
                -42.093102396410266,
                -2.689040055782111
              ],
              [
                -42.082891472354014,
                -2.686687580008707
              ],
              [
                -42.079226499638516,
                -2.686523083166085
              ],
              [
                -42.068023107224576,
                -2.686018578661558
              ],
              [
                -42.063997647494666,
                -2.685838079834136
              ],
              [
                -42.06081963951885,
                -2.686495079725086
              ],
              [
                -42.056413203595234,
                -2.687436048019587
              ],
              [
                -42.050221260293476,
                -2.688758556783436
              ],
              [
                -42.05032674039494,
                -2.692135520433719
              ],
              [
                -42.05782969617854,
                -2.69311000313277
              ],
              [
                -42.059196171848164,
                -2.693286997171349
              ],
              [
                -42.05799918277954,
                -2.699665447981557
              ],
              [
                -42.05733817118453,
                -2.703099916953035
              ],
              [
                -42.05423071440153,
                -2.707762887573084
              ],
              [
                -42.04838724093741,
                -2.716531812276541
              ],
              [
                -42.044962763621456,
                -2.721669787086248
              ],
              [
                -42.04153831130362,
                -2.726808228891412
              ],
              [
                -42.03276037769192,
                -2.739979662292002
              ],
              [
                -42.030067896667184,
                -2.744019103905252
              ],
              [
                -42.02409542553343,
                -2.758299999520127
              ],
              [
                -42.02182546959365,
                -2.754071529820603
              ],
              [
                -42.01746198762835,
                -2.74440360894735
              ],
              [
                -42.01399551564734,
                -2.736674163232948
              ],
              [
                -42.011753565705696,
                -2.731675694578571
              ],
              [
                -42.0132030541328,
                -2.727150238384731
              ],
              [
                -42.01409053465791,
                -2.724380745297984
              ],
              [
                -42.01635552316017,
                -2.717212801005044
              ],
              [
                -42.01032558841827,
                -2.715695325989634
              ],
              [
                -42.005888589378195,
                -2.714656335731728
              ],
              [
                -41.995994672346384,
                -2.717081798705762
              ],
              [
                -41.986079773471985,
                -2.719594282988957
              ],
              [
                -41.98003232095867,
                -2.721126792130661
              ],
              [
                -41.979872829263826,
                -2.721167267764674
              ],
              [
                -41.97414484613995,
                -2.722619248633548
              ],
              [
                -41.96160946185731,
                -2.722159278332666
              ],
              [
                -41.95794498177345,
                -2.720094788336904
              ],
              [
                -41.95424404799816,
                -2.718009776492567
              ],
              [
                -41.94657207222752,
                -2.718585804607124
              ],
              [
                -41.93995915673388,
                -2.719082290671939
              ],
              [
                -41.93694667356574,
                -2.719308275969515
              ],
              [
                -41.92895424445193,
                -2.719908781791867
              ],
              [
                -41.91417134233526,
                -2.721018739520226
              ],
              [
                -41.91148088230869,
                -2.718651755206565
              ],
              [
                -41.91069588401164,
                -2.717961299366097
              ],
              [
                -41.90641443349798,
                -2.717695782116853
              ],
              [
                -41.89447152049619,
                -2.716956284204099
              ],
              [
                -41.88959704356655,
                -2.717764758365813
              ],
              [
                -41.88391660326142,
                -2.718720745122424
              ],
              [
                -41.87137571836368,
                -2.720832259704772
              ],
              [
                -41.86993021524884,
                -2.721075261354072
              ],
              [
                -41.85908481542843,
                -2.722900242701149
              ],
              [
                -41.85760330973865,
                -2.722735220533822
              ],
              [
                -41.84840739515491,
                -2.721713244685803
              ],
              [
                -41.832597021557866,
                -2.719955730553704
              ],
              [
                -41.82344908460092,
                -2.718938247693062
              ],
              [
                -41.819864618791804,
                -2.723788208583703
              ],
              [
                -41.8287930814942,
                -2.721762730219459
              ],
              [
                -41.82345658478368,
                -2.727924155295526
              ],
              [
                -41.81372819093642,
                -2.738958597826486
              ],
              [
                -41.8173026655566,
                -2.744735032693558
              ],
              [
                -41.82246161826277,
                -2.753070484024777
              ],
              [
                -41.82419356505541,
                -2.755869476649101
              ],
              [
                -41.84866637808668,
                -2.773167340939807
              ],
              [
                -41.84641890466083,
                -2.805082581515058
              ],
              [
                -41.84411939401603,
                -2.810388555274872
              ],
              [
                -41.840738415529835,
                -2.818187985185203
              ],
              [
                -41.843287913722044,
                -2.823550426063613
              ],
              [
                -41.85058484135713,
                -2.826589924642014
              ],
              [
                -41.86118477222341,
                -2.831004890538282
              ],
              [
                -41.86088877299157,
                -2.842520785420907
              ],
              [
                -41.86059326374066,
                -2.854036722317803
              ],
              [
                -41.8610312584761,
                -2.856025205920059
              ],
              [
                -41.86510972674876,
                -2.874530067660687
              ],
              [
                -41.86131625537983,
                -2.883043996905044
              ],
              [
                -41.85752277500491,
                -2.891557932150445
              ],
              [
                -41.84219988362073,
                -2.910576778530774
              ],
              [
                -41.83568344389598,
                -2.9156722335693
              ],
              [
                -41.820349584915974,
                -2.927657619849922
              ],
              [
                -41.80755318458854,
                -2.93765954244957
              ],
              [
                -41.802915720588615,
                -2.944939999026537
              ],
              [
                -41.79641073239804,
                -2.966204320755177
              ],
              [
                -41.814347094627834,
                -2.987701668992824
              ],
              [
                -41.82389352323215,
                -3.024143383924208
              ],
              [
                -41.82823247087361,
                -3.028332360747696
              ],
              [
                -41.83257293750206,
                -3.032522303570441
              ],
              [
                -41.840325373996656,
                -3.036372783225787
              ],
              [
                -41.861272207026325,
                -3.046777713378065
              ],
              [
                -41.86543914137218,
                -3.053073664765731
              ],
              [
                -41.869605612719184,
                -3.059369104167868
              ],
              [
                -41.88948443879852,
                -3.089415892138287
              ],
              [
                -41.89443589343416,
                -3.093418860722927
              ],
              [
                -41.89938685907508,
                -3.097420336325504
              ],
              [
                -41.902838846340266,
                -3.097925314614645
              ],
              [
                -41.91344623205113,
                -3.09947683328939
              ],
              [
                -41.9223656594402,
                -3.107575242441609
              ],
              [
                -41.92564164669745,
                -3.115115695952217
              ],
              [
                -41.92508114031766,
                -3.123677651164422
              ],
              [
                -41.924180659902724,
                -3.137435027865197
              ],
              [
                -41.931291601166336,
                -3.145685470271375
              ],
              [
                -41.934161054253536,
                -3.149013927429078
              ],
              [
                -41.93688804142702,
                -3.155406408725741
              ],
              [
                -41.93691503903522,
                -3.155879380059641
              ],
              [
                -41.93866702139736,
                -3.187008127829763
              ],
              [
                -41.942891988598774,
                -3.189400150786706
              ],
              [
                -41.95217839974917,
                -3.181245691498832
              ],
              [
                -41.955721890570665,
                -3.178134204951812
              ],
              [
                -41.96407131036723,
                -3.178632734109467
              ],
              [
                -41.97119723049924,
                -3.192421107637845
              ],
              [
                -41.974313701704176,
                -3.211857970903631
              ],
              [
                -41.97866669144491,
                -3.218131942813687
              ],
              [
                -41.98337064673894,
                -3.223152866585777
              ],
              [
                -41.984117142278556,
                -3.22395089166912
              ],
              [
                -41.9954270214041,
                -3.236024302187631
              ],
              [
                -41.999838513226116,
                -3.240734252293203
              ],
              [
                -42.00992189599535,
                -3.245962234493415
              ],
              [
                -42.02352580271704,
                -3.245678731738113
              ],
              [
                -42.030117225322904,
                -3.245541727245755
              ],
              [
                -42.066331449596206,
                -3.252848698088481
              ],
              [
                -42.071292911677574,
                -3.255455163748262
              ],
              [
                -42.076255362752576,
                -3.258061645412238
              ],
              [
                -42.115443032925064,
                -3.261972138766506
              ],
              [
                -42.13479788337299,
                -3.280556493236657
              ],
              [
                -42.133493866423954,
                -3.285132964349651
              ],
              [
                -42.13219040246814,
                -3.289709901460288
              ],
              [
                -42.128162426462154,
                -3.290327900162161
              ],
              [
                -42.10193563033716,
                -3.294350358155736
              ],
              [
                -42.097282691352135,
                -3.298046362804105
              ],
              [
                -42.096048673429934,
                -3.303673816800138
              ],
              [
                -42.09974814714138,
                -3.311033254270368
              ],
              [
                -42.11019757301244,
                -3.313443224594329
              ],
              [
                -42.11327153238328,
                -3.314152710274308
              ],
              [
                -42.12189947242687,
                -3.321526166539779
              ],
              [
                -42.1280504017103,
                -3.342624513802283
              ],
              [
                -42.12739689247306,
                -3.344280494754316
              ],
              [
                -42.124145453168,
                -3.352514430876851
              ],
              [
                -42.12834439591729,
                -3.35412241206491
              ],
              [
                -42.134209833678675,
                -3.356368903962672
              ],
              [
                -42.14208829954627,
                -3.359386379683563
              ],
              [
                -42.15181071874522,
                -3.387118166284582
              ],
              [
                -42.18522542928172,
                -3.392213646945825
              ],
              [
                -42.18998038193024,
                -3.395215618635565
              ],
              [
                -42.19783281634869,
                -3.405208562613861
              ],
              [
                -42.197555293675194,
                -3.415428986681754
              ],
              [
                -42.197304818156525,
                -3.424648398490218
              ],
              [
                -42.20399024386132,
                -3.435413808123349
              ],
              [
                -42.20217827750637,
                -3.428460877011374
              ],
              [
                -42.20240578254377,
                -3.428685861366362
              ],
              [
                -42.20521273932228,
                -3.431478346943038
              ],
              [
                -42.209705189557155,
                -3.43594534027959
              ],
              [
                -42.22229357731911,
                -3.434693335893467
              ],
              [
                -42.24254343328687,
                -3.432679846393338
              ],
              [
                -42.27237769212301,
                -3.444112762120803
              ],
              [
                -42.27941761293444,
                -3.447243247806552
              ],
              [
                -42.28874555951555,
                -3.451391226574742
              ],
              [
                -42.29841098148926,
                -3.452583224237111
              ],
              [
                -42.31933978556544,
                -3.436511356212275
              ],
              [
                -42.32047481056741,
                -3.435640356363624
              ],
              [
                -42.32544775106946,
                -3.434573382539674
              ],
              [
                -42.330421226569875,
                -3.433506397715094
              ],
              [
                -42.353918501565694,
                -3.448106781712326
              ],
              [
                -42.373393870665886,
                -3.452557265232802
              ],
              [
                -42.37639733630586,
                -3.451709242946039
              ],
              [
                -42.39227171657136,
                -3.447225789716525
              ],
              [
                -42.39211420359592,
                -3.455527742851341
              ],
              [
                -42.39203118554852,
                -3.45991118813363
              ],
              [
                -42.398603134622036,
                -3.472315628453431
              ],
              [
                -42.40589357501598,
                -3.474047596067767
              ],
              [
                -42.40929353875354,
                -3.474855582223521
              ],
              [
                -42.41542048935761,
                -3.472451114144268
              ],
              [
                -42.416226511898806,
                -3.464461185833469
              ],
              [
                -42.41688649206112,
                -3.457919724343696
              ],
              [
                -42.42277843223344,
                -3.453904255536466
              ],
              [
                -42.4318858752702,
                -3.454253745479952
              ],
              [
                -42.44785923166138,
                -3.477979074702783
              ],
              [
                -42.44799922347238,
                -3.478077568002993
              ],
              [
                -42.458310167912444,
                -3.485302518696108
              ],
              [
                -42.45968313268203,
                -3.485189545115202
              ],
              [
                -42.46609059827387,
                -3.484662051739881
              ],
              [
                -42.472830531445695,
                -3.48336857337463
              ],
              [
                -42.485853935573104,
                -3.470666170058238
              ],
              [
                -42.486034950537125,
                -3.467011174173336
              ],
              [
                -42.486947917340146,
                -3.448557328145844
              ],
              [
                -42.49165640188845,
                -3.448053341888602
              ],
              [
                -42.49797584428562,
                -3.447375340607803
              ],
              [
                -42.50293329688942,
                -3.454235776944841
              ],
              [
                -42.504083769817896,
                -3.455828292186266
              ],
              [
                -42.5057532739233,
                -3.473972618675321
              ],
              [
                -42.50616625775007,
                -3.478457590444838
              ],
              [
                -42.51240419807938,
                -3.482169087491809
              ],
              [
                -42.5291645575742,
                -3.492140500802327
              ],
              [
                -42.5345855178941,
                -3.500148960616703
              ],
              [
                -42.53384502371295,
                -3.508264889126732
              ],
              [
                -42.53299602793733,
                -3.517565800520355
              ],
              [
                -42.56199827971846,
                -3.555390535739382
              ],
              [
                -42.57208667237585,
                -3.569135428718267
              ],
              [
                -42.60934288112025,
                -3.585773809898388
              ],
              [
                -42.616972825881604,
                -3.61170762387669
              ],
              [
                -42.62181027231455,
                -3.621163558661242
              ],
              [
                -42.62424723436361,
                -3.625927039297851
              ],
              [
                -42.63433765656124,
                -3.631018481653956
              ],
              [
                -42.63548013296006,
                -3.631594994732144
              ],
              [
                -42.656690946204215,
                -3.653094825560935
              ],
              [
                -42.675364783942165,
                -3.675238662561088
              ],
              [
                -42.67719127254393,
                -3.681015123288769
              ],
              [
                -42.67888279294295,
                -3.685148588492569
              ],
              [
                -42.68240575144558,
                -3.693756036375043
              ],
              [
                -42.68239123760943,
                -3.703062945423095
              ],
              [
                -42.668478336482636,
                -3.728987764839114
              ],
              [
                -42.67067781421661,
                -3.74122618353339
              ],
              [
                -42.68284020221947,
                -3.763006021920983
              ],
              [
                -42.68075174203595,
                -3.770598960188806
              ],
              [
                -42.680750219045144,
                -3.770606444131132
              ],
              [
                -42.67866475683418,
                -3.778192381456726
              ],
              [
                -42.66830982527318,
                -3.790548785833698
              ],
              [
                -42.67116179766891,
                -3.793403766351117
              ],
              [
                -42.675587735585125,
                -3.797606255306688
              ],
              [
                -42.68536265747485,
                -3.806887188978927
              ],
              [
                -42.72077736041427,
                -3.875039188542136
              ],
              [
                -42.721209840197346,
                -3.878798656174025
              ],
              [
                -42.72164386496638,
                -3.882557632812399
              ],
              [
                -42.72581581467368,
                -3.90964640996052
              ],
              [
                -42.73216826415943,
                -3.917457863538883
              ],
              [
                -42.738520178646134,
                -3.925269315136156
              ],
              [
                -42.784742287792376,
                -3.957601060050556
              ],
              [
                -42.79279223745314,
                -3.980558390773373
              ],
              [
                -42.79752767251342,
                -3.996247764108353
              ],
              [
                -42.80070317144497,
                -3.996463279950985
              ],
              [
                -42.805366123167914,
                -3.996779306723678
              ],
              [
                -42.80832508031253,
                -3.993869314137245
              ],
              [
                -42.81497903391772,
                -3.987324350793766
              ],
              [
                -42.823257990156655,
                -3.991836337465563
              ],
              [
                -42.84256529330404,
                -4.037251003765848
              ],
              [
                -42.856819682354214,
                -4.076665687462953
              ],
              [
                -42.85968162723519,
                -4.084587638950622
              ],
              [
                -42.862544110105205,
                -4.092509080455194
              ],
              [
                -42.87644400740512,
                -4.109669961458407
              ],
              [
                -42.88468892964032,
                -4.112631940037606
              ],
              [
                -42.8955313292211,
                -4.116527918818331
              ],
              [
                -42.90266727438778,
                -4.123984368959857
              ],
              [
                -42.90375273658976,
                -4.132229322258519
              ],
              [
                -42.8876559000964,
                -4.146816186420327
              ],
              [
                -42.88714437705286,
                -4.147280179671812
              ],
              [
                -42.88752537306567,
                -4.1511916393393
              ],
              [
                -42.887906378076835,
                -4.15510262201253
              ],
              [
                -42.908381713502415,
                -4.15578913274308
              ],
              [
                -42.92613605723615,
                -4.156384637849543
              ],
              [
                -42.93559648659483,
                -4.16138210975437
              ],
              [
                -42.93609048130499,
                -4.161916116968094
              ],
              [
                -42.940390949956985,
                -4.166569559975665
              ],
              [
                -42.98161358638464,
                -4.214310233502705
              ],
              [
                -42.98520805732466,
                -4.22400065433477
              ],
              [
                -42.988803514246406,
                -4.233691094186483
              ],
              [
                -42.98335253517047,
                -4.287511679662238
              ],
              [
                -42.98195503634027,
                -4.294020120029707
              ],
              [
                -42.98055855849714,
                -4.300528567400474
              ],
              [
                -42.976381082837065,
                -4.30805302473935
              ],
              [
                -42.96188818006441,
                -4.334158310217875
              ],
              [
                -42.962129202188585,
                -4.343931729891851
              ],
              [
                -42.96237217028593,
                -4.353704684582133
              ],
              [
                -42.962920664519224,
                -4.376049001012047
              ],
              [
                -42.958275710982576,
                -4.382863451460249
              ],
              [
                -42.952100760955936,
                -4.385697941015063
              ],
              [
                -42.95041325234027,
                -4.386472415335234
              ],
              [
                -42.93982786895899,
                -4.384130910512185
              ],
              [
                -42.93005892182983,
                -4.381970959749518
              ],
              [
                -42.887311757067394,
                -4.411442718392769
              ],
              [
                -42.87947882080815,
                -4.419819133416168
              ],
              [
                -42.87164588054925,
                -4.428195587433008
              ],
              [
                -42.87763132574637,
                -4.449404920170902
              ],
              [
                -42.878224805812884,
                -4.451509883666628
              ],
              [
                -42.87711982371214,
                -4.457594859388207
              ],
              [
                -42.876014342611185,
                -4.463680314108825
              ],
              [
                -42.86886589187459,
                -4.46788726479709
              ],
              [
                -42.85366202755469,
                -4.476835186677155
              ],
              [
                -42.85005001693981,
                -4.480991185578073
              ],
              [
                -42.84960955733068,
                -4.485260648280282
              ],
              [
                -42.852395530494086,
                -4.495007551535617
              ],
              [
                -42.85725245670641,
                -4.498999038105436
              ],
              [
                -42.85909093588871,
                -4.500510033723266
              ],
              [
                -42.85934395874672,
                -4.504495499934388
              ],
              [
                -42.86000093598425,
                -4.514841900611316
              ],
              [
                -42.86716538307559,
                -4.525050851760644
              ],
              [
                -42.87433083415063,
                -4.535260260937005
              ],
              [
                -42.86918036014067,
                -4.556420114491144
              ],
              [
                -42.867530866054715,
                -4.563198551779076
              ],
              [
                -42.868648370388314,
                -4.569886500219918
              ],
              [
                -42.874286297041046,
                -4.581709415579847
              ],
              [
                -42.910363978323,
                -4.643253485944792
              ],
              [
                -42.91452792666194,
                -4.648153953520759
              ],
              [
                -42.918692390995695,
                -4.653053897108705
              ],
              [
                -42.94731513455548,
                -4.674546778899784
              ],
              [
                -42.954198577444906,
                -4.689095648893783
              ],
              [
                -42.949888115740656,
                -4.699967600277683
              ],
              [
                -42.94220918198575,
                -4.708193019593946
              ],
              [
                -42.93544323078502,
                -4.712730990964501
              ],
              [
                -42.922654339596434,
                -4.721306404842866
              ],
              [
                -42.920286350883686,
                -4.736038286439618
              ],
              [
                -42.92548828990625,
                -4.749642190670221
              ],
              [
                -42.94807658606951,
                -4.766493066348782
              ],
              [
                -42.94939456711229,
                -4.790324910192384
              ],
              [
                -42.94334411904857,
                -4.800215321001905
              ],
              [
                -42.93729466097099,
                -4.810105757809997
              ],
              [
                -42.92184230633348,
                -4.822498162665438
              ],
              [
                -42.897120475328556,
                -4.842323968801965
              ],
              [
                -42.89522398048345,
                -4.850082424835135
              ],
              [
                -42.895223980481774,
                -4.850085409812845
              ],
              [
                -42.89332902661925,
                -4.8578413578694
              ],
              [
                -42.89425798097314,
                -4.868592790182616
              ],
              [
                -42.8958949678916,
                -4.887541168781388
              ],
              [
                -42.89308798388504,
                -4.891002641369854
              ],
              [
                -42.890282024869876,
                -4.894464112958143
              ],
              [
                -42.88586352970732,
                -4.900279067075792
              ],
              [
                -42.86939515108601,
                -4.921955394027422
              ],
              [
                -42.867522670340634,
                -4.927331830866462
              ],
              [
                -42.8636637113475,
                -4.930071318227705
              ],
              [
                -42.85439630341498,
                -4.936649256862162
              ],
              [
                -42.860561730292446,
                -4.970995521396836
              ],
              [
                -42.86094771589738,
                -4.97314648859221
              ],
              [
                -42.860067732587005,
                -4.977508466581357
              ],
              [
                -42.85918919626266,
                -4.981870445572722
              ],
              [
                -42.841137832875226,
                -5.030419071442982
              ],
              [
                -42.84138884620646,
                -5.055479374010443
              ],
              [
                -42.83918882398358,
                -5.06271031789345
              ],
              [
                -42.836988864754694,
                -5.069941759775396
              ],
              [
                -42.8224909467713,
                -5.08641513861657
              ],
              [
                -42.81937749318278,
                -5.089952598449048
              ],
              [
                -42.81884797555634,
                -5.091587576968157
              ],
              [
                -42.81831898792611,
                -5.09322110249848
              ],
              [
                -42.81805050803033,
                -5.095079075512992
              ],
              [
                -42.81296801387723,
                -5.130226315003097
              ],
              [
                -42.80777506557233,
                -5.140053252814421
              ],
              [
                -42.80258258825761,
                -5.149878660637454
              ],
              [
                -42.79755759973231,
                -5.182847923489111
              ],
              [
                -42.79871160243712,
                -5.190924872148976
              ],
              [
                -42.79986508913786,
                -5.199002773811396
              ],
              [
                -42.81252645579286,
                -5.215010691538641
              ],
              [
                -42.82710383127562,
                -5.233440062533449
              ],
              [
                -42.826548841175914,
                -5.239457493524363
              ],
              [
                -42.82599535805909,
                -5.245475958512087
              ],
              [
                -42.82213588859294,
                -5.255476888927215
              ],
              [
                -42.8119669368254,
                -5.2818292061434
              ],
              [
                -42.81242043665868,
                -5.290747641253032
              ],
              [
                -42.812873930482624,
                -5.29966605137329
              ],
              [
                -42.82592480970062,
                -5.346573727669334
              ],
              [
                -42.83137273148201,
                -5.350773180938447
              ],
              [
                -42.831379242425555,
                -5.350778187905366
              ],
              [
                -42.83682120825888,
                -5.354973680207944
              ],
              [
                -42.83852316783735,
                -5.355754168485623
              ],
              [
                -42.864503448724896,
                -5.367663581486026
              ],
              [
                -42.869194408587774,
                -5.376884020161773
              ],
              [
                -42.87006241796175,
                -5.377720036516761
              ],
              [
                -42.88211130010703,
                -5.389317949236296
              ],
              [
                -42.89980618118506,
                -5.391102934131612
              ],
              [
                -42.904337645821414,
                -5.391560441591651
              ],
              [
                -42.90967309234985,
                -5.394469396428351
              ],
              [
                -42.912862564763316,
                -5.398453864968856
              ],
              [
                -42.91829050518304,
                -5.414686275382145
              ],
              [
                -42.92263244150098,
                -5.427671682154309
              ],
              [
                -42.92670443909348,
                -5.432591128379875
              ],
              [
                -42.93077738467647,
                -5.437511106609858
              ],
              [
                -42.94960274145713,
                -5.442670573433049
              ],
              [
                -42.96208612822196,
                -5.446092048090568
              ],
              [
                -42.96465661917905,
                -5.449297524058825
              ],
              [
                -42.967227600131444,
                -5.452503017030506
              ],
              [
                -42.97184501404273,
                -5.475110354136689
              ],
              [
                -42.972886006291226,
                -5.480206824201383
              ],
              [
                -42.97396451187395,
                -5.482721322340923
              ],
              [
                -42.97504301245578,
                -5.485236278478422
              ],
              [
                -42.99486132584426,
                -5.50656814987787
              ],
              [
                -43.00488771146188,
                -5.541531865695456
              ],
              [
                -43.02399707322034,
                -5.560274242054387
              ],
              [
                -43.025957014486096,
                -5.572205152506044
              ],
              [
                -43.027027499516315,
                -5.578720628251684
              ],
              [
                -43.03421044528394,
                -5.59188254909037
              ],
              [
                -43.048414349447356,
                -5.591587041535997
              ],
              [
                -43.064840196648575,
                -5.595656517339219
              ],
              [
                -43.08025056233237,
                -5.599474001351607
              ],
              [
                -43.089778997657774,
                -5.606963960543569
              ],
              [
                -43.09070747460172,
                -5.607693958787756
              ],
              [
                -43.09485142970648,
                -5.613920401755921
              ],
              [
                -43.09860740667259,
                -5.632816754420078
              ],
              [
                -43.09069696787136,
                -5.63338876101117
              ],
              [
                -43.09030497265719,
                -5.634020257114782
              ],
              [
                -43.08230602850271,
                -5.646901148236494
              ],
              [
                -43.087667463036354,
                -5.682818403084243
              ],
              [
                -43.077154515698396,
                -5.731244029771548
              ],
              [
                -43.07978199435679,
                -5.741549952948859
              ],
              [
                -43.08792393580005,
                -5.741654956640668
              ],
              [
                -43.09438836596159,
                -5.741738485365415
              ],
              [
                -43.096622869309044,
                -5.745338461473335
              ],
              [
                -43.1009412957995,
                -5.765102799518622
              ],
              [
                -43.09764181112482,
                -5.792747081757443
              ],
              [
                -43.09447435349958,
                -5.799393531928856
              ],
              [
                -43.091307369866605,
                -5.806040485097544
              ],
              [
                -43.09625231053556,
                -5.829572338151823
              ],
              [
                -43.07721394004729,
                -5.864768070528753
              ],
              [
                -43.07779445251913,
                -5.867382042796654
              ],
              [
                -43.07837393499998,
                -5.869994009079669
              ],
              [
                -43.07919841742615,
                -5.871221020666236
              ],
              [
                -43.09902873023678,
                -5.900712309587798
              ],
              [
                -43.09464324864824,
                -5.910016246537701
              ],
              [
                -43.09419128485764,
                -5.910715742111564
              ],
              [
                -43.09320178188504,
                -5.912245707242652
              ],
              [
                -43.08727582996648,
                -5.921411172140357
              ],
              [
                -43.086900305472014,
                -5.93380558328063
              ],
              [
                -43.0865033286309,
                -5.946926963111304
              ],
              [
                -43.08126933673938,
                -5.96083187686923
              ],
              [
                -43.072133402822764,
                -5.974576275084127
              ],
              [
                -43.071028905331495,
                -5.975317270899867
              ],
              [
                -43.059306503645395,
                -5.98317917327642
              ],
              [
                -43.05507005728795,
                -5.986019672563035
              ],
              [
                -43.04936107881113,
                -5.993384101765107
              ],
              [
                -43.04954809090741,
                -6.001079545676128
              ],
              [
                -43.04983105245782,
                -6.01270947292486
              ],
              [
                -43.054109509889635,
                -6.023547399783715
              ],
              [
                -43.058748997121285,
                -6.027605380418664
              ],
              [
                -43.07494583179521,
                -6.0417727624242
              ],
              [
                -43.07459482679905,
                -6.054348180420179
              ],
              [
                -43.06259492135183,
                -6.06781905464906
              ],
              [
                -43.05964346613222,
                -6.071132067387674
              ],
              [
                -43.05774246989272,
                -6.076470028098293
              ],
              [
                -43.049586505322,
                -6.099368328280836
              ],
              [
                -43.04164557912143,
                -6.105168282324833
              ],
              [
                -43.03370562591774,
                -6.110968232363901
              ],
              [
                -43.01094132233613,
                -6.110631730591161
              ],
              [
                -42.99262548738123,
                -6.117372167349457
              ],
              [
                -42.992387486138874,
                -6.117638663234988
              ],
              [
                -42.98213004889955,
                -6.129111078194323
              ],
              [
                -42.97505907755207,
                -6.167161304536006
              ],
              [
                -42.97162611180623,
                -6.172518253997682
              ],
              [
                -42.968193649055145,
                -6.177874720462719
              ],
              [
                -42.9514747551408,
                -6.187318115547194
              ],
              [
                -42.94249284176691,
                -6.189989626587315
              ],
              [
                -42.93351089640264,
                -6.192661089624366
              ],
              [
                -42.92264048920187,
                -6.205482975243694
              ],
              [
                -42.916246530138885,
                -6.213024940605816
              ],
              [
                -42.90560963213572,
                -6.218774392969747
              ],
              [
                -42.89497318613895,
                -6.224525327314895
              ],
              [
                -42.88436327991961,
                -6.226151295768809
              ],
              [
                -42.87398385684983,
                -6.227741780646628
              ],
              [
                -42.87036387916077,
                -6.231011748161142
              ],
              [
                -42.86674294147987,
                -6.234282232670006
              ],
              [
                -42.854830499455915,
                -6.253182099105194
              ],
              [
                -42.85500051335714,
                -6.262412039884302
              ],
              [
                -42.85507950557839,
                -6.266717497531481
              ],
              [
                -42.850518012948925,
                -6.275011422703336
              ],
              [
                -42.84661155581171,
                -6.282113385047825
              ],
              [
                -42.84898202273064,
                -6.298055248513682
              ],
              [
                -42.85030751533789,
                -6.306969210488908
              ],
              [
                -42.8598003994682,
                -6.330164545392904
              ],
              [
                -42.85432597407518,
                -6.333279494665656
              ],
              [
                -42.84904851618769,
                -6.330793533898838
              ],
              [
                -42.83668960884307,
                -6.324970046260155
              ],
              [
                -42.82894316652644,
                -6.337040962567531
              ],
              [
                -42.83147762280496,
                -6.342379444521099
              ],
              [
                -42.85197546060307,
                -6.358727837523317
              ],
              [
                -42.85378844103738,
                -6.363730782422875
              ],
              [
                -42.85613541599951,
                -6.370206753995617
              ],
              [
                -42.85670341367433,
                -6.379212193840719
              ],
              [
                -42.85736438747979,
                -6.38969611900269
              ],
              [
                -42.86938280749924,
                -6.406396503393225
              ],
              [
                -42.87736221080327,
                -6.417484413674059
              ],
              [
                -42.877096208919625,
                -6.422933373840915
              ],
              [
                -42.876829726036476,
                -6.428382346010684
              ],
              [
                -42.874821729280086,
                -6.434033773408689
              ],
              [
                -42.86916577475105,
                -6.44995219441873
              ],
              [
                -42.86992975251537,
                -6.457913125111693
              ],
              [
                -42.87069373827346,
                -6.465872076828273
              ],
              [
                -42.867413262182644,
                -6.48447641914324
              ],
              [
                -42.87347268165399,
                -6.488228916444794
              ],
              [
                -42.88284063644871,
                -6.494028843378482
              ],
              [
                -42.883272621335344,
                -6.496109338592192
              ],
              [
                -42.88511310655183,
                -6.504962791679849
              ],
              [
                -42.884721574062006,
                -6.508533268453907
              ],
              [
                -42.87937811596378,
                -6.509750249571727
              ],
              [
                -42.871432190767095,
                -6.511559218419179
              ],
              [
                -42.88905402109043,
                -6.538124524908403
              ],
              [
                -42.88421056107363,
                -6.560107873690451
              ],
              [
                -42.884681047195436,
                -6.56668532532789
              ],
              [
                -42.885152031308294,
                -6.573262278975469
              ],
              [
                -42.903714372694,
                -6.597267623117273
              ],
              [
                -42.91683124660953,
                -6.63692183589141
              ],
              [
                -42.91470174521407,
                -6.644499285368992
              ],
              [
                -42.91391875748073,
                -6.647283769599173
              ],
              [
                -42.91602471946076,
                -6.656005719074119
              ],
              [
                -42.919385186113054,
                -6.669923606924925
              ],
              [
                -42.949327949718864,
                -6.704499898095564
              ],
              [
                -42.978641673644155,
                -6.718374314725442
              ],
              [
                -42.99096955470525,
                -6.745053625691658
              ],
              [
                -42.995872498990465,
                -6.749564577977551
              ],
              [
                -43.00077597927058,
                -6.754076067268104
              ],
              [
                -43.026286754224905,
                -6.760563525548348
              ],
              [
                -43.03300519386499,
                -6.762272524517102
              ],
              [
                -43.048277081702714,
                -6.759908551612279
              ],
              [
                -43.05844850562879,
                -6.753340608998283
              ],
              [
                -43.06539045234887,
                -6.748858166793003
              ],
              [
                -43.07355690797928,
                -6.748276650419002
              ],
              [
                -43.0817223176257,
                -6.747695686040159
              ],
              [
                -43.10472161957201,
                -6.770363017830902
              ],
              [
                -43.130419379292405,
                -6.780614460341995
              ],
              [
                -43.14563828019868,
                -6.782716475273476
              ],
              [
                -43.152276200193,
                -6.782393986858355
              ],
              [
                -43.16834007424816,
                -6.769348090479595
              ],
              [
                -43.20350583034067,
                -6.763804139211754
              ],
              [
                -43.244301472513044,
                -6.765967147974874
              ],
              [
                -43.2470234774142,
                -6.767075654164457
              ],
              [
                -43.249744417324706,
                -6.768184146354253
              ],
              [
                -43.25486388512983,
                -6.774995585463084
              ],
              [
                -43.262571837066396,
                -6.785250039908158
              ],
              [
                -43.27045124539226,
                -6.790740485737887
              ],
              [
                -43.27833166971198,
                -6.796230965583027
              ],
              [
                -43.30692593404021,
                -6.800495440765266
              ],
              [
                -43.34858759057843,
                -6.797693501110778
              ],
              [
                -43.35614155551824,
                -6.808309950010654
              ],
              [
                -43.36369648043993,
                -6.818927352938131
              ],
              [
                -43.37358388741653,
                -6.818238885791346
              ],
              [
                -43.38747678899431,
                -6.817271888849045
              ],
              [
                -43.38973174447044,
                -6.827431339867293
              ],
              [
                -43.3911937402669,
                -6.834018298855385
              ],
              [
                -43.39908368591906,
                -6.832865277450119
              ],
              [
                -43.403048631847746,
                -6.836314751930962
              ],
              [
                -43.40875159940336,
                -6.841275746991464
              ],
              [
                -43.41964400194257,
                -6.843448233200289
              ],
              [
                -43.4349083852878,
                -6.840171257937658
              ],
              [
                -43.44396380013068,
                -6.838227285135027
              ],
              [
                -43.45165523182916,
                -6.842808274545572
              ],
              [
                -43.454093221813565,
                -6.844260747116885
              ],
              [
                -43.45690771055285,
                -6.845937728386533
              ],
              [
                -43.48155701400814,
                -6.834714325513826
              ],
              [
                -43.4892979500833,
                -6.819736431869977
              ],
              [
                -43.48971893431368,
                -6.818921441037858
              ],
              [
                -43.49489989522794,
                -6.808887510973999
              ],
              [
                -43.50649332104548,
                -6.800021606690493
              ],
              [
                -43.53341061967252,
                -6.791920668188046
              ],
              [
                -43.53825809143586,
                -6.790461715501796
              ],
              [
                -43.54207056655572,
                -6.786579211317045
              ],
              [
                -43.545882540679976,
                -6.782696738130318
              ],
              [
                -43.54649050794709,
                -6.779936781353755
              ],
              [
                -43.550436990194086,
                -6.762012387705591
              ],
              [
                -43.56044640979376,
                -6.749547016849376
              ],
              [
                -43.57118835119064,
                -6.74870153344827
              ],
              [
                -43.57264283929169,
                -6.751314989914514
              ],
              [
                -43.57345931048975,
                -6.752781477076867
              ],
              [
                -43.57526481623475,
                -6.756025969313002
              ],
              [
                -43.58398023757965,
                -6.757099968580979
              ],
              [
                -43.59853163439069,
                -6.743307076790142
              ],
              [
                -43.621168942498144,
                -6.734627144853726
              ],
              [
                -43.62914140292625,
                -6.726901711414583
              ],
              [
                -43.637115314344605,
                -6.719176764967075
              ],
              [
                -43.672321045247536,
                -6.705881380916297
              ],
              [
                -43.69814183223434,
                -6.709165377934731
              ],
              [
                -43.702186831253556,
                -6.704356430491215
              ],
              [
                -43.70563379255773,
                -6.700257965497534
              ],
              [
                -43.71562069774578,
                -6.698806966776594
              ],
              [
                -43.72831510839914,
                -6.704416928785981
              ],
              [
                -43.75355239537192,
                -6.702553959111985
              ],
              [
                -43.76033782679735,
                -6.702053958065838
              ],
              [
                -43.765911803720826,
                -6.706991938258048
              ],
              [
                -43.76778578922128,
                -6.708651946903778
              ],
              [
                -43.77444473359195,
                -6.714550891117909
              ],
              [
                -43.78192216251882,
                -6.710168456322307
              ],
              [
                -43.79498556982182,
                -6.702512993303344
              ],
              [
                -43.80237497915763,
                -6.712363445936909
              ],
              [
                -43.806821960433716,
                -6.718290917219497
              ],
              [
                -43.81972884009186,
                -6.726892335203186
              ],
              [
                -43.8533605472693,
                -6.735228322675218
              ],
              [
                -43.87947634330686,
                -6.75732816928072
              ],
              [
                -43.91339606309688,
                -6.752277244420771
              ],
              [
                -43.91851702518881,
                -6.755407203272323
              ],
              [
                -43.92417746495208,
                -6.764717649624013
              ],
              [
                -43.92805245350333,
                -6.771090603440057
              ],
              [
                -43.93346438895798,
                -6.7708506055008
              ],
              [
                -43.94470180975901,
                -6.765126146223081
              ],
              [
                -43.948113273095224,
                -6.754059243615457
              ],
              [
                -43.94894525954203,
                -6.751359768469803
              ],
              [
                -43.9608107085901,
                -6.741513362971387
              ],
              [
                -43.9703336158057,
                -6.743837834088787
              ],
              [
                -43.98733394602152,
                -6.756894268986101
              ],
              [
                -44.03299308693484,
                -6.76001976753117
              ],
              [
                -44.04249750596109,
                -6.769051198107122
              ],
              [
                -44.05296945238238,
                -6.767926212531556
              ],
              [
                -44.059832350738134,
                -6.784362114512691
              ],
              [
                -44.05975836512034,
                -6.785863116810342
              ],
              [
                -44.05866034598884,
                -6.808053930612916
              ],
              [
                -44.06929677866954,
                -6.820993366559683
              ],
              [
                -44.07658568708285,
                -6.820742853260121
              ],
              [
                -44.084002134315575,
                -6.809620944074553
              ],
              [
                -44.088472624711585,
                -6.802916516199938
              ],
              [
                -44.109598461278814,
                -6.80305101238646
              ],
              [
                -44.11392841105818,
                -6.804673007414062
              ],
              [
                -44.1150099179667,
                -6.810053984189397
              ],
              [
                -44.11354189039539,
                -6.817925428264319
              ],
              [
                -44.11234040669102,
                -6.819631893205529
              ],
              [
                -44.09830999255551,
                -6.839559248703563
              ],
              [
                -44.10283096931557,
                -6.852757643977667
              ],
              [
                -44.10860990106467,
                -6.857227620071921
              ],
              [
                -44.11377737128883,
                -6.852484167859724
              ],
              [
                -44.11439940167193,
                -6.843438716374207
              ],
              [
                -44.114775393110826,
                -6.83796725740206
              ],
              [
                -44.11949836558135,
                -6.832404310623868
              ],
              [
                -44.12782127107522,
                -6.84149528008272
              ],
              [
                -44.13633218534657,
                -6.858770636839642
              ],
              [
                -44.13877214962304,
                -6.863723603811962
              ],
              [
                -44.149633578536324,
                -6.868069068989358
              ],
              [
                -44.1604940054679,
                -6.872414587181156
              ],
              [
                -44.17410037078334,
                -6.893166915739728
              ],
              [
                -44.17462233417675,
                -6.896080399591141
              ],
              [
                -44.175143332577285,
                -6.898993371446805
              ],
              [
                -44.17313584311873,
                -6.923950192567212
              ],
              [
                -44.17563181805346,
                -6.927522703412277
              ],
              [
                -44.185265262340856,
                -6.926458701893046
              ],
              [
                -44.19247020397392,
                -6.925663195468844
              ],
              [
                -44.20102560071843,
                -6.932129151926302
              ],
              [
                -44.20560906807985,
                -6.963073956472947
              ],
              [
                -44.235499808949925,
                -6.998349715826662
              ],
              [
                -44.24847120755451,
                -6.998343738724731
              ],
              [
                -44.25604212263212,
                -6.998340262086754
              ],
              [
                -44.26158058706902,
                -6.998337763741144
              ],
              [
                -44.263935534427105,
                -7.017022599199448
              ],
              [
                -44.274491456720135,
                -7.031009551660768
              ],
              [
                -44.2784009119334,
                -7.045625923153273
              ],
              [
                -44.27466242631765,
                -7.061815315164938
              ],
              [
                -44.27386741879965,
                -7.065256277301795
              ],
              [
                -44.27481443002144,
                -7.068983774898675
              ],
              [
                -44.30579713111602,
                -7.116782450426022
              ],
              [
                -44.31549706440349,
                -7.11760494191934
              ],
              [
                -44.32255198692717,
                -7.113795005671954
              ],
              [
                -44.33264593142468,
                -7.108343050532525
              ],
              [
                -44.35714123106617,
                -7.11824799592079
              ],
              [
                -44.36365167549363,
                -7.120880977011248
              ],
              [
                -44.371641611250745,
                -7.119758491702603
              ],
              [
                -44.37963205801115,
                -7.118636523389885
              ],
              [
                -44.41182229225809,
                -7.135199900832125
              ],
              [
                -44.43729754940216,
                -7.1553387716603
              ],
              [
                -44.45047992303045,
                -7.158307284212431
              ],
              [
                -44.46366133268588,
                -7.161274762784456
              ],
              [
                -44.48582362711668,
                -7.176042679593308
              ],
              [
                -44.48889360685287,
                -7.180247168849005
              ],
              [
                -44.490486083190255,
                -7.182429151971129
              ],
              [
                -44.49384606967786,
                -7.183076120349521
              ],
              [
                -44.50043552672594,
                -7.184344152176962
              ],
              [
                -44.51427790863536,
                -7.195970574909953
              ],
              [
                -44.523275823016014,
                -7.217223922285467
              ],
              [
                -44.52463981327606,
                -7.22044390298985
              ],
              [
                -44.52609129491841,
                -7.221269910484006
              ],
              [
                -44.52754176356994,
                -7.222094880984931
              ],
              [
                -44.55847800980966,
                -7.226419882689727
              ],
              [
                -44.56107001172107,
                -7.226781888418502
              ],
              [
                -44.56366300162537,
                -7.22714387114867
              ],
              [
                -44.56752544979922,
                -7.236165315588268
              ],
              [
                -44.5725443843342,
                -7.247887262227073
              ],
              [
                -44.579345825663964,
                -7.257220670102803
              ],
              [
                -44.58463727392141,
                -7.261039166114769
              ],
              [
                -44.59519071069375,
                -7.268654624284378
              ],
              [
                -44.59570569563018,
                -7.276553080714187
              ],
              [
                -44.61632648018749,
                -7.307511349281959
              ],
              [
                -44.639861286592016,
                -7.314460849665031
              ],
              [
                -44.643177779588186,
                -7.320622802734016
              ],
              [
                -44.64580724821382,
                -7.325505280102671
              ],
              [
                -44.65250617791493,
                -7.326264750738068
              ],
              [
                -44.655949188009544,
                -7.326654762067789
              ],
              [
                -44.66294511879026,
                -7.33141925113018
              ],
              [
                -44.67052204517647,
                -7.349405128851495
              ],
              [
                -44.671646506803945,
                -7.352073593309775
              ],
              [
                -44.67512098611719,
                -7.355311591898019
              ],
              [
                -44.67859544842994,
                -7.358550070487117
              ],
              [
                -44.679445971619735,
                -7.361922531819923
              ],
              [
                -44.68757235781511,
                -7.394145318348715
              ],
              [
                -44.69990625535808,
                -7.395009319357161
              ],
              [
                -44.70074923008377,
                -7.394484842748313
              ],
              [
                -44.704336221385226,
                -7.392254363424063
              ],
              [
                -44.706687712099736,
                -7.39390132709558
              ],
              [
                -44.71181267188789,
                -7.397492330758765
              ],
              [
                -44.72948753665609,
                -7.390652401976507
              ],
              [
                -44.74256743483418,
                -7.361833078527263
              ],
              [
                -44.78054763099138,
                -7.377830003421421
              ],
              [
                -44.78345461778442,
                -7.374454525407895
              ],
              [
                -44.78636259656906,
                -7.371079579390827
              ],
              [
                -44.81632433642518,
                -7.360815166206167
              ],
              [
                -44.81920180430069,
                -7.368810605377554
              ],
              [
                -44.82207930716948,
                -7.376804549573031
              ],
              [
                -44.83119270253123,
                -7.380010541505627
              ],
              [
                -44.848081579380825,
                -7.38595052047109
              ],
              [
                -44.853649013885885,
                -7.396038953632512
              ],
              [
                -44.85266051916101,
                -7.400061426913731
              ],
              [
                -44.85226951785215,
                -7.401651894558628
              ],
              [
                -44.86259846310531,
                -7.410885852938007
              ],
              [
                -44.86853487980798,
                -7.421895785126094
              ],
              [
                -44.88226928946247,
                -7.427162245063633
              ],
              [
                -44.89196118422328,
                -7.424854268733262
              ],
              [
                -44.896226176116755,
                -7.423839305583686
              ],
              [
                -44.90098562852228,
                -7.425644765387564
              ],
              [
                -44.909464546318624,
                -7.442872666001375
              ],
              [
                -44.919065946450516,
                -7.453132589812478
              ],
              [
                -44.92024144447938,
                -7.457098573491708
              ],
              [
                -44.92403439360763,
                -7.469900479550055
              ],
              [
                -44.92804288665028,
                -7.470992968639506
              ],
              [
                -44.936820291903906,
                -7.469630008993783
              ],
              [
                -44.957046137487815,
                -7.47532796801004
              ],
              [
                -44.968763038083516,
                -7.48303894946284
              ],
              [
                -44.975650481684546,
                -7.482506432386638
              ],
              [
                -44.975980491077706,
                -7.482481437858558
              ],
              [
                -44.977687477596454,
                -7.482349457318072
              ],
              [
                -44.97796395241311,
                -7.482327930717474
              ],
              [
                -44.98315491241805,
                -7.481927468190808
              ],
              [
                -44.9883168882744,
                -7.481946965743129
              ],
              [
                -44.99722832180815,
                -7.490141930361887
              ],
              [
                -44.997488805514614,
                -7.490381394948613
              ],
              [
                -45.00399274824671,
                -7.496361363663213
              ],
              [
                -45.01081068746832,
                -7.495787391821655
              ],
              [
                -45.01108918827175,
                -7.495763893236965
              ],
              [
                -45.01818561630323,
                -7.495166395810825
              ],
              [
                -45.04441189820013,
                -7.510786301167536
              ],
              [
                -45.04450993036665,
                -7.510844302857799
              ],
              [
                -45.04601490380524,
                -7.510387308376241
              ],
              [
                -45.06896423654077,
                -7.50341739027392
              ],
              [
                -45.076237144178094,
                -7.50286390571524
              ],
              [
                -45.076466652367664,
                -7.502846894041604
              ],
              [
                -45.07671315542331,
                -7.502828377393824
              ],
              [
                -45.084461607317486,
                -7.502238899516435
              ],
              [
                -45.12608978280546,
                -7.513629848214668
              ],
              [
                -45.12634026065201,
                -7.513806372230609
              ],
              [
                -45.126490770357584,
                -7.513912857636432
              ],
              [
                -45.136746661172396,
                -7.521153802266244
              ],
              [
                -45.14571110842506,
                -7.520599807278355
              ],
              [
                -45.14600258412489,
                -7.520581818668745
              ],
              [
                -45.14615761290144,
                -7.520572339875812
              ],
              [
                -45.15504051917808,
                -7.51309138339032
              ],
              [
                -45.174020878229555,
                -7.528457812257331
              ],
              [
                -45.17857281240867,
                -7.535683749835755
              ],
              [
                -45.178597845173364,
                -7.535723261587561
              ],
              [
                -45.18317427111621,
                -7.542988715932217
              ],
              [
                -45.20386612300559,
                -7.551389156334101
              ],
              [
                -45.214857020623064,
                -7.565550589360711
              ],
              [
                -45.21773848497509,
                -7.567459053936466
              ],
              [
                -45.22231844943155,
                -7.565493064636382
              ],
              [
                -45.22263546295514,
                -7.560049635240207
              ],
              [
                -45.225501958975876,
                -7.557047122463199
              ],
              [
                -45.23267390098977,
                -7.549533690571385
              ],
              [
                -45.2518402391066,
                -7.560080129956105
              ],
              [
                -45.2563971671348,
                -7.565270598602281
              ],
              [
                -45.26095462715655,
                -7.570461557255115
              ],
              [
                -45.27562304336044,
                -7.567675123884405
              ],
              [
                -45.284654949305484,
                -7.565959122974998
              ],
              [
                -45.29029989523623,
                -7.569510597876573
              ],
              [
                -45.295944845167526,
                -7.57306309377818
              ],
              [
                -45.305777801691995,
                -7.573599582199161
              ],
              [
                -45.32105364880005,
                -7.574433605623115
              ],
              [
                -45.32144515367634,
                -7.574455110838227
              ],
              [
                -45.322662154966615,
                -7.574521613509272
              ],
              [
                -45.339353992698705,
                -7.579855084483153
              ],
              [
                -45.34113998725303,
                -7.586614014923306
              ],
              [
                -45.34120949545581,
                -7.586878556179078
              ],
              [
                -45.342849969668585,
                -7.59308898324283
              ],
              [
                -45.37323119130374,
                -7.618392837596052
              ],
              [
                -45.380969639905764,
                -7.613151907592987
              ],
              [
                -45.381132634718,
                -7.613041403499348
              ],
              [
                -45.38159413235476,
                -7.612728878063196
              ],
              [
                -45.39366254487079,
                -7.612620909036854
              ],
              [
                -45.39824099380769,
                -7.623094821803077
              ],
              [
                -45.39846098504315,
                -7.632719288321861
              ],
              [
                -45.398471989521205,
                -7.633182773169148
              ],
              [
                -45.398619478278675,
                -7.639655226139777
              ],
              [
                -45.40366845854675,
                -7.643646179629857
              ],
              [
                -45.40635343505035,
                -7.643896181435164
              ],
              [
                -45.40670790621292,
                -7.643928686544894
              ],
              [
                -45.41526187673481,
                -7.644724704115792
              ],
              [
                -45.414274864221355,
                -7.651454660290865
              ],
              [
                -45.41424237226935,
                -7.65167716974292
              ],
              [
                -45.41386937882521,
                -7.654225127016747
              ],
              [
                -45.41740683910529,
                -7.658168115442402
              ],
              [
                -45.424906769336026,
                -7.659646606391203
              ],
              [
                -45.42535478170549,
                -7.65973561220438
              ],
              [
                -45.432332209176785,
                -7.661110090376512
              ],
              [
                -45.43665315515344,
                -7.66728504734165
              ],
              [
                -45.43648067679666,
                -7.668050571962467
              ],
              [
                -45.43643367496967,
                -7.668261545480515
              ],
              [
                -45.43484217389481,
                -7.675332010801048
              ],
              [
                -45.43952714966055,
                -7.677609983681189
              ],
              [
                -45.4453706099319,
                -7.674941028357915
              ],
              [
                -45.44557610039435,
                -7.674847036191407
              ],
              [
                -45.455583519514725,
                -7.670276064735509
              ],
              [
                -45.45563203206009,
                -7.670351053270189
              ],
              [
                -45.46032297510659,
                -7.677588997374212
              ],
              [
                -45.452041027305135,
                -7.682177994327512
              ],
              [
                -45.451804022056855,
                -7.68230898821228
              ],
              [
                -45.44889653254278,
                -7.68392046650001
              ],
              [
                -45.45665348528523,
                -7.689071412717913
              ],
              [
                -45.4569550007105,
                -7.689270930643051
              ],
              [
                -45.45712249039225,
                -7.689263431851842
              ],
              [
                -45.46849840287718,
                -7.688739441132912
              ],
              [
                -45.46833288087824,
                -7.704896844980032
              ],
              [
                -45.468328402483046,
                -7.70535085188547
              ],
              [
                -45.468250867940945,
                -7.712890763493309
              ],
              [
                -45.47423084797267,
                -7.713581292444015
              ],
              [
                -45.47459083908523,
                -7.713622777501871
              ],
              [
                -45.47970578305946,
                -7.714213276317479
              ],
              [
                -45.486482698734996,
                -7.72863766860009
              ],
              [
                -45.48312373574192,
                -7.737630143256953
              ],
              [
                -45.48292925378496,
                -7.738150105536366
              ],
              [
                -45.48109074863782,
                -7.743071604323167
              ],
              [
                -45.48182674928666,
                -7.750962536363653
              ],
              [
                -45.48615670547357,
                -7.750500549616607
              ],
              [
                -45.486248724755185,
                -7.750490553771991
              ],
              [
                -45.486417219666194,
                -7.750235048669147
              ],
              [
                -45.489467665951956,
                -7.745607577027576
              ],
              [
                -45.496077647564356,
                -7.749907559069871
              ],
              [
                -45.48868015807592,
                -7.758285506089598
              ],
              [
                -45.488430200782346,
                -7.758568971925087
              ],
              [
                -45.484286707074745,
                -7.763261948083875
              ],
              [
                -45.49241511386506,
                -7.776747842168991
              ],
              [
                -45.48953015368073,
                -7.786142800584099
              ],
              [
                -45.4934701214708,
                -7.788271305883145
              ],
              [
                -45.49643060132196,
                -7.786948292695975
              ],
              [
                -45.49672409812601,
                -7.786817306865819
              ],
              [
                -45.50022703991972,
                -7.785252323839265
              ],
              [
                -45.49913155018264,
                -7.792964768406884
              ],
              [
                -45.49905455527122,
                -7.793506760652098
              ],
              [
                -45.49847157594376,
                -7.797607237245557
              ],
              [
                -45.501489552011144,
                -7.798711241632201
              ],
              [
                -45.50188755072327,
                -7.798856745024482
              ],
              [
                -45.51049298663488,
                -7.802005204873162
              ],
              [
                -45.50982795458882,
                -7.815829611386372
              ],
              [
                -45.50979746321346,
                -7.816469592013073
              ],
              [
                -45.509631450205696,
                -7.819915596463771
              ],
              [
                -45.515369884308164,
                -7.833921501922602
              ],
              [
                -45.518041865791744,
                -7.835355484759758
              ],
              [
                -45.51844235341638,
                -7.835570982682412
              ],
              [
                -45.52598680884355,
                -7.839619483468305
              ],
              [
                -45.52470781335702,
                -7.854708379903353
              ],
              [
                -45.52463982911827,
                -7.855509862402853
              ],
              [
                -45.52455282380997,
                -7.856538344344851
              ],
              [
                -45.5250598015533,
                -7.856793837100717
              ],
              [
                -45.52545581222836,
                -7.856993365129269
              ],
              [
                -45.53486522622663,
                -7.86173784002981
              ],
              [
                -45.53995817846822,
                -7.864306295530299
              ],
              [
                -45.533852718748946,
                -7.875630724882572
              ],
              [
                -45.53362922811394,
                -7.876044715860946
              ],
              [
                -45.53325723285378,
                -7.876252247095104
              ],
              [
                -45.53199174617254,
                -7.876960238074395
              ],
              [
                -45.531712236231265,
                -7.877116216747249
              ],
              [
                -45.52162082155293,
                -7.882757684760441
              ],
              [
                -45.52042930218124,
                -7.887689143183836
              ],
              [
                -45.532306198056936,
                -7.890975610400946
              ],
              [
                -45.53263970733015,
                -7.89106764110009
              ],
              [
                -45.53947567243537,
                -7.892959118900881
              ],
              [
                -45.542603132462894,
                -7.897317094407443
              ],
              [
                -45.53306617757987,
                -7.902748042378869
              ],
              [
                -45.53274270695839,
                -7.902932047818944
              ],
              [
                -45.52378626881616,
                -7.908032513584667
              ],
              [
                -45.52321677264293,
                -7.91180197449632
              ],
              [
                -45.53165871412074,
                -7.922816897086404
              ],
              [
                -45.53450018513008,
                -7.921302423102306
              ],
              [
                -45.53476966013933,
                -7.921158913335888
              ],
              [
                -45.53878266049652,
                -7.919019442721051
              ],
              [
                -45.54346762293696,
                -7.920569930773027
              ],
              [
                -45.54092960213444,
                -7.928619387808723
              ],
              [
                -45.54416758706858,
                -7.939306300618287
              ],
              [
                -45.547039559883245,
                -7.94693376579647
              ],
              [
                -45.55573747625194,
                -7.953926715964597
              ],
              [
                -45.54936951098534,
                -7.957607177851199
              ],
              [
                -45.54907802512456,
                -7.957775675426734
              ],
              [
                -45.5446640515202,
                -7.960327171855885
              ],
              [
                -45.54826553162845,
                -7.968886613471004
              ],
              [
                -45.55215100184833,
                -7.970978586119819
              ],
              [
                -45.552528966659644,
                -7.971182109112922
              ],
              [
                -45.556438944674554,
                -7.97328659870417
              ],
              [
                -45.566669361676595,
                -7.996735931198902
              ],
              [
                -45.559605398733254,
                -8.001585912488215
              ],
              [
                -45.559320415792826,
                -8.001781888883913
              ],
              [
                -45.550764976635776,
                -8.007655838782915
              ],
              [
                -45.5494174849235,
                -8.014094823940605
              ],
              [
                -45.553740949017325,
                -8.016841303630994
              ],
              [
                -45.562532859134194,
                -8.014180834252647
              ],
              [
                -45.5747327705338,
                -8.027458232569195
              ],
              [
                -45.57587076630934,
                -8.02869721132301
              ],
              [
                -45.575347754786144,
                -8.02936023733022
              ],
              [
                -45.56101585307825,
                -8.047507095651325
              ],
              [
                -45.5673162845388,
                -8.05326357500674
              ],
              [
                -45.56745528629844,
                -8.056421034828242
              ],
              [
                -45.567498286978704,
                -8.05740552022486
              ],
              [
                -45.56797675636463,
                -8.06828095929012
              ],
              [
                -45.56803828848973,
                -8.069673925949761
              ],
              [
                -45.56820327343289,
                -8.073437407714374
              ],
              [
                -45.56985776165425,
                -8.076141417106198
              ],
              [
                -45.57046974381738,
                -8.077141917961356
              ],
              [
                -45.58314614488745,
                -8.097868778724528
              ],
              [
                -45.57864865008355,
                -8.106218712946244
              ],
              [
                -45.578431658395374,
                -8.106621725012799
              ],
              [
                -45.57469467698829,
                -8.113560152509015
              ],
              [
                -45.582709090908125,
                -8.12523659177781
              ],
              [
                -45.58311412811299,
                -8.125827069201799
              ],
              [
                -45.585019575260546,
                -8.128603043392669
              ],
              [
                -45.58617658350057,
                -8.13617700150669
              ],
              [
                -45.58498958634959,
                -8.140685502941913
              ],
              [
                -45.584766089262814,
                -8.141533970001635
              ],
              [
                -45.58104958042775,
                -8.155651862169291
              ],
              [
                -45.59086252436477,
                -8.17297077035981
              ],
              [
                -45.594552978422364,
                -8.173729789951485
              ],
              [
                -45.59494446324618,
                -8.173810256802522
              ],
              [
                -45.599140913201026,
                -8.17467274720654
              ],
              [
                -45.606498375290776,
                -8.185302688045756
              ],
              [
                -45.610717330638444,
                -8.186592679604928
              ],
              [
                -45.61244779412781,
                -8.194325144317176
              ],
              [
                -45.61190281223899,
                -8.19550515582991
              ],
              [
                -45.611791332876315,
                -8.195745623099855
              ],
              [
                -45.60772384708809,
                -8.204557588722265
              ],
              [
                -45.65971738259209,
                -8.248424809415786
              ],
              [
                -45.66113789314746,
                -8.249623305807692
              ],
              [
                -45.661360857472566,
                -8.252474805886447
              ],
              [
                -45.66414179902977,
                -8.28808505168917
              ],
              [
                -45.66969878754389,
                -8.295476531762707
              ],
              [
                -45.67014377542072,
                -8.296068528245215
              ],
              [
                -45.676147201793604,
                -8.30405296681673
              ],
              [
                -45.67773368895823,
                -8.316003401303929
              ],
              [
                -45.67791069418702,
                -8.31733586655137
              ],
              [
                -45.67858966855308,
                -8.322448836968045
              ],
              [
                -45.6819581322555,
                -8.327967824420728
              ],
              [
                -45.682837130827096,
                -8.329407817669201
              ],
              [
                -45.68708457209615,
                -8.33636677038422
              ],
              [
                -45.68709457313883,
                -8.342053729288658
              ],
              [
                -45.68709606210589,
                -8.343041232673789
              ],
              [
                -45.68711657240594,
                -8.355156150533237
              ],
              [
                -45.69119552345383,
                -8.36262460270276
              ],
              [
                -45.69373551118068,
                -8.363796091469299
              ],
              [
                -45.69409052320715,
                -8.363960067736308
              ],
              [
                -45.703078441935446,
                -8.368105056228783
              ],
              [
                -45.69992493151673,
                -8.380930964120079
              ],
              [
                -45.70519988534536,
                -8.387200931593775
              ],
              [
                -45.71103185485306,
                -8.387628953745482
              ],
              [
                -45.72114977035415,
                -8.39398990063056
              ],
              [
                -45.71881277559903,
                -8.402823844125157
              ],
              [
                -45.73022265899131,
                -8.41196729178094
              ],
              [
                -45.73131266696916,
                -8.415241266020018
              ],
              [
                -45.73238415151777,
                -8.420021734171373
              ],
              [
                -45.731299645558416,
                -8.422432729930774
              ],
              [
                -45.72813717916852,
                -8.429465193644356
              ],
              [
                -45.73588259348692,
                -8.441445112622334
              ],
              [
                -45.74189353129639,
                -8.450741047765973
              ],
              [
                -45.74104804523384,
                -8.462871962862684
              ],
              [
                -45.74020302514974,
                -8.475002364979428
              ],
              [
                -45.741413008272204,
                -8.479113343794229
              ],
              [
                -45.7455904743492,
                -8.493300781454096
              ],
              [
                -45.741815520204504,
                -8.497037716567455
              ],
              [
                -45.73804151805278,
                -8.500774701680072
              ],
              [
                -45.748203442874804,
                -8.513991645128792
              ],
              [
                -45.75356988513533,
                -8.520970097206337
              ],
              [
                -45.75641186994581,
                -8.527353526617592
              ],
              [
                -45.755215349830344,
                -8.532600025377208
              ],
              [
                -45.75340088072008,
                -8.540551462455083
              ],
              [
                -45.75974382128148,
                -8.548441410530428
              ],
              [
                -45.76038431038001,
                -8.54923838589246
              ],
              [
                -45.760469795727815,
                -8.55109991057546
              ],
              [
                -45.76094830395963,
                -8.561439319175966
              ],
              [
                -45.76444725686144,
                -8.565652305793321
              ],
              [
                -45.76794570976537,
                -8.56986577741301
              ],
              [
                -45.77499617094836,
                -8.57378774973002
              ],
              [
                -45.79336049215859,
                -8.58400219311941
              ],
              [
                -45.79344200323456,
                -8.588012192513435
              ],
              [
                -45.7935235243078,
                -8.592022661906434
              ],
              [
                -45.78377108093722,
                -8.597933120243974
              ],
              [
                -45.76521820532649,
                -8.609178527736022
              ],
              [
                -45.77771411287911,
                -8.617420960128387
              ],
              [
                -45.78375255182846,
                -8.61592696847645
              ],
              [
                -45.79705596238952,
                -8.612635502489258
              ],
              [
                -45.79825193046284,
                -8.620559455048395
              ],
              [
                -45.80341389865009,
                -8.621576452766172
              ],
              [
                -45.81512880016775,
                -8.632827898404239
              ],
              [
                -45.82061573633663,
                -8.649154812730421
              ],
              [
                -45.818562238734195,
                -8.653612283921708
              ],
              [
                -45.81708477194085,
                -8.654027249588868
              ],
              [
                -45.81119728460091,
                -8.655680241326191
              ],
              [
                -45.82055171852775,
                -8.665225671886235
              ],
              [
                -45.83952802015197,
                -8.714879875568172
              ],
              [
                -45.85211240750689,
                -8.70973193523593
              ],
              [
                -45.85288290877431,
                -8.709416415156369
              ],
              [
                -45.86419982297046,
                -8.715484378069256
              ],
              [
                -45.863408838953646,
                -8.724001348743823
              ],
              [
                -45.86290631403773,
                -8.729407289361335
              ],
              [
                -45.87126173286615,
                -8.73060830039561
              ],
              [
                -45.873966214126,
                -8.730996799732871
              ],
              [
                -45.888927600222196,
                -8.739253742138375
              ],
              [
                -45.889591089609475,
                -8.746029207967684
              ],
              [
                -45.89119409011811,
                -8.762393096317224
              ],
              [
                -45.905673956095306,
                -8.758663666664916
              ],
              [
                -45.90720544246076,
                -8.7582686669356
              ],
              [
                -45.91747837624054,
                -8.767683589725698
              ],
              [
                -45.927599762364835,
                -8.776958543314732
              ],
              [
                -45.93059472395832,
                -8.777705031622055
              ],
              [
                -45.934582713459825,
                -8.77870006136258
              ],
              [
                -45.9367902089779,
                -8.782439011025417
              ],
              [
                -45.93802815592955,
                -8.784536520495738
              ],
              [
                -45.93875267625157,
                -8.795449959113604
              ],
              [
                -45.94351513557691,
                -8.799247410180719
              ],
              [
                -45.95308852180569,
                -8.806879388142908
              ],
              [
                -45.960346468894095,
                -8.81848131938223
              ],
              [
                -45.95825045335887,
                -8.825777776896107
              ],
              [
                -45.95215352769288,
                -8.828203220225577
              ],
              [
                -45.94493957833871,
                -8.831072717400883
              ],
              [
                -45.94511057216722,
                -8.839979630746342
              ],
              [
                -45.94856354893358,
                -8.84454511836153
              ],
              [
                -45.95691198307933,
                -8.840119165710547
              ],
              [
                -45.95949493215327,
                -8.8506095982607
              ],
              [
                -45.96488187290382,
                -8.855886058318097
              ],
              [
                -45.97112883295086,
                -8.86200401577454
              ],
              [
                -45.97227779745409,
                -8.868732006633971
              ],
              [
                -45.9715563270704,
                -8.869691976508781
              ],
              [
                -45.967197352960895,
                -8.875491925459025
              ],
              [
                -45.97617176476682,
                -8.881386386415386
              ],
              [
                -45.97964773886832,
                -8.883669886168347
              ],
              [
                -45.98256072129718,
                -8.889649359930933
              ],
              [
                -45.98070474394381,
                -8.896815324635796
              ],
              [
                -45.979249716152275,
                -8.902431763001852
              ],
              [
                -45.984069670712216,
                -8.908268248827174
              ],
              [
                -45.98551615937575,
                -8.910019210877524
              ],
              [
                -45.98528969248491,
                -8.91241971180482
              ],
              [
                -45.984752169365635,
                -8.91811918564618
              ],
              [
                -45.987777666461554,
                -8.9208881647324
              ],
              [
                -45.993873092259,
                -8.926465651703088
              ],
              [
                -45.99188208774149,
                -8.940067046899317
              ],
              [
                -45.98040466661133,
                -8.966415345663616
              ],
              [
                -45.97384619638551,
                -8.972542291047326
              ],
              [
                -45.967287756161035,
                -8.978669776423342
              ],
              [
                -45.95870479429982,
                -9.001335094654582
              ],
              [
                -45.94304241040946,
                -9.01491451282974
              ],
              [
                -45.94437288647403,
                -9.021527940774016
              ],
              [
                -45.9449858862075,
                -9.024575921393918
              ],
              [
                -45.94029692933866,
                -9.031505874553762
              ],
              [
                -45.935378428893976,
                -9.038774832224949
              ],
              [
                -45.93779541880056,
                -9.04766529141665
              ],
              [
                -45.9399993782605,
                -9.055773706530497
              ],
              [
                -45.93745888579526,
                -9.063270686370021
              ],
              [
                -45.934782414618404,
                -9.065276159182552
              ],
              [
                -45.92884197039889,
                -9.069727124253342
              ],
              [
                -45.92696548016993,
                -9.080329058413355
              ],
              [
                -45.92938545803797,
                -9.084784003826734
              ],
              [
                -45.93094240951052,
                -9.087650510722384
              ],
              [
                -45.928398948767544,
                -9.088348492279257
              ],
              [
                -45.92271049683889,
                -9.089909480632159
              ],
              [
                -45.92213946362969,
                -9.098456410785872
              ],
              [
                -45.925332451709934,
                -9.10087239348625
              ],
              [
                -45.92717094363475,
                -9.10226290440939
              ],
              [
                -45.92471446234577,
                -9.111626335092778
              ],
              [
                -45.9250259473909,
                -9.117332804933419
              ],
              [
                -45.92765642363309,
                -9.13751367728071
              ],
              [
                -45.92083343499194,
                -9.145775096306895
              ],
              [
                -45.91906144850133,
                -9.150554093910793
              ],
              [
                -45.91290846703376,
                -9.167146950964707
              ],
              [
                -45.89665459149586,
                -9.186113327998
              ],
              [
                -45.89927756115877,
                -9.19103030168091
              ],
              [
                -45.90292605855969,
                -9.190871302861423
              ],
              [
                -45.905237532442165,
                -9.190770300145331
              ],
              [
                -45.89944303454838,
                -9.206087206011158
              ],
              [
                -45.89823704539506,
                -9.209275169714108
              ],
              [
                -45.8996310506686,
                -9.215989130228797
              ],
              [
                -45.90277999834798,
                -9.231152548306522
              ],
              [
                -45.89995002479921,
                -9.232778501415822
              ],
              [
                -45.89618603700166,
                -9.234941015940123
              ],
              [
                -45.90023750209644,
                -9.247876414698661
              ],
              [
                -45.90145048004941,
                -9.251748898683468
              ],
              [
                -45.90038247544276,
                -9.255236359592404
              ],
              [
                -45.898081988901716,
                -9.262748331702518
              ],
              [
                -45.90056896503272,
                -9.264687825826362
              ],
              [
                -45.90331196991155,
                -9.266827810930135
              ],
              [
                -45.90070547194008,
                -9.271607256614802
              ],
              [
                -45.895516479852354,
                -9.281123702290046
              ],
              [
                -45.89667097646087,
                -9.289982119546321
              ],
              [
                -45.90097445755099,
                -9.290800638112977
              ],
              [
                -45.905446418277805,
                -9.29165014167107
              ],
              [
                -45.90664437789779,
                -9.2976121179787
              ],
              [
                -45.8998314522803,
                -9.312550489290361
              ],
              [
                -45.90186490050868,
                -9.317466445424506
              ],
              [
                -45.89723145980918,
                -9.318552446985926
              ],
              [
                -45.896990966641916,
                -9.318608439342746
              ],
              [
                -45.89615493870321,
                -9.325075898018637
              ],
              [
                -45.895830952345484,
                -9.327575874271982
              ],
              [
                -45.89051800513486,
                -9.33112934688555
              ],
              [
                -45.89325594709488,
                -9.34177879029591
              ],
              [
                -45.88696501201324,
                -9.348403232669812
              ],
              [
                -45.87907055837325,
                -9.345802769601342
              ],
              [
                -45.874605599740306,
                -9.353406187660008
              ],
              [
                -45.86279167277939,
                -9.3587501650373
              ],
              [
                -45.85944422089132,
                -9.358172146962497
              ],
              [
                -45.846717308982655,
                -9.355973673690784
              ],
              [
                -45.82374495076353,
                -9.374542019528382
              ],
              [
                -45.82065598672175,
                -9.38176046466545
              ],
              [
                -45.81756802166627,
                -9.388979900798603
              ],
              [
                -45.81693401164843,
                -9.38985740231694
              ],
              [
                -45.79742312019923,
                -9.41684720793025
              ],
              [
                -45.801470087382526,
                -9.42349665007246
              ],
              [
                -45.802321097009404,
                -9.42489467090457
              ],
              [
                -45.800915103646204,
                -9.434645603424073
              ],
              [
                -45.79739261559201,
                -9.436263565739607
              ],
              [
                -45.79357665670025,
                -9.438017076825973
              ],
              [
                -45.796908126171736,
                -9.441568549443344
              ],
              [
                -45.79738112783696,
                -9.44207302169027
              ],
              [
                -45.796829589776664,
                -9.442430536708686
              ],
              [
                -45.792902153832685,
                -9.444975518482114
              ],
              [
                -45.79423161646327,
                -9.449940994520572
              ],
              [
                -45.78750869310131,
                -9.458014914845949
              ],
              [
                -45.7947126136614,
                -9.46010542254818
              ],
              [
                -45.788509160872756,
                -9.46420938846421
              ],
              [
                -45.78906565575808,
                -9.469889838935494
              ],
              [
                -45.78340568291794,
                -9.479702799120405
              ],
              [
                -45.788481649845444,
                -9.482173266876966
              ],
              [
                -45.79239609791708,
                -9.484680745040054
              ],
              [
                -45.79390957579862,
                -9.485650755460997
              ],
              [
                -45.79617407317172,
                -9.487101242616326
              ],
              [
                -45.79668606097047,
                -9.489761741811707
              ],
              [
                -45.79773156226287,
                -9.495194172502623
              ],
              [
                -45.80266753016602,
                -9.498619671870939
              ],
              [
                -45.8096699370281,
                -9.503479129287884
              ],
              [
                -45.82128235373653,
                -9.519029558250752
              ],
              [
                -45.82048184896016,
                -9.525001509292943
              ],
              [
                -45.82035031882946,
                -9.525985021713018
              ],
              [
                -45.822494812498114,
                -9.527981497185399
              ],
              [
                -45.83152074112884,
                -9.536387952871129
              ],
              [
                -45.82918874798278,
                -9.5410694405596
              ],
              [
                -45.826963248246734,
                -9.545538378761407
              ],
              [
                -45.83573920065967,
                -9.555765831309754
              ],
              [
                -45.84100112473834,
                -9.561897291484998
              ],
              [
                -45.84128714192377,
                -9.568213757602152
              ],
              [
                -45.84157363610082,
                -9.57452973772857
              ],
              [
                -45.83748513909996,
                -9.580643171019597
              ],
              [
                -45.833239690056644,
                -9.586991623591215
              ],
              [
                -45.83303516957504,
                -9.590410596045336
              ],
              [
                -45.83441264635366,
                -9.59068658787178
              ],
              [
                -45.83727164906435,
                -9.591259100514089
              ],
              [
                -45.836165122066,
                -9.6018820189209
              ],
              [
                -45.83180918408078,
                -9.603901522599028
              ],
              [
                -45.82495272247801,
                -9.607080501755986
              ],
              [
                -45.819532233281556,
                -9.617950422447722
              ],
              [
                -45.81911723661248,
                -9.624561361844878
              ],
              [
                -45.82534469552327,
                -9.626090842251736
              ],
              [
                -45.82450566580441,
                -9.636350289372917
              ],
              [
                -45.827153149841116,
                -9.642180245514638
              ],
              [
                -45.8319481001835,
                -9.652740210395429
              ],
              [
                -45.82877010993985,
                -9.679522515150524
              ],
              [
                -45.82874609488222,
                -9.67972700179018
              ],
              [
                -45.82878207951712,
                -9.679793518399867
              ],
              [
                -45.83759952208589,
                -9.696049898073577
              ],
              [
                -45.82976205332877,
                -9.70242987818346
              ],
              [
                -45.82837305994272,
                -9.703560353165274
              ],
              [
                -45.82889606267704,
                -9.70531434337276
              ],
              [
                -45.82957854950169,
                -9.707601846298921
              ],
              [
                -45.830950548060656,
                -9.712203791987442
              ],
              [
                -45.82855157938941,
                -9.7143812969471
              ],
              [
                -45.825527589972744,
                -9.717126286465282
              ],
              [
                -45.82737008925286,
                -9.722181224774062
              ],
              [
                -45.82817856015893,
                -9.724400240302597
              ],
              [
                -45.82658507916101,
                -9.727361219128152
              ],
              [
                -45.81993360873347,
                -9.739718617757468
              ],
              [
                -45.82410808184558,
                -9.743709580800642
              ],
              [
                -45.82659305628988,
                -9.74608657332039
              ],
              [
                -45.82281657567144,
                -9.75223103282386
              ],
              [
                -45.82025659784241,
                -9.756395997661873
              ],
              [
                -45.82099856603174,
                -9.772719907405557
              ],
              [
                -45.82806149941882,
                -9.791293795182098
              ],
              [
                -45.83570641318977,
                -9.795169269218379
              ],
              [
                -45.838316383922226,
                -9.796491767774627
              ],
              [
                -45.84414235392188,
                -9.806204691716921
              ],
              [
                -45.8493767523085,
                -9.830781059484277
              ],
              [
                -45.851000761074495,
                -9.838404997971912
              ],
              [
                -45.85277422049529,
                -9.839664520947553
              ],
              [
                -45.85568369193585,
                -9.84173149706185
              ],
              [
                -45.85504469680116,
                -9.846751449717042
              ],
              [
                -45.85494821462828,
                -9.84751044067586
              ],
              [
                -45.85547420849733,
                -9.848297446208019
              ],
              [
                -45.86400960323768,
                -9.861059892788257
              ],
              [
                -45.86585608520207,
                -9.870310337050665
              ],
              [
                -45.8623591284411,
                -9.873074303878159
              ],
              [
                -45.86114364486669,
                -9.874034781172618
              ],
              [
                -45.86205812465712,
                -9.87724925544461
              ],
              [
                -45.863129114528675,
                -9.881012757350039
              ],
              [
                -45.85990363720912,
                -9.885722733891912
              ],
              [
                -45.859641632808476,
                -9.886104693096957
              ],
              [
                -45.859542636305925,
                -9.887142705247838
              ],
              [
                -45.85763114367192,
                -9.907108058524269
              ],
              [
                -45.85223464210974,
                -9.919390984351589
              ],
              [
                -45.851434648672864,
                -9.9200074583803
              ],
              [
                -45.84607618493472,
                -9.92413845906917
              ],
              [
                -45.84186469709683,
                -9.941147846730193
              ],
              [
                -45.846119655812736,
                -9.950469283571355
              ],
              [
                -45.8485906365108,
                -9.955881733553822
              ],
              [
                -45.84759065867787,
                -9.958884201888575
              ],
              [
                -45.846594655828085,
                -9.961874220309271
              ],
              [
                -45.852258572161126,
                -9.98176057055182
              ],
              [
                -45.85560553219202,
                -9.983747065812787
              ],
              [
                -45.85661455445911,
                -9.984345574181065
              ],
              [
                -45.85631055787667,
                -9.985934060530075
              ],
              [
                -45.85439905748961,
                -9.99592697953225
              ],
              [
                -45.861835478694644,
                -10.003867440333646
              ],
              [
                -45.86579643105037,
                -10.008096430869477
              ],
              [
                -45.875048337912254,
                -10.039873219917068
              ],
              [
                -45.87479883007604,
                -10.05004218291868
              ],
              [
                -45.87468431307637,
                -10.054726617520705
              ],
              [
                -45.875432836512836,
                -10.055235122163598
              ],
              [
                -45.879775791432984,
                -10.05818413549931
              ],
              [
                -45.87620628497819,
                -10.061905576044522
              ],
              [
                -45.86951582711067,
                -10.06888102570611
              ],
              [
                -45.87812122811692,
                -10.109889769721686
              ],
              [
                -45.881987694136534,
                -10.11177078340369
              ],
              [
                -45.885853675161144,
                -10.113651779087764
              ],
              [
                -45.89732405681128,
                -10.136159644279045
              ],
              [
                -45.89746003850456,
                -10.137127106213395
              ],
              [
                -45.89960101317037,
                -10.152358023741337
              ],
              [
                -45.909623428562,
                -10.156534034381687
              ],
              [
                -45.91308539833828,
                -10.157976486425225
              ],
              [
                -45.91496089124139,
                -10.16366797612061
              ],
              [
                -45.91498987490051,
                -10.16375546059355
              ],
              [
                -45.91507936610201,
                -10.163826467248448
              ],
              [
                -45.92384529493362,
                -10.17074691967752
              ],
              [
                -45.927029767178745,
                -10.179798880406661
              ],
              [
                -45.930765727045504,
                -10.190417809764252
              ],
              [
                -45.93500268449205,
                -10.190454814849872
              ],
              [
                -45.93789015962642,
                -10.190480306314248
              ],
              [
                -45.94519810572974,
                -10.196032783784428
              ],
              [
                -45.94528657155854,
                -10.204201230363058
              ],
              [
                -45.94531007054196,
                -10.206408220200634
              ],
              [
                -45.94696258689768,
                -10.208417734359578
              ],
              [
                -45.954871509023555,
                -10.218032170513544
              ],
              [
                -45.94728851808425,
                -10.252146938722122
              ],
              [
                -45.945953038898466,
                -10.258155386435625
              ],
              [
                -45.97352481905622,
                -10.247959011794652
              ],
              [
                -45.99582463131209,
                -10.249904490511417
              ],
              [
                -46.003127579912025,
                -10.260517455649364
              ],
              [
                -46.01730146243776,
                -10.239980577368673
              ],
              [
                -46.01926947518394,
                -10.21956425189533
              ],
              [
                -46.02279702231135,
                -10.182970972333342
              ],
              [
                -46.0278539847226,
                -10.176460014518717
              ],
              [
                -46.0385838721836,
                -10.17632352188516
              ],
              [
                -46.050435278304676,
                -10.18126000909038
              ],
              [
                -46.099302372601095,
                -10.212489858334914
              ],
              [
                -46.135490068676084,
                -10.20594293000815
              ],
              [
                -46.16421583884302,
                -10.212491944372324
              ],
              [
                -46.19411913828018,
                -10.182201162098751
              ],
              [
                -46.201657603087135,
                -10.173511218250527
              ],
              [
                -46.21049553790104,
                -10.16930927229813
              ],
              [
                -46.240404794704304,
                -10.171477286139854
              ],
              [
                -46.272578015080256,
                -10.181205252539351
              ],
              [
                -46.301018317676736,
                -10.177918341489487
              ],
              [
                -46.31265720827522,
                -10.17241288637752
              ],
              [
                -46.32065815963413,
                -10.17843685800346
              ],
              [
                -46.32709709478613,
                -10.183283817188071
              ],
              [
                -46.347828935576075,
                -10.172669403191978
              ],
              [
                -46.35764539721768,
                -10.170535935225104
              ],
              [
                -46.367462817863654,
                -10.16840296225409
              ],
              [
                -46.38346824245484,
                -10.119958812795234
              ],
              [
                -46.41845999894505,
                -10.082401569693687
              ],
              [
                -46.43095892425316,
                -10.079784612095068
              ],
              [
                -46.443458312578024,
                -10.07716763249378
              ],
              [
                -46.45372175203563,
                -10.055290787083102
              ],
              [
                -46.45485326087497,
                -10.052381302084846
              ],
              [
                -46.45598575570509,
                -10.049472322084968
              ],
              [
                -46.45666028001216,
                -10.017565526814238
              ],
              [
                -46.477158159020206,
                -9.987893759042874
              ],
              [
                -46.47429715768547,
                -9.98058828119473
              ],
              [
                -46.47143672533986,
                -9.97328231736259
              ],
              [
                -46.47423217682171,
                -9.961901917629683
              ],
              [
                -46.47658117949099,
                -9.952340460714371
              ],
              [
                -46.474187718437086,
                -9.949014996024035
              ],
              [
                -46.46529380569046,
                -9.936660052078029
              ],
              [
                -46.47364973799697,
                -9.92810660217424
              ],
              [
                -46.478186191305795,
                -9.923462166517169
              ],
              [
                -46.47485124389018,
                -9.906387774721342
              ],
              [
                -46.472631762766405,
                -9.902014285992307
              ],
              [
                -46.47041279863556,
                -9.897641294265668
              ],
              [
                -46.472475326306515,
                -9.877031944851828
              ],
              [
                -46.48169723361892,
                -9.87340596443665
              ],
              [
                -46.4912196707363,
                -9.869662031142413
              ],
              [
                -46.491015661844536,
                -9.868454525627808
              ],
              [
                -46.48971317468755,
                -9.860742569441454
              ],
              [
                -46.48657070165553,
                -9.858853087687802
              ],
              [
                -46.47990879782803,
                -9.854847598175995
              ],
              [
                -46.490173201657655,
                -9.848277170897086
              ],
              [
                -46.495536662951004,
                -9.844843695488564
              ],
              [
                -46.49437816114068,
                -9.835929725207377
              ],
              [
                -46.493219712319075,
                -9.827015782938915
              ],
              [
                -46.51172557884365,
                -9.798577480952634
              ],
              [
                -46.54196385017175,
                -9.80990594431296
              ],
              [
                -46.5620501816088,
                -9.810281490501612
              ],
              [
                -46.57657858187298,
                -9.80477201258155
              ],
              [
                -46.579238064070076,
                -9.79594008840559
              ],
              [
                -46.572068110452925,
                -9.783602138686268
              ],
              [
                -46.570380630252,
                -9.78069717824081
              ],
              [
                -46.568914153835664,
                -9.769315749398704
              ],
              [
                -46.57571914515631,
                -9.757598836806533
              ],
              [
                -46.59570999423219,
                -9.74684440809606
              ],
              [
                -46.627082262399156,
                -9.7482594392431
              ],
              [
                -46.64235711603092,
                -9.74105949396907
              ],
              [
                -46.646990618603716,
                -9.729944579802513
              ],
              [
                -46.645193628637145,
                -9.716180184800374
              ],
              [
                -46.65290958693712,
                -9.69303883046807
              ],
              [
                -46.612630961390835,
                -9.665404950965879
              ],
              [
                -46.60953396211764,
                -9.658442507916577
              ],
              [
                -46.60643802582928,
                -9.65148052987731
              ],
              [
                -46.60569155723608,
                -9.629758204481302
              ],
              [
                -46.59134266170696,
                -9.60566782504222
              ],
              [
                -46.5913321886848,
                -9.599185348704438
              ],
              [
                -46.59132370222815,
                -9.593864392905806
              ],
              [
                -46.59113271255849,
                -9.586598446400016
              ],
              [
                -46.58122627981762,
                -9.584233437748336
              ],
              [
                -46.57887830984166,
                -9.583672937542962
              ],
              [
                -46.53739967587584,
                -9.55738306720514
              ],
              [
                -46.540053623358936,
                -9.549758628483438
              ],
              [
                -46.54870408468073,
                -9.54402716971338
              ],
              [
                -46.5568620394636,
                -9.53862221124724
              ],
              [
                -46.55761052546998,
                -9.533777272347322
              ],
              [
                -46.57526240664358,
                -9.51714387749461
              ],
              [
                -46.57530839689188,
                -9.51249438750149
              ],
              [
                -46.575355406128125,
                -9.507845947505515
              ],
              [
                -46.57230894630158,
                -9.505043450945449
              ],
              [
                -46.55877056305736,
                -9.492588526160702
              ],
              [
                -46.56054805494118,
                -9.483629094031746
              ],
              [
                -46.57282797954851,
                -9.477358643000446
              ],
              [
                -46.58092091702835,
                -9.479283653172905
              ],
              [
                -46.59280379583842,
                -9.482109632035073
              ],
              [
                -46.59762277084336,
                -9.474094683393153
              ],
              [
                -46.600053754718644,
                -9.470052201322913
              ],
              [
                -46.613027162657026,
                -9.46487774302005
              ],
              [
                -46.61971361122004,
                -9.465085748591417
              ],
              [
                -46.627163055805624,
                -9.465317772912384
              ],
              [
                -46.64913791830591,
                -9.443685937247716
              ],
              [
                -46.64749692939037,
                -9.42674202748375
              ],
              [
                -46.644610459143124,
                -9.421525065703756
              ],
              [
                -46.64332946490205,
                -9.419210094115774
              ],
              [
                -46.63235556409499,
                -9.411184632934061
              ],
              [
                -46.64152400478467,
                -9.408492156084657
              ],
              [
                -46.646053957037424,
                -9.40716214699025
              ],
              [
                -46.65115741902097,
                -9.405663668655919
              ],
              [
                -46.6575288708055,
                -9.39939872653856
              ],
              [
                -46.665546318684775,
                -9.391516776776045
              ],
              [
                -46.670020785475266,
                -9.390948792696038
              ],
              [
                -46.6744952892687,
                -9.390380294619282
              ],
              [
                -46.68145020537027,
                -9.392629807296379
              ],
              [
                -46.69713059310553,
                -9.397701762059867
              ],
              [
                -46.710008454794526,
                -9.396831817817656
              ],
              [
                -46.72288486451631,
                -9.395961812575065
              ],
              [
                -46.73752226336648,
                -9.404450293169194
              ],
              [
                -46.73754224115595,
                -9.404496270896827
              ],
              [
                -46.740607743766596,
                -9.411618245677975
              ],
              [
                -46.74560116703985,
                -9.41399170529655
              ],
              [
                -46.76293855455613,
                -9.408550285753458
              ],
              [
                -46.76541306236968,
                -9.401795321123439
              ],
              [
                -46.75824509822013,
                -9.380813961693654
              ],
              [
                -46.76788557389633,
                -9.365101578161024
              ],
              [
                -46.77392200934583,
                -9.361378592213468
              ],
              [
                -46.77924097485587,
                -9.358098123572548
              ],
              [
                -46.781356964476366,
                -9.353822164579439
              ],
              [
                -46.78290948412583,
                -9.35068516859381
              ],
              [
                -46.79874136050091,
                -9.338441252966236
              ],
              [
                -46.812490269565345,
                -9.305547007825181
              ],
              [
                -46.81832373917995,
                -9.30303200983127
              ],
              [
                -46.82505167756774,
                -9.304975501175019
              ],
              [
                -46.834884100764434,
                -9.307815490380529
              ],
              [
                -46.84313905994109,
                -9.300768577379488
              ],
              [
                -46.846635017117634,
                -9.292022101757231
              ],
              [
                -46.84421453668306,
                -9.283770186056172
              ],
              [
                -46.83230313867943,
                -9.279187194686314
              ],
              [
                -46.831322152122766,
                -9.268322770522165
              ],
              [
                -46.826225196347366,
                -9.266963282350416
              ],
              [
                -46.822329736855345,
                -9.265924776510309
              ],
              [
                -46.81851827287275,
                -9.259656279472196
              ],
              [
                -46.8284637250493,
                -9.239687460721004
              ],
              [
                -46.826438756467695,
                -9.222926526431644
              ],
              [
                -46.81918632257119,
                -9.211213622581468
              ],
              [
                -46.82235583464574,
                -9.198643683334383
              ],
              [
                -46.827383794150954,
                -9.193867247955307
              ],
              [
                -46.83840969573655,
                -9.197053731129396
              ],
              [
                -46.838858183099354,
                -9.197183725808886
              ],
              [
                -46.85027510411087,
                -9.191436784056418
              ],
              [
                -46.85110711498045,
                -9.188585309406855
              ],
              [
                -46.851939087848365,
                -9.185734799751515
              ],
              [
                -46.85142609921779,
                -9.183694336320517
              ],
              [
                -46.8473806514216,
                -9.16760841043412
              ],
              [
                -46.85199165440026,
                -9.154048506243925
              ],
              [
                -46.860272071125436,
                -9.147137578362713
              ],
              [
                -46.86920899404242,
                -9.14513960952504
              ],
              [
                -46.88990837070621,
                -9.140512666159935
              ],
              [
                -46.88989135118588,
                -9.130753205102387
              ],
              [
                -46.889883361477004,
                -9.126252216134912
              ],
              [
                -46.89406832225259,
                -9.122582748612261
              ],
              [
                -46.90051479956536,
                -9.129169222513946
              ],
              [
                -46.90630673673424,
                -9.135086197998609
              ],
              [
                -46.91724865576887,
                -9.131693223446353
              ],
              [
                -46.925878064500445,
                -9.134542234988874
              ],
              [
                -46.93105306980617,
                -9.120388825186025
              ],
              [
                -46.92576510848223,
                -9.117242847408152
              ],
              [
                -46.92982756749872,
                -9.105580896280598
              ],
              [
                -46.92344263897148,
                -9.097866463737468
              ],
              [
                -46.92312962083771,
                -9.09748846281819
              ],
              [
                -46.92347864125805,
                -9.09737797993039
              ],
              [
                -46.930162103860646,
                -9.095263486219745
              ],
              [
                -46.92788010513707,
                -9.092176511526029
              ],
              [
                -46.92941012006048,
                -9.083715079873453
              ],
              [
                -46.927433628745504,
                -9.08427406500773
              ],
              [
                -46.91854418479845,
                -9.086788524621845
              ],
              [
                -46.91172173889528,
                -9.082323554647614
              ],
              [
                -46.92223016187822,
                -9.066300158084053
              ],
              [
                -46.94257403166212,
                -9.064071686669509
              ],
              [
                -46.97549723746599,
                -9.08132962293846
              ],
              [
                -46.98625817315416,
                -9.076068680158457
              ],
              [
                -46.986284664964515,
                -9.076055162275882
              ],
              [
                -46.99827156115295,
                -9.075993660373864
              ],
              [
                -46.99865708084906,
                -9.07713168547967
              ],
              [
                -46.999994540917704,
                -9.081078631571362
              ],
              [
                -47.00691201049691,
                -9.081794137873887
              ],
              [
                -47.01318646765181,
                -9.077683198539962
              ],
              [
                -47.012011976849834,
                -9.07500470545607
              ],
              [
                -47.0097734890976,
                -9.069898216805374
              ],
              [
                -47.01903990470927,
                -9.073885205704892
              ],
              [
                -47.02313588694646,
                -9.075647215036431
              ],
              [
                -47.0285033501631,
                -9.072377720237101
              ],
              [
                -47.03394282287549,
                -9.069064769797585
              ],
              [
                -47.04016875827768,
                -9.068538759302717
              ],
              [
                -47.04524370348279,
                -9.068110250864068
              ],
              [
                -47.047990204981666,
                -9.07090874698144
              ],
              [
                -47.06830254236416,
                -9.063475340069632
              ],
              [
                -47.06756054119816,
                -9.05561339084362
              ],
              [
                -47.05742716211425,
                -9.052592381729292
              ],
              [
                -47.057296151173325,
                -9.052553386830896
              ],
              [
                -47.05937264698901,
                -9.0500539043003
              ],
              [
                -47.06129962375457,
                -9.047733931442437
              ],
              [
                -47.05888263344979,
                -9.047743423623665
              ],
              [
                -47.05382265758932,
                -9.047764435715527
              ],
              [
                -47.051607705391426,
                -9.03852847269564
              ],
              [
                -47.05402218794343,
                -9.033983499732942
              ],
              [
                -47.043052767878784,
                -9.026149057737339
              ],
              [
                -47.04357727000676,
                -9.02251105778599
              ],
              [
                -47.04832873749885,
                -9.021406076313534
              ],
              [
                -47.048218733992975,
                -9.016502104801598
              ],
              [
                -47.05247820979297,
                -9.017572600743382
              ],
              [
                -47.05604968111862,
                -9.018470124019233
              ],
              [
                -47.06807161796529,
                -9.011621683826665
              ],
              [
                -47.06165814856996,
                -9.0072926994421
              ],
              [
                -47.04806627347565,
                -9.006670683015503
              ],
              [
                -47.0436038092518,
                -9.006466176267082
              ],
              [
                -47.03844385598232,
                -9.000555724528766
              ],
              [
                -47.03102090672557,
                -8.985490274307995
              ]
            ],
            [
              [
                -44.81337814152567,
                -1.802111532566571
              ],
              [
                -44.8070131929199,
                -1.806952967437456
              ],
              [
                -44.81008617173895,
                -1.801528518271169
              ],
              [
                -44.81337814152567,
                -1.802111532566571
              ]
            ],
            [
              [
                -44.569338072877656,
                -1.919747055076506
              ],
              [
                -44.56100813845525,
                -1.922738062400539
              ],
              [
                -44.566295601541256,
                -1.917725574744056
              ],
              [
                -44.569338072877656,
                -1.919747055076506
              ]
            ],
            [
              [
                -45.74672037647187,
                -1.372030421049302
              ],
              [
                -45.753933339095596,
                -1.378299874122902
              ],
              [
                -45.74401689918802,
                -1.374396413794368
              ],
              [
                -45.74672037647187,
                -1.372030421049302
              ]
            ],
            [
              [
                -45.88752331805051,
                -1.180350406407767
              ],
              [
                -45.88471132852339,
                -1.183371870257849
              ],
              [
                -45.87787136558751,
                -1.178063929206645
              ],
              [
                -45.88752331805051,
                -1.180350406407767
              ]
            ],
            [
              [
                -45.82244231756664,
                -1.310566390840822
              ],
              [
                -45.8199223099421,
                -1.312315887277086
              ],
              [
                -45.81958582495883,
                -1.303602940742111
              ],
              [
                -45.819363810602994,
                -1.297860513892967
              ],
              [
                -45.82030382365483,
                -1.301740462864313
              ],
              [
                -45.82244231756664,
                -1.310566390840822
              ]
            ],
            [
              [
                -44.69330659382537,
                -1.81627090272347
              ],
              [
                -44.6877016292124,
                -1.807551951772346
              ],
              [
                -44.69231861056188,
                -1.807529466952891
              ],
              [
                -44.69330659382537,
                -1.81627090272347
              ]
            ],
            [
              [
                -45.38981164352004,
                -1.68975047565449
              ],
              [
                -45.396501582112144,
                -1.693981968182218
              ],
              [
                -45.3879981352329,
                -1.692340446775624
              ],
              [
                -45.387416135337354,
                -1.679940565035802
              ],
              [
                -45.38981164352004,
                -1.68975047565449
              ]
            ],
            [
              [
                -44.800955296072196,
                -1.626301830855792
              ],
              [
                -44.79423883437852,
                -1.625918854445854
              ],
              [
                -44.790448371942524,
                -1.618091422271435
              ],
              [
                -44.800955296072196,
                -1.626301830855792
              ]
            ],
            [
              [
                -45.12782520975903,
                -1.543967522110346
              ],
              [
                -45.12257974868727,
                -1.546306527394757
              ],
              [
                -45.112673829238204,
                -1.544012522914672
              ],
              [
                -45.115443299780594,
                -1.541940559142089
              ],
              [
                -45.12257974868727,
                -1.546306527394757
              ],
              [
                -45.1239102647565,
                -1.543290524503655
              ],
              [
                -45.12675870597801,
                -1.539613055911208
              ],
              [
                -45.12782520975903,
                -1.543967522110346
              ]
            ],
            [
              [
                -45.82298580900974,
                -1.2486288628562
              ],
              [
                -45.81391137502873,
                -1.227212545682731
              ],
              [
                -45.822536817615514,
                -1.241370931413185
              ],
              [
                -45.82298580900974,
                -1.2486288628562
              ]
            ],
            [
              [
                -45.57567222399224,
                -1.335734182953104
              ],
              [
                -45.57238925600664,
                -1.339989144301043
              ],
              [
                -45.57067878609365,
                -1.32955574276533
              ],
              [
                -45.57357423845483,
                -1.329345724824462
              ],
              [
                -45.57567222399224,
                -1.335734182953104
              ]
            ],
            [
              [
                -45.799500460161966,
                -1.331501230674422
              ],
              [
                -45.802308438141765,
                -1.338317195887909
              ],
              [
                -45.800759949276845,
                -1.344098153167896
              ],
              [
                -45.79826649652931,
                -1.333104742413229
              ],
              [
                -45.79594601729506,
                -1.322871807987495
              ],
              [
                -45.799500460161966,
                -1.331501230674422
              ]
            ],
            [
              [
                -44.83421198599697,
                -1.822641346420813
              ],
              [
                -44.845666902007906,
                -1.828360339597233
              ],
              [
                -44.82020959603138,
                -1.82220087666565
              ],
              [
                -44.83421198599697,
                -1.822641346420813
              ]
            ],
            [
              [
                -45.368570338340135,
                -1.436380367406857
              ],
              [
                -45.36712933566602,
                -1.424802466703517
              ],
              [
                -45.360009897083714,
                -1.427780931932957
              ],
              [
                -45.36159139814864,
                -1.424942959673423
              ],
              [
                -45.36712933566602,
                -1.424802466703517
              ],
              [
                -45.37749175176198,
                -1.421932970211569
              ],
              [
                -45.37052031778789,
                -1.426191976788527
              ],
              [
                -45.368570338340135,
                -1.436380367406857
              ]
            ],
            [
              [
                -45.90541515779728,
                -1.240258923510521
              ],
              [
                -45.90230818065338,
                -1.242534919924465
              ],
              [
                -45.90455114865003,
                -1.246026910022735
              ],
              [
                -45.90661913898116,
                -1.249698859744748
              ],
              [
                -45.9054366606872,
                -1.252994363794385
              ],
              [
                -45.90412565433803,
                -1.256649303123056
              ],
              [
                -45.903054668220875,
                -1.259635293516541
              ],
              [
                -45.90063568136908,
                -1.257655799015364
              ],
              [
                -45.90372619242708,
                -1.250275867960052
              ],
              [
                -45.90230818065338,
                -1.242534919924465
              ],
              [
                -45.89703923716531,
                -1.239459951229845
              ],
              [
                -45.90541515779728,
                -1.240258923510521
              ]
            ],
            [
              [
                -45.59484058036364,
                -1.33918714705615
              ],
              [
                -45.587375646961455,
                -1.33203921475876
              ],
              [
                -45.58457614202869,
                -1.324917753041794
              ],
              [
                -45.58816563204044,
                -1.311738856091357
              ],
              [
                -45.58889664146029,
                -1.323331766715322
              ],
              [
                -45.588705611168066,
                -1.328217251816835
              ],
              [
                -45.59484058036364,
                -1.33918714705615
              ]
            ],
            [
              [
                -45.39239460993477,
                -1.564462905457183
              ],
              [
                -45.38867565848091,
                -1.54139809078031
              ],
              [
                -45.379399241633735,
                -1.53982110192963
              ],
              [
                -45.38380121256461,
                -1.53710612625304
              ],
              [
                -45.38867565848091,
                -1.54139809078031
              ],
              [
                -45.39172065126253,
                -1.543029096047932
              ],
              [
                -45.39380411564573,
                -1.555044484781824
              ],
              [
                -45.39562958895519,
                -1.562220449994179
              ],
              [
                -45.39923608581675,
                -1.566535430137643
              ],
              [
                -45.39836957509904,
                -1.574532356655036
              ],
              [
                -45.39239460993477,
                -1.564462905457183
              ]
            ]
          ],
          [
            [
              [
                -44.97077000031627,
                -1.344385539130453
              ],
              [
                -44.97072996807031,
                -1.335455107177112
              ],
              [
                -44.9707375085,
                -1.332402102445397
              ],
              [
                -44.96447101791355,
                -1.326872656576593
              ],
              [
                -44.9503576529684,
                -1.336342102105815
              ],
              [
                -44.94699068622585,
                -1.338601082337214
              ],
              [
                -44.941594232312056,
                -1.342221538856927
              ],
              [
                -44.943147703815875,
                -1.337328068417086
              ],
              [
                -44.95518212604471,
                -1.328734126886209
              ],
              [
                -44.9613150662608,
                -1.324355184258164
              ],
              [
                -44.95490910256618,
                -1.320164718175418
              ],
              [
                -44.95793710735545,
                -1.309230794029949
              ],
              [
                -44.958160081836375,
                -1.320353200260488
              ],
              [
                -44.96604251411193,
                -1.316670717599296
              ],
              [
                -44.97126147625573,
                -1.314180246420268
              ],
              [
                -44.96630604350518,
                -1.307304296051288
              ],
              [
                -44.97217748264836,
                -1.310169800139504
              ],
              [
                -44.97439795792889,
                -1.311252773237806
              ],
              [
                -44.97938193613983,
                -1.30744929202592
              ],
              [
                -44.98496638669903,
                -1.303060353369147
              ],
              [
                -44.97226347312981,
                -1.283462987792251
              ],
              [
                -44.967852526443494,
                -1.280531010466151
              ],
              [
                -44.96344154275891,
                -1.277598020151904
              ],
              [
                -44.952587657639384,
                -1.277072530474405
              ],
              [
                -44.943124719081894,
                -1.285998446897961
              ],
              [
                -44.934366778404,
                -1.285544957989041
              ],
              [
                -44.930383306937586,
                -1.285338459942318
              ],
              [
                -44.92559283272072,
                -1.292467912785088
              ],
              [
                -44.92164236605144,
                -1.298348376288902
              ],
              [
                -44.92273035557374,
                -1.303934320836925
              ],
              [
                -44.91924990414685,
                -1.303651341444571
              ],
              [
                -44.92164236605144,
                -1.298348376288902
              ],
              [
                -44.92381785084315,
                -1.291926418638559
              ],
              [
                -44.924417875371844,
                -1.290155921244278
              ],
              [
                -44.92563936560345,
                -1.277877541145362
              ],
              [
                -44.902155053342526,
                -1.278069011036238
              ],
              [
                -44.89142912567051,
                -1.28158499952024
              ],
              [
                -44.89069712642601,
                -1.28182449257739
              ],
              [
                -44.88756164107997,
                -1.282852983234684
              ],
              [
                -44.8816462115954,
                -1.284791959504172
              ],
              [
                -44.85839136368455,
                -1.297634869763625
              ],
              [
                -44.845558994117425,
                -1.317874210130825
              ],
              [
                -44.847140973711966,
                -1.32342416899587
              ],
              [
                -44.84756094815219,
                -1.324900132791261
              ],
              [
                -44.847883467172736,
                -1.327577633395536
              ],
              [
                -44.848027462841664,
                -1.328776622262503
              ],
              [
                -44.84226299966302,
                -1.333955064785489
              ],
              [
                -44.86262932793576,
                -1.343106025227803
              ],
              [
                -44.87546024139278,
                -1.35530843520469
              ],
              [
                -44.87249374421812,
                -1.340805032397795
              ],
              [
                -44.86956079672746,
                -1.326464639385797
              ],
              [
                -44.87470375093024,
                -1.333288115129476
              ],
              [
                -44.87699873227778,
                -1.336333547257311
              ],
              [
                -44.8813521789447,
                -1.3355650858285
              ],
              [
                -44.884426660699496,
                -1.335021586474856
              ],
              [
                -44.88716713939657,
                -1.332615608279774
              ],
              [
                -44.890732610681006,
                -1.329485131745811
              ],
              [
                -44.89480407640071,
                -1.329878134404501
              ],
              [
                -44.9010015271796,
                -1.331032604597306
              ],
              [
                -44.88857762470854,
                -1.335902053429238
              ],
              [
                -44.88901713319562,
                -1.33611955784085
              ],
              [
                -44.89715007719362,
                -1.340143026463773
              ],
              [
                -44.907437969008086,
                -1.345038005801154
              ],
              [
                -44.91040796804855,
                -1.347906485408781
              ],
              [
                -44.90265503181223,
                -1.345264515291675
              ],
              [
                -44.89609256710496,
                -1.34297852665882
              ],
              [
                -44.89283961905306,
                -1.341687036981631
              ],
              [
                -44.88715866735648,
                -1.345733992177039
              ],
              [
                -44.89346259274227,
                -1.350241458820719
              ],
              [
                -44.897263086943646,
                -1.348549464351062
              ],
              [
                -44.920100383237845,
                -1.373258781704867
              ],
              [
                -44.921503357056494,
                -1.367690832383261
              ],
              [
                -44.92363235410427,
                -1.374452803193165
              ],
              [
                -44.93113027597351,
                -1.379471739196305
              ],
              [
                -44.93326278612702,
                -1.379361242396016
              ],
              [
                -44.9428896970826,
                -1.37886274780881
              ],
              [
                -44.94410618703599,
                -1.387458701521957
              ],
              [
                -44.94456017591172,
                -1.390663687182699
              ],
              [
                -44.959641586712344,
                -1.40237607153881
              ],
              [
                -44.96327502102529,
                -1.402167562746312
              ],
              [
                -44.96338253622834,
                -1.401853090159116
              ],
              [
                -44.96468802555217,
                -1.39802812850663
              ],
              [
                -44.96714899933534,
                -1.402571059334921
              ],
              [
                -44.970044978542745,
                -1.407916521133144
              ],
              [
                -44.982789885582896,
                -1.409301533774892
              ],
              [
                -44.99034030489749,
                -1.40351758308915
              ],
              [
                -44.99218581155395,
                -1.402103094169739
              ],
              [
                -45.00243375190802,
                -1.394254145649511
              ],
              [
                -45.011965667832285,
                -1.386953204825652
              ],
              [
                -45.017359621740795,
                -1.359628413705178
              ],
              [
                -45.01761962344179,
                -1.355005940937853
              ],
              [
                -45.013421134129814,
                -1.351766477909274
              ],
              [
                -45.00928968827351,
                -1.348622986165502
              ],
              [
                -45.00514071455843,
                -1.345467030518088
              ],
              [
                -45.0037577416534,
                -1.344415517299632
              ],
              [
                -44.998154272594995,
                -1.346430005039308
              ],
              [
                -45.00088125487148,
                -1.34135353115004
              ],
              [
                -45.00531669868097,
                -1.3422935297154
              ],
              [
                -45.00592169288115,
                -1.342422046835506
              ],
              [
                -45.01277316052679,
                -1.343875017891182
              ],
              [
                -45.016361629060704,
                -1.344636526680077
              ],
              [
                -45.02801954097413,
                -1.338556589877063
              ],
              [
                -45.0207186083549,
                -1.32780266457839
              ],
              [
                -45.01691262530688,
                -1.322197683652756
              ],
              [
                -45.00509571801475,
                -1.319871720470156
              ],
              [
                -44.99567080410751,
                -1.322164193488368
              ],
              [
                -44.99379230552697,
                -1.324817158972029
              ],
              [
                -44.98989832741588,
                -1.330317128437574
              ],
              [
                -44.98412038982329,
                -1.331866624695307
              ],
              [
                -44.97837343999116,
                -1.333406101032685
              ],
              [
                -44.97523694464377,
                -1.33424708411504
              ],
              [
                -44.97072996807031,
                -1.335455107177112
              ],
              [
                -44.97422046919216,
                -1.343478026609652
              ],
              [
                -44.97549145667537,
                -1.346399022561661
              ],
              [
                -44.9788849125914,
                -1.354200437684645
              ],
              [
                -44.98506385797277,
                -1.352912946509921
              ],
              [
                -44.982216909934664,
                -1.356199928003085
              ],
              [
                -44.976233942875865,
                -1.370485808198808
              ],
              [
                -44.979082925266326,
                -1.358864402189538
              ],
              [
                -44.97264048100586,
                -1.347643991608726
              ],
              [
                -44.97077000031627,
                -1.344385539130453
              ]
            ],
            [
              [
                -44.924277850731606,
                -1.339253059668666
              ],
              [
                -44.92418336543125,
                -1.345754505074926
              ],
              [
                -44.921879894067565,
                -1.34316951441075
              ],
              [
                -44.924277850731606,
                -1.339253059668666
              ]
            ],
            [
              [
                -44.90932596821121,
                -1.325443650582534
              ],
              [
                -44.899113569567135,
                -1.315602242039951
              ],
              [
                -44.89998757324765,
                -1.311822251028164
              ],
              [
                -44.90325551432375,
                -1.312255751240706
              ],
              [
                -44.907787499711155,
                -1.310718291695934
              ],
              [
                -44.905049495381256,
                -1.304287314340889
              ],
              [
                -44.91572343303148,
                -1.303712834416585
              ],
              [
                -44.90891045857547,
                -1.305952807242166
              ],
              [
                -44.907787499711155,
                -1.310718291695934
              ],
              [
                -44.90764799834563,
                -1.313697727937275
              ],
              [
                -44.90325551432375,
                -1.312255751240706
              ],
              [
                -44.9108434576608,
                -1.322584170643163
              ],
              [
                -44.90932596821121,
                -1.325443650582534
              ],
              [
                -44.909726971471905,
                -1.329003633487289
              ],
              [
                -44.905038494491926,
                -1.329453647296714
              ],
              [
                -44.90932596821121,
                -1.325443650582534
              ]
            ]
          ],
          [
            [
              [
                -45.67797593429201,
                -1.300212438493374
              ],
              [
                -45.68574136418481,
                -1.290106540914964
              ],
              [
                -45.684205388408984,
                -1.269467206283991
              ],
              [
                -45.65670612275744,
                -1.254157805487925
              ],
              [
                -45.6501841625428,
                -1.268601693487116
              ],
              [
                -45.65005466495826,
                -1.272462663336271
              ],
              [
                -45.643531191794494,
                -1.273655643311243
              ],
              [
                -45.64252770161498,
                -1.273839139769114
              ],
              [
                -45.644128681479515,
                -1.277787630229015
              ],
              [
                -45.64692419153927,
                -1.284683057649651
              ],
              [
                -45.65436363962344,
                -1.289366532502885
              ],
              [
                -45.64428669394761,
                -1.286091558606223
              ],
              [
                -45.64417919481913,
                -1.285895545067856
              ],
              [
                -45.63803174664914,
                -1.274696628592459
              ],
              [
                -45.64277421952548,
                -1.268419204705156
              ],
              [
                -45.647533683270794,
                -1.262117759998926
              ],
              [
                -45.640427740368665,
                -1.252192328806083
              ],
              [
                -45.6354417614283,
                -1.245228872604406
              ],
              [
                -45.63882676772641,
                -1.206517677441754
              ],
              [
                -45.63612129003861,
                -1.17792239408571
              ],
              [
                -45.629771831195384,
                -1.160440002307109
              ],
              [
                -45.629357341898334,
                -1.142885135974008
              ],
              [
                -45.619762412590454,
                -1.131131727518827
              ],
              [
                -45.615912433180185,
                -1.135002713700626
              ],
              [
                -45.617269419437605,
                -1.128648253957731
              ],
              [
                -45.62814633657529,
                -1.126932760445703
              ],
              [
                -45.628100335411716,
                -1.123452771761053
              ],
              [
                -45.62188890298143,
                -1.116296339040023
              ],
              [
                -45.61554946368291,
                -1.115554848778285
              ],
              [
                -45.61101646252997,
                -1.120134817500126
              ],
              [
                -45.60767199422747,
                -1.123607777759979
              ],
              [
                -45.60347202066545,
                -1.126750751396453
              ],
              [
                -45.60548500281577,
                -1.141942139745286
              ],
              [
                -45.602251529994504,
                -1.164233482682461
              ],
              [
                -45.60534202423954,
                -1.175255877778805
              ],
              [
                -45.60972398756511,
                -1.177928884204539
              ],
              [
                -45.610356983096906,
                -1.174537916933032
              ],
              [
                -45.610728493474056,
                -1.172546433043458
              ],
              [
                -45.61313094314754,
                -1.176234896505298
              ],
              [
                -45.62439486252217,
                -1.193524254472367
              ],
              [
                -45.632320784621434,
                -1.220041066325928
              ],
              [
                -45.63224779547946,
                -1.224833008124124
              ],
              [
                -45.632117799222165,
                -1.23335095077939
              ],
              [
                -45.62379134428046,
                -1.233900462363627
              ],
              [
                -45.62193837394321,
                -1.239447905186882
              ],
              [
                -45.623689872379266,
                -1.245138384483992
              ],
              [
                -45.6252663332253,
                -1.250538347953581
              ],
              [
                -45.62292939120575,
                -1.252530798549692
              ],
              [
                -45.62413636177962,
                -1.258962749176499
              ],
              [
                -45.63312079229428,
                -1.273219668966818
              ],
              [
                -45.63388479364302,
                -1.290363028729067
              ],
              [
                -45.63935422546668,
                -1.29919396198273
              ],
              [
                -45.63935874843008,
                -1.29920195492316
              ],
              [
                -45.64032923102986,
                -1.310589886187669
              ],
              [
                -45.63613976713337,
                -1.333383206628824
              ],
              [
                -45.632105798814656,
                -1.332619216726403
              ],
              [
                -45.63233279330912,
                -1.3432406476825
              ],
              [
                -45.63244229661501,
                -1.348353089159517
              ],
              [
                -45.63494275070466,
                -1.350479579545617
              ],
              [
                -45.64106320497011,
                -1.355685038331952
              ],
              [
                -45.6831168883671,
                -1.360293027660192
              ],
              [
                -45.69605428101089,
                -1.361710508159416
              ],
              [
                -45.69601878804361,
                -1.357151527479524
              ],
              [
                -45.6791694316556,
                -1.351634591211321
              ],
              [
                -45.67674493299085,
                -1.349315593275367
              ],
              [
                -45.672722481069734,
                -1.345468117590948
              ],
              [
                -45.67503443795799,
                -1.339578150348632
              ],
              [
                -45.67864543216561,
                -1.336896203150714
              ],
              [
                -45.680150422582635,
                -1.335777699825655
              ],
              [
                -45.68699935587172,
                -1.330692220265223
              ],
              [
                -45.68326737822346,
                -1.323392790650159
              ],
              [
                -45.68216940615212,
                -1.32124578364894
              ],
              [
                -45.675207936944474,
                -1.318933816937844
              ],
              [
                -45.67264097998984,
                -1.312838867458234
              ],
              [
                -45.67797593429201,
                -1.300212438493374
              ]
            ],
            [
              [
                -45.65941156395367,
                -1.296955475075589
              ],
              [
                -45.65756907769072,
                -1.30119594180437
              ],
              [
                -45.65456612407637,
                -1.308106400213857
              ],
              [
                -45.65328613561698,
                -1.311049865813156
              ],
              [
                -45.65116762757087,
                -1.308630391714445
              ],
              [
                -45.65827057180629,
                -1.29105153641627
              ],
              [
                -45.65941156395367,
                -1.296955475075589
              ]
            ]
          ],
          [
            [
              [
                -44.80181329161146,
                -1.529242104070183
              ],
              [
                -44.80940721682535,
                -1.532569548127763
              ],
              [
                -44.79628884604634,
                -1.531133074636024
              ],
              [
                -44.79261936820265,
                -1.530731578014582
              ],
              [
                -44.789634379855094,
                -1.530767091190006
              ],
              [
                -44.78937838591442,
                -1.536049522886099
              ],
              [
                -44.78361844233692,
                -1.537321519119581
              ],
              [
                -44.78730589625425,
                -1.541951489524706
              ],
              [
                -44.78554791485448,
                -1.549164436234072
              ],
              [
                -44.7914828801479,
                -1.558109378203471
              ],
              [
                -44.796361324538374,
                -1.5631623196372
              ],
              [
                -44.8035237694844,
                -1.56471783214841
              ],
              [
                -44.80900473550756,
                -1.562283350727132
              ],
              [
                -44.81133620422706,
                -1.561248323050548
              ],
              [
                -44.81804015466635,
                -1.558270382995187
              ],
              [
                -44.82035866602868,
                -1.554348393297351
              ],
              [
                -44.8282845627255,
                -1.540940508886117
              ],
              [
                -44.826202601768806,
                -1.53795453923701
              ],
              [
                -44.824121130807676,
                -1.53496856059068
              ],
              [
                -44.81142522790532,
                -1.526729123998392
              ],
              [
                -44.808082238790675,
                -1.524559608909854
              ],
              [
                -44.80155678927238,
                -1.520324656976171
              ],
              [
                -44.799133821760215,
                -1.518751658517598
              ],
              [
                -44.796965830069446,
                -1.50145428496745
              ],
              [
                -44.795068832717256,
                -1.4980373406725
              ],
              [
                -44.7931738653477,
                -1.494621843369763
              ],
              [
                -44.77497552749086,
                -1.484016415965504
              ],
              [
                -44.7730435148109,
                -1.48399793475904
              ],
              [
                -44.77201205399005,
                -1.48398842364607
              ],
              [
                -44.76913957176852,
                -1.483962424327858
              ],
              [
                -44.76336759054277,
                -1.483909916690643
              ],
              [
                -44.768368055582755,
                -1.491280351364566
              ],
              [
                -44.78002195810784,
                -1.508459761465898
              ],
              [
                -44.788485399758116,
                -1.520935660909553
              ],
              [
                -44.79216636463341,
                -1.526139620925896
              ],
              [
                -44.793511860890206,
                -1.52657259087512
              ],
              [
                -44.79456534147886,
                -1.526911116490258
              ],
              [
                -44.80181329161146,
                -1.529242104070183
              ]
            ]
          ],
          [
            [
              [
                -44.77442100711239,
                -1.527162103848596
              ],
              [
                -44.777897479078696,
                -1.529747591783484
              ],
              [
                -44.78499991707263,
                -1.528130588424895
              ],
              [
                -44.78455942679858,
                -1.526849113110781
              ],
              [
                -44.783358434956405,
                -1.523355623517458
              ],
              [
                -44.780257466178476,
                -1.514332681731673
              ],
              [
                -44.76101513637246,
                -1.488541402931665
              ],
              [
                -44.7613466192276,
                -1.480187964725542
              ],
              [
                -44.76323562368144,
                -1.477745463700742
              ],
              [
                -44.77206554368382,
                -1.466327575397576
              ],
              [
                -44.77189955876678,
                -1.461950089771191
              ],
              [
                -44.75997665276191,
                -1.459311114801111
              ],
              [
                -44.753018695206435,
                -1.45777114232653
              ],
              [
                -44.74210526359043,
                -1.470165018781076
              ],
              [
                -44.736723825890344,
                -1.51158820668386
              ],
              [
                -44.74330976849924,
                -1.517940662429984
              ],
              [
                -44.744586737146946,
                -1.519173173263261
              ],
              [
                -44.768577054238456,
                -1.522815149909406
              ],
              [
                -44.77442100711239,
                -1.527162103848596
              ]
            ]
          ],
          [
            [
              [
                -45.68520690160612,
                -1.196060238278853
              ],
              [
                -45.680128943535145,
                -1.187540326854229
              ],
              [
                -45.67287297730414,
                -1.200965209425854
              ],
              [
                -45.66566755061947,
                -1.214684122781412
              ],
              [
                -45.663336064534846,
                -1.216805594416379
              ],
              [
                -45.66052409214232,
                -1.219365558668103
              ],
              [
                -45.657942594979275,
                -1.221714043550057
              ],
              [
                -45.65894560560045,
                -1.225309515558836
              ],
              [
                -45.66166558887041,
                -1.235064966336233
              ],
              [
                -45.65946309085801,
                -1.243245899257427
              ],
              [
                -45.66553151619397,
                -1.251238834876611
              ],
              [
                -45.67530845586313,
                -1.2570823033048
              ],
              [
                -45.6829998890327,
                -1.261679756823362
              ],
              [
                -45.68517037152147,
                -1.258719766484657
              ],
              [
                -45.690727844242645,
                -1.251141838497683
              ],
              [
                -45.69726230552732,
                -1.24223289369547
              ],
              [
                -45.69272233671708,
                -1.244152895517476
              ],
              [
                -45.699548260901054,
                -1.234028965923186
              ],
              [
                -45.699337283680215,
                -1.219780576376184
              ],
              [
                -45.69722829556447,
                -1.217065594542862
              ],
              [
                -45.6899038467628,
                -1.217363101197772
              ],
              [
                -45.69649430293407,
                -1.212786131722657
              ],
              [
                -45.68825685408686,
                -1.200722195539568
              ],
              [
                -45.68283940228099,
                -1.215329091486993
              ],
              [
                -45.6750344621967,
                -1.215976577429573
              ],
              [
                -45.681316416709336,
                -1.211786620995011
              ],
              [
                -45.68520690160612,
                -1.196060238278853
              ]
            ]
          ],
          [
            [
              [
                -45.54191252003608,
                -1.195020749025735
              ],
              [
                -45.54047651642859,
                -1.200908694263728
              ],
              [
                -45.53235156865338,
                -1.203851182797542
              ],
              [
                -45.52841712228191,
                -1.205209154938791
              ],
              [
                -45.5257761506044,
                -1.214539586944426
              ],
              [
                -45.52405764776192,
                -1.223378023814788
              ],
              [
                -45.52882110380662,
                -1.220886039384029
              ],
              [
                -45.529284102035,
                -1.228575000287651
              ],
              [
                -45.532238599435914,
                -1.231585955959582
              ],
              [
                -45.53499654501402,
                -1.230340463797738
              ],
              [
                -45.5417465055879,
                -1.227291988874727
              ],
              [
                -45.557121392255944,
                -1.226630502198542
              ],
              [
                -45.575877740259656,
                -1.234104949535238
              ],
              [
                -45.58968964321696,
                -1.246772863919616
              ],
              [
                -45.59288460827521,
                -1.246323866802181
              ],
              [
                -45.59049510846092,
                -1.243145876445473
              ],
              [
                -45.576718751061854,
                -1.224826013797855
              ],
              [
                -45.57011527908742,
                -1.209141622386958
              ],
              [
                -45.56663031410962,
                -1.210957137139457
              ],
              [
                -45.562000344012,
                -1.213368620213049
              ],
              [
                -45.559092872052126,
                -1.211535116644808
              ],
              [
                -45.5659243251946,
                -1.207170656667913
              ],
              [
                -45.570423296386394,
                -1.204295688076777
              ],
              [
                -45.56632931785466,
                -1.201490671683394
              ],
              [
                -45.57143427211506,
                -1.193007255596207
              ],
              [
                -45.57000629476782,
                -1.18975277900191
              ],
              [
                -45.557347880121846,
                -1.188196789919616
              ],
              [
                -45.55427242426354,
                -1.187818806329572
              ],
              [
                -45.54550048623288,
                -1.192930271370467
              ],
              [
                -45.54191252003608,
                -1.195020749025735
              ]
            ]
          ],
          [
            [
              [
                -44.61721270465729,
                -1.79126706632761
              ],
              [
                -44.61138576447577,
                -1.784214615762949
              ],
              [
                -44.604630806208135,
                -1.779335665462044
              ],
              [
                -44.59978533770211,
                -1.774740661418394
              ],
              [
                -44.59686536850188,
                -1.771972206880502
              ],
              [
                -44.59419289528452,
                -1.76943771961594
              ],
              [
                -44.59311340808322,
                -1.768413729186226
              ],
              [
                -44.575973543013035,
                -1.779582125427923
              ],
              [
                -44.577095045582894,
                -1.7819891273305
              ],
              [
                -44.578218506136594,
                -1.784396602231204
              ],
              [
                -44.60279334504644,
                -1.797002033541114
              ],
              [
                -44.60160034777696,
                -1.805163433145317
              ],
              [
                -44.600393344717546,
                -1.81283289949635
              ],
              [
                -44.60067285139683,
                -1.813288890086406
              ],
              [
                -44.60246731749643,
                -1.81621987516911
              ],
              [
                -44.60647530148946,
                -1.816889862948813
              ],
              [
                -44.61093126390714,
                -1.817634845256915
              ],
              [
                -44.61106676682855,
                -1.81764185323334
              ],
              [
                -44.616155225326835,
                -1.817900855379548
              ],
              [
                -44.61693421602385,
                -1.813800385755131
              ],
              [
                -44.617981695971,
                -1.798617517540768
              ],
              [
                -44.61721270465729,
                -1.79126706632761
              ]
            ]
          ],
          [
            [
              [
                -44.728810855381965,
                -1.562094332987109
              ],
              [
                -44.755527160550436,
                -1.582919177344932
              ],
              [
                -44.756392169776426,
                -1.577175201277562
              ],
              [
                -44.753811156315926,
                -1.555451884347224
              ],
              [
                -44.74534873085566,
                -1.547781438233128
              ],
              [
                -44.74261276743516,
                -1.537600497354297
              ],
              [
                -44.73625779083807,
                -1.537634505911616
              ],
              [
                -44.72325592236041,
                -1.546456934194835
              ],
              [
                -44.722815433800065,
                -1.546755952831625
              ],
              [
                -44.722593912487135,
                -1.547137440880432
              ],
              [
                -44.72021342061447,
                -1.551243421116526
              ],
              [
                -44.728810855381965,
                -1.562094332987109
              ]
            ]
          ],
          [
            [
              [
                -45.798436502207714,
                -1.126643778317985
              ],
              [
                -45.79463855530393,
                -1.137761201936754
              ],
              [
                -45.8037784641662,
                -1.150839611612429
              ],
              [
                -45.808140423521664,
                -1.148101636874386
              ],
              [
                -45.810327923970846,
                -1.151565587072045
              ],
              [
                -45.812968401606334,
                -1.149909599931102
              ],
              [
                -45.81661285449983,
                -1.147622143692
              ],
              [
                -45.81795135770792,
                -1.128571767500261
              ],
              [
                -45.81664736987015,
                -1.121357313718668
              ],
              [
                -45.80468196940628,
                -1.112569890341237
              ],
              [
                -45.80108198521799,
                -1.114583384656796
              ],
              [
                -45.79758951020016,
                -1.116536863438558
              ],
              [
                -45.79310106387868,
                -1.119047339879354
              ],
              [
                -45.79325753204914,
                -1.123493320361479
              ],
              [
                -45.798436502207714,
                -1.126643778317985
              ]
            ]
          ],
          [
            [
              [
                -45.79419455258506,
                -1.188348303496285
              ],
              [
                -45.78720758235351,
                -1.18664233932781
              ],
              [
                -45.78134765356063,
                -1.190254323241718
              ],
              [
                -45.77881913597091,
                -1.199331216453347
              ],
              [
                -45.778278643905054,
                -1.20127173674778
              ],
              [
                -45.77635568265675,
                -1.196192770743807
              ],
              [
                -45.775504700246614,
                -1.210167137294673
              ],
              [
                -45.785854088880775,
                -1.227597539524504
              ],
              [
                -45.78812456364504,
                -1.224372536162444
              ],
              [
                -45.79082807412112,
                -1.220533583489959
              ],
              [
                -45.795853498968185,
                -1.21339564501745
              ],
              [
                -45.79831348936809,
                -1.195758775261339
              ],
              [
                -45.79419455258506,
                -1.188348303496285
              ]
            ]
          ],
          [
            [
              [
                -45.55760987654829,
                -1.328899223582959
              ],
              [
                -45.55429390828959,
                -1.317899327098755
              ],
              [
                -45.54995742051064,
                -1.310046845700468
              ],
              [
                -45.54102251321586,
                -1.305565401113734
              ],
              [
                -45.535149555694595,
                -1.302619912424431
              ],
              [
                -45.531763554242794,
                -1.308807868128408
              ],
              [
                -45.531265595999756,
                -1.309717876172908
              ],
              [
                -45.53245606146028,
                -1.317312316991757
              ],
              [
                -45.52943708028786,
                -1.316234302646493
              ],
              [
                -45.533857553938944,
                -1.326902721777991
              ],
              [
                -45.54229849750219,
                -1.328793745875417
              ],
              [
                -45.550355419771634,
                -1.326411247182708
              ],
              [
                -45.55760987654829,
                -1.328899223582959
              ]
            ]
          ],
          [
            [
              [
                -45.090044529013305,
                -1.419250465601699
              ],
              [
                -45.093048001209986,
                -1.43152987784773
              ],
              [
                -45.09433549283121,
                -1.427008398414758
              ],
              [
                -45.09672298143412,
                -1.418620961539555
              ],
              [
                -45.08677407460667,
                -1.402827586821891
              ],
              [
                -45.08560858236794,
                -1.399475105099857
              ],
              [
                -45.07758713751484,
                -1.376410804066168
              ],
              [
                -45.07811312539033,
                -1.370205823334487
              ],
              [
                -45.06856721516969,
                -1.367478380482712
              ],
              [
                -45.06543825024903,
                -1.371207838596478
              ],
              [
                -45.068548201040976,
                -1.375224819568126
              ],
              [
                -45.0674977026656,
                -1.385251233150195
              ],
              [
                -45.07426416152935,
                -1.389666683724548
              ],
              [
                -45.074968152897114,
                -1.390126200350517
              ],
              [
                -45.07851012356034,
                -1.392437182383822
              ],
              [
                -45.07849312373519,
                -1.398147112979298
              ],
              [
                -45.090044529013305,
                -1.419250465601699
              ]
            ]
          ],
          [
            [
              [
                -45.0969064593677,
                -1.468195618127596
              ],
              [
                -45.08887803678542,
                -1.450676749704848
              ],
              [
                -45.070608173298396,
                -1.460101638888043
              ],
              [
                -45.068668690560386,
                -1.466032109502546
              ],
              [
                -45.07340763638376,
                -1.476066542155521
              ],
              [
                -45.09541898654564,
                -1.471339582996267
              ],
              [
                -45.0969064593677,
                -1.468195618127596
              ]
            ]
          ],
          [
            [
              [
                -45.0361169795309,
                -1.390364699954234
              ],
              [
                -45.041736898750926,
                -1.386818689878214
              ],
              [
                -45.044993380947616,
                -1.363205410064737
              ],
              [
                -45.04881037412689,
                -1.349033985530026
              ],
              [
                -45.045500913854205,
                -1.345326025205329
              ],
              [
                -45.043098422256676,
                -1.342633557304203
              ],
              [
                -45.04226142129115,
                -1.346180023174526
              ],
              [
                -45.04103294714548,
                -1.351384464362888
              ],
              [
                -45.037821459277296,
                -1.364996360245288
              ],
              [
                -45.03279849541567,
                -1.386283204441849
              ],
              [
                -45.0361169795309,
                -1.390364699954234
              ]
            ]
          ],
          [
            [
              [
                -44.74924167802554,
                -1.69202832638066
              ],
              [
                -44.73484080425265,
                -1.682156398557108
              ],
              [
                -44.73023085681164,
                -1.687192369289199
              ],
              [
                -44.725831873743125,
                -1.691997352819872
              ],
              [
                -44.740814741044524,
                -1.701020284239194
              ],
              [
                -44.751859671897584,
                -1.693822309269518
              ],
              [
                -44.74924167802554,
                -1.69202832638066
              ]
            ]
          ],
          [
            [
              [
                -45.685098382153136,
                -1.296816975229153
              ],
              [
                -45.67797593429201,
                -1.300212438493374
              ],
              [
                -45.67602693837102,
                -1.313762370046244
              ],
              [
                -45.68259238957025,
                -1.316736827695143
              ],
              [
                -45.683698892396514,
                -1.313768868240761
              ],
              [
                -45.68502088262969,
                -1.310223409173547
              ],
              [
                -45.6869113480625,
                -1.308909880377983
              ],
              [
                -45.689355840226675,
                -1.307210929575879
              ],
              [
                -45.69260282422675,
                -1.304835430000721
              ],
              [
                -45.69098233603879,
                -1.291263537028311
              ],
              [
                -45.69059232360007,
                -1.287999040573202
              ],
              [
                -45.68937983877101,
                -1.289945545707782
              ],
              [
                -45.685098382153136,
                -1.296816975229153
              ]
            ]
          ],
          [
            [
              [
                -44.46838034841426,
                -2.075885351904202
              ],
              [
                -44.471209802682864,
                -2.076651369791202
              ],
              [
                -44.482172231730736,
                -2.075130374088165
              ],
              [
                -44.44402404702898,
                -2.057456988919385
              ],
              [
                -44.45129546090549,
                -2.070384413446126
              ],
              [
                -44.46838034841426,
                -2.075885351904202
              ]
            ]
          ],
          [
            [
              [
                -44.480206749681024,
                -2.106096626392807
              ],
              [
                -44.476212782147726,
                -2.103500164098089
              ],
              [
                -44.4758822897169,
                -2.095751701863906
              ],
              [
                -44.46939580909522,
                -2.100947674758479
              ],
              [
                -44.46340938481761,
                -2.100844661026418
              ],
              [
                -44.467871334541016,
                -2.107736115811637
              ],
              [
                -44.46745881900861,
                -2.114976050721928
              ],
              [
                -44.46724332777367,
                -2.118754518973012
              ],
              [
                -44.467322317425335,
                -2.121603999355065
              ],
              [
                -44.47233227067126,
                -2.124924002427168
              ],
              [
                -44.47149979309511,
                -2.117828034094278
              ],
              [
                -44.47549774956332,
                -2.116563049719157
              ],
              [
                -44.48536367478048,
                -2.113354572593694
              ],
              [
                -44.47441175559222,
                -2.111088616012761
              ],
              [
                -44.480206749681024,
                -2.106096626392807
              ]
            ]
          ],
          [
            [
              [
                -45.39762310019574,
                -1.395382683458315
              ],
              [
                -45.39726860449052,
                -1.404160637022263
              ],
              [
                -45.397169604851086,
                -1.406608097500001
              ],
              [
                -45.4063625346723,
                -1.418293515755796
              ],
              [
                -45.40912752069417,
                -1.414044045355863
              ],
              [
                -45.41012700411071,
                -1.412508091138902
              ],
              [
                -45.41262250817561,
                -1.408673583554638
              ],
              [
                -45.40615054007424,
                -1.396378676387766
              ],
              [
                -45.402993564288835,
                -1.39384270002242
              ],
              [
                -45.39762310019574,
                -1.395382683458315
              ]
            ]
          ],
          [
            [
              [
                -45.071731164858775,
                -1.446252281279994
              ],
              [
                -45.070152678129325,
                -1.453052214349201
              ],
              [
                -45.078593637869226,
                -1.439548316411724
              ],
              [
                -45.070382203346334,
                -1.424016442981775
              ],
              [
                -45.065130242288454,
                -1.426972918602814
              ],
              [
                -45.061484766886586,
                -1.439578302181498
              ],
              [
                -45.065075246870165,
                -1.437716341958476
              ],
              [
                -45.072065171293,
                -1.440093308125698
              ],
              [
                -45.063106755300794,
                -1.444093271162415
              ],
              [
                -45.068284684193806,
                -1.445474264582743
              ],
              [
                -45.071731164858775,
                -1.446252281279994
              ]
            ]
          ],
          [
            [
              [
                -44.72280891176847,
                -1.661879069485003
              ],
              [
                -44.72047940366309,
                -1.664844052439145
              ],
              [
                -44.72008440769718,
                -1.665347518525933
              ],
              [
                -44.717559939088694,
                -1.668562016540065
              ],
              [
                -44.722691901705616,
                -1.676864955364008
              ],
              [
                -44.73623080107016,
                -1.672962492815986
              ],
              [
                -44.73736577713167,
                -1.672635990531053
              ],
              [
                -44.73479728375796,
                -1.66637554266648
              ],
              [
                -44.72280891176847,
                -1.661879069485003
              ]
            ]
          ],
          [
            [
              [
                -44.44509453936228,
                -2.025062750552425
              ],
              [
                -44.45705894588998,
                -2.029742724876066
              ],
              [
                -44.45417396825379,
                -2.024039765546821
              ],
              [
                -44.45217798710475,
                -2.020108285965652
              ],
              [
                -44.44994601094645,
                -2.015711799868515
              ],
              [
                -44.44580454988938,
                -2.007609883507458
              ],
              [
                -44.44263756301787,
                -2.012258862367402
              ],
              [
                -44.4385660949692,
                -2.018512317798716
              ],
              [
                -44.44509453936228,
                -2.025062750552425
              ]
            ]
          ],
          [
            [
              [
                -44.622565142641086,
                -1.812088913012687
              ],
              [
                -44.62271365709701,
                -1.818383860144599
              ],
              [
                -44.62452315756263,
                -1.814480395243667
              ],
              [
                -44.62938360720091,
                -1.803998447068837
              ],
              [
                -44.63094009856417,
                -1.800596484296475
              ],
              [
                -44.63285807824239,
                -1.796316042288091
              ],
              [
                -44.63172959901847,
                -1.792539075788183
              ],
              [
                -44.63037759770449,
                -1.788128090068386
              ],
              [
                -44.622230667366146,
                -1.797851501294427
              ],
              [
                -44.622565142641086,
                -1.812088913012687
              ]
            ]
          ],
          [
            [
              [
                -45.08918703564361,
                -1.431686390984845
              ],
              [
                -45.087112058735315,
                -1.421671460701297
              ],
              [
                -45.08296907766398,
                -1.42026747865005
              ],
              [
                -45.079107118360746,
                -1.418958489927316
              ],
              [
                -45.082184088222114,
                -1.441369790206791
              ],
              [
                -45.08490108406779,
                -1.439734309106193
              ],
              [
                -45.08784006318583,
                -1.437965321057799
              ],
              [
                -45.08918703564361,
                -1.431686390984845
              ]
            ]
          ],
          [
            [
              [
                -45.515137704498564,
                -1.406052153078464
              ],
              [
                -45.522117637925966,
                -1.405284651076744
              ],
              [
                -45.52261762574664,
                -1.400948690881611
              ],
              [
                -45.513993715145645,
                -1.390263743042798
              ],
              [
                -45.51232673036702,
                -1.389340781724848
              ],
              [
                -45.511363745004815,
                -1.38880776758393
              ],
              [
                -45.50807824254828,
                -1.390048253658267
              ],
              [
                -45.50673326600516,
                -1.390556775589416
              ],
              [
                -45.50955973605484,
                -1.395257222591001
              ],
              [
                -45.51123623244161,
                -1.398043207850165
              ],
              [
                -45.515137704498564,
                -1.406052153078464
              ]
            ]
          ],
          [
            [
              [
                -45.04776389587737,
                -1.3273641652911
              ],
              [
                -45.04353692950934,
                -1.331977113490675
              ],
              [
                -45.04327790941352,
                -1.339076577407258
              ],
              [
                -45.05000987744448,
                -1.344179031673301
              ],
              [
                -45.05447881148526,
                -1.329774154033778
              ],
              [
                -45.04776389587737,
                -1.3273641652911
              ]
            ]
          ],
          [
            [
              [
                -45.790640547545785,
                -1.247342362548455
              ],
              [
                -45.796756525796184,
                -1.247514882192765
              ],
              [
                -45.801480960786336,
                -1.241908905129403
              ],
              [
                -45.79836699561148,
                -1.238414959960479
              ],
              [
                -45.794226536507026,
                -1.234514981696678
              ],
              [
                -45.78791757864775,
                -1.235113955222313
              ],
              [
                -45.790640547545785,
                -1.247342362548455
              ]
            ]
          ],
          [
            [
              [
                -45.00204722779103,
                -1.401220074572336
              ],
              [
                -45.000574730301494,
                -1.401947085788719
              ],
              [
                -45.0004112625794,
                -1.402027573148304
              ],
              [
                -44.99783376256423,
                -1.410183010647931
              ],
              [
                -45.004099696941076,
                -1.410895514302582
              ],
              [
                -45.00713767757146,
                -1.407180534088944
              ],
              [
                -45.01545560383256,
                -1.395525146189345
              ],
              [
                -45.01089517863121,
                -1.396852113317212
              ],
              [
                -45.00204722779103,
                -1.401220074572336
              ]
            ]
          ],
          [
            [
              [
                -44.61375525074612,
                -1.830106250976029
              ],
              [
                -44.61388773779122,
                -1.829656751425435
              ],
              [
                -44.616218199990115,
                -1.821751326092698
              ],
              [
                -44.61223474876503,
                -1.821232816161924
              ],
              [
                -44.60766079825267,
                -1.820636338695307
              ],
              [
                -44.604488822081414,
                -1.827008776499785
              ],
              [
                -44.60232083085931,
                -1.833681717239501
              ],
              [
                -44.60590779983507,
                -1.831435760124325
              ],
              [
                -44.6133632178477,
                -1.830172777383182
              ],
              [
                -44.61375525074612,
                -1.830106250976029
              ]
            ]
          ],
          [
            [
              [
                -45.626304333471325,
                -1.318554834842915
              ],
              [
                -45.62550684330339,
                -1.321091282582331
              ],
              [
                -45.62491082866178,
                -1.322985793196333
              ],
              [
                -45.62370586241239,
                -1.32718476133094
              ],
              [
                -45.62812131488387,
                -1.33940216392634
              ],
              [
                -45.62874880762268,
                -1.335420710050484
              ],
              [
                -45.630140306490205,
                -1.324586267979206
              ],
              [
                -45.63140678816584,
                -1.31472632954704
              ],
              [
                -45.626304333471325,
                -1.318554834842915
              ]
            ]
          ],
          [
            [
              [
                -44.830967566853,
                -1.560419856075231
              ],
              [
                -44.819476137582754,
                -1.562051356477631
              ],
              [
                -44.81612968947045,
                -1.565392336404831
              ],
              [
                -44.81659417577023,
                -1.570822782147601
              ],
              [
                -44.82395761756966,
                -1.570093775100157
              ],
              [
                -44.82755006304153,
                -1.565136820518481
              ],
              [
                -44.830967566853,
                -1.560419856075231
              ]
            ]
          ],
          [
            [
              [
                -45.56185283602328,
                -1.260878724039564
              ],
              [
                -45.55867136701291,
                -1.260400235161842
              ],
              [
                -45.55731039114621,
                -1.263828728040709
              ],
              [
                -45.559842365669944,
                -1.268027173708921
              ],
              [
                -45.56117835486547,
                -1.270240666192292
              ],
              [
                -45.56241684706142,
                -1.270918668263145
              ],
              [
                -45.5668022903468,
                -1.273319637810025
              ],
              [
                -45.567082295922596,
                -1.268344689440048
              ],
              [
                -45.5643803159881,
                -1.2624537455303
              ],
              [
                -45.56185283602328,
                -1.260878724039564
              ]
            ]
          ],
          [
            [
              [
                -42.750054553925494,
                -2.544328868633379
              ],
              [
                -42.744240601598925,
                -2.557434736365056
              ],
              [
                -42.749195544208796,
                -2.550263816393879
              ],
              [
                -42.75248103953378,
                -2.54967129895786
              ],
              [
                -42.756291501226066,
                -2.557071762757
              ],
              [
                -42.7604514680467,
                -2.557712251031833
              ],
              [
                -42.7580314933996,
                -2.552331293995477
              ],
              [
                -42.75665048856588,
                -2.549321832900288
              ],
              [
                -42.75521851916068,
                -2.546267855136886
              ],
              [
                -42.750054553925494,
                -2.544328868633379
              ]
            ]
          ],
          [
            [
              [
                -44.009260441441164,
                -2.399590784089629
              ],
              [
                -44.00304047519525,
                -2.393059842061786
              ],
              [
                -43.999970498544755,
                -2.390525856491597
              ],
              [
                -43.9969920365139,
                -2.400907797544811
              ],
              [
                -44.00116899707167,
                -2.400635797812901
              ],
              [
                -44.009260441441164,
                -2.399590784089629
              ]
            ]
          ],
          [
            [
              [
                -44.61138576447577,
                -1.784214615762949
              ],
              [
                -44.616666710360406,
                -1.784876096864895
              ],
              [
                -44.61668720791578,
                -1.781500647568981
              ],
              [
                -44.61150577206577,
                -1.776947666123885
              ],
              [
                -44.606164801007345,
                -1.774649669478665
              ],
              [
                -44.60292881863583,
                -1.775101185346257
              ],
              [
                -44.61138576447577,
                -1.784214615762949
              ]
            ]
          ],
          [
            [
              [
                -45.64032923102986,
                -1.310589886187669
              ],
              [
                -45.63782174963243,
                -1.304379418623763
              ],
              [
                -45.63765375102591,
                -1.303879441367906
              ],
              [
                -45.63627424746816,
                -1.299770450140813
              ],
              [
                -45.634176771941064,
                -1.311943849981655
              ],
              [
                -45.63438426716686,
                -1.312889337884175
              ],
              [
                -45.63591224410015,
                -1.319840299710445
              ],
              [
                -45.64032923102986,
                -1.310589886187669
              ]
            ]
          ],
          [
            [
              [
                -45.6537551098229,
                -1.361002005391831
              ],
              [
                -45.643065700864675,
                -1.358360001507711
              ],
              [
                -45.639085240375664,
                -1.36213247341078
              ],
              [
                -45.646865672170456,
                -1.364225488946564
              ],
              [
                -45.6537551098229,
                -1.361002005391831
              ]
            ]
          ],
          [
            [
              [
                -45.64195771418321,
                -1.247053871828455
              ],
              [
                -45.64675668519376,
                -1.250046320980081
              ],
              [
                -45.64913167539293,
                -1.244951388800509
              ],
              [
                -45.64721420174963,
                -1.242571882467224
              ],
              [
                -45.64293822506112,
                -1.243444876222051
              ],
              [
                -45.639345720969615,
                -1.244707886136675
              ],
              [
                -45.639076251062995,
                -1.244802884378171
              ],
              [
                -45.639395252529965,
                -1.245052369543709
              ],
              [
                -45.64195771418321,
                -1.247053871828455
              ]
            ]
          ],
          [
            [
              [
                -44.01616185823658,
                -2.399001811551042
              ],
              [
                -44.009260441441164,
                -2.399590784089629
              ],
              [
                -44.010387908606766,
                -2.402374256211911
              ],
              [
                -44.010535422376726,
                -2.402538268004971
              ],
              [
                -44.01474886524726,
                -2.407206726659528
              ],
              [
                -44.016082365625365,
                -2.403411754942778
              ],
              [
                -44.01612285394585,
                -2.40113428729895
              ],
              [
                -44.01616185823658,
                -2.399001811551042
              ]
            ]
          ],
          [
            [
              [
                -45.139126653347944,
                -1.4685395890822
              ],
              [
                -45.136401651519364,
                -1.46464862011082
              ],
              [
                -45.13472616903664,
                -1.468565110099751
              ],
              [
                -45.13437217968097,
                -1.469393111755022
              ],
              [
                -45.136275138492124,
                -1.476071549437777
              ],
              [
                -45.13829363852552,
                -1.470739108248405
              ],
              [
                -45.139126653347944,
                -1.4685395890822
              ]
            ]
          ],
          [
            [
              [
                -44.775734006979356,
                -1.568848792404576
              ],
              [
                -44.78335243901893,
                -1.571855272951599
              ],
              [
                -44.78012344737365,
                -1.567823295051467
              ],
              [
                -44.772084535867485,
                -1.563757331503697
              ],
              [
                -44.775734006979356,
                -1.568848792404576
              ]
            ]
          ],
          [
            [
              [
                -44.8756742301885,
                -1.339903062799401
              ],
              [
                -44.876942725378136,
                -1.344687506493244
              ],
              [
                -44.88155168770518,
                -1.339682530438692
              ],
              [
                -44.87717673036469,
                -1.339476535299025
              ],
              [
                -44.87554022832993,
                -1.339400049616551
              ],
              [
                -44.8756742301885,
                -1.339903062799401
              ]
            ]
          ],
          [
            [
              [
                -45.04061840859255,
                -1.392734165694292
              ],
              [
                -45.0361169795309,
                -1.390364699954234
              ],
              [
                -45.03777846261492,
                -1.395122667045014
              ],
              [
                -45.03970943184029,
                -1.398267605453878
              ],
              [
                -45.04061840859255,
                -1.392734165694292
              ]
            ]
          ],
          [
            [
              [
                -45.13579464803608,
                -1.488630936096014
              ],
              [
                -45.132910191392625,
                -1.485202459574207
              ],
              [
                -45.13287516972045,
                -1.49048992746709
              ],
              [
                -45.1347886859961,
                -1.494033392943716
              ],
              [
                -45.13579464803608,
                -1.488630936096014
              ]
            ]
          ],
          [
            [
              [
                -44.45764992042571,
                -2.133729926799556
              ],
              [
                -44.45635341653265,
                -2.130673454676288
              ],
              [
                -44.45343193592481,
                -2.130235931249467
              ],
              [
                -44.45113044856439,
                -2.13300293964562
              ],
              [
                -44.45764992042571,
                -2.133729926799556
              ]
            ]
          ],
          [
            [
              [
                -45.81598935505815,
                -1.258576292908211
              ],
              [
                -45.814430362811706,
                -1.254714831727392
              ],
              [
                -45.81385288705156,
                -1.256441298643797
              ],
              [
                -45.81267789967714,
                -1.259956276006807
              ],
              [
                -45.817064860258725,
                -1.261241793016547
              ],
              [
                -45.81598935505815,
                -1.258576292908211
              ]
            ]
          ],
          [
            [
              [
                -47.03132492651205,
                -8.980129832244042
              ],
              [
                -47.03102090672557,
                -8.985490274307995
              ],
              [
                -47.03295839935254,
                -8.982324800928529
              ],
              [
                -47.03384389215482,
                -8.980877834268352
              ],
              [
                -47.03527240076964,
                -8.978543363949969
              ],
              [
                -47.031358430933956,
                -8.979534846121753
              ],
              [
                -47.03132492651205,
                -8.980129832244042
              ]
            ]
          ],
          [
            [
              [
                -45.108299890227066,
                -1.400299628709363
              ],
              [
                -45.10367843335999,
                -1.402187599576305
              ],
              [
                -45.10626942027552,
                -1.406017057928227
              ],
              [
                -45.108299890227066,
                -1.400299628709363
              ]
            ]
          ],
          [
            [
              [
                -45.111029879303175,
                -1.390738183814906
              ],
              [
                -45.10648041164207,
                -1.393976686436859
              ],
              [
                -45.108271400057944,
                -1.396701660038603
              ],
              [
                -45.111029879303175,
                -1.390738183814906
              ]
            ]
          ],
          [
            [
              [
                -45.55867136701291,
                -1.260400235161842
              ],
              [
                -45.55596340271017,
                -1.257208766862212
              ],
              [
                -45.55509539396072,
                -1.260789725663427
              ],
              [
                -45.55867136701291,
                -1.260400235161842
              ]
            ]
          ],
          [
            [
              [
                -44.756861140091786,
                -1.592813112222323
              ],
              [
                -44.75531566643674,
                -1.587090145525147
              ],
              [
                -44.75415767606277,
                -1.590023601952774
              ],
              [
                -44.756861140091786,
                -1.592813112222323
              ]
            ]
          ],
          [
            [
              [
                -45.764090291592666,
                -1.187978824859797
              ],
              [
                -45.76179430486803,
                -1.185552334819476
              ],
              [
                -45.76082178492454,
                -1.189250810788784
              ],
              [
                -45.764090291592666,
                -1.187978824859797
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 11,
        "nome": "Minas Gerais",
        "sigla": "MG",
        "regiao_id": 2,
        "codigo_ibg": 31
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -44.208857532783014,
                -14.244126594316711
              ],
              [
                -44.208710003273445,
                -14.246226093266593
              ],
              [
                -44.200823078378335,
                -14.25069154272213
              ],
              [
                -44.20087655700763,
                -14.254031546448008
              ],
              [
                -44.182515696771404,
                -14.257784502552449
              ],
              [
                -44.176084765295194,
                -14.263148465977839
              ],
              [
                -44.17253227833807,
                -14.26611242690054
              ],
              [
                -44.16470683303973,
                -14.267723901851582
              ],
              [
                -44.16239836506418,
                -14.264921433045837
              ],
              [
                -44.162356859495446,
                -14.264871421280823
              ],
              [
                -44.16014441510861,
                -14.257909938013292
              ],
              [
                -44.13685358803842,
                -14.263704895336083
              ],
              [
                -44.13559957363204,
                -14.27427080174195
              ],
              [
                -44.13557708448197,
                -14.27445981755062
              ],
              [
                -44.14320501014214,
                -14.278048813541936
              ],
              [
                -44.135204585623015,
                -14.281740282501948
              ],
              [
                -44.12392968352059,
                -14.274525806475603
              ],
              [
                -44.12320720125943,
                -14.273526787352635
              ],
              [
                -44.11218378231996,
                -14.258284388802995
              ],
              [
                -44.10687283147909,
                -14.258676858449647
              ],
              [
                -44.10562235131377,
                -14.266848815437028
              ],
              [
                -44.10045489547328,
                -14.271649278404881
              ],
              [
                -44.098988400166796,
                -14.27301224660749
              ],
              [
                -44.09323694041177,
                -14.273158748006386
              ],
              [
                -44.09039193779711,
                -14.270968757580107
              ],
              [
                -44.08651099715499,
                -14.267980772282968
              ],
              [
                -44.08707097350655,
                -14.27194025104353
              ],
              [
                -44.08787495476738,
                -14.277631233644472
              ],
              [
                -44.08199000505313,
                -14.274999709784147
              ],
              [
                -44.08200300474333,
                -14.273422754437915
              ],
              [
                -44.08204353105397,
                -14.26854824828134
              ],
              [
                -44.07404108724646,
                -14.27019575471975
              ],
              [
                -44.07632207072952,
                -14.275084738701267
              ],
              [
                -44.07754756294282,
                -14.277712214720092
              ],
              [
                -44.07279008351769,
                -14.279816185840268
              ],
              [
                -44.07298607631318,
                -14.285216634186279
              ],
              [
                -44.068018634894436,
                -14.279280671059523
              ],
              [
                -44.06093367974818,
                -14.281926643939828
              ],
              [
                -44.05938220991699,
                -14.280040176847265
              ],
              [
                -44.057018231550046,
                -14.277167199413288
              ],
              [
                -44.05686870438526,
                -14.28077564311296
              ],
              [
                -44.05674173004009,
                -14.283816144322241
              ],
              [
                -44.04901328297339,
                -14.28434862102766
              ],
              [
                -44.04112634363766,
                -14.285775587000517
              ],
              [
                -44.038018852287514,
                -14.2818516077396
              ],
              [
                -44.03409688105167,
                -14.28842506994839
              ],
              [
                -44.025158974343704,
                -14.2763611526196
              ],
              [
                -44.015036079578586,
                -14.276063123380968
              ],
              [
                -44.01505855215314,
                -14.27281165330351
              ],
              [
                -44.0150700680868,
                -14.271101668781043
              ],
              [
                -44.00887560891949,
                -14.27188912252975
              ],
              [
                -44.00573964465079,
                -14.266921660635125
              ],
              [
                -43.98596280890754,
                -14.267108619197167
              ],
              [
                -43.99018180308157,
                -14.272150112449191
              ],
              [
                -43.98478980189685,
                -14.274292098262844
              ],
              [
                -43.98218884858602,
                -14.27532556055903
              ],
              [
                -43.98042533187668,
                -14.28170652254415
              ],
              [
                -43.97329291203331,
                -14.278804527288507
              ],
              [
                -43.970719451182525,
                -14.277757550362468
              ],
              [
                -43.96917395435855,
                -14.273584581301632
              ],
              [
                -43.964146491231986,
                -14.284729489623423
              ],
              [
                -43.961514496683165,
                -14.290561938495905
              ],
              [
                -43.95493153908059,
                -14.291741926182572
              ],
              [
                -43.95572552218563,
                -14.299258876520218
              ],
              [
                -43.95212154296621,
                -14.292517907698738
              ],
              [
                -43.950534596360924,
                -14.289549961190998
              ],
              [
                -43.94588062896504,
                -14.289506443618102
              ],
              [
                -43.94436663472031,
                -14.29502639727886
              ],
              [
                -43.94172762151923,
                -14.304645837956269
              ],
              [
                -43.93541817965162,
                -14.296244367746551
              ],
              [
                -43.93465919872458,
                -14.295234857647985
              ],
              [
                -43.93356870651924,
                -14.296496378087905
              ],
              [
                -43.92601778057678,
                -14.30522679791824
              ],
              [
                -43.924057293988845,
                -14.297791334136479
              ],
              [
                -43.92355728948723,
                -14.295895856900303
              ],
              [
                -43.9168443417974,
                -14.29549285706243
              ],
              [
                -43.91589636560943,
                -14.298902825583747
              ],
              [
                -43.91497285037612,
                -14.302223327695046
              ],
              [
                -43.91118988751154,
                -14.304593303809327
              ],
              [
                -43.90871339543369,
                -14.302105314872973
              ],
              [
                -43.90950290157034,
                -14.300029817913384
              ],
              [
                -43.91057340768249,
                -14.297218339934794
              ],
              [
                -43.904848424428785,
                -14.299859284118794
              ],
              [
                -43.90401444618715,
                -14.29316684270778
              ],
              [
                -43.90124446007825,
                -14.29828181171204
              ],
              [
                -43.89207102702146,
                -14.30057379322588
              ],
              [
                -43.89171055037953,
                -14.29528032804967
              ],
              [
                -43.888885585922715,
                -14.304804754942749
              ],
              [
                -43.888445081940695,
                -14.306290734097947
              ],
              [
                -43.880982615566644,
                -14.306975702333503
              ],
              [
                -43.88051463268315,
                -14.311357208702942
              ],
              [
                -43.872428183543896,
                -14.313140186157847
              ],
              [
                -43.86954273373366,
                -14.309598676997195
              ],
              [
                -43.86112428051079,
                -14.3124246625033
              ],
              [
                -43.86001177783491,
                -14.311802665441617
              ],
              [
                -43.85229438144941,
                -14.307490678877409
              ],
              [
                -43.85104136185911,
                -14.31312665322253
              ],
              [
                -43.85169886610783,
                -14.313860636831004
              ],
              [
                -43.85504334776484,
                -14.317594634579653
              ],
              [
                -43.851015340328196,
                -14.318629123455322
              ],
              [
                -43.847985895265346,
                -14.314779629946296
              ],
              [
                -43.84631840484301,
                -14.31266111412422
              ],
              [
                -43.84646137529233,
                -14.315126132255385
              ],
              [
                -43.84681788541871,
                -14.321289103083238
              ],
              [
                -43.841511441400534,
                -14.316219624217986
              ],
              [
                -43.83573749404675,
                -14.310704149311103
              ],
              [
                -43.82592857949303,
                -14.315734088961191
              ],
              [
                -43.81919261983588,
                -14.312078095045282
              ],
              [
                -43.82141108324071,
                -14.320660552149414
              ],
              [
                -43.82190709672771,
                -14.322579550218501
              ],
              [
                -43.81828662193576,
                -14.321351048655274
              ],
              [
                -43.8149096519779,
                -14.32020552899473
              ],
              [
                -43.81467966056968,
                -14.322643012660478
              ],
              [
                -43.8144091337336,
                -14.32551149261593
              ],
              [
                -43.810659693361195,
                -14.325217014108269
              ],
              [
                -43.80824167775892,
                -14.325027007202271
              ],
              [
                -43.80770869349877,
                -14.327106008555765
              ],
              [
                -43.80643570026059,
                -14.332073459951834
              ],
              [
                -43.79967574894963,
                -14.337379419029766
              ],
              [
                -43.79336030828413,
                -14.336292927053421
              ],
              [
                -43.79229332346426,
                -14.336109394381772
              ],
              [
                -43.790745340535054,
                -14.336547410088144
              ],
              [
                -43.782788890582395,
                -14.338798863878338
              ],
              [
                -43.80420966892929,
                -14.371971707659503
              ],
              [
                -43.818988004197266,
                -14.388892126009363
              ],
              [
                -43.83009185547848,
                -14.436312850680476
              ],
              [
                -43.836016255543505,
                -14.449049784875237
              ],
              [
                -43.83601826851877,
                -14.44905428785114
              ],
              [
                -43.84194068258564,
                -14.461786210114132
              ],
              [
                -43.87347035621696,
                -14.520136404695826
              ],
              [
                -43.88090273010978,
                -14.561976192413088
              ],
              [
                -43.8794857292991,
                -14.56229018207254
              ],
              [
                -43.87676524378179,
                -14.56289316673817
              ],
              [
                -43.880678190961184,
                -14.571391099685004
              ],
              [
                -43.886121144420514,
                -14.583214051032252
              ],
              [
                -43.88224818697242,
                -14.58629904860477
              ],
              [
                -43.88519363336994,
                -14.594122995036335
              ],
              [
                -43.883272616433956,
                -14.597919458630926
              ],
              [
                -43.88309962699414,
                -14.597298469113232
              ],
              [
                -43.880987186265,
                -14.589708493684832
              ],
              [
                -43.875091205121876,
                -14.592508453518246
              ],
              [
                -43.87647416833188,
                -14.608691396395194
              ],
              [
                -43.86965574623115,
                -14.612381862217813
              ],
              [
                -43.87403817594337,
                -14.613989851966128
              ],
              [
                -43.87063121631093,
                -14.621659293442432
              ],
              [
                -43.88026962834576,
                -14.628255276900168
              ],
              [
                -43.881344618089024,
                -14.628990765276999
              ],
              [
                -43.879892600431724,
                -14.630482258706541
              ],
              [
                -43.8742141629673,
                -14.636316209449763
              ],
              [
                -43.87900210926665,
                -14.635744218172185
              ],
              [
                -43.88537055779765,
                -14.634982214771806
              ],
              [
                -43.87846813497731,
                -14.63889371510014
              ],
              [
                -43.87673612756876,
                -14.639875706145752
              ],
              [
                -43.87820261432684,
                -14.640464694089898
              ],
              [
                -43.88329158337326,
                -14.642510202418652
              ],
              [
                -43.878876092347916,
                -14.645631142836177
              ],
              [
                -43.88309705831386,
                -14.6527811096445
              ],
              [
                -43.87870807201406,
                -14.6575795959101
              ],
              [
                -43.87548262926471,
                -14.651819098367195
              ],
              [
                -43.872851156703184,
                -14.653141591791432
              ],
              [
                -43.87149313379226,
                -14.663137535684509
              ],
              [
                -43.86308022391665,
                -14.661884521793478
              ],
              [
                -43.86290269143792,
                -14.661858537645353
              ],
              [
                -43.86279069677369,
                -14.662185028467315
              ],
              [
                -43.85878769877947,
                -14.673849960652023
              ],
              [
                -43.852622750968735,
                -14.672753463680836
              ],
              [
                -43.84645781116099,
                -14.671657463709813
              ],
              [
                -43.8202205144369,
                -14.680266340104325
              ],
              [
                -43.800096183646,
                -14.686869776226263
              ],
              [
                -43.794390235188686,
                -14.68625576912034
              ],
              [
                -43.78524679298324,
                -14.685271768340693
              ],
              [
                -43.77688486213646,
                -14.690966726266156
              ],
              [
                -43.77573937623972,
                -14.691747203541198
              ],
              [
                -43.77407089501569,
                -14.691800229341142
              ],
              [
                -43.763155988143865,
                -14.692147200408277
              ],
              [
                -43.75918502377462,
                -14.69620917483674
              ],
              [
                -43.7552130304139,
                -14.700271630259683
              ],
              [
                -43.74920859230791,
                -14.69974863508738
              ],
              [
                -43.72584778335632,
                -14.697713085207246
              ],
              [
                -43.720598322569124,
                -14.701818091158332
              ],
              [
                -43.71778232060395,
                -14.710828513430558
              ],
              [
                -43.71773734371513,
                -14.710973028473008
              ],
              [
                -43.711822860457865,
                -14.71453248159644
              ],
              [
                -43.71920577921867,
                -14.720458478252809
              ],
              [
                -43.719452787428764,
                -14.729091389128072
              ],
              [
                -43.709320384214465,
                -14.729940870461464
              ],
              [
                -43.70300990398182,
                -14.724871892430675
              ],
              [
                -43.68691906131972,
                -14.721371408988619
              ],
              [
                -43.68901405776717,
                -14.7269218637951
              ],
              [
                -43.685508086085974,
                -14.727401374825156
              ],
              [
                -43.68067960538613,
                -14.731741337059416
              ],
              [
                -43.67505014693219,
                -14.729729348601056
              ],
              [
                -43.67338715186468,
                -14.72913485535353
              ],
              [
                -43.67364065016416,
                -14.731105309787266
              ],
              [
                -43.673756676551896,
                -14.73200881248373
              ],
              [
                -43.67060167880402,
                -14.736412785212739
              ],
              [
                -43.668058203729764,
                -14.736554789957982
              ],
              [
                -43.665959236000376,
                -14.736671286625933
              ],
              [
                -43.663062743550746,
                -14.739731259986378
              ],
              [
                -43.65862074200044,
                -14.744423734733907
              ],
              [
                -43.63811795176988,
                -14.748295645751499
              ],
              [
                -43.629587502923506,
                -14.749907149207528
              ],
              [
                -43.62508254824963,
                -14.75277160097532
              ],
              [
                -43.61459159553667,
                -14.759443077206184
              ],
              [
                -43.60565068086155,
                -14.75901305139203
              ],
              [
                -43.59834026330796,
                -14.754902050837428
              ],
              [
                -43.590376807870335,
                -14.750424082405646
              ],
              [
                -43.580212400748934,
                -14.750363572246718
              ],
              [
                -43.575812939628236,
                -14.759596012347886
              ],
              [
                -43.573374442644685,
                -14.764712960933966
              ],
              [
                -43.56296052801247,
                -14.769930433142436
              ],
              [
                -43.53676422869143,
                -14.783055282869023
              ],
              [
                -43.53354075908551,
                -14.788270742489466
              ],
              [
                -43.53653372068785,
                -14.79014226824333
              ],
              [
                -43.53667117884811,
                -14.791069748823988
              ],
              [
                -43.538789656209524,
                -14.805285654798586
              ],
              [
                -43.53098520088784,
                -14.814848591000619
              ],
              [
                -43.524843272562656,
                -14.81353808136706
              ],
              [
                -43.52307026287368,
                -14.809976602017375
              ],
              [
                -43.52082180489849,
                -14.80545913167953
              ],
              [
                -43.514958356259264,
                -14.804144134566636
              ],
              [
                -43.509342923662054,
                -14.807151086520992
              ],
              [
                -43.51057039992765,
                -14.797554676183971
              ],
              [
                -43.51075341700666,
                -14.796125669218625
              ],
              [
                -43.50434947074936,
                -14.796529181697785
              ],
              [
                -43.49806601550375,
                -14.796925632428975
              ],
              [
                -43.49704503094938,
                -14.795325660430407
              ],
              [
                -43.49385405071559,
                -14.790327162430597
              ],
              [
                -43.4850586415019,
                -14.79070064897369
              ],
              [
                -43.4778456801713,
                -14.789925138264353
              ],
              [
                -43.47413921086226,
                -14.789527146300962
              ],
              [
                -43.46896028677844,
                -14.784231664709287
              ],
              [
                -43.46856479294821,
                -14.781010697700726
              ],
              [
                -43.47221926076182,
                -14.77697575064565
              ],
              [
                -43.480849217011595,
                -14.76744628272526
              ],
              [
                -43.474868283393256,
                -14.761716825428259
              ],
              [
                -43.466655835534134,
                -14.764173299278584
              ],
              [
                -43.46243234752363,
                -14.76543676028605
              ],
              [
                -43.45584944432824,
                -14.757293821725025
              ],
              [
                -43.45899089618506,
                -14.750079858229281
              ],
              [
                -43.460864396345976,
                -14.74577691675798
              ],
              [
                -43.455817458531044,
                -14.73970642820098
              ],
              [
                -43.44542753663558,
                -14.736238940556973
              ],
              [
                -43.440069133598314,
                -14.72786295959862
              ],
              [
                -43.43423315379878,
                -14.725969996165036
              ],
              [
                -43.43448416681821,
                -14.72259349425965
              ],
              [
                -43.42776322796551,
                -14.725917958380556
              ],
              [
                -43.42826070134027,
                -14.721759514686623
              ],
              [
                -43.42490773740214,
                -14.71952949958578
              ],
              [
                -43.42036577007442,
                -14.72134696666944
              ],
              [
                -43.41428381750711,
                -14.72378046033953
              ],
              [
                -43.40364092171272,
                -14.714260026390793
              ],
              [
                -43.39862300078492,
                -14.709771013289895
              ],
              [
                -43.36395329800855,
                -14.698541048767106
              ],
              [
                -43.34609793365161,
                -14.695620031146944
              ],
              [
                -43.33387504609741,
                -14.68720658187766
              ],
              [
                -43.33193109034963,
                -14.683331083340946
              ],
              [
                -43.33009811227282,
                -14.679676612640879
              ],
              [
                -43.31926770181659,
                -14.677506582475946
              ],
              [
                -43.310822272277576,
                -14.669298122472899
              ],
              [
                -43.29911686405255,
                -14.674970084673832
              ],
              [
                -43.297736365405484,
                -14.675639077210228
              ],
              [
                -43.287919452554206,
                -14.675662072327283
              ],
              [
                -43.28329452524213,
                -14.67345957897327
              ],
              [
                -43.28244052631168,
                -14.67128108890286
              ],
              [
                -43.28014103811805,
                -14.665413122043223
              ],
              [
                -43.236026937988726,
                -14.656297600989683
              ],
              [
                -43.23228447696584,
                -14.658180571039074
              ],
              [
                -43.228541485948135,
                -14.66006408508366
              ],
              [
                -43.207263155150756,
                -14.65790455418227
              ],
              [
                -43.20438416925578,
                -14.655689555921462
              ],
              [
                -43.19648178139402,
                -14.64961260790393
              ],
              [
                -43.18802632597696,
                -14.654802541580668
              ],
              [
                -43.17963941807137,
                -14.659949008637934
              ],
              [
                -43.178520417803874,
                -14.656965519108766
              ],
              [
                -43.1759459337,
                -14.650098070031563
              ],
              [
                -43.15766208722868,
                -14.661711467438547
              ],
              [
                -43.15109311685251,
                -14.665883912574838
              ],
              [
                -43.13993122574364,
                -14.66574639645843
              ],
              [
                -43.12877132363293,
                -14.665608884350188
              ],
              [
                -43.12290186543402,
                -14.671442818428007
              ],
              [
                -43.115897404742114,
                -14.67840427662714
              ],
              [
                -43.11073396580264,
                -14.678561270885702
              ],
              [
                -43.10935447701987,
                -14.675974785483119
              ],
              [
                -43.10761647944737,
                -14.672717814606278
              ],
              [
                -43.102143007092465,
                -14.674561296946457
              ],
              [
                -43.0941215696053,
                -14.680605235063314
              ],
              [
                -43.09112411672467,
                -14.682863717048532
              ],
              [
                -43.08336962451029,
                -14.697544113378836
              ],
              [
                -43.07901119451592,
                -14.690074159016525
              ],
              [
                -43.071871745489155,
                -14.68839613623186
              ],
              [
                -43.07126474312051,
                -14.687541137472655
              ],
              [
                -43.06598230881141,
                -14.680108704337725
              ],
              [
                -43.05636987687345,
                -14.68942063558196
              ],
              [
                -43.05526637722903,
                -14.690489125117708
              ],
              [
                -43.05022544947524,
                -14.689990602631187
              ],
              [
                -43.04816794612743,
                -14.689787130392245
              ],
              [
                -43.037907042657615,
                -14.682357651810511
              ],
              [
                -43.02647264205237,
                -14.686657108874565
              ],
              [
                -43.026418142580965,
                -14.692200055576677
              ],
              [
                -43.0264096440559,
                -14.693091047064351
              ],
              [
                -43.01054575385628,
                -14.701211982016124
              ],
              [
                -43.0024647993448,
                -14.695883986181817
              ],
              [
                -43.00239883497735,
                -14.695840504338243
              ],
              [
                -43.002042842752886,
                -14.695962009984049
              ],
              [
                -42.98495895499155,
                -14.701780436069923
              ],
              [
                -42.98094249721582,
                -14.699857930123935
              ],
              [
                -42.976926550435614,
                -14.69793595617843
              ],
              [
                -42.9522902147606,
                -14.707951372524235
              ],
              [
                -42.93848033064393,
                -14.707577818403996
              ],
              [
                -42.93710131538448,
                -14.71922676010109
              ],
              [
                -42.937009829777274,
                -14.719995256198946
              ],
              [
                -42.934575833556075,
                -14.722622225840684
              ],
              [
                -42.92457292340346,
                -14.73341866916959
              ],
              [
                -42.92499491610672,
                -14.735506161993735
              ],
              [
                -42.9266498654911,
                -14.743689112273545
              ],
              [
                -42.91979545702847,
                -14.743785070030503
              ],
              [
                -42.916564968397914,
                -14.747034062475834
              ],
              [
                -42.91533399415863,
                -14.746256571184865
              ],
              [
                -42.9117949889672,
                -14.744023061964295
              ],
              [
                -42.90883801993198,
                -14.751787540985475
              ],
              [
                -42.90548401836535,
                -14.760592941907444
              ],
              [
                -42.90223158315481,
                -14.760928946300542
              ],
              [
                -42.90104006268598,
                -14.758425981730587
              ],
              [
                -42.89957207658454,
                -14.755343992268262
              ],
              [
                -42.89633709988275,
                -14.761441431110452
              ],
              [
                -42.895757121805744,
                -14.762533930377248
              ],
              [
                -42.885878207739104,
                -14.76659037552275
              ],
              [
                -42.87745227947097,
                -14.76729086286536
              ],
              [
                -42.87362179337871,
                -14.76526837584511
              ],
              [
                -42.87214879518658,
                -14.764490887143788
              ],
              [
                -42.87108781396481,
                -14.76569485890189
              ],
              [
                -42.86704832977277,
                -14.770278321717313
              ],
              [
                -42.87142080229816,
                -14.771184859553884
              ],
              [
                -42.87198677083699,
                -14.774909330511457
              ],
              [
                -42.860250895318416,
                -14.78015225115945
              ],
              [
                -42.849412458721254,
                -14.794588151545376
              ],
              [
                -42.84432098731265,
                -14.795328667302412
              ],
              [
                -42.84195050667837,
                -14.795673131138745
              ],
              [
                -42.838160035287345,
                -14.80198208371531
              ],
              [
                -42.83588652865052,
                -14.805764586480677
              ],
              [
                -42.790622885781595,
                -14.838904797638362
              ],
              [
                -42.75473666597125,
                -14.845968695799595
              ],
              [
                -42.74822618457773,
                -14.863919568777526
              ],
              [
                -42.74169574755054,
                -14.869560557780424
              ],
              [
                -42.739216744624194,
                -14.871701536321405
              ],
              [
                -42.739107766375014,
                -14.87179551955471
              ],
              [
                -42.735538773175044,
                -14.876972484478575
              ],
              [
                -42.725340375987535,
                -14.87882194563663
              ],
              [
                -42.72295784367123,
                -14.885159913410549
              ],
              [
                -42.713236456250876,
                -14.88380841015667
              ],
              [
                -42.71325745923775,
                -14.883717407754892
              ],
              [
                -42.71420495265616,
                -14.879604940785097
              ],
              [
                -42.705154007803316,
                -14.882006416491727
              ],
              [
                -42.69954805086713,
                -14.888272848192214
              ],
              [
                -42.69986903498393,
                -14.892796801783847
              ],
              [
                -42.69996054087844,
                -14.894087810962663
              ],
              [
                -42.692400609420076,
                -14.902034244943776
              ],
              [
                -42.68331768171977,
                -14.905068743670158
              ],
              [
                -42.68246365181564,
                -14.904584237204688
              ],
              [
                -42.67945168836667,
                -14.902876236613228
              ],
              [
                -42.66965677287588,
                -14.910794659937121
              ],
              [
                -42.66339929564803,
                -14.915853141978577
              ],
              [
                -42.66304782197138,
                -14.913999630833159
              ],
              [
                -42.66230734428996,
                -14.910095656697415
              ],
              [
                -42.65929736794183,
                -14.909987154224842
              ],
              [
                -42.657072879559685,
                -14.916734625716101
              ],
              [
                -42.656509379234095,
                -14.918444605183934
              ],
              [
                -42.65214791482293,
                -14.92027059444182
              ],
              [
                -42.65481836884745,
                -14.923802574178602
              ],
              [
                -42.63450352836545,
                -14.934493970356833
              ],
              [
                -42.62489057448301,
                -14.94401241907966
              ],
              [
                -42.61303320402992,
                -14.937273923451688
              ],
              [
                -42.60797874376676,
                -14.931215934270485
              ],
              [
                -42.60214433078975,
                -14.9242234835503
              ],
              [
                -42.58674694989571,
                -14.929892919175474
              ],
              [
                -42.58497095043644,
                -14.934594404055126
              ],
              [
                -42.58426945902209,
                -14.9364498903779
              ],
              [
                -42.57997450523339,
                -14.937642857658386
              ],
              [
                -42.5771215109591,
                -14.938435854877124
              ],
              [
                -42.57199003051981,
                -14.945568322981918
              ],
              [
                -42.56028312323477,
                -14.961841700298121
              ],
              [
                -42.55710712085647,
                -14.9603407071495
              ],
              [
                -42.553931677473145,
                -14.958840175000818
              ],
              [
                -42.534482832074154,
                -14.966129124640972
              ],
              [
                -42.531619322940536,
                -14.97574107527228
              ],
              [
                -42.5312343148619,
                -14.9770340536144
              ],
              [
                -42.52588688772362,
                -14.980049029810111
              ],
              [
                -42.52132490473335,
                -14.98262098908992
              ],
              [
                -42.5186209132432,
                -14.982847985057228
              ],
              [
                -42.51587945306708,
                -14.983077511944897
              ],
              [
                -42.49540461421224,
                -14.994367910035672
              ],
              [
                -42.47551125365751,
                -15.016586208483957
              ],
              [
                -42.436397073276424,
                -15.02226263233719
              ],
              [
                -42.439151999357115,
                -15.028621601694947
              ],
              [
                -42.44190697443146,
                -15.034980559062724
              ],
              [
                -42.44251944549287,
                -15.060151902392162
              ],
              [
                -42.431961010674605,
                -15.062601883106721
              ],
              [
                -42.42140260486972,
                -15.065052346817541
              ],
              [
                -42.42057760213527,
                -15.06545934088312
              ],
              [
                -42.396089802149234,
                -15.077539223092066
              ],
              [
                -42.385885899973225,
                -15.074166740439583
              ],
              [
                -42.37568400778832,
                -15.070794218803954
              ],
              [
                -42.35452362764478,
                -15.097725538766381
              ],
              [
                -42.336390287101125,
                -15.079971126557899
              ],
              [
                -42.33080384344507,
                -15.079506598851838
              ],
              [
                -42.326080395065766,
                -15.088059546770474
              ],
              [
                -42.323294408585156,
                -15.093104528742773
              ],
              [
                -42.315348970917206,
                -15.098736984218846
              ],
              [
                -42.29448263094315,
                -15.107248875669752
              ],
              [
                -42.292151129050794,
                -15.108199861775443
              ],
              [
                -42.29071612582938,
                -15.109536363032289
              ],
              [
                -42.28302321325935,
                -15.116698321455425
              ],
              [
                -42.28074120469008,
                -15.11559381237798
              ],
              [
                -42.27508679379216,
                -15.112857315621753
              ],
              [
                -42.27469629032616,
                -15.113291323262263
              ],
              [
                -42.26999730283286,
                -15.118505773885863
              ],
              [
                -42.26439783418642,
                -15.124719713769524
              ],
              [
                -42.249085489779645,
                -15.110848771442395
              ],
              [
                -42.24678702313345,
                -15.108766822405784
              ],
              [
                -42.2270327004327,
                -15.098808847289936
              ],
              [
                -42.22071623760911,
                -15.102453782855239
              ],
              [
                -42.215993284886935,
                -15.105179267853618
              ],
              [
                -42.20242340857225,
                -15.099619301101795
              ],
              [
                -42.19220301312027,
                -15.102664775696502
              ],
              [
                -42.182562562569316,
                -15.099189776759099
              ],
              [
                -42.181817094977845,
                -15.096401791791564
              ],
              [
                -42.17940315291554,
                -15.087372319727502
              ],
              [
                -42.172924175521125,
                -15.08549882028193
              ],
              [
                -42.16406875825857,
                -15.096940756071948
              ],
              [
                -42.152949855473935,
                -15.098906725838217
              ],
              [
                -42.153383323092974,
                -15.108109157426734
              ],
              [
                -42.15347680867262,
                -15.110106665182578
              ],
              [
                -42.14951134072084,
                -15.119893595622164
              ],
              [
                -42.14065187483518,
                -15.12839099968571
              ],
              [
                -42.13127998173951,
                -15.130604468883337
              ],
              [
                -42.12851849110776,
                -15.131256475102987
              ],
              [
                -42.115909570123584,
                -15.14366387445096
              ],
              [
                -42.11332659080334,
                -15.149912342226266
              ],
              [
                -42.106582107679415,
                -15.166225215387861
              ],
              [
                -42.09793768546166,
                -15.167865701367274
              ],
              [
                -42.095673198075886,
                -15.181989131816753
              ],
              [
                -42.09122670444632,
                -15.18615108034896
              ],
              [
                -42.08431477259041,
                -15.186072085964733
              ],
              [
                -42.07289988874611,
                -15.175246618570347
              ],
              [
                -42.03080774256551,
                -15.16624361531623
              ],
              [
                -42.002674011858794,
                -15.160221624570672
              ],
              [
                -41.99660254909846,
                -15.158922602257705
              ],
              [
                -41.992069594843144,
                -15.163328060096344
              ],
              [
                -41.98739511354833,
                -15.167871541833286
              ],
              [
                -41.97945318977885,
                -15.167129548852479
              ],
              [
                -41.97892969234275,
                -15.16708051126144
              ],
              [
                -41.95303493198394,
                -15.175209434360633
              ],
              [
                -41.93256410222945,
                -15.17291243062848
              ],
              [
                -41.92866912156847,
                -15.165178446119274
              ],
              [
                -41.92622967021391,
                -15.160333993117012
              ],
              [
                -41.91731375376944,
                -15.160176963859213
              ],
              [
                -41.9147222770223,
                -15.160131481714698
              ],
              [
                -41.91051379493244,
                -15.155213979159734
              ],
              [
                -41.90938781294823,
                -15.153898002435788
              ],
              [
                -41.9084688451302,
                -15.153721494966266
              ],
              [
                -41.89774342363533,
                -15.151657994513158
              ],
              [
                -41.8783266004787,
                -15.135242057735237
              ],
              [
                -41.87630814677409,
                -15.130247594445798
              ],
              [
                -41.87058170235262,
                -15.116079661083932
              ],
              [
                -41.85410983370051,
                -15.116992640354704
              ],
              [
                -41.8461464222834,
                -15.115102638604803
              ],
              [
                -41.815945206814575,
                -15.107934170028722
              ],
              [
                -41.80898025659372,
                -15.100641674762501
              ],
              [
                -41.80031380882902,
                -15.100641668048192
              ],
              [
                -41.788227414010294,
                -15.11140558425237
              ],
              [
                -41.632367510667954,
                -15.250203997534289
              ],
              [
                -41.463532722987765,
                -15.401699295170824
              ],
              [
                -41.36217193003829,
                -15.49422905907294
              ],
              [
                -41.35808449049629,
                -15.49795550876564
              ],
              [
                -41.35602646482683,
                -15.499830987510558
              ],
              [
                -41.35579097466051,
                -15.50212101681033
              ],
              [
                -41.34191687208957,
                -15.636929632528723
              ],
              [
                -41.33122428562275,
                -15.740205495770676
              ],
              [
                -41.330808810743235,
                -15.744211953161939
              ],
              [
                -41.32728231503875,
                -15.741102472304604
              ],
              [
                -41.32440836209947,
                -15.741527956635153
              ],
              [
                -41.3043390270002,
                -15.742969427584821
              ],
              [
                -41.29255414019975,
                -15.73854441848347
              ],
              [
                -41.281560714035066,
                -15.740307902313258
              ],
              [
                -41.28028923863388,
                -15.741061389407959
              ],
              [
                -41.268168838148945,
                -15.748241831593702
              ],
              [
                -41.25310744682871,
                -15.746464810327714
              ],
              [
                -41.245883533226475,
                -15.74224334996511
              ],
              [
                -41.23699611983555,
                -15.737050362754681
              ],
              [
                -41.21488181981616,
                -15.736790827788347
              ],
              [
                -41.2065188760658,
                -15.742805250768077
              ],
              [
                -41.19815694131315,
                -15.748820228743227
              ],
              [
                -41.16906116452261,
                -15.760058588021217
              ],
              [
                -41.1674101796319,
                -15.760166093471268
              ],
              [
                -41.155891785080776,
                -15.760914090718869
              ],
              [
                -41.15028080266632,
                -15.76578204361902
              ],
              [
                -41.14384686506035,
                -15.771364515632833
              ],
              [
                -41.135223437411945,
                -15.76567802900023
              ],
              [
                -41.12879852626562,
                -15.74784613089147
              ],
              [
                -41.12824304894505,
                -15.746304624529923
              ],
              [
                -41.124702555133005,
                -15.744426630067641
              ],
              [
                -41.121162129321675,
                -15.742548134610981
              ],
              [
                -41.11928211815214,
                -15.742264133108101
              ],
              [
                -41.09249785597191,
                -15.738218098724678
              ],
              [
                -41.0848294244707,
                -15.72852167587557
              ],
              [
                -41.07716253094042,
                -15.718827204048573
              ],
              [
                -41.060624673057355,
                -15.722814653439325
              ],
              [
                -41.05301973666366,
                -15.724647651790692
              ],
              [
                -41.0444493134983,
                -15.719000666171212
              ],
              [
                -41.03587938132788,
                -15.713354182567265
              ],
              [
                -41.01059658979561,
                -15.713373135607442
              ],
              [
                -41.00678762686643,
                -15.713375655988981
              ],
              [
                -41.005346168001296,
                -15.712500150962008
              ],
              [
                -41.003904674136415,
                -15.711624664935405
              ],
              [
                -41.00044122158993,
                -15.70817215151362
              ],
              [
                -40.985082345229756,
                -15.69286221965053
              ],
              [
                -40.982149388235435,
                -15.69203173377046
              ],
              [
                -40.979216422241876,
                -15.691201231891489
              ],
              [
                -40.97718592116832,
                -15.682904289287894
              ],
              [
                -40.973027521566586,
                -15.665913870435396
              ],
              [
                -40.961884150505036,
                -15.64842696570157
              ],
              [
                -40.948823261468995,
                -15.655773903155557
              ],
              [
                -40.93558683173115,
                -15.656180374780734
              ],
              [
                -40.932831849668545,
                -15.658359371377909
              ],
              [
                -40.93002340399417,
                -15.660580356619448
              ],
              [
                -40.92822091291422,
                -15.658263850025042
              ],
              [
                -40.92614245188295,
                -15.655592858177966
              ],
              [
                -40.913920534997516,
                -15.660554322015527
              ],
              [
                -40.90803307678352,
                -15.676006719033989
              ],
              [
                -40.902176586425696,
                -15.691380110616578
              ],
              [
                -40.893229152169326,
                -15.69032460977967
              ],
              [
                -40.89163766935467,
                -15.690136624209947
              ],
              [
                -40.88895621684943,
                -15.691128091369173
              ],
              [
                -40.88116377931815,
                -15.694010063859942
              ],
              [
                -40.85506048583445,
                -15.68669505066883
              ],
              [
                -40.848438076196615,
                -15.680846090926185
              ],
              [
                -40.84163810284327,
                -15.682763058184024
              ],
              [
                -40.83980415207247,
                -15.676624130527564
              ],
              [
                -40.83916415157287,
                -15.674482123861356
              ],
              [
                -40.831166215808636,
                -15.670318620208029
              ],
              [
                -40.83106024383256,
                -15.670263147373651
              ],
              [
                -40.83106123386342,
                -15.67024165251017
              ],
              [
                -40.83196424440643,
                -15.65365876113118
              ],
              [
                -40.82891076371007,
                -15.647798782664898
              ],
              [
                -40.81524788721384,
                -15.648014237825167
              ],
              [
                -40.791259092475755,
                -15.667974084248778
              ],
              [
                -40.785600596987464,
                -15.673844055658309
              ],
              [
                -40.77994214050178,
                -15.679713502070312
              ],
              [
                -40.78178265018846,
                -15.683686474294825
              ],
              [
                -40.785548114487256,
                -15.6918134207651
              ],
              [
                -40.779724610765,
                -15.703490845448227
              ],
              [
                -40.76945321388995,
                -15.711842799335363
              ],
              [
                -40.767277687591246,
                -15.713611764484707
              ],
              [
                -40.76247327097059,
                -15.712237283828742
              ],
              [
                -40.74863337585833,
                -15.706624263215682
              ],
              [
                -40.73815601049587,
                -15.70044378798287
              ],
              [
                -40.74006047523657,
                -15.69426284107938
              ],
              [
                -40.74261496117256,
                -15.685974386535333
              ],
              [
                -40.7426229511515,
                -15.685947908715406
              ],
              [
                -40.743136978726795,
                -15.684281395068115
              ],
              [
                -40.7317900667798,
                -15.674353472941
              ],
              [
                -40.72004218249585,
                -15.672528446229533
              ],
              [
                -40.70681681480515,
                -15.665945493909591
              ],
              [
                -40.698961884223706,
                -15.668160961495662
              ],
              [
                -40.696865881027406,
                -15.670604419531772
              ],
              [
                -40.68526348090389,
                -15.688247796665241
              ],
              [
                -40.68150349022423,
                -15.69396475826114
              ],
              [
                -40.68191497422787,
                -15.698555715106473
              ],
              [
                -40.66766106451141,
                -15.704031678180007
              ],
              [
                -40.66080563548674,
                -15.70666463284227
              ],
              [
                -40.64455274846108,
                -15.728391998297225
              ],
              [
                -40.63748828763305,
                -15.729741948656692
              ],
              [
                -40.63711530175695,
                -15.729813451565496
              ],
              [
                -40.63562384789661,
                -15.725383003854455
              ],
              [
                -40.633020885783246,
                -15.71765103699861
              ],
              [
                -40.632074883073116,
                -15.717086559922429
              ],
              [
                -40.62899791729139,
                -15.715249561187552
              ],
              [
                -40.61377750361134,
                -15.73463790108587
              ],
              [
                -40.609513049126996,
                -15.735572390874568
              ],
              [
                -40.60620006172014,
                -15.736298365610988
              ],
              [
                -40.599834115851756,
                -15.74799429210907
              ],
              [
                -40.600768078704355,
                -15.750714800610396
              ],
              [
                -40.60355356848342,
                -15.758830245385665
              ],
              [
                -40.59222115989623,
                -15.76185269586968
              ],
              [
                -40.59268361590351,
                -15.770538124067622
              ],
              [
                -40.58096624211941,
                -15.77278561975251
              ],
              [
                -40.56702333501133,
                -15.775460562917036
              ],
              [
                -40.57099080083132,
                -15.783904548667179
              ],
              [
                -40.57273228380276,
                -15.787610498374779
              ],
              [
                -40.56165735959628,
                -15.802818397696962
              ],
              [
                -40.55246841109287,
                -15.80325737808729
              ],
              [
                -40.53974456629746,
                -15.794560902820475
              ],
              [
                -40.53940906565097,
                -15.794331901682238
              ],
              [
                -40.53504010182782,
                -15.794882909688477
              ],
              [
                -40.530778642093026,
                -15.795419886968519
              ],
              [
                -40.51788623181695,
                -15.79704687052319
              ],
              [
                -40.51844975321988,
                -15.785979953110347
              ],
              [
                -40.51873328398181,
                -15.780401957693293
              ],
              [
                -40.50834785738355,
                -15.76753603680065
              ],
              [
                -40.49448048749028,
                -15.765435508189183
              ],
              [
                -40.49172202368621,
                -15.769214998650359
              ],
              [
                -40.4887520222005,
                -15.773284453922345
              ],
              [
                -40.4814650995886,
                -15.774563441342979
              ],
              [
                -40.46734821902501,
                -15.76039603132644
              ],
              [
                -40.46024679718883,
                -15.75327006104042
              ],
              [
                -40.45631981580609,
                -15.756406046538858
              ],
              [
                -40.456312350858624,
                -15.756413019482089
              ],
              [
                -40.45239286142294,
                -15.759542976030763
              ],
              [
                -40.43210401087828,
                -15.778163338858223
              ],
              [
                -40.42463953657692,
                -15.805242667429575
              ],
              [
                -40.41834860065691,
                -15.807965639458812
              ],
              [
                -40.41418710655825,
                -15.806076669200992
              ],
              [
                -40.40177172663627,
                -15.816234565859858
              ],
              [
                -40.4010347279093,
                -15.812518607001454
              ],
              [
                -40.401021231149464,
                -15.812452099397264
              ],
              [
                -40.39996973691301,
                -15.81269459606131
              ],
              [
                -40.39693777818461,
                -15.81339310144671
              ],
              [
                -40.391882780416296,
                -15.81941052684256
              ],
              [
                -40.38436435886699,
                -15.8152705309977
              ],
              [
                -40.38211040288685,
                -15.814029555942716
              ],
              [
                -40.38098487544647,
                -15.815828549671075
              ],
              [
                -40.37646390679412,
                -15.823054465364315
              ],
              [
                -40.372916948966235,
                -15.817005035387037
              ],
              [
                -40.371100991555124,
                -15.813908017784923
              ],
              [
                -40.36598503872755,
                -15.816989019572658
              ],
              [
                -40.35632561650104,
                -15.812550526954086
              ],
              [
                -40.34668720513301,
                -15.81682296245877
              ],
              [
                -40.338833745930145,
                -15.816294984297464
              ],
              [
                -40.33851879005878,
                -15.817185445141266
              ],
              [
                -40.336302754005835,
                -15.823446430855215
              ],
              [
                -40.327153378589806,
                -15.817780940865973
              ],
              [
                -40.317828940549894,
                -15.818754442714305
              ],
              [
                -40.31424997849234,
                -15.8168084438455
              ],
              [
                -40.31115350172334,
                -15.815125417148286
              ],
              [
                -40.29993262588204,
                -15.815797407651422
              ],
              [
                -40.294014162134445,
                -15.81615191325901
              ],
              [
                -40.292041153081485,
                -15.814707407990218
              ],
              [
                -40.28224727509981,
                -15.807534935468013
              ],
              [
                -40.27753729995907,
                -15.812702387769937
              ],
              [
                -40.273657817201254,
                -15.816958858246489
              ],
              [
                -40.26851835784942,
                -15.812277895986659
              ],
              [
                -40.26260343806228,
                -15.81264838250776
              ],
              [
                -40.262197924466896,
                -15.808356887912982
              ],
              [
                -40.25529699392039,
                -15.810047864408201
              ],
              [
                -40.23535666904797,
                -15.803943873813685
              ],
              [
                -40.230680238231194,
                -15.803318390760097
              ],
              [
                -40.230468740138534,
                -15.803290390575
              ],
              [
                -40.23025421820872,
                -15.804282889935937
              ],
              [
                -40.22731473310302,
                -15.817913781777186
              ],
              [
                -40.22410575916077,
                -15.821255741166372
              ],
              [
                -40.22286428048159,
                -15.821024278501433
              ],
              [
                -40.2224737510422,
                -15.820951772290382
              ],
              [
                -40.222318770011384,
                -15.822259745761649
              ],
              [
                -40.222288259805694,
                -15.822518733073277
              ],
              [
                -40.222871748076486,
                -15.825585241701628
              ],
              [
                -40.2207487772884,
                -15.825817235597642
              ],
              [
                -40.21785831708576,
                -15.826132724651039
              ],
              [
                -40.21811280646297,
                -15.830172696566507
              ],
              [
                -40.21843577822494,
                -15.835312148657653
              ],
              [
                -40.214517819640406,
                -15.835302175002663
              ],
              [
                -40.212603327459135,
                -15.835296650755105
              ],
              [
                -40.21251882797427,
                -15.83815315456743
              ],
              [
                -40.21240983418982,
                -15.841836603116116
              ],
              [
                -40.20840085137687,
                -15.844029083392716
              ],
              [
                -40.2054418513966,
                -15.8456475750951
              ],
              [
                -40.20571387695182,
                -15.847863076569352
              ],
              [
                -40.20646934204237,
                -15.854020043984102
              ],
              [
                -40.198892927819585,
                -15.857163003134245
              ],
              [
                -40.19967540300936,
                -15.862041453672285
              ],
              [
                -40.19376095090332,
                -15.858248489471825
              ],
              [
                -40.192263963808855,
                -15.85728897196265
              ],
              [
                -40.18540602048717,
                -15.861961437684245
              ],
              [
                -40.17357212567286,
                -15.870024866446542
              ],
              [
                -40.16964067015102,
                -15.86896787737442
              ],
              [
                -40.16570969562523,
                -15.867911404301521
              ],
              [
                -40.16561615168344,
                -15.877059827357474
              ],
              [
                -40.16544663782626,
                -15.89360573658876
              ],
              [
                -40.174722567968374,
                -15.902509666335048
              ],
              [
                -40.17033609116685,
                -15.907053643112944
              ],
              [
                -40.16646362179442,
                -15.904551137245575
              ],
              [
                -40.16435111978739,
                -15.909762098723826
              ],
              [
                -40.159432160259186,
                -15.90799813839538
              ],
              [
                -40.15606969919463,
                -15.899075671937963
              ],
              [
                -40.15371424938279,
                -15.90099017780193
              ],
              [
                -40.14985977750981,
                -15.904123631392999
              ],
              [
                -40.14890575247288,
                -15.904898618860031
              ],
              [
                -40.148683250176774,
                -15.904491129050125
              ],
              [
                -40.14564933304736,
                -15.89893264593159
              ],
              [
                -40.14017787308061,
                -15.89842213975353
              ],
              [
                -40.13699088254078,
                -15.905809591626044
              ],
              [
                -40.127764456228235,
                -15.907134081404363
              ],
              [
                -40.11683406875218,
                -15.903094080141175
              ],
              [
                -40.11260408176378,
                -15.910826515030621
              ],
              [
                -40.10495112167201,
                -15.911588524067444
              ],
              [
                -40.09623671885839,
                -15.906934512501659
              ],
              [
                -40.09484224308983,
                -15.89931708024375
              ],
              [
                -40.084627333813295,
                -15.896660058507775
              ],
              [
                -40.08132837174428,
                -15.904349536241922
              ],
              [
                -40.086372292304254,
                -15.913111480529938
              ],
              [
                -40.08330931515873,
                -15.918612924507398
              ],
              [
                -40.080515340533225,
                -15.923630397005958
              ],
              [
                -40.077207875686675,
                -15.921422418273425
              ],
              [
                -40.074453912292874,
                -15.919583437162528
              ],
              [
                -40.07193442257791,
                -15.91134145892356
              ],
              [
                -40.06565800432114,
                -15.909423972270227
              ],
              [
                -40.07126690712338,
                -15.92569436707346
              ],
              [
                -40.065949954084196,
                -15.924510894416722
              ],
              [
                -40.06845392864543,
                -15.933447335254726
              ],
              [
                -40.07062642731759,
                -15.933561339269076
              ],
              [
                -40.07651034997197,
                -15.933870813429664
              ],
              [
                -40.07843633892269,
                -15.94045830913355
              ],
              [
                -40.07029339006036,
                -15.937475286971234
              ],
              [
                -40.06800941348347,
                -15.938388269276714
              ],
              [
                -40.06573894979751,
                -15.93929627563674
              ],
              [
                -40.06576144265039,
                -15.942534767218042
              ],
              [
                -40.06581292264154,
                -15.949911193715765
              ],
              [
                -40.0606539452902,
                -15.951956204923503
              ],
              [
                -40.058738504755,
                -15.952715202833994
              ],
              [
                -40.05996145303717,
                -15.953233699663492
              ],
              [
                -40.06423042213122,
                -15.955045194569433
              ],
              [
                -40.06580090066797,
                -15.959743171606622
              ],
              [
                -40.06076792699237,
                -15.963535634994763
              ],
              [
                -40.052609034421806,
                -15.962662128464494
              ],
              [
                -40.04537709251395,
                -15.961888110905408
              ],
              [
                -40.04310910613819,
                -15.968056060046264
              ],
              [
                -40.04391708080078,
                -15.968721074237495
              ],
              [
                -40.04585357881989,
                -15.970314061511957
              ],
              [
                -40.04027161722404,
                -15.971262033914183
              ],
              [
                -40.03114119077172,
                -15.97281251240238
              ],
              [
                -40.0320531700522,
                -15.976991008582665
              ],
              [
                -40.0322956818757,
                -15.978099975996892
              ],
              [
                -40.02855171839595,
                -15.979431986137248
              ],
              [
                -40.026876699051876,
                -15.980027456491992
              ],
              [
                -40.02696622832566,
                -15.980536970428414
              ],
              [
                -40.02776519186194,
                -15.985114915894956
              ],
              [
                -40.022143253755026,
                -15.98979790463818
              ],
              [
                -40.02093525744381,
                -15.984339925024287
              ],
              [
                -40.02092824550653,
                -15.984339425015364
              ],
              [
                -40.01329634020161,
                -15.98354043491834
              ],
              [
                -40.00968835820087,
                -15.991028869407485
              ],
              [
                -40.00803884042895,
                -15.994452341944836
              ],
              [
                -40.004147383945885,
                -15.994325836039646
              ],
              [
                -40.000575442628126,
                -15.99421083661284
              ],
              [
                -40.00141690500965,
                -15.995951309069595
              ],
              [
                -40.00403887392678,
                -16.00137082036171
              ],
              [
                -39.99468248488021,
                -16.000006280856493
              ],
              [
                -39.993220474629744,
                -15.997363296031468
              ],
              [
                -39.991892491753624,
                -15.994961303916845
              ],
              [
                -39.99187348698362,
                -15.994927332098683
              ],
              [
                -39.99165448664141,
                -15.994531837219846
              ],
              [
                -39.99092952273691,
                -15.994690838966891
              ],
              [
                -39.99085948832562,
                -15.994706295748653
              ],
              [
                -39.97960510796184,
                -15.997174282775918
              ],
              [
                -39.97964158858988,
                -15.99828827080071
              ],
              [
                -39.9798655980863,
                -16.005093244197734
              ],
              [
                -39.96356273722367,
                -16.004855709616802
              ],
              [
                -39.96311374590481,
                -16.00065975235722
              ],
              [
                -39.96273924765008,
                -15.997157753843139
              ],
              [
                -39.95374584197018,
                -15.991155788303143
              ],
              [
                -39.94758739689631,
                -15.991899762006016
              ],
              [
                -39.94699538198443,
                -15.991971259535655
              ],
              [
                -39.92383260308669,
                -15.994771207996187
              ],
              [
                -39.91571664379333,
                -16.000144157060873
              ],
              [
                -39.91647415304766,
                -16.003442646504336
              ],
              [
                -39.9227155618952,
                -16.01002211464204
              ],
              [
                -39.92531557391852,
                -16.01276362678621
              ],
              [
                -39.92532105886,
                -16.012769125760883
              ],
              [
                -39.925535063556616,
                -16.01299511070072
              ],
              [
                -39.935290450585,
                -16.023278039512405
              ],
              [
                -39.931503478379426,
                -16.026863507331594
              ],
              [
                -39.92180405354543,
                -16.02815948643006
              ],
              [
                -39.89925121320169,
                -16.048953331159414
              ],
              [
                -39.89905119962646,
                -16.04913785064859
              ],
              [
                -39.89905821040697,
                -16.049223329120558
              ],
              [
                -39.89979270712327,
                -16.058332783831904
              ],
              [
                -39.88885778839144,
                -16.063881746910546
              ],
              [
                -39.882745821615934,
                -16.066983677767844
              ],
              [
                -39.87635935891139,
                -16.07894562417604
              ],
              [
                -39.87659186383166,
                -16.08329158812543
              ],
              [
                -39.877012330609496,
                -16.091144047257266
              ],
              [
                -39.87030140828851,
                -16.093618503036446
              ],
              [
                -39.8662104206245,
                -16.09512651144487
              ],
              [
                -39.864391957406156,
                -16.10338896112321
              ],
              [
                -39.85643447623599,
                -16.113360851399978
              ],
              [
                -39.858418967622995,
                -16.118810358425613
              ],
              [
                -39.860402415017795,
                -16.12425829646602
              ],
              [
                -39.86834586664107,
                -16.131394767175383
              ],
              [
                -39.88231971195989,
                -16.14394720919397
              ],
              [
                -39.89003416098266,
                -16.137941778441274
              ],
              [
                -39.89656058844015,
                -16.141132240632864
              ],
              [
                -39.89329258599735,
                -16.149911709606958
              ],
              [
                -39.88687965851706,
                -16.150507685727145
              ],
              [
                -39.88584117399848,
                -16.15357165560621
              ],
              [
                -39.88254018006276,
                -16.16330910748664
              ],
              [
                -39.88299463854496,
                -16.194224394447147
              ],
              [
                -39.89407752748092,
                -16.19915138168698
              ],
              [
                -39.89800546853663,
                -16.200897876521903
              ],
              [
                -39.90178493485634,
                -16.208033846167584
              ],
              [
                -39.91223332308795,
                -16.20991133654065
              ],
              [
                -39.91184382333616,
                -16.21590932711047
              ],
              [
                -39.9354885770705,
                -16.244533654290542
              ],
              [
                -39.92814560731516,
                -16.257672572867385
              ],
              [
                -39.92285065249988,
                -16.261183555546125
              ],
              [
                -39.919705666370255,
                -16.26326850294491
              ],
              [
                -39.919328167546404,
                -16.265488516330425
              ],
              [
                -39.91628866687558,
                -16.283372387622535
              ],
              [
                -39.93226004826604,
                -16.28313192007709
              ],
              [
                -39.936768485355714,
                -16.290110906132636
              ],
              [
                -39.938844941984094,
                -16.293325385581504
              ],
              [
                -39.94227195034985,
                -16.291925393374562
              ],
              [
                -39.943126422458924,
                -16.291576882059477
              ],
              [
                -39.951857833397426,
                -16.29741435970329
              ],
              [
                -39.952651340056605,
                -16.299721345611545
              ],
              [
                -39.9601177503054,
                -16.321429729477103
              ],
              [
                -39.968271137586854,
                -16.32659720837681
              ],
              [
                -39.971879151685144,
                -16.31865576252195
              ],
              [
                -39.97606661225366,
                -16.3173087713213
              ],
              [
                -39.9790110880437,
                -16.316361778429698
              ],
              [
                -39.978053582195905,
                -16.31067633341218
              ],
              [
                -39.99035396929663,
                -16.312976345574178
              ],
              [
                -39.99603291071046,
                -16.330740717167703
              ],
              [
                -40.010759755018114,
                -16.339606698499754
              ],
              [
                -40.01077777521003,
                -16.33995068537618
              ],
              [
                -40.011085758448516,
                -16.34580216125814
              ],
              [
                -40.01704769044594,
                -16.34759765450381
              ],
              [
                -40.02082914553395,
                -16.35212111282969
              ],
              [
                -40.02443260159338,
                -16.35643111818578
              ],
              [
                -40.02824254501558,
                -16.373860529787844
              ],
              [
                -40.03210449748456,
                -16.391527404054425
              ],
              [
                -40.036405425646564,
                -16.397797893426123
              ],
              [
                -40.041488896735316,
                -16.40520833206469
              ],
              [
                -40.041181362150375,
                -16.407474341352312
              ],
              [
                -40.04064938640127,
                -16.411397310884066
              ],
              [
                -40.05212525950666,
                -16.4312081923669
              ],
              [
                -40.05672219367888,
                -16.431347210637366
              ],
              [
                -40.0623061610886,
                -16.431515714472535
              ],
              [
                -40.061453661543716,
                -16.43733516461097
              ],
              [
                -40.061122645165575,
                -16.439595142908576
              ],
              [
                -40.06792956118504,
                -16.45793756244125
              ],
              [
                -40.08358692652206,
                -16.463457026776517
              ],
              [
                -40.08796338835053,
                -16.45168461301172
              ],
              [
                -40.09597180292677,
                -16.447897180857318
              ],
              [
                -40.09277789447892,
                -16.437193704004475
              ],
              [
                -40.09188090711324,
                -16.434186737187648
              ],
              [
                -40.09757785773026,
                -16.422169826316207
              ],
              [
                -40.10290530540935,
                -16.436538252051477
              ],
              [
                -40.112671669709066,
                -16.45258715222843
              ],
              [
                -40.11369817566581,
                -16.45730261963464
              ],
              [
                -40.115377609135656,
                -16.465019101488217
              ],
              [
                -40.119394104048524,
                -16.465893112173948
              ],
              [
                -40.12031856951281,
                -16.466094103563314
              ],
              [
                -40.13990135641801,
                -16.495096453633046
              ],
              [
                -40.15809219321874,
                -16.513279356819275
              ],
              [
                -40.16135165570735,
                -16.522551291922753
              ],
              [
                -40.16157062058113,
                -16.523174803433122
              ],
              [
                -40.161771621974076,
                -16.52308880332705
              ],
              [
                -40.16744957859124,
                -16.52065282162003
              ],
              [
                -40.17081803709252,
                -16.52367932179928
              ],
              [
                -40.169820024809155,
                -16.533101269396667
              ],
              [
                -40.17444798471545,
                -16.53742223478532
              ],
              [
                -40.16906903492259,
                -16.538573723012522
              ],
              [
                -40.1656040560659,
                -16.54086719255555
              ],
              [
                -40.161168619532134,
                -16.543803669364372
              ],
              [
                -40.16166708936922,
                -16.552531128972298
              ],
              [
                -40.16095605694944,
                -16.55809208111867
              ],
              [
                -40.15548561103316,
                -16.561813556193627
              ],
              [
                -40.16059203138351,
                -16.571493013147606
              ],
              [
                -40.15945755932708,
                -16.579836961250987
              ],
              [
                -40.1715369299393,
                -16.57717296843749
              ],
              [
                -40.17351743083397,
                -16.581660450098823
              ],
              [
                -40.18900480276514,
                -16.57103106988524
              ],
              [
                -40.18978927288787,
                -16.57049205563928
              ],
              [
                -40.197936721362254,
                -16.569822079393834
              ],
              [
                -40.1991282119479,
                -16.57185355790722
              ],
              [
                -40.20083219233306,
                -16.574758537913883
              ],
              [
                -40.21164259113686,
                -16.5726415934338
              ],
              [
                -40.215055063262355,
                -16.570463106079266
              ],
              [
                -40.23408291301209,
                -16.558315700616163
              ],
              [
                -40.236427917005805,
                -16.563687684456752
              ],
              [
                -40.24545930098661,
                -16.56652218104539
              ],
              [
                -40.24562933064903,
                -16.56747665342465
              ],
              [
                -40.24663030701162,
                -16.573088646381912
              ],
              [
                -40.254083745095414,
                -16.569077662646066
              ],
              [
                -40.254293235469156,
                -16.568964164726303
              ],
              [
                -40.25457871853345,
                -16.56918264388218
              ],
              [
                -40.26074716509752,
                -16.57390512563789
              ],
              [
                -40.27511454424717,
                -16.57355668558182
              ],
              [
                -40.28724188098311,
                -16.597101061346404
              ],
              [
                -40.28500641649133,
                -16.601329038116504
              ],
              [
                -40.28343390558643,
                -16.604302992853107
              ],
              [
                -40.29167581650415,
                -16.62161292341031
              ],
              [
                -40.29788621779539,
                -16.652048751113146
              ],
              [
                -40.306464621916696,
                -16.667664655951206
              ],
              [
                -40.31228003411808,
                -16.67825160896377
              ],
              [
                -40.31065650809893,
                -16.69536696018562
              ],
              [
                -40.3066320447425,
                -16.696291460188974
              ],
              [
                -40.3034205827535,
                -16.70413241289924
              ],
              [
                -40.30195806967493,
                -16.707704390169525
              ],
              [
                -40.30715352623164,
                -16.71472135120193
              ],
              [
                -40.31018499154587,
                -16.718814832394727
              ],
              [
                -40.31008098324594,
                -16.72302582218536
              ],
              [
                -40.3097689701691,
                -16.735742244056574
              ],
              [
                -40.316070901218794,
                -16.742109193161838
              ],
              [
                -40.3197328657322,
                -16.745808667961608
              ],
              [
                -40.33544067293564,
                -16.779240015180157
              ],
              [
                -40.345099080053714,
                -16.786795462158327
              ],
              [
                -40.33508166338743,
                -16.787762975960565
              ],
              [
                -40.33080419585776,
                -16.792457910222428
              ],
              [
                -40.330555172515,
                -16.79273139408268
              ],
              [
                -40.323207245427575,
                -16.80079484598241
              ],
              [
                -40.29581946933404,
                -16.79642535309201
              ],
              [
                -40.29423148782644,
                -16.796171859767565
              ],
              [
                -40.293411982050316,
                -16.796681340133365
              ],
              [
                -40.28415257738322,
                -16.80243528178688
              ],
              [
                -40.279588625299546,
                -16.800045804229935
              ],
              [
                -40.26967869483084,
                -16.794857288234613
              ],
              [
                -40.257407307057434,
                -16.80606970671316
              ],
              [
                -40.257856765325016,
                -16.815642156451165
              ],
              [
                -40.25807325038532,
                -16.820245647438025
              ],
              [
                -40.25636327180089,
                -16.821603620946686
              ],
              [
                -40.25312978827861,
                -16.82417161621599
              ],
              [
                -40.25101431003303,
                -16.838385030674953
              ],
              [
                -40.24650930125019,
                -16.84526745901499
              ],
              [
                -40.25142975437885,
                -16.848558947681358
              ],
              [
                -40.25171875115984,
                -16.859579885250763
              ],
              [
                -40.26072117342154,
                -16.859747916635133
              ],
              [
                -40.265629112952944,
                -16.85983989302171
              ],
              [
                -40.264541132265926,
                -16.864556869962854
              ],
              [
                -40.27157356306748,
                -16.86972484695082
              ],
              [
                -40.2733015450856,
                -16.877716310869456
              ],
              [
                -40.275300979230586,
                -16.886960244582355
              ],
              [
                -40.28124793965272,
                -16.900710658785954
              ],
              [
                -40.29365228800062,
                -16.903373168093992
              ],
              [
                -40.29981027786822,
                -16.895184227764876
              ],
              [
                -40.30235374211844,
                -16.891801777238193
              ],
              [
                -40.30732969534562,
                -16.89631073559065
              ],
              [
                -40.31647312336789,
                -16.904596700344086
              ],
              [
                -40.32253656093685,
                -16.903573721741694
              ],
              [
                -40.32668348892312,
                -16.908864693793188
              ],
              [
                -40.339487940517536,
                -16.898011779000704
              ],
              [
                -40.34835084678945,
                -16.89883329918356
              ],
              [
                -40.3480608497644,
                -16.891536849526602
              ],
              [
                -40.3479028649892,
                -16.88757188062573
              ],
              [
                -40.366089233163706,
                -16.873696479268204
              ],
              [
                -40.37096219579077,
                -16.87443048667238
              ],
              [
                -40.37273116276883,
                -16.878704445624557
              ],
              [
                -40.375502620128906,
                -16.88539992652765
              ],
              [
                -40.39114099195165,
                -16.887141923471617
              ],
              [
                -40.39628295153901,
                -16.887713938379985
              ],
              [
                -40.401361897908004,
                -16.890194963443005
              ],
              [
                -40.403124855560435,
                -16.89263342669123
              ],
              [
                -40.405808838579716,
                -16.896346427799855
              ],
              [
                -40.40716232395685,
                -16.898218399781808
              ],
              [
                -40.417225723247995,
                -16.898775915821076
              ],
              [
                -40.43257711936931,
                -16.887531025057367
              ],
              [
                -40.43659959371644,
                -16.892707967642373
              ],
              [
                -40.44785947665515,
                -16.891679500624225
              ],
              [
                -40.45370395709946,
                -16.881136084092798
              ],
              [
                -40.4562089155453,
                -16.879879570405
              ],
              [
                -40.45948888794284,
                -16.87823411052736
              ],
              [
                -40.47959570678844,
                -16.876350663987683
              ],
              [
                -40.49080709999478,
                -16.88401712651574
              ],
              [
                -40.49199008972077,
                -16.899588538150184
              ],
              [
                -40.49229756998397,
                -16.90363751587888
              ],
              [
                -40.50429890654124,
                -16.928754352966408
              ],
              [
                -40.51587624966627,
                -16.9629371839183
              ],
              [
                -40.534605052247535,
                -16.98033062306115
              ],
              [
                -40.53984300469881,
                -16.98207608406703
              ],
              [
                -40.54508196714101,
                -16.983822589071988
              ],
              [
                -40.55373283722812,
                -17.00875246665487
              ],
              [
                -40.55295734263589,
                -17.01395446242477
              ],
              [
                -40.552181848042615,
                -17.0191554072042
              ],
              [
                -40.55588377451939,
                -17.02812487326531
              ],
              [
                -40.569628604818135,
                -17.061430200425345
              ],
              [
                -40.569515596684084,
                -17.079899070528185
              ],
              [
                -40.56590260970044,
                -17.080774568464875
              ],
              [
                -40.56102616321758,
                -17.08195553318736
              ],
              [
                -40.55638768659082,
                -17.089128011800717
              ],
              [
                -40.557440135672955,
                -17.095417944393258
              ],
              [
                -40.56254357835715,
                -17.125893781102057
              ],
              [
                -40.57042395338399,
                -17.137111718456126
              ],
              [
                -40.57830486438676,
                -17.148330182847502
              ],
              [
                -40.574213343595375,
                -17.179857462283156
              ],
              [
                -40.565588943167,
                -17.183472420144877
              ],
              [
                -40.55346350159147,
                -17.188554901521798
              ],
              [
                -40.55201100180478,
                -17.198707806017964
              ],
              [
                -40.56152140038016,
                -17.21138525471263
              ],
              [
                -40.55799840819431,
                -17.23019965370082
              ],
              [
                -40.564068359862425,
                -17.239497599573617
              ],
              [
                -40.565090820235305,
                -17.239805610621165
              ],
              [
                -40.56951276860083,
                -17.241137587828888
              ],
              [
                -40.570767753981144,
                -17.251742030754077
              ],
              [
                -40.56455382073474,
                -17.25663149037433
              ],
              [
                -40.56316982270303,
                -17.257720499157852
              ],
              [
                -40.56157681959116,
                -17.272028904252668
              ],
              [
                -40.54735290602658,
                -17.2832288014815
              ],
              [
                -40.55479185113384,
                -17.28586731945525
              ],
              [
                -40.55595631670762,
                -17.28644180615846
              ],
              [
                -40.567368725716385,
                -17.292077289430356
              ],
              [
                -40.57184717777004,
                -17.294288777442045
              ],
              [
                -40.577658114147575,
                -17.303819221560857
              ],
              [
                -40.58182006455635,
                -17.310645691996378
              ],
              [
                -40.58408302759802,
                -17.31115171119436
              ],
              [
                -40.590118481706334,
                -17.312499701401517
              ],
              [
                -40.59452543025369,
                -17.32308863951291
              ],
              [
                -40.6044123255595,
                -17.322921667187845
              ],
              [
                -40.60860179641328,
                -17.32647313557348
              ],
              [
                -40.602342304231996,
                -17.340284538057627
              ],
              [
                -40.59871436167297,
                -17.34199253485352
              ],
              [
                -40.59232988572925,
                -17.344997485581732
              ],
              [
                -40.60259376402388,
                -17.35638146502166
              ],
              [
                -40.60455226732472,
                -17.35855394656883
              ],
              [
                -40.60345177994305,
                -17.359563441373552
              ],
              [
                -40.59857881267958,
                -17.364032918087972
              ],
              [
                -40.59731227241518,
                -17.37641631170883
              ],
              [
                -40.60122375538777,
                -17.382056289973153
              ],
              [
                -40.59934073890766,
                -17.389873234093933
              ],
              [
                -40.610441165130936,
                -17.397182217906355
              ],
              [
                -40.62307852773935,
                -17.405502711548007
              ],
              [
                -40.60746864734945,
                -17.41756858896056
              ],
              [
                -40.59567421802792,
                -17.42009407230366
              ],
              [
                -40.58239587121095,
                -17.41513155713161
              ],
              [
                -40.578318906262716,
                -17.413608090617714
              ],
              [
                -40.57536340903053,
                -17.414225559277238
              ],
              [
                -40.565725491428445,
                -17.420310988177327
              ],
              [
                -40.55835004470758,
                -17.429403923167744
              ],
              [
                -40.55372356874004,
                -17.435106905884574
              ],
              [
                -40.550952575148536,
                -17.43608537770672
              ],
              [
                -40.52025136449228,
                -17.446925777859327
              ],
              [
                -40.52054330436656,
                -17.457872720170275
              ],
              [
                -40.50641239540573,
                -17.47368308068377
              ],
              [
                -40.50511742384064,
                -17.475132065458215
              ],
              [
                -40.50405991416488,
                -17.47561906250335
              ],
              [
                -40.497458485132135,
                -17.478659035572882
              ],
              [
                -40.49642799154039,
                -17.481899021014346
              ],
              [
                -40.495043470772636,
                -17.486252988045422
              ],
              [
                -40.49031253311606,
                -17.486930985954647
              ],
              [
                -40.48355809171996,
                -17.487898970265462
              ],
              [
                -40.48260209087356,
                -17.4880359426205
              ],
              [
                -40.48348355112811,
                -17.505458847908894
              ],
              [
                -40.48601250482741,
                -17.512226307797597
              ],
              [
                -40.48866197379109,
                -17.519315276982713
              ],
              [
                -40.48791248380917,
                -17.52395577950601
              ],
              [
                -40.4858240183137,
                -17.526338221129674
              ],
              [
                -40.48182900635574,
                -17.528861224273466
              ],
              [
                -40.474798577227496,
                -17.533301677047092
              ],
              [
                -40.47083960051231,
                -17.542804095075606
              ],
              [
                -40.46025464935469,
                -17.56820543242272
              ],
              [
                -40.44429678212218,
                -17.57793586320403
              ],
              [
                -40.43947731829213,
                -17.57378889206637
              ],
              [
                -40.43168139206149,
                -17.56708040974385
              ],
              [
                -40.41028158775364,
                -17.56774888192729
              ],
              [
                -40.414124019366845,
                -17.575682799286113
              ],
              [
                -40.40940056349181,
                -17.58514876106853
              ],
              [
                -40.41161751178009,
                -17.588582754543687
              ],
              [
                -40.41172402098403,
                -17.588512726170023
              ],
              [
                -40.41561000294211,
                -17.585956774026563
              ],
              [
                -40.41736446160432,
                -17.596001701681693
              ],
              [
                -40.414060448542,
                -17.60961812212617
              ],
              [
                -40.40614455837941,
                -17.610643589817688
              ],
              [
                -40.404516051336245,
                -17.610854598433892
              ],
              [
                -40.40224957408534,
                -17.61484507100297
              ],
              [
                -40.39641358032726,
                -17.625123982775797
              ],
              [
                -40.381056261129814,
                -17.61211205905732
              ],
              [
                -40.37953877521529,
                -17.610826562929688
              ],
              [
                -40.375550781284225,
                -17.611402551839586
              ],
              [
                -40.37156385634567,
                -17.611978532751806
              ],
              [
                -40.370010835661894,
                -17.61555351018319
              ],
              [
                -40.361047898086916,
                -17.63619035737856
              ],
              [
                -40.35426193331498,
                -17.64379528747422
              ],
              [
                -40.3487089989526,
                -17.63151388407475
              ],
              [
                -40.34815451740341,
                -17.630287884425456
              ],
              [
                -40.34732854902491,
                -17.61423246189949
              ],
              [
                -40.333655131811845,
                -17.631674336373585
              ],
              [
                -40.327126160772316,
                -17.637847793597018
              ],
              [
                -40.32177471636958,
                -17.6439947452276
              ],
              [
                -40.32399768152996,
                -17.64888873989381
              ],
              [
                -40.325452657997815,
                -17.65209169431719
              ],
              [
                -40.32242770159819,
                -17.660835135708833
              ],
              [
                -40.30775979065438,
                -17.665062108143115
              ],
              [
                -40.307333315174375,
                -17.66518510158508
              ],
              [
                -40.302968316150505,
                -17.66983605514129
              ],
              [
                -40.2986043531229,
                -17.674485012711337
              ],
              [
                -40.298234865216955,
                -17.68322296721391
              ],
              [
                -40.29770381578434,
                -17.695777394368488
              ],
              [
                -40.295114852972624,
                -17.699013358872342
              ],
              [
                -40.292172874213705,
                -17.699404378881468
              ],
              [
                -40.28584693349126,
                -17.700245352698566
              ],
              [
                -40.28209095242489,
                -17.71432578347901
              ],
              [
                -40.27996243664293,
                -17.72230620222012
              ],
              [
                -40.27538196415906,
                -17.72425520368052
              ],
              [
                -40.270802005673566,
                -17.72620416514176
              ],
              [
                -40.26728503596076,
                -17.724705655454052
              ],
              [
                -40.25537115111492,
                -17.71962919230305
              ],
              [
                -40.25257369683021,
                -17.72302466443994
              ],
              [
                -40.25101770023137,
                -17.725610128845613
              ],
              [
                -40.248547699565336,
                -17.729715128324514
              ],
              [
                -40.23927429173873,
                -17.73308408622227
              ],
              [
                -40.23391133492312,
                -17.73325508092221
              ],
              [
                -40.22354341014898,
                -17.733587043071093
              ],
              [
                -40.21326247041931,
                -17.75319790892623
              ],
              [
                -40.216360429855456,
                -17.761865846517857
              ],
              [
                -40.21703640391255,
                -17.763756857395826
              ],
              [
                -40.215357414198834,
                -17.770749811958748
              ],
              [
                -40.21398543342958,
                -17.7764637728354
              ],
              [
                -40.21767736455177,
                -17.795178669983642
              ],
              [
                -40.20411945658718,
                -17.804848575609864
              ],
              [
                -40.201383451330315,
                -17.81561052043384
              ],
              [
                -40.20163446222547,
                -17.816984500628855
              ],
              [
                -40.20371143196213,
                -17.828359436023952
              ],
              [
                -40.195534478860374,
                -17.827720426155516
              ],
              [
                -40.193279984722274,
                -17.828743926649
              ],
              [
                -40.186011565307204,
                -17.832046377759184
              ],
              [
                -40.17470063045402,
                -17.852197257494197
              ],
              [
                -40.175997609849404,
                -17.853670266109045
              ],
              [
                -40.177294612244296,
                -17.85514322772505
              ],
              [
                -40.21234322228996,
                -17.895029072015255
              ],
              [
                -40.23302601199754,
                -17.90582255101381
              ],
              [
                -40.249719344504435,
                -17.914534007895927
              ],
              [
                -40.263081715238584,
                -17.92150699083261
              ],
              [
                -40.2640056932197,
                -17.931362956396246
              ],
              [
                -40.26168271411294,
                -17.935486410123538
              ],
              [
                -40.24176735373605,
                -17.95208327883525
              ],
              [
                -40.234096418795225,
                -17.954931240862955
              ],
              [
                -40.2264249708689,
                -17.95777819889495
              ],
              [
                -40.2220174780753,
                -17.97994758218094
              ],
              [
                -40.22258395836137,
                -17.979798568174534
              ],
              [
                -40.22874290109892,
                -17.978185081809883
              ],
              [
                -40.244430303551745,
                -17.974075136918778
              ],
              [
                -40.254954702988464,
                -17.971317666891668
              ],
              [
                -40.258904685408204,
                -17.967790218731583
              ],
              [
                -40.26590159655769,
                -17.961541275820128
              ],
              [
                -40.27128256916154,
                -17.961850271402866
              ],
              [
                -40.27527355826926,
                -17.962079264770786
              ],
              [
                -40.28912192693026,
                -17.952397843749175
              ],
              [
                -40.29059543404315,
                -17.951367342793
              ],
              [
                -40.29070692155931,
                -17.946405373795656
              ],
              [
                -40.29988735153387,
                -17.9466929008742
              ],
              [
                -40.305793289770286,
                -17.946877928219784
              ],
              [
                -40.309555749532564,
                -17.942096466215446
              ],
              [
                -40.31186523226887,
                -17.939161492310518
              ],
              [
                -40.31706822582852,
                -17.93883598235535
              ],
              [
                -40.32140065699259,
                -17.938565493382157
              ],
              [
                -40.32445315814402,
                -17.935631528910623
              ],
              [
                -40.33355607297988,
                -17.926880102086145
              ],
              [
                -40.345553485620044,
                -17.923588619104606
              ],
              [
                -40.35040791481374,
                -17.926796121273693
              ],
              [
                -40.35520986954524,
                -17.92996911555386
              ],
              [
                -40.359342371705495,
                -17.92676913576894
              ],
              [
                -40.364902801558515,
                -17.922463168388674
              ],
              [
                -40.372026245413906,
                -17.926731161610263
              ],
              [
                -40.37274724411071,
                -17.92716365941566
              ],
              [
                -40.378912680699926,
                -17.92753916413121
              ],
              [
                -40.38602110581715,
                -17.927973145329247
              ],
              [
                -40.38843561945978,
                -17.92607217841541
              ],
              [
                -40.391549064079385,
                -17.923620700159322
              ],
              [
                -40.40399047486943,
                -17.925052206735046
              ],
              [
                -40.417017327350955,
                -17.926550229060595
              ],
              [
                -40.42459830727915,
                -17.92370027386882
              ],
              [
                -40.4321787362199,
                -17.92085028467548
              ],
              [
                -40.45347156311979,
                -17.918329367157504
              ],
              [
                -40.45892298640336,
                -17.9199693419309
              ],
              [
                -40.459922999174104,
                -17.92156284433097
              ],
              [
                -40.46378692191736,
                -17.927720306970564
              ],
              [
                -40.47622886773343,
                -17.91551991522527
              ],
              [
                -40.48373079798072,
                -17.90816343286521
              ],
              [
                -40.49333070291562,
                -17.909181464433615
              ],
              [
                -40.50293164085102,
                -17.910199498010385
              ],
              [
                -40.515463538166514,
                -17.896474589547683
              ],
              [
                -40.518161026583414,
                -17.893521119474766
              ],
              [
                -40.52240049228897,
                -17.892492128877148
              ],
              [
                -40.526640475991286,
                -17.8914636112777
              ],
              [
                -40.547036754139086,
                -17.906815069087056
              ],
              [
                -40.56753055248285,
                -17.91554907276706
              ],
              [
                -40.584311881683924,
                -17.917684073711925
              ],
              [
                -40.58413839249009,
                -17.92789352036161
              ],
              [
                -40.584013345865856,
                -17.93528046498239
              ],
              [
                -40.586762351636374,
                -17.938539969875553
              ],
              [
                -40.59403778798025,
                -17.936373459017506
              ],
              [
                -40.59679474337595,
                -17.935552480302636
              ],
              [
                -40.61304758594785,
                -17.93924847796552
              ],
              [
                -40.6148985631033,
                -17.94323398078682
              ],
              [
                -40.6122135804605,
                -17.94902743795649
              ],
              [
                -40.62148550597543,
                -17.96008438309461
              ],
              [
                -40.61978149670995,
                -17.96163687049884
              ],
              [
                -40.61387353540244,
                -17.967016809843777
              ],
              [
                -40.623891927923744,
                -17.97543780621319
              ],
              [
                -40.62554089068038,
                -17.988849720465428
              ],
              [
                -40.635585803436776,
                -17.990965227534396
              ],
              [
                -40.64563022320644,
                -17.993080743612076
              ],
              [
                -40.6483401751516,
                -17.99967668162226
              ],
              [
                -40.651095623447915,
                -18.00638465506474
              ],
              [
                -40.661421522860856,
                -18.01263965407545
              ],
              [
                -40.666075011598764,
                -18.011788639278105
              ],
              [
                -40.672784946990866,
                -18.01056167775681
              ],
              [
                -40.67668391261448,
                -18.012406194430998
              ],
              [
                -40.67742989727076,
                -18.012759676791596
              ],
              [
                -40.67831389837562,
                -18.012336165048755
              ],
              [
                -40.683825339384825,
                -18.00969768157862
              ],
              [
                -40.68730131686874,
                -18.011951218990973
              ],
              [
                -40.70415014471946,
                -18.022871652118596
              ],
              [
                -40.71100211514611,
                -18.01547170161045
              ],
              [
                -40.71294357893735,
                -18.010850264920872
              ],
              [
                -40.715765579453986,
                -18.0041338124285
              ],
              [
                -40.72536900102335,
                -18.00073532351701
              ],
              [
                -40.739921893659194,
                -18.003994333728983
              ],
              [
                -40.74368435666464,
                -18.004837334111528
              ],
              [
                -40.745288332152676,
                -18.00593884871418
              ],
              [
                -40.750372276818055,
                -18.009429319955654
              ],
              [
                -40.75991219046535,
                -18.003482868073828
              ],
              [
                -40.77186109866835,
                -18.001827384416345
              ],
              [
                -40.77116610468066,
                -17.987910484796917
              ],
              [
                -40.77080064162747,
                -17.98058251566113
              ],
              [
                -40.77732706443135,
                -17.981825007094727
              ],
              [
                -40.7773481103012,
                -17.976206052554808
              ],
              [
                -40.78172357082562,
                -17.974765585717908
              ],
              [
                -40.78313206688845,
                -17.975475592262228
              ],
              [
                -40.79460595035455,
                -17.98126006141428
              ],
              [
                -40.79147796093215,
                -17.971002108263658
              ],
              [
                -40.79806843902258,
                -17.956468236652793
              ],
              [
                -40.80222489909033,
                -17.958012701627133
              ],
              [
                -40.80838037815441,
                -17.952277253828075
              ],
              [
                -40.81376282254731,
                -17.958430217803976
              ],
              [
                -40.824571187072294,
                -17.963216715580334
              ],
              [
                -40.82821318830541,
                -17.95701178162563
              ],
              [
                -40.830154660300025,
                -17.95370430310442
              ],
              [
                -40.836641125737096,
                -17.95670577300509
              ],
              [
                -40.844352049771466,
                -17.965121733142546
              ],
              [
                -40.85914337084488,
                -17.981264687347743
              ],
              [
                -40.86272581019905,
                -17.987822653461702
              ],
              [
                -40.8699022417633,
                -17.985614673705243
              ],
              [
                -40.87252225108625,
                -17.9803192143044
              ],
              [
                -40.87746621356879,
                -17.981246712534446
              ],
              [
                -40.8777122076228,
                -17.980679706385043
              ],
              [
                -40.882316687903455,
                -17.97007029544146
              ],
              [
                -40.89077109364688,
                -17.982552746991534
              ],
              [
                -40.8967055472273,
                -17.981246752435236
              ],
              [
                -40.89957702148868,
                -17.984252252258685
              ],
              [
                -40.900731481727746,
                -17.985460713363437
              ],
              [
                -40.90219599353945,
                -17.98699372215438
              ],
              [
                -40.772902379582,
                -18.107080286584605
              ],
              [
                -40.771098821718894,
                -18.155441000436678
              ],
              [
                -40.79434559266261,
                -18.15956651712758
              ],
              [
                -40.799580560170256,
                -18.156027056467458
              ],
              [
                -40.80481551168038,
                -18.15248806880368
              ],
              [
                -40.82378837035732,
                -18.14880662024484
              ],
              [
                -40.824595830843315,
                -18.14898814877331
              ],
              [
                -40.83210479897642,
                -18.1506731327091
              ],
              [
                -40.84032770266378,
                -18.14736714759428
              ],
              [
                -40.84946211803654,
                -18.146956190162534
              ],
              [
                -40.85561958629575,
                -18.13934622640547
              ],
              [
                -40.855627604245704,
                -18.139336742477457
              ],
              [
                -40.8556465810828,
                -18.13933472352711
              ],
              [
                -40.862904531606745,
                -18.13864975198877
              ],
              [
                -40.86383403936992,
                -18.135908293050733
              ],
              [
                -40.86602750709653,
                -18.129435331697334
              ],
              [
                -40.87715396482734,
                -18.118947887823577
              ],
              [
                -40.880291889956695,
                -18.12365938617726
              ],
              [
                -40.8853008599934,
                -18.12358437256298
              ],
              [
                -40.88860832996552,
                -18.12353488142106
              ],
              [
                -40.8913048098127,
                -18.12049541975108
              ],
              [
                -40.89349228961523,
                -18.11815994790676
              ],
              [
                -40.892710311852824,
                -18.107399497011023
              ],
              [
                -40.89877175189425,
                -18.112272976205823
              ],
              [
                -40.90295773013118,
                -18.11038300069172
              ],
              [
                -40.90414672366222,
                -18.115449993082226
              ],
              [
                -40.90888266834346,
                -18.114350464985645
              ],
              [
                -40.916899108918464,
                -18.12026097295389
              ],
              [
                -40.92474600582607,
                -18.128544435578572
              ],
              [
                -40.93180995141706,
                -18.136000919558935
              ],
              [
                -40.93963884255887,
                -18.135970397307467
              ],
              [
                -40.94011237839334,
                -18.135968913257987
              ],
              [
                -40.94451935737429,
                -18.129176470148437
              ],
              [
                -40.94960426942385,
                -18.128804961453707
              ],
              [
                -40.95110523943098,
                -18.14157192103264
              ],
              [
                -40.951152729138954,
                -18.141973411756226
              ],
              [
                -40.9484587807922,
                -18.145216364244845
              ],
              [
                -40.95413768763943,
                -18.154014322605146
              ],
              [
                -40.961771649149995,
                -18.15369033072038
              ],
              [
                -40.96551812685134,
                -18.147413884229273
              ],
              [
                -40.96711862160475,
                -18.144733404239062
              ],
              [
                -40.97769450547068,
                -18.141550466084126
              ],
              [
                -40.9866359144591,
                -18.146294928890434
              ],
              [
                -40.987645393035386,
                -18.156175867602773
              ],
              [
                -40.989075352921276,
                -18.17016928791679
              ],
              [
                -40.99281533465494,
                -18.165751834427123
              ],
              [
                -41.010194194214115,
                -18.166918362215732
              ],
              [
                -41.01240368663509,
                -18.16148738264311
              ],
              [
                -41.01418866451148,
                -18.15709944407557
              ],
              [
                -41.027707053413415,
                -18.157192930489405
              ],
              [
                -41.031400981520044,
                -18.16653840177775
              ],
              [
                -41.0334134506997,
                -18.17162887479873
              ],
              [
                -41.0377374369059,
                -18.17060787944888
              ],
              [
                -41.03698195244099,
                -18.168369400129848
              ],
              [
                -41.0349374696468,
                -18.162314445729947
              ],
              [
                -41.055643270266906,
                -18.166124431621427
              ],
              [
                -41.0530428018691,
                -18.172190400695907
              ],
              [
                -41.0538853036879,
                -18.173916406213035
              ],
              [
                -41.05605327890693,
                -18.17836087737027
              ],
              [
                -41.060636218788694,
                -18.17965285792241
              ],
              [
                -41.06488019394947,
                -18.180351868290508
              ],
              [
                -41.06648066730328,
                -18.180615360938866
              ],
              [
                -41.092348416257956,
                -18.19507982056677
              ],
              [
                -41.09305141027209,
                -18.201834799241198
              ],
              [
                -41.093714379478946,
                -18.208203285115925
              ],
              [
                -41.09613334386074,
                -18.209278264641203
              ],
              [
                -41.10404826558057,
                -18.21279677681609
              ],
              [
                -41.10179527837501,
                -18.21872172847007
              ],
              [
                -41.09622431100943,
                -18.22342619564676
              ],
              [
                -41.09132534398728,
                -18.227562647509007
              ],
              [
                -41.09159783543294,
                -18.230822120897283
              ],
              [
                -41.09627631779701,
                -18.231510116238333
              ],
              [
                -41.0988407914265,
                -18.231887620164468
              ],
              [
                -41.1023422315495,
                -18.236484112181138
              ],
              [
                -41.09865576224876,
                -18.238624103209343
              ],
              [
                -41.100399752726055,
                -18.243260566470074
              ],
              [
                -41.09900825458584,
                -18.2470675423124
              ],
              [
                -41.096072758727985,
                -18.25509698325369
              ],
              [
                -41.1270969280236,
                -18.276438930341804
              ],
              [
                -41.13888529881327,
                -18.288502364315157
              ],
              [
                -41.139707320930796,
                -18.289706886907673
              ],
              [
                -41.14602774491836,
                -18.298977333292363
              ],
              [
                -41.150508172130664,
                -18.302334332647707
              ],
              [
                -41.15843811732025,
                -18.308275314825284
              ],
              [
                -41.154262574600416,
                -18.334221659421136
              ],
              [
                -41.15201062256466,
                -18.33504664904225
              ],
              [
                -41.14975815053193,
                -18.335872612656484
              ],
              [
                -41.148608585449864,
                -18.360322969196893
              ],
              [
                -41.145484589939045,
                -18.373381387464608
              ],
              [
                -41.14770206970727,
                -18.378668376021274
              ],
              [
                -41.15236052167608,
                -18.379604382975536
              ],
              [
                -41.159196959532416,
                -18.380976880788882
              ],
              [
                -41.16214190697165,
                -18.390280833348957
              ],
              [
                -41.16004739262449,
                -18.402314775755887
              ],
              [
                -41.15005399660095,
                -18.404509738669027
              ],
              [
                -41.14433552908818,
                -18.405765698736516
              ],
              [
                -41.14509403102406,
                -18.406392714608188
              ],
              [
                -41.15337195501338,
                -18.41323566639279
              ],
              [
                -41.15580592698333,
                -18.413518699674842
              ],
              [
                -41.16626981628496,
                -18.414733691800166
              ],
              [
                -41.18162413313564,
                -18.43859360163161
              ],
              [
                -41.17904115664423,
                -18.44047508839994
              ],
              [
                -41.17529367950143,
                -18.443203533857623
              ],
              [
                -41.17515269451214,
                -18.443306044972726
              ],
              [
                -41.173944212897716,
                -18.443413039892345
              ],
              [
                -41.02353851398087,
                -18.45669171106463
              ],
              [
                -41.01947553364975,
                -18.463937630432056
              ],
              [
                -41.0145050457093,
                -18.472801578495748
              ],
              [
                -41.01538702678065,
                -18.476912560235967
              ],
              [
                -41.02627284469567,
                -18.527617795967
              ],
              [
                -41.04158705244841,
                -18.604919389179503
              ],
              [
                -41.03325110740304,
                -18.60693682839163
              ],
              [
                -41.03257261208386,
                -18.6177472720049
              ],
              [
                -41.038993049672776,
                -18.619438765274122
              ],
              [
                -41.04203147675394,
                -18.623497737841085
              ],
              [
                -41.04374246352675,
                -18.62578325302755
              ],
              [
                -41.05270588998136,
                -18.628238742063214
              ],
              [
                -41.050488921299,
                -18.63366872090791
              ],
              [
                -41.0411574739576,
                -18.639268664203076
              ],
              [
                -41.04401692553378,
                -18.647796111409075
              ],
              [
                -41.030249053460686,
                -18.645345110493444
              ],
              [
                -41.02995753914859,
                -18.645293120199497
              ],
              [
                -41.030060579387346,
                -18.645671602206455
              ],
              [
                -41.03182151725362,
                -18.65215358806973
              ],
              [
                -41.02179763030694,
                -18.654085054300726
              ],
              [
                -41.019812650952005,
                -18.65446802900696
              ],
              [
                -41.014993666529975,
                -18.658430528067694
              ],
              [
                -41.00482524538829,
                -18.666792461556852
              ],
              [
                -40.99804780364308,
                -18.669251933356716
              ],
              [
                -40.988495382120526,
                -18.67271939260376
              ],
              [
                -40.98834584990615,
                -18.673399855334885
              ],
              [
                -40.98762738587423,
                -18.676669854821515
              ],
              [
                -40.96703303689524,
                -18.678779798361997
              ],
              [
                -40.95164468520278,
                -18.680356252677342
              ],
              [
                -40.94744218571988,
                -18.68372473044977
              ],
              [
                -40.94323921724194,
                -18.687093714217806
              ],
              [
                -40.94233120472245,
                -18.700636640467547
              ],
              [
                -40.940003156847304,
                -18.73536990948214
              ],
              [
                -40.93748612306918,
                -18.77204919995408
              ],
              [
                -40.9262086722694,
                -18.78769361281138
              ],
              [
                -40.928232146592464,
                -18.79245258431151
              ],
              [
                -40.92069570276933,
                -18.79881653181575
              ],
              [
                -40.91654669777648,
                -18.81497194139331
              ],
              [
                -40.92750859621829,
                -18.819220939288872
              ],
              [
                -40.936797981080865,
                -18.830229380507884
              ],
              [
                -40.944232957832526,
                -18.82562642756093
              ],
              [
                -40.94532593371107,
                -18.82494991474194
              ],
              [
                -40.94770891925108,
                -18.82908288566275
              ],
              [
                -40.950674358344706,
                -18.83422486693378
              ],
              [
                -40.95690933254656,
                -18.8349703874709
              ],
              [
                -40.96267776900352,
                -18.835659411375868
              ],
              [
                -40.962918739568856,
                -18.837111393448584
              ],
              [
                -40.963512261190345,
                -18.840683860947113
              ],
              [
                -40.97117618390718,
                -18.840052885428758
              ],
              [
                -40.98749553579075,
                -18.838708914920772
              ],
              [
                -41.02502871589519,
                -18.83669948511188
              ],
              [
                -41.08330571592553,
                -18.836097609119932
              ],
              [
                -41.089249165847114,
                -18.838570109107838
              ],
              [
                -41.09707357339082,
                -18.841825110467543
              ],
              [
                -41.11010346317167,
                -18.838451130974374
              ],
              [
                -41.11959041808985,
                -18.81717477374679
              ],
              [
                -41.11898595062181,
                -18.811387831996203
              ],
              [
                -41.118803472213074,
                -18.809641826727248
              ],
              [
                -41.1207984397552,
                -18.809145837725076
              ],
              [
                -41.12962434494894,
                -18.806952372679323
              ],
              [
                -41.13256383665298,
                -18.79878891701955
              ],
              [
                -41.133727829764254,
                -18.79555541214806
              ],
              [
                -41.147701248868124,
                -18.796318952623288
              ],
              [
                -41.151355182620925,
                -18.796339452062295
              ],
              [
                -41.23214248404431,
                -18.79679362569517
              ],
              [
                -41.245076314749525,
                -18.821474519937173
              ],
              [
                -41.23741935083472,
                -18.834280419100274
              ],
              [
                -41.241638285643724,
                -18.840411884467358
              ],
              [
                -41.24347428936409,
                -18.843080368879843
              ],
              [
                -41.24076279629454,
                -18.84523637080257
              ],
              [
                -41.23932132235908,
                -18.846382339193486
              ],
              [
                -41.24013331689352,
                -18.84870134349732
              ],
              [
                -41.2409567822528,
                -18.851052314641734
              ],
              [
                -41.24197178167361,
                -18.85394881003789
              ],
              [
                -41.23895480157441,
                -18.858161764457297
              ],
              [
                -41.23598031666175,
                -18.856027267589713
              ],
              [
                -41.22970239268539,
                -18.85788426182379
              ],
              [
                -41.214383481400596,
                -18.877152136791967
              ],
              [
                -41.209368525931254,
                -18.873948627846822
              ],
              [
                -41.211923498156544,
                -18.868399141184224
              ],
              [
                -41.210417028680446,
                -18.863056682887336
              ],
              [
                -41.208023551234284,
                -18.854563743942304
              ],
              [
                -41.19913211463551,
                -18.86238468631654
              ],
              [
                -41.19319170316262,
                -18.863174661413783
              ],
              [
                -41.188034743892175,
                -18.863484128913868
              ],
              [
                -41.16596646151841,
                -18.8577461452323
              ],
              [
                -41.16238594905905,
                -18.860810591095344
              ],
              [
                -41.15880649459273,
                -18.863874573962942
              ],
              [
                -41.15790000618875,
                -18.867102563432205
              ],
              [
                -41.15255549470517,
                -18.886123442468257
              ],
              [
                -41.14861105116692,
                -18.885389459510993
              ],
              [
                -41.14530058377168,
                -18.88477244819665
              ],
              [
                -41.14385606612701,
                -18.88450292875244
              ],
              [
                -41.13077267745721,
                -18.89368234755051
              ],
              [
                -41.12644470685505,
                -18.894020366605933
              ],
              [
                -41.11918326039564,
                -18.902963289862093
              ],
              [
                -41.1200917288907,
                -18.90753529134496
              ],
              [
                -41.11433675465161,
                -18.911897222191076
              ],
              [
                -41.115884743275856,
                -18.9161307019628
              ],
              [
                -41.117769221814264,
                -18.92128466912429
              ],
              [
                -41.1123587757817,
                -18.922055188422092
              ],
              [
                -41.109461815464066,
                -18.927662626021608
              ],
              [
                -41.10321581635697,
                -18.929937122896384
              ],
              [
                -41.1032733084937,
                -18.93357406401898
              ],
              [
                -41.096076903977924,
                -18.934892077437038
              ],
              [
                -41.082591517335565,
                -18.937363014116205
              ],
              [
                -41.078841526862305,
                -18.938050027348726
              ],
              [
                -41.07873805628739,
                -18.9395685233645
              ],
              [
                -41.07821652234864,
                -18.947202973196784
              ],
              [
                -41.071538062396094,
                -18.944120986097587
              ],
              [
                -41.069373616801236,
                -18.943122465361633
              ],
              [
                -41.06785014486692,
                -18.942419956250028
              ],
              [
                -41.067084627899916,
                -18.943171466317477
              ],
              [
                -41.06569012106843,
                -18.944540458510474
              ],
              [
                -41.064379631800186,
                -18.950998901486994
              ],
              [
                -41.061313654132434,
                -18.966109843861172
              ],
              [
                -41.050954215503964,
                -18.965730313485516
              ],
              [
                -41.05031172468885,
                -18.97376876974007
              ],
              [
                -41.04558876725469,
                -18.974705758496242
              ],
              [
                -41.04201578387803,
                -18.97728824014739
              ],
              [
                -41.038368313037864,
                -18.979924213334268
              ],
              [
                -41.03546733458946,
                -18.979524211602623
              ],
              [
                -41.028685420507166,
                -18.973875205102004
              ],
              [
                -41.01796649211425,
                -18.973054208536823
              ],
              [
                -41.017181520174454,
                -18.976045203627343
              ],
              [
                -41.051437138885575,
                -19.000632594064236
              ],
              [
                -41.05648611379,
                -19.00258062535736
              ],
              [
                -41.06594101185908,
                -19.006227091052487
              ],
              [
                -41.06552848320029,
                -19.015894565453408
              ],
              [
                -41.07138743420931,
                -19.02336052065384
              ],
              [
                -41.062736981932815,
                -19.03881492552584
              ],
              [
                -41.065154420761225,
                -19.050845360275392
              ],
              [
                -41.064473915190995,
                -19.051529860909785
              ],
              [
                -41.060330460163364,
                -19.05569779823848
              ],
              [
                -41.052833497497566,
                -19.058688270334436
              ],
              [
                -41.053450482625145,
                -19.06837720382546
              ],
              [
                -41.03508663170979,
                -19.071072685880512
              ],
              [
                -41.02500722034285,
                -19.079490096310334
              ],
              [
                -41.02316175286059,
                -19.0781076344121
              ],
              [
                -41.01887376720934,
                -19.074895641945346
              ],
              [
                -41.0122013362473,
                -19.075313121585282
              ],
              [
                -41.01361180906456,
                -19.08469405249287
              ],
              [
                -41.014374777563496,
                -19.089770523850277
              ],
              [
                -41.010159333086065,
                -19.08707502155423
              ],
              [
                -41.00406240303358,
                -19.083176553257253
              ],
              [
                -41.00592435977987,
                -19.089996003867284
              ],
              [
                -41.00804731399764,
                -19.097770481533523
              ],
              [
                -40.99228097667724,
                -19.09940641111001
              ],
              [
                -40.98435701388156,
                -19.100227932795036
              ],
              [
                -40.982649035511734,
                -19.106049369685504
              ],
              [
                -40.98176252835782,
                -19.10907034442877
              ],
              [
                -40.973125088031146,
                -19.115010298132827
              ],
              [
                -40.96916509694168,
                -19.12240476925193
              ],
              [
                -40.9663001396277,
                -19.121831740552867
              ],
              [
                -40.96249066611172,
                -19.12107023696262
              ],
              [
                -40.9555397420442,
                -19.12952568670494
              ],
              [
                -40.95727970093101,
                -19.13457568026561
              ],
              [
                -40.96054764698991,
                -19.144063113486258
              ],
              [
                -40.95195624361797,
                -19.14209613580608
              ],
              [
                -40.94767428280457,
                -19.141116130477652
              ],
              [
                -40.94379478371828,
                -19.14389158936631
              ],
              [
                -40.94744772518585,
                -19.150867070856474
              ],
              [
                -40.94845672777961,
                -19.152793538880616
              ],
              [
                -40.94501274762528,
                -19.15695102072643
              ],
              [
                -40.94194779717706,
                -19.16065097800089
              ],
              [
                -40.94164127092301,
                -19.16537394217203
              ],
              [
                -40.940685250207395,
                -19.180083856514123
              ],
              [
                -40.937950779927455,
                -19.182879865687337
              ],
              [
                -40.935294299297865,
                -19.181228351614298
              ],
              [
                -40.93375533215362,
                -19.18027185088854
              ],
              [
                -40.93218031825973,
                -19.185797327788777
              ],
              [
                -40.92633637506493,
                -19.188793320283427
              ],
              [
                -40.92914181025876,
                -19.197370733826453
              ],
              [
                -40.92226535669159,
                -19.203599209556003
              ],
              [
                -40.92791328176295,
                -19.21128168223783
              ],
              [
                -40.92877826616992,
                -19.214686153479594
              ],
              [
                -40.930619266974674,
                -19.221932619688413
              ],
              [
                -40.934510240262284,
                -19.222463119821224
              ],
              [
                -40.94075462646997,
                -19.231670079043965
              ],
              [
                -40.940121135531214,
                -19.234907546108698
              ],
              [
                -40.93280070291742,
                -19.241971016117365
              ],
              [
                -40.932623192091505,
                -19.2421419897614
              ],
              [
                -40.938243630530394,
                -19.248892461816354
              ],
              [
                -40.928492719930325,
                -19.25018245987284
              ],
              [
                -40.927600241565656,
                -19.250299969318593
              ],
              [
                -40.91979330335394,
                -19.251330428961282
              ],
              [
                -40.91711629073238,
                -19.25576439385085
              ],
              [
                -40.91805727168485,
                -19.26163436311242
              ],
              [
                -40.919547738728795,
                -19.27094181479746
              ],
              [
                -40.929345679384596,
                -19.27115332623338
              ],
              [
                -40.93452661320413,
                -19.27126485451452
              ],
              [
                -40.94409451718132,
                -19.279058793955517
              ],
              [
                -40.937981541329705,
                -19.28590978274476
              ],
              [
                -40.93056310956672,
                -19.285703755276757
              ],
              [
                -40.92765464453667,
                -19.28562324960375
              ],
              [
                -40.930209085311105,
                -19.291000242134487
              ],
              [
                -40.93237659841754,
                -19.295562700529402
              ],
              [
                -40.9309245734166,
                -19.29935216572321
              ],
              [
                -40.924145618393204,
                -19.30196616341642
              ],
              [
                -40.92324116377372,
                -19.301811652393763
              ],
              [
                -40.91561420244508,
                -19.300509627767827
              ],
              [
                -40.90730077186868,
                -19.30832358138282
              ],
              [
                -40.9142796980328,
                -19.317692538366437
              ],
              [
                -40.91482167488801,
                -19.31783703468229
              ],
              [
                -40.919656650991726,
                -19.319126547498737
              ],
              [
                -40.92164212053751,
                -19.328444974256932
              ],
              [
                -40.92812056919327,
                -19.329254514309177
              ],
              [
                -40.9382209313786,
                -19.34725641550186
              ],
              [
                -40.93923391181667,
                -19.358142365287488
              ],
              [
                -40.93231642998995,
                -19.363743813556862
              ],
              [
                -40.92777995158592,
                -19.36741676291303
              ],
              [
                -40.931184420392476,
                -19.373369263036455
              ],
              [
                -40.928292931678236,
                -19.380747186665513
              ],
              [
                -40.95670166336226,
                -19.398314142391616
              ],
              [
                -40.9567336370327,
                -19.398333661347813
              ],
              [
                -40.95678164139248,
                -19.398424675929142
              ],
              [
                -40.96181159435461,
                -19.407938626200973
              ],
              [
                -40.96007356407365,
                -19.418826050264936
              ],
              [
                -40.96364502354783,
                -19.42300001999723
              ],
              [
                -40.967210493087855,
                -19.42716750976015
              ],
              [
                -40.958929062639925,
                -19.438547955122658
              ],
              [
                -40.95833553397551,
                -19.439363926198233
              ],
              [
                -40.95819002743461,
                -19.4409849156274
              ],
              [
                -40.957433038165426,
                -19.449436872733813
              ],
              [
                -40.95506104179562,
                -19.451301347036235
              ],
              [
                -40.94446962106224,
                -19.459625803946334
              ],
              [
                -40.95098404814827,
                -19.46517025914925
              ],
              [
                -40.948926045661814,
                -19.47266570896772
              ],
              [
                -40.953167547355584,
                -19.4729512403724
              ],
              [
                -40.969180839726924,
                -19.49217967577462
              ],
              [
                -40.970783824942345,
                -19.494104150216327
              ],
              [
                -40.971014306341665,
                -19.496446145349623
              ],
              [
                -40.971327312018424,
                -19.499626612878018
              ],
              [
                -40.97183479722808,
                -19.504804094435222
              ],
              [
                -40.97777223188975,
                -19.50508560150427
              ],
              [
                -41.00891094898754,
                -19.506661149024907
              ],
              [
                -41.0281517909887,
                -19.498419737091044
              ],
              [
                -41.045368207363424,
                -19.487491324134588
              ],
              [
                -41.049257133163,
                -19.502529256791775
              ],
              [
                -41.04748364577826,
                -19.50509524438797
              ],
              [
                -41.04148663581779,
                -19.51397117902357
              ],
              [
                -41.04083665428914,
                -19.514933673153255
              ],
              [
                -41.04126864207307,
                -19.517618134793643
              ],
              [
                -41.04456107218637,
                -19.538072039428258
              ],
              [
                -41.03937308824986,
                -19.549370457036716
              ],
              [
                -41.038353587994415,
                -19.55159096722095
              ],
              [
                -41.03642959218859,
                -19.568302332054927
              ],
              [
                -41.05655338070128,
                -19.577543834693994
              ],
              [
                -41.05584938880796,
                -19.584295787817027
              ],
              [
                -41.062906830652125,
                -19.584124332944235
              ],
              [
                -41.071511752874244,
                -19.583915846607375
              ],
              [
                -41.07242270364787,
                -19.58983381095227
              ],
              [
                -41.073605666783614,
                -19.597515776872
              ],
              [
                -41.085491106206945,
                -19.59635827699057
              ],
              [
                -41.08991556602794,
                -19.595926802951368
              ],
              [
                -41.09136403914038,
                -19.599289800977022
              ],
              [
                -41.09224900837827,
                -19.60134429221973
              ],
              [
                -41.095090506358844,
                -19.602529271605864
              ],
              [
                -41.099382952029195,
                -19.604320754673083
              ],
              [
                -41.101590410672806,
                -19.61299923918636
              ],
              [
                -41.10799481749468,
                -19.638187084153493
              ],
              [
                -41.11412024859816,
                -19.641357102390206
              ],
              [
                -41.119215682530324,
                -19.64090508593703
              ],
              [
                -41.12363717329069,
                -19.640514133685564
              ],
              [
                -41.129090126811974,
                -19.642247113625604
              ],
              [
                -41.13372603923421,
                -19.650362081658464
              ],
              [
                -41.138221995472094,
                -19.658233038789483
              ],
              [
                -41.14855787657249,
                -19.663357046099996
              ],
              [
                -41.15280337131537,
                -19.662795063450677
              ],
              [
                -41.159473311358184,
                -19.66191204485587
              ],
              [
                -41.16806919557537,
                -19.671731030874188
              ],
              [
                -41.17339560691365,
                -19.687281952445137
              ],
              [
                -41.17128412222716,
                -19.692418404835998
              ],
              [
                -41.177319579715,
                -19.70279886422624
              ],
              [
                -41.17578056693222,
                -19.708354821496545
              ],
              [
                -41.173900554188236,
                -19.71514380906447
              ],
              [
                -41.178553019541084,
                -19.719461281755123
              ],
              [
                -41.178621491781115,
                -19.719524763544893
              ],
              [
                -41.18161047691014,
                -19.7316817233666
              ],
              [
                -41.18160645791549,
                -19.73169422728725
              ],
              [
                -41.17910243296422,
                -19.739603681198275
              ],
              [
                -41.1843714076199,
                -19.742770667751962
              ],
              [
                -41.190393836505336,
                -19.746390129393916
              ],
              [
                -41.18739837215566,
                -19.757490575252678
              ],
              [
                -41.17449395850996,
                -19.76656401103313
              ],
              [
                -41.17128745358348,
                -19.777842924437238
              ],
              [
                -41.17289890589857,
                -19.78336892677112
              ],
              [
                -41.16554044324366,
                -19.791972831252064
              ],
              [
                -41.165770911841875,
                -19.809418228396165
              ],
              [
                -41.17504480724477,
                -19.82520919960382
              ],
              [
                -41.185888201345094,
                -19.82717470615554
              ],
              [
                -41.17866725898292,
                -19.835134624591937
              ],
              [
                -41.17528976786309,
                -19.838857601265122
              ],
              [
                -41.17873824561577,
                -19.846527068599755
              ],
              [
                -41.180142717896665,
                -19.84704757873212
              ],
              [
                -41.18360020612115,
                -19.848330055052966
              ],
              [
                -41.184476074450984,
                -19.88846884214458
              ],
              [
                -41.18763806099252,
                -19.890688323575713
              ],
              [
                -41.20382139476966,
                -19.902037307175426
              ],
              [
                -41.20464738302852,
                -19.902617280723685
              ],
              [
                -41.205150356985015,
                -19.902447308779095
              ],
              [
                -41.21087632696505,
                -19.900508346200724
              ],
              [
                -41.227970166153476,
                -19.90512533867691
              ],
              [
                -41.232174094166666,
                -19.91849325482696
              ],
              [
                -41.23310858435206,
                -19.919935244779992
              ],
              [
                -41.24377047052091,
                -19.936391172812176
              ],
              [
                -41.248518908827556,
                -19.93745520625928
              ],
              [
                -41.25012188963712,
                -19.935818207957414
              ],
              [
                -41.25420386868412,
                -19.931651221284
              ],
              [
                -41.271697714204166,
                -19.941625702737987
              ],
              [
                -41.288163565701474,
                -19.939440758136207
              ],
              [
                -41.29966197460858,
                -19.93791429094131
              ],
              [
                -41.299964960023495,
                -19.937874284830915
              ],
              [
                -41.307512372686524,
                -19.94784473855475
              ],
              [
                -41.30969384108185,
                -19.96138117454603
              ],
              [
                -41.311288294615885,
                -19.97127412567178
              ],
              [
                -41.3153897638426,
                -19.97470562249208
              ],
              [
                -41.319491682065184,
                -19.978136627321767
              ],
              [
                -41.3145502166158,
                -19.98634206753198
              ],
              [
                -41.30838825497875,
                -19.99657447671782
              ],
              [
                -41.30768821183945,
                -20.012525405967015
              ],
              [
                -41.31670109612269,
                -20.026243851158192
              ],
              [
                -41.31685212521652,
                -20.02647385320608
              ],
              [
                -41.316891614247936,
                -20.026724348893296
              ],
              [
                -41.3195555735267,
                -20.043766262560403
              ],
              [
                -41.32377650209508,
                -20.051865727650394
              ],
              [
                -41.328025442180575,
                -20.054803682635207
              ],
              [
                -41.33314992001234,
                -20.05834617918573
              ],
              [
                -41.331481416952364,
                -20.062724673057282
              ],
              [
                -41.335204369393345,
                -20.06497615272359
              ],
              [
                -41.33618483651439,
                -20.075950622655707
              ],
              [
                -41.33712530906327,
                -20.08648455897658
              ],
              [
                -41.340587752146334,
                -20.087387542606006
              ],
              [
                -41.34481322897409,
                -20.08849055981103
              ],
              [
                -41.34590268636326,
                -20.10297747845781
              ],
              [
                -41.35048110981987,
                -20.11866538618543
              ],
              [
                -41.35043109508523,
                -20.118736904676016
              ],
              [
                -41.347113621854,
                -20.123413366265314
              ],
              [
                -41.35403507624252,
                -20.13076383268068
              ],
              [
                -41.35611555230953,
                -20.132973847991266
              ],
              [
                -41.35592103318728,
                -20.134519303953855
              ],
              [
                -41.3554060065851,
                -20.138603802068147
              ],
              [
                -41.35893851397286,
                -20.140335805268908
              ],
              [
                -41.36148498130026,
                -20.141584791970867
              ],
              [
                -41.36742836783928,
                -20.15635673496693
              ],
              [
                -41.37552478439539,
                -20.161454219609183
              ],
              [
                -41.373685298203604,
                -20.167664664977217
              ],
              [
                -41.37537779033464,
                -20.172022646507294
              ],
              [
                -41.38170570858117,
                -20.18831807000456
              ],
              [
                -41.40125848531261,
                -20.198735064690442
              ],
              [
                -41.40868444535727,
                -20.197205084739338
              ],
              [
                -41.412480388360954,
                -20.20175157295629
              ],
              [
                -41.41129287378395,
                -20.20618602668821
              ],
              [
                -41.424463260161495,
                -20.206173576133335
              ],
              [
                -41.556417592854785,
                -20.206052828602164
              ],
              [
                -41.665533166530736,
                -20.206204061203138
              ],
              [
                -41.754295391030006,
                -20.206368729579296
              ],
              [
                -41.75634785692488,
                -20.206372241172698
              ],
              [
                -41.75667783428221,
                -20.213402189147175
              ],
              [
                -41.759479801931256,
                -20.212865686405085
              ],
              [
                -41.76537071936244,
                -20.223416164492086
              ],
              [
                -41.7643307266455,
                -20.225747119305158
              ],
              [
                -41.76362171588384,
                -20.227336119952348
              ],
              [
                -41.76623872273986,
                -20.228551634142487
              ],
              [
                -41.76670019513067,
                -20.228765610001812
              ],
              [
                -41.76675571826184,
                -20.229311637117803
              ],
              [
                -41.769344630493855,
                -20.254603988622506
              ],
              [
                -41.7743155591607,
                -20.259543943626245
              ],
              [
                -41.77933847255622,
                -20.278619875967408
              ],
              [
                -41.780033984727034,
                -20.281261858001432
              ],
              [
                -41.77922899676857,
                -20.28207334672048
              ],
              [
                -41.77665749387606,
                -20.284664829660517
              ],
              [
                -41.78062096019469,
                -20.292050801980512
              ],
              [
                -41.793164343980834,
                -20.29583731848631
              ],
              [
                -41.802679239798636,
                -20.298709805192313
              ],
              [
                -41.80529919241746,
                -20.30433476719931
              ],
              [
                -41.80653817173792,
                -20.30699478738309
              ],
              [
                -41.810705128142416,
                -20.308120258620214
              ],
              [
                -41.8226675394812,
                -20.3113507689273
              ],
              [
                -41.824550497016446,
                -20.31719876707058
              ],
              [
                -41.825245971388284,
                -20.319360751772507
              ],
              [
                -41.82525298627179,
                -20.319382242670425
              ],
              [
                -41.825265001145475,
                -20.31939025765359
              ],
              [
                -41.83388539729441,
                -20.325234715079493
              ],
              [
                -41.835925387256204,
                -20.329579726850064
              ],
              [
                -41.8414448385673,
                -20.32606024966504
              ],
              [
                -41.846733277495936,
                -20.32899275254942
              ],
              [
                -41.84399179951287,
                -20.340437176548004
              ],
              [
                -41.85223767042033,
                -20.347665121595234
              ],
              [
                -41.85209567768647,
                -20.356288081991437
              ],
              [
                -41.85499012312674,
                -20.363755551622337
              ],
              [
                -41.85837108706491,
                -20.372478021494086
              ],
              [
                -41.84916114308134,
                -20.374108981648604
              ],
              [
                -41.84832716905624,
                -20.374256501946988
              ],
              [
                -41.83466519787901,
                -20.409336752728926
              ],
              [
                -41.80296994634369,
                -20.42189613289761
              ],
              [
                -41.80310145538049,
                -20.428855606075988
              ],
              [
                -41.80310692454035,
                -20.429165085393407
              ],
              [
                -41.79782946139778,
                -20.434523545046776
              ],
              [
                -41.80240642586659,
                -20.442391541372384
              ],
              [
                -41.799178872381034,
                -20.477102313065494
              ],
              [
                -41.803071292139286,
                -20.485239277431955
              ],
              [
                -41.805696761870166,
                -20.490728265403735
              ],
              [
                -41.80725975568104,
                -20.493995763104174
              ],
              [
                -41.80823619874347,
                -20.503848718475087
              ],
              [
                -41.808961684514585,
                -20.51117067311879
              ],
              [
                -41.81037614741822,
                -20.51490717193249
              ],
              [
                -41.81179113531346,
                -20.51864413574822
              ],
              [
                -41.8111261670129,
                -20.520265134374938
              ],
              [
                -41.80388816750967,
                -20.537904003505083
              ],
              [
                -41.803904678715156,
                -20.543590979490865
              ],
              [
                -41.80789713281485,
                -20.550050957352823
              ],
              [
                -41.81565601863363,
                -20.55263946996958
              ],
              [
                -41.827220431584394,
                -20.5564979433737
              ],
              [
                -41.840391262117926,
                -20.586004315655355
              ],
              [
                -41.840745724426775,
                -20.595890298601375
              ],
              [
                -41.840891209656995,
                -20.599943746853015
              ],
              [
                -41.841138719559815,
                -20.600296249500474
              ],
              [
                -41.84533317107517,
                -20.606267728598265
              ],
              [
                -41.85273259669783,
                -20.607060727259633
              ],
              [
                -41.85573703619108,
                -20.614005700344823
              ],
              [
                -41.85251955877997,
                -20.618181171227924
              ],
              [
                -41.84635162379537,
                -20.6214396493278
              ],
              [
                -41.82869674930794,
                -20.62429308625945
              ],
              [
                -41.82533978614444,
                -20.624220575948435
              ],
              [
                -41.81700633921492,
                -20.62404155479675
              ],
              [
                -41.81964880908499,
                -20.632839524952736
              ],
              [
                -41.82078827718332,
                -20.63663549490241
              ],
              [
                -41.82005629161911,
                -20.6370330220573
              ],
              [
                -41.81432682217597,
                -20.640141973973954
              ],
              [
                -41.81104639815674,
                -20.63705499024874
              ],
              [
                -41.80838238135108,
                -20.643746951695803
              ],
              [
                -41.81601182167202,
                -20.646552458947806
              ],
              [
                -41.81282333972551,
                -20.652767410795523
              ],
              [
                -41.819074277713995,
                -20.657309881441986
              ],
              [
                -41.82563422467564,
                -20.6561864216339
              ],
              [
                -41.827234677249606,
                -20.65591193280643
              ],
              [
                -41.826152720474845,
                -20.657966903139215
              ],
              [
                -41.82374571087147,
                -20.662539380730408
              ],
              [
                -41.83287761691434,
                -20.670350839249025
              ],
              [
                -41.8389590104503,
                -20.682950301748807
              ],
              [
                -41.8457889577156,
                -20.685412305095454
              ],
              [
                -41.84756095540275,
                -20.686050798706827
              ],
              [
                -41.84789792421558,
                -20.686900788864317
              ],
              [
                -41.849933409892294,
                -20.69203027768372
              ],
              [
                -41.85085888642901,
                -20.69483127659981
              ],
              [
                -41.85420584947735,
                -20.70496770826077
              ],
              [
                -41.855900316628215,
                -20.70530619532932
              ],
              [
                -41.860643799264096,
                -20.706252734127066
              ],
              [
                -41.86188375795239,
                -20.71099719822904
              ],
              [
                -41.859320263911684,
                -20.712411168640458
              ],
              [
                -41.85604782711943,
                -20.71421468830185
              ],
              [
                -41.86088574480737,
                -20.722109118613385
              ],
              [
                -41.8636577053735,
                -20.7217056351998
              ],
              [
                -41.864945216488245,
                -20.721518647185746
              ],
              [
                -41.86436169420618,
                -20.723220145605758
              ],
              [
                -41.856145237256825,
                -20.747188980600537
              ],
              [
                -41.859145693998535,
                -20.748241472823842
              ],
              [
                -41.8631026957729,
                -20.741448511806816
              ],
              [
                -41.873412561086504,
                -20.74359755596949
              ],
              [
                -41.87713703366543,
                -20.755664486158537
              ],
              [
                -41.87779101834933,
                -20.757783977182836
              ],
              [
                -41.87855852506634,
                -20.760271956473666
              ],
              [
                -41.87450052309149,
                -20.76632441128902
              ],
              [
                -41.87305804607068,
                -20.770440889646306
              ],
              [
                -41.878661480274495,
                -20.773610895435866
              ],
              [
                -41.878393971562936,
                -20.778225855817205
              ],
              [
                -41.88515139267663,
                -20.784792359896997
              ],
              [
                -41.896286775238536,
                -20.789019830798637
              ],
              [
                -41.900791732806844,
                -20.7957517967999
              ],
              [
                -41.905900161086564,
                -20.796743329273927
              ],
              [
                -41.91564061970091,
                -20.79230285187778
              ],
              [
                -41.92772600722416,
                -20.794499372012485
              ],
              [
                -41.92493249661702,
                -20.801085839909877
              ],
              [
                -41.934982830023046,
                -20.828069201369033
              ],
              [
                -41.92576391341662,
                -20.83759015351189
              ],
              [
                -41.929261859424265,
                -20.844472637459894
              ],
              [
                -41.93523526120526,
                -20.854149579087593
              ],
              [
                -41.94239571354565,
                -20.855828560676343
              ],
              [
                -41.94937613359202,
                -20.85746507408107
              ],
              [
                -41.95431457209979,
                -20.865013540837733
              ],
              [
                -41.95105210949089,
                -20.871624008577317
              ],
              [
                -41.94992308643262,
                -20.873911518607727
              ],
              [
                -41.95431554396975,
                -20.877579975047343
              ],
              [
                -41.9600090091462,
                -20.882336498656702
              ],
              [
                -41.964783933491155,
                -20.89237491766223
              ],
              [
                -41.96704790092815,
                -20.89940840703131
              ],
              [
                -41.96612190130589,
                -20.901225908077215
              ],
              [
                -41.963680426210516,
                -20.906017863560372
              ],
              [
                -41.96692534226731,
                -20.916342786504437
              ],
              [
                -41.97138280443853,
                -20.915760837041848
              ],
              [
                -41.972459327304136,
                -20.915619828313826
              ],
              [
                -41.979230751984446,
                -20.921867295476424
              ],
              [
                -41.97899124430098,
                -20.922925289220462
              ],
              [
                -41.97625673047183,
                -20.935010730770284
              ],
              [
                -41.998265068041064,
                -20.92597684281118
              ],
              [
                -42.00417948109499,
                -20.931540787691578
              ],
              [
                -42.01607189427873,
                -20.928079849108517
              ],
              [
                -42.01618339142836,
                -20.928029338640883
              ],
              [
                -42.02295883969279,
                -20.924983381864358
              ],
              [
                -42.02736577876096,
                -20.92764087387222
              ],
              [
                -42.038126193607624,
                -20.92695990569523
              ],
              [
                -42.04576159890369,
                -20.93274537444818
              ],
              [
                -42.045775134757704,
                -20.93275538142607
              ],
              [
                -42.06533193292768,
                -20.938145420227173
              ],
              [
                -42.087032726177746,
                -20.93865344431491
              ],
              [
                -42.090134716655115,
                -20.93720947033549
              ],
              [
                -42.093237189130924,
                -20.93576499135976
              ],
              [
                -42.10789006905264,
                -20.946641946300097
              ],
              [
                -42.1090125049436,
                -20.947474950460684
              ],
              [
                -42.110689029425,
                -20.94812895788137
              ],
              [
                -42.11481647275996,
                -20.949738456922056
              ],
              [
                -42.11484047896454,
                -20.95033445578031
              ],
              [
                -42.11518043964326,
                -20.95873289551866
              ],
              [
                -42.12145590596187,
                -20.959242913505555
              ],
              [
                -42.12481237397626,
                -20.955631426751864
              ],
              [
                -42.125074397416356,
                -20.955349416879255
              ],
              [
                -42.137993239004075,
                -20.960064427908545
              ],
              [
                -42.151261086270154,
                -20.973889382961534
              ],
              [
                -42.15032260708673,
                -20.979315864681123
              ],
              [
                -42.15018258816287,
                -20.98012585901245
              ],
              [
                -42.14116766735948,
                -20.986604806115224
              ],
              [
                -42.14112014751875,
                -20.987452781460508
              ],
              [
                -42.14082513823351,
                -20.992662783856716
              ],
              [
                -42.126084755385364,
                -21.003093200329413
              ],
              [
                -42.12110777067116,
                -21.002568683438263
              ],
              [
                -42.116597330714576,
                -21.00209315438317
              ],
              [
                -42.11527682635009,
                -21.006973132134156
              ],
              [
                -42.11464733369419,
                -21.0093011171828
              ],
              [
                -42.11129986304736,
                -21.011240099924816
              ],
              [
                -42.10733091092389,
                -21.01353906827683
              ],
              [
                -42.10228491427843,
                -21.013972577090183
              ],
              [
                -42.08450255572049,
                -21.022388969187894
              ],
              [
                -42.08155559763192,
                -21.029546942907007
              ],
              [
                -42.080518585345466,
                -21.03345893951059
              ],
              [
                -42.08005706077789,
                -21.035197909109506
              ],
              [
                -42.09299946449684,
                -21.036722933390134
              ],
              [
                -42.09333146746317,
                -21.036761945962425
              ],
              [
                -42.10070489386921,
                -21.033966449282698
              ],
              [
                -42.09854492029316,
                -21.039072426859434
              ],
              [
                -42.10247483290497,
                -21.045329895610763
              ],
              [
                -42.1017698353135,
                -21.047130892310445
              ],
              [
                -42.10093137399081,
                -21.04927189987621
              ],
              [
                -42.119336142139595,
                -21.068890838282158
              ],
              [
                -42.11994411187812,
                -21.074456309956656
              ],
              [
                -42.12806706781288,
                -21.078340797351533
              ],
              [
                -42.132757013432645,
                -21.080583276432016
              ],
              [
                -42.133710469085166,
                -21.08503828087473
              ],
              [
                -42.13561044752543,
                -21.09391672892419
              ],
              [
                -42.139871884203956,
                -21.102390668728276
              ],
              [
                -42.15777375614589,
                -21.1013282237012
              ],
              [
                -42.163957205595885,
                -21.100961723271823
              ],
              [
                -42.16710164120999,
                -21.107538198604498
              ],
              [
                -42.17024557481777,
                -21.11411568394195
              ],
              [
                -42.170237563183356,
                -21.128145091086015
              ],
              [
                -42.17537998392499,
                -21.132200095634932
              ],
              [
                -42.178039484184964,
                -21.137138589602245
              ],
              [
                -42.17899445676863,
                -21.140481069045975
              ],
              [
                -42.18839133273849,
                -21.14611254429513
              ],
              [
                -42.19040135276969,
                -21.147317036640434
              ],
              [
                -42.195443236340076,
                -21.159554514403705
              ],
              [
                -42.19507024719554,
                -21.16008648668597
              ],
              [
                -42.19235576670442,
                -21.16395595064077
              ],
              [
                -42.19842220073152,
                -21.167098951294662
              ],
              [
                -42.19843769957183,
                -21.16710746828605
              ],
              [
                -42.19844222144033,
                -21.16714144711589
              ],
              [
                -42.199040700098685,
                -21.171243960693882
              ],
              [
                -42.19931818750917,
                -21.173150414203278
              ],
              [
                -42.200327169192185,
                -21.173676450797505
              ],
              [
                -42.20807962592045,
                -21.177718926683063
              ],
              [
                -42.198197639420634,
                -21.195368326303964
              ],
              [
                -42.19480968496044,
                -21.20141876206761
              ],
              [
                -42.197318123980125,
                -21.204325249567578
              ],
              [
                -42.201477091269616,
                -21.209144252823076
              ],
              [
                -42.20222757442218,
                -21.217734192934095
              ],
              [
                -42.20253003339742,
                -21.22119319826498
              ],
              [
                -42.19832808604395,
                -21.22467567475861
              ],
              [
                -42.19691560533675,
                -21.225846672174175
              ],
              [
                -42.19099214422955,
                -21.223764647154592
              ],
              [
                -42.18836715972617,
                -21.229554604129927
              ],
              [
                -42.194314567496534,
                -21.23906906171028
              ],
              [
                -42.18928308290135,
                -21.249404994813677
              ],
              [
                -42.203636953137476,
                -21.261249999087667
              ],
              [
                -42.21211186235103,
                -21.271574941477983
              ],
              [
                -42.21698178383751,
                -21.27398693629052
              ],
              [
                -42.22298071984633,
                -21.276957928835085
              ],
              [
                -42.22857116857605,
                -21.283935405161845
              ],
              [
                -42.22942560271232,
                -21.307963284811457
              ],
              [
                -42.23781300288214,
                -21.318187765679312
              ],
              [
                -42.236274487764994,
                -21.32468422358848
              ],
              [
                -42.2317970206782,
                -21.326738200002527
              ],
              [
                -42.228999563869074,
                -21.32802115751761
              ],
              [
                -42.22850508161426,
                -21.32824816313274
              ],
              [
                -42.223707065759065,
                -21.337497107670668
              ],
              [
                -42.234828454216476,
                -21.342778120314872
              ],
              [
                -42.23580844930059,
                -21.343243130198353
              ],
              [
                -42.235050985751734,
                -21.34406212004996
              ],
              [
                -42.22896502150677,
                -21.35064607063009
              ],
              [
                -42.23219748275385,
                -21.353661065409188
              ],
              [
                -42.22945194310862,
                -21.366074983140347
              ],
              [
                -42.23984738074246,
                -21.37179296179684
              ],
              [
                -42.241423825459634,
                -21.37266048198639
              ],
              [
                -42.24069484387134,
                -21.376694962902167
              ],
              [
                -42.24056135402897,
                -21.37743445267153
              ],
              [
                -42.24086181475965,
                -21.377660440196674
              ],
              [
                -42.25119870824605,
                -21.385447420811744
              ],
              [
                -42.249148237174424,
                -21.389531387306526
              ],
              [
                -42.24861522351081,
                -21.389665885317044
              ],
              [
                -42.23752584573672,
                -21.392464856922306
              ],
              [
                -42.23872980284648,
                -21.401334811927285
              ],
              [
                -42.260400588981774,
                -21.409585816357215
              ],
              [
                -42.26480307271918,
                -21.403162384854962
              ],
              [
                -42.267187564202295,
                -21.39968339095598
              ],
              [
                -42.27865140356684,
                -21.4100833435692
              ],
              [
                -42.27606641145842,
                -21.423552769259235
              ],
              [
                -42.2752189148345,
                -21.427966264930497
              ],
              [
                -42.27935436437153,
                -21.431236238620205
              ],
              [
                -42.28049585382265,
                -21.43213873160393
              ],
              [
                -42.280108351886724,
                -21.432997246142605
              ],
              [
                -42.279245862253234,
                -21.43490873398389
              ],
              [
                -42.28238083526486,
                -21.438306207602977
              ],
              [
                -42.285638283828554,
                -21.4418367168197
              ],
              [
                -42.284771269838956,
                -21.443891182898685
              ],
              [
                -42.28390178885416,
                -21.445951666940296
              ],
              [
                -42.28523975003289,
                -21.448141690612186
              ],
              [
                -42.28590626615193,
                -21.449233682458683
              ],
              [
                -42.29220417224769,
                -21.459543631273394
              ],
              [
                -42.277271275944,
                -21.46503605535896
              ],
              [
                -42.26820186983414,
                -21.46837202493205
              ],
              [
                -42.27099134050029,
                -21.475177012781664
              ],
              [
                -42.25234897194575,
                -21.489778870909998
              ],
              [
                -42.25439644598743,
                -21.494095351097656
              ],
              [
                -42.27334423329075,
                -21.503676857314087
              ],
              [
                -42.28574008105093,
                -21.527179249533262
              ],
              [
                -42.29421001393555,
                -21.524763780699345
              ],
              [
                -42.29669946891871,
                -21.524053305450295
              ],
              [
                -42.30592490271781,
                -21.529040276511903
              ],
              [
                -42.303311370710766,
                -21.536674245054908
              ],
              [
                -42.302511416319945,
                -21.53901071883704
              ],
              [
                -42.31017030874022,
                -21.546600193464034
              ],
              [
                -42.310033774638335,
                -21.551414661833398
              ],
              [
                -42.31811122194327,
                -21.554811202532566
              ],
              [
                -42.33072808090409,
                -21.56011666420815
              ],
              [
                -42.32697963124004,
                -21.562194163222575
              ],
              [
                -42.32674060499459,
                -21.563406660276744
              ],
              [
                -42.325534596691526,
                -21.569530129203372
              ],
              [
                -42.34582591169318,
                -21.582491600348902
              ],
              [
                -42.350217349924186,
                -21.588268078696892
              ],
              [
                -42.35272633562663,
                -21.591569581467915
              ],
              [
                -42.368575100282996,
                -21.619118481554732
              ],
              [
                -42.364713147147675,
                -21.623514919142053
              ],
              [
                -42.360537155147966,
                -21.62166392369211
              ],
              [
                -42.35889217169089,
                -21.62093543351232
              ],
              [
                -42.355308704020246,
                -21.62536190961674
              ],
              [
                -42.36052216016259,
                -21.630700863319536
              ],
              [
                -42.36876855589539,
                -21.639144335154402
              ],
              [
                -42.364603060143516,
                -21.645799826182714
              ],
              [
                -42.35508417286364,
                -21.64698879680804
              ],
              [
                -42.34627225519689,
                -21.642482311980366
              ],
              [
                -42.343507742761226,
                -21.64997572501829
              ],
              [
                -42.342250263588156,
                -21.655465700215103
              ],
              [
                -42.34238725291994,
                -21.65778620239943
              ],
              [
                -42.34051023883683,
                -21.660178188311345
              ],
              [
                -42.33778577650183,
                -21.66139767230001
              ],
              [
                -42.331452348964895,
                -21.66120766989007
              ],
              [
                -42.325668883787856,
                -21.656628668804483
              ],
              [
                -42.32453792226834,
                -21.655733174744835
              ],
              [
                -42.32103891525504,
                -21.658579645329787
              ],
              [
                -42.31972442389056,
                -21.659649138532515
              ],
              [
                -42.31944396047401,
                -21.659251628932594
              ],
              [
                -42.31619897390795,
                -21.654661165089006
              ],
              [
                -42.30935005427997,
                -21.658303617360623
              ],
              [
                -42.30153462985142,
                -21.654519611193365
              ],
              [
                -42.28265272319554,
                -21.670165514324452
              ],
              [
                -42.286701712672645,
                -21.672227486362857
              ],
              [
                -42.28623722694676,
                -21.67324497890312
              ],
              [
                -42.28295170693374,
                -21.68045044316906
              ],
              [
                -42.2786832525906,
                -21.678320943946414
              ],
              [
                -42.274871778576724,
                -21.676418430648827
              ],
              [
                -42.27023029630667,
                -21.68119892831861
              ],
              [
                -42.26689982243884,
                -21.694602838002883
              ],
              [
                -42.27050928234542,
                -21.70112932760391
              ],
              [
                -42.271571248604765,
                -21.703049813131955
              ],
              [
                -42.26899379396374,
                -21.705357771775635
              ],
              [
                -42.26754026518818,
                -21.70665979442124
              ],
              [
                -42.26620128768708,
                -21.707858766885114
              ],
              [
                -42.271127229041944,
                -21.714705749031143
              ],
              [
                -42.29948042860798,
                -21.72608774454896
              ],
              [
                -42.30679639873893,
                -21.729024257028104
              ],
              [
                -42.30924883527745,
                -21.73216925056593
              ],
              [
                -42.31170133281364,
                -21.735314241107407
              ],
              [
                -42.33541309406811,
                -21.745754226420882
              ],
              [
                -42.34576949065361,
                -21.7435757590826
              ],
              [
                -42.35612340827619,
                -21.74139728273997
              ],
              [
                -42.37636321900164,
                -21.748007316681324
              ],
              [
                -42.378947710297105,
                -21.750106803043124
              ],
              [
                -42.39071954754905,
                -21.75966877794851
              ],
              [
                -42.43169364998648,
                -21.774575805552086
              ],
              [
                -42.46023989388572,
                -21.784961301449957
              ],
              [
                -42.46380783593108,
                -21.786552315921515
              ],
              [
                -42.46546035030926,
                -21.78727830519736
              ],
              [
                -42.55761492744941,
                -21.82779580117837
              ],
              [
                -42.566752326971766,
                -21.830961810259357
              ],
              [
                -42.58197466511287,
                -21.83722878827254
              ],
              [
                -42.581304700822876,
                -21.841187770104654
              ],
              [
                -42.58050169848426,
                -21.84593123354584
              ],
              [
                -42.59365053106397,
                -21.851782265657803
              ],
              [
                -42.59778702752016,
                -21.853229741364768
              ],
              [
                -42.6047784326324,
                -21.854745257765835
              ],
              [
                -42.60567695203249,
                -21.8539272742179
              ],
              [
                -42.60812992674893,
                -21.85169276283945
              ],
              [
                -42.62062329924454,
                -21.85891428029064
              ],
              [
                -42.62875972345027,
                -21.861783296533808
              ],
              [
                -42.636897135651274,
                -21.86465228778926
              ],
              [
                -42.65140799279848,
                -21.869261809804257
              ],
              [
                -42.65518397118379,
                -21.871506263533274
              ],
              [
                -42.66730384745751,
                -21.87327579435045
              ],
              [
                -42.67364478400623,
                -21.877020808679912
              ],
              [
                -42.680653700389726,
                -21.87910579324644
              ],
              [
                -42.685962164395306,
                -21.881665317160593
              ],
              [
                -42.69776104452154,
                -21.887658789417003
              ],
              [
                -42.697765524476374,
                -21.887660800417713
              ],
              [
                -42.69942804144489,
                -21.88850531117423
              ],
              [
                -42.70013951538208,
                -21.88878729248025
              ],
              [
                -42.70014251435147,
                -21.88878877848003
              ],
              [
                -42.702657974379115,
                -21.88978629855971
              ],
              [
                -42.70782744035368,
                -21.892436275685235
              ],
              [
                -42.710077919268656,
                -21.89359030030428
              ],
              [
                -42.73209668483173,
                -21.904030264019617
              ],
              [
                -42.738754633138285,
                -21.90758530020096
              ],
              [
                -42.74896703189996,
                -21.91229179127629
              ],
              [
                -42.75538295821631,
                -21.91483679408363
              ],
              [
                -42.75538645818121,
                -21.914838307084562
              ],
              [
                -42.755633963723646,
                -21.914936791191383
              ],
              [
                -42.7562239507608,
                -21.91520829425686
              ],
              [
                -42.76620888483165,
                -21.919808763345564
              ],
              [
                -42.77294679464687,
                -21.922590308753286
              ],
              [
                -42.77295480056516,
                -21.922594283752666
              ],
              [
                -42.775539249796964,
                -21.923660772680382
              ],
              [
                -42.78304420915427,
                -21.927043786908328
              ],
              [
                -42.785643666954016,
                -21.928215783334775
              ],
              [
                -42.79119210219604,
                -21.930659757545598
              ],
              [
                -42.823781778438885,
                -21.940914821844796
              ],
              [
                -42.82849625760554,
                -21.942454815660223
              ],
              [
                -42.82850075756172,
                -21.942456307663782
              ],
              [
                -42.83321169076532,
                -21.943994808481218
              ],
              [
                -42.882090249667456,
                -21.95961634602347
              ],
              [
                -42.88222723838672,
                -21.963132821319665
              ],
              [
                -42.88236373711099,
                -21.966648307621675
              ],
              [
                -42.90866294716619,
                -21.98999725854287
              ],
              [
                -42.914190899190515,
                -21.992238735868476
              ],
              [
                -42.94649355466006,
                -22.006139248452513
              ],
              [
                -42.95929944140125,
                -22.014432745066298
              ],
              [
                -42.96147739929268,
                -22.014431257526475
              ],
              [
                -42.96599488465818,
                -22.0144287538498
              ],
              [
                -42.98587765238266,
                -22.03032969636875
              ],
              [
                -42.9893591339505,
                -22.032718225891443
              ],
              [
                -42.992840553518576,
                -22.035106704417576
              ],
              [
                -42.99382255055568,
                -22.034880719034405
              ],
              [
                -43.00337397712168,
                -22.032679248231737
              ],
              [
                -43.025204785241996,
                -22.039753753876536
              ],
              [
                -43.03816216898392,
                -22.04655373170338
              ],
              [
                -43.03848311802634,
                -22.052485735246975
              ],
              [
                -43.03285815165206,
                -22.058953669124616
              ],
              [
                -43.03217718935977,
                -22.05973666941996
              ],
              [
                -43.032182132115324,
                -22.066030619331947
              ],
              [
                -43.035270601225946,
                -22.0687286323356
              ],
              [
                -43.043534543501984,
                -22.07538262518858
              ],
              [
                -43.05052844815512,
                -22.080915611580718
              ],
              [
                -43.053000422603,
                -22.082599590219118
              ],
              [
                -43.05762037337688,
                -22.08526408626904
              ],
              [
                -43.064809828879035,
                -22.089220584211308
              ],
              [
                -43.069138759623314,
                -22.091395581033268
              ],
              [
                -43.07299821011706,
                -22.093352102789083
              ],
              [
                -43.075838213663324,
                -22.08733563757263
              ],
              [
                -43.0775957280456,
                -22.08361363794944
              ],
              [
                -43.089068084375555,
                -22.087449646304236
              ],
              [
                -43.10632747346697,
                -22.08354171466321
              ],
              [
                -43.12244681848814,
                -22.090353716620072
              ],
              [
                -43.124183790285535,
                -22.095517181737943
              ],
              [
                -43.12717622724384,
                -22.104411678059375
              ],
              [
                -43.136462113138705,
                -22.10880465615563
              ],
              [
                -43.14459508233063,
                -22.10005372714863
              ],
              [
                -43.153698539150035,
                -22.07748185885262
              ],
              [
                -43.15192310290069,
                -22.074308858513113
              ],
              [
                -43.150148115642565,
                -22.071136860172516
              ],
              [
                -43.13366377559787,
                -22.05850488092601
              ],
              [
                -43.12787337006801,
                -22.046155466225887
              ],
              [
                -43.129682372417626,
                -22.035033031424224
              ],
              [
                -43.13066089690666,
                -22.02948256316021
              ],
              [
                -43.13360083472899,
                -22.03032556425777
              ],
              [
                -43.14373327562427,
                -22.03322957494896
              ],
              [
                -43.14715073825629,
                -22.03062157482656
              ],
              [
                -43.15238820479001,
                -22.031528090384946
              ],
              [
                -43.154708149322765,
                -22.03192960417688
              ],
              [
                -43.1629711233226,
                -22.02802013287004
              ],
              [
                -43.16366611074162,
                -22.025082637574705
              ],
              [
                -43.164290620916866,
                -22.022442168590533
              ],
              [
                -43.16912456374973,
                -22.021705687499743
              ],
              [
                -43.175241010114696,
                -22.02451116860454
              ],
              [
                -43.19790130377295,
                -22.03490718674634
              ],
              [
                -43.20222824624609,
                -22.036522185430226
              ],
              [
                -43.20492124346769,
                -22.034874188594745
              ],
              [
                -43.202788274426126,
                -22.02579471140668
              ],
              [
                -43.202607293240355,
                -22.02502424587068
              ],
              [
                -43.20440125510212,
                -22.02586970408625
              ],
              [
                -43.21291969197471,
                -22.02988472811582
              ],
              [
                -43.22199809809501,
                -22.031090756851597
              ],
              [
                -43.22733803701743,
                -22.02742925292109
              ],
              [
                -43.236320989136495,
                -22.022373799254307
              ],
              [
                -43.236288501787186,
                -22.019831326099517
              ],
              [
                -43.23619850907204,
                -22.012754862857832
              ],
              [
                -43.24612643630445,
                -22.006537924473466
              ],
              [
                -43.254375866859355,
                -22.011493899049267
              ],
              [
                -43.261989813344975,
                -22.008036443805178
              ],
              [
                -43.26559924535955,
                -22.010262427582685
              ],
              [
                -43.269043246113284,
                -22.012386958462105
              ],
              [
                -43.27290421253235,
                -22.01164496596203
              ],
              [
                -43.27471820004305,
                -22.011176444914113
              ],
              [
                -43.274749697780905,
                -22.011171952016316
              ],
              [
                -43.28009762188506,
                -22.01054348168709
              ],
              [
                -43.283724617785424,
                -22.012067497084963
              ],
              [
                -43.30056893414333,
                -22.019144482610415
              ],
              [
                -43.3164848026075,
                -22.01554505903201
              ],
              [
                -43.31696731205294,
                -22.013939527399916
              ],
              [
                -43.318627810576956,
                -22.008416592628535
              ],
              [
                -43.320100314489046,
                -22.006428090438682
              ],
              [
                -43.3304572125456,
                -22.010404084377612
              ],
              [
                -43.33895513334762,
                -22.010068119523506
              ],
              [
                -43.3464290885519,
                -22.00337166536593
              ],
              [
                -43.35167450159338,
                -22.008260157795227
              ],
              [
                -43.35406298931764,
                -22.008754154317025
              ],
              [
                -43.36548937376015,
                -22.011117188174353
              ],
              [
                -43.368035354867935,
                -22.019614143514588
              ],
              [
                -43.36815232271436,
                -22.02000412583269
              ],
              [
                -43.418847325631525,
                -22.053304096378326
              ],
              [
                -43.421785805147714,
                -22.053599081326972
              ],
              [
                -43.42776223029091,
                -22.05419761343487
              ],
              [
                -43.43676866101303,
                -22.058914590393208
              ],
              [
                -43.44263708988862,
                -22.061987595726013
              ],
              [
                -43.462044890051565,
                -22.072150582570327
              ],
              [
                -43.47249377951036,
                -22.06991612736448
              ],
              [
                -43.47551479307042,
                -22.069270126291723
              ],
              [
                -43.48403118076419,
                -22.07135764735731
              ],
              [
                -43.491763638856575,
                -22.073252647413423
              ],
              [
                -43.50710451100028,
                -22.07401067453329
              ],
              [
                -43.50570300046958,
                -22.067761686517418
              ],
              [
                -43.505327522622,
                -22.06609072699981
              ],
              [
                -43.51318247041781,
                -22.058830243590787
              ],
              [
                -43.519410910751986,
                -22.061372267567045
              ],
              [
                -43.52411585703669,
                -22.069497239508454
              ],
              [
                -43.52531285157054,
                -22.07156423811917
              ],
              [
                -43.55547656573071,
                -22.081553233397234
              ],
              [
                -43.56217448160199,
                -22.08730023846648
              ],
              [
                -43.56651394902432,
                -22.087234225837307
              ],
              [
                -43.56823244179378,
                -22.081180776711225
              ],
              [
                -43.5690229350704,
                -22.078394784761436
              ],
              [
                -43.574321382083966,
                -22.077327817612442
              ],
              [
                -43.58410831827428,
                -22.070034362253796
              ],
              [
                -43.58566135389097,
                -22.05153195846362
              ],
              [
                -43.59140131826235,
                -22.05621545944027
              ],
              [
                -43.59552324957196,
                -22.059577457973646
              ],
              [
                -43.59923975636417,
                -22.05851148479421
              ],
              [
                -43.60092522374335,
                -22.063965444598377
              ],
              [
                -43.602739665776845,
                -22.0698354166417
              ],
              [
                -43.60329516968302,
                -22.071632392003387
              ],
              [
                -43.610680099987206,
                -22.071107936433584
              ],
              [
                -43.611932576167575,
                -22.081322899185224
              ],
              [
                -43.619080000794874,
                -22.083100389433607
              ],
              [
                -43.62065851378202,
                -22.075008433186785
              ],
              [
                -43.627900450747305,
                -22.07605292036375
              ],
              [
                -43.631811387104605,
                -22.072857979402016
              ],
              [
                -43.637845358088626,
                -22.06792849357524
              ],
              [
                -43.64395882013451,
                -22.06581551677286
              ],
              [
                -43.65215126458019,
                -22.06661555860289
              ],
              [
                -43.656017184715004,
                -22.0728465380955
              ],
              [
                -43.665181635800266,
                -22.073960550836016
              ],
              [
                -43.66578809678308,
                -22.07626100781075
              ],
              [
                -43.66813456926079,
                -22.085167487003538
              ],
              [
                -43.673224996547304,
                -22.08907697932673
              ],
              [
                -43.67875700474443,
                -22.077059542843223
              ],
              [
                -43.68149000741902,
                -22.071121089667347
              ],
              [
                -43.69142788215989,
                -22.070413111552348
              ],
              [
                -43.699816826766806,
                -22.077406592784797
              ],
              [
                -43.70830322752359,
                -22.076566114648394
              ],
              [
                -43.708905736392886,
                -22.078915631379907
              ],
              [
                -43.70945771127856,
                -22.081068118973505
              ],
              [
                -43.713943664786584,
                -22.080908606217676
              ],
              [
                -43.71951513898202,
                -22.080711129422856
              ],
              [
                -43.7233050878875,
                -22.084858600263058
              ],
              [
                -43.73357695564842,
                -22.09610109904281
              ],
              [
                -43.74077041628787,
                -22.094367590112952
              ],
              [
                -43.746514361566604,
                -22.092277625270338
              ],
              [
                -43.74606390337823,
                -22.08857012972583
              ],
              [
                -43.74596335968084,
                -22.087743647617067
              ],
              [
                -43.748165355880204,
                -22.086616157898305
              ],
              [
                -43.760083302374305,
                -22.08051372295484
              ],
              [
                -43.75742029584885,
                -22.078008738727725
              ],
              [
                -43.75579781341338,
                -22.076483242242816
              ],
              [
                -43.7626432727941,
                -22.073151761438933
              ],
              [
                -43.763438771027964,
                -22.07276475541204
              ],
              [
                -43.76352926352527,
                -22.07232826783385
              ],
              [
                -43.765570259909275,
                -22.06242383076212
              ],
              [
                -43.76834471719931,
                -22.067066320536966
              ],
              [
                -43.77701219030198,
                -22.066281843597984
              ],
              [
                -43.79848294958273,
                -22.08106528827551
              ],
              [
                -43.799840448055576,
                -22.082000824053846
              ],
              [
                -43.80175842124753,
                -22.08205931666225
              ],
              [
                -43.81116683680087,
                -22.082347334266146
              ],
              [
                -43.810788315870155,
                -22.084798309013287
              ],
              [
                -43.81067635069981,
                -22.085525824080612
              ],
              [
                -43.81427378057407,
                -22.08584083569931
              ],
              [
                -43.816337753142314,
                -22.086021819069586
              ],
              [
                -43.81763877345773,
                -22.086837818307064
              ],
              [
                -43.82311770881893,
                -22.090277307085042
              ],
              [
                -43.8429699975699,
                -22.098464844875604
              ],
              [
                -43.85027645699411,
                -22.096509857365994
              ],
              [
                -43.857234914168195,
                -22.094647897500135
              ],
              [
                -43.86190783671555,
                -22.099040884477553
              ],
              [
                -43.87031526654672,
                -22.099483868792422
              ],
              [
                -43.87100726690421,
                -22.103431871812507
              ],
              [
                -43.87951521542914,
                -22.100296386293568
              ],
              [
                -43.879930659094704,
                -22.108339874126745
              ],
              [
                -43.880179149742894,
                -22.113149841710296
              ],
              [
                -43.89057305163057,
                -22.11384436988362
              ],
              [
                -43.89237805763593,
                -22.113964877909222
              ],
              [
                -43.89481253042295,
                -22.116037362792174
              ],
              [
                -43.89724748820822,
                -22.118108829683493
              ],
              [
                -43.94036556370243,
                -22.131653890702633
              ],
              [
                -43.95286546288017,
                -22.14097487331158
              ],
              [
                -43.96062989632729,
                -22.140074399760344
              ],
              [
                -43.97838072663976,
                -22.146162410048284
              ],
              [
                -43.97673823299474,
                -22.149151884922436
              ],
              [
                -43.98501414621176,
                -22.14954189126939
              ],
              [
                -43.987904637793655,
                -22.149677920028562
              ],
              [
                -43.990170098170445,
                -22.153393397345365
              ],
              [
                -43.99288053753402,
                -22.157836870185417
              ],
              [
                -43.997600037490635,
                -22.155545897745977
              ],
              [
                -44.00462345191079,
                -22.161883900264588
              ],
              [
                -44.01753337153738,
                -22.154364456950802
              ],
              [
                -44.02351382814394,
                -22.150880991695104
              ],
              [
                -44.03785116429565,
                -22.15386250978034
              ],
              [
                -44.03905464469642,
                -22.156621500150347
              ],
              [
                -44.04057365722714,
                -22.160100978750474
              ],
              [
                -44.05158855104104,
                -22.16075351389263
              ],
              [
                -44.06596242824155,
                -22.16160451871662
              ],
              [
                -44.07214583528745,
                -22.164465023439593
              ],
              [
                -44.07379131533696,
                -22.168072998748027
              ],
              [
                -44.07665077496918,
                -22.174343985957456
              ],
              [
                -44.08536071802702,
                -22.176917482648765
              ],
              [
                -44.08662118563534,
                -22.183132465028695
              ],
              [
                -44.089322663263175,
                -22.17642051934455
              ],
              [
                -44.08976418371616,
                -22.175322006941517
              ],
              [
                -44.09965408672912,
                -22.17280854095692
              ],
              [
                -44.10448005462587,
                -22.177311551052046
              ],
              [
                -44.10465401896532,
                -22.184081521884796
              ],
              [
                -44.1174609008032,
                -22.189622000470862
              ],
              [
                -44.11887538003856,
                -22.18678402421071
              ],
              [
                -44.12268236720662,
                -22.187771548147893
              ],
              [
                -44.12087333877378,
                -22.194838498405634
              ],
              [
                -44.12487732073265,
                -22.20001999405224
              ],
              [
                -44.12436880068342,
                -22.201174451010743
              ],
              [
                -44.121090312803744,
                -22.20861943160542
              ],
              [
                -44.12598526344414,
                -22.208306448819258
              ],
              [
                -44.12703674171007,
                -22.211540426503024
              ],
              [
                -44.13318369137481,
                -22.209606426996086
              ],
              [
                -44.14065811547901,
                -22.219764910986292
              ],
              [
                -44.145887079015864,
                -22.215509931609223
              ],
              [
                -44.14876406815696,
                -22.213168482660283
              ],
              [
                -44.150545542974484,
                -22.21845793806153
              ],
              [
                -44.15323898982245,
                -22.22645390442445
              ],
              [
                -44.158208946874694,
                -22.224787922549904
              ],
              [
                -44.16371090468439,
                -22.232038430899166
              ],
              [
                -44.16660234962647,
                -22.2317329229049
              ],
              [
                -44.16648938412262,
                -22.2285484243795
              ],
              [
                -44.16643686597039,
                -22.227078432522294
              ],
              [
                -44.171827323497105,
                -22.229394426023394
              ],
              [
                -44.17314982537868,
                -22.22996244863875
              ],
              [
                -44.17982526690862,
                -22.22756449181056
              ],
              [
                -44.180598254075754,
                -22.23263344872397
              ],
              [
                -44.18878863150734,
                -22.23993743381892
              ],
              [
                -44.18910215396256,
                -22.2402174202469
              ],
              [
                -44.20126848944461,
                -22.251066413156728
              ],
              [
                -44.20342500899314,
                -22.248340404232287
              ],
              [
                -44.20810093497263,
                -22.250562923380752
              ],
              [
                -44.20903796469116,
                -22.24962595144513
              ],
              [
                -44.21289790993519,
                -22.245766459544587
              ],
              [
                -44.22401128987105,
                -22.25249194415525
              ],
              [
                -44.22396880573505,
                -22.257663904489235
              ],
              [
                -44.22395928033367,
                -22.258825889723447
              ],
              [
                -44.23504616902639,
                -22.265704398553513
              ],
              [
                -44.23635517523,
                -22.264537898716075
              ],
              [
                -44.23871215318958,
                -22.262438437209497
              ],
              [
                -44.246297565536864,
                -22.266505912841236
              ],
              [
                -44.250371544326576,
                -22.260193950594868
              ],
              [
                -44.25764396747627,
                -22.265896976346507
              ],
              [
                -44.2601589608603,
                -22.26786947215089
              ],
              [
                -44.260827969458774,
                -22.26308199951956
              ],
              [
                -44.26107345361695,
                -22.261323975835484
              ],
              [
                -44.26197398904527,
                -22.253259021990726
              ],
              [
                -44.26322397603308,
                -22.248001076200755
              ],
              [
                -44.2638189746547,
                -22.243747596751245
              ],
              [
                -44.26687544004504,
                -22.247833079518788
              ],
              [
                -44.268371440681996,
                -22.24431859775965
              ],
              [
                -44.28408431296641,
                -22.24306061780316
              ],
              [
                -44.28901827773007,
                -22.246960615378786
              ],
              [
                -44.291077758170914,
                -22.24256164244336
              ],
              [
                -44.299387675090664,
                -22.251042147201797
              ],
              [
                -44.30135413111389,
                -22.253049608413107
              ],
              [
                -44.31430554262692,
                -22.254000662414708
              ],
              [
                -44.32157095200093,
                -22.26066312347312
              ],
              [
                -44.324088435014566,
                -22.25443516872852
              ],
              [
                -44.324298934765444,
                -22.253914156845028
              ],
              [
                -44.32700093071593,
                -22.2548351853362
              ],
              [
                -44.33680880915997,
                -22.258179693381326
              ],
              [
                -44.33811982673248,
                -22.256559676778433
              ],
              [
                -44.34073681790129,
                -22.25332620552721
              ],
              [
                -44.3519051968947,
                -22.25725220126015
              ],
              [
                -44.36052014568366,
                -22.252597263627894
              ],
              [
                -44.3752385079186,
                -22.261582242718678
              ],
              [
                -44.37578599060028,
                -22.26344524697327
              ],
              [
                -44.37698747796275,
                -22.26753071399295
              ],
              [
                -44.38364090718202,
                -22.267028767807776
              ],
              [
                -44.38715385996402,
                -22.272002728489486
              ],
              [
                -44.39139535051985,
                -22.264004264908802
              ],
              [
                -44.39161832886551,
                -22.263583782559447
              ],
              [
                -44.3943643398082,
                -22.2584048342065
              ],
              [
                -44.41401969193309,
                -22.253652382831937
              ],
              [
                -44.42345909516131,
                -22.255162898013
              ],
              [
                -44.43228103738191,
                -22.251134938831747
              ],
              [
                -44.43798746717248,
                -22.257817924849448
              ],
              [
                -44.44001946023437,
                -22.25431645036787
              ],
              [
                -44.44431341179805,
                -22.257471427057215
              ],
              [
                -44.44624040723862,
                -22.2551414315403
              ],
              [
                -44.45601532332564,
                -22.25674848214585
              ],
              [
                -44.462185240787825,
                -22.266199447807402
              ],
              [
                -44.458702239297246,
                -22.269996898055687
              ],
              [
                -44.46223019940165,
                -22.273828883444573
              ],
              [
                -44.4628637008771,
                -22.274517396717297
              ],
              [
                -44.46071069727399,
                -22.279862853838694
              ],
              [
                -44.4675726595547,
                -22.279100891508225
              ],
              [
                -44.4939953368174,
                -22.300472830709666
              ],
              [
                -44.500625291455044,
                -22.303238864494794
              ],
              [
                -44.51323967210114,
                -22.308501356718683
              ],
              [
                -44.516397652862736,
                -22.31415183929836
              ],
              [
                -44.51733110878595,
                -22.315821804560954
              ],
              [
                -44.530124482854845,
                -22.323409327916227
              ],
              [
                -44.53277895928327,
                -22.329932779933245
              ],
              [
                -44.54183284893386,
                -22.331100314958583
              ],
              [
                -44.54307986173104,
                -22.331260828443213
              ],
              [
                -44.545431369083445,
                -22.32315387829191
              ],
              [
                -44.558155244556296,
                -22.32916136225748
              ],
              [
                -44.56391215185579,
                -22.331880352057375
              ],
              [
                -44.567898628180075,
                -22.327403388418062
              ],
              [
                -44.571239129855165,
                -22.32365192753465
              ],
              [
                -44.579707545177506,
                -22.326931927707417
              ],
              [
                -44.58060502441436,
                -22.327279936360355
              ],
              [
                -44.58071904875195,
                -22.327175412170796
              ],
              [
                -44.58729501062222,
                -22.32112395802286
              ],
              [
                -44.61683873117369,
                -22.33127799493876
              ],
              [
                -44.625736636682106,
                -22.33559897620313
              ],
              [
                -44.62699110259502,
                -22.341302966640736
              ],
              [
                -44.627908574039175,
                -22.34547796266472
              ],
              [
                -44.633480509178455,
                -22.348451954792868
              ],
              [
                -44.642466454478345,
                -22.353248464012147
              ],
              [
                -44.64469338939279,
                -22.360803890004764
              ],
              [
                -44.64582536099746,
                -22.36464390525053
              ],
              [
                -44.65344228598932,
                -22.370440857027397
              ],
              [
                -44.65453578807121,
                -22.371273400846754
              ],
              [
                -44.65492227085983,
                -22.37156735443105
              ],
              [
                -44.658436247565945,
                -22.37424088364909
              ],
              [
                -44.659962191774504,
                -22.37906736314033
              ],
              [
                -44.66605070394555,
                -22.37269140925559
              ],
              [
                -44.67832506651209,
                -22.373717904583856
              ],
              [
                -44.68475903359562,
                -22.37165744257432
              ],
              [
                -44.69119394967722,
                -22.3695969885671
              ],
              [
                -44.69367445503063,
                -22.370047491907734
              ],
              [
                -44.70544386858361,
                -22.372185994506463
              ],
              [
                -44.71142829728656,
                -22.368242035487057
              ],
              [
                -44.714037793614985,
                -22.366370535478964
              ],
              [
                -44.71510628178787,
                -22.36560456902532
              ],
              [
                -44.71766326713738,
                -22.364202580591964
              ],
              [
                -44.72354423618776,
                -22.359454086215926
              ],
              [
                -44.731784664641275,
                -22.36270812608942
              ],
              [
                -44.734345137364805,
                -22.36478560072536
              ],
              [
                -44.74076752922579,
                -22.37203358838353
              ],
              [
                -44.74401452222391,
                -22.375697593120073
              ],
              [
                -44.75128443076554,
                -22.375421112645373
              ],
              [
                -44.75432343507473,
                -22.375306102223455
              ],
              [
                -44.75962435375716,
                -22.376583123000415
              ],
              [
                -44.76795530787961,
                -22.380351088667023
              ],
              [
                -44.77556523417839,
                -22.383792591029234
              ],
              [
                -44.777477696775264,
                -22.383144122231435
              ],
              [
                -44.780049678407245,
                -22.382271607264272
              ],
              [
                -44.78362162064175,
                -22.384946611697284
              ],
              [
                -44.78439711722469,
                -22.385527607922242
              ],
              [
                -44.787150606341186,
                -22.3875896161762
              ],
              [
                -44.793279569906105,
                -22.38658814323365
              ],
              [
                -44.803581439095844,
                -22.39332414573931
              ],
              [
                -44.80879685742615,
                -22.40477210494952
              ],
              [
                -44.80838238100129,
                -22.4077055686153
              ],
              [
                -44.80777037499938,
                -22.412033531992147
              ],
              [
                -44.807660356556006,
                -22.41281305891816
              ],
              [
                -44.80755387617267,
                -22.413563035997058
              ],
              [
                -44.82374920513028,
                -22.418905064941107
              ],
              [
                -44.832911076526024,
                -22.430380530561614
              ],
              [
                -44.844209982296505,
                -22.42728006052081
              ],
              [
                -44.85398092898653,
                -22.427499562344934
              ],
              [
                -44.85552639807835,
                -22.42609058126698
              ],
              [
                -44.859393906783836,
                -22.422565625594245
              ],
              [
                -44.87104729540345,
                -22.42375865469401
              ],
              [
                -44.87485073665254,
                -22.428065127916888
              ],
              [
                -44.889294111166265,
                -22.431622654988164
              ],
              [
                -44.89854448745597,
                -22.452326078355245
              ],
              [
                -44.93963161255427,
                -22.452169190239125
              ],
              [
                -44.95852543731736,
                -22.465402664545664
              ],
              [
                -44.96543335467345,
                -22.47468809910208
              ],
              [
                -44.99137562476281,
                -22.465671246644035
              ],
              [
                -45.000577573017175,
                -22.467986231969864
              ],
              [
                -45.005832020842156,
                -22.466458263326636
              ],
              [
                -45.009387472268735,
                -22.46587575760105
              ],
              [
                -45.01367546470618,
                -22.466829293419654
              ],
              [
                -45.02670235353032,
                -22.469724799153305
              ],
              [
                -45.025957839058016,
                -22.467723786815004
              ],
              [
                -45.02470985705494,
                -22.464369832658967
              ],
              [
                -45.027964349419484,
                -22.46269785238548
              ],
              [
                -45.04010421104231,
                -22.468771320449502
              ],
              [
                -45.060247027975876,
                -22.47205285432153
              ],
              [
                -45.082180796187515,
                -22.485850343406636
              ],
              [
                -45.08767574779847,
                -22.483747884184094
              ],
              [
                -45.09068072571395,
                -22.482597878734968
              ],
              [
                -45.091938741192074,
                -22.48317687930672
              ],
              [
                -45.102935644729705,
                -22.488239883308974
              ],
              [
                -45.12123145448154,
                -22.494770378777844
              ],
              [
                -45.12801688992524,
                -22.496609432070137
              ],
              [
                -45.14337220549651,
                -22.506136914355537
              ],
              [
                -45.1496916994547,
                -22.506835399901075
              ],
              [
                -45.159567566564824,
                -22.516418895327586
              ],
              [
                -45.16380248475995,
                -22.526642339622494
              ],
              [
                -45.16547246601138,
                -22.530675342753934
              ],
              [
                -45.164074469967176,
                -22.537429786624937
              ],
              [
                -45.177603327530576,
                -22.54937827661214
              ],
              [
                -45.20329655548608,
                -22.556798812975973
              ],
              [
                -45.211909475766696,
                -22.56236981042408
              ],
              [
                -45.2170809255628,
                -22.562736794548183
              ],
              [
                -45.22265187887146,
                -22.563131826612487
              ],
              [
                -45.226189848155634,
                -22.566208331395035
              ],
              [
                -45.25942851070579,
                -22.582369800451545
              ],
              [
                -45.25805550781436,
                -22.58608478001508
              ],
              [
                -45.25668303491836,
                -22.589799292583297
              ],
              [
                -45.258119521664035,
                -22.59269125563574
              ],
              [
                -45.263772920002936,
                -22.604071704513675
              ],
              [
                -45.26099444566282,
                -22.606635183800485
              ],
              [
                -45.26573388476779,
                -22.610007687462673
              ],
              [
                -45.274161800242716,
                -22.615690715041666
              ],
              [
                -45.293365666601986,
                -22.61317323577332
              ],
              [
                -45.332517291998506,
                -22.61713983338726
              ],
              [
                -45.33466677557559,
                -22.621018304710844
              ],
              [
                -45.33535075989305,
                -22.622252800676442
              ],
              [
                -45.34346369661515,
                -22.624571323520733
              ],
              [
                -45.348506652202936,
                -22.626012834264227
              ],
              [
                -45.35213162478922,
                -22.629710336447264
              ],
              [
                -45.36051452469421,
                -22.638261799388275
              ],
              [
                -45.365334469967905,
                -22.637537821847495
              ],
              [
                -45.370154929239064,
                -22.63681434030626
              ],
              [
                -45.39489269807647,
                -22.651422311708085
              ],
              [
                -45.39954561472902,
                -22.65262983255163
              ],
              [
                -45.401848124157816,
                -22.652310308295398
              ],
              [
                -45.4100475650258,
                -22.65117386686604
              ],
              [
                -45.40835212549367,
                -22.63298842868089
              ],
              [
                -45.41265509558958,
                -22.627992484050957
              ],
              [
                -45.41248662470555,
                -22.62171298444322
              ],
              [
                -45.42044204938304,
                -22.62034854541841
              ],
              [
                -45.435107449685965,
                -22.61783008100749
              ],
              [
                -45.43704841684766,
                -22.611242117557396
              ],
              [
                -45.43859891795752,
                -22.61019562271903
              ],
              [
                -45.44760537051978,
                -22.60411516694279
              ],
              [
                -45.44864084330092,
                -22.604570163577403
              ],
              [
                -45.45136980936842,
                -22.60576965724919
              ],
              [
                -45.471343719126075,
                -22.590022792023053
              ],
              [
                -45.473862184190494,
                -22.590201296976534
              ],
              [
                -45.48084109440758,
                -22.590696338474853
              ],
              [
                -45.47686666065271,
                -22.59563927825737
              ],
              [
                -45.4745471582984,
                -22.598524746284337
              ],
              [
                -45.4883569397249,
                -22.62828464035114
              ],
              [
                -45.49416291166068,
                -22.626942171430336
              ],
              [
                -45.49996837560433,
                -22.625600199506138
              ],
              [
                -45.51253572150732,
                -22.63624818903841
              ],
              [
                -45.52448557627585,
                -22.646374139425884
              ],
              [
                -45.53230004433969,
                -22.64625917114801
              ],
              [
                -45.532600037807306,
                -22.64625517198002
              ],
              [
                -45.533056528715626,
                -22.646642680381877
              ],
              [
                -45.53946145827511,
                -22.652079179999745
              ],
              [
                -45.54965584873341,
                -22.652101713535643
              ],
              [
                -45.55113684248037,
                -22.652639216007667
              ],
              [
                -45.5730726333999,
                -22.656470229386464
              ],
              [
                -45.5737771577936,
                -22.656036255351054
              ],
              [
                -45.579995115143525,
                -22.65220575834245
              ],
              [
                -45.58140507434035,
                -22.651840781894688
              ],
              [
                -45.58392508003166,
                -22.6511887868166
              ],
              [
                -45.58154811428496,
                -22.64416982357099
              ],
              [
                -45.57688214388047,
                -22.640026837519233
              ],
              [
                -45.57629866354195,
                -22.64011732550867
              ],
              [
                -45.57238570781311,
                -22.640721321039102
              ],
              [
                -45.55491039847097,
                -22.62594034665851
              ],
              [
                -45.563212341112674,
                -22.61224889800764
              ],
              [
                -45.568046333893996,
                -22.610415936782697
              ],
              [
                -45.57227478308882,
                -22.608812961828033
              ],
              [
                -45.576270764083645,
                -22.60185301062327
              ],
              [
                -45.58750866359438,
                -22.6098914909817
              ],
              [
                -45.584331187439496,
                -22.615007951144584
              ],
              [
                -45.5895466220823,
                -22.618557430471835
              ],
              [
                -45.60463147893408,
                -22.619934488843157
              ],
              [
                -45.61115692814559,
                -22.628675454183703
              ],
              [
                -45.617681812350106,
                -22.63741543955397
              ],
              [
                -45.647246053429704,
                -22.64773245506992
              ],
              [
                -45.66455938818514,
                -22.65015748266054
              ],
              [
                -45.66932686132153,
                -22.648098023339234
              ],
              [
                -45.680630777315685,
                -22.63583557894525
              ],
              [
                -45.67397388642726,
                -22.630198118482443
              ],
              [
                -45.67509238112907,
                -22.62570214575467
              ],
              [
                -45.66565898949872,
                -22.612198170952823
              ],
              [
                -45.6676869919385,
                -22.609147200875906
              ],
              [
                -45.66388153815688,
                -22.59960922864482
              ],
              [
                -45.66346802013149,
                -22.599458239237407
              ],
              [
                -45.658419094673924,
                -22.59761073228339
              ],
              [
                -45.6626270919407,
                -22.586198800669415
              ],
              [
                -45.6604501106084,
                -22.578483332274622
              ],
              [
                -45.67929697361936,
                -22.572921904653683
              ],
              [
                -45.68753939470372,
                -22.5790288951079
              ],
              [
                -45.69679430486198,
                -22.585885845783555
              ],
              [
                -45.70103574926845,
                -22.58420740022028
              ],
              [
                -45.71646713798617,
                -22.578100445935377
              ],
              [
                -45.71674762659812,
                -22.58160295214525
              ],
              [
                -45.726596544304975,
                -22.588602445802007
              ],
              [
                -45.72714550722056,
                -22.593825892623812
              ],
              [
                -45.734152476145944,
                -22.596949906897127
              ],
              [
                -45.73659290736767,
                -22.60651937135664
              ],
              [
                -45.73698841414254,
                -22.60806836312131
              ],
              [
                -45.729353970981286,
                -22.612734814359072
              ],
              [
                -45.72868044438472,
                -22.61790279714252
              ],
              [
                -45.71713001388321,
                -22.62049676251664
              ],
              [
                -45.708269559571455,
                -22.638968157278196
              ],
              [
                -45.70229910892473,
                -22.64091964283988
              ],
              [
                -45.69921564083297,
                -22.641927611701487
              ],
              [
                -45.69752409799885,
                -22.649262066500388
              ],
              [
                -45.693546145516265,
                -22.65120856750073
              ],
              [
                -45.71627740125011,
                -22.661319572663768
              ],
              [
                -45.72678032606979,
                -22.657944116158692
              ],
              [
                -45.73178381382978,
                -22.656336610352913
              ],
              [
                -45.74235970102574,
                -22.65866665116593
              ],
              [
                -45.747426663949604,
                -22.666329601872956
              ],
              [
                -45.74834613897417,
                -22.66860609465804
              ],
              [
                -45.74878260586391,
                -22.66968710475711
              ],
              [
                -45.750787612006384,
                -22.669972595875425
              ],
              [
                -45.755044585090424,
                -22.670579615616305
              ],
              [
                -45.758313030044405,
                -22.674185611549202
              ],
              [
                -45.76062999036302,
                -22.676742096832502
              ],
              [
                -45.76678795937272,
                -22.678930105323786
              ],
              [
                -45.77843431681371,
                -22.68306811161677
              ],
              [
                -45.7829217773771,
                -22.68796208984416
              ],
              [
                -45.787408213947934,
                -22.692855087083032
              ],
              [
                -45.79137669455085,
                -22.693755592681043
              ],
              [
                -45.81120053776066,
                -22.698253118670312
              ],
              [
                -45.81531196724721,
                -22.710156056005918
              ],
              [
                -45.819423360704874,
                -22.72205901837449
              ],
              [
                -45.81347038659581,
                -22.728169495424776
              ],
              [
                -45.805383465563864,
                -22.736470449360343
              ],
              [
                -45.79869002386213,
                -22.73781788873253
              ],
              [
                -45.796378517923664,
                -22.738283413226718
              ],
              [
                -45.75665789721447,
                -22.72083637262319
              ],
              [
                -45.72783013470114,
                -22.723170277953063
              ],
              [
                -45.72794762216047,
                -22.725864789602543
              ],
              [
                -45.7280651286199,
                -22.728558788255622
              ],
              [
                -45.72065065910743,
                -22.741246683359467
              ],
              [
                -45.718132151747696,
                -22.745555164228048
              ],
              [
                -45.71802018877429,
                -22.746790145117274
              ],
              [
                -45.71730512373509,
                -22.754691125030487
              ],
              [
                -45.71349669078803,
                -22.756324597948396
              ],
              [
                -45.712956161627346,
                -22.7565565833813
              ],
              [
                -45.71287667888525,
                -22.757631599112194
              ],
              [
                -45.71221114584417,
                -22.766656040890098
              ],
              [
                -45.712070634970786,
                -22.76856354054467
              ],
              [
                -45.71635356199084,
                -22.779481021979013
              ],
              [
                -45.721397542756854,
                -22.779018003949275
              ],
              [
                -45.722047040447194,
                -22.778958507005218
              ],
              [
                -45.72877895945455,
                -22.78681450554608
              ],
              [
                -45.7290174606127,
                -22.7892719736659
              ],
              [
                -45.72949542487735,
                -22.79419947685241
              ],
              [
                -45.72953190616719,
                -22.798407441209836
              ],
              [
                -45.721423471559504,
                -22.804141378108557
              ],
              [
                -45.71983896540463,
                -22.80777389972863
              ],
              [
                -45.7138170175768,
                -22.808661341075094
              ],
              [
                -45.71313500343411,
                -22.814284819826327
              ],
              [
                -45.72232741669962,
                -22.815816335822834
              ],
              [
                -45.72608691693977,
                -22.812341359417985
              ],
              [
                -45.72833489935371,
                -22.809690396008065
              ],
              [
                -45.741251802231396,
                -22.807418932043387
              ],
              [
                -45.741787321492346,
                -22.801835466691603
              ],
              [
                -45.742457826089094,
                -22.794844480315227
              ],
              [
                -45.74620330079853,
                -22.79408700212408
              ],
              [
                -45.754468224175405,
                -22.792414520600584
              ],
              [
                -45.75857017660287,
                -22.80052949478854
              ],
              [
                -45.77049455208951,
                -22.805424525523183
              ],
              [
                -45.76715649876238,
                -22.835997857175858
              ],
              [
                -45.76566649439949,
                -22.841937338283124
              ],
              [
                -45.76814846721264,
                -22.843870833046353
              ],
              [
                -45.77044491205594,
                -22.845660320976034
              ],
              [
                -45.77522489869276,
                -22.849384799673622
              ],
              [
                -45.79082471828289,
                -22.85763582993577
              ],
              [
                -45.79551018201548,
                -22.852203373210564
              ],
              [
                -45.800194681755436,
                -22.84677140448032
              ],
              [
                -45.80239068007879,
                -22.83335246827194
              ],
              [
                -45.8093531324651,
                -22.828884499288964
              ],
              [
                -45.81465960321227,
                -22.83306849829127
              ],
              [
                -45.815314072027654,
                -22.833584489675737
              ],
              [
                -45.81728009286575,
                -22.832500493144426
              ],
              [
                -45.82293551500517,
                -22.829383046254478
              ],
              [
                -45.829056965246295,
                -22.834386526675708
              ],
              [
                -45.838528380235466,
                -22.832488559569367
              ],
              [
                -45.84767732240738,
                -22.837329556089344
              ],
              [
                -45.849647254172446,
                -22.845323522161593
              ],
              [
                -45.84997927405226,
                -22.846671005780976
              ],
              [
                -45.85163371896585,
                -22.847639500807333
              ],
              [
                -45.85682817632373,
                -22.850679035900075
              ],
              [
                -45.85696166991689,
                -22.854824981910735
              ],
              [
                -45.85713368122412,
                -22.86020599226511
              ],
              [
                -45.863411084479175,
                -22.868218434150847
              ],
              [
                -45.86860805212405,
                -22.87053446065431
              ],
              [
                -45.87014353395029,
                -22.8712189596894
              ],
              [
                -45.875442953586514,
                -22.87357993627187
              ],
              [
                -45.87840143435424,
                -22.87489797527416
              ],
              [
                -45.888464841966695,
                -22.875316487045477
              ],
              [
                -45.889510884157815,
                -22.858158554934914
              ],
              [
                -45.89364737116414,
                -22.856613101531746
              ],
              [
                -45.89185539751863,
                -22.84936963038741
              ],
              [
                -45.890568934044964,
                -22.844170136106822
              ],
              [
                -45.89355592433952,
                -22.83869768086247
              ],
              [
                -45.90549232592718,
                -22.833262201066315
              ],
              [
                -45.90759932537321,
                -22.83006423878555
              ],
              [
                -45.9038823748928,
                -22.81877576325182
              ],
              [
                -45.909432833393424,
                -22.816784806807366
              ],
              [
                -45.922134705486506,
                -22.823553293152532
              ],
              [
                -45.924541159291785,
                -22.831634264076214
              ],
              [
                -45.92831364571421,
                -22.83560728693024
              ],
              [
                -45.93701800985044,
                -22.844773745162584
              ],
              [
                -45.943733991123885,
                -22.845096259157934
              ],
              [
                -45.94828894597612,
                -22.84531478268725
              ],
              [
                -45.949966898554,
                -22.845395773932463
              ],
              [
                -45.95468585655191,
                -22.85106124854172
              ],
              [
                -45.9559428270998,
                -22.852570740975025
              ],
              [
                -45.9597447973615,
                -22.85713574519569
              ],
              [
                -45.96187028245699,
                -22.856190752455475
              ],
              [
                -45.964387241807955,
                -22.85507177660456
              ],
              [
                -45.97671014759669,
                -22.86510776187223
              ],
              [
                -45.991903980601194,
                -22.86909328425432
              ],
              [
                -45.99180096133476,
                -22.87317974996504
              ],
              [
                -45.99170497583553,
                -22.877008226897168
              ],
              [
                -46.001204385377505,
                -22.879284760542422
              ],
              [
                -46.0079912987581,
                -22.88872373842343
              ],
              [
                -46.02688066010751,
                -22.8841703037715
              ],
              [
                -46.05082289771379,
                -22.895824802859046
              ],
              [
                -46.074939734732176,
                -22.890464374443916
              ],
              [
                -46.08485313562081,
                -22.892196891840133
              ],
              [
                -46.09476704951411,
                -22.89392989624507
              ],
              [
                -46.103675445905594,
                -22.901236386048545
              ],
              [
                -46.108218382617984,
                -22.904961905371444
              ],
              [
                -46.11568834710917,
                -22.907004901603713
              ],
              [
                -46.123158267607124,
                -22.909047420845134
              ],
              [
                -46.13863260262678,
                -22.922257408609774
              ],
              [
                -46.15354502445686,
                -22.911933960728557
              ],
              [
                -46.14725807719078,
                -22.907348977509674
              ],
              [
                -46.14602457794409,
                -22.905376972216104
              ],
              [
                -46.13643518717987,
                -22.89004002964907
              ],
              [
                -46.141657702141664,
                -22.88045509150853
              ],
              [
                -46.14418973685099,
                -22.857802180485425
              ],
              [
                -46.15488361594564,
                -22.863674722863227
              ],
              [
                -46.16354156646167,
                -22.86511472015909
              ],
              [
                -46.18504786102677,
                -22.868691770152264
              ],
              [
                -46.19174432350079,
                -22.86472730505277
              ],
              [
                -46.19937721786401,
                -22.870447288727455
              ],
              [
                -46.23368942830401,
                -22.88132332056445
              ],
              [
                -46.23771682859397,
                -22.89365528776088
              ],
              [
                -46.24631529835644,
                -22.889627800230873
              ],
              [
                -46.25807567509532,
                -22.890074846813317
              ],
              [
                -46.25970966994016,
                -22.885259360586193
              ],
              [
                -46.26218269973742,
                -22.877967894125277
              ],
              [
                -46.26641315718709,
                -22.875187915705514
              ],
              [
                -46.278573556454674,
                -22.877847937173826
              ],
              [
                -46.28852194418922,
                -22.889509412976274
              ],
              [
                -46.29499985421444,
                -22.897102422947388
              ],
              [
                -46.30508177424106,
                -22.89195142872237
              ],
              [
                -46.31516373228086,
                -22.88680099549228
              ],
              [
                -46.3355805298207,
                -22.895480510242184
              ],
              [
                -46.34200843316081,
                -22.901565990086144
              ],
              [
                -46.34477142349609,
                -22.904182005724795
              ],
              [
                -46.346993879121335,
                -22.902864008978803
              ],
              [
                -46.34798240273033,
                -22.902277014432713
              ],
              [
                -46.3591783093357,
                -22.895635068172016
              ],
              [
                -46.372508746595926,
                -22.8789511650924
              ],
              [
                -46.375331247524024,
                -22.877223695898767
              ],
              [
                -46.377409222251195,
                -22.876559706736064
              ],
              [
                -46.37560873028769,
                -22.871946716978016
              ],
              [
                -46.37504825263524,
                -22.870511231032786
              ],
              [
                -46.37663972890982,
                -22.87032024133744
              ],
              [
                -46.38373467818333,
                -22.869468740987283
              ],
              [
                -46.38038472944372,
                -22.86354576995973
              ],
              [
                -46.37639425449179,
                -22.86150878825405
              ],
              [
                -46.37215233406442,
                -22.859343254445477
              ],
              [
                -46.373885837086874,
                -22.843402842747878
              ],
              [
                -46.370243420164854,
                -22.834571385365763
              ],
              [
                -46.375245390367326,
                -22.828649397568274
              ],
              [
                -46.376357883294915,
                -22.82733292472917
              ],
              [
                -46.37362041888278,
                -22.8178434609191
              ],
              [
                -46.3612416244567,
                -22.78844356536938
              ],
              [
                -46.36017211082732,
                -22.788007067423738
              ],
              [
                -46.35188021523362,
                -22.78462257810049
              ],
              [
                -46.34759925714737,
                -22.777160580746656
              ],
              [
                -46.342986308167966,
                -22.776741071918043
              ],
              [
                -46.34119036664515,
                -22.76884160448577
              ],
              [
                -46.332124909090645,
                -22.765594585430215
              ],
              [
                -46.33476343192852,
                -22.75969913501085
              ],
              [
                -46.337779396177424,
                -22.759222664556603
              ],
              [
                -46.3544332670486,
                -22.756592204790007
              ],
              [
                -46.36000323076354,
                -22.760822705630403
              ],
              [
                -46.372681660625,
                -22.746180796429083
              ],
              [
                -46.37588963494569,
                -22.74549828845918
              ],
              [
                -46.38106959790083,
                -22.743524817915056
              ],
              [
                -46.38534807001083,
                -22.739809359933894
              ],
              [
                -46.392228994219934,
                -22.742970367349265
              ],
              [
                -46.392288502634855,
                -22.742997349389142
              ],
              [
                -46.406150363400805,
                -22.74024890944881
              ],
              [
                -46.41310131832876,
                -22.736790945666797
              ],
              [
                -46.422609256953855,
                -22.732060475840317
              ],
              [
                -46.42363073963847,
                -22.73354246281702
              ],
              [
                -46.42411523956056,
                -22.73386049868782
              ],
              [
                -46.42636270499967,
                -22.735337489081406
              ],
              [
                -46.436297153411466,
                -22.73143553560566
              ],
              [
                -46.43678766735277,
                -22.73048854333897
              ],
              [
                -46.4389971215697,
                -22.72622705614521
              ],
              [
                -46.44602507736285,
                -22.726664083574168
              ],
              [
                -46.45227751580435,
                -22.72705308208177
              ],
              [
                -46.45344501303469,
                -22.722701113418022
              ],
              [
                -46.45772855650954,
                -22.706741179018874
              ],
              [
                -46.468185479111455,
                -22.700812257341912
              ],
              [
                -46.472311432102735,
                -22.704705236764728
              ],
              [
                -46.47530391488748,
                -22.701342772579167
              ],
              [
                -46.47747891455931,
                -22.698897756893
              ],
              [
                -46.47401494909707,
                -22.696539275211595
              ],
              [
                -46.469919471293984,
                -22.693751287772653
              ],
              [
                -46.47254698393564,
                -22.691070280430242
              ],
              [
                -46.47811392763681,
                -22.6853893620813
              ],
              [
                -46.48018195264928,
                -22.677819381794603
              ],
              [
                -46.469271042457486,
                -22.672957368131545
              ],
              [
                -46.46355008371175,
                -22.674695354287966
              ],
              [
                -46.460852116640744,
                -22.675514866043923
              ],
              [
                -46.45695816217134,
                -22.67240583567275
              ],
              [
                -46.45470716675914,
                -22.67060783677621
              ],
              [
                -46.44793174670136,
                -22.669271340253058
              ],
              [
                -46.43971430462379,
                -22.66764934207581
              ],
              [
                -46.43345237691759,
                -22.664241835570785
              ],
              [
                -46.42718992421629,
                -22.66083434307301
              ],
              [
                -46.420678989834684,
                -22.662972301206064
              ],
              [
                -46.418512500682304,
                -22.66368328793531
              ],
              [
                -46.41715252946161,
                -22.662621312103603
              ],
              [
                -46.41296353198064,
                -22.659351297700887
              ],
              [
                -46.407447610749415,
                -22.661657269801566
              ],
              [
                -46.40538461825188,
                -22.66251926711695
              ],
              [
                -46.40792006490032,
                -22.668806221977892
              ],
              [
                -46.40541208546171,
                -22.672060702979945
              ],
              [
                -46.392603218354154,
                -22.662476247069637
              ],
              [
                -46.392503713710276,
                -22.661063217346193
              ],
              [
                -46.39191026560502,
                -22.65264879572647
              ],
              [
                -46.395863736443175,
                -22.64548282785597
              ],
              [
                -46.40559517129183,
                -22.640081389722347
              ],
              [
                -46.40800565546334,
                -22.63966287230527
              ],
              [
                -46.420212079134956,
                -22.63754240077518
              ],
              [
                -46.424165055284625,
                -22.63309845927331
              ],
              [
                -46.42255057575963,
                -22.6243489684005
              ],
              [
                -46.41393816175869,
                -22.622481968341127
              ],
              [
                -46.404303736024,
                -22.620392932506533
              ],
              [
                -46.41708220088206,
                -22.60481704497179
              ],
              [
                -46.422573147330155,
                -22.59812358615166
              ],
              [
                -46.419052687743886,
                -22.593743599800895
              ],
              [
                -46.414935746513,
                -22.588620144269658
              ],
              [
                -46.41219078935865,
                -22.585204646585968
              ],
              [
                -46.41957671320254,
                -22.584388171686722
              ],
              [
                -46.428243145802035,
                -22.58342968496997
              ],
              [
                -46.433299109591516,
                -22.573376268560715
              ],
              [
                -46.41620882013065,
                -22.55841179713244
              ],
              [
                -46.410961857624805,
                -22.553816765087216
              ],
              [
                -46.40668845515694,
                -22.540047843399986
              ],
              [
                -46.41123991032885,
                -22.5362043567706
              ],
              [
                -46.415976360813794,
                -22.544354358857845
              ],
              [
                -46.41749036386811,
                -22.54695981489724
              ],
              [
                -46.42585229060688,
                -22.547074861320485
              ],
              [
                -46.438914696643884,
                -22.540098398620042
              ],
              [
                -46.43898771484544,
                -22.537328943695726
              ],
              [
                -46.43906969797769,
                -22.534242938268957
              ],
              [
                -46.448741629594416,
                -22.5303369849789
              ],
              [
                -46.454056127034406,
                -22.52189905579731
              ],
              [
                -46.4623970454319,
                -22.522193564320347
              ],
              [
                -46.464486519747645,
                -22.52226707571399
              ],
              [
                -46.466403513197676,
                -22.518034585651247
              ],
              [
                -46.48410937908314,
                -22.518749632931197
              ],
              [
                -46.490723850041526,
                -22.509890169259606
              ],
              [
                -46.496751794673045,
                -22.512737203577935
              ],
              [
                -46.504828183130456,
                -22.51655219603283
              ],
              [
                -46.50653269501318,
                -22.50645825060312
              ],
              [
                -46.54169148378219,
                -22.49385788665828
              ],
              [
                -46.535607036492955,
                -22.487261920591767
              ],
              [
                -46.53357104974604,
                -22.480881914646865
              ],
              [
                -46.53021213719438,
                -22.470353969363522
              ],
              [
                -46.54249852055061,
                -22.46726501341674
              ],
              [
                -46.54875645761985,
                -22.468448024083642
              ],
              [
                -46.54910347852038,
                -22.46851302373354
              ],
              [
                -46.549183978002,
                -22.468150028641077
              ],
              [
                -46.55110098252854,
                -22.459486054162856
              ],
              [
                -46.54901897811861,
                -22.45815205665439
              ],
              [
                -46.54811354233578,
                -22.454790083800333
              ],
              [
                -46.554357481679844,
                -22.446156660053997
              ],
              [
                -46.56437442387002,
                -22.446890166101564
              ],
              [
                -46.57184130821857,
                -22.452382168052015
              ],
              [
                -46.572196325430305,
                -22.452643644810685
              ],
              [
                -46.57232784624042,
                -22.45204315696151
              ],
              [
                -46.573898832902906,
                -22.444873688585204
              ],
              [
                -46.57559883317102,
                -22.444422228344198
              ],
              [
                -46.581455768280726,
                -22.440669750840197
              ],
              [
                -46.59127769014293,
                -22.43761879594553
              ],
              [
                -46.59936961985883,
                -22.44104979719519
              ],
              [
                -46.602994106796366,
                -22.442586284999685
              ],
              [
                -46.60566956017171,
                -22.440174298540185
              ],
              [
                -46.611466041490026,
                -22.4349483287094
              ],
              [
                -46.61616399915564,
                -22.438167827643465
              ],
              [
                -46.62222644633282,
                -22.437017845613337
              ],
              [
                -46.63113987850127,
                -22.43532687291674
              ],
              [
                -46.63627537128966,
                -22.429173408571334
              ],
              [
                -46.639594332289136,
                -22.4251964411384
              ],
              [
                -46.64174580045871,
                -22.426118455759163
              ],
              [
                -46.64662528521866,
                -22.42820893944101
              ],
              [
                -46.650255249439056,
                -22.42303396741922
              ],
              [
                -46.66610217260084,
                -22.413896591298197
              ],
              [
                -46.6677591697274,
                -22.407305616435767
              ],
              [
                -46.662963747148545,
                -22.391048654781365
              ],
              [
                -46.665851260571294,
                -22.380493243728562
              ],
              [
                -46.66294627729587,
                -22.372417742300673
              ],
              [
                -46.66047433659676,
                -22.365546289475645
              ],
              [
                -46.66883826931855,
                -22.36735729895154
              ],
              [
                -46.67510520792007,
                -22.368713797805146
              ],
              [
                -46.68201117207677,
                -22.357474373944733
              ],
              [
                -46.68657413828329,
                -22.356799903569176
              ],
              [
                -46.68694565899797,
                -22.351804420803713
              ],
              [
                -46.687160161347435,
                -22.348916905812676
              ],
              [
                -46.69027662885863,
                -22.346256926705255
              ],
              [
                -46.69092012356698,
                -22.346555425078492
              ],
              [
                -46.69399612448835,
                -22.34798346785818
              ],
              [
                -46.69323263970008,
                -22.343281984622585
              ],
              [
                -46.6931846340381,
                -22.342984952871994
              ],
              [
                -46.69349112770766,
                -22.34291648202886
              ],
              [
                -46.69760259645661,
                -22.34199497056177
              ],
              [
                -46.6965446359995,
                -22.33859402047699
              ],
              [
                -46.69610660109312,
                -22.337184987829414
              ],
              [
                -46.69531114445582,
                -22.33441552053005
              ],
              [
                -46.698490621175296,
                -22.3318405521989
              ],
              [
                -46.700741628735805,
                -22.320995106789468
              ],
              [
                -46.71036407062172,
                -22.318836125137686
              ],
              [
                -46.71052754891901,
                -22.31229516500477
              ],
              [
                -46.713762030966976,
                -22.314223145879577
              ],
              [
                -46.71749751291292,
                -22.316449136741042
              ],
              [
                -46.722709982647594,
                -22.30630820414816
              ],
              [
                -46.722803993444565,
                -22.306125225256114
              ],
              [
                -46.71720001933588,
                -22.306012724463024
              ],
              [
                -46.71516004240671,
                -22.305971697078782
              ],
              [
                -46.70499964312429,
                -22.29540669847933
              ],
              [
                -46.70988665969552,
                -22.284890798773677
              ],
              [
                -46.71007417841033,
                -22.284487274164217
              ],
              [
                -46.70960668056913,
                -22.28408227077337
              ],
              [
                -46.70188123383602,
                -22.277383291880405
              ],
              [
                -46.7054487209976,
                -22.273958820564395
              ],
              [
                -46.7101676840793,
                -22.269429337536227
              ],
              [
                -46.71043619966638,
                -22.263453899102373
              ],
              [
                -46.69358491195852,
                -22.24617494469335
              ],
              [
                -46.69171690034395,
                -22.246851428448903
              ],
              [
                -46.68811694014053,
                -22.24815539955918
              ],
              [
                -46.690029454831716,
                -22.243519911387565
              ],
              [
                -46.692667923012586,
                -22.237127961387323
              ],
              [
                -46.68479153899108,
                -22.22994348145736
              ],
              [
                -46.68420100258652,
                -22.229404979362574
              ],
              [
                -46.67772508291725,
                -22.223497476308047
              ],
              [
                -46.6795290982381,
                -22.216392007389064
              ],
              [
                -46.670334272907155,
                -22.183755653867188
              ],
              [
                -46.67318175854808,
                -22.175680691353822
              ],
              [
                -46.67320076955459,
                -22.17562819665087
              ],
              [
                -46.668562299405046,
                -22.17424269753432
              ],
              [
                -46.66813329584507,
                -22.174601210692895
              ],
              [
                -46.66196886789276,
                -22.17975766984031
              ],
              [
                -46.65022144674955,
                -22.17710862433581
              ],
              [
                -46.6454855053189,
                -22.179910633370923
              ],
              [
                -46.64279156526194,
                -22.169182180260897
              ],
              [
                -46.639773123913905,
                -22.15716220034215
              ],
              [
                -46.6241067373885,
                -22.152895207853582
              ],
              [
                -46.612588335890294,
                -22.149757685340248
              ],
              [
                -46.60085599134631,
                -22.13855569907836
              ],
              [
                -46.600063016353644,
                -22.134254217105294
              ],
              [
                -46.613425913342525,
                -22.130719758857495
              ],
              [
                -46.61567137353977,
                -22.130125789722054
              ],
              [
                -46.61693690348451,
                -22.126451786376713
              ],
              [
                -46.62283537586555,
                -22.109327881657904
              ],
              [
                -46.63258781079824,
                -22.107425927964993
              ],
              [
                -46.633886275803974,
                -22.107172916664933
              ],
              [
                -46.64064575044264,
                -22.09986598522566
              ],
              [
                -46.6443822310035,
                -22.09582650828127
              ],
              [
                -46.6510997178466,
                -22.09081506095839
              ],
              [
                -46.659123109466606,
                -22.0933140649408
              ],
              [
                -46.67046903329692,
                -22.09684708707595
              ],
              [
                -46.68005545707734,
                -22.090645109091206
              ],
              [
                -46.68721737059936,
                -22.093873101345523
              ],
              [
                -46.69306836786858,
                -22.090405654431688
              ],
              [
                -46.700015277918595,
                -22.094681144206017
              ],
              [
                -46.71580016643966,
                -22.089411720628537
              ],
              [
                -46.71966165055819,
                -22.08231026634532
              ],
              [
                -46.71976867428332,
                -22.082113743555542
              ],
              [
                -46.722808126514416,
                -22.07652029298483
              ],
              [
                -46.72005717675751,
                -22.07445429621862
              ],
              [
                -46.71290073449345,
                -22.074325294457413
              ],
              [
                -46.7056492977071,
                -22.078471761404383
              ],
              [
                -46.69579638519213,
                -22.072443748003874
              ],
              [
                -46.68348998127496,
                -22.073405205212694
              ],
              [
                -46.668813636970626,
                -22.06956968252434
              ],
              [
                -46.66436515049669,
                -22.068407701952857
              ],
              [
                -46.661115173829735,
                -22.064110695336506
              ],
              [
                -46.65786471616332,
                -22.059813690725324
              ],
              [
                -46.66409769373602,
                -22.058111750552953
              ],
              [
                -46.67593859255593,
                -22.05487778572182
              ],
              [
                -46.68005907944102,
                -22.044884334759107
              ],
              [
                -46.684953552778964,
                -22.036860364645385
              ],
              [
                -46.681516103448885,
                -22.028113914436847
              ],
              [
                -46.676609189442416,
                -22.02350740983308
              ],
              [
                -46.674116683284346,
                -22.021841413935334
              ],
              [
                -46.66339327656262,
                -22.023022892456243
              ],
              [
                -46.66175027463019,
                -22.023203907173794
              ],
              [
                -46.6541883551085,
                -22.016837912704325
              ],
              [
                -46.6536483833126,
                -22.015314387411376
              ],
              [
                -46.6518504129564,
                -22.010241423418563
              ],
              [
                -46.65033242758193,
                -22.00990842589318
              ],
              [
                -46.647918433253466,
                -22.009377921882187
              ],
              [
                -46.639890506921375,
                -22.008390418893697
              ],
              [
                -46.63290158525079,
                -22.010863876425788
              ],
              [
                -46.616716175839855,
                -22.016590809869605
              ],
              [
                -46.61421871540187,
                -22.014709306995652
              ],
              [
                -46.613022737665595,
                -22.009113358113336
              ],
              [
                -46.61375277253224,
                -22.001386881454213
              ],
              [
                -46.61779075449632,
                -21.994447905998005
              ],
              [
                -46.62038122039983,
                -21.993348415148276
              ],
              [
                -46.623449194969865,
                -21.992045931539632
              ],
              [
                -46.63054666123089,
                -21.980684010144927
              ],
              [
                -46.637003124815884,
                -21.980311549273004
              ],
              [
                -46.64341458972133,
                -21.974462576067715
              ],
              [
                -46.65335604608703,
                -21.952848208609744
              ],
              [
                -46.65327108875825,
                -21.94703221778263
              ],
              [
                -46.65318607442375,
                -21.94121627496025
              ],
              [
                -46.656591567234486,
                -21.938933763868647
              ],
              [
                -46.66684800942294,
                -21.932060850818083
              ],
              [
                -46.66456306128669,
                -21.92009486808123
              ],
              [
                -46.67201655176241,
                -21.908663450991853
              ],
              [
                -46.664207121647344,
                -21.898551493726092
              ],
              [
                -46.66086718212611,
                -21.897643477049545
              ],
              [
                -46.65272074146867,
                -21.895429465645254
              ],
              [
                -46.64708682675726,
                -21.87996854753095
              ],
              [
                -46.656705236844324,
                -21.876780561362477
              ],
              [
                -46.65689229176921,
                -21.86882310544159
              ],
              [
                -46.66630474110304,
                -21.848545235440277
              ],
              [
                -46.67149719608239,
                -21.849197726260133
              ],
              [
                -46.676689140069065,
                -21.849850238080784
              ],
              [
                -46.683110637008866,
                -21.843624806681802
              ],
              [
                -46.690505588788895,
                -21.836455830344494
              ],
              [
                -46.68025268841887,
                -21.822863366136904
              ],
              [
                -46.670367292360346,
                -21.815993371181698
              ],
              [
                -46.668101346114234,
                -21.809147904501806
              ],
              [
                -46.667186349166585,
                -21.806382947136484
              ],
              [
                -46.66112492406537,
                -21.799954459358982
              ],
              [
                -46.65082255785462,
                -21.789027957572635
              ],
              [
                -46.651342039563716,
                -21.787063496262103
              ],
              [
                -46.65354156579676,
                -21.7787450275297
              ],
              [
                -46.645182643206006,
                -21.775091524535085
              ],
              [
                -46.64105269312283,
                -21.771262545663138
              ],
              [
                -46.63795723153564,
                -21.768393037008323
              ],
              [
                -46.636500240736815,
                -21.76769555642938
              ],
              [
                -46.63371025548288,
                -21.768470048318925
              ],
              [
                -46.62966626641558,
                -21.768329521202933
              ],
              [
                -46.625579314266545,
                -21.76571452370621
              ],
              [
                -46.63038883068014,
                -21.74040865555448
              ],
              [
                -46.625136415941014,
                -21.733604656860503
              ],
              [
                -46.62801688175,
                -21.72912171580653
              ],
              [
                -46.62199298653494,
                -21.715047246609007
              ],
              [
                -46.62733149130833,
                -21.705856304461914
              ],
              [
                -46.62929295934051,
                -21.7024808507118
              ],
              [
                -46.62849999845412,
                -21.696058348118402
              ],
              [
                -46.623413528984706,
                -21.69263735685927
              ],
              [
                -46.62113861830446,
                -21.675047428443282
              ],
              [
                -46.61852861022672,
                -21.67560395186751
              ],
              [
                -46.57706244387303,
                -21.684446789734487
              ],
              [
                -46.571236984649346,
                -21.681649285592915
              ],
              [
                -46.5654110634292,
                -21.678852291454263
              ],
              [
                -46.56542659374838,
                -21.673763803726434
              ],
              [
                -46.56547661643719,
                -21.65757939195316
              ],
              [
                -46.55580669350077,
                -21.652327895359313
              ],
              [
                -46.54787077264065,
                -21.649238907066398
              ],
              [
                -46.552525772382694,
                -21.637577474999052
              ],
              [
                -46.54691633698564,
                -21.629943473567987
              ],
              [
                -46.53441645389699,
                -21.628191948857413
              ],
              [
                -46.53383148611726,
                -21.620796489610278
              ],
              [
                -46.52539406839712,
                -21.61605548694058
              ],
              [
                -46.51710164122956,
                -21.61139602328064
              ],
              [
                -46.51552416812115,
                -21.605500034281842
              ],
              [
                -46.51819315875809,
                -21.603922541869366
              ],
              [
                -46.52400162796987,
                -21.600491074607366
              ],
              [
                -46.51799968475818,
                -21.592531097797114
              ],
              [
                -46.52143918648916,
                -21.584166663859957
              ],
              [
                -46.511688777131226,
                -21.571626696102093
              ],
              [
                -46.51186583250406,
                -21.566039223299313
              ],
              [
                -46.50922033552415,
                -21.56468169781945
              ],
              [
                -46.50293138037577,
                -21.56145618167673
              ],
              [
                -46.499705453281805,
                -21.554096747403793
              ],
              [
                -46.51013741837515,
                -21.536298325104582
              ],
              [
                -46.51452086839884,
                -21.534851863573994
              ],
              [
                -46.52009034377687,
                -21.53301339804348
              ],
              [
                -46.523697316671246,
                -21.521447950925666
              ],
              [
                -46.516552404644536,
                -21.512205987399298
              ],
              [
                -46.51538291887673,
                -21.510693986568246
              ],
              [
                -46.51598094734074,
                -21.508889993786447
              ],
              [
                -46.51879744650514,
                -21.500399031888694
              ],
              [
                -46.51348249861765,
                -21.49437603778985
              ],
              [
                -46.50841503982895,
                -21.48863456700528
              ],
              [
                -46.51094606782723,
                -21.479642621786073
              ],
              [
                -46.511336047755044,
                -21.478255614464214
              ],
              [
                -46.51177755279988,
                -21.47668713214849
              ],
              [
                -46.50880208826534,
                -21.469408171261513
              ],
              [
                -46.519108558462776,
                -21.461541711068246
              ],
              [
                -46.51978557337728,
                -21.448009781796245
              ],
              [
                -46.528043995470114,
                -21.44450881426829
              ],
              [
                -46.53450044903975,
                -21.448173823617697
              ],
              [
                -46.53530142604569,
                -21.448628824536076
              ],
              [
                -46.54702636987345,
                -21.44098288599497
              ],
              [
                -46.54836986537676,
                -21.44010639672616
              ],
              [
                -46.549638842507996,
                -21.440228414470386
              ],
              [
                -46.560982757360186,
                -21.44131791101358
              ],
              [
                -46.569799201299055,
                -21.436343945012712
              ],
              [
                -46.57131468687209,
                -21.433968482384653
              ],
              [
                -46.576101161732296,
                -21.426465050939594
              ],
              [
                -46.5813115993627,
                -21.430897029352945
              ],
              [
                -46.58504557484897,
                -21.433110026540366
              ],
              [
                -46.60226340986455,
                -21.443316006829328
              ],
              [
                -46.6148072881421,
                -21.44097853098336
              ],
              [
                -46.61940277350053,
                -21.434521082993527
              ],
              [
                -46.617330795668735,
                -21.431504591005048
              ],
              [
                -46.6156098074087,
                -21.428997119501776
              ],
              [
                -46.62009682355485,
                -21.425244137270028
              ],
              [
                -46.61797682871916,
                -21.42068964153359
              ],
              [
                -46.626120299431804,
                -21.410993216389244
              ],
              [
                -46.6327447391479,
                -21.410503727121462
              ],
              [
                -46.63689019680745,
                -21.41019723147117
              ],
              [
                -46.63804020998138,
                -21.403288295605133
              ],
              [
                -46.63833822487617,
                -21.401497801970223
              ],
              [
                -46.64062321703991,
                -21.399143317252683
              ],
              [
                -46.64710114048771,
                -21.39246786824975
              ],
              [
                -46.64696271851515,
                -21.37946190925189
              ],
              [
                -46.64557723050269,
                -21.375264460752863
              ],
              [
                -46.64452775767193,
                -21.372084475257125
              ],
              [
                -46.64609225240902,
                -21.36617148772608
              ],
              [
                -46.64672822634023,
                -21.363765510936876
              ],
              [
                -46.65917463703225,
                -21.36326802093523
              ],
              [
                -46.665247598179505,
                -21.361160067960682
              ],
              [
                -46.673470469906434,
                -21.374005023922116
              ],
              [
                -46.68295543431915,
                -21.37577054933621
              ],
              [
                -46.69175332428877,
                -21.382258038334484
              ],
              [
                -46.692270822835226,
                -21.38465851319371
              ],
              [
                -46.693438266999756,
                -21.39007146633241
              ],
              [
                -46.70550417363235,
                -21.404353431649238
              ],
              [
                -46.711390635576464,
                -21.396165514300222
              ],
              [
                -46.71425561986589,
                -21.392181001896418
              ],
              [
                -46.71728059774699,
                -21.39089754798454
              ],
              [
                -46.719993553592055,
                -21.389150558471492
              ],
              [
                -46.724189039843075,
                -21.39119807469276
              ],
              [
                -46.72682650056261,
                -21.391371539791216
              ],
              [
                -46.73651041576141,
                -21.392009081183613
              ],
              [
                -46.755085282227505,
                -21.379767667544133
              ],
              [
                -46.76167225867242,
                -21.374505237020056
              ],
              [
                -46.761573285383676,
                -21.374206232190375
              ],
              [
                -46.75817633319876,
                -21.363913253515822
              ],
              [
                -46.76425129109385,
                -21.359575291222935
              ],
              [
                -46.76883375908117,
                -21.36065730707602
              ],
              [
                -46.77594370045135,
                -21.362336311712774
              ],
              [
                -46.780745631554055,
                -21.370125785071025
              ],
              [
                -46.78083414239933,
                -21.370267779624612
              ],
              [
                -46.78117509896793,
                -21.370141292125197
              ],
              [
                -46.785747077376,
                -21.368441822263197
              ],
              [
                -46.78803054071341,
                -21.370393295932377
              ],
              [
                -46.7913080343139,
                -21.37319431015472
              ],
              [
                -46.80007095017223,
                -21.370835318460617
              ],
              [
                -46.818348832335694,
                -21.365914917015715
              ],
              [
                -46.832307204173276,
                -21.367317405012166
              ],
              [
                -46.835414646460855,
                -21.37302989490355
              ],
              [
                -46.8351176392283,
                -21.37725835893252
              ],
              [
                -46.83478262268291,
                -21.382027864280964
              ],
              [
                -46.84166558020272,
                -21.388626323901885
              ],
              [
                -46.8524149612533,
                -21.389104872917947
              ],
              [
                -46.85532995786579,
                -21.389233894977746
              ],
              [
                -46.8570814142395,
                -21.392300354962952
              ],
              [
                -46.85780091553241,
                -21.393560344848254
              ],
              [
                -46.859427899097,
                -21.393906845480842
              ],
              [
                -46.86490886846689,
                -21.395074878348005
              ],
              [
                -46.865740344237324,
                -21.397533368816113
              ],
              [
                -46.86766932022725,
                -21.403234833718056
              ],
              [
                -46.881999183833905,
                -21.4060423441097
              ],
              [
                -46.88422868117036,
                -21.406479385905342
              ],
              [
                -46.88574916906823,
                -21.408005373644304
              ],
              [
                -46.89284956020904,
                -21.415128342443623
              ],
              [
                -46.897462516070775,
                -21.41352536724592
              ],
              [
                -46.89983901899731,
                -21.412700387443522
              ],
              [
                -46.902930988407036,
                -21.415740854124596
              ],
              [
                -46.90796693406799,
                -21.420692338844223
              ],
              [
                -46.9146573976265,
                -21.420491891457797
              ],
              [
                -46.9164158493182,
                -21.420439375349527
              ],
              [
                -46.91706184791155,
                -21.421466375167892
              ],
              [
                -46.92031732954515,
                -21.426641359141854
              ],
              [
                -46.9242792904048,
                -21.42283938469063
              ],
              [
                -46.92499927556053,
                -21.422149365874326
              ],
              [
                -46.92708328855271,
                -21.42276886643002
              ],
              [
                -46.93368119437653,
                -21.424730405523984
              ],
              [
                -46.93975316231306,
                -21.42244889941103
              ],
              [
                -46.94864959523159,
                -21.419104934809436
              ],
              [
                -46.95306007290889,
                -21.42211196616257
              ],
              [
                -46.95620453759391,
                -21.424256432275925
              ],
              [
                -46.958988516619144,
                -21.421962454535585
              ],
              [
                -46.96248749154394,
                -21.419078989485868
              ],
              [
                -46.96957290852985,
                -21.421657981949465
              ],
              [
                -46.9699694168352,
                -21.421802479310575
              ],
              [
                -46.97040989300446,
                -21.421540470719627
              ],
              [
                -46.97447838986612,
                -21.41911801898216
              ],
              [
                -46.975489897401836,
                -21.420827017534364
              ],
              [
                -46.97647186933042,
                -21.422486005246967
              ],
              [
                -46.97956984637514,
                -21.424308512776747
              ],
              [
                -46.98350680801278,
                -21.426632514144735
              ],
              [
                -46.986417305623476,
                -21.421151051875935
              ],
              [
                -46.99219623744718,
                -21.424696542313328
              ],
              [
                -46.99924166409713,
                -21.420539579682032
              ],
              [
                -47.01077908550319,
                -21.4218050921804
              ],
              [
                -47.012379095199954,
                -21.41589010949352
              ],
              [
                -47.010062099017205,
                -21.41330761863054
              ],
              [
                -47.007260126449125,
                -21.410183619060206
              ],
              [
                -47.00983214079425,
                -21.405382151662725
              ],
              [
                -47.014962120516245,
                -21.39580522770955
              ],
              [
                -47.00947164405477,
                -21.392999736536346
              ],
              [
                -47.008270166772476,
                -21.392385219283298
              ],
              [
                -47.00414875647156,
                -21.38313276837691
              ],
              [
                -47.00681023050964,
                -21.376187777418778
              ],
              [
                -47.00908124368099,
                -21.37026283558307
              ],
              [
                -46.996435868620374,
                -21.356922373690953
              ],
              [
                -46.9975998818177,
                -21.350344887060924
              ],
              [
                -47.01745074403781,
                -21.33995449682291
              ],
              [
                -47.021207247412384,
                -21.332223021514114
              ],
              [
                -47.02325075626669,
                -21.32801557992235
              ],
              [
                -47.02133874844927,
                -21.323185604877487
              ],
              [
                -47.02053976206695,
                -21.32116511139248
              ],
              [
                -47.02839674806556,
                -21.31300313793892
              ],
              [
                -47.03008774245788,
                -21.31124617775587
              ],
              [
                -47.03472069556297,
                -21.310539689316126
              ],
              [
                -47.03192322140312,
                -21.304572224366048
              ],
              [
                -47.029464278295805,
                -21.29932670587745
              ],
              [
                -47.0364227127171,
                -21.29381578143348
              ],
              [
                -47.04191319570308,
                -21.289467308589078
              ],
              [
                -47.0403396906281,
                -21.28445179834184
              ],
              [
                -47.03908222794023,
                -21.280443846130463
              ],
              [
                -47.04440574413478,
                -21.26545190856767
              ],
              [
                -47.05086968015655,
                -21.256747995038975
              ],
              [
                -47.0518207051129,
                -21.246123543189007
              ],
              [
                -47.06019466132059,
                -21.24048109208129
              ],
              [
                -47.062646659156265,
                -21.238829103396117
              ],
              [
                -47.07690802587921,
                -21.239295104624375
              ],
              [
                -47.078431531885045,
                -21.230092654491663
              ],
              [
                -47.07186410188074,
                -21.225344192894404
              ],
              [
                -47.07035464516948,
                -21.22135117597716
              ],
              [
                -47.0675247006912,
                -21.213867205254548
              ],
              [
                -47.06230024554402,
                -21.209495744412834
              ],
              [
                -47.06511673387432,
                -21.207010251659465
              ],
              [
                -47.086119054479425,
                -21.20678078083204
              ],
              [
                -47.09298950677346,
                -21.20859982817903
              ],
              [
                -47.09519544396084,
                -21.209184301179306
              ],
              [
                -47.09824844755787,
                -21.213003284981156
              ],
              [
                -47.10199991203314,
                -21.202546862681928
              ],
              [
                -47.1031359351747,
                -21.19938035976239
              ],
              [
                -47.11833383875971,
                -21.18528696183183
              ],
              [
                -47.11810687473583,
                -21.17484453604806
              ],
              [
                -47.12121185382866,
                -21.170309532821367
              ],
              [
                -47.123195360221516,
                -21.167412577840697
              ],
              [
                -47.11685092442177,
                -21.15321113500377
              ],
              [
                -47.13304638407225,
                -21.132974769015053
              ],
              [
                -47.12577643566256,
                -21.12486177574732
              ],
              [
                -47.12310897273344,
                -21.118718817122577
              ],
              [
                -47.119974026814326,
                -21.111500318426735
              ],
              [
                -47.11233457925845,
                -21.10815281846793
              ],
              [
                -47.11269608533969,
                -21.1044318422043
              ],
              [
                -47.12766751376449,
                -21.098729915575586
              ],
              [
                -47.13531895768023,
                -21.089119489508438
              ],
              [
                -47.13658697603408,
                -21.08752601543878
              ],
              [
                -47.14182990351293,
                -21.087743006093202
              ],
              [
                -47.141311422901104,
                -21.084657507491134
              ],
              [
                -47.14009693723961,
                -21.077435065857312
              ],
              [
                -47.13864449570973,
                -21.068796066388874
              ],
              [
                -47.14296796006821,
                -21.062477622896935
              ],
              [
                -47.14564495176561,
                -21.058564664601434
              ],
              [
                -47.14786200732969,
                -21.035007782128904
              ],
              [
                -47.152723001967274,
                -21.025409340752145
              ],
              [
                -47.15651845843452,
                -21.01847536584335
              ],
              [
                -47.1509585251383,
                -21.01025788573028
              ],
              [
                -47.150668047780115,
                -21.00982838003195
              ],
              [
                -47.15066604598393,
                -21.00976489333094
              ],
              [
                -47.150517046105826,
                -21.005731922266857
              ],
              [
                -47.1501911007552,
                -20.996924962625176
              ],
              [
                -47.1488220917069,
                -20.99395348930755
              ],
              [
                -47.14326766763522,
                -20.98189851266762
              ],
              [
                -47.14619415401234,
                -20.975690558048825
              ],
              [
                -47.14912064738736,
                -20.96948208443424
              ],
              [
                -47.15919158288137,
                -20.966843645255718
              ],
              [
                -47.16407156478576,
                -20.965564628069465
              ],
              [
                -47.166441030196964,
                -20.96190916375767
              ],
              [
                -47.17057351021949,
                -20.955532713077037
              ],
              [
                -47.17484550705389,
                -20.954608216607678
              ],
              [
                -47.18152446777112,
                -20.953162727889456
              ],
              [
                -47.19211089146521,
                -20.941568842988556
              ],
              [
                -47.19084643413719,
                -20.934651349887105
              ],
              [
                -47.18958243179783,
                -20.927733367798353
              ],
              [
                -47.20647883182104,
                -20.924358423840676
              ],
              [
                -47.21000379612878,
                -20.923653940368943
              ],
              [
                -47.21706023696771,
                -20.917390999724297
              ],
              [
                -47.22411670081259,
                -20.91112756007973
              ],
              [
                -47.23958764397349,
                -20.884797710530588
              ],
              [
                -47.227262841572895,
                -20.856798305046688
              ],
              [
                -47.22653534234425,
                -20.8551463191007
              ],
              [
                -47.21456749923941,
                -20.825310922586045
              ],
              [
                -47.224298022903895,
                -20.799642584228252
              ],
              [
                -47.218848563749916,
                -20.7957286010028
              ],
              [
                -47.21339963458925,
                -20.791815117784942
              ],
              [
                -47.21230364942428,
                -20.78910711899842
              ],
              [
                -47.2066147037974,
                -20.77504716205993
              ],
              [
                -47.197997798259756,
                -20.774631637851794
              ],
              [
                -47.19483832046493,
                -20.774479159443928
              ],
              [
                -47.188361867914416,
                -20.770140149673608
              ],
              [
                -47.181852999154394,
                -20.740872248108428
              ],
              [
                -47.17472308138959,
                -20.738774739893344
              ],
              [
                -47.182538529320134,
                -20.733043293663304
              ],
              [
                -47.18580199466971,
                -20.730649340612924
              ],
              [
                -47.16905368224498,
                -20.717212859456694
              ],
              [
                -47.17266666856064,
                -20.711432406668113
              ],
              [
                -47.168938693002055,
                -20.705211897155575
              ],
              [
                -47.16535374036815,
                -20.70137192451325
              ],
              [
                -47.15068033439473,
                -20.706418846444436
              ],
              [
                -47.148657852872375,
                -20.707114837887286
              ],
              [
                -47.11829911557389,
                -20.7067617856862
              ],
              [
                -47.11450713670603,
                -20.70531676895145
              ],
              [
                -47.11308369373267,
                -20.699306832390416
              ],
              [
                -47.11233867091489,
                -20.696163830695575
              ],
              [
                -47.10182833225099,
                -20.683877858256626
              ],
              [
                -47.10077329842857,
                -20.682644858527006
              ],
              [
                -47.10165632454725,
                -20.673196422569426
              ],
              [
                -47.101986833062305,
                -20.669663937534864
              ],
              [
                -47.101587856471774,
                -20.668918918124064
              ],
              [
                -47.096056932466325,
                -20.65858548806055
              ],
              [
                -47.09692244993738,
                -20.643853559720903
              ],
              [
                -47.10843539378848,
                -20.63907660833081
              ],
              [
                -47.11220335256195,
                -20.637513107548735
              ],
              [
                -47.111480367603406,
                -20.634344139070247
              ],
              [
                -47.109899875460805,
                -20.627422666606567
              ],
              [
                -47.10844140679322,
                -20.62102867890453
              ],
              [
                -47.12410882725411,
                -20.608800799266653
              ],
              [
                -47.12496881700647,
                -20.603675817334544
              ],
              [
                -47.12232887767173,
                -20.594251354342482
              ],
              [
                -47.125549878694564,
                -20.58998337028486
              ],
              [
                -47.13195180767341,
                -20.59027088322829
              ],
              [
                -47.14203477083582,
                -20.565469048380685
              ],
              [
                -47.13720085313219,
                -20.56063302154731
              ],
              [
                -47.141957849080526,
                -20.545067113304192
              ],
              [
                -47.14299884838414,
                -20.541658630521248
              ],
              [
                -47.14553532556423,
                -20.533350196365394
              ],
              [
                -47.147965337501645,
                -20.529260236434876
              ],
              [
                -47.15391782873813,
                -20.5192448042798
              ],
              [
                -47.18433708629388,
                -20.513221891985012
              ],
              [
                -47.1991689674763,
                -20.503139485732966
              ],
              [
                -47.20160147045772,
                -20.502867994243587
              ],
              [
                -47.21103090274037,
                -20.501816015357104
              ],
              [
                -47.22914577050974,
                -20.486418604262614
              ],
              [
                -47.250138662892205,
                -20.47626420900544
              ],
              [
                -47.253149112942054,
                -20.477476722776956
              ],
              [
                -47.25751157667383,
                -20.470776764416645
              ],
              [
                -47.25902856801665,
                -20.46844679359262
              ],
              [
                -47.26247604428522,
                -20.467646804248393
              ],
              [
                -47.26944502427608,
                -20.466029326833297
              ],
              [
                -47.29132788372515,
                -20.44882944902034
              ],
              [
                -47.28685542762378,
                -20.443041447759416
              ],
              [
                -47.28602795841339,
                -20.44196944786316
              ],
              [
                -47.28683447175479,
                -20.434666024394527
              ],
              [
                -47.28929191110188,
                -20.433509034260783
              ],
              [
                -47.293833900322596,
                -20.431371542187733
              ],
              [
                -47.29245090298296,
                -20.42772505439077
              ],
              [
                -47.29490440242744,
                -20.42619604305364
              ],
              [
                -47.29083994046517,
                -20.423038554072193
              ],
              [
                -47.28830796665093,
                -20.421072062198817
              ],
              [
                -47.291481450661294,
                -20.418697585799332
              ],
              [
                -47.293025453423134,
                -20.417542121336403
              ],
              [
                -47.29173094946258,
                -20.417008099644782
              ],
              [
                -47.28778549416296,
                -20.415378592547604
              ],
              [
                -47.28912902354202,
                -20.409325122388694
              ],
              [
                -47.28537755886572,
                -20.40482312675911
              ],
              [
                -47.29009601810084,
                -20.39937370323392
              ],
              [
                -47.28678107606905,
                -20.38744025587587
              ],
              [
                -47.29208103118912,
                -20.385631764113576
              ],
              [
                -47.29330851141708,
                -20.385212749264614
              ],
              [
                -47.292072550232064,
                -20.384238263871843
              ],
              [
                -47.29017007096054,
                -20.382737250351685
              ],
              [
                -47.29205506311735,
                -20.38152278103987
              ],
              [
                -47.2939745570573,
                -20.380286283922537
              ],
              [
                -47.29208159620608,
                -20.36983835197075
              ],
              [
                -47.29497404682529,
                -20.368749336600175
              ],
              [
                -47.29289657160962,
                -20.363863845118377
              ],
              [
                -47.29775560142672,
                -20.347965950831078
              ],
              [
                -47.28898169171515,
                -20.337045478824468
              ],
              [
                -47.289832176605486,
                -20.333248995475355
              ],
              [
                -47.287568226087174,
                -20.3325090133554
              ],
              [
                -47.28505770979708,
                -20.331689495000283
              ],
              [
                -47.285297756149376,
                -20.31926556117024
              ],
              [
                -47.28536425040331,
                -20.31581709815242
              ],
              [
                -47.28417878815679,
                -20.314714591532926
              ],
              [
                -47.281368314386626,
                -20.312102609169845
              ],
              [
                -47.27627442014744,
                -20.291160674481503
              ],
              [
                -47.26947046435518,
                -20.28234768333821
              ],
              [
                -47.26416654411216,
                -20.28210071116668
              ],
              [
                -47.25444213544956,
                -20.266422765242428
              ],
              [
                -47.25241717612793,
                -20.263157758097545
              ],
              [
                -47.24911320305806,
                -20.262060266138874
              ],
              [
                -47.24580874999437,
                -20.260962266183043
              ],
              [
                -47.24350927776965,
                -20.255267274236694
              ],
              [
                -47.23211241904153,
                -20.22703889667148
              ],
              [
                -47.23065196264198,
                -20.2186229572048
              ],
              [
                -47.25441435702246,
                -20.191565105401754
              ],
              [
                -47.256588364126564,
                -20.165721751476745
              ],
              [
                -47.261976359935325,
                -20.161269784808567
              ],
              [
                -47.267364815743356,
                -20.15681783713993
              ],
              [
                -47.292554147749634,
                -20.148182409615934
              ],
              [
                -47.30921759057829,
                -20.124018085792425
              ],
              [
                -47.3309214335098,
                -20.113767180399538
              ],
              [
                -47.34708131861034,
                -20.10486728048516
              ],
              [
                -47.35134227857154,
                -20.104345273715587
              ],
              [
                -47.35822170523701,
                -20.10350180908046
              ],
              [
                -47.37411566158872,
                -20.08318443541162
              ],
              [
                -47.40265139313699,
                -20.082314516126953
              ],
              [
                -47.405498892211135,
                -20.080850495426468
              ],
              [
                -47.41416836080374,
                -20.068476081722487
              ],
              [
                -47.417777328710976,
                -20.064163605873592
              ],
              [
                -47.4386737017515,
                -20.049186762507293
              ],
              [
                -47.44344923902476,
                -20.02923835515057
              ],
              [
                -47.43808228221393,
                -20.025730843918268
              ],
              [
                -47.42506041327121,
                -20.017218861099764
              ],
              [
                -47.425214929638756,
                -20.012348404365447
              ],
              [
                -47.43736184979403,
                -19.996486996472456
              ],
              [
                -47.44355481142811,
                -19.991950012179185
              ],
              [
                -47.4497472870706,
                -19.98741305488252
              ],
              [
                -47.456959250277556,
                -19.976859646633795
              ],
              [
                -47.46592320848501,
                -19.9637422163314
              ],
              [
                -47.46958667196526,
                -19.962644751849307
              ],
              [
                -47.47325065444348,
                -19.961547247368546
              ],
              [
                -47.477549595535706,
                -19.965562223398216
              ],
              [
                -47.48078457998791,
                -19.96858371665185
              ],
              [
                -47.48949898485479,
                -19.969940257743243
              ],
              [
                -47.49714444182133,
                -19.971130737987203
              ],
              [
                -47.49946443538161,
                -19.97353573999222
              ],
              [
                -47.499636412495136,
                -19.973714247546834
              ],
              [
                -47.508071328998234,
                -19.982457212776616
              ],
              [
                -47.512368287795816,
                -19.98264574758554
              ],
              [
                -47.53947855275,
                -19.98383630449057
              ],
              [
                -47.54919743635337,
                -19.992776776044874
              ],
              [
                -47.55368591752127,
                -19.995504269930375
              ],
              [
                -47.58063721555671,
                -19.995383811945356
              ],
              [
                -47.592067091226994,
                -20.006520277148407
              ],
              [
                -47.59314203796279,
                -20.0156512702498
              ],
              [
                -47.59427602381178,
                -20.0252797290854
              ],
              [
                -47.61353231869744,
                -20.041630683605295
              ],
              [
                -47.622269256895265,
                -20.041772213841632
              ],
              [
                -47.62447672300887,
                -20.04180768520923
              ],
              [
                -47.62610570735682,
                -20.04270671891515
              ],
              [
                -47.6355156309374,
                -20.047902185217556
              ],
              [
                -47.66177095976831,
                -20.02858983424085
              ],
              [
                -47.67231339244486,
                -20.01771591268535
              ],
              [
                -47.6737319112,
                -20.01088847151694
              ],
              [
                -47.67569642992507,
                -20.00143453652577
              ],
              [
                -47.703778718711824,
                -19.97942620120773
              ],
              [
                -47.71222817167506,
                -19.977671215937697
              ],
              [
                -47.72272658453266,
                -19.982562219421016
              ],
              [
                -47.73322297841494,
                -19.987452231924816
              ],
              [
                -47.750648336715265,
                -19.989810758160036
              ],
              [
                -47.76452074730271,
                -19.98551081590084
              ],
              [
                -47.77346564838007,
                -19.984816796727085
              ],
              [
                -47.801101437568775,
                -19.984755898402646
              ],
              [
                -47.83422016221833,
                -19.995587916975722
              ],
              [
                -47.84530655562098,
                -19.99225393203733
              ],
              [
                -47.85127603376874,
                -19.990458964760574
              ],
              [
                -47.85932495639884,
                -19.992516481009833
              ],
              [
                -47.87493826382281,
                -20.01996488514509
              ],
              [
                -47.87535871643106,
                -20.02954485576443
              ],
              [
                -47.873175754940654,
                -20.035571315055282
              ],
              [
                -47.870721752891626,
                -20.037604783015063
              ],
              [
                -47.86058781650635,
                -20.04600322976711
              ],
              [
                -47.85964980059016,
                -20.04934819019203
              ],
              [
                -47.85984528693361,
                -20.052920199376306
              ],
              [
                -47.863073734299675,
                -20.056362176843084
              ],
              [
                -47.86950416631808,
                -20.063218147859928
              ],
              [
                -47.871441650628675,
                -20.07635210616076
              ],
              [
                -47.8781585144969,
                -20.102566488301076
              ],
              [
                -47.8939208540386,
                -20.123355399701047
              ],
              [
                -47.90132977062462,
                -20.12298944222933
              ],
              [
                -47.91136221189855,
                -20.118139496059143
              ],
              [
                -47.933638058794,
                -20.107370593467465
              ],
              [
                -47.9383355426107,
                -20.099445633628353
              ],
              [
                -47.94303354442072,
                -20.09152017279409
              ],
              [
                -47.94801306598755,
                -20.05533689122567
              ],
              [
                -47.97550838951782,
                -20.03489402349502
              ],
              [
                -47.97746239956848,
                -20.035353539207886
              ],
              [
                -47.980931362479986,
                -20.036169040026383
              ],
              [
                -47.98559379928743,
                -20.038592556090034
              ],
              [
                -47.98675280183394,
                -20.040067559890336
              ],
              [
                -47.99014475346528,
                -20.044382020615092
              ],
              [
                -47.990095186524144,
                -20.074514384988003
              ],
              [
                -48.002891020925055,
                -20.104157249406533
              ],
              [
                -48.019392855651816,
                -20.11968021445447
              ],
              [
                -48.025249796624514,
                -20.11867075417723
              ],
              [
                -48.037730722820505,
                -20.116519773206143
              ],
              [
                -48.04625059078236,
                -20.13192374669475
              ],
              [
                -48.04892453761031,
                -20.136758219228533
              ],
              [
                -48.069977347422224,
                -20.146125714694165
              ],
              [
                -48.094083175046336,
                -20.14595626281637
              ],
              [
                -48.11161704605684,
                -20.143418313613125
              ],
              [
                -48.12165396281402,
                -20.134650874248553
              ],
              [
                -48.12608294044054,
                -20.130781909092967
              ],
              [
                -48.147262303463336,
                -20.123665495130563
              ],
              [
                -48.157827760841364,
                -20.103737616674398
              ],
              [
                -48.1654666927412,
                -20.103416137634355
              ],
              [
                -48.16727417452297,
                -20.103339656596123
              ],
              [
                -48.178354127871174,
                -20.095324202240434
              ],
              [
                -48.18200167101055,
                -20.074150815184336
              ],
              [
                -48.20292156399785,
                -20.044967496480705
              ],
              [
                -48.20799450810224,
                -20.04018654232081
              ],
              [
                -48.21099800518174,
                -20.037355067543796
              ],
              [
                -48.213633986209885,
                -20.03487007116937
              ],
              [
                -48.22515789945269,
                -20.02882662742936
              ],
              [
                -48.24048429610952,
                -20.028872694074767
              ],
              [
                -48.247046728126364,
                -20.035443660168585
              ],
              [
                -48.24368723143862,
                -20.046870115789122
              ],
              [
                -48.25298007351887,
                -20.07738097403364
              ],
              [
                -48.25128856970987,
                -20.081346444720936
              ],
              [
                -48.24959705589929,
                -20.08531191040899
              ],
              [
                -48.24601207575517,
                -20.08872741191736
              ],
              [
                -48.218527749354664,
                -20.114909728474522
              ],
              [
                -48.21821473748685,
                -20.1258991710116
              ],
              [
                -48.22426466756794,
                -20.13312511480671
              ],
              [
                -48.24591044285241,
                -20.140473651799173
              ],
              [
                -48.267984289233596,
                -20.142414189817092
              ],
              [
                -48.27576970498669,
                -20.140024228111148
              ],
              [
                -48.28355718873246,
                -20.137634260409527
              ],
              [
                -48.289188637638404,
                -20.13071128188293
              ],
              [
                -48.295031125604076,
                -20.123528830134436
              ],
              [
                -48.30715254411022,
                -20.118244416317804
              ],
              [
                -48.31927542863236,
                -20.11295796950977
              ],
              [
                -48.339181776538695,
                -20.113845985036754
              ],
              [
                -48.34509174196715,
                -20.117170995227987
              ],
              [
                -48.35100067340902,
                -20.120494486431742
              ],
              [
                -48.35762260475397,
                -20.121169504110853
              ],
              [
                -48.36875402488766,
                -20.122305535106022
              ],
              [
                -48.37646047739792,
                -20.12200852920254
              ],
              [
                -48.40530875354183,
                -20.113035652693366
              ],
              [
                -48.438559974892804,
                -20.119746698652463
              ],
              [
                -48.45072735764806,
                -20.125161211964073
              ],
              [
                -48.45837279902139,
                -20.12755268912884
              ],
              [
                -48.4906480115902,
                -20.13764921567925
              ],
              [
                -48.50223494305941,
                -20.13747228119502
              ],
              [
                -48.51382131955336,
                -20.13729478771758
              ],
              [
                -48.536882145731205,
                -20.133025340092626
              ],
              [
                -48.55416102480514,
                -20.12982142959139
              ],
              [
                -48.58025730957554,
                -20.131631455890624
              ],
              [
                -48.58073579268409,
                -20.131664991958303
              ],
              [
                -48.588212725152445,
                -20.13452846354195
              ],
              [
                -48.59569017061783,
                -20.137392986130816
              ],
              [
                -48.62632436779154,
                -20.159607951469876
              ],
              [
                -48.643986216431166,
                -20.165290946952663
              ],
              [
                -48.6464031870364,
                -20.166068462486212
              ],
              [
                -48.66237306007836,
                -20.166216995851418
              ],
              [
                -48.667779027529576,
                -20.16369503968845
              ],
              [
                -48.67380298792135,
                -20.160885055476523
              ],
              [
                -48.685028382739105,
                -20.161677078603088
              ],
              [
                -48.69218582661014,
                -20.16218160563045
              ],
              [
                -48.71625769242964,
                -20.154083188852955
              ],
              [
                -48.729070082482906,
                -20.149772748192103
              ],
              [
                -48.732281046882896,
                -20.150187227495312
              ],
              [
                -48.753254871168096,
                -20.15289327869375
              ],
              [
                -48.780667647137626,
                -20.163374793824826
              ],
              [
                -48.82274281625553,
                -20.16143188344645
              ],
              [
                -48.843996603376546,
                -20.17284038453235
              ],
              [
                -48.8495860465394,
                -20.177225876313248
              ],
              [
                -48.85517498470758,
                -20.18161038910767
              ],
              [
                -48.857416425034614,
                -20.198851292784482
              ],
              [
                -48.85541440709327,
                -20.205315775206444
              ],
              [
                -48.85337741109345,
                -20.211893721007435
              ],
              [
                -48.853690408619144,
                -20.216717725136267
              ],
              [
                -48.857871339826985,
                -20.229378670447094
              ],
              [
                -48.8717427054019,
                -20.241376609010246
              ],
              [
                -48.88498550339509,
                -20.26866403928952
              ],
              [
                -48.888074439080555,
                -20.29469941725689
              ],
              [
                -48.8816339620152,
                -20.304610357084357
              ],
              [
                -48.87519346194645,
                -20.314520804914523
              ],
              [
                -48.87954784019402,
                -20.347810652382545
              ],
              [
                -48.869355363385445,
                -20.377286484944015
              ],
              [
                -48.86801933160089,
                -20.389677935523828
              ],
              [
                -48.86710031787118,
                -20.398194382319126
              ],
              [
                -48.86864977924874,
                -20.406494839576297
              ],
              [
                -48.87812967659928,
                -20.42918877935967
              ],
              [
                -48.88353758785778,
                -20.43360425488956
              ],
              [
                -48.886319580181876,
                -20.435875734564913
              ],
              [
                -48.89635649443049,
                -20.439754740720982
              ],
              [
                -48.89886144678271,
                -20.440722736759547
              ],
              [
                -48.96255104185891,
                -20.402638081892807
              ],
              [
                -48.96548753446629,
                -20.397951628414837
              ],
              [
                -48.96842503006169,
                -20.393266150935165
              ],
              [
                -48.9637273796083,
                -20.283204638685568
              ],
              [
                -48.96252241186596,
                -20.273012222244603
              ],
              [
                -48.96131744411173,
                -20.262817254833593
              ],
              [
                -48.97170602177187,
                -20.206993032310134
              ],
              [
                -48.98346099585586,
                -20.173699223888715
              ],
              [
                -48.9876194948389,
                -20.169308247247972
              ],
              [
                -48.99177794182177,
                -20.16491778160382
              ],
              [
                -49.018643782720225,
                -20.153042890796407
              ],
              [
                -49.023381743862934,
                -20.151857395610495
              ],
              [
                -49.02812318098456,
                -20.15067092743798
              ],
              [
                -49.05874897511783,
                -20.150633001849442
              ],
              [
                -49.06287791036628,
                -20.152450017022144
              ],
              [
                -49.067007395611604,
                -20.154266991199517
              ],
              [
                -49.06743938506586,
                -20.153973496692572
              ],
              [
                -49.08920806918368,
                -20.211279270018473
              ],
              [
                -49.120261175926586,
                -20.268832084175795
              ],
              [
                -49.12399412877574,
                -20.272863596112483
              ],
              [
                -49.12892507341299,
                -20.278189588147573
              ],
              [
                -49.13029556330664,
                -20.27966857883022
              ],
              [
                -49.17909107271511,
                -20.309699532438323
              ],
              [
                -49.190687006109606,
                -20.307826072444307
              ],
              [
                -49.21706028845841,
                -20.30323467369712
              ],
              [
                -49.21706831239693,
                -20.303234156720514
              ],
              [
                -49.21718680148711,
                -20.303227152062746
              ],
              [
                -49.238354193230165,
                -20.286567279747242
              ],
              [
                -49.25879158133167,
                -20.258111466287964
              ],
              [
                -49.259026599450856,
                -20.257782985427372
              ],
              [
                -49.25758762430252,
                -20.245984530495225
              ],
              [
                -49.26139910762722,
                -20.23942106694139
              ],
              [
                -49.26536963135295,
                -20.227850635079115
              ],
              [
                -49.265649101661,
                -20.227338110190374
              ],
              [
                -49.29877350250028,
                -20.166589983999494
              ],
              [
                -49.30532658301499,
                -20.116514248223048
              ],
              [
                -49.308058602251414,
                -20.101505818264375
              ],
              [
                -49.307236703608105,
                -20.07328697274824
              ],
              [
                -49.29608694765459,
                -20.016376713738673
              ],
              [
                -49.28981698505666,
                -20.010379703598243
              ],
              [
                -49.28354707345229,
                -20.00438320447177
              ],
              [
                -49.26876467007008,
                -20.005807172675674
              ],
              [
                -49.26205924371119,
                -20.006453180356946
              ],
              [
                -49.25496329479713,
                -20.00280968011686
              ],
              [
                -49.24786838287569,
                -19.999166664887735
              ],
              [
                -49.24654590453659,
                -19.982066221601283
              ],
              [
                -49.250249946338094,
                -19.970329799149717
              ],
              [
                -49.253649901554404,
                -19.966912814919656
              ],
              [
                -49.255898411081404,
                -19.96582482780731
              ],
              [
                -49.25797937968904,
                -19.964817371886078
              ],
              [
                -49.264696850457426,
                -19.961566895411792
              ],
              [
                -49.29706456745153,
                -19.96007246673887
              ],
              [
                -49.30539851053065,
                -19.96310046502188
              ],
              [
                -49.313729913636685,
                -19.966127968311326
              ],
              [
                -49.320258382586005,
                -19.970633952046764
              ],
              [
                -49.33396973006293,
                -19.98009744513008
              ],
              [
                -49.34498163510207,
                -19.983167468182057
              ],
              [
                -49.355993552148675,
                -19.98623897824217
              ],
              [
                -49.411333616040885,
                -19.98234311730783
              ],
              [
                -49.4399048948021,
                -19.98033221949433
              ],
              [
                -49.4488063393042,
                -19.977921229735234
              ],
              [
                -49.45770675482488,
                -19.97551077097003
              ],
              [
                -49.487009111252156,
                -19.954757932398156
              ],
              [
                -49.50300506146435,
                -19.919449145301716
              ],
              [
                -49.51329302390498,
                -19.915423682606626
              ],
              [
                -49.5235794153723,
                -19.91139873590175
              ],
              [
                -49.550991246269874,
                -19.90537334368515
              ],
              [
                -49.55777414990718,
                -19.910074301281007
              ],
              [
                -49.55872814357247,
                -19.910735814661056
              ],
              [
                -49.570091050788555,
                -19.910158829670877
              ],
              [
                -49.58167397734142,
                -19.909571358301502
              ],
              [
                -49.60547928253976,
                -19.91601391578864
              ],
              [
                -49.62618857794669,
                -19.9272733859539
              ],
              [
                -49.64073996686934,
                -19.931401911400343
              ],
              [
                -49.71172441814378,
                -19.930788589954414
              ],
              [
                -49.74908561336511,
                -19.92484821224868
              ],
              [
                -49.78197739572512,
                -19.923990771444032
              ],
              [
                -49.78731184658551,
                -19.925984305044565
              ],
              [
                -49.792643792472134,
                -19.927976279650284
              ],
              [
                -49.79845771445471,
                -19.928647279620808
              ],
              [
                -49.82964298832445,
                -19.93224884786114
              ],
              [
                -49.83478094979596,
                -19.93284235145148
              ],
              [
                -49.84495784431575,
                -19.93960985360529
              ],
              [
                -49.85509476622481,
                -19.946349827810614
              ],
              [
                -49.881072525628994,
                -19.944094901710383
              ],
              [
                -49.890803976756956,
                -19.94325045789497
              ],
              [
                -49.90451286525327,
                -19.938576017080024
              ],
              [
                -49.95963350673255,
                -19.92042571699217
              ],
              [
                -50.01170508353777,
                -19.926244316620988
              ],
              [
                -50.04341585247594,
                -19.91602544812409
              ],
              [
                -50.07633918925881,
                -19.88756163960816
              ],
              [
                -50.082617117576355,
                -19.883526684478163
              ],
              [
                -50.08992457888563,
                -19.878829706065122
              ],
              [
                -50.10232998311135,
                -19.875441246886698
              ],
              [
                -50.13841070576247,
                -19.87453885885279
              ],
              [
                -50.15921253915992,
                -19.877780881093276
              ],
              [
                -50.173101474694576,
                -19.87418792272039
              ],
              [
                -50.2000082525888,
                -19.86699004475509
              ],
              [
                -50.21020768972052,
                -19.867477045071954
              ],
              [
                -50.22040862685484,
                -19.867964079398316
              ],
              [
                -50.244674382069924,
                -19.876288104485003
              ],
              [
                -50.285148130116276,
                -19.86734922786657
              ],
              [
                -50.31137188145781,
                -19.87259875724234
              ],
              [
                -50.323680815143824,
                -19.870138299590533
              ],
              [
                -50.33599023285086,
                -19.867677359941883
              ],
              [
                -50.3523140929892,
                -19.86411741178444
              ],
              [
                -50.35587707989877,
                -19.85579946905875
              ],
              [
                -50.3566060802615,
                -19.854098956725387
              ],
              [
                -50.36566103864334,
                -19.84969651644182
              ],
              [
                -50.36772550673436,
                -19.848693534404944
              ],
              [
                -50.37241949176999,
                -19.844907548931207
              ],
              [
                -50.414026275012425,
                -19.803293333364895
              ],
              [
                -50.45400700743973,
                -19.785567504385966
              ],
              [
                -50.471423904724105,
                -19.779184579856892
              ],
              [
                -50.485699264112036,
                -19.78517858068964
              ],
              [
                -50.495939671562326,
                -19.793168600945094
              ],
              [
                -50.49870463614363,
                -19.79532559032149
              ],
              [
                -50.507632093996975,
                -19.79563010620598
              ],
              [
                -50.50763960393816,
                -19.795630585223368
              ],
              [
                -50.5165595098602,
                -19.79593511109218
              ],
              [
                -50.51732401696836,
                -19.796295116445922
              ],
              [
                -50.54667573834558,
                -19.810133623035714
              ],
              [
                -50.56154163408505,
                -19.813114657293735
              ],
              [
                -50.57550100736276,
                -19.815550646682205
              ],
              [
                -50.5806664481363,
                -19.82095965760159
              ],
              [
                -50.58583039392161,
                -19.826367134536383
              ],
              [
                -50.59082133623811,
                -19.833755629037103
              ],
              [
                -50.605758181566536,
                -19.85586555480564
              ],
              [
                -50.61023612249694,
                -19.85821854086399
              ],
              [
                -50.61603058235335,
                -19.861263552238757
              ],
              [
                -50.63569837920243,
                -19.874595544388566
              ],
              [
                -50.656408618264315,
                -19.904905938593018
              ],
              [
                -50.68292590380475,
                -19.91483995539601
              ],
              [
                -50.72462905901647,
                -19.926350520172697
              ],
              [
                -50.78311459786339,
                -19.936761129360082
              ],
              [
                -50.7883345130634,
                -19.93915612934556
              ],
              [
                -50.79355097529286,
                -19.941550603329446
              ],
              [
                -50.80304638540249,
                -19.94819959947405
              ],
              [
                -50.816847272844,
                -19.95786361746283
              ],
              [
                -50.82123920794023,
                -19.95938211123843
              ],
              [
                -50.84359254904009,
                -19.96711413857284
              ],
              [
                -50.852890442917634,
                -19.970972137836178
              ],
              [
                -50.86874627364852,
                -19.985738587545907
              ],
              [
                -50.87127077249209,
                -19.98808957968806
              ],
              [
                -50.873436763421736,
                -19.98829311050903
              ],
              [
                -50.88561317447478,
                -19.98943561962417
              ],
              [
                -50.89649859001184,
                -19.989682643341368
              ],
              [
                -50.90764399115436,
                -19.9937876404789
              ],
              [
                -50.90951692985254,
                -19.998196147705347
              ],
              [
                -50.91138940555201,
                -20.00260411193798
              ],
              [
                -50.927489292234704,
                -20.011368136415637
              ],
              [
                -50.93317022590231,
                -20.014460622656326
              ],
              [
                -50.935375676168476,
                -20.0171486274903
              ],
              [
                -50.94479708350691,
                -20.02862760319792
              ],
              [
                -50.9486745488724,
                -20.031045594692706
              ],
              [
                -50.94905554877991,
                -20.031112584405246
              ],
              [
                -50.96597241357196,
                -20.03405912618301
              ],
              [
                -50.99689354111299,
                -20.080291968298283
              ],
              [
                -50.99998499885325,
                -20.084914443950506
              ],
              [
                -51.00926746828095,
                -20.071557045159462
              ],
              [
                -51.017399981052804,
                -20.04792316708534
              ],
              [
                -51.01637400308546,
                -20.041125215648776
              ],
              [
                -51.01086208559356,
                -20.032829244972014
              ],
              [
                -51.00554614472221,
                -20.024829231518652
              ],
              [
                -51.00431722256845,
                -19.998748355636316
              ],
              [
                -51.01915270186571,
                -19.966807042619386
              ],
              [
                -51.0195311922441,
                -19.96436254654215
              ],
              [
                -51.019910185614634,
                -19.9619190584624
              ],
              [
                -51.011909787217164,
                -19.944381133652904
              ],
              [
                -51.00125143913646,
                -19.921016721970485
              ],
              [
                -51.00011750305526,
                -19.91346325678401
              ],
              [
                -50.99898302096884,
                -19.905909261608798
              ],
              [
                -51.01871896200595,
                -19.86706200072709
              ],
              [
                -51.01771004671077,
                -19.836813612678032
              ],
              [
                -51.02220460621063,
                -19.807028284162417
              ],
              [
                -51.031784068735185,
                -19.79338236861557
              ],
              [
                -51.03359557459165,
                -19.790802381961505
              ],
              [
                -51.03945103677566,
                -19.776650466899206
              ],
              [
                -51.027412209079095,
                -19.756109527587466
              ],
              [
                -51.04494161307425,
                -19.73709067307667
              ],
              [
                -51.045454639069405,
                -19.728583704674264
              ],
              [
                -51.04218217905114,
                -19.724402195904165
              ],
              [
                -51.03800671723372,
                -19.719066715966743
              ],
              [
                -51.03608579967799,
                -19.686546345341114
              ],
              [
                -51.02135199658965,
                -19.65995944866276
              ],
              [
                -51.01574204271429,
                -19.655601941668888
              ],
              [
                -51.01239558759573,
                -19.6530029546584
              ],
              [
                -51.0111026064367,
                -19.650232453784913
              ],
              [
                -51.004848700734165,
                -19.636823526995876
              ],
              [
                -50.98997737580666,
                -19.60493762132255
              ],
              [
                -50.98706743788859,
                -19.589040190653957
              ],
              [
                -50.97052858138471,
                -19.58167467598291
              ],
              [
                -50.962381642322114,
                -19.58123767978526
              ],
              [
                -50.951915208045655,
                -19.587639098589566
              ],
              [
                -50.946054744054,
                -19.591223072122727
              ],
              [
                -50.93709032927267,
                -19.59180307519219
              ],
              [
                -50.927510387777545,
                -19.58733303654938
              ],
              [
                -50.922641994600625,
                -19.57559560611193
              ],
              [
                -50.923679498138284,
                -19.558150695926866
              ],
              [
                -50.92703601934476,
                -19.549964718774326
              ],
              [
                -50.93039351253603,
                -19.541778783627
              ],
              [
                -50.93825996044793,
                -19.53155682440991
              ],
              [
                -50.96245886887277,
                -19.500113049823483
              ],
              [
                -50.962219414285684,
                -19.4922605768579
              ],
              [
                -50.96197991368495,
                -19.48440911589686
              ],
              [
                -50.95741544914641,
                -19.479566634090144
              ],
              [
                -50.948952558992566,
                -19.47058713202048
              ],
              [
                -50.935323155835626,
                -19.467213118144013
              ],
              [
                -50.84169181884167,
                -19.49868524299314
              ],
              [
                -50.827063920483475,
                -19.487649266509276
              ],
              [
                -50.82620495885921,
                -19.487001272246104
              ],
              [
                -50.8244579777651,
                -19.474574322406063
              ],
              [
                -50.83831540966806,
                -19.45713396369672
              ],
              [
                -50.86809578774662,
                -19.433458137446728
              ],
              [
                -50.87148273195133,
                -19.427914676458332
              ],
              [
                -50.87487022615279,
                -19.422370170476125
              ],
              [
                -50.87190879835955,
                -19.405663751091243
              ],
              [
                -50.86602638698587,
                -19.398641770019005
              ],
              [
                -50.86014442259834,
                -19.391620786962285
              ],
              [
                -50.837397710391414,
                -19.345154938051483
              ],
              [
                -50.836298750920236,
                -19.335241506622673
              ],
              [
                -50.835733272703465,
                -19.330142528525126
              ],
              [
                -50.8353467795456,
                -19.326655053508638
              ],
              [
                -50.82848788684456,
                -19.309994091291415
              ],
              [
                -50.816637524171874,
                -19.289362663599615
              ],
              [
                -50.788288303898895,
                -19.262221249837715
              ],
              [
                -50.779635406213714,
                -19.26055423442639
              ],
              [
                -50.77098195654014,
                -19.258887689018618
              ],
              [
                -50.7472401990654,
                -19.235358753787942
              ],
              [
                -50.74556673692545,
                -19.226084302240167
              ],
              [
                -50.7434758094551,
                -19.214496853304592
              ],
              [
                -50.74240134678545,
                -19.19774743877523
              ],
              [
                -50.73320094667517,
                -19.185626964303058
              ],
              [
                -50.732984437120486,
                -19.18534144807048
              ],
              [
                -50.68017286330864,
                -19.172734901284034
              ],
              [
                -50.67610791450631,
                -19.167984926936025
              ],
              [
                -50.672042975696435,
                -19.16323641459009
              ],
              [
                -50.674962468467264,
                -19.149711998536546
              ],
              [
                -50.67722549967765,
                -19.13922703877354
              ],
              [
                -50.67418353320618,
                -19.13728655205404
              ],
              [
                -50.67114253572887,
                -19.13534555934175
              ],
              [
                -50.66736508716959,
                -19.1350730420454
              ],
              [
                -50.645110757656084,
                -19.133467020188533
              ],
              [
                -50.58454368825541,
                -19.136924834049122
              ],
              [
                -50.578277761779844,
                -19.128744368177585
              ],
              [
                -50.572011822294996,
                -19.12056339233242
              ],
              [
                -50.54900853758599,
                -19.108271882391264
              ],
              [
                -50.54319259650431,
                -19.10516439817062
              ],
              [
                -50.540118622570986,
                -19.102053416884992
              ],
              [
                -50.540115651599166,
                -19.102051409886865
              ],
              [
                -50.5370396616842,
                -19.09893892760733
              ],
              [
                -50.52234235412567,
                -19.07647097513617
              ],
              [
                -50.50162007048927,
                -19.03954962707315
              ],
              [
                -50.498262146662235,
                -19.03356464855599
              ],
              [
                -50.49698617179564,
                -19.02620515868686
              ],
              [
                -50.49459371027265,
                -19.01240474009077
              ],
              [
                -50.4991856912594,
                -19.008098761704403
              ],
              [
                -50.50353866544303,
                -19.004017784667038
              ],
              [
                -50.49956774021683,
                -18.98069888661729
              ],
              [
                -50.51139523794983,
                -18.951501055655346
              ],
              [
                -50.50866232086267,
                -18.93696510578949
              ],
              [
                -50.499825868403555,
                -18.929231618894917
              ],
              [
                -50.47298109793198,
                -18.92041761415229
              ],
              [
                -50.46929563046518,
                -18.91842559528898
              ],
              [
                -50.46928815953302,
                -18.91842160528906
              ],
              [
                -50.465595672131144,
                -18.9164256164298
              ],
              [
                -50.44109392643866,
                -18.89032269175893
              ],
              [
                -50.42411571037574,
                -18.835752909858726
              ],
              [
                -50.42065624954426,
                -18.830699937034034
              ],
              [
                -50.41361981731297,
                -18.826242439543723
              ],
              [
                -50.40207338656686,
                -18.82392740080009
              ],
              [
                -50.39378498123542,
                -18.82226540907198
              ],
              [
                -50.3850215671295,
                -18.81709139871165
              ],
              [
                -50.37625861602069,
                -18.81191791236835
              ],
              [
                -50.35184445604494,
                -18.759833584632837
              ],
              [
                -50.33098817155895,
                -18.724667197356343
              ],
              [
                -50.3234912749941,
                -18.712025759689794
              ],
              [
                -50.316185337709996,
                -18.705006300926218
              ],
              [
                -50.30888044641243,
                -18.69798678118753
              ],
              [
                -50.28607561678625,
                -18.689675780485572
              ],
              [
                -50.269481264709945,
                -18.683627795383227
              ],
              [
                -50.25209791811747,
                -18.678156751647496
              ],
              [
                -50.2497829227501,
                -18.67742825142134
              ],
              [
                -50.23506403187948,
                -18.67861022167905
              ],
              [
                -50.217009683783076,
                -18.68005969148554
              ],
              [
                -50.20770575377305,
                -18.679338154083528
              ],
              [
                -50.1984023307691,
                -18.678617163685526
              ],
              [
                -50.163810621782396,
                -18.663888618930038
              ],
              [
                -50.07956775577683,
                -18.6717809150594
              ],
              [
                -50.07044884996971,
                -18.6608659588114
              ],
              [
                -50.06379744730805,
                -18.652905468536638
              ],
              [
                -50.05328854150327,
                -18.645564971901436
              ],
              [
                -50.04972757645347,
                -18.643078006080056
              ],
              [
                -50.04489461961747,
                -18.630736546239074
              ],
              [
                -50.03695672679939,
                -18.61046559577991
              ],
              [
                -50.02828533756593,
                -18.601003623908735
              ],
              [
                -50.02804684247929,
                -18.600971158483013
              ],
              [
                -50.02648534299595,
                -18.60076264067651
              ],
              [
                -50.01611344514537,
                -18.599376643044312
              ],
              [
                -49.98097767648283,
                -18.60418253146801
              ],
              [
                -49.97583121947123,
                -18.610700463713773
              ],
              [
                -49.973340707112435,
                -18.613854444540454
              ],
              [
                -49.96255178568299,
                -18.616148432273256
              ],
              [
                -49.93117802354338,
                -18.623529796491137
              ],
              [
                -49.9175371148149,
                -18.622185781721697
              ],
              [
                -49.90389723809986,
                -18.62084275096244
              ],
              [
                -49.90339424077351,
                -18.620539277194286
              ],
              [
                -49.888226377678464,
                -18.61139979815705
              ],
              [
                -49.88460190146669,
                -18.60991975944925
              ],
              [
                -49.88132194048773,
                -18.609607278974412
              ],
              [
                -49.87702397914467,
                -18.61085875451429
              ],
              [
                -49.87116950388586,
                -18.613184250110876
              ],
              [
                -49.86967601542259,
                -18.616897687644684
              ],
              [
                -49.86761953352604,
                -18.622014694069648
              ],
              [
                -49.85589708717076,
                -18.622540635031893
              ],
              [
                -49.85231614625274,
                -18.6227016335498
              ],
              [
                -49.849079669080695,
                -18.625332616039664
              ],
              [
                -49.835678725907364,
                -18.636227540102496
              ],
              [
                -49.827498317294925,
                -18.634170523086002
              ],
              [
                -49.81931884367961,
                -18.632114544074398
              ],
              [
                -49.794314537228374,
                -18.643228930833374
              ],
              [
                -49.783217141886446,
                -18.640880387141873
              ],
              [
                -49.77705669215426,
                -18.6304604203002
              ],
              [
                -49.772385281457744,
                -18.614981992461995
              ],
              [
                -49.76980779033826,
                -18.612342518915302
              ],
              [
                -49.762770358687035,
                -18.610396004210642
              ],
              [
                -49.75676742531588,
                -18.60873549764392
              ],
              [
                -49.749993929948566,
                -18.61154549067071
              ],
              [
                -49.741172512084795,
                -18.615205444630785
              ],
              [
                -49.735664062914594,
                -18.615422414219893
              ],
              [
                -49.730917604269685,
                -18.61287094200335
              ],
              [
                -49.72362516788326,
                -18.608950406218767
              ],
              [
                -49.70520436198883,
                -18.594607943763393
              ],
              [
                -49.69306345075484,
                -18.59113894110526
              ],
              [
                -49.686448006443776,
                -18.589741949833673
              ],
              [
                -49.6692601145732,
                -18.589863399641207
              ],
              [
                -49.6617301800869,
                -18.593817879309015
              ],
              [
                -49.647387783608295,
                -18.60135027619249
              ],
              [
                -49.639647835531655,
                -18.59975878114925
              ],
              [
                -49.63506491102745,
                -18.589555848389598
              ],
              [
                -49.64010288456784,
                -18.584145382738935
              ],
              [
                -49.65200279279359,
                -18.57136696630861
              ],
              [
                -49.650525316882906,
                -18.563953471595944
              ],
              [
                -49.647524381752575,
                -18.55840551418737
              ],
              [
                -49.627773549724715,
                -18.552118483926698
              ],
              [
                -49.587949356370046,
                -18.544410963190735
              ],
              [
                -49.58136594054965,
                -18.54313743849238
              ],
              [
                -49.57478298273193,
                -18.541863446801543
              ],
              [
                -49.57471246623263,
                -18.54187990055175
              ],
              [
                -49.55756359697795,
                -18.5458948447414
              ],
              [
                -49.549584688615035,
                -18.540975891383376
              ],
              [
                -49.54798671169544,
                -18.53805239972337
              ],
              [
                -49.5472407200698,
                -18.5366873775524
              ],
              [
                -49.543045248773474,
                -18.529014893699603
              ],
              [
                -49.541921255891204,
                -18.526959448975134
              ],
              [
                -49.54302329627074,
                -18.52208644429371
              ],
              [
                -49.546151287693995,
                -18.50825101902625
              ],
              [
                -49.547352776464386,
                -18.50293456574649
              ],
              [
                -49.535369920734695,
                -18.493182598343385
              ],
              [
                -49.49715868185517,
                -18.493194997501625
              ],
              [
                -49.494065724364646,
                -18.495617478285215
              ],
              [
                -49.4923837270579,
                -18.49611197583958
              ],
              [
                -49.49038026104927,
                -18.499330443364897
              ],
              [
                -49.485817778965675,
                -18.50779738320261
              ],
              [
                -49.48584772668241,
                -18.525233320452852
              ],
              [
                -49.48585919232835,
                -18.531765298714912
              ],
              [
                -49.485253158538086,
                -18.544044221559055
              ],
              [
                -49.48135767191906,
                -18.560566639879106
              ],
              [
                -49.43193292610465,
                -18.606279278609225
              ],
              [
                -49.41625997497205,
                -18.63808560026808
              ],
              [
                -49.40815003314285,
                -18.64434503227475
              ],
              [
                -49.39425615288093,
                -18.645811032583634
              ],
              [
                -49.3782842590945,
                -18.64188100310161
              ],
              [
                -49.37284983373649,
                -18.63189653652241
              ],
              [
                -49.37052786906215,
                -18.627629560669888
              ],
              [
                -49.364686444824315,
                -18.616894606802084
              ],
              [
                -49.34274814020166,
                -18.597534657194643
              ],
              [
                -49.32979932351553,
                -18.565055285359758
              ],
              [
                -49.32594988686031,
                -18.562974279259958
              ],
              [
                -49.32210141119562,
                -18.560894268160876
              ],
              [
                -49.302512045715055,
                -18.56597069432801
              ],
              [
                -49.29212662969184,
                -18.5635436842305
              ],
              [
                -49.286757230195285,
                -18.540481284986964
              ],
              [
                -49.28385525699593,
                -18.53657879309784
              ],
              [
                -49.27122038155229,
                -18.527270318317196
              ],
              [
                -49.248630051223174,
                -18.522200821003302
              ],
              [
                -49.24362713911117,
                -18.505830889160045
              ],
              [
                -49.22795534920044,
                -18.479390961235946
              ],
              [
                -49.21506198349714,
                -18.466350521403644
              ],
              [
                -49.2148679662893,
                -18.46624151347544
              ],
              [
                -49.200906578261936,
                -18.458394501676473
              ],
              [
                -49.19262418456998,
                -18.447322075295627
              ],
              [
                -49.19637719845294,
                -18.43813510535899
              ],
              [
                -49.199262679932744,
                -18.4351186290486
              ],
              [
                -49.21081109382281,
                -18.4230462028492
              ],
              [
                -49.21079313833267,
                -18.417928757946395
              ],
              [
                -49.205197675007376,
                -18.4113292430602
              ],
              [
                -49.189390274079365,
                -18.415032190254177
              ],
              [
                -49.18540883256539,
                -18.415964680198684
              ],
              [
                -49.15767755562057,
                -18.413424669744067
              ],
              [
                -49.15589856896991,
                -18.40785615691649
              ],
              [
                -49.15411858032125,
                -18.402287710093166
              ],
              [
                -49.12702033539715,
                -18.382909242204352
              ],
              [
                -49.10833999639468,
                -18.38338170656646
              ],
              [
                -49.1067185029618,
                -18.388393182012276
              ],
              [
                -49.10953594350948,
                -18.394364131263277
              ],
              [
                -49.11205293104364,
                -18.399699107944464
              ],
              [
                -49.10947594379975,
                -18.40172709783353
              ],
              [
                -49.09759303961613,
                -18.403108553817713
              ],
              [
                -49.096428534320786,
                -18.403244061385696
              ],
              [
                -49.09572153260441,
                -18.40447855562249
              ],
              [
                -49.09079054752327,
                -18.413090527468345
              ],
              [
                -49.08149061663776,
                -18.412798497826348
              ],
              [
                -49.076573648731035,
                -18.416315965811634
              ],
              [
                -49.06873422819964,
                -18.411532466787374
              ],
              [
                -49.0532953698925,
                -18.40211147863263
              ],
              [
                -49.041533537224524,
                -18.3760455994553
              ],
              [
                -49.03240764342576,
                -18.371750073072906
              ],
              [
                -49.023282185627025,
                -18.367455065704736
              ],
              [
                -49.010598812478314,
                -18.372048027986814
              ],
              [
                -49.00549731442063,
                -18.373894500785738
              ],
              [
                -49.00051338468047,
                -18.372185037447167
              ],
              [
                -48.99552940993775,
                -18.370477499102577
              ],
              [
                -48.99068847058532,
                -18.359322039847196
              ],
              [
                -48.99015597991476,
                -18.358095073660426
              ],
              [
                -48.989660974078426,
                -18.35797657107685
              ],
              [
                -48.98103405564478,
                -18.355912041907487
              ],
              [
                -48.982381550540815,
                -18.348426588149177
              ],
              [
                -48.98372806641427,
                -18.34094963035203
              ],
              [
                -48.97642415549797,
                -18.339722623184212
              ],
              [
                -48.975125169183855,
                -18.339504617197424
              ],
              [
                -48.9687637362619,
                -18.32887364485548
              ],
              [
                -48.95903729321077,
                -18.323014179958072
              ],
              [
                -48.93630953797792,
                -18.305743689066553
              ],
              [
                -48.91728817714526,
                -18.30541166292846
              ],
              [
                -48.915879677552866,
                -18.306797662730727
              ],
              [
                -48.90664776710877,
                -18.315882072887412
              ],
              [
                -48.88948937938639,
                -18.31765753166686
              ],
              [
                -48.882426934610585,
                -18.31838803741031
              ],
              [
                -48.87735645611936,
                -18.32538498471329
              ],
              [
                -48.872286449623026,
                -18.332381920016555
              ],
              [
                -48.831620744848756,
                -18.345670287199045
              ],
              [
                -48.827561221290914,
                -18.374783647982504
              ],
              [
                -48.82172677217735,
                -18.37698812526951
              ],
              [
                -48.815893308061085,
                -18.379192602557733
              ],
              [
                -48.805874891615595,
                -18.367498628038376
              ],
              [
                -48.80039147002284,
                -18.361098144932846
              ],
              [
                -48.78316714313552,
                -18.35085613830587
              ],
              [
                -48.77296669649251,
                -18.354111606092705
              ],
              [
                -48.76972722815705,
                -18.355145600320704
              ],
              [
                -48.76691823076448,
                -18.35296161757755
              ],
              [
                -48.75528084613129,
                -18.343915614230035
              ],
              [
                -48.742713950978235,
                -18.34836059653172
              ],
              [
                -48.73014702784632,
                -18.352806514824135
              ],
              [
                -48.716071659580216,
                -18.347546006936035
              ],
              [
                -48.708117248511904,
                -18.344573532054852
              ],
              [
                -48.70270028631452,
                -18.347136980541777
              ],
              [
                -48.697283328121145,
                -18.34970047602714
              ],
              [
                -48.69153137077448,
                -18.347135491299266
              ],
              [
                -48.68241193467354,
                -18.343068469159288
              ],
              [
                -48.667998538697375,
                -18.344962428852075
              ],
              [
                -48.64670722947935,
                -18.343427910514684
              ],
              [
                -48.64062878546094,
                -18.33979842237188
              ],
              [
                -48.64108876945198,
                -18.33295992961013
              ],
              [
                -48.633362362865846,
                -18.327183460347992
              ],
              [
                -48.59681015304474,
                -18.334347323825206
              ],
              [
                -48.58190326446641,
                -18.32676384382108
              ],
              [
                -48.57326181980508,
                -18.325385312474932
              ],
              [
                -48.56095246144127,
                -18.32342079647912
              ],
              [
                -48.55328448033533,
                -18.330932273912627
              ],
              [
                -48.553748454420926,
                -18.339991205650822
              ],
              [
                -48.55408095512568,
                -18.34648269894135
              ],
              [
                -48.54922498699435,
                -18.34969217249548
              ],
              [
                -48.54277853678555,
                -18.350011154786078
              ],
              [
                -48.53065013247926,
                -18.350611117353072
              ],
              [
                -48.5151861884968,
                -18.365950984298973
              ],
              [
                -48.49231440775636,
                -18.352510520012068
              ],
              [
                -48.484346969689895,
                -18.35316552006684
              ],
              [
                -48.48096199126185,
                -18.360184451960375
              ],
              [
                -48.48208946411344,
                -18.36340846644633
              ],
              [
                -48.485429914291984,
                -18.372961919414383
              ],
              [
                -48.4791829412603,
                -18.379235342341776
              ],
              [
                -48.45373772229852,
                -18.360965377273853
              ],
              [
                -48.44519979618713,
                -18.354835416028713
              ],
              [
                -48.44065632907756,
                -18.35983836629499
              ],
              [
                -48.43611281696644,
                -18.364842332554815
              ],
              [
                -48.421731960280034,
                -18.361660318851744
              ],
              [
                -48.4190619948049,
                -18.3610698355658
              ],
              [
                -48.41301302598587,
                -18.355778340972183
              ],
              [
                -48.40525858175483,
                -18.35587883532632
              ],
              [
                -48.404366088304485,
                -18.362298795004026
              ],
              [
                -48.40319609689275,
                -18.370720228989967
              ],
              [
                -48.400034086009725,
                -18.371410710121634
              ],
              [
                -48.35315200609876,
                -18.365606145589094
              ],
              [
                -48.34206505795384,
                -18.37047811217329
              ],
              [
                -48.31317324502378,
                -18.384374456734474
              ],
              [
                -48.304228840950174,
                -18.38265898444362
              ],
              [
                -48.311764304140944,
                -18.375795508585703
              ],
              [
                -48.31528177462814,
                -18.372591045927162
              ],
              [
                -48.31448828469809,
                -18.36369157384309
              ],
              [
                -48.287837547459716,
                -18.348216114517783
              ],
              [
                -48.27861465305179,
                -18.33106716741393
              ],
              [
                -48.27179519746472,
                -18.33121265878503
              ],
              [
                -48.27049772162952,
                -18.33123967962473
              ],
              [
                -48.26194881460884,
                -18.331418125801314
              ],
              [
                -48.254942831942486,
                -18.343523063438106
              ],
              [
                -48.226358039997905,
                -18.345300003842656
              ],
              [
                -48.225456030808196,
                -18.350115966446186
              ],
              [
                -48.226727502762806,
                -18.357157923893926
              ],
              [
                -48.22049856202141,
                -18.361817892865876
              ],
              [
                -48.19551324845343,
                -18.363729326957856
              ],
              [
                -48.17243038429978,
                -18.374493231812956
              ],
              [
                -48.17066492332015,
                -18.374075718803397
              ],
              [
                -48.16090048039412,
                -18.37176920568157
              ],
              [
                -48.15951546412858,
                -18.38240767574535
              ],
              [
                -48.15930446465224,
                -18.384027168078664
              ],
              [
                -48.15034303111701,
                -18.394724596178474
              ],
              [
                -48.1473385025218,
                -18.407745487457554
              ],
              [
                -48.128236145326746,
                -18.4156589169197
              ],
              [
                -48.11417025485604,
                -18.426097330387087
              ],
              [
                -48.09526489018524,
                -18.428162778805756
              ],
              [
                -48.073576554784005,
                -18.42664525883292
              ],
              [
                -48.067235101907805,
                -18.424730237707713
              ],
              [
                -48.064343672121296,
                -18.419140784207535
              ],
              [
                -48.06095418773847,
                -18.412588296423124
              ],
              [
                -48.05536674550621,
                -18.412777779435963
              ],
              [
                -48.0475857926074,
                -18.41650223846012
              ],
              [
                -48.04378679687545,
                -18.423857191411496
              ],
              [
                -48.03892180629962,
                -18.433275669445177
              ],
              [
                -48.02824538730567,
                -18.435851108511056
              ],
              [
                -48.01315049548987,
                -18.444228524924892
              ],
              [
                -48.00950950245504,
                -18.452383499190827
              ],
              [
                -48.00602404525119,
                -18.453880978481834
              ],
              [
                -47.99745461556831,
                -18.454128446224665
              ],
              [
                -47.99708311217063,
                -18.453872435652148
              ],
              [
                -47.980452262475985,
                -18.442393978919373
              ],
              [
                -47.97559529302919,
                -18.45214491824336
              ],
              [
                -47.97831873117395,
                -18.459266873565046
              ],
              [
                -47.9794502416163,
                -18.462224384245555
              ],
              [
                -47.97875420909825,
                -18.475496317497996
              ],
              [
                -47.975924207883615,
                -18.4773152896886
              ],
              [
                -47.968631775535954,
                -18.474278769010215
              ],
              [
                -47.9645013126077,
                -18.476892293143415
              ],
              [
                -47.9617392804933,
                -18.495605162059427
              ],
              [
                -47.95448133529035,
                -18.4998146108074
              ],
              [
                -47.95219786596058,
                -18.493372655043988
              ],
              [
                -47.94807989866266,
                -18.491420174294955
              ],
              [
                -47.94009349977938,
                -18.487635650780046
              ],
              [
                -47.93876598540122,
                -18.487603672840766
              ],
              [
                -47.92909409478563,
                -18.487374639408202
              ],
              [
                -47.926029126858886,
                -18.474999676893923
              ],
              [
                -47.92498065603087,
                -18.470767739875114
              ],
              [
                -47.91939869332345,
                -18.46843920964405
              ],
              [
                -47.913817261613794,
                -18.466110713419223
              ],
              [
                -47.904154792756565,
                -18.470250158911806
              ],
              [
                -47.88972993052517,
                -18.476429591955558
              ],
              [
                -47.88742596356969,
                -18.472237617825655
              ],
              [
                -47.885121477616586,
                -18.468045142702103
              ],
              [
                -47.88198299385592,
                -18.470271109101404
              ],
              [
                -47.87503251246532,
                -18.47520157790196
              ],
              [
                -47.86772560479812,
                -18.47392959131386
              ],
              [
                -47.86278716496547,
                -18.469276080399972
              ],
              [
                -47.863725669274615,
                -18.46389563887578
              ],
              [
                -47.86396615270087,
                -18.463763636105682
              ],
              [
                -47.872830064734764,
                -18.458884684503328
              ],
              [
                -47.87153460307739,
                -18.45573868744227
              ],
              [
                -47.865177665168616,
                -18.452387672636867
              ],
              [
                -47.8640876815993,
                -18.448958205499903
              ],
              [
                -47.86006821979964,
                -18.442598210420467
              ],
              [
                -47.8583627312593,
                -18.43989575116929
              ],
              [
                -47.84900480920195,
                -18.440851722543016
              ],
              [
                -47.84574434897087,
                -18.444042686755758
              ],
              [
                -47.8383368683433,
                -18.451292118711553
              ],
              [
                -47.83148295589577,
                -18.449315134800038
              ],
              [
                -47.83119445379354,
                -18.442031649119603
              ],
              [
                -47.836585440957165,
                -18.435361704536433
              ],
              [
                -47.83245697965874,
                -18.428357256522926
              ],
              [
                -47.83031752393037,
                -18.42472726999614
              ],
              [
                -47.82943702095199,
                -18.41295332379399
              ],
              [
                -47.81487466599207,
                -18.40579230939948
              ],
              [
                -47.80867121604504,
                -18.40040483741162
              ],
              [
                -47.79742631576424,
                -18.40697275290387
              ],
              [
                -47.79922678419551,
                -18.409818241603965
              ],
              [
                -47.79961226257668,
                -18.41042777639748
              ],
              [
                -47.79804127127984,
                -18.415842709203822
              ],
              [
                -47.78875785070055,
                -18.417191691791576
              ],
              [
                -47.77856340632609,
                -18.423665667186707
              ],
              [
                -47.770280517945444,
                -18.41789566732656
              ],
              [
                -47.76736303233974,
                -18.415864142898364
              ],
              [
                -47.75866961194756,
                -18.40981069755558
              ],
              [
                -47.74794669008545,
                -18.4110786412328
              ],
              [
                -47.74742017611834,
                -18.411141149693588
              ],
              [
                -47.747371699073085,
                -18.410131662723344
              ],
              [
                -47.747141732168004,
                -18.40531118374724
              ],
              [
                -47.75593317264822,
                -18.394510745148487
              ],
              [
                -47.74289079798483,
                -18.37698979925334
              ],
              [
                -47.73575436706679,
                -18.378460308238616
              ],
              [
                -47.73283740697954,
                -18.37543178393497
              ],
              [
                -47.74011638362514,
                -18.363373853280624
              ],
              [
                -47.71639954289782,
                -18.36162434437524
              ],
              [
                -47.71393756757919,
                -18.36829930961297
              ],
              [
                -47.710749087047624,
                -18.37694273712753
              ],
              [
                -47.70192265896588,
                -18.378673211881114
              ],
              [
                -47.69812519103927,
                -18.37677320579704
              ],
              [
                -47.685651317047274,
                -18.36232776573054
              ],
              [
                -47.68585932038615,
                -18.359171803333062
              ],
              [
                -47.686003307766455,
                -18.35698980481298
              ],
              [
                -47.67892689085114,
                -18.355923804917765
              ],
              [
                -47.67025244722915,
                -18.357009268341166
              ],
              [
                -47.65711962895096,
                -18.327338885772672
              ],
              [
                -47.652732143857094,
                -18.325780900633653
              ],
              [
                -47.64648318640352,
                -18.32984333144349
              ],
              [
                -47.64616668854385,
                -18.33948982535363
              ],
              [
                -47.6432012008084,
                -18.338056805848435
              ],
              [
                -47.632853272947806,
                -18.333057291583394
              ],
              [
                -47.625732834773395,
                -18.333830290218444
              ],
              [
                -47.6238108965526,
                -18.322168336505914
              ],
              [
                -47.610808505527636,
                -18.314924366682845
              ],
              [
                -47.62685992942232,
                -18.302675471378006
              ],
              [
                -47.6275964264724,
                -18.301119963042453
              ],
              [
                -47.627680897249,
                -18.300941448151466
              ],
              [
                -47.63231589896168,
                -18.291156527952726
              ],
              [
                -47.638317840060495,
                -18.28721106797181
              ],
              [
                -47.62945346998064,
                -18.276783605048948
              ],
              [
                -47.618567070706455,
                -18.271245095446492
              ],
              [
                -47.62476004760297,
                -18.2600071643115
              ],
              [
                -47.61883057730346,
                -18.25466366312122
              ],
              [
                -47.61411964352913,
                -18.25041770110541
              ],
              [
                -47.61469011735726,
                -18.260215130133478
              ],
              [
                -47.614330628855036,
                -18.260361627556755
              ],
              [
                -47.60684515084413,
                -18.263422127673284
              ],
              [
                -47.60280871151348,
                -18.2572626490253
              ],
              [
                -47.60492020987692,
                -18.25121316992316
              ],
              [
                -47.60653320362822,
                -18.246590191356308
              ],
              [
                -47.59033486903424,
                -18.235339707014777
              ],
              [
                -47.58843988351537,
                -18.235192704429092
              ],
              [
                -47.58134492548458,
                -18.23464120001165
              ],
              [
                -47.58100741770911,
                -18.231239242722165
              ],
              [
                -47.58302243593085,
                -18.22992622608454
              ],
              [
                -47.5881693882951,
                -18.226571755109717
              ],
              [
                -47.58077097258111,
                -18.22366674910376
              ],
              [
                -47.576035511665616,
                -18.22553573565619
              ],
              [
                -47.570501573030434,
                -18.21986027217249
              ],
              [
                -47.5671166402574,
                -18.203396827114858
              ],
              [
                -47.55728419125324,
                -18.207567780176184
              ],
              [
                -47.538270849580336,
                -18.194526834864675
              ],
              [
                -47.534118386661135,
                -18.198149285725922
              ],
              [
                -47.5380718358096,
                -18.211461229220113
              ],
              [
                -47.53967729246106,
                -18.21686869105811
              ],
              [
                -47.538735294872495,
                -18.227292641263542
              ],
              [
                -47.53328832696534,
                -18.230239618639033
              ],
              [
                -47.5270394010246,
                -18.225777656306803
              ],
              [
                -47.53019685984464,
                -18.217381184745
              ],
              [
                -47.517565510819075,
                -18.210131223205938
              ],
              [
                -47.517693017600976,
                -18.202214247275318
              ],
              [
                -47.51775451662121,
                -18.19840975801477
              ],
              [
                -47.504786647675076,
                -18.191796774490527
              ],
              [
                -47.5017376535583,
                -18.194383245200964
              ],
              [
                -47.496727175946646,
                -18.19863421385458
              ],
              [
                -47.48944875573359,
                -18.191964234630976
              ],
              [
                -47.48448078266893,
                -18.193062220635696
              ],
              [
                -47.485105824177374,
                -18.18765526194474
              ],
              [
                -47.485263306310905,
                -18.18629676631015
              ],
              [
                -47.4854223024645,
                -18.184925279746274
              ],
              [
                -47.479535891859854,
                -18.176756794464396
              ],
              [
                -47.47101243888938,
                -18.17958374345191
              ],
              [
                -47.468115461308145,
                -18.175864285044703
              ],
              [
                -47.46350600356203,
                -18.17689476622345
              ],
              [
                -47.45078665951071,
                -18.15870535118069
              ],
              [
                -47.44409120461867,
                -18.158460822207747
              ],
              [
                -47.435425737282806,
                -18.167128767705893
              ],
              [
                -47.42958931336862,
                -18.16534724062737
              ],
              [
                -47.426895331132684,
                -18.160836763806095
              ],
              [
                -47.434917785202714,
                -18.151519833206795
              ],
              [
                -47.42833687438598,
                -18.138818885907245
              ],
              [
                -47.417902452276664,
                -18.14023037689763
              ],
              [
                -47.41303349392855,
                -18.145970311146424
              ],
              [
                -47.411955501386124,
                -18.147241304123003
              ],
              [
                -47.407535033092586,
                -18.14106032004997
              ],
              [
                -47.396421614111375,
                -18.141398288055974
              ],
              [
                -47.396090155910144,
                -18.1369113385227
              ],
              [
                -47.40501055878844,
                -18.134817354616107
              ],
              [
                -47.40502108775524,
                -18.13479684674616
              ],
              [
                -47.40687105268797,
                -18.131285391117384
              ],
              [
                -47.40074460076147,
                -18.128831871905863
              ],
              [
                -47.39537065607211,
                -18.126679860847435
              ],
              [
                -47.39471067514008,
                -18.12313838268412
              ],
              [
                -47.393338727378435,
                -18.115775944694107
              ],
              [
                -47.38872971384785,
                -18.118752425825527
              ],
              [
                -47.385643249584454,
                -18.12074537950318
              ],
              [
                -47.38353327205639,
                -18.11727938766824
              ],
              [
                -47.36907092290113,
                -18.114788895777043
              ],
              [
                -47.36548193204861,
                -18.107349913194092
              ],
              [
                -47.35542856117057,
                -18.104645929434103
              ],
              [
                -47.354258569065735,
                -18.09635396478129
              ],
              [
                -47.35775105191426,
                -18.09603596133836
              ],
              [
                -47.360932517181695,
                -18.09574647604347
              ],
              [
                -47.35734558856929,
                -18.084556507958702
              ],
              [
                -47.34642614796099,
                -18.087774482555176
              ],
              [
                -47.342975209950595,
                -18.080343525306734
              ],
              [
                -47.322318405722214,
                -18.06922451333422
              ],
              [
                -47.31041399052072,
                -18.069014510528763
              ],
              [
                -47.30989696668153,
                -18.069005500407577
              ],
              [
                -47.30198455049317,
                -18.073268953381582
              ],
              [
                -47.3045825253925,
                -18.06488652380543
              ],
              [
                -47.305095026371404,
                -18.063233024555338
              ],
              [
                -47.302403052737155,
                -18.063307534089287
              ],
              [
                -47.292008118249825,
                -18.063595007124658
              ],
              [
                -47.28275624270597,
                -18.05776850353486
              ],
              [
                -47.28305324133491,
                -18.051277026964318
              ],
              [
                -47.283546744988946,
                -18.04048158323246
              ],
              [
                -47.28630775336985,
                -18.042313101929864
              ],
              [
                -47.286318750264606,
                -18.042320084918327
              ],
              [
                -47.29261921387524,
                -18.029714199702102
              ],
              [
                -47.3003341428954,
                -18.026556718515735
              ],
              [
                -47.29528519936733,
                -18.026126676375682
              ],
              [
                -47.296163222662535,
                -18.012585252823218
              ],
              [
                -47.29639975076241,
                -18.00892878138828
              ],
              [
                -47.30017420966257,
                -18.008071327348958
              ],
              [
                -47.31109309638601,
                -18.00559084584158
              ],
              [
                -47.315145611174614,
                -17.994928897454834
              ],
              [
                -47.31872708093992,
                -17.99557243216529
              ],
              [
                -47.31934757269732,
                -17.985511975928954
              ],
              [
                -47.322501564110404,
                -17.984028987743642
              ],
              [
                -47.32086910484048,
                -17.97927650681471
              ],
              [
                -47.319216130549776,
                -17.978670989250602
              ],
              [
                -47.31350817634841,
                -17.976578497313845
              ],
              [
                -47.31339719914342,
                -17.962759566046046
              ],
              [
                -47.32331161118732,
                -17.95691011679027
              ],
              [
                -47.33322656224213,
                -17.95105967553573
              ],
              [
                -47.33510758768724,
                -17.9373952429506
              ],
              [
                -47.34014453252542,
                -17.93694276761272
              ],
              [
                -47.3389530786867,
                -17.927699288107604
              ],
              [
                -47.34307006040467,
                -17.922131357361454
              ],
              [
                -47.341855585987155,
                -17.919730865150047
              ],
              [
                -47.33795611801295,
                -17.912023378589872
              ],
              [
                -47.34375307127034,
                -17.908508904906476
              ],
              [
                -47.34638659309735,
                -17.89751449113758
              ],
              [
                -47.35215952622234,
                -17.897408003624196
              ],
              [
                -47.35661697480471,
                -17.897325502039884
              ],
              [
                -47.35838248653375,
                -17.886751077135262
              ],
              [
                -47.3554195622098,
                -17.877330120643336
              ],
              [
                -47.35325856024143,
                -17.870459637655387
              ],
              [
                -47.356348550863245,
                -17.867873139063814
              ],
              [
                -47.35908655457952,
                -17.865581180147423
              ],
              [
                -47.357648053756094,
                -17.8579187119195
              ],
              [
                -47.36647605529709,
                -17.834908830767063
              ],
              [
                -47.372609514518295,
                -17.829484867784355
              ],
              [
                -47.36402860874027,
                -17.82303442231836
              ],
              [
                -47.36289463366637,
                -17.816508932877962
              ],
              [
                -47.359196633662656,
                -17.815907442771735
              ],
              [
                -47.35159073041935,
                -17.814670408272622
              ],
              [
                -47.35017773611715,
                -17.810797919361868
              ],
              [
                -47.35563019518271,
                -17.806428980353758
              ],
              [
                -47.357561180520314,
                -17.804881476746946
              ],
              [
                -47.35495672891124,
                -17.804638482211583
              ],
              [
                -47.35253973276209,
                -17.804413485000392
              ],
              [
                -47.347994787846154,
                -17.797502492005652
              ],
              [
                -47.351349748994956,
                -17.79505354428546
              ],
              [
                -47.35378326287061,
                -17.79327754399318
              ],
              [
                -47.35403077594519,
                -17.78707554897734
              ],
              [
                -47.34835432672356,
                -17.78400708538777
              ],
              [
                -47.34700181875846,
                -17.783086561192295
              ],
              [
                -47.34037440463266,
                -17.778575104045764
              ],
              [
                -47.3427568572575,
                -17.774592603186637
              ],
              [
                -47.3322639725122,
                -17.766155642009675
              ],
              [
                -47.33600647725484,
                -17.76052465981147
              ],
              [
                -47.33419747284278,
                -17.75633670771969
              ],
              [
                -47.337714450211834,
                -17.754081211346282
              ],
              [
                -47.34200795058381,
                -17.751328720300098
              ],
              [
                -47.34227993215311,
                -17.743996257303372
              ],
              [
                -47.336591518594346,
                -17.736960790524773
              ],
              [
                -47.317395679981566,
                -17.72617330246547
              ],
              [
                -47.31164876638593,
                -17.711052896020785
              ],
              [
                -47.31323627684654,
                -17.698553453115746
              ],
              [
                -47.305202364513974,
                -17.69087897760797
              ],
              [
                -47.300570886304634,
                -17.690965474903795
              ],
              [
                -47.299070931221664,
                -17.690992949440123
              ],
              [
                -47.29871343256893,
                -17.68871297261948
              ],
              [
                -47.29790294649002,
                -17.6835439989679
              ],
              [
                -47.29449948053527,
                -17.683604483121886
              ],
              [
                -47.28615201087726,
                -17.683751488887278
              ],
              [
                -47.26996717565226,
                -17.674010003292857
              ],
              [
                -47.27059169990693,
                -17.666836520379686
              ],
              [
                -47.275343662453984,
                -17.660377600830092
              ],
              [
                -47.28092963806147,
                -17.652784126086146
              ],
              [
                -47.27828767682485,
                -17.643765183680966
              ],
              [
                -47.27129724311715,
                -17.63941570213803
              ],
              [
                -47.26781975509617,
                -17.63725167685376
              ],
              [
                -47.263519841820816,
                -17.61999677418739
              ],
              [
                -47.266424331844135,
                -17.608626829447008
              ],
              [
                -47.271462805731694,
                -17.602301366847342
              ],
              [
                -47.275372799242966,
                -17.597392933300966
              ],
              [
                -47.2834147954934,
                -17.577118524777756
              ],
              [
                -47.306174639450994,
                -17.55799767253021
              ],
              [
                -47.30878462082196,
                -17.555818185746094
              ],
              [
                -47.310439108489234,
                -17.551682730166966
              ],
              [
                -47.31365960899988,
                -17.543631769658113
              ],
              [
                -47.32039306411181,
                -17.530699357587466
              ],
              [
                -47.33048103569422,
                -17.524378396034447
              ],
              [
                -47.34493289587439,
                -17.525863938921823
              ],
              [
                -47.35092935058013,
                -17.526480448836736
              ],
              [
                -47.37408919012257,
                -17.52345950859106
              ],
              [
                -47.37746516364924,
                -17.52034103242106
              ],
              [
                -47.38445660332345,
                -17.513883056768318
              ],
              [
                -47.39932748317605,
                -17.51247459082713
              ],
              [
                -47.413752857759576,
                -17.521061604326896
              ],
              [
                -47.44595707117006,
                -17.530315104469228
              ],
              [
                -47.44712607137296,
                -17.531356612567617
              ],
              [
                -47.45316099746879,
                -17.536733578594813
              ],
              [
                -47.46645242533093,
                -17.533105652899792
              ],
              [
                -47.470122378165115,
                -17.52555665962474
              ],
              [
                -47.477067394023344,
                -17.51127026994803
              ],
              [
                -47.484132800222255,
                -17.518211268023965
              ],
              [
                -47.49203974074443,
                -17.525978737633487
              ],
              [
                -47.49540319071285,
                -17.524837728025844
              ],
              [
                -47.50245216254242,
                -17.516092297449077
              ],
              [
                -47.50528217025053,
                -17.492550931300705
              ],
              [
                -47.50372024043633,
                -17.485523950786796
              ],
              [
                -47.50278424791719,
                -17.481314991847693
              ],
              [
                -47.5116132007602,
                -17.473323054228988
              ],
              [
                -47.513856685846775,
                -17.460343095372064
              ],
              [
                -47.53524302516699,
                -17.45517020447619
              ],
              [
                -47.53576153334396,
                -17.45504518027074
              ],
              [
                -47.54073500468131,
                -17.453843686495457
              ],
              [
                -47.539901016407114,
                -17.445402255024586
              ],
              [
                -47.530343598961444,
                -17.441367268279564
              ],
              [
                -47.526873626025,
                -17.439902231376287
              ],
              [
                -47.526770631810805,
                -17.432347305877375
              ],
              [
                -47.526728673446385,
                -17.42927578193905
              ],
              [
                -47.52565618856912,
                -17.428182321341012
              ],
              [
                -47.52097772308908,
                -17.42341184418977
              ],
              [
                -47.52670415522024,
                -17.42356234193677
              ],
              [
                -47.527265673722134,
                -17.423577324087535
              ],
              [
                -47.529426172798495,
                -17.41337088451019
              ],
              [
                -47.53532014558325,
                -17.412268402210206
              ],
              [
                -47.5368961086755,
                -17.407798441175284
              ],
              [
                -47.529889182265485,
                -17.409518919790596
              ],
              [
                -47.52426173370344,
                -17.410900887205116
              ],
              [
                -47.523562728798886,
                -17.40646043304418
              ],
              [
                -47.53131065784152,
                -17.403253953867534
              ],
              [
                -47.53355768119591,
                -17.40232346767931
              ],
              [
                -47.53837517709655,
                -17.388338539816576
              ],
              [
                -47.538214140382955,
                -17.388335554480236
              ],
              [
                -47.52869272744224,
                -17.388161001588117
              ],
              [
                -47.527977243157856,
                -17.3881480180928
              ],
              [
                -47.52836473484873,
                -17.38740152286997
              ],
              [
                -47.531869233981,
                -17.380661087018105
              ],
              [
                -47.53006275671324,
                -17.377124104697426
              ],
              [
                -47.525927250588765,
                -17.38175904125511
              ],
              [
                -47.52525176316212,
                -17.382516038792936
              ],
              [
                -47.51405639366361,
                -17.37426656579592
              ],
              [
                -47.513562907402836,
                -17.356595654843314
              ],
              [
                -47.513539435588854,
                -17.355750177248865
              ],
              [
                -47.51915438083326,
                -17.356232688952367
              ],
              [
                -47.521228878529705,
                -17.352351706932467
              ],
              [
                -47.504146040932646,
                -17.336834740519922
              ],
              [
                -47.51170599341509,
                -17.334483262391377
              ],
              [
                -47.51120198797772,
                -17.331282312177912
              ],
              [
                -47.49933209252186,
                -17.334999257717904
              ],
              [
                -47.498176104855126,
                -17.335360754293042
              ],
              [
                -47.49711910991668,
                -17.33804522482784
              ],
              [
                -47.49462309077898,
                -17.34438570394104
              ],
              [
                -47.490373611603665,
                -17.344365200790655
              ],
              [
                -47.487505660342194,
                -17.349046145847005
              ],
              [
                -47.47177629053091,
                -17.344754149227395
              ],
              [
                -47.46382730643003,
                -17.355046071593417
              ],
              [
                -47.45743087723906,
                -17.351262094630798
              ],
              [
                -47.45959035694534,
                -17.343689610310026
              ],
              [
                -47.45066092567744,
                -17.344658079754996
              ],
              [
                -47.44661246507943,
                -17.348809563018552
              ],
              [
                -47.44034002467939,
                -17.347241062645033
              ],
              [
                -47.44968745771053,
                -17.336059636046045
              ],
              [
                -47.45456796066501,
                -17.33022168450074
              ],
              [
                -47.450563489245475,
                -17.32863867815177
              ],
              [
                -47.4498975144369,
                -17.3235932213594
              ],
              [
                -47.44148305981107,
                -17.32255421355414
              ],
              [
                -47.43618763337774,
                -17.317696705717506
              ],
              [
                -47.43202465386759,
                -17.319660702288033
              ],
              [
                -47.423742704555046,
                -17.323567164636348
              ],
              [
                -47.4212612452837,
                -17.315506690863746
              ],
              [
                -47.42423574086582,
                -17.312428231604724
              ],
              [
                -47.428923208992195,
                -17.30757774843655
              ],
              [
                -47.42475922810778,
                -17.30418276235028
              ],
              [
                -47.42034629968198,
                -17.300584262805966
              ],
              [
                -47.42097031455306,
                -17.289171347547512
              ],
              [
                -47.425100783800104,
                -17.28316935326928
              ],
              [
                -47.4280492754827,
                -17.28311139197042
              ],
              [
                -47.43232171169732,
                -17.283027387681074
              ],
              [
                -47.434010719341906,
                -17.275758441809632
              ],
              [
                -47.42114534533642,
                -17.27079095121817
              ],
              [
                -47.417197897315184,
                -17.269266954734118
              ],
              [
                -47.41649339306669,
                -17.264516958361828
              ],
              [
                -47.417209910562285,
                -17.26400745461206
              ],
              [
                -47.422286855640486,
                -17.26040051270997
              ],
              [
                -47.41403192669689,
                -17.258527992751773
              ],
              [
                -47.40782196640293,
                -17.25711946276898
              ],
              [
                -47.4089059853518,
                -17.25388951922827
              ],
              [
                -47.41062195269623,
                -17.24877202905745
              ],
              [
                -47.40636203190378,
                -17.24522203265346
              ],
              [
                -47.39561758821981,
                -17.242305547873848
              ],
              [
                -47.39502512390815,
                -17.242743557270487
              ],
              [
                -47.386089653545596,
                -17.249346989935958
              ],
              [
                -47.38148320831245,
                -17.242897022175367
              ],
              [
                -47.38605369641569,
                -17.239276551256317
              ],
              [
                -47.37856673109363,
                -17.23581854741475
              ],
              [
                -47.381341231517794,
                -17.231756552957677
              ],
              [
                -47.38487122651635,
                -17.226588611999613
              ],
              [
                -47.37815679148668,
                -17.21951614202558
              ],
              [
                -47.373121814632,
                -17.220767610513224
              ],
              [
                -47.372089850640634,
                -17.217004606258268
              ],
              [
                -47.37028036880103,
                -17.217963599262077
              ],
              [
                -47.362198405117404,
                -17.222248063072836
              ],
              [
                -47.363731937916576,
                -17.21154615320011
              ],
              [
                -47.360396451717094,
                -17.204158667236733
              ],
              [
                -47.355623513333065,
                -17.202178196464523
              ],
              [
                -47.350679060497754,
                -17.204833151700043
              ],
              [
                -47.349786555126954,
                -17.196037708506147
              ],
              [
                -47.35447754479342,
                -17.194747705470057
              ],
              [
                -47.35640151971247,
                -17.194218699427143
              ],
              [
                -47.36259646849529,
                -17.186033282267267
              ],
              [
                -47.35622854221241,
                -17.18816324923026
              ],
              [
                -47.349923583400184,
                -17.186018746045587
              ],
              [
                -47.34917760092952,
                -17.185765245786197
              ],
              [
                -47.34234166659824,
                -17.18909070838646
              ],
              [
                -47.33972816144954,
                -17.18692722425834
              ],
              [
                -47.34836563073984,
                -17.182184283072072
              ],
              [
                -47.35275810739851,
                -17.17977127535707
              ],
              [
                -47.348039619293765,
                -17.175764287500442
              ],
              [
                -47.35154011608164,
                -17.166037872750668
              ],
              [
                -47.3415347142764,
                -17.16537134577113
              ],
              [
                -47.33345928481858,
                -17.16483332026416
              ],
              [
                -47.33113480005294,
                -17.15850337394514
              ],
              [
                -47.32723232883893,
                -17.14787390113029
              ],
              [
                -47.323235387702276,
                -17.155169865706444
              ],
              [
                -47.31059449800615,
                -17.14585889612239
              ],
              [
                -47.308806984866855,
                -17.152045355339517
              ],
              [
                -47.30692697071223,
                -17.15855330564904
              ],
              [
                -47.3025305263377,
                -17.15399034349979
              ],
              [
                -47.30336452385845,
                -17.148755868167772
              ],
              [
                -47.30372906727041,
                -17.146467859136646
              ],
              [
                -47.2953721101191,
                -17.138163887435407
              ],
              [
                -47.29320762726226,
                -17.139067901973615
              ],
              [
                -47.28226322883966,
                -17.14363983012373
              ],
              [
                -47.28297573554003,
                -17.13011892772007
              ],
              [
                -47.27590230926426,
                -17.126941911492594
              ],
              [
                -47.27864678446753,
                -17.124755418034947
              ],
              [
                -47.28172528162945,
                -17.122301939716497
              ],
              [
                -47.276846310916234,
                -17.122553961915088
              ],
              [
                -47.2754823026194,
                -17.12262494861312
              ],
              [
                -47.27516730305359,
                -17.120500472268155
              ],
              [
                -47.27470233801434,
                -17.117369453971275
              ],
              [
                -47.281510299578784,
                -17.11443350521678
              ],
              [
                -47.27314636242237,
                -17.103116558676618
              ],
              [
                -47.26725790848156,
                -17.11082900191647
              ],
              [
                -47.26548693822122,
                -17.113148466748118
              ],
              [
                -47.265022417625524,
                -17.108094994718208
              ],
              [
                -47.26401047417832,
                -17.097082070329463
              ],
              [
                -47.25889399150178,
                -17.100300538200937
              ],
              [
                -47.256070038623065,
                -17.092684583819686
              ],
              [
                -47.254512020560185,
                -17.09635054091312
              ],
              [
                -47.25387254417735,
                -17.09785452551511
              ],
              [
                -47.24772959457787,
                -17.098095513086818
              ],
              [
                -47.24758512728693,
                -17.09266555877471
              ],
              [
                -47.24728263094296,
                -17.081310598779112
              ],
              [
                -47.24265016217814,
                -17.086367568862997
              ],
              [
                -47.23910267869996,
                -17.08323156203495
              ],
              [
                -47.23478274107503,
                -17.08585407779067
              ],
              [
                -47.2292877661714,
                -17.089190016221863
              ],
              [
                -47.22394479582191,
                -17.085271556743454
              ],
              [
                -47.23030277867815,
                -17.08590155596283
              ],
              [
                -47.231732262141946,
                -17.084231068944035
              ],
              [
                -47.23373324959079,
                -17.081893588710514
              ],
              [
                -47.228431306523824,
                -17.078573584126715
              ],
              [
                -47.225237828896546,
                -17.08077607353298
              ],
              [
                -47.22334030200165,
                -17.082085041483012
              ],
              [
                -47.223341331346546,
                -17.079767067875075
              ],
              [
                -47.223344350789525,
                -17.075233602115755
              ],
              [
                -47.218229867148686,
                -17.0767140762792
              ],
              [
                -47.20723346448806,
                -17.079896057783987
              ],
              [
                -47.20992995882132,
                -17.07346807091495
              ],
              [
                -47.20870248060202,
                -17.070004081820834
              ],
              [
                -47.20742350112922,
                -17.06639362440301
              ],
              [
                -47.20526898056084,
                -17.067585598429645
              ],
              [
                -47.197649056604384,
                -17.07180206461384
              ],
              [
                -47.190594122951694,
                -17.069167562655494
              ],
              [
                -47.19119412746164,
                -17.066003598865667
              ],
              [
                -47.195810581242014,
                -17.06617657778779
              ],
              [
                -47.19576958174426,
                -17.060895598958435
              ],
              [
                -47.19576007378766,
                -17.059608127827918
              ],
              [
                -47.19445358737555,
                -17.05996911211005
              ],
              [
                -47.1898436172114,
                -17.06124161346935
              ],
              [
                -47.18698067928719,
                -17.05723212982558
              ],
              [
                -47.18164969437807,
                -17.063495585932483
              ],
              [
                -47.175780754699005,
                -17.053729624705614
              ],
              [
                -47.18129022289834,
                -17.052361651771086
              ],
              [
                -47.18153573045321,
                -17.050836138462362
              ],
              [
                -47.18400475005422,
                -17.035502250839794
              ],
              [
                -47.17986626010538,
                -17.03461474378311
              ],
              [
                -47.178594256079485,
                -17.03903621139053
              ],
              [
                -47.17154031459365,
                -17.043711177327808
              ],
              [
                -47.166526360948694,
                -17.04703415584933
              ],
              [
                -47.167617335325446,
                -17.04091519295271
              ],
              [
                -47.168015352281564,
                -17.038684663750445
              ],
              [
                -47.1657883729178,
                -17.039611165045432
              ],
              [
                -47.16304688516884,
                -17.040751154101045
              ],
              [
                -47.16129892209953,
                -17.036410694638615
              ],
              [
                -47.16072740292526,
                -17.03499069803209
              ],
              [
                -47.15998342922267,
                -17.030049196933376
              ],
              [
                -47.15721794935273,
                -17.033501213546995
              ],
              [
                -47.156651471247365,
                -17.034208208552254
              ],
              [
                -47.156288951516366,
                -17.03273970465194
              ],
              [
                -47.154594480835684,
                -17.025866216893522
              ],
              [
                -47.153548511386106,
                -17.02795321148024
              ],
              [
                -47.15168299172151,
                -17.031673674567
              ],
              [
                -47.146537550884524,
                -17.03032467785383
              ],
              [
                -47.1485375401877,
                -17.02368172972583
              ],
              [
                -47.143949565398906,
                -17.024727711361642
              ],
              [
                -47.13131522110178,
                -17.009977275640477
              ],
              [
                -47.13234570058452,
                -17.004873798216856
              ],
              [
                -47.14245363441335,
                -17.008579291800437
              ],
              [
                -47.144320084947935,
                -17.00926381809293
              ],
              [
                -47.14370311944713,
                -17.00334682752845
              ],
              [
                -47.139960157301395,
                -17.004225335865943
              ],
              [
                -47.13984365616807,
                -17.0042528124711
              ],
              [
                -47.13984015473971,
                -17.004015805735452
              ],
              [
                -47.13973068235538,
                -16.99671533568149
              ],
              [
                -47.15067957157899,
                -16.99689438796394
              ],
              [
                -47.135599219163026,
                -16.992423881948117
              ],
              [
                -47.131094242175585,
                -16.991088887556348
              ],
              [
                -47.1261608057067,
                -16.980105912071558
              ],
              [
                -47.142171190342836,
                -16.97163198052328
              ],
              [
                -47.14373470909767,
                -16.964977027578584
              ],
              [
                -47.149226638808756,
                -16.966495541061644
              ],
              [
                -47.151205129774624,
                -16.967042059320114
              ],
              [
                -47.15790558774181,
                -16.964238549575583
              ],
              [
                -47.153712140401986,
                -16.95715261374273
              ],
              [
                -47.15220816920972,
                -16.95461162220517
              ],
              [
                -47.155049136682884,
                -16.954115626886747
              ],
              [
                -47.1661450246155,
                -16.9521791357905
              ],
              [
                -47.15936610667092,
                -16.944308657707857
              ],
              [
                -47.156483645305634,
                -16.94096269658222
              ],
              [
                -47.16189110263943,
                -16.938573215865325
              ],
              [
                -47.163951605287814,
                -16.93766273611792
              ],
              [
                -47.16789805980432,
                -16.940732228982213
              ],
              [
                -47.16906955839363,
                -16.935072760863772
              ],
              [
                -47.17392800769081,
                -16.93243077733858
              ],
              [
                -47.167673069482234,
                -16.927649273793794
              ],
              [
                -47.15979667203007,
                -16.92162879049192
              ],
              [
                -47.16633210786415,
                -16.91507786552135
              ],
              [
                -47.17243008649606,
                -16.91803634151223
              ],
              [
                -47.18162804117868,
                -16.901811930155255
              ],
              [
                -47.18895294956047,
                -16.905424437218187
              ],
              [
                -47.196277385948825,
                -16.909036947290456
              ],
              [
                -47.19321543983318,
                -16.897862504864904
              ],
              [
                -47.20090289595969,
                -16.8941850128701
              ],
              [
                -47.2019258720042,
                -16.88971155107282
              ],
              [
                -47.196139941918425,
                -16.88759903520839
              ],
              [
                -47.19417948316968,
                -16.88688355591495
              ],
              [
                -47.1927334812191,
                -16.88270056635106
              ],
              [
                -47.19836494675964,
                -16.8820805945723
              ],
              [
                -47.20045793860876,
                -16.881850099229926
              ],
              [
                -47.200459952898235,
                -16.876885102926916
              ],
              [
                -47.20046041837799,
                -16.874915636517084
              ],
              [
                -47.20122342712504,
                -16.874992122716044
              ],
              [
                -47.20726036164635,
                -16.87560062018543
              ],
              [
                -47.20889589366456,
                -16.865035184441826
              ],
              [
                -47.20237643431988,
                -16.86120071231308
              ],
              [
                -47.19992595111833,
                -16.85975969889692
              ],
              [
                -47.20312147410158,
                -16.848128799202215
              ],
              [
                -47.20551697282889,
                -16.83940931816566
              ],
              [
                -47.203737485764236,
                -16.837312360702686
              ],
              [
                -47.19307759918847,
                -16.824748391899224
              ],
              [
                -47.2046475022585,
                -16.826411912288908
              ],
              [
                -47.21169445983564,
                -16.827425899663247
              ],
              [
                -47.212339432530975,
                -16.82265242071348
              ],
              [
                -47.208046507716475,
                -16.820007950915958
              ],
              [
                -47.20703751316268,
                -16.819385924141958
              ],
              [
                -47.20997499359943,
                -16.81637445153473
              ],
              [
                -47.21973441357384,
                -16.806369033928995
              ],
              [
                -47.21687096014448,
                -16.80338056599993
              ],
              [
                -47.21522994909139,
                -16.80166756177496
              ],
              [
                -47.21861993479026,
                -16.79650760468839
              ],
              [
                -47.216786488636956,
                -16.779609657864636
              ],
              [
                -47.22234495967232,
                -16.77771619573578
              ],
              [
                -47.23045839934612,
                -16.774952239659402
              ],
              [
                -47.22947944304602,
                -16.767033278270397
              ],
              [
                -47.222982979562566,
                -16.761365288187527
              ],
              [
                -47.23164942022685,
                -16.76462229581486
              ],
              [
                -47.23554340814412,
                -16.75492936421729
              ],
              [
                -47.23406194310371,
                -16.74422641280252
              ],
              [
                -47.226092990280804,
                -16.74122992326061
              ],
              [
                -47.23064898652127,
                -16.733918968229517
              ],
              [
                -47.22264507688585,
                -16.730960470426098
              ],
              [
                -47.22269556233652,
                -16.72925195774818
              ],
              [
                -47.222905074543064,
                -16.7222100111779
              ],
              [
                -47.22720504401779,
                -16.71302656472659
              ],
              [
                -47.24110043865438,
                -16.704064146148834
              ],
              [
                -47.24475992470986,
                -16.706211621215537
              ],
              [
                -47.248293912930556,
                -16.70828511542184
              ],
              [
                -47.2510343634409,
                -16.705041137653502
              ],
              [
                -47.24719542274604,
                -16.702033674849776
              ],
              [
                -47.2452564475869,
                -16.70051515698869
              ],
              [
                -47.24615646906217,
                -16.682009745743635
              ],
              [
                -47.252535413209955,
                -16.685177271971853
              ],
              [
                -47.25478339482358,
                -16.686293245645995
              ],
              [
                -47.25850838668777,
                -16.678518802386037
              ],
              [
                -47.255043926066634,
                -16.677259313948507
              ],
              [
                -47.247959972183736,
                -16.674685322051914
              ],
              [
                -47.249708944981805,
                -16.66635937065898
              ],
              [
                -47.259370902968335,
                -16.667333843563235
              ],
              [
                -47.25340496267428,
                -16.663206375397962
              ],
              [
                -47.258924408825905,
                -16.65517393729002
              ],
              [
                -47.263457868830706,
                -16.661833898786572
              ],
              [
                -47.265396359787864,
                -16.659443912735327
              ],
              [
                -47.26719235799768,
                -16.66056293144168
              ],
              [
                -47.27151179661985,
                -16.663252897935635
              ],
              [
                -47.27678924789853,
                -16.659590931717197
              ],
              [
                -47.277683303804864,
                -16.649787989501945
              ],
              [
                -47.27786929989322,
                -16.647753492874273
              ],
              [
                -47.28098674616732,
                -16.646395030710753
              ],
              [
                -47.28656624028459,
                -16.643964550469203
              ],
              [
                -47.285187716467355,
                -16.642080053768584
              ],
              [
                -47.28322527107869,
                -16.639398059157887
              ],
              [
                -47.28626624066246,
                -16.634606590370563
              ],
              [
                -47.291326202834796,
                -16.635776590600965
              ],
              [
                -47.29364917517537,
                -16.636313117547225
              ],
              [
                -47.29380518703597,
                -16.63359360955526
              ],
              [
                -47.29423420341572,
                -16.626106647877016
              ],
              [
                -47.301147122507196,
                -16.62654964989046
              ],
              [
                -47.30221713278118,
                -16.627542678758903
              ],
              [
                -47.30665559329402,
                -16.631659140788187
              ],
              [
                -47.30471363397819,
                -16.625746663658674
              ],
              [
                -47.304015138291454,
                -16.623621169679048
              ],
              [
                -47.315826067235754,
                -16.616149749627272
              ],
              [
                -47.317242530677696,
                -16.616734260422593
              ],
              [
                -47.31952998839546,
                -16.617679246087043
              ],
              [
                -47.31867552960925,
                -16.615703248974235
              ],
              [
                -47.314962063328316,
                -16.607119295589293
              ],
              [
                -47.318093563457154,
                -16.603102809799907
              ],
              [
                -47.326625464307085,
                -16.6104282940129
              ],
              [
                -47.333897906575224,
                -16.61667277646012
              ],
              [
                -47.33750889916222,
                -16.61120680348401
              ],
              [
                -47.333461939074674,
                -16.607731823809473
              ],
              [
                -47.33435142091131,
                -16.606884849234202
              ],
              [
                -47.338033400535984,
                -16.60337987282293
              ],
              [
                -47.3365149197183,
                -16.59751738231023
              ],
              [
                -47.34250636775341,
                -16.597700380796265
              ],
              [
                -47.34240885966926,
                -16.592723944459475
              ],
              [
                -47.35034083633433,
                -16.592896443039717
              ],
              [
                -47.35528076053497,
                -16.589144472576148
              ],
              [
                -47.362481732169634,
                -16.593266947318032
              ],
              [
                -47.36422570709804,
                -16.59426496256404
              ],
              [
                -47.36653067354194,
                -16.6006344229982
              ],
              [
                -47.37572710174928,
                -16.59877644318126
              ],
              [
                -47.37597061964311,
                -16.585460522528443
              ],
              [
                -47.376074617541065,
                -16.579776562417553
              ],
              [
                -47.38650606650484,
                -16.57857258362558
              ],
              [
                -47.38650154476354,
                -16.579366577332056
              ],
              [
                -47.38645304290664,
                -16.588407023458778
              ],
              [
                -47.395980491208604,
                -16.579541097123077
              ],
              [
                -47.394100486347625,
                -16.574969625871795
              ],
              [
                -47.39356849545932,
                -16.573676630740735
              ],
              [
                -47.398424979898394,
                -16.57236065694767
              ],
              [
                -47.40039896544433,
                -16.571826133939553
              ],
              [
                -47.4124268520854,
                -16.57540464567453
              ],
              [
                -47.40871892369369,
                -16.566371204681158
              ],
              [
                -47.4119273782081,
                -16.565054223461768
              ],
              [
                -47.40960139840197,
                -16.561586226331748
              ],
              [
                -47.40881242328788,
                -16.560409250040713
              ],
              [
                -47.410269423546126,
                -16.56094271219328
              ],
              [
                -47.41259336622611,
                -16.561792223444588
              ],
              [
                -47.412122415855166,
                -16.55915574268876
              ],
              [
                -47.411922915937616,
                -16.5580372263086
              ],
              [
                -47.40543344963983,
                -16.555235255932445
              ],
              [
                -47.413068401039496,
                -16.54766678764985
              ],
              [
                -47.42012135594253,
                -16.55133678150983
              ],
              [
                -47.42041436301054,
                -16.551162317060424
              ],
              [
                -47.425927303653154,
                -16.54788381821165
              ],
              [
                -47.42859128451368,
                -16.551923830956838
              ],
              [
                -47.433590258721445,
                -16.544401374931294
              ],
              [
                -47.430645263983024,
                -16.54130038253925
              ],
              [
                -47.430096786621064,
                -16.540721861520655
              ],
              [
                -47.43112175923337,
                -16.54084137800621
              ],
              [
                -47.43768622352373,
                -16.54160389953797
              ],
              [
                -47.443964682678384,
                -16.54031088756523
              ],
              [
                -47.441676237639555,
                -16.528744946206633
              ],
              [
                -47.44489870454835,
                -16.5289989545309
              ],
              [
                -47.447208213239556,
                -16.516910039561
              ],
              [
                -47.458135635485306,
                -16.501765138981455
              ],
              [
                -47.45470819037566,
                -16.494696659029827
              ],
              [
                -47.45254221047173,
                -16.490231702647066
              ],
              [
                -47.4544676896716,
                -16.488235722416874
              ],
              [
                -47.455706183727536,
                -16.48695070892326
              ],
              [
                -47.453622245636005,
                -16.467443323974468
              ],
              [
                -47.449684280380055,
                -16.465389314920436
              ],
              [
                -47.447487292321,
                -16.464243815563037
              ],
              [
                -47.44624084159116,
                -16.457313858438066
              ],
              [
                -47.44500786057249,
                -16.450462377925508
              ],
              [
                -47.439033413751154,
                -16.44736589731445
              ],
              [
                -47.42907397806808,
                -16.442205390639582
              ],
              [
                -47.4313934732827,
                -16.439689418042537
              ],
              [
                -47.428652516737,
                -16.433039471357688
              ],
              [
                -47.424433075839545,
                -16.42280300004362
              ],
              [
                -47.42317959450166,
                -16.419762501915134
              ],
              [
                -47.42328459138135,
                -16.418990010313536
              ],
              [
                -47.42672760507788,
                -16.393705680326118
              ],
              [
                -47.41887667053498,
                -16.390406162026853
              ],
              [
                -47.417459722997485,
                -16.380792728201282
              ],
              [
                -47.40789128778409,
                -16.37716222818686
              ],
              [
                -47.40317281810376,
                -16.375372203183307
              ],
              [
                -47.377973111540754,
                -16.346419353479664
              ],
              [
                -47.37481114584404,
                -16.340398871679163
              ],
              [
                -47.370860163702005,
                -16.3328738754424
              ],
              [
                -47.36954018921512,
                -16.332393886342608
              ],
              [
                -47.36582823059164,
                -16.33104440406161
              ],
              [
                -47.35765982896151,
                -16.310724974751082
              ],
              [
                -47.35252636367709,
                -16.306151019110292
              ],
              [
                -47.3542843810554,
                -16.299175045644382
              ],
              [
                -47.35576086120534,
                -16.29331757052274
              ],
              [
                -47.349219450962565,
                -16.288332599262652
              ],
              [
                -47.34721545618794,
                -16.28680561847352
              ],
              [
                -47.347668460488606,
                -16.28503060205776
              ],
              [
                -47.35041144528656,
                -16.274289193116452
              ],
              [
                -47.335121592394756,
                -16.258312732882185
              ],
              [
                -47.33446011714731,
                -16.257621747296064
              ],
              [
                -47.33435412204919,
                -16.25667876321601
              ],
              [
                -47.33338864151207,
                -16.24807228013054
              ],
              [
                -47.33047515884032,
                -16.248419271299074
              ],
              [
                -47.32849269803431,
                -16.248655270970833
              ],
              [
                -47.322015777211035,
                -16.230863381743116
              ],
              [
                -47.32881225790254,
                -16.21594146894229
              ],
              [
                -47.32974525997482,
                -16.213893476009158
              ],
              [
                -47.32899475620279,
                -16.213305453688754
              ],
              [
                -47.32329081513994,
                -16.208834491470277
              ],
              [
                -47.3295707477515,
                -16.20499350819435
              ],
              [
                -47.33587721719263,
                -16.20113652705731
              ],
              [
                -47.330190259305006,
                -16.1960570452041
              ],
              [
                -47.32951729294586,
                -16.19545555711783
              ],
              [
                -47.32979526059758,
                -16.19414457983553
              ],
              [
                -47.33087778814107,
                -16.189035593911957
              ],
              [
                -47.33034180273069,
                -16.18382162127603
              ],
              [
                -47.33365175578346,
                -16.184156156173888
              ],
              [
                -47.34006619356505,
                -16.184804656666383
              ],
              [
                -47.33459476831519,
                -16.18139713814571
              ],
              [
                -47.33095881504098,
                -16.17913265612031
              ],
              [
                -47.331511306723506,
                -16.171285681075044
              ],
              [
                -47.34023777587891,
                -16.163337263175524
              ],
              [
                -47.34075726069202,
                -16.163370779046684
              ],
              [
                -47.344566235002176,
                -16.163612785454674
              ],
              [
                -47.34202473970816,
                -16.159663271858275
              ],
              [
                -47.33998179777956,
                -16.15648932604318
              ],
              [
                -47.343368757607344,
                -16.155729808059675
              ],
              [
                -47.34817921885554,
                -16.15465233469916
              ],
              [
                -47.34418773037833,
                -16.15100586051354
              ],
              [
                -47.34051230777336,
                -16.147646862404592
              ],
              [
                -47.34419623757785,
                -16.14765785581277
              ],
              [
                -47.34992872615104,
                -16.147674864342015
              ],
              [
                -47.34586776383648,
                -16.140055889716727
              ],
              [
                -47.35162623178423,
                -16.13150698007669
              ],
              [
                -47.34424230913331,
                -16.1297129759217
              ],
              [
                -47.33731636961987,
                -16.128030446090452
              ],
              [
                -47.34104132467883,
                -16.12131299333916
              ],
              [
                -47.336115865612506,
                -16.119034997821196
              ],
              [
                -47.33834188925424,
                -16.114293020244443
              ],
              [
                -47.338524874649664,
                -16.11390302474639
              ],
              [
                -47.3376218656948,
                -16.113027533705363
              ],
              [
                -47.33142892372364,
                -16.10702407900312
              ],
              [
                -47.33131195264618,
                -16.101940595564933
              ],
              [
                -47.331126487602006,
                -16.093852625425562
              ],
              [
                -47.33670042100244,
                -16.093143647564492
              ],
              [
                -47.33269046141142,
                -16.085433687638993
              ],
              [
                -47.328860508919675,
                -16.089977134048354
              ],
              [
                -47.323690558126444,
                -16.084760658149893
              ],
              [
                -47.32287355951461,
                -16.087113166627653
              ],
              [
                -47.32189053418967,
                -16.08994112416902
              ],
              [
                -47.310898635128446,
                -16.087679136353692
              ],
              [
                -47.310724157828886,
                -16.081511144762835
              ],
              [
                -47.3162156183807,
                -16.079175171631217
              ],
              [
                -47.307761197046034,
                -16.0783841719028
              ],
              [
                -47.31027817248594,
                -16.067086223840956
              ],
              [
                -47.315289124284575,
                -16.066862749168255
              ],
              [
                -47.30394974439741,
                -16.060225781659394
              ],
              [
                -47.30836671828127,
                -16.050077809151272
              ],
              [
                -47.30814775317286,
                -16.041645352914422
              ],
              [
                -47.31094571371496,
                -16.040856365870347
              ],
              [
                -47.30860927476364,
                -16.03549091157155
              ],
              [
                -47.30970375835283,
                -16.032118446257847
              ],
              [
                -47.29896286612215,
                -16.02574193361115
              ],
              [
                -47.29929885449746,
                -16.022933966683905
              ],
              [
                -47.300057358103345,
                -16.016599515946584
              ],
              [
                -47.288400971800236,
                -16.01549097460476
              ],
              [
                -47.28730348694926,
                -16.009224523780805
              ],
              [
                -47.28059751871241,
                -16.011252501185147
              ],
              [
                -47.27609156029867,
                -16.00571201254469
              ],
              [
                -47.27679504592209,
                -16.011211473774043
              ],
              [
                -47.261216668062126,
                -16.013673928976417
              ],
              [
                -47.2574247156742,
                -16.01620644045989
              ],
              [
                -47.25363322828329,
                -16.018739393940873
              ],
              [
                -47.24087184942586,
                -16.01408188889337
              ],
              [
                -47.23810837749422,
                -16.013073902882855
              ],
              [
                -47.2370068751274,
                -16.013585897860075
              ],
              [
                -47.233340377536116,
                -16.015290389139253
              ],
              [
                -47.22851795776122,
                -16.01249689380679
              ],
              [
                -47.227957433902255,
                -16.01217190946745
              ],
              [
                -47.22795295839616,
                -16.012424405071332
              ],
              [
                -47.22790192461496,
                -16.01530787912842
              ],
              [
                -47.22120448448533,
                -16.011220381146945
              ],
              [
                -47.21908751775949,
                -16.01379037077857
              ],
              [
                -47.216349042158484,
                -16.004609890736667
              ],
              [
                -47.20732166421651,
                -15.996922428901772
              ],
              [
                -47.20733363258393,
                -15.993437947085692
              ],
              [
                -47.20735614416671,
                -15.9870099884803
              ],
              [
                -47.200313731364055,
                -15.986847489271515
              ],
              [
                -47.197583225154865,
                -15.986784462150862
              ],
              [
                -47.196142737046884,
                -15.983761996894419
              ],
              [
                -47.194819277484264,
                -15.980985000523983
              ],
              [
                -47.19270228077917,
                -15.981216979010526
              ],
              [
                -47.18481133552082,
                -15.982082463455644
              ],
              [
                -47.186305339854414,
                -15.978254985509276
              ],
              [
                -47.182451385778826,
                -15.976686987428993
              ],
              [
                -47.183849385002596,
                -15.974669039332905
              ],
              [
                -47.1864923801913,
                -15.97085406161856
              ],
              [
                -47.180694420367274,
                -15.970300057073688
              ],
              [
                -47.180018385449095,
                -15.971835522268604
              ],
              [
                -47.177812913236686,
                -15.976845989274935
              ],
              [
                -47.169811501468814,
                -15.972752019812203
              ],
              [
                -47.165686017917906,
                -15.96492654866131
              ],
              [
                -47.167179021419365,
                -15.962422050439567
              ],
              [
                -47.16988952162291,
                -15.95787408990621
              ],
              [
                -47.16601554806274,
                -15.955670108309118
              ],
              [
                -47.169090011280346,
                -15.953509602352078
              ],
              [
                -47.1569086615561,
                -15.94055515742464
              ],
              [
                -47.15216267293743,
                -15.942562639889292
              ],
              [
                -47.14920522062762,
                -15.938235657844732
              ],
              [
                -47.15073420233962,
                -15.934553173198552
              ],
              [
                -47.1453627687533,
                -15.931835685473846
              ],
              [
                -47.14199077069925,
                -15.93503564810314
              ],
              [
                -47.13880032330961,
                -15.93067366580094
              ],
              [
                -47.1415118083451,
                -15.9261851909639
              ],
              [
                -47.134902839303074,
                -15.92829717314259
              ],
              [
                -47.132740349856604,
                -15.93140267870076
              ],
              [
                -47.13107584897937,
                -15.933793131195998
              ],
              [
                -47.13272081861359,
                -15.942388615065374
              ],
              [
                -47.13412681179034,
                -15.949737565344746
              ],
              [
                -47.12729589046834,
                -15.944834083757982
              ],
              [
                -47.125619885337244,
                -15.943631087050415
              ],
              [
                -47.118314924144705,
                -15.94888302151154
              ],
              [
                -47.107873992054486,
                -15.956390481270619
              ],
              [
                -47.097223108382195,
                -15.95839246397591
              ],
              [
                -47.0865721877251,
                -15.96039491567801
              ],
              [
                -47.05234649452741,
                -15.937462980094859
              ],
              [
                -46.956607304552364,
                -15.91556292695218
              ],
              [
                -46.87057201712875,
                -15.896056401631581
              ],
              [
                -46.824426911837435,
                -15.885267365324134
              ],
              [
                -46.811722511512016,
                -15.885109363099698
              ],
              [
                -46.808231038892124,
                -15.873951411275206
              ],
              [
                -46.80751656540522,
                -15.871667406573243
              ],
              [
                -46.80565106710873,
                -15.868429945906744
              ],
              [
                -46.80506362577698,
                -15.858941475557794
              ],
              [
                -46.81940756355477,
                -15.830802660489667
              ],
              [
                -46.82278204955395,
                -15.824183209996756
              ],
              [
                -46.821539561560925,
                -15.819850720680584
              ],
              [
                -46.8205630634988,
                -15.816444751730538
              ],
              [
                -46.82136206564851,
                -15.81429124229881
              ],
              [
                -46.82307406568384,
                -15.809676285375883
              ],
              [
                -46.81271017938132,
                -15.786752396767554
              ],
              [
                -46.8270316717467,
                -15.748734144900697
              ],
              [
                -46.82702965316051,
                -15.738968697384994
              ],
              [
                -46.82702767656182,
                -15.729202226888273
              ],
              [
                -46.84201410839535,
                -15.714219345845326
              ],
              [
                -46.85516553932372,
                -15.681720060006251
              ],
              [
                -46.852512628253784,
                -15.649091750136149
              ],
              [
                -46.85492763969184,
                -15.644432751884898
              ],
              [
                -46.85817161984357,
                -15.638175293184782
              ],
              [
                -46.85671112895484,
                -15.631190849392095
              ],
              [
                -46.854352187121535,
                -15.619905408917244
              ],
              [
                -46.88164795724239,
                -15.609094029470967
              ],
              [
                -46.896618391994735,
                -15.592732149069871
              ],
              [
                -46.90785228815538,
                -15.590547176119879
              ],
              [
                -46.9190857013375,
                -15.5883622171681
              ],
              [
                -46.943151586682426,
                -15.565307355801744
              ],
              [
                -46.94590507432715,
                -15.559727393342538
              ],
              [
                -46.94865855496843,
                -15.554147926881312
              ],
              [
                -46.94377363387154,
                -15.517738654087195
              ],
              [
                -46.93199129950976,
                -15.482723295389446
              ],
              [
                -46.930029416292534,
                -15.442642526297673
              ],
              [
                -46.91255260196826,
                -15.412908678534459
              ],
              [
                -46.849039688805334,
                -15.372714792642652
              ],
              [
                -46.850282673357164,
                -15.368074797139338
              ],
              [
                -46.860240090785084,
                -15.364332865263151
              ],
              [
                -46.869039525549184,
                -15.361025380721845
              ],
              [
                -46.8605191326544,
                -15.35051241259377
              ],
              [
                -46.84875524194113,
                -15.346697445580716
              ],
              [
                -46.847503750716506,
                -15.346291947471984
              ],
              [
                -46.83646586146184,
                -15.322149042423344
              ],
              [
                -46.87336366728678,
                -15.285022337069115
              ],
              [
                -46.88616954191727,
                -15.28236086846974
              ],
              [
                -46.88888103751409,
                -15.281797846809313
              ],
              [
                -46.8894670621211,
                -15.272268940637025
              ],
              [
                -46.890261544899225,
                -15.259352515973982
              ],
              [
                -46.89176758330569,
                -15.234877622890044
              ],
              [
                -46.906614972534065,
                -15.237290172492571
              ],
              [
                -46.91239192026956,
                -15.24640959805067
              ],
              [
                -46.92721428296249,
                -15.254647096820362
              ],
              [
                -46.940486195993046,
                -15.229190271541555
              ],
              [
                -46.93462978322026,
                -15.224353265682002
              ],
              [
                -46.93303926949119,
                -15.223039286069245
              ],
              [
                -46.93537728040924,
                -15.21188285942517
              ],
              [
                -46.93690877197033,
                -15.204573368560892
              ],
              [
                -46.922764448721736,
                -15.181940475467032
              ],
              [
                -46.920156472798,
                -15.17776852408275
              ],
              [
                -46.91627950408924,
                -15.166547048118005
              ],
              [
                -46.912402097361884,
                -15.1553266161749
              ],
              [
                -46.91002510658294,
                -15.151656133423215
              ],
              [
                -46.89583025412378,
                -15.129739749524605
              ],
              [
                -46.888710841705254,
                -15.111190851045954
              ],
              [
                -46.895889313320694,
                -15.103379874779433
              ],
              [
                -46.89858877935701,
                -15.093125468906656
              ],
              [
                -46.91334868504182,
                -15.082191020586318
              ],
              [
                -46.925598093955465,
                -15.07311660599976
              ],
              [
                -46.924523130305964,
                -15.060652674539787
              ],
              [
                -46.92428314575295,
                -15.05786772285823
              ],
              [
                -46.91831670288293,
                -15.049420740488728
              ],
              [
                -46.87383159456465,
                -15.025395289237354
              ],
              [
                -46.86373220454373,
                -15.019940831269656
              ],
              [
                -46.8600967394334,
                -15.014879827188206
              ],
              [
                -46.85646029332835,
                -15.009818343116054
              ],
              [
                -46.82241553273512,
                -15.010289307758768
              ],
              [
                -46.78142038347086,
                -15.018878191292089
              ],
              [
                -46.75282157527915,
                -15.03229957549902
              ],
              [
                -46.739558679260064,
                -15.035586021003013
              ],
              [
                -46.726295771266486,
                -15.038871973506954
              ],
              [
                -46.679599581791926,
                -15.059655287889044
              ],
              [
                -46.65596823557842,
                -15.081061610809071
              ],
              [
                -46.64915731050755,
                -15.083752075738953
              ],
              [
                -46.64234733043239,
                -15.086444073660116
              ],
              [
                -46.62500648243516,
                -15.089069031238774
              ],
              [
                -46.61734853212407,
                -15.086224994098295
              ],
              [
                -46.610179107562786,
                -15.083562515846067
              ],
              [
                -46.604810133445945,
                -15.08554300851466
              ],
              [
                -46.59987218921001,
                -15.087365000892012
              ],
              [
                -46.592965252734714,
                -15.083610498363184
              ],
              [
                -46.58756829459058,
                -15.080677488985371
              ],
              [
                -46.5818798607355,
                -15.081801980936284
              ],
              [
                -46.57618936590037,
                -15.082926950880333
              ],
              [
                -46.5508836403981,
                -15.06342901881677
              ],
              [
                -46.54414566641106,
                -15.064824517273026
              ],
              [
                -46.53156280338514,
                -15.055948050469121
              ],
              [
                -46.52568832632914,
                -15.055922554657508
              ],
              [
                -46.51400291143223,
                -15.060575467286343
              ],
              [
                -46.50808598833523,
                -15.056192483290259
              ],
              [
                -46.50216950823438,
                -15.051809531304968
              ],
              [
                -46.520235476919034,
                -15.013294768097081
              ],
              [
                -46.52530490778631,
                -15.009063291718636
              ],
              [
                -46.530374876653475,
                -15.00483084534494
              ],
              [
                -46.526869968299835,
                -14.987706916765575
              ],
              [
                -46.520671012855836,
                -14.984101424883958
              ],
              [
                -46.51632254153042,
                -14.981571925287213
              ],
              [
                -46.51685255727133,
                -14.972418508657084
              ],
              [
                -46.530305476747664,
                -14.960273090119582
              ],
              [
                -46.540402900064684,
                -14.951157650008819
              ],
              [
                -46.53971091106771,
                -14.94172621371758
              ],
              [
                -46.539548416709586,
                -14.939507733118369
              ],
              [
                -46.54264742542953,
                -14.93264475015147
              ],
              [
                -46.54610142784828,
                -14.924997806332502
              ],
              [
                -46.541909493777176,
                -14.895580992114937
              ],
              [
                -46.53594907333294,
                -14.88942248941415
              ],
              [
                -46.52998860588527,
                -14.883264040728243
              ],
              [
                -46.52112424095016,
                -14.850729707053969
              ],
              [
                -46.526896707752684,
                -14.840011271200826
              ],
              [
                -46.532669706543935,
                -14.829292357353316
              ],
              [
                -46.53773215349283,
                -14.827586343452616
              ],
              [
                -46.55836901549542,
                -14.820629936312816
              ],
              [
                -46.55864651808114,
                -14.814038486657951
              ],
              [
                -46.55723503671011,
                -14.806628012609774
              ],
              [
                -46.56259898961982,
                -14.799071078847282
              ],
              [
                -46.56296999167748,
                -14.798548083531262
              ],
              [
                -46.562983974603846,
                -14.79852857666889
              ],
              [
                -46.565456006326315,
                -14.785928151565706
              ],
              [
                -46.550473614373324,
                -14.77422520234094
              ],
              [
                -46.54279571716392,
                -14.768228702746274
              ],
              [
                -46.543286206706625,
                -14.760235779597018
              ],
              [
                -46.54375672076992,
                -14.752568804544662
              ],
              [
                -46.53668078689446,
                -14.747011830566686
              ],
              [
                -46.52380142553356,
                -14.744950803883524
              ],
              [
                -46.52196040977819,
                -14.744656304213885
              ],
              [
                -46.509847006548554,
                -14.737815821457465
              ],
              [
                -46.507074551445676,
                -14.731698845613925
              ],
              [
                -46.50781955495047,
                -14.726277878189345
              ],
              [
                -46.50928454578777,
                -14.715622971226503
              ],
              [
                -46.502906623066565,
                -14.703965002765498
              ],
              [
                -46.50028016888949,
                -14.705066522633256
              ],
              [
                -46.4915362225596,
                -14.708734487565144
              ],
              [
                -46.4895812495739,
                -14.709553976280437
              ],
              [
                -46.48467926667103,
                -14.707992471349797
              ],
              [
                -46.47447036235171,
                -14.7047394505099
              ],
              [
                -46.47143988902728,
                -14.706559432498041
              ],
              [
                -46.4684098917001,
                -14.708379422486475
              ],
              [
                -46.467056400029676,
                -14.71278389762654
              ],
              [
                -46.461532435502356,
                -14.73076629189801
              ],
              [
                -46.45422896533266,
                -14.730999799234349
              ],
              [
                -46.44692802215077,
                -14.731232761579882
              ],
              [
                -46.43754060103609,
                -14.742162212450456
              ],
              [
                -46.428753669475576,
                -14.75239263144123
              ],
              [
                -46.41682626073071,
                -14.756017569774503
              ],
              [
                -46.40409884592711,
                -14.759885526242579
              ],
              [
                -46.40203136564742,
                -14.76185401011761
              ],
              [
                -46.38566193736925,
                -14.777440881346212
              ],
              [
                -46.38001947609112,
                -14.782813873055654
              ],
              [
                -46.37654949244785,
                -14.786116836669324
              ],
              [
                -46.3700705622727,
                -14.78816781000296
              ],
              [
                -46.36716356973228,
                -14.789088326382268
              ],
              [
                -46.36682206250665,
                -14.790101304916334
              ],
              [
                -46.36518759822212,
                -14.794943778003793
              ],
              [
                -46.35914961679838,
                -14.794267245868738
              ],
              [
                -46.35524467186304,
                -14.793829778255073
              ],
              [
                -46.350469683735426,
                -14.79606024566314
              ],
              [
                -46.33596131267503,
                -14.802835694060686
              ],
              [
                -46.33496979279571,
                -14.807396619937517
              ],
              [
                -46.333872809816036,
                -14.81244460081395
              ],
              [
                -46.32887333945787,
                -14.813142590643942
              ],
              [
                -46.32176738338815,
                -14.814134079928369
              ],
              [
                -46.32032137964097,
                -14.830330499860704
              ],
              [
                -46.31982787288679,
                -14.835861464061765
              ],
              [
                -46.32448381640201,
                -14.847342386401587
              ],
              [
                -46.31837987575816,
                -14.847076398747653
              ],
              [
                -46.31403940300154,
                -14.854039349651886
              ],
              [
                -46.31003190908048,
                -14.855067832375878
              ],
              [
                -46.30003998108494,
                -14.85763278226983
              ],
              [
                -46.308456894913704,
                -14.877121683380102
              ],
              [
                -46.313313356257076,
                -14.8801016961281
              ],
              [
                -46.31590085146411,
                -14.881689671732374
              ],
              [
                -46.31890328285843,
                -14.899995569825805
              ],
              [
                -46.315046284437344,
                -14.90014608186613
              ],
              [
                -46.30568033908787,
                -14.9111084895179
              ],
              [
                -46.29997090865424,
                -14.90964100946393
              ],
              [
                -46.29423697243188,
                -14.908167495403081
              ],
              [
                -46.29247647151031,
                -14.91742842283123
              ],
              [
                -46.29173496685747,
                -14.921327920012498
              ],
              [
                -46.286410983087116,
                -14.927699867522568
              ],
              [
                -46.25778269999389,
                -14.928434321593512
              ],
              [
                -46.25135078508641,
                -14.924553335119937
              ],
              [
                -46.24695481147461,
                -14.921900855319699
              ],
              [
                -46.24070335248853,
                -14.921431336529139
              ],
              [
                -46.233269940750795,
                -14.921080302884517
              ],
              [
                -46.227443431651274,
                -14.933830745668088
              ],
              [
                -46.22403795744319,
                -14.941284175441867
              ],
              [
                -46.21529253007152,
                -14.943078173004313
              ],
              [
                -46.21296505645668,
                -14.9396256886229
              ],
              [
                -46.21087205128569,
                -14.936519673678971
              ],
              [
                -46.20537559598142,
                -14.936716161434784
              ],
              [
                -46.176045836122874,
                -14.948604083917084
              ],
              [
                -46.16136948214354,
                -14.929451139453167
              ],
              [
                -46.16598150378018,
                -14.908003793751098
              ],
              [
                -46.16098954425305,
                -14.905726773744275
              ],
              [
                -46.159037010108214,
                -14.913361242069811
              ],
              [
                -46.15039259294162,
                -14.921367697966716
              ],
              [
                -46.14531763554858,
                -14.926068148504706
              ],
              [
                -46.13831918088323,
                -14.928048143219867
              ],
              [
                -46.1345037187339,
                -14.923815139665287
              ],
              [
                -46.13190223922175,
                -14.920929165562859
              ],
              [
                -46.1195408219417,
                -14.920173647247895
              ],
              [
                -46.11447935577474,
                -14.928836552901684
              ],
              [
                -46.109961366045525,
                -14.936569016929827
              ],
              [
                -46.08771105499971,
                -14.935945984685283
              ],
              [
                -46.082683159929445,
                -14.917630574363796
              ],
              [
                -46.079091673135956,
                -14.904547167467566
              ],
              [
                -46.07034825676584,
                -14.907968625659151
              ],
              [
                -46.06687276406056,
                -14.909329111421867
              ],
              [
                -46.06190882441109,
                -14.907544115661977
              ],
              [
                -46.05636090613592,
                -14.894556165691993
              ],
              [
                -46.05231595295266,
                -14.885088215122622
              ],
              [
                -46.04173405328119,
                -14.877183259579008
              ],
              [
                -46.03698609429731,
                -14.873636251461171
              ],
              [
                -46.033435604729526,
                -14.879390705630126
              ],
              [
                -46.02085070630777,
                -14.883331177806467
              ],
              [
                -46.003447323499685,
                -14.901847565691456
              ],
              [
                -46.00487676503229,
                -14.91165500247657
              ],
              [
                -46.006801215007066,
                -14.92485042156167
              ],
              [
                -45.995407344469136,
                -14.927022385118544
              ],
              [
                -45.98653735351748,
                -14.940611273160608
              ],
              [
                -45.980351417946565,
                -14.943890764830629
              ],
              [
                -45.971318957371814,
                -14.948677727549727
              ],
              [
                -45.96565300394085,
                -14.965196114488672
              ],
              [
                -45.96929693772371,
                -14.991893971625926
              ],
              [
                -45.97380890014496,
                -14.99510645033044
              ],
              [
                -45.97808733977228,
                -14.998151426580186
              ],
              [
                -45.97449083922636,
                -15.00714139696155
              ],
              [
                -45.97509228131442,
                -15.038461691975025
              ],
              [
                -46.0180473486116,
                -15.080829533662504
              ],
              [
                -46.018573351847266,
                -15.086711498706457
              ],
              [
                -46.019099334079755,
                -15.092592491761497
              ],
              [
                -46.02762174345589,
                -15.101778423582303
              ],
              [
                -46.02804523670643,
                -15.113661847872063
              ],
              [
                -46.037044138615904,
                -15.116034345891599
              ],
              [
                -46.043290110113944,
                -15.117680847993759
              ],
              [
                -46.048976524718505,
                -15.130204306420692
              ],
              [
                -46.04471304436192,
                -15.141716243217045
              ],
              [
                -46.04351756362333,
                -15.144944720408565
              ],
              [
                -46.04944644751037,
                -15.165938578614893
              ],
              [
                -46.061449344176616,
                -15.174923086288446
              ],
              [
                -46.07482025637991,
                -15.175030088586292
              ],
              [
                -46.07828920541735,
                -15.175057604892626
              ],
              [
                -46.07915720062373,
                -15.17799807260755
              ],
              [
                -46.08023469773977,
                -15.181648559634127
              ],
              [
                -46.086984609064935,
                -15.183355554410571
              ],
              [
                -46.09240307077152,
                -15.184725061647304
              ],
              [
                -46.09726102398576,
                -15.192161039993266
              ],
              [
                -46.11881537741295,
                -15.19177605743937
              ],
              [
                -46.118483354563296,
                -15.201631499241472
              ],
              [
                -46.106390406277605,
                -15.210479446856196
              ],
              [
                -46.09838298006901,
                -15.216338378257165
              ],
              [
                -46.09803545661411,
                -15.226044324896838
              ],
              [
                -46.09755743290602,
                -15.239421238241796
              ],
              [
                -46.091924990241125,
                -15.24746571054315
              ],
              [
                -46.08724249552604,
                -15.246152708307873
              ],
              [
                -46.08451753196956,
                -15.245389199586722
              ],
              [
                -46.08082107240145,
                -15.248064167316288
              ],
              [
                -46.07937757590378,
                -15.253657637518243
              ],
              [
                -46.07665107683082,
                -15.264228580763914
              ],
              [
                -46.052202756547295,
                -15.258741558447403
              ],
              [
                -46.05554277771096,
                -15.2516671003115
              ],
              [
                -46.051961812066246,
                -15.245359132826076
              ],
              [
                -46.046595883022896,
                -15.23590768606928
              ],
              [
                -46.04343388351276,
                -15.233875181833032
              ],
              [
                -46.038132425956796,
                -15.23046719850362
              ],
              [
                -46.03863895306376,
                -15.22330975557811
              ],
              [
                -46.03909347514146,
                -15.216879805380493
              ],
              [
                -46.03483797725739,
                -15.214934766615313
              ],
              [
                -46.03165002081204,
                -15.213477779040026
              ],
              [
                -46.03037303698458,
                -15.209491806573054
              ],
              [
                -46.027620065156725,
                -15.200896858861332
              ],
              [
                -46.01009623216351,
                -15.195393356851671
              ],
              [
                -46.00048782124041,
                -15.195554845017762
              ],
              [
                -45.998212300411005,
                -15.197154347572416
              ],
              [
                -45.99395437080163,
                -15.200146290418688
              ],
              [
                -45.99265189165031,
                -15.195411844241494
              ],
              [
                -45.98407495756676,
                -15.195507824711646
              ],
              [
                -45.976428039445224,
                -15.177136896271234
              ],
              [
                -45.97774001492738,
                -15.175588425631716
              ],
              [
                -45.9791475148618,
                -15.173927411818056
              ],
              [
                -45.97450054541721,
                -15.170827927032727
              ],
              [
                -45.97157359484971,
                -15.168876443835192
              ],
              [
                -45.97169557731773,
                -15.165095477849627
              ],
              [
                -45.97204311783906,
                -15.154270032887307
              ],
              [
                -45.96268169622352,
                -15.146674582295741
              ],
              [
                -45.953320766599575,
                -15.13908008772762
              ],
              [
                -45.91803056692256,
                -15.133061581038042
              ],
              [
                -45.87255092593738,
                -15.15743135001589
              ],
              [
                -45.8572380523011,
                -15.148386857887584
              ],
              [
                -45.85746405217129,
                -15.14752389229309
              ],
              [
                -45.85840603262859,
                -15.143927383822051
              ],
              [
                -45.852896579453045,
                -15.144257910708568
              ],
              [
                -45.84530063488026,
                -15.144713894006998
              ],
              [
                -45.834572251808154,
                -15.136911909258453
              ],
              [
                -45.83338077338434,
                -15.130303923271684
              ],
              [
                -45.832174807230416,
                -15.123614458737512
              ],
              [
                -45.827434821992945,
                -15.12234395732606
              ],
              [
                -45.82906331728881,
                -15.119563507423385
              ],
              [
                -45.82496185752284,
                -15.115929512873084
              ],
              [
                -45.82053890742108,
                -15.117879997413757
              ],
              [
                -45.811236500295706,
                -15.112389013014573
              ],
              [
                -45.80441153106246,
                -15.11666096067934
              ],
              [
                -45.80129203343927,
                -15.118613938611292
              ],
              [
                -45.79411363299444,
                -15.115770452819268
              ],
              [
                -45.788726654693704,
                -15.11363544323838
              ],
              [
                -45.75631340694556,
                -15.118084864639316
              ],
              [
                -45.75119845410634,
                -15.121761351894895
              ],
              [
                -45.73967554240277,
                -15.117091358701751
              ],
              [
                -45.738783547137274,
                -15.116824371604192
              ],
              [
                -45.72139169594444,
                -15.111624863685083
              ],
              [
                -45.723315712720606,
                -15.105306914876827
              ],
              [
                -45.723466215468804,
                -15.104812408022584
              ],
              [
                -45.72176422652325,
                -15.104151893718239
              ],
              [
                -45.714824769909065,
                -15.101457402567135
              ],
              [
                -45.71522979626831,
                -15.099594407122671
              ],
              [
                -45.71656977346836,
                -15.093423443398288
              ],
              [
                -45.71254978735061,
                -15.09733645328977
              ],
              [
                -45.710466816777824,
                -15.092937961981377
              ],
              [
                -45.69844143978009,
                -15.091027956937666
              ],
              [
                -45.69328847406435,
                -15.090209955206113
              ],
              [
                -45.69333645715783,
                -15.088423445667557
              ],
              [
                -45.69346248622144,
                -15.083761486970506
              ],
              [
                -45.68781355033427,
                -15.085605444873737
              ],
              [
                -45.68679302094306,
                -15.085938432063134
              ],
              [
                -45.67928158525418,
                -15.083612971758614
              ],
              [
                -45.67840761694861,
                -15.079184955875379
              ],
              [
                -45.67431615238946,
                -15.08004248437376
              ],
              [
                -45.65545087461936,
                -15.048385636773121
              ],
              [
                -45.65794433821812,
                -15.04283215664734
              ],
              [
                -45.66014232192503,
                -15.037938685141773
              ],
              [
                -45.654950395408186,
                -15.02583873403718
              ],
              [
                -45.647211981746416,
                -15.023410237007319
              ],
              [
                -45.6465229431277,
                -15.031250709110662
              ],
              [
                -45.64308249709644,
                -15.016221274303971
              ],
              [
                -45.639080044382105,
                -15.0229917495623
              ],
              [
                -45.62226719373902,
                -15.019254234571369
              ],
              [
                -45.615697236458736,
                -15.013302252231737
              ],
              [
                -45.60812532588932,
                -14.996176360205899
              ],
              [
                -45.6008558724448,
                -14.995822342000343
              ],
              [
                -45.60056538582455,
                -14.995808327551863
              ],
              [
                -45.60067288676815,
                -14.99538684120547
              ],
              [
                -45.60228538787943,
                -14.989085401889922
              ],
              [
                -45.59643845254564,
                -14.985297381314796
              ],
              [
                -45.59409346746985,
                -14.983778409897733
              ],
              [
                -45.59392495356152,
                -14.982365385832434
              ],
              [
                -45.5930539877175,
                -14.975055460887479
              ],
              [
                -45.58826152587446,
                -14.976952923082951
              ],
              [
                -45.586353524872045,
                -14.977708413197654
              ],
              [
                -45.5870570278505,
                -14.975801927603378
              ],
              [
                -45.58799155016257,
                -14.973270439077035
              ],
              [
                -45.5666242314348,
                -14.95604700971407
              ],
              [
                -45.56570526125994,
                -14.944415066981703
              ],
              [
                -45.553229871921346,
                -14.944632075037914
              ],
              [
                -45.54734544237123,
                -14.93851058411876
              ],
              [
                -45.5330430150004,
                -14.939179072233701
              ],
              [
                -45.52262311264018,
                -14.950536485909677
              ],
              [
                -45.5203941110397,
                -14.952966436654176
              ],
              [
                -45.513562667357846,
                -14.95352342698395
              ],
              [
                -45.51108721676192,
                -14.952310427578146
              ],
              [
                -45.49806631161063,
                -14.94593146823416
              ],
              [
                -45.49472433176854,
                -14.951629426836929
              ],
              [
                -45.48963033439684,
                -14.960312870796141
              ],
              [
                -45.469586527419445,
                -14.949689379546149
              ],
              [
                -45.45470816827806,
                -14.953270343582535
              ],
              [
                -45.454912130539824,
                -14.948625884140561
              ],
              [
                -45.44869071427943,
                -14.942243877221504
              ],
              [
                -45.44953469648455,
                -14.940651409077145
              ],
              [
                -45.4507931868253,
                -14.938276925263922
              ],
              [
                -45.44207179126504,
                -14.93480641878068
              ],
              [
                -45.43450588304679,
                -14.922087995591259
              ],
              [
                -45.42549746719176,
                -14.91999000857951
              ],
              [
                -45.42169697387329,
                -14.924780967617787
              ],
              [
                -45.4206214890094,
                -14.92613644872573
              ],
              [
                -45.39937817490184,
                -14.917050464555794
              ],
              [
                -45.380708865171634,
                -14.897360030350432
              ],
              [
                -45.37031643360974,
                -14.893686044158153
              ],
              [
                -45.3710854326563,
                -14.88619460153708
              ],
              [
                -45.37166944376724,
                -14.880502637019589
              ],
              [
                -45.36354903838154,
                -14.87124466376687
              ],
              [
                -45.357398575244304,
                -14.875500134678733
              ],
              [
                -45.355145074583106,
                -14.877059625453835
              ],
              [
                -45.35151511179063,
                -14.875636627273389
              ],
              [
                -45.350071634410085,
                -14.869919669270748
              ],
              [
                -45.34949163849421,
                -14.867624164718107
              ],
              [
                -45.3524471380294,
                -14.863658227352094
              ],
              [
                -45.338025239770566,
                -14.86074418751991
              ],
              [
                -45.32968533675812,
                -14.85905968141717
              ],
              [
                -45.32207289078631,
                -14.851617206502484
              ],
              [
                -45.31762242900915,
                -14.85211968854334
              ],
              [
                -45.313945977986975,
                -14.845756737376083
              ],
              [
                -45.31101100049414,
                -14.840677259997669
              ],
              [
                -45.31112250192564,
                -14.840499254245753
              ],
              [
                -45.316727975342815,
                -14.83155133098456
              ],
              [
                -45.309739020252486,
                -14.836243302813122
              ],
              [
                -45.305577576537104,
                -14.83389729515158
              ],
              [
                -45.302151098832304,
                -14.828395836394238
              ],
              [
                -45.29504164945017,
                -14.826912831369654
              ],
              [
                -45.293371161375205,
                -14.827787317221532
              ],
              [
                -45.28526471725449,
                -14.832028263694674
              ],
              [
                -45.28973071771608,
                -14.82528833540964
              ],
              [
                -45.28952170490396,
                -14.825030804551748
              ],
              [
                -45.28649075364508,
                -14.82128832616286
              ],
              [
                -45.28831774466269,
                -14.817660355814285
              ],
              [
                -45.2764183368244,
                -14.812173872816453
              ],
              [
                -45.27398888070395,
                -14.811053908064597
              ],
              [
                -45.27354885555585,
                -14.80932489446821
              ],
              [
                -45.27076038895312,
                -14.798374944032613
              ],
              [
                -45.26495047564503,
                -14.800787916402868
              ],
              [
                -45.26262445573822,
                -14.801753915542186
              ],
              [
                -45.26093149388225,
                -14.796797444743152
              ],
              [
                -45.258292533943425,
                -14.789075481571581
              ],
              [
                -45.247015621852675,
                -14.783161018306057
              ],
              [
                -45.246298158965615,
                -14.783544501760439
              ],
              [
                -45.2415396638876,
                -14.786082991272758
              ],
              [
                -45.23123476948142,
                -14.77456202987067
              ],
              [
                -45.22974031158066,
                -14.76876204245174
              ],
              [
                -45.22884182646173,
                -14.765277102429637
              ],
              [
                -45.21623542880699,
                -14.75744559220323
              ],
              [
                -45.20515255076822,
                -14.74429215818983
              ],
              [
                -45.19323661995543,
                -14.748444618411252
              ],
              [
                -45.190878163609796,
                -14.749266613349366
              ],
              [
                -45.19039415845169,
                -14.747192620750745
              ],
              [
                -45.189182670079674,
                -14.742000171299422
              ],
              [
                -45.182682708623275,
                -14.743796637100688
              ],
              [
                -45.18148271876973,
                -14.744128643000261
              ],
              [
                -45.17453428791741,
                -14.7411666321512
              ],
              [
                -45.17382728624227,
                -14.740864637679328
              ],
              [
                -45.171597844862845,
                -14.734230190965585
              ],
              [
                -45.16318239670267,
                -14.731937182565963
              ],
              [
                -45.165801878651614,
                -14.734981645207721
              ],
              [
                -45.15246196894289,
                -14.742094578379005
              ],
              [
                -45.13155013324734,
                -14.745858520892853
              ],
              [
                -45.09504142049009,
                -14.752430431032687
              ],
              [
                -45.08904846498304,
                -14.75049896181781
              ],
              [
                -45.083055547477024,
                -14.748568452601845
              ],
              [
                -45.063024239651334,
                -14.725741563354651
              ],
              [
                -45.053707866640536,
                -14.699020213394142
              ],
              [
                -45.04639292850321,
                -14.689281232267065
              ],
              [
                -45.045460443407165,
                -14.692334708485426
              ],
              [
                -45.044515945333394,
                -14.695428185445666
              ],
              [
                -45.03953099531867,
                -14.690439219249319
              ],
              [
                -45.039420970374124,
                -14.687676734461148
              ],
              [
                -45.039107000618074,
                -14.679809787633692
              ],
              [
                -45.0145012234255,
                -14.67281579071132
              ],
              [
                -44.99532739607098,
                -14.667366279257823
              ],
              [
                -44.99588387505454,
                -14.66171331286116
              ],
              [
                -44.9962103945627,
                -14.658396823164027
              ],
              [
                -44.99232690603634,
                -14.659592840199124
              ],
              [
                -44.985515955733035,
                -14.661690303707086
              ],
              [
                -44.98363800801099,
                -14.654194865991325
              ],
              [
                -44.98293099603345,
                -14.651371845543688
              ],
              [
                -44.979774526625285,
                -14.651467368411506
              ],
              [
                -44.973518068355396,
                -14.651656360259706
              ],
              [
                -44.975825082054904,
                -14.64867885904732
              ],
              [
                -44.980180531946715,
                -14.643056418186974
              ],
              [
                -44.9619082327748,
                -14.6314054493887
              ],
              [
                -44.95695525588613,
                -14.63535691813732
              ],
              [
                -44.9546462493232,
                -14.637198891102532
              ],
              [
                -44.95107680259672,
                -14.631206921514373
              ],
              [
                -44.95104879591455,
                -14.63115891475106
              ],
              [
                -44.950996319359845,
                -14.631149914712381
              ],
              [
                -44.94260836642192,
                -14.629773912156434
              ],
              [
                -44.94396785391163,
                -14.62729743130376
              ],
              [
                -44.94617137535856,
                -14.623283484096921
              ],
              [
                -44.94149239842614,
                -14.626059937325131
              ],
              [
                -44.93972741590011,
                -14.627107446979453
              ],
              [
                -44.91626010125601,
                -14.619211446806332
              ],
              [
                -44.91656962305444,
                -14.614175972378742
              ],
              [
                -44.90756818289626,
                -14.612939983946223
              ],
              [
                -44.90834768790769,
                -14.609492996876488
              ],
              [
                -44.90979217292131,
                -14.603107518505471
              ],
              [
                -44.8978612743607,
                -14.604761003708614
              ],
              [
                -44.896065793574216,
                -14.605010015072992
              ],
              [
                -44.894997308866216,
                -14.603626487455161
              ],
              [
                -44.889966341028845,
                -14.59711354850572
              ],
              [
                -44.876054980665714,
                -14.592826526720163
              ],
              [
                -44.87579648087733,
                -14.596020540194912
              ],
              [
                -44.8755384770855,
                -14.599213497677985
              ],
              [
                -44.86725957016038,
                -14.587093556553825
              ],
              [
                -44.861566638088874,
                -14.578759095380175
              ],
              [
                -44.85892163442299,
                -14.578375620043186
              ],
              [
                -44.8502892003254,
                -14.5771210914374
              ],
              [
                -44.843013271832646,
                -14.580372070278209
              ],
              [
                -44.84348529871687,
                -14.565864674829502
              ],
              [
                -44.847119240100035,
                -14.57503261537544
              ],
              [
                -44.84968323112659,
                -14.570696630777547
              ],
              [
                -44.85166173078736,
                -14.570783637717083
              ],
              [
                -44.858377672330484,
                -14.571077626704563
              ],
              [
                -44.853711699564535,
                -14.566693144752396
              ],
              [
                -44.8545382161224,
                -14.562180211175331
              ],
              [
                -44.84954372769448,
                -14.562325185579947
              ],
              [
                -44.84650980024874,
                -14.557577189668676
              ],
              [
                -44.84668529588238,
                -14.545043771933216
              ],
              [
                -44.839481356239595,
                -14.539906291096106
              ],
              [
                -44.83799337258498,
                -14.540353796822705
              ],
              [
                -44.83246043277472,
                -14.54201776821378
              ],
              [
                -44.82393497217229,
                -14.538934786792652
              ],
              [
                -44.82601800168037,
                -14.533740324514975
              ],
              [
                -44.83447042436626,
                -14.535473836878186
              ],
              [
                -44.83137695478656,
                -14.526698883003805
              ],
              [
                -44.8316494445223,
                -14.526173865621315
              ],
              [
                -44.83587344789913,
                -14.518047928624263
              ],
              [
                -44.830518485292366,
                -14.519898907215127
              ],
              [
                -44.82813551768043,
                -14.518318911524068
              ],
              [
                -44.82267102802047,
                -14.514695415705752
              ],
              [
                -44.82563201542241,
                -14.512722919673035
              ],
              [
                -44.834153957144956,
                -14.507046004502754
              ],
              [
                -44.83281947610307,
                -14.499384540063822
              ],
              [
                -44.8165121150314,
                -14.503440488400566
              ],
              [
                -44.81339913207489,
                -14.504214493348508
              ],
              [
                -44.81074268798398,
                -14.50029598720601
              ],
              [
                -44.80988768228783,
                -14.499035006275093
              ],
              [
                -44.817840627243235,
                -14.489750549735852
              ],
              [
                -44.80437772271241,
                -14.496112495209758
              ],
              [
                -44.80307424336807,
                -14.49611651491992
              ],
              [
                -44.79390731031225,
                -14.496144499819753
              ],
              [
                -44.79393131829046,
                -14.491132540907106
              ],
              [
                -44.79394833997045,
                -14.487406056278369
              ],
              [
                -44.788497851064356,
                -14.48822603289545
              ],
              [
                -44.78714837373453,
                -14.48842903233449
              ],
              [
                -44.78592190732371,
                -14.487033045575593
              ],
              [
                -44.78163092136784,
                -14.482148578418942
              ],
              [
                -44.76895454870964,
                -14.476893069949227
              ],
              [
                -44.765103061317824,
                -14.477393038268083
              ],
              [
                -44.7624310855531,
                -14.477740043550961
              ],
              [
                -44.760120616312925,
                -14.475086059470081
              ],
              [
                -44.75986360695546,
                -14.474791043794873
              ],
              [
                -44.75973311181163,
                -14.474907066872294
              ],
              [
                -44.75632464417885,
                -14.477935536785985
              ],
              [
                -44.75337365924066,
                -14.475250033785912
              ],
              [
                -44.749071704606266,
                -14.471335059827545
              ],
              [
                -44.74860524261829,
                -14.469038068808597
              ],
              [
                -44.74762273049287,
                -14.464199113158536
              ],
              [
                -44.743030800085045,
                -14.465843101331648
              ],
              [
                -44.741405795327836,
                -14.466424592024582
              ],
              [
                -44.72523943651274,
                -14.457204623406138
              ],
              [
                -44.72485996486011,
                -14.455427143428427
              ],
              [
                -44.723403954200045,
                -14.448612672855184
              ],
              [
                -44.71865450453921,
                -14.450563646915036
              ],
              [
                -44.712462057083684,
                -14.443518175551759
              ],
              [
                -44.71072306963171,
                -14.446645663745317
              ],
              [
                -44.71014907645179,
                -14.447678651543505
              ],
              [
                -44.70484712268287,
                -14.446138151636326
              ],
              [
                -44.700878667358225,
                -14.449340629522162
              ],
              [
                -44.69873067840607,
                -14.437004188985567
              ],
              [
                -44.69779122191798,
                -14.431606727824034
              ],
              [
                -44.69274926219122,
                -14.432172229715578
              ],
              [
                -44.69381926401726,
                -14.423044293473362
              ],
              [
                -44.6874343188612,
                -14.421095788179045
              ],
              [
                -44.687936828572106,
                -14.41623532129404
              ],
              [
                -44.679022386661096,
                -14.42115977328138
              ],
              [
                -44.67379390825364,
                -14.424048723874668
              ],
              [
                -44.66915144835765,
                -14.415752779803404
              ],
              [
                -44.66756545647297,
                -14.423398228045116
              ],
              [
                -44.664428489526976,
                -14.417699244942067
              ],
              [
                -44.65762856148645,
                -14.415918260942362
              ],
              [
                -44.662515022807746,
                -14.407999298049168
              ],
              [
                -44.66296654143527,
                -14.407267336234948
              ],
              [
                -44.658462604014375,
                -14.395312864481411
              ],
              [
                -44.652040130605705,
                -14.393127879591443
              ],
              [
                -44.65112364641146,
                -14.396829358712573
              ],
              [
                -44.648902172041375,
                -14.397147865969794
              ],
              [
                -44.645402672816495,
                -14.397648858927825
              ],
              [
                -44.640683720987305,
                -14.39572183741839
              ],
              [
                -44.64275771790377,
                -14.392454884674631
              ],
              [
                -44.64337623359871,
                -14.391480376611577
              ],
              [
                -44.63992576077968,
                -14.390431883994825
              ],
              [
                -44.63767677845114,
                -14.389748877243012
              ],
              [
                -44.63315130494194,
                -14.39395036113465
              ],
              [
                -44.627860380281206,
                -14.3818139222009
              ],
              [
                -44.625191883225995,
                -14.375693440523271
              ],
              [
                -44.61820746288859,
                -14.37491893219696
              ],
              [
                -44.61618096891081,
                -14.37469393107353
              ],
              [
                -44.61488146345324,
                -14.372542956816952
              ],
              [
                -44.61032202746811,
                -14.364997984510154
              ],
              [
                -44.604552061991456,
                -14.365989460625395
              ],
              [
                -44.597268647461895,
                -14.367240959574286
              ],
              [
                -44.58747872871965,
                -14.35562501090388
              ],
              [
                -44.58574973860204,
                -14.353573034330415
              ],
              [
                -44.58171330932187,
                -14.352125013156934
              ],
              [
                -44.57468034256328,
                -14.349601510347842
              ],
              [
                -44.57346884698912,
                -14.347666552960591
              ],
              [
                -44.57188637352277,
                -14.345140555509708
              ],
              [
                -44.56918940575203,
                -14.346193040532723
              ],
              [
                -44.56415391752467,
                -14.348158017037676
              ],
              [
                -44.564568915527104,
                -14.344601542236182
              ],
              [
                -44.56515042773325,
                -14.339617076349995
              ],
              [
                -44.559455997663726,
                -14.340056074950796
              ],
              [
                -44.55678649980334,
                -14.336592081318129
              ],
              [
                -44.5504640640086,
                -14.339744558445592
              ],
              [
                -44.54470111712856,
                -14.342618526212036
              ],
              [
                -44.542413628063365,
                -14.336973064426557
              ],
              [
                -44.54012666298828,
                -14.33132810564649
              ],
              [
                -44.52873675003166,
                -14.327681080232923
              ],
              [
                -44.521061813228194,
                -14.329242052674678
              ],
              [
                -44.518484321446266,
                -14.329766062100072
              ],
              [
                -44.51593586764648,
                -14.327386080144139
              ],
              [
                -44.51042994108915,
                -14.322244589849777
              ],
              [
                -44.50847092291396,
                -14.325172569785572
              ],
              [
                -44.508452420017306,
                -14.32519958059056
              ],
              [
                -44.50838140967765,
                -14.325156573729485
              ],
              [
                -44.50302447154847,
                -14.321880580407454
              ],
              [
                -44.50119151306874,
                -14.323846574378686
              ],
              [
                -44.49803002893826,
                -14.327238061453349
              ],
              [
                -44.486761104985845,
                -14.328074535146124
              ],
              [
                -44.48272564762803,
                -14.321672560015605
              ],
              [
                -44.48002368784111,
                -14.319990563589261
              ],
              [
                -44.47195124317183,
                -14.31496407625933
              ],
              [
                -44.47044174247813,
                -14.315574094989904
              ],
              [
                -44.46915727009761,
                -14.316094048649708
              ],
              [
                -44.46633077352327,
                -14.312077079163018
              ],
              [
                -44.465071818075046,
                -14.310287612857696
              ],
              [
                -44.464588299496214,
                -14.310595078170659
              ],
              [
                -44.461453852667724,
                -14.312592089728028
              ],
              [
                -44.453026915002994,
                -14.31142408043993
              ],
              [
                -44.439261029464255,
                -14.302865610860229
              ],
              [
                -44.43016811649621,
                -14.289290164704463
              ],
              [
                -44.410267291329944,
                -14.281882686808334
              ],
              [
                -44.409452307572565,
                -14.281579199265023
              ],
              [
                -44.39908392048349,
                -14.277850181282423
              ],
              [
                -44.39673640538607,
                -14.273034239533372
              ],
              [
                -44.39415747089407,
                -14.276785680377108
              ],
              [
                -44.389566985596026,
                -14.275692210220505
              ],
              [
                -44.389489466953336,
                -14.276409193735335
              ],
              [
                -44.389100489683216,
                -14.280031680080109
              ],
              [
                -44.377924085258044,
                -14.275744680133165
              ],
              [
                -44.37738910759561,
                -14.271857678833351
              ],
              [
                -44.37706860038914,
                -14.269527713441894
              ],
              [
                -44.37575110435131,
                -14.271138678420657
              ],
              [
                -44.37323913253141,
                -14.274210687497286
              ],
              [
                -44.3694111854278,
                -14.271179672412742
              ],
              [
                -44.3611767250529,
                -14.272365677234939
              ],
              [
                -44.352482307403996,
                -14.265738682757805
              ],
              [
                -44.352613306793394,
                -14.260988210853315
              ],
              [
                -44.35262629252226,
                -14.260519227725934
              ],
              [
                -44.35200781224925,
                -14.260722207443786
              ],
              [
                -44.3456313819823,
                -14.262817207900392
              ],
              [
                -44.349135852123695,
                -14.259462734231915
              ],
              [
                -44.35142383120021,
                -14.257272727422404
              ],
              [
                -44.3411999129583,
                -14.256356239670637
              ],
              [
                -44.3407138980343,
                -14.256313218108813
              ],
              [
                -44.340703909371186,
                -14.256170232961278
              ],
              [
                -44.34018245050636,
                -14.248956269945499
              ],
              [
                -44.32035412294951,
                -14.244315266613015
              ],
              [
                -44.31606763206736,
                -14.239909298171296
              ],
              [
                -44.309269205017124,
                -14.246103737974725
              ],
              [
                -44.301922755593836,
                -14.252797704807978
              ],
              [
                -44.290860328607565,
                -14.252279181243821
              ],
              [
                -44.28627438335685,
                -14.248914682966058
              ],
              [
                -44.28395439448816,
                -14.247213181400914
              ],
              [
                -44.28160638878588,
                -14.249485696594423
              ],
              [
                -44.2802119178724,
                -14.250834683022973
              ],
              [
                -44.27631444991055,
                -14.251995148365369
              ],
              [
                -44.27357946292199,
                -14.250035673670395
              ],
              [
                -44.268154530517116,
                -14.246148670168145
              ],
              [
                -44.25556511059468,
                -14.251010140275868
              ],
              [
                -44.25401212432579,
                -14.251609125001915
              ],
              [
                -44.247091209020745,
                -14.259672554185057
              ],
              [
                -44.244020227684324,
                -14.25665960634399
              ],
              [
                -44.24920616637746,
                -14.253043107142009
              ],
              [
                -44.24662322037421,
                -14.251516615074452
              ],
              [
                -44.23829524226574,
                -14.257559563181657
              ],
              [
                -44.23898227791527,
                -14.250631117554638
              ],
              [
                -44.23923826439205,
                -14.248049148719437
              ],
              [
                -44.234663295857914,
                -14.246494145465462
              ],
              [
                -44.24019975430693,
                -14.240255672835248
              ],
              [
                -44.21764943657561,
                -14.244535118670179
              ],
              [
                -44.214657468968994,
                -14.232850706862896
              ],
              [
                -44.20941501023765,
                -14.236206140555367
              ],
              [
                -44.208857532783014,
                -14.244126594316711
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 12,
        "nome": "Mato Grosso do Sul",
        "sigla": "MS",
        "regiao_id": 5,
        "codigo_ibg": 50
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -53.87405934722708,
                -17.921778476888576
              ],
              [
                -53.87181987205658,
                -17.92141749687943
              ],
              [
                -53.87121535413566,
                -17.922958987621925
              ],
              [
                -53.86965037633946,
                -17.92694944524603
              ],
              [
                -53.86641541467288,
                -17.92495245891337
              ],
              [
                -53.85954696283268,
                -17.920711987341306
              ],
              [
                -53.859957961557015,
                -17.924151454303555
              ],
              [
                -53.849875012326144,
                -17.92877939286899
              ],
              [
                -53.84996649749731,
                -17.931782412944646
              ],
              [
                -53.85004149632348,
                -17.934220372455012
              ],
              [
                -53.845959497333325,
                -17.93344637865362
              ],
              [
                -53.844152534060655,
                -17.933103891642784
              ],
              [
                -53.84360753093479,
                -17.934933382268788
              ],
              [
                -53.84223054509194,
                -17.939554326591757
              ],
              [
                -53.84046403551908,
                -17.93774983508412
              ],
              [
                -53.837043085269315,
                -17.93425436585551
              ],
              [
                -53.83582705803394,
                -17.941904317308307
              ],
              [
                -53.83509857469509,
                -17.946488816409392
              ],
              [
                -53.83285459882705,
                -17.944567306220698
              ],
              [
                -53.83031311176099,
                -17.942390820407848
              ],
              [
                -53.82365412594129,
                -17.948256271082126
              ],
              [
                -53.82444713284033,
                -17.952100745222015
              ],
              [
                -53.82496062041278,
                -17.95459024460075
              ],
              [
                -53.82096063560147,
                -17.961609685862364
              ],
              [
                -53.8187371664215,
                -17.957920199465047
              ],
              [
                -53.81759464493162,
                -17.958134702670566
              ],
              [
                -53.80956770971303,
                -17.959637191031423
              ],
              [
                -53.81215169255502,
                -17.962879653283938
              ],
              [
                -53.81265217129477,
                -17.963507650783924
              ],
              [
                -53.808100682927524,
                -17.97179662110028
              ],
              [
                -53.805844211250374,
                -17.96837862843046
              ],
              [
                -53.80500922662094,
                -17.967113622884888
              ],
              [
                -53.804598733115405,
                -17.969464106562093
              ],
              [
                -53.803850231739936,
                -17.973743083947497
              ],
              [
                -53.79971476111288,
                -17.97372160370175
              ],
              [
                -53.79767574859598,
                -17.97371110064989
              ],
              [
                -53.797316779621255,
                -17.976099083291185
              ],
              [
                -53.796979280901596,
                -17.978337562641627
              ],
              [
                -53.79610626137771,
                -17.977500085127016
              ],
              [
                -53.79267928465339,
                -17.97421107796787
              ],
              [
                -53.79348626437374,
                -17.98027553641707
              ],
              [
                -53.78876479971803,
                -17.980324023397323
              ],
              [
                -53.785573290753625,
                -17.98969451036461
              ],
              [
                -53.78382280212717,
                -17.994832444479943
              ],
              [
                -53.778764357503654,
                -17.997576434802138
              ],
              [
                -53.77370738486916,
                -18.000321938119722
              ],
              [
                -53.75962196686318,
                -17.998469890986566
              ],
              [
                -53.73523366471542,
                -17.99526436603613
              ],
              [
                -53.72994517172279,
                -17.999854821674436
              ],
              [
                -53.726294693985096,
                -18.00302377464304
              ],
              [
                -53.72238372644886,
                -18.000208292212267
              ],
              [
                -53.71878876804574,
                -17.99762080357576
              ],
              [
                -53.71506480874812,
                -17.999358269639394
              ],
              [
                -53.71511930250383,
                -18.00186878675878
              ],
              [
                -53.71520629504404,
                -18.00583727756319
              ],
              [
                -53.70023088768517,
                -18.005375723133504
              ],
              [
                -53.70006989884262,
                -18.005370726752865
              ],
              [
                -53.70000840212255,
                -18.00542820234677
              ],
              [
                -53.691680927989694,
                -18.01322567628978
              ],
              [
                -53.69013296082086,
                -18.00377020093237
              ],
              [
                -53.689847987598895,
                -18.002029710863052
              ],
              [
                -53.68224604477781,
                -17.99721124002614
              ],
              [
                -53.67854207950994,
                -17.994863707084594
              ],
              [
                -53.6783275825204,
                -17.99395272555155
              ],
              [
                -53.67724057582515,
                -17.989342260096894
              ],
              [
                -53.67090665110422,
                -17.98778124413862
              ],
              [
                -53.6656536799779,
                -17.986486222714383
              ],
              [
                -53.66195922572722,
                -17.98557522049558
              ],
              [
                -53.6607387596091,
                -17.977062258890335
              ],
              [
                -53.6340959618817,
                -17.976399699331203
              ],
              [
                -53.62798296960716,
                -17.9799831563049
              ],
              [
                -53.614645060437795,
                -17.9778071646179
              ],
              [
                -53.609575099342635,
                -17.988517608796403
              ],
              [
                -53.60896807616821,
                -17.98980008963355
              ],
              [
                -53.60648808953534,
                -17.995041065364077
              ],
              [
                -53.588648701586514,
                -18.00456196890013
              ],
              [
                -53.589037188094764,
                -18.01215995640755
              ],
              [
                -53.583538715877175,
                -18.009365453983403
              ],
              [
                -53.57451278242889,
                -18.01220241594765
              ],
              [
                -53.570746823547,
                -18.009162908936748
              ],
              [
                -53.56448585592833,
                -18.004110418576186
              ],
              [
                -53.55672990020983,
                -18.005204382397825
              ],
              [
                -53.555660425748975,
                -18.013669872409352
              ],
              [
                -53.5554864092389,
                -18.01504485491427
              ],
              [
                -53.55214292707888,
                -18.014673836202
              ],
              [
                -53.547041957977335,
                -18.01410834996048
              ],
              [
                -53.52854160472153,
                -18.021129264814505
              ],
              [
                -53.517371670920426,
                -18.02019773303976
              ],
              [
                -53.513600190876694,
                -18.025677195449514
              ],
              [
                -53.513037703660345,
                -18.026494195440726
              ],
              [
                -53.501372259610584,
                -18.02769368102381
              ],
              [
                -53.498622275051744,
                -18.035457133898227
              ],
              [
                -53.48556434598118,
                -18.039950062458683
              ],
              [
                -53.47603944328651,
                -18.035804064976904
              ],
              [
                -53.472073442701706,
                -18.038699530289716
              ],
              [
                -53.46734399208268,
                -18.03444254328237
              ],
              [
                -53.465874042323726,
                -18.02389959418919
              ],
              [
                -53.459299074016364,
                -18.018855593080506
              ],
              [
                -53.457886150168775,
                -18.007816646359945
              ],
              [
                -53.456789147320336,
                -17.999248690517447
              ],
              [
                -53.452989703585416,
                -17.99883166589786
              ],
              [
                -53.44868223963134,
                -17.998359171259416
              ],
              [
                -53.44922422688018,
                -17.995374171816103
              ],
              [
                -53.44981420749594,
                -17.99212770165006
              ],
              [
                -53.440101326660965,
                -17.98889168979245
              ],
              [
                -53.43851185173039,
                -17.98347671780983
              ],
              [
                -53.43598183814223,
                -17.98629521103492
              ],
              [
                -53.42990038906868,
                -17.983540710100222
              ],
              [
                -53.42976688195924,
                -17.986140176255958
              ],
              [
                -53.42970390008433,
                -17.98736366268119
              ],
              [
                -53.425069913183485,
                -17.988838155619813
              ],
              [
                -53.4208234760341,
                -17.985338191556146
              ],
              [
                -53.417374437197765,
                -17.994346615071912
              ],
              [
                -53.41212703683596,
                -17.988040134950367
              ],
              [
                -53.41076149073489,
                -17.992772631586227
              ],
              [
                -53.40466654298636,
                -17.99288710491252
              ],
              [
                -53.380265172536305,
                -18.008306477850883
              ],
              [
                -53.369996266702344,
                -18.006939962347527
              ],
              [
                -53.35972734587977,
                -18.005574440847624
              ],
              [
                -53.35540190231942,
                -18.006129943620923
              ],
              [
                -53.34209145392763,
                -18.007840402914983
              ],
              [
                -53.335164023297246,
                -18.006448392858765
              ],
              [
                -53.33344154621664,
                -18.004391365704603
              ],
              [
                -53.330484579980535,
                -18.000858913032356
              ],
              [
                -53.29850730665247,
                -17.995084866198447
              ],
              [
                -53.237258224072946,
                -18.00710766955904
              ],
              [
                -53.21709034845725,
                -18.013118603700118
              ],
              [
                -53.21299188142885,
                -18.015860542308598
              ],
              [
                -53.20464543418246,
                -18.021443510708718
              ],
              [
                -53.20311543620361,
                -18.021061024610738
              ],
              [
                -53.19828847313022,
                -18.019854499992796
              ],
              [
                -53.193650996053265,
                -18.028832439478737
              ],
              [
                -53.19002452002758,
                -18.03585240519887
              ],
              [
                -53.18160304970726,
                -18.038723879472933
              ],
              [
                -53.1731816343947,
                -18.04159633774008
              ],
              [
                -53.15325874011381,
                -18.04101830479198
              ],
              [
                -53.077378335892064,
                -18.03916212760091
              ],
              [
                -53.071484380707645,
                -18.039018135614434
              ],
              [
                -53.07520829617299,
                -18.05034310526194
              ],
              [
                -53.08633871266791,
                -18.055275575885318
              ],
              [
                -53.103101069247515,
                -18.057411635009558
              ],
              [
                -53.12545489671366,
                -18.060260670879657
              ],
              [
                -53.1388308038206,
                -18.071186157460428
              ],
              [
                -53.14273524498626,
                -18.081386617742147
              ],
              [
                -53.13552871497478,
                -18.1154479491472
              ],
              [
                -53.12851420177307,
                -18.138031807167845
              ],
              [
                -53.12887418081919,
                -18.149141774661746
              ],
              [
                -53.1292346428384,
                -18.16025221817279
              ],
              [
                -53.10968413623531,
                -18.219110402783173
              ],
              [
                -53.10982504990787,
                -18.24760675469534
              ],
              [
                -53.10568156095555,
                -18.259887712826472
              ],
              [
                -53.101538044984856,
                -18.272168154967723
              ],
              [
                -53.10105203479577,
                -18.29309655100197
              ],
              [
                -53.10066698078264,
                -18.30968696355474
              ],
              [
                -53.098362460926325,
                -18.312388942785375
              ],
              [
                -53.09605851206586,
                -18.315090929017014
              ],
              [
                -53.06943309744257,
                -18.34242775986081
              ],
              [
                -53.04403278978222,
                -18.34346770219968
              ],
              [
                -53.03557833915274,
                -18.347675143238593
              ],
              [
                -53.029260874052106,
                -18.350818632377564
              ],
              [
                -53.02431794757117,
                -18.35015259886488
              ],
              [
                -53.01501606050216,
                -18.329240679156214
              ],
              [
                -53.01343556972351,
                -18.325686700944615
              ],
              [
                -53.00890860278511,
                -18.32265018502254
              ],
              [
                -53.00438264083835,
                -18.31961418010635
              ],
              [
                -52.97885139360523,
                -18.303874727803645
              ],
              [
                -52.94890812230309,
                -18.296118683060588
              ],
              [
                -52.936116222161274,
                -18.29779013549601
              ],
              [
                -52.93610970420424,
                -18.29779163647295
              ],
              [
                -52.92332429004206,
                -18.29946261392765
              ],
              [
                -52.88066809717102,
                -18.31161745267124
              ],
              [
                -52.876258590553846,
                -18.31290091787869
              ],
              [
                -52.871849132937825,
                -18.31418493408325
              ],
              [
                -52.82093952750451,
                -18.310605336278467
              ],
              [
                -52.79652666903252,
                -18.317303758122428
              ],
              [
                -52.78899770784887,
                -18.321221222734426
              ],
              [
                -52.78146876167183,
                -18.32513914734092
              ],
              [
                -52.766002843033824,
                -18.33924955448195
              ],
              [
                -52.75837488833141,
                -18.348348523676908
              ],
              [
                -52.758524876294516,
                -18.35196049091722
              ],
              [
                -52.76140079134106,
                -18.3708558917625
              ],
              [
                -52.78012962079847,
                -18.38558137417227
              ],
              [
                -52.785566566545796,
                -18.389856398796237
              ],
              [
                -52.7917889794813,
                -18.402131348756733
              ],
              [
                -52.79801143238765,
                -18.414406814757456
              ],
              [
                -52.873277352073885,
                -18.427667412733147
              ],
              [
                -52.880456181316866,
                -18.465039236007918
              ],
              [
                -52.898593018421764,
                -18.476376751671598
              ],
              [
                -52.903151476152516,
                -18.482577744746898
              ],
              [
                -52.907710452872024,
                -18.48877920283499
              ],
              [
                -52.91525983539519,
                -18.508446130925382
              ],
              [
                -52.94404609250445,
                -18.51902166926214
              ],
              [
                -52.96184790605055,
                -18.540364116254423
              ],
              [
                -52.96442537464135,
                -18.549572578171833
              ],
              [
                -52.95653089459162,
                -18.558209530959633
              ],
              [
                -52.9531249038119,
                -18.561934496848956
              ],
              [
                -52.95237440466293,
                -18.56785494981502
              ],
              [
                -52.95131440590503,
                -18.576217410224427
              ],
              [
                -52.947161908498146,
                -18.57995138717038
              ],
              [
                -52.94103542840497,
                -18.585461358248644
              ],
              [
                -52.933949936704806,
                -18.598655256987843
              ],
              [
                -52.93527241743234,
                -18.602110275126204
              ],
              [
                -52.939367392748316,
                -18.612806248425944
              ],
              [
                -52.92776694252562,
                -18.612891215437493
              ],
              [
                -52.92062500811327,
                -18.612943697976462
              ],
              [
                -52.91381806840078,
                -18.619363629290177
              ],
              [
                -52.91493503298784,
                -18.628731610838102
              ],
              [
                -52.91560300938417,
                -18.634339588824847
              ],
              [
                -52.91612346633365,
                -18.638706583908686
              ],
              [
                -52.91365551303546,
                -18.640193529048705
              ],
              [
                -52.872955799502634,
                -18.64573793160416
              ],
              [
                -52.84812340528727,
                -18.661158796109405
              ],
              [
                -52.84601091311567,
                -18.666668267399068
              ],
              [
                -52.83892048801898,
                -18.666511751963732
              ],
              [
                -52.83628699429962,
                -18.666453236490266
              ],
              [
                -52.83490302022297,
                -18.667207743622523
              ],
              [
                -52.82715306458729,
                -18.67143621014746
              ],
              [
                -52.8206431067945,
                -18.66968119125437
              ],
              [
                -52.80893020773979,
                -18.666523200267864
              ],
              [
                -52.79757976105347,
                -18.673681127644226
              ],
              [
                -52.78622980937867,
                -18.68083955901035
              ],
              [
                -52.773458943426036,
                -18.68075754573237
              ],
              [
                -52.76540449412163,
                -18.680706039377387
              ],
              [
                -52.75725600784728,
                -18.685510976311313
              ],
              [
                -52.749107068583704,
                -18.690316426237313
              ],
              [
                -52.73246421138804,
                -18.6886299071662
              ],
              [
                -52.730794706010904,
                -18.688460399650562
              ],
              [
                -52.72443773694633,
                -18.689639395192383
              ],
              [
                -52.71808129388285,
                -18.69081887273316
              ],
              [
                -52.69679296708195,
                -18.68361833924639
              ],
              [
                -52.68343959699981,
                -18.679101840173924
              ],
              [
                -52.678426150056424,
                -18.67961881908913
              ],
              [
                -52.67341417610744,
                -18.680135315010567
              ],
              [
                -52.64287137459411,
                -18.687980717288035
              ],
              [
                -52.64251036548081,
                -18.688245678191567
              ],
              [
                -52.63318191621211,
                -18.695101624973482
              ],
              [
                -52.62383049458631,
                -18.68969212411672
              ],
              [
                -52.6221245397953,
                -18.688705668143434
              ],
              [
                -52.616014057810254,
                -18.688898131703297
              ],
              [
                -52.597691219829436,
                -18.689474112417958
              ],
              [
                -52.592367747773395,
                -18.689435555015358
              ],
              [
                -52.58822377441992,
                -18.6835880774323
              ],
              [
                -52.58601480528266,
                -18.680471102654984
              ],
              [
                -52.566348500703725,
                -18.66445264980046
              ],
              [
                -52.560805043597185,
                -18.66287013373306
              ],
              [
                -52.55516807325362,
                -18.66126011255394
              ],
              [
                -52.55506606307947,
                -18.661231116423462
              ],
              [
                -52.5525361017083,
                -18.656531642907492
              ],
              [
                -52.53075775383125,
                -18.657565068912113
              ],
              [
                -52.52204732993126,
                -18.664180519285885
              ],
              [
                -52.52151231853281,
                -18.66750399411893
              ],
              [
                -52.52129380533128,
                -18.668860004522365
              ],
              [
                -52.50028046417502,
                -18.674820417502595
              ],
              [
                -52.475015130086035,
                -18.68509733340847
              ],
              [
                -52.461817211031864,
                -18.687801791767004
              ],
              [
                -52.44861977399986,
                -18.690506750122967
              ],
              [
                -52.42758938123382,
                -18.72747950167739
              ],
              [
                -52.42482137909941,
                -18.731183998106097
              ],
              [
                -52.41209842510616,
                -18.739787909312152
              ],
              [
                -52.410958917322944,
                -18.745885398219755
              ],
              [
                -52.41045193740865,
                -18.748599890825034
              ],
              [
                -52.40934490886129,
                -18.754525844584954
              ],
              [
                -52.402902447864705,
                -18.75637231891857
              ],
              [
                -52.39726449462933,
                -18.757987812333432
              ],
              [
                -52.3980959931673,
                -18.76132031659782
              ],
              [
                -52.39892746770203,
                -18.76465327486245
              ],
              [
                -52.3845320369876,
                -18.77752319275058
              ],
              [
                -52.37787307304543,
                -18.783475626214784
              ],
              [
                -52.37829957871856,
                -18.784967628653533
              ],
              [
                -52.37892907389569,
                -18.787172617433587
              ],
              [
                -52.376701058061215,
                -18.78687714305988
              ],
              [
                -52.37576509427217,
                -18.78675311322228
              ],
              [
                -52.37536608035736,
                -18.788472129541113
              ],
              [
                -52.37473306932051,
                -18.791198591772332
              ],
              [
                -52.37165910227169,
                -18.79211708782609
              ],
              [
                -52.367230652017234,
                -18.79344005861652
              ],
              [
                -52.36794614054611,
                -18.795295068175946
              ],
              [
                -52.369293094820904,
                -18.798791069036458
              ],
              [
                -52.36344663569398,
                -18.79914602451546
              ],
              [
                -52.353535215691856,
                -18.799748002508476
              ],
              [
                -52.35048023101115,
                -18.809717468258548
              ],
              [
                -52.35081320288919,
                -18.809958478035327
              ],
              [
                -52.353955668427005,
                -18.8122324359333
              ],
              [
                -52.34629271816871,
                -18.814883925525404
              ],
              [
                -52.34418771314514,
                -18.821078890532384
              ],
              [
                -52.33746079035431,
                -18.821487385510007
              ],
              [
                -52.3336252737389,
                -18.82778532262927
              ],
              [
                -52.32084389473258,
                -18.830558781575725
              ],
              [
                -52.30853545956341,
                -18.839256753311187
              ],
              [
                -52.29823354094751,
                -18.836163706754867
              ],
              [
                -52.29741006051031,
                -18.83456322478838
              ],
              [
                -52.29445659339697,
                -18.828820741857303
              ],
              [
                -52.26428631409734,
                -18.830227178469904
              ],
              [
                -52.26326382271123,
                -18.827353185692928
              ],
              [
                -52.25760485902823,
                -18.832773619023918
              ],
              [
                -52.25098037583883,
                -18.83911858075523
              ],
              [
                -52.227679062098005,
                -18.841277019562906
              ],
              [
                -52.218819111958794,
                -18.842097003259216
              ],
              [
                -52.2093686982575,
                -18.84572645288704
              ],
              [
                -52.20894465791529,
                -18.846599986898113
              ],
              [
                -52.207454662224386,
                -18.84966995536575
              ],
              [
                -52.20374821031687,
                -18.847960953535065
              ],
              [
                -52.20251071003039,
                -18.847390933921833
              ],
              [
                -52.20087824739499,
                -18.84868545396216
              ],
              [
                -52.198817243953954,
                -18.850320445384817
              ],
              [
                -52.19710125350458,
                -18.849638927047575
              ],
              [
                -52.18648285654458,
                -18.845422904778673
              ],
              [
                -52.18097738634871,
                -18.84745491162506
              ],
              [
                -52.179321881730345,
                -18.85412785654137
              ],
              [
                -52.179088878797465,
                -18.855067331743243
              ],
              [
                -52.17732939298284,
                -18.85463085420019
              ],
              [
                -52.17567689630619,
                -18.85422083981249
              ],
              [
                -52.17387540145087,
                -18.85781084714797
              ],
              [
                -52.17195143581107,
                -18.861643809083844
              ],
              [
                -52.1688959377459,
                -18.863263299029683
              ],
              [
                -52.165508452630704,
                -18.865058796339024
              ],
              [
                -52.16536995582383,
                -18.867124767742375
              ],
              [
                -52.16476040369913,
                -18.876203234575733
              ],
              [
                -52.158982956724046,
                -18.876037723541756
              ],
              [
                -52.16117694648167,
                -18.87819220551561
              ],
              [
                -52.15462799668684,
                -18.882103658270967
              ],
              [
                -52.15667945899937,
                -18.88797363326612
              ],
              [
                -52.14756398966779,
                -18.893460610403928
              ],
              [
                -52.14104605365045,
                -18.891000082732475
              ],
              [
                -52.13695707914488,
                -18.88945659917755
              ],
              [
                -52.131015630876,
                -18.897269548021555
              ],
              [
                -52.123928186317315,
                -18.896847521776003
              ],
              [
                -52.11295724146587,
                -18.901714465925767
              ],
              [
                -52.111213260446334,
                -18.898839491332136
              ],
              [
                -52.10573280622007,
                -18.905440947761342
              ],
              [
                -52.10725275383676,
                -18.91258041869897
              ],
              [
                -52.10850874688526,
                -18.91848389050037
              ],
              [
                -52.10445527524917,
                -18.91761188702301
              ],
              [
                -52.10239874929265,
                -18.92154738214823
              ],
              [
                -52.106538750503574,
                -18.92305037602645
              ],
              [
                -52.09548178268554,
                -18.93556028274033
              ],
              [
                -52.09013584697818,
                -18.935261294380467
              ],
              [
                -52.08845784862669,
                -18.935167277501964
              ],
              [
                -52.08897983617382,
                -18.936793293564534
              ],
              [
                -52.090298299812844,
                -18.94090323755736
              ],
              [
                -52.080868856383425,
                -18.950447706694273
              ],
              [
                -52.066398962318026,
                -18.952879636719913
              ],
              [
                -52.06126252011038,
                -18.949394665150404
              ],
              [
                -52.05614957068267,
                -18.94592617457468
              ],
              [
                -52.04942659125872,
                -18.95089913908467
              ],
              [
                -52.04473662632343,
                -18.950042599902417
              ],
              [
                -52.043085646519685,
                -18.95463509010873
              ],
              [
                -52.0411726355904,
                -18.959954557389818
              ],
              [
                -52.036767148373066,
                -18.960930555727536
              ],
              [
                -52.03535617895452,
                -18.9587560388475
              ],
              [
                -52.03467468896501,
                -18.95770503480721
              ],
              [
                -52.01815030697807,
                -18.963036496580298
              ],
              [
                -52.0193692680917,
                -18.967230961917476
              ],
              [
                -52.01927278751729,
                -18.967332469215542
              ],
              [
                -52.013958295883334,
                -18.972951411425736
              ],
              [
                -52.020182250213466,
                -18.978832920050802
              ],
              [
                -52.01510074389102,
                -18.982319896405006
              ],
              [
                -52.006995871622365,
                -18.972149903165818
              ],
              [
                -52.00409488638042,
                -18.96850894204144
              ],
              [
                -51.99840094194836,
                -18.96728344094048
              ],
              [
                -51.995684443179194,
                -18.972529875468105
              ],
              [
                -51.995458431614225,
                -18.972966403932702
              ],
              [
                -51.993132964802946,
                -18.97261590154286
              ],
              [
                -51.980899068508215,
                -18.970769858465363
              ],
              [
                -51.980138070676354,
                -18.973051857285018
              ],
              [
                -51.97770356001159,
                -18.98035180932248
              ],
              [
                -51.97250759184447,
                -18.974209337542238
              ],
              [
                -51.966535662154214,
                -18.974870825273722
              ],
              [
                -51.96667616185229,
                -18.97390381697026
              ],
              [
                -51.96778868131086,
                -18.96624035919116
              ],
              [
                -51.957561243822056,
                -18.9747782726953
              ],
              [
                -51.95100926582718,
                -18.980247755373707
              ],
              [
                -51.95169028467675,
                -18.975341267129167
              ],
              [
                -51.95181275026678,
                -18.97445928539983
              ],
              [
                -51.94556182085023,
                -18.974700277306436
              ],
              [
                -51.939822875737086,
                -18.96748779598004
              ],
              [
                -51.93321690233801,
                -18.975017745263617
              ],
              [
                -51.92633744067438,
                -18.97593670052062
              ],
              [
                -51.92539543695371,
                -18.982189678037333
              ],
              [
                -51.92514595612203,
                -18.983842662978507
              ],
              [
                -51.92246595043694,
                -18.983677180855818
              ],
              [
                -51.91425902665378,
                -18.98317015211022
              ],
              [
                -51.90934655674518,
                -18.990340120333677
              ],
              [
                -51.90701503438283,
                -18.993742601083913
              ],
              [
                -51.902664071642704,
                -18.993733585975995
              ],
              [
                -51.89831412989779,
                -18.993724573871482
              ],
              [
                -51.88889566155905,
                -19.0078939771093
              ],
              [
                -51.88949265020415,
                -19.01069248307538
              ],
              [
                -51.89073412650426,
                -19.016512941130966
              ],
              [
                -51.88287816608944,
                -19.019209428878835
              ],
              [
                -51.88226217605609,
                -19.01942092634959
              ],
              [
                -51.87971819520264,
                -19.02319887786499
              ],
              [
                -51.875037702195606,
                -19.030147332667166
              ],
              [
                -51.87156973863845,
                -19.029537854505293
              ],
              [
                -51.87065321573441,
                -19.035071817312545
              ],
              [
                -51.85436629651695,
                -19.05093018532506
              ],
              [
                -51.848286830432954,
                -19.046416723978943
              ],
              [
                -51.84959986609412,
                -19.04486570931297
              ],
              [
                -51.85039836085098,
                -19.043921228603057
              ],
              [
                -51.842268427074146,
                -19.041840198084167
              ],
              [
                -51.8351229546733,
                -19.045513176251184
              ],
              [
                -51.839794398340516,
                -19.049271654360975
              ],
              [
                -51.84339337141211,
                -19.052168166594452
              ],
              [
                -51.84082089269082,
                -19.056679144737235
              ],
              [
                -51.81943407055089,
                -19.053465111266444
              ],
              [
                -51.81843905436191,
                -19.053315589384376
              ],
              [
                -51.816841582282564,
                -19.053998595215823
              ],
              [
                -51.81524458319861,
                -19.054682107046077
              ],
              [
                -51.79592368608104,
                -19.066161495869242
              ],
              [
                -51.796518696801705,
                -19.068576968542676
              ],
              [
                -51.797432672464794,
                -19.072290450204598
              ],
              [
                -51.789743209326176,
                -19.073436955306118
              ],
              [
                -51.789070722036435,
                -19.073537442127602
              ],
              [
                -51.789195719277,
                -19.073829931134252
              ],
              [
                -51.79083869817287,
                -19.077665938117715
              ],
              [
                -51.78070878767294,
                -19.08074889224563
              ],
              [
                -51.778568778514035,
                -19.079010406562013
              ],
              [
                -51.77559634046234,
                -19.076595414783238
              ],
              [
                -51.762210393459966,
                -19.086621839334953
              ],
              [
                -51.76014989139016,
                -19.088165299103842
              ],
              [
                -51.759005419045636,
                -19.088113304397805
              ],
              [
                -51.742703058360185,
                -19.087371770863456
              ],
              [
                -51.74608002093852,
                -19.09346324814264
              ],
              [
                -51.74506200027388,
                -19.09460124441052
              ],
              [
                -51.739875008358574,
                -19.100401229005286
              ],
              [
                -51.73355458009439,
                -19.099090175662116
              ],
              [
                -51.7311460854303,
                -19.098590211723383
              ],
              [
                -51.73068961277208,
                -19.10001617813732
              ],
              [
                -51.7293871109062,
                -19.10408017051367
              ],
              [
                -51.72820659020161,
                -19.10776762589772
              ],
              [
                -51.71617667783528,
                -19.10701810735618
              ],
              [
                -51.714777692526226,
                -19.105160636118022
              ],
              [
                -51.712677229085706,
                -19.10237113327282
              ],
              [
                -51.70537078257111,
                -19.106201090270847
              ],
              [
                -51.70841325503876,
                -19.107217582512877
              ],
              [
                -51.71080920393116,
                -19.108018585064563
              ],
              [
                -51.7092822100551,
                -19.1119710693595
              ],
              [
                -51.69947726441749,
                -19.119218512556404
              ],
              [
                -51.69273083981099,
                -19.1177520058164
              ],
              [
                -51.65745604145761,
                -19.13745782545458
              ],
              [
                -51.648302156591186,
                -19.132212336544544
              ],
              [
                -51.63914869272308,
                -19.126967358652916
              ],
              [
                -51.625301792425994,
                -19.136289290075332
              ],
              [
                -51.62339778797573,
                -19.137570756408532
              ],
              [
                -51.620250331543296,
                -19.13718377106521
              ],
              [
                -51.61314690698865,
                -19.136312248743405
              ],
              [
                -51.612016900447514,
                -19.137355715136817
              ],
              [
                -51.60870591743397,
                -19.140416205834743
              ],
              [
                -51.5962904970263,
                -19.13584820053843
              ],
              [
                -51.595033014837746,
                -19.133234215098618
              ],
              [
                -51.59200959224612,
                -19.126951734676904
              ],
              [
                -51.57917567169768,
                -19.130223696970155
              ],
              [
                -51.57742265362958,
                -19.127812215354197
              ],
              [
                -51.575735696025745,
                -19.132103188658157
              ],
              [
                -51.55409335080553,
                -19.132016122507007
              ],
              [
                -51.55163033981791,
                -19.137076081337206
              ],
              [
                -51.55032135294969,
                -19.139765092835546
              ],
              [
                -51.544831890577925,
                -19.141299079818243
              ],
              [
                -51.53928490059363,
                -19.142849549578184
              ],
              [
                -51.53760344858726,
                -19.14234402554545
              ],
              [
                -51.533784986362726,
                -19.14119752692388
              ],
              [
                -51.52695304821907,
                -19.139146031660587
              ],
              [
                -51.51205512815554,
                -19.14983243614215
              ],
              [
                -51.510980602994984,
                -19.15060291790261
              ],
              [
                -51.5105081059689,
                -19.15044695239463
              ],
              [
                -51.500983195046345,
                -19.147315431105184
              ],
              [
                -51.500297193591024,
                -19.154206898194538
              ],
              [
                -51.50013467216151,
                -19.155840886392475
              ],
              [
                -51.49343220223644,
                -19.162532826942275
              ],
              [
                -51.48092330619027,
                -19.159213824835142
              ],
              [
                -51.47836337188037,
                -19.156909336685878
              ],
              [
                -51.47483089633091,
                -19.153728848005997
              ],
              [
                -51.46936389602827,
                -19.15801779557977
              ],
              [
                -51.45775548396929,
                -19.167126256589892
              ],
              [
                -51.45656148795626,
                -19.162532761305503
              ],
              [
                -51.45274652177312,
                -19.16173126614317
              ],
              [
                -51.44674805523095,
                -19.16047123545591
              ],
              [
                -51.44314308616253,
                -19.164709699025664
              ],
              [
                -51.437594106150804,
                -19.171232161269813
              ],
              [
                -51.43006518933836,
                -19.170585148877947
              ],
              [
                -51.42865917479365,
                -19.169201678532755
              ],
              [
                -51.42477272875028,
                -19.16537715987736
              ],
              [
                -51.41998626159762,
                -19.166075149439546
              ],
              [
                -51.416179272058066,
                -19.17383262154706
              ],
              [
                -51.41097532792026,
                -19.17246061240858
              ],
              [
                -51.40635435476601,
                -19.175894090004306
              ],
              [
                -51.41014980923698,
                -19.180857559268414
              ],
              [
                -51.39974134923252,
                -19.191040482448106
              ],
              [
                -51.39739937626132,
                -19.193330462067543
              ],
              [
                -51.39505836728039,
                -19.195621452684463
              ],
              [
                -51.39352391008378,
                -19.195512949235706
              ],
              [
                -51.374622536503935,
                -19.194176423765633
              ],
              [
                -51.36951106166791,
                -19.200204357337228
              ],
              [
                -51.370011530274766,
                -19.207459848758532
              ],
              [
                -51.36712404000795,
                -19.20988782934631
              ],
              [
                -51.364067586605735,
                -19.21245731285858
              ],
              [
                -51.36898250763859,
                -19.220228285297853
              ],
              [
                -51.36595204159352,
                -19.221900258940305
              ],
              [
                -51.34810219453178,
                -19.215188262481227
              ],
              [
                -51.34350672491177,
                -19.213459734511645
              ],
              [
                -51.339340222291746,
                -19.217629707915517
              ],
              [
                -51.33517324967472,
                -19.22180020231451
              ],
              [
                -51.32795281658953,
                -19.2208331761519
              ],
              [
                -51.31185492550475,
                -19.218677169591217
              ],
              [
                -51.307322506251204,
                -19.21806766372003
              ],
              [
                -51.30331400964304,
                -19.22323159800863
              ],
              [
                -51.304678480939536,
                -19.227962092060118
              ],
              [
                -51.307422926243994,
                -19.237479059963793
              ],
              [
                -51.297477493492444,
                -19.238323047584338
              ],
              [
                -51.28400609478771,
                -19.250556951498854
              ],
              [
                -51.282518074634424,
                -19.250320941744445
              ],
              [
                -51.27845661297182,
                -19.249676430229005
              ],
              [
                -51.27655260677353,
                -19.255115427672102
              ],
              [
                -51.274263625325894,
                -19.261654857137973
              ],
              [
                -51.256452741980546,
                -19.266969804242112
              ],
              [
                -51.25172477456496,
                -19.273613277955807
              ],
              [
                -51.24827778256919,
                -19.268668769517372
              ],
              [
                -51.24625531503876,
                -19.265767779477738
              ],
              [
                -51.24223085269326,
                -19.26772978523104
              ],
              [
                -51.240450845598005,
                -19.268597780717066
              ],
              [
                -51.236504893266726,
                -19.266840755546035
              ],
              [
                -51.229900950291956,
                -19.26389974992106
              ],
              [
                -51.22378552286965,
                -19.257720787260862
              ],
              [
                -51.215895592939475,
                -19.25851225440097
              ],
              [
                -51.210568613749174,
                -19.262813221184786
              ],
              [
                -51.20730809777236,
                -19.26544569285316
              ],
              [
                -51.20652812200998,
                -19.264613192630467
              ],
              [
                -51.20273666005377,
                -19.260565704277507
              ],
              [
                -51.20037265676587,
                -19.264452697547938
              ],
              [
                -51.187457757573526,
                -19.268087125863335
              ],
              [
                -51.181359273512065,
                -19.28035456745973
              ],
              [
                -51.180008798931176,
                -19.28307153964636
              ],
              [
                -51.17392432321447,
                -19.285004537227078
              ],
              [
                -51.16784035350121,
                -19.286936987810602
              ],
              [
                -51.16578786399759,
                -19.286920993608234
              ],
              [
                -51.143342549500154,
                -19.286745942729723
              ],
              [
                -51.13530407308018,
                -19.29410241462689
              ],
              [
                -51.12558963791718,
                -19.302993314234644
              ],
              [
                -51.11792371102712,
                -19.302520831680006
              ],
              [
                -51.10554126811726,
                -19.30821327496438
              ],
              [
                -51.098071830925896,
                -19.307335242761972
              ],
              [
                -51.09301088977631,
                -19.306740228464317
              ],
              [
                -51.09210288992696,
                -19.306633250618024
              ],
              [
                -51.08747540229962,
                -19.30786721511218
              ],
              [
                -51.08743689674397,
                -19.312723706912745
              ],
              [
                -51.08742841664987,
                -19.313831188851587
              ],
              [
                -51.07496297142532,
                -19.32057463412415
              ],
              [
                -51.06730050840094,
                -19.324719606562436
              ],
              [
                -51.06718803011563,
                -19.325467593869057
              ],
              [
                -51.06656049996785,
                -19.329632083303384
              ],
              [
                -51.06117608267768,
                -19.32925107919267
              ],
              [
                -51.05734108438645,
                -19.32898006156647
              ],
              [
                -51.053124089908394,
                -19.335392998533493
              ],
              [
                -51.05031164337547,
                -19.334607025879027
              ],
              [
                -51.0421451458764,
                -19.34621543503705
              ],
              [
                -51.042066154274494,
                -19.351887426020348
              ],
              [
                -51.04195113054958,
                -19.360145363150007
              ],
              [
                -51.03590919665097,
                -19.361374352005136
              ],
              [
                -51.03185219892191,
                -19.362200347803395
              ],
              [
                -51.023313225689314,
                -19.380987728336965
              ],
              [
                -51.01750974859087,
                -19.383411219361374
              ],
              [
                -51.02195721749672,
                -19.38778019694716
              ],
              [
                -51.02570566564348,
                -19.391463193858844
              ],
              [
                -51.03145412833374,
                -19.397363681852834
              ],
              [
                -51.030235616198304,
                -19.40097314230396
              ],
              [
                -51.01500970420006,
                -19.40202410826268
              ],
              [
                -51.011675758851766,
                -19.4008146041667
              ],
              [
                -51.00564128671639,
                -19.398624602570834
              ],
              [
                -51.001485815416885,
                -19.40375857750593
              ],
              [
                -51.00264729627363,
                -19.40737806503851
              ],
              [
                -51.00433779739845,
                -19.412649030429137
              ],
              [
                -50.99681183522135,
                -19.41162052769555
              ],
              [
                -50.984569943547484,
                -19.40994702774144
              ],
              [
                -50.98469191258746,
                -19.420431944359326
              ],
              [
                -50.984813871606335,
                -19.430917423990422
              ],
              [
                -50.96328653411477,
                -19.438339301677527
              ],
              [
                -50.95152508145924,
                -19.442393796877795
              ],
              [
                -50.9463661094015,
                -19.45218573901535
              ],
              [
                -50.94623411468146,
                -19.452436230535127
              ],
              [
                -50.93920764149171,
                -19.45604716796609
              ],
              [
                -50.935323155835626,
                -19.467213118144013
              ],
              [
                -50.948952558992566,
                -19.47058713202048
              ],
              [
                -50.95741544914641,
                -19.479566634090144
              ],
              [
                -50.96197991368495,
                -19.48440911589686
              ],
              [
                -50.962219414285684,
                -19.4922605768579
              ],
              [
                -50.96245886887277,
                -19.500113049823483
              ],
              [
                -50.93825996044793,
                -19.53155682440991
              ],
              [
                -50.93039351253603,
                -19.541778783627
              ],
              [
                -50.92703601934476,
                -19.549964718774326
              ],
              [
                -50.923679498138284,
                -19.558150695926866
              ],
              [
                -50.922641994600625,
                -19.57559560611193
              ],
              [
                -50.927510387777545,
                -19.58733303654938
              ],
              [
                -50.93709032927267,
                -19.59180307519219
              ],
              [
                -50.946054744054,
                -19.591223072122727
              ],
              [
                -50.951915208045655,
                -19.587639098589566
              ],
              [
                -50.962381642322114,
                -19.58123767978526
              ],
              [
                -50.97052858138471,
                -19.58167467598291
              ],
              [
                -50.98706743788859,
                -19.589040190653957
              ],
              [
                -50.98997737580666,
                -19.60493762132255
              ],
              [
                -51.004848700734165,
                -19.636823526995876
              ],
              [
                -51.0111026064367,
                -19.650232453784913
              ],
              [
                -51.01239558759573,
                -19.6530029546584
              ],
              [
                -51.01574204271429,
                -19.655601941668888
              ],
              [
                -51.02135199658965,
                -19.65995944866276
              ],
              [
                -51.03608579967799,
                -19.686546345341114
              ],
              [
                -51.03800671723372,
                -19.719066715966743
              ],
              [
                -51.04218217905114,
                -19.724402195904165
              ],
              [
                -51.045454639069405,
                -19.728583704674264
              ],
              [
                -51.04494161307425,
                -19.73709067307667
              ],
              [
                -51.027412209079095,
                -19.756109527587466
              ],
              [
                -51.03945103677566,
                -19.776650466899206
              ],
              [
                -51.03359557459165,
                -19.790802381961505
              ],
              [
                -51.031784068735185,
                -19.79338236861557
              ],
              [
                -51.02220460621063,
                -19.807028284162417
              ],
              [
                -51.01771004671077,
                -19.836813612678032
              ],
              [
                -51.01871896200595,
                -19.86706200072709
              ],
              [
                -50.99898302096884,
                -19.905909261608798
              ],
              [
                -51.00011750305526,
                -19.91346325678401
              ],
              [
                -51.00125143913646,
                -19.921016721970485
              ],
              [
                -51.011909787217164,
                -19.944381133652904
              ],
              [
                -51.019910185614634,
                -19.9619190584624
              ],
              [
                -51.0195311922441,
                -19.96436254654215
              ],
              [
                -51.01915270186571,
                -19.966807042619386
              ],
              [
                -51.00431722256845,
                -19.998748355636316
              ],
              [
                -51.00554614472221,
                -20.024829231518652
              ],
              [
                -51.01086208559356,
                -20.032829244972014
              ],
              [
                -51.01637400308546,
                -20.041125215648776
              ],
              [
                -51.017399981052804,
                -20.04792316708534
              ],
              [
                -51.00926746828095,
                -20.071557045159462
              ],
              [
                -50.99998499885325,
                -20.084914443950506
              ],
              [
                -51.00136297886235,
                -20.09580790912849
              ],
              [
                -51.02874959835925,
                -20.1504907541758
              ],
              [
                -51.03953497332478,
                -20.17654412843651
              ],
              [
                -51.0407239446718,
                -20.179417147880937
              ],
              [
                -51.04452241076833,
                -20.188760596636538
              ],
              [
                -51.0445253777247,
                -20.188767621613344
              ],
              [
                -51.04832184783418,
                -20.198106092407667
              ],
              [
                -51.049854309533075,
                -20.20364754396863
              ],
              [
                -51.057881641212425,
                -20.232666456049547
              ],
              [
                -51.06327611977768,
                -20.241122420138307
              ],
              [
                -51.06867102532342,
                -20.249579388246698
              ],
              [
                -51.10052623025113,
                -20.273551360080972
              ],
              [
                -51.127236469684846,
                -20.29206533840889
              ],
              [
                -51.15060575411748,
                -20.301449875110965
              ],
              [
                -51.15874019673943,
                -20.303672350282156
              ],
              [
                -51.166874111372096,
                -20.305894882459903
              ],
              [
                -51.23948454652467,
                -20.31647349217733
              ],
              [
                -51.29519209815524,
                -20.328013107341626
              ],
              [
                -51.30809846160079,
                -20.33360058383421
              ],
              [
                -51.32100437606401,
                -20.339187106359816
              ],
              [
                -51.322473328662475,
                -20.340271093598954
              ],
              [
                -51.34290266238201,
                -20.355342114105618
              ],
              [
                -51.350374052467735,
                -20.36270959719269
              ],
              [
                -51.35938645961775,
                -20.381299031497665
              ],
              [
                -51.36072993200103,
                -20.384070022054857
              ],
              [
                -51.3656818835387,
                -20.388343509869074
              ],
              [
                -51.37461930845628,
                -20.396056978529973
              ],
              [
                -51.390780566129465,
                -20.42749490853715
              ],
              [
                -51.403458393726886,
                -20.452156323786024
              ],
              [
                -51.40878487618214,
                -20.459100310128214
              ],
              [
                -51.4105223549566,
                -20.460112284472054
              ],
              [
                -51.42013826116757,
                -20.465710308399625
              ],
              [
                -51.42098625125,
                -20.46620381657142
              ],
              [
                -51.44873496019828,
                -20.482345280319468
              ],
              [
                -51.45600990161331,
                -20.49166325879648
              ],
              [
                -51.46328583600732,
                -20.50098075331013
              ],
              [
                -51.4944174424849,
                -20.553009616423036
              ],
              [
                -51.49707337264977,
                -20.560377075868946
              ],
              [
                -51.499729352801815,
                -20.567745038325842
              ],
              [
                -51.522989137105725,
                -20.58817150708335
              ],
              [
                -51.56717918779481,
                -20.617882993738675
              ],
              [
                -51.56972769548074,
                -20.619848990302582
              ],
              [
                -51.57621662002642,
                -20.624854475656715
              ],
              [
                -51.586467497317514,
                -20.634329977181842
              ],
              [
                -51.591088953760085,
                -20.64131244991925
              ],
              [
                -51.59571038519678,
                -20.6482939376769
              ],
              [
                -51.62771643469589,
                -20.724598687947225
              ],
              [
                -51.6343988169773,
                -20.75437905604571
              ],
              [
                -51.62326282497303,
                -20.778852424452968
              ],
              [
                -51.62602427661677,
                -20.78284592706045
              ],
              [
                -51.62698378902465,
                -20.784233923796485
              ],
              [
                -51.626390269289985,
                -20.789386909124804
              ],
              [
                -51.626211772748235,
                -20.790940375990303
              ],
              [
                -51.6324786056205,
                -20.82108579470367
              ],
              [
                -51.62987607356388,
                -20.851414158072277
              ],
              [
                -51.62466301488907,
                -20.87292853281469
              ],
              [
                -51.61882303455466,
                -20.881265469980622
              ],
              [
                -51.612983564213174,
                -20.889602429146887
              ],
              [
                -51.61848990755599,
                -20.931949751262717
              ],
              [
                -51.622338338446355,
                -20.938912241425403
              ],
              [
                -51.62618728732007,
                -20.94587573459966
              ],
              [
                -51.66556391761472,
                -20.970060717300733
              ],
              [
                -51.71319756584768,
                -20.970985339108033
              ],
              [
                -51.718944507740574,
                -20.975330849844898
              ],
              [
                -51.72469196562676,
                -20.97967683859176
              ],
              [
                -51.7528331387527,
                -21.013864754158483
              ],
              [
                -51.77163540321124,
                -21.048282177857185
              ],
              [
                -51.775145784820204,
                -21.072622560149178
              ],
              [
                -51.78917111114132,
                -21.101645464575167
              ],
              [
                -51.8075049268004,
                -21.10954998420129
              ],
              [
                -51.811588406940125,
                -21.11674746250458
              ],
              [
                -51.813953366743476,
                -21.120915456685335
              ],
              [
                -51.82309077847036,
                -21.126367459663786
              ],
              [
                -51.84409211466556,
                -21.13098847277958
              ],
              [
                -51.864975965647616,
                -21.130068053849943
              ],
              [
                -51.875361831532736,
                -21.135675564791924
              ],
              [
                -51.87848029044535,
                -21.14766502539111
              ],
              [
                -51.87766779675407,
                -21.15597147328424
              ],
              [
                -51.875259723417805,
                -21.180571350432896
              ],
              [
                -51.87880862631622,
                -21.197405788082357
              ],
              [
                -51.86356817300753,
                -21.22548614427372
              ],
              [
                -51.85378565921344,
                -21.260454447261143
              ],
              [
                -51.85089863966897,
                -21.26330944410201
              ],
              [
                -51.848012663117274,
                -21.266164406945443
              ],
              [
                -51.857158498130765,
                -21.29729830308699
              ],
              [
                -51.86120188302988,
                -21.33619817149204
              ],
              [
                -51.864196805050106,
                -21.343692620851424
              ],
              [
                -51.86719126906518,
                -21.351186119227567
              ],
              [
                -51.905629409163495,
                -21.38475355685853
              ],
              [
                -51.91770819489723,
                -21.411751990496942
              ],
              [
                -51.919201649743,
                -21.421793464150447
              ],
              [
                -51.9206951135684,
                -21.43183489082242
              ],
              [
                -51.92817797608262,
                -21.45534233898787
              ],
              [
                -51.939018906810254,
                -21.463472327568713
              ],
              [
                -51.94986026153019,
                -21.471602329187935
              ],
              [
                -51.9630096197654,
                -21.49555574413551
              ],
              [
                -51.96739856886785,
                -21.501287727138294
              ],
              [
                -51.99766578958545,
                -21.515155277371054
              ],
              [
                -52.05656186691141,
                -21.50313496117195
              ],
              [
                -52.06595082319553,
                -21.504117996278747
              ],
              [
                -52.07728468002393,
                -21.514746986342427
              ],
              [
                -52.09274452548409,
                -21.53758340425781
              ],
              [
                -52.102786845558406,
                -21.55858787214698
              ],
              [
                -52.09948285578163,
                -21.57021781984912
              ],
              [
                -52.0683759953173,
                -21.597625090115926
              ],
              [
                -52.065330473818555,
                -21.608875063129336
              ],
              [
                -52.062284483312325,
                -21.6201225161615
              ],
              [
                -52.05897298151141,
                -21.62573897551073
              ],
              [
                -52.04901001714925,
                -21.642635872282487
              ],
              [
                -52.04916848862141,
                -21.651747844409176
              ],
              [
                -52.04932747807039,
                -21.660860775545665
              ],
              [
                -52.07597809816367,
                -21.71437764337426
              ],
              [
                -52.093305398797675,
                -21.729973613323214
              ],
              [
                -52.09785086483668,
                -21.73738410938163
              ],
              [
                -52.10239630486307,
                -21.74479510045537
              ],
              [
                -52.13954350483608,
                -21.755254618548648
              ],
              [
                -52.153415857709774,
                -21.765979627539732
              ],
              [
                -52.160755785124906,
                -21.770296623492637
              ],
              [
                -52.17500709906252,
                -21.79769607897258
              ],
              [
                -52.183038956806485,
                -21.813137509889312
              ],
              [
                -52.183449420914755,
                -21.823172487395066
              ],
              [
                -52.18362191943786,
                -21.827379970841605
              ],
              [
                -52.190057808460274,
                -21.84566588765644
              ],
              [
                -52.23169140066946,
                -21.88219234648232
              ],
              [
                -52.247899222696574,
                -21.901805822090232
              ],
              [
                -52.25892508690836,
                -21.90626535764477
              ],
              [
                -52.263188543100476,
                -21.911822331823313
              ],
              [
                -52.268133003010234,
                -21.913303330455726
              ],
              [
                -52.27537747334152,
                -21.915474343105313
              ],
              [
                -52.308313084335836,
                -21.951439788729296
              ],
              [
                -52.31344601308159,
                -21.961665263824614
              ],
              [
                -52.31857894380703,
                -21.97189023295158
              ],
              [
                -52.34492397235452,
                -22.061492418016567
              ],
              [
                -52.3767080788678,
                -22.10620133910143
              ],
              [
                -52.39076846186298,
                -22.115133331435658
              ],
              [
                -52.39750789148185,
                -22.121505332158147
              ],
              [
                -52.4076782406828,
                -22.141157791265616
              ],
              [
                -52.45669475730055,
                -22.184490233737115
              ],
              [
                -52.479454488000634,
                -22.200405251863675
              ],
              [
                -52.48258144181526,
                -22.206992705141538
              ],
              [
                -52.48624840664231,
                -22.21471771254701
              ],
              [
                -52.49249935138132,
                -22.219483714444173
              ],
              [
                -52.50292776659336,
                -22.224909190294195
              ],
              [
                -52.5227231139893,
                -22.228545230397405
              ],
              [
                -52.550027823664536,
                -22.242455759545464
              ],
              [
                -52.578674091353975,
                -22.25147479918107
              ],
              [
                -52.60147887652856,
                -22.27147279408776
              ],
              [
                -52.612952767339024,
                -22.275649316243854
              ],
              [
                -52.624427168155094,
                -22.27982631942043
              ],
              [
                -52.65637732986404,
                -22.303322319547302
              ],
              [
                -52.69462004795947,
                -22.314132894745807
              ],
              [
                -52.723755232155774,
                -22.33463289363902
              ],
              [
                -52.734538623460594,
                -22.338735397253163
              ],
              [
                -52.74531954879486,
                -22.34283741888079
              ],
              [
                -52.79781702935674,
                -22.37779592570741
              ],
              [
                -52.83937160594418,
                -22.414835389905818
              ],
              [
                -52.8542464024426,
                -22.438634827774205
              ],
              [
                -52.85578841979378,
                -22.439820322872958
              ],
              [
                -52.85732838616359,
                -22.44100481897017
              ],
              [
                -52.88532264883146,
                -22.452396345166637
              ],
              [
                -52.947675090241106,
                -22.472875463325487
              ],
              [
                -52.99806618902217,
                -22.49270899691678
              ],
              [
                -53.004139072033055,
                -22.503406512283764
              ],
              [
                -53.007272544202,
                -22.50892647661813
              ],
              [
                -53.01314943885502,
                -22.5192789707651
              ],
              [
                -53.055428087170924,
                -22.53537549555591
              ],
              [
                -53.080933328428195,
                -22.557773976919183
              ],
              [
                -53.107750515453226,
                -22.59682239984016
              ],
              [
                -53.10819293805582,
                -22.613084350998136
              ],
              [
                -53.10818994505108,
                -22.613091876960183
              ],
              [
                -53.10466893923559,
                -22.621452309139393
              ],
              [
                -53.127450652469825,
                -22.651613255226163
              ],
              [
                -53.18043457505918,
                -22.71315966572543
              ],
              [
                -53.19093596908498,
                -22.72072617152226
              ],
              [
                -53.20143586112194,
                -22.728291681351894
              ],
              [
                -53.23947158080372,
                -22.733272758461244
              ],
              [
                -53.32633529907543,
                -22.764804899142568
              ],
              [
                -53.35403057389088,
                -22.776892431770555
              ],
              [
                -53.35739852084211,
                -22.779154927734368
              ],
              [
                -53.36076651279096,
                -22.78141793869956
              ],
              [
                -53.37703078840672,
                -22.802759874047204
              ],
              [
                -53.4004985955551,
                -22.807652420863093
              ],
              [
                -53.40503156472555,
                -22.81079292720783
              ],
              [
                -53.40956399890125,
                -22.813932935558846
              ],
              [
                -53.41100201734728,
                -22.81529591692843
              ],
              [
                -53.42912981825201,
                -22.83247690605923
              ],
              [
                -53.43973271676658,
                -22.83696742435944
              ],
              [
                -53.45033611228544,
                -22.841457930681685
              ],
              [
                -53.48424028366962,
                -22.863376959728136
              ],
              [
                -53.53420987453212,
                -22.882090550435098
              ],
              [
                -53.54025681128524,
                -22.885855551359434
              ],
              [
                -53.546304231035045,
                -22.8896205312949
              ],
              [
                -53.55892109220721,
                -22.905484013792595
              ],
              [
                -53.55959058160985,
                -22.91058598189656
              ],
              [
                -53.560259066019675,
                -22.915686460007517
              ],
              [
                -53.57024742639743,
                -22.92801346553111
              ],
              [
                -53.57951633503784,
                -22.931885961276627
              ],
              [
                -53.58878525968406,
                -22.935758501037462
              ],
              [
                -53.6069001051165,
                -22.95085596598093
              ],
              [
                -53.611506519349064,
                -22.96226693714893
              ],
              [
                -53.6161114265726,
                -22.973675409354477
              ],
              [
                -53.62574883640662,
                -22.98708739104381
              ],
              [
                -53.63360321544578,
                -22.99801789335309
              ],
              [
                -53.633102236189586,
                -22.999901850798775
              ],
              [
                -53.63215819905965,
                -23.003449853687744
              ],
              [
                -53.63005771742536,
                -23.011340810856442
              ],
              [
                -53.636063276731065,
                -23.124553888933036
              ],
              [
                -53.65980600586785,
                -23.153981345037916
              ],
              [
                -53.65956797760581,
                -23.159634818502393
              ],
              [
                -53.659330976333344,
                -23.16528732797789
              ],
              [
                -53.68490664670838,
                -23.205187257935727
              ],
              [
                -53.70741774196752,
                -23.26388609391678
              ],
              [
                -53.711024721244904,
                -23.273292076078008
              ],
              [
                -53.71463213750076,
                -23.282697557264502
              ],
              [
                -53.73009843531702,
                -23.317225465822087
              ],
              [
                -53.761375055336686,
                -23.359277909063962
              ],
              [
                -53.76874644085652,
                -23.369185384735598
              ],
              [
                -53.770580953087475,
                -23.3716508596737
              ],
              [
                -53.775728392502046,
                -23.372118385504432
              ],
              [
                -53.81549307023475,
                -23.385165975946997
              ],
              [
                -53.8760679737049,
                -23.419308024418125
              ],
              [
                -53.93152595302562,
                -23.4454705669555
              ],
              [
                -53.96056024511818,
                -23.4436811511279
              ],
              [
                -53.96408071450798,
                -23.44469068686629
              ],
              [
                -53.96524071575225,
                -23.44502317641701
              ],
              [
                -53.97686660085475,
                -23.453395688883855
              ],
              [
                -53.982333047555855,
                -23.459526652530098
              ],
              [
                -54.00381219104168,
                -23.510602561896132
              ],
              [
                -54.00681955664677,
                -23.544268953188016
              ],
              [
                -54.02348880265425,
                -23.585597815716678
              ],
              [
                -54.02880958787941,
                -23.628095211566286
              ],
              [
                -54.0307255421695,
                -23.64338615238019
              ],
              [
                -54.0317035080303,
                -23.651192595795127
              ],
              [
                -54.04407734414397,
                -23.668176073766077
              ],
              [
                -54.050362235402474,
                -23.68681702507821
              ],
              [
                -54.052899103751415,
                -23.724463402849437
              ],
              [
                -54.07517929084712,
                -23.764003845813836
              ],
              [
                -54.08310707702515,
                -23.80500721399023
              ],
              [
                -54.08553099296858,
                -23.82467964656634
              ],
              [
                -54.084100501310964,
                -23.827549151752592
              ],
              [
                -54.08256848761998,
                -23.830618607900803
              ],
              [
                -54.084045471413816,
                -23.834581595785657
              ],
              [
                -54.08757489278235,
                -23.844050072024903
              ],
              [
                -54.08381386607591,
                -23.864441478859582
              ],
              [
                -54.083765346398806,
                -23.86470698876546
              ],
              [
                -54.07937429699055,
                -23.888498912606554
              ],
              [
                -54.09932344852034,
                -23.94598624949477
              ],
              [
                -54.104591359973504,
                -23.953389254935104
              ],
              [
                -54.10985932641459,
                -23.960792223394904
              ],
              [
                -54.12917610530254,
                -23.981628231307027
              ],
              [
                -54.16789575565766,
                -23.99872825467643
              ],
              [
                -54.24115154056001,
                -24.0508857839979
              ],
              [
                -54.24632597783314,
                -24.05390282370715
              ],
              [
                -54.250257927001336,
                -24.05493782117491
              ],
              [
                -54.27439124752738,
                -24.06128987433037
              ],
              [
                -54.287103586201894,
                -24.06950989510664
              ],
              [
                -54.29208910020665,
                -24.060524916813122
              ],
              [
                -54.29357958117754,
                -24.05903843591885
              ],
              [
                -54.295924105265875,
                -24.056700473813837
              ],
              [
                -54.296673093741,
                -24.055952941893842
              ],
              [
                -54.30102555729043,
                -24.054596990060514
              ],
              [
                -54.30404107504438,
                -24.051100990196854
              ],
              [
                -54.30927152194781,
                -24.04574605113496
              ],
              [
                -54.313124062359854,
                -24.033423603638724
              ],
              [
                -54.316370053257565,
                -24.029716632270404
              ],
              [
                -54.32099154100534,
                -24.024005169418157
              ],
              [
                -54.320799538400344,
                -24.022228650938796
              ],
              [
                -54.32024757449839,
                -24.01712965178446
              ],
              [
                -54.31916258666921,
                -24.008169689257272
              ],
              [
                -54.322388075031384,
                -24.007176694407438
              ],
              [
                -54.33740447350871,
                -24.003347275530277
              ],
              [
                -54.34081696874036,
                -24.003822775209393
              ],
              [
                -54.34767989196094,
                -24.004766309722328
              ],
              [
                -54.351086389266655,
                -24.005235307407062
              ],
              [
                -54.35528585619156,
                -24.0056022980792
              ],
              [
                -54.359210836134615,
                -24.002661824298936
              ],
              [
                -54.36983625613062,
                -23.993643389818438
              ],
              [
                -54.37904572466195,
                -23.9901679594293
              ],
              [
                -54.38131068990233,
                -23.987434465415895
              ],
              [
                -54.38469020733525,
                -23.983348972784572
              ],
              [
                -54.387506198915275,
                -23.97950750743712
              ],
              [
                -54.38848467903052,
                -23.97817302830704
              ],
              [
                -54.392444186213815,
                -23.9728210329865
              ],
              [
                -54.3989176442193,
                -23.964888593945346
              ],
              [
                -54.40669111712516,
                -23.962208614016568
              ],
              [
                -54.41052759038114,
                -23.96088563632595
              ],
              [
                -54.41391255679779,
                -23.95959567502283
              ],
              [
                -54.41531905631636,
                -23.95583767970478
              ],
              [
                -54.42549109256165,
                -23.92747879069715
              ],
              [
                -54.42672760528502,
                -23.923724344809994
              ],
              [
                -54.42769410514124,
                -23.919416358948542
              ],
              [
                -54.42772712332436,
                -23.919308355432452
              ],
              [
                -54.427785598651504,
                -23.9191163462919
              ],
              [
                -54.429369602567036,
                -23.913900882624528
              ],
              [
                -54.43253712107313,
                -23.910441890111073
              ],
              [
                -54.436737102341006,
                -23.906147418913413
              ],
              [
                -54.44927449502972,
                -23.90081498392076
              ],
              [
                -54.45839344212673,
                -23.89917751379437
              ],
              [
                -54.46792790570843,
                -23.897414564479313
              ],
              [
                -54.46900640965112,
                -23.89721457074415
              ],
              [
                -54.48294131020682,
                -23.89444609848711
              ],
              [
                -54.48616179343502,
                -23.891221612322262
              ],
              [
                -54.48769628167316,
                -23.88959063205356
              ],
              [
                -54.49140877715294,
                -23.885644674015982
              ],
              [
                -54.50018923552802,
                -23.875898234849615
              ],
              [
                -54.502184184825836,
                -23.8805492113449
              ],
              [
                -54.50374668447728,
                -23.88386820502565
              ],
              [
                -54.50432766742548,
                -23.885101696678845
              ],
              [
                -54.51043362160057,
                -23.883180731551725
              ],
              [
                -54.51748306300814,
                -23.884813739248607
              ],
              [
                -54.522054073597076,
                -23.879253771630538
              ],
              [
                -54.52413656705526,
                -23.87680827299108
              ],
              [
                -54.52615905867478,
                -23.87443279791029
              ],
              [
                -54.53221502295291,
                -23.874371310178425
              ],
              [
                -54.53231901020192,
                -23.87437032152624
              ],
              [
                -54.5368294637614,
                -23.874549323845237
              ],
              [
                -54.545357411098024,
                -23.8704058764232
              ],
              [
                -54.546929907679136,
                -23.869641890273847
              ],
              [
                -54.54748891714891,
                -23.86976289270666
              ],
              [
                -54.55774133008406,
                -23.871978410004466
              ],
              [
                -54.5696387060446,
                -23.874463455833098
              ],
              [
                -54.57045271699813,
                -23.870160952397903
              ],
              [
                -54.56837726524826,
                -23.861684978820982
              ],
              [
                -54.567672803623736,
                -23.85880750343718
              ],
              [
                -54.566073838888315,
                -23.855060509100547
              ],
              [
                -54.56973179014291,
                -23.85281652297219
              ],
              [
                -54.58158824094723,
                -23.845208570533238
              ],
              [
                -54.58343574920811,
                -23.841463117600863
              ],
              [
                -54.58578023427933,
                -23.8391455913753
              ],
              [
                -54.58874422790256,
                -23.839118105280633
              ],
              [
                -54.59162018223306,
                -23.841118110885123
              ],
              [
                -54.595065151507846,
                -23.84310061743639
              ],
              [
                -54.596561629676955,
                -23.845396622455567
              ],
              [
                -54.59793509747622,
                -23.84750362872219
              ],
              [
                -54.6008480903892,
                -23.851584602269455
              ],
              [
                -54.60966101260128,
                -23.851055123288848
              ],
              [
                -54.61509800540598,
                -23.837976718467697
              ],
              [
                -54.61691801935231,
                -23.83359872461835
              ],
              [
                -54.62499748219172,
                -23.833507248682743
              ],
              [
                -54.6321294083755,
                -23.828438798799986
              ],
              [
                -54.63733289614768,
                -23.829602298009373
              ],
              [
                -54.63801740859312,
                -23.829755305747447
              ],
              [
                -54.64702281645014,
                -23.832297821790714
              ],
              [
                -54.6502663242086,
                -23.826104356907834
              ],
              [
                -54.65403629308554,
                -23.818905404239576
              ],
              [
                -54.66342526034493,
                -23.81732044079197
              ],
              [
                -54.666804699646086,
                -23.81661694640765
              ],
              [
                -54.668818734910936,
                -23.81435794987079
              ],
              [
                -54.670806206883356,
                -23.811711456582906
              ],
              [
                -54.67632415469791,
                -23.817916442442314
              ],
              [
                -54.67840713446577,
                -23.820258962260223
              ],
              [
                -54.68028310124871,
                -23.822724457970697
              ],
              [
                -54.68782250690928,
                -23.82752996837855
              ],
              [
                -54.68716153757924,
                -23.830878439648416
              ],
              [
                -54.69078746318571,
                -23.833475464714756
              ],
              [
                -54.69867438411221,
                -23.840122444961647
              ],
              [
                -54.69847086660481,
                -23.845730939976754
              ],
              [
                -54.69810434256967,
                -23.855853389920345
              ],
              [
                -54.70505476106341,
                -23.86279841508762
              ],
              [
                -54.70972775439128,
                -23.863277914946305
              ],
              [
                -54.721302633924864,
                -23.86449641217509
              ],
              [
                -54.72506812170734,
                -23.865005453925388
              ],
              [
                -54.734842052164666,
                -23.864979979461236
              ],
              [
                -54.744436485846826,
                -23.86675948720762
              ],
              [
                -54.746899476108965,
                -23.86317902868065
              ],
              [
                -54.74692595811405,
                -23.863129001940376
              ],
              [
                -54.74891145553545,
                -23.859367537449426
              ],
              [
                -54.75964639039942,
                -23.85671607119484
              ],
              [
                -54.763629348219744,
                -23.864207569708306
              ],
              [
                -54.77359477423293,
                -23.863703583538268
              ],
              [
                -54.77649772362149,
                -23.86666958500894
              ],
              [
                -54.77864170953932,
                -23.86885910062411
              ],
              [
                -54.785124656389854,
                -23.867928623355592
              ],
              [
                -54.785363685915634,
                -23.867864633369233
              ],
              [
                -54.78829112385126,
                -23.867083638774005
              ],
              [
                -54.79157113668147,
                -23.866445650860072
              ],
              [
                -54.797251567634426,
                -23.87332763815694
              ],
              [
                -54.79738753300448,
                -23.873492636043654
              ],
              [
                -54.80461997691366,
                -23.878043631501793
              ],
              [
                -54.80918596485128,
                -23.880869130011845
              ],
              [
                -54.8134744032397,
                -23.88815563533904
              ],
              [
                -54.81763435133582,
                -23.88736464788493
              ],
              [
                -54.82170081624067,
                -23.886286157103374
              ],
              [
                -54.823506290544685,
                -23.885694190135066
              ],
              [
                -54.82617680172873,
                -23.884817683017893
              ],
              [
                -54.836667721684364,
                -23.882625728418425
              ],
              [
                -54.839843209669716,
                -23.88570371446138
              ],
              [
                -54.84383864899851,
                -23.889986207121403
              ],
              [
                -54.84593813395634,
                -23.89199323425172
              ],
              [
                -54.848743623477944,
                -23.894341699568
              ],
              [
                -54.84925359312085,
                -23.894768226808832
              ],
              [
                -54.8488556003609,
                -23.898990193062644
              ],
              [
                -54.84907656974344,
                -23.905595195242796
              ],
              [
                -54.858687476947715,
                -23.90292973535505
              ],
              [
                -54.86162848131887,
                -23.90405273631844
              ],
              [
                -54.866212955438485,
                -23.90502475727369
              ],
              [
                -54.87230189114126,
                -23.90690373414997
              ],
              [
                -54.87596037924814,
                -23.903231750865796
              ],
              [
                -54.87835934844857,
                -23.900824275070864
              ],
              [
                -54.883276342122166,
                -23.89546883072024
              ],
              [
                -54.88963280551569,
                -23.898211324541947
              ],
              [
                -54.89376572479779,
                -23.9091028031841
              ],
              [
                -54.901472166815395,
                -23.909220814471585
              ],
              [
                -54.907587122813226,
                -23.90971685916824
              ],
              [
                -54.91074705664142,
                -23.915397316359563
              ],
              [
                -54.91250954617753,
                -23.919646317771825
              ],
              [
                -54.924382466124264,
                -23.9225723544227
              ],
              [
                -54.923938958593276,
                -23.926047818119308
              ],
              [
                -54.92213690289654,
                -23.932294326858088
              ],
              [
                -54.929525341955774,
                -23.941337311777907
              ],
              [
                -54.92868081356662,
                -23.944491791236462
              ],
              [
                -54.92767531795041,
                -23.94824926710913
              ],
              [
                -54.92678233182725,
                -23.952182274762237
              ],
              [
                -54.92792829096151,
                -23.95966774516204
              ],
              [
                -54.93658721398272,
                -23.962099225842643
              ],
              [
                -54.93955369368442,
                -23.963346756522846
              ],
              [
                -54.94797514375866,
                -23.961147277137812
              ],
              [
                -54.95077609958034,
                -23.96165629677347
              ],
              [
                -54.96131952980732,
                -23.962637304694155
              ],
              [
                -54.96575750760711,
                -23.962450856165265
              ],
              [
                -54.96874850132546,
                -23.960369367224576
              ],
              [
                -54.97042945593521,
                -23.95892837473129
              ],
              [
                -54.97118395806593,
                -23.95828186544626
              ],
              [
                -54.97499294069206,
                -23.95549537062978
              ],
              [
                -54.98568934388434,
                -23.96126891082559
              ],
              [
                -54.992007797380815,
                -23.958000921030706
              ],
              [
                -54.99793130804889,
                -23.95591344591351
              ],
              [
                -55.017833569242484,
                -23.970227994038744
              ],
              [
                -55.01894058812923,
                -23.971023973051295
              ],
              [
                -55.022440560234294,
                -23.97146199128546
              ],
              [
                -55.02786400556116,
                -23.972140990145093
              ],
              [
                -55.03271747013286,
                -23.972783497222075
              ],
              [
                -55.03576693334226,
                -23.97552601216188
              ],
              [
                -55.037686415700676,
                -23.977252518754536
              ],
              [
                -55.04558332788442,
                -23.983824018003315
              ],
              [
                -55.0506303072499,
                -23.980642554656953
              ],
              [
                -55.05074679470477,
                -23.980569044295493
              ],
              [
                -55.05085529732615,
                -23.980720018149025
              ],
              [
                -55.05313430236939,
                -23.98389103007581
              ],
              [
                -55.06003320109732,
                -23.992131008380852
              ],
              [
                -55.064613667628116,
                -23.99051154319931
              ],
              [
                -55.07161663791589,
                -23.986839594061912
              ],
              [
                -55.071874151822094,
                -23.983375082614486
              ],
              [
                -55.0718796375839,
                -23.980906097964393
              ],
              [
                -55.071882172035096,
                -23.97978010677274
              ],
              [
                -55.07263814528057,
                -23.980618093478075
              ],
              [
                -55.07656208484558,
                -23.984965585960452
              ],
              [
                -55.081848559112096,
                -23.984154126417373
              ],
              [
                -55.08962803074612,
                -23.982522151998257
              ],
              [
                -55.09443449713965,
                -23.97423721704776
              ],
              [
                -55.0969094846141,
                -23.971406219889797
              ],
              [
                -55.098785506668385,
                -23.96926020941408
              ],
              [
                -55.1069019590459,
                -23.963309264679538
              ],
              [
                -55.11818882832222,
                -23.97609625240403
              ],
              [
                -55.12069030595997,
                -23.97695725889002
              ],
              [
                -55.1222197778965,
                -23.977484272242865
              ],
              [
                -55.122287818734144,
                -23.977402273747245
              ],
              [
                -55.124505785387576,
                -23.974742795142397
              ],
              [
                -55.12612077364135,
                -23.977553769089166
              ],
              [
                -55.12679177252747,
                -23.978636766691732
              ],
              [
                -55.128897750919236,
                -23.98203627830694
              ],
              [
                -55.13649416058783,
                -23.984768290590385
              ],
              [
                -55.149052580543966,
                -23.98753632241156
              ],
              [
                -55.152085032621535,
                -23.985066836893054
              ],
              [
                -55.15560354079197,
                -23.982200855338377
              ],
              [
                -55.159196977270064,
                -23.986670334365755
              ],
              [
                -55.15958100261719,
                -23.987148366047226
              ],
              [
                -55.1631819728848,
                -23.98838684296897
              ],
              [
                -55.173544879962904,
                -23.991821883211024
              ],
              [
                -55.17794985491171,
                -23.993201895364574
              ],
              [
                -55.179933345284795,
                -23.9897869114953
              ],
              [
                -55.18127833086635,
                -23.98746939479585
              ],
              [
                -55.18602480334796,
                -23.987347921138557
              ],
              [
                -55.18971126808363,
                -23.98706095547898
              ],
              [
                -55.19720220809833,
                -23.98789945881518
              ],
              [
                -55.197731698345706,
                -23.990905962500534
              ],
              [
                -55.19830670224641,
                -23.994168460481262
              ],
              [
                -55.20473261296385,
                -24.00101842308451
              ],
              [
                -55.24520582368087,
                -23.999156593345287
              ],
              [
                -55.24932478493151,
                -23.998474093477384
              ],
              [
                -55.25409175737454,
                -23.996078124525614
              ],
              [
                -55.25960573075518,
                -23.993361634156923
              ],
              [
                -55.26418271835582,
                -23.98674869468374
              ],
              [
                -55.26438222107994,
                -23.986459659322072
              ],
              [
                -55.264643715871486,
                -23.98654469391616
              ],
              [
                -55.26789071544919,
                -23.98760069529345
              ],
              [
                -55.27026770394755,
                -23.984462681788536
              ],
              [
                -55.277608164521496,
                -23.983193714707227
              ],
              [
                -55.280443160727266,
                -23.97955873540277
              ],
              [
                -55.28280261289946,
                -23.976534760454495
              ],
              [
                -55.290184106009946,
                -23.97204632128264
              ],
              [
                -55.29353510222279,
                -23.969996334403774
              ],
              [
                -55.29649456760587,
                -23.968104353679866
              ],
              [
                -55.303552040254466,
                -23.963563361589088
              ],
              [
                -55.30773450163528,
                -23.962744880379717
              ],
              [
                -55.31176199208617,
                -23.961936389616287
              ],
              [
                -55.31475894144314,
                -23.962247916643378
              ],
              [
                -55.31765143313016,
                -23.96316690028824
              ],
              [
                -55.32133787903964,
                -23.966625933108293
              ],
              [
                -55.32308985541951,
                -23.96991940698491
              ],
              [
                -55.325413850212676,
                -23.97434288601092
              ],
              [
                -55.32910927847699,
                -23.980707379169445
              ],
              [
                -55.33655916414746,
                -23.993538894380546
              ],
              [
                -55.34374513672296,
                -23.994823372276834
              ],
              [
                -55.347445116440824,
                -23.994038402348803
              ],
              [
                -55.35763304505433,
                -23.990194449461992
              ],
              [
                -55.36206251225107,
                -23.98422348428742
              ],
              [
                -55.36324453716891,
                -23.98262999158089
              ],
              [
                -55.37085299564395,
                -23.980433034511428
              ],
              [
                -55.371224954417016,
                -23.980326038119905
              ],
              [
                -55.37526346954655,
                -23.97958903416761
              ],
              [
                -55.375730943230145,
                -23.978330045939416
              ],
              [
                -55.37710893819493,
                -23.974618046956465
              ],
              [
                -55.37783494676948,
                -23.971166583911792
              ],
              [
                -55.387295427855925,
                -23.96801610624582
              ],
              [
                -55.3886809024453,
                -23.971663111745414
              ],
              [
                -55.389026882938275,
                -23.971924098039317
              ],
              [
                -55.39224035436286,
                -23.974348631769278
              ],
              [
                -55.39591131427791,
                -23.97608263034375
              ],
              [
                -55.40157578045942,
                -23.974185142731233
              ],
              [
                -55.40397179674334,
                -23.96606469485707
              ],
              [
                -55.41252075136823,
                -23.96592569808577
              ],
              [
                -55.41866468940722,
                -23.958943742022637
              ],
              [
                -55.41694174804693,
                -23.952324749286404
              ],
              [
                -55.41605077902478,
                -23.948901789700876
              ],
              [
                -55.419332772798356,
                -23.942833812968463
              ],
              [
                -55.42184324654008,
                -23.942425314771402
              ],
              [
                -55.431577207515694,
                -23.94084035878488
              ],
              [
                -55.42726727684778,
                -23.927108887081506
              ],
              [
                -55.42710826800062,
                -23.92660089024163
              ],
              [
                -55.427526272910555,
                -23.926377893390196
              ],
              [
                -55.431858745255916,
                -23.924064920664012
              ],
              [
                -55.43757218906814,
                -23.920671447180773
              ],
              [
                -55.442892175988234,
                -23.9166989653045
              ],
              [
                -55.44019073282234,
                -23.910761497033906
              ],
              [
                -55.43758375874681,
                -23.90538498121796
              ],
              [
                -55.436123331022216,
                -23.890811541966684
              ],
              [
                -55.436018340969554,
                -23.88975355614755
              ],
              [
                -55.43435490547894,
                -23.87295107270331
              ],
              [
                -55.436501895357694,
                -23.867867098896227
              ],
              [
                -55.43680041348219,
                -23.867047624636495
              ],
              [
                -55.43776393610686,
                -23.86440363970565
              ],
              [
                -55.43787892171639,
                -23.85723115707239
              ],
              [
                -55.43787946180965,
                -23.857206658156112
              ],
              [
                -55.438178962562844,
                -23.85319769256702
              ],
              [
                -55.43883451229206,
                -23.830367249156073
              ],
              [
                -55.44259804003518,
                -23.823158799889562
              ],
              [
                -55.44266901368459,
                -23.823119784257763
              ],
              [
                -55.44787601896742,
                -23.82025734127181
              ],
              [
                -55.440119625552974,
                -23.80649382541566
              ],
              [
                -55.436609172092744,
                -23.800781840817624
              ],
              [
                -55.43562717138669,
                -23.799184836887274
              ],
              [
                -55.435038658353015,
                -23.798227872127615
              ],
              [
                -55.43306868982606,
                -23.794313363666607
              ],
              [
                -55.43053525937722,
                -23.78962035893789
              ],
              [
                -55.43146026939375,
                -23.78212141618817
              ],
              [
                -55.434979285277585,
                -23.770482461005813
              ],
              [
                -55.4393767857319,
                -23.765479469487136
              ],
              [
                -55.43943627357886,
                -23.765410998915637
              ],
              [
                -55.44336174263332,
                -23.760853502323773
              ],
              [
                -55.440801280235846,
                -23.757201010036688
              ],
              [
                -55.44016029439572,
                -23.75628700296572
              ],
              [
                -55.441102292150866,
                -23.753380526870746
              ],
              [
                -55.441695311063256,
                -23.75182503607422
              ],
              [
                -55.445047300854675,
                -23.743035573783633
              ],
              [
                -55.44425031101327,
                -23.740117090928585
              ],
              [
                -55.442892342257565,
                -23.73514111411787
              ],
              [
                -55.439713396297755,
                -23.723847630479465
              ],
              [
                -55.43424997196576,
                -23.7172806113532
              ],
              [
                -55.43854295856866,
                -23.71139967144699
              ],
              [
                -55.446277928304475,
                -23.71248970155996
              ],
              [
                -55.44976138501062,
                -23.715423691305066
              ],
              [
                -55.452399843521064,
                -23.717645685630778
              ],
              [
                -55.460852293208475,
                -23.71262423769086
              ],
              [
                -55.46209429324525,
                -23.709099232680607
              ],
              [
                -55.46410979400757,
                -23.70399129057109
              ],
              [
                -55.461329899335006,
                -23.690355295170786
              ],
              [
                -55.460189909389356,
                -23.684764312187234
              ],
              [
                -55.46638688921983,
                -23.67792585883644
              ],
              [
                -55.46716836793356,
                -23.677603855522047
              ],
              [
                -55.4735128401443,
                -23.67498840244729
              ],
              [
                -55.47270241930661,
                -23.658571460012865
              ],
              [
                -55.47265640429982,
                -23.65763644500841
              ],
              [
                -55.472309432012494,
                -23.64966549370148
              ],
              [
                -55.474675954096355,
                -23.647398519209194
              ],
              [
                -55.478737428253424,
                -23.64331850346084
              ],
              [
                -55.48089089971702,
                -23.641085557144283
              ],
              [
                -55.48606638189366,
                -23.642384543979833
              ],
              [
                -55.487331348673784,
                -23.642701548119163
              ],
              [
                -55.491114347609084,
                -23.639950087966316
              ],
              [
                -55.49379734407795,
                -23.63803408334099
              ],
              [
                -55.49494784013315,
                -23.637212577933315
              ],
              [
                -55.495752834514384,
                -23.636933607549643
              ],
              [
                -55.50368828498708,
                -23.634184111196134
              ],
              [
                -55.50758372578212,
                -23.632798667814335
              ],
              [
                -55.507838243952435,
                -23.629741640952552
              ],
              [
                -55.50787874855115,
                -23.629260161708306
              ],
              [
                -55.50851976998719,
                -23.62364720073971
              ],
              [
                -55.51453421477691,
                -23.62557619323973
              ],
              [
                -55.52952111067453,
                -23.630383748899238
              ],
              [
                -55.53228509609306,
                -23.628593756113585
              ],
              [
                -55.53654457312638,
                -23.625472782778104
              ],
              [
                -55.53423510317616,
                -23.61815077873205
              ],
              [
                -55.53420063830362,
                -23.608729810325155
              ],
              [
                -55.53525968868373,
                -23.599558870721776
              ],
              [
                -55.53563869682189,
                -23.59218938080084
              ],
              [
                -55.52955026885619,
                -23.585568386890984
              ],
              [
                -55.52850429571934,
                -23.584429877255417
              ],
              [
                -55.52570578244442,
                -23.581131393084142
              ],
              [
                -55.52832932223116,
                -23.57156291105439
              ],
              [
                -55.523745374491746,
                -23.5672674253338
              ],
              [
                -55.53299536841943,
                -23.55510498504835
              ],
              [
                -55.53319788021904,
                -23.550688022624104
              ],
              [
                -55.53407791429518,
                -23.541378568962916
              ],
              [
                -55.5381073768901,
                -23.533135107147743
              ],
              [
                -55.5396103932313,
                -23.530059617512432
              ],
              [
                -55.53899089144677,
                -23.52830762237616
              ],
              [
                -55.53788642444214,
                -23.525183626267886
              ],
              [
                -55.53971793277228,
                -23.52433612419733
              ],
              [
                -55.544221370999814,
                -23.52225165515482
              ],
              [
                -55.54551995407125,
                -23.503435716022388
              ],
              [
                -55.54709795853248,
                -23.50070322248044
              ],
              [
                -55.54898092794051,
                -23.496915261515255
              ],
              [
                -55.554207415352295,
                -23.489097796234592
              ],
              [
                -55.560137892180826,
                -23.48786282303047
              ],
              [
                -55.5614074255745,
                -23.482365356812938
              ],
              [
                -55.56135442888073,
                -23.47569488619026
              ],
              [
                -55.55495845571661,
                -23.476062338796474
              ],
              [
                -55.546285538732434,
                -23.47667281105288
              ],
              [
                -55.54543255588539,
                -23.473040344523582
              ],
              [
                -55.54194115912426,
                -23.45513686559603
              ],
              [
                -55.54596512050563,
                -23.448472418460774
              ],
              [
                -55.54161020255911,
                -23.442483923368783
              ],
              [
                -55.53610025169472,
                -23.434969949649105
              ],
              [
                -55.52880432237298,
                -23.425413923985328
              ],
              [
                -55.5287653508502,
                -23.425361944033153
              ],
              [
                -55.51694298320973,
                -23.409178460986833
              ],
              [
                -55.51420203879864,
                -23.401377477456673
              ],
              [
                -55.51385705596418,
                -23.400414979590714
              ],
              [
                -55.51362856703987,
                -23.39977949399754
              ],
              [
                -55.51298903646954,
                -23.39799499995525
              ],
              [
                -55.51050006812878,
                -23.391778504888457
              ],
              [
                -55.50646364031689,
                -23.380753034095015
              ],
              [
                -55.50561919727108,
                -23.376358556267267
              ],
              [
                -55.5117141690009,
                -23.36525459111316
              ],
              [
                -55.51501414832816,
                -23.36455912933768
              ],
              [
                -55.520274099386704,
                -23.36344912142176
              ],
              [
                -55.520995664661434,
                -23.354800155229903
              ],
              [
                -55.521304148730785,
                -23.35110618981884
              ],
              [
                -55.52421164286676,
                -23.347293186361064
              ],
              [
                -55.528812130590985,
                -23.340740227797603
              ],
              [
                -55.531918145729634,
                -23.33656178023549
              ],
              [
                -55.53554162815445,
                -23.33161878097699
              ],
              [
                -55.53754260325561,
                -23.33025581519474
              ],
              [
                -55.540680086306885,
                -23.328117831786326
              ],
              [
                -55.550272076079715,
                -23.321994363157955
              ],
              [
                -55.555779539600955,
                -23.318501901145655
              ],
              [
                -55.554219067536245,
                -23.313594386727242
              ],
              [
                -55.55218360799627,
                -23.307343424330373
              ],
              [
                -55.55148609718105,
                -23.305199433343127
              ],
              [
                -55.55046861350758,
                -23.3023069438566
              ],
              [
                -55.5515816216807,
                -23.29813895271329
              ],
              [
                -55.55338164288749,
                -23.291399471590648
              ],
              [
                -55.5538481886454,
                -23.283064531535125
              ],
              [
                -55.55387467755638,
                -23.27757653533554
              ],
              [
                -55.548451233186675,
                -23.274896549686687
              ],
              [
                -55.5471867406084,
                -23.274250045744967
              ],
              [
                -55.54287029858682,
                -23.272046039109515
              ],
              [
                -55.53155640128258,
                -23.266132512202745
              ],
              [
                -55.52277246880503,
                -23.25847048558899
              ],
              [
                -55.52571645005003,
                -23.25584904219234
              ],
              [
                -55.53015394296271,
                -23.251344533122392
              ],
              [
                -55.53570591443386,
                -23.25037557162127
              ],
              [
                -55.537241394608714,
                -23.25010759656712
              ],
              [
                -55.540913887730156,
                -23.24252811749319
              ],
              [
                -55.537745440261716,
                -23.234668640978846
              ],
              [
                -55.53972895514515,
                -23.231564144085482
              ],
              [
                -55.54158546577285,
                -23.22771666231636
              ],
              [
                -55.543058954717665,
                -23.224753192271496
              ],
              [
                -55.545931446728304,
                -23.219192230686904
              ],
              [
                -55.54103046864616,
                -23.218855681800765
              ],
              [
                -55.53380454588489,
                -23.216198711248513
              ],
              [
                -55.5305461048952,
                -23.212547202085414
              ],
              [
                -55.5287200856336,
                -23.210501182116253
              ],
              [
                -55.52591065290856,
                -23.206734199825323
              ],
              [
                -55.52332920522343,
                -23.196522725358882
              ],
              [
                -55.525581663407266,
                -23.193881256769366
              ],
              [
                -55.53136267584054,
                -23.189622786249583
              ],
              [
                -55.534826631954346,
                -23.186068821743103
              ],
              [
                -55.53543763403479,
                -23.18544178988748
              ],
              [
                -55.54252463382945,
                -23.179723346631135
              ],
              [
                -55.542584604851484,
                -23.17620537187879
              ],
              [
                -55.53998867612716,
                -23.174127859517952
              ],
              [
                -55.539748648554436,
                -23.173936352390225
              ],
              [
                -55.539925674018946,
                -23.17113188757897
              ],
              [
                -55.540078189148204,
                -23.168721875336683
              ],
              [
                -55.5425121487925,
                -23.16667039831549
              ],
              [
                -55.542538693592135,
                -23.155674416097792
              ],
              [
                -55.54757566561478,
                -23.154208963561985
              ],
              [
                -55.55085514670574,
                -23.15335795818314
              ],
              [
                -55.554635118720796,
                -23.152387500847112
              ],
              [
                -55.56634606262556,
                -23.143066043016535
              ],
              [
                -55.57396952545088,
                -23.143163584560284
              ],
              [
                -55.574533031600424,
                -23.143392567614733
              ],
              [
                -55.58345792777281,
                -23.147021583314466
              ],
              [
                -55.58643940358358,
                -23.148109597322016
              ],
              [
                -55.59196136466933,
                -23.150455606319504
              ],
              [
                -55.596665841277535,
                -23.152080590120267
              ],
              [
                -55.59674890002948,
                -23.12946070583722
              ],
              [
                -55.59822291355907,
                -23.120827214223084
              ],
              [
                -55.595565923313686,
                -23.119511232066763
              ],
              [
                -55.59494545886171,
                -23.1192037180971
              ],
              [
                -55.59954794768945,
                -23.103909269529492
              ],
              [
                -55.60444696538809,
                -23.09754531331625
              ],
              [
                -55.60486993649878,
                -23.096995828578734
              ],
              [
                -55.610830942133184,
                -23.089207350708648
              ],
              [
                -55.6162184279675,
                -23.081639400211962
              ],
              [
                -55.61074497765164,
                -23.07766789400858
              ],
              [
                -55.609937988568205,
                -23.077081927391106
              ],
              [
                -55.610857061085994,
                -23.05867246556778
              ],
              [
                -55.61414155339157,
                -23.05434349111817
              ],
              [
                -55.61005460872365,
                -23.048674498292065
              ],
              [
                -55.612640607323634,
                -23.041348033870566
              ],
              [
                -55.62160905826139,
                -23.03367759238292
              ],
              [
                -55.62259253852604,
                -23.032836593469476
              ],
              [
                -55.62697551932038,
                -23.030290140466406
              ],
              [
                -55.62987103860231,
                -23.029322147204613
              ],
              [
                -55.638413959782696,
                -23.026071695140633
              ],
              [
                -55.63028062784959,
                -22.998958240899594
              ],
              [
                -55.632076120207735,
                -22.994998781346972
              ],
              [
                -55.62736568263344,
                -22.989648250464136
              ],
              [
                -55.62575218393337,
                -22.987815282535294
              ],
              [
                -55.62184873994992,
                -22.985561282626684
              ],
              [
                -55.62300924348093,
                -22.982205281944555
              ],
              [
                -55.625790230637236,
                -22.97883982655235
              ],
              [
                -55.63179621369474,
                -22.969499359190934
              ],
              [
                -55.63392870951532,
                -22.96637136587666
              ],
              [
                -55.63676920624148,
                -22.96195038631006
              ],
              [
                -55.637243202694016,
                -22.961212927386377
              ],
              [
                -55.63713870162431,
                -22.960896898135832
              ],
              [
                -55.6349657166758,
                -22.95434693965151
              ],
              [
                -55.638628727174435,
                -22.950833931625013
              ],
              [
                -55.64073969613473,
                -22.950618446208658
              ],
              [
                -55.646558647439456,
                -22.950023958115317
              ],
              [
                -55.64497120576829,
                -22.944423492261244
              ],
              [
                -55.64438669978015,
                -22.941538000308302
              ],
              [
                -55.64689472230055,
                -22.93778752735562
              ],
              [
                -55.650718715700556,
                -22.932411568266147
              ],
              [
                -55.64606525561422,
                -22.924415562756717
              ],
              [
                -55.64808180147676,
                -22.91300460263174
              ],
              [
                -55.64928327602296,
                -22.90955811740678
              ],
              [
                -55.65008930155743,
                -22.903668639330768
              ],
              [
                -55.64979079327625,
                -22.902973658762818
              ],
              [
                -55.647938355559866,
                -22.8986601636546
              ],
              [
                -55.65035584885635,
                -22.887697687309092
              ],
              [
                -55.65454481957602,
                -22.885261720260416
              ],
              [
                -55.655005841375846,
                -22.884985221705275
              ],
              [
                -55.65745580468834,
                -22.883514763701122
              ],
              [
                -55.660016295130475,
                -22.881803737884248
              ],
              [
                -55.66034033088548,
                -22.87781076171508
              ],
              [
                -55.65958935553811,
                -22.871334802647684
              ],
              [
                -55.65891186197406,
                -22.866105828517668
              ],
              [
                -55.66148189572461,
                -22.86083782901602
              ],
              [
                -55.66485287550057,
                -22.8506068922486
              ],
              [
                -55.661852433377355,
                -22.846405379082274
              ],
              [
                -55.65854845345518,
                -22.84218487800821
              ],
              [
                -55.658468987160276,
                -22.840814881488548
              ],
              [
                -55.658119011310696,
                -22.834773427251047
              ],
              [
                -55.65802650986672,
                -22.8310699317622
              ],
              [
                -55.65788302349328,
                -22.825310933222386
              ],
              [
                -55.656449553312825,
                -22.8229804566635
              ],
              [
                -55.65560704237448,
                -22.82161043568787
              ],
              [
                -55.653358094286126,
                -22.818116955528282
              ],
              [
                -55.65447107930149,
                -22.81282846942348
              ],
              [
                -55.65547561815014,
                -22.80805302019516
              ],
              [
                -55.65309565042022,
                -22.805773491419913
              ],
              [
                -55.64919466084088,
                -22.80203650779571
              ],
              [
                -55.65049517978613,
                -22.798545034081034
              ],
              [
                -55.651627686229936,
                -22.79550203227189
              ],
              [
                -55.65210116188159,
                -22.7923045688761
              ],
              [
                -55.638538789778096,
                -22.782266026016657
              ],
              [
                -55.63675434983793,
                -22.7786540588035
              ],
              [
                -55.63356589586195,
                -22.772342574445315
              ],
              [
                -55.631106917803464,
                -22.76815608006729
              ],
              [
                -55.62931593518895,
                -22.76740807690412
              ],
              [
                -55.62716944263068,
                -22.76651108811562
              ],
              [
                -55.623907470278,
                -22.76658654436416
              ],
              [
                -55.61684052438341,
                -22.767543021318325
              ],
              [
                -55.62066651973867,
                -22.763195574720474
              ],
              [
                -55.62394051449317,
                -22.758174084688537
              ],
              [
                -55.621120539885645,
                -22.753472103960867
              ],
              [
                -55.618267097583356,
                -22.74339461381923
              ],
              [
                -55.612671660818,
                -22.738599625521278
              ],
              [
                -55.61507462954732,
                -22.73441414679539
              ],
              [
                -55.61491565003823,
                -22.730294180616653
              ],
              [
                -55.61415618142791,
                -22.725187183947515
              ],
              [
                -55.61303672113143,
                -22.720136708931168
              ],
              [
                -55.61226823622723,
                -22.716463721250527
              ],
              [
                -55.612334732813906,
                -22.715376218249723
              ],
              [
                -55.612642238363065,
                -22.710356237714006
              ],
              [
                -55.61288726850053,
                -22.70556325919051
              ],
              [
                -55.613065784249905,
                -22.69885077514308
              ],
              [
                -55.61120482574108,
                -22.694443267532883
              ],
              [
                -55.610746829993836,
                -22.69335977584028
              ],
              [
                -55.60965032592952,
                -22.688731291456378
              ],
              [
                -55.61305884928899,
                -22.681391834961047
              ],
              [
                -55.61469085488709,
                -22.677879834432588
              ],
              [
                -55.615048868153444,
                -22.67471237262257
              ],
              [
                -55.61467484323575,
                -22.67001887779088
              ],
              [
                -55.61438535882892,
                -22.6663718965833
              ],
              [
                -55.613554422213625,
                -22.660305907083625
              ],
              [
                -55.614791430658215,
                -22.655694430132872
              ],
              [
                -55.620235882706844,
                -22.652384981104277
              ],
              [
                -55.622302925762014,
                -22.63930502736477
              ],
              [
                -55.62358842763336,
                -22.63071006548214
              ],
              [
                -55.6240789581208,
                -22.627763569339162
              ],
              [
                -55.629241936404334,
                -22.62357509147095
              ],
              [
                -55.63723589640732,
                -22.616261159562754
              ],
              [
                -55.64057287075511,
                -22.61470464566307
              ],
              [
                -55.64366837649183,
                -22.612691685584764
              ],
              [
                -55.647860840343405,
                -22.61045971177614
              ],
              [
                -55.65143232371567,
                -22.608454192191427
              ],
              [
                -55.66062525492794,
                -22.602596743015305
              ],
              [
                -55.663004768853824,
                -22.601080784910156
              ],
              [
                -55.663430737124635,
                -22.600471283398715
              ],
              [
                -55.66727725951551,
                -22.594973819877943
              ],
              [
                -55.67333921630161,
                -22.5924633290065
              ],
              [
                -55.67532619441656,
                -22.591640324226518
              ],
              [
                -55.680805160201615,
                -22.5894258634576
              ],
              [
                -55.6860301529906,
                -22.58310291821343
              ],
              [
                -55.69096061381004,
                -22.578949425455207
              ],
              [
                -55.692786629188596,
                -22.57376997136308
              ],
              [
                -55.69323513992273,
                -22.57164196622288
              ],
              [
                -55.69360164379654,
                -22.569901456468557
              ],
              [
                -55.694493129426874,
                -22.566692007518537
              ],
              [
                -55.69469565541209,
                -22.56306951081246
              ],
              [
                -55.69941062503605,
                -22.56126803715018
              ],
              [
                -55.70518112201081,
                -22.558995564499906
              ],
              [
                -55.708849066153455,
                -22.557957564829326
              ],
              [
                -55.71138655003527,
                -22.55723908143542
              ],
              [
                -55.71717254680403,
                -22.553374104389807
              ],
              [
                -55.72129752397122,
                -22.551466116211998
              ],
              [
                -55.72563151479575,
                -22.543306662516713
              ],
              [
                -55.72911101024195,
                -22.53686621409611
              ],
              [
                -55.73501900438111,
                -22.525094270033225
              ],
              [
                -55.74578148600546,
                -22.503982871060906
              ],
              [
                -55.74774549057818,
                -22.500103385869313
              ],
              [
                -55.74749456509572,
                -22.485254437957995
              ],
              [
                -55.74970304687277,
                -22.480309443273807
              ],
              [
                -55.75127306380955,
                -22.47598947437326
              ],
              [
                -55.74967958535746,
                -22.47180897292036
              ],
              [
                -55.746517644584955,
                -22.463361503409516
              ],
              [
                -55.74318866778873,
                -22.46202599649523
              ],
              [
                -55.74238616158801,
                -22.46170401106992
              ],
              [
                -55.73857221334095,
                -22.45978298366526
              ],
              [
                -55.734977726928726,
                -22.458305003410384
              ],
              [
                -55.73763923661215,
                -22.452787034190113
              ],
              [
                -55.736633237451805,
                -22.44713205179505
              ],
              [
                -55.735625797968765,
                -22.442653547280205
              ],
              [
                -55.73435829313253,
                -22.43713255759598
              ],
              [
                -55.73526633197738,
                -22.427826618092315
              ],
              [
                -55.73587833734596,
                -22.424383114105897
              ],
              [
                -55.736512837928416,
                -22.415118665601792
              ],
              [
                -55.7369428779642,
                -22.409658172116572
              ],
              [
                -55.744242326404105,
                -22.40407571385448
              ],
              [
                -55.73948793268402,
                -22.39452475928145
              ],
              [
                -55.741117425122326,
                -22.391843271858203
              ],
              [
                -55.744119388750335,
                -22.388947280539014
              ],
              [
                -55.747949376283,
                -22.38777178980657
              ],
              [
                -55.75173533724311,
                -22.38656432104638
              ],
              [
                -55.75609834856371,
                -22.385522840532957
              ],
              [
                -55.758911300476385,
                -22.38482083891405
              ],
              [
                -55.76206377492124,
                -22.383872350235997
              ],
              [
                -55.76535428004757,
                -22.383020859655183
              ],
              [
                -55.769720209183035,
                -22.38448188037464
              ],
              [
                -55.77337619515361,
                -22.385704873680005
              ],
              [
                -55.78187313445838,
                -22.38388688499909
              ],
              [
                -55.784345114826166,
                -22.385622389756602
              ],
              [
                -55.78838656701121,
                -22.387417931284162
              ],
              [
                -55.78943260376388,
                -22.383857434077257
              ],
              [
                -55.79033061923945,
                -22.38010843906237
              ],
              [
                -55.802276034881196,
                -22.372182983712243
              ],
              [
                -55.7990980952464,
                -22.36640452088799
              ],
              [
                -55.797759125018736,
                -22.363559515616934
              ],
              [
                -55.798449082301104,
                -22.360802520469026
              ],
              [
                -55.79988612230512,
                -22.355058567162303
              ],
              [
                -55.802980098965904,
                -22.353349053959043
              ],
              [
                -55.807479592412854,
                -22.350657606650817
              ],
              [
                -55.81327656053508,
                -22.347221145063344
              ],
              [
                -55.819426494966194,
                -22.343575166326126
              ],
              [
                -55.82344698101353,
                -22.341089180775473
              ],
              [
                -55.83176799407532,
                -22.32383976459996
              ],
              [
                -55.83255000778984,
                -22.31859727446033
              ],
              [
                -55.83417901667373,
                -22.311690805840502
              ],
              [
                -55.834717024253536,
                -22.309410843541077
              ],
              [
                -55.83613802768694,
                -22.301652850256477
              ],
              [
                -55.837496550590494,
                -22.29388238682362
              ],
              [
                -55.84872101949016,
                -22.28370546802794
              ],
              [
                -55.85243000474082,
                -22.281485492542842
              ],
              [
                -55.860088954390626,
                -22.280931016696954
              ],
              [
                -55.870350350298345,
                -22.28191653267799
              ],
              [
                -55.870576359645355,
                -22.28193803031715
              ],
              [
                -55.89631118215503,
                -22.284747105849966
              ],
              [
                -55.90236763016924,
                -22.28250712385333
              ],
              [
                -55.90633962015793,
                -22.280689121784444
              ],
              [
                -55.90679262676038,
                -22.280482154942135
              ],
              [
                -55.91152409357957,
                -22.27835915234259
              ],
              [
                -55.915937060379015,
                -22.276575694545553
              ],
              [
                -55.918319512299014,
                -22.279451172012468
              ],
              [
                -55.9290549636218,
                -22.27807623678553
              ],
              [
                -55.944529817307725,
                -22.282912736829292
              ],
              [
                -55.94490580460503,
                -22.28375772606422
              ],
              [
                -55.94854675241964,
                -22.29192773402562
              ],
              [
                -55.95383773862829,
                -22.28891773329813
              ],
              [
                -55.963253191488135,
                -22.289375265510852
              ],
              [
                -55.967690149321726,
                -22.28973629929963
              ],
              [
                -55.972247123043324,
                -22.286345313574582
              ],
              [
                -55.97277863379483,
                -22.285949818639054
              ],
              [
                -55.97602762310006,
                -22.283518847417646
              ],
              [
                -55.98257655959234,
                -22.278593389355414
              ],
              [
                -55.989436507699715,
                -22.28029689212592
              ],
              [
                -55.999355437652135,
                -22.283069884857277
              ],
              [
                -56.00071790721304,
                -22.285797393655027
              ],
              [
                -56.00145989958904,
                -22.28729287978752
              ],
              [
                -56.004086393984494,
                -22.292584878648295
              ],
              [
                -56.00989734650475,
                -22.2923439059022
              ],
              [
                -56.01341335452262,
                -22.286484950474613
              ],
              [
                -56.01376181022649,
                -22.28590442060316
              ],
              [
                -56.019350784439865,
                -22.285351452238718
              ],
              [
                -56.01992027474898,
                -22.285279466294046
              ],
              [
                -56.02945673496577,
                -22.284069997726395
              ],
              [
                -56.04218762791604,
                -22.28234454808035
              ],
              [
                -56.045718633195406,
                -22.280764033776194
              ],
              [
                -56.056131059001565,
                -22.276103592005303
              ],
              [
                -56.05997101499332,
                -22.27675159691641
              ],
              [
                -56.0669299760596,
                -22.282125603261605
              ],
              [
                -56.06706496574259,
                -22.28222962732742
              ],
              [
                -56.07093342600905,
                -22.285481587273516
              ],
              [
                -56.07716641053756,
                -22.284183108553897
              ],
              [
                -56.08150238750722,
                -22.282807660086903
              ],
              [
                -56.0818728743667,
                -22.282690661668607
              ],
              [
                -56.08205787588459,
                -22.282745133065678
              ],
              [
                -56.087871329371815,
                -22.284440657602104
              ],
              [
                -56.096032235217294,
                -22.282961204626535
              ],
              [
                -56.09959125663947,
                -22.280751710594746
              ],
              [
                -56.101453722963896,
                -22.279595722519716
              ],
              [
                -56.10461520766758,
                -22.277516243774013
              ],
              [
                -56.10801019838926,
                -22.275809761471397
              ],
              [
                -56.11204467409973,
                -22.273442771491627
              ],
              [
                -56.11696714473696,
                -22.270781791348
              ],
              [
                -56.12340212010609,
                -22.271070307748673
              ],
              [
                -56.123070072824724,
                -22.27478028280524
              ],
              [
                -56.127265057311334,
                -22.274085820521726
              ],
              [
                -56.12815155878903,
                -22.278559808793204
              ],
              [
                -56.12820152551639,
                -22.27881281107296
              ],
              [
                -56.128848535249105,
                -22.282576762054283
              ],
              [
                -56.13239998758571,
                -22.28368680346803
              ],
              [
                -56.13652296018893,
                -22.285281787013922
              ],
              [
                -56.14073941997549,
                -22.28419431416859
              ],
              [
                -56.146322899055555,
                -22.28262782832332
              ],
              [
                -56.14966587378618,
                -22.28157033760129
              ],
              [
                -56.15285933919958,
                -22.28279285349435
              ],
              [
                -56.15754429861103,
                -22.28474636158785
              ],
              [
                -56.16017728428063,
                -22.282592385415647
              ],
              [
                -56.16288228976635,
                -22.2852643537359
              ],
              [
                -56.16515326665834,
                -22.287007892900114
              ],
              [
                -56.17009671771379,
                -22.285025880465504
              ],
              [
                -56.17409918655,
                -22.281061410914518
              ],
              [
                -56.17805167490531,
                -22.278141421582813
              ],
              [
                -56.18290264628088,
                -22.27815946191845
              ],
              [
                -56.18678813075007,
                -22.2807934661256
              ],
              [
                -56.18891710183284,
                -22.278117471156648
              ],
              [
                -56.19140761833365,
                -22.273280504823106
              ],
              [
                -56.19198961445461,
                -22.2721489935914
              ],
              [
                -56.19295960164503,
                -22.27272251968274
              ],
              [
                -56.2000675590967,
                -22.276923504692814
              ],
              [
                -56.20322052953192,
                -22.27440355043097
              ],
              [
                -56.20941097442087,
                -22.276398558176734
              ],
              [
                -56.21245246831715,
                -22.273146074095294
              ],
              [
                -56.215882981031385,
                -22.269209609616375
              ],
              [
                -56.22000495783107,
                -22.265583112258035
              ],
              [
                -56.22568492786827,
                -22.261410139735247
              ],
              [
                -56.2283409232712,
                -22.25929317049449
              ],
              [
                -56.232493876037594,
                -22.2561646985057
              ],
              [
                -56.232473861175144,
                -22.25479970116815
              ],
              [
                -56.2324508871532,
                -22.253210673597195
              ],
              [
                -56.23255939184753,
                -22.248719709493052
              ],
              [
                -56.238714916470954,
                -22.23529228052494
              ],
              [
                -56.24164890618518,
                -22.235571774860492
              ],
              [
                -56.24681534470778,
                -22.236336788595075
              ],
              [
                -56.254255808066574,
                -22.237229318103267
              ],
              [
                -56.25752076830841,
                -22.23756180330859
              ],
              [
                -56.259099249538124,
                -22.23780033449003
              ],
              [
                -56.26154924426892,
                -22.23817082297975
              ],
              [
                -56.26413922381771,
                -22.236414849272577
              ],
              [
                -56.26912321784603,
                -22.23301885083389
              ],
              [
                -56.27291118436944,
                -22.233929891697287
              ],
              [
                -56.275907620599405,
                -22.23586737850055
              ],
              [
                -56.27840916496609,
                -22.232690395426147
              ],
              [
                -56.281490613729176,
                -22.229677939621617
              ],
              [
                -56.28530113537994,
                -22.225594960831796
              ],
              [
                -56.28654160491218,
                -22.224750471687297
              ],
              [
                -56.2927620574915,
                -22.22051700505867
              ],
              [
                -56.30137655303609,
                -22.215061522244753
              ],
              [
                -56.308263487059186,
                -22.213555062292144
              ],
              [
                -56.310907977940985,
                -22.212977087676165
              ],
              [
                -56.315292410191724,
                -22.21749406696599
              ],
              [
                -56.31890341676926,
                -22.212992100979044
              ],
              [
                -56.32123091973805,
                -22.20970809671119
              ],
              [
                -56.3236228866588,
                -22.20604210496782
              ],
              [
                -56.328722907063266,
                -22.202336135947522
              ],
              [
                -56.329304873990985,
                -22.199070676080478
              ],
              [
                -56.33560235195848,
                -22.196237184837106
              ],
              [
                -56.343802355510725,
                -22.185006761645216
              ],
              [
                -56.34330937711874,
                -22.17919926216235
              ],
              [
                -56.34740332161734,
                -22.178187790628446
              ],
              [
                -56.35002582705689,
                -22.17394331061101
              ],
              [
                -56.352930293700105,
                -22.173493327366405
              ],
              [
                -56.35665829942892,
                -22.173658353604377
              ],
              [
                -56.36291926195921,
                -22.169924392342903
              ],
              [
                -56.3652702500366,
                -22.162730396656702
              ],
              [
                -56.36639978432235,
                -22.158603435501504
              ],
              [
                -56.368725257983904,
                -22.152390436346728
              ],
              [
                -56.37199777360903,
                -22.14988295837574
              ],
              [
                -56.3720472750716,
                -22.143355484107627
              ],
              [
                -56.369942813396676,
                -22.14038098673897
              ],
              [
                -56.366665857823385,
                -22.135750524395274
              ],
              [
                -56.36983736716295,
                -22.12966754947809
              ],
              [
                -56.3727588193297,
                -22.12520154917354
              ],
              [
                -56.3792268287236,
                -22.115894593837048
              ],
              [
                -56.38096934508888,
                -22.113051114188142
              ],
              [
                -56.383026833192126,
                -22.10913465824922
              ],
              [
                -56.38149534147489,
                -22.10453165434873
              ],
              [
                -56.3850463379898,
                -22.10427415945921
              ],
              [
                -56.38730730248701,
                -22.10206270125969
              ],
              [
                -56.38768132424481,
                -22.10169716970695
              ],
              [
                -56.39139229409499,
                -22.098572201250455
              ],
              [
                -56.39225178087534,
                -22.09535821209439
              ],
              [
                -56.3919632997627,
                -22.094834721996016
              ],
              [
                -56.38983284248011,
                -22.090965743667542
              ],
              [
                -56.389376837138535,
                -22.08532024778498
              ],
              [
                -56.39030537873066,
                -22.08174977808724
              ],
              [
                -56.39133486136079,
                -22.077977268409157
              ],
              [
                -56.39231836512454,
                -22.074254306415167
              ],
              [
                -56.39738781632467,
                -22.078094290108247
              ],
              [
                -56.401385817920605,
                -22.07834829884793
              ],
              [
                -56.404524761277266,
                -22.076991313456613
              ],
              [
                -56.409322721602145,
                -22.07800984607481
              ],
              [
                -56.41554467068878,
                -22.078878839709002
              ],
              [
                -56.42137512226415,
                -22.08255535239373
              ],
              [
                -56.426251601189485,
                -22.079962385766294
              ],
              [
                -56.428281111744305,
                -22.08257635713115
              ],
              [
                -56.42953606166934,
                -22.0843513659541
              ],
              [
                -56.43046956245661,
                -22.085671371336613
              ],
              [
                -56.43508804116858,
                -22.08280188285029
              ],
              [
                -56.43803503158832,
                -22.080970390493103
              ],
              [
                -56.44120099315323,
                -22.082260907030136
              ],
              [
                -56.44234249239234,
                -22.082789396808465
              ],
              [
                -56.44597195436611,
                -22.084467436472547
              ],
              [
                -56.4463019488899,
                -22.083700436166673
              ],
              [
                -56.4489289590386,
                -22.07759947255847
              ],
              [
                -56.45119742437596,
                -22.082298434479107
              ],
              [
                -56.45424890941719,
                -22.08175096201184
              ],
              [
                -56.456832386579556,
                -22.078479472480858
              ],
              [
                -56.46123785533859,
                -22.07902949949703
              ],
              [
                -56.46186184613574,
                -22.082856481244523
              ],
              [
                -56.469004301395806,
                -22.079480012477582
              ],
              [
                -56.47237777866751,
                -22.08376200434702
              ],
              [
                -56.476303221545024,
                -22.088484488447325
              ],
              [
                -56.47556573949923,
                -22.09043697337537
              ],
              [
                -56.47454221964729,
                -22.09314997477386
              ],
              [
                -56.4817036655021,
                -22.093425493466206
              ],
              [
                -56.48642412982265,
                -22.09323099906383
              ],
              [
                -56.48796763832661,
                -22.09347999208566
              ],
              [
                -56.490113640342635,
                -22.093826493676634
              ],
              [
                -56.4948855844922,
                -22.094407513763926
              ],
              [
                -56.50175905404229,
                -22.09531506037422
              ],
              [
                -56.50609047089512,
                -22.099279557409076
              ],
              [
                -56.507665959410545,
                -22.103034034454183
              ],
              [
                -56.51633139981408,
                -22.10802053769993
              ],
              [
                -56.51873283263546,
                -22.115620513131553
              ],
              [
                -56.52062880584456,
                -22.12022353129024
              ],
              [
                -56.52378978058357,
                -22.121481011974463
              ],
              [
                -56.52216576994507,
                -22.127173503241128
              ],
              [
                -56.52568123201717,
                -22.129317002005212
              ],
              [
                -56.524178763802155,
                -22.131375997161165
              ],
              [
                -56.52387075537523,
                -22.13179748773522
              ],
              [
                -56.52036126370595,
                -22.13665443790549
              ],
              [
                -56.51840028126065,
                -22.139363419371772
              ],
              [
                -56.5239477308448,
                -22.14001346372112
              ],
              [
                -56.52832765878553,
                -22.14741093217588
              ],
              [
                -56.53316564051579,
                -22.147437944426134
              ],
              [
                -56.53728413019315,
                -22.14746194240576
              ],
              [
                -56.53692611795904,
                -22.151480433463735
              ],
              [
                -56.53675159450386,
                -22.153846939780312
              ],
              [
                -56.536472563630106,
                -22.15761741794368
              ],
              [
                -56.53339209190418,
                -22.157301898253387
              ],
              [
                -56.537113050678386,
                -22.16077843011986
              ],
              [
                -56.54282999376457,
                -22.16316243807818
              ],
              [
                -56.54445950410642,
                -22.16384241891609
              ],
              [
                -56.547195991872925,
                -22.165338918466002
              ],
              [
                -56.55501739410504,
                -22.1692079260224
              ],
              [
                -56.55429588667341,
                -22.17422790051126
              ],
              [
                -56.553260394743056,
                -22.17778587601915
              ],
              [
                -56.55317591725642,
                -22.1780768787528
              ],
              [
                -56.55255088786186,
                -22.181811866958622
              ],
              [
                -56.551622360786965,
                -22.184934884299675
              ],
              [
                -56.556356346575804,
                -22.190055371789395
              ],
              [
                -56.557239815479896,
                -22.195795834924805
              ],
              [
                -56.56013826083418,
                -22.197099346673316
              ],
              [
                -56.56355924830511,
                -22.200394335263685
              ],
              [
                -56.568075182539026,
                -22.20688734139228
              ],
              [
                -56.57424617611147,
                -22.20583484062563
              ],
              [
                -56.574548682379195,
                -22.20247087710306
              ],
              [
                -56.5746351783132,
                -22.201509393669706
              ],
              [
                -56.57956863921059,
                -22.205134862953187
              ],
              [
                -56.58029162045092,
                -22.20698986090528
              ],
              [
                -56.58156207178328,
                -22.210249856793233
              ],
              [
                -56.587238531793126,
                -22.212456857307878
              ],
              [
                -56.58967602761678,
                -22.213404399825087
              ],
              [
                -56.59148097647433,
                -22.215795363394466
              ],
              [
                -56.59266249063073,
                -22.217361353801525
              ],
              [
                -56.59300746760596,
                -22.216996896145734
              ],
              [
                -56.597742987565205,
                -22.21198691634804
              ],
              [
                -56.598436946433964,
                -22.21671991237754
              ],
              [
                -56.60129890251452,
                -22.21952940088759
              ],
              [
                -56.60668989938088,
                -22.220948917204073
              ],
              [
                -56.60698985669485,
                -22.223842870273145
              ],
              [
                -56.60595484550076,
                -22.227185398559055
              ],
              [
                -56.60580083672907,
                -22.227682386370955
              ],
              [
                -56.60505586161738,
                -22.232093872931024
              ],
              [
                -56.61298527717876,
                -22.23675488228422
              ],
              [
                -56.61789074784231,
                -22.240220859090783
              ],
              [
                -56.62252870146152,
                -22.24310938002149
              ],
              [
                -56.626108167329384,
                -22.24545386443798
              ],
              [
                -56.628466124803836,
                -22.243927394162366
              ],
              [
                -56.629901154400216,
                -22.242997911907803
              ],
              [
                -56.633721108354756,
                -22.24052391452398
              ],
              [
                -56.636624123330805,
                -22.241924900003287
              ],
              [
                -56.64028957447288,
                -22.24403691149203
              ],
              [
                -56.639305060374,
                -22.2483398846858
              ],
              [
                -56.63822954048142,
                -22.25112537576196
              ],
              [
                -56.63772657012881,
                -22.25242787371873
              ],
              [
                -56.63603454520251,
                -22.25799738034198
              ],
              [
                -56.63498952522209,
                -22.26237783508276
              ],
              [
                -56.6392574879681,
                -22.262931348818373
              ],
              [
                -56.643735469617404,
                -22.262307359235894
              ],
              [
                -56.649549930302676,
                -22.25864890125213
              ],
              [
                -56.650519469236905,
                -22.254693404816848
              ],
              [
                -56.65830942835866,
                -22.252949930611933
              ],
              [
                -56.66321939084882,
                -22.25265694427784
              ],
              [
                -56.67016435782622,
                -22.248996473900796
              ],
              [
                -56.676706278699314,
                -22.245300041357062
              ],
              [
                -56.67849729568994,
                -22.240618031007596
              ],
              [
                -56.68089330736455,
                -22.234353551976064
              ],
              [
                -56.6824743105318,
                -22.229468076651766
              ],
              [
                -56.68099235795326,
                -22.221776634120484
              ],
              [
                -56.684654819806056,
                -22.22017512225032
              ],
              [
                -56.68775029078482,
                -22.22231311683743
              ],
              [
                -56.68906981426495,
                -22.219458149766048
              ],
              [
                -56.69502427969527,
                -22.21888016071637
              ],
              [
                -56.697942244010996,
                -22.21989817955336
              ],
              [
                -56.701855234942066,
                -22.218356684276127
              ],
              [
                -56.6997757252859,
                -22.225388671711993
              ],
              [
                -56.69294676236947,
                -22.227450609916318
              ],
              [
                -56.69415173824252,
                -22.22932363638422
              ],
              [
                -56.697493704289336,
                -22.234517111372167
              ],
              [
                -56.69961915152486,
                -22.23648362146326
              ],
              [
                -56.70211612840811,
                -22.239214107143507
              ],
              [
                -56.7041591243001,
                -22.241698107216084
              ],
              [
                -56.71033955724454,
                -22.248708595113094
              ],
              [
                -56.71309352056533,
                -22.252199108045463
              ],
              [
                -56.716078000260325,
                -22.255974091752467
              ],
              [
                -56.71951296547364,
                -22.261485072009172
              ],
              [
                -56.72694492042071,
                -22.258849604700377
              ],
              [
                -56.731583395208645,
                -22.255738669079385
              ],
              [
                -56.734834846285786,
                -22.253763154171946
              ],
              [
                -56.73233891399861,
                -22.249784187708
              ],
              [
                -56.73060092708046,
                -22.24701317456712
              ],
              [
                -56.73614138878432,
                -22.24415269997255
              ],
              [
                -56.739633391645,
                -22.24124723998829
              ],
              [
                -56.742522354568834,
                -22.238356757026786
              ],
              [
                -56.7451488510288,
                -22.234992771834488
              ],
              [
                -56.74756484578283,
                -22.238726248871707
              ],
              [
                -56.74930182408338,
                -22.2419402585083
              ],
              [
                -56.75249480538832,
                -22.24408372943098
              ],
              [
                -56.75587374280222,
                -22.242868762345307
              ],
              [
                -56.762911219040284,
                -22.243881283388088
              ],
              [
                -56.76182270788867,
                -22.24702275825545
              ],
              [
                -56.760635699187226,
                -22.25049526768594
              ],
              [
                -56.765485164437365,
                -22.25338425138539
              ],
              [
                -56.76724465956664,
                -22.25443174245715
              ],
              [
                -56.771240618018034,
                -22.257489766863685
              ],
              [
                -56.774620084946264,
                -22.258848253062958
              ],
              [
                -56.77756405631652,
                -22.257640266567023
              ],
              [
                -56.78128751984819,
                -22.256302786001125
              ],
              [
                -56.78208254554363,
                -22.255186293324954
              ],
              [
                -56.78423053501658,
                -22.252170324408635
              ],
              [
                -56.782227071868704,
                -22.24778182987571
              ],
              [
                -56.78672502048995,
                -22.247699817527543
              ],
              [
                -56.78461804296773,
                -22.24170435511254
              ],
              [
                -56.793565523149134,
                -22.2421913760499
              ],
              [
                -56.79705796958976,
                -22.245918385584886
              ],
              [
                -56.80080242740283,
                -22.250433381263253
              ],
              [
                -56.80208138141638,
                -22.254299869261754
              ],
              [
                -56.802214398462205,
                -22.25756436063672
              ],
              [
                -56.80165987327256,
                -22.262703833469015
              ],
              [
                -56.802535855742626,
                -22.266646321930423
              ],
              [
                -56.80438931860087,
                -22.270031813406575
              ],
              [
                -56.80283330295057,
                -22.276328775128505
              ],
              [
                -56.80200483421306,
                -22.279681271139125
              ],
              [
                -56.804822258977104,
                -22.284016281500175
              ],
              [
                -56.80905724332664,
                -22.28584776386812
              ],
              [
                -56.816007208483676,
                -22.287863769310256
              ],
              [
                -56.823173091926,
                -22.296975287489076
              ],
              [
                -56.829665072658706,
                -22.299680803157926
              ],
              [
                -56.83544849771558,
                -22.300902807568797
              ],
              [
                -56.8411529717515,
                -22.302006824128203
              ],
              [
                -56.845820925495296,
                -22.29726482808623
              ],
              [
                -56.849448955001066,
                -22.293150842591256
              ],
              [
                -56.85338940858947,
                -22.2895203854651
              ],
              [
                -56.86064585769822,
                -22.289645392295924
              ],
              [
                -56.86488483091567,
                -22.28637843089666
              ],
              [
                -56.86866034533423,
                -22.282018473696755
              ],
              [
                -56.876955784556216,
                -22.277772979589308
              ],
              [
                -56.877525804327675,
                -22.27381100477525
              ],
              [
                -56.87650982751379,
                -22.270763025800676
              ],
              [
                -56.87515983821967,
                -22.263860038764392
              ],
              [
                -56.875297348182265,
                -22.26172255241675
              ],
              [
                -56.87539386910274,
                -22.26021305081935
              ],
              [
                -56.876830356710386,
                -22.25631907155817
              ],
              [
                -56.877682862580684,
                -22.252888076866107
              ],
              [
                -56.88461485096034,
                -22.24650864957963
              ],
              [
                -56.89911225244185,
                -22.253514144344216
              ],
              [
                -56.899496693031374,
                -22.256165146635926
              ],
              [
                -56.899748212908925,
                -22.2579001205916
              ],
              [
                -56.90020070241147,
                -22.26315409832915
              ],
              [
                -56.905471129783606,
                -22.266879103656674
              ],
              [
                -56.91039211480593,
                -22.26025314674507
              ],
              [
                -56.91658958595586,
                -22.258819164425102
              ],
              [
                -56.915556097369176,
                -22.25655069575839
              ],
              [
                -56.91514810645544,
                -22.25565468047108
              ],
              [
                -56.92063458132987,
                -22.25560469920893
              ],
              [
                -56.92503507060108,
                -22.25122323706113
              ],
              [
                -56.92968599707461,
                -22.25444474510366
              ],
              [
                -56.92972751877018,
                -22.254450737216466
              ],
              [
                -56.93603195951749,
                -22.255369228315764
              ],
              [
                -56.9418474014217,
                -22.256334283695196
              ],
              [
                -56.945548393151846,
                -22.256636290534946
              ],
              [
                -56.952171342446306,
                -22.250874826139903
              ],
              [
                -56.95388938508302,
                -22.247273844761413
              ],
              [
                -56.953929866120376,
                -22.247189329175484
              ],
              [
                -56.95739288722394,
                -22.239170385255576
              ],
              [
                -56.96364183395114,
                -22.239934892691032
              ],
              [
                -56.96915830498323,
                -22.24034091698986
              ],
              [
                -56.970514282847205,
                -22.24221540502631
              ],
              [
                -56.97191174421435,
                -22.244147902001686
              ],
              [
                -56.97572670924578,
                -22.243893928075497
              ],
              [
                -56.98371868951224,
                -22.243479419069832
              ],
              [
                -56.98722966226384,
                -22.24034443785625
              ],
              [
                -56.99077813417389,
                -22.237097972136404
              ],
              [
                -56.99054867025275,
                -22.235874467514932
              ],
              [
                -56.98899819736594,
                -22.227594529393993
              ],
              [
                -56.99638265593318,
                -22.222805546140684
              ],
              [
                -56.998922139931445,
                -22.22545053437601
              ],
              [
                -57.00124660751326,
                -22.22806152504013
              ],
              [
                -57.00631757099962,
                -22.2329615358106
              ],
              [
                -57.00894453479377,
                -22.23550002769575
              ],
              [
                -57.01458048081278,
                -22.23272505407192
              ],
              [
                -57.01679900280036,
                -22.231632563848198
              ],
              [
                -57.017554957950644,
                -22.232639587887096
              ],
              [
                -57.01965397337585,
                -22.23543607521935
              ],
              [
                -57.03191437465826,
                -22.23369509334647
              ],
              [
                -57.04268279022391,
                -22.24056910879064
              ],
              [
                -57.047799745165776,
                -22.23958715948674
              ],
              [
                -57.04903524350167,
                -22.23209366458153
              ],
              [
                -57.049264247086484,
                -22.230704174976765
              ],
              [
                -57.05583520688251,
                -22.22980270005729
              ],
              [
                -57.058804717560214,
                -22.229585719301127
              ],
              [
                -57.05852467510648,
                -22.234548681760952
              ],
              [
                -57.058454677666184,
                -22.235607175987504
              ],
              [
                -57.05812466648009,
                -22.240587674232017
              ],
              [
                -57.06094715881827,
                -22.241542684080432
              ],
              [
                -57.06750109921462,
                -22.243756194678934
              ],
              [
                -57.07143107436249,
                -22.244627206364648
              ],
              [
                -57.07667653764325,
                -22.24494622112021
              ],
              [
                -57.08008449046149,
                -22.250104201780974
              ],
              [
                -57.08419047801103,
                -22.249195722995783
              ],
              [
                -57.08646897235342,
                -22.244394244379325
              ],
              [
                -57.08764046455959,
                -22.241924737404926
              ],
              [
                -57.088827928566545,
                -22.243262261736024
              ],
              [
                -57.0911629260206,
                -22.24589122442661
              ],
              [
                -57.09353837916423,
                -22.248529723217953
              ],
              [
                -57.10143532895189,
                -22.24764877050512
              ],
              [
                -57.11117630265254,
                -22.240181809738534
              ],
              [
                -57.10956984567144,
                -22.23554984708141
              ],
              [
                -57.108653337538456,
                -22.233749324166514
              ],
              [
                -57.10688688933675,
                -22.230278849115976
              ],
              [
                -57.10451539229536,
                -22.226519851094977
              ],
              [
                -57.11835479466411,
                -22.23002337274811
              ],
              [
                -57.12368723711428,
                -22.22825741775781
              ],
              [
                -57.13482117606182,
                -22.225210428657068
              ],
              [
                -57.140139147807446,
                -22.228799939714087
              ],
              [
                -57.14137962370612,
                -22.229532457245032
              ],
              [
                -57.14710059450932,
                -22.23291046931175
              ],
              [
                -57.15319057400205,
                -22.228676011999724
              ],
              [
                -57.15527153754856,
                -22.22796898703844
              ],
              [
                -57.15679602915652,
                -22.227451510658387
              ],
              [
                -57.157096021158466,
                -22.227713500745715
              ],
              [
                -57.160798511145096,
                -22.230949988816178
              ],
              [
                -57.16555598225327,
                -22.229256520738343
              ],
              [
                -57.16908343922834,
                -22.231466017681942
              ],
              [
                -57.17593037364922,
                -22.231547551386047
              ],
              [
                -57.18202581641067,
                -22.234800059100376
              ],
              [
                -57.18291236780654,
                -22.229025583212707
              ],
              [
                -57.18537585602975,
                -22.226900594210285
              ],
              [
                -57.18801230329719,
                -22.224178096756102
              ],
              [
                -57.18898634017077,
                -22.22324511599518
              ],
              [
                -57.19053332520802,
                -22.22176263490637
              ],
              [
                -57.18998332546469,
                -22.215942631564225
              ],
              [
                -57.197658286042724,
                -22.211775676093257
              ],
              [
                -57.20158078720496,
                -22.211599181265804
              ],
              [
                -57.203071756092534,
                -22.214853672191328
              ],
              [
                -57.20673919864347,
                -22.218280170530807
              ],
              [
                -57.20845170039042,
                -22.214684216019453
              ],
              [
                -57.2139151685903,
                -22.218442188021246
              ],
              [
                -57.213098170091875,
                -22.22222720278025
              ],
              [
                -57.21038065841832,
                -22.22547368523401
              ],
              [
                -57.214552657418245,
                -22.227047680382192
              ],
              [
                -57.215317127380224,
                -22.231603166656306
              ],
              [
                -57.21657758602308,
                -22.239117143672672
              ],
              [
                -57.228784964355235,
                -22.244309669607333
              ],
              [
                -57.236859921074526,
                -22.248023670202706
              ],
              [
                -57.24051487498839,
                -22.24921469499049
              ],
              [
                -57.24381586355783,
                -22.24683019153358
              ],
              [
                -57.24907784881181,
                -22.242042226372885
              ],
              [
                -57.254216334321754,
                -22.23875875780907
              ],
              [
                -57.25806577547661,
                -22.237107780671618
              ],
              [
                -57.26557326861967,
                -22.236627801397276
              ],
              [
                -57.269271710501705,
                -22.235866292816336
              ],
              [
                -57.26973974656918,
                -22.233109337485374
              ],
              [
                -57.27017725618313,
                -22.230529843467018
              ],
              [
                -57.2738907074803,
                -22.23151784911649
              ],
              [
                -57.27548167918464,
                -22.2319408278238
              ],
              [
                -57.27631969255674,
                -22.230586368019026
              ],
              [
                -57.27802120128097,
                -22.22783636462788
              ],
              [
                -57.28259315370725,
                -22.22345338588787
              ],
              [
                -57.28571665216713,
                -22.22169590176547
              ],
              [
                -57.2904706186987,
                -22.222620412968247
              ],
              [
                -57.291758621115164,
                -22.218277417539266
              ],
              [
                -57.29710458445956,
                -22.22114543718638
              ],
              [
                -57.297247086213886,
                -22.22122244338842
              ],
              [
                -57.30147656321894,
                -22.22324445426371
              ],
              [
                -57.302636026143546,
                -22.226012432797535
              ],
              [
                -57.30351851939316,
                -22.228116937645936
              ],
              [
                -57.30733849788361,
                -22.227785964024843
              ],
              [
                -57.31257892663475,
                -22.231545453394272
              ],
              [
                -57.31646689029511,
                -22.23562846234911
              ],
              [
                -57.3148293862965,
                -22.24048644896616
              ],
              [
                -57.31868688073333,
                -22.24333593492499
              ],
              [
                -57.32247385822156,
                -22.242259446678663
              ],
              [
                -57.32528234918684,
                -22.23905896632665
              ],
              [
                -57.32814382085794,
                -22.236380491413335
              ],
              [
                -57.32954933214018,
                -22.234831985069235
              ],
              [
                -57.33096680138826,
                -22.233270990804776
              ],
              [
                -57.336961758929085,
                -22.231949016470693
              ],
              [
                -57.33788577724939,
                -22.23261153024651
              ],
              [
                -57.33985972970464,
                -22.234027512902465
              ],
              [
                -57.34059073571273,
                -22.231891018335947
              ],
              [
                -57.341705228303645,
                -22.22863204272415
              ],
              [
                -57.34513121463619,
                -22.2306815579501
              ],
              [
                -57.34617221731687,
                -22.23130455023399
              ],
              [
                -57.34971320532462,
                -22.229461068733894
              ],
              [
                -57.35656816043382,
                -22.225892617608455
              ],
              [
                -57.357011155424544,
                -22.2275171006509
              ],
              [
                -57.357523654008055,
                -22.22939458207941
              ],
              [
                -57.36084759320292,
                -22.232476083566286
              ],
              [
                -57.36392310145832,
                -22.22683212315473
              ],
              [
                -57.36681109304683,
                -22.2287131252159
              ],
              [
                -57.37221353773404,
                -22.231129128599015
              ],
              [
                -57.37228754954292,
                -22.224850136627186
              ],
              [
                -57.38105048510519,
                -22.22579265569931
              ],
              [
                -57.37847901432926,
                -22.21745767602339
              ],
              [
                -57.3783820411481,
                -22.21714321075295
              ],
              [
                -57.37703204725453,
                -22.21336170593597
              ],
              [
                -57.38060004507384,
                -22.210176209960462
              ],
              [
                -57.386496524632804,
                -22.207693274143164
              ],
              [
                -57.385989050344996,
                -22.20410625139249
              ],
              [
                -57.38997597268869,
                -22.206223758197044
              ],
              [
                -57.39195547001734,
                -22.207130779558216
              ],
              [
                -57.394020464623324,
                -22.208077775062684
              ],
              [
                -57.3957149632923,
                -22.20563479059969
              ],
              [
                -57.39597397649364,
                -22.204841296059705
              ],
              [
                -57.39686946866156,
                -22.202093787036077
              ],
              [
                -57.40637292045472,
                -22.19867784789805
              ],
              [
                -57.416498825229525,
                -22.19630085931918
              ],
              [
                -57.41934879543398,
                -22.198903372870678
              ],
              [
                -57.41978530902394,
                -22.199301861955867
              ],
              [
                -57.424349281958904,
                -22.203186372783282
              ],
              [
                -57.4245592823418,
                -22.19757939900471
              ],
              [
                -57.42456780212257,
                -22.197353396779768
              ],
              [
                -57.42506729040772,
                -22.19745041906488
              ],
              [
                -57.430049764356774,
                -22.198417418888077
              ],
              [
                -57.43332123593615,
                -22.199077924224547
              ],
              [
                -57.43641519486757,
                -22.19645994883103
              ],
              [
                -57.43926071645511,
                -22.19384544462631
              ],
              [
                -57.44183170595196,
                -22.191482982704695
              ],
              [
                -57.44540166189387,
                -22.188270501804794
              ],
              [
                -57.44806517729494,
                -22.187115518186776
              ],
              [
                -57.455243087743035,
                -22.189104024692313
              ],
              [
                -57.45823008256762,
                -22.187250544422536
              ],
              [
                -57.4627065493124,
                -22.190275031823578
              ],
              [
                -57.464384056852104,
                -22.19140852047401
              ],
              [
                -57.466602523370156,
                -22.18968152531394
              ],
              [
                -57.47091448488093,
                -22.186326062265113
              ],
              [
                -57.47527896905621,
                -22.1883605915818
              ],
              [
                -57.47601447385017,
                -22.18870358081475
              ],
              [
                -57.48008743019251,
                -22.19095110049487
              ],
              [
                -57.4834328841187,
                -22.190043590252454
              ],
              [
                -57.487755880450244,
                -22.189416593228575
              ],
              [
                -57.48927786793378,
                -22.185455620198248
              ],
              [
                -57.49014888355111,
                -22.183174632529102
              ],
              [
                -57.49076088739061,
                -22.181571672788895
              ],
              [
                -57.49247886135041,
                -22.181981663960514
              ],
              [
                -57.49475984103871,
                -22.182525647501087
              ],
              [
                -57.49857584142514,
                -22.18334217408016
              ],
              [
                -57.505578274503364,
                -22.184684200177628
              ],
              [
                -57.51054623920098,
                -22.179022234835692
              ],
              [
                -57.51567973611351,
                -22.17273323509226
              ],
              [
                -57.51740422783508,
                -22.17157925644467
              ],
              [
                -57.52134319921768,
                -22.16894178881129
              ],
              [
                -57.52427920402444,
                -22.17124078666975
              ],
              [
                -57.52531817849326,
                -22.17274329205615
              ],
              [
                -57.52680514155578,
                -22.174894287746053
              ],
              [
                -57.53971506949745,
                -22.179364313545626
              ],
              [
                -57.54627452034164,
                -22.181311823238165
              ],
              [
                -57.55017547326494,
                -22.1804908344962
              ],
              [
                -57.55762945279344,
                -22.17821686297279
              ],
              [
                -57.562446408045126,
                -22.177273859579692
              ],
              [
                -57.567586860118396,
                -22.175796395986673
              ],
              [
                -57.571203878445736,
                -22.1748434347639
              ],
              [
                -57.57625383878986,
                -22.175344943366824
              ],
              [
                -57.579391820611555,
                -22.173506953514035
              ],
              [
                -57.58556031400756,
                -22.16658798712974
              ],
              [
                -57.58745327002353,
                -22.163708511694857
              ],
              [
                -57.586715322556046,
                -22.158963013936972
              ],
              [
                -57.585498350488656,
                -22.15389353270906
              ],
              [
                -57.58518733936854,
                -22.15259854297203
              ],
              [
                -57.584604838547854,
                -22.14901404190084
              ],
              [
                -57.585108876926085,
                -22.14071308585875
              ],
              [
                -57.58950684129751,
                -22.1426605905819
              ],
              [
                -57.59289930478504,
                -22.142883086755234
              ],
              [
                -57.59227432846563,
                -22.136267622530866
              ],
              [
                -57.59595832670317,
                -22.137112124590313
              ],
              [
                -57.59742980764922,
                -22.13771011635099
              ],
              [
                -57.60034729674916,
                -22.13889612582451
              ],
              [
                -57.598651784362865,
                -22.135851123399608
              ],
              [
                -57.596700814381954,
                -22.132347152668153
              ],
              [
                -57.602184280633416,
                -22.130479648439458
              ],
              [
                -57.604270286318055,
                -22.129769173482295
              ],
              [
                -57.6047658179084,
                -22.12655367466287
              ],
              [
                -57.60533380974233,
                -22.122870713616813
              ],
              [
                -57.607027779519704,
                -22.12311370725869
              ],
              [
                -57.611556266847394,
                -22.123763190669376
              ],
              [
                -57.60944378296642,
                -22.119439703119845
              ],
              [
                -57.60761180708689,
                -22.115689210588844
              ],
              [
                -57.61220831186302,
                -22.106632282186997
              ],
              [
                -57.608888381949576,
                -22.09583780110655
              ],
              [
                -57.61241585840255,
                -22.094477800909644
              ],
              [
                -57.61717333246144,
                -22.096413333797926
              ],
              [
                -57.62385226778972,
                -22.099673321494965
              ],
              [
                -57.633206161881056,
                -22.103845317787787
              ],
              [
                -57.63725611816321,
                -22.105648363858105
              ],
              [
                -57.650595570936254,
                -22.105959382678776
              ],
              [
                -57.65200604453587,
                -22.105954875224306
              ],
              [
                -57.66285995320788,
                -22.105920405204778
              ],
              [
                -57.666885462477765,
                -22.103720946368366
              ],
              [
                -57.6693239331318,
                -22.102388954580853
              ],
              [
                -57.67494591660974,
                -22.101309480189617
              ],
              [
                -57.68827985488334,
                -22.087999048765905
              ],
              [
                -57.696055285208985,
                -22.09146557934603
              ],
              [
                -57.70587973943062,
                -22.090457116215916
              ],
              [
                -57.7028177182671,
                -22.09899356634681
              ],
              [
                -57.70483817858362,
                -22.101212558554412
              ],
              [
                -57.70532920031854,
                -22.101750574366285
              ],
              [
                -57.70951515368406,
                -22.103567580856062
              ],
              [
                -57.71332263236273,
                -22.104091054376752
              ],
              [
                -57.71537611290362,
                -22.108620570117925
              ],
              [
                -57.719133056843006,
                -22.10889507529699
              ],
              [
                -57.72086806463436,
                -22.10744859861963
              ],
              [
                -57.725869032735154,
                -22.103280630367355
              ],
              [
                -57.729301024937286,
                -22.09974914198194
              ],
              [
                -57.73364848192597,
                -22.100841142376566
              ],
              [
                -57.736442466634365,
                -22.102374131333153
              ],
              [
                -57.73864090672242,
                -22.115404599699684
              ],
              [
                -57.73613592476992,
                -22.118007105114526
              ],
              [
                -57.73393392368631,
                -22.119560604940325
              ],
              [
                -57.73371943655415,
                -22.119712103753898
              ],
              [
                -57.73438643302187,
                -22.12324309233259
              ],
              [
                -57.73565140399212,
                -22.128260080971774
              ],
              [
                -57.73677487825962,
                -22.132101531009347
              ],
              [
                -57.74174230582863,
                -22.135964546356835
              ],
              [
                -57.74552628907799,
                -22.138265565011988
              ],
              [
                -57.75439176563899,
                -22.13432409545758
              ],
              [
                -57.76131568726599,
                -22.134931613768664
              ],
              [
                -57.76611869523181,
                -22.132249159009216
              ],
              [
                -57.77737661259839,
                -22.12596170882102
              ],
              [
                -57.787638516827506,
                -22.130398715368415
              ],
              [
                -57.789965982436634,
                -22.136299180590726
              ],
              [
                -57.790538009250156,
                -22.137748174701304
              ],
              [
                -57.793936466182764,
                -22.143255193671088
              ],
              [
                -57.79664491706286,
                -22.14758815325782
              ],
              [
                -57.80226737348991,
                -22.150052694342904
              ],
              [
                -57.80690486842011,
                -22.144188222428806
              ],
              [
                -57.80930236155503,
                -22.140665221652533
              ],
              [
                -57.81733579416879,
                -22.137419769135686
              ],
              [
                -57.81875181969295,
                -22.13339578782775
              ],
              [
                -57.82017484073265,
                -22.12895030991852
              ],
              [
                -57.82036030889713,
                -22.128304315624035
              ],
              [
                -57.82221931558901,
                -22.121822855765284
              ],
              [
                -57.827746296268266,
                -22.119550854983977
              ],
              [
                -57.8394902223039,
                -22.122754878366774
              ],
              [
                -57.84384215146,
                -22.124618373313695
              ],
              [
                -57.850585644057865,
                -22.126391920265466
              ],
              [
                -57.85630357642219,
                -22.12871842911516
              ],
              [
                -57.857430044234434,
                -22.12917692425278
              ],
              [
                -57.861052513901726,
                -22.13032493419155
              ],
              [
                -57.85875303784737,
                -22.135798414949765
              ],
              [
                -57.86184699949176,
                -22.13466742761021
              ],
              [
                -57.8679130032971,
                -22.130062946164315
              ],
              [
                -57.8834218602022,
                -22.130894495468656
              ],
              [
                -57.886045383791725,
                -22.126046526705412
              ],
              [
                -57.88772138617041,
                -22.122948526191767
              ],
              [
                -57.89519184549478,
                -22.12404704970463
              ],
              [
                -57.90319576999853,
                -22.125223051690732
              ],
              [
                -57.924855648415736,
                -22.121149128793018
              ],
              [
                -57.9229341507355,
                -22.11264868122668
              ],
              [
                -57.92273568827081,
                -22.112051155529457
              ],
              [
                -57.92138922023737,
                -22.108001689356115
              ],
              [
                -57.92782318388883,
                -22.105758718388945
              ],
              [
                -57.93207915875918,
                -22.104116736445974
              ],
              [
                -57.93221864603273,
                -22.10406323206949
              ],
              [
                -57.93229062323112,
                -22.103881204893295
              ],
              [
                -57.93412816529719,
                -22.099233266927293
              ],
              [
                -57.93890317444042,
                -22.085269333723314
              ],
              [
                -57.943361640612416,
                -22.084533811476494
              ],
              [
                -57.947180121943994,
                -22.08570884695575
              ],
              [
                -57.94836859806345,
                -22.08597934090492
              ],
              [
                -57.950901058142406,
                -22.08655534519082
              ],
              [
                -57.95750654880814,
                -22.081583382632708
              ],
              [
                -57.95752102875107,
                -22.08157289871345
              ],
              [
                -57.96001053612317,
                -22.079720909752616
              ],
              [
                -57.96062354804667,
                -22.08053390508463
              ],
              [
                -57.96447849654876,
                -22.08564489189478
              ],
              [
                -57.96767095717519,
                -22.08920890360399
              ],
              [
                -57.975399895980566,
                -22.09028540401799
              ],
              [
                -57.97916986742698,
                -22.090711927784962
              ],
              [
                -57.984929855497306,
                -22.08715045691222
              ],
              [
                -57.991276295962074,
                -22.09025193430428
              ],
              [
                -57.99017730375075,
                -22.08472495869799
              ],
              [
                -57.98871082273806,
                -22.07818100121535
              ],
              [
                -57.98570688299919,
                -22.07349698574805
              ],
              [
                -57.98495241788226,
                -22.071100021103344
              ],
              [
                -57.98376292676745,
                -22.06732100254895
              ],
              [
                -57.98460895098631,
                -22.061703038527387
              ],
              [
                -57.98738691620221,
                -22.058840040774548
              ],
              [
                -57.991228903673345,
                -22.05535656046232
              ],
              [
                -57.99128642137592,
                -22.055334053720635
              ],
              [
                -58.00520082257016,
                -22.049857629302323
              ],
              [
                -58.00591985376769,
                -22.046899624225794
              ],
              [
                -58.00673885089975,
                -22.04351016687327
              ],
              [
                -58.00602535168596,
                -22.03998117804425
              ],
              [
                -58.00326941980695,
                -22.03163570030476
              ],
              [
                -58.001100439663674,
                -22.028796203561956
              ],
              [
                -57.99860793817501,
                -22.027169186838396
              ],
              [
                -57.992876512862345,
                -22.023719178634597
              ],
              [
                -57.988548525638656,
                -22.021249200760174
              ],
              [
                -57.985318083837186,
                -22.01940516837982
              ],
              [
                -57.96758720905381,
                -22.00916565376781
              ],
              [
                -57.962804773151035,
                -21.999841179810666
              ],
              [
                -57.96272030756288,
                -21.992294218154793
              ],
              [
                -57.96267730164613,
                -21.988825215333932
              ],
              [
                -57.962608833568375,
                -21.983275744222112
              ],
              [
                -57.962348353848455,
                -21.977429755466744
              ],
              [
                -57.9592904262113,
                -21.97332873904951
              ],
              [
                -57.95427746348139,
                -21.967065746433555
              ],
              [
                -57.95302599352779,
                -21.965502256530204
              ],
              [
                -57.945635095760906,
                -21.946952293491012
              ],
              [
                -57.939964669573115,
                -21.931925858508166
              ],
              [
                -57.93664774121278,
                -21.921129349250396
              ],
              [
                -57.937832244684024,
                -21.910423897110245
              ],
              [
                -57.94213426069882,
                -21.907836431340783
              ],
              [
                -57.94686721281417,
                -21.904989951795926
              ],
              [
                -57.94729524809981,
                -21.900597978554465
              ],
              [
                -57.94314078594285,
                -21.89696195019544
              ],
              [
                -57.93857781076955,
                -21.8932494567889
              ],
              [
                -57.930211876789315,
                -21.885987481889895
              ],
              [
                -57.92665690363908,
                -21.88617597192018
              ],
              [
                -57.91817097018925,
                -21.886626435347363
              ],
              [
                -57.912192554397535,
                -21.879092448002996
              ],
              [
                -57.916329538619806,
                -21.87318895759594
              ],
              [
                -57.92281602237565,
                -21.86999051479797
              ],
              [
                -57.92795846968256,
                -21.867435005597887
              ],
              [
                -57.93813441883174,
                -21.862378071667546
              ],
              [
                -57.94752737593865,
                -21.857386612018125
              ],
              [
                -57.952132369343325,
                -21.854961649667036
              ],
              [
                -57.958199341247905,
                -21.851944144922246
              ],
              [
                -57.969110267641604,
                -21.846324726161672
              ],
              [
                -57.968119270342925,
                -21.8421847485871
              ],
              [
                -57.94988842151356,
                -21.83683221004553
              ],
              [
                -57.938014021902944,
                -21.830043157521057
              ],
              [
                -57.9362245348712,
                -21.828914163532584
              ],
              [
                -57.932106583617234,
                -21.826316182959875
              ],
              [
                -57.926450172128504,
                -21.81616520934755
              ],
              [
                -57.92256172841368,
                -21.808618186816844
              ],
              [
                -57.91487932515799,
                -21.79466623434133
              ],
              [
                -57.91328432432759,
                -21.79148125876791
              ],
              [
                -57.90909895034893,
                -21.768883802000797
              ],
              [
                -57.91487789488487,
                -21.76784334578855
              ],
              [
                -57.9214738365552,
                -21.766655858656158
              ],
              [
                -57.93330079987829,
                -21.760178893582175
              ],
              [
                -57.937390797568355,
                -21.757775912495568
              ],
              [
                -57.94230575878474,
                -21.752904955165636
              ],
              [
                -57.944439271848395,
                -21.747634501320732
              ],
              [
                -57.94547179701933,
                -21.74241049982909
              ],
              [
                -57.943669279825045,
                -21.739691494078585
              ],
              [
                -57.93981986513878,
                -21.73422851989222
              ],
              [
                -57.935749895782365,
                -21.731276509726307
              ],
              [
                -57.920504036980724,
                -21.720441508175806
              ],
              [
                -57.9142010976202,
                -21.716601479893757
              ],
              [
                -57.91346508111593,
                -21.71615349504265
              ],
              [
                -57.908632638773135,
                -21.713209979465425
              ],
              [
                -57.89585222481697,
                -21.703708501428064
              ],
              [
                -57.88892632752524,
                -21.69795300256159
              ],
              [
                -57.88344333852969,
                -21.692790490313275
              ],
              [
                -57.881898412705475,
                -21.688112007931768
              ],
              [
                -57.883165371090385,
                -21.68486153071544
              ],
              [
                -57.88477591460781,
                -21.681222048875927
              ],
              [
                -57.89047137531366,
                -21.677243560079575
              ],
              [
                -57.89712934068348,
                -21.674914589856403
              ],
              [
                -57.903811279174846,
                -21.67085261145067
              ],
              [
                -57.9078272758938,
                -21.670220647244847
              ],
              [
                -57.90926077195362,
                -21.669518156105884
              ],
              [
                -57.92510866126446,
                -21.661747223964166
              ],
              [
                -57.932884653877174,
                -21.65096026128064
              ],
              [
                -57.935855697853775,
                -21.64193982055738
              ],
              [
                -57.93971268574123,
                -21.629935857525734
              ],
              [
                -57.93938569083171,
                -21.624971892949873
              ],
              [
                -57.93909021453875,
                -21.620885899565746
              ],
              [
                -57.93507178543856,
                -21.61497289249526
              ],
              [
                -57.93204831632652,
                -21.61084193466167
              ],
              [
                -57.92782784759055,
                -21.606858400569067
              ],
              [
                -57.927154832294804,
                -21.606232924522562
              ],
              [
                -57.92483588239418,
                -21.604077911363476
              ],
              [
                -57.91272751265177,
                -21.5962514121833
              ],
              [
                -57.91240602289966,
                -21.590389910651503
              ],
              [
                -57.91393002402936,
                -21.58616946148371
              ],
              [
                -57.92066198178707,
                -21.58082347646588
              ],
              [
                -57.93649788630619,
                -21.57355906049757
              ],
              [
                -57.944273370784764,
                -21.569992604840635
              ],
              [
                -57.94880533105339,
                -21.56799262976006
              ],
              [
                -57.95740879815424,
                -21.56357217454187
              ],
              [
                -57.9610932813573,
                -21.55592267755429
              ],
              [
                -57.96232930770465,
                -21.553280217224383
              ],
              [
                -57.96497881287812,
                -21.547535721650515
              ],
              [
                -57.96559280317679,
                -21.541319742238596
              ],
              [
                -57.965886819084886,
                -21.53746726196792
              ],
              [
                -57.9660668715697,
                -21.530879812433007
              ],
              [
                -57.96622188056568,
                -21.52502882437583
              ],
              [
                -57.964517877145816,
                -21.521315815367107
              ],
              [
                -57.96118394339719,
                -21.514004336212526
              ],
              [
                -57.95942043970982,
                -21.510472848423824
              ],
              [
                -57.95659800234144,
                -21.507684826836616
              ],
              [
                -57.95080555779409,
                -21.50204785741233
              ],
              [
                -57.95078255999967,
                -21.502032854390087
              ],
              [
                -57.9462820752431,
                -21.49909486604394
              ],
              [
                -57.94332812217826,
                -21.49618435546738
              ],
              [
                -57.93450021592351,
                -21.48433437626836
              ],
              [
                -57.93072627336794,
                -21.477764867346277
              ],
              [
                -57.92563731900911,
                -21.468254902131612
              ],
              [
                -57.924244333645206,
                -21.464498919307132
              ],
              [
                -57.92496134093886,
                -21.4591789243227
              ],
              [
                -57.925633372326324,
                -21.454193936080955
              ],
              [
                -57.92597139445098,
                -21.45073743568823
              ],
              [
                -57.908270615455045,
                -21.418777021180308
              ],
              [
                -57.90727763140797,
                -21.41698400607838
              ],
              [
                -57.907024626438776,
                -21.415761522380745
              ],
              [
                -57.903746677635155,
                -21.39989754827802
              ],
              [
                -57.89902674990628,
                -21.39453556350503
              ],
              [
                -57.8961942789874,
                -21.3913415843675
              ],
              [
                -57.88620589216345,
                -21.38032808913077
              ],
              [
                -57.880963941581804,
                -21.375046070503803
              ],
              [
                -57.88083292791844,
                -21.374913604539817
              ],
              [
                -57.872043010383365,
                -21.364396599448888
              ],
              [
                -57.86244164287829,
                -21.353123096417136
              ],
              [
                -57.858991188952295,
                -21.34797712897588
              ],
              [
                -57.854037238936215,
                -21.338328130018862
              ],
              [
                -57.85240829185449,
                -21.331519142873724
              ],
              [
                -57.8506103128322,
                -21.32246667777045
              ],
              [
                -57.8528498201331,
                -21.31792372204575
              ],
              [
                -57.86556175051014,
                -21.31330527215511
              ],
              [
                -57.86988321868128,
                -21.312256266133254
              ],
              [
                -57.875573199633166,
                -21.31069481809435
              ],
              [
                -57.87851918104264,
                -21.309886326982316
              ],
              [
                -57.88607213745182,
                -21.308113348450323
              ],
              [
                -57.89666958189751,
                -21.30489439625575
              ],
              [
                -57.91011999399986,
                -21.295051462218495
              ],
              [
                -57.91863597383221,
                -21.284032493778657
              ],
              [
                -57.92051148556277,
                -21.279483029919287
              ],
              [
                -57.918941005502674,
                -21.27649254910258
              ],
              [
                -57.909110080955195,
                -21.268971038879894
              ],
              [
                -57.89430223876855,
                -21.258754535321508
              ],
              [
                -57.88652728873063,
                -21.253474512994565
              ],
              [
                -57.882525851783505,
                -21.250744014789344
              ],
              [
                -57.87715689715718,
                -21.24708050249562
              ],
              [
                -57.87108595762139,
                -21.24272101638341
              ],
              [
                -57.86477799831158,
                -21.23817403718109
              ],
              [
                -57.857668570678115,
                -21.233234528833695
              ],
              [
                -57.84955115227401,
                -21.219336026684402
              ],
              [
                -57.8483537162442,
                -21.209097085626933
              ],
              [
                -57.84608676965545,
                -21.19220662480734
              ],
              [
                -57.84781427878091,
                -21.18636816693098
              ],
              [
                -57.85134977865803,
                -21.174926189636672
              ],
              [
                -57.85426681615753,
                -21.16560225625771
              ],
              [
                -57.85497630115426,
                -21.163130738829047
              ],
              [
                -57.85833533104038,
                -21.151435802862025
              ],
              [
                -57.861808841566884,
                -21.13933636862893
              ],
              [
                -57.86022688330253,
                -21.128983910877327
              ],
              [
                -57.855913414203414,
                -21.120582918094055
              ],
              [
                -57.854257955417054,
                -21.11735989893551
              ],
              [
                -57.85198598849691,
                -21.11248043550707
              ],
              [
                -57.85063349731758,
                -21.109653946942196
              ],
              [
                -57.84879201764031,
                -21.106176943083643
              ],
              [
                -57.84481906127346,
                -21.098627970506975
              ],
              [
                -57.84732206790601,
                -21.093438466864942
              ],
              [
                -57.850944063976684,
                -21.088012990471423
              ],
              [
                -57.852600550909244,
                -21.08547801719397
              ],
              [
                -57.85424655458337,
                -21.08275051653892
              ],
              [
                -57.85915407719971,
                -21.07474058689211
              ],
              [
                -57.861412572465085,
                -21.071259083686485
              ],
              [
                -57.861671064239445,
                -21.070835592922695
              ],
              [
                -57.86463204867601,
                -21.065978138559437
              ],
              [
                -57.86634406559167,
                -21.051022678720646
              ],
              [
                -57.86706561459934,
                -21.041056226871213
              ],
              [
                -57.86445916200506,
                -21.038412225865663
              ],
              [
                -57.85846120332055,
                -21.03245572273034
              ],
              [
                -57.85358777074166,
                -21.027783227687106
              ],
              [
                -57.847668791547626,
                -21.022108230863132
              ],
              [
                -57.84451986111836,
                -21.017283226655774
              ],
              [
                -57.842960851236874,
                -21.014745249530588
              ],
              [
                -57.84237537440722,
                -21.01379476097726
              ],
              [
                -57.8423578586219,
                -21.013766270020778
              ],
              [
                -57.83952440433481,
                -21.00916224004025
              ],
              [
                -57.83714892338012,
                -21.005605271896236
              ],
              [
                -57.82694103777451,
                -20.990347279720012
              ],
              [
                -57.8165786727037,
                -20.97953629293657
              ],
              [
                -57.82055214832899,
                -20.971227860527947
              ],
              [
                -57.82410865452033,
                -20.963258371684024
              ],
              [
                -57.8234176919784,
                -20.960116400321468
              ],
              [
                -57.822432668867,
                -20.955678908495614
              ],
              [
                -57.81882826459981,
                -20.942132938855934
              ],
              [
                -57.823438769259674,
                -20.93576146977826
              ],
              [
                -57.82826371927638,
                -20.93407550130362
              ],
              [
                -57.83326418844154,
                -20.935478025787788
              ],
              [
                -57.83405018580068,
                -20.9361874797616
              ],
              [
                -57.8416920955137,
                -20.943084009523933
              ],
              [
                -57.84764207094281,
                -20.946933020556642
              ],
              [
                -57.85417450240669,
                -20.94764651210384
              ],
              [
                -57.86014646335353,
                -20.94640403462636
              ],
              [
                -57.863178458675904,
                -20.945739547176096
              ],
              [
                -57.866343428979405,
                -20.943963049934013
              ],
              [
                -57.86935841480354,
                -20.941168094713923
              ],
              [
                -57.871943926012385,
                -20.93879010175358
              ],
              [
                -57.87539390341371,
                -20.93211664212189
              ],
              [
                -57.87855339138428,
                -20.924963672241688
              ],
              [
                -57.88092193434997,
                -20.919603187814456
              ],
              [
                -57.8838054212137,
                -20.913869228239104
              ],
              [
                -57.89261686878509,
                -20.904453283371197
              ],
              [
                -57.899369366266214,
                -20.90187180983105
              ],
              [
                -57.9034718260024,
                -20.901369326083724
              ],
              [
                -57.90749326930378,
                -20.90201431016605
              ],
              [
                -57.90836826104361,
                -20.902159316344058
              ],
              [
                -57.91370575386047,
                -20.903043362632857
              ],
              [
                -57.91938121000375,
                -20.903191854470332
              ],
              [
                -57.923633181005464,
                -20.900309882311653
              ],
              [
                -57.92769865594197,
                -20.89751291829106
              ],
              [
                -57.92751720274746,
                -20.893578933177785
              ],
              [
                -57.92137272220709,
                -20.887206404188632
              ],
              [
                -57.900974413300496,
                -20.87624741940284
              ],
              [
                -57.89744593419519,
                -20.873447405221306
              ],
              [
                -57.88622556287688,
                -20.864519894573583
              ],
              [
                -57.881404581930624,
                -20.860951412103283
              ],
              [
                -57.88029510906986,
                -20.860129890536857
              ],
              [
                -57.86835970618596,
                -20.85127988751017
              ],
              [
                -57.86460924973649,
                -20.847842400879067
              ],
              [
                -57.861766795239824,
                -20.844133915949556
              ],
              [
                -57.858181837513186,
                -20.830008943540093
              ],
              [
                -57.858971352867975,
                -20.82636297346851
              ],
              [
                -57.861253877737845,
                -20.820581976276728
              ],
              [
                -57.86316935770879,
                -20.816635007666886
              ],
              [
                -57.87456982614072,
                -20.807193059787032
              ],
              [
                -57.8819193024591,
                -20.798851131798646
              ],
              [
                -57.889765757781504,
                -20.79188417459056
              ],
              [
                -57.89380475310963,
                -20.790458693765128
              ],
              [
                -57.90036917508469,
                -20.789831719872403
              ],
              [
                -57.90811516296918,
                -20.790172729248237
              ],
              [
                -57.921073044686544,
                -20.794849760594516
              ],
              [
                -57.9251040060495,
                -20.796052743726847
              ],
              [
                -57.93201693451275,
                -20.798115754678673
              ],
              [
                -57.93534543320053,
                -20.79882376237687
              ],
              [
                -57.93964590534917,
                -20.79957628888084
              ],
              [
                -57.943943388495306,
                -20.799754806344776
              ],
              [
                -57.947224355435,
                -20.799900807827875
              ],
              [
                -57.95754880828077,
                -20.795637351852047
              ],
              [
                -57.95909029129264,
                -20.7912378836157
              ],
              [
                -57.959873783848806,
                -20.788998864671232
              ],
              [
                -57.95392235980911,
                -20.77594842431024
              ],
              [
                -57.94654999050824,
                -20.761313433117337
              ],
              [
                -57.94463549973289,
                -20.75792045094951
              ],
              [
                -57.94200053833779,
                -20.753463960249498
              ],
              [
                -57.939773544338486,
                -20.751024473866224
              ],
              [
                -57.93267911843643,
                -20.74592644084646
              ],
              [
                -57.924545672245614,
                -20.744070450544193
              ],
              [
                -57.91550125727807,
                -20.743314931700848
              ],
              [
                -57.90821728764768,
                -20.744630901076782
              ],
              [
                -57.90610381322416,
                -20.745015374342557
              ],
              [
                -57.90499628281657,
                -20.745216380291115
              ],
              [
                -57.8951628482223,
                -20.746748853187505
              ],
              [
                -57.87459449181161,
                -20.74987278204218
              ],
              [
                -57.867267070720466,
                -20.74733675055475
              ],
              [
                -57.86325558193477,
                -20.745348251242355
              ],
              [
                -57.86247359123333,
                -20.741217755106856
              ],
              [
                -57.87052707750663,
                -20.731796318990817
              ],
              [
                -57.874187608695905,
                -20.72178488559428
              ],
              [
                -57.877137580895884,
                -20.713716924347946
              ],
              [
                -57.87739107759905,
                -20.71302391950539
              ],
              [
                -57.89173755592707,
                -20.69791249804912
              ],
              [
                -57.89758002678674,
                -20.691982064181335
              ],
              [
                -57.90342200365105,
                -20.686051604308552
              ],
              [
                -57.911363016491286,
                -20.670524645878444
              ],
              [
                -57.91481796648562,
                -20.667098697141743
              ],
              [
                -57.91925598406722,
                -20.66365970942041
              ],
              [
                -57.924705452635294,
                -20.662088253313325
              ],
              [
                -57.927848411334885,
                -20.663160260117554
              ],
              [
                -57.932727374382125,
                -20.66577126186651
              ],
              [
                -57.93449583413354,
                -20.66859375948534
              ],
              [
                -57.936892814934986,
                -20.672535232152033
              ],
              [
                -57.93709931926764,
                -20.672919223453594
              ],
              [
                -57.939806790320404,
                -20.677945704327644
              ],
              [
                -57.95064662645457,
                -20.697747665974898
              ],
              [
                -57.95398909644723,
                -20.703027190939753
              ],
              [
                -57.959526574505475,
                -20.703199673123926
              ],
              [
                -57.96750399763836,
                -20.702471198799895
              ],
              [
                -57.977400968445735,
                -20.70108272856139
              ],
              [
                -57.98459238683704,
                -20.701207766924878
              ],
              [
                -57.98680341779447,
                -20.695521288159895
              ],
              [
                -57.98819744464634,
                -20.69055380045066
              ],
              [
                -57.98691745233779,
                -20.680817867034907
              ],
              [
                -57.97887954370515,
                -20.667753864633678
              ],
              [
                -57.97730455957608,
                -20.664960893478597
              ],
              [
                -57.97647106809285,
                -20.66348239004505
              ],
              [
                -57.970426171583355,
                -20.64213894626012
              ],
              [
                -57.974139665487236,
                -20.638542968869384
              ],
              [
                -57.987164085080984,
                -20.63472801336185
              ],
              [
                -57.990667576608196,
                -20.633702040480305
              ],
              [
                -57.994374088676736,
                -20.630367530160537
              ],
              [
                -57.9988295642295,
                -20.62633108451877
              ],
              [
                -58.005795022773235,
                -20.61952462899323
              ],
              [
                -58.00942900353159,
                -20.615541158683165
              ],
              [
                -58.012220992688576,
                -20.612473139677363
              ],
              [
                -58.01196549721788,
                -20.60899215089312
              ],
              [
                -58.0109095576836,
                -20.60295720049224
              ],
              [
                -58.00911257226962,
                -20.593021221305893
              ],
              [
                -58.00737112648256,
                -20.589845236000183
              ],
              [
                -58.005301111813594,
                -20.586090719701478
              ],
              [
                -58.0037526647419,
                -20.583801751925456
              ],
              [
                -58.00361764030832,
                -20.583601232209887
              ],
              [
                -57.99691122946253,
                -20.572652251767128
              ],
              [
                -57.99819972314425,
                -20.56849427399853
              ],
              [
                -58.00045074527091,
                -20.563230783945748
              ],
              [
                -58.002634762921346,
                -20.556762813849897
              ],
              [
                -58.007477230774626,
                -20.54392138476531
              ],
              [
                -58.00981674951092,
                -20.53771193224972
              ],
              [
                -58.01215573724517,
                -20.53150242573404
              ],
              [
                -58.01727628469084,
                -20.516753503088257
              ],
              [
                -58.01805829878414,
                -20.512556521942866
              ],
              [
                -58.01594631763392,
                -20.499550079561782
              ],
              [
                -58.01468835421484,
                -20.489395593907616
              ],
              [
                -58.013458382438394,
                -20.484612112766794
              ],
              [
                -58.01114790998968,
                -20.480935605556862
              ],
              [
                -57.99427512409972,
                -20.45409016596314
              ],
              [
                -57.99420264115996,
                -20.44946920476976
              ],
              [
                -57.99468863629101,
                -20.441664744294734
              ],
              [
                -57.99643316898155,
                -20.434806760310554
              ],
              [
                -57.99904615760805,
                -20.42954330139559
              ],
              [
                -58.00379812431151,
                -20.426334304753087
              ],
              [
                -58.01057013938401,
                -20.416714372397596
              ],
              [
                -58.02033512106213,
                -20.401984450722967
              ],
              [
                -58.02453960918373,
                -20.400906938034325
              ],
              [
                -58.02771508085854,
                -20.400477455019747
              ],
              [
                -58.032661024794145,
                -20.399798981163176
              ],
              [
                -58.038513498800995,
                -20.3985275150743
              ],
              [
                -58.039716980811676,
                -20.39826600658013
              ],
              [
                -58.04913393334427,
                -20.395561550118682
              ],
              [
                -58.062724376103375,
                -20.392415572664852
              ],
              [
                -58.06547986091846,
                -20.39009058796294
              ],
              [
                -58.07040281609234,
                -20.385827119459165
              ],
              [
                -58.07894529577734,
                -20.37705671439213
              ],
              [
                -58.08362228900508,
                -20.372066224666096
              ],
              [
                -58.084927784878495,
                -20.36926922426149
              ],
              [
                -58.08908478226701,
                -20.359286790283818
              ],
              [
                -58.09179626686893,
                -20.350856329611346
              ],
              [
                -58.095937785227136,
                -20.33762589685922
              ],
              [
                -58.100811295735426,
                -20.324408952252227
              ],
              [
                -58.1003713289151,
                -20.321332935614656
              ],
              [
                -58.09777287936793,
                -20.31372246528512
              ],
              [
                -58.09739586352742,
                -20.312617963996153
              ],
              [
                -58.09480742576863,
                -20.305644473499573
              ],
              [
                -58.093064923174026,
                -20.30085398994976
              ],
              [
                -58.08847306135898,
                -20.273273610162562
              ],
              [
                -58.08936608655196,
                -20.260990638555324
              ],
              [
                -58.094056051851865,
                -20.255029148244
              ],
              [
                -58.1013065175876,
                -20.250524726468708
              ],
              [
                -58.104693030874834,
                -20.250112730966837
              ],
              [
                -58.11217498297793,
                -20.24954474917969
              ],
              [
                -58.11808943886184,
                -20.24971027418338
              ],
              [
                -58.121499389569195,
                -20.251226753046478
              ],
              [
                -58.12844331434665,
                -20.257204258914587
              ],
              [
                -58.132166796584485,
                -20.26184274987218
              ],
              [
                -58.138684244984546,
                -20.269959724073214
              ],
              [
                -58.143986702089855,
                -20.272155235233022
              ],
              [
                -58.14809665181388,
                -20.270508284182455
              ],
              [
                -58.15131666306435,
                -20.268644263235505
              ],
              [
                -58.1573041115676,
                -20.264901295044083
              ],
              [
                -58.160482613076475,
                -20.262144327069677
              ],
              [
                -58.16258461763575,
                -20.254586356552668
              ],
              [
                -58.165779126469616,
                -20.237621444948378
              ],
              [
                -58.16509918698439,
                -20.23309942363224
              ],
              [
                -58.1634891793843,
                -20.229526969257467
              ],
              [
                -58.16077123428648,
                -20.22376799119303
              ],
              [
                -58.157909754446024,
                -20.220010495753964
              ],
              [
                -58.155168781265594,
                -20.216718983057742
              ],
              [
                -58.152718782608105,
                -20.21420197253613
              ],
              [
                -58.14378385770048,
                -20.21063095743628
              ],
              [
                -58.141977900236576,
                -20.210098973927575
              ],
              [
                -58.13800189703838,
                -20.20892796820362
              ],
              [
                -58.129458464141706,
                -20.205492436814826
              ],
              [
                -58.12167008204133,
                -20.194629976554022
              ],
              [
                -58.12508355349497,
                -20.187504515503857
              ],
              [
                -58.136238001861116,
                -20.179672579854028
              ],
              [
                -58.14106198712935,
                -20.17946507987204
              ],
              [
                -58.14286498464221,
                -20.17938810248329
              ],
              [
                -58.1505359191108,
                -20.179862114566046
              ],
              [
                -58.154294897866464,
                -20.180093119902864
              ],
              [
                -58.15914236866233,
                -20.17908361778552
              ],
              [
                -58.165678320389695,
                -20.175993160915993
              ],
              [
                -58.16680583367863,
                -20.17129168062404
              ],
              [
                -58.162419399697704,
                -20.162121692562355
              ],
              [
                -58.15086900730437,
                -20.15266020933365
              ],
              [
                -58.14811852411491,
                -20.152388192140773
              ],
              [
                -58.13838208871188,
                -20.151425161683107
              ],
              [
                -58.13349364927789,
                -20.150000158188544
              ],
              [
                -58.12958815465608,
                -20.148812647776488
              ],
              [
                -58.12220869617009,
                -20.146569144775835
              ],
              [
                -58.11513127078797,
                -20.143401143904505
              ],
              [
                -58.11002781369038,
                -20.135060662951446
              ],
              [
                -58.10706634738751,
                -20.129507686605468
              ],
              [
                -58.10546287676376,
                -20.126812681288435
              ],
              [
                -58.10392140239342,
                -20.124220688796584
              ],
              [
                -58.100832961257126,
                -20.12067616306884
              ],
              [
                -58.09570298560979,
                -20.114960694913393
              ],
              [
                -58.09129702303465,
                -20.11021367851875
              ],
              [
                -58.08828256530671,
                -20.107304688804746
              ],
              [
                -58.07395116786465,
                -20.10696567169969
              ],
              [
                -58.064727224707745,
                -20.105850131393215
              ],
              [
                -58.063304254559206,
                -20.10567762579974
              ],
              [
                -58.05569027725409,
                -20.10536659742876
              ],
              [
                -58.048453851887594,
                -20.104634093652283
              ],
              [
                -58.04333141330925,
                -20.09828009783526
              ],
              [
                -58.03788547149888,
                -20.081123159009156
              ],
              [
                -58.03606148777261,
                -20.07494917932143
              ],
              [
                -58.035024007122225,
                -20.07143866059927
              ],
              [
                -58.03234756881262,
                -20.0653052042733
              ],
              [
                -58.02526614608705,
                -20.058612210964053
              ],
              [
                -58.017989690139835,
                -20.057228697423227
              ],
              [
                -57.99977529562842,
                -20.055539134796085
              ],
              [
                -57.99834880046838,
                -20.05568363309238
              ],
              [
                -57.984568923883785,
                -20.057078096660618
              ],
              [
                -57.97722648107168,
                -20.05184908649768
              ],
              [
                -57.97194399854041,
                -20.04761010191393
              ],
              [
                -57.97073554805257,
                -20.043472094953014
              ],
              [
                -57.969669063033244,
                -20.03982060569703
              ],
              [
                -57.965195613814394,
                -20.029921630481827
              ],
              [
                -57.959693666270766,
                -20.021857175797717
              ],
              [
                -57.95095576183065,
                -20.01958962316618
              ],
              [
                -57.94589378606914,
                -20.018326610777372
              ],
              [
                -57.93455286040007,
                -20.0197946053215
              ],
              [
                -57.92699641503178,
                -20.02247056169631
              ],
              [
                -57.91443646110867,
                -20.034065990851865
              ],
              [
                -57.9141404452012,
                -20.034322968074626
              ],
              [
                -57.91063995310415,
                -20.037366964039645
              ],
              [
                -57.90286852541368,
                -20.0405774328788
              ],
              [
                -57.89989703245873,
                -20.03857043825536
              ],
              [
                -57.88669218530656,
                -20.014994973968992
              ],
              [
                -57.880063262566246,
                -20.007110492478038
              ],
              [
                -57.88005727863171,
                -20.0071024824889
              ],
              [
                -57.87342184194556,
                -19.99921151202655
              ],
              [
                -57.86171794475057,
                -19.98511901376366
              ],
              [
                -57.860209962475416,
                -19.977527025303928
              ],
              [
                -57.8587014891913,
                -19.96993508385374
              ],
              [
                -58.13116182472547,
                -19.757998093475663
              ],
              [
                -58.006949422479806,
                -19.499217721761323
              ],
              [
                -57.78354827589719,
                -19.03301540717595
              ],
              [
                -57.70882376984818,
                -19.031051713112728
              ],
              [
                -57.704888307455185,
                -19.025244226637966
              ],
              [
                -57.69362243872137,
                -19.010418235167936
              ],
              [
                -57.697942909543784,
                -19.00682178251332
              ],
              [
                -57.69728994409098,
                -19.002178271899435
              ],
              [
                -57.69658393280526,
                -18.997591791930418
              ],
              [
                -57.69809497261393,
                -18.9947663406034
              ],
              [
                -57.70163042893719,
                -18.989420344246454
              ],
              [
                -57.70377344931446,
                -18.986041387727372
              ],
              [
                -57.70496443963482,
                -18.984705883987814
              ],
              [
                -57.71010439475252,
                -18.978942917633272
              ],
              [
                -57.713389890659656,
                -18.977845442831253
              ],
              [
                -57.718611849602105,
                -18.97652593823299
              ],
              [
                -57.719136571041474,
                -18.89854372905762
              ],
              [
                -57.76602477435914,
                -18.898789871972564
              ],
              [
                -57.55712198192483,
                -18.239850929849077
              ],
              [
                -57.547949534115546,
                -18.23906387437696
              ],
              [
                -57.53877660632163,
                -18.238276875908298
              ],
              [
                -57.4988723691102,
                -18.234841785617068
              ],
              [
                -57.4667810964774,
                -18.2319332322211
              ],
              [
                -57.45329219941667,
                -18.230673706111084
              ],
              [
                -57.455607199772516,
                -18.22741620279825
              ],
              [
                -57.46251966558711,
                -18.22328774107967
              ],
              [
                -57.46627162165564,
                -18.222391264519697
              ],
              [
                -57.466827643407534,
                -18.222257769513895
              ],
              [
                -57.47363509173834,
                -18.21647730885636
              ],
              [
                -57.47349312270863,
                -18.215434317485528
              ],
              [
                -57.47306063079843,
                -18.21225830653732
              ],
              [
                -57.47684861957163,
                -18.2089833402079
              ],
              [
                -57.4787435843801,
                -18.206169859064094
              ],
              [
                -57.478928608078526,
                -18.20242684794066
              ],
              [
                -57.482934611507204,
                -18.197064410211027
              ],
              [
                -57.48878303965941,
                -18.20070592278298
              ],
              [
                -57.49022703259936,
                -18.201604884173026
              ],
              [
                -57.499709491790476,
                -18.196396959418095
              ],
              [
                -57.50442693804133,
                -18.19980043090426
              ],
              [
                -57.574220142397444,
                -18.131396408091547
              ],
              [
                -57.599465209555326,
                -18.045877281634162
              ],
              [
                -57.62303366164293,
                -18.003399025345935
              ],
              [
                -57.62496215142978,
                -17.998769522255248
              ],
              [
                -57.63074766777285,
                -17.984881127985588
              ],
              [
                -57.719922497689744,
                -17.82737044939881
              ],
              [
                -57.68252322777367,
                -17.82760686964416
              ],
              [
                -57.682551777226,
                -17.817559403553535
              ],
              [
                -57.68258029466112,
                -17.807511439478127
              ],
              [
                -57.71949113833574,
                -17.771229173143354
              ],
              [
                -57.708944312539565,
                -17.72936883067312
              ],
              [
                -57.71978629861142,
                -17.719702879326515
              ],
              [
                -57.72032179521701,
                -17.719675398826087
              ],
              [
                -57.723318762219826,
                -17.71952188221735
              ],
              [
                -57.72981420442155,
                -17.718352422644784
              ],
              [
                -57.732989730835065,
                -17.71164393488379
              ],
              [
                -57.73713171045059,
                -17.70052850170542
              ],
              [
                -57.737748697135956,
                -17.698873007722003
              ],
              [
                -57.74014871515307,
                -17.693015055652776
              ],
              [
                -57.743437677158,
                -17.692903032632795
              ],
              [
                -57.74839114554434,
                -17.692733064162848
              ],
              [
                -57.754839662654376,
                -17.67614865615659
              ],
              [
                -57.75764316386129,
                -17.668939684366805
              ],
              [
                -57.75988018115185,
                -17.66335219282461
              ],
              [
                -57.76236013499073,
                -17.660200210474365
              ],
              [
                -57.76635415096221,
                -17.65884225809879
              ],
              [
                -57.767397142208495,
                -17.65848823017658
              ],
              [
                -57.77182811374467,
                -17.657346280096743
              ],
              [
                -57.775044594225676,
                -17.65653029360273
              ],
              [
                -57.78129707452737,
                -17.642079333810305
              ],
              [
                -57.78248707817894,
                -17.63840784812325
              ],
              [
                -57.77880760514929,
                -17.63267586780568
              ],
              [
                -57.7781841086264,
                -17.631809404549365
              ],
              [
                -57.77380618309849,
                -17.6257268917948
              ],
              [
                -57.771217718330895,
                -17.612415457734766
              ],
              [
                -57.773778220483614,
                -17.611091937496937
              ],
              [
                -57.777906168866444,
                -17.612833947421223
              ],
              [
                -57.779882674730096,
                -17.613667437303654
              ],
              [
                -57.78215414383833,
                -17.609472488455825
              ],
              [
                -57.78280217197569,
                -17.606511969618165
              ],
              [
                -57.778139698973796,
                -17.602307008872305
              ],
              [
                -57.777019198066625,
                -17.601296492895845
              ],
              [
                -57.773392728697935,
                -17.597326524927375
              ],
              [
                -57.77735120497816,
                -17.595585022923856
              ],
              [
                -57.77938372852942,
                -17.594623541911677
              ],
              [
                -57.78245820972862,
                -17.593168027510885
              ],
              [
                -57.78021572059778,
                -17.589482044022112
              ],
              [
                -57.7769447598698,
                -17.584105078446413
              ],
              [
                -57.78056070311003,
                -17.58459059790536
              ],
              [
                -57.78100771021802,
                -17.584590572060662
              ],
              [
                -57.78929318460351,
                -17.584595587454487
              ],
              [
                -57.782222226442975,
                -17.57708459934642
              ],
              [
                -57.78090573788829,
                -17.57568559137929
              ],
              [
                -57.78251223959849,
                -17.57529262705519
              ],
              [
                -57.78476472157262,
                -17.574742127011426
              ],
              [
                -57.784957732908296,
                -17.569190646071775
              ],
              [
                -57.79379368092647,
                -17.563785714879344
              ],
              [
                -57.794962701144534,
                -17.559944199817586
              ],
              [
                -57.79303121329148,
                -17.556858219819834
              ],
              [
                -57.79255472751011,
                -17.556096205550517
              ],
              [
                -57.78502077414762,
                -17.55505819514296
              ],
              [
                -57.78142628049624,
                -17.556093195845843
              ],
              [
                -57.77713683578348,
                -17.557327671979657
              ],
              [
                -57.770882356600545,
                -17.558985657395908
              ],
              [
                -57.768547851970574,
                -17.55960464996557
              ],
              [
                -57.75862944427502,
                -17.562059604826448
              ],
              [
                -57.75176249059752,
                -17.5638880689954
              ],
              [
                -57.740116605619995,
                -17.53725367163916
              ],
              [
                -57.73951163941082,
                -17.535869652471604
              ],
              [
                -57.7335146744172,
                -17.532590687799654
              ],
              [
                -57.7275172254305,
                -17.52931118313677
              ],
              [
                -57.71154380073492,
                -17.543665061046656
              ],
              [
                -57.71092328499365,
                -17.55465001363399
              ],
              [
                -57.71030276023056,
                -17.565634965235507
              ],
              [
                -57.70293174976326,
                -17.5749919307711
              ],
              [
                -57.70110078211105,
                -17.577316399991993
              ],
              [
                -57.70139727374963,
                -17.578898919593204
              ],
              [
                -57.70334370994484,
                -17.58929886411996
              ],
              [
                -57.69667923985764,
                -17.60079680114374
              ],
              [
                -57.70151521547565,
                -17.600807309598572
              ],
              [
                -57.69213621676381,
                -17.626244697372055
              ],
              [
                -57.68753873381357,
                -17.628294185503933
              ],
              [
                -57.68621524273741,
                -17.62888419278413
              ],
              [
                -57.68686921208707,
                -17.631161169618426
              ],
              [
                -57.68937019333754,
                -17.639872642211
              ],
              [
                -57.683903198597356,
                -17.6417196248734
              ],
              [
                -57.68218275112035,
                -17.6423010891574
              ],
              [
                -57.68218421391591,
                -17.646630104326018
              ],
              [
                -57.68218523363473,
                -17.649921583530077
              ],
              [
                -57.680890721898834,
                -17.650324076611824
              ],
              [
                -57.67640923704692,
                -17.651718066582504
              ],
              [
                -57.678632234372,
                -17.656774021682924
              ],
              [
                -57.67884772758945,
                -17.657265044332398
              ],
              [
                -57.67829819484977,
                -17.65772802910844
              ],
              [
                -57.67123826689306,
                -17.663675007691136
              ],
              [
                -57.671958223529806,
                -17.670300988799625
              ],
              [
                -57.668296224997185,
                -17.671470453758552
              ],
              [
                -57.672272707043795,
                -17.676836949213396
              ],
              [
                -57.67046220906274,
                -17.680638435744726
              ],
              [
                -57.672860692824855,
                -17.681584428291753
              ],
              [
                -57.68018511113504,
                -17.68447291307692
              ],
              [
                -57.682604610379336,
                -17.69581788230613
              ],
              [
                -57.6795756130159,
                -17.703606366206124
              ],
              [
                -57.68376855300278,
                -17.715200822112156
              ],
              [
                -57.67185110779399,
                -17.71928529828287
              ],
              [
                -57.67114956297318,
                -17.7278942240569
              ],
              [
                -57.66651511725647,
                -17.725706742490736
              ],
              [
                -57.66301513447308,
                -17.729417707988407
              ],
              [
                -57.64543275422139,
                -17.723923203598286
              ],
              [
                -57.64446275987996,
                -17.719556685036103
              ],
              [
                -57.64420429985669,
                -17.718391198891545
              ],
              [
                -57.63976481985551,
                -17.7169212230657
              ],
              [
                -57.622571381801436,
                -17.735922100548848
              ],
              [
                -57.61857488982358,
                -17.746144050443128
              ],
              [
                -57.61810487247587,
                -17.74734501855518
              ],
              [
                -57.617656405912435,
                -17.747514026731814
              ],
              [
                -57.61145843457353,
                -17.74985551450829
              ],
              [
                -57.61582839226749,
                -17.752287500433837
              ],
              [
                -57.6194903406823,
                -17.75432551405064
              ],
              [
                -57.61434734295224,
                -17.766372440884297
              ],
              [
                -57.60924636931508,
                -17.769476403546726
              ],
              [
                -57.60041191951565,
                -17.774852862656065
              ],
              [
                -57.60218741736526,
                -17.778587383782174
              ],
              [
                -57.60463489017327,
                -17.778683388787492
              ],
              [
                -57.6120433521648,
                -17.778974373965692
              ],
              [
                -57.60324434195956,
                -17.803409283213384
              ],
              [
                -57.589517428991,
                -17.801749741837167
              ],
              [
                -57.57579252303366,
                -17.80009122747527
              ],
              [
                -57.57177353941613,
                -17.81232417249324
              ],
              [
                -57.57090901656228,
                -17.814954647025324
              ],
              [
                -57.56733802377779,
                -17.81496713065667
              ],
              [
                -57.558127108667534,
                -17.814999618492255
              ],
              [
                -57.55164463154362,
                -17.827422564327517
              ],
              [
                -57.551464597734395,
                -17.827767548517805
              ],
              [
                -57.551073629703446,
                -17.827970545708684
              ],
              [
                -57.53049121434947,
                -17.83867343538778
              ],
              [
                -57.5323611994728,
                -17.845936431064636
              ],
              [
                -57.53423115958467,
                -17.853199905750564
              ],
              [
                -57.50882281402256,
                -17.863450797514393
              ],
              [
                -57.477048522122345,
                -17.858614748255125
              ],
              [
                -57.477090021591074,
                -17.868529195809053
              ],
              [
                -57.47713049904946,
                -17.878443167375373
              ],
              [
                -57.46139953765282,
                -17.898097553820186
              ],
              [
                -57.45193106276908,
                -17.90220348707069
              ],
              [
                -57.44538214203224,
                -17.89152955143215
              ],
              [
                -57.445751685523454,
                -17.88350308562162
              ],
              [
                -57.44618620160185,
                -17.87406561247985
              ],
              [
                -57.43809776001683,
                -17.871058077044445
              ],
              [
                -57.42119990570822,
                -17.853760143292355
              ],
              [
                -57.412439956126306,
                -17.856865086319655
              ],
              [
                -57.41021297143827,
                -17.857654064429624
              ],
              [
                -57.40945401244326,
                -17.854477118836012
              ],
              [
                -57.409214505860895,
                -17.853475094118316
              ],
              [
                -57.403233062268455,
                -17.849503109000736
              ],
              [
                -57.40157306125854,
                -17.848400592970616
              ],
              [
                -57.400043079152375,
                -17.847384620942787
              ],
              [
                -57.397982136673434,
                -17.8389291095647
              ],
              [
                -57.391312131985885,
                -17.844663081788497
              ],
              [
                -57.39082616497668,
                -17.84508059489124
              ],
              [
                -57.38514918564746,
                -17.843835092947494
              ],
              [
                -57.38438468546856,
                -17.84213957557397
              ],
              [
                -57.37697928856708,
                -17.825718158419782
              ],
              [
                -57.36754283818727,
                -17.825758095682513
              ],
              [
                -57.362617374506684,
                -17.833120584069974
              ],
              [
                -57.3612673716395,
                -17.833719571210867
              ],
              [
                -57.352918906764366,
                -17.837420519987976
              ],
              [
                -57.34615098761916,
                -17.83370555488112
              ],
              [
                -57.341873981362625,
                -17.835556509498748
              ],
              [
                -57.34081901949478,
                -17.830195037724714
              ],
              [
                -57.32468712334266,
                -17.8287115175213
              ],
              [
                -57.32426212924739,
                -17.82408801251698
              ],
              [
                -57.3162987246957,
                -17.820109014374207
              ],
              [
                -57.31084925211592,
                -17.817386489861136
              ],
              [
                -57.30754727464184,
                -17.8103695237722
              ],
              [
                -57.29923281900007,
                -17.81640896947307
              ],
              [
                -57.28948588788677,
                -17.81111399688747
              ],
              [
                -57.28765541550495,
                -17.81371796291463
              ],
              [
                -57.292297872743525,
                -17.81877396616199
              ],
              [
                -57.292960391355535,
                -17.819495465056622
              ],
              [
                -57.28279292517945,
                -17.82225442578867
              ],
              [
                -57.2829734293343,
                -17.818255446941837
              ],
              [
                -57.2832349480082,
                -17.812473450302047
              ],
              [
                -57.273662513113706,
                -17.816232419664374
              ],
              [
                -57.26430005709007,
                -17.81990888489139
              ],
              [
                -57.25207517543947,
                -17.810322403748955
              ],
              [
                -57.232096806941705,
                -17.81194285346864
              ],
              [
                -57.23357779922174,
                -17.80770486197314
              ],
              [
                -57.24493070190547,
                -17.805874906657063
              ],
              [
                -57.2470047218071,
                -17.80180941001915
              ],
              [
                -57.25148823227154,
                -17.79301848120774
              ],
              [
                -57.242433268997466,
                -17.79714590547527
              ],
              [
                -57.234548327908215,
                -17.787892457391383
              ],
              [
                -57.22754388630378,
                -17.789862924464238
              ],
              [
                -57.22660092974212,
                -17.782345950592163
              ],
              [
                -57.21533495702971,
                -17.78753338795267
              ],
              [
                -57.200101063653705,
                -17.7883838790865
              ],
              [
                -57.17431627357975,
                -17.775800842821223
              ],
              [
                -57.16861880117197,
                -17.776455337476918
              ],
              [
                -57.15540541373756,
                -17.77797281425458
              ],
              [
                -57.15215890394491,
                -17.774123309034408
              ],
              [
                -57.146207484980444,
                -17.76706680547698
              ],
              [
                -57.13021009449881,
                -17.77404527249741
              ],
              [
                -57.115073682450145,
                -17.780648173215265
              ],
              [
                -57.11364968461319,
                -17.773986717849958
              ],
              [
                -57.113072191037666,
                -17.771283224039582
              ],
              [
                -57.10540774435485,
                -17.77061671683575
              ],
              [
                -57.09804731960479,
                -17.76997669231743
              ],
              [
                -57.0947318489784,
                -17.75984620981194
              ],
              [
                -57.092567403846054,
                -17.753235271375097
              ],
              [
                -57.08592841294292,
                -17.750965234198436
              ],
              [
                -57.07928946904407,
                -17.748695234028098
              ],
              [
                -57.077348510457085,
                -17.74420623779194
              ],
              [
                -57.07337905742167,
                -17.735024276917724
              ],
              [
                -57.06525059893501,
                -17.73467477531559
              ],
              [
                -57.06365910860833,
                -17.734606752476342
              ],
              [
                -57.05618121446564,
                -17.729056269182657
              ],
              [
                -57.04962321377798,
                -17.732220238708432
              ],
              [
                -57.044379744898144,
                -17.72988171745377
              ],
              [
                -57.03589839721812,
                -17.7037233014321
              ],
              [
                -57.03284292576935,
                -17.69430133999651
              ],
              [
                -57.025934473831846,
                -17.693040323220345
              ],
              [
                -57.018735037968625,
                -17.69172584791199
              ],
              [
                -57.01900104535747,
                -17.685606363931896
              ],
              [
                -57.01904106070254,
                -17.684674837739664
              ],
              [
                -57.01037114524181,
                -17.671675392159724
              ],
              [
                -57.00523519401272,
                -17.670992389674073
              ],
              [
                -56.999901236164405,
                -17.670283857783062
              ],
              [
                -56.98768632068042,
                -17.65885190593458
              ],
              [
                -56.985502390385975,
                -17.65015343799672
              ],
              [
                -56.983318399078776,
                -17.64145443907647
              ],
              [
                -56.982341411975064,
                -17.640217452502945
              ],
              [
                -56.95892064116184,
                -17.610558529865457
              ],
              [
                -56.96001415920779,
                -17.60471805400307
              ],
              [
                -56.97227153681259,
                -17.61316951963031
              ],
              [
                -56.97360905211284,
                -17.612766536665685
              ],
              [
                -56.98087702428287,
                -17.61057505303326
              ],
              [
                -56.981851516774924,
                -17.60342262008775
              ],
              [
                -56.975429572115246,
                -17.59552212020495
              ],
              [
                -56.98182408169482,
                -17.579792696415932
              ],
              [
                -56.97346415541928,
                -17.579937178461254
              ],
              [
                -56.972958647727886,
                -17.579946197132628
              ],
              [
                -56.968160202263036,
                -17.573508674605808
              ],
              [
                -56.96626571281799,
                -17.57096720592874
              ],
              [
                -56.963175213649286,
                -17.570446200115395
              ],
              [
                -56.94984632082366,
                -17.568197676058517
              ],
              [
                -56.945891365152924,
                -17.55982718746788
              ],
              [
                -56.94193540747586,
                -17.551456735893684
              ],
              [
                -56.917012598182566,
                -17.540918718561098
              ],
              [
                -56.90904420019619,
                -17.532107221601738
              ],
              [
                -56.90270224038176,
                -17.535247184839474
              ],
              [
                -56.89523728845096,
                -17.538943175980265
              ],
              [
                -56.87543640901398,
                -17.532533163273136
              ],
              [
                -56.871554986852644,
                -17.520534668609972
              ],
              [
                -56.87001000326557,
                -17.515758687879014
              ],
              [
                -56.86848649212267,
                -17.515473222150366
              ],
              [
                -56.86607000534471,
                -17.515020192823695
              ],
              [
                -56.860936068589226,
                -17.50477172598936
              ],
              [
                -56.86234356558817,
                -17.50212772320616
              ],
              [
                -56.8661230782157,
                -17.495026263388382
              ],
              [
                -56.86143214509871,
                -17.483469800987113
              ],
              [
                -56.854534197025444,
                -17.485160291661522
              ],
              [
                -56.849706717671125,
                -17.486343783634975
              ],
              [
                -56.8455137524911,
                -17.48014128797324
              ],
              [
                -56.84776324428529,
                -17.47445780358277
              ],
              [
                -56.84940873086502,
                -17.47029982651323
              ],
              [
                -56.84728726443096,
                -17.46356737326495
              ],
              [
                -56.833667372277276,
                -17.463274845902053
              ],
              [
                -56.831634400839484,
                -17.463231323922138
              ],
              [
                -56.82488146264779,
                -17.45845282007688
              ],
              [
                -56.82495744311561,
                -17.45264833669116
              ],
              [
                -56.82832193051697,
                -17.4502308869723
              ],
              [
                -56.840376393639986,
                -17.44156942646194
              ],
              [
                -56.832708942472344,
                -17.434603954155552
              ],
              [
                -56.83055397230712,
                -17.43511795262448
              ],
              [
                -56.82218149817991,
                -17.437114925363698
              ],
              [
                -56.819468533738785,
                -17.430701952395452
              ],
              [
                -56.823875050737065,
                -17.422640488109252
              ],
              [
                -56.83162749267751,
                -17.42293350353977
              ],
              [
                -56.83376497832479,
                -17.423014499621022
              ],
              [
                -56.84148593274199,
                -17.4177505244113
              ],
              [
                -56.830617050965394,
                -17.407771545232567
              ],
              [
                -56.828340603737836,
                -17.39072662637582
              ],
              [
                -56.80387928390557,
                -17.386188583972842
              ],
              [
                -56.79049885926453,
                -17.390626552233204
              ],
              [
                -56.78545588031728,
                -17.387088536831115
              ],
              [
                -56.788062379321445,
                -17.378958582335148
              ],
              [
                -56.7888714108715,
                -17.376435572595582
              ],
              [
                -56.78621792584838,
                -17.370065627711053
              ],
              [
                -56.77898300542281,
                -17.366881617376222
              ],
              [
                -56.764095107855006,
                -17.36032960342834
              ],
              [
                -56.76037514738902,
                -17.35120763206981
              ],
              [
                -56.76415264744675,
                -17.34715567502311
              ],
              [
                -56.76872060558921,
                -17.3422551724078
              ],
              [
                -56.7566827242293,
                -17.33640168887994
              ],
              [
                -56.756386743739704,
                -17.326295719178997
              ],
              [
                -56.74377980264097,
                -17.326866688165886
              ],
              [
                -56.738897370583636,
                -17.323337686236872
              ],
              [
                -56.737703870834615,
                -17.322475703741496
              ],
              [
                -56.73760589327462,
                -17.32218521567622
              ],
              [
                -56.733322437387955,
                -17.309422240814907
              ],
              [
                -56.72369001770913,
                -17.308492724411543
              ],
              [
                -56.7195035305368,
                -17.30808923054351
              ],
              [
                -56.71466605376088,
                -17.315740662260477
              ],
              [
                -56.71301504658613,
                -17.318353681481213
              ],
              [
                -56.70179014692955,
                -17.317999138734297
              ],
              [
                -56.69140372466036,
                -17.317671624987806
              ],
              [
                -56.69230067429202,
                -17.319664123126415
              ],
              [
                -56.6953801562623,
                -17.32650257502008
              ],
              [
                -56.688044718165,
                -17.320523607939492
              ],
              [
                -56.68265124988565,
                -17.31612709330546
              ],
              [
                -56.67452581199613,
                -17.323758046818497
              ],
              [
                -56.673405820561214,
                -17.324809550722367
              ],
              [
                -56.67331877091234,
                -17.332972493253564
              ],
              [
                -56.663046339287135,
                -17.33839146137169
              ],
              [
                -56.654655412978606,
                -17.33140595975545
              ],
              [
                -56.66125037840056,
                -17.326934000542405
              ],
              [
                -56.66215490006898,
                -17.326321007311783
              ],
              [
                -56.659823432057685,
                -17.32208701571452
              ],
              [
                -56.6531549259071,
                -17.322597997891744
              ],
              [
                -56.65120493525307,
                -17.327959971136753
              ],
              [
                -56.64717995583405,
                -17.339024937926
              ],
              [
                -56.64181549577118,
                -17.330086950889243
              ],
              [
                -56.64439601456276,
                -17.327912967232812
              ],
              [
                -56.649350959044455,
                -17.323737978695107
              ],
              [
                -56.64690649482177,
                -17.31654853787887
              ],
              [
                -56.63574355915251,
                -17.32785244875755
              ],
              [
                -56.62990606981297,
                -17.333763908990306
              ],
              [
                -56.62884012310719,
                -17.32780490662227
              ],
              [
                -56.62803863161606,
                -17.323324947889102
              ],
              [
                -56.624489146738924,
                -17.321648949821803
              ],
              [
                -56.616242196815385,
                -17.323369916105126
              ],
              [
                -56.61373969422276,
                -17.327491411001763
              ],
              [
                -56.61132319544677,
                -17.331469851697804
              ],
              [
                -56.60515325399894,
                -17.32831887907615
              ],
              [
                -56.59536330373177,
                -17.33210584204461
              ],
              [
                -56.584378402689225,
                -17.328809334946875
              ],
              [
                -56.5760409691863,
                -17.330616270646516
              ],
              [
                -56.577862444212116,
                -17.323579816973716
              ],
              [
                -56.578823481921184,
                -17.319865363566716
              ],
              [
                -56.57063202096497,
                -17.314860364495647
              ],
              [
                -56.555513599027506,
                -17.320546767342123
              ],
              [
                -56.55048713531791,
                -17.32243674401003
              ],
              [
                -56.543976683021775,
                -17.31897525885675
              ],
              [
                -56.531844801240275,
                -17.31252478286989
              ],
              [
                -56.530752817022154,
                -17.313807756880433
              ],
              [
                -56.526011312438676,
                -17.31938022218125
              ],
              [
                -56.52159588088876,
                -17.310146231945208
              ],
              [
                -56.51334294673152,
                -17.31238672109861
              ],
              [
                -56.51115394054076,
                -17.306033221657977
              ],
              [
                -56.505513486241775,
                -17.305606739288013
              ],
              [
                -56.50866248527424,
                -17.301184776299003
              ],
              [
                -56.514927978427835,
                -17.29238682904822
              ],
              [
                -56.50754349690746,
                -17.29484629049088
              ],
              [
                -56.5012400691439,
                -17.285886310482592
              ],
              [
                -56.49768155627031,
                -17.298271758770916
              ],
              [
                -56.489569647354486,
                -17.297875711104044
              ],
              [
                -56.47966865579892,
                -17.3046971693441
              ],
              [
                -56.47259470170283,
                -17.309571135641956
              ],
              [
                -56.471676729321,
                -17.30643563621404
              ],
              [
                -56.470654226601305,
                -17.302943168992215
              ],
              [
                -56.46698876200695,
                -17.301841656346244
              ],
              [
                -56.46540725539328,
                -17.307799131931425
              ],
              [
                -56.46343376938634,
                -17.31523111748551
              ],
              [
                -56.45829681222277,
                -17.31279609262563
              ],
              [
                -56.45184336752603,
                -17.309737580037822
              ],
              [
                -56.451078841143946,
                -17.318025553091136
              ],
              [
                -56.45034730150998,
                -17.3259525087164
              ],
              [
                -56.44279935809878,
                -17.330273466077287
              ],
              [
                -56.43595542071748,
                -17.322540516697185
              ],
              [
                -56.43206944853939,
                -17.31815000000825
              ],
              [
                -56.41787407669639,
                -17.311701499000907
              ],
              [
                -56.41479109821647,
                -17.31430048660486
              ],
              [
                -56.41217761468024,
                -17.31650296901247
              ],
              [
                -56.40756813870886,
                -17.315663472935153
              ],
              [
                -56.40328769398135,
                -17.30765147818649
              ],
              [
                -56.40232470628708,
                -17.30585000419087
              ],
              [
                -56.39954772416259,
                -17.30498399281331
              ],
              [
                -56.38840180896066,
                -17.3015074722698
              ],
              [
                -56.38572683509039,
                -17.295125515865358
              ],
              [
                -56.3830508532194,
                -17.288743517469022
              ],
              [
                -56.36028202664426,
                -17.280454510811474
              ],
              [
                -56.361314050978535,
                -17.27074806341646
              ],
              [
                -56.35542561660697,
                -17.26693156648188
              ],
              [
                -56.34243119508358,
                -17.272603504684664
              ],
              [
                -56.34298719353796,
                -17.27550298310493
              ],
              [
                -56.349793153866415,
                -17.274099509865525
              ],
              [
                -56.351178613509475,
                -17.27709897694561
              ],
              [
                -56.34649666086654,
                -17.280768966128683
              ],
              [
                -56.33534618573878,
                -17.289509392252658
              ],
              [
                -56.33445972299507,
                -17.281986434084452
              ],
              [
                -56.322736786446505,
                -17.28527688326613
              ],
              [
                -56.322710304360946,
                -17.275749451537443
              ],
              [
                -56.31301240043168,
                -17.268989956290792
              ],
              [
                -56.29862146675615,
                -17.27561840304319
              ],
              [
                -56.294434528564615,
                -17.270872893227114
              ],
              [
                -56.28604259204495,
                -17.270000858929713
              ],
              [
                -56.2795936545933,
                -17.258725403515278
              ],
              [
                -56.282325134264255,
                -17.253181943251217
              ],
              [
                -56.2850891378908,
                -17.247572992339297
              ],
              [
                -56.275707214478764,
                -17.247634940746895
              ],
              [
                -56.26760073956902,
                -17.24768841336429
              ],
              [
                -56.271117247546414,
                -17.243787977260325
              ],
              [
                -56.27215622069907,
                -17.24263545861621
              ],
              [
                -56.26851278191486,
                -17.23935196415681
              ],
              [
                -56.26656878224419,
                -17.23997495474397
              ],
              [
                -56.25602285871666,
                -17.24335392853035
              ],
              [
                -56.26005683602208,
                -17.23469696142595
              ],
              [
                -56.261875324351095,
                -17.230795478111393
              ],
              [
                -56.25856938664917,
                -17.228870978874617
              ],
              [
                -56.25363040222544,
                -17.2323934530054
              ],
              [
                -56.251764385134436,
                -17.233723971854513
              ],
              [
                -56.25218888726231,
                -17.231876947565244
              ],
              [
                -56.25444942057981,
                -17.222043027947933
              ],
              [
                -56.25082044316196,
                -17.218584532279934
              ],
              [
                -56.23740351444237,
                -17.221756474818257
              ],
              [
                -56.234358049809124,
                -17.225484936790046
              ],
              [
                -56.23320306373932,
                -17.226899442054304
              ],
              [
                -56.22040514053263,
                -17.228352389251395
              ],
              [
                -56.22204965627695,
                -17.220628935389612
              ],
              [
                -56.22242665651996,
                -17.21885444269108
              ],
              [
                -56.220631656828566,
                -17.21986394004632
              ],
              [
                -56.20824121309814,
                -17.226831373370203
              ],
              [
                -56.21130173483902,
                -17.21482994680125
              ],
              [
                -56.21248874138763,
                -17.210174960079286
              ],
              [
                -56.2072012432474,
                -17.212617457820905
              ],
              [
                -56.2049322736333,
                -17.21366544284022
              ],
              [
                -56.200772324627586,
                -17.209148941290096
              ],
              [
                -56.19762635087143,
                -17.205733431685527
              ],
              [
                -56.19591784718129,
                -17.206529942139962
              ],
              [
                -56.18862237894061,
                -17.209930893918337
              ],
              [
                -56.18719692224212,
                -17.201824936087167
              ],
              [
                -56.18490598753442,
                -17.18879300961679
              ],
              [
                -56.176572499320855,
                -17.19553295693921
              ],
              [
                -56.17029057598185,
                -17.18823748374563
              ],
              [
                -56.164341604015796,
                -17.193032422058977
              ],
              [
                -56.16282360491456,
                -17.19425593920741
              ],
              [
                -56.159402627812725,
                -17.191285442104654
              ],
              [
                -56.152204194826865,
                -17.18503494531944
              ],
              [
                -56.14476125831651,
                -17.18444793034978
              ],
              [
                -56.143667259246996,
                -17.18572042034127
              ],
              [
                -56.13934228878185,
                -17.190752387673857
              ],
              [
                -56.13588180238424,
                -17.18874087149244
              ],
              [
                -56.13797379983204,
                -17.18333239020742
              ],
              [
                -56.13962529917705,
                -17.179064939078042
              ],
              [
                -56.13273583060956,
                -17.180105433702852
              ],
              [
                -56.12487242201445,
                -17.173884413201193
              ],
              [
                -56.120955940236065,
                -17.175027908750604
              ],
              [
                -56.12031945500971,
                -17.175213911401467
              ],
              [
                -56.1195014548945,
                -17.174318424114844
              ],
              [
                -56.11259100149323,
                -17.16675643758993
              ],
              [
                -56.10317608972501,
                -17.173101367846574
              ],
              [
                -56.08393620389033,
                -17.177224322091547
              ],
              [
                -56.07106728212432,
                -17.17019482666636
              ],
              [
                -56.06624882659172,
                -17.173900805279374
              ],
              [
                -56.060308863764405,
                -17.17155829940005
              ],
              [
                -56.05731837472932,
                -17.176097273041947
              ],
              [
                -56.05045938799013,
                -17.186508715580644
              ],
              [
                -56.04671194786722,
                -17.184382210209233
              ],
              [
                -56.047268928227545,
                -17.18135121825597
              ],
              [
                -56.048989438669246,
                -17.171995233622848
              ],
              [
                -56.04461999229303,
                -17.17115174136226
              ],
              [
                -56.0319065369769,
                -17.18491765939322
              ],
              [
                -56.01554261300895,
                -17.18979010558655
              ],
              [
                -56.004985169677965,
                -17.203757510062236
              ],
              [
                -56.01436757142761,
                -17.207538509445804
              ],
              [
                -56.018044550724994,
                -17.20901951834669
              ],
              [
                -56.019621546192035,
                -17.216085483709904
              ],
              [
                -56.01088861568735,
                -17.21431600250202
              ],
              [
                -56.00810312494249,
                -17.21375146897233
              ],
              [
                -56.00959211164579,
                -17.216841953733415
              ],
              [
                -56.01217907085951,
                -17.222208966698016
              ],
              [
                -56.01026005081919,
                -17.238602879436865
              ],
              [
                -56.003237081332315,
                -17.233290394244
              ],
              [
                -56.0033170701234,
                -17.244351848216862
              ],
              [
                -55.99998457766158,
                -17.24580281789741
              ],
              [
                -55.996558142305844,
                -17.2422328493274
              ],
              [
                -55.99467266326861,
                -17.240268341868216
              ],
              [
                -55.989388182414146,
                -17.24080233454775
              ],
              [
                -55.98956115449295,
                -17.245930304539968
              ],
              [
                -55.98988314564999,
                -17.25549627935937
              ],
              [
                -55.98623864936326,
                -17.25766673925568
              ],
              [
                -55.981263203477106,
                -17.248126277802662
              ],
              [
                -55.98029023903879,
                -17.246259263198244
              ],
              [
                -55.97741173268017,
                -17.24914577199847
              ],
              [
                -55.9734022568966,
                -17.253166238254146
              ],
              [
                -55.96735429412392,
                -17.254102735352397
              ],
              [
                -55.9630808390382,
                -17.25293870863259
              ],
              [
                -55.95139993228198,
                -17.249757201008606
              ],
              [
                -55.955293888249486,
                -17.256015666469082
              ],
              [
                -55.954791393944866,
                -17.256645663588586
              ],
              [
                -55.93883744280351,
                -17.276634571426428
              ],
              [
                -55.930957484696506,
                -17.273372560534206
              ],
              [
                -55.93089151534062,
                -17.268085077497314
              ],
              [
                -55.930843509043086,
                -17.264219077558877
              ],
              [
                -55.92581559219403,
                -17.257419127570113
              ],
              [
                -55.91692610346967,
                -17.26400604996734
              ],
              [
                -55.88601383172024,
                -17.26486997177776
              ],
              [
                -55.89891772843516,
                -17.271652476322405
              ],
              [
                -55.901403719151865,
                -17.272959490008066
              ],
              [
                -55.89935870319212,
                -17.276593959716944
              ],
              [
                -55.89147327045023,
                -17.27248047240717
              ],
              [
                -55.890349302610204,
                -17.27189398307972
              ],
              [
                -55.890527767549656,
                -17.272585454624547
              ],
              [
                -55.89215477468481,
                -17.278873943310703
              ],
              [
                -55.88603977531809,
                -17.284324891317365
              ],
              [
                -55.87498186713785,
                -17.28016638033214
              ],
              [
                -55.87649687758647,
                -17.27767942251015
              ],
              [
                -55.87940333677309,
                -17.272907931711124
              ],
              [
                -55.87428888694409,
                -17.26819645379416
              ],
              [
                -55.857705473959456,
                -17.283088837265876
              ],
              [
                -55.850900545348374,
                -17.27883385425052
              ],
              [
                -55.85016099932639,
                -17.28777981789686
              ],
              [
                -55.84998603381463,
                -17.289899789573056
              ],
              [
                -55.84139705658488,
                -17.300791242606703
              ],
              [
                -55.83779509185991,
                -17.300071713691395
              ],
              [
                -55.835287630935824,
                -17.292951257340462
              ],
              [
                -55.834571615234964,
                -17.29091827209458
              ],
              [
                -55.82669117160069,
                -17.288645234103505
              ],
              [
                -55.82894513859342,
                -17.294890707482285
              ],
              [
                -55.83121212139409,
                -17.301172220751436
              ],
              [
                -55.82803811932004,
                -17.305461667888217
              ],
              [
                -55.8206011971412,
                -17.29744319208868
              ],
              [
                -55.820430705788134,
                -17.297259181438264
              ],
              [
                -55.80598582562604,
                -17.297569660331703
              ],
              [
                -55.80201233370962,
                -17.300842139743576
              ],
              [
                -55.80281729114492,
                -17.303492639611342
              ],
              [
                -55.80448930903122,
                -17.309002120630463
              ],
              [
                -55.78389735793676,
                -17.329605494065635
              ],
              [
                -55.78012740463199,
                -17.320987000891666
              ],
              [
                -55.77201695294896,
                -17.331611434157363
              ],
              [
                -55.76940848224944,
                -17.32728346685751
              ],
              [
                -55.76824150635354,
                -17.32534844108875
              ],
              [
                -55.76068207159505,
                -17.323093432808612
              ],
              [
                -55.74348415206389,
                -17.3309013843377
              ],
              [
                -55.739740150812,
                -17.336705817654682
              ],
              [
                -55.729513207877936,
                -17.33939032399281
              ],
              [
                -55.72934820987898,
                -17.339433805400596
              ],
              [
                -55.72172775877812,
                -17.348256238401845
              ],
              [
                -55.713808324410735,
                -17.341583776799148
              ],
              [
                -55.70857436975185,
                -17.33717378036214
              ],
              [
                -55.70599236745859,
                -17.342675239814778
              ],
              [
                -55.70082390086368,
                -17.353688694665816
              ],
              [
                -55.695389439369386,
                -17.344155224280797
              ],
              [
                -55.69270700021443,
                -17.3394502304251
              ],
              [
                -55.68873902363335,
                -17.340004692249572
              ],
              [
                -55.68630149481111,
                -17.345713679174953
              ],
              [
                -55.68448503368869,
                -17.34996714676747
              ],
              [
                -55.68309105597338,
                -17.346313658684682
              ],
              [
                -55.680126046979716,
                -17.338544185037087
              ],
              [
                -55.674661605394164,
                -17.33990369576181
              ],
              [
                -55.67234261048696,
                -17.34832265056137
              ],
              [
                -55.67228609231902,
                -17.348527632558156
              ],
              [
                -55.667583105605914,
                -17.350246612652676
              ],
              [
                -55.666765132526436,
                -17.349365600333027
              ],
              [
                -55.65604473531571,
                -17.33782213937219
              ],
              [
                -55.65460222546146,
                -17.347361604603073
              ],
              [
                -55.65033124280214,
                -17.352437073627716
              ],
              [
                -55.64520474921435,
                -17.358529548243492
              ],
              [
                -55.64171078799074,
                -17.354049026451634
              ],
              [
                -55.63959982460709,
                -17.351341056626747
              ],
              [
                -55.64471131720331,
                -17.34118658909825
              ],
              [
                -55.64076285697418,
                -17.33896710966683
              ],
              [
                -55.63537534588783,
                -17.35260704981357
              ],
              [
                -55.63220736261392,
                -17.356246001618626
              ],
              [
                -55.625816897262496,
                -17.363588480818134
              ],
              [
                -55.61326243658095,
                -17.36766492248143
              ],
              [
                -55.613025468678096,
                -17.362280933592892
              ],
              [
                -55.612898500256925,
                -17.35941247537328
              ],
              [
                -55.60573302405828,
                -17.36457490482811
              ],
              [
                -55.60276652646701,
                -17.366712378454014
              ],
              [
                -55.59276608604854,
                -17.373917842249732
              ],
              [
                -55.59266907073365,
                -17.373908330049073
              ],
              [
                -55.58508716525674,
                -17.373173350334483
              ],
              [
                -55.58881961511534,
                -17.377915806592522
              ],
              [
                -55.58238563593434,
                -17.37863128660472
              ],
              [
                -55.58539910078006,
                -17.388161246892274
              ],
              [
                -55.58312411592711,
                -17.394961229569255
              ],
              [
                -55.58567756100481,
                -17.397394720654088
              ],
              [
                -55.58607505928295,
                -17.397772734048313
              ],
              [
                -55.58549859459766,
                -17.39799722366955
              ],
              [
                -55.5793491189383,
                -17.40040017725861
              ],
              [
                -55.58423406574102,
                -17.4022462156164
              ],
              [
                -55.58607303836329,
                -17.402940683259985
              ],
              [
                -55.588122521280596,
                -17.413374652388054
              ],
              [
                -55.5849305094838,
                -17.422082122765886
              ],
              [
                -55.575167083595545,
                -17.41848661462597
              ],
              [
                -55.574817584321075,
                -17.41835761330029
              ],
              [
                -55.57479560046795,
                -17.419099601118738
              ],
              [
                -55.57434955865974,
                -17.43422405228765
              ],
              [
                -55.56773612241197,
                -17.434683532888787
              ],
              [
                -55.56349161590066,
                -17.440009976881903
              ],
              [
                -55.5622631458763,
                -17.43978198378371
              ],
              [
                -55.559209172193775,
                -17.439213991573304
              ],
              [
                -55.54909620325652,
                -17.447638437893808
              ],
              [
                -55.554950142083506,
                -17.447389447522262
              ],
              [
                -55.55534565743746,
                -17.44737246057888
              ],
              [
                -55.55684713218394,
                -17.451453416127478
              ],
              [
                -55.54691920328755,
                -17.454587378191817
              ],
              [
                -55.54221572529337,
                -17.456072366216983
              ],
              [
                -55.54267723588292,
                -17.458389870602165
              ],
              [
                -55.54304571736967,
                -17.460239844726253
              ],
              [
                -55.540398752872804,
                -17.460431841321256
              ],
              [
                -55.538039262473575,
                -17.46060385171695
              ],
              [
                -55.53754974436282,
                -17.46298581046076
              ],
              [
                -55.536510739751066,
                -17.46804379556136
              ],
              [
                -55.533614788615765,
                -17.466512819792907
              ],
              [
                -55.532739255829945,
                -17.46605031155945
              ],
              [
                -55.521775820045136,
                -17.472132275602398
              ],
              [
                -55.52191334932458,
                -17.47279126716816
              ],
              [
                -55.523566798555215,
                -17.480734228823213
              ],
              [
                -55.51451133562682,
                -17.483179683934036
              ],
              [
                -55.50401093632901,
                -17.48054015487302
              ],
              [
                -55.503819415659045,
                -17.48607017108502
              ],
              [
                -55.4988199672624,
                -17.482551669449233
              ],
              [
                -55.497096466462104,
                -17.486077629286065
              ],
              [
                -55.48957352048563,
                -17.484696627348747
              ],
              [
                -55.488629028265535,
                -17.483832125639452
              ],
              [
                -55.48252260902786,
                -17.478243156991653
              ],
              [
                -55.47626059860476,
                -17.4838305948096
              ],
              [
                -55.472122649714656,
                -17.480915602794767
              ],
              [
                -55.458176201651284,
                -17.4935470327206
              ],
              [
                -55.45798620450174,
                -17.49924948118354
              ],
              [
                -55.45326525580251,
                -17.49995496943066
              ],
              [
                -55.45329424156687,
                -17.50181446465615
              ],
              [
                -55.45337822528982,
                -17.50724043197158
              ],
              [
                -55.443215767550974,
                -17.511333405341425
              ],
              [
                -55.44337428967631,
                -17.506091437859002
              ],
              [
                -55.43220637080128,
                -17.511297875018563
              ],
              [
                -55.42544241261217,
                -17.509958867795653
              ],
              [
                -55.422819946925564,
                -17.50943988644484
              ],
              [
                -55.42256344031436,
                -17.510680872564482
              ],
              [
                -55.422066911199494,
                -17.51308135318929
              ],
              [
                -55.41584446691074,
                -17.51447733176871
              ],
              [
                -55.41115349982595,
                -17.51341633754626
              ],
              [
                -55.4046845539804,
                -17.511952795591824
              ],
              [
                -55.39290160256156,
                -17.51561528472414
              ],
              [
                -55.39178160928041,
                -17.517805269675115
              ],
              [
                -55.39021264368993,
                -17.520872247799808
              ],
              [
                -55.38666265112082,
                -17.518964728004587
              ],
              [
                -55.386099641788356,
                -17.5186622578785
              ],
              [
                -55.385758661743495,
                -17.519169731882965
              ],
              [
                -55.38181466981005,
                -17.525032222264386
              ],
              [
                -55.37971268256076,
                -17.5208327317705
              ],
              [
                -55.376593222772165,
                -17.514599748342512
              ],
              [
                -55.37138275270295,
                -17.522235678058806
              ],
              [
                -55.35809735129397,
                -17.526018639918554
              ],
              [
                -55.355668849916725,
                -17.528596144958122
              ],
              [
                -55.35324084153501,
                -17.53117411499635
              ],
              [
                -55.35015235870494,
                -17.54811700864149
              ],
              [
                -55.33893592658497,
                -17.548065516848954
              ],
              [
                -55.33734694932302,
                -17.544798028701692
              ],
              [
                -55.32979146444336,
                -17.549067998775307
              ],
              [
                -55.32737201995092,
                -17.54687548701047
              ],
              [
                -55.32198253508667,
                -17.541991511225493
              ],
              [
                -55.31539958250406,
                -17.545548479740777
              ],
              [
                -55.29915470775086,
                -17.540748445529772
              ],
              [
                -55.29214876305555,
                -17.542105414304206
              ],
              [
                -55.29601468735004,
                -17.55067140066839
              ],
              [
                -55.283315289512906,
                -17.55171486455377
              ],
              [
                -55.284518799616066,
                -17.553429867292348
              ],
              [
                -55.28904072916803,
                -17.559868823310453
              ],
              [
                -55.28226927335358,
                -17.55699632657009
              ],
              [
                -55.280264279122775,
                -17.56251282419868
              ],
              [
                -55.2750788073653,
                -17.563725289115006
              ],
              [
                -55.274954818225844,
                -17.562247297066158
              ],
              [
                -55.27462983093966,
                -17.558382304628502
              ],
              [
                -55.270738365235694,
                -17.559625307647938
              ],
              [
                -55.26868037726868,
                -17.556333800458653
              ],
              [
                -55.26253789350534,
                -17.56439324897251
              ],
              [
                -55.26656787763903,
                -17.565588277971088
              ],
              [
                -55.26515585386604,
                -17.571280747328455
              ],
              [
                -55.26361886157331,
                -17.577478195236928
              ],
              [
                -55.26044589036391,
                -17.575622694169727
              ],
              [
                -55.257566402674385,
                -17.57393972210696
              ],
              [
                -55.25357095110764,
                -17.57650318426527
              ],
              [
                -55.25493140742948,
                -17.57994618707669
              ],
              [
                -55.249789450498035,
                -17.584433656216966
              ],
              [
                -55.25001546176122,
                -17.585238656371374
              ],
              [
                -55.251876930982604,
                -17.591867621944814
              ],
              [
                -55.24440048769345,
                -17.590970595054365
              ],
              [
                -55.24876042613042,
                -17.59489311033909
              ],
              [
                -55.24201495793453,
                -17.60168254471515
              ],
              [
                -55.24281694669037,
                -17.593864074837974
              ],
              [
                -55.237709478913416,
                -17.600415559314158
              ],
              [
                -55.236297502493436,
                -17.59604056231816
              ],
              [
                -55.23609200963597,
                -17.595403055505486
              ],
              [
                -55.2314000605523,
                -17.59513504691175
              ],
              [
                -55.22737956706984,
                -17.599206509607
              ],
              [
                -55.21761912965935,
                -17.607072960863704
              ],
              [
                -55.21816360644897,
                -17.610188469022972
              ],
              [
                -55.218619594227604,
                -17.612795447117254
              ],
              [
                -55.22514904306726,
                -17.608880982036485
              ],
              [
                -55.220981586511456,
                -17.61665644566968
              ],
              [
                -55.210793643987856,
                -17.614616931820137
              ],
              [
                -55.20429269115407,
                -17.613316401069774
              ],
              [
                -55.20298169077504,
                -17.617499387059585
              ],
              [
                -55.19496075675578,
                -17.61826887573099
              ],
              [
                -55.19349423503781,
                -17.622601341694963
              ],
              [
                -55.19216372948142,
                -17.626530342710403
              ],
              [
                -55.18956827406872,
                -17.62441331918945
              ],
              [
                -55.18337731533272,
                -17.619362850115323
              ],
              [
                -55.18440479328188,
                -17.623770809993385
              ],
              [
                -55.180194851961986,
                -17.622755819764475
              ],
              [
                -55.17355137138091,
                -17.63018626862925
              ],
              [
                -55.17426737142282,
                -17.632037265571714
              ],
              [
                -55.1747963660572,
                -17.633407752084377
              ],
              [
                -55.172149885936896,
                -17.633519271987225
              ],
              [
                -55.1662929105129,
                -17.633765217284648
              ],
              [
                -55.168895373098614,
                -17.63579673018289
              ],
              [
                -55.171207864294814,
                -17.63760125031991
              ],
              [
                -55.170094344349096,
                -17.640390739704376
              ],
              [
                -55.166600894495325,
                -17.63740173363268
              ],
              [
                -55.16595340345341,
                -17.636847715361377
              ],
              [
                -55.16457788454315,
                -17.638817209565424
              ],
              [
                -55.162545936583946,
                -17.641726680139488
              ],
              [
                -55.16076794546563,
                -17.641483690718346
              ],
              [
                -55.14148754919343,
                -17.638844139654456
              ],
              [
                -55.14231952745004,
                -17.64472863876528
              ],
              [
                -55.138480050053026,
                -17.647224625561552
              ],
              [
                -55.13053660337015,
                -17.650643092165797
              ],
              [
                -55.126737125051136,
                -17.65227755371523
              ],
              [
                -55.11337624281982,
                -17.643517573463924
              ],
              [
                -55.112687746135315,
                -17.64146808644637
              ],
              [
                -55.10883483285253,
                -17.62999710654659
              ],
              [
                -55.097268922422366,
                -17.62816459042134
              ],
              [
                -55.09335940146251,
                -17.6347350697135
              ],
              [
                -55.092850410639016,
                -17.635590569803856
              ],
              [
                -55.09215093638322,
                -17.63488105407125
              ],
              [
                -55.086818485794765,
                -17.62947258973783
              ],
              [
                -55.079433506913894,
                -17.63304806207149
              ],
              [
                -55.0721310692534,
                -17.631187036736215
              ],
              [
                -55.066598605909235,
                -17.637970499046844
              ],
              [
                -55.063299091631805,
                -17.64201596558641
              ],
              [
                -55.06198612493942,
                -17.638609481797417
              ],
              [
                -55.055956155403074,
                -17.641945445527373
              ],
              [
                -55.0550781704741,
                -17.639362975322506
              ],
              [
                -55.05468216637389,
                -17.638196450297333
              ],
              [
                -55.05114321768612,
                -17.638956961214657
              ],
              [
                -55.04679521510313,
                -17.639890932371113
              ],
              [
                -55.0456847544087,
                -17.638178959883714
              ],
              [
                -55.044237759444805,
                -17.635947459768342
              ],
              [
                -55.039896300535766,
                -17.63735442592859
              ],
              [
                -55.031537820778325,
                -17.640062415508826
              ],
              [
                -55.02649386391892,
                -17.635877417732388
              ],
              [
                -55.02254439376709,
                -17.637487908004136
              ],
              [
                -55.022522430007974,
                -17.63487892406549
              ],
              [
                -55.02251739856835,
                -17.63431741344545
              ],
              [
                -55.02144840182772,
                -17.634725881034704
              ],
              [
                -55.01843545247537,
                -17.6358753696103
              ],
              [
                -55.01766145100482,
                -17.633963889822827
              ],
              [
                -55.015797990345604,
                -17.629364406772847
              ],
              [
                -55.012272504772206,
                -17.63263389203267
              ],
              [
                -55.01076348937672,
                -17.63403289630065
              ],
              [
                -55.01057698059831,
                -17.632215901580338
              ],
              [
                -55.010236027966,
                -17.628895884882795
              ],
              [
                -55.005776521918406,
                -17.63103089164551
              ],
              [
                -54.99131661184852,
                -17.637952812021073
              ],
              [
                -54.99226612103169,
                -17.632528321527847
              ],
              [
                -54.98864913588453,
                -17.632564351344236
              ],
              [
                -54.98556517386377,
                -17.62604287647085
              ],
              [
                -54.9844257102651,
                -17.62363286191205
              ],
              [
                -54.983088717606904,
                -17.625431836898663
              ],
              [
                -54.98095522853543,
                -17.628302350324546
              ],
              [
                -54.97849123540662,
                -17.6242968532714
              ],
              [
                -54.97375730294357,
                -17.616602367312648
              ],
              [
                -54.96695431878825,
                -17.619957818018037
              ],
              [
                -54.965874368796996,
                -17.620490343050424
              ],
              [
                -54.965982333499696,
                -17.619587840174
              ],
              [
                -54.96651485986639,
                -17.61514737046717
              ],
              [
                -54.95387392756258,
                -17.614982334657284
              ],
              [
                -54.94782096356461,
                -17.614902820908643
              ],
              [
                -54.94536401508824,
                -17.611744310285204
              ],
              [
                -54.94290603261742,
                -17.608585822662818
              ],
              [
                -54.90660330995506,
                -17.609845229843707
              ],
              [
                -54.90202579326165,
                -17.614026703544926
              ],
              [
                -54.894313344478014,
                -17.621072143174814
              ],
              [
                -54.88011947218882,
                -17.616426149709717
              ],
              [
                -54.86035010099454,
                -17.623409084558826
              ],
              [
                -54.85354616953686,
                -17.61547910760174
              ],
              [
                -54.85106864949574,
                -17.61259061781783
              ],
              [
                -54.8469677020623,
                -17.61288858933349
              ],
              [
                -54.84380524332279,
                -17.613118107478606
              ],
              [
                -54.83859174037511,
                -17.613496577880753
              ],
              [
                -54.824485864541714,
                -17.609232552077906
              ],
              [
                -54.819681930895996,
                -17.602909572270097
              ],
              [
                -54.81990741060506,
                -17.601700062023202
              ],
              [
                -54.820795446314634,
                -17.596943611652154
              ],
              [
                -54.800236623602274,
                -17.5821531141173
              ],
              [
                -54.79390962598647,
                -17.58194261231315
              ],
              [
                -54.791069163917136,
                -17.581847085671907
              ],
              [
                -54.777569801247886,
                -17.570792143723278
              ],
              [
                -54.75826593093612,
                -17.565253125695328
              ],
              [
                -54.75964646172458,
                -17.55992460906597
              ],
              [
                -54.75648448839438,
                -17.558158629836118
              ],
              [
                -54.76165496283601,
                -17.553972666679996
              ],
              [
                -54.7575039802744,
                -17.547779178079743
              ],
              [
                -54.75546151462709,
                -17.544732699149648
              ],
              [
                -54.75648653726009,
                -17.534049725733823
              ],
              [
                -54.74844009421522,
                -17.522443764875288
              ],
              [
                -54.736148700486964,
                -17.51757427049585
              ],
              [
                -54.729569782771854,
                -17.515087235970494
              ],
              [
                -54.72513077355621,
                -17.516201727188395
              ],
              [
                -54.71829135637023,
                -17.51791920286969
              ],
              [
                -54.714950901547766,
                -17.51269874616229
              ],
              [
                -54.700070490079284,
                -17.505868716923004
              ],
              [
                -54.699777489247445,
                -17.502834731314582
              ],
              [
                -54.69920804100836,
                -17.496936764407913
              ],
              [
                -54.69589704398408,
                -17.501783756279277
              ],
              [
                -54.69501356364535,
                -17.503077224506022
              ],
              [
                -54.680796642369806,
                -17.50640468702282
              ],
              [
                -54.67623768027786,
                -17.501081683796695
              ],
              [
                -54.66893174572655,
                -17.503790682047327
              ],
              [
                -54.65844629971253,
                -17.497746656337753
              ],
              [
                -54.65621385665815,
                -17.49646015640149
              ],
              [
                -54.65448334673367,
                -17.4897157173398
              ],
              [
                -54.63969693776507,
                -17.49406665204019
              ],
              [
                -54.6355689621839,
                -17.49528164059931
              ],
              [
                -54.62853303817716,
                -17.491875650015075
              ],
              [
                -54.6201006196842,
                -17.487794133928688
              ],
              [
                -54.618061607211686,
                -17.489154626005938
              ],
              [
                -54.616821610616405,
                -17.4899810993671
              ],
              [
                -54.609179198195626,
                -17.486441094890992
              ],
              [
                -54.58810086481638,
                -17.476677617360853
              ],
              [
                -54.581151940483494,
                -17.46756161885196
              ],
              [
                -54.57181649015075,
                -17.47514356895052
              ],
              [
                -54.56750902752744,
                -17.47386409591174
              ],
              [
                -54.5678080165372,
                -17.476451076407784
              ],
              [
                -54.568516485233445,
                -17.482579562529118
              ],
              [
                -54.56253502212571,
                -17.478730526530086
              ],
              [
                -54.553675571390464,
                -17.488224493476853
              ],
              [
                -54.538389715114754,
                -17.478736985101033
              ],
              [
                -54.53581874226999,
                -17.47714146771556
              ],
              [
                -54.53025124707372,
                -17.479369475332984
              ],
              [
                -54.52468428187719,
                -17.481597942948067
              ],
              [
                -54.51865834728299,
                -17.481054951489398
              ],
              [
                -54.50323996436674,
                -17.47966590862046
              ],
              [
                -54.49623899472172,
                -17.48431485216968
              ],
              [
                -54.49223752150521,
                -17.486971329765364
              ],
              [
                -54.490429535895835,
                -17.488171853092688
              ],
              [
                -54.48079759955205,
                -17.48722083054769
              ],
              [
                -54.482543570552416,
                -17.49352327839894
              ],
              [
                -54.47371711931997,
                -17.49754673716832
              ],
              [
                -54.47836407395283,
                -17.50614423917378
              ],
              [
                -54.47903956290295,
                -17.50739420539601
              ],
              [
                -54.47681657602035,
                -17.508283197054734
              ],
              [
                -54.47287807826262,
                -17.50985817950426
              ],
              [
                -54.47468059101552,
                -17.51123519794801
              ],
              [
                -54.47709856622539,
                -17.51308070686939
              ],
              [
                -54.47177506763464,
                -17.52081863008642
              ],
              [
                -54.46640211518852,
                -17.528630096859498
              ],
              [
                -54.43771477082549,
                -17.533655021306622
              ],
              [
                -54.433538322007955,
                -17.534386516831987
              ],
              [
                -54.42848384508103,
                -17.538942958535745
              ],
              [
                -54.420567892707176,
                -17.537306979158082
              ],
              [
                -54.413484936938566,
                -17.544108905075603
              ],
              [
                -54.41320393722455,
                -17.551081890010035
              ],
              [
                -54.40543447589701,
                -17.55316236379497
              ],
              [
                -54.40340447442567,
                -17.563105807479765
              ],
              [
                -54.403201972183155,
                -17.564096822664432
              ],
              [
                -54.402366441263325,
                -17.564378316377635
              ],
              [
                -54.38243009153421,
                -17.571086720979597
              ],
              [
                -54.38095759669103,
                -17.57523768426266
              ],
              [
                -54.38232308115199,
                -17.57896520039464
              ],
              [
                -54.38489807263892,
                -17.576923192643537
              ],
              [
                -54.38573802974568,
                -17.584759155590294
              ],
              [
                -54.38582104707485,
                -17.585538172403027
              ],
              [
                -54.381862547600306,
                -17.59080311970365
              ],
              [
                -54.379678059062456,
                -17.5937091006557
              ],
              [
                -54.382966014656446,
                -17.59908208838607
              ],
              [
                -54.38409100485781,
                -17.600920076165394
              ],
              [
                -54.383199008336526,
                -17.60082860235952
              ],
              [
                -54.380983037433914,
                -17.600600605876703
              ],
              [
                -54.38206099396671,
                -17.605786580968054
              ],
              [
                -54.37390455466767,
                -17.608131521602935
              ],
              [
                -54.38728541231507,
                -17.63149195006989
              ],
              [
                -54.35484257057137,
                -17.65026230311598
              ],
              [
                -54.35006811672159,
                -17.656661285456458
              ],
              [
                -54.342930651778886,
                -17.655129263441363
              ],
              [
                -54.33507070619888,
                -17.661278231218343
              ],
              [
                -54.32729177948832,
                -17.658363700633057
              ],
              [
                -54.31948479262254,
                -17.661891167942777
              ],
              [
                -54.3087644100716,
                -17.658401166589716
              ],
              [
                -54.30190892249366,
                -17.661213636365925
              ],
              [
                -54.29639896337605,
                -17.656647631615012
              ],
              [
                -54.291459035995715,
                -17.652553170230288
              ],
              [
                -54.28800056538034,
                -17.65509261560422
              ],
              [
                -54.28781756729991,
                -17.655227110565157
              ],
              [
                -54.269351679314376,
                -17.651640089513855
              ],
              [
                -54.2498453491817,
                -17.640051601847208
              ],
              [
                -54.24653937893377,
                -17.63425511398585
              ],
              [
                -54.2432329196833,
                -17.628458656133382
              ],
              [
                -54.221709089704774,
                -17.619475648254006
              ],
              [
                -54.216965643578945,
                -17.619621140912997
              ],
              [
                -54.213484687705126,
                -17.61972811385615
              ],
              [
                -54.207278729379595,
                -17.614828145978084
              ],
              [
                -54.20415026295168,
                -17.612357123073046
              ],
              [
                -54.202080754460404,
                -17.613106132692227
              ],
              [
                -54.19931827415818,
                -17.61410613450396
              ],
              [
                -54.19714678871713,
                -17.611747620470258
              ],
              [
                -54.191120869544335,
                -17.605201124270955
              ],
              [
                -54.17832246984476,
                -17.602405634974733
              ],
              [
                -54.172988511081776,
                -17.605093113060207
              ],
              [
                -54.16765504832078,
                -17.60778005014736
              ],
              [
                -54.163384074234564,
                -17.607059581780163
              ],
              [
                -54.15370611755942,
                -17.605427039091705
              ],
              [
                -54.150137648978564,
                -17.60977203725604
              ],
              [
                -54.146206180763926,
                -17.614558474589888
              ],
              [
                -54.14224717806592,
                -17.611387994732265
              ],
              [
                -54.14079523137873,
                -17.610225487251718
              ],
              [
                -54.139220227667955,
                -17.612007464558918
              ],
              [
                -54.133675727809575,
                -17.618279940397514
              ],
              [
                -54.12243232755437,
                -17.612775954832372
              ],
              [
                -54.11809286918627,
                -17.618696916171068
              ],
              [
                -54.116235367780426,
                -17.6155094055769
              ],
              [
                -54.11337239825171,
                -17.618364913997294
              ],
              [
                -54.111455424883694,
                -17.613079429464364
              ],
              [
                -54.09787651618828,
                -17.613134867781227
              ],
              [
                -54.084183602866226,
                -17.618588850118066
              ],
              [
                -54.0765271524923,
                -17.614564330939906
              ],
              [
                -54.078923165064445,
                -17.604317392850334
              ],
              [
                -54.072151226083975,
                -17.596319918336356
              ],
              [
                -54.072351245556014,
                -17.593372917782034
              ],
              [
                -54.072759750770075,
                -17.587353945246623
              ],
              [
                -54.051124479777364,
                -17.562325028231413
              ],
              [
                -54.0515705228134,
                -17.54575260530983
              ],
              [
                -54.050950497576714,
                -17.544851098761054
              ],
              [
                -54.04539757213683,
                -17.536779601716173
              ],
              [
                -54.04890105187353,
                -17.529497145395446
              ],
              [
                -54.05079555508307,
                -17.529295641923955
              ],
              [
                -54.05495550999844,
                -17.52885366106136
              ],
              [
                -54.05268406219677,
                -17.522756706370664
              ],
              [
                -54.05725953034518,
                -17.520377239062103
              ],
              [
                -54.05756906963151,
                -17.512681742743492
              ],
              [
                -54.05145609137011,
                -17.50525175155012
              ],
              [
                -54.048553625306816,
                -17.50618427733704
              ],
              [
                -54.04075169837936,
                -17.500088278150578
              ],
              [
                -54.040739213493765,
                -17.50007826816424
              ],
              [
                -54.040732717604506,
                -17.500053785256394
              ],
              [
                -54.03712075832414,
                -17.485996826475073
              ],
              [
                -54.030872816089776,
                -17.481920852212205
              ],
              [
                -54.02462435786265,
                -17.47784384796312
              ],
              [
                -53.99448608819627,
                -17.46794978715434
              ],
              [
                -53.98976362822179,
                -17.47029980824007
              ],
              [
                -53.9835926675425,
                -17.473370269603485
              ],
              [
                -53.977324219874404,
                -17.46990125066352
              ],
              [
                -53.9725417345381,
                -17.471797223609126
              ],
              [
                -53.970762763073814,
                -17.465373763703774
              ],
              [
                -53.951739930240706,
                -17.458890761053045
              ],
              [
                -53.94958345503421,
                -17.45526124688111
              ],
              [
                -53.94742699982259,
                -17.45163278270862
              ],
              [
                -53.94301553365788,
                -17.448322796638276
              ],
              [
                -53.93059311982876,
                -17.439002785733695
              ],
              [
                -53.928864645871485,
                -17.433374341499228
              ],
              [
                -53.9280626604169,
                -17.430762838136417
              ],
              [
                -53.923551201139006,
                -17.42776081648964
              ],
              [
                -53.919041738842,
                -17.4247608508441
              ],
              [
                -53.917450249217055,
                -17.421289354388815
              ],
              [
                -53.91409281318417,
                -17.413969359738527
              ],
              [
                -53.898547957344505,
                -17.401230913600656
              ],
              [
                -53.887480577265165,
                -17.392161414081794
              ],
              [
                -53.88397010386053,
                -17.38576042204681
              ],
              [
                -53.87793165038774,
                -17.374751988408093
              ],
              [
                -53.86787624923727,
                -17.36793146642717
              ],
              [
                -53.85750783420327,
                -17.36419497297432
              ],
              [
                -53.830818032819636,
                -17.354575964292927
              ],
              [
                -53.83263704722213,
                -17.34494501165753
              ],
              [
                -53.83445557161243,
                -17.335314540026825
              ],
              [
                -53.81956629130916,
                -17.294167692899553
              ],
              [
                -53.806129380537996,
                -17.28751619227766
              ],
              [
                -53.79739746368701,
                -17.287329680086014
              ],
              [
                -53.785210035448245,
                -17.28706965990775
              ],
              [
                -53.77062268868847,
                -17.26320824268824
              ],
              [
                -53.76376275116002,
                -17.2578297403106
              ],
              [
                -53.76319629508797,
                -17.25324677249693
              ],
              [
                -53.76232079671286,
                -17.24615780418329
              ],
              [
                -53.758573338190445,
                -17.242697291708172
              ],
              [
                -53.74902391570888,
                -17.242300302568555
              ],
              [
                -53.74650943375272,
                -17.242195795002832
              ],
              [
                -53.7338405462882,
                -17.231425298959984
              ],
              [
                -53.70814975756702,
                -17.22772377900044
              ],
              [
                -53.6939908296433,
                -17.23353771793018
              ],
              [
                -53.691953287652254,
                -17.2460186419428
              ],
              [
                -53.685504338677,
                -17.249719111842328
              ],
              [
                -53.67956989383059,
                -17.253125104296714
              ],
              [
                -53.70564870995777,
                -17.661944277034547
              ],
              [
                -53.719541085315875,
                -17.66836379568175
              ],
              [
                -53.72821652375043,
                -17.664650309451307
              ],
              [
                -53.7331769902704,
                -17.66252732904407
              ],
              [
                -53.736676466518254,
                -17.664755335765715
              ],
              [
                -53.746237384502464,
                -17.670842333290224
              ],
              [
                -53.7638187463103,
                -17.67146136676317
              ],
              [
                -53.7789576486863,
                -17.67199391062695
              ],
              [
                -53.784982089523716,
                -17.67592188806569
              ],
              [
                -53.79832099372492,
                -17.670087449677183
              ],
              [
                -53.80582147372719,
                -17.671925942996374
              ],
              [
                -53.81289889632815,
                -17.681008942265095
              ],
              [
                -53.84065515340953,
                -17.691301955159595
              ],
              [
                -53.84478463097105,
                -17.694373447779576
              ],
              [
                -53.855399022735924,
                -17.70226943111148
              ],
              [
                -53.85855746706151,
                -17.713570408073547
              ],
              [
                -53.86042844985111,
                -17.720263381190588
              ],
              [
                -53.8700113662185,
                -17.72691784153108
              ],
              [
                -53.87651380811282,
                -17.731433364705225
              ],
              [
                -53.87795778213926,
                -17.736178837378834
              ],
              [
                -53.87901876626131,
                -17.73966380565927
              ],
              [
                -53.88261474413754,
                -17.743270301674542
              ],
              [
                -53.887873178771315,
                -17.748544807467947
              ],
              [
                -53.89596057885614,
                -17.76886172713596
              ],
              [
                -53.89547355689094,
                -17.772719230974545
              ],
              [
                -53.894986560923336,
                -17.776576675814923
              ],
              [
                -53.90156048443867,
                -17.78399619450389
              ],
              [
                -53.90676943975479,
                -17.789874650597596
              ],
              [
                -53.9100243782663,
                -17.79967662163514
              ],
              [
                -53.91219438426543,
                -17.806210617342067
              ],
              [
                -53.91406035513337,
                -17.80840961132389
              ],
              [
                -53.9182337963382,
                -17.813328573104155
              ],
              [
                -53.940656084362594,
                -17.839755026991952
              ],
              [
                -53.94112605919556,
                -17.84817800127392
              ],
              [
                -53.94773446928967,
                -17.855632969086468
              ],
              [
                -53.94638498255142,
                -17.85941494017381
              ],
              [
                -53.946177462436545,
                -17.859996960109882
              ],
              [
                -53.94871747656372,
                -17.863286414040935
              ],
              [
                -53.95350241493761,
                -17.86948240885453
              ],
              [
                -53.951815894616566,
                -17.874718400749607
              ],
              [
                -53.95524137606825,
                -17.882572363944575
              ],
              [
                -53.94726139053852,
                -17.898334756130946
              ],
              [
                -53.95062182879426,
                -17.902744257245942
              ],
              [
                -53.945063361353164,
                -17.906308244795156
              ],
              [
                -53.94415389419029,
                -17.906891241976812
              ],
              [
                -53.94493385511416,
                -17.907833221807337
              ],
              [
                -53.95115531570385,
                -17.91534168453817
              ],
              [
                -53.9502293212371,
                -17.917912671994632
              ],
              [
                -53.94840680130815,
                -17.922975674326917
              ],
              [
                -53.945093863890925,
                -17.916308185175467
              ],
              [
                -53.93804288563502,
                -17.919637663015898
              ],
              [
                -53.93348243885437,
                -17.914503171064066
              ],
              [
                -53.93190244986549,
                -17.912724660892643
              ],
              [
                -53.92928397141319,
                -17.914544135401226
              ],
              [
                -53.92275349919998,
                -17.919083604249664
              ],
              [
                -53.91275858478048,
                -17.914706600432194
              ],
              [
                -53.910789612050095,
                -17.91384412428922
              ],
              [
                -53.90720113192395,
                -17.91476111832065
              ],
              [
                -53.902570649443604,
                -17.915944065587304
              ],
              [
                -53.903282655524755,
                -17.91480010536906
              ],
              [
                -53.9046261449076,
                -17.912640128171837
              ],
              [
                -53.898041187138865,
                -17.908355092489284
              ],
              [
                -53.884929768669025,
                -17.912837551156013
              ],
              [
                -53.8793228189389,
                -17.91959300059259
              ],
              [
                -53.87710132036884,
                -17.92226849233635
              ],
              [
                -53.87405934722708,
                -17.921778476888576
              ]
            ]
          ],
          [
            [
              [
                -53.872176867150266,
                -17.916416019668798
              ],
              [
                -53.87181987205658,
                -17.92141749687943
              ],
              [
                -53.874960868109284,
                -17.918378026025334
              ],
              [
                -53.872176867150266,
                -17.916416019668798
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 13,
        "nome": "Mato Grosso",
        "sigla": "MT",
        "regiao_id": 5,
        "codigo_ibg": 51
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -60.360833078775364,
                -13.299324293144378
              ],
              [
                -60.36032954467816,
                -13.298893290872714
              ],
              [
                -60.35315963025992,
                -13.29275181127183
              ],
              [
                -60.351526178673616,
                -13.275388872548758
              ],
              [
                -60.35126365412695,
                -13.272598892363423
              ],
              [
                -60.35046518995926,
                -13.272556386918197
              ],
              [
                -60.3384897634374,
                -13.271922871228886
              ],
              [
                -60.3326552964852,
                -13.257528411006819
              ],
              [
                -60.32515986395976,
                -13.252343940955548
              ],
              [
                -60.32782490399848,
                -13.23056401709191
              ],
              [
                -60.327908918937624,
                -13.229880540047454
              ],
              [
                -60.327188910571635,
                -13.22971152128225
              ],
              [
                -60.323817439266676,
                -13.228922527689926
              ],
              [
                -60.320795971730064,
                -13.219172086349648
              ],
              [
                -60.32028797019462,
                -13.217533069009544
              ],
              [
                -60.31897199606472,
                -13.215606591212756
              ],
              [
                -60.307683591868326,
                -13.19908014390253
              ],
              [
                -60.30833560694857,
                -13.194811635645095
              ],
              [
                -60.308784073435895,
                -13.191878165527301
              ],
              [
                -60.30362612649828,
                -13.178805713547582
              ],
              [
                -60.29910470129331,
                -13.175026730898352
              ],
              [
                -60.29782917554806,
                -13.17566120574023
              ],
              [
                -60.29562418635872,
                -13.176759185818204
              ],
              [
                -60.267962429208055,
                -13.144564779998996
              ],
              [
                -60.27578187929745,
                -13.138526846407075
              ],
              [
                -60.28007336169159,
                -13.135211838585587
              ],
              [
                -60.275604907604766,
                -13.126421370639074
              ],
              [
                -60.2804664080329,
                -13.119541937559587
              ],
              [
                -60.27657693232093,
                -13.11226692458727
              ],
              [
                -60.28182591066257,
                -13.10575645877157
              ],
              [
                -60.27863493412149,
                -13.102160977129053
              ],
              [
                -60.28350690131884,
                -13.097755015930879
              ],
              [
                -60.281974927350355,
                -13.092984519427663
              ],
              [
                -60.28244947321162,
                -13.080146583024352
              ],
              [
                -60.26888656585326,
                -13.077263585784683
              ],
              [
                -60.26707555042425,
                -13.076878582751267
              ],
              [
                -60.25683517354855,
                -13.061838119091194
              ],
              [
                -60.252826699602586,
                -13.055950129296587
              ],
              [
                -60.25209368433995,
                -13.055768622582432
              ],
              [
                -60.247801246082304,
                -13.054706117399968
              ],
              [
                -60.24416778058632,
                -13.04607267665872
              ],
              [
                -60.24213727665675,
                -13.041248199458224
              ],
              [
                -60.23695834743677,
                -13.034501705910438
              ],
              [
                -60.2257309137492,
                -13.033302199526046
              ],
              [
                -60.22415241049535,
                -13.033133694081917
              ],
              [
                -60.22263591960771,
                -13.031158688198278
              ],
              [
                -60.219677455184765,
                -13.027306700179738
              ],
              [
                -60.218763470907966,
                -13.027170686923967
              ],
              [
                -60.21474948304156,
                -13.026574700404005
              ],
              [
                -60.21382601181543,
                -13.015306238990757
              ],
              [
                -60.21311604391394,
                -13.006646775266743
              ],
              [
                -60.20703410193046,
                -12.998986301786548
              ],
              [
                -60.20403311459457,
                -12.99520631542677
              ],
              [
                -60.202944603402116,
                -12.99262231992779
              ],
              [
                -60.20087662783316,
                -12.987712329086383
              ],
              [
                -60.197230193419855,
                -12.985348841617714
              ],
              [
                -60.19152522001765,
                -12.981650830586306
              ],
              [
                -60.190174741931855,
                -12.97636885271951
              ],
              [
                -60.18873574408412,
                -12.970738867120513
              ],
              [
                -60.182804305651665,
                -12.967223383912458
              ],
              [
                -60.16962337307664,
                -12.96971834254506
              ],
              [
                -60.16687637838227,
                -12.970238362964967
              ],
              [
                -60.15601194905243,
                -12.968342346326457
              ],
              [
                -60.15423745452277,
                -12.966936330633368
              ],
              [
                -60.15009102199993,
                -12.963650353030406
              ],
              [
                -60.146250004430456,
                -12.965829809426024
              ],
              [
                -60.13925305701046,
                -12.969799311177571
              ],
              [
                -60.133208123939234,
                -12.964023316134885
              ],
              [
                -60.13032162682584,
                -12.961264802851858
              ],
              [
                -60.127316650709794,
                -12.960800303840587
              ],
              [
                -60.11662571790361,
                -12.959148289572596
              ],
              [
                -60.10459936882818,
                -12.91905995704819
              ],
              [
                -60.08599352034015,
                -12.898300016650259
              ],
              [
                -60.082282051425565,
                -12.889518057851236
              ],
              [
                -60.07857059449835,
                -12.880736079072168
              ],
              [
                -60.0841571363165,
                -12.836899789366635
              ],
              [
                -60.077244751006596,
                -12.803653420335122
              ],
              [
                -60.07655628278914,
                -12.79887592690453
              ],
              [
                -60.07553379195353,
                -12.786982984493806
              ],
              [
                -60.07431731995119,
                -12.772763055434558
              ],
              [
                -60.08026930647211,
                -12.759358115935974
              ],
              [
                -60.0850178266221,
                -12.748202148714304
              ],
              [
                -60.08848331234365,
                -12.74006018742299
              ],
              [
                -60.08947282877182,
                -12.731948233204063
              ],
              [
                -60.087849356264115,
                -12.72160779223245
              ],
              [
                -60.084510377644904,
                -12.712427307111147
              ],
              [
                -60.07564948543321,
                -12.688062883813473
              ],
              [
                -60.0765965057451,
                -12.676104467664581
              ],
              [
                -60.07754302003327,
                -12.66414749952328
              ],
              [
                -60.070654561747816,
                -12.653346058457167
              ],
              [
                -60.062405662825846,
                -12.640412069745071
              ],
              [
                -60.06140718679624,
                -12.630886612744057
              ],
              [
                -60.06353765265092,
                -12.626087161960188
              ],
              [
                -60.06819614475968,
                -12.615594696902635
              ],
              [
                -60.05859471840511,
                -12.603875727609907
              ],
              [
                -60.041660361896234,
                -12.599663720786303
              ],
              [
                -60.039230352611085,
                -12.599059211662071
              ],
              [
                -60.03440088672429,
                -12.594901219859759
              ],
              [
                -60.02756698725455,
                -12.578940283825114
              ],
              [
                -60.02749195651319,
                -12.578028298514711
              ],
              [
                -60.02610353313222,
                -12.561227884494958
              ],
              [
                -60.02215006527107,
                -12.555918364268601
              ],
              [
                -60.01426461490255,
                -12.55060741154507
              ],
              [
                -60.01015763191972,
                -12.547841397341347
              ],
              [
                -60.00640417835292,
                -12.545313905813265
              ],
              [
                -60.000398711019415,
                -12.535822935321436
              ],
              [
                -59.972867410213006,
                -12.529962904505565
              ],
              [
                -59.95693306507809,
                -12.50181050497706
              ],
              [
                -59.9358441693644,
                -12.49466251108165
              ],
              [
                -59.935089695098924,
                -12.489471025597302
              ],
              [
                -59.934684226016635,
                -12.486680528626735
              ],
              [
                -59.924572267087356,
                -12.481325547075327
              ],
              [
                -59.9128658711825,
                -12.480312517140897
              ],
              [
                -59.90712738252999,
                -12.479816528349348
              ],
              [
                -59.90583589360391,
                -12.477609516242945
              ],
              [
                -59.90261844668564,
                -12.472108521434905
              ],
              [
                -59.89672547159206,
                -12.470784542867703
              ],
              [
                -59.89644947389045,
                -12.474529535479705
              ],
              [
                -59.89635797399887,
                -12.475771503045257
              ],
              [
                -59.88747004254073,
                -12.474610499105369
              ],
              [
                -59.86794913512364,
                -12.481961428919735
              ],
              [
                -59.85531123232107,
                -12.478192926927829
              ],
              [
                -59.84620727539842,
                -12.469297479412395
              ],
              [
                -59.84081335102217,
                -12.458967482049006
              ],
              [
                -59.841723877014225,
                -12.43269111638922
              ],
              [
                -59.8420093824228,
                -12.424446165968961
              ],
              [
                -59.83716292176947,
                -12.416118667224515
              ],
              [
                -59.834192477557856,
                -12.411014701327113
              ],
              [
                -59.83351996961843,
                -12.40792769218969
              ],
              [
                -59.832011500190774,
                -12.400997251830734
              ],
              [
                -59.8230130745365,
                -12.390045743494861
              ],
              [
                -59.822700101485886,
                -12.390009245061087
              ],
              [
                -59.81909608593507,
                -12.389588754067109
              ],
              [
                -59.81891913423818,
                -12.384330768125702
              ],
              [
                -59.818717626096905,
                -12.378346826234182
              ],
              [
                -59.80700470217718,
                -12.369724838954225
              ],
              [
                -59.80495870938857,
                -12.368218814529644
              ],
              [
                -59.80447924635903,
                -12.366628353409249
              ],
              [
                -59.80157325753144,
                -12.356991394045545
              ],
              [
                -59.79541933761148,
                -12.34919840674207
              ],
              [
                -59.79240233613957,
                -12.345378925382658
              ],
              [
                -59.773813455152705,
                -12.34055288414017
              ],
              [
                -59.831303704905665,
                -12.291539216993463
              ],
              [
                -59.88580845087303,
                -12.245070005963894
              ],
              [
                -59.89177841115788,
                -12.245053537152058
              ],
              [
                -59.893056916827646,
                -12.232412577461483
              ],
              [
                -59.89306243589559,
                -12.232360071695815
              ],
              [
                -59.89566392680093,
                -12.229510585694024
              ],
              [
                -59.901175400411965,
                -12.223475136696525
              ],
              [
                -59.90073442365542,
                -12.214832190760172
              ],
              [
                -59.908293899174275,
                -12.192024281158028
              ],
              [
                -59.908833912408106,
                -12.183952835626139
              ],
              [
                -59.90394794435172,
                -12.175225867851053
              ],
              [
                -59.89960198120557,
                -12.167464859969533
              ],
              [
                -59.9006985776628,
                -12.115041613227284
              ],
              [
                -59.91472204524084,
                -12.097449712361653
              ],
              [
                -59.916188006811986,
                -12.096745748073474
              ],
              [
                -59.923177467395924,
                -12.09338975130216
              ],
              [
                -59.92577350948181,
                -12.086227271734996
              ],
              [
                -59.92873649328896,
                -12.078052323177726
              ],
              [
                -59.94664690534645,
                -12.06535042545631
              ],
              [
                -59.947651364402994,
                -12.065319903431842
              ],
              [
                -59.951265888017225,
                -12.065209927541249
              ],
              [
                -59.95525883173744,
                -12.058049976513391
              ],
              [
                -59.95637086087359,
                -12.05605596708816
              ],
              [
                -59.9571198334246,
                -12.056042977518464
              ],
              [
                -59.96145830866052,
                -12.055964983815569
              ],
              [
                -59.96093183702399,
                -12.050042502193367
              ],
              [
                -59.96517733562911,
                -12.045839528968424
              ],
              [
                -59.96904080560795,
                -12.032073098973099
              ],
              [
                -59.97881523778405,
                -12.029150128390954
              ],
              [
                -59.97412229908871,
                -12.014705692634099
              ],
              [
                -59.97207882308007,
                -12.012696715498889
              ],
              [
                -59.96604337930869,
                -12.006763717875407
              ],
              [
                -59.970069850838655,
                -11.997448238147705
              ],
              [
                -59.97229886360624,
                -11.995817767206159
              ],
              [
                -59.97904083165869,
                -11.990886794647336
              ],
              [
                -59.97238488547873,
                -11.989244809529563
              ],
              [
                -59.96984488882165,
                -11.988617819579433
              ],
              [
                -59.968933893214704,
                -11.982269342129548
              ],
              [
                -59.97379039018603,
                -11.975914844230875
              ],
              [
                -59.97082241987559,
                -11.969548883114454
              ],
              [
                -59.97829289115016,
                -11.964836401936902
              ],
              [
                -59.98645283613508,
                -11.959688945873541
              ],
              [
                -59.982124361029314,
                -11.95486195568757
              ],
              [
                -59.98259538460124,
                -11.952528468552385
              ],
              [
                -59.983155879474886,
                -11.949749493491113
              ],
              [
                -59.98329038092828,
                -11.949080509605173
              ],
              [
                -59.98325839021488,
                -11.949029501765706
              ],
              [
                -59.979627391649686,
                -11.943291025774931
              ],
              [
                -59.98544338801194,
                -11.933660086671598
              ],
              [
                -59.98820487410973,
                -11.929087076311724
              ],
              [
                -59.98650339866634,
                -11.926201595602096
              ],
              [
                -59.98292842334728,
                -11.920139611127572
              ],
              [
                -59.984634409765206,
                -11.913945157816626
              ],
              [
                -59.99489785095975,
                -11.90891469703403
              ],
              [
                -60.0063488370361,
                -11.894283300600309
              ],
              [
                -60.01153930916412,
                -11.892640307058445
              ],
              [
                -60.01660326361996,
                -11.894438780518097
              ],
              [
                -60.02072224633733,
                -11.895901812707404
              ],
              [
                -60.0272381823707,
                -11.894905798792067
              ],
              [
                -60.029347184408365,
                -11.894582805000617
              ],
              [
                -60.02976169712841,
                -11.895016313892803
              ],
              [
                -60.03289766331378,
                -11.89829579651524
              ],
              [
                -60.03705215109734,
                -11.895336322737982
              ],
              [
                -60.0404276144882,
                -11.892930356173148
              ],
              [
                -60.04397459716942,
                -11.894638337281453
              ],
              [
                -60.049848544901,
                -11.8974658438143
              ],
              [
                -60.059982499912444,
                -11.894604376453872
              ],
              [
                -60.07284294702049,
                -11.884087456821678
              ],
              [
                -60.07609340417908,
                -11.88488942327715
              ],
              [
                -60.074369945871176,
                -11.879302478086522
              ],
              [
                -60.07637792874339,
                -11.874042490258184
              ],
              [
                -60.07798894003626,
                -11.869820989262012
              ],
              [
                -60.08221240880579,
                -11.868268022560327
              ],
              [
                -60.084895375686045,
                -11.867281022644676
              ],
              [
                -60.083335407839186,
                -11.865741038419134
              ],
              [
                -60.08004495147568,
                -11.86249155438918
              ],
              [
                -60.0827844363897,
                -11.858087053219187
              ],
              [
                -60.08570241169295,
                -11.860414068525333
              ],
              [
                -60.08796189589422,
                -11.862215068896534
              ],
              [
                -60.090597356991296,
                -11.860728082034637
              ],
              [
                -60.088897408236285,
                -11.854755591549045
              ],
              [
                -60.09206536413617,
                -11.852747599880452
              ],
              [
                -60.095710851824904,
                -11.858451074038422
              ],
              [
                -60.09334686394002,
                -11.848762112274159
              ],
              [
                -60.09832132607538,
                -11.845155669716435
              ],
              [
                -60.09736935034537,
                -11.839457173415733
              ],
              [
                -60.10059384145077,
                -11.835008701302526
              ],
              [
                -60.108231293278784,
                -11.839181697213332
              ],
              [
                -60.09918485400447,
                -11.830075719902469
              ],
              [
                -60.09873888649109,
                -11.829626725022356
              ],
              [
                -60.100691875541614,
                -11.824429749824153
              ],
              [
                -60.10281836941644,
                -11.81877027392209
              ],
              [
                -60.10111789279862,
                -11.818085285793227
              ],
              [
                -60.09724091175092,
                -11.816523286502266
              ],
              [
                -60.101065897382306,
                -11.814168799493157
              ],
              [
                -60.09837840819696,
                -11.803481829488986
              ],
              [
                -60.10214439605357,
                -11.802810365150254
              ],
              [
                -60.104129876908345,
                -11.802455848245886
              ],
              [
                -60.10231989484996,
                -11.800201343657877
              ],
              [
                -60.10024042401523,
                -11.79761137002614
              ],
              [
                -60.09619946134557,
                -11.799064350519055
              ],
              [
                -60.09774695232239,
                -11.79465389322899
              ],
              [
                -60.10275640230188,
                -11.79370439331622
              ],
              [
                -60.10795987619971,
                -11.792718406908218
              ],
              [
                -60.103005400679216,
                -11.790004393639743
              ],
              [
                -60.102350424237834,
                -11.78964541600211
              ],
              [
                -60.10329989567688,
                -11.786901933482316
              ],
              [
                -60.104961428692754,
                -11.782100951072652
              ],
              [
                -60.104254914448696,
                -11.781800444092374
              ],
              [
                -60.10054495142533,
                -11.780221446201857
              ],
              [
                -60.10395994955184,
                -11.777830480601533
              ],
              [
                -60.10490942902993,
                -11.778304948271114
              ],
              [
                -60.108618894553004,
                -11.780157450987003
              ],
              [
                -60.108318407231586,
                -11.775353468063981
              ],
              [
                -60.10572990726328,
                -11.773922975554
              ],
              [
                -60.10245744605855,
                -11.772115476435646
              ],
              [
                -60.10651842237385,
                -11.769708493061762
              ],
              [
                -60.10656744513611,
                -11.769679498274282
              ],
              [
                -60.1065434364674,
                -11.769577525668907
              ],
              [
                -60.10504045899163,
                -11.763309047884203
              ],
              [
                -60.10778194034527,
                -11.762959054307098
              ],
              [
                -60.11078942818228,
                -11.762575043353257
              ],
              [
                -60.1118444187878,
                -11.758855563215372
              ],
              [
                -60.10945292608626,
                -11.753937098045402
              ],
              [
                -60.10684545948718,
                -11.748573085408756
              ],
              [
                -60.110538450800256,
                -11.747891121957633
              ],
              [
                -60.110682437993574,
                -11.747864617329565
              ],
              [
                -60.11615293946956,
                -11.728901211590983
              ],
              [
                -60.1144584737738,
                -11.72605922577045
              ],
              [
                -60.11195296540251,
                -11.721856204351377
              ],
              [
                -60.11602097552491,
                -11.717353741978066
              ],
              [
                -60.12112541759989,
                -11.711705300379991
              ],
              [
                -60.11710647976808,
                -11.707740303242147
              ],
              [
                -60.11565548102085,
                -11.706308780804557
              ],
              [
                -60.11748695739347,
                -11.702908835694004
              ],
              [
                -60.118148991720595,
                -11.701679824160566
              ],
              [
                -60.11784448662862,
                -11.698361839883942
              ],
              [
                -60.1169529847901,
                -11.688636871118431
              ],
              [
                -60.11880650036798,
                -11.686138395171453
              ],
              [
                -60.121872465745376,
                -11.682004908418762
              ],
              [
                -60.1186324862763,
                -11.680213429349905
              ],
              [
                -60.11820600324155,
                -11.679977942602866
              ],
              [
                -60.113950556458114,
                -11.656003524216475
              ],
              [
                -60.11576705846826,
                -11.655020552680044
              ],
              [
                -60.11826205711905,
                -11.65367156692593
              ],
              [
                -60.11692956269017,
                -11.642927077827682
              ],
              [
                -60.1143716092107,
                -11.642747585055895
              ],
              [
                -60.11109613008574,
                -11.642518076225068
              ],
              [
                -60.113562126036044,
                -11.635626100298282
              ],
              [
                -60.11365759595872,
                -11.635358118622422
              ],
              [
                -60.11338360396039,
                -11.634058629735145
              ],
              [
                -60.110513162883144,
                -11.620435657355742
              ],
              [
                -60.111317135646196,
                -11.620140687053022
              ],
              [
                -60.114762136488366,
                -11.618878687601889
              ],
              [
                -60.11068867082661,
                -11.616058207539114
              ],
              [
                -60.10936765933987,
                -11.615144178138523
              ],
              [
                -60.11018317681064,
                -11.612778213785658
              ],
              [
                -60.113535648629345,
                -11.603050776672413
              ],
              [
                -60.11007017872213,
                -11.595082297909759
              ],
              [
                -60.11434466365129,
                -11.59106382980848
              ],
              [
                -60.110376727529214,
                -11.582095359490467
              ],
              [
                -60.10320625243443,
                -11.583570826447648
              ],
              [
                -60.093904321213856,
                -11.585484810741773
              ],
              [
                -60.09975427910089,
                -11.578531349103981
              ],
              [
                -60.10107277311819,
                -11.576963372203595
              ],
              [
                -60.09844380836207,
                -11.576618355049257
              ],
              [
                -60.09112185161034,
                -11.575656356271054
              ],
              [
                -60.09565332234076,
                -11.572544371715297
              ],
              [
                -60.09873630687077,
                -11.57042739230226
              ],
              [
                -60.097848809462214,
                -11.567497889388754
              ],
              [
                -60.0922753483637,
                -11.567613386052477
              ],
              [
                -60.08667839840877,
                -11.567729388672944
              ],
              [
                -60.090411370378455,
                -11.56489188452155
              ],
              [
                -60.09362386072292,
                -11.562450397738928
              ],
              [
                -60.090242390264926,
                -11.554482950206717
              ],
              [
                -60.08637041085186,
                -11.560227907548208
              ],
              [
                -60.085379889681015,
                -11.55970789004882
              ],
              [
                -60.078160471456535,
                -11.555918432701066
              ],
              [
                -60.078762950561796,
                -11.553885412553834
              ],
              [
                -60.07917346063448,
                -11.552499448270451
              ],
              [
                -60.0747469948365,
                -11.550350921761519
              ],
              [
                -60.05692811843562,
                -11.541701432805043
              ],
              [
                -60.05323964566451,
                -11.533248986910696
              ],
              [
                -60.04966117994202,
                -11.528273990164514
              ],
              [
                -60.040984262351515,
                -11.516207535201724
              ],
              [
                -60.036153800522875,
                -11.512642033176473
              ],
              [
                -60.02781683722973,
                -11.506487045236652
              ],
              [
                -60.028815370007614,
                -11.503870567337943
              ],
              [
                -60.03096633637259,
                -11.498234076561161
              ],
              [
                -60.03069888080024,
                -11.492775609774116
              ],
              [
                -60.02198640635428,
                -11.495709104775026
              ],
              [
                -60.019266938888094,
                -11.496624571035268
              ],
              [
                -60.016147439804136,
                -11.490323621919849
              ],
              [
                -60.01509695187818,
                -11.4882011302948
              ],
              [
                -60.011687973835244,
                -11.487275125345299
              ],
              [
                -60.009113492418706,
                -11.486575622874586
              ],
              [
                -60.006830533725044,
                -11.483955111258933
              ],
              [
                -60.00187406746765,
                -11.478265642301043
              ],
              [
                -59.98487069047261,
                -11.468945177080117
              ],
              [
                -59.97570127712577,
                -11.438824763137804
              ],
              [
                -59.95181192348692,
                -11.436024742699985
              ],
              [
                -59.94422947603535,
                -11.431918740388324
              ],
              [
                -59.94284951381347,
                -11.427139270823487
              ],
              [
                -59.941482032437364,
                -11.422402283099993
              ],
              [
                -59.938213548257124,
                -11.42277529778569
              ],
              [
                -59.93387455824368,
                -11.423270275076563
              ],
              [
                -59.92537112958276,
                -11.415084796001429
              ],
              [
                -59.92720313850893,
                -11.412368339042198
              ],
              [
                -59.92009670122549,
                -11.397613362116758
              ],
              [
                -59.92237167551093,
                -11.393769397853697
              ],
              [
                -59.922207685262784,
                -11.39277437891499
              ],
              [
                -59.92096572506222,
                -11.38525893958982
              ],
              [
                -59.91993773127428,
                -11.379036458995287
              ],
              [
                -59.92134221379453,
                -11.374037977271978
              ],
              [
                -59.924899242095584,
                -11.361379034758912
              ],
              [
                -59.92196773421049,
                -11.355394564849849
              ],
              [
                -59.92190174984382,
                -11.355259556326011
              ],
              [
                -59.92197625561441,
                -11.35513858898382
              ],
              [
                -59.92398674642969,
                -11.351870576749896
              ],
              [
                -59.92240377036393,
                -11.350465586156535
              ],
              [
                -59.91732078968672,
                -11.345953092106289
              ],
              [
                -59.92723528120375,
                -11.310037786369799
              ],
              [
                -59.94948618125993,
                -11.304006362094333
              ],
              [
                -59.95420765867954,
                -11.299171351376561
              ],
              [
                -59.958929638095725,
                -11.294336902653665
              ],
              [
                -59.95960463531601,
                -11.282308946438594
              ],
              [
                -59.95990163790958,
                -11.277002971168779
              ],
              [
                -59.96238312608709,
                -11.273626004212037
              ],
              [
                -59.967980638184024,
                -11.266008563164538
              ],
              [
                -59.96767064415822,
                -11.259124581763835
              ],
              [
                -59.96737413052221,
                -11.252537124094518
              ],
              [
                -59.97490810191687,
                -11.242165153423587
              ],
              [
                -59.98148959466886,
                -11.233105208367768
              ],
              [
                -59.98267959566075,
                -11.223953743480266
              ],
              [
                -59.98387011863536,
                -11.21480278959853
              ],
              [
                -59.98059412617946,
                -11.20336733310511
              ],
              [
                -59.97498366408893,
                -11.198504353840466
              ],
              [
                -59.97453969040479,
                -11.198119873768155
              ],
              [
                -59.96731872952668,
                -11.19746936829475
              ],
              [
                -59.96348425866684,
                -11.188720378126186
              ],
              [
                -59.97067473144985,
                -11.179807456484623
              ],
              [
                -59.97818867760573,
                -11.179306462487823
              ],
              [
                -59.97987469476822,
                -11.179194450852538
              ],
              [
                -59.98704614692269,
                -11.170711002275747
              ],
              [
                -59.98845467778753,
                -11.160835053990384
              ],
              [
                -59.99537114105985,
                -11.156586085395933
              ],
              [
                -59.9972326057922,
                -11.150965598215027
              ],
              [
                -59.994485164639144,
                -11.147807607397743
              ],
              [
                -59.98717267461305,
                -11.146960606684988
              ],
              [
                -59.98439922367221,
                -11.146639613380142
              ],
              [
                -59.98635471238516,
                -11.14242362620722
              ],
              [
                -59.98857117952661,
                -11.137643176954956
              ],
              [
                -59.985595699229265,
                -11.138216136386824
              ],
              [
                -59.97940626997829,
                -11.139407652644337
              ],
              [
                -59.976237818134045,
                -11.121992720752845
              ],
              [
                -59.98631426086916,
                -11.1141202734174
              ],
              [
                -59.98984422310997,
                -11.121462757153333
              ],
              [
                -59.99067470216547,
                -11.123190722974078
              ],
              [
                -60.012579577761485,
                -11.12212879179104
              ],
              [
                -60.01520857298806,
                -11.115886822639174
              ],
              [
                -60.01702004714141,
                -11.122258277755853
              ],
              [
                -60.01885252303531,
                -11.128703240594374
              ],
              [
                -60.02570597845987,
                -11.130337255063536
              ],
              [
                -60.031485954258436,
                -11.127382270832376
              ],
              [
                -60.033823466200545,
                -11.12288279452704
              ],
              [
                -60.03690094722273,
                -11.116958350724396
              ],
              [
                -60.04318387647103,
                -11.123528802580147
              ],
              [
                -60.043515903894985,
                -11.123875808622774
              ],
              [
                -60.06617573018493,
                -11.125114839660823
              ],
              [
                -60.074484241501395,
                -11.104629438481219
              ],
              [
                -60.083710176964956,
                -11.096450498941268
              ],
              [
                -60.089314130436264,
                -11.100068014592393
              ],
              [
                -60.08915912572033,
                -11.102735975647187
              ],
              [
                -60.088822619687456,
                -11.10850596281385
              ],
              [
                -60.10431752529687,
                -11.108786485865354
              ],
              [
                -60.10990150341839,
                -11.104051992050762
              ],
              [
                -60.11215201039204,
                -11.102144012214398
              ],
              [
                -60.11624246012538,
                -11.104455023039668
              ],
              [
                -60.11987043205706,
                -11.106504011230344
              ],
              [
                -60.12528792449725,
                -11.103312047377646
              ],
              [
                -60.13009838491599,
                -11.100478037927228
              ],
              [
                -60.13411434925463,
                -11.102126072530815
              ],
              [
                -60.14195131323165,
                -11.10534203576542
              ],
              [
                -60.146074285194736,
                -11.100519094836235
              ],
              [
                -60.14748577570424,
                -11.098868090445064
              ],
              [
                -60.15098229278133,
                -11.100141604808687
              ],
              [
                -60.15192474760341,
                -11.100484594908231
              ],
              [
                -60.15213177365289,
                -11.100326079951948
              ],
              [
                -60.15873670735063,
                -11.095257138298331
              ],
              [
                -60.1764005987463,
                -11.098729644083557
              ],
              [
                -60.185068057220846,
                -11.104037645618837
              ],
              [
                -60.18611655000482,
                -11.105768621844634
              ],
              [
                -60.19093202267256,
                -11.11371859733694
              ],
              [
                -60.20426242228542,
                -11.111726625667062
              ],
              [
                -60.210012391534235,
                -11.106411665605567
              ],
              [
                -60.22695580175009,
                -11.105040193352597
              ],
              [
                -60.232827764487894,
                -11.09961471196272
              ],
              [
                -60.238312764891184,
                -11.094547259351005
              ],
              [
                -60.24777868977959,
                -11.09637876043494
              ],
              [
                -60.24826169376364,
                -11.096472270847368
              ],
              [
                -60.26462756967662,
                -11.09327079856174
              ],
              [
                -60.270455524519186,
                -11.098297792573602
              ],
              [
                -60.280290473925525,
                -11.091135828433302
              ],
              [
                -60.278772527108536,
                -11.084681851944476
              ],
              [
                -60.27747903130135,
                -11.079182365689121
              ],
              [
                -60.28181703771695,
                -11.063621477786812
              ],
              [
                -60.29090195055257,
                -11.065094949752874
              ],
              [
                -60.30071041915495,
                -11.056757508645886
              ],
              [
                -60.30867436525785,
                -11.063632519210957
              ],
              [
                -60.316982827413376,
                -11.064699996638321
              ],
              [
                -60.334008688131995,
                -11.082493454159133
              ],
              [
                -60.34789510968368,
                -11.073649029130744
              ],
              [
                -60.34142662185411,
                -11.082242471835812
              ],
              [
                -60.34426762448069,
                -11.091800938152563
              ],
              [
                -60.34634406407855,
                -11.098787385352475
              ],
              [
                -60.34365011302001,
                -11.104486876036317
              ],
              [
                -60.347429073504664,
                -11.108644351421384
              ],
              [
                -60.36978694228738,
                -11.09861943792524
              ],
              [
                -60.37214343329323,
                -11.094669943058799
              ],
              [
                -60.37270394662949,
                -11.093730490085616
              ],
              [
                -60.37951138218448,
                -11.095089472760618
              ],
              [
                -60.38320386516362,
                -11.095826967838933
              ],
              [
                -60.39101432142633,
                -11.093398016642194
              ],
              [
                -60.39502781148385,
                -11.080949558420105
              ],
              [
                -60.39531779134454,
                -11.080049064816205
              ],
              [
                -60.40359878987012,
                -11.068983613839558
              ],
              [
                -60.40619776758453,
                -11.070132608266741
              ],
              [
                -60.407788753002286,
                -11.070836606913948
              ],
              [
                -60.41384620991268,
                -11.067076661485132
              ],
              [
                -60.41286321890388,
                -11.060049693277522
              ],
              [
                -60.410951762871946,
                -11.046381230307494
              ],
              [
                -60.41357375416188,
                -11.043089739020756
              ],
              [
                -60.429160680908645,
                -11.037100294365779
              ],
              [
                -60.43008765230369,
                -11.037767324042155
              ],
              [
                -60.43514613326544,
                -11.041404812834616
              ],
              [
                -60.44605654854216,
                -11.041757319774103
              ],
              [
                -60.44146962288113,
                -11.028265355482299
              ],
              [
                -60.437399167528405,
                -11.016291902518569
              ],
              [
                -60.441157634255404,
                -11.016400908393
              ],
              [
                -60.4496436168133,
                -11.008068962847624
              ],
              [
                -60.451926609638214,
                -10.99288554254459
              ],
              [
                -60.4595160799759,
                -10.989462569180796
              ],
              [
                -60.46017855308829,
                -10.989461095303534
              ],
              [
                -60.831490340100075,
                -10.988528676315036
              ],
              [
                -60.96971450076486,
                -10.988067893346566
              ],
              [
                -61.00005533228701,
                -10.987956910244591
              ],
              [
                -61.00005531190747,
                -10.989902931964638
              ],
              [
                -61.00005732451556,
                -10.991848907688775
              ],
              [
                -61.42846079073949,
                -10.987144113625076
              ],
              [
                -61.54971154685913,
                -10.985702815287546
              ],
              [
                -61.55007658350608,
                -10.98127781336873
              ],
              [
                -61.55013507940534,
                -10.980571808413712
              ],
              [
                -61.54561158452825,
                -10.978661832673337
              ],
              [
                -61.53894866042522,
                -10.975850834052496
              ],
              [
                -61.53805614617174,
                -10.97547431910373
              ],
              [
                -61.52954120323473,
                -10.978625814440896
              ],
              [
                -61.52706924939176,
                -10.956795877411189
              ],
              [
                -61.51896482566313,
                -10.949304915943184
              ],
              [
                -61.5225978049722,
                -10.9400864546533
              ],
              [
                -61.52270330883367,
                -10.939819955946643
              ],
              [
                -61.52253832012857,
                -10.938465990327696
              ],
              [
                -61.520307333700266,
                -10.920129543236326
              ],
              [
                -61.51443790326579,
                -10.906817577007857
              ],
              [
                -61.51973736645998,
                -10.905418108835109
              ],
              [
                -61.521308377222404,
                -10.905000609241343
              ],
              [
                -61.523756364384504,
                -10.904351625100322
              ],
              [
                -61.521003365321754,
                -10.89728714401415
              ],
              [
                -61.51940338955931,
                -10.893178662510673
              ],
              [
                -61.52036837432137,
                -10.891869156625143
              ],
              [
                -61.52574886325612,
                -10.884573211267695
              ],
              [
                -61.52332441418215,
                -10.879888209793062
              ],
              [
                -61.52159247356841,
                -10.844819878830974
              ],
              [
                -61.51425200029954,
                -10.839670412075082
              ],
              [
                -61.50969456279779,
                -10.83647339482201
              ],
              [
                -61.51086802504381,
                -10.828951414357892
              ],
              [
                -61.51198253196583,
                -10.821808449208175
              ],
              [
                -61.50287810851233,
                -10.808057988663034
              ],
              [
                -61.51001659702691,
                -10.793926101990808
              ],
              [
                -61.50488316061137,
                -10.78907909875917
              ],
              [
                -61.50472463859292,
                -10.784362132317451
              ],
              [
                -61.49441469557636,
                -10.78368110192756
              ],
              [
                -61.492104707267806,
                -10.788639565213838
              ],
              [
                -61.48746772367735,
                -10.798592538605252
              ],
              [
                -61.47329082612601,
                -10.797120021048391
              ],
              [
                -61.47973780375342,
                -10.785190081023389
              ],
              [
                -61.47595336296615,
                -10.767290648018164
              ],
              [
                -61.47792633880513,
                -10.764304676882796
              ],
              [
                -61.479898356649,
                -10.761318675745295
              ],
              [
                -61.480323373098436,
                -10.743808775181733
              ],
              [
                -61.48782534357848,
                -10.736009802528594
              ],
              [
                -61.4848723728755,
                -10.726350324300176
              ],
              [
                -61.48208585700821,
                -10.725038862188665
              ],
              [
                -61.47584590561754,
                -10.72210134918658
              ],
              [
                -61.47184897020899,
                -10.71458636822171
              ],
              [
                -61.480406410708675,
                -10.714386397290008
              ],
              [
                -61.485263370861354,
                -10.710345395396219
              ],
              [
                -61.49827181503036,
                -10.69952449261184
              ],
              [
                -61.498010348026185,
                -10.688028539634283
              ],
              [
                -61.49125238838084,
                -10.67515855870637
              ],
              [
                -61.48961290658631,
                -10.674064064607862
              ],
              [
                -61.48166795989277,
                -10.66875858983966
              ],
              [
                -61.48182150674488,
                -10.65295066982902
              ],
              [
                -61.478404551632636,
                -10.642650678685582
              ],
              [
                -61.48273349802839,
                -10.637261250599618
              ],
              [
                -61.48322102727021,
                -10.63665474496647
              ],
              [
                -61.48904198627926,
                -10.629411771168336
              ],
              [
                -61.479896570972194,
                -10.614257313137191
              ],
              [
                -61.4756816167676,
                -10.607273847726365
              ],
              [
                -61.47835408080887,
                -10.601749391488417
              ],
              [
                -61.48095559500531,
                -10.596371418513554
              ],
              [
                -61.477207109391514,
                -10.58981992106718
              ],
              [
                -61.47690662198623,
                -10.589295419792702
              ],
              [
                -61.477060611226996,
                -10.588032933387579
              ],
              [
                -61.47940812514616,
                -10.568765012788893
              ],
              [
                -61.475022672581645,
                -10.563141023410562
              ],
              [
                -61.46956370943921,
                -10.556142065110219
              ],
              [
                -61.46576625871038,
                -10.540825615799921
              ],
              [
                -61.47549372666016,
                -10.532175191369674
              ],
              [
                -61.47851572590847,
                -10.529487688698808
              ],
              [
                -61.48026174910722,
                -10.487356882211499
              ],
              [
                -61.469653370298325,
                -10.461027479752373
              ],
              [
                -61.4716478576947,
                -10.45875098266043
              ],
              [
                -61.47551032796938,
                -10.45434252565524
              ],
              [
                -61.46904340428882,
                -10.449850049253019
              ],
              [
                -61.466538406457275,
                -10.448109555588035
              ],
              [
                -61.464463440832276,
                -10.436900106876454
              ],
              [
                -61.46123699693897,
                -10.419520142574317
              ],
              [
                -61.464522949079665,
                -10.417166186901145
              ],
              [
                -61.46733643735518,
                -10.415152203016598
              ],
              [
                -61.469324449875224,
                -10.415844687284219
              ],
              [
                -61.47559890649002,
                -10.418030203132728
              ],
              [
                -61.47637488290902,
                -10.410243234503469
              ],
              [
                -61.47457341319009,
                -10.40841773435789
              ],
              [
                -61.47095892383488,
                -10.40475625009714
              ],
              [
                -61.48190239961641,
                -10.400400283296511
              ],
              [
                -61.48351037850511,
                -10.399759781616536
              ],
              [
                -61.48510539012971,
                -10.396895817372721
              ],
              [
                -61.48901237441084,
                -10.389879348521509
              ],
              [
                -61.49174832523139,
                -10.389628348001692
              ],
              [
                -61.49650879582128,
                -10.389192336533744
              ],
              [
                -61.50257232472687,
                -10.375538908376535
              ],
              [
                -61.50214481835967,
                -10.368722941682728
              ],
              [
                -61.50118336300319,
                -10.35338653039853
              ],
              [
                -61.511344787661834,
                -10.350009053104293
              ],
              [
                -61.5156212974583,
                -10.33404462791497
              ],
              [
                -61.52093825915819,
                -10.330394631981527
              ],
              [
                -61.52666274873058,
                -10.330907650982125
              ],
              [
                -61.53319369260036,
                -10.331492636972099
              ],
              [
                -61.54034415498218,
                -10.319832233047157
              ],
              [
                -61.54756311516859,
                -10.31600373990418
              ],
              [
                -61.55279111376884,
                -10.306613814223482
              ],
              [
                -61.547912640690704,
                -10.30344531789507
              ],
              [
                -61.543546187157744,
                -10.30060982397487
              ],
              [
                -61.55405314133494,
                -10.279171927028576
              ],
              [
                -61.57138159385014,
                -10.258862549139634
              ],
              [
                -61.57433707539301,
                -10.25065007681368
              ],
              [
                -61.575329062231496,
                -10.247893095132412
              ],
              [
                -61.57630008009238,
                -10.245195110166703
              ],
              [
                -61.577066102535156,
                -10.226201180284598
              ],
              [
                -61.57450462062603,
                -10.221548198040349
              ],
              [
                -61.57304961217139,
                -10.218906203988485
              ],
              [
                -61.573178123661386,
                -10.215680234941702
              ],
              [
                -61.573461642550335,
                -10.208569774700832
              ],
              [
                -61.56681467671831,
                -10.205234284379676
              ],
              [
                -61.55117777228721,
                -10.197387772068607
              ],
              [
                -61.559189736274185,
                -10.192717795688994
              ],
              [
                -61.55923272906928,
                -10.19269329986163
              ],
              [
                -61.55928276029079,
                -10.186181352730966
              ],
              [
                -61.559366272387,
                -10.175280380395153
              ],
              [
                -61.57652469734593,
                -10.163809991474496
              ],
              [
                -61.58692363083509,
                -10.162839472042412
              ],
              [
                -61.5962780901137,
                -10.161967519538825
              ],
              [
                -61.5996495500832,
                -10.154438528977213
              ],
              [
                -61.59006317172422,
                -10.12254217201274
              ],
              [
                -61.58962119158906,
                -10.121072668593776
              ],
              [
                -61.59008718415645,
                -10.115945706226723
              ],
              [
                -61.59055319771948,
                -10.110818725862286
              ],
              [
                -61.585850219376596,
                -10.101474278405822
              ],
              [
                -61.582740252919294,
                -10.095293263947026
              ],
              [
                -61.582673746760314,
                -10.090621825816669
              ],
              [
                -61.58255178207796,
                -10.082087824121798
              ],
              [
                -61.580751790409224,
                -10.080766338953547
              ],
              [
                -61.57815530419534,
                -10.078861339034864
              ],
              [
                -61.57999179691473,
                -10.07186936182582
              ],
              [
                -61.58317081614971,
                -10.059761936610515
              ],
              [
                -61.578491323398566,
                -10.054311944623532
              ],
              [
                -61.57381085365142,
                -10.048861489648916
              ],
              [
                -61.55506648849679,
                -10.037941016181074
              ],
              [
                -61.54000914280814,
                -10.000248629446345
              ],
              [
                -61.54103463588034,
                -9.995251154497925
              ],
              [
                -61.53677918482052,
                -9.992878171080712
              ],
              [
                -61.53065221905038,
                -9.989462172243813
              ],
              [
                -61.52743277900066,
                -9.970040273724194
              ],
              [
                -61.53201525194412,
                -9.96750427972407
              ],
              [
                -61.538873193940525,
                -9.963708803658333
              ],
              [
                -61.53186427882089,
                -9.952250830077524
              ],
              [
                -61.52878329955302,
                -9.95084185337391
              ],
              [
                -61.522781332993596,
                -9.948098334898818
              ],
              [
                -61.523155844090645,
                -9.94095639720967
              ],
              [
                -61.52582130326919,
                -9.937561422935916
              ],
              [
                -61.52822430241261,
                -9.934501941811945
              ],
              [
                -61.52398885758776,
                -9.929345456470417
              ],
              [
                -61.52259287019356,
                -9.92764494063778
              ],
              [
                -61.52287434776556,
                -9.92434797226648
              ],
              [
                -61.524427887384554,
                -9.906192030848992
              ],
              [
                -61.517322415333496,
                -9.899451074960233
              ],
              [
                -61.51021646628465,
                -9.892709601094113
              ],
              [
                -61.50737753438421,
                -9.860696231828868
              ],
              [
                -61.51117003556663,
                -9.851865756747737
              ],
              [
                -61.51496201374326,
                -9.84303480466737
              ],
              [
                -61.5177070518275,
                -9.822632395952821
              ],
              [
                -61.525074474639645,
                -9.820898419683958
              ],
              [
                -61.53157344307809,
                -9.819368962204395
              ],
              [
                -61.52833498536916,
                -9.813637956008696
              ],
              [
                -61.524726498775046,
                -9.807250985092356
              ],
              [
                -61.524375527203794,
                -9.795344060999343
              ],
              [
                -61.53362347509614,
                -9.79376406490128
              ],
              [
                -61.537850455197585,
                -9.793042080453231
              ],
              [
                -61.54680739341429,
                -9.787161106481323
              ],
              [
                -61.53207352774662,
                -9.757597227865036
              ],
              [
                -61.531517574821855,
                -9.74856227409912
              ],
              [
                -61.53096206188098,
                -9.739527316345438
              ],
              [
                -61.54187800753084,
                -9.734547852407266
              ],
              [
                -61.55470896266691,
                -9.72869487313125
              ],
              [
                -61.567391885851,
                -9.727277909438325
              ],
              [
                -61.57382786168366,
                -9.717279975386461
              ],
              [
                -61.56339091839506,
                -9.712404464704266
              ],
              [
                -61.55219050799977,
                -9.707171964435148
              ],
              [
                -61.54702652998587,
                -9.713258931299043
              ],
              [
                -61.545533523763844,
                -9.715018939428074
              ],
              [
                -61.53187710088632,
                -9.71139494149904
              ],
              [
                -61.519118185959236,
                -9.696655474109567
              ],
              [
                -61.504734289360144,
                -9.691002973949264
              ],
              [
                -61.50172084112776,
                -9.679465019463148
              ],
              [
                -61.50354832954053,
                -9.672391554901875
              ],
              [
                -61.50634334715619,
                -9.661574125044202
              ],
              [
                -61.499930378572856,
                -9.660286619006685
              ],
              [
                -61.493516423001054,
                -9.658999101971213
              ],
              [
                -61.480392522079825,
                -9.639656672333624
              ],
              [
                -61.47705654441957,
                -9.626912762749852
              ],
              [
                -61.48124801286318,
                -9.623433248148244
              ],
              [
                -61.48693398239027,
                -9.626158776799102
              ],
              [
                -61.487279001540784,
                -9.62608474463687
              ],
              [
                -61.49518845514491,
                -9.624389258834423
              ],
              [
                -61.50248290099222,
                -9.614959333722998
              ],
              [
                -61.49688398037912,
                -9.588840928943506
              ],
              [
                -61.494639497965935,
                -9.578369999162941
              ],
              [
                -61.49590200239625,
                -9.573995994078038
              ],
              [
                -61.50103099484615,
                -9.572935524322707
              ],
              [
                -61.50261348934641,
                -9.572608530099574
              ],
              [
                -61.50412446337777,
                -9.567562042307358
              ],
              [
                -61.50981143636228,
                -9.56471308215886
              ],
              [
                -61.508581473959644,
                -9.551707123937309
              ],
              [
                -61.507482016710895,
                -9.54008118692497
              ],
              [
                -61.513217949857875,
                -9.538928683446844
              ],
              [
                -61.51353095217457,
                -9.53886570418506
              ],
              [
                -61.513490448440535,
                -9.538177179122846
              ],
              [
                -61.51290597083606,
                -9.528151241916833
              ],
              [
                -61.51844592948666,
                -9.530068247773151
              ],
              [
                -61.526567372137215,
                -9.532879222567136
              ],
              [
                -61.54900726885868,
                -9.514839338300273
              ],
              [
                -61.5468442811307,
                -9.510843363490963
              ],
              [
                -61.53725086103368,
                -9.506541357037033
              ],
              [
                -61.52553944139572,
                -9.501289870607744
              ],
              [
                -61.55576177201536,
                -9.48783398382026
              ],
              [
                -61.56259924778439,
                -9.48479001215752
              ],
              [
                -61.56851874265973,
                -9.476468046110453
              ],
              [
                -61.578094164187924,
                -9.471234575998324
              ],
              [
                -61.57346422151589,
                -9.462479603292776
              ],
              [
                -61.58095067804278,
                -9.458479650722866
              ],
              [
                -61.56242632751066,
                -9.437390707367953
              ],
              [
                -61.559469840796154,
                -9.436371203473355
              ],
              [
                -61.556179878145464,
                -9.435236702592034
              ],
              [
                -61.555339872471926,
                -9.421409287660245
              ],
              [
                -61.550461900317124,
                -9.416579307586456
              ],
              [
                -61.56037539285175,
                -9.397864892748656
              ],
              [
                -61.55225644473149,
                -9.38717543855117
              ],
              [
                -61.56716088615979,
                -9.37639452036539
              ],
              [
                -61.57607781810905,
                -9.369969039413192
              ],
              [
                -61.59662819558789,
                -9.367311573958924
              ],
              [
                -61.607406141100796,
                -9.362195108978266
              ],
              [
                -61.60858263726319,
                -9.361636607127704
              ],
              [
                -61.611677633632205,
                -9.355612161907105
              ],
              [
                -61.62537704381606,
                -9.362560654566801
              ],
              [
                -61.624847063424234,
                -9.339103253060438
              ],
              [
                -61.62319658520449,
                -9.336769261841187
              ],
              [
                -61.617169130743825,
                -9.326973784822039
              ],
              [
                -61.61698615021736,
                -9.326676286854605
              ],
              [
                -61.610786186982445,
                -9.31534182233596
              ],
              [
                -61.61487368077072,
                -9.30585687167309
              ],
              [
                -61.61194125323519,
                -9.280022493287952
              ],
              [
                -61.61487372418727,
                -9.278278520143926
              ],
              [
                -61.62343416767878,
                -9.281733989410096
              ],
              [
                -61.62642263800122,
                -9.282940021459664
              ],
              [
                -61.63216414130049,
                -9.276254047954088
              ],
              [
                -61.632005626837795,
                -9.266242577457232
              ],
              [
                -61.62778268364191,
                -9.256719138984128
              ],
              [
                -61.60208183551202,
                -9.255760584152693
              ],
              [
                -61.59906435776237,
                -9.255648108298976
              ],
              [
                -61.59521588010559,
                -9.25115860140272
              ],
              [
                -61.58842894408479,
                -9.243242149290586
              ],
              [
                -61.5752780191794,
                -9.237342137225596
              ],
              [
                -61.54277170733759,
                -9.242912080094467
              ],
              [
                -61.53856322615656,
                -9.2436330598834
              ],
              [
                -61.53215875038913,
                -9.2485050543064
              ],
              [
                -61.52587577507235,
                -9.24367406346806
              ],
              [
                -61.5275232853783,
                -9.230854632092866
              ],
              [
                -61.520336874794864,
                -9.194574788138187
              ],
              [
                -61.52309138321796,
                -9.178640848109312
              ],
              [
                -61.531083356048335,
                -9.170912412485256
              ],
              [
                -61.531342358941856,
                -9.170661391958022
              ],
              [
                -61.53176185307734,
                -9.16961241916744
              ],
              [
                -61.534947848521995,
                -9.161635429776771
              ],
              [
                -61.540558843508045,
                -9.152756470803835
              ],
              [
                -61.55521028182795,
                -9.129572096574137
              ],
              [
                -61.55660278279725,
                -9.12201513378289
              ],
              [
                -61.557995288757745,
                -9.114458197995226
              ],
              [
                -61.55440234636573,
                -9.089867803114762
              ],
              [
                -61.55038038578837,
                -9.078941328541871
              ],
              [
                -61.550379855796336,
                -9.078937846556444
              ],
              [
                -61.54635642621167,
                -9.068009377018477
              ],
              [
                -61.54051744870358,
                -9.053009430900302
              ],
              [
                -61.53532000726284,
                -9.047326962641291
              ],
              [
                -61.53012303081683,
                -9.041644969394302
              ],
              [
                -61.5274256043415,
                -9.01896607869457
              ],
              [
                -61.513156716808126,
                -8.988226203519968
              ],
              [
                -61.511829746107374,
                -8.982019740106432
              ],
              [
                -61.510502237402775,
                -8.975813759697182
              ],
              [
                -61.4911174077697,
                -8.940758907861095
              ],
              [
                -61.48962492239137,
                -8.939227909569265
              ],
              [
                -61.4881314440193,
                -8.937696418279304
              ],
              [
                -61.46811055151094,
                -8.917145459609348
              ],
              [
                -61.4760170246076,
                -8.911448029838654
              ],
              [
                -61.48392351071087,
                -8.90575004406146
              ],
              [
                -61.4915954669649,
                -8.884447667167226
              ],
              [
                -61.51094288578345,
                -8.863260763857447
              ],
              [
                -61.51206289901323,
                -8.849916826606337
              ],
              [
                -61.51318338921251,
                -8.83657242637491
              ],
              [
                -61.525538374085805,
                -8.816461020617522
              ],
              [
                -61.53225183756649,
                -8.819688986497235
              ],
              [
                -61.532600324359535,
                -8.819856503231897
              ],
              [
                -61.54121026294914,
                -8.818868509428517
              ],
              [
                -61.55824819457754,
                -8.807235110399693
              ],
              [
                -61.56781461746297,
                -8.80070366647464
              ],
              [
                -61.57191259462624,
                -8.8011671570318
              ],
              [
                -61.5797465710641,
                -8.802054138830181
              ],
              [
                -61.582423046102214,
                -8.798310180093035
              ],
              [
                -61.57996957998542,
                -8.798309688735568
              ],
              [
                -61.18446390138973,
                -8.798193175853939
              ],
              [
                -60.84834089556066,
                -8.797789754293834
              ],
              [
                -60.74696050807253,
                -8.797464619164685
              ],
              [
                -60.419469947849514,
                -8.796233719408018
              ],
              [
                -60.40786150253395,
                -8.796317719334901
              ],
              [
                -59.85649987534332,
                -8.80031799294456
              ],
              [
                -59.70437577240947,
                -8.801280307261461
              ],
              [
                -59.12673583774949,
                -8.802964062270183
              ],
              [
                -58.49983324502063,
                -8.802438305019542
              ],
              [
                -58.442355639392865,
                -8.799025744828615
              ],
              [
                -58.437091683943315,
                -8.795117260069754
              ],
              [
                -58.42955772368818,
                -8.789523784602007
              ],
              [
                -58.42221474639956,
                -8.788331293778556
              ],
              [
                -58.419965767417025,
                -8.78968528716072
              ],
              [
                -58.41535531205457,
                -8.792461252440772
              ],
              [
                -58.394897949663964,
                -8.779818773635007
              ],
              [
                -58.35407126372369,
                -8.736946973128054
              ],
              [
                -58.331458404445314,
                -8.72597699064132
              ],
              [
                -58.32555098123305,
                -8.719611007337473
              ],
              [
                -58.327811451716684,
                -8.7117240762226
              ],
              [
                -58.35051132584197,
                -8.701191657826541
              ],
              [
                -58.380506642019554,
                -8.705190174242459
              ],
              [
                -58.41052742321334,
                -8.719758118699302
              ],
              [
                -58.43302929782846,
                -8.710009693103828
              ],
              [
                -58.43689576728484,
                -8.703034225484004
              ],
              [
                -58.413848996136636,
                -8.64347851491729
              ],
              [
                -58.40858354853253,
                -8.63733505339799
              ],
              [
                -58.40306508686694,
                -8.630896080024213
              ],
              [
                -58.40059160411657,
                -8.615545627602055
              ],
              [
                -58.39082217958691,
                -8.602949697199351
              ],
              [
                -58.39071020770793,
                -8.59186275483852
              ],
              [
                -58.39802817656368,
                -8.579681815462394
              ],
              [
                -58.398297662189414,
                -8.579464817881696
              ],
              [
                -58.41008259408652,
                -8.569981893905373
              ],
              [
                -58.40381117757417,
                -8.539135044366272
              ],
              [
                -58.40425768852992,
                -8.534725531750464
              ],
              [
                -58.404704693482245,
                -8.530314077146636
              ],
              [
                -58.407384676317264,
                -8.526209110884382
              ],
              [
                -58.41502164249414,
                -8.514511678520998
              ],
              [
                -58.41583816207048,
                -8.503185219610987
              ],
              [
                -58.41665466662704,
                -8.491858787713687
              ],
              [
                -58.40476027822374,
                -8.467288380269697
              ],
              [
                -58.401388270165725,
                -8.465550393599154
              ],
              [
                -58.39801630310921,
                -8.463811889933472
              ],
              [
                -58.39435533113819,
                -8.454019457832102
              ],
              [
                -58.38898338051239,
                -8.439650532287592
              ],
              [
                -58.38226342577431,
                -8.434194022856428
              ],
              [
                -58.37554298504142,
                -8.428736543444622
              ],
              [
                -58.35302367169753,
                -8.392414232489596
              ],
              [
                -58.3537506935849,
                -8.388190760403443
              ],
              [
                -58.354477182472955,
                -8.383967287317835
              ],
              [
                -58.35229719215296,
                -8.379016309199837
              ],
              [
                -58.34783425143181,
                -8.368880318017878
              ],
              [
                -58.349361259659,
                -8.36048386071247
              ],
              [
                -58.350595746648565,
                -8.353691927059826
              ],
              [
                -58.34757527922518,
                -8.345767937720739
              ],
              [
                -58.33174939034816,
                -8.337217481510702
              ],
              [
                -58.31803296307744,
                -8.32980649230699
              ],
              [
                -58.31437151858916,
                -8.322669541317833
              ],
              [
                -58.31521150238658,
                -8.305978620602064
              ],
              [
                -58.320528992670376,
                -8.299597143049867
              ],
              [
                -58.32537397114895,
                -8.293783172074907
              ],
              [
                -58.32751798049356,
                -8.285372260694977
              ],
              [
                -58.32367301394177,
                -8.278344275986452
              ],
              [
                -58.31995502031439,
                -8.271546801299023
              ],
              [
                -58.320683061352966,
                -8.252703918273836
              ],
              [
                -58.3214950585309,
                -8.23168099728251
              ],
              [
                -58.31689509005936,
                -8.220228548839092
              ],
              [
                -58.31229514156999,
                -8.2087750974326
              ],
              [
                -58.318526616131756,
                -8.183810754940609
              ],
              [
                -58.3220691398663,
                -8.169617336290628
              ],
              [
                -58.31950965917057,
                -8.16186335695939
              ],
              [
                -58.31694967246832,
                -8.154109890638235
              ],
              [
                -58.29765932248998,
                -8.140830457588917
              ],
              [
                -58.29054238331006,
                -8.13593097846207
              ],
              [
                -58.28851888255219,
                -8.132031478542327
              ],
              [
                -58.28649491479515,
                -8.128132003626108
              ],
              [
                -58.289174406194036,
                -8.115166079903162
              ],
              [
                -58.291543903641774,
                -8.10370064228735
              ],
              [
                -58.28982390662786,
                -8.098465175462582
              ],
              [
                -58.286076445618804,
                -8.087061204141229
              ],
              [
                -58.291121438651714,
                -8.073870785456362
              ],
              [
                -58.31594081336583,
                -8.03322201948549
              ],
              [
                -58.321171806578334,
                -8.024656077791924
              ],
              [
                -58.32307477415593,
                -8.016991102569857
              ],
              [
                -58.32497829972368,
                -8.009324674358538
              ],
              [
                -58.32461231160412,
                -8.003837193481694
              ],
              [
                -58.32384181931429,
                -7.992259759721726
              ],
              [
                -58.327366821081554,
                -7.980279811143699
              ],
              [
                -58.33089079083354,
                -7.968301894559131
              ],
              [
                -58.35460767365184,
                -7.94369355054606
              ],
              [
                -58.372867621618354,
                -7.906827759574258
              ],
              [
                -58.37419063809424,
                -7.864313973990997
              ],
              [
                -58.37903662871037,
                -7.85726648817486
              ],
              [
                -58.382565084139024,
                -7.852134532161912
              ],
              [
                -58.38242512589698,
                -7.838706594589048
              ],
              [
                -58.37122521865927,
                -7.820913193044897
              ],
              [
                -58.333439970690705,
                -7.803945726314305
              ],
              [
                -58.29433173065498,
                -7.771928357359654
              ],
              [
                -58.289091786865264,
                -7.759976397698106
              ],
              [
                -58.28385285704943,
                -7.748024478073782
              ],
              [
                -58.2812633814624,
                -7.718425105907276
              ],
              [
                -58.28046090906283,
                -7.709247683460243
              ],
              [
                -58.2789774066192,
                -7.706291669720299
              ],
              [
                -58.27749392617488,
                -7.703335193985051
              ],
              [
                -58.27210845274597,
                -7.697778735924905
              ],
              [
                -58.25288961294783,
                -7.677952312313609
              ],
              [
                -58.24610666400908,
                -7.665558349408768
              ],
              [
                -58.2393242180473,
                -7.653164905546094
              ],
              [
                -58.23068427283584,
                -7.645696941576629
              ],
              [
                -58.20158699193224,
                -7.620545048183272
              ],
              [
                -58.20176649567538,
                -7.608455597904593
              ],
              [
                -58.201945028400786,
                -7.596367184636434
              ],
              [
                -58.21641495924927,
                -7.555152908918266
              ],
              [
                -58.222183484514346,
                -7.523603580173085
              ],
              [
                -58.21261558405621,
                -7.492936229781529
              ],
              [
                -58.21248558704221,
                -7.458079419529906
              ],
              [
                -58.17274739944242,
                -7.393905704422417
              ],
              [
                -58.140162644616176,
                -7.362948338043146
              ],
              [
                -58.139579636374435,
                -7.361896361795213
              ],
              [
                -58.136364688613405,
                -7.356093369015849
              ],
              [
                -58.13712368893835,
                -7.352466411455441
              ],
              [
                -58.13791919823442,
                -7.348659912857028
              ],
              [
                -58.13265119954096,
                -7.349730414570724
              ],
              [
                -58.11950828166413,
                -7.35240136840685
              ],
              [
                -58.066250101864675,
                -7.390902123023101
              ],
              [
                -58.062383607691366,
                -7.395464609318037
              ],
              [
                -58.058516631521265,
                -7.400026554612722
              ],
              [
                -58.03458223658803,
                -7.453763768147384
              ],
              [
                -58.00164488625663,
                -7.487753024908657
              ],
              [
                -57.97206204608489,
                -7.53421277524872
              ],
              [
                -57.944225656087845,
                -7.605121884021026
              ],
              [
                -57.93903813475244,
                -7.652758106961326
              ],
              [
                -57.93645113270539,
                -7.654578109682249
              ],
              [
                -57.93386616464756,
                -7.656397094409777
              ],
              [
                -57.93152565407911,
                -7.656470073361358
              ],
              [
                -57.91566977086513,
                -7.656967051697228
              ],
              [
                -57.90471334323043,
                -7.666214507769163
              ],
              [
                -57.89762635337233,
                -7.675645462312994
              ],
              [
                -57.873316866121485,
                -7.800817268315227
              ],
              [
                -57.84045101463203,
                -7.861100407686151
              ],
              [
                -57.837456029715824,
                -7.871735842807486
              ],
              [
                -57.834460554786844,
                -7.88237177693009
              ],
              [
                -57.832991008628944,
                -7.928186550178699
              ],
              [
                -57.83219197592316,
                -7.953111415020444
              ],
              [
                -57.83025249341224,
                -7.962813378221409
              ],
              [
                -57.82831346288392,
                -7.972516286424041
              ],
              [
                -57.80347812290048,
                -8.003045101152482
              ],
              [
                -57.77600924232847,
                -8.051796341696603
              ],
              [
                -57.72791999130649,
                -8.093836074516059
              ],
              [
                -57.71933900010988,
                -8.146223283066918
              ],
              [
                -57.71461648472873,
                -8.157566712540724
              ],
              [
                -57.70411755894542,
                -8.167644624443364
              ],
              [
                -57.67865020617024,
                -8.170778610526709
              ],
              [
                -57.67672722189509,
                -8.171015084984456
              ],
              [
                -57.671728255026366,
                -8.173550575980917
              ],
              [
                -57.666729301160096,
                -8.176086538972369
              ],
              [
                -57.642751404396755,
                -8.213293350033148
              ],
              [
                -57.64372838305745,
                -8.223230285566494
              ],
              [
                -57.64470487970504,
                -8.233167738111426
              ],
              [
                -57.6516702933233,
                -8.246800662185656
              ],
              [
                -57.661735709026736,
                -8.266499589242455
              ],
              [
                -57.66302570560732,
                -8.280500031586188
              ],
              [
                -57.66431567115624,
                -8.294501421954235
              ],
              [
                -57.672449087088005,
                -8.307544383676246
              ],
              [
                -57.678845532329625,
                -8.317801322457093
              ],
              [
                -57.67869205773442,
                -8.325461269352065
              ],
              [
                -57.678539030128604,
                -8.3331202652592
              ],
              [
                -57.673645531215705,
                -8.34791816004084
              ],
              [
                -57.66863654455727,
                -8.36306659090492
              ],
              [
                -57.66825405118681,
                -8.370850022917768
              ],
              [
                -57.6691560379594,
                -8.376098531401626
              ],
              [
                -57.67188703368156,
                -8.38103746973999
              ],
              [
                -57.68603739702223,
                -8.406626395624144
              ],
              [
                -57.68633239728586,
                -8.41367734825542
              ],
              [
                -57.68106489812793,
                -8.43274374504441
              ],
              [
                -57.675470445208624,
                -8.439774687405855
              ],
              [
                -57.65089904773391,
                -8.470657985059113
              ],
              [
                -57.651310056245784,
                -8.478462477040734
              ],
              [
                -57.65534849666649,
                -8.484999417024309
              ],
              [
                -57.65994948340292,
                -8.492447386119109
              ],
              [
                -57.65853544962135,
                -8.50000334108558
              ],
              [
                -57.63898105563884,
                -8.512031785476715
              ],
              [
                -57.637122089814866,
                -8.513175238336087
              ],
              [
                -57.63514358759814,
                -8.51834524165711
              ],
              [
                -57.63316510837809,
                -8.523515210978655
              ],
              [
                -57.63831552981268,
                -8.540184600142391
              ],
              [
                -57.64414951709685,
                -8.546585084168946
              ],
              [
                -57.64998347237753,
                -8.552986560206971
              ],
              [
                -57.648119444492075,
                -8.575990931544567
              ],
              [
                -57.64426096043052,
                -8.581595415302122
              ],
              [
                -57.636025499389575,
                -8.583610889613832
              ],
              [
                -57.63033652921485,
                -8.585003883316949
              ],
              [
                -57.62172659666198,
                -8.590803822010498
              ],
              [
                -57.62337953564055,
                -8.622313176878416
              ],
              [
                -57.62422603744222,
                -8.63845660542616
              ],
              [
                -57.62003902812416,
                -8.647361552085261
              ],
              [
                -57.612748573441074,
                -8.654571513312193
              ],
              [
                -57.604781607129226,
                -8.662450957279956
              ],
              [
                -57.59309511730803,
                -8.70703472415832
              ],
              [
                -57.60053452543072,
                -8.740844032282247
              ],
              [
                -57.59647658586746,
                -8.748468482582023
              ],
              [
                -57.59241759630588,
                -8.756092454881053
              ],
              [
                -57.56184575078956,
                -8.760866881063478
              ],
              [
                -57.52986701167778,
                -8.747791420519468
              ],
              [
                -57.48433974588283,
                -8.768998226241433
              ],
              [
                -57.46301239379641,
                -8.784358664847236
              ],
              [
                -57.45749641926359,
                -8.78529315594078
              ],
              [
                -57.45198046173392,
                -8.7862286180285
              ],
              [
                -57.450563951898005,
                -8.786037117152658
              ],
              [
                -57.42706212399174,
                -8.782854110666968
              ],
              [
                -57.42133914870931,
                -8.789173584234456
              ],
              [
                -57.41561517643182,
                -8.795494015790648
              ],
              [
                -57.41275714445052,
                -8.825804873700134
              ],
              [
                -57.41995458612847,
                -8.849228756690582
              ],
              [
                -57.4161680859189,
                -8.857568216579235
              ],
              [
                -57.4071586235518,
                -8.860670692078155
              ],
              [
                -57.40290166242412,
                -8.862137161079097
              ],
              [
                -57.380234299568976,
                -8.880738533265768
              ],
              [
                -57.349066511572694,
                -8.88039601803033
              ],
              [
                -57.31453621721505,
                -8.886627930096164
              ],
              [
                -57.30998024327337,
                -8.892425903739577
              ],
              [
                -57.30814673751619,
                -8.899442337791877
              ],
              [
                -57.305513743724475,
                -8.909518822298502
              ],
              [
                -57.29906879197761,
                -8.913610293086792
              ],
              [
                -57.285679374565895,
                -8.91511026484033
              ],
              [
                -57.24812264110041,
                -8.90600473652711
              ],
              [
                -57.211715826728856,
                -8.91630117334541
              ],
              [
                -57.20202841315064,
                -8.922983103660664
              ],
              [
                -57.19234145757808,
                -8.929665571961856
              ],
              [
                -57.13775823709014,
                -8.994226645937065
              ],
              [
                -57.09408846094896,
                -9.016726475636561
              ],
              [
                -57.092296964201886,
                -9.024987947278039
              ],
              [
                -57.09050549344504,
                -9.033249410923682
              ],
              [
                -57.078136043677105,
                -9.052015764107635
              ],
              [
                -57.0737460588664,
                -9.054752272348185
              ],
              [
                -57.059090663631146,
                -9.063890195355025
              ],
              [
                -57.061571101689815,
                -9.07363014419424
              ],
              [
                -57.062731620142735,
                -9.078188146598533
              ],
              [
                -57.055750158684674,
                -9.082488592414471
              ],
              [
                -57.04581720852946,
                -9.08860753803502
              ],
              [
                -57.03872821898967,
                -9.09790400231526
              ],
              [
                -57.04098070283933,
                -9.114707412008071
              ],
              [
                -57.047743657138874,
                -9.122572890171249
              ],
              [
                -57.05992854637554,
                -9.13674333767708
              ],
              [
                -57.05591557733174,
                -9.147408749184628
              ],
              [
                -57.05190207727683,
                -9.158074702690506
              ],
              [
                -57.05736653355093,
                -9.173415146316799
              ],
              [
                -57.059252478310775,
                -9.178709108043735
              ],
              [
                -57.05786550214092,
                -9.182348100730994
              ],
              [
                -57.057310508674654,
                -9.183803082608746
              ],
              [
                -57.053438009171934,
                -9.187539039439647
              ],
              [
                -57.03881061269845,
                -9.196642999499707
              ],
              [
                -57.03597261637164,
                -9.203472931045821
              ],
              [
                -57.033531629828616,
                -9.209346418976525
              ],
              [
                -57.022122701710366,
                -9.219312338996215
              ],
              [
                -57.01134676057271,
                -9.222012809675965
              ],
              [
                -57.00770279651058,
                -9.2229257850991
              ],
              [
                -56.99503386567188,
                -9.233453243520934
              ],
              [
                -56.951734163321994,
                -9.222695249271496
              ],
              [
                -56.94470269908957,
                -9.226369221073453
              ],
              [
                -56.93767222785765,
                -9.230042691874322
              ],
              [
                -56.8718476408621,
                -9.238774547094845
              ],
              [
                -56.86756717787901,
                -9.236099038891576
              ],
              [
                -56.85982024001656,
                -9.231257572010083
              ],
              [
                -56.84367533956668,
                -9.240416506478407
              ],
              [
                -56.819593973874206,
                -9.245952963581768
              ],
              [
                -56.81124753370931,
                -9.256508893477118
              ],
              [
                -56.80290107354071,
                -9.267064797361902
              ],
              [
                -56.79358708546888,
                -9.292158691890176
              ],
              [
                -56.78467312025655,
                -9.316173539468618
              ],
              [
                -56.78152562194165,
                -9.316505536473173
              ],
              [
                -56.778652155915005,
                -9.316808013002756
              ],
              [
                -56.77500767313463,
                -9.329139962247599
              ],
              [
                -56.775063107679436,
                -9.354384835885574
              ],
              [
                -56.77508610988515,
                -9.364898289451263
              ],
              [
                -56.77311763629521,
                -9.369340251158235
              ],
              [
                -56.76909062740356,
                -9.378426187421004
              ],
              [
                -56.76947363656147,
                -9.380137680247307
              ],
              [
                -56.771809110032386,
                -9.39056913944257
              ],
              [
                -56.76059467921403,
                -9.404609036620695
              ],
              [
                -56.753756688107536,
                -9.405973519246428
              ],
              [
                -56.73517585033338,
                -9.39513358273347
              ],
              [
                -56.717818453846355,
                -9.383501603016308
              ],
              [
                -56.714303978081176,
                -9.378699122631154
              ],
              [
                -56.71078850532014,
                -9.373896633252631
              ],
              [
                -56.67136979079436,
                -9.367007138195666
              ],
              [
                -56.273302348932845,
                -9.400423447692962
              ],
              [
                -56.15601161213136,
                -9.410187769548388
              ],
              [
                -55.79158201116239,
                -9.440277636395384
              ],
              [
                -54.9950582787993,
                -9.505017778012304
              ],
              [
                -54.87947708886195,
                -9.514011601703615
              ],
              [
                -54.61319434109821,
                -9.534731623596825
              ],
              [
                -54.335549724328445,
                -9.554878180044284
              ],
              [
                -54.10612130476595,
                -9.571526307794342
              ],
              [
                -53.58795885070923,
                -9.610703954880599
              ],
              [
                -53.33539858085564,
                -9.6294400148806
              ],
              [
                -53.13289797187215,
                -9.64446170589572
              ],
              [
                -52.61949306952599,
                -9.681804860613603
              ],
              [
                -52.35910089242756,
                -9.70011491569416
              ],
              [
                -52.25487012464267,
                -9.707410273027435
              ],
              [
                -51.95166576889913,
                -9.727974760369548
              ],
              [
                -51.3040273978987,
                -9.771900719060582
              ],
              [
                -51.0488192311749,
                -9.787487823786805
              ],
              [
                -50.842426727638255,
                -9.800423491757034
              ],
              [
                -50.22434624526551,
                -9.84075151043438
              ],
              [
                -50.225704724953545,
                -9.843256497350733
              ],
              [
                -50.22676771253741,
                -9.845216009117765
              ],
              [
                -50.22628272506632,
                -9.847489454993076
              ],
              [
                -50.224988203475824,
                -9.853556948297873
              ],
              [
                -50.24332704876506,
                -9.870841858887388
              ],
              [
                -50.263817843461176,
                -9.908096143189743
              ],
              [
                -50.26528181814239,
                -9.921173568712828
              ],
              [
                -50.266745788798005,
                -9.934251496259835
              ],
              [
                -50.27224573143553,
                -9.943902457452817
              ],
              [
                -50.27848219291096,
                -9.95484741599863
              ],
              [
                -50.277976690137706,
                -9.962608364444655
              ],
              [
                -50.27747066335914,
                -9.970369819893607
              ],
              [
                -50.28451761365088,
                -9.981459232731185
              ],
              [
                -50.29020906576483,
                -9.990414716404034
              ],
              [
                -50.29201354137654,
                -10.003059624134332
              ],
              [
                -50.293818007965484,
                -10.015704062893855
              ],
              [
                -50.302183896330064,
                -10.03500895915849
              ],
              [
                -50.307086866385184,
                -10.04013291349661
              ],
              [
                -50.31199031443442,
                -10.045257387842883
              ],
              [
                -50.33937960974634,
                -10.060533358534
              ],
              [
                -50.354211488824525,
                -10.076765269869224
              ],
              [
                -50.356572967644496,
                -10.085432225029939
              ],
              [
                -50.35893441445524,
                -10.0940981662116
              ],
              [
                -50.37199932091954,
                -10.105602601146574
              ],
              [
                -50.377347262262816,
                -10.110312086668726
              ],
              [
                -50.38451719869739,
                -10.12197004282062
              ],
              [
                -50.39168714111633,
                -10.133627478016068
              ],
              [
                -50.3982940472128,
                -10.16369529639761
              ],
              [
                -50.396441044406195,
                -10.170477765070721
              ],
              [
                -50.39401508436863,
                -10.171785745099134
              ],
              [
                -50.38893858377089,
                -10.174524704127371
              ],
              [
                -50.38189408944489,
                -10.221341942842477
              ],
              [
                -50.39762693063703,
                -10.254015746818066
              ],
              [
                -50.40476886667901,
                -10.283796605205753
              ],
              [
                -50.42273666684876,
                -10.330243348235149
              ],
              [
                -50.42028216979384,
                -10.343053757971289
              ],
              [
                -50.41782714872144,
                -10.355864199719342
              ],
              [
                -50.438248984678566,
                -10.373168118925108
              ],
              [
                -50.44059894665065,
                -10.375159599555873
              ],
              [
                -50.44421092587626,
                -10.383161571858746
              ],
              [
                -50.44782288309326,
                -10.391163497178033
              ],
              [
                -50.47326117792231,
                -10.404499444747504
              ],
              [
                -50.48740299361489,
                -10.467565108152879
              ],
              [
                -50.486756978461855,
                -10.470282580435036
              ],
              [
                -50.48215651647169,
                -10.489630993416865
              ],
              [
                -50.48871396018336,
                -10.494715957036199
              ],
              [
                -50.49991186234548,
                -10.495323480214118
              ],
              [
                -50.51561573194716,
                -10.504722913626773
              ],
              [
                -50.51448872728291,
                -10.525554303017959
              ],
              [
                -50.51853019704274,
                -10.532971756630191
              ],
              [
                -50.522571636796364,
                -10.540388739260443
              ],
              [
                -50.521287650063165,
                -10.548809673566115
              ],
              [
                -50.51926463574524,
                -10.562072091737484
              ],
              [
                -50.5251905721582,
                -10.5712320419641
              ],
              [
                -50.53111751555238,
                -10.5803929962122
              ],
              [
                -50.53695793847545,
                -10.604809862936103
              ],
              [
                -50.54252189381497,
                -10.608915841037577
              ],
              [
                -50.55191183877626,
                -10.609844846982996
              ],
              [
                -50.562709245252506,
                -10.61091384912055
              ],
              [
                -50.58210205882668,
                -10.643675700085803
              ],
              [
                -50.60298938557279,
                -10.660527634435931
              ],
              [
                -50.60294438157763,
                -10.661435124126948
              ],
              [
                -50.602360860671595,
                -10.673196552328907
              ],
              [
                -50.58715595178374,
                -10.685543462248834
              ],
              [
                -50.58628148683641,
                -10.694663424304375
              ],
              [
                -50.585406967877994,
                -10.703783340368664
              ],
              [
                -50.581986491410554,
                -10.709571338035186
              ],
              [
                -50.57646199210481,
                -10.718921771108354
              ],
              [
                -50.57694646960863,
                -10.727067199764699
              ],
              [
                -50.57729798336215,
                -10.732975175157259
              ],
              [
                -50.57431447549838,
                -10.740928642953303
              ],
              [
                -50.5701259937181,
                -10.752093545481213
              ],
              [
                -50.589735833229966,
                -10.786042382908
              ],
              [
                -50.592570769863585,
                -10.800678812640246
              ],
              [
                -50.60061170203329,
                -10.802723298486114
              ],
              [
                -50.60671265412566,
                -10.80427429837477
              ],
              [
                -50.611733630066475,
                -10.809912769163441
              ],
              [
                -50.611247630957095,
                -10.821158713701134
              ],
              [
                -50.61124709894101,
                -10.8211721906228
              ],
              [
                -50.621542500206544,
                -10.839004618911394
              ],
              [
                -50.618578495175605,
                -10.863684957627237
              ],
              [
                -50.61117054578823,
                -10.86808843752349
              ],
              [
                -50.60448758674592,
                -10.872061389946452
              ],
              [
                -50.59954859800318,
                -10.88270833051725
              ],
              [
                -50.60032107520674,
                -10.900227248907045
              ],
              [
                -50.62165938987138,
                -10.917794674120453
              ],
              [
                -50.62707136001903,
                -10.92666113611573
              ],
              [
                -50.6324842991483,
                -10.935528572130396
              ],
              [
                -50.62308483426287,
                -10.959920921358114
              ],
              [
                -50.619767330583564,
                -10.968529368093378
              ],
              [
                -50.621414328526974,
                -10.973853345968148
              ],
              [
                -50.624376299449224,
                -10.983426269412876
              ],
              [
                -50.61848479576712,
                -10.998535193101446
              ],
              [
                -50.61712683844844,
                -11.002016654139776
              ],
              [
                -50.61707981290001,
                -11.01055713510718
              ],
              [
                -50.61696326395383,
                -11.031592008380356
              ],
              [
                -50.61398030547409,
                -11.037078461538583
              ],
              [
                -50.610997307992996,
                -11.042564437699195
              ],
              [
                -50.60899280953391,
                -11.067047288501131
              ],
              [
                -50.61263224643,
                -11.07525771688502
              ],
              [
                -50.61627222331107,
                -11.083469186280677
              ],
              [
                -50.63657753837763,
                -11.100136617081827
              ],
              [
                -50.64340347194436,
                -11.122753508948673
              ],
              [
                -50.65101739045026,
                -11.126552492585134
              ],
              [
                -50.65863082196405,
                -11.130351484231824
              ],
              [
                -50.663454247443404,
                -11.158602321135287
              ],
              [
                -50.65944177231965,
                -11.186921648547736
              ],
              [
                -50.659573752682704,
                -11.188009157542233
              ],
              [
                -50.66129820514929,
                -11.202259570857988
              ],
              [
                -50.657255714408564,
                -11.210903524505625
              ],
              [
                -50.65395826766825,
                -11.217952958363512
              ],
              [
                -50.65798168173908,
                -11.247425792458328
              ],
              [
                -50.66966054331771,
                -11.284221093589666
              ],
              [
                -50.67408550395567,
                -11.289371604974978
              ],
              [
                -50.674113517701095,
                -11.289403563835538
              ],
              [
                -50.678510978586786,
                -11.294522545368565
              ],
              [
                -50.67873993971788,
                -11.294647570003878
              ],
              [
                -50.696351808986364,
                -11.304255047039032
              ],
              [
                -50.6982643038748,
                -11.31012249858833
              ],
              [
                -50.70287922798989,
                -11.319848442321168
              ],
              [
                -50.705778693270695,
                -11.325958431012026
              ],
              [
                -50.70559172364268,
                -11.331728359956573
              ],
              [
                -50.70528971026595,
                -11.341077334407007
              ],
              [
                -50.71179214104736,
                -11.359433237099449
              ],
              [
                -50.719917541868405,
                -11.37079369802099
              ],
              [
                -50.728042966674685,
                -11.38215411798559
              ],
              [
                -50.72693645261358,
                -11.411558440630394
              ],
              [
                -50.73878081722143,
                -11.43495034327906
              ],
              [
                -50.74018678854475,
                -11.444720787126165
              ],
              [
                -50.741593749845556,
                -11.454491733987945
              ],
              [
                -50.738878735667555,
                -11.498768458380782
              ],
              [
                -50.7366157262197,
                -11.50507291125201
              ],
              [
                -50.73435274776799,
                -11.511376916127283
              ],
              [
                -50.73970615701509,
                -11.538198242852104
              ],
              [
                -50.71601477121016,
                -11.577798481526415
              ],
              [
                -50.67530856606224,
                -11.58224189628634
              ],
              [
                -50.664456124500795,
                -11.584769861168025
              ],
              [
                -50.660806185296096,
                -11.590369856855387
              ],
              [
                -50.65711518928916,
                -11.596033301119444
              ],
              [
                -50.656060165014836,
                -11.600417274706384
              ],
              [
                -50.65836165136759,
                -11.61717166162342
              ],
              [
                -50.661258613923536,
                -11.621684647639627
              ],
              [
                -50.66415607247256,
                -11.626198125660217
              ],
              [
                -50.66305153294121,
                -11.672069383052019
              ],
              [
                -50.67361690501984,
                -11.692269775669098
              ],
              [
                -50.68165634718211,
                -11.69596125845147
              ],
              [
                -50.689695303352394,
                -11.699653240241531
              ],
              [
                -50.72112299311993,
                -11.731297640414825
              ],
              [
                -50.72134850100578,
                -11.735290081311087
              ],
              [
                -50.7215745048823,
                -11.73928456119889
              ],
              [
                -50.71888600399373,
                -11.745190554753108
              ],
              [
                -50.71351054620345,
                -11.756997482893857
              ],
              [
                -50.70739303943226,
                -11.767819872402619
              ],
              [
                -50.70165207127255,
                -11.79562625104077
              ],
              [
                -50.700119548407635,
                -11.803051666893422
              ],
              [
                -50.69384760677439,
                -11.813838597367782
              ],
              [
                -50.69384561078242,
                -11.813842617342038
              ],
              [
                -50.6875766031252,
                -11.824626027839063
              ],
              [
                -50.68738661145099,
                -11.828343532655033
              ],
              [
                -50.68583857737979,
                -11.858579359413158
              ],
              [
                -50.682182082782624,
                -11.862485322680838
              ],
              [
                -50.67054719746418,
                -11.864681797872464
              ],
              [
                -50.65929225749633,
                -11.866806750066576
              ],
              [
                -50.6388218822042,
                -11.884151638128131
              ],
              [
                -50.639463375641284,
                -11.893212558264098
              ],
              [
                -50.64010434807216,
                -11.902272030419569
              ],
              [
                -50.655488714384205,
                -11.911921497772491
              ],
              [
                -50.65864618997285,
                -11.913902481691844
              ],
              [
                -50.66333865174766,
                -11.921086953876442
              ],
              [
                -50.663940139821044,
                -11.922008444668057
              ],
              [
                -50.66742810645204,
                -11.92735143927284
              ],
              [
                -50.66093617263139,
                -11.937325356984616
              ],
              [
                -50.654268677649405,
                -11.94756828090471
              ],
              [
                -50.65514268525184,
                -11.95544173916723
              ],
              [
                -50.65636465514165,
                -11.966434204518995
              ],
              [
                -50.66407908150219,
                -11.973731643991824
              ],
              [
                -50.66545604124956,
                -11.9750346408998
              ],
              [
                -50.67635644625508,
                -11.985347125629747
              ],
              [
                -50.68250990964324,
                -11.99409956151453
              ],
              [
                -50.6833573691631,
                -12.001502031452794
              ],
              [
                -50.683601876594075,
                -12.010412465994822
              ],
              [
                -50.682195858647766,
                -12.012374965756088
              ],
              [
                -50.67737940181182,
                -12.01909642941121
              ],
              [
                -50.68530632067899,
                -12.032943857731125
              ],
              [
                -50.6866073133611,
                -12.035216329122091
              ],
              [
                -50.686368316214775,
                -12.039965834186637
              ],
              [
                -50.68612928306394,
                -12.044716274248541
              ],
              [
                -50.6794538256431,
                -12.051550258287197
              ],
              [
                -50.66715489588304,
                -12.06414164106571
              ],
              [
                -50.66698240930479,
                -12.072445587379066
              ],
              [
                -50.66681039471206,
                -12.080750054698857
              ],
              [
                -50.670065841276525,
                -12.096647465164239
              ],
              [
                -50.67556074805846,
                -12.123480321274803
              ],
              [
                -50.67409474877737,
                -12.128275813136582
              ],
              [
                -50.67040177860244,
                -12.14036323470031
              ],
              [
                -50.67616069812329,
                -12.150622670834434
              ],
              [
                -50.67857017023264,
                -12.15491565281379
              ],
              [
                -50.67869862896383,
                -12.184232980610341
              ],
              [
                -50.67871363007783,
                -12.187660966534878
              ],
              [
                -50.67899662312911,
                -12.18817848511305
              ],
              [
                -50.686406056900644,
                -12.20173489469428
              ],
              [
                -50.67820907485467,
                -12.218432286327952
              ],
              [
                -50.65496376457426,
                -12.217787271925447
              ],
              [
                -50.64939981261214,
                -12.220045745250781
              ],
              [
                -50.6438363396507,
                -12.22230423157505
              ],
              [
                -50.64948675049933,
                -12.248706104775168
              ],
              [
                -50.63187237080739,
                -12.268220446260933
              ],
              [
                -50.630899343816715,
                -12.274786912110327
              ],
              [
                -50.629926857817026,
                -12.28135289296731
              ],
              [
                -50.64844418536111,
                -12.29866832006379
              ],
              [
                -50.642616188441586,
                -12.319070159036261
              ],
              [
                -50.637353212220866,
                -12.32487214211209
              ],
              [
                -50.64088516847858,
                -12.337063598174845
              ],
              [
                -50.64358064378791,
                -12.346369529917286
              ],
              [
                -50.629660716094,
                -12.373265373522434
              ],
              [
                -50.62805317510901,
                -12.392997727297487
              ],
              [
                -50.6295926697268,
                -12.401782197089048
              ],
              [
                -50.63187210870348,
                -12.414753144894293
              ],
              [
                -50.62536867254704,
                -12.421134577695987
              ],
              [
                -50.617789196491536,
                -12.428570524855754
              ],
              [
                -50.62280165097677,
                -12.453352399747738
              ],
              [
                -50.62652908995376,
                -12.45777088745829
              ],
              [
                -50.63523352355013,
                -12.454527896924759
              ],
              [
                -50.64179497553341,
                -12.452082455400284
              ],
              [
                -50.65085493697174,
                -12.458446409225479
              ],
              [
                -50.64606791193062,
                -12.47943029302242
              ],
              [
                -50.64555241781458,
                -12.48169025664777
              ],
              [
                -50.65233985519563,
                -12.493678198612264
              ],
              [
                -50.65912829054867,
                -12.505666657617063
              ],
              [
                -50.6645451977445,
                -12.536794487547763
              ],
              [
                -50.67712459641092,
                -12.551687912351134
              ],
              [
                -50.68156552490558,
                -12.560234400604894
              ],
              [
                -50.6860074913814,
                -12.56878134987799
              ],
              [
                -50.675921032577406,
                -12.575622790147289
              ],
              [
                -50.668960601504004,
                -12.580343779374214
              ],
              [
                -50.667513062548686,
                -12.595587145787553
              ],
              [
                -50.67340500806113,
                -12.599698137024637
              ],
              [
                -50.68060347470913,
                -12.597090674540478
              ],
              [
                -50.69395787502609,
                -12.592253722723154
              ],
              [
                -50.702172827130866,
                -12.598289688273468
              ],
              [
                -50.70602176064552,
                -12.609279134171787
              ],
              [
                -50.70464276623963,
                -12.613015632247524
              ],
              [
                -50.70067679553298,
                -12.623781573211327
              ],
              [
                -50.70059977292143,
                -12.625025046227991
              ],
              [
                -50.69922028391777,
                -12.647353916865246
              ],
              [
                -50.68208836905503,
                -12.646651391755961
              ],
              [
                -50.65573709868782,
                -12.645570373158977
              ],
              [
                -50.646460162925365,
                -12.650698333195468
              ],
              [
                -50.64587910896424,
                -12.6633717743278
              ],
              [
                -50.64900909569018,
                -12.666932234997187
              ],
              [
                -50.65440905218307,
                -12.673074710285874
              ],
              [
                -50.65429603602538,
                -12.678758184774445
              ],
              [
                -50.644654090378694,
                -12.70181702737876
              ],
              [
                -50.63765763857101,
                -12.701410523742538
              ],
              [
                -50.63273768827798,
                -12.701124042970996
              ],
              [
                -50.629658701679716,
                -12.704109023297281
              ],
              [
                -50.63333564567109,
                -12.712027474914096
              ],
              [
                -50.63674160067808,
                -12.719361444305438
              ],
              [
                -50.62281368156684,
                -12.733880320663888
              ],
              [
                -50.62674161465582,
                -12.760471668961863
              ],
              [
                -50.635523556601065,
                -12.771437124609044
              ],
              [
                -50.62978856435835,
                -12.775646084669479
              ],
              [
                -50.622687641237185,
                -12.773611587743991
              ],
              [
                -50.61437569765362,
                -12.771229604670717
              ],
              [
                -50.61129670780876,
                -12.775474069059275
              ],
              [
                -50.61807713755692,
                -12.787506519431636
              ],
              [
                -50.6248905799379,
                -12.799597452580688
              ],
              [
                -50.62610353194718,
                -12.81145439147287
              ],
              [
                -50.6222630352567,
                -12.8193123457088
              ],
              [
                -50.61765009790476,
                -12.82117985554558
              ],
              [
                -50.6130416145334,
                -12.817979830245392
              ],
              [
                -50.60983615116294,
                -12.812340868762409
              ],
              [
                -50.602843247510066,
                -12.800040444454082
              ],
              [
                -50.57905639571708,
                -12.801751889384628
              ],
              [
                -50.60018421238021,
                -12.81846130558976
              ],
              [
                -50.59855070524335,
                -12.823057312520707
              ],
              [
                -50.59737920901442,
                -12.826352268397363
              ],
              [
                -50.56648694705441,
                -12.842999140926786
              ],
              [
                -50.53151917455589,
                -12.843201093902717
              ],
              [
                -50.51987427532299,
                -12.835970582774994
              ],
              [
                -50.51262483014838,
                -12.844218028943233
              ],
              [
                -50.51237382745172,
                -12.846213026526875
              ],
              [
                -50.51164683452079,
                -12.851998988420757
              ],
              [
                -50.5106062994593,
                -12.86027795605533
              ],
              [
                -50.50200233895261,
                -12.872630383297238
              ],
              [
                -50.5017683465627,
                -12.883909809813119
              ],
              [
                -50.50949227122183,
                -12.89417524360245
              ],
              [
                -50.513901241210604,
                -12.900034741954437
              ],
              [
                -50.51554620455337,
                -12.91318565747312
              ],
              [
                -50.5164161666767,
                -12.920145136714135
              ],
              [
                -50.52045364273714,
                -12.92859856321311
              ],
              [
                -50.524490588791764,
                -12.93705154873243
              ],
              [
                -50.524687065207004,
                -12.944931987785917
              ],
              [
                -50.52546302667072,
                -12.975995831581661
              ],
              [
                -50.53361743439348,
                -12.979380836117967
              ],
              [
                -50.54177186812193,
                -12.982765811665132
              ],
              [
                -50.54328837332191,
                -12.984770313296877
              ],
              [
                -50.556354729794,
                -13.002035752283213
              ],
              [
                -50.564932691893894,
                -13.003486748211362
              ],
              [
                -50.5715371305468,
                -13.000510779973688
              ],
              [
                -50.57834158789552,
                -12.997443301581468
              ],
              [
                -50.59253895520639,
                -13.00243128889389
              ],
              [
                -50.58707346757441,
                -13.023983145411288
              ],
              [
                -50.5788120486879,
                -13.028304635394955
              ],
              [
                -50.57153756346216,
                -13.032110603912075
              ],
              [
                -50.56870761640874,
                -13.038704558892714
              ],
              [
                -50.57645853358386,
                -13.050659505955752
              ],
              [
                -50.60479529304355,
                -13.05648802344425
              ],
              [
                -50.605927799271335,
                -13.056720996144547
              ],
              [
                -50.608108782817624,
                -13.059683995749149
              ],
              [
                -50.610289261367484,
                -13.062646499358637
              ],
              [
                -50.58983032252819,
                -13.109248690001728
              ],
              [
                -50.5986496755366,
                -13.145948522108263
              ],
              [
                -50.594926183851456,
                -13.17009488286014
              ],
              [
                -50.59952513239886,
                -13.177481327670826
              ],
              [
                -50.60131812289696,
                -13.180360818127104
              ],
              [
                -50.6026575834436,
                -13.193765248428804
              ],
              [
                -50.58845615454714,
                -13.209394153248358
              ],
              [
                -50.586063694369265,
                -13.212026634687225
              ],
              [
                -50.58455418507632,
                -13.219339562181087
              ],
              [
                -50.58422814746603,
                -13.223157545805993
              ],
              [
                -50.587758087280235,
                -13.248771925550344
              ],
              [
                -50.60493793068678,
                -13.276857792143256
              ],
              [
                -50.60673685132566,
                -13.310428106863338
              ],
              [
                -50.60970382773543,
                -13.31458160756966
              ],
              [
                -50.61169379588659,
                -13.317368088970062
              ],
              [
                -50.62376120051327,
                -13.334258507723224
              ],
              [
                -50.63848057462885,
                -13.34518295265819
              ],
              [
                -50.64218703182238,
                -13.35296443106027
              ],
              [
                -50.645892978011474,
                -13.360745403480122
              ],
              [
                -50.65023095175141,
                -13.36411038897899
              ],
              [
                -50.665182814877376,
                -13.375709354825503
              ],
              [
                -50.666929260482284,
                -13.387248794449675
              ],
              [
                -50.66867575106669,
                -13.398787727099721
              ],
              [
                -50.66820175444159,
                -13.400994216307247
              ],
              [
                -50.66327622389394,
                -13.423930105357563
              ],
              [
                -50.66498919469866,
                -13.441854009473076
              ],
              [
                -50.67754010176733,
                -13.443115491185907
              ],
              [
                -50.682973570675756,
                -13.443662015988796
              ],
              [
                -50.69084897779963,
                -13.46221440589023
              ],
              [
                -50.696490900228916,
                -13.475506333256558
              ],
              [
                -50.70560082525073,
                -13.48338482213353
              ],
              [
                -50.71471074026933,
                -13.49126381303723
              ],
              [
                -50.74459901506553,
                -13.50715976026091
              ],
              [
                -50.762984317296436,
                -13.529582168723527
              ],
              [
                -50.76801815916495,
                -13.596614334300877
              ],
              [
                -50.77444861780709,
                -13.607651750696853
              ],
              [
                -50.78087903843197,
                -13.618689230128403
              ],
              [
                -50.787441419262386,
                -13.647971553937072
              ],
              [
                -50.80348974865506,
                -13.690535864107988
              ],
              [
                -50.822491076042965,
                -13.712792258783281
              ],
              [
                -50.830375011967966,
                -13.712287809952523
              ],
              [
                -50.83825946189811,
                -13.711783296123839
              ],
              [
                -50.859937764509155,
                -13.718473321157703
              ],
              [
                -50.86562920399491,
                -13.72556677570283
              ],
              [
                -50.87132066447464,
                -13.73266024926613
              ],
              [
                -50.8533422367903,
                -13.765343030592044
              ],
              [
                -50.85607215003187,
                -13.795197888256654
              ],
              [
                -50.854857141632586,
                -13.801578856964873
              ],
              [
                -50.853043648557524,
                -13.811098297827655
              ],
              [
                -50.85348063607642,
                -13.813816795134926
              ],
              [
                -50.856264119061315,
                -13.831114709043364
              ],
              [
                -50.84095865184427,
                -13.862489996436752
              ],
              [
                -50.84074861516571,
                -13.889329845062402
              ],
              [
                -50.84297758734128,
                -13.896210303569108
              ],
              [
                -50.84520605351493,
                -13.903089802090442
              ],
              [
                -50.84300303845384,
                -13.915026229457899
              ],
              [
                -50.840822537437774,
                -13.926844660507417
              ],
              [
                -50.84302952011513,
                -13.934041136337541
              ],
              [
                -50.845236462786445,
                -13.941236571184385
              ],
              [
                -50.84994445385032,
                -13.945455549536575
              ],
              [
                -50.862260806521974,
                -13.95649304086995
              ],
              [
                -50.86368581020222,
                -13.968138968660037
              ],
              [
                -50.86511125685647,
                -13.97978541547073
              ],
              [
                -50.84876087094903,
                -14.00033428073778
              ],
              [
                -50.85139927670301,
                -14.02869711414753
              ],
              [
                -50.83131885040644,
                -14.072950845675454
              ],
              [
                -50.83317732615573,
                -14.089411761916484
              ],
              [
                -50.84159770130696,
                -14.104950679469287
              ],
              [
                -50.84776114770211,
                -14.109576171607355
              ],
              [
                -50.849488655397174,
                -14.110872696004039
              ],
              [
                -50.868577498278384,
                -14.125199633245321
              ],
              [
                -50.8797873770168,
                -14.126035142023163
              ],
              [
                -50.890996294777,
                -14.126871642800303
              ],
              [
                -50.89657928542012,
                -14.120946219688916
              ],
              [
                -50.90432023771714,
                -14.112730758762048
              ],
              [
                -50.91334315841341,
                -14.11115026717323
              ],
              [
                -50.91701411996639,
                -14.114384286018362
              ],
              [
                -50.91815362676931,
                -14.125364715145516
              ],
              [
                -50.90988014651129,
                -14.149090096140505
              ],
              [
                -50.91287308610884,
                -14.169351988828666
              ],
              [
                -50.91929902387681,
                -14.176326443217935
              ],
              [
                -50.92572546663632,
                -14.183300898627664
              ],
              [
                -50.93657080150173,
                -14.209961784681688
              ],
              [
                -50.96081905793172,
                -14.248912640805141
              ],
              [
                -50.974791407616124,
                -14.290421925270692
              ],
              [
                -50.96881884768542,
                -14.333489184628013
              ],
              [
                -50.98010022459036,
                -14.353780109844818
              ],
              [
                -50.98473767585965,
                -14.35802408151133
              ],
              [
                -50.98937614411925,
                -14.362268583185344
              ],
              [
                -50.987903086975834,
                -14.395780899717156
              ],
              [
                -50.99859948443594,
                -14.418797806853638
              ],
              [
                -50.99235900774462,
                -14.427949226888936
              ],
              [
                -50.98611854105028,
                -14.437100159924242
              ],
              [
                -50.986320524887184,
                -14.445771613940371
              ],
              [
                -50.9865739807764,
                -14.456676086386462
              ],
              [
                -50.980947541102324,
                -14.461629030575226
              ],
              [
                -50.97532103742995,
                -14.46658251375807
              ],
              [
                -50.96774508401019,
                -14.481867901117878
              ],
              [
                -50.970100026946945,
                -14.491019380836985
              ],
              [
                -50.9724544998724,
                -14.500171329569756
              ],
              [
                -50.96240205173111,
                -14.526628653769194
              ],
              [
                -50.996705174294625,
                -14.579015963277595
              ],
              [
                -50.99587367533346,
                -14.580513952843912
              ],
              [
                -50.995042700370455,
                -14.58201092141658
              ],
              [
                -50.99130364925346,
                -14.613354237777475
              ],
              [
                -51.01854137782401,
                -14.661070551905127
              ],
              [
                -51.02163985040083,
                -14.662806037973741
              ],
              [
                -51.02606929962727,
                -14.66528751778429
              ],
              [
                -51.031094741814,
                -14.679641490086329
              ],
              [
                -51.026730214406975,
                -14.706359803731123
              ],
              [
                -51.04882001327938,
                -14.734874716388127
              ],
              [
                -51.05348544622577,
                -14.746225686768232
              ],
              [
                -51.05239691466436,
                -14.759902081741908
              ],
              [
                -51.04541146708284,
                -14.776172018618688
              ],
              [
                -51.04198595861244,
                -14.784151424498791
              ],
              [
                -51.04282592347259,
                -14.78842541802915
              ],
              [
                -51.043666948320904,
                -14.792699893562547
              ],
              [
                -51.0679002008224,
                -14.809865850830686
              ],
              [
                -51.089348987024614,
                -14.840725231979661
              ],
              [
                -51.086401960506734,
                -14.862192627243306
              ],
              [
                -51.090624941957,
                -14.87236609215125
              ],
              [
                -51.09106839148054,
                -14.891412459761243
              ],
              [
                -51.087821394835544,
                -14.896881442061714
              ],
              [
                -51.08253090796167,
                -14.905792374523223
              ],
              [
                -51.08553137955872,
                -14.91727933329073
              ],
              [
                -51.1088231672896,
                -14.929434794289346
              ],
              [
                -51.132786999363525,
                -14.94194078194367
              ],
              [
                -51.146431352558295,
                -14.95907770517753
              ],
              [
                -51.14554583531496,
                -14.975892628951435
              ],
              [
                -51.1454863263588,
                -14.977017642049333
              ],
              [
                -51.14973579623342,
                -14.982586105979616
              ],
              [
                -51.19818187026831,
                -15.0044485933159
              ],
              [
                -51.24212699404315,
                -15.034960011693059
              ],
              [
                -51.27142875263804,
                -15.037371529611855
              ],
              [
                -51.29001561262415,
                -15.033890602016742
              ],
              [
                -51.29757062198398,
                -15.022769185166082
              ],
              [
                -51.30027261082105,
                -14.997207819378055
              ],
              [
                -51.30044112699111,
                -14.995613331877058
              ],
              [
                -51.30140612240776,
                -14.986483872544785
              ],
              [
                -51.310094096385235,
                -14.980055908966623
              ],
              [
                -51.32212852563213,
                -14.971374996657888
              ],
              [
                -51.33731791391672,
                -14.972934502305645
              ],
              [
                -51.34359832882026,
                -14.982325445948842
              ],
              [
                -51.348565261538155,
                -14.989752433006533
              ],
              [
                -51.349577273884194,
                -14.991265924314197
              ],
              [
                -51.35678670598054,
                -15.0020464007919
              ],
              [
                -51.35822867377156,
                -15.002812372793363
              ],
              [
                -51.36955107890601,
                -15.00882888495746
              ],
              [
                -51.37632254657686,
                -15.012426868285258
              ],
              [
                -51.39787841574914,
                -15.003039972799925
              ],
              [
                -51.425682181306875,
                -15.007633499624097
              ],
              [
                -51.44121053537183,
                -15.029671400766782
              ],
              [
                -51.44657096690382,
                -15.03164339259188
              ],
              [
                -51.45193144543921,
                -15.033614898423748
              ],
              [
                -51.47726721532853,
                -15.056282812509496
              ],
              [
                -51.511898455554935,
                -15.054199906487828
              ],
              [
                -51.52176236628621,
                -15.057991420282605
              ],
              [
                -51.5354817617538,
                -15.069250879024667
              ],
              [
                -51.54141215881708,
                -15.0820447991748
              ],
              [
                -51.55226406295138,
                -15.105452219597542
              ],
              [
                -51.562907950783824,
                -15.109337706634467
              ],
              [
                -51.573551395631185,
                -15.113222721689526
              ],
              [
                -51.583059795646314,
                -15.129631164040397
              ],
              [
                -51.597763623215556,
                -15.155005530769833
              ],
              [
                -51.60668103158864,
                -15.163067512292386
              ],
              [
                -51.61773346857722,
                -15.164138043547151
              ],
              [
                -51.62838485859469,
                -15.165169053186053
              ],
              [
                -51.651614165049594,
                -15.179437517834025
              ],
              [
                -51.65202012385144,
                -15.192855457973527
              ],
              [
                -51.65242511163099,
                -15.206273400136755
              ],
              [
                -51.654027596771776,
                -15.210473876256028
              ],
              [
                -51.66754192172101,
                -15.245907210583766
              ],
              [
                -51.65928546470235,
                -15.254245165563736
              ],
              [
                -51.65749099082637,
                -15.256057658733823
              ],
              [
                -51.64706752168191,
                -15.260263097039758
              ],
              [
                -51.64645753026067,
                -15.267507080304664
              ],
              [
                -51.67823624265879,
                -15.290163019019253
              ],
              [
                -51.67831623695006,
                -15.290220007898263
              ],
              [
                -51.68306671730235,
                -15.29931847599781
              ],
              [
                -51.68781763563714,
                -15.308417454119008
              ],
              [
                -51.685763066985615,
                -15.345479237750897
              ],
              [
                -51.679142141962004,
                -15.351523216661471
              ],
              [
                -51.67315714857949,
                -15.356987649798318
              ],
              [
                -51.67089864448161,
                -15.371289074332113
              ],
              [
                -51.688909953961414,
                -15.397542982176704
              ],
              [
                -51.69714787924559,
                -15.40955142018572
              ],
              [
                -51.69746234766603,
                -15.417347409796097
              ],
              [
                -51.694497383343254,
                -15.421386350381622
              ],
              [
                -51.69104189711623,
                -15.426093845594563
              ],
              [
                -51.69239836530797,
                -15.432810803795315
              ],
              [
                -51.70793021835322,
                -15.456035701050634
              ],
              [
                -51.69909023743873,
                -15.473300620208922
              ],
              [
                -51.699495711441685,
                -15.484090544115576
              ],
              [
                -51.70419014704989,
                -15.496257013156335
              ],
              [
                -51.72548297277767,
                -15.502034036980145
              ],
              [
                -51.73498087973599,
                -15.510430007013651
              ],
              [
                -51.73556584383285,
                -15.522793944552888
              ],
              [
                -51.733703890383204,
                -15.526385913720022
              ],
              [
                -51.72551338379341,
                -15.542182827699522
              ],
              [
                -51.727322358723576,
                -15.5501612707212
              ],
              [
                -51.74162623449973,
                -15.55634426702541
              ],
              [
                -51.75157266158099,
                -15.553829327510698
              ],
              [
                -51.75393669357303,
                -15.54837083168732
              ],
              [
                -51.76136766481186,
                -15.531212450837115
              ],
              [
                -51.775810546070154,
                -15.537214429327948
              ],
              [
                -51.77543049259505,
                -15.557844849787744
              ],
              [
                -51.75319903413018,
                -15.609735528617263
              ],
              [
                -51.76248492346081,
                -15.645193349991494
              ],
              [
                -51.803040964830124,
                -15.708746138809135
              ],
              [
                -51.803167464895864,
                -15.715801575125118
              ],
              [
                -51.80329344795939,
                -15.72285604945174
              ],
              [
                -51.817458331868515,
                -15.7320115473148
              ],
              [
                -51.82577376402238,
                -15.737387016449194
              ],
              [
                -51.83109621026175,
                -15.74396351327325
              ],
              [
                -51.832734194824546,
                -15.74637900282302
              ],
              [
                -51.835036146383395,
                -15.750183976921589
              ],
              [
                -51.83894159905004,
                -15.756711960988248
              ],
              [
                -51.84404806137736,
                -15.765248918707224
              ],
              [
                -51.85782692721841,
                -15.78617432982872
              ],
              [
                -51.865786859749896,
                -15.793820804133158
              ],
              [
                -51.87374678627759,
                -15.801466807465983
              ],
              [
                -51.877813708974614,
                -15.819243214528399
              ],
              [
                -51.87911967138826,
                -15.824951194195037
              ],
              [
                -51.880826667440246,
                -15.825611171606749
              ],
              [
                -51.883636625481266,
                -15.826697188287431
              ],
              [
                -51.89177908495441,
                -15.829845178261838
              ],
              [
                -51.91154148132998,
                -15.81313032603422
              ],
              [
                -51.94634869989648,
                -15.81011989357508
              ],
              [
                -51.95533663156955,
                -15.82100286132893
              ],
              [
                -51.95466462064573,
                -15.827462322111204
              ],
              [
                -51.95364861764472,
                -15.83722177193051
              ],
              [
                -51.95931506046276,
                -15.839817293357887
              ],
              [
                -51.972204457232245,
                -15.837411301914972
              ],
              [
                -51.98279139416522,
                -15.843540804579826
              ],
              [
                -51.995861709201485,
                -15.872640704869257
              ],
              [
                -52.00170813442177,
                -15.878838175076803
              ],
              [
                -52.00755512263166,
                -15.885036626296715
              ],
              [
                -52.057620747395745,
                -15.886971235954038
              ],
              [
                -52.080935595269345,
                -15.880402329493325
              ],
              [
                -52.091318480749834,
                -15.88220483411049
              ],
              [
                -52.09244398674441,
                -15.883434309527614
              ],
              [
                -52.096820419948294,
                -15.888215301600251
              ],
              [
                -52.10161441502331,
                -15.88567583038764
              ],
              [
                -52.10827535183923,
                -15.882147344046816
              ],
              [
                -52.1280416808976,
                -15.894513353350279
              ],
              [
                -52.19248376038447,
                -15.882158035167361
              ],
              [
                -52.20118268316373,
                -15.885713533655208
              ],
              [
                -52.209881591950825,
                -15.889268517158026
              ],
              [
                -52.21648506158241,
                -15.889077049397267
              ],
              [
                -52.235287446744266,
                -15.888531071798926
              ],
              [
                -52.244696345896436,
                -15.890588105168693
              ],
              [
                -52.252231772600645,
                -15.892650575458594
              ],
              [
                -52.25188976531811,
                -15.896864565194752
              ],
              [
                -52.25186027868617,
                -15.897229567353342
              ],
              [
                -52.25098527804256,
                -15.90801003596452
              ],
              [
                -52.25776667421638,
                -15.932047917717439
              ],
              [
                -52.26118264191325,
                -15.934884898344588
              ],
              [
                -52.26365562625195,
                -15.93693838773517
              ],
              [
                -52.264194107021744,
                -15.941386855275855
              ],
              [
                -52.264817100547184,
                -15.946536365595662
              ],
              [
                -52.272574011866475,
                -15.95279984003397
              ],
              [
                -52.28857637105248,
                -15.965719787127533
              ],
              [
                -52.289109829835176,
                -15.975319234686076
              ],
              [
                -52.28964379659621,
                -15.984919716254588
              ],
              [
                -52.3099891080356,
                -16.006955637803767
              ],
              [
                -52.31492703559,
                -16.027377056650845
              ],
              [
                -52.31761899638158,
                -16.03068655751296
              ],
              [
                -52.32287145929035,
                -16.03714352974604
              ],
              [
                -52.32108447945898,
                -16.0430154804059
              ],
              [
                -52.32057395622313,
                -16.044691980172107
              ],
              [
                -52.32726787281746,
                -16.06815137533651
              ],
              [
                -52.34976767011259,
                -16.0835023345248
              ],
              [
                -52.34979665991243,
                -16.083498363607706
              ],
              [
                -52.36039059911023,
                -16.08189837561033
              ],
              [
                -52.367092060686126,
                -16.08402589005928
              ],
              [
                -52.369186006367805,
                -16.091760844313768
              ],
              [
                -52.369996002473286,
                -16.094754338643472
              ],
              [
                -52.37462395351578,
                -16.09795032439514
              ],
              [
                -52.39735028701176,
                -16.093000381267526
              ],
              [
                -52.43701049446187,
                -16.109663889255863
              ],
              [
                -52.457927301516264,
                -16.127053363661748
              ],
              [
                -52.470998679067016,
                -16.126276884237402
              ],
              [
                -52.48639558342009,
                -16.129926397671188
              ],
              [
                -52.48798706405388,
                -16.130303885369997
              ],
              [
                -52.49410749780633,
                -16.13627939516669
              ],
              [
                -52.500227436558134,
                -16.142255345976004
              ],
              [
                -52.50401192740273,
                -16.139666393774025
              ],
              [
                -52.51304188293548,
                -16.133487440401776
              ],
              [
                -52.51650585786136,
                -16.134803919731336
              ],
              [
                -52.52503978880913,
                -16.140115429085867
              ],
              [
                -52.526082226550535,
                -16.150571894202074
              ],
              [
                -52.52660022250913,
                -16.155769829405116
              ],
              [
                -52.5320156761862,
                -16.158009839640428
              ],
              [
                -52.548123061365985,
                -16.1646738293367
              ],
              [
                -52.54503804667257,
                -16.17433577718737
              ],
              [
                -52.54312653823487,
                -16.18032324925407
              ],
              [
                -52.54969700116836,
                -16.188637234981787
              ],
              [
                -52.54975348259174,
                -16.188708727764546
              ],
              [
                -52.54974347022919,
                -16.18889174286557
              ],
              [
                -52.54894744849881,
                -16.202975662641165
              ],
              [
                -52.54520393296405,
                -16.222945075719654
              ],
              [
                -52.55610387637378,
                -16.223813055787144
              ],
              [
                -52.56621426763833,
                -16.224617081412138
              ],
              [
                -52.56880376049775,
                -16.230281094078002
              ],
              [
                -52.55378531432778,
                -16.24846644769975
              ],
              [
                -52.54584835958838,
                -16.258076902074745
              ],
              [
                -52.54681134403955,
                -16.260846378976783
              ],
              [
                -52.55457528270805,
                -16.265197894468216
              ],
              [
                -52.561843212752805,
                -16.26460889847575
              ],
              [
                -52.56718621993726,
                -16.258882438744312
              ],
              [
                -52.57592914933622,
                -16.24951051200211
              ],
              [
                -52.58042611904462,
                -16.251148493188325
              ],
              [
                -52.58257109866778,
                -16.25698498108146
              ],
              [
                -52.5862185386971,
                -16.266910926801888
              ],
              [
                -52.602915433184776,
                -16.270385928441513
              ],
              [
                -52.610263373640606,
                -16.27191496353413
              ],
              [
                -52.6166382848002,
                -16.27942443093502
              ],
              [
                -52.623013723948844,
                -16.286933896358835
              ],
              [
                -52.63699415242313,
                -16.283148457623124
              ],
              [
                -52.64728604603619,
                -16.284967953937834
              ],
              [
                -52.649329047108,
                -16.283812970004142
              ],
              [
                -52.65656651585329,
                -16.279722509656718
              ],
              [
                -52.673306850733056,
                -16.29590097402484
              ],
              [
                -52.68109676368038,
                -16.303429462622425
              ],
              [
                -52.68643121347685,
                -16.31503788434619
              ],
              [
                -52.681181256750975,
                -16.32064487192705
              ],
              [
                -52.68520868496593,
                -16.32806783765632
              ],
              [
                -52.681815674242046,
                -16.343089236654322
              ],
              [
                -52.690169626135265,
                -16.34892825365074
              ],
              [
                -52.692706103634485,
                -16.350701220920524
              ],
              [
                -52.68923259188449,
                -16.35639970505983
              ],
              [
                -52.69248703279884,
                -16.367893674789705
              ],
              [
                -52.68479156654796,
                -16.374771118851097
              ],
              [
                -52.677510106217724,
                -16.381278074596274
              ],
              [
                -52.68207160197984,
                -16.387676063480463
              ],
              [
                -52.68790253130539,
                -16.395856012780165
              ],
              [
                -52.67614955715643,
                -16.411864910383372
              ],
              [
                -52.66167616514208,
                -16.40985138635498
              ],
              [
                -52.65001526597002,
                -16.408228876814025
              ],
              [
                -52.64295630894786,
                -16.412296842615355
              ],
              [
                -52.64062581409363,
                -16.41628231044777
              ],
              [
                -52.64257830289337,
                -16.42089432194877
              ],
              [
                -52.644649252161095,
                -16.42578576739584
              ],
              [
                -52.638068286286995,
                -16.43663719507281
              ],
              [
                -52.628714344397764,
                -16.442107689030873
              ],
              [
                -52.61565343397292,
                -16.44974562233773
              ],
              [
                -52.61999888887668,
                -16.45544308409734
              ],
              [
                -52.62090235749786,
                -16.456628562510048
              ],
              [
                -52.62023836942212,
                -16.464411541086484
              ],
              [
                -52.61623540103474,
                -16.461201552283875
              ],
              [
                -52.61198941786875,
                -16.457797048861696
              ],
              [
                -52.6039009945974,
                -16.464213502163705
              ],
              [
                -52.61134941511455,
                -16.46867751378765
              ],
              [
                -52.612815394422505,
                -16.469556492926095
              ],
              [
                -52.610628408545956,
                -16.473116968098225
              ],
              [
                -52.60539642258492,
                -16.481634446879816
              ],
              [
                -52.61209835762861,
                -16.5016793499774
              ],
              [
                -52.62125326559883,
                -16.500885375443286
              ],
              [
                -52.63207968689593,
                -16.49994589838005
              ],
              [
                -52.637064668861356,
                -16.502468909704582
              ],
              [
                -52.634730657096185,
                -16.512164839503047
              ],
              [
                -52.62952017437477,
                -16.51720179585544
              ],
              [
                -52.62926616360729,
                -16.517447295117794
              ],
              [
                -52.62565315077141,
                -16.533002721284305
              ],
              [
                -52.63419811622893,
                -16.537511724309283
              ],
              [
                -52.63526204222751,
                -16.551187666019338
              ],
              [
                -52.64651601210601,
                -16.5454707205888
              ],
              [
                -52.6475394889306,
                -16.546210692410895
              ],
              [
                -52.65026244851979,
                -16.548179212279365
              ],
              [
                -52.65036044668226,
                -16.55069320161467
              ],
              [
                -52.65076441752808,
                -16.561038657623868
              ],
              [
                -52.65573490678069,
                -16.556512163303857
              ],
              [
                -52.66220984754634,
                -16.55061521387366
              ],
              [
                -52.66812479930541,
                -16.550064241902287
              ],
              [
                -52.66981930090848,
                -16.55874969070871
              ],
              [
                -52.6638578218202,
                -16.56530613619324
              ],
              [
                -52.65967635969755,
                -16.56990461796894
              ],
              [
                -52.667433266621,
                -16.56917711103004
              ],
              [
                -52.67340373500452,
                -16.56861715224118
              ],
              [
                -52.672034716318,
                -16.57415911695847
              ],
              [
                -52.67020371837741,
                -16.58157256579139
              ],
              [
                -52.67500971049628,
                -16.584549063669733
              ],
              [
                -52.68976359109758,
                -16.58381112071298
              ],
              [
                -52.69117258518468,
                -16.58249162714418
              ],
              [
                -52.70364503700252,
                -16.570809199631643
              ],
              [
                -52.7074854970118,
                -16.575455193446146
              ],
              [
                -52.70692294310066,
                -16.585657130048194
              ],
              [
                -52.706252949126664,
                -16.597826592142297
              ],
              [
                -52.72483184458757,
                -16.588795656016956
              ],
              [
                -52.73072978119062,
                -16.585928196958793
              ],
              [
                -52.73895269964049,
                -16.58936670248019
              ],
              [
                -52.737316712444986,
                -16.59098318113889
              ],
              [
                -52.73062277737796,
                -16.59759813872729
              ],
              [
                -52.74032266224014,
                -16.603466621189447
              ],
              [
                -52.740597184948065,
                -16.608898080240415
              ],
              [
                -52.737122675030555,
                -16.61252809022682
              ],
              [
                -52.72559577887699,
                -16.614183042149286
              ],
              [
                -52.71520526215597,
                -16.638819407439723
              ],
              [
                -52.72404170312504,
                -16.646327879281575
              ],
              [
                -52.73228666766092,
                -16.634180969270314
              ],
              [
                -52.73876763942367,
                -16.629548995862898
              ],
              [
                -52.73917961736629,
                -16.629587476622238
              ],
              [
                -52.74636956099806,
                -16.630264493850586
              ],
              [
                -52.747509053781776,
                -16.638413484120143
              ],
              [
                -52.742388044798815,
                -16.650538420397297
              ],
              [
                -52.73993157716306,
                -16.656352868441555
              ],
              [
                -52.74604201680859,
                -16.654892388278526
              ],
              [
                -52.74947346965996,
                -16.657928384852994
              ],
              [
                -52.75431842826889,
                -16.662214369791094
              ],
              [
                -52.770761285636624,
                -16.668065890927828
              ],
              [
                -52.76871179475495,
                -16.671968370909855
              ],
              [
                -52.762343873715444,
                -16.671896331670307
              ],
              [
                -52.75470690684083,
                -16.6718108185909
              ],
              [
                -52.76223481741385,
                -16.681668289529934
              ],
              [
                -52.761255803805206,
                -16.70584068282265
              ],
              [
                -52.77150571971796,
                -16.706805171809656
              ],
              [
                -52.779208660545066,
                -16.707530691079597
              ],
              [
                -52.7881120504673,
                -16.7225281352088
              ],
              [
                -52.78706103699371,
                -16.72981512764695
              ],
              [
                -52.78548405227374,
                -16.740744565817803
              ],
              [
                -52.792073009510105,
                -16.739289596781877
              ],
              [
                -52.8006234226228,
                -16.747132044723383
              ],
              [
                -52.80145339167218,
                -16.74631908243858
              ],
              [
                -52.80690089374708,
                -16.740981611956002
              ],
              [
                -52.822821214932006,
                -16.757690068409527
              ],
              [
                -52.829785160283876,
                -16.759954585857216
              ],
              [
                -52.83020263780408,
                -16.764580520190236
              ],
              [
                -52.83059264525266,
                -16.768910521846724
              ],
              [
                -52.830764621278156,
                -16.77081900232207
              ],
              [
                -52.83593408637406,
                -16.769518014317015
              ],
              [
                -52.84813051239823,
                -16.766449076767923
              ],
              [
                -52.8523574637812,
                -16.771386542444223
              ],
              [
                -52.853598482268715,
                -16.772836034534798
              ],
              [
                -52.85467646847456,
                -16.771650079559894
              ],
              [
                -52.85846046664741,
                -16.767486583731262
              ],
              [
                -52.8628188842392,
                -16.774376070605094
              ],
              [
                -52.86687037223277,
                -16.78078056104978
              ],
              [
                -52.873153826485876,
                -16.778625061608814
              ],
              [
                -52.881786748886704,
                -16.775663608348115
              ],
              [
                -52.89376013151159,
                -16.78469208086073
              ],
              [
                -52.8910131613886,
                -16.785831076206225
              ],
              [
                -52.88803969565831,
                -16.78706404159002
              ],
              [
                -52.89189912836604,
                -16.7929290451498
              ],
              [
                -52.90560150824898,
                -16.798472043967312
              ],
              [
                -52.907178504643234,
                -16.80982600870761
              ],
              [
                -52.91432345859111,
                -16.811749501272672
              ],
              [
                -52.925196852220076,
                -16.81631249718145
              ],
              [
                -52.926052832185455,
                -16.81667150649091
              ],
              [
                -52.92585384435309,
                -16.810360517412917
              ],
              [
                -52.925680854223664,
                -16.804893553470205
              ],
              [
                -52.926491348182786,
                -16.804584061785793
              ],
              [
                -52.932148337002474,
                -16.802423569928255
              ],
              [
                -52.93518277930878,
                -16.810018533586955
              ],
              [
                -52.94392920588013,
                -16.812712557286766
              ],
              [
                -52.92845277927368,
                -16.82696945511516
              ],
              [
                -52.931678245125184,
                -16.83538492643047
              ],
              [
                -52.92857875610971,
                -16.838284917758184
              ],
              [
                -52.92661480448302,
                -16.840571380570626
              ],
              [
                -52.952486081698616,
                -16.84147843731868
              ],
              [
                -52.94694961097023,
                -16.84484690682383
              ],
              [
                -52.94441964133134,
                -16.85475435190454
              ],
              [
                -52.944150111626925,
                -16.85580835038062
              ],
              [
                -52.950095579601886,
                -16.85996182087559
              ],
              [
                -52.949539569535965,
                -16.8555873769115
              ],
              [
                -52.949233074777105,
                -16.85317186442548
              ],
              [
                -52.95673453206578,
                -16.850346412956842
              ],
              [
                -52.95468302856526,
                -16.856424384956725
              ],
              [
                -52.95347354827012,
                -16.860006842506102
              ],
              [
                -52.96315996532505,
                -16.858211366328035
              ],
              [
                -52.964210464283205,
                -16.858016388671835
              ],
              [
                -52.9641849463834,
                -16.858448901603776
              ],
              [
                -52.96370696251283,
                -16.866554836849907
              ],
              [
                -52.96669694502274,
                -16.868174829271542
              ],
              [
                -52.97579287465534,
                -16.863054870164657
              ],
              [
                -52.989389790108184,
                -16.8643004229609
              ],
              [
                -52.99778570703453,
                -16.865069429895794
              ],
              [
                -53.001014188544644,
                -16.8592274734977
              ],
              [
                -53.00565615100386,
                -16.863735454368012
              ],
              [
                -53.01082711641795,
                -16.857570005977134
              ],
              [
                -53.01361661318588,
                -16.86445745751484
              ],
              [
                -53.013221610547575,
                -16.86823446008336
              ],
              [
                -53.012589068888374,
                -16.874292416526522
              ],
              [
                -53.01240257473479,
                -16.87608238679497
              ],
              [
                -53.013625079499995,
                -16.876672411902362
              ],
              [
                -53.01726503202522,
                -16.878429894220524
              ],
              [
                -53.01524555955086,
                -16.88039737740513
              ],
              [
                -53.0113970497043,
                -16.884146861075838
              ],
              [
                -53.015843546012974,
                -16.884484376855198
              ],
              [
                -53.01842800201416,
                -16.89822080222909
              ],
              [
                -53.02412042678292,
                -16.90079481156295
              ],
              [
                -53.03131337610774,
                -16.904046300261662
              ],
              [
                -53.02948538555025,
                -16.90947427287594
              ],
              [
                -53.039121313434,
                -16.911512772897574
              ],
              [
                -53.03319983069667,
                -16.920310253386166
              ],
              [
                -53.03860075441449,
                -16.931278206248418
              ],
              [
                -53.035840768799154,
                -16.93063368678943
              ],
              [
                -53.03275831868255,
                -16.92991418892576
              ],
              [
                -53.03600975710973,
                -16.93876366458593
              ],
              [
                -53.03362475502106,
                -16.940814157536874
              ],
              [
                -53.041626205632745,
                -16.94561262703539
              ],
              [
                -53.04604568030795,
                -16.948263116107494
              ],
              [
                -53.044232658114154,
                -16.953486610733222
              ],
              [
                -53.04837211158604,
                -16.96612306903117
              ],
              [
                -53.05118161576543,
                -16.96085707992268
              ],
              [
                -53.05465306251675,
                -16.96299507716422
              ],
              [
                -53.05347902386907,
                -16.9875544840349
              ],
              [
                -53.05145401725463,
                -16.989971937136662
              ],
              [
                -53.04942853264339,
                -16.99238943923665
              ],
              [
                -53.05745794613261,
                -17.00899087246386
              ],
              [
                -53.05556794663788,
                -17.014528826494768
              ],
              [
                -53.0589679100585,
                -17.013437341500733
              ],
              [
                -53.06213538795788,
                -17.012419877619795
              ],
              [
                -53.06320386388587,
                -17.01653334716192
              ],
              [
                -53.06024389382559,
                -17.017422340120746
              ],
              [
                -53.05509594976634,
                -17.018969305913448
              ],
              [
                -53.060829386453506,
                -17.019860839252992
              ],
              [
                -53.061036894886726,
                -17.019892808592402
              ],
              [
                -53.06236985790575,
                -17.0291367911155
              ],
              [
                -53.06848331615529,
                -17.02952330468531
              ],
              [
                -53.06546232896858,
                -17.03851573116423
              ],
              [
                -53.06998329151911,
                -17.04169926112031
              ],
              [
                -53.072045244302544,
                -17.050147703062372
              ],
              [
                -53.06459129047229,
                -17.045895188127652
              ],
              [
                -53.06326031192974,
                -17.04513622649614
              ],
              [
                -53.0603493410826,
                -17.052496685756275
              ],
              [
                -53.06565328260569,
                -17.051904677948716
              ],
              [
                -53.06601529111512,
                -17.051864691983738
              ],
              [
                -53.066034782678464,
                -17.053556192240976
              ],
              [
                -53.0660942780076,
                -17.058768675382368
              ],
              [
                -53.05787534512481,
                -17.058666623527806
              ],
              [
                -53.0555168364028,
                -17.069923067154367
              ],
              [
                -53.06173025032746,
                -17.070935091113927
              ],
              [
                -53.06087177255122,
                -17.07719506828383
              ],
              [
                -53.0667382343632,
                -17.072940077670776
              ],
              [
                -53.06795219843291,
                -17.07774957939825
              ],
              [
                -53.07152216691865,
                -17.0773370996996
              ],
              [
                -53.07334065143192,
                -17.077127092946537
              ],
              [
                -53.072600653180324,
                -17.08048655275073
              ],
              [
                -53.07154316251627,
                -17.085293061152505
              ],
              [
                -53.07445413685718,
                -17.082078078794577
              ],
              [
                -53.07729413450539,
                -17.07894209890533
              ],
              [
                -53.08063812532557,
                -17.07942859354145
              ],
              [
                -53.07945010216262,
                -17.08636806683217
              ],
              [
                -53.07908857836709,
                -17.088482028260458
              ],
              [
                -53.08121808663829,
                -17.0878860580173
              ],
              [
                -53.08976203556405,
                -17.085494598139164
              ],
              [
                -53.08591354375336,
                -17.091917047549572
              ],
              [
                -53.0851750478987,
                -17.093150046141744
              ],
              [
                -53.0870185264235,
                -17.09286655178924
              ],
              [
                -53.09181251598345,
                -17.09212955247439
              ],
              [
                -53.089584505439035,
                -17.095070022709873
              ],
              [
                -53.087209503448406,
                -17.098205995699967
              ],
              [
                -53.09626542499198,
                -17.100806517102573
              ],
              [
                -53.10104643327049,
                -17.10217951907031
              ],
              [
                -53.10002241587263,
                -17.104032995142056
              ],
              [
                -53.098888903755714,
                -17.106083483050668
              ],
              [
                -53.10145437949726,
                -17.10957047408909
              ],
              [
                -53.10505085786936,
                -17.108350522176426
              ],
              [
                -53.108142352422405,
                -17.107301012289287
              ],
              [
                -53.11862574343997,
                -17.11277350290797
              ],
              [
                -53.115123255247106,
                -17.116398494003782
              ],
              [
                -53.11862573961087,
                -17.118581488255565
              ],
              [
                -53.11588923409215,
                -17.12371047025971
              ],
              [
                -53.11504225609516,
                -17.12529893597114
              ],
              [
                -53.12662961002141,
                -17.14374540176035
              ],
              [
                -53.12691210481851,
                -17.143816396102967
              ],
              [
                -53.131733082240125,
                -17.14502238097699
              ],
              [
                -53.1298690938625,
                -17.149208866381137
              ],
              [
                -53.127650605979234,
                -17.154192332294677
              ],
              [
                -53.13256608132289,
                -17.155553844688466
              ],
              [
                -53.13501403936624,
                -17.161192844650216
              ],
              [
                -53.138469496474606,
                -17.161259337526598
              ],
              [
                -53.13973349437052,
                -17.16128334240937
              ],
              [
                -53.14023797219548,
                -17.16345581265457
              ],
              [
                -53.141905443862136,
                -17.170636789722842
              ],
              [
                -53.14494094158099,
                -17.169297328046117
              ],
              [
                -53.149288893397724,
                -17.1673788441285
              ],
              [
                -53.1569298575287,
                -17.17019482934197
              ],
              [
                -53.155862799778745,
                -17.187104254446815
              ],
              [
                -53.155795307500675,
                -17.188179728367377
              ],
              [
                -53.157874292296356,
                -17.192204752889886
              ],
              [
                -53.16682267478267,
                -17.209528151937604
              ],
              [
                -53.166297170164604,
                -17.213563149251655
              ],
              [
                -53.16546565968265,
                -17.219949622097214
              ],
              [
                -53.169747625558145,
                -17.221309615061116
              ],
              [
                -53.17070511192816,
                -17.22161362494824
              ],
              [
                -53.17137311933809,
                -17.224649113672193
              ],
              [
                -53.1748440509242,
                -17.240409561969493
              ],
              [
                -53.18031698989698,
                -17.24385801825592
              ],
              [
                -53.18542895090834,
                -17.25720947551922
              ],
              [
                -53.18771493641618,
                -17.257653491939852
              ],
              [
                -53.197617834646266,
                -17.259577501761253
              ],
              [
                -53.19590281622717,
                -17.272620938221436
              ],
              [
                -53.195539825698454,
                -17.275378438789755
              ],
              [
                -53.198644306676094,
                -17.277632908921667
              ],
              [
                -53.20817722062592,
                -17.284556423129537
              ],
              [
                -53.20790768293837,
                -17.292912879435892
              ],
              [
                -53.21813907941519,
                -17.298857874810853
              ],
              [
                -53.21820208725984,
                -17.30917031004025
              ],
              [
                -53.21348512397926,
                -17.307625827793537
              ],
              [
                -53.210207136666355,
                -17.313704768300006
              ],
              [
                -53.216110565007,
                -17.31966827228791
              ],
              [
                -53.21282358752934,
                -17.32197372794113
              ],
              [
                -53.217248540236724,
                -17.326469715080037
              ],
              [
                -53.20569508410102,
                -17.332538680838464
              ],
              [
                -53.20390010449073,
                -17.333481650254765
              ],
              [
                -53.20173013345388,
                -17.33986215604564
              ],
              [
                -53.204077607513796,
                -17.341076651142778
              ],
              [
                -53.20629255270081,
                -17.342222154237465
              ],
              [
                -53.20370908810073,
                -17.343021640417266
              ],
              [
                -53.20070759915655,
                -17.343950103009632
              ],
              [
                -53.20020659106385,
                -17.352298086857342
              ],
              [
                -53.20185160292812,
                -17.352825602399896
              ],
              [
                -53.2033475619848,
                -17.353304561806418
              ],
              [
                -53.202660054852885,
                -17.361790560592908
              ],
              [
                -53.20254557269772,
                -17.36178054936406
              ],
              [
                -53.19512610051929,
                -17.361107014644315
              ],
              [
                -53.195877093973294,
                -17.368098977667827
              ],
              [
                -53.20371703388555,
                -17.370409482966846
              ],
              [
                -53.206219503068816,
                -17.371146989617444
              ],
              [
                -53.20383701173363,
                -17.37129149024779
              ],
              [
                -53.2009535729045,
                -17.371465986540706
              ],
              [
                -53.197017058544056,
                -17.380891924280142
              ],
              [
                -53.202766480919436,
                -17.389533916831002
              ],
              [
                -53.204290488085505,
                -17.383361941513535
              ],
              [
                -53.20553900692641,
                -17.383833937366298
              ],
              [
                -53.20909295501029,
                -17.385176957797455
              ],
              [
                -53.20817246075851,
                -17.393178386264374
              ],
              [
                -53.20768643928192,
                -17.395079877466436
              ],
              [
                -53.20606442833956,
                -17.401429866750732
              ],
              [
                -53.21157838459644,
                -17.40346388676713
              ],
              [
                -53.21495285521511,
                -17.404708871226546
              ],
              [
                -53.21297739673394,
                -17.406477357456673
              ],
              [
                -53.21211939486994,
                -17.407244862912915
              ],
              [
                -53.2141458778148,
                -17.408993835848943
              ],
              [
                -53.220509312980184,
                -17.414484841242103
              ],
              [
                -53.2234622531632,
                -17.425863801756776
              ],
              [
                -53.22982220023649,
                -17.426799802817737
              ],
              [
                -53.22820869212902,
                -17.43776573924721
              ],
              [
                -53.23293167824713,
                -17.44048474930346
              ],
              [
                -53.22808768740626,
                -17.444112229206574
              ],
              [
                -53.22686769174353,
                -17.445025690130525
              ],
              [
                -53.226873177629344,
                -17.45537814825905
              ],
              [
                -53.22345317037923,
                -17.457561641129058
              ],
              [
                -53.236237542647146,
                -17.482873560412088
              ],
              [
                -53.24106595604495,
                -17.49243353683988
              ],
              [
                -53.23930995634953,
                -17.49748551075793
              ],
              [
                -53.238667964093246,
                -17.49933297285502
              ],
              [
                -53.24011193560285,
                -17.501299986452825
              ],
              [
                -53.24257742115156,
                -17.50465894523018
              ],
              [
                -53.2429189314881,
                -17.505123985954082
              ],
              [
                -53.241160443802016,
                -17.51017493587768
              ],
              [
                -53.239306902068726,
                -17.515501415327694
              ],
              [
                -53.242359900971124,
                -17.522830377604013
              ],
              [
                -53.24615784083659,
                -17.531945870630715
              ],
              [
                -53.24334784850242,
                -17.533251334933247
              ],
              [
                -53.24257234110277,
                -17.533611348430167
              ],
              [
                -53.24408382080069,
                -17.541018789647538
              ],
              [
                -53.24532180297835,
                -17.547084268269142
              ],
              [
                -53.244025292397005,
                -17.54854874651827
              ],
              [
                -53.24061883163082,
                -17.552397737892555
              ],
              [
                -53.24336532163424,
                -17.554807744673578
              ],
              [
                -53.24748975059448,
                -17.558426734343858
              ],
              [
                -53.24207127557391,
                -17.5670716742059
              ],
              [
                -53.24011877332983,
                -17.570187149416547
              ],
              [
                -53.24157875678189,
                -17.571742653942323
              ],
              [
                -53.24240026459526,
                -17.572618169986768
              ],
              [
                -53.24122574549371,
                -17.57508464001319
              ],
              [
                -53.23867428661173,
                -17.58044411765169
              ],
              [
                -53.240533242854646,
                -17.58175358625913
              ],
              [
                -53.2417332510382,
                -17.58259860836202
              ],
              [
                -53.24021373621919,
                -17.585513080532653
              ],
              [
                -53.23817425448295,
                -17.589426568936723
              ],
              [
                -53.23979621922546,
                -17.590430556349485
              ],
              [
                -53.242374695558965,
                -17.592027066415255
              ],
              [
                -53.242431181484896,
                -17.601366531461736
              ],
              [
                -53.24928915056867,
                -17.605848001939513
              ],
              [
                -53.246011157704764,
                -17.610507983979236
              ],
              [
                -53.25070611763216,
                -17.61862846882693
              ],
              [
                -53.24676160020474,
                -17.62265491310066
              ],
              [
                -53.250321086598944,
                -17.627617401421247
              ],
              [
                -53.25034654065535,
                -17.643762362824912
              ],
              [
                -53.24547805411928,
                -17.65182831267382
              ],
              [
                -53.24806953829666,
                -17.654571801648256
              ],
              [
                -53.24479804117604,
                -17.65552826637193
              ],
              [
                -53.24362706964939,
                -17.65587077597699
              ],
              [
                -53.24431601933517,
                -17.66430874570363
              ],
              [
                -53.23976103667527,
                -17.66742420758413
              ],
              [
                -53.24350500176372,
                -17.67047969797549
              ],
              [
                -53.245265002352944,
                -17.67191567881239
              ],
              [
                -53.24278450293144,
                -17.67537270021759
              ],
              [
                -53.239568023051255,
                -17.679856667209364
              ],
              [
                -53.240457016818354,
                -17.685830135526754
              ],
              [
                -53.241166507335834,
                -17.686358122885736
              ],
              [
                -53.24637644973616,
                -17.6902341201881
              ],
              [
                -53.24003199899002,
                -17.694059590503358
              ],
              [
                -53.23869949239617,
                -17.69486258264033
              ],
              [
                -53.238924998222224,
                -17.696953587794873
              ],
              [
                -53.239457473280545,
                -17.70189057091318
              ],
              [
                -53.235626984123314,
                -17.706998003606685
              ],
              [
                -53.23783994346391,
                -17.713691483949134
              ],
              [
                -53.20736363887925,
                -17.727177361841825
              ],
              [
                -53.20032121205055,
                -17.730293828613412
              ],
              [
                -53.196076697667905,
                -17.73622781856378
              ],
              [
                -53.196737715931185,
                -17.737353314123283
              ],
              [
                -53.19782218923651,
                -17.739200301477425
              ],
              [
                -53.189198245874,
                -17.744898248770845
              ],
              [
                -53.1887932250648,
                -17.74516576357754
              ],
              [
                -53.18856973393208,
                -17.745816724105428
              ],
              [
                -53.18657423575729,
                -17.751624213126327
              ],
              [
                -53.18506923139087,
                -17.750933198546843
              ],
              [
                -53.18248075111863,
                -17.749745198549086
              ],
              [
                -53.17684478341732,
                -17.755415155272743
              ],
              [
                -53.17511826617188,
                -17.765397612191325
              ],
              [
                -53.16812183525279,
                -17.76581109619638
              ],
              [
                -53.16920184015641,
                -17.770064091732564
              ],
              [
                -53.15919133961734,
                -17.78630247525015
              ],
              [
                -53.160498323108015,
                -17.789345964784605
              ],
              [
                -53.16295277522187,
                -17.795061471137412
              ],
              [
                -53.13589790124296,
                -17.836606709206748
              ],
              [
                -53.14251226492541,
                -17.871209563273595
              ],
              [
                -53.136480308668936,
                -17.877009016422857
              ],
              [
                -53.13234278970058,
                -17.880986999400232
              ],
              [
                -53.132229801927686,
                -17.89087795079227
              ],
              [
                -53.127101793399646,
                -17.899567399214494
              ],
              [
                -53.130929718605834,
                -17.91639482128306
              ],
              [
                -53.099980920247255,
                -17.936364686443117
              ],
              [
                -53.085484449715096,
                -17.96149652407225
              ],
              [
                -53.08286797224082,
                -17.963458522817994
              ],
              [
                -53.076485028238466,
                -17.96824546259994
              ],
              [
                -53.069567528819334,
                -17.986086359609505
              ],
              [
                -53.071484380707645,
                -18.039018135614434
              ],
              [
                -53.077378335892064,
                -18.03916212760091
              ],
              [
                -53.15325874011381,
                -18.04101830479198
              ],
              [
                -53.1731816343947,
                -18.04159633774008
              ],
              [
                -53.18160304970726,
                -18.038723879472933
              ],
              [
                -53.19002452002758,
                -18.03585240519887
              ],
              [
                -53.193650996053265,
                -18.028832439478737
              ],
              [
                -53.19828847313022,
                -18.019854499992796
              ],
              [
                -53.20311543620361,
                -18.021061024610738
              ],
              [
                -53.20464543418246,
                -18.021443510708718
              ],
              [
                -53.21299188142885,
                -18.015860542308598
              ],
              [
                -53.21709034845725,
                -18.013118603700118
              ],
              [
                -53.237258224072946,
                -18.00710766955904
              ],
              [
                -53.29850730665247,
                -17.995084866198447
              ],
              [
                -53.330484579980535,
                -18.000858913032356
              ],
              [
                -53.33344154621664,
                -18.004391365704603
              ],
              [
                -53.335164023297246,
                -18.006448392858765
              ],
              [
                -53.34209145392763,
                -18.007840402914983
              ],
              [
                -53.35540190231942,
                -18.006129943620923
              ],
              [
                -53.35972734587977,
                -18.005574440847624
              ],
              [
                -53.369996266702344,
                -18.006939962347527
              ],
              [
                -53.380265172536305,
                -18.008306477850883
              ],
              [
                -53.40466654298636,
                -17.99288710491252
              ],
              [
                -53.41076149073489,
                -17.992772631586227
              ],
              [
                -53.41212703683596,
                -17.988040134950367
              ],
              [
                -53.417374437197765,
                -17.994346615071912
              ],
              [
                -53.4208234760341,
                -17.985338191556146
              ],
              [
                -53.425069913183485,
                -17.988838155619813
              ],
              [
                -53.42970390008433,
                -17.98736366268119
              ],
              [
                -53.42976688195924,
                -17.986140176255958
              ],
              [
                -53.42990038906868,
                -17.983540710100222
              ],
              [
                -53.43598183814223,
                -17.98629521103492
              ],
              [
                -53.43851185173039,
                -17.98347671780983
              ],
              [
                -53.440101326660965,
                -17.98889168979245
              ],
              [
                -53.44981420749594,
                -17.99212770165006
              ],
              [
                -53.44922422688018,
                -17.995374171816103
              ],
              [
                -53.44868223963134,
                -17.998359171259416
              ],
              [
                -53.452989703585416,
                -17.99883166589786
              ],
              [
                -53.456789147320336,
                -17.999248690517447
              ],
              [
                -53.457886150168775,
                -18.007816646359945
              ],
              [
                -53.459299074016364,
                -18.018855593080506
              ],
              [
                -53.465874042323726,
                -18.02389959418919
              ],
              [
                -53.46734399208268,
                -18.03444254328237
              ],
              [
                -53.472073442701706,
                -18.038699530289716
              ],
              [
                -53.47603944328651,
                -18.035804064976904
              ],
              [
                -53.48556434598118,
                -18.039950062458683
              ],
              [
                -53.498622275051744,
                -18.035457133898227
              ],
              [
                -53.501372259610584,
                -18.02769368102381
              ],
              [
                -53.513037703660345,
                -18.026494195440726
              ],
              [
                -53.513600190876694,
                -18.025677195449514
              ],
              [
                -53.517371670920426,
                -18.02019773303976
              ],
              [
                -53.52854160472153,
                -18.021129264814505
              ],
              [
                -53.547041957977335,
                -18.01410834996048
              ],
              [
                -53.55214292707888,
                -18.014673836202
              ],
              [
                -53.5554864092389,
                -18.01504485491427
              ],
              [
                -53.555660425748975,
                -18.013669872409352
              ],
              [
                -53.55672990020983,
                -18.005204382397825
              ],
              [
                -53.56448585592833,
                -18.004110418576186
              ],
              [
                -53.570746823547,
                -18.009162908936748
              ],
              [
                -53.57451278242889,
                -18.01220241594765
              ],
              [
                -53.583538715877175,
                -18.009365453983403
              ],
              [
                -53.589037188094764,
                -18.01215995640755
              ],
              [
                -53.588648701586514,
                -18.00456196890013
              ],
              [
                -53.60648808953534,
                -17.995041065364077
              ],
              [
                -53.60896807616821,
                -17.98980008963355
              ],
              [
                -53.609575099342635,
                -17.988517608796403
              ],
              [
                -53.614645060437795,
                -17.9778071646179
              ],
              [
                -53.62798296960716,
                -17.9799831563049
              ],
              [
                -53.6340959618817,
                -17.976399699331203
              ],
              [
                -53.6607387596091,
                -17.977062258890335
              ],
              [
                -53.66195922572722,
                -17.98557522049558
              ],
              [
                -53.6656536799779,
                -17.986486222714383
              ],
              [
                -53.67090665110422,
                -17.98778124413862
              ],
              [
                -53.67724057582515,
                -17.989342260096894
              ],
              [
                -53.6783275825204,
                -17.99395272555155
              ],
              [
                -53.67854207950994,
                -17.994863707084594
              ],
              [
                -53.68224604477781,
                -17.99721124002614
              ],
              [
                -53.689847987598895,
                -18.002029710863052
              ],
              [
                -53.69013296082086,
                -18.00377020093237
              ],
              [
                -53.691680927989694,
                -18.01322567628978
              ],
              [
                -53.70000840212255,
                -18.00542820234677
              ],
              [
                -53.70006989884262,
                -18.005370726752865
              ],
              [
                -53.70023088768517,
                -18.005375723133504
              ],
              [
                -53.71520629504404,
                -18.00583727756319
              ],
              [
                -53.71511930250383,
                -18.00186878675878
              ],
              [
                -53.71506480874812,
                -17.999358269639394
              ],
              [
                -53.71878876804574,
                -17.99762080357576
              ],
              [
                -53.72238372644886,
                -18.000208292212267
              ],
              [
                -53.726294693985096,
                -18.00302377464304
              ],
              [
                -53.72994517172279,
                -17.999854821674436
              ],
              [
                -53.73523366471542,
                -17.99526436603613
              ],
              [
                -53.75962196686318,
                -17.998469890986566
              ],
              [
                -53.77370738486916,
                -18.000321938119722
              ],
              [
                -53.778764357503654,
                -17.997576434802138
              ],
              [
                -53.78382280212717,
                -17.994832444479943
              ],
              [
                -53.785573290753625,
                -17.98969451036461
              ],
              [
                -53.78876479971803,
                -17.980324023397323
              ],
              [
                -53.79348626437374,
                -17.98027553641707
              ],
              [
                -53.79267928465339,
                -17.97421107796787
              ],
              [
                -53.79610626137771,
                -17.977500085127016
              ],
              [
                -53.796979280901596,
                -17.978337562641627
              ],
              [
                -53.797316779621255,
                -17.976099083291185
              ],
              [
                -53.79767574859598,
                -17.97371110064989
              ],
              [
                -53.79971476111288,
                -17.97372160370175
              ],
              [
                -53.803850231739936,
                -17.973743083947497
              ],
              [
                -53.804598733115405,
                -17.969464106562093
              ],
              [
                -53.80500922662094,
                -17.967113622884888
              ],
              [
                -53.805844211250374,
                -17.96837862843046
              ],
              [
                -53.808100682927524,
                -17.97179662110028
              ],
              [
                -53.81265217129477,
                -17.963507650783924
              ],
              [
                -53.81215169255502,
                -17.962879653283938
              ],
              [
                -53.80956770971303,
                -17.959637191031423
              ],
              [
                -53.81759464493162,
                -17.958134702670566
              ],
              [
                -53.8187371664215,
                -17.957920199465047
              ],
              [
                -53.82096063560147,
                -17.961609685862364
              ],
              [
                -53.82496062041278,
                -17.95459024460075
              ],
              [
                -53.82444713284033,
                -17.952100745222015
              ],
              [
                -53.82365412594129,
                -17.948256271082126
              ],
              [
                -53.83031311176099,
                -17.942390820407848
              ],
              [
                -53.83285459882705,
                -17.944567306220698
              ],
              [
                -53.83509857469509,
                -17.946488816409392
              ],
              [
                -53.83582705803394,
                -17.941904317308307
              ],
              [
                -53.837043085269315,
                -17.93425436585551
              ],
              [
                -53.84046403551908,
                -17.93774983508412
              ],
              [
                -53.84223054509194,
                -17.939554326591757
              ],
              [
                -53.84360753093479,
                -17.934933382268788
              ],
              [
                -53.844152534060655,
                -17.933103891642784
              ],
              [
                -53.845959497333325,
                -17.93344637865362
              ],
              [
                -53.85004149632348,
                -17.934220372455012
              ],
              [
                -53.84996649749731,
                -17.931782412944646
              ],
              [
                -53.849875012326144,
                -17.92877939286899
              ],
              [
                -53.859957961557015,
                -17.924151454303555
              ],
              [
                -53.85954696283268,
                -17.920711987341306
              ],
              [
                -53.86641541467288,
                -17.92495245891337
              ],
              [
                -53.86965037633946,
                -17.92694944524603
              ],
              [
                -53.87121535413566,
                -17.922958987621925
              ],
              [
                -53.87181987205658,
                -17.92141749687943
              ],
              [
                -53.872176867150266,
                -17.916416019668798
              ],
              [
                -53.874960868109284,
                -17.918378026025334
              ],
              [
                -53.87181987205658,
                -17.92141749687943
              ],
              [
                -53.87405934722708,
                -17.921778476888576
              ],
              [
                -53.87710132036884,
                -17.92226849233635
              ],
              [
                -53.8793228189389,
                -17.91959300059259
              ],
              [
                -53.884929768669025,
                -17.912837551156013
              ],
              [
                -53.898041187138865,
                -17.908355092489284
              ],
              [
                -53.9046261449076,
                -17.912640128171837
              ],
              [
                -53.903282655524755,
                -17.91480010536906
              ],
              [
                -53.902570649443604,
                -17.915944065587304
              ],
              [
                -53.90720113192395,
                -17.91476111832065
              ],
              [
                -53.910789612050095,
                -17.91384412428922
              ],
              [
                -53.91275858478048,
                -17.914706600432194
              ],
              [
                -53.92275349919998,
                -17.919083604249664
              ],
              [
                -53.92928397141319,
                -17.914544135401226
              ],
              [
                -53.93190244986549,
                -17.912724660892643
              ],
              [
                -53.93348243885437,
                -17.914503171064066
              ],
              [
                -53.93804288563502,
                -17.919637663015898
              ],
              [
                -53.945093863890925,
                -17.916308185175467
              ],
              [
                -53.94840680130815,
                -17.922975674326917
              ],
              [
                -53.9502293212371,
                -17.917912671994632
              ],
              [
                -53.95115531570385,
                -17.91534168453817
              ],
              [
                -53.94493385511416,
                -17.907833221807337
              ],
              [
                -53.94415389419029,
                -17.906891241976812
              ],
              [
                -53.945063361353164,
                -17.906308244795156
              ],
              [
                -53.95062182879426,
                -17.902744257245942
              ],
              [
                -53.94726139053852,
                -17.898334756130946
              ],
              [
                -53.95524137606825,
                -17.882572363944575
              ],
              [
                -53.951815894616566,
                -17.874718400749607
              ],
              [
                -53.95350241493761,
                -17.86948240885453
              ],
              [
                -53.94871747656372,
                -17.863286414040935
              ],
              [
                -53.946177462436545,
                -17.859996960109882
              ],
              [
                -53.94638498255142,
                -17.85941494017381
              ],
              [
                -53.94773446928967,
                -17.855632969086468
              ],
              [
                -53.94112605919556,
                -17.84817800127392
              ],
              [
                -53.940656084362594,
                -17.839755026991952
              ],
              [
                -53.9182337963382,
                -17.813328573104155
              ],
              [
                -53.91406035513337,
                -17.80840961132389
              ],
              [
                -53.91219438426543,
                -17.806210617342067
              ],
              [
                -53.9100243782663,
                -17.79967662163514
              ],
              [
                -53.90676943975479,
                -17.789874650597596
              ],
              [
                -53.90156048443867,
                -17.78399619450389
              ],
              [
                -53.894986560923336,
                -17.776576675814923
              ],
              [
                -53.89547355689094,
                -17.772719230974545
              ],
              [
                -53.89596057885614,
                -17.76886172713596
              ],
              [
                -53.887873178771315,
                -17.748544807467947
              ],
              [
                -53.88261474413754,
                -17.743270301674542
              ],
              [
                -53.87901876626131,
                -17.73966380565927
              ],
              [
                -53.87795778213926,
                -17.736178837378834
              ],
              [
                -53.87651380811282,
                -17.731433364705225
              ],
              [
                -53.8700113662185,
                -17.72691784153108
              ],
              [
                -53.86042844985111,
                -17.720263381190588
              ],
              [
                -53.85855746706151,
                -17.713570408073547
              ],
              [
                -53.855399022735924,
                -17.70226943111148
              ],
              [
                -53.84478463097105,
                -17.694373447779576
              ],
              [
                -53.84065515340953,
                -17.691301955159595
              ],
              [
                -53.81289889632815,
                -17.681008942265095
              ],
              [
                -53.80582147372719,
                -17.671925942996374
              ],
              [
                -53.79832099372492,
                -17.670087449677183
              ],
              [
                -53.784982089523716,
                -17.67592188806569
              ],
              [
                -53.7789576486863,
                -17.67199391062695
              ],
              [
                -53.7638187463103,
                -17.67146136676317
              ],
              [
                -53.746237384502464,
                -17.670842333290224
              ],
              [
                -53.736676466518254,
                -17.664755335765715
              ],
              [
                -53.7331769902704,
                -17.66252732904407
              ],
              [
                -53.72821652375043,
                -17.664650309451307
              ],
              [
                -53.719541085315875,
                -17.66836379568175
              ],
              [
                -53.70564870995777,
                -17.661944277034547
              ],
              [
                -53.67956989383059,
                -17.253125104296714
              ],
              [
                -53.685504338677,
                -17.249719111842328
              ],
              [
                -53.691953287652254,
                -17.2460186419428
              ],
              [
                -53.6939908296433,
                -17.23353771793018
              ],
              [
                -53.70814975756702,
                -17.22772377900044
              ],
              [
                -53.7338405462882,
                -17.231425298959984
              ],
              [
                -53.74650943375272,
                -17.242195795002832
              ],
              [
                -53.74902391570888,
                -17.242300302568555
              ],
              [
                -53.758573338190445,
                -17.242697291708172
              ],
              [
                -53.76232079671286,
                -17.24615780418329
              ],
              [
                -53.76319629508797,
                -17.25324677249693
              ],
              [
                -53.76376275116002,
                -17.2578297403106
              ],
              [
                -53.77062268868847,
                -17.26320824268824
              ],
              [
                -53.785210035448245,
                -17.28706965990775
              ],
              [
                -53.79739746368701,
                -17.287329680086014
              ],
              [
                -53.806129380537996,
                -17.28751619227766
              ],
              [
                -53.81956629130916,
                -17.294167692899553
              ],
              [
                -53.83445557161243,
                -17.335314540026825
              ],
              [
                -53.83263704722213,
                -17.34494501165753
              ],
              [
                -53.830818032819636,
                -17.354575964292927
              ],
              [
                -53.85750783420327,
                -17.36419497297432
              ],
              [
                -53.86787624923727,
                -17.36793146642717
              ],
              [
                -53.87793165038774,
                -17.374751988408093
              ],
              [
                -53.88397010386053,
                -17.38576042204681
              ],
              [
                -53.887480577265165,
                -17.392161414081794
              ],
              [
                -53.898547957344505,
                -17.401230913600656
              ],
              [
                -53.91409281318417,
                -17.413969359738527
              ],
              [
                -53.917450249217055,
                -17.421289354388815
              ],
              [
                -53.919041738842,
                -17.4247608508441
              ],
              [
                -53.923551201139006,
                -17.42776081648964
              ],
              [
                -53.9280626604169,
                -17.430762838136417
              ],
              [
                -53.928864645871485,
                -17.433374341499228
              ],
              [
                -53.93059311982876,
                -17.439002785733695
              ],
              [
                -53.94301553365788,
                -17.448322796638276
              ],
              [
                -53.94742699982259,
                -17.45163278270862
              ],
              [
                -53.94958345503421,
                -17.45526124688111
              ],
              [
                -53.951739930240706,
                -17.458890761053045
              ],
              [
                -53.970762763073814,
                -17.465373763703774
              ],
              [
                -53.9725417345381,
                -17.471797223609126
              ],
              [
                -53.977324219874404,
                -17.46990125066352
              ],
              [
                -53.9835926675425,
                -17.473370269603485
              ],
              [
                -53.98976362822179,
                -17.47029980824007
              ],
              [
                -53.99448608819627,
                -17.46794978715434
              ],
              [
                -54.02462435786265,
                -17.47784384796312
              ],
              [
                -54.030872816089776,
                -17.481920852212205
              ],
              [
                -54.03712075832414,
                -17.485996826475073
              ],
              [
                -54.040732717604506,
                -17.500053785256394
              ],
              [
                -54.040739213493765,
                -17.50007826816424
              ],
              [
                -54.04075169837936,
                -17.500088278150578
              ],
              [
                -54.048553625306816,
                -17.50618427733704
              ],
              [
                -54.05145609137011,
                -17.50525175155012
              ],
              [
                -54.05756906963151,
                -17.512681742743492
              ],
              [
                -54.05725953034518,
                -17.520377239062103
              ],
              [
                -54.05268406219677,
                -17.522756706370664
              ],
              [
                -54.05495550999844,
                -17.52885366106136
              ],
              [
                -54.05079555508307,
                -17.529295641923955
              ],
              [
                -54.04890105187353,
                -17.529497145395446
              ],
              [
                -54.04539757213683,
                -17.536779601716173
              ],
              [
                -54.050950497576714,
                -17.544851098761054
              ],
              [
                -54.0515705228134,
                -17.54575260530983
              ],
              [
                -54.051124479777364,
                -17.562325028231413
              ],
              [
                -54.072759750770075,
                -17.587353945246623
              ],
              [
                -54.072351245556014,
                -17.593372917782034
              ],
              [
                -54.072151226083975,
                -17.596319918336356
              ],
              [
                -54.078923165064445,
                -17.604317392850334
              ],
              [
                -54.0765271524923,
                -17.614564330939906
              ],
              [
                -54.084183602866226,
                -17.618588850118066
              ],
              [
                -54.09787651618828,
                -17.613134867781227
              ],
              [
                -54.111455424883694,
                -17.613079429464364
              ],
              [
                -54.11337239825171,
                -17.618364913997294
              ],
              [
                -54.116235367780426,
                -17.6155094055769
              ],
              [
                -54.11809286918627,
                -17.618696916171068
              ],
              [
                -54.12243232755437,
                -17.612775954832372
              ],
              [
                -54.133675727809575,
                -17.618279940397514
              ],
              [
                -54.139220227667955,
                -17.612007464558918
              ],
              [
                -54.14079523137873,
                -17.610225487251718
              ],
              [
                -54.14224717806592,
                -17.611387994732265
              ],
              [
                -54.146206180763926,
                -17.614558474589888
              ],
              [
                -54.150137648978564,
                -17.60977203725604
              ],
              [
                -54.15370611755942,
                -17.605427039091705
              ],
              [
                -54.163384074234564,
                -17.607059581780163
              ],
              [
                -54.16765504832078,
                -17.60778005014736
              ],
              [
                -54.172988511081776,
                -17.605093113060207
              ],
              [
                -54.17832246984476,
                -17.602405634974733
              ],
              [
                -54.191120869544335,
                -17.605201124270955
              ],
              [
                -54.19714678871713,
                -17.611747620470258
              ],
              [
                -54.19931827415818,
                -17.61410613450396
              ],
              [
                -54.202080754460404,
                -17.613106132692227
              ],
              [
                -54.20415026295168,
                -17.612357123073046
              ],
              [
                -54.207278729379595,
                -17.614828145978084
              ],
              [
                -54.213484687705126,
                -17.61972811385615
              ],
              [
                -54.216965643578945,
                -17.619621140912997
              ],
              [
                -54.221709089704774,
                -17.619475648254006
              ],
              [
                -54.2432329196833,
                -17.628458656133382
              ],
              [
                -54.24653937893377,
                -17.63425511398585
              ],
              [
                -54.2498453491817,
                -17.640051601847208
              ],
              [
                -54.269351679314376,
                -17.651640089513855
              ],
              [
                -54.28781756729991,
                -17.655227110565157
              ],
              [
                -54.28800056538034,
                -17.65509261560422
              ],
              [
                -54.291459035995715,
                -17.652553170230288
              ],
              [
                -54.29639896337605,
                -17.656647631615012
              ],
              [
                -54.30190892249366,
                -17.661213636365925
              ],
              [
                -54.3087644100716,
                -17.658401166589716
              ],
              [
                -54.31948479262254,
                -17.661891167942777
              ],
              [
                -54.32729177948832,
                -17.658363700633057
              ],
              [
                -54.33507070619888,
                -17.661278231218343
              ],
              [
                -54.342930651778886,
                -17.655129263441363
              ],
              [
                -54.35006811672159,
                -17.656661285456458
              ],
              [
                -54.35484257057137,
                -17.65026230311598
              ],
              [
                -54.38728541231507,
                -17.63149195006989
              ],
              [
                -54.37390455466767,
                -17.608131521602935
              ],
              [
                -54.38206099396671,
                -17.605786580968054
              ],
              [
                -54.380983037433914,
                -17.600600605876703
              ],
              [
                -54.383199008336526,
                -17.60082860235952
              ],
              [
                -54.38409100485781,
                -17.600920076165394
              ],
              [
                -54.382966014656446,
                -17.59908208838607
              ],
              [
                -54.379678059062456,
                -17.5937091006557
              ],
              [
                -54.381862547600306,
                -17.59080311970365
              ],
              [
                -54.38582104707485,
                -17.585538172403027
              ],
              [
                -54.38573802974568,
                -17.584759155590294
              ],
              [
                -54.38489807263892,
                -17.576923192643537
              ],
              [
                -54.38232308115199,
                -17.57896520039464
              ],
              [
                -54.38095759669103,
                -17.57523768426266
              ],
              [
                -54.38243009153421,
                -17.571086720979597
              ],
              [
                -54.402366441263325,
                -17.564378316377635
              ],
              [
                -54.403201972183155,
                -17.564096822664432
              ],
              [
                -54.40340447442567,
                -17.563105807479765
              ],
              [
                -54.40543447589701,
                -17.55316236379497
              ],
              [
                -54.41320393722455,
                -17.551081890010035
              ],
              [
                -54.413484936938566,
                -17.544108905075603
              ],
              [
                -54.420567892707176,
                -17.537306979158082
              ],
              [
                -54.42848384508103,
                -17.538942958535745
              ],
              [
                -54.433538322007955,
                -17.534386516831987
              ],
              [
                -54.43771477082549,
                -17.533655021306622
              ],
              [
                -54.46640211518852,
                -17.528630096859498
              ],
              [
                -54.47177506763464,
                -17.52081863008642
              ],
              [
                -54.47709856622539,
                -17.51308070686939
              ],
              [
                -54.47468059101552,
                -17.51123519794801
              ],
              [
                -54.47287807826262,
                -17.50985817950426
              ],
              [
                -54.47681657602035,
                -17.508283197054734
              ],
              [
                -54.47903956290295,
                -17.50739420539601
              ],
              [
                -54.47836407395283,
                -17.50614423917378
              ],
              [
                -54.47371711931997,
                -17.49754673716832
              ],
              [
                -54.482543570552416,
                -17.49352327839894
              ],
              [
                -54.48079759955205,
                -17.48722083054769
              ],
              [
                -54.490429535895835,
                -17.488171853092688
              ],
              [
                -54.49223752150521,
                -17.486971329765364
              ],
              [
                -54.49623899472172,
                -17.48431485216968
              ],
              [
                -54.50323996436674,
                -17.47966590862046
              ],
              [
                -54.51865834728299,
                -17.481054951489398
              ],
              [
                -54.52468428187719,
                -17.481597942948067
              ],
              [
                -54.53025124707372,
                -17.479369475332984
              ],
              [
                -54.53581874226999,
                -17.47714146771556
              ],
              [
                -54.538389715114754,
                -17.478736985101033
              ],
              [
                -54.553675571390464,
                -17.488224493476853
              ],
              [
                -54.56253502212571,
                -17.478730526530086
              ],
              [
                -54.568516485233445,
                -17.482579562529118
              ],
              [
                -54.5678080165372,
                -17.476451076407784
              ],
              [
                -54.56750902752744,
                -17.47386409591174
              ],
              [
                -54.57181649015075,
                -17.47514356895052
              ],
              [
                -54.581151940483494,
                -17.46756161885196
              ],
              [
                -54.58810086481638,
                -17.476677617360853
              ],
              [
                -54.609179198195626,
                -17.486441094890992
              ],
              [
                -54.616821610616405,
                -17.4899810993671
              ],
              [
                -54.618061607211686,
                -17.489154626005938
              ],
              [
                -54.6201006196842,
                -17.487794133928688
              ],
              [
                -54.62853303817716,
                -17.491875650015075
              ],
              [
                -54.6355689621839,
                -17.49528164059931
              ],
              [
                -54.63969693776507,
                -17.49406665204019
              ],
              [
                -54.65448334673367,
                -17.4897157173398
              ],
              [
                -54.65621385665815,
                -17.49646015640149
              ],
              [
                -54.65844629971253,
                -17.497746656337753
              ],
              [
                -54.66893174572655,
                -17.503790682047327
              ],
              [
                -54.67623768027786,
                -17.501081683796695
              ],
              [
                -54.680796642369806,
                -17.50640468702282
              ],
              [
                -54.69501356364535,
                -17.503077224506022
              ],
              [
                -54.69589704398408,
                -17.501783756279277
              ],
              [
                -54.69920804100836,
                -17.496936764407913
              ],
              [
                -54.699777489247445,
                -17.502834731314582
              ],
              [
                -54.700070490079284,
                -17.505868716923004
              ],
              [
                -54.714950901547766,
                -17.51269874616229
              ],
              [
                -54.71829135637023,
                -17.51791920286969
              ],
              [
                -54.72513077355621,
                -17.516201727188395
              ],
              [
                -54.729569782771854,
                -17.515087235970494
              ],
              [
                -54.736148700486964,
                -17.51757427049585
              ],
              [
                -54.74844009421522,
                -17.522443764875288
              ],
              [
                -54.75648653726009,
                -17.534049725733823
              ],
              [
                -54.75546151462709,
                -17.544732699149648
              ],
              [
                -54.7575039802744,
                -17.547779178079743
              ],
              [
                -54.76165496283601,
                -17.553972666679996
              ],
              [
                -54.75648448839438,
                -17.558158629836118
              ],
              [
                -54.75964646172458,
                -17.55992460906597
              ],
              [
                -54.75826593093612,
                -17.565253125695328
              ],
              [
                -54.777569801247886,
                -17.570792143723278
              ],
              [
                -54.791069163917136,
                -17.581847085671907
              ],
              [
                -54.79390962598647,
                -17.58194261231315
              ],
              [
                -54.800236623602274,
                -17.5821531141173
              ],
              [
                -54.820795446314634,
                -17.596943611652154
              ],
              [
                -54.81990741060506,
                -17.601700062023202
              ],
              [
                -54.819681930895996,
                -17.602909572270097
              ],
              [
                -54.824485864541714,
                -17.609232552077906
              ],
              [
                -54.83859174037511,
                -17.613496577880753
              ],
              [
                -54.84380524332279,
                -17.613118107478606
              ],
              [
                -54.8469677020623,
                -17.61288858933349
              ],
              [
                -54.85106864949574,
                -17.61259061781783
              ],
              [
                -54.85354616953686,
                -17.61547910760174
              ],
              [
                -54.86035010099454,
                -17.623409084558826
              ],
              [
                -54.88011947218882,
                -17.616426149709717
              ],
              [
                -54.894313344478014,
                -17.621072143174814
              ],
              [
                -54.90202579326165,
                -17.614026703544926
              ],
              [
                -54.90660330995506,
                -17.609845229843707
              ],
              [
                -54.94290603261742,
                -17.608585822662818
              ],
              [
                -54.94536401508824,
                -17.611744310285204
              ],
              [
                -54.94782096356461,
                -17.614902820908643
              ],
              [
                -54.95387392756258,
                -17.614982334657284
              ],
              [
                -54.96651485986639,
                -17.61514737046717
              ],
              [
                -54.965982333499696,
                -17.619587840174
              ],
              [
                -54.965874368796996,
                -17.620490343050424
              ],
              [
                -54.96695431878825,
                -17.619957818018037
              ],
              [
                -54.97375730294357,
                -17.616602367312648
              ],
              [
                -54.97849123540662,
                -17.6242968532714
              ],
              [
                -54.98095522853543,
                -17.628302350324546
              ],
              [
                -54.983088717606904,
                -17.625431836898663
              ],
              [
                -54.9844257102651,
                -17.62363286191205
              ],
              [
                -54.98556517386377,
                -17.62604287647085
              ],
              [
                -54.98864913588453,
                -17.632564351344236
              ],
              [
                -54.99226612103169,
                -17.632528321527847
              ],
              [
                -54.99131661184852,
                -17.637952812021073
              ],
              [
                -55.005776521918406,
                -17.63103089164551
              ],
              [
                -55.010236027966,
                -17.628895884882795
              ],
              [
                -55.01057698059831,
                -17.632215901580338
              ],
              [
                -55.01076348937672,
                -17.63403289630065
              ],
              [
                -55.012272504772206,
                -17.63263389203267
              ],
              [
                -55.015797990345604,
                -17.629364406772847
              ],
              [
                -55.01766145100482,
                -17.633963889822827
              ],
              [
                -55.01843545247537,
                -17.6358753696103
              ],
              [
                -55.02144840182772,
                -17.634725881034704
              ],
              [
                -55.02251739856835,
                -17.63431741344545
              ],
              [
                -55.022522430007974,
                -17.63487892406549
              ],
              [
                -55.02254439376709,
                -17.637487908004136
              ],
              [
                -55.02649386391892,
                -17.635877417732388
              ],
              [
                -55.031537820778325,
                -17.640062415508826
              ],
              [
                -55.039896300535766,
                -17.63735442592859
              ],
              [
                -55.044237759444805,
                -17.635947459768342
              ],
              [
                -55.0456847544087,
                -17.638178959883714
              ],
              [
                -55.04679521510313,
                -17.639890932371113
              ],
              [
                -55.05114321768612,
                -17.638956961214657
              ],
              [
                -55.05468216637389,
                -17.638196450297333
              ],
              [
                -55.0550781704741,
                -17.639362975322506
              ],
              [
                -55.055956155403074,
                -17.641945445527373
              ],
              [
                -55.06198612493942,
                -17.638609481797417
              ],
              [
                -55.063299091631805,
                -17.64201596558641
              ],
              [
                -55.066598605909235,
                -17.637970499046844
              ],
              [
                -55.0721310692534,
                -17.631187036736215
              ],
              [
                -55.079433506913894,
                -17.63304806207149
              ],
              [
                -55.086818485794765,
                -17.62947258973783
              ],
              [
                -55.09215093638322,
                -17.63488105407125
              ],
              [
                -55.092850410639016,
                -17.635590569803856
              ],
              [
                -55.09335940146251,
                -17.6347350697135
              ],
              [
                -55.097268922422366,
                -17.62816459042134
              ],
              [
                -55.10883483285253,
                -17.62999710654659
              ],
              [
                -55.112687746135315,
                -17.64146808644637
              ],
              [
                -55.11337624281982,
                -17.643517573463924
              ],
              [
                -55.126737125051136,
                -17.65227755371523
              ],
              [
                -55.13053660337015,
                -17.650643092165797
              ],
              [
                -55.138480050053026,
                -17.647224625561552
              ],
              [
                -55.14231952745004,
                -17.64472863876528
              ],
              [
                -55.14148754919343,
                -17.638844139654456
              ],
              [
                -55.16076794546563,
                -17.641483690718346
              ],
              [
                -55.162545936583946,
                -17.641726680139488
              ],
              [
                -55.16457788454315,
                -17.638817209565424
              ],
              [
                -55.16595340345341,
                -17.636847715361377
              ],
              [
                -55.166600894495325,
                -17.63740173363268
              ],
              [
                -55.170094344349096,
                -17.640390739704376
              ],
              [
                -55.171207864294814,
                -17.63760125031991
              ],
              [
                -55.168895373098614,
                -17.63579673018289
              ],
              [
                -55.1662929105129,
                -17.633765217284648
              ],
              [
                -55.172149885936896,
                -17.633519271987225
              ],
              [
                -55.1747963660572,
                -17.633407752084377
              ],
              [
                -55.17426737142282,
                -17.632037265571714
              ],
              [
                -55.17355137138091,
                -17.63018626862925
              ],
              [
                -55.180194851961986,
                -17.622755819764475
              ],
              [
                -55.18440479328188,
                -17.623770809993385
              ],
              [
                -55.18337731533272,
                -17.619362850115323
              ],
              [
                -55.18956827406872,
                -17.62441331918945
              ],
              [
                -55.19216372948142,
                -17.626530342710403
              ],
              [
                -55.19349423503781,
                -17.622601341694963
              ],
              [
                -55.19496075675578,
                -17.61826887573099
              ],
              [
                -55.20298169077504,
                -17.617499387059585
              ],
              [
                -55.20429269115407,
                -17.613316401069774
              ],
              [
                -55.210793643987856,
                -17.614616931820137
              ],
              [
                -55.220981586511456,
                -17.61665644566968
              ],
              [
                -55.22514904306726,
                -17.608880982036485
              ],
              [
                -55.218619594227604,
                -17.612795447117254
              ],
              [
                -55.21816360644897,
                -17.610188469022972
              ],
              [
                -55.21761912965935,
                -17.607072960863704
              ],
              [
                -55.22737956706984,
                -17.599206509607
              ],
              [
                -55.2314000605523,
                -17.59513504691175
              ],
              [
                -55.23609200963597,
                -17.595403055505486
              ],
              [
                -55.236297502493436,
                -17.59604056231816
              ],
              [
                -55.237709478913416,
                -17.600415559314158
              ],
              [
                -55.24281694669037,
                -17.593864074837974
              ],
              [
                -55.24201495793453,
                -17.60168254471515
              ],
              [
                -55.24876042613042,
                -17.59489311033909
              ],
              [
                -55.24440048769345,
                -17.590970595054365
              ],
              [
                -55.251876930982604,
                -17.591867621944814
              ],
              [
                -55.25001546176122,
                -17.585238656371374
              ],
              [
                -55.249789450498035,
                -17.584433656216966
              ],
              [
                -55.25493140742948,
                -17.57994618707669
              ],
              [
                -55.25357095110764,
                -17.57650318426527
              ],
              [
                -55.257566402674385,
                -17.57393972210696
              ],
              [
                -55.26044589036391,
                -17.575622694169727
              ],
              [
                -55.26361886157331,
                -17.577478195236928
              ],
              [
                -55.26515585386604,
                -17.571280747328455
              ],
              [
                -55.26656787763903,
                -17.565588277971088
              ],
              [
                -55.26253789350534,
                -17.56439324897251
              ],
              [
                -55.26868037726868,
                -17.556333800458653
              ],
              [
                -55.270738365235694,
                -17.559625307647938
              ],
              [
                -55.27462983093966,
                -17.558382304628502
              ],
              [
                -55.274954818225844,
                -17.562247297066158
              ],
              [
                -55.2750788073653,
                -17.563725289115006
              ],
              [
                -55.280264279122775,
                -17.56251282419868
              ],
              [
                -55.28226927335358,
                -17.55699632657009
              ],
              [
                -55.28904072916803,
                -17.559868823310453
              ],
              [
                -55.284518799616066,
                -17.553429867292348
              ],
              [
                -55.283315289512906,
                -17.55171486455377
              ],
              [
                -55.29601468735004,
                -17.55067140066839
              ],
              [
                -55.29214876305555,
                -17.542105414304206
              ],
              [
                -55.29915470775086,
                -17.540748445529772
              ],
              [
                -55.31539958250406,
                -17.545548479740777
              ],
              [
                -55.32198253508667,
                -17.541991511225493
              ],
              [
                -55.32737201995092,
                -17.54687548701047
              ],
              [
                -55.32979146444336,
                -17.549067998775307
              ],
              [
                -55.33734694932302,
                -17.544798028701692
              ],
              [
                -55.33893592658497,
                -17.548065516848954
              ],
              [
                -55.35015235870494,
                -17.54811700864149
              ],
              [
                -55.35324084153501,
                -17.53117411499635
              ],
              [
                -55.355668849916725,
                -17.528596144958122
              ],
              [
                -55.35809735129397,
                -17.526018639918554
              ],
              [
                -55.37138275270295,
                -17.522235678058806
              ],
              [
                -55.376593222772165,
                -17.514599748342512
              ],
              [
                -55.37971268256076,
                -17.5208327317705
              ],
              [
                -55.38181466981005,
                -17.525032222264386
              ],
              [
                -55.385758661743495,
                -17.519169731882965
              ],
              [
                -55.386099641788356,
                -17.5186622578785
              ],
              [
                -55.38666265112082,
                -17.518964728004587
              ],
              [
                -55.39021264368993,
                -17.520872247799808
              ],
              [
                -55.39178160928041,
                -17.517805269675115
              ],
              [
                -55.39290160256156,
                -17.51561528472414
              ],
              [
                -55.4046845539804,
                -17.511952795591824
              ],
              [
                -55.41115349982595,
                -17.51341633754626
              ],
              [
                -55.41584446691074,
                -17.51447733176871
              ],
              [
                -55.422066911199494,
                -17.51308135318929
              ],
              [
                -55.42256344031436,
                -17.510680872564482
              ],
              [
                -55.422819946925564,
                -17.50943988644484
              ],
              [
                -55.42544241261217,
                -17.509958867795653
              ],
              [
                -55.43220637080128,
                -17.511297875018563
              ],
              [
                -55.44337428967631,
                -17.506091437859002
              ],
              [
                -55.443215767550974,
                -17.511333405341425
              ],
              [
                -55.45337822528982,
                -17.50724043197158
              ],
              [
                -55.45329424156687,
                -17.50181446465615
              ],
              [
                -55.45326525580251,
                -17.49995496943066
              ],
              [
                -55.45798620450174,
                -17.49924948118354
              ],
              [
                -55.458176201651284,
                -17.4935470327206
              ],
              [
                -55.472122649714656,
                -17.480915602794767
              ],
              [
                -55.47626059860476,
                -17.4838305948096
              ],
              [
                -55.48252260902786,
                -17.478243156991653
              ],
              [
                -55.488629028265535,
                -17.483832125639452
              ],
              [
                -55.48957352048563,
                -17.484696627348747
              ],
              [
                -55.497096466462104,
                -17.486077629286065
              ],
              [
                -55.4988199672624,
                -17.482551669449233
              ],
              [
                -55.503819415659045,
                -17.48607017108502
              ],
              [
                -55.50401093632901,
                -17.48054015487302
              ],
              [
                -55.51451133562682,
                -17.483179683934036
              ],
              [
                -55.523566798555215,
                -17.480734228823213
              ],
              [
                -55.52191334932458,
                -17.47279126716816
              ],
              [
                -55.521775820045136,
                -17.472132275602398
              ],
              [
                -55.532739255829945,
                -17.46605031155945
              ],
              [
                -55.533614788615765,
                -17.466512819792907
              ],
              [
                -55.536510739751066,
                -17.46804379556136
              ],
              [
                -55.53754974436282,
                -17.46298581046076
              ],
              [
                -55.538039262473575,
                -17.46060385171695
              ],
              [
                -55.540398752872804,
                -17.460431841321256
              ],
              [
                -55.54304571736967,
                -17.460239844726253
              ],
              [
                -55.54267723588292,
                -17.458389870602165
              ],
              [
                -55.54221572529337,
                -17.456072366216983
              ],
              [
                -55.54691920328755,
                -17.454587378191817
              ],
              [
                -55.55684713218394,
                -17.451453416127478
              ],
              [
                -55.55534565743746,
                -17.44737246057888
              ],
              [
                -55.554950142083506,
                -17.447389447522262
              ],
              [
                -55.54909620325652,
                -17.447638437893808
              ],
              [
                -55.559209172193775,
                -17.439213991573304
              ],
              [
                -55.5622631458763,
                -17.43978198378371
              ],
              [
                -55.56349161590066,
                -17.440009976881903
              ],
              [
                -55.56773612241197,
                -17.434683532888787
              ],
              [
                -55.57434955865974,
                -17.43422405228765
              ],
              [
                -55.57479560046795,
                -17.419099601118738
              ],
              [
                -55.574817584321075,
                -17.41835761330029
              ],
              [
                -55.575167083595545,
                -17.41848661462597
              ],
              [
                -55.5849305094838,
                -17.422082122765886
              ],
              [
                -55.588122521280596,
                -17.413374652388054
              ],
              [
                -55.58607303836329,
                -17.402940683259985
              ],
              [
                -55.58423406574102,
                -17.4022462156164
              ],
              [
                -55.5793491189383,
                -17.40040017725861
              ],
              [
                -55.58549859459766,
                -17.39799722366955
              ],
              [
                -55.58607505928295,
                -17.397772734048313
              ],
              [
                -55.58567756100481,
                -17.397394720654088
              ],
              [
                -55.58312411592711,
                -17.394961229569255
              ],
              [
                -55.58539910078006,
                -17.388161246892274
              ],
              [
                -55.58238563593434,
                -17.37863128660472
              ],
              [
                -55.58881961511534,
                -17.377915806592522
              ],
              [
                -55.58508716525674,
                -17.373173350334483
              ],
              [
                -55.59266907073365,
                -17.373908330049073
              ],
              [
                -55.59276608604854,
                -17.373917842249732
              ],
              [
                -55.60276652646701,
                -17.366712378454014
              ],
              [
                -55.60573302405828,
                -17.36457490482811
              ],
              [
                -55.612898500256925,
                -17.35941247537328
              ],
              [
                -55.613025468678096,
                -17.362280933592892
              ],
              [
                -55.61326243658095,
                -17.36766492248143
              ],
              [
                -55.625816897262496,
                -17.363588480818134
              ],
              [
                -55.63220736261392,
                -17.356246001618626
              ],
              [
                -55.63537534588783,
                -17.35260704981357
              ],
              [
                -55.64076285697418,
                -17.33896710966683
              ],
              [
                -55.64471131720331,
                -17.34118658909825
              ],
              [
                -55.63959982460709,
                -17.351341056626747
              ],
              [
                -55.64171078799074,
                -17.354049026451634
              ],
              [
                -55.64520474921435,
                -17.358529548243492
              ],
              [
                -55.65033124280214,
                -17.352437073627716
              ],
              [
                -55.65460222546146,
                -17.347361604603073
              ],
              [
                -55.65604473531571,
                -17.33782213937219
              ],
              [
                -55.666765132526436,
                -17.349365600333027
              ],
              [
                -55.667583105605914,
                -17.350246612652676
              ],
              [
                -55.67228609231902,
                -17.348527632558156
              ],
              [
                -55.67234261048696,
                -17.34832265056137
              ],
              [
                -55.674661605394164,
                -17.33990369576181
              ],
              [
                -55.680126046979716,
                -17.338544185037087
              ],
              [
                -55.68309105597338,
                -17.346313658684682
              ],
              [
                -55.68448503368869,
                -17.34996714676747
              ],
              [
                -55.68630149481111,
                -17.345713679174953
              ],
              [
                -55.68873902363335,
                -17.340004692249572
              ],
              [
                -55.69270700021443,
                -17.3394502304251
              ],
              [
                -55.695389439369386,
                -17.344155224280797
              ],
              [
                -55.70082390086368,
                -17.353688694665816
              ],
              [
                -55.70599236745859,
                -17.342675239814778
              ],
              [
                -55.70857436975185,
                -17.33717378036214
              ],
              [
                -55.713808324410735,
                -17.341583776799148
              ],
              [
                -55.72172775877812,
                -17.348256238401845
              ],
              [
                -55.72934820987898,
                -17.339433805400596
              ],
              [
                -55.729513207877936,
                -17.33939032399281
              ],
              [
                -55.739740150812,
                -17.336705817654682
              ],
              [
                -55.74348415206389,
                -17.3309013843377
              ],
              [
                -55.76068207159505,
                -17.323093432808612
              ],
              [
                -55.76824150635354,
                -17.32534844108875
              ],
              [
                -55.76940848224944,
                -17.32728346685751
              ],
              [
                -55.77201695294896,
                -17.331611434157363
              ],
              [
                -55.78012740463199,
                -17.320987000891666
              ],
              [
                -55.78389735793676,
                -17.329605494065635
              ],
              [
                -55.80448930903122,
                -17.309002120630463
              ],
              [
                -55.80281729114492,
                -17.303492639611342
              ],
              [
                -55.80201233370962,
                -17.300842139743576
              ],
              [
                -55.80598582562604,
                -17.297569660331703
              ],
              [
                -55.820430705788134,
                -17.297259181438264
              ],
              [
                -55.8206011971412,
                -17.29744319208868
              ],
              [
                -55.82803811932004,
                -17.305461667888217
              ],
              [
                -55.83121212139409,
                -17.301172220751436
              ],
              [
                -55.82894513859342,
                -17.294890707482285
              ],
              [
                -55.82669117160069,
                -17.288645234103505
              ],
              [
                -55.834571615234964,
                -17.29091827209458
              ],
              [
                -55.835287630935824,
                -17.292951257340462
              ],
              [
                -55.83779509185991,
                -17.300071713691395
              ],
              [
                -55.84139705658488,
                -17.300791242606703
              ],
              [
                -55.84998603381463,
                -17.289899789573056
              ],
              [
                -55.85016099932639,
                -17.28777981789686
              ],
              [
                -55.850900545348374,
                -17.27883385425052
              ],
              [
                -55.857705473959456,
                -17.283088837265876
              ],
              [
                -55.87428888694409,
                -17.26819645379416
              ],
              [
                -55.87940333677309,
                -17.272907931711124
              ],
              [
                -55.87649687758647,
                -17.27767942251015
              ],
              [
                -55.87498186713785,
                -17.28016638033214
              ],
              [
                -55.88603977531809,
                -17.284324891317365
              ],
              [
                -55.89215477468481,
                -17.278873943310703
              ],
              [
                -55.890527767549656,
                -17.272585454624547
              ],
              [
                -55.890349302610204,
                -17.27189398307972
              ],
              [
                -55.89147327045023,
                -17.27248047240717
              ],
              [
                -55.89935870319212,
                -17.276593959716944
              ],
              [
                -55.901403719151865,
                -17.272959490008066
              ],
              [
                -55.89891772843516,
                -17.271652476322405
              ],
              [
                -55.88601383172024,
                -17.26486997177776
              ],
              [
                -55.91692610346967,
                -17.26400604996734
              ],
              [
                -55.92581559219403,
                -17.257419127570113
              ],
              [
                -55.930843509043086,
                -17.264219077558877
              ],
              [
                -55.93089151534062,
                -17.268085077497314
              ],
              [
                -55.930957484696506,
                -17.273372560534206
              ],
              [
                -55.93883744280351,
                -17.276634571426428
              ],
              [
                -55.954791393944866,
                -17.256645663588586
              ],
              [
                -55.955293888249486,
                -17.256015666469082
              ],
              [
                -55.95139993228198,
                -17.249757201008606
              ],
              [
                -55.9630808390382,
                -17.25293870863259
              ],
              [
                -55.96735429412392,
                -17.254102735352397
              ],
              [
                -55.9734022568966,
                -17.253166238254146
              ],
              [
                -55.97741173268017,
                -17.24914577199847
              ],
              [
                -55.98029023903879,
                -17.246259263198244
              ],
              [
                -55.981263203477106,
                -17.248126277802662
              ],
              [
                -55.98623864936326,
                -17.25766673925568
              ],
              [
                -55.98988314564999,
                -17.25549627935937
              ],
              [
                -55.98956115449295,
                -17.245930304539968
              ],
              [
                -55.989388182414146,
                -17.24080233454775
              ],
              [
                -55.99467266326861,
                -17.240268341868216
              ],
              [
                -55.996558142305844,
                -17.2422328493274
              ],
              [
                -55.99998457766158,
                -17.24580281789741
              ],
              [
                -56.0033170701234,
                -17.244351848216862
              ],
              [
                -56.003237081332315,
                -17.233290394244
              ],
              [
                -56.01026005081919,
                -17.238602879436865
              ],
              [
                -56.01217907085951,
                -17.222208966698016
              ],
              [
                -56.00959211164579,
                -17.216841953733415
              ],
              [
                -56.00810312494249,
                -17.21375146897233
              ],
              [
                -56.01088861568735,
                -17.21431600250202
              ],
              [
                -56.019621546192035,
                -17.216085483709904
              ],
              [
                -56.018044550724994,
                -17.20901951834669
              ],
              [
                -56.01436757142761,
                -17.207538509445804
              ],
              [
                -56.004985169677965,
                -17.203757510062236
              ],
              [
                -56.01554261300895,
                -17.18979010558655
              ],
              [
                -56.0319065369769,
                -17.18491765939322
              ],
              [
                -56.04461999229303,
                -17.17115174136226
              ],
              [
                -56.048989438669246,
                -17.171995233622848
              ],
              [
                -56.047268928227545,
                -17.18135121825597
              ],
              [
                -56.04671194786722,
                -17.184382210209233
              ],
              [
                -56.05045938799013,
                -17.186508715580644
              ],
              [
                -56.05731837472932,
                -17.176097273041947
              ],
              [
                -56.060308863764405,
                -17.17155829940005
              ],
              [
                -56.06624882659172,
                -17.173900805279374
              ],
              [
                -56.07106728212432,
                -17.17019482666636
              ],
              [
                -56.08393620389033,
                -17.177224322091547
              ],
              [
                -56.10317608972501,
                -17.173101367846574
              ],
              [
                -56.11259100149323,
                -17.16675643758993
              ],
              [
                -56.1195014548945,
                -17.174318424114844
              ],
              [
                -56.12031945500971,
                -17.175213911401467
              ],
              [
                -56.120955940236065,
                -17.175027908750604
              ],
              [
                -56.12487242201445,
                -17.173884413201193
              ],
              [
                -56.13273583060956,
                -17.180105433702852
              ],
              [
                -56.13962529917705,
                -17.179064939078042
              ],
              [
                -56.13797379983204,
                -17.18333239020742
              ],
              [
                -56.13588180238424,
                -17.18874087149244
              ],
              [
                -56.13934228878185,
                -17.190752387673857
              ],
              [
                -56.143667259246996,
                -17.18572042034127
              ],
              [
                -56.14476125831651,
                -17.18444793034978
              ],
              [
                -56.152204194826865,
                -17.18503494531944
              ],
              [
                -56.159402627812725,
                -17.191285442104654
              ],
              [
                -56.16282360491456,
                -17.19425593920741
              ],
              [
                -56.164341604015796,
                -17.193032422058977
              ],
              [
                -56.17029057598185,
                -17.18823748374563
              ],
              [
                -56.176572499320855,
                -17.19553295693921
              ],
              [
                -56.18490598753442,
                -17.18879300961679
              ],
              [
                -56.18719692224212,
                -17.201824936087167
              ],
              [
                -56.18862237894061,
                -17.209930893918337
              ],
              [
                -56.19591784718129,
                -17.206529942139962
              ],
              [
                -56.19762635087143,
                -17.205733431685527
              ],
              [
                -56.200772324627586,
                -17.209148941290096
              ],
              [
                -56.2049322736333,
                -17.21366544284022
              ],
              [
                -56.2072012432474,
                -17.212617457820905
              ],
              [
                -56.21248874138763,
                -17.210174960079286
              ],
              [
                -56.21130173483902,
                -17.21482994680125
              ],
              [
                -56.20824121309814,
                -17.226831373370203
              ],
              [
                -56.220631656828566,
                -17.21986394004632
              ],
              [
                -56.22242665651996,
                -17.21885444269108
              ],
              [
                -56.22204965627695,
                -17.220628935389612
              ],
              [
                -56.22040514053263,
                -17.228352389251395
              ],
              [
                -56.23320306373932,
                -17.226899442054304
              ],
              [
                -56.234358049809124,
                -17.225484936790046
              ],
              [
                -56.23740351444237,
                -17.221756474818257
              ],
              [
                -56.25082044316196,
                -17.218584532279934
              ],
              [
                -56.25444942057981,
                -17.222043027947933
              ],
              [
                -56.25218888726231,
                -17.231876947565244
              ],
              [
                -56.251764385134436,
                -17.233723971854513
              ],
              [
                -56.25363040222544,
                -17.2323934530054
              ],
              [
                -56.25856938664917,
                -17.228870978874617
              ],
              [
                -56.261875324351095,
                -17.230795478111393
              ],
              [
                -56.26005683602208,
                -17.23469696142595
              ],
              [
                -56.25602285871666,
                -17.24335392853035
              ],
              [
                -56.26656878224419,
                -17.23997495474397
              ],
              [
                -56.26851278191486,
                -17.23935196415681
              ],
              [
                -56.27215622069907,
                -17.24263545861621
              ],
              [
                -56.271117247546414,
                -17.243787977260325
              ],
              [
                -56.26760073956902,
                -17.24768841336429
              ],
              [
                -56.275707214478764,
                -17.247634940746895
              ],
              [
                -56.2850891378908,
                -17.247572992339297
              ],
              [
                -56.282325134264255,
                -17.253181943251217
              ],
              [
                -56.2795936545933,
                -17.258725403515278
              ],
              [
                -56.28604259204495,
                -17.270000858929713
              ],
              [
                -56.294434528564615,
                -17.270872893227114
              ],
              [
                -56.29862146675615,
                -17.27561840304319
              ],
              [
                -56.31301240043168,
                -17.268989956290792
              ],
              [
                -56.322710304360946,
                -17.275749451537443
              ],
              [
                -56.322736786446505,
                -17.28527688326613
              ],
              [
                -56.33445972299507,
                -17.281986434084452
              ],
              [
                -56.33534618573878,
                -17.289509392252658
              ],
              [
                -56.34649666086654,
                -17.280768966128683
              ],
              [
                -56.351178613509475,
                -17.27709897694561
              ],
              [
                -56.349793153866415,
                -17.274099509865525
              ],
              [
                -56.34298719353796,
                -17.27550298310493
              ],
              [
                -56.34243119508358,
                -17.272603504684664
              ],
              [
                -56.35542561660697,
                -17.26693156648188
              ],
              [
                -56.361314050978535,
                -17.27074806341646
              ],
              [
                -56.36028202664426,
                -17.280454510811474
              ],
              [
                -56.3830508532194,
                -17.288743517469022
              ],
              [
                -56.38572683509039,
                -17.295125515865358
              ],
              [
                -56.38840180896066,
                -17.3015074722698
              ],
              [
                -56.39954772416259,
                -17.30498399281331
              ],
              [
                -56.40232470628708,
                -17.30585000419087
              ],
              [
                -56.40328769398135,
                -17.30765147818649
              ],
              [
                -56.40756813870886,
                -17.315663472935153
              ],
              [
                -56.41217761468024,
                -17.31650296901247
              ],
              [
                -56.41479109821647,
                -17.31430048660486
              ],
              [
                -56.41787407669639,
                -17.311701499000907
              ],
              [
                -56.43206944853939,
                -17.31815000000825
              ],
              [
                -56.43595542071748,
                -17.322540516697185
              ],
              [
                -56.44279935809878,
                -17.330273466077287
              ],
              [
                -56.45034730150998,
                -17.3259525087164
              ],
              [
                -56.451078841143946,
                -17.318025553091136
              ],
              [
                -56.45184336752603,
                -17.309737580037822
              ],
              [
                -56.45829681222277,
                -17.31279609262563
              ],
              [
                -56.46343376938634,
                -17.31523111748551
              ],
              [
                -56.46540725539328,
                -17.307799131931425
              ],
              [
                -56.46698876200695,
                -17.301841656346244
              ],
              [
                -56.470654226601305,
                -17.302943168992215
              ],
              [
                -56.471676729321,
                -17.30643563621404
              ],
              [
                -56.47259470170283,
                -17.309571135641956
              ],
              [
                -56.47966865579892,
                -17.3046971693441
              ],
              [
                -56.489569647354486,
                -17.297875711104044
              ],
              [
                -56.49768155627031,
                -17.298271758770916
              ],
              [
                -56.5012400691439,
                -17.285886310482592
              ],
              [
                -56.50754349690746,
                -17.29484629049088
              ],
              [
                -56.514927978427835,
                -17.29238682904822
              ],
              [
                -56.50866248527424,
                -17.301184776299003
              ],
              [
                -56.505513486241775,
                -17.305606739288013
              ],
              [
                -56.51115394054076,
                -17.306033221657977
              ],
              [
                -56.51334294673152,
                -17.31238672109861
              ],
              [
                -56.52159588088876,
                -17.310146231945208
              ],
              [
                -56.526011312438676,
                -17.31938022218125
              ],
              [
                -56.530752817022154,
                -17.313807756880433
              ],
              [
                -56.531844801240275,
                -17.31252478286989
              ],
              [
                -56.543976683021775,
                -17.31897525885675
              ],
              [
                -56.55048713531791,
                -17.32243674401003
              ],
              [
                -56.555513599027506,
                -17.320546767342123
              ],
              [
                -56.57063202096497,
                -17.314860364495647
              ],
              [
                -56.578823481921184,
                -17.319865363566716
              ],
              [
                -56.577862444212116,
                -17.323579816973716
              ],
              [
                -56.5760409691863,
                -17.330616270646516
              ],
              [
                -56.584378402689225,
                -17.328809334946875
              ],
              [
                -56.59536330373177,
                -17.33210584204461
              ],
              [
                -56.60515325399894,
                -17.32831887907615
              ],
              [
                -56.61132319544677,
                -17.331469851697804
              ],
              [
                -56.61373969422276,
                -17.327491411001763
              ],
              [
                -56.616242196815385,
                -17.323369916105126
              ],
              [
                -56.624489146738924,
                -17.321648949821803
              ],
              [
                -56.62803863161606,
                -17.323324947889102
              ],
              [
                -56.62884012310719,
                -17.32780490662227
              ],
              [
                -56.62990606981297,
                -17.333763908990306
              ],
              [
                -56.63574355915251,
                -17.32785244875755
              ],
              [
                -56.64690649482177,
                -17.31654853787887
              ],
              [
                -56.649350959044455,
                -17.323737978695107
              ],
              [
                -56.64439601456276,
                -17.327912967232812
              ],
              [
                -56.64181549577118,
                -17.330086950889243
              ],
              [
                -56.64717995583405,
                -17.339024937926
              ],
              [
                -56.65120493525307,
                -17.327959971136753
              ],
              [
                -56.6531549259071,
                -17.322597997891744
              ],
              [
                -56.659823432057685,
                -17.32208701571452
              ],
              [
                -56.66215490006898,
                -17.326321007311783
              ],
              [
                -56.66125037840056,
                -17.326934000542405
              ],
              [
                -56.654655412978606,
                -17.33140595975545
              ],
              [
                -56.663046339287135,
                -17.33839146137169
              ],
              [
                -56.67331877091234,
                -17.332972493253564
              ],
              [
                -56.673405820561214,
                -17.324809550722367
              ],
              [
                -56.67452581199613,
                -17.323758046818497
              ],
              [
                -56.68265124988565,
                -17.31612709330546
              ],
              [
                -56.688044718165,
                -17.320523607939492
              ],
              [
                -56.6953801562623,
                -17.32650257502008
              ],
              [
                -56.69230067429202,
                -17.319664123126415
              ],
              [
                -56.69140372466036,
                -17.317671624987806
              ],
              [
                -56.70179014692955,
                -17.317999138734297
              ],
              [
                -56.71301504658613,
                -17.318353681481213
              ],
              [
                -56.71466605376088,
                -17.315740662260477
              ],
              [
                -56.7195035305368,
                -17.30808923054351
              ],
              [
                -56.72369001770913,
                -17.308492724411543
              ],
              [
                -56.733322437387955,
                -17.309422240814907
              ],
              [
                -56.73760589327462,
                -17.32218521567622
              ],
              [
                -56.737703870834615,
                -17.322475703741496
              ],
              [
                -56.738897370583636,
                -17.323337686236872
              ],
              [
                -56.74377980264097,
                -17.326866688165886
              ],
              [
                -56.756386743739704,
                -17.326295719178997
              ],
              [
                -56.7566827242293,
                -17.33640168887994
              ],
              [
                -56.76872060558921,
                -17.3422551724078
              ],
              [
                -56.76415264744675,
                -17.34715567502311
              ],
              [
                -56.76037514738902,
                -17.35120763206981
              ],
              [
                -56.764095107855006,
                -17.36032960342834
              ],
              [
                -56.77898300542281,
                -17.366881617376222
              ],
              [
                -56.78621792584838,
                -17.370065627711053
              ],
              [
                -56.7888714108715,
                -17.376435572595582
              ],
              [
                -56.788062379321445,
                -17.378958582335148
              ],
              [
                -56.78545588031728,
                -17.387088536831115
              ],
              [
                -56.79049885926453,
                -17.390626552233204
              ],
              [
                -56.80387928390557,
                -17.386188583972842
              ],
              [
                -56.828340603737836,
                -17.39072662637582
              ],
              [
                -56.830617050965394,
                -17.407771545232567
              ],
              [
                -56.84148593274199,
                -17.4177505244113
              ],
              [
                -56.83376497832479,
                -17.423014499621022
              ],
              [
                -56.83162749267751,
                -17.42293350353977
              ],
              [
                -56.823875050737065,
                -17.422640488109252
              ],
              [
                -56.819468533738785,
                -17.430701952395452
              ],
              [
                -56.82218149817991,
                -17.437114925363698
              ],
              [
                -56.83055397230712,
                -17.43511795262448
              ],
              [
                -56.832708942472344,
                -17.434603954155552
              ],
              [
                -56.840376393639986,
                -17.44156942646194
              ],
              [
                -56.82832193051697,
                -17.4502308869723
              ],
              [
                -56.82495744311561,
                -17.45264833669116
              ],
              [
                -56.82488146264779,
                -17.45845282007688
              ],
              [
                -56.831634400839484,
                -17.463231323922138
              ],
              [
                -56.833667372277276,
                -17.463274845902053
              ],
              [
                -56.84728726443096,
                -17.46356737326495
              ],
              [
                -56.84940873086502,
                -17.47029982651323
              ],
              [
                -56.84776324428529,
                -17.47445780358277
              ],
              [
                -56.8455137524911,
                -17.48014128797324
              ],
              [
                -56.849706717671125,
                -17.486343783634975
              ],
              [
                -56.854534197025444,
                -17.485160291661522
              ],
              [
                -56.86143214509871,
                -17.483469800987113
              ],
              [
                -56.8661230782157,
                -17.495026263388382
              ],
              [
                -56.86234356558817,
                -17.50212772320616
              ],
              [
                -56.860936068589226,
                -17.50477172598936
              ],
              [
                -56.86607000534471,
                -17.515020192823695
              ],
              [
                -56.86848649212267,
                -17.515473222150366
              ],
              [
                -56.87001000326557,
                -17.515758687879014
              ],
              [
                -56.871554986852644,
                -17.520534668609972
              ],
              [
                -56.87543640901398,
                -17.532533163273136
              ],
              [
                -56.89523728845096,
                -17.538943175980265
              ],
              [
                -56.90270224038176,
                -17.535247184839474
              ],
              [
                -56.90904420019619,
                -17.532107221601738
              ],
              [
                -56.917012598182566,
                -17.540918718561098
              ],
              [
                -56.94193540747586,
                -17.551456735893684
              ],
              [
                -56.945891365152924,
                -17.55982718746788
              ],
              [
                -56.94984632082366,
                -17.568197676058517
              ],
              [
                -56.963175213649286,
                -17.570446200115395
              ],
              [
                -56.96626571281799,
                -17.57096720592874
              ],
              [
                -56.968160202263036,
                -17.573508674605808
              ],
              [
                -56.972958647727886,
                -17.579946197132628
              ],
              [
                -56.97346415541928,
                -17.579937178461254
              ],
              [
                -56.98182408169482,
                -17.579792696415932
              ],
              [
                -56.975429572115246,
                -17.59552212020495
              ],
              [
                -56.981851516774924,
                -17.60342262008775
              ],
              [
                -56.98087702428287,
                -17.61057505303326
              ],
              [
                -56.97360905211284,
                -17.612766536665685
              ],
              [
                -56.97227153681259,
                -17.61316951963031
              ],
              [
                -56.96001415920779,
                -17.60471805400307
              ],
              [
                -56.95892064116184,
                -17.610558529865457
              ],
              [
                -56.982341411975064,
                -17.640217452502945
              ],
              [
                -56.983318399078776,
                -17.64145443907647
              ],
              [
                -56.985502390385975,
                -17.65015343799672
              ],
              [
                -56.98768632068042,
                -17.65885190593458
              ],
              [
                -56.999901236164405,
                -17.670283857783062
              ],
              [
                -57.00523519401272,
                -17.670992389674073
              ],
              [
                -57.01037114524181,
                -17.671675392159724
              ],
              [
                -57.01904106070254,
                -17.684674837739664
              ],
              [
                -57.01900104535747,
                -17.685606363931896
              ],
              [
                -57.018735037968625,
                -17.69172584791199
              ],
              [
                -57.025934473831846,
                -17.693040323220345
              ],
              [
                -57.03284292576935,
                -17.69430133999651
              ],
              [
                -57.03589839721812,
                -17.7037233014321
              ],
              [
                -57.044379744898144,
                -17.72988171745377
              ],
              [
                -57.04962321377798,
                -17.732220238708432
              ],
              [
                -57.05618121446564,
                -17.729056269182657
              ],
              [
                -57.06365910860833,
                -17.734606752476342
              ],
              [
                -57.06525059893501,
                -17.73467477531559
              ],
              [
                -57.07337905742167,
                -17.735024276917724
              ],
              [
                -57.077348510457085,
                -17.74420623779194
              ],
              [
                -57.07928946904407,
                -17.748695234028098
              ],
              [
                -57.08592841294292,
                -17.750965234198436
              ],
              [
                -57.092567403846054,
                -17.753235271375097
              ],
              [
                -57.0947318489784,
                -17.75984620981194
              ],
              [
                -57.09804731960479,
                -17.76997669231743
              ],
              [
                -57.10540774435485,
                -17.77061671683575
              ],
              [
                -57.113072191037666,
                -17.771283224039582
              ],
              [
                -57.11364968461319,
                -17.773986717849958
              ],
              [
                -57.115073682450145,
                -17.780648173215265
              ],
              [
                -57.13021009449881,
                -17.77404527249741
              ],
              [
                -57.146207484980444,
                -17.76706680547698
              ],
              [
                -57.15215890394491,
                -17.774123309034408
              ],
              [
                -57.15540541373756,
                -17.77797281425458
              ],
              [
                -57.16861880117197,
                -17.776455337476918
              ],
              [
                -57.17431627357975,
                -17.775800842821223
              ],
              [
                -57.200101063653705,
                -17.7883838790865
              ],
              [
                -57.21533495702971,
                -17.78753338795267
              ],
              [
                -57.22660092974212,
                -17.782345950592163
              ],
              [
                -57.22754388630378,
                -17.789862924464238
              ],
              [
                -57.234548327908215,
                -17.787892457391383
              ],
              [
                -57.242433268997466,
                -17.79714590547527
              ],
              [
                -57.25148823227154,
                -17.79301848120774
              ],
              [
                -57.2470047218071,
                -17.80180941001915
              ],
              [
                -57.24493070190547,
                -17.805874906657063
              ],
              [
                -57.23357779922174,
                -17.80770486197314
              ],
              [
                -57.232096806941705,
                -17.81194285346864
              ],
              [
                -57.25207517543947,
                -17.810322403748955
              ],
              [
                -57.26430005709007,
                -17.81990888489139
              ],
              [
                -57.273662513113706,
                -17.816232419664374
              ],
              [
                -57.2832349480082,
                -17.812473450302047
              ],
              [
                -57.2829734293343,
                -17.818255446941837
              ],
              [
                -57.28279292517945,
                -17.82225442578867
              ],
              [
                -57.292960391355535,
                -17.819495465056622
              ],
              [
                -57.292297872743525,
                -17.81877396616199
              ],
              [
                -57.28765541550495,
                -17.81371796291463
              ],
              [
                -57.28948588788677,
                -17.81111399688747
              ],
              [
                -57.29923281900007,
                -17.81640896947307
              ],
              [
                -57.30754727464184,
                -17.8103695237722
              ],
              [
                -57.31084925211592,
                -17.817386489861136
              ],
              [
                -57.3162987246957,
                -17.820109014374207
              ],
              [
                -57.32426212924739,
                -17.82408801251698
              ],
              [
                -57.32468712334266,
                -17.8287115175213
              ],
              [
                -57.34081901949478,
                -17.830195037724714
              ],
              [
                -57.341873981362625,
                -17.835556509498748
              ],
              [
                -57.34615098761916,
                -17.83370555488112
              ],
              [
                -57.352918906764366,
                -17.837420519987976
              ],
              [
                -57.3612673716395,
                -17.833719571210867
              ],
              [
                -57.362617374506684,
                -17.833120584069974
              ],
              [
                -57.36754283818727,
                -17.825758095682513
              ],
              [
                -57.37697928856708,
                -17.825718158419782
              ],
              [
                -57.38438468546856,
                -17.84213957557397
              ],
              [
                -57.38514918564746,
                -17.843835092947494
              ],
              [
                -57.39082616497668,
                -17.84508059489124
              ],
              [
                -57.391312131985885,
                -17.844663081788497
              ],
              [
                -57.397982136673434,
                -17.8389291095647
              ],
              [
                -57.400043079152375,
                -17.847384620942787
              ],
              [
                -57.40157306125854,
                -17.848400592970616
              ],
              [
                -57.403233062268455,
                -17.849503109000736
              ],
              [
                -57.409214505860895,
                -17.853475094118316
              ],
              [
                -57.40945401244326,
                -17.854477118836012
              ],
              [
                -57.41021297143827,
                -17.857654064429624
              ],
              [
                -57.412439956126306,
                -17.856865086319655
              ],
              [
                -57.42119990570822,
                -17.853760143292355
              ],
              [
                -57.43809776001683,
                -17.871058077044445
              ],
              [
                -57.44618620160185,
                -17.87406561247985
              ],
              [
                -57.445751685523454,
                -17.88350308562162
              ],
              [
                -57.44538214203224,
                -17.89152955143215
              ],
              [
                -57.45193106276908,
                -17.90220348707069
              ],
              [
                -57.46139953765282,
                -17.898097553820186
              ],
              [
                -57.47713049904946,
                -17.878443167375373
              ],
              [
                -57.477090021591074,
                -17.868529195809053
              ],
              [
                -57.477048522122345,
                -17.858614748255125
              ],
              [
                -57.50882281402256,
                -17.863450797514393
              ],
              [
                -57.53423115958467,
                -17.853199905750564
              ],
              [
                -57.5323611994728,
                -17.845936431064636
              ],
              [
                -57.53049121434947,
                -17.83867343538778
              ],
              [
                -57.551073629703446,
                -17.827970545708684
              ],
              [
                -57.551464597734395,
                -17.827767548517805
              ],
              [
                -57.55164463154362,
                -17.827422564327517
              ],
              [
                -57.558127108667534,
                -17.814999618492255
              ],
              [
                -57.56733802377779,
                -17.81496713065667
              ],
              [
                -57.57090901656228,
                -17.814954647025324
              ],
              [
                -57.57177353941613,
                -17.81232417249324
              ],
              [
                -57.57579252303366,
                -17.80009122747527
              ],
              [
                -57.589517428991,
                -17.801749741837167
              ],
              [
                -57.60324434195956,
                -17.803409283213384
              ],
              [
                -57.6120433521648,
                -17.778974373965692
              ],
              [
                -57.60463489017327,
                -17.778683388787492
              ],
              [
                -57.60218741736526,
                -17.778587383782174
              ],
              [
                -57.60041191951565,
                -17.774852862656065
              ],
              [
                -57.60924636931508,
                -17.769476403546726
              ],
              [
                -57.61434734295224,
                -17.766372440884297
              ],
              [
                -57.6194903406823,
                -17.75432551405064
              ],
              [
                -57.61582839226749,
                -17.752287500433837
              ],
              [
                -57.61145843457353,
                -17.74985551450829
              ],
              [
                -57.617656405912435,
                -17.747514026731814
              ],
              [
                -57.61810487247587,
                -17.74734501855518
              ],
              [
                -57.61857488982358,
                -17.746144050443128
              ],
              [
                -57.622571381801436,
                -17.735922100548848
              ],
              [
                -57.63976481985551,
                -17.7169212230657
              ],
              [
                -57.64420429985669,
                -17.718391198891545
              ],
              [
                -57.64446275987996,
                -17.719556685036103
              ],
              [
                -57.64543275422139,
                -17.723923203598286
              ],
              [
                -57.66301513447308,
                -17.729417707988407
              ],
              [
                -57.66651511725647,
                -17.725706742490736
              ],
              [
                -57.67114956297318,
                -17.7278942240569
              ],
              [
                -57.67185110779399,
                -17.71928529828287
              ],
              [
                -57.68376855300278,
                -17.715200822112156
              ],
              [
                -57.6795756130159,
                -17.703606366206124
              ],
              [
                -57.682604610379336,
                -17.69581788230613
              ],
              [
                -57.68018511113504,
                -17.68447291307692
              ],
              [
                -57.672860692824855,
                -17.681584428291753
              ],
              [
                -57.67046220906274,
                -17.680638435744726
              ],
              [
                -57.672272707043795,
                -17.676836949213396
              ],
              [
                -57.668296224997185,
                -17.671470453758552
              ],
              [
                -57.671958223529806,
                -17.670300988799625
              ],
              [
                -57.67123826689306,
                -17.663675007691136
              ],
              [
                -57.67829819484977,
                -17.65772802910844
              ],
              [
                -57.67884772758945,
                -17.657265044332398
              ],
              [
                -57.678632234372,
                -17.656774021682924
              ],
              [
                -57.67640923704692,
                -17.651718066582504
              ],
              [
                -57.680890721898834,
                -17.650324076611824
              ],
              [
                -57.68218523363473,
                -17.649921583530077
              ],
              [
                -57.68218421391591,
                -17.646630104326018
              ],
              [
                -57.68218275112035,
                -17.6423010891574
              ],
              [
                -57.683903198597356,
                -17.6417196248734
              ],
              [
                -57.68937019333754,
                -17.639872642211
              ],
              [
                -57.68686921208707,
                -17.631161169618426
              ],
              [
                -57.68621524273741,
                -17.62888419278413
              ],
              [
                -57.68753873381357,
                -17.628294185503933
              ],
              [
                -57.69213621676381,
                -17.626244697372055
              ],
              [
                -57.70151521547565,
                -17.600807309598572
              ],
              [
                -57.69667923985764,
                -17.60079680114374
              ],
              [
                -57.70334370994484,
                -17.58929886411996
              ],
              [
                -57.70139727374963,
                -17.578898919593204
              ],
              [
                -57.70110078211105,
                -17.577316399991993
              ],
              [
                -57.70293174976326,
                -17.5749919307711
              ],
              [
                -57.71030276023056,
                -17.565634965235507
              ],
              [
                -57.71092328499365,
                -17.55465001363399
              ],
              [
                -57.71154380073492,
                -17.543665061046656
              ],
              [
                -57.7275172254305,
                -17.52931118313677
              ],
              [
                -57.7335146744172,
                -17.532590687799654
              ],
              [
                -57.73951163941082,
                -17.535869652471604
              ],
              [
                -57.740116605619995,
                -17.53725367163916
              ],
              [
                -57.75176249059752,
                -17.5638880689954
              ],
              [
                -57.76338941592733,
                -17.553692635647707
              ],
              [
                -57.7722494003697,
                -17.545923720713063
              ],
              [
                -57.88304440998102,
                -17.44921537382089
              ],
              [
                -57.96941967835974,
                -17.503633873163142
              ],
              [
                -57.97294164219,
                -17.50559636765887
              ],
              [
                -57.97990759672627,
                -17.510102352212538
              ],
              [
                -57.983319080821516,
                -17.51115435995017
              ],
              [
                -57.99135452270868,
                -17.51379538148525
              ],
              [
                -57.994984481211006,
                -17.5138458506598
              ],
              [
                -58.00002246298322,
                -17.505218426980488
              ],
              [
                -58.00466094313846,
                -17.505577922560548
              ],
              [
                -58.011257942975824,
                -17.500129981618013
              ],
              [
                -58.015747383175196,
                -17.499391468051304
              ],
              [
                -58.022671862962234,
                -17.493926999009656
              ],
              [
                -58.028734831534784,
                -17.49551602551536
              ],
              [
                -58.034077279403206,
                -17.494371054715813
              ],
              [
                -58.04274072085741,
                -17.49218305450681
              ],
              [
                -58.04347226114163,
                -17.489332576390982
              ],
              [
                -58.044410240041,
                -17.483645602752915
              ],
              [
                -58.04622226564204,
                -17.475342163468817
              ],
              [
                -58.04645874337314,
                -17.47418064356134
              ],
              [
                -58.04883279274153,
                -17.462519222695008
              ],
              [
                -58.05247627499946,
                -17.458348221182447
              ],
              [
                -58.05527075420774,
                -17.455430242644443
              ],
              [
                -58.05857871765573,
                -17.451962269550968
              ],
              [
                -58.06598870874823,
                -17.4510053083223
              ],
              [
                -58.071377647686575,
                -17.451528804176526
              ],
              [
                -58.07596411092633,
                -17.453788790266152
              ],
              [
                -58.079024600911175,
                -17.45535180111825
              ],
              [
                -58.08907549805118,
                -17.46048380721875
              ],
              [
                -58.09697798679405,
                -17.457462343228034
              ],
              [
                -58.10923241295092,
                -17.452896392397513
              ],
              [
                -58.1142083762932,
                -17.449943929593108
              ],
              [
                -58.11862186220653,
                -17.443785923689518
              ],
              [
                -58.11877086832955,
                -17.440895464212034
              ],
              [
                -58.12009787186573,
                -17.43121499494011
              ],
              [
                -58.12239841096924,
                -17.41443008657833
              ],
              [
                -58.12919686386169,
                -17.41103508815024
              ],
              [
                -58.1353503119629,
                -17.413709602659633
              ],
              [
                -58.13858131033859,
                -17.415114087554553
              ],
              [
                -58.1447782860944,
                -17.41315613403757
              ],
              [
                -58.14918725917408,
                -17.406228652072276
              ],
              [
                -58.15180223959864,
                -17.401504181003276
              ],
              [
                -58.151783768584764,
                -17.400475681920067
              ],
              [
                -58.151486302327264,
                -17.384027264574197
              ],
              [
                -58.1566687711273,
                -17.385107261717422
              ],
              [
                -58.16449571189813,
                -17.38694079575093
              ],
              [
                -58.16812518125193,
                -17.387804799743897
              ],
              [
                -58.17278413260149,
                -17.38881780380897
              ],
              [
                -58.176255140278705,
                -17.38957231381977
              ],
              [
                -58.18438056051417,
                -17.39020231727131
              ],
              [
                -58.18795503709188,
                -17.38896331722785
              ],
              [
                -58.19721400802569,
                -17.383875874607007
              ],
              [
                -58.19918199339147,
                -17.38173838089256
              ],
              [
                -58.19831350300391,
                -17.37812888656004
              ],
              [
                -58.19703902070306,
                -17.375060916101948
              ],
              [
                -58.196335541993776,
                -17.373340896920336
              ],
              [
                -58.195732552955995,
                -17.371866913049665
              ],
              [
                -58.19449303533816,
                -17.368471914946195
              ],
              [
                -58.1998835646344,
                -17.359208477470506
              ],
              [
                -58.20155355434844,
                -17.356549999998403
              ],
              [
                -58.21545443895425,
                -17.35888451167261
              ],
              [
                -58.21924590197981,
                -17.35950703000556
              ],
              [
                -58.225108894776746,
                -17.355813052273465
              ],
              [
                -58.226897890027296,
                -17.351253062419918
              ],
              [
                -58.227699920439285,
                -17.347464092066534
              ],
              [
                -58.232880338881486,
                -17.35086059727899
              ],
              [
                -58.23972082896528,
                -17.34913562746832
              ],
              [
                -58.239960324871724,
                -17.349338098303548
              ],
              [
                -58.24277578926846,
                -17.351715088380182
              ],
              [
                -58.24610425609081,
                -17.354198596366544
              ],
              [
                -58.25060974912772,
                -17.353184639830403
              ],
              [
                -58.2540457013104,
                -17.352059125977874
              ],
              [
                -58.255688207725,
                -17.34562317294897
              ],
              [
                -58.25945067567982,
                -17.344909191348307
              ],
              [
                -58.26277968742042,
                -17.34311118580463
              ],
              [
                -58.262999195824314,
                -17.329872761296635
              ],
              [
                -58.267774202939826,
                -17.32749575968323
              ],
              [
                -58.271444167586516,
                -17.32566829812142
              ],
              [
                -58.275911155776875,
                -17.323254283857796
              ],
              [
                -58.27673613175702,
                -17.31992581277705
              ],
              [
                -58.27749663761686,
                -17.31426733949709
              ],
              [
                -58.27762765148493,
                -17.31329333358066
              ],
              [
                -58.276708663209746,
                -17.309021350667024
              ],
              [
                -58.276831193941305,
                -17.299826377375137
              ],
              [
                -58.286546110925975,
                -17.3009139000585
              ],
              [
                -58.287155619872976,
                -17.300972922391836
              ],
              [
                -58.29138957271604,
                -17.301384406648584
              ],
              [
                -58.29760807866256,
                -17.297917439908186
              ],
              [
                -58.296999080348904,
                -17.2940234703313
              ],
              [
                -58.296216586997154,
                -17.290901957340512
              ],
              [
                -58.29491708635963,
                -17.29015446889184
              ],
              [
                -58.29116164831181,
                -17.287992496604378
              ],
              [
                -58.30265759860181,
                -17.278026530371793
              ],
              [
                -58.30282662534958,
                -17.269786084526686
              ],
              [
                -58.304520101842016,
                -17.267152600994784
              ],
              [
                -58.30735456985202,
                -17.26639958913856
              ],
              [
                -58.3127185670651,
                -17.26588862381555
              ],
              [
                -58.3166975307483,
                -17.265509127446453
              ],
              [
                -58.320462031386825,
                -17.261306172243934
              ],
              [
                -58.31985400819932,
                -17.25771616950835
              ],
              [
                -58.31938053296293,
                -17.254917195073514
              ],
              [
                -58.32241152688015,
                -17.2515787096683
              ],
              [
                -58.32778151718406,
                -17.24741123642674
              ],
              [
                -58.33056347587554,
                -17.245451758074
              ],
              [
                -58.33396045484672,
                -17.243059234957755
              ],
              [
                -58.33673944843231,
                -17.240422253203477
              ],
              [
                -58.338106962128556,
                -17.23665730218714
              ],
              [
                -58.33915644543483,
                -17.233765807996956
              ],
              [
                -58.34083744585803,
                -17.233356313863563
              ],
              [
                -58.34535542212205,
                -17.232255334632352
              ],
              [
                -58.34711640434864,
                -17.2284763626721
              ],
              [
                -58.34914492437247,
                -17.224877362701758
              ],
              [
                -58.351321899095716,
                -17.22068237740413
              ],
              [
                -58.35593791625615,
                -17.211789453413182
              ],
              [
                -58.35826490505607,
                -17.212290929415502
              ],
              [
                -58.359533391226336,
                -17.21256395259879
              ],
              [
                -58.363629347678966,
                -17.21309695299144
              ],
              [
                -58.36367287147576,
                -17.206186471703663
              ],
              [
                -58.36367686624393,
                -17.205536491216193
              ],
              [
                -58.36394237580454,
                -17.199283511968954
              ],
              [
                -58.36899385696483,
                -17.199895027486427
              ],
              [
                -58.379882781541596,
                -17.193053071569874
              ],
              [
                -58.38065180177229,
                -17.192645561097645
              ],
              [
                -58.38280976019848,
                -17.191501066000505
              ],
              [
                -58.394895203947684,
                -17.183656626974905
              ],
              [
                -58.39539573595469,
                -17.178144651463338
              ],
              [
                -58.39644530500057,
                -17.152092767450494
              ],
              [
                -58.39667279665245,
                -17.144420285589216
              ],
              [
                -58.39690382682463,
                -17.136546336520702
              ],
              [
                -58.396911827526644,
                -17.136272341597056
              ],
              [
                -58.39287336323557,
                -17.127159346474688
              ],
              [
                -58.38610496809654,
                -17.118116397177868
              ],
              [
                -58.38482846460821,
                -17.11469239515144
              ],
              [
                -58.38554995156399,
                -17.110848399815755
              ],
              [
                -58.38810346608888,
                -17.108017935214832
              ],
              [
                -58.39127093339848,
                -17.10493645113841
              ],
              [
                -58.39699092401389,
                -17.104578943020652
              ],
              [
                -58.40056839168701,
                -17.105521976453346
              ],
              [
                -58.40658134785511,
                -17.10829795099482
              ],
              [
                -58.40767930848758,
                -17.108804477826222
              ],
              [
                -58.4129527814753,
                -17.110808951474198
              ],
              [
                -58.420419752492094,
                -17.111733498854527
              ],
              [
                -58.42380721812816,
                -17.11167598067121
              ],
              [
                -58.42838570494116,
                -17.104787514833607
              ],
              [
                -58.42725725120302,
                -17.100013042374208
              ],
              [
                -58.42459076094026,
                -17.08950610712564
              ],
              [
                -58.433439234076666,
                -17.08609813269004
              ],
              [
                -58.43454972316235,
                -17.082032126181794
              ],
              [
                -58.43518923724763,
                -17.078327149089187
              ],
              [
                -58.430984289219595,
                -17.073115148543604
              ],
              [
                -58.429886295918955,
                -17.071753692015967
              ],
              [
                -58.43039227441471,
                -17.06857120457586
              ],
              [
                -58.430590278570655,
                -17.067323695890614
              ],
              [
                -58.431248281930905,
                -17.062740202242942
              ],
              [
                -58.4315442965702,
                -17.05852824924735
              ],
              [
                -58.42879584104294,
                -17.056326731792193
              ],
              [
                -58.424715879885554,
                -17.05305922608809
              ],
              [
                -58.42137539626165,
                -17.050805228345098
              ],
              [
                -58.42605837649475,
                -17.048252761043138
              ],
              [
                -58.42767235873571,
                -17.047707269230283
              ],
              [
                -58.42892586493425,
                -17.047283248036912
              ],
              [
                -58.43281434403417,
                -17.045529778638425
              ],
              [
                -58.43311532207719,
                -17.038956807781148
              ],
              [
                -58.42997085734275,
                -17.03628333016118
              ],
              [
                -58.42628387765025,
                -17.036687799282834
              ],
              [
                -58.4184299541782,
                -17.037548762112564
              ],
              [
                -58.41633096659976,
                -17.034585282262608
              ],
              [
                -58.41810594802738,
                -17.0295648271506
              ],
              [
                -58.42136045379351,
                -17.028672341819064
              ],
              [
                -58.42690441327864,
                -17.028052821213983
              ],
              [
                -58.42887637936045,
                -17.027831837048012
              ],
              [
                -58.43409886703638,
                -17.026793373249685
              ],
              [
                -58.434023869885564,
                -17.021158405832736
              ],
              [
                -58.43480992651276,
                -17.00699395556306
              ],
              [
                -58.43214894511697,
                -17.004163448794603
              ],
              [
                -58.429139443514806,
                -17.00148644355709
              ],
              [
                -58.428834972274494,
                -17.00118293396311
              ],
              [
                -58.42663747819421,
                -16.998991467898588
              ],
              [
                -58.42558299259388,
                -16.99616194118605
              ],
              [
                -58.42422552480009,
                -16.99300748996902
              ],
              [
                -58.423203542840824,
                -16.98913049839616
              ],
              [
                -58.43085598397324,
                -16.99033800700762
              ],
              [
                -58.436093960500415,
                -16.98601152494436
              ],
              [
                -58.440643452187295,
                -16.982865529576962
              ],
              [
                -58.44237340946297,
                -16.981669557561567
              ],
              [
                -58.44543591198728,
                -16.97911209516902
              ],
              [
                -58.44591942348281,
                -16.976699089720938
              ],
              [
                -58.44700192030435,
                -16.97129710434525
              ],
              [
                -58.44799741948456,
                -16.967436104790078
              ],
              [
                -58.45211791517578,
                -16.968233615077967
              ],
              [
                -58.45546837015894,
                -16.968882133004843
              ],
              [
                -58.45903785269653,
                -16.965505673051535
              ],
              [
                -58.460012344649485,
                -16.961041174773904
              ],
              [
                -58.46113389463537,
                -16.9540112067934
              ],
              [
                -58.45990437660894,
                -16.950264221199348
              ],
              [
                -58.459530409071924,
                -16.94912574566454
              ],
              [
                -58.45742191591619,
                -16.943426250422583
              ],
              [
                -58.45605246139381,
                -16.9394492673765
              ],
              [
                -58.459883421073975,
                -16.937246778532995
              ],
              [
                -58.464096379519475,
                -16.93903027921861
              ],
              [
                -58.46796387055187,
                -16.940668284601806
              ],
              [
                -58.47099334859244,
                -16.936523306258586
              ],
              [
                -58.47321232702078,
                -16.933391842954965
              ],
              [
                -58.4719418826574,
                -16.93059632058206
              ],
              [
                -58.46983486589729,
                -16.9265833458228
              ],
              [
                -58.468215911374145,
                -16.923553868485662
              ],
              [
                -58.469678916218506,
                -16.920927355330452
              ],
              [
                -58.47377639210298,
                -16.913967888569093
              ],
              [
                -58.46681544074956,
                -16.910822389279506
              ],
              [
                -58.4662719658585,
                -16.9105774028646
              ],
              [
                -58.461542984457644,
                -16.902962417506714
              ],
              [
                -58.46063303220662,
                -16.899274944517426
              ],
              [
                -58.465985997988625,
                -16.898639937404067
              ],
              [
                -58.46752197074554,
                -16.898457475963525
              ],
              [
                -58.46510652317335,
                -16.885474986235472
              ],
              [
                -58.46508102268909,
                -16.885344002679464
              ],
              [
                -58.46404904937481,
                -16.880111520385412
              ],
              [
                -58.46259954689286,
                -16.872957540505336
              ],
              [
                -58.46446258304759,
                -16.868157560793215
              ],
              [
                -58.464705573200526,
                -16.86753057283429
              ],
              [
                -58.464602068106316,
                -16.867438084934005
              ],
              [
                -58.45993862088749,
                -16.863282071388802
              ],
              [
                -58.46531358750411,
                -16.857470111397184
              ],
              [
                -58.46101262662854,
                -16.848994617541607
              ],
              [
                -58.46715111563963,
                -16.847913174091442
              ],
              [
                -58.46182217299425,
                -16.839908186853165
              ],
              [
                -58.46880264018701,
                -16.83343972742169
              ],
              [
                -58.471768631262314,
                -16.830518743177358
              ],
              [
                -58.475379582963484,
                -16.826962242011284
              ],
              [
                -58.47682660595659,
                -16.81501631002901
              ],
              [
                -58.47462865684858,
                -16.80499132650108
              ],
              [
                -58.47378315743814,
                -16.80118536618627
              ],
              [
                -58.47258420619511,
                -16.796660884786682
              ],
              [
                -58.47125572590265,
                -16.790969885605172
              ],
              [
                -58.466269752019464,
                -16.79151739504719
              ],
              [
                -58.462703751560674,
                -16.79190935163376
              ],
              [
                -58.45801882446868,
                -16.785496896909724
              ],
              [
                -58.4598333044093,
                -16.783098884762946
              ],
              [
                -58.4628858068111,
                -16.779469406492282
              ],
              [
                -58.46602381079822,
                -16.756402009108946
              ],
              [
                -58.46957734675847,
                -16.750255565885404
              ],
              [
                -58.472795309288635,
                -16.744688584569555
              ],
              [
                -58.47203781535069,
                -16.74160209970931
              ],
              [
                -58.47068287775006,
                -16.736440097453084
              ],
              [
                -58.469109886377396,
                -16.730959616901995
              ],
              [
                -58.46605590617817,
                -16.720318164801906
              ],
              [
                -58.466839917996914,
                -16.716283669480166
              ],
              [
                -58.46793591979959,
                -16.71151469679826
              ],
              [
                -58.46985794597412,
                -16.703148761197504
              ],
              [
                -58.46848397952802,
                -16.697959755034848
              ],
              [
                -58.46426001369948,
                -16.681682328101587
              ],
              [
                -58.46024659332774,
                -16.666215868575947
              ],
              [
                -58.461355101582605,
                -16.658976888605093
              ],
              [
                -58.462463099831844,
                -16.65173791863756
              ],
              [
                -58.450854758634726,
                -16.6264205149832
              ],
              [
                -58.43543993587511,
                -16.591878117282516
              ],
              [
                -58.420615071643134,
                -16.57304217868306
              ],
              [
                -58.410734646403206,
                -16.569202175459697
              ],
              [
                -58.393187795644145,
                -16.562382641118656
              ],
              [
                -58.388904352219015,
                -16.552000660412396
              ],
              [
                -58.3883658250782,
                -16.550695663221955
              ],
              [
                -58.38541835877534,
                -16.543113207810908
              ],
              [
                -58.35703712548382,
                -16.528809694507682
              ],
              [
                -58.35698958984857,
                -16.528785715485636
              ],
              [
                -58.35689261867591,
                -16.528705201565156
              ],
              [
                -58.34924016511699,
                -16.5222931880802
              ],
              [
                -58.34323520846785,
                -16.517261710207205
              ],
              [
                -58.333050834080986,
                -16.48950678680888
              ],
              [
                -58.33340084871241,
                -16.483851323998348
              ],
              [
                -58.334053382275314,
                -16.477488365725357
              ],
              [
                -58.33837936927447,
                -16.469694892080366
              ],
              [
                -58.347082845169275,
                -16.454015983275983
              ],
              [
                -58.35069534683054,
                -16.443109027191728
              ],
              [
                -58.35320787581152,
                -16.43473858940369
              ],
              [
                -58.355479880550654,
                -16.427039623378068
              ],
              [
                -58.35255941034824,
                -16.41813865346232
              ],
              [
                -58.350656912810194,
                -16.411865178646696
              ],
              [
                -58.344787002507985,
                -16.393686209317234
              ],
              [
                -58.34373551872298,
                -16.38964425376674
              ],
              [
                -58.337934579705575,
                -16.38573674214406
              ],
              [
                -58.33560710565397,
                -16.386432231732122
              ],
              [
                -58.33304259192428,
                -16.387197713466605
              ],
              [
                -58.3216942077938,
                -16.379574734107404
              ],
              [
                -58.31262125844843,
                -16.373786237031055
              ],
              [
                -58.30914181204933,
                -16.371667253990804
              ],
              [
                -58.3064438293396,
                -16.369683215314737
              ],
              [
                -58.30818734490331,
                -16.359838284617897
              ],
              [
                -58.30702733984387,
                -16.352697308153573
              ],
              [
                -58.30443138399857,
                -16.337818355955562
              ],
              [
                -58.304009929041456,
                -16.33382736279125
              ],
              [
                -58.30358692808974,
                -16.329836895623643
              ],
              [
                -58.30178151184302,
                -16.30698400410861
              ],
              [
                -58.30901495637845,
                -16.29871605247818
              ],
              [
                -58.316247430919596,
                -16.290448098838752
              ],
              [
                -58.32066149975868,
                -16.264235211787735
              ],
              [
                -58.32499993865497,
                -16.267558714028596
              ],
              [
                -58.327300410766725,
                -16.269608677423587
              ],
              [
                -58.33437535571303,
                -16.272005186966553
              ],
              [
                -58.338407852616285,
                -16.273371215268625
              ],
              [
                -58.3452023060501,
                -16.27240572751027
              ],
              [
                -58.34989127143122,
                -16.270822730123008
              ],
              [
                -58.35160425407341,
                -16.270244243557787
              ],
              [
                -58.3563462286464,
                -16.271164254349415
              ],
              [
                -58.36507764194402,
                -16.27285925269771
              ],
              [
                -58.367167666609134,
                -16.27173727820175
              ],
              [
                -58.38798952867969,
                -16.260559888863895
              ],
              [
                -58.388625018550826,
                -16.263692873958124
              ],
              [
                -58.389589525563515,
                -16.269442823460224
              ],
              [
                -58.39001450541011,
                -16.273870329913294
              ],
              [
                -58.391323993268536,
                -16.28346427600786
              ],
              [
                -58.39177745353555,
                -16.286112280598932
              ],
              [
                -58.393521448088336,
                -16.296297234421203
              ],
              [
                -58.39929086583904,
                -16.30158823740387
              ],
              [
                -58.40774981188569,
                -16.30868171877028
              ],
              [
                -58.42242670052486,
                -16.31656870308562
              ],
              [
                -58.42605465215367,
                -16.318653726623634
              ],
              [
                -58.42968263178451,
                -16.320738216166998
              ],
              [
                -58.784851858270954,
                -16.30638355495383
              ],
              [
                -59.46942756256911,
                -16.279026223128664
              ],
              [
                -59.53564515861412,
                -16.277614862682967
              ],
              [
                -59.76089075238749,
                -16.273331894432825
              ],
              [
                -59.82203985279638,
                -16.272169576038976
              ],
              [
                -59.913570283540004,
                -16.27029927211157
              ],
              [
                -60.032466538840715,
                -16.267881031812802
              ],
              [
                -60.12794745265527,
                -16.26604129095009
              ],
              [
                -60.17100568018465,
                -16.2652163861956
              ],
              [
                -60.22831345931413,
                -15.586288674762281
              ],
              [
                -60.23794515280417,
                -15.473380654124782
              ],
              [
                -60.239756652008516,
                -15.471340138144111
              ],
              [
                -60.49923374558318,
                -15.179065885882903
              ],
              [
                -60.5189616643651,
                -15.158908976158193
              ],
              [
                -60.521671147995605,
                -15.156141013945762
              ],
              [
                -60.52654464514244,
                -15.150754550688756
              ],
              [
                -60.53141810828944,
                -15.145368080428312
              ],
              [
                -60.54743505403753,
                -15.127317677038576
              ],
              [
                -60.5558085014383,
                -15.117801230539206
              ],
              [
                -60.55581851840677,
                -15.11778973960598
              ],
              [
                -60.55584751931349,
                -15.117757239796463
              ],
              [
                -60.564258975595926,
                -15.108196290543571
              ],
              [
                -60.500358396733276,
                -15.095751205679536
              ],
              [
                -60.243661968423204,
                -15.096299658401563
              ],
              [
                -60.271537354954035,
                -14.619837160666181
              ],
              [
                -60.2785013487639,
                -14.621755139674331
              ],
              [
                -60.28197579329634,
                -14.6224671576283
              ],
              [
                -60.28644078342823,
                -14.623381177000127
              ],
              [
                -60.29145823183917,
                -14.624473147909335
              ],
              [
                -60.30504867009758,
                -14.61742070669836
              ],
              [
                -60.30818866437799,
                -14.615691215438204
              ],
              [
                -60.315652641399204,
                -14.611577783103902
              ],
              [
                -60.319501601301546,
                -14.608631271166784
              ],
              [
                -60.321063603141056,
                -14.605572307578276
              ],
              [
                -60.32194709007588,
                -14.601217310537923
              ],
              [
                -60.32354011736366,
                -14.59191535138505
              ],
              [
                -60.324558636741976,
                -14.584888392080323
              ],
              [
                -60.32532964714698,
                -14.579759407818868
              ],
              [
                -60.33117210258686,
                -14.567217967765865
              ],
              [
                -60.32910465927462,
                -14.564938475080869
              ],
              [
                -60.328947160537474,
                -14.564800979268405
              ],
              [
                -60.32607416255704,
                -14.562299972663894
              ],
              [
                -60.327986171820754,
                -14.559439007082869
              ],
              [
                -60.33097414430426,
                -14.556300022973783
              ],
              [
                -60.33144662971699,
                -14.555343525758543
              ],
              [
                -60.332337131611524,
                -14.553540051779365
              ],
              [
                -60.33373763602718,
                -14.54764157693128
              ],
              [
                -60.334025152108936,
                -14.545587071596854
              ],
              [
                -60.33427163674139,
                -14.54382959801111
              ],
              [
                -60.33544166550256,
                -14.536655597641664
              ],
              [
                -60.33657413940013,
                -14.535943112934884
              ],
              [
                -60.339643136572676,
                -14.534012620277482
              ],
              [
                -60.33775615677268,
                -14.531472631026901
              ],
              [
                -60.33660915522505,
                -14.529929625519216
              ],
              [
                -60.33898767744307,
                -14.526317655905551
              ],
              [
                -60.33919515088261,
                -14.526026642502497
              ],
              [
                -60.34185563567895,
                -14.522301667953812
              ],
              [
                -60.340861681628205,
                -14.519723174835825
              ],
              [
                -60.3406146805968,
                -14.519082698793692
              ],
              [
                -60.33899768755021,
                -14.515095192809959
              ],
              [
                -60.338178197320715,
                -14.510857217573504
              ],
              [
                -60.34542765229039,
                -14.505591272198554
              ],
              [
                -60.347437186619366,
                -14.499867272028952
              ],
              [
                -60.34841317548796,
                -14.495701787480003
              ],
              [
                -60.356038151823974,
                -14.491894349217313
              ],
              [
                -60.366246100754594,
                -14.486796368700285
              ],
              [
                -60.36641008611814,
                -14.482779913769672
              ],
              [
                -60.36076911533325,
                -14.484694359827534
              ],
              [
                -60.360762139648294,
                -14.483716860635761
              ],
              [
                -60.36069513286019,
                -14.474764901510435
              ],
              [
                -60.36278014075997,
                -14.469882929213345
              ],
              [
                -60.3684131167764,
                -14.467458468124272
              ],
              [
                -60.37154459816194,
                -14.466136453202969
              ],
              [
                -60.375206575902915,
                -14.464590988324924
              ],
              [
                -60.37827605669625,
                -14.463253982323891
              ],
              [
                -60.37800557082467,
                -14.457433508497878
              ],
              [
                -60.37915957871407,
                -14.454084539144597
              ],
              [
                -60.37921607676002,
                -14.453919547914667
              ],
              [
                -60.38119908489299,
                -14.448333587141672
              ],
              [
                -60.381894581833755,
                -14.4444150940082
              ],
              [
                -60.38379455508358,
                -14.441145595987974
              ],
              [
                -60.390192547232694,
                -14.433681157286863
              ],
              [
                -60.39058403761909,
                -14.433801632677067
              ],
              [
                -60.395943002845854,
                -14.435450166022518
              ],
              [
                -60.3997310007922,
                -14.430907155135658
              ],
              [
                -60.39363853651765,
                -14.429438171476553
              ],
              [
                -60.397664518100065,
                -14.425300694527502
              ],
              [
                -60.396546026713835,
                -14.423165199425144
              ],
              [
                -60.3951035526904,
                -14.420411198032813
              ],
              [
                -60.39708002237938,
                -14.421071202795934
              ],
              [
                -60.399692012789494,
                -14.421943189127814
              ],
              [
                -60.40004501742903,
                -14.418598717994364
              ],
              [
                -60.40144849884566,
                -14.415668747549043
              ],
              [
                -60.40570049362734,
                -14.412158264637606
              ],
              [
                -60.403339526964395,
                -14.410314762664356
              ],
              [
                -60.398793048754754,
                -14.404395762849619
              ],
              [
                -60.40234355551759,
                -14.401400816377517
              ],
              [
                -60.40229756706756,
                -14.397388297990714
              ],
              [
                -60.40072060149648,
                -14.38915235079059
              ],
              [
                -60.40412758014855,
                -14.386573358371015
              ],
              [
                -60.400885090048725,
                -14.381559860901174
              ],
              [
                -60.40060108375471,
                -14.381120896998429
              ],
              [
                -60.39782509963311,
                -14.385307836500827
              ],
              [
                -60.39587309284011,
                -14.387658354006712
              ],
              [
                -60.39426962648177,
                -14.38329137060357
              ],
              [
                -60.39271067306319,
                -14.379267351958157
              ],
              [
                -60.39671411543503,
                -14.376998881629834
              ],
              [
                -60.39574763561043,
                -14.369449922107096
              ],
              [
                -60.39482869001003,
                -14.363845434071655
              ],
              [
                -60.39883412662997,
                -14.36536695588043
              ],
              [
                -60.4014726223593,
                -14.366471426331323
              ],
              [
                -60.40519958629494,
                -14.36467394854482
              ],
              [
                -60.4065515758195,
                -14.36615695569385
              ],
              [
                -60.40812158349143,
                -14.367879935381021
              ],
              [
                -60.410501056111144,
                -14.363631464247419
              ],
              [
                -60.40973606557414,
                -14.361937497211315
              ],
              [
                -60.40711713555322,
                -14.35613399322776
              ],
              [
                -60.41203759189284,
                -14.358887499209054
              ],
              [
                -60.412245580385246,
                -14.359003996207996
              ],
              [
                -60.41281657463179,
                -14.35785499996271
              ],
              [
                -60.414610049268795,
                -14.354244017046021
              ],
              [
                -60.413059066759416,
                -14.350228024395255
              ],
              [
                -60.41736657991514,
                -14.34770355672243
              ],
              [
                -60.418825073188806,
                -14.34989152833748
              ],
              [
                -60.421005050670686,
                -14.35316204029094
              ],
              [
                -60.42661100934735,
                -14.346189080894325
              ],
              [
                -60.42491505384551,
                -14.342107083183397
              ],
              [
                -60.42480703509072,
                -14.341845591972147
              ],
              [
                -60.42557802483821,
                -14.341264094938825
              ],
              [
                -60.43346697455284,
                -14.335317621508555
              ],
              [
                -60.43619146873106,
                -14.329059645998427
              ],
              [
                -60.437396501051545,
                -14.326236166702003
              ],
              [
                -60.43823447057858,
                -14.32427269623157
              ],
              [
                -60.43988248565946,
                -14.319970699700177
              ],
              [
                -60.44365346110254,
                -14.318279727570408
              ],
              [
                -60.44470595492379,
                -14.317807705720773
              ],
              [
                -60.44781842827766,
                -14.316121242132963
              ],
              [
                -60.45244241386495,
                -14.313466243642956
              ],
              [
                -60.45101195200975,
                -14.309708259250076
              ],
              [
                -60.449668470225575,
                -14.306999267937327
              ],
              [
                -60.45643493557263,
                -14.303687801695009
              ],
              [
                -60.45625942522974,
                -14.297757820562286
              ],
              [
                -60.45630042912698,
                -14.291594876820412
              ],
              [
                -60.45781643499139,
                -14.288594377895423
              ],
              [
                -60.459330954779794,
                -14.285194374534015
              ],
              [
                -60.46049996017856,
                -14.27849743535262
              ],
              [
                -60.46119896218014,
                -14.274500434557227
              ],
              [
                -60.464318436365936,
                -14.264572481308267
              ],
              [
                -60.460247495559784,
                -14.25715151657301
              ],
              [
                -60.4590649941722,
                -14.256461012710325
              ],
              [
                -60.44908106588743,
                -14.250632528877135
              ],
              [
                -60.4489125899757,
                -14.24053205819507
              ],
              [
                -60.45445459367294,
                -14.238054076977848
              ],
              [
                -60.45796804882311,
                -14.236283099571956
              ],
              [
                -60.4581830617923,
                -14.236174587465579
              ],
              [
                -60.46156603229314,
                -14.234590591040357
              ],
              [
                -60.46419401438706,
                -14.233399603429715
              ],
              [
                -60.46226202741196,
                -14.22659465296792
              ],
              [
                -60.4615230427481,
                -14.223991165593729
              ],
              [
                -60.466057026390665,
                -14.218030675861634
              ],
              [
                -60.46955254908956,
                -14.2134361945046
              ],
              [
                -60.46901454002506,
                -14.211358697501998
              ],
              [
                -60.46700556366119,
                -14.203596241658017
              ],
              [
                -60.46923608451872,
                -14.198277767405468
              ],
              [
                -60.47105155426366,
                -14.192333810713276
              ],
              [
                -60.47592303227333,
                -14.192350299205444
              ],
              [
                -60.47640553044227,
                -14.192334326313897
              ],
              [
                -60.48554946880668,
                -14.19202731733802
              ],
              [
                -60.48943996003641,
                -14.1883628331716
              ],
              [
                -60.488327468020394,
                -14.182046859587652
              ],
              [
                -60.48763700552737,
                -14.178352398616946
              ],
              [
                -60.48786900768492,
                -14.174599414875905
              ],
              [
                -60.487860521335634,
                -14.170816938731896
              ],
              [
                -60.481974083340454,
                -14.162881448211305
              ],
              [
                -60.47665260188673,
                -14.159831423705311
              ],
              [
                -60.4744431088921,
                -14.158564937912866
              ],
              [
                -60.47411912026131,
                -14.15352447305472
              ],
              [
                -60.473572651797284,
                -14.149872464252457
              ],
              [
                -60.47674314050693,
                -14.147868006995106
              ],
              [
                -60.477444616345416,
                -14.147424478256907
              ],
              [
                -60.481252114181686,
                -14.133994572363413
              ],
              [
                -60.47823014906527,
                -14.131819073409545
              ],
              [
                -60.47524115702061,
                -14.129525566994527
              ],
              [
                -60.47280319174254,
                -14.127655572102435
              ],
              [
                -60.474766178251805,
                -14.125813588595339
              ],
              [
                -60.47643517788622,
                -14.124246099371943
              ],
              [
                -60.47916417988751,
                -14.121060605809733
              ],
              [
                -60.48286365713645,
                -14.11610262732295
              ],
              [
                -60.47706671846152,
                -14.112174640307723
              ],
              [
                -60.47280821066166,
                -14.10956664741212
              ],
              [
                -60.47611372645051,
                -14.104956677709632
              ],
              [
                -60.47860573170413,
                -14.10183567938279
              ],
              [
                -60.47825271261429,
                -14.095724713719756
              ],
              [
                -60.47501475811076,
                -14.09251070042653
              ],
              [
                -60.468219812073706,
                -14.090919703080418
              ],
              [
                -60.465218303118704,
                -14.093925205762009
              ],
              [
                -60.46102285165119,
                -14.092773662277402
              ],
              [
                -60.45914536708673,
                -14.091765178217761
              ],
              [
                -60.45249491567769,
                -14.088194174007597
              ],
              [
                -60.45007543538488,
                -14.08672120662
              ],
              [
                -60.44695842245249,
                -14.085153672107353
              ],
              [
                -60.44359498045698,
                -14.081161703644742
              ],
              [
                -60.440429485650924,
                -14.077893190754995
              ],
              [
                -60.43688303412287,
                -14.073731703580998
              ],
              [
                -60.432813043781366,
                -14.069093243175336
              ],
              [
                -60.42996960348314,
                -14.066079217987546
              ],
              [
                -60.42718461553042,
                -14.062313261903375
              ],
              [
                -60.42721310689137,
                -14.058081756703645
              ],
              [
                -60.427183605325126,
                -14.056634751364989
              ],
              [
                -60.42712212438506,
                -14.053661285997526
              ],
              [
                -60.425478644919075,
                -14.049324807632203
              ],
              [
                -60.418737700237926,
                -14.042111812732271
              ],
              [
                -60.416710703970956,
                -14.040921811099997
              ],
              [
                -60.41563273480426,
                -14.040289797292626
              ],
              [
                -60.40551381711914,
                -14.030004338363101
              ],
              [
                -60.406305294339234,
                -14.026919349283622
              ],
              [
                -60.406574787466596,
                -14.025703361679755
              ],
              [
                -60.407109318694566,
                -14.023294847369772
              ],
              [
                -60.40277536553932,
                -14.015694384227734
              ],
              [
                -60.400986891571016,
                -14.007941394149881
              ],
              [
                -60.40160237413745,
                -14.002932952332243
              ],
              [
                -60.4025793843771,
                -13.999339951673727
              ],
              [
                -60.39848690906742,
                -13.996509944159857
              ],
              [
                -60.39489292356948,
                -13.99645797068954
              ],
              [
                -60.3944664508124,
                -13.993910945887803
              ],
              [
                -60.39425344992977,
                -13.992638458483786
              ],
              [
                -60.38785499085908,
                -13.99225845933025
              ],
              [
                -60.384597015411565,
                -13.99218645566022
              ],
              [
                -60.38152255138726,
                -13.991884933293747
              ],
              [
                -60.38138505821159,
                -13.986968457526922
              ],
              [
                -60.3863140277294,
                -13.980776483638769
              ],
              [
                -60.388822540633136,
                -13.978169003346874
              ],
              [
                -60.398737967187024,
                -13.977027041032136
              ],
              [
                -60.404865933240146,
                -13.979034518135185
              ],
              [
                -60.406766418542276,
                -13.979657021719172
              ],
              [
                -60.41308186813623,
                -13.980033569700652
              ],
              [
                -60.41805285389037,
                -13.974081589924655
              ],
              [
                -60.42347433142294,
                -13.965241120567894
              ],
              [
                -60.425409326920715,
                -13.96121363767608
              ],
              [
                -60.41942884727367,
                -13.96309465046316
              ],
              [
                -60.418809382417216,
                -13.959756150395497
              ],
              [
                -60.41826537220542,
                -13.956822644882646
              ],
              [
                -60.41828189896998,
                -13.953302170896011
              ],
              [
                -60.41027096040908,
                -13.949807658714194
              ],
              [
                -60.414590903800196,
                -13.948427673393972
              ],
              [
                -60.41832541938028,
                -13.947179706301997
              ],
              [
                -60.419512912816444,
                -13.937993753316427
              ],
              [
                -60.423776877022576,
                -13.939089739036305
              ],
              [
                -60.42648985479074,
                -13.942722236384657
              ],
              [
                -60.432602808908044,
                -13.93848625521526
              ],
              [
                -60.438411311252686,
                -13.934961284570777
              ],
              [
                -60.43852830071698,
                -13.934889804103527
              ],
              [
                -60.44447326488944,
                -13.931528813094582
              ],
              [
                -60.44362776435274,
                -13.938952805828016
              ],
              [
                -60.449919711985146,
                -13.9371543103573
              ],
              [
                -60.45031673706522,
                -13.927990321570682
              ],
              [
                -60.4509582593264,
                -13.916739901597328
              ],
              [
                -60.45123627277564,
                -13.914000908063876
              ],
              [
                -60.451545286731935,
                -13.910950931831911
              ],
              [
                -60.4515997794779,
                -13.904180923835495
              ],
              [
                -60.454714273312526,
                -13.896839986607707
              ],
              [
                -60.45567178119069,
                -13.893438484152764
              ],
              [
                -60.45340880856049,
                -13.890321004737597
              ],
              [
                -60.45165233091987,
                -13.88720402839814
              ],
              [
                -60.451680351321826,
                -13.883798013994458
              ],
              [
                -60.45444282626751,
                -13.88048904400345
              ],
              [
                -60.462334242309375,
                -13.881885077187238
              ],
              [
                -60.462769280556564,
                -13.881961574805775
              ],
              [
                -60.46634174211605,
                -13.884766062240375
              ],
              [
                -60.466634248664185,
                -13.88778752185929
              ],
              [
                -60.47345416966016,
                -13.884872548903179
              ],
              [
                -60.472888687471894,
                -13.87926708196673
              ],
              [
                -60.46519326161789,
                -13.874104114166
              ],
              [
                -60.46501378994978,
                -13.873984102262586
              ],
              [
                -60.46346930130366,
                -13.862636136095484
              ],
              [
                -60.45537387885451,
                -13.854749635320292
              ],
              [
                -60.45874633518002,
                -13.852336667054573
              ],
              [
                -60.46231482732336,
                -13.853993164017549
              ],
              [
                -60.46658479840938,
                -13.855602167656599
              ],
              [
                -60.472369260366015,
                -13.854569698004612
              ],
              [
                -60.477749724564724,
                -13.85360969820863
              ],
              [
                -60.48610418274125,
                -13.857331698104492
              ],
              [
                -60.49124316120627,
                -13.856001203270626
              ],
              [
                -60.48893217209761,
                -13.852311238037299
              ],
              [
                -60.48592468060548,
                -13.849755255828217
              ],
              [
                -60.482801227257234,
                -13.846542231988677
              ],
              [
                -60.479813750925146,
                -13.84340877012493
              ],
              [
                -60.47659825431527,
                -13.840108259447632
              ],
              [
                -60.47577527992604,
                -13.83932827480915
              ],
              [
                -60.468598327570895,
                -13.832528292686437
              ],
              [
                -60.470913327351646,
                -13.829130284097417
              ],
              [
                -60.47439982283783,
                -13.829954807183562
              ],
              [
                -60.47751479251243,
                -13.830787318454066
              ],
              [
                -60.477518295487215,
                -13.830789306453564
              ],
              [
                -60.4815832629448,
                -13.83188480568706
              ],
              [
                -60.486217239579844,
                -13.827761346876969
              ],
              [
                -60.48759675037582,
                -13.823250865728362
              ],
              [
                -60.48853223331703,
                -13.819864355172568
              ],
              [
                -60.48435578269641,
                -13.818685872040936
              ],
              [
                -60.47953176744763,
                -13.820886845098535
              ],
              [
                -60.4787592802501,
                -13.821239842062877
              ],
              [
                -60.47404881833216,
                -13.822988335158426
              ],
              [
                -60.467582353513215,
                -13.820814306155302
              ],
              [
                -60.46576887343168,
                -13.815315343216936
              ],
              [
                -60.467479867920076,
                -13.812309364795375
              ],
              [
                -60.476997806532864,
                -13.808647894453797
              ],
              [
                -60.47736532463021,
                -13.80354189055443
              ],
              [
                -60.472552848828954,
                -13.800531892390335
              ],
              [
                -60.46506292672999,
                -13.800325423420917
              ],
              [
                -60.466579400947595,
                -13.795242938863273
              ],
              [
                -60.482024825117676,
                -13.795329948071963
              ],
              [
                -60.48952779750996,
                -13.795377988697263
              ],
              [
                -60.49280325179412,
                -13.793244487093475
              ],
              [
                -60.49959223013488,
                -13.788442538512687
              ],
              [
                -60.50225122099278,
                -13.79314549740374
              ],
              [
                -60.50332217646199,
                -13.795039983124664
              ],
              [
                -60.51113217191626,
                -13.790622535161553
              ],
              [
                -60.527625047703076,
                -13.791293054265546
              ],
              [
                -60.5218091128472,
                -13.783731597070442
              ],
              [
                -60.519268130233705,
                -13.780861603131317
              ],
              [
                -60.518783628313145,
                -13.780313608290415
              ],
              [
                -60.516715664032304,
                -13.777381595598312
              ],
              [
                -60.51741264339495,
                -13.772167633810636
              ],
              [
                -60.52227462684883,
                -13.768418143964281
              ],
              [
                -60.52614760370779,
                -13.76941464015253
              ],
              [
                -60.53122105600873,
                -13.771068657254874
              ],
              [
                -60.54738046800722,
                -13.769845208144504
              ],
              [
                -60.547233991688145,
                -13.773105196878966
              ],
              [
                -60.547192992512066,
                -13.773295160037588
              ],
              [
                -60.546396968114166,
                -13.776973651741766
              ],
              [
                -60.54583445368519,
                -13.7818516431726
              ],
              [
                -60.54944194777768,
                -13.781193145391622
              ],
              [
                -60.556974925952964,
                -13.77041422308782
              ],
              [
                -60.560213397566535,
                -13.769605699120381
              ],
              [
                -60.56864832709198,
                -13.7680432340907
              ],
              [
                -60.56731137491067,
                -13.762640241737778
              ],
              [
                -60.566713871699626,
                -13.760709773149559
              ],
              [
                -60.56569339899843,
                -13.757413773097959
              ],
              [
                -60.57165836806491,
                -13.756341294911078
              ],
              [
                -60.57816482714967,
                -13.755170806252043
              ],
              [
                -60.576576840532866,
                -13.751996798519182
              ],
              [
                -60.57405084258823,
                -13.746948849262518
              ],
              [
                -60.577629851612585,
                -13.746859362144574
              ],
              [
                -60.58174581565236,
                -13.746683849497831
              ],
              [
                -60.58380231784539,
                -13.74652185746635
              ],
              [
                -60.58629329254543,
                -13.746325381485502
              ],
              [
                -60.59170725001722,
                -13.743377378579487
              ],
              [
                -60.59960321133468,
                -13.748373404349726
              ],
              [
                -60.603091692345906,
                -13.747176886437968
              ],
              [
                -60.605965677395105,
                -13.745796904960999
              ],
              [
                -60.60519118509988,
                -13.738923447616708
              ],
              [
                -60.607251181020814,
                -13.736151957950348
              ],
              [
                -60.61017664875076,
                -13.739778942703097
              ],
              [
                -60.61149813887878,
                -13.734352456021297
              ],
              [
                -60.611239678081084,
                -13.733590953500993
              ],
              [
                -60.60961818315054,
                -13.728821983027435
              ],
              [
                -60.61691916602666,
                -13.7228985368835
              ],
              [
                -60.62277260985948,
                -13.719068534381952
              ],
              [
                -60.63069505185493,
                -13.727806511345262
              ],
              [
                -60.64067547415667,
                -13.730423547940292
              ],
              [
                -60.64157099865047,
                -13.731886542021156
              ],
              [
                -60.645408452194616,
                -13.738156529231368
              ],
              [
                -60.65094441419472,
                -13.735573527113123
              ],
              [
                -60.65177790730104,
                -13.7314710531271
              ],
              [
                -60.65212441500626,
                -13.729766052614336
              ],
              [
                -60.653055918872134,
                -13.731418558022161
              ],
              [
                -60.65421940546048,
                -13.733483560283737
              ],
              [
                -60.655228883185316,
                -13.736808036231619
              ],
              [
                -60.65936084608464,
                -13.734405046444135
              ],
              [
                -60.66516035653465,
                -13.730007097065585
              ],
              [
                -60.67564180957056,
                -13.721878154304372
              ],
              [
                -60.68022926339156,
                -13.718293155146362
              ],
              [
                -60.68333074815299,
                -13.7155386915739
              ],
              [
                -60.68495974877554,
                -13.711338679633696
              ],
              [
                -60.68584223409,
                -13.708826725438422
              ],
              [
                -60.68932875332543,
                -13.69890176608109
              ],
              [
                -60.694818236336914,
                -13.70100674726203
              ],
              [
                -60.69467570354559,
                -13.702205245215218
              ],
              [
                -60.693169214500855,
                -13.71491668170695
              ],
              [
                -60.69623269780961,
                -13.714292720613876
              ],
              [
                -60.7039711363958,
                -13.704186784881793
              ],
              [
                -60.705154155909874,
                -13.700322270667927
              ],
              [
                -60.70687467704915,
                -13.695184301625781
              ],
              [
                -60.70777965664466,
                -13.692482822223587
              ],
              [
                -60.709437149089624,
                -13.686916352747657
              ],
              [
                -60.714886145741204,
                -13.68520185096531
              ],
              [
                -60.7162626160689,
                -13.681810392283333
              ],
              [
                -60.71310316476836,
                -13.68222837500388
              ],
              [
                -60.713388655645076,
                -13.675587387910062
              ],
              [
                -60.709071199810985,
                -13.675712880365626
              ],
              [
                -60.7091037062651,
                -13.67131090587761
              ],
              [
                -60.69913175196674,
                -13.67042938548442
              ],
              [
                -60.69646526518025,
                -13.666093410093195
              ],
              [
                -60.70154327789526,
                -13.6598599574423
              ],
              [
                -60.69718828304451,
                -13.657355453261884
              ],
              [
                -60.69904479493644,
                -13.653774448350674
              ],
              [
                -60.692956821745334,
                -13.652894964105045
              ],
              [
                -60.69255434643458,
                -13.652292955651946
              ],
              [
                -60.690049836390315,
                -13.648546969279732
              ],
              [
                -60.69096037810215,
                -13.645308492036598
              ],
              [
                -60.691241873628535,
                -13.644308470594208
              ],
              [
                -60.6900928519755,
                -13.643130980866284
              ],
              [
                -60.68582490069701,
                -13.638755520318124
              ],
              [
                -60.68632342104837,
                -13.637706995523022
              ],
              [
                -60.688520881177006,
                -13.633090035446394
              ],
              [
                -60.68548444013075,
                -13.624819552954458
              ],
              [
                -60.67371901625617,
                -13.616680079750903
              ],
              [
                -60.67218303140496,
                -13.612051071951132
              ],
              [
                -60.66917804998306,
                -13.613034572774946
              ],
              [
                -60.66902505077641,
                -13.613084572257096
              ],
              [
                -60.66900906851146,
                -13.612790571392871
              ],
              [
                -60.66873804972431,
                -13.607919592198687
              ],
              [
                -60.66686058491891,
                -13.609699578205968
              ],
              [
                -60.665405575495804,
                -13.611078562688336
              ],
              [
                -60.66451158604722,
                -13.607738091111548
              ],
              [
                -60.66363160139752,
                -13.604451111353468
              ],
              [
                -60.658898622172075,
                -13.603139612710326
              ],
              [
                -60.65490815112158,
                -13.602033590798667
              ],
              [
                -60.64496321773751,
                -13.592413098392566
              ],
              [
                -60.64062926722364,
                -13.596131586561004
              ],
              [
                -60.63776779705267,
                -13.591562123806497
              ],
              [
                -60.639643284115365,
                -13.587363119437736
              ],
              [
                -60.64085477386308,
                -13.584650629765061
              ],
              [
                -60.634003836329484,
                -13.578193638249974
              ],
              [
                -60.63165488518505,
                -13.571291679883739
              ],
              [
                -60.61070852146962,
                -13.565941172716357
              ],
              [
                -60.60189705638354,
                -13.571070127943456
              ],
              [
                -60.59976605427965,
                -13.572310603564974
              ],
              [
                -60.591957119963716,
                -13.571137122029793
              ],
              [
                -60.58530214130825,
                -13.564676122017786
              ],
              [
                -60.57710072147055,
                -13.564819588420255
              ],
              [
                -60.5554893529688,
                -13.552954099033098
              ],
              [
                -60.5542913862758,
                -13.54777261728334
              ],
              [
                -60.54782794303426,
                -13.547990124017097
              ],
              [
                -60.54531946576923,
                -13.541108149371484
              ],
              [
                -60.54349146543267,
                -13.536093143668541
              ],
              [
                -60.540651005375864,
                -13.536028664045507
              ],
              [
                -60.53251254593105,
                -13.535842646940907
              ],
              [
                -60.53108105751121,
                -13.525615185964309
              ],
              [
                -60.529823585355885,
                -13.516632211375198
              ],
              [
                -60.50614976301488,
                -13.501553744965264
              ],
              [
                -60.485877400841204,
                -13.493772203387532
              ],
              [
                -60.481202920784206,
                -13.496440700018846
              ],
              [
                -60.48119592281593,
                -13.496445214986256
              ],
              [
                -60.48118891287902,
                -13.49643521501196
              ],
              [
                -60.4784604443302,
                -13.492588717845752
              ],
              [
                -60.46504305820041,
                -13.488705687793757
              ],
              [
                -60.46108456159079,
                -13.490597199023014
              ],
              [
                -60.4558220833702,
                -13.493112648051612
              ],
              [
                -60.431458250799494,
                -13.488027657298844
              ],
              [
                -60.430655768527735,
                -13.484789154690532
              ],
              [
                -60.42985225926011,
                -13.481550683082055
              ],
              [
                -60.410986924817266,
                -13.461249220105808
              ],
              [
                -60.40622397971068,
                -13.461889696727981
              ],
              [
                -60.401930477955126,
                -13.4624677135672
              ],
              [
                -60.39937950831154,
                -13.455844716054687
              ],
              [
                -60.38737607492583,
                -13.454284697687855
              ],
              [
                -60.38227763511199,
                -13.441265236815847
              ],
              [
                -60.37879622193223,
                -13.422045314374602
              ],
              [
                -60.37878821173893,
                -13.420287819465939
              ],
              [
                -60.37876472136715,
                -13.41491486314975
              ],
              [
                -60.376265740841184,
                -13.412747369799247
              ],
              [
                -60.37132277835974,
                -13.40846037302377
              ],
              [
                -60.376070770366276,
                -13.396657435511614
              ],
              [
                -60.37847179184322,
                -13.390689937578607
              ],
              [
                -60.37904231127941,
                -13.37482649797737
              ],
              [
                -60.375564813481695,
                -13.373628036729192
              ],
              [
                -60.37606983987973,
                -13.368277537435496
              ],
              [
                -60.37392132762257,
                -13.367815056923241
              ],
              [
                -60.3707953686201,
                -13.367141521271439
              ],
              [
                -60.37062289243876,
                -13.347967614641174
              ],
              [
                -60.36473995216232,
                -13.34053912878082
              ],
              [
                -60.36554245090058,
                -13.331826155763986
              ],
              [
                -60.367970464917384,
                -13.329265676095735
              ],
              [
                -60.37293142956272,
                -13.324034213418313
              ],
              [
                -60.37095294964183,
                -13.318220733984147
              ],
              [
                -60.36323701012083,
                -13.310361257201947
              ],
              [
                -60.36107453262894,
                -13.308159254749215
              ],
              [
                -60.360877075097726,
                -13.300937793679287
              ],
              [
                -60.360833078775364,
                -13.299324293144378
              ]
            ]
          ],
          [
            [
              [
                -60.36268704253049,
                -13.296128822890333
              ],
              [
                -60.360833078775364,
                -13.299324293144378
              ],
              [
                -60.364429517974976,
                -13.29953379659719
              ],
              [
                -60.36268704253049,
                -13.296128822890333
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 14,
        "nome": "Pará",
        "sigla": "PA",
        "regiao_id": 3,
        "codigo_ibg": 15
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -46.95145363606387,
                -0.734060346746202
              ],
              [
                -46.95624258065019,
                -0.73122888626025
              ],
              [
                -46.958967066005506,
                -0.739753804081497
              ],
              [
                -46.95728606218759,
                -0.747186764623459
              ],
              [
                -46.95824457983363,
                -0.747340260572064
              ],
              [
                -46.96040505225853,
                -0.747685263209656
              ],
              [
                -46.96222753377867,
                -0.753293716663212
              ],
              [
                -46.9697849847739,
                -0.754812718085844
              ],
              [
                -46.96010655744702,
                -0.759350171412239
              ],
              [
                -46.96697500996549,
                -0.76870960647054
              ],
              [
                -46.97956091181504,
                -0.777006547015353
              ],
              [
                -46.97916791831083,
                -0.782270497848725
              ],
              [
                -46.9761484139048,
                -0.787575438118379
              ],
              [
                -46.967889978965225,
                -0.779011021965719
              ],
              [
                -46.965059511388155,
                -0.781556001765229
              ],
              [
                -46.96167253802303,
                -0.784603964768856
              ],
              [
                -46.959438560931574,
                -0.78661347960645
              ],
              [
                -46.95965454281159,
                -0.791347443432596
              ],
              [
                -46.95971707319616,
                -0.792729414164878
              ],
              [
                -46.95893606617467,
                -0.79274740395287
              ],
              [
                -46.955065602803984,
                -0.792834923914256
              ],
              [
                -46.9557551048814,
                -0.78921493689766
              ],
              [
                -46.95129961821636,
                -0.787025471733568
              ],
              [
                -46.949246635683906,
                -0.789615462270098
              ],
              [
                -46.946357669960605,
                -0.798183359272747
              ],
              [
                -46.9531330914849,
                -0.804053833314437
              ],
              [
                -46.95479360362448,
                -0.805492316790226
              ],
              [
                -46.95003111463166,
                -0.810097287072982
              ],
              [
                -46.94786162703435,
                -0.812195281254995
              ],
              [
                -46.94668116962496,
                -0.816625244203759
              ],
              [
                -46.94550115720991,
                -0.821055228153259
              ],
              [
                -46.94199216672604,
                -0.834223612913506
              ],
              [
                -46.93215974680878,
                -0.845998032363783
              ],
              [
                -46.93437873998928,
                -0.853739943069822
              ],
              [
                -46.93863021308103,
                -0.856938432763698
              ],
              [
                -46.94144468729636,
                -0.85905494334421
              ],
              [
                -46.948214142610624,
                -0.857539444337016
              ],
              [
                -46.9511851279285,
                -0.856874448598747
              ],
              [
                -46.955422573058186,
                -0.860790408971979
              ],
              [
                -46.954312599467436,
                -0.861634891578895
              ],
              [
                -46.944092171901296,
                -0.869413335686132
              ],
              [
                -46.93483073810216,
                -0.876209301184424
              ],
              [
                -46.92997876638516,
                -0.875170794362193
              ],
              [
                -46.923465319439536,
                -0.873776298002831
              ],
              [
                -46.92002583731422,
                -0.878158758061156
              ],
              [
                -46.91705985933493,
                -0.875469781712161
              ],
              [
                -46.92069385089089,
                -0.871788825464486
              ],
              [
                -46.919851857217665,
                -0.863701378469542
              ],
              [
                -46.91939837415966,
                -0.859352441740261
              ],
              [
                -46.91679638511434,
                -0.859193907636053
              ],
              [
                -46.911859432978204,
                -0.858892911337555
              ],
              [
                -46.91072192633307,
                -0.862267908127999
              ],
              [
                -46.90580845946911,
                -0.857785915910333
              ],
              [
                -46.91113590285579,
                -0.855778462409539
              ],
              [
                -46.912582922288934,
                -0.850872978030884
              ],
              [
                -46.9092174218197,
                -0.843872015718491
              ],
              [
                -46.9130118964155,
                -0.827811151541483
              ],
              [
                -46.913218925919296,
                -0.826936175069743
              ],
              [
                -46.912361421955374,
                -0.82241517960137
              ],
              [
                -46.91150440998511,
                -0.817894721132348
              ],
              [
                -46.904291468720864,
                -0.813390264894574
              ],
              [
                -46.89596602905783,
                -0.808191310721169
              ],
              [
                -46.88419061430995,
                -0.808359277269559
              ],
              [
                -46.88026417900927,
                -0.812361254084896
              ],
              [
                -46.879400178253775,
                -0.806213322752597
              ],
              [
                -46.8823721598949,
                -0.801948359798057
              ],
              [
                -46.88582360588134,
                -0.797459868554375
              ],
              [
                -46.88508110994676,
                -0.793697411484876
              ],
              [
                -46.87956968488595,
                -0.796885391201114
              ],
              [
                -46.88350914432926,
                -0.790345411279384
              ],
              [
                -46.88589012946367,
                -0.786393450935491
              ],
              [
                -46.88310563636713,
                -0.780700515042435
              ],
              [
                -46.887056112008906,
                -0.781440512922718
              ],
              [
                -46.883889654460155,
                -0.769272598209142
              ],
              [
                -46.8723527220229,
                -0.757653687632051
              ],
              [
                -46.865367276053014,
                -0.763325613710025
              ],
              [
                -46.86446429063382,
                -0.766936086727566
              ],
              [
                -46.863580806071155,
                -0.770467080339689
              ],
              [
                -46.86151532796017,
                -0.769964082885721
              ],
              [
                -46.85763982277431,
                -0.769021098534118
              ],
              [
                -46.85789385505668,
                -0.766608617531321
              ],
              [
                -46.85862732909257,
                -0.759650162444486
              ],
              [
                -46.859253339873284,
                -0.75347521051336
              ],
              [
                -46.84663043235681,
                -0.746407252992291
              ],
              [
                -46.85165089813086,
                -0.743329274405643
              ],
              [
                -46.86157882584051,
                -0.744876772804878
              ],
              [
                -46.86367179818673,
                -0.740578786029596
              ],
              [
                -46.85736032501993,
                -0.735930823081685
              ],
              [
                -46.845166431599054,
                -0.73544733855385
              ],
              [
                -46.8336385101533,
                -0.745094251557922
              ],
              [
                -46.82919257592144,
                -0.748815744393655
              ],
              [
                -46.82944104529762,
                -0.756468696363778
              ],
              [
                -46.83212952844133,
                -0.758874641685904
              ],
              [
                -46.834814012617855,
                -0.761259148169698
              ],
              [
                -46.836688512563306,
                -0.768317610744144
              ],
              [
                -46.83255904335052,
                -0.767242592356146
              ],
              [
                -46.83191303814445,
                -0.779320510282978
              ],
              [
                -46.83175854304926,
                -0.78220748275495
              ],
              [
                -46.83135455517284,
                -0.792167908499555
              ],
              [
                -46.83604349425983,
                -0.791509423873724
              ],
              [
                -46.84015997673603,
                -0.790906927772983
              ],
              [
                -46.84722040167246,
                -0.789873431175589
              ],
              [
                -46.85067438322669,
                -0.78936744528851
              ],
              [
                -46.854773842840586,
                -0.788767955161758
              ],
              [
                -46.856581364531834,
                -0.793281402728047
              ],
              [
                -46.84664790458848,
                -0.794694904205573
              ],
              [
                -46.84224397331161,
                -0.795321375097598
              ],
              [
                -46.83938247922094,
                -0.795767365488313
              ],
              [
                -46.83760999679265,
                -0.796043400254131
              ],
              [
                -46.833229009689695,
                -0.803244330171436
              ],
              [
                -46.83527652019507,
                -0.811047786256001
              ],
              [
                -46.836252519364365,
                -0.814428761175653
              ],
              [
                -46.836416990044555,
                -0.814999246943958
              ],
              [
                -46.846169916913425,
                -0.818013238504584
              ],
              [
                -46.84663443066921,
                -0.824667670007928
              ],
              [
                -46.85716783987962,
                -0.834257098728535
              ],
              [
                -46.85349584387716,
                -0.835869581340361
              ],
              [
                -46.84477393155082,
                -0.828467632523602
              ],
              [
                -46.840139462510756,
                -0.824535176318774
              ],
              [
                -46.83409750560375,
                -0.827105142553334
              ],
              [
                -46.833775534060095,
                -0.827242158499467
              ],
              [
                -46.833943998688625,
                -0.828007647817006
              ],
              [
                -46.835333020376865,
                -0.834325591920576
              ],
              [
                -46.85162838997841,
                -0.847537480305274
              ],
              [
                -46.851182893756565,
                -0.85357093535931
              ],
              [
                -46.850245383837155,
                -0.863956858979053
              ],
              [
                -46.84475545029112,
                -0.860802396853222
              ],
              [
                -46.84536044368113,
                -0.851128471909373
              ],
              [
                -46.84553193373514,
                -0.847634508931278
              ],
              [
                -46.83871948728803,
                -0.844902513538773
              ],
              [
                -46.838310464060214,
                -0.848374000654785
              ],
              [
                -46.834557528029336,
                -0.846725014528229
              ],
              [
                -46.8255255629814,
                -0.840581067308836
              ],
              [
                -46.825524577989455,
                -0.840576530342518
              ],
              [
                -46.82498459844591,
                -0.837584076567505
              ],
              [
                -46.823243103798454,
                -0.828055654342094
              ],
              [
                -46.816307673985804,
                -0.8284461487094
              ],
              [
                -46.81465317866309,
                -0.838292566199828
              ],
              [
                -46.81412918235927,
                -0.841408522938645
              ],
              [
                -46.81340617945716,
                -0.84570852383942
              ],
              [
                -46.81224769362108,
                -0.852598456409145
              ],
              [
                -46.81519266344044,
                -0.858011919419351
              ],
              [
                -46.81124770570196,
                -0.858184923704471
              ],
              [
                -46.810495205990016,
                -0.862742396689948
              ],
              [
                -46.81110570877229,
                -0.867677845011908
              ],
              [
                -46.821035106852236,
                -0.874218288416892
              ],
              [
                -46.82604957284607,
                -0.888104187639711
              ],
              [
                -46.8223395938094,
                -0.892688663112053
              ],
              [
                -46.826353571717455,
                -0.89527114535048
              ],
              [
                -46.82108010987018,
                -0.898118604569272
              ],
              [
                -46.82027061758541,
                -0.902556581459172
              ],
              [
                -46.81829064899508,
                -0.900654117388304
              ],
              [
                -46.8173591472448,
                -0.899759103941768
              ],
              [
                -46.81695013010864,
                -0.89326667120434
              ],
              [
                -46.81722764329749,
                -0.890394694606831
              ],
              [
                -46.81729315187013,
                -0.889714165678304
              ],
              [
                -46.81858363627278,
                -0.886935693499108
              ],
              [
                -46.811400697137046,
                -0.880280733228342
              ],
              [
                -46.807519737321016,
                -0.876684786565833
              ],
              [
                -46.8023747470595,
                -0.865225879314214
              ],
              [
                -46.8019657793412,
                -0.863905385102273
              ],
              [
                -46.80134826429665,
                -0.861905888924239
              ],
              [
                -46.80104026561908,
                -0.862298380968071
              ],
              [
                -46.78664038318943,
                -0.880678255521036
              ],
              [
                -46.79702927563746,
                -0.887789704731613
              ],
              [
                -46.79586982013661,
                -0.891429175508798
              ],
              [
                -46.79380632429454,
                -0.897588622426326
              ],
              [
                -46.7884913388395,
                -0.899967605115616
              ],
              [
                -46.789315348912325,
                -0.896386132869948
              ],
              [
                -46.79003736671765,
                -0.893247160319234
              ],
              [
                -46.791005357778026,
                -0.888799180542025
              ],
              [
                -46.78199589831407,
                -0.885608721289935
              ],
              [
                -46.78185893171186,
                -0.882462714701759
              ],
              [
                -46.784996896772725,
                -0.871846333110572
              ],
              [
                -46.78854787169488,
                -0.870093309555871
              ],
              [
                -46.78784185481801,
                -0.863585861944284
              ],
              [
                -46.79110984404225,
                -0.860622878367872
              ],
              [
                -46.79170034386193,
                -0.857312919084942
              ],
              [
                -46.792630822272365,
                -0.852094978051242
              ],
              [
                -46.79307133415158,
                -0.84962496349732
              ],
              [
                -46.79394430496492,
                -0.84472902606135
              ],
              [
                -46.78599790383293,
                -0.836587092879936
              ],
              [
                -46.783162917904974,
                -0.833683107221121
              ],
              [
                -46.772745987502496,
                -0.837573548131107
              ],
              [
                -46.76921003097952,
                -0.844097024138749
              ],
              [
                -46.76773554201927,
                -0.846818004703469
              ],
              [
                -46.76534903290009,
                -0.851089971611846
              ],
              [
                -46.76354654805566,
                -0.848161483243785
              ],
              [
                -46.76469155169925,
                -0.843987033478102
              ],
              [
                -46.76505104807519,
                -0.842676511283932
              ],
              [
                -46.76685453790794,
                -0.836102072477418
              ],
              [
                -46.76844600428468,
                -0.830300127892192
              ],
              [
                -46.76590055618005,
                -0.817191202356441
              ],
              [
                -46.75724412491629,
                -0.824661185764271
              ],
              [
                -46.75286463341077,
                -0.826146668232607
              ],
              [
                -46.746889176114685,
                -0.828173634495317
              ],
              [
                -46.7443901931295,
                -0.839627535824622
              ],
              [
                -46.74348322061336,
                -0.844267017136377
              ],
              [
                -46.74086673430357,
                -0.857654894046043
              ],
              [
                -46.74077474396039,
                -0.858124416535936
              ],
              [
                -46.73991423310243,
                -0.862526862624919
              ],
              [
                -46.741689217129434,
                -0.865625371721812
              ],
              [
                -46.737701240360344,
                -0.878687274930501
              ],
              [
                -46.73611026401683,
                -0.883895730937545
              ],
              [
                -46.733027296595445,
                -0.893993155346292
              ],
              [
                -46.73275129186281,
                -0.90164557129497
              ],
              [
                -46.73587325853971,
                -0.904576060816298
              ],
              [
                -46.74149274176314,
                -0.909813518441715
              ],
              [
                -46.74518368301025,
                -0.913254492235435
              ],
              [
                -46.7458841708505,
                -0.920073429531665
              ],
              [
                -46.742252207701775,
                -0.920582423320116
              ],
              [
                -46.73984574691437,
                -0.914273498031463
              ],
              [
                -46.727130824712916,
                -0.904760047449864
              ],
              [
                -46.72146936524069,
                -0.895740130025419
              ],
              [
                -46.721276382999,
                -0.884212221927963
              ],
              [
                -46.72846734198757,
                -0.863367858111815
              ],
              [
                -46.72475036954179,
                -0.844674512106207
              ],
              [
                -46.72332135927732,
                -0.837488041556164
              ],
              [
                -46.7229228505489,
                -0.835484072462311
              ],
              [
                -46.72107040069419,
                -0.826716129677233
              ],
              [
                -46.71382544061558,
                -0.824518177323707
              ],
              [
                -46.703246541281224,
                -0.82130817318324
              ],
              [
                -46.69997956103782,
                -0.824876655211805
              ],
              [
                -46.6975485774593,
                -0.827532124140186
              ],
              [
                -46.68904612868316,
                -0.848335494991737
              ],
              [
                -46.685147174437766,
                -0.85055147503875
              ],
              [
                -46.680019684569785,
                -0.853466431736017
              ],
              [
                -46.677059739160555,
                -0.855148930861704
              ],
              [
                -46.68083170161201,
                -0.860154901911621
              ],
              [
                -46.676603738928456,
                -0.862090870007346
              ],
              [
                -46.6736292359006,
                -0.861253388934744
              ],
              [
                -46.67325024246516,
                -0.855162436351269
              ],
              [
                -46.67294325925423,
                -0.851126968437872
              ],
              [
                -46.66785478228239,
                -0.852260932428784
              ],
              [
                -46.665749817026885,
                -0.856512934464181
              ],
              [
                -46.66405083585676,
                -0.849356967699588
              ],
              [
                -46.66768980427582,
                -0.845388998709161
              ],
              [
                -46.678410693992916,
                -0.843535994679629
              ],
              [
                -46.68731166618792,
                -0.817744208144048
              ],
              [
                -46.68749713181605,
                -0.817206192179891
              ],
              [
                -46.685260678097265,
                -0.806495783921641
              ],
              [
                -46.677996217073044,
                -0.805519790473823
              ],
              [
                -46.67514973166313,
                -0.808582261313354
              ],
              [
                -46.669963292001015,
                -0.814161711114771
              ],
              [
                -46.6617683322826,
                -0.811884237287626
              ],
              [
                -46.666750822781665,
                -0.803554312016617
              ],
              [
                -46.66739228892777,
                -0.802737819180573
              ],
              [
                -46.671829779348926,
                -0.797089875817072
              ],
              [
                -46.66182282508754,
                -0.789503919494224
              ],
              [
                -46.663742818599516,
                -0.786589941458208
              ],
              [
                -46.65135694228133,
                -0.782758469869803
              ],
              [
                -46.64656496998383,
                -0.78457493282083
              ],
              [
                -46.637690030965,
                -0.787938432801896
              ],
              [
                -46.64309949834558,
                -0.797922352716642
              ],
              [
                -46.6460419849769,
                -0.795104865067757
              ],
              [
                -46.64805846120757,
                -0.797634367366018
              ],
              [
                -46.64408648616083,
                -0.803841323582532
              ],
              [
                -46.641886504749785,
                -0.8072812926538
              ],
              [
                -46.63971502012347,
                -0.810673257086159
              ],
              [
                -46.63955150435631,
                -0.810929233156739
              ],
              [
                -46.63889051642933,
                -0.811076235990865
              ],
              [
                -46.62213416004981,
                -0.81480774039199
              ],
              [
                -46.61802219205455,
                -0.821274668634099
              ],
              [
                -46.615748178199645,
                -0.824850649671799
              ],
              [
                -46.61513621181351,
                -0.825813151414638
              ],
              [
                -46.616286696850985,
                -0.826823122985655
              ],
              [
                -46.61845218998159,
                -0.828722620014902
              ],
              [
                -46.61706418757176,
                -0.829670112789429
              ],
              [
                -46.61111872393588,
                -0.833729572830045
              ],
              [
                -46.60852825104962,
                -0.84177252244968
              ],
              [
                -46.610131729908424,
                -0.849332947124262
              ],
              [
                -46.6136187298285,
                -0.851488434391545
              ],
              [
                -46.617694183182984,
                -0.853977935229082
              ],
              [
                -46.622402664430446,
                -0.849228463213943
              ],
              [
                -46.62580713557953,
                -0.845794987226142
              ],
              [
                -46.62902710164715,
                -0.847150992438975
              ],
              [
                -46.62469414289919,
                -0.857621894761532
              ],
              [
                -46.623423631354015,
                -0.860691873694053
              ],
              [
                -46.62576060525732,
                -0.861664377683383
              ],
              [
                -46.62738409268588,
                -0.862340359810544
              ],
              [
                -46.62682112963799,
                -0.865904858128769
              ],
              [
                -46.6258956151311,
                -0.871773286203234
              ],
              [
                -46.62872059219555,
                -0.873494792657923
              ],
              [
                -46.63282707031046,
                -0.875996770427855
              ],
              [
                -46.638185506635665,
                -0.870701300552959
              ],
              [
                -46.64057700649588,
                -0.868212343397177
              ],
              [
                -46.6447074886732,
                -0.868495333735161
              ],
              [
                -46.63724103396827,
                -0.879337741971679
              ],
              [
                -46.63396303668637,
                -0.884050719424808
              ],
              [
                -46.63204956811472,
                -0.886800166686838
              ],
              [
                -46.631528067046816,
                -0.887550678026
              ],
              [
                -46.6325025814281,
                -0.888608664236862
              ],
              [
                -46.634174067360476,
                -0.890423137879181
              ],
              [
                -46.63840351872395,
                -0.891138141015669
              ],
              [
                -46.64639894204159,
                -0.892414124388719
              ],
              [
                -46.651766930602975,
                -0.893545650547346
              ],
              [
                -46.64826992069612,
                -0.90091259818269
              ],
              [
                -46.64654943853523,
                -0.904506057175001
              ],
              [
                -46.642715497083515,
                -0.904156570346974
              ],
              [
                -46.64012300179267,
                -0.9063450297219
              ],
              [
                -46.63754052842592,
                -0.908524545163826
              ],
              [
                -46.63239756552626,
                -0.912864492189949
              ],
              [
                -46.63427554231117,
                -0.928258370549264
              ],
              [
                -46.6473689588532,
                -0.942676782546127
              ],
              [
                -46.650008421493105,
                -0.943068262939685
              ],
              [
                -46.65567388279408,
                -0.943908758344618
              ],
              [
                -46.66235633075797,
                -0.940609282735884
              ],
              [
                -46.66616179897163,
                -0.944928762985495
              ],
              [
                -46.66186234279231,
                -0.947154243884077
              ],
              [
                -46.66424730416474,
                -0.949501227672874
              ],
              [
                -46.66767030165933,
                -0.950926193460272
              ],
              [
                -46.67266224400725,
                -0.953003705573712
              ],
              [
                -46.67789972745666,
                -0.955183691956224
              ],
              [
                -46.6832866544042,
                -0.951923219898209
              ],
              [
                -46.68735661992621,
                -0.961791649861016
              ],
              [
                -46.67974667442728,
                -0.970420578651431
              ],
              [
                -46.67491673392168,
                -0.975897007261337
              ],
              [
                -46.67081575183033,
                -0.972937047811611
              ],
              [
                -46.67340375431265,
                -0.969676585420754
              ],
              [
                -46.667158292532065,
                -0.968276092095289
              ],
              [
                -46.656722855111326,
                -0.965935598269138
              ],
              [
                -46.65470290085158,
                -0.964302599195682
              ],
              [
                -46.650036418215194,
                -0.960530127752173
              ],
              [
                -46.647165955583766,
                -0.958209165708998
              ],
              [
                -46.648814913608106,
                -0.960598635094015
              ],
              [
                -46.653560901259716,
                -0.967476093402942
              ],
              [
                -46.655362374082294,
                -0.970086054169503
              ],
              [
                -46.652552404684,
                -0.970278566391985
              ],
              [
                -46.64916191774981,
                -0.970510565249258
              ],
              [
                -46.63743751564897,
                -0.965054091500225
              ],
              [
                -46.63066356020649,
                -0.96162364425927
              ],
              [
                -46.62976506217808,
                -0.961168133547543
              ],
              [
                -46.62422913513362,
                -0.958364659787564
              ],
              [
                -46.6160471966257,
                -0.954221185710835
              ],
              [
                -46.60632125653203,
                -0.954169689929372
              ],
              [
                -46.60136027911444,
                -0.951011698899061
              ],
              [
                -46.598499316676104,
                -0.963439592825468
              ],
              [
                -46.59681785075256,
                -0.970742555136716
              ],
              [
                -46.59276438053099,
                -0.974442539038444
              ],
              [
                -46.585079406521885,
                -0.976207995925715
              ],
              [
                -46.580529456886296,
                -0.973654510422009
              ],
              [
                -46.571541042424805,
                -0.954584168668809
              ],
              [
                -46.56966652433789,
                -0.950621183030802
              ],
              [
                -46.56813756050067,
                -0.94738871298307
              ],
              [
                -46.566882561157335,
                -0.94751673587795
              ],
              [
                -46.56454657313212,
                -0.947755703815835
              ],
              [
                -46.56422809823827,
                -0.942189266343976
              ],
              [
                -46.56403609610697,
                -0.938851279248562
              ],
              [
                -46.56140962061862,
                -0.936534288243399
              ],
              [
                -46.55645665329946,
                -0.932166314287526
              ],
              [
                -46.558305136266384,
                -0.930306352396741
              ],
              [
                -46.56055862615851,
                -0.928039880589085
              ],
              [
                -46.56021713702662,
                -0.917225437335599
              ],
              [
                -46.55984963086635,
                -0.908317521847528
              ],
              [
                -46.55061267659245,
                -0.903612051960068
              ],
              [
                -46.54211024559297,
                -0.917316437571979
              ],
              [
                -46.54646673508037,
                -0.925439386377879
              ],
              [
                -46.54346875675638,
                -0.929237347652236
              ],
              [
                -46.54307423997005,
                -0.944987255942214
              ],
              [
                -46.54274924594837,
                -0.957948644092096
              ],
              [
                -46.541680770784254,
                -0.961329124716757
              ],
              [
                -46.540148757017484,
                -0.96617507034148
              ],
              [
                -46.538272775769514,
                -0.972107042815134
              ],
              [
                -46.54052127564085,
                -0.978669000092042
              ],
              [
                -46.53673129403242,
                -0.977263994118048
              ],
              [
                -46.53455329668083,
                -0.970174049797415
              ],
              [
                -46.530646334324864,
                -0.957455148324437
              ],
              [
                -46.53497732180863,
                -0.958472144248007
              ],
              [
                -46.53558231612689,
                -0.958614131260236
              ],
              [
                -46.53564781387777,
                -0.947975207739963
              ],
              [
                -46.531332816203545,
                -0.947515738659695
              ],
              [
                -46.52486088119227,
                -0.946826219042761
              ],
              [
                -46.52846787459763,
                -0.953447691000885
              ],
              [
                -46.51783094861773,
                -0.945043230532413
              ],
              [
                -46.52131791216557,
                -0.94126625917026
              ],
              [
                -46.52715285890096,
                -0.934947330078185
              ],
              [
                -46.52885087101962,
                -0.933107807023812
              ],
              [
                -46.53115885054447,
                -0.930316361153975
              ],
              [
                -46.52285539897552,
                -0.918090450570737
              ],
              [
                -46.522267409263485,
                -0.920257936300326
              ],
              [
                -46.521724913219145,
                -0.92225842028367
              ],
              [
                -46.51846994164207,
                -0.919456439853952
              ],
              [
                -46.51942144596932,
                -0.913588487832519
              ],
              [
                -46.5207514386352,
                -0.905388540292767
              ],
              [
                -46.51268997580696,
                -0.896459120157042
              ],
              [
                -46.50969899388896,
                -0.896357113583853
              ],
              [
                -46.50703303446365,
                -0.896266580961472
              ],
              [
                -46.507741530372385,
                -0.892888123311581
              ],
              [
                -46.508372529836954,
                -0.889879149889835
              ],
              [
                -46.51193701249268,
                -0.888511646516368
              ],
              [
                -46.510537003798774,
                -0.883886688957282
              ],
              [
                -46.49977660786705,
                -0.87413224775378
              ],
              [
                -46.496167131487795,
                -0.876223266710263
              ],
              [
                -46.492227153008066,
                -0.875102777663227
              ],
              [
                -46.484273193627175,
                -0.872841267718682
              ],
              [
                -46.47441577981998,
                -0.88095669788843
              ],
              [
                -46.470525314245194,
                -0.886466659212831
              ],
              [
                -46.471361299288226,
                -0.891056633949283
              ],
              [
                -46.467922326566494,
                -0.893465113537589
              ],
              [
                -46.466271333182455,
                -0.894621610696293
              ],
              [
                -46.4659338368535,
                -0.894025621119574
              ],
              [
                -46.462877877038345,
                -0.888626167197004
              ],
              [
                -46.456112423571035,
                -0.876672720947622
              ],
              [
                -46.45464295119483,
                -0.874094748091872
              ],
              [
                -46.44974248748516,
                -0.869871815183839
              ],
              [
                -46.44110953814321,
                -0.869887791123892
              ],
              [
                -46.44122555969425,
                -0.865720824352012
              ],
              [
                -46.44405402598718,
                -0.864467818045049
              ],
              [
                -46.4491849616185,
                -0.862131834098537
              ],
              [
                -46.430001633167954,
                -0.858267393985884
              ],
              [
                -46.42657867367879,
                -0.857578404781553
              ],
              [
                -46.41785473362129,
                -0.871107784449436
              ],
              [
                -46.41846871567512,
                -0.882216187280531
              ],
              [
                -46.42281019516936,
                -0.891064143476197
              ],
              [
                -46.43068412360291,
                -0.897838588616728
              ],
              [
                -46.43940557143585,
                -0.905128023015863
              ],
              [
                -46.447025994713975,
                -0.904034551065602
              ],
              [
                -46.44880048372575,
                -0.897630575210467
              ],
              [
                -46.45214646481488,
                -0.898320578420847
              ],
              [
                -46.45614791781273,
                -0.899300080538527
              ],
              [
                -46.45944091398877,
                -0.902906028918021
              ],
              [
                -46.46150837076554,
                -0.905258524544232
              ],
              [
                -46.464079852584796,
                -0.908204517787917
              ],
              [
                -46.46830231244652,
                -0.913042965061833
              ],
              [
                -46.46813832234701,
                -0.916272438878069
              ],
              [
                -46.46800083210304,
                -0.918962432734849
              ],
              [
                -46.467939314440336,
                -0.920171911678264
              ],
              [
                -46.46852430789907,
                -0.920398428050935
              ],
              [
                -46.47347426847461,
                -0.92231440828774
              ],
              [
                -46.479033742325875,
                -0.924430375101978
              ],
              [
                -46.483416695783596,
                -0.930688351804336
              ],
              [
                -46.48127674203451,
                -0.932972830468903
              ],
              [
                -46.47783275685107,
                -0.930909856496434
              ],
              [
                -46.473098795713646,
                -0.92807286716612
              ],
              [
                -46.47195029838014,
                -0.929795838143722
              ],
              [
                -46.468413815064984,
                -0.935101798039832
              ],
              [
                -46.47204579855469,
                -0.939212289720153
              ],
              [
                -46.46547034993773,
                -0.942405732059287
              ],
              [
                -46.47159831196077,
                -0.948190195521649
              ],
              [
                -46.47677975530124,
                -0.945358739310837
              ],
              [
                -46.480289740436724,
                -0.94344024407276
              ],
              [
                -46.47854274151867,
                -0.946865713250675
              ],
              [
                -46.47768424194964,
                -0.948526226731865
              ],
              [
                -46.475212289465404,
                -0.953307675683576
              ],
              [
                -46.473885275403575,
                -0.955881650278145
              ],
              [
                -46.48116173267459,
                -0.96070010312584
              ],
              [
                -46.482379221183045,
                -0.961506620243073
              ],
              [
                -46.49256461571728,
                -0.960413631639752
              ],
              [
                -46.49081815076738,
                -0.963988588708351
              ],
              [
                -46.499647060575214,
                -0.972935512916744
              ],
              [
                -46.49370662645863,
                -0.972515010334862
              ],
              [
                -46.48137221176334,
                -0.971469035650075
              ],
              [
                -46.47041829541686,
                -0.970541022254891
              ],
              [
                -46.463947342731,
                -0.975821990983954
              ],
              [
                -46.46398786470797,
                -0.981689958119483
              ],
              [
                -46.4641103505878,
                -0.999443336432176
              ],
              [
                -46.46934929775247,
                -1.002633285249646
              ],
              [
                -46.47018030828508,
                -1.003060312163017
              ],
              [
                -46.471914307790385,
                -1.003951286723207
              ],
              [
                -46.475138286388464,
                -1.000062839183602
              ],
              [
                -46.47719523781932,
                -0.997582341976131
              ],
              [
                -46.486105184745654,
                -1.000113335180499
              ],
              [
                -46.48373569667587,
                -1.002978282479245
              ],
              [
                -46.47803975679408,
                -1.009724753358726
              ],
              [
                -46.479172217187084,
                -1.0166426958223
              ],
              [
                -46.479652723074956,
                -1.019848154939472
              ],
              [
                -46.4761132576128,
                -1.018096173574744
              ],
              [
                -46.47301627970889,
                -1.016563686628536
              ],
              [
                -46.47154528129128,
                -1.022682135732579
              ],
              [
                -46.47263279451009,
                -1.025738117043745
              ],
              [
                -46.47525027338816,
                -1.032959078446124
              ],
              [
                -46.482498203886834,
                -1.037360030519804
              ],
              [
                -46.47617826397318,
                -1.042716989693797
              ],
              [
                -46.47268879632884,
                -1.047182967887506
              ],
              [
                -46.470597791113896,
                -1.049918448185851
              ],
              [
                -46.46826582072899,
                -1.052881936749265
              ],
              [
                -46.46911379595339,
                -1.046951975143536
              ],
              [
                -46.470278305014894,
                -1.038809022109575
              ],
              [
                -46.464967350241714,
                -1.02918410831527
              ],
              [
                -46.45355542201436,
                -1.031771074515408
              ],
              [
                -46.45135443249807,
                -1.027924091975782
              ],
              [
                -46.454725904649386,
                -1.026488606135565
              ],
              [
                -46.46033789963816,
                -1.02399913245688
              ],
              [
                -46.46069185876138,
                -1.017218174168747
              ],
              [
                -46.45700689611759,
                -1.017953180206857
              ],
              [
                -46.449518458745146,
                -1.019461176982264
              ],
              [
                -46.44430050792091,
                -1.020378672458893
              ],
              [
                -46.42674113639326,
                -1.013541201336515
              ],
              [
                -46.42263568389601,
                -1.015213212313575
              ],
              [
                -46.41803671818214,
                -1.017131707383333
              ],
              [
                -46.41247377486742,
                -1.019452163322526
              ],
              [
                -46.41216576269785,
                -1.039496511412251
              ],
              [
                -46.41597370400063,
                -1.049398455892125
              ],
              [
                -46.42073268139519,
                -1.048169940698726
              ],
              [
                -46.42496213887206,
                -1.047023472821496
              ],
              [
                -46.42980612067284,
                -1.053060416345305
              ],
              [
                -46.429318613921374,
                -1.057025384654837
              ],
              [
                -46.428330130503305,
                -1.065057318513842
              ],
              [
                -46.425389129253475,
                -1.064806316997112
              ],
              [
                -46.42589164977321,
                -1.054101920046665
              ],
              [
                -46.4203546758293,
                -1.051915929652881
              ],
              [
                -46.40838828803648,
                -1.061438372902856
              ],
              [
                -46.41069874085816,
                -1.05629138767777
              ],
              [
                -46.407555295283395,
                -1.047537967694709
              ],
              [
                -46.4061887863338,
                -1.043730977976429
              ],
              [
                -46.40696578803458,
                -1.03825253903658
              ],
              [
                -46.40991675187374,
                -1.017462174878422
              ],
              [
                -46.41608072389173,
                -1.012355731849646
              ],
              [
                -46.418785217278405,
                -1.010116235939562
              ],
              [
                -46.423245156300354,
                -1.006307261986599
              ],
              [
                -46.41847320037072,
                -1.004637268875245
              ],
              [
                -46.41847620435812,
                -1.004551797514834
              ],
              [
                -46.418760195209785,
                -0.99609234282191
              ],
              [
                -46.40974528389739,
                -0.995758332197001
              ],
              [
                -46.40224833965531,
                -0.988978378992789
              ],
              [
                -46.392359890103805,
                -0.988711384767628
              ],
              [
                -46.390025409377756,
                -0.98681089670284
              ],
              [
                -46.391284912574044,
                -1.003649763846835
              ],
              [
                -46.3922829061961,
                -1.016994180134891
              ],
              [
                -46.38920144540104,
                -1.021845141453102
              ],
              [
                -46.385677946932674,
                -1.027392589503367
              ],
              [
                -46.38364546023615,
                -1.030592087308075
              ],
              [
                -46.381719506703696,
                -1.03388406743428
              ],
              [
                -46.38067700739193,
                -1.044562467426901
              ],
              [
                -46.3749380607981,
                -1.051964411314061
              ],
              [
                -46.372144573581565,
                -1.05052093474325
              ],
              [
                -46.374314036578895,
                -1.044572494520755
              ],
              [
                -46.376948040144654,
                -1.037349516893459
              ],
              [
                -46.3770815266201,
                -1.033380553601422
              ],
              [
                -46.3771520289231,
                -1.018792171756735
              ],
              [
                -46.37222204625565,
                -1.024971630889055
              ],
              [
                -46.36930259503786,
                -1.015351678504482
              ],
              [
                -46.36254164653042,
                -1.005580755784343
              ],
              [
                -46.361870654839045,
                -1.004610271965017
              ],
              [
                -46.35741216610759,
                -0.998191826459506
              ],
              [
                -46.34941525135633,
                -0.994886340214774
              ],
              [
                -46.344245273172376,
                -0.996261852270516
              ],
              [
                -46.3460792625213,
                -1.000049292135851
              ],
              [
                -46.34427477312461,
                -1.002907289508044
              ],
              [
                -46.34263679347228,
                -1.005500774881551
              ],
              [
                -46.336357350804306,
                -1.015444683627168
              ],
              [
                -46.33336085628137,
                -1.036796518381722
              ],
              [
                -46.334573333766905,
                -1.04563346239095
              ],
              [
                -46.33633633882177,
                -1.048012427815808
              ],
              [
                -46.338270836519804,
                -1.050622429535846
              ],
              [
                -46.34752625631144,
                -1.063049320772657
              ],
              [
                -46.348492241621365,
                -1.072302778678597
              ],
              [
                -46.341471803624536,
                -1.074540235999563
              ],
              [
                -46.338102819061604,
                -1.071688776880124
              ],
              [
                -46.337814320325556,
                -1.071444272670776
              ],
              [
                -46.332402374915596,
                -1.073525269375893
              ],
              [
                -46.31941947863596,
                -1.071457281111245
              ],
              [
                -46.305111078702744,
                -1.083947180709474
              ],
              [
                -46.30335810889725,
                -1.079243204680536
              ],
              [
                -46.30687005418923,
                -1.075686230777153
              ],
              [
                -46.31264051130598,
                -1.069838264322124
              ],
              [
                -46.33299285096438,
                -1.068857767378531
              ],
              [
                -46.332817380298295,
                -1.061425846967724
              ],
              [
                -46.330126394441955,
                -1.058934348256819
              ],
              [
                -46.3243064501711,
                -1.053544396830649
              ],
              [
                -46.31936295519679,
                -1.039782986207254
              ],
              [
                -46.31941195595399,
                -1.038719020180013
              ],
              [
                -46.32019597104691,
                -1.021723645549636
              ],
              [
                -46.31686150481552,
                -1.01401468586552
              ],
              [
                -46.30469710438828,
                -1.018504657686773
              ],
              [
                -46.30445407023064,
                -1.018812146352274
              ],
              [
                -46.298162650921206,
                -1.02677261891243
              ],
              [
                -46.29689364519929,
                -1.046681958617352
              ],
              [
                -46.3005926015234,
                -1.054752386664816
              ],
              [
                -46.296720135581424,
                -1.05410287801879
              ],
              [
                -46.29308165355822,
                -1.047830950513386
              ],
              [
                -46.28347826194243,
                -1.047521927572149
              ],
              [
                -46.29288216697706,
                -1.041036979375693
              ],
              [
                -46.29088667017604,
                -1.035161034134065
              ],
              [
                -46.29235018371213,
                -1.028357578292551
              ],
              [
                -46.294219671333764,
                -1.019698647407969
              ],
              [
                -46.30084209777997,
                -1.014071209413432
              ],
              [
                -46.30680107129234,
                -1.00900724910779
              ],
              [
                -46.296723649216034,
                -0.993389842883143
              ],
              [
                -46.28975620539603,
                -0.999521304058131
              ],
              [
                -46.289521684186,
                -0.99972828847742
              ],
              [
                -46.27138384365581,
                -0.991242856840614
              ],
              [
                -46.26728237288622,
                -0.995573843854953
              ],
              [
                -46.26629936902994,
                -0.983009439961331
              ],
              [
                -46.27685880070293,
                -0.987632385590315
              ],
              [
                -46.281378278002904,
                -0.985231412150096
              ],
              [
                -46.281417268703706,
                -0.985209399319913
              ],
              [
                -46.28660874886843,
                -0.982269916997771
              ],
              [
                -46.274909824119256,
                -0.968332544095228
              ],
              [
                -46.272085353390246,
                -0.964967563996038
              ],
              [
                -46.2730258232887,
                -0.963446562519187
              ],
              [
                -46.27396684118263,
                -0.96192606903852
              ],
              [
                -46.26773889466349,
                -0.942704223522795
              ],
              [
                -46.26404691317961,
                -0.943346214270359
              ],
              [
                -46.26111094785749,
                -0.94385771108584
              ],
              [
                -46.262777929336735,
                -0.940504751446999
              ],
              [
                -46.263861419198086,
                -0.938325261932189
              ],
              [
                -46.261001427554554,
                -0.93652826408571
              ],
              [
                -46.26082693891852,
                -0.936418767887177
              ],
              [
                -46.25767643777789,
                -0.94109474241989
              ],
              [
                -46.25355598298587,
                -0.947166183362573
              ],
              [
                -46.25757847047439,
                -0.932969788398298
              ],
              [
                -46.243043582611556,
                -0.928159335828511
              ],
              [
                -46.24942251628698,
                -0.927378353431031
              ],
              [
                -46.255735464477624,
                -0.926604823968905
              ],
              [
                -46.25632397492773,
                -0.926532836577402
              ],
              [
                -46.26199292750016,
                -0.931100836941412
              ],
              [
                -46.269279881340765,
                -0.928737333528065
              ],
              [
                -46.26453689035461,
                -0.917438424801213
              ],
              [
                -46.25827496414281,
                -0.914844430561959
              ],
              [
                -46.254401498323986,
                -0.913239447172393
              ],
              [
                -46.25085403596635,
                -0.911769441808061
              ],
              [
                -46.2468495318188,
                -0.913251943218211
              ],
              [
                -46.24446407164525,
                -0.910155973200092
              ],
              [
                -46.24138210434581,
                -0.911713954147622
              ],
              [
                -46.24122658216625,
                -0.906213512447183
              ],
              [
                -46.22439520544158,
                -0.907418471491104
              ],
              [
                -46.21344179356422,
                -0.896278074981869
              ],
              [
                -46.21388079988759,
                -0.889673615675351
              ],
              [
                -46.20424636696222,
                -0.886023164053132
              ],
              [
                -46.18969097592106,
                -0.894066090962106
              ],
              [
                -46.18523100746348,
                -0.903459517829139
              ],
              [
                -46.18573402742689,
                -0.913625934448629
              ],
              [
                -46.18643403339363,
                -0.927802318962085
              ],
              [
                -46.18679301402945,
                -0.932876779863626
              ],
              [
                -46.1951044628533,
                -0.930567809181323
              ],
              [
                -46.19989491786303,
                -0.929278842422753
              ],
              [
                -46.20216586832851,
                -0.928667333280861
              ],
              [
                -46.20961731740599,
                -0.930095323414373
              ],
              [
                -46.21029884230739,
                -0.937198747127024
              ],
              [
                -46.210611829420046,
                -0.941190747175238
              ],
              [
                -46.22003276918684,
                -0.951650147729042
              ],
              [
                -46.216439786064136,
                -0.951377652349195
              ],
              [
                -46.21297981691445,
                -0.951084679139245
              ],
              [
                -46.202866395406055,
                -0.95022566639306
              ],
              [
                -46.20229987080331,
                -0.950177653686668
              ],
              [
                -46.20338887822601,
                -0.968270519923697
              ],
              [
                -46.21073831785385,
                -0.971655485393949
              ],
              [
                -46.21249179716719,
                -0.972456481592249
              ],
              [
                -46.21795374553621,
                -0.974951474523508
              ],
              [
                -46.22137322128126,
                -0.972904986301972
              ],
              [
                -46.22564021442231,
                -0.971172509824599
              ],
              [
                -46.22185271491687,
                -0.978305447820579
              ],
              [
                -46.22455020296031,
                -0.987008379829084
              ],
              [
                -46.228660664386865,
                -0.984714418550456
              ],
              [
                -46.232110167909546,
                -0.982640903534746
              ],
              [
                -46.23714160585999,
                -0.9914133413271
              ],
              [
                -46.247041044669594,
                -0.995490815963698
              ],
              [
                -46.25186700506053,
                -0.997335804724343
              ],
              [
                -46.241172587998946,
                -0.996571337128909
              ],
              [
                -46.23366714121595,
                -0.9960218113192
              ],
              [
                -46.221245718282496,
                -0.98935686179618
              ],
              [
                -46.22065022863406,
                -0.983288926266571
              ],
              [
                -46.214639279695874,
                -0.979342926152183
              ],
              [
                -46.21480127712479,
                -0.990197366692464
              ],
              [
                -46.21858024903708,
                -0.996738833064453
              ],
              [
                -46.21591929165245,
                -1.01297018293049
              ],
              [
                -46.222283736353205,
                -1.020760132293457
              ],
              [
                -46.21043731331952,
                -1.01954315791718
              ],
              [
                -46.20789633950535,
                -1.03150606883761
              ],
              [
                -46.20624385532356,
                -1.039284476271316
              ],
              [
                -46.20921880980119,
                -1.058360332584473
              ],
              [
                -46.21838825645034,
                -1.060515342636223
              ],
              [
                -46.22881017434329,
                -1.070746257324658
              ],
              [
                -46.23540261662335,
                -1.083103185604522
              ],
              [
                -46.24459452208739,
                -1.100368555511107
              ],
              [
                -46.24638400976818,
                -1.10373000058069
              ],
              [
                -46.26137140505997,
                -1.122817359746084
              ],
              [
                -46.25981144045896,
                -1.134920774933491
              ],
              [
                -46.25935990446099,
                -1.138457250401929
              ],
              [
                -46.2675443686587,
                -1.148504206388946
              ],
              [
                -46.27231283390134,
                -1.154357145286885
              ],
              [
                -46.27657479083206,
                -1.16879751389725
              ],
              [
                -46.2723842948783,
                -1.171591511390141
              ],
              [
                -46.2672948719546,
                -1.160010614270837
              ],
              [
                -46.25310748429821,
                -1.149754659962369
              ],
              [
                -46.24996199897059,
                -1.14049071684659
              ],
              [
                -46.24875799977392,
                -1.137093278106042
              ],
              [
                -46.24261155611967,
                -1.131872323321137
              ],
              [
                -46.240999080902206,
                -1.115321439025377
              ],
              [
                -46.230948652222395,
                -1.097347546271669
              ],
              [
                -46.22948714689011,
                -1.094807091105833
              ],
              [
                -46.22524068125483,
                -1.091401611044363
              ],
              [
                -46.206885344482124,
                -1.076681231899355
              ],
              [
                -46.19830890287647,
                -1.069802267332672
              ],
              [
                -46.19363146463318,
                -1.066051794837699
              ],
              [
                -46.18945648927958,
                -1.05627934758887
              ],
              [
                -46.18608299645979,
                -1.048381925396642
              ],
              [
                -46.18615851748708,
                -1.043618468148388
              ],
              [
                -46.186577993567596,
                -1.017323161549851
              ],
              [
                -46.186247523234115,
                -1.016491648749738
              ],
              [
                -46.1797610575562,
                -1.00017779241799
              ],
              [
                -46.17116962412578,
                -0.99250432598084
              ],
              [
                -46.16118872215738,
                -0.995653833087309
              ],
              [
                -46.15526075621734,
                -1.003190279735905
              ],
              [
                -46.16080821287434,
                -1.013744178162068
              ],
              [
                -46.15580171843692,
                -1.023925610066073
              ],
              [
                -46.15704120977623,
                -1.032193555140394
              ],
              [
                -46.158188723899976,
                -1.039842969856468
              ],
              [
                -46.15569424102793,
                -1.049370418008018
              ],
              [
                -46.16158968126285,
                -1.057330837030658
              ],
              [
                -46.17178311639573,
                -1.071093763107866
              ],
              [
                -46.17315460822303,
                -1.075151741847983
              ],
              [
                -46.177464070052864,
                -1.088290616877181
              ],
              [
                -46.18001104525756,
                -1.096056074989206
              ],
              [
                -46.184640021446285,
                -1.110167963821228
              ],
              [
                -46.19512043669237,
                -1.121028385877103
              ],
              [
                -46.19886838509803,
                -1.124911864300803
              ],
              [
                -46.20091436294265,
                -1.127031849703262
              ],
              [
                -46.20492386028275,
                -1.131186826138117
              ],
              [
                -46.20099338725392,
                -1.134764784778356
              ],
              [
                -46.19858788054211,
                -1.130178324801051
              ],
              [
                -46.18374852494374,
                -1.127718828143983
              ],
              [
                -46.17157909176877,
                -1.145983201540919
              ],
              [
                -46.17229759990303,
                -1.155120610172219
              ],
              [
                -46.165950132226705,
                -1.154490102983941
              ],
              [
                -46.166085161710534,
                -1.150742134090819
              ],
              [
                -46.16939763595341,
                -1.144104182311798
              ],
              [
                -46.1722315837696,
                -1.138425257276603
              ],
              [
                -46.17724605727953,
                -1.128374819313601
              ],
              [
                -46.18161952959327,
                -1.119472393654676
              ],
              [
                -46.17410408878493,
                -1.112865438139543
              ],
              [
                -46.16922662386775,
                -1.096280581833022
              ],
              [
                -46.16530115907825,
                -1.083128175954811
              ],
              [
                -46.15794021559178,
                -1.08002319325848
              ],
              [
                -46.153524744598215,
                -1.089387102405968
              ],
              [
                -46.147327292835016,
                -1.125001342408023
              ],
              [
                -46.15405322522862,
                -1.135235782611863
              ],
              [
                -46.14843680085895,
                -1.13484576674242
              ],
              [
                -46.147327292835016,
                -1.125001342408023
              ],
              [
                -46.14495279292868,
                -1.120150866454123
              ],
              [
                -46.139398856850725,
                -1.121422363142266
              ],
              [
                -46.14224632216351,
                -1.118451869820355
              ],
              [
                -46.1460847969284,
                -1.114447426390327
              ],
              [
                -46.146205321480835,
                -1.103534495268881
              ],
              [
                -46.14632980703966,
                -1.092236082053198
              ],
              [
                -46.148127796713794,
                -1.087572107292585
              ],
              [
                -46.151374781690755,
                -1.078789679631844
              ],
              [
                -46.14717079181321,
                -1.067382253682378
              ],
              [
                -46.13696838429685,
                -1.057296343048509
              ],
              [
                -46.10403263707611,
                -1.040817477525115
              ],
              [
                -46.100075652867204,
                -1.040292460958149
              ],
              [
                -46.096106213760415,
                -1.039740494592686
              ],
              [
                -46.092442235755534,
                -1.03548150312922
              ],
              [
                -46.08966676971898,
                -1.032255036024257
              ],
              [
                -46.092174735944184,
                -1.026703588075782
              ],
              [
                -46.08685978422347,
                -1.018713631467986
              ],
              [
                -46.08162132325872,
                -1.015911675873825
              ],
              [
                -46.07670135631695,
                -1.017166144818473
              ],
              [
                -46.07249441182715,
                -1.018516119133821
              ],
              [
                -46.07186188490992,
                -1.025140090239777
              ],
              [
                -46.0711664123928,
                -1.032424035379025
              ],
              [
                -46.07772334177897,
                -1.037777508972904
              ],
              [
                -46.082823304635006,
                -1.041941455331038
              ],
              [
                -46.08334480853021,
                -1.042366471203915
              ],
              [
                -46.08964476294212,
                -1.047509910365058
              ],
              [
                -46.09437371272063,
                -1.051370880968218
              ],
              [
                -46.100303686165155,
                -1.070623237112605
              ],
              [
                -46.09670420036804,
                -1.076291183058728
              ],
              [
                -46.09508420561047,
                -1.07884120068817
              ],
              [
                -46.09040375397838,
                -1.086209119718049
              ],
              [
                -46.08805526472332,
                -1.089906090633191
              ],
              [
                -46.089886230981556,
                -1.093800086636438
              ],
              [
                -46.08335729895084,
                -1.091847078417373
              ],
              [
                -46.0723283705243,
                -1.107369950318727
              ],
              [
                -46.08672127503522,
                -1.134205278835481
              ],
              [
                -46.086687245043734,
                -1.157348578147442
              ],
              [
                -46.08789273308578,
                -1.161531076939528
              ],
              [
                -46.09775116257626,
                -1.195724818921848
              ],
              [
                -46.10169964433111,
                -1.199697286727262
              ],
              [
                -46.102071654387366,
                -1.200071261979139
              ],
              [
                -46.10373813320012,
                -1.20174827665627
              ],
              [
                -46.10554860130594,
                -1.200640770229
              ],
              [
                -46.10660810317498,
                -1.199993285240888
              ],
              [
                -46.11235156709979,
                -1.196479813434146
              ],
              [
                -46.11331854751333,
                -1.197018798538011
              ],
              [
                -46.13015493143566,
                -1.206401220742573
              ],
              [
                -46.15072476211324,
                -1.224447592682346
              ],
              [
                -46.145497783796266,
                -1.236840501088004
              ],
              [
                -46.14027031546757,
                -1.249235426479482
              ],
              [
                -46.16248317165416,
                -1.2779691959431
              ],
              [
                -46.148859276633516,
                -1.307679995519738
              ],
              [
                -46.139821347587755,
                -1.308800981663452
              ],
              [
                -46.13078290355598,
                -1.309921942805121
              ],
              [
                -46.131560902945225,
                -1.319651905148552
              ],
              [
                -46.13237937792643,
                -1.329885817742271
              ],
              [
                -46.12888539641862,
                -1.333718769498148
              ],
              [
                -46.12125097076639,
                -1.333195771142896
              ],
              [
                -46.10573910937291,
                -1.332133775515422
              ],
              [
                -46.10383762040514,
                -1.336604761750143
              ],
              [
                -46.1146265054226,
                -1.350013129259223
              ],
              [
                -46.11685349325419,
                -1.367259034680629
              ],
              [
                -46.12069145160611,
                -1.372324980462717
              ],
              [
                -46.124530438948504,
                -1.377390942252606
              ],
              [
                -46.13111338798184,
                -1.400054763033199
              ],
              [
                -46.15557169450433,
                -1.42121663528686
              ],
              [
                -46.14605776098711,
                -1.428873068438583
              ],
              [
                -46.137501814175344,
                -1.435758019510257
              ],
              [
                -46.139090806373176,
                -1.444061955801429
              ],
              [
                -46.157586157096574,
                -1.459485847035812
              ],
              [
                -46.17621450877928,
                -1.475020705565848
              ],
              [
                -46.166204109001946,
                -1.482884664079523
              ],
              [
                -46.16258261057869,
                -1.48572915019776
              ],
              [
                -46.16213012003859,
                -1.491468616313904
              ],
              [
                -46.161953110000105,
                -1.493712078552063
              ],
              [
                -46.15429620681879,
                -1.502347500732873
              ],
              [
                -46.1478322426283,
                -1.509637453162623
              ],
              [
                -46.144978753722015,
                -1.515287399496765
              ],
              [
                -46.14212525981312,
                -1.520936851834165
              ],
              [
                -46.13155883386701,
                -1.578472443955133
              ],
              [
                -46.13969278090882,
                -1.582704897022617
              ],
              [
                -46.14918721126025,
                -1.587645390096455
              ],
              [
                -46.15000168859641,
                -1.594511320125271
              ],
              [
                -46.145668256215345,
                -1.599659777924089
              ],
              [
                -46.144329753290656,
                -1.601249294820608
              ],
              [
                -46.14426823219105,
                -1.609240234304922
              ],
              [
                -46.15957412643202,
                -1.624246122680119
              ],
              [
                -46.15914062697319,
                -1.648211417265822
              ],
              [
                -46.15586264904843,
                -1.654909901758206
              ],
              [
                -46.152793668585,
                -1.661181332470259
              ],
              [
                -46.153205652438906,
                -1.675555725636211
              ],
              [
                -46.17005901942192,
                -1.696045079832008
              ],
              [
                -46.19123134656981,
                -1.703792036745844
              ],
              [
                -46.20043328241605,
                -1.702801541057127
              ],
              [
                -46.20413527721094,
                -1.709937997836112
              ],
              [
                -46.20399377436755,
                -1.714400936659959
              ],
              [
                -46.20388077148578,
                -1.717988914989652
              ],
              [
                -46.212150217963426,
                -1.724616388551917
              ],
              [
                -46.21779564075557,
                -1.726688372383635
              ],
              [
                -46.22474161411989,
                -1.725703352190354
              ],
              [
                -46.235136020655524,
                -1.720512395953268
              ],
              [
                -46.24232394671053,
                -1.716922928134422
              ],
              [
                -46.25180490602868,
                -1.722662903573287
              ],
              [
                -46.26128632734979,
                -1.728403848022682
              ],
              [
                -46.276451183464786,
                -1.725663362617244
              ],
              [
                -46.290748072295244,
                -1.723080395848522
              ],
              [
                -46.30467746652953,
                -1.727392362880635
              ],
              [
                -46.30598644612791,
                -1.728650364838088
              ],
              [
                -46.31096444057013,
                -1.733437807430502
              ],
              [
                -46.31410290957988,
                -1.741352773456992
              ],
              [
                -46.32356031322092,
                -1.765203087844442
              ],
              [
                -46.32263381258485,
                -1.768903564234467
              ],
              [
                -46.3217083309392,
                -1.772604538621736
              ],
              [
                -46.31617789488426,
                -1.794695357816314
              ],
              [
                -46.31292988786145,
                -1.799948350196219
              ],
              [
                -46.3065834531035,
                -1.80394229619832
              ],
              [
                -46.29898052036848,
                -1.797631366219411
              ],
              [
                -46.29380156942057,
                -1.793332882893454
              ],
              [
                -46.28762558560029,
                -1.796247346922802
              ],
              [
                -46.282534645493975,
                -1.798649845984749
              ],
              [
                -46.25747982591902,
                -1.792573890390209
              ],
              [
                -46.22891204060543,
                -1.800261309006295
              ],
              [
                -46.21490114124019,
                -1.818130682366008
              ],
              [
                -46.21224565610966,
                -1.825795133943109
              ],
              [
                -46.20959020997283,
                -1.83345857552926
              ],
              [
                -46.22546555764099,
                -1.856571887902638
              ],
              [
                -46.242249943919326,
                -1.881009701836066
              ],
              [
                -46.233637017642636,
                -1.881146701801443
              ],
              [
                -46.2254680639353,
                -1.881275726930081
              ],
              [
                -46.21815862521864,
                -1.912881478193969
              ],
              [
                -46.23289048219281,
                -1.966537575771263
              ],
              [
                -46.24905635467915,
                -2.000165329198105
              ],
              [
                -46.24858387100856,
                -2.009605257333566
              ],
              [
                -46.24811187532353,
                -2.019044195485411
              ],
              [
                -46.25854376158384,
                -2.042614539061853
              ],
              [
                -46.26520070316695,
                -2.049895453012333
              ],
              [
                -46.26557322717769,
                -2.050302961099673
              ],
              [
                -46.272974641784714,
                -2.058399407242402
              ],
              [
                -46.27650063735898,
                -2.078437249312318
              ],
              [
                -46.272246173573826,
                -2.085233226076109
              ],
              [
                -46.26799269977783,
                -2.092029150838672
              ],
              [
                -46.26847065902787,
                -2.111505998320451
              ],
              [
                -46.27016716375433,
                -2.115839980810733
              ],
              [
                -46.27186314348333,
                -2.120171937319778
              ],
              [
                -46.27342812398224,
                -2.121611944117688
              ],
              [
                -46.29660446785341,
                -2.14293280415998
              ],
              [
                -46.29610647021165,
                -2.148594231321021
              ],
              [
                -46.28959149623933,
                -2.150219745598598
              ],
              [
                -46.28022206319472,
                -2.152558211854526
              ],
              [
                -46.29141747637132,
                -2.164219645966318
              ],
              [
                -46.316291300692896,
                -2.175654049513898
              ],
              [
                -46.31641427972559,
                -2.175710572131273
              ],
              [
                -46.323610250210976,
                -2.182421502697772
              ],
              [
                -46.330806662693405,
                -2.189131970285223
              ],
              [
                -46.337584599709274,
                -2.202125381561447
              ],
              [
                -46.341883089196465,
                -2.210365794143308
              ],
              [
                -46.341940571537855,
                -2.218462237624774
              ],
              [
                -46.352915483633105,
                -2.225656185784768
              ],
              [
                -46.35705094437607,
                -2.226594694036956
              ],
              [
                -46.36660890783058,
                -2.228763669757921
              ],
              [
                -46.37341330858581,
                -2.241824078700576
              ],
              [
                -46.37611131727992,
                -2.247002058421953
              ],
              [
                -46.381857749087224,
                -2.24968352934265
              ],
              [
                -46.387604206897244,
                -2.252365508264436
              ],
              [
                -46.39182717795533,
                -2.249790040364906
              ],
              [
                -46.401329627085396,
                -2.24399605857831
              ],
              [
                -46.41052556465223,
                -2.23074717543257
              ],
              [
                -46.431918397884054,
                -2.239473112359025
              ],
              [
                -46.42961090084981,
                -2.246205068306646
              ],
              [
                -46.41911146683195,
                -2.25758848680973
              ],
              [
                -46.41686348452811,
                -2.260025959286712
              ],
              [
                -46.421412445150104,
                -2.289229729322377
              ],
              [
                -46.42632839790864,
                -2.293618212545908
              ],
              [
                -46.431244857663785,
                -2.298006191780976
              ],
              [
                -46.43069935920616,
                -2.317880037919698
              ],
              [
                -46.430616845987366,
                -2.320865511011676
              ],
              [
                -46.43215435764094,
                -2.32595746013667
              ],
              [
                -46.433691333296096,
                -2.331047946277097
              ],
              [
                -46.43633280912752,
                -2.333662419888883
              ],
              [
                -46.4630526149943,
                -2.360109731021181
              ],
              [
                -46.464448091025844,
                -2.370999136715464
              ],
              [
                -46.46296310314218,
                -2.375612120507482
              ],
              [
                -46.45390164788535,
                -2.383295064569696
              ],
              [
                -46.44702972375827,
                -2.380663077788235
              ],
              [
                -46.43558831173392,
                -2.368549677088946
              ],
              [
                -46.42661039490355,
                -2.359044239061291
              ],
              [
                -46.41084500797486,
                -2.361795215249097
              ],
              [
                -46.40837154375479,
                -2.36980816080197
              ],
              [
                -46.41948444107391,
                -2.38262053824432
              ],
              [
                -46.42303992613261,
                -2.390940981369669
              ],
              [
                -46.42304239311191,
                -2.390946499330023
              ],
              [
                -46.426595392184225,
                -2.399260933513959
              ],
              [
                -46.42786287000542,
                -2.40054643148284
              ],
              [
                -46.43963977742743,
                -2.41248883561255
              ],
              [
                -46.43811026471727,
                -2.420952274213686
              ],
              [
                -46.43658077599866,
                -2.429415200823508
              ],
              [
                -46.43889777214348,
                -2.445821091507154
              ],
              [
                -46.43924677169809,
                -2.448292063539827
              ],
              [
                -46.439003285315984,
                -2.449163066094303
              ],
              [
                -46.436476776097415,
                -2.458204989185546
              ],
              [
                -46.431169821781936,
                -2.459302504525476
              ],
              [
                -46.43472580059035,
                -2.471453404759347
              ],
              [
                -46.4359377929551,
                -2.475595382848094
              ],
              [
                -46.43758276651783,
                -2.481215326268951
              ],
              [
                -46.43228380238717,
                -2.48800526898098
              ],
              [
                -46.42799935681296,
                -2.493496747498668
              ],
              [
                -46.42960033031851,
                -2.49712123650205
              ],
              [
                -46.43528278540943,
                -2.509989139230578
              ],
              [
                -46.433503298769374,
                -2.514475081888764
              ],
              [
                -46.42726584094202,
                -2.517824061451058
              ],
              [
                -46.415303935417654,
                -2.524247025736378
              ],
              [
                -46.41596793978064,
                -2.529056506796227
              ],
              [
                -46.46577152040254,
                -2.543261892845134
              ],
              [
                -46.477711441685145,
                -2.541616906654591
              ],
              [
                -46.47938794080843,
                -2.541385919874156
              ],
              [
                -46.4827764264984,
                -2.532276957438487
              ],
              [
                -46.49165634794615,
                -2.535376963630977
              ],
              [
                -46.49505230354346,
                -2.539125927352124
              ],
              [
                -46.501435265039696,
                -2.555655835790606
              ],
              [
                -46.49316482745205,
                -2.560233797745142
              ],
              [
                -46.48936535012684,
                -2.562336772184593
              ],
              [
                -46.48695935734714,
                -2.569765709217648
              ],
              [
                -46.49041382106045,
                -2.581074622847193
              ],
              [
                -46.49971827104889,
                -2.578446660018853
              ],
              [
                -46.50065725344567,
                -2.579550660271989
              ],
              [
                -46.50879918851772,
                -2.589117065162116
              ],
              [
                -46.50669220569221,
                -2.601843472712495
              ],
              [
                -46.504584203855046,
                -2.614570396270504
              ],
              [
                -46.53994194836475,
                -2.622457340421942
              ],
              [
                -46.55474982751433,
                -2.630416292353019
              ],
              [
                -46.561543746074875,
                -2.627430803324677
              ],
              [
                -46.563304773742054,
                -2.62665732453494
              ],
              [
                -46.57435567192977,
                -2.628414315390738
              ],
              [
                -46.58436260407202,
                -2.636629230925218
              ],
              [
                -46.58593856428611,
                -2.644663705002536
              ],
              [
                -46.58891555733296,
                -2.659844090591689
              ],
              [
                -46.59332849859353,
                -2.665025042395374
              ],
              [
                -46.59839946709,
                -2.654137124251752
              ],
              [
                -46.605553420983206,
                -2.638780233296058
              ],
              [
                -46.61069836778592,
                -2.643468182914433
              ],
              [
                -46.62150029529702,
                -2.64417620035315
              ],
              [
                -46.620211320088714,
                -2.665838033481832
              ],
              [
                -46.63335369551325,
                -2.672760999587014
              ],
              [
                -46.633037177696174,
                -2.676541483017879
              ],
              [
                -46.632609188292385,
                -2.681654924730308
              ],
              [
                -46.63557766794457,
                -2.68607589762063
              ],
              [
                -46.642144122576994,
                -2.682397440549852
              ],
              [
                -46.648579062201904,
                -2.678792969893228
              ],
              [
                -46.65030706889787,
                -2.681771924842897
              ],
              [
                -46.647640092798724,
                -2.686702887138312
              ],
              [
                -46.6465490985304,
                -2.688718895131423
              ],
              [
                -46.65251705495237,
                -2.690522866050323
              ],
              [
                -46.6635239460573,
                -2.693849865622587
              ],
              [
                -46.66749644164263,
                -2.702257306951557
              ],
              [
                -46.671469888213494,
                -2.71066424330108
              ],
              [
                -46.66599591575115,
                -2.724339645216263
              ],
              [
                -46.66980388536062,
                -2.730424602381446
              ],
              [
                -46.667663908736884,
                -2.733677555051809
              ],
              [
                -46.66092495733029,
                -2.731648065459004
              ],
              [
                -46.65243803157254,
                -2.729092589087242
              ],
              [
                -46.65254901240649,
                -2.735898053749396
              ],
              [
                -46.65590802179639,
                -2.7380570172422
              ],
              [
                -46.65663399804501,
                -2.738524509100735
              ],
              [
                -46.650443054562324,
                -2.756607403799497
              ],
              [
                -46.645015092158026,
                -2.757258399197657
              ],
              [
                -46.64277611546307,
                -2.754179893753234
              ],
              [
                -46.63986212728567,
                -2.750173444810455
              ],
              [
                -46.63549416055936,
                -2.754220404939922
              ],
              [
                -46.631532187777076,
                -2.766798296319746
              ],
              [
                -46.63624515442242,
                -2.778514244984426
              ],
              [
                -46.637160158585495,
                -2.78079022479872
              ],
              [
                -46.63622116247812,
                -2.793332124527721
              ],
              [
                -46.62697219201884,
                -2.804081033342916
              ],
              [
                -46.611007347673926,
                -2.799498067960023
              ],
              [
                -46.60885235055194,
                -2.792977125502405
              ],
              [
                -46.60733636582904,
                -2.788390169246174
              ],
              [
                -46.60191941143501,
                -2.788847144034562
              ],
              [
                -46.5985514441537,
                -2.795337112768948
              ],
              [
                -46.599358934891136,
                -2.804071528690106
              ],
              [
                -46.60016041168601,
                -2.81274695704749
              ],
              [
                -46.596641432241526,
                -2.817263946043827
              ],
              [
                -46.59367595261238,
                -2.815824952432359
              ],
              [
                -46.592724450111064,
                -2.815363929440057
              ],
              [
                -46.5900004732897,
                -2.817731912301256
              ],
              [
                -46.58902448204701,
                -2.822785887298378
              ],
              [
                -46.59009098166343,
                -2.823238873390767
              ],
              [
                -46.59391344161735,
                -2.824859881989315
              ],
              [
                -46.591021976159226,
                -2.829862837681807
              ],
              [
                -46.59011098263089,
                -2.831438807929408
              ],
              [
                -46.58255555438964,
                -2.827202846968359
              ],
              [
                -46.580638554163286,
                -2.830085824377067
              ],
              [
                -46.58382804532967,
                -2.833593833067622
              ],
              [
                -46.57538760381108,
                -2.841040738054207
              ],
              [
                -46.58141354973107,
                -2.839902746454869
              ],
              [
                -46.58747202334755,
                -2.844595721585442
              ],
              [
                -46.59859040479659,
                -2.835547310153923
              ],
              [
                -46.600394424402765,
                -2.834079326439149
              ],
              [
                -46.61351732515219,
                -2.828602867806492
              ],
              [
                -46.61810925030886,
                -2.835553790055307
              ],
              [
                -46.61167533066763,
                -2.841906762716418
              ],
              [
                -46.611260314752094,
                -2.845064230683652
              ],
              [
                -46.61000231706112,
                -2.854640660831666
              ],
              [
                -46.617692270257216,
                -2.85892562454127
              ],
              [
                -46.626131684509254,
                -2.855249649201248
              ],
              [
                -46.63624312714172,
                -2.850844711734762
              ],
              [
                -46.640019099434234,
                -2.852564189632366
              ],
              [
                -46.63538312111257,
                -2.858334633173561
              ],
              [
                -46.64091509747534,
                -2.863978624285637
              ],
              [
                -46.64072157258683,
                -2.865045609489222
              ],
              [
                -46.64002606958429,
                -2.868868579554825
              ],
              [
                -46.63459614004489,
                -2.869946563791459
              ],
              [
                -46.634445616879255,
                -2.873661540834645
              ],
              [
                -46.64139208115701,
                -2.876993536217161
              ],
              [
                -46.64044109108993,
                -2.880893480637549
              ],
              [
                -46.64600004411476,
                -2.884074472622265
              ],
              [
                -46.654204462049655,
                -2.878074530023424
              ],
              [
                -46.660681933578076,
                -2.876872023069383
              ],
              [
                -46.66821538404754,
                -2.875474513906735
              ],
              [
                -46.67922778211648,
                -2.881383489133958
              ],
              [
                -46.680276786179235,
                -2.892131408761461
              ],
              [
                -46.67219785030965,
                -2.892383410001339
              ],
              [
                -46.67056834618686,
                -2.894255901862107
              ],
              [
                -46.66214090861115,
                -2.903942328714939
              ],
              [
                -46.66513339609091,
                -2.905238799428314
              ],
              [
                -46.665189899646776,
                -2.905263299271709
              ],
              [
                -46.66275087541885,
                -2.916361252102752
              ],
              [
                -46.66060393320745,
                -2.91843071634725
              ],
              [
                -46.655997954081016,
                -2.922868174560432
              ],
              [
                -46.65338447636476,
                -2.930706646905209
              ],
              [
                -46.66515238610516,
                -2.935092101528652
              ],
              [
                -46.66686184370428,
                -2.93572910755347
              ],
              [
                -46.67124882387247,
                -2.946773509331423
              ],
              [
                -46.66586034174587,
                -2.95395297243762
              ],
              [
                -46.66560734168146,
                -2.953989480079804
              ],
              [
                -46.655297962558556,
                -2.955477935492154
              ],
              [
                -46.64761501259907,
                -2.961345929213315
              ],
              [
                -46.64751900563419,
                -2.971392827546949
              ],
              [
                -46.66036790615576,
                -2.972686333995898
              ],
              [
                -46.661444869818574,
                -2.972794839614068
              ],
              [
                -46.66161089521877,
                -2.976365802870726
              ],
              [
                -46.65916641282652,
                -2.976973795562758
              ],
              [
                -46.651661977956095,
                -2.978839779269663
              ],
              [
                -46.6486109848517,
                -2.98319475165299
              ],
              [
                -46.65166395950514,
                -2.996167656055442
              ],
              [
                -46.66059189336087,
                -2.994437689914152
              ],
              [
                -46.668803834690515,
                -2.992845703503864
              ],
              [
                -46.67222079886058,
                -2.996896659529964
              ],
              [
                -46.672382782184926,
                -3.003403620596628
              ],
              [
                -46.66585835163615,
                -3.008241559192726
              ],
              [
                -46.66396785967564,
                -3.00964356935242
              ],
              [
                -46.66808231732137,
                -3.014069045950688
              ],
              [
                -46.6737717781982,
                -3.020188497923256
              ],
              [
                -46.67190328212711,
                -3.024083473093457
              ],
              [
                -46.66869381933169,
                -3.03077340057578
              ],
              [
                -46.674221779814424,
                -3.033250890798288
              ],
              [
                -46.67854873175359,
                -3.035190378449801
              ],
              [
                -46.679663720123,
                -3.053786261684553
              ],
              [
                -46.67745423482671,
                -3.059873699915483
              ],
              [
                -46.67524475952621,
                -3.065962147140077
              ],
              [
                -46.681023209165524,
                -3.073429120507106
              ],
              [
                -46.683914680465485,
                -3.077165599676483
              ],
              [
                -46.682933200677525,
                -3.080683061929655
              ],
              [
                -46.67944072177475,
                -3.09318996738617
              ],
              [
                -46.69039412395272,
                -3.091784969774451
              ],
              [
                -46.69072060745456,
                -3.09174295720426
              ],
              [
                -46.706414515267575,
                -3.097817938523168
              ],
              [
                -46.70597198634399,
                -3.10385688983192
              ],
              [
                -46.704806486151654,
                -3.119776793670104
              ],
              [
                -46.71011046611846,
                -3.127995726548615
              ],
              [
                -46.71600143062371,
                -3.125791723739817
              ],
              [
                -46.71618693474143,
                -3.111507842699949
              ],
              [
                -46.71619540693108,
                -3.110849857443338
              ],
              [
                -46.719318401789295,
                -3.108527882392135
              ],
              [
                -46.72399935694707,
                -3.110621853139553
              ],
              [
                -46.729146317308995,
                -3.120989291490297
              ],
              [
                -46.73037080692573,
                -3.123455740210674
              ],
              [
                -46.731382782459875,
                -3.122625782584588
              ],
              [
                -46.73455929102517,
                -3.120021785582498
              ],
              [
                -46.7396692317307,
                -3.122517785618065
              ],
              [
                -46.7473951630547,
                -3.120527801979536
              ],
              [
                -46.74497120578073,
                -3.133172708003588
              ],
              [
                -46.73663474373282,
                -3.128554222965341
              ],
              [
                -46.731995285439325,
                -3.125983753646516
              ],
              [
                -46.73164229138345,
                -3.138219651414863
              ],
              [
                -46.723357336225604,
                -3.150802546557554
              ],
              [
                -46.72644230844044,
                -3.158538495100234
              ],
              [
                -46.73729524341932,
                -3.159785985436694
              ],
              [
                -46.746246156133765,
                -3.160815511589044
              ],
              [
                -46.74844613394504,
                -3.164015457444065
              ],
              [
                -46.74736615270988,
                -3.170465942612314
              ],
              [
                -46.74602517486145,
                -3.17847436947383
              ],
              [
                -46.75202912530897,
                -3.186934298039076
              ],
              [
                -46.75781007097045,
                -3.18154235012755
              ],
              [
                -46.76521402774684,
                -3.174636391746975
              ],
              [
                -46.77333295098983,
                -3.175410391427672
              ],
              [
                -46.75989406834952,
                -3.18554531718063
              ],
              [
                -46.75922903726693,
                -3.186046331311677
              ],
              [
                -46.75675207123205,
                -3.193823782400576
              ],
              [
                -46.764439504594726,
                -3.197580728486179
              ],
              [
                -46.77050097278671,
                -3.20054221864238
              ],
              [
                -46.766427978198585,
                -3.20284470945277
              ],
              [
                -46.75673107260944,
                -3.208325165149879
              ],
              [
                -46.75572305939562,
                -3.215744118420558
              ],
              [
                -46.75908004873507,
                -3.217821097853588
              ],
              [
                -46.76097105689771,
                -3.211053160256453
              ],
              [
                -46.77022996592767,
                -3.212911118649151
              ],
              [
                -46.7789399032339,
                -3.214659120614964
              ],
              [
                -46.78065886163916,
                -3.220578094791784
              ],
              [
                -46.77929688465035,
                -3.224222037064454
              ],
              [
                -46.77813088836438,
                -3.227340047194655
              ],
              [
                -46.78179887683947,
                -3.24052091802707
              ],
              [
                -46.78855379830963,
                -3.241958919459353
              ],
              [
                -46.7922222923674,
                -3.238820948486187
              ],
              [
                -46.79560775450444,
                -3.235924958658711
              ],
              [
                -46.80701968351242,
                -3.241499954294958
              ],
              [
                -46.804687679369046,
                -3.256482844812186
              ],
              [
                -46.803608186846596,
                -3.263422792569993
              ],
              [
                -46.80966264769928,
                -3.267352751864459
              ],
              [
                -46.81162613695603,
                -3.266491279852917
              ],
              [
                -46.818724591659816,
                -3.263375780123093
              ],
              [
                -46.823607520890725,
                -3.268867755740045
              ],
              [
                -46.81498858971045,
                -3.274818711503582
              ],
              [
                -46.80908563942279,
                -3.278894173834054
              ],
              [
                -46.818465059523874,
                -3.285725109722675
              ],
              [
                -46.827415474054746,
                -3.292243566695638
              ],
              [
                -46.82160751932107,
                -3.295585064328669
              ],
              [
                -46.81123662158647,
                -3.301552525233054
              ],
              [
                -46.81295160325355,
                -3.309188476201861
              ],
              [
                -46.82153654088009,
                -3.312531452820694
              ],
              [
                -46.82978847806105,
                -3.311067444757604
              ],
              [
                -46.839570393631774,
                -3.323762842883124
              ],
              [
                -46.84552734430549,
                -3.320274869364742
              ],
              [
                -46.84925830854456,
                -3.318090414574259
              ],
              [
                -46.85048581738284,
                -3.324675856917971
              ],
              [
                -46.85130530426049,
                -3.329072817770376
              ],
              [
                -46.848041320759336,
                -3.335271277008109
              ],
              [
                -46.857545248209114,
                -3.341350249480289
              ],
              [
                -46.86378820225275,
                -3.331748799850959
              ],
              [
                -46.8672111891492,
                -3.326484344981557
              ],
              [
                -46.872524130953266,
                -3.327422336495461
              ],
              [
                -46.86705816669942,
                -3.33279730871836
              ],
              [
                -46.86713416092944,
                -3.333244778546737
              ],
              [
                -46.867992187223706,
                -3.338322268558305
              ],
              [
                -46.878600090248725,
                -3.344322210084448
              ],
              [
                -46.87706607432845,
                -3.341211262702042
              ],
              [
                -46.8804710460157,
                -3.339208262472379
              ],
              [
                -46.88741501939754,
                -3.335122307638168
              ],
              [
                -46.889595479202875,
                -3.341002738476018
              ],
              [
                -46.90402986034355,
                -3.350671180402034
              ],
              [
                -46.899945421150235,
                -3.351980164311487
              ],
              [
                -46.899651410879656,
                -3.353239658177987
              ],
              [
                -46.8988359066731,
                -3.356739127801975
              ],
              [
                -46.9052148880935,
                -3.358192630109492
              ],
              [
                -46.903887882825885,
                -3.364035091765105
              ],
              [
                -46.90699785879627,
                -3.366784084428834
              ],
              [
                -46.91242729962415,
                -3.363210107289865
              ],
              [
                -46.91521677098624,
                -3.361373131608307
              ],
              [
                -46.91680679034628,
                -3.36479008985328
              ],
              [
                -46.92341472901382,
                -3.363886083122848
              ],
              [
                -46.920946747188815,
                -3.368080073092986
              ],
              [
                -46.92198173609379,
                -3.370815035983081
              ],
              [
                -46.92333571019389,
                -3.374394008978111
              ],
              [
                -46.927533689624276,
                -3.375234532759616
              ],
              [
                -46.93325065527273,
                -3.376380036010519
              ],
              [
                -46.93804861063353,
                -3.371228076870479
              ],
              [
                -46.94205259836513,
                -3.374901037331205
              ],
              [
                -46.93840809852664,
                -3.383890469548509
              ],
              [
                -46.93626962770017,
                -3.389164932951703
              ],
              [
                -46.936665601057626,
                -3.3929478850964
              ],
              [
                -46.94112859767709,
                -3.39091791350931
              ],
              [
                -46.94346355821382,
                -3.389855929095158
              ],
              [
                -46.94857652195305,
                -3.392300417825157
              ],
              [
                -46.944244536387636,
                -3.39653488972425
              ],
              [
                -46.94845803680799,
                -3.406536806106916
              ],
              [
                -46.94422156598115,
                -3.411993773317027
              ],
              [
                -46.9413875921645,
                -3.415644748025574
              ],
              [
                -46.94333357031568,
                -3.422470205133008
              ],
              [
                -46.945378524735176,
                -3.419880693504173
              ],
              [
                -46.94652150658587,
                -3.418432739335337
              ],
              [
                -46.94718051351732,
                -3.423136704037999
              ],
              [
                -46.95546743588241,
                -3.423289701529447
              ],
              [
                -46.950835479700686,
                -3.436602577505022
              ],
              [
                -46.94929548556647,
                -3.436481570700089
              ],
              [
                -46.9474445068286,
                -3.436336589931358
              ],
              [
                -46.94054856389177,
                -3.44758550464973
              ],
              [
                -46.94277654551717,
                -3.450553485439324
              ],
              [
                -46.9513614796899,
                -3.448156013287563
              ],
              [
                -46.9468275131468,
                -3.458168927866964
              ],
              [
                -46.95194248380229,
                -3.456098968868939
              ],
              [
                -46.95616794911581,
                -3.461351426245196
              ],
              [
                -46.955214446962344,
                -3.464738374668631
              ],
              [
                -46.9488404883368,
                -3.465942383289818
              ],
              [
                -46.948460979495735,
                -3.476885291067406
              ],
              [
                -46.9633793701333,
                -3.474413833243432
              ],
              [
                -46.96888233132416,
                -3.47350232515852
              ],
              [
                -46.9658578542182,
                -3.48108979273117
              ],
              [
                -46.96041040103563,
                -3.494755186906092
              ],
              [
                -46.966778344742025,
                -3.493499713667084
              ],
              [
                -46.96824635390102,
                -3.490255210442457
              ],
              [
                -46.96928683087529,
                -3.490360214152892
              ],
              [
                -46.97835523293322,
                -3.491272732649337
              ],
              [
                -46.98305722547584,
                -3.496766194577444
              ],
              [
                -46.975468786146486,
                -3.507073612772075
              ],
              [
                -46.969999315826996,
                -3.514503561404009
              ],
              [
                -46.9793147460816,
                -3.517472522390558
              ],
              [
                -46.98033072014837,
                -3.517796019537785
              ],
              [
                -46.97983022451498,
                -3.518866029692892
              ],
              [
                -46.976640258970306,
                -3.525684957698922
              ],
              [
                -46.98949466290854,
                -3.529071453310687
              ],
              [
                -46.990112654098525,
                -3.529233948429266
              ],
              [
                -46.990219646157385,
                -3.529507436529459
              ],
              [
                -46.99252312990186,
                -3.535373897785629
              ],
              [
                -46.99931658699732,
                -3.534975402658988
              ],
              [
                -47.005391044591256,
                -3.534618901911781
              ],
              [
                -47.00754699871579,
                -3.539922865122058
              ],
              [
                -47.00955200315932,
                -3.544854857918058
              ],
              [
                -47.01567942866023,
                -3.546062849068904
              ],
              [
                -47.01807992205319,
                -3.546535855779784
              ],
              [
                -47.01817944453563,
                -3.548234309739178
              ],
              [
                -47.019020901673464,
                -3.562631718687911
              ],
              [
                -47.02285689075521,
                -3.563799717108468
              ],
              [
                -47.0292208487748,
                -3.559800229423165
              ],
              [
                -47.03291881888721,
                -3.561040240269432
              ],
              [
                -47.037358772205536,
                -3.562528711684749
              ],
              [
                -47.036683261585,
                -3.564310699708119
              ],
              [
                -47.03519077626049,
                -3.568246685045902
              ],
              [
                -47.03646679035196,
                -3.57301116774446
              ],
              [
                -47.03049083688849,
                -3.580263600474884
              ],
              [
                -47.026892829807956,
                -3.579484595384529
              ],
              [
                -47.03543476706194,
                -3.586822557086125
              ],
              [
                -47.029662307303376,
                -3.600118940947652
              ],
              [
                -47.03326880054736,
                -3.600388468674569
              ],
              [
                -47.032111789888106,
                -3.603765420146449
              ],
              [
                -47.03438579139921,
                -3.603909420159776
              ],
              [
                -47.03660926429944,
                -3.604050426171525
              ],
              [
                -47.04055473644369,
                -3.614278380296526
              ],
              [
                -47.048196650635106,
                -3.619272841312775
              ],
              [
                -47.04484370465513,
                -3.625143289077165
              ],
              [
                -47.05071114345789,
                -3.632285219047581
              ],
              [
                -47.04434317090182,
                -3.633029242836431
              ],
              [
                -47.04563916767033,
                -3.63808170155105
              ],
              [
                -47.03965820509414,
                -3.641146190033809
              ],
              [
                -47.04693867028009,
                -3.645604631730196
              ],
              [
                -47.055826599914084,
                -3.646221639450341
              ],
              [
                -47.04774764330762,
                -3.656036581039751
              ],
              [
                -47.05149463598748,
                -3.661738546299163
              ],
              [
                -47.0481601355474,
                -3.668232993656544
              ],
              [
                -47.044662660219096,
                -3.664148995022571
              ],
              [
                -47.04271317720026,
                -3.661872525279155
              ],
              [
                -47.036081738780936,
                -3.671073435886392
              ],
              [
                -47.051740124242,
                -3.680994881754618
              ],
              [
                -47.04513265752096,
                -3.707636194657819
              ],
              [
                -47.04971562336695,
                -3.718466139999701
              ],
              [
                -47.05215208166374,
                -3.720690615372121
              ],
              [
                -47.06113253504074,
                -3.728889544475398
              ],
              [
                -47.05541355249131,
                -3.736061999929329
              ],
              [
                -47.05521705900946,
                -3.738167990907633
              ],
              [
                -47.05437906921246,
                -3.747150940838557
              ],
              [
                -47.058972526665144,
                -3.754291388415014
              ],
              [
                -47.06435797846617,
                -3.762662793662081
              ],
              [
                -47.06205050885337,
                -3.767506264249153
              ],
              [
                -47.059743504235406,
                -3.772348729843579
              ],
              [
                -47.06189448679304,
                -3.787039650816138
              ],
              [
                -47.06402396914076,
                -3.796164597220808
              ],
              [
                -47.06957394735553,
                -3.798839545949339
              ],
              [
                -47.073193885797906,
                -3.800585058333394
              ],
              [
                -47.07782634618841,
                -3.811329488515001
              ],
              [
                -47.07633535134939,
                -3.813958453189762
              ],
              [
                -47.07504689012983,
                -3.816229952491927
              ],
              [
                -47.07884833673465,
                -3.818905403396118
              ],
              [
                -47.08779577419595,
                -3.825203876162743
              ],
              [
                -47.085354786759474,
                -3.831713837927238
              ],
              [
                -47.08291330632195,
                -3.838224783685564
              ],
              [
                -47.08768976360045,
                -3.855094159709339
              ],
              [
                -47.09469670920345,
                -3.858696644661297
              ],
              [
                -47.101261651304824,
                -3.862071617013431
              ],
              [
                -47.10356365287349,
                -3.867857578246039
              ],
              [
                -47.106859620614024,
                -3.876141036326074
              ],
              [
                -47.116525533908074,
                -3.877612540676557
              ],
              [
                -47.12144449225058,
                -3.881734980973355
              ],
              [
                -47.1217800087983,
                -3.881497987812395
              ],
              [
                -47.12710694786959,
                -3.877724521084302
              ],
              [
                -47.13474638060381,
                -3.881431505659399
              ],
              [
                -47.134761900300305,
                -3.892048926695191
              ],
              [
                -47.13477240465673,
                -3.899328866302753
              ],
              [
                -47.13858584812506,
                -3.902087855711331
              ],
              [
                -47.14177133888195,
                -3.899782383560355
              ],
              [
                -47.146302274823206,
                -3.896502885948103
              ],
              [
                -47.155801703542245,
                -3.914046268847511
              ],
              [
                -47.169715096477766,
                -3.921450221531224
              ],
              [
                -47.1707925795631,
                -3.93294213502688
              ],
              [
                -47.17152156313923,
                -3.940717585567994
              ],
              [
                -47.17396355999345,
                -3.94368408387524
              ],
              [
                -47.176497035752654,
                -3.943415075038693
              ],
              [
                -47.18166100253672,
                -3.942867089484039
              ],
              [
                -47.18125251205306,
                -3.952147507869258
              ],
              [
                -47.18094248792048,
                -3.959189490086726
              ],
              [
                -47.18477595121436,
                -3.965998940032314
              ],
              [
                -47.188925931365176,
                -3.966236420447044
              ],
              [
                -47.18951942381055,
                -3.966270422506115
              ],
              [
                -47.19037042599753,
                -3.968888396491324
              ],
              [
                -47.19321538687054,
                -3.977643869252825
              ],
              [
                -47.19647288495,
                -3.973678401825486
              ],
              [
                -47.19893837460176,
                -3.970677398204885
              ],
              [
                -47.202188323072654,
                -3.978047835936336
              ],
              [
                -47.205965277909804,
                -3.986613297533129
              ],
              [
                -47.211882747259125,
                -3.985459309650686
              ],
              [
                -47.21365924285503,
                -3.985113308984971
              ],
              [
                -47.21527474403376,
                -3.988052303113396
              ],
              [
                -47.21941617459783,
                -3.995587233177133
              ],
              [
                -47.22590964791436,
                -3.997698219615778
              ],
              [
                -47.22687913468967,
                -3.997333706672648
              ],
              [
                -47.235885081579866,
                -3.993951247040799
              ],
              [
                -47.24460598087444,
                -4.011969148730434
              ],
              [
                -47.267026306893385,
                -4.025285031556764
              ],
              [
                -47.26700979172524,
                -4.027836527616094
              ],
              [
                -47.26697579631229,
                -4.033099986608843
              ],
              [
                -47.27220675417205,
                -4.031516996412325
              ],
              [
                -47.27582623009196,
                -4.030420997967259
              ],
              [
                -47.28419018542707,
                -4.035944958443816
              ],
              [
                -47.28561114447486,
                -4.040079955126644
              ],
              [
                -47.287105137854304,
                -4.044428407351232
              ],
              [
                -47.291858093188644,
                -4.043217448298698
              ],
              [
                -47.292594096666036,
                -4.043029930993813
              ],
              [
                -47.29268008670387,
                -4.043629918824332
              ],
              [
                -47.29394510553685,
                -4.052470376395101
              ],
              [
                -47.30293251812577,
                -4.052104389593865
              ],
              [
                -47.30715847802302,
                -4.057680827625673
              ],
              [
                -47.31085444417039,
                -4.055035870097361
              ],
              [
                -47.30891099136427,
                -4.052369388813197
              ],
              [
                -47.30944046248798,
                -4.052047880342892
              ],
              [
                -47.31846940239101,
                -4.046563913486015
              ],
              [
                -47.324878849233365,
                -4.056848864605954
              ],
              [
                -47.32524533069088,
                -4.068064251101841
              ],
              [
                -47.331963809360985,
                -4.070157751892018
              ],
              [
                -47.33366627347765,
                -4.079682690965333
              ],
              [
                -47.33271726749738,
                -4.082052177854687
              ],
              [
                -47.33038830226796,
                -4.087867629860375
              ],
              [
                -47.33794874628993,
                -4.094267593917391
              ],
              [
                -47.3451351963294,
                -4.100351047015569
              ],
              [
                -47.34755416404211,
                -4.107761510338626
              ],
              [
                -47.3503316274404,
                -4.116276460121213
              ],
              [
                -47.34931464822715,
                -4.118142451513529
              ],
              [
                -47.34805413463869,
                -4.120455416646212
              ],
              [
                -47.344209170293816,
                -4.127510857193869
              ],
              [
                -47.347348634315026,
                -4.135246303650448
              ],
              [
                -47.34983512115125,
                -4.141371768052527
              ],
              [
                -47.34692015988748,
                -4.14423473646346
              ],
              [
                -47.343270657848485,
                -4.147820726421566
              ],
              [
                -47.347019645509604,
                -4.156871658013839
              ],
              [
                -47.342066659439695,
                -4.168010088395864
              ],
              [
                -47.341798166743025,
                -4.177021522159628
              ],
              [
                -47.347610604925066,
                -4.181724464327401
              ],
              [
                -47.351762595911744,
                -4.178586487500129
              ],
              [
                -47.35370855584528,
                -4.177116017827033
              ],
              [
                -47.364995979597914,
                -4.179330019332829
              ],
              [
                -47.36043502750649,
                -4.188554439356357
              ],
              [
                -47.36855144524002,
                -4.194929413083767
              ],
              [
                -47.36659996103505,
                -4.202636323129783
              ],
              [
                -47.36585746014047,
                -4.205568830220481
              ],
              [
                -47.368946936121795,
                -4.20643533181345
              ],
              [
                -47.36936192089562,
                -4.206551834220787
              ],
              [
                -47.370704409209054,
                -4.209280802853857
              ],
              [
                -47.371375440367146,
                -4.210645272671072
              ],
              [
                -47.373408917663646,
                -4.214778287859357
              ],
              [
                -47.37269540133224,
                -4.216228264337551
              ],
              [
                -47.37119390815584,
                -4.219279719194558
              ],
              [
                -47.37346941368705,
                -4.21950173586411
              ],
              [
                -47.37924234737157,
                -4.220065219024999
              ],
              [
                -47.37611286256632,
                -4.23068216412123
              ],
              [
                -47.372054389142974,
                -4.244452059683482
              ],
              [
                -47.37840835390865,
                -4.244054061897348
              ],
              [
                -47.379400846055084,
                -4.244588574698142
              ],
              [
                -47.388774270884355,
                -4.249641536458571
              ],
              [
                -47.3972136856097,
                -4.260698936790887
              ],
              [
                -47.403055141963506,
                -4.26835192251944
              ],
              [
                -47.40713260052925,
                -4.269017415089277
              ],
              [
                -47.41094160216679,
                -4.269638410824659
              ],
              [
                -47.41552104636435,
                -4.275020376746274
              ],
              [
                -47.41805154547489,
                -4.277994340366924
              ],
              [
                -47.41967952127239,
                -4.277620846860729
              ],
              [
                -47.42916145720554,
                -4.275447855191199
              ],
              [
                -47.42910092830312,
                -4.283513313878321
              ],
              [
                -47.429083449173625,
                -4.285844295604851
              ],
              [
                -47.431036418902515,
                -4.284723807488811
              ],
              [
                -47.43260441544472,
                -4.283824310620426
              ],
              [
                -47.441221351399065,
                -4.286421780204585
              ],
              [
                -47.44876076920836,
                -4.298888211383887
              ],
              [
                -47.473016581241126,
                -4.314070094870933
              ],
              [
                -47.471442110803636,
                -4.320338587325657
              ],
              [
                -47.47943954989983,
                -4.327686522543459
              ],
              [
                -47.47790353571377,
                -4.334763480400521
              ],
              [
                -47.47734906698911,
                -4.336486962089774
              ],
              [
                -47.47600756191205,
                -4.340657434300733
              ],
              [
                -47.48138002007406,
                -4.349639868724835
              ],
              [
                -47.48138351004335,
                -4.349647362674578
              ],
              [
                -47.475716554430726,
                -4.354646830718252
              ],
              [
                -47.485809966381595,
                -4.36409479455283
              ],
              [
                -47.487947939001906,
                -4.366096262811764
              ],
              [
                -47.488119948630015,
                -4.371634202364166
              ],
              [
                -47.488292446249915,
                -4.377173164913556
              ],
              [
                -47.49532740511225,
                -4.38545264323641
              ],
              [
                -47.502162821620516,
                -4.393496590108965
              ],
              [
                -47.502118830184685,
                -4.398474057466479
              ],
              [
                -47.50207532774143,
                -4.403452514820286
              ],
              [
                -47.51823518383208,
                -4.426600860003498
              ],
              [
                -47.52377816193729,
                -4.435015801666983
              ],
              [
                -47.530739602191666,
                -4.443527252485062
              ],
              [
                -47.5629368414963,
                -4.47507602881026
              ],
              [
                -47.58104417546157,
                -4.519572250670931
              ],
              [
                -47.60753896854184,
                -4.548365071573853
              ],
              [
                -47.61068142226899,
                -4.555974012726295
              ],
              [
                -47.62269183250342,
                -4.565507429747844
              ],
              [
                -47.627670779585415,
                -4.567398938566742
              ],
              [
                -47.63724473475739,
                -4.571036394996186
              ],
              [
                -47.65216208801583,
                -4.589019317380545
              ],
              [
                -47.67950986617621,
                -4.608241157643254
              ],
              [
                -47.701134199321686,
                -4.604417207800128
              ],
              [
                -47.709909650009344,
                -4.605677728270474
              ],
              [
                -47.7114936058606,
                -4.605905206634079
              ],
              [
                -47.711441130814144,
                -4.604956699151216
              ],
              [
                -47.71132863786588,
                -4.60290974621645
              ],
              [
                -47.71485460125481,
                -4.60267922688578
              ],
              [
                -47.717079081920744,
                -4.603467217757245
              ],
              [
                -47.724380521028,
                -4.606054202206132
              ],
              [
                -47.741623401788885,
                -4.598348265549951
              ],
              [
                -47.74182290532832,
                -4.598259278281509
              ],
              [
                -47.741934408468914,
                -4.598283290181479
              ],
              [
                -47.75035882652225,
                -4.6001282664153
              ],
              [
                -47.751660321922415,
                -4.596255293902935
              ],
              [
                -47.753607826548354,
                -4.590458315046434
              ],
              [
                -47.77043767053664,
                -4.592951843747576
              ],
              [
                -47.78948854375306,
                -4.585147382779768
              ],
              [
                -47.79709047770372,
                -4.59107687236755
              ],
              [
                -47.804692939651716,
                -4.597007307965424
              ],
              [
                -47.81571782697564,
                -4.614364711870727
              ],
              [
                -48.02568163299203,
                -4.778939668635168
              ],
              [
                -48.05787539780959,
                -4.803840515198137
              ],
              [
                -48.194194319107716,
                -4.910649360097711
              ],
              [
                -48.203803231995025,
                -4.918243852519886
              ],
              [
                -48.21190415998666,
                -4.924645802120274
              ],
              [
                -48.39583418450706,
                -5.070008893320272
              ],
              [
                -48.41860601364648,
                -5.085346306653356
              ],
              [
                -48.42262946179916,
                -5.080268841606801
              ],
              [
                -48.42685993656973,
                -5.074929898460959
              ],
              [
                -48.43418539573788,
                -5.076308871966216
              ],
              [
                -48.437154856957314,
                -5.083202332362172
              ],
              [
                -48.43644384756912,
                -5.085823837188975
              ],
              [
                -48.43371736141686,
                -5.095868773555915
              ],
              [
                -48.452136238246396,
                -5.111183170366916
              ],
              [
                -48.64383069959006,
                -5.261723257850509
              ],
              [
                -48.75471282282593,
                -5.348801238641357
              ],
              [
                -48.75429332163624,
                -5.356457698200488
              ],
              [
                -48.74922832750559,
                -5.363524159458205
              ],
              [
                -48.745051862606616,
                -5.369351612610942
              ],
              [
                -48.73068047904596,
                -5.374803044131703
              ],
              [
                -48.71985755289416,
                -5.373269051802293
              ],
              [
                -48.71632558979987,
                -5.376796034759926
              ],
              [
                -48.68011085811235,
                -5.378390001739777
              ],
              [
                -48.65004707644077,
                -5.384549928479092
              ],
              [
                -48.64664559719443,
                -5.386940923098914
              ],
              [
                -48.643245129941896,
                -5.389331393721638
              ],
              [
                -48.62868172257309,
                -5.409689266293303
              ],
              [
                -48.603241389950135,
                -5.41949518076733
              ],
              [
                -48.59707944838538,
                -5.420434664137639
              ],
              [
                -48.59091748582503,
                -5.42137514350038
              ],
              [
                -48.557096736465034,
                -5.416342184383057
              ],
              [
                -48.51031211777194,
                -5.429278034756936
              ],
              [
                -48.500710174018394,
                -5.427425045413108
              ],
              [
                -48.4911082362759,
                -5.425572082075273
              ],
              [
                -48.464148949928884,
                -5.411921628694807
              ],
              [
                -48.44134711081825,
                -5.40778914343788
              ],
              [
                -48.41912627894367,
                -5.40145765939332
              ],
              [
                -48.401001939862866,
                -5.40304014507248
              ],
              [
                -48.39563750346263,
                -5.396821172116497
              ],
              [
                -48.39307001080795,
                -5.393844699329575
              ],
              [
                -48.38430256363504,
                -5.393838209247393
              ],
              [
                -48.37304714721353,
                -5.402368646022811
              ],
              [
                -48.3694636613843,
                -5.410604589138794
              ],
              [
                -48.36791318354202,
                -5.414168556090813
              ],
              [
                -48.36347269782534,
                -5.417131047059515
              ],
              [
                -48.35760676615369,
                -5.421044509631875
              ],
              [
                -48.34592682840659,
                -5.437471863975516
              ],
              [
                -48.341796371212034,
                -5.449203789195016
              ],
              [
                -48.341271842549894,
                -5.457176736231886
              ],
              [
                -48.340645356557296,
                -5.466694670820224
              ],
              [
                -48.33316090917626,
                -5.477511617828228
              ],
              [
                -48.32690694579712,
                -5.486550557651382
              ],
              [
                -48.327332441654775,
                -5.492126021489958
              ],
              [
                -48.32741895020504,
                -5.493255487962601
              ],
              [
                -48.327647918354685,
                -5.496262479922399
              ],
              [
                -48.32582893547628,
                -5.49421696637229
              ],
              [
                -48.325083450674086,
                -5.49337801347939
              ],
              [
                -48.30026909448697,
                -5.522635796216411
              ],
              [
                -48.27826928457528,
                -5.540039166545041
              ],
              [
                -48.26967182353098,
                -5.543870603639149
              ],
              [
                -48.26107538548774,
                -5.547701601731135
              ],
              [
                -48.23106961742793,
                -5.550422038930485
              ],
              [
                -48.22805263495056,
                -5.550696036925069
              ],
              [
                -48.22010822164567,
                -5.552282543559601
              ],
              [
                -48.212163745349116,
                -5.553869508188908
              ],
              [
                -48.194299913600254,
                -5.562722442815998
              ],
              [
                -48.19163093377309,
                -5.566828396275641
              ],
              [
                -48.18928893084051,
                -5.570432394346562
              ],
              [
                -48.18366947969208,
                -5.572521348252095
              ],
              [
                -48.174384518199076,
                -5.575972820350849
              ],
              [
                -48.13771480776846,
                -5.602320141611583
              ],
              [
                -48.13181333615758,
                -5.617758018450948
              ],
              [
                -48.13505079658544,
                -5.637329887125534
              ],
              [
                -48.151017131093404,
                -5.669078186307683
              ],
              [
                -48.155573109128596,
                -5.678136121775344
              ],
              [
                -48.16012806916304,
                -5.687193072269626
              ],
              [
                -48.17387845762108,
                -5.708844455988993
              ],
              [
                -48.19347181027459,
                -5.719251364596629
              ],
              [
                -48.219603583073216,
                -5.727791323628736
              ],
              [
                -48.27210270570774,
                -5.725121404358014
              ],
              [
                -48.280844143402454,
                -5.731237868866636
              ],
              [
                -48.28872704744088,
                -5.741572777518062
              ],
              [
                -48.29411201400719,
                -5.756302208939006
              ],
              [
                -48.29889445323012,
                -5.781574042443559
              ],
              [
                -48.29133696043003,
                -5.833228179444802
              ],
              [
                -48.289897008756554,
                -5.837894657174718
              ],
              [
                -48.28845749107748,
                -5.842561123905954
              ],
              [
                -48.273167081214005,
                -5.86636344130053
              ],
              [
                -48.245164785736044,
                -5.895859737956193
              ],
              [
                -48.23233137555824,
                -5.916704564909431
              ],
              [
                -48.2299103849776,
                -5.925649532307281
              ],
              [
                -48.23119284193449,
                -5.94576439495988
              ],
              [
                -48.23928329963326,
                -5.951430860345682
              ],
              [
                -48.247721202531615,
                -5.95733981639715
              ],
              [
                -48.25151970687449,
                -5.960000790564338
              ],
              [
                -48.27533101182237,
                -5.964847281578477
              ],
              [
                -48.29151537844203,
                -5.968140772099567
              ],
              [
                -48.29907831407926,
                -5.971303768852607
              ],
              [
                -48.306640266729325,
                -5.974466757613061
              ],
              [
                -48.321021666287095,
                -5.98512567172317
              ],
              [
                -48.327649106167684,
                -5.994513643337944
              ],
              [
                -48.33427702603666,
                -6.0039020859772
              ],
              [
                -48.3381594919757,
                -6.020466472755404
              ],
              [
                -48.336935986857696,
                -6.03116437162301
              ],
              [
                -48.33227203370263,
                -6.037893338215422
              ],
              [
                -48.30154525188482,
                -6.046931756062061
              ],
              [
                -48.285349872776685,
                -6.070009589789884
              ],
              [
                -48.284034871302474,
                -6.075596048581846
              ],
              [
                -48.28271937682837,
                -6.081182505375422
              ],
              [
                -48.29168379563549,
                -6.103692366699948
              ],
              [
                -48.29837824154524,
                -6.110909325998393
              ],
              [
                -48.305072193456375,
                -6.118126268315938
              ],
              [
                -48.32887499827305,
                -6.133341210081794
              ],
              [
                -48.33146195649675,
                -6.134994702152904
              ],
              [
                -48.33404945771678,
                -6.136648164226144
              ],
              [
                -48.36504919330889,
                -6.146999115151771
              ],
              [
                -48.390850529843135,
                -6.146329156126163
              ],
              [
                -48.40783135688771,
                -6.153026119244748
              ],
              [
                -48.40878487507109,
                -6.153844113582242
              ],
              [
                -48.41938275719074,
                -6.162939564755234
              ],
              [
                -48.43149818101054,
                -6.176917959836482
              ],
              [
                -48.433744651694795,
                -6.184970950304749
              ],
              [
                -48.43599164436682,
                -6.19302388778643
              ],
              [
                -48.42471268664663,
                -6.23340309644151
              ],
              [
                -48.41854470412543,
                -6.267868848440007
              ],
              [
                -48.41734720009999,
                -6.270365862953056
              ],
              [
                -48.41226923566994,
                -6.280953280807487
              ],
              [
                -48.41446271602136,
                -6.284843247869572
              ],
              [
                -48.41685221561431,
                -6.289081713791375
              ],
              [
                -48.41668271345425,
                -6.298211173299406
              ],
              [
                -48.41160873249654,
                -6.305687602737055
              ],
              [
                -48.389612371683796,
                -6.320613495086298
              ],
              [
                -48.38443793472564,
                -6.324124974635917
              ],
              [
                -48.38015494810286,
                -6.330033939062339
              ],
              [
                -48.378747983110976,
                -6.334398877052482
              ],
              [
                -48.37486995641844,
                -6.346427817354318
              ],
              [
                -48.38223938099298,
                -6.37923160767538
              ],
              [
                -48.390617838260226,
                -6.376464618867602
              ],
              [
                -48.39899729652874,
                -6.37369765605641
              ],
              [
                -48.40722520930667,
                -6.368065673033653
              ],
              [
                -48.42393359212476,
                -6.356628285319718
              ],
              [
                -48.43559950784962,
                -6.353715315155249
              ],
              [
                -48.44726543059243,
                -6.350801819988476
              ],
              [
                -48.49977404148944,
                -6.350550865878527
              ],
              [
                -48.50347651303993,
                -6.352566871637703
              ],
              [
                -48.50717948558773,
                -6.354582878399834
              ],
              [
                -48.52351982963773,
                -6.373163731450934
              ],
              [
                -48.52693781532167,
                -6.381230688144524
              ],
              [
                -48.53035576699802,
                -6.389297621853506
              ],
              [
                -48.546855127223374,
                -6.404968074401153
              ],
              [
                -48.55805454004408,
                -6.411203027695811
              ],
              [
                -48.56185152381081,
                -6.413317489948243
              ],
              [
                -48.56450701683646,
                -6.414593022770201
              ],
              [
                -48.61271262312658,
                -6.453350768359128
              ],
              [
                -48.626095981351796,
                -6.477761626377293
              ],
              [
                -48.629599451723344,
                -6.484153596392064
              ],
              [
                -48.63143845212055,
                -6.48750755394085
              ],
              [
                -48.64582529262338,
                -6.508509960374424
              ],
              [
                -48.64627277661295,
                -6.523454340804398
              ],
              [
                -48.65766569704723,
                -6.539052261289188
              ],
              [
                -48.66291013313176,
                -6.557424125447895
              ],
              [
                -48.663912636724426,
                -6.570193028716241
              ],
              [
                -48.65177671105068,
                -6.611675781867377
              ],
              [
                -48.65151868594379,
                -6.639572579195155
              ],
              [
                -48.6549161332594,
                -6.650258532270149
              ],
              [
                -48.66026460028873,
                -6.657087494264003
              ],
              [
                -48.66441205763846,
                -6.662382938252496
              ],
              [
                -48.686159375994094,
                -6.679433848754639
              ],
              [
                -48.73643448747162,
                -6.692402295886906
              ],
              [
                -48.76072429382745,
                -6.720987116796378
              ],
              [
                -48.76894321794006,
                -6.728326608191684
              ],
              [
                -48.77716214105243,
                -6.735666068610425
              ],
              [
                -48.81374484683065,
                -6.751384964481816
              ],
              [
                -48.81942880273882,
                -6.752098493845621
              ],
              [
                -48.82511129866158,
                -6.752812464206878
              ],
              [
                -48.83943917688253,
                -6.750281003282332
              ],
              [
                -48.84932761658461,
                -6.74853402434494
              ],
              [
                -48.913408113941834,
                -6.756988514922891
              ],
              [
                -48.926235017168956,
                -6.7616494910032
              ],
              [
                -48.939061393416836,
                -6.766310473103773
              ],
              [
                -48.968035174793876,
                -6.779491901297162
              ],
              [
                -49.01843781354094,
                -6.783831438898093
              ],
              [
                -49.03288815267,
                -6.802154330172893
              ],
              [
                -49.03810712792489,
                -6.80877276901094
              ],
              [
                -49.043326107176384,
                -6.81539123086365
              ],
              [
                -49.082832275568144,
                -6.842980582912747
              ],
              [
                -49.114826020770785,
                -6.869568939054564
              ],
              [
                -49.11737549958516,
                -6.870938434516979
              ],
              [
                -49.11992497840004,
                -6.872307924980662
              ],
              [
                -49.15001623419569,
                -6.888470348937023
              ],
              [
                -49.17149354997298,
                -6.892553844043117
              ],
              [
                -49.17904250327588,
                -6.897675831890266
              ],
              [
                -49.18107597208194,
                -6.902192792650908
              ],
              [
                -49.18346794949891,
                -6.907508261601713
              ],
              [
                -49.194482874872804,
                -6.915048223068297
              ],
              [
                -49.209049735676516,
                -6.925019667155389
              ],
              [
                -49.21159272494284,
                -6.931453625093942
              ],
              [
                -49.21413619620034,
                -6.937887596042262
              ],
              [
                -49.21699467478682,
                -6.961087958534114
              ],
              [
                -49.21493115203219,
                -6.975351343004557
              ],
              [
                -49.21146621397414,
                -6.979466324395394
              ],
              [
                -49.20882121372331,
                -6.982607809790192
              ],
              [
                -49.194831271732745,
                -7.025883016998675
              ],
              [
                -49.19387329118828,
                -7.033086943863658
              ],
              [
                -49.19291576563286,
                -7.040291903726986
              ],
              [
                -49.19085726913359,
                -7.074546671001261
              ],
              [
                -49.19334770637741,
                -7.129431835414628
              ],
              [
                -49.18730321274335,
                -7.177825015754045
              ],
              [
                -49.18541919348436,
                -7.192908415410756
              ],
              [
                -49.1827567000906,
                -7.214200786617495
              ],
              [
                -49.18483618307441,
                -7.234788630872305
              ],
              [
                -49.20634199437656,
                -7.270345457030229
              ],
              [
                -49.215475897645156,
                -7.297347761299763
              ],
              [
                -49.231026269155905,
                -7.325401589186032
              ],
              [
                -49.232205737998854,
                -7.326851605070727
              ],
              [
                -49.23338573083776,
                -7.328301585956853
              ],
              [
                -49.23431270804939,
                -7.329239596865748
              ],
              [
                -49.25388457160224,
                -7.34904297746431
              ],
              [
                -49.25960901851362,
                -7.357916924444597
              ],
              [
                -49.26533246742687,
                -7.366788363463374
              ],
              [
                -49.31926399778554,
                -7.430889988075082
              ],
              [
                -49.37754602256142,
                -7.496061649652638
              ],
              [
                -49.3836039239326,
                -7.543108348572073
              ],
              [
                -49.38248341467698,
                -7.554693271105696
              ],
              [
                -49.381363431399826,
                -7.566278188653053
              ],
              [
                -49.36041902031024,
                -7.614447865907531
              ],
              [
                -49.34119564604047,
                -7.656879078213838
              ],
              [
                -49.322774248886624,
                -7.681360401830216
              ],
              [
                -49.29607842308094,
                -7.696056309917543
              ],
              [
                -49.263513146402076,
                -7.723071101061254
              ],
              [
                -49.24611275533022,
                -7.737131490454163
              ],
              [
                -49.244445267344915,
                -7.738478962235769
              ],
              [
                -49.23954728984219,
                -7.740339476489312
              ],
              [
                -49.227707383430314,
                -7.753460364438197
              ],
              [
                -49.2207134314907,
                -7.761210823283844
              ],
              [
                -49.21170247588253,
                -7.767659239305996
              ],
              [
                -49.20269205227691,
                -7.774108199316719
              ],
              [
                -49.19501308825499,
                -7.777164185147837
              ],
              [
                -49.16029335040494,
                -7.790980064266664
              ],
              [
                -49.150135933885615,
                -7.801809987324855
              ],
              [
                -49.14970791882173,
                -7.809831440107745
              ],
              [
                -49.161804301645944,
                -7.82839881500841
              ],
              [
                -49.16446477562935,
                -7.832482295908214
              ],
              [
                -49.165551778110455,
                -7.84064174241902
              ],
              [
                -49.166639745574074,
                -7.848802208934974
              ],
              [
                -49.16232873736735,
                -7.900141869017559
              ],
              [
                -49.16309870288424,
                -7.913332790498256
              ],
              [
                -49.16386920637257,
                -7.926524215000554
              ],
              [
                -49.16919462753594,
                -7.954401531593195
              ],
              [
                -49.17538656680314,
                -7.965487470119021
              ],
              [
                -49.18157901805243,
                -7.976573385679426
              ],
              [
                -49.17516702275214,
                -8.008846684525382
              ],
              [
                -49.172835543694674,
                -8.02058810508906
              ],
              [
                -49.17470851130619,
                -8.042711456699234
              ],
              [
                -49.175680989871886,
                -8.054193423422928
              ],
              [
                -49.181210920769146,
                -8.068229302910092
              ],
              [
                -49.18750486834071,
                -8.072522283552626
              ],
              [
                -49.1927133327832,
                -8.07607478071428
              ],
              [
                -49.19891078979317,
                -8.087191716353422
              ],
              [
                -49.20090524181424,
                -8.105091616918426
              ],
              [
                -49.20687869412081,
                -8.122110511306978
              ],
              [
                -49.20466368759572,
                -8.133300439637866
              ],
              [
                -49.2034131871162,
                -8.139616882621826
              ],
              [
                -49.20913163332187,
                -8.17509967992724
              ],
              [
                -49.21540454739073,
                -8.19382054618171
              ],
              [
                -49.21826051939459,
                -8.199121016000978
              ],
              [
                -49.221712505759164,
                -8.205528506526598
              ],
              [
                -49.23602187642568,
                -8.232088834451426
              ],
              [
                -49.25329518685629,
                -8.270637601732393
              ],
              [
                -49.26261457390929,
                -8.310415373018348
              ],
              [
                -49.269574017309786,
                -8.342938685336382
              ],
              [
                -49.28287136831573,
                -8.379166443691426
              ],
              [
                -49.2888053046003,
                -8.385100433187162
              ],
              [
                -49.294739745879056,
                -8.391034896694784
              ],
              [
                -49.31284763549449,
                -8.402504345128685
              ],
              [
                -49.34895883387961,
                -8.417985782555004
              ],
              [
                -49.37656209856392,
                -8.45489155645699
              ],
              [
                -49.383222503325705,
                -8.474286967260406
              ],
              [
                -49.38713348479216,
                -8.481836913689403
              ],
              [
                -49.39104493224765,
                -8.489387380130832
              ],
              [
                -49.399211316798564,
                -8.546720529253294
              ],
              [
                -49.40012430075534,
                -8.558403943786262
              ],
              [
                -49.40943670849016,
                -8.579607306775836
              ],
              [
                -49.451019358759176,
                -8.612103661478576
              ],
              [
                -49.46141228164026,
                -8.634799550820421
              ],
              [
                -49.46616124100195,
                -8.641251487310745
              ],
              [
                -49.470910171359904,
                -8.647703460814409
              ],
              [
                -49.476188145263706,
                -8.660692903521719
              ],
              [
                -49.48132558158555,
                -8.673337310240125
              ],
              [
                -49.48935000493469,
                -8.691641210345074
              ],
              [
                -49.542993005708986,
                -8.761000330223938
              ],
              [
                -49.56373931637344,
                -8.799147113396701
              ],
              [
                -49.56759178494218,
                -8.805022589722725
              ],
              [
                -49.56890027792128,
                -8.806154075282205
              ],
              [
                -49.56949976187196,
                -8.806672600788964
              ],
              [
                -49.58413317138451,
                -8.822928508845715
              ],
              [
                -49.58804511890015,
                -8.831011949718842
              ],
              [
                -49.59195806839951,
                -8.839095895606766
              ],
              [
                -49.61221442747483,
                -8.845834383850386
              ],
              [
                -49.64104270054842,
                -8.84632139052068
              ],
              [
                -49.68390286001912,
                -8.85607888477978
              ],
              [
                -49.72874900772925,
                -8.898936195938981
              ],
              [
                -49.73669194498327,
                -8.902352676385771
              ],
              [
                -49.74463386425175,
                -8.905768134847511
              ],
              [
                -49.753241268550084,
                -8.920137058142705
              ],
              [
                -49.754098286513724,
                -8.921567566410118
              ],
              [
                -49.76226219835705,
                -8.929831533546428
              ],
              [
                -49.77042715318883,
                -8.938095998708507
              ],
              [
                -49.79320742592565,
                -8.979036256816935
              ],
              [
                -49.84213550715006,
                -9.026931524281828
              ],
              [
                -49.84988044054351,
                -9.04631991656085
              ],
              [
                -49.84992393927619,
                -9.047924881848664
              ],
              [
                -49.850212410310235,
                -9.058502841848435
              ],
              [
                -49.859743322679485,
                -9.079343713577323
              ],
              [
                -49.87082622840899,
                -9.103575089691734
              ],
              [
                -49.89642196611502,
                -9.143274865670334
              ],
              [
                -49.89670495776962,
                -9.143486369730404
              ],
              [
                -49.90959834590713,
                -9.15312631690961
              ],
              [
                -49.90701287116565,
                -9.159467267329658
              ],
              [
                -49.9047948658223,
                -9.164905746667626
              ],
              [
                -49.90723786632755,
                -9.173426693991127
              ],
              [
                -49.943017057319445,
                -9.20048906568202
              ],
              [
                -49.95768843369463,
                -9.220340467506853
              ],
              [
                -49.96569135720629,
                -9.230466407074946
              ],
              [
                -49.99359963974707,
                -9.233699919730347
              ],
              [
                -50.00454854603535,
                -9.268362200869259
              ],
              [
                -50.011605962336645,
                -9.274588176985452
              ],
              [
                -50.01866441562844,
                -9.280815144114403
              ],
              [
                -50.01992541465973,
                -9.281368654329908
              ],
              [
                -50.0373297510886,
                -9.289005633741693
              ],
              [
                -50.043813694594306,
                -9.30083754343141
              ],
              [
                -50.05029764108269,
                -9.312669516159456
              ],
              [
                -50.05535006619098,
                -9.341912325326465
              ],
              [
                -50.05237856677354,
                -9.372272634926277
              ],
              [
                -50.06507645081669,
                -9.400401473608955
              ],
              [
                -50.089656201013305,
                -9.431197325368297
              ],
              [
                -50.10488003325358,
                -9.47501008262337
              ],
              [
                -50.10096705317778,
                -9.499883938569235
              ],
              [
                -50.10527151289013,
                -9.50272942595362
              ],
              [
                -50.10741148634727,
                -9.504143913181515
              ],
              [
                -50.10749249751017,
                -9.508230377797862
              ],
              [
                -50.09834403692192,
                -9.519788286974665
              ],
              [
                -50.09371606843502,
                -9.525634762087076
              ],
              [
                -50.09140956175302,
                -9.535465688268987
              ],
              [
                -50.08910307506152,
                -9.545294622469278
              ],
              [
                -50.099935485348226,
                -9.561635529381892
              ],
              [
                -50.1068764218001,
                -9.572105980546493
              ],
              [
                -50.10689340960166,
                -9.582992436434134
              ],
              [
                -50.10691140237847,
                -9.593879342335399
              ],
              [
                -50.119683804012446,
                -9.606588775646514
              ],
              [
                -50.12627271925884,
                -9.619163725916655
              ],
              [
                -50.12943920134013,
                -9.621200210800442
              ],
              [
                -50.13854763078539,
                -9.627059704458558
              ],
              [
                -50.13941508531703,
                -9.649404563147076
              ],
              [
                -50.139598077844305,
                -9.654104038329294
              ],
              [
                -50.14465402149388,
                -9.664215463480483
              ],
              [
                -50.14970898413925,
                -9.674325445665913
              ],
              [
                -50.149978492371474,
                -9.681044366936769
              ],
              [
                -50.1505474486452,
                -9.69520828420645
              ],
              [
                -50.15513740811421,
                -9.697347777372263
              ],
              [
                -50.158658387093176,
                -9.698988279136417
              ],
              [
                -50.15988888401522,
                -9.706640750114461
              ],
              [
                -50.160818868589665,
                -9.712425201844963
              ],
              [
                -50.19743402406165,
                -9.758432959383294
              ],
              [
                -50.203325423368305,
                -9.790512299315166
              ],
              [
                -50.208630381625035,
                -9.80166623295525
              ],
              [
                -50.21393384087694,
                -9.812819657628399
              ],
              [
                -50.21497234110629,
                -9.819701624116915
              ],
              [
                -50.21715428884292,
                -9.834164546105928
              ],
              [
                -50.22143825916464,
                -9.838087513433198
              ],
              [
                -50.22434624526551,
                -9.84075151043438
              ],
              [
                -50.842426727638255,
                -9.800423491757034
              ],
              [
                -51.0488192311749,
                -9.787487823786805
              ],
              [
                -51.3040273978987,
                -9.771900719060582
              ],
              [
                -51.95166576889913,
                -9.727974760369548
              ],
              [
                -52.25487012464267,
                -9.707410273027435
              ],
              [
                -52.35910089242756,
                -9.70011491569416
              ],
              [
                -52.61949306952599,
                -9.681804860613603
              ],
              [
                -53.13289797187215,
                -9.64446170589572
              ],
              [
                -53.33539858085564,
                -9.6294400148806
              ],
              [
                -53.58795885070923,
                -9.610703954880599
              ],
              [
                -54.10612130476595,
                -9.571526307794342
              ],
              [
                -54.335549724328445,
                -9.554878180044284
              ],
              [
                -54.61319434109821,
                -9.534731623596825
              ],
              [
                -54.87947708886195,
                -9.514011601703615
              ],
              [
                -54.9950582787993,
                -9.505017778012304
              ],
              [
                -55.79158201116239,
                -9.440277636395384
              ],
              [
                -56.15601161213136,
                -9.410187769548388
              ],
              [
                -56.273302348932845,
                -9.400423447692962
              ],
              [
                -56.67136979079436,
                -9.367007138195666
              ],
              [
                -56.71078850532014,
                -9.373896633252631
              ],
              [
                -56.714303978081176,
                -9.378699122631154
              ],
              [
                -56.717818453846355,
                -9.383501603016308
              ],
              [
                -56.73517585033338,
                -9.39513358273347
              ],
              [
                -56.753756688107536,
                -9.405973519246428
              ],
              [
                -56.76059467921403,
                -9.404609036620695
              ],
              [
                -56.771809110032386,
                -9.39056913944257
              ],
              [
                -56.76947363656147,
                -9.380137680247307
              ],
              [
                -56.76909062740356,
                -9.378426187421004
              ],
              [
                -56.77311763629521,
                -9.369340251158235
              ],
              [
                -56.77508610988515,
                -9.364898289451263
              ],
              [
                -56.775063107679436,
                -9.354384835885574
              ],
              [
                -56.77500767313463,
                -9.329139962247599
              ],
              [
                -56.778652155915005,
                -9.316808013002756
              ],
              [
                -56.78152562194165,
                -9.316505536473173
              ],
              [
                -56.78467312025655,
                -9.316173539468618
              ],
              [
                -56.79358708546888,
                -9.292158691890176
              ],
              [
                -56.80290107354071,
                -9.267064797361902
              ],
              [
                -56.81124753370931,
                -9.256508893477118
              ],
              [
                -56.819593973874206,
                -9.245952963581768
              ],
              [
                -56.84367533956668,
                -9.240416506478407
              ],
              [
                -56.85982024001656,
                -9.231257572010083
              ],
              [
                -56.86756717787901,
                -9.236099038891576
              ],
              [
                -56.8718476408621,
                -9.238774547094845
              ],
              [
                -56.93767222785765,
                -9.230042691874322
              ],
              [
                -56.94470269908957,
                -9.226369221073453
              ],
              [
                -56.951734163321994,
                -9.222695249271496
              ],
              [
                -56.99503386567188,
                -9.233453243520934
              ],
              [
                -57.00770279651058,
                -9.2229257850991
              ],
              [
                -57.01134676057271,
                -9.222012809675965
              ],
              [
                -57.022122701710366,
                -9.219312338996215
              ],
              [
                -57.033531629828616,
                -9.209346418976525
              ],
              [
                -57.03597261637164,
                -9.203472931045821
              ],
              [
                -57.03881061269845,
                -9.196642999499707
              ],
              [
                -57.053438009171934,
                -9.187539039439647
              ],
              [
                -57.057310508674654,
                -9.183803082608746
              ],
              [
                -57.05786550214092,
                -9.182348100730994
              ],
              [
                -57.059252478310775,
                -9.178709108043735
              ],
              [
                -57.05736653355093,
                -9.173415146316799
              ],
              [
                -57.05190207727683,
                -9.158074702690506
              ],
              [
                -57.05591557733174,
                -9.147408749184628
              ],
              [
                -57.05992854637554,
                -9.13674333767708
              ],
              [
                -57.047743657138874,
                -9.122572890171249
              ],
              [
                -57.04098070283933,
                -9.114707412008071
              ],
              [
                -57.03872821898967,
                -9.09790400231526
              ],
              [
                -57.04581720852946,
                -9.08860753803502
              ],
              [
                -57.055750158684674,
                -9.082488592414471
              ],
              [
                -57.062731620142735,
                -9.078188146598533
              ],
              [
                -57.061571101689815,
                -9.07363014419424
              ],
              [
                -57.059090663631146,
                -9.063890195355025
              ],
              [
                -57.0737460588664,
                -9.054752272348185
              ],
              [
                -57.078136043677105,
                -9.052015764107635
              ],
              [
                -57.09050549344504,
                -9.033249410923682
              ],
              [
                -57.092296964201886,
                -9.024987947278039
              ],
              [
                -57.09408846094896,
                -9.016726475636561
              ],
              [
                -57.13775823709014,
                -8.994226645937065
              ],
              [
                -57.19234145757808,
                -8.929665571961856
              ],
              [
                -57.20202841315064,
                -8.922983103660664
              ],
              [
                -57.211715826728856,
                -8.91630117334541
              ],
              [
                -57.24812264110041,
                -8.90600473652711
              ],
              [
                -57.285679374565895,
                -8.91511026484033
              ],
              [
                -57.29906879197761,
                -8.913610293086792
              ],
              [
                -57.305513743724475,
                -8.909518822298502
              ],
              [
                -57.30814673751619,
                -8.899442337791877
              ],
              [
                -57.30998024327337,
                -8.892425903739577
              ],
              [
                -57.31453621721505,
                -8.886627930096164
              ],
              [
                -57.349066511572694,
                -8.88039601803033
              ],
              [
                -57.380234299568976,
                -8.880738533265768
              ],
              [
                -57.40290166242412,
                -8.862137161079097
              ],
              [
                -57.4071586235518,
                -8.860670692078155
              ],
              [
                -57.4161680859189,
                -8.857568216579235
              ],
              [
                -57.41995458612847,
                -8.849228756690582
              ],
              [
                -57.41275714445052,
                -8.825804873700134
              ],
              [
                -57.41561517643182,
                -8.795494015790648
              ],
              [
                -57.42133914870931,
                -8.789173584234456
              ],
              [
                -57.42706212399174,
                -8.782854110666968
              ],
              [
                -57.450563951898005,
                -8.786037117152658
              ],
              [
                -57.45198046173392,
                -8.7862286180285
              ],
              [
                -57.45749641926359,
                -8.78529315594078
              ],
              [
                -57.46301239379641,
                -8.784358664847236
              ],
              [
                -57.48433974588283,
                -8.768998226241433
              ],
              [
                -57.52986701167778,
                -8.747791420519468
              ],
              [
                -57.56184575078956,
                -8.760866881063478
              ],
              [
                -57.59241759630588,
                -8.756092454881053
              ],
              [
                -57.59647658586746,
                -8.748468482582023
              ],
              [
                -57.60053452543072,
                -8.740844032282247
              ],
              [
                -57.59309511730803,
                -8.70703472415832
              ],
              [
                -57.604781607129226,
                -8.662450957279956
              ],
              [
                -57.612748573441074,
                -8.654571513312193
              ],
              [
                -57.62003902812416,
                -8.647361552085261
              ],
              [
                -57.62422603744222,
                -8.63845660542616
              ],
              [
                -57.62337953564055,
                -8.622313176878416
              ],
              [
                -57.62172659666198,
                -8.590803822010498
              ],
              [
                -57.63033652921485,
                -8.585003883316949
              ],
              [
                -57.636025499389575,
                -8.583610889613832
              ],
              [
                -57.64426096043052,
                -8.581595415302122
              ],
              [
                -57.648119444492075,
                -8.575990931544567
              ],
              [
                -57.64998347237753,
                -8.552986560206971
              ],
              [
                -57.64414951709685,
                -8.546585084168946
              ],
              [
                -57.63831552981268,
                -8.540184600142391
              ],
              [
                -57.63316510837809,
                -8.523515210978655
              ],
              [
                -57.63514358759814,
                -8.51834524165711
              ],
              [
                -57.637122089814866,
                -8.513175238336087
              ],
              [
                -57.63898105563884,
                -8.512031785476715
              ],
              [
                -57.65853544962135,
                -8.50000334108558
              ],
              [
                -57.65994948340292,
                -8.492447386119109
              ],
              [
                -57.65534849666649,
                -8.484999417024309
              ],
              [
                -57.651310056245784,
                -8.478462477040734
              ],
              [
                -57.65089904773391,
                -8.470657985059113
              ],
              [
                -57.675470445208624,
                -8.439774687405855
              ],
              [
                -57.68106489812793,
                -8.43274374504441
              ],
              [
                -57.68633239728586,
                -8.41367734825542
              ],
              [
                -57.68603739702223,
                -8.406626395624144
              ],
              [
                -57.67188703368156,
                -8.38103746973999
              ],
              [
                -57.6691560379594,
                -8.376098531401626
              ],
              [
                -57.66825405118681,
                -8.370850022917768
              ],
              [
                -57.66863654455727,
                -8.36306659090492
              ],
              [
                -57.673645531215705,
                -8.34791816004084
              ],
              [
                -57.678539030128604,
                -8.3331202652592
              ],
              [
                -57.67869205773442,
                -8.325461269352065
              ],
              [
                -57.678845532329625,
                -8.317801322457093
              ],
              [
                -57.672449087088005,
                -8.307544383676246
              ],
              [
                -57.66431567115624,
                -8.294501421954235
              ],
              [
                -57.66302570560732,
                -8.280500031586188
              ],
              [
                -57.661735709026736,
                -8.266499589242455
              ],
              [
                -57.6516702933233,
                -8.246800662185656
              ],
              [
                -57.64470487970504,
                -8.233167738111426
              ],
              [
                -57.64372838305745,
                -8.223230285566494
              ],
              [
                -57.642751404396755,
                -8.213293350033148
              ],
              [
                -57.666729301160096,
                -8.176086538972369
              ],
              [
                -57.671728255026366,
                -8.173550575980917
              ],
              [
                -57.67672722189509,
                -8.171015084984456
              ],
              [
                -57.67865020617024,
                -8.170778610526709
              ],
              [
                -57.70411755894542,
                -8.167644624443364
              ],
              [
                -57.71461648472873,
                -8.157566712540724
              ],
              [
                -57.71933900010988,
                -8.146223283066918
              ],
              [
                -57.72791999130649,
                -8.093836074516059
              ],
              [
                -57.77600924232847,
                -8.051796341696603
              ],
              [
                -57.80347812290048,
                -8.003045101152482
              ],
              [
                -57.82831346288392,
                -7.972516286424041
              ],
              [
                -57.83025249341224,
                -7.962813378221409
              ],
              [
                -57.83219197592316,
                -7.953111415020444
              ],
              [
                -57.832991008628944,
                -7.928186550178699
              ],
              [
                -57.834460554786844,
                -7.88237177693009
              ],
              [
                -57.837456029715824,
                -7.871735842807486
              ],
              [
                -57.84045101463203,
                -7.861100407686151
              ],
              [
                -57.873316866121485,
                -7.800817268315227
              ],
              [
                -57.89762635337233,
                -7.675645462312994
              ],
              [
                -57.90471334323043,
                -7.666214507769163
              ],
              [
                -57.91566977086513,
                -7.656967051697228
              ],
              [
                -57.93152565407911,
                -7.656470073361358
              ],
              [
                -57.93386616464756,
                -7.656397094409777
              ],
              [
                -57.93645113270539,
                -7.654578109682249
              ],
              [
                -57.93903813475244,
                -7.652758106961326
              ],
              [
                -57.944225656087845,
                -7.605121884021026
              ],
              [
                -57.97206204608489,
                -7.53421277524872
              ],
              [
                -58.00164488625663,
                -7.487753024908657
              ],
              [
                -58.03458223658803,
                -7.453763768147384
              ],
              [
                -58.058516631521265,
                -7.400026554612722
              ],
              [
                -58.062383607691366,
                -7.395464609318037
              ],
              [
                -58.066250101864675,
                -7.390902123023101
              ],
              [
                -58.11950828166413,
                -7.35240136840685
              ],
              [
                -58.13265119954096,
                -7.349730414570724
              ],
              [
                -58.13791919823442,
                -7.348659912857028
              ],
              [
                -58.13712368893835,
                -7.352466411455441
              ],
              [
                -58.136364688613405,
                -7.356093369015849
              ],
              [
                -58.139136686541505,
                -7.352451376747789
              ],
              [
                -58.1464371281144,
                -7.342857448986973
              ],
              [
                -58.16948550008844,
                -7.312848154202917
              ],
              [
                -58.174291020258785,
                -7.271053862924469
              ],
              [
                -58.16756563334839,
                -7.23096057457013
              ],
              [
                -58.18279908592653,
                -7.177899852826617
              ],
              [
                -58.19914946948512,
                -7.155399982114651
              ],
              [
                -58.204074950720965,
                -7.144644574719197
              ],
              [
                -58.20900046194447,
                -7.133888637324872
              ],
              [
                -58.21703740398238,
                -7.127834163061423
              ],
              [
                -58.22225685708429,
                -7.123902169862774
              ],
              [
                -58.225782365324136,
                -7.116493218739802
              ],
              [
                -58.22930785555764,
                -7.109084781613135
              ],
              [
                -58.24536424977857,
                -7.088509890556332
              ],
              [
                -58.2523132328785,
                -7.083050937023491
              ],
              [
                -58.258090702897995,
                -7.081618453527162
              ],
              [
                -58.26805314231369,
                -7.079149454802394
              ],
              [
                -58.28773401939917,
                -7.06130308244346
              ],
              [
                -58.326344823592315,
                -6.999657959198647
              ],
              [
                -58.33230480950938,
                -6.991604002882531
              ],
              [
                -58.33826379542914,
                -6.983550028559363
              ],
              [
                -58.355087181162226,
                -6.971287609247928
              ],
              [
                -58.368074100217356,
                -6.970679145968113
              ],
              [
                -58.37972954538792,
                -6.970133169973487
              ],
              [
                -58.38603599220028,
                -6.964945205233235
              ],
              [
                -58.393003482182465,
                -6.950950273436747
              ],
              [
                -58.39361248173182,
                -6.949727793355449
              ],
              [
                -58.40004644639371,
                -6.944893312915368
              ],
              [
                -58.40648038405977,
                -6.940058338471819
              ],
              [
                -58.434031742434044,
                -6.908435523632333
              ],
              [
                -58.462363654202626,
                -6.836907959593417
              ],
              [
                -58.46939212678598,
                -6.804243120834033
              ],
              [
                -58.47531208848502,
                -6.792647192556127
              ],
              [
                -58.481231566173356,
                -6.781051253273709
              ],
              [
                -58.477948700196954,
                -6.699242171402188
              ],
              [
                -58.46467477900468,
                -6.669672839625304
              ],
              [
                -58.456844365173865,
                -6.661361362693524
              ],
              [
                -58.449012888347056,
                -6.653049410791556
              ],
              [
                -58.4267825923789,
                -6.615798092231726
              ],
              [
                -58.370290462035754,
                -6.574361766941538
              ],
              [
                -58.34936362033627,
                -6.562029810303855
              ],
              [
                -58.33917066840998,
                -6.54817086825453
              ],
              [
                -58.33178523291339,
                -6.532630455766413
              ],
              [
                -58.32706228320339,
                -6.508769562225988
              ],
              [
                -58.325113326754554,
                -6.498924598544116
              ],
              [
                -58.322294333122976,
                -6.496776611972229
              ],
              [
                -58.31947533349171,
                -6.494628627402756
              ],
              [
                -58.29258604741675,
                -6.493514108998912
              ],
              [
                -58.2720331887655,
                -6.48089066377876
              ],
              [
                -58.26703218444147,
                -6.474722206083145
              ],
              [
                -58.262031250113225,
                -6.468554726396585
              ],
              [
                -58.1517191277412,
                -6.229921924575374
              ],
              [
                -57.860868479183814,
                -5.60074160854476
              ],
              [
                -57.54757147313159,
                -4.921232684713111
              ],
              [
                -57.0184010375009,
                -3.797953320301577
              ],
              [
                -56.95594099151714,
                -3.657809608339279
              ],
              [
                -56.93983364134262,
                -3.621644332333255
              ],
              [
                -56.9132458454288,
                -3.561948159510044
              ],
              [
                -56.88672900944428,
                -3.498697003105335
              ],
              [
                -56.809408113793836,
                -3.33277147354667
              ],
              [
                -56.77677685793867,
                -3.262701873345802
              ],
              [
                -56.76737442902434,
                -3.242511987180984
              ],
              [
                -56.4871169808857,
                -2.632617553559015
              ],
              [
                -56.43269235516656,
                -2.516019206974783
              ],
              [
                -56.401654088619495,
                -2.456276585179692
              ],
              [
                -56.407426045103286,
                -2.449825629683165
              ],
              [
                -56.417853976827516,
                -2.449211635088669
              ],
              [
                -56.424925926890175,
                -2.448795140110681
              ],
              [
                -56.43517588825178,
                -2.441201196002018
              ],
              [
                -56.43695086440555,
                -2.439886197466692
              ],
              [
                -56.43962433057961,
                -2.439289199978206
              ],
              [
                -56.454810763020845,
                -2.435896731605605
              ],
              [
                -56.463980688252605,
                -2.431252754517755
              ],
              [
                -56.46509020317358,
                -2.422774812363258
              ],
              [
                -56.42741844890178,
                -2.371884593244472
              ],
              [
                -56.42514496644977,
                -2.368813113767853
              ],
              [
                -56.42155597872415,
                -2.361831167160228
              ],
              [
                -56.417966518996096,
                -2.354849184565588
              ],
              [
                -56.412251558786885,
                -2.321375407375841
              ],
              [
                -56.38874321512089,
                -2.27818316249241
              ],
              [
                -56.383811758235616,
                -2.270134715972051
              ],
              [
                -56.36370088024324,
                -2.2589472512188
              ],
              [
                -56.33113311878269,
                -2.251024293886764
              ],
              [
                -56.262995061163316,
                -2.220715954314737
              ],
              [
                -56.21979436272182,
                -2.19550260982966
              ],
              [
                -56.19144252508018,
                -2.170958754427964
              ],
              [
                -56.168924192450035,
                -2.145167403649434
              ],
              [
                -56.141424869686986,
                -2.099873683245466
              ],
              [
                -56.10191368187646,
                -2.047681497539639
              ],
              [
                -56.097053202292976,
                -2.036796037917544
              ],
              [
                -56.097871201276156,
                -2.029182585212289
              ],
              [
                -56.0981362002957,
                -2.026720596183932
              ],
              [
                -56.14458287867452,
                -2.038866046565147
              ],
              [
                -56.146284363683634,
                -2.039131066471544
              ],
              [
                -56.16224878557956,
                -2.041620531200482
              ],
              [
                -56.162437270386945,
                -2.041567039579763
              ],
              [
                -56.17202571172196,
                -2.03884855085502
              ],
              [
                -56.18620011180938,
                -2.042482543152583
              ],
              [
                -56.19348057536813,
                -2.048547013739872
              ],
              [
                -56.19458657342852,
                -2.051427500694552
              ],
              [
                -56.196565533219186,
                -2.056582952191585
              ],
              [
                -56.20713896358015,
                -2.059642445925712
              ],
              [
                -56.22787233993809,
                -2.055882970834838
              ],
              [
                -56.25565066054071,
                -2.087978298944122
              ],
              [
                -56.267910060399565,
                -2.090354775392968
              ],
              [
                -56.28016900728195,
                -2.092731776848315
              ],
              [
                -56.29695237763642,
                -2.100467735482909
              ],
              [
                -56.298717870942646,
                -2.105095700205466
              ],
              [
                -56.29893384302706,
                -2.110125646037603
              ],
              [
                -56.29930835996559,
                -2.118837618793786
              ],
              [
                -56.306590310948415,
                -2.120394096703031
              ],
              [
                -56.307949787171566,
                -2.120684590380191
              ],
              [
                -56.31041080170479,
                -2.126343554149274
              ],
              [
                -56.3162572571949,
                -2.139786489239716
              ],
              [
                -56.320854697947304,
                -2.142607970741784
              ],
              [
                -56.32545217370144,
                -2.145428948251658
              ],
              [
                -56.350645515850324,
                -2.148530923568089
              ],
              [
                -56.36912838440495,
                -2.137170995513866
              ],
              [
                -56.38153281618013,
                -2.143864968271703
              ],
              [
                -56.386245796050666,
                -2.150493953022152
              ],
              [
                -56.39219972545677,
                -2.158867399727448
              ],
              [
                -56.413377612149574,
                -2.175609780192779
              ],
              [
                -56.423781539231726,
                -2.171165336116014
              ],
              [
                -56.429867983567036,
                -2.164406849505122
              ],
              [
                -56.436308961765874,
                -2.157253890360666
              ],
              [
                -56.443675420980234,
                -2.153583938656005
              ],
              [
                -56.460537779440216,
                -2.157215929262493
              ],
              [
                -56.46813726572106,
                -2.16463837328315
              ],
              [
                -56.47023374372918,
                -2.16668585770727
              ],
              [
                -56.47738817612676,
                -2.170037327949341
              ],
              [
                -56.50189901403242,
                -2.148049996146017
              ],
              [
                -56.5071239782529,
                -2.143363023805006
              ],
              [
                -56.51897093357843,
                -2.137965538786277
              ],
              [
                -56.526558886049564,
                -2.138856043850792
              ],
              [
                -56.53962126367667,
                -2.152739468055591
              ],
              [
                -56.54033628289382,
                -2.153499456744435
              ],
              [
                -56.54852170550433,
                -2.157851921356713
              ],
              [
                -56.55670615512742,
                -2.162205414975335
              ],
              [
                -56.56908806861262,
                -2.174418348068974
              ],
              [
                -56.57990402844318,
                -2.179064820826205
              ],
              [
                -56.58814444955315,
                -2.17451784257072
              ],
              [
                -56.58884394523364,
                -2.174131846095354
              ],
              [
                -56.591687446127764,
                -2.174294869033153
              ],
              [
                -56.597283908495754,
                -2.174616374907282
              ],
              [
                -56.601957387576554,
                -2.181726825034838
              ],
              [
                -56.60363734149232,
                -2.189343750196752
              ],
              [
                -56.60701034019731,
                -2.20463667420388
              ],
              [
                -56.61240628628396,
                -2.209830632030996
              ],
              [
                -56.62091872861664,
                -2.205628182813981
              ],
              [
                -56.62392471702023,
                -2.204144174623008
              ],
              [
                -56.6349186625612,
                -2.203259187456025
              ],
              [
                -56.65610299611763,
                -2.209817148324757
              ],
              [
                -56.65750000712168,
                -2.210250166204251
              ],
              [
                -56.66803292103702,
                -2.211237641761644
              ],
              [
                -56.67856486597452,
                -2.212225124322311
              ],
              [
                -56.70206072160539,
                -2.201414211201873
              ],
              [
                -56.705319684543504,
                -2.190227788721032
              ],
              [
                -56.70726069137441,
                -2.183563318947984
              ],
              [
                -56.725911559466084,
                -2.176734863510468
              ],
              [
                -56.743489467289045,
                -2.176546888266209
              ],
              [
                -56.75108991223172,
                -2.176465388188731
              ],
              [
                -56.76045434687136,
                -2.173717914501298
              ],
              [
                -56.76803180357842,
                -2.165412457223081
              ],
              [
                -56.768922302815625,
                -2.140333127397625
              ],
              [
                -56.75273589316231,
                -2.111337797585167
              ],
              [
                -56.73225804082374,
                -2.091399376804406
              ],
              [
                -56.72289812545519,
                -2.066981057252488
              ],
              [
                -56.721860647753914,
                -2.057884604095193
              ],
              [
                -56.72082263204397,
                -2.048788657946799
              ],
              [
                -56.726094630072055,
                -2.030621253739239
              ],
              [
                -56.73006057729445,
                -2.026148291569073
              ],
              [
                -56.73402604651949,
                -2.021675303395984
              ],
              [
                -56.747374980152,
                -2.01116437996903
              ],
              [
                -56.75693090412167,
                -2.009761920153413
              ],
              [
                -56.766486364107244,
                -2.008360433328433
              ],
              [
                -56.79270020080029,
                -2.020657330941756
              ],
              [
                -56.83332791803414,
                -2.021191867878482
              ],
              [
                -56.843771840368035,
                -2.020310869227775
              ],
              [
                -56.858536240587775,
                -2.015587391682268
              ],
              [
                -56.87579166063168,
                -1.992009556815616
              ],
              [
                -56.896865006428186,
                -1.975885128743066
              ],
              [
                -56.90470198317889,
                -1.971902191676288
              ],
              [
                -56.912537929943,
                -1.967919217602212
              ],
              [
                -56.92865083436463,
                -1.964663708605912
              ],
              [
                -56.95226118005339,
                -1.959892748758441
              ],
              [
                -56.95643812979685,
                -1.956263767491686
              ],
              [
                -56.96061710052849,
                -1.952634280225581
              ],
              [
                -56.9622781097144,
                -1.946874343867063
              ],
              [
                -56.96581407693549,
                -1.934615401598057
              ],
              [
                -56.974082042803744,
                -1.927893973829739
              ],
              [
                -56.97970151373271,
                -1.923326484521881
              ],
              [
                -56.98248299781691,
                -1.917686010767998
              ],
              [
                -56.98784143914549,
                -1.906821599728514
              ],
              [
                -57.021965734615186,
                -1.913891049456293
              ],
              [
                -57.02611820427737,
                -1.914751561533222
              ],
              [
                -57.0314081735298,
                -1.912983571506941
              ],
              [
                -57.0366986607826,
                -1.911216072475508
              ],
              [
                -57.0483450816393,
                -1.88230224005968
              ],
              [
                -57.05555652525193,
                -1.864400867147027
              ],
              [
                -57.06155848020579,
                -1.858365918567418
              ],
              [
                -57.067560460159584,
                -1.852331438978551
              ],
              [
                -57.07306044028926,
                -1.831333063936339
              ],
              [
                -57.06967544363889,
                -1.827219609405308
              ],
              [
                -57.059977030668385,
                -1.815433660682495
              ],
              [
                -57.06339599212895,
                -1.808158711751982
              ],
              [
                -57.070028461030375,
                -1.802324245132571
              ],
              [
                -57.07891790362062,
                -1.800850740242946
              ],
              [
                -57.083952350952266,
                -1.793520290054954
              ],
              [
                -57.08481284564186,
                -1.781704878359825
              ],
              [
                -57.097345294813465,
                -1.774767407806616
              ],
              [
                -57.10585525137656,
                -1.782665876215157
              ],
              [
                -57.115315644749366,
                -1.791446813673286
              ],
              [
                -57.12886707896977,
                -1.763828005925642
              ],
              [
                -57.14868095926222,
                -1.761999993934009
              ],
              [
                -57.16270238380697,
                -1.768244485616575
              ],
              [
                -57.16112838724371,
                -1.758261533326431
              ],
              [
                -57.160729392393705,
                -1.755732044204856
              ],
              [
                -57.162894369163986,
                -1.754442555405903
              ],
              [
                -57.17130681876148,
                -1.749432089266239
              ],
              [
                -57.168191831917284,
                -1.742988612624321
              ],
              [
                -57.16128686479576,
                -1.728706227468686
              ],
              [
                -57.16438987342328,
                -1.7205342766921
              ],
              [
                -57.17526628050213,
                -1.727690731067115
              ],
              [
                -57.178483274822604,
                -1.729808202348065
              ],
              [
                -57.184936217929454,
                -1.723846734312052
              ],
              [
                -57.187049733191245,
                -1.721893748419825
              ],
              [
                -57.188762721123304,
                -1.723260257763615
              ],
              [
                -57.193946675630066,
                -1.727394220608221
              ],
              [
                -57.20069515234914,
                -1.72361724269925
              ],
              [
                -57.201136649155224,
                -1.721362257163758
              ],
              [
                -57.203240600691345,
                -1.710612845352017
              ],
              [
                -57.21517006578147,
                -1.715673809418314
              ],
              [
                -57.21775051785297,
                -1.718797784584029
              ],
              [
                -57.22163401237552,
                -1.723497759759461
              ],
              [
                -57.230234451058145,
                -1.725585238608119
              ],
              [
                -57.2443678710643,
                -1.720130791487055
              ],
              [
                -57.248667827178046,
                -1.712204340466443
              ],
              [
                -57.248054319245064,
                -1.711279824779242
              ],
              [
                -57.243199883424225,
                -1.70395887085992
              ],
              [
                -57.25338479275162,
                -1.695961946742767
              ],
              [
                -57.26170226160185,
                -1.69664240881149
              ],
              [
                -57.25850776803843,
                -1.706769870105592
              ],
              [
                -57.26003974378232,
                -1.709494353381596
              ],
              [
                -57.263971749455244,
                -1.716487304032135
              ],
              [
                -57.272000173828474,
                -1.718331809174805
              ],
              [
                -57.273124166143695,
                -1.713015825884433
              ],
              [
                -57.273888674274765,
                -1.709400349451424
              ],
              [
                -57.29709950688137,
                -1.711116856200841
              ],
              [
                -57.30105001184711,
                -1.720531281594831
              ],
              [
                -57.30499995780545,
                -1.729945239012459
              ],
              [
                -57.32071188128597,
                -1.71988231845011
              ],
              [
                -57.327452326314884,
                -1.715565335654099
              ],
              [
                -57.34655671661635,
                -1.713686835606949
              ],
              [
                -57.34522471392223,
                -1.717663308805301
              ],
              [
                -57.34350972432509,
                -1.722785789144576
              ],
              [
                -57.3515556835999,
                -1.732831716952999
              ],
              [
                -57.37259303826938,
                -1.713774372721399
              ],
              [
                -57.39217641397982,
                -1.722738292885511
              ],
              [
                -57.400533352562654,
                -1.712274375678355
              ],
              [
                -57.39044841786627,
                -1.706369919913674
              ],
              [
                -57.38909193162235,
                -1.701153439321446
              ],
              [
                -57.38747944521464,
                -1.694952952471746
              ],
              [
                -57.39564789808763,
                -1.691894481560751
              ],
              [
                -57.3971158980799,
                -1.691344988169442
              ],
              [
                -57.40367283015452,
                -1.68362155234631
              ],
              [
                -57.40343833428933,
                -1.680892059379033
              ],
              [
                -57.402949862736435,
                -1.675191590866506
              ],
              [
                -57.41142380951764,
                -1.672947109208873
              ],
              [
                -57.41595128100825,
                -1.678307555739356
              ],
              [
                -57.42706018559588,
                -1.691462015011632
              ],
              [
                -57.43942163348478,
                -1.688153041609066
              ],
              [
                -57.436412649303406,
                -1.679689552705952
              ],
              [
                -57.434924152598185,
                -1.675504573988669
              ],
              [
                -57.43815815176895,
                -1.66615165189749
              ],
              [
                -57.45576000489873,
                -1.671698129596967
              ],
              [
                -57.474691389699245,
                -1.677664581190139
              ],
              [
                -57.481804365811946,
                -1.664094658825229
              ],
              [
                -57.481240872028785,
                -1.661173697878481
              ],
              [
                -57.48006437482919,
                -1.655074736490184
              ],
              [
                -57.492251812736484,
                -1.656470223280107
              ],
              [
                -57.49313077924944,
                -1.656570720905426
              ],
              [
                -57.498560772254415,
                -1.653496249335557
              ],
              [
                -57.501142744566344,
                -1.652034256573745
              ],
              [
                -57.50666470694722,
                -1.640928830276446
              ],
              [
                -57.51539864157348,
                -1.645559280169159
              ],
              [
                -57.528274073956666,
                -1.652385743181202
              ],
              [
                -57.52660056228035,
                -1.640278842970838
              ],
              [
                -57.52468661149207,
                -1.626432901956073
              ],
              [
                -57.53872101005069,
                -1.62352194347692
              ],
              [
                -57.54517446116616,
                -1.610179504512161
              ],
              [
                -57.55295142115384,
                -1.60488156553722
              ],
              [
                -57.557426388388414,
                -1.604868545683796
              ],
              [
                -57.56361335400541,
                -1.604851534263189
              ],
              [
                -57.58539122304366,
                -1.595878112212093
              ],
              [
                -57.59287015985364,
                -1.599359600531684
              ],
              [
                -57.595204158792725,
                -1.589096650399753
              ],
              [
                -57.59370915164435,
                -1.586600682715815
              ],
              [
                -57.5896146756225,
                -1.57976521793113
              ],
              [
                -57.60206959068492,
                -1.569962765480519
              ],
              [
                -57.609647058649806,
                -1.574594759032187
              ],
              [
                -57.61094705274419,
                -1.582551702582191
              ],
              [
                -57.65230977522197,
                -1.588227183034113
              ],
              [
                -57.65320579164616,
                -1.58835015352472
              ],
              [
                -57.653231303502054,
                -1.588291175876856
              ],
              [
                -57.65654377081523,
                -1.580499231389368
              ],
              [
                -57.66866119505007,
                -1.588067184847004
              ],
              [
                -57.6781651098507,
                -1.59400266428969
              ],
              [
                -57.69364355031949,
                -1.575954246789198
              ],
              [
                -57.70633896389204,
                -1.575619753736371
              ],
              [
                -57.70265995852336,
                -1.563155339954538
              ],
              [
                -57.70208347653804,
                -1.561200855281628
              ],
              [
                -57.70467296587704,
                -1.546575445659975
              ],
              [
                -57.70829893174198,
                -1.540937473565841
              ],
              [
                -57.71192441660724,
                -1.535299506469092
              ],
              [
                -57.712025908975484,
                -1.530894535331847
              ],
              [
                -57.712650414172906,
                -1.5038146793697
              ],
              [
                -57.72100236690674,
                -1.50670916026074
              ],
              [
                -57.72935379965275,
                -1.509603654159908
              ],
              [
                -57.73783777431046,
                -1.504760703476355
              ],
              [
                -57.744322222073706,
                -1.501058721643925
              ],
              [
                -57.74711322325209,
                -1.503686690858782
              ],
              [
                -57.750005202785886,
                -1.506409193545029
              ],
              [
                -57.760586134418986,
                -1.502020220653766
              ],
              [
                -57.77281152951241,
                -1.508465679626494
              ],
              [
                -57.783174468729776,
                -1.507669693625096
              ],
              [
                -57.78058950047228,
                -1.499603720301523
              ],
              [
                -57.78114397957574,
                -1.497227262350691
              ],
              [
                -57.78335348902646,
                -1.487754319354977
              ],
              [
                -57.789514948539704,
                -1.489877313285381
              ],
              [
                -57.79075594078852,
                -1.490305271054288
              ],
              [
                -57.79678291467777,
                -1.4854183160261
              ],
              [
                -57.80091785755569,
                -1.474496887928459
              ],
              [
                -57.80114237829987,
                -1.473903402455259
              ],
              [
                -57.80148488022438,
                -1.473732378532785
              ],
              [
                -57.80933882763676,
                -1.469801417291363
              ],
              [
                -57.810691318131944,
                -1.461313958314429
              ],
              [
                -57.812166300027236,
                -1.452053033901076
              ],
              [
                -57.82063426221226,
                -1.450188534662966
              ],
              [
                -57.823523730693765,
                -1.43942511035369
              ],
              [
                -57.83900663988328,
                -1.436485632904011
              ],
              [
                -57.84127412600116,
                -1.436054631915607
              ],
              [
                -57.84353909677238,
                -1.432615638545214
              ],
              [
                -57.845804605539634,
                -1.429175156182595
              ],
              [
                -57.87935087423714,
                -1.422931701895698
              ],
              [
                -57.89370230272889,
                -1.433198143887266
              ],
              [
                -57.90351922490255,
                -1.425692684891099
              ],
              [
                -57.91159967863854,
                -1.428296177390145
              ],
              [
                -57.91801066405864,
                -1.419473731348262
              ],
              [
                -57.91521416695901,
                -1.416139242255612
              ],
              [
                -57.90997818647305,
                -1.409896290665379
              ],
              [
                -57.920162150357456,
                -1.40253832785073
              ],
              [
                -57.93704501948265,
                -1.397982879031493
              ],
              [
                -57.94042250634661,
                -1.39978135222323
              ],
              [
                -57.95122992472278,
                -1.405535829855156
              ],
              [
                -57.959835871728096,
                -1.401240354766365
              ],
              [
                -57.95622292777015,
                -1.387462957535259
              ],
              [
                -57.9549804173764,
                -1.382723446984681
              ],
              [
                -57.96283737769532,
                -1.379796486720595
              ],
              [
                -57.976584269289525,
                -1.374676034483991
              ],
              [
                -57.97603129246304,
                -1.365908083614959
              ],
              [
                -57.97545029117925,
                -1.365177582766016
              ],
              [
                -57.970361331728554,
                -1.358774597159388
              ],
              [
                -57.98120877458178,
                -1.356384126339878
              ],
              [
                -57.98355824425705,
                -1.355866638841988
              ],
              [
                -57.99586966203637,
                -1.343987197764369
              ],
              [
                -58.00042063189684,
                -1.329841799355004
              ],
              [
                -57.99747566388744,
                -1.32008586180499
              ],
              [
                -57.993653672120686,
                -1.316139386126848
              ],
              [
                -57.99033971113266,
                -1.312717401487193
              ],
              [
                -57.990172211212084,
                -1.291688521472685
              ],
              [
                -57.97406182423162,
                -1.232301367175784
              ],
              [
                -57.96895087547862,
                -1.227611907717952
              ],
              [
                -57.96383991472619,
                -1.222922925267002
              ],
              [
                -57.96497387484304,
                -1.205752518152553
              ],
              [
                -57.97172486052946,
                -1.199246060523978
              ],
              [
                -57.97847531421988,
                -1.192739605886876
              ],
              [
                -57.97273936388116,
                -1.178870712209225
              ],
              [
                -57.970391357291135,
                -1.173192225109553
              ],
              [
                -57.97150784732488,
                -1.16223779558211
              ],
              [
                -57.97866529476404,
                -1.154071343745675
              ],
              [
                -58.00663962350054,
                -1.145394886464258
              ],
              [
                -58.01525060145957,
                -1.128754487528942
              ],
              [
                -58.00925461891545,
                -1.118751043202975
              ],
              [
                -58.017029583336345,
                -1.105818637379759
              ],
              [
                -58.03203499807168,
                -1.101020678009911
              ],
              [
                -58.041729415296366,
                -1.103009652945925
              ],
              [
                -58.081338666718885,
                -1.126150530960809
              ],
              [
                -58.08372714232772,
                -1.136654480865725
              ],
              [
                -58.081979678668134,
                -1.144728917299741
              ],
              [
                -58.0814496744996,
                -1.147175888885289
              ],
              [
                -58.09377759676939,
                -1.160175828946082
              ],
              [
                -58.10487852652968,
                -1.16171880085432
              ],
              [
                -58.1231689021341,
                -1.16426081318358
              ],
              [
                -58.121109914962524,
                -1.174490262032628
              ],
              [
                -58.118783910178564,
                -1.186039694125994
              ],
              [
                -58.12730639238018,
                -1.189688654325481
              ],
              [
                -58.155423693965076,
                -1.225545458099079
              ],
              [
                -58.15877615612179,
                -1.227434938694502
              ],
              [
                -58.162128655279325,
                -1.229324911289297
              ],
              [
                -58.174018086341874,
                -1.21417102585062
              ],
              [
                -58.180615534528165,
                -1.205763081085266
              ],
              [
                -58.18735197293379,
                -1.202568079935885
              ],
              [
                -58.19408944633855,
                -1.199372609784204
              ],
              [
                -58.214760349054245,
                -1.1653483268536
              ],
              [
                -58.24284914216408,
                -1.156269890678438
              ],
              [
                -58.25425058668121,
                -1.132258504593045
              ],
              [
                -58.26783001497293,
                -1.131578525866707
              ],
              [
                -58.26978850260312,
                -1.13411802940294
              ],
              [
                -58.273308484370226,
                -1.13868049742314
              ],
              [
                -58.322767658259984,
                -1.142962956978397
              ],
              [
                -58.3193691934629,
                -1.134059026202706
              ],
              [
                -58.32018817756532,
                -1.133523031461418
              ],
              [
                -58.335163585020254,
                -1.123726078007508
              ],
              [
                -58.335748570522725,
                -1.123343595331642
              ],
              [
                -58.33604257382395,
                -1.122797585557904
              ],
              [
                -58.343896012439615,
                -1.108184681894954
              ],
              [
                -58.3480000101364,
                -1.110155187115814
              ],
              [
                -58.35167648347063,
                -1.111920654460731
              ],
              [
                -58.36080993107316,
                -1.104767705588682
              ],
              [
                -58.356426958793755,
                -1.09833772226392
              ],
              [
                -58.355226947382754,
                -1.096577754304621
              ],
              [
                -58.35738393341221,
                -1.095630253172796
              ],
              [
                -58.36230741980806,
                -1.093468256561054
              ],
              [
                -58.36797289979892,
                -1.06569145609357
              ],
              [
                -58.388166747443854,
                -1.050089546092085
              ],
              [
                -58.39217625471366,
                -1.039491098387699
              ],
              [
                -58.398926711067396,
                -1.043440054471929
              ],
              [
                -58.399344188492016,
                -1.043683583120886
              ],
              [
                -58.40582013392618,
                -1.038557612953102
              ],
              [
                -58.41116911459253,
                -1.043908587671037
              ],
              [
                -58.42956301148416,
                -1.026785663105687
              ],
              [
                -58.42316403790899,
                -1.01654322168466
              ],
              [
                -58.41897606136101,
                -1.009838790046
              ],
              [
                -58.424015536095624,
                -1.000817328295293
              ],
              [
                -58.424530560104245,
                -0.999895321737188
              ],
              [
                -58.4229730708667,
                -0.99110488665218
              ],
              [
                -58.422557564439416,
                -0.99077487451056
              ],
              [
                -58.41022863478184,
                -0.980974963723401
              ],
              [
                -58.41467059171641,
                -0.974615472404528
              ],
              [
                -58.41424413244681,
                -0.966722021304092
              ],
              [
                -58.42128858098053,
                -0.964415052761703
              ],
              [
                -58.42708802502453,
                -0.962515572662366
              ],
              [
                -58.42889251723764,
                -0.954284613844406
              ],
              [
                -58.4294985209485,
                -0.951522127015997
              ],
              [
                -58.450309380182986,
                -0.925751797006217
              ],
              [
                -58.44803289960649,
                -0.906647373947417
              ],
              [
                -58.45255839731399,
                -0.897863431728335
              ],
              [
                -58.44256995438356,
                -0.894624455249647
              ],
              [
                -58.433371018637835,
                -0.891640974398868
              ],
              [
                -58.43553247663428,
                -0.883137041256667
              ],
              [
                -58.45852636257266,
                -0.86366264979048
              ],
              [
                -58.46654930485154,
                -0.863926657296444
              ],
              [
                -58.46728231266583,
                -0.869648101057381
              ],
              [
                -58.467683291827186,
                -0.872777111881308
              ],
              [
                -58.47566274312948,
                -0.86725662008577
              ],
              [
                -58.47953670365836,
                -0.859245174257102
              ],
              [
                -58.48702815520055,
                -0.859396168348069
              ],
              [
                -58.4932831379953,
                -0.845786247421004
              ],
              [
                -58.49316761480991,
                -0.844879772686242
              ],
              [
                -58.49145362787768,
                -0.83146035864589
              ],
              [
                -58.500479097583955,
                -0.836039808102661
              ],
              [
                -58.50630405157156,
                -0.833622818913184
              ],
              [
                -58.507858032231695,
                -0.832977834864747
              ],
              [
                -58.51173050495376,
                -0.839447792677304
              ],
              [
                -58.518806983561696,
                -0.83547534169951
              ],
              [
                -58.520134475489215,
                -0.827624891573191
              ],
              [
                -58.5601147211729,
                -0.804289017328576
              ],
              [
                -58.56077370706589,
                -0.796891053460291
              ],
              [
                -58.56080870390042,
                -0.796500070739927
              ],
              [
                -58.56741669038953,
                -0.792373609546368
              ],
              [
                -58.57088266423724,
                -0.785352614817108
              ],
              [
                -58.577879122120684,
                -0.783442151762571
              ],
              [
                -58.57808910685671,
                -0.78338465412197
              ],
              [
                -58.590363522752156,
                -0.77340819961002
              ],
              [
                -58.5905330498214,
                -0.772585205417962
              ],
              [
                -58.59211452513723,
                -0.764884759404834
              ],
              [
                -58.59978448382034,
                -0.764237281057153
              ],
              [
                -58.60204796330609,
                -0.762704764233317
              ],
              [
                -58.61324840543974,
                -0.755119313640393
              ],
              [
                -58.61956486247633,
                -0.744191368918748
              ],
              [
                -58.634371776781634,
                -0.718576552555475
              ],
              [
                -58.64859966935082,
                -0.713647580763655
              ],
              [
                -58.64883518793692,
                -0.71356558326587
              ],
              [
                -58.65618613930597,
                -0.715748556365524
              ],
              [
                -58.6635165843565,
                -0.712774061449988
              ],
              [
                -58.665997563196655,
                -0.705017634809656
              ],
              [
                -58.678209502825,
                -0.692064215406832
              ],
              [
                -58.68308047195757,
                -0.686897248952366
              ],
              [
                -58.68774596398479,
                -0.685112768809441
              ],
              [
                -58.70465884860413,
                -0.678644285147071
              ],
              [
                -58.70341984884169,
                -0.671059842097948
              ],
              [
                -58.70680782876201,
                -0.657379915953284
              ],
              [
                -58.70909882884569,
                -0.648124983981622
              ],
              [
                -58.71275127794239,
                -0.646837470824078
              ],
              [
                -58.71643477685471,
                -0.6455389877321
              ],
              [
                -58.718100253595985,
                -0.63734954750045
              ],
              [
                -58.71811425951805,
                -0.637281040900027
              ],
              [
                -58.71815677526483,
                -0.637248032096028
              ],
              [
                -58.731479667907415,
                -0.627030080767066
              ],
              [
                -58.733478185314986,
                -0.611300698394268
              ],
              [
                -58.73010668380853,
                -0.609425198988011
              ],
              [
                -58.72666520772862,
                -0.60751072180429
              ],
              [
                -58.7276142201091,
                -0.583878875349243
              ],
              [
                -58.72814322200178,
                -0.58294086485317
              ],
              [
                -58.73152668812531,
                -0.576944888034537
              ],
              [
                -58.72866270467706,
                -0.561796501938434
              ],
              [
                -58.72104976381155,
                -0.558952506848066
              ],
              [
                -58.71803726726286,
                -0.555498508703629
              ],
              [
                -58.7104163269388,
                -0.546758067968862
              ],
              [
                -58.71002680163462,
                -0.542494580770897
              ],
              [
                -58.709636807330575,
                -0.538230642578059
              ],
              [
                -58.71022881311131,
                -0.521141739194171
              ],
              [
                -58.716203271175765,
                -0.506894327688455
              ],
              [
                -58.71874477101059,
                -0.504780345203053
              ],
              [
                -58.72239524322879,
                -0.501743357179737
              ],
              [
                -58.73485969331064,
                -0.471191026167881
              ],
              [
                -58.72469674556921,
                -0.441894696307672
              ],
              [
                -58.72924620659758,
                -0.434959250069944
              ],
              [
                -58.73317469601835,
                -0.433409260370038
              ],
              [
                -58.736497144078136,
                -0.432098753232067
              ],
              [
                -58.7380926386818,
                -0.428686297241478
              ],
              [
                -58.74350311880776,
                -0.417114356092332
              ],
              [
                -58.7545135597302,
                -0.412914369282413
              ],
              [
                -58.765524468665426,
                -0.408714397460473
              ],
              [
                -58.77278693545748,
                -0.400001457711861
              ],
              [
                -58.78161387893715,
                -0.389412012986645
              ],
              [
                -58.79031932378686,
                -0.385065555838942
              ],
              [
                -58.79261332492691,
                -0.385990555580113
              ],
              [
                -58.797549294106865,
                -0.387980549269148
              ],
              [
                -58.80049077569562,
                -0.382963551683628
              ],
              [
                -58.801609748071584,
                -0.381055071872536
              ],
              [
                -58.80722223633921,
                -0.380422076885327
              ],
              [
                -58.81729616580432,
                -0.379285574087367
              ],
              [
                -58.822292152013446,
                -0.374732104903432
              ],
              [
                -58.82348511389999,
                -0.37364412430999
              ],
              [
                -58.83561657122071,
                -0.368860145861801
              ],
              [
                -58.846063995645515,
                -0.364740673435785
              ],
              [
                -58.87154484471297,
                -0.342665325349289
              ],
              [
                -58.874461808444245,
                -0.295507072177141
              ],
              [
                -58.87017936782372,
                -0.280243182952734
              ],
              [
                -58.86501939708854,
                -0.273766729495162
              ],
              [
                -58.86487336231033,
                -0.265224273303678
              ],
              [
                -58.87008184616631,
                -0.261574299796966
              ],
              [
                -58.87124333222151,
                -0.260760313589653
              ],
              [
                -58.878791279052585,
                -0.229011473046407
              ],
              [
                -58.880513772751335,
                -0.196255166246509
              ],
              [
                -58.86257690244061,
                -0.160611890928985
              ],
              [
                -58.86262639551259,
                -0.144445473414154
              ],
              [
                -58.866238860954155,
                -0.137655044184297
              ],
              [
                -58.86628986164979,
                -0.137559031746585
              ],
              [
                -58.866285396678535,
                -0.137464553298785
              ],
              [
                -58.86336241120205,
                -0.077706396776641
              ],
              [
                -58.87156184309196,
                -0.064168983093446
              ],
              [
                -58.880041297166066,
                -0.06417848812023
              ],
              [
                -58.885036745210684,
                -0.059763491000451
              ],
              [
                -58.886057762088235,
                -0.058860989290213
              ],
              [
                -58.886077748998524,
                -0.055328042962601
              ],
              [
                -58.88619975540951,
                -0.033997640801518
              ],
              [
                -58.893505686592185,
                -0.023672738272743
              ],
              [
                -58.89591669913039,
                -0.020265241227434
              ],
              [
                -58.89780867478131,
                -0.017591257885676
              ],
              [
                -58.89705669329918,
                -0.01599727721476
              ],
              [
                -58.89513119386614,
                -0.011911304131641
              ],
              [
                -58.894529714479006,
                -0.010635306601473
              ],
              [
                -58.89488970613707,
                0.263852054215423
              ],
              [
                -58.89542264896971,
                0.614104436569218
              ],
              [
                -58.8949245740408,
                1.224746195235013
              ],
              [
                -58.89494508927913,
                1.228228179390283
              ],
              [
                -58.890196606765194,
                1.2283741736324
              ],
              [
                -58.888046639701344,
                1.22823667705579
              ],
              [
                -58.880218165810035,
                1.227737181591197
              ],
              [
                -58.87775819685047,
                1.226296684762599
              ],
              [
                -58.8740132128566,
                1.223513678738787
              ],
              [
                -58.87288972624525,
                1.220012716502683
              ],
              [
                -58.87263321990162,
                1.219379718247105
              ],
              [
                -58.872328744867644,
                1.218628709689538
              ],
              [
                -58.87193823438902,
                1.217665714385946
              ],
              [
                -58.87164324329384,
                1.216937216695305
              ],
              [
                -58.8708967551121,
                1.215102250549329
              ],
              [
                -58.87032723378828,
                1.213699764845414
              ],
              [
                -58.86998126502107,
                1.212849255876328
              ],
              [
                -58.86991875442464,
                1.212694742790332
              ],
              [
                -58.868997264480214,
                1.209826784799752
              ],
              [
                -58.86681726347356,
                1.20486530711851
              ],
              [
                -58.86405828514271,
                1.20435779665501
              ],
              [
                -58.85853781649951,
                1.203341831734426
              ],
              [
                -58.856775323351165,
                1.196317870490347
              ],
              [
                -58.85603435042258,
                1.192854892099535
              ],
              [
                -58.855645835080935,
                1.191039380903958
              ],
              [
                -58.854943332890855,
                1.187732393585036
              ],
              [
                -58.85398134323426,
                1.184563947389355
              ],
              [
                -58.84897239251272,
                1.183596921283389
              ],
              [
                -58.838358959815984,
                1.186440914341029
              ],
              [
                -58.83129048298423,
                1.188335889714756
              ],
              [
                -58.82625551040448,
                1.187551423510956
              ],
              [
                -58.82351456258441,
                1.183632943505743
              ],
              [
                -58.82363803208197,
                1.182273429676391
              ],
              [
                -58.82459004621016,
                1.171726006056964
              ],
              [
                -58.820610080187315,
                1.171511515636497
              ],
              [
                -58.81037012660412,
                1.178280962238807
              ],
              [
                -58.80542816873914,
                1.181696935874802
              ],
              [
                -58.802958674530686,
                1.176411974124119
              ],
              [
                -58.79959669765987,
                1.177003957975648
              ],
              [
                -58.796296207574095,
                1.182400439022343
              ],
              [
                -58.79352071054564,
                1.186554897605429
              ],
              [
                -58.79328424690628,
                1.186908900439429
              ],
              [
                -58.790947232269026,
                1.190881865184664
              ],
              [
                -58.785948785398034,
                1.190671866544067
              ],
              [
                -58.779099314567134,
                1.191078386870565
              ],
              [
                -58.77901531107211,
                1.191083358825589
              ],
              [
                -58.7774358470444,
                1.188486889123518
              ],
              [
                -58.77570383993799,
                1.185871899505785
              ],
              [
                -58.76932686114753,
                1.186980915705148
              ],
              [
                -58.76346391758065,
                1.197558316147638
              ],
              [
                -58.7631024057007,
                1.197881332142636
              ],
              [
                -58.75576346874101,
                1.204428282492438
              ],
              [
                -58.75073898520925,
                1.208909252658798
              ],
              [
                -58.74866852070982,
                1.208777253073282
              ],
              [
                -58.747042010530386,
                1.208673771397689
              ],
              [
                -58.74460302149981,
                1.207173288967281
              ],
              [
                -58.74343054469618,
                1.206451775088348
              ],
              [
                -58.73898205358482,
                1.200459809280782
              ],
              [
                -58.73408109472611,
                1.20826075351102
              ],
              [
                -58.73154359533996,
                1.212047227288689
              ],
              [
                -58.72965861819502,
                1.214860727030788
              ],
              [
                -58.727911125236304,
                1.217587195318976
              ],
              [
                -58.725443138423586,
                1.221399184945236
              ],
              [
                -58.72140415663915,
                1.227637649683729
              ],
              [
                -58.71766520406995,
                1.23372411338085
              ],
              [
                -58.71497021457355,
                1.237794083394241
              ],
              [
                -58.71713067497819,
                1.240851043408346
              ],
              [
                -58.720212660582796,
                1.24521105275782
              ],
              [
                -58.720458687034544,
                1.245558536713638
              ],
              [
                -58.7201811867855,
                1.255861457673217
              ],
              [
                -58.71848266522014,
                1.260170439421033
              ],
              [
                -58.71634519194353,
                1.266366889720644
              ],
              [
                -58.71290173008258,
                1.274966360294501
              ],
              [
                -58.711701703678735,
                1.278338321761975
              ],
              [
                -58.71063722098807,
                1.284154300536848
              ],
              [
                -58.70965272883738,
                1.289828254177191
              ],
              [
                -58.70489125873558,
                1.294114228433416
              ],
              [
                -58.69626580748056,
                1.2956567384529
              ],
              [
                -58.6930218419478,
                1.296218708431556
              ],
              [
                -58.685052885914324,
                1.296886693844797
              ],
              [
                -58.680495897606534,
                1.295931220710812
              ],
              [
                -58.67798994360204,
                1.291434715322758
              ],
              [
                -58.67768293007993,
                1.288188278829553
              ],
              [
                -58.677082430841715,
                1.287477277997912
              ],
              [
                -58.675460439002,
                1.285559770239414
              ],
              [
                -58.669293501030126,
                1.281415808020242
              ],
              [
                -58.66628049631712,
                1.280929806366542
              ],
              [
                -58.66406500504853,
                1.279084305062779
              ],
              [
                -58.660797522279935,
                1.281714281576066
              ],
              [
                -58.65829304300069,
                1.283835798299859
              ],
              [
                -58.65476558174349,
                1.2867787538694
              ],
              [
                -58.650085598813874,
                1.287824742651371
              ],
              [
                -58.64494462404966,
                1.286907265185392
              ],
              [
                -58.639823669971605,
                1.287015241538699
              ],
              [
                -58.63732267383309,
                1.288327729134359
              ],
              [
                -58.63627317506947,
                1.288879249602697
              ],
              [
                -58.63232769873501,
                1.289812252205052
              ],
              [
                -58.628227251502835,
                1.289895744902507
              ],
              [
                -58.62526224285893,
                1.287638754945826
              ],
              [
                -58.620211788782534,
                1.285594269303267
              ],
              [
                -58.61391533641079,
                1.282673268712706
              ],
              [
                -58.61181881957718,
                1.280110801774259
              ],
              [
                -58.607802864797314,
                1.275200811636755
              ],
              [
                -58.60425090123877,
                1.275411812679217
              ],
              [
                -58.60093139243518,
                1.274785324822311
              ],
              [
                -58.59468445264642,
                1.272553851093779
              ],
              [
                -58.590895472939785,
                1.270595840189057
              ],
              [
                -58.58824399726538,
                1.269096843732255
              ],
              [
                -58.58790897387484,
                1.266012370293175
              ],
              [
                -58.58768047565384,
                1.263907876956607
              ],
              [
                -58.58487051990208,
                1.262600893312598
              ],
              [
                -58.58278101290401,
                1.266009368331434
              ],
              [
                -58.580979048253695,
                1.268947860240542
              ],
              [
                -58.57915003377444,
                1.271843351401631
              ],
              [
                -58.57717506098084,
                1.275775821268002
              ],
              [
                -58.577355538125836,
                1.279783794090597
              ],
              [
                -58.577313055265364,
                1.280400788354969
              ],
              [
                -58.57709403698683,
                1.283564285200386
              ],
              [
                -58.56995210753413,
                1.287119752331597
              ],
              [
                -58.568707592355075,
                1.290831719656621
              ],
              [
                -58.56706861678006,
                1.293412721737607
              ],
              [
                -58.56468961374818,
                1.295664186663949
              ],
              [
                -58.56128314594566,
                1.292687188994446
              ],
              [
                -58.559074677718954,
                1.290598718188971
              ],
              [
                -58.55454620517007,
                1.290441223259381
              ],
              [
                -58.54629175405374,
                1.29099623829618
              ],
              [
                -58.53902477485857,
                1.286898251665474
              ],
              [
                -58.538800285102006,
                1.282278280561371
              ],
              [
                -58.53871528976388,
                1.28150778320437
              ],
              [
                -58.53815177915594,
                1.276369301168037
              ],
              [
                -58.537643295797984,
                1.268123351928526
              ],
              [
                -58.53546331138388,
                1.266113375664248
              ],
              [
                -58.53204784751182,
                1.26910783490522
              ],
              [
                -58.52795034915326,
                1.270092329167092
              ],
              [
                -58.525727865175895,
                1.26715235352183
              ],
              [
                -58.52351990664079,
                1.261387398964497
              ],
              [
                -58.52236689819297,
                1.258375905956912
              ],
              [
                -58.51851340249387,
                1.25866193349576
              ],
              [
                -58.518711413528536,
                1.262740385866709
              ],
              [
                -58.51885691308315,
                1.265735378779518
              ],
              [
                -58.51869290646552,
                1.268978366132179
              ],
              [
                -58.51708941674978,
                1.271276844921188
              ],
              [
                -58.514658935821735,
                1.274760295380552
              ],
              [
                -58.51236643509398,
                1.278045800060363
              ],
              [
                -58.51115196057595,
                1.277415296641498
              ],
              [
                -58.508800455063344,
                1.276193797580104
              ],
              [
                -58.50810349235725,
                1.275832324633378
              ],
              [
                -58.50657098879925,
                1.275036298155671
              ],
              [
                -58.50466951772634,
                1.2729328415193
              ],
              [
                -58.50348298856865,
                1.269505345032921
              ],
              [
                -58.49573356676016,
                1.268440345995453
              ],
              [
                -58.49564007369331,
                1.271794328677509
              ],
              [
                -58.49552607060441,
                1.275911317735836
              ],
              [
                -58.495449566554285,
                1.278616302347011
              ],
              [
                -58.49519855334801,
                1.28244027714951
              ],
              [
                -58.494844047228725,
                1.289074703914169
              ],
              [
                -58.49481105430944,
                1.289696714141669
              ],
              [
                -58.494748057466026,
                1.290871215018086
              ],
              [
                -58.4946600446826,
                1.29252170000733
              ],
              [
                -58.49450755605714,
                1.29537969567188
              ],
              [
                -58.48691312640055,
                1.299098666664109
              ],
              [
                -58.48321764146728,
                1.30090863197646
              ],
              [
                -58.47562466397704,
                1.308922607929144
              ],
              [
                -58.470460206190346,
                1.319785040073103
              ],
              [
                -58.471155716915625,
                1.325144472723917
              ],
              [
                -58.471747699404105,
                1.329799975619554
              ],
              [
                -58.47236717751247,
                1.335513936101978
              ],
              [
                -58.47275769542638,
                1.339115393344641
              ],
              [
                -58.47379066922028,
                1.348810361766421
              ],
              [
                -58.47164470885754,
                1.350724319722815
              ],
              [
                -58.46979370575807,
                1.352374837335579
              ],
              [
                -58.467957195198686,
                1.3558718077501
              ],
              [
                -58.46239673305625,
                1.365208752965678
              ],
              [
                -58.46039826469025,
                1.367649714742699
              ],
              [
                -58.45738076614462,
                1.371881219440524
              ],
              [
                -58.46112923994298,
                1.37407666389212
              ],
              [
                -58.46444674456999,
                1.37523767353376
              ],
              [
                -58.46950619266815,
                1.371305185442266
              ],
              [
                -58.471480195325775,
                1.373085689047938
              ],
              [
                -58.47271817158545,
                1.374202200530332
              ],
              [
                -58.474197664335094,
                1.375536688740656
              ],
              [
                -58.478303662663144,
                1.379240157124898
              ],
              [
                -58.48074511936377,
                1.381611641245982
              ],
              [
                -58.484470091029436,
                1.385190613310945
              ],
              [
                -58.47934614968022,
                1.387297114460422
              ],
              [
                -58.478523130603804,
                1.387635126237396
              ],
              [
                -58.479154637161145,
                1.390593072421855
              ],
              [
                -58.48269610432736,
                1.39227557995587
              ],
              [
                -58.48442958460916,
                1.393251065401173
              ],
              [
                -58.48550710394707,
                1.393857050950893
              ],
              [
                -58.48856856608752,
                1.395249556146196
              ],
              [
                -58.49624404471653,
                1.399185535885426
              ],
              [
                -58.50013651966587,
                1.401324511732271
              ],
              [
                -58.50458996217891,
                1.403638008641428
              ],
              [
                -58.50545948157125,
                1.410016955133901
              ],
              [
                -58.50100799143678,
                1.415374422684845
              ],
              [
                -58.50168450173524,
                1.418216930583335
              ],
              [
                -58.501544980516165,
                1.42325438598837
              ],
              [
                -58.50043300482629,
                1.425296372358725
              ],
              [
                -58.49757103106402,
                1.430557323815398
              ],
              [
                -58.49985999872567,
                1.43738730585639
              ],
              [
                -58.50184599541286,
                1.443311282330586
              ],
              [
                -58.507400447308434,
                1.445290736525623
              ],
              [
                -58.507791934465224,
                1.452106701240856
              ],
              [
                -58.508245441987846,
                1.460079656941928
              ],
              [
                -58.50820395454364,
                1.463277626518255
              ],
              [
                -58.50400148466074,
                1.464946133460286
              ],
              [
                -58.49946148488408,
                1.466352607916517
              ],
              [
                -58.49587000941839,
                1.467480613271859
              ],
              [
                -58.49296904676481,
                1.468615625736161
              ],
              [
                -58.49063206244308,
                1.466309615225829
              ],
              [
                -58.48680806249076,
                1.462400124128425
              ],
              [
                -58.48212510697892,
                1.462036621304362
              ],
              [
                -58.47224316804682,
                1.461537641158705
              ],
              [
                -58.47082969153032,
                1.461970150219231
              ],
              [
                -58.46871719221012,
                1.462615142834212
              ],
              [
                -58.46674918502314,
                1.463216136747994
              ],
              [
                -58.46350872447521,
                1.464206123016484
              ],
              [
                -58.46186624225808,
                1.465062620448082
              ],
              [
                -58.46051674229637,
                1.465765112880083
              ],
              [
                -58.45595827078082,
                1.462033131559993
              ],
              [
                -58.45504927241637,
                1.461494141636192
              ],
              [
                -58.453492803066005,
                1.460572132898362
              ],
              [
                -58.447955313580536,
                1.456453154721655
              ],
              [
                -58.443394838039445,
                1.457531164166447
              ],
              [
                -58.44077737194959,
                1.462069619994002
              ],
              [
                -58.438919886625406,
                1.464849617678225
              ],
              [
                -58.42940592700873,
                1.466706587282455
              ],
              [
                -58.424745961151245,
                1.467473102589425
              ],
              [
                -58.42351646331047,
                1.468896072659863
              ],
              [
                -58.42136297384949,
                1.471390072007708
              ],
              [
                -58.41664351357859,
                1.471151556413387
              ],
              [
                -58.414235047241,
                1.471030061619145
              ],
              [
                -58.40554206310265,
                1.470899585487625
              ],
              [
                -58.40238809228454,
                1.470852571075228
              ],
              [
                -58.39589464177446,
                1.470786560038995
              ],
              [
                -58.39004367641456,
                1.470489057555028
              ],
              [
                -58.38509919349598,
                1.470425072850051
              ],
              [
                -58.38270673956227,
                1.47263654085074
              ],
              [
                -58.371268789076716,
                1.482012499185736
              ],
              [
                -58.37291829552249,
                1.484370482185282
              ],
              [
                -58.376183751762916,
                1.488426466200188
              ],
              [
                -58.38084374229534,
                1.488984935841632
              ],
              [
                -58.384648692131,
                1.489090969048565
              ],
              [
                -58.386139208516774,
                1.491573425258831
              ],
              [
                -58.38663667930798,
                1.492401436325918
              ],
              [
                -58.38801569441271,
                1.494695899657075
              ],
              [
                -58.389475685994405,
                1.497125902181082
              ],
              [
                -58.392143157683535,
                1.502021361957321
              ],
              [
                -58.39091416907813,
                1.506843855295582
              ],
              [
                -58.3878551833216,
                1.508440338872832
              ],
              [
                -58.386741690962545,
                1.509021816076323
              ],
              [
                -58.38310722855224,
                1.511309303308723
              ],
              [
                -58.38516719755924,
                1.513378292167381
              ],
              [
                -58.38745619512156,
                1.515677793674222
              ],
              [
                -58.388508682411505,
                1.517052289537262
              ],
              [
                -58.39331964073788,
                1.523336721336379
              ],
              [
                -58.39313914700364,
                1.527005729932631
              ],
              [
                -58.39099017008527,
                1.531340681014061
              ],
              [
                -58.38900918511316,
                1.535807660324667
              ],
              [
                -58.377167762833665,
                1.53711016664345
              ],
              [
                -58.37307227065302,
                1.537520659192165
              ],
              [
                -58.37248279723137,
                1.537554151851445
              ],
              [
                -58.367373800246504,
                1.537848133875704
              ],
              [
                -58.363473822858005,
                1.53836416282482
              ],
              [
                -58.358895369784115,
                1.54680506825899
              ],
              [
                -58.36059085869302,
                1.550166575140778
              ],
              [
                -58.36131332996669,
                1.551598556570774
              ],
              [
                -58.36057033459359,
                1.555473532748948
              ],
              [
                -58.3598498627837,
                1.56063250509295
              ],
              [
                -58.34921789956564,
                1.564622992232013
              ],
              [
                -58.34488993631734,
                1.567176939617391
              ],
              [
                -58.34387693848723,
                1.575731884131475
              ],
              [
                -58.33689798859471,
                1.581629376448822
              ],
              [
                -58.33184600111923,
                1.591129786193305
              ],
              [
                -58.32828602122717,
                1.593549284553686
              ],
              [
                -58.32187806633143,
                1.597451758161411
              ],
              [
                -58.31880310838468,
                1.596092258734012
              ],
              [
                -58.315053134534175,
                1.594805279702442
              ],
              [
                -58.314637117618744,
                1.58824631770244
              ],
              [
                -58.31536811481848,
                1.581221376819963
              ],
              [
                -58.31557162103749,
                1.579266392818041
              ],
              [
                -58.31601460200819,
                1.576409384385559
              ],
              [
                -58.316140133602524,
                1.574874891793933
              ],
              [
                -58.31649013347184,
                1.570586422085741
              ],
              [
                -58.31017166264096,
                1.567691458287242
              ],
              [
                -58.30103321770983,
                1.566043441207201
              ],
              [
                -58.29590922812057,
                1.565259975790415
              ],
              [
                -58.29211177230262,
                1.565106971832116
              ],
              [
                -58.29035529025678,
                1.568343946625349
              ],
              [
                -58.289849274124094,
                1.569276934800701
              ],
              [
                -58.28771081133697,
                1.572807415704944
              ],
              [
                -58.28353132811778,
                1.571519905591451
              ],
              [
                -58.27978232924527,
                1.570364911770512
              ],
              [
                -58.27605187125268,
                1.569247927722607
              ],
              [
                -58.27374087750652,
                1.568555918410766
              ],
              [
                -58.27243338557113,
                1.568164953494237
              ],
              [
                -58.27124191392051,
                1.567807930397506
              ],
              [
                -58.26176744892776,
                1.571161908640291
              ],
              [
                -58.25673901098115,
                1.569541925367414
              ],
              [
                -58.25414850536526,
                1.562745479351588
              ],
              [
                -58.253984008469224,
                1.562312977959772
              ],
              [
                -58.250339537235845,
                1.560025480103989
              ],
              [
                -58.24496558470454,
                1.557102499734778
              ],
              [
                -58.24008309935591,
                1.55340153824631
              ],
              [
                -58.23778113021937,
                1.549867532340905
              ],
              [
                -58.23568263858513,
                1.547442545695719
              ],
              [
                -58.232885155317476,
                1.548940028878479
              ],
              [
                -58.23070414418584,
                1.550878553503265
              ],
              [
                -58.227138667105,
                1.554559990131688
              ],
              [
                -58.22353468460598,
                1.556740509937137
              ],
              [
                -58.22092520017462,
                1.558319498656694
              ],
              [
                -58.21237377779546,
                1.565231929309298
              ],
              [
                -58.19680735332523,
                1.567641436881998
              ],
              [
                -58.19253939623569,
                1.572655371142497
              ],
              [
                -58.1900489168918,
                1.570776888070093
              ],
              [
                -58.187826904860806,
                1.569099921826595
              ],
              [
                -58.18527943387652,
                1.567175905021878
              ],
              [
                -58.18332196618322,
                1.565697421625532
              ],
              [
                -58.17911096909524,
                1.564947950230759
              ],
              [
                -58.160443081239194,
                1.560578937643306
              ],
              [
                -58.15871761829078,
                1.554080493097648
              ],
              [
                -58.157767618920985,
                1.550514008749906
              ],
              [
                -58.15609662761178,
                1.544096566718443
              ],
              [
                -58.15354063564713,
                1.542334588931917
              ],
              [
                -58.1537621228295,
                1.53998308740404
              ],
              [
                -58.15402563085574,
                1.537185582620027
              ],
              [
                -58.154399623400955,
                1.533526117145794
              ],
              [
                -58.154076636223785,
                1.529792645963391
              ],
              [
                -58.148697660078305,
                1.521147189739569
              ],
              [
                -58.145572192403286,
                1.515785722899407
              ],
              [
                -58.149067689217965,
                1.510448282415096
              ],
              [
                -58.144441203079964,
                1.50793779775696
              ],
              [
                -58.135267752304976,
                1.503002805940239
              ],
              [
                -58.128898288090525,
                1.499364331815018
              ],
              [
                -58.12049236826624,
                1.50058634942874
              ],
              [
                -58.11620090746102,
                1.500921832404593
              ],
              [
                -58.11074491569514,
                1.501675808550416
              ],
              [
                -58.104262949051545,
                1.507654289405032
              ],
              [
                -58.1004754804978,
                1.510996740034962
              ],
              [
                -58.1000919987705,
                1.511335266870307
              ],
              [
                -58.09707448957214,
                1.514363739597051
              ],
              [
                -58.0852940865388,
                1.51522422463763
              ],
              [
                -58.08509808719799,
                1.51317925914933
              ],
              [
                -58.08475408710735,
                1.509595744073364
              ],
              [
                -58.07327964078358,
                1.507938772651358
              ],
              [
                -58.069362174771385,
                1.508027265221993
              ],
              [
                -58.06808518792184,
                1.511051753356425
              ],
              [
                -58.06727517621127,
                1.514066744653147
              ],
              [
                -58.06654568618263,
                1.516294733801143
              ],
              [
                -58.06559519835611,
                1.519197701751049
              ],
              [
                -58.064684186142685,
                1.522739194785176
              ],
              [
                -58.060401734698345,
                1.525817145893889
              ],
              [
                -58.05181279777253,
                1.523963162323894
              ],
              [
                -58.04820380810877,
                1.523042680157159
              ],
              [
                -58.042229862099994,
                1.521456192545811
              ],
              [
                -58.037500880631235,
                1.519108683899378
              ],
              [
                -58.034842371498264,
                1.516611698645411
              ],
              [
                -58.03075042145997,
                1.512768742342669
              ],
              [
                -58.02973290990938,
                1.511841238814385
              ],
              [
                -58.02902992036495,
                1.51120173658689
              ],
              [
                -58.02787343369483,
                1.510149739792854
              ],
              [
                -58.026682463243525,
                1.5090652541911
              ],
              [
                -58.02479296358023,
                1.510209733726758
              ],
              [
                -58.02321995801818,
                1.511163227507707
              ],
              [
                -58.019823972217566,
                1.509541242712936
              ],
              [
                -58.0181454966587,
                1.508906267238004
              ],
              [
                -58.00886504739472,
                1.505396776725489
              ],
              [
                -58.003745581231094,
                1.503540768987454
              ],
              [
                -58.004277595413015,
                1.510505218266123
              ],
              [
                -58.00496059371013,
                1.517250710922162
              ],
              [
                -57.999960611517395,
                1.521192682529207
              ],
              [
                -58.0007401020581,
                1.528615109030502
              ],
              [
                -57.99781911518658,
                1.532166616502576
              ],
              [
                -57.99689112847304,
                1.533978585136416
              ],
              [
                -57.9957856317698,
                1.536139076583086
              ],
              [
                -57.9940731405233,
                1.539484533593733
              ],
              [
                -57.99202016388962,
                1.540534544655608
              ],
              [
                -57.99029966125358,
                1.541414547840291
              ],
              [
                -57.98824267725464,
                1.54417651435821
              ],
              [
                -57.989544154746426,
                1.546435506749208
              ],
              [
                -57.99136764082471,
                1.549600510682615
              ],
              [
                -57.99179165605217,
                1.550337497242821
              ],
              [
                -57.993738659321366,
                1.553717945879096
              ],
              [
                -57.9895886826449,
                1.563084415224728
              ],
              [
                -57.9893071732878,
                1.567774370269591
              ],
              [
                -57.98563670398649,
                1.567091367615209
              ],
              [
                -57.985290683126436,
                1.567026899931135
              ],
              [
                -57.9820157114075,
                1.566303382620004
              ],
              [
                -57.97803375124988,
                1.573261847807985
              ],
              [
                -57.9795002344633,
                1.576606807540979
              ],
              [
                -57.98112270659587,
                1.580471306109482
              ],
              [
                -57.98240819887879,
                1.583983271770873
              ],
              [
                -57.9831201870161,
                1.58607327105954
              ],
              [
                -57.98334221349952,
                1.586725250094208
              ],
              [
                -57.9830802188591,
                1.587788251480624
              ],
              [
                -57.98087521729339,
                1.596743186758798
              ],
              [
                -57.98245769871241,
                1.604607159659382
              ],
              [
                -57.98266720516445,
                1.605707637925289
              ],
              [
                -57.983212688132355,
                1.608576607368938
              ],
              [
                -57.98367418572068,
                1.611001599529251
              ],
              [
                -57.98418517494189,
                1.613687600091985
              ],
              [
                -57.984738168705896,
                1.617202551553268
              ],
              [
                -57.98378419438917,
                1.617937048793156
              ],
              [
                -57.981354202866086,
                1.619807554669476
              ],
              [
                -57.98018771186978,
                1.628804471899016
              ],
              [
                -57.980671208647784,
                1.633994957001714
              ],
              [
                -57.98585218209378,
                1.637026945569387
              ],
              [
                -57.986890672569686,
                1.637631441095692
              ],
              [
                -57.98782915667387,
                1.638177915955432
              ],
              [
                -57.990513133812804,
                1.639740926974405
              ],
              [
                -57.99189363814071,
                1.640544412358197
              ],
              [
                -57.99657510077725,
                1.643090387808322
              ],
              [
                -58.00212455897228,
                1.646108885561692
              ],
              [
                -58.0018225748956,
                1.649926357946236
              ],
              [
                -57.98970814578113,
                1.651962324392302
              ],
              [
                -57.98946664036818,
                1.659704312569791
              ],
              [
                -57.98488166521266,
                1.660980275556526
              ],
              [
                -57.98045920846538,
                1.660613785717865
              ],
              [
                -57.978347714479696,
                1.656354791476069
              ],
              [
                -57.969468275368776,
                1.654965322849394
              ],
              [
                -57.963419308218754,
                1.656261326349677
              ],
              [
                -57.95728785629359,
                1.654646319800152
              ],
              [
                -57.95891582306967,
                1.651450841931092
              ],
              [
                -57.95297538610635,
                1.649290345801441
              ],
              [
                -57.95225839458608,
                1.648991852467345
              ],
              [
                -57.94968141568773,
                1.647917353465538
              ],
              [
                -57.93963545969986,
                1.646876350416014
              ],
              [
                -57.93670548583326,
                1.646390351695316
              ],
              [
                -57.92626555431107,
                1.645252371152423
              ],
              [
                -57.91292962964075,
                1.648145850987295
              ],
              [
                -57.90834315986468,
                1.652227331628903
              ],
              [
                -57.904234698196426,
                1.65611029960895
              ],
              [
                -57.90411619179559,
                1.656641779293927
              ],
              [
                -57.9037046978771,
                1.658482293813206
              ],
              [
                -57.902639170264315,
                1.663249753072335
              ],
              [
                -57.901532719855005,
                1.668200719182541
              ],
              [
                -57.898373231527934,
                1.671328679049297
              ],
              [
                -57.894494249554356,
                1.674760178854214
              ],
              [
                -57.88874079699446,
                1.674662181018766
              ],
              [
                -57.88631031154161,
                1.672297180041141
              ],
              [
                -57.88412032455014,
                1.670167187670888
              ],
              [
                -57.8766543718915,
                1.672712183057427
              ],
              [
                -57.87676586561173,
                1.675111650237263
              ],
              [
                -57.876851346624704,
                1.676972664742146
              ],
              [
                -57.8766058462297,
                1.680629654048851
              ],
              [
                -57.8730778874534,
                1.678621139593088
              ],
              [
                -57.869839882851714,
                1.676777667190243
              ],
              [
                -57.868301919480416,
                1.676145661716367
              ],
              [
                -57.862677441695666,
                1.673835155609042
              ],
              [
                -57.85932748378011,
                1.672020697002961
              ],
              [
                -57.85588746544255,
                1.670116713930156
              ],
              [
                -57.8518475237303,
                1.668506201892296
              ],
              [
                -57.852195992239174,
                1.673953181254439
              ],
              [
                -57.852398494208245,
                1.677119139701807
              ],
              [
                -57.85085700441949,
                1.678276638147578
              ],
              [
                -57.84644353079303,
                1.68159163950927
              ],
              [
                -57.84478106631573,
                1.688514090213344
              ],
              [
                -57.84129309131486,
                1.686494085846241
              ],
              [
                -57.83957059755042,
                1.684004087837017
              ],
              [
                -57.835867122625444,
                1.687025590185028
              ],
              [
                -57.82724916111584,
                1.685561091086598
              ],
              [
                -57.827021147531156,
                1.685522594267705
              ],
              [
                -57.82211368094471,
                1.684894581923203
              ],
              [
                -57.818977719424474,
                1.68432011069388
              ],
              [
                -57.80719280610701,
                1.688332082853534
              ],
              [
                -57.80095533701939,
                1.686582082131095
              ],
              [
                -57.797475829022716,
                1.6884415557228
              ],
              [
                -57.79587585014643,
                1.69144055071975
              ],
              [
                -57.79849635903731,
                1.69520851001391
              ],
              [
                -57.80108632112692,
                1.698931017583692
              ],
              [
                -57.80304883217812,
                1.702286958268748
              ],
              [
                -57.79897082276581,
                1.704554980163125
              ],
              [
                -57.79637134643994,
                1.706000468534021
              ],
              [
                -57.79484438364749,
                1.706849467877777
              ],
              [
                -57.793959352486524,
                1.713276926774367
              ],
              [
                -57.79295787911204,
                1.719412858444058
              ],
              [
                -57.791540869658085,
                1.728053826453595
              ],
              [
                -57.78446592909651,
                1.724999833584135
              ],
              [
                -57.7822089155801,
                1.722822863515932
              ],
              [
                -57.779844470959574,
                1.719728360116571
              ],
              [
                -57.77673948678622,
                1.724998832599058
              ],
              [
                -57.77424347099831,
                1.729691788817064
              ],
              [
                -57.77036900814048,
                1.728816794249471
              ],
              [
                -57.766693020048244,
                1.727984815466902
              ],
              [
                -57.76629203162694,
                1.723652322268417
              ],
              [
                -57.76623556013259,
                1.723034862088167
              ],
              [
                -57.76058856198253,
                1.723109825169179
              ],
              [
                -57.75973808523162,
                1.723120843881846
              ],
              [
                -57.759674103651534,
                1.723023347470891
              ],
              [
                -57.7572365906507,
                1.719295377997674
              ],
              [
                -57.75126313413037,
                1.720924353345035
              ],
              [
                -57.74834966086685,
                1.721946357247218
              ],
              [
                -57.74456666689847,
                1.723273330029966
              ],
              [
                -57.738295733870636,
                1.722367847042758
              ],
              [
                -57.733857236617496,
                1.721098853787346
              ],
              [
                -57.73084926541191,
                1.720282857085554
              ],
              [
                -57.729235263497074,
                1.719844860393136
              ],
              [
                -57.7285387627461,
                1.720062855859415
              ],
              [
                -57.72415931646491,
                1.721432333222323
              ],
              [
                -57.719295843062014,
                1.723259339614383
              ],
              [
                -57.71595785123352,
                1.721142354917351
              ],
              [
                -57.71677385774628,
                1.718944855788519
              ],
              [
                -57.71790534652308,
                1.715898361017089
              ],
              [
                -57.709625378151415,
                1.718109348149152
              ],
              [
                -57.70957441842823,
                1.718260359197142
              ],
              [
                -57.70823338571525,
                1.722221840220156
              ],
              [
                -57.709871375826026,
                1.72522031399594
              ],
              [
                -57.7112413684624,
                1.728730301522303
              ],
              [
                -57.70505591901188,
                1.731456758968571
              ],
              [
                -57.69662698673632,
                1.721240349341471
              ],
              [
                -57.69696849048198,
                1.717855342483102
              ],
              [
                -57.69754898781829,
                1.714191882416013
              ],
              [
                -57.69627496992972,
                1.713272899805464
              ],
              [
                -57.69539349154209,
                1.712636396538636
              ],
              [
                -57.694876976830685,
                1.712263890723342
              ],
              [
                -57.69061051699565,
                1.709185923777296
              ],
              [
                -57.68604405263565,
                1.707626930309758
              ],
              [
                -57.68474156573317,
                1.707468442963733
              ],
              [
                -57.67371762427846,
                1.706125416511119
              ],
              [
                -57.671921123026664,
                1.703612458689233
              ],
              [
                -57.671913158078716,
                1.703601449755713
              ],
              [
                -57.67191013209819,
                1.703598427773759
              ],
              [
                -57.669030666858966,
                1.699843975402201
              ],
              [
                -57.66892515261739,
                1.699422977994798
              ],
              [
                -57.66852615802625,
                1.69566548527151
              ],
              [
                -57.66290169865553,
                1.692385502257149
              ],
              [
                -57.66004373103924,
                1.689601532858127
              ],
              [
                -57.65439975792388,
                1.689818527085221
              ],
              [
                -57.65225625605889,
                1.686341045185638
              ],
              [
                -57.649894272550895,
                1.682841563369079
              ],
              [
                -57.644068813845436,
                1.685971051429128
              ],
              [
                -57.64072084219083,
                1.687530535879932
              ],
              [
                -57.635181875736784,
                1.690580011500639
              ],
              [
                -57.632410391574666,
                1.691898999589355
              ],
              [
                -57.63071540724623,
                1.695217977494771
              ],
              [
                -57.62881940106342,
                1.698930450895916
              ],
              [
                -57.621456476362106,
                1.700194442157324
              ],
              [
                -57.61894297291513,
                1.696276485925632
              ],
              [
                -57.61890499116523,
                1.696217476283563
              ],
              [
                -57.614131506944425,
                1.695414984075529
              ],
              [
                -57.609718055578675,
                1.694278973037503
              ],
              [
                -57.605028085710174,
                1.69402298744786
              ],
              [
                -57.60125309326527,
                1.697404964419727
              ],
              [
                -57.597332122692784,
                1.700918954526807
              ],
              [
                -57.59034065285479,
                1.701654447169423
              ],
              [
                -57.58352871988631,
                1.702486922251478
              ],
              [
                -57.58122421216348,
                1.706531413447624
              ],
              [
                -57.575726246521675,
                1.701509926364326
              ],
              [
                -57.57590476791305,
                1.699516959837347
              ],
              [
                -57.57626774067802,
                1.695447982301006
              ],
              [
                -57.576600736772626,
                1.690791485418012
              ],
              [
                -57.57211577198494,
                1.689209508151983
              ],
              [
                -57.56239885062848,
                1.695787977676599
              ],
              [
                -57.55664489282291,
                1.693816475521168
              ],
              [
                -57.55316087804823,
                1.695401446754048
              ],
              [
                -57.54992590292971,
                1.700187449075284
              ],
              [
                -57.54085898210708,
                1.700592426251501
              ],
              [
                -57.5371350001311,
                1.70096993095195
              ],
              [
                -57.535053019986826,
                1.70524240475507
              ],
              [
                -57.533683500638645,
                1.708632870241385
              ],
              [
                -57.535324504762826,
                1.711366346594363
              ],
              [
                -57.53655251062365,
                1.713411861137447
              ],
              [
                -57.536982497773685,
                1.714127824777445
              ],
              [
                -57.539315996306335,
                1.718013322116585
              ],
              [
                -57.54073244979636,
                1.720847813783247
              ],
              [
                -57.53571349475896,
                1.721621803664055
              ],
              [
                -57.53128351216597,
                1.722009291108361
              ],
              [
                -57.52866105274017,
                1.724809763946202
              ],
              [
                -57.52558708190731,
                1.728439769483486
              ],
              [
                -57.5245730634827,
                1.727344769062183
              ],
              [
                -57.5227300824333,
                1.725355266015386
              ],
              [
                -57.52106009326209,
                1.723552278848602
              ],
              [
                -57.51834310863053,
                1.72160381032271
              ],
              [
                -57.51374211773095,
                1.723492796342038
              ],
              [
                -57.51621612316382,
                1.73215121888303
              ],
              [
                -57.515769122051275,
                1.73563871097697
              ],
              [
                -57.515426635497946,
                1.738304174232985
              ],
              [
                -57.51521162240702,
                1.739972159754255
              ],
              [
                -57.514976138399476,
                1.741809656210582
              ],
              [
                -57.51311763876237,
                1.746418122926869
              ],
              [
                -57.50799516863809,
                1.757858040080216
              ],
              [
                -57.50491269371903,
                1.758130554569574
              ],
              [
                -57.50127272025259,
                1.758453048600266
              ],
              [
                -57.4967597360018,
                1.767309003032521
              ],
              [
                -57.49764724450419,
                1.771085957642476
              ],
              [
                -57.497940235359614,
                1.772329448941835
              ],
              [
                -57.498483704379346,
                1.774642457617392
              ],
              [
                -57.49902822439079,
                1.776959440268161
              ],
              [
                -57.501322206615036,
                1.786583867670055
              ],
              [
                -57.4984572327798,
                1.789012846711151
              ],
              [
                -57.49308427013058,
                1.792964812550268
              ],
              [
                -57.48965027247428,
                1.792963844639796
              ],
              [
                -57.486574783591095,
                1.79296333082223
              ],
              [
                -57.48461781380204,
                1.800362767983329
              ],
              [
                -57.48044984951721,
                1.797320282913396
              ],
              [
                -57.47928035098809,
                1.796562807342572
              ],
              [
                -57.47721336219258,
                1.79522381617266
              ],
              [
                -57.476244855519106,
                1.797856807429457
              ],
              [
                -57.47569285455446,
                1.799357777884162
              ],
              [
                -57.4749993711228,
                1.798387787771719
              ],
              [
                -57.473516371891584,
                1.796314286357406
              ],
              [
                -57.46705641324036,
                1.799426769139447
              ],
              [
                -57.46291243956787,
                1.80110877549526
              ],
              [
                -57.45716196564198,
                1.803728758542126
              ],
              [
                -57.45250951785159,
                1.806489726995649
              ],
              [
                -57.44933953082293,
                1.813072695894034
              ],
              [
                -57.44417754634391,
                1.822796611554789
              ],
              [
                -57.44031707769927,
                1.825286594898415
              ],
              [
                -57.437267112152156,
                1.827253603737826
              ],
              [
                -57.43845207715823,
                1.829560076599065
              ],
              [
                -57.440980561097945,
                1.834484561412287
              ],
              [
                -57.44180907950728,
                1.836097528525222
              ],
              [
                -57.44438505406363,
                1.841333488399997
              ],
              [
                -57.44081908681375,
                1.846648969090014
              ],
              [
                -57.43901657130172,
                1.849335449746188
              ],
              [
                -57.43705508486432,
                1.851706437335268
              ],
              [
                -57.43540811157274,
                1.853697428392757
              ],
              [
                -57.43311962665962,
                1.857940402144111
              ],
              [
                -57.43187463416696,
                1.869781304505725
              ],
              [
                -57.428738144447436,
                1.874301795264214
              ],
              [
                -57.432959124084746,
                1.878252772646549
              ],
              [
                -57.433024620658564,
                1.878319783244306
              ],
              [
                -57.43562908470848,
                1.880998737160247
              ],
              [
                -57.4345176157167,
                1.884287739207621
              ],
              [
                -57.43313511449784,
                1.890865174535714
              ],
              [
                -57.432881607727204,
                1.906456579583523
              ],
              [
                -57.42705565203273,
                1.909881546423655
              ],
              [
                -57.422215183994474,
                1.910498539173582
              ],
              [
                -57.41666871433049,
                1.911205566156429
              ],
              [
                -57.41502770448618,
                1.911429045286141
              ],
              [
                -57.414283744090554,
                1.911530059440145
              ],
              [
                -57.4084237773602,
                1.912327035767122
              ],
              [
                -57.402445291368466,
                1.913140528955272
              ],
              [
                -57.40036630614249,
                1.917322506082528
              ],
              [
                -57.39790081410276,
                1.922282997201547
              ],
              [
                -57.39453633561773,
                1.923859975326917
              ],
              [
                -57.391258874693314,
                1.925077962732356
              ],
              [
                -57.38450590996858,
                1.927917414945402
              ],
              [
                -57.379613949092445,
                1.9256644617117
              ],
              [
                -57.37381649798052,
                1.922994957841771
              ],
              [
                -57.369054994383546,
                1.923979471288317
              ],
              [
                -57.36552952183992,
                1.929361411423479
              ],
              [
                -57.362275542719296,
                1.934327371245438
              ],
              [
                -57.35954655655065,
                1.938494350239316
              ],
              [
                -57.35831758948285,
                1.941061826727509
              ],
              [
                -57.35619758867128,
                1.944678322354147
              ],
              [
                -57.35782756174398,
                1.947350818004515
              ],
              [
                -57.360772545822755,
                1.949302301574515
              ],
              [
                -57.36307204017229,
                1.950396302355365
              ],
              [
                -57.36413602939372,
                1.950902285479345
              ],
              [
                -57.36762199717333,
                1.952603279785493
              ],
              [
                -57.36857101204107,
                1.956841245390044
              ],
              [
                -57.36435001495499,
                1.958238247369507
              ],
              [
                -57.35969755025917,
                1.960686731602115
              ],
              [
                -57.35520058260049,
                1.963119710973753
              ],
              [
                -57.35509958415223,
                1.96685818640544
              ],
              [
                -57.355681090391535,
                1.970784140854834
              ],
              [
                -57.35019261202656,
                1.976286107596215
              ],
              [
                -57.34512564134239,
                1.980722589161763
              ],
              [
                -57.33734869825642,
                1.975899603260032
              ],
              [
                -57.33231273600637,
                1.978209605226459
              ],
              [
                -57.328816768280596,
                1.980068566484436
              ],
              [
                -57.32602676981822,
                1.979630076427321
              ],
              [
                -57.32477727267262,
                1.979434098295032
              ],
              [
                -57.317615807692555,
                1.978317605227268
              ],
              [
                -57.312692338603014,
                1.977704114648158
              ],
              [
                -57.31172985961944,
                1.981120088830048
              ],
              [
                -57.310025876702056,
                1.986463539638076
              ],
              [
                -57.308813383162565,
                1.990266009303124
              ],
              [
                -57.306596402008125,
                1.997089976612018
              ],
              [
                -57.30431688949546,
                1.99963146290578
              ],
              [
                -57.28669099782783,
                1.988488012983065
              ],
              [
                -57.28350953928705,
                1.986476544734987
              ],
              [
                -57.276738573469686,
                1.983443039880008
              ],
              [
                -57.26831813789765,
                1.984082054367956
              ],
              [
                -57.267320647234634,
                1.980284067044081
              ],
              [
                -57.270154636719155,
                1.976167606853498
              ],
              [
                -57.27200260494975,
                1.973481619343462
              ],
              [
                -57.269144119839986,
                1.969922645962702
              ],
              [
                -57.26323517756091,
                1.963698173512704
              ],
              [
                -57.261794683000105,
                1.962181187665109
              ],
              [
                -57.26075918751217,
                1.96204119324695
              ],
              [
                -57.25594270880348,
                1.961392192940123
              ],
              [
                -57.25571021365447,
                1.956524211591875
              ],
              [
                -57.25566222124121,
                1.955524223888023
              ],
              [
                -57.254709743496655,
                1.950977247303319
              ],
              [
                -57.25093075644933,
                1.949823758488062
              ],
              [
                -57.24595677397205,
                1.948330749471201
              ],
              [
                -57.243187314583224,
                1.947295270205678
              ],
              [
                -57.23921782818549,
                1.944553785361876
              ],
              [
                -57.23567634777756,
                1.939472835409499
              ],
              [
                -57.23048939651995,
                1.938405808652251
              ],
              [
                -57.228778902154865,
                1.942146309548315
              ],
              [
                -57.22882889065374,
                1.946318269225376
              ],
              [
                -57.22872439811375,
                1.950499249799005
              ],
              [
                -57.22862240204713,
                1.952963231212449
              ],
              [
                -57.22858041501633,
                1.953988210728619
              ],
              [
                -57.221705922936984,
                1.957760183915145
              ],
              [
                -57.218789469708355,
                1.959411683637403
              ],
              [
                -57.2165934601226,
                1.960542663855421
              ],
              [
                -57.21420349772239,
                1.961773184387362
              ],
              [
                -57.212645516903045,
                1.963747174463758
              ],
              [
                -57.2095295292642,
                1.967694619616023
              ],
              [
                -57.206127559502484,
                1.971336602609681
              ],
              [
                -57.201628576088716,
                1.976767551970268
              ],
              [
                -57.19940559532126,
                1.979118055461462
              ],
              [
                -57.19596111484635,
                1.97923954368254
              ],
              [
                -57.192599633848474,
                1.979378545817211
              ],
              [
                -57.19150263169088,
                1.979465039948414
              ],
              [
                -57.186015669918085,
                1.979896048612727
              ],
              [
                -57.18312316993076,
                1.980234522623257
              ],
              [
                -57.18189669280159,
                1.983022031635484
              ],
              [
                -57.17855769450083,
                1.990616494619977
              ],
              [
                -57.16875979205988,
                1.993375950269969
              ],
              [
                -57.16403479467377,
                1.993319964228074
              ],
              [
                -57.159363844505464,
                1.991367452204063
              ],
              [
                -57.15818333104543,
                1.990873963978598
              ],
              [
                -57.1536403704843,
                1.99096095308755
              ],
              [
                -57.14861739225815,
                1.989936467090102
              ],
              [
                -57.14693142492389,
                1.989592455770492
              ],
              [
                -57.14483044208883,
                1.993030455387349
              ],
              [
                -57.14366292385323,
                1.994924439052184
              ],
              [
                -57.142822422722816,
                1.996287912171462
              ],
              [
                -57.14079843674513,
                1.998556924206364
              ],
              [
                -57.13929646966632,
                2.000241891092388
              ],
              [
                -57.13809698079119,
                2.001586415222862
              ],
              [
                -57.13754896804635,
                2.002200407172248
              ],
              [
                -57.13580049474386,
                2.003096877976339
              ],
              [
                -57.131247010605094,
                2.005429377851538
              ],
              [
                -57.13030401237527,
                2.005912373512049
              ],
              [
                -57.12956952986957,
                2.006288842909186
              ],
              [
                -57.11943407513871,
                2.010653819240647
              ],
              [
                -57.11767708048934,
                2.012922303343131
              ],
              [
                -57.11582659339004,
                2.015312809642689
              ],
              [
                -57.11599860392361,
                2.020013271905567
              ],
              [
                -57.11617959830283,
                2.025033715141058
              ],
              [
                -57.11606858704999,
                2.028234221822063
              ],
              [
                -57.11113511947928,
                2.029962704382564
              ],
              [
                -57.10637366035489,
                2.026563725500012
              ],
              [
                -57.10191719141707,
                2.022832261820492
              ],
              [
                -57.09873522615937,
                2.020197775570627
              ],
              [
                -57.095649225701756,
                2.019589273505602
              ],
              [
                -57.09318474412681,
                2.023103227486161
              ],
              [
                -57.08961028463872,
                2.026204707745747
              ],
              [
                -57.086109805032514,
                2.028141685409656
              ],
              [
                -57.08107382726048,
                2.019288259055953
              ],
              [
                -57.08137382224789,
                2.016338767852752
              ],
              [
                -57.08163430744583,
                2.013512284856478
              ],
              [
                -57.0820523184478,
                2.007984320037543
              ],
              [
                -57.07685636786392,
                2.005252356818783
              ],
              [
                -57.07296387500962,
                2.002828387036662
              ],
              [
                -57.07062388139467,
                1.997033914092579
              ],
              [
                -57.070647405637445,
                1.995696415581766
              ],
              [
                -57.070677912971504,
                1.993894413018456
              ],
              [
                -57.07070538725676,
                1.992323937985707
              ],
              [
                -57.07076741294496,
                1.988621455482212
              ],
              [
                -57.07082188852562,
                1.985445979633367
              ],
              [
                -57.07063439763808,
                1.982221509023121
              ],
              [
                -57.07075491236299,
                1.973641546454459
              ],
              [
                -57.070681412100555,
                1.969214569496596
              ],
              [
                -57.0696854219279,
                1.967218604858734
              ],
              [
                -57.068905900271076,
                1.965656626532979
              ],
              [
                -57.06398095358216,
                1.957326162908133
              ],
              [
                -57.05985646115267,
                1.958473163441744
              ],
              [
                -57.056050000178736,
                1.958047162039131
              ],
              [
                -57.05325902717208,
                1.952922175723317
              ],
              [
                -57.04870255763582,
                1.953464693966366
              ],
              [
                -57.04732604823531,
                1.953628697528461
              ],
              [
                -57.04312759508395,
                1.951964196869763
              ],
              [
                -57.03410914472304,
                1.948529716047532
              ],
              [
                -57.03028869147858,
                1.945896730646119
              ],
              [
                -57.02701519595509,
                1.942710226915065
              ],
              [
                -57.024400699083095,
                1.940253262747909
              ],
              [
                -57.024086708425145,
                1.935419802314469
              ],
              [
                -57.02402573607602,
                1.934473286300109
              ],
              [
                -57.023903735589315,
                1.928284833512011
              ],
              [
                -57.016901777115365,
                1.919331383298937
              ],
              [
                -57.01367980147439,
                1.915384416415675
              ],
              [
                -57.010929286451386,
                1.916505906523502
              ],
              [
                -57.00865831846935,
                1.917431409009486
              ],
              [
                -57.00585183601514,
                1.914212435631411
              ],
              [
                -57.00021037629356,
                1.90774245907512
              ],
              [
                -56.989442453026776,
                1.907691981357118
              ],
              [
                -56.98649848154429,
                1.907691948526745
              ],
              [
                -56.98234350368122,
                1.907691954354506
              ],
              [
                -56.980042993647096,
                1.905919980950206
              ],
              [
                -56.97590704331589,
                1.903591499561744
              ],
              [
                -56.9709805516277,
                1.906058466517228
              ],
              [
                -56.97005257951958,
                1.909459427668318
              ],
              [
                -56.96887609603377,
                1.912645404111731
              ],
              [
                -56.96868458509467,
                1.913161914778697
              ],
              [
                -56.96762256466686,
                1.917134886255601
              ],
              [
                -56.961014122757845,
                1.922483344419594
              ],
              [
                -56.95675566608692,
                1.924174840466581
              ],
              [
                -56.95319018168753,
                1.923618341987065
              ],
              [
                -56.94998620598636,
                1.923160352985166
              ],
              [
                -56.94936019517427,
                1.922281369390566
              ],
              [
                -56.94797769542264,
                1.920340360327272
              ],
              [
                -56.93981274606272,
                1.923087362557047
              ],
              [
                -56.938939780461844,
                1.92343683308854
              ],
              [
                -56.93455228959828,
                1.925195822663941
              ],
              [
                -56.92968583773953,
                1.927006304773221
              ],
              [
                -56.9233803778129,
                1.929299294402388
              ],
              [
                -56.919214370630314,
                1.930818279558101
              ],
              [
                -56.91629892697113,
                1.927363816688533
              ],
              [
                -56.91409794303609,
                1.92310234815422
              ],
              [
                -56.91074194385123,
                1.920718379357194
              ],
              [
                -56.9080859525182,
                1.917396894720746
              ],
              [
                -56.90808396953321,
                1.91738788877747
              ],
              [
                -56.906713983974974,
                1.910912927553742
              ],
              [
                -56.89176307989693,
                1.901252482762509
              ],
              [
                -56.888271579496454,
                1.899131498268833
              ],
              [
                -56.88670711721645,
                1.896037009506883
              ],
              [
                -56.88010966485241,
                1.895902999511657
              ],
              [
                -56.865679247569595,
                1.893344507747458
              ],
              [
                -56.86515322856141,
                1.894526026084176
              ],
              [
                -56.86402476297908,
                1.897060993641459
              ],
              [
                -56.85812029759105,
                1.895747494345139
              ],
              [
                -56.858581803316156,
                1.893442505139864
              ],
              [
                -56.85907228889599,
                1.890998551825892
              ],
              [
                -56.85576479413597,
                1.889646534504729
              ],
              [
                -56.85130782973927,
                1.891528032289028
              ],
              [
                -56.85088132238089,
                1.891708022024632
              ],
              [
                -56.845289363108115,
                1.893740017530837
              ],
              [
                -56.837326439821595,
                1.88845904091993
              ],
              [
                -56.83148096355055,
                1.889078045350098
              ],
              [
                -56.82897900445948,
                1.885028081435019
              ],
              [
                -56.82708801223829,
                1.881967104395169
              ],
              [
                -56.8252489910226,
                1.881335101907796
              ],
              [
                -56.82382001717981,
                1.880843078643376
              ],
              [
                -56.82268853443085,
                1.880453589808925
              ],
              [
                -56.82160854735194,
                1.880082090874408
              ],
              [
                -56.81476407638513,
                1.87711710085456
              ],
              [
                -56.81257308748189,
                1.876167611293528
              ],
              [
                -56.80874212931521,
                1.873834643088911
              ],
              [
                -56.805251155430014,
                1.873599147623489
              ],
              [
                -56.80193764090186,
                1.871617650326862
              ],
              [
                -56.80125166888211,
                1.869244165252838
              ],
              [
                -56.80106616722741,
                1.868607149258405
              ],
              [
                -56.800593678656526,
                1.866974669524261
              ],
              [
                -56.795232698926846,
                1.861816223900329
              ],
              [
                -56.796283698243656,
                1.858284215681134
              ],
              [
                -56.797397699375196,
                1.853958747529046
              ],
              [
                -56.79078723141896,
                1.852932289257832
              ],
              [
                -56.787543765404585,
                1.854819757350575
              ],
              [
                -56.78810425221645,
                1.86458868828298
              ],
              [
                -56.78425629519079,
                1.865849697191514
              ],
              [
                -56.78059531701358,
                1.867007164809694
              ],
              [
                -56.773416841769816,
                1.869276663371142
              ],
              [
                -56.767624901435795,
                1.876423607222324
              ],
              [
                -56.76624039887727,
                1.881218083276867
              ],
              [
                -56.76565140681103,
                1.884100068740891
              ],
              [
                -56.76554637837013,
                1.884482550273327
              ],
              [
                -56.76436438864731,
                1.888836025188908
              ],
              [
                -56.75924093851401,
                1.890760002493386
              ],
              [
                -56.75627945273907,
                1.892584501032381
              ],
              [
                -56.75459847008381,
                1.893621469949798
              ],
              [
                -56.753486460567636,
                1.895610995950108
              ],
              [
                -56.75215447333347,
                1.897994468374946
              ],
              [
                -56.75058549348074,
                1.900800432036832
              ],
              [
                -56.74916648975294,
                1.903338421448229
              ],
              [
                -56.745872537227356,
                1.90463942922335
              ],
              [
                -56.74435502458682,
                1.905503902281489
              ],
              [
                -56.742886542643774,
                1.906341418524967
              ],
              [
                -56.73709707250043,
                1.909112882211078
              ],
              [
                -56.73637206949057,
                1.91125936530783
              ],
              [
                -56.73420156843304,
                1.917683846689256
              ],
              [
                -56.72632262892949,
                1.918973827226339
              ],
              [
                -56.72259914326701,
                1.923362789148224
              ],
              [
                -56.72043366016138,
                1.926258762040552
              ],
              [
                -56.71561919777388,
                1.925764260832178
              ],
              [
                -56.712511713585094,
                1.92526427914388
              ],
              [
                -56.708511243141075,
                1.924443284253353
              ],
              [
                -56.70412477494789,
                1.924320283795286
              ],
              [
                -56.701601308287124,
                1.923030774318146
              ],
              [
                -56.69963430502373,
                1.92202579418154
              ],
              [
                -56.69645984557965,
                1.92040379064592
              ],
              [
                -56.690847858922375,
                1.917535302387299
              ],
              [
                -56.68212241643389,
                1.913076364418433
              ],
              [
                -56.67997293819712,
                1.916141824221516
              ],
              [
                -56.67888893919081,
                1.915688847810668
              ],
              [
                -56.67719344113001,
                1.914980326860733
              ],
              [
                -56.67522997879847,
                1.914159837551171
              ],
              [
                -56.67341097353528,
                1.913399853896064
              ],
              [
                -56.6684350179336,
                1.913873838463876
              ],
              [
                -56.66214905537864,
                1.915278826709291
              ],
              [
                -56.66013007107264,
                1.915645822793294
              ],
              [
                -56.65486661716875,
                1.916601311198439
              ],
              [
                -56.65175513068633,
                1.917341823584513
              ],
              [
                -56.65054111130221,
                1.917629811399943
              ],
              [
                -56.64602817404034,
                1.931470704585812
              ],
              [
                -56.64045219526175,
                1.931947217951227
              ],
              [
                -56.63835518785338,
                1.934458696283836
              ],
              [
                -56.63483621824409,
                1.937745687245497
              ],
              [
                -56.63189823536596,
                1.939559148800345
              ],
              [
                -56.63017323866105,
                1.94184762965928
              ],
              [
                -56.6293297416952,
                1.942966137258241
              ],
              [
                -56.62088979026256,
                1.946397621873514
              ],
              [
                -56.61765034751215,
                1.943929127752106
              ],
              [
                -56.61267737338529,
                1.939249176298318
              ],
              [
                -56.61037386958045,
                1.937194650797447
              ],
              [
                -56.60805591679219,
                1.935407684579993
              ],
              [
                -56.60728188587199,
                1.934810671182736
              ],
              [
                -56.606649919829664,
                1.934998167802106
              ],
              [
                -56.603000926682654,
                1.936080671830252
              ],
              [
                -56.597577478765416,
                1.937287170557171
              ],
              [
                -56.59527597741655,
                1.933580705643281
              ],
              [
                -56.59467898521183,
                1.929997707423615
              ],
              [
                -56.59577950117166,
                1.926623222348874
              ],
              [
                -56.595846965801826,
                1.926414731703335
              ],
              [
                -56.5949159956546,
                1.923041743041832
              ],
              [
                -56.5914265114121,
                1.920869752965606
              ],
              [
                -56.58614354022883,
                1.912691836852086
              ],
              [
                -56.58051207032136,
                1.907875870114464
              ],
              [
                -56.575817130139804,
                1.90785687291856
              ],
              [
                -56.569789158990766,
                1.909414329249088
              ],
              [
                -56.56034971334204,
                1.911605322558156
              ],
              [
                -56.55787073429981,
                1.914451810618865
              ],
              [
                -56.55496675982175,
                1.91778577043233
              ],
              [
                -56.55177326107061,
                1.917935791570032
              ],
              [
                -56.54894326917341,
                1.917502799547648
              ],
              [
                -56.5468598055043,
                1.917174289066294
              ],
              [
                -56.54578431538639,
                1.917003278859441
              ],
              [
                -56.54088884238109,
                1.917423268786761
              ],
              [
                -56.536437852466214,
                1.918158795815556
              ],
              [
                -56.53297239982375,
                1.920564774408628
              ],
              [
                -56.52946391684726,
                1.921568248982012
              ],
              [
                -56.52425694891074,
                1.925294220609972
              ],
              [
                -56.522214458157485,
                1.927939199063353
              ],
              [
                -56.521134464632965,
                1.929337712784729
              ],
              [
                -56.51727799717801,
                1.932824157320013
              ],
              [
                -56.51305603186175,
                1.933413175340714
              ],
              [
                -56.50909852776512,
                1.934361661128751
              ],
              [
                -56.507187033763074,
                1.93493114193015
              ],
              [
                -56.50274557764265,
                1.93625513616828
              ],
              [
                -56.498325605379236,
                1.937611122205248
              ],
              [
                -56.495103631627785,
                1.938499647583109
              ],
              [
                -56.491957156489455,
                1.942611624282612
              ],
              [
                -56.48752517105549,
                1.948404072815783
              ],
              [
                -56.48616670354817,
                1.95247453628154
              ],
              [
                -56.48249971423911,
                1.954752008599064
              ],
              [
                -56.47576975981955,
                1.952119051583336
              ],
              [
                -56.47333725556227,
                1.951204533761768
              ],
              [
                -56.471993790257194,
                1.9506995466214
              ],
              [
                -56.468391810466755,
                1.949712034934865
              ],
              [
                -56.46624331892869,
                1.950476556406719
              ],
              [
                -56.46420832668006,
                1.951200540171139
              ],
              [
                -56.46065533625994,
                1.951350531188933
              ],
              [
                -56.45356188499511,
                1.952888025272858
              ],
              [
                -56.4506864082408,
                1.956652521246813
              ],
              [
                -56.4443374437399,
                1.949377540197913
              ],
              [
                -56.440889496900844,
                1.952214534969499
              ],
              [
                -56.43517803767748,
                1.950834048210071
              ],
              [
                -56.432631049667464,
                1.948137046824889
              ],
              [
                -56.42230759701613,
                1.94613455175298
              ],
              [
                -56.422294103303294,
                1.941872580161697
              ],
              [
                -56.42200111531843,
                1.941347090457971
              ],
              [
                -56.420643605656956,
                1.93890459078059
              ],
              [
                -56.4112521753512,
                1.928793158149227
              ],
              [
                -56.4072157077507,
                1.929981169364625
              ],
              [
                -56.40303173720617,
                1.930764164142317
              ],
              [
                -56.39959577101553,
                1.927539169917319
              ],
              [
                -56.39642226529495,
                1.923799193081852
              ],
              [
                -56.389370830228216,
                1.923924681283852
              ],
              [
                -56.3882848360398,
                1.927918673275923
              ],
              [
                -56.38822481659909,
                1.930864151303753
              ],
              [
                -56.38819032034113,
                1.932571627305297
              ],
              [
                -56.380270373715376,
                1.933082146773481
              ],
              [
                -56.37849389501593,
                1.933196146535879
              ],
              [
                -56.37206441797117,
                1.933422619254382
              ],
              [
                -56.3698964300885,
                1.935974629208141
              ],
              [
                -56.36827594489307,
                1.937882110465489
              ],
              [
                -56.36365797697131,
                1.939270081214381
              ],
              [
                -56.35734602253426,
                1.941830559926888
              ],
              [
                -56.35333306464991,
                1.940044600288621
              ],
              [
                -56.34948108848346,
                1.939171587816727
              ],
              [
                -56.338782658699955,
                1.935914094764084
              ],
              [
                -56.33436916240428,
                1.937617581534007
              ],
              [
                -56.33199768631089,
                1.934898130385327
              ],
              [
                -56.32918570587791,
                1.932723633601104
              ],
              [
                -56.32696321480629,
                1.930098635888323
              ],
              [
                -56.32521975241267,
                1.928412636260555
              ],
              [
                -56.320817757716824,
                1.924156672445479
              ],
              [
                -56.31695529497307,
                1.922062698852039
              ],
              [
                -56.31368032391492,
                1.921991671387162
              ],
              [
                -56.312652320488915,
                1.921969190242425
              ],
              [
                -56.30916382322786,
                1.920349186702395
              ],
              [
                -56.30660038220067,
                1.918212718753103
              ],
              [
                -56.30554637833288,
                1.916781226684566
              ],
              [
                -56.302175380142536,
                1.912203277248919
              ],
              [
                -56.297978939480664,
                1.910323280192682
              ],
              [
                -56.29470092273737,
                1.909215267418425
              ],
              [
                -56.294004938108976,
                1.909493264431424
              ],
              [
                -56.290201484999336,
                1.911014751557014
              ],
              [
                -56.28943746696268,
                1.907106784540547
              ],
              [
                -56.28942399006803,
                1.907037282984862
              ],
              [
                -56.279714040742064,
                1.901232798702636
              ],
              [
                -56.273905093825846,
                1.89780784417212
              ],
              [
                -56.26523214451005,
                1.889703890028636
              ],
              [
                -56.26279015044047,
                1.884898929341498
              ],
              [
                -56.258267684594294,
                1.884067410455941
              ],
              [
                -56.24806974622807,
                1.878946443676673
              ],
              [
                -56.24345026386498,
                1.878654457289258
              ],
              [
                -56.24199428429806,
                1.88190392892423
              ],
              [
                -56.23950080773791,
                1.887467394338896
              ],
              [
                -56.23748980271114,
                1.890748397609962
              ],
              [
                -56.23683333149811,
                1.892262852654558
              ],
              [
                -56.23385634866611,
                1.89913833145221
              ],
              [
                -56.21444448499638,
                1.902552307007954
              ],
              [
                -56.21055400626321,
                1.897620808773718
              ],
              [
                -56.20839101286619,
                1.894878831881246
              ],
              [
                -56.203581527307726,
                1.896192350063503
              ],
              [
                -56.20039457558846,
                1.896671344085004
              ],
              [
                -56.198339560744635,
                1.893044845940164
              ],
              [
                -56.19441960521389,
                1.891731351339152
              ],
              [
                -56.190926618362134,
                1.892567337971371
              ],
              [
                -56.1818666895932,
                1.891898840781903
              ],
              [
                -56.178471692613556,
                1.89458535448101
              ],
              [
                -56.17295023980113,
                1.898955809702158
              ],
              [
                -56.171702752092976,
                1.897875305339783
              ],
              [
                -56.17009275179407,
                1.896481810900186
              ],
              [
                -56.16979426801482,
                1.895358836076148
              ],
              [
                -56.169531261971485,
                1.894369324399225
              ],
              [
                -56.16920275641525,
                1.893133355297112
              ],
              [
                -56.16920126942669,
                1.893126341342032
              ],
              [
                -56.16797529053443,
                1.888552366566776
              ],
              [
                -56.164835304308305,
                1.889847856325353
              ],
              [
                -56.163597805101816,
                1.890357877686417
              ],
              [
                -56.16235982289825,
                1.890869374037525
              ],
              [
                -56.159728317471775,
                1.891954860292193
              ],
              [
                -56.151804876563574,
                1.890893858959882
              ],
              [
                -56.149449892795076,
                1.886749878103938
              ],
              [
                -56.146339898699374,
                1.883220922064445
              ],
              [
                -56.144647414163124,
                1.880977907101823
              ],
              [
                -56.14056146283737,
                1.875561966998874
              ],
              [
                -56.13637747026236,
                1.873387463912689
              ],
              [
                -56.132430532298365,
                1.870731970004036
              ],
              [
                -56.131964029375915,
                1.870417469910232
              ],
              [
                -56.13193001863112,
                1.870280489786716
              ],
              [
                -56.1293380351013,
                1.859726551325254
              ],
              [
                -56.12542105945726,
                1.858884047708193
              ],
              [
                -56.11962111500366,
                1.85764606813822
              ],
              [
                -56.11704110219849,
                1.851524112029679
              ],
              [
                -56.11399363306506,
                1.854103598521947
              ],
              [
                -56.11226515676636,
                1.855565574597476
              ],
              [
                -56.10876267384333,
                1.853332107094439
              ],
              [
                -56.106331673861504,
                1.851780601471883
              ],
              [
                -56.102371214938756,
                1.849327128271342
              ],
              [
                -56.09809023302785,
                1.84705063884154
              ],
              [
                -56.092956284819664,
                1.85143110211313
              ],
              [
                -56.08960328758373,
                1.854291568695641
              ],
              [
                -56.08478532971892,
                1.853555078156523
              ],
              [
                -56.08292382256339,
                1.850224599206973
              ],
              [
                -56.08078685430769,
                1.846399144387088
              ],
              [
                -56.076818899996255,
                1.845633625271165
              ],
              [
                -56.07492338296363,
                1.850162595445352
              ],
              [
                -56.07486137732245,
                1.850310600470579
              ],
              [
                -56.065876442480494,
                1.852406572471504
              ],
              [
                -56.061192486696164,
                1.851950094159673
              ],
              [
                -56.05807549080354,
                1.851653077239787
              ],
              [
                -56.05436351568799,
                1.85153460800295
              ],
              [
                -56.049365069851326,
                1.851382601635629
              ],
              [
                -56.04540058900455,
                1.848861614892636
              ],
              [
                -56.042789591229734,
                1.84720110494334
              ],
              [
                -56.04189012486572,
                1.847748111157454
              ],
              [
                -56.040236122229665,
                1.848754103194528
              ],
              [
                -56.035843164101216,
                1.850136608051806
              ],
              [
                -56.03022917381655,
                1.844049127976618
              ],
              [
                -56.02844821078105,
                1.842117667010978
              ],
              [
                -56.02581520957049,
                1.84264863586241
              ],
              [
                -56.02352773715724,
                1.84311062725343
              ],
              [
                -56.016222792058095,
                1.839639170784687
              ],
              [
                -56.01593327395319,
                1.839514662513806
              ],
              [
                -56.00946182231651,
                1.836730661819606
              ],
              [
                -55.99834892109841,
                1.831860708409283
              ],
              [
                -55.99511342997557,
                1.835423194449118
              ],
              [
                -55.982638484084156,
                1.83608668280483
              ],
              [
                -55.9818465211719,
                1.839899144867901
              ],
              [
                -55.978763534330376,
                1.842486140260809
              ],
              [
                -55.97537605555662,
                1.840904149611774
              ],
              [
                -55.97223457183511,
                1.844466627657619
              ],
              [
                -55.97086057326682,
                1.84602509317715
              ],
              [
                -55.96950109528711,
                1.848777083955992
              ],
              [
                -55.96637759578877,
                1.847311117625478
              ],
              [
                -55.962839120651935,
                1.847017119579544
              ],
              [
                -55.962476646012945,
                1.846916097137391
              ],
              [
                -55.955976177357755,
                1.845103599148764
              ],
              [
                -55.95315221048775,
                1.849080596576449
              ],
              [
                -55.94951821080124,
                1.853181546975636
              ],
              [
                -55.94359874614276,
                1.859878026896929
              ],
              [
                -55.94088778172559,
                1.86316948078791
              ],
              [
                -55.93908229776946,
                1.865361455062092
              ],
              [
                -55.938199292170886,
                1.866432477866072
              ],
              [
                -55.93764028558966,
                1.867112949294462
              ],
              [
                -55.93663780171499,
                1.868354448958028
              ],
              [
                -55.935532320469136,
                1.869724954754998
              ],
              [
                -55.93471779944537,
                1.870735417969485
              ],
              [
                -55.933609313217914,
                1.872108917745083
              ],
              [
                -55.929414330626344,
                1.874423409563571
              ],
              [
                -55.9291088535496,
                1.874591421388548
              ],
              [
                -55.92335537050104,
                1.878768868671513
              ],
              [
                -55.91429294916807,
                1.885222839240128
              ],
              [
                -55.90880199005515,
                1.887112836444206
              ],
              [
                -55.903283527237306,
                1.888584322358603
              ],
              [
                -55.90250451772505,
                1.897887232651114
              ],
              [
                -55.90199751633333,
                1.903957189035071
              ],
              [
                -55.90162302352385,
                1.908419184909925
              ],
              [
                -55.90873597467098,
                1.912012663518744
              ],
              [
                -55.91105747118171,
                1.913907144845269
              ],
              [
                -55.911746457619905,
                1.914341658211671
              ],
              [
                -55.9199643992153,
                1.919513618874526
              ],
              [
                -55.920553408835524,
                1.928836039413846
              ],
              [
                -55.921424382842915,
                1.933792511422808
              ],
              [
                -55.92448936546173,
                1.936038009682881
              ],
              [
                -55.92675135542075,
                1.937694505548191
              ],
              [
                -55.92896234359135,
                1.939771458665468
              ],
              [
                -55.92520184416455,
                1.945715922936251
              ],
              [
                -55.924377367113216,
                1.947019420223134
              ],
              [
                -55.92348787845196,
                1.948425435824143
              ],
              [
                -55.921922370847476,
                1.950901398271596
              ],
              [
                -55.92344634404987,
                1.954367385156274
              ],
              [
                -55.92477986360593,
                1.957380855930957
              ],
              [
                -55.92507484851669,
                1.95804735967884
              ],
              [
                -55.92590984236348,
                1.960781341129665
              ],
              [
                -55.929171321088994,
                1.968747795230962
              ],
              [
                -55.930383800434534,
                1.97170927930991
              ],
              [
                -55.93532627348279,
                1.986097673109142
              ],
              [
                -55.934393287355114,
                1.997043104603528
              ],
              [
                -55.93027529780634,
                1.997345104438429
              ],
              [
                -55.92830731488821,
                1.995959591884462
              ],
              [
                -55.92549082461068,
                1.993977116970387
              ],
              [
                -55.922403872131575,
                1.991804623216039
              ],
              [
                -55.91774837415871,
                1.991745623248179
              ],
              [
                -55.913145419020125,
                1.991094118153721
              ],
              [
                -55.90736296222736,
                2.001794045790093
              ],
              [
                -55.90665596070765,
                2.008992503695056
              ],
              [
                -55.90617296539558,
                2.013917989466935
              ],
              [
                -55.910209934066295,
                2.014948484939353
              ],
              [
                -55.90986194208408,
                2.022605929951659
              ],
              [
                -55.90688493760585,
                2.024918912003396
              ],
              [
                -55.9035359884425,
                2.027520378062782
              ],
              [
                -55.90146399711883,
                2.029867859152124
              ],
              [
                -55.902520484865626,
                2.041603786976018
              ],
              [
                -55.9082264242047,
                2.047941764365818
              ],
              [
                -55.92003137445654,
                2.05348921274051
              ],
              [
                -55.921017356994206,
                2.05384872669289
              ],
              [
                -55.92528384403245,
                2.055403220841233
              ],
              [
                -55.92867078868812,
                2.057138692548333
              ],
              [
                -55.93220626552247,
                2.058419707262497
              ],
              [
                -55.9314317674925,
                2.061861662602881
              ],
              [
                -55.92956176812911,
                2.066622119019033
              ],
              [
                -55.929100782750666,
                2.067796129229186
              ],
              [
                -55.92611782364059,
                2.075626069295846
              ],
              [
                -55.92867078812959,
                2.079155030066659
              ],
              [
                -55.93641572519264,
                2.079231531916342
              ],
              [
                -55.940256719628685,
                2.081944044404521
              ],
              [
                -55.945201159831576,
                2.08839749485247
              ],
              [
                -55.948128674819976,
                2.092217456851295
              ],
              [
                -55.95274561230647,
                2.091455481225269
              ],
              [
                -55.95499110000461,
                2.090914999431907
              ],
              [
                -55.95578060097645,
                2.090724495913891
              ],
              [
                -55.96080506697797,
                2.089514993325782
              ],
              [
                -55.966049541363994,
                2.088976991428911
              ],
              [
                -55.9724045079878,
                2.103717412352306
              ],
              [
                -55.97002403111637,
                2.110960336433168
              ],
              [
                -55.96917052282286,
                2.113554315268617
              ],
              [
                -55.9728700000312,
                2.116734803686872
              ],
              [
                -55.97413399498165,
                2.116442325982894
              ],
              [
                -55.97759295095457,
                2.115643308256583
              ],
              [
                -55.976736946390155,
                2.119167820029496
              ],
              [
                -55.97659996010029,
                2.11973027632253
              ],
              [
                -55.976029471055746,
                2.122077792850474
              ],
              [
                -55.97495397862578,
                2.126502262686902
              ],
              [
                -55.981544912385885,
                2.132384196406877
              ],
              [
                -55.982554912884645,
                2.132381208740611
              ],
              [
                -55.98695538256131,
                2.132367733197726
              ],
              [
                -55.99076685718446,
                2.131857725706329
              ],
              [
                -55.99314284234072,
                2.140141679477852
              ],
              [
                -55.987845888000855,
                2.144784644569125
              ],
              [
                -55.986092886809864,
                2.14632112000759
              ],
              [
                -55.987027381835084,
                2.14941908611129
              ],
              [
                -55.99036636735785,
                2.15259407946821
              ],
              [
                -55.99271436430694,
                2.155614558522363
              ],
              [
                -55.99462282026657,
                2.158049523254846
              ],
              [
                -55.996818338263814,
                2.160852020683488
              ],
              [
                -56.002507281386904,
                2.1681144721532
              ],
              [
                -56.01353520213059,
                2.165877989214018
              ],
              [
                -56.01534620938753,
                2.166174004858184
              ],
              [
                -56.01722569020054,
                2.166482498442913
              ],
              [
                -56.02776311088335,
                2.168209994524861
              ],
              [
                -56.03450405718765,
                2.169315993456641
              ],
              [
                -56.04130451077515,
                2.174698959560024
              ],
              [
                -56.04541800030678,
                2.177955414662697
              ],
              [
                -56.048378485752025,
                2.182796897080129
              ],
              [
                -56.05448194876972,
                2.185175893536849
              ],
              [
                -56.05564641979223,
                2.189905345115205
              ],
              [
                -56.046300485790326,
                2.196470791365669
              ],
              [
                -56.04627647792941,
                2.196518816045182
              ],
              [
                -56.043156517014566,
                2.202735280549946
              ],
              [
                -56.041493036417634,
                2.206796748555084
              ],
              [
                -56.03644454262812,
                2.213853691944031
              ],
              [
                -56.03598102948022,
                2.217383646790318
              ],
              [
                -56.03725954337118,
                2.220157636126438
              ],
              [
                -56.042792995265906,
                2.221801123211009
              ],
              [
                -56.04231599423986,
                2.228320084573308
              ],
              [
                -56.04518397721388,
                2.230145092613357
              ],
              [
                -56.04803144909614,
                2.232666544109324
              ],
              [
                -56.05747888496758,
                2.233911074081427
              ],
              [
                -56.065174865697465,
                2.239519022058683
              ],
              [
                -56.06854033127807,
                2.241971511182699
              ],
              [
                -56.073031297711864,
                2.237982526637421
              ],
              [
                -56.07364481481347,
                2.23784805071398
              ],
              [
                -56.076788772837176,
                2.237158561232591
              ],
              [
                -56.078134754344774,
                2.239774532634753
              ],
              [
                -56.07889474398522,
                2.241250006273546
              ],
              [
                -56.0820682365132,
                2.244602980475269
              ],
              [
                -56.08740369435388,
                2.250420955335235
              ],
              [
                -56.092781147672895,
                2.247784483272188
              ],
              [
                -56.095093643190005,
                2.246650503415639
              ],
              [
                -56.095448643426394,
                2.241972010994176
              ],
              [
                -56.10338210582611,
                2.244003491369222
              ],
              [
                -56.108369076394645,
                2.245280000695773
              ],
              [
                -56.11182805153565,
                2.247294477719025
              ],
              [
                -56.119811505151965,
                2.250801464518206
              ],
              [
                -56.12124648553553,
                2.255123433862125
              ],
              [
                -56.12262798531288,
                2.259282914245865
              ],
              [
                -56.12675943729691,
                2.26082039460436
              ],
              [
                -56.13195492702646,
                2.263670385775174
              ],
              [
                -56.13483689025982,
                2.264510874259682
              ],
              [
                -56.138309876403774,
                2.266263876002874
              ],
              [
                -56.13612586871027,
                2.271467339416643
              ],
              [
                -56.12733991734968,
                2.276753795086518
              ],
              [
                -56.12476343922134,
                2.278768274114113
              ],
              [
                -56.1274439382965,
                2.287024234257501
              ],
              [
                -56.12301645728657,
                2.291330205737959
              ],
              [
                -56.11438202325907,
                2.298716651733609
              ],
              [
                -56.113921516084396,
                2.299110642012389
              ],
              [
                -56.11387652617558,
                2.299705650121346
              ],
              [
                -56.113538019869814,
                2.304153122034903
              ],
              [
                -56.11257052274246,
                2.308170595535205
              ],
              [
                -56.112836503897285,
                2.311580581407729
              ],
              [
                -56.10660506948504,
                2.318812021176399
              ],
              [
                -56.10315458131566,
                2.319779505701218
              ],
              [
                -56.100362101984395,
                2.320562489650797
              ],
              [
                -56.09318612666655,
                2.321759995410208
              ],
              [
                -56.09134964855762,
                2.330405421417553
              ],
              [
                -56.09299265673749,
                2.334169420433643
              ],
              [
                -56.093844137610134,
                2.336122889986052
              ],
              [
                -56.09420763399454,
                2.336955898678204
              ],
              [
                -56.09502012714749,
                2.338818885807443
              ],
              [
                -56.091647130325875,
                2.346164328748908
              ],
              [
                -56.08920467320948,
                2.351483801213508
              ],
              [
                -56.09164562943353,
                2.360588724658766
              ],
              [
                -56.08847766842773,
                2.364557183674329
              ],
              [
                -56.08944512937289,
                2.372861649823117
              ],
              [
                -56.085040689064726,
                2.374613115865828
              ],
              [
                -56.08031069076747,
                2.373690643245519
              ],
              [
                -56.079861227029284,
                2.369677677280538
              ],
              [
                -56.07488225861776,
                2.37085014590388
              ],
              [
                -56.07076876888562,
                2.368406170432612
              ],
              [
                -56.06897177039547,
                2.36559017119281
              ],
              [
                -56.06712581725995,
                2.362654689716711
              ],
              [
                -56.06363181417725,
                2.358362745527656
              ],
              [
                -56.06227181536023,
                2.35706325854184
              ],
              [
                -56.06086134188416,
                2.355715255855651
              ],
              [
                -56.059920343176884,
                2.352052264442506
              ],
              [
                -56.0592848640418,
                2.346802311492015
              ],
              [
                -56.0585623478126,
                2.343473338972348
              ],
              [
                -56.04830195149692,
                2.335395898188648
              ],
              [
                -56.04472243878577,
                2.334579386297921
              ],
              [
                -56.03891500713819,
                2.334547367527048
              ],
              [
                -56.03387252994046,
                2.333432901084882
              ],
              [
                -56.02900555738202,
                2.333017883136729
              ],
              [
                -56.029014578057975,
                2.336775879599432
              ],
              [
                -56.029022558873514,
                2.340134340669573
              ],
              [
                -56.02125611185057,
                2.34303531106694
              ],
              [
                -56.021933088813846,
                2.348005306832947
              ],
              [
                -56.02633905760142,
                2.34762327683872
              ],
              [
                -56.02586958138449,
                2.351271758845378
              ],
              [
                -56.02634855574238,
                2.355869256976606
              ],
              [
                -56.030219555931694,
                2.361488210602493
              ],
              [
                -56.032451547471474,
                2.364728681203367
              ],
              [
                -56.039399997074455,
                2.366813658983816
              ],
              [
                -56.03712848918574,
                2.371189637612546
              ],
              [
                -56.03594699154153,
                2.374817628501368
              ],
              [
                -56.033087505248254,
                2.376810618487995
              ],
              [
                -56.028952052883334,
                2.379617069713565
              ],
              [
                -56.02411658585974,
                2.382898073589956
              ],
              [
                -56.02366059488231,
                2.385545539127261
              ],
              [
                -56.022654589338316,
                2.391394511544144
              ],
              [
                -56.01479013528221,
                2.399071430603
              ],
              [
                -56.010067175082156,
                2.400775939802033
              ],
              [
                -56.00642968803559,
                2.402087442949661
              ],
              [
                -56.003431223208615,
                2.408209891850251
              ],
              [
                -56.00085573095556,
                2.413469842533563
              ],
              [
                -55.99786375166932,
                2.415032329256404
              ],
              [
                -55.993657275980965,
                2.417229301397429
              ],
              [
                -55.98939277887548,
                2.418831815405363
              ],
              [
                -55.986636793510435,
                2.422007777645198
              ],
              [
                -55.98610232517699,
                2.424224264948051
              ],
              [
                -55.98500530864889,
                2.428771731791874
              ],
              [
                -55.98517482657866,
                2.434414688909738
              ],
              [
                -55.99150127419558,
                2.439115672383486
              ],
              [
                -55.994398266576034,
                2.436186195590698
              ],
              [
                -55.99649374818298,
                2.438757660504049
              ],
              [
                -55.99801223175284,
                2.440621169846735
              ],
              [
                -55.99947621869693,
                2.442417637609347
              ],
              [
                -56.004346175945635,
                2.446407616230416
              ],
              [
                -56.00837064460093,
                2.444833653968512
              ],
              [
                -56.010805645889356,
                2.453443083491477
              ],
              [
                -56.00718017139737,
                2.455767082980822
              ],
              [
                -56.003929198586036,
                2.45783803825808
              ],
              [
                -56.00072870146215,
                2.459877030760403
              ],
              [
                -55.99783021637627,
                2.461969038021809
              ],
              [
                -55.99578272403141,
                2.463446524611159
              ],
              [
                -55.99480872405133,
                2.464149987654336
              ],
              [
                -55.99363124132958,
                2.464999011670878
              ],
              [
                -55.990320790934206,
                2.466987994453346
              ],
              [
                -55.98919277944404,
                2.471908451818613
              ],
              [
                -55.98750978565875,
                2.479249397122384
              ],
              [
                -55.97920231987378,
                2.485612353422395
              ],
              [
                -55.97832084862943,
                2.490965336022106
              ],
              [
                -55.97786983054987,
                2.493703299913686
              ],
              [
                -55.97733984780523,
                2.496920775632471
              ],
              [
                -55.98199782296974,
                2.499250261062073
              ],
              [
                -55.98258279108264,
                2.499583773089527
              ],
              [
                -55.99131023209247,
                2.504562249648735
              ],
              [
                -55.99121625793558,
                2.509434701682684
              ],
              [
                -55.99030026850536,
                2.513099682326622
              ],
              [
                -55.98871075161443,
                2.516201178414401
              ],
              [
                -55.98236480738843,
                2.519196155449603
              ],
              [
                -55.97899982097479,
                2.519425141711385
              ],
              [
                -55.977581330048615,
                2.527836568034321
              ],
              [
                -55.9730708866404,
                2.526349081126209
              ],
              [
                -55.969947900477486,
                2.529855559976858
              ],
              [
                -55.958101947228755,
                2.531397556488549
              ],
              [
                -55.95571798355671,
                2.531486529024617
              ],
              [
                -55.953333506888555,
                2.531576055556764
              ],
              [
                -55.94200606743206,
                2.532828013157665
              ],
              [
                -55.93776609654186,
                2.533430015640078
              ],
              [
                -55.93252713128566,
                2.533508504189501
              ],
              [
                -55.92827666515399,
                2.53223401498688
              ],
              [
                -55.92357469288933,
                2.531087529778723
              ],
              [
                -55.92152771262868,
                2.524114084809714
              ],
              [
                -55.92130870431264,
                2.523367066633599
              ],
              [
                -55.92341767923266,
                2.51918610885696
              ],
              [
                -55.91903873346484,
                2.519199119163321
              ],
              [
                -55.91511875647376,
                2.519945098825708
              ],
              [
                -55.91496722944046,
                2.519974096579652
              ],
              [
                -55.90964176957481,
                2.520560599771938
              ],
              [
                -55.90103132713429,
                2.517759610005295
              ],
              [
                -55.899752338389874,
                2.514989124732006
              ],
              [
                -55.89890285251223,
                2.510453181210786
              ],
              [
                -55.898214346498534,
                2.508947654846183
              ],
              [
                -55.89742335822661,
                2.507218170914998
              ],
              [
                -55.89456587489545,
                2.506574206100223
              ],
              [
                -55.89072791897152,
                2.5057101903732
              ],
              [
                -55.88569893204888,
                2.503977700917079
              ],
              [
                -55.88408247422927,
                2.499124735204345
              ],
              [
                -55.88392097339557,
                2.498780219408406
              ],
              [
                -55.88184395638549,
                2.494371258611898
              ],
              [
                -55.880228974588015,
                2.489413308589466
              ],
              [
                -55.873257511939194,
                2.48842880852785
              ],
              [
                -55.865590078770055,
                2.481911340565149
              ],
              [
                -55.86428110627244,
                2.481767858034487
              ],
              [
                -55.86277010008744,
                2.4816023585755
              ],
              [
                -55.86110811188354,
                2.481420363170337
              ],
              [
                -55.853855658099434,
                2.480345347612712
              ],
              [
                -55.85141067443624,
                2.473202393664108
              ],
              [
                -55.85017219872139,
                2.469585910979669
              ],
              [
                -55.84675922346801,
                2.467606438758388
              ],
              [
                -55.84372924550148,
                2.466303930228076
              ],
              [
                -55.84089123633864,
                2.464880941559369
              ],
              [
                -55.83784127162307,
                2.463240465244853
              ],
              [
                -55.83769576958591,
                2.463175942616668
              ],
              [
                -55.83766329180082,
                2.463161471700127
              ],
              [
                -55.83390380568186,
                2.461487989349462
              ],
              [
                -55.829645834858795,
                2.45960698518576
              ],
              [
                -55.82824732096919,
                2.459397979058541
              ],
              [
                -55.826523354200006,
                2.459140495133502
              ],
              [
                -55.81717690264165,
                2.459025471543312
              ],
              [
                -55.81452041782288,
                2.458993001805826
              ],
              [
                -55.80986796111427,
                2.458375001203724
              ],
              [
                -55.80675698431046,
                2.458132999681636
              ],
              [
                -55.80183200528581,
                2.457749987438193
              ],
              [
                -55.80045950818154,
                2.457687980354982
              ],
              [
                -55.79777502758144,
                2.457566994190376
              ],
              [
                -55.79559805062809,
                2.457651479856092
              ],
              [
                -55.79347857530412,
                2.457734488551991
              ],
              [
                -55.79162556935831,
                2.457532488217858
              ],
              [
                -55.78927610764274,
                2.45727649606145
              ],
              [
                -55.78328611770495,
                2.456366971902875
              ],
              [
                -55.78078866679662,
                2.462197959646783
              ],
              [
                -55.76881672216322,
                2.456777994026604
              ],
              [
                -55.765984238053754,
                2.455182505514206
              ],
              [
                -55.763912784675256,
                2.448892024171533
              ],
              [
                -55.762580778540936,
                2.445349559008612
              ],
              [
                -55.76190978151063,
                2.443563565522552
              ],
              [
                -55.761495787576045,
                2.442463569613711
              ],
              [
                -55.76095676856714,
                2.441030568851792
              ],
              [
                -55.76197676821808,
                2.44031407592852
              ],
              [
                -55.765873756961994,
                2.437575590329339
              ],
              [
                -55.76230430221257,
                2.434281632736154
              ],
              [
                -55.75828381124445,
                2.431381650393602
              ],
              [
                -55.75372885554673,
                2.426159674143222
              ],
              [
                -55.752943827710745,
                2.423052205312353
              ],
              [
                -55.75110785102912,
                2.418928231799289
              ],
              [
                -55.74746437772281,
                2.412851252501378
              ],
              [
                -55.747226403400894,
                2.412454282029621
              ],
              [
                -55.74235993388658,
                2.409617273989093
              ],
              [
                -55.73894895032682,
                2.408584307590821
              ],
              [
                -55.733578999657716,
                2.406958318410124
              ],
              [
                -55.71727057282256,
                2.402475338213081
              ],
              [
                -55.712500142538936,
                2.40301083902151
              ],
              [
                -55.70713064311645,
                2.403613839175534
              ],
              [
                -55.70511516200289,
                2.407004790143143
              ],
              [
                -55.70384567329173,
                2.409711262876435
              ],
              [
                -55.703381691971025,
                2.410655752494062
              ],
              [
                -55.70262916531603,
                2.412188241137968
              ],
              [
                -55.700602699015285,
                2.416315743242798
              ],
              [
                -55.697811725125725,
                2.421999182825023
              ],
              [
                -55.69468421771232,
                2.421082208764921
              ],
              [
                -55.68537280517749,
                2.417874209625976
              ],
              [
                -55.680606821537246,
                2.416534714777881
              ],
              [
                -55.67571837779358,
                2.414907729783141
              ],
              [
                -55.6733593883606,
                2.414122221132069
              ],
              [
                -55.668852896923006,
                2.416019244048234
              ],
              [
                -55.66843142459461,
                2.416196209733907
              ],
              [
                -55.66539893181613,
                2.417473204251907
              ],
              [
                -55.66257593371102,
                2.418661219428854
              ],
              [
                -55.65670697292549,
                2.421107697236639
              ],
              [
                -55.65156752087001,
                2.419359681955481
              ],
              [
                -55.64808805880621,
                2.418310213653247
              ],
              [
                -55.64752853749453,
                2.418141695567975
              ],
              [
                -55.64170907390508,
                2.416255206965645
              ],
              [
                -55.63502112605321,
                2.416971702892726
              ],
              [
                -55.63111014934748,
                2.417220207880679
              ],
              [
                -55.62358969157582,
                2.418898199169972
              ],
              [
                -55.61654674083867,
                2.420211665028885
              ],
              [
                -55.615112762423216,
                2.422298149755526
              ],
              [
                -55.61212978827956,
                2.426639615052296
              ],
              [
                -55.60289883677311,
                2.430788595409357
              ],
              [
                -55.599153376744425,
                2.431799609412361
              ],
              [
                -55.59420789435946,
                2.433247581104985
              ],
              [
                -55.58915291177585,
                2.43158159032246
              ],
              [
                -55.58622892726331,
                2.433035594687331
              ],
              [
                -55.57874398476355,
                2.436268564687512
              ],
              [
                -55.574187025698905,
                2.438199531318727
              ],
              [
                -55.572297546162915,
                2.431940569003191
              ],
              [
                -55.57123205331649,
                2.428412583935371
              ],
              [
                -55.57010655417502,
                2.423973637864268
              ],
              [
                -55.56079859870615,
                2.4267470972647
              ],
              [
                -55.55717062194645,
                2.42773159348156
              ],
              [
                -55.55101668820299,
                2.42988609207426
              ],
              [
                -55.52699535326386,
                2.43032909067929
              ],
              [
                -55.52424635406546,
                2.436296522243227
              ],
              [
                -55.517728386076804,
                2.437423022488828
              ],
              [
                -55.51719437951092,
                2.43753852553598
              ],
              [
                -55.51366592920283,
                2.438301025244461
              ],
              [
                -55.50835845442178,
                2.439211503343985
              ],
              [
                -55.503290503054735,
                2.440211004937801
              ],
              [
                -55.499182507562885,
                2.443725464224912
              ],
              [
                -55.481707138946966,
                2.439107006613245
              ],
              [
                -55.42888199804023,
                2.439330967463418
              ],
              [
                -55.38479628468989,
                2.418866117649356
              ],
              [
                -55.345078536682514,
                2.448451878307121
              ],
              [
                -55.35566044787275,
                2.476018732978634
              ],
              [
                -55.319682699691846,
                2.515832939335922
              ],
              [
                -55.27679845616098,
                2.512825432764487
              ],
              [
                -55.234240776890225,
                2.503847479140084
              ],
              [
                -55.206981452437375,
                2.523319364447011
              ],
              [
                -55.1722906365719,
                2.559803579249619
              ],
              [
                -55.146360828042724,
                2.56415206061855
              ],
              [
                -55.13463540125629,
                2.566118026142195
              ],
              [
                -55.12291049948589,
                2.568084014659588
              ],
              [
                -55.102488635783004,
                2.526139797557842
              ],
              [
                -55.05571044616129,
                2.553634105523811
              ],
              [
                -55.00324275841936,
                2.591395316100892
              ],
              [
                -54.95370810227167,
                2.584085846690765
              ],
              [
                -54.93485774210857,
                2.518956783769596
              ],
              [
                -54.918957356650346,
                2.500076905675277
              ],
              [
                -54.91509341597228,
                2.494871457206119
              ],
              [
                -54.911229417292745,
                2.489665971740235
              ],
              [
                -54.87170822442017,
                2.434101849481547
              ],
              [
                -54.87552568254241,
                2.427402872747508
              ],
              [
                -54.87575567016393,
                2.426999378533869
              ],
              [
                -54.87420219232468,
                2.415628458249077
              ],
              [
                -54.86720475168946,
                2.409241020656918
              ],
              [
                -54.865589278416245,
                2.403196540631926
              ],
              [
                -54.826673541124634,
                2.362486821325935
              ],
              [
                -54.807069182484526,
                2.341979456255555
              ],
              [
                -54.805814665840046,
                2.33575350259495
              ],
              [
                -54.80456068718615,
                2.329529037922709
              ],
              [
                -54.79375575602375,
                2.313039662911964
              ],
              [
                -54.801539219217915,
                2.308050681948774
              ],
              [
                -54.803116712773296,
                2.305055695551748
              ],
              [
                -54.806727684150786,
                2.298199755706178
              ],
              [
                -54.803173215748885,
                2.291716774991569
              ],
              [
                -54.80242771011714,
                2.290357299858989
              ],
              [
                -54.80325221382638,
                2.289871789384427
              ],
              [
                -54.8085921815615,
                2.286729322200566
              ],
              [
                -54.81092816060184,
                2.266683476282961
              ],
              [
                -54.80469171914798,
                2.256272531003912
              ],
              [
                -54.79845626467663,
                2.245863109722499
              ],
              [
                -54.79438628522716,
                2.240669127206536
              ],
              [
                -54.794384297241415,
                2.240665639229257
              ],
              [
                -54.78109389546137,
                2.223702753607488
              ],
              [
                -54.77461992422663,
                2.220646267019845
              ],
              [
                -54.77014493855318,
                2.208744363334434
              ],
              [
                -54.76261999022941,
                2.202623872956645
              ],
              [
                -54.762354022065544,
                2.192746471042993
              ],
              [
                -54.76898597871492,
                2.187893990628907
              ],
              [
                -54.76740099626353,
                2.181217537844067
              ],
              [
                -54.78228187189037,
                2.174694595168358
              ],
              [
                -54.78273086798301,
                2.174497576627336
              ],
              [
                -54.78259087808309,
                2.173931096375522
              ],
              [
                -54.78098839966811,
                2.167460136190165
              ],
              [
                -54.78659586960923,
                2.164176658908849
              ],
              [
                -54.79089881794101,
                2.161657682101134
              ],
              [
                -54.78993183420382,
                2.158812199841169
              ],
              [
                -54.78915183206114,
                2.156518186949099
              ],
              [
                -54.792988323505966,
                2.153896709675287
              ],
              [
                -54.79803678119566,
                2.150446764313992
              ],
              [
                -54.80047726437924,
                2.142857823825274
              ],
              [
                -54.80576224855903,
                2.139234838678241
              ],
              [
                -54.80339177301395,
                2.133318361513077
              ],
              [
                -54.80308774325212,
                2.132560387488213
              ],
              [
                -54.80373974927562,
                2.131463901019419
              ],
              [
                -54.80872771185305,
                2.123069447664378
              ],
              [
                -54.806616725886265,
                2.116129005415686
              ],
              [
                -54.80420124822461,
                2.108186541771403
              ],
              [
                -54.80877021109386,
                2.104653065768626
              ],
              [
                -54.8133386959674,
                2.101120090756499
              ],
              [
                -54.80877024647625,
                2.086543189776859
              ],
              [
                -54.80567724682402,
                2.076674748780155
              ],
              [
                -54.808770219212406,
                2.073880288368687
              ],
              [
                -54.81158772034765,
                2.071335275206025
              ],
              [
                -54.81233971478871,
                2.063120340292499
              ],
              [
                -54.80654123734066,
                2.055430388931679
              ],
              [
                -54.80547678278452,
                2.054018896043656
              ],
              [
                -54.80597774486283,
                2.052686413089549
              ],
              [
                -54.810843728472626,
                2.039743502936311
              ],
              [
                -54.80675977305012,
                2.037675500546802
              ],
              [
                -54.80331328314729,
                2.02612907762285
              ],
              [
                -54.79448234808015,
                2.017122652183169
              ],
              [
                -54.79047939626126,
                2.007562226847985
              ],
              [
                -54.78038193017153,
                1.999916777982377
              ],
              [
                -54.78006344930282,
                1.99982475150534
              ],
              [
                -54.76853304146859,
                1.996511797318432
              ],
              [
                -54.77165252285545,
                1.978722901970132
              ],
              [
                -54.76350955659005,
                1.979099416144285
              ],
              [
                -54.75550764179144,
                1.974513930490003
              ],
              [
                -54.753311153283896,
                1.967476488855712
              ],
              [
                -54.755373133557,
                1.96061202926376
              ],
              [
                -54.75964358534385,
                1.960510014146038
              ],
              [
                -54.757871627912486,
                1.957446056099352
              ],
              [
                -54.7633525776706,
                1.946592638068303
              ],
              [
                -54.76453508249715,
                1.944251148022377
              ],
              [
                -54.771096544063184,
                1.940806164835623
              ],
              [
                -54.76542657750938,
                1.907957373366189
              ],
              [
                -54.7613735848668,
                1.905891391034616
              ],
              [
                -54.763039580375505,
                1.89279551481321
              ],
              [
                -54.75857110754931,
                1.890460019172228
              ],
              [
                -54.7570636325006,
                1.886788517243846
              ],
              [
                -54.752596177944866,
                1.875911102560671
              ],
              [
                -54.75261218083909,
                1.872144649675269
              ],
              [
                -54.752699683763225,
                1.851282772754087
              ],
              [
                -54.74799220315989,
                1.842637335122644
              ],
              [
                -54.74334871929887,
                1.84100933374548
              ],
              [
                -54.73928876852677,
                1.839586355157716
              ],
              [
                -54.740122743889145,
                1.832359902539457
              ],
              [
                -54.73353280050608,
                1.832283413317221
              ],
              [
                -54.744718248592456,
                1.820369972635923
              ],
              [
                -54.75017918897054,
                1.814554032803559
              ],
              [
                -54.746428224953874,
                1.806075085305746
              ],
              [
                -54.75393115836329,
                1.798118125236068
              ],
              [
                -54.75217318580189,
                1.790988180257784
              ],
              [
                -54.74595425000435,
                1.786759222819906
              ],
              [
                -54.744288222711575,
                1.77610928230364
              ],
              [
                -54.71803942556988,
                1.771377817134462
              ],
              [
                -54.701757041045695,
                1.772583277966311
              ],
              [
                -54.70094053404937,
                1.774184774086956
              ],
              [
                -54.700005529778835,
                1.776018758627439
              ],
              [
                -54.697698574598206,
                1.773848765501565
              ],
              [
                -54.691671589926976,
                1.768179833751105
              ],
              [
                -54.6785341896784,
                1.769200294618674
              ],
              [
                -54.67070423135604,
                1.765964342208842
              ],
              [
                -54.65828234071149,
                1.769758792759328
              ],
              [
                -54.65107037995447,
                1.77196226523222
              ],
              [
                -54.650534882555235,
                1.771756286470336
              ],
              [
                -54.64315142820788,
                1.768909296590387
              ],
              [
                -54.63148550156491,
                1.776821734830087
              ],
              [
                -54.625395539908034,
                1.780952199709108
              ],
              [
                -54.618410597395965,
                1.780298237293626
              ],
              [
                -54.611229638195475,
                1.779626713946898
              ],
              [
                -54.607534652208045,
                1.781607713776608
              ],
              [
                -54.60142520691465,
                1.784883167341513
              ],
              [
                -54.58924927372674,
                1.780849204180021
              ],
              [
                -54.58056383809686,
                1.777971713192279
              ],
              [
                -54.577023885999225,
                1.78034169945514
              ],
              [
                -54.5704219117145,
                1.784762184229404
              ],
              [
                -54.570568919184666,
                1.779018727651951
              ],
              [
                -54.57059742627651,
                1.777900234133972
              ],
              [
                -54.56290094779511,
                1.776242719254486
              ],
              [
                -54.536507162529524,
                1.770560278547347
              ],
              [
                -54.52761769592264,
                1.76524929881571
              ],
              [
                -54.52632221522801,
                1.762453318187316
              ],
              [
                -54.52347923264541,
                1.756318858495719
              ],
              [
                -54.51192929805676,
                1.753722370962406
              ],
              [
                -54.508064341020656,
                1.748581902377764
              ],
              [
                -54.50404937229821,
                1.750261895135902
              ],
              [
                -54.501343401009585,
                1.751394406882399
              ],
              [
                -54.5005368985123,
                1.75082691047705
              ],
              [
                -54.49545642217686,
                1.747252406120842
              ],
              [
                -54.48512251482758,
                1.75160538540949
              ],
              [
                -54.48474547989965,
                1.753366860526531
              ],
              [
                -54.484233008715584,
                1.755760363378972
              ],
              [
                -54.47914605892239,
                1.754267356095929
              ],
              [
                -54.47078658714219,
                1.751813868425077
              ],
              [
                -54.47025410984294,
                1.755216851510074
              ],
              [
                -54.470122581509486,
                1.756058865839906
              ],
              [
                -54.46431864727461,
                1.759498316353237
              ],
              [
                -54.44691624682267,
                1.756565361624848
              ],
              [
                -54.43412033854626,
                1.754409362859411
              ],
              [
                -54.42912485602165,
                1.757594338265954
              ],
              [
                -54.42417391720998,
                1.760749806875967
              ],
              [
                -54.419126934540614,
                1.757861828963704
              ],
              [
                -54.4174054692508,
                1.756877347130185
              ],
              [
                -54.41472548795055,
                1.75748983435237
              ],
              [
                -54.40345705538256,
                1.760066316250263
              ],
              [
                -54.39904708373394,
                1.756164339305986
              ],
              [
                -54.386954667961746,
                1.745464895027664
              ],
              [
                -54.3820917000906,
                1.754730853670744
              ],
              [
                -54.37722923820756,
                1.763996785292421
              ],
              [
                -54.35120441213564,
                1.754379319296571
              ],
              [
                -54.35033990954988,
                1.747698863915986
              ],
              [
                -54.34370846896182,
                1.74698837003158
              ],
              [
                -54.34386297042463,
                1.744981381540705
              ],
              [
                -54.34417443733938,
                1.740937927755922
              ],
              [
                -54.33609300336443,
                1.740498911695996
              ],
              [
                -54.33285051458966,
                1.742172936653481
              ],
              [
                -54.3290700353381,
                1.744124410612569
              ],
              [
                -54.32295358970527,
                1.737811937475285
              ],
              [
                -54.31639313966807,
                1.737100431627307
              ],
              [
                -54.30881620220611,
                1.741360398140039
              ],
              [
                -54.305287223783495,
                1.737209429144655
              ],
              [
                -54.312925187632935,
                1.733764449170071
              ],
              [
                -54.31022020242722,
                1.730744975777862
              ],
              [
                -54.30929121088176,
                1.729707978512206
              ],
              [
                -54.308157701454235,
                1.729687500370181
              ],
              [
                -54.3047892299586,
                1.729626989945935
              ],
              [
                -54.30212722946958,
                1.726597012638723
              ],
              [
                -54.29995528057268,
                1.724124520709329
              ],
              [
                -54.298284259609,
                1.72462704292363
              ],
              [
                -54.29270029349132,
                1.726305493276678
              ],
              [
                -54.28940232283039,
                1.720892565825414
              ],
              [
                -54.28115287614069,
                1.724158531860173
              ],
              [
                -54.28245536127792,
                1.71656707517915
              ],
              [
                -54.2843883777381,
                1.715025576006606
              ],
              [
                -54.291872322191004,
                1.709055135931819
              ],
              [
                -54.279594888451804,
                1.707993128074895
              ],
              [
                -54.278163910838266,
                1.708712638893424
              ],
              [
                -54.2740069361069,
                1.710803619833919
              ],
              [
                -54.26631248110194,
                1.708375649271992
              ],
              [
                -54.26909197631105,
                1.705121149813643
              ],
              [
                -54.26517851662068,
                1.696092733518745
              ],
              [
                -54.26555901111712,
                1.695929225709105
              ],
              [
                -54.26875745807343,
                1.694553228724895
              ],
              [
                -54.262256001911794,
                1.68869324252142
              ],
              [
                -54.26317303273188,
                1.680472299961573
              ],
              [
                -54.24972161582542,
                1.68388678880058
              ],
              [
                -54.24884911360516,
                1.684108297103014
              ],
              [
                -54.24784162571372,
                1.682528780474077
              ],
              [
                -54.243958166112364,
                1.676440330452441
              ],
              [
                -54.23934115999136,
                1.676389363692439
              ],
              [
                -54.22965225080158,
                1.676282353098417
              ],
              [
                -54.228337741435716,
                1.668441411480554
              ],
              [
                -54.22786477326022,
                1.665618909335688
              ],
              [
                -54.218811809784896,
                1.65714997310669
              ],
              [
                -54.202454448032256,
                1.649319014889435
              ],
              [
                -54.19698148362978,
                1.632074650516816
              ],
              [
                -54.19061500680389,
                1.6252191711162
              ],
              [
                -54.186924541800295,
                1.628295677583142
              ],
              [
                -54.187057545029404,
                1.645169555175112
              ],
              [
                -54.18706953761066,
                1.646633041337908
              ],
              [
                -54.181730071621885,
                1.65838444005657
              ],
              [
                -54.17787512157333,
                1.657758952354011
              ],
              [
                -54.17564563570072,
                1.648278029597489
              ],
              [
                -54.1684301829267,
                1.64431404756854
              ],
              [
                -54.15639624080036,
                1.643070042122778
              ],
              [
                -54.151983771143755,
                1.639644080142004
              ],
              [
                -54.1463663137496,
                1.644078553991931
              ],
              [
                -54.143177848837254,
                1.640923052481569
              ],
              [
                -54.14195386064652,
                1.629559634660379
              ],
              [
                -54.14187386535184,
                1.628819136624217
              ],
              [
                -54.13962436961064,
                1.627996657637207
              ],
              [
                -54.12931792852899,
                1.624229185605499
              ],
              [
                -54.13300940033516,
                1.617450738068737
              ],
              [
                -54.12259699431775,
                1.607710802217546
              ],
              [
                -54.122093010957094,
                1.602110824782557
              ],
              [
                -54.12360497401277,
                1.601270808778723
              ],
              [
                -54.13089842404596,
                1.597218877695962
              ],
              [
                -54.127869949592565,
                1.591623389647379
              ],
              [
                -54.12234401272517,
                1.591178388390341
              ],
              [
                -54.11806452349833,
                1.581791450570673
              ],
              [
                -54.11567204207094,
                1.583878952989486
              ],
              [
                -54.11059706010484,
                1.588306399057465
              ],
              [
                -54.101512113426644,
                1.590938382292981
              ],
              [
                -54.10146115117734,
                1.584614921836947
              ],
              [
                -54.10987006186652,
                1.575297490416622
              ],
              [
                -54.11270957930066,
                1.568638541844057
              ],
              [
                -54.1170400265018,
                1.558484613095219
              ],
              [
                -54.11403753914425,
                1.551534172168412
              ],
              [
                -54.10829011572799,
                1.551278674618846
              ],
              [
                -54.098292675854054,
                1.550834177404073
              ],
              [
                -54.084103772231735,
                1.526644821000447
              ],
              [
                -54.087070753139,
                1.51818438154013
              ],
              [
                -54.08854172816571,
                1.513989933061292
              ],
              [
                -54.08467776382099,
                1.505774989466923
              ],
              [
                -54.08826123761888,
                1.4923095707526
              ],
              [
                -54.085980248690674,
                1.488609093142446
              ],
              [
                -54.07747830726172,
                1.486000590879836
              ],
              [
                -54.07895329844112,
                1.495213553254872
              ],
              [
                -54.0686743981239,
                1.501279515277282
              ],
              [
                -54.061952917531656,
                1.495056061702335
              ],
              [
                -54.055454961401225,
                1.493800054770695
              ],
              [
                -54.03951258874716,
                1.506471469117608
              ],
              [
                -54.02409370160718,
                1.500167997253242
              ],
              [
                -54.021750688328,
                1.504984975325614
              ],
              [
                -54.02070869987365,
                1.50712845667162
              ],
              [
                -54.027093176873784,
                1.517146891589953
              ],
              [
                -54.019789208153675,
                1.516075394231879
              ],
              [
                -54.00856378872614,
                1.519977360533486
              ],
              [
                -53.999040850863956,
                1.505080977826884
              ],
              [
                -54.00239234808711,
                1.492023069480858
              ],
              [
                -54.00298432959149,
                1.489717065133875
              ],
              [
                -53.99698187475521,
                1.476081658679705
              ],
              [
                -53.9856764670532,
                1.479351631289175
              ],
              [
                -53.97564853385645,
                1.472588680738466
              ],
              [
                -53.97071453966174,
                1.479191655226161
              ],
              [
                -53.96659355622422,
                1.484707108199328
              ],
              [
                -53.95542166028444,
                1.480205127187057
              ],
              [
                -53.953597680012834,
                1.468281195157319
              ],
              [
                -53.9430257554368,
                1.471613160498629
              ],
              [
                -53.93526830249999,
                1.467362195535067
              ],
              [
                -53.92827784709847,
                1.469490700730539
              ],
              [
                -53.925400354222404,
                1.465788704090311
              ],
              [
                -53.93010034218675,
                1.458011778490246
              ],
              [
                -53.92989983611741,
                1.450241306822288
              ],
              [
                -53.93187233310739,
                1.448948817938405
              ],
              [
                -53.939985287600166,
                1.443632387426534
              ],
              [
                -53.93986574958846,
                1.437760932959404
              ],
              [
                -53.935377303046124,
                1.43646443877934
              ],
              [
                -53.9220148727308,
                1.442571395898866
              ],
              [
                -53.920592406456954,
                1.446760864369325
              ],
              [
                -53.91704342021858,
                1.457215773157954
              ],
              [
                -53.895513581523204,
                1.455437297706824
              ],
              [
                -53.89276259942955,
                1.443692367357068
              ],
              [
                -53.88023966140485,
                1.440546397017285
              ],
              [
                -53.87625819018651,
                1.435762397477331
              ],
              [
                -53.88394164628473,
                1.416449557279015
              ],
              [
                -53.88726463755421,
                1.408098608245556
              ],
              [
                -53.88234317908328,
                1.40105913972064
              ],
              [
                -53.878503714161226,
                1.405051132044535
              ],
              [
                -53.87581771470512,
                1.407843584681133
              ],
              [
                -53.85448835858437,
                1.401906159478361
              ],
              [
                -53.84381994356503,
                1.406617597571982
              ],
              [
                -53.84112493656517,
                1.402374131667482
              ],
              [
                -53.8487998913924,
                1.399508165525522
              ],
              [
                -53.85059638841522,
                1.398837647404554
              ],
              [
                -53.85234787954604,
                1.394046689066451
              ],
              [
                -53.84985638970699,
                1.392152199352811
              ],
              [
                -53.83028901522475,
                1.39416318692545
              ],
              [
                -53.82311806840386,
                1.389926725100311
              ],
              [
                -53.80795016131783,
                1.391931213584158
              ],
              [
                -53.80457221401273,
                1.397540167049505
              ],
              [
                -53.80700618965708,
                1.402324619227493
              ],
              [
                -53.81552812887893,
                1.408915583422566
              ],
              [
                -53.82229108623581,
                1.414145546465016
              ],
              [
                -53.8151816335894,
                1.422194973687732
              ],
              [
                -53.80350769001193,
                1.423836968246486
              ],
              [
                -53.79120779636415,
                1.416982019080951
              ],
              [
                -53.78426184561301,
                1.404942117031461
              ],
              [
                -53.783237831872405,
                1.403167124818921
              ],
              [
                -53.77412889552126,
                1.397214164231314
              ],
              [
                -53.767571952979516,
                1.396229158590892
              ],
              [
                -53.76599696692644,
                1.399747647504153
              ],
              [
                -53.76198598125234,
                1.408706056163831
              ],
              [
                -53.75607051837202,
                1.397607145006031
              ],
              [
                -53.75462052769452,
                1.394886178109816
              ],
              [
                -53.73184471015159,
                1.386599241648538
              ],
              [
                -53.731206711253876,
                1.392830177393628
              ],
              [
                -53.730872716875915,
                1.396091142277357
              ],
              [
                -53.71492931174193,
                1.405689103201587
              ],
              [
                -53.71554031213825,
                1.418247482353488
              ],
              [
                -53.72356973182833,
                1.428541425314226
              ],
              [
                -53.71670629150837,
                1.43207340502464
              ],
              [
                -53.696346431903315,
                1.423241965695418
              ],
              [
                -53.695320962786,
                1.418454507899068
              ],
              [
                -53.71295283484936,
                1.396746139309718
              ],
              [
                -53.711622331475475,
                1.388341226901812
              ],
              [
                -53.71097882261139,
                1.384276221270801
              ],
              [
                -53.70097693639041,
                1.378229258220291
              ],
              [
                -53.692501965772095,
                1.383012235208456
              ],
              [
                -53.68701301629245,
                1.386109707174841
              ],
              [
                -53.68256352466346,
                1.384667229065877
              ],
              [
                -53.68153756351063,
                1.379956243747367
              ],
              [
                -53.67998657336821,
                1.37283281465088
              ],
              [
                -53.666455642793814,
                1.373391301246428
              ],
              [
                -53.662110693371176,
                1.388043196207032
              ],
              [
                -53.657286715847334,
                1.404309595096736
              ],
              [
                -53.64981926554941,
                1.408564575789667
              ],
              [
                -53.644123810629175,
                1.405861088974372
              ],
              [
                -53.63995282043584,
                1.392669181525485
              ],
              [
                -53.644648797989525,
                1.380555262500146
              ],
              [
                -53.64304080000214,
                1.364199363949248
              ],
              [
                -53.64856776887992,
                1.357413394955183
              ],
              [
                -53.652690753398915,
                1.355177931877296
              ],
              [
                -53.66259569438641,
                1.349807982112495
              ],
              [
                -53.650483259835724,
                1.336535041633181
              ],
              [
                -53.64139984354956,
                1.335280567409597
              ],
              [
                -53.63276438869233,
                1.347671993888893
              ],
              [
                -53.59473463923205,
                1.358467408496388
              ],
              [
                -53.57020780937272,
                1.337616514159265
              ],
              [
                -53.56498783730543,
                1.340060996601995
              ],
              [
                -53.56413036512953,
                1.345354489549917
              ],
              [
                -53.56337735636218,
                1.350006428863799
              ],
              [
                -53.54801196177442,
                1.348846458806506
              ],
              [
                -53.53806652366899,
                1.341174516961062
              ],
              [
                -53.53290457610313,
                1.322742627013944
              ],
              [
                -53.54340749107854,
                1.316088180093129
              ],
              [
                -53.543624524631646,
                1.31595069206559
              ],
              [
                -53.5607603713039,
                1.311142715831274
              ],
              [
                -53.56215139364637,
                1.306895750869159
              ],
              [
                -53.55620694088095,
                1.285118382341603
              ],
              [
                -53.554217926772075,
                1.282859905282801
              ],
              [
                -53.55222845866599,
                1.280601941221153
              ],
              [
                -53.53003660137706,
                1.273935477398923
              ],
              [
                -53.52284615233082,
                1.261651060370644
              ],
              [
                -53.53009462994141,
                1.245647144093008
              ],
              [
                -53.53262008427529,
                1.242948687816054
              ],
              [
                -53.54584151002213,
                1.228822288787456
              ],
              [
                -53.54484602296058,
                1.221861802739872
              ],
              [
                -53.53553457169067,
                1.212584877947859
              ],
              [
                -53.524729159091805,
                1.212029370862521
              ],
              [
                -53.4732590138006,
                1.239646173816657
              ],
              [
                -53.438288264656364,
                1.232446730570816
              ],
              [
                -53.43326527239699,
                1.234801719716158
              ],
              [
                -53.433123784292256,
                1.235189734058047
              ],
              [
                -53.43006331465458,
                1.243572664622627
              ],
              [
                -53.4260403370986,
                1.243034144574364
              ],
              [
                -53.42506785261837,
                1.226077755507415
              ],
              [
                -53.42482284601239,
                1.22179431353734
              ],
              [
                -53.41695788264098,
                1.203089952131166
              ],
              [
                -53.411082941193804,
                1.199493460544563
              ],
              [
                -53.40251048937201,
                1.194245502717446
              ],
              [
                -53.39559952779698,
                1.179607098911395
              ],
              [
                -53.399398034346625,
                1.15872325123627
              ],
              [
                -53.403929486683715,
                1.151485780065057
              ],
              [
                -53.41164892774537,
                1.14795382026649
              ],
              [
                -53.42079538059285,
                1.147675314632385
              ],
              [
                -53.43090630508873,
                1.152447268919434
              ],
              [
                -53.434180279585185,
                1.153992776974672
              ],
              [
                -53.43902824782131,
                1.152812788759863
              ],
              [
                -53.44387670305722,
                1.151632782543459
              ],
              [
                -53.456763115562126,
                1.138607871867348
              ],
              [
                -53.45906512961607,
                1.127847956109896
              ],
              [
                -53.455399643190994,
                1.110678082797133
              ],
              [
                -53.455344156584395,
                1.077683791128845
              ],
              [
                -53.453786647626224,
                1.074612805674936
              ],
              [
                -53.45222965366355,
                1.071541862221239
              ],
              [
                -53.45111316936553,
                1.070765833304315
              ],
              [
                -53.43548029221759,
                1.059894927530404
              ],
              [
                -53.4342108155403,
                1.048327502626846
              ],
              [
                -53.42698633633364,
                1.043631527342748
              ],
              [
                -53.4218479006503,
                1.03405359441049
              ],
              [
                -53.420868907992904,
                1.029263115686498
              ],
              [
                -53.42578485031607,
                1.024031669794954
              ],
              [
                -53.42945234293785,
                1.020127185737633
              ],
              [
                -53.42634887486357,
                0.992742400517963
              ],
              [
                -53.41828141208823,
                0.990020893797227
              ],
              [
                -53.4169864229536,
                0.989584408568905
              ],
              [
                -53.4103224582584,
                0.982359944506435
              ],
              [
                -53.41018847758924,
                0.956959635249738
              ],
              [
                -53.410373464404955,
                0.95640462802692
              ],
              [
                -53.413259941921936,
                0.947735698016473
              ],
              [
                -53.411014485547454,
                0.929659816888017
              ],
              [
                -53.362693310372116,
                0.8976350271493
              ],
              [
                -53.360871319924996,
                0.896428033080277
              ],
              [
                -53.357663366250996,
                0.892497565252037
              ],
              [
                -53.35445536157644,
                0.888568098418986
              ],
              [
                -53.32860203639395,
                0.874199174384675
              ],
              [
                -53.3018152146987,
                0.859311779829137
              ],
              [
                -53.294428303834486,
                0.852682310794765
              ],
              [
                -53.287041350971876,
                0.846052854769451
              ],
              [
                -53.28250436177223,
                0.838617433542718
              ],
              [
                -53.27434791392982,
                0.825248012047959
              ],
              [
                -53.264507496935344,
                0.817388055094869
              ],
              [
                -53.26450348396308,
                0.817384567118005
              ],
              [
                -53.25466856693526,
                0.809529130150266
              ],
              [
                -53.240866660992474,
                0.801573659428378
              ],
              [
                -53.213928837570315,
                0.786046284534443
              ],
              [
                -53.21143738503276,
                0.781887299417421
              ],
              [
                -53.20651188955067,
                0.773664854546122
              ],
              [
                -53.20316092424153,
                0.775460854022556
              ],
              [
                -53.201956422397934,
                0.776106846517614
              ],
              [
                -53.18979503925353,
                0.768698877361366
              ],
              [
                -53.18412957914668,
                0.747820549126773
              ],
              [
                -53.15692925490586,
                0.729390651102809
              ],
              [
                -53.153763269033774,
                0.7245547125297
              ],
              [
                -53.153756259082755,
                0.724544205600129
              ],
              [
                -53.15059730515965,
                0.719718732958716
              ],
              [
                -53.14339535266399,
                0.696578376933854
              ],
              [
                -53.13637141401336,
                0.693237397871013
              ],
              [
                -53.13257591984264,
                0.694555884573729
              ],
              [
                -53.12363797467448,
                0.697661371669422
              ],
              [
                -53.105819109854686,
                0.684299958454159
              ],
              [
                -53.10526609706487,
                0.679778494031114
              ],
              [
                -53.108870076160656,
                0.67168706817894
              ],
              [
                -53.11033357745159,
                0.668401573567212
              ],
              [
                -53.12001251430607,
                0.63429581233567
              ],
              [
                -53.1468443476852,
                0.600653047200496
              ],
              [
                -53.15301280745873,
                0.576783708021165
              ],
              [
                -53.15436928421993,
                0.563671788708476
              ],
              [
                -53.15183979184266,
                0.559400305362141
              ],
              [
                -53.147678309831555,
                0.552374384495229
              ],
              [
                -53.1479033470795,
                0.541977954729518
              ],
              [
                -53.1515842956406,
                0.532214008938724
              ],
              [
                -53.15625326802753,
                0.528089041134568
              ],
              [
                -53.162235228525276,
                0.52280459424539
              ],
              [
                -53.159152774214796,
                0.514129630569276
              ],
              [
                -53.16929017633317,
                0.494054258735079
              ],
              [
                -53.160800745661724,
                0.489475812034036
              ],
              [
                -53.15906776056943,
                0.488541301219422
              ],
              [
                -53.15465079229893,
                0.480499360170057
              ],
              [
                -53.167267691273956,
                0.446048592295023
              ],
              [
                -53.15876126093112,
                0.420646749976755
              ],
              [
                -53.15925024928996,
                0.408414368428822
              ],
              [
                -53.160201247135184,
                0.402256385969598
              ],
              [
                -53.17483364423896,
                0.382038039935803
              ],
              [
                -53.16379021881577,
                0.360344691401714
              ],
              [
                -53.155875802199965,
                0.354437707770297
              ],
              [
                -53.147961844584756,
                0.348531749141423
              ],
              [
                -53.135980424537436,
                0.326353410884955
              ],
              [
                -53.13492543032281,
                0.313425999855669
              ],
              [
                -53.13387143307839,
                0.300498089815613
              ],
              [
                -53.11145511195501,
                0.286851180740216
              ],
              [
                -53.09364869883632,
                0.259279861637459
              ],
              [
                -53.1059301367178,
                0.232064566172408
              ],
              [
                -53.093815233694436,
                0.195628810834189
              ],
              [
                -53.08835775213572,
                0.192283813177467
              ],
              [
                -53.082899808582795,
                0.188938845524418
              ],
              [
                -53.069622880951144,
                0.169865494448392
              ],
              [
                -53.05164952831477,
                0.15766007811693
              ],
              [
                -53.04762152411066,
                0.147951118326366
              ],
              [
                -53.045835547431565,
                0.143646644237306
              ],
              [
                -53.04230159571687,
                0.139786193128269
              ],
              [
                -53.03913111350407,
                0.136324211348642
              ],
              [
                -53.0383846216909,
                0.132418722598349
              ],
              [
                -53.03603861197839,
                0.120139325125602
              ],
              [
                -53.028789170887514,
                0.105041429519981
              ],
              [
                -53.02453221509324,
                0.102770916723263
              ],
              [
                -53.01065182433495,
                0.095367999307871
              ],
              [
                -53.013660279022275,
                0.067649660688043
              ],
              [
                -53.01448929240643,
                0.060015207004
              ],
              [
                -53.008847827140386,
                0.052103268129199
              ],
              [
                -53.003206866866634,
                0.044191321260205
              ],
              [
                -52.99298593697808,
                0.039630867848892
              ],
              [
                -52.984764989378874,
                0.035963878452645
              ],
              [
                -52.98079001567053,
                0.03004143624317
              ],
              [
                -52.97681555095606,
                0.024119475033284
              ],
              [
                -52.97628154465433,
                -0.007305296795699
              ],
              [
                -52.970545559881494,
                -0.042787076454153
              ],
              [
                -52.965041607578684,
                -0.052843009891304
              ],
              [
                -52.95953916125574,
                -0.062896944337369
              ],
              [
                -52.944816269807,
                -0.105593632529129
              ],
              [
                -52.93322882678902,
                -0.139194905833354
              ],
              [
                -52.93076287166149,
                -0.142247389302755
              ],
              [
                -52.92829535654418,
                -0.145300883764301
              ],
              [
                -52.90353553941324,
                -0.154613832744301
              ],
              [
                -52.897122097487674,
                -0.15414382776114
              ],
              [
                -52.88971962944198,
                -0.149971331618632
              ],
              [
                -52.87634622850997,
                -0.142432879975534
              ],
              [
                -52.85106841216347,
                -0.14983735070981
              ],
              [
                -52.847680422364526,
                -0.15462079749177
              ],
              [
                -52.844292467563776,
                -0.159403774274879
              ],
              [
                -52.83562250757539,
                -0.185824088538352
              ],
              [
                -52.75996404797457,
                -0.237248730680554
              ],
              [
                -52.75395308454754,
                -0.261861587009613
              ],
              [
                -52.74684563126476,
                -0.269071030286536
              ],
              [
                -52.73973819898172,
                -0.276280987551373
              ],
              [
                -52.715768846611304,
                -0.29237037643519
              ],
              [
                -52.71220836616483,
                -0.29240438206157
              ],
              [
                -52.700425958430344,
                -0.292516349828708
              ],
              [
                -52.68964801237648,
                -0.302593298604814
              ],
              [
                -52.683697062956696,
                -0.313987723710724
              ],
              [
                -52.68137509182762,
                -0.339640040105174
              ],
              [
                -52.69547698595659,
                -0.37167582350452
              ],
              [
                -52.69678597453434,
                -0.379258275639055
              ],
              [
                -52.69809647109393,
                -0.386841729775895
              ],
              [
                -52.68344359191451,
                -0.410225563927027
              ],
              [
                -52.68060307825992,
                -0.414758535323752
              ],
              [
                -52.67963958566308,
                -0.419053522426783
              ],
              [
                -52.678677101056984,
                -0.42334747153771
              ],
              [
                -52.702206946547314,
                -0.458905251355449
              ],
              [
                -52.69054250786805,
                -0.476832095356144
              ],
              [
                -52.692750999992946,
                -0.4864535269792
              ],
              [
                -52.69495997810236,
                -0.49607499161815
              ],
              [
                -52.681353592592984,
                -0.51615436404343
              ],
              [
                -52.67664660787959,
                -0.518949835962837
              ],
              [
                -52.66681818930214,
                -0.524786297116844
              ],
              [
                -52.66860518430137,
                -0.533926232969819
              ],
              [
                -52.669375178695816,
                -0.537869189595214
              ],
              [
                -52.667290158647354,
                -0.543808654625101
              ],
              [
                -52.63953485314134,
                -0.584576366193164
              ],
              [
                -52.62871146018393,
                -0.593519319364865
              ],
              [
                -52.61251655500076,
                -0.594562808031385
              ],
              [
                -52.59256819895255,
                -0.581368402897541
              ],
              [
                -52.58115778007225,
                -0.578551930876808
              ],
              [
                -52.56974835520192,
                -0.575735941862824
              ],
              [
                -52.534705594822896,
                -0.573757957348002
              ],
              [
                -52.52870412977126,
                -0.581251900521259
              ],
              [
                -52.522703666710115,
                -0.588745821688448
              ],
              [
                -52.51270925637867,
                -0.619319639480152
              ],
              [
                -52.510572256922,
                -0.656540393378978
              ],
              [
                -52.51146574862245,
                -0.657786353097288
              ],
              [
                -52.515376231049935,
                -0.663242309837749
              ],
              [
                -52.512040252010486,
                -0.664776327235111
              ],
              [
                -52.50991526042935,
                -0.678854746560922
              ],
              [
                -52.504912781339286,
                -0.686702677427162
              ],
              [
                -52.50126080609164,
                -0.692431135640501
              ],
              [
                -52.49693685826052,
                -0.710955000941437
              ],
              [
                -52.49740183711708,
                -0.730420374428798
              ],
              [
                -52.50135283412933,
                -0.746433776463931
              ],
              [
                -52.50476879585513,
                -0.752418718700538
              ],
              [
                -52.51776619846342,
                -0.775190564494666
              ],
              [
                -52.52225116567364,
                -0.791361950679248
              ],
              [
                -52.52070968031575,
                -0.800804882282758
              ],
              [
                -52.51650621991783,
                -0.805373861223595
              ],
              [
                -52.509376251432506,
                -0.813122291544087
              ],
              [
                -52.51562422537183,
                -0.820212265788096
              ],
              [
                -52.53456806177001,
                -0.841709109133001
              ],
              [
                -52.5381845632528,
                -0.854476547172686
              ],
              [
                -52.52831710804956,
                -0.858796001113166
              ],
              [
                -52.51645920620968,
                -0.858286010110611
              ],
              [
                -52.5064967827293,
                -0.853784552043141
              ],
              [
                -52.496364363446176,
                -0.84920658148254
              ],
              [
                -52.46728903195553,
                -0.840223628221803
              ],
              [
                -52.46343108206928,
                -0.83698165349002
              ],
              [
                -52.45504463601105,
                -0.829936685722583
              ],
              [
                -52.449033699448265,
                -0.832032181986928
              ],
              [
                -52.44384972918829,
                -0.833839182277526
              ],
              [
                -52.43167878902469,
                -0.847502067285538
              ],
              [
                -52.42552884828503,
                -0.850935532548056
              ],
              [
                -52.41937839755264,
                -0.854368522809172
              ],
              [
                -52.402686000722085,
                -0.876817349325281
              ],
              [
                -52.40175350456968,
                -0.8820038484466
              ],
              [
                -52.40082152141002,
                -0.887191808559765
              ],
              [
                -52.40427250434018,
                -0.912521113266339
              ],
              [
                -52.41348391564976,
                -0.918655078349693
              ],
              [
                -52.416927407754734,
                -0.918672591670959
              ],
              [
                -52.42623232719415,
                -0.918721099531354
              ],
              [
                -52.43720674883029,
                -0.928544019182087
              ],
              [
                -52.43895324989877,
                -0.934961962457238
              ],
              [
                -52.43615726861594,
                -0.947951385176618
              ],
              [
                -52.4194558751557,
                -0.96563227365942
              ],
              [
                -52.41774288859611,
                -0.998692528272865
              ],
              [
                -52.413991888214944,
                -1.008939979222136
              ],
              [
                -52.41631990478653,
                -1.025280362298169
              ],
              [
                -52.421529861634134,
                -1.032411805373278
              ],
              [
                -52.42876180505744,
                -1.042309757271359
              ],
              [
                -52.42656632011982,
                -1.050462702482475
              ],
              [
                -52.419593355222396,
                -1.052366699745051
              ],
              [
                -52.41093893684107,
                -1.048421211849916
              ],
              [
                -52.39970499553958,
                -1.043299754454245
              ],
              [
                -52.39117706799522,
                -1.048479220590391
              ],
              [
                -52.38265013445046,
                -1.053658666717625
              ],
              [
                -52.374023690923096,
                -1.056628159599711
              ],
              [
                -52.35768577258537,
                -1.062252122587783
              ],
              [
                -52.35461881636841,
                -1.06584656809245
              ],
              [
                -52.35155182515124,
                -1.069441561591844
              ],
              [
                -52.33251644853942,
                -1.115557728294733
              ],
              [
                -52.30051369803727,
                -1.115577238222403
              ],
              [
                -52.272850367547385,
                -1.128199638456195
              ],
              [
                -52.238191141161835,
                -1.125907149404574
              ],
              [
                -52.22993416902444,
                -1.130052116350447
              ],
              [
                -52.22167825588691,
                -1.134197115288934
              ],
              [
                -52.20695085369359,
                -1.132864119914096
              ],
              [
                -52.173102083091216,
                -1.129800640162977
              ],
              [
                -52.161917152479546,
                -1.133720094124535
              ],
              [
                -52.15623619127122,
                -1.13906855235408
              ],
              [
                -52.146261760381954,
                -1.148458498786025
              ],
              [
                -52.12424293535595,
                -1.146302499766273
              ],
              [
                -52.119791471492206,
                -1.145866995985409
              ],
              [
                -52.11798497480767,
                -1.147744987093214
              ],
              [
                -52.116178482123026,
                -1.149623485197003
              ],
              [
                -52.10629353786767,
                -1.182734763377035
              ],
              [
                -52.110319516247515,
                -1.204342617073609
              ],
              [
                -52.10953153284662,
                -1.215879014568464
              ],
              [
                -52.099931579161755,
                -1.226163464979412
              ],
              [
                -52.069990307699854,
                -1.235651387250711
              ],
              [
                -52.05417889848298,
                -1.233886884400049
              ],
              [
                -52.04659644096858,
                -1.218975988219662
              ],
              [
                -52.047094477319405,
                -1.213951047045154
              ],
              [
                -52.05577889274395,
                -1.201044612160918
              ],
              [
                -52.0627708525754,
                -1.190653199096947
              ],
              [
                -52.0619473404018,
                -1.184021230502372
              ],
              [
                -52.05459589496443,
                -1.17661729704077
              ],
              [
                -52.04043003306676,
                -1.169800847553613
              ],
              [
                -52.01086073423553,
                -1.167268350818568
              ],
              [
                -52.00941921927068,
                -1.167569352562367
              ],
              [
                -51.99065437092927,
                -1.171481309225447
              ],
              [
                -51.98751189259065,
                -1.167257858135036
              ],
              [
                -51.986565407913254,
                -1.156502423358894
              ],
              [
                -51.98872289529485,
                -1.147057978264302
              ],
              [
                -51.992930848700006,
                -1.128642601883531
              ],
              [
                -51.98486541728957,
                -1.121114150323276
              ],
              [
                -51.97451550085253,
                -1.12028515430912
              ],
              [
                -51.96512406480154,
                -1.125475107898339
              ],
              [
                -51.96148856192863,
                -1.13410457320483
              ],
              [
                -51.96413904676317,
                -1.150904945476911
              ],
              [
                -51.96545852921188,
                -1.159272376348832
              ],
              [
                -51.96107006271053,
                -1.167023816460971
              ],
              [
                -51.95660811866167,
                -1.168851843436567
              ],
              [
                -51.951733129732304,
                -1.169266302861606
              ],
              [
                -51.94643616539754,
                -1.165672350211397
              ],
              [
                -51.93472777627225,
                -1.140997498575781
              ],
              [
                -51.93290627562631,
                -1.137157543167859
              ],
              [
                -51.92919780400502,
                -1.13457055902393
              ],
              [
                -51.92548932338468,
                -1.131984051880156
              ],
              [
                -51.90352397178744,
                -1.154680906421096
              ],
              [
                -51.896716540399346,
                -1.161715863910192
              ],
              [
                -51.89176707575448,
                -1.16389033545877
              ],
              [
                -51.88681958609857,
                -1.166064823005183
              ],
              [
                -51.808627649228654,
                -1.156768885258515
              ],
              [
                -51.778726884790494,
                -1.140512008394356
              ],
              [
                -51.7779323964396,
                -1.140079991190542
              ],
              [
                -51.77841835324282,
                -1.138670019798502
              ],
              [
                -51.778905362038536,
                -1.137259494410408
              ],
              [
                -51.703871398052094,
                -1.067408979917972
              ],
              [
                -51.6867630319674,
                -1.035677199639309
              ],
              [
                -51.68517955932011,
                -1.012536342445384
              ],
              [
                -51.68852403447082,
                -1.000121941170813
              ],
              [
                -51.68929653742131,
                -0.997254940738185
              ],
              [
                -51.690517037998156,
                -0.981070060784104
              ],
              [
                -51.6950500058344,
                -0.920958984702953
              ],
              [
                -51.69002054976017,
                -0.892181675700817
              ],
              [
                -51.68774004085347,
                -0.822876668964349
              ],
              [
                -51.683556097113545,
                -0.797221847193253
              ],
              [
                -51.68114811272352,
                -0.791071885822075
              ],
              [
                -51.67873961233277,
                -0.784921929459035
              ],
              [
                -51.66501373440768,
                -0.761564585192081
              ],
              [
                -51.595071215836754,
                -0.650110360489225
              ],
              [
                -51.571178897671956,
                -0.622809029216641
              ],
              [
                -51.525754238703044,
                -0.579872313642282
              ],
              [
                -51.52020276144744,
                -0.572793381747031
              ],
              [
                -51.51465079919258,
                -0.565714934864726
              ],
              [
                -51.48623953739534,
                -0.545281068937866
              ],
              [
                -51.45732474504501,
                -0.512905783248058
              ],
              [
                -51.43201592364447,
                -0.473015542873369
              ],
              [
                -51.42026197747605,
                -0.428666884456145
              ],
              [
                -51.41178508085344,
                -0.421538404068227
              ],
              [
                -51.40330761623758,
                -0.414409948702228
              ],
              [
                -51.36204392179548,
                -0.367781790079962
              ],
              [
                -51.35398945812938,
                -0.31819764609581
              ],
              [
                -51.35065348515705,
                -0.308742702280016
              ],
              [
                -51.34731803317048,
                -0.299287759482626
              ],
              [
                -51.32716315114902,
                -0.268365495516628
              ],
              [
                -51.30201887293461,
                -0.252317107682616
              ],
              [
                -51.295575392026834,
                -0.240033176519191
              ],
              [
                -51.28913144210776,
                -0.227748766397016
              ],
              [
                -51.26524459746677,
                -0.195126500801543
              ],
              [
                -51.215899960334816,
                -0.118289534116239
              ],
              [
                -51.17443675510463,
                -0.104212146364097
              ],
              [
                -51.118068676019654,
                -0.096966668025381
              ],
              [
                -51.11274172092915,
                -0.096282206722797
              ],
              [
                -51.10539776823422,
                -0.092537200716992
              ],
              [
                -51.10353628649296,
                -0.091587706308927
              ],
              [
                -51.09657083510856,
                -0.088035231977266
              ],
              [
                -51.09286185251083,
                -0.087784229682178
              ],
              [
                -51.08445693534774,
                -0.087215731544621
              ],
              [
                -51.065214058487655,
                -0.075900850221512
              ],
              [
                -51.0070149755776,
                -0.000023345714155
              ],
              [
                -51.00221048783259,
                -0.000023364714034
              ],
              [
                -51.00001101451546,
                -0.000023379713934
              ],
              [
                -50.99645103389964,
                0.003774095734502
              ],
              [
                -50.98298963484693,
                0.029678930514684
              ],
              [
                -50.98010918536912,
                0.035221389720164
              ],
              [
                -50.97724917975352,
                0.038662374625442
              ],
              [
                -50.974388717140705,
                0.042103839523449
              ],
              [
                -50.93371148723039,
                0.070004134814577
              ],
              [
                -50.92716806091846,
                0.073214103254412
              ],
              [
                -50.92716552593658,
                0.073215637243613
              ],
              [
                -50.92061958764702,
                0.076426099673165
              ],
              [
                -50.78433708765161,
                0.112434824100611
              ],
              [
                -50.74305937099368,
                0.130820191056685
              ],
              [
                -50.6677389138981,
                0.185354325085077
              ],
              [
                -50.6443620800445,
                0.20906662807763
              ],
              [
                -50.62318173043725,
                0.232104977692274
              ],
              [
                -50.59920893106431,
                0.249675370695341
              ],
              [
                -50.5794685652057,
                0.270123210340427
              ],
              [
                -50.55569173671295,
                0.307037951030928
              ],
              [
                -50.53881835736112,
                0.346118655300169
              ],
              [
                -50.52343695373907,
                0.372709488939
              ],
              [
                -50.503543092532894,
                0.435407509671145
              ],
              [
                -50.4855352327487,
                0.459224347305399
              ],
              [
                -50.480643248274625,
                0.470522270658948
              ],
              [
                -50.47575229377955,
                0.481819692987558
              ],
              [
                -50.46682686574081,
                0.531211343851771
              ],
              [
                -50.45188496344478,
                0.571264538423879
              ],
              [
                -50.43609056998518,
                0.600422830301801
              ],
              [
                -50.40940377246521,
                0.623660154310228
              ],
              [
                -50.34678071852447,
                0.667161342089598
              ],
              [
                -50.31418194991546,
                0.678303781437194
              ],
              [
                -50.1975562890956,
                0.701214590490922
              ],
              [
                -50.15698662747045,
                0.705429571420342
              ],
              [
                -50.093460572184256,
                0.702403560184471
              ],
              [
                -50.091602075544955,
                0.647499988550074
              ],
              [
                -50.05899533165063,
                0.616735701797534
              ],
              [
                -50.040351968321055,
                0.573855501330426
              ],
              [
                -50.035100497052966,
                0.509174451294664
              ],
              [
                -50.039072480971086,
                0.497822556011301
              ],
              [
                -50.04304444887453,
                0.486470627702352
              ],
              [
                -50.05207038480293,
                0.372584938285684
              ],
              [
                -50.06080034103613,
                0.338753201818392
              ],
              [
                -50.01457316083374,
                0.333591245688186
              ],
              [
                -49.984375403629954,
                0.323547289291849
              ],
              [
                -49.97770891802661,
                0.324632299231586
              ],
              [
                -49.972188001375415,
                0.319293348241714
              ],
              [
                -49.966796013496754,
                0.320920839351763
              ],
              [
                -49.96254403919257,
                0.325886272589588
              ],
              [
                -49.957655581482136,
                0.331596243454907
              ],
              [
                -49.946050163278166,
                0.321836822899005
              ],
              [
                -49.945713173740465,
                0.32155331091668
              ],
              [
                -49.93523924203242,
                0.318568323866914
              ],
              [
                -49.93429874485634,
                0.31895183507762
              ],
              [
                -49.930004776013284,
                0.320702337344869
              ],
              [
                -49.92283435733633,
                0.316725349555032
              ],
              [
                -49.91914187328245,
                0.314677858080894
              ],
              [
                -49.915360405872846,
                0.312736383844399
              ],
              [
                -49.9082244508256,
                0.311930871324031
              ],
              [
                -49.9028864956925,
                0.311327876427173
              ],
              [
                -49.86377728270135,
                0.327762286834578
              ],
              [
                -49.84723239189984,
                0.334714706182167
              ],
              [
                -49.8447569038358,
                0.336911721291656
              ],
              [
                -49.84106745356822,
                0.340175670680045
              ],
              [
                -49.83741247920428,
                0.339993188825609
              ],
              [
                -49.833990515341604,
                0.335355210991241
              ],
              [
                -49.83267600199751,
                0.333574237727229
              ],
              [
                -49.83165101152657,
                0.332185252660269
              ],
              [
                -49.827723037373296,
                0.326979290887461
              ],
              [
                -49.824040567136684,
                0.328639767797629
              ],
              [
                -49.8210650727507,
                0.330264246995542
              ],
              [
                -49.81369366430293,
                0.334230211170963
              ],
              [
                -49.81019165980234,
                0.334839727634696
              ],
              [
                -49.80520072314591,
                0.335708720166384
              ],
              [
                -49.80101422663403,
                0.336438705732027
              ],
              [
                -49.795539283510614,
                0.337349712937741
              ],
              [
                -49.79139529874659,
                0.336890217055586
              ],
              [
                -49.790739819529335,
                0.336817704547629
              ],
              [
                -49.787800822974866,
                0.3364922007565
              ],
              [
                -49.78260885286282,
                0.335917188659209
              ],
              [
                -49.776904415295796,
                0.339780674618916
              ],
              [
                -49.76271251538597,
                0.349391106836271
              ],
              [
                -49.7577795516264,
                0.34387614628933
              ],
              [
                -49.75227259008271,
                0.337721188325433
              ],
              [
                -49.75345809066244,
                0.332476246114285
              ],
              [
                -49.738468200464006,
                0.32292528517983
              ],
              [
                -49.733453217900355,
                0.326879758507455
              ],
              [
                -49.74132668600885,
                0.336651220534487
              ],
              [
                -49.74516364278677,
                0.34159868110307
              ],
              [
                -49.742507180888616,
                0.347914629525643
              ],
              [
                -49.74171416659008,
                0.349800606914269
              ],
              [
                -49.733876739654846,
                0.352825094776848
              ],
              [
                -49.73394522325992,
                0.350545092193899
              ],
              [
                -49.73399422497586,
                0.348936097779097
              ],
              [
                -49.7295627593094,
                0.349251095305999
              ],
              [
                -49.72631029088554,
                0.35289058295086
              ],
              [
                -49.72354231494943,
                0.355989064509832
              ],
              [
                -49.71791283707468,
                0.355471073974201
              ],
              [
                -49.71319437554785,
                0.35503655888099
              ],
              [
                -49.71087339839348,
                0.357238554911529
              ],
              [
                -49.710257405864425,
                0.357823057672235
              ],
              [
                -49.7081899278702,
                0.359785031441489
              ],
              [
                -49.70318445137589,
                0.360835531634592
              ],
              [
                -49.69571199687953,
                0.362404498972064
              ],
              [
                -49.67718616103627,
                0.366292965053434
              ],
              [
                -49.66883720847043,
                0.357301029468597
              ],
              [
                -49.66576421408207,
                0.35399106718293
              ],
              [
                -49.66340675533528,
                0.353476097784463
              ],
              [
                -49.659962784541484,
                0.352723094051436
              ],
              [
                -49.66086325700776,
                0.351684104583407
              ],
              [
                -49.66654122680853,
                0.345128126101402
              ],
              [
                -49.66199624706636,
                0.344221139430775
              ],
              [
                -49.65595980000642,
                0.348230108255326
              ],
              [
                -49.65396232554692,
                0.349557121596151
              ],
              [
                -49.650704347591066,
                0.344528647698415
              ],
              [
                -49.64962633454647,
                0.342864158649107
              ],
              [
                -49.648172345181216,
                0.342744653444599
              ],
              [
                -49.64190941899266,
                0.342229653873244
              ],
              [
                -49.633435484372804,
                0.332732232973326
              ],
              [
                -49.63379444582408,
                0.330976220650405
              ],
              [
                -49.634101471643795,
                0.32947373049681
              ],
              [
                -49.62312056534743,
                0.320084317722948
              ],
              [
                -49.62008857163175,
                0.317490815295081
              ],
              [
                -49.619547565607995,
                0.31702882860352
              ],
              [
                -49.61543362284482,
                0.313510881798083
              ],
              [
                -49.61127162382023,
                0.300296478907688
              ],
              [
                -49.6093466341467,
                0.294005493187298
              ],
              [
                -49.60730417234245,
                0.287328543243778
              ],
              [
                -49.60656615783181,
                0.284918064592527
              ],
              [
                -49.603832693157685,
                0.275982646901626
              ],
              [
                -49.601077189637714,
                0.266978185705419
              ],
              [
                -49.561850014707986,
                0.203297180415449
              ],
              [
                -49.47514864829965,
                0.07929306323693
              ],
              [
                -49.46319772001322,
                0.072690594683801
              ],
              [
                -49.45752176971286,
                0.065374641342187
              ],
              [
                -49.45269231011747,
                0.068115661549776
              ],
              [
                -49.45008182125533,
                0.06963614556938
              ],
              [
                -49.448441345282255,
                0.070591129672188
              ],
              [
                -49.44403686860532,
                0.069791135396779
              ],
              [
                -49.44074491876571,
                0.069175637802583
              ],
              [
                -49.43488093580624,
                0.068078143659897
              ],
              [
                -49.42948495845916,
                0.06184470354491
              ],
              [
                -49.41779457430086,
                0.057405706452036
              ],
              [
                -49.41740008620565,
                0.056192245197123
              ],
              [
                -49.416324082127844,
                0.052883270043782
              ],
              [
                -49.411002621201774,
                0.051788762897534
              ],
              [
                -49.40551514349843,
                0.050659253003909
              ],
              [
                -49.40195768462422,
                0.049927276258051
              ],
              [
                -49.3958257296607,
                0.048665796314433
              ],
              [
                -49.393023745241635,
                0.048089293453868
              ],
              [
                -49.3870477971706,
                0.041306337333797
              ],
              [
                -49.38298784003111,
                0.032987381304596
              ],
              [
                -49.384515301831435,
                0.027921441840107
              ],
              [
                -49.38959825651204,
                0.023734464044831
              ],
              [
                -49.393534243614546,
                0.020492977423725
              ],
              [
                -49.39787323574947,
                0.020861472779405
              ],
              [
                -49.39651724371844,
                0.016074023282587
              ],
              [
                -49.39158877891429,
                0.015166518813561
              ],
              [
                -49.37970035923843,
                0.012978560563885
              ],
              [
                -49.3783548451228,
                0.012731054346023
              ],
              [
                -49.36713395556898,
                -0.000035359622409
              ],
              [
                -49.007648115638624,
                -0.16039220331081
              ],
              [
                -48.99960318566014,
                -0.181600542604325
              ],
              [
                -48.994205688510114,
                -0.184508507425371
              ],
              [
                -48.992942194838875,
                -0.185190006461536
              ],
              [
                -48.98501775335115,
                -0.189459988356099
              ],
              [
                -48.97589084774941,
                -0.194377961521752
              ],
              [
                -48.969813875629534,
                -0.197652940653901
              ],
              [
                -48.96368993286001,
                -0.200952389603622
              ],
              [
                -48.958539970899494,
                -0.203727376373032
              ],
              [
                -48.9537795200625,
                -0.206353858225544
              ],
              [
                -48.948001561702135,
                -0.211083315817947
              ],
              [
                -48.93297166862515,
                -0.223385251297151
              ],
              [
                -48.930268167578475,
                -0.225598206190177
              ],
              [
                -48.91561879689726,
                -0.231238665896541
              ],
              [
                -48.91313431325197,
                -0.23279817952393
              ],
              [
                -48.903246874641646,
                -0.227776691606524
              ],
              [
                -48.898747413917704,
                -0.229718181403521
              ],
              [
                -48.89799391249045,
                -0.230043707021996
              ],
              [
                -48.897774410143256,
                -0.229147675508426
              ],
              [
                -48.896264430510556,
                -0.222989741089851
              ],
              [
                -48.883316511519595,
                -0.220624756856188
              ],
              [
                -48.879329067090765,
                -0.219898236008257
              ],
              [
                -48.87597207398756,
                -0.219301269239908
              ],
              [
                -48.87294710541104,
                -0.219192774939773
              ],
              [
                -48.852554773604055,
                -0.218529269167742
              ],
              [
                -48.8486222947665,
                -0.218401270983881
              ],
              [
                -48.844690316927235,
                -0.218272769803801
              ],
              [
                -48.84327383242072,
                -0.218654245995834
              ],
              [
                -48.831383410516075,
                -0.221854259436081
              ],
              [
                -48.826003455491005,
                -0.219470752576704
              ],
              [
                -48.82485097305479,
                -0.218960266248284
              ],
              [
                -48.822751500610515,
                -0.219624746365814
              ],
              [
                -48.8211070057956,
                -0.220144768544467
              ],
              [
                -48.81535253044685,
                -0.221603227793155
              ],
              [
                -48.810772069473046,
                -0.220191270909533
              ],
              [
                -48.809965085468036,
                -0.219941759697531
              ],
              [
                -48.806182098572194,
                -0.218779264027937
              ],
              [
                -48.799154661712684,
                -0.219160745057272
              ],
              [
                -48.792649726978,
                -0.219658774254629
              ],
              [
                -48.78451574825221,
                -0.223285729681733
              ],
              [
                -48.7769013214026,
                -0.236058140703222
              ],
              [
                -48.77452933889137,
                -0.240037619732501
              ],
              [
                -48.77014438244749,
                -0.240113602043282
              ],
              [
                -48.7695458738913,
                -0.24012411194817
              ],
              [
                -48.763966910316455,
                -0.240219588079786
              ],
              [
                -48.75744797862061,
                -0.243711071515083
              ],
              [
                -48.74515057875299,
                -0.25029802827274
              ],
              [
                -48.73731362695412,
                -0.250923544472221
              ],
              [
                -48.72808818148031,
                -0.251559502549003
              ],
              [
                -48.717267761867845,
                -0.252306492764974
              ],
              [
                -48.70240837417277,
                -0.256793467658815
              ],
              [
                -48.69098046223197,
                -0.253927495113547
              ],
              [
                -48.68042805371736,
                -0.253475992047468
              ],
              [
                -48.676279580577656,
                -0.253257023502869
              ],
              [
                -48.67713456833571,
                -0.249646536789796
              ],
              [
                -48.67608606915332,
                -0.249041032159691
              ],
              [
                -48.66888061387976,
                -0.244882578176221
              ],
              [
                -48.63738085266017,
                -0.231626154671148
              ],
              [
                -48.63440390482034,
                -0.231507655444062
              ],
              [
                -48.62885093415917,
                -0.231286650885773
              ],
              [
                -48.62621194980638,
                -0.231181161574387
              ],
              [
                -48.61740303239516,
                -0.230830177865273
              ],
              [
                -48.6062135947231,
                -0.230383672781342
              ],
              [
                -48.602419640919074,
                -0.232294165749954
              ],
              [
                -48.59727065718755,
                -0.234887642700048
              ],
              [
                -48.59099021402643,
                -0.232848649364145
              ],
              [
                -48.587381236943855,
                -0.231676670794546
              ],
              [
                -48.57605682924216,
                -0.233861159532233
              ],
              [
                -48.56792989374856,
                -0.235428122863336
              ],
              [
                -48.56737840979426,
                -0.237524123569049
              ],
              [
                -48.566849402674386,
                -0.239538589869917
              ],
              [
                -48.56352890438539,
                -0.240605595989063
              ],
              [
                -48.559775439322664,
                -0.241726072704042
              ],
              [
                -48.556148489441014,
                -0.238897099212836
              ],
              [
                -48.55197102267634,
                -0.235639639832258
              ],
              [
                -48.534897645818944,
                -0.24002061235888
              ],
              [
                -48.53473313004427,
                -0.240062581047674
              ],
              [
                -48.53456115132724,
                -0.240038596217234
              ],
              [
                -48.52824268646637,
                -0.239155621459993
              ],
              [
                -48.52496920189033,
                -0.238698097695409
              ],
              [
                -48.517941761313736,
                -0.238171114321636
              ],
              [
                -48.513774306320435,
                -0.240614587366209
              ],
              [
                -48.50791382892612,
                -0.244051079110495
              ],
              [
                -48.50218286874,
                -0.242034104645733
              ],
              [
                -48.47760558138985,
                -0.233383649024064
              ],
              [
                -48.474954072206884,
                -0.232450656755663
              ],
              [
                -48.47230311102059,
                -0.231517661488015
              ],
              [
                -48.44024132902216,
                -0.243636588992195
              ],
              [
                -48.43677936678628,
                -0.246519034826788
              ],
              [
                -48.43268291527289,
                -0.249929510781959
              ],
              [
                -48.43165889089405,
                -0.250781523524789
              ],
              [
                -48.42783393136093,
                -0.253966502133239
              ],
              [
                -48.42510346668815,
                -0.256069497680224
              ],
              [
                -48.42106898981816,
                -0.256325462676091
              ],
              [
                -48.42006498231658,
                -0.256388977178681
              ],
              [
                -48.414914046788205,
                -0.256714992625189
              ],
              [
                -48.41182255487979,
                -0.256910494093725
              ],
              [
                -48.405495612012636,
                -0.261203929508848
              ],
              [
                -48.39987464967219,
                -0.271312870448719
              ],
              [
                -48.39983164899089,
                -0.271389375888165
              ],
              [
                -48.396107176730986,
                -0.274096843973164
              ],
              [
                -48.39238220347562,
                -0.276804815052683
              ],
              [
                -48.383747783118935,
                -0.301027144750521
              ],
              [
                -48.386610243586794,
                -0.329133444580713
              ],
              [
                -48.386811237000714,
                -0.331110940149398
              ],
              [
                -48.38701274341033,
                -0.333087412726179
              ],
              [
                -48.38872023991333,
                -0.349856776373384
              ],
              [
                -48.38922170794253,
                -0.354784768424945
              ],
              [
                -48.390053213351976,
                -0.362947695885758
              ],
              [
                -48.39471917109392,
                -0.371101136609453
              ],
              [
                -48.40974504820528,
                -0.383513544797293
              ],
              [
                -48.419365483932786,
                -0.391449996406632
              ],
              [
                -48.42315798444306,
                -0.394578457791244
              ],
              [
                -48.4304584286028,
                -0.400601437263301
              ],
              [
                -48.433340885950656,
                -0.402978917085583
              ],
              [
                -48.444101822437446,
                -0.424596245154969
              ],
              [
                -48.453442251650976,
                -0.460552494866246
              ],
              [
                -48.45527671253383,
                -0.467614434575031
              ],
              [
                -48.455611738955014,
                -0.468904419206748
              ],
              [
                -48.45677621599005,
                -0.473385876663308
              ],
              [
                -48.472118113879716,
                -0.498754226077499
              ],
              [
                -48.235646367820664,
                -0.581540594549067
              ],
              [
                -47.999989131027135,
                -0.663958469545767
              ],
              [
                -47.99624065474723,
                -0.681350812997834
              ],
              [
                -47.997278662884185,
                -0.693377725131529
              ],
              [
                -47.99794616817515,
                -0.701110672646193
              ],
              [
                -47.99654466142449,
                -0.70443917618229
              ],
              [
                -47.9938236821009,
                -0.702213167212126
              ],
              [
                -47.98932172466907,
                -0.705722169144994
              ],
              [
                -47.98596524996453,
                -0.705360649483613
              ],
              [
                -47.98297024790037,
                -0.689664251016693
              ],
              [
                -47.978961288961536,
                -0.690987740978716
              ],
              [
                -47.97698681976776,
                -0.691640245029586
              ],
              [
                -47.975804311130446,
                -0.686381793394902
              ],
              [
                -47.97346732871768,
                -0.674898864211042
              ],
              [
                -47.9681333623792,
                -0.669005928876882
              ],
              [
                -47.96736788121455,
                -0.668159940002961
              ],
              [
                -47.96221643548388,
                -0.662467479230401
              ],
              [
                -47.9628624119282,
                -0.658795504167078
              ],
              [
                -47.963761414585264,
                -0.653686028650414
              ],
              [
                -47.96444089678908,
                -0.649823073990326
              ],
              [
                -47.96367240089821,
                -0.645905581610075
              ],
              [
                -47.96285791637085,
                -0.641753108949203
              ],
              [
                -47.96175742409496,
                -0.636366140308412
              ],
              [
                -47.95709096956402,
                -0.63227467589349
              ],
              [
                -47.95223901443967,
                -0.628070208298217
              ],
              [
                -47.938822116223776,
                -0.631455202410915
              ],
              [
                -47.926134192610846,
                -0.621385251184844
              ],
              [
                -47.925400202078,
                -0.622183262276894
              ],
              [
                -47.92269123025602,
                -0.625126226488169
              ],
              [
                -47.91912125085932,
                -0.628852205889368
              ],
              [
                -47.91469530185718,
                -0.620714278189463
              ],
              [
                -47.91043781957008,
                -0.612696828635925
              ],
              [
                -47.91096732294536,
                -0.607977847281709
              ],
              [
                -47.911373805169866,
                -0.604238385736295
              ],
              [
                -47.91178382536889,
                -0.600460903471114
              ],
              [
                -47.908825846621845,
                -0.600978427446964
              ],
              [
                -47.906872860314834,
                -0.601319898791457
              ],
              [
                -47.90214288290135,
                -0.602156926286039
              ],
              [
                -47.90035339888563,
                -0.595635438986228
              ],
              [
                -47.90278436475824,
                -0.593038477223107
              ],
              [
                -47.90362738347062,
                -0.59215548276493
              ],
              [
                -47.907780858492096,
                -0.587803512005519
              ],
              [
                -47.90476735677145,
                -0.580159559858505
              ],
              [
                -47.89608341582222,
                -0.57203213685449
              ],
              [
                -47.89777540131512,
                -0.568619157026928
              ],
              [
                -47.9045453751369,
                -0.570685130361431
              ],
              [
                -47.903534862543616,
                -0.559673729098209
              ],
              [
                -47.897162915081196,
                -0.552474270488381
              ],
              [
                -47.886331509844894,
                -0.550916790154689
              ],
              [
                -47.8642721918087,
                -0.556337746774563
              ],
              [
                -47.85421825844401,
                -0.573085115042364
              ],
              [
                -47.85699471990148,
                -0.581261068206576
              ],
              [
                -47.85626973625972,
                -0.582756538171073
              ],
              [
                -47.85507722907287,
                -0.585217022014416
              ],
              [
                -47.852753760586,
                -0.585407536440678
              ],
              [
                -47.841663844185376,
                -0.58631703692665
              ],
              [
                -47.84589431290525,
                -0.591161472669392
              ],
              [
                -47.84468383560461,
                -0.596911433354056
              ],
              [
                -47.84549580837347,
                -0.598296935242943
              ],
              [
                -47.8473817879,
                -0.601511421786294
              ],
              [
                -47.84428783634004,
                -0.613226304538409
              ],
              [
                -47.841909849576304,
                -0.622231241245696
              ],
              [
                -47.84885479151654,
                -0.630564715640191
              ],
              [
                -47.84742530204523,
                -0.633755170110543
              ],
              [
                -47.851095779255935,
                -0.634977659438817
              ],
              [
                -47.851554777534226,
                -0.638185149940651
              ],
              [
                -47.85251723869842,
                -0.645249068191485
              ],
              [
                -47.85343724021851,
                -0.651807049157446
              ],
              [
                -47.86329469245315,
                -0.656741516792499
              ],
              [
                -47.864936159171776,
                -0.667462938308882
              ],
              [
                -47.87077159833769,
                -0.677118359022629
              ],
              [
                -47.86181819749573,
                -0.681405809809062
              ],
              [
                -47.85435875573392,
                -0.670087907150535
              ],
              [
                -47.84832529751531,
                -0.666964440540423
              ],
              [
                -47.84601630561021,
                -0.670799381213772
              ],
              [
                -47.843169329686475,
                -0.67552837428232
              ],
              [
                -47.84054636610281,
                -0.6798858230945
              ],
              [
                -47.836194887032015,
                -0.678790823746224
              ],
              [
                -47.83095842499811,
                -0.673540899803109
              ],
              [
                -47.82359849517163,
                -0.666153948369221
              ],
              [
                -47.82412844988013,
                -0.645613579162586
              ],
              [
                -47.82086548695903,
                -0.640079107520358
              ],
              [
                -47.816404032412045,
                -0.630428716010285
              ],
              [
                -47.81819603118229,
                -0.626704207503945
              ],
              [
                -47.82080599991142,
                -0.621279776545818
              ],
              [
                -47.824463984897896,
                -0.613674802683103
              ],
              [
                -47.82386546662841,
                -0.610928318805934
              ],
              [
                -47.822026490157995,
                -0.602481919698509
              ],
              [
                -47.817528541336635,
                -0.599484924366296
              ],
              [
                -47.815848052106574,
                -0.598364935464839
              ],
              [
                -47.808074099182924,
                -0.59318498292859
              ],
              [
                -47.81099308028708,
                -0.591316988876542
              ],
              [
                -47.81247857414444,
                -0.590365493980649
              ],
              [
                -47.814440528428065,
                -0.589109499357911
              ],
              [
                -47.80895460373513,
                -0.577015080825322
              ],
              [
                -47.80762708397054,
                -0.574089117232909
              ],
              [
                -47.810996063010286,
                -0.567554161513957
              ],
              [
                -47.812438587321644,
                -0.564755166192908
              ],
              [
                -47.81440455776059,
                -0.560839219119916
              ],
              [
                -47.80374614898831,
                -0.549978274210043
              ],
              [
                -47.79035722483639,
                -0.55353474510743
              ],
              [
                -47.78674926956034,
                -0.560938223400583
              ],
              [
                -47.78721825069207,
                -0.565512163797555
              ],
              [
                -47.79269619899258,
                -0.570120624310135
              ],
              [
                -47.798473176018824,
                -0.574969618085328
              ],
              [
                -47.80108363714859,
                -0.577161074169075
              ],
              [
                -47.80188514842767,
                -0.586238015512023
              ],
              [
                -47.79872617508962,
                -0.588783507565237
              ],
              [
                -47.793907713844455,
                -0.583791033898847
              ],
              [
                -47.78328828818799,
                -0.581804049712132
              ],
              [
                -47.77439735346495,
                -0.580140047284852
              ],
              [
                -47.767699395707204,
                -0.584950034411725
              ],
              [
                -47.77227187672494,
                -0.590863991268319
              ],
              [
                -47.76464792874335,
                -0.611563823489834
              ],
              [
                -47.76246642876912,
                -0.617419278271939
              ],
              [
                -47.76706841976554,
                -0.633019685976654
              ],
              [
                -47.76709190324624,
                -0.637269129750644
              ],
              [
                -47.76074945755466,
                -0.632412668924899
              ],
              [
                -47.75798047964584,
                -0.63029168629008
              ],
              [
                -47.755000987570256,
                -0.62511922707231
              ],
              [
                -47.752966499222424,
                -0.621586744879333
              ],
              [
                -47.753721019838174,
                -0.6175442826602
              ],
              [
                -47.754752517474195,
                -0.612017338378869
              ],
              [
                -47.75549550816773,
                -0.608034333724036
              ],
              [
                -47.752762027056804,
                -0.604987875913498
              ],
              [
                -47.75015752296,
                -0.602085401057191
              ],
              [
                -47.749025551151504,
                -0.593667941881085
              ],
              [
                -47.75757446835203,
                -0.582936519462365
              ],
              [
                -47.758612462746626,
                -0.579712068258091
              ],
              [
                -47.760704458414644,
                -0.573217114190261
              ],
              [
                -47.76145997287635,
                -0.570872124496339
              ],
              [
                -47.76330695233455,
                -0.565138681810009
              ],
              [
                -47.76101696584727,
                -0.562178675421668
              ],
              [
                -47.74718305031033,
                -0.563156700226596
              ],
              [
                -47.737905645379755,
                -0.56381269472822
              ],
              [
                -47.73099567732684,
                -0.553687752765475
              ],
              [
                -47.731662668430396,
                -0.551609279115166
              ],
              [
                -47.73255619487019,
                -0.548824279682724
              ],
              [
                -47.72104177136816,
                -0.542277342086946
              ],
              [
                -47.718804769656266,
                -0.536785356381943
              ],
              [
                -47.703489906589034,
                -0.534923395046728
              ],
              [
                -47.69354496529427,
                -0.542427348070611
              ],
              [
                -47.69537645527289,
                -0.544873803172576
              ],
              [
                -47.696931946364245,
                -0.546951797971592
              ],
              [
                -47.69825842820861,
                -0.548723783010216
              ],
              [
                -47.69609746711133,
                -0.555050224251827
              ],
              [
                -47.69273447788204,
                -0.564461189686737
              ],
              [
                -47.69269598816552,
                -0.564568160896031
              ],
              [
                -47.68975350483075,
                -0.572804100017995
              ],
              [
                -47.69059649941808,
                -0.573299607430291
              ],
              [
                -47.69578496095044,
                -0.576350597342555
              ],
              [
                -47.69832391453287,
                -0.592765466666267
              ],
              [
                -47.68583900766645,
                -0.596816938884841
              ],
              [
                -47.67922206812331,
                -0.591535987270271
              ],
              [
                -47.67552508628002,
                -0.589056968249002
              ],
              [
                -47.67206511263252,
                -0.58673650108066
              ],
              [
                -47.662296198040735,
                -0.580185543612728
              ],
              [
                -47.64785482493667,
                -0.585740012605084
              ],
              [
                -47.63886788896276,
                -0.599208413671549
              ],
              [
                -47.63622941463855,
                -0.603163378324282
              ],
              [
                -47.63245842607811,
                -0.617589288733332
              ],
              [
                -47.62677597645758,
                -0.625851700405429
              ],
              [
                -47.627139478270045,
                -0.631327171094779
              ],
              [
                -47.631886922947636,
                -0.635994146100536
              ],
              [
                -47.63184941565083,
                -0.643180094169011
              ],
              [
                -47.63182491545467,
                -0.647859056706938
              ],
              [
                -47.64056388374631,
                -0.654447507919753
              ],
              [
                -47.63727290852722,
                -0.65608098161657
              ],
              [
                -47.63045196390518,
                -0.659307451284878
              ],
              [
                -47.63094341946083,
                -0.667929392842752
              ],
              [
                -47.63280790896264,
                -0.672419888942804
              ],
              [
                -47.6341778983087,
                -0.675715345801323
              ],
              [
                -47.63769736893329,
                -0.684185281763858
              ],
              [
                -47.639594887170766,
                -0.688751765323402
              ],
              [
                -47.63586838038296,
                -0.688776742811035
              ],
              [
                -47.630524939858816,
                -0.688590246712619
              ],
              [
                -47.62887893792376,
                -0.693161209923864
              ],
              [
                -47.62874394391327,
                -0.693534718162871
              ],
              [
                -47.626023981855724,
                -0.700966154226304
              ],
              [
                -47.61989603286343,
                -0.694160195773388
              ],
              [
                -47.61454704425398,
                -0.695550192065542
              ],
              [
                -47.608786114758914,
                -0.697047687527847
              ],
              [
                -47.60229665908804,
                -0.683638790689111
              ],
              [
                -47.60051568289654,
                -0.679968320566074
              ],
              [
                -47.58865123985172,
                -0.655517995683924
              ],
              [
                -47.58006282437969,
                -0.637818604433216
              ],
              [
                -47.58079181338039,
                -0.631261155838323
              ],
              [
                -47.5811548318889,
                -0.627995179948082
              ],
              [
                -47.58116280383406,
                -0.627924678468523
              ],
              [
                -47.58169982021522,
                -0.62221225863253
              ],
              [
                -47.58000331946986,
                -0.617126276003422
              ],
              [
                -47.5785493388275,
                -0.612765815047407
              ],
              [
                -47.577387345902814,
                -0.609280341663965
              ],
              [
                -47.58014332853365,
                -0.602516392768609
              ],
              [
                -47.58361528686613,
                -0.593993447903491
              ],
              [
                -47.58048532285513,
                -0.590388456260876
              ],
              [
                -47.580261327571904,
                -0.590130482147184
              ],
              [
                -47.57896783548546,
                -0.588640986038832
              ],
              [
                -47.57805082651359,
                -0.587584997760978
              ],
              [
                -47.57896231708824,
                -0.58104354209472
              ],
              [
                -47.55946300843521,
                -0.587203983176749
              ],
              [
                -47.55741199053406,
                -0.593248447411749
              ],
              [
                -47.55663602826693,
                -0.595248929589057
              ],
              [
                -47.55597601814289,
                -0.596948925992824
              ],
              [
                -47.551592048204405,
                -0.599291417369933
              ],
              [
                -47.55077153939244,
                -0.599729914070877
              ],
              [
                -47.54194461997583,
                -0.604322863493058
              ],
              [
                -47.529897692921594,
                -0.622658724217652
              ],
              [
                -47.533167680370596,
                -0.632126648609221
              ],
              [
                -47.533886162755266,
                -0.634206145323065
              ],
              [
                -47.53701214331805,
                -0.643256569804453
              ],
              [
                -47.53544367900811,
                -0.645781075051388
              ],
              [
                -47.53107670855365,
                -0.652809525839621
              ],
              [
                -47.526173730603404,
                -0.654502999937102
              ],
              [
                -47.52482925776354,
                -0.654967502397807
              ],
              [
                -47.52532372584911,
                -0.656944966850688
              ],
              [
                -47.527940223127565,
                -0.667416402829193
              ],
              [
                -47.52613823336038,
                -0.672573363639214
              ],
              [
                -47.51866227777292,
                -0.67609182404405
              ],
              [
                -47.51410832913913,
                -0.678235334839231
              ],
              [
                -47.5149403325106,
                -0.681922792712417
              ],
              [
                -47.507159389934785,
                -0.689009239761941
              ],
              [
                -47.505613901722995,
                -0.69973416052241
              ],
              [
                -47.50558088595338,
                -0.699962174837831
              ],
              [
                -47.50282689443227,
                -0.704695132685373
              ],
              [
                -47.50149491433625,
                -0.706984587680951
              ],
              [
                -47.496076454621296,
                -0.716296543516179
              ],
              [
                -47.49000899672297,
                -0.726725465051291
              ],
              [
                -47.48649954780595,
                -0.732757421244673
              ],
              [
                -47.48499403799439,
                -0.73534489802392
              ],
              [
                -47.48431656683268,
                -0.738620368807971
              ],
              [
                -47.482640575797156,
                -0.746717823944944
              ],
              [
                -47.48516504278639,
                -0.755673227165946
              ],
              [
                -47.48763752316323,
                -0.764604158576829
              ],
              [
                -47.48236906034582,
                -0.762620694682806
              ],
              [
                -47.477831584958835,
                -0.760878689082925
              ],
              [
                -47.46873016563279,
                -0.765313648500887
              ],
              [
                -47.46614418756754,
                -0.751715777514532
              ],
              [
                -47.470967157802434,
                -0.731868413349263
              ],
              [
                -47.46687268735088,
                -0.704682627543445
              ],
              [
                -47.4679476816923,
                -0.698986175678005
              ],
              [
                -47.46902315502609,
                -0.693289714814714
              ],
              [
                -47.47188565660275,
                -0.678124802999152
              ],
              [
                -47.47377112913516,
                -0.668135405904731
              ],
              [
                -47.47565662865527,
                -0.658146444813291
              ],
              [
                -47.474572132350396,
                -0.65255448801276
              ],
              [
                -47.473096133178316,
                -0.644944568087853
              ],
              [
                -47.472227146106114,
                -0.640866576134696
              ],
              [
                -47.47032764924307,
                -0.631945655874056
              ],
              [
                -47.47526562701681,
                -0.628628688773338
              ],
              [
                -47.48068756414403,
                -0.624987214105284
              ],
              [
                -47.48160859245504,
                -0.621134245638931
              ],
              [
                -47.47634060315978,
                -0.611946801097694
              ],
              [
                -47.47462714039782,
                -0.60895884804137
              ],
              [
                -47.47359114540145,
                -0.607151829313347
              ],
              [
                -47.47309863020633,
                -0.606292854622504
              ],
              [
                -47.47075116434034,
                -0.60219936569182
              ],
              [
                -47.46860869288964,
                -0.598462396146013
              ],
              [
                -47.472886162373825,
                -0.598991393563245
              ],
              [
                -47.47426314813459,
                -0.596138444752652
              ],
              [
                -47.44390687519481,
                -0.588892960022035
              ],
              [
                -47.41876606398842,
                -0.591703961337765
              ],
              [
                -47.414247607782194,
                -0.598947917404667
              ],
              [
                -47.41655405754113,
                -0.608339306112812
              ],
              [
                -47.417279083805,
                -0.611290293342846
              ],
              [
                -47.417987082202416,
                -0.61417428206859
              ],
              [
                -47.41854306180211,
                -0.616439272361489
              ],
              [
                -47.42127501517151,
                -0.627563192321789
              ],
              [
                -47.420331552184244,
                -0.629539179636278
              ],
              [
                -47.41952056121219,
                -0.631236161024232
              ],
              [
                -47.42079804636769,
                -0.632959143388491
              ],
              [
                -47.427512472622595,
                -0.642018079967804
              ],
              [
                -47.42806798401912,
                -0.646736063142408
              ],
              [
                -47.42861746939175,
                -0.652270004289528
              ],
              [
                -47.42512000874,
                -0.65500846976023
              ],
              [
                -47.42079203252268,
                -0.644041542459906
              ],
              [
                -47.41991454137038,
                -0.641819101816739
              ],
              [
                -47.416971561064386,
                -0.650056515682473
              ],
              [
                -47.4132185947059,
                -0.648575541319417
              ],
              [
                -47.40628465670352,
                -0.657109972651895
              ],
              [
                -47.39986671151256,
                -0.656791482469029
              ],
              [
                -47.39178074372512,
                -0.647728028814811
              ],
              [
                -47.38844479539361,
                -0.643954569447778
              ],
              [
                -47.38791680588335,
                -0.638058626014027
              ],
              [
                -47.387552311980166,
                -0.633989130087044
              ],
              [
                -47.38474830233736,
                -0.633512122389323
              ],
              [
                -47.3765413548524,
                -0.632116139055622
              ],
              [
                -47.3698814215894,
                -0.630982637907104
              ],
              [
                -47.3663244590626,
                -0.625652677066573
              ],
              [
                -47.37118691428854,
                -0.623025200898729
              ],
              [
                -47.375728893940135,
                -0.620571244419009
              ],
              [
                -47.37783286902964,
                -0.619434228999409
              ],
              [
                -47.38091735902057,
                -0.61369479471529
              ],
              [
                -47.38256281575503,
                -0.610501297477135
              ],
              [
                -47.37985884149747,
                -0.608123306866378
              ],
              [
                -47.353462554218304,
                -0.608603808263884
              ],
              [
                -47.34608312475127,
                -0.603447872875353
              ],
              [
                -47.33806365788508,
                -0.60190787366616
              ],
              [
                -47.33634317400165,
                -0.601577380982408
              ],
              [
                -47.33544167787461,
                -0.601404372194973
              ],
              [
                -47.32908522133857,
                -0.600182874757714
              ],
              [
                -47.32207627415732,
                -0.593842437203399
              ],
              [
                -47.29943996412823,
                -0.598511892879502
              ],
              [
                -47.28640307992831,
                -0.604780354414023
              ],
              [
                -47.282651082365895,
                -0.606584820747421
              ],
              [
                -47.28274259395998,
                -0.615822776271683
              ],
              [
                -47.28585357518915,
                -0.616827245069965
              ],
              [
                -47.288692037870824,
                -0.612965769914069
              ],
              [
                -47.29288350237458,
                -0.607455317084427
              ],
              [
                -47.29279850065668,
                -0.612223278737379
              ],
              [
                -47.292744025835084,
                -0.615290771997944
              ],
              [
                -47.29233104191931,
                -0.616074743155254
              ],
              [
                -47.290400526336036,
                -0.619737226859522
              ],
              [
                -47.28598908527563,
                -0.628104687497256
              ],
              [
                -47.289300052038435,
                -0.628388683657198
              ],
              [
                -47.2895560334666,
                -0.636227130594608
              ],
              [
                -47.289876017024305,
                -0.63629663110552
              ],
              [
                -47.29367353003953,
                -0.637129113245021
              ],
              [
                -47.29258050369606,
                -0.645407526824549
              ],
              [
                -47.287976060871515,
                -0.644030048652095
              ],
              [
                -47.27855313386491,
                -0.641213582751139
              ],
              [
                -47.27197466153992,
                -0.646582517425613
              ],
              [
                -47.26903470374509,
                -0.648927007809067
              ],
              [
                -47.265292717615445,
                -0.64445154967032
              ],
              [
                -47.2589992859995,
                -0.651390494721929
              ],
              [
                -47.25651629606551,
                -0.649586998884233
              ],
              [
                -47.251694818089064,
                -0.646084025454089
              ],
              [
                -47.25529229294142,
                -0.642811057012192
              ],
              [
                -47.26327223872343,
                -0.635552626468216
              ],
              [
                -47.263466751306034,
                -0.634743137484697
              ],
              [
                -47.26481272949526,
                -0.629150670051428
              ],
              [
                -47.26166574671248,
                -0.626084696531056
              ],
              [
                -47.24888182968845,
                -0.631568650847007
              ],
              [
                -47.248840337291526,
                -0.627938169765408
              ],
              [
                -47.235547952591716,
                -0.628000177244769
              ],
              [
                -47.214850593731846,
                -0.636132597254644
              ],
              [
                -47.21210714556438,
                -0.637210106038522
              ],
              [
                -47.20511820167455,
                -0.651864484746042
              ],
              [
                -47.203799199506754,
                -0.654630985113244
              ],
              [
                -47.202305720638755,
                -0.657762431758518
              ],
              [
                -47.1990257490836,
                -0.664639371468929
              ],
              [
                -47.20790566202863,
                -0.680466764842785
              ],
              [
                -47.20879414419187,
                -0.681187778573306
              ],
              [
                -47.214134104101866,
                -0.685522741895838
              ],
              [
                -47.218553590354254,
                -0.686111742914301
              ],
              [
                -47.229497479609,
                -0.678079291406282
              ],
              [
                -47.24077991009878,
                -0.684079259913422
              ],
              [
                -47.246080382985184,
                -0.70380509822975
              ],
              [
                -47.24329188696582,
                -0.706864071319797
              ],
              [
                -47.2281120025659,
                -0.696689664350825
              ],
              [
                -47.22880251059737,
                -0.704707112000734
              ],
              [
                -47.224639020528556,
                -0.702538098701369
              ],
              [
                -47.215848091000865,
                -0.69742613480652
              ],
              [
                -47.21708556889489,
                -0.693650182904606
              ],
              [
                -47.21363412350772,
                -0.690235196916211
              ],
              [
                -47.20986766625634,
                -0.689904700036226
              ],
              [
                -47.20898865596591,
                -0.689827705530066
              ],
              [
                -47.205218671743665,
                -0.689497192650321
              ],
              [
                -47.20395117641938,
                -0.689385686366129
              ],
              [
                -47.20131871751536,
                -0.689155726840812
              ],
              [
                -47.194541265339055,
                -0.699126640310347
              ],
              [
                -47.19168979451922,
                -0.694199187596223
              ],
              [
                -47.194834242648874,
                -0.692886170609862
              ],
              [
                -47.19460676216994,
                -0.683778264133502
              ],
              [
                -47.19458475441362,
                -0.682892772698827
              ],
              [
                -47.183664344698116,
                -0.671310343676983
              ],
              [
                -47.184262827697964,
                -0.664555388846307
              ],
              [
                -47.179221889305275,
                -0.662750403815114
              ],
              [
                -47.1813413486571,
                -0.668485872435595
              ],
              [
                -47.175553929004565,
                -0.666178377067638
              ],
              [
                -47.16757698014398,
                -0.662895902755673
              ],
              [
                -47.17093944212621,
                -0.667220386944407
              ],
              [
                -47.16060903094055,
                -0.667421865574186
              ],
              [
                -47.15833252912647,
                -0.669664349736085
              ],
              [
                -47.16104950110468,
                -0.673071330680116
              ],
              [
                -47.15738152894224,
                -0.674915805676728
              ],
              [
                -47.16499750416426,
                -0.682630239082142
              ],
              [
                -47.16818844777569,
                -0.694669181038603
              ],
              [
                -47.16786797087486,
                -0.698636640578064
              ],
              [
                -47.167678970112114,
                -0.700974107221763
              ],
              [
                -47.167455477573625,
                -0.703745603643338
              ],
              [
                -47.167205458207626,
                -0.706844582634164
              ],
              [
                -47.165471481507964,
                -0.728329425137954
              ],
              [
                -47.16570996567528,
                -0.728469428121848
              ],
              [
                -47.168780950076375,
                -0.730269896055784
              ],
              [
                -47.17338293571208,
                -0.732988374327826
              ],
              [
                -47.17724489429381,
                -0.743279299403918
              ],
              [
                -47.17642888212111,
                -0.747547269692846
              ],
              [
                -47.17568139852627,
                -0.750721769093592
              ],
              [
                -47.17482441072172,
                -0.754362226031084
              ],
              [
                -47.16969042480329,
                -0.776182066840362
              ],
              [
                -47.16675445348457,
                -0.773325568717764
              ],
              [
                -47.168530950953226,
                -0.762674157824345
              ],
              [
                -47.16521248450148,
                -0.760315663983431
              ],
              [
                -47.160206019430355,
                -0.773617058914945
              ],
              [
                -47.15698254450451,
                -0.768727109840889
              ],
              [
                -47.15961653656543,
                -0.756546226385151
              ],
              [
                -47.16027501682783,
                -0.753502708009859
              ],
              [
                -47.16380699798387,
                -0.752329731042587
              ],
              [
                -47.166376464147625,
                -0.744095778325685
              ],
              [
                -47.16479098750661,
                -0.74130183188959
              ],
              [
                -47.14954861328754,
                -0.714447520642774
              ],
              [
                -47.146527123991454,
                -0.718459484604346
              ],
              [
                -47.145626152761345,
                -0.719655462649549
              ],
              [
                -47.143620159833986,
                -0.722319469702605
              ],
              [
                -47.14438815611202,
                -0.731755874773128
              ],
              [
                -47.139884691858704,
                -0.727740433143046
              ],
              [
                -47.14367865796533,
                -0.715902491315653
              ],
              [
                -47.14393813405725,
                -0.715091517356162
              ],
              [
                -47.14524214946587,
                -0.711025029645706
              ],
              [
                -47.1440156553003,
                -0.705708100987544
              ],
              [
                -47.14049769114943,
                -0.705788077079112
              ],
              [
                -47.13468220553654,
                -0.705921086571362
              ],
              [
                -47.13797368476181,
                -0.7018950987446
              ],
              [
                -47.138713689193935,
                -0.700964604716202
              ],
              [
                -47.141345678390415,
                -0.697655634507717
              ],
              [
                -47.14086819091222,
                -0.687549231478092
              ],
              [
                -47.136130211295075,
                -0.68509474828596
              ],
              [
                -47.12940526839468,
                -0.687961715416085
              ],
              [
                -47.12555530425571,
                -0.682561241178947
              ],
              [
                -47.117740840158014,
                -0.679904252234686
              ],
              [
                -47.11122388712679,
                -0.677599787789474
              ],
              [
                -47.10610994085116,
                -0.669735329773434
              ],
              [
                -47.08827158874811,
                -0.662311903429201
              ],
              [
                -47.09066658386981,
                -0.669263841966218
              ],
              [
                -47.08597408987298,
                -0.679350775609669
              ],
              [
                -47.07852864644096,
                -0.683182259495363
              ],
              [
                -47.07312519378473,
                -0.694225180964349
              ],
              [
                -47.07136871289671,
                -0.6978146221377
              ],
              [
                -47.06830574074654,
                -0.704211078337675
              ],
              [
                -47.07431368956703,
                -0.70716954989431
              ],
              [
                -47.08184360269389,
                -0.710877056029745
              ],
              [
                -47.087413577888114,
                -0.713615513193187
              ],
              [
                -47.0788006613497,
                -0.717567477060685
              ],
              [
                -47.07780715615509,
                -0.726283432236429
              ],
              [
                -47.07252317880713,
                -0.723312959813152
              ],
              [
                -47.06920272956009,
                -0.730192413410891
              ],
              [
                -47.07236118733347,
                -0.731137400684839
              ],
              [
                -47.07177821334631,
                -0.735963344790754
              ],
              [
                -47.089188567098866,
                -0.748885747486496
              ],
              [
                -47.08757707834014,
                -0.749653252636505
              ],
              [
                -47.08596509258528,
                -0.7504217607788
              ],
              [
                -47.08583009362247,
                -0.750361746211472
              ],
              [
                -47.07288367309416,
                -0.744638772472998
              ],
              [
                -47.06878022155982,
                -0.754212215001355
              ],
              [
                -47.07187068376807,
                -0.756021188866453
              ],
              [
                -47.076016665851725,
                -0.758448187246965
              ],
              [
                -47.0730756981531,
                -0.760294176260616
              ],
              [
                -47.07196919849172,
                -0.771964065529125
              ],
              [
                -47.066275735601145,
                -0.776174530718932
              ],
              [
                -47.06108226146322,
                -0.774493071690351
              ],
              [
                -47.067570733315414,
                -0.769836596891114
              ],
              [
                -47.06632773123515,
                -0.765474651150739
              ],
              [
                -47.06266575520488,
                -0.76570512108468
              ],
              [
                -47.06296825747181,
                -0.759631167209845
              ],
              [
                -47.063443769738754,
                -0.750078253188464
              ],
              [
                -47.05977328405084,
                -0.736586851040348
              ],
              [
                -47.058577788593475,
                -0.732191386578873
              ],
              [
                -47.05767382181637,
                -0.728771395901029
              ],
              [
                -47.05792329528839,
                -0.724598434925215
              ],
              [
                -47.05877181607852,
                -0.710368031734472
              ],
              [
                -47.05904478448629,
                -0.704779098290458
              ],
              [
                -47.04949686391335,
                -0.711192037776163
              ],
              [
                -47.05182283416123,
                -0.7220049386356
              ],
              [
                -47.05339435648357,
                -0.729310388501711
              ],
              [
                -47.05346782389071,
                -0.729652408967492
              ],
              [
                -47.05441785122338,
                -0.734069866238265
              ],
              [
                -47.05518882599966,
                -0.737651856701538
              ],
              [
                -47.05500884454214,
                -0.746633752969638
              ],
              [
                -47.05468080350628,
                -0.762991169462244
              ],
              [
                -47.054680332507345,
                -0.763017658265499
              ],
              [
                -47.0545358109214,
                -0.770225080735743
              ],
              [
                -47.05446783899781,
                -0.774814061658444
              ],
              [
                -47.05623632894989,
                -0.780241514540308
              ],
              [
                -47.06316278103582,
                -0.800123376673242
              ],
              [
                -47.06118076890272,
                -0.802957855439377
              ],
              [
                -47.060408797693086,
                -0.804061847168921
              ],
              [
                -47.05727579946154,
                -0.805873314407822
              ],
              [
                -47.050482851649235,
                -0.793334418777996
              ],
              [
                -47.03334398353275,
                -0.805085840811607
              ],
              [
                -47.02800803160241,
                -0.802506357418886
              ],
              [
                -47.03003153038733,
                -0.799894889013
              ],
              [
                -47.03632247508262,
                -0.791773917941634
              ],
              [
                -47.039051933557545,
                -0.788249977378702
              ],
              [
                -47.04499739297176,
                -0.789489455770329
              ],
              [
                -47.046413910532834,
                -0.785505480488279
              ],
              [
                -47.04871635957761,
                -0.778864028024268
              ],
              [
                -47.043472899389606,
                -0.771441565623074
              ],
              [
                -47.0407759453583,
                -0.767847104053965
              ],
              [
                -47.040737924968994,
                -0.764506138863233
              ],
              [
                -47.04062093284055,
                -0.754214203296899
              ],
              [
                -47.040498927775666,
                -0.743508790813904
              ],
              [
                -47.044388899986984,
                -0.733144859178854
              ],
              [
                -47.04068895465916,
                -0.728985400743313
              ],
              [
                -47.037356952028425,
                -0.730199392414193
              ],
              [
                -47.03729394321812,
                -0.733392861677379
              ],
              [
                -47.03710195635429,
                -0.737005840812343
              ],
              [
                -47.03376848169505,
                -0.738654318251697
              ],
              [
                -47.02983051564598,
                -0.740455798496318
              ],
              [
                -47.01865262410153,
                -0.730041385859936
              ],
              [
                -47.02174807961944,
                -0.727886418164638
              ],
              [
                -47.01835913090329,
                -0.723913433388218
              ],
              [
                -47.01398463958481,
                -0.721509449861206
              ],
              [
                -47.00868369540177,
                -0.718596456038312
              ],
              [
                -47.00774419163621,
                -0.718079987793553
              ],
              [
                -47.00043474392306,
                -0.714063504002042
              ],
              [
                -46.99499580745153,
                -0.715087993890184
              ],
              [
                -46.98154337330473,
                -0.70497757490051
              ],
              [
                -46.97475945330059,
                -0.704212575988805
              ],
              [
                -46.97153296303228,
                -0.703848596410445
              ],
              [
                -46.96274702597764,
                -0.707482555579506
              ],
              [
                -46.957257097512404,
                -0.713137003999037
              ],
              [
                -46.9550245859691,
                -0.72027596966124
              ],
              [
                -46.95145363606387,
                -0.734060346746202
              ]
            ]
          ],
          [
            [
              [
                -46.401362845894845,
                -0.952300668409373
              ],
              [
                -46.40444780549559,
                -0.957064647112802
              ],
              [
                -46.40727729675952,
                -0.95599465546121
              ],
              [
                -46.40988078865041,
                -0.964372092068246
              ],
              [
                -46.41164324035079,
                -0.970086532514495
              ],
              [
                -46.43702105738892,
                -0.986082438953666
              ],
              [
                -46.435412067827144,
                -0.977742976128545
              ],
              [
                -46.4332766076494,
                -0.966675578658573
              ],
              [
                -46.42880712978023,
                -0.9615505914635
              ],
              [
                -46.42489467353199,
                -0.957065145557834
              ],
              [
                -46.41669821795371,
                -0.947667693915269
              ],
              [
                -46.41404676080941,
                -0.944484731428381
              ],
              [
                -46.41531725192266,
                -0.936493805401818
              ],
              [
                -46.41594225058748,
                -0.932221813459498
              ],
              [
                -46.407814804654414,
                -0.929826339449694
              ],
              [
                -46.40226585821589,
                -0.932552799385567
              ],
              [
                -46.398546369263784,
                -0.938555769995124
              ],
              [
                -46.39589588023144,
                -0.94298423852
              ],
              [
                -46.39897084381579,
                -0.948577186003453
              ],
              [
                -46.401362845894845,
                -0.952300668409373
              ]
            ]
          ],
          [
            [
              [
                -46.07507634958412,
                -1.066853789080017
              ],
              [
                -46.07069640231634,
                -1.069081270758934
              ],
              [
                -46.060530477939544,
                -1.094368051376947
              ],
              [
                -46.06620842841942,
                -1.104784482897367
              ],
              [
                -46.068176896848485,
                -1.099446536265469
              ],
              [
                -46.06827442512637,
                -1.099183030258242
              ],
              [
                -46.0690839141336,
                -1.096987025865612
              ],
              [
                -46.06976138296025,
                -1.096320543964777
              ],
              [
                -46.0726708837427,
                -1.093457554868293
              ],
              [
                -46.08457930380851,
                -1.081738152516289
              ],
              [
                -46.08827725723288,
                -1.073085227015088
              ],
              [
                -46.0903282438952,
                -1.068593244034953
              ],
              [
                -46.08787675893504,
                -1.060934823250831
              ],
              [
                -46.0824868131539,
                -1.058189843167616
              ],
              [
                -46.081747298946595,
                -1.057813318899887
              ],
              [
                -46.07210638438873,
                -1.061236305904607
              ],
              [
                -46.07507634958412,
                -1.066853789080017
              ]
            ]
          ],
          [
            [
              [
                -47.034094011111385,
                -0.695061172316707
              ],
              [
                -47.03406151063859,
                -0.691846169219729
              ],
              [
                -47.02978600835021,
                -0.691694662971946
              ],
              [
                -47.02385308574629,
                -0.691485692006665
              ],
              [
                -47.02313259080586,
                -0.69669064423418
              ],
              [
                -47.013667137404035,
                -0.694488172781705
              ],
              [
                -47.00505823175998,
                -0.700323107619325
              ],
              [
                -47.01033368227175,
                -0.701762600379977
              ],
              [
                -47.01689863589471,
                -0.70351558592625
              ],
              [
                -47.022107570926764,
                -0.704906579046514
              ],
              [
                -47.02751704042354,
                -0.706351056789155
              ],
              [
                -47.03316701107862,
                -0.707860063075864
              ],
              [
                -47.03384148657052,
                -0.700474130047609
              ],
              [
                -47.034094011111385,
                -0.695061172316707
              ]
            ]
          ],
          [
            [
              [
                -46.37509653766546,
                -0.995854857159018
              ],
              [
                -46.3749970324151,
                -1.004113783329591
              ],
              [
                -46.37491255920219,
                -1.011075742215335
              ],
              [
                -46.38082798123475,
                -1.01299370761142
              ],
              [
                -46.384078487843,
                -1.007111242039519
              ],
              [
                -46.383725972028444,
                -1.003399301772325
              ],
              [
                -46.382842000008466,
                -0.994095842288846
              ],
              [
                -46.37413803313301,
                -0.987213899725173
              ],
              [
                -46.37038308269048,
                -0.991076886340872
              ],
              [
                -46.37509653766546,
                -0.995854857159018
              ]
            ]
          ],
          [
            [
              [
                -47.843036823804184,
                -0.662353942908565
              ],
              [
                -47.84121335695427,
                -0.657674982080403
              ],
              [
                -47.83305039287494,
                -0.653773540019412
              ],
              [
                -47.831329425430475,
                -0.658958472827168
              ],
              [
                -47.83334540799798,
                -0.661573974806709
              ],
              [
                -47.83647589540193,
                -0.661252976427923
              ],
              [
                -47.836915386652635,
                -0.666395438737065
              ],
              [
                -47.84039184223923,
                -0.668537430321608
              ],
              [
                -47.843036823804184,
                -0.662353942908565
              ]
            ]
          ],
          [
            [
              [
                -46.44423751572465,
                -1.009880739918046
              ],
              [
                -46.446052498691635,
                -1.010024245074879
              ],
              [
                -46.45046093977312,
                -1.009052245897463
              ],
              [
                -46.437249077782305,
                -1.001111294599111
              ],
              [
                -46.435015088717876,
                -1.003674273155457
              ],
              [
                -46.43554406710393,
                -1.007926264431211
              ],
              [
                -46.43570356071247,
                -1.009207243874311
              ],
              [
                -46.44423751572465,
                -1.009880739918046
              ]
            ]
          ],
          [
            [
              [
                -46.32667893257518,
                -0.95029017758954
              ],
              [
                -46.32263847645999,
                -0.944943228197046
              ],
              [
                -46.31654351448113,
                -0.946123725626067
              ],
              [
                -46.31539951387843,
                -0.950013658323949
              ],
              [
                -46.31874949407902,
                -0.948975165507496
              ],
              [
                -46.32232097418848,
                -0.951169659478819
              ],
              [
                -46.3182794911405,
                -0.953877149700015
              ],
              [
                -46.32316694704496,
                -0.956256127448793
              ],
              [
                -46.32667893257518,
                -0.95029017758954
              ]
            ]
          ],
          [
            [
              [
                -46.607768242038844,
                -0.830815610258755
              ],
              [
                -46.60606877470165,
                -0.825218632918259
              ],
              [
                -46.600921318244886,
                -0.826208132987551
              ],
              [
                -46.60032782741275,
                -0.830135625565584
              ],
              [
                -46.60525828419644,
                -0.832489096488222
              ],
              [
                -46.607768242038844,
                -0.830815610258755
              ]
            ]
          ],
          [
            [
              [
                -46.60815325513167,
                -0.858899902431234
              ],
              [
                -46.60645127783293,
                -0.853306447040559
              ],
              [
                -46.60130781234217,
                -0.854294939102734
              ],
              [
                -46.60071279050814,
                -0.858222893687401
              ],
              [
                -46.60564326428372,
                -0.860573874653503
              ],
              [
                -46.60815325513167,
                -0.858899902431234
              ]
            ]
          ],
          [
            [
              [
                -47.03474247091223,
                -0.720383960122222
              ],
              [
                -47.04296691287202,
                -0.722020456710669
              ],
              [
                -47.044168424929204,
                -0.719306460989439
              ],
              [
                -47.040829955526696,
                -0.71853148044509
              ],
              [
                -47.03706146151257,
                -0.716657984027404
              ],
              [
                -47.03222200440325,
                -0.717989973687195
              ],
              [
                -47.03474247091223,
                -0.720383960122222
              ]
            ]
          ],
          [
            [
              [
                -46.46796682912934,
                -0.963936098340988
              ],
              [
                -46.47499726683092,
                -0.964220102066574
              ],
              [
                -46.46882783222232,
                -0.957716638950686
              ],
              [
                -46.465050855162595,
                -0.95952612096599
              ],
              [
                -46.46796682912934,
                -0.963936098340988
              ]
            ]
          ],
          [
            [
              [
                -46.206390363187026,
                -0.94079423365882
              ],
              [
                -46.202711887212715,
                -0.936363761514353
              ],
              [
                -46.198958402854394,
                -0.940309238431337
              ],
              [
                -46.206390363187026,
                -0.94079423365882
              ]
            ]
          ],
          [
            [
              [
                -46.95424461211,
                -0.740374310022841
              ],
              [
                -46.95145363606387,
                -0.734060346746202
              ],
              [
                -46.94925663946034,
                -0.738723838837854
              ],
              [
                -46.95424461211,
                -0.740374310022841
              ]
            ]
          ],
          [
            [
              [
                -47.021708069048906,
                -0.715428495775585
              ],
              [
                -47.0168291295725,
                -0.713164025171575
              ],
              [
                -47.01874559352401,
                -0.71750648105726
              ],
              [
                -47.021708069048906,
                -0.715428495775585
              ]
            ]
          ],
          [
            [
              [
                -46.2700588817636,
                -0.933487803963308
              ],
              [
                -46.26577490500741,
                -0.93288028702424
              ],
              [
                -46.267081877476926,
                -0.936435762490488
              ],
              [
                -46.2700588817636,
                -0.933487803963308
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 15,
        "nome": "Paraíba",
        "sigla": "PB",
        "regiao_id": 4,
        "codigo_ibg": 25
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -37.226773701726835,
                -6.034635890388484
              ],
              [
                -37.22228275793381,
                -6.041760830097652
              ],
              [
                -37.21040034691525,
                -6.039686331264106
              ],
              [
                -37.20329341630934,
                -6.038445844748153
              ],
              [
                -37.19816995339652,
                -6.043458795817208
              ],
              [
                -37.193890998546905,
                -6.04764574395908
              ],
              [
                -37.18626354878658,
                -6.047639760321692
              ],
              [
                -37.17352368210822,
                -6.047629235582307
              ],
              [
                -37.17516817036545,
                -6.054551705640734
              ],
              [
                -37.170155683275205,
                -6.08266645842884
              ],
              [
                -37.1711141733648,
                -6.089897421649023
              ],
              [
                -37.17224616510752,
                -6.098441841565287
              ],
              [
                -37.168956172789905,
                -6.105442804993898
              ],
              [
                -37.16681269186877,
                -6.110004741480547
              ],
              [
                -37.16820470011485,
                -6.115621705794648
              ],
              [
                -37.1746911326622,
                -6.141794494308783
              ],
              [
                -37.166998682052096,
                -6.152143905472484
              ],
              [
                -37.16597666820786,
                -6.153519410931435
              ],
              [
                -37.15975574067455,
                -6.152554927692161
              ],
              [
                -37.1565257644747,
                -6.152054425643403
              ],
              [
                -37.15742374040762,
                -6.153894399617879
              ],
              [
                -37.16527868708302,
                -6.169985769992604
              ],
              [
                -37.173548607250886,
                -6.179411711489926
              ],
              [
                -37.17381408171997,
                -6.179714707256003
              ],
              [
                -37.17470060981354,
                -6.179855727694982
              ],
              [
                -37.178906054308314,
                -6.180525205029899
              ],
              [
                -37.192537958746804,
                -6.182695695394305
              ],
              [
                -37.19394240977491,
                -6.19797059931429
              ],
              [
                -37.203203854196104,
                -6.205798025178089
              ],
              [
                -37.22568464316395,
                -6.211924998869143
              ],
              [
                -37.23296956470411,
                -6.213910961756009
              ],
              [
                -37.251122413051,
                -6.226070394051253
              ],
              [
                -37.27015071638449,
                -6.251325707453824
              ],
              [
                -37.27432215870971,
                -6.281036484348874
              ],
              [
                -37.28038362104048,
                -6.281455975910353
              ],
              [
                -37.28644556937,
                -6.281876470465345
              ],
              [
                -37.294099502906285,
                -6.280377991195151
              ],
              [
                -37.30687339699691,
                -6.277876522118556
              ],
              [
                -37.31264084753792,
                -6.27892100155962
              ],
              [
                -37.31840726709102,
                -6.279965485001928
              ],
              [
                -37.32110125317268,
                -6.286526969925412
              ],
              [
                -37.32778968784213,
                -6.302814315662114
              ],
              [
                -37.33398762204105,
                -6.307843778958339
              ],
              [
                -37.35616444227351,
                -6.319555239849856
              ],
              [
                -37.36423636747701,
                -6.323817709441422
              ],
              [
                -37.37057780915681,
                -6.333903104019973
              ],
              [
                -37.376919733824096,
                -6.343988048628717
              ],
              [
                -37.377015714927694,
                -6.354881444842888
              ],
              [
                -37.37714071470779,
                -6.368941363143446
              ],
              [
                -37.382152668817895,
                -6.373219800697433
              ],
              [
                -37.382868151046296,
                -6.373950327408376
              ],
              [
                -37.387781636541376,
                -6.378968782078047
              ],
              [
                -37.388149601059155,
                -6.379343778364166
              ],
              [
                -37.38904009087607,
                -6.390624670105904
              ],
              [
                -37.395722529456265,
                -6.400761108660139
              ],
              [
                -37.39699149823357,
                -6.416141497424515
              ],
              [
                -37.390185075208215,
                -6.422134952793535
              ],
              [
                -37.38619559301834,
                -6.425647422533348
              ],
              [
                -37.38171764733173,
                -6.436471324411374
              ],
              [
                -37.390547049118744,
                -6.452443214551717
              ],
              [
                -37.39649146919745,
                -6.513816718939419
              ],
              [
                -37.41633979670152,
                -6.531555611798293
              ],
              [
                -37.420863254780365,
                -6.531728618457382
              ],
              [
                -37.42538571886976,
                -6.531902104112557
              ],
              [
                -37.463003395977644,
                -6.533340602935999
              ],
              [
                -37.468854337562995,
                -6.534770601622002
              ],
              [
                -37.475802261354254,
                -6.542405563353153
              ],
              [
                -37.48273169931946,
                -6.550018985259709
              ],
              [
                -37.48018270142638,
                -6.559426940748723
              ],
              [
                -37.474418242012135,
                -6.564058861545922
              ],
              [
                -37.47971969639862,
                -6.593760171194988
              ],
              [
                -37.47998618558406,
                -6.60482509463459
              ],
              [
                -37.47730319598208,
                -6.606599041916431
              ],
              [
                -37.47560374616972,
                -6.607722546961189
              ],
              [
                -37.474133245473645,
                -6.617522978150213
              ],
              [
                -37.47534022254326,
                -6.628317895370379
              ],
              [
                -37.47681619559832,
                -6.641520782925354
              ],
              [
                -37.473686206664574,
                -6.646618239864249
              ],
              [
                -37.47253171354163,
                -6.648497732355577
              ],
              [
                -37.465790793305146,
                -6.6595671510564
              ],
              [
                -37.47454919373465,
                -6.672065540140876
              ],
              [
                -37.47480368924815,
                -6.672428544472031
              ],
              [
                -37.47469419107784,
                -6.672624065866254
              ],
              [
                -37.47058474022851,
                -6.679943001750399
              ],
              [
                -37.484299583234964,
                -6.709642754665949
              ],
              [
                -37.42486009702084,
                -6.699778317151778
              ],
              [
                -37.40693178288149,
                -6.696802806196248
              ],
              [
                -37.397515861023386,
                -6.699375801598999
              ],
              [
                -37.39729984888469,
                -6.699435275985298
              ],
              [
                -37.342310344252006,
                -6.700854265283295
              ],
              [
                -37.31389608101502,
                -6.688579312170812
              ],
              [
                -37.294473246076265,
                -6.691716800323765
              ],
              [
                -37.28331137024969,
                -6.693520253568765
              ],
              [
                -37.2863838129471,
                -6.702632202164486
              ],
              [
                -37.28976227631856,
                -6.712650121878633
              ],
              [
                -37.27951587500326,
                -6.723841532085416
              ],
              [
                -37.26351600266241,
                -6.731774952901998
              ],
              [
                -37.26843344579559,
                -6.758717765971175
              ],
              [
                -37.27058090998566,
                -6.762889219742149
              ],
              [
                -37.27272888316921,
                -6.7670611695137
              ],
              [
                -37.26640694635744,
                -6.77223663458813
              ],
              [
                -37.256965535301624,
                -6.779966080488186
              ],
              [
                -37.26000699691471,
                -6.786569533823598
              ],
              [
                -37.260188516041445,
                -6.786963516860805
              ],
              [
                -37.25501102103187,
                -6.798071960220417
              ],
              [
                -37.25473951070439,
                -6.810666838333505
              ],
              [
                -37.23419468254998,
                -6.824240205736464
              ],
              [
                -37.1902630677911,
                -6.824969702566916
              ],
              [
                -37.187522616550524,
                -6.822712226368914
              ],
              [
                -37.18478414429172,
                -6.820455724166599
              ],
              [
                -37.18311513181619,
                -6.819317242938935
              ],
              [
                -37.15182091591685,
                -6.797969859787877
              ],
              [
                -37.14736797651535,
                -6.791777923315709
              ],
              [
                -37.128780667470444,
                -6.765932122469391
              ],
              [
                -37.12421618446183,
                -6.766413595557978
              ],
              [
                -37.11965222545042,
                -6.766895595642692
              ],
              [
                -37.075007135976755,
                -6.753740654449363
              ],
              [
                -37.059013260578205,
                -6.749027197580866
              ],
              [
                -37.04087693831174,
                -6.739670248798864
              ],
              [
                -37.03384600822348,
                -6.734180280198476
              ],
              [
                -37.0259560791856,
                -6.728023831270855
              ],
              [
                -37.001796303668876,
                -6.709172466257636
              ],
              [
                -36.98952639949109,
                -6.708756467219727
              ],
              [
                -36.98737541491174,
                -6.711129437074913
              ],
              [
                -36.97992596124537,
                -6.719346895312351
              ],
              [
                -36.977207495864306,
                -6.721459354832931
              ],
              [
                -36.97138654159758,
                -6.740249712924427
              ],
              [
                -36.97287550379768,
                -6.757302578700231
              ],
              [
                -36.96917554858219,
                -6.768953006483367
              ],
              [
                -36.96414958584396,
                -6.773659457011097
              ],
              [
                -36.961330115620015,
                -6.77391945703789
              ],
              [
                -36.95225018341513,
                -6.774756958241047
              ],
              [
                -36.95704013286648,
                -6.780822403780882
              ],
              [
                -36.95868514287943,
                -6.78290588751111
              ],
              [
                -36.95672315732947,
                -6.790079824718708
              ],
              [
                -36.937457314764515,
                -6.772712953224651
              ],
              [
                -36.93690232501998,
                -6.772212433785938
              ],
              [
                -36.909640577966314,
                -6.75673604207213
              ],
              [
                -36.89603120271332,
                -6.752009068051506
              ],
              [
                -36.8346112293673,
                -6.730674219574604
              ],
              [
                -36.78754162093575,
                -6.772978848952313
              ],
              [
                -36.77992570881733,
                -6.779823311785369
              ],
              [
                -36.77230927370617,
                -6.78666824361086
              ],
              [
                -36.76904327129937,
                -6.838351333902785
              ],
              [
                -36.729775598348056,
                -6.835996304533189
              ],
              [
                -36.74024899839509,
                -6.866529100045035
              ],
              [
                -36.744566462391354,
                -6.879116491840652
              ],
              [
                -36.75077640441952,
                -6.889041932942086
              ],
              [
                -36.756986847435044,
                -6.898966833073546
              ],
              [
                -36.75808481823072,
                -6.905399818179572
              ],
              [
                -36.75957580093268,
                -6.914138754410851
              ],
              [
                -36.76389624834742,
                -6.928871112448483
              ],
              [
                -36.765752735463764,
                -6.935201082925064
              ],
              [
                -36.743681892862604,
                -6.967378304352916
              ],
              [
                -36.736877957469396,
                -6.97136226625371
              ],
              [
                -36.73007452707572,
                -6.97534625615208
              ],
              [
                -36.718142105121004,
                -6.982332678835945
              ],
              [
                -36.71111369287917,
                -6.977551697539563
              ],
              [
                -36.71108319019728,
                -6.977484217049232
              ],
              [
                -36.68769891735276,
                -6.926601082402396
              ],
              [
                -36.65476771873711,
                -6.928237042674891
              ],
              [
                -36.63874034989987,
                -6.93291051472807
              ],
              [
                -36.637018354853325,
                -6.933412524577055
              ],
              [
                -36.63196438973137,
                -6.933462009682654
              ],
              [
                -36.61610455758078,
                -6.933615982474828
              ],
              [
                -36.61273506655215,
                -6.935025999022314
              ],
              [
                -36.6093661255194,
                -6.936436455566238
              ],
              [
                -36.56751150748529,
                -6.874396414231185
              ],
              [
                -36.566499006467474,
                -6.86714648679
              ],
              [
                -36.56636602430471,
                -6.86619248223328
              ],
              [
                -36.56769752361085,
                -6.864522502334937
              ],
              [
                -36.569522513953196,
                -6.862234035659377
              ],
              [
                -36.57308294635683,
                -6.857769578358213
              ],
              [
                -36.569594506908835,
                -6.848229629326305
              ],
              [
                -36.569367007357336,
                -6.847607638084311
              ],
              [
                -36.55972659102276,
                -6.840379686603871
              ],
              [
                -36.54322324587996,
                -6.841711182815852
              ],
              [
                -36.537314298174955,
                -6.834771232637008
              ],
              [
                -36.53020288312574,
                -6.826418774821796
              ],
              [
                -36.5115480190129,
                -6.819171308441901
              ],
              [
                -36.50903055363753,
                -6.815897342626165
              ],
              [
                -36.50651508224264,
                -6.8126248948029
              ],
              [
                -36.500875630448135,
                -6.777171639521105
              ],
              [
                -36.50243614479269,
                -6.76646972429031
              ],
              [
                -36.50399614313067,
                -6.755768318066172
              ],
              [
                -36.52177950113671,
                -6.742111430162332
              ],
              [
                -36.5264799566694,
                -6.735659484406692
              ],
              [
                -36.533196393582585,
                -6.733212006296733
              ],
              [
                -36.53390739836812,
                -6.733050513052916
              ],
              [
                -36.54377529012688,
                -6.730806028450316
              ],
              [
                -36.557345187189235,
                -6.710371688340764
              ],
              [
                -36.557034689219776,
                -6.706983241963756
              ],
              [
                -36.55321225231817,
                -6.702329759257154
              ],
              [
                -36.54698878871607,
                -6.694753841100644
              ],
              [
                -36.53659089685413,
                -6.653188119552999
              ],
              [
                -36.52133506614546,
                -6.609895484807303
              ],
              [
                -36.523578045859715,
                -6.601307020431619
              ],
              [
                -36.524113549448465,
                -6.599256047059037
              ],
              [
                -36.51337966235423,
                -6.593996609738074
              ],
              [
                -36.50752869968879,
                -6.595056560478289
              ],
              [
                -36.5015927355141,
                -6.601086032715981
              ],
              [
                -36.473047503924825,
                -6.614009401339291
              ],
              [
                -36.46987852889912,
                -6.618895855460617
              ],
              [
                -36.46008458407951,
                -6.633996250134999
              ],
              [
                -36.45040567504493,
                -6.625158812902298
              ],
              [
                -36.4489181921782,
                -6.623800839703264
              ],
              [
                -36.439028300219235,
                -6.628317772318024
              ],
              [
                -36.437799809783456,
                -6.628878753053507
              ],
              [
                -36.43767130304861,
                -6.62869479242944
              ],
              [
                -36.43542531915884,
                -6.625481287465457
              ],
              [
                -36.44071479809837,
                -6.599620011340586
              ],
              [
                -36.44296229285653,
                -6.560279797404733
              ],
              [
                -36.44874072484833,
                -6.553518866913812
              ],
              [
                -36.45824814386572,
                -6.551047377758803
              ],
              [
                -36.45872615269389,
                -6.550922912059762
              ],
              [
                -36.46523361290174,
                -6.54923039875518
              ],
              [
                -36.46869106163637,
                -6.548473920021869
              ],
              [
                -36.47794650063789,
                -6.540288987101927
              ],
              [
                -36.4805179780876,
                -6.527500104420574
              ],
              [
                -36.48219896356689,
                -6.519141671954376
              ],
              [
                -36.49110088435295,
                -6.516125676710021
              ],
              [
                -36.50000283114674,
                -6.513109715462537
              ],
              [
                -36.51880564717169,
                -6.501997315985338
              ],
              [
                -36.525417600190124,
                -6.500765333064006
              ],
              [
                -36.5265655820996,
                -6.489780406116907
              ],
              [
                -36.52770559601717,
                -6.478874494549062
              ],
              [
                -36.52965009276178,
                -6.460227663065369
              ],
              [
                -36.52920009210884,
                -6.453475187474814
              ],
              [
                -36.52875011545033,
                -6.446723760882146
              ],
              [
                -36.52364214496093,
                -6.435654328655464
              ],
              [
                -36.50706332831464,
                -6.399722117022717
              ],
              [
                -36.504511851919624,
                -6.38571122096335
              ],
              [
                -36.499419893918635,
                -6.357770943296829
              ],
              [
                -36.44440086693137,
                -6.361319385162402
              ],
              [
                -36.43713644538213,
                -6.3615898684066
              ],
              [
                -36.429873038829236,
                -6.361860366651862
              ],
              [
                -36.39413037479099,
                -6.293579878053765
              ],
              [
                -36.36216865126962,
                -6.30958326222604
              ],
              [
                -36.35847816406055,
                -6.308088753851517
              ],
              [
                -36.34348382536233,
                -6.302015774982753
              ],
              [
                -36.307152163969754,
                -6.281103912458507
              ],
              [
                -36.303402696065874,
                -6.284805391524187
              ],
              [
                -36.29491775743717,
                -6.293181828254085
              ],
              [
                -36.27944436177557,
                -6.30845619645878
              ],
              [
                -36.278584356067746,
                -6.317052134194369
              ],
              [
                -36.27813086733459,
                -6.345412906222151
              ],
              [
                -36.294431187305904,
                -6.370473228288564
              ],
              [
                -36.297993151566885,
                -6.378393647287918
              ],
              [
                -36.29516870451796,
                -6.39296352716771
              ],
              [
                -36.25072258865818,
                -6.404027449647201
              ],
              [
                -36.24933107014207,
                -6.436963155780995
              ],
              [
                -36.218684851630066,
                -6.43350618086701
              ],
              [
                -36.20301396107093,
                -6.432467697191686
              ],
              [
                -36.171313269347664,
                -6.430199686162632
              ],
              [
                -36.142995992375894,
                -6.425002218732041
              ],
              [
                -36.13815003690576,
                -6.426150680476839
              ],
              [
                -36.13136609144764,
                -6.427759193313544
              ],
              [
                -36.12696614259904,
                -6.424808676115465
              ],
              [
                -36.11083477227468,
                -6.428529174136516
              ],
              [
                -36.102670375851176,
                -6.421155219942504
              ],
              [
                -36.09703841339157,
                -6.419188718108545
              ],
              [
                -36.093807454960285,
                -6.418060738088081
              ],
              [
                -36.08629703069673,
                -6.415438256320035
              ],
              [
                -36.07956506554712,
                -6.404792845256449
              ],
              [
                -36.07226163048473,
                -6.41002576576418
              ],
              [
                -36.072730136050076,
                -6.412005263217501
              ],
              [
                -36.073314145273784,
                -6.414474758823149
              ],
              [
                -36.06980716952877,
                -6.414591235674733
              ],
              [
                -36.06677617554277,
                -6.41469173695514
              ],
              [
                -36.061288258628466,
                -6.409848289255986
              ],
              [
                -36.05113181893852,
                -6.431112084644252
              ],
              [
                -36.05080131645375,
                -6.431804094896892
              ],
              [
                -36.04353087060342,
                -6.437835545022132
              ],
              [
                -36.036261440746465,
                -6.443867984136963
              ],
              [
                -36.035248435504535,
                -6.444333984765194
              ],
              [
                -36.01283312032682,
                -6.454641401048385
              ],
              [
                -36.007884174169725,
                -6.464721328230999
              ],
              [
                -36.007963172379604,
                -6.464853332224811
              ],
              [
                -36.012439645587236,
                -6.472357255024196
              ],
              [
                -36.00690117497956,
                -6.474122727371476
              ],
              [
                -36.00489521575515,
                -6.480655182823717
              ],
              [
                -35.977186450535,
                -6.488506126337698
              ],
              [
                -35.964784557358605,
                -6.48395913882878
              ],
              [
                -35.943728250221504,
                -6.492607547180557
              ],
              [
                -35.852617537265814,
                -6.48717203575013
              ],
              [
                -35.779465186453805,
                -6.482797553425622
              ],
              [
                -35.77616319817519,
                -6.482600548914538
              ],
              [
                -35.77286074890142,
                -6.482404043399555
              ],
              [
                -35.76367684967982,
                -6.480109547996499
              ],
              [
                -35.72729967159251,
                -6.471021097954287
              ],
              [
                -35.72118270606543,
                -6.471632617181613
              ],
              [
                -35.717479231048,
                -6.472002111876228
              ],
              [
                -35.709818804965614,
                -6.470086609424254
              ],
              [
                -35.681502059190656,
                -6.4475272798387
              ],
              [
                -35.67087618014878,
                -6.451322741656201
              ],
              [
                -35.664685729458895,
                -6.450112225496034
              ],
              [
                -35.662508246431734,
                -6.447830749467832
              ],
              [
                -35.65792228000514,
                -6.445570767756577
              ],
              [
                -35.63538798301705,
                -6.45392768741645
              ],
              [
                -35.628909036358046,
                -6.455234672837416
              ],
              [
                -35.61980711291994,
                -6.457071651348037
              ],
              [
                -35.61810363159519,
                -6.459650154542353
              ],
              [
                -35.61641563714558,
                -6.462205604931804
              ],
              [
                -35.6120011731143,
                -6.461677594403147
              ],
              [
                -35.59971129839674,
                -6.460207643503546
              ],
              [
                -35.59437932668017,
                -6.467532055241979
              ],
              [
                -35.591687880528305,
                -6.471229022815447
              ],
              [
                -35.59123536411382,
                -6.476758994056706
              ],
              [
                -35.580486454806895,
                -6.486054894528205
              ],
              [
                -35.56371161786469,
                -6.48706739381369
              ],
              [
                -35.56431610171785,
                -6.491377864525138
              ],
              [
                -35.55854563892602,
                -6.492388363759312
              ],
              [
                -35.55135821501707,
                -6.489990845513839
              ],
              [
                -35.54927674089323,
                -6.486540372960096
              ],
              [
                -35.54621473960179,
                -6.481464434870163
              ],
              [
                -35.54028682817909,
                -6.482564404289694
              ],
              [
                -35.537477318351726,
                -6.47950344915254
              ],
              [
                -35.53489187041069,
                -6.481373911474968
              ],
              [
                -35.53315686321437,
                -6.482628396288797
              ],
              [
                -35.527849910820215,
                -6.479589928402764
              ],
              [
                -35.52665494499007,
                -6.478905958156021
              ],
              [
                -35.52481646253147,
                -6.475694456847727
              ],
              [
                -35.51726601035303,
                -6.478947937916598
              ],
              [
                -35.51186957145272,
                -6.476413448926924
              ],
              [
                -35.500937676110716,
                -6.477394445163425
              ],
              [
                -35.49742067458649,
                -6.48080791447356
              ],
              [
                -35.49530770429558,
                -6.482858370638444
              ],
              [
                -35.49306024368068,
                -6.480982416329121
              ],
              [
                -35.49150225850529,
                -6.479681913820974
              ],
              [
                -35.48959774372568,
                -6.481120888039006
              ],
              [
                -35.48363832648182,
                -6.485624875031289
              ],
              [
                -35.47665537875825,
                -6.486447352020172
              ],
              [
                -35.472905916636826,
                -6.483007892359952
              ],
              [
                -35.472631924258394,
                -6.482756895209072
              ],
              [
                -35.46373597854715,
                -6.482305358259137
              ],
              [
                -35.459708532357354,
                -6.476586901789361
              ],
              [
                -35.446208156609615,
                -6.486441810937394
              ],
              [
                -35.44172417190851,
                -6.478531876842387
              ],
              [
                -35.44112967174002,
                -6.487326337623509
              ],
              [
                -35.429649802524544,
                -6.488117819039608
              ],
              [
                -35.40723700282631,
                -6.490313280281523
              ],
              [
                -35.402799534161545,
                -6.491270780780605
              ],
              [
                -35.398361584503114,
                -6.492228275279144
              ],
              [
                -35.36402186844642,
                -6.511637594281386
              ],
              [
                -35.34076956710599,
                -6.538211351473596
              ],
              [
                -35.335524087545714,
                -6.541074336091357
              ],
              [
                -35.33027915198329,
                -6.543936809712216
              ],
              [
                -35.27406764177681,
                -6.543472795989964
              ],
              [
                -35.26338825814682,
                -6.538538816054589
              ],
              [
                -35.25270882452364,
                -6.53360536113021
              ],
              [
                -35.25156133294597,
                -6.533482352400757
              ],
              [
                -35.23184453104479,
                -6.531371351037596
              ],
              [
                -35.224586597407665,
                -6.521949914518227
              ],
              [
                -35.224251095706286,
                -6.521514426822856
              ],
              [
                -35.224014583031654,
                -6.521207443152398
              ],
              [
                -35.22247213117638,
                -6.516046971841655
              ],
              [
                -35.2177296495964,
                -6.518197947504545
              ],
              [
                -35.209647735824625,
                -6.536778320449706
              ],
              [
                -35.20770623952943,
                -6.541242745196235
              ],
              [
                -35.20022880051018,
                -6.548342184192996
              ],
              [
                -35.198974804072584,
                -6.549532690795372
              ],
              [
                -35.19349535037248,
                -6.549739199679013
              ],
              [
                -35.16979156204604,
                -6.557985597189576
              ],
              [
                -35.16078564427202,
                -6.553289641430855
              ],
              [
                -35.1569771848915,
                -6.551303145074161
              ],
              [
                -35.15124222735,
                -6.551779665619268
              ],
              [
                -35.145240788202564,
                -6.552279154811327
              ],
              [
                -35.14256178855609,
                -6.548822677040993
              ],
              [
                -35.132305394440564,
                -6.535591258478866
              ],
              [
                -35.132157906891365,
                -6.535400770925266
              ],
              [
                -35.122941512816226,
                -6.529453821198948
              ],
              [
                -35.112132591302846,
                -6.507207495244891
              ],
              [
                -35.11205460546079,
                -6.504876500041012
              ],
              [
                -35.107892145876995,
                -6.505140006314283
              ],
              [
                -35.10316916133389,
                -6.505439000951234
              ],
              [
                -35.10238419779937,
                -6.503228501334796
              ],
              [
                -35.10114771813388,
                -6.499745538727318
              ],
              [
                -35.09503025391199,
                -6.497271564922992
              ],
              [
                -35.06934148169554,
                -6.52167683753981
              ],
              [
                -35.0666304905748,
                -6.524252335019421
              ],
              [
                -35.06192256012354,
                -6.525819311406194
              ],
              [
                -35.05721557066594,
                -6.527386301792768
              ],
              [
                -35.03644625297047,
                -6.516718862112998
              ],
              [
                -35.03406079857345,
                -6.513431895219584
              ],
              [
                -35.028025345288356,
                -6.505114948759766
              ],
              [
                -35.018282460862665,
                -6.502654454612078
              ],
              [
                -35.01199449113316,
                -6.505158945430125
              ],
              [
                -35.01059752241283,
                -6.505715455056762
              ],
              [
                -34.99154467850446,
                -6.501088458669769
              ],
              [
                -34.98612022158402,
                -6.496187016988771
              ],
              [
                -34.97916380837118,
                -6.489901060579649
              ],
              [
                -34.974917826250696,
                -6.487493049451468
              ],
              [
                -34.97050489169873,
                -6.484991069984145
              ],
              [
                -34.96906337896985,
                -6.48783704403754
              ],
              [
                -34.967041414773206,
                -6.491838034375677
              ],
              [
                -34.96666340224855,
                -6.509470862333875
              ],
              [
                -34.966026412083835,
                -6.544654587100434
              ],
              [
                -34.96589188965493,
                -6.552084547885205
              ],
              [
                -34.96575687022518,
                -6.559514488676575
              ],
              [
                -34.96497736823241,
                -6.602548157085986
              ],
              [
                -34.96174289294986,
                -6.639773851101258
              ],
              [
                -34.95354243899915,
                -6.668307601321877
              ],
              [
                -34.946352986111634,
                -6.680750990214711
              ],
              [
                -34.93780005505786,
                -6.684573968877255
              ],
              [
                -34.931995609750054,
                -6.687440948013457
              ],
              [
                -34.930853135727894,
                -6.688005455724103
              ],
              [
                -34.932278092936336,
                -6.692543390973419
              ],
              [
                -34.93272659696642,
                -6.693970373732434
              ],
              [
                -34.93416456916814,
                -6.698330353432573
              ],
              [
                -34.93308211537646,
                -6.70706378220218
              ],
              [
                -34.932260092569095,
                -6.713693738132944
              ],
              [
                -34.93149859615125,
                -6.720415182368186
              ],
              [
                -34.93295957400463,
                -6.726532646910628
              ],
              [
                -34.933819560874724,
                -6.730188092949913
              ],
              [
                -34.93408857370633,
                -6.731330595898902
              ],
              [
                -34.933629560411006,
                -6.732029572961221
              ],
              [
                -34.93152459739896,
                -6.735236565719087
              ],
              [
                -34.92879662023866,
                -6.739666041207905
              ],
              [
                -34.931841070017484,
                -6.743814004682173
              ],
              [
                -34.93234207304383,
                -6.744497011491411
              ],
              [
                -34.93836901821263,
                -6.752707922101921
              ],
              [
                -34.93267455739338,
                -6.756356394989234
              ],
              [
                -34.93281957852699,
                -6.760315386133982
              ],
              [
                -34.93336103981459,
                -6.76931578185855
              ],
              [
                -34.92980458816204,
                -6.769067779563396
              ],
              [
                -34.91835567630315,
                -6.768268800621094
              ],
              [
                -34.91712770362596,
                -6.780324212548808
              ],
              [
                -34.91484819775888,
                -6.802702029543463
              ],
              [
                -34.9139257202331,
                -6.811749450972703
              ],
              [
                -34.91389668430942,
                -6.812036446639215
              ],
              [
                -34.913814697526405,
                -6.812844435069615
              ],
              [
                -34.91305220585876,
                -6.819871368905766
              ],
              [
                -34.90224729916435,
                -6.854395114520723
              ],
              [
                -34.90222975947751,
                -6.864855510197593
              ],
              [
                -34.89638532096058,
                -6.871631951759625
              ],
              [
                -34.88392643931565,
                -6.886077334183258
              ],
              [
                -34.87967746056384,
                -6.891004290692515
              ],
              [
                -34.868198054340375,
                -6.897404239583953
              ],
              [
                -34.86351759522349,
                -6.898191229168683
              ],
              [
                -34.85308419659438,
                -6.899946200174613
              ],
              [
                -34.8627325901164,
                -6.91675058210558
              ],
              [
                -34.863606109186996,
                -6.921323049843625
              ],
              [
                -34.863665589444594,
                -6.921631536397622
              ],
              [
                -34.86417106412037,
                -6.924275535431302
              ],
              [
                -34.86645056559399,
                -6.936195446923731
              ],
              [
                -34.86546357346882,
                -6.939320926100321
              ],
              [
                -34.862972592433294,
                -6.946277371432427
              ],
              [
                -34.86079309922609,
                -6.952246799921365
              ],
              [
                -34.85273567819015,
                -6.961363219180478
              ],
              [
                -34.850243687900885,
                -6.964182716827262
              ],
              [
                -34.85257165037014,
                -6.967851160833696
              ],
              [
                -34.855596636793486,
                -6.972616116478602
              ],
              [
                -34.86055759219338,
                -6.975094615819268
              ],
              [
                -34.868319993280515,
                -6.978973107756512
              ],
              [
                -34.87036599471119,
                -6.985029033379516
              ],
              [
                -34.867144505571204,
                -7.008004855370129
              ],
              [
                -34.85904355546817,
                -7.026702693555788
              ],
              [
                -34.85497309911233,
                -7.027066716906183
              ],
              [
                -34.85459061255555,
                -7.027101226372845
              ],
              [
                -34.84809018224502,
                -7.010992316625281
              ],
              [
                -34.84120875092984,
                -7.004527878056762
              ],
              [
                -34.837827264697715,
                -7.001351395368828
              ],
              [
                -34.83463732362283,
                -6.998334414528017
              ],
              [
                -34.83504179182409,
                -6.979007078386086
              ],
              [
                -34.8350477999651,
                -6.978713078757216
              ],
              [
                -34.84129025774251,
                -6.966136660161459
              ],
              [
                -34.8389832926415,
                -6.966149657528249
              ],
              [
                -34.82967584096432,
                -6.966201179947523
              ],
              [
                -34.82812585361701,
                -6.980349561986826
              ],
              [
                -34.82525336019301,
                -7.006566345021747
              ],
              [
                -34.82964331142253,
                -7.03046266665375
              ],
              [
                -34.84025672834479,
                -7.042202561317287
              ],
              [
                -34.84104021626321,
                -7.045152039120294
              ],
              [
                -34.8418702045836,
                -7.048363013853087
              ],
              [
                -34.84332120950155,
                -7.055697968842355
              ],
              [
                -34.84100520231846,
                -7.060419936319346
              ],
              [
                -34.83787072777464,
                -7.06778338400948
              ],
              [
                -34.83667225211183,
                -7.068565885912597
              ],
              [
                -34.829896809552686,
                -7.072991833772568
              ],
              [
                -34.8311148047774,
                -7.08149376424134
              ],
              [
                -34.83244127549283,
                -7.090763180625517
              ],
              [
                -34.83343823959362,
                -7.097953133512003
              ],
              [
                -34.83136578385596,
                -7.100210115974799
              ],
              [
                -34.823924328933266,
                -7.108176065932093
              ],
              [
                -34.82174986707053,
                -7.110507533726666
              ],
              [
                -34.82172333265162,
                -7.120331461768994
              ],
              [
                -34.82169534503344,
                -7.130454364420597
              ],
              [
                -34.81580290773528,
                -7.137416294497472
              ],
              [
                -34.80968392433972,
                -7.144601756623928
              ],
              [
                -34.803577991144635,
                -7.145443712715455
              ],
              [
                -34.80338651786356,
                -7.145469724376557
              ],
              [
                -34.796778047183494,
                -7.146380703572327
              ],
              [
                -34.79311157819917,
                -7.154080672224075
              ],
              [
                -34.79325509135608,
                -7.159278122570805
              ],
              [
                -34.793414094994276,
                -7.165026083510031
              ],
              [
                -34.79424906676062,
                -7.195244823425694
              ],
              [
                -34.79872052062245,
                -7.203376769206055
              ],
              [
                -34.79898001894498,
                -7.203848251599311
              ],
              [
                -34.799435013249436,
                -7.204676277264588
              ],
              [
                -34.80247997376464,
                -7.210303743184496
              ],
              [
                -34.80407445565574,
                -7.228691086737351
              ],
              [
                -34.80423543391553,
                -7.23054955293805
              ],
              [
                -34.804369421629566,
                -7.232103543563264
              ],
              [
                -34.80502593744272,
                -7.239692482137437
              ],
              [
                -34.80459294070954,
                -7.247895925041214
              ],
              [
                -34.803842431689205,
                -7.262116303484469
              ],
              [
                -34.79835197545439,
                -7.279508663245362
              ],
              [
                -34.79985492676334,
                -7.282471131535671
              ],
              [
                -34.79949643609174,
                -7.288125105965109
              ],
              [
                -34.79953495953534,
                -7.28842309260326
              ],
              [
                -34.80027691984085,
                -7.29412757739418
              ],
              [
                -34.798942938364156,
                -7.297839530719603
              ],
              [
                -34.798653429439575,
                -7.298632519163578
              ],
              [
                -34.79741647530772,
                -7.302018984165864
              ],
              [
                -34.79829394377766,
                -7.305709986195406
              ],
              [
                -34.79962491980239,
                -7.311308925254048
              ],
              [
                -34.80142790204481,
                -7.317619886941553
              ],
              [
                -34.79866594468753,
                -7.32108733124152
              ],
              [
                -34.79741695469206,
                -7.322991811115156
              ],
              [
                -34.796478451209225,
                -7.324423332993894
              ],
              [
                -34.79353348779777,
                -7.328914269967002
              ],
              [
                -34.794731481421856,
                -7.335363225141035
              ],
              [
                -34.79636693127172,
                -7.342956175464423
              ],
              [
                -34.798081411122,
                -7.349516116125019
              ],
              [
                -34.799569395214924,
                -7.357229069402854
              ],
              [
                -34.79813193125039,
                -7.361519510045844
              ],
              [
                -34.79663892337895,
                -7.366393474979444
              ],
              [
                -34.79811492250864,
                -7.371343438384719
              ],
              [
                -34.80174987628689,
                -7.383056852173229
              ],
              [
                -34.80502581440468,
                -7.41022364109821
              ],
              [
                -34.806529793935184,
                -7.422699536365425
              ],
              [
                -34.80678130717697,
                -7.424784527867433
              ],
              [
                -34.807782275210094,
                -7.433090445150722
              ],
              [
                -34.81086423188566,
                -7.458942274652689
              ],
              [
                -34.809133756563135,
                -7.461792228647116
              ],
              [
                -34.80483676452427,
                -7.468869691033724
              ],
              [
                -34.80312178611632,
                -7.471640678670839
              ],
              [
                -34.806136756922804,
                -7.474207138300289
              ],
              [
                -34.81018624975623,
                -7.477592128126371
              ],
              [
                -34.810494241776965,
                -7.477850112283654
              ],
              [
                -34.81437218253439,
                -7.486324529330939
              ],
              [
                -34.81287270689,
                -7.493677973519409
              ],
              [
                -34.8121481899389,
                -7.497212455769636
              ],
              [
                -34.80925672107861,
                -7.511329848950404
              ],
              [
                -34.81451418033043,
                -7.519739787545272
              ],
              [
                -34.814525168218466,
                -7.519756809417216
              ],
              [
                -34.81977312556116,
                -7.528151726143166
              ],
              [
                -34.82003810853445,
                -7.529017233423187
              ],
              [
                -34.825666064256076,
                -7.547367084991437
              ],
              [
                -34.83161948543219,
                -7.548468078476246
              ],
              [
                -34.83357448042971,
                -7.548829579994317
              ],
              [
                -34.83981542777103,
                -7.543309640516959
              ],
              [
                -34.85315578986348,
                -7.538950167867549
              ],
              [
                -34.85748277262287,
                -7.537537184243537
              ],
              [
                -34.861643747250454,
                -7.541178161179688
              ],
              [
                -34.86314973663849,
                -7.542495638749993
              ],
              [
                -34.87022065903059,
                -7.548683584467391
              ],
              [
                -34.8818745502516,
                -7.5404551774636
              ],
              [
                -34.88776352083493,
                -7.536297222852659
              ],
              [
                -34.89030846841906,
                -7.539536173846823
              ],
              [
                -34.8947464260979,
                -7.545182125009744
              ],
              [
                -34.90028288088814,
                -7.545182162987476
              ],
              [
                -34.90085786813768,
                -7.537606698806743
              ],
              [
                -34.900923372168954,
                -7.536739194771668
              ],
              [
                -34.903220366180484,
                -7.536959205666045
              ],
              [
                -34.909787809175455,
                -7.537586206381473
              ],
              [
                -34.9106742966139,
                -7.542470672073332
              ],
              [
                -34.91456177354847,
                -7.542215684899671
              ],
              [
                -34.92390818303017,
                -7.53220127445429
              ],
              [
                -34.928020132223764,
                -7.53015879569012
              ],
              [
                -34.932133095411174,
                -7.528114315941882
              ],
              [
                -34.93574157638168,
                -7.529943292950593
              ],
              [
                -34.950086956103746,
                -7.537216250283367
              ],
              [
                -34.960248345192575,
                -7.538346217584442
              ],
              [
                -34.96891027646584,
                -7.520645885868291
              ],
              [
                -34.98385316453798,
                -7.506111999424131
              ],
              [
                -34.98419315792684,
                -7.502679534027046
              ],
              [
                -34.984405155152075,
                -7.501086034880729
              ],
              [
                -34.984604146424815,
                -7.499590029948179
              ],
              [
                -34.98577314828468,
                -7.497572054871126
              ],
              [
                -34.99135258865835,
                -7.487940156646341
              ],
              [
                -34.98599114578409,
                -7.47042528247267
              ],
              [
                -34.986701662943695,
                -7.462605857341836
              ],
              [
                -34.990992643267866,
                -7.459538396864283
              ],
              [
                -35.007222969556565,
                -7.447935988957235
              ],
              [
                -35.02902632418993,
                -7.425415160081939
              ],
              [
                -35.038300716081686,
                -7.421405718645136
              ],
              [
                -35.047577631958845,
                -7.417396244207179
              ],
              [
                -35.048257125198,
                -7.416848748055928
              ],
              [
                -35.065249486144005,
                -7.4031573483032
              ],
              [
                -35.07479792246262,
                -7.397915425875048
              ],
              [
                -35.079369386016644,
                -7.396621411432784
              ],
              [
                -35.085428830566165,
                -7.401717912082091
              ],
              [
                -35.08794928325292,
                -7.403838384960316
              ],
              [
                -35.09738420300196,
                -7.407898338282648
              ],
              [
                -35.11935999244315,
                -7.40336239103465
              ],
              [
                -35.12446646847241,
                -7.400252407446338
              ],
              [
                -35.12562797247096,
                -7.399545410905487
              ],
              [
                -35.12912842237292,
                -7.398759445653096
              ],
              [
                -35.13413937985793,
                -7.397633927178233
              ],
              [
                -35.15327473281507,
                -7.392038524343
              ],
              [
                -35.17050854797669,
                -7.387877537721152
              ],
              [
                -35.180274489220274,
                -7.395574491330749
              ],
              [
                -35.19026640649546,
                -7.397560491608762
              ],
              [
                -35.19599635427847,
                -7.391153041712601
              ],
              [
                -35.199578318761965,
                -7.387147563159039
              ],
              [
                -35.222809619799456,
                -7.380205624932234
              ],
              [
                -35.22602806738686,
                -7.37927413863374
              ],
              [
                -35.22865955203524,
                -7.378683170206823
              ],
              [
                -35.229011554911274,
                -7.378604647081863
              ],
              [
                -35.23080055117959,
                -7.378001654152935
              ],
              [
                -35.23199103471095,
                -7.377600688190453
              ],
              [
                -35.24327392437008,
                -7.373983680996128
              ],
              [
                -35.27743911895595,
                -7.386443611078049
              ],
              [
                -35.277551123915366,
                -7.38648461783138
              ],
              [
                -35.27965857833827,
                -7.387253623210848
              ],
              [
                -35.3062303413928,
                -7.406916494726608
              ],
              [
                -35.31559876598689,
                -7.405302507247813
              ],
              [
                -35.323595700796204,
                -7.410903948421496
              ],
              [
                -35.33451810845234,
                -7.406404000998634
              ],
              [
                -35.33240708739939,
                -7.414913435845669
              ],
              [
                -35.3330026006905,
                -7.422343364215622
              ],
              [
                -35.341878524930785,
                -7.421874379296399
              ],
              [
                -35.3518534148374,
                -7.421997884456909
              ],
              [
                -35.34890594335063,
                -7.431844294108773
              ],
              [
                -35.34889044845263,
                -7.431896816680385
              ],
              [
                -35.34810196667034,
                -7.434532275176136
              ],
              [
                -35.35060242826996,
                -7.439861739630622
              ],
              [
                -35.36240130999777,
                -7.442381222089631
              ],
              [
                -35.365541814230106,
                -7.441596739575987
              ],
              [
                -35.36561428858962,
                -7.441578267774787
              ],
              [
                -35.36880076541585,
                -7.440783247377684
              ],
              [
                -35.37202723860714,
                -7.444529725942273
              ],
              [
                -35.37208375905005,
                -7.444595219462741
              ],
              [
                -35.372954719463635,
                -7.445606226060137
              ],
              [
                -35.37543822197936,
                -7.448489224952334
              ],
              [
                -35.38014668025868,
                -7.448831703619199
              ],
              [
                -35.40779990917378,
                -7.460831642277827
              ],
              [
                -35.41480432355345,
                -7.47112553665131
              ],
              [
                -35.42075880201996,
                -7.473663527817193
              ],
              [
                -35.423444769287975,
                -7.472992056084376
              ],
              [
                -35.433914678785726,
                -7.470377072391411
              ],
              [
                -35.43765862526782,
                -7.46315512238222
              ],
              [
                -35.44107162926787,
                -7.456572171066807
              ],
              [
                -35.47896526337466,
                -7.444646816983042
              ],
              [
                -35.490889182943995,
                -7.450162776840862
              ],
              [
                -35.5028140525128,
                -7.455678743718716
              ],
              [
                -35.50705250391931,
                -7.486514507407995
              ],
              [
                -35.50610452287871,
                -7.49280143591074
              ],
              [
                -35.505156521834614,
                -7.499088391417149
              ],
              [
                -35.50145052777658,
                -7.512027302233505
              ],
              [
                -35.507652970727534,
                -7.516359278441247
              ],
              [
                -35.5086974617841,
                -7.517088254429185
              ],
              [
                -35.5106449391378,
                -7.528154646223269
              ],
              [
                -35.51921937606641,
                -7.5360990965908
              ],
              [
                -35.51948435538636,
                -7.550116981838071
              ],
              [
                -35.526662298542334,
                -7.569381854671581
              ],
              [
                -35.53619670106249,
                -7.579672288282134
              ],
              [
                -35.53587467658305,
                -7.58695621044472
              ],
              [
                -35.529426243954646,
                -7.589282183307158
              ],
              [
                -35.52809672824253,
                -7.58886219665016
              ],
              [
                -35.51489686425346,
                -7.584684236985693
              ],
              [
                -35.521450293971874,
                -7.598532636286667
              ],
              [
                -35.51760381109116,
                -7.606014065296019
              ],
              [
                -35.52531128080764,
                -7.607147552017138
              ],
              [
                -35.526512249849375,
                -7.610033044091251
              ],
              [
                -35.53118770739544,
                -7.621264435764961
              ],
              [
                -35.52248378080367,
                -7.632051883073406
              ],
              [
                -35.51402784820448,
                -7.642533283982353
              ],
              [
                -35.52013627209407,
                -7.643966243183842
              ],
              [
                -35.526457739029794,
                -7.645449279150327
              ],
              [
                -35.53231065261284,
                -7.654415702655872
              ],
              [
                -35.54324008868256,
                -7.656376684280575
              ],
              [
                -35.54635204923098,
                -7.656935179180771
              ],
              [
                -35.56033292541995,
                -7.659443167763158
              ],
              [
                -35.571674811824,
                -7.654679727801835
              ],
              [
                -35.57182781649422,
                -7.654615201425029
              ],
              [
                -35.588570671876646,
                -7.650388775231436
              ],
              [
                -35.592900110574185,
                -7.652156266496001
              ],
              [
                -35.592903619541445,
                -7.65215774348696
              ],
              [
                -35.597230594263074,
                -7.653924249759688
              ],
              [
                -35.61003797882042,
                -7.654109756819269
              ],
              [
                -35.61885140425696,
                -7.663770676147649
              ],
              [
                -35.621437866282236,
                -7.667367134701144
              ],
              [
                -35.62831531221147,
                -7.676928086413398
              ],
              [
                -35.636443704460035,
                -7.68437752473568
              ],
              [
                -35.652764072514515,
                -7.695110976320861
              ],
              [
                -35.65770752159042,
                -7.697023428948236
              ],
              [
                -35.65906102529348,
                -7.697179452732156
              ],
              [
                -35.68050181252668,
                -7.699643428380444
              ],
              [
                -35.693231705219304,
                -7.699367930095169
              ],
              [
                -35.69487171883627,
                -7.693568511013645
              ],
              [
                -35.69958614502306,
                -7.691455501193325
              ],
              [
                -35.707716071772566,
                -7.69693798109868
              ],
              [
                -35.71021207383389,
                -7.70156795550345
              ],
              [
                -35.711784035497175,
                -7.704483439721741
              ],
              [
                -35.717655979480455,
                -7.707410886077579
              ],
              [
                -35.72496293223656,
                -7.708120424980063
              ],
              [
                -35.73589031717997,
                -7.709180907843161
              ],
              [
                -35.7426357383714,
                -7.713363888987321
              ],
              [
                -35.74405775813765,
                -7.716602333567184
              ],
              [
                -35.74575171498946,
                -7.720459804482259
              ],
              [
                -35.75133566665955,
                -7.723272310554496
              ],
              [
                -35.75776109843876,
                -7.723877804631492
              ],
              [
                -35.761558110035715,
                -7.724235794676325
              ],
              [
                -35.771388000173374,
                -7.73149924494659
              ],
              [
                -35.78010292495882,
                -7.737938226881202
              ],
              [
                -35.78477888513757,
                -7.738988214156596
              ],
              [
                -35.78945484231824,
                -7.740038197433624
              ],
              [
                -35.81532359247965,
                -7.747138176183948
              ],
              [
                -35.8280174633137,
                -7.75124862449371
              ],
              [
                -35.838470901222045,
                -7.747564679362277
              ],
              [
                -35.841113379196955,
                -7.746633688679568
              ],
              [
                -35.86181869320383,
                -7.750631678964144
              ],
              [
                -35.87163607919816,
                -7.749128186219231
              ],
              [
                -35.8812310100275,
                -7.755715155770414
              ],
              [
                -35.88232047195666,
                -7.765259580656799
              ],
              [
                -35.88273550144907,
                -7.76889802940716
              ],
              [
                -35.883358962668986,
                -7.774365979956506
              ],
              [
                -35.903950762424735,
                -7.791835349555683
              ],
              [
                -35.93241053299194,
                -7.784027962523703
              ],
              [
                -35.93764848885044,
                -7.78681691764682
              ],
              [
                -35.94288642071039,
                -7.789605926773905
              ],
              [
                -35.94728990679247,
                -7.788835912176177
              ],
              [
                -35.95856129318458,
                -7.786865931234231
              ],
              [
                -35.969501202372214,
                -7.789048930478804
              ],
              [
                -35.97395215231894,
                -7.794419909778854
              ],
              [
                -35.98089310661788,
                -7.802794365455979
              ],
              [
                -35.98362907457412,
                -7.802151863586782
              ],
              [
                -35.9923154952409,
                -7.800114365206748
              ],
              [
                -35.99757994017039,
                -7.812905785022669
              ],
              [
                -36.00277789580473,
                -7.812586786510244
              ],
              [
                -36.02111323653805,
                -7.809825821207705
              ],
              [
                -36.03478711489923,
                -7.79564094579403
              ],
              [
                -36.04278704303835,
                -7.793248456663572
              ],
              [
                -36.048011995301614,
                -7.797021419121203
              ],
              [
                -36.053426440761406,
                -7.800931385657693
              ],
              [
                -36.06291185862055,
                -7.788142504072007
              ],
              [
                -36.064305380580954,
                -7.786264015849635
              ],
              [
                -36.0671148553253,
                -7.782475053672474
              ],
              [
                -36.07027881550049,
                -7.780495575596427
              ],
              [
                -36.10207201979235,
                -7.779067624128831
              ],
              [
                -36.10416503135609,
                -7.779963595719205
              ],
              [
                -36.11682739277536,
                -7.785385074995874
              ],
              [
                -36.126374811472154,
                -7.783840600406574
              ],
              [
                -36.14291668043133,
                -7.781163601044054
              ],
              [
                -36.181816311513785,
                -7.783419115269875
              ],
              [
                -36.200501134630194,
                -7.789492096154848
              ],
              [
                -36.2036366141245,
                -7.77843516200371
              ],
              [
                -36.2060351291984,
                -7.769974761993475
              ],
              [
                -36.21623300978946,
                -7.763731794631415
              ],
              [
                -36.25199668488982,
                -7.793546585127237
              ],
              [
                -36.253879657050675,
                -7.801286526123924
              ],
              [
                -36.24425576330347,
                -7.807482977299677
              ],
              [
                -36.24131376414401,
                -7.809376952235474
              ],
              [
                -36.23968529246912,
                -7.817408894247763
              ],
              [
                -36.2500586782438,
                -7.826991354446874
              ],
              [
                -36.263058585616925,
                -7.831329324650008
              ],
              [
                -36.26685405413677,
                -7.830742845168287
              ],
              [
                -36.27064951365743,
                -7.830157330678964
              ],
              [
                -36.27879394693057,
                -7.823625400968749
              ],
              [
                -36.29051284148796,
                -7.814225950401641
              ],
              [
                -36.304138211635376,
                -7.807889016398863
              ],
              [
                -36.30950118134273,
                -7.808358018886396
              ],
              [
                -36.361306704439166,
                -7.805885063271742
              ],
              [
                -36.36724315696896,
                -7.809032571323631
              ],
              [
                -36.373180622490985,
                -7.81218053337849
              ],
              [
                -36.3807500569327,
                -7.810851551608284
              ],
              [
                -36.396000920848465,
                -7.808175089297299
              ],
              [
                -36.40697979354085,
                -7.813681050897179
              ],
              [
                -36.41338223688127,
                -7.814331027801063
              ],
              [
                -36.42361015577794,
                -7.815369539649883
              ],
              [
                -36.428045632516756,
                -7.823910472554182
              ],
              [
                -36.42861408317691,
                -7.866338673588619
              ],
              [
                -36.42861758391725,
                -7.866629148330054
              ],
              [
                -36.42873207080652,
                -7.866742147539889
              ],
              [
                -36.43992448716841,
                -7.877848583851149
              ],
              [
                -36.43348704847131,
                -7.879313066412834
              ],
              [
                -36.43227805104553,
                -7.879588575321907
              ],
              [
                -36.42965204399751,
                -7.885283997940429
              ],
              [
                -36.43437399350566,
                -7.89586093434086
              ],
              [
                -36.44531338545225,
                -7.907774370257551
              ],
              [
                -36.44530339258163,
                -7.915306292671549
              ],
              [
                -36.45289835391284,
                -7.915160316783863
              ],
              [
                -36.45715581898287,
                -7.915078299772643
              ],
              [
                -36.463652253108776,
                -7.914953799854739
              ],
              [
                -36.46612572788936,
                -7.916402816536369
              ],
              [
                -36.47969857948163,
                -7.924352262437079
              ],
              [
                -36.484194570558245,
                -7.919070788031107
              ],
              [
                -36.48767100878066,
                -7.91498781050575
              ],
              [
                -36.493817951821875,
                -7.917666322531458
              ],
              [
                -36.498946417302506,
                -7.91990081320947
              ],
              [
                -36.53588262367222,
                -7.901274448047268
              ],
              [
                -36.54656201805345,
                -7.899264984069071
              ],
              [
                -36.55158099405981,
                -7.898320502358897
              ],
              [
                -36.572142293082344,
                -7.905520441640652
              ],
              [
                -36.57769721826631,
                -7.914727376535591
              ],
              [
                -36.57627623019853,
                -7.920681325189588
              ],
              [
                -36.57269627717078,
                -7.935678729941994
              ],
              [
                -36.578912692090384,
                -7.945307673139223
              ],
              [
                -36.57954269069818,
                -7.94628213707715
              ],
              [
                -36.57906369844463,
                -7.946937148614746
              ],
              [
                -36.57327425672422,
                -7.954857090568903
              ],
              [
                -36.57634672983162,
                -7.95924756794351
              ],
              [
                -36.58981210777922,
                -7.956799095630831
              ],
              [
                -36.61085891846963,
                -7.947749679533895
              ],
              [
                -36.617171369746714,
                -7.959615594525103
              ],
              [
                -36.62222529911809,
                -7.969114488897742
              ],
              [
                -36.62241829351332,
                -7.97396344646175
              ],
              [
                -36.62268330382415,
                -7.980607922169175
              ],
              [
                -36.624271796215844,
                -7.982423398364361
              ],
              [
                -36.62740776340009,
                -7.986009404073599
              ],
              [
                -36.627411688438606,
                -8.055352842989345
              ],
              [
                -36.64837550409421,
                -8.067159783500975
              ],
              [
                -36.65635441904538,
                -8.075744212526557
              ],
              [
                -36.657022912937585,
                -8.079622188069864
              ],
              [
                -36.64993299104049,
                -8.080918186318392
              ],
              [
                -36.646796502899804,
                -8.092078599459674
              ],
              [
                -36.64249953091791,
                -8.092403085474865
              ],
              [
                -36.63653959765246,
                -8.092853089174568
              ],
              [
                -36.628155173671104,
                -8.102293992366445
              ],
              [
                -36.62816965473556,
                -8.110640923820894
              ],
              [
                -36.638383067407375,
                -8.118380867250067
              ],
              [
                -36.66597782231286,
                -8.123582843438747
              ],
              [
                -36.69309854041956,
                -8.141089228249315
              ],
              [
                -36.689862599437234,
                -8.148155676037494
              ],
              [
                -36.68662709844829,
                -8.155221614832037
              ],
              [
                -36.706935912060665,
                -8.155868652411398
              ],
              [
                -36.70794291706735,
                -8.15590113098279
              ],
              [
                -36.72238578386899,
                -8.150559682006863
              ],
              [
                -36.72534125104364,
                -8.156048643052241
              ],
              [
                -36.719985820926084,
                -8.16187560670326
              ],
              [
                -36.71535084004041,
                -8.166918559993
              ],
              [
                -36.72196628531459,
                -8.167921569662584
              ],
              [
                -36.724136744648774,
                -8.171977020143734
              ],
              [
                -36.73511565960157,
                -8.17235202223692
              ],
              [
                -36.74217461256752,
                -8.172593548153204
              ],
              [
                -36.74972001517363,
                -8.175301535444248
              ],
              [
                -36.751502992370646,
                -8.183698451142778
              ],
              [
                -36.75307050731705,
                -8.19108039850288
              ],
              [
                -36.761146414462765,
                -8.195888861060718
              ],
              [
                -36.77442479167652,
                -8.190599404746228
              ],
              [
                -36.775150294459976,
                -8.1903104405697
              ],
              [
                -36.78829167264118,
                -8.190181931355625
              ],
              [
                -36.80357406554019,
                -8.19398290262488
              ],
              [
                -36.810422499332944,
                -8.202776356509403
              ],
              [
                -36.812070468918236,
                -8.204893359558016
              ],
              [
                -36.86127201116716,
                -8.222011735341725
              ],
              [
                -36.87880735726968,
                -8.235230651134938
              ],
              [
                -36.88716879178882,
                -8.237821161134235
              ],
              [
                -36.89553071530897,
                -8.240411648140872
              ],
              [
                -36.9078600756876,
                -8.253632525716668
              ],
              [
                -36.91521803568914,
                -8.261532982103674
              ],
              [
                -36.92257593468798,
                -8.269432941515257
              ],
              [
                -36.928100396461716,
                -8.270794906650188
              ],
              [
                -36.94452824212103,
                -8.27484540022016
              ],
              [
                -36.947307214178146,
                -8.278722362759247
              ],
              [
                -36.947577199670896,
                -8.284798322321475
              ],
              [
                -36.94788770001637,
                -8.291804795777503
              ],
              [
                -36.95158016371355,
                -8.295964736922045
              ],
              [
                -36.9570011130716,
                -8.298920735760023
              ],
              [
                -36.976795932813424,
                -8.297091245358205
              ],
              [
                -36.97743145321926,
                -8.297032773338604
              ],
              [
                -36.98408589680235,
                -8.299792250728759
              ],
              [
                -36.990740302388595,
                -8.30255173512446
              ],
              [
                -36.99396928894842,
                -8.301095764999552
              ],
              [
                -37.02021256497007,
                -8.289262343752805
              ],
              [
                -37.045305855860924,
                -8.261276092435992
              ],
              [
                -37.08785648566275,
                -8.255057168652785
              ],
              [
                -37.09173195958604,
                -8.247577228254503
              ],
              [
                -37.095607961502495,
                -8.24009626686624
              ],
              [
                -37.10578784823389,
                -8.228196382625597
              ],
              [
                -37.12250174144583,
                -8.208659559399527
              ],
              [
                -37.12364273258567,
                -8.20357758433644
              ],
              [
                -37.124784732714225,
                -8.198495616276238
              ],
              [
                -37.12476420407678,
                -8.198279617916567
              ],
              [
                -37.12246274672821,
                -8.174014807231458
              ],
              [
                -37.126025235762874,
                -8.173286832766891
              ],
              [
                -37.13070319636765,
                -8.174413828987241
              ],
              [
                -37.14076908414978,
                -8.176838310712577
              ],
              [
                -37.159801461225925,
                -8.170006843890745
              ],
              [
                -37.16318992907819,
                -8.165365897305495
              ],
              [
                -37.16299492569707,
                -8.15829346741605
              ],
              [
                -37.15865895361977,
                -8.155273962006031
              ],
              [
                -37.15057904987877,
                -8.149646992524318
              ],
              [
                -37.154544006590356,
                -8.143828066466629
              ],
              [
                -37.158190468734105,
                -8.13847659255766
              ],
              [
                -37.15280502490229,
                -8.127073179678217
              ],
              [
                -37.15004206415952,
                -8.126130669641675
              ],
              [
                -37.1435921281235,
                -8.123929717237164
              ],
              [
                -37.146788079634675,
                -8.10360035202716
              ],
              [
                -37.14740159342258,
                -8.099700372494315
              ],
              [
                -37.14534911061345,
                -8.093634409420437
              ],
              [
                -37.14329665180059,
                -8.08756798335745
              ],
              [
                -37.1527955726279,
                -8.07061660641648
              ],
              [
                -37.15642452581497,
                -8.064141165157078
              ],
              [
                -37.15704453779817,
                -8.057483222845025
              ],
              [
                -37.157665028771454,
                -8.050826760526878
              ],
              [
                -37.147989658680274,
                -8.034711867566921
              ],
              [
                -37.149147115741705,
                -8.023336971006165
              ],
              [
                -37.15086011648012,
                -8.012439546236564
              ],
              [
                -37.15140562248224,
                -8.008971099366471
              ],
              [
                -37.15851108080651,
                -7.998867156876057
              ],
              [
                -37.161369550237715,
                -7.996730681632914
              ],
              [
                -37.167240505475306,
                -7.996459186479842
              ],
              [
                -37.17573042762814,
                -7.996067203377123
              ],
              [
                -37.17888689810544,
                -7.990645726647133
              ],
              [
                -37.17956939401857,
                -7.989474242213507
              ],
              [
                -37.181104862373026,
                -7.989538742961031
              ],
              [
                -37.184329336718704,
                -7.989673733534587
              ],
              [
                -37.18785581429577,
                -7.982565316081817
              ],
              [
                -37.19297079610558,
                -7.982461329022724
              ],
              [
                -37.192267298445934,
                -7.960196959781332
              ],
              [
                -37.20742267341955,
                -7.951457560274505
              ],
              [
                -37.211321144689514,
                -7.954207538257096
              ],
              [
                -37.218436569415566,
                -7.958286494615442
              ],
              [
                -37.22032407949279,
                -7.961019980109025
              ],
              [
                -37.22005157370293,
                -7.963726463066465
              ],
              [
                -37.21919205436435,
                -7.97225891973251
              ],
              [
                -37.228368977228094,
                -7.97218590672033
              ],
              [
                -37.230340979368755,
                -7.969008436755654
              ],
              [
                -37.23693641884416,
                -7.972908399098609
              ],
              [
                -37.24655782095293,
                -7.968956432278455
              ],
              [
                -37.25283224481631,
                -7.968512964767294
              ],
              [
                -37.26312167040651,
                -7.967785960686923
              ],
              [
                -37.267325136298595,
                -7.968932987271993
              ],
              [
                -37.27698754570681,
                -7.971568926835974
              ],
              [
                -37.28582650534633,
                -7.968143972322963
              ],
              [
                -37.29515688480402,
                -7.964529524661341
              ],
              [
                -37.29961588291844,
                -7.967555989014813
              ],
              [
                -37.30292881979793,
                -7.969805497413633
              ],
              [
                -37.31657171495818,
                -7.967661504947714
              ],
              [
                -37.33937351187047,
                -7.979396443314846
              ],
              [
                -37.34110199589016,
                -7.974144459051511
              ],
              [
                -37.35546085971691,
                -7.974524977790276
              ],
              [
                -37.350451895224566,
                -7.961967094161388
              ],
              [
                -37.34725695698276,
                -7.953957635093294
              ],
              [
                -37.34900945842033,
                -7.947918669903979
              ],
              [
                -37.34171000703889,
                -7.945303711087406
              ],
              [
                -37.33123661360205,
                -7.944046208278777
              ],
              [
                -37.317357234608004,
                -7.942380198862176
              ],
              [
                -37.31428926829301,
                -7.935525766072686
              ],
              [
                -37.30856381130776,
                -7.933786274823464
              ],
              [
                -37.30764632719733,
                -7.926664812838316
              ],
              [
                -37.31084728710931,
                -7.921483847257406
              ],
              [
                -37.310985297071205,
                -7.921259868090966
              ],
              [
                -37.30918080631411,
                -7.913491925374272
              ],
              [
                -37.32162871226276,
                -7.911080945937472
              ],
              [
                -37.30758884019528,
                -7.907548964801694
              ],
              [
                -37.303233877743686,
                -7.903767971383173
              ],
              [
                -37.28698750657505,
                -7.903484958501372
              ],
              [
                -37.25734329739517,
                -7.882754109286113
              ],
              [
                -37.24425341331337,
                -7.87385516134209
              ],
              [
                -37.24449943163152,
                -7.862043777523195
              ],
              [
                -37.24451594013726,
                -7.861232256788268
              ],
              [
                -37.24995088547222,
                -7.853264350512344
              ],
              [
                -37.2467449013236,
                -7.846403900815895
              ],
              [
                -37.22167262371847,
                -7.841840889013062
              ],
              [
                -37.227601104904195,
                -7.821051570007258
              ],
              [
                -37.227722076821514,
                -7.821067543980153
              ],
              [
                -37.24005197248718,
                -7.822687545283238
              ],
              [
                -37.24324297553952,
                -7.81859407637623
              ],
              [
                -37.22975607150911,
                -7.812702136101914
              ],
              [
                -37.230152087072206,
                -7.806349183410037
              ],
              [
                -37.22258766311086,
                -7.803664690121225
              ],
              [
                -37.217010691347774,
                -7.806287675470012
              ],
              [
                -37.19753937976615,
                -7.798528203919446
              ],
              [
                -37.191129942642746,
                -7.790764753765977
              ],
              [
                -37.18260550540778,
                -7.790332768364961
              ],
              [
                -37.17836655732547,
                -7.793593239847137
              ],
              [
                -37.17746404976043,
                -7.794286738780612
              ],
              [
                -37.175317070446404,
                -7.79593821533553
              ],
              [
                -37.170258110123385,
                -7.799827197315869
              ],
              [
                -37.17095258998771,
                -7.794781720813723
              ],
              [
                -37.16035620913068,
                -7.793062730713707
              ],
              [
                -37.15223528296279,
                -7.780514337208369
              ],
              [
                -37.15154379193045,
                -7.779445337919807
              ],
              [
                -37.156356235694616,
                -7.776469344698578
              ],
              [
                -37.15662024241553,
                -7.772609390720396
              ],
              [
                -37.14621987297332,
                -7.756797982690852
              ],
              [
                -37.138537426193665,
                -7.74511757792819
              ],
              [
                -37.14715787251031,
                -7.732914163016628
              ],
              [
                -37.148690364654605,
                -7.730745681979974
              ],
              [
                -37.15129681670602,
                -7.712792847817358
              ],
              [
                -37.16062927307439,
                -7.697563460870392
              ],
              [
                -37.167230213102314,
                -7.690631494626226
              ],
              [
                -37.17243117393445,
                -7.684443544535116
              ],
              [
                -37.17533363995999,
                -7.680990581500736
              ],
              [
                -37.18145011538959,
                -7.664956719275671
              ],
              [
                -37.18808804188473,
                -7.658285269040358
              ],
              [
                -37.19472801636418,
                -7.651612833811908
              ],
              [
                -37.19634348875477,
                -7.631486502779198
              ],
              [
                -37.19510101250534,
                -7.627934016307804
              ],
              [
                -37.191603552413,
                -7.624103521246332
              ],
              [
                -37.18639359891997,
                -7.618399566367683
              ],
              [
                -37.181420631835984,
                -7.605592177680357
              ],
              [
                -37.1799601506679,
                -7.601829689687673
              ],
              [
                -37.17381474481861,
                -7.591755280973785
              ],
              [
                -37.17377023326839,
                -7.591682764501209
              ],
              [
                -37.167535799309874,
                -7.581462363876319
              ],
              [
                -37.15534741290141,
                -7.567490954779574
              ],
              [
                -37.133306118627075,
                -7.549653570183787
              ],
              [
                -37.13297510484626,
                -7.549275560862048
              ],
              [
                -37.1211062422748,
                -7.535713174989724
              ],
              [
                -37.10568738153863,
                -7.530740695810461
              ],
              [
                -37.085838547071795,
                -7.517870288593887
              ],
              [
                -37.07686562294714,
                -7.517294771238417
              ],
              [
                -37.067893203825925,
                -7.51671928688577
              ],
              [
                -37.05340285270887,
                -7.504162875409127
              ],
              [
                -37.02725457061085,
                -7.497178384861616
              ],
              [
                -37.02513509281524,
                -7.496612906651606
              ],
              [
                -37.01013921231691,
                -7.489168442174298
              ],
              [
                -37.009064740073974,
                -7.490172426555888
              ],
              [
                -37.002840788801045,
                -7.495987901633308
              ],
              [
                -36.98382397013701,
                -7.481723977184359
              ],
              [
                -36.98643696024022,
                -7.474782065155534
              ],
              [
                -36.9932643913002,
                -7.472722553325807
              ],
              [
                -36.99502386611154,
                -7.472192053779323
              ],
              [
                -36.994052403928116,
                -7.466631134304927
              ],
              [
                -36.993648889253166,
                -7.464318146994796
              ],
              [
                -36.996887871574245,
                -7.459001169799459
              ],
              [
                -37.001369305324026,
                -7.457075679153457
              ],
              [
                -37.01030723504288,
                -7.45323473276216
              ],
              [
                -37.01275821785964,
                -7.444587782877484
              ],
              [
                -37.02319613079521,
                -7.433460893291774
              ],
              [
                -37.0159397381573,
                -7.430687892421807
              ],
              [
                -37.01408821909058,
                -7.429980412537885
              ],
              [
                -37.016173725597035,
                -7.426023420896113
              ],
              [
                -37.017143204394394,
                -7.424183937938601
              ],
              [
                -37.025549132538195,
                -7.422063970739192
              ],
              [
                -37.01700120966286,
                -7.409538547822073
              ],
              [
                -37.01349526957271,
                -7.40440161518919
              ],
              [
                -37.017536239919174,
                -7.398885663153838
              ],
              [
                -37.02172270912517,
                -7.393171209772374
              ],
              [
                -37.025849155800294,
                -7.387539258703455
              ],
              [
                -37.04941745781255,
                -7.377254839356805
              ],
              [
                -37.05251740867267,
                -7.378184346432715
              ],
              [
                -37.05482091787396,
                -7.378726821928126
              ],
              [
                -37.06745931376774,
                -7.381703821188365
              ],
              [
                -37.07217526763806,
                -7.368862904650796
              ],
              [
                -37.0725347821886,
                -7.367883413542975
              ],
              [
                -37.09168960616465,
                -7.356525042221348
              ],
              [
                -37.12102083713233,
                -7.350552599537921
              ],
              [
                -37.1234363396792,
                -7.347991606263798
              ],
              [
                -37.12755878148087,
                -7.343619163355013
              ],
              [
                -37.131060766566314,
                -7.344880142148596
              ],
              [
                -37.13489724160439,
                -7.346261656254602
              ],
              [
                -37.1409411855879,
                -7.338982675385951
              ],
              [
                -37.14452916408279,
                -7.334661727674173
              ],
              [
                -37.14707263609881,
                -7.335322721421987
              ],
              [
                -37.15665054555223,
                -7.337813232168901
              ],
              [
                -37.16492699258624,
                -7.336195717908329
              ],
              [
                -37.16757547846006,
                -7.322398821208204
              ],
              [
                -37.16810743782098,
                -7.319628367158019
              ],
              [
                -37.176537412088756,
                -7.309167927800929
              ],
              [
                -37.19823771065221,
                -7.29390209465734
              ],
              [
                -37.20599564321601,
                -7.292402591067043
              ],
              [
                -37.213754578777696,
                -7.290902622480253
              ],
              [
                -37.232530437913795,
                -7.274732256165594
              ],
              [
                -37.26066318964748,
                -7.273142774325099
              ],
              [
                -37.27561155551873,
                -7.274693277324542
              ],
              [
                -37.28397744900807,
                -7.283658197822977
              ],
              [
                -37.28775192668252,
                -7.287702673183552
              ],
              [
                -37.31357121792375,
                -7.288885178144956
              ],
              [
                -37.33122506183453,
                -7.28931068194249
              ],
              [
                -37.35607233143342,
                -7.321724986756615
              ],
              [
                -37.35260832444075,
                -7.329134410667053
              ],
              [
                -37.34912734756648,
                -7.336580828279734
              ],
              [
                -37.367035699265415,
                -7.346344793925879
              ],
              [
                -37.385301531681115,
                -7.356303700382423
              ],
              [
                -37.386004044452825,
                -7.356347736568073
              ],
              [
                -37.40772384192002,
                -7.357702244369309
              ],
              [
                -37.41507377639604,
                -7.361320710843973
              ],
              [
                -37.42656717904941,
                -7.353402279844933
              ],
              [
                -37.4314886536326,
                -7.356107741574577
              ],
              [
                -37.44124553121745,
                -7.359322756994588
              ],
              [
                -37.4657408286028,
                -7.357693262034042
              ],
              [
                -37.49652206832094,
                -7.367137230077568
              ],
              [
                -37.4918145873447,
                -7.382250099546354
              ],
              [
                -37.49083758917382,
                -7.384594580663226
              ],
              [
                -37.48662211763684,
                -7.39470800120868
              ],
              [
                -37.49658203255804,
                -7.403065458078103
              ],
              [
                -37.50528744492958,
                -7.418775311159035
              ],
              [
                -37.50999141052599,
                -7.420054295838828
              ],
              [
                -37.5102704140112,
                -7.42012982046661
              ],
              [
                -37.51523685290399,
                -7.427171264792173
              ],
              [
                -37.51472036786634,
                -7.429250256325721
              ],
              [
                -37.51385584882477,
                -7.432729220771463
              ],
              [
                -37.516094346128504,
                -7.435325204405181
              ],
              [
                -37.51999179135094,
                -7.439846655420586
              ],
              [
                -37.52181328369428,
                -7.441960645467946
              ],
              [
                -37.52691923120001,
                -7.447884099579333
              ],
              [
                -37.52703072094687,
                -7.450851603732456
              ],
              [
                -37.52755571060795,
                -7.464834984089715
              ],
              [
                -37.5327536868753,
                -7.47259941608436
              ],
              [
                -37.55076802952252,
                -7.478764877259398
              ],
              [
                -37.571419348077335,
                -7.4887033193695
              ],
              [
                -37.590190665983705,
                -7.500219745923098
              ],
              [
                -37.603640547614,
                -7.505415729190205
              ],
              [
                -37.609367481512756,
                -7.507628719532232
              ],
              [
                -37.6101639745536,
                -7.512736701755425
              ],
              [
                -37.61102097275843,
                -7.518233139034253
              ],
              [
                -37.616508405716274,
                -7.523088587833323
              ],
              [
                -37.619441890895374,
                -7.525684080089567
              ],
              [
                -37.6299807801872,
                -7.532120024625684
              ],
              [
                -37.649465130462495,
                -7.529984072148298
              ],
              [
                -37.654386065155144,
                -7.526157611435144
              ],
              [
                -37.65930604885788,
                -7.522331135719849
              ],
              [
                -37.676240877939485,
                -7.535220544527212
              ],
              [
                -37.678995851751345,
                -7.541604514498053
              ],
              [
                -37.68175180754953,
                -7.5479894464711
              ],
              [
                -37.70611359967363,
                -7.550194965398486
              ],
              [
                -37.70969006926517,
                -7.559132902391591
              ],
              [
                -37.707391559781136,
                -7.570251315052364
              ],
              [
                -37.720638982543626,
                -7.581500728837451
              ],
              [
                -37.7283389025693,
                -7.579338243473693
              ],
              [
                -37.734606837219516,
                -7.596259133257981
              ],
              [
                -37.73550681421526,
                -7.596381127023596
              ],
              [
                -37.74104576095395,
                -7.597132614576886
              ],
              [
                -37.745294717343185,
                -7.608933535198253
              ],
              [
                -37.75545314306915,
                -7.619119455891735
              ],
              [
                -37.7559026206834,
                -7.622210938493648
              ],
              [
                -37.757317098617484,
                -7.631893378226514
              ],
              [
                -37.75758360583589,
                -7.633717364426082
              ],
              [
                -37.74782469401025,
                -7.635706826474527
              ],
              [
                -37.745908700537285,
                -7.636097824965109
              ],
              [
                -37.73815126060847,
                -7.643816757571895
              ],
              [
                -37.73835576315969,
                -7.659807657915471
              ],
              [
                -37.75166814194388,
                -7.66388463010848
              ],
              [
                -37.75429809829859,
                -7.669604094275513
              ],
              [
                -37.76473900364548,
                -7.659410151770612
              ],
              [
                -37.76746347881751,
                -7.656750189339236
              ],
              [
                -37.76769148651895,
                -7.648283273522612
              ],
              [
                -37.77302394975379,
                -7.646919265195177
              ],
              [
                -37.7757174366385,
                -7.646230275606309
              ],
              [
                -37.775860934870074,
                -7.63928682302948
              ],
              [
                -37.77596442987985,
                -7.634328370184469
              ],
              [
                -37.779298905977384,
                -7.630040886729347
              ],
              [
                -37.782008914925974,
                -7.62655592562048
              ],
              [
                -37.78206241051015,
                -7.626487430188522
              ],
              [
                -37.792298287773775,
                -7.627549416078135
              ],
              [
                -37.80756215106666,
                -7.643570327111179
              ],
              [
                -37.843551362651894,
                -7.646681322620593
              ],
              [
                -37.85082277723583,
                -7.649954809253027
              ],
              [
                -37.8580931958319,
                -7.653228290892021
              ],
              [
                -37.88019547798831,
                -7.683264074168795
              ],
              [
                -37.89696383615706,
                -7.690428043603858
              ],
              [
                -37.90289278037706,
                -7.697648987015876
              ],
              [
                -37.90058580498799,
                -7.702183916453552
              ],
              [
                -37.89882181668886,
                -7.70565089650157
              ],
              [
                -37.891905393454365,
                -7.710539372561222
              ],
              [
                -37.89392685456003,
                -7.71323585752483
              ],
              [
                -37.90873273530087,
                -7.709957369424042
              ],
              [
                -37.91266518250492,
                -7.709086406225321
              ],
              [
                -37.92015961672135,
                -7.712893886054267
              ],
              [
                -37.925030582653505,
                -7.725792784226745
              ],
              [
                -37.926423559473996,
                -7.729481753113208
              ],
              [
                -37.93456598958408,
                -7.73505772196482
              ],
              [
                -37.94270892569175,
                -7.740633179835335
              ],
              [
                -37.94713686562335,
                -7.755825574207905
              ],
              [
                -37.95795527683921,
                -7.765644484832792
              ],
              [
                -37.95805824486484,
                -7.765733010238909
              ],
              [
                -37.97100662535078,
                -7.776854943658838
              ],
              [
                -37.982441555796704,
                -7.772301984649331
              ],
              [
                -37.98257703365248,
                -7.772248468167267
              ],
              [
                -37.9827310301963,
                -7.772379968286526
              ],
              [
                -37.98987546163092,
                -7.77848793937846
              ],
              [
                -37.99528292920891,
                -7.778523413463965
              ],
              [
                -38.00034889078474,
                -7.778556437293681
              ],
              [
                -38.004081349837634,
                -7.777615926484257
              ],
              [
                -38.00775131869432,
                -7.766478512503975
              ],
              [
                -38.01256329076369,
                -7.762481047909129
              ],
              [
                -38.022410736099474,
                -7.754299637319074
              ],
              [
                -38.03077263149515,
                -7.751021148080617
              ],
              [
                -38.03913356890648,
                -7.747742691838345
              ],
              [
                -38.05429195210576,
                -7.76016112520414
              ],
              [
                -38.069105299554636,
                -7.772297510507166
              ],
              [
                -38.069459298656525,
                -7.773282004279949
              ],
              [
                -38.07501274349348,
                -7.788722896954408
              ],
              [
                -38.07534222936719,
                -7.804996783093108
              ],
              [
                -38.072542729729385,
                -7.808865259322728
              ],
              [
                -38.066088287978545,
                -7.817786195058866
              ],
              [
                -38.067512263312,
                -7.821703172345849
              ],
              [
                -38.07673369365003,
                -7.830162594340197
              ],
              [
                -38.117280352352026,
                -7.820779700802038
              ],
              [
                -38.1184993286025,
                -7.819654713364979
              ],
              [
                -38.11965682134499,
                -7.81858622144734
              ],
              [
                -38.1237482844407,
                -7.812582271521767
              ],
              [
                -38.122047796843674,
                -7.807041811364808
              ],
              [
                -38.13075675854412,
                -7.803470825643902
              ],
              [
                -38.13105223757045,
                -7.795358908707266
              ],
              [
                -38.133164234816164,
                -7.793239903569069
              ],
              [
                -38.137307677946836,
                -7.789082958608911
              ],
              [
                -38.14151414257811,
                -7.784862990179031
              ],
              [
                -38.16363646638136,
                -7.77954805758985
              ],
              [
                -38.17039640411804,
                -7.785860987960201
              ],
              [
                -38.17428786012311,
                -7.789495456423971
              ],
              [
                -38.17613735966649,
                -7.788613993638092
              ],
              [
                -38.17706635790301,
                -7.788170968765992
              ],
              [
                -38.18681374570618,
                -7.7954769410204
              ],
              [
                -38.19383217604197,
                -7.800737892653409
              ],
              [
                -38.19986263175982,
                -7.803865399738651
              ],
              [
                -38.19986812470853,
                -7.803869390712697
              ],
              [
                -38.20589359347358,
                -7.806994876815178
              ],
              [
                -38.21311550363538,
                -7.812743346932385
              ],
              [
                -38.221811427951216,
                -7.819665299334458
              ],
              [
                -38.22666587775204,
                -7.821820775888749
              ],
              [
                -38.23507383420202,
                -7.825554250339185
              ],
              [
                -38.235132808612384,
                -7.825644728698984
              ],
              [
                -38.238182799106475,
                -7.830336725496127
              ],
              [
                -38.25665112644023,
                -7.830670722031258
              ],
              [
                -38.26077459273178,
                -7.829061746632905
              ],
              [
                -38.264546547983834,
                -7.827589752905319
              ],
              [
                -38.2695835258043,
                -7.830235722899425
              ],
              [
                -38.27588946299285,
                -7.833548225867533
              ],
              [
                -38.28640136822932,
                -7.829822235780722
              ],
              [
                -38.297350274963755,
                -7.813543385467819
              ],
              [
                -38.302182265668975,
                -7.788014059519947
              ],
              [
                -38.298199314716015,
                -7.782792598995488
              ],
              [
                -38.2942163437611,
                -7.777571151479001
              ],
              [
                -38.30085579033057,
                -7.768317715261231
              ],
              [
                -38.29942931327316,
                -7.762812252991242
              ],
              [
                -38.30409375022548,
                -7.763203750792698
              ],
              [
                -38.31633716949058,
                -7.764233284885783
              ],
              [
                -38.30746824755496,
                -7.752404346693615
              ],
              [
                -38.301774812440186,
                -7.744810912872581
              ],
              [
                -38.31027474944594,
                -7.738388962617623
              ],
              [
                -38.31548067136693,
                -7.728079049289686
              ],
              [
                -38.31164622168997,
                -7.715926611705455
              ],
              [
                -38.316859704826065,
                -7.715127633988794
              ],
              [
                -38.315812189133574,
                -7.708748682709795
              ],
              [
                -38.32038268628014,
                -7.697566267532165
              ],
              [
                -38.326288627163265,
                -7.693319312618622
              ],
              [
                -38.3299760780129,
                -7.695755280034641
              ],
              [
                -38.336850542246715,
                -7.692998315552006
              ],
              [
                -38.344303478919244,
                -7.694596298378734
              ],
              [
                -38.35709887966186,
                -7.676780974287052
              ],
              [
                -38.36241832770518,
                -7.687373880920486
              ],
              [
                -38.36882325401899,
                -7.690854846576446
              ],
              [
                -38.37055175415046,
                -7.698015297476602
              ],
              [
                -38.37066423709604,
                -7.698105799878456
              ],
              [
                -38.38186065015787,
                -7.707091238535546
              ],
              [
                -38.388543083133754,
                -7.709239225585475
              ],
              [
                -38.40353695146555,
                -7.714059727025427
              ],
              [
                -38.400981454005766,
                -7.718672660885761
              ],
              [
                -38.40043447597422,
                -7.719659680938968
              ],
              [
                -38.40924587766218,
                -7.730088593778292
              ],
              [
                -38.427236226175495,
                -7.729636118777685
              ],
              [
                -38.43394767547242,
                -7.720040177134663
              ],
              [
                -38.44330160471779,
                -7.719082206979264
              ],
              [
                -38.45663048766244,
                -7.717717701127985
              ],
              [
                -38.4618109437728,
                -7.727238140993495
              ],
              [
                -38.46358990760793,
                -7.730508133596135
              ],
              [
                -38.47191185013106,
                -7.731689611367507
              ],
              [
                -38.4840412590498,
                -7.733410609133591
              ],
              [
                -38.495344637529335,
                -7.738378590587538
              ],
              [
                -38.495579127941454,
                -7.741497080105196
              ],
              [
                -38.49619213494471,
                -7.749647019742532
              ],
              [
                -38.51620845918992,
                -7.748929509464197
              ],
              [
                -38.529316809306664,
                -7.754714499242142
              ],
              [
                -38.537527768789325,
                -7.751077044518519
              ],
              [
                -38.54292772373278,
                -7.748684545061185
              ],
              [
                -38.55072965145036,
                -7.751570011527965
              ],
              [
                -38.56187803902957,
                -7.755693511341369
              ],
              [
                -38.56919498715696,
                -7.752260026336384
              ],
              [
                -38.57170396228517,
                -7.751082540308001
              ],
              [
                -38.57296944219132,
                -7.752401526339048
              ],
              [
                -38.574440452133814,
                -7.753934018919663
              ],
              [
                -38.57504245408712,
                -7.752478547444378
              ],
              [
                -38.57762690371573,
                -7.746226100952361
              ],
              [
                -38.58133386932552,
                -7.750050543977641
              ],
              [
                -38.58566484697846,
                -7.754517032650139
              ],
              [
                -38.5932307806096,
                -7.753950563112842
              ],
              [
                -38.59735023984571,
                -7.741647125725582
              ],
              [
                -38.60718017742736,
                -7.739083159163654
              ],
              [
                -38.60745766861394,
                -7.729790215831059
              ],
              [
                -38.60758415796681,
                -7.725564274970537
              ],
              [
                -38.61574810145585,
                -7.713926359834764
              ],
              [
                -38.617945079517774,
                -7.712491869493951
              ],
              [
                -38.62493254446958,
                -7.707928399762769
              ],
              [
                -38.62545953607442,
                -7.701562958452996
              ],
              [
                -38.62605703076439,
                -7.694334523749026
              ],
              [
                -38.636218465969236,
                -7.688725065513411
              ],
              [
                -38.64095342992594,
                -7.686111578163469
              ],
              [
                -38.6412379171971,
                -7.682736136989378
              ],
              [
                -38.641700892628776,
                -7.677251647955155
              ],
              [
                -38.646083388743754,
                -7.676985649515276
              ],
              [
                -38.65101582710807,
                -7.676685158781757
              ],
              [
                -38.65072984647116,
                -7.67314321340956
              ],
              [
                -38.65032885999362,
                -7.668170731795378
              ],
              [
                -38.66320524901446,
                -7.657140830840173
              ],
              [
                -38.66761170306045,
                -7.659183788902425
              ],
              [
                -38.66956070538866,
                -7.660087822619969
              ],
              [
                -38.67238366021804,
                -7.658407320641611
              ],
              [
                -38.67961859431549,
                -7.646104415773671
              ],
              [
                -38.68635256306891,
                -7.634654009038357
              ],
              [
                -38.69097453982021,
                -7.633530520279153
              ],
              [
                -38.69559650657387,
                -7.632407016519544
              ],
              [
                -38.70502640434021,
                -7.621661130600994
              ],
              [
                -38.71471681828535,
                -7.621519625464638
              ],
              [
                -38.7179168326326,
                -7.608956707310417
              ],
              [
                -38.7126408722629,
                -7.6079537106831
              ],
              [
                -38.71094486608498,
                -7.5929398272211
              ],
              [
                -38.710720401634056,
                -7.590951369128403
              ],
              [
                -38.71064289209921,
                -7.586235370850368
              ],
              [
                -38.702252448514095,
                -7.580583920027266
              ],
              [
                -38.688532582546316,
                -7.580765415682514
              ],
              [
                -38.68567759631825,
                -7.58080339211245
              ],
              [
                -38.684624102337835,
                -7.579722935472899
              ],
              [
                -38.67941215690603,
                -7.574378471886535
              ],
              [
                -38.674883726974116,
                -7.569732977547465
              ],
              [
                -38.66860526668365,
                -7.568288476509331
              ],
              [
                -38.65494389291577,
                -7.565144528498492
              ],
              [
                -38.65874734380163,
                -7.560222031923442
              ],
              [
                -38.653190397932,
                -7.554169570482972
              ],
              [
                -38.65463140271322,
                -7.550034114051962
              ],
              [
                -38.65610789126689,
                -7.545795645432953
              ],
              [
                -38.65319141563721,
                -7.540750693453949
              ],
              [
                -38.65242943030992,
                -7.539433202861605
              ],
              [
                -38.65110893436966,
                -7.52740579423326
              ],
              [
                -38.651118452300494,
                -7.527388782370129
              ],
              [
                -38.65427543429707,
                -7.521822344188275
              ],
              [
                -38.649845442807845,
                -7.51928986793204
              ],
              [
                -38.64609247712788,
                -7.517144854269247
              ],
              [
                -38.64727199186306,
                -7.513388391766087
              ],
              [
                -38.648011984304546,
                -7.511031902270468
              ],
              [
                -38.645611994706144,
                -7.501526975656915
              ],
              [
                -38.63847308433912,
                -7.493211525271717
              ],
              [
                -38.631334638963594,
                -7.484896079909025
              ],
              [
                -38.641309565335916,
                -7.464570259439068
              ],
              [
                -38.64364403771898,
                -7.463722249728131
              ],
              [
                -38.645791526674145,
                -7.462941774356064
              ],
              [
                -38.644537528191826,
                -7.459619294657087
              ],
              [
                -38.63660111552297,
                -7.459157273939151
              ],
              [
                -38.60294588760318,
                -7.457195795452789
              ],
              [
                -38.605213882271755,
                -7.44526986606246
              ],
              [
                -38.605421388328026,
                -7.444175871557976
              ],
              [
                -38.59598998684975,
                -7.438414928060487
              ],
              [
                -38.59569096659287,
                -7.438232436216923
              ],
              [
                -38.5946349904577,
                -7.433540476139767
              ],
              [
                -38.58491857625043,
                -7.434418937848482
              ],
              [
                -38.58412708160543,
                -7.415953565966836
              ],
              [
                -38.590435029563245,
                -7.414927104710936
              ],
              [
                -38.59517051499527,
                -7.414157090273481
              ],
              [
                -38.59000156539065,
                -7.400785701176368
              ],
              [
                -38.583650109789964,
                -7.397998223486981
              ],
              [
                -38.58321811669401,
                -7.397808735595726
              ],
              [
                -38.58243313828192,
                -7.395550746203238
              ],
              [
                -38.57570121191408,
                -7.376179368723579
              ],
              [
                -38.5682507628195,
                -7.367023940802418
              ],
              [
                -38.56079935172734,
                -7.357868524906316
              ],
              [
                -38.54929544396242,
                -7.33438819528978
              ],
              [
                -38.54950896478854,
                -7.328329232727453
              ],
              [
                -38.54959293891247,
                -7.325928748126215
              ],
              [
                -38.54997496097133,
                -7.315068805373524
              ],
              [
                -38.54011453158404,
                -7.306172394769645
              ],
              [
                -38.53972604123281,
                -7.305821878149871
              ],
              [
                -38.536965593023396,
                -7.299609426507016
              ],
              [
                -38.534204590814774,
                -7.293396985872302
              ],
              [
                -38.542248563676594,
                -7.277997611743211
              ],
              [
                -38.541827082636544,
                -7.267175167162176
              ],
              [
                -38.545631013386185,
                -7.264785200339974
              ],
              [
                -38.54572703057115,
                -7.264759184612195
              ],
              [
                -38.55171898568615,
                -7.263165702369443
              ],
              [
                -38.553391966541696,
                -7.254797791626264
              ],
              [
                -38.55406345727225,
                -7.251437319834158
              ],
              [
                -38.551433017735455,
                -7.24280837581965
              ],
              [
                -38.55729945863511,
                -7.238999386415554
              ],
              [
                -38.5788697682876,
                -7.246631847481165
              ],
              [
                -38.58270022595706,
                -7.247986835042744
              ],
              [
                -38.594731619805614,
                -7.24613388837815
              ],
              [
                -38.59774758149825,
                -7.239718925708509
              ],
              [
                -38.600261573735324,
                -7.234371484497907
              ],
              [
                -38.60322005903115,
                -7.235675945779266
              ],
              [
                -38.60504404256741,
                -7.236480438019138
              ],
              [
                -38.614987452279344,
                -7.229790006725221
              ],
              [
                -38.61626744202138,
                -7.226038053376183
              ],
              [
                -38.61825542619401,
                -7.220210079435009
              ],
              [
                -38.619741427816784,
                -7.215574137002037
              ],
              [
                -38.622669897695516,
                -7.20727417267649
              ],
              [
                -38.6194139510536,
                -7.196654756403099
              ],
              [
                -38.625684390736836,
                -7.191186818964733
              ],
              [
                -38.627410894468255,
                -7.191544803534695
              ],
              [
                -38.63616981401349,
                -7.193358792299413
              ],
              [
                -38.64065578377427,
                -7.187141346232914
              ],
              [
                -38.64325275395249,
                -7.183541876719579
              ],
              [
                -38.650976188642126,
                -7.183859384137401
              ],
              [
                -38.661310113591384,
                -7.184283384719294
              ],
              [
                -38.68744787552276,
                -7.189765874625201
              ],
              [
                -38.682628928121076,
                -7.175582966356133
              ],
              [
                -38.676500469664745,
                -7.168530023631293
              ],
              [
                -38.68481189726292,
                -7.160949574858155
              ],
              [
                -38.67491001146637,
                -7.155325125385051
              ],
              [
                -38.676960481735854,
                -7.131393312949181
              ],
              [
                -38.66936708278261,
                -7.120589375894829
              ],
              [
                -38.6617736308201,
                -7.109785451873305
              ],
              [
                -38.65602668502341,
                -7.092035093445554
              ],
              [
                -38.65942116570987,
                -7.085151153687185
              ],
              [
                -38.66618913657225,
                -7.080325174690069
              ],
              [
                -38.66695011937306,
                -7.079782198415276
              ],
              [
                -38.671071584808395,
                -7.070138772327621
              ],
              [
                -38.676860523128454,
                -7.066708771905907
              ],
              [
                -38.672863582204485,
                -7.059488854217587
              ],
              [
                -38.669688619034716,
                -7.053754397775318
              ],
              [
                -38.66946959973421,
                -7.047311417964861
              ],
              [
                -38.672735073304246,
                -7.046088433759103
              ],
              [
                -38.678328537322685,
                -7.043992440969268
              ],
              [
                -38.68942093016235,
                -7.039837013035329
              ],
              [
                -38.69041145755106,
                -7.033136053096445
              ],
              [
                -38.69106041518054,
                -7.028748606186041
              ],
              [
                -38.71313775186801,
                -7.017690673248436
              ],
              [
                -38.721670166109924,
                -7.014663210755112
              ],
              [
                -38.730203124355135,
                -7.011635259262998
              ],
              [
                -38.747256487687366,
                -6.999223335933459
              ],
              [
                -38.74952044627967,
                -6.997575869223638
              ],
              [
                -38.749947462559824,
                -6.997599861355586
              ],
              [
                -38.758304879762484,
                -6.998067354954544
              ],
              [
                -38.76407881980183,
                -6.993895413167838
              ],
              [
                -38.764620338397485,
                -6.993504386562223
              ],
              [
                -38.76058334423432,
                -6.989661932003086
              ],
              [
                -38.7565968882686,
                -6.989015935006231
              ],
              [
                -38.75150694000319,
                -6.988190949566385
              ],
              [
                -38.74250953687265,
                -6.974835525238733
              ],
              [
                -38.747691987226055,
                -6.966316583326242
              ],
              [
                -38.7465865033256,
                -6.960187150480844
              ],
              [
                -38.75442844668775,
                -6.95836467721151
              ],
              [
                -38.7569219305005,
                -6.957785174485063
              ],
              [
                -38.76475834504372,
                -6.947639762982062
              ],
              [
                -38.75628742106718,
                -6.940016288190245
              ],
              [
                -38.75486244236013,
                -6.938734295971607
              ],
              [
                -38.75709495188599,
                -6.934815327646955
              ],
              [
                -38.76202839847906,
                -6.926153903653679
              ],
              [
                -38.758622915861004,
                -6.92497189422264
              ],
              [
                -38.75838544597968,
                -6.924889434681048
              ],
              [
                -38.758792432842576,
                -6.916163482878776
              ],
              [
                -38.75933392151936,
                -6.915657995150234
              ],
              [
                -38.76139190608906,
                -6.913736522386606
              ],
              [
                -38.76483287961797,
                -6.910523539535271
              ],
              [
                -38.76374538869461,
                -6.909632028576743
              ],
              [
                -38.755896466420324,
                -6.903200075171453
              ],
              [
                -38.738203604166905,
                -6.898564600829534
              ],
              [
                -38.72991518360615,
                -6.896393116452307
              ],
              [
                -38.7309536762953,
                -6.89269964054842
              ],
              [
                -38.73189067459912,
                -6.889368652790093
              ],
              [
                -38.72829222566398,
                -6.886804200851971
              ],
              [
                -38.72505523189641,
                -6.886573672267343
              ],
              [
                -38.72289576573198,
                -6.886419172882966
              ],
              [
                -38.71363285653213,
                -6.88575567924203
              ],
              [
                -38.713699357046316,
                -6.881507206902207
              ],
              [
                -38.71377983206793,
                -6.876391256234259
              ],
              [
                -38.70147694578197,
                -6.874978776151448
              ],
              [
                -38.69795699260257,
                -6.874574745700472
              ],
              [
                -38.69213351343942,
                -6.869988297699797
              ],
              [
                -38.68630958028132,
                -6.865400802715611
              ],
              [
                -38.675037667929836,
                -6.861438846992438
              ],
              [
                -38.676820156897286,
                -6.853938919905953
              ],
              [
                -38.67148472542623,
                -6.845302953415465
              ],
              [
                -38.67098223140174,
                -6.844449970608316
              ],
              [
                -38.670143230709606,
                -6.843024987954772
              ],
              [
                -38.67252222277958,
                -6.839275505488957
              ],
              [
                -38.676740688983465,
                -6.832628087623225
              ],
              [
                -38.67366871840185,
                -6.830172574284895
              ],
              [
                -38.66091431555882,
                -6.822524140907424
              ],
              [
                -38.65529338656119,
                -6.813857217517334
              ],
              [
                -38.64883795597047,
                -6.80773522197191
              ],
              [
                -38.64673645558908,
                -6.807200249579588
              ],
              [
                -38.635078078887254,
                -6.804230269068899
              ],
              [
                -38.63593155901117,
                -6.797913291287335
              ],
              [
                -38.6361485813522,
                -6.797600326851012
              ],
              [
                -38.641143515180445,
                -6.790381365977805
              ],
              [
                -38.629420129520625,
                -6.789534861106274
              ],
              [
                -38.62908961140588,
                -6.789511351050832
              ],
              [
                -38.623277176448376,
                -6.79147086281132
              ],
              [
                -38.61702774118226,
                -6.793577324126709
              ],
              [
                -38.616634740526386,
                -6.792274328875583
              ],
              [
                -38.613774243136916,
                -6.782794423811708
              ],
              [
                -38.62863364107863,
                -6.777324443534249
              ],
              [
                -38.63988956420253,
                -6.763598064236645
              ],
              [
                -38.645776522553334,
                -6.761593581863645
              ],
              [
                -38.65082494097835,
                -6.759875613686233
              ],
              [
                -38.6510534391609,
                -6.758241625427869
              ],
              [
                -38.65128896731421,
                -6.756562625521306
              ],
              [
                -38.64999599109073,
                -6.752970678254566
              ],
              [
                -38.64959098140565,
                -6.751845682627897
              ],
              [
                -38.652004474075184,
                -6.74818268654786
              ],
              [
                -38.65715293297067,
                -6.74036927536898
              ],
              [
                -38.65666442554907,
                -6.737076279378613
              ],
              [
                -38.65559294297327,
                -6.729854326233701
              ],
              [
                -38.66450836600767,
                -6.723698899961136
              ],
              [
                -38.67113131807708,
                -6.710277995016287
              ],
              [
                -38.67222730020515,
                -6.706558025444321
              ],
              [
                -38.66966082956813,
                -6.70216856144179
              ],
              [
                -38.67277029337424,
                -6.696831107755973
              ],
              [
                -38.66408241373612,
                -6.679802216840404
              ],
              [
                -38.660200924378735,
                -6.678413730808172
              ],
              [
                -38.661418428981804,
                -6.675342774334513
              ],
              [
                -38.66197993510839,
                -6.67392724864008
              ],
              [
                -38.659649937495516,
                -6.67367325695837
              ],
              [
                -38.651582020093166,
                -6.672793288066042
              ],
              [
                -38.646835546724866,
                -6.663605857563063
              ],
              [
                -38.64658454877209,
                -6.653819906848388
              ],
              [
                -38.65162152124334,
                -6.647884971149153
              ],
              [
                -38.65670095838164,
                -6.641899520867795
              ],
              [
                -38.65488450839849,
                -6.630091083676544
              ],
              [
                -38.650124548251334,
                -6.627846617664921
              ],
              [
                -38.64874804464392,
                -6.627197608710334
              ],
              [
                -38.64843606437472,
                -6.625730629810172
              ],
              [
                -38.64682208666722,
                -6.618147700191235
              ],
              [
                -38.64627809516843,
                -6.615591712534949
              ],
              [
                -38.645228610747914,
                -6.614908225077512
              ],
              [
                -38.62877873990751,
                -6.604191262338026
              ],
              [
                -38.6317112153846,
                -6.592804874284373
              ],
              [
                -38.632553708346165,
                -6.589536377105985
              ],
              [
                -38.63341821137633,
                -6.588773901593442
              ],
              [
                -38.64247266337799,
                -6.580790477518738
              ],
              [
                -38.63270121742639,
                -6.568821552180083
              ],
              [
                -38.63225075101885,
                -6.562048582184922
              ],
              [
                -38.62892628232493,
                -6.559924122298397
              ],
              [
                -38.62528081755796,
                -6.557594136780253
              ],
              [
                -38.62586231265274,
                -6.554496678113448
              ],
              [
                -38.62622531083688,
                -6.55256566228394
              ],
              [
                -38.62171635468653,
                -6.547151695007324
              ],
              [
                -38.60945796816976,
                -6.532435314310742
              ],
              [
                -38.61223744648146,
                -6.5303613262561
              ],
              [
                -38.615729413978016,
                -6.52775587079938
              ],
              [
                -38.61515291352946,
                -6.525500380840051
              ],
              [
                -38.61190845239195,
                -6.512803462785543
              ],
              [
                -38.59775258835061,
                -6.502380035704492
              ],
              [
                -38.59753508834731,
                -6.502220049793261
              ],
              [
                -38.594594087348185,
                -6.500054043536933
              ],
              [
                -38.591028657288014,
                -6.495664085062622
              ],
              [
                -38.57002984115438,
                -6.469807282869391
              ],
              [
                -38.56971483440332,
                -6.461696326460165
              ],
              [
                -38.569679860320065,
                -6.460793822425222
              ],
              [
                -38.56943434173265,
                -6.454488374089586
              ],
              [
                -38.55602395603441,
                -6.447256417007449
              ],
              [
                -38.554544973834034,
                -6.436975495658864
              ],
              [
                -38.55388298069103,
                -6.432376057850706
              ],
              [
                -38.54464059731164,
                -6.424396614444068
              ],
              [
                -38.5445415792286,
                -6.424311606036055
              ],
              [
                -38.536530665440786,
                -6.417395166243806
              ],
              [
                -38.52413625725994,
                -6.406695218858531
              ],
              [
                -38.51752830052819,
                -6.408345717608759
              ],
              [
                -38.52981122216257,
                -6.39304382655431
              ],
              [
                -38.54021264708984,
                -6.400145290473135
              ],
              [
                -38.5423326307128,
                -6.401592800675389
              ],
              [
                -38.549798065083486,
                -6.39835930677133
              ],
              [
                -38.55114152181317,
                -6.397777829183787
              ],
              [
                -38.55188753820878,
                -6.397969815197134
              ],
              [
                -38.5573059632413,
                -6.399364321031671
              ],
              [
                -38.5575124964131,
                -6.399417316759787
              ],
              [
                -38.57290184019134,
                -6.403377761423013
              ],
              [
                -38.58069479941541,
                -6.400635794924265
              ],
              [
                -38.581163758233025,
                -6.396345852488901
              ],
              [
                -38.588974692282456,
                -6.395127376190483
              ],
              [
                -38.59374717897046,
                -6.396476345949561
              ],
              [
                -38.601666107143245,
                -6.389236923380428
              ],
              [
                -38.59456216382134,
                -6.386182433274898
              ],
              [
                -38.59208521089563,
                -6.377861499107616
              ],
              [
                -38.587864250015876,
                -6.375622993627575
              ],
              [
                -38.579004316314446,
                -6.374984010636726
              ],
              [
                -38.572725851095925,
                -6.374531005937656
              ],
              [
                -38.57453436139098,
                -6.367084568894212
              ],
              [
                -38.5754068312252,
                -6.363491598342648
              ],
              [
                -38.571956364809,
                -6.362528611500969
              ],
              [
                -38.56697292598189,
                -6.361138598946475
              ],
              [
                -38.56447794063744,
                -6.356643139148321
              ],
              [
                -38.56400744828686,
                -6.355795141411944
              ],
              [
                -38.56187546996649,
                -6.355528154057751
              ],
              [
                -38.54468314661788,
                -6.353372138296197
              ],
              [
                -38.549468579122916,
                -6.350314178221474
              ],
              [
                -38.54934558329452,
                -6.35015817334989
              ],
              [
                -38.544632603173156,
                -6.344201223432139
              ],
              [
                -38.533636228058896,
                -6.342048732805865
              ],
              [
                -38.51722486019837,
                -6.345561210596798
              ],
              [
                -38.51512038425579,
                -6.347385187032728
              ],
              [
                -38.50503997596303,
                -6.356123605464413
              ],
              [
                -38.49408304258601,
                -6.34642069049646
              ],
              [
                -38.493864048483694,
                -6.344925162964921
              ],
              [
                -38.48589564800828,
                -6.339942225360073
              ],
              [
                -38.47521222724806,
                -6.333261253155047
              ],
              [
                -38.467156809399064,
                -6.331496782511378
              ],
              [
                -38.466392315151964,
                -6.331329270305094
              ],
              [
                -38.462302859275525,
                -6.330433300550869
              ],
              [
                -38.45723036908492,
                -6.329321803820062
              ],
              [
                -38.46194432740887,
                -6.333418751112465
              ],
              [
                -38.4676543005035,
                -6.338382742337064
              ],
              [
                -38.47565923133514,
                -6.34534016761399
              ],
              [
                -38.48954609370915,
                -6.364840513437708
              ],
              [
                -38.48343763229644,
                -6.379011398334169
              ],
              [
                -38.48269912256499,
                -6.380724907537601
              ],
              [
                -38.48381563167985,
                -6.386975869763113
              ],
              [
                -38.48584959337421,
                -6.398363250748518
              ],
              [
                -38.469160733644976,
                -6.396450786385071
              ],
              [
                -38.46542726237117,
                -6.396022772201579
              ],
              [
                -38.451025392862014,
                -6.392526787681952
              ],
              [
                -38.447576431835735,
                -6.402965230320506
              ],
              [
                -38.44131247621109,
                -6.406043680212076
              ],
              [
                -38.425659107110775,
                -6.413737614952916
              ],
              [
                -38.413183705963796,
                -6.411666131654068
              ],
              [
                -38.40071082680986,
                -6.409594646365452
              ],
              [
                -38.387063434925736,
                -6.419382529163904
              ],
              [
                -38.38385598042256,
                -6.422955010255897
              ],
              [
                -38.38271249245885,
                -6.427282468871838
              ],
              [
                -38.38155297059259,
                -6.431669948012124
              ],
              [
                -38.37473555131287,
                -6.436990906090578
              ],
              [
                -38.37329003847592,
                -6.45539277620383
              ],
              [
                -38.36799658930218,
                -6.45424677952619
              ],
              [
                -38.363493611138054,
                -6.45327179505624
              ],
              [
                -38.36058965078009,
                -6.461598714427002
              ],
              [
                -38.35148420510648,
                -6.457016750857807
              ],
              [
                -38.350709241113556,
                -6.456626721363721
              ],
              [
                -38.34266831318232,
                -6.457969751499633
              ],
              [
                -38.338942826412286,
                -6.459736709256959
              ],
              [
                -38.32916790255836,
                -6.459637213426219
              ],
              [
                -38.31787350458431,
                -6.473386593972998
              ],
              [
                -38.31543651881778,
                -6.480748534133438
              ],
              [
                -38.3130005410373,
                -6.488111484289818
              ],
              [
                -38.30380259983367,
                -6.48853895973205
              ],
              [
                -38.29364521196425,
                -6.489010944178714
              ],
              [
                -38.290989237596364,
                -6.497118883388019
              ],
              [
                -38.28833425221504,
                -6.505225831609464
              ],
              [
                -38.256147029779896,
                -6.482821987879249
              ],
              [
                -38.25052657605376,
                -6.485327940594522
              ],
              [
                -38.23884516950562,
                -6.479906997841065
              ],
              [
                -38.23702420301569,
                -6.480436997488707
              ],
              [
                -38.227747269037266,
                -6.483137443216463
              ],
              [
                -38.20805695037065,
                -6.486532410497369
              ],
              [
                -38.20016551424736,
                -6.487892909578211
              ],
              [
                -38.196573527619435,
                -6.492247865271628
              ],
              [
                -38.19187858470268,
                -6.497940847809521
              ],
              [
                -38.187069114060485,
                -6.498795813902366
              ],
              [
                -38.17822868216725,
                -6.498772809096034
              ],
              [
                -38.16078434934237,
                -6.498726318651355
              ],
              [
                -38.15413991388004,
                -6.494912326838858
              ],
              [
                -38.147495961415615,
                -6.491098363034334
              ],
              [
                -38.13940203008169,
                -6.508766705038942
              ],
              [
                -38.135132050022634,
                -6.518087617606026
              ],
              [
                -38.12768760183582,
                -6.519779100393263
              ],
              [
                -38.121121192128136,
                -6.521270101335152
              ],
              [
                -38.11962816992812,
                -6.521609087684035
              ],
              [
                -38.11525271532608,
                -6.521297583140952
              ],
              [
                -38.09760038066606,
                -6.509099697147717
              ],
              [
                -38.09640239809635,
                -6.501744249618403
              ],
              [
                -38.09500942502893,
                -6.493194311270208
              ],
              [
                -38.089736472270644,
                -6.489916344246875
              ],
              [
                -38.082630034288684,
                -6.485498351875506
              ],
              [
                -38.070417154683234,
                -6.463541030784292
              ],
              [
                -38.061549237121866,
                -6.459060545763466
              ],
              [
                -38.05885523288191,
                -6.452741109232933
              ],
              [
                -38.053315813641056,
                -6.452166111005225
              ],
              [
                -38.052836806272076,
                -6.450003607549339
              ],
              [
                -38.05106481008969,
                -6.442017161654201
              ],
              [
                -38.045332388794826,
                -6.441056684312584
              ],
              [
                -38.043534877450924,
                -6.451586110976806
              ],
              [
                -38.035866951058104,
                -6.459600522328827
              ],
              [
                -38.036624441929334,
                -6.461856532241304
              ],
              [
                -38.040249882015175,
                -6.472656440453717
              ],
              [
                -38.02733952872271,
                -6.474248428365455
              ],
              [
                -38.02568601932893,
                -6.472519414739042
              ],
              [
                -38.021427052525134,
                -6.468066455610581
              ],
              [
                -38.012339152047126,
                -6.467886448916798
              ],
              [
                -38.01859909924462,
                -6.448636611294684
              ],
              [
                -38.019581589681096,
                -6.445615137527901
              ],
              [
                -38.0163756082091,
                -6.441845142803313
              ],
              [
                -38.00784020348115,
                -6.431807707444785
              ],
              [
                -38.00783620351909,
                -6.431803232477023
              ],
              [
                -38.01400515981912,
                -6.43000575462268
              ],
              [
                -38.01330518491173,
                -6.427039261311504
              ],
              [
                -37.990898373015405,
                -6.426564774081926
              ],
              [
                -37.983740410672844,
                -6.426412767500237
              ],
              [
                -37.978695463837056,
                -6.426305763975478
              ],
              [
                -37.97095903039174,
                -6.420393293004064
              ],
              [
                -37.96829053607275,
                -6.418354323156215
              ],
              [
                -37.96763307186068,
                -6.404736415109742
              ],
              [
                -37.955007203814596,
                -6.397820970777354
              ],
              [
                -37.95481668995015,
                -6.407673894650766
              ],
              [
                -37.94638526374575,
                -6.418099798581254
              ],
              [
                -37.94308229191255,
                -6.42218377647371
              ],
              [
                -37.92764190665067,
                -6.4211052716368
              ],
              [
                -37.92481692046984,
                -6.417923280629686
              ],
              [
                -37.91893948826565,
                -6.411303335475288
              ],
              [
                -37.90114215289575,
                -6.399289900617259
              ],
              [
                -37.89442970643814,
                -6.399561404050866
              ],
              [
                -37.88986823322357,
                -6.399745904588871
              ],
              [
                -37.885856770393374,
                -6.396610923458166
              ],
              [
                -37.883872310272146,
                -6.391288972781286
              ],
              [
                -37.88812128004654,
                -6.386820487548597
              ],
              [
                -37.887834291602836,
                -6.363980170090632
              ],
              [
                -37.859436522176104,
                -6.374489080135956
              ],
              [
                -37.853851075690564,
                -6.365927134492217
              ],
              [
                -37.84114067910062,
                -6.362641681863891
              ],
              [
                -37.84372567916519,
                -6.353967227500323
              ],
              [
                -37.83386927228345,
                -6.341996808811298
              ],
              [
                -37.84090019152303,
                -6.334680867734395
              ],
              [
                -37.84280418415256,
                -6.332699400505076
              ],
              [
                -37.8333402743748,
                -6.321389463950028
              ],
              [
                -37.81428545517984,
                -6.313731027556845
              ],
              [
                -37.82083091055611,
                -6.308073080181764
              ],
              [
                -37.823640363540264,
                -6.305644606051436
              ],
              [
                -37.810030511028046,
                -6.291270220928642
              ],
              [
                -37.79412966196264,
                -6.28864421020895
              ],
              [
                -37.78652319934342,
                -6.28900319345809
              ],
              [
                -37.782677740904234,
                -6.289185199535683
              ],
              [
                -37.75727095668028,
                -6.290384690646849
              ],
              [
                -37.75758044498101,
                -6.287276688243389
              ],
              [
                -37.77103134959122,
                -6.272676815556781
              ],
              [
                -37.77008635766364,
                -6.265248885259071
              ],
              [
                -37.76012446736752,
                -6.253701477504022
              ],
              [
                -37.770650867691685,
                -6.248217527354137
              ],
              [
                -37.77604883722266,
                -6.2454050199175
              ],
              [
                -37.769745869360314,
                -6.242398554470545
              ],
              [
                -37.76128646705042,
                -6.238363574721475
              ],
              [
                -37.7642804423954,
                -6.234359594098817
              ],
              [
                -37.766554410931484,
                -6.23365213211754
              ],
              [
                -37.774484346057726,
                -6.231188147567825
              ],
              [
                -37.76345195781085,
                -6.21851074108285
              ],
              [
                -37.759348973839934,
                -6.216807751834978
              ],
              [
                -37.75335405594618,
                -6.214318765554436
              ],
              [
                -37.753818526101654,
                -6.206144322098841
              ],
              [
                -37.75417805499873,
                -6.199805384155091
              ],
              [
                -37.74880957108422,
                -6.193441435756447
              ],
              [
                -37.70868643918064,
                -6.176881038446747
              ],
              [
                -37.69778653678525,
                -6.186214963070356
              ],
              [
                -37.694687574729166,
                -6.18656096037501
              ],
              [
                -37.67697071678799,
                -6.188538910531943
              ],
              [
                -37.646319467276726,
                -6.189002381380311
              ],
              [
                -37.64220102043136,
                -6.184225454373641
              ],
              [
                -37.63808306058017,
                -6.179448955371059
              ],
              [
                -37.61586974857105,
                -6.176094491695874
              ],
              [
                -37.60771932086311,
                -6.174861980237738
              ],
              [
                -37.59956839816552,
                -6.173629998778412
              ],
              [
                -37.5933164470218,
                -6.168898534104171
              ],
              [
                -37.584519527430324,
                -6.162241596007435
              ],
              [
                -37.572855120412804,
                -6.158117105151335
              ],
              [
                -37.56922616520031,
                -6.155051143034221
              ],
              [
                -37.565481684513486,
                -6.159106596691651
              ],
              [
                -37.560496742868395,
                -6.164506554962871
              ],
              [
                -37.539599420697066,
                -6.165541513593499
              ],
              [
                -37.53669745475148,
                -6.155116093994244
              ],
              [
                -37.53595446695607,
                -6.152447604576681
              ],
              [
                -37.530688501269246,
                -6.152337611124442
              ],
              [
                -37.52113508429627,
                -6.152139105668859
              ],
              [
                -37.51826560053624,
                -6.152080096326041
              ],
              [
                -37.50249424527705,
                -6.151754093962169
              ],
              [
                -37.50265523841723,
                -6.14928164557753
              ],
              [
                -37.5029987447121,
                -6.14400967940547
              ],
              [
                -37.488971889916606,
                -6.144051677251106
              ],
              [
                -37.48631039540411,
                -6.14707263572738
              ],
              [
                -37.48259194942536,
                -6.151292583072479
              ],
              [
                -37.45855512485446,
                -6.144615149679802
              ],
              [
                -37.45351417676779,
                -6.119685326453684
              ],
              [
                -37.4452952599232,
                -6.111813378520367
              ],
              [
                -37.439763827105786,
                -6.110915919157451
              ],
              [
                -37.43158490283389,
                -6.109588894538398
              ],
              [
                -37.42555494845742,
                -6.108609888512965
              ],
              [
                -37.41866548313664,
                -6.110046899851396
              ],
              [
                -37.40741512260263,
                -6.112392845279502
              ],
              [
                -37.400324676014456,
                -6.110696863264633
              ],
              [
                -37.393234715426516,
                -6.109000866253856
              ],
              [
                -37.3827123397736,
                -6.086798535340153
              ],
              [
                -37.37839138705287,
                -6.084748057881496
              ],
              [
                -37.37116493508161,
                -6.082393096031693
              ],
              [
                -37.354035069494614,
                -6.086099025066203
              ],
              [
                -37.344707166462904,
                -6.076585621595641
              ],
              [
                -37.335378734432496,
                -6.067072187155648
              ],
              [
                -37.30879547939399,
                -6.057372242572918
              ],
              [
                -37.29849208475033,
                -6.049816287098457
              ],
              [
                -37.291930125901516,
                -6.054080260258391
              ],
              [
                -37.286513695426926,
                -6.057600734015233
              ],
              [
                -37.272073316997165,
                -6.055313731240734
              ],
              [
                -37.26762436105249,
                -6.050639785556279
              ],
              [
                -37.26317590410197,
                -6.045966823871555
              ],
              [
                -37.25466249916023,
                -6.027900931929365
              ],
              [
                -37.24458906669354,
                -6.026592944130092
              ],
              [
                -37.230731185700016,
                -6.028357432635353
              ],
              [
                -37.226773701726835,
                -6.034635890388484
              ]
            ]
          ],
          [
            [
              [
                -34.86163255784239,
                -6.982025055750045
              ],
              [
                -34.85652211225062,
                -6.983376070480305
              ],
              [
                -34.85017216870517,
                -6.982005545293119
              ],
              [
                -34.847864185951764,
                -6.981508075764617
              ],
              [
                -34.84736620589064,
                -6.983873559392201
              ],
              [
                -34.847013219975054,
                -6.985548029678956
              ],
              [
                -34.849320169723384,
                -6.999570916350837
              ],
              [
                -34.852370635308716,
                -7.006716869880673
              ],
              [
                -34.85544762139084,
                -7.011256834404828
              ],
              [
                -34.85989207953664,
                -7.013617805376388
              ],
              [
                -34.862032529174506,
                -7.010575353280648
              ],
              [
                -34.864566026704296,
                -7.005356876953566
              ],
              [
                -34.86606554181607,
                -6.998318448581787
              ],
              [
                -34.866543525586636,
                -6.99516847924546
              ],
              [
                -34.86721152404495,
                -6.991402990989492
              ],
              [
                -34.86712802064719,
                -6.987133047294504
              ],
              [
                -34.86458005396665,
                -6.980776088760814
              ],
              [
                -34.86163255784239,
                -6.982025055750045
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 16,
        "nome": "Pernambuco",
        "sigla": "PE",
        "regiao_id": 4,
        "codigo_ibg": 26
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -37.176537412088756,
                -7.309167927800929
              ],
              [
                -37.16810743782098,
                -7.319628367158019
              ],
              [
                -37.16757547846006,
                -7.322398821208204
              ],
              [
                -37.16492699258624,
                -7.336195717908329
              ],
              [
                -37.15665054555223,
                -7.337813232168901
              ],
              [
                -37.14707263609881,
                -7.335322721421987
              ],
              [
                -37.14452916408279,
                -7.334661727674173
              ],
              [
                -37.1409411855879,
                -7.338982675385951
              ],
              [
                -37.13489724160439,
                -7.346261656254602
              ],
              [
                -37.131060766566314,
                -7.344880142148596
              ],
              [
                -37.12755878148087,
                -7.343619163355013
              ],
              [
                -37.1234363396792,
                -7.347991606263798
              ],
              [
                -37.12102083713233,
                -7.350552599537921
              ],
              [
                -37.09168960616465,
                -7.356525042221348
              ],
              [
                -37.0725347821886,
                -7.367883413542975
              ],
              [
                -37.07217526763806,
                -7.368862904650796
              ],
              [
                -37.06745931376774,
                -7.381703821188365
              ],
              [
                -37.05482091787396,
                -7.378726821928126
              ],
              [
                -37.05251740867267,
                -7.378184346432715
              ],
              [
                -37.04941745781255,
                -7.377254839356805
              ],
              [
                -37.025849155800294,
                -7.387539258703455
              ],
              [
                -37.02172270912517,
                -7.393171209772374
              ],
              [
                -37.017536239919174,
                -7.398885663153838
              ],
              [
                -37.01349526957271,
                -7.40440161518919
              ],
              [
                -37.01700120966286,
                -7.409538547822073
              ],
              [
                -37.025549132538195,
                -7.422063970739192
              ],
              [
                -37.017143204394394,
                -7.424183937938601
              ],
              [
                -37.016173725597035,
                -7.426023420896113
              ],
              [
                -37.01408821909058,
                -7.429980412537885
              ],
              [
                -37.0159397381573,
                -7.430687892421807
              ],
              [
                -37.02319613079521,
                -7.433460893291774
              ],
              [
                -37.01275821785964,
                -7.444587782877484
              ],
              [
                -37.01030723504288,
                -7.45323473276216
              ],
              [
                -37.001369305324026,
                -7.457075679153457
              ],
              [
                -36.996887871574245,
                -7.459001169799459
              ],
              [
                -36.993648889253166,
                -7.464318146994796
              ],
              [
                -36.994052403928116,
                -7.466631134304927
              ],
              [
                -36.99502386611154,
                -7.472192053779323
              ],
              [
                -36.9932643913002,
                -7.472722553325807
              ],
              [
                -36.98643696024022,
                -7.474782065155534
              ],
              [
                -36.98382397013701,
                -7.481723977184359
              ],
              [
                -37.002840788801045,
                -7.495987901633308
              ],
              [
                -37.009064740073974,
                -7.490172426555888
              ],
              [
                -37.01013921231691,
                -7.489168442174298
              ],
              [
                -37.02513509281524,
                -7.496612906651606
              ],
              [
                -37.02725457061085,
                -7.497178384861616
              ],
              [
                -37.05340285270887,
                -7.504162875409127
              ],
              [
                -37.067893203825925,
                -7.51671928688577
              ],
              [
                -37.07686562294714,
                -7.517294771238417
              ],
              [
                -37.085838547071795,
                -7.517870288593887
              ],
              [
                -37.10568738153863,
                -7.530740695810461
              ],
              [
                -37.1211062422748,
                -7.535713174989724
              ],
              [
                -37.13297510484626,
                -7.549275560862048
              ],
              [
                -37.133306118627075,
                -7.549653570183787
              ],
              [
                -37.15534741290141,
                -7.567490954779574
              ],
              [
                -37.167535799309874,
                -7.581462363876319
              ],
              [
                -37.17377023326839,
                -7.591682764501209
              ],
              [
                -37.17381474481861,
                -7.591755280973785
              ],
              [
                -37.1799601506679,
                -7.601829689687673
              ],
              [
                -37.181420631835984,
                -7.605592177680357
              ],
              [
                -37.18639359891997,
                -7.618399566367683
              ],
              [
                -37.191603552413,
                -7.624103521246332
              ],
              [
                -37.19510101250534,
                -7.627934016307804
              ],
              [
                -37.19634348875477,
                -7.631486502779198
              ],
              [
                -37.19472801636418,
                -7.651612833811908
              ],
              [
                -37.18808804188473,
                -7.658285269040358
              ],
              [
                -37.18145011538959,
                -7.664956719275671
              ],
              [
                -37.17533363995999,
                -7.680990581500736
              ],
              [
                -37.17243117393445,
                -7.684443544535116
              ],
              [
                -37.167230213102314,
                -7.690631494626226
              ],
              [
                -37.16062927307439,
                -7.697563460870392
              ],
              [
                -37.15129681670602,
                -7.712792847817358
              ],
              [
                -37.148690364654605,
                -7.730745681979974
              ],
              [
                -37.14715787251031,
                -7.732914163016628
              ],
              [
                -37.138537426193665,
                -7.74511757792819
              ],
              [
                -37.14621987297332,
                -7.756797982690852
              ],
              [
                -37.15662024241553,
                -7.772609390720396
              ],
              [
                -37.156356235694616,
                -7.776469344698578
              ],
              [
                -37.15154379193045,
                -7.779445337919807
              ],
              [
                -37.15223528296279,
                -7.780514337208369
              ],
              [
                -37.16035620913068,
                -7.793062730713707
              ],
              [
                -37.17095258998771,
                -7.794781720813723
              ],
              [
                -37.170258110123385,
                -7.799827197315869
              ],
              [
                -37.175317070446404,
                -7.79593821533553
              ],
              [
                -37.17746404976043,
                -7.794286738780612
              ],
              [
                -37.17836655732547,
                -7.793593239847137
              ],
              [
                -37.18260550540778,
                -7.790332768364961
              ],
              [
                -37.191129942642746,
                -7.790764753765977
              ],
              [
                -37.19753937976615,
                -7.798528203919446
              ],
              [
                -37.217010691347774,
                -7.806287675470012
              ],
              [
                -37.22258766311086,
                -7.803664690121225
              ],
              [
                -37.230152087072206,
                -7.806349183410037
              ],
              [
                -37.22975607150911,
                -7.812702136101914
              ],
              [
                -37.24324297553952,
                -7.81859407637623
              ],
              [
                -37.24005197248718,
                -7.822687545283238
              ],
              [
                -37.227722076821514,
                -7.821067543980153
              ],
              [
                -37.227601104904195,
                -7.821051570007258
              ],
              [
                -37.22167262371847,
                -7.841840889013062
              ],
              [
                -37.2467449013236,
                -7.846403900815895
              ],
              [
                -37.24995088547222,
                -7.853264350512344
              ],
              [
                -37.24451594013726,
                -7.861232256788268
              ],
              [
                -37.24449943163152,
                -7.862043777523195
              ],
              [
                -37.24425341331337,
                -7.87385516134209
              ],
              [
                -37.25734329739517,
                -7.882754109286113
              ],
              [
                -37.28698750657505,
                -7.903484958501372
              ],
              [
                -37.303233877743686,
                -7.903767971383173
              ],
              [
                -37.30758884019528,
                -7.907548964801694
              ],
              [
                -37.32162871226276,
                -7.911080945937472
              ],
              [
                -37.30918080631411,
                -7.913491925374272
              ],
              [
                -37.310985297071205,
                -7.921259868090966
              ],
              [
                -37.31084728710931,
                -7.921483847257406
              ],
              [
                -37.30764632719733,
                -7.926664812838316
              ],
              [
                -37.30856381130776,
                -7.933786274823464
              ],
              [
                -37.31428926829301,
                -7.935525766072686
              ],
              [
                -37.317357234608004,
                -7.942380198862176
              ],
              [
                -37.33123661360205,
                -7.944046208278777
              ],
              [
                -37.34171000703889,
                -7.945303711087406
              ],
              [
                -37.34900945842033,
                -7.947918669903979
              ],
              [
                -37.34725695698276,
                -7.953957635093294
              ],
              [
                -37.350451895224566,
                -7.961967094161388
              ],
              [
                -37.35546085971691,
                -7.974524977790276
              ],
              [
                -37.34110199589016,
                -7.974144459051511
              ],
              [
                -37.33937351187047,
                -7.979396443314846
              ],
              [
                -37.31657171495818,
                -7.967661504947714
              ],
              [
                -37.30292881979793,
                -7.969805497413633
              ],
              [
                -37.29961588291844,
                -7.967555989014813
              ],
              [
                -37.29515688480402,
                -7.964529524661341
              ],
              [
                -37.28582650534633,
                -7.968143972322963
              ],
              [
                -37.27698754570681,
                -7.971568926835974
              ],
              [
                -37.267325136298595,
                -7.968932987271993
              ],
              [
                -37.26312167040651,
                -7.967785960686923
              ],
              [
                -37.25283224481631,
                -7.968512964767294
              ],
              [
                -37.24655782095293,
                -7.968956432278455
              ],
              [
                -37.23693641884416,
                -7.972908399098609
              ],
              [
                -37.230340979368755,
                -7.969008436755654
              ],
              [
                -37.228368977228094,
                -7.97218590672033
              ],
              [
                -37.21919205436435,
                -7.97225891973251
              ],
              [
                -37.22005157370293,
                -7.963726463066465
              ],
              [
                -37.22032407949279,
                -7.961019980109025
              ],
              [
                -37.218436569415566,
                -7.958286494615442
              ],
              [
                -37.211321144689514,
                -7.954207538257096
              ],
              [
                -37.20742267341955,
                -7.951457560274505
              ],
              [
                -37.192267298445934,
                -7.960196959781332
              ],
              [
                -37.19297079610558,
                -7.982461329022724
              ],
              [
                -37.18785581429577,
                -7.982565316081817
              ],
              [
                -37.184329336718704,
                -7.989673733534587
              ],
              [
                -37.181104862373026,
                -7.989538742961031
              ],
              [
                -37.17956939401857,
                -7.989474242213507
              ],
              [
                -37.17888689810544,
                -7.990645726647133
              ],
              [
                -37.17573042762814,
                -7.996067203377123
              ],
              [
                -37.167240505475306,
                -7.996459186479842
              ],
              [
                -37.161369550237715,
                -7.996730681632914
              ],
              [
                -37.15851108080651,
                -7.998867156876057
              ],
              [
                -37.15140562248224,
                -8.008971099366471
              ],
              [
                -37.15086011648012,
                -8.012439546236564
              ],
              [
                -37.149147115741705,
                -8.023336971006165
              ],
              [
                -37.147989658680274,
                -8.034711867566921
              ],
              [
                -37.157665028771454,
                -8.050826760526878
              ],
              [
                -37.15704453779817,
                -8.057483222845025
              ],
              [
                -37.15642452581497,
                -8.064141165157078
              ],
              [
                -37.1527955726279,
                -8.07061660641648
              ],
              [
                -37.14329665180059,
                -8.08756798335745
              ],
              [
                -37.14534911061345,
                -8.093634409420437
              ],
              [
                -37.14740159342258,
                -8.099700372494315
              ],
              [
                -37.146788079634675,
                -8.10360035202716
              ],
              [
                -37.1435921281235,
                -8.123929717237164
              ],
              [
                -37.15004206415952,
                -8.126130669641675
              ],
              [
                -37.15280502490229,
                -8.127073179678217
              ],
              [
                -37.158190468734105,
                -8.13847659255766
              ],
              [
                -37.154544006590356,
                -8.143828066466629
              ],
              [
                -37.15057904987877,
                -8.149646992524318
              ],
              [
                -37.15865895361977,
                -8.155273962006031
              ],
              [
                -37.16299492569707,
                -8.15829346741605
              ],
              [
                -37.16318992907819,
                -8.165365897305495
              ],
              [
                -37.159801461225925,
                -8.170006843890745
              ],
              [
                -37.14076908414978,
                -8.176838310712577
              ],
              [
                -37.13070319636765,
                -8.174413828987241
              ],
              [
                -37.126025235762874,
                -8.173286832766891
              ],
              [
                -37.12246274672821,
                -8.174014807231458
              ],
              [
                -37.12476420407678,
                -8.198279617916567
              ],
              [
                -37.124784732714225,
                -8.198495616276238
              ],
              [
                -37.12364273258567,
                -8.20357758433644
              ],
              [
                -37.12250174144583,
                -8.208659559399527
              ],
              [
                -37.10578784823389,
                -8.228196382625597
              ],
              [
                -37.095607961502495,
                -8.24009626686624
              ],
              [
                -37.09173195958604,
                -8.247577228254503
              ],
              [
                -37.08785648566275,
                -8.255057168652785
              ],
              [
                -37.045305855860924,
                -8.261276092435992
              ],
              [
                -37.02021256497007,
                -8.289262343752805
              ],
              [
                -36.99396928894842,
                -8.301095764999552
              ],
              [
                -36.990740302388595,
                -8.30255173512446
              ],
              [
                -36.98408589680235,
                -8.299792250728759
              ],
              [
                -36.97743145321926,
                -8.297032773338604
              ],
              [
                -36.976795932813424,
                -8.297091245358205
              ],
              [
                -36.9570011130716,
                -8.298920735760023
              ],
              [
                -36.95158016371355,
                -8.295964736922045
              ],
              [
                -36.94788770001637,
                -8.291804795777503
              ],
              [
                -36.947577199670896,
                -8.284798322321475
              ],
              [
                -36.947307214178146,
                -8.278722362759247
              ],
              [
                -36.94452824212103,
                -8.27484540022016
              ],
              [
                -36.928100396461716,
                -8.270794906650188
              ],
              [
                -36.92257593468798,
                -8.269432941515257
              ],
              [
                -36.91521803568914,
                -8.261532982103674
              ],
              [
                -36.9078600756876,
                -8.253632525716668
              ],
              [
                -36.89553071530897,
                -8.240411648140872
              ],
              [
                -36.88716879178882,
                -8.237821161134235
              ],
              [
                -36.87880735726968,
                -8.235230651134938
              ],
              [
                -36.86127201116716,
                -8.222011735341725
              ],
              [
                -36.812070468918236,
                -8.204893359558016
              ],
              [
                -36.810422499332944,
                -8.202776356509403
              ],
              [
                -36.80357406554019,
                -8.19398290262488
              ],
              [
                -36.78829167264118,
                -8.190181931355625
              ],
              [
                -36.775150294459976,
                -8.1903104405697
              ],
              [
                -36.77442479167652,
                -8.190599404746228
              ],
              [
                -36.761146414462765,
                -8.195888861060718
              ],
              [
                -36.75307050731705,
                -8.19108039850288
              ],
              [
                -36.751502992370646,
                -8.183698451142778
              ],
              [
                -36.74972001517363,
                -8.175301535444248
              ],
              [
                -36.74217461256752,
                -8.172593548153204
              ],
              [
                -36.73511565960157,
                -8.17235202223692
              ],
              [
                -36.724136744648774,
                -8.171977020143734
              ],
              [
                -36.72196628531459,
                -8.167921569662584
              ],
              [
                -36.71535084004041,
                -8.166918559993
              ],
              [
                -36.719985820926084,
                -8.16187560670326
              ],
              [
                -36.72534125104364,
                -8.156048643052241
              ],
              [
                -36.72238578386899,
                -8.150559682006863
              ],
              [
                -36.70794291706735,
                -8.15590113098279
              ],
              [
                -36.706935912060665,
                -8.155868652411398
              ],
              [
                -36.68662709844829,
                -8.155221614832037
              ],
              [
                -36.689862599437234,
                -8.148155676037494
              ],
              [
                -36.69309854041956,
                -8.141089228249315
              ],
              [
                -36.66597782231286,
                -8.123582843438747
              ],
              [
                -36.638383067407375,
                -8.118380867250067
              ],
              [
                -36.62816965473556,
                -8.110640923820894
              ],
              [
                -36.628155173671104,
                -8.102293992366445
              ],
              [
                -36.63653959765246,
                -8.092853089174568
              ],
              [
                -36.64249953091791,
                -8.092403085474865
              ],
              [
                -36.646796502899804,
                -8.092078599459674
              ],
              [
                -36.64993299104049,
                -8.080918186318392
              ],
              [
                -36.657022912937585,
                -8.079622188069864
              ],
              [
                -36.65635441904538,
                -8.075744212526557
              ],
              [
                -36.64837550409421,
                -8.067159783500975
              ],
              [
                -36.627411688438606,
                -8.055352842989345
              ],
              [
                -36.62740776340009,
                -7.986009404073599
              ],
              [
                -36.624271796215844,
                -7.982423398364361
              ],
              [
                -36.62268330382415,
                -7.980607922169175
              ],
              [
                -36.62241829351332,
                -7.97396344646175
              ],
              [
                -36.62222529911809,
                -7.969114488897742
              ],
              [
                -36.617171369746714,
                -7.959615594525103
              ],
              [
                -36.61085891846963,
                -7.947749679533895
              ],
              [
                -36.58981210777922,
                -7.956799095630831
              ],
              [
                -36.57634672983162,
                -7.95924756794351
              ],
              [
                -36.57327425672422,
                -7.954857090568903
              ],
              [
                -36.57906369844463,
                -7.946937148614746
              ],
              [
                -36.57954269069818,
                -7.94628213707715
              ],
              [
                -36.578912692090384,
                -7.945307673139223
              ],
              [
                -36.57269627717078,
                -7.935678729941994
              ],
              [
                -36.57627623019853,
                -7.920681325189588
              ],
              [
                -36.57769721826631,
                -7.914727376535591
              ],
              [
                -36.572142293082344,
                -7.905520441640652
              ],
              [
                -36.55158099405981,
                -7.898320502358897
              ],
              [
                -36.54656201805345,
                -7.899264984069071
              ],
              [
                -36.53588262367222,
                -7.901274448047268
              ],
              [
                -36.498946417302506,
                -7.91990081320947
              ],
              [
                -36.493817951821875,
                -7.917666322531458
              ],
              [
                -36.48767100878066,
                -7.91498781050575
              ],
              [
                -36.484194570558245,
                -7.919070788031107
              ],
              [
                -36.47969857948163,
                -7.924352262437079
              ],
              [
                -36.46612572788936,
                -7.916402816536369
              ],
              [
                -36.463652253108776,
                -7.914953799854739
              ],
              [
                -36.45715581898287,
                -7.915078299772643
              ],
              [
                -36.45289835391284,
                -7.915160316783863
              ],
              [
                -36.44530339258163,
                -7.915306292671549
              ],
              [
                -36.44531338545225,
                -7.907774370257551
              ],
              [
                -36.43437399350566,
                -7.89586093434086
              ],
              [
                -36.42965204399751,
                -7.885283997940429
              ],
              [
                -36.43227805104553,
                -7.879588575321907
              ],
              [
                -36.43348704847131,
                -7.879313066412834
              ],
              [
                -36.43992448716841,
                -7.877848583851149
              ],
              [
                -36.42873207080652,
                -7.866742147539889
              ],
              [
                -36.42861758391725,
                -7.866629148330054
              ],
              [
                -36.42861408317691,
                -7.866338673588619
              ],
              [
                -36.428045632516756,
                -7.823910472554182
              ],
              [
                -36.42361015577794,
                -7.815369539649883
              ],
              [
                -36.41338223688127,
                -7.814331027801063
              ],
              [
                -36.40697979354085,
                -7.813681050897179
              ],
              [
                -36.396000920848465,
                -7.808175089297299
              ],
              [
                -36.3807500569327,
                -7.810851551608284
              ],
              [
                -36.373180622490985,
                -7.81218053337849
              ],
              [
                -36.36724315696896,
                -7.809032571323631
              ],
              [
                -36.361306704439166,
                -7.805885063271742
              ],
              [
                -36.30950118134273,
                -7.808358018886396
              ],
              [
                -36.304138211635376,
                -7.807889016398863
              ],
              [
                -36.29051284148796,
                -7.814225950401641
              ],
              [
                -36.27879394693057,
                -7.823625400968749
              ],
              [
                -36.27064951365743,
                -7.830157330678964
              ],
              [
                -36.26685405413677,
                -7.830742845168287
              ],
              [
                -36.263058585616925,
                -7.831329324650008
              ],
              [
                -36.2500586782438,
                -7.826991354446874
              ],
              [
                -36.23968529246912,
                -7.817408894247763
              ],
              [
                -36.24131376414401,
                -7.809376952235474
              ],
              [
                -36.24425576330347,
                -7.807482977299677
              ],
              [
                -36.253879657050675,
                -7.801286526123924
              ],
              [
                -36.25199668488982,
                -7.793546585127237
              ],
              [
                -36.21623300978946,
                -7.763731794631415
              ],
              [
                -36.2060351291984,
                -7.769974761993475
              ],
              [
                -36.2036366141245,
                -7.77843516200371
              ],
              [
                -36.200501134630194,
                -7.789492096154848
              ],
              [
                -36.181816311513785,
                -7.783419115269875
              ],
              [
                -36.14291668043133,
                -7.781163601044054
              ],
              [
                -36.126374811472154,
                -7.783840600406574
              ],
              [
                -36.11682739277536,
                -7.785385074995874
              ],
              [
                -36.10416503135609,
                -7.779963595719205
              ],
              [
                -36.10207201979235,
                -7.779067624128831
              ],
              [
                -36.07027881550049,
                -7.780495575596427
              ],
              [
                -36.0671148553253,
                -7.782475053672474
              ],
              [
                -36.064305380580954,
                -7.786264015849635
              ],
              [
                -36.06291185862055,
                -7.788142504072007
              ],
              [
                -36.053426440761406,
                -7.800931385657693
              ],
              [
                -36.048011995301614,
                -7.797021419121203
              ],
              [
                -36.04278704303835,
                -7.793248456663572
              ],
              [
                -36.03478711489923,
                -7.79564094579403
              ],
              [
                -36.02111323653805,
                -7.809825821207705
              ],
              [
                -36.00277789580473,
                -7.812586786510244
              ],
              [
                -35.99757994017039,
                -7.812905785022669
              ],
              [
                -35.9923154952409,
                -7.800114365206748
              ],
              [
                -35.98362907457412,
                -7.802151863586782
              ],
              [
                -35.98089310661788,
                -7.802794365455979
              ],
              [
                -35.97395215231894,
                -7.794419909778854
              ],
              [
                -35.969501202372214,
                -7.789048930478804
              ],
              [
                -35.95856129318458,
                -7.786865931234231
              ],
              [
                -35.94728990679247,
                -7.788835912176177
              ],
              [
                -35.94288642071039,
                -7.789605926773905
              ],
              [
                -35.93764848885044,
                -7.78681691764682
              ],
              [
                -35.93241053299194,
                -7.784027962523703
              ],
              [
                -35.903950762424735,
                -7.791835349555683
              ],
              [
                -35.883358962668986,
                -7.774365979956506
              ],
              [
                -35.88273550144907,
                -7.76889802940716
              ],
              [
                -35.88232047195666,
                -7.765259580656799
              ],
              [
                -35.8812310100275,
                -7.755715155770414
              ],
              [
                -35.87163607919816,
                -7.749128186219231
              ],
              [
                -35.86181869320383,
                -7.750631678964144
              ],
              [
                -35.841113379196955,
                -7.746633688679568
              ],
              [
                -35.838470901222045,
                -7.747564679362277
              ],
              [
                -35.8280174633137,
                -7.75124862449371
              ],
              [
                -35.81532359247965,
                -7.747138176183948
              ],
              [
                -35.78945484231824,
                -7.740038197433624
              ],
              [
                -35.78477888513757,
                -7.738988214156596
              ],
              [
                -35.78010292495882,
                -7.737938226881202
              ],
              [
                -35.771388000173374,
                -7.73149924494659
              ],
              [
                -35.761558110035715,
                -7.724235794676325
              ],
              [
                -35.75776109843876,
                -7.723877804631492
              ],
              [
                -35.75133566665955,
                -7.723272310554496
              ],
              [
                -35.74575171498946,
                -7.720459804482259
              ],
              [
                -35.74405775813765,
                -7.716602333567184
              ],
              [
                -35.7426357383714,
                -7.713363888987321
              ],
              [
                -35.73589031717997,
                -7.709180907843161
              ],
              [
                -35.72496293223656,
                -7.708120424980063
              ],
              [
                -35.717655979480455,
                -7.707410886077579
              ],
              [
                -35.711784035497175,
                -7.704483439721741
              ],
              [
                -35.71021207383389,
                -7.70156795550345
              ],
              [
                -35.707716071772566,
                -7.69693798109868
              ],
              [
                -35.69958614502306,
                -7.691455501193325
              ],
              [
                -35.69487171883627,
                -7.693568511013645
              ],
              [
                -35.693231705219304,
                -7.699367930095169
              ],
              [
                -35.68050181252668,
                -7.699643428380444
              ],
              [
                -35.65906102529348,
                -7.697179452732156
              ],
              [
                -35.65770752159042,
                -7.697023428948236
              ],
              [
                -35.652764072514515,
                -7.695110976320861
              ],
              [
                -35.636443704460035,
                -7.68437752473568
              ],
              [
                -35.62831531221147,
                -7.676928086413398
              ],
              [
                -35.621437866282236,
                -7.667367134701144
              ],
              [
                -35.61885140425696,
                -7.663770676147649
              ],
              [
                -35.61003797882042,
                -7.654109756819269
              ],
              [
                -35.597230594263074,
                -7.653924249759688
              ],
              [
                -35.592903619541445,
                -7.65215774348696
              ],
              [
                -35.592900110574185,
                -7.652156266496001
              ],
              [
                -35.588570671876646,
                -7.650388775231436
              ],
              [
                -35.57182781649422,
                -7.654615201425029
              ],
              [
                -35.571674811824,
                -7.654679727801835
              ],
              [
                -35.56033292541995,
                -7.659443167763158
              ],
              [
                -35.54635204923098,
                -7.656935179180771
              ],
              [
                -35.54324008868256,
                -7.656376684280575
              ],
              [
                -35.53231065261284,
                -7.654415702655872
              ],
              [
                -35.526457739029794,
                -7.645449279150327
              ],
              [
                -35.52013627209407,
                -7.643966243183842
              ],
              [
                -35.51402784820448,
                -7.642533283982353
              ],
              [
                -35.52248378080367,
                -7.632051883073406
              ],
              [
                -35.53118770739544,
                -7.621264435764961
              ],
              [
                -35.526512249849375,
                -7.610033044091251
              ],
              [
                -35.52531128080764,
                -7.607147552017138
              ],
              [
                -35.51760381109116,
                -7.606014065296019
              ],
              [
                -35.521450293971874,
                -7.598532636286667
              ],
              [
                -35.51489686425346,
                -7.584684236985693
              ],
              [
                -35.52809672824253,
                -7.58886219665016
              ],
              [
                -35.529426243954646,
                -7.589282183307158
              ],
              [
                -35.53587467658305,
                -7.58695621044472
              ],
              [
                -35.53619670106249,
                -7.579672288282134
              ],
              [
                -35.526662298542334,
                -7.569381854671581
              ],
              [
                -35.51948435538636,
                -7.550116981838071
              ],
              [
                -35.51921937606641,
                -7.5360990965908
              ],
              [
                -35.5106449391378,
                -7.528154646223269
              ],
              [
                -35.5086974617841,
                -7.517088254429185
              ],
              [
                -35.507652970727534,
                -7.516359278441247
              ],
              [
                -35.50145052777658,
                -7.512027302233505
              ],
              [
                -35.505156521834614,
                -7.499088391417149
              ],
              [
                -35.50610452287871,
                -7.49280143591074
              ],
              [
                -35.50705250391931,
                -7.486514507407995
              ],
              [
                -35.5028140525128,
                -7.455678743718716
              ],
              [
                -35.490889182943995,
                -7.450162776840862
              ],
              [
                -35.47896526337466,
                -7.444646816983042
              ],
              [
                -35.44107162926787,
                -7.456572171066807
              ],
              [
                -35.43765862526782,
                -7.46315512238222
              ],
              [
                -35.433914678785726,
                -7.470377072391411
              ],
              [
                -35.423444769287975,
                -7.472992056084376
              ],
              [
                -35.42075880201996,
                -7.473663527817193
              ],
              [
                -35.41480432355345,
                -7.47112553665131
              ],
              [
                -35.40779990917378,
                -7.460831642277827
              ],
              [
                -35.38014668025868,
                -7.448831703619199
              ],
              [
                -35.37543822197936,
                -7.448489224952334
              ],
              [
                -35.372954719463635,
                -7.445606226060137
              ],
              [
                -35.37208375905005,
                -7.444595219462741
              ],
              [
                -35.37202723860714,
                -7.444529725942273
              ],
              [
                -35.36880076541585,
                -7.440783247377684
              ],
              [
                -35.36561428858962,
                -7.441578267774787
              ],
              [
                -35.365541814230106,
                -7.441596739575987
              ],
              [
                -35.36240130999777,
                -7.442381222089631
              ],
              [
                -35.35060242826996,
                -7.439861739630622
              ],
              [
                -35.34810196667034,
                -7.434532275176136
              ],
              [
                -35.34889044845263,
                -7.431896816680385
              ],
              [
                -35.34890594335063,
                -7.431844294108773
              ],
              [
                -35.3518534148374,
                -7.421997884456909
              ],
              [
                -35.341878524930785,
                -7.421874379296399
              ],
              [
                -35.3330026006905,
                -7.422343364215622
              ],
              [
                -35.33240708739939,
                -7.414913435845669
              ],
              [
                -35.33451810845234,
                -7.406404000998634
              ],
              [
                -35.323595700796204,
                -7.410903948421496
              ],
              [
                -35.31559876598689,
                -7.405302507247813
              ],
              [
                -35.3062303413928,
                -7.406916494726608
              ],
              [
                -35.27965857833827,
                -7.387253623210848
              ],
              [
                -35.277551123915366,
                -7.38648461783138
              ],
              [
                -35.27743911895595,
                -7.386443611078049
              ],
              [
                -35.24327392437008,
                -7.373983680996128
              ],
              [
                -35.23199103471095,
                -7.377600688190453
              ],
              [
                -35.23080055117959,
                -7.378001654152935
              ],
              [
                -35.229011554911274,
                -7.378604647081863
              ],
              [
                -35.22865955203524,
                -7.378683170206823
              ],
              [
                -35.22602806738686,
                -7.37927413863374
              ],
              [
                -35.222809619799456,
                -7.380205624932234
              ],
              [
                -35.199578318761965,
                -7.387147563159039
              ],
              [
                -35.19599635427847,
                -7.391153041712601
              ],
              [
                -35.19026640649546,
                -7.397560491608762
              ],
              [
                -35.180274489220274,
                -7.395574491330749
              ],
              [
                -35.17050854797669,
                -7.387877537721152
              ],
              [
                -35.15327473281507,
                -7.392038524343
              ],
              [
                -35.13413937985793,
                -7.397633927178233
              ],
              [
                -35.12912842237292,
                -7.398759445653096
              ],
              [
                -35.12562797247096,
                -7.399545410905487
              ],
              [
                -35.12446646847241,
                -7.400252407446338
              ],
              [
                -35.11935999244315,
                -7.40336239103465
              ],
              [
                -35.09738420300196,
                -7.407898338282648
              ],
              [
                -35.08794928325292,
                -7.403838384960316
              ],
              [
                -35.085428830566165,
                -7.401717912082091
              ],
              [
                -35.079369386016644,
                -7.396621411432784
              ],
              [
                -35.07479792246262,
                -7.397915425875048
              ],
              [
                -35.065249486144005,
                -7.4031573483032
              ],
              [
                -35.048257125198,
                -7.416848748055928
              ],
              [
                -35.047577631958845,
                -7.417396244207179
              ],
              [
                -35.038300716081686,
                -7.421405718645136
              ],
              [
                -35.02902632418993,
                -7.425415160081939
              ],
              [
                -35.007222969556565,
                -7.447935988957235
              ],
              [
                -34.990992643267866,
                -7.459538396864283
              ],
              [
                -34.986701662943695,
                -7.462605857341836
              ],
              [
                -34.98599114578409,
                -7.47042528247267
              ],
              [
                -34.99135258865835,
                -7.487940156646341
              ],
              [
                -34.98577314828468,
                -7.497572054871126
              ],
              [
                -34.984604146424815,
                -7.499590029948179
              ],
              [
                -34.984405155152075,
                -7.501086034880729
              ],
              [
                -34.98419315792684,
                -7.502679534027046
              ],
              [
                -34.98385316453798,
                -7.506111999424131
              ],
              [
                -34.96891027646584,
                -7.520645885868291
              ],
              [
                -34.960248345192575,
                -7.538346217584442
              ],
              [
                -34.950086956103746,
                -7.537216250283367
              ],
              [
                -34.93574157638168,
                -7.529943292950593
              ],
              [
                -34.932133095411174,
                -7.528114315941882
              ],
              [
                -34.928020132223764,
                -7.53015879569012
              ],
              [
                -34.92390818303017,
                -7.53220127445429
              ],
              [
                -34.91456177354847,
                -7.542215684899671
              ],
              [
                -34.9106742966139,
                -7.542470672073332
              ],
              [
                -34.909787809175455,
                -7.537586206381473
              ],
              [
                -34.903220366180484,
                -7.536959205666045
              ],
              [
                -34.900923372168954,
                -7.536739194771668
              ],
              [
                -34.90085786813768,
                -7.537606698806743
              ],
              [
                -34.90028288088814,
                -7.545182162987476
              ],
              [
                -34.8947464260979,
                -7.545182125009744
              ],
              [
                -34.89030846841906,
                -7.539536173846823
              ],
              [
                -34.88776352083493,
                -7.536297222852659
              ],
              [
                -34.8818745502516,
                -7.5404551774636
              ],
              [
                -34.87022065903059,
                -7.548683584467391
              ],
              [
                -34.86314973663849,
                -7.542495638749993
              ],
              [
                -34.861643747250454,
                -7.541178161179688
              ],
              [
                -34.85748277262287,
                -7.537537184243537
              ],
              [
                -34.85315578986348,
                -7.538950167867549
              ],
              [
                -34.83981542777103,
                -7.543309640516959
              ],
              [
                -34.83357448042971,
                -7.548829579994317
              ],
              [
                -34.83243251007018,
                -7.549833052167905
              ],
              [
                -34.831217013331866,
                -7.550900546777748
              ],
              [
                -34.83347096438864,
                -7.551577533993676
              ],
              [
                -34.8363494496433,
                -7.552441544166236
              ],
              [
                -34.83920541010766,
                -7.553299047375305
              ],
              [
                -34.83725691534405,
                -7.565001943614321
              ],
              [
                -34.83423898277817,
                -7.563553949995201
              ],
              [
                -34.829037489497345,
                -7.580830820442086
              ],
              [
                -34.82649600751295,
                -7.583665781000199
              ],
              [
                -34.82395605251629,
                -7.586498792574734
              ],
              [
                -34.82372402352943,
                -7.592149217344779
              ],
              [
                -34.82357052021305,
                -7.595887696421427
              ],
              [
                -34.823512529714186,
                -7.597301673104268
              ],
              [
                -34.823147020674405,
                -7.597794684909087
              ],
              [
                -34.81529810924254,
                -7.608380599829712
              ],
              [
                -34.812644134754805,
                -7.6119350485702
              ],
              [
                -34.81033463047555,
                -7.615027053246929
              ],
              [
                -34.80704615811702,
                -7.624949964755485
              ],
              [
                -34.8080746628197,
                -7.626268453989343
              ],
              [
                -34.812426116023616,
                -7.633532912236375
              ],
              [
                -34.817996067105916,
                -7.642243332858121
              ],
              [
                -34.819837046292385,
                -7.645121799257609
              ],
              [
                -34.82173200892605,
                -7.648085263021194
              ],
              [
                -34.82935592793394,
                -7.671031121784009
              ],
              [
                -34.83340690333715,
                -7.683064008919474
              ],
              [
                -34.8368348694444,
                -7.682756543870672
              ],
              [
                -34.84069634870248,
                -7.682333525058591
              ],
              [
                -34.84575979337339,
                -7.688282485398322
              ],
              [
                -34.84356531665039,
                -7.691878943165143
              ],
              [
                -34.842422813645356,
                -7.693750926428477
              ],
              [
                -34.841651295719956,
                -7.695014410806904
              ],
              [
                -34.83108291689902,
                -7.700128882363045
              ],
              [
                -34.83313486194295,
                -7.711443308809154
              ],
              [
                -34.832034389040565,
                -7.723507700994745
              ],
              [
                -34.83049889596266,
                -7.726243670097196
              ],
              [
                -34.82676693188884,
                -7.726186166814824
              ],
              [
                -34.825699435593066,
                -7.726170178158352
              ],
              [
                -34.824952944994145,
                -7.729390651984098
              ],
              [
                -34.82248795126954,
                -7.743070527330478
              ],
              [
                -34.83706227994355,
                -7.791573660400443
              ],
              [
                -34.836114786076415,
                -7.809644525090803
              ],
              [
                -34.840170253524846,
                -7.810618503362411
              ],
              [
                -34.84410422411989,
                -7.811536031993514
              ],
              [
                -34.84678969026916,
                -7.812162495010758
              ],
              [
                -34.84724966756403,
                -7.815547977457456
              ],
              [
                -34.846229199326025,
                -7.817559955716244
              ],
              [
                -34.84353519381725,
                -7.822870433529214
              ],
              [
                -34.841510732161524,
                -7.826932384758418
              ],
              [
                -34.84223671052219,
                -7.829668372567069
              ],
              [
                -34.843295224922485,
                -7.833659863653351
              ],
              [
                -34.84408971246624,
                -7.836653309471718
              ],
              [
                -34.84026071774156,
                -7.843983758405023
              ],
              [
                -34.8362262680037,
                -7.844483273430559
              ],
              [
                -34.83391876215813,
                -7.861538618283515
              ],
              [
                -34.833473792726764,
                -7.864828598831352
              ],
              [
                -34.8320142807856,
                -7.874396537785639
              ],
              [
                -34.82918979667796,
                -7.878063991560438
              ],
              [
                -34.82642481965045,
                -7.882234942383295
              ],
              [
                -34.823536862626135,
                -7.886556424919457
              ],
              [
                -34.823797820825185,
                -7.889780906522609
              ],
              [
                -34.82358034596783,
                -7.898838323478888
              ],
              [
                -34.82345882216518,
                -7.905335773829598
              ],
              [
                -34.82343382938133,
                -7.906673759194428
              ],
              [
                -34.822387825937426,
                -7.909248249981574
              ],
              [
                -34.819094370727136,
                -7.917352676205875
              ],
              [
                -34.828199737972426,
                -7.958263346628458
              ],
              [
                -34.83540069497097,
                -7.983961150414666
              ],
              [
                -34.8363021693338,
                -7.987166648700731
              ],
              [
                -34.83679767748724,
                -7.988927639124775
              ],
              [
                -34.83691166853021,
                -7.992750579924218
              ],
              [
                -34.83697714326232,
                -7.994939083636454
              ],
              [
                -34.83729765810929,
                -8.005709987561318
              ],
              [
                -34.84015060255178,
                -8.009156968431627
              ],
              [
                -34.852948001442414,
                -8.02461785976068
              ],
              [
                -34.85576998719034,
                -8.028027329923816
              ],
              [
                -34.862585913949474,
                -8.036262773945259
              ],
              [
                -34.86443437830283,
                -8.039976249970206
              ],
              [
                -34.86029290082598,
                -8.043995228998963
              ],
              [
                -34.85904691592751,
                -8.048171191000435
              ],
              [
                -34.879512200707275,
                -8.092600868306137
              ],
              [
                -34.882922675997044,
                -8.099994798486161
              ],
              [
                -34.88633314128231,
                -8.107387719686196
              ],
              [
                -34.912052345248966,
                -8.163117821445077
              ],
              [
                -34.91362685297938,
                -8.168188779645188
              ],
              [
                -34.91620980983055,
                -8.178001695215425
              ],
              [
                -34.91811828280877,
                -8.185252640489981
              ],
              [
                -34.91745181034924,
                -8.192268587618914
              ],
              [
                -34.91739228240172,
                -8.192889594673423
              ],
              [
                -34.91700228894013,
                -8.197981047204866
              ],
              [
                -34.916892317889214,
                -8.201857530541256
              ],
              [
                -34.91604030506277,
                -8.207655486144661
              ],
              [
                -34.91793928281385,
                -8.211468458557299
              ],
              [
                -34.921873242008814,
                -8.21791239881922
              ],
              [
                -34.923132244520495,
                -8.221785374266826
              ],
              [
                -34.919441762136785,
                -8.226725343427246
              ],
              [
                -34.93348860050934,
                -8.245782195233225
              ],
              [
                -34.93961153932951,
                -8.254089139589901
              ],
              [
                -34.943624986769514,
                -8.279427420160737
              ],
              [
                -34.9495414327971,
                -8.2897928422119
              ],
              [
                -34.94990194672775,
                -8.297067300226287
              ],
              [
                -34.9460594590645,
                -8.299018304828381
              ],
              [
                -34.938881544342266,
                -8.30266327445554
              ],
              [
                -34.948765414876476,
                -8.331084037607106
              ],
              [
                -34.95086641595768,
                -8.337127003732498
              ],
              [
                -34.94949640229562,
                -8.339752466989285
              ],
              [
                -34.94801342348554,
                -8.342594468453084
              ],
              [
                -34.942044470328675,
                -8.343051462112523
              ],
              [
                -34.940047966825325,
                -8.351327369420511
              ],
              [
                -34.94318746323944,
                -8.355154853812792
              ],
              [
                -34.94602591970982,
                -8.358579822135376
              ],
              [
                -34.94890989650214,
                -8.357353833074493
              ],
              [
                -34.95115088396472,
                -8.35633932883805
              ],
              [
                -34.95328186238591,
                -8.355375843112538
              ],
              [
                -34.95471133692396,
                -8.354728847339222
              ],
              [
                -34.95511583165919,
                -8.355465371869043
              ],
              [
                -34.958143327731285,
                -8.36098581986942
              ],
              [
                -34.96062981240941,
                -8.365640765250534
              ],
              [
                -34.95579134012924,
                -8.37088623914901
              ],
              [
                -34.95173586542163,
                -8.375283204342816
              ],
              [
                -34.966433711788625,
                -8.408937943602034
              ],
              [
                -34.9719261597681,
                -8.421469843568058
              ],
              [
                -34.97192715375625,
                -8.421473338541455
              ],
              [
                -34.977418079736054,
                -8.43400226756363
              ],
              [
                -34.98350454381155,
                -8.454500603545295
              ],
              [
                -34.98393651570499,
                -8.455955617473787
              ],
              [
                -34.984468013421896,
                -8.457745599853945
              ],
              [
                -34.98511150123176,
                -8.459400070388662
              ],
              [
                -34.98549799491328,
                -8.46039356190372
              ],
              [
                -34.997815352642824,
                -8.485434867395773
              ],
              [
                -35.00043735611164,
                -8.497326804257401
              ],
              [
                -34.99847634146209,
                -8.501549758552631
              ],
              [
                -34.99811237912568,
                -8.502333253113767
              ],
              [
                -34.99604535657216,
                -8.507612722040058
              ],
              [
                -34.99974234260325,
                -8.51297916096829
              ],
              [
                -35.00166031490384,
                -8.515763651697938
              ],
              [
                -35.00348578913906,
                -8.525742082967334
              ],
              [
                -35.00230178816635,
                -8.529059062006851
              ],
              [
                -34.998701324567214,
                -8.53915145998177
              ],
              [
                -35.005884267098125,
                -8.557659814831334
              ],
              [
                -35.004192243945745,
                -8.565562752545992
              ],
              [
                -35.00874623128392,
                -8.567145263874863
              ],
              [
                -35.01547315187859,
                -8.562686784292547
              ],
              [
                -35.01854463417221,
                -8.5636767830504
              ],
              [
                -35.02274107895287,
                -8.565029302890338
              ],
              [
                -35.02997103582619,
                -8.572878256350057
              ],
              [
                -35.031422509772554,
                -8.576356706306772
              ],
              [
                -35.03666393679433,
                -8.588913602315714
              ],
              [
                -35.04255591188305,
                -8.595454581964665
              ],
              [
                -35.04282337894549,
                -8.604507481351494
              ],
              [
                -35.04604334492328,
                -8.605459008554474
              ],
              [
                -35.043908881942855,
                -8.610707464739969
              ],
              [
                -35.066480638406254,
                -8.643896199828156
              ],
              [
                -35.068535624107994,
                -8.649460167039415
              ],
              [
                -35.07059108180223,
                -8.655024137257305
              ],
              [
                -35.07043210109909,
                -8.656395618408329
              ],
              [
                -35.069397600035416,
                -8.665309532899823
              ],
              [
                -35.06863408746554,
                -8.671881487920864
              ],
              [
                -35.07151109872315,
                -8.673822980138976
              ],
              [
                -35.07597555581679,
                -8.676664961641787
              ],
              [
                -35.079128988510114,
                -8.678672936574417
              ],
              [
                -35.08028949135729,
                -8.67941144676949
              ],
              [
                -35.08299046921584,
                -8.686118416631698
              ],
              [
                -35.08507394826627,
                -8.690929840791158
              ],
              [
                -35.080908950456454,
                -8.698805291834613
              ],
              [
                -35.07873848870827,
                -8.702910269978199
              ],
              [
                -35.07835899679656,
                -8.710468225656744
              ],
              [
                -35.08266443673814,
                -8.715191173376489
              ],
              [
                -35.08508644155008,
                -8.717806659980988
              ],
              [
                -35.08652490018455,
                -8.719359659059634
              ],
              [
                -35.08975785985005,
                -8.728835579808395
              ],
              [
                -35.0898563667117,
                -8.729125061631798
              ],
              [
                -35.08902237125889,
                -8.732708036977757
              ],
              [
                -35.088746389763955,
                -8.733893030501143
              ],
              [
                -35.08580587779041,
                -8.74652142551772
              ],
              [
                -35.09694126305319,
                -8.759116852598806
              ],
              [
                -35.09756827076101,
                -8.759826328591478
              ],
              [
                -35.100665252682965,
                -8.763328820875298
              ],
              [
                -35.10458919618248,
                -8.767911287436597
              ],
              [
                -35.10366422612679,
                -8.775517234362885
              ],
              [
                -35.10259969335451,
                -8.784274656202617
              ],
              [
                -35.106853662364344,
                -8.794129578972724
              ],
              [
                -35.1103901426875,
                -8.795983071505663
              ],
              [
                -35.11415260091544,
                -8.797889050821922
              ],
              [
                -35.11900954107267,
                -8.805827500065103
              ],
              [
                -35.13143643095997,
                -8.826138869361294
              ],
              [
                -35.133642894359205,
                -8.840845728728576
              ],
              [
                -35.13144090754642,
                -8.853452641735231
              ],
              [
                -35.12987339111428,
                -8.862384576893557
              ],
              [
                -35.12912740724451,
                -8.866627030246967
              ],
              [
                -35.137223848257584,
                -8.876523460127137
              ],
              [
                -35.137489310569244,
                -8.876847986828228
              ],
              [
                -35.13852380502866,
                -8.879350442241002
              ],
              [
                -35.14214376164076,
                -8.888106400216115
              ],
              [
                -35.14602275993524,
                -8.895653822825931
              ],
              [
                -35.14701522626464,
                -8.89758381166442
              ],
              [
                -35.14702774893163,
                -8.89783833269599
              ],
              [
                -35.147175735057445,
                -8.900774308993617
              ],
              [
                -35.14856619961399,
                -8.904048785720429
              ],
              [
                -35.150138692326706,
                -8.907544246886975
              ],
              [
                -35.152042684379005,
                -8.912951225480377
              ],
              [
                -35.15293668122262,
                -8.910684744861765
              ],
              [
                -35.152970665988484,
                -8.91059871955953
              ],
              [
                -35.15519267205165,
                -8.902222774569235
              ],
              [
                -35.17622195814781,
                -8.888406422910316
              ],
              [
                -35.19974677127197,
                -8.887792926733116
              ],
              [
                -35.20263323909296,
                -8.888950433198893
              ],
              [
                -35.208572703169295,
                -8.891331399763047
              ],
              [
                -35.21058316707546,
                -8.8888509537532
              ],
              [
                -35.21093314627402,
                -8.888418952408765
              ],
              [
                -35.21194667203985,
                -8.88846795289259
              ],
              [
                -35.21959006840255,
                -8.888839919523546
              ],
              [
                -35.235451438634186,
                -8.882979505589628
              ],
              [
                -35.25226878386917,
                -8.886802487246051
              ],
              [
                -35.27707110448567,
                -8.874728586192404
              ],
              [
                -35.28713849305121,
                -8.876123082956095
              ],
              [
                -35.31107226939235,
                -8.870111645064512
              ],
              [
                -35.35027392183859,
                -8.860266265956836
              ],
              [
                -35.38602560668485,
                -8.852720350038608
              ],
              [
                -35.399483995329305,
                -8.849879884568203
              ],
              [
                -35.40755694477614,
                -8.83886648290258
              ],
              [
                -35.408516438223025,
                -8.837556972880913
              ],
              [
                -35.41214839528744,
                -8.836479986334709
              ],
              [
                -35.41619036505324,
                -8.83840648283707
              ],
              [
                -35.43141621974129,
                -8.835576496782059
              ],
              [
                -35.43393471025256,
                -8.83871199161176
              ],
              [
                -35.43537070871882,
                -8.84049997797052
              ],
              [
                -35.45035807471137,
                -8.841007997831932
              ],
              [
                -35.45887497168748,
                -8.83752699709912
              ],
              [
                -35.461251488680965,
                -8.830012587396144
              ],
              [
                -35.46625993726577,
                -8.827836592544438
              ],
              [
                -35.46516145422198,
                -8.825491626791191
              ],
              [
                -35.46184047636677,
                -8.81840017795574
              ],
              [
                -35.4670764223197,
                -8.814568693135316
              ],
              [
                -35.48008729742039,
                -8.81950666293414
              ],
              [
                -35.48081181534872,
                -8.822430142883983
              ],
              [
                -35.48162729413621,
                -8.825721615059933
              ],
              [
                -35.493023700762535,
                -8.82495762371212
              ],
              [
                -35.495257149055455,
                -8.827872628022797
              ],
              [
                -35.49985564836771,
                -8.824534164828346
              ],
              [
                -35.49986912242384,
                -8.822010652401358
              ],
              [
                -35.49988111827649,
                -8.819737660031903
              ],
              [
                -35.503764089338034,
                -8.821869146823554
              ],
              [
                -35.51009103073057,
                -8.817719686389006
              ],
              [
                -35.51754398004817,
                -8.816934201839139
              ],
              [
                -35.52961335949856,
                -8.826750663075716
              ],
              [
                -35.5309533644474,
                -8.827840646775877
              ],
              [
                -35.531289349093946,
                -8.827050659184078
              ],
              [
                -35.53466131147345,
                -8.819121226507283
              ],
              [
                -35.53996826076382,
                -8.821155700277522
              ],
              [
                -35.547829718910535,
                -8.827893160801871
              ],
              [
                -35.563189056274425,
                -8.831217640195925
              ],
              [
                -35.56757001294216,
                -8.840176066576493
              ],
              [
                -35.56843598158542,
                -8.841946565610096
              ],
              [
                -35.57390095779806,
                -8.84358707159448
              ],
              [
                -35.57413145365623,
                -8.843656071257957
              ],
              [
                -35.58203788718598,
                -8.846027037685346
              ],
              [
                -35.589750309673384,
                -8.851622992991993
              ],
              [
                -35.59010778800509,
                -8.85212950237894
              ],
              [
                -35.59623524513288,
                -8.86080194553308
              ],
              [
                -35.60282669539059,
                -8.862201441372978
              ],
              [
                -35.611205121185414,
                -8.863980913813954
              ],
              [
                -35.613946072929565,
                -8.86456293867022
              ],
              [
                -35.61743254260367,
                -8.867832884378194
              ],
              [
                -35.629593418870414,
                -8.879238813651334
              ],
              [
                -35.64043334152439,
                -8.882089301964058
              ],
              [
                -35.64047782812409,
                -8.882087792014111
              ],
              [
                -35.65149875094188,
                -8.881743808183959
              ],
              [
                -35.659245674230405,
                -8.88150281273368
              ],
              [
                -35.66808556442566,
                -8.892977757696734
              ],
              [
                -35.66950254602938,
                -8.894817238709722
              ],
              [
                -35.67826100056447,
                -8.896447727689196
              ],
              [
                -35.68701940210662,
                -8.898078232673525
              ],
              [
                -35.68783388730455,
                -8.898595742381323
              ],
              [
                -35.69973828426889,
                -8.906163676246752
              ],
              [
                -35.71321115730125,
                -8.904366178802405
              ],
              [
                -35.721719075301095,
                -8.908080152507582
              ],
              [
                -35.74791934442112,
                -8.916723137549427
              ],
              [
                -35.754178801962084,
                -8.912188663973145
              ],
              [
                -35.760438740501,
                -8.907655186387723
              ],
              [
                -35.76050926487518,
                -8.907643693537638
              ],
              [
                -35.77275912412902,
                -8.905717228037062
              ],
              [
                -35.777711608856066,
                -8.898459270330559
              ],
              [
                -35.78249105283469,
                -8.897251297797496
              ],
              [
                -35.7795615743074,
                -8.890333837627656
              ],
              [
                -35.77890209261714,
                -8.888776380073525
              ],
              [
                -35.780031581092324,
                -8.886886883632522
              ],
              [
                -35.78319606520556,
                -8.881595430206644
              ],
              [
                -35.78125407291902,
                -8.877929957811684
              ],
              [
                -35.77643814428159,
                -8.86883852481454
              ],
              [
                -35.77550216016355,
                -8.858029616448883
              ],
              [
                -35.78084707579751,
                -8.860538589697112
              ],
              [
                -35.78712054684553,
                -8.850704649035125
              ],
              [
                -35.79014201362505,
                -8.852985635034635
              ],
              [
                -35.795050964996115,
                -8.847706701026615
              ],
              [
                -35.807420858829644,
                -8.859961614122671
              ],
              [
                -35.82250173555738,
                -8.856989159102293
              ],
              [
                -35.8242146981386,
                -8.864976594956515
              ],
              [
                -35.825195203302535,
                -8.86954906153049
              ],
              [
                -35.83108513080164,
                -8.868911559549858
              ],
              [
                -35.8365780998423,
                -8.8683170828936
              ],
              [
                -35.84452050801309,
                -8.87207203981509
              ],
              [
                -35.84866750026404,
                -8.87138556670395
              ],
              [
                -35.861457367857234,
                -8.857942676456938
              ],
              [
                -35.88647766946679,
                -8.862640145913161
              ],
              [
                -35.88987063674964,
                -8.859413664728184
              ],
              [
                -35.895731075877265,
                -8.853840701770686
              ],
              [
                -35.8998910562294,
                -8.860919658805413
              ],
              [
                -35.90051804664995,
                -8.861987147120757
              ],
              [
                -35.90128554380955,
                -8.861909679383494
              ],
              [
                -35.910831938728194,
                -8.860949692062015
              ],
              [
                -35.917986882088854,
                -8.873763094533462
              ],
              [
                -35.92324079453574,
                -8.883172530611208
              ],
              [
                -35.93265474193947,
                -8.884173525090874
              ],
              [
                -35.94206813235638,
                -8.885174529574032
              ],
              [
                -35.95021555150121,
                -8.891477990131733
              ],
              [
                -35.96015099031954,
                -8.893517973085023
              ],
              [
                -35.96053598378647,
                -8.89359698081242
              ],
              [
                -35.962830441852105,
                -8.898466416330068
              ],
              [
                -35.96471744134448,
                -8.902470398158394
              ],
              [
                -35.973280849376195,
                -8.901266435897732
              ],
              [
                -35.97366833893847,
                -8.901212413651702
              ],
              [
                -35.988272235343324,
                -8.903755408833833
              ],
              [
                -35.994111149099815,
                -8.906848890134805
              ],
              [
                -36.004921054828,
                -8.9125763685284
              ],
              [
                -36.01218301811194,
                -8.911919355933389
              ],
              [
                -36.03033985798308,
                -8.908406404831467
              ],
              [
                -36.06347803337667,
                -8.914917372794154
              ],
              [
                -36.08635180532805,
                -8.943653176120081
              ],
              [
                -36.12645544044755,
                -8.955590643800754
              ],
              [
                -36.12874942834664,
                -8.968472016988056
              ],
              [
                -36.12637392170583,
                -8.980750418698547
              ],
              [
                -36.12042547072963,
                -8.983438923815836
              ],
              [
                -36.111721552394755,
                -8.987373391936808
              ],
              [
                -36.103866601996955,
                -8.998503265610863
              ],
              [
                -36.1090025643289,
                -9.008014215216285
              ],
              [
                -36.11330804195656,
                -9.008786219113231
              ],
              [
                -36.114602507539786,
                -9.01300717189576
              ],
              [
                -36.11103255080391,
                -9.017248649207326
              ],
              [
                -36.12365591188763,
                -9.030058046224662
              ],
              [
                -36.138479785687956,
                -9.035662544460484
              ],
              [
                -36.140442266111954,
                -9.03782700462307
              ],
              [
                -36.141317732379065,
                -9.038792505005105
              ],
              [
                -36.151264665910425,
                -9.04226949622676
              ],
              [
                -36.15431363298194,
                -9.0395485067857
              ],
              [
                -36.16366656752767,
                -9.040065508157127
              ],
              [
                -36.17467597137131,
                -9.043728500916478
              ],
              [
                -36.17900041236102,
                -9.04060801466695
              ],
              [
                -36.188487338443906,
                -9.045869475840846
              ],
              [
                -36.19470677581744,
                -9.044536010597938
              ],
              [
                -36.19528726671912,
                -9.044411499069007
              ],
              [
                -36.196173251608535,
                -9.04680098656727
              ],
              [
                -36.1968192402365,
                -9.048542991808837
              ],
              [
                -36.20393216301973,
                -9.067748316182415
              ],
              [
                -36.20988362109967,
                -9.072716805494691
              ],
              [
                -36.21297808028604,
                -9.072760286932485
              ],
              [
                -36.22146002305358,
                -9.07287932361696
              ],
              [
                -36.221816502203204,
                -9.081315741409062
              ],
              [
                -36.22201497916391,
                -9.086007222707655
              ],
              [
                -36.236003878119625,
                -9.103065102848884
              ],
              [
                -36.24300628340848,
                -9.099631627387092
              ],
              [
                -36.245069783813626,
                -9.098619649976671
              ],
              [
                -36.26639159848313,
                -9.101956119648232
              ],
              [
                -36.26573758154522,
                -9.111620044223091
              ],
              [
                -36.2602336310152,
                -9.11809898676881
              ],
              [
                -36.26309209654111,
                -9.125581955183586
              ],
              [
                -36.25726713051603,
                -9.137936831564527
              ],
              [
                -36.24853521654612,
                -9.141569830934062
              ],
              [
                -36.246252727159685,
                -9.142519821617423
              ],
              [
                -36.24178276914048,
                -9.147038767066377
              ],
              [
                -36.2405052939955,
                -9.152076750435638
              ],
              [
                -36.2386067791766,
                -9.159566691521382
              ],
              [
                -36.231012344695806,
                -9.16354414527866
              ],
              [
                -36.22461839402171,
                -9.166891613930755
              ],
              [
                -36.2244979077144,
                -9.170589074586442
              ],
              [
                -36.237402302349395,
                -9.174443571841612
              ],
              [
                -36.24741669072524,
                -9.17743505208008
              ],
              [
                -36.25266764177985,
                -9.172883574580489
              ],
              [
                -36.252050639029115,
                -9.180821537433786
              ],
              [
                -36.25553564508101,
                -9.18155502699657
              ],
              [
                -36.26107258371376,
                -9.176853551898217
              ],
              [
                -36.25968661109399,
                -9.170378594060503
              ],
              [
                -36.270644499558095,
                -9.170613601196704
              ],
              [
                -36.27372046139998,
                -9.165776657898508
              ],
              [
                -36.2853083718841,
                -9.169655618799323
              ],
              [
                -36.28732935477484,
                -9.172900629872405
              ],
              [
                -36.294526785025326,
                -9.172017107141372
              ],
              [
                -36.2967502650931,
                -9.176357602046265
              ],
              [
                -36.300067231265345,
                -9.175353100719079
              ],
              [
                -36.30656868099538,
                -9.17639659966063
              ],
              [
                -36.30947363569174,
                -9.17231812140573
              ],
              [
                -36.311876116550266,
                -9.179491584874647
              ],
              [
                -36.321072055057776,
                -9.18495303558313
              ],
              [
                -36.324705993061805,
                -9.194081998288498
              ],
              [
                -36.327196988952586,
                -9.200341954838038
              ],
              [
                -36.3344774189395,
                -9.204385403652573
              ],
              [
                -36.33965684990281,
                -9.20611492419298
              ],
              [
                -36.340914328078064,
                -9.205627891050462
              ],
              [
                -36.35230977198757,
                -9.201216939047644
              ],
              [
                -36.349670267398636,
                -9.210151367568109
              ],
              [
                -36.34844127057306,
                -9.214310349761558
              ],
              [
                -36.35315721331131,
                -9.219698304022735
              ],
              [
                -36.357898172506886,
                -9.217863815325309
              ],
              [
                -36.365258637511936,
                -9.22220229700561
              ],
              [
                -36.37542802284782,
                -9.21627384644423
              ],
              [
                -36.38959440731656,
                -9.224450807131458
              ],
              [
                -36.402502275607844,
                -9.222339853006753
              ],
              [
                -36.40594124416602,
                -9.221939322201186
              ],
              [
                -36.408008734865355,
                -9.22169834992659
              ],
              [
                -36.408761735347774,
                -9.222530356282707
              ],
              [
                -36.41085172248285,
                -9.224838816625244
              ],
              [
                -36.41485817548416,
                -9.229264305613443
              ],
              [
                -36.419360131260014,
                -9.229147806622475
              ],
              [
                -36.42350259414157,
                -9.22592331095201
              ],
              [
                -36.423913082760734,
                -9.225603814759008
              ],
              [
                -36.42285610383735,
                -9.215238394674088
              ],
              [
                -36.43656447934292,
                -9.211682954268595
              ],
              [
                -36.440218435305184,
                -9.218493400788372
              ],
              [
                -36.45377083919989,
                -9.225752845972325
              ],
              [
                -36.45322382237093,
                -9.231920799557043
              ],
              [
                -36.457544791667964,
                -9.23300628126383
              ],
              [
                -36.458548297515534,
                -9.234261288639852
              ],
              [
                -36.46226322892987,
                -9.238908245711217
              ],
              [
                -36.47366363232363,
                -9.242699725351313
              ],
              [
                -36.47849159345663,
                -9.240164766056507
              ],
              [
                -36.48080410188262,
                -9.238950768409028
              ],
              [
                -36.486997526467206,
                -9.24106726900853
              ],
              [
                -36.487482035999626,
                -9.243355744058968
              ],
              [
                -36.489359474924825,
                -9.252231682326483
              ],
              [
                -36.49222995974899,
                -9.253818671907123
              ],
              [
                -36.49100149179542,
                -9.261272101141184
              ],
              [
                -36.499067892568775,
                -9.263434111143631
              ],
              [
                -36.50287185087234,
                -9.270551058585923
              ],
              [
                -36.571398733458516,
                -9.292158461873742
              ],
              [
                -36.572322218434145,
                -9.319575238792343
              ],
              [
                -36.569624714526796,
                -9.318553259037307
              ],
              [
                -36.56776472682886,
                -9.317848757652978
              ],
              [
                -36.5634717569924,
                -9.322356727483157
              ],
              [
                -36.56691573765747,
                -9.331407638056785
              ],
              [
                -36.57564167755207,
                -9.331454158816124
              ],
              [
                -36.58583057255954,
                -9.337660620250789
              ],
              [
                -36.59104802924754,
                -9.335164631022257
              ],
              [
                -36.592639006736235,
                -9.33440314027368
              ],
              [
                -36.6032094206356,
                -9.340300611424906
              ],
              [
                -36.60378591817647,
                -9.340622111525947
              ],
              [
                -36.6041934003661,
                -9.339739096595707
              ],
              [
                -36.60728337505664,
                -9.333039655234002
              ],
              [
                -36.61240933878012,
                -9.333505681474156
              ],
              [
                -36.61431081651957,
                -9.330599680262127
              ],
              [
                -36.61338833857586,
                -9.323380264108398
              ],
              [
                -36.61907329712444,
                -9.328280703264525
              ],
              [
                -36.622998254013844,
                -9.326179725835015
              ],
              [
                -36.623664733739425,
                -9.328634703855265
              ],
              [
                -36.62439372869163,
                -9.331316174218985
              ],
              [
                -36.63414264608101,
                -9.326499732781418
              ],
              [
                -36.63474065967927,
                -9.32231829101526
              ],
              [
                -36.63496863614772,
                -9.320720783330112
              ],
              [
                -36.639075601260544,
                -9.31984581477915
              ],
              [
                -36.6404616151495,
                -9.319550797303426
              ],
              [
                -36.641006614038396,
                -9.31874431192014
              ],
              [
                -36.64424258562075,
                -9.313963342149837
              ],
              [
                -36.64804652189899,
                -9.314729844879963
              ],
              [
                -36.64929352251756,
                -9.314980359141833
              ],
              [
                -36.65895993439901,
                -9.30832639053607
              ],
              [
                -36.663411411688635,
                -9.304006911395302
              ],
              [
                -36.67848230279707,
                -9.289381071193217
              ],
              [
                -36.69339567918019,
                -9.2749081906763
              ],
              [
                -36.75834162198559,
                -9.270976248706974
              ],
              [
                -36.79670924021457,
                -9.292608123694153
              ],
              [
                -36.83927936203904,
                -9.287792697772911
              ],
              [
                -36.84343884416739,
                -9.282264727442195
              ],
              [
                -36.84759831929447,
                -9.276737773103592
              ],
              [
                -36.867795137372994,
                -9.268106877158973
              ],
              [
                -36.869812104324566,
                -9.269239855471493
              ],
              [
                -36.88035004131432,
                -9.275679821609193
              ],
              [
                -36.87436855881003,
                -9.276548799467832
              ],
              [
                -36.877229549106666,
                -9.277820796520267
              ],
              [
                -36.88936141987776,
                -9.283214756071231
              ],
              [
                -36.893721885282616,
                -9.28293276926958
              ],
              [
                -36.89932085372857,
                -9.282570306231719
              ],
              [
                -36.91540019626834,
                -9.298606170130274
              ],
              [
                -36.92689105384277,
                -9.324988503733417
              ],
              [
                -36.92255159892404,
                -9.329808440288847
              ],
              [
                -36.9205191188869,
                -9.332065923345835
              ],
              [
                -36.9218445911532,
                -9.33933988170262
              ],
              [
                -36.92898252886316,
                -9.345311829354335
              ],
              [
                -36.937453432631756,
                -9.352399784857557
              ],
              [
                -36.936343974508496,
                -9.360790720533187
              ],
              [
                -36.936075948539916,
                -9.361164219464232
              ],
              [
                -36.93001201650051,
                -9.369612674044104
              ],
              [
                -36.9323424973244,
                -9.37213467022454
              ],
              [
                -36.93669694302012,
                -9.376846137809558
              ],
              [
                -36.943585354791544,
                -9.381919098069261
              ],
              [
                -36.9520078025629,
                -9.382120085506392
              ],
              [
                -36.976861092152944,
                -9.361254787212534
              ],
              [
                -36.977735104696805,
                -9.360912283617914
              ],
              [
                -36.98854401349285,
                -9.356676792728662
              ],
              [
                -36.99728291028515,
                -9.353954332482601
              ],
              [
                -37.002962890407076,
                -9.352184871170335
              ],
              [
                -37.02387021949743,
                -9.315293137890094
              ],
              [
                -37.045109518338435,
                -9.315461166581345
              ],
              [
                -37.048077527362196,
                -9.312909208602582
              ],
              [
                -37.0542404525811,
                -9.307607752345506
              ],
              [
                -37.0549049542136,
                -9.30703622127687
              ],
              [
                -37.05679594720377,
                -9.31248268701064
              ],
              [
                -37.05849193545971,
                -9.317368662789995
              ],
              [
                -37.059182925782224,
                -9.313728207869323
              ],
              [
                -37.06116489608228,
                -9.30330026931088
              ],
              [
                -37.056783978902956,
                -9.281366437532242
              ],
              [
                -37.08358623847226,
                -9.251898149799436
              ],
              [
                -37.105851549371316,
                -9.239376288016949
              ],
              [
                -37.110957014449006,
                -9.23994626148765
              ],
              [
                -37.116061981534536,
                -9.24051526296653
              ],
              [
                -37.12533489464051,
                -9.253685204368917
              ],
              [
                -37.12948984611637,
                -9.25958565679542
              ],
              [
                -37.13573226580854,
                -9.263621118238236
              ],
              [
                -37.14197673548252,
                -9.267657602683457
              ],
              [
                -37.163921035244364,
                -9.275289061799283
              ],
              [
                -37.1689574916088,
                -9.272021596129337
              ],
              [
                -37.168598516265284,
                -9.265219637009888
              ],
              [
                -37.16838200508228,
                -9.261122176662962
              ],
              [
                -37.16798649769073,
                -9.253650715562319
              ],
              [
                -37.16786851185477,
                -9.25142123424421
              ],
              [
                -37.15853410685349,
                -9.238685838450252
              ],
              [
                -37.18996286039054,
                -9.220000489603516
              ],
              [
                -37.19812526550095,
                -9.215147554787496
              ],
              [
                -37.20929968198179,
                -9.219696003961534
              ],
              [
                -37.233920448408284,
                -9.23974739902115
              ],
              [
                -37.2335599487862,
                -9.233237424687175
              ],
              [
                -37.23347746793593,
                -9.231742939860442
              ],
              [
                -37.23210797677612,
                -9.229966941948746
              ],
              [
                -37.22837000455032,
                -9.225119994943688
              ],
              [
                -37.231928976003054,
                -9.221908498453509
              ],
              [
                -37.23314195313315,
                -9.206171649093779
              ],
              [
                -37.23862441849663,
                -9.202976645274887
              ],
              [
                -37.238750453447565,
                -9.202903148946048
              ],
              [
                -37.246360361127905,
                -9.189978751389594
              ],
              [
                -37.245607889680926,
                -9.189051288674051
              ],
              [
                -37.2428204306578,
                -9.18561778194015
              ],
              [
                -37.26200575595621,
                -9.159601497801946
              ],
              [
                -37.27186616170881,
                -9.154053537768608
              ],
              [
                -37.28172657547066,
                -9.148505610730465
              ],
              [
                -37.294853016735,
                -9.123312793741825
              ],
              [
                -37.29628798859551,
                -9.120558308829969
              ],
              [
                -37.2983659969934,
                -9.118614835401962
              ],
              [
                -37.3007299431072,
                -9.116403858254554
              ],
              [
                -37.31063485797772,
                -9.107139451247093
              ],
              [
                -37.31051140067584,
                -9.104360437077743
              ],
              [
                -37.31031586853121,
                -9.099958996071852
              ],
              [
                -37.315103352799696,
                -9.093861058463034
              ],
              [
                -37.32414925434968,
                -9.093079563715765
              ],
              [
                -37.327287738786815,
                -9.092808048663722
              ],
              [
                -37.331715212149064,
                -9.086821107879443
              ],
              [
                -37.33614319350625,
                -9.080834166095404
              ],
              [
                -37.40025162397191,
                -9.051554916910606
              ],
              [
                -37.40892254589852,
                -9.047590975772469
              ],
              [
                -37.417593493832634,
                -9.043626983631741
              ],
              [
                -37.42420943780718,
                -9.037687028481175
              ],
              [
                -37.43355736688961,
                -9.02929459832169
              ],
              [
                -37.43955480725369,
                -9.027746136509839
              ],
              [
                -37.44555474360006,
                -9.026197152703526
              ],
              [
                -37.468963563490064,
                -9.02725718127886
              ],
              [
                -37.49329935244919,
                -9.00017838362031
              ],
              [
                -37.4866014549233,
                -8.975151585026284
              ],
              [
                -37.4882259151339,
                -8.970214106724944
              ],
              [
                -37.48985040334218,
                -8.965276654424988
              ],
              [
                -37.50609177974262,
                -8.966342622265975
              ],
              [
                -37.509599772514036,
                -8.966572625746675
              ],
              [
                -37.52065967718475,
                -8.965011656641003
              ],
              [
                -37.52390111895505,
                -8.964554178057702
              ],
              [
                -37.524918111273905,
                -8.964221170498323
              ],
              [
                -37.52969556949523,
                -8.96265618766555
              ],
              [
                -37.54076898388031,
                -8.958063716405817
              ],
              [
                -37.55585234439012,
                -8.969058645428214
              ],
              [
                -37.562013782777,
                -8.973549124273628
              ],
              [
                -37.572708176011226,
                -8.971694159057465
              ],
              [
                -37.58340312225297,
                -8.969839161841202
              ],
              [
                -37.60043494136264,
                -8.975704143351683
              ],
              [
                -37.61163782849595,
                -8.986371099408586
              ],
              [
                -37.614402801508646,
                -8.994494029860904
              ],
              [
                -37.61635629790382,
                -9.000233969496813
              ],
              [
                -37.611589830856495,
                -9.005742921675767
              ],
              [
                -37.62131522169441,
                -9.011391898180321
              ],
              [
                -37.643234027100235,
                -9.017871867728896
              ],
              [
                -37.658747414261136,
                -9.005993486326878
              ],
              [
                -37.66363837829958,
                -9.000420027726511
              ],
              [
                -37.663110856972736,
                -8.999331046423066
              ],
              [
                -37.6604848992065,
                -8.993915549699658
              ],
              [
                -37.66124190511548,
                -8.986852140489004
              ],
              [
                -37.67280431902112,
                -8.992265079469902
              ],
              [
                -37.68561767534879,
                -8.998264052222977
              ],
              [
                -37.689615161786186,
                -8.990011126919198
              ],
              [
                -37.69844458598082,
                -8.992052105735693
              ],
              [
                -37.70175405676248,
                -8.988863638743851
              ],
              [
                -37.702934028217236,
                -8.985796157877463
              ],
              [
                -37.69111567089865,
                -8.97891772166058
              ],
              [
                -37.687398164715354,
                -8.976753716497248
              ],
              [
                -37.684249233856654,
                -8.966708795097606
              ],
              [
                -37.68960570665459,
                -8.955901366269783
              ],
              [
                -37.70123457527784,
                -8.948147937247368
              ],
              [
                -37.70177409685287,
                -8.947788429445813
              ],
              [
                -37.704367560602655,
                -8.93850300563752
              ],
              [
                -37.704528561715925,
                -8.937928522104704
              ],
              [
                -37.704538583660046,
                -8.937893529377023
              ],
              [
                -37.707477054488045,
                -8.927390617047216
              ],
              [
                -37.71825747431005,
                -8.917794196092078
              ],
              [
                -37.7211849599091,
                -8.915188217370849
              ],
              [
                -37.72523291326627,
                -8.907573742340462
              ],
              [
                -37.72625039506619,
                -8.9056597846659
              ],
              [
                -37.7241679342597,
                -8.903680276648197
              ],
              [
                -37.730961879520116,
                -8.899179824711489
              ],
              [
                -37.73388235856029,
                -8.8972458489279
              ],
              [
                -37.73404634778621,
                -8.895437857676031
              ],
              [
                -37.7347933478095,
                -8.887203928292292
              ],
              [
                -37.74119731231391,
                -8.887247904811796
              ],
              [
                -37.745699773798876,
                -8.882728470918204
              ],
              [
                -37.74887124635742,
                -8.869167557823625
              ],
              [
                -37.75990564212273,
                -8.857214142811193
              ],
              [
                -37.76894607647259,
                -8.863707625202544
              ],
              [
                -37.77562901505039,
                -8.862119123244616
              ],
              [
                -37.78076497225434,
                -8.864877609182713
              ],
              [
                -37.79944528708312,
                -8.882851482111892
              ],
              [
                -37.80094778851062,
                -8.884296991621643
              ],
              [
                -37.801877776315116,
                -8.88430048444611
              ],
              [
                -37.808478194152556,
                -8.884324513303811
              ],
              [
                -37.82172256701334,
                -8.890253457877016
              ],
              [
                -37.825559554630445,
                -8.891971459484761
              ],
              [
                -37.8286925217415,
                -8.893372961826955
              ],
              [
                -37.82857751166183,
                -8.896709931655414
              ],
              [
                -37.82635654159133,
                -8.898467892414551
              ],
              [
                -37.82312554732242,
                -8.901405867383732
              ],
              [
                -37.82162707582306,
                -8.90862482778743
              ],
              [
                -37.82150258789852,
                -8.90972481941165
              ],
              [
                -37.82088655826512,
                -8.915173798923385
              ],
              [
                -37.824999544665424,
                -8.91880623842417
              ],
              [
                -37.832544953016416,
                -8.925471197318965
              ],
              [
                -37.83090248213807,
                -8.927990192897555
              ],
              [
                -37.8291204814194,
                -8.929508686858643
              ],
              [
                -37.825824026838006,
                -8.932318173734352
              ],
              [
                -37.82746451572882,
                -8.936250130727997
              ],
              [
                -37.83027547485141,
                -8.946038085852518
              ],
              [
                -37.833919414884136,
                -8.959834451688938
              ],
              [
                -37.838700860817,
                -8.977969331073133
              ],
              [
                -37.845484805431404,
                -8.983991294192805
              ],
              [
                -37.846031289164294,
                -8.984476269062323
              ],
              [
                -37.849018757075704,
                -8.990642232599225
              ],
              [
                -37.8500452750481,
                -8.992761722660953
              ],
              [
                -37.851877224675206,
                -8.997281173479971
              ],
              [
                -37.86377012448239,
                -9.008423625009824
              ],
              [
                -37.86686507999039,
                -9.009742627001707
              ],
              [
                -37.87919995744131,
                -9.015000599075234
              ],
              [
                -37.876640016895024,
                -9.020402539229732
              ],
              [
                -37.87483200222071,
                -9.024218025967986
              ],
              [
                -37.88056995787852,
                -9.039938919582434
              ],
              [
                -37.88921438592636,
                -9.034477466534453
              ],
              [
                -37.88935339878562,
                -9.03438943632313
              ],
              [
                -37.90452974106721,
                -9.03981991282337
              ],
              [
                -37.90746218492694,
                -9.051808333838194
              ],
              [
                -37.93843592521564,
                -9.082534131021784
              ],
              [
                -37.94009488380813,
                -9.08654411160118
              ],
              [
                -37.942278374578656,
                -9.091821086206586
              ],
              [
                -37.94664581772187,
                -9.094373052236259
              ],
              [
                -37.95123580078357,
                -9.097055539503303
              ],
              [
                -37.95380523573893,
                -9.104867002550865
              ],
              [
                -37.954878745185816,
                -9.108128963190012
              ],
              [
                -37.95895768934421,
                -9.116470910714556
              ],
              [
                -37.96016118603113,
                -9.117230395175715
              ],
              [
                -37.963587153826666,
                -9.119392378256835
              ],
              [
                -37.97069158604518,
                -9.123875850473425
              ],
              [
                -37.975522530072276,
                -9.13797223578752
              ],
              [
                -37.978946495389266,
                -9.147961178464803
              ],
              [
                -37.98382445466889,
                -9.150894691187512
              ],
              [
                -37.99058641229209,
                -9.154960644249083
              ],
              [
                -37.999068817685384,
                -9.15183318061534
              ],
              [
                -38.0106162055115,
                -9.155610137376737
              ],
              [
                -38.02539658004056,
                -9.155097188209472
              ],
              [
                -38.0289890591916,
                -9.162661121211535
              ],
              [
                -38.02988153153955,
                -9.164540102048251
              ],
              [
                -38.044138926488465,
                -9.16840011480586
              ],
              [
                -38.052681339184275,
                -9.167584623997033
              ],
              [
                -38.06436473510941,
                -9.173184092369485
              ],
              [
                -38.07286015318015,
                -9.171388077827729
              ],
              [
                -38.07885310873871,
                -9.170120591968322
              ],
              [
                -38.091894500046976,
                -9.172307602077794
              ],
              [
                -38.09197947624064,
                -9.172369615696745
              ],
              [
                -38.10218542442842,
                -9.1797885531565
              ],
              [
                -38.11884274423274,
                -9.201402912135132
              ],
              [
                -38.14351900763943,
                -9.217796331762624
              ],
              [
                -38.15591487514761,
                -9.242143641617947
              ],
              [
                -38.15666335225437,
                -9.25172208214702
              ],
              [
                -38.15672084422813,
                -9.252257567222777
              ],
              [
                -38.1567618734969,
                -9.25263855743089
              ],
              [
                -38.15796786392721,
                -9.26390697485832
              ],
              [
                -38.17859465110794,
                -9.289208818816505
              ],
              [
                -38.18511010378186,
                -9.292403294392198
              ],
              [
                -38.193007500651696,
                -9.294245276348475
              ],
              [
                -38.20256742455499,
                -9.296474800040825
              ],
              [
                -38.224133229889674,
                -9.316248163223868
              ],
              [
                -38.23060865666793,
                -9.322759104220966
              ],
              [
                -38.23708408244278,
                -9.329271080225675
              ],
              [
                -38.25014400136347,
                -9.290076881510823
              ],
              [
                -38.2871097788414,
                -9.216728926373072
              ],
              [
                -38.28347781385519,
                -9.211408960377257
              ],
              [
                -38.279845824866996,
                -9.206088527392817
              ],
              [
                -38.28720929495711,
                -9.173471760676964
              ],
              [
                -38.31122911661936,
                -9.14745546588655
              ],
              [
                -38.31825804666042,
                -9.140595020558594
              ],
              [
                -38.32100056780976,
                -9.116557187791857
              ],
              [
                -38.30421173129639,
                -9.096378337865541
              ],
              [
                -38.31836658606502,
                -9.09140539627029
              ],
              [
                -38.31986407845272,
                -9.090878906605226
              ],
              [
                -38.32547153708019,
                -9.077593980710246
              ],
              [
                -38.32065809887584,
                -9.069625537420368
              ],
              [
                -38.30701974213178,
                -9.061488104064257
              ],
              [
                -38.29903480764007,
                -9.056724129973102
              ],
              [
                -38.289726896536386,
                -9.046938703026832
              ],
              [
                -38.28932888857068,
                -9.037978738360101
              ],
              [
                -38.29599135295584,
                -9.022489863968985
              ],
              [
                -38.31658117726481,
                -9.000764550117228
              ],
              [
                -38.32024515077276,
                -8.990658628827097
              ],
              [
                -38.34002847610177,
                -8.99007364974394
              ],
              [
                -38.352962358678234,
                -8.99554164617314
              ],
              [
                -38.38550708833356,
                -9.024415939944136
              ],
              [
                -38.40807037882702,
                -9.038102850474175
              ],
              [
                -38.4166327984443,
                -9.036506375432317
              ],
              [
                -38.425195720065794,
                -9.034909381394003
              ],
              [
                -38.45424346942719,
                -9.02193950422073
              ],
              [
                -38.48302276276058,
                -9.001073215428075
              ],
              [
                -38.503115599293594,
                -8.981009356447233
              ],
              [
                -38.51347753119063,
                -8.9595340427923
              ],
              [
                -38.5190649823845,
                -8.947946123172777
              ],
              [
                -38.5180094814914,
                -8.941740645314967
              ],
              [
                -38.51684453215867,
                -8.934892695138132
              ],
              [
                -38.512869558870015,
                -8.929625731581273
              ],
              [
                -38.50889460157876,
                -8.924358781032684
              ],
              [
                -38.499275157599165,
                -8.916973807972875
              ],
              [
                -38.479122849282476,
                -8.901504430279925
              ],
              [
                -38.47600837932636,
                -8.895320945409056
              ],
              [
                -38.47289342437127,
                -8.889136529553861
              ],
              [
                -38.46958046793976,
                -8.865076667666523
              ],
              [
                -38.47451492104188,
                -8.857382249567912
              ],
              [
                -38.47944990213732,
                -8.84968779347036
              ],
              [
                -38.508607667790635,
                -8.832225452582204
              ],
              [
                -38.55107182130875,
                -8.822309070760271
              ],
              [
                -38.56095720086763,
                -8.826750023850382
              ],
              [
                -38.57084361242303,
                -8.831191507951413
              ],
              [
                -38.603442805886985,
                -8.880191668788171
              ],
              [
                -38.60532279550225,
                -8.886529651432127
              ],
              [
                -38.607203243108486,
                -8.892868107080707
              ],
              [
                -38.60878968285328,
                -8.952354656721731
              ],
              [
                -38.62353206149982,
                -8.979335459384348
              ],
              [
                -38.63029399130903,
                -8.98477393942723
              ],
              [
                -38.640323874809155,
                -8.986867441378012
              ],
              [
                -38.67058063178979,
                -8.974454534010688
              ],
              [
                -38.68606802255883,
                -8.95056775674768
              ],
              [
                -38.68893301285901,
                -8.943982793282215
              ],
              [
                -38.691799005147935,
                -8.937397323823273
              ],
              [
                -38.69323150681384,
                -8.918308476774618
              ],
              [
                -38.69748550307025,
                -8.8852617280115
              ],
              [
                -38.70615193166873,
                -8.861859379857213
              ],
              [
                -38.711432899955014,
                -8.857303927612527
              ],
              [
                -38.71671384224274,
                -8.85274898736236
              ],
              [
                -38.73942115538111,
                -8.844335057014204
              ],
              [
                -38.79842671258032,
                -8.79236899473734
              ],
              [
                -38.803859152230935,
                -8.790321494982848
              ],
              [
                -38.80929259987644,
                -8.788273525231697
              ],
              [
                -38.811448081311774,
                -8.788072039728572
              ],
              [
                -38.85455821112008,
                -8.784043100665315
              ],
              [
                -38.86771459026051,
                -8.783473613113621
              ],
              [
                -38.884380973983745,
                -8.789209073066642
              ],
              [
                -38.9245441011028,
                -8.794356090297017
              ],
              [
                -38.9261360954207,
                -8.795259057088352
              ],
              [
                -38.9277275687428,
                -8.796162552875836
              ],
              [
                -38.94330593917036,
                -8.803841507507066
              ],
              [
                -38.95302486444277,
                -8.803107522013262
              ],
              [
                -38.956063324307884,
                -8.800716043562266
              ],
              [
                -38.96468874179996,
                -8.793926105900933
              ],
              [
                -38.96751571552009,
                -8.793640128655356
              ],
              [
                -38.97704867265162,
                -8.792675620689398
              ],
              [
                -38.99168504267762,
                -8.767079332907889
              ],
              [
                -39.016644339908794,
                -8.751266982241479
              ],
              [
                -39.02435077876426,
                -8.744102024471745
              ],
              [
                -39.032058710610904,
                -8.7369360967069
              ],
              [
                -39.06379847944644,
                -8.729320659562894
              ],
              [
                -39.15097623310489,
                -8.70879888235003
              ],
              [
                -39.15408768745858,
                -8.707406392538742
              ],
              [
                -39.15705717771343,
                -8.70423091878615
              ],
              [
                -39.16872108037772,
                -8.691755045900315
              ],
              [
                -39.1765500320367,
                -8.703015967855718
              ],
              [
                -39.185063435790035,
                -8.70571496179532
              ],
              [
                -39.20040228643047,
                -8.710576434094794
              ],
              [
                -39.21048521455942,
                -8.709073949570582
              ],
              [
                -39.21338670085073,
                -8.708641954459898
              ],
              [
                -39.217238155054154,
                -8.708067974281446
              ],
              [
                -39.22347009843542,
                -8.710925422954723
              ],
              [
                -39.23339502876915,
                -8.705363481284087
              ],
              [
                -39.24516392201163,
                -8.681823187193288
              ],
              [
                -39.2462659767006,
                -8.647340910175139
              ],
              [
                -39.241436012516864,
                -8.640867979595964
              ],
              [
                -39.24648297547211,
                -8.627319569482069
              ],
              [
                -39.2535349074411,
                -8.618754155351477
              ],
              [
                -39.25550542667567,
                -8.617379669335346
              ],
              [
                -39.26643032827735,
                -8.609757219786927
              ],
              [
                -39.27225276847672,
                -8.596239342164079
              ],
              [
                -39.2736522795169,
                -8.585297907422351
              ],
              [
                -39.273754772375376,
                -8.584493890467154
              ],
              [
                -39.27430978159463,
                -8.580154448095001
              ],
              [
                -39.282290730112166,
                -8.567705532558456
              ],
              [
                -39.288197156495286,
                -8.562900583535829
              ],
              [
                -39.30302654918745,
                -8.559798618072396
              ],
              [
                -39.34325220714453,
                -8.558158658047148
              ],
              [
                -39.35698558917865,
                -8.546634236873683
              ],
              [
                -39.362686072772966,
                -8.539245290749372
              ],
              [
                -39.37585594228553,
                -8.533577361720642
              ],
              [
                -39.38335337988306,
                -8.533222883198054
              ],
              [
                -39.40782717100782,
                -8.538226356592004
              ],
              [
                -39.41174664956433,
                -8.539975847247392
              ],
              [
                -39.415666586117425,
                -8.541725835901682
              ],
              [
                -39.41800507990127,
                -8.545008329782044
              ],
              [
                -39.42745148810905,
                -8.558268217459887
              ],
              [
                -39.43511341669287,
                -8.562911700192176
              ],
              [
                -39.44022537762055,
                -8.562875697155036
              ],
              [
                -39.451734259408056,
                -8.562794730329525
              ],
              [
                -39.45717370578299,
                -8.568939662953618
              ],
              [
                -39.46022270470733,
                -8.579460109087567
              ],
              [
                -39.46085716519853,
                -8.581649559509517
              ],
              [
                -39.475718535009406,
                -8.592400497866988
              ],
              [
                -39.51823516505498,
                -8.603510964197344
              ],
              [
                -39.5220326272866,
                -8.607829923869579
              ],
              [
                -39.525829115526705,
                -8.612147405558078
              ],
              [
                -39.53707199498764,
                -8.616036396310664
              ],
              [
                -39.554798849486104,
                -8.622168353567504
              ],
              [
                -39.55644882810773,
                -8.626609345385317
              ],
              [
                -39.557130819510796,
                -8.628444810499005
              ],
              [
                -39.56145628340886,
                -8.629332830974995
              ],
              [
                -39.56414776670123,
                -8.62988533140559
              ],
              [
                -39.56502322507389,
                -8.631041812701854
              ],
              [
                -39.569213695776675,
                -8.636577289833818
              ],
              [
                -39.60720386267705,
                -8.655585151307603
              ],
              [
                -39.61627480155864,
                -8.655592663711861
              ],
              [
                -39.625345207453755,
                -8.65560017911749
              ],
              [
                -39.63110167401127,
                -8.656537166597428
              ],
              [
                -39.645606036927454,
                -8.658898178775331
              ],
              [
                -39.653015947059714,
                -8.657932696792363
              ],
              [
                -39.660425399201216,
                -8.656968184801695
              ],
              [
                -39.69075462956414,
                -8.661922187736272
              ],
              [
                -39.691702640812075,
                -8.671020603783319
              ],
              [
                -39.69265061505007,
                -8.68011957183887
              ],
              [
                -39.68349366777219,
                -8.708667835613824
              ],
              [
                -39.67463871571889,
                -8.736276608619274
              ],
              [
                -39.67346822934064,
                -8.74514656042239
              ],
              [
                -39.67488119472826,
                -8.750863033810674
              ],
              [
                -39.67717717610282,
                -8.76014995484649
              ],
              [
                -39.673613184757635,
                -8.772725368232967
              ],
              [
                -39.67185272996579,
                -8.778938312999044
              ],
              [
                -39.67302967213748,
                -8.782800803788119
              ],
              [
                -39.674206191311555,
                -8.786662777583523
              ],
              [
                -39.69146803801733,
                -8.796809705573105
              ],
              [
                -39.69825146852237,
                -8.797977714454579
              ],
              [
                -39.7050358990229,
                -8.799146193336457
              ],
              [
                -39.74739354495754,
                -8.793635772955605
              ],
              [
                -39.78862917429554,
                -8.818712115661565
              ],
              [
                -39.8014065488298,
                -8.818344141545754
              ],
              [
                -39.80993148762417,
                -8.816213180268376
              ],
              [
                -39.82125038709457,
                -8.813383688764253
              ],
              [
                -39.87268096779664,
                -8.822236667180333
              ],
              [
                -39.88986978508029,
                -8.8275501498099
              ],
              [
                -39.894253262816775,
                -8.83130362959292
              ],
              [
                -39.901243172537804,
                -8.84912648515709
              ],
              [
                -39.90137068502694,
                -8.854705980549873
              ],
              [
                -39.90149817151185,
                -8.86028644493977
              ],
              [
                -39.87903581923867,
                -8.901500595553564
              ],
              [
                -39.87357236741595,
                -8.930906880775519
              ],
              [
                -39.889866198698776,
                -8.966346641245307
              ],
              [
                -39.896338639410885,
                -8.972144109302706
              ],
              [
                -39.90281104912274,
                -8.977941058377162
              ],
              [
                -39.91604892453506,
                -8.998136454233595
              ],
              [
                -39.95764802581793,
                -9.048202136804486
              ],
              [
                -39.97884784349077,
                -9.054636105894152
              ],
              [
                -39.9915912186889,
                -9.059121074856145
              ],
              [
                -40.00451011558224,
                -9.059406071508691
              ],
              [
                -40.020172994377184,
                -9.060276575618785
              ],
              [
                -40.03224637417578,
                -9.060947078650747
              ],
              [
                -40.06526260130217,
                -9.062781600900358
              ],
              [
                -40.07079903075725,
                -9.06988055586854
              ],
              [
                -40.076336010203796,
                -9.076978997856736
              ],
              [
                -40.12832952342716,
                -9.109192834921291
              ],
              [
                -40.16462321077076,
                -9.106078866525149
              ],
              [
                -40.16893270043274,
                -9.098566949184073
              ],
              [
                -40.17324217609144,
                -9.091055486840393
              ],
              [
                -40.20860138172561,
                -9.063925744296439
              ],
              [
                -40.22729520909703,
                -9.055661309628185
              ],
              [
                -40.23351019087809,
                -9.05472730754009
              ],
              [
                -40.233599198099824,
                -9.054744295505206
              ],
              [
                -40.24370758677012,
                -9.056625792896186
              ],
              [
                -40.25032654768986,
                -9.060099287318597
              ],
              [
                -40.2713388214763,
                -9.080578678041535
              ],
              [
                -40.28631968978779,
                -9.11691743432932
              ],
              [
                -40.29363009113211,
                -9.151656684771948
              ],
              [
                -40.285430616930896,
                -9.181475457335846
              ],
              [
                -40.307434365229504,
                -9.270989831267237
              ],
              [
                -40.31304481010286,
                -9.278921766847018
              ],
              [
                -40.31865576096585,
                -9.286853730445674
              ],
              [
                -40.33540508033397,
                -9.326980946804179
              ],
              [
                -40.33502957581507,
                -9.340226382177057
              ],
              [
                -40.334653555279544,
                -9.353472784564179
              ],
              [
                -40.35773180777024,
                -9.376783617749126
              ],
              [
                -40.37099372684261,
                -9.378315598379096
              ],
              [
                -40.39090106660132,
                -9.37175316596516
              ],
              [
                -40.394478533337335,
                -9.368590727347312
              ],
              [
                -40.411266892400015,
                -9.353753341118427
              ],
              [
                -40.420507824146014,
                -9.352161335033799
              ],
              [
                -40.43550619808913,
                -9.361763794518595
              ],
              [
                -40.44705904887321,
                -9.387237133670977
              ],
              [
                -40.455309972400315,
                -9.405429022752628
              ],
              [
                -40.467924372337094,
                -9.420104419624236
              ],
              [
                -40.476215792068146,
                -9.418679912412582
              ],
              [
                -40.47880576339245,
                -9.41732044283288
              ],
              [
                -40.489280676423974,
                -9.412159979642153
              ],
              [
                -40.49550613832545,
                -9.409283526688766
              ],
              [
                -40.496952606441035,
                -9.408848529301205
              ],
              [
                -40.5004975893162,
                -9.407782030608068
              ],
              [
                -40.50181357693124,
                -9.406979543717624
              ],
              [
                -40.51033751843908,
                -9.406768062050778
              ],
              [
                -40.52895632138681,
                -9.413673531878002
              ],
              [
                -40.53320731513868,
                -9.417476007061712
              ],
              [
                -40.53745827589096,
                -9.421277959254933
              ],
              [
                -40.54065524345073,
                -9.42905140094004
              ],
              [
                -40.54887365531246,
                -9.449034263518495
              ],
              [
                -40.56190003665821,
                -9.460954719836547
              ],
              [
                -40.57333190330309,
                -9.46653219388092
              ],
              [
                -40.59446323169353,
                -9.467004203546816
              ],
              [
                -40.62278898792014,
                -9.482495601483135
              ],
              [
                -40.68094399830282,
                -9.466817290191313
              ],
              [
                -40.71273727226222,
                -9.450270428470734
              ],
              [
                -40.72181868600292,
                -9.445544483668465
              ],
              [
                -40.74492248123545,
                -9.442942019354524
              ],
              [
                -40.76196632792065,
                -9.448957462268952
              ],
              [
                -40.77632919542472,
                -9.454027481150199
              ],
              [
                -40.771303268097235,
                -9.449368483100356
              ],
              [
                -40.76690930877432,
                -9.445295521541585
              ],
              [
                -40.75920687587568,
                -9.42866611407687
              ],
              [
                -40.76094186952387,
                -9.423560164303193
              ],
              [
                -40.76459184984131,
                -9.41281971871828
              ],
              [
                -40.7520064547015,
                -9.407275753171128
              ],
              [
                -40.74647902327303,
                -9.40484027980056
              ],
              [
                -40.7422900466924,
                -9.400291311911122
              ],
              [
                -40.7421500590168,
                -9.383072910683165
              ],
              [
                -40.744118047821175,
                -9.376855961114478
              ],
              [
                -40.746086042624086,
                -9.370637026562472
              ],
              [
                -40.741404600721395,
                -9.361790598893284
              ],
              [
                -40.73770961846651,
                -9.35480861994429
              ],
              [
                -40.73938061271942,
                -9.35055317107416
              ],
              [
                -40.74101558618236,
                -9.346388199521284
              ],
              [
                -40.73931761290257,
                -9.342300219975698
              ],
              [
                -40.73689864656799,
                -9.33647674109189
              ],
              [
                -40.74197812815496,
                -9.329999797622659
              ],
              [
                -40.755094033130334,
                -9.313274926680533
              ],
              [
                -40.75119857115496,
                -9.307612955140632
              ],
              [
                -40.7470320905963,
                -9.306633466853704
              ],
              [
                -40.74359664164496,
                -9.305825979090763
              ],
              [
                -40.743034131666356,
                -9.304638006005618
              ],
              [
                -40.73902616356493,
                -9.296173554410029
              ],
              [
                -40.729005753720514,
                -9.301013014496862
              ],
              [
                -40.71971834302282,
                -9.305497966868037
              ],
              [
                -40.715506882738126,
                -9.301756982295885
              ],
              [
                -40.70771545221293,
                -9.294835517806852
              ],
              [
                -40.70715044343932,
                -9.286742585136746
              ],
              [
                -40.70702096446698,
                -9.284890082260983
              ],
              [
                -40.70592297326991,
                -9.284443594329744
              ],
              [
                -40.68622865411434,
                -9.27643765844554
              ],
              [
                -40.68025122139242,
                -9.258673259543203
              ],
              [
                -40.68877364904361,
                -9.256596280133765
              ],
              [
                -40.68607965989341,
                -9.243137373788997
              ],
              [
                -40.6861716793108,
                -9.242943882269163
              ],
              [
                -40.690610140136805,
                -9.233677926184647
              ],
              [
                -40.69411012700911,
                -9.233056958203875
              ],
              [
                -40.68032776518356,
                -9.211795091540823
              ],
              [
                -40.68159524307142,
                -9.204350646200892
              ],
              [
                -40.676680279650164,
                -9.192912233228672
              ],
              [
                -40.67632128396924,
                -9.192654213714668
              ],
              [
                -40.670253342064704,
                -9.188298740795267
              ],
              [
                -40.671712344388155,
                -9.17199936320526
              ],
              [
                -40.66702489992447,
                -9.158669462117308
              ],
              [
                -40.67734282117569,
                -9.150840509737602
              ],
              [
                -40.68470578248938,
                -9.137216631959976
              ],
              [
                -40.69609070085713,
                -9.138997119674858
              ],
              [
                -40.707475592237664,
                -9.14077811739394
              ],
              [
                -40.732118411303745,
                -9.123627257327925
              ],
              [
                -40.75000923854801,
                -9.117487835420963
              ],
              [
                -40.75666970197568,
                -9.119430809203894
              ],
              [
                -40.75655021125546,
                -9.11329687708098
              ],
              [
                -40.75638121858711,
                -9.104578928451495
              ],
              [
                -40.76288414739311,
                -9.106491906280487
              ],
              [
                -40.776820556348056,
                -9.100287985824181
              ],
              [
                -40.78315946324876,
                -9.103488460257692
              ],
              [
                -40.78571095526654,
                -9.104776447593098
              ],
              [
                -40.78721693231829,
                -9.101992952072209
              ],
              [
                -40.78907743102625,
                -9.098555482598673
              ],
              [
                -40.79498888208823,
                -9.098303010365616
              ],
              [
                -40.80372182032295,
                -9.097929525844165
              ],
              [
                -40.81157277887513,
                -9.084000108632212
              ],
              [
                -40.819606675763424,
                -9.079993164450007
              ],
              [
                -40.818943702484795,
                -9.071059733846868
              ],
              [
                -40.818768724372326,
                -9.068698239607691
              ],
              [
                -40.820092212258906,
                -9.066614262884318
              ],
              [
                -40.823198168870526,
                -9.061723788080965
              ],
              [
                -40.82387068405197,
                -9.05198986657907
              ],
              [
                -40.8247541765216,
                -9.03920946615824
              ],
              [
                -40.828255663055025,
                -9.035019962726889
              ],
              [
                -40.83175761858491,
                -9.03083001029858
              ],
              [
                -40.83278914104454,
                -9.022720089528308
              ],
              [
                -40.834613145075465,
                -9.008375687306657
              ],
              [
                -40.840501104771334,
                -9.001793714432667
              ],
              [
                -40.845854029401316,
                -8.995811290718862
              ],
              [
                -40.850577008543226,
                -8.984703859168613
              ],
              [
                -40.8509185302249,
                -8.96724947084187
              ],
              [
                -40.85121504193982,
                -8.952098599960452
              ],
              [
                -40.85607351798705,
                -8.949856602889488
              ],
              [
                -40.86093296202918,
                -8.947614119821793
              ],
              [
                -40.86285496129726,
                -8.938081206209848
              ],
              [
                -40.86590244260266,
                -8.922967327822247
              ],
              [
                -40.87396687541396,
                -8.918787367840682
              ],
              [
                -40.88084431067832,
                -8.915222874258653
              ],
              [
                -40.883072800586795,
                -8.90442498005309
              ],
              [
                -40.89464921817812,
                -8.888695067531915
              ],
              [
                -40.89532123230151,
                -8.879853647743095
              ],
              [
                -40.89602373855409,
                -8.870614192854664
              ],
              [
                -40.90187868894942,
                -8.860557780919557
              ],
              [
                -40.91236311549466,
                -8.851127866031778
              ],
              [
                -40.91459161060675,
                -8.849123902630943
              ],
              [
                -40.91777458263667,
                -8.842082926384807
              ],
              [
                -40.92095756866181,
                -8.835043005132752
              ],
              [
                -40.928177980783225,
                -8.837782975520238
              ],
              [
                -40.946109847158816,
                -8.84458744622702
              ],
              [
                -40.958401750363144,
                -8.841282991059785
              ],
              [
                -40.96052523923822,
                -8.830355565678312
              ],
              [
                -40.9657606720147,
                -8.82824106201629
              ],
              [
                -40.97871010058973,
                -8.823012638303526
              ],
              [
                -40.98582053442421,
                -8.827028599428061
              ],
              [
                -40.98937749532662,
                -8.829037617488131
              ],
              [
                -40.995051946648964,
                -8.828692580542171
              ],
              [
                -40.99996742135139,
                -8.828393614519758
              ],
              [
                -41.00505386574206,
                -8.831987567704974
              ],
              [
                -41.02086022590539,
                -8.84315554606515
              ],
              [
                -41.02589966255586,
                -8.838854574903399
              ],
              [
                -41.030939152207566,
                -8.834553591739907
              ],
              [
                -41.02457719606665,
                -8.807316775079366
              ],
              [
                -41.03585614030569,
                -8.785471964062593
              ],
              [
                -41.04193960169678,
                -8.783616964343619
              ],
              [
                -41.04812104301895,
                -8.785042464153333
              ],
              [
                -41.054445467103264,
                -8.786500943869266
              ],
              [
                -41.05817295841194,
                -8.784659488745378
              ],
              [
                -41.06514337589341,
                -8.781215505304896
              ],
              [
                -41.070983862041906,
                -8.782713500264913
              ],
              [
                -41.07274782475585,
                -8.784103975007332
              ],
              [
                -41.078158267800994,
                -8.788370973669663
              ],
              [
                -41.08173873794305,
                -8.783923996106035
              ],
              [
                -41.101378608319955,
                -8.781560051317005
              ],
              [
                -41.10404059399481,
                -8.774319582898464
              ],
              [
                -41.105575074159475,
                -8.77014512136811
              ],
              [
                -41.10906801595575,
                -8.769836107004629
              ],
              [
                -41.110774524688516,
                -8.769685123758522
              ],
              [
                -41.11515549256676,
                -8.763730657789573
              ],
              [
                -41.114614003509494,
                -8.762345666202673
              ],
              [
                -41.11327899116253,
                -8.758928683429003
              ],
              [
                -41.12033095184462,
                -8.754625733214148
              ],
              [
                -41.12418791459191,
                -8.752271765882593
              ],
              [
                -41.12624891674956,
                -8.746633298376942
              ],
              [
                -41.12831042490073,
                -8.74099433287694
              ],
              [
                -41.12646442875322,
                -8.728547436459598
              ],
              [
                -41.1219009673467,
                -8.724428951595264
              ],
              [
                -41.12133449263363,
                -8.723917953714217
              ],
              [
                -41.121602485366786,
                -8.722904969251664
              ],
              [
                -41.12281947361488,
                -8.718302526498118
              ],
              [
                -41.12025548450684,
                -8.716026023357674
              ],
              [
                -41.11154757165088,
                -8.708292057461088
              ],
              [
                -41.113255560832805,
                -8.703597104860796
              ],
              [
                -41.16244463664483,
                -8.709568580788726
              ],
              [
                -41.172051581276925,
                -8.70680561297561
              ],
              [
                -41.17464153086511,
                -8.709403593844096
              ],
              [
                -41.176023025843854,
                -8.710789106242359
              ],
              [
                -41.17928900507942,
                -8.70896163754079
              ],
              [
                -41.182054997260444,
                -8.7074136233458
              ],
              [
                -41.18524595499199,
                -8.708778643653485
              ],
              [
                -41.187276455003925,
                -8.709647629392911
              ],
              [
                -41.189140927925976,
                -8.708982646981354
              ],
              [
                -41.194151371407024,
                -8.707197154674883
              ],
              [
                -41.20452128407091,
                -8.70907811527422
              ],
              [
                -41.226597618563396,
                -8.704302711039787
              ],
              [
                -41.23945550606237,
                -8.710473673305389
              ],
              [
                -41.23902499273353,
                -8.713477628342254
              ],
              [
                -41.23818450103963,
                -8.719343582456608
              ],
              [
                -41.252206374205514,
                -8.7199596147263
              ],
              [
                -41.260232818192065,
                -8.720312123036194
              ],
              [
                -41.26615075988594,
                -8.726817072193299
              ],
              [
                -41.26959923507383,
                -8.730608058397072
              ],
              [
                -41.28115013269165,
                -8.73569302926342
              ],
              [
                -41.29459200096273,
                -8.735139532385034
              ],
              [
                -41.30206295366327,
                -8.730437061378167
              ],
              [
                -41.30953491036011,
                -8.725735116364786
              ],
              [
                -41.31465986087084,
                -8.726054637090007
              ],
              [
                -41.33397067402893,
                -8.727259123363561
              ],
              [
                -41.34833208795851,
                -8.715256210345284
              ],
              [
                -41.357936490023796,
                -8.707229271199616
              ],
              [
                -41.336602679923764,
                -8.707964761089942
              ],
              [
                -41.30947690875082,
                -8.691358864589496
              ],
              [
                -41.28715215205432,
                -8.660582565537737
              ],
              [
                -41.25346539776551,
                -8.661789036200293
              ],
              [
                -41.23910253006064,
                -8.655440549845462
              ],
              [
                -41.236808057642904,
                -8.652130588386052
              ],
              [
                -41.233397609207316,
                -8.647210098414062
              ],
              [
                -41.2253426801182,
                -8.646193111922846
              ],
              [
                -41.21697175674076,
                -8.645135107424005
              ],
              [
                -41.21161828679387,
                -8.639086149700232
              ],
              [
                -41.20626634583067,
                -8.63303816698347
              ],
              [
                -41.207193331761445,
                -8.613787327226019
              ],
              [
                -41.207383349999574,
                -8.609838332794576
              ],
              [
                -41.21158082419505,
                -8.602025900995491
              ],
              [
                -41.2024814069876,
                -8.598880423856162
              ],
              [
                -41.19817244293173,
                -8.592076957636904
              ],
              [
                -41.18625005099684,
                -8.592589439496052
              ],
              [
                -41.17952561044257,
                -8.592878435957532
              ],
              [
                -41.17857862194739,
                -8.589251987136153
              ],
              [
                -41.17824410401006,
                -8.587971960023754
              ],
              [
                -41.17391267279743,
                -8.585654500539794
              ],
              [
                -41.16877668279039,
                -8.582906998381908
              ],
              [
                -41.16301076468538,
                -8.570250079949107
              ],
              [
                -41.151800366029,
                -8.545641277424405
              ],
              [
                -41.14826492254385,
                -8.533508854449106
              ],
              [
                -41.13423653527773,
                -8.533149325668667
              ],
              [
                -41.13212303294936,
                -8.535401805422588
              ],
              [
                -41.12715356579674,
                -8.540699769506986
              ],
              [
                -41.111073246711484,
                -8.533246822897818
              ],
              [
                -41.10674226763456,
                -8.526629858479012
              ],
              [
                -41.1036938008439,
                -8.521972892158113
              ],
              [
                -41.10169732420403,
                -8.521448387041763
              ],
              [
                -41.0975713570817,
                -8.5203649189321
              ],
              [
                -41.090111422878294,
                -8.512226454544919
              ],
              [
                -41.08288847718906,
                -8.49622855912031
              ],
              [
                -41.080719529027554,
                -8.484171126090809
              ],
              [
                -41.0785505488487,
                -8.472113719085579
              ],
              [
                -41.05072226730355,
                -8.480081656245787
              ],
              [
                -41.047420317408246,
                -8.448343348422412
              ],
              [
                -41.045559859626366,
                -8.444656918312655
              ],
              [
                -41.04389085774392,
                -8.44147792871863
              ],
              [
                -41.03861643583127,
                -8.431432480376571
              ],
              [
                -41.020593593991734,
                -8.425227527320523
              ],
              [
                -41.00699117837885,
                -8.420544023438046
              ],
              [
                -41.00472573457399,
                -8.414147074597805
              ],
              [
                -40.99980628527271,
                -8.400252170548567
              ],
              [
                -40.98045145498606,
                -8.398022673591269
              ],
              [
                -40.965195064072596,
                -8.411710045219221
              ],
              [
                -40.96511756861849,
                -8.416062547633882
              ],
              [
                -40.96190109064507,
                -8.41730554462273
              ],
              [
                -40.960484079111424,
                -8.417853034331866
              ],
              [
                -40.957497121282145,
                -8.419007506175936
              ],
              [
                -40.95735210880766,
                -8.419749482668005
              ],
              [
                -40.95670511614169,
                -8.423070493017486
              ],
              [
                -40.93770228598646,
                -8.434592867779898
              ],
              [
                -40.92634084446976,
                -8.445836264712103
              ],
              [
                -40.91397246564349,
                -8.435783838905582
              ],
              [
                -40.90243457466927,
                -8.422679434033997
              ],
              [
                -40.90168359998515,
                -8.411058533545361
              ],
              [
                -40.901467110973876,
                -8.407708541623053
              ],
              [
                -40.907230029794114,
                -8.398584121139447
              ],
              [
                -40.89818763151875,
                -8.394120650061327
              ],
              [
                -40.89413065773837,
                -8.392118150797787
              ],
              [
                -40.88948421149043,
                -8.386345695326517
              ],
              [
                -40.89228971977357,
                -8.371397280355712
              ],
              [
                -40.89485319191535,
                -8.369437305956048
              ],
              [
                -40.89735366654628,
                -8.367524819152955
              ],
              [
                -40.893298191329784,
                -8.35742339666569
              ],
              [
                -40.87073688068604,
                -8.358078393972557
              ],
              [
                -40.86200498030339,
                -8.364819830939776
              ],
              [
                -40.858844963933954,
                -8.371360257537212
              ],
              [
                -40.85696800218739,
                -8.375244246606105
              ],
              [
                -40.8581940050877,
                -8.379210197966247
              ],
              [
                -40.83702216095766,
                -8.380735159212172
              ],
              [
                -40.82798224632466,
                -8.371347236965972
              ],
              [
                -40.81894181169182,
                -8.361958816754525
              ],
              [
                -40.821892840010484,
                -8.34019945255041
              ],
              [
                -40.82228181738954,
                -8.337331486747392
              ],
              [
                -40.8208263461305,
                -8.334743512205934
              ],
              [
                -40.81801335061813,
                -8.329742037950677
              ],
              [
                -40.818162357380174,
                -8.328642024082301
              ],
              [
                -40.819126343839464,
                -8.321523577706609
              ],
              [
                -40.801674008361886,
                -8.31972560267613
              ],
              [
                -40.79196760053579,
                -8.318725101000437
              ],
              [
                -40.78932012724713,
                -8.313044616868527
              ],
              [
                -40.78605513641898,
                -8.306037181827334
              ],
              [
                -40.78238068888778,
                -8.303524696721933
              ],
              [
                -40.77948918822517,
                -8.301547696444052
              ],
              [
                -40.78068522754344,
                -8.29566273735841
              ],
              [
                -40.78103821616293,
                -8.293923768334981
              ],
              [
                -40.78128169821427,
                -8.292720291314994
              ],
              [
                -40.780699219280216,
                -8.291500278656647
              ],
              [
                -40.77788024863331,
                -8.285597329021535
              ],
              [
                -40.77889923944135,
                -8.278630378658939
              ],
              [
                -40.78240171686708,
                -8.254684038179162
              ],
              [
                -40.77509480978164,
                -8.2514310671832
              ],
              [
                -40.76824833160915,
                -8.248383072122733
              ],
              [
                -40.76364789489676,
                -8.243712607938248
              ],
              [
                -40.74893252752377,
                -8.24164813454572
              ],
              [
                -40.744123057098804,
                -8.239685138460752
              ],
              [
                -40.74024858847217,
                -8.233669170768156
              ],
              [
                -40.73649314266329,
                -8.227838186845673
              ],
              [
                -40.72860870355967,
                -8.224514196897953
              ],
              [
                -40.723686250588784,
                -8.224196230735911
              ],
              [
                -40.70889338291019,
                -8.22324019624751
              ],
              [
                -40.70623140542428,
                -8.221125745246983
              ],
              [
                -40.703461942879144,
                -8.212426284183504
              ],
              [
                -40.70021146278503,
                -8.20221684471577
              ],
              [
                -40.69398052121477,
                -8.20048735868392
              ],
              [
                -40.68403712052286,
                -8.187380942333565
              ],
              [
                -40.67819314678578,
                -8.185530445553944
              ],
              [
                -40.67487316890986,
                -8.184478985226919
              ],
              [
                -40.66863473357596,
                -8.177081494603796
              ],
              [
                -40.66814774648842,
                -8.174026525474872
              ],
              [
                -40.666215787896235,
                -8.161911610222054
              ],
              [
                -40.658115353838475,
                -8.156172657847165
              ],
              [
                -40.65307439931762,
                -8.156498166925692
              ],
              [
                -40.64317345875836,
                -8.157138624323647
              ],
              [
                -40.64262249497697,
                -8.152131681421473
              ],
              [
                -40.64234899755426,
                -8.149643687360465
              ],
              [
                -40.637937040560104,
                -8.14681772504698
              ],
              [
                -40.63263509750344,
                -8.139151755324697
              ],
              [
                -40.61129227220617,
                -8.146301673853632
              ],
              [
                -40.60105885799021,
                -8.149730164576738
              ],
              [
                -40.600795854096454,
                -8.144348675701831
              ],
              [
                -40.60076284099591,
                -8.143664689675262
              ],
              [
                -40.59705787425415,
                -8.141678198877475
              ],
              [
                -40.58940546094916,
                -8.137575741018843
              ],
              [
                -40.590855949694,
                -8.123115830131047
              ],
              [
                -40.59611791296926,
                -8.117621398056311
              ],
              [
                -40.596827895198444,
                -8.116226903897894
              ],
              [
                -40.60346636058641,
                -8.10318598528648
              ],
              [
                -40.59837740022767,
                -8.080459173131203
              ],
              [
                -40.58190308149994,
                -8.044288389470864
              ],
              [
                -40.57866811731038,
                -8.037186472658792
              ],
              [
                -40.5754326641198,
                -8.03008402686179
              ],
              [
                -40.54383148895837,
                -7.964212963347102
              ],
              [
                -40.54406298107168,
                -7.957327506118125
              ],
              [
                -40.54422848602442,
                -7.952387542546679
              ],
              [
                -40.54440148108615,
                -7.947248582446532
              ],
              [
                -40.53785361036287,
                -7.866647653260944
              ],
              [
                -40.543360079994315,
                -7.835174391620517
              ],
              [
                -40.5472970335091,
                -7.828328952404418
              ],
              [
                -40.555461492799665,
                -7.818405021494458
              ],
              [
                -40.56318443858769,
                -7.812233084581496
              ],
              [
                -40.57439734824742,
                -7.806652152319324
              ],
              [
                -40.62826939072102,
                -7.792178271203504
              ],
              [
                -40.6533201862613,
                -7.781822889926119
              ],
              [
                -40.672496531835634,
                -7.76199453623992
              ],
              [
                -40.67317903666071,
                -7.761288044022088
              ],
              [
                -40.677822532805145,
                -7.72469280424046
              ],
              [
                -40.664687182078914,
                -7.687373585808673
              ],
              [
                -40.64737833859358,
                -7.647667858729095
              ],
              [
                -40.64402640815184,
                -7.607345143290137
              ],
              [
                -40.647988876202085,
                -7.599487214596994
              ],
              [
                -40.651952337240566,
                -7.591628277913342
              ],
              [
                -40.6939900335153,
                -7.537598692824731
              ],
              [
                -40.713624422782516,
                -7.4890775683908
              ],
              [
                -40.71287341763162,
                -7.472850701623693
              ],
              [
                -40.712220427100235,
                -7.47171768245359
              ],
              [
                -40.704524005343316,
                -7.458350791858357
              ],
              [
                -40.678689249118165,
                -7.453491792461381
              ],
              [
                -40.67831522634363,
                -7.453421793670544
              ],
              [
                -40.678175721546936,
                -7.453395297751392
              ],
              [
                -40.67100328640754,
                -7.452046297813615
              ],
              [
                -40.65974190222555,
                -7.445475354133091
              ],
              [
                -40.65809389297982,
                -7.444513865888324
              ],
              [
                -40.65693741157294,
                -7.442338856025245
              ],
              [
                -40.656796423985696,
                -7.442074360865792
              ],
              [
                -40.65673641558736,
                -7.441961367652258
              ],
              [
                -40.652355467496484,
                -7.433725442979792
              ],
              [
                -40.651943442626084,
                -7.432950432375463
              ],
              [
                -40.54808086541305,
                -7.392315162360444
              ],
              [
                -40.50097278758812,
                -7.388055143589104
              ],
              [
                -40.42126241782666,
                -7.390050581947278
              ],
              [
                -40.37242034004631,
                -7.384984090951551
              ],
              [
                -40.31895081269919,
                -7.373985121464431
              ],
              [
                -40.290265555047675,
                -7.387191022948866
              ],
              [
                -40.2839775893521,
                -7.387450511925385
              ],
              [
                -40.27903564824866,
                -7.387654983401718
              ],
              [
                -40.271669707639106,
                -7.39172697511838
              ],
              [
                -40.246060382781586,
                -7.433331618597106
              ],
              [
                -40.14950073549862,
                -7.413814198592497
              ],
              [
                -40.07830835145873,
                -7.39942226840945
              ],
              [
                -40.022891829410185,
                -7.398354718690023
              ],
              [
                -40.01463635891656,
                -7.393404763029389
              ],
              [
                -40.006380975425344,
                -7.388454788382179
              ],
              [
                -39.99149057155703,
                -7.392255235991311
              ],
              [
                -39.98438112683262,
                -7.394069720704752
              ],
              [
                -39.972738734530175,
                -7.391323754871991
              ],
              [
                -39.96109682523636,
                -7.388577767050307
              ],
              [
                -39.94369399747352,
                -7.369341397012193
              ],
              [
                -39.91516725364609,
                -7.337808125323025
              ],
              [
                -39.904168873184524,
                -7.338446578771742
              ],
              [
                -39.903026850888175,
                -7.338525100273023
              ],
              [
                -39.90291434984425,
                -7.33853257812736
              ],
              [
                -39.88975448067147,
                -7.339438595849181
              ],
              [
                -39.86374021944328,
                -7.327885144742159
              ],
              [
                -39.86154821397491,
                -7.326911640298667
              ],
              [
                -39.85630878764068,
                -7.325865154980631
              ],
              [
                -39.85106982230512,
                -7.324818656664986
              ],
              [
                -39.84221239288055,
                -7.327609133696808
              ],
              [
                -39.82832002429735,
                -7.33198508683029
              ],
              [
                -39.82165255183446,
                -7.330152078281523
              ],
              [
                -39.814985125375195,
                -7.328319085736525
              ],
              [
                -39.788851330525056,
                -7.331616546233116
              ],
              [
                -39.75739863773347,
                -7.320572136202267
              ],
              [
                -39.68684621172847,
                -7.315187617823931
              ],
              [
                -39.662187952150994,
                -7.310208109790985
              ],
              [
                -39.65532550081206,
                -7.316777576956261
              ],
              [
                -39.64846355047085,
                -7.323347038118627
              ],
              [
                -39.62676921103186,
                -7.332910457020083
              ],
              [
                -39.61952825833326,
                -7.333971432308679
              ],
              [
                -39.6144438123817,
                -7.33471592468433
              ],
              [
                -39.606926893030234,
                -7.335816895452764
              ],
              [
                -39.59794297288225,
                -7.34371384891997
              ],
              [
                -39.56977916501434,
                -7.374758582992597
              ],
              [
                -39.54490038035103,
                -7.390271444487573
              ],
              [
                -39.5173531100199,
                -7.409107295809065
              ],
              [
                -39.508120671505324,
                -7.413947229024717
              ],
              [
                -39.49888923199123,
                -7.418786215243345
              ],
              [
                -39.489968303690794,
                -7.43375758545391
              ],
              [
                -39.489093319036044,
                -7.435226060704396
              ],
              [
                -39.48382036117099,
                -7.440467011050457
              ],
              [
                -39.4785479093022,
                -7.445708480391135
              ],
              [
                -39.447924158793526,
                -7.454328404001545
              ],
              [
                -39.417251911236306,
                -7.466142274497972
              ],
              [
                -39.413513460289884,
                -7.468782278625844
              ],
              [
                -39.40584099397399,
                -7.474198707694814
              ],
              [
                -39.40247752973317,
                -7.47444171116741
              ],
              [
                -39.39629059063685,
                -7.474889722831979
              ],
              [
                -39.3683093112181,
                -7.493962053756379
              ],
              [
                -39.36356183659213,
                -7.500798002465297
              ],
              [
                -39.35881437896464,
                -7.507633927173805
              ],
              [
                -39.35444489895245,
                -7.510916414941146
              ],
              [
                -39.32751163264423,
                -7.531153223859587
              ],
              [
                -39.317182732777745,
                -7.540885664239922
              ],
              [
                -39.31637273284889,
                -7.544474145566054
              ],
              [
                -39.315479243930966,
                -7.548934116264262
              ],
              [
                -39.31326471678217,
                -7.560305504865537
              ],
              [
                -39.31269123963357,
                -7.564107977778071
              ],
              [
                -39.31189323520184,
                -7.569405927253934
              ],
              [
                -39.308111775821956,
                -7.575520405167477
              ],
              [
                -39.29573184223326,
                -7.595539740455626
              ],
              [
                -39.29090988200947,
                -7.598972699701691
              ],
              [
                -39.28460796441895,
                -7.600118693953192
              ],
              [
                -39.277615019432,
                -7.606601664476112
              ],
              [
                -39.28410193314511,
                -7.604571158034671
              ],
              [
                -39.287675398094585,
                -7.60852564418632
              ],
              [
                -39.294155352345214,
                -7.615698096502332
              ],
              [
                -39.29599082685361,
                -7.617730076712849
              ],
              [
                -39.30325477661187,
                -7.628229515670559
              ],
              [
                -39.30266526398121,
                -7.642495872915477
              ],
              [
                -39.30252124936268,
                -7.645975369637978
              ],
              [
                -39.30439223461785,
                -7.647934857438337
              ],
              [
                -39.311061206367256,
                -7.654918299407656
              ],
              [
                -39.30651021856117,
                -7.6646857252678
              ],
              [
                -39.28337188881777,
                -7.663827239743195
              ],
              [
                -39.27792145427257,
                -7.662077739428325
              ],
              [
                -39.27449449273987,
                -7.660977729890693
              ],
              [
                -39.27161251036307,
                -7.660052239488439
              ],
              [
                -39.26842905080082,
                -7.662512228382056
              ],
              [
                -39.26496405500555,
                -7.669418152613776
              ],
              [
                -39.25095369292352,
                -7.680295087332635
              ],
              [
                -39.24784518813346,
                -7.688308994524274
              ],
              [
                -39.247087214060706,
                -7.690263013210224
              ],
              [
                -39.250694667121714,
                -7.699708443171208
              ],
              [
                -39.252170142395755,
                -7.704534382112611
              ],
              [
                -39.24681818425722,
                -7.704927384751031
              ],
              [
                -39.24463669995126,
                -7.705087887748101
              ],
              [
                -39.23442382023392,
                -7.696458925214128
              ],
              [
                -39.23435930983533,
                -7.696404966566827
              ],
              [
                -39.233458813607854,
                -7.696404952826365
              ],
              [
                -39.222359409418296,
                -7.69640495369996
              ],
              [
                -39.2209264243709,
                -7.695700422820187
              ],
              [
                -39.20772753267941,
                -7.689211980779529
              ],
              [
                -39.198307630710545,
                -7.69442942079367
              ],
              [
                -39.19830211875529,
                -7.694432922762822
              ],
              [
                -39.188888179745106,
                -7.699648375791596
              ],
              [
                -39.17062632491733,
                -7.714847771423015
              ],
              [
                -39.146099039353125,
                -7.717843230699047
              ],
              [
                -39.135256650797835,
                -7.722985677074598
              ],
              [
                -39.12346772923458,
                -7.742855013834568
              ],
              [
                -39.10590784681411,
                -7.759998383336972
              ],
              [
                -39.10222689378184,
                -7.770655293110567
              ],
              [
                -39.10164238195925,
                -7.782517719388398
              ],
              [
                -39.10146038345503,
                -7.786210674459835
              ],
              [
                -39.09036045357088,
                -7.808825512163647
              ],
              [
                -39.09119444798786,
                -7.82480188373416
              ],
              [
                -39.09034145240307,
                -7.827144359413513
              ],
              [
                -39.087253470001514,
                -7.835626791074978
              ],
              [
                -39.09020793037225,
                -7.840481262052333
              ],
              [
                -39.090327956164096,
                -7.840683765630536
              ],
              [
                -39.094700373152264,
                -7.848056218877235
              ],
              [
                -39.095704368511626,
                -7.851573666285676
              ],
              [
                -39.090918408684495,
                -7.857785117612002
              ],
              [
                -39.06448917900803,
                -7.839122740805766
              ],
              [
                -39.05893423554331,
                -7.829016809169697
              ],
              [
                -39.053379757064334,
                -7.818910883559431
              ],
              [
                -39.026017503413996,
                -7.824248838642175
              ],
              [
                -39.01749107918884,
                -7.813520906293391
              ],
              [
                -39.01235362851406,
                -7.814899406651099
              ],
              [
                -39.00615815203723,
                -7.830593773375059
              ],
              [
                -39.004206684797346,
                -7.83553724154746
              ],
              [
                -39.00102072587641,
                -7.839727197363351
              ],
              [
                -38.98186886670755,
                -7.845733167210337
              ],
              [
                -38.97671042217,
                -7.843606165924085
              ],
              [
                -38.97425341630104,
                -7.842593154503931
              ],
              [
                -38.965739019386376,
                -7.844590150381386
              ],
              [
                -38.96187252194244,
                -7.842130172683688
              ],
              [
                -38.961292534276005,
                -7.841761158979442
              ],
              [
                -38.959407068070135,
                -7.838822175540127
              ],
              [
                -38.96054056997763,
                -7.836756698035884
              ],
              [
                -38.964554010319226,
                -7.829446740418423
              ],
              [
                -38.95736757216413,
                -7.823955277807135
              ],
              [
                -38.95365612668683,
                -7.821118823091711
              ],
              [
                -38.95247664304286,
                -7.810161381661651
              ],
              [
                -38.93962827330471,
                -7.794614984183351
              ],
              [
                -38.942623248670685,
                -7.788217060885168
              ],
              [
                -38.94561822803387,
                -7.781819094588707
              ],
              [
                -38.940647253060106,
                -7.771019158914553
              ],
              [
                -38.94202877252537,
                -7.76325024463751
              ],
              [
                -38.93920079805674,
                -7.758381280030115
              ],
              [
                -38.93367281890257,
                -7.758443267013582
              ],
              [
                -38.92723890680537,
                -7.753421302604625
              ],
              [
                -38.919823967433494,
                -7.754181288774757
              ],
              [
                -38.91969045148398,
                -7.754310270691812
              ],
              [
                -38.91573248061956,
                -7.758140266540773
              ],
              [
                -38.916599510922694,
                -7.753521797099991
              ],
              [
                -38.917864002528475,
                -7.746784820973472
              ],
              [
                -38.9151984998539,
                -7.744088857129262
              ],
              [
                -38.899959149607696,
                -7.744488849601511
              ],
              [
                -38.89616365635513,
                -7.748310316641182
              ],
              [
                -38.89443066793796,
                -7.750054788050857
              ],
              [
                -38.88101381473564,
                -7.747048831729096
              ],
              [
                -38.88051532556362,
                -7.724579478992629
              ],
              [
                -38.87485588123748,
                -7.717758518886122
              ],
              [
                -38.86936794925063,
                -7.711145060366603
              ],
              [
                -38.85137009070789,
                -7.715549017391956
              ],
              [
                -38.84880158632104,
                -7.717574985984028
              ],
              [
                -38.8457081281478,
                -7.720014979018029
              ],
              [
                -38.830268255088285,
                -7.720000460515572
              ],
              [
                -38.821571832118956,
                -7.714411514665536
              ],
              [
                -38.81462889362357,
                -7.696991630730857
              ],
              [
                -38.81472342149646,
                -7.687581200985728
              ],
              [
                -38.81814388476871,
                -7.683972712061905
              ],
              [
                -38.82384683820529,
                -7.677957277198777
              ],
              [
                -38.81931240256326,
                -7.664438884795429
              ],
              [
                -38.81543593181653,
                -7.663672866449794
              ],
              [
                -38.81181046642224,
                -7.668396357767177
              ],
              [
                -38.79287510863711,
                -7.669729826323063
              ],
              [
                -38.78897665524499,
                -7.670004325085136
              ],
              [
                -38.784993177997244,
                -7.66609736143169
              ],
              [
                -38.7799852324579,
                -7.661185396563405
              ],
              [
                -38.77504878560201,
                -7.662047855037196
              ],
              [
                -38.76021491559811,
                -7.655611918775245
              ],
              [
                -38.756791419621145,
                -7.657701383181134
              ],
              [
                -38.75408946673915,
                -7.65934987150996
              ],
              [
                -38.74240704770591,
                -7.659923352718299
              ],
              [
                -38.74144257977037,
                -7.647987430368318
              ],
              [
                -38.734527124791335,
                -7.638152014322205
              ],
              [
                -38.73459466253167,
                -7.637748520434763
              ],
              [
                -38.73528962084633,
                -7.633612063346175
              ],
              [
                -38.72709020300256,
                -7.628724590790782
              ],
              [
                -38.72530872933254,
                -7.627662068411929
              ],
              [
                -38.7233317494538,
                -7.626484116751441
              ],
              [
                -38.717209309619534,
                -7.626492113764321
              ],
              [
                -38.71471681828535,
                -7.621519625464638
              ],
              [
                -38.70502640434021,
                -7.621661130600994
              ],
              [
                -38.69559650657387,
                -7.632407016519544
              ],
              [
                -38.69097453982021,
                -7.633530520279153
              ],
              [
                -38.68635256306891,
                -7.634654009038357
              ],
              [
                -38.67961859431549,
                -7.646104415773671
              ],
              [
                -38.67238366021804,
                -7.658407320641611
              ],
              [
                -38.66956070538866,
                -7.660087822619969
              ],
              [
                -38.66761170306045,
                -7.659183788902425
              ],
              [
                -38.66320524901446,
                -7.657140830840173
              ],
              [
                -38.65032885999362,
                -7.668170731795378
              ],
              [
                -38.65072984647116,
                -7.67314321340956
              ],
              [
                -38.65101582710807,
                -7.676685158781757
              ],
              [
                -38.646083388743754,
                -7.676985649515276
              ],
              [
                -38.641700892628776,
                -7.677251647955155
              ],
              [
                -38.6412379171971,
                -7.682736136989378
              ],
              [
                -38.64095342992594,
                -7.686111578163469
              ],
              [
                -38.636218465969236,
                -7.688725065513411
              ],
              [
                -38.62605703076439,
                -7.694334523749026
              ],
              [
                -38.62545953607442,
                -7.701562958452996
              ],
              [
                -38.62493254446958,
                -7.707928399762769
              ],
              [
                -38.617945079517774,
                -7.712491869493951
              ],
              [
                -38.61574810145585,
                -7.713926359834764
              ],
              [
                -38.60758415796681,
                -7.725564274970537
              ],
              [
                -38.60745766861394,
                -7.729790215831059
              ],
              [
                -38.60718017742736,
                -7.739083159163654
              ],
              [
                -38.59735023984571,
                -7.741647125725582
              ],
              [
                -38.5932307806096,
                -7.753950563112842
              ],
              [
                -38.58566484697846,
                -7.754517032650139
              ],
              [
                -38.58133386932552,
                -7.750050543977641
              ],
              [
                -38.57762690371573,
                -7.746226100952361
              ],
              [
                -38.57504245408712,
                -7.752478547444378
              ],
              [
                -38.574440452133814,
                -7.753934018919663
              ],
              [
                -38.57296944219132,
                -7.752401526339048
              ],
              [
                -38.57170396228517,
                -7.751082540308001
              ],
              [
                -38.56919498715696,
                -7.752260026336384
              ],
              [
                -38.56187803902957,
                -7.755693511341369
              ],
              [
                -38.55072965145036,
                -7.751570011527965
              ],
              [
                -38.54292772373278,
                -7.748684545061185
              ],
              [
                -38.537527768789325,
                -7.751077044518519
              ],
              [
                -38.529316809306664,
                -7.754714499242142
              ],
              [
                -38.51620845918992,
                -7.748929509464197
              ],
              [
                -38.49619213494471,
                -7.749647019742532
              ],
              [
                -38.495579127941454,
                -7.741497080105196
              ],
              [
                -38.495344637529335,
                -7.738378590587538
              ],
              [
                -38.4840412590498,
                -7.733410609133591
              ],
              [
                -38.47191185013106,
                -7.731689611367507
              ],
              [
                -38.46358990760793,
                -7.730508133596135
              ],
              [
                -38.4618109437728,
                -7.727238140993495
              ],
              [
                -38.45663048766244,
                -7.717717701127985
              ],
              [
                -38.44330160471779,
                -7.719082206979264
              ],
              [
                -38.43394767547242,
                -7.720040177134663
              ],
              [
                -38.427236226175495,
                -7.729636118777685
              ],
              [
                -38.40924587766218,
                -7.730088593778292
              ],
              [
                -38.40043447597422,
                -7.719659680938968
              ],
              [
                -38.400981454005766,
                -7.718672660885761
              ],
              [
                -38.40353695146555,
                -7.714059727025427
              ],
              [
                -38.388543083133754,
                -7.709239225585475
              ],
              [
                -38.38186065015787,
                -7.707091238535546
              ],
              [
                -38.37066423709604,
                -7.698105799878456
              ],
              [
                -38.37055175415046,
                -7.698015297476602
              ],
              [
                -38.36882325401899,
                -7.690854846576446
              ],
              [
                -38.36241832770518,
                -7.687373880920486
              ],
              [
                -38.35709887966186,
                -7.676780974287052
              ],
              [
                -38.344303478919244,
                -7.694596298378734
              ],
              [
                -38.336850542246715,
                -7.692998315552006
              ],
              [
                -38.3299760780129,
                -7.695755280034641
              ],
              [
                -38.326288627163265,
                -7.693319312618622
              ],
              [
                -38.32038268628014,
                -7.697566267532165
              ],
              [
                -38.315812189133574,
                -7.708748682709795
              ],
              [
                -38.316859704826065,
                -7.715127633988794
              ],
              [
                -38.31164622168997,
                -7.715926611705455
              ],
              [
                -38.31548067136693,
                -7.728079049289686
              ],
              [
                -38.31027474944594,
                -7.738388962617623
              ],
              [
                -38.301774812440186,
                -7.744810912872581
              ],
              [
                -38.30746824755496,
                -7.752404346693615
              ],
              [
                -38.31633716949058,
                -7.764233284885783
              ],
              [
                -38.30409375022548,
                -7.763203750792698
              ],
              [
                -38.29942931327316,
                -7.762812252991242
              ],
              [
                -38.30085579033057,
                -7.768317715261231
              ],
              [
                -38.2942163437611,
                -7.777571151479001
              ],
              [
                -38.298199314716015,
                -7.782792598995488
              ],
              [
                -38.302182265668975,
                -7.788014059519947
              ],
              [
                -38.297350274963755,
                -7.813543385467819
              ],
              [
                -38.28640136822932,
                -7.829822235780722
              ],
              [
                -38.27588946299285,
                -7.833548225867533
              ],
              [
                -38.2695835258043,
                -7.830235722899425
              ],
              [
                -38.264546547983834,
                -7.827589752905319
              ],
              [
                -38.26077459273178,
                -7.829061746632905
              ],
              [
                -38.25665112644023,
                -7.830670722031258
              ],
              [
                -38.238182799106475,
                -7.830336725496127
              ],
              [
                -38.235132808612384,
                -7.825644728698984
              ],
              [
                -38.23507383420202,
                -7.825554250339185
              ],
              [
                -38.22666587775204,
                -7.821820775888749
              ],
              [
                -38.221811427951216,
                -7.819665299334458
              ],
              [
                -38.21311550363538,
                -7.812743346932385
              ],
              [
                -38.20589359347358,
                -7.806994876815178
              ],
              [
                -38.19986812470853,
                -7.803869390712697
              ],
              [
                -38.19986263175982,
                -7.803865399738651
              ],
              [
                -38.19383217604197,
                -7.800737892653409
              ],
              [
                -38.18681374570618,
                -7.7954769410204
              ],
              [
                -38.17706635790301,
                -7.788170968765992
              ],
              [
                -38.17613735966649,
                -7.788613993638092
              ],
              [
                -38.17428786012311,
                -7.789495456423971
              ],
              [
                -38.17039640411804,
                -7.785860987960201
              ],
              [
                -38.16363646638136,
                -7.77954805758985
              ],
              [
                -38.14151414257811,
                -7.784862990179031
              ],
              [
                -38.137307677946836,
                -7.789082958608911
              ],
              [
                -38.133164234816164,
                -7.793239903569069
              ],
              [
                -38.13105223757045,
                -7.795358908707266
              ],
              [
                -38.13075675854412,
                -7.803470825643902
              ],
              [
                -38.122047796843674,
                -7.807041811364808
              ],
              [
                -38.1237482844407,
                -7.812582271521767
              ],
              [
                -38.11965682134499,
                -7.81858622144734
              ],
              [
                -38.1184993286025,
                -7.819654713364979
              ],
              [
                -38.117280352352026,
                -7.820779700802038
              ],
              [
                -38.07673369365003,
                -7.830162594340197
              ],
              [
                -38.067512263312,
                -7.821703172345849
              ],
              [
                -38.066088287978545,
                -7.817786195058866
              ],
              [
                -38.072542729729385,
                -7.808865259322728
              ],
              [
                -38.07534222936719,
                -7.804996783093108
              ],
              [
                -38.07501274349348,
                -7.788722896954408
              ],
              [
                -38.069459298656525,
                -7.773282004279949
              ],
              [
                -38.069105299554636,
                -7.772297510507166
              ],
              [
                -38.05429195210576,
                -7.76016112520414
              ],
              [
                -38.03913356890648,
                -7.747742691838345
              ],
              [
                -38.03077263149515,
                -7.751021148080617
              ],
              [
                -38.022410736099474,
                -7.754299637319074
              ],
              [
                -38.01256329076369,
                -7.762481047909129
              ],
              [
                -38.00775131869432,
                -7.766478512503975
              ],
              [
                -38.004081349837634,
                -7.777615926484257
              ],
              [
                -38.00034889078474,
                -7.778556437293681
              ],
              [
                -37.99528292920891,
                -7.778523413463965
              ],
              [
                -37.98987546163092,
                -7.77848793937846
              ],
              [
                -37.9827310301963,
                -7.772379968286526
              ],
              [
                -37.98257703365248,
                -7.772248468167267
              ],
              [
                -37.982441555796704,
                -7.772301984649331
              ],
              [
                -37.97100662535078,
                -7.776854943658838
              ],
              [
                -37.95805824486484,
                -7.765733010238909
              ],
              [
                -37.95795527683921,
                -7.765644484832792
              ],
              [
                -37.94713686562335,
                -7.755825574207905
              ],
              [
                -37.94270892569175,
                -7.740633179835335
              ],
              [
                -37.93456598958408,
                -7.73505772196482
              ],
              [
                -37.926423559473996,
                -7.729481753113208
              ],
              [
                -37.925030582653505,
                -7.725792784226745
              ],
              [
                -37.92015961672135,
                -7.712893886054267
              ],
              [
                -37.91266518250492,
                -7.709086406225321
              ],
              [
                -37.90873273530087,
                -7.709957369424042
              ],
              [
                -37.89392685456003,
                -7.71323585752483
              ],
              [
                -37.891905393454365,
                -7.710539372561222
              ],
              [
                -37.89882181668886,
                -7.70565089650157
              ],
              [
                -37.90058580498799,
                -7.702183916453552
              ],
              [
                -37.90289278037706,
                -7.697648987015876
              ],
              [
                -37.89696383615706,
                -7.690428043603858
              ],
              [
                -37.88019547798831,
                -7.683264074168795
              ],
              [
                -37.8580931958319,
                -7.653228290892021
              ],
              [
                -37.85082277723583,
                -7.649954809253027
              ],
              [
                -37.843551362651894,
                -7.646681322620593
              ],
              [
                -37.80756215106666,
                -7.643570327111179
              ],
              [
                -37.792298287773775,
                -7.627549416078135
              ],
              [
                -37.78206241051015,
                -7.626487430188522
              ],
              [
                -37.782008914925974,
                -7.62655592562048
              ],
              [
                -37.779298905977384,
                -7.630040886729347
              ],
              [
                -37.77596442987985,
                -7.634328370184469
              ],
              [
                -37.775860934870074,
                -7.63928682302948
              ],
              [
                -37.7757174366385,
                -7.646230275606309
              ],
              [
                -37.77302394975379,
                -7.646919265195177
              ],
              [
                -37.76769148651895,
                -7.648283273522612
              ],
              [
                -37.76746347881751,
                -7.656750189339236
              ],
              [
                -37.76473900364548,
                -7.659410151770612
              ],
              [
                -37.75429809829859,
                -7.669604094275513
              ],
              [
                -37.75166814194388,
                -7.66388463010848
              ],
              [
                -37.73835576315969,
                -7.659807657915471
              ],
              [
                -37.73815126060847,
                -7.643816757571895
              ],
              [
                -37.745908700537285,
                -7.636097824965109
              ],
              [
                -37.74782469401025,
                -7.635706826474527
              ],
              [
                -37.75758360583589,
                -7.633717364426082
              ],
              [
                -37.757317098617484,
                -7.631893378226514
              ],
              [
                -37.7559026206834,
                -7.622210938493648
              ],
              [
                -37.75545314306915,
                -7.619119455891735
              ],
              [
                -37.745294717343185,
                -7.608933535198253
              ],
              [
                -37.74104576095395,
                -7.597132614576886
              ],
              [
                -37.73550681421526,
                -7.596381127023596
              ],
              [
                -37.734606837219516,
                -7.596259133257981
              ],
              [
                -37.7283389025693,
                -7.579338243473693
              ],
              [
                -37.720638982543626,
                -7.581500728837451
              ],
              [
                -37.707391559781136,
                -7.570251315052364
              ],
              [
                -37.70969006926517,
                -7.559132902391591
              ],
              [
                -37.70611359967363,
                -7.550194965398486
              ],
              [
                -37.68175180754953,
                -7.5479894464711
              ],
              [
                -37.678995851751345,
                -7.541604514498053
              ],
              [
                -37.676240877939485,
                -7.535220544527212
              ],
              [
                -37.65930604885788,
                -7.522331135719849
              ],
              [
                -37.654386065155144,
                -7.526157611435144
              ],
              [
                -37.649465130462495,
                -7.529984072148298
              ],
              [
                -37.6299807801872,
                -7.532120024625684
              ],
              [
                -37.619441890895374,
                -7.525684080089567
              ],
              [
                -37.616508405716274,
                -7.523088587833323
              ],
              [
                -37.61102097275843,
                -7.518233139034253
              ],
              [
                -37.6101639745536,
                -7.512736701755425
              ],
              [
                -37.609367481512756,
                -7.507628719532232
              ],
              [
                -37.603640547614,
                -7.505415729190205
              ],
              [
                -37.590190665983705,
                -7.500219745923098
              ],
              [
                -37.571419348077335,
                -7.4887033193695
              ],
              [
                -37.55076802952252,
                -7.478764877259398
              ],
              [
                -37.5327536868753,
                -7.47259941608436
              ],
              [
                -37.52755571060795,
                -7.464834984089715
              ],
              [
                -37.52703072094687,
                -7.450851603732456
              ],
              [
                -37.52691923120001,
                -7.447884099579333
              ],
              [
                -37.52181328369428,
                -7.441960645467946
              ],
              [
                -37.51999179135094,
                -7.439846655420586
              ],
              [
                -37.516094346128504,
                -7.435325204405181
              ],
              [
                -37.51385584882477,
                -7.432729220771463
              ],
              [
                -37.51472036786634,
                -7.429250256325721
              ],
              [
                -37.51523685290399,
                -7.427171264792173
              ],
              [
                -37.5102704140112,
                -7.42012982046661
              ],
              [
                -37.50999141052599,
                -7.420054295838828
              ],
              [
                -37.50528744492958,
                -7.418775311159035
              ],
              [
                -37.49658203255804,
                -7.403065458078103
              ],
              [
                -37.48662211763684,
                -7.39470800120868
              ],
              [
                -37.49083758917382,
                -7.384594580663226
              ],
              [
                -37.4918145873447,
                -7.382250099546354
              ],
              [
                -37.49652206832094,
                -7.367137230077568
              ],
              [
                -37.4657408286028,
                -7.357693262034042
              ],
              [
                -37.44124553121745,
                -7.359322756994588
              ],
              [
                -37.4314886536326,
                -7.356107741574577
              ],
              [
                -37.42656717904941,
                -7.353402279844933
              ],
              [
                -37.41507377639604,
                -7.361320710843973
              ],
              [
                -37.40772384192002,
                -7.357702244369309
              ],
              [
                -37.386004044452825,
                -7.356347736568073
              ],
              [
                -37.385301531681115,
                -7.356303700382423
              ],
              [
                -37.367035699265415,
                -7.346344793925879
              ],
              [
                -37.34912734756648,
                -7.336580828279734
              ],
              [
                -37.35260832444075,
                -7.329134410667053
              ],
              [
                -37.35607233143342,
                -7.321724986756615
              ],
              [
                -37.33122506183453,
                -7.28931068194249
              ],
              [
                -37.31357121792375,
                -7.288885178144956
              ],
              [
                -37.28775192668252,
                -7.287702673183552
              ],
              [
                -37.28397744900807,
                -7.283658197822977
              ],
              [
                -37.27561155551873,
                -7.274693277324542
              ],
              [
                -37.26066318964748,
                -7.273142774325099
              ],
              [
                -37.232530437913795,
                -7.274732256165594
              ],
              [
                -37.213754578777696,
                -7.290902622480253
              ],
              [
                -37.20599564321601,
                -7.292402591067043
              ],
              [
                -37.19823771065221,
                -7.29390209465734
              ],
              [
                -37.176537412088756,
                -7.309167927800929
              ]
            ]
          ],
          [
            [
              [
                -32.407145082547515,
                -3.837766857907594
              ],
              [
                -32.40057212656115,
                -3.836926868948739
              ],
              [
                -32.39787666159016,
                -3.842500787770981
              ],
              [
                -32.39620965383176,
                -3.845947276363576
              ],
              [
                -32.39475669017157,
                -3.849166247999321
              ],
              [
                -32.39087522629802,
                -3.851360253682019
              ],
              [
                -32.39601816553413,
                -3.852071242278749
              ],
              [
                -32.39652317251648,
                -3.856069178840315
              ],
              [
                -32.40010512035939,
                -3.856244203547146
              ],
              [
                -32.40808656761865,
                -3.856228213409027
              ],
              [
                -32.409428550977005,
                -3.859899664088852
              ],
              [
                -32.41081803371331,
                -3.864108129139705
              ],
              [
                -32.41627247659751,
                -3.86328962608871
              ],
              [
                -32.41665597607399,
                -3.863232159717207
              ],
              [
                -32.41725296718395,
                -3.864337139358748
              ],
              [
                -32.41952744974241,
                -3.868547577701399
              ],
              [
                -32.42152143458771,
                -3.867762602176862
              ],
              [
                -32.42610790883018,
                -3.865957603366124
              ],
              [
                -32.42443041630002,
                -3.868982089620914
              ],
              [
                -32.422967406919334,
                -3.871620075293702
              ],
              [
                -32.42441089531965,
                -3.878278023182955
              ],
              [
                -32.427821869158514,
                -3.871371091112145
              ],
              [
                -32.431896349630954,
                -3.871045058329881
              ],
              [
                -32.43887076534425,
                -3.870643092198748
              ],
              [
                -32.44174973980858,
                -3.870477068622229
              ],
              [
                -32.44463722364016,
                -3.871943068930261
              ],
              [
                -32.44998465932701,
                -3.874658528275906
              ],
              [
                -32.45316464140795,
                -3.876273029403146
              ],
              [
                -32.456611102982066,
                -3.878023514447115
              ],
              [
                -32.45691712106552,
                -3.881222009889377
              ],
              [
                -32.461364083275605,
                -3.877472546845995
              ],
              [
                -32.463915533840925,
                -3.880032508583973
              ],
              [
                -32.46764499431233,
                -3.877360036978132
              ],
              [
                -32.46008309279888,
                -3.872499557417098
              ],
              [
                -32.45539361194495,
                -3.864149137040212
              ],
              [
                -32.447284695631986,
                -3.861802675563971
              ],
              [
                -32.44619570606073,
                -3.854806727721709
              ],
              [
                -32.44259276278726,
                -3.850523272556705
              ],
              [
                -32.43561881306005,
                -3.847985785137856
              ],
              [
                -32.43462133439627,
                -3.847622762939693
              ],
              [
                -32.407145082547515,
                -3.837766857907594
              ]
            ]
          ],
          [
            [
              [
                -32.40057212656115,
                -3.836926868948739
              ],
              [
                -32.39997865372566,
                -3.831928410029544
              ],
              [
                -32.39848364398351,
                -3.830606412970715
              ],
              [
                -32.39734464957977,
                -3.833367887671314
              ],
              [
                -32.40057212656115,
                -3.836926868948739
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 17,
        "nome": "Piauí",
        "sigla": "PI",
        "regiao_id": 4,
        "codigo_ibg": 22
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -41.73867878992938,
                -2.805582542221568
              ],
              [
                -41.734402809916645,
                -2.807036034470516
              ],
              [
                -41.733869805448684,
                -2.806676036121177
              ],
              [
                -41.7317318246284,
                -2.805229564773233
              ],
              [
                -41.72854985668542,
                -2.803078062618453
              ],
              [
                -41.724774893904076,
                -2.806499045608637
              ],
              [
                -41.72072594331659,
                -2.81016799056681
              ],
              [
                -41.70610856333496,
                -2.823415888077944
              ],
              [
                -41.699866111310726,
                -2.829073354741297
              ],
              [
                -41.694654123037736,
                -2.833796791051625
              ],
              [
                -41.69247715546677,
                -2.835769802889815
              ],
              [
                -41.68901967314816,
                -2.838902761225272
              ],
              [
                -41.683639205226086,
                -2.843778736279924
              ],
              [
                -41.67550177905089,
                -2.849052693321637
              ],
              [
                -41.66484386327148,
                -2.855961136731298
              ],
              [
                -41.65891091627174,
                -2.859806611669987
              ],
              [
                -41.65528745096227,
                -2.867215546427666
              ],
              [
                -41.650129502378704,
                -2.871538496870185
              ],
              [
                -41.63280111053022,
                -2.886055384442533
              ],
              [
                -41.607176321304685,
                -2.898376792468187
              ],
              [
                -41.59938191482068,
                -2.902124739527184
              ],
              [
                -41.59311045293051,
                -2.905140242824936
              ],
              [
                -41.580775574793314,
                -2.906995728218865
              ],
              [
                -41.57230011005109,
                -2.904409725709354
              ],
              [
                -41.559987711580455,
                -2.900653269127167
              ],
              [
                -41.55850374579833,
                -2.897923785050988
              ],
              [
                -41.54440386391183,
                -2.904302213385346
              ],
              [
                -41.533027430804495,
                -2.909449159264017
              ],
              [
                -41.50997864596745,
                -2.910910144216142
              ],
              [
                -41.50647465791661,
                -2.908420174607081
              ],
              [
                -41.5010907189355,
                -2.904593198879029
              ],
              [
                -41.49814874607306,
                -2.902530708105236
              ],
              [
                -41.49448977933763,
                -2.899965248047437
              ],
              [
                -41.49103578336405,
                -2.899047752119842
              ],
              [
                -41.48092288135765,
                -2.896359281921185
              ],
              [
                -41.47647990298331,
                -2.897398256307807
              ],
              [
                -41.4610160434695,
                -2.901035717675065
              ],
              [
                -41.452865134677225,
                -2.902953201942051
              ],
              [
                -41.44494817496301,
                -2.904815176719252
              ],
              [
                -41.43856972847109,
                -2.909690139326221
              ],
              [
                -41.434422255040616,
                -2.906468661276549
              ],
              [
                -41.4290668082534,
                -2.907605174589641
              ],
              [
                -41.41975238318498,
                -2.909498149657869
              ],
              [
                -41.41781890215559,
                -2.909891151934735
              ],
              [
                -41.41105246124039,
                -2.907598662732755
              ],
              [
                -41.40894547901692,
                -2.906884145658172
              ],
              [
                -41.40752296701877,
                -2.906402143980603
              ],
              [
                -41.399387043665236,
                -2.903646676976783
              ],
              [
                -41.37907973231104,
                -2.914436604484726
              ],
              [
                -41.374063778231445,
                -2.91710207687832
              ],
              [
                -41.370407803843904,
                -2.916630086388141
              ],
              [
                -41.367245309325675,
                -2.916221086565299
              ],
              [
                -41.36449632589227,
                -2.917260584487358
              ],
              [
                -41.36052238251561,
                -2.918764537353667
              ],
              [
                -41.35689888926365,
                -2.92013503838378
              ],
              [
                -41.3529274268698,
                -2.921637525260572
              ],
              [
                -41.338443050820246,
                -2.927060982833235
              ],
              [
                -41.33445358011912,
                -2.923853503750322
              ],
              [
                -41.33236109218699,
                -2.921932003178104
              ],
              [
                -41.33152761738361,
                -2.92116701392237
              ],
              [
                -41.326727173434016,
                -2.921065037146251
              ],
              [
                -41.322306192320816,
                -2.92097103943241
              ],
              [
                -41.32253167730128,
                -2.945889817471557
              ],
              [
                -41.322557191143986,
                -2.948767804843593
              ],
              [
                -41.32062118293411,
                -2.952878775867668
              ],
              [
                -41.31868572771795,
                -2.956990750884176
              ],
              [
                -41.29808935682267,
                -2.968039635117567
              ],
              [
                -41.29277592622511,
                -2.967776164416334
              ],
              [
                -41.283135005798904,
                -2.967298134959574
              ],
              [
                -41.2719406019889,
                -2.970921631713427
              ],
              [
                -41.27181810780929,
                -2.971531618872924
              ],
              [
                -41.26916710455539,
                -2.984728495157079
              ],
              [
                -41.25674971465205,
                -3.004292355076783
              ],
              [
                -41.2561637032263,
                -3.011216798412401
              ],
              [
                -41.25616272123035,
                -3.011229319313617
              ],
              [
                -41.25557871478681,
                -3.018141244753001
              ],
              [
                -41.24940477376642,
                -3.028686162725723
              ],
              [
                -41.25422220583347,
                -3.030848630360563
              ],
              [
                -41.25339021162149,
                -3.037237097843994
              ],
              [
                -41.25553221460682,
                -3.040607064079329
              ],
              [
                -41.26006668335197,
                -3.047738999563251
              ],
              [
                -41.260401176193774,
                -3.054713986852105
              ],
              [
                -41.26097267033781,
                -3.066719869691991
              ],
              [
                -41.256935671706664,
                -3.070588820895701
              ],
              [
                -41.25615270898464,
                -3.071338847731463
              ],
              [
                -41.25544119291313,
                -3.080137265347413
              ],
              [
                -41.256274684376216,
                -3.087662695508441
              ],
              [
                -41.267585613984586,
                -3.09348765667855
              ],
              [
                -41.27191404842979,
                -3.100563111614849
              ],
              [
                -41.27666349751619,
                -3.101391579764497
              ],
              [
                -41.28544242374607,
                -3.102922099808687
              ],
              [
                -41.28511694699014,
                -3.112958031903056
              ],
              [
                -41.318780157053965,
                -3.144989768408897
              ],
              [
                -41.31897214479346,
                -3.149723737350057
              ],
              [
                -41.32833455767684,
                -3.161238641384637
              ],
              [
                -41.32493507751779,
                -3.171175578271768
              ],
              [
                -41.32210910716039,
                -3.179437513497655
              ],
              [
                -41.386422534775164,
                -3.28839618392383
              ],
              [
                -41.397032446708316,
                -3.311921979975845
              ],
              [
                -41.39980593715223,
                -3.318539963297998
              ],
              [
                -41.39901343060947,
                -3.321630931850001
              ],
              [
                -41.39894994304652,
                -3.321878931888486
              ],
              [
                -41.39758693043091,
                -3.327192388862885
              ],
              [
                -41.40306887889182,
                -3.3322153666795
              ],
              [
                -41.41193931696829,
                -3.340342790534983
              ],
              [
                -41.41553526574026,
                -3.349108709437701
              ],
              [
                -41.41841874908908,
                -3.356140660623996
              ],
              [
                -41.42313921644886,
                -3.367651592556399
              ],
              [
                -41.41833926149616,
                -3.370133058363725
              ],
              [
                -41.41042830689907,
                -3.366135098568191
              ],
              [
                -41.40232487294717,
                -3.362039094474826
              ],
              [
                -41.40029488603326,
                -3.377529485761856
              ],
              [
                -41.40172538562627,
                -3.386768931182868
              ],
              [
                -41.40318886685405,
                -3.396221854963468
              ],
              [
                -41.39692490831378,
                -3.398150847509836
              ],
              [
                -41.39348294759178,
                -3.399211339914327
              ],
              [
                -41.391921944038394,
                -3.40332328122281
              ],
              [
                -41.38861700626812,
                -3.412028726013066
              ],
              [
                -41.363378213081766,
                -3.410838714601302
              ],
              [
                -41.355650750641445,
                -3.410474738474783
              ],
              [
                -41.347591321393914,
                -3.414268207757969
              ],
              [
                -41.344402325196306,
                -3.418962155879459
              ],
              [
                -41.34380885260764,
                -3.433457558479041
              ],
              [
                -41.34353585832319,
                -3.440140507204835
              ],
              [
                -41.347616802674814,
                -3.452161905962133
              ],
              [
                -41.335212927650005,
                -3.46114431103526
              ],
              [
                -41.329250941707194,
                -3.46546176701605
              ],
              [
                -41.328384960190895,
                -3.477708703104625
              ],
              [
                -41.3276364583232,
                -3.488297126194079
              ],
              [
                -41.32378800151417,
                -3.493205051390897
              ],
              [
                -41.32226401047852,
                -3.492557575843519
              ],
              [
                -41.31283260571453,
                -3.488550599405579
              ],
              [
                -41.30501165950531,
                -3.489732576111711
              ],
              [
                -41.298837217660875,
                -3.490665600405986
              ],
              [
                -41.32021053168219,
                -3.497501523463496
              ],
              [
                -41.322291517076295,
                -3.50069899333784
              ],
              [
                -41.32370350145101,
                -3.502869506962108
              ],
              [
                -41.32445197552244,
                -3.517400389918729
              ],
              [
                -41.324876494319696,
                -3.526727298351898
              ],
              [
                -41.325300466111486,
                -3.536054724791834
              ],
              [
                -41.33649136085958,
                -3.553535116995671
              ],
              [
                -41.36427165057118,
                -3.557644587097684
              ],
              [
                -41.366967603510176,
                -3.561633071110536
              ],
              [
                -41.37022556629856,
                -3.566787530272751
              ],
              [
                -41.37022959570965,
                -3.568182504410319
              ],
              [
                -41.37024909002546,
                -3.574509952142969
              ],
              [
                -41.361624629611214,
                -3.582824379920863
              ],
              [
                -41.35465370783091,
                -3.585090881457247
              ],
              [
                -41.34452678396585,
                -3.588382333733692
              ],
              [
                -41.33096040161141,
                -3.624460042286285
              ],
              [
                -41.33872132032574,
                -3.670062196567059
              ],
              [
                -41.343314286740416,
                -3.675615654265822
              ],
              [
                -41.341369770990376,
                -3.680415620125679
              ],
              [
                -41.336842299955265,
                -3.682365121086087
              ],
              [
                -41.33507132041512,
                -3.683127611421085
              ],
              [
                -41.326165903132214,
                -3.686960545912817
              ],
              [
                -41.31834795578743,
                -3.685920068764429
              ],
              [
                -41.30728706568831,
                -3.684447576636118
              ],
              [
                -41.29042521541007,
                -3.689330526645688
              ],
              [
                -41.28381626095518,
                -3.695704004299191
              ],
              [
                -41.278958301579685,
                -3.700388443822174
              ],
              [
                -41.27316535169184,
                -3.700997458672659
              ],
              [
                -41.255056000120085,
                -3.702899918330607
              ],
              [
                -41.24136159219616,
                -3.710889855423792
              ],
              [
                -41.23910112153246,
                -3.712208831212426
              ],
              [
                -41.239052103580576,
                -3.720336256917407
              ],
              [
                -41.2515105164292,
                -3.722980782540218
              ],
              [
                -41.2542494771937,
                -3.726263716135874
              ],
              [
                -41.25698545998398,
                -3.729543227760246
              ],
              [
                -41.259531449891576,
                -3.748354066472011
              ],
              [
                -41.260751442937,
                -3.75736749887405
              ],
              [
                -41.253744956190815,
                -3.774680367259888
              ],
              [
                -41.26445137381172,
                -3.791218733220463
              ],
              [
                -41.26737433921198,
                -3.794038714545469
              ],
              [
                -41.28383170307343,
                -3.809918616172577
              ],
              [
                -41.3004380456269,
                -3.82594247883678
              ],
              [
                -41.29656511182291,
                -3.828597461552411
              ],
              [
                -41.294783109177295,
                -3.829818452303329
              ],
              [
                -41.28956815900131,
                -3.824450480748856
              ],
              [
                -41.28708766779715,
                -3.821897500512339
              ],
              [
                -41.282296220859166,
                -3.826452461071761
              ],
              [
                -41.27647676195624,
                -3.825260978824592
              ],
              [
                -41.26820431597213,
                -3.844132841668993
              ],
              [
                -41.26987982682333,
                -3.851498285189657
              ],
              [
                -41.2701577909771,
                -3.852721774808479
              ],
              [
                -41.26738082739949,
                -3.861491197506355
              ],
              [
                -41.260905864439984,
                -3.863897191012899
              ],
              [
                -41.2595784033145,
                -3.864390665604486
              ],
              [
                -41.25920690761139,
                -3.866262671906027
              ],
              [
                -41.25848091510049,
                -3.869917637208727
              ],
              [
                -41.24706698772217,
                -3.883163023388645
              ],
              [
                -41.24513650130487,
                -3.89596490814924
              ],
              [
                -41.23852104190743,
                -3.90210485758484
              ],
              [
                -41.228536627301494,
                -3.911370304272287
              ],
              [
                -41.22715663276829,
                -3.916109758871772
              ],
              [
                -41.221426703057894,
                -3.935776089689793
              ],
              [
                -41.219862197779186,
                -3.941145586324035
              ],
              [
                -41.22124916332691,
                -3.945386515025241
              ],
              [
                -41.222635181881344,
                -3.949625989740798
              ],
              [
                -41.22864659154252,
                -3.968014845745637
              ],
              [
                -41.2322620955067,
                -3.96761535845492
              ],
              [
                -41.232575054906825,
                -3.967580859861882
              ],
              [
                -41.23974051205523,
                -3.978836283767726
              ],
              [
                -41.243983477356736,
                -3.979395792325114
              ],
              [
                -41.243812469843036,
                -3.986030243808718
              ],
              [
                -41.24886341609982,
                -3.989506687119349
              ],
              [
                -41.245353957978566,
                -4.006221074985139
              ],
              [
                -41.242957459419294,
                -4.009766526427636
              ],
              [
                -41.23906401637684,
                -4.015527994020408
              ],
              [
                -41.24489193162781,
                -4.020117968079725
              ],
              [
                -41.25401938214838,
                -4.02730691550458
              ],
              [
                -41.254308376102635,
                -4.035393369993089
              ],
              [
                -41.249802405849664,
                -4.037391811469862
              ],
              [
                -41.23427252824299,
                -4.029308884060119
              ],
              [
                -41.20852574226554,
                -4.025220422110435
              ],
              [
                -41.20323929827145,
                -4.021169961127087
              ],
              [
                -41.2029602856999,
                -4.020955437664535
              ],
              [
                -41.19909633132654,
                -4.017995969869573
              ],
              [
                -41.19263287339718,
                -4.020161930159502
              ],
              [
                -41.192230882697864,
                -4.020296949931182
              ],
              [
                -41.182672959486865,
                -4.018352936699293
              ],
              [
                -41.181826462008196,
                -4.024071394966209
              ],
              [
                -41.18165396692943,
                -4.025235917856886
              ],
              [
                -41.171439042274116,
                -4.032116352880784
              ],
              [
                -41.16137763772094,
                -4.031464850386604
              ],
              [
                -41.15957566284627,
                -4.031348340476131
              ],
              [
                -41.14272029856847,
                -4.036450307276602
              ],
              [
                -41.11996798017339,
                -4.035978271653147
              ],
              [
                -41.114012030093306,
                -4.040281264563927
              ],
              [
                -41.11385253484034,
                -4.043819214033678
              ],
              [
                -41.1138000116787,
                -4.04738371134723
              ],
              [
                -41.113739530353044,
                -4.051457652705367
              ],
              [
                -41.120207490080254,
                -4.056204148811947
              ],
              [
                -41.12678039789623,
                -4.061027119382929
              ],
              [
                -41.12185896294457,
                -4.07682549559735
              ],
              [
                -41.13735531204966,
                -4.119015657623634
              ],
              [
                -41.14099276939133,
                -4.121679648659512
              ],
              [
                -41.14279624429136,
                -4.123881633430762
              ],
              [
                -41.132920350399516,
                -4.133769053254302
              ],
              [
                -41.12975835140797,
                -4.136935015259654
              ],
              [
                -41.12773539440778,
                -4.138960514628483
              ],
              [
                -41.1272948637004,
                -4.141991966936885
              ],
              [
                -41.12577337360764,
                -4.152468888063584
              ],
              [
                -41.1179524633462,
                -4.164862794413884
              ],
              [
                -41.10235407458981,
                -4.1677652516538
              ],
              [
                -41.09051416770993,
                -4.169968234061098
              ],
              [
                -41.09294364777367,
                -4.173405736560854
              ],
              [
                -41.098848097458635,
                -4.181758154614998
              ],
              [
                -41.10161059453018,
                -4.18562813893237
              ],
              [
                -41.13552625538985,
                -4.233147282085231
              ],
              [
                -41.121445861305695,
                -4.300524264343487
              ],
              [
                -41.11886438000821,
                -4.31287414864619
              ],
              [
                -41.11905686711011,
                -4.323891070603044
              ],
              [
                -41.11979284091386,
                -4.330201532200655
              ],
              [
                -41.133045259352286,
                -4.339779959641063
              ],
              [
                -41.1371652083857,
                -4.346984888001741
              ],
              [
                -41.14863061570527,
                -4.367033273814233
              ],
              [
                -41.159635003748505,
                -4.381133137395975
              ],
              [
                -41.15931350223037,
                -4.410190936083916
              ],
              [
                -41.176757833132584,
                -4.440897213005185
              ],
              [
                -41.17752233408812,
                -4.464400028290256
              ],
              [
                -41.17764330822984,
                -4.468113499743558
              ],
              [
                -41.17970228717365,
                -4.471786985478159
              ],
              [
                -41.18690573449706,
                -4.484634883145837
              ],
              [
                -41.188969230394115,
                -4.488315343844131
              ],
              [
                -41.18970568028978,
                -4.502186746421016
              ],
              [
                -41.19039520200678,
                -4.515162137895403
              ],
              [
                -41.196903633311074,
                -4.521609095579824
              ],
              [
                -41.20341256461058,
                -4.528055529283391
              ],
              [
                -41.20632704277603,
                -4.540833432472046
              ],
              [
                -41.20787653937497,
                -4.547627403009077
              ],
              [
                -41.210805018692064,
                -4.549968391500665
              ],
              [
                -41.213734490000554,
                -4.552310388987301
              ],
              [
                -41.22162340247761,
                -4.567148238949543
              ],
              [
                -41.227018362233714,
                -4.567390265854411
              ],
              [
                -41.24036177329536,
                -4.568087258339066
              ],
              [
                -41.242161239690454,
                -4.571146243756343
              ],
              [
                -41.235029820191855,
                -4.5733651990451
              ],
              [
                -41.224839892744676,
                -4.576630190721871
              ],
              [
                -41.22107841847911,
                -4.580012167798086
              ],
              [
                -41.22446139469066,
                -4.592716077918651
              ],
              [
                -41.22797132776146,
                -4.599691527138609
              ],
              [
                -41.23274878493263,
                -4.609186951670454
              ],
              [
                -41.22895382324818,
                -4.619728875742715
              ],
              [
                -41.21928191774409,
                -4.626329796031507
              ],
              [
                -41.21607392044959,
                -4.628519269550851
              ],
              [
                -41.20243705272963,
                -4.637826728979852
              ],
              [
                -41.197718081844215,
                -4.644343159475356
              ],
              [
                -41.1951745953092,
                -4.647855135177753
              ],
              [
                -41.19265062962415,
                -4.651340594093492
              ],
              [
                -41.1925551063548,
                -4.651473092026112
              ],
              [
                -41.18666115145786,
                -4.659611053451858
              ],
              [
                -41.18479667892253,
                -4.661805042628718
              ],
              [
                -41.17390327534878,
                -4.667094976306855
              ],
              [
                -41.202114994282574,
                -4.701524701824589
              ],
              [
                -41.20823646682751,
                -4.707695690710097
              ],
              [
                -41.21435941535881,
                -4.713867117606507
              ],
              [
                -41.22827229258656,
                -4.727894041412224
              ],
              [
                -41.236272196133015,
                -4.738386440230281
              ],
              [
                -41.234855197874005,
                -4.746159890898417
              ],
              [
                -41.249961583178184,
                -4.756257820581037
              ],
              [
                -41.24763458502348,
                -4.761184291597116
              ],
              [
                -41.24653209594378,
                -4.761749269679666
              ],
              [
                -41.24023615388757,
                -4.764973741610844
              ],
              [
                -41.22949174438997,
                -4.767552720104372
              ],
              [
                -41.21238737830628,
                -4.77165819049228
              ],
              [
                -41.20763941351137,
                -4.776400642606805
              ],
              [
                -41.20960289157665,
                -4.782979089250254
              ],
              [
                -41.21761632228465,
                -4.787181068358055
              ],
              [
                -41.222238313320425,
                -4.789604569276039
              ],
              [
                -41.232089203117944,
                -4.804168934035223
              ],
              [
                -41.232257716962586,
                -4.80929043891124
              ],
              [
                -41.2324572070312,
                -4.815368863481242
              ],
              [
                -41.23590417087589,
                -4.817826344531292
              ],
              [
                -41.24084962861131,
                -4.821353317208826
              ],
              [
                -41.23987113308199,
                -4.839380207715521
              ],
              [
                -41.23906814780166,
                -4.85417057111315
              ],
              [
                -41.24393307816208,
                -4.861448044095065
              ],
              [
                -41.248797542522496,
                -4.868724985095355
              ],
              [
                -41.23714864338366,
                -4.880827354167606
              ],
              [
                -41.22772372089968,
                -4.890618776120854
              ],
              [
                -41.231137670307895,
                -4.89799626258228
              ],
              [
                -41.23455164371052,
                -4.905373667056613
              ],
              [
                -41.22257224855714,
                -4.926139543709114
              ],
              [
                -41.2202797516516,
                -4.937209926828198
              ],
              [
                -41.210210319352974,
                -4.938243915347855
              ],
              [
                -41.20923484446882,
                -4.9455903676566
              ],
              [
                -41.203414372096404,
                -4.949379350468011
              ],
              [
                -41.199000905622164,
                -4.94106839854756
              ],
              [
                -41.19707843070834,
                -4.937448445156778
              ],
              [
                -41.17984059366627,
                -4.947174322246024
              ],
              [
                -41.15054231636582,
                -4.991738989240305
              ],
              [
                -41.146091820598485,
                -4.991828482067962
              ],
              [
                -41.1433633524523,
                -4.988236523002669
              ],
              [
                -41.14048638165822,
                -4.984448529357077
              ],
              [
                -41.137142924587906,
                -4.984596531359292
              ],
              [
                -41.13519641913462,
                -4.996765925229406
              ],
              [
                -41.135179442217336,
                -4.996872952401692
              ],
              [
                -41.13470594896397,
                -4.997269454105554
              ],
              [
                -41.12222253575719,
                -5.007704858335475
              ],
              [
                -41.122031511924035,
                -5.010276826565796
              ],
              [
                -41.12104255178684,
                -5.023573224371883
              ],
              [
                -41.13142495025866,
                -5.047693548914096
              ],
              [
                -41.096626723070166,
                -5.052776997404756
              ],
              [
                -41.095488242850664,
                -5.06124493504801
              ],
              [
                -41.089193765784586,
                -5.064513398506475
              ],
              [
                -41.08787879257004,
                -5.068488389385267
              ],
              [
                -41.08697128766595,
                -5.072944338820907
              ],
              [
                -41.08382381067685,
                -5.075391804333174
              ],
              [
                -41.08091134382071,
                -5.077656817372218
              ],
              [
                -41.08005683261116,
                -5.078321281809622
              ],
              [
                -41.07992285670393,
                -5.083684259754988
              ],
              [
                -41.07974732719541,
                -5.090719187906478
              ],
              [
                -41.069343421831164,
                -5.09534215066193
              ],
              [
                -41.052035576654795,
                -5.103032578035126
              ],
              [
                -41.0432181385756,
                -5.094307167695402
              ],
              [
                -41.039888191380115,
                -5.091011684991048
              ],
              [
                -41.03362521711174,
                -5.090693687860189
              ],
              [
                -41.027802780885644,
                -5.104970082409601
              ],
              [
                -41.02602729031585,
                -5.109323520120237
              ],
              [
                -41.02303979692313,
                -5.108381049619759
              ],
              [
                -41.01369936699025,
                -5.105434049822394
              ],
              [
                -41.01225538860208,
                -5.11689995734352
              ],
              [
                -41.011458386693214,
                -5.123228912510157
              ],
              [
                -40.988227587099374,
                -5.138374279436522
              ],
              [
                -40.98208761221881,
                -5.153150687964049
              ],
              [
                -40.9745616857036,
                -5.149161197134355
              ],
              [
                -40.97320718103815,
                -5.149233715801984
              ],
              [
                -40.964255277953754,
                -5.149712215002385
              ],
              [
                -40.95403134642562,
                -5.160925090397035
              ],
              [
                -40.94894439996763,
                -5.166503548819291
              ],
              [
                -40.94503994082762,
                -5.166407069308205
              ],
              [
                -40.937370967375045,
                -5.166217047344986
              ],
              [
                -40.92860903809425,
                -5.171297014452637
              ],
              [
                -40.92513259068069,
                -5.181108939420034
              ],
              [
                -40.93141152089139,
                -5.20025728866898
              ],
              [
                -40.93157703120954,
                -5.200761802908848
              ],
              [
                -40.93044153996907,
                -5.203853271622628
              ],
              [
                -40.93033202571782,
                -5.204150778285338
              ],
              [
                -40.92638854515374,
                -5.214032196478346
              ],
              [
                -40.92503406750941,
                -5.21754015788366
              ],
              [
                -40.921654098974656,
                -5.226080601655841
              ],
              [
                -40.92866954118626,
                -5.230950077537703
              ],
              [
                -40.930350996377236,
                -5.232117048602704
              ],
              [
                -40.93841943431741,
                -5.237717516529688
              ],
              [
                -40.94159192637734,
                -5.239919987560574
              ],
              [
                -40.93254049233206,
                -5.246993436249088
              ],
              [
                -40.93245998397201,
                -5.247056427720906
              ],
              [
                -40.93012350260236,
                -5.248786929138015
              ],
              [
                -40.92503654616632,
                -5.252552880399494
              ],
              [
                -40.923081572280765,
                -5.254815873971537
              ],
              [
                -40.91821060989627,
                -5.260528327490034
              ],
              [
                -40.91143566181013,
                -5.269166262547271
              ],
              [
                -40.923863558238814,
                -5.274463232397692
              ],
              [
                -40.922770064775555,
                -5.279001196108276
              ],
              [
                -40.92159606172194,
                -5.283991640318614
              ],
              [
                -40.92168404784964,
                -5.284218141641232
              ],
              [
                -40.92350606178252,
                -5.288911115889483
              ],
              [
                -40.9255220248297,
                -5.294035087967165
              ],
              [
                -40.92366204765158,
                -5.298825031311321
              ],
              [
                -40.92088206464751,
                -5.301162537829759
              ],
              [
                -40.91988055659036,
                -5.301972993052763
              ],
              [
                -40.91589058923586,
                -5.305200484062709
              ],
              [
                -40.912911604832594,
                -5.307662961507789
              ],
              [
                -40.91305559661867,
                -5.316158887754471
              ],
              [
                -40.913134612219025,
                -5.319102376339475
              ],
              [
                -40.915257081613106,
                -5.3271693040454
              ],
              [
                -40.91727206014417,
                -5.334829745800036
              ],
              [
                -40.918074549592575,
                -5.337879726011065
              ],
              [
                -40.92269500956479,
                -5.341566202640728
              ],
              [
                -40.92310652891061,
                -5.341894715380086
              ],
              [
                -40.927347490252544,
                -5.345279181093107
              ],
              [
                -40.93333191707744,
                -5.350116155774763
              ],
              [
                -40.93572591575826,
                -5.365587550253718
              ],
              [
                -40.928977440897434,
                -5.374970949690512
              ],
              [
                -40.92793144991504,
                -5.376271949147402
              ],
              [
                -40.9277114676013,
                -5.376545435931036
              ],
              [
                -40.923437003366914,
                -5.381858899869261
              ],
              [
                -40.91610405865804,
                -5.390836836039996
              ],
              [
                -40.91232556201432,
                -5.403227248337869
              ],
              [
                -40.91117458343031,
                -5.407002204877661
              ],
              [
                -40.91736702701759,
                -5.415903126789106
              ],
              [
                -40.921036479148036,
                -5.415929165804423
              ],
              [
                -40.92476847173281,
                -5.415990665587532
              ],
              [
                -40.93804085202229,
                -5.416207165947905
              ],
              [
                -40.94207929888056,
                -5.419857116588747
              ],
              [
                -40.93635786099686,
                -5.438082503347565
              ],
              [
                -40.92887292331571,
                -5.444059422302103
              ],
              [
                -40.92138946662587,
                -5.450035902256039
              ],
              [
                -40.918739502659925,
                -5.457059362171178
              ],
              [
                -40.9154390191146,
                -5.465807774560433
              ],
              [
                -40.91813950872249,
                -5.473526201451453
              ],
              [
                -40.928780882509706,
                -5.482958157164306
              ],
              [
                -40.928743404081416,
                -5.492390576373173
              ],
              [
                -40.93267687015881,
                -5.500341500295995
              ],
              [
                -40.92954835669359,
                -5.504878480866934
              ],
              [
                -40.928380877476044,
                -5.506571463273231
              ],
              [
                -40.928499863808625,
                -5.517471390444057
              ],
              [
                -40.93105184758843,
                -5.520347858138249
              ],
              [
                -40.935978297761636,
                -5.525901327942189
              ],
              [
                -40.9313763302252,
                -5.536052265929831
              ],
              [
                -40.93200634025858,
                -5.548520157541345
              ],
              [
                -40.91988543440156,
                -5.561154070012618
              ],
              [
                -40.914002467095486,
                -5.567285990760003
              ],
              [
                -40.91298048063988,
                -5.578690909448165
              ],
              [
                -40.91195946616128,
                -5.590096827142526
              ],
              [
                -40.90464202681581,
                -5.602821234905818
              ],
              [
                -40.903666023971994,
                -5.604517737407986
              ],
              [
                -40.902176549120824,
                -5.609954693173483
              ],
              [
                -40.90068755626243,
                -5.615392118937458
              ],
              [
                -40.90731447791488,
                -5.641967933265731
              ],
              [
                -40.9108559422113,
                -5.656182829584434
              ],
              [
                -40.920684871262324,
                -5.659792824379892
              ],
              [
                -40.92874478860854,
                -5.662753297998269
              ],
              [
                -40.938391711049505,
                -5.672964221627201
              ],
              [
                -40.9304322482932,
                -5.685172601993118
              ],
              [
                -40.92999925751622,
                -5.685836127686428
              ],
              [
                -40.92687826711871,
                -5.705838466020202
              ],
              [
                -40.91316340646827,
                -5.726367788638282
              ],
              [
                -40.91527039100081,
                -5.735392216571207
              ],
              [
                -40.9173768485282,
                -5.744417184513888
              ],
              [
                -40.909378402469585,
                -5.76532451895488
              ],
              [
                -40.906174899728605,
                -5.794468781112127
              ],
              [
                -40.90867687088298,
                -5.82515555037485
              ],
              [
                -40.90697236254247,
                -5.837026951411204
              ],
              [
                -40.905268395182496,
                -5.848898371460497
              ],
              [
                -40.90311440426992,
                -5.855118817986831
              ],
              [
                -40.89323445784163,
                -5.883651609664614
              ],
              [
                -40.89518894074651,
                -5.892285025644161
              ],
              [
                -40.89714291564676,
                -5.900918978632282
              ],
              [
                -40.8797595547666,
                -5.918038356748192
              ],
              [
                -40.87406206959671,
                -5.974331924480646
              ],
              [
                -40.88144002092192,
                -5.982687377266134
              ],
              [
                -40.88697845111793,
                -5.98895932758318
              ],
              [
                -40.890978406462615,
                -6.005442698786752
              ],
              [
                -40.90356830841082,
                -6.020495569590794
              ],
              [
                -40.905499765609356,
                -6.033254010616125
              ],
              [
                -40.90672724083736,
                -6.041362929265936
              ],
              [
                -40.90515374597326,
                -6.045997401247644
              ],
              [
                -40.901829307068176,
                -6.048817866734557
              ],
              [
                -40.88419843745869,
                -6.047433381313398
              ],
              [
                -40.88390244596683,
                -6.047410374287864
              ],
              [
                -40.878424470258146,
                -6.051643830657856
              ],
              [
                -40.87294654055046,
                -6.055878320017652
              ],
              [
                -40.85709365200226,
                -6.104917426374715
              ],
              [
                -40.84653022481864,
                -6.131713237142674
              ],
              [
                -40.85274264588028,
                -6.164817468887994
              ],
              [
                -40.846851664573336,
                -6.202632203001404
              ],
              [
                -40.84934914521857,
                -6.213235622884614
              ],
              [
                -40.85184712384668,
                -6.223839016788193
              ],
              [
                -40.82319832077125,
                -6.257880244699578
              ],
              [
                -40.810125929965494,
                -6.286680035868126
              ],
              [
                -40.786849105855765,
                -6.304509901441093
              ],
              [
                -40.78074515380769,
                -6.323861251570223
              ],
              [
                -40.77994515185961,
                -6.333408177193671
              ],
              [
                -40.779145140901655,
                -6.34295510082665
              ],
              [
                -40.789971054434616,
                -6.371503388829888
              ],
              [
                -40.7968784772361,
                -6.378464851401415
              ],
              [
                -40.799710960319956,
                -6.381319846955123
              ],
              [
                -40.804776906487,
                -6.392746720701021
              ],
              [
                -40.79571093004447,
                -6.427799966998727
              ],
              [
                -40.7935729289067,
                -6.457430240045782
              ],
              [
                -40.79489093116714,
                -6.463873218639803
              ],
              [
                -40.7962094254183,
                -6.470316640237862
              ],
              [
                -40.790648433090745,
                -6.49846945804379
              ],
              [
                -40.79020544421033,
                -6.500714411886904
              ],
              [
                -40.79029245499815,
                -6.501376926980867
              ],
              [
                -40.791747426705626,
                -6.512476821792347
              ],
              [
                -40.78996695557976,
                -6.516837329809271
              ],
              [
                -40.78728796244414,
                -6.523397237686313
              ],
              [
                -40.784933966455974,
                -6.525961236758498
              ],
              [
                -40.74547526713978,
                -6.565161422221119
              ],
              [
                -40.73524335727459,
                -6.599814143021177
              ],
              [
                -40.72843539038589,
                -6.608555559533757
              ],
              [
                -40.72162743249558,
                -6.617296994043313
              ],
              [
                -40.731554326867524,
                -6.653683257798165
              ],
              [
                -40.70979852740268,
                -6.675743580561131
              ],
              [
                -40.65041000655572,
                -6.678371028989771
              ],
              [
                -40.641225579792184,
                -6.688443436725823
              ],
              [
                -40.632042149020585,
                -6.698516857448734
              ],
              [
                -40.60674433983262,
                -6.713685707032425
              ],
              [
                -40.56277523049514,
                -6.719837138307102
              ],
              [
                -40.51701108352921,
                -6.730107026332987
              ],
              [
                -40.475860443329694,
                -6.734121464679153
              ],
              [
                -40.47156245902806,
                -6.744542895273498
              ],
              [
                -40.4688820059106,
                -6.747023863662802
              ],
              [
                -40.464611028185544,
                -6.750976345825781
              ],
              [
                -40.458440067264185,
                -6.756687797373377
              ],
              [
                -40.45488309700124,
                -6.770680205724569
              ],
              [
                -40.451652118913564,
                -6.783389598958219
              ],
              [
                -40.447102141226395,
                -6.786438584685689
              ],
              [
                -40.442555186516856,
                -6.789485540429109
              ],
              [
                -40.44225368811237,
                -6.790771552555674
              ],
              [
                -40.438613711361576,
                -6.806298927353374
              ],
              [
                -40.43179327450594,
                -6.812590356052294
              ],
              [
                -40.41646087993924,
                -6.812141858925625
              ],
              [
                -40.412388924908015,
                -6.808922895026799
              ],
              [
                -40.403761513236205,
                -6.8021024387517
              ],
              [
                -40.388414641607646,
                -6.796612449488448
              ],
              [
                -40.37021027063185,
                -6.802665408438593
              ],
              [
                -40.37020179900761,
                -6.807603872352415
              ],
              [
                -40.3713482783673,
                -6.808813375129223
              ],
              [
                -40.38121267881712,
                -6.819216299425125
              ],
              [
                -40.38629963541268,
                -6.828829220099973
              ],
              [
                -40.40184399435602,
                -6.839603646947608
              ],
              [
                -40.409011921648535,
                -6.85269656215467
              ],
              [
                -40.41275888908571,
                -6.856355045548827
              ],
              [
                -40.41897832900871,
                -6.859392511468844
              ],
              [
                -40.42814173786545,
                -6.863867982839892
              ],
              [
                -40.42858372851296,
                -6.884471321784805
              ],
              [
                -40.43064770357967,
                -6.891612255852336
              ],
              [
                -40.43271219563648,
                -6.898752725933348
              ],
              [
                -40.43243567704109,
                -6.899991717443604
              ],
              [
                -40.424643747576404,
                -6.93488147025897
              ],
              [
                -40.42874619463889,
                -6.948112852352095
              ],
              [
                -40.428759703911766,
                -6.95152432383116
              ],
              [
                -40.42891817248434,
                -6.990684050987767
              ],
              [
                -40.428932671621006,
                -6.994240001405383
              ],
              [
                -40.42793564489596,
                -6.994483513817142
              ],
              [
                -40.41069730199131,
                -6.998685461124574
              ],
              [
                -40.405874848113704,
                -7.004936401656561
              ],
              [
                -40.42578315996547,
                -7.014176357908261
              ],
              [
                -40.42747613998748,
                -7.014961848342231
              ],
              [
                -40.427637652199216,
                -7.018087814097962
              ],
              [
                -40.436803094269024,
                -7.015676842196093
              ],
              [
                -40.430417126008045,
                -7.02402981282818
              ],
              [
                -40.426563133818426,
                -7.029070752171104
              ],
              [
                -40.42734164578381,
                -7.033491736730953
              ],
              [
                -40.44053152647888,
                -7.045538148933559
              ],
              [
                -40.44290551864553,
                -7.047706140578515
              ],
              [
                -40.443393513458645,
                -7.051623582694186
              ],
              [
                -40.44388100827457,
                -7.05554057381511
              ],
              [
                -40.44639597500461,
                -7.059299042697441
              ],
              [
                -40.45901532721158,
                -7.078151414754945
              ],
              [
                -40.463775811725895,
                -7.082106386945207
              ],
              [
                -40.4690452719882,
                -7.087254846632669
              ],
              [
                -40.47005624146124,
                -7.093579823225444
              ],
              [
                -40.48936255427182,
                -7.118300619945503
              ],
              [
                -40.486677095350146,
                -7.125554582753477
              ],
              [
                -40.483992088420585,
                -7.13280803156806
              ],
              [
                -40.49422799163153,
                -7.165676302680724
              ],
              [
                -40.49533398543801,
                -7.169226765104813
              ],
              [
                -40.49521298713409,
                -7.170906758494683
              ],
              [
                -40.49441348516038,
                -7.181954187576947
              ],
              [
                -40.49866393683067,
                -7.186074645254985
              ],
              [
                -40.50091890096511,
                -7.188260619762759
              ],
              [
                -40.51014680017071,
                -7.226575357901793
              ],
              [
                -40.51475372364591,
                -7.273303008057178
              ],
              [
                -40.51179426211099,
                -7.301410784770061
              ],
              [
                -40.51482269865578,
                -7.30730724839515
              ],
              [
                -40.51785067720202,
                -7.313202200039504
              ],
              [
                -40.523644612289665,
                -7.317849204192844
              ],
              [
                -40.54112999760751,
                -7.331874097169324
              ],
              [
                -40.54064545961175,
                -7.338165542073068
              ],
              [
                -40.540161482606806,
                -7.34445701998132
              ],
              [
                -40.54808086541305,
                -7.392315162360444
              ],
              [
                -40.651943442626084,
                -7.432950432375463
              ],
              [
                -40.652355467496484,
                -7.433725442979792
              ],
              [
                -40.65673641558736,
                -7.441961367652258
              ],
              [
                -40.656796423985696,
                -7.442074360865792
              ],
              [
                -40.65693741157294,
                -7.442338856025245
              ],
              [
                -40.65809389297982,
                -7.444513865888324
              ],
              [
                -40.65974190222555,
                -7.445475354133091
              ],
              [
                -40.67100328640754,
                -7.452046297813615
              ],
              [
                -40.678175721546936,
                -7.453395297751392
              ],
              [
                -40.67831522634363,
                -7.453421793670544
              ],
              [
                -40.678689249118165,
                -7.453491792461381
              ],
              [
                -40.704524005343316,
                -7.458350791858357
              ],
              [
                -40.712220427100235,
                -7.47171768245359
              ],
              [
                -40.71287341763162,
                -7.472850701623693
              ],
              [
                -40.713624422782516,
                -7.4890775683908
              ],
              [
                -40.6939900335153,
                -7.537598692824731
              ],
              [
                -40.651952337240566,
                -7.591628277913342
              ],
              [
                -40.647988876202085,
                -7.599487214596994
              ],
              [
                -40.64402640815184,
                -7.607345143290137
              ],
              [
                -40.64737833859358,
                -7.647667858729095
              ],
              [
                -40.664687182078914,
                -7.687373585808673
              ],
              [
                -40.677822532805145,
                -7.72469280424046
              ],
              [
                -40.67317903666071,
                -7.761288044022088
              ],
              [
                -40.672496531835634,
                -7.76199453623992
              ],
              [
                -40.6533201862613,
                -7.781822889926119
              ],
              [
                -40.62826939072102,
                -7.792178271203504
              ],
              [
                -40.57439734824742,
                -7.806652152319324
              ],
              [
                -40.56318443858769,
                -7.812233084581496
              ],
              [
                -40.555461492799665,
                -7.818405021494458
              ],
              [
                -40.5472970335091,
                -7.828328952404418
              ],
              [
                -40.543360079994315,
                -7.835174391620517
              ],
              [
                -40.53785361036287,
                -7.866647653260944
              ],
              [
                -40.54440148108615,
                -7.947248582446532
              ],
              [
                -40.54422848602442,
                -7.952387542546679
              ],
              [
                -40.54406298107168,
                -7.957327506118125
              ],
              [
                -40.54383148895837,
                -7.964212963347102
              ],
              [
                -40.5754326641198,
                -8.03008402686179
              ],
              [
                -40.57866811731038,
                -8.037186472658792
              ],
              [
                -40.58190308149994,
                -8.044288389470864
              ],
              [
                -40.59837740022767,
                -8.080459173131203
              ],
              [
                -40.60346636058641,
                -8.10318598528648
              ],
              [
                -40.596827895198444,
                -8.116226903897894
              ],
              [
                -40.59611791296926,
                -8.117621398056311
              ],
              [
                -40.590855949694,
                -8.123115830131047
              ],
              [
                -40.58940546094916,
                -8.137575741018843
              ],
              [
                -40.59705787425415,
                -8.141678198877475
              ],
              [
                -40.60076284099591,
                -8.143664689675262
              ],
              [
                -40.600795854096454,
                -8.144348675701831
              ],
              [
                -40.60105885799021,
                -8.149730164576738
              ],
              [
                -40.61129227220617,
                -8.146301673853632
              ],
              [
                -40.63263509750344,
                -8.139151755324697
              ],
              [
                -40.637937040560104,
                -8.14681772504698
              ],
              [
                -40.64234899755426,
                -8.149643687360465
              ],
              [
                -40.64262249497697,
                -8.152131681421473
              ],
              [
                -40.64317345875836,
                -8.157138624323647
              ],
              [
                -40.65307439931762,
                -8.156498166925692
              ],
              [
                -40.658115353838475,
                -8.156172657847165
              ],
              [
                -40.666215787896235,
                -8.161911610222054
              ],
              [
                -40.66814774648842,
                -8.174026525474872
              ],
              [
                -40.66863473357596,
                -8.177081494603796
              ],
              [
                -40.67487316890986,
                -8.184478985226919
              ],
              [
                -40.67819314678578,
                -8.185530445553944
              ],
              [
                -40.68403712052286,
                -8.187380942333565
              ],
              [
                -40.69398052121477,
                -8.20048735868392
              ],
              [
                -40.70021146278503,
                -8.20221684471577
              ],
              [
                -40.703461942879144,
                -8.212426284183504
              ],
              [
                -40.70623140542428,
                -8.221125745246983
              ],
              [
                -40.70889338291019,
                -8.22324019624751
              ],
              [
                -40.723686250588784,
                -8.224196230735911
              ],
              [
                -40.72860870355967,
                -8.224514196897953
              ],
              [
                -40.73649314266329,
                -8.227838186845673
              ],
              [
                -40.74024858847217,
                -8.233669170768156
              ],
              [
                -40.744123057098804,
                -8.239685138460752
              ],
              [
                -40.74893252752377,
                -8.24164813454572
              ],
              [
                -40.76364789489676,
                -8.243712607938248
              ],
              [
                -40.76824833160915,
                -8.248383072122733
              ],
              [
                -40.77509480978164,
                -8.2514310671832
              ],
              [
                -40.78240171686708,
                -8.254684038179162
              ],
              [
                -40.77889923944135,
                -8.278630378658939
              ],
              [
                -40.77788024863331,
                -8.285597329021535
              ],
              [
                -40.780699219280216,
                -8.291500278656647
              ],
              [
                -40.78128169821427,
                -8.292720291314994
              ],
              [
                -40.78103821616293,
                -8.293923768334981
              ],
              [
                -40.78068522754344,
                -8.29566273735841
              ],
              [
                -40.77948918822517,
                -8.301547696444052
              ],
              [
                -40.78238068888778,
                -8.303524696721933
              ],
              [
                -40.78605513641898,
                -8.306037181827334
              ],
              [
                -40.78932012724713,
                -8.313044616868527
              ],
              [
                -40.79196760053579,
                -8.318725101000437
              ],
              [
                -40.801674008361886,
                -8.31972560267613
              ],
              [
                -40.819126343839464,
                -8.321523577706609
              ],
              [
                -40.818162357380174,
                -8.328642024082301
              ],
              [
                -40.81801335061813,
                -8.329742037950677
              ],
              [
                -40.8208263461305,
                -8.334743512205934
              ],
              [
                -40.82228181738954,
                -8.337331486747392
              ],
              [
                -40.821892840010484,
                -8.34019945255041
              ],
              [
                -40.81894181169182,
                -8.361958816754525
              ],
              [
                -40.82798224632466,
                -8.371347236965972
              ],
              [
                -40.83702216095766,
                -8.380735159212172
              ],
              [
                -40.8581940050877,
                -8.379210197966247
              ],
              [
                -40.85696800218739,
                -8.375244246606105
              ],
              [
                -40.858844963933954,
                -8.371360257537212
              ],
              [
                -40.86200498030339,
                -8.364819830939776
              ],
              [
                -40.87073688068604,
                -8.358078393972557
              ],
              [
                -40.893298191329784,
                -8.35742339666569
              ],
              [
                -40.89735366654628,
                -8.367524819152955
              ],
              [
                -40.89485319191535,
                -8.369437305956048
              ],
              [
                -40.89228971977357,
                -8.371397280355712
              ],
              [
                -40.88948421149043,
                -8.386345695326517
              ],
              [
                -40.89413065773837,
                -8.392118150797787
              ],
              [
                -40.89818763151875,
                -8.394120650061327
              ],
              [
                -40.907230029794114,
                -8.398584121139447
              ],
              [
                -40.901467110973876,
                -8.407708541623053
              ],
              [
                -40.90168359998515,
                -8.411058533545361
              ],
              [
                -40.90243457466927,
                -8.422679434033997
              ],
              [
                -40.91397246564349,
                -8.435783838905582
              ],
              [
                -40.92634084446976,
                -8.445836264712103
              ],
              [
                -40.93770228598646,
                -8.434592867779898
              ],
              [
                -40.95670511614169,
                -8.423070493017486
              ],
              [
                -40.95735210880766,
                -8.419749482668005
              ],
              [
                -40.957497121282145,
                -8.419007506175936
              ],
              [
                -40.960484079111424,
                -8.417853034331866
              ],
              [
                -40.96190109064507,
                -8.41730554462273
              ],
              [
                -40.96511756861849,
                -8.416062547633882
              ],
              [
                -40.965195064072596,
                -8.411710045219221
              ],
              [
                -40.98045145498606,
                -8.398022673591269
              ],
              [
                -40.99980628527271,
                -8.400252170548567
              ],
              [
                -41.00472573457399,
                -8.414147074597805
              ],
              [
                -41.00699117837885,
                -8.420544023438046
              ],
              [
                -41.020593593991734,
                -8.425227527320523
              ],
              [
                -41.03861643583127,
                -8.431432480376571
              ],
              [
                -41.04389085774392,
                -8.44147792871863
              ],
              [
                -41.045559859626366,
                -8.444656918312655
              ],
              [
                -41.047420317408246,
                -8.448343348422412
              ],
              [
                -41.05072226730355,
                -8.480081656245787
              ],
              [
                -41.0785505488487,
                -8.472113719085579
              ],
              [
                -41.080719529027554,
                -8.484171126090809
              ],
              [
                -41.08288847718906,
                -8.49622855912031
              ],
              [
                -41.090111422878294,
                -8.512226454544919
              ],
              [
                -41.0975713570817,
                -8.5203649189321
              ],
              [
                -41.10169732420403,
                -8.521448387041763
              ],
              [
                -41.1036938008439,
                -8.521972892158113
              ],
              [
                -41.10674226763456,
                -8.526629858479012
              ],
              [
                -41.111073246711484,
                -8.533246822897818
              ],
              [
                -41.12715356579674,
                -8.540699769506986
              ],
              [
                -41.13212303294936,
                -8.535401805422588
              ],
              [
                -41.13423653527773,
                -8.533149325668667
              ],
              [
                -41.14826492254385,
                -8.533508854449106
              ],
              [
                -41.151800366029,
                -8.545641277424405
              ],
              [
                -41.16301076468538,
                -8.570250079949107
              ],
              [
                -41.16877668279039,
                -8.582906998381908
              ],
              [
                -41.17391267279743,
                -8.585654500539794
              ],
              [
                -41.17824410401006,
                -8.587971960023754
              ],
              [
                -41.17857862194739,
                -8.589251987136153
              ],
              [
                -41.17952561044257,
                -8.592878435957532
              ],
              [
                -41.18625005099684,
                -8.592589439496052
              ],
              [
                -41.19817244293173,
                -8.592076957636904
              ],
              [
                -41.2024814069876,
                -8.598880423856162
              ],
              [
                -41.21158082419505,
                -8.602025900995491
              ],
              [
                -41.207383349999574,
                -8.609838332794576
              ],
              [
                -41.207193331761445,
                -8.613787327226019
              ],
              [
                -41.20626634583067,
                -8.63303816698347
              ],
              [
                -41.21161828679387,
                -8.639086149700232
              ],
              [
                -41.21697175674076,
                -8.645135107424005
              ],
              [
                -41.2253426801182,
                -8.646193111922846
              ],
              [
                -41.233397609207316,
                -8.647210098414062
              ],
              [
                -41.236808057642904,
                -8.652130588386052
              ],
              [
                -41.23910253006064,
                -8.655440549845462
              ],
              [
                -41.25346539776551,
                -8.661789036200293
              ],
              [
                -41.28715215205432,
                -8.660582565537737
              ],
              [
                -41.30947690875082,
                -8.691358864589496
              ],
              [
                -41.336602679923764,
                -8.707964761089942
              ],
              [
                -41.357936490023796,
                -8.707229271199616
              ],
              [
                -41.36935090950158,
                -8.705645791689703
              ],
              [
                -41.37891533054668,
                -8.707118299499376
              ],
              [
                -41.37942732801544,
                -8.707343285389813
              ],
              [
                -41.381959801604296,
                -8.70845478891161
              ],
              [
                -41.39198419584429,
                -8.72301820852596
              ],
              [
                -41.394179685211334,
                -8.726208162859864
              ],
              [
                -41.3970546402286,
                -8.736089601009235
              ],
              [
                -41.39993011222915,
                -8.745971547174491
              ],
              [
                -41.411672515036855,
                -8.77351385388552
              ],
              [
                -41.423840368170396,
                -8.80206267300376
              ],
              [
                -41.427689343484204,
                -8.799366707058777
              ],
              [
                -41.43153779780317,
                -8.796671223108804
              ],
              [
                -41.46226153766503,
                -8.82834752552265
              ],
              [
                -41.46275799773978,
                -8.829094996684855
              ],
              [
                -41.47180191200737,
                -8.842711429584133
              ],
              [
                -41.4784128820868,
                -8.843042919798567
              ],
              [
                -41.48746330353806,
                -8.843497952561867
              ],
              [
                -41.493461246570945,
                -8.858931819558217
              ],
              [
                -41.49415623992572,
                -8.860719818512964
              ],
              [
                -41.49566822666822,
                -8.861262317156273
              ],
              [
                -41.502095643315485,
                -8.8635658111598
              ],
              [
                -41.510097570219315,
                -8.876279221610263
              ],
              [
                -41.50886956307583,
                -8.882674683846457
              ],
              [
                -41.50706959572467,
                -8.89204509933533
              ],
              [
                -41.51597600888095,
                -8.897951581214377
              ],
              [
                -41.51840100086986,
                -8.899559560200965
              ],
              [
                -41.52253144080545,
                -8.904836547791524
              ],
              [
                -41.525792413582224,
                -8.913525477245416
              ],
              [
                -41.53096336112352,
                -8.927304363436265
              ],
              [
                -41.537714809161635,
                -8.932440363707041
              ],
              [
                -41.54446574920401,
                -8.937576807986284
              ],
              [
                -41.54442073744033,
                -8.940660796018477
              ],
              [
                -41.544135225964425,
                -8.960144162261313
              ],
              [
                -41.565301054494746,
                -8.968591628633572
              ],
              [
                -41.56748801627041,
                -8.961600178501897
              ],
              [
                -41.56966603108652,
                -8.954638224154616
              ],
              [
                -41.58963435105971,
                -8.954944722141601
              ],
              [
                -41.596526773552,
                -8.95849523189372
              ],
              [
                -41.60541122248344,
                -8.956997723504365
              ],
              [
                -41.609405147204804,
                -8.96652517391214
              ],
              [
                -41.60954964063857,
                -8.966870185609562
              ],
              [
                -41.61068415474444,
                -8.967230664199
              ],
              [
                -41.62082355931988,
                -8.970455662575194
              ],
              [
                -41.62156657223518,
                -8.973235151605202
              ],
              [
                -41.623713037982974,
                -8.981267075795023
              ],
              [
                -41.62861299355043,
                -8.984484553939652
              ],
              [
                -41.64600335329834,
                -8.986718079742579
              ],
              [
                -41.66902616607966,
                -8.989674558157644
              ],
              [
                -41.669391621785316,
                -8.999621990027794
              ],
              [
                -41.66951863616747,
                -9.00307395969467
              ],
              [
                -41.68006503862482,
                -9.011875926066491
              ],
              [
                -41.71200728476245,
                -9.012808431007764
              ],
              [
                -41.72322017740906,
                -9.013135941120067
              ],
              [
                -41.72581365790175,
                -9.016783414946106
              ],
              [
                -41.72840759738935,
                -9.020430913776385
              ],
              [
                -41.7325730474761,
                -9.04556272121138
              ],
              [
                -41.73160105126585,
                -9.057487148874433
              ],
              [
                -41.73062955203515,
                -9.069411562553757
              ],
              [
                -41.73479951819098,
                -9.094294899937394
              ],
              [
                -41.75855426513608,
                -9.116254752281574
              ],
              [
                -41.76698419143069,
                -9.121083236890554
              ],
              [
                -41.77541362873339,
                -9.125911192516565
              ],
              [
                -41.79968192162823,
                -9.147620090459133
              ],
              [
                -41.79971191438135,
                -9.1561870290721
              ],
              [
                -41.79974140812984,
                -9.164753965694064
              ],
              [
                -41.81951921421671,
                -9.188696295512248
              ],
              [
                -41.837579020668976,
                -9.241863937022906
              ],
              [
                -41.845984946740835,
                -9.243297454746665
              ],
              [
                -41.851363405305314,
                -9.240840966666502
              ],
              [
                -41.85932382450451,
                -9.23720502058778
              ],
              [
                -41.870135749167034,
                -9.237025500181456
              ],
              [
                -41.88405161920925,
                -9.252507928912008
              ],
              [
                -41.88454908845589,
                -9.253061427543392
              ],
              [
                -41.89328102747532,
                -9.255775406639252
              ],
              [
                -41.90201245050579,
                -9.258489395742508
              ],
              [
                -41.90286796046133,
                -9.260258369211318
              ],
              [
                -41.90920386946239,
                -9.273363312807298
              ],
              [
                -41.91754832062225,
                -9.278057258629046
              ],
              [
                -41.92438224615282,
                -9.277389285522082
              ],
              [
                -41.93626615626973,
                -9.267515352378881
              ],
              [
                -41.937052663387576,
                -9.266861860796038
              ],
              [
                -41.93867213427076,
                -9.266458378334718
              ],
              [
                -41.95779500641486,
                -9.261689413955043
              ],
              [
                -41.96483741670159,
                -9.254959485616036
              ],
              [
                -41.97188038198626,
                -9.248229510274253
              ],
              [
                -41.99955214419169,
                -9.265540429925869
              ],
              [
                -42.020962474105374,
                -9.249773083096057
              ],
              [
                -42.02776989405933,
                -9.25274356743028
              ],
              [
                -42.034577848011565,
                -9.255714548768452
              ],
              [
                -42.051394215623986,
                -9.25564105801229
              ],
              [
                -42.06161362132697,
                -9.255595082111464
              ],
              [
                -42.066128588646535,
                -9.255574575017233
              ],
              [
                -42.100143259856296,
                -9.280619917499834
              ],
              [
                -42.13652595972236,
                -9.286697403495147
              ],
              [
                -42.17910660855947,
                -9.2848574925142
              ],
              [
                -42.18692206273891,
                -9.288260473013114
              ],
              [
                -42.193846499767034,
                -9.291276436280157
              ],
              [
                -42.20604838586988,
                -9.292378434536275
              ],
              [
                -42.21828529686094,
                -9.286825990333673
              ],
              [
                -42.22577574410053,
                -9.283427030037336
              ],
              [
                -42.23455715219915,
                -9.285968031608782
              ],
              [
                -42.2433395862958,
                -9.288509509185657
              ],
              [
                -42.250398005399724,
                -9.294169479159553
              ],
              [
                -42.269196356566056,
                -9.309242383943134
              ],
              [
                -42.27936776194469,
                -9.309167404305972
              ],
              [
                -42.28953966533173,
                -9.309091890675328
              ],
              [
                -42.31877144237015,
                -9.319846865854213
              ],
              [
                -42.32211490545646,
                -9.326391835797416
              ],
              [
                -42.32545836753786,
                -9.332936793751477
              ],
              [
                -42.332068789477056,
                -9.33667126279781
              ],
              [
                -42.35471261318301,
                -9.349463185939184
              ],
              [
                -42.356819067771305,
                -9.36223060129529
              ],
              [
                -42.35875655685831,
                -9.37397650360736
              ],
              [
                -42.36211101432638,
                -9.379136002303799
              ],
              [
                -42.39849518455603,
                -9.396678399461628
              ],
              [
                -42.42074299181095,
                -9.411999309987035
              ],
              [
                -42.42283098180889,
                -9.414411822480918
              ],
              [
                -42.425411430382326,
                -9.427148727787149
              ],
              [
                -42.427889388345726,
                -9.439377652538495
              ],
              [
                -42.44252476329972,
                -9.45699503511533
              ],
              [
                -42.44694973193377,
                -9.459343534625017
              ],
              [
                -42.451375668559834,
                -9.461693008132583
              ],
              [
                -42.45231070292594,
                -9.461569012009528
              ],
              [
                -42.473201508399605,
                -9.458794563971844
              ],
              [
                -42.487505381799714,
                -9.461309561086662
              ],
              [
                -42.49082837248656,
                -9.469198512001569
              ],
              [
                -42.48801186127326,
                -9.476987425932956
              ],
              [
                -42.48567388654419,
                -9.483452402566831
              ],
              [
                -42.491783812167654,
                -9.493047333729951
              ],
              [
                -42.521609552936475,
                -9.502543309531392
              ],
              [
                -42.54877436227148,
                -9.495467884226976
              ],
              [
                -42.55878078596276,
                -9.49840584383206
              ],
              [
                -42.56878768665978,
                -9.50134335645092
              ],
              [
                -42.57079815162046,
                -9.500764372657994
              ],
              [
                -42.586087543659715,
                -9.496360408863996
              ],
              [
                -42.59464294780644,
                -9.504356346915756
              ],
              [
                -42.59489197036121,
                -9.504697850826382
              ],
              [
                -42.59647344483392,
                -9.50686482257099
              ],
              [
                -42.60280288968535,
                -9.51553777853256
              ],
              [
                -42.621248213519124,
                -9.540822610030885
              ],
              [
                -42.6319616268368,
                -9.537998163331181
              ],
              [
                -42.639827055568134,
                -9.543420137535266
              ],
              [
                -42.647247986446516,
                -9.542168154352275
              ],
              [
                -42.65421943397234,
                -9.540992148149172
              ],
              [
                -42.67421027211249,
                -9.548307626668612
              ],
              [
                -42.6908721190452,
                -9.54474367563209
              ],
              [
                -42.70953046432324,
                -9.531479272720894
              ],
              [
                -42.71965890020505,
                -9.53079828457448
              ],
              [
                -42.71766439000031,
                -9.546638683104003
              ],
              [
                -42.71650188828666,
                -9.555869633156288
              ],
              [
                -42.73205475610168,
                -9.568696058911186
              ],
              [
                -42.73693223243317,
                -9.575179009626371
              ],
              [
                -42.736934719408666,
                -9.575182498605075
              ],
              [
                -42.74181016875694,
                -9.581661977355143
              ],
              [
                -42.743466151183,
                -9.582429478901659
              ],
              [
                -42.757356050944644,
                -9.588870443928595
              ],
              [
                -42.75904799871559,
                -9.599720375087042
              ],
              [
                -42.76049647025146,
                -9.609006809758021
              ],
              [
                -42.76477492455695,
                -9.616339250040664
              ],
              [
                -42.80220513979336,
                -9.621652262146329
              ],
              [
                -42.81118256712437,
                -9.620236789880629
              ],
              [
                -42.82015997946482,
                -9.618821776611066
              ],
              [
                -42.82112750428466,
                -9.598622433609815
              ],
              [
                -42.8211360064214,
                -9.598438930881539
              ],
              [
                -42.825246486318505,
                -9.589670009771773
              ],
              [
                -42.825259973239646,
                -9.589641010986197
              ],
              [
                -42.82938343505573,
                -9.580843555089253
              ],
              [
                -42.82942093834177,
                -9.57676310120613
              ],
              [
                -42.829593484936275,
                -9.558077211991627
              ],
              [
                -42.83674692990688,
                -9.549961768769233
              ],
              [
                -42.845466833458964,
                -9.549390769347708
              ],
              [
                -42.86602867046422,
                -9.560989718312912
              ],
              [
                -42.87052162748094,
                -9.563524235861014
              ],
              [
                -42.87225860090323,
                -9.562834234532403
              ],
              [
                -42.87399709531443,
                -9.562143227212326
              ],
              [
                -42.87618507308379,
                -9.55961076205489
              ],
              [
                -42.90525339373977,
                -9.525967500629795
              ],
              [
                -42.91663827723144,
                -9.526401527229913
              ],
              [
                -42.92802319673786,
                -9.526835540834368
              ],
              [
                -42.94585454144215,
                -9.51800562125811
              ],
              [
                -42.950971515216125,
                -9.509971654146714
              ],
              [
                -42.95125601099139,
                -9.501828210454196
              ],
              [
                -42.951416515801526,
                -9.497241761170864
              ],
              [
                -42.95599700080532,
                -9.484900331098704
              ],
              [
                -42.96174597466858,
                -9.469410438978434
              ],
              [
                -42.96212249068222,
                -9.463028493298824
              ],
              [
                -42.96249949968693,
                -9.456646539624558
              ],
              [
                -42.95921951888803,
                -9.443052651577027
              ],
              [
                -42.95693756223808,
                -9.43359169720248
              ],
              [
                -42.957472065677656,
                -9.431027706440496
              ],
              [
                -42.95800604612076,
                -9.428463727678327
              ],
              [
                -42.97149694792228,
                -9.407653879730837
              ],
              [
                -42.980274372081304,
                -9.404447428405314
              ],
              [
                -42.981205852776505,
                -9.404106932767993
              ],
              [
                -42.982965342977906,
                -9.403464432115117
              ],
              [
                -42.98746983663955,
                -9.40092992948917
              ],
              [
                -43.00128322261689,
                -9.407352426379317
              ],
              [
                -43.006062157750385,
                -9.409574420315087
              ],
              [
                -43.008896126486604,
                -9.40858291823956
              ],
              [
                -43.01173112121475,
                -9.407591911161411
              ],
              [
                -43.01557511079367,
                -9.408252422822436
              ],
              [
                -43.05417478332417,
                -9.414882925487854
              ],
              [
                -43.05664973015699,
                -9.417380418029339
              ],
              [
                -43.05807874386653,
                -9.406147479830759
              ],
              [
                -43.05926175543985,
                -9.396851071056965
              ],
              [
                -43.06621818969796,
                -9.393640572746463
              ],
              [
                -43.06670270033433,
                -9.393976557965875
              ],
              [
                -43.074166604113564,
                -9.399152529541908
              ],
              [
                -43.08249155003272,
                -9.396917571020248
              ],
              [
                -43.08480505840378,
                -9.391129622344469
              ],
              [
                -43.09534297230882,
                -9.385111148247868
              ],
              [
                -43.10370237446025,
                -9.386036666027934
              ],
              [
                -43.10702185095185,
                -9.378083238330253
              ],
              [
                -43.11129331055519,
                -9.380100235134593
              ],
              [
                -43.11960276399405,
                -9.372130274999677
              ],
              [
                -43.123468237969504,
                -9.378637233501644
              ],
              [
                -43.127518693879296,
                -9.379319241243998
              ],
              [
                -43.12901518128527,
                -9.379570757152228
              ],
              [
                -43.13275316246196,
                -9.386838187307381
              ],
              [
                -43.14520404375214,
                -9.377159768423875
              ],
              [
                -43.14682103984277,
                -9.375902791828013
              ],
              [
                -43.15252347352704,
                -9.376266297567826
              ],
              [
                -43.15822745520227,
                -9.376629801310562
              ],
              [
                -43.164706372842694,
                -9.391101176023655
              ],
              [
                -43.173532300316815,
                -9.41081457738741
              ],
              [
                -43.191304140896655,
                -9.420026031720628
              ],
              [
                -43.19833206284251,
                -9.417576042253975
              ],
              [
                -43.2011920768328,
                -9.411742112413101
              ],
              [
                -43.205769549602095,
                -9.402406151482284
              ],
              [
                -43.21823544557443,
                -9.400213198213306
              ],
              [
                -43.22810384800474,
                -9.402425674879263
              ],
              [
                -43.22859936580829,
                -9.402537182658987
              ],
              [
                -43.23820376671277,
                -9.40897015610925
              ],
              [
                -43.24194623316518,
                -9.411477125040143
              ],
              [
                -43.247163681291525,
                -9.41148162174876
              ],
              [
                -43.26627301628848,
                -9.41150013064838
              ],
              [
                -43.26989448444814,
                -9.417854096103879
              ],
              [
                -43.272469470252155,
                -9.422370602002449
              ],
              [
                -43.27836892579279,
                -9.42416608420692
              ],
              [
                -43.28472235770041,
                -9.419618111356336
              ],
              [
                -43.29262080256444,
                -9.403750246733662
              ],
              [
                -43.29520132016041,
                -9.39856627208166
              ],
              [
                -43.299039266113276,
                -9.395461791567522
              ],
              [
                -43.30535021223929,
                -9.392830326530584
              ],
              [
                -43.31455566095782,
                -9.388991870939766
              ],
              [
                -43.319463622219786,
                -9.384017414403072
              ],
              [
                -43.33379653178022,
                -9.36948851865079
              ],
              [
                -43.34347794487201,
                -9.369020543479557
              ],
              [
                -43.35315986597148,
                -9.368552051313577
              ],
              [
                -43.37361772774941,
                -9.35096669316093
              ],
              [
                -43.404134526777035,
                -9.3011815574841
              ],
              [
                -43.423944859687815,
                -9.276529243949621
              ],
              [
                -43.42745686173899,
                -9.272158277716784
              ],
              [
                -43.440976253924134,
                -9.264976344624543
              ],
              [
                -43.46126959172488,
                -9.261372365380476
              ],
              [
                -43.485058400126235,
                -9.26487987724965
              ],
              [
                -43.49109133075717,
                -9.268500342037939
              ],
              [
                -43.497124296389536,
                -9.272120814833789
              ],
              [
                -43.50573272161313,
                -9.280570271409728
              ],
              [
                -43.5251610195532,
                -9.299640185221445
              ],
              [
                -43.53428194699246,
                -9.304106142672945
              ],
              [
                -43.54340334743339,
                -9.308572126138758
              ],
              [
                -43.5624591843085,
                -9.313342104428816
              ],
              [
                -43.56890063885144,
                -9.314954605477162
              ],
              [
                -43.57154111974771,
                -9.316319105054411
              ],
              [
                -43.57418160964428,
                -9.31768360663294
              ],
              [
                -43.6241966568607,
                -9.37327178648249
              ],
              [
                -43.686415075860936,
                -9.417647040271948
              ],
              [
                -43.75102002858291,
                -9.43366449028373
              ],
              [
                -43.76932689025336,
                -9.441642939516747
              ],
              [
                -43.77901079167962,
                -9.45012840172696
              ],
              [
                -43.781647793560175,
                -9.452438898992108
              ],
              [
                -43.783058787128226,
                -9.454139367028988
              ],
              [
                -43.78552526664856,
                -9.457111343618923
              ],
              [
                -43.799048619262884,
                -9.479244711623021
              ],
              [
                -43.81803295391153,
                -9.493835147015114
              ],
              [
                -43.83294730106708,
                -9.51950848487027
              ],
              [
                -43.849292147669026,
                -9.547644311859308
              ],
              [
                -43.850736600819445,
                -9.554686782845161
              ],
              [
                -43.85218158595851,
                -9.56172974083719
              ],
              [
                -43.841138171443454,
                -9.585642028602372
              ],
              [
                -43.84224762470395,
                -9.612260855906692
              ],
              [
                -43.836446164921306,
                -9.615781845509476
              ],
              [
                -43.83357719029495,
                -9.617522840477962
              ],
              [
                -43.83390915920935,
                -9.622177813394172
              ],
              [
                -43.83530517974639,
                -9.62311529064778
              ],
              [
                -43.8397681119016,
                -9.626114260464075
              ],
              [
                -43.841551120748946,
                -9.637018696821606
              ],
              [
                -43.82806521900923,
                -9.647956631544092
              ],
              [
                -43.82624770211777,
                -9.649431090508617
              ],
              [
                -43.798317383257036,
                -9.694737751541942
              ],
              [
                -43.7933804216062,
                -9.7126946626288
              ],
              [
                -43.79398489502789,
                -9.714963620004543
              ],
              [
                -43.797262369920496,
                -9.727271067694536
              ],
              [
                -43.78451943128119,
                -9.761973295862585
              ],
              [
                -43.774758524581046,
                -9.766545283756919
              ],
              [
                -43.76677307937413,
                -9.770285739305383
              ],
              [
                -43.76441858905402,
                -9.773303206228759
              ],
              [
                -43.76116511872065,
                -9.777472668341364
              ],
              [
                -43.74638823968019,
                -9.783170611828563
              ],
              [
                -43.72102739832402,
                -9.792950038528652
              ],
              [
                -43.718938911224356,
                -9.798193002728304
              ],
              [
                -43.717418453157414,
                -9.802010948203083
              ],
              [
                -43.70799702226997,
                -9.805295422137984
              ],
              [
                -43.701582569910975,
                -9.805101921024349
              ],
              [
                -43.69305962386303,
                -9.80484490590248
              ],
              [
                -43.68776366697995,
                -9.810089368370752
              ],
              [
                -43.68246719510183,
                -9.815334356832642
              ],
              [
                -43.67898125179609,
                -9.81686482146553
              ],
              [
                -43.664445334125254,
                -9.823244771562333
              ],
              [
                -43.65284694782907,
                -9.838643656153671
              ],
              [
                -43.653137914134895,
                -9.844846618614344
              ],
              [
                -43.663693329526986,
                -9.85441006931904
              ],
              [
                -43.66486131572648,
                -9.855468037536465
              ],
              [
                -43.66542831130896,
                -9.862053017763408
              ],
              [
                -43.66599527188612,
                -9.868637965997038
              ],
              [
                -43.677046172825776,
                -9.886117373977577
              ],
              [
                -43.68599158477179,
                -9.888577366820726
              ],
              [
                -43.69422053681602,
                -9.890840324163964
              ],
              [
                -43.69602500325061,
                -9.900793283195373
              ],
              [
                -43.70507592511676,
                -9.905970235887626
              ],
              [
                -43.708636890881216,
                -9.912807709992723
              ],
              [
                -43.70577591175234,
                -9.921642146146171
              ],
              [
                -43.69839095882902,
                -9.923566108545552
              ],
              [
                -43.69243951263666,
                -9.925117095133485
              ],
              [
                -43.690052545424116,
                -9.929087072599273
              ],
              [
                -43.689165041280646,
                -9.938653025134363
              ],
              [
                -43.69561646797746,
                -9.951689940921948
              ],
              [
                -43.702550386540665,
                -9.965703846744864
              ],
              [
                -43.69341194529642,
                -9.974028800979834
              ],
              [
                -43.684272510064154,
                -9.982354736200422
              ],
              [
                -43.680790546871364,
                -9.983748211725255
              ],
              [
                -43.66562215766773,
                -9.989818668016527
              ],
              [
                -43.66374866454048,
                -9.996871585343772
              ],
              [
                -43.661876166399864,
                -10.003924533675466
              ],
              [
                -43.669069575832424,
                -10.030320370606399
              ],
              [
                -43.68988189684403,
                -10.044222304774028
              ],
              [
                -43.69106336546953,
                -10.058901214540047
              ],
              [
                -43.69149436845808,
                -10.064271195975499
              ],
              [
                -43.69251633873183,
                -10.076981606815837
              ],
              [
                -43.729529046294665,
                -10.071841654307624
              ],
              [
                -43.754635337653085,
                -10.073856687633652
              ],
              [
                -43.75882631799788,
                -10.07419368735741
              ],
              [
                -43.76222827190068,
                -10.0769171531351
              ],
              [
                -43.76563023380336,
                -10.079640664915923
              ],
              [
                -43.77271316120737,
                -10.098370522763716
              ],
              [
                -43.76701470975092,
                -10.113194447596518
              ],
              [
                -43.76660221381703,
                -10.114266941915552
              ],
              [
                -43.766189665345394,
                -10.133847772208329
              ],
              [
                -43.7786855337661,
                -10.180055500782252
              ],
              [
                -43.79744732664684,
                -10.20842983056577
              ],
              [
                -43.83693347299012,
                -10.250724574715903
              ],
              [
                -43.837680938429564,
                -10.26565099998305
              ],
              [
                -43.836664463103546,
                -10.268581961187168
              ],
              [
                -43.8336464629755,
                -10.277284904440783
              ],
              [
                -43.837763419944025,
                -10.303407224178017
              ],
              [
                -43.84255235207523,
                -10.309647677398
              ],
              [
                -43.84734080820902,
                -10.315886163643167
              ],
              [
                -43.85401226582252,
                -10.319826641516487
              ],
              [
                -43.87128011112306,
                -10.330024100653603
              ],
              [
                -43.88449748927103,
                -10.343609512336238
              ],
              [
                -43.89469689192006,
                -10.354091964042572
              ],
              [
                -43.89831535079412,
                -10.36415839648468
              ],
              [
                -43.899868847704035,
                -10.368480358632533
              ],
              [
                -43.90642828055876,
                -10.375353320962018
              ],
              [
                -43.91438568316364,
                -10.383691802282152
              ],
              [
                -43.915348142746076,
                -10.418357070025102
              ],
              [
                -43.915394163277384,
                -10.420019564034211
              ],
              [
                -43.91610514097271,
                -10.422006519708374
              ],
              [
                -43.916815644671374,
                -10.423993525382405
              ],
              [
                -43.9413369255285,
                -10.425621542844441
              ],
              [
                -43.95212535417038,
                -10.425104546603867
              ],
              [
                -43.96291374582617,
                -10.424588064361496
              ],
              [
                -43.97165320343354,
                -10.42071760985325
              ],
              [
                -43.9915135165911,
                -10.411921665767851
              ],
              [
                -44.00730242970523,
                -10.404928749671036
              ],
              [
                -44.017569322275996,
                -10.404738752606267
              ],
              [
                -44.01766834647098,
                -10.404737274738338
              ],
              [
                -44.028916739649496,
                -10.415975200085523
              ],
              [
                -44.035359652369486,
                -10.444299013271989
              ],
              [
                -44.02543918523334,
                -10.47505579314895
              ],
              [
                -44.0292151515291,
                -10.483666265807102
              ],
              [
                -44.036602564129396,
                -10.489332236477821
              ],
              [
                -44.04115105363044,
                -10.492821218040287
              ],
              [
                -44.0506054590561,
                -10.505819149785571
              ],
              [
                -44.066124252543254,
                -10.558340302835132
              ],
              [
                -44.07261069555316,
                -10.580291151936743
              ],
              [
                -44.077244130622006,
                -10.584329134089078
              ],
              [
                -44.08187858868079,
                -10.588368105243195
              ],
              [
                -44.09486500525131,
                -10.59043361194356
              ],
              [
                -44.10034845247256,
                -10.591306111084798
              ],
              [
                -44.10528290376872,
                -10.594043608227672
              ],
              [
                -44.110215843079125,
                -10.59678060437668
              ],
              [
                -44.11843328285617,
                -10.611777027793636
              ],
              [
                -44.12981768235904,
                -10.632552370174704
              ],
              [
                -44.13408610039161,
                -10.635816873053562
              ],
              [
                -44.14631203015347,
                -10.641012844279556
              ],
              [
                -44.15706691874061,
                -10.643239866222158
              ],
              [
                -44.16513486406493,
                -10.642483857418169
              ],
              [
                -44.16890134252282,
                -10.639311367083332
              ],
              [
                -44.177677282684115,
                -10.63191896288216
              ],
              [
                -44.19256665542161,
                -10.62816547744309
              ],
              [
                -44.208243055066234,
                -10.61491110155387
              ],
              [
                -44.21517548583773,
                -10.614804615019573
              ],
              [
                -44.2258083992414,
                -10.620339062032654
              ],
              [
                -44.236940802254736,
                -10.626134069984387
              ],
              [
                -44.26361507492373,
                -10.624253099141844
              ],
              [
                -44.27312749218224,
                -10.615336665835548
              ],
              [
                -44.28264095943666,
                -10.606420743519932
              ],
              [
                -44.29632835995401,
                -10.581893913165382
              ],
              [
                -44.303310840392854,
                -10.569381499303214
              ],
              [
                -44.31132378119106,
                -10.560183562929772
              ],
              [
                -44.315997236532596,
                -10.55608510378825
              ],
              [
                -44.32155167724929,
                -10.553911116091708
              ],
              [
                -44.3352426040415,
                -10.548553692587985
              ],
              [
                -44.34409502314911,
                -10.549383694280277
              ],
              [
                -44.352996918125775,
                -10.556223659515789
              ],
              [
                -44.395905537597095,
                -10.603091406833377
              ],
              [
                -44.40096949001094,
                -10.606586871309343
              ],
              [
                -44.42322628947396,
                -10.612846840472654
              ],
              [
                -44.43803867496033,
                -10.609356375172462
              ],
              [
                -44.44868110398257,
                -10.606848919127422
              ],
              [
                -44.452877568354886,
                -10.607334425271171
              ],
              [
                -44.463221974812825,
                -10.61484237618646
              ],
              [
                -44.47914281108355,
                -10.640048746278838
              ],
              [
                -44.50206012020945,
                -10.64948521670867
              ],
              [
                -44.5175465026654,
                -10.649618742596552
              ],
              [
                -44.52468596288722,
                -10.647311728805047
              ],
              [
                -44.548940738920635,
                -10.629435901708813
              ],
              [
                -44.57737052198454,
                -10.626353428117863
              ],
              [
                -44.58323898984786,
                -10.62917894612424
              ],
              [
                -44.589107916709885,
                -10.632004399137497
              ],
              [
                -44.5977058328189,
                -10.64531982107867
              ],
              [
                -44.60665876031925,
                -10.65918476893671
              ],
              [
                -44.61226921758912,
                -10.664822738293473
              ],
              [
                -44.62777606952516,
                -10.675304169726273
              ],
              [
                -44.644764432176785,
                -10.676882692211183
              ],
              [
                -44.64593694063384,
                -10.67693567236961
              ],
              [
                -44.659478321442414,
                -10.677540682796415
              ],
              [
                -44.65972029247398,
                -10.677551183038402
              ],
              [
                -44.66730722672462,
                -10.685525174766967
              ],
              [
                -44.66866221812754,
                -10.692112616549815
              ],
              [
                -44.66165423195363,
                -10.728417864864483
              ],
              [
                -44.66090923188436,
                -10.73227633775978
              ],
              [
                -44.66237319246556,
                -10.75014623122559
              ],
              [
                -44.667273139327335,
                -10.759639182732343
              ],
              [
                -44.68679898248807,
                -10.768786646446603
              ],
              [
                -44.69990086341519,
                -10.765207172705363
              ],
              [
                -44.713003278359146,
                -10.761628206956544
              ],
              [
                -44.75079847166297,
                -10.781710122363412
              ],
              [
                -44.75708541290132,
                -10.78775612423716
              ],
              [
                -44.75747939760464,
                -10.792359069842192
              ],
              [
                -44.758508351318746,
                -10.80437498212287
              ],
              [
                -44.77775770303009,
                -10.803612008123162
              ],
              [
                -44.78270364814253,
                -10.805857998977896
              ],
              [
                -44.78764911926122,
                -10.808103489839143
              ],
              [
                -44.80991039491075,
                -10.846167263942808
              ],
              [
                -44.808031871392615,
                -10.864589676059957
              ],
              [
                -44.81274935582447,
                -10.87094761103434
              ],
              [
                -44.81853031160104,
                -10.873635607192972
              ],
              [
                -44.82246275043705,
                -10.875465089495226
              ],
              [
                -44.82306174877239,
                -10.87608711325087
              ],
              [
                -44.83118468095266,
                -10.884522044271431
              ],
              [
                -44.845441536110975,
                -10.899326979144625
              ],
              [
                -44.851158489018935,
                -10.89561801866445
              ],
              [
                -44.85262548777417,
                -10.890726536252254
              ],
              [
                -44.85462047763772,
                -10.884074584930863
              ],
              [
                -44.85504200512411,
                -10.884161092924277
              ],
              [
                -44.86324589873899,
                -10.885849598767752
              ],
              [
                -44.88358373272794,
                -10.901557493874062
              ],
              [
                -44.88401674078881,
                -10.901891990280431
              ],
              [
                -44.88463973172931,
                -10.901922005906034
              ],
              [
                -44.89703363808049,
                -10.902522037336302
              ],
              [
                -44.9007610985357,
                -10.909584976580549
              ],
              [
                -44.900767603465134,
                -10.909598483501805
              ],
              [
                -44.900791098268485,
                -10.909603969497311
              ],
              [
                -44.909571525943086,
                -10.911536980569691
              ],
              [
                -44.9308063292605,
                -10.928338917020778
              ],
              [
                -44.97348551269365,
                -10.892838667569576
              ],
              [
                -44.994632860207005,
                -10.88347827686007
              ],
              [
                -45.000014830727295,
                -10.885850733629255
              ],
              [
                -45.0017177830209,
                -10.886601252026907
              ],
              [
                -45.01618171398405,
                -10.88418177969462
              ],
              [
                -45.030276101864665,
                -10.866235401080605
              ],
              [
                -45.07282127331941,
                -10.840005117289577
              ],
              [
                -45.079638234870664,
                -10.839782637678718
              ],
              [
                -45.08645665841462,
                -10.8395611660641
              ],
              [
                -45.090446147390075,
                -10.841041626754683
              ],
              [
                -45.10488250327792,
                -10.846400629166473
              ],
              [
                -45.11049346930432,
                -10.843377674036992
              ],
              [
                -45.116103959338176,
                -10.840354675905122
              ],
              [
                -45.150570674880214,
                -10.838790246308632
              ],
              [
                -45.153377669534194,
                -10.838663238818947
              ],
              [
                -45.158001095422286,
                -10.837939736561953
              ],
              [
                -45.158006602378805,
                -10.837939236572417
              ],
              [
                -45.16263609122165,
                -10.837215270326231
              ],
              [
                -45.21597666882155,
                -10.828873372220784
              ],
              [
                -45.21739015850596,
                -10.822921410397361
              ],
              [
                -45.21880465117779,
                -10.816969938574768
              ],
              [
                -45.23528851058656,
                -10.819589437383947
              ],
              [
                -45.24763491204938,
                -10.82155096300104
              ],
              [
                -45.252351862187716,
                -10.816412005273328
              ],
              [
                -45.25706833833043,
                -10.811273025543352
              ],
              [
                -45.260121326543974,
                -10.803344571576638
              ],
              [
                -45.27137129169265,
                -10.774128278396553
              ],
              [
                -45.28013719548478,
                -10.769681833508821
              ],
              [
                -45.28890466027466,
                -10.76523435962498
              ],
              [
                -45.32195539206,
                -10.75984945357822
              ],
              [
                -45.35780211898964,
                -10.731791159011653
              ],
              [
                -45.37447858662867,
                -10.667355624487332
              ],
              [
                -45.38798497987338,
                -10.649932747240147
              ],
              [
                -45.39671593389473,
                -10.647856743947559
              ],
              [
                -45.412384810716816,
                -10.644131305282164
              ],
              [
                -45.427433700215595,
                -10.634604897146865
              ],
              [
                -45.43146767305673,
                -10.62703242912969
              ],
              [
                -45.43550167389293,
                -10.619460480109781
              ],
              [
                -45.436420705639335,
                -10.576272275482637
              ],
              [
                -45.44691414672789,
                -10.555922900162994
              ],
              [
                -45.443434179144205,
                -10.548586941967562
              ],
              [
                -45.4399542275541,
                -10.541250482788865
              ],
              [
                -45.43776326123322,
                -10.525107581081887
              ],
              [
                -45.4423007189042,
                -10.516057642288942
              ],
              [
                -45.44683921156146,
                -10.507007222501748
              ],
              [
                -45.430667831493395,
                -10.487956830727592
              ],
              [
                -45.40260211151424,
                -10.464917967662993
              ],
              [
                -45.39931065578935,
                -10.45570149103209
              ],
              [
                -45.39601918805247,
                -10.446485568416234
              ],
              [
                -45.414561051619536,
                -10.433000646221045
              ],
              [
                -45.42407998267149,
                -10.426077203098561
              ],
              [
                -45.426797975648526,
                -10.414784289571106
              ],
              [
                -45.42951547461448,
                -10.403491891049088
              ],
              [
                -45.4274154873462,
                -10.38651398720666
              ],
              [
                -45.42581054292023,
                -10.37353904813022
              ],
              [
                -45.42785702094952,
                -10.366298097584245
              ],
              [
                -45.429904015970955,
                -10.359055665051907
              ],
              [
                -45.4564048086202,
                -10.338156309818547
              ],
              [
                -45.47284871145959,
                -10.314922998954893
              ],
              [
                -45.48176464547114,
                -10.307878544791874
              ],
              [
                -45.49068210347642,
                -10.30083459162075
              ],
              [
                -45.49553405034621,
                -10.298094140468752
              ],
              [
                -45.51671389102057,
                -10.286135245526651
              ],
              [
                -45.519325386570905,
                -10.281188765825796
              ],
              [
                -45.521937366115615,
                -10.27624232012547
              ],
              [
                -45.52226090200776,
                -10.257130425260552
              ],
              [
                -45.52243690390353,
                -10.246728500842014
              ],
              [
                -45.524157892772834,
                -10.241585546307892
              ],
              [
                -45.525878363645,
                -10.23644155378116
              ],
              [
                -45.52997586947676,
                -10.23374109340325
              ],
              [
                -45.55615217318022,
                -10.216489228892456
              ],
              [
                -45.566848095648695,
                -10.201137328688345
              ],
              [
                -45.57042706755814,
                -10.184328953037378
              ],
              [
                -45.56501567701518,
                -10.159568125765157
              ],
              [
                -45.56602817891856,
                -10.149339161340595
              ],
              [
                -45.56704064980831,
                -10.139110739923549
              ],
              [
                -45.578932597472836,
                -10.121523847734222
              ],
              [
                -45.59280997883924,
                -10.109887970396466
              ],
              [
                -45.60276490963739,
                -10.10757448672887
              ],
              [
                -45.63748911758909,
                -10.12511841007293
              ],
              [
                -45.63882112415766,
                -10.125791421366667
              ],
              [
                -45.64358304961853,
                -10.126357424612811
              ],
              [
                -45.64834453808559,
                -10.126923426859614
              ],
              [
                -45.66888536513876,
                -10.144953318665133
              ],
              [
                -45.68615721847886,
                -10.153261798415054
              ],
              [
                -45.69556964448248,
                -10.155062784496199
              ],
              [
                -45.70348707072247,
                -10.156578296565916
              ],
              [
                -45.71582595536787,
                -10.152204832226715
              ],
              [
                -45.722469905237816,
                -10.154895801114307
              ],
              [
                -45.726219397524645,
                -10.156444300781585
              ],
              [
                -45.7345787757382,
                -10.171755220278596
              ],
              [
                -45.72640182905542,
                -10.206692478395887
              ],
              [
                -45.73343322878056,
                -10.228184868483357
              ],
              [
                -45.73851869215962,
                -10.2338643412265
              ],
              [
                -45.74360563652391,
                -10.23954427798043
              ],
              [
                -45.79332471063631,
                -10.26726268896825
              ],
              [
                -45.84279683944587,
                -10.258843757634644
              ],
              [
                -45.877436070773264,
                -10.23892545952282
              ],
              [
                -45.92535768549344,
                -10.249852942742695
              ],
              [
                -45.93565511469375,
                -10.254003926082783
              ],
              [
                -45.945953038898466,
                -10.258155386435625
              ],
              [
                -45.94728851808425,
                -10.252146938722122
              ],
              [
                -45.954871509023555,
                -10.218032170513544
              ],
              [
                -45.94696258689768,
                -10.208417734359578
              ],
              [
                -45.94531007054196,
                -10.206408220200634
              ],
              [
                -45.94528657155854,
                -10.204201230363058
              ],
              [
                -45.94519810572974,
                -10.196032783784428
              ],
              [
                -45.93789015962642,
                -10.190480306314248
              ],
              [
                -45.93500268449205,
                -10.190454814849872
              ],
              [
                -45.930765727045504,
                -10.190417809764252
              ],
              [
                -45.927029767178745,
                -10.179798880406661
              ],
              [
                -45.92384529493362,
                -10.17074691967752
              ],
              [
                -45.91507936610201,
                -10.163826467248448
              ],
              [
                -45.91498987490051,
                -10.16375546059355
              ],
              [
                -45.91496089124139,
                -10.16366797612061
              ],
              [
                -45.91308539833828,
                -10.157976486425225
              ],
              [
                -45.909623428562,
                -10.156534034381687
              ],
              [
                -45.89960101317037,
                -10.152358023741337
              ],
              [
                -45.89746003850456,
                -10.137127106213395
              ],
              [
                -45.89732405681128,
                -10.136159644279045
              ],
              [
                -45.885853675161144,
                -10.113651779087764
              ],
              [
                -45.881987694136534,
                -10.11177078340369
              ],
              [
                -45.87812122811692,
                -10.109889769721686
              ],
              [
                -45.86951582711067,
                -10.06888102570611
              ],
              [
                -45.87620628497819,
                -10.061905576044522
              ],
              [
                -45.879775791432984,
                -10.05818413549931
              ],
              [
                -45.875432836512836,
                -10.055235122163598
              ],
              [
                -45.87468431307637,
                -10.054726617520705
              ],
              [
                -45.87479883007604,
                -10.05004218291868
              ],
              [
                -45.875048337912254,
                -10.039873219917068
              ],
              [
                -45.86579643105037,
                -10.008096430869477
              ],
              [
                -45.861835478694644,
                -10.003867440333646
              ],
              [
                -45.85439905748961,
                -9.99592697953225
              ],
              [
                -45.85631055787667,
                -9.985934060530075
              ],
              [
                -45.85661455445911,
                -9.984345574181065
              ],
              [
                -45.85560553219202,
                -9.983747065812787
              ],
              [
                -45.852258572161126,
                -9.98176057055182
              ],
              [
                -45.846594655828085,
                -9.961874220309271
              ],
              [
                -45.84759065867787,
                -9.958884201888575
              ],
              [
                -45.8485906365108,
                -9.955881733553822
              ],
              [
                -45.846119655812736,
                -9.950469283571355
              ],
              [
                -45.84186469709683,
                -9.941147846730193
              ],
              [
                -45.84607618493472,
                -9.92413845906917
              ],
              [
                -45.851434648672864,
                -9.9200074583803
              ],
              [
                -45.85223464210974,
                -9.919390984351589
              ],
              [
                -45.85763114367192,
                -9.907108058524269
              ],
              [
                -45.859542636305925,
                -9.887142705247838
              ],
              [
                -45.859641632808476,
                -9.886104693096957
              ],
              [
                -45.85990363720912,
                -9.885722733891912
              ],
              [
                -45.863129114528675,
                -9.881012757350039
              ],
              [
                -45.86205812465712,
                -9.87724925544461
              ],
              [
                -45.86114364486669,
                -9.874034781172618
              ],
              [
                -45.8623591284411,
                -9.873074303878159
              ],
              [
                -45.86585608520207,
                -9.870310337050665
              ],
              [
                -45.86400960323768,
                -9.861059892788257
              ],
              [
                -45.85547420849733,
                -9.848297446208019
              ],
              [
                -45.85494821462828,
                -9.84751044067586
              ],
              [
                -45.85504469680116,
                -9.846751449717042
              ],
              [
                -45.85568369193585,
                -9.84173149706185
              ],
              [
                -45.85277422049529,
                -9.839664520947553
              ],
              [
                -45.851000761074495,
                -9.838404997971912
              ],
              [
                -45.8493767523085,
                -9.830781059484277
              ],
              [
                -45.84414235392188,
                -9.806204691716921
              ],
              [
                -45.838316383922226,
                -9.796491767774627
              ],
              [
                -45.83570641318977,
                -9.795169269218379
              ],
              [
                -45.82806149941882,
                -9.791293795182098
              ],
              [
                -45.82099856603174,
                -9.772719907405557
              ],
              [
                -45.82025659784241,
                -9.756395997661873
              ],
              [
                -45.82281657567144,
                -9.75223103282386
              ],
              [
                -45.82659305628988,
                -9.74608657332039
              ],
              [
                -45.82410808184558,
                -9.743709580800642
              ],
              [
                -45.81993360873347,
                -9.739718617757468
              ],
              [
                -45.82658507916101,
                -9.727361219128152
              ],
              [
                -45.82817856015893,
                -9.724400240302597
              ],
              [
                -45.82737008925286,
                -9.722181224774062
              ],
              [
                -45.825527589972744,
                -9.717126286465282
              ],
              [
                -45.82855157938941,
                -9.7143812969471
              ],
              [
                -45.830950548060656,
                -9.712203791987442
              ],
              [
                -45.82957854950169,
                -9.707601846298921
              ],
              [
                -45.82889606267704,
                -9.70531434337276
              ],
              [
                -45.82837305994272,
                -9.703560353165274
              ],
              [
                -45.82976205332877,
                -9.70242987818346
              ],
              [
                -45.83759952208589,
                -9.696049898073577
              ],
              [
                -45.82878207951712,
                -9.679793518399867
              ],
              [
                -45.82874609488222,
                -9.67972700179018
              ],
              [
                -45.82877010993985,
                -9.679522515150524
              ],
              [
                -45.8319481001835,
                -9.652740210395429
              ],
              [
                -45.827153149841116,
                -9.642180245514638
              ],
              [
                -45.82450566580441,
                -9.636350289372917
              ],
              [
                -45.82534469552327,
                -9.626090842251736
              ],
              [
                -45.81911723661248,
                -9.624561361844878
              ],
              [
                -45.819532233281556,
                -9.617950422447722
              ],
              [
                -45.82495272247801,
                -9.607080501755986
              ],
              [
                -45.83180918408078,
                -9.603901522599028
              ],
              [
                -45.836165122066,
                -9.6018820189209
              ],
              [
                -45.83727164906435,
                -9.591259100514089
              ],
              [
                -45.83441264635366,
                -9.59068658787178
              ],
              [
                -45.83303516957504,
                -9.590410596045336
              ],
              [
                -45.833239690056644,
                -9.586991623591215
              ],
              [
                -45.83748513909996,
                -9.580643171019597
              ],
              [
                -45.84157363610082,
                -9.57452973772857
              ],
              [
                -45.84128714192377,
                -9.568213757602152
              ],
              [
                -45.84100112473834,
                -9.561897291484998
              ],
              [
                -45.83573920065967,
                -9.555765831309754
              ],
              [
                -45.826963248246734,
                -9.545538378761407
              ],
              [
                -45.82918874798278,
                -9.5410694405596
              ],
              [
                -45.83152074112884,
                -9.536387952871129
              ],
              [
                -45.822494812498114,
                -9.527981497185399
              ],
              [
                -45.82035031882946,
                -9.525985021713018
              ],
              [
                -45.82048184896016,
                -9.525001509292943
              ],
              [
                -45.82128235373653,
                -9.519029558250752
              ],
              [
                -45.8096699370281,
                -9.503479129287884
              ],
              [
                -45.80266753016602,
                -9.498619671870939
              ],
              [
                -45.79773156226287,
                -9.495194172502623
              ],
              [
                -45.79668606097047,
                -9.489761741811707
              ],
              [
                -45.79617407317172,
                -9.487101242616326
              ],
              [
                -45.79390957579862,
                -9.485650755460997
              ],
              [
                -45.79239609791708,
                -9.484680745040054
              ],
              [
                -45.788481649845444,
                -9.482173266876966
              ],
              [
                -45.78340568291794,
                -9.479702799120405
              ],
              [
                -45.78906565575808,
                -9.469889838935494
              ],
              [
                -45.788509160872756,
                -9.46420938846421
              ],
              [
                -45.7947126136614,
                -9.46010542254818
              ],
              [
                -45.78750869310131,
                -9.458014914845949
              ],
              [
                -45.79423161646327,
                -9.449940994520572
              ],
              [
                -45.792902153832685,
                -9.444975518482114
              ],
              [
                -45.796829589776664,
                -9.442430536708686
              ],
              [
                -45.79738112783696,
                -9.44207302169027
              ],
              [
                -45.796908126171736,
                -9.441568549443344
              ],
              [
                -45.79357665670025,
                -9.438017076825973
              ],
              [
                -45.79739261559201,
                -9.436263565739607
              ],
              [
                -45.800915103646204,
                -9.434645603424073
              ],
              [
                -45.802321097009404,
                -9.42489467090457
              ],
              [
                -45.801470087382526,
                -9.42349665007246
              ],
              [
                -45.79742312019923,
                -9.41684720793025
              ],
              [
                -45.81693401164843,
                -9.38985740231694
              ],
              [
                -45.81756802166627,
                -9.388979900798603
              ],
              [
                -45.82065598672175,
                -9.38176046466545
              ],
              [
                -45.82374495076353,
                -9.374542019528382
              ],
              [
                -45.846717308982655,
                -9.355973673690784
              ],
              [
                -45.85944422089132,
                -9.358172146962497
              ],
              [
                -45.86279167277939,
                -9.3587501650373
              ],
              [
                -45.874605599740306,
                -9.353406187660008
              ],
              [
                -45.87907055837325,
                -9.345802769601342
              ],
              [
                -45.88696501201324,
                -9.348403232669812
              ],
              [
                -45.89325594709488,
                -9.34177879029591
              ],
              [
                -45.89051800513486,
                -9.33112934688555
              ],
              [
                -45.895830952345484,
                -9.327575874271982
              ],
              [
                -45.89615493870321,
                -9.325075898018637
              ],
              [
                -45.896990966641916,
                -9.318608439342746
              ],
              [
                -45.89723145980918,
                -9.318552446985926
              ],
              [
                -45.90186490050868,
                -9.317466445424506
              ],
              [
                -45.8998314522803,
                -9.312550489290361
              ],
              [
                -45.90664437789779,
                -9.2976121179787
              ],
              [
                -45.905446418277805,
                -9.29165014167107
              ],
              [
                -45.90097445755099,
                -9.290800638112977
              ],
              [
                -45.89667097646087,
                -9.289982119546321
              ],
              [
                -45.895516479852354,
                -9.281123702290046
              ],
              [
                -45.90070547194008,
                -9.271607256614802
              ],
              [
                -45.90331196991155,
                -9.266827810930135
              ],
              [
                -45.90056896503272,
                -9.264687825826362
              ],
              [
                -45.898081988901716,
                -9.262748331702518
              ],
              [
                -45.90038247544276,
                -9.255236359592404
              ],
              [
                -45.90145048004941,
                -9.251748898683468
              ],
              [
                -45.90023750209644,
                -9.247876414698661
              ],
              [
                -45.89618603700166,
                -9.234941015940123
              ],
              [
                -45.89995002479921,
                -9.232778501415822
              ],
              [
                -45.90277999834798,
                -9.231152548306522
              ],
              [
                -45.8996310506686,
                -9.215989130228797
              ],
              [
                -45.89823704539506,
                -9.209275169714108
              ],
              [
                -45.89944303454838,
                -9.206087206011158
              ],
              [
                -45.905237532442165,
                -9.190770300145331
              ],
              [
                -45.90292605855969,
                -9.190871302861423
              ],
              [
                -45.89927756115877,
                -9.19103030168091
              ],
              [
                -45.89665459149586,
                -9.186113327998
              ],
              [
                -45.91290846703376,
                -9.167146950964707
              ],
              [
                -45.91906144850133,
                -9.150554093910793
              ],
              [
                -45.92083343499194,
                -9.145775096306895
              ],
              [
                -45.92765642363309,
                -9.13751367728071
              ],
              [
                -45.9250259473909,
                -9.117332804933419
              ],
              [
                -45.92471446234577,
                -9.111626335092778
              ],
              [
                -45.92717094363475,
                -9.10226290440939
              ],
              [
                -45.925332451709934,
                -9.10087239348625
              ],
              [
                -45.92213946362969,
                -9.098456410785872
              ],
              [
                -45.92271049683889,
                -9.089909480632159
              ],
              [
                -45.928398948767544,
                -9.088348492279257
              ],
              [
                -45.93094240951052,
                -9.087650510722384
              ],
              [
                -45.92938545803797,
                -9.084784003826734
              ],
              [
                -45.92696548016993,
                -9.080329058413355
              ],
              [
                -45.92884197039889,
                -9.069727124253342
              ],
              [
                -45.934782414618404,
                -9.065276159182552
              ],
              [
                -45.93745888579526,
                -9.063270686370021
              ],
              [
                -45.9399993782605,
                -9.055773706530497
              ],
              [
                -45.93779541880056,
                -9.04766529141665
              ],
              [
                -45.935378428893976,
                -9.038774832224949
              ],
              [
                -45.94029692933866,
                -9.031505874553762
              ],
              [
                -45.9449858862075,
                -9.024575921393918
              ],
              [
                -45.94437288647403,
                -9.021527940774016
              ],
              [
                -45.94304241040946,
                -9.01491451282974
              ],
              [
                -45.95870479429982,
                -9.001335094654582
              ],
              [
                -45.967287756161035,
                -8.978669776423342
              ],
              [
                -45.97384619638551,
                -8.972542291047326
              ],
              [
                -45.98040466661133,
                -8.966415345663616
              ],
              [
                -45.99188208774149,
                -8.940067046899317
              ],
              [
                -45.993873092259,
                -8.926465651703088
              ],
              [
                -45.987777666461554,
                -8.9208881647324
              ],
              [
                -45.984752169365635,
                -8.91811918564618
              ],
              [
                -45.98528969248491,
                -8.91241971180482
              ],
              [
                -45.98551615937575,
                -8.910019210877524
              ],
              [
                -45.984069670712216,
                -8.908268248827174
              ],
              [
                -45.979249716152275,
                -8.902431763001852
              ],
              [
                -45.98070474394381,
                -8.896815324635796
              ],
              [
                -45.98256072129718,
                -8.889649359930933
              ],
              [
                -45.97964773886832,
                -8.883669886168347
              ],
              [
                -45.97617176476682,
                -8.881386386415386
              ],
              [
                -45.967197352960895,
                -8.875491925459025
              ],
              [
                -45.9715563270704,
                -8.869691976508781
              ],
              [
                -45.97227779745409,
                -8.868732006633971
              ],
              [
                -45.97112883295086,
                -8.86200401577454
              ],
              [
                -45.96488187290382,
                -8.855886058318097
              ],
              [
                -45.95949493215327,
                -8.8506095982607
              ],
              [
                -45.95691198307933,
                -8.840119165710547
              ],
              [
                -45.94856354893358,
                -8.84454511836153
              ],
              [
                -45.94511057216722,
                -8.839979630746342
              ],
              [
                -45.94493957833871,
                -8.831072717400883
              ],
              [
                -45.95215352769288,
                -8.828203220225577
              ],
              [
                -45.95825045335887,
                -8.825777776896107
              ],
              [
                -45.960346468894095,
                -8.81848131938223
              ],
              [
                -45.95308852180569,
                -8.806879388142908
              ],
              [
                -45.94351513557691,
                -8.799247410180719
              ],
              [
                -45.93875267625157,
                -8.795449959113604
              ],
              [
                -45.93802815592955,
                -8.784536520495738
              ],
              [
                -45.9367902089779,
                -8.782439011025417
              ],
              [
                -45.934582713459825,
                -8.77870006136258
              ],
              [
                -45.93059472395832,
                -8.777705031622055
              ],
              [
                -45.927599762364835,
                -8.776958543314732
              ],
              [
                -45.91747837624054,
                -8.767683589725698
              ],
              [
                -45.90720544246076,
                -8.7582686669356
              ],
              [
                -45.905673956095306,
                -8.758663666664916
              ],
              [
                -45.89119409011811,
                -8.762393096317224
              ],
              [
                -45.889591089609475,
                -8.746029207967684
              ],
              [
                -45.888927600222196,
                -8.739253742138375
              ],
              [
                -45.873966214126,
                -8.730996799732871
              ],
              [
                -45.87126173286615,
                -8.73060830039561
              ],
              [
                -45.86290631403773,
                -8.729407289361335
              ],
              [
                -45.863408838953646,
                -8.724001348743823
              ],
              [
                -45.86419982297046,
                -8.715484378069256
              ],
              [
                -45.85288290877431,
                -8.709416415156369
              ],
              [
                -45.85211240750689,
                -8.70973193523593
              ],
              [
                -45.83952802015197,
                -8.714879875568172
              ],
              [
                -45.82055171852775,
                -8.665225671886235
              ],
              [
                -45.81119728460091,
                -8.655680241326191
              ],
              [
                -45.81708477194085,
                -8.654027249588868
              ],
              [
                -45.818562238734195,
                -8.653612283921708
              ],
              [
                -45.82061573633663,
                -8.649154812730421
              ],
              [
                -45.81512880016775,
                -8.632827898404239
              ],
              [
                -45.80341389865009,
                -8.621576452766172
              ],
              [
                -45.79825193046284,
                -8.620559455048395
              ],
              [
                -45.79705596238952,
                -8.612635502489258
              ],
              [
                -45.78375255182846,
                -8.61592696847645
              ],
              [
                -45.77771411287911,
                -8.617420960128387
              ],
              [
                -45.76521820532649,
                -8.609178527736022
              ],
              [
                -45.78377108093722,
                -8.597933120243974
              ],
              [
                -45.7935235243078,
                -8.592022661906434
              ],
              [
                -45.79344200323456,
                -8.588012192513435
              ],
              [
                -45.79336049215859,
                -8.58400219311941
              ],
              [
                -45.77499617094836,
                -8.57378774973002
              ],
              [
                -45.76794570976537,
                -8.56986577741301
              ],
              [
                -45.76444725686144,
                -8.565652305793321
              ],
              [
                -45.76094830395963,
                -8.561439319175966
              ],
              [
                -45.760469795727815,
                -8.55109991057546
              ],
              [
                -45.76038431038001,
                -8.54923838589246
              ],
              [
                -45.75974382128148,
                -8.548441410530428
              ],
              [
                -45.75340088072008,
                -8.540551462455083
              ],
              [
                -45.755215349830344,
                -8.532600025377208
              ],
              [
                -45.75641186994581,
                -8.527353526617592
              ],
              [
                -45.75356988513533,
                -8.520970097206337
              ],
              [
                -45.748203442874804,
                -8.513991645128792
              ],
              [
                -45.73804151805278,
                -8.500774701680072
              ],
              [
                -45.741815520204504,
                -8.497037716567455
              ],
              [
                -45.7455904743492,
                -8.493300781454096
              ],
              [
                -45.741413008272204,
                -8.479113343794229
              ],
              [
                -45.74020302514974,
                -8.475002364979428
              ],
              [
                -45.74104804523384,
                -8.462871962862684
              ],
              [
                -45.74189353129639,
                -8.450741047765973
              ],
              [
                -45.73588259348692,
                -8.441445112622334
              ],
              [
                -45.72813717916852,
                -8.429465193644356
              ],
              [
                -45.731299645558416,
                -8.422432729930774
              ],
              [
                -45.73238415151777,
                -8.420021734171373
              ],
              [
                -45.73131266696916,
                -8.415241266020018
              ],
              [
                -45.73022265899131,
                -8.41196729178094
              ],
              [
                -45.71881277559903,
                -8.402823844125157
              ],
              [
                -45.72114977035415,
                -8.39398990063056
              ],
              [
                -45.71103185485306,
                -8.387628953745482
              ],
              [
                -45.70519988534536,
                -8.387200931593775
              ],
              [
                -45.69992493151673,
                -8.380930964120079
              ],
              [
                -45.703078441935446,
                -8.368105056228783
              ],
              [
                -45.69409052320715,
                -8.363960067736308
              ],
              [
                -45.69373551118068,
                -8.363796091469299
              ],
              [
                -45.69119552345383,
                -8.36262460270276
              ],
              [
                -45.68711657240594,
                -8.355156150533237
              ],
              [
                -45.68709606210589,
                -8.343041232673789
              ],
              [
                -45.68709457313883,
                -8.342053729288658
              ],
              [
                -45.68708457209615,
                -8.33636677038422
              ],
              [
                -45.682837130827096,
                -8.329407817669201
              ],
              [
                -45.6819581322555,
                -8.327967824420728
              ],
              [
                -45.67858966855308,
                -8.322448836968045
              ],
              [
                -45.67791069418702,
                -8.31733586655137
              ],
              [
                -45.67773368895823,
                -8.316003401303929
              ],
              [
                -45.676147201793604,
                -8.30405296681673
              ],
              [
                -45.67014377542072,
                -8.296068528245215
              ],
              [
                -45.66969878754389,
                -8.295476531762707
              ],
              [
                -45.66414179902977,
                -8.28808505168917
              ],
              [
                -45.661360857472566,
                -8.252474805886447
              ],
              [
                -45.66113789314746,
                -8.249623305807692
              ],
              [
                -45.65971738259209,
                -8.248424809415786
              ],
              [
                -45.60772384708809,
                -8.204557588722265
              ],
              [
                -45.611791332876315,
                -8.195745623099855
              ],
              [
                -45.61190281223899,
                -8.19550515582991
              ],
              [
                -45.61244779412781,
                -8.194325144317176
              ],
              [
                -45.610717330638444,
                -8.186592679604928
              ],
              [
                -45.606498375290776,
                -8.185302688045756
              ],
              [
                -45.599140913201026,
                -8.17467274720654
              ],
              [
                -45.59494446324618,
                -8.173810256802522
              ],
              [
                -45.594552978422364,
                -8.173729789951485
              ],
              [
                -45.59086252436477,
                -8.17297077035981
              ],
              [
                -45.58104958042775,
                -8.155651862169291
              ],
              [
                -45.584766089262814,
                -8.141533970001635
              ],
              [
                -45.58498958634959,
                -8.140685502941913
              ],
              [
                -45.58617658350057,
                -8.13617700150669
              ],
              [
                -45.585019575260546,
                -8.128603043392669
              ],
              [
                -45.58311412811299,
                -8.125827069201799
              ],
              [
                -45.582709090908125,
                -8.12523659177781
              ],
              [
                -45.57469467698829,
                -8.113560152509015
              ],
              [
                -45.578431658395374,
                -8.106621725012799
              ],
              [
                -45.57864865008355,
                -8.106218712946244
              ],
              [
                -45.58314614488745,
                -8.097868778724528
              ],
              [
                -45.57046974381738,
                -8.077141917961356
              ],
              [
                -45.56985776165425,
                -8.076141417106198
              ],
              [
                -45.56820327343289,
                -8.073437407714374
              ],
              [
                -45.56803828848973,
                -8.069673925949761
              ],
              [
                -45.56797675636463,
                -8.06828095929012
              ],
              [
                -45.567498286978704,
                -8.05740552022486
              ],
              [
                -45.56745528629844,
                -8.056421034828242
              ],
              [
                -45.5673162845388,
                -8.05326357500674
              ],
              [
                -45.56101585307825,
                -8.047507095651325
              ],
              [
                -45.575347754786144,
                -8.02936023733022
              ],
              [
                -45.57587076630934,
                -8.02869721132301
              ],
              [
                -45.5747327705338,
                -8.027458232569195
              ],
              [
                -45.562532859134194,
                -8.014180834252647
              ],
              [
                -45.553740949017325,
                -8.016841303630994
              ],
              [
                -45.5494174849235,
                -8.014094823940605
              ],
              [
                -45.550764976635776,
                -8.007655838782915
              ],
              [
                -45.559320415792826,
                -8.001781888883913
              ],
              [
                -45.559605398733254,
                -8.001585912488215
              ],
              [
                -45.566669361676595,
                -7.996735931198902
              ],
              [
                -45.556438944674554,
                -7.97328659870417
              ],
              [
                -45.552528966659644,
                -7.971182109112922
              ],
              [
                -45.55215100184833,
                -7.970978586119819
              ],
              [
                -45.54826553162845,
                -7.968886613471004
              ],
              [
                -45.5446640515202,
                -7.960327171855885
              ],
              [
                -45.54907802512456,
                -7.957775675426734
              ],
              [
                -45.54936951098534,
                -7.957607177851199
              ],
              [
                -45.55573747625194,
                -7.953926715964597
              ],
              [
                -45.547039559883245,
                -7.94693376579647
              ],
              [
                -45.54416758706858,
                -7.939306300618287
              ],
              [
                -45.54092960213444,
                -7.928619387808723
              ],
              [
                -45.54346762293696,
                -7.920569930773027
              ],
              [
                -45.53878266049652,
                -7.919019442721051
              ],
              [
                -45.53476966013933,
                -7.921158913335888
              ],
              [
                -45.53450018513008,
                -7.921302423102306
              ],
              [
                -45.53165871412074,
                -7.922816897086404
              ],
              [
                -45.52321677264293,
                -7.91180197449632
              ],
              [
                -45.52378626881616,
                -7.908032513584667
              ],
              [
                -45.53274270695839,
                -7.902932047818944
              ],
              [
                -45.53306617757987,
                -7.902748042378869
              ],
              [
                -45.542603132462894,
                -7.897317094407443
              ],
              [
                -45.53947567243537,
                -7.892959118900881
              ],
              [
                -45.53263970733015,
                -7.89106764110009
              ],
              [
                -45.532306198056936,
                -7.890975610400946
              ],
              [
                -45.52042930218124,
                -7.887689143183836
              ],
              [
                -45.52162082155293,
                -7.882757684760441
              ],
              [
                -45.531712236231265,
                -7.877116216747249
              ],
              [
                -45.53199174617254,
                -7.876960238074395
              ],
              [
                -45.53325723285378,
                -7.876252247095104
              ],
              [
                -45.53362922811394,
                -7.876044715860946
              ],
              [
                -45.533852718748946,
                -7.875630724882572
              ],
              [
                -45.53995817846822,
                -7.864306295530299
              ],
              [
                -45.53486522622663,
                -7.86173784002981
              ],
              [
                -45.52545581222836,
                -7.856993365129269
              ],
              [
                -45.5250598015533,
                -7.856793837100717
              ],
              [
                -45.52455282380997,
                -7.856538344344851
              ],
              [
                -45.52463982911827,
                -7.855509862402853
              ],
              [
                -45.52470781335702,
                -7.854708379903353
              ],
              [
                -45.52598680884355,
                -7.839619483468305
              ],
              [
                -45.51844235341638,
                -7.835570982682412
              ],
              [
                -45.518041865791744,
                -7.835355484759758
              ],
              [
                -45.515369884308164,
                -7.833921501922602
              ],
              [
                -45.509631450205696,
                -7.819915596463771
              ],
              [
                -45.50979746321346,
                -7.816469592013073
              ],
              [
                -45.50982795458882,
                -7.815829611386372
              ],
              [
                -45.51049298663488,
                -7.802005204873162
              ],
              [
                -45.50188755072327,
                -7.798856745024482
              ],
              [
                -45.501489552011144,
                -7.798711241632201
              ],
              [
                -45.49847157594376,
                -7.797607237245557
              ],
              [
                -45.49905455527122,
                -7.793506760652098
              ],
              [
                -45.49913155018264,
                -7.792964768406884
              ],
              [
                -45.50022703991972,
                -7.785252323839265
              ],
              [
                -45.49672409812601,
                -7.786817306865819
              ],
              [
                -45.49643060132196,
                -7.786948292695975
              ],
              [
                -45.4934701214708,
                -7.788271305883145
              ],
              [
                -45.48953015368073,
                -7.786142800584099
              ],
              [
                -45.49241511386506,
                -7.776747842168991
              ],
              [
                -45.484286707074745,
                -7.763261948083875
              ],
              [
                -45.488430200782346,
                -7.758568971925087
              ],
              [
                -45.48868015807592,
                -7.758285506089598
              ],
              [
                -45.496077647564356,
                -7.749907559069871
              ],
              [
                -45.489467665951956,
                -7.745607577027576
              ],
              [
                -45.486417219666194,
                -7.750235048669147
              ],
              [
                -45.486248724755185,
                -7.750490553771991
              ],
              [
                -45.48615670547357,
                -7.750500549616607
              ],
              [
                -45.48182674928666,
                -7.750962536363653
              ],
              [
                -45.48109074863782,
                -7.743071604323167
              ],
              [
                -45.48292925378496,
                -7.738150105536366
              ],
              [
                -45.48312373574192,
                -7.737630143256953
              ],
              [
                -45.486482698734996,
                -7.72863766860009
              ],
              [
                -45.47970578305946,
                -7.714213276317479
              ],
              [
                -45.47459083908523,
                -7.713622777501871
              ],
              [
                -45.47423084797267,
                -7.713581292444015
              ],
              [
                -45.468250867940945,
                -7.712890763493309
              ],
              [
                -45.468328402483046,
                -7.70535085188547
              ],
              [
                -45.46833288087824,
                -7.704896844980032
              ],
              [
                -45.46849840287718,
                -7.688739441132912
              ],
              [
                -45.45712249039225,
                -7.689263431851842
              ],
              [
                -45.4569550007105,
                -7.689270930643051
              ],
              [
                -45.45665348528523,
                -7.689071412717913
              ],
              [
                -45.44889653254278,
                -7.68392046650001
              ],
              [
                -45.451804022056855,
                -7.68230898821228
              ],
              [
                -45.452041027305135,
                -7.682177994327512
              ],
              [
                -45.46032297510659,
                -7.677588997374212
              ],
              [
                -45.45563203206009,
                -7.670351053270189
              ],
              [
                -45.455583519514725,
                -7.670276064735509
              ],
              [
                -45.44557610039435,
                -7.674847036191407
              ],
              [
                -45.4453706099319,
                -7.674941028357915
              ],
              [
                -45.43952714966055,
                -7.677609983681189
              ],
              [
                -45.43484217389481,
                -7.675332010801048
              ],
              [
                -45.43643367496967,
                -7.668261545480515
              ],
              [
                -45.43648067679666,
                -7.668050571962467
              ],
              [
                -45.43665315515344,
                -7.66728504734165
              ],
              [
                -45.432332209176785,
                -7.661110090376512
              ],
              [
                -45.42535478170549,
                -7.65973561220438
              ],
              [
                -45.424906769336026,
                -7.659646606391203
              ],
              [
                -45.41740683910529,
                -7.658168115442402
              ],
              [
                -45.41386937882521,
                -7.654225127016747
              ],
              [
                -45.41424237226935,
                -7.65167716974292
              ],
              [
                -45.414274864221355,
                -7.651454660290865
              ],
              [
                -45.41526187673481,
                -7.644724704115792
              ],
              [
                -45.40670790621292,
                -7.643928686544894
              ],
              [
                -45.40635343505035,
                -7.643896181435164
              ],
              [
                -45.40366845854675,
                -7.643646179629857
              ],
              [
                -45.398619478278675,
                -7.639655226139777
              ],
              [
                -45.398471989521205,
                -7.633182773169148
              ],
              [
                -45.39846098504315,
                -7.632719288321861
              ],
              [
                -45.39824099380769,
                -7.623094821803077
              ],
              [
                -45.39366254487079,
                -7.612620909036854
              ],
              [
                -45.38159413235476,
                -7.612728878063196
              ],
              [
                -45.381132634718,
                -7.613041403499348
              ],
              [
                -45.380969639905764,
                -7.613151907592987
              ],
              [
                -45.37323119130374,
                -7.618392837596052
              ],
              [
                -45.342849969668585,
                -7.59308898324283
              ],
              [
                -45.34120949545581,
                -7.586878556179078
              ],
              [
                -45.34113998725303,
                -7.586614014923306
              ],
              [
                -45.339353992698705,
                -7.579855084483153
              ],
              [
                -45.322662154966615,
                -7.574521613509272
              ],
              [
                -45.32144515367634,
                -7.574455110838227
              ],
              [
                -45.32105364880005,
                -7.574433605623115
              ],
              [
                -45.305777801691995,
                -7.573599582199161
              ],
              [
                -45.295944845167526,
                -7.57306309377818
              ],
              [
                -45.29029989523623,
                -7.569510597876573
              ],
              [
                -45.284654949305484,
                -7.565959122974998
              ],
              [
                -45.27562304336044,
                -7.567675123884405
              ],
              [
                -45.26095462715655,
                -7.570461557255115
              ],
              [
                -45.2563971671348,
                -7.565270598602281
              ],
              [
                -45.2518402391066,
                -7.560080129956105
              ],
              [
                -45.23267390098977,
                -7.549533690571385
              ],
              [
                -45.225501958975876,
                -7.557047122463199
              ],
              [
                -45.22263546295514,
                -7.560049635240207
              ],
              [
                -45.22231844943155,
                -7.565493064636382
              ],
              [
                -45.21773848497509,
                -7.567459053936466
              ],
              [
                -45.214857020623064,
                -7.565550589360711
              ],
              [
                -45.20386612300559,
                -7.551389156334101
              ],
              [
                -45.18317427111621,
                -7.542988715932217
              ],
              [
                -45.178597845173364,
                -7.535723261587561
              ],
              [
                -45.17857281240867,
                -7.535683749835755
              ],
              [
                -45.174020878229555,
                -7.528457812257331
              ],
              [
                -45.15504051917808,
                -7.51309138339032
              ],
              [
                -45.14615761290144,
                -7.520572339875812
              ],
              [
                -45.14600258412489,
                -7.520581818668745
              ],
              [
                -45.14571110842506,
                -7.520599807278355
              ],
              [
                -45.136746661172396,
                -7.521153802266244
              ],
              [
                -45.126490770357584,
                -7.513912857636432
              ],
              [
                -45.12634026065201,
                -7.513806372230609
              ],
              [
                -45.12608978280546,
                -7.513629848214668
              ],
              [
                -45.084461607317486,
                -7.502238899516435
              ],
              [
                -45.07671315542331,
                -7.502828377393824
              ],
              [
                -45.076466652367664,
                -7.502846894041604
              ],
              [
                -45.076237144178094,
                -7.50286390571524
              ],
              [
                -45.06896423654077,
                -7.50341739027392
              ],
              [
                -45.04601490380524,
                -7.510387308376241
              ],
              [
                -45.04450993036665,
                -7.510844302857799
              ],
              [
                -45.04441189820013,
                -7.510786301167536
              ],
              [
                -45.01818561630323,
                -7.495166395810825
              ],
              [
                -45.01108918827175,
                -7.495763893236965
              ],
              [
                -45.01081068746832,
                -7.495787391821655
              ],
              [
                -45.00399274824671,
                -7.496361363663213
              ],
              [
                -44.997488805514614,
                -7.490381394948613
              ],
              [
                -44.99722832180815,
                -7.490141930361887
              ],
              [
                -44.9883168882744,
                -7.481946965743129
              ],
              [
                -44.98315491241805,
                -7.481927468190808
              ],
              [
                -44.97796395241311,
                -7.482327930717474
              ],
              [
                -44.977687477596454,
                -7.482349457318072
              ],
              [
                -44.975980491077706,
                -7.482481437858558
              ],
              [
                -44.975650481684546,
                -7.482506432386638
              ],
              [
                -44.968763038083516,
                -7.48303894946284
              ],
              [
                -44.957046137487815,
                -7.47532796801004
              ],
              [
                -44.936820291903906,
                -7.469630008993783
              ],
              [
                -44.92804288665028,
                -7.470992968639506
              ],
              [
                -44.92403439360763,
                -7.469900479550055
              ],
              [
                -44.92024144447938,
                -7.457098573491708
              ],
              [
                -44.919065946450516,
                -7.453132589812478
              ],
              [
                -44.909464546318624,
                -7.442872666001375
              ],
              [
                -44.90098562852228,
                -7.425644765387564
              ],
              [
                -44.896226176116755,
                -7.423839305583686
              ],
              [
                -44.89196118422328,
                -7.424854268733262
              ],
              [
                -44.88226928946247,
                -7.427162245063633
              ],
              [
                -44.86853487980798,
                -7.421895785126094
              ],
              [
                -44.86259846310531,
                -7.410885852938007
              ],
              [
                -44.85226951785215,
                -7.401651894558628
              ],
              [
                -44.85266051916101,
                -7.400061426913731
              ],
              [
                -44.853649013885885,
                -7.396038953632512
              ],
              [
                -44.848081579380825,
                -7.38595052047109
              ],
              [
                -44.83119270253123,
                -7.380010541505627
              ],
              [
                -44.82207930716948,
                -7.376804549573031
              ],
              [
                -44.81920180430069,
                -7.368810605377554
              ],
              [
                -44.81632433642518,
                -7.360815166206167
              ],
              [
                -44.78636259656906,
                -7.371079579390827
              ],
              [
                -44.78345461778442,
                -7.374454525407895
              ],
              [
                -44.78054763099138,
                -7.377830003421421
              ],
              [
                -44.74256743483418,
                -7.361833078527263
              ],
              [
                -44.72948753665609,
                -7.390652401976507
              ],
              [
                -44.71181267188789,
                -7.397492330758765
              ],
              [
                -44.706687712099736,
                -7.39390132709558
              ],
              [
                -44.704336221385226,
                -7.392254363424063
              ],
              [
                -44.70074923008377,
                -7.394484842748313
              ],
              [
                -44.69990625535808,
                -7.395009319357161
              ],
              [
                -44.68757235781511,
                -7.394145318348715
              ],
              [
                -44.679445971619735,
                -7.361922531819923
              ],
              [
                -44.67859544842994,
                -7.358550070487117
              ],
              [
                -44.67512098611719,
                -7.355311591898019
              ],
              [
                -44.671646506803945,
                -7.352073593309775
              ],
              [
                -44.67052204517647,
                -7.349405128851495
              ],
              [
                -44.66294511879026,
                -7.33141925113018
              ],
              [
                -44.655949188009544,
                -7.326654762067789
              ],
              [
                -44.65250617791493,
                -7.326264750738068
              ],
              [
                -44.64580724821382,
                -7.325505280102671
              ],
              [
                -44.643177779588186,
                -7.320622802734016
              ],
              [
                -44.639861286592016,
                -7.314460849665031
              ],
              [
                -44.61632648018749,
                -7.307511349281959
              ],
              [
                -44.59570569563018,
                -7.276553080714187
              ],
              [
                -44.59519071069375,
                -7.268654624284378
              ],
              [
                -44.58463727392141,
                -7.261039166114769
              ],
              [
                -44.579345825663964,
                -7.257220670102803
              ],
              [
                -44.5725443843342,
                -7.247887262227073
              ],
              [
                -44.56752544979922,
                -7.236165315588268
              ],
              [
                -44.56366300162537,
                -7.22714387114867
              ],
              [
                -44.56107001172107,
                -7.226781888418502
              ],
              [
                -44.55847800980966,
                -7.226419882689727
              ],
              [
                -44.52754176356994,
                -7.222094880984931
              ],
              [
                -44.52609129491841,
                -7.221269910484006
              ],
              [
                -44.52463981327606,
                -7.22044390298985
              ],
              [
                -44.523275823016014,
                -7.217223922285467
              ],
              [
                -44.51427790863536,
                -7.195970574909953
              ],
              [
                -44.50043552672594,
                -7.184344152176962
              ],
              [
                -44.49384606967786,
                -7.183076120349521
              ],
              [
                -44.490486083190255,
                -7.182429151971129
              ],
              [
                -44.48889360685287,
                -7.180247168849005
              ],
              [
                -44.48582362711668,
                -7.176042679593308
              ],
              [
                -44.46366133268588,
                -7.161274762784456
              ],
              [
                -44.45047992303045,
                -7.158307284212431
              ],
              [
                -44.43729754940216,
                -7.1553387716603
              ],
              [
                -44.41182229225809,
                -7.135199900832125
              ],
              [
                -44.37963205801115,
                -7.118636523389885
              ],
              [
                -44.371641611250745,
                -7.119758491702603
              ],
              [
                -44.36365167549363,
                -7.120880977011248
              ],
              [
                -44.35714123106617,
                -7.11824799592079
              ],
              [
                -44.33264593142468,
                -7.108343050532525
              ],
              [
                -44.32255198692717,
                -7.113795005671954
              ],
              [
                -44.31549706440349,
                -7.11760494191934
              ],
              [
                -44.30579713111602,
                -7.116782450426022
              ],
              [
                -44.27481443002144,
                -7.068983774898675
              ],
              [
                -44.27386741879965,
                -7.065256277301795
              ],
              [
                -44.27466242631765,
                -7.061815315164938
              ],
              [
                -44.2784009119334,
                -7.045625923153273
              ],
              [
                -44.274491456720135,
                -7.031009551660768
              ],
              [
                -44.263935534427105,
                -7.017022599199448
              ],
              [
                -44.26158058706902,
                -6.998337763741144
              ],
              [
                -44.25604212263212,
                -6.998340262086754
              ],
              [
                -44.24847120755451,
                -6.998343738724731
              ],
              [
                -44.235499808949925,
                -6.998349715826662
              ],
              [
                -44.20560906807985,
                -6.963073956472947
              ],
              [
                -44.20102560071843,
                -6.932129151926302
              ],
              [
                -44.19247020397392,
                -6.925663195468844
              ],
              [
                -44.185265262340856,
                -6.926458701893046
              ],
              [
                -44.17563181805346,
                -6.927522703412277
              ],
              [
                -44.17313584311873,
                -6.923950192567212
              ],
              [
                -44.175143332577285,
                -6.898993371446805
              ],
              [
                -44.17462233417675,
                -6.896080399591141
              ],
              [
                -44.17410037078334,
                -6.893166915739728
              ],
              [
                -44.1604940054679,
                -6.872414587181156
              ],
              [
                -44.149633578536324,
                -6.868069068989358
              ],
              [
                -44.13877214962304,
                -6.863723603811962
              ],
              [
                -44.13633218534657,
                -6.858770636839642
              ],
              [
                -44.12782127107522,
                -6.84149528008272
              ],
              [
                -44.11949836558135,
                -6.832404310623868
              ],
              [
                -44.114775393110826,
                -6.83796725740206
              ],
              [
                -44.11439940167193,
                -6.843438716374207
              ],
              [
                -44.11377737128883,
                -6.852484167859724
              ],
              [
                -44.10860990106467,
                -6.857227620071921
              ],
              [
                -44.10283096931557,
                -6.852757643977667
              ],
              [
                -44.09830999255551,
                -6.839559248703563
              ],
              [
                -44.11234040669102,
                -6.819631893205529
              ],
              [
                -44.11354189039539,
                -6.817925428264319
              ],
              [
                -44.1150099179667,
                -6.810053984189397
              ],
              [
                -44.11392841105818,
                -6.804673007414062
              ],
              [
                -44.109598461278814,
                -6.80305101238646
              ],
              [
                -44.088472624711585,
                -6.802916516199938
              ],
              [
                -44.084002134315575,
                -6.809620944074553
              ],
              [
                -44.07658568708285,
                -6.820742853260121
              ],
              [
                -44.06929677866954,
                -6.820993366559683
              ],
              [
                -44.05866034598884,
                -6.808053930612916
              ],
              [
                -44.05975836512034,
                -6.785863116810342
              ],
              [
                -44.059832350738134,
                -6.784362114512691
              ],
              [
                -44.05296945238238,
                -6.767926212531556
              ],
              [
                -44.04249750596109,
                -6.769051198107122
              ],
              [
                -44.03299308693484,
                -6.76001976753117
              ],
              [
                -43.98733394602152,
                -6.756894268986101
              ],
              [
                -43.9703336158057,
                -6.743837834088787
              ],
              [
                -43.9608107085901,
                -6.741513362971387
              ],
              [
                -43.94894525954203,
                -6.751359768469803
              ],
              [
                -43.948113273095224,
                -6.754059243615457
              ],
              [
                -43.94470180975901,
                -6.765126146223081
              ],
              [
                -43.93346438895798,
                -6.7708506055008
              ],
              [
                -43.92805245350333,
                -6.771090603440057
              ],
              [
                -43.92417746495208,
                -6.764717649624013
              ],
              [
                -43.91851702518881,
                -6.755407203272323
              ],
              [
                -43.91339606309688,
                -6.752277244420771
              ],
              [
                -43.87947634330686,
                -6.75732816928072
              ],
              [
                -43.8533605472693,
                -6.735228322675218
              ],
              [
                -43.81972884009186,
                -6.726892335203186
              ],
              [
                -43.806821960433716,
                -6.718290917219497
              ],
              [
                -43.80237497915763,
                -6.712363445936909
              ],
              [
                -43.79498556982182,
                -6.702512993303344
              ],
              [
                -43.78192216251882,
                -6.710168456322307
              ],
              [
                -43.77444473359195,
                -6.714550891117909
              ],
              [
                -43.76778578922128,
                -6.708651946903778
              ],
              [
                -43.765911803720826,
                -6.706991938258048
              ],
              [
                -43.76033782679735,
                -6.702053958065838
              ],
              [
                -43.75355239537192,
                -6.702553959111985
              ],
              [
                -43.72831510839914,
                -6.704416928785981
              ],
              [
                -43.71562069774578,
                -6.698806966776594
              ],
              [
                -43.70563379255773,
                -6.700257965497534
              ],
              [
                -43.702186831253556,
                -6.704356430491215
              ],
              [
                -43.69814183223434,
                -6.709165377934731
              ],
              [
                -43.672321045247536,
                -6.705881380916297
              ],
              [
                -43.637115314344605,
                -6.719176764967075
              ],
              [
                -43.62914140292625,
                -6.726901711414583
              ],
              [
                -43.621168942498144,
                -6.734627144853726
              ],
              [
                -43.59853163439069,
                -6.743307076790142
              ],
              [
                -43.58398023757965,
                -6.757099968580979
              ],
              [
                -43.57526481623475,
                -6.756025969313002
              ],
              [
                -43.57345931048975,
                -6.752781477076867
              ],
              [
                -43.57264283929169,
                -6.751314989914514
              ],
              [
                -43.57118835119064,
                -6.74870153344827
              ],
              [
                -43.56044640979376,
                -6.749547016849376
              ],
              [
                -43.550436990194086,
                -6.762012387705591
              ],
              [
                -43.54649050794709,
                -6.779936781353755
              ],
              [
                -43.545882540679976,
                -6.782696738130318
              ],
              [
                -43.54207056655572,
                -6.786579211317045
              ],
              [
                -43.53825809143586,
                -6.790461715501796
              ],
              [
                -43.53341061967252,
                -6.791920668188046
              ],
              [
                -43.50649332104548,
                -6.800021606690493
              ],
              [
                -43.49489989522794,
                -6.808887510973999
              ],
              [
                -43.48971893431368,
                -6.818921441037858
              ],
              [
                -43.4892979500833,
                -6.819736431869977
              ],
              [
                -43.48155701400814,
                -6.834714325513826
              ],
              [
                -43.45690771055285,
                -6.845937728386533
              ],
              [
                -43.454093221813565,
                -6.844260747116885
              ],
              [
                -43.45165523182916,
                -6.842808274545572
              ],
              [
                -43.44396380013068,
                -6.838227285135027
              ],
              [
                -43.4349083852878,
                -6.840171257937658
              ],
              [
                -43.41964400194257,
                -6.843448233200289
              ],
              [
                -43.40875159940336,
                -6.841275746991464
              ],
              [
                -43.403048631847746,
                -6.836314751930962
              ],
              [
                -43.39908368591906,
                -6.832865277450119
              ],
              [
                -43.3911937402669,
                -6.834018298855385
              ],
              [
                -43.38973174447044,
                -6.827431339867293
              ],
              [
                -43.38747678899431,
                -6.817271888849045
              ],
              [
                -43.37358388741653,
                -6.818238885791346
              ],
              [
                -43.36369648043993,
                -6.818927352938131
              ],
              [
                -43.35614155551824,
                -6.808309950010654
              ],
              [
                -43.34858759057843,
                -6.797693501110778
              ],
              [
                -43.30692593404021,
                -6.800495440765266
              ],
              [
                -43.27833166971198,
                -6.796230965583027
              ],
              [
                -43.27045124539226,
                -6.790740485737887
              ],
              [
                -43.262571837066396,
                -6.785250039908158
              ],
              [
                -43.25486388512983,
                -6.774995585463084
              ],
              [
                -43.249744417324706,
                -6.768184146354253
              ],
              [
                -43.2470234774142,
                -6.767075654164457
              ],
              [
                -43.244301472513044,
                -6.765967147974874
              ],
              [
                -43.20350583034067,
                -6.763804139211754
              ],
              [
                -43.16834007424816,
                -6.769348090479595
              ],
              [
                -43.152276200193,
                -6.782393986858355
              ],
              [
                -43.14563828019868,
                -6.782716475273476
              ],
              [
                -43.130419379292405,
                -6.780614460341995
              ],
              [
                -43.10472161957201,
                -6.770363017830902
              ],
              [
                -43.0817223176257,
                -6.747695686040159
              ],
              [
                -43.07355690797928,
                -6.748276650419002
              ],
              [
                -43.06539045234887,
                -6.748858166793003
              ],
              [
                -43.05844850562879,
                -6.753340608998283
              ],
              [
                -43.048277081702714,
                -6.759908551612279
              ],
              [
                -43.03300519386499,
                -6.762272524517102
              ],
              [
                -43.026286754224905,
                -6.760563525548348
              ],
              [
                -43.00077597927058,
                -6.754076067268104
              ],
              [
                -42.995872498990465,
                -6.749564577977551
              ],
              [
                -42.99096955470525,
                -6.745053625691658
              ],
              [
                -42.978641673644155,
                -6.718374314725442
              ],
              [
                -42.949327949718864,
                -6.704499898095564
              ],
              [
                -42.919385186113054,
                -6.669923606924925
              ],
              [
                -42.91602471946076,
                -6.656005719074119
              ],
              [
                -42.91391875748073,
                -6.647283769599173
              ],
              [
                -42.91470174521407,
                -6.644499285368992
              ],
              [
                -42.91683124660953,
                -6.63692183589141
              ],
              [
                -42.903714372694,
                -6.597267623117273
              ],
              [
                -42.885152031308294,
                -6.573262278975469
              ],
              [
                -42.884681047195436,
                -6.56668532532789
              ],
              [
                -42.88421056107363,
                -6.560107873690451
              ],
              [
                -42.88905402109043,
                -6.538124524908403
              ],
              [
                -42.871432190767095,
                -6.511559218419179
              ],
              [
                -42.87937811596378,
                -6.509750249571727
              ],
              [
                -42.884721574062006,
                -6.508533268453907
              ],
              [
                -42.88511310655183,
                -6.504962791679849
              ],
              [
                -42.883272621335344,
                -6.496109338592192
              ],
              [
                -42.88284063644871,
                -6.494028843378482
              ],
              [
                -42.87347268165399,
                -6.488228916444794
              ],
              [
                -42.867413262182644,
                -6.48447641914324
              ],
              [
                -42.87069373827346,
                -6.465872076828273
              ],
              [
                -42.86992975251537,
                -6.457913125111693
              ],
              [
                -42.86916577475105,
                -6.44995219441873
              ],
              [
                -42.874821729280086,
                -6.434033773408689
              ],
              [
                -42.876829726036476,
                -6.428382346010684
              ],
              [
                -42.877096208919625,
                -6.422933373840915
              ],
              [
                -42.87736221080327,
                -6.417484413674059
              ],
              [
                -42.86938280749924,
                -6.406396503393225
              ],
              [
                -42.85736438747979,
                -6.38969611900269
              ],
              [
                -42.85670341367433,
                -6.379212193840719
              ],
              [
                -42.85613541599951,
                -6.370206753995617
              ],
              [
                -42.85378844103738,
                -6.363730782422875
              ],
              [
                -42.85197546060307,
                -6.358727837523317
              ],
              [
                -42.83147762280496,
                -6.342379444521099
              ],
              [
                -42.82894316652644,
                -6.337040962567531
              ],
              [
                -42.83668960884307,
                -6.324970046260155
              ],
              [
                -42.84904851618769,
                -6.330793533898838
              ],
              [
                -42.85432597407518,
                -6.333279494665656
              ],
              [
                -42.8598003994682,
                -6.330164545392904
              ],
              [
                -42.85030751533789,
                -6.306969210488908
              ],
              [
                -42.84898202273064,
                -6.298055248513682
              ],
              [
                -42.84661155581171,
                -6.282113385047825
              ],
              [
                -42.850518012948925,
                -6.275011422703336
              ],
              [
                -42.85507950557839,
                -6.266717497531481
              ],
              [
                -42.85500051335714,
                -6.262412039884302
              ],
              [
                -42.854830499455915,
                -6.253182099105194
              ],
              [
                -42.86674294147987,
                -6.234282232670006
              ],
              [
                -42.87036387916077,
                -6.231011748161142
              ],
              [
                -42.87398385684983,
                -6.227741780646628
              ],
              [
                -42.88436327991961,
                -6.226151295768809
              ],
              [
                -42.89497318613895,
                -6.224525327314895
              ],
              [
                -42.90560963213572,
                -6.218774392969747
              ],
              [
                -42.916246530138885,
                -6.213024940605816
              ],
              [
                -42.92264048920187,
                -6.205482975243694
              ],
              [
                -42.93351089640264,
                -6.192661089624366
              ],
              [
                -42.94249284176691,
                -6.189989626587315
              ],
              [
                -42.9514747551408,
                -6.187318115547194
              ],
              [
                -42.968193649055145,
                -6.177874720462719
              ],
              [
                -42.97162611180623,
                -6.172518253997682
              ],
              [
                -42.97505907755207,
                -6.167161304536006
              ],
              [
                -42.98213004889955,
                -6.129111078194323
              ],
              [
                -42.992387486138874,
                -6.117638663234988
              ],
              [
                -42.99262548738123,
                -6.117372167349457
              ],
              [
                -43.01094132233613,
                -6.110631730591161
              ],
              [
                -43.03370562591774,
                -6.110968232363901
              ],
              [
                -43.04164557912143,
                -6.105168282324833
              ],
              [
                -43.049586505322,
                -6.099368328280836
              ],
              [
                -43.05774246989272,
                -6.076470028098293
              ],
              [
                -43.05964346613222,
                -6.071132067387674
              ],
              [
                -43.06259492135183,
                -6.06781905464906
              ],
              [
                -43.07459482679905,
                -6.054348180420179
              ],
              [
                -43.07494583179521,
                -6.0417727624242
              ],
              [
                -43.058748997121285,
                -6.027605380418664
              ],
              [
                -43.054109509889635,
                -6.023547399783715
              ],
              [
                -43.04983105245782,
                -6.01270947292486
              ],
              [
                -43.04954809090741,
                -6.001079545676128
              ],
              [
                -43.04936107881113,
                -5.993384101765107
              ],
              [
                -43.05507005728795,
                -5.986019672563035
              ],
              [
                -43.059306503645395,
                -5.98317917327642
              ],
              [
                -43.071028905331495,
                -5.975317270899867
              ],
              [
                -43.072133402822764,
                -5.974576275084127
              ],
              [
                -43.08126933673938,
                -5.96083187686923
              ],
              [
                -43.0865033286309,
                -5.946926963111304
              ],
              [
                -43.086900305472014,
                -5.93380558328063
              ],
              [
                -43.08727582996648,
                -5.921411172140357
              ],
              [
                -43.09320178188504,
                -5.912245707242652
              ],
              [
                -43.09419128485764,
                -5.910715742111564
              ],
              [
                -43.09464324864824,
                -5.910016246537701
              ],
              [
                -43.09902873023678,
                -5.900712309587798
              ],
              [
                -43.07919841742615,
                -5.871221020666236
              ],
              [
                -43.07837393499998,
                -5.869994009079669
              ],
              [
                -43.07779445251913,
                -5.867382042796654
              ],
              [
                -43.07721394004729,
                -5.864768070528753
              ],
              [
                -43.09625231053556,
                -5.829572338151823
              ],
              [
                -43.091307369866605,
                -5.806040485097544
              ],
              [
                -43.09447435349958,
                -5.799393531928856
              ],
              [
                -43.09764181112482,
                -5.792747081757443
              ],
              [
                -43.1009412957995,
                -5.765102799518622
              ],
              [
                -43.096622869309044,
                -5.745338461473335
              ],
              [
                -43.09438836596159,
                -5.741738485365415
              ],
              [
                -43.08792393580005,
                -5.741654956640668
              ],
              [
                -43.07978199435679,
                -5.741549952948859
              ],
              [
                -43.077154515698396,
                -5.731244029771548
              ],
              [
                -43.087667463036354,
                -5.682818403084243
              ],
              [
                -43.08230602850271,
                -5.646901148236494
              ],
              [
                -43.09030497265719,
                -5.634020257114782
              ],
              [
                -43.09069696787136,
                -5.63338876101117
              ],
              [
                -43.09860740667259,
                -5.632816754420078
              ],
              [
                -43.09485142970648,
                -5.613920401755921
              ],
              [
                -43.09070747460172,
                -5.607693958787756
              ],
              [
                -43.089778997657774,
                -5.606963960543569
              ],
              [
                -43.08025056233237,
                -5.599474001351607
              ],
              [
                -43.064840196648575,
                -5.595656517339219
              ],
              [
                -43.048414349447356,
                -5.591587041535997
              ],
              [
                -43.03421044528394,
                -5.59188254909037
              ],
              [
                -43.027027499516315,
                -5.578720628251684
              ],
              [
                -43.025957014486096,
                -5.572205152506044
              ],
              [
                -43.02399707322034,
                -5.560274242054387
              ],
              [
                -43.00488771146188,
                -5.541531865695456
              ],
              [
                -42.99486132584426,
                -5.50656814987787
              ],
              [
                -42.97504301245578,
                -5.485236278478422
              ],
              [
                -42.97396451187395,
                -5.482721322340923
              ],
              [
                -42.972886006291226,
                -5.480206824201383
              ],
              [
                -42.97184501404273,
                -5.475110354136689
              ],
              [
                -42.967227600131444,
                -5.452503017030506
              ],
              [
                -42.96465661917905,
                -5.449297524058825
              ],
              [
                -42.96208612822196,
                -5.446092048090568
              ],
              [
                -42.94960274145713,
                -5.442670573433049
              ],
              [
                -42.93077738467647,
                -5.437511106609858
              ],
              [
                -42.92670443909348,
                -5.432591128379875
              ],
              [
                -42.92263244150098,
                -5.427671682154309
              ],
              [
                -42.91829050518304,
                -5.414686275382145
              ],
              [
                -42.912862564763316,
                -5.398453864968856
              ],
              [
                -42.90967309234985,
                -5.394469396428351
              ],
              [
                -42.904337645821414,
                -5.391560441591651
              ],
              [
                -42.89980618118506,
                -5.391102934131612
              ],
              [
                -42.88211130010703,
                -5.389317949236296
              ],
              [
                -42.87006241796175,
                -5.377720036516761
              ],
              [
                -42.869194408587774,
                -5.376884020161773
              ],
              [
                -42.864503448724896,
                -5.367663581486026
              ],
              [
                -42.83852316783735,
                -5.355754168485623
              ],
              [
                -42.83682120825888,
                -5.354973680207944
              ],
              [
                -42.831379242425555,
                -5.350778187905366
              ],
              [
                -42.83137273148201,
                -5.350773180938447
              ],
              [
                -42.82592480970062,
                -5.346573727669334
              ],
              [
                -42.812873930482624,
                -5.29966605137329
              ],
              [
                -42.81242043665868,
                -5.290747641253032
              ],
              [
                -42.8119669368254,
                -5.2818292061434
              ],
              [
                -42.82213588859294,
                -5.255476888927215
              ],
              [
                -42.82599535805909,
                -5.245475958512087
              ],
              [
                -42.826548841175914,
                -5.239457493524363
              ],
              [
                -42.82710383127562,
                -5.233440062533449
              ],
              [
                -42.81252645579286,
                -5.215010691538641
              ],
              [
                -42.79986508913786,
                -5.199002773811396
              ],
              [
                -42.79871160243712,
                -5.190924872148976
              ],
              [
                -42.79755759973231,
                -5.182847923489111
              ],
              [
                -42.80258258825761,
                -5.149878660637454
              ],
              [
                -42.80777506557233,
                -5.140053252814421
              ],
              [
                -42.81296801387723,
                -5.130226315003097
              ],
              [
                -42.81805050803033,
                -5.095079075512992
              ],
              [
                -42.81831898792611,
                -5.09322110249848
              ],
              [
                -42.81884797555634,
                -5.091587576968157
              ],
              [
                -42.81937749318278,
                -5.089952598449048
              ],
              [
                -42.8224909467713,
                -5.08641513861657
              ],
              [
                -42.836988864754694,
                -5.069941759775396
              ],
              [
                -42.83918882398358,
                -5.06271031789345
              ],
              [
                -42.84138884620646,
                -5.055479374010443
              ],
              [
                -42.841137832875226,
                -5.030419071442982
              ],
              [
                -42.85918919626266,
                -4.981870445572722
              ],
              [
                -42.860067732587005,
                -4.977508466581357
              ],
              [
                -42.86094771589738,
                -4.97314648859221
              ],
              [
                -42.860561730292446,
                -4.970995521396836
              ],
              [
                -42.85439630341498,
                -4.936649256862162
              ],
              [
                -42.8636637113475,
                -4.930071318227705
              ],
              [
                -42.867522670340634,
                -4.927331830866462
              ],
              [
                -42.86939515108601,
                -4.921955394027422
              ],
              [
                -42.88586352970732,
                -4.900279067075792
              ],
              [
                -42.890282024869876,
                -4.894464112958143
              ],
              [
                -42.89308798388504,
                -4.891002641369854
              ],
              [
                -42.8958949678916,
                -4.887541168781388
              ],
              [
                -42.89425798097314,
                -4.868592790182616
              ],
              [
                -42.89332902661925,
                -4.8578413578694
              ],
              [
                -42.895223980481774,
                -4.850085409812845
              ],
              [
                -42.89522398048345,
                -4.850082424835135
              ],
              [
                -42.897120475328556,
                -4.842323968801965
              ],
              [
                -42.92184230633348,
                -4.822498162665438
              ],
              [
                -42.93729466097099,
                -4.810105757809997
              ],
              [
                -42.94334411904857,
                -4.800215321001905
              ],
              [
                -42.94939456711229,
                -4.790324910192384
              ],
              [
                -42.94807658606951,
                -4.766493066348782
              ],
              [
                -42.92548828990625,
                -4.749642190670221
              ],
              [
                -42.920286350883686,
                -4.736038286439618
              ],
              [
                -42.922654339596434,
                -4.721306404842866
              ],
              [
                -42.93544323078502,
                -4.712730990964501
              ],
              [
                -42.94220918198575,
                -4.708193019593946
              ],
              [
                -42.949888115740656,
                -4.699967600277683
              ],
              [
                -42.954198577444906,
                -4.689095648893783
              ],
              [
                -42.94731513455548,
                -4.674546778899784
              ],
              [
                -42.918692390995695,
                -4.653053897108705
              ],
              [
                -42.91452792666194,
                -4.648153953520759
              ],
              [
                -42.910363978323,
                -4.643253485944792
              ],
              [
                -42.874286297041046,
                -4.581709415579847
              ],
              [
                -42.868648370388314,
                -4.569886500219918
              ],
              [
                -42.867530866054715,
                -4.563198551779076
              ],
              [
                -42.86918036014067,
                -4.556420114491144
              ],
              [
                -42.87433083415063,
                -4.535260260937005
              ],
              [
                -42.86716538307559,
                -4.525050851760644
              ],
              [
                -42.86000093598425,
                -4.514841900611316
              ],
              [
                -42.85934395874672,
                -4.504495499934388
              ],
              [
                -42.85909093588871,
                -4.500510033723266
              ],
              [
                -42.85725245670641,
                -4.498999038105436
              ],
              [
                -42.852395530494086,
                -4.495007551535617
              ],
              [
                -42.84960955733068,
                -4.485260648280282
              ],
              [
                -42.85005001693981,
                -4.480991185578073
              ],
              [
                -42.85366202755469,
                -4.476835186677155
              ],
              [
                -42.86886589187459,
                -4.46788726479709
              ],
              [
                -42.876014342611185,
                -4.463680314108825
              ],
              [
                -42.87711982371214,
                -4.457594859388207
              ],
              [
                -42.878224805812884,
                -4.451509883666628
              ],
              [
                -42.87763132574637,
                -4.449404920170902
              ],
              [
                -42.87164588054925,
                -4.428195587433008
              ],
              [
                -42.87947882080815,
                -4.419819133416168
              ],
              [
                -42.887311757067394,
                -4.411442718392769
              ],
              [
                -42.93005892182983,
                -4.381970959749518
              ],
              [
                -42.93982786895899,
                -4.384130910512185
              ],
              [
                -42.95041325234027,
                -4.386472415335234
              ],
              [
                -42.952100760955936,
                -4.385697941015063
              ],
              [
                -42.958275710982576,
                -4.382863451460249
              ],
              [
                -42.962920664519224,
                -4.376049001012047
              ],
              [
                -42.96237217028593,
                -4.353704684582133
              ],
              [
                -42.962129202188585,
                -4.343931729891851
              ],
              [
                -42.96188818006441,
                -4.334158310217875
              ],
              [
                -42.976381082837065,
                -4.30805302473935
              ],
              [
                -42.98055855849714,
                -4.300528567400474
              ],
              [
                -42.98195503634027,
                -4.294020120029707
              ],
              [
                -42.98335253517047,
                -4.287511679662238
              ],
              [
                -42.988803514246406,
                -4.233691094186483
              ],
              [
                -42.98520805732466,
                -4.22400065433477
              ],
              [
                -42.98161358638464,
                -4.214310233502705
              ],
              [
                -42.940390949956985,
                -4.166569559975665
              ],
              [
                -42.93609048130499,
                -4.161916116968094
              ],
              [
                -42.93559648659483,
                -4.16138210975437
              ],
              [
                -42.92613605723615,
                -4.156384637849543
              ],
              [
                -42.908381713502415,
                -4.15578913274308
              ],
              [
                -42.887906378076835,
                -4.15510262201253
              ],
              [
                -42.88752537306567,
                -4.1511916393393
              ],
              [
                -42.88714437705286,
                -4.147280179671812
              ],
              [
                -42.8876559000964,
                -4.146816186420327
              ],
              [
                -42.90375273658976,
                -4.132229322258519
              ],
              [
                -42.90266727438778,
                -4.123984368959857
              ],
              [
                -42.8955313292211,
                -4.116527918818331
              ],
              [
                -42.88468892964032,
                -4.112631940037606
              ],
              [
                -42.87644400740512,
                -4.109669961458407
              ],
              [
                -42.862544110105205,
                -4.092509080455194
              ],
              [
                -42.85968162723519,
                -4.084587638950622
              ],
              [
                -42.856819682354214,
                -4.076665687462953
              ],
              [
                -42.84256529330404,
                -4.037251003765848
              ],
              [
                -42.823257990156655,
                -3.991836337465563
              ],
              [
                -42.81497903391772,
                -3.987324350793766
              ],
              [
                -42.80832508031253,
                -3.993869314137245
              ],
              [
                -42.805366123167914,
                -3.996779306723678
              ],
              [
                -42.80070317144497,
                -3.996463279950985
              ],
              [
                -42.79752767251342,
                -3.996247764108353
              ],
              [
                -42.79279223745314,
                -3.980558390773373
              ],
              [
                -42.784742287792376,
                -3.957601060050556
              ],
              [
                -42.738520178646134,
                -3.925269315136156
              ],
              [
                -42.73216826415943,
                -3.917457863538883
              ],
              [
                -42.72581581467368,
                -3.90964640996052
              ],
              [
                -42.72164386496638,
                -3.882557632812399
              ],
              [
                -42.721209840197346,
                -3.878798656174025
              ],
              [
                -42.72077736041427,
                -3.875039188542136
              ],
              [
                -42.68536265747485,
                -3.806887188978927
              ],
              [
                -42.675587735585125,
                -3.797606255306688
              ],
              [
                -42.67116179766891,
                -3.793403766351117
              ],
              [
                -42.66830982527318,
                -3.790548785833698
              ],
              [
                -42.67866475683418,
                -3.778192381456726
              ],
              [
                -42.680750219045144,
                -3.770606444131132
              ],
              [
                -42.68075174203595,
                -3.770598960188806
              ],
              [
                -42.68284020221947,
                -3.763006021920983
              ],
              [
                -42.67067781421661,
                -3.74122618353339
              ],
              [
                -42.668478336482636,
                -3.728987764839114
              ],
              [
                -42.68239123760943,
                -3.703062945423095
              ],
              [
                -42.68240575144558,
                -3.693756036375043
              ],
              [
                -42.67888279294295,
                -3.685148588492569
              ],
              [
                -42.67719127254393,
                -3.681015123288769
              ],
              [
                -42.675364783942165,
                -3.675238662561088
              ],
              [
                -42.656690946204215,
                -3.653094825560935
              ],
              [
                -42.63548013296006,
                -3.631594994732144
              ],
              [
                -42.63433765656124,
                -3.631018481653956
              ],
              [
                -42.62424723436361,
                -3.625927039297851
              ],
              [
                -42.62181027231455,
                -3.621163558661242
              ],
              [
                -42.616972825881604,
                -3.61170762387669
              ],
              [
                -42.60934288112025,
                -3.585773809898388
              ],
              [
                -42.57208667237585,
                -3.569135428718267
              ],
              [
                -42.56199827971846,
                -3.555390535739382
              ],
              [
                -42.53299602793733,
                -3.517565800520355
              ],
              [
                -42.53384502371295,
                -3.508264889126732
              ],
              [
                -42.5345855178941,
                -3.500148960616703
              ],
              [
                -42.5291645575742,
                -3.492140500802327
              ],
              [
                -42.51240419807938,
                -3.482169087491809
              ],
              [
                -42.50616625775007,
                -3.478457590444838
              ],
              [
                -42.5057532739233,
                -3.473972618675321
              ],
              [
                -42.504083769817896,
                -3.455828292186266
              ],
              [
                -42.50293329688942,
                -3.454235776944841
              ],
              [
                -42.49797584428562,
                -3.447375340607803
              ],
              [
                -42.49165640188845,
                -3.448053341888602
              ],
              [
                -42.486947917340146,
                -3.448557328145844
              ],
              [
                -42.486034950537125,
                -3.467011174173336
              ],
              [
                -42.485853935573104,
                -3.470666170058238
              ],
              [
                -42.472830531445695,
                -3.48336857337463
              ],
              [
                -42.46609059827387,
                -3.484662051739881
              ],
              [
                -42.45968313268203,
                -3.485189545115202
              ],
              [
                -42.458310167912444,
                -3.485302518696108
              ],
              [
                -42.44799922347238,
                -3.478077568002993
              ],
              [
                -42.44785923166138,
                -3.477979074702783
              ],
              [
                -42.4318858752702,
                -3.454253745479952
              ],
              [
                -42.42277843223344,
                -3.453904255536466
              ],
              [
                -42.41688649206112,
                -3.457919724343696
              ],
              [
                -42.416226511898806,
                -3.464461185833469
              ],
              [
                -42.41542048935761,
                -3.472451114144268
              ],
              [
                -42.40929353875354,
                -3.474855582223521
              ],
              [
                -42.40589357501598,
                -3.474047596067767
              ],
              [
                -42.398603134622036,
                -3.472315628453431
              ],
              [
                -42.39203118554852,
                -3.45991118813363
              ],
              [
                -42.39211420359592,
                -3.455527742851341
              ],
              [
                -42.39227171657136,
                -3.447225789716525
              ],
              [
                -42.37639733630586,
                -3.451709242946039
              ],
              [
                -42.373393870665886,
                -3.452557265232802
              ],
              [
                -42.353918501565694,
                -3.448106781712326
              ],
              [
                -42.330421226569875,
                -3.433506397715094
              ],
              [
                -42.32544775106946,
                -3.434573382539674
              ],
              [
                -42.32047481056741,
                -3.435640356363624
              ],
              [
                -42.31933978556544,
                -3.436511356212275
              ],
              [
                -42.29841098148926,
                -3.452583224237111
              ],
              [
                -42.28874555951555,
                -3.451391226574742
              ],
              [
                -42.27941761293444,
                -3.447243247806552
              ],
              [
                -42.27237769212301,
                -3.444112762120803
              ],
              [
                -42.24254343328687,
                -3.432679846393338
              ],
              [
                -42.22229357731911,
                -3.434693335893467
              ],
              [
                -42.209705189557155,
                -3.43594534027959
              ],
              [
                -42.20521273932228,
                -3.431478346943038
              ],
              [
                -42.20240578254377,
                -3.428685861366362
              ],
              [
                -42.20217827750637,
                -3.428460877011374
              ],
              [
                -42.20399024386132,
                -3.435413808123349
              ],
              [
                -42.197304818156525,
                -3.424648398490218
              ],
              [
                -42.197555293675194,
                -3.415428986681754
              ],
              [
                -42.19783281634869,
                -3.405208562613861
              ],
              [
                -42.18998038193024,
                -3.395215618635565
              ],
              [
                -42.18522542928172,
                -3.392213646945825
              ],
              [
                -42.15181071874522,
                -3.387118166284582
              ],
              [
                -42.14208829954627,
                -3.359386379683563
              ],
              [
                -42.134209833678675,
                -3.356368903962672
              ],
              [
                -42.12834439591729,
                -3.35412241206491
              ],
              [
                -42.124145453168,
                -3.352514430876851
              ],
              [
                -42.12739689247306,
                -3.344280494754316
              ],
              [
                -42.1280504017103,
                -3.342624513802283
              ],
              [
                -42.12189947242687,
                -3.321526166539779
              ],
              [
                -42.11327153238328,
                -3.314152710274308
              ],
              [
                -42.11019757301244,
                -3.313443224594329
              ],
              [
                -42.09974814714138,
                -3.311033254270368
              ],
              [
                -42.096048673429934,
                -3.303673816800138
              ],
              [
                -42.097282691352135,
                -3.298046362804105
              ],
              [
                -42.10193563033716,
                -3.294350358155736
              ],
              [
                -42.128162426462154,
                -3.290327900162161
              ],
              [
                -42.13219040246814,
                -3.289709901460288
              ],
              [
                -42.133493866423954,
                -3.285132964349651
              ],
              [
                -42.13479788337299,
                -3.280556493236657
              ],
              [
                -42.115443032925064,
                -3.261972138766506
              ],
              [
                -42.076255362752576,
                -3.258061645412238
              ],
              [
                -42.071292911677574,
                -3.255455163748262
              ],
              [
                -42.066331449596206,
                -3.252848698088481
              ],
              [
                -42.030117225322904,
                -3.245541727245755
              ],
              [
                -42.02352580271704,
                -3.245678731738113
              ],
              [
                -42.00992189599535,
                -3.245962234493415
              ],
              [
                -41.999838513226116,
                -3.240734252293203
              ],
              [
                -41.9954270214041,
                -3.236024302187631
              ],
              [
                -41.984117142278556,
                -3.22395089166912
              ],
              [
                -41.98337064673894,
                -3.223152866585777
              ],
              [
                -41.97866669144491,
                -3.218131942813687
              ],
              [
                -41.974313701704176,
                -3.211857970903631
              ],
              [
                -41.97119723049924,
                -3.192421107637845
              ],
              [
                -41.96407131036723,
                -3.178632734109467
              ],
              [
                -41.955721890570665,
                -3.178134204951812
              ],
              [
                -41.95217839974917,
                -3.181245691498832
              ],
              [
                -41.942891988598774,
                -3.189400150786706
              ],
              [
                -41.93866702139736,
                -3.187008127829763
              ],
              [
                -41.93691503903522,
                -3.155879380059641
              ],
              [
                -41.93688804142702,
                -3.155406408725741
              ],
              [
                -41.934161054253536,
                -3.149013927429078
              ],
              [
                -41.931291601166336,
                -3.145685470271375
              ],
              [
                -41.924180659902724,
                -3.137435027865197
              ],
              [
                -41.92508114031766,
                -3.123677651164422
              ],
              [
                -41.92564164669745,
                -3.115115695952217
              ],
              [
                -41.9223656594402,
                -3.107575242441609
              ],
              [
                -41.91344623205113,
                -3.09947683328939
              ],
              [
                -41.902838846340266,
                -3.097925314614645
              ],
              [
                -41.89938685907508,
                -3.097420336325504
              ],
              [
                -41.89443589343416,
                -3.093418860722927
              ],
              [
                -41.88948443879852,
                -3.089415892138287
              ],
              [
                -41.869605612719184,
                -3.059369104167868
              ],
              [
                -41.86543914137218,
                -3.053073664765731
              ],
              [
                -41.861272207026325,
                -3.046777713378065
              ],
              [
                -41.840325373996656,
                -3.036372783225787
              ],
              [
                -41.83257293750206,
                -3.032522303570441
              ],
              [
                -41.82823247087361,
                -3.028332360747696
              ],
              [
                -41.82389352323215,
                -3.024143383924208
              ],
              [
                -41.814347094627834,
                -2.987701668992824
              ],
              [
                -41.79641073239804,
                -2.966204320755177
              ],
              [
                -41.802915720588615,
                -2.944939999026537
              ],
              [
                -41.80755318458854,
                -2.93765954244957
              ],
              [
                -41.820349584915974,
                -2.927657619849922
              ],
              [
                -41.83568344389598,
                -2.9156722335693
              ],
              [
                -41.84219988362073,
                -2.910576778530774
              ],
              [
                -41.85752277500491,
                -2.891557932150445
              ],
              [
                -41.86131625537983,
                -2.883043996905044
              ],
              [
                -41.86510972674876,
                -2.874530067660687
              ],
              [
                -41.8610312584761,
                -2.856025205920059
              ],
              [
                -41.86059326374066,
                -2.854036722317803
              ],
              [
                -41.86088877299157,
                -2.842520785420907
              ],
              [
                -41.86118477222341,
                -2.831004890538282
              ],
              [
                -41.85058484135713,
                -2.826589924642014
              ],
              [
                -41.843287913722044,
                -2.823550426063613
              ],
              [
                -41.840738415529835,
                -2.818187985185203
              ],
              [
                -41.84411939401603,
                -2.810388555274872
              ],
              [
                -41.84641890466083,
                -2.805082581515058
              ],
              [
                -41.84866637808668,
                -2.773167340939807
              ],
              [
                -41.82419356505541,
                -2.755869476649101
              ],
              [
                -41.82246161826277,
                -2.753070484024777
              ],
              [
                -41.8173026655566,
                -2.744735032693558
              ],
              [
                -41.81372819093642,
                -2.738958597826486
              ],
              [
                -41.80719421658982,
                -2.743665531914599
              ],
              [
                -41.8016237824113,
                -2.747903014977606
              ],
              [
                -41.79604230358261,
                -2.754540450232398
              ],
              [
                -41.78454542815591,
                -2.769525362231596
              ],
              [
                -41.778792947147736,
                -2.774856783653741
              ],
              [
                -41.763189575903496,
                -2.789318667428321
              ],
              [
                -41.76192508601426,
                -2.790489679853879
              ],
              [
                -41.75321664564509,
                -2.79856210285023
              ],
              [
                -41.75013468328847,
                -2.801418584492358
              ],
              [
                -41.74572771230969,
                -2.803020066543027
              ],
              [
                -41.74153774655897,
                -2.804543038276793
              ],
              [
                -41.73867878992938,
                -2.805582542221568
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 18,
        "nome": "Paraná",
        "sigla": "PR",
        "regiao_id": 1,
        "codigo_ibg": 41
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -52.06415660907534,
                -22.53383576363634
              ],
              [
                -52.0555581623148,
                -22.538336209777892
              ],
              [
                -52.05053818457647,
                -22.538249687944244
              ],
              [
                -52.04763373927889,
                -22.53860968073441
              ],
              [
                -52.040272796864535,
                -22.53891916225368
              ],
              [
                -52.03342382065929,
                -22.53920762340585
              ],
              [
                -52.02863185520385,
                -22.5394091101221
              ],
              [
                -51.99770408722918,
                -22.545126515938435
              ],
              [
                -51.960367803464436,
                -22.562976330131583
              ],
              [
                -51.95018739879806,
                -22.564919304636973
              ],
              [
                -51.940007976141025,
                -22.566862257145832
              ],
              [
                -51.90121016395916,
                -22.601495524033563
              ],
              [
                -51.89216620233032,
                -22.60983447749409
              ],
              [
                -51.889053693884726,
                -22.61270345666164
              ],
              [
                -51.88511419380753,
                -22.616401418027678
              ],
              [
                -51.87301078080515,
                -22.627763337190988
              ],
              [
                -51.868864817637494,
                -22.628517833642164
              ],
              [
                -51.85527089219141,
                -22.630992294658498
              ],
              [
                -51.84658947050312,
                -22.631743260402036
              ],
              [
                -51.77292609344148,
                -22.614082111184896
              ],
              [
                -51.752781218545415,
                -22.61727105461877
              ],
              [
                -51.74933523573314,
                -22.62042200762277
              ],
              [
                -51.74676476406066,
                -22.62277301146641
              ],
              [
                -51.74344579725825,
                -22.62336248008906
              ],
              [
                -51.73473083417509,
                -22.62491147558423
              ],
              [
                -51.7308818385406,
                -22.63973141273209
              ],
              [
                -51.725371281072896,
                -22.660949280323642
              ],
              [
                -51.7176643251562,
                -22.66853322973587
              ],
              [
                -51.710238907240914,
                -22.668672206728015
              ],
              [
                -51.69866949964383,
                -22.6688881968868
              ],
              [
                -51.68152362697946,
                -22.660723675704432
              ],
              [
                -51.68062712131143,
                -22.66029665770355
              ],
              [
                -51.67122921916034,
                -22.6585286713858
              ],
              [
                -51.66183131101995,
                -22.65676064407706
              ],
              [
                -51.65882334156981,
                -22.656871623549634
              ],
              [
                -51.628084063104495,
                -22.65800201422098
              ],
              [
                -51.61978259407697,
                -22.660679016439506
              ],
              [
                -51.615338648976206,
                -22.66211147428679
              ],
              [
                -51.58954078199071,
                -22.685206335860077
              ],
              [
                -51.559065981536335,
                -22.696695670778713
              ],
              [
                -51.553431001871154,
                -22.694339662209764
              ],
              [
                -51.54779606420764,
                -22.691983694646435
              ],
              [
                -51.52519377887273,
                -22.686321651156824
              ],
              [
                -51.516731809349714,
                -22.68953009174458
              ],
              [
                -51.4984044447477,
                -22.690047538379858
              ],
              [
                -51.471434189077506,
                -22.676647031946864
              ],
              [
                -51.44579499682372,
                -22.65277757685031
              ],
              [
                -51.424222664172945,
                -22.653027496942418
              ],
              [
                -51.40144627044406,
                -22.66741686539461
              ],
              [
                -51.38697388343786,
                -22.66511133915921
              ],
              [
                -51.38603941212254,
                -22.66496233994986
              ],
              [
                -51.382529444001975,
                -22.6623938397598
              ],
              [
                -51.37321001989146,
                -22.657686851742067
              ],
              [
                -51.33278481742894,
                -22.667024694509333
              ],
              [
                -51.3215463856403,
                -22.669905134091035
              ],
              [
                -51.305796012482695,
                -22.673942083436703
              ],
              [
                -51.29611609360571,
                -22.670851084856068
              ],
              [
                -51.283421686272916,
                -22.66679104015832
              ],
              [
                -51.280928217208654,
                -22.665993541899162
              ],
              [
                -51.263286855777245,
                -22.668569983497985
              ],
              [
                -51.24659143781907,
                -22.684712888983455
              ],
              [
                -51.24071945099834,
                -22.696455326792506
              ],
              [
                -51.234846934167415,
                -22.70819772760446
              ],
              [
                -51.211556555115855,
                -22.72372512772661
              ],
              [
                -51.186515245024886,
                -22.729407047430996
              ],
              [
                -51.17553082897538,
                -22.73760294520462
              ],
              [
                -51.17470281633087,
                -22.738181461371322
              ],
              [
                -51.162071395748406,
                -22.74701738159159
              ],
              [
                -51.15439644611524,
                -22.75238586575921
              ],
              [
                -51.11308820566337,
                -22.760364697202046
              ],
              [
                -51.05711415238079,
                -22.76557304683467
              ],
              [
                -51.00361995663895,
                -22.79218576995849
              ],
              [
                -50.9940865361612,
                -22.79166424243184
              ],
              [
                -50.978093706913235,
                -22.783051223429084
              ],
              [
                -50.976339232508344,
                -22.783329751027367
              ],
              [
                -50.961116821819566,
                -22.785743175498812
              ],
              [
                -50.94463042404177,
                -22.80358906934426
              ],
              [
                -50.9391344448163,
                -22.804379530618668
              ],
              [
                -50.92627007203789,
                -22.798757520882724
              ],
              [
                -50.91855861251579,
                -22.795385515472468
              ],
              [
                -50.89039236285991,
                -22.79532593319596
              ],
              [
                -50.84188560153811,
                -22.830923165427983
              ],
              [
                -50.83272458361861,
                -22.857802046303064
              ],
              [
                -50.825686659750936,
                -22.86336799846298
              ],
              [
                -50.82398363725253,
                -22.86471498385363
              ],
              [
                -50.82325216890438,
                -22.86529347029699
              ],
              [
                -50.82225516051397,
                -22.86732994999866
              ],
              [
                -50.81651564800949,
                -22.879054884954062
              ],
              [
                -50.804232224830436,
                -22.8854898347913
              ],
              [
                -50.796020271527055,
                -22.889791796561287
              ],
              [
                -50.792452295956494,
                -22.893867785215974
              ],
              [
                -50.792642268755735,
                -22.90463174391893
              ],
              [
                -50.79284374928195,
                -22.905176216306675
              ],
              [
                -50.80565005724726,
                -22.939813106854206
              ],
              [
                -50.80251207869752,
                -22.942922068749326
              ],
              [
                -50.79577761756105,
                -22.94959552430278
              ],
              [
                -50.78876665408597,
                -22.948158034017784
              ],
              [
                -50.7774517361682,
                -22.94583701436482
              ],
              [
                -50.75816541741446,
                -22.947165936847682
              ],
              [
                -50.7498334536873,
                -22.95101240208114
              ],
              [
                -50.74630945707326,
                -22.955002887210433
              ],
              [
                -50.74109501387824,
                -22.960908346445954
              ],
              [
                -50.72935006535429,
                -22.96201380255441
              ],
              [
                -50.70933676490484,
                -22.952950285222325
              ],
              [
                -50.675533683434644,
                -22.900033422697827
              ],
              [
                -50.66179380727087,
                -22.895208394191513
              ],
              [
                -50.648317426477554,
                -22.896560847212903
              ],
              [
                -50.63674948565826,
                -22.906261273785244
              ],
              [
                -50.62001308333619,
                -22.91161370263866
              ],
              [
                -50.61226414948153,
                -22.919402660484334
              ],
              [
                -50.608379165099,
                -22.92330815882928
              ],
              [
                -50.576513478848526,
                -22.90348561146507
              ],
              [
                -50.55390462121998,
                -22.91121005699372
              ],
              [
                -50.54442819114159,
                -22.911502531385533
              ],
              [
                -50.54088373941945,
                -22.91161200231184
              ],
              [
                -50.52952728159166,
                -22.918615938519313
              ],
              [
                -50.52842428571216,
                -22.92419392731563
              ],
              [
                -50.526399271699475,
                -22.934441872186135
              ],
              [
                -50.52003529085522,
                -22.94002830217362
              ],
              [
                -50.51242285153043,
                -22.942822803870214
              ],
              [
                -50.50439540092177,
                -22.945770254692803
              ],
              [
                -50.49376898221143,
                -22.9402572624553
              ],
              [
                -50.48723754208477,
                -22.93686873578975
              ],
              [
                -50.47053619470676,
                -22.941702704863403
              ],
              [
                -50.46088626537592,
                -22.94449614745749
              ],
              [
                -50.45346832541855,
                -22.94276511033984
              ],
              [
                -50.44604986047095,
                -22.941034135226662
              ],
              [
                -50.42747348762525,
                -22.944507037285668
              ],
              [
                -50.41864011981967,
                -22.93388308855118
              ],
              [
                -50.418556148876156,
                -22.925457593989872
              ],
              [
                -50.418452661147384,
                -22.915002657740526
              ],
              [
                -50.41638471910428,
                -22.9127706577493
              ],
              [
                -50.395349393191324,
                -22.906596125293177
              ],
              [
                -50.38762096241564,
                -22.905520620625715
              ],
              [
                -50.37989351563623,
                -22.904446094962303
              ],
              [
                -50.36862908798065,
                -22.910715545429145
              ],
              [
                -50.3580896450721,
                -22.916581496722678
              ],
              [
                -50.358557620424,
                -22.92203044063815
              ],
              [
                -50.344908706040485,
                -22.93416385776744
              ],
              [
                -50.339693178100696,
                -22.947584782804963
              ],
              [
                -50.33752170220299,
                -22.947846279229662
              ],
              [
                -50.31800286598089,
                -22.95019523814851
              ],
              [
                -50.31000642579375,
                -22.94729073622099
              ],
              [
                -50.30569846102363,
                -22.94572569480056
              ],
              [
                -50.30441748546296,
                -22.943890715547298
              ],
              [
                -50.29991954718436,
                -22.93744671671585
              ],
              [
                -50.28876961709593,
                -22.93702122815349
              ],
              [
                -50.27289677724904,
                -22.93051321163845
              ],
              [
                -50.27027980523231,
                -22.931244195800144
              ],
              [
                -50.2676648012048,
                -22.931974174971867
              ],
              [
                -50.24611449035272,
                -22.937991569726865
              ],
              [
                -50.24269146030241,
                -22.946871041770883
              ],
              [
                -50.24124345558488,
                -22.950627537907724
              ],
              [
                -50.23815449987863,
                -22.952624022422
              ],
              [
                -50.23373103710385,
                -22.952767982611878
              ],
              [
                -50.223616095399635,
                -22.949644482323553
              ],
              [
                -50.216390163911505,
                -22.94741296198237
              ],
              [
                -50.20742524958002,
                -22.950785429253724
              ],
              [
                -50.20338924999771,
                -22.952303431918335
              ],
              [
                -50.19637379195916,
                -22.952633866604806
              ],
              [
                -50.18284392305333,
                -22.953269849582163
              ],
              [
                -50.165323080003795,
                -22.946300328184847
              ],
              [
                -50.158149156532886,
                -22.94050932680239
              ],
              [
                -50.150974247065875,
                -22.934718317434672
              ],
              [
                -50.13977181325056,
                -22.93756631822265
              ],
              [
                -50.13302485534677,
                -22.939282254989635
              ],
              [
                -50.11296151627107,
                -22.937812728301463
              ],
              [
                -50.10193161737533,
                -22.929368233533072
              ],
              [
                -50.094202698729,
                -22.923450713121234
              ],
              [
                -50.080359316117224,
                -22.921306703835956
              ],
              [
                -50.08031534236111,
                -22.921336183582433
              ],
              [
                -50.071059398373926,
                -22.92763565883015
              ],
              [
                -50.066978425620206,
                -22.927025625223152
              ],
              [
                -50.051574597449715,
                -22.90696321093259
              ],
              [
                -50.04614817034676,
                -22.902006684454587
              ],
              [
                -50.03134126662652,
                -22.90845913956092
              ],
              [
                -50.02418682067057,
                -22.90941760930478
              ],
              [
                -50.02416281684932,
                -22.909420604220955
              ],
              [
                -50.01703286872043,
                -22.91037557205318
              ],
              [
                -50.0052264306503,
                -22.926865491218894
              ],
              [
                -49.99575998571977,
                -22.92904596198805
              ],
              [
                -49.99019202240834,
                -22.92391045685416
              ],
              [
                -49.99355653052492,
                -22.913039517196097
              ],
              [
                -49.99728804183481,
                -22.90098256458583
              ],
              [
                -49.98567165610688,
                -22.89702054663828
              ],
              [
                -49.96463779224945,
                -22.91601840487565
              ],
              [
                -49.96276274118161,
                -22.923200357325676
              ],
              [
                -49.960888260103005,
                -22.930381840783767
              ],
              [
                -49.96311171798932,
                -22.937187297993702
              ],
              [
                -49.965918670073464,
                -22.94577828550229
              ],
              [
                -49.973554573452645,
                -22.954252272879188
              ],
              [
                -49.971662066810495,
                -22.95874125353893
              ],
              [
                -49.96470811040228,
                -22.966313205290795
              ],
              [
                -49.954058158194115,
                -22.977909627268883
              ],
              [
                -49.93027133689633,
                -22.982542038165594
              ],
              [
                -49.90104401278001,
                -22.99866440795016
              ],
              [
                -49.89964351997037,
                -23.00409885511777
              ],
              [
                -49.901729973937044,
                -23.007997848072264
              ],
              [
                -49.90387043018037,
                -23.009679362435758
              ],
              [
                -49.90897389292145,
                -23.013688351925136
              ],
              [
                -49.91590725966071,
                -23.03596075981265
              ],
              [
                -49.913509290076526,
                -23.04329422513232
              ],
              [
                -49.91111174247973,
                -23.050628184455043
              ],
              [
                -49.89620789281311,
                -23.05535612631509
              ],
              [
                -49.88487393681695,
                -23.064428090735507
              ],
              [
                -49.882412943490436,
                -23.061210080791497
              ],
              [
                -49.88120996248168,
                -23.059637587750945
              ],
              [
                -49.87514802236161,
                -23.065395556691023
              ],
              [
                -49.87105902317103,
                -23.06927901932112
              ],
              [
                -49.86692454417429,
                -23.07120199297833
              ],
              [
                -49.850088685927204,
                -23.07276591923969
              ],
              [
                -49.84961118842503,
                -23.0725594626755
              ],
              [
                -49.83317034828741,
                -23.065454445260777
              ],
              [
                -49.82650036800572,
                -23.074378867168665
              ],
              [
                -49.8196219189113,
                -23.0762598578165
              ],
              [
                -49.81905543085676,
                -23.076414849481164
              ],
              [
                -49.81486344947126,
                -23.0783033231078
              ],
              [
                -49.8122234582313,
                -23.079493323274047
              ],
              [
                -49.80939396447584,
                -23.086141772116218
              ],
              [
                -49.80861496226314,
                -23.087973274157267
              ],
              [
                -49.80611447060176,
                -23.093845240220475
              ],
              [
                -49.795886058800804,
                -23.094081700723432
              ],
              [
                -49.79508355909386,
                -23.09410019925267
              ],
              [
                -49.79458406344743,
                -23.094847206648037
              ],
              [
                -49.78991406012107,
                -23.101825642621282
              ],
              [
                -49.77786568284993,
                -23.100892135573282
              ],
              [
                -49.76245928235163,
                -23.10508506413277
              ],
              [
                -49.75704834599324,
                -23.10142806925634
              ],
              [
                -49.75089339128549,
                -23.09726805827007
              ],
              [
                -49.74230095156862,
                -23.098947552646244
              ],
              [
                -49.740866949466515,
                -23.098796531002634
              ],
              [
                -49.726584045034166,
                -23.10790194240678
              ],
              [
                -49.725946572903396,
                -23.11111293809797
              ],
              [
                -49.73033148071153,
                -23.122130924179498
              ],
              [
                -49.73441740548729,
                -23.13239737753413
              ],
              [
                -49.73000092466042,
                -23.140955332656237
              ],
              [
                -49.71600449748244,
                -23.147901273902058
              ],
              [
                -49.70933453294301,
                -23.156344681768964
              ],
              [
                -49.70090712712806,
                -23.152540688478148
              ],
              [
                -49.70305810563066,
                -23.157816196890135
              ],
              [
                -49.69777062426365,
                -23.163642143798942
              ],
              [
                -49.68425573638524,
                -23.164273103811524
              ],
              [
                -49.67782974637921,
                -23.16457259137677
              ],
              [
                -49.68047370841495,
                -23.169143069197798
              ],
              [
                -49.682775174235026,
                -23.1731215584718
              ],
              [
                -49.674568244832635,
                -23.17787102914545
              ],
              [
                -49.674440728588905,
                -23.17794450845799
              ],
              [
                -49.653402799735005,
                -23.21141230299925
              ],
              [
                -49.6540817348256,
                -23.227266748940288
              ],
              [
                -49.64922975402675,
                -23.2370781975387
              ],
              [
                -49.64774427295806,
                -23.2379002136681
              ],
              [
                -49.64460878581308,
                -23.23963467905748
              ],
              [
                -49.64538579191838,
                -23.244118153703706
              ],
              [
                -49.64661073037072,
                -23.251192651903516
              ],
              [
                -49.63959379388612,
                -23.255838085544557
              ],
              [
                -49.62779030384793,
                -23.28089494285341
              ],
              [
                -49.62532231209701,
                -23.286130937661927
              ],
              [
                -49.630900185102625,
                -23.30394386827674
              ],
              [
                -49.625987723279174,
                -23.31245082416109
              ],
              [
                -49.62278573126264,
                -23.317994271502197
              ],
              [
                -49.6212471963191,
                -23.33140074215369
              ],
              [
                -49.62565864026487,
                -23.33819623016528
              ],
              [
                -49.628950072196375,
                -23.343266690990905
              ],
              [
                -49.63572405232382,
                -23.339279742902125
              ],
              [
                -49.63710650326126,
                -23.349226201755528
              ],
              [
                -49.63337399975114,
                -23.355244671551247
              ],
              [
                -49.63294949272012,
                -23.355928148437922
              ],
              [
                -49.62464960118756,
                -23.35525116129853
              ],
              [
                -49.61167917320351,
                -23.354192621713185
              ],
              [
                -49.594789271544684,
                -23.366443514063366
              ],
              [
                -49.595449729146324,
                -23.374703457741383
              ],
              [
                -49.58991278046403,
                -23.38229841855295
              ],
              [
                -49.591060234847966,
                -23.388097909925246
              ],
              [
                -49.602834166581566,
                -23.383410944798012
              ],
              [
                -49.60515963305578,
                -23.384260463267598
              ],
              [
                -49.62044998451997,
                -23.3898489920907
              ],
              [
                -49.61887248663639,
                -23.396485422819673
              ],
              [
                -49.60494263656838,
                -23.393948933411824
              ],
              [
                -49.59818313841872,
                -23.399046376919554
              ],
              [
                -49.601791137699266,
                -23.400735869770457
              ],
              [
                -49.603849091205575,
                -23.401894892157767
              ],
              [
                -49.60318559131557,
                -23.407320368658226
              ],
              [
                -49.594088653147324,
                -23.40637581518756
              ],
              [
                -49.59317116179508,
                -23.406280830819565
              ],
              [
                -49.58909419176772,
                -23.415197764556527
              ],
              [
                -49.57983023581756,
                -23.414574751247446
              ],
              [
                -49.580301193644736,
                -23.431092175157623
              ],
              [
                -49.56661283618578,
                -23.426741181972684
              ],
              [
                -49.571163750422755,
                -23.43450663247054
              ],
              [
                -49.56853025866104,
                -23.43980862154991
              ],
              [
                -49.57252422215575,
                -23.44338311776573
              ],
              [
                -49.57911967923652,
                -23.43613016870971
              ],
              [
                -49.58711312661497,
                -23.435534212267896
              ],
              [
                -49.589615091330984,
                -23.435347188583155
              ],
              [
                -49.595151520114094,
                -23.43988968844665
              ],
              [
                -49.602587965667404,
                -23.450947184069886
              ],
              [
                -49.59872346307992,
                -23.45726561725058
              ],
              [
                -49.599818957462595,
                -23.461464124174224
              ],
              [
                -49.60208389662142,
                -23.470144575083005
              ],
              [
                -49.59808991333574,
                -23.476663554051562
              ],
              [
                -49.60130937066833,
                -23.47750857827589
              ],
              [
                -49.6027768796021,
                -23.477893568114332
              ],
              [
                -49.60677335229491,
                -23.47576506498674
              ],
              [
                -49.61439231196229,
                -23.471707117777235
              ],
              [
                -49.6135367552836,
                -23.48649606803679
              ],
              [
                -49.62088916317146,
                -23.492831541066842
              ],
              [
                -49.616962664943465,
                -23.501422011702132
              ],
              [
                -49.614100176508806,
                -23.507682457183492
              ],
              [
                -49.618865148745314,
                -23.50621298166178
              ],
              [
                -49.621223617530504,
                -23.505485520798228
              ],
              [
                -49.62870054668525,
                -23.511740491580586
              ],
              [
                -49.625252056918484,
                -23.519401432520716
              ],
              [
                -49.623719569016366,
                -23.522806923826252
              ],
              [
                -49.6118351110585,
                -23.52890837167611
              ],
              [
                -49.59971771355322,
                -23.535129799320885
              ],
              [
                -49.60218367845233,
                -23.538621325369938
              ],
              [
                -49.6046491253532,
                -23.54211280542182
              ],
              [
                -49.60216512952324,
                -23.546785265618137
              ],
              [
                -49.59874516688465,
                -23.553218245716256
              ],
              [
                -49.602141122874286,
                -23.55768471757446
              ],
              [
                -49.60750251996111,
                -23.564736692733188
              ],
              [
                -49.60313304294006,
                -23.57115118604242
              ],
              [
                -49.60068904963899,
                -23.57473915584164
              ],
              [
                -49.60153254926181,
                -23.58220614061797
              ],
              [
                -49.60992443489663,
                -23.58736263181854
              ],
              [
                -49.615926881834774,
                -23.59105163983108
              ],
              [
                -49.61896284662706,
                -23.6016831092673
              ],
              [
                -49.62063828081576,
                -23.60656307426628
              ],
              [
                -49.617552823529195,
                -23.61212905699094
              ],
              [
                -49.61477933889304,
                -23.613461534085477
              ],
              [
                -49.61365883490958,
                -23.613999540468853
              ],
              [
                -49.613438309955974,
                -23.617833516021378
              ],
              [
                -49.61315278848222,
                -23.622795492737406
              ],
              [
                -49.61181929499105,
                -23.623111970384738
              ],
              [
                -49.60580986785455,
                -23.624535938272075
              ],
              [
                -49.60775330799887,
                -23.63357341199476
              ],
              [
                -49.608473822646204,
                -23.63402091534347
              ],
              [
                -49.61664871521013,
                -23.639101926293833
              ],
              [
                -49.61081474276031,
                -23.64077988466112
              ],
              [
                -49.60288932585639,
                -23.643059370197488
              ],
              [
                -49.60140031067359,
                -23.65145333515463
              ],
              [
                -49.5920293854549,
                -23.655370785555245
              ],
              [
                -49.584983430167256,
                -23.65831576602202
              ],
              [
                -49.58524938003064,
                -23.66334172616344
              ],
              [
                -49.58065794944988,
                -23.663969746618708
              ],
              [
                -49.58194892641848,
                -23.667797207806952
              ],
              [
                -49.582629389753755,
                -23.669813704586254
              ],
              [
                -49.57924438825986,
                -23.677769642584362
              ],
              [
                -49.57478895127193,
                -23.675700659534925
              ],
              [
                -49.57419146604012,
                -23.675423170858206
              ],
              [
                -49.574377936986316,
                -23.676138161486
              ],
              [
                -49.5753634375862,
                -23.679909656980282
              ],
              [
                -49.56763197096217,
                -23.68754559806841
              ],
              [
                -49.56475648206319,
                -23.68639607304372
              ],
              [
                -49.56124951511478,
                -23.684994581135147
              ],
              [
                -49.56090301911328,
                -23.690505066427026
              ],
              [
                -49.5608340067463,
                -23.691597043728343
              ],
              [
                -49.56004502768838,
                -23.69141957205688
              ],
              [
                -49.55677652344546,
                -23.690685049130327
              ],
              [
                -49.554759046382046,
                -23.700768004544152
              ],
              [
                -49.54935753473079,
                -23.7032269899955
              ],
              [
                -49.55404695679732,
                -23.72088990770576
              ],
              [
                -49.554140433205085,
                -23.722794394169025
              ],
              [
                -49.548605467881714,
                -23.736026846968905
              ],
              [
                -49.551870427720374,
                -23.737710344009837
              ],
              [
                -49.56032887869027,
                -23.733374876588766
              ],
              [
                -49.56355683783516,
                -23.731720914216623
              ],
              [
                -49.5669342990187,
                -23.740009383502226
              ],
              [
                -49.5634607919584,
                -23.749407836354532
              ],
              [
                -49.571502700993065,
                -23.75459433162005
              ],
              [
                -49.56018277287083,
                -23.760875247325174
              ],
              [
                -49.56333423148263,
                -23.763703767345742
              ],
              [
                -49.56601017902323,
                -23.77731571772731
              ],
              [
                -49.56957414863257,
                -23.778118233323525
              ],
              [
                -49.56533366275157,
                -23.780277691525715
              ],
              [
                -49.560473674970915,
                -23.790436650064425
              ],
              [
                -49.561084665737745,
                -23.793581125052174
              ],
              [
                -49.56261013417312,
                -23.801439118536997
              ],
              [
                -49.5604451446959,
                -23.808582055677462
              ],
              [
                -49.55958010631033,
                -23.81143505435476
              ],
              [
                -49.561876073954856,
                -23.81807501420243
              ],
              [
                -49.575252960289895,
                -23.826559537422327
              ],
              [
                -49.577935408735584,
                -23.826587551516845
              ],
              [
                -49.586671859541795,
                -23.826679047881502
              ],
              [
                -49.59246280903913,
                -23.829775088955113
              ],
              [
                -49.59213275939808,
                -23.83565604491517
              ],
              [
                -49.59208676276926,
                -23.836484038391532
              ],
              [
                -49.586576309374024,
                -23.841583986680753
              ],
              [
                -49.5952587261796,
                -23.837652060333912
              ],
              [
                -49.59808221723448,
                -23.836373048205594
              ],
              [
                -49.60754812346986,
                -23.838884050944614
              ],
              [
                -49.60298867316312,
                -23.841578553974646
              ],
              [
                -49.60057616828743,
                -23.843004521761156
              ],
              [
                -49.59499270897895,
                -23.84630498417824
              ],
              [
                -49.598084695194125,
                -23.84880699643519
              ],
              [
                -49.59993465986607,
                -23.848678997626333
              ],
              [
                -49.60760561646389,
                -23.848147053304213
              ],
              [
                -49.610245056310156,
                -23.851198040940915
              ],
              [
                -49.59922613785813,
                -23.856593485146742
              ],
              [
                -49.60197557329495,
                -23.867787931895638
              ],
              [
                -49.59745610621422,
                -23.870606917535476
              ],
              [
                -49.597452597231474,
                -23.870609895512573
              ],
              [
                -49.59293864611858,
                -23.873426387178657
              ],
              [
                -49.591754157819175,
                -23.87584537167697
              ],
              [
                -49.58557166144654,
                -23.8884767911862
              ],
              [
                -49.58171720865311,
                -23.885067796266647
              ],
              [
                -49.57311128711,
                -23.877457826926335
              ],
              [
                -49.57569471506535,
                -23.890792773435884
              ],
              [
                -49.576643198714955,
                -23.89568623338564
              ],
              [
                -49.57052571962787,
                -23.898727720207894
              ],
              [
                -49.568701739932436,
                -23.89963472291107
              ],
              [
                -49.56488127534116,
                -23.907392681544057
              ],
              [
                -49.5626902397151,
                -23.911843142669
              ],
              [
                -49.561781745868586,
                -23.912151161624
              ],
              [
                -49.553973845744004,
                -23.914802613842216
              ],
              [
                -49.55605929476184,
                -23.920936604230878
              ],
              [
                -49.55650126650464,
                -23.922235100293783
              ],
              [
                -49.55518280588826,
                -23.922282571051564
              ],
              [
                -49.54594437865556,
                -23.922614546331417
              ],
              [
                -49.53539441729255,
                -23.92780349777629
              ],
              [
                -49.531522473490575,
                -23.928578488727155
              ],
              [
                -49.53019998810593,
                -23.921966507642598
              ],
              [
                -49.522519071331395,
                -23.916542527207344
              ],
              [
                -49.513836612423304,
                -23.92328097805847
              ],
              [
                -49.511140157431726,
                -23.925372955244146
              ],
              [
                -49.513889082693694,
                -23.930523932656996
              ],
              [
                -49.50755664762897,
                -23.933223913200152
              ],
              [
                -49.511723065821144,
                -23.93719191779779
              ],
              [
                -49.517401039589,
                -23.936017937020818
              ],
              [
                -49.52397800783728,
                -23.9346574607653
              ],
              [
                -49.52061148200574,
                -23.945606369770086
              ],
              [
                -49.5141900478896,
                -23.94411389713397
              ],
              [
                -49.51338703700293,
                -23.943927368428668
              ],
              [
                -49.50290161377575,
                -23.94812581709951
              ],
              [
                -49.50497958951778,
                -23.954340829129972
              ],
              [
                -49.49942909276839,
                -23.95880627586574
              ],
              [
                -49.50721203781576,
                -23.96170928594112
              ],
              [
                -49.51324399727838,
                -23.96395928930618
              ],
              [
                -49.51570543554959,
                -23.96945328648106
              ],
              [
                -49.510276993752264,
                -23.971460741611487
              ],
              [
                -49.5038610641863,
                -23.968438761207075
              ],
              [
                -49.50305856699211,
                -23.96806172427761
              ],
              [
                -49.49320109410632,
                -23.977104672112336
              ],
              [
                -49.49535803781452,
                -23.985340651174987
              ],
              [
                -49.497452527870415,
                -23.99334012602343
              ],
              [
                -49.49200105178241,
                -23.993012629588524
              ],
              [
                -49.49168907135385,
                -23.992993605706417
              ],
              [
                -49.48590409946784,
                -23.999931064645416
              ],
              [
                -49.485829080649545,
                -24.006080558363326
              ],
              [
                -49.48008310722805,
                -24.005673546341
              ],
              [
                -49.48301356172859,
                -24.011429513912294
              ],
              [
                -49.48433706851165,
                -24.011868003199837
              ],
              [
                -49.49090151692959,
                -24.014040011561267
              ],
              [
                -49.485665527286706,
                -24.0161355039108
              ],
              [
                -49.4896654788065,
                -24.01816148597642
              ],
              [
                -49.48666598218146,
                -24.02410397655015
              ],
              [
                -49.48105652434522,
                -24.02351246368553
              ],
              [
                -49.4792060663745,
                -24.019351974928774
              ],
              [
                -49.4790291032502,
                -24.018952957008835
              ],
              [
                -49.473892606238806,
                -24.01956297071311
              ],
              [
                -49.47337261530788,
                -24.025620415444603
              ],
              [
                -49.47326958675863,
                -24.02681942724523
              ],
              [
                -49.47224512493594,
                -24.026831427042126
              ],
              [
                -49.462954695102724,
                -24.026939405999837
              ],
              [
                -49.46243767018107,
                -24.037369333935068
              ],
              [
                -49.46243364893396,
                -24.03744585261115
              ],
              [
                -49.45295566736427,
                -24.052684747379352
              ],
              [
                -49.45036068066528,
                -24.05502075787557
              ],
              [
                -49.44807072395444,
                -24.055595218481567
              ],
              [
                -49.43735078621906,
                -24.05828468450457
              ],
              [
                -49.43849524459471,
                -24.068252665455674
              ],
              [
                -49.42929729463157,
                -24.06821162027551
              ],
              [
                -49.43447675652643,
                -24.07436463619589
              ],
              [
                -49.43472275048246,
                -24.074656634765976
              ],
              [
                -49.43403328484912,
                -24.074977121336364
              ],
              [
                -49.428523307742424,
                -24.0775400859207
              ],
              [
                -49.42893630104956,
                -24.080650063538293
              ],
              [
                -49.429772256323005,
                -24.086939567526546
              ],
              [
                -49.41720984103325,
                -24.091103985842814
              ],
              [
                -49.40439294750552,
                -24.095352956028574
              ],
              [
                -49.40732889629968,
                -24.09743095562596
              ],
              [
                -49.40535389865856,
                -24.101397415394956
              ],
              [
                -49.40450991223696,
                -24.103092916893033
              ],
              [
                -49.408572370362194,
                -24.107794896297204
              ],
              [
                -49.393091452452346,
                -24.114452843449264
              ],
              [
                -49.38883796346882,
                -24.118873318341244
              ],
              [
                -49.38665602270109,
                -24.115683308588142
              ],
              [
                -49.38170106400024,
                -24.116653802352754
              ],
              [
                -49.37752058575598,
                -24.117164762376976
              ],
              [
                -49.3770075706539,
                -24.11722726554006
              ],
              [
                -49.37035762838235,
                -24.11388626862864
              ],
              [
                -49.36832065351104,
                -24.11614224916188
              ],
              [
                -49.362340702590124,
                -24.119156230447572
              ],
              [
                -49.361611684466986,
                -24.12679919408511
              ],
              [
                -49.341022790748006,
                -24.137837113630184
              ],
              [
                -49.336789341194226,
                -24.13719210119525
              ],
              [
                -49.336306801626755,
                -24.144411549313812
              ],
              [
                -49.33197233543152,
                -24.149622008727082
              ],
              [
                -49.33792027301137,
                -24.15494752141328
              ],
              [
                -49.339857237054034,
                -24.15668199733617
              ],
              [
                -49.33936869805946,
                -24.174377422854878
              ],
              [
                -49.3456900789494,
                -24.191040882666123
              ],
              [
                -49.34462857275231,
                -24.193692860609715
              ],
              [
                -49.34105508770202,
                -24.202624310277763
              ],
              [
                -49.34774801062192,
                -24.2067503142272
              ],
              [
                -49.355377435872775,
                -24.211454347740496
              ],
              [
                -49.35018846209868,
                -24.220303259767558
              ],
              [
                -49.323359112686326,
                -24.235941646238636
              ],
              [
                -49.321609635032054,
                -24.236961137683913
              ],
              [
                -49.32082564393776,
                -24.238974137086142
              ],
              [
                -49.319136120589214,
                -24.24330910326097
              ],
              [
                -49.317294621834186,
                -24.24320010500064
              ],
              [
                -49.312214186898174,
                -24.242898595492456
              ],
              [
                -49.304885225384396,
                -24.25093450517917
              ],
              [
                -49.307481701467665,
                -24.256628521113758
              ],
              [
                -49.30818517011244,
                -24.25817050803643
              ],
              [
                -49.30655817376473,
                -24.258297005482724
              ],
              [
                -49.30213872713495,
                -24.25864048139816
              ],
              [
                -49.29822119985471,
                -24.267557945077435
              ],
              [
                -49.29936367687531,
                -24.2712909194738
              ],
              [
                -49.30074467708984,
                -24.275804414447087
              ],
              [
                -49.294714662081255,
                -24.284923354772953
              ],
              [
                -49.298172631345025,
                -24.28731936678026
              ],
              [
                -49.28827772942891,
                -24.291312323906904
              ],
              [
                -49.288255216577376,
                -24.291321302800718
              ],
              [
                -49.28826219944901,
                -24.29134081474325
              ],
              [
                -49.289514705139005,
                -24.294917786124635
              ],
              [
                -49.28296369638168,
                -24.307540243636627
              ],
              [
                -49.26911130577227,
                -24.306838196510057
              ],
              [
                -49.25398640247209,
                -24.317776616237666
              ],
              [
                -49.249949957038744,
                -24.31886660029554
              ],
              [
                -49.24838790645489,
                -24.330015042246135
              ],
              [
                -49.23072605655882,
                -24.329894977928937
              ],
              [
                -49.22379758518906,
                -24.33972892054713
              ],
              [
                -49.21538613858107,
                -24.338803432200102
              ],
              [
                -49.205379235528,
                -24.337701904623824
              ],
              [
                -49.20027926757264,
                -24.34397384235235
              ],
              [
                -49.224428005553705,
                -24.364743838106623
              ],
              [
                -49.24091228362084,
                -24.384644283758735
              ],
              [
                -49.2518786272335,
                -24.40345523479103
              ],
              [
                -49.253376606025874,
                -24.40725476409513
              ],
              [
                -49.249744620693,
                -24.413363716097944
              ],
              [
                -49.24260619784038,
                -24.401925223120987
              ],
              [
                -49.23727623044523,
                -24.40420820030314
              ],
              [
                -49.2361722331016,
                -24.407244209589837
              ],
              [
                -49.23532773930951,
                -24.40956618757205
              ],
              [
                -49.234972750556246,
                -24.410542180520675
              ],
              [
                -49.2355227528176,
                -24.411698673556316
              ],
              [
                -49.23897670494301,
                -24.41895863195949
              ],
              [
                -49.24019265461929,
                -24.421515125413325
              ],
              [
                -49.252155053004,
                -24.43360562183359
              ],
              [
                -49.25002949110529,
                -24.44492109352394
              ],
              [
                -49.25182146291284,
                -24.451024560476295
              ],
              [
                -49.2530444265233,
                -24.455190524479466
              ],
              [
                -49.24923095259332,
                -24.456468528430122
              ],
              [
                -49.24742097196987,
                -24.457074521340324
              ],
              [
                -49.24484601457489,
                -24.45793752482776
              ],
              [
                -49.24483447882838,
                -24.46460996886924
              ],
              [
                -49.247979947817996,
                -24.464228505220053
              ],
              [
                -49.246692976753955,
                -24.46865096936565
              ],
              [
                -49.25906434915187,
                -24.472566970178523
              ],
              [
                -49.263360794354675,
                -24.473926978106284
              ],
              [
                -49.270692723502414,
                -24.482937980685996
              ],
              [
                -49.27021971467304,
                -24.48552648378119
              ],
              [
                -49.26907817059805,
                -24.4917739380516
              ],
              [
                -49.27968360693752,
                -24.495509949143436
              ],
              [
                -49.295013445898746,
                -24.500909464328693
              ],
              [
                -49.29079895133071,
                -24.507235450703455
              ],
              [
                -49.28658448476225,
                -24.513560911080916
              ],
              [
                -49.28743994610023,
                -24.526336849382755
              ],
              [
                -49.28087295538736,
                -24.536712282124952
              ],
              [
                -49.29177182974889,
                -24.543803787749066
              ],
              [
                -49.2920713127946,
                -24.5524947787804
              ],
              [
                -49.300478750811294,
                -24.548227823249164
              ],
              [
                -49.30508473633661,
                -24.54589034206185
              ],
              [
                -49.30929667300694,
                -24.552911309071913
              ],
              [
                -49.31549912303844,
                -24.55500583510228
              ],
              [
                -49.31410160640914,
                -24.561924274964294
              ],
              [
                -49.317190570646254,
                -24.566139767738775
              ],
              [
                -49.31270256660231,
                -24.57582524182406
              ],
              [
                -49.310556061580854,
                -24.580456683521433
              ],
              [
                -49.31186203784407,
                -24.585569196122478
              ],
              [
                -49.311936014120505,
                -24.585602671220386
              ],
              [
                -49.316047997807836,
                -24.587488208564587
              ],
              [
                -49.31154351951261,
                -24.59060717693019
              ],
              [
                -49.30327705785853,
                -24.596330627051096
              ],
              [
                -49.3105659929143,
                -24.602460121367926
              ],
              [
                -49.31263893860751,
                -24.60420361389156
              ],
              [
                -49.31418589318354,
                -24.613763580463267
              ],
              [
                -49.308907935349,
                -24.618954028102912
              ],
              [
                -49.306585962540915,
                -24.621237529666278
              ],
              [
                -49.3083514140222,
                -24.624494024175185
              ],
              [
                -49.31593882301295,
                -24.638492501004077
              ],
              [
                -49.30658336814947,
                -24.64208492122089
              ],
              [
                -49.3058868807757,
                -24.642352434961072
              ],
              [
                -49.30650239577084,
                -24.64289096074162
              ],
              [
                -49.30928184314015,
                -24.64532243375387
              ],
              [
                -49.30609884393022,
                -24.646903912419603
              ],
              [
                -49.3011668865973,
                -24.649354411109346
              ],
              [
                -49.299134906825614,
                -24.654456372311444
              ],
              [
                -49.30357434410593,
                -24.65759185873447
              ],
              [
                -49.31140724190837,
                -24.663123857260306
              ],
              [
                -49.30468778435573,
                -24.668406309002027
              ],
              [
                -49.3048242811761,
                -24.67214081750562
              ],
              [
                -49.293948350482324,
                -24.6741802701263
              ],
              [
                -49.27545194477987,
                -24.693117635211653
              ],
              [
                -49.26233704948592,
                -24.691387604845854
              ],
              [
                -49.25627962488029,
                -24.695274597237905
              ],
              [
                -49.240231205633435,
                -24.697988005878813
              ],
              [
                -49.20842096851701,
                -24.700343406372696
              ],
              [
                -49.20610950677023,
                -24.697817924215
              ],
              [
                -49.20437153901126,
                -24.695919432352238
              ],
              [
                -49.20440254472632,
                -24.693804947918398
              ],
              [
                -49.204522538173514,
                -24.685462973712358
              ],
              [
                -49.19419464602437,
                -24.682770961039346
              ],
              [
                -49.185163270007,
                -24.67220296603694
              ],
              [
                -49.17435534077363,
                -24.671634458375166
              ],
              [
                -49.1703548827482,
                -24.67441691764925
              ],
              [
                -49.16935289225207,
                -24.675113937706083
              ],
              [
                -49.16328094001813,
                -24.673379413599946
              ],
              [
                -49.156853000058405,
                -24.671542877790674
              ],
              [
                -49.13459117160161,
                -24.675017335963894
              ],
              [
                -49.12876667268038,
                -24.680653260047805
              ],
              [
                -49.124542208491995,
                -24.682375239872222
              ],
              [
                -49.111110297944144,
                -24.687849194725285
              ],
              [
                -49.10954783575252,
                -24.680900209741687
              ],
              [
                -49.10845737281839,
                -24.676053750797642
              ],
              [
                -49.10158343367338,
                -24.675242733494812
              ],
              [
                -49.097836447778725,
                -24.679852201210615
              ],
              [
                -49.09664497394696,
                -24.681317663581815
              ],
              [
                -49.08716401952858,
                -24.67983066182073
              ],
              [
                -49.08677552132109,
                -24.679128661425253
              ],
              [
                -49.08269212121434,
                -24.67174567431632
              ],
              [
                -49.075337171232356,
                -24.672736631270887
              ],
              [
                -49.07245118019354,
                -24.678191111276803
              ],
              [
                -49.0696616470482,
                -24.683464596314437
              ],
              [
                -49.055321814512446,
                -24.68214706265397
              ],
              [
                -49.05083534277888,
                -24.67900257924745
              ],
              [
                -49.04502240832365,
                -24.66559807901318
              ],
              [
                -49.036911506540505,
                -24.65991908549273
              ],
              [
                -49.03902253583253,
                -24.65286412653781
              ],
              [
                -49.03408205140404,
                -24.64955012643297
              ],
              [
                -49.03396106455142,
                -24.648972605382905
              ],
              [
                -49.032388109552414,
                -24.641440653835282
              ],
              [
                -49.03935605155694,
                -24.643408189704655
              ],
              [
                -49.04176406461436,
                -24.64003819782954
              ],
              [
                -49.03293013513807,
                -24.633966177682748
              ],
              [
                -49.01788122693129,
                -24.64211709272057
              ],
              [
                -49.004250385421166,
                -24.633923127166845
              ],
              [
                -48.999845394795265,
                -24.63617907328505
              ],
              [
                -49.00491884135266,
                -24.638634575234427
              ],
              [
                -49.008290780108126,
                -24.653275556693544
              ],
              [
                -49.00931376014834,
                -24.65771703597715
              ],
              [
                -48.99637784468552,
                -24.655758007402987
              ],
              [
                -48.99498886796533,
                -24.656029479961802
              ],
              [
                -48.98963491958871,
                -24.65707696798413
              ],
              [
                -48.98907489438764,
                -24.65914747787534
              ],
              [
                -48.98755441469718,
                -24.66476791743581
              ],
              [
                -48.977590989127776,
                -24.66520240950656
              ],
              [
                -48.97637449582721,
                -24.665255378486695
              ],
              [
                -48.97614198705494,
                -24.665966406889122
              ],
              [
                -48.9744399654125,
                -24.67116785456942
              ],
              [
                -48.96352207082101,
                -24.672619840545448
              ],
              [
                -48.96280409430668,
                -24.6727153309135
              ],
              [
                -48.96266007213366,
                -24.673074308013675
              ],
              [
                -48.95796505246649,
                -24.684781276066527
              ],
              [
                -48.948084213530294,
                -24.676077259285634
              ],
              [
                -48.9450532352086,
                -24.673407768585268
              ],
              [
                -48.932251314797774,
                -24.674115228687054
              ],
              [
                -48.92194288561855,
                -24.67468468915009
              ],
              [
                -48.91477445407325,
                -24.682410173511265
              ],
              [
                -48.91075500552761,
                -24.678483140814578
              ],
              [
                -48.911967995376166,
                -24.67294718898703
              ],
              [
                -48.90524303593221,
                -24.67087166735852
              ],
              [
                -48.901610581722856,
                -24.66975066253966
              ],
              [
                -48.87828929073098,
                -24.667890623199643
              ],
              [
                -48.86490788080274,
                -24.666823571725853
              ],
              [
                -48.85097050611097,
                -24.674132013603085
              ],
              [
                -48.8483350108172,
                -24.671935994267756
              ],
              [
                -48.85284300570479,
                -24.665076556130217
              ],
              [
                -48.85801997794607,
                -24.657197602212413
              ],
              [
                -48.852823530063716,
                -24.65565906522817
              ],
              [
                -48.84339009591,
                -24.662102037675073
              ],
              [
                -48.839915113379746,
                -24.664475507209932
              ],
              [
                -48.83268219414302,
                -24.658650022252925
              ],
              [
                -48.82827124439307,
                -24.655097538897127
              ],
              [
                -48.824286779736,
                -24.655943524036804
              ],
              [
                -48.82030330207543,
                -24.656788975181865
              ],
              [
                -48.818689317722985,
                -24.659605496722975
              ],
              [
                -48.810917330466324,
                -24.673167388473505
              ],
              [
                -48.799243433317564,
                -24.674886850072294
              ],
              [
                -48.78772447667754,
                -24.68068630760559
              ],
              [
                -48.77816451662645,
                -24.696229703725653
              ],
              [
                -48.76857507039339,
                -24.694773699700104
              ],
              [
                -48.76519113760395,
                -24.690341687122125
              ],
              [
                -48.756800228208505,
                -24.67935269255352
              ],
              [
                -48.75260375704903,
                -24.68188018320403
              ],
              [
                -48.753066246900225,
                -24.689205661905337
              ],
              [
                -48.7533472289371,
                -24.693665149680655
              ],
              [
                -48.74410730538232,
                -24.692609134142057
              ],
              [
                -48.73654838939349,
                -24.686121120914713
              ],
              [
                -48.73405443376969,
                -24.683980616833615
              ],
              [
                -48.73184092414645,
                -24.68524261080557
              ],
              [
                -48.72962695852567,
                -24.686505124773852
              ],
              [
                -48.726800947200815,
                -24.68581509476662
              ],
              [
                -48.70327019601692,
                -24.68006905378695
              ],
              [
                -48.693295216930046,
                -24.68962200185858
              ],
              [
                -48.68988024165058,
                -24.692892467917588
              ],
              [
                -48.68950877829352,
                -24.690051980310944
              ],
              [
                -48.68779732412622,
                -24.676965527212957
              ],
              [
                -48.693603783950465,
                -24.67018008090583
              ],
              [
                -48.68777983796306,
                -24.668735547650634
              ],
              [
                -48.676499412387436,
                -24.671105519884144
              ],
              [
                -48.670990930761725,
                -24.678798467416925
              ],
              [
                -48.67446538661155,
                -24.683550452895844
              ],
              [
                -48.67162742667921,
                -24.686806940804743
              ],
              [
                -48.676145857933776,
                -24.6915709524966
              ],
              [
                -48.67657587672495,
                -24.69202393799361
              ],
              [
                -48.67102535820342,
                -24.698167409708468
              ],
              [
                -48.67671033966042,
                -24.69790739848129
              ],
              [
                -48.67447633422575,
                -24.701311873673273
              ],
              [
                -48.66084793441352,
                -24.70595384532038
              ],
              [
                -48.65769350496768,
                -24.691557903070485
              ],
              [
                -48.6411606832138,
                -24.689164827332036
              ],
              [
                -48.643556672435345,
                -24.683846361452183
              ],
              [
                -48.64482963255481,
                -24.681019901927417
              ],
              [
                -48.63307076904274,
                -24.676932895985807
              ],
              [
                -48.63017479058123,
                -24.678588349228388
              ],
              [
                -48.62769178862289,
                -24.680006860721416
              ],
              [
                -48.622754351894,
                -24.67494937197611
              ],
              [
                -48.61613695232509,
                -24.668170869036743
              ],
              [
                -48.61284646402756,
                -24.669578366064222
              ],
              [
                -48.61159647163775,
                -24.669477860587403
              ],
              [
                -48.608976992872606,
                -24.669267363301266
              ],
              [
                -48.60172555443258,
                -24.668683838137284
              ],
              [
                -48.59546259630879,
                -24.674168792286338
              ],
              [
                -48.58877063730701,
                -24.680028747569978
              ],
              [
                -48.586578628090265,
                -24.677390732518912
              ],
              [
                -48.581366222148766,
                -24.671118238917916
              ],
              [
                -48.57887319578441,
                -24.683416704971258
              ],
              [
                -48.57854520444374,
                -24.68503519334693
              ],
              [
                -48.5731012070039,
                -24.690150165546687
              ],
              [
                -48.56782373078493,
                -24.695108626901863
              ],
              [
                -48.567994750664354,
                -24.696106606361777
              ],
              [
                -48.56871524109658,
                -24.700309102457364
              ],
              [
                -48.559630265684994,
                -24.71020450882797
              ],
              [
                -48.55498682132728,
                -24.70978354309858
              ],
              [
                -48.55161084415059,
                -24.70947749384478
              ],
              [
                -48.55127882879217,
                -24.712986016497588
              ],
              [
                -48.5509773153488,
                -24.716181491523837
              ],
              [
                -48.546650335845015,
                -24.716982951790943
              ],
              [
                -48.54411136291361,
                -24.71745344197176
              ],
              [
                -48.54382934689473,
                -24.719419471073557
              ],
              [
                -48.54307233740381,
                -24.7247009491737
              ],
              [
                -48.53813938369358,
                -24.72689190088479
              ],
              [
                -48.532038945630894,
                -24.729601382846827
              ],
              [
                -48.531657435128466,
                -24.72977088296806
              ],
              [
                -48.528706955448236,
                -24.73108084944185
              ],
              [
                -48.528746437827685,
                -24.731160360240313
              ],
              [
                -48.531298403840545,
                -24.73626533535996
              ],
              [
                -48.52836241754769,
                -24.73851133606015
              ],
              [
                -48.52200500268552,
                -24.735571325264566
              ],
              [
                -48.511019558084065,
                -24.740062263752243
              ],
              [
                -48.50995006533244,
                -24.739132279218595
              ],
              [
                -48.50455115717359,
                -24.734434272593074
              ],
              [
                -48.49882867913126,
                -24.73846023135514
              ],
              [
                -48.5034926404643,
                -24.739634267075257
              ],
              [
                -48.50185814277414,
                -24.7442192142775
              ],
              [
                -48.50721504824497,
                -24.751868691743375
              ],
              [
                -48.506055010592796,
                -24.763429156984955
              ],
              [
                -48.51100596284058,
                -24.763457661289973
              ],
              [
                -48.511602462932444,
                -24.76346067513581
              ],
              [
                -48.5162174428787,
                -24.7680551817853
              ],
              [
                -48.51624142941634,
                -24.771871644307
              ],
              [
                -48.51628988196436,
                -24.779642136798095
              ],
              [
                -48.529765766715656,
                -24.784621633533455
              ],
              [
                -48.53389970890379,
                -24.78726213867651
              ],
              [
                -48.538034147087465,
                -24.789902630825736
              ],
              [
                -48.55527047546781,
                -24.805700108362515
              ],
              [
                -48.558714911392904,
                -24.82058556462571
              ],
              [
                -48.55770292671755,
                -24.82138005823622
              ],
              [
                -48.5552728964993,
                -24.823289063888172
              ],
              [
                -48.5575938703122,
                -24.82492554449206
              ],
              [
                -48.56180184065047,
                -24.82789154459497
              ],
              [
                -48.56732974655212,
                -24.846996481321725
              ],
              [
                -48.56398974838092,
                -24.855667953690467
              ],
              [
                -48.56619320502961,
                -24.863452940008486
              ],
              [
                -48.556555741185974,
                -24.86556889826534
              ],
              [
                -48.552910786100924,
                -24.866369366611842
              ],
              [
                -48.54921231911147,
                -24.866995878496784
              ],
              [
                -48.546353822040444,
                -24.8732568391529
              ],
              [
                -48.548621781686776,
                -24.879299792743534
              ],
              [
                -48.5548492016438,
                -24.882730305332444
              ],
              [
                -48.55785919184485,
                -24.88408132928171
              ],
              [
                -48.558959139306246,
                -24.88873231887664
              ],
              [
                -48.55583065955597,
                -24.892567280536056
              ],
              [
                -48.55882362242975,
                -24.90067874304952
              ],
              [
                -48.562509614831434,
                -24.89545725875131
              ],
              [
                -48.56324009072979,
                -24.894422793230863
              ],
              [
                -48.57157603397012,
                -24.89450829000458
              ],
              [
                -48.57235052896708,
                -24.90237677156826
              ],
              [
                -48.56693956483853,
                -24.903319772788997
              ],
              [
                -48.56666105094442,
                -24.903367780721624
              ],
              [
                -48.565982497754334,
                -24.91630372122309
              ],
              [
                -48.569261467689806,
                -24.92058320518738
              ],
              [
                -48.56980640641647,
                -24.930601647364654
              ],
              [
                -48.56244794371408,
                -24.938850608907703
              ],
              [
                -48.56890586793731,
                -24.94328309025321
              ],
              [
                -48.56272843156397,
                -24.949932039977234
              ],
              [
                -48.568340861361506,
                -24.952606073790676
              ],
              [
                -48.572162835085315,
                -24.954428064430008
              ],
              [
                -48.570195815195774,
                -24.956557046648513
              ],
              [
                -48.567462861466495,
                -24.959514536113137
              ],
              [
                -48.56703580403161,
                -24.971665983678722
              ],
              [
                -48.577006180961426,
                -24.984131435688884
              ],
              [
                -48.58365563820252,
                -24.98522498518641
              ],
              [
                -48.587874102927145,
                -24.985918972653824
              ],
              [
                -48.588300563411856,
                -24.9898834590014
              ],
              [
                -48.58877755371863,
                -24.994312440637202
              ],
              [
                -48.59464549156911,
                -24.995620438826123
              ],
              [
                -48.59745446118574,
                -25.002827963608446
              ],
              [
                -48.598000396045144,
                -25.01855989793149
              ],
              [
                -48.58851597855199,
                -25.01919336250843
              ],
              [
                -48.588386983579554,
                -25.019201840068085
              ],
              [
                -48.588853435967806,
                -25.025733340221286
              ],
              [
                -48.589100403835026,
                -25.02918633108965
              ],
              [
                -48.58434493931214,
                -25.032241291802098
              ],
              [
                -48.5810609862914,
                -25.03435025196059
              ],
              [
                -48.58122145186409,
                -25.036749774800867
              ],
              [
                -48.58217842564817,
                -25.051061225189343
              ],
              [
                -48.57147851853295,
                -25.05081316445868
              ],
              [
                -48.568574023664155,
                -25.050745670575726
              ],
              [
                -48.565693013882004,
                -25.056133119799522
              ],
              [
                -48.559184051110655,
                -25.060156110081113
              ],
              [
                -48.55936049389623,
                -25.074826527577436
              ],
              [
                -48.55939252263889,
                -25.077454523102134
              ],
              [
                -48.55585850159227,
                -25.083793001447066
              ],
              [
                -48.554793022513365,
                -25.084539977079825
              ],
              [
                -48.54216909167862,
                -25.09339042563593
              ],
              [
                -48.53483316246318,
                -25.098533368792612
              ],
              [
                -48.52810119017188,
                -25.10029035347946
              ],
              [
                -48.521091768103155,
                -25.086029388784077
              ],
              [
                -48.52079778379816,
                -25.085430868267682
              ],
              [
                -48.51636235230512,
                -25.084377388525805
              ],
              [
                -48.51192739481027,
                -25.08332385978804
              ],
              [
                -48.50409049087964,
                -25.069488387853607
              ],
              [
                -48.4979235979715,
                -25.05860094734059
              ],
              [
                -48.491101180413295,
                -25.05272744256201
              ],
              [
                -48.49027267625013,
                -25.052722931972276
              ],
              [
                -48.482810231819236,
                -25.05268643163146
              ],
              [
                -48.4836802443285,
                -25.040113979134706
              ],
              [
                -48.473566419872014,
                -25.012048064763636
              ],
              [
                -48.43443731743642,
                -24.991044534930907
              ],
              [
                -48.42974688822345,
                -24.99047303353169
              ],
              [
                -48.417580973034774,
                -24.98898900138655
              ],
              [
                -48.4165514859892,
                -24.988863480666755
              ],
              [
                -48.41058858098415,
                -24.979624014439054
              ],
              [
                -48.394311163017086,
                -24.990510393250005
              ],
              [
                -48.37943725718382,
                -24.995392341813673
              ],
              [
                -48.37753526041178,
                -25.00133982771991
              ],
              [
                -48.377205773241236,
                -25.002369298510324
              ],
              [
                -48.37061231905739,
                -25.007280773910253
              ],
              [
                -48.354266422876364,
                -25.019456171254372
              ],
              [
                -48.35258839328307,
                -25.022747674638275
              ],
              [
                -48.350009913445604,
                -25.02780465103672
              ],
              [
                -48.35074539347757,
                -25.035979593190316
              ],
              [
                -48.338918978735755,
                -25.03553559288361
              ],
              [
                -48.33859849442391,
                -25.035524062924914
              ],
              [
                -48.33745347201223,
                -25.037603543897514
              ],
              [
                -48.33657499323016,
                -25.03919905366965
              ],
              [
                -48.33641249605133,
                -25.039072038674956
              ],
              [
                -48.3319885305597,
                -25.035633041746554
              ],
              [
                -48.323658596008734,
                -25.03624202814662
              ],
              [
                -48.323867065595245,
                -25.053881943240864
              ],
              [
                -48.325517508848534,
                -25.054442947146104
              ],
              [
                -48.333228466280225,
                -25.05706444472322
              ],
              [
                -48.33318842539416,
                -25.070066892900787
              ],
              [
                -48.3172925707041,
                -25.056041414848416
              ],
              [
                -48.3147201102112,
                -25.048301960171464
              ],
              [
                -48.313435173431046,
                -25.04443694582321
              ],
              [
                -48.300438319416905,
                -25.0307589725975
              ],
              [
                -48.29889486407069,
                -25.02389652063641
              ],
              [
                -48.29234241808698,
                -25.023919975008685
              ],
              [
                -48.28989242431528,
                -25.02736495833321
              ],
              [
                -48.28455044607061,
                -25.034877408068336
              ],
              [
                -48.27782749119876,
                -25.03260038625162
              ],
              [
                -48.27725205789419,
                -25.019366469244133
              ],
              [
                -48.277083540908414,
                -25.015489963479304
              ],
              [
                -48.26910369740646,
                -24.997028524609327
              ],
              [
                -48.26285122986671,
                -24.99229801930634
              ],
              [
                -48.259433758815355,
                -24.99715151685447
              ],
              [
                -48.254423288484915,
                -24.996015490806197
              ],
              [
                -48.2586063314512,
                -24.986582047307586
              ],
              [
                -48.251197410022684,
                -24.978246046105767
              ],
              [
                -48.24529540474145,
                -24.98888547430213
              ],
              [
                -48.24017844218411,
                -24.99149845365681
              ],
              [
                -48.23526148125409,
                -24.998553435537996
              ],
              [
                -48.23267399907572,
                -24.999499428592312
              ],
              [
                -48.23051198696936,
                -25.000289404612715
              ],
              [
                -48.22899199644035,
                -25.000844906596168
              ],
              [
                -48.22975047305389,
                -25.00220391743121
              ],
              [
                -48.23207897709767,
                -25.006375392720905
              ],
              [
                -48.227664010536124,
                -25.00745135952844
              ],
              [
                -48.22287702071213,
                -25.008617842803748
              ],
              [
                -48.22623198980998,
                -25.011051840382997
              ],
              [
                -48.23146793721381,
                -25.014851334290242
              ],
              [
                -48.231074414999085,
                -25.02028181094809
              ],
              [
                -48.220261477593716,
                -25.026063756567343
              ],
              [
                -48.21445955086857,
                -25.029166737774922
              ],
              [
                -48.2091260934302,
                -25.02824971381839
              ],
              [
                -48.20979605023398,
                -25.035849715968126
              ],
              [
                -48.21424501228743,
                -25.04119268314638
              ],
              [
                -48.215054973916395,
                -25.042166171720464
              ],
              [
                -48.211616936021684,
                -25.05867961876269
              ],
              [
                -48.21557487821803,
                -25.076166566061648
              ],
              [
                -48.21061138493875,
                -25.079166526305904
              ],
              [
                -48.20965938064113,
                -25.07974153398381
              ],
              [
                -48.209922391949476,
                -25.080407034103292
              ],
              [
                -48.21171485697595,
                -25.084941517294684
              ],
              [
                -48.19915689960846,
                -25.101934881856394
              ],
              [
                -48.19594644542396,
                -25.10556988101622
              ],
              [
                -48.194231940267215,
                -25.107511345749593
              ],
              [
                -48.19420440697221,
                -25.108706330808715
              ],
              [
                -48.19410639452123,
                -25.113002331050936
              ],
              [
                -48.18390895388292,
                -25.12542974357233
              ],
              [
                -48.18121296076079,
                -25.128715248766774
              ],
              [
                -48.181204475164215,
                -25.12941922388152
              ],
              [
                -48.18116092970704,
                -25.133330223864537
              ],
              [
                -48.17632447529409,
                -25.136619690322384
              ],
              [
                -48.16651252627516,
                -25.1432921424195
              ],
              [
                -48.156403617789316,
                -25.144144609215353
              ],
              [
                -48.16171702856769,
                -25.153694093127957
              ],
              [
                -48.16635948378307,
                -25.15536157894377
              ],
              [
                -48.16823599568122,
                -25.15603559110395
              ],
              [
                -48.167939472328136,
                -25.159412560468596
              ],
              [
                -48.16723845483635,
                -25.167398036866953
              ],
              [
                -48.17514185557304,
                -25.17787753021655
              ],
              [
                -48.17791231466824,
                -25.181551007038678
              ],
              [
                -48.19288065700253,
                -25.189359527494023
              ],
              [
                -48.186718185687745,
                -25.198397447494013
              ],
              [
                -48.177763736155796,
                -25.20621240665735
              ],
              [
                -48.16672030687592,
                -25.20826236358979
              ],
              [
                -48.16171485882734,
                -25.209191839068783
              ],
              [
                -48.15691335191001,
                -25.21726730624248
              ],
              [
                -48.150757352555814,
                -25.227618756935986
              ],
              [
                -48.114404105149326,
                -25.25019354901542
              ],
              [
                -48.107080178088886,
                -25.245949061144753
              ],
              [
                -48.10064724203394,
                -25.242387557323692
              ],
              [
                -48.09510028758409,
                -25.241465015601975
              ],
              [
                -48.09467879434889,
                -25.24139503955895
              ],
              [
                -48.08948485474217,
                -25.240533007706212
              ],
              [
                -48.08539785625278,
                -25.239854009597323
              ],
              [
                -48.08465440422198,
                -25.240432994909778
              ],
              [
                -48.081749397729695,
                -25.242695992590548
              ],
              [
                -48.07522145945609,
                -25.240454469139923
              ],
              [
                -48.065684548986745,
                -25.239839454630523
              ],
              [
                -48.06155908860672,
                -25.239481929103782
              ],
              [
                -48.05880461681845,
                -25.23571144274289
              ],
              [
                -48.05651313077206,
                -25.232574964268856
              ],
              [
                -48.05904563351719,
                -25.22894996494884
              ],
              [
                -48.057619693350524,
                -25.221319505450438
              ],
              [
                -48.05690417336408,
                -25.21787801417945
              ],
              [
                -48.05353372287872,
                -25.220635994384043
              ],
              [
                -48.04914025571766,
                -25.224229468979193
              ],
              [
                -48.04659573459265,
                -25.22614046321929
              ],
              [
                -48.04409827241351,
                -25.223224462215256
              ],
              [
                -48.041175810640496,
                -25.219813977888425
              ],
              [
                -48.03524536148431,
                -25.218870972087657
              ],
              [
                -48.02761640763892,
                -25.221300919243816
              ],
              [
                -48.02946438160615,
                -25.225693421197356
              ],
              [
                -48.02696192829294,
                -25.227564389730734
              ],
              [
                -48.02445692199414,
                -25.2294373682477
              ],
              [
                -48.02307993452627,
                -25.230466855737003
              ],
              [
                -48.030596333234534,
                -25.237003876800856
              ],
              [
                -48.03732276311088,
                -25.242853854191118
              ],
              [
                -48.053409589244644,
                -25.256845353014675
              ],
              [
                -48.07144185674186,
                -25.27252833520793
              ],
              [
                -48.08580072238048,
                -25.28514931233647
              ],
              [
                -48.09786506669035,
                -25.299172784631534
              ],
              [
                -48.09824554092008,
                -25.303523776233966
              ],
              [
                -48.09835801444087,
                -25.30654727936182
              ],
              [
                -48.09851952351851,
                -25.31088874231712
              ],
              [
                -48.09934250073185,
                -25.315849748858945
              ],
              [
                -48.13179511693967,
                -25.342865704278445
              ],
              [
                -48.14915790897725,
                -25.360310207859165
              ],
              [
                -48.15246290781397,
                -25.363630704939435
              ],
              [
                -48.156900840024804,
                -25.369355167928525
              ],
              [
                -48.18104799799882,
                -25.400507606087743
              ],
              [
                -48.19910475016712,
                -25.430134555380725
              ],
              [
                -48.20827709995558,
                -25.460253951725107
              ],
              [
                -48.212549536956445,
                -25.464022981206636
              ],
              [
                -48.216821976951046,
                -25.467792973691193
              ],
              [
                -48.239983305394844,
                -25.471001512535125
              ],
              [
                -48.248397222100884,
                -25.472167032650525
              ],
              [
                -48.25681063881953,
                -25.473332552770838
              ],
              [
                -48.27620742024257,
                -25.483353584478284
              ],
              [
                -48.286557337751375,
                -25.48870110309291
              ],
              [
                -48.29659924354948,
                -25.49050009191742
              ],
              [
                -48.30926112182893,
                -25.49276861523294
              ],
              [
                -48.30148715721279,
                -25.497687605808018
              ],
              [
                -48.293371728601976,
                -25.502822030432277
              ],
              [
                -48.28355480493773,
                -25.50903348132855
              ],
              [
                -48.27642931556304,
                -25.513541961604673
              ],
              [
                -48.283650692021176,
                -25.531514379932098
              ],
              [
                -48.28742067035893,
                -25.540897855551304
              ],
              [
                -48.28941609553767,
                -25.545864365126835
              ],
              [
                -48.293455566962386,
                -25.548202371712392
              ],
              [
                -48.29526155492865,
                -25.54924737431789
              ],
              [
                -48.30031399064874,
                -25.559130827092645
              ],
              [
                -48.30017646501784,
                -25.559586827837148
              ],
              [
                -48.297937960786705,
                -25.56699829316981
              ],
              [
                -48.30282694105733,
                -25.565758823734598
              ],
              [
                -48.312979779684916,
                -25.576094321070418
              ],
              [
                -48.319724237459184,
                -25.58336978071143
              ],
              [
                -48.322230193777834,
                -25.584886789699127
              ],
              [
                -48.358041391800526,
                -25.585436411162405
              ],
              [
                -48.367230791683895,
                -25.589537909309374
              ],
              [
                -48.37663321943747,
                -25.593733934779877
              ],
              [
                -48.3773101986523,
                -25.594035917750936
              ],
              [
                -48.38032370010721,
                -25.595467916726413
              ],
              [
                -48.41439227864434,
                -25.62347940697695
              ],
              [
                -48.43070159751102,
                -25.643969357280373
              ],
              [
                -48.43469451772275,
                -25.64901235517352
              ],
              [
                -48.434696527694236,
                -25.649015388167967
              ],
              [
                -48.438687971922256,
                -25.654055843075845
              ],
              [
                -48.45548573216058,
                -25.680526290581312
              ],
              [
                -48.458539218965655,
                -25.685350283378448
              ],
              [
                -48.461593681760995,
                -25.690173292189986
              ],
              [
                -48.47419900725606,
                -25.710082242329477
              ],
              [
                -48.47631794356873,
                -25.713428710978413
              ],
              [
                -48.47634593816206,
                -25.71347323089333
              ],
              [
                -48.478212926683405,
                -25.716787714859468
              ],
              [
                -48.52431377170406,
                -25.798612040291545
              ],
              [
                -48.525869735284225,
                -25.80153353887563
              ],
              [
                -48.52715018544099,
                -25.805898020915105
              ],
              [
                -48.529858123552806,
                -25.815125488530263
              ],
              [
                -48.53710749661988,
                -25.84012740814162
              ],
              [
                -48.53467597551886,
                -25.84624638231778
              ],
              [
                -48.53467047665984,
                -25.849504355555503
              ],
              [
                -48.53865197497242,
                -25.8486773767034
              ],
              [
                -48.541432932345366,
                -25.847315380050002
              ],
              [
                -48.54392389023207,
                -25.848701900707074
              ],
              [
                -48.54904936050623,
                -25.851553415184465
              ],
              [
                -48.554235794451515,
                -25.853351408983613
              ],
              [
                -48.55485082052671,
                -25.85356190415651
              ],
              [
                -48.5564367662519,
                -25.85410392418441
              ],
              [
                -48.56352369898763,
                -25.85652842770987
              ],
              [
                -48.564310713591794,
                -25.862046892705848
              ],
              [
                -48.56224918165094,
                -25.870618885533084
              ],
              [
                -48.56372366281669,
                -25.87405384590861
              ],
              [
                -48.56703958254622,
                -25.885345334605116
              ],
              [
                -48.563309116772004,
                -25.887776293997092
              ],
              [
                -48.56328362391748,
                -25.88779279984985
              ],
              [
                -48.559175108625666,
                -25.890387259374926
              ],
              [
                -48.5679515086463,
                -25.906071745720574
              ],
              [
                -48.575429402783165,
                -25.91938718814419
              ],
              [
                -48.575909861399694,
                -25.920243209372316
              ],
              [
                -48.57697236003601,
                -25.923150182504653
              ],
              [
                -48.578034861672,
                -25.926056710640836
              ],
              [
                -48.58957663428429,
                -25.957641114305765
              ],
              [
                -48.59426904195829,
                -25.97054458243826
              ],
              [
                -48.59041657734112,
                -25.97643601698029
              ],
              [
                -48.59394752250978,
                -25.97658504491669
              ],
              [
                -48.597390004624216,
                -25.976681065771402
              ],
              [
                -48.599356956121916,
                -25.976735551975267
              ],
              [
                -48.60119344696407,
                -25.9802300713914
              ],
              [
                -48.61054187943717,
                -25.979012561618713
              ],
              [
                -48.6097508692559,
                -25.98370154883074
              ],
              [
                -48.61322137065359,
                -25.980666072940913
              ],
              [
                -48.61522881401499,
                -25.980922087497355
              ],
              [
                -48.620161313671325,
                -25.981552604146014
              ],
              [
                -48.62020682002877,
                -25.977864127613216
              ],
              [
                -48.620254316956014,
                -25.97402813866162
              ],
              [
                -48.62272577036133,
                -25.97631710883859
              ],
              [
                -48.62337030364882,
                -25.976912622629897
              ],
              [
                -48.633035686488896,
                -25.976957638997376
              ],
              [
                -48.63255271715627,
                -25.97028018833951
              ],
              [
                -48.632521214154046,
                -25.969845182925397
              ],
              [
                -48.63771471739369,
                -25.96703871376551
              ],
              [
                -48.63845722494652,
                -25.963608742503478
              ],
              [
                -48.6429206882811,
                -25.955778786464652
              ],
              [
                -48.653396585969325,
                -25.962208298683557
              ],
              [
                -48.6617824791097,
                -25.968094269216216
              ],
              [
                -48.66270395548328,
                -25.973601739864105
              ],
              [
                -48.66468995654134,
                -25.97523276702405
              ],
              [
                -48.66900187573413,
                -25.97877275438077
              ],
              [
                -48.67209635426038,
                -25.977993777506946
              ],
              [
                -48.67565883138874,
                -25.977097264617306
              ],
              [
                -48.680178806171284,
                -25.980560290961048
              ],
              [
                -48.70613858992267,
                -25.982477334363853
              ],
              [
                -48.71045255000056,
                -25.982795358233563
              ],
              [
                -48.88442808773755,
                -25.980753374652387
              ],
              [
                -48.96027699626379,
                -25.9792676191943
              ],
              [
                -48.95344902405298,
                -25.986332075635865
              ],
              [
                -48.95022653216764,
                -25.989666575244627
              ],
              [
                -48.95649694317072,
                -25.99299905803315
              ],
              [
                -48.961091426199054,
                -25.99055358451372
              ],
              [
                -48.96131143385935,
                -25.990436582685557
              ],
              [
                -48.96139992077036,
                -25.987541586095162
              ],
              [
                -48.961517963210625,
                -25.983707624210034
              ],
              [
                -48.96770340049814,
                -25.98640062117646
              ],
              [
                -48.97801777995648,
                -25.98881214679279
              ],
              [
                -48.97990472703917,
                -25.997363116172522
              ],
              [
                -48.985976712544776,
                -26.000233616112848
              ],
              [
                -48.98399617140427,
                -26.003570608802534
              ],
              [
                -48.98371420281171,
                -26.004045592053707
              ],
              [
                -48.98467917483823,
                -26.00405459319094
              ],
              [
                -48.98841565795833,
                -26.00409111633279
              ],
              [
                -48.9962835524605,
                -26.0102476135936
              ],
              [
                -48.997662557771726,
                -26.011327124990203
              ],
              [
                -49.001196527898514,
                -26.0107836256952
              ],
              [
                -49.002702027452806,
                -26.01055162253552
              ],
              [
                -49.00307299764402,
                -26.01098813508255
              ],
              [
                -49.006638463427244,
                -26.01518262373626
              ],
              [
                -49.02006285338379,
                -26.015596646314794
              ],
              [
                -49.0219758499277,
                -26.013048180369488
              ],
              [
                -49.02918130937294,
                -26.00344972083914
              ],
              [
                -49.04044772445051,
                -26.003532773584226
              ],
              [
                -49.04413965461909,
                -26.01333224721721
              ],
              [
                -49.046138607803286,
                -26.018637206495693
              ],
              [
                -49.051332073465666,
                -26.01681775355504
              ],
              [
                -49.0547380484518,
                -26.02004826441049
              ],
              [
                -49.06821047238129,
                -26.008987833077104
              ],
              [
                -49.06905699538046,
                -26.005527325101955
              ],
              [
                -49.07059999415627,
                -25.999213888336392
              ],
              [
                -49.075433443354434,
                -26.004698371264382
              ],
              [
                -49.076445420413286,
                -26.00584687020316
              ],
              [
                -49.081626883390335,
                -26.004724379555746
              ],
              [
                -49.09044682867434,
                -26.002814441900195
              ],
              [
                -49.0914507845624,
                -26.00476592474495
              ],
              [
                -49.093843261157055,
                -26.009417909841325
              ],
              [
                -49.099180741425556,
                -26.008404938293694
              ],
              [
                -49.10183571182473,
                -26.00488644548568
              ],
              [
                -49.106570182276194,
                -25.998610493065737
              ],
              [
                -49.112409626503485,
                -26.004770992756978
              ],
              [
                -49.125071567018615,
                -25.99574355695698
              ],
              [
                -49.13120749530935,
                -25.99980153966972
              ],
              [
                -49.12912799979035,
                -26.003910548125408
              ],
              [
                -49.13207243225084,
                -26.006731029058418
              ],
              [
                -49.14076936027257,
                -26.01506101393124
              ],
              [
                -49.142390844849444,
                -26.010633546172542
              ],
              [
                -49.14632284751851,
                -26.0094145767869
              ],
              [
                -49.147500797948574,
                -26.013354064638865
              ],
              [
                -49.150756781307095,
                -26.00937607455183
              ],
              [
                -49.160260223097275,
                -26.00746259718603
              ],
              [
                -49.16164120189582,
                -26.007184603799992
              ],
              [
                -49.16669020982717,
                -26.000663641322888
              ],
              [
                -49.17479511161803,
                -26.00134820043458
              ],
              [
                -49.18008508042026,
                -26.00774465249019
              ],
              [
                -49.18070752527587,
                -26.00849769267257
              ],
              [
                -49.182215551049715,
                -26.008464199774654
              ],
              [
                -49.191489965853904,
                -26.008261187143827
              ],
              [
                -49.191334954813556,
                -26.01180268513702
              ],
              [
                -49.19128793285236,
                -26.01287820688383
              ],
              [
                -49.19517988175509,
                -26.01540169411306
              ],
              [
                -49.20681730502965,
                -26.017469215649907
              ],
              [
                -49.20707531773147,
                -26.017514719328403
              ],
              [
                -49.20710380825945,
                -26.017573713197788
              ],
              [
                -49.21069874370946,
                -26.025014178742087
              ],
              [
                -49.21711271409329,
                -26.023546716507937
              ],
              [
                -49.22493462189155,
                -26.030495704905828
              ],
              [
                -49.229278085767206,
                -26.030382722685363
              ],
              [
                -49.23406503606363,
                -26.030257259978733
              ],
              [
                -49.23752348632563,
                -26.030852741142205
              ],
              [
                -49.23648197845026,
                -26.036880207785636
              ],
              [
                -49.242183402463034,
                -26.043616714030545
              ],
              [
                -49.252738308480104,
                -26.04967121093557
              ],
              [
                -49.25483425096064,
                -26.055678196060768
              ],
              [
                -49.254254751725846,
                -26.057646211672505
              ],
              [
                -49.25306677909247,
                -26.061680674427503
              ],
              [
                -49.256847221079525,
                -26.063665208405727
              ],
              [
                -49.2555407196042,
                -26.067159174823153
              ],
              [
                -49.26267166252326,
                -26.066833703660706
              ],
              [
                -49.26645361525261,
                -26.066661207834013
              ],
              [
                -49.26533213073381,
                -26.069544698183844
              ],
              [
                -49.26456263699679,
                -26.071525164124544
              ],
              [
                -49.26848309806674,
                -26.07591567945564
              ],
              [
                -49.26548359203307,
                -26.078525674626373
              ],
              [
                -49.27395603962175,
                -26.077794189458714
              ],
              [
                -49.27464053625647,
                -26.077735175949464
              ],
              [
                -49.27448702421078,
                -26.078302176291718
              ],
              [
                -49.273640015452195,
                -26.08142765863918
              ],
              [
                -49.27661497264337,
                -26.08252267834383
              ],
              [
                -49.27792195573968,
                -26.081587681216845
              ],
              [
                -49.28230843857493,
                -26.078448717643422
              ],
              [
                -49.28653443597995,
                -26.07820173757866
              ],
              [
                -49.29403584585196,
                -26.084531719453445
              ],
              [
                -49.29487031331769,
                -26.091593176478632
              ],
              [
                -49.29127731178052,
                -26.093057180025312
              ],
              [
                -49.293218310028756,
                -26.096220184484345
              ],
              [
                -49.2936747792642,
                -26.096964674179272
              ],
              [
                -49.289294317822,
                -26.10220362882374
              ],
              [
                -49.30025269842172,
                -26.103846171947154
              ],
              [
                -49.297595731757696,
                -26.10935513329115
              ],
              [
                -49.30289664039744,
                -26.11303115597133
              ],
              [
                -49.30427015039948,
                -26.10953116676605
              ],
              [
                -49.31755103864399,
                -26.109297182714574
              ],
              [
                -49.33425235636758,
                -26.119757719618793
              ],
              [
                -49.33379086626338,
                -26.124391177585363
              ],
              [
                -49.3416907721335,
                -26.128681711622875
              ],
              [
                -49.34680324767888,
                -26.127608739656583
              ],
              [
                -49.35043519721279,
                -26.133433706739524
              ],
              [
                -49.35403864670603,
                -26.134666221063636
              ],
              [
                -49.35967261123364,
                -26.137211722185953
              ],
              [
                -49.359803601909434,
                -26.140214698295456
              ],
              [
                -49.36288755417135,
                -26.141810204531062
              ],
              [
                -49.36650001699409,
                -26.143679204494948
              ],
              [
                -49.36728099895689,
                -26.144083227568604
              ],
              [
                -49.36822746341566,
                -26.14893318143433
              ],
              [
                -49.37127597067705,
                -26.1483952306005
              ],
              [
                -49.36979944790462,
                -26.15545968806817
              ],
              [
                -49.37242691402734,
                -26.153105695678075
              ],
              [
                -49.386979811381934,
                -26.155462238218988
              ],
              [
                -49.38978327219766,
                -26.156994227779325
              ],
              [
                -49.393022762572784,
                -26.15360125333547
              ],
              [
                -49.395252257879605,
                -26.151267290542812
              ],
              [
                -49.39956273271424,
                -26.150269797642274
              ],
              [
                -49.40626711246901,
                -26.161142782032535
              ],
              [
                -49.416156079089866,
                -26.15271434266838
              ],
              [
                -49.41675850506192,
                -26.164969304572605
              ],
              [
                -49.41992850807822,
                -26.163520295579435
              ],
              [
                -49.42122650361449,
                -26.16617430092216
              ],
              [
                -49.42804791938732,
                -26.16775779869124
              ],
              [
                -49.43280586964041,
                -26.170168806480646
              ],
              [
                -49.43607984509772,
                -26.17182780615544
              ],
              [
                -49.43699385499857,
                -26.16953835380423
              ],
              [
                -49.43933433884208,
                -26.163680854615624
              ],
              [
                -49.45867070980621,
                -26.16223391748055
              ],
              [
                -49.46407759715104,
                -26.172859375601334
              ],
              [
                -49.478996457924254,
                -26.185403400288603
              ],
              [
                -49.48642788714513,
                -26.180059449123842
              ],
              [
                -49.485401897579514,
                -26.184437440282494
              ],
              [
                -49.489405855945,
                -26.19076541491909
              ],
              [
                -49.486431859501344,
                -26.194112882445143
              ],
              [
                -49.490182770320175,
                -26.199123874186423
              ],
              [
                -49.49326173030247,
                -26.203237373054833
              ],
              [
                -49.499471729619174,
                -26.202988882723286
              ],
              [
                -49.497163217271755,
                -26.20669137914642
              ],
              [
                -49.49444520757218,
                -26.211050336743696
              ],
              [
                -49.49059773403847,
                -26.209576829411652
              ],
              [
                -49.4904317574155,
                -26.213712812366992
              ],
              [
                -49.497920641785576,
                -26.218212325538833
              ],
              [
                -49.49607216619918,
                -26.22081280662802
              ],
              [
                -49.50263760280151,
                -26.221491333028077
              ],
              [
                -49.503882576720436,
                -26.226095297961407
              ],
              [
                -49.51136504384735,
                -26.220931361291715
              ],
              [
                -49.519461950904216,
                -26.226733863654836
              ],
              [
                -49.524347406190756,
                -26.22158441424574
              ],
              [
                -49.52866942664036,
                -26.217027924734932
              ],
              [
                -49.528918891856456,
                -26.22377989932053
              ],
              [
                -49.5289293605811,
                -26.22406938927316
              ],
              [
                -49.529091368202096,
                -26.223839914672823
              ],
              [
                -49.53571484973533,
                -26.214450970924258
              ],
              [
                -49.53968082220718,
                -26.21327497258002
              ],
              [
                -49.54236376925547,
                -26.223265453203673
              ],
              [
                -49.53638232129228,
                -26.226371897588997
              ],
              [
                -49.536146788714774,
                -26.226493415347047
              ],
              [
                -49.537852797021415,
                -26.22959942944492
              ],
              [
                -49.540481246993046,
                -26.227794898980093
              ],
              [
                -49.54518574388759,
                -26.224564932785555
              ],
              [
                -49.551395193163046,
                -26.224580482497263
              ],
              [
                -49.54748418693648,
                -26.229233426030696
              ],
              [
                -49.54886167264314,
                -26.23070542114016
              ],
              [
                -49.5545951306621,
                -26.236831451446207
              ],
              [
                -49.56068106932901,
                -26.234810454360055
              ],
              [
                -49.56676752100207,
                -26.232788473279037
              ],
              [
                -49.57742551138803,
                -26.21350860993022
              ],
              [
                -49.57970846363653,
                -26.227643031798205
              ],
              [
                -49.59034384061164,
                -26.229662069865313
              ],
              [
                -49.5871049362866,
                -26.216958112434643
              ],
              [
                -49.59214385786713,
                -26.22287508221428
              ],
              [
                -49.59822884652476,
                -26.220867611072627
              ],
              [
                -49.601766772684186,
                -26.227186102345804
              ],
              [
                -49.605656304164796,
                -26.211344694465758
              ],
              [
                -49.61163326306337,
                -26.213385700854356
              ],
              [
                -49.614266748098615,
                -26.20694474369948
              ],
              [
                -49.616741680494734,
                -26.213615216097597
              ],
              [
                -49.62109717848843,
                -26.215199719768755
              ],
              [
                -49.628924104394336,
                -26.211148747078933
              ],
              [
                -49.634747591362846,
                -26.208134298815512
              ],
              [
                -49.63323807053315,
                -26.20694925918096
              ],
              [
                -49.62549718359015,
                -26.200869802948397
              ],
              [
                -49.629911662309866,
                -26.190489342452985
              ],
              [
                -49.6363856084474,
                -26.190523347985735
              ],
              [
                -49.63357959082119,
                -26.201340811240797
              ],
              [
                -49.63679458380831,
                -26.20348731899646
              ],
              [
                -49.64520099625444,
                -26.209100327214088
              ],
              [
                -49.64854794537462,
                -26.20779430828937
              ],
              [
                -49.6482220061163,
                -26.195375865495397
              ],
              [
                -49.64822000434839,
                -26.19532339668433
              ],
              [
                -49.65324547181664,
                -26.192555397826624
              ],
              [
                -49.65271149519998,
                -26.18670941584126
              ],
              [
                -49.66004746628161,
                -26.18651542911461
              ],
              [
                -49.66251592594802,
                -26.189978937462623
              ],
              [
                -49.66334191545648,
                -26.19113844290483
              ],
              [
                -49.667352367094956,
                -26.188152464460636
              ],
              [
                -49.67161133247583,
                -26.184980994539217
              ],
              [
                -49.672116821718525,
                -26.186353493114996
              ],
              [
                -49.67379631027223,
                -26.190919482719377
              ],
              [
                -49.68012626640422,
                -26.185657990519296
              ],
              [
                -49.68934570191802,
                -26.187962011802448
              ],
              [
                -49.68290976751499,
                -26.181826053118357
              ],
              [
                -49.6762703525555,
                -26.17549654949235
              ],
              [
                -49.68833073554312,
                -26.176200566230325
              ],
              [
                -49.698490239248954,
                -26.161032659770207
              ],
              [
                -49.7101901384504,
                -26.15389220253552
              ],
              [
                -49.71798654701177,
                -26.161207214362566
              ],
              [
                -49.72726344181633,
                -26.16991120299974
              ],
              [
                -49.73651693833278,
                -26.156600811547648
              ],
              [
                -49.72917653124412,
                -26.148506797134356
              ],
              [
                -49.72440907258094,
                -26.1432497967776
              ],
              [
                -49.73503498594773,
                -26.14185783451419
              ],
              [
                -49.73535799149758,
                -26.141815365753097
              ],
              [
                -49.739807468801175,
                -26.138051855664802
              ],
              [
                -49.748109394536925,
                -26.139468895161176
              ],
              [
                -49.74950390686454,
                -26.12883743044232
              ],
              [
                -49.75064943339408,
                -26.12010648681869
              ],
              [
                -49.7641618603907,
                -26.113349554192244
              ],
              [
                -49.767551393008716,
                -26.10429607827673
              ],
              [
                -49.77561279265431,
                -26.1013496302118
              ],
              [
                -49.77827525315052,
                -26.106931097309392
              ],
              [
                -49.76785784453926,
                -26.109899077412962
              ],
              [
                -49.77215979551115,
                -26.1165045491818
              ],
              [
                -49.77330875094164,
                -26.118269064450462
              ],
              [
                -49.77794724770489,
                -26.11889804862381
              ],
              [
                -49.78176274401236,
                -26.111277116778027
              ],
              [
                -49.7872167154464,
                -26.100382163614565
              ],
              [
                -49.78754717449026,
                -26.108005618313364
              ],
              [
                -49.787662692594246,
                -26.110676120751123
              ],
              [
                -49.792476632411585,
                -26.11265361048894
              ],
              [
                -49.79920007984246,
                -26.10924563767349
              ],
              [
                -49.8040500548775,
                -26.10720269450632
              ],
              [
                -49.80607759006181,
                -26.097056242077468
              ],
              [
                -49.80793861115834,
                -26.08774377899498
              ],
              [
                -49.81405252370558,
                -26.092343751301517
              ],
              [
                -49.81560350734154,
                -26.093510284143626
              ],
              [
                -49.824193467896855,
                -26.09248231570206
              ],
              [
                -49.83026545654327,
                -26.082759348216776
              ],
              [
                -49.83017601267779,
                -26.06948640636306
              ],
              [
                -49.83017300049286,
                -26.06904942298135
              ],
              [
                -49.830369982316284,
                -26.0691909041125
              ],
              [
                -49.83971836803284,
                -26.075901414366506
              ],
              [
                -49.84312939050899,
                -26.0724564256016
              ],
              [
                -49.83332800062502,
                -26.06668042935398
              ],
              [
                -49.833170965121326,
                -26.064917440398858
              ],
              [
                -49.83208654193563,
                -26.052703493303746
              ],
              [
                -49.83516552187368,
                -26.051997001223434
              ],
              [
                -49.83954143373908,
                -26.059485949929385
              ],
              [
                -49.8421019180373,
                -26.063867470160506
              ],
              [
                -49.86176529904917,
                -26.054754538831574
              ],
              [
                -49.864186802816796,
                -26.046116100104218
              ],
              [
                -49.87027676703359,
                -26.043297639954474
              ],
              [
                -49.86989024377232,
                -26.042660649036048
              ],
              [
                -49.865029337931325,
                -26.034650631645235
              ],
              [
                -49.89129965880696,
                -26.028888766874474
              ],
              [
                -49.895076085910226,
                -26.031411755095345
              ],
              [
                -49.898106059098076,
                -26.033435730683664
              ],
              [
                -49.90216506226397,
                -26.028302292363303
              ],
              [
                -49.903661559898325,
                -26.0264102704095
              ],
              [
                -49.91482944598781,
                -26.024588321512322
              ],
              [
                -49.91836594024883,
                -26.025840333670548
              ],
              [
                -49.934832759166156,
                -26.031669363029806
              ],
              [
                -49.94019078408349,
                -26.025016412691166
              ],
              [
                -49.938312762308335,
                -26.022809916621046
              ],
              [
                -49.933920847953445,
                -26.01765043313954
              ],
              [
                -49.93841979843193,
                -26.012309452052477
              ],
              [
                -49.941727306757905,
                -26.008382487720898
              ],
              [
                -49.951780192520914,
                -26.018142478005572
              ],
              [
                -49.952696668468505,
                -26.019031960761243
              ],
              [
                -49.95903461801881,
                -26.020303995222086
              ],
              [
                -49.94751618045962,
                -26.03048040286565
              ],
              [
                -49.97220589492331,
                -26.057765378140758
              ],
              [
                -49.97710135658295,
                -26.05283542183091
              ],
              [
                -49.96584246771844,
                -26.040131921307253
              ],
              [
                -49.96407357191031,
                -26.02478900139973
              ],
              [
                -49.97150304039001,
                -26.016604039671233
              ],
              [
                -49.990552919261795,
                -26.011769110348943
              ],
              [
                -49.99306289759564,
                -26.01113162811243
              ],
              [
                -49.99803033016553,
                -26.01535460304025
              ],
              [
                -50.01105620951882,
                -26.013508671475382
              ],
              [
                -50.011060662395096,
                -26.02523910994447
              ],
              [
                -50.01018017779025,
                -26.02663310482366
              ],
              [
                -50.0027456895483,
                -26.038407557241083
              ],
              [
                -50.00584515940785,
                -26.04476755602706
              ],
              [
                -50.03169449912058,
                -26.028566192684867
              ],
              [
                -50.035516454489056,
                -26.027536199303647
              ],
              [
                -50.039338938857625,
                -26.026505719926007
              ],
              [
                -50.04404641170369,
                -26.02922369761644
              ],
              [
                -50.056142766395205,
                -26.036208231261956
              ],
              [
                -50.063844221839446,
                -26.033140267434693
              ],
              [
                -50.06443172505944,
                -26.032906254270294
              ],
              [
                -50.0724561536969,
                -26.034516786200065
              ],
              [
                -50.072620628891436,
                -26.034876761418484
              ],
              [
                -50.077670563841636,
                -26.045904240531783
              ],
              [
                -50.078972064157874,
                -26.03670028395881
              ],
              [
                -50.080748612420294,
                -26.024136320429683
              ],
              [
                -50.084327601015794,
                -26.023048838450187
              ],
              [
                -50.09157297886077,
                -26.043185290215572
              ],
              [
                -50.09335193328227,
                -26.0447007935767
              ],
              [
                -50.109897761848906,
                -26.058800304976327
              ],
              [
                -50.11089077870292,
                -26.054458824350437
              ],
              [
                -50.111193251704115,
                -26.053136336252468
              ],
              [
                -50.11790170415725,
                -26.05130184854634
              ],
              [
                -50.118889179136325,
                -26.05593435074708
              ],
              [
                -50.12057814791197,
                -26.063856793158465
              ],
              [
                -50.13105862420277,
                -26.04787090738245
              ],
              [
                -50.13425763149084,
                -26.042990952145573
              ],
              [
                -50.13591161331173,
                -26.044655914547054
              ],
              [
                -50.14907991661791,
                -26.057915427811597
              ],
              [
                -50.15198693653489,
                -26.05078745887975
              ],
              [
                -50.14784850438275,
                -26.04143046852766
              ],
              [
                -50.141516129283914,
                -26.027111494172303
              ],
              [
                -50.15100606681513,
                -26.022734052184905
              ],
              [
                -50.16369344004652,
                -26.026521587764815
              ],
              [
                -50.17137186382448,
                -26.024668607376487
              ],
              [
                -50.17926678447184,
                -26.02826211761097
              ],
              [
                -50.18204027776791,
                -26.033943621956816
              ],
              [
                -50.17601231165853,
                -26.03361561492719
              ],
              [
                -50.17104180882344,
                -26.042089564967437
              ],
              [
                -50.16416939075257,
                -26.042679535712274
              ],
              [
                -50.16314238662157,
                -26.036215042124613
              ],
              [
                -50.15538444112284,
                -26.041562008340748
              ],
              [
                -50.16116933734594,
                -26.05302348046879
              ],
              [
                -50.16172685430269,
                -26.054128990263095
              ],
              [
                -50.17075275208866,
                -26.064932950748624
              ],
              [
                -50.174497206561085,
                -26.06550648121993
              ],
              [
                -50.181940082833066,
                -26.078308436076192
              ],
              [
                -50.182038096348705,
                -26.078476924785317
              ],
              [
                -50.19250354827206,
                -26.07114949196753
              ],
              [
                -50.1930995521105,
                -26.068084022261992
              ],
              [
                -50.193739528528816,
                -26.06479155354148
              ],
              [
                -50.207541464880535,
                -26.061036575778648
              ],
              [
                -50.20936294220348,
                -26.05667263097451
              ],
              [
                -50.218232365771016,
                -26.0564401416527
              ],
              [
                -50.21843886250117,
                -26.056587625804
              ],
              [
                -50.223618322625704,
                -26.060272631656634
              ],
              [
                -50.23144031971068,
                -26.049591193280538
              ],
              [
                -50.23359030151103,
                -26.04665521431837
              ],
              [
                -50.23544332127229,
                -26.035811763476183
              ],
              [
                -50.25103021893131,
                -26.029734817244204
              ],
              [
                -50.25183719958483,
                -26.03139984282641
              ],
              [
                -50.254635642302695,
                -26.037172303985585
              ],
              [
                -50.25393913731031,
                -26.04022882839474
              ],
              [
                -50.25152463204045,
                -26.050828779272173
              ],
              [
                -50.26402854818463,
                -26.04410933004965
              ],
              [
                -50.267815515123914,
                -26.042074334272797
              ],
              [
                -50.27286298514647,
                -26.04750684227512
              ],
              [
                -50.27637493431926,
                -26.051287851192402
              ],
              [
                -50.28736732453787,
                -26.05308586658085
              ],
              [
                -50.29836023776883,
                -26.05488337598486
              ],
              [
                -50.31771900876498,
                -26.06807041678606
              ],
              [
                -50.31985800524162,
                -26.074686888727104
              ],
              [
                -50.32169543998745,
                -26.080370352081964
              ],
              [
                -50.32419442194079,
                -26.081088392874655
              ],
              [
                -50.33157433688549,
                -26.083208402988028
              ],
              [
                -50.32619687247834,
                -26.095685811136537
              ],
              [
                -50.325146354831595,
                -26.098123814662127
              ],
              [
                -50.326552317898944,
                -26.098277334842855
              ],
              [
                -50.33085580937582,
                -26.098747316640576
              ],
              [
                -50.33197226400234,
                -26.107782794324944
              ],
              [
                -50.32843577292651,
                -26.112007273924295
              ],
              [
                -50.32610881011933,
                -26.114785742899265
              ],
              [
                -50.32482776785062,
                -26.12337072415489
              ],
              [
                -50.33268972070189,
                -26.122155738147573
              ],
              [
                -50.333499167366966,
                -26.125251732555306
              ],
              [
                -50.3216612685563,
                -26.125481204740094
              ],
              [
                -50.32311073976022,
                -26.13444315384725
              ],
              [
                -50.32824471665404,
                -26.12861519650589
              ],
              [
                -50.33202767359889,
                -26.13407921829952
              ],
              [
                -50.340393600655496,
                -26.13015125592433
              ],
              [
                -50.35227855414199,
                -26.118819823503724
              ],
              [
                -50.35471853465002,
                -26.118311315604267
              ],
              [
                -50.3596509952991,
                -26.117281829028467
              ],
              [
                -50.360599000785655,
                -26.11001788479067
              ],
              [
                -50.36588046460658,
                -26.11140940253883
              ],
              [
                -50.36697796159804,
                -26.1116978841908
              ],
              [
                -50.36589899231,
                -26.108548894060366
              ],
              [
                -50.36098707610386,
                -26.094217450900576
              ],
              [
                -50.36603754963643,
                -26.086949001543626
              ],
              [
                -50.37108755417439,
                -26.079679505189315
              ],
              [
                -50.39910190251026,
                -26.062963187877834
              ],
              [
                -50.40292289750107,
                -26.057245688690134
              ],
              [
                -50.40762534678332,
                -26.058938723357006
              ],
              [
                -50.40779481665937,
                -26.061058201174074
              ],
              [
                -50.40813831820196,
                -26.065338211675734
              ],
              [
                -50.41790176015092,
                -26.061409216990718
              ],
              [
                -50.433137638348335,
                -26.060597280375326
              ],
              [
                -50.43624613026699,
                -26.05580180839714
              ],
              [
                -50.4393545941884,
                -26.05100533542288
              ],
              [
                -50.44969305944922,
                -26.043480397818318
              ],
              [
                -50.44915957187647,
                -26.039331402189323
              ],
              [
                -50.44300212051047,
                -26.03538939683838
              ],
              [
                -50.45392104036723,
                -26.03143345613377
              ],
              [
                -50.45825151367609,
                -26.036271964047305
              ],
              [
                -50.458656025566995,
                -26.03672395475938
              ],
              [
                -50.458705021706706,
                -26.035869441048895
              ],
              [
                -50.45923406084081,
                -26.02658399478894
              ],
              [
                -50.46805945119891,
                -26.02705602782784
              ],
              [
                -50.47103597701753,
                -26.02084603657449
              ],
              [
                -50.47676090228865,
                -26.023722072366976
              ],
              [
                -50.47602240136874,
                -26.030215033141335
              ],
              [
                -50.475590870149276,
                -26.034007005826794
              ],
              [
                -50.4816053212217,
                -26.030805042821594
              ],
              [
                -50.48113234333305,
                -26.02975906504805
              ],
              [
                -50.479528369827385,
                -26.026214562591075
              ],
              [
                -50.49356727164606,
                -26.02664461638527
              ],
              [
                -50.49971122231995,
                -26.02329261636238
              ],
              [
                -50.50218616853583,
                -26.027882126950363
              ],
              [
                -50.50417765832615,
                -26.031574613187942
              ],
              [
                -50.50886013763075,
                -26.02848365627939
              ],
              [
                -50.5130756103983,
                -26.025701651665706
              ],
              [
                -50.521381046819286,
                -26.030324183829237
              ],
              [
                -50.54912024828624,
                -26.038931740126934
              ],
              [
                -50.5548012873292,
                -26.020936322926246
              ],
              [
                -50.56290878710675,
                -26.00604139462902
              ],
              [
                -50.57130474132995,
                -26.003299943971783
              ],
              [
                -50.59118999154214,
                -26.021641421279508
              ],
              [
                -50.591657898239255,
                -26.042059345522603
              ],
              [
                -50.58934540974568,
                -26.043501332454777
              ],
              [
                -50.58287247575826,
                -26.04753729887654
              ],
              [
                -50.57972798440001,
                -26.04765677480796
              ],
              [
                -50.566866626949,
                -26.041206758811175
              ],
              [
                -50.556194184303116,
                -26.041445220871363
              ],
              [
                -50.55490323050915,
                -26.04147375140463
              ],
              [
                -50.55052425528997,
                -26.044188204714597
              ],
              [
                -50.55067122593565,
                -26.044955727414557
              ],
              [
                -50.55258967020972,
                -26.05494418751203
              ],
              [
                -50.55633714895723,
                -26.059354189124328
              ],
              [
                -50.586149346604216,
                -26.069229736051017
              ],
              [
                -50.59694627927126,
                -26.069958246949174
              ],
              [
                -50.62791905318978,
                -26.062631876430522
              ],
              [
                -50.640515394067506,
                -26.07840537384164
              ],
              [
                -50.64537430887319,
                -26.090286351220268
              ],
              [
                -50.64995874563341,
                -26.095245306788623
              ],
              [
                -50.6567551397643,
                -26.102597324201202
              ],
              [
                -50.651271142313014,
                -26.11659076891173
              ],
              [
                -50.64758013356814,
                -26.12600970428095
              ],
              [
                -50.652134573922964,
                -26.130631695014696
              ],
              [
                -50.65668854527488,
                -26.13525421175441
              ],
              [
                -50.67779279522942,
                -26.147356236705743
              ],
              [
                -50.69896903025967,
                -26.180837146887455
              ],
              [
                -50.70473944083475,
                -26.18618016627204
              ],
              [
                -50.70930292936627,
                -26.189055675450124
              ],
              [
                -50.731306668666576,
                -26.202918675501376
              ],
              [
                -50.714694185004404,
                -26.238058017449045
              ],
              [
                -50.7180051234263,
                -26.244286000359246
              ],
              [
                -50.731806504246606,
                -26.249279008532298
              ],
              [
                -50.74499887830899,
                -26.245851582885614
              ],
              [
                -50.74590793685222,
                -26.229937149149816
              ],
              [
                -50.7459474404173,
                -26.22925062875124
              ],
              [
                -50.751944425396225,
                -26.22523618964015
              ],
              [
                -50.75794287737297,
                -26.22122068353644
              ],
              [
                -50.79280260701661,
                -26.227244777909434
              ],
              [
                -50.825527766526896,
                -26.245947300698145
              ],
              [
                -50.82787022609953,
                -26.253735788824113
              ],
              [
                -50.83166561776243,
                -26.266350260669533
              ],
              [
                -50.83851505837616,
                -26.270812759150964
              ],
              [
                -50.8461694921438,
                -26.266829327593275
              ],
              [
                -50.8614949810956,
                -26.243768422502832
              ],
              [
                -50.86263744322842,
                -26.242048965561374
              ],
              [
                -50.86559396320777,
                -26.241923467123783
              ],
              [
                -50.86855093718483,
                -26.2417979866881
              ],
              [
                -50.87869278813657,
                -26.25849545171109
              ],
              [
                -50.880115756623994,
                -26.268034406511727
              ],
              [
                -50.88153767410424,
                -26.277571893330823
              ],
              [
                -50.8904855864517,
                -26.285252875584288
              ],
              [
                -50.899508966338466,
                -26.288632412462444
              ],
              [
                -50.900533503171815,
                -26.288160402657923
              ],
              [
                -50.90248946540318,
                -26.287258402579802
              ],
              [
                -50.908718454926465,
                -26.284386442174547
              ],
              [
                -50.90761256406807,
                -26.251092555169127
              ],
              [
                -50.9345184165584,
                -26.233382704516682
              ],
              [
                -50.94129436186956,
                -26.233809722192277
              ],
              [
                -50.94326933383635,
                -26.24172418671652
              ],
              [
                -50.9402268090038,
                -26.248378669557727
              ],
              [
                -50.931329335751904,
                -26.26784158566762
              ],
              [
                -50.93721825237006,
                -26.27887153554941
              ],
              [
                -50.9441971535811,
                -26.282014578289292
              ],
              [
                -50.95277513471649,
                -26.2691296325998
              ],
              [
                -50.96972601753208,
                -26.263058704347287
              ],
              [
                -50.97206002927068,
                -26.25421576484406
              ],
              [
                -50.97439405799979,
                -26.24537180135145
              ],
              [
                -50.984054498510076,
                -26.23913386068467
              ],
              [
                -50.99101397014574,
                -26.234639878545426
              ],
              [
                -50.99997491655237,
                -26.229988407823907
              ],
              [
                -51.05469793591845,
                -26.244485026904382
              ],
              [
                -51.062385378461094,
                -26.24269608064187
              ],
              [
                -51.06929385003709,
                -26.23667862472824
              ],
              [
                -51.07953427643816,
                -26.22775716956233
              ],
              [
                -51.08266976201241,
                -26.22788918085134
              ],
              [
                -51.084195213735235,
                -26.23433117520427
              ],
              [
                -51.08906266182481,
                -26.24648513176461
              ],
              [
                -51.094025082851786,
                -26.249472148206515
              ],
              [
                -51.09338857353095,
                -26.256897614678643
              ],
              [
                -51.09325156587583,
                -26.258493106549054
              ],
              [
                -51.10138744408778,
                -26.265179132797513
              ],
              [
                -51.10446791539714,
                -26.274935613810733
              ],
              [
                -51.10913185525054,
                -26.27355460273838
              ],
              [
                -51.12178326962069,
                -26.279063634734563
              ],
              [
                -51.13445416477177,
                -26.279157174989543
              ],
              [
                -51.13616765013426,
                -26.279170190838283
              ],
              [
                -51.13634311828278,
                -26.279987665549413
              ],
              [
                -51.13755260585123,
                -26.285619167787665
              ],
              [
                -51.143469568284864,
                -26.285304187263815
              ],
              [
                -51.14549303948153,
                -26.28312368093923
              ],
              [
                -51.149220505952634,
                -26.27910620297515
              ],
              [
                -51.16336540730025,
                -26.283019258821323
              ],
              [
                -51.163348362983506,
                -26.286902744037132
              ],
              [
                -51.16333135462912,
                -26.29103023239313
              ],
              [
                -51.16935829857297,
                -26.291102245891977
              ],
              [
                -51.18131618748899,
                -26.298970238294764
              ],
              [
                -51.1933330745798,
                -26.301500295772183
              ],
              [
                -51.19815106328262,
                -26.29988381007899
              ],
              [
                -51.20285802870954,
                -26.29830482287071
              ],
              [
                -51.207890981021755,
                -26.296616824170226
              ],
              [
                -51.21571787441535,
                -26.313496816644303
              ],
              [
                -51.21969381781787,
                -26.31705729781354
              ],
              [
                -51.223668274231734,
                -26.320617313985515
              ],
              [
                -51.22728523822473,
                -26.32117632449657
              ],
              [
                -51.24277059749129,
                -26.323569869511005
              ],
              [
                -51.24492158058211,
                -26.329659337510957
              ],
              [
                -51.25115498754186,
                -26.332413858345138
              ],
              [
                -51.252338500164875,
                -26.334767834154587
              ],
              [
                -51.25600194218274,
                -26.34205283019838
              ],
              [
                -51.25628639952539,
                -26.342618338193475
              ],
              [
                -51.256023915021444,
                -26.342989840981062
              ],
              [
                -51.251670444248404,
                -26.3491513032868
              ],
              [
                -51.25633389295783,
                -26.356287765329764
              ],
              [
                -51.261085810381275,
                -26.363558777222988
              ],
              [
                -51.25664681031073,
                -26.369707741287282
              ],
              [
                -51.252208804234854,
                -26.375855692358673
              ],
              [
                -51.26705866673878,
                -26.383351199429534
              ],
              [
                -51.27031365397285,
                -26.38499372793283
              ],
              [
                -51.277757054841885,
                -26.389969245251837
              ],
              [
                -51.27656604233396,
                -26.395810695656913
              ],
              [
                -51.27599705391936,
                -26.39860220490529
              ],
              [
                -51.276262021553485,
                -26.401475675755176
              ],
              [
                -51.28041152439145,
                -26.40084918731941
              ],
              [
                -51.2887129530568,
                -26.399596232457352
              ],
              [
                -51.292230900529134,
                -26.403973205315843
              ],
              [
                -51.29025441237537,
                -26.40790370270837
              ],
              [
                -51.28714288559895,
                -26.4138471871226
              ],
              [
                -51.29730531329063,
                -26.41897019241435
              ],
              [
                -51.29119727101517,
                -26.4366470984152
              ],
              [
                -51.28272482642641,
                -26.441004556796535
              ],
              [
                -51.27406337992841,
                -26.445459499179343
              ],
              [
                -51.27737234249075,
                -26.450188032122394
              ],
              [
                -51.27985428865891,
                -26.45373401333697
              ],
              [
                -51.277412316426,
                -26.457835998527493
              ],
              [
                -51.271039835850864,
                -26.456675984472756
              ],
              [
                -51.26730335924772,
                -26.460456953291786
              ],
              [
                -51.2643474053169,
                -26.461003454125667
              ],
              [
                -51.25348248976623,
                -26.463013374404756
              ],
              [
                -51.25712095085143,
                -26.4670043940732
              ],
              [
                -51.260080914495326,
                -26.470251368994177
              ],
              [
                -51.25508243108931,
                -26.472118862120418
              ],
              [
                -51.25045543434221,
                -26.473846838023135
              ],
              [
                -51.255667411545446,
                -26.476531837726483
              ],
              [
                -51.26609079395809,
                -26.481901856148973
              ],
              [
                -51.2607542915766,
                -26.49068282395133
              ],
              [
                -51.268084754777725,
                -26.490153342258814
              ],
              [
                -51.273780196085944,
                -26.496581345606316
              ],
              [
                -51.268303705425204,
                -26.499853299161
              ],
              [
                -51.27154218701995,
                -26.504454807357792
              ],
              [
                -51.26256372015904,
                -26.507015270223935
              ],
              [
                -51.26546766816738,
                -26.51236824064002
              ],
              [
                -51.26155069250259,
                -26.51276424064465
              ],
              [
                -51.25648723546546,
                -26.519787204545107
              ],
              [
                -51.2531797701852,
                -26.520134703835996
              ],
              [
                -51.25091675910325,
                -26.52037166114325
              ],
              [
                -51.24970326938529,
                -26.525338664597022
              ],
              [
                -51.246560243048094,
                -26.53819560082913
              ],
              [
                -51.24048277417795,
                -26.539138562405434
              ],
              [
                -51.23911279712669,
                -26.539351088900084
              ],
              [
                -51.23938129482269,
                -26.540787585824177
              ],
              [
                -51.24011027426018,
                -26.544691064748335
              ],
              [
                -51.23581627415446,
                -26.546122518821814
              ],
              [
                -51.223282895587154,
                -26.550301499656374
              ],
              [
                -51.22943783448795,
                -26.55478449743299
              ],
              [
                -51.22822027359677,
                -26.561652940253595
              ],
              [
                -51.216482857107465,
                -26.56899737680998
              ],
              [
                -51.224142268680225,
                -26.575886877459112
              ],
              [
                -51.224292281236025,
                -26.576410371157518
              ],
              [
                -51.22678220409506,
                -26.585090852592376
              ],
              [
                -51.22602871420644,
                -26.586916878609877
              ],
              [
                -51.22357870807053,
                -26.59285233641095
              ],
              [
                -51.23336757012827,
                -26.600802824843996
              ],
              [
                -51.237331570794524,
                -26.604022319458824
              ],
              [
                -51.23597157222189,
                -26.605729322781606
              ],
              [
                -51.22816756692149,
                -26.615516749539847
              ],
              [
                -51.23250154349577,
                -26.614365796651146
              ],
              [
                -51.237331508667374,
                -26.61865327458548
              ],
              [
                -51.23886497261762,
                -26.627890741831276
              ],
              [
                -51.24603587382717,
                -26.626939778149655
              ],
              [
                -51.248299373164755,
                -26.626639807087933
              ],
              [
                -51.258143813711925,
                -26.625333811968794
              ],
              [
                -51.26579419108664,
                -26.635967302755887
              ],
              [
                -51.26694166409335,
                -26.637561805228856
              ],
              [
                -51.26833218710854,
                -26.637320304921023
              ],
              [
                -51.27707763232732,
                -26.63579934772901
              ],
              [
                -51.27489662436897,
                -26.640252830667258
              ],
              [
                -51.282225036300396,
                -26.642092335879504
              ],
              [
                -51.28228705038869,
                -26.643114343552725
              ],
              [
                -51.28291799545845,
                -26.653499312760943
              ],
              [
                -51.30534728958073,
                -26.654665378100205
              ],
              [
                -51.31059677984143,
                -26.65146139954116
              ],
              [
                -51.323297668695304,
                -26.656424926760383
              ],
              [
                -51.326009161418824,
                -26.652450940990892
              ],
              [
                -51.32830765279195,
                -26.64908247768235
              ],
              [
                -51.33084814580574,
                -26.647201966070707
              ],
              [
                -51.33463811633682,
                -26.647535513165526
              ],
              [
                -51.35263745388164,
                -26.651666024817906
              ],
              [
                -51.36231886689114,
                -26.65681854287551
              ],
              [
                -51.36461283943534,
                -26.658039544682964
              ],
              [
                -51.37275577097185,
                -26.658499091588716
              ],
              [
                -51.38103768038529,
                -26.658966107959753
              ],
              [
                -51.39076807120008,
                -26.663386626761962
              ],
              [
                -51.405401448105444,
                -26.67566813565086
              ],
              [
                -51.39662048014656,
                -26.681991583088667
              ],
              [
                -51.39241098430613,
                -26.685022534891363
              ],
              [
                -51.39253545096928,
                -26.697766020407606
              ],
              [
                -51.398220846962886,
                -26.710418956761952
              ],
              [
                -51.398224335926514,
                -26.710420967767263
              ],
              [
                -51.41102570888885,
                -26.71679047275039
              ],
              [
                -51.40664831798377,
                -26.700130023741437
              ],
              [
                -51.43484665819261,
                -26.68763216564047
              ],
              [
                -51.43695115397591,
                -26.67717572901496
              ],
              [
                -51.43905769072203,
                -26.666719259407788
              ],
              [
                -51.44678813420251,
                -26.660360305397674
              ],
              [
                -51.45520009173708,
                -26.65344036870485
              ],
              [
                -51.454720132340086,
                -26.646799878899998
              ],
              [
                -51.45424015693477,
                -26.640158922103975
              ],
              [
                -51.463744652411286,
                -26.62664497796265
              ],
              [
                -51.467712633116655,
                -26.62155550939818
              ],
              [
                -51.47486107584015,
                -26.618972542323903
              ],
              [
                -51.476310572473864,
                -26.61941504387235
              ],
              [
                -51.48562701455558,
                -26.622262086676276
              ],
              [
                -51.483884535018404,
                -26.613099613151835
              ],
              [
                -51.4835975438281,
                -26.61158909135425
              ],
              [
                -51.49039551674208,
                -26.605218618105557
              ],
              [
                -51.49375654078974,
                -26.5891452012904
              ],
              [
                -51.509563954293945,
                -26.581689297294115
              ],
              [
                -51.51740636009794,
                -26.585690802931882
              ],
              [
                -51.523190816684796,
                -26.588642290999374
              ],
              [
                -51.529703254922914,
                -26.591964828343954
              ],
              [
                -51.56936546370433,
                -26.59091743080914
              ],
              [
                -51.58394985142825,
                -26.590532504214394
              ],
              [
                -51.5871008104832,
                -26.589405485126484
              ],
              [
                -51.59746022491802,
                -26.585701056149226
              ],
              [
                -51.609537126261316,
                -26.589052588944742
              ],
              [
                -51.61462608205758,
                -26.590465071926445
              ],
              [
                -51.62278403884333,
                -26.588844110083855
              ],
              [
                -51.63094149664868,
                -26.587222145243782
              ],
              [
                -51.64365192295907,
                -26.576990231915918
              ],
              [
                -51.645660400161546,
                -26.575373236505463
              ],
              [
                -51.655111841996856,
                -26.5704802824227
              ],
              [
                -51.66456378484261,
                -26.565587344338844
              ],
              [
                -51.69455547070521,
                -26.582943368982832
              ],
              [
                -51.73414021499056,
                -26.57432502737961
              ],
              [
                -51.745658578588426,
                -26.57753155983511
              ],
              [
                -51.745668612498676,
                -26.57753407886174
              ],
              [
                -51.757176981201006,
                -26.58073759031027
              ],
              [
                -51.77995828264807,
                -26.586648621127484
              ],
              [
                -51.78503076322118,
                -26.588169140757664
              ],
              [
                -51.79003919426416,
                -26.588547661064368
              ],
              [
                -51.798157163870826,
                -26.58663468712064
              ],
              [
                -51.802440596647635,
                -26.585625208618538
              ],
              [
                -51.818149511798715,
                -26.579554801536247
              ],
              [
                -51.823141466035985,
                -26.58176480554385
              ],
              [
                -51.831767872531444,
                -26.58724878717386
              ],
              [
                -51.843294736252396,
                -26.594577793741696
              ],
              [
                -51.854605658786156,
                -26.59200184830363
              ],
              [
                -51.86398209111626,
                -26.595716882648084
              ],
              [
                -51.8731055130758,
                -26.59974388805611
              ],
              [
                -51.87921346658798,
                -26.596672946001046
              ],
              [
                -51.883175976269925,
                -26.586630465108286
              ],
              [
                -51.88361197366449,
                -26.58552546840201
              ],
              [
                -51.899060874209546,
                -26.579655023734485
              ],
              [
                -51.92777261994954,
                -26.584354599824835
              ],
              [
                -51.946315043750744,
                -26.569909735176523
              ],
              [
                -51.956563462099425,
                -26.5732347429672
              ],
              [
                -51.95937641912196,
                -26.574147246774892
              ],
              [
                -51.96822988071424,
                -26.56913380597072
              ],
              [
                -51.97113388116697,
                -26.567489330778734
              ],
              [
                -51.974435303784006,
                -26.567849337182853
              ],
              [
                -51.98145376657285,
                -26.56861534230154
              ],
              [
                -51.98431675143429,
                -26.565804352922264
              ],
              [
                -51.98471626001849,
                -26.56541235365929
              ],
              [
                -51.986052727427,
                -26.56544539225413
              ],
              [
                -52.01073106888447,
                -26.56605895410707
              ],
              [
                -52.01368253740859,
                -26.559727503968396
              ],
              [
                -52.0148705312326,
                -26.557177994796934
              ],
              [
                -52.01984001680838,
                -26.554791020394575
              ],
              [
                -52.02072302854708,
                -26.5482150318012
              ],
              [
                -52.02957045908245,
                -26.546232067684194
              ],
              [
                -52.03230998892021,
                -26.540436611981466
              ],
              [
                -52.04698738743944,
                -26.534898170444567
              ],
              [
                -52.05469784091872,
                -26.528083245696966
              ],
              [
                -52.056944314731716,
                -26.528923236760072
              ],
              [
                -52.059766783116544,
                -26.529977751125507
              ],
              [
                -52.0619072740379,
                -26.526847265272576
              ],
              [
                -52.06407780491579,
                -26.52367275767466
              ],
              [
                -52.068237258167656,
                -26.52420027253341
              ],
              [
                -52.07841019619519,
                -26.525489816991083
              ],
              [
                -52.08235317474384,
                -26.518296857244184
              ],
              [
                -52.08753814545384,
                -26.508838876546545
              ],
              [
                -52.089139646218115,
                -26.5099303824864
              ],
              [
                -52.092896633782146,
                -26.512491395038612
              ],
              [
                -52.09674211128792,
                -26.50433293021748
              ],
              [
                -52.09798213020522,
                -26.493158499451265
              ],
              [
                -52.09918368389305,
                -26.482330020391668
              ],
              [
                -52.10547214855866,
                -26.47895157397013
              ],
              [
                -52.11176059223091,
                -26.47557308954731
              ],
              [
                -52.12241050219127,
                -26.475588114963855
              ],
              [
                -52.12863549266285,
                -26.475596630838087
              ],
              [
                -52.12995596992449,
                -26.474089652590806
              ],
              [
                -52.133535975801706,
                -26.47000116204123
              ],
              [
                -52.13779292554555,
                -26.47140618425874
              ],
              [
                -52.14395438772542,
                -26.473440692049248
              ],
              [
                -52.157277778909396,
                -26.471388738889893
              ],
              [
                -52.17656867236754,
                -26.453067373809272
              ],
              [
                -52.18514916099859,
                -26.444918424590085
              ],
              [
                -52.19001512573507,
                -26.445704931046837
              ],
              [
                -52.20363244895361,
                -26.45948844233866
              ],
              [
                -52.2050064290738,
                -26.45999345846863
              ],
              [
                -52.219902799478504,
                -26.465460452450724
              ],
              [
                -52.22893824819692,
                -26.464320005132123
              ],
              [
                -52.24185813787902,
                -26.462688042161044
              ],
              [
                -52.24574512539255,
                -26.45792405994131
              ],
              [
                -52.248451632783926,
                -26.454607591674645
              ],
              [
                -52.262875007938824,
                -26.4543311534223
              ],
              [
                -52.27064343727993,
                -26.45946313548143
              ],
              [
                -52.27408237168697,
                -26.461735151939205
              ],
              [
                -52.28233131438962,
                -26.46202019205715
              ],
              [
                -52.283728823442964,
                -26.45836522330733
              ],
              [
                -52.283906336081394,
                -26.45790172349595
              ],
              [
                -52.304536740714205,
                -26.440252346721213
              ],
              [
                -52.31376514821709,
                -26.44022735232834
              ],
              [
                -52.32299509172537,
                -26.44020137594875
              ],
              [
                -52.33597846629358,
                -26.442437946186452
              ],
              [
                -52.33799597446121,
                -26.439262928993003
              ],
              [
                -52.34143144533667,
                -26.433856960313
              ],
              [
                -52.34882589687772,
                -26.4384835008029
              ],
              [
                -52.35261784815368,
                -26.440856472186415
              ],
              [
                -52.35810130814428,
                -26.43752452973935
              ],
              [
                -52.36683776852536,
                -26.43221655441288
              ],
              [
                -52.37154173701834,
                -26.436135575815204
              ],
              [
                -52.37624616850524,
                -26.440054573227215
              ],
              [
                -52.38634361596249,
                -26.434611602153254
              ],
              [
                -52.39398505769432,
                -26.43049314895831
              ],
              [
                -52.400588559529346,
                -26.42268470150751
              ],
              [
                -52.4025380174187,
                -26.427743703374272
              ],
              [
                -52.41185189562192,
                -26.43249322527381
              ],
              [
                -52.4213543349452,
                -26.43733973553217
              ],
              [
                -52.44345764360924,
                -26.439463290443793
              ],
              [
                -52.44606414350223,
                -26.439448813697123
              ],
              [
                -52.458035554172305,
                -26.43938084920637
              ],
              [
                -52.46075652718784,
                -26.43936585486777
              ],
              [
                -52.46505599486936,
                -26.436396873009095
              ],
              [
                -52.46765546244939,
                -26.43279736625927
              ],
              [
                -52.476201977919054,
                -26.420961469130024
              ],
              [
                -52.48598440137385,
                -26.420252487050064
              ],
              [
                -52.495767816840775,
                -26.419542517980442
              ],
              [
                -52.518182720916904,
                -26.403636665440494
              ],
              [
                -52.522456675111634,
                -26.400603183696695
              ],
              [
                -52.523643147009324,
                -26.40081869416389
              ],
              [
                -52.538515545416836,
                -26.403514208657093
              ],
              [
                -52.55486139991671,
                -26.411157223826304
              ],
              [
                -52.55896188232409,
                -26.407472278644182
              ],
              [
                -52.564489833266904,
                -26.410085777438095
              ],
              [
                -52.57074576451128,
                -26.409047784020263
              ],
              [
                -52.57849173034643,
                -26.407761821698905
              ],
              [
                -52.590040589636175,
                -26.413176858432745
              ],
              [
                -52.59361756972893,
                -26.41485383448011
              ],
              [
                -52.59594407558883,
                -26.41443985609048
              ],
              [
                -52.601021008369074,
                -26.4135368610613
              ],
              [
                -52.61267444349873,
                -26.40964043328662
              ],
              [
                -52.61342344681399,
                -26.409390439770355
              ],
              [
                -52.61379845719612,
                -26.4086399306022
              ],
              [
                -52.620605952103794,
                -26.39501998915182
              ],
              [
                -52.63320433726047,
                -26.39060256844428
              ],
              [
                -52.63556287305932,
                -26.384872610914556
              ],
              [
                -52.64455731478848,
                -26.380506126289482
              ],
              [
                -52.64673781460089,
                -26.379448158529694
              ],
              [
                -52.65753821625881,
                -26.374204721212685
              ],
              [
                -52.67807816699371,
                -26.352190339295316
              ],
              [
                -52.69976096567244,
                -26.356431911769867
              ],
              [
                -52.708267924308835,
                -26.35457694602882
              ],
              [
                -52.71835032475831,
                -26.356872961021395
              ],
              [
                -52.72128333428623,
                -26.35295147046303
              ],
              [
                -52.725103805159215,
                -26.347843014998684
              ],
              [
                -52.737252238594834,
                -26.341681581483186
              ],
              [
                -52.741458691878464,
                -26.344984091349442
              ],
              [
                -52.751194134773115,
                -26.34413059461701
              ],
              [
                -52.7562005462005,
                -26.349432596666368
              ],
              [
                -52.75921403513267,
                -26.352624626699793
              ],
              [
                -52.76395197619127,
                -26.351141624397293
              ],
              [
                -52.76887495507163,
                -26.34960366793201
              ],
              [
                -52.77537094601762,
                -26.347573697668803
              ],
              [
                -52.78292936029014,
                -26.3497926740304
              ],
              [
                -52.790487781567855,
                -26.352011707399736
              ],
              [
                -52.81416463348978,
                -26.344284326887177
              ],
              [
                -52.84166040429438,
                -26.345939392736604
              ],
              [
                -52.84351441219068,
                -26.345453879913727
              ],
              [
                -52.84436642050036,
                -26.345030931334758
              ],
              [
                -52.84747838037077,
                -26.34348593648297
              ],
              [
                -52.8666316657309,
                -26.355573953238885
              ],
              [
                -52.87097664144414,
                -26.35831544055144
              ],
              [
                -52.874224106815795,
                -26.359169440232208
              ],
              [
                -52.87747206218687,
                -26.360022963917682
              ],
              [
                -52.907859375585915,
                -26.35904156291042
              ],
              [
                -52.91624931865939,
                -26.355071587801078
              ],
              [
                -52.926176223729875,
                -26.360310132683644
              ],
              [
                -52.9309996530454,
                -26.36070911247658
              ],
              [
                -52.936888640817685,
                -26.361196648759393
              ],
              [
                -52.94995699500548,
                -26.361827187050554
              ],
              [
                -52.985626782080594,
                -26.346957371750662
              ],
              [
                -52.987725259179754,
                -26.34715185855643
              ],
              [
                -52.989824274277424,
                -26.34734588336596
              ],
              [
                -53.005155578982745,
                -26.356273908342338
              ],
              [
                -53.02727986344106,
                -26.369156901490715
              ],
              [
                -53.0462987075842,
                -26.380225446682427
              ],
              [
                -53.07403243854727,
                -26.38641899199108
              ],
              [
                -53.09010082008684,
                -26.390009070397102
              ],
              [
                -53.09384079890689,
                -26.38263157492523
              ],
              [
                -53.1002457480542,
                -26.383533610763163
              ],
              [
                -53.10706670223116,
                -26.380972133449447
              ],
              [
                -53.108998729105316,
                -26.376272652747552
              ],
              [
                -53.12402860036608,
                -26.373778221431028
              ],
              [
                -53.132270581136964,
                -26.366793285680426
              ],
              [
                -53.13326008721083,
                -26.36368777838924
              ],
              [
                -53.134870610077506,
                -26.35863680269148
              ],
              [
                -53.140030067961696,
                -26.356759810209404
              ],
              [
                -53.14529157246172,
                -26.345212387818
              ],
              [
                -53.15302300752359,
                -26.343614934564002
              ],
              [
                -53.15749244869578,
                -26.347666914174177
              ],
              [
                -53.160521425570124,
                -26.350412930947837
              ],
              [
                -53.164699414909556,
                -26.349002945439768
              ],
              [
                -53.16875688099281,
                -26.347633461369657
              ],
              [
                -53.1839512739221,
                -26.343369018424873
              ],
              [
                -53.194936252603554,
                -26.331654576908612
              ],
              [
                -53.197082756288566,
                -26.32936559504834
              ],
              [
                -53.2009336847476,
                -26.329187138335634
              ],
              [
                -53.20478516120516,
                -26.329008611625785
              ],
              [
                -53.23072055489312,
                -26.312099800303333
              ],
              [
                -53.24549348272319,
                -26.297209374634
              ],
              [
                -53.24682296135366,
                -26.297496887420685
              ],
              [
                -53.25186544023068,
                -26.298587885783213
              ],
              [
                -53.253822435917684,
                -26.29114843611183
              ],
              [
                -53.254267949946644,
                -26.28945393824743
              ],
              [
                -53.25545294507922,
                -26.28919291931663
              ],
              [
                -53.26168492170897,
                -26.28781993897678
              ],
              [
                -53.260138905446716,
                -26.2825674676858
              ],
              [
                -53.25924895844193,
                -26.27954347643028
              ],
              [
                -53.27313392713593,
                -26.260283077907992
              ],
              [
                -53.27618990270793,
                -26.259873100111506
              ],
              [
                -53.279781847528575,
                -26.259390129459103
              ],
              [
                -53.28027786991411,
                -26.25409261758514
              ],
              [
                -53.28096939595848,
                -26.246700646276533
              ],
              [
                -53.28754434959635,
                -26.249543151315798
              ],
              [
                -53.299718730204155,
                -26.256162186892226
              ],
              [
                -53.30543366022133,
                -26.259269178032948
              ],
              [
                -53.31112861524045,
                -26.258752230972178
              ],
              [
                -53.31406363317718,
                -26.257101233811515
              ],
              [
                -53.31864161468126,
                -26.254526761514246
              ],
              [
                -53.331655986346234,
                -26.25396781461614
              ],
              [
                -53.33538646573646,
                -26.25380783240474
              ],
              [
                -53.335432457629004,
                -26.253295309244862
              ],
              [
                -53.335820486451325,
                -26.24900384266414
              ],
              [
                -53.34037095594455,
                -26.248084359851447
              ],
              [
                -53.34493292736509,
                -26.247162362087977
              ],
              [
                -53.34783937417459,
                -26.251081880604698
              ],
              [
                -53.35038985078302,
                -26.254520867431722
              ],
              [
                -53.3550023124833,
                -26.249791376298678
              ],
              [
                -53.3560558312161,
                -26.24871139857628
              ],
              [
                -53.35770530411144,
                -26.249304420503965
              ],
              [
                -53.3635312597725,
                -26.251396897387085
              ],
              [
                -53.36820273977298,
                -26.247413437021653
              ],
              [
                -53.37507521329758,
                -26.241552972617114
              ],
              [
                -53.37792265673524,
                -26.245662501317355
              ],
              [
                -53.379965149353495,
                -26.248610494953365
              ],
              [
                -53.38313560358803,
                -26.246720490406027
              ],
              [
                -53.38479260234777,
                -26.245732990526356
              ],
              [
                -53.38937754610468,
                -26.249501525532967
              ],
              [
                -53.42004974125186,
                -26.274710035536298
              ],
              [
                -53.43184962268761,
                -26.27817002831917
              ],
              [
                -53.440534071673476,
                -26.280503550679956
              ],
              [
                -53.460687340682334,
                -26.29471607733705
              ],
              [
                -53.47121978962834,
                -26.29023362047832
              ],
              [
                -53.47264928447958,
                -26.28962513455521
              ],
              [
                -53.48197767327749,
                -26.294087658347927
              ],
              [
                -53.48739563991177,
                -26.297129645810013
              ],
              [
                -53.49668304271939,
                -26.302344200046065
              ],
              [
                -53.521649884050895,
                -26.292290796796934
              ],
              [
                -53.54402921709561,
                -26.291327366840104
              ],
              [
                -53.55116766304084,
                -26.29226087831471
              ],
              [
                -53.55409366718184,
                -26.286971433897076
              ],
              [
                -53.55490013649629,
                -26.285513430499908
              ],
              [
                -53.56997806792034,
                -26.275424987022227
              ],
              [
                -53.571697581176,
                -26.275142025079173
              ],
              [
                -53.57984848951702,
                -26.273799543535198
              ],
              [
                -53.58158750868615,
                -26.26849609191709
              ],
              [
                -53.582961545720416,
                -26.26430508039505
              ],
              [
                -53.58602602158576,
                -26.254967614579083
              ],
              [
                -53.5955434383168,
                -26.25620316855149
              ],
              [
                -53.60160739636911,
                -26.2573826663825
              ],
              [
                -53.60722887944259,
                -26.257497188084102
              ],
              [
                -53.62014578255264,
                -26.25668422184187
              ],
              [
                -53.62264773568457,
                -26.258503746892135
              ],
              [
                -53.62402620388339,
                -26.259505740575104
              ],
              [
                -53.625149721818104,
                -26.260322751946145
              ],
              [
                -53.64256561490082,
                -26.252257829232576
              ],
              [
                -53.64289410523853,
                -26.248202335517536
              ],
              [
                -53.63713465798294,
                -26.249041329235265
              ],
              [
                -53.64079268342298,
                -26.244481847044
              ],
              [
                -53.643083639587964,
                -26.24142135512278
              ],
              [
                -53.64525814330073,
                -26.240030378385853
              ],
              [
                -53.64737915426395,
                -26.238674368344654
              ],
              [
                -53.65067113906202,
                -26.233522904759468
              ],
              [
                -53.648700645715095,
                -26.226960414955773
              ],
              [
                -53.64710467593535,
                -26.22418193925104
              ],
              [
                -53.64526770681037,
                -26.220983955046623
              ],
              [
                -53.64181578068491,
                -26.215088425818657
              ],
              [
                -53.644800254321396,
                -26.210952457858305
              ],
              [
                -53.649652234257154,
                -26.206731003835362
              ],
              [
                -53.65093925246695,
                -26.20561099705273
              ],
              [
                -53.650881255382814,
                -26.20093801798095
              ],
              [
                -53.6508797364906,
                -26.20091602504675
              ],
              [
                -53.650569770162356,
                -26.193535032854026
              ],
              [
                -53.65348577888135,
                -26.19292057524071
              ],
              [
                -53.65737576215952,
                -26.19210157176245
              ],
              [
                -53.66219173875211,
                -26.186422123331145
              ],
              [
                -53.665304209606674,
                -26.18292811574613
              ],
              [
                -53.66964021800306,
                -26.17811914778202
              ],
              [
                -53.671478222898735,
                -26.175666186280676
              ],
              [
                -53.67376571016049,
                -26.17212768189857
              ],
              [
                -53.67589020553032,
                -26.169073225367104
              ],
              [
                -53.67860371005379,
                -26.164055725296233
              ],
              [
                -53.68123767371117,
                -26.16257274548669
              ],
              [
                -53.68742163228439,
                -26.15934378298234
              ],
              [
                -53.69063213278445,
                -26.157362796839536
              ],
              [
                -53.69097464612932,
                -26.157151823743398
              ],
              [
                -53.691379634085166,
                -26.15692228892998
              ],
              [
                -53.69952757297067,
                -26.152302828924924
              ],
              [
                -53.703330109074884,
                -26.14482137671241
              ],
              [
                -53.70356459114262,
                -26.14440237390544
              ],
              [
                -53.705476592700315,
                -26.140984385792095
              ],
              [
                -53.70787406151282,
                -26.136443444047508
              ],
              [
                -53.715865049138806,
                -26.12620648568224
              ],
              [
                -53.71985453701228,
                -26.127608008342353
              ],
              [
                -53.72301552238906,
                -26.128718485998085
              ],
              [
                -53.7271539994096,
                -26.126398012252842
              ],
              [
                -53.73417744300181,
                -26.121958551644372
              ],
              [
                -53.737968916489514,
                -26.118298569001727
              ],
              [
                -53.740669926143745,
                -26.116477097519276
              ],
              [
                -53.7413779277626,
                -26.111911619833442
              ],
              [
                -53.741838922555054,
                -26.1081886295401
              ],
              [
                -53.73722399273302,
                -26.100057638479292
              ],
              [
                -53.73693352315045,
                -26.099545660106266
              ],
              [
                -53.73703753466353,
                -26.09879064192484
              ],
              [
                -53.737648014542295,
                -26.09432916956685
              ],
              [
                -53.73851155069596,
                -26.087304202428058
              ],
              [
                -53.73735858531756,
                -26.08249821892377
              ],
              [
                -53.73593211788769,
                -26.078174207887376
              ],
              [
                -53.73351212827991,
                -26.07491470986753
              ],
              [
                -53.73344060824,
                -26.074817720928262
              ],
              [
                -53.73145467637624,
                -26.070408707188392
              ],
              [
                -53.72626822171895,
                -26.066192720455255
              ],
              [
                -53.72715921807677,
                -26.061817721837027
              ],
              [
                -53.729144732645764,
                -26.056193280169143
              ],
              [
                -53.73081526179192,
                -26.051438300558246
              ],
              [
                -53.73281523162352,
                -26.045726323229964
              ],
              [
                -53.733712258788124,
                -26.04390982632106
              ],
              [
                -53.734311751566246,
                -26.042695827396866
              ],
              [
                -53.73888171697998,
                -26.040915831409396
              ],
              [
                -53.742853690904916,
                -26.03959183881585
              ],
              [
                -53.744912210031494,
                -26.039916390069532
              ],
              [
                -53.74664218385047,
                -26.04018938432462
              ],
              [
                -53.75152613822267,
                -26.03764940792132
              ],
              [
                -53.75535360089211,
                -26.04077238336367
              ],
              [
                -53.7554981381477,
                -26.03540792830914
              ],
              [
                -53.755536102003155,
                -26.033996399031473
              ],
              [
                -53.75878563815833,
                -26.030880431695522
              ],
              [
                -53.760767114348496,
                -26.033163922309296
              ],
              [
                -53.76243909541431,
                -26.035090420986027
              ],
              [
                -53.76705202879498,
                -26.03255946058886
              ],
              [
                -53.77049352091706,
                -26.03027098924353
              ],
              [
                -53.7711350344427,
                -26.025824507968174
              ],
              [
                -53.775314001216465,
                -26.022573538367123
              ],
              [
                -53.77831700641048,
                -26.01941602228711
              ],
              [
                -53.7769960251583,
                -26.017614061453887
              ],
              [
                -53.77576655167985,
                -26.015936044543743
              ],
              [
                -53.781730523015945,
                -26.012925071496245
              ],
              [
                -53.77971603085012,
                -26.008158075841884
              ],
              [
                -53.788733964388534,
                -26.006272108971828
              ],
              [
                -53.79182796999049,
                -26.00562512805381
              ],
              [
                -53.79234196706119,
                -26.002880661796784
              ],
              [
                -53.79315947891163,
                -25.998511681897767
              ],
              [
                -53.795558965070995,
                -25.99985666303947
              ],
              [
                -53.79745446823785,
                -26.000919146312285
              ],
              [
                -53.804077911476305,
                -25.995185697444946
              ],
              [
                -53.80264844485957,
                -25.993191200855343
              ],
              [
                -53.80004548014902,
                -25.989560209424344
              ],
              [
                -53.801964982027656,
                -25.98466124915989
              ],
              [
                -53.80523093242287,
                -25.984457749404548
              ],
              [
                -53.807944914147356,
                -25.98789523385695
              ],
              [
                -53.80821340985412,
                -25.987959754599625
              ],
              [
                -53.81305337154479,
                -25.989116273010854
              ],
              [
                -53.816535360602366,
                -25.989079272484304
              ],
              [
                -53.817250854935445,
                -25.985925784268677
              ],
              [
                -53.81746539562244,
                -25.981021791965965
              ],
              [
                -53.81498940003836,
                -25.980594273573683
              ],
              [
                -53.813933397744506,
                -25.980412274420015
              ],
              [
                -53.816615384209214,
                -25.977977822844966
              ],
              [
                -53.818443907048774,
                -25.97508781072386
              ],
              [
                -53.820068893290866,
                -25.974760332550858
              ],
              [
                -53.821555853450704,
                -25.97445985980061
              ],
              [
                -53.82583484472666,
                -25.97484133173578
              ],
              [
                -53.83353429345926,
                -25.970365906584973
              ],
              [
                -53.83325829954676,
                -25.96598342084859
              ],
              [
                -53.82954732050683,
                -25.963873376549987
              ],
              [
                -53.827227352612404,
                -25.96255439761351
              ],
              [
                -53.83221934160954,
                -25.95877391660153
              ],
              [
                -53.833131324772125,
                -25.958083433079302
              ],
              [
                -53.83554681586747,
                -25.955964423531068
              ],
              [
                -53.8387858208656,
                -25.953598458704384
              ],
              [
                -53.840103329464085,
                -25.944957493472316
              ],
              [
                -53.84150989670284,
                -25.932780032070283
              ],
              [
                -53.83736143642251,
                -25.93147552462241
              ],
              [
                -53.832729983476,
                -25.927697035520932
              ],
              [
                -53.83246249385922,
                -25.920680569421588
              ],
              [
                -53.828947508358596,
                -25.92433651407146
              ],
              [
                -53.82873700236377,
                -25.924468527896234
              ],
              [
                -53.8233380551092,
                -25.92786299372676
              ],
              [
                -53.822605065102294,
                -25.917351533375772
              ],
              [
                -53.82180859829642,
                -25.916374544740787
              ],
              [
                -53.81804014054781,
                -25.911747047488657
              ],
              [
                -53.82557059597457,
                -25.91062956477581
              ],
              [
                -53.82856804426162,
                -25.912077089665164
              ],
              [
                -53.83298601594433,
                -25.914210070350656
              ],
              [
                -53.83265802426851,
                -25.90984958639648
              ],
              [
                -53.83230254874899,
                -25.90688658479388
              ],
              [
                -53.82963258935799,
                -25.906051084070178
              ],
              [
                -53.82240812524685,
                -25.90378957688469
              ],
              [
                -53.819000185012825,
                -25.898414601359573
              ],
              [
                -53.823552676510836,
                -25.895269613716987
              ],
              [
                -53.831808623311744,
                -25.893736622913064
              ],
              [
                -53.83460457262213,
                -25.89321715249507
              ],
              [
                -53.837017612203326,
                -25.88728319537772
              ],
              [
                -53.846503525074944,
                -25.888235718817295
              ],
              [
                -53.848928524578085,
                -25.88368823121742
              ],
              [
                -53.840273596678514,
                -25.88153219264559
              ],
              [
                -53.8386851072903,
                -25.87766170065502
              ],
              [
                -53.834619669016554,
                -25.87782567774962
              ],
              [
                -53.833456651520464,
                -25.87787269548533
              ],
              [
                -53.829243702627814,
                -25.87681571004402
              ],
              [
                -53.826195245095676,
                -25.874351165314746
              ],
              [
                -53.82321575281494,
                -25.871940200657463
              ],
              [
                -53.835792668680504,
                -25.868691233703835
              ],
              [
                -53.83464217181678,
                -25.865326755621126
              ],
              [
                -53.83222224167133,
                -25.8586672648168
              ],
              [
                -53.837453196005676,
                -25.859944286124414
              ],
              [
                -53.837778723444096,
                -25.859973260179803
              ],
              [
                -53.84136268725577,
                -25.860289278809173
              ],
              [
                -53.843641162082875,
                -25.85730479560108
              ],
              [
                -53.83858769208245,
                -25.8554092829396
              ],
              [
                -53.83773673482778,
                -25.855090277975723
              ],
              [
                -53.8354602371359,
                -25.852709792710215
              ],
              [
                -53.834213785229835,
                -25.849982276218096
              ],
              [
                -53.83861377111485,
                -25.84399583531141
              ],
              [
                -53.84063523620192,
                -25.840930866461996
              ],
              [
                -53.841153217310755,
                -25.840934360279245
              ],
              [
                -53.84556719116971,
                -25.84096036677764
              ],
              [
                -53.848736174264005,
                -25.841469358303804
              ],
              [
                -53.84867620782974,
                -25.83564336712341
              ],
              [
                -53.846091247056236,
                -25.83172538680113
              ],
              [
                -53.84296080575666,
                -25.827715398859862
              ],
              [
                -53.837271328442036,
                -25.825135386220033
              ],
              [
                -53.83577734053949,
                -25.824457895164638
              ],
              [
                -53.836054373408835,
                -25.823310391894783
              ],
              [
                -53.837847381957125,
                -25.815887408498945
              ],
              [
                -53.83198943784071,
                -25.81424940219862
              ],
              [
                -53.82964995767389,
                -25.813708884717474
              ],
              [
                -53.82746043723251,
                -25.813203885649525
              ],
              [
                -53.820214006515805,
                -25.815980356009664
              ],
              [
                -53.81895100254469,
                -25.812361353404587
              ],
              [
                -53.82252651265171,
                -25.810765387235623
              ],
              [
                -53.82635397260526,
                -25.808767413270544
              ],
              [
                -53.82724597593522,
                -25.808301423940872
              ],
              [
                -53.825444524129864,
                -25.805578930503717
              ],
              [
                -53.82334902143737,
                -25.80241142749015
              ],
              [
                -53.821412052078955,
                -25.800052434390096
              ],
              [
                -53.82235109841372,
                -25.792060947880426
              ],
              [
                -53.83126799918825,
                -25.791480974198137
              ],
              [
                -53.83137000148948,
                -25.791466000606594
              ],
              [
                -53.84061744424232,
                -25.790086510710093
              ],
              [
                -53.83346751827246,
                -25.786470491361364
              ],
              [
                -53.833370009212466,
                -25.78642150917829
              ],
              [
                -53.830758562962785,
                -25.782183493865098
              ],
              [
                -53.838529507447134,
                -25.774127543635267
              ],
              [
                -53.84229999903894,
                -25.77021858172408
              ],
              [
                -53.84720297093916,
                -25.768690103998804
              ],
              [
                -53.85278296503926,
                -25.767101631853897
              ],
              [
                -53.85587792507352,
                -25.765897141699337
              ],
              [
                -53.85071099923563,
                -25.76065016069988
              ],
              [
                -53.84843899290668,
                -25.757928644622474
              ],
              [
                -53.845390013424705,
                -25.75845962714875
              ],
              [
                -53.843952537655774,
                -25.758813147929622
              ],
              [
                -53.84083105270535,
                -25.759580101427087
              ],
              [
                -53.841254117330976,
                -25.751707151736014
              ],
              [
                -53.83763361953928,
                -25.75144265886332
              ],
              [
                -53.833540674041075,
                -25.750302134203714
              ],
              [
                -53.84019160998226,
                -25.746414645359778
              ],
              [
                -53.84498312904776,
                -25.743176187842668
              ],
              [
                -53.84797007118444,
                -25.740735704357824
              ],
              [
                -53.85016658049082,
                -25.7412657283465
              ],
              [
                -53.85329602185906,
                -25.742020713880283
              ],
              [
                -53.8639069504647,
                -25.740875256972654
              ],
              [
                -53.86202453732295,
                -25.732530267717443
              ],
              [
                -53.860863060060346,
                -25.7294847646229
              ],
              [
                -53.85916055199134,
                -25.72430377663588
              ],
              [
                -53.85978009772749,
                -25.71838431724307
              ],
              [
                -53.86411654937519,
                -25.717672339826247
              ],
              [
                -53.868002018168525,
                -25.71888033252349
              ],
              [
                -53.867211544576264,
                -25.715262365617473
              ],
              [
                -53.87199701134013,
                -25.71140737609374
              ],
              [
                -53.87660798531731,
                -25.708040919351824
              ],
              [
                -53.86437862705076,
                -25.70468336939092
              ],
              [
                -53.865766622587394,
                -25.69746389297975
              ],
              [
                -53.86000314972324,
                -25.699548391882708
              ],
              [
                -53.85974915622939,
                -25.699639869689797
              ],
              [
                -53.85731469132534,
                -25.702932356319717
              ],
              [
                -53.85515566182484,
                -25.706116859272083
              ],
              [
                -53.84985572047856,
                -25.702134363735013
              ],
              [
                -53.85217175984403,
                -25.695434388890483
              ],
              [
                -53.853630760058,
                -25.690966381701475
              ],
              [
                -53.84901329973875,
                -25.6902233599287
              ],
              [
                -53.84179085175907,
                -25.68764035006317
              ],
              [
                -53.844749331727776,
                -25.685751360663442
              ],
              [
                -53.85263427506852,
                -25.682416908313055
              ],
              [
                -53.85379576122978,
                -25.681752926574447
              ],
              [
                -53.85721975001275,
                -25.679796943027597
              ],
              [
                -53.85550778517544,
                -25.67620093384453
              ],
              [
                -53.854385818629616,
                -25.673844453657903
              ],
              [
                -53.85770229887241,
                -25.666117991717197
              ],
              [
                -53.861473317181435,
                -25.66109103049023
              ],
              [
                -53.863471786383826,
                -25.65895405353387
              ],
              [
                -53.87173773122571,
                -25.655309082527513
              ],
              [
                -53.8744817275723,
                -25.654177075878838
              ],
              [
                -53.87485521644134,
                -25.65402310569576
              ],
              [
                -53.882967145264146,
                -25.657913090364303
              ],
              [
                -53.88788111369702,
                -25.65975862054199
              ],
              [
                -53.88512562757533,
                -25.65453659804215
              ],
              [
                -53.881436714977525,
                -25.647777610332117
              ],
              [
                -53.880546219355374,
                -25.647138121312395
              ],
              [
                -53.87823824766087,
                -25.645480611670923
              ],
              [
                -53.877961243459524,
                -25.640389640454504
              ],
              [
                -53.88388620297595,
                -25.64326115478409
              ],
              [
                -53.88582865845595,
                -25.644203158498065
              ],
              [
                -53.89443410741445,
                -25.642286665988514
              ],
              [
                -53.892565161462,
                -25.637812185152328
              ],
              [
                -53.890915693626454,
                -25.6338626863633
              ],
              [
                -53.889228201905226,
                -25.629483194860423
              ],
              [
                -53.88747024297962,
                -25.62550823778338
              ],
              [
                -53.892053241324554,
                -25.622098257075184
              ],
              [
                -53.90090561440965,
                -25.628396763379342
              ],
              [
                -53.90485556842638,
                -25.631329777581495
              ],
              [
                -53.906576570135705,
                -25.632607265415366
              ],
              [
                -53.91191254081648,
                -25.635539278489944
              ],
              [
                -53.91271801252026,
                -25.632561293107152
              ],
              [
                -53.914374014745086,
                -25.62938030137572
              ],
              [
                -53.91880499371465,
                -25.63125079376702
              ],
              [
                -53.9172830242675,
                -25.62628282076411
              ],
              [
                -53.91705102622153,
                -25.62552730843512
              ],
              [
                -53.913967064248524,
                -25.621770808976716
              ],
              [
                -53.91541355913774,
                -25.615985335081966
              ],
              [
                -53.920737501552516,
                -25.617599366436973
              ],
              [
                -53.923740470982324,
                -25.618813336973208
              ],
              [
                -53.926757978143996,
                -25.62006488343849
              ],
              [
                -53.93271443035172,
                -25.62179538063813
              ],
              [
                -53.93839089591047,
                -25.61994989961307
              ],
              [
                -53.94148839457612,
                -25.61438243677621
              ],
              [
                -53.94195140177913,
                -25.61141596215172
              ],
              [
                -53.94723833079123,
                -25.611318966009218
              ],
              [
                -53.94787080072151,
                -25.61937142276469
              ],
              [
                -53.952148738161725,
                -25.623044954703765
              ],
              [
                -53.94920028437566,
                -25.62603393554164
              ],
              [
                -53.947157279186634,
                -25.628239386129575
              ],
              [
                -53.946391767367665,
                -25.629064909732136
              ],
              [
                -53.944032805472446,
                -25.630816372702085
              ],
              [
                -53.94661224473387,
                -25.635385372749475
              ],
              [
                -53.9493392234553,
                -25.643098849000054
              ],
              [
                -53.95077469544172,
                -25.646682340280094
              ],
              [
                -53.95601864556611,
                -25.64499538922853
              ],
              [
                -53.96027164381379,
                -25.638924416080673
              ],
              [
                -53.96111762937555,
                -25.63567893970045
              ],
              [
                -53.960067181407275,
                -25.630246423843364
              ],
              [
                -53.96078368904943,
                -25.62580146094991
              ],
              [
                -53.962662672748536,
                -25.619547497077367
              ],
              [
                -53.964343223236355,
                -25.61345352498613
              ],
              [
                -53.97415861160034,
                -25.614513035940274
              ],
              [
                -53.97654461444563,
                -25.611322557785684
              ],
              [
                -53.97620165045128,
                -25.60512309493951
              ],
              [
                -53.97272420562032,
                -25.603887549801637
              ],
              [
                -53.97055822003671,
                -25.60311756873509
              ],
              [
                -53.96996971542547,
                -25.599833587460655
              ],
              [
                -53.97527868360915,
                -25.59614661818902
              ],
              [
                -53.98440616066986,
                -25.58980813501408
              ],
              [
                -53.98757312266088,
                -25.587798164719835
              ],
              [
                -53.99020514031944,
                -25.585627710076984
              ],
              [
                -54.0002560480499,
                -25.57987023322272
              ],
              [
                -54.00785854610125,
                -25.569162804041977
              ],
              [
                -54.01044853804756,
                -25.566529802768112
              ],
              [
                -54.01882646964816,
                -25.563965367550146
              ],
              [
                -54.02291943481381,
                -25.564996357507553
              ],
              [
                -54.028732398959974,
                -25.567254880465608
              ],
              [
                -54.030642876957096,
                -25.56978835284625
              ],
              [
                -54.03239736209562,
                -25.572325862669057
              ],
              [
                -54.03380533790031,
                -25.574372859888555
              ],
              [
                -54.035592329538595,
                -25.576970346632585
              ],
              [
                -54.04034327078294,
                -25.583167842968482
              ],
              [
                -54.045993715151575,
                -25.584250361241043
              ],
              [
                -54.04961866409577,
                -25.58450936610951
              ],
              [
                -54.056836642627346,
                -25.579307411482453
              ],
              [
                -54.059348646505065,
                -25.577302923864533
              ],
              [
                -54.06015964781237,
                -25.576051954809635
              ],
              [
                -54.06188412533455,
                -25.573392971574016
              ],
              [
                -54.07028113044767,
                -25.56013652647959
              ],
              [
                -54.07879304866573,
                -25.55862206929123
              ],
              [
                -54.080549033389964,
                -25.561961070506044
              ],
              [
                -54.084128974187465,
                -25.572188530558012
              ],
              [
                -54.08548894873288,
                -25.575787521544157
              ],
              [
                -54.08676339613512,
                -25.579099499172923
              ],
              [
                -54.08876139103538,
                -25.584315008114068
              ],
              [
                -54.08876338489035,
                -25.587859502521656
              ],
              [
                -54.08876385940762,
                -25.588206001389462
              ],
              [
                -54.08876633797074,
                -25.590880461647263
              ],
              [
                -54.088776795232214,
                -25.603443941589788
              ],
              [
                -54.08927226844217,
                -25.611191408000717
              ],
              [
                -54.093733233397074,
                -25.616080410699503
              ],
              [
                -54.098310682086726,
                -25.618255943681273
              ],
              [
                -54.102123670391194,
                -25.613665450080013
              ],
              [
                -54.10483413520508,
                -25.610169464027543
              ],
              [
                -54.110201142484286,
                -25.60241651821858
              ],
              [
                -54.11855368126903,
                -25.581627623504804
              ],
              [
                -54.1226316900089,
                -25.57084816907018
              ],
              [
                -54.12255622162963,
                -25.56591216594974
              ],
              [
                -54.12203624895123,
                -25.559942718654472
              ],
              [
                -54.12035675398304,
                -25.55629219470769
              ],
              [
                -54.11883576807207,
                -25.55305521696677
              ],
              [
                -54.11497284122268,
                -25.547311710222516
              ],
              [
                -54.1089933852768,
                -25.539173223916023
              ],
              [
                -54.105178470701794,
                -25.533981718558
              ],
              [
                -54.09739757556881,
                -25.52291375558882
              ],
              [
                -54.096024071633096,
                -25.51871373455773
              ],
              [
                -54.092647191012084,
                -25.500294317202734
              ],
              [
                -54.09461719133198,
                -25.495836321739645
              ],
              [
                -54.09916265255649,
                -25.494904352707426
              ],
              [
                -54.10461063285776,
                -25.4949678685649
              ],
              [
                -54.10912909043857,
                -25.495648389144954
              ],
              [
                -54.12800538127257,
                -25.50885037292622
              ],
              [
                -54.130961346107256,
                -25.511022386160374
              ],
              [
                -54.14353123158209,
                -25.52025690094969
              ],
              [
                -54.14742415220201,
                -25.524839408590772
              ],
              [
                -54.149458145167685,
                -25.527410887304146
              ],
              [
                -54.1522621233646,
                -25.530934873603012
              ],
              [
                -54.15720754013104,
                -25.536934390320035
              ],
              [
                -54.161315024161006,
                -25.540574402825673
              ],
              [
                -54.165165501703065,
                -25.54078789163746
              ],
              [
                -54.16930093301517,
                -25.54079492112495
              ],
              [
                -54.17296643377504,
                -25.540810927934963
              ],
              [
                -54.17429342629651,
                -25.540257921398897
              ],
              [
                -54.186321359532634,
                -25.535243484992186
              ],
              [
                -54.190358294069796,
                -25.533730499101424
              ],
              [
                -54.19798276006213,
                -25.53525952986395
              ],
              [
                -54.206121146428885,
                -25.542374545202787
              ],
              [
                -54.206956641743446,
                -25.545538026812213
              ],
              [
                -54.20741362490197,
                -25.548691530127023
              ],
              [
                -54.20685461620569,
                -25.55333947799995
              ],
              [
                -54.20365262739405,
                -25.5576809785903
              ],
              [
                -54.197855646360175,
                -25.55955143712498
              ],
              [
                -54.19567767255743,
                -25.560240443226366
              ],
              [
                -54.189659706030504,
                -25.56214342487789
              ],
              [
                -54.17971625731635,
                -25.56528586069487
              ],
              [
                -54.17326279208995,
                -25.573732828455597
              ],
              [
                -54.172993252087075,
                -25.580287794119226
              ],
              [
                -54.17544773230121,
                -25.582531287426207
              ],
              [
                -54.177976698126805,
                -25.584268303649267
              ],
              [
                -54.18116870337948,
                -25.584053296570296
              ],
              [
                -54.18861715735839,
                -25.583071339954703
              ],
              [
                -54.19401309085621,
                -25.58225386858839
              ],
              [
                -54.199270084429365,
                -25.58142687676504
              ],
              [
                -54.202659530469106,
                -25.579050394425977
              ],
              [
                -54.204509526566554,
                -25.57769191735676
              ],
              [
                -54.20899400040558,
                -25.57440045384774
              ],
              [
                -54.22307745460851,
                -25.56397900431042
              ],
              [
                -54.2300214250963,
                -25.56236103998794
              ],
              [
                -54.23407536829589,
                -25.563948531066053
              ],
              [
                -54.23684033250549,
                -25.56987304749304
              ],
              [
                -54.233539821209746,
                -25.578933979388648
              ],
              [
                -54.23179080362912,
                -25.58373549060863
              ],
              [
                -54.23511573239163,
                -25.59299496817875
              ],
              [
                -54.241332685066936,
                -25.59726694716527
              ],
              [
                -54.24476465217303,
                -25.597145454640348
              ],
              [
                -54.2494365985918,
                -25.596675503613437
              ],
              [
                -54.25788909945757,
                -25.58839553527898
              ],
              [
                -54.26327457551005,
                -25.582807087398084
              ],
              [
                -54.27208854691074,
                -25.56629516909342
              ],
              [
                -54.27571955613149,
                -25.55949167699029
              ],
              [
                -54.280071031279206,
                -25.5563712434417
              ],
              [
                -54.28582952212347,
                -25.556503239263133
              ],
              [
                -54.29433444833573,
                -25.557413755215737
              ],
              [
                -54.29443493241091,
                -25.55745627143093
              ],
              [
                -54.30379983996446,
                -25.56147977430117
              ],
              [
                -54.32777464014012,
                -25.571672825593414
              ],
              [
                -54.32778412814479,
                -25.571655347683507
              ],
              [
                -54.332363538936185,
                -25.581083816232905
              ],
              [
                -54.33754698491947,
                -25.591754276911498
              ],
              [
                -54.3400679598353,
                -25.59652679233597
              ],
              [
                -54.3424808994209,
                -25.601094759048575
              ],
              [
                -54.34588486453091,
                -25.606862784377427
              ],
              [
                -54.35147482999292,
                -25.60715027915981
              ],
              [
                -54.3548488015192,
                -25.607044315401623
              ],
              [
                -54.35928175632751,
                -25.603184328524133
              ],
              [
                -54.36277125458098,
                -25.600146348658917
              ],
              [
                -54.366113752450815,
                -25.596984877674135
              ],
              [
                -54.36991472729792,
                -25.593738903578565
              ],
              [
                -54.37814616576992,
                -25.594378901532956
              ],
              [
                -54.38633208002476,
                -25.60191042105317
              ],
              [
                -54.38874755033608,
                -25.604906420892252
              ],
              [
                -54.38821653821065,
                -25.612372397895967
              ],
              [
                -54.38759998691807,
                -25.61792585178149
              ],
              [
                -54.38749651121241,
                -25.61943235855071
              ],
              [
                -54.387327972947205,
                -25.621879356053416
              ],
              [
                -54.390579440389125,
                -25.628990330575956
              ],
              [
                -54.39314390559993,
                -25.634075309222474
              ],
              [
                -54.39752936233082,
                -25.637771329790088
              ],
              [
                -54.41272769785926,
                -25.648993839320624
              ],
              [
                -54.41850661847386,
                -25.653345841732413
              ],
              [
                -54.42202659973809,
                -25.657934827402787
              ],
              [
                -54.42355107289479,
                -25.66225231789598
              ],
              [
                -54.42393450931395,
                -25.67078380579686
              ],
              [
                -54.42312950029505,
                -25.67866429659636
              ],
              [
                -54.42255247594155,
                -25.683909763683616
              ],
              [
                -54.42223844579144,
                -25.68819275479982
              ],
              [
                -54.42545344671217,
                -25.69151872249135
              ],
              [
                -54.42909290559627,
                -25.69482175476485
              ],
              [
                -54.43289185365921,
                -25.693406739766658
              ],
              [
                -54.43667487025446,
                -25.690041765976886
              ],
              [
                -54.440110828913305,
                -25.682618827997267
              ],
              [
                -54.441283382740174,
                -25.669749372518222
              ],
              [
                -54.443969415684265,
                -25.662377889728774
              ],
              [
                -54.44718687494618,
                -25.658431935803854
              ],
              [
                -54.46087186147663,
                -25.643229521112154
              ],
              [
                -54.46485331267153,
                -25.63891955805721
              ],
              [
                -54.46707580764279,
                -25.636513064658114
              ],
              [
                -54.48309376204176,
                -25.62548213277924
              ],
              [
                -54.499891182571794,
                -25.614023737015653
              ],
              [
                -54.50435963792471,
                -25.616752250039433
              ],
              [
                -54.5129800667524,
                -25.622257772827467
              ],
              [
                -54.51350152653968,
                -25.622576761646812
              ],
              [
                -54.51751451244695,
                -25.625026272971734
              ],
              [
                -54.52200497753666,
                -25.627081285256878
              ],
              [
                -54.525552910054586,
                -25.627164768540933
              ],
              [
                -54.52990489704138,
                -25.623906323399662
              ],
              [
                -54.53291591740586,
                -25.62099530139803
              ],
              [
                -54.53635589351599,
                -25.61740985807856
              ],
              [
                -54.534339454926666,
                -25.60844487873865
              ],
              [
                -54.5337524284787,
                -25.605833399052568
              ],
              [
                -54.53345097646839,
                -25.599839887245363
              ],
              [
                -54.537126461553115,
                -25.59701743930768
              ],
              [
                -54.540338448522036,
                -25.59455045758867
              ],
              [
                -54.54998690021269,
                -25.5895069998967
              ],
              [
                -54.55535536521473,
                -25.586833030461783
              ],
              [
                -54.56570626051069,
                -25.590457046410307
              ],
              [
                -54.57401621715182,
                -25.590748534854463
              ],
              [
                -54.579943683623895,
                -25.591201585359464
              ],
              [
                -54.5827796491494,
                -25.59134710692093
              ],
              [
                -54.5894710934891,
                -25.591689602486056
              ],
              [
                -54.59307908843052,
                -25.591815111844
              ],
              [
                -54.59306157634911,
                -25.58302663297384
              ],
              [
                -54.59319765429241,
                -25.563024216659823
              ],
              [
                -54.59322971462011,
                -25.5580022389019
              ],
              [
                -54.59309371379474,
                -25.553768261022416
              ],
              [
                -54.59298419803328,
                -25.552735225954457
              ],
              [
                -54.59207976033604,
                -25.544192281213096
              ],
              [
                -54.59714226627124,
                -25.531571843654692
              ],
              [
                -54.59863477025848,
                -25.527851336885018
              ],
              [
                -54.599716798743536,
                -25.521450864287083
              ],
              [
                -54.59988587180924,
                -25.505036926696498
              ],
              [
                -54.60021740501054,
                -25.499048455143203
              ],
              [
                -54.600258906467566,
                -25.495832942642764
              ],
              [
                -54.60032790704514,
                -25.490437970259894
              ],
              [
                -54.600942435688204,
                -25.483622012381122
              ],
              [
                -54.61002490676407,
                -25.47526155931357
              ],
              [
                -54.61042087641381,
                -25.47489757788111
              ],
              [
                -54.61666337029673,
                -25.468886122237258
              ],
              [
                -54.61766087378334,
                -25.46331563669908
              ],
              [
                -54.61834088440509,
                -25.45638464143129
              ],
              [
                -54.61732594733213,
                -25.45252614329685
              ],
              [
                -54.60739303779598,
                -25.445833143924265
              ],
              [
                -54.605849541557596,
                -25.444325630357156
              ],
              [
                -54.60193460560576,
                -25.440502145922927
              ],
              [
                -54.5992991397458,
                -25.433986165689063
              ],
              [
                -54.59810665168265,
                -25.43066016623624
              ],
              [
                -54.59629519360914,
                -25.425117668770273
              ],
              [
                -54.589504312782225,
                -25.41283721259314
              ],
              [
                -54.58692583981963,
                -25.403418209997316
              ],
              [
                -54.586844381374696,
                -25.400139741317762
              ],
              [
                -54.58676885689855,
                -25.397092728911716
              ],
              [
                -54.578721480094174,
                -25.38483876133114
              ],
              [
                -54.571874054908314,
                -25.37671126863547
              ],
              [
                -54.57111555865813,
                -25.37572727616363
              ],
              [
                -54.57043710837623,
                -25.374847749634327
              ],
              [
                -54.5679746170227,
                -25.371654262348947
              ],
              [
                -54.564269682184324,
                -25.367190293830653
              ],
              [
                -54.55083880665267,
                -25.356624259032202
              ],
              [
                -54.54879581771567,
                -25.35496024427524
              ],
              [
                -54.54121092462265,
                -25.34878277676377
              ],
              [
                -54.53870143863961,
                -25.34510774791429
              ],
              [
                -54.531901530740704,
                -25.33516328643002
              ],
              [
                -54.52653211073442,
                -25.327715786860693
              ],
              [
                -54.52494812972806,
                -25.325506273507166
              ],
              [
                -54.52463860683338,
                -25.325073777831463
              ],
              [
                -54.517546723743465,
                -25.31496130194197
              ],
              [
                -54.51066278985021,
                -25.310107805678523
              ],
              [
                -54.50659684285153,
                -25.3072402788072
              ],
              [
                -54.502200392605864,
                -25.300985805828194
              ],
              [
                -54.50219889862958,
                -25.30098279183279
              ],
              [
                -54.50073192223042,
                -25.298896288509713
              ],
              [
                -54.49030308742653,
                -25.275094339759594
              ],
              [
                -54.4895955762156,
                -25.271164849124027
              ],
              [
                -54.48828063134815,
                -25.264419392572325
              ],
              [
                -54.48818712975906,
                -25.264010866580964
              ],
              [
                -54.485998675189826,
                -25.254445885208764
              ],
              [
                -54.4852516804977,
                -25.251168886317796
              ],
              [
                -54.48513019063351,
                -25.250636901633623
              ],
              [
                -54.48121229333079,
                -25.23262446890214
              ],
              [
                -54.47022844699796,
                -25.211725494089503
              ],
              [
                -54.468664515779224,
                -25.20737699389844
              ],
              [
                -54.46623903306023,
                -25.20132202830928
              ],
              [
                -54.461600625105724,
                -25.193240519679147
              ],
              [
                -54.45575570600354,
                -25.183967548784892
              ],
              [
                -54.44755278562438,
                -25.17340355896419
              ],
              [
                -54.442934852059146,
                -25.171615528790234
              ],
              [
                -54.44192081844385,
                -25.171393048997388
              ],
              [
                -54.4369438616092,
                -25.170298532300137
              ],
              [
                -54.42961249032629,
                -25.158893024343424
              ],
              [
                -54.42986750935095,
                -25.154146560847582
              ],
              [
                -54.43119254546276,
                -25.136521125471294
              ],
              [
                -54.43226605964187,
                -25.130773637126126
              ],
              [
                -54.44019004170929,
                -25.120912183100145
              ],
              [
                -54.44078602160196,
                -25.1203696949545
              ],
              [
                -54.45240600052629,
                -25.10979128410419
              ],
              [
                -54.454200005999496,
                -25.10724628870956
              ],
              [
                -54.455678003319804,
                -25.104814282846903
              ],
              [
                -54.458975984476595,
                -25.09826933984592
              ],
              [
                -54.460600015468486,
                -25.09446286601916
              ],
              [
                -54.45811053608123,
                -25.083852903366456
              ],
              [
                -54.457152574657584,
                -25.07941339068778
              ],
              [
                -54.456826074375186,
                -25.077899909548552
              ],
              [
                -54.4566026142779,
                -25.071849935732114
              ],
              [
                -54.45806910517592,
                -25.068577447607726
              ],
              [
                -54.46152112498035,
                -25.060876956970464
              ],
              [
                -54.460242157403066,
                -25.049601001766213
              ],
              [
                -54.45936419779201,
                -25.04217853524147
              ],
              [
                -54.45868424632797,
                -25.03844852821119
              ],
              [
                -54.45656778561223,
                -25.03178754490167
              ],
              [
                -54.45325381696468,
                -25.02169409681238
              ],
              [
                -54.45167985656099,
                -25.017599068915594
              ],
              [
                -54.45021587953444,
                -25.01369911575765
              ],
              [
                -54.44197151898233,
                -24.989903672062105
              ],
              [
                -54.43995764116546,
                -24.962593723644602
              ],
              [
                -54.441246660735594,
                -24.953668778697953
              ],
              [
                -54.44143566678232,
                -24.952358803696207
              ],
              [
                -54.44129719635722,
                -24.94860679567024
              ],
              [
                -54.436286747321326,
                -24.939629817206853
              ],
              [
                -54.43048783190003,
                -24.92924031774622
              ],
              [
                -54.42891238310078,
                -24.92135635852571
              ],
              [
                -54.42838739282776,
                -24.918728868453762
              ],
              [
                -54.425866446116714,
                -24.91297838490001
              ],
              [
                -54.42104801392472,
                -24.901986879891815
              ],
              [
                -54.41779208106716,
                -24.89394139147753
              ],
              [
                -54.415540640192766,
                -24.88193343673299
              ],
              [
                -54.41451816922294,
                -24.874781942050355
              ],
              [
                -54.41361520691009,
                -24.86846749899678
              ],
              [
                -54.411906736901614,
                -24.85963052059739
              ],
              [
                -54.4117797726048,
                -24.858958503402878
              ],
              [
                -54.410010305109644,
                -24.84961553149901
              ],
              [
                -54.408776333511064,
                -24.842009052652177
              ],
              [
                -54.40136848909483,
                -24.82519407140507
              ],
              [
                -54.39951650397499,
                -24.820991579100767
              ],
              [
                -54.39894949911465,
                -24.81735109532655
              ],
              [
                -54.397427570492745,
                -24.80757463480232
              ],
              [
                -54.39371210438469,
                -24.800364631194924
              ],
              [
                -54.39166063578775,
                -24.796384674488362
              ],
              [
                -54.391143678591085,
                -24.79321818231726
              ],
              [
                -54.38966723057049,
                -24.784174180544518
              ],
              [
                -54.381742298369915,
                -24.76792923286052
              ],
              [
                -54.37532291245344,
                -24.76280870307704
              ],
              [
                -54.36890346053405,
                -24.75768821830749
              ],
              [
                -54.358973594963665,
                -24.745099242635234
              ],
              [
                -54.35731108980177,
                -24.74274522286278
              ],
              [
                -54.35607860653135,
                -24.738670255342882
              ],
              [
                -54.35511216869036,
                -24.735478269767977
              ],
              [
                -54.34901979629084,
                -24.713258827684054
              ],
              [
                -54.340879402933226,
                -24.69835083510114
              ],
              [
                -54.33740793345115,
                -24.69172733257722
              ],
              [
                -54.324883167970874,
                -24.660685425590728
              ],
              [
                -54.3245936458807,
                -24.656811929679616
              ],
              [
                -54.32358321095826,
                -24.651003437851347
              ],
              [
                -54.32250623117355,
                -24.63840196875001
              ],
              [
                -54.32047832895879,
                -24.625153546629797
              ],
              [
                -54.320451814099165,
                -24.62492100832579
              ],
              [
                -54.32002384869955,
                -24.621119535724095
              ],
              [
                -54.320023322731835,
                -24.621112543745937
              ],
              [
                -54.31930237105646,
                -24.61470907595151
              ],
              [
                -54.316996473956735,
                -24.59009665441499
              ],
              [
                -54.31927753389052,
                -24.574656195453485
              ],
              [
                -54.319963517333406,
                -24.57108819686975
              ],
              [
                -54.32761254686947,
                -24.550216789548802
              ],
              [
                -54.33325206238432,
                -24.5354413577657
              ],
              [
                -54.33519008238598,
                -24.52970988777292
              ],
              [
                -54.335555557747064,
                -24.52574690145595
              ],
              [
                -54.335871072187565,
                -24.521112450250268
              ],
              [
                -54.33611762846194,
                -24.512933966843846
              ],
              [
                -54.33633562241194,
                -24.505873473553347
              ],
              [
                -54.33563118994384,
                -24.499348505329593
              ],
              [
                -54.32957278472919,
                -24.479438580504066
              ],
              [
                -54.32875079386309,
                -24.476685051088996
              ],
              [
                -54.32679383673756,
                -24.47035510100575
              ],
              [
                -54.32248693872784,
                -24.45784612602537
              ],
              [
                -54.30066219008805,
                -24.431408126452997
              ],
              [
                -54.296263225118764,
                -24.42623814925508
              ],
              [
                -54.295493239400685,
                -24.42533316674678
              ],
              [
                -54.289832313301716,
                -24.417768139484203
              ],
              [
                -54.28880083104489,
                -24.416229673260496
              ],
              [
                -54.2872263340145,
                -24.41388315996712
              ],
              [
                -54.284955391265555,
                -24.410496677881202
              ],
              [
                -54.28104192342932,
                -24.404651164668373
              ],
              [
                -54.275061515947776,
                -24.39277421712985
              ],
              [
                -54.272641573556655,
                -24.38906820465838
              ],
              [
                -54.27064707173467,
                -24.384691702917372
              ],
              [
                -54.26858712618158,
                -24.38086521207898
              ],
              [
                -54.26380919439569,
                -24.372337249197805
              ],
              [
                -54.2590722354232,
                -24.366521726184228
              ],
              [
                -54.25857875159825,
                -24.365134726276338
              ],
              [
                -54.257592275947175,
                -24.362359757466912
              ],
              [
                -54.25787927907247,
                -24.35681075944651
              ],
              [
                -54.25994331632348,
                -24.34722680623347
              ],
              [
                -54.263966341028876,
                -24.329937864033827
              ],
              [
                -54.26481932040556,
                -24.326280886442728
              ],
              [
                -54.269411380752125,
                -24.305518983104957
              ],
              [
                -54.27110688808816,
                -24.30257650989507
              ],
              [
                -54.28452185136411,
                -24.281160129425817
              ],
              [
                -54.28996983749854,
                -24.276645674195287
              ],
              [
                -54.295417831637984,
                -24.27213070196461
              ],
              [
                -54.31749871495061,
                -24.25436234098837
              ],
              [
                -54.322727189205516,
                -24.249472354299726
              ],
              [
                -54.32435171012726,
                -24.243499858253767
              ],
              [
                -54.324736749311,
                -24.23245390649087
              ],
              [
                -54.32432526858091,
                -24.226611439191636
              ],
              [
                -54.32360628751324,
                -24.21639048892334
              ],
              [
                -54.32420529198699,
                -24.212405504631484
              ],
              [
                -54.32627130145547,
                -24.208742007143186
              ],
              [
                -54.32783232804678,
                -24.205975503880556
              ],
              [
                -54.32790933634446,
                -24.199471033513646
              ],
              [
                -54.328000877360665,
                -24.191750068387798
              ],
              [
                -54.3290038516838,
                -24.188817068835412
              ],
              [
                -54.334546392266326,
                -24.17620665477027
              ],
              [
                -54.335630864460775,
                -24.1736581441686
              ],
              [
                -54.3363148548447,
                -24.172050676988853
              ],
              [
                -54.339896377095165,
                -24.164387200343857
              ],
              [
                -54.34235539415235,
                -24.15833323140808
              ],
              [
                -54.343536923919935,
                -24.14461578561472
              ],
              [
                -54.34469690843666,
                -24.13957281686725
              ],
              [
                -54.340947519807706,
                -24.128721843769885
              ],
              [
                -54.33512608024031,
                -24.118134359882745
              ],
              [
                -54.33286258937642,
                -24.11570735171319
              ],
              [
                -54.329855621474714,
                -24.112387344151163
              ],
              [
                -54.32203073699959,
                -24.105720360100122
              ],
              [
                -54.31111181674892,
                -24.099147843440342
              ],
              [
                -54.30727336959836,
                -24.09691983136037
              ],
              [
                -54.30427987833806,
                -24.09518184140262
              ],
              [
                -54.301347438301036,
                -24.091249329241673
              ],
              [
                -54.29513449898067,
                -24.082315351478258
              ],
              [
                -54.28988506462265,
                -24.074136378332394
              ],
              [
                -54.287103586201894,
                -24.06950989510664
              ],
              [
                -54.27439124752738,
                -24.06128987433037
              ],
              [
                -54.250257927001336,
                -24.05493782117491
              ],
              [
                -54.24632597783314,
                -24.05390282370715
              ],
              [
                -54.24115154056001,
                -24.0508857839979
              ],
              [
                -54.16789575565766,
                -23.99872825467643
              ],
              [
                -54.12917610530254,
                -23.981628231307027
              ],
              [
                -54.10985932641459,
                -23.960792223394904
              ],
              [
                -54.104591359973504,
                -23.953389254935104
              ],
              [
                -54.09932344852034,
                -23.94598624949477
              ],
              [
                -54.07937429699055,
                -23.888498912606554
              ],
              [
                -54.083765346398806,
                -23.86470698876546
              ],
              [
                -54.08381386607591,
                -23.864441478859582
              ],
              [
                -54.08757489278235,
                -23.844050072024903
              ],
              [
                -54.084045471413816,
                -23.834581595785657
              ],
              [
                -54.08256848761998,
                -23.830618607900803
              ],
              [
                -54.084100501310964,
                -23.827549151752592
              ],
              [
                -54.08553099296858,
                -23.82467964656634
              ],
              [
                -54.08310707702515,
                -23.80500721399023
              ],
              [
                -54.07517929084712,
                -23.764003845813836
              ],
              [
                -54.052899103751415,
                -23.724463402849437
              ],
              [
                -54.050362235402474,
                -23.68681702507821
              ],
              [
                -54.04407734414397,
                -23.668176073766077
              ],
              [
                -54.0317035080303,
                -23.651192595795127
              ],
              [
                -54.0307255421695,
                -23.64338615238019
              ],
              [
                -54.02880958787941,
                -23.628095211566286
              ],
              [
                -54.02348880265425,
                -23.585597815716678
              ],
              [
                -54.00681955664677,
                -23.544268953188016
              ],
              [
                -54.00381219104168,
                -23.510602561896132
              ],
              [
                -53.982333047555855,
                -23.459526652530098
              ],
              [
                -53.97686660085475,
                -23.453395688883855
              ],
              [
                -53.96524071575225,
                -23.44502317641701
              ],
              [
                -53.96408071450798,
                -23.44469068686629
              ],
              [
                -53.96056024511818,
                -23.4436811511279
              ],
              [
                -53.93152595302562,
                -23.4454705669555
              ],
              [
                -53.8760679737049,
                -23.419308024418125
              ],
              [
                -53.81549307023475,
                -23.385165975946997
              ],
              [
                -53.775728392502046,
                -23.372118385504432
              ],
              [
                -53.770580953087475,
                -23.3716508596737
              ],
              [
                -53.76874644085652,
                -23.369185384735598
              ],
              [
                -53.761375055336686,
                -23.359277909063962
              ],
              [
                -53.73009843531702,
                -23.317225465822087
              ],
              [
                -53.71463213750076,
                -23.282697557264502
              ],
              [
                -53.711024721244904,
                -23.273292076078008
              ],
              [
                -53.70741774196752,
                -23.26388609391678
              ],
              [
                -53.68490664670838,
                -23.205187257935727
              ],
              [
                -53.659330976333344,
                -23.16528732797789
              ],
              [
                -53.65956797760581,
                -23.159634818502393
              ],
              [
                -53.65980600586785,
                -23.153981345037916
              ],
              [
                -53.636063276731065,
                -23.124553888933036
              ],
              [
                -53.63005771742536,
                -23.011340810856442
              ],
              [
                -53.63215819905965,
                -23.003449853687744
              ],
              [
                -53.633102236189586,
                -22.999901850798775
              ],
              [
                -53.63360321544578,
                -22.99801789335309
              ],
              [
                -53.62574883640662,
                -22.98708739104381
              ],
              [
                -53.6161114265726,
                -22.973675409354477
              ],
              [
                -53.611506519349064,
                -22.96226693714893
              ],
              [
                -53.6069001051165,
                -22.95085596598093
              ],
              [
                -53.58878525968406,
                -22.935758501037462
              ],
              [
                -53.57951633503784,
                -22.931885961276627
              ],
              [
                -53.57024742639743,
                -22.92801346553111
              ],
              [
                -53.560259066019675,
                -22.915686460007517
              ],
              [
                -53.55959058160985,
                -22.91058598189656
              ],
              [
                -53.55892109220721,
                -22.905484013792595
              ],
              [
                -53.546304231035045,
                -22.8896205312949
              ],
              [
                -53.54025681128524,
                -22.885855551359434
              ],
              [
                -53.53420987453212,
                -22.882090550435098
              ],
              [
                -53.48424028366962,
                -22.863376959728136
              ],
              [
                -53.45033611228544,
                -22.841457930681685
              ],
              [
                -53.43973271676658,
                -22.83696742435944
              ],
              [
                -53.42912981825201,
                -22.83247690605923
              ],
              [
                -53.41100201734728,
                -22.81529591692843
              ],
              [
                -53.40956399890125,
                -22.813932935558846
              ],
              [
                -53.40503156472555,
                -22.81079292720783
              ],
              [
                -53.4004985955551,
                -22.807652420863093
              ],
              [
                -53.37703078840672,
                -22.802759874047204
              ],
              [
                -53.36076651279096,
                -22.78141793869956
              ],
              [
                -53.35739852084211,
                -22.779154927734368
              ],
              [
                -53.35403057389088,
                -22.776892431770555
              ],
              [
                -53.32633529907543,
                -22.764804899142568
              ],
              [
                -53.23947158080372,
                -22.733272758461244
              ],
              [
                -53.20143586112194,
                -22.728291681351894
              ],
              [
                -53.19093596908498,
                -22.72072617152226
              ],
              [
                -53.18043457505918,
                -22.71315966572543
              ],
              [
                -53.127450652469825,
                -22.651613255226163
              ],
              [
                -53.10466893923559,
                -22.621452309139393
              ],
              [
                -53.094071456373875,
                -22.637489702151605
              ],
              [
                -53.08730143536724,
                -22.65792361817674
              ],
              [
                -53.07420507955795,
                -22.640865127972454
              ],
              [
                -53.052964780101306,
                -22.630235653165574
              ],
              [
                -53.04548789553326,
                -22.624090134663188
              ],
              [
                -53.03801092695976,
                -22.617945157178223
              ],
              [
                -53.024000611700224,
                -22.603721647588504
              ],
              [
                -53.011938221273454,
                -22.597539146206035
              ],
              [
                -52.98435100626628,
                -22.575877148638693
              ],
              [
                -52.978129541911606,
                -22.572942149818875
              ],
              [
                -52.97190912255129,
                -22.57000715300999
              ],
              [
                -52.92422646310178,
                -22.565147015398708
              ],
              [
                -52.91453753042191,
                -22.56843849599094
              ],
              [
                -52.9048480897612,
                -22.57172996157942
              ],
              [
                -52.88490067648048,
                -22.587654818973807
              ],
              [
                -52.86570781676743,
                -22.60297821797689
              ],
              [
                -52.857848327091645,
                -22.607523667320333
              ],
              [
                -52.84434843027644,
                -22.608742131181774
              ],
              [
                -52.81492471078672,
                -22.596566573928715
              ],
              [
                -52.782441921897295,
                -22.601910456680564
              ],
              [
                -52.7794789370526,
                -22.60245547248966
              ],
              [
                -52.77007603255313,
                -22.60544243111181
              ],
              [
                -52.7497866459872,
                -22.611888829974472
              ],
              [
                -52.721924809791766,
                -22.626272227884908
              ],
              [
                -52.71154637001468,
                -22.62663116967868
              ],
              [
                -52.70116793625208,
                -22.626990663474437
              ],
              [
                -52.6624363021408,
                -22.61138059137103
              ],
              [
                -52.60273989569578,
                -22.570727579935177
              ],
              [
                -52.5862185319086,
                -22.566039576769594
              ],
              [
                -52.579758090474535,
                -22.56852256337504
              ],
              [
                -52.56662212716218,
                -22.588763446383876
              ],
              [
                -52.53991272495352,
                -22.61311176591093
              ],
              [
                -52.536486238111436,
                -22.61438624047414
              ],
              [
                -52.53312626085072,
                -22.615638740325846
              ],
              [
                -52.52298081349291,
                -22.619418180593442
              ],
              [
                -52.520369330568556,
                -22.621251661466214
              ],
              [
                -52.50279293759286,
                -22.633592563639702
              ],
              [
                -52.49620798405186,
                -22.631881037187878
              ],
              [
                -52.489622547519026,
                -22.630169536739754
              ],
              [
                -52.483406131734256,
                -22.62662104959228
              ],
              [
                -52.45824435442279,
                -22.612258531849662
              ],
              [
                -52.446364478354454,
                -22.608371009795768
              ],
              [
                -52.43572053602426,
                -22.607025982587366
              ],
              [
                -52.43478104649749,
                -22.606907501185354
              ],
              [
                -52.4338980542395,
                -22.607158490510606
              ],
              [
                -52.42085516507182,
                -22.61086544023049
              ],
              [
                -52.36918547396296,
                -22.628503206610908
              ],
              [
                -52.304275420883634,
                -22.635991520372283
              ],
              [
                -52.29946798856445,
                -22.63302750335407
              ],
              [
                -52.289751090917456,
                -22.62703651627191
              ],
              [
                -52.27817521831114,
                -22.61990250604572
              ],
              [
                -52.2499039118166,
                -22.61494742548809
              ],
              [
                -52.23962247558421,
                -22.61940287268117
              ],
              [
                -52.2299760192458,
                -22.637544294964197
              ],
              [
                -52.228655950043596,
                -22.6537147413659
              ],
              [
                -52.22774891660277,
                -22.66481968396044
              ],
              [
                -52.222988936635744,
                -22.673825138070413
              ],
              [
                -52.21860198739612,
                -22.67278961476588
              ],
              [
                -52.21058158446493,
                -22.667545616931832
              ],
              [
                -52.196672201594474,
                -22.658449616330774
              ],
              [
                -52.18105284885076,
                -22.65297058733817
              ],
              [
                -52.15617006656587,
                -22.644238553021502
              ],
              [
                -52.161353611365286,
                -22.605409232906872
              ],
              [
                -52.16113213728926,
                -22.6013632552016
              ],
              [
                -52.16091067620981,
                -22.597317271498934
              ],
              [
                -52.15618327889629,
                -22.582258792614198
              ],
              [
                -52.15191880885586,
                -22.568674854356
              ],
              [
                -52.15008684665267,
                -22.558723888143557
              ],
              [
                -52.148253910439145,
                -22.548771920951122
              ],
              [
                -52.1348950901524,
                -22.52743047998355
              ],
              [
                -52.12516669887845,
                -22.51878995578793
              ],
              [
                -52.110181311537325,
                -22.51664692897113
              ],
              [
                -52.107132819086765,
                -22.516210940483997
              ],
              [
                -52.098129394116924,
                -22.518487408237586
              ],
              [
                -52.09812339315516,
                -22.51848942121144
              ],
              [
                -52.08912646715304,
                -22.520764863988052
              ],
              [
                -52.08475849414761,
                -22.523051364712497
              ],
              [
                -52.06415660907534,
                -22.53383576363634
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 19,
        "nome": "Rio de Janeiro",
        "sigla": "RJ",
        "regiao_id": 2,
        "codigo_ibg": 33
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -44.51272640102142,
                -23.292935091271175
              ],
              [
                -44.51639939429497,
                -23.291660614346224
              ],
              [
                -44.520944852111946,
                -23.290356659940954
              ],
              [
                -44.52389431328376,
                -23.289510632006383
              ],
              [
                -44.528761802610994,
                -23.28811516791453
              ],
              [
                -44.53524271562613,
                -23.29277214846831
              ],
              [
                -44.53792818567861,
                -23.29924515905023
              ],
              [
                -44.53960462816697,
                -23.303308634329195
              ],
              [
                -44.540462110096705,
                -23.305386118805597
              ],
              [
                -44.54118264900459,
                -23.30440364043777
              ],
              [
                -44.54239362580544,
                -23.30275315458077
              ],
              [
                -44.54688558728436,
                -23.305454132021342
              ],
              [
                -44.55082852555327,
                -23.307830625507528
              ],
              [
                -44.556145008440836,
                -23.305364645732073
              ],
              [
                -44.55911844340944,
                -23.31108014072925
              ],
              [
                -44.560728950319856,
                -23.314250106088224
              ],
              [
                -44.56282289499607,
                -23.31837160125719
              ],
              [
                -44.565508871811616,
                -23.323658090523974
              ],
              [
                -44.564123392352904,
                -23.325981558717405
              ],
              [
                -44.56145536910468,
                -23.330453535216026
              ],
              [
                -44.56776030357262,
                -23.338550536070095
              ],
              [
                -44.57749316223458,
                -23.35104848947933
              ],
              [
                -44.58232311151731,
                -23.357251463322825
              ],
              [
                -44.59106404392713,
                -23.362217487748968
              ],
              [
                -44.59906345776474,
                -23.35940252098744
              ],
              [
                -44.60213444274375,
                -23.36358798159126
              ],
              [
                -44.60602836904562,
                -23.3667019755262
              ],
              [
                -44.606523937748165,
                -23.352394561596533
              ],
              [
                -44.60663595017763,
                -23.349158046224893
              ],
              [
                -44.611994904329755,
                -23.348379087589638
              ],
              [
                -44.61419585745341,
                -23.34957457695924
              ],
              [
                -44.617744844564946,
                -23.351502568556096
              ],
              [
                -44.61816831979116,
                -23.348114103753343
              ],
              [
                -44.618878339890685,
                -23.34243662057232
              ],
              [
                -44.623703313545555,
                -23.340762649710573
              ],
              [
                -44.62673527342993,
                -23.346645129173776
              ],
              [
                -44.62844772294031,
                -23.349969132136174
              ],
              [
                -44.633500683350285,
                -23.352115616827046
              ],
              [
                -44.631873727634286,
                -23.34675916471188
              ],
              [
                -44.6316487259547,
                -23.34602012959243
              ],
              [
                -44.629265255386926,
                -23.338604677158013
              ],
              [
                -44.640391677066226,
                -23.332730724432988
              ],
              [
                -44.647552634985125,
                -23.33496724646326
              ],
              [
                -44.651981042317246,
                -23.342246718157188
              ],
              [
                -44.661756985620485,
                -23.339511731885064
              ],
              [
                -44.66039145826221,
                -23.34484923089979
              ],
              [
                -44.659697978128555,
                -23.347376690046925
              ],
              [
                -44.65958149477333,
                -23.347800219724906
              ],
              [
                -44.66146346074506,
                -23.3474157317024
              ],
              [
                -44.67212388662262,
                -23.34523924822063
              ],
              [
                -44.673855370953994,
                -23.34048379645083
              ],
              [
                -44.67566187413782,
                -23.33557182262851
              ],
              [
                -44.68134782476995,
                -23.34000228226884
              ],
              [
                -44.681317291177756,
                -23.347419261126596
              ],
              [
                -44.6816577772372,
                -23.347729746593068
              ],
              [
                -44.682693762244654,
                -23.348675279965896
              ],
              [
                -44.68655022160682,
                -23.35219327292078
              ],
              [
                -44.686635748032096,
                -23.349656285142952
              ],
              [
                -44.686690742872635,
                -23.348004257100357
              ],
              [
                -44.68766973093218,
                -23.34753280101363
              ],
              [
                -44.69035572388754,
                -23.346240281489038
              ],
              [
                -44.69530118782281,
                -23.343861295295937
              ],
              [
                -44.701530162413114,
                -23.341253328705108
              ],
              [
                -44.70752360071398,
                -23.343098839428105
              ],
              [
                -44.71269454049077,
                -23.345139845975634
              ],
              [
                -44.7163769850149,
                -23.34659287221902
              ],
              [
                -44.7179534723981,
                -23.34721534160622
              ],
              [
                -44.72079546200159,
                -23.353381827292075
              ],
              [
                -44.719342451324515,
                -23.357797797456037
              ],
              [
                -44.71925345621998,
                -23.35806880993232
              ],
              [
                -44.71967043822963,
                -23.357879303971917
              ],
              [
                -44.72695738495215,
                -23.354575333576914
              ],
              [
                -44.731095352240054,
                -23.359226317990604
              ],
              [
                -44.73004832429554,
                -23.36046280327986
              ],
              [
                -44.72399936052122,
                -23.36760076298173
              ],
              [
                -44.72801535550386,
                -23.366154787837303
              ],
              [
                -44.7334732739154,
                -23.364126822400095
              ],
              [
                -44.736460763786695,
                -23.362059329361994
              ],
              [
                -44.74237722990744,
                -23.357964868933177
              ],
              [
                -44.74970416945754,
                -23.3580283877634
              ],
              [
                -44.756421635306936,
                -23.348463451339633
              ],
              [
                -44.758061627578655,
                -23.345227961109053
              ],
              [
                -44.759702633840824,
                -23.34199247688184
              ],
              [
                -44.760980634600514,
                -23.341747985545304
              ],
              [
                -44.77787749123194,
                -23.33851606020249
              ],
              [
                -44.776319504486395,
                -23.333296053548022
              ],
              [
                -44.78162447743095,
                -23.325935100845495
              ],
              [
                -44.78052550500361,
                -23.318450156149922
              ],
              [
                -44.78006055720829,
                -23.315282145826444
              ],
              [
                -44.78733049982324,
                -23.305549698718355
              ],
              [
                -44.787030036624046,
                -23.299576259096362
              ],
              [
                -44.80491488771637,
                -23.289535354564585
              ],
              [
                -44.806952873559645,
                -23.282874394605113
              ],
              [
                -44.81403283222372,
                -23.28577289233861
              ],
              [
                -44.82616822167075,
                -23.290740887186864
              ],
              [
                -44.82362126997104,
                -23.28036741515816
              ],
              [
                -44.82682821890146,
                -23.27925894018761
              ],
              [
                -44.82923773750343,
                -23.271989464114238
              ],
              [
                -44.83011223344654,
                -23.269351018969473
              ],
              [
                -44.83970218225836,
                -23.26330504181197
              ],
              [
                -44.83651271120968,
                -23.25630557311995
              ],
              [
                -44.85399258476143,
                -23.24849117793731
              ],
              [
                -44.864304517702834,
                -23.248576203805573
              ],
              [
                -44.87461839963981,
                -23.248661702683037
              ],
              [
                -44.877844947326125,
                -23.23232228166324
              ],
              [
                -44.885069880399826,
                -23.23193233130689
              ],
              [
                -44.888491859410145,
                -23.223999350140886
              ],
              [
                -44.88335490627426,
                -23.218181380542312
              ],
              [
                -44.88095944058843,
                -23.215467857798455
              ],
              [
                -44.881452960734244,
                -23.211914414937453
              ],
              [
                -44.88259798733321,
                -23.203661433068525
              ],
              [
                -44.87729003597289,
                -23.19867696109688
              ],
              [
                -44.87592956476921,
                -23.197399956410102
              ],
              [
                -44.87261362382286,
                -23.183805507619606
              ],
              [
                -44.86029772981171,
                -23.180988478267874
              ],
              [
                -44.85290279960909,
                -23.176249001481906
              ],
              [
                -44.85325731176989,
                -23.17461901916577
              ],
              [
                -44.85376232686503,
                -23.172291523562823
              ],
              [
                -44.8486893430156,
                -23.172450498947992
              ],
              [
                -44.84581838443733,
                -23.17254050267737
              ],
              [
                -44.845044904911646,
                -23.170720527180922
              ],
              [
                -44.843931891854666,
                -23.168103504531935
              ],
              [
                -44.84014196045767,
                -23.166544011564813
              ],
              [
                -44.83601099569344,
                -23.16507951921885
              ],
              [
                -44.82443612343749,
                -23.160976485053382
              ],
              [
                -44.82498514273552,
                -23.14404907478328
              ],
              [
                -44.825011635156685,
                -23.14321860479285
              ],
              [
                -44.8244481736257,
                -23.143024586175674
              ],
              [
                -44.81643272440943,
                -23.14027007537523
              ],
              [
                -44.81923573519601,
                -23.133082613107813
              ],
              [
                -44.81937474113745,
                -23.132725612180142
              ],
              [
                -44.81877873590617,
                -23.132211608993348
              ],
              [
                -44.81067383296968,
                -23.12521511809606
              ],
              [
                -44.81317582248775,
                -23.121116664363395
              ],
              [
                -44.81076434795113,
                -23.114614684654562
              ],
              [
                -44.814355324267744,
                -23.111088714173867
              ],
              [
                -44.81576884251868,
                -23.109701207610588
              ],
              [
                -44.813959860491465,
                -23.107061224217194
              ],
              [
                -44.808596401560656,
                -23.099233751784226
              ],
              [
                -44.8124478893562,
                -23.09799377115776
              ],
              [
                -44.811926398267495,
                -23.091572314239244
              ],
              [
                -44.81241892052494,
                -23.09141629232074
              ],
              [
                -44.81798434223055,
                -23.0896553368319
              ],
              [
                -44.8127139678607,
                -23.074465889664523
              ],
              [
                -44.81357046480296,
                -23.067126411880597
              ],
              [
                -44.80967000054913,
                -23.063918898526516
              ],
              [
                -44.80688354479868,
                -23.061627932846534
              ],
              [
                -44.8086125207767,
                -23.05852295131132
              ],
              [
                -44.81112802946738,
                -23.054005951629346
              ],
              [
                -44.806504076977305,
                -23.05125595514677
              ],
              [
                -44.80271811869514,
                -23.04900449857351
              ],
              [
                -44.801453676632214,
                -23.03384606127897
              ],
              [
                -44.801209666004794,
                -23.03091956654802
              ],
              [
                -44.800031699396975,
                -23.02894454375389
              ],
              [
                -44.797152214468575,
                -23.02411507193654
              ],
              [
                -44.79796574096526,
                -23.02080058092838
              ],
              [
                -44.798230755101706,
                -23.01972160478608
              ],
              [
                -44.79740523992292,
                -23.018231118645698
              ],
              [
                -44.7927078031859,
                -23.009748134318762
              ],
              [
                -44.79497579906784,
                -23.007095140109012
              ],
              [
                -44.80220325763617,
                -22.9986417009839
              ],
              [
                -44.79287583504762,
                -22.993209225599244
              ],
              [
                -44.792038907735716,
                -22.981662768387785
              ],
              [
                -44.786682950871885,
                -22.982275768960015
              ],
              [
                -44.78188447253153,
                -22.97867277315443
              ],
              [
                -44.770860085734526,
                -22.98141120524473
              ],
              [
                -44.764877631519425,
                -22.982897685957724
              ],
              [
                -44.75903566883568,
                -22.97844970737537
              ],
              [
                -44.75485023658734,
                -22.97980216859389
              ],
              [
                -44.75610370779825,
                -22.97536671315931
              ],
              [
                -44.748936285968625,
                -22.969507723747483
              ],
              [
                -44.7510663031879,
                -22.963942766085864
              ],
              [
                -44.75182927090631,
                -22.961949760668254
              ],
              [
                -44.74340891518147,
                -22.947096334881717
              ],
              [
                -44.71148524742285,
                -22.92947534097076
              ],
              [
                -44.70435027651084,
                -22.93435330137454
              ],
              [
                -44.70428328194614,
                -22.93439829997842
              ],
              [
                -44.69572089098601,
                -22.93175526353032
              ],
              [
                -44.687923429060696,
                -22.93591374858889
              ],
              [
                -44.67755204421077,
                -22.922924767828682
              ],
              [
                -44.673455615608255,
                -22.917794272370266
              ],
              [
                -44.670487113975035,
                -22.91976925190726
              ],
              [
                -44.6590476996206,
                -22.92737922561353
              ],
              [
                -44.64604283098218,
                -22.918682199265703
              ],
              [
                -44.638577912972316,
                -22.913690726101404
              ],
              [
                -44.63464046600749,
                -22.906643753307726
              ],
              [
                -44.62868353227011,
                -22.90411574940794
              ],
              [
                -44.60962771275164,
                -22.887760763685026
              ],
              [
                -44.60662724867641,
                -22.885185773990756
              ],
              [
                -44.60437176671287,
                -22.886273773702413
              ],
              [
                -44.60018181750737,
                -22.88829426473515
              ],
              [
                -44.575516067047104,
                -22.878109254433973
              ],
              [
                -44.55479020804121,
                -22.888587140397416
              ],
              [
                -44.54378683096914,
                -22.884430612853887
              ],
              [
                -44.5447393133133,
                -22.882011641048827
              ],
              [
                -44.545235791968324,
                -22.880751667443626
              ],
              [
                -44.53258494168453,
                -22.868728190378974
              ],
              [
                -44.525827036114194,
                -22.866769183692572
              ],
              [
                -44.51907007553931,
                -22.864810155014844
              ],
              [
                -44.51550210931337,
                -22.86129117540469
              ],
              [
                -44.50543771013462,
                -22.85136569927487
              ],
              [
                -44.49428886012964,
                -22.84598769135317
              ],
              [
                -44.49026485990002,
                -22.84597670963446
              ],
              [
                -44.483948955480905,
                -22.84595865781647
              ],
              [
                -44.47934695092171,
                -22.850866117827618
              ],
              [
                -44.47598349845683,
                -22.85584158982603
              ],
              [
                -44.47980190629339,
                -22.864242090541286
              ],
              [
                -44.480516886215185,
                -22.865814566874985
              ],
              [
                -44.47836890546467,
                -22.866551566568656
              ],
              [
                -44.47566794789767,
                -22.867478536865374
              ],
              [
                -44.47602593191937,
                -22.87032754008889
              ],
              [
                -44.47645891541512,
                -22.873778023615422
              ],
              [
                -44.47126794266165,
                -22.875568019080042
              ],
              [
                -44.46656248424902,
                -22.884557933139238
              ],
              [
                -44.43670224546321,
                -22.870696937013324
              ],
              [
                -44.426283381779,
                -22.85519848894787
              ],
              [
                -44.42156846860031,
                -22.85099799262565
              ],
              [
                -44.41296004211977,
                -22.847744983327644
              ],
              [
                -44.40240614773136,
                -22.846654983390152
              ],
              [
                -44.401440146922745,
                -22.852812930052472
              ],
              [
                -44.40124261171099,
                -22.854072422439017
              ],
              [
                -44.39788715882313,
                -22.85339594273958
              ],
              [
                -44.391652210638746,
                -22.852138437154704
              ],
              [
                -44.389157704837025,
                -22.854828416488704
              ],
              [
                -44.38691823568784,
                -22.857242870835705
              ],
              [
                -44.373799366079865,
                -22.85758136114541
              ],
              [
                -44.35031806163544,
                -22.848108828247437
              ],
              [
                -44.325383810612074,
                -22.838049828406767
              ],
              [
                -44.32004386085785,
                -22.839971303861002
              ],
              [
                -44.32047538254113,
                -22.841447285864348
              ],
              [
                -44.32146983137961,
                -22.844846775056208
              ],
              [
                -44.3183448791031,
                -22.841085299935312
              ],
              [
                -44.31698640693853,
                -22.839449307235526
              ],
              [
                -44.2962060883616,
                -22.82734279750548
              ],
              [
                -44.28628669207109,
                -22.8280407786973
              ],
              [
                -44.28288920865553,
                -22.835404241949718
              ],
              [
                -44.272882301326625,
                -22.83120524365115
              ],
              [
                -44.26952733474297,
                -22.829797756541918
              ],
              [
                -44.26324345135142,
                -22.811334804388416
              ],
              [
                -44.2609789925527,
                -22.80468181765718
              ],
              [
                -44.25993299413984,
                -22.80451081270529
              ],
              [
                -44.245072599345065,
                -22.802084815969156
              ],
              [
                -44.24127417736295,
                -22.792667837616406
              ],
              [
                -44.241787186326846,
                -22.78768287320055
              ],
              [
                -44.24757011419752,
                -22.78996235049125
              ],
              [
                -44.25263559895921,
                -22.78814137879882
              ],
              [
                -44.25606158110049,
                -22.786909408888032
              ],
              [
                -44.25340860967162,
                -22.781214929504078
              ],
              [
                -44.257042567194205,
                -22.778305952292765
              ],
              [
                -44.25713412029122,
                -22.767981497709293
              ],
              [
                -44.25003820942553,
                -22.757064055948508
              ],
              [
                -44.23972228677891,
                -22.755433010507407
              ],
              [
                -44.238344791379454,
                -22.75521503391304
              ],
              [
                -44.239083314302405,
                -22.75381504668255
              ],
              [
                -44.24166681003097,
                -22.74891907335383
              ],
              [
                -44.22676196362724,
                -22.73349460590717
              ],
              [
                -44.21658708069275,
                -22.732861569028916
              ],
              [
                -44.20154468986448,
                -22.73192655073648
              ],
              [
                -44.19798421863064,
                -22.72447758960516
              ],
              [
                -44.200740738672124,
                -22.723528576528057
              ],
              [
                -44.20155219650297,
                -22.723249094038437
              ],
              [
                -44.20328170672293,
                -22.71570663037699
              ],
              [
                -44.18920234617979,
                -22.713268104006875
              ],
              [
                -44.184473373666066,
                -22.712449115489523
              ],
              [
                -44.1779144736327,
                -22.70719659176079
              ],
              [
                -44.17416952526281,
                -22.70160264515124
              ],
              [
                -44.17493850295211,
                -22.698231653633783
              ],
              [
                -44.176209505050366,
                -22.692658674191158
              ],
              [
                -44.16872359455187,
                -22.68821270311326
              ],
              [
                -44.16842061472783,
                -22.688033173189286
              ],
              [
                -44.16801311322241,
                -22.68706719182899
              ],
              [
                -44.16564813689488,
                -22.681466202931716
              ],
              [
                -44.16366666637483,
                -22.680060195565385
              ],
              [
                -44.16051420854711,
                -22.677823717164824
              ],
              [
                -44.161287175930624,
                -22.675514224487003
              ],
              [
                -44.161748694159535,
                -22.67413522444183
              ],
              [
                -44.173492629745766,
                -22.65738783826128
              ],
              [
                -44.17619863546671,
                -22.655703843630352
              ],
              [
                -44.18988251005641,
                -22.647185418359776
              ],
              [
                -44.18656354827477,
                -22.641476963498977
              ],
              [
                -44.18384110517992,
                -22.636794964203204
              ],
              [
                -44.19039958203393,
                -22.629723503065552
              ],
              [
                -44.1973775038685,
                -22.629970521257967
              ],
              [
                -44.198060518880744,
                -22.62999504593925
              ],
              [
                -44.203349973919444,
                -22.626130570776272
              ],
              [
                -44.2034434561098,
                -22.62351608680217
              ],
              [
                -44.20375001276284,
                -22.614915626655648
              ],
              [
                -44.20756497371159,
                -22.614272612855093
              ],
              [
                -44.21606338140815,
                -22.616482147457905
              ],
              [
                -44.218684375644344,
                -22.617163142040308
              ],
              [
                -44.22004433795837,
                -22.61484814694063
              ],
              [
                -44.22637282615566,
                -22.604073230292098
              ],
              [
                -44.23065827582933,
                -22.60742570620195
              ],
              [
                -44.2323372594387,
                -22.608035713647244
              ],
              [
                -44.24758413336989,
                -22.60613475214013
              ],
              [
                -44.24763263079899,
                -22.606184261026144
              ],
              [
                -44.253023561178615,
                -22.611738743111026
              ],
              [
                -44.26581749903338,
                -22.603736335947307
              ],
              [
                -44.27107143466292,
                -22.600450349855617
              ],
              [
                -44.277261886806016,
                -22.602195344664462
              ],
              [
                -44.28265531852731,
                -22.60371638446846
              ],
              [
                -44.285257813154224,
                -22.609228838426183
              ],
              [
                -44.29471071813861,
                -22.601818415548248
              ],
              [
                -44.295194772429454,
                -22.598723424933922
              ],
              [
                -44.29534773509795,
                -22.597746434107016
              ],
              [
                -44.298637719842695,
                -22.598015424475886
              ],
              [
                -44.305815137021966,
                -22.598602940551118
              ],
              [
                -44.31114112566955,
                -22.595445494018655
              ],
              [
                -44.312325611583326,
                -22.594743963568757
              ],
              [
                -44.31312162080437,
                -22.595038500232334
              ],
              [
                -44.317579550237134,
                -22.596688956945727
              ],
              [
                -44.322131047972256,
                -22.592098520360274
              ],
              [
                -44.32597249364911,
                -22.58822453740418
              ],
              [
                -44.33054345277887,
                -22.58900505266096
              ],
              [
                -44.33975789638037,
                -22.59057855431115
              ],
              [
                -44.343205857698145,
                -22.587935576307533
              ],
              [
                -44.34725534120893,
                -22.590065069613335
              ],
              [
                -44.345736326788895,
                -22.596769559904686
              ],
              [
                -44.34984877541158,
                -22.595093559940146
              ],
              [
                -44.35137376596872,
                -22.599099536436164
              ],
              [
                -44.34929323930636,
                -22.608765509818305
              ],
              [
                -44.35596569635399,
                -22.613764992091195
              ],
              [
                -44.36065466869404,
                -22.61121250992548
              ],
              [
                -44.36081466564479,
                -22.6052345564793
              ],
              [
                -44.36115121336768,
                -22.592631620800713
              ],
              [
                -44.36593564486037,
                -22.59335060493585
              ],
              [
                -44.36984865629997,
                -22.589363154712085
              ],
              [
                -44.361735711631326,
                -22.582736638593076
              ],
              [
                -44.359935735669744,
                -22.581267144006826
              ],
              [
                -44.363605734254676,
                -22.57791417405026
              ],
              [
                -44.37120565647534,
                -22.579605695869315
              ],
              [
                -44.38397906878099,
                -22.573223736720593
              ],
              [
                -44.391237504125336,
                -22.57290528844292
              ],
              [
                -44.395258931469826,
                -22.58105275435458
              ],
              [
                -44.40896778162959,
                -22.587762760896513
              ],
              [
                -44.40587631265654,
                -22.590581699992654
              ],
              [
                -44.404965822965025,
                -22.59141222054012
              ],
              [
                -44.40375680256926,
                -22.600158660746796
              ],
              [
                -44.3980753603588,
                -22.60251663423757
              ],
              [
                -44.405913269588275,
                -22.60712411753751
              ],
              [
                -44.41629021688975,
                -22.601314699273065
              ],
              [
                -44.42468164077102,
                -22.599272710414887
              ],
              [
                -44.42979059052185,
                -22.598029246985273
              ],
              [
                -44.4422979508484,
                -22.603829741864583
              ],
              [
                -44.44559993767845,
                -22.60893972775176
              ],
              [
                -44.44629190842104,
                -22.6100107123756
              ],
              [
                -44.447461430982614,
                -22.609800209496555
              ],
              [
                -44.4539578445616,
                -22.608629745393728
              ],
              [
                -44.45543931780597,
                -22.60960024259854
              ],
              [
                -44.4643967435381,
                -22.61546921979764
              ],
              [
                -44.47772613691458,
                -22.61245029779313
              ],
              [
                -44.48031611237442,
                -22.6082922928632
              ],
              [
                -44.48296312362297,
                -22.604042851529375
              ],
              [
                -44.48709758403858,
                -22.605654331653298
              ],
              [
                -44.485482085157074,
                -22.609493832714815
              ],
              [
                -44.48509605166298,
                -22.61041132623358
              ],
              [
                -44.508152288522155,
                -22.64044472756921
              ],
              [
                -44.51448628598837,
                -22.629718792429227
              ],
              [
                -44.51951974112061,
                -22.629881814998285
              ],
              [
                -44.524235189027905,
                -22.630034331775672
              ],
              [
                -44.53097415230454,
                -22.630252832606775
              ],
              [
                -44.534245099115374,
                -22.63252931824979
              ],
              [
                -44.530794611948075,
                -22.6393067692162
              ],
              [
                -44.53484958602077,
                -22.6370353020019
              ],
              [
                -44.53701755526956,
                -22.63186931781323
              ],
              [
                -44.53132813006267,
                -22.62544636386386
              ],
              [
                -44.525634195901134,
                -22.61901887094061
              ],
              [
                -44.53272866534373,
                -22.615267908969642
              ],
              [
                -44.53665561280064,
                -22.616801918946543
              ],
              [
                -44.548519533876224,
                -22.605680986392553
              ],
              [
                -44.55699398918041,
                -22.608843497536753
              ],
              [
                -44.562811416413275,
                -22.611014998444936
              ],
              [
                -44.56647990185949,
                -22.6074350335471
              ],
              [
                -44.57261087451435,
                -22.601451063845712
              ],
              [
                -44.57663230309037,
                -22.61015803829483
              ],
              [
                -44.583695673166815,
                -22.62545096892762
              ],
              [
                -44.59103564621099,
                -22.62246351290884
              ],
              [
                -44.59448563269826,
                -22.614946533501552
              ],
              [
                -44.59673414545187,
                -22.610047060219742
              ],
              [
                -44.601873567209886,
                -22.60713460898715
              ],
              [
                -44.604328029118484,
                -22.61394009352958
              ],
              [
                -44.604366063743555,
                -22.613955567555628
              ],
              [
                -44.60984148382546,
                -22.616165579399446
              ],
              [
                -44.61632594578873,
                -22.61041110650917
              ],
              [
                -44.62185493736122,
                -22.605504150973115
              ],
              [
                -44.62743586239254,
                -22.607117161990498
              ],
              [
                -44.633016799426294,
                -22.608730158011966
              ],
              [
                -44.6449187126541,
                -22.602061752941292
              ],
              [
                -44.646682260729456,
                -22.584138326443707
              ],
              [
                -44.641932321129616,
                -22.577133863771703
              ],
              [
                -44.641293393829535,
                -22.558867446631133
              ],
              [
                -44.647335357004955,
                -22.552330490360802
              ],
              [
                -44.64991433798458,
                -22.55651147192815
              ],
              [
                -44.661093738613815,
                -22.550434017056727
              ],
              [
                -44.66587268024023,
                -22.553462511055518
              ],
              [
                -44.67695256045012,
                -22.560483530440433
              ],
              [
                -44.675829109025756,
                -22.544581104528593
              ],
              [
                -44.67539063715433,
                -22.544138103512765
              ],
              [
                -44.6704971714052,
                -22.539188578528748
              ],
              [
                -44.67998412240533,
                -22.52568969315128
              ],
              [
                -44.687899584297604,
                -22.52150224344729
              ],
              [
                -44.69552754730668,
                -22.51775776283141
              ],
              [
                -44.69868950153883,
                -22.51340230533698
              ],
              [
                -44.7007105227345,
                -22.510619820189458
              ],
              [
                -44.705329964028856,
                -22.511613812615217
              ],
              [
                -44.7152633962018,
                -22.513751820588332
              ],
              [
                -44.71210843117972,
                -22.505970364952834
              ],
              [
                -44.70965195186243,
                -22.499911876825685
              ],
              [
                -44.71207344967715,
                -22.492874938378492
              ],
              [
                -44.716976905549075,
                -22.492239450453482
              ],
              [
                -44.72012736776933,
                -22.491830941782975
              ],
              [
                -44.72345086955755,
                -22.483583512597484
              ],
              [
                -44.722272934624236,
                -22.47381355088484
              ],
              [
                -44.719403955860734,
                -22.4703480741087
              ],
              [
                -44.71814646730622,
                -22.468828547155823
              ],
              [
                -44.718183477095586,
                -22.46487755743434
              ],
              [
                -44.72693144576726,
                -22.44668668591201
              ],
              [
                -44.72977844097287,
                -22.43962225175403
              ],
              [
                -44.73997939979992,
                -22.42612283030386
              ],
              [
                -44.75466081771092,
                -22.414425926938666
              ],
              [
                -44.760459765764104,
                -22.414505910877715
              ],
              [
                -44.76364824130324,
                -22.414549926092363
              ],
              [
                -44.7695581924072,
                -22.414631446320307
              ],
              [
                -44.77210764603191,
                -22.41284244274921
              ],
              [
                -44.77899662578411,
                -22.408009485433613
              ],
              [
                -44.80100542756408,
                -22.40561856423504
              ],
              [
                -44.80879685742615,
                -22.40477210494952
              ],
              [
                -44.803581439095844,
                -22.39332414573931
              ],
              [
                -44.793279569906105,
                -22.38658814323365
              ],
              [
                -44.787150606341186,
                -22.3875896161762
              ],
              [
                -44.78439711722469,
                -22.385527607922242
              ],
              [
                -44.78362162064175,
                -22.384946611697284
              ],
              [
                -44.780049678407245,
                -22.382271607264272
              ],
              [
                -44.777477696775264,
                -22.383144122231435
              ],
              [
                -44.77556523417839,
                -22.383792591029234
              ],
              [
                -44.76795530787961,
                -22.380351088667023
              ],
              [
                -44.75962435375716,
                -22.376583123000415
              ],
              [
                -44.75432343507473,
                -22.375306102223455
              ],
              [
                -44.75128443076554,
                -22.375421112645373
              ],
              [
                -44.74401452222391,
                -22.375697593120073
              ],
              [
                -44.74076752922579,
                -22.37203358838353
              ],
              [
                -44.734345137364805,
                -22.36478560072536
              ],
              [
                -44.731784664641275,
                -22.36270812608942
              ],
              [
                -44.72354423618776,
                -22.359454086215926
              ],
              [
                -44.71766326713738,
                -22.364202580591964
              ],
              [
                -44.71510628178787,
                -22.36560456902532
              ],
              [
                -44.714037793614985,
                -22.366370535478964
              ],
              [
                -44.71142829728656,
                -22.368242035487057
              ],
              [
                -44.70544386858361,
                -22.372185994506463
              ],
              [
                -44.69367445503063,
                -22.370047491907734
              ],
              [
                -44.69119394967722,
                -22.3695969885671
              ],
              [
                -44.68475903359562,
                -22.37165744257432
              ],
              [
                -44.67832506651209,
                -22.373717904583856
              ],
              [
                -44.66605070394555,
                -22.37269140925559
              ],
              [
                -44.659962191774504,
                -22.37906736314033
              ],
              [
                -44.658436247565945,
                -22.37424088364909
              ],
              [
                -44.65492227085983,
                -22.37156735443105
              ],
              [
                -44.65453578807121,
                -22.371273400846754
              ],
              [
                -44.65344228598932,
                -22.370440857027397
              ],
              [
                -44.64582536099746,
                -22.36464390525053
              ],
              [
                -44.64469338939279,
                -22.360803890004764
              ],
              [
                -44.642466454478345,
                -22.353248464012147
              ],
              [
                -44.633480509178455,
                -22.348451954792868
              ],
              [
                -44.627908574039175,
                -22.34547796266472
              ],
              [
                -44.62699110259502,
                -22.341302966640736
              ],
              [
                -44.625736636682106,
                -22.33559897620313
              ],
              [
                -44.61683873117369,
                -22.33127799493876
              ],
              [
                -44.58729501062222,
                -22.32112395802286
              ],
              [
                -44.58071904875195,
                -22.327175412170796
              ],
              [
                -44.58060502441436,
                -22.327279936360355
              ],
              [
                -44.579707545177506,
                -22.326931927707417
              ],
              [
                -44.571239129855165,
                -22.32365192753465
              ],
              [
                -44.567898628180075,
                -22.327403388418062
              ],
              [
                -44.56391215185579,
                -22.331880352057375
              ],
              [
                -44.558155244556296,
                -22.32916136225748
              ],
              [
                -44.545431369083445,
                -22.32315387829191
              ],
              [
                -44.54307986173104,
                -22.331260828443213
              ],
              [
                -44.54183284893386,
                -22.331100314958583
              ],
              [
                -44.53277895928327,
                -22.329932779933245
              ],
              [
                -44.530124482854845,
                -22.323409327916227
              ],
              [
                -44.51733110878595,
                -22.315821804560954
              ],
              [
                -44.516397652862736,
                -22.31415183929836
              ],
              [
                -44.51323967210114,
                -22.308501356718683
              ],
              [
                -44.500625291455044,
                -22.303238864494794
              ],
              [
                -44.4939953368174,
                -22.300472830709666
              ],
              [
                -44.4675726595547,
                -22.279100891508225
              ],
              [
                -44.46071069727399,
                -22.279862853838694
              ],
              [
                -44.4628637008771,
                -22.274517396717297
              ],
              [
                -44.46223019940165,
                -22.273828883444573
              ],
              [
                -44.458702239297246,
                -22.269996898055687
              ],
              [
                -44.462185240787825,
                -22.266199447807402
              ],
              [
                -44.45601532332564,
                -22.25674848214585
              ],
              [
                -44.44624040723862,
                -22.2551414315403
              ],
              [
                -44.44431341179805,
                -22.257471427057215
              ],
              [
                -44.44001946023437,
                -22.25431645036787
              ],
              [
                -44.43798746717248,
                -22.257817924849448
              ],
              [
                -44.43228103738191,
                -22.251134938831747
              ],
              [
                -44.42345909516131,
                -22.255162898013
              ],
              [
                -44.41401969193309,
                -22.253652382831937
              ],
              [
                -44.3943643398082,
                -22.2584048342065
              ],
              [
                -44.39161832886551,
                -22.263583782559447
              ],
              [
                -44.39139535051985,
                -22.264004264908802
              ],
              [
                -44.38715385996402,
                -22.272002728489486
              ],
              [
                -44.38364090718202,
                -22.267028767807776
              ],
              [
                -44.37698747796275,
                -22.26753071399295
              ],
              [
                -44.37578599060028,
                -22.26344524697327
              ],
              [
                -44.3752385079186,
                -22.261582242718678
              ],
              [
                -44.36052014568366,
                -22.252597263627894
              ],
              [
                -44.3519051968947,
                -22.25725220126015
              ],
              [
                -44.34073681790129,
                -22.25332620552721
              ],
              [
                -44.33811982673248,
                -22.256559676778433
              ],
              [
                -44.33680880915997,
                -22.258179693381326
              ],
              [
                -44.32700093071593,
                -22.2548351853362
              ],
              [
                -44.324298934765444,
                -22.253914156845028
              ],
              [
                -44.324088435014566,
                -22.25443516872852
              ],
              [
                -44.32157095200093,
                -22.26066312347312
              ],
              [
                -44.31430554262692,
                -22.254000662414708
              ],
              [
                -44.30135413111389,
                -22.253049608413107
              ],
              [
                -44.299387675090664,
                -22.251042147201797
              ],
              [
                -44.291077758170914,
                -22.24256164244336
              ],
              [
                -44.28901827773007,
                -22.246960615378786
              ],
              [
                -44.28408431296641,
                -22.24306061780316
              ],
              [
                -44.268371440681996,
                -22.24431859775965
              ],
              [
                -44.26687544004504,
                -22.247833079518788
              ],
              [
                -44.2638189746547,
                -22.243747596751245
              ],
              [
                -44.26322397603308,
                -22.248001076200755
              ],
              [
                -44.26197398904527,
                -22.253259021990726
              ],
              [
                -44.26107345361695,
                -22.261323975835484
              ],
              [
                -44.260827969458774,
                -22.26308199951956
              ],
              [
                -44.2601589608603,
                -22.26786947215089
              ],
              [
                -44.25764396747627,
                -22.265896976346507
              ],
              [
                -44.250371544326576,
                -22.260193950594868
              ],
              [
                -44.246297565536864,
                -22.266505912841236
              ],
              [
                -44.23871215318958,
                -22.262438437209497
              ],
              [
                -44.23635517523,
                -22.264537898716075
              ],
              [
                -44.23504616902639,
                -22.265704398553513
              ],
              [
                -44.22395928033367,
                -22.258825889723447
              ],
              [
                -44.22396880573505,
                -22.257663904489235
              ],
              [
                -44.22401128987105,
                -22.25249194415525
              ],
              [
                -44.21289790993519,
                -22.245766459544587
              ],
              [
                -44.20903796469116,
                -22.24962595144513
              ],
              [
                -44.20810093497263,
                -22.250562923380752
              ],
              [
                -44.20342500899314,
                -22.248340404232287
              ],
              [
                -44.20126848944461,
                -22.251066413156728
              ],
              [
                -44.18910215396256,
                -22.2402174202469
              ],
              [
                -44.18878863150734,
                -22.23993743381892
              ],
              [
                -44.180598254075754,
                -22.23263344872397
              ],
              [
                -44.17982526690862,
                -22.22756449181056
              ],
              [
                -44.17314982537868,
                -22.22996244863875
              ],
              [
                -44.171827323497105,
                -22.229394426023394
              ],
              [
                -44.16643686597039,
                -22.227078432522294
              ],
              [
                -44.16648938412262,
                -22.2285484243795
              ],
              [
                -44.16660234962647,
                -22.2317329229049
              ],
              [
                -44.16371090468439,
                -22.232038430899166
              ],
              [
                -44.158208946874694,
                -22.224787922549904
              ],
              [
                -44.15323898982245,
                -22.22645390442445
              ],
              [
                -44.150545542974484,
                -22.21845793806153
              ],
              [
                -44.14876406815696,
                -22.213168482660283
              ],
              [
                -44.145887079015864,
                -22.215509931609223
              ],
              [
                -44.14065811547901,
                -22.219764910986292
              ],
              [
                -44.13318369137481,
                -22.209606426996086
              ],
              [
                -44.12703674171007,
                -22.211540426503024
              ],
              [
                -44.12598526344414,
                -22.208306448819258
              ],
              [
                -44.121090312803744,
                -22.20861943160542
              ],
              [
                -44.12436880068342,
                -22.201174451010743
              ],
              [
                -44.12487732073265,
                -22.20001999405224
              ],
              [
                -44.12087333877378,
                -22.194838498405634
              ],
              [
                -44.12268236720662,
                -22.187771548147893
              ],
              [
                -44.11887538003856,
                -22.18678402421071
              ],
              [
                -44.1174609008032,
                -22.189622000470862
              ],
              [
                -44.10465401896532,
                -22.184081521884796
              ],
              [
                -44.10448005462587,
                -22.177311551052046
              ],
              [
                -44.09965408672912,
                -22.17280854095692
              ],
              [
                -44.08976418371616,
                -22.175322006941517
              ],
              [
                -44.089322663263175,
                -22.17642051934455
              ],
              [
                -44.08662118563534,
                -22.183132465028695
              ],
              [
                -44.08536071802702,
                -22.176917482648765
              ],
              [
                -44.07665077496918,
                -22.174343985957456
              ],
              [
                -44.07379131533696,
                -22.168072998748027
              ],
              [
                -44.07214583528745,
                -22.164465023439593
              ],
              [
                -44.06596242824155,
                -22.16160451871662
              ],
              [
                -44.05158855104104,
                -22.16075351389263
              ],
              [
                -44.04057365722714,
                -22.160100978750474
              ],
              [
                -44.03905464469642,
                -22.156621500150347
              ],
              [
                -44.03785116429565,
                -22.15386250978034
              ],
              [
                -44.02351382814394,
                -22.150880991695104
              ],
              [
                -44.01753337153738,
                -22.154364456950802
              ],
              [
                -44.00462345191079,
                -22.161883900264588
              ],
              [
                -43.997600037490635,
                -22.155545897745977
              ],
              [
                -43.99288053753402,
                -22.157836870185417
              ],
              [
                -43.990170098170445,
                -22.153393397345365
              ],
              [
                -43.987904637793655,
                -22.149677920028562
              ],
              [
                -43.98501414621176,
                -22.14954189126939
              ],
              [
                -43.97673823299474,
                -22.149151884922436
              ],
              [
                -43.97838072663976,
                -22.146162410048284
              ],
              [
                -43.96062989632729,
                -22.140074399760344
              ],
              [
                -43.95286546288017,
                -22.14097487331158
              ],
              [
                -43.94036556370243,
                -22.131653890702633
              ],
              [
                -43.89724748820822,
                -22.118108829683493
              ],
              [
                -43.89481253042295,
                -22.116037362792174
              ],
              [
                -43.89237805763593,
                -22.113964877909222
              ],
              [
                -43.89057305163057,
                -22.11384436988362
              ],
              [
                -43.880179149742894,
                -22.113149841710296
              ],
              [
                -43.879930659094704,
                -22.108339874126745
              ],
              [
                -43.87951521542914,
                -22.100296386293568
              ],
              [
                -43.87100726690421,
                -22.103431871812507
              ],
              [
                -43.87031526654672,
                -22.099483868792422
              ],
              [
                -43.86190783671555,
                -22.099040884477553
              ],
              [
                -43.857234914168195,
                -22.094647897500135
              ],
              [
                -43.85027645699411,
                -22.096509857365994
              ],
              [
                -43.8429699975699,
                -22.098464844875604
              ],
              [
                -43.82311770881893,
                -22.090277307085042
              ],
              [
                -43.81763877345773,
                -22.086837818307064
              ],
              [
                -43.816337753142314,
                -22.086021819069586
              ],
              [
                -43.81427378057407,
                -22.08584083569931
              ],
              [
                -43.81067635069981,
                -22.085525824080612
              ],
              [
                -43.810788315870155,
                -22.084798309013287
              ],
              [
                -43.81116683680087,
                -22.082347334266146
              ],
              [
                -43.80175842124753,
                -22.08205931666225
              ],
              [
                -43.799840448055576,
                -22.082000824053846
              ],
              [
                -43.79848294958273,
                -22.08106528827551
              ],
              [
                -43.77701219030198,
                -22.066281843597984
              ],
              [
                -43.76834471719931,
                -22.067066320536966
              ],
              [
                -43.765570259909275,
                -22.06242383076212
              ],
              [
                -43.76352926352527,
                -22.07232826783385
              ],
              [
                -43.763438771027964,
                -22.07276475541204
              ],
              [
                -43.7626432727941,
                -22.073151761438933
              ],
              [
                -43.75579781341338,
                -22.076483242242816
              ],
              [
                -43.75742029584885,
                -22.078008738727725
              ],
              [
                -43.760083302374305,
                -22.08051372295484
              ],
              [
                -43.748165355880204,
                -22.086616157898305
              ],
              [
                -43.74596335968084,
                -22.087743647617067
              ],
              [
                -43.74606390337823,
                -22.08857012972583
              ],
              [
                -43.746514361566604,
                -22.092277625270338
              ],
              [
                -43.74077041628787,
                -22.094367590112952
              ],
              [
                -43.73357695564842,
                -22.09610109904281
              ],
              [
                -43.7233050878875,
                -22.084858600263058
              ],
              [
                -43.71951513898202,
                -22.080711129422856
              ],
              [
                -43.713943664786584,
                -22.080908606217676
              ],
              [
                -43.70945771127856,
                -22.081068118973505
              ],
              [
                -43.708905736392886,
                -22.078915631379907
              ],
              [
                -43.70830322752359,
                -22.076566114648394
              ],
              [
                -43.699816826766806,
                -22.077406592784797
              ],
              [
                -43.69142788215989,
                -22.070413111552348
              ],
              [
                -43.68149000741902,
                -22.071121089667347
              ],
              [
                -43.67875700474443,
                -22.077059542843223
              ],
              [
                -43.673224996547304,
                -22.08907697932673
              ],
              [
                -43.66813456926079,
                -22.085167487003538
              ],
              [
                -43.66578809678308,
                -22.07626100781075
              ],
              [
                -43.665181635800266,
                -22.073960550836016
              ],
              [
                -43.656017184715004,
                -22.0728465380955
              ],
              [
                -43.65215126458019,
                -22.06661555860289
              ],
              [
                -43.64395882013451,
                -22.06581551677286
              ],
              [
                -43.637845358088626,
                -22.06792849357524
              ],
              [
                -43.631811387104605,
                -22.072857979402016
              ],
              [
                -43.627900450747305,
                -22.07605292036375
              ],
              [
                -43.62065851378202,
                -22.075008433186785
              ],
              [
                -43.619080000794874,
                -22.083100389433607
              ],
              [
                -43.611932576167575,
                -22.081322899185224
              ],
              [
                -43.610680099987206,
                -22.071107936433584
              ],
              [
                -43.60329516968302,
                -22.071632392003387
              ],
              [
                -43.602739665776845,
                -22.0698354166417
              ],
              [
                -43.60092522374335,
                -22.063965444598377
              ],
              [
                -43.59923975636417,
                -22.05851148479421
              ],
              [
                -43.59552324957196,
                -22.059577457973646
              ],
              [
                -43.59140131826235,
                -22.05621545944027
              ],
              [
                -43.58566135389097,
                -22.05153195846362
              ],
              [
                -43.58410831827428,
                -22.070034362253796
              ],
              [
                -43.574321382083966,
                -22.077327817612442
              ],
              [
                -43.5690229350704,
                -22.078394784761436
              ],
              [
                -43.56823244179378,
                -22.081180776711225
              ],
              [
                -43.56651394902432,
                -22.087234225837307
              ],
              [
                -43.56217448160199,
                -22.08730023846648
              ],
              [
                -43.55547656573071,
                -22.081553233397234
              ],
              [
                -43.52531285157054,
                -22.07156423811917
              ],
              [
                -43.52411585703669,
                -22.069497239508454
              ],
              [
                -43.519410910751986,
                -22.061372267567045
              ],
              [
                -43.51318247041781,
                -22.058830243590787
              ],
              [
                -43.505327522622,
                -22.06609072699981
              ],
              [
                -43.50570300046958,
                -22.067761686517418
              ],
              [
                -43.50710451100028,
                -22.07401067453329
              ],
              [
                -43.491763638856575,
                -22.073252647413423
              ],
              [
                -43.48403118076419,
                -22.07135764735731
              ],
              [
                -43.47551479307042,
                -22.069270126291723
              ],
              [
                -43.47249377951036,
                -22.06991612736448
              ],
              [
                -43.462044890051565,
                -22.072150582570327
              ],
              [
                -43.44263708988862,
                -22.061987595726013
              ],
              [
                -43.43676866101303,
                -22.058914590393208
              ],
              [
                -43.42776223029091,
                -22.05419761343487
              ],
              [
                -43.421785805147714,
                -22.053599081326972
              ],
              [
                -43.418847325631525,
                -22.053304096378326
              ],
              [
                -43.36815232271436,
                -22.02000412583269
              ],
              [
                -43.368035354867935,
                -22.019614143514588
              ],
              [
                -43.36548937376015,
                -22.011117188174353
              ],
              [
                -43.35406298931764,
                -22.008754154317025
              ],
              [
                -43.35167450159338,
                -22.008260157795227
              ],
              [
                -43.3464290885519,
                -22.00337166536593
              ],
              [
                -43.33895513334762,
                -22.010068119523506
              ],
              [
                -43.3304572125456,
                -22.010404084377612
              ],
              [
                -43.320100314489046,
                -22.006428090438682
              ],
              [
                -43.318627810576956,
                -22.008416592628535
              ],
              [
                -43.31696731205294,
                -22.013939527399916
              ],
              [
                -43.3164848026075,
                -22.01554505903201
              ],
              [
                -43.30056893414333,
                -22.019144482610415
              ],
              [
                -43.283724617785424,
                -22.012067497084963
              ],
              [
                -43.28009762188506,
                -22.01054348168709
              ],
              [
                -43.274749697780905,
                -22.011171952016316
              ],
              [
                -43.27471820004305,
                -22.011176444914113
              ],
              [
                -43.27290421253235,
                -22.01164496596203
              ],
              [
                -43.269043246113284,
                -22.012386958462105
              ],
              [
                -43.26559924535955,
                -22.010262427582685
              ],
              [
                -43.261989813344975,
                -22.008036443805178
              ],
              [
                -43.254375866859355,
                -22.011493899049267
              ],
              [
                -43.24612643630445,
                -22.006537924473466
              ],
              [
                -43.23619850907204,
                -22.012754862857832
              ],
              [
                -43.236288501787186,
                -22.019831326099517
              ],
              [
                -43.236320989136495,
                -22.022373799254307
              ],
              [
                -43.22733803701743,
                -22.02742925292109
              ],
              [
                -43.22199809809501,
                -22.031090756851597
              ],
              [
                -43.21291969197471,
                -22.02988472811582
              ],
              [
                -43.20440125510212,
                -22.02586970408625
              ],
              [
                -43.202607293240355,
                -22.02502424587068
              ],
              [
                -43.202788274426126,
                -22.02579471140668
              ],
              [
                -43.20492124346769,
                -22.034874188594745
              ],
              [
                -43.20222824624609,
                -22.036522185430226
              ],
              [
                -43.19790130377295,
                -22.03490718674634
              ],
              [
                -43.175241010114696,
                -22.02451116860454
              ],
              [
                -43.16912456374973,
                -22.021705687499743
              ],
              [
                -43.164290620916866,
                -22.022442168590533
              ],
              [
                -43.16366611074162,
                -22.025082637574705
              ],
              [
                -43.1629711233226,
                -22.02802013287004
              ],
              [
                -43.154708149322765,
                -22.03192960417688
              ],
              [
                -43.15238820479001,
                -22.031528090384946
              ],
              [
                -43.14715073825629,
                -22.03062157482656
              ],
              [
                -43.14373327562427,
                -22.03322957494896
              ],
              [
                -43.13360083472899,
                -22.03032556425777
              ],
              [
                -43.13066089690666,
                -22.02948256316021
              ],
              [
                -43.129682372417626,
                -22.035033031424224
              ],
              [
                -43.12787337006801,
                -22.046155466225887
              ],
              [
                -43.13366377559787,
                -22.05850488092601
              ],
              [
                -43.150148115642565,
                -22.071136860172516
              ],
              [
                -43.15192310290069,
                -22.074308858513113
              ],
              [
                -43.153698539150035,
                -22.07748185885262
              ],
              [
                -43.14459508233063,
                -22.10005372714863
              ],
              [
                -43.136462113138705,
                -22.10880465615563
              ],
              [
                -43.12717622724384,
                -22.104411678059375
              ],
              [
                -43.124183790285535,
                -22.095517181737943
              ],
              [
                -43.12244681848814,
                -22.090353716620072
              ],
              [
                -43.10632747346697,
                -22.08354171466321
              ],
              [
                -43.089068084375555,
                -22.087449646304236
              ],
              [
                -43.0775957280456,
                -22.08361363794944
              ],
              [
                -43.075838213663324,
                -22.08733563757263
              ],
              [
                -43.07299821011706,
                -22.093352102789083
              ],
              [
                -43.069138759623314,
                -22.091395581033268
              ],
              [
                -43.064809828879035,
                -22.089220584211308
              ],
              [
                -43.05762037337688,
                -22.08526408626904
              ],
              [
                -43.053000422603,
                -22.082599590219118
              ],
              [
                -43.05052844815512,
                -22.080915611580718
              ],
              [
                -43.043534543501984,
                -22.07538262518858
              ],
              [
                -43.035270601225946,
                -22.0687286323356
              ],
              [
                -43.032182132115324,
                -22.066030619331947
              ],
              [
                -43.03217718935977,
                -22.05973666941996
              ],
              [
                -43.03285815165206,
                -22.058953669124616
              ],
              [
                -43.03848311802634,
                -22.052485735246975
              ],
              [
                -43.03816216898392,
                -22.04655373170338
              ],
              [
                -43.025204785241996,
                -22.039753753876536
              ],
              [
                -43.00337397712168,
                -22.032679248231737
              ],
              [
                -42.99382255055568,
                -22.034880719034405
              ],
              [
                -42.992840553518576,
                -22.035106704417576
              ],
              [
                -42.9893591339505,
                -22.032718225891443
              ],
              [
                -42.98587765238266,
                -22.03032969636875
              ],
              [
                -42.96599488465818,
                -22.0144287538498
              ],
              [
                -42.96147739929268,
                -22.014431257526475
              ],
              [
                -42.95929944140125,
                -22.014432745066298
              ],
              [
                -42.94649355466006,
                -22.006139248452513
              ],
              [
                -42.914190899190515,
                -21.992238735868476
              ],
              [
                -42.90866294716619,
                -21.98999725854287
              ],
              [
                -42.88236373711099,
                -21.966648307621675
              ],
              [
                -42.88222723838672,
                -21.963132821319665
              ],
              [
                -42.882090249667456,
                -21.95961634602347
              ],
              [
                -42.83321169076532,
                -21.943994808481218
              ],
              [
                -42.82850075756172,
                -21.942456307663782
              ],
              [
                -42.82849625760554,
                -21.942454815660223
              ],
              [
                -42.823781778438885,
                -21.940914821844796
              ],
              [
                -42.79119210219604,
                -21.930659757545598
              ],
              [
                -42.785643666954016,
                -21.928215783334775
              ],
              [
                -42.78304420915427,
                -21.927043786908328
              ],
              [
                -42.775539249796964,
                -21.923660772680382
              ],
              [
                -42.77295480056516,
                -21.922594283752666
              ],
              [
                -42.77294679464687,
                -21.922590308753286
              ],
              [
                -42.76620888483165,
                -21.919808763345564
              ],
              [
                -42.7562239507608,
                -21.91520829425686
              ],
              [
                -42.755633963723646,
                -21.914936791191383
              ],
              [
                -42.75538645818121,
                -21.914838307084562
              ],
              [
                -42.75538295821631,
                -21.91483679408363
              ],
              [
                -42.74896703189996,
                -21.91229179127629
              ],
              [
                -42.738754633138285,
                -21.90758530020096
              ],
              [
                -42.73209668483173,
                -21.904030264019617
              ],
              [
                -42.710077919268656,
                -21.89359030030428
              ],
              [
                -42.70782744035368,
                -21.892436275685235
              ],
              [
                -42.702657974379115,
                -21.88978629855971
              ],
              [
                -42.70014251435147,
                -21.88878877848003
              ],
              [
                -42.70013951538208,
                -21.88878729248025
              ],
              [
                -42.69942804144489,
                -21.88850531117423
              ],
              [
                -42.697765524476374,
                -21.887660800417713
              ],
              [
                -42.69776104452154,
                -21.887658789417003
              ],
              [
                -42.685962164395306,
                -21.881665317160593
              ],
              [
                -42.680653700389726,
                -21.87910579324644
              ],
              [
                -42.67364478400623,
                -21.877020808679912
              ],
              [
                -42.66730384745751,
                -21.87327579435045
              ],
              [
                -42.65518397118379,
                -21.871506263533274
              ],
              [
                -42.65140799279848,
                -21.869261809804257
              ],
              [
                -42.636897135651274,
                -21.86465228778926
              ],
              [
                -42.62875972345027,
                -21.861783296533808
              ],
              [
                -42.62062329924454,
                -21.85891428029064
              ],
              [
                -42.60812992674893,
                -21.85169276283945
              ],
              [
                -42.60567695203249,
                -21.8539272742179
              ],
              [
                -42.6047784326324,
                -21.854745257765835
              ],
              [
                -42.59778702752016,
                -21.853229741364768
              ],
              [
                -42.59365053106397,
                -21.851782265657803
              ],
              [
                -42.58050169848426,
                -21.84593123354584
              ],
              [
                -42.581304700822876,
                -21.841187770104654
              ],
              [
                -42.58197466511287,
                -21.83722878827254
              ],
              [
                -42.566752326971766,
                -21.830961810259357
              ],
              [
                -42.55761492744941,
                -21.82779580117837
              ],
              [
                -42.46546035030926,
                -21.78727830519736
              ],
              [
                -42.46380783593108,
                -21.786552315921515
              ],
              [
                -42.46023989388572,
                -21.784961301449957
              ],
              [
                -42.43169364998648,
                -21.774575805552086
              ],
              [
                -42.39071954754905,
                -21.75966877794851
              ],
              [
                -42.378947710297105,
                -21.750106803043124
              ],
              [
                -42.37636321900164,
                -21.748007316681324
              ],
              [
                -42.35612340827619,
                -21.74139728273997
              ],
              [
                -42.34576949065361,
                -21.7435757590826
              ],
              [
                -42.33541309406811,
                -21.745754226420882
              ],
              [
                -42.31170133281364,
                -21.735314241107407
              ],
              [
                -42.30924883527745,
                -21.73216925056593
              ],
              [
                -42.30679639873893,
                -21.729024257028104
              ],
              [
                -42.29948042860798,
                -21.72608774454896
              ],
              [
                -42.271127229041944,
                -21.714705749031143
              ],
              [
                -42.26620128768708,
                -21.707858766885114
              ],
              [
                -42.26754026518818,
                -21.70665979442124
              ],
              [
                -42.26899379396374,
                -21.705357771775635
              ],
              [
                -42.271571248604765,
                -21.703049813131955
              ],
              [
                -42.27050928234542,
                -21.70112932760391
              ],
              [
                -42.26689982243884,
                -21.694602838002883
              ],
              [
                -42.27023029630667,
                -21.68119892831861
              ],
              [
                -42.274871778576724,
                -21.676418430648827
              ],
              [
                -42.2786832525906,
                -21.678320943946414
              ],
              [
                -42.28295170693374,
                -21.68045044316906
              ],
              [
                -42.28623722694676,
                -21.67324497890312
              ],
              [
                -42.286701712672645,
                -21.672227486362857
              ],
              [
                -42.28265272319554,
                -21.670165514324452
              ],
              [
                -42.30153462985142,
                -21.654519611193365
              ],
              [
                -42.30935005427997,
                -21.658303617360623
              ],
              [
                -42.31619897390795,
                -21.654661165089006
              ],
              [
                -42.31944396047401,
                -21.659251628932594
              ],
              [
                -42.31972442389056,
                -21.659649138532515
              ],
              [
                -42.32103891525504,
                -21.658579645329787
              ],
              [
                -42.32453792226834,
                -21.655733174744835
              ],
              [
                -42.325668883787856,
                -21.656628668804483
              ],
              [
                -42.331452348964895,
                -21.66120766989007
              ],
              [
                -42.33778577650183,
                -21.66139767230001
              ],
              [
                -42.34051023883683,
                -21.660178188311345
              ],
              [
                -42.34238725291994,
                -21.65778620239943
              ],
              [
                -42.342250263588156,
                -21.655465700215103
              ],
              [
                -42.343507742761226,
                -21.64997572501829
              ],
              [
                -42.34627225519689,
                -21.642482311980366
              ],
              [
                -42.35508417286364,
                -21.64698879680804
              ],
              [
                -42.364603060143516,
                -21.645799826182714
              ],
              [
                -42.36876855589539,
                -21.639144335154402
              ],
              [
                -42.36052216016259,
                -21.630700863319536
              ],
              [
                -42.355308704020246,
                -21.62536190961674
              ],
              [
                -42.35889217169089,
                -21.62093543351232
              ],
              [
                -42.360537155147966,
                -21.62166392369211
              ],
              [
                -42.364713147147675,
                -21.623514919142053
              ],
              [
                -42.368575100282996,
                -21.619118481554732
              ],
              [
                -42.35272633562663,
                -21.591569581467915
              ],
              [
                -42.350217349924186,
                -21.588268078696892
              ],
              [
                -42.34582591169318,
                -21.582491600348902
              ],
              [
                -42.325534596691526,
                -21.569530129203372
              ],
              [
                -42.32674060499459,
                -21.563406660276744
              ],
              [
                -42.32697963124004,
                -21.562194163222575
              ],
              [
                -42.33072808090409,
                -21.56011666420815
              ],
              [
                -42.31811122194327,
                -21.554811202532566
              ],
              [
                -42.310033774638335,
                -21.551414661833398
              ],
              [
                -42.31017030874022,
                -21.546600193464034
              ],
              [
                -42.302511416319945,
                -21.53901071883704
              ],
              [
                -42.303311370710766,
                -21.536674245054908
              ],
              [
                -42.30592490271781,
                -21.529040276511903
              ],
              [
                -42.29669946891871,
                -21.524053305450295
              ],
              [
                -42.29421001393555,
                -21.524763780699345
              ],
              [
                -42.28574008105093,
                -21.527179249533262
              ],
              [
                -42.27334423329075,
                -21.503676857314087
              ],
              [
                -42.25439644598743,
                -21.494095351097656
              ],
              [
                -42.25234897194575,
                -21.489778870909998
              ],
              [
                -42.27099134050029,
                -21.475177012781664
              ],
              [
                -42.26820186983414,
                -21.46837202493205
              ],
              [
                -42.277271275944,
                -21.46503605535896
              ],
              [
                -42.29220417224769,
                -21.459543631273394
              ],
              [
                -42.28590626615193,
                -21.449233682458683
              ],
              [
                -42.28523975003289,
                -21.448141690612186
              ],
              [
                -42.28390178885416,
                -21.445951666940296
              ],
              [
                -42.284771269838956,
                -21.443891182898685
              ],
              [
                -42.285638283828554,
                -21.4418367168197
              ],
              [
                -42.28238083526486,
                -21.438306207602977
              ],
              [
                -42.279245862253234,
                -21.43490873398389
              ],
              [
                -42.280108351886724,
                -21.432997246142605
              ],
              [
                -42.28049585382265,
                -21.43213873160393
              ],
              [
                -42.27935436437153,
                -21.431236238620205
              ],
              [
                -42.2752189148345,
                -21.427966264930497
              ],
              [
                -42.27606641145842,
                -21.423552769259235
              ],
              [
                -42.27865140356684,
                -21.4100833435692
              ],
              [
                -42.267187564202295,
                -21.39968339095598
              ],
              [
                -42.26480307271918,
                -21.403162384854962
              ],
              [
                -42.260400588981774,
                -21.409585816357215
              ],
              [
                -42.23872980284648,
                -21.401334811927285
              ],
              [
                -42.23752584573672,
                -21.392464856922306
              ],
              [
                -42.24861522351081,
                -21.389665885317044
              ],
              [
                -42.249148237174424,
                -21.389531387306526
              ],
              [
                -42.25119870824605,
                -21.385447420811744
              ],
              [
                -42.24086181475965,
                -21.377660440196674
              ],
              [
                -42.24056135402897,
                -21.37743445267153
              ],
              [
                -42.24069484387134,
                -21.376694962902167
              ],
              [
                -42.241423825459634,
                -21.37266048198639
              ],
              [
                -42.23984738074246,
                -21.37179296179684
              ],
              [
                -42.22945194310862,
                -21.366074983140347
              ],
              [
                -42.23219748275385,
                -21.353661065409188
              ],
              [
                -42.22896502150677,
                -21.35064607063009
              ],
              [
                -42.235050985751734,
                -21.34406212004996
              ],
              [
                -42.23580844930059,
                -21.343243130198353
              ],
              [
                -42.234828454216476,
                -21.342778120314872
              ],
              [
                -42.223707065759065,
                -21.337497107670668
              ],
              [
                -42.22850508161426,
                -21.32824816313274
              ],
              [
                -42.228999563869074,
                -21.32802115751761
              ],
              [
                -42.2317970206782,
                -21.326738200002527
              ],
              [
                -42.236274487764994,
                -21.32468422358848
              ],
              [
                -42.23781300288214,
                -21.318187765679312
              ],
              [
                -42.22942560271232,
                -21.307963284811457
              ],
              [
                -42.22857116857605,
                -21.283935405161845
              ],
              [
                -42.22298071984633,
                -21.276957928835085
              ],
              [
                -42.21698178383751,
                -21.27398693629052
              ],
              [
                -42.21211186235103,
                -21.271574941477983
              ],
              [
                -42.203636953137476,
                -21.261249999087667
              ],
              [
                -42.18928308290135,
                -21.249404994813677
              ],
              [
                -42.194314567496534,
                -21.23906906171028
              ],
              [
                -42.18836715972617,
                -21.229554604129927
              ],
              [
                -42.19099214422955,
                -21.223764647154592
              ],
              [
                -42.19691560533675,
                -21.225846672174175
              ],
              [
                -42.19832808604395,
                -21.22467567475861
              ],
              [
                -42.20253003339742,
                -21.22119319826498
              ],
              [
                -42.20222757442218,
                -21.217734192934095
              ],
              [
                -42.201477091269616,
                -21.209144252823076
              ],
              [
                -42.197318123980125,
                -21.204325249567578
              ],
              [
                -42.19480968496044,
                -21.20141876206761
              ],
              [
                -42.198197639420634,
                -21.195368326303964
              ],
              [
                -42.20807962592045,
                -21.177718926683063
              ],
              [
                -42.200327169192185,
                -21.173676450797505
              ],
              [
                -42.19931818750917,
                -21.173150414203278
              ],
              [
                -42.199040700098685,
                -21.171243960693882
              ],
              [
                -42.19844222144033,
                -21.16714144711589
              ],
              [
                -42.19843769957183,
                -21.16710746828605
              ],
              [
                -42.19842220073152,
                -21.167098951294662
              ],
              [
                -42.19235576670442,
                -21.16395595064077
              ],
              [
                -42.19507024719554,
                -21.16008648668597
              ],
              [
                -42.195443236340076,
                -21.159554514403705
              ],
              [
                -42.19040135276969,
                -21.147317036640434
              ],
              [
                -42.18839133273849,
                -21.14611254429513
              ],
              [
                -42.17899445676863,
                -21.140481069045975
              ],
              [
                -42.178039484184964,
                -21.137138589602245
              ],
              [
                -42.17537998392499,
                -21.132200095634932
              ],
              [
                -42.170237563183356,
                -21.128145091086015
              ],
              [
                -42.17024557481777,
                -21.11411568394195
              ],
              [
                -42.16710164120999,
                -21.107538198604498
              ],
              [
                -42.163957205595885,
                -21.100961723271823
              ],
              [
                -42.15777375614589,
                -21.1013282237012
              ],
              [
                -42.139871884203956,
                -21.102390668728276
              ],
              [
                -42.13561044752543,
                -21.09391672892419
              ],
              [
                -42.133710469085166,
                -21.08503828087473
              ],
              [
                -42.132757013432645,
                -21.080583276432016
              ],
              [
                -42.12806706781288,
                -21.078340797351533
              ],
              [
                -42.11994411187812,
                -21.074456309956656
              ],
              [
                -42.119336142139595,
                -21.068890838282158
              ],
              [
                -42.10093137399081,
                -21.04927189987621
              ],
              [
                -42.1017698353135,
                -21.047130892310445
              ],
              [
                -42.10247483290497,
                -21.045329895610763
              ],
              [
                -42.09854492029316,
                -21.039072426859434
              ],
              [
                -42.10070489386921,
                -21.033966449282698
              ],
              [
                -42.09333146746317,
                -21.036761945962425
              ],
              [
                -42.09299946449684,
                -21.036722933390134
              ],
              [
                -42.08005706077789,
                -21.035197909109506
              ],
              [
                -42.080518585345466,
                -21.03345893951059
              ],
              [
                -42.08155559763192,
                -21.029546942907007
              ],
              [
                -42.08450255572049,
                -21.022388969187894
              ],
              [
                -42.10228491427843,
                -21.013972577090183
              ],
              [
                -42.10733091092389,
                -21.01353906827683
              ],
              [
                -42.11129986304736,
                -21.011240099924816
              ],
              [
                -42.11464733369419,
                -21.0093011171828
              ],
              [
                -42.11527682635009,
                -21.006973132134156
              ],
              [
                -42.116597330714576,
                -21.00209315438317
              ],
              [
                -42.12110777067116,
                -21.002568683438263
              ],
              [
                -42.126084755385364,
                -21.003093200329413
              ],
              [
                -42.14082513823351,
                -20.992662783856716
              ],
              [
                -42.14112014751875,
                -20.987452781460508
              ],
              [
                -42.14116766735948,
                -20.986604806115224
              ],
              [
                -42.15018258816287,
                -20.98012585901245
              ],
              [
                -42.15032260708673,
                -20.979315864681123
              ],
              [
                -42.151261086270154,
                -20.973889382961534
              ],
              [
                -42.137993239004075,
                -20.960064427908545
              ],
              [
                -42.125074397416356,
                -20.955349416879255
              ],
              [
                -42.12481237397626,
                -20.955631426751864
              ],
              [
                -42.12145590596187,
                -20.959242913505555
              ],
              [
                -42.11518043964326,
                -20.95873289551866
              ],
              [
                -42.11484047896454,
                -20.95033445578031
              ],
              [
                -42.11481647275996,
                -20.949738456922056
              ],
              [
                -42.110689029425,
                -20.94812895788137
              ],
              [
                -42.1090125049436,
                -20.947474950460684
              ],
              [
                -42.10789006905264,
                -20.946641946300097
              ],
              [
                -42.093237189130924,
                -20.93576499135976
              ],
              [
                -42.090134716655115,
                -20.93720947033549
              ],
              [
                -42.087032726177746,
                -20.93865344431491
              ],
              [
                -42.06533193292768,
                -20.938145420227173
              ],
              [
                -42.045775134757704,
                -20.93275538142607
              ],
              [
                -42.04576159890369,
                -20.93274537444818
              ],
              [
                -42.038126193607624,
                -20.92695990569523
              ],
              [
                -42.02736577876096,
                -20.92764087387222
              ],
              [
                -42.02295883969279,
                -20.924983381864358
              ],
              [
                -42.01618339142836,
                -20.928029338640883
              ],
              [
                -42.01607189427873,
                -20.928079849108517
              ],
              [
                -42.00417948109499,
                -20.931540787691578
              ],
              [
                -41.998265068041064,
                -20.92597684281118
              ],
              [
                -41.97625673047183,
                -20.935010730770284
              ],
              [
                -41.97899124430098,
                -20.922925289220462
              ],
              [
                -41.979230751984446,
                -20.921867295476424
              ],
              [
                -41.972459327304136,
                -20.915619828313826
              ],
              [
                -41.97138280443853,
                -20.915760837041848
              ],
              [
                -41.96692534226731,
                -20.916342786504437
              ],
              [
                -41.963680426210516,
                -20.906017863560372
              ],
              [
                -41.96612190130589,
                -20.901225908077215
              ],
              [
                -41.96704790092815,
                -20.89940840703131
              ],
              [
                -41.964783933491155,
                -20.89237491766223
              ],
              [
                -41.9600090091462,
                -20.882336498656702
              ],
              [
                -41.95431554396975,
                -20.877579975047343
              ],
              [
                -41.94992308643262,
                -20.873911518607727
              ],
              [
                -41.95105210949089,
                -20.871624008577317
              ],
              [
                -41.95431457209979,
                -20.865013540837733
              ],
              [
                -41.94937613359202,
                -20.85746507408107
              ],
              [
                -41.94239571354565,
                -20.855828560676343
              ],
              [
                -41.93523526120526,
                -20.854149579087593
              ],
              [
                -41.929261859424265,
                -20.844472637459894
              ],
              [
                -41.92576391341662,
                -20.83759015351189
              ],
              [
                -41.934982830023046,
                -20.828069201369033
              ],
              [
                -41.92493249661702,
                -20.801085839909877
              ],
              [
                -41.92772600722416,
                -20.794499372012485
              ],
              [
                -41.91564061970091,
                -20.79230285187778
              ],
              [
                -41.905900161086564,
                -20.796743329273927
              ],
              [
                -41.900791732806844,
                -20.7957517967999
              ],
              [
                -41.896286775238536,
                -20.789019830798637
              ],
              [
                -41.88515139267663,
                -20.784792359896997
              ],
              [
                -41.878393971562936,
                -20.778225855817205
              ],
              [
                -41.878661480274495,
                -20.773610895435866
              ],
              [
                -41.87305804607068,
                -20.770440889646306
              ],
              [
                -41.87450052309149,
                -20.76632441128902
              ],
              [
                -41.866143603695896,
                -20.76526440070641
              ],
              [
                -41.85343472320674,
                -20.763652394061268
              ],
              [
                -41.84773075789308,
                -20.77464131730171
              ],
              [
                -41.8369433204212,
                -20.781075281478852
              ],
              [
                -41.82517540109771,
                -20.788096716201224
              ],
              [
                -41.826102899277394,
                -20.790627677626908
              ],
              [
                -41.828113338156996,
                -20.796110685560443
              ],
              [
                -41.81645695888734,
                -20.800445642096903
              ],
              [
                -41.81142201184213,
                -20.798753637620283
              ],
              [
                -41.806648536254464,
                -20.7971501092723
              ],
              [
                -41.80918352839837,
                -20.799992600726082
              ],
              [
                -41.81344847416543,
                -20.80477458067379
              ],
              [
                -41.80208458595852,
                -20.805049075893844
              ],
              [
                -41.800786085363406,
                -20.80508006972196
              ],
              [
                -41.79984057316166,
                -20.804149563579113
              ],
              [
                -41.79406514311393,
                -20.798467086032154
              ],
              [
                -41.77540331779736,
                -20.799393556864317
              ],
              [
                -41.76711639362034,
                -20.801500529280872
              ],
              [
                -41.76560438842143,
                -20.803635004206715
              ],
              [
                -41.76351841418454,
                -20.806579488411888
              ],
              [
                -41.76190991018118,
                -20.806670969197526
              ],
              [
                -41.75526749498802,
                -20.80704747180212
              ],
              [
                -41.750265988152755,
                -20.81703241307243
              ],
              [
                -41.749988505161596,
                -20.8175859004285
              ],
              [
                -41.749839990932706,
                -20.81741139803174
              ],
              [
                -41.74734800563851,
                -20.814487900130374
              ],
              [
                -41.74050659454901,
                -20.817798881352935
              ],
              [
                -41.7425545524505,
                -20.825050315750346
              ],
              [
                -41.74258053397841,
                -20.82514281630867
              ],
              [
                -41.74256702407712,
                -20.825150819234036
              ],
              [
                -41.73888606797726,
                -20.82732633092164
              ],
              [
                -41.74066705215837,
                -20.82963830850045
              ],
              [
                -41.73698805882844,
                -20.83609824095623
              ],
              [
                -41.74518445445321,
                -20.85281969027266
              ],
              [
                -41.73978798074585,
                -20.865598108527696
              ],
              [
                -41.74132444797569,
                -20.871379096767146
              ],
              [
                -41.728810542566585,
                -20.869568049586817
              ],
              [
                -41.72685358227263,
                -20.864086589760753
              ],
              [
                -41.71199771757005,
                -20.871072512493463
              ],
              [
                -41.718586136849275,
                -20.87501900236355
              ],
              [
                -41.722584090079756,
                -20.883538472465645
              ],
              [
                -41.726721021291056,
                -20.892352944311277
              ],
              [
                -41.72494403247061,
                -20.89675689634103
              ],
              [
                -41.722242034463754,
                -20.896736909183282
              ],
              [
                -41.71929959159233,
                -20.896715392476622
              ],
              [
                -41.721766532402654,
                -20.915109807605997
              ],
              [
                -41.722731486939026,
                -20.922302250918744
              ],
              [
                -41.73600536136746,
                -20.927983761005148
              ],
              [
                -41.73340835010982,
                -20.943279179247043
              ],
              [
                -41.719299433054665,
                -20.953188095868043
              ],
              [
                -41.71754644935037,
                -20.954419098136228
              ],
              [
                -41.71621544956948,
                -20.969435011860163
              ],
              [
                -41.71567791435879,
                -20.97549245987005
              ],
              [
                -41.714879440858155,
                -20.976470463726503
              ],
              [
                -41.71153996868094,
                -20.980558921860208
              ],
              [
                -41.710368420637074,
                -20.990952889970732
              ],
              [
                -41.71483038370152,
                -20.991471892556305
              ],
              [
                -41.71572288551186,
                -20.99157637807018
              ],
              [
                -41.72188679414901,
                -21.002444829729445
              ],
              [
                -41.72242928169961,
                -21.010631775791918
              ],
              [
                -41.71953030661731,
                -21.01282128321449
              ],
              [
                -41.71510583801997,
                -21.016162226864733
              ],
              [
                -41.71576531957567,
                -21.02243723553077
              ],
              [
                -41.721026756743015,
                -21.024014226290188
              ],
              [
                -41.72330825070225,
                -21.024697697923322
              ],
              [
                -41.7251502305304,
                -21.029926208008856
              ],
              [
                -41.72214222148349,
                -21.040774644466456
              ],
              [
                -41.72139672197855,
                -21.043461641233247
              ],
              [
                -41.7162307544152,
                -21.046626598102954
              ],
              [
                -41.71999370458642,
                -21.054722049231895
              ],
              [
                -41.72313314622423,
                -21.05567105944773
              ],
              [
                -41.72393064851175,
                -21.05591206501113
              ],
              [
                -41.728580160574694,
                -21.05013958099409
              ],
              [
                -41.731539550427975,
                -21.066318021699356
              ],
              [
                -41.7229496303452,
                -21.06792949893783
              ],
              [
                -41.72129912924616,
                -21.073108445168437
              ],
              [
                -41.735717463848914,
                -21.102282822760802
              ],
              [
                -41.72861949705432,
                -21.10633127934748
              ],
              [
                -41.72341957035642,
                -21.10363579968572
              ],
              [
                -41.71706460055695,
                -21.104834792354094
              ],
              [
                -41.716696625637425,
                -21.104904277118603
              ],
              [
                -41.71069166391329,
                -21.106037241962525
              ],
              [
                -41.707456682999364,
                -21.106647747103487
              ],
              [
                -41.70475220227362,
                -21.11068370303836
              ],
              [
                -41.71178062238194,
                -21.11351020127202
              ],
              [
                -41.717534572165455,
                -21.115824198287754
              ],
              [
                -41.71758605273773,
                -21.12333066400435
              ],
              [
                -41.70416266805445,
                -21.126645633717743
              ],
              [
                -41.705315684649435,
                -21.120319655472862
              ],
              [
                -41.698508254405176,
                -21.117938658353985
              ],
              [
                -41.69228829240367,
                -21.115762671512595
              ],
              [
                -41.68788832137911,
                -21.12143663765579
              ],
              [
                -41.681784370930146,
                -21.120549621145653
              ],
              [
                -41.67783192162331,
                -21.124837608799112
              ],
              [
                -41.67698389470711,
                -21.125757576859193
              ],
              [
                -41.6773713768027,
                -21.131948033428138
              ],
              [
                -41.67195847266285,
                -21.127137554663438
              ],
              [
                -41.67122893686416,
                -21.126489577446083
              ],
              [
                -41.66225904062503,
                -21.1245705667923
              ],
              [
                -41.66406401941889,
                -21.130655034238835
              ],
              [
                -41.66451799131985,
                -21.132184505063137
              ],
              [
                -41.66060453893189,
                -21.1333675265305
              ],
              [
                -41.65305057667877,
                -21.136883980907
              ],
              [
                -41.65370859496793,
                -21.14247295034358
              ],
              [
                -41.64638214768414,
                -21.144115930341492
              ],
              [
                -41.638540203686375,
                -21.145872394522065
              ],
              [
                -41.63023627366762,
                -21.144295374582175
              ],
              [
                -41.628493302035096,
                -21.143964887283953
              ],
              [
                -41.62689331318236,
                -21.145119891316217
              ],
              [
                -41.613123905133534,
                -21.155057806542892
              ],
              [
                -41.60025955146396,
                -21.15168426062084
              ],
              [
                -41.5873956648017,
                -21.14831126871642
              ],
              [
                -41.58438367661437,
                -21.157706212019885
              ],
              [
                -41.58179565416722,
                -21.16577765945665
              ],
              [
                -41.574645214673424,
                -21.168067150384466
              ],
              [
                -41.57030676490245,
                -21.170834603315438
              ],
              [
                -41.568879749316295,
                -21.179209559836416
              ],
              [
                -41.56531125400824,
                -21.182236036172934
              ],
              [
                -41.558025848867594,
                -21.172445577897335
              ],
              [
                -41.548028418439,
                -21.182364498031514
              ],
              [
                -41.53050058249666,
                -21.17932997139027
              ],
              [
                -41.517438170640204,
                -21.186524922035144
              ],
              [
                -41.51370823445769,
                -21.17877096614082
              ],
              [
                -41.512520765589855,
                -21.17630197668609
              ],
              [
                -41.507660279929496,
                -21.1755234595265
              ],
              [
                -41.50172332030158,
                -21.181352402196598
              ],
              [
                -41.49819484651357,
                -21.184816901254653
              ],
              [
                -41.49489838910359,
                -21.18322287515096
              ],
              [
                -41.487949437909215,
                -21.179863890040473
              ],
              [
                -41.4822269948353,
                -21.183266377304015
              ],
              [
                -41.47883702305244,
                -21.18175138156154
              ],
              [
                -41.47947047537696,
                -21.193431812997815
              ],
              [
                -41.46985604529503,
                -21.201230254438745
              ],
              [
                -41.46439661597113,
                -21.20347372556889
              ],
              [
                -41.45373069735729,
                -21.20785715797839
              ],
              [
                -41.45084471892699,
                -21.210546133719397
              ],
              [
                -41.44752722327171,
                -21.213638148276342
              ],
              [
                -41.43521981795342,
                -21.21552508132057
              ],
              [
                -41.42079198540245,
                -21.202792644359697
              ],
              [
                -41.41689052032813,
                -21.199349154849983
              ],
              [
                -41.41351905222493,
                -21.200916620510398
              ],
              [
                -41.412835549475936,
                -21.201234121199267
              ],
              [
                -41.41218356304779,
                -21.200571645256005
              ],
              [
                -41.410259576391915,
                -21.198616609326823
              ],
              [
                -41.40884512342236,
                -21.1999656327362
              ],
              [
                -41.40643111283054,
                -21.202269601652564
              ],
              [
                -41.40431914390187,
                -21.199919112422293
              ],
              [
                -41.402967147950484,
                -21.198414638396116
              ],
              [
                -41.39807222377794,
                -21.199854613190094
              ],
              [
                -41.39208476049916,
                -21.20161609769681
              ],
              [
                -41.38186583354595,
                -21.199767547843578
              ],
              [
                -41.38184786391845,
                -21.199687563234026
              ],
              [
                -41.38101588334732,
                -21.19591459969025
              ],
              [
                -41.377108894962376,
                -21.195248570178418
              ],
              [
                -41.373821931382274,
                -21.200866525136906
              ],
              [
                -41.37261242168875,
                -21.202933515140447
              ],
              [
                -41.367700956237805,
                -21.202657004178295
              ],
              [
                -41.36324300667558,
                -21.20240602713737
              ],
              [
                -41.334146761493194,
                -21.21026189679921
              ],
              [
                -41.33069176979016,
                -21.213483376317857
              ],
              [
                -41.32581629126019,
                -21.218027866367844
              ],
              [
                -41.325764825950486,
                -21.214924381034923
              ],
              [
                -41.325726307464954,
                -21.212597373533146
              ],
              [
                -41.31667287352951,
                -21.213261372835383
              ],
              [
                -41.31699388755275,
                -21.217451827912438
              ],
              [
                -41.317273888374125,
                -21.22110131982299
              ],
              [
                -41.311521441365485,
                -21.218978802892977
              ],
              [
                -41.31103242121055,
                -21.218798336729268
              ],
              [
                -41.31072692387255,
                -21.219200297842384
              ],
              [
                -41.308167441801245,
                -21.222566275664672
              ],
              [
                -41.296904014229526,
                -21.23393719388625
              ],
              [
                -41.288417098718384,
                -21.233680206486046
              ],
              [
                -41.28561763103957,
                -21.23733168720278
              ],
              [
                -41.28346863288098,
                -21.234450179782385
              ],
              [
                -41.27666469959764,
                -21.23975415022
              ],
              [
                -41.27538321628689,
                -21.235893658121803
              ],
              [
                -41.27141726530811,
                -21.23680362595229
              ],
              [
                -41.27191573124782,
                -21.232884159326336
              ],
              [
                -41.268055298180606,
                -21.232718133227962
              ],
              [
                -41.26527931430163,
                -21.232598626405704
              ],
              [
                -41.26166432987313,
                -21.236269125113207
              ],
              [
                -41.259169360386764,
                -21.23052814737783
              ],
              [
                -41.25689189661803,
                -21.22529017943348
              ],
              [
                -41.24087055704748,
                -21.220665159178797
              ],
              [
                -41.23436408196829,
                -21.225663608954424
              ],
              [
                -41.22803865193164,
                -21.22550512508893
              ],
              [
                -41.22689816765734,
                -21.227948575196
              ],
              [
                -41.225907149894944,
                -21.23007158938734
              ],
              [
                -41.22220269704096,
                -21.229683592866067
              ],
              [
                -41.22030323355019,
                -21.22948457852303
              ],
              [
                -41.22053570731367,
                -21.230299582648378
              ],
              [
                -41.221263208065764,
                -21.232846073545538
              ],
              [
                -41.21182177871352,
                -21.23540301171382
              ],
              [
                -41.204865341703254,
                -21.24341446811379
              ],
              [
                -41.203670831101384,
                -21.239368971251675
              ],
              [
                -41.19916886101636,
                -21.243653953554368
              ],
              [
                -41.19248592364857,
                -21.242197432890933
              ],
              [
                -41.1904954441028,
                -21.247993426850236
              ],
              [
                -41.187739493893204,
                -21.24303292431642
              ],
              [
                -41.18313698663092,
                -21.243952426621984
              ],
              [
                -41.18582397264278,
                -21.240912443351313
              ],
              [
                -41.18487848287975,
                -21.239484947888574
              ],
              [
                -41.182456548227414,
                -21.235826954083095
              ],
              [
                -41.18116051289002,
                -21.239201453774875
              ],
              [
                -41.18069902983801,
                -21.24040293918827
              ],
              [
                -41.174080094540514,
                -21.240596896738083
              ],
              [
                -41.17640307981597,
                -21.244352402784415
              ],
              [
                -41.16362267948327,
                -21.24272237388899
              ],
              [
                -41.16056571245598,
                -21.245511848653745
              ],
              [
                -41.15680473929294,
                -21.24015287996621
              ],
              [
                -41.141339382782434,
                -21.23823284742639
              ],
              [
                -41.13888042905635,
                -21.232080889090433
              ],
              [
                -41.13704498062195,
                -21.227488422748216
              ],
              [
                -41.13322498489439,
                -21.226322408203547
              ],
              [
                -41.124756585144034,
                -21.2284248701232
              ],
              [
                -41.11883210029755,
                -21.22989536838194
              ],
              [
                -41.1121186790426,
                -21.225109843776988
              ],
              [
                -41.10389576314257,
                -21.219247873528726
              ],
              [
                -41.1022532754043,
                -21.221689872453144
              ],
              [
                -41.09203037926607,
                -21.21815886691728
              ],
              [
                -41.09064738973453,
                -21.22115936340713
              ],
              [
                -41.081033987960936,
                -21.221490334309415
              ],
              [
                -41.074613525955904,
                -21.228507757286163
              ],
              [
                -41.07301902664123,
                -21.230250276119317
              ],
              [
                -41.0720660319452,
                -21.229580779546282
              ],
              [
                -41.07014704869203,
                -21.228233248417187
              ],
              [
                -41.05546616534359,
                -21.237663206120505
              ],
              [
                -41.05507564563431,
                -21.238121192725842
              ],
              [
                -41.04098174626161,
                -21.254668563113952
              ],
              [
                -41.0328633327389,
                -21.250235562365162
              ],
              [
                -41.02474540321236,
                -21.24580255363145
              ],
              [
                -41.006026058565055,
                -21.251673480308625
              ],
              [
                -41.00203856877864,
                -21.25870196385007
              ],
              [
                -40.99805211598076,
                -21.265730397396705
              ],
              [
                -40.99671407480551,
                -21.28283482533623
              ],
              [
                -40.994867101392565,
                -21.285098317750535
              ],
              [
                -40.985165647807875,
                -21.286468781784777
              ],
              [
                -40.97951473917638,
                -21.282052804670258
              ],
              [
                -40.977583771487915,
                -21.28054378539242
              ],
              [
                -40.97629224426476,
                -21.285394268031464
              ],
              [
                -40.97572776673591,
                -21.287511735213176
              ],
              [
                -40.971167278059006,
                -21.293794718483472
              ],
              [
                -40.97108480049927,
                -21.293908193675396
              ],
              [
                -40.96791028873669,
                -21.293625197845433
              ],
              [
                -40.965443331823586,
                -21.295159202783058
              ],
              [
                -40.964311838415306,
                -21.299534641378546
              ],
              [
                -40.95718386734529,
                -21.302958636225565
              ],
              [
                -40.95958484790033,
                -21.316531557055228
              ],
              [
                -40.96276225184929,
                -21.334486447932967
              ],
              [
                -40.963885248955314,
                -21.340832948091865
              ],
              [
                -40.964321245849185,
                -21.343924431326073
              ],
              [
                -40.96335922597418,
                -21.347890890561413
              ],
              [
                -40.96296774320831,
                -21.349505876886354
              ],
              [
                -40.961544717450806,
                -21.35536533377962
              ],
              [
                -40.960202221842636,
                -21.360894343656618
              ],
              [
                -40.96467065886802,
                -21.363099831092917
              ],
              [
                -40.974637038855235,
                -21.380248250313517
              ],
              [
                -40.9774290139552,
                -21.385077722642478
              ],
              [
                -40.9822089370627,
                -21.39334468198743
              ],
              [
                -40.98532341059205,
                -21.392030707369475
              ],
              [
                -40.98716690110765,
                -21.391253191881066
              ],
              [
                -40.991106851417825,
                -21.39400468316368
              ],
              [
                -40.997175782513324,
                -21.39829770306862
              ],
              [
                -40.99944877805778,
                -21.403577670780052
              ],
              [
                -41.002574708377665,
                -21.410946656180766
              ],
              [
                -41.003389228953736,
                -21.41286614169209
              ],
              [
                -41.006146655883285,
                -21.41936661094909
              ],
              [
                -41.00871364017774,
                -21.423367092298257
              ],
              [
                -41.011955114221244,
                -21.428190084779015
              ],
              [
                -41.01962099398413,
                -21.439594543019954
              ],
              [
                -41.02301794900238,
                -21.444647982643172
              ],
              [
                -41.025395441193766,
                -21.447444499088203
              ],
              [
                -41.028521889474675,
                -21.45105949586529
              ],
              [
                -41.03088736093668,
                -21.453793973624038
              ],
              [
                -41.037309296678416,
                -21.46113343700448
              ],
              [
                -41.041863255060434,
                -21.463654930050215
              ],
              [
                -41.047244191793574,
                -21.466634949561712
              ],
              [
                -41.055613588709626,
                -21.476714415757414
              ],
              [
                -41.05817457234527,
                -21.47984487986546
              ],
              [
                -41.05948953423858,
                -21.481452895270717
              ],
              [
                -41.06020856243594,
                -21.482330365221596
              ],
              [
                -41.06396550041927,
                -21.48692287425273
              ],
              [
                -41.071401852554246,
                -21.508235748756974
              ],
              [
                -41.07365281765874,
                -21.514830739491632
              ],
              [
                -41.071726794853916,
                -21.534979142387638
              ],
              [
                -41.07125530958449,
                -21.53963260021012
              ],
              [
                -41.07077829519371,
                -21.544348551685932
              ],
              [
                -41.05651883196268,
                -21.58422384143655
              ],
              [
                -41.05618833944147,
                -21.58514832068289
              ],
              [
                -41.04751888026612,
                -21.59593324822198
              ],
              [
                -41.04434789732984,
                -21.597575225914635
              ],
              [
                -41.036903451831755,
                -21.601429190634445
              ],
              [
                -41.0312374849707,
                -21.60431417275866
              ],
              [
                -41.02635103395107,
                -21.604288655381993
              ],
              [
                -41.019692079993746,
                -21.604215130089276
              ],
              [
                -41.0129546383125,
                -21.607644090761035
              ],
              [
                -41.00999819950668,
                -21.611497079057663
              ],
              [
                -41.0133191042619,
                -21.627593507272515
              ],
              [
                -41.02363037433989,
                -21.680236258665374
              ],
              [
                -41.02571678635524,
                -21.720551522220507
              ],
              [
                -41.02492926942548,
                -21.723874017534072
              ],
              [
                -41.02319124914469,
                -21.731212983056835
              ],
              [
                -41.022437290286156,
                -21.73439645219917
              ],
              [
                -41.02026227691844,
                -21.743579902805603
              ],
              [
                -41.013786742879894,
                -21.77092024792124
              ],
              [
                -41.01071422623718,
                -21.78389316513551
              ],
              [
                -41.007641218579884,
                -21.79686560436899
              ],
              [
                -40.99558271531649,
                -21.847776783270394
              ],
              [
                -40.99449274756516,
                -21.852378785066904
              ],
              [
                -40.99389874706875,
                -21.854885260250633
              ],
              [
                -40.991621211313046,
                -21.864499708426276
              ],
              [
                -40.986645740690086,
                -21.88550705630266
              ],
              [
                -40.983504728699195,
                -21.898766998978232
              ],
              [
                -40.98236120578321,
                -21.903594983465027
              ],
              [
                -40.97964821584543,
                -21.91505238781126
              ],
              [
                -40.97382218338077,
                -21.946767226635963
              ],
              [
                -40.97420164486634,
                -21.958456173192165
              ],
              [
                -40.97458062933827,
                -21.970143618776913
              ],
              [
                -40.98467146632552,
                -21.99923545290277
              ],
              [
                -40.98706144089095,
                -22.002461958443636
              ],
              [
                -40.992318852571756,
                -22.00932845047874
              ],
              [
                -40.99540482724181,
                -22.011689422305864
              ],
              [
                -41.00503772051567,
                -22.018899893024855
              ],
              [
                -41.0092181711977,
                -22.02202888640273
              ],
              [
                -41.01284114843886,
                -22.02387689326218
              ],
              [
                -41.03700488061456,
                -22.036199894735372
              ],
              [
                -41.07674097451856,
                -22.056464851609746
              ],
              [
                -41.07866147950504,
                -22.057444359031315
              ],
              [
                -41.08057945151713,
                -22.058422886452753
              ],
              [
                -41.110219141806674,
                -22.07744935290517
              ],
              [
                -41.12576496805656,
                -22.087448286391446
              ],
              [
                -41.13094741613514,
                -22.090780798237375
              ],
              [
                -41.1575761551472,
                -22.109571249039178
              ],
              [
                -41.161807094371255,
                -22.111615258445923
              ],
              [
                -41.18818280048014,
                -22.124357729823792
              ],
              [
                -41.19572925940431,
                -22.128003763810664
              ],
              [
                -41.19573221937215,
                -22.128005755807262
              ],
              [
                -41.20327567733026,
                -22.131649752808492
              ],
              [
                -41.221808489480644,
                -22.1388542556307
              ],
              [
                -41.23421133744163,
                -22.14367622322445
              ],
              [
                -41.241964284283156,
                -22.14669076810649
              ],
              [
                -41.24943171749962,
                -22.148701774600134
              ],
              [
                -41.26179759597741,
                -22.152033256017475
              ],
              [
                -41.2649680700791,
                -22.152887276204854
              ],
              [
                -41.26813902417587,
                -22.15374176639218
              ],
              [
                -41.29802674694512,
                -22.16179231251236
              ],
              [
                -41.30326319392717,
                -22.1632028088007
              ],
              [
                -41.30441015875128,
                -22.163512290956653
              ],
              [
                -41.31194012739295,
                -22.16554028556966
              ],
              [
                -41.33194392653009,
                -22.17092880682234
              ],
              [
                -41.34163531613307,
                -22.173539807649824
              ],
              [
                -41.351327238738335,
                -22.176150815490075
              ],
              [
                -41.37678499582893,
                -22.183007845398883
              ],
              [
                -41.38109795483298,
                -22.184169855794437
              ],
              [
                -41.38541041284289,
                -22.185331884190944
              ],
              [
                -41.41915456933521,
                -22.19442140468765
              ],
              [
                -41.42326453232978,
                -22.195528407899193
              ],
              [
                -41.43267746371081,
                -22.198063891551808
              ],
              [
                -41.444145818095095,
                -22.201152926327307
              ],
              [
                -41.471628060662916,
                -22.208555934607986
              ],
              [
                -41.48272597268272,
                -22.21154594905177
              ],
              [
                -41.4910203724022,
                -22.214696934812398
              ],
              [
                -41.5007423017921,
                -22.21839044823285
              ],
              [
                -41.537918912655606,
                -22.23248595342178
              ],
              [
                -41.56014218099303,
                -22.24149296376126
              ],
              [
                -41.56891359435554,
                -22.245037427735745
              ],
              [
                -41.57214706868018,
                -22.246344441833347
              ],
              [
                -41.57777150084575,
                -22.248617467749092
              ],
              [
                -41.58313698462898,
                -22.250785941580915
              ],
              [
                -41.62348405797809,
                -22.267090445517155
              ],
              [
                -41.62541107098861,
                -22.268052965230257
              ],
              [
                -41.627339031990815,
                -22.269015439946738
              ],
              [
                -41.68224947945663,
                -22.29642891417233
              ],
              [
                -41.687256413543224,
                -22.298922919522507
              ],
              [
                -41.68861039450451,
                -22.299597451347115
              ],
              [
                -41.69273584137849,
                -22.302829925685852
              ],
              [
                -41.7033832283775,
                -22.311154388613307
              ],
              [
                -41.72653650757656,
                -22.329255368615307
              ],
              [
                -41.740504824423624,
                -22.340175322377004
              ],
              [
                -41.741754322499496,
                -22.340786342291103
              ],
              [
                -41.74960024334585,
                -22.344623343757352
              ],
              [
                -41.7577046822851,
                -22.34866881079258
              ],
              [
                -41.766804082399155,
                -22.354087306175792
              ],
              [
                -41.772666500157996,
                -22.363106809879216
              ],
              [
                -41.77406698140878,
                -22.37366323858317
              ],
              [
                -41.772933969194554,
                -22.37413421633361
              ],
              [
                -41.76938898169091,
                -22.375605698919607
              ],
              [
                -41.766610024243995,
                -22.376760215047245
              ],
              [
                -41.77048494830849,
                -22.384185676135886
              ],
              [
                -41.76751299487493,
                -22.388380663017607
              ],
              [
                -41.77830439318887,
                -22.393315664183294
              ],
              [
                -41.784725830335915,
                -22.396597639097074
              ],
              [
                -41.788864275001835,
                -22.398451160757123
              ],
              [
                -41.79085725461675,
                -22.3993431480793
              ],
              [
                -41.793312733232725,
                -22.401232660381723
              ],
              [
                -41.79454169352762,
                -22.40217812253298
              ],
              [
                -41.797858178141134,
                -22.40382412923615
              ],
              [
                -41.81432648737945,
                -22.414439604152637
              ],
              [
                -41.817394961549894,
                -22.416315622063646
              ],
              [
                -41.82373441537845,
                -22.421355105733674
              ],
              [
                -41.8286683170209,
                -22.425264610768295
              ],
              [
                -41.836205744446936,
                -22.431237584612024
              ],
              [
                -41.83725475967694,
                -22.43206856992319
              ],
              [
                -41.84141820695792,
                -22.4353680652165
              ],
              [
                -41.85196306580779,
                -22.44609154902367
              ],
              [
                -41.85397956883727,
                -22.450237009622533
              ],
              [
                -41.85806847272743,
                -22.458756469792984
              ],
              [
                -41.85873749437673,
                -22.46200599467604
              ],
              [
                -41.85946947358156,
                -22.465560482143292
              ],
              [
                -41.859539979964865,
                -22.46590245955299
              ],
              [
                -41.860428936558144,
                -22.470221432466968
              ],
              [
                -41.86180840588784,
                -22.476922409310905
              ],
              [
                -41.8683393462695,
                -22.478041405817276
              ],
              [
                -41.87372027325726,
                -22.483204399592466
              ],
              [
                -41.87809525102557,
                -22.487400879855155
              ],
              [
                -41.88211018230199,
                -22.48734340216871
              ],
              [
                -41.88413470164178,
                -22.487192414998944
              ],
              [
                -41.89102961350258,
                -22.486677410860175
              ],
              [
                -41.8994025326045,
                -22.49431091139839
              ],
              [
                -41.91186640007602,
                -22.51025833134663
              ],
              [
                -41.914189371430865,
                -22.51332733934312
              ],
              [
                -41.91651284277517,
                -22.516397811336518
              ],
              [
                -41.92048775668236,
                -22.526604292720545
              ],
              [
                -41.92431571284163,
                -22.530272273414557
              ],
              [
                -41.93244014014536,
                -22.53754673730922
              ],
              [
                -41.93736307369024,
                -22.536673267126506
              ],
              [
                -41.94094706636988,
                -22.53160481917573
              ],
              [
                -41.94181107138185,
                -22.530318301957934
              ],
              [
                -41.943639065945796,
                -22.527595813541765
              ],
              [
                -41.95178896098071,
                -22.529719321015538
              ],
              [
                -41.95697191054947,
                -22.531488825889088
              ],
              [
                -41.96036537839602,
                -22.53419133648673
              ],
              [
                -41.9685602993122,
                -22.54241978970512
              ],
              [
                -41.97368671884985,
                -22.551946774586302
              ],
              [
                -41.976608183977575,
                -22.557375767015678
              ],
              [
                -41.98062914030797,
                -22.559699742160795
              ],
              [
                -41.97971564171698,
                -22.564428234580987
              ],
              [
                -41.98356754740389,
                -22.578488153048976
              ],
              [
                -41.98646749067503,
                -22.5890746189923
              ],
              [
                -41.98905948045158,
                -22.598365573831806
              ],
              [
                -41.99251945881219,
                -22.597244565276483
              ],
              [
                -41.99507189977926,
                -22.602470545884664
              ],
              [
                -41.997933381137095,
                -22.608328532036392
              ],
              [
                -41.99749382956249,
                -22.62734742443481
              ],
              [
                -41.997372790825246,
                -22.63618792182986
              ],
              [
                -41.997371776645934,
                -22.636252393497003
              ],
              [
                -41.9973367644814,
                -22.63881188729579
              ],
              [
                -41.9972882914204,
                -22.64240336277521
              ],
              [
                -41.99703872628803,
                -22.66065478767746
              ],
              [
                -41.99692871735254,
                -22.667462716548318
              ],
              [
                -41.996208722297176,
                -22.67070524013645
              ],
              [
                -41.98847169258804,
                -22.70274904367578
              ],
              [
                -41.9846351912524,
                -22.717286934653533
              ],
              [
                -41.98032523156227,
                -22.72315842275391
              ],
              [
                -41.97650773411376,
                -22.728809892223737
              ],
              [
                -41.97384576300086,
                -22.729804368417195
              ],
              [
                -41.97084427446787,
                -22.730273342439506
              ],
              [
                -41.967763293610346,
                -22.730754855197166
              ],
              [
                -41.961995838402096,
                -22.731366332506905
              ],
              [
                -41.95539289641245,
                -22.732066335255645
              ],
              [
                -41.95743886201732,
                -22.73686827887039
              ],
              [
                -41.94583895805916,
                -22.754149180235093
              ],
              [
                -41.94311744408251,
                -22.758006149646505
              ],
              [
                -41.939633993460205,
                -22.761000115543247
              ],
              [
                -41.93415250325728,
                -22.76551359162526
              ],
              [
                -41.93133603762848,
                -22.7678405746158
              ],
              [
                -41.925965096386086,
                -22.768970071270427
              ],
              [
                -41.922714621053736,
                -22.769797071829863
              ],
              [
                -41.91899461457546,
                -22.7707435325924
              ],
              [
                -41.91221271647783,
                -22.768638535218045
              ],
              [
                -41.90964022029003,
                -22.76190357515563
              ],
              [
                -41.91229422042075,
                -22.75512808950654
              ],
              [
                -41.904957789036125,
                -22.754810080611595
              ],
              [
                -41.900828862473354,
                -22.750560600928985
              ],
              [
                -41.89678390212991,
                -22.751433600254458
              ],
              [
                -41.88839495102951,
                -22.75355406423566
              ],
              [
                -41.88544398687577,
                -22.751706533244477
              ],
              [
                -41.88252403939838,
                -22.749533560999357
              ],
              [
                -41.88238952881323,
                -22.743322579452553
              ],
              [
                -41.882227535357714,
                -22.740220129927003
              ],
              [
                -41.87501510117625,
                -22.741507592149574
              ],
              [
                -41.87349764998723,
                -22.73733710267976
              ],
              [
                -41.86787318235792,
                -22.74177555477187
              ],
              [
                -41.872003118835806,
                -22.748098048811944
              ],
              [
                -41.87389258962692,
                -22.75126501036478
              ],
              [
                -41.86812816072606,
                -22.752811489909334
              ],
              [
                -41.86368065696036,
                -22.75404149839565
              ],
              [
                -41.86595415110449,
                -22.76136896662193
              ],
              [
                -41.87065907238424,
                -22.760541497727225
              ],
              [
                -41.87419304402002,
                -22.76521345873697
              ],
              [
                -41.8761955387042,
                -22.760911976800962
              ],
              [
                -41.877414013029686,
                -22.76730496716543
              ],
              [
                -41.87901796700849,
                -22.775021415741822
              ],
              [
                -41.88474292504504,
                -22.768442479847348
              ],
              [
                -41.88891288466465,
                -22.770156485609114
              ],
              [
                -41.88720292712058,
                -22.77351396512
              ],
              [
                -41.88659191254603,
                -22.77693492108251
              ],
              [
                -41.89194882726574,
                -22.781461910467588
              ],
              [
                -41.896818770759246,
                -22.785507388092945
              ],
              [
                -41.90014075505041,
                -22.78072945290975
              ],
              [
                -41.90440075546094,
                -22.78292044247621
              ],
              [
                -41.90649471677691,
                -22.778474457491818
              ],
              [
                -41.91125517857758,
                -22.778328998265234
              ],
              [
                -41.91699361426736,
                -22.782046979769333
              ],
              [
                -41.92270756021939,
                -22.78574997829651
              ],
              [
                -41.93140745742997,
                -22.796591927929533
              ],
              [
                -41.932590418933295,
                -22.80369291367001
              ],
              [
                -41.927543465059166,
                -22.807449364719965
              ],
              [
                -41.92966092615693,
                -22.812861859453864
              ],
              [
                -41.93256538825731,
                -22.812146854456664
              ],
              [
                -41.94140935029563,
                -22.809017903817413
              ],
              [
                -41.94477280593512,
                -22.812835880853537
              ],
              [
                -41.958120655589035,
                -22.817199404408747
              ],
              [
                -41.959713636795954,
                -22.81771987679088
              ],
              [
                -41.96690856845955,
                -22.820072915026724
              ],
              [
                -41.96965505420071,
                -22.820980904359182
              ],
              [
                -41.970026067058704,
                -22.821598385151646
              ],
              [
                -41.983374891004374,
                -22.843813801808167
              ],
              [
                -41.98569833877831,
                -22.848359307556386
              ],
              [
                -41.98584282160899,
                -22.85812674719108
              ],
              [
                -41.98581930688444,
                -22.860471723193783
              ],
              [
                -41.985806786451725,
                -22.861670234061027
              ],
              [
                -41.98260632504738,
                -22.86709620131226
              ],
              [
                -41.9808823399011,
                -22.87236116513589
              ],
              [
                -41.99080517700708,
                -22.88184365111026
              ],
              [
                -41.99894164259076,
                -22.884406139767005
              ],
              [
                -42.00141309930961,
                -22.889185611749646
              ],
              [
                -42.00507808439657,
                -22.8848361641878
              ],
              [
                -42.008176036097396,
                -22.88435514451379
              ],
              [
                -42.008230526631976,
                -22.8843476556905
              ],
              [
                -42.01169549403494,
                -22.8838716729248
              ],
              [
                -42.01993741261393,
                -22.89017064987614
              ],
              [
                -42.023694878478395,
                -22.893042138847022
              ],
              [
                -42.02540485460355,
                -22.895596142222438
              ],
              [
                -42.02764382402897,
                -22.899149640869187
              ],
              [
                -42.03085476615969,
                -22.90425162813023
              ],
              [
                -42.03387022397482,
                -22.915435545019818
              ],
              [
                -42.03616819304306,
                -22.923975530525567
              ],
              [
                -42.035777681678475,
                -22.92863901886167
              ],
              [
                -42.03571065688712,
                -22.929443484608285
              ],
              [
                -42.035359183535405,
                -22.933647462379007
              ],
              [
                -42.03396268783814,
                -22.938421447594536
              ],
              [
                -42.03177016316582,
                -22.94591741597307
              ],
              [
                -42.028455177605736,
                -22.945994914121993
              ],
              [
                -42.02556721525605,
                -22.946061885414277
              ],
              [
                -42.02122675815462,
                -22.94604638142012
              ],
              [
                -42.022623231058475,
                -22.949272375615934
              ],
              [
                -42.02490722828597,
                -22.954087840016964
              ],
              [
                -42.02575069943994,
                -22.95586733114407
              ],
              [
                -42.022692733076234,
                -22.95913932074472
              ],
              [
                -42.02071870946056,
                -22.961251805992585
              ],
              [
                -42.017665743157586,
                -22.958105305159318
              ],
              [
                -42.01418179801135,
                -22.9545218394472
              ],
              [
                -42.007415843629424,
                -22.953499808371383
              ],
              [
                -42.0085533302628,
                -22.95961126626682
              ],
              [
                -42.006168847729704,
                -22.961924752446176
              ],
              [
                -42.00284688016013,
                -22.959006251776547
              ],
              [
                -41.9966104480431,
                -22.960002735810452
              ],
              [
                -42.00001687561261,
                -22.965018741052173
              ],
              [
                -42.002973877175656,
                -22.969371705517794
              ],
              [
                -42.00746634276413,
                -22.96708124160151
              ],
              [
                -42.0130512796039,
                -22.964108778932346
              ],
              [
                -42.015682742776946,
                -22.967221273864535
              ],
              [
                -42.01274478308672,
                -22.970242742042284
              ],
              [
                -42.0176322010122,
                -22.97068376228436
              ],
              [
                -42.01879719526876,
                -22.970788755726655
              ],
              [
                -42.01883471636975,
                -22.975006728437847
              ],
              [
                -42.018860180022244,
                -22.97873921258185
              ],
              [
                -42.01409172265914,
                -22.97875968629893
              ],
              [
                -42.010056768739126,
                -22.97877718290555
              ],
              [
                -42.00744626021758,
                -22.983107145286915
              ],
              [
                -42.01210223599051,
                -22.987983161464225
              ],
              [
                -42.012466199013105,
                -22.992249623771954
              ],
              [
                -42.01285670435248,
                -22.997335084000905
              ],
              [
                -42.0153016718532,
                -22.991500633813054
              ],
              [
                -42.01768070459417,
                -22.985782660868924
              ],
              [
                -42.01881315392603,
                -22.985288660265834
              ],
              [
                -42.02697011549501,
                -22.981730715137665
              ],
              [
                -42.03100707049703,
                -22.9800047401988
              ],
              [
                -42.03450205466177,
                -22.978510740699488
              ],
              [
                -42.03418604392278,
                -22.97534577593107
              ],
              [
                -42.033723083920115,
                -22.970696280313426
              ],
              [
                -42.042472013945975,
                -22.964540844859417
              ],
              [
                -42.045618494402056,
                -22.963662370348565
              ],
              [
                -42.076338743884236,
                -22.955152461950206
              ],
              [
                -42.134954250903284,
                -22.950747107104238
              ],
              [
                -42.188510251394646,
                -22.946732751468573
              ],
              [
                -42.22841490604656,
                -22.943741378799448
              ],
              [
                -42.29103285756998,
                -22.938996515302865
              ],
              [
                -42.33090500362239,
                -22.937152117935156
              ],
              [
                -42.366879728337956,
                -22.935394742189377
              ],
              [
                -42.37844259945478,
                -22.93535775508754
              ],
              [
                -42.405857878922944,
                -22.93515830756762
              ],
              [
                -42.41163483061776,
                -22.93511632163495
              ],
              [
                -42.413972813855224,
                -22.935099355733232
              ],
              [
                -42.424423716054456,
                -22.935023350997284
              ],
              [
                -42.45482291571765,
                -22.936294927828147
              ],
              [
                -42.46272134045767,
                -22.936624940503204
              ],
              [
                -42.469738301155395,
                -22.936918465095864
              ],
              [
                -42.47298275788724,
                -22.937054460767555
              ],
              [
                -42.47696071000617,
                -22.93722047617819
              ],
              [
                -42.480281181055666,
                -22.937359484032054
              ],
              [
                -42.49337008600996,
                -22.937906520002727
              ],
              [
                -42.510179960626445,
                -22.933553053194167
              ],
              [
                -42.51372389836094,
                -22.93280758607271
              ],
              [
                -42.513730933301474,
                -22.93280656509595
              ],
              [
                -42.51728236997415,
                -22.932060090999077
              ],
              [
                -42.54519013134618,
                -22.93292564460324
              ],
              [
                -42.55536453280633,
                -22.932972196612248
              ],
              [
                -42.56554045926592,
                -22.933018713631213
              ],
              [
                -42.579171331119284,
                -22.932710719351267
              ],
              [
                -42.58530678832921,
                -22.93281073268549
              ],
              [
                -42.5932961927713,
                -22.933678265958807
              ],
              [
                -42.595419692221775,
                -22.933908773285335
              ],
              [
                -42.60008213387655,
                -22.93422326274695
              ],
              [
                -42.62894590179887,
                -22.936165827567773
              ],
              [
                -42.63596382373898,
                -22.936755323750113
              ],
              [
                -42.6361568418933,
                -22.936799367028243
              ],
              [
                -42.643225234325016,
                -22.938406337247986
              ],
              [
                -42.66911451571351,
                -22.94268989574798
              ],
              [
                -42.67255498941483,
                -22.943279894695493
              ],
              [
                -42.67918493278059,
                -22.946536903550367
              ],
              [
                -42.682819370554895,
                -22.948190409682866
              ],
              [
                -42.68353484667234,
                -22.951018902383062
              ],
              [
                -42.68368584153119,
                -22.95161588578725
              ],
              [
                -42.685328818537954,
                -22.95871085155
              ],
              [
                -42.69198027383863,
                -22.96192385670831
              ],
              [
                -42.69622423282996,
                -22.9577423836107
              ],
              [
                -42.70495065830131,
                -22.956796901942262
              ],
              [
                -42.713924597026654,
                -22.955706423642535
              ],
              [
                -42.732933421799586,
                -22.95693596775777
              ],
              [
                -42.74972628868692,
                -22.958021489871374
              ],
              [
                -42.753958742352474,
                -22.958295514478465
              ],
              [
                -42.761987133645256,
                -22.958814546709053
              ],
              [
                -42.768934088335776,
                -22.959066024474357
              ],
              [
                -42.774393041377024,
                -22.959263545651208
              ],
              [
                -42.77766553050406,
                -22.959225057334493
              ],
              [
                -42.80425025628769,
                -22.9611291148371
              ],
              [
                -42.81313369312217,
                -22.96162664442348
              ],
              [
                -42.82441208826731,
                -22.962618161773246
              ],
              [
                -42.82899353848963,
                -22.963021146666218
              ],
              [
                -42.84210894744766,
                -22.96399569288827
              ],
              [
                -42.84406141178564,
                -22.964121689334664
              ],
              [
                -42.85849780920034,
                -22.965055723207332
              ],
              [
                -42.87544562193176,
                -22.96615126982217
              ],
              [
                -42.884632059238264,
                -22.967941262805645
              ],
              [
                -42.90106339719087,
                -22.971143802623345
              ],
              [
                -42.90237388582588,
                -22.971399302762997
              ],
              [
                -42.91221579196939,
                -22.973317816839295
              ],
              [
                -42.918030241115204,
                -22.974451326341214
              ],
              [
                -42.9230402024046,
                -22.973929814983094
              ],
              [
                -42.94370552125825,
                -22.971781380499255
              ],
              [
                -42.95039046828825,
                -22.97108692436988
              ],
              [
                -42.95039695023406,
                -22.97108590839184
              ],
              [
                -42.957087390224835,
                -22.970390423283487
              ],
              [
                -42.99411806180656,
                -22.9691685068927
              ],
              [
                -42.99839803231369,
                -22.969725510288647
              ],
              [
                -42.99864552603066,
                -22.969757512775228
              ],
              [
                -43.00675197523485,
                -22.97081255668169
              ],
              [
                -43.01145041688687,
                -22.97142404390361
              ],
              [
                -43.01379940031742,
                -22.97629603984645
              ],
              [
                -43.01989133657244,
                -22.97562504007655
              ],
              [
                -43.02337730176706,
                -22.975306057758566
              ],
              [
                -43.027095732891325,
                -22.980576042313743
              ],
              [
                -43.029940715225095,
                -22.978130050878555
              ],
              [
                -43.032833206464666,
                -22.975577604095538
              ],
              [
                -43.0407191392064,
                -22.976119581996812
              ],
              [
                -43.044044614058336,
                -22.978022591240755
              ],
              [
                -43.051499538397415,
                -22.982245108771167
              ],
              [
                -43.05048007404981,
                -22.976779119216555
              ],
              [
                -43.048095575957,
                -22.974526101164514
              ],
              [
                -43.04592612234403,
                -22.97247711166399
              ],
              [
                -43.0539275511809,
                -22.96280668553341
              ],
              [
                -43.0687159357312,
                -22.958857231736552
              ],
              [
                -43.072110896545844,
                -22.957949751102515
              ],
              [
                -43.07436040453121,
                -22.957349277954805
              ],
              [
                -43.082253827327406,
                -22.955241303022056
              ],
              [
                -43.09406721513552,
                -22.954587829115024
              ],
              [
                -43.09755268998693,
                -22.9543953361736
              ],
              [
                -43.102354642454685,
                -22.9541298380337
              ],
              [
                -43.10544563272101,
                -22.953959350953856
              ],
              [
                -43.11064559775343,
                -22.95367136396701
              ],
              [
                -43.112240587824395,
                -22.951001898366982
              ],
              [
                -43.11516056997187,
                -22.94611393822689
              ],
              [
                -43.11966605376709,
                -22.94528993108141
              ],
              [
                -43.117887597214875,
                -22.93781145851428
              ],
              [
                -43.12916848677784,
                -22.938144472326385
              ],
              [
                -43.135299909404516,
                -22.93825652578796
              ],
              [
                -43.12636000261251,
                -22.934832482411107
              ],
              [
                -43.12572951627639,
                -22.931183526857943
              ],
              [
                -43.12492151910652,
                -22.92766453019934
              ],
              [
                -43.12278258114647,
                -22.923000559748573
              ],
              [
                -43.1188950861599,
                -22.927026005634037
              ],
              [
                -43.11488011836521,
                -22.93135801566611
              ],
              [
                -43.10822917313193,
                -22.937554938566656
              ],
              [
                -43.10356268818767,
                -22.935938928399533
              ],
              [
                -43.10163920317145,
                -22.93527295968218
              ],
              [
                -43.098417766469254,
                -22.930704944937577
              ],
              [
                -43.095013818229376,
                -22.925854955123192
              ],
              [
                -43.095255335692634,
                -22.920397503840164
              ],
              [
                -43.09547832273603,
                -22.91611750366773
              ],
              [
                -43.11078368890011,
                -22.916093066709973
              ],
              [
                -43.116761666472556,
                -22.90515161060701
              ],
              [
                -43.11698365444186,
                -22.905176131064167
              ],
              [
                -43.123966116598716,
                -22.905938161493186
              ],
              [
                -43.126855089541635,
                -22.90811013925081
              ],
              [
                -43.13053204986553,
                -22.911162134700866
              ],
              [
                -43.13307551177938,
                -22.908424647919023
              ],
              [
                -43.13506450502842,
                -22.90553915342495
              ],
              [
                -43.13684799050858,
                -22.9028441784497
              ],
              [
                -43.13435451001254,
                -22.900693175619008
              ],
              [
                -43.12984958150327,
                -22.896808706148725
              ],
              [
                -43.124227136768255,
                -22.891959193563483
              ],
              [
                -43.12667164627416,
                -22.885029268344475
              ],
              [
                -43.13109359689025,
                -22.883853263708993
              ],
              [
                -43.134729578963196,
                -22.882831784257885
              ],
              [
                -43.129949630501194,
                -22.87675880795898
              ],
              [
                -43.12237071451428,
                -22.877665282710478
              ],
              [
                -43.120390679997314,
                -22.88259524606439
              ],
              [
                -43.116143231601214,
                -22.88121724284246
              ],
              [
                -43.11400024359151,
                -22.880522254712012
              ],
              [
                -43.11389976342721,
                -22.877572254105782
              ],
              [
                -43.113509261908156,
                -22.8759107703435
              ],
              [
                -43.112899781140186,
                -22.87331877763506
              ],
              [
                -43.11155733492085,
                -22.867728278921184
              ],
              [
                -43.10750587787913,
                -22.862693318401003
              ],
              [
                -43.10647187289731,
                -22.861733820479678
              ],
              [
                -43.1050584073283,
                -22.860421335326198
              ],
              [
                -43.10243742215943,
                -22.85720734548631
              ],
              [
                -43.101231456605916,
                -22.853965351470627
              ],
              [
                -43.098529485896044,
                -22.84918285522876
              ],
              [
                -43.097934020023544,
                -22.848231370413014
              ],
              [
                -43.09478104604001,
                -22.84319839925279
              ],
              [
                -43.09319154637237,
                -22.840116379455868
              ],
              [
                -43.0944260709208,
                -22.837992888231962
              ],
              [
                -43.09671302468031,
                -22.834056938763553
              ],
              [
                -43.09539456453029,
                -22.82703646327995
              ],
              [
                -43.08596216836335,
                -22.821832949683834
              ],
              [
                -43.08008872890659,
                -22.8185924835694
              ],
              [
                -43.07465128771349,
                -22.81266849096383
              ],
              [
                -43.065732904545456,
                -22.802319010399245
              ],
              [
                -43.07036735767992,
                -22.80100304798093
              ],
              [
                -43.07469233987575,
                -22.79990857065603
              ],
              [
                -43.07483432173356,
                -22.799872534203942
              ],
              [
                -43.07864480607122,
                -22.7989085418941
              ],
              [
                -43.079259802606344,
                -22.795279072579397
              ],
              [
                -43.080685328907364,
                -22.78670762700512
              ],
              [
                -43.069669925845055,
                -22.779113653317296
              ],
              [
                -43.069168927103256,
                -22.776179150656592
              ],
              [
                -43.067869494450925,
                -22.7685786952088
              ],
              [
                -43.06558499090625,
                -22.766477211777463
              ],
              [
                -43.06229555099507,
                -22.763452220354818
              ],
              [
                -43.053585624009656,
                -22.759041688820112
              ],
              [
                -43.04996467741162,
                -22.757214711569382
              ],
              [
                -43.04459272390094,
                -22.75450420220255
              ],
              [
                -43.04216474399552,
                -22.753274224063116
              ],
              [
                -43.028885389252956,
                -22.74654521933121
              ],
              [
                -43.027115417225936,
                -22.743093741003467
              ],
              [
                -43.026440923126636,
                -22.741777241839557
              ],
              [
                -43.0303214229112,
                -22.733129755096243
              ],
              [
                -43.027443459829,
                -22.72461931121058
              ],
              [
                -43.03451089899577,
                -22.716144869848115
              ],
              [
                -43.03509792376238,
                -22.70950040859776
              ],
              [
                -43.03209545575246,
                -22.705653930087607
              ],
              [
                -43.03154898766635,
                -22.70495393917893
              ],
              [
                -43.02614699361216,
                -22.705169888153
              ],
              [
                -43.035186946121456,
                -22.69811644678174
              ],
              [
                -43.03174848573742,
                -22.692002961503114
              ],
              [
                -43.034694478218285,
                -22.690190512176166
              ],
              [
                -43.0376034619576,
                -22.68840100963871
              ],
              [
                -43.039902423392185,
                -22.690842015354324
              ],
              [
                -43.04369743255642,
                -22.684973037519516
              ],
              [
                -43.050234364418706,
                -22.68883254007444
              ],
              [
                -43.05255581176556,
                -22.684899052748225
              ],
              [
                -43.05451383775184,
                -22.681486108879948
              ],
              [
                -43.05637078751616,
                -22.684784059169207
              ],
              [
                -43.065360705679545,
                -22.6863450955641
              ],
              [
                -43.07313813989417,
                -22.682780645472317
              ],
              [
                -43.08176459680793,
                -22.67882816751255
              ],
              [
                -43.08541754767274,
                -22.677154663313498
              ],
              [
                -43.089765508589295,
                -22.68209565383001
              ],
              [
                -43.09223199421012,
                -22.684986649744832
              ],
              [
                -43.096144438215745,
                -22.68550716824834
              ],
              [
                -43.102355889079526,
                -22.68633168617308
              ],
              [
                -43.107578861038384,
                -22.68702519020168
              ],
              [
                -43.1069888435683,
                -22.693242639606254
              ],
              [
                -43.113443309002896,
                -22.688502672972902
              ],
              [
                -43.11171078141423,
                -22.693443659806665
              ],
              [
                -43.116191741487064,
                -22.695941168913052
              ],
              [
                -43.11646774547236,
                -22.700464634031313
              ],
              [
                -43.11679870027667,
                -22.705884631817813
              ],
              [
                -43.11981769709847,
                -22.708418091975997
              ],
              [
                -43.122325644356174,
                -22.71030611403748
              ],
              [
                -43.1269025887265,
                -22.71375160068014
              ],
              [
                -43.133837030369065,
                -22.714199624390872
              ],
              [
                -43.14170099432457,
                -22.707503640163072
              ],
              [
                -43.145775959949994,
                -22.707347667487948
              ],
              [
                -43.15178390080101,
                -22.707117170189004
              ],
              [
                -43.163982282256946,
                -22.710198198399354
              ],
              [
                -43.164888766131746,
                -22.711911181202424
              ],
              [
                -43.16595576968999,
                -22.71392470092314
              ],
              [
                -43.181309631839156,
                -22.72018519048628
              ],
              [
                -43.19459496503409,
                -22.722844201670384
              ],
              [
                -43.20680685732528,
                -22.72528920715488
              ],
              [
                -43.210859820259344,
                -22.72610023462713
              ],
              [
                -43.21180881034622,
                -22.726290243142323
              ],
              [
                -43.215045771944915,
                -22.726938233311788
              ],
              [
                -43.21416778295828,
                -22.727834741569374
              ],
              [
                -43.21189833291381,
                -22.73015320513581
              ],
              [
                -43.21610326398093,
                -22.73545121766551
              ],
              [
                -43.22684864182722,
                -22.739336701214878
              ],
              [
                -43.22977815394688,
                -22.738056722190628
              ],
              [
                -43.231977639519826,
                -22.737095746683448
              ],
              [
                -43.23546808862774,
                -22.735181267273102
              ],
              [
                -43.23288011232597,
                -22.739834221398443
              ],
              [
                -43.23913855732094,
                -22.740833237678988
              ],
              [
                -43.244257006374525,
                -22.74612470164963
              ],
              [
                -43.243299512108095,
                -22.74800518680862
              ],
              [
                -43.242409500650176,
                -22.749752675804647
              ],
              [
                -43.2481149349719,
                -22.753576188615064
              ],
              [
                -43.25282438838453,
                -22.756955688052543
              ],
              [
                -43.254007893421075,
                -22.75780467090846
              ],
              [
                -43.25997631207969,
                -22.763397686626423
              ],
              [
                -43.262387258717006,
                -22.765111640379008
              ],
              [
                -43.26574323102266,
                -22.7674966662572
              ],
              [
                -43.26824820563207,
                -22.769277144927837
              ],
              [
                -43.27108267706054,
                -22.772804635779707
              ],
              [
                -43.27289714412894,
                -22.77610662110128
              ],
              [
                -43.277662606300694,
                -22.78039062123179
              ],
              [
                -43.2767595985327,
                -22.78524508877833
              ],
              [
                -43.28820097222189,
                -22.80036304555344
              ],
              [
                -43.28799993255122,
                -22.80380503695413
              ],
              [
                -43.287427455155395,
                -22.80823152350495
              ],
              [
                -43.28735995118176,
                -22.808748024767155
              ],
              [
                -43.282929465658185,
                -22.815816943156555
              ],
              [
                -43.273689064147554,
                -22.81077147109431
              ],
              [
                -43.27506703994424,
                -22.818385409917703
              ],
              [
                -43.267272064689955,
                -22.82192839402092
              ],
              [
                -43.2648465731177,
                -22.82485736716901
              ],
              [
                -43.26179111264829,
                -22.828588341696747
              ],
              [
                -43.25475864477942,
                -22.837048809397306
              ],
              [
                -43.24361673506519,
                -22.84385521858158
              ],
              [
                -43.24188920391415,
                -22.850223684494097
              ],
              [
                -43.24095822966896,
                -22.85365767503816
              ],
              [
                -43.23788022472129,
                -22.86156110682286
              ],
              [
                -43.2403232095726,
                -22.864083104697837
              ],
              [
                -43.2423321882045,
                -22.868261111235267
              ],
              [
                -43.23751216204188,
                -22.87666356301164
              ],
              [
                -43.23311022501274,
                -22.873291544227442
              ],
              [
                -43.23007629007662,
                -22.871193542705182
              ],
              [
                -43.22566030487536,
                -22.875155030541322
              ],
              [
                -43.22267232406367,
                -22.873213023367445
              ],
              [
                -43.21795037385553,
                -22.875432512039204
              ],
              [
                -43.21383291556021,
                -22.872642514124713
              ],
              [
                -43.213633917721225,
                -22.872507519274727
              ],
              [
                -43.208898464458606,
                -22.86853400062194
              ],
              [
                -43.20534996924408,
                -22.871968481330885
              ],
              [
                -43.200879002049625,
                -22.876093453208384
              ],
              [
                -43.20604743569156,
                -22.88164596416579
              ],
              [
                -43.21094889954858,
                -22.886911939858624
              ],
              [
                -43.20815591731264,
                -22.892767400545132
              ],
              [
                -43.19585001284541,
                -22.88973138846157
              ],
              [
                -43.18925155787218,
                -22.888103393309034
              ],
              [
                -43.17872715703752,
                -22.891878354121413
              ],
              [
                -43.177351169920584,
                -22.89557182621463
              ],
              [
                -43.17150622524023,
                -22.901208257008808
              ],
              [
                -43.16871919694763,
                -22.904112249334084
              ],
              [
                -43.16169128965717,
                -22.904170725698105
              ],
              [
                -43.16109177447112,
                -22.90794023444096
              ],
              [
                -43.16063377580304,
                -22.912115174544862
              ],
              [
                -43.15664479023391,
                -22.91234568498685
              ],
              [
                -43.1605267341374,
                -22.916915668466544
              ],
              [
                -43.16738370150148,
                -22.915682694486577
              ],
              [
                -43.16850670622665,
                -22.915480717420838
              ],
              [
                -43.17243914937828,
                -22.916538222451173
              ],
              [
                -43.17024913954126,
                -22.919534682880073
              ],
              [
                -43.16850670622665,
                -22.915480717420838
              ],
              [
                -43.16706168597513,
                -22.920729149633708
              ],
              [
                -43.16666818909269,
                -22.922157176528646
              ],
              [
                -43.166927667791754,
                -22.922819177925845
              ],
              [
                -43.17053214108663,
                -22.931999115860627
              ],
              [
                -43.16777913726252,
                -22.937929585287375
              ],
              [
                -43.173968076374734,
                -22.94363057423562
              ],
              [
                -43.17929604060501,
                -22.94358007642001
              ],
              [
                -43.18017851384671,
                -22.947510552269264
              ],
              [
                -43.17747750533762,
                -22.94859156385205
              ],
              [
                -43.16959758012331,
                -22.951745534624617
              ],
              [
                -43.1658716294958,
                -22.947629513259827
              ],
              [
                -43.16537963352789,
                -22.947072516731442
              ],
              [
                -43.1620586632228,
                -22.943319534634785
              ],
              [
                -43.15574073666123,
                -22.942078556265326
              ],
              [
                -43.15004524459475,
                -22.95003299897248
              ],
              [
                -43.155204206109424,
                -22.951683466287363
              ],
              [
                -43.16378762845859,
                -22.953989483317073
              ],
              [
                -43.15936265310195,
                -22.957051454581777
              ],
              [
                -43.1586221385548,
                -22.96363045307319
              ],
              [
                -43.16448906931722,
                -22.963482455157436
              ],
              [
                -43.16742854489584,
                -22.963326973619218
              ],
              [
                -43.17680245976723,
                -22.969076971701707
              ],
              [
                -43.18323392106671,
                -22.973022446024405
              ],
              [
                -43.18685636405271,
                -22.981566911257143
              ],
              [
                -43.184893872214644,
                -22.982916406442715
              ],
              [
                -43.18096036960144,
                -22.98561987276919
              ],
              [
                -43.18476436336635,
                -22.987492868476266
              ],
              [
                -43.19070379121654,
                -22.990381365763522
              ],
              [
                -43.208710155380054,
                -22.989155922024647
              ],
              [
                -43.221044536343726,
                -22.98831594751908
              ],
              [
                -43.232222398337356,
                -22.99471947421588
              ],
              [
                -43.232379887675435,
                -22.994809457184818
              ],
              [
                -43.24324279499389,
                -23.001032472974615
              ],
              [
                -43.25754165475556,
                -23.001158988893554
              ],
              [
                -43.27365554552392,
                -23.001301538513
              ],
              [
                -43.27672551473102,
                -23.002861542884226
              ],
              [
                -43.27967047110063,
                -23.005379539204988
              ],
              [
                -43.28310893668103,
                -23.007390999323157
              ],
              [
                -43.283375935852064,
                -23.00754750025327
              ],
              [
                -43.28659690822291,
                -23.009599012605296
              ],
              [
                -43.285904384528095,
                -23.016048488994386
              ],
              [
                -43.29586981586542,
                -23.016046494208027
              ],
              [
                -43.29509932587588,
                -23.01173452043135
              ],
              [
                -43.302108741014855,
                -23.014734039082438
              ],
              [
                -43.305522696685735,
                -23.015809512762758
              ],
              [
                -43.31073766536383,
                -23.01530653395798
              ],
              [
                -43.35814426960207,
                -23.010742682183505
              ],
              [
                -43.36482119934405,
                -23.011582697629024
              ],
              [
                -43.43098961031685,
                -23.019978780727676
              ],
              [
                -43.43725604362572,
                -23.02084780598918
              ],
              [
                -43.443520499959206,
                -23.021715841253748
              ],
              [
                -43.45589338080941,
                -23.025788323912032
              ],
              [
                -43.465697766576135,
                -23.029093318575708
              ],
              [
                -43.466608752919484,
                -23.02995685774375
              ],
              [
                -43.470671190397006,
                -23.03380733258024
              ],
              [
                -43.47358469641421,
                -23.03199635716054
              ],
              [
                -43.473678202774025,
                -23.031937857694505
              ],
              [
                -43.47794766250634,
                -23.032522838102455
              ],
              [
                -43.48732157428518,
                -23.03381135054801
              ],
              [
                -43.49372801936443,
                -23.034691858159277
              ],
              [
                -43.49802994565075,
                -23.03727638785735
              ],
              [
                -43.5036879033704,
                -23.040726368902924
              ],
              [
                -43.505620882775005,
                -23.041905366235305
              ],
              [
                -43.50620086559623,
                -23.042258870036367
              ],
              [
                -43.50840134405203,
                -23.04751887407955
              ],
              [
                -43.51582776153245,
                -23.048613378361555
              ],
              [
                -43.52468220606863,
                -23.049845883751317
              ],
              [
                -43.52548866565012,
                -23.049957900336636
              ],
              [
                -43.53565861109783,
                -23.051372422324636
              ],
              [
                -43.53638559783638,
                -23.053923877756745
              ],
              [
                -43.53690558263479,
                -23.055748890198487
              ],
              [
                -43.54442448468156,
                -23.059637860071337
              ],
              [
                -43.54901444013067,
                -23.06200037066639
              ],
              [
                -43.550621406924634,
                -23.066239883179833
              ],
              [
                -43.55222491742342,
                -23.07058335717988
              ],
              [
                -43.54948841326956,
                -23.072892327633763
              ],
              [
                -43.55310985819223,
                -23.07558133207886
              ],
              [
                -43.554898363822595,
                -23.072896826948735
              ],
              [
                -43.564513287971586,
                -23.075337847497387
              ],
              [
                -43.567397244036414,
                -23.074580338846864
              ],
              [
                -43.57182523300843,
                -23.073357868563342
              ],
              [
                -43.56811677745589,
                -23.071116362691892
              ],
              [
                -43.56826926713153,
                -23.066267398808563
              ],
              [
                -43.567549804489836,
                -23.063342917205038
              ],
              [
                -43.56491229470194,
                -23.06040440559003
              ],
              [
                -43.561203862991526,
                -23.05627244398083
              ],
              [
                -43.55939989930497,
                -23.053847459068564
              ],
              [
                -43.55676040941371,
                -23.05029946944192
              ],
              [
                -43.55964287982881,
                -23.04587447773182
              ],
              [
                -43.562737884812854,
                -23.045838477102652
              ],
              [
                -43.56362683496032,
                -23.053295962962352
              ],
              [
                -43.56852379204961,
                -23.05232298369367
              ],
              [
                -43.56753077284408,
                -23.05811195974179
              ],
              [
                -43.56753431972823,
                -23.05910892687421
              ],
              [
                -43.567549804489836,
                -23.063342917205038
              ],
              [
                -43.57242724793626,
                -23.060048438243346
              ],
              [
                -43.57695623237006,
                -23.056776461247672
              ],
              [
                -43.58607914986464,
                -23.054373016176896
              ],
              [
                -43.5946520697415,
                -23.052114553940314
              ],
              [
                -43.60165799493259,
                -23.052254543824514
              ],
              [
                -43.656606546361644,
                -23.05335470118409
              ],
              [
                -43.66808544631856,
                -23.05354020774473
              ],
              [
                -43.694149708432185,
                -23.055417750789662
              ],
              [
                -43.7114370397075,
                -23.055580801919483
              ],
              [
                -43.7071711097599,
                -23.05340829318109
              ],
              [
                -43.70397712440845,
                -23.052534305959433
              ],
              [
                -43.68233634009582,
                -23.046610268401217
              ],
              [
                -43.66665749658952,
                -23.04231828274751
              ],
              [
                -43.63673074185034,
                -23.036079728861615
              ],
              [
                -43.62299088600922,
                -23.033215680448617
              ],
              [
                -43.614077955773794,
                -23.031367197874275
              ],
              [
                -43.60543551180663,
                -23.03448962970838
              ],
              [
                -43.60274755507248,
                -23.039752604845905
              ],
              [
                -43.572646773417524,
                -23.050220981898843
              ],
              [
                -43.580017243996146,
                -23.040466563146495
              ],
              [
                -43.582828709307115,
                -23.039837574669498
              ],
              [
                -43.58626870532914,
                -23.03622760044299
              ],
              [
                -43.59393314506582,
                -23.032384624542647
              ],
              [
                -43.596506602464295,
                -23.02819815884168
              ],
              [
                -43.599568628228106,
                -23.023155672623975
              ],
              [
                -43.6125825201987,
                -23.016379267236804
              ],
              [
                -43.61642650466902,
                -23.014395764118923
              ],
              [
                -43.61899646067577,
                -23.012289299229806
              ],
              [
                -43.62520691902756,
                -23.00719983057414
              ],
              [
                -43.63011088900242,
                -23.006907359986855
              ],
              [
                -43.639146808579945,
                -23.00636736155137
              ],
              [
                -43.64365427190646,
                -23.001584917881434
              ],
              [
                -43.64743124713712,
                -23.002140400163917
              ],
              [
                -43.64798973999641,
                -23.002222413241498
              ],
              [
                -43.653234700715835,
                -23.00299390735627
              ],
              [
                -43.65663120557203,
                -22.997952953006106
              ],
              [
                -43.664123631367616,
                -22.9868335342352
              ],
              [
                -43.677123542955314,
                -22.980584562215853
              ],
              [
                -43.68371051529814,
                -22.98448655956926
              ],
              [
                -43.686936967177594,
                -22.986398081765376
              ],
              [
                -43.68975389663861,
                -22.991869055477284
              ],
              [
                -43.69538138659538,
                -22.98501960486915
              ],
              [
                -43.70047085400538,
                -22.985696119042267
              ],
              [
                -43.7129572538821,
                -22.969824213707778
              ],
              [
                -43.720365188860335,
                -22.97379821789778
              ],
              [
                -43.71979873298411,
                -22.970828724913392
              ],
              [
                -43.718983243300315,
                -22.96655823263224
              ],
              [
                -43.72099873067704,
                -22.964294285038036
              ],
              [
                -43.72847617526437,
                -22.95589582289972
              ],
              [
                -43.739453617359445,
                -22.949415395646376
              ],
              [
                -43.74247508147111,
                -22.94763138936753
              ],
              [
                -43.74949555394235,
                -22.943485921220372
              ],
              [
                -43.75001602034544,
                -22.94317841810156
              ],
              [
                -43.75229000162601,
                -22.941836432682308
              ],
              [
                -43.75982243356661,
                -22.937389490362772
              ],
              [
                -43.76328445404198,
                -22.935216000153062
              ],
              [
                -43.763261430667086,
                -22.930540520953382
              ],
              [
                -43.76779141205443,
                -22.931856551507188
              ],
              [
                -43.77694233583598,
                -22.924507106659618
              ],
              [
                -43.77520386514855,
                -22.920896113716132
              ],
              [
                -43.78392077407789,
                -22.92315960271638
              ],
              [
                -43.78752727546193,
                -22.921259127552556
              ],
              [
                -43.79406569112737,
                -22.917456683446126
              ],
              [
                -43.80501362673094,
                -22.914170713484804
              ],
              [
                -43.808033582329855,
                -22.915484224058662
              ],
              [
                -43.81090655840605,
                -22.91673468955312
              ],
              [
                -43.81550652842976,
                -22.9183047190603
              ],
              [
                -43.81499105045466,
                -22.912623732456172
              ],
              [
                -43.814930559029094,
                -22.911960240538566
              ],
              [
                -43.82197597882686,
                -22.910580257933404
              ],
              [
                -43.8210324892343,
                -22.914412238711098
              ],
              [
                -43.82091297278036,
                -22.914897229024927
              ],
              [
                -43.82012198167753,
                -22.917814712676023
              ],
              [
                -43.81932996858091,
                -22.920732720322636
              ],
              [
                -43.82256444545325,
                -22.922647727534383
              ],
              [
                -43.82809287938286,
                -22.92583620460652
              ],
              [
                -43.832796345543585,
                -22.928365716716982
              ],
              [
                -43.841443752796124,
                -22.92911823596036
              ],
              [
                -43.843763278788366,
                -22.925485733841064
              ],
              [
                -43.84683373952061,
                -22.92499724036328
              ],
              [
                -43.851509212809766,
                -22.924330288010957
              ],
              [
                -43.85017322799849,
                -22.92027077328747
              ],
              [
                -43.85369816614661,
                -22.918041810510243
              ],
              [
                -43.84812226401681,
                -22.916341300038415
              ],
              [
                -43.84691373569432,
                -22.915972312638345
              ],
              [
                -43.83947134760223,
                -22.913702291009297
              ],
              [
                -43.83566286271223,
                -22.912115811671786
              ],
              [
                -43.83246790475183,
                -22.907845790072187
              ],
              [
                -43.83636286846967,
                -22.90308533264371
              ],
              [
                -43.847480275281896,
                -22.90219288043842
              ],
              [
                -43.85563821592974,
                -22.90161839484731
              ],
              [
                -43.86725760761191,
                -22.904219910919362
              ],
              [
                -43.870997058487916,
                -22.904715410407118
              ],
              [
                -43.87327355809302,
                -22.906837891077295
              ],
              [
                -43.876736500291116,
                -22.909305402209778
              ],
              [
                -43.879634467657404,
                -22.911369923814828
              ],
              [
                -43.88430390051478,
                -22.921547347537718
              ],
              [
                -43.88804037947892,
                -22.921699858703374
              ],
              [
                -43.89385182700387,
                -22.92196840580781
              ],
              [
                -43.90975966212261,
                -22.930138369185443
              ],
              [
                -43.91343064291604,
                -22.9302104145787
              ],
              [
                -43.92523705134838,
                -22.930118443371214
              ],
              [
                -43.94076190849984,
                -22.92999894018119
              ],
              [
                -43.95057033757773,
                -22.9266105037364
              ],
              [
                -43.953730291558344,
                -22.928388517471827
              ],
              [
                -43.956088776913994,
                -22.929715508276086
              ],
              [
                -43.964529163716776,
                -22.934464977583332
              ],
              [
                -43.97015412433328,
                -22.93298501873709
              ],
              [
                -43.97186212185774,
                -22.932535511464692
              ],
              [
                -43.976812059804445,
                -22.931233531958306
              ],
              [
                -43.983469506904385,
                -22.933155060306312
              ],
              [
                -43.98839499188567,
                -22.935139537748185
              ],
              [
                -43.99398792310289,
                -22.94033503935235
              ],
              [
                -43.994421886076466,
                -22.940738046546844
              ],
              [
                -43.99590639702401,
                -22.941103544718455
              ],
              [
                -44.00007184159486,
                -22.942129038813697
              ],
              [
                -44.00292334284245,
                -22.93824056230772
              ],
              [
                -44.00573678260838,
                -22.941707535935926
              ],
              [
                -44.01170521541551,
                -22.94920404738056
              ],
              [
                -44.01182924285376,
                -22.949359542955005
              ],
              [
                -44.01208621709485,
                -22.94952803782023
              ],
              [
                -44.017103685232605,
                -22.95281603220089
              ],
              [
                -44.02232312887166,
                -22.9560205355317
              ],
              [
                -44.02215061594667,
                -22.9580895120218
              ],
              [
                -44.0218085901725,
                -22.962193986174242
              ],
              [
                -44.02682655243595,
                -22.96350700016631
              ],
              [
                -44.02893953094104,
                -22.963865021057714
              ],
              [
                -44.0301210160412,
                -22.964065001234832
              ],
              [
                -44.03035801885152,
                -22.965071514979073
              ],
              [
                -44.03182798665352,
                -22.97131596857971
              ],
              [
                -44.03411143227403,
                -22.98112794804402
              ],
              [
                -44.03889989118866,
                -22.982229426469665
              ],
              [
                -44.04661281575513,
                -22.983944959720326
              ],
              [
                -44.052598789766925,
                -22.98134898128201
              ],
              [
                -44.055339751260824,
                -22.979956489348748
              ],
              [
                -44.04764987109123,
                -22.970329500547432
              ],
              [
                -44.04290591075398,
                -22.964411050626644
              ],
              [
                -44.04156191865806,
                -22.962733526188117
              ],
              [
                -44.04468943410449,
                -22.95608908078161
              ],
              [
                -44.039334977852235,
                -22.95023709893469
              ],
              [
                -44.04137547966246,
                -22.946193621008863
              ],
              [
                -44.04289899028558,
                -22.94365813638199
              ],
              [
                -44.046158962749956,
                -22.938242158372088
              ],
              [
                -44.04957344724778,
                -22.940436178806937
              ],
              [
                -44.05413939009992,
                -22.94347818519375
              ],
              [
                -44.06242579302713,
                -22.948637170221552
              ],
              [
                -44.07392070859564,
                -22.951050162145417
              ],
              [
                -44.07836162913215,
                -22.954865168472733
              ],
              [
                -44.08307909935468,
                -22.958972140128342
              ],
              [
                -44.07987509505404,
                -22.960628620548047
              ],
              [
                -44.081041086403964,
                -22.965616136466625
              ],
              [
                -44.0795595736021,
                -22.967442115658656
              ],
              [
                -44.07520610075811,
                -22.972806557028186
              ],
              [
                -44.076377086729416,
                -22.976292045250936
              ],
              [
                -44.079418046136006,
                -22.98533454352945
              ],
              [
                -44.081741510732144,
                -22.991498996856887
              ],
              [
                -44.08613846385757,
                -22.992990020374773
              ],
              [
                -44.087728457426124,
                -22.993506012121514
              ],
              [
                -44.089328422898866,
                -22.994024995880785
              ],
              [
                -44.096132390449114,
                -22.9963670067123
              ],
              [
                -44.096308325586236,
                -23.0041709693874
              ],
              [
                -44.09633035337402,
                -23.00513948575629
              ],
              [
                -44.09954231315103,
                -23.008761969802496
              ],
              [
                -44.10219278361522,
                -23.01148444470902
              ],
              [
                -44.10487523469706,
                -23.01423992954441
              ],
              [
                -44.105330737196624,
                -23.015053960822748
              ],
              [
                -44.10970967767986,
                -23.02288543463951
              ],
              [
                -44.11355612707906,
                -23.02261792622503
              ],
              [
                -44.122362543958744,
                -23.02664340632058
              ],
              [
                -44.12617003352933,
                -23.028351414252594
              ],
              [
                -44.12836198871303,
                -23.02923643884121
              ],
              [
                -44.13038049062333,
                -23.030051428304827
              ],
              [
                -44.13548593193502,
                -23.03239295065556
              ],
              [
                -44.139602375683666,
                -23.034145431209204
              ],
              [
                -44.143910856490336,
                -23.034706461037974
              ],
              [
                -44.14881727785834,
                -23.035345935091144
              ],
              [
                -44.14993478118954,
                -23.036931450424095
              ],
              [
                -44.15220575238166,
                -23.040152425947614
              ],
              [
                -44.15368927120059,
                -23.037688469826225
              ],
              [
                -44.15656624515889,
                -23.03290799363047
              ],
              [
                -44.165645665419305,
                -23.032225990252098
              ],
              [
                -44.16918364080116,
                -23.033788008187926
              ],
              [
                -44.17630606351183,
                -23.037120022186595
              ],
              [
                -44.17537255487139,
                -23.04151247347528
              ],
              [
                -44.17426456847453,
                -23.04183850193056
              ],
              [
                -44.171317056364586,
                -23.042704972844547
              ],
              [
                -44.17293704179647,
                -23.047973960749978
              ],
              [
                -44.178926976299266,
                -23.049719950386713
              ],
              [
                -44.18225748721506,
                -23.044980496195144
              ],
              [
                -44.18300598382813,
                -23.047817985507145
              ],
              [
                -44.184184464534674,
                -23.052305980009542
              ],
              [
                -44.18897239545031,
                -23.05342346446325
              ],
              [
                -44.1937338371495,
                -23.054364985697443
              ],
              [
                -44.194934866509534,
                -23.05060250207201
              ],
              [
                -44.193892391178736,
                -23.04625602024701
              ],
              [
                -44.1933948959919,
                -23.04418299791603
              ],
              [
                -44.192869918402856,
                -23.04199501606701
              ],
              [
                -44.19981736856634,
                -23.03832655940501
              ],
              [
                -44.202649308669706,
                -23.039702573360714
              ],
              [
                -44.21541617840542,
                -23.0459045646953
              ],
              [
                -44.22145961074342,
                -23.048840549757582
              ],
              [
                -44.22757805031839,
                -23.051812539854932
              ],
              [
                -44.23187800744637,
                -23.0539015703316
              ],
              [
                -44.236984961341555,
                -23.05638257309061
              ],
              [
                -44.24357242200554,
                -23.053886070816134
              ],
              [
                -44.245535419318365,
                -23.049312113130792
              ],
              [
                -44.25017387408562,
                -23.049945139539048
              ],
              [
                -44.24360895663625,
                -23.044336639937917
              ],
              [
                -44.24001702252603,
                -23.036130159858907
              ],
              [
                -44.23832752183912,
                -23.032091662799345
              ],
              [
                -44.23584358678559,
                -23.026219191458978
              ],
              [
                -44.23172761205779,
                -23.02348622760251
              ],
              [
                -44.229008141879184,
                -23.02181769935939
              ],
              [
                -44.227523155616936,
                -23.020906703772855
              ],
              [
                -44.221429200299816,
                -23.017136233629902
              ],
              [
                -44.221925214935624,
                -23.01108223318345
              ],
              [
                -44.22892715208425,
                -23.012710241094208
              ],
              [
                -44.23655712824594,
                -23.006201778962478
              ],
              [
                -44.242263607567175,
                -22.99986337185319
              ],
              [
                -44.24819101959255,
                -23.000681868790355
              ],
              [
                -44.24804154492887,
                -22.995872899601164
              ],
              [
                -44.25254348532399,
                -22.9998883692875
              ],
              [
                -44.254912956921025,
                -23.002078872069305
              ],
              [
                -44.2566704502014,
                -23.00350984587689
              ],
              [
                -44.258196914074816,
                -23.004752375975144
              ],
              [
                -44.260814402669574,
                -23.00657535519998
              ],
              [
                -44.26238388279378,
                -23.00538187416665
              ],
              [
                -44.26681839388964,
                -23.00201039432356
              ],
              [
                -44.273759294116125,
                -23.006351913999726
              ],
              [
                -44.27577624803571,
                -23.008776871716815
              ],
              [
                -44.27665426541003,
                -23.010058391893217
              ],
              [
                -44.27884722336827,
                -23.013260353339884
              ],
              [
                -44.278009718139785,
                -23.017247837869228
              ],
              [
                -44.283438177048296,
                -23.014063369790133
              ],
              [
                -44.2885816559561,
                -23.01677638052019
              ],
              [
                -44.289334634350524,
                -23.019992380043348
              ],
              [
                -44.29011011751185,
                -23.023857863499618
              ],
              [
                -44.298221510406194,
                -23.025703363394094
              ],
              [
                -44.30564096654873,
                -23.023006394314805
              ],
              [
                -44.30313899730304,
                -23.017880915285147
              ],
              [
                -44.30164205289509,
                -23.01481239804951
              ],
              [
                -44.29913757168118,
                -23.009680955053277
              ],
              [
                -44.297760086465146,
                -23.00685746696402
              ],
              [
                -44.30397806528523,
                -23.00215999629419
              ],
              [
                -44.30714849191438,
                -23.00659597142512
              ],
              [
                -44.307336491459616,
                -23.006859482659966
              ],
              [
                -44.313183462553866,
                -23.00757299291941
              ],
              [
                -44.31610691272443,
                -23.00805048546845
              ],
              [
                -44.31606790391189,
                -23.013841950464393
              ],
              [
                -44.31970286884867,
                -23.00987449033784
              ],
              [
                -44.32211685872095,
                -23.014726981449087
              ],
              [
                -44.327416791036725,
                -23.01528895298037
              ],
              [
                -44.326436320323474,
                -23.020776471923355
              ],
              [
                -44.33200574175015,
                -23.020239980472528
              ],
              [
                -44.33605170174407,
                -23.025005967405534
              ],
              [
                -44.34071419033695,
                -23.02214196672291
              ],
              [
                -44.344793631299616,
                -23.02555097928414
              ],
              [
                -44.34724861159236,
                -23.027602967011685
              ],
              [
                -44.350347564080195,
                -23.030045477594484
              ],
              [
                -44.350316091558234,
                -23.02233399461168
              ],
              [
                -44.35784754063997,
                -23.022871023278537
              ],
              [
                -44.35790204009922,
                -23.01938805518225
              ],
              [
                -44.36207449360818,
                -23.017589565053807
              ],
              [
                -44.360540521166406,
                -23.01272156435325
              ],
              [
                -44.360564035528675,
                -23.009672096091666
              ],
              [
                -44.35662606699042,
                -23.007325583802082
              ],
              [
                -44.35532960923523,
                -23.002536094374342
              ],
              [
                -44.35416559739774,
                -22.998362638340975
              ],
              [
                -44.347703704887905,
                -22.993087622389627
              ],
              [
                -44.34469472435549,
                -22.9907226457023
              ],
              [
                -44.333059297889214,
                -22.992716582859817
              ],
              [
                -44.32795785550014,
                -22.993540571195748
              ],
              [
                -44.328425374541006,
                -22.987443617832174
              ],
              [
                -44.328732913476586,
                -22.98309912559701
              ],
              [
                -44.32327593349066,
                -22.982222632181667
              ],
              [
                -44.32180395334623,
                -22.983787125690625
              ],
              [
                -44.320308479330386,
                -22.98537560702085
              ],
              [
                -44.315968505865065,
                -22.98315960906187
              ],
              [
                -44.309500556732324,
                -22.97969860240411
              ],
              [
                -44.31186253871067,
                -22.97284263080473
              ],
              [
                -44.3096610821505,
                -22.96920714044045
              ],
              [
                -44.30544912185982,
                -22.9630586908185
              ],
              [
                -44.301461166719555,
                -22.962320189696932
              ],
              [
                -44.29903520218315,
                -22.957696703514173
              ],
              [
                -44.301735194453535,
                -22.956203680953625
              ],
              [
                -44.305964169714606,
                -22.95719622549141
              ],
              [
                -44.31276308436809,
                -22.959259701749335
              ],
              [
                -44.31837506577483,
                -22.956053251259423
              ],
              [
                -44.318961564958926,
                -22.956288742694685
              ],
              [
                -44.32915395589202,
                -22.960380760269867
              ],
              [
                -44.33212789993063,
                -22.96652322461878
              ],
              [
                -44.33581739624801,
                -22.963878744264125
              ],
              [
                -44.3363448778905,
                -22.960613253426082
              ],
              [
                -44.33675640745313,
                -22.956734273237448
              ],
              [
                -44.33279794733613,
                -22.954301285363083
              ],
              [
                -44.33282646835577,
                -22.948455309643165
              ],
              [
                -44.33275143582679,
                -22.945311349612158
              ],
              [
                -44.33274346492355,
                -22.94498231117855
              ],
              [
                -44.32758601536216,
                -22.943132346290152
              ],
              [
                -44.32236054073769,
                -22.94149180421267
              ],
              [
                -44.31955758469179,
                -22.937109839861098
              ],
              [
                -44.32532055690277,
                -22.936110835115183
              ],
              [
                -44.32324210006335,
                -22.929650397742716
              ],
              [
                -44.32556406223307,
                -22.927318392219146
              ],
              [
                -44.33175701774018,
                -22.927276898997626
              ],
              [
                -44.339193974465,
                -22.921792933384125
              ],
              [
                -44.34436643926476,
                -22.921608483121485
              ],
              [
                -44.34733988238935,
                -22.921652458869843
              ],
              [
                -44.35061687098182,
                -22.92647246537939
              ],
              [
                -44.352879830663895,
                -22.929589449397987
              ],
              [
                -44.353089844943796,
                -22.92987845656594
              ],
              [
                -44.35378981400734,
                -22.92887845126633
              ],
              [
                -44.35840232066227,
                -22.92228699442869
              ],
              [
                -44.36357676345617,
                -22.923384507990708
              ],
              [
                -44.36053177851946,
                -22.925078502660853
              ],
              [
                -44.362868757321614,
                -22.92731197014408
              ],
              [
                -44.36601720169032,
                -22.929996990625266
              ],
              [
                -44.359942270869546,
                -22.931404480559337
              ],
              [
                -44.36263072294858,
                -22.932894440574483
              ],
              [
                -44.36551869105714,
                -22.93446245474937
              ],
              [
                -44.370911667215005,
                -22.93739797504509
              ],
              [
                -44.36963363418187,
                -22.94446740953091
              ],
              [
                -44.36638767845678,
                -22.946968378769867
              ],
              [
                -44.363758225010265,
                -22.939994425350168
              ],
              [
                -44.36266019518422,
                -22.94041990935516
              ],
              [
                -44.35679174786841,
                -22.9426953996519
              ],
              [
                -44.34948280108933,
                -22.945591385094755
              ],
              [
                -44.352103282927224,
                -22.94862385649253
              ],
              [
                -44.3558342173036,
                -22.95288684593769
              ],
              [
                -44.35870069524947,
                -22.956161824834496
              ],
              [
                -44.36222768365875,
                -22.954262365449978
              ],
              [
                -44.36679263691601,
                -22.959451357682216
              ],
              [
                -44.372122075044025,
                -22.95809086053576
              ],
              [
                -44.3735485217203,
                -22.966392844360783
              ],
              [
                -44.37723648380273,
                -22.969267309411965
              ],
              [
                -44.380229987344045,
                -22.966248839986697
              ],
              [
                -44.383609945849564,
                -22.971756309533838
              ],
              [
                -44.38668790403511,
                -22.96893885536578
              ],
              [
                -44.383561481211025,
                -22.96538684708078
              ],
              [
                -44.37809703592307,
                -22.95918085131378
              ],
              [
                -44.38248300600331,
                -22.956292905996687
              ],
              [
                -44.388660450278564,
                -22.95222441217366
              ],
              [
                -44.389937470860275,
                -22.951383421651865
              ],
              [
                -44.392100446599024,
                -22.949959976313338
              ],
              [
                -44.39484639048593,
                -22.950393476592073
              ],
              [
                -44.39610537997293,
                -22.95059195601393
              ],
              [
                -44.40168635393378,
                -22.951473480743314
              ],
              [
                -44.4071842865504,
                -22.95205649569033
              ],
              [
                -44.407394783242744,
                -22.951572965584045
              ],
              [
                -44.411845262701945,
                -22.941360038719722
              ],
              [
                -44.41339174006907,
                -22.94559801245566
              ],
              [
                -44.42238717184819,
                -22.950207530402416
              ],
              [
                -44.42231814929817,
                -22.95312950014916
              ],
              [
                -44.42224416258649,
                -22.95803247034779
              ],
              [
                -44.42223012883928,
                -22.958948489900806
              ],
              [
                -44.423071622301414,
                -22.958392958834636
              ],
              [
                -44.42605210368401,
                -22.956426487304178
              ],
              [
                -44.425808124908635,
                -22.959582986456088
              ],
              [
                -44.425635099401426,
                -22.96181899022989
              ],
              [
                -44.42805010568925,
                -22.96208298944638
              ],
              [
                -44.433175537612115,
                -22.962642999520316
              ],
              [
                -44.4344965373539,
                -22.967696464756187
              ],
              [
                -44.43545449018314,
                -22.972445927483932
              ],
              [
                -44.43102601900292,
                -22.977063896368985
              ],
              [
                -44.43022852229296,
                -22.977895405225908
              ],
              [
                -44.43126052726852,
                -22.9782459263142
              ],
              [
                -44.434431982461724,
                -22.979323429657967
              ],
              [
                -44.434627965526985,
                -22.98418237181986
              ],
              [
                -44.43473346617958,
                -22.987189864643362
              ],
              [
                -44.440402416733654,
                -22.991147376866532
              ],
              [
                -44.43812442493353,
                -22.995448836063595
              ],
              [
                -44.435355418409166,
                -22.994643853483204
              ],
              [
                -44.42918299842562,
                -22.99285034650156
              ],
              [
                -44.42516251016616,
                -22.997641820652497
              ],
              [
                -44.4326259189032,
                -23.000414303404934
              ],
              [
                -44.43811888313081,
                -23.002408315603674
              ],
              [
                -44.43909235711529,
                -23.003553298721876
              ],
              [
                -44.44286682751871,
                -23.007994790543272
              ],
              [
                -44.445869766444495,
                -23.011528295655477
              ],
              [
                -44.44373982210706,
                -23.014633781005045
              ],
              [
                -44.439816818064,
                -23.020352746975007
              ],
              [
                -44.43456237365419,
                -23.020309216033265
              ],
              [
                -44.430728895776845,
                -23.020322226647718
              ],
              [
                -44.43894933617012,
                -23.02429622970184
              ],
              [
                -44.44463627681522,
                -23.02689169556062
              ],
              [
                -44.44885522177169,
                -23.02134325556438
              ],
              [
                -44.45259973819638,
                -23.016314764794174
              ],
              [
                -44.45967364503634,
                -23.018218781708903
              ],
              [
                -44.45876417914276,
                -23.01337081453314
              ],
              [
                -44.458293672085354,
                -23.01086580829349
              ],
              [
                -44.46260466015036,
                -23.005666331870135
              ],
              [
                -44.46778261362337,
                -23.005907845654892
              ],
              [
                -44.4687885812858,
                -23.00992933005728
              ],
              [
                -44.46897562132516,
                -23.01067482498228
              ],
              [
                -44.47197707216207,
                -23.00883434790268
              ],
              [
                -44.47588957088018,
                -23.006435378957896
              ],
              [
                -44.48324448302654,
                -23.008113887715343
              ],
              [
                -44.48529445273048,
                -23.012113382045918
              ],
              [
                -44.48712292658309,
                -23.016037375146187
              ],
              [
                -44.48907387754798,
                -23.020223836321644
              ],
              [
                -44.494359821435744,
                -23.023535834688563
              ],
              [
                -44.499195298189065,
                -23.026538858328994
              ],
              [
                -44.50467772373211,
                -23.029738343778842
              ],
              [
                -44.510963683656385,
                -23.026484856333134
              ],
              [
                -44.51935009595389,
                -23.026560390598117
              ],
              [
                -44.52943299956909,
                -23.02759940982918
              ],
              [
                -44.55031783300833,
                -23.03748489187436
              ],
              [
                -44.55499777089293,
                -23.038022919940826
              ],
              [
                -44.55759575951491,
                -23.03832141253073
              ],
              [
                -44.55985072222284,
                -23.038580942380857
              ],
              [
                -44.56276419362432,
                -23.04157291593405
              ],
              [
                -44.563876191418395,
                -23.04271490747433
              ],
              [
                -44.56813063973393,
                -23.047133917830216
              ],
              [
                -44.575830578769455,
                -23.047918941891606
              ],
              [
                -44.577552533678414,
                -23.053373420461416
              ],
              [
                -44.58441996469958,
                -23.057389410834702
              ],
              [
                -44.58829093606307,
                -23.059673394389343
              ],
              [
                -44.59402287670885,
                -23.06153091102188
              ],
              [
                -44.598974811967466,
                -23.063110890873894
              ],
              [
                -44.59445887494345,
                -23.05741792385894
              ],
              [
                -44.59045344430121,
                -23.052422959899467
              ],
              [
                -44.591183422629925,
                -23.049057439964486
              ],
              [
                -44.5920019174215,
                -23.04846945998954
              ],
              [
                -44.59628488816463,
                -23.04539399627778
              ],
              [
                -44.60149636121165,
                -23.047285004333396
              ],
              [
                -44.608232265352406,
                -23.04973049786765
              ],
              [
                -44.6112902472862,
                -23.048045497194543
              ],
              [
                -44.61385272438775,
                -23.05082599284095
              ],
              [
                -44.61364226737897,
                -23.04539003924915
              ],
              [
                -44.617598241571045,
                -23.043070549037445
              ],
              [
                -44.631246590345924,
                -23.048916040042418
              ],
              [
                -44.63676502113329,
                -23.051638540978292
              ],
              [
                -44.63803304521873,
                -23.0522635514267
              ],
              [
                -44.63815850799867,
                -23.05167456157916
              ],
              [
                -44.638969529058684,
                -23.04786109298752
              ],
              [
                -44.64497096874719,
                -23.046452114964076
              ],
              [
                -44.64725292574586,
                -23.05034860353606
              ],
              [
                -44.64815742393711,
                -23.051932103424793
              ],
              [
                -44.65060339330051,
                -23.056213062612127
              ],
              [
                -44.65335638605236,
                -23.054159573871612
              ],
              [
                -44.656173868400884,
                -23.052058094534637
              ],
              [
                -44.66090429862236,
                -23.056478588250222
              ],
              [
                -44.66764627147112,
                -23.053638642069444
              ],
              [
                -44.66998023779827,
                -23.055708127518322
              ],
              [
                -44.67788666663698,
                -23.063603096285156
              ],
              [
                -44.67623415959102,
                -23.070327058737924
              ],
              [
                -44.68303159898068,
                -23.070959102158334
              ],
              [
                -44.68752652549907,
                -23.077185067671877
              ],
              [
                -44.68567504372818,
                -23.080256049011567
              ],
              [
                -44.69032497130378,
                -23.082569543604222
              ],
              [
                -44.68888950294595,
                -23.086585512572395
              ],
              [
                -44.68730498724246,
                -23.090484502694537
              ],
              [
                -44.69266094220303,
                -23.093277515929643
              ],
              [
                -44.69450040833845,
                -23.098321483901017
              ],
              [
                -44.69591836745035,
                -23.1023109567545
              ],
              [
                -44.69765134808921,
                -23.115983388376005
              ],
              [
                -44.69773130848317,
                -23.120363372748987
              ],
              [
                -44.6941648288796,
                -23.12267884703504
              ],
              [
                -44.68779289268094,
                -23.12681682402114
              ],
              [
                -44.69177931051728,
                -23.13115279222827
              ],
              [
                -44.69525130889536,
                -23.131680838157884
              ],
              [
                -44.69868675760752,
                -23.132203340015533
              ],
              [
                -44.69746575722611,
                -23.1434932659991
              ],
              [
                -44.696276744258306,
                -23.14419175644233
              ],
              [
                -44.69284676843169,
                -23.146206229530026
              ],
              [
                -44.695221758196126,
                -23.149898729435506
              ],
              [
                -44.69683423036897,
                -23.152386719995746
              ],
              [
                -44.69695974084716,
                -23.152524711681394
              ],
              [
                -44.698895208348766,
                -23.154662211789017
              ],
              [
                -44.70494715432883,
                -23.155549247049272
              ],
              [
                -44.7062491273124,
                -23.163017680105273
              ],
              [
                -44.706712090763254,
                -23.16664469213434
              ],
              [
                -44.70305062044374,
                -23.167554669837422
              ],
              [
                -44.70207213593714,
                -23.16785165176117
              ],
              [
                -44.69860717347571,
                -23.168902670329473
              ],
              [
                -44.698369627802066,
                -23.17415012175239
              ],
              [
                -44.70405510490406,
                -23.1753596494978
              ],
              [
                -44.70698357994548,
                -23.175892651946278
              ],
              [
                -44.70968454108399,
                -23.176383662974995
              ],
              [
                -44.71177252465893,
                -23.182158598244694
              ],
              [
                -44.71707847748904,
                -23.184794139198637
              ],
              [
                -44.71468545030396,
                -23.18850011208242
              ],
              [
                -44.7146334614956,
                -23.188580583558675
              ],
              [
                -44.71390797975444,
                -23.193907061299903
              ],
              [
                -44.714650443556216,
                -23.193842555631086
              ],
              [
                -44.717814918143,
                -23.19356656657206
              ],
              [
                -44.72116885081987,
                -23.20152053798344
              ],
              [
                -44.713711885521064,
                -23.211419989675047
              ],
              [
                -44.71049194208182,
                -23.211174973049204
              ],
              [
                -44.70897994955689,
                -23.216047959808407
              ],
              [
                -44.70783394764576,
                -23.219739954169665
              ],
              [
                -44.71227489048456,
                -23.221568937620933
              ],
              [
                -44.70911539082245,
                -23.225610922827986
              ],
              [
                -44.71215985468571,
                -23.233387366276542
              ],
              [
                -44.70773291699836,
                -23.233038869836008
              ],
              [
                -44.70113646459335,
                -23.23251586029919
              ],
              [
                -44.696390492138555,
                -23.23201934169293
              ],
              [
                -44.6898325791352,
                -23.22188286986857
              ],
              [
                -44.68398811784762,
                -23.22336334889415
              ],
              [
                -44.677094183859914,
                -23.219135337146568
              ],
              [
                -44.67569021072877,
                -23.21827436840266
              ],
              [
                -44.67308574786947,
                -23.214623356628806
              ],
              [
                -44.67707773703556,
                -23.212235379852924
              ],
              [
                -44.67106530224438,
                -23.2105718683535
              ],
              [
                -44.66867229963357,
                -23.20564240423642
              ],
              [
                -44.66596382488827,
                -23.20912888429511
              ],
              [
                -44.66325688220974,
                -23.206009886727987
              ],
              [
                -44.65861591384959,
                -23.207083875975837
              ],
              [
                -44.65664241665958,
                -23.202139905082905
              ],
              [
                -44.65076450676893,
                -23.18759745518029
              ],
              [
                -44.64642105426437,
                -23.186343425314416
              ],
              [
                -44.64293310458918,
                -23.18533593860789
              ],
              [
                -44.64320958070517,
                -23.189885914727416
              ],
              [
                -44.64326957077621,
                -23.19158390181842
              ],
              [
                -44.64341357352261,
                -23.19566587780633
              ],
              [
                -44.63992158025222,
                -23.19579888866299
              ],
              [
                -44.62618320023304,
                -23.19632182476378
              ],
              [
                -44.62631419905236,
                -23.20167130468517
              ],
              [
                -44.62261121486289,
                -23.202667309864836
              ],
              [
                -44.61671427058551,
                -23.204304265022152
              ],
              [
                -44.62648066437801,
                -23.21272227660818
              ],
              [
                -44.62658113923426,
                -23.21280875847091
              ],
              [
                -44.624095665365274,
                -23.221065237461925
              ],
              [
                -44.62783414010833,
                -23.22387322031082
              ],
              [
                -44.63283008717969,
                -23.22223524871157
              ],
              [
                -44.63390856203711,
                -23.217167275728364
              ],
              [
                -44.6372315477877,
                -23.217965754992555
              ],
              [
                -44.640564540723986,
                -23.218679291688765
              ],
              [
                -44.63637308840149,
                -23.210694318201252
              ],
              [
                -44.64010951524717,
                -23.213479272151595
              ],
              [
                -44.64296103175879,
                -23.215629783707712
              ],
              [
                -44.64523599063574,
                -23.21734578475832
              ],
              [
                -44.65101492614308,
                -23.221602284300477
              ],
              [
                -44.65270743420546,
                -23.217317802261874
              ],
              [
                -44.656159382159004,
                -23.216139796268603
              ],
              [
                -44.65697339390933,
                -23.215895808646863
              ],
              [
                -44.66243736595417,
                -23.214260362312153
              ],
              [
                -44.660993363761435,
                -23.21728631600629
              ],
              [
                -44.66075485022279,
                -23.217786840979322
              ],
              [
                -44.658067835369124,
                -23.222814794778852
              ],
              [
                -44.654914373773984,
                -23.227143791625316
              ],
              [
                -44.65814282986678,
                -23.22934427798723
              ],
              [
                -44.66128182702556,
                -23.231452762544528
              ],
              [
                -44.66650074706565,
                -23.234862740605443
              ],
              [
                -44.66976820818719,
                -23.232568291410217
              ],
              [
                -44.67260821304359,
                -23.23057328362736
              ],
              [
                -44.67817365149495,
                -23.238238293457524
              ],
              [
                -44.67492665338573,
                -23.23922077793203
              ],
              [
                -44.67098670991432,
                -23.24031172900008
              ],
              [
                -44.677193654984045,
                -23.24229475554257
              ],
              [
                -44.68164960497167,
                -23.2399632687678
              ],
              [
                -44.68401708169489,
                -23.242101771092205
              ],
              [
                -44.67929513090696,
                -23.24665122162301
              ],
              [
                -44.692123496811156,
                -23.252698718002748
              ],
              [
                -44.68660301995302,
                -23.25254673363789
              ],
              [
                -44.67871708616635,
                -23.252193204767863
              ],
              [
                -44.67451464937314,
                -23.248779227475257
              ],
              [
                -44.672084662667096,
                -23.24680573519714
              ],
              [
                -44.66901020119459,
                -23.244310237637116
              ],
              [
                -44.66912720063736,
                -23.249171213902017
              ],
              [
                -44.663234738903725,
                -23.247800704310674
              ],
              [
                -44.65824032564462,
                -23.239164726646653
              ],
              [
                -44.6508833678105,
                -23.235212239327556
              ],
              [
                -44.64469343932933,
                -23.23519419252476
              ],
              [
                -44.637549490167586,
                -23.23546617374457
              ],
              [
                -44.63238401336856,
                -23.239354631191294
              ],
              [
                -44.61315720738674,
                -23.234064117883356
              ],
              [
                -44.62450758806438,
                -23.24509962842665
              ],
              [
                -44.62933000431996,
                -23.254197089400474
              ],
              [
                -44.63357894892327,
                -23.262228535889296
              ],
              [
                -44.635285445862806,
                -23.26545306225215
              ],
              [
                -44.637056389848354,
                -23.268801005208616
              ],
              [
                -44.638888396938796,
                -23.27226302779409
              ],
              [
                -44.64108982259214,
                -23.276425509071192
              ],
              [
                -44.64839624647582,
                -23.283915468535376
              ],
              [
                -44.65302720432118,
                -23.28864196179919
              ],
              [
                -44.656897182222984,
                -23.292592959664436
              ],
              [
                -44.65537616660534,
                -23.29532492156243
              ],
              [
                -44.64909921198462,
                -23.29746292626445
              ],
              [
                -44.64541474579992,
                -23.29871739324331
              ],
              [
                -44.63987430208701,
                -23.300461381826473
              ],
              [
                -44.64248826864135,
                -23.295425427836594
              ],
              [
                -44.64545227413724,
                -23.290399456757832
              ],
              [
                -44.64129282391189,
                -23.287330466930378
              ],
              [
                -44.6378003341861,
                -23.28979793568737
              ],
              [
                -44.63114292146449,
                -23.28300896467456
              ],
              [
                -44.62684448276378,
                -23.273277970079867
              ],
              [
                -44.62552898666343,
                -23.27029948661606
              ],
              [
                -44.62025209724457,
                -23.258425524553612
              ],
              [
                -44.60913718005799,
                -23.251619531535553
              ],
              [
                -44.605893710438316,
                -23.249633052121553
              ],
              [
                -44.603212775854374,
                -23.247991536605895
              ],
              [
                -44.59658185117031,
                -23.24392105886078
              ],
              [
                -44.59864882381421,
                -23.237183588509662
              ],
              [
                -44.5962908603086,
                -23.23464157816306
              ],
              [
                -44.58842041428682,
                -23.234345084123365
              ],
              [
                -44.5741450424166,
                -23.233808014782127
              ],
              [
                -44.56489114435234,
                -23.229443536335204
              ],
              [
                -44.55989217309851,
                -23.227085547941215
              ],
              [
                -44.56062014971805,
                -23.231817010413902
              ],
              [
                -44.5667325982509,
                -23.23702448329389
              ],
              [
                -44.57041006549175,
                -23.240145980469674
              ],
              [
                -44.57441851687499,
                -23.243578974071475
              ],
              [
                -44.575760513601885,
                -23.244728501264547
              ],
              [
                -44.575486487110624,
                -23.24781749283514
              ],
              [
                -44.575452515299865,
                -23.24816346309815
              ],
              [
                -44.57493249676198,
                -23.2534984633257
              ],
              [
                -44.577497479657225,
                -23.257231928579078
              ],
              [
                -44.58046293422051,
                -23.26154943415492
              ],
              [
                -44.583750353521026,
                -23.266328381423765
              ],
              [
                -44.579425895736655,
                -23.271077367071165
              ],
              [
                -44.57516544299764,
                -23.26907035997923
              ],
              [
                -44.56564850763206,
                -23.27178582011812
              ],
              [
                -44.559049082703005,
                -23.27366830517303
              ],
              [
                -44.55092014061997,
                -23.271292811289403
              ],
              [
                -44.54887664230713,
                -23.274420293854625
              ],
              [
                -44.54570870551833,
                -23.272604773843135
              ],
              [
                -44.54182021028827,
                -23.27037479883931
              ],
              [
                -44.536519266240056,
                -23.26753127090577
              ],
              [
                -44.532545803522034,
                -23.270381780522452
              ],
              [
                -44.525019338419376,
                -23.275780234339344
              ],
              [
                -44.52240535105435,
                -23.277655715296643
              ],
              [
                -44.51850239689972,
                -23.280454154351673
              ],
              [
                -44.519839855926826,
                -23.28433366755521
              ],
              [
                -44.51489643477092,
                -23.28619612722227
              ],
              [
                -44.51272640102142,
                -23.292935091271175
              ]
            ],
            [
              [
                -43.3597938096445,
                -22.993308775350737
              ],
              [
                -43.351032361227205,
                -22.993442254645426
              ],
              [
                -43.34419494933452,
                -22.99441473987155
              ],
              [
                -43.33123005258656,
                -22.99529767342702
              ],
              [
                -43.3237706087599,
                -22.99575565055736
              ],
              [
                -43.32211510444364,
                -23.00027715992507
              ],
              [
                -43.312949664369924,
                -23.001797611334
              ],
              [
                -43.31069117623553,
                -23.00603307552442
              ],
              [
                -43.306747206104895,
                -23.00727656002653
              ],
              [
                -43.30146425132513,
                -23.008985544714015
              ],
              [
                -43.305762734918474,
                -23.00070660181717
              ],
              [
                -43.31542868735223,
                -22.99697914359746
              ],
              [
                -43.320996608947766,
                -22.99516918015454
              ],
              [
                -43.32325661635142,
                -22.990189194638404
              ],
              [
                -43.331017067452585,
                -22.989757719164487
              ],
              [
                -43.33395403913562,
                -22.989594708688855
              ],
              [
                -43.34239649678683,
                -22.9818162702121
              ],
              [
                -43.34760592521957,
                -22.979789782891856
              ],
              [
                -43.350277404931234,
                -22.9787503170364
              ],
              [
                -43.35547088549032,
                -22.97673034964187
              ],
              [
                -43.363476828700655,
                -22.97361587303334
              ],
              [
                -43.375977694748585,
                -22.975616892045963
              ],
              [
                -43.3797946487235,
                -22.974475404705917
              ],
              [
                -43.382643170381044,
                -22.973602919494205
              ],
              [
                -43.39392450881371,
                -22.98321237486258
              ],
              [
                -43.397014995992684,
                -22.980502425333988
              ],
              [
                -43.40352997832058,
                -22.974789458597748
              ],
              [
                -43.405642445684556,
                -22.979378942568353
              ],
              [
                -43.408395394119076,
                -22.982438432758816
              ],
              [
                -43.412473374194484,
                -22.985407952884287
              ],
              [
                -43.412430364931566,
                -22.989197388129256
              ],
              [
                -43.40723739006469,
                -22.991952378902162
              ],
              [
                -43.39023406030513,
                -22.988071868232275
              ],
              [
                -43.38889952652759,
                -22.99311883288154
              ],
              [
                -43.38551508157562,
                -22.989432335116373
              ],
              [
                -43.38729260000447,
                -22.982578391531877
              ],
              [
                -43.38444663839816,
                -22.98049389730636
              ],
              [
                -43.380805173249826,
                -22.977929883351965
              ],
              [
                -43.376944185989984,
                -22.97831236231304
              ],
              [
                -43.37284521055575,
                -22.978781885220098
              ],
              [
                -43.364881799236805,
                -22.979524328620958
              ],
              [
                -43.35298838503451,
                -22.981057287796787
              ],
              [
                -43.348814397890955,
                -22.98360779925684
              ],
              [
                -43.35328535710059,
                -22.98540126817737
              ],
              [
                -43.3495929275895,
                -22.987343754898678
              ],
              [
                -43.346549919604385,
                -22.991818232854957
              ],
              [
                -43.351032361227205,
                -22.993442254645426
              ],
              [
                -43.35764534147075,
                -22.989741781264836
              ],
              [
                -43.3597938096445,
                -22.993308775350737
              ]
            ]
          ],
          [
            [
              [
                -44.23321742526931,
                -23.089434896755773
              ],
              [
                -44.209291104596495,
                -23.09243433097266
              ],
              [
                -44.20987309346695,
                -23.098505287298348
              ],
              [
                -44.19556770537804,
                -23.09829625184024
              ],
              [
                -44.19417321948228,
                -23.104346248948122
              ],
              [
                -44.1997946491785,
                -23.10863173142773
              ],
              [
                -44.20495259562381,
                -23.10409874112918
              ],
              [
                -44.216680515982425,
                -23.10498376641568
              ],
              [
                -44.21614251833464,
                -23.10794778669987
              ],
              [
                -44.215906024289445,
                -23.10925126078923
              ],
              [
                -44.211810527321596,
                -23.11037622335431
              ],
              [
                -44.20844107130608,
                -23.111101715795996
              ],
              [
                -44.208080552267695,
                -23.110519725627768
              ],
              [
                -44.20642310509085,
                -23.107839727071422
              ],
              [
                -44.204808080408434,
                -23.111564227794904
              ],
              [
                -44.201346616381464,
                -23.119543657068593
              ],
              [
                -44.19695465249114,
                -23.12178365546699
              ],
              [
                -44.19023722240222,
                -23.120338141350494
              ],
              [
                -44.186741730138245,
                -23.119586633564538
              ],
              [
                -44.18513622417716,
                -23.119240626911466
              ],
              [
                -44.18111678082475,
                -23.118375636264595
              ],
              [
                -44.175732349257395,
                -23.117217616359845
              ],
              [
                -44.169374399327126,
                -23.118549605845228
              ],
              [
                -44.17115834690563,
                -23.12456607566429
              ],
              [
                -44.17171333819312,
                -23.126438583139475
              ],
              [
                -44.172348850932494,
                -23.128580544534525
              ],
              [
                -44.17009583903244,
                -23.137633985881703
              ],
              [
                -44.16701435156238,
                -23.139940003486583
              ],
              [
                -44.16456835249345,
                -23.141634483745694
              ],
              [
                -44.155967462728654,
                -23.13862847907363
              ],
              [
                -44.157789928089365,
                -23.135066479130842
              ],
              [
                -44.151718509543805,
                -23.134218476881912
              ],
              [
                -44.14995251705921,
                -23.130669503227647
              ],
              [
                -44.14810504878941,
                -23.126640511670605
              ],
              [
                -44.14724555815779,
                -23.124765002396696
              ],
              [
                -44.12706374101637,
                -23.119557977249904
              ],
              [
                -44.124501760397706,
                -23.1236649555638
              ],
              [
                -44.12820572280103,
                -23.12728747105502
              ],
              [
                -44.133194664076896,
                -23.132166461951485
              ],
              [
                -44.14007709607623,
                -23.138891444058004
              ],
              [
                -44.13831707709706,
                -23.14502238679099
              ],
              [
                -44.1334376025433,
                -23.145655889612282
              ],
              [
                -44.13666955480005,
                -23.150306355909084
              ],
              [
                -44.14062900038522,
                -23.156335360533934
              ],
              [
                -44.13806403224843,
                -23.159653332655022
              ],
              [
                -44.13252459292368,
                -23.156956323730373
              ],
              [
                -44.12783516790076,
                -23.154673807102693
              ],
              [
                -44.12760014730405,
                -23.154559806019307
              ],
              [
                -44.123786674324016,
                -23.15270230370555
              ],
              [
                -44.11941172608325,
                -23.150573308192236
              ],
              [
                -44.11465327777078,
                -23.14825633855709
              ],
              [
                -44.1168442499219,
                -23.154228286677025
              ],
              [
                -44.113075278948145,
                -23.158285770990574
              ],
              [
                -44.10747026673368,
                -23.164319225845322
              ],
              [
                -44.09148192539879,
                -23.166381168895033
              ],
              [
                -44.09300489558839,
                -23.170052175305095
              ],
              [
                -44.09501089559865,
                -23.174667139471588
              ],
              [
                -44.10227930673215,
                -23.176763168940017
              ],
              [
                -44.108619722379586,
                -23.178404163107594
              ],
              [
                -44.11275719837738,
                -23.181893663449245
              ],
              [
                -44.117407667547546,
                -23.185815143099635
              ],
              [
                -44.12117711832471,
                -23.186265642085992
              ],
              [
                -44.13183204233316,
                -23.187305674789656
              ],
              [
                -44.12720105483931,
                -23.181845707583506
              ],
              [
                -44.12354713420381,
                -23.17753919946783
              ],
              [
                -44.127084620100064,
                -23.173198720886738
              ],
              [
                -44.12944756967268,
                -23.170300245201812
              ],
              [
                -44.13280906110154,
                -23.169134270865936
              ],
              [
                -44.13582903179591,
                -23.168192782529808
              ],
              [
                -44.13740553563029,
                -23.167701305140668
              ],
              [
                -44.15229388883113,
                -23.17033628756068
              ],
              [
                -44.155620835041006,
                -23.170925291690047
              ],
              [
                -44.15890783962134,
                -23.171507335746345
              ],
              [
                -44.16197477625757,
                -23.17489932069578
              ],
              [
                -44.163690747317794,
                -23.17680781714217
              ],
              [
                -44.164088781461196,
                -23.177250299087166
              ],
              [
                -44.16616122717175,
                -23.179554789592213
              ],
              [
                -44.16830119305759,
                -23.181934292920705
              ],
              [
                -44.170686699944426,
                -23.184587264598985
              ],
              [
                -44.17395665833398,
                -23.182424817811338
              ],
              [
                -44.17519513715112,
                -23.181605309090234
              ],
              [
                -44.17779064114488,
                -23.179842804561144
              ],
              [
                -44.18584008722881,
                -23.1804563243188
              ],
              [
                -44.18765706324759,
                -23.184000812185918
              ],
              [
                -44.18960303103274,
                -23.188530299672202
              ],
              [
                -44.186382034363014,
                -23.189693314394365
              ],
              [
                -44.182710048099935,
                -23.191019793114386
              ],
              [
                -44.18254356827618,
                -23.194906255992862
              ],
              [
                -44.192483956319215,
                -23.20109027311832
              ],
              [
                -44.19656088306188,
                -23.20356324925058
              ],
              [
                -44.19730743504161,
                -23.197401314854147
              ],
              [
                -44.20351989034208,
                -23.194140320283573
              ],
              [
                -44.20712485498579,
                -23.192248324100042
              ],
              [
                -44.21716726823467,
                -23.19398135689136
              ],
              [
                -44.22019922719972,
                -23.19451535851498
              ],
              [
                -44.22728764257746,
                -23.19610638102191
              ],
              [
                -44.22873015133898,
                -23.197278372293862
              ],
              [
                -44.231869117003605,
                -23.19982835753746
              ],
              [
                -44.235734572510886,
                -23.199799352119868
              ],
              [
                -44.23869054489597,
                -23.199778384207058
              ],
              [
                -44.244434487126064,
                -23.1997373769245
              ],
              [
                -44.24830645104429,
                -23.20240789958001
              ],
              [
                -44.251285434541174,
                -23.20450238158797
              ],
              [
                -44.25855486946799,
                -23.202268434950668
              ],
              [
                -44.25256393222748,
                -23.197297411596146
              ],
              [
                -44.25548642405576,
                -23.190905976149505
              ],
              [
                -44.256315442592644,
                -23.189091479093292
              ],
              [
                -44.258499413158916,
                -23.190187977734414
              ],
              [
                -44.26316484723226,
                -23.19253147910181
              ],
              [
                -44.26713980239792,
                -23.194528489268667
              ],
              [
                -44.271490271703804,
                -23.196714967545578
              ],
              [
                -44.26844733928636,
                -23.190974003846964
              ],
              [
                -44.267042826401564,
                -23.188153487577562
              ],
              [
                -44.26654336090994,
                -23.18715050403827
              ],
              [
                -44.27079180082818,
                -23.18246705398043
              ],
              [
                -44.273368805436476,
                -23.17962553357202
              ],
              [
                -44.27890574932214,
                -23.17805805604945
              ],
              [
                -44.28538772644235,
                -23.177302621186147
              ],
              [
                -44.29027818258615,
                -23.178064107749808
              ],
              [
                -44.3021175454498,
                -23.177553150197827
              ],
              [
                -44.31767638040239,
                -23.185783650824842
              ],
              [
                -44.31601341877629,
                -23.188644636620886
              ],
              [
                -44.315538907306156,
                -23.18946209142136
              ],
              [
                -44.327975280541,
                -23.205835081675914
              ],
              [
                -44.3314912289905,
                -23.210581563480016
              ],
              [
                -44.33235018991272,
                -23.215825013716543
              ],
              [
                -44.333481168391124,
                -23.222984477530012
              ],
              [
                -44.348763006839576,
                -23.226125033929602
              ],
              [
                -44.34023862536999,
                -23.21806404337656
              ],
              [
                -44.345830062356406,
                -23.21696257079589
              ],
              [
                -44.35102055891903,
                -23.21457059330132
              ],
              [
                -44.35050853984214,
                -23.21000461775347
              ],
              [
                -44.34959107942734,
                -23.20507610084558
              ],
              [
                -44.34699509359674,
                -23.20108463991284
              ],
              [
                -44.3446341425789,
                -23.197454645891572
              ],
              [
                -44.34233769141749,
                -23.1940081551707
              ],
              [
                -44.34004020326743,
                -23.190560152458044
              ],
              [
                -44.340943199578966,
                -23.18451470484714
              ],
              [
                -44.341577190024125,
                -23.18137372960334
              ],
              [
                -44.34550566168203,
                -23.180195731873383
              ],
              [
                -44.35149712354823,
                -23.185303722630973
              ],
              [
                -44.35321209503278,
                -23.18676971625539
              ],
              [
                -44.357697027999365,
                -23.190602217053943
              ],
              [
                -44.36040404185403,
                -23.182922264138803
              ],
              [
                -44.362042041801374,
                -23.1781382654719
              ],
              [
                -44.366644013585,
                -23.179817268890815
              ],
              [
                -44.370372975725445,
                -23.181247800138653
              ],
              [
                -44.3732259501936,
                -23.182131289634633
              ],
              [
                -44.377172409040334,
                -23.17706182957781
              ],
              [
                -44.37725294902594,
                -23.17179334801027
              ],
              [
                -44.37056699431574,
                -23.16600135364489
              ],
              [
                -44.36795204716853,
                -23.16404686792904
              ],
              [
                -44.36162107579483,
                -23.164760326387125
              ],
              [
                -44.35771810890798,
                -23.161828343873868
              ],
              [
                -44.354264681872685,
                -23.158975350202383
              ],
              [
                -44.34893072639925,
                -23.1604683286278
              ],
              [
                -44.346355241376436,
                -23.158366858733974
              ],
              [
                -44.33984481606347,
                -23.15323186074646
              ],
              [
                -44.33512684868474,
                -23.153640845036637
              ],
              [
                -44.32680740355615,
                -23.15436281809627
              ],
              [
                -44.32258298555407,
                -23.147602307102876
              ],
              [
                -44.32037048636885,
                -23.14392136378294
              ],
              [
                -44.321078011711016,
                -23.143136328459132
              ],
              [
                -44.324025478463945,
                -23.13986888509135
              ],
              [
                -44.32044549679175,
                -23.13724638400712
              ],
              [
                -44.31690454159685,
                -23.134998887232534
              ],
              [
                -44.31274808428465,
                -23.137332350816383
              ],
              [
                -44.31216659029812,
                -23.137658852680104
              ],
              [
                -44.31235409356686,
                -23.137058355067772
              ],
              [
                -44.313729097551544,
                -23.13264988693473
              ],
              [
                -44.31014165733552,
                -23.12482990579093
              ],
              [
                -44.30112223604419,
                -23.118335921647695
              ],
              [
                -44.29857627090777,
                -23.11650240958946
              ],
              [
                -44.295220795628694,
                -23.114086427149076
              ],
              [
                -44.296301753100316,
                -23.125873346450163
              ],
              [
                -44.29683722302269,
                -23.13171836282868
              ],
              [
                -44.29102728679821,
                -23.13160182271586
              ],
              [
                -44.28995779567544,
                -23.136802320860557
              ],
              [
                -44.28435482557654,
                -23.136078793219667
              ],
              [
                -44.28277938447921,
                -23.13251731107352
              ],
              [
                -44.28140436921507,
                -23.129407828300014
              ],
              [
                -44.27894893755894,
                -23.12495483107042
              ],
              [
                -44.272845455410895,
                -23.122452340641292
              ],
              [
                -44.2751669613323,
                -23.117034856931795
              ],
              [
                -44.25953511459528,
                -23.115891827307863
              ],
              [
                -44.25744663528932,
                -23.111900341873763
              ],
              [
                -44.252906174674806,
                -23.110319850246125
              ],
              [
                -44.24951723840902,
                -23.109265824189887
              ],
              [
                -44.25095372013471,
                -23.100940395105287
              ],
              [
                -44.251148715993615,
                -23.09981137906176
              ],
              [
                -44.259114659397476,
                -23.099321393862066
              ],
              [
                -44.25058473469371,
                -23.096693892543
              ],
              [
                -44.242441321225215,
                -23.094198403638455
              ],
              [
                -44.237296370945096,
                -23.092589409541297
              ],
              [
                -44.23713790128228,
                -23.088465385967936
              ],
              [
                -44.23697540086747,
                -23.08522740712025
              ],
              [
                -44.2329639154165,
                -23.08563289538016
              ],
              [
                -44.23321742526931,
                -23.089434896755773
              ]
            ]
          ],
          [
            [
              [
                -43.827899024627165,
                -23.057297560391355
              ],
              [
                -43.82013260500945,
                -23.058080051899267
              ],
              [
                -43.81635161284146,
                -23.058461517973807
              ],
              [
                -43.80459470984034,
                -23.059646019903116
              ],
              [
                -43.793564308735654,
                -23.060758469121936
              ],
              [
                -43.77799494972082,
                -23.060451441175225
              ],
              [
                -43.81045067849005,
                -23.06549797302765
              ],
              [
                -43.818273566030406,
                -23.066505999964022
              ],
              [
                -43.85879524096658,
                -23.071733069569444
              ],
              [
                -43.95540284405969,
                -23.087511215008274
              ],
              [
                -43.97010267675882,
                -23.094285720511998
              ],
              [
                -43.97885508874062,
                -23.09835974720794
              ],
              [
                -43.98259906066335,
                -23.10010221979458
              ],
              [
                -43.98638203451246,
                -23.101759716899593
              ],
              [
                -43.99577895462491,
                -23.10048425723198
              ],
              [
                -43.997755426610134,
                -23.09626380394716
              ],
              [
                -44.003351884359574,
                -23.094184805991528
              ],
              [
                -44.008041836433065,
                -23.092609823169724
              ],
              [
                -44.008194868656574,
                -23.088595849003237
              ],
              [
                -44.007927874853884,
                -23.081601898138462
              ],
              [
                -44.012121863172354,
                -23.077812906702896
              ],
              [
                -44.00629893064967,
                -23.070849413836303
              ],
              [
                -43.999649993508655,
                -23.072146411770337
              ],
              [
                -43.99330105155931,
                -23.067425423656996
              ],
              [
                -43.99073410206117,
                -23.05681046022551
              ],
              [
                -43.987706113593845,
                -23.0577214407182
              ],
              [
                -43.984287153968914,
                -23.058813425288815
              ],
              [
                -43.98156920291365,
                -23.055523446971915
              ],
              [
                -43.978613230214954,
                -23.051500944574425
              ],
              [
                -43.9738997992765,
                -23.04508849107889
              ],
              [
                -43.96596437347586,
                -23.042822958879192
              ],
              [
                -43.962712387701245,
                -23.04189547869705
              ],
              [
                -43.95883494993218,
                -23.040789962710686
              ],
              [
                -43.95479799069623,
                -23.03963894352133
              ],
              [
                -43.948678012314,
                -23.048532389033525
              ],
              [
                -43.93440058734262,
                -23.055404310578034
              ],
              [
                -43.93145712658614,
                -23.05682130084498
              ],
              [
                -43.92515167945552,
                -23.057175789294224
              ],
              [
                -43.918846714326016,
                -23.057530282746452
              ],
              [
                -43.91849422184812,
                -23.057707269946693
              ],
              [
                -43.89957337818352,
                -23.067229680229147
              ],
              [
                -43.89878737480545,
                -23.067625676209207
              ],
              [
                -43.89477543328277,
                -23.067471681250503
              ],
              [
                -43.88870698694964,
                -23.067238652189427
              ],
              [
                -43.8846765138164,
                -23.067013157531072
              ],
              [
                -43.88006603381216,
                -23.06291465213744
              ],
              [
                -43.87782208084509,
                -23.060842655217495
              ],
              [
                -43.87770207443095,
                -23.057458676336967
              ],
              [
                -43.87760159439939,
                -23.051326715862764
              ],
              [
                -43.877535136625575,
                -23.04726373142987
              ],
              [
                -43.88056661831898,
                -23.041434277840967
              ],
              [
                -43.88821406416206,
                -23.037721308266445
              ],
              [
                -43.902523457974794,
                -23.03077538915589
              ],
              [
                -43.90711241156815,
                -23.026079420205566
              ],
              [
                -43.89656050357392,
                -23.030261375764365
              ],
              [
                -43.89330252697012,
                -23.031552854806282
              ],
              [
                -43.86423327249616,
                -23.044111218306863
              ],
              [
                -43.85764631454221,
                -23.04650117413797
              ],
              [
                -43.837820465207706,
                -23.053696591333004
              ],
              [
                -43.83400149123526,
                -23.055083078417027
              ],
              [
                -43.827899024627165,
                -23.057297560391355
              ]
            ]
          ],
          [
            [
              [
                -43.18249192388947,
                -22.78103338952147
              ],
              [
                -43.178325956548484,
                -22.782021389781953
              ],
              [
                -43.17471848460168,
                -22.776950411645863
              ],
              [
                -43.17205152625474,
                -22.775893398980234
              ],
              [
                -43.17086604195674,
                -22.782423849399418
              ],
              [
                -43.16836557382711,
                -22.779817877874088
              ],
              [
                -43.16551108045685,
                -22.77666688014524
              ],
              [
                -43.15708315684012,
                -22.77824583840063
              ],
              [
                -43.156891161445024,
                -22.78224732098141
              ],
              [
                -43.15669764591413,
                -22.78629730231904
              ],
              [
                -43.16686651802851,
                -22.791727818711674
              ],
              [
                -43.17141647529791,
                -22.795266810929448
              ],
              [
                -43.17502997883541,
                -22.798134297059054
              ],
              [
                -43.181246865219954,
                -22.80306729069152
              ],
              [
                -43.17745139128392,
                -22.806150243492116
              ],
              [
                -43.17687289258011,
                -22.809386736895796
              ],
              [
                -43.17550491046438,
                -22.817035709312172
              ],
              [
                -43.175032389614074,
                -22.819680692935655
              ],
              [
                -43.170478933088766,
                -22.820867649188386
              ],
              [
                -43.16424298304433,
                -22.822493663881495
              ],
              [
                -43.16777041813174,
                -22.826413148577206
              ],
              [
                -43.16975939296887,
                -22.828611624827104
              ],
              [
                -43.17210490021556,
                -22.830299130546056
              ],
              [
                -43.175678867991884,
                -22.82395166239097
              ],
              [
                -43.17574088777778,
                -22.82384214909665
              ],
              [
                -43.17715285997204,
                -22.82528868058272
              ],
              [
                -43.18475678146757,
                -22.833076655684486
              ],
              [
                -43.186802252436074,
                -22.82879815826399
              ],
              [
                -43.18917374421286,
                -22.823635695084988
              ],
              [
                -43.190485742326466,
                -22.8207782086974
              ],
              [
                -43.20375417177591,
                -22.819403237068286
              ],
              [
                -43.209408593920884,
                -22.81482277054192
              ],
              [
                -43.2154605582357,
                -22.816625790342936
              ],
              [
                -43.21875101018963,
                -22.817652313813205
              ],
              [
                -43.221548981941716,
                -22.818524783767227
              ],
              [
                -43.22636794001273,
                -22.820027807855773
              ],
              [
                -43.23016640207665,
                -22.82317381313295
              ],
              [
                -43.2326163663815,
                -22.82520331044266
              ],
              [
                -43.24299275871734,
                -22.83226978042143
              ],
              [
                -43.24847870621341,
                -22.82906530562108
              ],
              [
                -43.24666526138679,
                -22.825033339898518
              ],
              [
                -43.253054225440465,
                -22.821666363257183
              ],
              [
                -43.25823518815746,
                -22.818945391261508
              ],
              [
                -43.26061565134996,
                -22.81561090801035
              ],
              [
                -43.26355612806282,
                -22.810151924761076
              ],
              [
                -43.26299967132648,
                -22.809368959196497
              ],
              [
                -43.26145218452927,
                -22.80719045197587
              ],
              [
                -43.25910421218242,
                -22.803884487267933
              ],
              [
                -43.254747722172745,
                -22.805961439610357
              ],
              [
                -43.251123754785354,
                -22.807689436592796
              ],
              [
                -43.24378081430082,
                -22.807037391704576
              ],
              [
                -43.24330084640796,
                -22.800142441691456
              ],
              [
                -43.241395351429205,
                -22.798401461376745
              ],
              [
                -43.23546143800211,
                -22.792979961861622
              ],
              [
                -43.22948301908875,
                -22.787516452452753
              ],
              [
                -43.22890154981285,
                -22.78268648094435
              ],
              [
                -43.21775162814286,
                -22.785227950313708
              ],
              [
                -43.21424613431519,
                -22.788417437342257
              ],
              [
                -43.20798518013796,
                -22.794112368749946
              ],
              [
                -43.19139231155328,
                -22.795934342540267
              ],
              [
                -43.188372333819316,
                -22.79404885706374
              ],
              [
                -43.189098836147686,
                -22.788189364093217
              ],
              [
                -43.189292856180145,
                -22.78662186639389
              ],
              [
                -43.18611692160808,
                -22.783811878117568
              ],
              [
                -43.18249192388947,
                -22.78103338952147
              ]
            ]
          ],
          [
            [
              [
                -43.913350602503336,
                -22.938969848681634
              ],
              [
                -43.91261610320233,
                -22.936934841646895
              ],
              [
                -43.911716148760995,
                -22.93444136340802
              ],
              [
                -43.90738817270622,
                -22.93242636373889
              ],
              [
                -43.90401972488652,
                -22.93086537940677
              ],
              [
                -43.90128575732277,
                -22.92969736584478
              ],
              [
                -43.89710978508311,
                -22.93151734088996
              ],
              [
                -43.89336231288032,
                -22.933088843283436
              ],
              [
                -43.89201983878798,
                -22.928171847695754
              ],
              [
                -43.88622587416574,
                -22.930473840098255
              ],
              [
                -43.88425588185237,
                -22.927072822458687
              ],
              [
                -43.88187093159521,
                -22.923852361841316
              ],
              [
                -43.87985796738784,
                -22.92409583231508
              ],
              [
                -43.87691748891793,
                -22.92445184778029
              ],
              [
                -43.86770705776333,
                -22.925566318919394
              ],
              [
                -43.877675433059,
                -22.940379272108906
              ],
              [
                -43.87493495985463,
                -22.94137975595818
              ],
              [
                -43.87629341112228,
                -22.94586871167641
              ],
              [
                -43.87290892629196,
                -22.946947220435238
              ],
              [
                -43.87964537058505,
                -22.95017073260279
              ],
              [
                -43.88401786231585,
                -22.947635257569615
              ],
              [
                -43.88784829305038,
                -22.950825228192645
              ],
              [
                -43.8910462849684,
                -22.95347523877198
              ],
              [
                -43.89625419021038,
                -22.957789699585522
              ],
              [
                -43.905522617407726,
                -22.957796734176195
              ],
              [
                -43.90945411655695,
                -22.955404745272222
              ],
              [
                -43.90756462015573,
                -22.951488272328046
              ],
              [
                -43.9055031693493,
                -22.94721677766087
              ],
              [
                -43.90853914667165,
                -22.944704813962357
              ],
              [
                -43.91210264198083,
                -22.94200483058061
              ],
              [
                -43.913350602503336,
                -22.938969848681634
              ]
            ]
          ],
          [
            [
              [
                -43.23788022472129,
                -22.86156110682286
              ],
              [
                -43.23519624696143,
                -22.857073118069284
              ],
              [
                -43.23777623720048,
                -22.850513162328745
              ],
              [
                -43.24045227446967,
                -22.8436637102778
              ],
              [
                -43.2411672649449,
                -22.84077771845677
              ],
              [
                -43.24256576370117,
                -22.837136745163896
              ],
              [
                -43.234809319183995,
                -22.839019726598647
              ],
              [
                -43.22902137080348,
                -22.836474213139105
              ],
              [
                -43.22598341754697,
                -22.838112715089697
              ],
              [
                -43.22327742691553,
                -22.839572209793673
              ],
              [
                -43.22328691966448,
                -22.843911160289124
              ],
              [
                -43.225974396235955,
                -22.841207191711707
              ],
              [
                -43.22728236528886,
                -22.842012684125244
              ],
              [
                -43.23220680278343,
                -22.845043691929213
              ],
              [
                -43.23092583981628,
                -22.85038966607195
              ],
              [
                -43.23028183257584,
                -22.8510166472825
              ],
              [
                -43.226207859357636,
                -22.85498361998109
              ],
              [
                -43.2232273677548,
                -22.85788660980877
              ],
              [
                -43.21913740934308,
                -22.859001602609663
              ],
              [
                -43.21770088009398,
                -22.862585087089794
              ],
              [
                -43.214118908547384,
                -22.8662245516956
              ],
              [
                -43.215621391939116,
                -22.869973032028142
              ],
              [
                -43.21621591597168,
                -22.869886542008466
              ],
              [
                -43.22059036341649,
                -22.869252553567783
              ],
              [
                -43.22190834262157,
                -22.86537007025734
              ],
              [
                -43.230871260578084,
                -22.865767585676963
              ],
              [
                -43.2329642592839,
                -22.86178160190018
              ],
              [
                -43.23788022472129,
                -22.86156110682286
              ]
            ]
          ],
          [
            [
              [
                -43.92934879596315,
                -23.006708071594343
              ],
              [
                -43.934008718593226,
                -23.01467050831664
              ],
              [
                -43.93690723811167,
                -23.004450591700497
              ],
              [
                -43.936713754479875,
                -22.99779611152833
              ],
              [
                -43.93130479646931,
                -22.995267605416743
              ],
              [
                -43.927727352509635,
                -22.988871648993484
              ],
              [
                -43.92004842343584,
                -22.987259139399384
              ],
              [
                -43.91689593315463,
                -22.99330007162631
              ],
              [
                -43.92547085474245,
                -22.99995659308559
              ],
              [
                -43.92934879596315,
                -23.006708071594343
              ]
            ]
          ],
          [
            [
              [
                -44.588209012915314,
                -23.206180222491508
              ],
              [
                -44.583765050353236,
                -23.203659706389974
              ],
              [
                -44.58591601959439,
                -23.2090486666026
              ],
              [
                -44.588080481625184,
                -23.211013680146497
              ],
              [
                -44.59910340895741,
                -23.216786677705574
              ],
              [
                -44.602603327303875,
                -23.222558652797296
              ],
              [
                -44.613088739961206,
                -23.22372967280255
              ],
              [
                -44.61856320397428,
                -23.222691186657233
              ],
              [
                -44.619277194237874,
                -23.222555706246943
              ],
              [
                -44.6188417086879,
                -23.222340217084046
              ],
              [
                -44.604449863707366,
                -23.215233706652093
              ],
              [
                -44.59369548826674,
                -23.202172729491803
              ],
              [
                -44.588209012915314,
                -23.206180222491508
              ]
            ]
          ],
          [
            [
              [
                -44.04585428461528,
                -22.999599364775122
              ],
              [
                -44.039322871019806,
                -22.99748636158849
              ],
              [
                -44.03445791524612,
                -22.99591337323506
              ],
              [
                -44.03083889977875,
                -23.003928302703986
              ],
              [
                -44.029526410975876,
                -23.006889792838944
              ],
              [
                -44.03772832363358,
                -23.0107317851075
              ],
              [
                -44.04244829968776,
                -23.00587832023953
              ],
              [
                -44.04705173171875,
                -23.01066531732708
              ],
              [
                -44.04917176478995,
                -23.0082538246772
              ],
              [
                -44.0521372020873,
                -23.004693348854175
              ],
              [
                -44.04915627756254,
                -23.00066738541175
              ],
              [
                -44.04585428461528,
                -22.999599364775122
              ]
            ]
          ],
          [
            [
              [
                -44.687442250750856,
                -23.164206156243367
              ],
              [
                -44.69180420753258,
                -23.169413616372616
              ],
              [
                -44.69498269921475,
                -23.160277671446096
              ],
              [
                -44.6941412262431,
                -23.155953185710977
              ],
              [
                -44.689510772392566,
                -23.154003206377666
              ],
              [
                -44.683614831793506,
                -23.15163418659724
              ],
              [
                -44.68172384533137,
                -23.157219178893353
              ],
              [
                -44.687442250750856,
                -23.164206156243367
              ]
            ]
          ],
          [
            [
              [
                -41.69831103520302,
                -22.410061888098234
              ],
              [
                -41.69487854202283,
                -22.416666327325647
              ],
              [
                -41.70216546589669,
                -22.416268362443446
              ],
              [
                -41.70535346609322,
                -22.41042439066124
              ],
              [
                -41.7054104837525,
                -22.40722393640884
              ],
              [
                -41.69710256261047,
                -22.40287543840251
              ],
              [
                -41.69361058734333,
                -22.407254910025046
              ],
              [
                -41.69831103520302,
                -22.410061888098234
              ]
            ]
          ],
          [
            [
              [
                -44.51272640102142,
                -23.292935091271175
              ],
              [
                -44.50518000428289,
                -23.291395582010175
              ],
              [
                -44.50244399914029,
                -23.29635004999618
              ],
              [
                -44.50933043352088,
                -23.301350564012413
              ],
              [
                -44.51272640102142,
                -23.292935091271175
              ]
            ]
          ],
          [
            [
              [
                -43.11053313402251,
                -22.761511816012256
              ],
              [
                -43.108550139636755,
                -22.75847333202398
              ],
              [
                -43.104749149538655,
                -22.762310319038633
              ],
              [
                -43.10282116936083,
                -22.7646837812059
              ],
              [
                -43.108429139184445,
                -22.769261775918817
              ],
              [
                -43.112834092177884,
                -22.767346318888936
              ],
              [
                -43.11053313402251,
                -22.761511816012256
              ]
            ]
          ],
          [
            [
              [
                -43.108550139636755,
                -22.75847333202398
              ],
              [
                -43.111782127816575,
                -22.75328485627486
              ],
              [
                -43.10829016029535,
                -22.74772737294769
              ],
              [
                -43.10439720056682,
                -22.748391394550698
              ],
              [
                -43.102875201111644,
                -22.752659863314296
              ],
              [
                -43.105023701680935,
                -22.755154846435847
              ],
              [
                -43.108550139636755,
                -22.75847333202398
              ]
            ]
          ],
          [
            [
              [
                -43.177351169920584,
                -22.89557182621463
              ],
              [
                -43.17460868954108,
                -22.892784299880176
              ],
              [
                -43.16695826505638,
                -22.895035295751075
              ],
              [
                -43.171694710168666,
                -22.89772677776435
              ],
              [
                -43.177351169920584,
                -22.89557182621463
              ]
            ]
          ],
          [
            [
              [
                -44.644679375697486,
                -23.073814467556915
              ],
              [
                -44.642327937788856,
                -23.067873501535786
              ],
              [
                -44.64004293067492,
                -23.069714493557782
              ],
              [
                -44.635568513838166,
                -23.06795749182095
              ],
              [
                -44.64160741701654,
                -23.07450395994094
              ],
              [
                -44.644679375697486,
                -23.073814467556915
              ]
            ]
          ],
          [
            [
              [
                -43.8611780496276,
                -22.952425150591043
              ],
              [
                -43.86018253707076,
                -22.94959365075614
              ],
              [
                -43.855924071520626,
                -22.949815652373083
              ],
              [
                -43.85796353323734,
                -22.954500633939247
              ],
              [
                -43.86335700880695,
                -22.9552746344816
              ],
              [
                -43.8611780496276,
                -22.952425150591043
              ]
            ]
          ],
          [
            [
              [
                -41.68675067457078,
                -22.401742902654416
              ],
              [
                -41.69036562552253,
                -22.40232592257613
              ],
              [
                -41.69121861734228,
                -22.398672405654892
              ],
              [
                -41.68568269178215,
                -22.396626420577057
              ],
              [
                -41.68675067457078,
                -22.401742902654416
              ]
            ]
          ],
          [
            [
              [
                -44.583765050353236,
                -23.203659706389974
              ],
              [
                -44.58371055483919,
                -23.200515197206276
              ],
              [
                -44.580462616257705,
                -23.198522211854865
              ],
              [
                -44.57913257649091,
                -23.203001208917826
              ],
              [
                -44.583765050353236,
                -23.203659706389974
              ]
            ]
          ],
          [
            [
              [
                -44.576303654149484,
                -23.185971791296186
              ],
              [
                -44.57306618496346,
                -23.18350977121973
              ],
              [
                -44.57284016867246,
                -23.188930232790057
              ],
              [
                -44.57637564622134,
                -23.189839261075413
              ],
              [
                -44.576303654149484,
                -23.185971791296186
              ]
            ]
          ],
          [
            [
              [
                -43.90997353438358,
                -22.983026111188266
              ],
              [
                -43.91294551046744,
                -22.98303015607463
              ],
              [
                -43.91425498808227,
                -22.979798642282212
              ],
              [
                -43.908054057248336,
                -22.97943762454576
              ],
              [
                -43.90997353438358,
                -22.983026111188266
              ]
            ]
          ],
          [
            [
              [
                -44.64635045589845,
                -23.22677825800165
              ],
              [
                -44.63969849689318,
                -23.228174244231052
              ],
              [
                -44.64900538966728,
                -23.230630214954783
              ],
              [
                -44.64635045589845,
                -23.22677825800165
              ]
            ]
          ],
          [
            [
              [
                -41.88659191254603,
                -22.77693492108251
              ],
              [
                -41.883795903283385,
                -22.780103416818182
              ],
              [
                -41.88243843473153,
                -22.782728409966797
              ],
              [
                -41.881836449702895,
                -22.78389286749212
              ],
              [
                -41.88369391228088,
                -22.784159371821765
              ],
              [
                -41.88499489107898,
                -22.78434589415476
              ],
              [
                -41.88659191254603,
                -22.77693492108251
              ]
            ]
          ],
          [
            [
              [
                -44.12922697303609,
                -23.035365893523462
              ],
              [
                -44.12372600354593,
                -23.039620879222902
              ],
              [
                -44.1287854408045,
                -23.04082487095359
              ],
              [
                -44.12922697303609,
                -23.035365893523462
              ]
            ]
          ],
          [
            [
              [
                -43.920054049929945,
                -22.941608362630603
              ],
              [
                -43.913350602503336,
                -22.938969848681634
              ],
              [
                -43.916081103895294,
                -22.942140860484155
              ],
              [
                -43.920395523977255,
                -22.94450732641691
              ],
              [
                -43.920054049929945,
                -22.941608362630603
              ]
            ]
          ],
          [
            [
              [
                -43.514412732473964,
                -23.065267759048083
              ],
              [
                -43.51140377790367,
                -23.060727793316307
              ],
              [
                -43.50837231173537,
                -23.064532782658272
              ],
              [
                -43.514412732473964,
                -23.065267759048083
              ]
            ]
          ],
          [
            [
              [
                -43.14818396099427,
                -23.059917416708462
              ],
              [
                -43.142212492841175,
                -23.06539887896465
              ],
              [
                -43.14673794299095,
                -23.06505341255662
              ],
              [
                -43.14818396099427,
                -23.059917416708462
              ]
            ]
          ],
          [
            [
              [
                -44.63969849689318,
                -23.228174244231052
              ],
              [
                -44.63651253026475,
                -23.223835233150886
              ],
              [
                -44.633614540500716,
                -23.225337728112823
              ],
              [
                -44.63969849689318,
                -23.228174244231052
              ]
            ]
          ],
          [
            [
              [
                -43.94372061913121,
                -23.02329251032416
              ],
              [
                -43.94696206912129,
                -23.023859011216494
              ],
              [
                -43.9436811099307,
                -23.019611521095673
              ],
              [
                -43.94372061913121,
                -23.02329251032416
              ]
            ]
          ],
          [
            [
              [
                -44.692810562079444,
                -23.21542090365451
              ],
              [
                -44.68940311876694,
                -23.21439342423279
              ],
              [
                -44.69084957082961,
                -23.21797938816395
              ],
              [
                -44.692810562079444,
                -23.21542090365451
              ]
            ]
          ],
          [
            [
              [
                -43.953848065802326,
                -23.004998625175364
              ],
              [
                -43.95021909826046,
                -23.005692600133248
              ],
              [
                -43.951708073435206,
                -23.008217613835026
              ],
              [
                -43.953848065802326,
                -23.004998625175364
              ]
            ]
          ],
          [
            [
              [
                -43.207898011115866,
                -23.040543172183035
              ],
              [
                -43.20631600099866,
                -23.037952688807103
              ],
              [
                -43.20216305325473,
                -23.03740819358408
              ],
              [
                -43.207898011115866,
                -23.040543172183035
              ]
            ]
          ],
          [
            [
              [
                -44.672432109063216,
                -23.09944942960709
              ],
              [
                -44.671865583223735,
                -23.096210441502727
              ],
              [
                -44.66911663612486,
                -23.097726433817165
              ],
              [
                -44.672432109063216,
                -23.09944942960709
              ]
            ]
          ],
          [
            [
              [
                -43.95367852893098,
                -23.02503654641094
              ],
              [
                -43.95273453029594,
                -23.022100541148234
              ],
              [
                -43.95073602506058,
                -23.024879032327995
              ],
              [
                -43.95367852893098,
                -23.02503654641094
              ]
            ]
          ],
          [
            [
              [
                -43.861225538439015,
                -22.946858161863894
              ],
              [
                -43.86018253707076,
                -22.94959365075614
              ],
              [
                -43.86323502331882,
                -22.949313670222082
              ],
              [
                -43.861225538439015,
                -22.946858161863894
              ]
            ]
          ],
          [
            [
              [
                -44.6750805032068,
                -23.111112880238554
              ],
              [
                -44.67235255733657,
                -23.11186888422858
              ],
              [
                -44.67469702437896,
                -23.11399033749338
              ],
              [
                -44.6750805032068,
                -23.111112880238554
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 20,
        "nome": "Rio Grande do Norte",
        "sigla": "RN",
        "regiao_id": 4,
        "codigo_ibg": 24
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -37.1517214228388,
                -4.935362041355721
              ],
              [
                -37.133105053437994,
                -4.937952521103094
              ],
              [
                -37.12993308621472,
                -4.936248530279583
              ],
              [
                -37.12814860040386,
                -4.935290537125431
              ],
              [
                -37.12538864444315,
                -4.933808557716918
              ],
              [
                -37.11912918523302,
                -4.930447061745865
              ],
              [
                -37.10917579954132,
                -4.925101615969468
              ],
              [
                -37.09760738670835,
                -4.924537089755612
              ],
              [
                -37.090818446731035,
                -4.928114583446412
              ],
              [
                -37.08913947108113,
                -4.928999562453764
              ],
              [
                -37.08732045962877,
                -4.929957565800666
              ],
              [
                -37.08316602213842,
                -4.932147051024943
              ],
              [
                -37.0827980288185,
                -4.931265020971376
              ],
              [
                -37.08201851461376,
                -4.929396552687045
              ],
              [
                -37.07147111606572,
                -4.936383506929119
              ],
              [
                -37.069530137528304,
                -4.937668499569623
              ],
              [
                -37.063089161147595,
                -4.941957947667566
              ],
              [
                -37.05710824186811,
                -4.9459414354669
              ],
              [
                -37.05030780994046,
                -4.94766290414205
              ],
              [
                -37.044160821340725,
                -4.949218895481208
              ],
              [
                -37.038385380517056,
                -4.950680377769646
              ],
              [
                -37.029486987025024,
                -4.94852888971076
              ],
              [
                -36.99890575646657,
                -4.930772528079072
              ],
              [
                -36.98105789546862,
                -4.933588975385545
              ],
              [
                -36.97187847872102,
                -4.930990487844192
              ],
              [
                -36.964356045014505,
                -4.927076518785309
              ],
              [
                -36.96411006261044,
                -4.926215541633844
              ],
              [
                -36.963182571397915,
                -4.922969043459113
              ],
              [
                -36.96215858820426,
                -4.919381085003312
              ],
              [
                -36.95861609528278,
                -4.92175357303076
              ],
              [
                -36.95479012373831,
                -4.924378543871782
              ],
              [
                -36.9328018402872,
                -4.939458412805035
              ],
              [
                -36.927024875304994,
                -4.943419863842913
              ],
              [
                -36.921248402320295,
                -4.947382315870587
              ],
              [
                -36.900898607396975,
                -4.950509323384607
              ],
              [
                -36.896343636826195,
                -4.950073286642793
              ],
              [
                -36.88117526747231,
                -4.948622316828961
              ],
              [
                -36.87883879109917,
                -4.950647793252252
              ],
              [
                -36.87329285268679,
                -4.955456264524101
              ],
              [
                -36.87097885312522,
                -4.957463259107045
              ],
              [
                -36.85977993120504,
                -4.967173669831642
              ],
              [
                -36.854739477475654,
                -4.971695100674869
              ],
              [
                -36.84999151723507,
                -4.978079561568621
              ],
              [
                -36.83596515045423,
                -4.997494404236147
              ],
              [
                -36.82920469744912,
                -5.004961341344121
              ],
              [
                -36.822445264435565,
                -5.01242777945387
              ],
              [
                -36.79895742598152,
                -5.038371075435156
              ],
              [
                -36.792578990111565,
                -5.040860011042456
              ],
              [
                -36.78620055824444,
                -5.043351011631137
              ],
              [
                -36.74185093669617,
                -5.060666832833419
              ],
              [
                -36.7391219780002,
                -5.060341361083967
              ],
              [
                -36.7323860049932,
                -5.059538866164968
              ],
              [
                -36.734215504297204,
                -5.062529838856977
              ],
              [
                -36.73498498185444,
                -5.063787329058465
              ],
              [
                -36.72261861659732,
                -5.074974744113936
              ],
              [
                -36.71406717198362,
                -5.073548719315179
              ],
              [
                -36.712146701139396,
                -5.073228236934732
              ],
              [
                -36.708017227755384,
                -5.06517278412319
              ],
              [
                -36.70374725873712,
                -5.064785283094555
              ],
              [
                -36.70338325813707,
                -5.068388268704237
              ],
              [
                -36.702794268401135,
                -5.074215244174962
              ],
              [
                -36.702304758279965,
                -5.079058687670775
              ],
              [
                -36.702205773661134,
                -5.080035166706537
              ],
              [
                -36.70191477977399,
                -5.082919655181677
              ],
              [
                -36.7002372750082,
                -5.084125644552921
              ],
              [
                -36.697307297870466,
                -5.086232116987474
              ],
              [
                -36.6894673973992,
                -5.091867581315648
              ],
              [
                -36.68303592320831,
                -5.090133101090025
              ],
              [
                -36.66661956099307,
                -5.085290126976353
              ],
              [
                -36.666009572484825,
                -5.085110112124947
              ],
              [
                -36.655853184914164,
                -5.086087610024312
              ],
              [
                -36.64569726134958,
                -5.087065581919549
              ],
              [
                -36.628162408791084,
                -5.088752563304927
              ],
              [
                -36.622574463394834,
                -5.080637644284854
              ],
              [
                -36.62147246996549,
                -5.079037135708532
              ],
              [
                -36.6193770090656,
                -5.075993186338748
              ],
              [
                -36.61503154967519,
                -5.077741652945097
              ],
              [
                -36.609578581706174,
                -5.080265154699346
              ],
              [
                -36.6064815944183,
                -5.081698630494198
              ],
              [
                -36.6003821681773,
                -5.080274630948301
              ],
              [
                -36.60029365601407,
                -5.080170621747278
              ],
              [
                -36.59574321503415,
                -5.074821159847546
              ],
              [
                -36.58777578014396,
                -5.079747638819815
              ],
              [
                -36.58539128811865,
                -5.080054634117042
              ],
              [
                -36.5783993736249,
                -5.08095512325672
              ],
              [
                -36.55839702361148,
                -5.083525104234704
              ],
              [
                -36.55431457939431,
                -5.080368102792566
              ],
              [
                -36.55247307681887,
                -5.080169590469687
              ],
              [
                -36.54517916587798,
                -5.079381612166664
              ],
              [
                -36.54409765152501,
                -5.079265115563922
              ],
              [
                -36.54164666338822,
                -5.079000611468414
              ],
              [
                -36.536539745944786,
                -5.078449111356806
              ],
              [
                -36.53214574414372,
                -5.0779746249822
              ],
              [
                -36.52658231477766,
                -5.077374134038851
              ],
              [
                -36.5159674188201,
                -5.069608203747525
              ],
              [
                -36.51073095618694,
                -5.065776694229069
              ],
              [
                -36.50549401755873,
                -5.061945236716169
              ],
              [
                -36.48293372378837,
                -5.061721227146001
              ],
              [
                -36.48008573786024,
                -5.062108214562817
              ],
              [
                -36.472502288625016,
                -5.063137189370666
              ],
              [
                -36.467663829225614,
                -5.063794193587365
              ],
              [
                -36.45932590864322,
                -5.064926181175824
              ],
              [
                -36.44992899539385,
                -5.066202170058057
              ],
              [
                -36.44356253346296,
                -5.067066681814993
              ],
              [
                -36.437196118536036,
                -5.067930655575832
              ],
              [
                -36.41172433546095,
                -5.076290080726054
              ],
              [
                -36.40555738916422,
                -5.078386079561353
              ],
              [
                -36.38945850250656,
                -5.083595515046983
              ],
              [
                -36.37827361900058,
                -5.087264968537863
              ],
              [
                -36.376240641722276,
                -5.087932484078642
              ],
              [
                -36.36912217677852,
                -5.090268457471424
              ],
              [
                -36.349769847334876,
                -5.087030968029681
              ],
              [
                -36.347033388935756,
                -5.08446998749058
              ],
              [
                -36.34702539601081,
                -5.084461987551619
              ],
              [
                -36.34426240484183,
                -5.081912021911802
              ],
              [
                -36.32652007841978,
                -5.088059472909586
              ],
              [
                -36.32216660875408,
                -5.090760412721812
              ],
              [
                -36.31546417823532,
                -5.094918874479366
              ],
              [
                -36.31050972372753,
                -5.097993348943533
              ],
              [
                -36.30900820660592,
                -5.098924861600129
              ],
              [
                -36.308044231361535,
                -5.102526339792361
              ],
              [
                -36.297041305649245,
                -5.093636383607997
              ],
              [
                -36.292376862012425,
                -5.089867431944272
              ],
              [
                -36.28937688690113,
                -5.087444926159471
              ],
              [
                -36.284089458431346,
                -5.088404920672343
              ],
              [
                -36.27963747961156,
                -5.089213401840432
              ],
              [
                -36.27657201159104,
                -5.08976942576431
              ],
              [
                -36.27308454902093,
                -5.08893741378185
              ],
              [
                -36.254704677252136,
                -5.093383872282984
              ],
              [
                -36.24887773146835,
                -5.094605850385796
              ],
              [
                -36.248027266943964,
                -5.094783861506099
              ],
              [
                -36.24317278263104,
                -5.095771356009551
              ],
              [
                -36.15129658672481,
                -5.095027302757096
              ],
              [
                -36.134284771748526,
                -5.09198383202621
              ],
              [
                -36.129478812983876,
                -5.091123339630744
              ],
              [
                -36.1239068281126,
                -5.090126847961364
              ],
              [
                -36.12188237771448,
                -5.088988360234869
              ],
              [
                -36.11867941314502,
                -5.087188377315789
              ],
              [
                -36.11244643441992,
                -5.083682892798173
              ],
              [
                -36.10937946660233,
                -5.081959377327999
              ],
              [
                -36.09878458796441,
                -5.076001933653163
              ],
              [
                -36.07786926176759,
                -5.06507902239272
              ],
              [
                -36.06935382720836,
                -5.063969529203169
              ],
              [
                -36.06504236391414,
                -5.063407039645925
              ],
              [
                -36.06184840558662,
                -5.06299454942174
              ],
              [
                -36.05816944861418,
                -5.062519533466744
              ],
              [
                -36.055677446501974,
                -5.061159045339449
              ],
              [
                -36.050377495180115,
                -5.058266543331914
              ],
              [
                -36.04102708806331,
                -5.053164611369151
              ],
              [
                -36.04042809756526,
                -5.052837594743755
              ],
              [
                -36.03614163993825,
                -5.050498126732577
              ],
              [
                -36.019201778678344,
                -5.049085608855382
              ],
              [
                -36.01277233727767,
                -5.048549646041652
              ],
              [
                -36.00846087845413,
                -5.047078626927644
              ],
              [
                -35.999757972538305,
                -5.044109157889458
              ],
              [
                -35.993162013981845,
                -5.044895135187222
              ],
              [
                -35.99187902335297,
                -5.045042633344049
              ],
              [
                -35.98410459625968,
                -5.045939126152565
              ],
              [
                -35.98237562250419,
                -5.044275629901662
              ],
              [
                -35.97943465413652,
                -5.041444670601162
              ],
              [
                -35.97427368107739,
                -5.041650169361117
              ],
              [
                -35.9700137379087,
                -5.042004148353166
              ],
              [
                -35.961639807279845,
                -5.042701151499167
              ],
              [
                -35.958781811664046,
                -5.042939137135134
              ],
              [
                -35.953716359656624,
                -5.043360649175169
              ],
              [
                -35.94891891524254,
                -5.043817636057696
              ],
              [
                -35.93232006535278,
                -5.04996607250991
              ],
              [
                -35.91416621207931,
                -5.056706020336994
              ],
              [
                -35.90790924979397,
                -5.05902850722003
              ],
              [
                -35.904399289574194,
                -5.060158501227257
              ],
              [
                -35.90382828358182,
                -5.060341997441633
              ],
              [
                -35.90047383000185,
                -5.061417497971618
              ],
              [
                -35.89553338133397,
                -5.063000945555227
              ],
              [
                -35.882122003974985,
                -5.067300424692696
              ],
              [
                -35.873347071193955,
                -5.067608916805934
              ],
              [
                -35.86796310618985,
                -5.067798388579194
              ],
              [
                -35.86795562525666,
                -5.06779839657544
              ],
              [
                -35.864344167452956,
                -5.067925393741093
              ],
              [
                -35.852039772157326,
                -5.068357394090777
              ],
              [
                -35.828495968470506,
                -5.07453132582463
              ],
              [
                -35.80230818837163,
                -5.072813853887321
              ],
              [
                -35.798318219415805,
                -5.074073312585975
              ],
              [
                -35.79619724304488,
                -5.074743334042339
              ],
              [
                -35.79537772824291,
                -5.075002316513106
              ],
              [
                -35.794196749616084,
                -5.07537531087014
              ],
              [
                -35.79049879109773,
                -5.076543788458233
              ],
              [
                -35.78935128545374,
                -5.076346794502675
              ],
              [
                -35.783581849524104,
                -5.075355287763636
              ],
              [
                -35.777396399275254,
                -5.078498778928785
              ],
              [
                -35.77476591913466,
                -5.079836260660213
              ],
              [
                -35.771399462391,
                -5.08154726596416
              ],
              [
                -35.76585348859895,
                -5.08434924424223
              ],
              [
                -35.7571151005624,
                -5.08876367971434
              ],
              [
                -35.736187754623046,
                -5.093053138141494
              ],
              [
                -35.731429803703804,
                -5.094028157780723
              ],
              [
                -35.72832283218393,
                -5.094665126012589
              ],
              [
                -35.71735143522958,
                -5.096914613109392
              ],
              [
                -35.71550443413479,
                -5.09816860990796
              ],
              [
                -35.70834100082409,
                -5.10303205746277
              ],
              [
                -35.70113658650191,
                -5.102612561314626
              ],
              [
                -35.69987010192047,
                -5.102441550086352
              ],
              [
                -35.69711910172398,
                -5.102070058763169
              ],
              [
                -35.689010173087695,
                -5.104512525694655
              ],
              [
                -35.68131874275697,
                -5.106882024430329
              ],
              [
                -35.6777482821716,
                -5.107982510625704
              ],
              [
                -35.66892233583223,
                -5.110701959922593
              ],
              [
                -35.63883612565442,
                -5.119904885427593
              ],
              [
                -35.634828157939815,
                -5.121130391373693
              ],
              [
                -35.63072469105712,
                -5.120390887394552
              ],
              [
                -35.62700071972564,
                -5.119754883756245
              ],
              [
                -35.622725243419666,
                -5.118948406242584
              ],
              [
                -35.618325791237964,
                -5.11811739186957
              ],
              [
                -35.61767379322983,
                -5.115793415617021
              ],
              [
                -35.61656830708318,
                -5.111853921398986
              ],
              [
                -35.61247934374395,
                -5.113805933342377
              ],
              [
                -35.60245895205372,
                -5.118764392654032
              ],
              [
                -35.59983543891452,
                -5.120062380693013
              ],
              [
                -35.59392548641435,
                -5.122987359738853
              ],
              [
                -35.58467007507325,
                -5.127567313529352
              ],
              [
                -35.57782914069541,
                -5.130952270330038
              ],
              [
                -35.570989209312415,
                -5.134337733124678
              ],
              [
                -35.55627935638122,
                -5.137908685463656
              ],
              [
                -35.531453543946206,
                -5.143935660575955
              ],
              [
                -35.51965766183517,
                -5.149730575092024
              ],
              [
                -35.51808117958664,
                -5.150505570939223
              ],
              [
                -35.50943823798287,
                -5.154752049741768
              ],
              [
                -35.48862941502256,
                -5.157973017874774
              ],
              [
                -35.46510663745378,
                -5.185249769032332
              ],
              [
                -35.462553624566524,
                -5.190837729854174
              ],
              [
                -35.46000115567242,
                -5.196425688677398
              ],
              [
                -35.438555833804536,
                -5.209059573108324
              ],
              [
                -35.4323869103536,
                -5.212694051144785
              ],
              [
                -35.42834495944141,
                -5.215075016548647
              ],
              [
                -35.42478198137213,
                -5.217174497505457
              ],
              [
                -35.41765601423705,
                -5.221372459425165
              ],
              [
                -35.38857725353565,
                -5.256704187513697
              ],
              [
                -35.384160318630514,
                -5.267715061849732
              ],
              [
                -35.38205080451333,
                -5.273757533161016
              ],
              [
                -35.36851844823087,
                -5.29312334625482
              ],
              [
                -35.36777792286753,
                -5.29708082938777
              ],
              [
                -35.3670384374935,
                -5.301038299522681
              ],
              [
                -35.35902648753049,
                -5.34390345463173
              ],
              [
                -35.35628403349761,
                -5.351049393590054
              ],
              [
                -35.35354153746144,
                -5.35819531955105
              ],
              [
                -35.351177050234746,
                -5.361056808854056
              ],
              [
                -35.344837588249426,
                -5.36872772564614
              ],
              [
                -35.3421741485229,
                -5.371950229832
              ],
              [
                -35.33889564503352,
                -5.375735698080716
              ],
              [
                -35.33442319233937,
                -5.377464661576785
              ],
              [
                -35.3255612592291,
                -5.380887142900262
              ],
              [
                -35.3207413116671,
                -5.376808667854674
              ],
              [
                -35.309599923291294,
                -5.391386051479228
              ],
              [
                -35.31007188946066,
                -5.40408694354789
              ],
              [
                -35.31021989905961,
                -5.408072394939198
              ],
              [
                -35.30462744200877,
                -5.416410855636313
              ],
              [
                -35.29795251384896,
                -5.426362760536536
              ],
              [
                -35.2958295307684,
                -5.426693252715149
              ],
              [
                -35.29282105757944,
                -5.42716225329436
              ],
              [
                -35.292391557953025,
                -5.430018204649964
              ],
              [
                -35.29168255567068,
                -5.435113700496392
              ],
              [
                -35.29116855136422,
                -5.438808156934734
              ],
              [
                -35.29116304639337,
                -5.43884713261232
              ],
              [
                -35.288967068887764,
                -5.454629006074669
              ],
              [
                -35.28824407666793,
                -5.459823470114475
              ],
              [
                -35.28403611191168,
                -5.462882452824271
              ],
              [
                -35.281682117187295,
                -5.464593942555211
              ],
              [
                -35.278221648513856,
                -5.469928873002053
              ],
              [
                -35.27476117980174,
                -5.475335340862708
              ],
              [
                -35.27243470481898,
                -5.47897029984177
              ],
              [
                -35.26541026715507,
                -5.482502286182314
              ],
              [
                -35.260707805543916,
                -5.480447303542184
              ],
              [
                -35.25939729323937,
                -5.495817652872354
              ],
              [
                -35.258778289650145,
                -5.505589610466544
              ],
              [
                -35.25909178802964,
                -5.510885558239305
              ],
              [
                -35.2579407994444,
                -5.51455551655912
              ],
              [
                -35.254224322410565,
                -5.519230497281782
              ],
              [
                -35.2506543803849,
                -5.525174925681942
              ],
              [
                -35.24895937812275,
                -5.528032386370185
              ],
              [
                -35.24900389319462,
                -5.536561359526734
              ],
              [
                -35.24900886068156,
                -5.537442843308973
              ],
              [
                -35.249036864634654,
                -5.542700306261166
              ],
              [
                -35.24426489484399,
                -5.557227681733806
              ],
              [
                -35.24169542997527,
                -5.560966150743634
              ],
              [
                -35.23723645075668,
                -5.567244588941737
              ],
              [
                -35.23443849483614,
                -5.571184042183173
              ],
              [
                -35.23439849716593,
                -5.571240546699008
              ],
              [
                -35.23223702299461,
                -5.574284530614211
              ],
              [
                -35.22777456413044,
                -5.57621651639932
              ],
              [
                -35.22599206626284,
                -5.583529467563137
              ],
              [
                -35.22633657396037,
                -5.591369373561748
              ],
              [
                -35.22690602987326,
                -5.604334260739848
              ],
              [
                -35.22707002838427,
                -5.60807726219263
              ],
              [
                -35.2273230289877,
                -5.612015219099818
              ],
              [
                -35.22418756697441,
                -5.623597118623872
              ],
              [
                -35.223160084176364,
                -5.627393600001855
              ],
              [
                -35.2222475581946,
                -5.627759080516987
              ],
              [
                -35.2164716299492,
                -5.630071578465414
              ],
              [
                -35.21673961132364,
                -5.639718473677148
              ],
              [
                -35.217185594547736,
                -5.650359382866205
              ],
              [
                -35.217512614381896,
                -5.658144332364592
              ],
              [
                -35.21766208710535,
                -5.661701300353214
              ],
              [
                -35.217731599581214,
                -5.663355293863461
              ],
              [
                -35.21788760015763,
                -5.667071250557758
              ],
              [
                -35.21804307874036,
                -5.670781757298198
              ],
              [
                -35.21513662392294,
                -5.678110196783386
              ],
              [
                -35.204917692041406,
                -5.692609534636737
              ],
              [
                -35.20063773955996,
                -5.692658050896354
              ],
              [
                -35.19944875523114,
                -5.692726070689148
              ],
              [
                -35.197292769581644,
                -5.692849053503073
              ],
              [
                -35.19474127299419,
                -5.695721507617858
              ],
              [
                -35.19270380597246,
                -5.698236004940944
              ],
              [
                -35.193829800590834,
                -5.702335484038847
              ],
              [
                -35.199207731413836,
                -5.721947319672154
              ],
              [
                -35.20428916846509,
                -5.740476682053907
              ],
              [
                -35.203701676804016,
                -5.747652627101288
              ],
              [
                -35.203378189587795,
                -5.751515589364189
              ],
              [
                -35.20321417398231,
                -5.75347854523785
              ],
              [
                -35.20289266487123,
                -5.757115051354033
              ],
              [
                -35.1995627092537,
                -5.758221546471315
              ],
              [
                -35.19935372309776,
                -5.758291017788085
              ],
              [
                -35.196017755567205,
                -5.759340018371337
              ],
              [
                -35.19550774591124,
                -5.763413481813712
              ],
              [
                -35.19503275409453,
                -5.767204438584702
              ],
              [
                -35.19461577300676,
                -5.770538435121658
              ],
              [
                -35.193328254909645,
                -5.780823822404364
              ],
              [
                -35.18781280785633,
                -5.789370278543367
              ],
              [
                -35.18586983972814,
                -5.79231123844582
              ],
              [
                -35.185742814765625,
                -5.792503731803228
              ],
              [
                -35.182907365341016,
                -5.792449758665021
              ],
              [
                -35.18038788690101,
                -5.801700160725114
              ],
              [
                -35.18028087587531,
                -5.808871089116172
              ],
              [
                -35.179967846345136,
                -5.825641960034637
              ],
              [
                -35.17917931780058,
                -5.867842146247727
              ],
              [
                -35.17407889340909,
                -5.877352062797898
              ],
              [
                -35.173075398584125,
                -5.878893052661986
              ],
              [
                -35.171131914416286,
                -5.881878006217482
              ],
              [
                -35.17075541669122,
                -5.882087518295848
              ],
              [
                -35.16636694186647,
                -5.884525984926086
              ],
              [
                -35.16107548820636,
                -5.879825029281082
              ],
              [
                -35.15725054255341,
                -5.881826530793026
              ],
              [
                -35.15424403276586,
                -5.883400001258447
              ],
              [
                -35.15464155809372,
                -5.897734887556966
              ],
              [
                -35.15573804359445,
                -5.909432302785175
              ],
              [
                -35.15701500136763,
                -5.923045666515391
              ],
              [
                -35.153709045477385,
                -5.927785630991916
              ],
              [
                -35.153605528613745,
                -5.941498549153311
              ],
              [
                -35.1506965723426,
                -5.949389951175718
              ],
              [
                -35.14573209741568,
                -5.962856360594826
              ],
              [
                -35.1382461557997,
                -5.964799855471126
              ],
              [
                -35.135097684721416,
                -5.965617344006319
              ],
              [
                -35.128582226505266,
                -5.967309797482381
              ],
              [
                -35.12521327586411,
                -5.974344241224791
              ],
              [
                -35.1220333143395,
                -5.983432183344437
              ],
              [
                -35.11833032103515,
                -5.984654163261041
              ],
              [
                -35.113369369004644,
                -5.987749145192055
              ],
              [
                -35.11334734845778,
                -5.994281081955237
              ],
              [
                -35.10681292171505,
                -6.005916997391093
              ],
              [
                -35.107097903316536,
                -6.009096460652614
              ],
              [
                -35.10903638701689,
                -6.01919790048504
              ],
              [
                -35.11119035111404,
                -6.030424279297383
              ],
              [
                -35.10939037763634,
                -6.048957640345501
              ],
              [
                -35.106238897611966,
                -6.05319360402985
              ],
              [
                -35.1041559207809,
                -6.055992571033408
              ],
              [
                -35.10324592193173,
                -6.056093583682546
              ],
              [
                -35.09743596997468,
                -6.056736088077541
              ],
              [
                -35.09800998357533,
                -6.060566542225629
              ],
              [
                -35.09995745990702,
                -6.069350969848353
              ],
              [
                -35.1012569157773,
                -6.075212427895861
              ],
              [
                -35.100865938240375,
                -6.083916377830743
              ],
              [
                -35.09948244072652,
                -6.114745096194483
              ],
              [
                -35.09894241454839,
                -6.126780505986502
              ],
              [
                -35.098612443624724,
                -6.138537411183551
              ],
              [
                -35.09511595391744,
                -6.171227643362417
              ],
              [
                -35.093985434874874,
                -6.18179056684688
              ],
              [
                -35.093516934423185,
                -6.181249069969726
              ],
              [
                -35.0910519793532,
                -6.178400090662988
              ],
              [
                -35.08806050692133,
                -6.182507083512814
              ],
              [
                -35.08491602774715,
                -6.186821532585908
              ],
              [
                -35.08313053441132,
                -6.189271516616791
              ],
              [
                -35.08110305779251,
                -6.19757044797463
              ],
              [
                -35.07774358095592,
                -6.211315326298876
              ],
              [
                -35.071692628685916,
                -6.22122022422691
              ],
              [
                -35.06672318189764,
                -6.220631227050535
              ],
              [
                -35.06359668436954,
                -6.22357769924936
              ],
              [
                -35.06033770491583,
                -6.226729205702896
              ],
              [
                -35.05528127347956,
                -6.226878206477295
              ],
              [
                -35.04967281802042,
                -6.227043679789006
              ],
              [
                -35.04191239401005,
                -6.227186694000861
              ],
              [
                -35.03677992233944,
                -6.235613627466143
              ],
              [
                -35.035025935740684,
                -6.273151303496125
              ],
              [
                -35.03477592078651,
                -6.278506275861074
              ],
              [
                -35.034427923539305,
                -6.285817711285107
              ],
              [
                -35.03315442288464,
                -6.292732652376923
              ],
              [
                -35.03030993827882,
                -6.308173026321568
              ],
              [
                -35.0366823791081,
                -6.314160964570524
              ],
              [
                -35.03490889261479,
                -6.321631436874287
              ],
              [
                -35.033898918098984,
                -6.325995880847598
              ],
              [
                -35.033467914865874,
                -6.327857892477827
              ],
              [
                -35.032737423554416,
                -6.331014361423933
              ],
              [
                -35.02972943185246,
                -6.344010729158429
              ],
              [
                -35.02181447634385,
                -6.362144105254899
              ],
              [
                -35.01791701108937,
                -6.367843023659272
              ],
              [
                -35.011454086336805,
                -6.369726547445964
              ],
              [
                -35.007913615873846,
                -6.368828531573726
              ],
              [
                -35.00733359844731,
                -6.366638061988868
              ],
              [
                -35.001107680197855,
                -6.369117009089467
              ],
              [
                -35.000884667159255,
                -6.369205515235697
              ],
              [
                -34.99783621496915,
                -6.370418517539396
              ],
              [
                -34.99165424933891,
                -6.372879499810236
              ],
              [
                -34.987031797365695,
                -6.383961920091535
              ],
              [
                -34.980881849818765,
                -6.398928787940591
              ],
              [
                -34.98010885274205,
                -6.402306266076145
              ],
              [
                -34.97829384629684,
                -6.410238684637061
              ],
              [
                -34.97726736783096,
                -6.414722158652268
              ],
              [
                -34.9750328610454,
                -6.424488059098446
              ],
              [
                -34.97209886765179,
                -6.437384968740987
              ],
              [
                -34.972387363503984,
                -6.46262225337688
              ],
              [
                -34.970268404195096,
                -6.466618734685095
              ],
              [
                -34.968627403121054,
                -6.469713212597621
              ],
              [
                -34.96880738986831,
                -6.477198156064708
              ],
              [
                -34.96893740435733,
                -6.482590089463288
              ],
              [
                -34.96906337896985,
                -6.48783704403754
              ],
              [
                -34.97050489169873,
                -6.484991069984145
              ],
              [
                -34.974917826250696,
                -6.487493049451468
              ],
              [
                -34.97916380837118,
                -6.489901060579649
              ],
              [
                -34.98612022158402,
                -6.496187016988771
              ],
              [
                -34.99154467850446,
                -6.501088458669769
              ],
              [
                -35.01059752241283,
                -6.505715455056762
              ],
              [
                -35.01199449113316,
                -6.505158945430125
              ],
              [
                -35.018282460862665,
                -6.502654454612078
              ],
              [
                -35.028025345288356,
                -6.505114948759766
              ],
              [
                -35.03406079857345,
                -6.513431895219584
              ],
              [
                -35.03644625297047,
                -6.516718862112998
              ],
              [
                -35.05721557066594,
                -6.527386301792768
              ],
              [
                -35.06192256012354,
                -6.525819311406194
              ],
              [
                -35.0666304905748,
                -6.524252335019421
              ],
              [
                -35.06934148169554,
                -6.52167683753981
              ],
              [
                -35.09503025391199,
                -6.497271564922992
              ],
              [
                -35.10114771813388,
                -6.499745538727318
              ],
              [
                -35.10238419779937,
                -6.503228501334796
              ],
              [
                -35.10316916133389,
                -6.505439000951234
              ],
              [
                -35.107892145876995,
                -6.505140006314283
              ],
              [
                -35.11205460546079,
                -6.504876500041012
              ],
              [
                -35.112132591302846,
                -6.507207495244891
              ],
              [
                -35.122941512816226,
                -6.529453821198948
              ],
              [
                -35.132157906891365,
                -6.535400770925266
              ],
              [
                -35.132305394440564,
                -6.535591258478866
              ],
              [
                -35.14256178855609,
                -6.548822677040993
              ],
              [
                -35.145240788202564,
                -6.552279154811327
              ],
              [
                -35.15124222735,
                -6.551779665619268
              ],
              [
                -35.1569771848915,
                -6.551303145074161
              ],
              [
                -35.16078564427202,
                -6.553289641430855
              ],
              [
                -35.16979156204604,
                -6.557985597189576
              ],
              [
                -35.19349535037248,
                -6.549739199679013
              ],
              [
                -35.198974804072584,
                -6.549532690795372
              ],
              [
                -35.20022880051018,
                -6.548342184192996
              ],
              [
                -35.20770623952943,
                -6.541242745196235
              ],
              [
                -35.209647735824625,
                -6.536778320449706
              ],
              [
                -35.2177296495964,
                -6.518197947504545
              ],
              [
                -35.22247213117638,
                -6.516046971841655
              ],
              [
                -35.224014583031654,
                -6.521207443152398
              ],
              [
                -35.224251095706286,
                -6.521514426822856
              ],
              [
                -35.224586597407665,
                -6.521949914518227
              ],
              [
                -35.23184453104479,
                -6.531371351037596
              ],
              [
                -35.25156133294597,
                -6.533482352400757
              ],
              [
                -35.25270882452364,
                -6.53360536113021
              ],
              [
                -35.26338825814682,
                -6.538538816054589
              ],
              [
                -35.27406764177681,
                -6.543472795989964
              ],
              [
                -35.33027915198329,
                -6.543936809712216
              ],
              [
                -35.335524087545714,
                -6.541074336091357
              ],
              [
                -35.34076956710599,
                -6.538211351473596
              ],
              [
                -35.36402186844642,
                -6.511637594281386
              ],
              [
                -35.398361584503114,
                -6.492228275279144
              ],
              [
                -35.402799534161545,
                -6.491270780780605
              ],
              [
                -35.40723700282631,
                -6.490313280281523
              ],
              [
                -35.429649802524544,
                -6.488117819039608
              ],
              [
                -35.44112967174002,
                -6.487326337623509
              ],
              [
                -35.44172417190851,
                -6.478531876842387
              ],
              [
                -35.446208156609615,
                -6.486441810937394
              ],
              [
                -35.459708532357354,
                -6.476586901789361
              ],
              [
                -35.46373597854715,
                -6.482305358259137
              ],
              [
                -35.472631924258394,
                -6.482756895209072
              ],
              [
                -35.472905916636826,
                -6.483007892359952
              ],
              [
                -35.47665537875825,
                -6.486447352020172
              ],
              [
                -35.48363832648182,
                -6.485624875031289
              ],
              [
                -35.48959774372568,
                -6.481120888039006
              ],
              [
                -35.49150225850529,
                -6.479681913820974
              ],
              [
                -35.49306024368068,
                -6.480982416329121
              ],
              [
                -35.49530770429558,
                -6.482858370638444
              ],
              [
                -35.49742067458649,
                -6.48080791447356
              ],
              [
                -35.500937676110716,
                -6.477394445163425
              ],
              [
                -35.51186957145272,
                -6.476413448926924
              ],
              [
                -35.51726601035303,
                -6.478947937916598
              ],
              [
                -35.52481646253147,
                -6.475694456847727
              ],
              [
                -35.52665494499007,
                -6.478905958156021
              ],
              [
                -35.527849910820215,
                -6.479589928402764
              ],
              [
                -35.53315686321437,
                -6.482628396288797
              ],
              [
                -35.53489187041069,
                -6.481373911474968
              ],
              [
                -35.537477318351726,
                -6.47950344915254
              ],
              [
                -35.54028682817909,
                -6.482564404289694
              ],
              [
                -35.54621473960179,
                -6.481464434870163
              ],
              [
                -35.54927674089323,
                -6.486540372960096
              ],
              [
                -35.55135821501707,
                -6.489990845513839
              ],
              [
                -35.55854563892602,
                -6.492388363759312
              ],
              [
                -35.56431610171785,
                -6.491377864525138
              ],
              [
                -35.56371161786469,
                -6.48706739381369
              ],
              [
                -35.580486454806895,
                -6.486054894528205
              ],
              [
                -35.59123536411382,
                -6.476758994056706
              ],
              [
                -35.591687880528305,
                -6.471229022815447
              ],
              [
                -35.59437932668017,
                -6.467532055241979
              ],
              [
                -35.59971129839674,
                -6.460207643503546
              ],
              [
                -35.6120011731143,
                -6.461677594403147
              ],
              [
                -35.61641563714558,
                -6.462205604931804
              ],
              [
                -35.61810363159519,
                -6.459650154542353
              ],
              [
                -35.61980711291994,
                -6.457071651348037
              ],
              [
                -35.628909036358046,
                -6.455234672837416
              ],
              [
                -35.63538798301705,
                -6.45392768741645
              ],
              [
                -35.65792228000514,
                -6.445570767756577
              ],
              [
                -35.662508246431734,
                -6.447830749467832
              ],
              [
                -35.664685729458895,
                -6.450112225496034
              ],
              [
                -35.67087618014878,
                -6.451322741656201
              ],
              [
                -35.681502059190656,
                -6.4475272798387
              ],
              [
                -35.709818804965614,
                -6.470086609424254
              ],
              [
                -35.717479231048,
                -6.472002111876228
              ],
              [
                -35.72118270606543,
                -6.471632617181613
              ],
              [
                -35.72729967159251,
                -6.471021097954287
              ],
              [
                -35.76367684967982,
                -6.480109547996499
              ],
              [
                -35.77286074890142,
                -6.482404043399555
              ],
              [
                -35.77616319817519,
                -6.482600548914538
              ],
              [
                -35.779465186453805,
                -6.482797553425622
              ],
              [
                -35.852617537265814,
                -6.48717203575013
              ],
              [
                -35.943728250221504,
                -6.492607547180557
              ],
              [
                -35.964784557358605,
                -6.48395913882878
              ],
              [
                -35.977186450535,
                -6.488506126337698
              ],
              [
                -36.00489521575515,
                -6.480655182823717
              ],
              [
                -36.00690117497956,
                -6.474122727371476
              ],
              [
                -36.012439645587236,
                -6.472357255024196
              ],
              [
                -36.007963172379604,
                -6.464853332224811
              ],
              [
                -36.007884174169725,
                -6.464721328230999
              ],
              [
                -36.01283312032682,
                -6.454641401048385
              ],
              [
                -36.035248435504535,
                -6.444333984765194
              ],
              [
                -36.036261440746465,
                -6.443867984136963
              ],
              [
                -36.04353087060342,
                -6.437835545022132
              ],
              [
                -36.05080131645375,
                -6.431804094896892
              ],
              [
                -36.05113181893852,
                -6.431112084644252
              ],
              [
                -36.061288258628466,
                -6.409848289255986
              ],
              [
                -36.06677617554277,
                -6.41469173695514
              ],
              [
                -36.06980716952877,
                -6.414591235674733
              ],
              [
                -36.073314145273784,
                -6.414474758823149
              ],
              [
                -36.072730136050076,
                -6.412005263217501
              ],
              [
                -36.07226163048473,
                -6.41002576576418
              ],
              [
                -36.07956506554712,
                -6.404792845256449
              ],
              [
                -36.08629703069673,
                -6.415438256320035
              ],
              [
                -36.093807454960285,
                -6.418060738088081
              ],
              [
                -36.09703841339157,
                -6.419188718108545
              ],
              [
                -36.102670375851176,
                -6.421155219942504
              ],
              [
                -36.11083477227468,
                -6.428529174136516
              ],
              [
                -36.12696614259904,
                -6.424808676115465
              ],
              [
                -36.13136609144764,
                -6.427759193313544
              ],
              [
                -36.13815003690576,
                -6.426150680476839
              ],
              [
                -36.142995992375894,
                -6.425002218732041
              ],
              [
                -36.171313269347664,
                -6.430199686162632
              ],
              [
                -36.20301396107093,
                -6.432467697191686
              ],
              [
                -36.218684851630066,
                -6.43350618086701
              ],
              [
                -36.24933107014207,
                -6.436963155780995
              ],
              [
                -36.25072258865818,
                -6.404027449647201
              ],
              [
                -36.29516870451796,
                -6.39296352716771
              ],
              [
                -36.297993151566885,
                -6.378393647287918
              ],
              [
                -36.294431187305904,
                -6.370473228288564
              ],
              [
                -36.27813086733459,
                -6.345412906222151
              ],
              [
                -36.278584356067746,
                -6.317052134194369
              ],
              [
                -36.27944436177557,
                -6.30845619645878
              ],
              [
                -36.29491775743717,
                -6.293181828254085
              ],
              [
                -36.303402696065874,
                -6.284805391524187
              ],
              [
                -36.307152163969754,
                -6.281103912458507
              ],
              [
                -36.34348382536233,
                -6.302015774982753
              ],
              [
                -36.35847816406055,
                -6.308088753851517
              ],
              [
                -36.36216865126962,
                -6.30958326222604
              ],
              [
                -36.39413037479099,
                -6.293579878053765
              ],
              [
                -36.429873038829236,
                -6.361860366651862
              ],
              [
                -36.43713644538213,
                -6.3615898684066
              ],
              [
                -36.44440086693137,
                -6.361319385162402
              ],
              [
                -36.499419893918635,
                -6.357770943296829
              ],
              [
                -36.504511851919624,
                -6.38571122096335
              ],
              [
                -36.50706332831464,
                -6.399722117022717
              ],
              [
                -36.52364214496093,
                -6.435654328655464
              ],
              [
                -36.52875011545033,
                -6.446723760882146
              ],
              [
                -36.52920009210884,
                -6.453475187474814
              ],
              [
                -36.52965009276178,
                -6.460227663065369
              ],
              [
                -36.52770559601717,
                -6.478874494549062
              ],
              [
                -36.5265655820996,
                -6.489780406116907
              ],
              [
                -36.525417600190124,
                -6.500765333064006
              ],
              [
                -36.51880564717169,
                -6.501997315985338
              ],
              [
                -36.50000283114674,
                -6.513109715462537
              ],
              [
                -36.49110088435295,
                -6.516125676710021
              ],
              [
                -36.48219896356689,
                -6.519141671954376
              ],
              [
                -36.4805179780876,
                -6.527500104420574
              ],
              [
                -36.47794650063789,
                -6.540288987101927
              ],
              [
                -36.46869106163637,
                -6.548473920021869
              ],
              [
                -36.46523361290174,
                -6.54923039875518
              ],
              [
                -36.45872615269389,
                -6.550922912059762
              ],
              [
                -36.45824814386572,
                -6.551047377758803
              ],
              [
                -36.44874072484833,
                -6.553518866913812
              ],
              [
                -36.44296229285653,
                -6.560279797404733
              ],
              [
                -36.44071479809837,
                -6.599620011340586
              ],
              [
                -36.43542531915884,
                -6.625481287465457
              ],
              [
                -36.43767130304861,
                -6.62869479242944
              ],
              [
                -36.437799809783456,
                -6.628878753053507
              ],
              [
                -36.439028300219235,
                -6.628317772318024
              ],
              [
                -36.4489181921782,
                -6.623800839703264
              ],
              [
                -36.45040567504493,
                -6.625158812902298
              ],
              [
                -36.46008458407951,
                -6.633996250134999
              ],
              [
                -36.46987852889912,
                -6.618895855460617
              ],
              [
                -36.473047503924825,
                -6.614009401339291
              ],
              [
                -36.5015927355141,
                -6.601086032715981
              ],
              [
                -36.50752869968879,
                -6.595056560478289
              ],
              [
                -36.51337966235423,
                -6.593996609738074
              ],
              [
                -36.524113549448465,
                -6.599256047059037
              ],
              [
                -36.523578045859715,
                -6.601307020431619
              ],
              [
                -36.52133506614546,
                -6.609895484807303
              ],
              [
                -36.53659089685413,
                -6.653188119552999
              ],
              [
                -36.54698878871607,
                -6.694753841100644
              ],
              [
                -36.55321225231817,
                -6.702329759257154
              ],
              [
                -36.557034689219776,
                -6.706983241963756
              ],
              [
                -36.557345187189235,
                -6.710371688340764
              ],
              [
                -36.54377529012688,
                -6.730806028450316
              ],
              [
                -36.53390739836812,
                -6.733050513052916
              ],
              [
                -36.533196393582585,
                -6.733212006296733
              ],
              [
                -36.5264799566694,
                -6.735659484406692
              ],
              [
                -36.52177950113671,
                -6.742111430162332
              ],
              [
                -36.50399614313067,
                -6.755768318066172
              ],
              [
                -36.50243614479269,
                -6.76646972429031
              ],
              [
                -36.500875630448135,
                -6.777171639521105
              ],
              [
                -36.50651508224264,
                -6.8126248948029
              ],
              [
                -36.50903055363753,
                -6.815897342626165
              ],
              [
                -36.5115480190129,
                -6.819171308441901
              ],
              [
                -36.53020288312574,
                -6.826418774821796
              ],
              [
                -36.537314298174955,
                -6.834771232637008
              ],
              [
                -36.54322324587996,
                -6.841711182815852
              ],
              [
                -36.55972659102276,
                -6.840379686603871
              ],
              [
                -36.569367007357336,
                -6.847607638084311
              ],
              [
                -36.569594506908835,
                -6.848229629326305
              ],
              [
                -36.57308294635683,
                -6.857769578358213
              ],
              [
                -36.569522513953196,
                -6.862234035659377
              ],
              [
                -36.56769752361085,
                -6.864522502334937
              ],
              [
                -36.56636602430471,
                -6.86619248223328
              ],
              [
                -36.566499006467474,
                -6.86714648679
              ],
              [
                -36.56751150748529,
                -6.874396414231185
              ],
              [
                -36.6093661255194,
                -6.936436455566238
              ],
              [
                -36.61273506655215,
                -6.935025999022314
              ],
              [
                -36.61610455758078,
                -6.933615982474828
              ],
              [
                -36.63196438973137,
                -6.933462009682654
              ],
              [
                -36.637018354853325,
                -6.933412524577055
              ],
              [
                -36.63874034989987,
                -6.93291051472807
              ],
              [
                -36.65476771873711,
                -6.928237042674891
              ],
              [
                -36.68769891735276,
                -6.926601082402396
              ],
              [
                -36.71108319019728,
                -6.977484217049232
              ],
              [
                -36.71111369287917,
                -6.977551697539563
              ],
              [
                -36.718142105121004,
                -6.982332678835945
              ],
              [
                -36.73007452707572,
                -6.97534625615208
              ],
              [
                -36.736877957469396,
                -6.97136226625371
              ],
              [
                -36.743681892862604,
                -6.967378304352916
              ],
              [
                -36.765752735463764,
                -6.935201082925064
              ],
              [
                -36.76389624834742,
                -6.928871112448483
              ],
              [
                -36.75957580093268,
                -6.914138754410851
              ],
              [
                -36.75808481823072,
                -6.905399818179572
              ],
              [
                -36.756986847435044,
                -6.898966833073546
              ],
              [
                -36.75077640441952,
                -6.889041932942086
              ],
              [
                -36.744566462391354,
                -6.879116491840652
              ],
              [
                -36.74024899839509,
                -6.866529100045035
              ],
              [
                -36.729775598348056,
                -6.835996304533189
              ],
              [
                -36.76904327129937,
                -6.838351333902785
              ],
              [
                -36.77230927370617,
                -6.78666824361086
              ],
              [
                -36.77992570881733,
                -6.779823311785369
              ],
              [
                -36.78754162093575,
                -6.772978848952313
              ],
              [
                -36.8346112293673,
                -6.730674219574604
              ],
              [
                -36.89603120271332,
                -6.752009068051506
              ],
              [
                -36.909640577966314,
                -6.75673604207213
              ],
              [
                -36.93690232501998,
                -6.772212433785938
              ],
              [
                -36.937457314764515,
                -6.772712953224651
              ],
              [
                -36.95672315732947,
                -6.790079824718708
              ],
              [
                -36.95868514287943,
                -6.78290588751111
              ],
              [
                -36.95704013286648,
                -6.780822403780882
              ],
              [
                -36.95225018341513,
                -6.774756958241047
              ],
              [
                -36.961330115620015,
                -6.77391945703789
              ],
              [
                -36.96414958584396,
                -6.773659457011097
              ],
              [
                -36.96917554858219,
                -6.768953006483367
              ],
              [
                -36.97287550379768,
                -6.757302578700231
              ],
              [
                -36.97138654159758,
                -6.740249712924427
              ],
              [
                -36.977207495864306,
                -6.721459354832931
              ],
              [
                -36.97992596124537,
                -6.719346895312351
              ],
              [
                -36.98737541491174,
                -6.711129437074913
              ],
              [
                -36.98952639949109,
                -6.708756467219727
              ],
              [
                -37.001796303668876,
                -6.709172466257636
              ],
              [
                -37.0259560791856,
                -6.728023831270855
              ],
              [
                -37.03384600822348,
                -6.734180280198476
              ],
              [
                -37.04087693831174,
                -6.739670248798864
              ],
              [
                -37.059013260578205,
                -6.749027197580866
              ],
              [
                -37.075007135976755,
                -6.753740654449363
              ],
              [
                -37.11965222545042,
                -6.766895595642692
              ],
              [
                -37.12421618446183,
                -6.766413595557978
              ],
              [
                -37.128780667470444,
                -6.765932122469391
              ],
              [
                -37.14736797651535,
                -6.791777923315709
              ],
              [
                -37.15182091591685,
                -6.797969859787877
              ],
              [
                -37.18311513181619,
                -6.819317242938935
              ],
              [
                -37.18478414429172,
                -6.820455724166599
              ],
              [
                -37.187522616550524,
                -6.822712226368914
              ],
              [
                -37.1902630677911,
                -6.824969702566916
              ],
              [
                -37.23419468254998,
                -6.824240205736464
              ],
              [
                -37.25473951070439,
                -6.810666838333505
              ],
              [
                -37.25501102103187,
                -6.798071960220417
              ],
              [
                -37.260188516041445,
                -6.786963516860805
              ],
              [
                -37.26000699691471,
                -6.786569533823598
              ],
              [
                -37.256965535301624,
                -6.779966080488186
              ],
              [
                -37.26640694635744,
                -6.77223663458813
              ],
              [
                -37.27272888316921,
                -6.7670611695137
              ],
              [
                -37.27058090998566,
                -6.762889219742149
              ],
              [
                -37.26843344579559,
                -6.758717765971175
              ],
              [
                -37.26351600266241,
                -6.731774952901998
              ],
              [
                -37.27951587500326,
                -6.723841532085416
              ],
              [
                -37.28976227631856,
                -6.712650121878633
              ],
              [
                -37.2863838129471,
                -6.702632202164486
              ],
              [
                -37.28331137024969,
                -6.693520253568765
              ],
              [
                -37.294473246076265,
                -6.691716800323765
              ],
              [
                -37.31389608101502,
                -6.688579312170812
              ],
              [
                -37.342310344252006,
                -6.700854265283295
              ],
              [
                -37.39729984888469,
                -6.699435275985298
              ],
              [
                -37.397515861023386,
                -6.699375801598999
              ],
              [
                -37.40693178288149,
                -6.696802806196248
              ],
              [
                -37.42486009702084,
                -6.699778317151778
              ],
              [
                -37.484299583234964,
                -6.709642754665949
              ],
              [
                -37.47058474022851,
                -6.679943001750399
              ],
              [
                -37.47469419107784,
                -6.672624065866254
              ],
              [
                -37.47480368924815,
                -6.672428544472031
              ],
              [
                -37.47454919373465,
                -6.672065540140876
              ],
              [
                -37.465790793305146,
                -6.6595671510564
              ],
              [
                -37.47253171354163,
                -6.648497732355577
              ],
              [
                -37.473686206664574,
                -6.646618239864249
              ],
              [
                -37.47681619559832,
                -6.641520782925354
              ],
              [
                -37.47534022254326,
                -6.628317895370379
              ],
              [
                -37.474133245473645,
                -6.617522978150213
              ],
              [
                -37.47560374616972,
                -6.607722546961189
              ],
              [
                -37.47730319598208,
                -6.606599041916431
              ],
              [
                -37.47998618558406,
                -6.60482509463459
              ],
              [
                -37.47971969639862,
                -6.593760171194988
              ],
              [
                -37.474418242012135,
                -6.564058861545922
              ],
              [
                -37.48018270142638,
                -6.559426940748723
              ],
              [
                -37.48273169931946,
                -6.550018985259709
              ],
              [
                -37.475802261354254,
                -6.542405563353153
              ],
              [
                -37.468854337562995,
                -6.534770601622002
              ],
              [
                -37.463003395977644,
                -6.533340602935999
              ],
              [
                -37.42538571886976,
                -6.531902104112557
              ],
              [
                -37.420863254780365,
                -6.531728618457382
              ],
              [
                -37.41633979670152,
                -6.531555611798293
              ],
              [
                -37.39649146919745,
                -6.513816718939419
              ],
              [
                -37.390547049118744,
                -6.452443214551717
              ],
              [
                -37.38171764733173,
                -6.436471324411374
              ],
              [
                -37.38619559301834,
                -6.425647422533348
              ],
              [
                -37.390185075208215,
                -6.422134952793535
              ],
              [
                -37.39699149823357,
                -6.416141497424515
              ],
              [
                -37.395722529456265,
                -6.400761108660139
              ],
              [
                -37.38904009087607,
                -6.390624670105904
              ],
              [
                -37.388149601059155,
                -6.379343778364166
              ],
              [
                -37.387781636541376,
                -6.378968782078047
              ],
              [
                -37.382868151046296,
                -6.373950327408376
              ],
              [
                -37.382152668817895,
                -6.373219800697433
              ],
              [
                -37.37714071470779,
                -6.368941363143446
              ],
              [
                -37.377015714927694,
                -6.354881444842888
              ],
              [
                -37.376919733824096,
                -6.343988048628717
              ],
              [
                -37.37057780915681,
                -6.333903104019973
              ],
              [
                -37.36423636747701,
                -6.323817709441422
              ],
              [
                -37.35616444227351,
                -6.319555239849856
              ],
              [
                -37.33398762204105,
                -6.307843778958339
              ],
              [
                -37.32778968784213,
                -6.302814315662114
              ],
              [
                -37.32110125317268,
                -6.286526969925412
              ],
              [
                -37.31840726709102,
                -6.279965485001928
              ],
              [
                -37.31264084753792,
                -6.27892100155962
              ],
              [
                -37.30687339699691,
                -6.277876522118556
              ],
              [
                -37.294099502906285,
                -6.280377991195151
              ],
              [
                -37.28644556937,
                -6.281876470465345
              ],
              [
                -37.28038362104048,
                -6.281455975910353
              ],
              [
                -37.27432215870971,
                -6.281036484348874
              ],
              [
                -37.27015071638449,
                -6.251325707453824
              ],
              [
                -37.251122413051,
                -6.226070394051253
              ],
              [
                -37.23296956470411,
                -6.213910961756009
              ],
              [
                -37.22568464316395,
                -6.211924998869143
              ],
              [
                -37.203203854196104,
                -6.205798025178089
              ],
              [
                -37.19394240977491,
                -6.19797059931429
              ],
              [
                -37.192537958746804,
                -6.182695695394305
              ],
              [
                -37.178906054308314,
                -6.180525205029899
              ],
              [
                -37.17470060981354,
                -6.179855727694982
              ],
              [
                -37.17381408171997,
                -6.179714707256003
              ],
              [
                -37.173548607250886,
                -6.179411711489926
              ],
              [
                -37.16527868708302,
                -6.169985769992604
              ],
              [
                -37.15742374040762,
                -6.153894399617879
              ],
              [
                -37.1565257644747,
                -6.152054425643403
              ],
              [
                -37.15975574067455,
                -6.152554927692161
              ],
              [
                -37.16597666820786,
                -6.153519410931435
              ],
              [
                -37.166998682052096,
                -6.152143905472484
              ],
              [
                -37.1746911326622,
                -6.141794494308783
              ],
              [
                -37.16820470011485,
                -6.115621705794648
              ],
              [
                -37.16681269186877,
                -6.110004741480547
              ],
              [
                -37.168956172789905,
                -6.105442804993898
              ],
              [
                -37.17224616510752,
                -6.098441841565287
              ],
              [
                -37.1711141733648,
                -6.089897421649023
              ],
              [
                -37.170155683275205,
                -6.08266645842884
              ],
              [
                -37.17516817036545,
                -6.054551705640734
              ],
              [
                -37.17352368210822,
                -6.047629235582307
              ],
              [
                -37.18626354878658,
                -6.047639760321692
              ],
              [
                -37.193890998546905,
                -6.04764574395908
              ],
              [
                -37.19816995339652,
                -6.043458795817208
              ],
              [
                -37.20329341630934,
                -6.038445844748153
              ],
              [
                -37.21040034691525,
                -6.039686331264106
              ],
              [
                -37.22228275793381,
                -6.041760830097652
              ],
              [
                -37.226773701726835,
                -6.034635890388484
              ],
              [
                -37.230731185700016,
                -6.028357432635353
              ],
              [
                -37.24458906669354,
                -6.026592944130092
              ],
              [
                -37.25466249916023,
                -6.027900931929365
              ],
              [
                -37.26317590410197,
                -6.045966823871555
              ],
              [
                -37.26762436105249,
                -6.050639785556279
              ],
              [
                -37.272073316997165,
                -6.055313731240734
              ],
              [
                -37.286513695426926,
                -6.057600734015233
              ],
              [
                -37.291930125901516,
                -6.054080260258391
              ],
              [
                -37.29849208475033,
                -6.049816287098457
              ],
              [
                -37.30879547939399,
                -6.057372242572918
              ],
              [
                -37.335378734432496,
                -6.067072187155648
              ],
              [
                -37.344707166462904,
                -6.076585621595641
              ],
              [
                -37.354035069494614,
                -6.086099025066203
              ],
              [
                -37.37116493508161,
                -6.082393096031693
              ],
              [
                -37.37839138705287,
                -6.084748057881496
              ],
              [
                -37.3827123397736,
                -6.086798535340153
              ],
              [
                -37.393234715426516,
                -6.109000866253856
              ],
              [
                -37.400324676014456,
                -6.110696863264633
              ],
              [
                -37.40741512260263,
                -6.112392845279502
              ],
              [
                -37.41866548313664,
                -6.110046899851396
              ],
              [
                -37.42555494845742,
                -6.108609888512965
              ],
              [
                -37.43158490283389,
                -6.109588894538398
              ],
              [
                -37.439763827105786,
                -6.110915919157451
              ],
              [
                -37.4452952599232,
                -6.111813378520367
              ],
              [
                -37.45351417676779,
                -6.119685326453684
              ],
              [
                -37.45855512485446,
                -6.144615149679802
              ],
              [
                -37.48259194942536,
                -6.151292583072479
              ],
              [
                -37.48631039540411,
                -6.14707263572738
              ],
              [
                -37.488971889916606,
                -6.144051677251106
              ],
              [
                -37.5029987447121,
                -6.14400967940547
              ],
              [
                -37.50265523841723,
                -6.14928164557753
              ],
              [
                -37.50249424527705,
                -6.151754093962169
              ],
              [
                -37.51826560053624,
                -6.152080096326041
              ],
              [
                -37.52113508429627,
                -6.152139105668859
              ],
              [
                -37.530688501269246,
                -6.152337611124442
              ],
              [
                -37.53595446695607,
                -6.152447604576681
              ],
              [
                -37.53669745475148,
                -6.155116093994244
              ],
              [
                -37.539599420697066,
                -6.165541513593499
              ],
              [
                -37.560496742868395,
                -6.164506554962871
              ],
              [
                -37.565481684513486,
                -6.159106596691651
              ],
              [
                -37.56922616520031,
                -6.155051143034221
              ],
              [
                -37.572855120412804,
                -6.158117105151335
              ],
              [
                -37.584519527430324,
                -6.162241596007435
              ],
              [
                -37.5933164470218,
                -6.168898534104171
              ],
              [
                -37.59956839816552,
                -6.173629998778412
              ],
              [
                -37.60771932086311,
                -6.174861980237738
              ],
              [
                -37.61586974857105,
                -6.176094491695874
              ],
              [
                -37.63808306058017,
                -6.179448955371059
              ],
              [
                -37.64220102043136,
                -6.184225454373641
              ],
              [
                -37.646319467276726,
                -6.189002381380311
              ],
              [
                -37.67697071678799,
                -6.188538910531943
              ],
              [
                -37.694687574729166,
                -6.18656096037501
              ],
              [
                -37.69778653678525,
                -6.186214963070356
              ],
              [
                -37.70868643918064,
                -6.176881038446747
              ],
              [
                -37.74880957108422,
                -6.193441435756447
              ],
              [
                -37.75417805499873,
                -6.199805384155091
              ],
              [
                -37.753818526101654,
                -6.206144322098841
              ],
              [
                -37.75335405594618,
                -6.214318765554436
              ],
              [
                -37.759348973839934,
                -6.216807751834978
              ],
              [
                -37.76345195781085,
                -6.21851074108285
              ],
              [
                -37.774484346057726,
                -6.231188147567825
              ],
              [
                -37.766554410931484,
                -6.23365213211754
              ],
              [
                -37.7642804423954,
                -6.234359594098817
              ],
              [
                -37.76128646705042,
                -6.238363574721475
              ],
              [
                -37.769745869360314,
                -6.242398554470545
              ],
              [
                -37.77604883722266,
                -6.2454050199175
              ],
              [
                -37.770650867691685,
                -6.248217527354137
              ],
              [
                -37.76012446736752,
                -6.253701477504022
              ],
              [
                -37.77008635766364,
                -6.265248885259071
              ],
              [
                -37.77103134959122,
                -6.272676815556781
              ],
              [
                -37.75758044498101,
                -6.287276688243389
              ],
              [
                -37.75727095668028,
                -6.290384690646849
              ],
              [
                -37.782677740904234,
                -6.289185199535683
              ],
              [
                -37.78652319934342,
                -6.28900319345809
              ],
              [
                -37.79412966196264,
                -6.28864421020895
              ],
              [
                -37.810030511028046,
                -6.291270220928642
              ],
              [
                -37.823640363540264,
                -6.305644606051436
              ],
              [
                -37.82083091055611,
                -6.308073080181764
              ],
              [
                -37.81428545517984,
                -6.313731027556845
              ],
              [
                -37.8333402743748,
                -6.321389463950028
              ],
              [
                -37.84280418415256,
                -6.332699400505076
              ],
              [
                -37.84090019152303,
                -6.334680867734395
              ],
              [
                -37.83386927228345,
                -6.341996808811298
              ],
              [
                -37.84372567916519,
                -6.353967227500323
              ],
              [
                -37.84114067910062,
                -6.362641681863891
              ],
              [
                -37.853851075690564,
                -6.365927134492217
              ],
              [
                -37.859436522176104,
                -6.374489080135956
              ],
              [
                -37.887834291602836,
                -6.363980170090632
              ],
              [
                -37.88812128004654,
                -6.386820487548597
              ],
              [
                -37.883872310272146,
                -6.391288972781286
              ],
              [
                -37.885856770393374,
                -6.396610923458166
              ],
              [
                -37.88986823322357,
                -6.399745904588871
              ],
              [
                -37.89442970643814,
                -6.399561404050866
              ],
              [
                -37.90114215289575,
                -6.399289900617259
              ],
              [
                -37.91893948826565,
                -6.411303335475288
              ],
              [
                -37.92481692046984,
                -6.417923280629686
              ],
              [
                -37.92764190665067,
                -6.4211052716368
              ],
              [
                -37.94308229191255,
                -6.42218377647371
              ],
              [
                -37.94638526374575,
                -6.418099798581254
              ],
              [
                -37.95481668995015,
                -6.407673894650766
              ],
              [
                -37.955007203814596,
                -6.397820970777354
              ],
              [
                -37.96763307186068,
                -6.404736415109742
              ],
              [
                -37.96829053607275,
                -6.418354323156215
              ],
              [
                -37.97095903039174,
                -6.420393293004064
              ],
              [
                -37.978695463837056,
                -6.426305763975478
              ],
              [
                -37.983740410672844,
                -6.426412767500237
              ],
              [
                -37.990898373015405,
                -6.426564774081926
              ],
              [
                -38.01330518491173,
                -6.427039261311504
              ],
              [
                -38.01400515981912,
                -6.43000575462268
              ],
              [
                -38.00783620351909,
                -6.431803232477023
              ],
              [
                -38.00784020348115,
                -6.431807707444785
              ],
              [
                -38.0163756082091,
                -6.441845142803313
              ],
              [
                -38.019581589681096,
                -6.445615137527901
              ],
              [
                -38.01859909924462,
                -6.448636611294684
              ],
              [
                -38.012339152047126,
                -6.467886448916798
              ],
              [
                -38.021427052525134,
                -6.468066455610581
              ],
              [
                -38.02568601932893,
                -6.472519414739042
              ],
              [
                -38.02733952872271,
                -6.474248428365455
              ],
              [
                -38.040249882015175,
                -6.472656440453717
              ],
              [
                -38.036624441929334,
                -6.461856532241304
              ],
              [
                -38.035866951058104,
                -6.459600522328827
              ],
              [
                -38.043534877450924,
                -6.451586110976806
              ],
              [
                -38.045332388794826,
                -6.441056684312584
              ],
              [
                -38.05106481008969,
                -6.442017161654201
              ],
              [
                -38.052836806272076,
                -6.450003607549339
              ],
              [
                -38.053315813641056,
                -6.452166111005225
              ],
              [
                -38.05885523288191,
                -6.452741109232933
              ],
              [
                -38.061549237121866,
                -6.459060545763466
              ],
              [
                -38.070417154683234,
                -6.463541030784292
              ],
              [
                -38.082630034288684,
                -6.485498351875506
              ],
              [
                -38.089736472270644,
                -6.489916344246875
              ],
              [
                -38.09500942502893,
                -6.493194311270208
              ],
              [
                -38.09640239809635,
                -6.501744249618403
              ],
              [
                -38.09760038066606,
                -6.509099697147717
              ],
              [
                -38.11525271532608,
                -6.521297583140952
              ],
              [
                -38.11962816992812,
                -6.521609087684035
              ],
              [
                -38.121121192128136,
                -6.521270101335152
              ],
              [
                -38.12768760183582,
                -6.519779100393263
              ],
              [
                -38.135132050022634,
                -6.518087617606026
              ],
              [
                -38.13940203008169,
                -6.508766705038942
              ],
              [
                -38.147495961415615,
                -6.491098363034334
              ],
              [
                -38.15413991388004,
                -6.494912326838858
              ],
              [
                -38.16078434934237,
                -6.498726318651355
              ],
              [
                -38.17822868216725,
                -6.498772809096034
              ],
              [
                -38.187069114060485,
                -6.498795813902366
              ],
              [
                -38.19187858470268,
                -6.497940847809521
              ],
              [
                -38.196573527619435,
                -6.492247865271628
              ],
              [
                -38.20016551424736,
                -6.487892909578211
              ],
              [
                -38.20805695037065,
                -6.486532410497369
              ],
              [
                -38.227747269037266,
                -6.483137443216463
              ],
              [
                -38.23702420301569,
                -6.480436997488707
              ],
              [
                -38.23884516950562,
                -6.479906997841065
              ],
              [
                -38.25052657605376,
                -6.485327940594522
              ],
              [
                -38.256147029779896,
                -6.482821987879249
              ],
              [
                -38.28833425221504,
                -6.505225831609464
              ],
              [
                -38.290989237596364,
                -6.497118883388019
              ],
              [
                -38.29364521196425,
                -6.489010944178714
              ],
              [
                -38.30380259983367,
                -6.48853895973205
              ],
              [
                -38.3130005410373,
                -6.488111484289818
              ],
              [
                -38.31543651881778,
                -6.480748534133438
              ],
              [
                -38.31787350458431,
                -6.473386593972998
              ],
              [
                -38.32916790255836,
                -6.459637213426219
              ],
              [
                -38.338942826412286,
                -6.459736709256959
              ],
              [
                -38.34266831318232,
                -6.457969751499633
              ],
              [
                -38.350709241113556,
                -6.456626721363721
              ],
              [
                -38.35148420510648,
                -6.457016750857807
              ],
              [
                -38.36058965078009,
                -6.461598714427002
              ],
              [
                -38.363493611138054,
                -6.45327179505624
              ],
              [
                -38.36799658930218,
                -6.45424677952619
              ],
              [
                -38.37329003847592,
                -6.45539277620383
              ],
              [
                -38.37473555131287,
                -6.436990906090578
              ],
              [
                -38.38155297059259,
                -6.431669948012124
              ],
              [
                -38.38271249245885,
                -6.427282468871838
              ],
              [
                -38.38385598042256,
                -6.422955010255897
              ],
              [
                -38.387063434925736,
                -6.419382529163904
              ],
              [
                -38.40071082680986,
                -6.409594646365452
              ],
              [
                -38.413183705963796,
                -6.411666131654068
              ],
              [
                -38.425659107110775,
                -6.413737614952916
              ],
              [
                -38.44131247621109,
                -6.406043680212076
              ],
              [
                -38.447576431835735,
                -6.402965230320506
              ],
              [
                -38.451025392862014,
                -6.392526787681952
              ],
              [
                -38.46542726237117,
                -6.396022772201579
              ],
              [
                -38.469160733644976,
                -6.396450786385071
              ],
              [
                -38.48584959337421,
                -6.398363250748518
              ],
              [
                -38.48381563167985,
                -6.386975869763113
              ],
              [
                -38.48269912256499,
                -6.380724907537601
              ],
              [
                -38.48343763229644,
                -6.379011398334169
              ],
              [
                -38.48954609370915,
                -6.364840513437708
              ],
              [
                -38.47565923133514,
                -6.34534016761399
              ],
              [
                -38.4676543005035,
                -6.338382742337064
              ],
              [
                -38.46194432740887,
                -6.333418751112465
              ],
              [
                -38.45723036908492,
                -6.329321803820062
              ],
              [
                -38.462302859275525,
                -6.330433300550869
              ],
              [
                -38.466392315151964,
                -6.331329270305094
              ],
              [
                -38.467156809399064,
                -6.331496782511378
              ],
              [
                -38.47521222724806,
                -6.333261253155047
              ],
              [
                -38.48589564800828,
                -6.339942225360073
              ],
              [
                -38.493864048483694,
                -6.344925162964921
              ],
              [
                -38.49408304258601,
                -6.34642069049646
              ],
              [
                -38.50503997596303,
                -6.356123605464413
              ],
              [
                -38.51512038425579,
                -6.347385187032728
              ],
              [
                -38.51722486019837,
                -6.345561210596798
              ],
              [
                -38.533636228058896,
                -6.342048732805865
              ],
              [
                -38.544632603173156,
                -6.344201223432139
              ],
              [
                -38.54934558329452,
                -6.35015817334989
              ],
              [
                -38.549468579122916,
                -6.350314178221474
              ],
              [
                -38.54468314661788,
                -6.353372138296197
              ],
              [
                -38.56187546996649,
                -6.355528154057751
              ],
              [
                -38.56400744828686,
                -6.355795141411944
              ],
              [
                -38.563914958663574,
                -6.35194065725212
              ],
              [
                -38.573248874058365,
                -6.348363210240901
              ],
              [
                -38.57669386104921,
                -6.347042221789326
              ],
              [
                -38.581161810203525,
                -6.330659829864676
              ],
              [
                -38.58103830820955,
                -6.323241914339913
              ],
              [
                -38.58091533020509,
                -6.315823953822867
              ],
              [
                -38.57396487761957,
                -6.309624486322043
              ],
              [
                -38.565643494738886,
                -6.302202054421534
              ],
              [
                -38.56903844664706,
                -6.300141058673566
              ],
              [
                -38.56991494139451,
                -6.299609066384849
              ],
              [
                -38.57046794018965,
                -6.292668139641992
              ],
              [
                -38.57115344593985,
                -6.284047189038189
              ],
              [
                -38.578216868459656,
                -6.279808741621125
              ],
              [
                -38.56255153845905,
                -6.267539310576383
              ],
              [
                -38.55404160028026,
                -6.263010862150736
              ],
              [
                -38.54546919847262,
                -6.245041483162549
              ],
              [
                -38.54385569226422,
                -6.245362487607725
              ],
              [
                -38.53808374360267,
                -6.246510969889417
              ],
              [
                -38.527909840104805,
                -6.23704902177866
              ],
              [
                -38.51933089998464,
                -6.222651120138008
              ],
              [
                -38.52802733901147,
                -6.214207719500221
              ],
              [
                -38.52890531994761,
                -6.213354713709022
              ],
              [
                -38.52682237628297,
                -6.192992878768545
              ],
              [
                -38.51541645798001,
                -6.191919882695749
              ],
              [
                -38.50094357850339,
                -6.190558898875058
              ],
              [
                -38.500378089716975,
                -6.179298946168929
              ],
              [
                -38.47275185737307,
                -6.15179066254758
              ],
              [
                -38.4716843698526,
                -6.148362698570871
              ],
              [
                -38.47602482894497,
                -6.144039710059516
              ],
              [
                -38.476034858864644,
                -6.14402920414789
              ],
              [
                -38.46934589758681,
                -6.129044318633053
              ],
              [
                -38.45686152122519,
                -6.108425504387692
              ],
              [
                -38.45322405050264,
                -6.105791014604704
              ],
              [
                -38.446709609101084,
                -6.101072539240088
              ],
              [
                -38.44211213816282,
                -6.097742052281788
              ],
              [
                -38.443593654813874,
                -6.093793583036136
              ],
              [
                -38.446933609477774,
                -6.084893144618058
              ],
              [
                -38.43105723854228,
                -6.075223728959323
              ],
              [
                -38.412803430106905,
                -6.057935366337481
              ],
              [
                -38.4044959969445,
                -6.056906859096596
              ],
              [
                -38.40071405912497,
                -6.053249865263393
              ],
              [
                -38.39631405960086,
                -6.054452860074417
              ],
              [
                -38.39456460246955,
                -6.054982366827968
              ],
              [
                -38.394496589047385,
                -6.055003346620908
              ],
              [
                -38.38274717066099,
                -6.052640890627884
              ],
              [
                -38.38401016804697,
                -6.058385335545125
              ],
              [
                -38.38515165083326,
                -6.063581268675835
              ],
              [
                -38.385308139020445,
                -6.064295290197167
              ],
              [
                -38.38287917568102,
                -6.065008267084298
              ],
              [
                -38.37798123734418,
                -6.066446269738347
              ],
              [
                -38.378294712705454,
                -6.067887267679687
              ],
              [
                -38.379391685968834,
                -6.072932228965487
              ],
              [
                -38.37206225257735,
                -6.082546149201519
              ],
              [
                -38.36486983759559,
                -6.088069614473405
              ],
              [
                -38.35575441236545,
                -6.084118124535456
              ],
              [
                -38.35072545848012,
                -6.081938137366415
              ],
              [
                -38.34698046307764,
                -6.080314629669085
              ],
              [
                -38.34679499541093,
                -6.076029179039508
              ],
              [
                -38.346399997638514,
                -6.066893759184635
              ],
              [
                -38.33888554375242,
                -6.076727661552073
              ],
              [
                -38.33637757347985,
                -6.080012142287709
              ],
              [
                -38.319533738119475,
                -6.076655151818761
              ],
              [
                -38.31197577872062,
                -6.081618118216244
              ],
              [
                -38.304417835325594,
                -6.086582089602087
              ],
              [
                -38.29040748099529,
                -6.072767164724506
              ],
              [
                -38.276624576968736,
                -6.069567194011968
              ],
              [
                -38.27451559995189,
                -6.065412697176734
              ],
              [
                -38.27412460964281,
                -6.064957229492421
              ],
              [
                -38.26459321560754,
                -6.053867296237402
              ],
              [
                -38.2626202218826,
                -6.053706795246262
              ],
              [
                -38.258799256339664,
                -6.053395294268998
              ],
              [
                -38.25978226634621,
                -6.049329839709895
              ],
              [
                -38.252183824656,
                -6.042718884663183
              ],
              [
                -38.25147485200541,
                -6.016964561880786
              ],
              [
                -38.25128185906837,
                -6.009967637562029
              ],
              [
                -38.2476998746581,
                -6.004521177979675
              ],
              [
                -38.244117901245076,
                -5.999075191401473
              ],
              [
                -38.20563175821697,
                -5.963964454619292
              ],
              [
                -38.19880681814214,
                -5.961571989141857
              ],
              [
                -38.19198189707117,
                -5.959178987673457
              ],
              [
                -38.189499921325485,
                -5.956540021836027
              ],
              [
                -38.18319798536738,
                -5.949841576485349
              ],
              [
                -38.17965952133862,
                -5.951221540461966
              ],
              [
                -38.17504705940917,
                -5.953019516491371
              ],
              [
                -38.16546661792694,
                -5.94647359892916
              ],
              [
                -38.16537910172625,
                -5.946414079342168
              ],
              [
                -38.16433212129072,
                -5.945698594309958
              ],
              [
                -38.16310562841495,
                -5.938511620970287
              ],
              [
                -38.163532640990475,
                -5.929922685668125
              ],
              [
                -38.16354264102043,
                -5.929732721165808
              ],
              [
                -38.16330367128676,
                -5.929433691366142
              ],
              [
                -38.15899367016622,
                -5.92403276511565
              ],
              [
                -38.14633432254475,
                -5.915794309016922
              ],
              [
                -38.142364869635486,
                -5.901944900380385
              ],
              [
                -38.142167843773215,
                -5.901258400653477
              ],
              [
                -38.12455352887596,
                -5.887228999107372
              ],
              [
                -38.11589860544384,
                -5.84760330243659
              ],
              [
                -38.11612208029063,
                -5.846293333877916
              ],
              [
                -38.118479583128064,
                -5.832451939209807
              ],
              [
                -38.11047066416914,
                -5.818831532543149
              ],
              [
                -38.10430671412076,
                -5.811834589891763
              ],
              [
                -38.075850481893106,
                -5.780754327522007
              ],
              [
                -38.05772164690737,
                -5.755785003453425
              ],
              [
                -38.057533151270675,
                -5.75290003808658
              ],
              [
                -38.05397319078936,
                -5.745391097169016
              ],
              [
                -38.05393167520349,
                -5.745303579834389
              ],
              [
                -38.05136023585631,
                -5.73987914407866
              ],
              [
                -38.046690272437,
                -5.730027702000312
              ],
              [
                -38.057115679906275,
                -5.729363716443231
              ],
              [
                -38.0613821463691,
                -5.724863762475168
              ],
              [
                -38.07222754873778,
                -5.723280783411788
              ],
              [
                -38.07285555231662,
                -5.723188753511159
              ],
              [
                -38.086780900782955,
                -5.730140734982529
              ],
              [
                -38.08856640466323,
                -5.727705737244359
              ],
              [
                -38.08930689006043,
                -5.714463345102484
              ],
              [
                -38.082455451511734,
                -5.698020955722456
              ],
              [
                -38.08233498579131,
                -5.67216815663462
              ],
              [
                -38.079270527001725,
                -5.667919689357908
              ],
              [
                -38.07620654720605,
                -5.66367224307841
              ],
              [
                -38.05299875006688,
                -5.645721871171856
              ],
              [
                -38.04344134902708,
                -5.641246390919821
              ],
              [
                -38.03388394599342,
                -5.636770915680485
              ],
              [
                -38.01891808184898,
                -5.605272663902836
              ],
              [
                -38.013567603043214,
                -5.594012262834331
              ],
              [
                -38.009717640921785,
                -5.591776264283576
              ],
              [
                -37.997939774627405,
                -5.58493631655883
              ],
              [
                -37.99757677913415,
                -5.570470931799854
              ],
              [
                -37.99757377322978,
                -5.57035040575186
              ],
              [
                -37.989650360947515,
                -5.555416030374555
              ],
              [
                -37.95603415805419,
                -5.538414650664355
              ],
              [
                -37.951208174117326,
                -5.535023663757393
              ],
              [
                -37.94638272817614,
                -5.531633189851722
              ],
              [
                -37.943765773075306,
                -5.526280734762516
              ],
              [
                -37.93224836602521,
                -5.502727399865611
              ],
              [
                -37.9246754135037,
                -5.503854898610014
              ],
              [
                -37.917102488988036,
                -5.504982387353737
              ],
              [
                -37.90439610734424,
                -5.500761405588386
              ],
              [
                -37.90159813783677,
                -5.497133442770976
              ],
              [
                -37.89972716563372,
                -5.491030005121413
              ],
              [
                -37.89928365732551,
                -5.489583989340427
              ],
              [
                -37.8980301695834,
                -5.485499529031864
              ],
              [
                -37.89722816696279,
                -5.484836543836502
              ],
              [
                -37.89183673057077,
                -5.480379080144682
              ],
              [
                -37.88776279891923,
                -5.45417029885093
              ],
              [
                -37.886394303814505,
                -5.445368354958831
              ],
              [
                -37.88356981716656,
                -5.440586358338015
              ],
              [
                -37.88074585451211,
                -5.435804407722833
              ],
              [
                -37.86009204740521,
                -5.406306133486844
              ],
              [
                -37.85892905165435,
                -5.395355221874115
              ],
              [
                -37.86063904436204,
                -5.386982289384186
              ],
              [
                -37.862884047840545,
                -5.375987880039206
              ],
              [
                -37.847083704918745,
                -5.339645648338725
              ],
              [
                -37.841203735567156,
                -5.337326682546557
              ],
              [
                -37.83532429221389,
                -5.335007186762827
              ],
              [
                -37.811761487232,
                -5.316567303594342
              ],
              [
                -37.78593324379404,
                -5.296788478994579
              ],
              [
                -37.78271878617619,
                -5.29443748597162
              ],
              [
                -37.782220261929,
                -5.286350560146225
              ],
              [
                -37.78672873326249,
                -5.277596597373564
              ],
              [
                -37.789352234088255,
                -5.272501652411401
              ],
              [
                -37.79080971909161,
                -5.261884738834326
              ],
              [
                -37.78507678603703,
                -5.247379365377013
              ],
              [
                -37.78312877972179,
                -5.242450407629126
              ],
              [
                -37.78305580430204,
                -5.231401465712585
              ],
              [
                -37.78303781392874,
                -5.228665493526395
              ],
              [
                -37.78349480460678,
                -5.227412005771725
              ],
              [
                -37.78633575595,
                -5.219623584432472
              ],
              [
                -37.78964173690395,
                -5.210563663490451
              ],
              [
                -37.77592038756741,
                -5.186741823235727
              ],
              [
                -37.764566011006046,
                -5.13923869954616
              ],
              [
                -37.73303428318773,
                -5.100265006432683
              ],
              [
                -37.72925334667402,
                -5.080297132167886
              ],
              [
                -37.723671864119645,
                -5.069471244868108
              ],
              [
                -37.711581996892036,
                -5.061711271662409
              ],
              [
                -37.689726681961375,
                -5.056162336698628
              ],
              [
                -37.677261301670036,
                -5.052996821561867
              ],
              [
                -37.6762833067265,
                -5.05009886726323
              ],
              [
                -37.67530532278209,
                -5.04720090096638
              ],
              [
                -37.63994516805295,
                -4.92602884248889
              ],
              [
                -37.638911172223544,
                -4.92578982488398
              ],
              [
                -37.466232210063794,
                -4.885936593360293
              ],
              [
                -37.4450078676546,
                -4.881039617182775
              ],
              [
                -37.357384176675026,
                -4.860822718492187
              ],
              [
                -37.3573736357688,
                -4.86082023850698
              ],
              [
                -37.3564396610761,
                -4.860604739781483
              ],
              [
                -37.256510026330034,
                -4.837607350000613
              ],
              [
                -37.25157408044322,
                -4.83204342151852
              ],
              [
                -37.24969159355652,
                -4.834979884868926
              ],
              [
                -37.24953361982525,
                -4.835225367807964
              ],
              [
                -37.23925370999448,
                -4.851983735361124
              ],
              [
                -37.2376417228784,
                -4.854611205340974
              ],
              [
                -37.2275757973214,
                -4.871022096806941
              ],
              [
                -37.221376347858886,
                -4.881128505108435
              ],
              [
                -37.21775785455338,
                -4.885442970467421
              ],
              [
                -37.215253392128524,
                -4.888382939479784
              ],
              [
                -37.214327887736346,
                -4.889460413318084
              ],
              [
                -37.2099559096746,
                -4.894550374037983
              ],
              [
                -37.19238655260176,
                -4.907940284150449
              ],
              [
                -37.186587622430935,
                -4.91235922856999
              ],
              [
                -37.186335637552844,
                -4.912551232893612
              ],
              [
                -37.1810411791356,
                -4.916586720659414
              ],
              [
                -37.18041018335598,
                -4.917255190945658
              ],
              [
                -37.172897218511515,
                -4.925217133890317
              ],
              [
                -37.166727280557026,
                -4.931755071003636
              ],
              [
                -37.15824284088784,
                -4.934454561952485
              ],
              [
                -37.1517214228388,
                -4.935362041355721
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 21,
        "nome": "Rondônia",
        "sigla": "RO",
        "regiao_id": 3,
        "codigo_ibg": 11
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -66.45888575152378,
                -9.87523990540018
              ],
              [
                -66.46301120293123,
                -9.874282444431998
              ],
              [
                -66.46311223047688,
                -9.874258447679566
              ],
              [
                -66.46616070598157,
                -9.871572951078532
              ],
              [
                -66.47026869003199,
                -9.868446940738544
              ],
              [
                -66.48050111979063,
                -9.867994487820006
              ],
              [
                -66.48580562940043,
                -9.870121970856818
              ],
              [
                -66.48919509811034,
                -9.874233963809468
              ],
              [
                -66.48975207713578,
                -9.876828973552161
              ],
              [
                -66.49008955590246,
                -9.878405467534948
              ],
              [
                -66.49061005740924,
                -9.882033438577404
              ],
              [
                -66.49603953957202,
                -9.882234969626817
              ],
              [
                -66.49968351387936,
                -9.882177464709175
              ],
              [
                -66.50044200622415,
                -9.87849198092703
              ],
              [
                -66.50037954311875,
                -9.878132469093927
              ],
              [
                -66.49987551933658,
                -9.875231480510555
              ],
              [
                -66.50355101238539,
                -9.875239463408949
              ],
              [
                -66.51097947411714,
                -9.876471502045211
              ],
              [
                -66.51396044811574,
                -9.879798498129272
              ],
              [
                -66.51581642366223,
                -9.881869962825519
              ],
              [
                -66.51900143689795,
                -9.883836476038441
              ],
              [
                -66.5283578536859,
                -9.884799501750654
              ],
              [
                -66.53221385321278,
                -9.883990495823571
              ],
              [
                -66.53387932481431,
                -9.88294199220287
              ],
              [
                -66.53759182555387,
                -9.88060403642403
              ],
              [
                -66.5432702977299,
                -9.8770370601405
              ],
              [
                -66.54471227912065,
                -9.87982901164267
              ],
              [
                -66.54563876879575,
                -9.882759009829226
              ],
              [
                -66.54737376861054,
                -9.886248519355263
              ],
              [
                -66.54837726940485,
                -9.888266985876557
              ],
              [
                -66.55025073243358,
                -9.886953505521907
              ],
              [
                -66.55865471617012,
                -9.881059026819475
              ],
              [
                -66.56387818360842,
                -9.882959037572427
              ],
              [
                -66.56536517561179,
                -9.885854038798147
              ],
              [
                -66.56178468178139,
                -9.888452523881822
              ],
              [
                -66.56324917787633,
                -9.890139502318753
              ],
              [
                -66.56671817041386,
                -9.894135022885223
              ],
              [
                -66.57030014369846,
                -9.891867516640955
              ],
              [
                -66.57378660935088,
                -9.889660030029683
              ],
              [
                -66.57594611056157,
                -9.89286953624663
              ],
              [
                -66.57714609978886,
                -9.89465200892674
              ],
              [
                -66.5779150862397,
                -9.901220975105668
              ],
              [
                -66.58097207401421,
                -9.904468003654094
              ],
              [
                -66.58698755837575,
                -9.899825523680398
              ],
              [
                -66.59921546758702,
                -9.897571546367963
              ],
              [
                -66.60324348342057,
                -9.896704059925675
              ],
              [
                -66.60810693549084,
                -9.895656064466646
              ],
              [
                -66.61761040114945,
                -9.893912569948162
              ],
              [
                -66.61930138743952,
                -9.893602095770973
              ],
              [
                -66.68926159191987,
                -9.862459322798605
              ],
              [
                -66.80596557128627,
                -9.814127147409357
              ],
              [
                -66.7802117414512,
                -9.790991223767698
              ],
              [
                -66.77688525452743,
                -9.768253813858072
              ],
              [
                -66.78177428227866,
                -9.758170318909723
              ],
              [
                -66.77499631293463,
                -9.752687363251605
              ],
              [
                -66.7620573704516,
                -9.75825581311524
              ],
              [
                -66.7589418792403,
                -9.758426804540868
              ],
              [
                -66.75004293448993,
                -9.758915784616315
              ],
              [
                -66.72501706087328,
                -9.755670788020346
              ],
              [
                -66.70093021714995,
                -9.742957254185184
              ],
              [
                -66.69142827052497,
                -9.733489781288393
              ],
              [
                -66.68830577482294,
                -9.72473181608672
              ],
              [
                -66.68456830887648,
                -9.71424786899384
              ],
              [
                -66.63906608244643,
                -9.688924874958385
              ],
              [
                -66.6237831983451,
                -9.680419379764523
              ],
              [
                -66.61642425688513,
                -9.672459912225175
              ],
              [
                -66.60906429042639,
                -9.664500439711555
              ],
              [
                -66.59215336247472,
                -9.670828376618513
              ],
              [
                -66.58592239403255,
                -9.67315988252355
              ],
              [
                -66.58126990557993,
                -9.670096897003688
              ],
              [
                -66.57661744312803,
                -9.667034370488315
              ],
              [
                -66.57261845901776,
                -9.665517896538262
              ],
              [
                -66.55097410853298,
                -9.657308879443212
              ],
              [
                -66.5437371351119,
                -9.650231877117497
              ],
              [
                -66.5364991816964,
                -9.643153431818721
              ],
              [
                -66.51563030557824,
                -9.637408899348419
              ],
              [
                -66.50022038549584,
                -9.63316687317209
              ],
              [
                -66.49845489484764,
                -9.629572889173815
              ],
              [
                -66.49668940119753,
                -9.625978905179108
              ],
              [
                -66.49452345628856,
                -9.601185512001647
              ],
              [
                -66.46707662602569,
                -9.581815556034066
              ],
              [
                -66.43854483248285,
                -9.554001085782135
              ],
              [
                -66.42317844279901,
                -9.525392206204774
              ],
              [
                -66.39201315309299,
                -9.500084730536454
              ],
              [
                -66.38964870603866,
                -9.455234920956418
              ],
              [
                -66.39267823238357,
                -9.4461794381799
              ],
              [
                -66.39570722571906,
                -9.437124001403392
              ],
              [
                -66.4005646933067,
                -9.433060497492958
              ],
              [
                -66.41432115700073,
                -9.42155407202346
              ],
              [
                -66.41364866315811,
                -9.413299092665572
              ],
              [
                -66.40845770604525,
                -9.406552129956367
              ],
              [
                -66.38828827178882,
                -9.418043544626135
              ],
              [
                -66.37437284252594,
                -9.42597150769336
              ],
              [
                -66.3664023684258,
                -9.42476700277304
              ],
              [
                -66.36290839898155,
                -9.422556485363513
              ],
              [
                -66.3529004608677,
                -9.416223509806153
              ],
              [
                -66.34145353363087,
                -9.42412844771502
              ],
              [
                -66.337415531072,
                -9.42691693245139
              ],
              [
                -66.33129357478862,
                -9.42453843461793
              ],
              [
                -66.31850764222153,
                -9.41957143589412
              ],
              [
                -66.31356416961033,
                -9.425254400781379
              ],
              [
                -66.31319667075421,
                -9.425676906691141
              ],
              [
                -66.31444913853373,
                -9.433419893667748
              ],
              [
                -66.30708767091724,
                -9.436450381402757
              ],
              [
                -66.2912532478875,
                -9.435001338273194
              ],
              [
                -66.28377780562772,
                -9.431770836448912
              ],
              [
                -66.28748878561207,
                -9.420091906367025
              ],
              [
                -66.29002829459101,
                -9.412098937162767
              ],
              [
                -66.2673929309917,
                -9.409369399308659
              ],
              [
                -66.26332395002352,
                -9.40998539283742
              ],
              [
                -66.25925646605072,
                -9.410601417367895
              ],
              [
                -66.25735145205837,
                -9.412756864641057
              ],
              [
                -66.2430005293126,
                -9.42899431081017
              ],
              [
                -66.23489255586375,
                -9.430482290961072
              ],
              [
                -66.22621458665992,
                -9.427202779509535
              ],
              [
                -66.22014464509766,
                -9.424908777501454
              ],
              [
                -66.2043292078919,
                -9.42558024077906
              ],
              [
                -66.19843226243312,
                -9.427180754916193
              ],
              [
                -66.17321436699659,
                -9.434025673328984
              ],
              [
                -66.15857596137523,
                -9.42618171034798
              ],
              [
                -66.15800544235593,
                -9.425431707198591
              ],
              [
                -66.15393949273387,
                -9.420080191417236
              ],
              [
                -66.1234676522536,
                -9.408386199271042
              ],
              [
                -66.11580168478206,
                -9.408590208785975
              ],
              [
                -66.11091823536681,
                -9.410218197372812
              ],
              [
                -66.10073977536763,
                -9.413611172410613
              ],
              [
                -66.05931498559775,
                -9.41782609155577
              ],
              [
                -66.04841756282447,
                -9.410545617463153
              ],
              [
                -66.04603554608329,
                -9.408954121633522
              ],
              [
                -66.04198308396172,
                -9.401980106930063
              ],
              [
                -66.0340961447001,
                -9.400241107228688
              ],
              [
                -66.00214678233547,
                -9.403867050098064
              ],
              [
                -65.98601135956416,
                -9.412736994915518
              ],
              [
                -65.97996892551366,
                -9.412747995627585
              ],
              [
                -65.9698664476001,
                -9.412766466099717
              ],
              [
                -65.96661894292822,
                -9.4197134566541
              ],
              [
                -65.95273001641215,
                -9.428370906626728
              ],
              [
                -65.95202302551883,
                -9.431846365795513
              ],
              [
                -65.95120404032632,
                -9.435870352781526
              ],
              [
                -65.93961805352055,
                -9.442515325633527
              ],
              [
                -65.93144561982042,
                -9.447202288893696
              ],
              [
                -65.92560560630002,
                -9.46166371786509
              ],
              [
                -65.92203611524432,
                -9.470504198946141
              ],
              [
                -65.91219863706695,
                -9.4833576266522
              ],
              [
                -65.89758570145406,
                -9.48865708268891
              ],
              [
                -65.88047032909581,
                -9.487072537127734
              ],
              [
                -65.85991587796032,
                -9.510376445825836
              ],
              [
                -65.8569489207786,
                -9.510351413335675
              ],
              [
                -65.8521114239426,
                -9.510310429015549
              ],
              [
                -65.84616893260386,
                -9.516019374858283
              ],
              [
                -65.84663692415526,
                -9.520556852909143
              ],
              [
                -65.84780989945321,
                -9.531932321937925
              ],
              [
                -65.84036093076719,
                -9.53372181483557
              ],
              [
                -65.83665146074826,
                -9.532773294578877
              ],
              [
                -65.82463705347661,
                -9.529701816277248
              ],
              [
                -65.81910204438735,
                -9.554951193900417
              ],
              [
                -65.81758602605969,
                -9.561866197141821
              ],
              [
                -65.8124350463795,
                -9.563376148598895
              ],
              [
                -65.81025005827199,
                -9.564017156851708
              ],
              [
                -65.80494407913052,
                -9.570120626183702
              ],
              [
                -65.80309159547188,
                -9.575132591888138
              ],
              [
                -65.7995795750091,
                -9.584632039524326
              ],
              [
                -65.79108015246422,
                -9.585252528023622
              ],
              [
                -65.7775862096763,
                -9.57713153490408
              ],
              [
                -65.77393124152348,
                -9.571051567594923
              ],
              [
                -65.77027079640585,
                -9.564963605318416
              ],
              [
                -65.76107780866552,
                -9.567299577450475
              ],
              [
                -65.75610533152864,
                -9.568563580077376
              ],
              [
                -65.75445737216675,
                -9.564501065483189
              ],
              [
                -65.74988439476697,
                -9.553231610916367
              ],
              [
                -65.74233344255575,
                -9.552575614633513
              ],
              [
                -65.73671847167284,
                -9.557003574604526
              ],
              [
                -65.72952050374592,
                -9.56268055250919
              ],
              [
                -65.72310103718928,
                -9.558663542372528
              ],
              [
                -65.70176320666413,
                -9.518747666794265
              ],
              [
                -65.69563376630603,
                -9.507281188751076
              ],
              [
                -65.68809228375615,
                -9.503650230565247
              ],
              [
                -65.68055083121247,
                -9.50001922039082
              ],
              [
                -65.68006738026578,
                -9.48488577270356
              ],
              [
                -65.67898493245491,
                -9.451002400654868
              ],
              [
                -65.67232797064553,
                -9.445958932169603
              ],
              [
                -65.66340549375776,
                -9.447634896284654
              ],
              [
                -65.65673551924776,
                -9.448887385418917
              ],
              [
                -65.64943356097318,
                -9.446883371679213
              ],
              [
                -65.64295411529822,
                -9.436040921051394
              ],
              [
                -65.64118266178116,
                -9.433076438362246
              ],
              [
                -65.63432119804291,
                -9.429307911886893
              ],
              [
                -65.62701122227351,
                -9.427660411845624
              ],
              [
                -65.61781528563746,
                -9.425587422512429
              ],
              [
                -65.60723285899844,
                -9.419624421557902
              ],
              [
                -65.59632091831102,
                -9.413476431816402
              ],
              [
                -65.59198745215005,
                -9.413430431379082
              ],
              [
                -65.58765294299663,
                -9.41338492193866
              ],
              [
                -65.5551791108119,
                -9.422065860017753
              ],
              [
                -65.52195325514134,
                -9.446515201971463
              ],
              [
                -65.5142787737496,
                -9.449120189502487
              ],
              [
                -65.5060893358124,
                -9.447309671742723
              ],
              [
                -65.50303433284974,
                -9.456559143501622
              ],
              [
                -65.5029243366463,
                -9.46091012807519
              ],
              [
                -65.50270480226708,
                -9.46958658832457
              ],
              [
                -65.49234086076588,
                -9.473273537662529
              ],
              [
                -65.47650645015801,
                -9.472274032124469
              ],
              [
                -65.4723379937183,
                -9.466139546679965
              ],
              [
                -65.46742003376859,
                -9.458901586230603
              ],
              [
                -65.46013807219056,
                -9.466188025812242
              ],
              [
                -65.45619906694156,
                -9.470129999024556
              ],
              [
                -65.44221164058936,
                -9.464309996392895
              ],
              [
                -65.43425370623008,
                -9.465947008075759
              ],
              [
                -65.43163670740321,
                -9.458246526927574
              ],
              [
                -65.44286869029895,
                -9.419759198387238
              ],
              [
                -65.4503501813096,
                -9.412139216365146
              ],
              [
                -65.45419913993969,
                -9.408218752929193
              ],
              [
                -65.45613666358166,
                -9.4008192836312
              ],
              [
                -65.44822573902977,
                -9.377755879172188
              ],
              [
                -65.4456692573935,
                -9.370302400288292
              ],
              [
                -65.44644925958417,
                -9.357850455252928
              ],
              [
                -65.44722879975089,
                -9.345398507232343
              ],
              [
                -65.445468310886,
                -9.338708524723446
              ],
              [
                -65.44316735588536,
                -9.329964043135643
              ],
              [
                -65.44492533985253,
                -9.328108561768714
              ],
              [
                -65.44863880511002,
                -9.324188607117042
              ],
              [
                -65.44655433916051,
                -9.315989601819059
              ],
              [
                -65.4397543705513,
                -9.311357635004827
              ],
              [
                -65.40934603274407,
                -9.32206454685121
              ],
              [
                -65.40527152177563,
                -9.326352513549237
              ],
              [
                -65.39305608884266,
                -9.339207966668246
              ],
              [
                -65.38474712924771,
                -9.337641956023628
              ],
              [
                -65.37908215282535,
                -9.332458933016836
              ],
              [
                -65.37704770998944,
                -9.324037988714183
              ],
              [
                -65.3507738099337,
                -9.32272296807479
              ],
              [
                -65.34649784699893,
                -9.315898986414378
              ],
              [
                -65.34222190705901,
                -9.30907451477108
              ],
              [
                -65.31771455397057,
                -9.296090521042334
              ],
              [
                -65.29996563423312,
                -9.286687035011449
              ],
              [
                -65.29183970420453,
                -9.278284035678757
              ],
              [
                -65.28371275717706,
                -9.269881565373684
              ],
              [
                -65.24618897471959,
                -9.257276077101318
              ],
              [
                -65.2113006516354,
                -9.278606432762224
              ],
              [
                -65.20755313460441,
                -9.28524691788087
              ],
              [
                -65.20668163787039,
                -9.293251378122752
              ],
              [
                -65.20819113580083,
                -9.296140850397475
              ],
              [
                -65.21054261799614,
                -9.300642360808965
              ],
              [
                -65.20848311635424,
                -9.309255302968616
              ],
              [
                -65.20168014125437,
                -9.320865232603346
              ],
              [
                -65.18785116956798,
                -9.344465649064535
              ],
              [
                -65.19236612558831,
                -9.35623410116194
              ],
              [
                -65.19651709289496,
                -9.367052568349523
              ],
              [
                -65.19568508413039,
                -9.374875540392978
              ],
              [
                -65.17710965382363,
                -9.383243952501488
              ],
              [
                -65.17307020059503,
                -9.38506393447191
              ],
              [
                -65.17362467837485,
                -9.389228445495176
              ],
              [
                -65.1741791841517,
                -9.393392899521503
              ],
              [
                -65.17520365318609,
                -9.395181411283797
              ],
              [
                -65.186349597498,
                -9.41463785837023
              ],
              [
                -65.18414905224813,
                -9.42660378964915
              ],
              [
                -65.17684912295782,
                -9.426250296884962
              ],
              [
                -65.17011463832436,
                -9.429443274528433
              ],
              [
                -65.15465523276278,
                -9.436771732193359
              ],
              [
                -65.1485297235236,
                -9.441597707549704
              ],
              [
                -65.14240428128645,
                -9.44642416389818
              ],
              [
                -65.11592043702528,
                -9.433681175979727
              ],
              [
                -65.11324045568801,
                -9.432391690805094
              ],
              [
                -65.1104169441671,
                -9.432344672688917
              ],
              [
                -65.09737302606938,
                -9.432130166664788
              ],
              [
                -65.0884330781755,
                -9.424695154370564
              ],
              [
                -65.09059209959055,
                -9.41467271980982
              ],
              [
                -65.09181406508314,
                -9.4089997442677
              ],
              [
                -65.08463415020522,
                -9.404798248381937
              ],
              [
                -65.07745215534396,
                -9.400595739509404
              ],
              [
                -65.0707317131811,
                -9.400915228106207
              ],
              [
                -65.05306680515915,
                -9.401754715133567
              ],
              [
                -65.0471543605199,
                -9.395807238873957
              ],
              [
                -65.04664637274533,
                -9.389535279048188
              ],
              [
                -65.04643185491199,
                -9.386882773851088
              ],
              [
                -65.0447158756033,
                -9.384980283519926
              ],
              [
                -65.03520193542073,
                -9.374431295436475
              ],
              [
                -65.03701694480255,
                -9.366814324288606
              ],
              [
                -65.03883142317804,
                -9.359197373142337
              ],
              [
                -65.02601851918762,
                -9.354303372536592
              ],
              [
                -65.02393104308453,
                -9.353505863440983
              ],
              [
                -65.0232010224974,
                -9.351098399551868
              ],
              [
                -65.02153904036811,
                -9.34561888001682
              ],
              [
                -65.01789356387057,
                -9.34506090066263
              ],
              [
                -65.00536512390794,
                -9.343144878135995
              ],
              [
                -65.01026413401242,
                -9.332538419132876
              ],
              [
                -65.01222514244829,
                -9.328292471345806
              ],
              [
                -65.00291970614262,
                -9.319051977776274
              ],
              [
                -65.00137368039199,
                -9.317516997339581
              ],
              [
                -65.00121170565629,
                -9.315916496232589
              ],
              [
                -65.00003421245137,
                -9.304230028281294
              ],
              [
                -64.98570179808524,
                -9.291547052482446
              ],
              [
                -64.97650986265747,
                -9.291880058445013
              ],
              [
                -64.96239495620007,
                -9.292391047355872
              ],
              [
                -64.95345499401114,
                -9.278540558235056
              ],
              [
                -64.95337952146714,
                -9.27651359091839
              ],
              [
                -64.95317053105407,
                -9.27088709124963
              ],
              [
                -64.94553055812041,
                -9.265663608945848
              ],
              [
                -64.9401976109769,
                -9.262017136027264
              ],
              [
                -64.93559265454813,
                -9.255426663541066
              ],
              [
                -64.93431366314246,
                -9.24944167169065
              ],
              [
                -64.93233968920214,
                -9.240202182354823
              ],
              [
                -64.92434872525371,
                -9.231532226895409
              ],
              [
                -64.92100376387691,
                -9.227903746918953
              ],
              [
                -64.92010128553261,
                -9.223899751024334
              ],
              [
                -64.91919828118826,
                -9.219895755131954
              ],
              [
                -64.92260078377959,
                -9.212546801538574
              ],
              [
                -64.92605677026567,
                -9.20508081847475
              ],
              [
                -64.92490627868165,
                -9.202073350377153
              ],
              [
                -64.9232527706463,
                -9.19775285160556
              ],
              [
                -64.92729778770781,
                -9.191211402848381
              ],
              [
                -64.93142126355409,
                -9.18454243269642
              ],
              [
                -64.92938476962328,
                -9.17945045134698
              ],
              [
                -64.928506281418,
                -9.177252967535214
              ],
              [
                -64.93313979438975,
                -9.154017055139075
              ],
              [
                -64.93407080810657,
                -9.153018571369767
              ],
              [
                -64.93882877232237,
                -9.147919588084328
              ],
              [
                -64.93984181189923,
                -9.131972172210773
              ],
              [
                -64.93944379553263,
                -9.124183709738025
              ],
              [
                -64.932981347948,
                -9.119957704588282
              ],
              [
                -64.91519301698234,
                -9.074230344606836
              ],
              [
                -64.91886401167687,
                -9.050179967225324
              ],
              [
                -64.91678103730449,
                -9.042755997992247
              ],
              [
                -64.90644756687385,
                -9.045086458899753
              ],
              [
                -64.90105562874567,
                -9.046302440326384
              ],
              [
                -64.89361267833974,
                -9.043317451078407
              ],
              [
                -64.88674621291435,
                -9.028827998383457
              ],
              [
                -64.87007883449806,
                -9.011809059366907
              ],
              [
                -64.83883702352823,
                -8.99372756752458
              ],
              [
                -64.81598765294787,
                -8.986264599583599
              ],
              [
                -64.79896874624812,
                -8.985081543601222
              ],
              [
                -64.78681129046386,
                -8.988069035357025
              ],
              [
                -64.77747132664659,
                -8.994162518039065
              ],
              [
                -64.77355686291659,
                -9.000236956635652
              ],
              [
                -64.76068991094458,
                -9.02020036797836
              ],
              [
                -64.75307692993883,
                -9.020366843286276
              ],
              [
                -64.73179207386349,
                -9.012751352217448
              ],
              [
                -64.72393514092262,
                -9.0055668989611
              ],
              [
                -64.70888873370231,
                -8.991808428095512
              ],
              [
                -64.70296074166794,
                -8.98950643055384
              ],
              [
                -64.69310179915814,
                -8.989789913199576
              ],
              [
                -64.68285685503706,
                -8.993234889884043
              ],
              [
                -64.68035086150132,
                -8.997667369872238
              ],
              [
                -64.67088289090435,
                -9.01441527447141
              ],
              [
                -64.6572119672161,
                -9.007830270522442
              ],
              [
                -64.6552100058509,
                -9.007636269387325
              ],
              [
                -64.64104205713058,
                -9.00626428227871
              ],
              [
                -64.6105797284241,
                -9.016070703656245
              ],
              [
                -64.58746235133249,
                -9.009125694531834
              ],
              [
                -64.57890593574635,
                -9.000870695670281
              ],
              [
                -64.5787679211852,
                -8.99763870920224
              ],
              [
                -64.57835742134142,
                -8.988032742457067
              ],
              [
                -64.57796992615923,
                -8.988163257383729
              ],
              [
                -64.56838348613867,
                -8.991391220829275
              ],
              [
                -64.55993604974392,
                -8.983301256542925
              ],
              [
                -64.54962559011094,
                -8.977887765041084
              ],
              [
                -64.53312718363046,
                -8.975885730180206
              ],
              [
                -64.51556777787094,
                -8.977200224699624
              ],
              [
                -64.49587290702475,
                -8.97867417451048
              ],
              [
                -64.48327696535324,
                -8.976018195882402
              ],
              [
                -64.47068103170534,
                -8.973362167268258
              ],
              [
                -64.46427956155686,
                -8.97503765842217
              ],
              [
                -64.454959592486,
                -8.977476626348796
              ],
              [
                -64.44607317816471,
                -8.976487130480526
              ],
              [
                -64.43854421924165,
                -8.971751142422496
              ],
              [
                -64.43273875677264,
                -8.968100152553081
              ],
              [
                -64.4079588872033,
                -8.960270653023969
              ],
              [
                -64.37942056314637,
                -8.946351178400663
              ],
              [
                -64.32289439401684,
                -8.928479658663383
              ],
              [
                -64.31506444392868,
                -8.929164660736959
              ],
              [
                -64.30906297113296,
                -8.93411062542217
              ],
              [
                -64.31253242771575,
                -8.950193049242898
              ],
              [
                -64.3142364152677,
                -8.954017545436935
              ],
              [
                -64.32556781113175,
                -8.979451466371067
              ],
              [
                -64.32478132025547,
                -8.987686928451415
              ],
              [
                -64.31081338199913,
                -8.99608884289008
              ],
              [
                -64.29717543768885,
                -8.99581336934749
              ],
              [
                -64.26456264965852,
                -8.988000357567364
              ],
              [
                -64.23269930578347,
                -8.991926761059506
              ],
              [
                -64.17450618398847,
                -8.960189327521428
              ],
              [
                -64.14894231255774,
                -8.958555315459751
              ],
              [
                -64.1429238569933,
                -8.953150831533181
              ],
              [
                -64.14125987700372,
                -8.944669872200311
              ],
              [
                -64.14727386621223,
                -8.91571948706208
              ],
              [
                -64.15119339579387,
                -8.896852575459123
              ],
              [
                -64.14973741878802,
                -8.884780126923097
              ],
              [
                -64.1482824197517,
                -8.872708159409532
              ],
              [
                -64.15399341778183,
                -8.854549754558633
              ],
              [
                -64.15769341918484,
                -8.842785290137176
              ],
              [
                -64.15619493658585,
                -8.838910839614327
              ],
              [
                -64.15469542299212,
                -8.835034839099901
              ],
              [
                -64.14790250814373,
                -8.82978585864609
              ],
              [
                -64.13524906652809,
                -8.82000888419212
              ],
              [
                -64.13209860691828,
                -8.812395909446183
              ],
              [
                -64.12894713530419,
                -8.804783444711378
              ],
              [
                -64.14425359170443,
                -8.776066567073553
              ],
              [
                -64.14255815588466,
                -8.742651723595186
              ],
              [
                -64.13035973536589,
                -8.721781267912052
              ],
              [
                -64.1240832469965,
                -8.716335317190056
              ],
              [
                -64.11425281686249,
                -8.722567751275918
              ],
              [
                -64.11074884114365,
                -8.724789745400026
              ],
              [
                -64.09425592332798,
                -8.727984710469018
              ],
              [
                -64.0746405489553,
                -8.712727754944757
              ],
              [
                -64.07076404802231,
                -8.711536753377416
              ],
              [
                -64.06688660209565,
                -8.710346261808553
              ],
              [
                -64.06535160590295,
                -8.710537763896042
              ],
              [
                -64.02735681326207,
                -8.715286189753646
              ],
              [
                -64.03116427773787,
                -8.704231722941696
              ],
              [
                -64.03497128819907,
                -8.693177283130469
              ],
              [
                -64.02140487445637,
                -8.674758357176444
              ],
              [
                -64.01811589802928,
                -8.670293343780836
              ],
              [
                -64.0159134068146,
                -8.667302860896925
              ],
              [
                -64.01765343706079,
                -8.663428377067106
              ],
              [
                -64.01912241189032,
                -8.660157417406646
              ],
              [
                -64.01619095355433,
                -8.652119922061418
              ],
              [
                -64.0075444803575,
                -8.644058470941129
              ],
              [
                -64.00466053830297,
                -8.641369981910865
              ],
              [
                -64.00460100558189,
                -8.63852346542337
              ],
              [
                -64.00432856109859,
                -8.625531017982022
              ],
              [
                -63.99375211193176,
                -8.618996044060808
              ],
              [
                -63.99057963538101,
                -8.617035572690508
              ],
              [
                -63.989905640025604,
                -8.612649050654134
              ],
              [
                -63.98569518319099,
                -8.585254700665569
              ],
              [
                -63.97974973338211,
                -8.580309704712763
              ],
              [
                -63.97202676367693,
                -8.582073183930529
              ],
              [
                -63.96663530160464,
                -8.587505643348983
              ],
              [
                -63.96544728133299,
                -8.593675136516037
              ],
              [
                -63.96508680564044,
                -8.595548599368653
              ],
              [
                -63.95357836467921,
                -8.606873054309851
              ],
              [
                -63.949509372609405,
                -8.607989519159355
              ],
              [
                -63.94544039854269,
                -8.609105547009626
              ],
              [
                -63.92405553047696,
                -8.575146649585182
              ],
              [
                -63.9226550582674,
                -8.558916203131906
              ],
              [
                -63.93088153501353,
                -8.55732173789465
              ],
              [
                -63.932680533992475,
                -8.556973754775811
              ],
              [
                -63.94510847581588,
                -8.550120290796498
              ],
              [
                -63.944190979099616,
                -8.538173328458605
              ],
              [
                -63.94327452535384,
                -8.526226365142664
              ],
              [
                -63.96004441365757,
                -8.510332969042931
              ],
              [
                -63.9656608853224,
                -8.505010002470907
              ],
              [
                -63.97399087422431,
                -8.486861100104978
              ],
              [
                -63.972662905237456,
                -8.478070109323525
              ],
              [
                -63.9583584824869,
                -8.466489156454134
              ],
              [
                -63.94032310654558,
                -8.451887194025115
              ],
              [
                -63.938576149336875,
                -8.441171751674938
              ],
              [
                -63.953212567543794,
                -8.427587313169637
              ],
              [
                -63.96391652278731,
                -8.424117864811608
              ],
              [
                -63.97737993871223,
                -8.419754407823246
              ],
              [
                -63.97690996123439,
                -8.414556908523231
              ],
              [
                -63.976439475755285,
                -8.40935892822847
              ],
              [
                -63.944859679937686,
                -8.387448978920903
              ],
              [
                -63.943788245724356,
                -8.347249145925218
              ],
              [
                -63.94360827173227,
                -8.330810742392043
              ],
              [
                -63.93153334297029,
                -8.324453224543467
              ],
              [
                -63.9153603895786,
                -8.327270195439697
              ],
              [
                -63.91032142901379,
                -8.32814770912424
              ],
              [
                -63.90707446949248,
                -8.324915218147268
              ],
              [
                -63.90382747197031,
                -8.321683238172776
              ],
              [
                -63.90244050524522,
                -8.318732742509695
              ],
              [
                -63.894410554494264,
                -8.30165327239142
              ],
              [
                -63.8814876491942,
                -8.290852821923576
              ],
              [
                -63.87024471184113,
                -8.288442825023864
              ],
              [
                -63.84660733058391,
                -8.2946562480566
              ],
              [
                -63.83571338382255,
                -8.300215244629399
              ],
              [
                -63.83569786589406,
                -8.300223216575848
              ],
              [
                -63.824819428076154,
                -8.305774698186376
              ],
              [
                -63.79071356958301,
                -8.332838517844124
              ],
              [
                -63.78107765898598,
                -8.328864536522772
              ],
              [
                -63.77298972125362,
                -8.320355077082102
              ],
              [
                -63.76055080380112,
                -8.292944167392433
              ],
              [
                -63.75954532108666,
                -8.290727682270258
              ],
              [
                -63.75279736680839,
                -8.28026519683289
              ],
              [
                -63.752794349831696,
                -8.2802597218517
              ],
              [
                -63.74604342656241,
                -8.26979274446507
              ],
              [
                -63.722242079325454,
                -8.249121307174791
              ],
              [
                -63.7425939826713,
                -8.216285462621077
              ],
              [
                -63.74804550333664,
                -8.207490035388801
              ],
              [
                -63.74651548167743,
                -8.198206581065085
              ],
              [
                -63.73157709771115,
                -8.182416617401113
              ],
              [
                -63.730553095907304,
                -8.181075640660005
              ],
              [
                -63.72952860210667,
                -8.179734144921055
              ],
              [
                -63.69290136616071,
                -8.157973180140345
              ],
              [
                -63.67590596530263,
                -8.12748778163222
              ],
              [
                -63.672642479640785,
                -8.124427307223765
              ],
              [
                -63.6693785279813,
                -8.121366808819245
              ],
              [
                -63.66192956734442,
                -8.100793887399789
              ],
              [
                -63.66637008394437,
                -8.094465924649375
              ],
              [
                -63.666530577387455,
                -8.094237413814023
              ],
              [
                -63.66843156579376,
                -8.091528431618785
              ],
              [
                -63.66648010252445,
                -8.0812259703004
              ],
              [
                -63.65035217791866,
                -8.066065042180199
              ],
              [
                -63.638237297222645,
                -8.046818611476015
              ],
              [
                -63.62609736957879,
                -8.040809591191904
              ],
              [
                -63.621259403231896,
                -8.029235174683114
              ],
              [
                -63.625868407811225,
                -8.015917229597404
              ],
              [
                -63.62592989771248,
                -8.015739231424762
              ],
              [
                -63.6202674757854,
                -7.968920926363654
              ],
              [
                -63.552474342979835,
                -7.972880810690214
              ],
              [
                -63.50000962514407,
                -7.975945754213744
              ],
              [
                -62.86608718755482,
                -7.975495526116155
              ],
              [
                -62.857629236145264,
                -7.982954471146043
              ],
              [
                -62.852829761910435,
                -7.987186943757645
              ],
              [
                -62.850209764591,
                -7.986862434859808
              ],
              [
                -62.84452431245223,
                -7.986157948743922
              ],
              [
                -62.84107881650553,
                -7.994598913815834
              ],
              [
                -62.832302339093644,
                -7.993993394364542
              ],
              [
                -62.82047238797615,
                -8.014121772166723
              ],
              [
                -62.814969408071875,
                -8.019544245699665
              ],
              [
                -62.809466447168255,
                -8.024966715227126
              ],
              [
                -62.78566808771533,
                -8.025968680744677
              ],
              [
                -62.743439799138116,
                -8.044910064001789
              ],
              [
                -62.712298912201575,
                -8.080207851107618
              ],
              [
                -62.69175003580871,
                -8.092533783366294
              ],
              [
                -62.69002204699583,
                -8.100526717432158
              ],
              [
                -62.67830707056472,
                -8.111069693658802
              ],
              [
                -62.68447204686227,
                -8.11569115046352
              ],
              [
                -62.68496906166173,
                -8.116063649481179
              ],
              [
                -62.68416603019446,
                -8.117510152171032
              ],
              [
                -62.673580079567465,
                -8.136575045811021
              ],
              [
                -62.67249859268645,
                -8.144937527109798
              ],
              [
                -62.67719505265071,
                -8.156777460744575
              ],
              [
                -62.678633007083725,
                -8.160401454866582
              ],
              [
                -62.68472147034283,
                -8.16534395125528
              ],
              [
                -62.68613295316878,
                -8.173399406134873
              ],
              [
                -62.672995537482755,
                -8.192358788979119
              ],
              [
                -62.6661760483525,
                -8.195807761217864
              ],
              [
                -62.659356110231364,
                -8.19925624945283
              ],
              [
                -62.650494601608635,
                -8.221653650853996
              ],
              [
                -62.652861102179884,
                -8.225835122789123
              ],
              [
                -62.654470590643534,
                -8.22868010454153
              ],
              [
                -62.649413598946104,
                -8.236650561433098
              ],
              [
                -62.60983330923102,
                -8.259422403271943
              ],
              [
                -62.59952584249104,
                -8.271864835879978
              ],
              [
                -62.59223936891827,
                -8.274591828558396
              ],
              [
                -62.58792791296252,
                -8.272787824771779
              ],
              [
                -62.584854416529275,
                -8.271502326349257
              ],
              [
                -62.58205194852777,
                -8.276373780554534
              ],
              [
                -62.58097095699886,
                -8.278252311992443
              ],
              [
                -62.577311945767875,
                -8.279265776825536
              ],
              [
                -62.573660490500686,
                -8.280276769678292
              ],
              [
                -62.56869200509356,
                -8.286903246433138
              ],
              [
                -62.561386046544186,
                -8.283631240114566
              ],
              [
                -62.55540754247466,
                -8.306381639529334
              ],
              [
                -62.5591585081585,
                -8.311462607379648
              ],
              [
                -62.560629024449405,
                -8.313454126660684
              ],
              [
                -62.556166539426904,
                -8.317664070552418
              ],
              [
                -62.55473901649747,
                -8.31901109783831
              ],
              [
                -62.554928537532454,
                -8.321983580187409
              ],
              [
                -62.55525250404798,
                -8.327060033585264
              ],
              [
                -62.550420043536086,
                -8.33771398402893
              ],
              [
                -62.548582024282,
                -8.341766476037417
              ],
              [
                -62.54881455195285,
                -8.343315940621343
              ],
              [
                -62.55099199376612,
                -8.357819878583715
              ],
              [
                -62.54181805030705,
                -8.357753373809048
              ],
              [
                -62.53902409470243,
                -8.357732874224363
              ],
              [
                -62.53217361084613,
                -8.369449337393164
              ],
              [
                -62.53146959712058,
                -8.370653802242291
              ],
              [
                -62.53083511405525,
                -8.371072818589488
              ],
              [
                -62.52493562934176,
                -8.3749707789126
              ],
              [
                -62.52519111101979,
                -8.377918259464689
              ],
              [
                -62.525603131427246,
                -8.38267674737065
              ],
              [
                -62.51793916636662,
                -8.384362720952671
              ],
              [
                -62.500338787926346,
                -8.365797815344168
              ],
              [
                -62.50029529722749,
                -8.365751311488893
              ],
              [
                -62.49343035257176,
                -8.362331327314065
              ],
              [
                -62.4933548280388,
                -8.362292819382052
              ],
              [
                -62.4864143818543,
                -8.358834818282684
              ],
              [
                -62.48197040425912,
                -8.351344338001232
              ],
              [
                -62.4783829230288,
                -8.345296851585816
              ],
              [
                -62.47243749929846,
                -8.34037639318777
              ],
              [
                -62.464942012951134,
                -8.339362392772305
              ],
              [
                -62.45323606301302,
                -8.348796828364197
              ],
              [
                -62.4466390906175,
                -8.3592032784152
              ],
              [
                -62.444798115924144,
                -8.362107226359088
              ],
              [
                -62.43171217446115,
                -8.371653183607993
              ],
              [
                -62.40771028476176,
                -8.377070123441525
              ],
              [
                -62.400855347834586,
                -8.376152639417992
              ],
              [
                -62.39443640542852,
                -8.375293635715888
              ],
              [
                -62.388713420732984,
                -8.376282132878613
              ],
              [
                -62.378771959020554,
                -8.380536572250765
              ],
              [
                -62.36655101703935,
                -8.389221541290953
              ],
              [
                -62.36027056786996,
                -8.398170496990891
              ],
              [
                -62.3594360402412,
                -8.406620942045237
              ],
              [
                -62.360687532076426,
                -8.423313374944025
              ],
              [
                -62.36471848181494,
                -8.434528822419173
              ],
              [
                -62.36874896653595,
                -8.445744775920826
              ],
              [
                -62.36318742325787,
                -8.477849108813263
              ],
              [
                -62.36551891174919,
                -8.493185042217076
              ],
              [
                -62.359548928529435,
                -8.505986975587371
              ],
              [
                -62.35344245611403,
                -8.511324943221647
              ],
              [
                -62.34217752193284,
                -8.514671407615106
              ],
              [
                -62.33682555540846,
                -8.51626190654343
              ],
              [
                -62.33475755735103,
                -8.521823391586674
              ],
              [
                -62.332689543290314,
                -8.527384356632393
              ],
              [
                -62.33309402499404,
                -8.534087311023486
              ],
              [
                -62.33380201120856,
                -8.545816283978361
              ],
              [
                -62.3306165199877,
                -8.552356223276515
              ],
              [
                -62.325270547712904,
                -8.563329669401524
              ],
              [
                -62.327189508104176,
                -8.572158646615495
              ],
              [
                -62.33579794516134,
                -8.585608595788019
              ],
              [
                -62.34146591673128,
                -8.594464558986992
              ],
              [
                -62.34055138991308,
                -8.60245054509757
              ],
              [
                -62.33524243022673,
                -8.609107487041896
              ],
              [
                -62.28896816715451,
                -8.638717785891656
              ],
              [
                -62.28077072030173,
                -8.638211807982072
              ],
              [
                -62.278800736107684,
                -8.62879133024469
              ],
              [
                -62.282477214679254,
                -8.62296487653822
              ],
              [
                -62.29377515046733,
                -8.605060947612154
              ],
              [
                -62.2927491860211,
                -8.595614507288847
              ],
              [
                -62.28882123544253,
                -8.588524516822474
              ],
              [
                -62.27374481215446,
                -8.582554519490898
              ],
              [
                -62.26835334979924,
                -8.575382055456355
              ],
              [
                -62.24227450285103,
                -8.580282986032355
              ],
              [
                -62.230090576948896,
                -8.588781458602297
              ],
              [
                -62.22807107824873,
                -8.590189938744777
              ],
              [
                -62.20260472777118,
                -8.594567403361953
              ],
              [
                -62.19523026373965,
                -8.592374371996994
              ],
              [
                -62.18785580471469,
                -8.590181883636061
              ],
              [
                -62.17415635263439,
                -8.627823697921173
              ],
              [
                -62.176109806860865,
                -8.636543663533116
              ],
              [
                -62.18584626977663,
                -8.636496667913022
              ],
              [
                -62.195271710435655,
                -8.63645019287097
              ],
              [
                -62.19999717196132,
                -8.643915157745841
              ],
              [
                -62.18090674050931,
                -8.657501057679763
              ],
              [
                -62.17902175459508,
                -8.659981583314156
              ],
              [
                -62.16455230698552,
                -8.679015470726393
              ],
              [
                -62.168809307580986,
                -8.688998908026132
              ],
              [
                -62.171237287238064,
                -8.694694883526875
              ],
              [
                -62.167271275080125,
                -8.702850340612398
              ],
              [
                -62.1616302953577,
                -8.705837844912832
              ],
              [
                -62.153657864915786,
                -8.710060322650778
              ],
              [
                -62.14826737207553,
                -8.717930762016953
              ],
              [
                -62.15287481768354,
                -8.725713730421344
              ],
              [
                -62.15649679006903,
                -8.731832708738255
              ],
              [
                -62.15449332895245,
                -8.739262181665044
              ],
              [
                -62.124437380281826,
                -8.801290849552371
              ],
              [
                -62.097467561539816,
                -8.808539773844114
              ],
              [
                -62.08561763484692,
                -8.803365302039072
              ],
              [
                -62.073767210171376,
                -8.798191782253578
              ],
              [
                -62.04998833911937,
                -8.7958912675536
              ],
              [
                -62.040256381084156,
                -8.797610256664324
              ],
              [
                -62.03052493606108,
                -8.799329233772394
              ],
              [
                -62.02079798214744,
                -8.823218623141438
              ],
              [
                -62.001733556043455,
                -8.832316567130052
              ],
              [
                -61.997819563644896,
                -8.8404005244453
              ],
              [
                -61.993906108235315,
                -8.848484976757423
              ],
              [
                -61.99333059391473,
                -8.851730940798392
              ],
              [
                -61.989687071517146,
                -8.872280350112579
              ],
              [
                -61.98468662315482,
                -8.878227812237345
              ],
              [
                -61.980403121425894,
                -8.874450323770578
              ],
              [
                -61.978604131719926,
                -8.872863350198644
              ],
              [
                -61.97072169064124,
                -8.874432825418209
              ],
              [
                -61.937569865060745,
                -8.881033228611207
              ],
              [
                -61.924123468604805,
                -8.87876373787408
              ],
              [
                -61.9053490863699,
                -8.874129236107345
              ],
              [
                -61.904077096677725,
                -8.869008282745892
              ],
              [
                -61.90246360982309,
                -8.862510769940373
              ],
              [
                -61.88343571707855,
                -8.862150265184885
              ],
              [
                -61.87876322242559,
                -8.862061244109237
              ],
              [
                -61.86913181701029,
                -8.857397760215267
              ],
              [
                -61.859499865608434,
                -8.852733770340636
              ],
              [
                -61.86286588513071,
                -8.833083374145907
              ],
              [
                -61.8632083819967,
                -8.831082381393692
              ],
              [
                -61.862046384658555,
                -8.823086407864785
              ],
              [
                -61.859655886123555,
                -8.820900424162794
              ],
              [
                -61.856325451062354,
                -8.817854925940876
              ],
              [
                -61.85757441914645,
                -8.813471446896676
              ],
              [
                -61.860489927004586,
                -8.803234019838497
              ],
              [
                -61.855151973217055,
                -8.788648550550008
              ],
              [
                -61.849314517279765,
                -8.784116067453624
              ],
              [
                -61.84276354811796,
                -8.779030079823364
              ],
              [
                -61.84316956810164,
                -8.76293666312695
              ],
              [
                -61.8435725888413,
                -8.74700174175835
              ],
              [
                -61.83596367777916,
                -8.732345286871867
              ],
              [
                -61.82757071506574,
                -8.732246811866613
              ],
              [
                -61.80571079604513,
                -8.747401700312611
              ],
              [
                -61.78948639489402,
                -8.744782661713682
              ],
              [
                -61.774069501400305,
                -8.747850650154696
              ],
              [
                -61.76157707396845,
                -8.736774180981184
              ],
              [
                -61.76283107753935,
                -8.729776214571963
              ],
              [
                -61.763075101034936,
                -8.728417215902061
              ],
              [
                -61.7607960869323,
                -8.727639738231494
              ],
              [
                -61.74834315888224,
                -8.723391214044849
              ],
              [
                -61.74385070792082,
                -8.717028246042547
              ],
              [
                -61.73959473124276,
                -8.709484801592383
              ],
              [
                -61.74027425099659,
                -8.70833229860858
              ],
              [
                -61.74390522999527,
                -8.702171814764036
              ],
              [
                -61.74131623465106,
                -8.694817372761497
              ],
              [
                -61.725435365290664,
                -8.693221324292828
              ],
              [
                -61.71273743870786,
                -8.68747433252054
              ],
              [
                -61.70860943592742,
                -8.697738291499165
              ],
              [
                -61.70482493736732,
                -8.707148748720728
              ],
              [
                -61.6969865017435,
                -8.709204240985391
              ],
              [
                -61.673044143054305,
                -8.70463623672845
              ],
              [
                -61.649877758105404,
                -8.713179156438356
              ],
              [
                -61.64806226525551,
                -8.716169652717463
              ],
              [
                -61.64554378678516,
                -8.720318594906129
              ],
              [
                -61.63782284652858,
                -8.720946608634819
              ],
              [
                -61.630018872741665,
                -8.72158108222152
              ],
              [
                -61.625931883371464,
                -8.728473549101308
              ],
              [
                -61.62842435951874,
                -8.74794496916878
              ],
              [
                -61.62901734879155,
                -8.752576455451525
              ],
              [
                -61.62588985281303,
                -8.755226449444782
              ],
              [
                -61.62276137784076,
                -8.757876416435312
              ],
              [
                -61.60549141412354,
                -8.79433370830119
              ],
              [
                -61.589570503467684,
                -8.794116678467484
              ],
              [
                -61.583311533796845,
                -8.797788665621596
              ],
              [
                -61.582423046102214,
                -8.798310180093035
              ],
              [
                -61.5797465710641,
                -8.802054138830181
              ],
              [
                -61.57191259462624,
                -8.8011671570318
              ],
              [
                -61.56781461746297,
                -8.80070366647464
              ],
              [
                -61.55824819457754,
                -8.807235110399693
              ],
              [
                -61.54121026294914,
                -8.818868509428517
              ],
              [
                -61.532600324359535,
                -8.819856503231897
              ],
              [
                -61.53225183756649,
                -8.819688986497235
              ],
              [
                -61.525538374085805,
                -8.816461020617522
              ],
              [
                -61.51318338921251,
                -8.83657242637491
              ],
              [
                -61.51206289901323,
                -8.849916826606337
              ],
              [
                -61.51094288578345,
                -8.863260763857447
              ],
              [
                -61.4915954669649,
                -8.884447667167226
              ],
              [
                -61.48392351071087,
                -8.90575004406146
              ],
              [
                -61.4760170246076,
                -8.911448029838654
              ],
              [
                -61.46811055151094,
                -8.917145459609348
              ],
              [
                -61.4881314440193,
                -8.937696418279304
              ],
              [
                -61.48962492239137,
                -8.939227909569265
              ],
              [
                -61.4911174077697,
                -8.940758907861095
              ],
              [
                -61.510502237402775,
                -8.975813759697182
              ],
              [
                -61.511829746107374,
                -8.982019740106432
              ],
              [
                -61.513156716808126,
                -8.988226203519968
              ],
              [
                -61.5274256043415,
                -9.01896607869457
              ],
              [
                -61.53012303081683,
                -9.041644969394302
              ],
              [
                -61.53532000726284,
                -9.047326962641291
              ],
              [
                -61.54051744870358,
                -9.053009430900302
              ],
              [
                -61.54635642621167,
                -9.068009377018477
              ],
              [
                -61.550379855796336,
                -9.078937846556444
              ],
              [
                -61.55038038578837,
                -9.078941328541871
              ],
              [
                -61.55440234636573,
                -9.089867803114762
              ],
              [
                -61.557995288757745,
                -9.114458197995226
              ],
              [
                -61.55660278279725,
                -9.12201513378289
              ],
              [
                -61.55521028182795,
                -9.129572096574137
              ],
              [
                -61.540558843508045,
                -9.152756470803835
              ],
              [
                -61.534947848521995,
                -9.161635429776771
              ],
              [
                -61.53176185307734,
                -9.16961241916744
              ],
              [
                -61.531342358941856,
                -9.170661391958022
              ],
              [
                -61.531083356048335,
                -9.170912412485256
              ],
              [
                -61.52309138321796,
                -9.178640848109312
              ],
              [
                -61.520336874794864,
                -9.194574788138187
              ],
              [
                -61.5275232853783,
                -9.230854632092866
              ],
              [
                -61.52587577507235,
                -9.24367406346806
              ],
              [
                -61.53215875038913,
                -9.2485050543064
              ],
              [
                -61.53856322615656,
                -9.2436330598834
              ],
              [
                -61.54277170733759,
                -9.242912080094467
              ],
              [
                -61.5752780191794,
                -9.237342137225596
              ],
              [
                -61.58842894408479,
                -9.243242149290586
              ],
              [
                -61.59521588010559,
                -9.25115860140272
              ],
              [
                -61.59906435776237,
                -9.255648108298976
              ],
              [
                -61.60208183551202,
                -9.255760584152693
              ],
              [
                -61.62778268364191,
                -9.256719138984128
              ],
              [
                -61.632005626837795,
                -9.266242577457232
              ],
              [
                -61.63216414130049,
                -9.276254047954088
              ],
              [
                -61.62642263800122,
                -9.282940021459664
              ],
              [
                -61.62343416767878,
                -9.281733989410096
              ],
              [
                -61.61487372418727,
                -9.278278520143926
              ],
              [
                -61.61194125323519,
                -9.280022493287952
              ],
              [
                -61.61487368077072,
                -9.30585687167309
              ],
              [
                -61.610786186982445,
                -9.31534182233596
              ],
              [
                -61.61698615021736,
                -9.326676286854605
              ],
              [
                -61.617169130743825,
                -9.326973784822039
              ],
              [
                -61.62319658520449,
                -9.336769261841187
              ],
              [
                -61.624847063424234,
                -9.339103253060438
              ],
              [
                -61.62537704381606,
                -9.362560654566801
              ],
              [
                -61.611677633632205,
                -9.355612161907105
              ],
              [
                -61.60858263726319,
                -9.361636607127704
              ],
              [
                -61.607406141100796,
                -9.362195108978266
              ],
              [
                -61.59662819558789,
                -9.367311573958924
              ],
              [
                -61.57607781810905,
                -9.369969039413192
              ],
              [
                -61.56716088615979,
                -9.37639452036539
              ],
              [
                -61.55225644473149,
                -9.38717543855117
              ],
              [
                -61.56037539285175,
                -9.397864892748656
              ],
              [
                -61.550461900317124,
                -9.416579307586456
              ],
              [
                -61.555339872471926,
                -9.421409287660245
              ],
              [
                -61.556179878145464,
                -9.435236702592034
              ],
              [
                -61.559469840796154,
                -9.436371203473355
              ],
              [
                -61.56242632751066,
                -9.437390707367953
              ],
              [
                -61.58095067804278,
                -9.458479650722866
              ],
              [
                -61.57346422151589,
                -9.462479603292776
              ],
              [
                -61.578094164187924,
                -9.471234575998324
              ],
              [
                -61.56851874265973,
                -9.476468046110453
              ],
              [
                -61.56259924778439,
                -9.48479001215752
              ],
              [
                -61.55576177201536,
                -9.48783398382026
              ],
              [
                -61.52553944139572,
                -9.501289870607744
              ],
              [
                -61.53725086103368,
                -9.506541357037033
              ],
              [
                -61.5468442811307,
                -9.510843363490963
              ],
              [
                -61.54900726885868,
                -9.514839338300273
              ],
              [
                -61.526567372137215,
                -9.532879222567136
              ],
              [
                -61.51844592948666,
                -9.530068247773151
              ],
              [
                -61.51290597083606,
                -9.528151241916833
              ],
              [
                -61.513490448440535,
                -9.538177179122846
              ],
              [
                -61.51353095217457,
                -9.53886570418506
              ],
              [
                -61.513217949857875,
                -9.538928683446844
              ],
              [
                -61.507482016710895,
                -9.54008118692497
              ],
              [
                -61.508581473959644,
                -9.551707123937309
              ],
              [
                -61.50981143636228,
                -9.56471308215886
              ],
              [
                -61.50412446337777,
                -9.567562042307358
              ],
              [
                -61.50261348934641,
                -9.572608530099574
              ],
              [
                -61.50103099484615,
                -9.572935524322707
              ],
              [
                -61.49590200239625,
                -9.573995994078038
              ],
              [
                -61.494639497965935,
                -9.578369999162941
              ],
              [
                -61.49688398037912,
                -9.588840928943506
              ],
              [
                -61.50248290099222,
                -9.614959333722998
              ],
              [
                -61.49518845514491,
                -9.624389258834423
              ],
              [
                -61.487279001540784,
                -9.62608474463687
              ],
              [
                -61.48693398239027,
                -9.626158776799102
              ],
              [
                -61.48124801286318,
                -9.623433248148244
              ],
              [
                -61.47705654441957,
                -9.626912762749852
              ],
              [
                -61.480392522079825,
                -9.639656672333624
              ],
              [
                -61.493516423001054,
                -9.658999101971213
              ],
              [
                -61.499930378572856,
                -9.660286619006685
              ],
              [
                -61.50634334715619,
                -9.661574125044202
              ],
              [
                -61.50354832954053,
                -9.672391554901875
              ],
              [
                -61.50172084112776,
                -9.679465019463148
              ],
              [
                -61.504734289360144,
                -9.691002973949264
              ],
              [
                -61.519118185959236,
                -9.696655474109567
              ],
              [
                -61.53187710088632,
                -9.71139494149904
              ],
              [
                -61.545533523763844,
                -9.715018939428074
              ],
              [
                -61.54702652998587,
                -9.713258931299043
              ],
              [
                -61.55219050799977,
                -9.707171964435148
              ],
              [
                -61.56339091839506,
                -9.712404464704266
              ],
              [
                -61.57382786168366,
                -9.717279975386461
              ],
              [
                -61.567391885851,
                -9.727277909438325
              ],
              [
                -61.55470896266691,
                -9.72869487313125
              ],
              [
                -61.54187800753084,
                -9.734547852407266
              ],
              [
                -61.53096206188098,
                -9.739527316345438
              ],
              [
                -61.531517574821855,
                -9.74856227409912
              ],
              [
                -61.53207352774662,
                -9.757597227865036
              ],
              [
                -61.54680739341429,
                -9.787161106481323
              ],
              [
                -61.537850455197585,
                -9.793042080453231
              ],
              [
                -61.53362347509614,
                -9.79376406490128
              ],
              [
                -61.524375527203794,
                -9.795344060999343
              ],
              [
                -61.524726498775046,
                -9.807250985092356
              ],
              [
                -61.52833498536916,
                -9.813637956008696
              ],
              [
                -61.53157344307809,
                -9.819368962204395
              ],
              [
                -61.525074474639645,
                -9.820898419683958
              ],
              [
                -61.5177070518275,
                -9.822632395952821
              ],
              [
                -61.51496201374326,
                -9.84303480466737
              ],
              [
                -61.51117003556663,
                -9.851865756747737
              ],
              [
                -61.50737753438421,
                -9.860696231828868
              ],
              [
                -61.51021646628465,
                -9.892709601094113
              ],
              [
                -61.517322415333496,
                -9.899451074960233
              ],
              [
                -61.524427887384554,
                -9.906192030848992
              ],
              [
                -61.52287434776556,
                -9.92434797226648
              ],
              [
                -61.52259287019356,
                -9.92764494063778
              ],
              [
                -61.52398885758776,
                -9.929345456470417
              ],
              [
                -61.52822430241261,
                -9.934501941811945
              ],
              [
                -61.52582130326919,
                -9.937561422935916
              ],
              [
                -61.523155844090645,
                -9.94095639720967
              ],
              [
                -61.522781332993596,
                -9.948098334898818
              ],
              [
                -61.52878329955302,
                -9.95084185337391
              ],
              [
                -61.53186427882089,
                -9.952250830077524
              ],
              [
                -61.538873193940525,
                -9.963708803658333
              ],
              [
                -61.53201525194412,
                -9.96750427972407
              ],
              [
                -61.52743277900066,
                -9.970040273724194
              ],
              [
                -61.53065221905038,
                -9.989462172243813
              ],
              [
                -61.53677918482052,
                -9.992878171080712
              ],
              [
                -61.54103463588034,
                -9.995251154497925
              ],
              [
                -61.54000914280814,
                -10.000248629446345
              ],
              [
                -61.55506648849679,
                -10.037941016181074
              ],
              [
                -61.57381085365142,
                -10.048861489648916
              ],
              [
                -61.578491323398566,
                -10.054311944623532
              ],
              [
                -61.58317081614971,
                -10.059761936610515
              ],
              [
                -61.57999179691473,
                -10.07186936182582
              ],
              [
                -61.57815530419534,
                -10.078861339034864
              ],
              [
                -61.580751790409224,
                -10.080766338953547
              ],
              [
                -61.58255178207796,
                -10.082087824121798
              ],
              [
                -61.582673746760314,
                -10.090621825816669
              ],
              [
                -61.582740252919294,
                -10.095293263947026
              ],
              [
                -61.585850219376596,
                -10.101474278405822
              ],
              [
                -61.59055319771948,
                -10.110818725862286
              ],
              [
                -61.59008718415645,
                -10.115945706226723
              ],
              [
                -61.58962119158906,
                -10.121072668593776
              ],
              [
                -61.59006317172422,
                -10.12254217201274
              ],
              [
                -61.5996495500832,
                -10.154438528977213
              ],
              [
                -61.5962780901137,
                -10.161967519538825
              ],
              [
                -61.58692363083509,
                -10.162839472042412
              ],
              [
                -61.57652469734593,
                -10.163809991474496
              ],
              [
                -61.559366272387,
                -10.175280380395153
              ],
              [
                -61.55928276029079,
                -10.186181352730966
              ],
              [
                -61.55923272906928,
                -10.19269329986163
              ],
              [
                -61.559189736274185,
                -10.192717795688994
              ],
              [
                -61.55117777228721,
                -10.197387772068607
              ],
              [
                -61.56681467671831,
                -10.205234284379676
              ],
              [
                -61.573461642550335,
                -10.208569774700832
              ],
              [
                -61.573178123661386,
                -10.215680234941702
              ],
              [
                -61.57304961217139,
                -10.218906203988485
              ],
              [
                -61.57450462062603,
                -10.221548198040349
              ],
              [
                -61.577066102535156,
                -10.226201180284598
              ],
              [
                -61.57630008009238,
                -10.245195110166703
              ],
              [
                -61.575329062231496,
                -10.247893095132412
              ],
              [
                -61.57433707539301,
                -10.25065007681368
              ],
              [
                -61.57138159385014,
                -10.258862549139634
              ],
              [
                -61.55405314133494,
                -10.279171927028576
              ],
              [
                -61.543546187157744,
                -10.30060982397487
              ],
              [
                -61.547912640690704,
                -10.30344531789507
              ],
              [
                -61.55279111376884,
                -10.306613814223482
              ],
              [
                -61.54756311516859,
                -10.31600373990418
              ],
              [
                -61.54034415498218,
                -10.319832233047157
              ],
              [
                -61.53319369260036,
                -10.331492636972099
              ],
              [
                -61.52666274873058,
                -10.330907650982125
              ],
              [
                -61.52093825915819,
                -10.330394631981527
              ],
              [
                -61.5156212974583,
                -10.33404462791497
              ],
              [
                -61.511344787661834,
                -10.350009053104293
              ],
              [
                -61.50118336300319,
                -10.35338653039853
              ],
              [
                -61.50214481835967,
                -10.368722941682728
              ],
              [
                -61.50257232472687,
                -10.375538908376535
              ],
              [
                -61.49650879582128,
                -10.389192336533744
              ],
              [
                -61.49174832523139,
                -10.389628348001692
              ],
              [
                -61.48901237441084,
                -10.389879348521509
              ],
              [
                -61.48510539012971,
                -10.396895817372721
              ],
              [
                -61.48351037850511,
                -10.399759781616536
              ],
              [
                -61.48190239961641,
                -10.400400283296511
              ],
              [
                -61.47095892383488,
                -10.40475625009714
              ],
              [
                -61.47457341319009,
                -10.40841773435789
              ],
              [
                -61.47637488290902,
                -10.410243234503469
              ],
              [
                -61.47559890649002,
                -10.418030203132728
              ],
              [
                -61.469324449875224,
                -10.415844687284219
              ],
              [
                -61.46733643735518,
                -10.415152203016598
              ],
              [
                -61.464522949079665,
                -10.417166186901145
              ],
              [
                -61.46123699693897,
                -10.419520142574317
              ],
              [
                -61.464463440832276,
                -10.436900106876454
              ],
              [
                -61.466538406457275,
                -10.448109555588035
              ],
              [
                -61.46904340428882,
                -10.449850049253019
              ],
              [
                -61.47551032796938,
                -10.45434252565524
              ],
              [
                -61.4716478576947,
                -10.45875098266043
              ],
              [
                -61.469653370298325,
                -10.461027479752373
              ],
              [
                -61.48026174910722,
                -10.487356882211499
              ],
              [
                -61.47851572590847,
                -10.529487688698808
              ],
              [
                -61.47549372666016,
                -10.532175191369674
              ],
              [
                -61.46576625871038,
                -10.540825615799921
              ],
              [
                -61.46956370943921,
                -10.556142065110219
              ],
              [
                -61.475022672581645,
                -10.563141023410562
              ],
              [
                -61.47940812514616,
                -10.568765012788893
              ],
              [
                -61.477060611226996,
                -10.588032933387579
              ],
              [
                -61.47690662198623,
                -10.589295419792702
              ],
              [
                -61.477207109391514,
                -10.58981992106718
              ],
              [
                -61.48095559500531,
                -10.596371418513554
              ],
              [
                -61.47835408080887,
                -10.601749391488417
              ],
              [
                -61.4756816167676,
                -10.607273847726365
              ],
              [
                -61.479896570972194,
                -10.614257313137191
              ],
              [
                -61.48904198627926,
                -10.629411771168336
              ],
              [
                -61.48322102727021,
                -10.63665474496647
              ],
              [
                -61.48273349802839,
                -10.637261250599618
              ],
              [
                -61.478404551632636,
                -10.642650678685582
              ],
              [
                -61.48182150674488,
                -10.65295066982902
              ],
              [
                -61.48166795989277,
                -10.66875858983966
              ],
              [
                -61.48961290658631,
                -10.674064064607862
              ],
              [
                -61.49125238838084,
                -10.67515855870637
              ],
              [
                -61.498010348026185,
                -10.688028539634283
              ],
              [
                -61.49827181503036,
                -10.69952449261184
              ],
              [
                -61.485263370861354,
                -10.710345395396219
              ],
              [
                -61.480406410708675,
                -10.714386397290008
              ],
              [
                -61.47184897020899,
                -10.71458636822171
              ],
              [
                -61.47584590561754,
                -10.72210134918658
              ],
              [
                -61.48208585700821,
                -10.725038862188665
              ],
              [
                -61.4848723728755,
                -10.726350324300176
              ],
              [
                -61.48782534357848,
                -10.736009802528594
              ],
              [
                -61.480323373098436,
                -10.743808775181733
              ],
              [
                -61.479898356649,
                -10.761318675745295
              ],
              [
                -61.47792633880513,
                -10.764304676882796
              ],
              [
                -61.47595336296615,
                -10.767290648018164
              ],
              [
                -61.47973780375342,
                -10.785190081023389
              ],
              [
                -61.47329082612601,
                -10.797120021048391
              ],
              [
                -61.48746772367735,
                -10.798592538605252
              ],
              [
                -61.492104707267806,
                -10.788639565213838
              ],
              [
                -61.49441469557636,
                -10.78368110192756
              ],
              [
                -61.50472463859292,
                -10.784362132317451
              ],
              [
                -61.50488316061137,
                -10.78907909875917
              ],
              [
                -61.51001659702691,
                -10.793926101990808
              ],
              [
                -61.50287810851233,
                -10.808057988663034
              ],
              [
                -61.51198253196583,
                -10.821808449208175
              ],
              [
                -61.51086802504381,
                -10.828951414357892
              ],
              [
                -61.50969456279779,
                -10.83647339482201
              ],
              [
                -61.51425200029954,
                -10.839670412075082
              ],
              [
                -61.52159247356841,
                -10.844819878830974
              ],
              [
                -61.52332441418215,
                -10.879888209793062
              ],
              [
                -61.52574886325612,
                -10.884573211267695
              ],
              [
                -61.52036837432137,
                -10.891869156625143
              ],
              [
                -61.51940338955931,
                -10.893178662510673
              ],
              [
                -61.521003365321754,
                -10.89728714401415
              ],
              [
                -61.523756364384504,
                -10.904351625100322
              ],
              [
                -61.521308377222404,
                -10.905000609241343
              ],
              [
                -61.51973736645998,
                -10.905418108835109
              ],
              [
                -61.51443790326579,
                -10.906817577007857
              ],
              [
                -61.520307333700266,
                -10.920129543236326
              ],
              [
                -61.52253832012857,
                -10.938465990327696
              ],
              [
                -61.52270330883367,
                -10.939819955946643
              ],
              [
                -61.5225978049722,
                -10.9400864546533
              ],
              [
                -61.51896482566313,
                -10.949304915943184
              ],
              [
                -61.52706924939176,
                -10.956795877411189
              ],
              [
                -61.52954120323473,
                -10.978625814440896
              ],
              [
                -61.53805614617174,
                -10.97547431910373
              ],
              [
                -61.53894866042522,
                -10.975850834052496
              ],
              [
                -61.54561158452825,
                -10.978661832673337
              ],
              [
                -61.55013507940534,
                -10.980571808413712
              ],
              [
                -61.55007658350608,
                -10.98127781336873
              ],
              [
                -61.54971154685913,
                -10.985702815287546
              ],
              [
                -61.42846079073949,
                -10.987144113625076
              ],
              [
                -61.00005732451556,
                -10.991848907688775
              ],
              [
                -61.00005531190747,
                -10.989902931964638
              ],
              [
                -61.00005533228701,
                -10.987956910244591
              ],
              [
                -60.96971450076486,
                -10.988067893346566
              ],
              [
                -60.831490340100075,
                -10.988528676315036
              ],
              [
                -60.46017855308829,
                -10.989461095303534
              ],
              [
                -60.4595160799759,
                -10.989462569180796
              ],
              [
                -60.451926609638214,
                -10.99288554254459
              ],
              [
                -60.4496436168133,
                -11.008068962847624
              ],
              [
                -60.441157634255404,
                -11.016400908393
              ],
              [
                -60.437399167528405,
                -11.016291902518569
              ],
              [
                -60.44146962288113,
                -11.028265355482299
              ],
              [
                -60.44605654854216,
                -11.041757319774103
              ],
              [
                -60.43514613326544,
                -11.041404812834616
              ],
              [
                -60.43008765230369,
                -11.037767324042155
              ],
              [
                -60.429160680908645,
                -11.037100294365779
              ],
              [
                -60.41357375416188,
                -11.043089739020756
              ],
              [
                -60.410951762871946,
                -11.046381230307494
              ],
              [
                -60.41286321890388,
                -11.060049693277522
              ],
              [
                -60.41384620991268,
                -11.067076661485132
              ],
              [
                -60.407788753002286,
                -11.070836606913948
              ],
              [
                -60.40619776758453,
                -11.070132608266741
              ],
              [
                -60.40359878987012,
                -11.068983613839558
              ],
              [
                -60.39531779134454,
                -11.080049064816205
              ],
              [
                -60.39502781148385,
                -11.080949558420105
              ],
              [
                -60.39101432142633,
                -11.093398016642194
              ],
              [
                -60.38320386516362,
                -11.095826967838933
              ],
              [
                -60.37951138218448,
                -11.095089472760618
              ],
              [
                -60.37270394662949,
                -11.093730490085616
              ],
              [
                -60.37214343329323,
                -11.094669943058799
              ],
              [
                -60.36978694228738,
                -11.09861943792524
              ],
              [
                -60.347429073504664,
                -11.108644351421384
              ],
              [
                -60.34365011302001,
                -11.104486876036317
              ],
              [
                -60.34634406407855,
                -11.098787385352475
              ],
              [
                -60.34426762448069,
                -11.091800938152563
              ],
              [
                -60.34142662185411,
                -11.082242471835812
              ],
              [
                -60.34789510968368,
                -11.073649029130744
              ],
              [
                -60.334008688131995,
                -11.082493454159133
              ],
              [
                -60.316982827413376,
                -11.064699996638321
              ],
              [
                -60.30867436525785,
                -11.063632519210957
              ],
              [
                -60.30071041915495,
                -11.056757508645886
              ],
              [
                -60.29090195055257,
                -11.065094949752874
              ],
              [
                -60.28181703771695,
                -11.063621477786812
              ],
              [
                -60.27747903130135,
                -11.079182365689121
              ],
              [
                -60.278772527108536,
                -11.084681851944476
              ],
              [
                -60.280290473925525,
                -11.091135828433302
              ],
              [
                -60.270455524519186,
                -11.098297792573602
              ],
              [
                -60.26462756967662,
                -11.09327079856174
              ],
              [
                -60.24826169376364,
                -11.096472270847368
              ],
              [
                -60.24777868977959,
                -11.09637876043494
              ],
              [
                -60.238312764891184,
                -11.094547259351005
              ],
              [
                -60.232827764487894,
                -11.09961471196272
              ],
              [
                -60.22695580175009,
                -11.105040193352597
              ],
              [
                -60.210012391534235,
                -11.106411665605567
              ],
              [
                -60.20426242228542,
                -11.111726625667062
              ],
              [
                -60.19093202267256,
                -11.11371859733694
              ],
              [
                -60.18611655000482,
                -11.105768621844634
              ],
              [
                -60.185068057220846,
                -11.104037645618837
              ],
              [
                -60.1764005987463,
                -11.098729644083557
              ],
              [
                -60.15873670735063,
                -11.095257138298331
              ],
              [
                -60.15213177365289,
                -11.100326079951948
              ],
              [
                -60.15192474760341,
                -11.100484594908231
              ],
              [
                -60.15098229278133,
                -11.100141604808687
              ],
              [
                -60.14748577570424,
                -11.098868090445064
              ],
              [
                -60.146074285194736,
                -11.100519094836235
              ],
              [
                -60.14195131323165,
                -11.10534203576542
              ],
              [
                -60.13411434925463,
                -11.102126072530815
              ],
              [
                -60.13009838491599,
                -11.100478037927228
              ],
              [
                -60.12528792449725,
                -11.103312047377646
              ],
              [
                -60.11987043205706,
                -11.106504011230344
              ],
              [
                -60.11624246012538,
                -11.104455023039668
              ],
              [
                -60.11215201039204,
                -11.102144012214398
              ],
              [
                -60.10990150341839,
                -11.104051992050762
              ],
              [
                -60.10431752529687,
                -11.108786485865354
              ],
              [
                -60.088822619687456,
                -11.10850596281385
              ],
              [
                -60.08915912572033,
                -11.102735975647187
              ],
              [
                -60.089314130436264,
                -11.100068014592393
              ],
              [
                -60.083710176964956,
                -11.096450498941268
              ],
              [
                -60.074484241501395,
                -11.104629438481219
              ],
              [
                -60.06617573018493,
                -11.125114839660823
              ],
              [
                -60.043515903894985,
                -11.123875808622774
              ],
              [
                -60.04318387647103,
                -11.123528802580147
              ],
              [
                -60.03690094722273,
                -11.116958350724396
              ],
              [
                -60.033823466200545,
                -11.12288279452704
              ],
              [
                -60.031485954258436,
                -11.127382270832376
              ],
              [
                -60.02570597845987,
                -11.130337255063536
              ],
              [
                -60.01885252303531,
                -11.128703240594374
              ],
              [
                -60.01702004714141,
                -11.122258277755853
              ],
              [
                -60.01520857298806,
                -11.115886822639174
              ],
              [
                -60.012579577761485,
                -11.12212879179104
              ],
              [
                -59.99067470216547,
                -11.123190722974078
              ],
              [
                -59.98984422310997,
                -11.121462757153333
              ],
              [
                -59.98631426086916,
                -11.1141202734174
              ],
              [
                -59.976237818134045,
                -11.121992720752845
              ],
              [
                -59.97940626997829,
                -11.139407652644337
              ],
              [
                -59.985595699229265,
                -11.138216136386824
              ],
              [
                -59.98857117952661,
                -11.137643176954956
              ],
              [
                -59.98635471238516,
                -11.14242362620722
              ],
              [
                -59.98439922367221,
                -11.146639613380142
              ],
              [
                -59.98717267461305,
                -11.146960606684988
              ],
              [
                -59.994485164639144,
                -11.147807607397743
              ],
              [
                -59.9972326057922,
                -11.150965598215027
              ],
              [
                -59.99537114105985,
                -11.156586085395933
              ],
              [
                -59.98845467778753,
                -11.160835053990384
              ],
              [
                -59.98704614692269,
                -11.170711002275747
              ],
              [
                -59.97987469476822,
                -11.179194450852538
              ],
              [
                -59.97818867760573,
                -11.179306462487823
              ],
              [
                -59.97067473144985,
                -11.179807456484623
              ],
              [
                -59.96348425866684,
                -11.188720378126186
              ],
              [
                -59.96731872952668,
                -11.19746936829475
              ],
              [
                -59.97453969040479,
                -11.198119873768155
              ],
              [
                -59.97498366408893,
                -11.198504353840466
              ],
              [
                -59.98059412617946,
                -11.20336733310511
              ],
              [
                -59.98387011863536,
                -11.21480278959853
              ],
              [
                -59.98267959566075,
                -11.223953743480266
              ],
              [
                -59.98148959466886,
                -11.233105208367768
              ],
              [
                -59.97490810191687,
                -11.242165153423587
              ],
              [
                -59.96737413052221,
                -11.252537124094518
              ],
              [
                -59.96767064415822,
                -11.259124581763835
              ],
              [
                -59.967980638184024,
                -11.266008563164538
              ],
              [
                -59.96238312608709,
                -11.273626004212037
              ],
              [
                -59.95990163790958,
                -11.277002971168779
              ],
              [
                -59.95960463531601,
                -11.282308946438594
              ],
              [
                -59.958929638095725,
                -11.294336902653665
              ],
              [
                -59.95420765867954,
                -11.299171351376561
              ],
              [
                -59.94948618125993,
                -11.304006362094333
              ],
              [
                -59.92723528120375,
                -11.310037786369799
              ],
              [
                -59.91732078968672,
                -11.345953092106289
              ],
              [
                -59.92240377036393,
                -11.350465586156535
              ],
              [
                -59.92398674642969,
                -11.351870576749896
              ],
              [
                -59.92197625561441,
                -11.35513858898382
              ],
              [
                -59.92190174984382,
                -11.355259556326011
              ],
              [
                -59.92196773421049,
                -11.355394564849849
              ],
              [
                -59.924899242095584,
                -11.361379034758912
              ],
              [
                -59.92134221379453,
                -11.374037977271978
              ],
              [
                -59.91993773127428,
                -11.379036458995287
              ],
              [
                -59.92096572506222,
                -11.38525893958982
              ],
              [
                -59.922207685262784,
                -11.39277437891499
              ],
              [
                -59.92237167551093,
                -11.393769397853697
              ],
              [
                -59.92009670122549,
                -11.397613362116758
              ],
              [
                -59.92720313850893,
                -11.412368339042198
              ],
              [
                -59.92537112958276,
                -11.415084796001429
              ],
              [
                -59.93387455824368,
                -11.423270275076563
              ],
              [
                -59.938213548257124,
                -11.42277529778569
              ],
              [
                -59.941482032437364,
                -11.422402283099993
              ],
              [
                -59.94284951381347,
                -11.427139270823487
              ],
              [
                -59.94422947603535,
                -11.431918740388324
              ],
              [
                -59.95181192348692,
                -11.436024742699985
              ],
              [
                -59.97570127712577,
                -11.438824763137804
              ],
              [
                -59.98487069047261,
                -11.468945177080117
              ],
              [
                -60.00187406746765,
                -11.478265642301043
              ],
              [
                -60.006830533725044,
                -11.483955111258933
              ],
              [
                -60.009113492418706,
                -11.486575622874586
              ],
              [
                -60.011687973835244,
                -11.487275125345299
              ],
              [
                -60.01509695187818,
                -11.4882011302948
              ],
              [
                -60.016147439804136,
                -11.490323621919849
              ],
              [
                -60.019266938888094,
                -11.496624571035268
              ],
              [
                -60.02198640635428,
                -11.495709104775026
              ],
              [
                -60.03069888080024,
                -11.492775609774116
              ],
              [
                -60.03096633637259,
                -11.498234076561161
              ],
              [
                -60.028815370007614,
                -11.503870567337943
              ],
              [
                -60.02781683722973,
                -11.506487045236652
              ],
              [
                -60.036153800522875,
                -11.512642033176473
              ],
              [
                -60.040984262351515,
                -11.516207535201724
              ],
              [
                -60.04966117994202,
                -11.528273990164514
              ],
              [
                -60.05323964566451,
                -11.533248986910696
              ],
              [
                -60.05692811843562,
                -11.541701432805043
              ],
              [
                -60.0747469948365,
                -11.550350921761519
              ],
              [
                -60.07917346063448,
                -11.552499448270451
              ],
              [
                -60.078762950561796,
                -11.553885412553834
              ],
              [
                -60.078160471456535,
                -11.555918432701066
              ],
              [
                -60.085379889681015,
                -11.55970789004882
              ],
              [
                -60.08637041085186,
                -11.560227907548208
              ],
              [
                -60.090242390264926,
                -11.554482950206717
              ],
              [
                -60.09362386072292,
                -11.562450397738928
              ],
              [
                -60.090411370378455,
                -11.56489188452155
              ],
              [
                -60.08667839840877,
                -11.567729388672944
              ],
              [
                -60.0922753483637,
                -11.567613386052477
              ],
              [
                -60.097848809462214,
                -11.567497889388754
              ],
              [
                -60.09873630687077,
                -11.57042739230226
              ],
              [
                -60.09565332234076,
                -11.572544371715297
              ],
              [
                -60.09112185161034,
                -11.575656356271054
              ],
              [
                -60.09844380836207,
                -11.576618355049257
              ],
              [
                -60.10107277311819,
                -11.576963372203595
              ],
              [
                -60.09975427910089,
                -11.578531349103981
              ],
              [
                -60.093904321213856,
                -11.585484810741773
              ],
              [
                -60.10320625243443,
                -11.583570826447648
              ],
              [
                -60.110376727529214,
                -11.582095359490467
              ],
              [
                -60.11434466365129,
                -11.59106382980848
              ],
              [
                -60.11007017872213,
                -11.595082297909759
              ],
              [
                -60.113535648629345,
                -11.603050776672413
              ],
              [
                -60.11018317681064,
                -11.612778213785658
              ],
              [
                -60.10936765933987,
                -11.615144178138523
              ],
              [
                -60.11068867082661,
                -11.616058207539114
              ],
              [
                -60.114762136488366,
                -11.618878687601889
              ],
              [
                -60.111317135646196,
                -11.620140687053022
              ],
              [
                -60.110513162883144,
                -11.620435657355742
              ],
              [
                -60.11338360396039,
                -11.634058629735145
              ],
              [
                -60.11365759595872,
                -11.635358118622422
              ],
              [
                -60.113562126036044,
                -11.635626100298282
              ],
              [
                -60.11109613008574,
                -11.642518076225068
              ],
              [
                -60.1143716092107,
                -11.642747585055895
              ],
              [
                -60.11692956269017,
                -11.642927077827682
              ],
              [
                -60.11826205711905,
                -11.65367156692593
              ],
              [
                -60.11576705846826,
                -11.655020552680044
              ],
              [
                -60.113950556458114,
                -11.656003524216475
              ],
              [
                -60.11820600324155,
                -11.679977942602866
              ],
              [
                -60.1186324862763,
                -11.680213429349905
              ],
              [
                -60.121872465745376,
                -11.682004908418762
              ],
              [
                -60.11880650036798,
                -11.686138395171453
              ],
              [
                -60.1169529847901,
                -11.688636871118431
              ],
              [
                -60.11784448662862,
                -11.698361839883942
              ],
              [
                -60.118148991720595,
                -11.701679824160566
              ],
              [
                -60.11748695739347,
                -11.702908835694004
              ],
              [
                -60.11565548102085,
                -11.706308780804557
              ],
              [
                -60.11710647976808,
                -11.707740303242147
              ],
              [
                -60.12112541759989,
                -11.711705300379991
              ],
              [
                -60.11602097552491,
                -11.717353741978066
              ],
              [
                -60.11195296540251,
                -11.721856204351377
              ],
              [
                -60.1144584737738,
                -11.72605922577045
              ],
              [
                -60.11615293946956,
                -11.728901211590983
              ],
              [
                -60.110682437993574,
                -11.747864617329565
              ],
              [
                -60.110538450800256,
                -11.747891121957633
              ],
              [
                -60.10684545948718,
                -11.748573085408756
              ],
              [
                -60.10945292608626,
                -11.753937098045402
              ],
              [
                -60.1118444187878,
                -11.758855563215372
              ],
              [
                -60.11078942818228,
                -11.762575043353257
              ],
              [
                -60.10778194034527,
                -11.762959054307098
              ],
              [
                -60.10504045899163,
                -11.763309047884203
              ],
              [
                -60.1065434364674,
                -11.769577525668907
              ],
              [
                -60.10656744513611,
                -11.769679498274282
              ],
              [
                -60.10651842237385,
                -11.769708493061762
              ],
              [
                -60.10245744605855,
                -11.772115476435646
              ],
              [
                -60.10572990726328,
                -11.773922975554
              ],
              [
                -60.108318407231586,
                -11.775353468063981
              ],
              [
                -60.108618894553004,
                -11.780157450987003
              ],
              [
                -60.10490942902993,
                -11.778304948271114
              ],
              [
                -60.10395994955184,
                -11.777830480601533
              ],
              [
                -60.10054495142533,
                -11.780221446201857
              ],
              [
                -60.104254914448696,
                -11.781800444092374
              ],
              [
                -60.104961428692754,
                -11.782100951072652
              ],
              [
                -60.10329989567688,
                -11.786901933482316
              ],
              [
                -60.102350424237834,
                -11.78964541600211
              ],
              [
                -60.103005400679216,
                -11.790004393639743
              ],
              [
                -60.10795987619971,
                -11.792718406908218
              ],
              [
                -60.10275640230188,
                -11.79370439331622
              ],
              [
                -60.09774695232239,
                -11.79465389322899
              ],
              [
                -60.09619946134557,
                -11.799064350519055
              ],
              [
                -60.10024042401523,
                -11.79761137002614
              ],
              [
                -60.10231989484996,
                -11.800201343657877
              ],
              [
                -60.104129876908345,
                -11.802455848245886
              ],
              [
                -60.10214439605357,
                -11.802810365150254
              ],
              [
                -60.09837840819696,
                -11.803481829488986
              ],
              [
                -60.101065897382306,
                -11.814168799493157
              ],
              [
                -60.09724091175092,
                -11.816523286502266
              ],
              [
                -60.10111789279862,
                -11.818085285793227
              ],
              [
                -60.10281836941644,
                -11.81877027392209
              ],
              [
                -60.100691875541614,
                -11.824429749824153
              ],
              [
                -60.09873888649109,
                -11.829626725022356
              ],
              [
                -60.09918485400447,
                -11.830075719902469
              ],
              [
                -60.108231293278784,
                -11.839181697213332
              ],
              [
                -60.10059384145077,
                -11.835008701302526
              ],
              [
                -60.09736935034537,
                -11.839457173415733
              ],
              [
                -60.09832132607538,
                -11.845155669716435
              ],
              [
                -60.09334686394002,
                -11.848762112274159
              ],
              [
                -60.095710851824904,
                -11.858451074038422
              ],
              [
                -60.09206536413617,
                -11.852747599880452
              ],
              [
                -60.088897408236285,
                -11.854755591549045
              ],
              [
                -60.090597356991296,
                -11.860728082034637
              ],
              [
                -60.08796189589422,
                -11.862215068896534
              ],
              [
                -60.08570241169295,
                -11.860414068525333
              ],
              [
                -60.0827844363897,
                -11.858087053219187
              ],
              [
                -60.08004495147568,
                -11.86249155438918
              ],
              [
                -60.083335407839186,
                -11.865741038419134
              ],
              [
                -60.084895375686045,
                -11.867281022644676
              ],
              [
                -60.08221240880579,
                -11.868268022560327
              ],
              [
                -60.07798894003626,
                -11.869820989262012
              ],
              [
                -60.07637792874339,
                -11.874042490258184
              ],
              [
                -60.074369945871176,
                -11.879302478086522
              ],
              [
                -60.07609340417908,
                -11.88488942327715
              ],
              [
                -60.07284294702049,
                -11.884087456821678
              ],
              [
                -60.059982499912444,
                -11.894604376453872
              ],
              [
                -60.049848544901,
                -11.8974658438143
              ],
              [
                -60.04397459716942,
                -11.894638337281453
              ],
              [
                -60.0404276144882,
                -11.892930356173148
              ],
              [
                -60.03705215109734,
                -11.895336322737982
              ],
              [
                -60.03289766331378,
                -11.89829579651524
              ],
              [
                -60.02976169712841,
                -11.895016313892803
              ],
              [
                -60.029347184408365,
                -11.894582805000617
              ],
              [
                -60.0272381823707,
                -11.894905798792067
              ],
              [
                -60.02072224633733,
                -11.895901812707404
              ],
              [
                -60.01660326361996,
                -11.894438780518097
              ],
              [
                -60.01153930916412,
                -11.892640307058445
              ],
              [
                -60.0063488370361,
                -11.894283300600309
              ],
              [
                -59.99489785095975,
                -11.90891469703403
              ],
              [
                -59.984634409765206,
                -11.913945157816626
              ],
              [
                -59.98292842334728,
                -11.920139611127572
              ],
              [
                -59.98650339866634,
                -11.926201595602096
              ],
              [
                -59.98820487410973,
                -11.929087076311724
              ],
              [
                -59.98544338801194,
                -11.933660086671598
              ],
              [
                -59.979627391649686,
                -11.943291025774931
              ],
              [
                -59.98325839021488,
                -11.949029501765706
              ],
              [
                -59.98329038092828,
                -11.949080509605173
              ],
              [
                -59.983155879474886,
                -11.949749493491113
              ],
              [
                -59.98259538460124,
                -11.952528468552385
              ],
              [
                -59.982124361029314,
                -11.95486195568757
              ],
              [
                -59.98645283613508,
                -11.959688945873541
              ],
              [
                -59.97829289115016,
                -11.964836401936902
              ],
              [
                -59.97082241987559,
                -11.969548883114454
              ],
              [
                -59.97379039018603,
                -11.975914844230875
              ],
              [
                -59.968933893214704,
                -11.982269342129548
              ],
              [
                -59.96984488882165,
                -11.988617819579433
              ],
              [
                -59.97238488547873,
                -11.989244809529563
              ],
              [
                -59.97904083165869,
                -11.990886794647336
              ],
              [
                -59.97229886360624,
                -11.995817767206159
              ],
              [
                -59.970069850838655,
                -11.997448238147705
              ],
              [
                -59.96604337930869,
                -12.006763717875407
              ],
              [
                -59.97207882308007,
                -12.012696715498889
              ],
              [
                -59.97412229908871,
                -12.014705692634099
              ],
              [
                -59.97881523778405,
                -12.029150128390954
              ],
              [
                -59.96904080560795,
                -12.032073098973099
              ],
              [
                -59.96517733562911,
                -12.045839528968424
              ],
              [
                -59.96093183702399,
                -12.050042502193367
              ],
              [
                -59.96145830866052,
                -12.055964983815569
              ],
              [
                -59.9571198334246,
                -12.056042977518464
              ],
              [
                -59.95637086087359,
                -12.05605596708816
              ],
              [
                -59.95525883173744,
                -12.058049976513391
              ],
              [
                -59.951265888017225,
                -12.065209927541249
              ],
              [
                -59.947651364402994,
                -12.065319903431842
              ],
              [
                -59.94664690534645,
                -12.06535042545631
              ],
              [
                -59.92873649328896,
                -12.078052323177726
              ],
              [
                -59.92577350948181,
                -12.086227271734996
              ],
              [
                -59.923177467395924,
                -12.09338975130216
              ],
              [
                -59.916188006811986,
                -12.096745748073474
              ],
              [
                -59.91472204524084,
                -12.097449712361653
              ],
              [
                -59.9006985776628,
                -12.115041613227284
              ],
              [
                -59.89960198120557,
                -12.167464859969533
              ],
              [
                -59.90394794435172,
                -12.175225867851053
              ],
              [
                -59.908833912408106,
                -12.183952835626139
              ],
              [
                -59.908293899174275,
                -12.192024281158028
              ],
              [
                -59.90073442365542,
                -12.214832190760172
              ],
              [
                -59.901175400411965,
                -12.223475136696525
              ],
              [
                -59.89566392680093,
                -12.229510585694024
              ],
              [
                -59.89306243589559,
                -12.232360071695815
              ],
              [
                -59.893056916827646,
                -12.232412577461483
              ],
              [
                -59.89177841115788,
                -12.245053537152058
              ],
              [
                -59.88580845087303,
                -12.245070005963894
              ],
              [
                -59.831303704905665,
                -12.291539216993463
              ],
              [
                -59.773813455152705,
                -12.34055288414017
              ],
              [
                -59.79240233613957,
                -12.345378925382658
              ],
              [
                -59.79541933761148,
                -12.34919840674207
              ],
              [
                -59.80157325753144,
                -12.356991394045545
              ],
              [
                -59.80447924635903,
                -12.366628353409249
              ],
              [
                -59.80495870938857,
                -12.368218814529644
              ],
              [
                -59.80700470217718,
                -12.369724838954225
              ],
              [
                -59.818717626096905,
                -12.378346826234182
              ],
              [
                -59.81891913423818,
                -12.384330768125702
              ],
              [
                -59.81909608593507,
                -12.389588754067109
              ],
              [
                -59.822700101485886,
                -12.390009245061087
              ],
              [
                -59.8230130745365,
                -12.390045743494861
              ],
              [
                -59.832011500190774,
                -12.400997251830734
              ],
              [
                -59.83351996961843,
                -12.40792769218969
              ],
              [
                -59.834192477557856,
                -12.411014701327113
              ],
              [
                -59.83716292176947,
                -12.416118667224515
              ],
              [
                -59.8420093824228,
                -12.424446165968961
              ],
              [
                -59.841723877014225,
                -12.43269111638922
              ],
              [
                -59.84081335102217,
                -12.458967482049006
              ],
              [
                -59.84620727539842,
                -12.469297479412395
              ],
              [
                -59.85531123232107,
                -12.478192926927829
              ],
              [
                -59.86794913512364,
                -12.481961428919735
              ],
              [
                -59.88747004254073,
                -12.474610499105369
              ],
              [
                -59.89635797399887,
                -12.475771503045257
              ],
              [
                -59.89644947389045,
                -12.474529535479705
              ],
              [
                -59.89672547159206,
                -12.470784542867703
              ],
              [
                -59.90261844668564,
                -12.472108521434905
              ],
              [
                -59.90583589360391,
                -12.477609516242945
              ],
              [
                -59.90712738252999,
                -12.479816528349348
              ],
              [
                -59.9128658711825,
                -12.480312517140897
              ],
              [
                -59.924572267087356,
                -12.481325547075327
              ],
              [
                -59.934684226016635,
                -12.486680528626735
              ],
              [
                -59.935089695098924,
                -12.489471025597302
              ],
              [
                -59.9358441693644,
                -12.49466251108165
              ],
              [
                -59.95693306507809,
                -12.50181050497706
              ],
              [
                -59.972867410213006,
                -12.529962904505565
              ],
              [
                -60.000398711019415,
                -12.535822935321436
              ],
              [
                -60.00640417835292,
                -12.545313905813265
              ],
              [
                -60.01015763191972,
                -12.547841397341347
              ],
              [
                -60.01426461490255,
                -12.55060741154507
              ],
              [
                -60.02215006527107,
                -12.555918364268601
              ],
              [
                -60.02610353313222,
                -12.561227884494958
              ],
              [
                -60.02749195651319,
                -12.578028298514711
              ],
              [
                -60.02756698725455,
                -12.578940283825114
              ],
              [
                -60.03440088672429,
                -12.594901219859759
              ],
              [
                -60.039230352611085,
                -12.599059211662071
              ],
              [
                -60.041660361896234,
                -12.599663720786303
              ],
              [
                -60.05859471840511,
                -12.603875727609907
              ],
              [
                -60.06819614475968,
                -12.615594696902635
              ],
              [
                -60.06353765265092,
                -12.626087161960188
              ],
              [
                -60.06140718679624,
                -12.630886612744057
              ],
              [
                -60.062405662825846,
                -12.640412069745071
              ],
              [
                -60.070654561747816,
                -12.653346058457167
              ],
              [
                -60.07754302003327,
                -12.66414749952328
              ],
              [
                -60.0765965057451,
                -12.676104467664581
              ],
              [
                -60.07564948543321,
                -12.688062883813473
              ],
              [
                -60.084510377644904,
                -12.712427307111147
              ],
              [
                -60.087849356264115,
                -12.72160779223245
              ],
              [
                -60.08947282877182,
                -12.731948233204063
              ],
              [
                -60.08848331234365,
                -12.74006018742299
              ],
              [
                -60.0850178266221,
                -12.748202148714304
              ],
              [
                -60.08026930647211,
                -12.759358115935974
              ],
              [
                -60.07431731995119,
                -12.772763055434558
              ],
              [
                -60.07553379195353,
                -12.786982984493806
              ],
              [
                -60.07655628278914,
                -12.79887592690453
              ],
              [
                -60.077244751006596,
                -12.803653420335122
              ],
              [
                -60.0841571363165,
                -12.836899789366635
              ],
              [
                -60.07857059449835,
                -12.880736079072168
              ],
              [
                -60.082282051425565,
                -12.889518057851236
              ],
              [
                -60.08599352034015,
                -12.898300016650259
              ],
              [
                -60.10459936882818,
                -12.91905995704819
              ],
              [
                -60.11662571790361,
                -12.959148289572596
              ],
              [
                -60.127316650709794,
                -12.960800303840587
              ],
              [
                -60.13032162682584,
                -12.961264802851858
              ],
              [
                -60.133208123939234,
                -12.964023316134885
              ],
              [
                -60.13925305701046,
                -12.969799311177571
              ],
              [
                -60.146250004430456,
                -12.965829809426024
              ],
              [
                -60.15009102199993,
                -12.963650353030406
              ],
              [
                -60.15423745452277,
                -12.966936330633368
              ],
              [
                -60.15601194905243,
                -12.968342346326457
              ],
              [
                -60.16687637838227,
                -12.970238362964967
              ],
              [
                -60.16962337307664,
                -12.96971834254506
              ],
              [
                -60.182804305651665,
                -12.967223383912458
              ],
              [
                -60.18873574408412,
                -12.970738867120513
              ],
              [
                -60.190174741931855,
                -12.97636885271951
              ],
              [
                -60.19152522001765,
                -12.981650830586306
              ],
              [
                -60.197230193419855,
                -12.985348841617714
              ],
              [
                -60.20087662783316,
                -12.987712329086383
              ],
              [
                -60.202944603402116,
                -12.99262231992779
              ],
              [
                -60.20403311459457,
                -12.99520631542677
              ],
              [
                -60.20703410193046,
                -12.998986301786548
              ],
              [
                -60.21311604391394,
                -13.006646775266743
              ],
              [
                -60.21382601181543,
                -13.015306238990757
              ],
              [
                -60.21474948304156,
                -13.026574700404005
              ],
              [
                -60.218763470907966,
                -13.027170686923967
              ],
              [
                -60.219677455184765,
                -13.027306700179738
              ],
              [
                -60.22263591960771,
                -13.031158688198278
              ],
              [
                -60.22415241049535,
                -13.033133694081917
              ],
              [
                -60.2257309137492,
                -13.033302199526046
              ],
              [
                -60.23695834743677,
                -13.034501705910438
              ],
              [
                -60.24213727665675,
                -13.041248199458224
              ],
              [
                -60.24416778058632,
                -13.04607267665872
              ],
              [
                -60.247801246082304,
                -13.054706117399968
              ],
              [
                -60.25209368433995,
                -13.055768622582432
              ],
              [
                -60.252826699602586,
                -13.055950129296587
              ],
              [
                -60.25683517354855,
                -13.061838119091194
              ],
              [
                -60.26707555042425,
                -13.076878582751267
              ],
              [
                -60.26888656585326,
                -13.077263585784683
              ],
              [
                -60.28244947321162,
                -13.080146583024352
              ],
              [
                -60.281974927350355,
                -13.092984519427663
              ],
              [
                -60.28350690131884,
                -13.097755015930879
              ],
              [
                -60.27863493412149,
                -13.102160977129053
              ],
              [
                -60.28182591066257,
                -13.10575645877157
              ],
              [
                -60.27657693232093,
                -13.11226692458727
              ],
              [
                -60.2804664080329,
                -13.119541937559587
              ],
              [
                -60.275604907604766,
                -13.126421370639074
              ],
              [
                -60.28007336169159,
                -13.135211838585587
              ],
              [
                -60.27578187929745,
                -13.138526846407075
              ],
              [
                -60.267962429208055,
                -13.144564779998996
              ],
              [
                -60.29562418635872,
                -13.176759185818204
              ],
              [
                -60.29782917554806,
                -13.17566120574023
              ],
              [
                -60.29910470129331,
                -13.175026730898352
              ],
              [
                -60.30362612649828,
                -13.178805713547582
              ],
              [
                -60.308784073435895,
                -13.191878165527301
              ],
              [
                -60.30833560694857,
                -13.194811635645095
              ],
              [
                -60.307683591868326,
                -13.19908014390253
              ],
              [
                -60.31897199606472,
                -13.215606591212756
              ],
              [
                -60.32028797019462,
                -13.217533069009544
              ],
              [
                -60.320795971730064,
                -13.219172086349648
              ],
              [
                -60.323817439266676,
                -13.228922527689926
              ],
              [
                -60.327188910571635,
                -13.22971152128225
              ],
              [
                -60.327908918937624,
                -13.229880540047454
              ],
              [
                -60.32782490399848,
                -13.23056401709191
              ],
              [
                -60.32515986395976,
                -13.252343940955548
              ],
              [
                -60.3326552964852,
                -13.257528411006819
              ],
              [
                -60.3384897634374,
                -13.271922871228886
              ],
              [
                -60.35046518995926,
                -13.272556386918197
              ],
              [
                -60.35126365412695,
                -13.272598892363423
              ],
              [
                -60.351526178673616,
                -13.275388872548758
              ],
              [
                -60.35315963025992,
                -13.29275181127183
              ],
              [
                -60.36032954467816,
                -13.298893290872714
              ],
              [
                -60.360833078775364,
                -13.299324293144378
              ],
              [
                -60.36268704253049,
                -13.296128822890333
              ],
              [
                -60.364429517974976,
                -13.29953379659719
              ],
              [
                -60.360833078775364,
                -13.299324293144378
              ],
              [
                -60.360877075097726,
                -13.300937793679287
              ],
              [
                -60.36107453262894,
                -13.308159254749215
              ],
              [
                -60.36323701012083,
                -13.310361257201947
              ],
              [
                -60.37095294964183,
                -13.318220733984147
              ],
              [
                -60.37293142956272,
                -13.324034213418313
              ],
              [
                -60.367970464917384,
                -13.329265676095735
              ],
              [
                -60.36554245090058,
                -13.331826155763986
              ],
              [
                -60.36473995216232,
                -13.34053912878082
              ],
              [
                -60.37062289243876,
                -13.347967614641174
              ],
              [
                -60.3707953686201,
                -13.367141521271439
              ],
              [
                -60.37392132762257,
                -13.367815056923241
              ],
              [
                -60.37606983987973,
                -13.368277537435496
              ],
              [
                -60.375564813481695,
                -13.373628036729192
              ],
              [
                -60.37904231127941,
                -13.37482649797737
              ],
              [
                -60.37847179184322,
                -13.390689937578607
              ],
              [
                -60.376070770366276,
                -13.396657435511614
              ],
              [
                -60.37132277835974,
                -13.40846037302377
              ],
              [
                -60.376265740841184,
                -13.412747369799247
              ],
              [
                -60.37876472136715,
                -13.41491486314975
              ],
              [
                -60.37878821173893,
                -13.420287819465939
              ],
              [
                -60.37879622193223,
                -13.422045314374602
              ],
              [
                -60.38227763511199,
                -13.441265236815847
              ],
              [
                -60.38737607492583,
                -13.454284697687855
              ],
              [
                -60.39937950831154,
                -13.455844716054687
              ],
              [
                -60.401930477955126,
                -13.4624677135672
              ],
              [
                -60.40622397971068,
                -13.461889696727981
              ],
              [
                -60.410986924817266,
                -13.461249220105808
              ],
              [
                -60.42985225926011,
                -13.481550683082055
              ],
              [
                -60.430655768527735,
                -13.484789154690532
              ],
              [
                -60.431458250799494,
                -13.488027657298844
              ],
              [
                -60.4558220833702,
                -13.493112648051612
              ],
              [
                -60.46108456159079,
                -13.490597199023014
              ],
              [
                -60.46504305820041,
                -13.488705687793757
              ],
              [
                -60.4784604443302,
                -13.492588717845752
              ],
              [
                -60.48118891287902,
                -13.49643521501196
              ],
              [
                -60.48119592281593,
                -13.496445214986256
              ],
              [
                -60.481202920784206,
                -13.496440700018846
              ],
              [
                -60.485877400841204,
                -13.493772203387532
              ],
              [
                -60.50614976301488,
                -13.501553744965264
              ],
              [
                -60.529823585355885,
                -13.516632211375198
              ],
              [
                -60.53108105751121,
                -13.525615185964309
              ],
              [
                -60.53251254593105,
                -13.535842646940907
              ],
              [
                -60.540651005375864,
                -13.536028664045507
              ],
              [
                -60.54349146543267,
                -13.536093143668541
              ],
              [
                -60.54531946576923,
                -13.541108149371484
              ],
              [
                -60.54782794303426,
                -13.547990124017097
              ],
              [
                -60.5542913862758,
                -13.54777261728334
              ],
              [
                -60.5554893529688,
                -13.552954099033098
              ],
              [
                -60.57710072147055,
                -13.564819588420255
              ],
              [
                -60.58530214130825,
                -13.564676122017786
              ],
              [
                -60.591957119963716,
                -13.571137122029793
              ],
              [
                -60.59976605427965,
                -13.572310603564974
              ],
              [
                -60.60189705638354,
                -13.571070127943456
              ],
              [
                -60.61070852146962,
                -13.565941172716357
              ],
              [
                -60.63165488518505,
                -13.571291679883739
              ],
              [
                -60.634003836329484,
                -13.578193638249974
              ],
              [
                -60.64085477386308,
                -13.584650629765061
              ],
              [
                -60.639643284115365,
                -13.587363119437736
              ],
              [
                -60.63776779705267,
                -13.591562123806497
              ],
              [
                -60.64062926722364,
                -13.596131586561004
              ],
              [
                -60.64496321773751,
                -13.592413098392566
              ],
              [
                -60.65490815112158,
                -13.602033590798667
              ],
              [
                -60.658898622172075,
                -13.603139612710326
              ],
              [
                -60.66363160139752,
                -13.604451111353468
              ],
              [
                -60.66451158604722,
                -13.607738091111548
              ],
              [
                -60.665405575495804,
                -13.611078562688336
              ],
              [
                -60.66686058491891,
                -13.609699578205968
              ],
              [
                -60.66873804972431,
                -13.607919592198687
              ],
              [
                -60.66900906851146,
                -13.612790571392871
              ],
              [
                -60.66902505077641,
                -13.613084572257096
              ],
              [
                -60.66917804998306,
                -13.613034572774946
              ],
              [
                -60.67218303140496,
                -13.612051071951132
              ],
              [
                -60.67371901625617,
                -13.616680079750903
              ],
              [
                -60.68548444013075,
                -13.624819552954458
              ],
              [
                -60.688520881177006,
                -13.633090035446394
              ],
              [
                -60.68632342104837,
                -13.637706995523022
              ],
              [
                -60.68582490069701,
                -13.638755520318124
              ],
              [
                -60.6900928519755,
                -13.643130980866284
              ],
              [
                -60.691241873628535,
                -13.644308470594208
              ],
              [
                -60.69096037810215,
                -13.645308492036598
              ],
              [
                -60.690049836390315,
                -13.648546969279732
              ],
              [
                -60.69255434643458,
                -13.652292955651946
              ],
              [
                -60.692956821745334,
                -13.652894964105045
              ],
              [
                -60.69904479493644,
                -13.653774448350674
              ],
              [
                -60.69718828304451,
                -13.657355453261884
              ],
              [
                -60.70154327789526,
                -13.6598599574423
              ],
              [
                -60.69646526518025,
                -13.666093410093195
              ],
              [
                -60.69913175196674,
                -13.67042938548442
              ],
              [
                -60.7091037062651,
                -13.67131090587761
              ],
              [
                -60.709071199810985,
                -13.675712880365626
              ],
              [
                -60.713388655645076,
                -13.675587387910062
              ],
              [
                -60.71310316476836,
                -13.68222837500388
              ],
              [
                -60.7162626160689,
                -13.681810392283333
              ],
              [
                -60.714886145741204,
                -13.68520185096531
              ],
              [
                -60.709437149089624,
                -13.686916352747657
              ],
              [
                -60.70777965664466,
                -13.692482822223587
              ],
              [
                -60.71511362808916,
                -13.69309184619911
              ],
              [
                -60.71688159234929,
                -13.69323881832685
              ],
              [
                -60.721487070089886,
                -13.691473359979183
              ],
              [
                -60.734257491613555,
                -13.686576887150277
              ],
              [
                -60.738995478538826,
                -13.684832911989739
              ],
              [
                -60.744898949256815,
                -13.685526907627526
              ],
              [
                -60.74709842574046,
                -13.685785431219
              ],
              [
                -60.75454937052962,
                -13.686856443615712
              ],
              [
                -60.75817435575667,
                -13.68614443303697
              ],
              [
                -60.761804838973106,
                -13.685423466504732
              ],
              [
                -60.764937825176276,
                -13.684676454031248
              ],
              [
                -60.768981808969095,
                -13.682585973778744
              ],
              [
                -60.76940130739496,
                -13.678988007880724
              ],
              [
                -60.7694588270634,
                -13.674881502235332
              ],
              [
                -60.769530325877696,
                -13.67203802862743
              ],
              [
                -60.76956082736338,
                -13.671278527694323
              ],
              [
                -60.76982985437544,
                -13.664619574589908
              ],
              [
                -60.7734973331842,
                -13.660793066406002
              ],
              [
                -60.777286314967206,
                -13.659391617884733
              ],
              [
                -60.78072280280693,
                -13.657998595590799
              ],
              [
                -60.78566726636756,
                -13.655881112318394
              ],
              [
                -60.7894142392518,
                -13.65546264082002
              ],
              [
                -60.789843731830594,
                -13.65541512190734
              ],
              [
                -60.7933127020145,
                -13.655150649217948
              ],
              [
                -60.80053666776944,
                -13.654593169550951
              ],
              [
                -60.80981862475296,
                -13.653435668564818
              ],
              [
                -60.81490358697579,
                -13.643340736090822
              ],
              [
                -60.81524211243781,
                -13.64267774241864
              ],
              [
                -60.81841110940703,
                -13.636467757584745
              ],
              [
                -60.82794006367563,
                -13.629041809854671
              ],
              [
                -60.83376700917723,
                -13.62642536237523
              ],
              [
                -60.84040850934459,
                -13.627265856944089
              ],
              [
                -60.843594436829434,
                -13.630380857308273
              ],
              [
                -60.844280459334726,
                -13.631051839094406
              ],
              [
                -60.84723741822743,
                -13.630022836342231
              ],
              [
                -60.852661917817926,
                -13.627997387683934
              ],
              [
                -60.85614240179884,
                -13.626439388112798
              ],
              [
                -60.86051085434724,
                -13.624526399798564
              ],
              [
                -60.86637833474667,
                -13.622319418777467
              ],
              [
                -60.873608781263854,
                -13.619605935601886
              ],
              [
                -60.87894776328251,
                -13.61716644938738
              ],
              [
                -60.88249628269453,
                -13.610200019283694
              ],
              [
                -60.88277476040062,
                -13.609586998283788
              ],
              [
                -60.886465777476445,
                -13.601473054003794
              ],
              [
                -60.901838201833975,
                -13.589344633942053
              ],
              [
                -60.902400190503585,
                -13.588903151856355
              ],
              [
                -60.90822066136385,
                -13.58433316502703
              ],
              [
                -60.92010610427502,
                -13.568485250319997
              ],
              [
                -60.92411162978383,
                -13.556557287718375
              ],
              [
                -60.92339113340188,
                -13.55545482256752
              ],
              [
                -60.920726147880686,
                -13.551376334112161
              ],
              [
                -60.91851867507313,
                -13.548035812701581
              ],
              [
                -60.91585421461559,
                -13.546224844305444
              ],
              [
                -60.91842419104935,
                -13.543701357614886
              ],
              [
                -60.92238617403126,
                -13.54039586690596
              ],
              [
                -60.93321608794208,
                -13.540248896015
              ],
              [
                -60.94377750471715,
                -13.547334893041798
              ],
              [
                -60.946857001552544,
                -13.549239378943374
              ],
              [
                -60.950317977643735,
                -13.551379382713407
              ],
              [
                -60.95733343605508,
                -13.548410383024304
              ],
              [
                -60.95927044307161,
                -13.545951433748519
              ],
              [
                -60.96676890390329,
                -13.536433951865604
              ],
              [
                -60.97149287711735,
                -13.536506981406706
              ],
              [
                -60.978244332655954,
                -13.53971899280168
              ],
              [
                -60.98529530760136,
                -13.538261995215763
              ],
              [
                -60.98553277696216,
                -13.540217006010867
              ],
              [
                -60.986015281502716,
                -13.544188967374831
              ],
              [
                -60.99414222324023,
                -13.546835997878667
              ],
              [
                -60.99902867918017,
                -13.54752649633922
              ],
              [
                -61.00568663122792,
                -13.546629505737204
              ],
              [
                -61.01041665618353,
                -13.529345091596786
              ],
              [
                -61.00805217098392,
                -13.522907091020155
              ],
              [
                -61.00551668451006,
                -13.52534059016681
              ],
              [
                -61.00019823049101,
                -13.53044606400357
              ],
              [
                -60.997392231978324,
                -13.527643079203052
              ],
              [
                -61.0060541914543,
                -13.51793461744418
              ],
              [
                -61.006222221219325,
                -13.51713012196137
              ],
              [
                -61.007342707321975,
                -13.511763657423558
              ],
              [
                -61.00696973473063,
                -13.508431642772083
              ],
              [
                -61.00692322589614,
                -13.50801964629826
              ],
              [
                -61.00410774260255,
                -13.502828668900442
              ],
              [
                -60.99889429434913,
                -13.499467199323004
              ],
              [
                -60.992677798605385,
                -13.504572149296918
              ],
              [
                -60.988457837611826,
                -13.503029655615652
              ],
              [
                -60.98768335400053,
                -13.499864174488035
              ],
              [
                -60.99434230952128,
                -13.496433677832693
              ],
              [
                -61.00035029500086,
                -13.493857196456073
              ],
              [
                -61.005788773697525,
                -13.491333729685948
              ],
              [
                -61.009756229812794,
                -13.489353758719806
              ],
              [
                -61.0142752203515,
                -13.487077276064621
              ],
              [
                -61.01922617069565,
                -13.488344274342978
              ],
              [
                -61.02489065355556,
                -13.489759776519602
              ],
              [
                -61.02770911377706,
                -13.488986260413911
              ],
              [
                -61.03324408627913,
                -13.487226818826166
              ],
              [
                -61.040498584633674,
                -13.484598307225248
              ],
              [
                -61.04629051864957,
                -13.48422285771867
              ],
              [
                -61.05030100090026,
                -13.488538318057243
              ],
              [
                -61.05039097695118,
                -13.492886311136765
              ],
              [
                -61.03494456801058,
                -13.496613247402825
              ],
              [
                -61.03484105221149,
                -13.499566266565067
              ],
              [
                -61.03480655430165,
                -13.500539763662086
              ],
              [
                -61.03801352594445,
                -13.504522222651763
              ],
              [
                -61.04047100875487,
                -13.507216716156757
              ],
              [
                -61.053416447039936,
                -13.51098274325102
              ],
              [
                -61.05966540475198,
                -13.511366241734287
              ],
              [
                -61.062009380036805,
                -13.507240283824054
              ],
              [
                -61.06541637425075,
                -13.50028882022868
              ],
              [
                -61.07510031246715,
                -13.49905034821425
              ],
              [
                -61.07707880609576,
                -13.498052843244292
              ],
              [
                -61.0897672470068,
                -13.491656386730261
              ],
              [
                -61.097094212344096,
                -13.495328398526368
              ],
              [
                -61.09544619377179,
                -13.501400340259822
              ],
              [
                -61.09449418270256,
                -13.504909332504019
              ],
              [
                -61.10012366613445,
                -13.512167827719177
              ],
              [
                -61.10271861274434,
                -13.515513797988305
              ],
              [
                -61.10246662076394,
                -13.517560794432859
              ],
              [
                -61.10223009697196,
                -13.519479303414014
              ],
              [
                -61.101299132563405,
                -13.52308228134216
              ],
              [
                -61.10027761780176,
                -13.528008749891212
              ],
              [
                -61.10545458078302,
                -13.531158743320145
              ],
              [
                -61.111427540373995,
                -13.527762301077791
              ],
              [
                -61.11946901794304,
                -13.522976834583748
              ],
              [
                -61.12534998130575,
                -13.519476822546702
              ],
              [
                -61.1297499444934,
                -13.522324329547914
              ],
              [
                -61.131112931639194,
                -13.523206328930085
              ],
              [
                -61.13581439711024,
                -13.526496325832849
              ],
              [
                -61.13864190222285,
                -13.52715231715344
              ],
              [
                -61.13871941244441,
                -13.522706350729091
              ],
              [
                -61.138610404132294,
                -13.521762351199946
              ],
              [
                -61.13803242535129,
                -13.516770883551336
              ],
              [
                -61.13767742775283,
                -13.512016378442125
              ],
              [
                -61.1434218992631,
                -13.512961409693764
              ],
              [
                -61.14669384503353,
                -13.518566897547672
              ],
              [
                -61.145669868962294,
                -13.521799379755969
              ],
              [
                -61.14365986185783,
                -13.528143322726562
              ],
              [
                -61.146254359581945,
                -13.531439837225939
              ],
              [
                -61.152496303311814,
                -13.526820354316472
              ],
              [
                -61.1542147875412,
                -13.522119387299238
              ],
              [
                -61.15589029276317,
                -13.517535412734833
              ],
              [
                -61.17063824302794,
                -13.510152446337404
              ],
              [
                -61.17987867530714,
                -13.508307017791578
              ],
              [
                -61.183352148971295,
                -13.50745202236604
              ],
              [
                -61.18711463667407,
                -13.516384972258463
              ],
              [
                -61.18447813664082,
                -13.522065963551075
              ],
              [
                -61.18449061298496,
                -13.523252954935655
              ],
              [
                -61.184551599761335,
                -13.529164425950198
              ],
              [
                -61.18757309841516,
                -13.531359413669048
              ],
              [
                -61.19104654408919,
                -13.534175424906325
              ],
              [
                -61.193572053562356,
                -13.535940921274957
              ],
              [
                -61.197559507067005,
                -13.53287441657229
              ],
              [
                -61.20739899499949,
                -13.525017985779927
              ],
              [
                -61.21418544828299,
                -13.527446977440698
              ],
              [
                -61.220790372235264,
                -13.529596474820757
              ],
              [
                -61.232828836347366,
                -13.524536530674036
              ],
              [
                -61.23671733844818,
                -13.51641756446686
              ],
              [
                -61.23991331567352,
                -13.514681601901982
              ],
              [
                -61.2429358039235,
                -13.51247860079722
              ],
              [
                -61.242530779566366,
                -13.509586631240536
              ],
              [
                -61.241676333811114,
                -13.503478123306097
              ],
              [
                -61.24171932764135,
                -13.500221653109582
              ],
              [
                -61.24769078656478,
                -13.495814190745449
              ],
              [
                -61.261703186681025,
                -13.497003180268079
              ],
              [
                -61.26816367614905,
                -13.501169674464306
              ],
              [
                -61.27054265853471,
                -13.502924710573716
              ],
              [
                -61.2707786447866,
                -13.503099184384794
              ],
              [
                -61.27449810386851,
                -13.505557675545667
              ],
              [
                -61.27972060642593,
                -13.505171191931412
              ],
              [
                -61.28489055916104,
                -13.501627236513972
              ],
              [
                -61.28536907576385,
                -13.498235223732138
              ],
              [
                -61.28761007424497,
                -13.482372329243606
              ],
              [
                -61.2956610654516,
                -13.47736735650294
              ],
              [
                -61.30347649740161,
                -13.483244837846303
              ],
              [
                -61.30316547839389,
                -13.486379313983988
              ],
              [
                -61.30164349626291,
                -13.49279778580617
              ],
              [
                -61.30046848230111,
                -13.497751268060538
              ],
              [
                -61.304093437074705,
                -13.50061176946767
              ],
              [
                -61.30810894279023,
                -13.503378280058978
              ],
              [
                -61.312365909114234,
                -13.50384428011439
              ],
              [
                -61.322947328326876,
                -13.503154275849443
              ],
              [
                -61.32748281970532,
                -13.502858791450665
              ],
              [
                -61.335707768133936,
                -13.505132795746112
              ],
              [
                -61.33728471800981,
                -13.507886286324391
              ],
              [
                -61.338528705889715,
                -13.511063281563882
              ],
              [
                -61.338686212101216,
                -13.511465787327312
              ],
              [
                -61.33989870619851,
                -13.514625787569992
              ],
              [
                -61.34154470054712,
                -13.517894788295933
              ],
              [
                -61.34980812366081,
                -13.520673755740171
              ],
              [
                -61.359293573323214,
                -13.520420802520858
              ],
              [
                -61.37322098166301,
                -13.520004803208524
              ],
              [
                -61.37670999892069,
                -13.519804828268342
              ],
              [
                -61.380351941312384,
                -13.51959734967661
              ],
              [
                -61.38334395338345,
                -13.519426849584617
              ],
              [
                -61.389136416148915,
                -13.519309852131798
              ],
              [
                -61.40017882922928,
                -13.523477844025102
              ],
              [
                -61.40418130291833,
                -13.528142864300786
              ],
              [
                -61.403491298580164,
                -13.53149883785058
              ],
              [
                -61.403480788230596,
                -13.532602812549678
              ],
              [
                -61.403428795550326,
                -13.538088814179899
              ],
              [
                -61.40866824820761,
                -13.53811832802147
              ],
              [
                -61.412472222789575,
                -13.537403315746001
              ],
              [
                -61.41713821242206,
                -13.539421827685402
              ],
              [
                -61.428861612680564,
                -13.54492332591126
              ],
              [
                -61.435128554283374,
                -13.545932328121623
              ],
              [
                -61.45392896212563,
                -13.548960859763794
              ],
              [
                -61.469336873727315,
                -13.554668375976721
              ],
              [
                -61.47434033693128,
                -13.552809889637688
              ],
              [
                -61.47762532782951,
                -13.5515093905421
              ],
              [
                -61.48741977683786,
                -13.547628434044299
              ],
              [
                -61.48952825554353,
                -13.546776952747871
              ],
              [
                -61.49145726012597,
                -13.545997961795281
              ],
              [
                -61.49418623566095,
                -13.546332439219208
              ],
              [
                -61.49915717867368,
                -13.546940959281605
              ],
              [
                -61.5064481486574,
                -13.547394956801723
              ],
              [
                -61.51270961543953,
                -13.542886984309034
              ],
              [
                -61.52103056278187,
                -13.53498605921166
              ],
              [
                -61.52517704563918,
                -13.534189559963568
              ],
              [
                -61.52987553754742,
                -13.531917068562956
              ],
              [
                -61.532703045125494,
                -13.529236075820247
              ],
              [
                -61.53463652599621,
                -13.527404108931384
              ],
              [
                -61.53792450803469,
                -13.526045107051864
              ],
              [
                -61.53943398378188,
                -13.525387134747332
              ],
              [
                -61.545636468986565,
                -13.52268214315338
              ],
              [
                -61.55229494575876,
                -13.519434185602988
              ],
              [
                -61.555723900693565,
                -13.517755191246854
              ],
              [
                -61.56330486256892,
                -13.520732197631057
              ],
              [
                -61.5690868343002,
                -13.517988720296577
              ],
              [
                -61.575490811663705,
                -13.513320241675224
              ],
              [
                -61.577518817289054,
                -13.508608779065737
              ],
              [
                -61.57723830526104,
                -13.50707028440662
              ],
              [
                -61.57684832718055,
                -13.504927787846357
              ],
              [
                -61.574386854346315,
                -13.49850729544572
              ],
              [
                -61.572593373483244,
                -13.494003819059325
              ],
              [
                -61.576077363176964,
                -13.48816486484351
              ],
              [
                -61.583565787647494,
                -13.490215841570834
              ],
              [
                -61.584175773858625,
                -13.494013855208498
              ],
              [
                -61.58461679004661,
                -13.497349807275956
              ],
              [
                -61.59009374754145,
                -13.500566333328068
              ],
              [
                -61.59837368451361,
                -13.501223848098881
              ],
              [
                -61.601457686297934,
                -13.504655828332458
              ],
              [
                -61.607223633148955,
                -13.511073320691276
              ],
              [
                -61.615226567450925,
                -13.510943841928801
              ],
              [
                -61.61610755189178,
                -13.507967318220938
              ],
              [
                -61.61703607856137,
                -13.504760349499291
              ],
              [
                -61.61709305279749,
                -13.504502340610992
              ],
              [
                -61.61774909954497,
                -13.501517865464281
              ],
              [
                -61.618282588640376,
                -13.498695864436803
              ],
              [
                -61.62250155469452,
                -13.49627340857382
              ],
              [
                -61.6255590423243,
                -13.499393917959862
              ],
              [
                -61.62459402166859,
                -13.504064873977189
              ],
              [
                -61.624199021344836,
                -13.505709882824748
              ],
              [
                -61.622177011580085,
                -13.514122343245791
              ],
              [
                -61.62827996458132,
                -13.515013347590827
              ],
              [
                -61.64055492435612,
                -13.508489879346577
              ],
              [
                -61.644391900785834,
                -13.5064503952102
              ],
              [
                -61.644907419755164,
                -13.509229381610629
              ],
              [
                -61.645084904641905,
                -13.510187902299213
              ],
              [
                -61.645202881051844,
                -13.514723376121905
              ],
              [
                -61.650746867568394,
                -13.51822786226716
              ],
              [
                -61.657790832978016,
                -13.514645408769697
              ],
              [
                -61.6647007659962,
                -13.511139445693122
              ],
              [
                -61.668251250567046,
                -13.509337427041146
              ],
              [
                -61.66808676492192,
                -13.504572959987616
              ],
              [
                -61.679011694978044,
                -13.506991466562832
              ],
              [
                -61.68553016683245,
                -13.50871547159023
              ],
              [
                -61.694398610307076,
                -13.500792025541287
              ],
              [
                -61.693211624177586,
                -13.507625504848315
              ],
              [
                -61.69820058429518,
                -13.50958598777372
              ],
              [
                -61.70348653122532,
                -13.511312478221916
              ],
              [
                -61.70308706570439,
                -13.512597472460097
              ],
              [
                -61.70244355085936,
                -13.514669969168928
              ],
              [
                -61.706163039740595,
                -13.515951471047902
              ],
              [
                -61.70938649607614,
                -13.516954002958618
              ],
              [
                -61.71836195361675,
                -13.519712502196644
              ],
              [
                -61.72444790411834,
                -13.521830995838764
              ],
              [
                -61.72714889290342,
                -13.520800997442882
              ],
              [
                -61.733867347037155,
                -13.518239025329612
              ],
              [
                -61.73187535530761,
                -13.52241249018094
              ],
              [
                -61.73047735190201,
                -13.52534048504406
              ],
              [
                -61.735563325239134,
                -13.526043024019271
              ],
              [
                -61.74271026671932,
                -13.524947519196118
              ],
              [
                -61.743228295431564,
                -13.524638002466327
              ],
              [
                -61.75104726391481,
                -13.51996206174428
              ],
              [
                -61.75708023019615,
                -13.523703051087136
              ],
              [
                -61.75558421334784,
                -13.526629041762948
              ],
              [
                -61.75475169554428,
                -13.528257553790718
              ],
              [
                -61.752975202513504,
                -13.53108600825067
              ],
              [
                -61.7566756746446,
                -13.540067493687843
              ],
              [
                -61.76380611879355,
                -13.538721490800295
              ],
              [
                -61.76537613924125,
                -13.532596029499134
              ],
              [
                -61.76637314390008,
                -13.528324034914956
              ],
              [
                -61.76858212800597,
                -13.518854587737616
              ],
              [
                -61.77618059181398,
                -13.519187591395628
              ],
              [
                -61.77661058553433,
                -13.52230159039944
              ],
              [
                -61.77726708624154,
                -13.529849074945025
              ],
              [
                -61.77750058602903,
                -13.532530038195594
              ],
              [
                -61.78397101530193,
                -13.53650804158822
              ],
              [
                -61.789336487645464,
                -13.537409050414333
              ],
              [
                -61.795027441430406,
                -13.534527069361964
              ],
              [
                -61.79800445981819,
                -13.532974097538045
              ],
              [
                -61.79832894835038,
                -13.532797599892774
              ],
              [
                -61.805146394500035,
                -13.529096638324413
              ],
              [
                -61.81523086652519,
                -13.529256628881152
              ],
              [
                -61.813881858241494,
                -13.532900646152004
              ],
              [
                -61.813676849554184,
                -13.533292128228597
              ],
              [
                -61.812515335310316,
                -13.535517100304757
              ],
              [
                -61.810631861846645,
                -13.539722089314004
              ],
              [
                -61.817753835025414,
                -13.538869093526666
              ],
              [
                -61.820520330285454,
                -13.533370127300875
              ],
              [
                -61.82539378237949,
                -13.535680145729808
              ],
              [
                -61.83285324099449,
                -13.53877064762237
              ],
              [
                -61.84037617296002,
                -13.548369626873763
              ],
              [
                -61.84278316460189,
                -13.540908660343598
              ],
              [
                -61.838034192391305,
                -13.534222148834724
              ],
              [
                -61.83889372025095,
                -13.530225686865924
              ],
              [
                -61.839693725835076,
                -13.526508686708253
              ],
              [
                -61.84641918530687,
                -13.527025714857558
              ],
              [
                -61.849190165217436,
                -13.528973726255668
              ],
              [
                -61.85158814683208,
                -13.530659695870552
              ],
              [
                -61.852224101156544,
                -13.537971700365466
              ],
              [
                -61.856417080541505,
                -13.536005686659982
              ],
              [
                -61.861339077902514,
                -13.53352321545018
              ],
              [
                -61.86537404469902,
                -13.5304157247536
              ],
              [
                -61.867023564124125,
                -13.527299768077558
              ],
              [
                -61.86934755245136,
                -13.519736801744775
              ],
              [
                -61.87138905082917,
                -13.51288231612395
              ],
              [
                -61.87896803475267,
                -13.501856887996606
              ],
              [
                -61.8825565192692,
                -13.496841395615169
              ],
              [
                -61.882169020892995,
                -13.492538934186697
              ],
              [
                -61.88171254128293,
                -13.488978948788699
              ],
              [
                -61.880901064429125,
                -13.482795972641624
              ],
              [
                -61.880421568148364,
                -13.479142464557496
              ],
              [
                -61.88162058208032,
                -13.468261525530838
              ],
              [
                -61.882297593531305,
                -13.461705550935989
              ],
              [
                -61.88269208992881,
                -13.456367559110976
              ],
              [
                -61.8849706228123,
                -13.450465096376611
              ],
              [
                -61.886230131312146,
                -13.44005914069108
              ],
              [
                -61.89233611939395,
                -13.430470689004803
              ],
              [
                -61.898612558541494,
                -13.426376730710212
              ],
              [
                -61.90261903400699,
                -13.427621201319663
              ],
              [
                -61.90560850675878,
                -13.43185521941219
              ],
              [
                -61.90681651115251,
                -13.433565687412205
              ],
              [
                -61.91369546160267,
                -13.433275718871482
              ],
              [
                -61.9177334613172,
                -13.431112711539765
              ],
              [
                -61.923035412374304,
                -13.42824374953306
              ],
              [
                -61.92653692031463,
                -13.426004273369669
              ],
              [
                -61.92894991518753,
                -13.420210787475247
              ],
              [
                -61.93030441455373,
                -13.416958318692092
              ],
              [
                -61.93475889253288,
                -13.409448344592654
              ],
              [
                -61.93970385180707,
                -13.40741737763414
              ],
              [
                -61.94486582539244,
                -13.412495376046348
              ],
              [
                -61.94727629951906,
                -13.414866861039297
              ],
              [
                -61.9517772984394,
                -13.41094686334862
              ],
              [
                -61.955822255340486,
                -13.407769390877357
              ],
              [
                -61.96048825380368,
                -13.407308896353689
              ],
              [
                -61.96629371561732,
                -13.406468413650668
              ],
              [
                -61.96977168541168,
                -13.405746425645985
              ],
              [
                -61.96578573622489,
                -13.39831695762328
              ],
              [
                -61.96169428705827,
                -13.390689967149717
              ],
              [
                -61.96691223676253,
                -13.386372524452728
              ],
              [
                -61.96986624488346,
                -13.384108026223489
              ],
              [
                -61.974386195048965,
                -13.386475539612798
              ],
              [
                -61.97654219934122,
                -13.387605037799295
              ],
              [
                -61.97544970018301,
                -13.385011054401861
              ],
              [
                -61.97072475338846,
                -13.373790547309087
              ],
              [
                -61.976112230340384,
                -13.368452088844853
              ],
              [
                -61.97995270094855,
                -13.365022099891826
              ],
              [
                -61.98590416107927,
                -13.364583621928201
              ],
              [
                -61.9935566168745,
                -13.363535164821682
              ],
              [
                -61.99478664560635,
                -13.363664642884599
              ],
              [
                -61.99667062847516,
                -13.363862660045575
              ],
              [
                -61.99372060817718,
                -13.370345628234842
              ],
              [
                -61.99603660939842,
                -13.373859593675487
              ],
              [
                -61.99989408074765,
                -13.370512135435899
              ],
              [
                -62.0080345731753,
                -13.359599193886082
              ],
              [
                -62.00984256283118,
                -13.357327723286787
              ],
              [
                -62.01163254238972,
                -13.354686720055959
              ],
              [
                -62.01352854666954,
                -13.351896243613618
              ],
              [
                -62.013663048138234,
                -13.348094752361856
              ],
              [
                -62.013572056229506,
                -13.345981775215934
              ],
              [
                -62.01342755333355,
                -13.342616773726077
              ],
              [
                -62.012987583883955,
                -13.333347805106703
              ],
              [
                -62.012977089229416,
                -13.329978313917707
              ],
              [
                -62.016556563717366,
                -13.326829324331365
              ],
              [
                -62.02436305078974,
                -13.327116354420623
              ],
              [
                -62.028287985354694,
                -13.330428831948243
              ],
              [
                -62.029000473190415,
                -13.330947343452108
              ],
              [
                -62.03199249150369,
                -13.33312483737022
              ],
              [
                -62.03511897272156,
                -13.32944587485415
              ],
              [
                -62.03699743875986,
                -13.326811879772524
              ],
              [
                -62.04558594185825,
                -13.315792456506726
              ],
              [
                -62.05153190851788,
                -13.313302468299648
              ],
              [
                -62.05625736601078,
                -13.311317981638467
              ],
              [
                -62.056348890716556,
                -13.304266493658178
              ],
              [
                -62.056406400937654,
                -13.299842527613102
              ],
              [
                -62.05620539929101,
                -13.294180557748046
              ],
              [
                -62.05630641959269,
                -13.287745571455062
              ],
              [
                -62.065433853941045,
                -13.292807591064596
              ],
              [
                -62.069612330480446,
                -13.295023591277822
              ],
              [
                -62.06981380610681,
                -13.295131063285858
              ],
              [
                -62.075987809209764,
                -13.292308601796947
              ],
              [
                -62.07952577545484,
                -13.290783097918757
              ],
              [
                -62.08358925150633,
                -13.289822634977687
              ],
              [
                -62.08413826500975,
                -13.284900146834673
              ],
              [
                -62.084502754995285,
                -13.281629174030089
              ],
              [
                -62.089107242397155,
                -13.281405676399793
              ],
              [
                -62.09213771355022,
                -13.281259174222507
              ],
              [
                -62.09384520650951,
                -13.278647206685235
              ],
              [
                -62.098085700109515,
                -13.272793204711386
              ],
              [
                -62.10064771471833,
                -13.269489740567737
              ],
              [
                -62.104761699699395,
                -13.266577251141838
              ],
              [
                -62.11098863164666,
                -13.261377282774996
              ],
              [
                -62.11503364900646,
                -13.25800730094032
              ],
              [
                -62.111497163666925,
                -13.253057837462324
              ],
              [
                -62.1095136933983,
                -13.250032339877627
              ],
              [
                -62.10928170647237,
                -13.249678351745583
              ],
              [
                -62.10909220733846,
                -13.245122346687559
              ],
              [
                -62.10915320819673,
                -13.23990835365268
              ],
              [
                -62.10878721240771,
                -13.233790396181728
              ],
              [
                -62.10880122400109,
                -13.22835291090837
              ],
              [
                -62.108420262121335,
                -13.218580937330556
              ],
              [
                -62.11145525287201,
                -13.21348546898112
              ],
              [
                -62.11281225607754,
                -13.211206981450351
              ],
              [
                -62.11322973832928,
                -13.207654002840915
              ],
              [
                -62.11393323693228,
                -13.203180538326947
              ],
              [
                -62.11342279409561,
                -13.191841552478962
              ],
              [
                -62.11323029734371,
                -13.18756059139827
              ],
              [
                -62.11401627669998,
                -13.185315092570734
              ],
              [
                -62.11703428305956,
                -13.176693633628721
              ],
              [
                -62.11657227863524,
                -13.173435647101188
              ],
              [
                -62.11605379937707,
                -13.170716659404395
              ],
              [
                -62.115541812000764,
                -13.168035180579235
              ],
              [
                -62.114514330897876,
                -13.16331219348478
              ],
              [
                -62.116241312135415,
                -13.15909270511878
              ],
              [
                -62.11905581304782,
                -13.153078734826199
              ],
              [
                -62.123729318712876,
                -13.150220766301144
              ],
              [
                -62.128005281491966,
                -13.14789576992662
              ],
              [
                -62.13410574381931,
                -13.14891878951973
              ],
              [
                -62.13812070774388,
                -13.154721250624918
              ],
              [
                -62.14226067056882,
                -13.160705762304335
              ],
              [
                -62.148659616152656,
                -13.161759762409236
              ],
              [
                -62.14998910383241,
                -13.158859256183682
              ],
              [
                -62.15025562482124,
                -13.153942296461318
              ],
              [
                -62.15062963835173,
                -13.15035979387714
              ],
              [
                -62.1594475967848,
                -13.146021333470328
              ],
              [
                -62.16118110278311,
                -13.143774849578724
              ],
              [
                -62.16892158543873,
                -13.13374740162563
              ],
              [
                -62.16813306377386,
                -13.129578412895178
              ],
              [
                -62.166565085333495,
                -13.123142946211244
              ],
              [
                -62.16605710511924,
                -13.118013957722022
              ],
              [
                -62.169835110455445,
                -13.113468474770938
              ],
              [
                -62.17378610259165,
                -13.11278900143507
              ],
              [
                -62.17756206059038,
                -13.11452001955537
              ],
              [
                -62.18106850486269,
                -13.118717509730965
              ],
              [
                -62.1822009991726,
                -13.122614963200677
              ],
              [
                -62.18275749311354,
                -13.126332952178753
              ],
              [
                -62.17957748856171,
                -13.132202448319397
              ],
              [
                -62.17711699921804,
                -13.136535902781059
              ],
              [
                -62.17712750079956,
                -13.13670442416087
              ],
              [
                -62.177501000260634,
                -13.142534395728203
              ],
              [
                -62.18091948639474,
                -13.145612370014199
              ],
              [
                -62.190321398879455,
                -13.153316354969617
              ],
              [
                -62.19373337846504,
                -13.15245287524889
              ],
              [
                -62.198119374440815,
                -13.150237899664024
              ],
              [
                -62.199883886819144,
                -13.143519934830321
              ],
              [
                -62.200025894248654,
                -13.140129955015878
              ],
              [
                -62.200262370251494,
                -13.127774478508899
              ],
              [
                -62.20033342376211,
                -13.124060502788517
              ],
              [
                -62.205118398503394,
                -13.116200064492597
              ],
              [
                -62.21408585428137,
                -13.110863069138123
              ],
              [
                -62.21837084291551,
                -13.111897574961594
              ],
              [
                -62.22239329138337,
                -13.113237073089957
              ],
              [
                -62.227314269428476,
                -13.118502592128577
              ],
              [
                -62.229415720791714,
                -13.120751578876744
              ],
              [
                -62.23133771267889,
                -13.11947358466776
              ],
              [
                -62.231979219969894,
                -13.119047605599619
              ],
              [
                -62.23511819749811,
                -13.116595583342578
              ],
              [
                -62.23939719572116,
                -13.115027116057266
              ],
              [
                -62.24188067907943,
                -13.119556588923158
              ],
              [
                -62.24377815277521,
                -13.125945091533005
              ],
              [
                -62.24412661653604,
                -13.130296545716504
              ],
              [
                -62.24527762040132,
                -13.14466353051704
              ],
              [
                -62.24874756057982,
                -13.144789013154357
              ],
              [
                -62.25422306130154,
                -13.144989023621159
              ],
              [
                -62.25900051582238,
                -13.145722009635424
              ],
              [
                -62.263119500237856,
                -13.14636955462511
              ],
              [
                -62.26378449026815,
                -13.14647453059058
              ],
              [
                -62.263573974855966,
                -13.146754523096735
              ],
              [
                -62.26095051416522,
                -13.150251499450654
              ],
              [
                -62.263938984053304,
                -13.152722495208986
              ],
              [
                -62.26793095495908,
                -13.147858519844954
              ],
              [
                -62.27175844420662,
                -13.146078065447092
              ],
              [
                -62.274152434265936,
                -13.14723005898873
              ],
              [
                -62.275028402434415,
                -13.147651067189123
              ],
              [
                -62.27365743041532,
                -13.151274028640586
              ],
              [
                -62.271402430646155,
                -13.155236997989004
              ],
              [
                -62.27789086556311,
                -13.15502504710855
              ],
              [
                -62.2814513788136,
                -13.149270047227414
              ],
              [
                -62.283170356439605,
                -13.146319085938886
              ],
              [
                -62.286769372370784,
                -13.14014161473545
              ],
              [
                -62.29011685964603,
                -13.143834099604511
              ],
              [
                -62.291371337047046,
                -13.145491110898288
              ],
              [
                -62.29386832095081,
                -13.148788561527006
              ],
              [
                -62.29870126495739,
                -13.150097601484989
              ],
              [
                -62.30086878305292,
                -13.1466846068595
              ],
              [
                -62.30216026766403,
                -13.14440110115815
              ],
              [
                -62.30238527324803,
                -13.144003140127122
              ],
              [
                -62.305336773768,
                -13.14066664081844
              ],
              [
                -62.31589869778713,
                -13.13390819207078
              ],
              [
                -62.317033697022985,
                -13.13689769907728
              ],
              [
                -62.317974204668445,
                -13.140675168704036
              ],
              [
                -62.31849366306368,
                -13.142868156467644
              ],
              [
                -62.31930217621862,
                -13.146284624194443
              ],
              [
                -62.32193313161824,
                -13.150873152228952
              ],
              [
                -62.32569764439727,
                -13.148558664713315
              ],
              [
                -62.32873710437328,
                -13.141975659859055
              ],
              [
                -62.329844101273565,
                -13.139576694207706
              ],
              [
                -62.33208658916716,
                -13.141683695835138
              ],
              [
                -62.340291538578384,
                -13.14939165951825
              ],
              [
                -62.34171302013933,
                -13.145391200563497
              ],
              [
                -62.34172804314569,
                -13.145348681755088
              ],
              [
                -62.33919803614945,
                -13.142641189803072
              ],
              [
                -62.3378345609283,
                -13.141182699522481
              ],
              [
                -62.336757568443225,
                -13.140030195672894
              ],
              [
                -62.34489603885981,
                -13.13668022303784
              ],
              [
                -62.3477560246159,
                -13.139102721742807
              ],
              [
                -62.35010798124257,
                -13.14011323874696
              ],
              [
                -62.35254649030466,
                -13.141160710789947
              ],
              [
                -62.35654294359704,
                -13.141280728536646
              ],
              [
                -62.35996091909377,
                -13.140018730319888
              ],
              [
                -62.36309389948261,
                -13.139089270260824
              ],
              [
                -62.36685039116389,
                -13.139661263807131
              ],
              [
                -62.37138737752819,
                -13.138331293141391
              ],
              [
                -62.37569933516782,
                -13.136997282028023
              ],
              [
                -62.37843485269235,
                -13.133775294810766
              ],
              [
                -62.38183931389189,
                -13.130351832725198
              ],
              [
                -62.391394786844025,
                -13.134293839439406
              ],
              [
                -62.39667273821352,
                -13.12961836591743
              ],
              [
                -62.396248739486644,
                -13.124046860083302
              ],
              [
                -62.39582876461093,
                -13.118531910048498
              ],
              [
                -62.39548079330115,
                -13.11396641457927
              ],
              [
                -62.39868377751075,
                -13.112944418000998
              ],
              [
                -62.40410273868964,
                -13.118768399112453
              ],
              [
                -62.40724069389308,
                -13.12214039281578
              ],
              [
                -62.410390191586906,
                -13.125251913530555
              ],
              [
                -62.4125706473967,
                -13.127107394001065
              ],
              [
                -62.41466314924607,
                -13.124960398393101
              ],
              [
                -62.416589650595995,
                -13.120672413522147
              ],
              [
                -62.41747816465743,
                -13.116902928567177
              ],
              [
                -62.433783099775326,
                -13.100715060049387
              ],
              [
                -62.43385661155544,
                -13.097809038164964
              ],
              [
                -62.43348711759613,
                -13.093598062299773
              ],
              [
                -62.43315562148923,
                -13.089824565863717
              ],
              [
                -62.43275661123303,
                -13.085358087907766
              ],
              [
                -62.43851010127696,
                -13.08052560891478
              ],
              [
                -62.44222559372841,
                -13.079459636534363
              ],
              [
                -62.44770853182385,
                -13.084248132675299
              ],
              [
                -62.45117601495675,
                -13.087275642346526
              ],
              [
                -62.45675696501448,
                -13.086188657863365
              ],
              [
                -62.45782047907436,
                -13.079603693874885
              ],
              [
                -62.45697600688522,
                -13.078155199612265
              ],
              [
                -62.455230493033454,
                -13.075159185346985
              ],
              [
                -62.45209402579433,
                -13.070364681029538
              ],
              [
                -62.45331103684984,
                -13.06430924137078
              ],
              [
                -62.45603205703054,
                -13.063171223223122
              ],
              [
                -62.46037550635633,
                -13.067101739261684
              ],
              [
                -62.463278989635356,
                -13.073434213304841
              ],
              [
                -62.463525977372,
                -13.073848206248107
              ],
              [
                -62.46541595805476,
                -13.07701469016915
              ],
              [
                -62.471255928428114,
                -13.078393718909991
              ],
              [
                -62.471818420053815,
                -13.074739726836631
              ],
              [
                -62.47199192399976,
                -13.072898230134838
              ],
              [
                -62.472144930597324,
                -13.071274241571205
              ],
              [
                -62.47292792239656,
                -13.059898290075324
              ],
              [
                -62.478288897564696,
                -13.056993811977085
              ],
              [
                -62.48058690853619,
                -13.058956298267947
              ],
              [
                -62.48275338183986,
                -13.061579291801692
              ],
              [
                -62.482727350579644,
                -13.071177274565585
              ],
              [
                -62.48373785691155,
                -13.07306425651906
              ],
              [
                -62.485795340220655,
                -13.076908238239836
              ],
              [
                -62.49644424530165,
                -13.08082873725161
              ],
              [
                -62.50171821300489,
                -13.082612738324233
              ],
              [
                -62.50642672404944,
                -13.079148249000363
              ],
              [
                -62.51212316892641,
                -13.074577784857187
              ],
              [
                -62.51339765712588,
                -13.073485288576029
              ],
              [
                -62.51744664487489,
                -13.070014841918502
              ],
              [
                -62.520412159185824,
                -13.067247329397988
              ],
              [
                -62.52316014248898,
                -13.065055338265283
              ],
              [
                -62.53071508011669,
                -13.07029784898334
              ],
              [
                -62.534339045515274,
                -13.072812859934288
              ],
              [
                -62.54587098860856,
                -13.073510367900088
              ],
              [
                -62.55739392602158,
                -13.065163926839736
              ],
              [
                -62.55741096394236,
                -13.065156430902725
              ],
              [
                -62.56391489358721,
                -13.06235695072101
              ],
              [
                -62.56763940830451,
                -13.060707940531378
              ],
              [
                -62.58097030884667,
                -13.05462499663064
              ],
              [
                -62.588418308293626,
                -13.051333522211927
              ],
              [
                -62.590314277432384,
                -13.050527039114321
              ],
              [
                -62.59293777717387,
                -13.049410032668785
              ],
              [
                -62.598775240856,
                -13.04694607484547
              ],
              [
                -62.60893670235264,
                -13.0421685915345
              ],
              [
                -62.611914672352306,
                -13.039532610509637
              ],
              [
                -62.61274720491837,
                -13.035668627712889
              ],
              [
                -62.61311167342963,
                -13.03211613479251
              ],
              [
                -62.61345717137189,
                -13.026053168260564
              ],
              [
                -62.61038720307217,
                -13.023890195121247
              ],
              [
                -62.59981078183371,
                -13.016439206550578
              ],
              [
                -62.60142279921839,
                -13.01201721345447
              ],
              [
                -62.60645326667308,
                -13.011310214360584
              ],
              [
                -62.6172291914843,
                -13.01561722114072
              ],
              [
                -62.62117766513712,
                -13.017194733265798
              ],
              [
                -62.62621963712772,
                -13.015269234773317
              ],
              [
                -62.63042414450544,
                -13.010174280469943
              ],
              [
                -62.6293601440956,
                -13.001837307605921
              ],
              [
                -62.62853316742571,
                -12.99535580926581
              ],
              [
                -62.6308171605429,
                -12.989761345929319
              ],
              [
                -62.63426465191363,
                -12.989018353734656
              ],
              [
                -62.63825063437981,
                -12.988704393023747
              ],
              [
                -62.640212103750855,
                -12.989510876986083
              ],
              [
                -62.646598076636955,
                -12.99213687212239
              ],
              [
                -62.64945405986524,
                -12.987350916900848
              ],
              [
                -62.64943855173414,
                -12.986508395032255
              ],
              [
                -62.649387068988155,
                -12.983687431518527
              ],
              [
                -62.64594510277551,
                -12.9767704414943
              ],
              [
                -62.644567132061795,
                -12.974113933671685
              ],
              [
                -62.64481510515552,
                -12.967262979913999
              ],
              [
                -62.65017559382545,
                -12.965127470826284
              ],
              [
                -62.658377569826904,
                -12.968133475198824
              ],
              [
                -62.661744520120074,
                -12.971432490654388
              ],
              [
                -62.66389897757786,
                -12.980208956092515
              ],
              [
                -62.666685956765356,
                -12.98318994857581
              ],
              [
                -62.674324924838295,
                -12.990480451775252
              ],
              [
                -62.681326377496354,
                -12.991140443554258
              ],
              [
                -62.68851530682569,
                -12.991466968967254
              ],
              [
                -62.69365229461487,
                -12.991772945280717
              ],
              [
                -62.6956547859857,
                -12.996728435794495
              ],
              [
                -62.69790625665946,
                -13.002301425509424
              ],
              [
                -62.706660720395156,
                -13.000225957121456
              ],
              [
                -62.712505167278266,
                -13.003828473549337
              ],
              [
                -62.71681616140849,
                -13.002844955017784
              ],
              [
                -62.717906618877414,
                -13.002595985172235
              ],
              [
                -62.7248891139876,
                -13.002170469998035
              ],
              [
                -62.7271145778582,
                -13.005833454835017
              ],
              [
                -62.72726156552847,
                -13.006075454229736
              ],
              [
                -62.727899048767256,
                -13.013720946941547
              ],
              [
                -62.729096023163,
                -13.020294924809395
              ],
              [
                -62.733317520397144,
                -13.02138642034857
              ],
              [
                -62.73849100804345,
                -13.014614483215354
              ],
              [
                -62.742111473339286,
                -13.010185474153928
              ],
              [
                -62.74356799403212,
                -13.008404010782591
              ],
              [
                -62.74314901252454,
                -12.99983851194221
              ],
              [
                -62.74702645474708,
                -12.999013535930287
              ],
              [
                -62.751454447840814,
                -13.001525530570312
              ],
              [
                -62.75313844617127,
                -13.006863512061162
              ],
              [
                -62.75373092018938,
                -13.009075499006904
              ],
              [
                -62.753947892264094,
                -13.009886508420294
              ],
              [
                -62.75520542093439,
                -13.013820510293533
              ],
              [
                -62.75876989299848,
                -13.015305498023954
              ],
              [
                -62.76276136260668,
                -13.014477007264864
              ],
              [
                -62.76685081823315,
                -13.012914032446881
              ],
              [
                -62.77243781034509,
                -13.010978528074308
              ],
              [
                -62.77839675629259,
                -13.00909306127194
              ],
              [
                -62.78672074227295,
                -13.001485110643204
              ],
              [
                -62.79370520480649,
                -12.994762161970725
              ],
              [
                -62.80622669984358,
                -12.96764428167172
              ],
              [
                -62.80685319026381,
                -12.96580478781241
              ],
              [
                -62.80825768420442,
                -12.961680804063285
              ],
              [
                -62.807002696848066,
                -12.957570809854419
              ],
              [
                -62.80602470808476,
                -12.954345311909448
              ],
              [
                -62.8044037269658,
                -12.949660346110818
              ],
              [
                -62.802653254296494,
                -12.944626861356344
              ],
              [
                -62.80531727194193,
                -12.940171399412257
              ],
              [
                -62.80956823731413,
                -12.938312395989174
              ],
              [
                -62.82234716407964,
                -12.933314937598809
              ],
              [
                -62.831363594689556,
                -12.937325924931931
              ],
              [
                -62.83145809389087,
                -12.937456431636805
              ],
              [
                -62.83823706753214,
                -12.946846402373566
              ],
              [
                -62.84520302797945,
                -12.948264404244437
              ],
              [
                -62.84709549985649,
                -12.945258935298051
              ],
              [
                -62.8495070091893,
                -12.940677447282818
              ],
              [
                -62.84674052034861,
                -12.935064959596051
              ],
              [
                -62.8431130815122,
                -12.927914520909523
              ],
              [
                -62.84248057264115,
                -12.92666850327575
              ],
              [
                -62.85456800880599,
                -12.917160573278792
              ],
              [
                -62.860202980633964,
                -12.912416115408103
              ],
              [
                -62.862806480553104,
                -12.911790109022457
              ],
              [
                -62.86956543260174,
                -12.91016361979348
              ],
              [
                -62.871676938429154,
                -12.905652623899464
              ],
              [
                -62.87461543308496,
                -12.894774170434951
              ],
              [
                -62.8798989217527,
                -12.886752720060466
              ],
              [
                -62.88481642682154,
                -12.882830241647339
              ],
              [
                -62.888241903302564,
                -12.880256286181266
              ],
              [
                -62.89123839674817,
                -12.874544814550887
              ],
              [
                -62.892315388667065,
                -12.870322797481395
              ],
              [
                -62.89192090221056,
                -12.865863821322996
              ],
              [
                -62.891716896177904,
                -12.863558328514893
              ],
              [
                -62.89142242558915,
                -12.858419877099438
              ],
              [
                -62.89591690595096,
                -12.856421883635385
              ],
              [
                -62.900588399167155,
                -12.85450537822283
              ],
              [
                -62.90434138150999,
                -12.853041398265006
              ],
              [
                -62.90632733850559,
                -12.852105414767879
              ],
              [
                -62.909258838213795,
                -12.850724419839997
              ],
              [
                -62.9099563423892,
                -12.849757931854336
              ],
              [
                -62.91519732467004,
                -12.842499457515615
              ],
              [
                -62.92065427398584,
                -12.840380987430862
              ],
              [
                -62.931291209781826,
                -12.841573013449965
              ],
              [
                -62.93664220579198,
                -12.842271525645492
              ],
              [
                -62.94107714946526,
                -12.846496483850864
              ],
              [
                -62.941099669056825,
                -12.846632004391292
              ],
              [
                -62.942346649394715,
                -12.854154967878404
              ],
              [
                -62.94772109289934,
                -12.85646497213315
              ],
              [
                -62.94966511224157,
                -12.852622511372463
              ],
              [
                -62.950905101617735,
                -12.849660509909908
              ],
              [
                -62.9509566005928,
                -12.84953701347393
              ],
              [
                -62.95584409366626,
                -12.847617538494443
              ],
              [
                -62.95879205245518,
                -12.846643026077306
              ],
              [
                -62.962501022421314,
                -12.846921540530776
              ],
              [
                -62.96427001915593,
                -12.849549048321633
              ],
              [
                -62.97023747865466,
                -12.858412029395282
              ],
              [
                -62.976252935844656,
                -12.858723520397776
              ],
              [
                -62.97865842244304,
                -12.856411530860683
              ],
              [
                -62.98140192387537,
                -12.85329153401124
              ],
              [
                -62.982121934979574,
                -12.84896957054324
              ],
              [
                -62.98214044111159,
                -12.84885808099538
              ],
              [
                -62.982733926759025,
                -12.843654591553003
              ],
              [
                -62.99007188530895,
                -12.841758606425428
              ],
              [
                -62.99078489676712,
                -12.841574614549694
              ],
              [
                -62.99467685688132,
                -12.840834612161508
              ],
              [
                -62.99951285427292,
                -12.839868654519407
              ],
              [
                -63.00758730367936,
                -12.837070654226501
              ],
              [
                -63.01039527856383,
                -12.834045202142843
              ],
              [
                -63.012502786873696,
                -12.829316699975518
              ],
              [
                -63.01187731276295,
                -12.82433121625048
              ],
              [
                -63.01042632168943,
                -12.819108232748249
              ],
              [
                -63.00987732725837,
                -12.81741424894216
              ],
              [
                -63.00880932703722,
                -12.814118249050344
              ],
              [
                -63.00787485407817,
                -12.810344763206706
              ],
              [
                -63.009115858005906,
                -12.807591789950722
              ],
              [
                -63.01714982617719,
                -12.806448299389896
              ],
              [
                -63.01754031114321,
                -12.806393301381146
              ],
              [
                -63.017939791333816,
                -12.80620729487782
              ],
              [
                -63.02062080218908,
                -12.80495980791895
              ],
              [
                -63.02040428941508,
                -12.800161331332745
              ],
              [
                -63.02035677689368,
                -12.79910084318238
              ],
              [
                -63.020295824048674,
                -12.789137869131697
              ],
              [
                -63.029331768328866,
                -12.782915402463564
              ],
              [
                -63.03318226668588,
                -12.780262929076372
              ],
              [
                -63.03497627888829,
                -12.774958939418784
              ],
              [
                -63.0374347539007,
                -12.767991979285656
              ],
              [
                -63.038896749499244,
                -12.763849010641373
              ],
              [
                -63.03892075782467,
                -12.756912060510976
              ],
              [
                -63.04069977771478,
                -12.749387553095302
              ],
              [
                -63.04657275948118,
                -12.740232624962388
              ],
              [
                -63.05336473155376,
                -12.730421659108227
              ],
              [
                -63.05836520504077,
                -12.72899418044185
              ],
              [
                -63.05999672283221,
                -12.729111186274917
              ],
              [
                -63.066064186590275,
                -12.729546203812214
              ],
              [
                -63.07304863618783,
                -12.72811970511526
              ],
              [
                -63.07647260597411,
                -12.727353737825453
              ],
              [
                -63.079526122035325,
                -12.724067747168371
              ],
              [
                -63.08061112869373,
                -12.720402750977724
              ],
              [
                -63.08058513848768,
                -12.71479429279572
              ],
              [
                -63.07987266386715,
                -12.704453819859626
              ],
              [
                -63.07739266558261,
                -12.701997328046135
              ],
              [
                -63.07328269867094,
                -12.699819841937538
              ],
              [
                -63.067875214312465,
                -12.696955334798853
              ],
              [
                -63.06267277052455,
                -12.693755841324721
              ],
              [
                -63.06152976736054,
                -12.685282879624776
              ],
              [
                -63.067042759640415,
                -12.682113401447236
              ],
              [
                -63.069949250985374,
                -12.682929884206583
              ],
              [
                -63.07702070419658,
                -12.685321407415032
              ],
              [
                -63.086992140069654,
                -12.684056417040813
              ],
              [
                -63.08746565259456,
                -12.683996418209944
              ],
              [
                -63.09503961570178,
                -12.68318542135575
              ],
              [
                -63.0965591160134,
                -12.680457432547046
              ],
              [
                -63.09824109962531,
                -12.676670469005504
              ],
              [
                -63.095935100098835,
                -12.673859953868902
              ],
              [
                -63.09175214660524,
                -12.668762491510229
              ],
              [
                -63.08997468710031,
                -12.661636491517699
              ],
              [
                -63.09276614837797,
                -12.657477511581426
              ],
              [
                -63.09614064803477,
                -12.656425030229062
              ],
              [
                -63.10224012862254,
                -12.654946536893753
              ],
              [
                -63.11131455833171,
                -12.657612071052338
              ],
              [
                -63.11435602122825,
                -12.65829955455581
              ],
              [
                -63.11559601329999,
                -12.657877574599015
              ],
              [
                -63.117317004072156,
                -12.657292062209882
              ],
              [
                -63.12113299285335,
                -12.65501509929349
              ],
              [
                -63.12176350914483,
                -12.654169098699604
              ],
              [
                -63.1273454810147,
                -12.646680622699904
              ],
              [
                -63.133571445084684,
                -12.647068133660946
              ],
              [
                -63.135099932510414,
                -12.647163165352497
              ],
              [
                -63.13905042991124,
                -12.64471765132328
              ],
              [
                -63.13874893913859,
                -12.643955188560042
              ],
              [
                -63.13742542831091,
                -12.640605186391499
              ],
              [
                -63.14178893711293,
                -12.637353199183265
              ],
              [
                -63.147566901248396,
                -12.635030736329064
              ],
              [
                -63.15123887149516,
                -12.63340123470183
              ],
              [
                -63.15224288159116,
                -12.628751238002147
              ],
              [
                -63.15379440824099,
                -12.618981789446234
              ],
              [
                -63.15443338396098,
                -12.615964311948332
              ],
              [
                -63.1571703911997,
                -12.61342684183405
              ],
              [
                -63.16158085297963,
                -12.614914843062879
              ],
              [
                -63.16613679602257,
                -12.623164796428721
              ],
              [
                -63.16595529674934,
                -12.627172282162098
              ],
              [
                -63.164655297010576,
                -12.630010791018242
              ],
              [
                -63.162710802082884,
                -12.63561874429225
              ],
              [
                -63.16192831859512,
                -12.637930230138707
              ],
              [
                -63.16160582239214,
                -12.638883726950946
              ],
              [
                -63.16468126674517,
                -12.638789225427487
              ],
              [
                -63.16809125843574,
                -12.639581758273009
              ],
              [
                -63.172923755240426,
                -12.639454242372848
              ],
              [
                -63.17450323834105,
                -12.637639762262518
              ],
              [
                -63.17679573622871,
                -12.635006761613758
              ],
              [
                -63.18444969185046,
                -12.626037330185406
              ],
              [
                -63.19238266419609,
                -12.623248362335564
              ],
              [
                -63.19530813737748,
                -12.624611832091507
              ],
              [
                -63.196594132041994,
                -12.630086844314336
              ],
              [
                -63.1918926579979,
                -12.637237786392857
              ],
              [
                -63.189646146231446,
                -12.640654774227041
              ],
              [
                -63.19108911426503,
                -12.645527751007236
              ],
              [
                -63.192185120939726,
                -12.651006731851131
              ],
              [
                -63.195372569358426,
                -12.653008739776887
              ],
              [
                -63.201658051020644,
                -12.656368233849156
              ],
              [
                -63.215976459062524,
                -12.656197252052564
              ],
              [
                -63.21861293871056,
                -12.656166249428441
              ],
              [
                -63.221461906748964,
                -12.658284777260489
              ],
              [
                -63.226596885830645,
                -12.662557251677073
              ],
              [
                -63.2291268568933,
                -12.664057765169236
              ],
              [
                -63.234732350057335,
                -12.662704780374716
              ],
              [
                -63.23958482874611,
                -12.661156281799203
              ],
              [
                -63.24347529649264,
                -12.662119787999627
              ],
              [
                -63.244593288210886,
                -12.665582793410513
              ],
              [
                -63.239670794944594,
                -12.670970234630413
              ],
              [
                -63.2395407769813,
                -12.672260255594601
              ],
              [
                -63.23906530381173,
                -12.676971721202763
              ],
              [
                -63.23091182066112,
                -12.68261967999165
              ],
              [
                -63.235221311429854,
                -12.688728186001278
              ],
              [
                -63.23890425271199,
                -12.689493672538005
              ],
              [
                -63.243262235952905,
                -12.689948190577255
              ],
              [
                -63.25409218525734,
                -12.685865725348334
              ],
              [
                -63.2623251591254,
                -12.683053729217908
              ],
              [
                -63.2670111032355,
                -12.68271674783816
              ],
              [
                -63.27664156199865,
                -12.682551786715168
              ],
              [
                -63.290866480234875,
                -12.681649816508662
              ],
              [
                -63.294904986710456,
                -12.681403801497888
              ],
              [
                -63.32178232602871,
                -12.67181488765785
              ],
              [
                -63.32566033248048,
                -12.670565916021493
              ],
              [
                -63.32969381130347,
                -12.668248444636314
              ],
              [
                -63.333137279641825,
                -12.666269445821206
              ],
              [
                -63.33861524143753,
                -12.661135449707638
              ],
              [
                -63.34965769741306,
                -12.656248508796525
              ],
              [
                -63.353022675179936,
                -12.656686007907597
              ],
              [
                -63.358473645597925,
                -12.657602039424427
              ],
              [
                -63.360497120875515,
                -12.657942004215643
              ],
              [
                -63.36719010265413,
                -12.659284517647109
              ],
              [
                -63.373083568367605,
                -12.656972542943988
              ],
              [
                -63.37685153922911,
                -12.655347559459996
              ],
              [
                -63.38034953391808,
                -12.652585597622386
              ],
              [
                -63.38432451286556,
                -12.649412094251465
              ],
              [
                -63.39320848722593,
                -12.6418376428873
              ],
              [
                -63.400307466204936,
                -12.635757692445948
              ],
              [
                -63.401563456418586,
                -12.632354213483852
              ],
              [
                -63.402903445641485,
                -12.628724708521707
              ],
              [
                -63.40774744041939,
                -12.614642292042253
              ],
              [
                -63.40929445929784,
                -12.610630281902564
              ],
              [
                -63.420738908582315,
                -12.606522329830014
              ],
              [
                -63.42282888270443,
                -12.60677484806332
              ],
              [
                -63.42613737790425,
                -12.60717383918505
              ],
              [
                -63.433586317528594,
                -12.608098378622278
              ],
              [
                -63.438907313215275,
                -12.605877382395963
              ],
              [
                -63.439416304810116,
                -12.602797893741235
              ],
              [
                -63.434251824167326,
                -12.598773393268957
              ],
              [
                -63.42676940355446,
                -12.596960400048426
              ],
              [
                -63.42600738807609,
                -12.59677538121294
              ],
              [
                -63.42672188687179,
                -12.592084397911066
              ],
              [
                -63.42998986261007,
                -12.5889369360051
              ],
              [
                -63.436527844081034,
                -12.582639958199522
              ],
              [
                -63.4366138839981,
                -12.578085981142928
              ],
              [
                -63.43615638358958,
                -12.575121503153246
              ],
              [
                -63.43596237941453,
                -12.574261477935883
              ],
              [
                -63.43532288173196,
                -12.571423486120635
              ],
              [
                -63.434101921845496,
                -12.56584552824879
              ],
              [
                -63.44008589718424,
                -12.563010534577812
              ],
              [
                -63.45572380014646,
                -12.561137562526579
              ],
              [
                -63.464208745132446,
                -12.562094593851763
              ],
              [
                -63.466757734828136,
                -12.561839100854176
              ],
              [
                -63.46856669138741,
                -12.561657598114605
              ],
              [
                -63.47355418736014,
                -12.561157595859301
              ],
              [
                -63.47944065573284,
                -12.562469104725142
              ],
              [
                -63.48950810852249,
                -12.564712110475307
              ],
              [
                -63.49564954463157,
                -12.565972623044201
              ],
              [
                -63.50094002817401,
                -12.566837137380436
              ],
              [
                -63.505227018102254,
                -12.563359166679248
              ],
              [
                -63.50831347496556,
                -12.560644684785203
              ],
              [
                -63.51319749069341,
                -12.554235702033921
              ],
              [
                -63.525924430789495,
                -12.544982267293914
              ],
              [
                -63.53008290543995,
                -12.541008814141307
              ],
              [
                -63.5336633876223,
                -12.535379837920834
              ],
              [
                -63.534885890087686,
                -12.532905321435242
              ],
              [
                -63.543167372672166,
                -12.516142918430324
              ],
              [
                -63.550958370134495,
                -12.507549979427564
              ],
              [
                -63.56019432719522,
                -12.503146512881942
              ],
              [
                -63.56481876817886,
                -12.502660514817196
              ],
              [
                -63.570929741446285,
                -12.502019017264532
              ],
              [
                -63.57552771417874,
                -12.500753552008444
              ],
              [
                -63.58098122737451,
                -12.496030566135628
              ],
              [
                -63.58600119114625,
                -12.49168309502241
              ],
              [
                -63.592999138406405,
                -12.489203612959717
              ],
              [
                -63.59698162064528,
                -12.488345636982006
              ],
              [
                -63.613344036482594,
                -12.484820158262822
              ],
              [
                -63.61756801731918,
                -12.478642711266312
              ],
              [
                -63.61852103335355,
                -12.477088720848544
              ],
              [
                -63.621392027433984,
                -12.472405736695086
              ],
              [
                -63.62495852322957,
                -12.471404225411993
              ],
              [
                -63.63279697774967,
                -12.469044237545546
              ],
              [
                -63.64793090924372,
                -12.464081818617537
              ],
              [
                -63.65615736241953,
                -12.461369837794921
              ],
              [
                -63.659638805959304,
                -12.461705843436837
              ],
              [
                -63.66019483685383,
                -12.461758329342326
              ],
              [
                -63.665808275499835,
                -12.462290837474633
              ],
              [
                -63.670637780920885,
                -12.461578866616959
              ],
              [
                -63.67375026553127,
                -12.460411365036371
              ],
              [
                -63.677590748494225,
                -12.458620399174396
              ],
              [
                -63.68134672659015,
                -12.455520379935674
              ],
              [
                -63.68451272786958,
                -12.452907927746038
              ],
              [
                -63.69185515414784,
                -12.450052465684262
              ],
              [
                -63.69557663300564,
                -12.449120961435197
              ],
              [
                -63.71944454073214,
                -12.442153019003127
              ],
              [
                -63.73308947644959,
                -12.438167062482796
              ],
              [
                -63.74036591198769,
                -12.438814066468595
              ],
              [
                -63.74065343038816,
                -12.438840094940536
              ],
              [
                -63.75631982204438,
                -12.440848614508432
              ],
              [
                -63.759910317947394,
                -12.441238086170559
              ],
              [
                -63.76366527297966,
                -12.441622117177708
              ],
              [
                -63.76804927490793,
                -12.438934615634068
              ],
              [
                -63.77330473932,
                -12.435677129886528
              ],
              [
                -63.77410971701373,
                -12.435178152294712
              ],
              [
                -63.77794574670864,
                -12.43258017933815
              ],
              [
                -63.782893699961335,
                -12.429233173304207
              ],
              [
                -63.78567067659795,
                -12.427492696127631
              ],
              [
                -63.78985067703318,
                -12.429019193801222
              ],
              [
                -63.79754059509779,
                -12.439616668337372
              ],
              [
                -63.80021556081985,
                -12.443490156503113
              ],
              [
                -63.80247556599757,
                -12.446762149045659
              ],
              [
                -63.81027703207672,
                -12.44873915925303
              ],
              [
                -63.812767494267334,
                -12.447977666944555
              ],
              [
                -63.81737748341095,
                -12.446567678182529
              ],
              [
                -63.821016461286455,
                -12.450282694848793
              ],
              [
                -63.825010431845946,
                -12.453709662270796
              ],
              [
                -63.828348921071985,
                -12.45657467344705
              ],
              [
                -63.83507536980502,
                -12.461549655655118
              ],
              [
                -63.840241813649,
                -12.461768175078488
              ],
              [
                -63.845818296003095,
                -12.46066620111254
              ],
              [
                -63.849642758046464,
                -12.459910186423382
              ],
              [
                -63.87241467624189,
                -12.446605279772314
              ],
              [
                -63.87932763970418,
                -12.445875807081674
              ],
              [
                -63.88291561303242,
                -12.447075796815911
              ],
              [
                -63.88832207402513,
                -12.449057289310353
              ],
              [
                -63.888557571101785,
                -12.45280028219396
              ],
              [
                -63.88880457476354,
                -12.457202793709744
              ],
              [
                -63.888670546361794,
                -12.461166245066845
              ],
              [
                -63.88872005009938,
                -12.465068736010156
              ],
              [
                -63.88872206370734,
                -12.465254759339425
              ],
              [
                -63.89582048052319,
                -12.475581220955538
              ],
              [
                -63.89891344620497,
                -12.479849721615224
              ],
              [
                -63.90008694759841,
                -12.481469222073118
              ],
              [
                -63.90002542903691,
                -12.48190318937894
              ],
              [
                -63.899241447922215,
                -12.48741217186711
              ],
              [
                -63.897975955548524,
                -12.493812639173614
              ],
              [
                -63.897170948706076,
                -12.499238615925595
              ],
              [
                -63.90002488947012,
                -12.505538612781077
              ],
              [
                -63.90648685813927,
                -12.50978510524645
              ],
              [
                -63.92044427546942,
                -12.51122911575421
              ],
              [
                -63.93734965845584,
                -12.524132629732447
              ],
              [
                -63.940353640572006,
                -12.525985106018815
              ],
              [
                -63.94937759490494,
                -12.528611621494582
              ],
              [
                -63.95626502443244,
                -12.530383134811508
              ],
              [
                -63.960651515334305,
                -12.529245131649798
              ],
              [
                -63.96482900412661,
                -12.527248656167774
              ],
              [
                -63.96891897845907,
                -12.525218186632436
              ],
              [
                -63.97526546766768,
                -12.519826681707007
              ],
              [
                -63.97574847037777,
                -12.519200679925639
              ],
              [
                -63.98199691768186,
                -12.511105726548136
              ],
              [
                -63.9899699112978,
                -12.504699791499721
              ],
              [
                -63.9951953663897,
                -12.502761816872619
              ],
              [
                -63.999833859012426,
                -12.502575826761214
              ],
              [
                -64.00204332709087,
                -12.510893287176033
              ],
              [
                -64.00293081746922,
                -12.514234756901125
              ],
              [
                -64.01008475362379,
                -12.514558269967104
              ],
              [
                -64.01677876232107,
                -12.50998982973561
              ],
              [
                -64.02140019695736,
                -12.509363804181135
              ],
              [
                -64.02690517692224,
                -12.512116815219999
              ],
              [
                -64.03258564411621,
                -12.514791309896832
              ],
              [
                -64.0392491107837,
                -12.514695825500258
              ],
              [
                -64.04310859001643,
                -12.508966885787546
              ],
              [
                -64.04573708644133,
                -12.504386872491304
              ],
              [
                -64.04637408433032,
                -12.503277876747552
              ],
              [
                -64.05028457950144,
                -12.496958439255934
              ],
              [
                -64.05570357391258,
                -12.492931966509653
              ],
              [
                -64.05936956238189,
                -12.490501483536057
              ],
              [
                -64.06570299624941,
                -12.492150468188868
              ],
              [
                -64.0681784923044,
                -12.493976979540756
              ],
              [
                -64.07170444713614,
                -12.49756594564769
              ],
              [
                -64.07235844728162,
                -12.498231974554098
              ],
              [
                -64.0751089346571,
                -12.500648467338703
              ],
              [
                -64.08320190135001,
                -12.500267460800956
              ],
              [
                -64.08553636100018,
                -12.498287477555195
              ],
              [
                -64.08819787033383,
                -12.495615984442592
              ],
              [
                -64.08833736834137,
                -12.494766022772877
              ],
              [
                -64.08986388743432,
                -12.485428551350655
              ],
              [
                -64.09364788433693,
                -12.484436038432923
              ],
              [
                -64.09817881891773,
                -12.48507157815044
              ],
              [
                -64.10183929020603,
                -12.492473530844672
              ],
              [
                -64.10235028459564,
                -12.492906523305885
              ],
              [
                -64.10534628442315,
                -12.495447512140952
              ],
              [
                -64.10829977907201,
                -12.497699538931718
              ],
              [
                -64.11443972425164,
                -12.501660009935256
              ],
              [
                -64.12210015867444,
                -12.504977041282174
              ],
              [
                -64.12226915933825,
                -12.501308055774185
              ],
              [
                -64.12197867363102,
                -12.495120565386454
              ],
              [
                -64.12197069972497,
                -12.491696565652141
              ],
              [
                -64.1219577111435,
                -12.48569761114744
              ],
              [
                -64.12464270889349,
                -12.482283118732798
              ],
              [
                -64.12920619956664,
                -12.476967657867931
              ],
              [
                -64.13442717739491,
                -12.47619914999307
              ],
              [
                -64.13900864629322,
                -12.478509638805509
              ],
              [
                -64.14300511836244,
                -12.482248659338733
              ],
              [
                -64.14448408609893,
                -12.491674126495019
              ],
              [
                -64.14535605901447,
                -12.497232602312275
              ],
              [
                -64.14655153783612,
                -12.502000071612125
              ],
              [
                -64.15182548212795,
                -12.509606565871735
              ],
              [
                -64.15793997675672,
                -12.51342954336613
              ],
              [
                -64.16291841299328,
                -12.513584565729477
              ],
              [
                -64.16816340947045,
                -12.510031587889614
              ],
              [
                -64.17221287961384,
                -12.506325599212712
              ],
              [
                -64.1732153799641,
                -12.503575141048623
              ],
              [
                -64.1726319088181,
                -12.500298627610077
              ],
              [
                -64.17178241251129,
                -12.496814149388321
              ],
              [
                -64.17033691210231,
                -12.490545659948816
              ],
              [
                -64.17073895313668,
                -12.484636689902393
              ],
              [
                -64.17110795466606,
                -12.480027189140566
              ],
              [
                -64.1721599871341,
                -12.46687477733651
              ],
              [
                -64.18347688996063,
                -12.4654667948449
              ],
              [
                -64.18717385574617,
                -12.469161268958153
              ],
              [
                -64.18952737742272,
                -12.47151227221721
              ],
              [
                -64.19615130247254,
                -12.47239079223086
              ],
              [
                -64.20008182831916,
                -12.469135304685276
              ],
              [
                -64.20466378541967,
                -12.46504933240175
              ],
              [
                -64.20481479689738,
                -12.464913842186307
              ],
              [
                -64.20820029471672,
                -12.462105846952666
              ],
              [
                -64.21223675174741,
                -12.461894852720249
              ],
              [
                -64.21909770542732,
                -12.461868853433659
              ],
              [
                -64.23116466826346,
                -12.455159425371578
              ],
              [
                -64.23736464013344,
                -12.460647406065865
              ],
              [
                -64.24115860003921,
                -12.46453539171075
              ],
              [
                -64.24295807271388,
                -12.466379416699022
              ],
              [
                -64.24497405566035,
                -12.468505895110399
              ],
              [
                -64.25131702138215,
                -12.47466839571713
              ],
              [
                -64.25385500491123,
                -12.4766258657774
              ],
              [
                -64.25794496134421,
                -12.479495361672594
              ],
              [
                -64.26613741405023,
                -12.48528087433714
              ],
              [
                -64.26914940962214,
                -12.48745635257575
              ],
              [
                -64.27250488326966,
                -12.492108354675509
              ],
              [
                -64.27855932484424,
                -12.500049328444543
              ],
              [
                -64.2840297881042,
                -12.500917850251845
              ],
              [
                -64.29080926062278,
                -12.500246377151692
              ],
              [
                -64.29537023809573,
                -12.494625911245201
              ],
              [
                -64.29725071250827,
                -12.491058915687725
              ],
              [
                -64.29726825589024,
                -12.48743441562574
              ],
              [
                -64.29436426271393,
                -12.479446444290646
              ],
              [
                -64.29314679472517,
                -12.476256462231229
              ],
              [
                -64.29238027891734,
                -12.474247446863663
              ],
              [
                -64.2901523246015,
                -12.468467979026885
              ],
              [
                -64.29032832044687,
                -12.460315027434664
              ],
              [
                -64.29304829864279,
                -12.458573519045029
              ],
              [
                -64.30218928168786,
                -12.457901033597839
              ],
              [
                -64.3225381436957,
                -12.45616810618969
              ],
              [
                -64.34854351851875,
                -12.459483113066206
              ],
              [
                -64.35309497030603,
                -12.458600630249316
              ],
              [
                -64.36031394124916,
                -12.457119654860008
              ],
              [
                -64.36161793742377,
                -12.456606147276387
              ],
              [
                -64.36963390661703,
                -12.45345069641746
              ],
              [
                -64.37958134389874,
                -12.452596189217855
              ],
              [
                -64.38163284744037,
                -12.452420204919111
              ],
              [
                -64.38321882638328,
                -12.451784706327123
              ],
              [
                -64.39091379715394,
                -12.44869871857237
              ],
              [
                -64.39610128598444,
                -12.448611255188409
              ],
              [
                -64.40023673632878,
                -12.44854126365271
              ],
              [
                -64.40495723491932,
                -12.446367777743284
              ],
              [
                -64.41552615529572,
                -12.44098579582933
              ],
              [
                -64.41810067270681,
                -12.438079843249763
              ],
              [
                -64.42330765065464,
                -12.431571356673002
              ],
              [
                -64.42668214678051,
                -12.428213374280515
              ],
              [
                -64.42797215286683,
                -12.425837409267796
              ],
              [
                -64.43074461675,
                -12.420731924877447
              ],
              [
                -64.4357846171124,
                -12.417370931792805
              ],
              [
                -64.43988958424988,
                -12.41554495140712
              ],
              [
                -64.44703505461888,
                -12.408731508732393
              ],
              [
                -64.4513865395733,
                -12.400999537773385
              ],
              [
                -64.4515040453803,
                -12.400792042742015
              ],
              [
                -64.45493806933032,
                -12.39443904607318
              ],
              [
                -64.4594095406624,
                -12.3866971083818
              ],
              [
                -64.4639670358958,
                -12.384643126682045
              ],
              [
                -64.47832795262737,
                -12.379149650562038
              ],
              [
                -64.47840294429136,
                -12.379121173811276
              ],
              [
                -64.48732343251882,
                -12.37319968843702
              ],
              [
                -64.48733139548816,
                -12.37319421447213
              ],
              [
                -64.49941786259728,
                -12.365558241417691
              ],
              [
                -64.50369133209743,
                -12.360201786839124
              ],
              [
                -64.51059381007946,
                -12.350296830563932
              ],
              [
                -64.51232431155847,
                -12.346590824111393
              ],
              [
                -64.51088933212337,
                -12.34216587596345
              ],
              [
                -64.50896587360454,
                -12.33903788425888
              ],
              [
                -64.50645687788725,
                -12.33555286466941
              ],
              [
                -64.5056938693183,
                -12.334854396642351
              ],
              [
                -64.50272589944844,
                -12.332135401436766
              ],
              [
                -64.49986490271904,
                -12.329562369927645
              ],
              [
                -64.49725394911378,
                -12.3169534175333
              ],
              [
                -64.49561947482141,
                -12.310013436956817
              ],
              [
                -64.49224355249508,
                -12.293808993881782
              ],
              [
                -64.49574407264618,
                -12.262488116096314
              ],
              [
                -64.49704706251893,
                -12.258185146953949
              ],
              [
                -64.49914909113963,
                -12.25045017258581
              ],
              [
                -64.499465058575,
                -12.249415177886526
              ],
              [
                -64.50119756095106,
                -12.24374422045377
              ],
              [
                -64.50526059170532,
                -12.229355275604737
              ],
              [
                -64.50647908477173,
                -12.22665080661199
              ],
              [
                -64.5121095428131,
                -12.222394826759926
              ],
              [
                -64.52303398507564,
                -12.225570334804722
              ],
              [
                -64.52586997768945,
                -12.22730683617088
              ],
              [
                -64.53477240441705,
                -12.233529797451006
              ],
              [
                -64.54089336093922,
                -12.237673318699692
              ],
              [
                -64.54725083560686,
                -12.241627320095029
              ],
              [
                -64.55262130709811,
                -12.239825308003521
              ],
              [
                -64.5557392825352,
                -12.237956314740343
              ],
              [
                -64.5591597874404,
                -12.234083368182027
              ],
              [
                -64.56220778894581,
                -12.229394868788603
              ],
              [
                -64.5653532784943,
                -12.223936391317872
              ],
              [
                -64.56569475590013,
                -12.223344886085435
              ],
              [
                -64.56799474089908,
                -12.219359943728962
              ],
              [
                -64.57514373259684,
                -12.214829950778757
              ],
              [
                -64.58267469119025,
                -12.214812967550372
              ],
              [
                -64.59392910648994,
                -12.215197482173293
              ],
              [
                -64.59703913228121,
                -12.215162507373762
              ],
              [
                -64.60078707988701,
                -12.215052515086875
              ],
              [
                -64.60693155109698,
                -12.214872028732728
              ],
              [
                -64.61353551145402,
                -12.213431515742498
              ],
              [
                -64.63807690746194,
                -12.205336126363653
              ],
              [
                -64.64498786998247,
                -12.202540644758885
              ],
              [
                -64.6529668154529,
                -12.199486136151922
              ],
              [
                -64.65626983275477,
                -12.198218165088692
              ],
              [
                -64.65711982800725,
                -12.19787468696377
              ],
              [
                -64.6640403015032,
                -12.195076688374986
              ],
              [
                -64.67114472428977,
                -12.192157712569028
              ],
              [
                -64.67219024821969,
                -12.191964736292162
              ],
              [
                -64.6768382226767,
                -12.191109752387446
              ],
              [
                -64.68587066890913,
                -12.189433272943111
              ],
              [
                -64.69366562140324,
                -12.187860271715303
              ],
              [
                -64.69738359394536,
                -12.186897799372131
              ],
              [
                -64.7037291071836,
                -12.18127230763268
              ],
              [
                -64.71184155217735,
                -12.173677363290727
              ],
              [
                -64.71336856762949,
                -12.167512368053071
              ],
              [
                -64.71570307331862,
                -12.15316744830575
              ],
              [
                -64.71643458749566,
                -12.147699959060988
              ],
              [
                -64.7171911029781,
                -12.142013000643443
              ],
              [
                -64.71470462442296,
                -12.130316542187327
              ],
              [
                -64.71417311920975,
                -12.12781655100146
              ],
              [
                -64.71301314060265,
                -12.123157045239873
              ],
              [
                -64.71120666429695,
                -12.120525576048074
              ],
              [
                -64.70421519513998,
                -12.11134910197931
              ],
              [
                -64.70154224982484,
                -12.107980591729971
              ],
              [
                -64.69804174692425,
                -12.103563108598763
              ],
              [
                -64.69732477422399,
                -12.099785107601058
              ],
              [
                -64.69798577197241,
                -12.094688147951274
              ],
              [
                -64.70796572952578,
                -12.086388696697641
              ],
              [
                -64.71236223452253,
                -12.085930198832957
              ],
              [
                -64.72164867658465,
                -12.088199706769146
              ],
              [
                -64.72954610580766,
                -12.093513208245808
              ],
              [
                -64.73551058413146,
                -12.097813205580968
              ],
              [
                -64.73709956346964,
                -12.101502679604524
              ],
              [
                -64.7363690528996,
                -12.105685650387686
              ],
              [
                -64.73635004977429,
                -12.10579764395461
              ],
              [
                -64.73502204031918,
                -12.110477136823373
              ],
              [
                -64.73368306790144,
                -12.116162620113199
              ],
              [
                -64.7359449971809,
                -12.138060545083173
              ],
              [
                -64.74182994799402,
                -12.151261521894346
              ],
              [
                -64.74721940887038,
                -12.15526448625278
              ],
              [
                -64.75166687282172,
                -12.157196012096053
              ],
              [
                -64.7571418512054,
                -12.155758017833703
              ],
              [
                -64.76106783787098,
                -12.153717020680704
              ],
              [
                -64.76839130227063,
                -12.143511588944127
              ],
              [
                -64.76755982818497,
                -12.140234095891572
              ],
              [
                -64.76515533637371,
                -12.133427085240966
              ],
              [
                -64.76216336922292,
                -12.125817621293692
              ],
              [
                -64.75915692225904,
                -12.11814764454649
              ],
              [
                -64.75689145067429,
                -12.11185166139589
              ],
              [
                -64.75754291963393,
                -12.10868219886146
              ],
              [
                -64.75818694343498,
                -12.106109681203286
              ],
              [
                -64.75925492641714,
                -12.101844737349998
              ],
              [
                -64.76195244216649,
                -12.098952706803079
              ],
              [
                -64.76508243012825,
                -12.095829231911136
              ],
              [
                -64.78435732136771,
                -12.08707281420322
              ],
              [
                -64.78786530859493,
                -12.08533732512932
              ],
              [
                -64.80148671292743,
                -12.087759826963735
              ],
              [
                -64.80651519881461,
                -12.091245840405223
              ],
              [
                -64.80883019432231,
                -12.09347434202962
              ],
              [
                -64.8122766422013,
                -12.099072815964053
              ],
              [
                -64.81302816784921,
                -12.100294322113843
              ],
              [
                -64.81722159999025,
                -12.107315312495723
              ],
              [
                -64.8229855580622,
                -12.116780287332961
              ],
              [
                -64.82590852254707,
                -12.121429759634449
              ],
              [
                -64.83109202413564,
                -12.121173789609589
              ],
              [
                -64.83511250987526,
                -12.11687830654708
              ],
              [
                -64.84093047038091,
                -12.105052860487223
              ],
              [
                -64.8416684846259,
                -12.100523875869944
              ],
              [
                -64.84120250722009,
                -12.093447381893375
              ],
              [
                -64.8351145641292,
                -12.085804402024873
              ],
              [
                -64.83290259246851,
                -12.082878916051913
              ],
              [
                -64.82955610049066,
                -12.079057444044373
              ],
              [
                -64.82647361228909,
                -12.077162415758536
              ],
              [
                -64.82006814079531,
                -12.074043914359052
              ],
              [
                -64.81916416147973,
                -12.073549405354184
              ],
              [
                -64.81324918567678,
                -12.07031345032799
              ],
              [
                -64.81004922447259,
                -12.067225456039456
              ],
              [
                -64.80879972384389,
                -12.063780932786793
              ],
              [
                -64.80892374535456,
                -12.058693471995866
              ],
              [
                -64.80841227210117,
                -12.05363696287051
              ],
              [
                -64.81245725902234,
                -12.04363703801726
              ],
              [
                -64.81600574738513,
                -12.03771606379058
              ],
              [
                -64.82103122614377,
                -12.029248078411985
              ],
              [
                -64.82372273496206,
                -12.024325615999002
              ],
              [
                -64.8261707052259,
                -12.019813635664727
              ],
              [
                -64.82974573636348,
                -12.016947170685365
              ],
              [
                -64.83794565983511,
                -12.010890706886174
              ],
              [
                -64.84380813435314,
                -12.009408716414208
              ],
              [
                -64.84847813699207,
                -12.00846621673817
              ],
              [
                -64.85631607651578,
                -12.011389220513772
              ],
              [
                -64.85759006396232,
                -12.011864241291153
              ],
              [
                -64.8611035614288,
                -12.012019221513619
              ],
              [
                -64.87626848446139,
                -12.012686238382363
              ],
              [
                -64.88229392112869,
                -12.013237761049822
              ],
              [
                -64.88582789543932,
                -12.014430251656814
              ],
              [
                -64.88912789655329,
                -12.015829249086504
              ],
              [
                -64.8904623873701,
                -12.016466258414015
              ],
              [
                -64.89412587790575,
                -12.018215269313986
              ],
              [
                -64.89989581092516,
                -12.020770261440068
              ],
              [
                -64.90585977473214,
                -12.023431285573285
              ],
              [
                -64.91770322182582,
                -12.026228291581834
              ],
              [
                -64.92237820381584,
                -12.024624316248685
              ],
              [
                -64.92601667665339,
                -12.022277827526121
              ],
              [
                -64.92933867369254,
                -12.020151318417337
              ],
              [
                -64.93556265822097,
                -12.008725369619869
              ],
              [
                -64.93692613314397,
                -12.006222397054714
              ],
              [
                -64.94095314480388,
                -11.998931415468487
              ],
              [
                -64.94700812280534,
                -11.993735961410474
              ],
              [
                -64.95249559495552,
                -11.99447496237728
              ],
              [
                -64.95741607420494,
                -11.996138475002786
              ],
              [
                -64.9631755078073,
                -11.999468484389547
              ],
              [
                -64.96557501469741,
                -12.004348938859962
              ],
              [
                -64.96751400482471,
                -12.008294439733945
              ],
              [
                -64.97044346477983,
                -12.016283430320819
              ],
              [
                -64.97445543815958,
                -12.018233416215006
              ],
              [
                -64.97887889912147,
                -12.01631393549039
              ],
              [
                -64.98356738566464,
                -12.013918446946095
              ],
              [
                -64.98785335918676,
                -12.008560493021381
              ],
              [
                -64.98937537238776,
                -12.006518481124438
              ],
              [
                -64.9939788598951,
                -12.000342511678475
              ],
              [
                -64.99995632678085,
                -11.992921062247463
              ],
              [
                -65.00809129125707,
                -11.99268056676256
              ],
              [
                -65.01317825176261,
                -11.994811062088672
              ],
              [
                -65.01464873871026,
                -11.995546581342177
              ],
              [
                -65.01781722520832,
                -11.997130083894653
              ],
              [
                -65.02400671605453,
                -11.99673457721074
              ],
              [
                -65.0284321854392,
                -11.996122104887359
              ],
              [
                -65.03139366332506,
                -11.994531091171757
              ],
              [
                -65.03256065182843,
                -11.9917531251577
              ],
              [
                -65.03468316029394,
                -11.985507161141076
              ],
              [
                -65.03234669642403,
                -11.981440636899926
              ],
              [
                -65.02827522817257,
                -11.975525185810694
              ],
              [
                -65.02704725073626,
                -11.973394668922255
              ],
              [
                -65.02601173663966,
                -11.97160016622644
              ],
              [
                -65.02771724915605,
                -11.96640071275065
              ],
              [
                -65.02820522579346,
                -11.966322720962543
              ],
              [
                -65.03144820708741,
                -11.96580720700643
              ],
              [
                -65.03483819826307,
                -11.967490715618537
              ],
              [
                -65.03947668965108,
                -11.96783874131776
              ],
              [
                -65.04052867640075,
                -11.960691732407762
              ],
              [
                -65.03698220760788,
                -11.95619226037853
              ],
              [
                -65.02996175648548,
                -11.948787293885998
              ],
              [
                -65.02977374789587,
                -11.948564763306308
              ],
              [
                -65.02712927774682,
                -11.945428284243272
              ],
              [
                -65.02560930205425,
                -11.940645289125234
              ],
              [
                -65.01707939468699,
                -11.919081853561757
              ],
              [
                -65.01377244661712,
                -11.903439909258209
              ],
              [
                -65.01450993963073,
                -11.899981434839333
              ],
              [
                -65.01513442671234,
                -11.894245954219572
              ],
              [
                -65.0182589487434,
                -11.891631461392548
              ],
              [
                -65.02527637478518,
                -11.888971504160562
              ],
              [
                -65.03142134799329,
                -11.886992009864018
              ],
              [
                -65.03870181445697,
                -11.885975534346327
              ],
              [
                -65.03985380581257,
                -11.885826022072646
              ],
              [
                -65.05318623649289,
                -11.884101074603977
              ],
              [
                -65.05765173500458,
                -11.882821046632332
              ],
              [
                -65.06352570609174,
                -11.876548586893284
              ],
              [
                -65.06694121157865,
                -11.872442624842718
              ],
              [
                -65.07009719777115,
                -11.868657156168501
              ],
              [
                -65.07029119242668,
                -11.863238680582567
              ],
              [
                -65.07017520946623,
                -11.855889706196212
              ],
              [
                -65.06073832693596,
                -11.823840284003467
              ],
              [
                -65.05968485599227,
                -11.80358836334245
              ],
              [
                -65.05926588154703,
                -11.794174423727528
              ],
              [
                -65.0591553897456,
                -11.79026793728993
              ],
              [
                -65.06030340391938,
                -11.786638435262295
              ],
              [
                -65.07131489259163,
                -11.757212057883773
              ],
              [
                -65.07200939673025,
                -11.755304593925654
              ],
              [
                -65.07537640139189,
                -11.746058622908402
              ],
              [
                -65.07914737012085,
                -11.735702161573244
              ],
              [
                -65.0799599165967,
                -11.71991570483218
              ],
              [
                -65.08510040700583,
                -11.71225724055883
              ],
              [
                -65.08832836114635,
                -11.708778293921908
              ],
              [
                -65.09582933602117,
                -11.704932811621056
              ],
              [
                -65.10190181526401,
                -11.706238823443567
              ],
              [
                -65.10594577443808,
                -11.70831980271523
              ],
              [
                -65.10876625984255,
                -11.709916326394858
              ],
              [
                -65.11965118458578,
                -11.715746801342377
              ],
              [
                -65.12310218024243,
                -11.717606818290731
              ],
              [
                -65.12755364971264,
                -11.7200063012272
              ],
              [
                -65.1291731443177,
                -11.720044791145071
              ],
              [
                -65.1780973609104,
                -11.721207890323464
              ],
              [
                -65.1833383366194,
                -11.723992383425752
              ],
              [
                -65.18481883307277,
                -11.726541379263258
              ],
              [
                -65.18284282435903,
                -11.731606840736054
              ],
              [
                -65.18061832329008,
                -11.734423847638432
              ],
              [
                -65.17552534215585,
                -11.740725300878687
              ],
              [
                -65.1753033317625,
                -11.740998821498211
              ],
              [
                -65.17323684235774,
                -11.750761257298157
              ],
              [
                -65.17712732405417,
                -11.754530765418972
              ],
              [
                -65.18012230483887,
                -11.755480747749836
              ],
              [
                -65.1855852491778,
                -11.755809261932992
              ],
              [
                -65.20308571011438,
                -11.747768321253247
              ],
              [
                -65.21324363267826,
                -11.742827386791232
              ],
              [
                -65.21999613154006,
                -11.739510404187682
              ],
              [
                -65.22836009650416,
                -11.734472440657498
              ],
              [
                -65.23614404076768,
                -11.729783446799553
              ],
              [
                -65.24252153324568,
                -11.72509048429186
              ],
              [
                -65.24900499063327,
                -11.720167013785824
              ],
              [
                -65.25211199358255,
                -11.717672014392708
              ],
              [
                -65.2553529900616,
                -11.71506656063788
              ],
              [
                -65.26004794683324,
                -11.706421093786538
              ],
              [
                -65.25948548908022,
                -11.70262460803012
              ],
              [
                -65.25811399779812,
                -11.698655617356215
              ],
              [
                -65.25544202260757,
                -11.696006124609976
              ],
              [
                -65.2506775303267,
                -11.691704634719668
              ],
              [
                -65.24296558334716,
                -11.68471065573893
              ],
              [
                -65.23206919086532,
                -11.674515664035964
              ],
              [
                -65.23073916508405,
                -11.673270673908581
              ],
              [
                -65.22029875902524,
                -11.660094707598605
              ],
              [
                -65.21735930508464,
                -11.65180969521745
              ],
              [
                -65.21743630948681,
                -11.64466273050405
              ],
              [
                -65.21768932996723,
                -11.641150743335336
              ],
              [
                -65.22629530502424,
                -11.62656284679163
              ],
              [
                -65.2286612736374,
                -11.622552358334515
              ],
              [
                -65.23114829400502,
                -11.617827359617513
              ],
              [
                -65.22987928624094,
                -11.614325900567632
              ],
              [
                -65.2278383253246,
                -11.609837880550623
              ],
              [
                -65.22651581769229,
                -11.606927904324737
              ],
              [
                -65.22418785461356,
                -11.603285886800593
              ],
              [
                -65.22481135807854,
                -11.596700456163335
              ],
              [
                -65.22695334965418,
                -11.592254443826722
              ],
              [
                -65.22780633585356,
                -11.590918474125996
              ],
              [
                -65.2332793262873,
                -11.582351522523153
              ],
              [
                -65.23388084567516,
                -11.573767554893543
              ],
              [
                -65.23372035818903,
                -11.569755061736776
              ],
              [
                -65.23030386543131,
                -11.565707063642048
              ],
              [
                -65.22618738598942,
                -11.561275584602948
              ],
              [
                -65.22613140739185,
                -11.561215072712141
              ],
              [
                -65.21859397557314,
                -11.553068072426875
              ],
              [
                -65.21306650981185,
                -11.546827612174985
              ],
              [
                -65.21096852849114,
                -11.542153601780242
              ],
              [
                -65.21126153918692,
                -11.530011677216283
              ],
              [
                -65.21402755132183,
                -11.523569206114814
              ],
              [
                -65.21627152813413,
                -11.519742706800104
              ],
              [
                -65.22440997262332,
                -11.515995243138004
              ],
              [
                -65.25888333876173,
                -11.513648303368669
              ],
              [
                -65.26426180305221,
                -11.512188336495312
              ],
              [
                -65.26963976534896,
                -11.51072885061763
              ],
              [
                -65.28357571341499,
                -11.50534286944975
              ],
              [
                -65.29449764230984,
                -11.50393939264634
              ],
              [
                -65.29809564168261,
                -11.5030244275407
              ],
              [
                -65.30101259746283,
                -11.502146912039791
              ],
              [
                -65.3043820976576,
                -11.500889952713697
              ],
              [
                -65.30862607428658,
                -11.49286398084737
              ],
              [
                -65.31120108433977,
                -11.479101515099392
              ],
              [
                -65.3178525989448,
                -11.446699166609728
              ],
              [
                -65.32039413552378,
                -11.433222210831472
              ],
              [
                -65.32039461552891,
                -11.433218222846428
              ],
              [
                -65.32293763807891,
                -11.419736791098
              ],
              [
                -65.32463265567448,
                -11.40479934094157
              ],
              [
                -65.32518867234145,
                -11.399238864596532
              ],
              [
                -65.32560115391028,
                -11.394115356447315
              ],
              [
                -65.32613366064393,
                -11.387517390729213
              ],
              [
                -65.32614967780555,
                -11.387388408214205
              ],
              [
                -65.32657618309469,
                -11.383960914104938
              ],
              [
                -65.33103469507117,
                -11.368194496991022
              ],
              [
                -65.3348241955366,
                -11.354493020360813
              ],
              [
                -65.33359772070979,
                -11.351343541249555
              ],
              [
                -65.33107775188515,
                -11.344782579838471
              ],
              [
                -65.32870975211858,
                -11.340437062878113
              ],
              [
                -65.32264729333303,
                -11.338676073023525
              ],
              [
                -65.30464537547984,
                -11.335301565064793
              ],
              [
                -65.3001173921747,
                -11.334452558798022
              ],
              [
                -65.29400642855033,
                -11.33328403077311
              ],
              [
                -65.29079895727989,
                -11.33042653504224
              ],
              [
                -65.29121748773369,
                -11.323711593612671
              ],
              [
                -65.29254797365024,
                -11.318531609408248
              ],
              [
                -65.29795945151552,
                -11.315606607652605
              ],
              [
                -65.32778078320412,
                -11.31359166319647
              ],
              [
                -65.3338387610951,
                -11.313181671700617
              ],
              [
                -65.33697575939944,
                -11.311424697643357
              ],
              [
                -65.34276021524744,
                -11.306980732924028
              ],
              [
                -65.34563521638732,
                -11.303355264998435
              ],
              [
                -65.36067822576231,
                -11.250878989204642
              ],
              [
                -65.36064674864039,
                -11.245123477543796
              ],
              [
                -65.36061473451521,
                -11.23936803288585
              ],
              [
                -65.35932377384762,
                -11.222284569124467
              ],
              [
                -65.35232886283698,
                -11.21508059002549
              ],
              [
                -65.34824086237779,
                -11.210918080406616
              ],
              [
                -65.33982489682984,
                -11.210600074316725
              ],
              [
                -65.33836693935837,
                -11.210545075875704
              ],
              [
                -65.33136694525687,
                -11.210417068673324
              ],
              [
                -65.32305501790502,
                -11.210259062206845
              ],
              [
                -65.31893701486217,
                -11.2048830718754
              ],
              [
                -65.31850805847324,
                -11.198718585021822
              ],
              [
                -65.32085555621187,
                -11.18861914218226
              ],
              [
                -65.32418653224501,
                -11.18645364989542
              ],
              [
                -65.33133849947282,
                -11.185552147004808
              ],
              [
                -65.34258742949197,
                -11.189702164551356
              ],
              [
                -65.34284394101067,
                -11.189796653678561
              ],
              [
                -65.34572993560303,
                -11.188560165281451
              ],
              [
                -65.34973488242356,
                -11.186284216596126
              ],
              [
                -65.35436138754362,
                -11.172946770334217
              ],
              [
                -65.36290688630561,
                -11.146814882614132
              ],
              [
                -65.36262393380493,
                -11.141359369505508
              ],
              [
                -65.36206444479019,
                -11.13748638327494
              ],
              [
                -65.36045841908869,
                -11.134704413285508
              ],
              [
                -65.35484797549599,
                -11.126637938914566
              ],
              [
                -65.35294147992326,
                -11.124071420628644
              ],
              [
                -65.35089200318157,
                -11.121448426289744
              ],
              [
                -65.34782753425293,
                -11.119017451448865
              ],
              [
                -65.33514711570254,
                -11.109832445439627
              ],
              [
                -65.32811564929911,
                -11.105050468891235
              ],
              [
                -65.32040719557742,
                -11.102322480816493
              ],
              [
                -65.31922222738503,
                -11.10190295419151
              ],
              [
                -65.30918329299924,
                -11.098929962829725
              ],
              [
                -65.28736588557791,
                -11.0922004388321
              ],
              [
                -65.2839794037237,
                -11.08845094918023
              ],
              [
                -65.27921045197371,
                -11.07866648665014
              ],
              [
                -65.27963797416658,
                -11.070266531466615
              ],
              [
                -65.28026649700482,
                -11.063134030112659
              ],
              [
                -65.28224447496893,
                -11.057086589285142
              ],
              [
                -65.29374842900825,
                -11.04520314831885
              ],
              [
                -65.29989692525399,
                -11.03885217999723
              ],
              [
                -65.29970442166452,
                -11.030939694975965
              ],
              [
                -65.29529348633938,
                -11.025896731187071
              ],
              [
                -65.27499460886038,
                -11.005560242015143
              ],
              [
                -65.26941062950387,
                -10.999970774161678
              ],
              [
                -65.26866516885202,
                -10.999670747916227
              ],
              [
                -65.26300868784938,
                -10.997393258059988
              ],
              [
                -65.25282423491865,
                -10.992935782012976
              ],
              [
                -65.25087328254807,
                -10.986504812659438
              ],
              [
                -65.25111627375712,
                -10.983500783883619
              ],
              [
                -65.26635325231187,
                -10.96346540883339
              ],
              [
                -65.27074773871975,
                -10.951957456953137
              ],
              [
                -65.27228671578489,
                -10.94758045340194
              ],
              [
                -65.27266021479974,
                -10.946518469878338
              ],
              [
                -65.27362921393207,
                -10.943711001679798
              ],
              [
                -65.27742770288972,
                -10.938548011940053
              ],
              [
                -65.2798247055553,
                -10.935288008885843
              ],
              [
                -65.28204419218922,
                -10.931992557645044
              ],
              [
                -65.28372519917784,
                -10.926142092639262
              ],
              [
                -65.28307122451132,
                -10.918934607402155
              ],
              [
                -65.27759326243302,
                -10.919493108747528
              ],
              [
                -65.2740332476109,
                -10.92114309254334
              ],
              [
                -65.27196677142348,
                -10.92210004946151
              ],
              [
                -65.26612080535745,
                -10.921480060396817
              ],
              [
                -65.26521782221303,
                -10.915794096269973
              ],
              [
                -65.26796481920817,
                -10.894683188106319
              ],
              [
                -65.27257883496823,
                -10.875600264945078
              ],
              [
                -65.27534333248582,
                -10.870244258090404
              ],
              [
                -65.28764331527542,
                -10.857147831826856
              ],
              [
                -65.29195577747382,
                -10.852586381810715
              ],
              [
                -65.29698226366071,
                -10.84711689631176
              ],
              [
                -65.29765876353228,
                -10.846380901146782
              ],
              [
                -65.30336323958556,
                -10.840173943484684
              ],
              [
                -65.30569973635308,
                -10.837565464966605
              ],
              [
                -65.31657671352991,
                -10.825508024406071
              ],
              [
                -65.32019018107036,
                -10.821627019695423
              ],
              [
                -65.32589467441355,
                -10.817497047528795
              ],
              [
                -65.32986215240322,
                -10.814596563898672
              ],
              [
                -65.32994114410482,
                -10.814538569245352
              ],
              [
                -65.33344664811264,
                -10.811825096133417
              ],
              [
                -65.33886708919886,
                -10.807532112046802
              ],
              [
                -65.34342907740309,
                -10.806742618845565
              ],
              [
                -65.34777354282399,
                -10.805893143480107
              ],
              [
                -65.35285703454393,
                -10.804453159527716
              ],
              [
                -65.35684149434132,
                -10.802740664637922
              ],
              [
                -65.36177400911761,
                -10.799460681039065
              ],
              [
                -65.36403000512976,
                -10.79582720104426
              ],
              [
                -65.36616797393295,
                -10.792085716231565
              ],
              [
                -65.36599347466078,
                -10.788807737722962
              ],
              [
                -65.36546350263212,
                -10.784160237526052
              ],
              [
                -65.36485353079439,
                -10.779631755764951
              ],
              [
                -65.35895353739828,
                -10.779847235723077
              ],
              [
                -65.35349207356917,
                -10.78018026002102
              ],
              [
                -65.35111906437602,
                -10.780325224370012
              ],
              [
                -65.34758161851194,
                -10.777993724614833
              ],
              [
                -65.34352065338307,
                -10.768873758419398
              ],
              [
                -65.34456365753218,
                -10.758488315671958
              ],
              [
                -65.34664164786871,
                -10.751220355486657
              ],
              [
                -65.34898964649757,
                -10.74300888017429
              ],
              [
                -65.34597117718077,
                -10.729275418492685
              ],
              [
                -65.34295272183363,
                -10.71554048785254
              ],
              [
                -65.34357826009892,
                -10.698875536128487
              ],
              [
                -65.34405876553622,
                -10.69044358842668
              ],
              [
                -65.3455452905,
                -10.683343096653976
              ],
              [
                -65.3484822620724,
                -10.671706139783995
              ],
              [
                -65.35423477433577,
                -10.662331189585482
              ],
              [
                -65.3577497619603,
                -10.656937714135609
              ],
              [
                -65.36775472332654,
                -10.650569248368235
              ],
              [
                -65.37512368374523,
                -10.646104776174804
              ],
              [
                -65.37923314339352,
                -10.645811285299377
              ],
              [
                -65.3820061365144,
                -10.64569729047796
              ],
              [
                -65.39355907170908,
                -10.645219826062535
              ],
              [
                -65.39674406103563,
                -10.644940857545283
              ],
              [
                -65.40121803238554,
                -10.642634352564171
              ],
              [
                -65.40355804657548,
                -10.640890866880854
              ],
              [
                -65.40521655878278,
                -10.633132401744344
              ],
              [
                -65.4102375113821,
                -10.630243913796129
              ],
              [
                -65.41345200167721,
                -10.623631433190225
              ],
              [
                -65.41494250537757,
                -10.617778985881545
              ],
              [
                -65.4086930577668,
                -10.61395848496597
              ],
              [
                -65.40956206575981,
                -10.600591030759887
              ],
              [
                -65.40583760682482,
                -10.597061039144485
              ],
              [
                -65.40131311423885,
                -10.592792542836975
              ],
              [
                -65.3995856418506,
                -10.591163049775492
              ],
              [
                -65.39735564267053,
                -10.589156562219587
              ],
              [
                -65.39718867298903,
                -10.5860245782653
              ],
              [
                -65.39745417777777,
                -10.5796470757959
              ],
              [
                -65.3998181520337,
                -10.570334122538972
              ],
              [
                -65.40088465346989,
                -10.566407659572182
              ],
              [
                -65.40182168829642,
                -10.562632648836724
              ],
              [
                -65.40410619195607,
                -10.553305217503176
              ],
              [
                -65.40737516451578,
                -10.549204223923859
              ],
              [
                -65.40794165187951,
                -10.548493709461829
              ],
              [
                -65.4115466498144,
                -10.544346757618861
              ],
              [
                -65.41548466072761,
                -10.539816277729049
              ],
              [
                -65.4177411423523,
                -10.536328279199688
              ],
              [
                -65.4178676559652,
                -10.532729805445198
              ],
              [
                -65.41769314258738,
                -10.52770031236289
              ],
              [
                -65.41522416179117,
                -10.521544851462933
              ],
              [
                -65.41424369266545,
                -10.519871830858241
              ],
              [
                -65.40918869624679,
                -10.511251861512703
              ],
              [
                -65.41021469839087,
                -10.508167891434928
              ],
              [
                -65.41435369414096,
                -10.499663908290191
              ],
              [
                -65.41556271943561,
                -10.498841916321188
              ],
              [
                -65.42348266161083,
                -10.493460453261019
              ],
              [
                -65.42762915677547,
                -10.484689009086877
              ],
              [
                -65.4289046644566,
                -10.48055752322641
              ],
              [
                -65.4275946738518,
                -10.476069036284535
              ],
              [
                -65.42497569467403,
                -10.46996055700893
              ],
              [
                -65.42240223629253,
                -10.46383306788904
              ],
              [
                -65.4174347724256,
                -10.46327756150753
              ],
              [
                -65.4109562697039,
                -10.463022564482236
              ],
              [
                -65.40667479369861,
                -10.46285507085371
              ],
              [
                -65.40555531145043,
                -10.46281106212142
              ],
              [
                -65.40238082036957,
                -10.462334059488638
              ],
              [
                -65.38984694110674,
                -10.447152105495364
              ],
              [
                -65.38534446787764,
                -10.437898129557535
              ],
              [
                -65.38084201063471,
                -10.428644628642
              ],
              [
                -65.37836653904724,
                -10.416506184666593
              ],
              [
                -65.37793903081263,
                -10.414411212576772
              ],
              [
                -65.3778570567144,
                -10.408300219696757
              ],
              [
                -65.37976405342307,
                -10.400494751336238
              ],
              [
                -65.38100054930584,
                -10.397486260374045
              ],
              [
                -65.38355153466767,
                -10.39205580144319
              ],
              [
                -65.3847885461859,
                -10.389256813718056
              ],
              [
                -65.38866504153424,
                -10.380486331180624
              ],
              [
                -65.38987404462893,
                -10.377430869342716
              ],
              [
                -65.39121103873164,
                -10.373990355122643
              ],
              [
                -65.38765907350857,
                -10.368862874862774
              ],
              [
                -65.38463810152102,
                -10.364946392084716
              ],
              [
                -65.37531565735775,
                -10.351735930683441
              ],
              [
                -65.36890267348296,
                -10.347286455207254
              ],
              [
                -65.36248973161048,
                -10.342836939743393
              ],
              [
                -65.35459080236384,
                -10.331878493602112
              ],
              [
                -65.34684436896929,
                -10.321132000982676
              ],
              [
                -65.34337588796188,
                -10.31796603478584
              ],
              [
                -65.33669690537587,
                -10.312376506118692
              ],
              [
                -65.33568891499657,
                -10.311492505676673
              ],
              [
                -65.33147246569237,
                -10.307794054200254
              ],
              [
                -65.32693597476498,
                -10.303653047819619
              ],
              [
                -65.32387148704751,
                -10.300874553902304
              ],
              [
                -65.32074405260694,
                -10.29694156511555
              ],
              [
                -65.3181915727201,
                -10.291546578649989
              ],
              [
                -65.31628909474793,
                -10.27656562443081
              ],
              [
                -65.31375461225146,
                -10.26850816779076
              ],
              [
                -65.3103591437394,
                -10.257714700783954
              ],
              [
                -65.30618066103945,
                -10.249381209471444
              ],
              [
                -65.30360170605636,
                -10.24706420771669
              ],
              [
                -65.29424177181254,
                -10.238657261138165
              ],
              [
                -65.29269477162822,
                -10.227401804971802
              ],
              [
                -65.28823583771577,
                -10.219426810952841
              ],
              [
                -65.28913084148658,
                -10.209712856177852
              ],
              [
                -65.29872182014688,
                -10.190984928908684
              ],
              [
                -65.30142131425879,
                -10.185713973753833
              ],
              [
                -65.30643180021447,
                -10.1645510749172
              ],
              [
                -65.30559183751318,
                -10.160943584827965
              ],
              [
                -65.30484135162904,
                -10.157770574287126
              ],
              [
                -65.30344282704581,
                -10.15279558232656
              ],
              [
                -65.30215836321274,
                -10.148219605087249
              ],
              [
                -65.30122437851784,
                -10.144888631836388
              ],
              [
                -65.29971337685187,
                -10.139100131704998
              ],
              [
                -65.30001339005302,
                -10.13086466356839
              ],
              [
                -65.30031391008842,
                -10.122720224104059
              ],
              [
                -65.30030091026927,
                -10.11664174451109
              ],
              [
                -65.30027793650059,
                -10.106949784244
              ],
              [
                -65.30049595552653,
                -10.09904331378852
              ],
              [
                -65.30196744530883,
                -10.094670322333949
              ],
              [
                -65.31106390166396,
                -10.088902840449919
              ],
              [
                -65.31262789922992,
                -10.08738937958474
              ],
              [
                -65.31548287431029,
                -10.084626891432386
              ],
              [
                -65.31797286528872,
                -10.080027893464038
              ],
              [
                -65.32515088677481,
                -10.065576967492847
              ],
              [
                -65.32669836049007,
                -10.062209985440074
              ],
              [
                -65.32819384978916,
                -10.053205507121751
              ],
              [
                -65.32895135565816,
                -10.04611607653733
              ],
              [
                -65.32837789064642,
                -10.042436075202211
              ],
              [
                -65.32158146219588,
                -10.009644701430812
              ],
              [
                -65.31962048171343,
                -10.000181729278658
              ],
              [
                -65.31989199463989,
                -9.99695371466469
              ],
              [
                -65.320163000567,
                -9.993725769050688
              ],
              [
                -65.32162801523737,
                -9.977208311571246
              ],
              [
                -65.32463101474124,
                -9.973702840391649
              ],
              [
                -65.32681050657992,
                -9.971322832716169
              ],
              [
                -65.33181348213992,
                -9.965860358000484
              ],
              [
                -65.33243899793845,
                -9.957325898616034
              ],
              [
                -65.33230602568304,
                -9.943829961404658
              ],
              [
                -65.33193900832853,
                -9.936616476550574
              ],
              [
                -65.33120355040523,
                -9.929666025137427
              ],
              [
                -65.32940006973138,
                -9.925267516555627
              ],
              [
                -65.3205636347157,
                -9.905538078608284
              ],
              [
                -65.31812215089587,
                -9.902595110629166
              ],
              [
                -65.31016869758024,
                -9.893178622890451
              ],
              [
                -65.30305377753169,
                -9.88528215887481
              ],
              [
                -65.30273878069211,
                -9.884936668656342
              ],
              [
                -65.29541330592602,
                -9.876906146830889
              ],
              [
                -65.29079483554294,
                -9.871258195480172
              ],
              [
                -65.28835735105746,
                -9.867474188678273
              ],
              [
                -65.28496740322262,
                -9.855157243136814
              ],
              [
                -65.2855264166512,
                -9.845097768493302
              ],
              [
                -65.2855234401959,
                -9.841057287540917
              ],
              [
                -65.28855243066229,
                -9.835576297773393
              ],
              [
                -65.29230890201846,
                -9.832828833974729
              ],
              [
                -65.30576683113951,
                -9.823870384705135
              ],
              [
                -65.31065533566168,
                -9.820561415783441
              ],
              [
                -65.31654827318526,
                -9.816572436982995
              ],
              [
                -65.31992726904019,
                -9.814089962583495
              ],
              [
                -65.32100979157393,
                -9.809728966537547
              ],
              [
                -65.32741580361487,
                -9.779548095097262
              ],
              [
                -65.34249575065317,
                -9.753040212677401
              ],
              [
                -65.34485022826468,
                -9.747076758613819
              ],
              [
                -65.34720573186861,
                -9.741111792557307
              ],
              [
                -65.35400973794744,
                -9.72323836989446
              ],
              [
                -65.36287870067142,
                -9.711544929424262
              ],
              [
                -65.37416467765162,
                -9.698907985245496
              ],
              [
                -65.39426207376503,
                -9.685185074886414
              ],
              [
                -65.39766706236519,
                -9.68403556550134
              ],
              [
                -65.40107156296868,
                -9.682886608112486
              ],
              [
                -65.41281550587271,
                -9.678922602261725
              ],
              [
                -65.42101043362175,
                -9.67629066288732
              ],
              [
                -65.42378445101465,
                -9.675399653545318
              ],
              [
                -65.42920840757328,
                -9.67352814999861
              ],
              [
                -65.43617338100897,
                -9.67124167740435
              ],
              [
                -65.44290884936933,
                -9.669737219537511
              ],
              [
                -65.44600833447222,
                -9.674293677433912
              ],
              [
                -65.44211683632365,
                -9.678575661414499
              ],
              [
                -65.44090581478389,
                -9.685904630256312
              ],
              [
                -65.44236780355767,
                -9.68894562023718
              ],
              [
                -65.44350832501854,
                -9.691318116202359
              ],
              [
                -65.44648729345948,
                -9.696661103009005
              ],
              [
                -65.45642819728566,
                -9.703540081043315
              ],
              [
                -65.46816615077525,
                -9.711215060974714
              ],
              [
                -65.47119062035192,
                -9.713192086388586
              ],
              [
                -65.48141258748151,
                -9.71336808679844
              ],
              [
                -65.48645953528835,
                -9.709439125302604
              ],
              [
                -65.48834301943728,
                -9.71341608551196
              ],
              [
                -65.48796203483995,
                -9.717498095775818
              ],
              [
                -65.48638453701219,
                -9.72049406218617
              ],
              [
                -65.48347154643142,
                -9.725048055718007
              ],
              [
                -65.48232105536056,
                -9.72684704623721
              ],
              [
                -65.48563502718032,
                -9.727759035071458
              ],
              [
                -65.48657552628468,
                -9.72785305020312
              ],
              [
                -65.49237800608292,
                -9.728433536184534
              ],
              [
                -65.4998514627111,
                -9.729481573065957
              ],
              [
                -65.50130740456504,
                -9.742477008226734
              ],
              [
                -65.50212890948302,
                -9.747431500177765
              ],
              [
                -65.50282887582625,
                -9.75624094567299
              ],
              [
                -65.5030883992767,
                -9.759506944995852
              ],
              [
                -65.50338439270296,
                -9.767653919318827
              ],
              [
                -65.49894837478632,
                -9.775302368009745
              ],
              [
                -65.49783489851923,
                -9.77833984101182
              ],
              [
                -65.50332586527834,
                -9.786137318865663
              ],
              [
                -65.50779432060507,
                -9.789328806144294
              ],
              [
                -65.51224680560212,
                -9.792154827754125
              ],
              [
                -65.51703326937113,
                -9.790353328994527
              ],
              [
                -65.52208723321037,
                -9.787051847197892
              ],
              [
                -65.5230337323073,
                -9.786512845688204
              ],
              [
                -65.52625773401351,
                -9.78467687257626
              ],
              [
                -65.53138668667407,
                -9.78125488533686
              ],
              [
                -65.53458519374533,
                -9.78921386901211
              ],
              [
                -65.53677916822157,
                -9.796332826210685
              ],
              [
                -65.53747716270266,
                -9.798195336443655
              ],
              [
                -65.53872363706562,
                -9.801517826160286
              ],
              [
                -65.54072013944872,
                -9.80186431404933
              ],
              [
                -65.55180308652669,
                -9.803785811548154
              ],
              [
                -65.55861703406929,
                -9.810072808188316
              ],
              [
                -65.56375000284028,
                -9.811551304892422
              ],
              [
                -65.5640349612327,
                -9.815373295263427
              ],
              [
                -65.56405049275347,
                -9.819334788694288
              ],
              [
                -65.55974600030441,
                -9.824976276068186
              ],
              [
                -65.55416699456889,
                -9.832288217256606
              ],
              [
                -65.55241151109159,
                -9.834956222634101
              ],
              [
                -65.55414999716666,
                -9.841236691270812
              ],
              [
                -65.55863648923344,
                -9.84329870082628
              ],
              [
                -65.56763191911057,
                -9.838875210441334
              ],
              [
                -65.59639976088151,
                -9.841025743365757
              ],
              [
                -65.59689879618313,
                -9.837438755346945
              ],
              [
                -65.5970168018653,
                -9.831092785866515
              ],
              [
                -65.5971352992446,
                -9.830490314270453
              ],
              [
                -65.59801577904624,
                -9.826022814099876
              ],
              [
                -65.60046179250435,
                -9.82369982053427
              ],
              [
                -65.60674574725724,
                -9.824721822774816
              ],
              [
                -65.61880365073102,
                -9.832704333636363
              ],
              [
                -65.62257862671515,
                -9.835202838472858
              ],
              [
                -65.62613313949733,
                -9.836041327056897
              ],
              [
                -65.62993359586022,
                -9.833907820944896
              ],
              [
                -65.63095909757342,
                -9.829050860410849
              ],
              [
                -65.63117912873196,
                -9.8276523458963
              ],
              [
                -65.63274762796858,
                -9.817690902974471
              ],
              [
                -65.63569510277843,
                -9.805552970246382
              ],
              [
                -65.64012607905165,
                -9.80646646093002
              ],
              [
                -65.64224807497614,
                -9.809202932251683
              ],
              [
                -65.64265758106691,
                -9.809730934963508
              ],
              [
                -65.64703303496881,
                -9.815379946179048
              ],
              [
                -65.65058703616725,
                -9.81349943073332
              ],
              [
                -65.65328254391336,
                -9.807139991361334
              ],
              [
                -65.6511410435361,
                -9.804122498036692
              ],
              [
                -65.649844059752,
                -9.802447001128586
              ],
              [
                -65.6438735971741,
                -9.794733021982436
              ],
              [
                -65.64677659658184,
                -9.791024008214794
              ],
              [
                -65.65101603831464,
                -9.79163552769414
              ],
              [
                -65.65381204148505,
                -9.79212902031779
              ],
              [
                -65.65871903294676,
                -9.792694517027346
              ],
              [
                -65.66246550382093,
                -9.789176547887983
              ],
              [
                -65.66849599726831,
                -9.780675110663827
              ],
              [
                -65.67122495486127,
                -9.782982601518597
              ],
              [
                -65.6781879341802,
                -9.789656073071267
              ],
              [
                -65.68110038705291,
                -9.792459590408182
              ],
              [
                -65.68117590054683,
                -9.792538561238347
              ],
              [
                -65.68888783590123,
                -9.80057955399724
              ],
              [
                -65.68922633828072,
                -9.805485027553637
              ],
              [
                -65.68933732979445,
                -9.812247507946584
              ],
              [
                -65.69511730410645,
                -9.810833032321467
              ],
              [
                -65.70049376691573,
                -9.809128022117074
              ],
              [
                -65.70955425345855,
                -9.806069561723932
              ],
              [
                -65.7081852289646,
                -9.803193575080375
              ],
              [
                -65.706067242388,
                -9.799595593894024
              ],
              [
                -65.69944231372996,
                -9.787732625848076
              ],
              [
                -65.70927679560499,
                -9.772125212655412
              ],
              [
                -65.7073008030599,
                -9.765567218571505
              ],
              [
                -65.70374481805958,
                -9.763588210200462
              ],
              [
                -65.69928084960152,
                -9.764723196971111
              ],
              [
                -65.69216787991444,
                -9.766796190105108
              ],
              [
                -65.68755741586436,
                -9.76813915887736
              ],
              [
                -65.68406292715915,
                -9.7676571581138
              ],
              [
                -65.68085494389682,
                -9.76662967780711
              ],
              [
                -65.67891946442516,
                -9.761143679887413
              ],
              [
                -65.68453942811915,
                -9.760601208770394
              ],
              [
                -65.68478793151417,
                -9.75584771661666
              ],
              [
                -65.68473747324873,
                -9.748700261784906
              ],
              [
                -65.69292591531878,
                -9.749873770415975
              ],
              [
                -65.70545881733203,
                -9.756834764685664
              ],
              [
                -65.7183072601068,
                -9.755508761871894
              ],
              [
                -65.72138474509079,
                -9.75650727707916
              ],
              [
                -65.72871971979875,
                -9.760591266717086
              ],
              [
                -65.73211317846119,
                -9.76267374745855
              ],
              [
                -65.73405764613173,
                -9.764908746349898
              ],
              [
                -65.73429217652324,
                -9.765178769732238
              ],
              [
                -65.7376386358683,
                -9.779400216960006
              ],
              [
                -65.74374959393175,
                -9.781480726037751
              ],
              [
                -65.74861506932555,
                -9.7760592435928
              ],
              [
                -65.75337404560057,
                -9.769800256039115
              ],
              [
                -65.75642206979549,
                -9.765799776506707
              ],
              [
                -65.76328900995908,
                -9.762378805899031
              ],
              [
                -65.77138448734671,
                -9.758112859321434
              ],
              [
                -65.77208747442842,
                -9.757742334789441
              ],
              [
                -65.77060499895636,
                -9.750206854010546
              ],
              [
                -65.76546705464305,
                -9.737755421461456
              ],
              [
                -65.76806602422788,
                -9.734902407011397
              ],
              [
                -65.7738540134256,
                -9.734197440732133
              ],
              [
                -65.77642349057236,
                -9.736696430644269
              ],
              [
                -65.77786647752758,
                -9.739706918907899
              ],
              [
                -65.77842448002019,
                -9.743890420483925
              ],
              [
                -65.77891997400012,
                -9.74858540609308
              ],
              [
                -65.77761997363088,
                -9.756587844768864
              ],
              [
                -65.77589745516865,
                -9.767195316255409
              ],
              [
                -65.77867891002899,
                -9.770446811777374
              ],
              [
                -65.78341639817532,
                -9.786061727238831
              ],
              [
                -65.78800585011696,
                -9.787997248457412
              ],
              [
                -65.793884796527,
                -9.791228261998942
              ],
              [
                -65.79613331451358,
                -9.78799526537141
              ],
              [
                -65.80102878224619,
                -9.789118773045965
              ],
              [
                -65.8051482730546,
                -9.787642781974762
              ],
              [
                -65.80801676101457,
                -9.781269806783724
              ],
              [
                -65.81020372739154,
                -9.776125318027416
              ],
              [
                -65.81194274630361,
                -9.772034353715528
              ],
              [
                -65.80967027705493,
                -9.769921335824614
              ],
              [
                -65.80528879744399,
                -9.767742334833988
              ],
              [
                -65.80127730252262,
                -9.765230859647719
              ],
              [
                -65.79957083752036,
                -9.756237889794415
              ],
              [
                -65.8038272969431,
                -9.75954388445735
              ],
              [
                -65.80661030270741,
                -9.761617869290912
              ],
              [
                -65.81514326938056,
                -9.755232907117314
              ],
              [
                -65.81825025916822,
                -9.75691291990326
              ],
              [
                -65.82044121951527,
                -9.764812371543108
              ],
              [
                -65.82330670962757,
                -9.766417876226463
              ],
              [
                -65.82754616502578,
                -9.767314901674206
              ],
              [
                -65.8339326308449,
                -9.775575370679958
              ],
              [
                -65.83627560512933,
                -9.778071851297579
              ],
              [
                -65.84246156463558,
                -9.784662834100981
              ],
              [
                -65.8409425889477,
                -9.789161804298077
              ],
              [
                -65.85103001562578,
                -9.79226433203069
              ],
              [
                -65.85959247207961,
                -9.794030835213052
              ],
              [
                -65.8624754389396,
                -9.793268852569788
              ],
              [
                -65.8668969448909,
                -9.791418855330667
              ],
              [
                -65.87131993101706,
                -9.788838865747637
              ],
              [
                -65.86748543348257,
                -9.78118691249967
              ],
              [
                -65.86800794993975,
                -9.776191395511988
              ],
              [
                -65.86820445999302,
                -9.774311411667377
              ],
              [
                -65.86162700439364,
                -9.767725451213735
              ],
              [
                -65.86775248489758,
                -9.7614984836033
              ],
              [
                -65.87079198652586,
                -9.752942993578904
              ],
              [
                -65.88369138921104,
                -9.752976536884988
              ],
              [
                -65.88955487968744,
                -9.755040543660138
              ],
              [
                -65.89815582892818,
                -9.759347019621256
              ],
              [
                -65.89844431225845,
                -9.759492021551107
              ],
              [
                -65.90207928618086,
                -9.765687018786323
              ],
              [
                -65.91486371276308,
                -9.761836050059005
              ],
              [
                -65.91487223843036,
                -9.76077504592853
              ],
              [
                -65.91493272990483,
                -9.753464060598507
              ],
              [
                -65.91900322492549,
                -9.753295084661657
              ],
              [
                -65.92218621499265,
                -9.753977555224507
              ],
              [
                -65.92472769147324,
                -9.75886855048086
              ],
              [
                -65.9230531696042,
                -9.761474072360024
              ],
              [
                -65.92227366752611,
                -9.762687525715487
              ],
              [
                -65.92598715402981,
                -9.762082538802295
              ],
              [
                -65.93023064679998,
                -9.765882028733936
              ],
              [
                -65.93343614172927,
                -9.768443050521448
              ],
              [
                -65.9367081105186,
                -9.768406527846606
              ],
              [
                -65.93791410184252,
                -9.763867061238978
              ],
              [
                -65.93792609385785,
                -9.76382057142678
              ],
              [
                -65.93944811244077,
                -9.761246068187196
              ],
              [
                -65.94331007174002,
                -9.760316586685807
              ],
              [
                -65.94841056674466,
                -9.764437587820074
              ],
              [
                -65.95197202001056,
                -9.766319085644774
              ],
              [
                -65.9546845277988,
                -9.767393092053618
              ],
              [
                -65.96061995980594,
                -9.769563579603595
              ],
              [
                -65.96557345236688,
                -9.770078580600725
              ],
              [
                -65.97194342063749,
                -9.776948565820023
              ],
              [
                -65.9724153857604,
                -9.781612026674543
              ],
              [
                -65.97314187954832,
                -9.78879101882645
              ],
              [
                -65.97338188774641,
                -9.79349601916988
              ],
              [
                -65.97357137037964,
                -9.79746700009333
              ],
              [
                -65.96319892056717,
                -9.801998930164393
              ],
              [
                -65.96614639433548,
                -9.804214938834885
              ],
              [
                -65.97257134245419,
                -9.802959460616252
              ],
              [
                -65.97955832060015,
                -9.803055487399988
              ],
              [
                -65.98129532264629,
                -9.80757947479793
              ],
              [
                -65.9842502880133,
                -9.805703978293147
              ],
              [
                -65.98830779762345,
                -9.801534479842692
              ],
              [
                -65.99794974427151,
                -9.801840517097228
              ],
              [
                -65.99810124878697,
                -9.80105800916826
              ],
              [
                -65.998572214405,
                -9.798615504751304
              ],
              [
                -65.99911125173422,
                -9.794288558258586
              ],
              [
                -66.00795866818011,
                -9.80148851431736
              ],
              [
                -66.00853717646547,
                -9.80201003535413
              ],
              [
                -66.01774611935245,
                -9.810300510080273
              ],
              [
                -66.0230440937551,
                -9.811117526580178
              ],
              [
                -66.02647559941988,
                -9.805764023384077
              ],
              [
                -66.02903806252978,
                -9.799707051341265
              ],
              [
                -66.02944558002959,
                -9.798768554379839
              ],
              [
                -66.03457507373041,
                -9.786951603227084
              ],
              [
                -66.0396770490312,
                -9.786589640653785
              ],
              [
                -66.05768996322158,
                -9.785090153733412
              ],
              [
                -66.06444992177252,
                -9.784456169778997
              ],
              [
                -66.06844489907607,
                -9.7850231860918
              ],
              [
                -66.08452428388581,
                -9.790959182293433
              ],
              [
                -66.07830431390727,
                -9.795133156342583
              ],
              [
                -66.08323680919283,
                -9.799024632181245
              ],
              [
                -66.08396377866615,
                -9.799598122274068
              ],
              [
                -66.08863024573841,
                -9.806900133416322
              ],
              [
                -66.09269323731525,
                -9.813262120996232
              ],
              [
                -66.09617122629069,
                -9.810686113820264
              ],
              [
                -66.1000931983243,
                -9.805488125787587
              ],
              [
                -66.09935770889355,
                -9.800596160223565
              ],
              [
                -66.09829474614466,
                -9.795663182289333
              ],
              [
                -66.09820021232339,
                -9.795224177719385
              ],
              [
                -66.09733672740036,
                -9.790404202700971
              ],
              [
                -66.09710326032729,
                -9.78560271562312
              ],
              [
                -66.09980723123093,
                -9.784195722995838
              ],
              [
                -66.10524069082803,
                -9.782671750133591
              ],
              [
                -66.10961567981185,
                -9.784763760565795
              ],
              [
                -66.10946616968867,
                -9.789003208065983
              ],
              [
                -66.11283216659395,
                -9.789283730417942
              ],
              [
                -66.11465915410767,
                -9.789564714317027
              ],
              [
                -66.11665615473783,
                -9.78987272138992
              ],
              [
                -66.12230060457934,
                -9.79065173257981
              ],
              [
                -66.12376310856253,
                -9.789286742820284
              ],
              [
                -66.12715256893676,
                -9.786122769600922
              ],
              [
                -66.12998755061487,
                -9.789405246310531
              ],
              [
                -66.14040151642354,
                -9.788015280899524
              ],
              [
                -66.14470297887816,
                -9.790671756205146
              ],
              [
                -66.14665997149248,
                -9.786177789474205
              ],
              [
                -66.15163444055386,
                -9.785175781998888
              ],
              [
                -66.15437892719315,
                -9.786294783350014
              ],
              [
                -66.15960189626772,
                -9.788236306697135
              ],
              [
                -66.16555836603958,
                -9.791218777479928
              ],
              [
                -66.17130032932808,
                -9.794537804720049
              ],
              [
                -66.17427832260783,
                -9.798255763127505
              ],
              [
                -66.17633880365992,
                -9.800588257046304
              ],
              [
                -66.17984428267765,
                -9.803479288270522
              ],
              [
                -66.1828762488605,
                -9.80401076420425
              ],
              [
                -66.186095761145,
                -9.804526780493518
              ],
              [
                -66.19294520448673,
                -9.806303288061239
              ],
              [
                -66.19599067053322,
                -9.81427026737702
              ],
              [
                -66.1916926890482,
                -9.81776325399213
              ],
              [
                -66.19101719977363,
                -9.8181357255776
              ],
              [
                -66.18916269825965,
                -9.819156240956
              ],
              [
                -66.18615121802735,
                -9.82228320393549
              ],
              [
                -66.1912537230875,
                -9.821531229790217
              ],
              [
                -66.19378317823875,
                -9.820532747412434
              ],
              [
                -66.19527116585749,
                -9.81994523589652
              ],
              [
                -66.19657115556468,
                -9.822948719217424
              ],
              [
                -66.19717167134586,
                -9.824337205205252
              ],
              [
                -66.19354066428653,
                -9.827387192472122
              ],
              [
                -66.20304913544403,
                -9.834124705570964
              ],
              [
                -66.20627411656862,
                -9.834112201780535
              ],
              [
                -66.2094545720136,
                -9.834038720137094
              ],
              [
                -66.21258557807286,
                -9.83373869722503
              ],
              [
                -66.22375001938263,
                -9.825559741357791
              ],
              [
                -66.2376614724501,
                -9.815368792058567
              ],
              [
                -66.2410679269314,
                -9.821978772875076
              ],
              [
                -66.24747941800855,
                -9.821587813531325
              ],
              [
                -66.24862741037552,
                -9.82649379683876
              ],
              [
                -66.248704375458,
                -9.826824772779512
              ],
              [
                -66.25222589180252,
                -9.830709769540416
              ],
              [
                -66.2550433565963,
                -9.832761791725014
              ],
              [
                -66.2594283212794,
                -9.831375284700297
              ],
              [
                -66.26258982361111,
                -9.830217814896805
              ],
              [
                -66.26272030304662,
                -9.830170293275463
              ],
              [
                -66.27446425954923,
                -9.832464288901752
              ],
              [
                -66.27496777580767,
                -9.827715299653459
              ],
              [
                -66.27906424985225,
                -9.828214837431819
              ],
              [
                -66.28303971091952,
                -9.832750821620394
              ],
              [
                -66.28270919943013,
                -9.835889294897806
              ],
              [
                -66.28224368250939,
                -9.840311786381097
              ],
              [
                -66.28558018475726,
                -9.84420527585161
              ],
              [
                -66.28997867694405,
                -9.841871296226891
              ],
              [
                -66.29417613120083,
                -9.840101786265722
              ],
              [
                -66.30134612296818,
                -9.836422843872745
              ],
              [
                -66.30425359311563,
                -9.83493134884808
              ],
              [
                -66.30527207251822,
                -9.836522818811098
              ],
              [
                -66.30735508597783,
                -9.839778325555027
              ],
              [
                -66.30926955271602,
                -9.83662484685714
              ],
              [
                -66.31215807028427,
                -9.831866867434867
              ],
              [
                -66.31535155492857,
                -9.831052375453847
              ],
              [
                -66.3186185241553,
                -9.835795856799649
              ],
              [
                -66.32758095453866,
                -9.83740035846065
              ],
              [
                -66.33172246410899,
                -9.838075873699475
              ],
              [
                -66.33654893807844,
                -9.838899884510093
              ],
              [
                -66.34279540155273,
                -9.840049893441352
              ],
              [
                -66.34730836096404,
                -9.840937858525473
              ],
              [
                -66.35067035752286,
                -9.845808878607356
              ],
              [
                -66.35369229975947,
                -9.848222846881399
              ],
              [
                -66.3563962907804,
                -9.853559842264264
              ],
              [
                -66.35649131357258,
                -9.854013841804266
              ],
              [
                -66.35837077070363,
                -9.862976304996353
              ],
              [
                -66.35925876485202,
                -9.865725298664481
              ],
              [
                -66.36263224519274,
                -9.865796805838173
              ],
              [
                -66.38173612592509,
                -9.86740080888854
              ],
              [
                -66.38929110588663,
                -9.864970361665067
              ],
              [
                -66.39396358845855,
                -9.863466877513565
              ],
              [
                -66.40309653067655,
                -9.864111369925244
              ],
              [
                -66.4026430506467,
                -9.868540846501077
              ],
              [
                -66.40646600868519,
                -9.871742334315854
              ],
              [
                -66.41050597654599,
                -9.875014323235998
              ],
              [
                -66.41065298131066,
                -9.875939336197547
              ],
              [
                -66.4115419657754,
                -9.881541821795665
              ],
              [
                -66.41679345262811,
                -9.884249835678656
              ],
              [
                -66.42071543194766,
                -9.886983820330885
              ],
              [
                -66.4224573936425,
                -9.889896801836759
              ],
              [
                -66.41953339327401,
                -9.89579227226448
              ],
              [
                -66.42576486764706,
                -9.898307259433496
              ],
              [
                -66.43152133104188,
                -9.891876805463045
              ],
              [
                -66.42715638190744,
                -9.880595367318405
              ],
              [
                -66.42434191859952,
                -9.873322352515787
              ],
              [
                -66.42635040231828,
                -9.869325882894069
              ],
              [
                -66.43481686151831,
                -9.865894403669028
              ],
              [
                -66.43935987271217,
                -9.868084395236853
              ],
              [
                -66.44458883943761,
                -9.870955917503942
              ],
              [
                -66.4534072580375,
                -9.870519924251361
              ],
              [
                -66.45712773259963,
                -9.873724903919705
              ],
              [
                -66.45888575152378,
                -9.87523990540018
              ]
            ]
          ],
          [
            [
              [
                -66.45888575152378,
                -9.87523990540018
              ],
              [
                -66.45407225172659,
                -9.877268382473819
              ],
              [
                -66.45677423953319,
                -9.878479411551053
              ],
              [
                -66.45888575152378,
                -9.87523990540018
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 22,
        "nome": "Roraima",
        "sigla": "RR",
        "regiao_id": 3,
        "codigo_ibg": 14
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -59.91635177883274,
                3.145914658374978
              ],
              [
                -59.912556815239014,
                3.147636622156158
              ],
              [
                -59.91053783173754,
                3.146229141674043
              ],
              [
                -59.9079703276314,
                3.144440644227099
              ],
              [
                -59.90685284241147,
                3.139754181964379
              ],
              [
                -59.91446631455957,
                3.13608471676227
              ],
              [
                -59.91561828807603,
                3.135530197660633
              ],
              [
                -59.91867377067882,
                3.132378706135472
              ],
              [
                -59.917247768680376,
                3.128904245404331
              ],
              [
                -59.916975808587836,
                3.12824123027225
              ],
              [
                -59.91240981778864,
                3.127277746893769
              ],
              [
                -59.908493822662116,
                3.125307260901914
              ],
              [
                -59.90826885730919,
                3.118275793202416
              ],
              [
                -59.9083438591715,
                3.113405332610024
              ],
              [
                -59.91436280686437,
                3.111394359607135
              ],
              [
                -59.91657028479212,
                3.118047320547884
              ],
              [
                -59.92031077949033,
                3.120996304555926
              ],
              [
                -59.920526762144796,
                3.121166775631426
              ],
              [
                -59.92156276268809,
                3.117763827643291
              ],
              [
                -59.9242977589131,
                3.10878438507752
              ],
              [
                -59.9267192445242,
                3.109229866546605
              ],
              [
                -59.92871970263778,
                3.10959787828225
              ],
              [
                -59.93379219714764,
                3.104916388910298
              ],
              [
                -59.93797466514191,
                3.099652439614913
              ],
              [
                -59.93851568074377,
                3.093759458371339
              ],
              [
                -59.93859268568887,
                3.092915969488703
              ],
              [
                -59.9391126819326,
                3.088037501119632
              ],
              [
                -59.9418976562195,
                3.086783518990455
              ],
              [
                -59.94465263159781,
                3.085711527749985
              ],
              [
                -59.945994135991626,
                3.085189026531038
              ],
              [
                -59.95450159680293,
                3.081785065048591
              ],
              [
                -59.956289566774686,
                3.074436084129813
              ],
              [
                -59.952311603998176,
                3.068107624346882
              ],
              [
                -59.950698608846864,
                3.058618199701947
              ],
              [
                -59.950176106329565,
                3.055545197950614
              ],
              [
                -59.95128811624565,
                3.041659808021306
              ],
              [
                -59.952836605487505,
                3.038837311723725
              ],
              [
                -59.955951584879024,
                3.033158354334789
              ],
              [
                -59.958789576499406,
                3.028324402726872
              ],
              [
                -59.95983156308623,
                3.018117463586821
              ],
              [
                -59.95833908389076,
                3.013585492145056
              ],
              [
                -59.95390760269421,
                3.009474510815727
              ],
              [
                -59.9506231333012,
                3.006428001618529
              ],
              [
                -59.94657018142838,
                3.003330530376014
              ],
              [
                -59.94659315991063,
                2.999780041735525
              ],
              [
                -59.951813651284134,
                2.993358119743449
              ],
              [
                -59.955308596923594,
                2.984346172512514
              ],
              [
                -59.95631363332141,
                2.981523668934518
              ],
              [
                -59.958122089037985,
                2.97644421228323
              ],
              [
                -59.96099358983316,
                2.961811283500146
              ],
              [
                -59.959629601478,
                2.960327796791521
              ],
              [
                -59.957589633407366,
                2.958108313197542
              ],
              [
                -59.9573256289348,
                2.953686336638851
              ],
              [
                -59.96154610171133,
                2.95041836217687
              ],
              [
                -59.963217585610934,
                2.948907892003709
              ],
              [
                -59.965509063135514,
                2.946836407478497
              ],
              [
                -59.97961150577133,
                2.933168976879168
              ],
              [
                -59.981255484408194,
                2.930364510447038
              ],
              [
                -59.98092200919631,
                2.921719557166088
              ],
              [
                -59.983292480811166,
                2.913878108267108
              ],
              [
                -59.98381548085724,
                2.909117643054053
              ],
              [
                -59.98100549533212,
                2.906741151054174
              ],
              [
                -59.98060601181655,
                2.906403147903353
              ],
              [
                -59.977255004351605,
                2.904260669260548
              ],
              [
                -59.97628751123125,
                2.896937207755613
              ],
              [
                -59.98243200776492,
                2.88906777367363
              ],
              [
                -59.987569953614376,
                2.882487810396189
              ],
              [
                -59.98332101438841,
                2.878051801110221
              ],
              [
                -59.98231150174968,
                2.876997839982625
              ],
              [
                -59.98053798954261,
                2.87373035578753
              ],
              [
                -59.97988903088288,
                2.870181363775812
              ],
              [
                -59.98432500594047,
                2.865612903109712
              ],
              [
                -59.98573296934149,
                2.864162425421243
              ],
              [
                -59.984698983266455,
                2.859830432927284
              ],
              [
                -59.98396598988344,
                2.85675794101745
              ],
              [
                -59.98380050897705,
                2.851848490362684
              ],
              [
                -59.985159004143476,
                2.849289509289147
              ],
              [
                -59.98768348244252,
                2.844535015875485
              ],
              [
                -59.9898234878273,
                2.840149076080817
              ],
              [
                -59.9878765018308,
                2.820065676510658
              ],
              [
                -59.98682150278084,
                2.811208248088948
              ],
              [
                -59.98639551879445,
                2.807633263308736
              ],
              [
                -59.985808493142166,
                2.803130304013748
              ],
              [
                -59.98790699409235,
                2.797918324114529
              ],
              [
                -59.98857799896976,
                2.796035844669986
              ],
              [
                -59.99079148666618,
                2.789825351787188
              ],
              [
                -59.99211998747422,
                2.786110880583065
              ],
              [
                -59.990989469048344,
                2.779081913157926
              ],
              [
                -59.990960474312885,
                2.778856420494719
              ],
              [
                -59.990324506104024,
                2.773931465689587
              ],
              [
                -59.9879790191943,
                2.758723081672793
              ],
              [
                -59.98713502905125,
                2.754121092842047
              ],
              [
                -59.986292013396294,
                2.748327610136729
              ],
              [
                -59.987367032534294,
                2.744918650976961
              ],
              [
                -59.98909702134559,
                2.740301654313815
              ],
              [
                -59.99129398694181,
                2.734440212282099
              ],
              [
                -59.99070902533247,
                2.72484828037829
              ],
              [
                -59.98920902450902,
                2.70929587170848
              ],
              [
                -59.98905154287745,
                2.708205368160099
              ],
              [
                -59.987979048189274,
                2.700785423055434
              ],
              [
                -59.98768004497414,
                2.698251920070351
              ],
              [
                -59.987374552817684,
                2.69566545939861
              ],
              [
                -59.988625541401746,
                2.691028978617461
              ],
              [
                -59.98963001935074,
                2.686570489667432
              ],
              [
                -59.987219051004104,
                2.682729040622344
              ],
              [
                -59.98435406898687,
                2.679681039652
              ],
              [
                -59.98164808882666,
                2.67715855060933
              ],
              [
                -59.980369600766174,
                2.676008072957328
              ],
              [
                -59.978404600968766,
                2.674240587710183
              ],
              [
                -59.9724451337765,
                2.666905126093052
              ],
              [
                -59.9713786311432,
                2.661629656173926
              ],
              [
                -59.970921167286626,
                2.657988168741071
              ],
              [
                -59.97319363104952,
                2.650417727885771
              ],
              [
                -59.973432652965975,
                2.649637215644566
              ],
              [
                -59.975188148005614,
                2.643904256594806
              ],
              [
                -59.974064640242396,
                2.639738278016523
              ],
              [
                -59.97126615725525,
                2.635605294563575
              ],
              [
                -59.96930366657214,
                2.633493334381718
              ],
              [
                -59.9687196822371,
                2.632865312896044
              ],
              [
                -59.966177686212326,
                2.630075852527134
              ],
              [
                -59.9630082038856,
                2.627212859345934
              ],
              [
                -59.96245670018451,
                2.622001883241293
              ],
              [
                -59.96205671473378,
                2.6189559122685
              ],
              [
                -59.961080214674396,
                2.615855446389133
              ],
              [
                -59.95646725983363,
                2.610344970442816
              ],
              [
                -59.95946772945754,
                2.609914451214571
              ],
              [
                -59.96298520432863,
                2.608804482249166
              ],
              [
                -59.96625671660906,
                2.607771463724805
              ],
              [
                -59.969007201976495,
                2.599038537946287
              ],
              [
                -59.969810703062564,
                2.59451008029037
              ],
              [
                -59.96670919761535,
                2.593441053435608
              ],
              [
                -59.96316223452766,
                2.592998567665352
              ],
              [
                -59.958196239748766,
                2.592533583465578
              ],
              [
                -59.95447976025288,
                2.590574565681766
              ],
              [
                -59.951249288941725,
                2.588549573486611
              ],
              [
                -59.95017430649371,
                2.587875579083315
              ],
              [
                -59.94269635848673,
                2.584687100152536
              ],
              [
                -59.93904835280113,
                2.582205130526057
              ],
              [
                -59.934275393324704,
                2.578122637013649
              ],
              [
                -59.93186090860234,
                2.57518065262653
              ],
              [
                -59.928193947441926,
                2.571641706311323
              ],
              [
                -59.9302479528991,
                2.567848692768557
              ],
              [
                -59.93268741211105,
                2.561451249921221
              ],
              [
                -59.93414892504628,
                2.557618293374711
              ],
              [
                -59.93587188632716,
                2.554102287035752
              ],
              [
                -59.93345142702176,
                2.550142333722595
              ],
              [
                -59.930043961044994,
                2.544745365602023
              ],
              [
                -59.92809147101012,
                2.540751891674484
              ],
              [
                -59.925313972248716,
                2.53819639884463
              ],
              [
                -59.92452696726226,
                2.537136887861878
              ],
              [
                -59.9199435054594,
                2.530965920911295
              ],
              [
                -59.91633203829562,
                2.526540455924069
              ],
              [
                -59.91903253144471,
                2.521424510500895
              ],
              [
                -59.918964533172066,
                2.520562487618668
              ],
              [
                -59.91871852980714,
                2.517432022204007
              ],
              [
                -59.917382542548026,
                2.509765062436642
              ],
              [
                -59.91177654740253,
                2.504404597266098
              ],
              [
                -59.90990109304921,
                2.494724162305516
              ],
              [
                -59.90457911201485,
                2.489945177784879
              ],
              [
                -59.902452130189936,
                2.488035675366339
              ],
              [
                -59.89918465953958,
                2.484810223366142
              ],
              [
                -59.89651967903943,
                2.482438730501799
              ],
              [
                -59.896886167961696,
                2.476932273495853
              ],
              [
                -59.89833864300593,
                2.472796803718808
              ],
              [
                -59.89181270223518,
                2.464755341222176
              ],
              [
                -59.892189702132555,
                2.453735384100229
              ],
              [
                -59.89421367407624,
                2.448892908742022
              ],
              [
                -59.894398181159396,
                2.448451417444232
              ],
              [
                -59.89832566091589,
                2.43829900345259
              ],
              [
                -59.894421690717756,
                2.433046539310455
              ],
              [
                -59.88857871983432,
                2.425185063006007
              ],
              [
                -59.89455770472052,
                2.422716600943348
              ],
              [
                -59.89495267846726,
                2.422553090064671
              ],
              [
                -59.89498669376117,
                2.418481105349101
              ],
              [
                -59.89483020104119,
                2.414756637490083
              ],
              [
                -59.894814191277405,
                2.414367129805668
              ],
              [
                -59.89470019236412,
                2.410484170905488
              ],
              [
                -59.89455220861894,
                2.406679699523591
              ],
              [
                -59.89718320706726,
                2.404328691503324
              ],
              [
                -59.89931068847019,
                2.401961737390266
              ],
              [
                -59.90431313920122,
                2.39639575538894
              ],
              [
                -59.906489134202104,
                2.388833310230423
              ],
              [
                -59.90364414238719,
                2.381838857845416
              ],
              [
                -59.902075151137275,
                2.377502885094766
              ],
              [
                -59.89798619660329,
                2.367702930965546
              ],
              [
                -59.89601569370824,
                2.363334445263841
              ],
              [
                -59.89226424544601,
                2.361190464672312
              ],
              [
                -59.88677975681693,
                2.357701470466433
              ],
              [
                -59.881810787028186,
                2.354610991077748
              ],
              [
                -59.878974800168685,
                2.353137026833579
              ],
              [
                -59.87747932749408,
                2.354361514000743
              ],
              [
                -59.87619183166164,
                2.355415995254905
              ],
              [
                -59.87060238058178,
                2.360547455664591
              ],
              [
                -59.86672139991072,
                2.350497514140804
              ],
              [
                -59.85301198559881,
                2.338306597851313
              ],
              [
                -59.85281096304208,
                2.337558603236257
              ],
              [
                -59.85154348513988,
                2.332844124873303
              ],
              [
                -59.841771037892805,
                2.328733136880139
              ],
              [
                -59.84165804999384,
                2.321805705119964
              ],
              [
                -59.83480457939649,
                2.321214705204512
              ],
              [
                -59.8325116109823,
                2.323672169742689
              ],
              [
                -59.8276221338193,
                2.328913644757013
              ],
              [
                -59.825683637673045,
                2.324707671139585
              ],
              [
                -59.82402867283005,
                2.320590182096171
              ],
              [
                -59.82157916883085,
                2.318798708905339
              ],
              [
                -59.81665971990309,
                2.31535970766129
              ],
              [
                -59.813354232090454,
                2.313105744927635
              ],
              [
                -59.806137751192765,
                2.308108269170581
              ],
              [
                -59.80392879949746,
                2.304272795259755
              ],
              [
                -59.802566789523475,
                2.301314296419417
              ],
              [
                -59.80004379344439,
                2.298124827548039
              ],
              [
                -59.79912779812296,
                2.297258832389242
              ],
              [
                -59.79147736155003,
                2.290028884812866
              ],
              [
                -59.788426391424956,
                2.287258369264489
              ],
              [
                -59.785178412471375,
                2.284446404896227
              ],
              [
                -59.7787389508903,
                2.285609903704719
              ],
              [
                -59.771925990801954,
                2.291705352956932
              ],
              [
                -59.76922199584953,
                2.294123822578308
              ],
              [
                -59.76231153618694,
                2.292010839761512
              ],
              [
                -59.75871755067578,
                2.29094785584481
              ],
              [
                -59.75855406965218,
                2.290902858056076
              ],
              [
                -59.75573807146592,
                2.290141365613672
              ],
              [
                -59.75198811079863,
                2.289298364331279
              ],
              [
                -59.7493956179386,
                2.289584357715876
              ],
              [
                -59.7483781358808,
                2.289696874687698
              ],
              [
                -59.74411913707213,
                2.292140351607288
              ],
              [
                -59.73867117684385,
                2.292889837221132
              ],
              [
                -59.73657019415899,
                2.287225352308619
              ],
              [
                -59.73232973315733,
                2.284151901177944
              ],
              [
                -59.72793223009653,
                2.281031898273517
              ],
              [
                -59.72191930492575,
                2.276789926510062
              ],
              [
                -59.72593378664605,
                2.267081994875146
              ],
              [
                -59.72599875631752,
                2.266925487832081
              ],
              [
                -59.72792026343727,
                2.262774028282859
              ],
              [
                -59.72541777124911,
                2.259576028508691
              ],
              [
                -59.72478677252151,
                2.257933032099058
              ],
              [
                -59.723641280276084,
                2.254952055499259
              ],
              [
                -59.72116379780743,
                2.252139083437736
              ],
              [
                -59.71936280803359,
                2.247340614463017
              ],
              [
                -59.718911800520594,
                2.24193514357483
              ],
              [
                -59.71873181008264,
                2.237494659017546
              ],
              [
                -59.723881285305275,
                2.233014197517518
              ],
              [
                -59.72707626045096,
                2.230234733193195
              ],
              [
                -59.72683228230223,
                2.226029235201496
              ],
              [
                -59.72599728602823,
                2.220628763136348
              ],
              [
                -59.72539330089136,
                2.216720307247423
              ],
              [
                -59.72441427610525,
                2.209379337705507
              ],
              [
                -59.723795800097214,
                2.205320364706845
              ],
              [
                -59.72880677900449,
                2.204182358184084
              ],
              [
                -59.731195755135836,
                2.20363986922471
              ],
              [
                -59.72914527167366,
                2.202200906114457
              ],
              [
                -59.72601627080691,
                2.20000441415942
              ],
              [
                -59.72832325650949,
                2.196179935741282
              ],
              [
                -59.7300642432558,
                2.193210442031454
              ],
              [
                -59.73119374768045,
                2.189989477613483
              ],
              [
                -59.73269424427183,
                2.185710480624491
              ],
              [
                -59.73242576481731,
                2.182782502988177
              ],
              [
                -59.7358722374468,
                2.180067042323604
              ],
              [
                -59.73630923252128,
                2.172029074371454
              ],
              [
                -59.739490701441504,
                2.16401663716812
              ],
              [
                -59.736254240858386,
                2.156704168663029
              ],
              [
                -59.736812226115646,
                2.151981194982543
              ],
              [
                -59.73718523804003,
                2.14854271258721
              ],
              [
                -59.73351524179297,
                2.141838759314051
              ],
              [
                -59.73097627543312,
                2.139643763556741
              ],
              [
                -59.72900128559936,
                2.137936282081122
              ],
              [
                -59.72642579304808,
                2.133902319268225
              ],
              [
                -59.72615629564255,
                2.124550378881641
              ],
              [
                -59.722810831866575,
                2.122843354961418
              ],
              [
                -59.720191839830456,
                2.11485093371344
              ],
              [
                -59.72065734328246,
                2.111123961059234
              ],
              [
                -59.72091186130659,
                2.106350461566594
              ],
              [
                -59.72269034060853,
                2.097654038916628
              ],
              [
                -59.728533318309125,
                2.094294526788529
              ],
              [
                -59.734024275018896,
                2.091138584329367
              ],
              [
                -59.73519425908243,
                2.088153587466291
              ],
              [
                -59.73828525147137,
                2.080019651847179
              ],
              [
                -59.74030225226832,
                2.074749156840936
              ],
              [
                -59.73972475013909,
                2.070038181678998
              ],
              [
                -59.73838275085175,
                2.064203237958812
              ],
              [
                -59.73743526669029,
                2.06013475985639
              ],
              [
                -59.73545976136578,
                2.050281321831726
              ],
              [
                -59.7321897964968,
                2.047360352176341
              ],
              [
                -59.731004816497034,
                2.04402087866383
              ],
              [
                -59.73032929342095,
                2.03769291408024
              ],
              [
                -59.7272243264421,
                2.035215419844032
              ],
              [
                -59.72709481323544,
                2.03511241741618
              ],
              [
                -59.72773083693807,
                2.030415441541446
              ],
              [
                -59.723196337965135,
                2.029241932110816
              ],
              [
                -59.71986887680768,
                2.028354949352345
              ],
              [
                -59.72072536912678,
                2.02392347096845
              ],
              [
                -59.72391234386994,
                2.022330480426161
              ],
              [
                -59.729559322618364,
                2.019197527796462
              ],
              [
                -59.731102315806325,
                2.018342009358167
              ],
              [
                -59.73360979273516,
                2.012851569762739
              ],
              [
                -59.731275795924766,
                2.008026087721371
              ],
              [
                -59.730799824022355,
                2.007041067428835
              ],
              [
                -59.73194529432027,
                2.003631600040554
              ],
              [
                -59.731958300258334,
                2.003583596329833
              ],
              [
                -59.73286281796236,
                2.000204127687178
              ],
              [
                -59.73277330597199,
                1.99860464416315
              ],
              [
                -59.732760818112745,
                1.998381633484343
              ],
              [
                -59.73264781638588,
                1.996365138430461
              ],
              [
                -59.7329563229297,
                1.991859692291325
              ],
              [
                -59.73287531261007,
                1.987855712052316
              ],
              [
                -59.73233581181873,
                1.984402208402741
              ],
              [
                -59.73524179294781,
                1.983685728540651
              ],
              [
                -59.73609580298995,
                1.983475231050096
              ],
              [
                -59.738086788684384,
                1.978801270412127
              ],
              [
                -59.73933375916395,
                1.976090293888039
              ],
              [
                -59.73994028079428,
                1.972082287870134
              ],
              [
                -59.73775078563722,
                1.968819812581123
              ],
              [
                -59.73584680467419,
                1.965982850852573
              ],
              [
                -59.73670828843736,
                1.963194336667893
              ],
              [
                -59.73749030671451,
                1.960244380416293
              ],
              [
                -59.73818529145907,
                1.957439893273471
              ],
              [
                -59.74206377756748,
                1.947585459923181
              ],
              [
                -59.74290376252151,
                1.944554454160637
              ],
              [
                -59.743353759607714,
                1.942064478070189
              ],
              [
                -59.743711273086575,
                1.940086513913131
              ],
              [
                -59.744075236538094,
                1.938070502983132
              ],
              [
                -59.74492673991243,
                1.933356036206597
              ],
              [
                -59.744972739705545,
                1.929713055832371
              ],
              [
                -59.74825322207741,
                1.92747455707931
              ],
              [
                -59.749373739791906,
                1.926445096515947
              ],
              [
                -59.75345021392606,
                1.922697615942322
              ],
              [
                -59.75388320232224,
                1.919458630277191
              ],
              [
                -59.7536572161374,
                1.914325657647732
              ],
              [
                -59.7535631913066,
                1.912193161264393
              ],
              [
                -59.755355680545435,
                1.909521167630696
              ],
              [
                -59.755552677472565,
                1.909227692428153
              ],
              [
                -59.75648317369137,
                1.906848195806457
              ],
              [
                -59.757307678454154,
                1.904739718546099
              ],
              [
                -59.75804467470069,
                1.902735206642931
              ],
              [
                -59.75871616528071,
                1.900907746670424
              ],
              [
                -59.75521469938996,
                1.895555273378444
              ],
              [
                -59.75505368640672,
                1.895308792792653
              ],
              [
                -59.7513937047008,
                1.889059811771414
              ],
              [
                -59.74954822975166,
                1.884832352294783
              ],
              [
                -59.74745174100595,
                1.881552844127983
              ],
              [
                -59.74849824151635,
                1.875703901092797
              ],
              [
                -59.750014241449634,
                1.872729406154147
              ],
              [
                -59.75052573672762,
                1.871726423243863
              ],
              [
                -59.75233272860764,
                1.866420952195694
              ],
              [
                -59.7512412203556,
                1.861686480933308
              ],
              [
                -59.74966023244356,
                1.858851007281195
              ],
              [
                -59.747464760170985,
                1.855912008068357
              ],
              [
                -59.74536428452455,
                1.85231203879929
              ],
              [
                -59.73353286055954,
                1.850569543771594
              ],
              [
                -59.72987235702233,
                1.850779544490518
              ],
              [
                -59.724436900173345,
                1.850099535981899
              ],
              [
                -59.72003841611516,
                1.849830554331409
              ],
              [
                -59.716850946391396,
                1.85151354945192
              ],
              [
                -59.71010796905788,
                1.855073991429851
              ],
              [
                -59.691218106518136,
                1.853966517640384
              ],
              [
                -59.68964711017807,
                1.852489016960607
              ],
              [
                -59.6828086432275,
                1.846057048186883
              ],
              [
                -59.68050665647,
                1.843577587248395
              ],
              [
                -59.67792416253681,
                1.840475573925886
              ],
              [
                -59.67343772871966,
                1.841312565696568
              ],
              [
                -59.67206721241966,
                1.842643082415051
              ],
              [
                -59.67102422927951,
                1.843655568112557
              ],
              [
                -59.668453737806125,
                1.845847051381168
              ],
              [
                -59.66530623669509,
                1.848168517713034
              ],
              [
                -59.66617274668563,
                1.851413496695655
              ],
              [
                -59.66855621914704,
                1.86034195936839
              ],
              [
                -59.6665357208655,
                1.864540424866211
              ],
              [
                -59.662310767153585,
                1.87019939905629
              ],
              [
                -59.66234627301531,
                1.86640293761187
              ],
              [
                -59.66231028506607,
                1.86342396229133
              ],
              [
                -59.65909231222089,
                1.859152488739915
              ],
              [
                -59.655907789487145,
                1.853774499770815
              ],
              [
                -59.654811297533826,
                1.851675005927018
              ],
              [
                -59.653904819358765,
                1.849940024972756
              ],
              [
                -59.65139732240786,
                1.845363550438941
              ],
              [
                -59.649848350320774,
                1.842536547788443
              ],
              [
                -59.655272326890106,
                1.82982314678606
              ],
              [
                -59.65706532146918,
                1.825687167835048
              ],
              [
                -59.65378933011401,
                1.817153710566171
              ],
              [
                -59.65977979144759,
                1.811233768354797
              ],
              [
                -59.664475270482384,
                1.812365751939765
              ],
              [
                -59.6654657785836,
                1.812604240799478
              ],
              [
                -59.672331201453964,
                1.807849797900314
              ],
              [
                -59.67704519171891,
                1.80777480164814
              ],
              [
                -59.67979067098263,
                1.798860834258465
              ],
              [
                -59.67990268034044,
                1.795111883514392
              ],
              [
                -59.680037676772834,
                1.7905789114225
              ],
              [
                -59.68196367366952,
                1.789710394097845
              ],
              [
                -59.684151151059275,
                1.788723900543367
              ],
              [
                -59.685875641249005,
                1.783753921468805
              ],
              [
                -59.68773464540197,
                1.779683952092755
              ],
              [
                -59.68544115223865,
                1.774701482991838
              ],
              [
                -59.684860147996915,
                1.773438513317543
              ],
              [
                -59.686390157748455,
                1.770608010502475
              ],
              [
                -59.68794162438356,
                1.767737526927911
              ],
              [
                -59.69078111940355,
                1.761237571196685
              ],
              [
                -59.6898581125697,
                1.758476090304146
              ],
              [
                -59.686791652166995,
                1.756407106734384
              ],
              [
                -59.67838219086598,
                1.755666602862602
              ],
              [
                -59.67333273170303,
                1.7552590909215
              ],
              [
                -59.67174922906068,
                1.75513110025488
              ],
              [
                -59.667634771434415,
                1.754581092409984
              ],
              [
                -59.66531279019091,
                1.754270088630107
              ],
              [
                -59.66470328279108,
                1.750449629106664
              ],
              [
                -59.66448430244406,
                1.749074144198831
              ],
              [
                -59.663836306273794,
                1.745231660794466
              ],
              [
                -59.66337428292414,
                1.741700688593144
              ],
              [
                -59.662208817923926,
                1.73737572490826
              ],
              [
                -59.65849732620517,
                1.739581697851003
              ],
              [
                -59.6558963339462,
                1.741788186083373
              ],
              [
                -59.65297387074038,
                1.743390158810701
              ],
              [
                -59.65143534889227,
                1.743054656389249
              ],
              [
                -59.64833890031249,
                1.742379682565073
              ],
              [
                -59.643973920035045,
                1.742364663491555
              ],
              [
                -59.63782945044226,
                1.741608187340311
              ],
              [
                -59.633575450560116,
                1.741431668254667
              ],
              [
                -59.63086051144035,
                1.734270714993956
              ],
              [
                -59.630404484569986,
                1.73258223088451
              ],
              [
                -59.62927600031175,
                1.728408258333612
              ],
              [
                -59.621910549451314,
                1.721802787556332
              ],
              [
                -59.615950097989895,
                1.716423837884965
              ],
              [
                -59.612129596888174,
                1.718857310451574
              ],
              [
                -59.608324117608994,
                1.721638275957582
              ],
              [
                -59.6080316472783,
                1.721852275611341
              ],
              [
                -59.60621813984188,
                1.722361788111972
              ],
              [
                -59.60527413034088,
                1.722626777291652
              ],
              [
                -59.598930170365634,
                1.724150755580255
              ],
              [
                -59.59570819300076,
                1.725582750234688
              ],
              [
                -59.586472773031424,
                1.727355262279981
              ],
              [
                -59.58476326139045,
                1.730151724229891
              ],
              [
                -59.58466428093241,
                1.730313231245064
              ],
              [
                -59.58260977452484,
                1.732351727600883
              ],
              [
                -59.57804780818114,
                1.733378731296311
              ],
              [
                -59.574360346585145,
                1.734762219105124
              ],
              [
                -59.57014434137745,
                1.735128207814683
              ],
              [
                -59.56710837409847,
                1.735902698410362
              ],
              [
                -59.560235925311936,
                1.729645237757188
              ],
              [
                -59.55366747699278,
                1.726226768329377
              ],
              [
                -59.549831490007925,
                1.72868972768609
              ],
              [
                -59.547982519464966,
                1.726633264415956
              ],
              [
                -59.54635998651862,
                1.72482925370633
              ],
              [
                -59.54369752903984,
                1.721775777147204
              ],
              [
                -59.53974556319571,
                1.722514286720254
              ],
              [
                -59.53624006152315,
                1.720141778896656
              ],
              [
                -59.533915098922535,
                1.717588293460099
              ],
              [
                -59.53369758835959,
                1.709256360910577
              ],
              [
                -59.52644011171829,
                1.703539404988661
              ],
              [
                -59.525981126735545,
                1.698450406106998
              ],
              [
                -59.52582612096397,
                1.697229933318175
              ],
              [
                -59.52551512943126,
                1.694767942865086
              ],
              [
                -59.522267164196066,
                1.688617971564087
              ],
              [
                -59.51759818677453,
                1.684762495268196
              ],
              [
                -59.51392771622892,
                1.681782504030725
              ],
              [
                -59.513445716177564,
                1.681391494230225
              ],
              [
                -59.51336872171435,
                1.681069535123427
              ],
              [
                -59.51047124290682,
                1.668937581460859
              ],
              [
                -59.50704627332347,
                1.668277597509646
              ],
              [
                -59.50662725374627,
                1.672469078499275
              ],
              [
                -59.50639077054717,
                1.674838047362787
              ],
              [
                -59.50210828358402,
                1.675975549508428
              ],
              [
                -59.49913781787568,
                1.677066026267694
              ],
              [
                -59.49260334431128,
                1.673950045117472
              ],
              [
                -59.49264034492329,
                1.670652065729583
              ],
              [
                -59.49324835133861,
                1.666627117806246
              ],
              [
                -59.4931248340113,
                1.662870637099491
              ],
              [
                -59.493879336302534,
                1.659857644195612
              ],
              [
                -59.494438344042614,
                1.655667177237044
              ],
              [
                -59.49455733156225,
                1.654771187590836
              ],
              [
                -59.493991852973856,
                1.650485708910469
              ],
              [
                -59.49425235963452,
                1.645646243727553
              ],
              [
                -59.48831087830076,
                1.639499279757017
              ],
              [
                -59.48641190547818,
                1.635674789003741
              ],
              [
                -59.48486940436833,
                1.632568304074446
              ],
              [
                -59.48233091174858,
                1.627021845393257
              ],
              [
                -59.47837445459508,
                1.625238840004275
              ],
              [
                -59.47202099509489,
                1.621547876360099
              ],
              [
                -59.469005514282244,
                1.620151890909209
              ],
              [
                -59.464551040140925,
                1.618129398827019
              ],
              [
                -59.46235205310629,
                1.618339898033359
              ],
              [
                -59.44736861246914,
                1.619773893809835
              ],
              [
                -59.445110155252024,
                1.613988397648107
              ],
              [
                -59.442498148391955,
                1.607097957968706
              ],
              [
                -59.4422431550651,
                1.606424437910051
              ],
              [
                -59.43971518046199,
                1.600523975366942
              ],
              [
                -59.43710019046195,
                1.594240040082989
              ],
              [
                -59.435042719998336,
                1.592785021231955
              ],
              [
                -59.42649877206043,
                1.586744565068811
              ],
              [
                -59.42652327062054,
                1.583788591647264
              ],
              [
                -59.42739577636052,
                1.579969118561843
              ],
              [
                -59.427741753634265,
                1.578608133734727
              ],
              [
                -59.42852075074613,
                1.575544635130355
              ],
              [
                -59.430361235707466,
                1.567660169427629
              ],
              [
                -59.42233128398031,
                1.556189757676018
              ],
              [
                -59.414711846704975,
                1.553746775387879
              ],
              [
                -59.410404865450865,
                1.552810768931191
              ],
              [
                -59.40630238739399,
                1.554412758437137
              ],
              [
                -59.40278440692758,
                1.555786750434982
              ],
              [
                -59.39890645253589,
                1.557421757793774
              ],
              [
                -59.39531595829841,
                1.555399759965238
              ],
              [
                -59.39457597508077,
                1.545133318833219
              ],
              [
                -59.39433546335169,
                1.541494847408043
              ],
              [
                -59.39430196960541,
                1.541257331812248
              ],
              [
                -59.393907468584786,
                1.538494361145178
              ],
              [
                -59.38351555536398,
                1.52917592511482
              ],
              [
                -59.38367156379464,
                1.523269940262473
              ],
              [
                -59.37861756419849,
                1.521411475138638
              ],
              [
                -59.37917408904166,
                1.516402485045862
              ],
              [
                -59.38025056757009,
                1.512390512143046
              ],
              [
                -59.38130905029338,
                1.507980541598502
              ],
              [
                -59.37827609542438,
                1.507362044577875
              ],
              [
                -59.372406116403354,
                1.506675038313722
              ],
              [
                -59.3646036509516,
                1.509970038930335
              ],
              [
                -59.362747656408615,
                1.508017033116726
              ],
              [
                -59.36100717115245,
                1.506186046604751
              ],
              [
                -59.35822518509798,
                1.513080010966923
              ],
              [
                -59.35820771118473,
                1.513154519519754
              ],
              [
                -59.3571451904693,
                1.517637485610395
              ],
              [
                -59.35218675377467,
                1.518188987183218
              ],
              [
                -59.345941774830436,
                1.518263984292776
              ],
              [
                -59.33923632156147,
                1.518623480602772
              ],
              [
                -59.335672832098936,
                1.516972488604401
              ],
              [
                -59.331634356755245,
                1.514019491247111
              ],
              [
                -59.3316878548464,
                1.512222020957234
              ],
              [
                -59.331766361981046,
                1.50957652371952
              ],
              [
                -59.33179386207847,
                1.508427041566547
              ],
              [
                -59.331822851179766,
                1.507220540753086
              ],
              [
                -59.33184037923651,
                1.50651052498229
              ],
              [
                -59.331849344270346,
                1.506126051272318
              ],
              [
                -59.329285375808496,
                1.500328089187245
              ],
              [
                -59.32114241802943,
                1.483150190551026
              ],
              [
                -59.32278593080719,
                1.480721685370954
              ],
              [
                -59.32740337989475,
                1.473899241994977
              ],
              [
                -59.32670590729911,
                1.468193793773766
              ],
              [
                -59.324881905991404,
                1.46430079452016
              ],
              [
                -59.318447432773546,
                1.461760819193969
              ],
              [
                -59.312419510417726,
                1.462534324250606
              ],
              [
                -59.30993100237387,
                1.466308285242407
              ],
              [
                -59.30986901772171,
                1.466401280675229
              ],
              [
                -59.300624563016015,
                1.464788806207657
              ],
              [
                -59.29645808950823,
                1.461482316957601
              ],
              [
                -59.292779098010115,
                1.458601341286367
              ],
              [
                -59.2862236612727,
                1.45270934490253
              ],
              [
                -59.283336683333545,
                1.448603373704228
              ],
              [
                -59.28790514494202,
                1.445052427844023
              ],
              [
                -59.287906624034804,
                1.444584411629538
              ],
              [
                -59.287921160051766,
                1.439491933936622
              ],
              [
                -59.285060658532686,
                1.437301943342035
              ],
              [
                -59.28305965676131,
                1.43577046701786
              ],
              [
                -59.2811261881821,
                1.431481488118478
              ],
              [
                -59.2794282019424,
                1.428395004115396
              ],
              [
                -59.27444574471516,
                1.41837958963192
              ],
              [
                -59.27672769665477,
                1.415979093406959
              ],
              [
                -59.28167019936709,
                1.41077911040494
              ],
              [
                -59.271530265472386,
                1.406990155770951
              ],
              [
                -59.26875626526071,
                1.400880671526
              ],
              [
                -59.25850583507095,
                1.396930713845579
              ],
              [
                -59.25236687834287,
                1.388595765141281
              ],
              [
                -59.24453640811627,
                1.387771756391189
              ],
              [
                -59.24172295592396,
                1.387475745559005
              ],
              [
                -59.23952744304094,
                1.387244238473567
              ],
              [
                -59.238056460829675,
                1.387089259085658
              ],
              [
                -59.233622478293405,
                1.386766266072897
              ],
              [
                -59.23151600435346,
                1.384272754473188
              ],
              [
                -59.22602903337121,
                1.377776791992756
              ],
              [
                -59.21344862112241,
                1.373772825200277
              ],
              [
                -59.2071036603409,
                1.371728340051009
              ],
              [
                -59.205833664439226,
                1.373998808263469
              ],
              [
                -59.204779651329964,
                1.375883303818687
              ],
              [
                -59.204021148568714,
                1.377240323576802
              ],
              [
                -59.19597921420462,
                1.378608276742521
              ],
              [
                -59.187959261630766,
                1.375566317188658
              ],
              [
                -59.18189482136676,
                1.367857854858298
              ],
              [
                -59.17874334293772,
                1.36403637297619
              ],
              [
                -59.17831883157833,
                1.363499906085041
              ],
              [
                -59.17625684640525,
                1.36089041720823
              ],
              [
                -59.173424849573365,
                1.359504910879267
              ],
              [
                -59.17075437875512,
                1.358237915878399
              ],
              [
                -59.167662892437804,
                1.357022930481526
              ],
              [
                -59.16609442468032,
                1.35756892590372
              ],
              [
                -59.16081343680185,
                1.359407909851493
              ],
              [
                -59.156604970909775,
                1.354468934426611
              ],
              [
                -59.15415398011443,
                1.351592474070535
              ],
              [
                -59.151767488853096,
                1.349115470347509
              ],
              [
                -59.14438151810634,
                1.34819748430691
              ],
              [
                -59.137401578309664,
                1.350417955634585
              ],
              [
                -59.13613209578982,
                1.350892960541139
              ],
              [
                -59.130991134084674,
                1.352813933027261
              ],
              [
                -59.12629713981577,
                1.349303963001815
              ],
              [
                -59.12607362818412,
                1.349135476961106
              ],
              [
                -59.12234116703713,
                1.346313473031934
              ],
              [
                -59.117818196686976,
                1.343114503192836
              ],
              [
                -59.113997722275904,
                1.344226489778462
              ],
              [
                -59.10930473795074,
                1.345967490430585
              ],
              [
                -59.10797427979709,
                1.346460979213511
              ],
              [
                -59.10328280049279,
                1.348072964632527
              ],
              [
                -59.09891782490518,
                1.346397477740501
              ],
              [
                -59.09543083501293,
                1.345028000202982
              ],
              [
                -59.08935136900271,
                1.346682483082728
              ],
              [
                -59.08656890619447,
                1.338904507954646
              ],
              [
                -59.085507409685015,
                1.338170018124099
              ],
              [
                -59.07573347145241,
                1.331405569532874
              ],
              [
                -59.06959251142845,
                1.330066090291065
              ],
              [
                -59.068361511410785,
                1.331956060754971
              ],
              [
                -59.06804151522584,
                1.33244704375785
              ],
              [
                -59.06739701488115,
                1.333437551711503
              ],
              [
                -59.065968008986175,
                1.335630549323261
              ],
              [
                -59.05712807596963,
                1.335001536289002
              ],
              [
                -59.05476860109116,
                1.329935551077862
              ],
              [
                -59.05453561058433,
                1.329434587023742
              ],
              [
                -59.05237211745349,
                1.324756120537938
              ],
              [
                -59.04700562882761,
                1.323363597778941
              ],
              [
                -59.03818120509374,
                1.326003111232197
              ],
              [
                -59.031593237043666,
                1.328360574814754
              ],
              [
                -59.02980224063443,
                1.328997069648798
              ],
              [
                -59.02652375602224,
                1.33016257801962
              ],
              [
                -59.022054309519355,
                1.33143005853882
              ],
              [
                -59.01546534297408,
                1.326333587685389
              ],
              [
                -59.012683836052645,
                1.324182108991888
              ],
              [
                -59.00620740081044,
                1.324274100140642
              ],
              [
                -59.004368413827386,
                1.324247092932711
              ],
              [
                -58.999905419566524,
                1.324180599433974
              ],
              [
                -58.993237474841074,
                1.327511065167825
              ],
              [
                -58.98871948648625,
                1.324577603811661
              ],
              [
                -58.986720526476354,
                1.31944713110967
              ],
              [
                -58.98655952550708,
                1.319111618084978
              ],
              [
                -58.98434903565753,
                1.314506163199855
              ],
              [
                -58.980139552628444,
                1.305578198781123
              ],
              [
                -58.978274560472414,
                1.302148244933687
              ],
              [
                -58.975367574229566,
                1.305539724066522
              ],
              [
                -58.972172604646225,
                1.309267675124349
              ],
              [
                -58.9686161125983,
                1.311115693357407
              ],
              [
                -58.9672751444957,
                1.311827181832107
              ],
              [
                -58.9654546502177,
                1.312792157693645
              ],
              [
                -58.95902019567983,
                1.313342676116922
              ],
              [
                -58.95543120416851,
                1.313483173561035
              ],
              [
                -58.95217422161235,
                1.313909151361687
              ],
              [
                -58.948213731321886,
                1.31410315041094
              ],
              [
                -58.94358876594703,
                1.314661649144516
              ],
              [
                -58.93157884775907,
                1.315800629927122
              ],
              [
                -58.92501689500857,
                1.316403134008045
              ],
              [
                -58.91802144788619,
                1.316902612618784
              ],
              [
                -58.915198469408196,
                1.313950657749183
              ],
              [
                -58.91544996105376,
                1.308060662100421
              ],
              [
                -58.91711943891052,
                1.303576688301991
              ],
              [
                -58.91930242244455,
                1.300320744242399
              ],
              [
                -58.91986341025579,
                1.299407232826344
              ],
              [
                -58.92323740407635,
                1.293910767418471
              ],
              [
                -58.92181240232016,
                1.290322801632534
              ],
              [
                -58.9179419385152,
                1.285287841037969
              ],
              [
                -58.90427804226455,
                1.281436336455661
              ],
              [
                -58.898239579842006,
                1.279734361482896
              ],
              [
                -58.89376060113966,
                1.272370895743651
              ],
              [
                -58.891259595938195,
                1.26826040689473
              ],
              [
                -58.88948760809788,
                1.265521426968058
              ],
              [
                -58.88630263315518,
                1.260594955880516
              ],
              [
                -58.892905108156704,
                1.257139487844141
              ],
              [
                -58.89545410711332,
                1.255745005683426
              ],
              [
                -58.89927405957072,
                1.253654014935014
              ],
              [
                -58.90175156218093,
                1.251070010881852
              ],
              [
                -58.90351803904618,
                1.248556536269199
              ],
              [
                -58.904072030995934,
                1.241706579392543
              ],
              [
                -58.91149450800976,
                1.2386530930688
              ],
              [
                -58.90842348883908,
                1.236508126333606
              ],
              [
                -58.903141559124286,
                1.2333691221379
              ],
              [
                -58.90082404232958,
                1.231780653215441
              ],
              [
                -58.89788955931727,
                1.229769153711789
              ],
              [
                -58.89494508927913,
                1.228228179390283
              ],
              [
                -58.8949245740408,
                1.224746195235013
              ],
              [
                -58.89542264896971,
                0.614104436569218
              ],
              [
                -58.89488970613707,
                0.263852054215423
              ],
              [
                -59.19175386270916,
                0.263858069069289
              ],
              [
                -59.43302887654151,
                0.263858070763602
              ],
              [
                -60.03743425115737,
                0.263836604292945
              ],
              [
                -60.04630218964332,
                0.232453779761894
              ],
              [
                -60.05855062033519,
                0.163318667265663
              ],
              [
                -60.072720521098766,
                0.150233749117977
              ],
              [
                -60.086322461049825,
                0.143009295540162
              ],
              [
                -60.09856787384823,
                0.139078821164498
              ],
              [
                -60.11576078591383,
                0.133560838899038
              ],
              [
                -60.11784077090942,
                0.128708378521338
              ],
              [
                -60.11784227890078,
                0.128703371549854
              ],
              [
                -60.11992024590507,
                0.123854898143764
              ],
              [
                -60.137417661499995,
                0.103016530811901
              ],
              [
                -60.135748140351915,
                0.093299588951556
              ],
              [
                -60.131054194684864,
                0.090634610014287
              ],
              [
                -60.12744822168408,
                0.088587134588886
              ],
              [
                -60.1229947506798,
                0.081373666485205
              ],
              [
                -60.13486465680563,
                0.067192759108491
              ],
              [
                -60.136017170117796,
                0.065816270930348
              ],
              [
                -60.13554764787545,
                0.062990282959397
              ],
              [
                -60.134116168275646,
                0.054368321860437
              ],
              [
                -60.137595648074736,
                0.050389846455762
              ],
              [
                -60.13990163068655,
                0.047753363426071
              ],
              [
                -60.14123361997486,
                0.042982908487983
              ],
              [
                -60.14269760149537,
                0.037737908236745
              ],
              [
                -60.138037148634915,
                0.029094988214698
              ],
              [
                -60.160197523865335,
                0.016602522088735
              ],
              [
                -60.17047946114475,
                0.000521153172174
              ],
              [
                -60.17467441977563,
                0.000347150157526
              ],
              [
                -60.183578879057286,
                -0.000023361745137
              ],
              [
                -60.181568874725464,
                -0.007907830125237
              ],
              [
                -60.18113739622904,
                -0.009601790538951
              ],
              [
                -60.20274975066545,
                -0.033267661762526
              ],
              [
                -60.20572423719874,
                -0.061209994880805
              ],
              [
                -60.21370318378374,
                -0.071972950172289
              ],
              [
                -60.22168213636089,
                -0.082735380505712
              ],
              [
                -60.22830510568647,
                -0.10085879432851
              ],
              [
                -60.22997907682911,
                -0.110037723578238
              ],
              [
                -60.22467864741586,
                -0.119196672826547
              ],
              [
                -60.226510609648095,
                -0.126696120567748
              ],
              [
                -60.22692011024015,
                -0.128369100142041
              ],
              [
                -60.21997715438525,
                -0.135032576426443
              ],
              [
                -60.22386715165858,
                -0.142530038235822
              ],
              [
                -60.23219708125989,
                -0.146143516052233
              ],
              [
                -60.24942398687632,
                -0.16669088671187
              ],
              [
                -60.24887097287314,
                -0.174743365355959
              ],
              [
                -60.2553499243468,
                -0.173528354370038
              ],
              [
                -60.257753942424216,
                -0.173077845970247
              ],
              [
                -60.26553487619584,
                -0.178075340053266
              ],
              [
                -60.26438738363386,
                -0.185721785993886
              ],
              [
                -60.263866884551284,
                -0.189188777471422
              ],
              [
                -60.275532816566034,
                -0.202245706379585
              ],
              [
                -60.280413784164416,
                -0.206293167775653
              ],
              [
                -60.28525278200818,
                -0.210305151377622
              ],
              [
                -60.293032724109956,
                -0.205303666737807
              ],
              [
                -60.29886369815297,
                -0.211413643590244
              ],
              [
                -60.29207571894872,
                -0.226687024549825
              ],
              [
                -60.29108322461883,
                -0.228920024970005
              ],
              [
                -60.298866175557556,
                -0.25586788292415
              ],
              [
                -60.301643656307796,
                -0.260172364871802
              ],
              [
                -60.30442115505628,
                -0.264476332828076
              ],
              [
                -60.29965066630578,
                -0.273485262072949
              ],
              [
                -60.28941721773052,
                -0.292810675181258
              ],
              [
                -60.29594620367854,
                -0.29822762608683
              ],
              [
                -60.302474640631715,
                -0.30364459300634
              ],
              [
                -60.302248659632866,
                -0.310219575124785
              ],
              [
                -60.30164464727593,
                -0.3278089704802
              ],
              [
                -60.312352109212206,
                -0.329440955875606
              ],
              [
                -60.318032565293485,
                -0.330306932312026
              ],
              [
                -60.321083060288096,
                -0.337253427553369
              ],
              [
                -60.31878207003302,
                -0.34818583019787
              ],
              [
                -60.316640073881864,
                -0.358361289095239
              ],
              [
                -60.32164254238063,
                -0.36835574441614
              ],
              [
                -60.321640017891,
                -0.377244186662003
              ],
              [
                -60.32958249768021,
                -0.381688166256712
              ],
              [
                -60.33553444005507,
                -0.385019148972227
              ],
              [
                -60.34275089533549,
                -0.401410051740333
              ],
              [
                -60.349449356258546,
                -0.406959508133825
              ],
              [
                -60.343891410034615,
                -0.412787988203134
              ],
              [
                -60.34528290351185,
                -0.420292914345087
              ],
              [
                -60.34421438351971,
                -0.422962430356478
              ],
              [
                -60.34139142338801,
                -0.430012366772294
              ],
              [
                -60.344167911650494,
                -0.440284815560603
              ],
              [
                -60.37478170502233,
                -0.464362683265713
              ],
              [
                -60.381950683199946,
                -0.470001133327567
              ],
              [
                -60.382227677279985,
                -0.474447611547676
              ],
              [
                -60.38250468635692,
                -0.478893610772999
              ],
              [
                -60.38883963589065,
                -0.490691037469607
              ],
              [
                -60.39055863472148,
                -0.493892502754821
              ],
              [
                -60.39055611665961,
                -0.494907990094581
              ],
              [
                -60.3905336201893,
                -0.502809960053143
              ],
              [
                -60.39337608560123,
                -0.505031942892089
              ],
              [
                -60.39942305731398,
                -0.509758902029399
              ],
              [
                -60.39747557668162,
                -0.545584204363756
              ],
              [
                -60.39442059769746,
                -0.5530841843532
              ],
              [
                -60.39004611984069,
                -0.554541645865902
              ],
              [
                -60.38191915755907,
                -0.557249163094495
              ],
              [
                -60.37887020154451,
                -0.576145553624051
              ],
              [
                -60.379553167563714,
                -0.576541540480435
              ],
              [
                -60.38748312634563,
                -0.581141515586621
              ],
              [
                -60.38275716288008,
                -0.589747469270914
              ],
              [
                -60.37373071812444,
                -0.588747958013394
              ],
              [
                -60.36525277420527,
                -0.587808964471619
              ],
              [
                -60.36469826160901,
                -0.596698394933509
              ],
              [
                -60.357475808597265,
                -0.605030360883248
              ],
              [
                -60.35432082912397,
                -0.612730819721905
              ],
              [
                -60.350533863151696,
                -0.621973253916356
              ],
              [
                -60.345393897716626,
                -0.623637759157784
              ],
              [
                -60.34025342028804,
                -0.625302764394129
              ],
              [
                -60.33735793072431,
                -0.62856725294013
              ],
              [
                -60.327198492391744,
                -0.640023662164084
              ],
              [
                -60.32218101363261,
                -0.637878163616447
              ],
              [
                -60.3194215307157,
                -0.636698198916566
              ],
              [
                -60.313032083263174,
                -0.641139169553978
              ],
              [
                -60.31399206111748,
                -0.657233549025029
              ],
              [
                -60.31414255099378,
                -0.659756056995952
              ],
              [
                -60.31245305755498,
                -0.661921032770496
              ],
              [
                -60.30330811730318,
                -0.673640484581067
              ],
              [
                -60.3073681054681,
                -0.686505911389826
              ],
              [
                -60.30830408773935,
                -0.6894723679797
              ],
              [
                -60.30759859478317,
                -0.689855383773182
              ],
              [
                -60.30164261492478,
                -0.693085870159552
              ],
              [
                -60.303100628576885,
                -0.710940234963957
              ],
              [
                -60.30914208012431,
                -0.724475184345713
              ],
              [
                -60.31535253735387,
                -0.722865679988231
              ],
              [
                -60.31664203292748,
                -0.72253117699174
              ],
              [
                -60.3408093933111,
                -0.738639611190569
              ],
              [
                -60.34082688620703,
                -0.738665598048226
              ],
              [
                -60.345530379222836,
                -0.745585057155673
              ],
              [
                -60.3509958358931,
                -0.743151083298458
              ],
              [
                -60.35302733824883,
                -0.742246589553271
              ],
              [
                -60.37692465808621,
                -0.742525093742449
              ],
              [
                -60.37927116675324,
                -0.74934453517688
              ],
              [
                -60.38275514146082,
                -0.759469990418314
              ],
              [
                -60.39794055096379,
                -0.757574487704954
              ],
              [
                -60.40052954304967,
                -0.757251497797917
              ],
              [
                -60.418584937036556,
                -0.763919474964064
              ],
              [
                -60.420049442976406,
                -0.769120435326181
              ],
              [
                -60.42108641955871,
                -0.772804420044311
              ],
              [
                -60.42663938288829,
                -0.778079900493583
              ],
              [
                -60.43413982329701,
                -0.772253926648472
              ],
              [
                -60.457475210506026,
                -0.773917426225143
              ],
              [
                -60.462301660005494,
                -0.771136929184083
              ],
              [
                -60.466639130293224,
                -0.768637930524536
              ],
              [
                -60.47859007718248,
                -0.770587423161583
              ],
              [
                -60.49358647339852,
                -0.782244882514587
              ],
              [
                -60.497477440153425,
                -0.789193310591656
              ],
              [
                -60.491640501903106,
                -0.796420793944785
              ],
              [
                -60.492275475085194,
                -0.797737259749472
              ],
              [
                -60.49525396917287,
                -0.803919237966533
              ],
              [
                -60.493307484393796,
                -0.811971699246247
              ],
              [
                -60.49831245731844,
                -0.821973142647259
              ],
              [
                -60.50248791832606,
                -0.82152316166139
              ],
              [
                -60.506089914630856,
                -0.821135661257419
              ],
              [
                -60.519423808316986,
                -0.833360584497612
              ],
              [
                -60.50798940547136,
                -0.838691565608377
              ],
              [
                -60.502752434317394,
                -0.841133516453851
              ],
              [
                -60.50414089931906,
                -0.848911976713681
              ],
              [
                -60.53053524097175,
                -0.874747836767868
              ],
              [
                -60.54053517439259,
                -0.875026342584721
              ],
              [
                -60.546150154805616,
                -0.869643386995161
              ],
              [
                -60.547201156893315,
                -0.868635394688929
              ],
              [
                -60.54971666234463,
                -0.869230368749297
              ],
              [
                -60.555832613976406,
                -0.870676387607162
              ],
              [
                -60.55619759720438,
                -0.868210383236923
              ],
              [
                -60.557450091117275,
                -0.859742938039885
              ],
              [
                -60.59080839823477,
                -0.853080481136918
              ],
              [
                -60.59233839920791,
                -0.862523404375645
              ],
              [
                -60.593868369167794,
                -0.871967383622893
              ],
              [
                -60.60675379405012,
                -0.865178890692554
              ],
              [
                -60.60914628647608,
                -0.863918916941812
              ],
              [
                -60.61997972937972,
                -0.863082925984994
              ],
              [
                -60.62219372701611,
                -0.868023901118021
              ],
              [
                -60.6235876936005,
                -0.871135873199441
              ],
              [
                -60.627959683686285,
                -0.869761392342423
              ],
              [
                -60.63331113419412,
                -0.868079383305304
              ],
              [
                -60.63448014094162,
                -0.872169886984956
              ],
              [
                -60.63553413640086,
                -0.875856369872168
              ],
              [
                -60.654145013689,
                -0.882803296248021
              ],
              [
                -60.66748144865948,
                -0.894188243590152
              ],
              [
                -60.67220241249815,
                -0.887522791815704
              ],
              [
                -60.67069392883809,
                -0.882408804672881
              ],
              [
                -60.669416938742174,
                -0.878077334271716
              ],
              [
                -60.67525939391397,
                -0.873082882475313
              ],
              [
                -60.707201197910614,
                -0.865301941460048
              ],
              [
                -60.71087317465009,
                -0.867032421462563
              ],
              [
                -60.71664214725136,
                -0.86975041033546
              ],
              [
                -60.72913559798211,
                -0.860209445310533
              ],
              [
                -60.73192055431957,
                -0.85808246333964
              ],
              [
                -60.7341860563115,
                -0.858392466941524
              ],
              [
                -60.75219393494099,
                -0.860856943834638
              ],
              [
                -60.75157044964949,
                -0.852138513514223
              ],
              [
                -60.75139394098019,
                -0.849672032004761
              ],
              [
                -60.75409943150042,
                -0.84195507164303
              ],
              [
                -60.75581994528794,
                -0.83704907175008
              ],
              [
                -60.75886739896248,
                -0.828359131764533
              ],
              [
                -60.75621492262188,
                -0.824504165553092
              ],
              [
                -60.752750943070296,
                -0.81946967070976
              ],
              [
                -60.757191933615424,
                -0.811855225015416
              ],
              [
                -60.75858243395719,
                -0.809471758257109
              ],
              [
                -60.7585149093911,
                -0.80908674635944
              ],
              [
                -60.75608891695828,
                -0.795304310632995
              ],
              [
                -60.766367854490625,
                -0.792650328450516
              ],
              [
                -60.77331434298697,
                -0.790856866135243
              ],
              [
                -60.77275083627686,
                -0.781975882758467
              ],
              [
                -60.77149183556983,
                -0.781186913932283
              ],
              [
                -60.76608934886605,
                -0.777801932843751
              ],
              [
                -60.760537899575574,
                -0.761140028590675
              ],
              [
                -60.76831435890734,
                -0.754195052611606
              ],
              [
                -60.77664431508712,
                -0.755861542442923
              ],
              [
                -60.780532779720026,
                -0.748365591015818
              ],
              [
                -60.78259427005401,
                -0.747334097912977
              ],
              [
                -60.78830575773532,
                -0.744474616257792
              ],
              [
                -60.79664120610233,
                -0.744470631241024
              ],
              [
                -60.799146679792464,
                -0.735585155255672
              ],
              [
                -60.79275620614731,
                -0.728085201669419
              ],
              [
                -60.793973710024765,
                -0.720549753148289
              ],
              [
                -60.79719919576459,
                -0.700580371085778
              ],
              [
                -60.804419150989915,
                -0.696137902247468
              ],
              [
                -60.81163909322044,
                -0.691695423401487
              ],
              [
                -60.842757922705026,
                -0.690306433352304
              ],
              [
                -60.85136886922406,
                -0.674470533085527
              ],
              [
                -60.8489208778045,
                -0.668504549544234
              ],
              [
                -60.846924418692126,
                -0.663639061021786
              ],
              [
                -60.84887091056556,
                -0.653919624532441
              ],
              [
                -60.85414384711811,
                -0.647528189123971
              ],
              [
                -60.86053282522079,
                -0.641971689240805
              ],
              [
                -60.88220269896624,
                -0.648918149322817
              ],
              [
                -60.907752044831845,
                -0.62364532234529
              ],
              [
                -60.90804753355592,
                -0.619365338827465
              ],
              [
                -60.90830954842126,
                -0.615580863592439
              ],
              [
                -60.91089755185916,
                -0.613666876328461
              ],
              [
                -60.91581150621094,
                -0.610031869714894
              ],
              [
                -60.91553350068294,
                -0.589473011372575
              ],
              [
                -60.91372803035321,
                -0.585170522796596
              ],
              [
                -60.91192153702712,
                -0.580866069235767
              ],
              [
                -60.92025598880679,
                -0.555035695634934
              ],
              [
                -60.92970391305837,
                -0.554476220516561
              ],
              [
                -60.93525138702685,
                -0.55975867203208
              ],
              [
                -60.93792939079516,
                -0.55961966902669
              ],
              [
                -60.946085839410415,
                -0.559196196055967
              ],
              [
                -60.94804233240233,
                -0.557730689257465
              ],
              [
                -60.95275530388629,
                -0.554198717020802
              ],
              [
                -60.95635378505576,
                -0.55839066736072
              ],
              [
                -60.95775776292839,
                -0.560026157521659
              ],
              [
                -60.97498167777465,
                -0.562252161829272
              ],
              [
                -60.98748060683424,
                -0.560863690520849
              ],
              [
                -60.999979026920585,
                -0.559475175207608
              ],
              [
                -61.008296483117896,
                -0.554146203086812
              ],
              [
                -61.02470038083116,
                -0.543637266978792
              ],
              [
                -61.03046983579101,
                -0.546991241122594
              ],
              [
                -61.03331331750825,
                -0.548645239319444
              ],
              [
                -61.04553574238661,
                -0.545590259061584
              ],
              [
                -61.054698219994066,
                -0.538360321355255
              ],
              [
                -61.06331513916864,
                -0.512807942750279
              ],
              [
                -61.08692549729421,
                -0.499758536942156
              ],
              [
                -61.09150599435092,
                -0.499478034830775
              ],
              [
                -61.09608597141373,
                -0.499198021716367
              ],
              [
                -61.097399438920334,
                -0.499842523296931
              ],
              [
                -61.11192284507724,
                -0.506970977496619
              ],
              [
                -61.117586814448,
                -0.501091011048331
              ],
              [
                -61.11914633373919,
                -0.49947203200989
              ],
              [
                -61.12858627444845,
                -0.497255027845759
              ],
              [
                -61.14830863567361,
                -0.498921024281305
              ],
              [
                -61.15470113002759,
                -0.505032990439352
              ],
              [
                -61.1549171287342,
                -0.505950011454725
              ],
              [
                -61.15705058495305,
                -0.51499896027632
              ],
              [
                -61.17303101611864,
                -0.507811504733136
              ],
              [
                -61.19802985095612,
                -0.505588502817905
              ],
              [
                -61.20135932832112,
                -0.503246494836878
              ],
              [
                -61.208304795450246,
                -0.498362529980616
              ],
              [
                -61.21636474280884,
                -0.499750047050406
              ],
              [
                -61.22248173082795,
                -0.505309518260762
              ],
              [
                -61.222756201206344,
                -0.518920423200064
              ],
              [
                -61.219265236363434,
                -0.525902400920153
              ],
              [
                -61.21581227730655,
                -0.532807862057547
              ],
              [
                -61.22227922235965,
                -0.537889837945888
              ],
              [
                -61.22358821288142,
                -0.538918831457876
              ],
              [
                -61.223587710433904,
                -0.544327784035757
              ],
              [
                -61.22358619718167,
                -0.559194194186018
              ],
              [
                -61.22706267535634,
                -0.561419197391474
              ],
              [
                -61.230537169543354,
                -0.563643691602465
              ],
              [
                -61.274975394692426,
                -0.575862625173068
              ],
              [
                -61.28234284728537,
                -0.574273108459752
              ],
              [
                -61.28525885889887,
                -0.57364362213645
              ],
              [
                -61.2977582592391,
                -0.575862610216895
              ],
              [
                -61.32553611277556,
                -0.59753250828643
              ],
              [
                -61.347938467856366,
                -0.595194006033925
              ],
              [
                -61.356367924149865,
                -0.598086475159662
              ],
              [
                -61.361837418925674,
                -0.6029299594589
              ],
              [
                -61.36609237263571,
                -0.606698468469692
              ],
              [
                -61.37520782598265,
                -0.610725409551968
              ],
              [
                -61.389977226313114,
                -0.617249895689272
              ],
              [
                -61.39831067480277,
                -0.624194361966022
              ],
              [
                -61.40664313430109,
                -0.631139306263492
              ],
              [
                -61.408236131329474,
                -0.631343306318513
              ],
              [
                -61.42831452528272,
                -0.633914804415991
              ],
              [
                -61.43025948848499,
                -0.642809240607354
              ],
              [
                -61.428848505073525,
                -0.646189725223766
              ],
              [
                -61.42608754191824,
                -0.652805202247094
              ],
              [
                -61.43469945802126,
                -0.658084168648519
              ],
              [
                -61.445154912387395,
                -0.657414676332301
              ],
              [
                -61.4563673495272,
                -0.656696173355874
              ],
              [
                -61.4641402991193,
                -0.66447111525317
              ],
              [
                -61.472478765378135,
                -0.683359513380013
              ],
              [
                -61.47361873880911,
                -0.694421953936377
              ],
              [
                -61.47442575443968,
                -0.702251936872768
              ],
              [
                -61.478559232443494,
                -0.709715884156312
              ],
              [
                -61.4826931824386,
                -0.71718032745297
              ],
              [
                -61.51609148305962,
                -0.745306707064589
              ],
              [
                -61.527202917205706,
                -0.750585668011985
              ],
              [
                -61.538314375366305,
                -0.755865118978219
              ],
              [
                -61.55248426042934,
                -0.810585348251485
              ],
              [
                -61.549420777977694,
                -0.838879655269647
              ],
              [
                -61.54302932421258,
                -0.843087151892618
              ],
              [
                -61.5430368330092,
                -0.851971605306253
              ],
              [
                -61.55368326629728,
                -0.860409540549654
              ],
              [
                -61.55776122404413,
                -0.863641527799071
              ],
              [
                -61.55792672894806,
                -0.872378466064085
              ],
              [
                -61.55831374100083,
                -0.892807380821713
              ],
              [
                -61.568172178664476,
                -0.901276835911694
              ],
              [
                -61.57803060333195,
                -0.909746298035726
              ],
              [
                -61.579700598113405,
                -0.930022670944858
              ],
              [
                -61.584703060175606,
                -0.936690113065561
              ],
              [
                -61.57914358151073,
                -0.955582532370026
              ],
              [
                -61.583031069781924,
                -0.982245383659224
              ],
              [
                -61.57553462586674,
                -0.987805338840094
              ],
              [
                -61.56964915479694,
                -0.987773333102375
              ],
              [
                -61.56019722169339,
                -0.98772186291832
              ],
              [
                -61.562482190019956,
                -1.000026284600283
              ],
              [
                -61.561306693962905,
                -1.004135244489372
              ],
              [
                -61.55970071207824,
                -1.009749713277694
              ],
              [
                -61.55311023086409,
                -1.016789698669119
              ],
              [
                -61.547477771447326,
                -1.022806667663918
              ],
              [
                -61.54525430492825,
                -1.028918636693121
              ],
              [
                -61.54303129540179,
                -1.035030072725117
              ],
              [
                -61.543588296926444,
                -1.061970442112263
              ],
              [
                -61.54484127118176,
                -1.066414399624052
              ],
              [
                -61.54609276944253,
                -1.070858371139513
              ],
              [
                -61.55783571940226,
                -1.072714895210065
              ],
              [
                -61.56720317273623,
                -1.074195880889965
              ],
              [
                -61.576094613207495,
                -1.078917867233372
              ],
              [
                -61.57776107035326,
                -1.088083781706526
              ],
              [
                -61.57296610940782,
                -1.092654272553725
              ],
              [
                -61.565813653272976,
                -1.099472752021331
              ],
              [
                -61.57233562211709,
                -1.103385734309928
              ],
              [
                -61.574146096803396,
                -1.104472200838565
              ],
              [
                -61.577481603719505,
                -1.112802170080439
              ],
              [
                -61.572388122301554,
                -1.118101125992355
              ],
              [
                -61.570536625327655,
                -1.120027139418348
              ],
              [
                -61.56609464105783,
                -1.132251039584603
              ],
              [
                -61.56858612270513,
                -1.140304530176378
              ],
              [
                -61.57360409069507,
                -1.145608468858659
              ],
              [
                -61.57830958849113,
                -1.150580936258823
              ],
              [
                -61.58164205642066,
                -1.164189386512882
              ],
              [
                -61.57810857663416,
                -1.172792812138337
              ],
              [
                -61.573316105322334,
                -1.184460773243029
              ],
              [
                -61.58079054878184,
                -1.188439238488448
              ],
              [
                -61.58220256174615,
                -1.189190739758223
              ],
              [
                -61.58776202822164,
                -1.196966196493648
              ],
              [
                -61.594353470078346,
                -1.219941582760069
              ],
              [
                -61.595253463455016,
                -1.22307753928913
              ],
              [
                -61.60083242910344,
                -1.234469497917557
              ],
              [
                -61.6064099057449,
                -1.245860942582685
              ],
              [
                -61.60671138672338,
                -1.257951872536948
              ],
              [
                -61.60720587707522,
                -1.277799241442638
              ],
              [
                -61.61284786662045,
                -1.282461243871321
              ],
              [
                -61.62637077583733,
                -1.293637170408318
              ],
              [
                -61.628317274461395,
                -1.301135632109117
              ],
              [
                -61.61109285136248,
                -1.307803084298613
              ],
              [
                -61.60052042795211,
                -1.320170012642153
              ],
              [
                -61.584975497147056,
                -1.338353938119369
              ],
              [
                -61.581642003735865,
                -1.353072829479196
              ],
              [
                -61.58720097695426,
                -1.361137316966819
              ],
              [
                -61.59535593049256,
                -1.365214265129025
              ],
              [
                -61.59942491931294,
                -1.367248273236605
              ],
              [
                -61.61859080838379,
                -1.394467131460003
              ],
              [
                -61.614150330520296,
                -1.4022425684023
              ],
              [
                -61.60796935552498,
                -1.404744568824476
              ],
              [
                -61.60372740008379,
                -1.405453564145698
              ],
              [
                -61.581373512277295,
                -1.409189536477028
              ],
              [
                -61.57734402172776,
                -1.418772962907078
              ],
              [
                -61.57331405016967,
                -1.428355427340168
              ],
              [
                -61.555216155326946,
                -1.428936912224926
              ],
              [
                -61.54747970258507,
                -1.429185407183005
              ],
              [
                -61.537763267138004,
                -1.433072385440615
              ],
              [
                -61.53886323864162,
                -1.44360084797137
              ],
              [
                -61.5388667646152,
                -1.443630822813578
              ],
              [
                -61.532482767637234,
                -1.464740206681467
              ],
              [
                -61.515949883722044,
                -1.50209799825867
              ],
              [
                -61.480814569135326,
                -1.517515422413483
              ],
              [
                -61.472759128981615,
                -1.527517367566054
              ],
              [
                -61.46942563925909,
                -1.537792810390561
              ],
              [
                -61.46997461432899,
                -1.545575758341087
              ],
              [
                -61.47498008151861,
                -1.552796237355136
              ],
              [
                -61.474144101973444,
                -1.578631597537575
              ],
              [
                -61.479950546104,
                -1.579833568615315
              ],
              [
                -61.480148048986045,
                -1.579874571447283
              ],
              [
                -61.48220306435424,
                -1.580299554708012
              ],
              [
                -61.49052898807229,
                -1.574468095575703
              ],
              [
                -61.54415019332679,
                -1.525292910341964
              ],
              [
                -61.559147609478536,
                -1.49474004525904
              ],
              [
                -61.56539710144855,
                -1.486268116467114
              ],
              [
                -61.57164603941689,
                -1.477796150666685
              ],
              [
                -61.604422381873604,
                -1.44779834259335
              ],
              [
                -61.63498169826079,
                -1.433630905266351
              ],
              [
                -61.646226626890716,
                -1.431690419024776
              ],
              [
                -61.64623062786873,
                -1.431688921033557
              ],
              [
                -61.657479544495615,
                -1.42974794878936
              ],
              [
                -61.71748073729662,
                -1.409460047047943
              ],
              [
                -61.74025611626546,
                -1.391685643647264
              ],
              [
                -61.76915243636964,
                -1.382521694160148
              ],
              [
                -61.78968032200342,
                -1.378634756046427
              ],
              [
                -61.797476249297326,
                -1.380297252988619
              ],
              [
                -61.81422267566241,
                -1.39679366693228
              ],
              [
                -61.81665063765183,
                -1.399185630898883
              ],
              [
                -61.82151161539908,
                -1.40029864211903
              ],
              [
                -61.826372117152424,
                -1.401411623341153
              ],
              [
                -61.83877803724063,
                -1.401411624065573
              ],
              [
                -61.86886737320143,
                -1.401411642674759
              ],
              [
                -61.89609370556102,
                -1.395022173140547
              ],
              [
                -61.90053569663766,
                -1.387106233595918
              ],
              [
                -61.904978156705326,
                -1.379189758050121
              ],
              [
                -61.906439165253076,
                -1.366742825607878
              ],
              [
                -61.90859663816033,
                -1.348353928482443
              ],
              [
                -61.91429910937191,
                -1.339626467451296
              ],
              [
                -61.92000055958268,
                -1.330899045412586
              ],
              [
                -61.94942443257695,
                -1.296688706865239
              ],
              [
                -61.95637337928551,
                -1.265574390439103
              ],
              [
                -61.961373875858676,
                -1.2540494478857
              ],
              [
                -61.96637332142115,
                -1.242525020326003
              ],
              [
                -61.97331477166208,
                -1.233936565734298
              ],
              [
                -61.987481692628435,
                -1.216408173417477
              ],
              [
                -61.98817422820806,
                -1.208909198892682
              ],
              [
                -61.98886621178211,
                -1.201410258372092
              ],
              [
                -62.016926562567406,
                -1.14141259144069
              ],
              [
                -62.03914745291356,
                -1.11807619088298
              ],
              [
                -62.06164580891802,
                -1.0994708054444
              ],
              [
                -62.06636977204016,
                -1.098361319081484
              ],
              [
                -62.071093742165466,
                -1.09725234371447
              ],
              [
                -62.08747917336371,
                -1.0997488234494
              ],
              [
                -62.09914908959051,
                -1.096412845952187
              ],
              [
                -62.10498057418673,
                -1.086138396810109
              ],
              [
                -62.114144504200944,
                -1.083074921429443
              ],
              [
                -62.11775800545341,
                -1.076136936404739
              ],
              [
                -62.11465051277525,
                -1.068927476670999
              ],
              [
                -62.1108150353801,
                -1.06002952568284
              ],
              [
                -62.1249884360432,
                -1.036973161929255
              ],
              [
                -62.13411090679946,
                -1.036973164416437
              ],
              [
                -62.142478870722734,
                -1.03697318278066
              ],
              [
                -62.144980336375916,
                -1.02808369978385
              ],
              [
                -62.15442528574087,
                -1.025857745976403
              ],
              [
                -62.167759699255754,
                -1.013918804680932
              ],
              [
                -62.16956769896379,
                -1.00975130580706
              ],
              [
                -62.171376186666905,
                -1.005584322930105
              ],
              [
                -62.178582150253085,
                -1.004156361551483
              ],
              [
                -62.199429532270145,
                -1.000024381481385
              ],
              [
                -62.201701511361456,
                -0.989805424353797
              ],
              [
                -62.20332100657611,
                -0.982521975753338
              ],
              [
                -62.21220496763876,
                -0.983285946126246
              ],
              [
                -62.213032957078305,
                -0.983357461879868
              ],
              [
                -62.216682942435,
                -0.980665492539555
              ],
              [
                -62.22692336732917,
                -0.973111530663728
              ],
              [
                -62.23612731867538,
                -0.973831537304975
              ],
              [
                -62.2585932000901,
                -0.975588520559677
              ],
              [
                -62.26512317506149,
                -0.963640578045968
              ],
              [
                -62.27165262902051,
                -0.951692658523156
              ],
              [
                -62.28554153803683,
                -0.943639206714591
              ],
              [
                -62.289668038087335,
                -0.945849177773011
              ],
              [
                -62.29331452780897,
                -0.947801681109107
              ],
              [
                -62.29776047149751,
                -0.940586212490009
              ],
              [
                -62.3122093938519,
                -0.930026274816641
              ],
              [
                -62.31342287983904,
                -0.925621302017468
              ],
              [
                -62.316647368847974,
                -0.913916854666588
              ],
              [
                -62.32566134431729,
                -0.915015863220806
              ],
              [
                -62.32804180923914,
                -0.915305365051101
              ],
              [
                -62.33970573198496,
                -0.90391240725533
              ],
              [
                -62.3461837251344,
                -0.892385487402664
              ],
              [
                -62.356098176299625,
                -0.874745561960863
              ],
              [
                -62.3604571342899,
                -0.875912570464264
              ],
              [
                -62.36443060440595,
                -0.876976052456973
              ],
              [
                -62.36596111004099,
                -0.869559597414334
              ],
              [
                -62.36609409840088,
                -0.868913600807757
              ],
              [
                -62.366537604993546,
                -0.868774597594743
              ],
              [
                -62.37581557263913,
                -0.865865104062734
              ],
              [
                -62.3709190651355,
                -0.860535177241291
              ],
              [
                -62.36943207948553,
                -0.85891668649665
              ],
              [
                -62.37303907715914,
                -0.848359224151315
              ],
              [
                -62.37997901886451,
                -0.843358740207434
              ],
              [
                -62.37553704909906,
                -0.836140276343993
              ],
              [
                -62.39359645033147,
                -0.828637810920624
              ],
              [
                -62.39526643530856,
                -0.820025375139208
              ],
              [
                -62.42776378379093,
                -0.811969919397719
              ],
              [
                -62.432758225567824,
                -0.803638461535415
              ],
              [
                -62.44220717422716,
                -0.801697466865233
              ],
              [
                -62.44427817454807,
                -0.804695453478769
              ],
              [
                -62.44776865086263,
                -0.809746443562512
              ],
              [
                -62.45276613935748,
                -0.803642456050976
              ],
              [
                -62.461100554162144,
                -0.801417468715558
              ],
              [
                -62.46228305919585,
                -0.797525026172742
              ],
              [
                -62.46498104557556,
                -0.788642556856341
              ],
              [
                -62.472860024214455,
                -0.791790051417697
              ],
              [
                -62.480265471466836,
                -0.794748018917037
              ],
              [
                -62.50054235661159,
                -0.781138596398543
              ],
              [
                -62.503870352384865,
                -0.765308683338303
              ],
              [
                -62.5097068003784,
                -0.758640240811462
              ],
              [
                -62.50161986695929,
                -0.754229766846783
              ],
              [
                -62.49442588362358,
                -0.75030627046043
              ],
              [
                -62.498319861328596,
                -0.733642848868097
              ],
              [
                -62.50164037639464,
                -0.732238346581697
              ],
              [
                -62.50553833234375,
                -0.730589872633679
              ],
              [
                -62.50775934411931,
                -0.713921948870922
              ],
              [
                -62.494985410860615,
                -0.703639034128505
              ],
              [
                -62.49248542426155,
                -0.687255624434781
              ],
              [
                -62.486095932741904,
                -0.68114064169899
              ],
              [
                -62.47643651364655,
                -0.688264101422635
              ],
              [
                -62.46914852981289,
                -0.693639082530737
              ],
              [
                -62.46564904927738,
                -0.690140108441902
              ],
              [
                -62.46331755424537,
                -0.687809117377075
              ],
              [
                -62.45526411676415,
                -0.691695594175688
              ],
              [
                -62.42804427025021,
                -0.69252959584022
              ],
              [
                -62.42498629016649,
                -0.699749048716782
              ],
              [
                -62.42259280213665,
                -0.700676033600538
              ],
              [
                -62.41886680332924,
                -0.702118503637794
              ],
              [
                -62.41637484983506,
                -0.703084035307227
              ],
              [
                -62.41305537657221,
                -0.70669047905835
              ],
              [
                -62.409983897983984,
                -0.710027499245346
              ],
              [
                -62.40637489580691,
                -0.726971868139547
              ],
              [
                -62.39220299213223,
                -0.719195928234675
              ],
              [
                -62.391250471817585,
                -0.714907462587193
              ],
              [
                -62.38998097239018,
                -0.709195969361876
              ],
              [
                -62.38164653532265,
                -0.705863482892236
              ],
              [
                -62.37953804079183,
                -0.706409495796243
              ],
              [
                -62.366654627885055,
                -0.709746487868205
              ],
              [
                -62.35832016481764,
                -0.706693481944532
              ],
              [
                -62.36082417593709,
                -0.699201019512118
              ],
              [
                -62.366170138991905,
                -0.696433560612377
              ],
              [
                -62.36887310835816,
                -0.695034549244534
              ],
              [
                -62.372209081067666,
                -0.68614310123069
              ],
              [
                -62.370260100758316,
                -0.676419662015861
              ],
              [
                -62.35331569942942,
                -0.676698155747877
              ],
              [
                -62.348322718423724,
                -0.670306200766504
              ],
              [
                -62.34393976909062,
                -0.673389671115837
              ],
              [
                -62.34082027493616,
                -0.675583663266301
              ],
              [
                -62.332841821148996,
                -0.670496176133644
              ],
              [
                -62.33225730972609,
                -0.666875716086571
              ],
              [
                -62.33145283864601,
                -0.661892230178355
              ],
              [
                -62.32617734113295,
                -0.656055768293114
              ],
              [
                -62.319775880791966,
                -0.65997624402879
              ],
              [
                -62.317565405760895,
                -0.661330232683517
              ],
              [
                -62.308675451548936,
                -0.66050522809408
              ],
              [
                -62.30368000060325,
                -0.653836776635492
              ],
              [
                -62.295897027455794,
                -0.651890287071482
              ],
              [
                -62.290344058456,
                -0.646330326750908
              ],
              [
                -62.28700758557848,
                -0.638007374206932
              ],
              [
                -62.2931185356027,
                -0.622449422692737
              ],
              [
                -62.29401504272747,
                -0.622046929898983
              ],
              [
                -62.301179491770604,
                -0.618829971529074
              ],
              [
                -62.2997910211761,
                -0.610501028229186
              ],
              [
                -62.29597404710805,
                -0.610375510522893
              ],
              [
                -62.291455062892766,
                -0.610226488873029
              ],
              [
                -62.29034558376054,
                -0.601888550669399
              ],
              [
                -62.30201200120292,
                -0.586888635748219
              ],
              [
                -62.304135504670384,
                -0.585746129960308
              ],
              [
                -62.30923594197258,
                -0.583001151882758
              ],
              [
                -62.307847482996735,
                -0.567162237164167
              ],
              [
                -62.31784341954011,
                -0.552169316012714
              ],
              [
                -62.30988195162281,
                -0.546693858167914
              ],
              [
                -62.30895846073657,
                -0.546058347436959
              ],
              [
                -62.31009494289512,
                -0.541503398535322
              ],
              [
                -62.31117596733604,
                -0.537169927462566
              ],
              [
                -62.308717468879045,
                -0.536291401884449
              ],
              [
                -62.303398509182436,
                -0.534388406466054
              ],
              [
                -62.30577147194125,
                -0.525141970406954
              ],
              [
                -62.30867348495417,
                -0.513833051267763
              ],
              [
                -62.30228899337973,
                -0.508276053058256
              ],
              [
                -62.30062451751489,
                -0.508104054832175
              ],
              [
                -62.294229057619106,
                -0.507443567804825
              ],
              [
                -62.28590208736906,
                -0.493278148899914
              ],
              [
                -62.28800360023751,
                -0.48817067802242
              ],
              [
                -62.289789075777385,
                -0.483831199066222
              ],
              [
                -62.283958596240055,
                -0.477170232792879
              ],
              [
                -62.281241112116476,
                -0.477490252898713
              ],
              [
                -62.27451017296873,
                -0.478282217209488
              ],
              [
                -62.27034218842298,
                -0.47022328447077
              ],
              [
                -62.273130162280225,
                -0.468185768441547
              ],
              [
                -62.27756715318213,
                -0.464943807894886
              ],
              [
                -62.27340367080927,
                -0.453829363320085
              ],
              [
                -62.24812529750076,
                -0.439386958906788
              ],
              [
                -62.24284435899444,
                -0.431892971196109
              ],
              [
                -62.23436739775706,
                -0.428530008421445
              ],
              [
                -62.22672941789962,
                -0.425499505952761
              ],
              [
                -62.22340446280241,
                -0.415677067751846
              ],
              [
                -62.22117996044151,
                -0.409106615412966
              ],
              [
                -62.217397007251,
                -0.408633600675658
              ],
              [
                -62.21228502237474,
                -0.407995109730501
              ],
              [
                -62.21397400330879,
                -0.404620129723781
              ],
              [
                -62.21672950651532,
                -0.399113147082385
              ],
              [
                -62.20828454613403,
                -0.395511701643731
              ],
              [
                -62.20173107531736,
                -0.392716704056398
              ],
              [
                -62.20459205925945,
                -0.381674765786463
              ],
              [
                -62.205904557345605,
                -0.37660879673256
              ],
              [
                -62.20339756462014,
                -0.367999339253873
              ],
              [
                -62.20048007772857,
                -0.366682845069737
              ],
              [
                -62.19478712216446,
                -0.364114355371539
              ],
              [
                -62.19373913299941,
                -0.344602950879791
              ],
              [
                -62.19339963120113,
                -0.338278983438798
              ],
              [
                -62.190620160683714,
                -0.334387027959286
              ],
              [
                -62.187840171167956,
                -0.330494042490252
              ],
              [
                -62.21923100064787,
                -0.310776636777635
              ],
              [
                -62.22328945017591,
                -0.314495115238485
              ],
              [
                -62.22590245770775,
                -0.316888617664991
              ],
              [
                -62.23534189087498,
                -0.316888617135749
              ],
              [
                -62.23895739847084,
                -0.30771966896242
              ],
              [
                -62.247843826982006,
                -0.301608699811428
              ],
              [
                -62.24496184214824,
                -0.294092246558178
              ],
              [
                -62.253675778980586,
                -0.279387836983346
              ],
              [
                -62.253122291374495,
                -0.271055865179685
              ],
              [
                -62.24739881899614,
                -0.265997410790687
              ],
              [
                -62.24117988035647,
                -0.260500441722093
              ],
              [
                -62.23784789896926,
                -0.25244448337703
              ],
              [
                -62.24645485074533,
                -0.251888472671988
              ],
              [
                -62.24919281798777,
                -0.244915524813053
              ],
              [
                -62.252561308823026,
                -0.236337056504891
              ],
              [
                -62.25283830790363,
                -0.219392156535617
              ],
              [
                -62.25112082338712,
                -0.217673161610736
              ],
              [
                -62.244784829372804,
                -0.21133067610558
              ],
              [
                -62.24645683487146,
                -0.18966880132683
              ],
              [
                -62.24145536356474,
                -0.181337858489234
              ],
              [
                -62.2445058440126,
                -0.174392891518478
              ],
              [
                -62.25812327953239,
                -0.164948960115393
              ],
              [
                -62.26185323108228,
                -0.164400453129221
              ],
              [
                -62.2675632087805,
                -0.163560953740774
              ],
              [
                -62.29006458597045,
                -0.139127085185921
              ],
              [
                -62.291733571939744,
                -0.133572646751795
              ],
              [
                -62.293403564899194,
                -0.128017640321174
              ],
              [
                -62.29921952313686,
                -0.122198197376829
              ],
              [
                -62.319205910986824,
                -0.102200815040402
              ],
              [
                -62.318304938058844,
                -0.093198350886164
              ],
              [
                -62.31740344412127,
                -0.084194906748433
              ],
              [
                -62.3216759028915,
                -0.070001462286605
              ],
              [
                -62.32523440741004,
                -0.069388469585955
              ],
              [
                -62.32973535977186,
                -0.068612471761646
              ],
              [
                -62.331673866241665,
                -0.060291014034852
              ],
              [
                -62.32608087185859,
                -0.061164504335518
              ],
              [
                -62.322790395873376,
                -0.061678528569427
              ],
              [
                -62.321375398808776,
                -0.037804168592688
              ],
              [
                -62.32349389822454,
                -0.034631687489845
              ],
              [
                -62.32582289148834,
                -0.031144201063606
              ],
              [
                -62.32439088834365,
                -0.028282719287587
              ],
              [
                -62.32220938930857,
                -0.023923722482976
              ],
              [
                -62.34608426657595,
                0.029929988770065
              ],
              [
                -62.35470920434548,
                0.036320943780306
              ],
              [
                -62.36111120729123,
                0.041064417560885
              ],
              [
                -62.362272662926586,
                0.042756384559471
              ],
              [
                -62.37027164108653,
                0.05440431859977
              ],
              [
                -62.390264008727364,
                0.059958801211498
              ],
              [
                -62.41442189357181,
                0.074700203029355
              ],
              [
                -62.41914786965379,
                0.083304176315412
              ],
              [
                -62.42387481672248,
                0.091908118606517
              ],
              [
                -62.42831831201881,
                0.118847484326842
              ],
              [
                -62.42554580290316,
                0.131921900672022
              ],
              [
                -62.422772844764474,
                0.144997316981962
              ],
              [
                -62.42804132428145,
                0.173852162396283
              ],
              [
                -62.438057756985735,
                0.195220048822721
              ],
              [
                -62.4451327031777,
                0.201478509693139
              ],
              [
                -62.45220717437397,
                0.20773698157324
              ],
              [
                -62.45388414495576,
                0.243874805014909
              ],
              [
                -62.467757565974445,
                0.276075123873982
              ],
              [
                -62.45805763115232,
                0.313847887867186
              ],
              [
                -62.466362089875005,
                0.333281306532405
              ],
              [
                -62.46344311327756,
                0.343142742713174
              ],
              [
                -62.460523609669295,
                0.353004183874337
              ],
              [
                -62.451377181872,
                0.365510118523921
              ],
              [
                -62.45247717939022,
                0.373853557985845
              ],
              [
                -62.445813204429975,
                0.379402055922278
              ],
              [
                -62.455029145383605,
                0.392030470949568
              ],
              [
                -62.455524659638634,
                0.392709984346393
              ],
              [
                -62.4557361413545,
                0.394837469982189
              ],
              [
                -62.45804111731435,
                0.418009821182199
              ],
              [
                -62.46262558703256,
                0.42218130716907
              ],
              [
                -62.46721057874851,
                0.426353260157718
              ],
              [
                -62.484715459376915,
                0.454402144323983
              ],
              [
                -62.508702341648494,
                0.440204205934799
              ],
              [
                -62.51341631383926,
                0.442112190066575
              ],
              [
                -62.51812978803564,
                0.44402119819528
              ],
              [
                -62.52101477232243,
                0.472095532357411
              ],
              [
                -62.522431778577776,
                0.485884933739188
              ],
              [
                -62.527456242328846,
                0.497512877950136
              ],
              [
                -62.532480207065454,
                0.50914131816237
              ],
              [
                -62.5272102196559,
                0.546867123870679
              ],
              [
                -62.51777079077817,
                0.584129409462365
              ],
              [
                -62.53775915398299,
                0.67551542145935
              ],
              [
                -62.53498316910647,
                0.694151306153242
              ],
              [
                -62.528040198114894,
                0.710526716464487
              ],
              [
                -62.499963343765735,
                0.748262500416227
              ],
              [
                -62.4624785677078,
                0.777188820183621
              ],
              [
                -62.459841585588784,
                0.781351801428053
              ],
              [
                -62.45720459246817,
                0.785514780667151
              ],
              [
                -62.462209078756594,
                0.820786069150989
              ],
              [
                -62.463875054117075,
                0.825090554154593
              ],
              [
                -62.465540554477805,
                0.82939402716395
              ],
              [
                -62.46817053482375,
                0.831781009635886
              ],
              [
                -62.48051947601901,
                0.842986973840225
              ],
              [
                -62.48302695277056,
                0.854919407830559
              ],
              [
                -62.485534449500676,
                0.866852325812455
              ],
              [
                -62.470308492466266,
                0.917684020292646
              ],
              [
                -62.46167606731595,
                0.926317003394611
              ],
              [
                -62.453043109168775,
                0.934950440462659
              ],
              [
                -62.44244265227488,
                0.960367312413036
              ],
              [
                -62.443850651328226,
                0.968768254170269
              ],
              [
                -62.44525762737644,
                0.977169216923439
              ],
              [
                -62.46109556817784,
                0.995224597677757
              ],
              [
                -62.47416549153507,
                1.016615981992862
              ],
              [
                -62.4772009621269,
                1.028264442441326
              ],
              [
                -62.47565346864332,
                1.034011881079787
              ],
              [
                -62.47389545217722,
                1.040536863467729
              ],
              [
                -62.476467447461864,
                1.044779320921251
              ],
              [
                -62.478591457308184,
                1.048283322300467
              ],
              [
                -62.477755451451415,
                1.056921260388783
              ],
              [
                -62.4768624609275,
                1.059310738299418
              ],
              [
                -62.469980494396566,
                1.077733155340201
              ],
              [
                -62.47108948785411,
                1.086598090488994
              ],
              [
                -62.47971243339867,
                1.089130103248204
              ],
              [
                -62.489076902023754,
                1.085098110698081
              ],
              [
                -62.4894178801898,
                1.084951107552729
              ],
              [
                -62.52055718764981,
                1.084130601325629
              ],
              [
                -62.52484769185978,
                1.086632080524594
              ],
              [
                -62.52913815907173,
                1.089134081723533
              ],
              [
                -62.54567056891562,
                1.124816886524514
              ],
              [
                -62.55414251178522,
                1.175181121398097
              ],
              [
                -62.58637578719515,
                1.255793160861494
              ],
              [
                -62.61028515448517,
                1.352982135769127
              ],
              [
                -62.61513662906958,
                1.357992604622573
              ],
              [
                -62.61998707965919,
                1.36300308548079
              ],
              [
                -62.62581657217347,
                1.385513457601815
              ],
              [
                -62.62141408558466,
                1.392431418087422
              ],
              [
                -62.61998907883779,
                1.39467086661661
              ],
              [
                -62.61970808631035,
                1.404079842470211
              ],
              [
                -62.636847480606136,
                1.434036164649235
              ],
              [
                -62.64041047944413,
                1.437693634597052
              ],
              [
                -62.64397343028211,
                1.441351644544632
              ],
              [
                -62.67637325642824,
                1.454943047186697
              ],
              [
                -62.69803512523404,
                1.475756461279889
              ],
              [
                -62.720279519688184,
                1.488849384479533
              ],
              [
                -62.725275460339475,
                1.494397362572766
              ],
              [
                -62.730271450989584,
                1.49994480167452
              ],
              [
                -62.731297438133026,
                1.505490293846729
              ],
              [
                -62.735821913841185,
                1.529942668306496
              ],
              [
                -62.747585852836124,
                1.535494611044752
              ],
              [
                -62.74925633560684,
                1.536282623176998
              ],
              [
                -62.75551729422016,
                1.546696558225181
              ],
              [
                -62.75626427587797,
                1.547940044664075
              ],
              [
                -62.76424022880242,
                1.555884523537872
              ],
              [
                -62.781252130661095,
                1.572825403886263
              ],
              [
                -62.803913508767735,
                1.590665819842357
              ],
              [
                -62.80504101496271,
                1.597540786859528
              ],
              [
                -62.80616848115051,
                1.604416264871372
              ],
              [
                -62.79581953535632,
                1.630980115144165
              ],
              [
                -62.77296915230836,
                1.656616458014299
              ],
              [
                -62.77136317717415,
                1.659780438404072
              ],
              [
                -62.76535419059549,
                1.671617859495101
              ],
              [
                -62.75564075998073,
                1.676078325674409
              ],
              [
                -62.75510126877951,
                1.676538339030091
              ],
              [
                -62.732960371653185,
                1.695413720441317
              ],
              [
                -62.72806042689127,
                1.700297181542494
              ],
              [
                -62.72321343083686,
                1.712695614723409
              ],
              [
                -62.7305388629717,
                1.818541503789542
              ],
              [
                -62.703650488571974,
                1.874255690670518
              ],
              [
                -62.69819652989428,
                1.912265481173816
              ],
              [
                -62.70550146194687,
                1.940031347464133
              ],
              [
                -62.71294642332451,
                1.94753230070256
              ],
              [
                -62.72039189469874,
                1.955033745950342
              ],
              [
                -62.741953778266556,
                1.966978197290946
              ],
              [
                -62.81271935879307,
                1.991987580768616
              ],
              [
                -62.82838176041426,
                2.013614430625223
              ],
              [
                -62.83334374212054,
                2.015195450574343
              ],
              [
                -62.83830469883577,
                2.016776418525762
              ],
              [
                -62.91929525738904,
                2.025291402019397
              ],
              [
                -62.94795458916264,
                2.031347878250856
              ],
              [
                -62.98026739654862,
                2.018093476741134
              ],
              [
                -63.02325418255477,
                2.014975981433551
              ],
              [
                -63.070371385667606,
                2.040292871175547
              ],
              [
                -63.07769486795847,
                2.051019313356398
              ],
              [
                -63.08501830623944,
                2.061745734550369
              ],
              [
                -63.09824772080151,
                2.072658207691767
              ],
              [
                -63.1067331870607,
                2.07965714052513
              ],
              [
                -63.11301616761098,
                2.088207594227375
              ],
              [
                -63.11301863759706,
                2.088209600217303
              ],
              [
                -63.119303117132915,
                2.096762550915989
              ],
              [
                -63.13776898217383,
                2.142165298835752
              ],
              [
                -63.13706850743288,
                2.161383694303872
              ],
              [
                -63.14097998663032,
                2.172756144920238
              ],
              [
                -63.15810287339435,
                2.174575128961935
              ],
              [
                -63.16493283379709,
                2.180620619524909
              ],
              [
                -63.19360768919844,
                2.181522616572985
              ],
              [
                -63.26211329574586,
                2.157358273034848
              ],
              [
                -63.27218524458091,
                2.155774277054055
              ],
              [
                -63.28225670643485,
                2.154188786076203
              ],
              [
                -63.31026503445203,
                2.165311725612163
              ],
              [
                -63.359284746598654,
                2.197405070379701
              ],
              [
                -63.371834199798315,
                2.212057991718376
              ],
              [
                -63.37558364533922,
                2.216450482339923
              ],
              [
                -63.400717011471926,
                2.245888303449297
              ],
              [
                -63.40219047900027,
                2.253362294655284
              ],
              [
                -63.40042149465836,
                2.261066225450386
              ],
              [
                -63.39924450608427,
                2.266189697376168
              ],
              [
                -63.39731601432645,
                2.274588167346674
              ],
              [
                -63.39808650752366,
                2.279360612862313
              ],
              [
                -63.39903650236456,
                2.285243605446207
              ],
              [
                -63.39999748811621,
                2.291197546649199
              ],
              [
                -63.40093099907063,
                2.296979031771442
              ],
              [
                -63.40471747711383,
                2.307595981825596
              ],
              [
                -63.42503533929292,
                2.36456816962914
              ],
              [
                -63.42361934147484,
                2.370828622265409
              ],
              [
                -63.42180535210363,
                2.378851567217422
              ],
              [
                -63.415057863294315,
                2.385862547753991
              ],
              [
                -63.40940092712003,
                2.391741489808942
              ],
              [
                -63.39434596917353,
                2.407385416411655
              ],
              [
                -63.3948864786199,
                2.417082845126291
              ],
              [
                -63.39540747628288,
                2.426445783636334
              ],
              [
                -63.40619691427264,
                2.436113763471286
              ],
              [
                -63.41951383032832,
                2.436724759332713
              ],
              [
                -63.4353477403967,
                2.436447258980896
              ],
              [
                -63.440696728207854,
                2.435947265761589
              ],
              [
                -63.46506959982174,
                2.433668769536779
              ],
              [
                -63.493680432546725,
                2.427557834630555
              ],
              [
                -63.51585632658572,
                2.423727364880293
              ],
              [
                -63.51586729452865,
                2.423724849898095
              ],
              [
                -63.523299255682815,
                2.422441384693371
              ],
              [
                -63.54395766825081,
                2.41755789099774
              ],
              [
                -63.558829594427095,
                2.412790919414078
              ],
              [
                -63.56562405033976,
                2.41061296375952
              ],
              [
                -63.57114852959234,
                2.409429968254476
              ],
              [
                -63.577291462632815,
                2.4081129647064
              ],
              [
                -63.60666483090274,
                2.396505531422779
              ],
              [
                -63.64271412608489,
                2.384896642569625
              ],
              [
                -63.653736077309155,
                2.384007125542824
              ],
              [
                -63.66134403544051,
                2.383392648737607
              ],
              [
                -63.68201241717622,
                2.381724148555686
              ],
              [
                -63.70840126566253,
                2.380613172551375
              ],
              [
                -63.71321872313026,
                2.381095680798091
              ],
              [
                -63.71457422194711,
                2.381230654590512
              ],
              [
                -63.73617910649289,
                2.383391151241494
              ],
              [
                -63.74506807802575,
                2.385336656210851
              ],
              [
                -63.75661249583989,
                2.389337644196362
              ],
              [
                -63.77077740891236,
                2.390678117434537
              ],
              [
                -63.78033286538512,
                2.391582144251039
              ],
              [
                -63.78717982361529,
                2.39374613229552
              ],
              [
                -63.80090374393451,
                2.398083119361011
              ],
              [
                -63.815538676392116,
                2.402707597257783
              ],
              [
                -63.83352407390955,
                2.408391567804685
              ],
              [
                -63.8381370468722,
                2.410632555610617
              ],
              [
                -63.849930975871224,
                2.416361033566089
              ],
              [
                -63.86728737071477,
                2.424791476270977
              ],
              [
                -63.872103849892774,
                2.426347975830988
              ],
              [
                -63.894667717967614,
                2.433638462698098
              ],
              [
                -63.915580619954106,
                2.440395421814311
              ],
              [
                -63.939956979466885,
                2.448271890363295
              ],
              [
                -63.96070637546052,
                2.454976356786162
              ],
              [
                -63.97804677893615,
                2.467760265530381
              ],
              [
                -63.98228876443886,
                2.468668791369531
              ],
              [
                -63.98252825008938,
                2.468923274109078
              ],
              [
                -63.98673273139765,
                2.473390740984238
              ],
              [
                -63.99256669164796,
                2.476723749550738
              ],
              [
                -63.99776867258981,
                2.476512726738604
              ],
              [
                -63.99995764121909,
                2.478936738703569
              ],
              [
                -64.00510610461019,
                2.478308215094084
              ],
              [
                -64.007896601262,
                2.47523426656166
              ],
              [
                -64.00952357547939,
                2.473441780745566
              ],
              [
                -64.01276506697404,
                2.46986980003556
              ],
              [
                -64.01714304785492,
                2.468057787407759
              ],
              [
                -64.02095453909111,
                2.467782298380806
              ],
              [
                -64.02432902274823,
                2.472258756907145
              ],
              [
                -64.03141697966387,
                2.47261775980685
              ],
              [
                -64.02965298708644,
                2.482225234010409
              ],
              [
                -64.03703993873356,
                2.486942193864546
              ],
              [
                -64.03922490529835,
                2.494670645632937
              ],
              [
                -64.04659590332801,
                2.496108155936565
              ],
              [
                -64.05548283126882,
                2.498056140136735
              ],
              [
                -64.0582058110812,
                2.503121113300234
              ],
              [
                -64.05708883415515,
                2.510025587139861
              ],
              [
                -64.05590382516341,
                2.512947582124246
              ],
              [
                -64.05389733964142,
                2.517894032007704
              ],
              [
                -64.05089785224281,
                2.522954505877224
              ],
              [
                -64.04661886589332,
                2.529546476071373
              ],
              [
                -64.04611738515376,
                2.530424474195899
              ],
              [
                -64.04483139395063,
                2.532674450700481
              ],
              [
                -64.04578336719702,
                2.537140910316864
              ],
              [
                -64.04831586021452,
                2.549025856090905
              ],
              [
                -64.04635737374534,
                2.553160841277899
              ],
              [
                -64.04381588955502,
                2.559161799285856
              ],
              [
                -64.04389938147973,
                2.565663751696659
              ],
              [
                -64.0425018873794,
                2.571514230962463
              ],
              [
                -64.04087188775894,
                2.576695206692533
              ],
              [
                -64.03784141742817,
                2.579441197810973
              ],
              [
                -64.03451042610287,
                2.578668174552615
              ],
              [
                -64.03434043707416,
                2.578459177595945
              ],
              [
                -64.03225092501675,
                2.575881682466502
              ],
              [
                -64.02852694520375,
                2.57642817701666
              ],
              [
                -64.02381748074424,
                2.591107107825297
              ],
              [
                -64.02095050142572,
                2.594188103207028
              ],
              [
                -64.01769051091495,
                2.597855565293808
              ],
              [
                -64.01145502050716,
                2.604952023842699
              ],
              [
                -64.00851253811256,
                2.609167997120384
              ],
              [
                -64.00889104135136,
                2.613487488230675
              ],
              [
                -64.00910056326688,
                2.615881459543894
              ],
              [
                -64.00926955841157,
                2.620639945221286
              ],
              [
                -64.00238307769213,
                2.626994409408222
              ],
              [
                -63.99892008757468,
                2.629838884763331
              ],
              [
                -63.99752558434719,
                2.631046873725508
              ],
              [
                -63.99104612881678,
                2.636658842020383
              ],
              [
                -63.98573917252629,
                2.648575777109079
              ],
              [
                -63.98422465641296,
                2.653383257775802
              ],
              [
                -63.98391267138842,
                2.659688216946194
              ],
              [
                -63.987237652531114,
                2.665620170659023
              ],
              [
                -63.98766715996565,
                2.666386669745616
              ],
              [
                -63.99084413364766,
                2.670495654141752
              ],
              [
                -63.98874863370248,
                2.67257916110818
              ],
              [
                -63.98659565503367,
                2.674718616749044
              ],
              [
                -63.984927659786415,
                2.686385052655542
              ],
              [
                -63.988399637271385,
                2.69200153711742
              ],
              [
                -63.993631109570345,
                2.695278025855001
              ],
              [
                -64.00121453796989,
                2.700028998731189
              ],
              [
                -64.0027035439171,
                2.707695477387063
              ],
              [
                -63.997271054683836,
                2.711370926374183
              ],
              [
                -63.9914310974824,
                2.715272917964496
              ],
              [
                -63.98890259695193,
                2.716962900823245
              ],
              [
                -63.98226466030278,
                2.717656916220094
              ],
              [
                -63.984888124800236,
                2.726486345114448
              ],
              [
                -63.98603611780926,
                2.731109312875115
              ],
              [
                -63.98869809003139,
                2.733149796120029
              ],
              [
                -63.99217009287551,
                2.735538290858861
              ],
              [
                -63.995567052134774,
                2.737874784845175
              ],
              [
                -63.99894306944889,
                2.740338759142188
              ],
              [
                -63.998522047014994,
                2.757857683199257
              ],
              [
                -63.99846254123825,
                2.760344682860395
              ],
              [
                -63.996095041549815,
                2.762727663056026
              ],
              [
                -63.993811582588634,
                2.764732118313157
              ],
              [
                -63.993486587940744,
                2.770078115543952
              ],
              [
                -64.00282100196691,
                2.77295959526108
              ],
              [
                -64.00393200501948,
                2.773303101915801
              ],
              [
                -64.00881498921206,
                2.778608060688555
              ],
              [
                -64.00886297223657,
                2.787055508485082
              ],
              [
                -64.01353942896161,
                2.79583045059424
              ],
              [
                -64.01362443578456,
                2.801993431632135
              ],
              [
                -64.01393995936114,
                2.805993907351028
              ],
              [
                -64.01383094885878,
                2.806153422447757
              ],
              [
                -64.01095794297937,
                2.810344374709186
              ],
              [
                -64.01137496224952,
                2.816074354204754
              ],
              [
                -64.01270543580536,
                2.819442829761332
              ],
              [
                -64.01553243333682,
                2.821061342369039
              ],
              [
                -64.02182887609429,
                2.824203836385296
              ],
              [
                -64.02902636705718,
                2.82757281660252
              ],
              [
                -64.03650530838094,
                2.831339285826015
              ],
              [
                -64.0387232893189,
                2.832456780849509
              ],
              [
                -64.04260427716314,
                2.834525784535125
              ],
              [
                -64.04659673539692,
                2.836664271901598
              ],
              [
                -64.04933123957893,
                2.840152729471498
              ],
              [
                -64.0511537240253,
                2.842501718727155
              ],
              [
                -64.05357171092164,
                2.845842210947713
              ],
              [
                -64.0571517050217,
                2.850825199908353
              ],
              [
                -64.06149117431006,
                2.864306109728246
              ],
              [
                -64.06306964682155,
                2.869293582751575
              ],
              [
                -64.06627261624762,
                2.880027032757934
              ],
              [
                -64.06935159210525,
                2.917244331843199
              ],
              [
                -64.06992209165404,
                2.924757268853829
              ],
              [
                -64.07056659334842,
                2.933259218594327
              ],
              [
                -64.07103408468546,
                2.938045716160525
              ],
              [
                -64.07478753529773,
                2.94199917374036
              ],
              [
                -64.08888097008595,
                2.945954662197142
              ],
              [
                -64.09429095177994,
                2.948419171550541
              ],
              [
                -64.09575695583322,
                2.949099657598373
              ],
              [
                -64.10187038269645,
                2.951939155280981
              ],
              [
                -64.11075885329238,
                2.960826095896401
              ],
              [
                -64.12433327525932,
                2.980862973051951
              ],
              [
                -64.12704674545968,
                2.992872939024977
              ],
              [
                -64.12729324266088,
                2.99396191631013
              ],
              [
                -64.1298882250359,
                3.004749874767898
              ],
              [
                -64.13786018503333,
                3.01222132759049
              ],
              [
                -64.1403391639692,
                3.014691299558008
              ],
              [
                -64.16016502714346,
                3.03308472766152
              ],
              [
                -64.16867651053882,
                3.052553122550091
              ],
              [
                -64.17228748496025,
                3.058387091073548
              ],
              [
                -64.17748944744486,
                3.063552063965871
              ],
              [
                -64.18045191690622,
                3.065938539641864
              ],
              [
                -64.18472342022314,
                3.069045505105945
              ],
              [
                -64.18911336488483,
                3.072238490171184
              ],
              [
                -64.19069386109439,
                3.073446010484799
              ],
              [
                -64.19714983419021,
                3.078375972275304
              ],
              [
                -64.20103830247992,
                3.08152145135687
              ],
              [
                -64.20710278362144,
                3.086427952091766
              ],
              [
                -64.21989070154466,
                3.096136880467797
              ],
              [
                -64.2242806631835,
                3.101425860343926
              ],
              [
                -64.23130614751162,
                3.110055324661896
              ],
              [
                -64.23375561983003,
                3.114074807373774
              ],
              [
                -64.23423212673363,
                3.119379281221452
              ],
              [
                -64.23379161763857,
                3.124172727342471
              ],
              [
                -64.23302362575212,
                3.129941224075705
              ],
              [
                -64.22235615145063,
                3.147172605416709
              ],
              [
                -64.22108866476798,
                3.149678596350186
              ],
              [
                -64.21948115951055,
                3.152857083506443
              ],
              [
                -64.21021072771624,
                3.17248946256828
              ],
              [
                -64.20771173325292,
                3.189158866895976
              ],
              [
                -64.208534209077,
                3.194896828538027
              ],
              [
                -64.21017321898712,
                3.203924770947256
              ],
              [
                -64.21282818859905,
                3.220810673711074
              ],
              [
                -64.21326666389615,
                3.223601671960016
              ],
              [
                -64.21321765827335,
                3.233108628909636
              ],
              [
                -64.21321868908203,
                3.233470618967168
              ],
              [
                -64.21326468605366,
                3.246658537194071
              ],
              [
                -64.22054960802171,
                3.264548949942035
              ],
              [
                -64.23675202823912,
                3.304378742583348
              ],
              [
                -64.23956650465598,
                3.311980203248298
              ],
              [
                -64.24072349091836,
                3.315287177100172
              ],
              [
                -64.24034399528614,
                3.320214662427666
              ],
              [
                -64.23915948722778,
                3.333822076681448
              ],
              [
                -64.23541649075202,
                3.36924837021604
              ],
              [
                -64.23518849092815,
                3.371125352997823
              ],
              [
                -64.23415346872365,
                3.379640806633417
              ],
              [
                -64.2347974814692,
                3.385064289806967
              ],
              [
                -64.24122293252866,
                3.391818265968956
              ],
              [
                -64.24849840169954,
                3.399466217795551
              ],
              [
                -64.25084987515955,
                3.403823693640561
              ],
              [
                -64.25465236236946,
                3.411373650108151
              ],
              [
                -64.25354736084331,
                3.417360602293843
              ],
              [
                -64.25289984930471,
                3.420842604203788
              ],
              [
                -64.25227334891487,
                3.423848068687569
              ],
              [
                -64.25132386439768,
                3.428320060099872
              ],
              [
                -64.23937391112585,
                3.44332194274574
              ],
              [
                -64.20215112236802,
                3.477488237915334
              ],
              [
                -64.19616462195167,
                3.48559071185314
              ],
              [
                -64.19600613465511,
                3.485805707604265
              ],
              [
                -64.18937016797135,
                3.49505065098767
              ],
              [
                -64.18622718351929,
                3.500031606331452
              ],
              [
                -64.1847896929713,
                3.512613037579946
              ],
              [
                -64.18467567355624,
                3.521570491132234
              ],
              [
                -64.18454016217272,
                3.525297451922065
              ],
              [
                -64.18450066776273,
                3.526394966970526
              ],
              [
                -64.18424318875314,
                3.528796466850413
              ],
              [
                -64.1836816757195,
                3.534050932141979
              ],
              [
                -64.18309519528867,
                3.538456406003501
              ],
              [
                -64.18379066972336,
                3.550799833689041
              ],
              [
                -64.1851226515774,
                3.560054295424518
              ],
              [
                -64.18769613379973,
                3.564321243829319
              ],
              [
                -64.19818259043015,
                3.580053655794952
              ],
              [
                -64.20493202663101,
                3.590179121942936
              ],
              [
                -64.2054035267804,
                3.590886603390234
              ],
              [
                -64.20726900614325,
                3.592562620405721
              ],
              [
                -64.24373481422298,
                3.625330454444299
              ],
              [
                -64.2459283019804,
                3.628577931175259
              ],
              [
                -64.25003826128543,
                3.636183865476284
              ],
              [
                -64.2509007548921,
                3.637780388353617
              ],
              [
                -64.25698720232768,
                3.648178828728335
              ],
              [
                -64.26216070525977,
                3.652078809688871
              ],
              [
                -64.2750411158957,
                3.661790263788948
              ],
              [
                -64.28040456746429,
                3.669777702800176
              ],
              [
                -64.28217055605123,
                3.677081679385762
              ],
              [
                -64.28270457324489,
                3.678861150087947
              ],
              [
                -64.2853900380931,
                3.687815614299251
              ],
              [
                -64.28680255333917,
                3.695375583289313
              ],
              [
                -64.28846001900558,
                3.700054534990981
              ],
              [
                -64.32217783756809,
                3.718659452583911
              ],
              [
                -64.32557531013262,
                3.720123942706342
              ],
              [
                -64.32734578352603,
                3.720887448642233
              ],
              [
                -64.33020078220373,
                3.72352192712526
              ],
              [
                -64.33110778397979,
                3.724423912797942
              ],
              [
                -64.34207120783248,
                3.735329855488999
              ],
              [
                -64.34621369926961,
                3.737271380495435
              ],
              [
                -64.35123515891637,
                3.739634342757731
              ],
              [
                -64.35651662698064,
                3.742403846985668
              ],
              [
                -64.3592466048712,
                3.744093321510371
              ],
              [
                -64.36418307461372,
                3.747043831560052
              ],
              [
                -64.36766558076906,
                3.748483804887959
              ],
              [
                -64.37622653767983,
                3.753312311998427
              ],
              [
                -64.38929345819855,
                3.760050748541756
              ],
              [
                -64.39585642694666,
                3.76236273704206
              ],
              [
                -64.39863141112647,
                3.76320124219937
              ],
              [
                -64.40845883865228,
                3.766167731154427
              ],
              [
                -64.41341432190774,
                3.769810721518263
              ],
              [
                -64.42781972326421,
                3.780401172181355
              ],
              [
                -64.44838160271406,
                3.78304516644463
              ],
              [
                -64.45144761096222,
                3.782976174682325
              ],
              [
                -64.45820206226655,
                3.782823189617312
              ],
              [
                -64.46850052160923,
                3.782210696183026
              ],
              [
                -64.47171200016338,
                3.782067205909991
              ],
              [
                -64.47553996166324,
                3.783387175135634
              ],
              [
                -64.48032045772796,
                3.785593655172172
              ],
              [
                -64.48485190105782,
                3.787831659890267
              ],
              [
                -64.48793939121228,
                3.791073637328167
              ],
              [
                -64.49489137636785,
                3.79946460842454
              ],
              [
                -64.4998088330234,
                3.804495077367257
              ],
              [
                -64.50476681596916,
                3.810349560906923
              ],
              [
                -64.50818927815219,
                3.81563953154645
              ],
              [
                -64.5237961826095,
                3.842337868521402
              ],
              [
                -64.55229550781446,
                3.864098765146445
              ],
              [
                -64.5571259707389,
                3.869399761645921
              ],
              [
                -64.56768740860677,
                3.882189684458927
              ],
              [
                -64.57135538986411,
                3.896549112533278
              ],
              [
                -64.57957532157965,
                3.92872592862429
              ],
              [
                -64.59070226938896,
                3.935264389496875
              ],
              [
                -64.5963687262314,
                3.938594889176425
              ],
              [
                -64.6100116505113,
                3.94616334413981
              ],
              [
                -64.61334664935632,
                3.947843332227456
              ],
              [
                -64.61601514453854,
                3.949650322209721
              ],
              [
                -64.6189410920534,
                3.952009301389499
              ],
              [
                -64.6218575998614,
                3.955564287134977
              ],
              [
                -64.62709654568022,
                3.96399876013214
              ],
              [
                -64.62834106585798,
                3.966280760658722
              ],
              [
                -64.63065453831615,
                3.970521742337275
              ],
              [
                -64.6333525353535,
                3.975469180465368
              ],
              [
                -64.64258246703308,
                3.984971136302086
              ],
              [
                -64.65005141209333,
                3.992332622530673
              ],
              [
                -64.65728437452408,
                3.996309092813802
              ],
              [
                -64.67319077652375,
                4.005055044072939
              ],
              [
                -64.67405528494895,
                4.009974532197933
              ],
              [
                -64.67455226859146,
                4.012804532312257
              ],
              [
                -64.6769927770073,
                4.014515511697579
              ],
              [
                -64.68013474751916,
                4.016718507894533
              ],
              [
                -64.68146322782526,
                4.027446453108711
              ],
              [
                -64.68310219890881,
                4.040676373069856
              ],
              [
                -64.68570568269374,
                4.043613353980257
              ],
              [
                -64.68810466587414,
                4.046009342666669
              ],
              [
                -64.6943036601637,
                4.052200814437029
              ],
              [
                -64.70791005599953,
                4.066990712861749
              ],
              [
                -64.7111735260735,
                4.075047681706742
              ],
              [
                -64.71354801553275,
                4.081801159973743
              ],
              [
                -64.7145170177804,
                4.084557625797374
              ],
              [
                -64.72236247507934,
                4.105338509282775
              ],
              [
                -64.7314344026425,
                4.120675431858729
              ],
              [
                -64.73978686736524,
                4.127674405794783
              ],
              [
                -64.7526347904205,
                4.137272847775263
              ],
              [
                -64.77755613761158,
                4.150302801435958
              ],
              [
                -64.78291609541137,
                4.153105306975484
              ],
              [
                -64.786881087137,
                4.156194273045263
              ],
              [
                -64.79084606686379,
                4.159282759120645
              ],
              [
                -64.80241500484537,
                4.168346713227746
              ],
              [
                -64.80678348096433,
                4.172265717128252
              ],
              [
                -64.80985546044909,
                4.175049682252141
              ],
              [
                -64.81270443524409,
                4.18354915054393
              ],
              [
                -64.81498793054054,
                4.191108619500604
              ],
              [
                -64.82124687141207,
                4.212274478089921
              ],
              [
                -64.82143938370076,
                4.217800979540135
              ],
              [
                -64.82146535505137,
                4.218566432446629
              ],
              [
                -64.82153084927381,
                4.219223440962481
              ],
              [
                -64.82198936182338,
                4.223830405529915
              ],
              [
                -64.82422933996445,
                4.243663828510924
              ],
              [
                -64.82264032693955,
                4.249720267877156
              ],
              [
                -64.81988532667401,
                4.258967222285879
              ],
              [
                -64.8171118463413,
                4.26550569822743
              ],
              [
                -64.80121244856828,
                4.281293097347098
              ],
              [
                -64.79654394825452,
                4.285834044683607
              ],
              [
                -64.79251696621719,
                4.286665556422486
              ],
              [
                -64.78336452569964,
                4.286950027559328
              ],
              [
                -64.77985705856118,
                4.286994054895782
              ],
              [
                -64.76336812571336,
                4.279680564887033
              ],
              [
                -64.74981769929931,
                4.274698582376376
              ],
              [
                -64.746715235302,
                4.273002098378642
              ],
              [
                -64.7291228390267,
                4.264419150523174
              ],
              [
                -64.69835648101113,
                4.251628690366867
              ],
              [
                -64.69307751893027,
                4.247334206915748
              ],
              [
                -64.69071255433443,
                4.245410222675798
              ],
              [
                -64.68783656359135,
                4.243135227981035
              ],
              [
                -64.68378857547381,
                4.239933232488084
              ],
              [
                -64.65786272982237,
                4.212340889713672
              ],
              [
                -64.65622475479556,
                4.208499919330316
              ],
              [
                -64.65458676476679,
                4.204658922947563
              ],
              [
                -64.64697681040423,
                4.185779041679124
              ],
              [
                -64.64304983803643,
                4.176028558642077
              ],
              [
                -64.6430438330772,
                4.176013594718768
              ],
              [
                -64.64304133209397,
                4.17600759374949
              ],
              [
                -64.63912286665523,
                4.166278131607547
              ],
              [
                -64.6295634151496,
                4.144427253083897
              ],
              [
                -64.62813895135386,
                4.141551261646764
              ],
              [
                -64.62679544196754,
                4.13893927784004
              ],
              [
                -64.62330198964746,
                4.134741802153525
              ],
              [
                -64.61028755127512,
                4.125789334790274
              ],
              [
                -64.60188808933657,
                4.1197363678722
              ],
              [
                -64.59348913340048,
                4.113682916970204
              ],
              [
                -64.55981583070334,
                4.102308454101951
              ],
              [
                -64.5510673589629,
                4.110960875573049
              ],
              [
                -64.55027888550154,
                4.111740382838915
              ],
              [
                -64.54372892632311,
                4.114525843447263
              ],
              [
                -64.53815694784527,
                4.114693339850529
              ],
              [
                -64.52899698641853,
                4.115464826610723
              ],
              [
                -64.52222851857069,
                4.116446326817835
              ],
              [
                -64.51617405855028,
                4.115157335778856
              ],
              [
                -64.51284256227349,
                4.114259328430301
              ],
              [
                -64.50430962167454,
                4.111960338220046
              ],
              [
                -64.4997396661737,
                4.111979348092721
              ],
              [
                -64.4853867294786,
                4.11587880548158
              ],
              [
                -64.4654023148094,
                4.119792282043497
              ],
              [
                -64.4602373516379,
                4.120974271214807
              ],
              [
                -64.45681536869179,
                4.121849767201156
              ],
              [
                -64.45161539590256,
                4.124255244713719
              ],
              [
                -64.44789490260456,
                4.124970729366392
              ],
              [
                -64.44582093952582,
                4.126136737668261
              ],
              [
                -64.43962097718853,
                4.129621212656534
              ],
              [
                -64.4301504920761,
                4.134081174170606
              ],
              [
                -64.4074596339723,
                4.132792191082962
              ],
              [
                -64.40318614418534,
                4.13254966555992
              ],
              [
                -64.39443669969646,
                4.135051634158236
              ],
              [
                -64.38568674622238,
                4.137552641754253
              ],
              [
                -64.35738391835798,
                4.133114150063468
              ],
              [
                -64.33475803166856,
                4.129566658335045
              ],
              [
                -64.3139011234244,
                4.131307616019901
              ],
              [
                -64.30563216293162,
                4.131749629125298
              ],
              [
                -64.30117518911305,
                4.1331036157937
              ],
              [
                -64.29839722321333,
                4.135050603345293
              ],
              [
                -64.29781221650605,
                4.136168080870218
              ],
              [
                -64.29636674917124,
                4.138928059876026
              ],
              [
                -64.29236525274055,
                4.14219005244436
              ],
              [
                -64.28901627474639,
                4.144217530168873
              ],
              [
                -64.2741753522705,
                4.143243551600982
              ],
              [
                -64.27189035199665,
                4.137350549205268
              ],
              [
                -64.27075585014796,
                4.133900566257466
              ],
              [
                -64.2702198757321,
                4.132675109581447
              ],
              [
                -64.26837287107912,
                4.128456127352926
              ],
              [
                -64.26156440349381,
                4.126741638186334
              ],
              [
                -64.25516247659405,
                4.128395598925868
              ],
              [
                -64.25277548445345,
                4.129181102060211
              ],
              [
                -64.2501289896027,
                4.130052599552822
              ],
              [
                -64.24560151213852,
                4.12844360132697
              ],
              [
                -64.23849603866165,
                4.125902115482364
              ],
              [
                -64.2330865855842,
                4.124591634049617
              ],
              [
                -64.2273630954075,
                4.121350620945454
              ],
              [
                -64.2268426033015,
                4.121056134208017
              ],
              [
                -64.22221163036764,
                4.117953645075575
              ],
              [
                -64.21943466971373,
                4.11652863901692
              ],
              [
                -64.21494319144963,
                4.115883144572526
              ],
              [
                -64.21201920563873,
                4.115867160728318
              ],
              [
                -64.20084724826916,
                4.116442122209641
              ],
              [
                -64.19004330098514,
                4.117051633744147
              ],
              [
                -64.18370085948344,
                4.120794091108408
              ],
              [
                -64.17991885632475,
                4.123554575524132
              ],
              [
                -64.17970288034334,
                4.123712598517778
              ],
              [
                -64.17647336660023,
                4.126038070672423
              ],
              [
                -64.1712344187405,
                4.129219062816802
              ],
              [
                -64.16721792139857,
                4.128032554642802
              ],
              [
                -64.16373495237042,
                4.126716071533994
              ],
              [
                -64.16158447109011,
                4.12437155392908
              ],
              [
                -64.15787497903538,
                4.12032008462739
              ],
              [
                -64.15514400290756,
                4.117770613762609
              ],
              [
                -64.14998301864873,
                4.113341119575805
              ],
              [
                -64.14939754802235,
                4.112839127934529
              ],
              [
                -64.14679306103001,
                4.110606133427483
              ],
              [
                -64.14181507000589,
                4.108954149180399
              ],
              [
                -64.14077557163941,
                4.108609135382312
              ],
              [
                -64.13552312573628,
                4.107433668351128
              ],
              [
                -64.1325416408556,
                4.106508149450013
              ],
              [
                -64.11729673510568,
                4.095931212289439
              ],
              [
                -64.11206675707946,
                4.09171772991172
              ],
              [
                -64.11088827309815,
                4.088823750974475
              ],
              [
                -64.10784327268709,
                4.086158752561698
              ],
              [
                -64.10395432106873,
                4.076715293690595
              ],
              [
                -64.10106884297855,
                4.066084853970752
              ],
              [
                -64.09567687390113,
                4.05394493887774
              ],
              [
                -64.09479238878947,
                4.048850968170048
              ],
              [
                -64.09475437760923,
                4.041663492460791
              ],
              [
                -64.09610986022929,
                4.039045513657472
              ],
              [
                -64.09895584693017,
                4.033550027551769
              ],
              [
                -64.09651088375466,
                4.025605599411787
              ],
              [
                -64.09162590242228,
                4.019006622343513
              ],
              [
                -64.08022895515644,
                4.005917705584125
              ],
              [
                -64.07766750737858,
                4.002975726027866
              ],
              [
                -64.07511951752029,
                4.00004924139703
              ],
              [
                -64.07346402368205,
                3.996063260133275
              ],
              [
                -64.07161503648679,
                3.992650272612993
              ],
              [
                -64.05011816211487,
                3.95060399683511
              ],
              [
                -64.04704718078527,
                3.949566508563899
              ],
              [
                -64.04438321339563,
                3.948430011092587
              ],
              [
                -64.03531725312354,
                3.94456300750733
              ],
              [
                -64.03519178030842,
                3.940522536525499
              ],
              [
                -64.03501178589599,
                3.934689085312404
              ],
              [
                -64.0245968377996,
                3.933816557519602
              ],
              [
                -64.00090193577435,
                3.931830582458926
              ],
              [
                -63.99958247157886,
                3.923954608734995
              ],
              [
                -63.9986004609969,
                3.917065645818576
              ],
              [
                -63.997754492105074,
                3.911129683770057
              ],
              [
                -63.996818978713684,
                3.90351873982785
              ],
              [
                -63.99175002617405,
                3.898712744956025
              ],
              [
                -63.98760052946188,
                3.899351253871118
              ],
              [
                -63.98074158808406,
                3.899714756598516
              ],
              [
                -63.97487613549805,
                3.893646274154535
              ],
              [
                -63.972278147977484,
                3.887334792082381
              ],
              [
                -63.96818867053624,
                3.878934353518162
              ],
              [
                -63.96530918471199,
                3.872237382384752
              ],
              [
                -63.963752183162754,
                3.868478391992651
              ],
              [
                -63.960677214105395,
                3.870332410940177
              ],
              [
                -63.95986521036345,
                3.876821361916346
              ],
              [
                -63.95936668612746,
                3.881440318319656
              ],
              [
                -63.958690697703915,
                3.887862798734269
              ],
              [
                -63.9551767380264,
                3.889576796160649
              ],
              [
                -63.94942025258655,
                3.888854292533527
              ],
              [
                -63.947102276068705,
                3.893139263618098
              ],
              [
                -63.943858794362875,
                3.900703700151384
              ],
              [
                -63.940553309562205,
                3.900862719216024
              ],
              [
                -63.93736030273977,
                3.901724201500965
              ],
              [
                -63.93610380867629,
                3.909212175674064
              ],
              [
                -63.93581381352203,
                3.911907151719693
              ],
              [
                -63.93556432438444,
                3.914232124818059
              ],
              [
                -63.93457131219339,
                3.918076616118849
              ],
              [
                -63.92914135467331,
                3.922811087751086
              ],
              [
                -63.925768348188186,
                3.92625555174157
              ],
              [
                -63.913728916122416,
                3.926438053175336
              ],
              [
                -63.908556458037246,
                3.929935541731883
              ],
              [
                -63.90533145401012,
                3.931415031583507
              ],
              [
                -63.90059100405524,
                3.934288502827406
              ],
              [
                -63.89531902777845,
                3.932531004158223
              ],
              [
                -63.8903965346013,
                3.930889514074651
              ],
              [
                -63.88289458192853,
                3.93409447175415
              ],
              [
                -63.8796856000733,
                3.935184000749685
              ],
              [
                -63.876334609613124,
                3.936835979564625
              ],
              [
                -63.86958966849361,
                3.939225456186819
              ],
              [
                -63.866745666466414,
                3.9407369230883
              ],
              [
                -63.86620866610474,
                3.94102242518077
              ],
              [
                -63.8620262031923,
                3.945584903465359
              ],
              [
                -63.85897322568526,
                3.94802038514941
              ],
              [
                -63.853329766989354,
                3.945315385459521
              ],
              [
                -63.849622255080156,
                3.949095388333415
              ],
              [
                -63.84316629782595,
                3.947719902821904
              ],
              [
                -63.84017133509543,
                3.946844396747102
              ],
              [
                -63.83460535335619,
                3.940382913780193
              ],
              [
                -63.83455987062777,
                3.940330413040365
              ],
              [
                -63.829735390075264,
                3.940146432012912
              ],
              [
                -63.82972288614127,
                3.940145904007952
              ],
              [
                -63.82810788766058,
                3.940084430328727
              ],
              [
                -63.826898880008805,
                3.940085901559003
              ],
              [
                -63.82688737706921,
                3.940085928551607
              ],
              [
                -63.81951444278849,
                3.940094428860342
              ],
              [
                -63.81484446634776,
                3.940051398155544
              ],
              [
                -63.808834014372714,
                3.937735421118772
              ],
              [
                -63.80790749746397,
                3.937378407500891
              ],
              [
                -63.80212054605191,
                3.935491936244832
              ],
              [
                -63.79645305803836,
                3.933570448260068
              ],
              [
                -63.792241088944905,
                3.933947420535926
              ],
              [
                -63.78788912716523,
                3.935006421966944
              ],
              [
                -63.784396112898804,
                3.936021917176484
              ],
              [
                -63.78374762220659,
                3.936185901865199
              ],
              [
                -63.77180118515071,
                3.939212389672726
              ],
              [
                -63.767753210120674,
                3.936527920919491
              ],
              [
                -63.76678423561915,
                3.935885412851047
              ],
              [
                -63.76489821910359,
                3.936588903787552
              ],
              [
                -63.75779427183265,
                3.93923838371302
              ],
              [
                -63.742872854862796,
                3.93204092701632
              ],
              [
                -63.73970689399653,
                3.926473456731515
              ],
              [
                -63.74080988718505,
                3.924870971260002
              ],
              [
                -63.743172882874575,
                3.921440982653502
              ],
              [
                -63.74006888061068,
                3.919196992082117
              ],
              [
                -63.73703791887586,
                3.915480498677548
              ],
              [
                -63.73096644201472,
                3.915214989344368
              ],
              [
                -63.726791955098335,
                3.915060006588499
              ],
              [
                -63.721592490899404,
                3.912783026897404
              ],
              [
                -63.71733100693414,
                3.908611521246546
              ],
              [
                -63.7123015494755,
                3.903696053224994
              ],
              [
                -63.70892504835808,
                3.906788030060582
              ],
              [
                -63.70935654506348,
                3.911669000398247
              ],
              [
                -63.70534558085499,
                3.913846010878543
              ],
              [
                -63.700573121343204,
                3.913311005848715
              ],
              [
                -63.69627860976659,
                3.913661493230388
              ],
              [
                -63.69300865595642,
                3.912129495644542
              ],
              [
                -63.68902767709938,
                3.910270515421471
              ],
              [
                -63.684977672352176,
                3.90882551487159
              ],
              [
                -63.68198070773152,
                3.907900013111827
              ],
              [
                -63.675142755482874,
                3.913263984219137
              ],
              [
                -63.6716542517028,
                3.915177478469302
              ],
              [
                -63.66296881235404,
                3.921920917776736
              ],
              [
                -63.66534577855608,
                3.923940433104796
              ],
              [
                -63.67442074924539,
                3.923015928896976
              ],
              [
                -63.672943237697005,
                3.926789409122922
              ],
              [
                -63.677893218417196,
                3.928663883870287
              ],
              [
                -63.67980221671986,
                3.932886888737352
              ],
              [
                -63.68185967782548,
                3.936165353916898
              ],
              [
                -63.68726617860212,
                3.935696364905199
              ],
              [
                -63.68754715509566,
                3.935735871863527
              ],
              [
                -63.69247715166213,
                3.936434849101047
              ],
              [
                -63.69751909532792,
                3.942448322059959
              ],
              [
                -63.69984359362087,
                3.944774311677611
              ],
              [
                -63.69946557881892,
                3.9508467709002
              ],
              [
                -63.69472712250094,
                3.95771823294888
              ],
              [
                -63.69492060985186,
                3.961912224898805
              ],
              [
                -63.6956720938732,
                3.966718693816181
              ],
              [
                -63.693597605892265,
                3.974545644247076
              ],
              [
                -63.6897896046844,
                3.979770098951239
              ],
              [
                -63.69078613009646,
                3.98505657536028
              ],
              [
                -63.68574414386234,
                3.992699553890008
              ],
              [
                -63.686831113178734,
                3.995784522523232
              ],
              [
                -63.68855462835621,
                4.000101988747049
              ],
              [
                -63.68637461357212,
                4.005281961705072
              ],
              [
                -63.68491211853814,
                4.009613945807122
              ],
              [
                -63.68666562022462,
                4.012865410943527
              ],
              [
                -63.68022464833871,
                4.017368390901598
              ],
              [
                -63.675780691006295,
                4.015446386687777
              ],
              [
                -63.67044469822093,
                4.013780413487936
              ],
              [
                -63.66605025649022,
                4.012082914068285
              ],
              [
                -63.66304373093698,
                4.0111719221857
              ],
              [
                -63.6601197699025,
                4.010334403950051
              ],
              [
                -63.651980806934766,
                4.002346443967564
              ],
              [
                -63.64882335869002,
                3.994311489435471
              ],
              [
                -63.64281089109312,
                3.979009084319535
              ],
              [
                -63.63735240317212,
                3.97543460264207
              ],
              [
                -63.62707195815649,
                3.969588115493928
              ],
              [
                -63.62040550359157,
                3.961284688252413
              ],
              [
                -63.61750603089598,
                3.958121702934797
              ],
              [
                -63.61138658948844,
                3.951330740678219
              ],
              [
                -63.607163613310576,
                3.945729256036903
              ],
              [
                -63.60492610374813,
                3.943151256905211
              ],
              [
                -63.60156464347237,
                3.940020789127703
              ],
              [
                -63.597975146170704,
                3.935656327043481
              ],
              [
                -63.59520466345833,
                3.93302580787307
              ],
              [
                -63.5915072017283,
                3.928657856745969
              ],
              [
                -63.58765873512868,
                3.91891489129615
              ],
              [
                -63.590352690900154,
                3.912420427941802
              ],
              [
                -63.59146319720277,
                3.908900974120577
              ],
              [
                -63.592509208719434,
                3.905584494133069
              ],
              [
                -63.59025770544874,
                3.902633998064725
              ],
              [
                -63.58788971034404,
                3.900412519889254
              ],
              [
                -63.58481675804003,
                3.89645202890437
              ],
              [
                -63.59038320296172,
                3.890985555621024
              ],
              [
                -63.58809123922633,
                3.885874607486936
              ],
              [
                -63.58039427642373,
                3.886719569038811
              ],
              [
                -63.569469844621004,
                3.88445408181232
              ],
              [
                -63.56584084692226,
                3.885928567398447
              ],
              [
                -63.56243686709006,
                3.887312082626181
              ],
              [
                -63.559015383292866,
                3.888792048307081
              ],
              [
                -63.5549909332654,
                3.890962045789156
              ],
              [
                -63.55454343227536,
                3.876885632491204
              ],
              [
                -63.55447841386674,
                3.874849639728235
              ],
              [
                -63.55122795990056,
                3.865328685455127
              ],
              [
                -63.54761248140022,
                3.863110696511972
              ],
              [
                -63.53872653493775,
                3.86560869319948
              ],
              [
                -63.53382206690942,
                3.859069706408399
              ],
              [
                -63.529431583268305,
                3.857269225684084
              ],
              [
                -63.522894116412125,
                3.856445709228279
              ],
              [
                -63.519009138787666,
                3.853513236093177
              ],
              [
                -63.514889142033624,
                3.851199254388601
              ],
              [
                -63.50971617411501,
                3.848471757332885
              ],
              [
                -63.503126212106686,
                3.85003226363889
              ],
              [
                -63.49819175550074,
                3.843075294186895
              ],
              [
                -63.49533276827337,
                3.847011276610198
              ],
              [
                -63.494200265883734,
                3.850953231054754
              ],
              [
                -63.495703765367,
                3.853488219918715
              ],
              [
                -63.49796076034855,
                3.858493196547619
              ],
              [
                -63.49840825437,
                3.85948620931544
              ],
              [
                -63.50112473657639,
                3.8650391491895
              ],
              [
                -63.502275720476185,
                3.868308143767627
              ],
              [
                -63.49795874246405,
                3.869790640882638
              ],
              [
                -63.492856751417534,
                3.868329638842016
              ],
              [
                -63.489815284216526,
                3.873784086701999
              ],
              [
                -63.481672830358306,
                3.873879107148677
              ],
              [
                -63.46983639387371,
                3.867828620434802
              ],
              [
                -63.46878088730027,
                3.863202138468324
              ],
              [
                -63.46766089327777,
                3.863149137073226
              ],
              [
                -63.4609514400912,
                3.862831162709671
              ],
              [
                -63.45581549536377,
                3.86118817167318
              ],
              [
                -63.45044851486917,
                3.85954167251675
              ],
              [
                -63.4456885470641,
                3.861325651681188
              ],
              [
                -63.43978308325517,
                3.863261629293432
              ],
              [
                -63.43668057802056,
                3.864439147841757
              ],
              [
                -63.434533595313525,
                3.867904594262807
              ],
              [
                -63.43371907714502,
                3.871991600047403
              ],
              [
                -63.43380561096209,
                3.876444542351526
              ],
              [
                -63.43534758274355,
                3.879752526917311
              ],
              [
                -63.43737406731352,
                3.884097531019155
              ],
              [
                -63.43445457335555,
                3.886495493883021
              ],
              [
                -63.43190360362044,
                3.888598991609448
              ],
              [
                -63.42738562134323,
                3.892324955092945
              ],
              [
                -63.41733167617439,
                3.901450404097679
              ],
              [
                -63.41243917743331,
                3.910784355118601
              ],
              [
                -63.41090220265937,
                3.913937326613786
              ],
              [
                -63.41312418175345,
                3.918937287170855
              ],
              [
                -63.41728563969083,
                3.922066305353576
              ],
              [
                -63.419135657073134,
                3.924943784494832
              ],
              [
                -63.41759717162021,
                3.92757776387301
              ],
              [
                -63.4159091620305,
                3.930100739773521
              ],
              [
                -63.416972648808155,
                3.934238203407664
              ],
              [
                -63.42478861980919,
                3.934959214291176
              ],
              [
                -63.42659910439673,
                3.93622919835766
              ],
              [
                -63.427291094416596,
                3.936715202086641
              ],
              [
                -63.432337548803325,
                3.934771705064779
              ],
              [
                -63.43892352503534,
                3.937621716335809
              ],
              [
                -63.4420595188614,
                3.940053201775528
              ],
              [
                -63.4398540204372,
                3.945101175304724
              ],
              [
                -63.438620026821326,
                3.948587651128594
              ],
              [
                -63.44472849829459,
                3.951910644480924
              ],
              [
                -63.449817474413656,
                3.954870618216283
              ],
              [
                -63.44579798957113,
                3.961581581340349
              ],
              [
                -63.44256199712199,
                3.964204059702917
              ],
              [
                -63.435615017241254,
                3.970260523599705
              ],
              [
                -63.431559549091574,
                3.974568999044561
              ],
              [
                -63.42817157222665,
                3.977551464290177
              ],
              [
                -63.41289363867198,
                3.975607967442761
              ],
              [
                -63.40409070792518,
                3.978100461979878
              ],
              [
                -63.392612258243865,
                3.980882419200718
              ],
              [
                -63.378081838324235,
                3.973316982188507
              ],
              [
                -63.373858882388234,
                3.970791486603925
              ],
              [
                -63.37118238448029,
                3.96944699241168
              ],
              [
                -63.36331392885382,
                3.965493486496489
              ],
              [
                -63.35849747383411,
                3.963369504307162
              ],
              [
                -63.35233751140354,
                3.960558508107247
              ],
              [
                -63.34779800501296,
                3.959878522041126
              ],
              [
                -63.33782308414167,
                3.958317020472787
              ],
              [
                -63.3306251178648,
                3.962548004317829
              ],
              [
                -63.32356115018601,
                3.964689493905753
              ],
              [
                -63.31543471412014,
                3.96212897908362
              ],
              [
                -63.3118787215881,
                3.961324508336624
              ],
              [
                -63.314201208395936,
                3.969009964867093
              ],
              [
                -63.31371269094131,
                3.970703433097622
              ],
              [
                -63.313030204098254,
                3.973071442436224
              ],
              [
                -63.31235919518591,
                3.977045907804729
              ],
              [
                -63.310714197886206,
                3.981944388390191
              ],
              [
                -63.30732273741687,
                3.981243870157537
              ],
              [
                -63.29753329026539,
                3.97706890829868
              ],
              [
                -63.292013329540985,
                3.9709739498825
              ],
              [
                -63.28594486359582,
                3.97310540513273
              ],
              [
                -63.28371787872209,
                3.96633197159983
              ],
              [
                -63.28327039091231,
                3.965049944485711
              ],
              [
                -63.281658882398766,
                3.960433489281044
              ],
              [
                -63.278848423495035,
                3.957080519258441
              ],
              [
                -63.275303420625946,
                3.956754004853814
              ],
              [
                -63.26389499891125,
                3.959381967977747
              ],
              [
                -63.26095301314776,
                3.960147978840796
              ],
              [
                -63.25695501385539,
                3.961188956499774
              ],
              [
                -63.25344652009585,
                3.961996466772146
              ],
              [
                -63.249266571185196,
                3.957581480846049
              ],
              [
                -63.2484400721463,
                3.956707988214096
              ],
              [
                -63.244613606207636,
                3.95572500230877
              ],
              [
                -63.24040113739587,
                3.954643001716196
              ],
              [
                -63.23757514380217,
                3.954160012643466
              ],
              [
                -63.23068818077878,
                3.953904001748824
              ],
              [
                -63.22315621659974,
                3.953025500935454
              ],
              [
                -63.21502076970172,
                3.952075990143145
              ],
              [
                -63.21159129801112,
                3.952133006670243
              ],
              [
                -63.20566681193621,
                3.951764487014616
              ],
              [
                -63.20692380981867,
                3.944354543310785
              ],
              [
                -63.20741332299104,
                3.941470032773484
              ],
              [
                -63.21043831193495,
                3.933202617964348
              ],
              [
                -63.21213329489255,
                3.928317149376999
              ],
              [
                -63.213245277974266,
                3.923417173502424
              ],
              [
                -63.21685077178121,
                3.913547229991526
              ],
              [
                -63.21991027427303,
                3.904038278101122
              ],
              [
                -63.22150077490008,
                3.898940796605459
              ],
              [
                -63.22490424507487,
                3.893442860466265
              ],
              [
                -63.2273342074883,
                3.889517882920218
              ],
              [
                -63.22978321155403,
                3.885992386147676
              ],
              [
                -63.23279919313035,
                3.881579892679965
              ],
              [
                -63.2327672074292,
                3.874822945433523
              ],
              [
                -63.23223923024266,
                3.863352024224541
              ],
              [
                -63.23152924325414,
                3.858063528344429
              ],
              [
                -63.23150422257844,
                3.851161601897647
              ],
              [
                -63.22650427001059,
                3.845053636970063
              ],
              [
                -63.22594577254297,
                3.835884191863302
              ],
              [
                -63.22299728104726,
                3.833003684160414
              ],
              [
                -63.220997792227145,
                3.830537193723814
              ],
              [
                -63.21815181626687,
                3.827517228865734
              ],
              [
                -63.215155328146885,
                3.824438235247916
              ],
              [
                -63.21171584845704,
                3.819595744217065
              ],
              [
                -63.209003877916025,
                3.818010754428036
              ],
              [
                -63.203635888221406,
                3.812035303565172
              ],
              [
                -63.20003693981834,
                3.813131289260219
              ],
              [
                -63.197124451520274,
                3.814599778287847
              ],
              [
                -63.19516142743049,
                3.815588791569751
              ],
              [
                -63.191134970394174,
                3.814896293000456
              ],
              [
                -63.18443553298106,
                3.813682261728531
              ],
              [
                -63.17992953024396,
                3.813447261312941
              ],
              [
                -63.17190958039293,
                3.809766779040524
              ],
              [
                -63.17158559826299,
                3.809541295105621
              ],
              [
                -63.167829122945946,
                3.80692831544824
              ],
              [
                -63.164104125818454,
                3.805388826807651
              ],
              [
                -63.150666187177784,
                3.806439790805522
              ],
              [
                -63.14470825177157,
                3.805325815439118
              ],
              [
                -63.13295878902051,
                3.803202318228546
              ],
              [
                -63.132571786074905,
                3.803236308804573
              ],
              [
                -63.12773533375175,
                3.803659800512947
              ],
              [
                -63.123459845960234,
                3.804858300219468
              ],
              [
                -63.112993913389104,
                3.799346322773431
              ],
              [
                -63.109656439428086,
                3.797445835406515
              ],
              [
                -63.10552295701026,
                3.795090843107919
              ],
              [
                -63.10261495846505,
                3.793663364353532
              ],
              [
                -63.098050516569195,
                3.789284868137004
              ],
              [
                -63.09336553935597,
                3.784846391188646
              ],
              [
                -63.08970856279858,
                3.781797412076337
              ],
              [
                -63.08622057221804,
                3.778944429969762
              ],
              [
                -63.08209661363076,
                3.768372976733797
              ],
              [
                -63.081777107831435,
                3.767553972131594
              ],
              [
                -63.07940862896122,
                3.765167494087453
              ],
              [
                -63.07874111294371,
                3.764494498459497
              ],
              [
                -63.06899918882271,
                3.753387569884377
              ],
              [
                -63.06470169634554,
                3.752641064508591
              ],
              [
                -63.06011073122392,
                3.752274051832276
              ],
              [
                -63.05913624600378,
                3.747957576444811
              ],
              [
                -63.058445263879264,
                3.740884629672582
              ],
              [
                -63.0628862119382,
                3.734220154648379
              ],
              [
                -63.062701243052864,
                3.730605178791155
              ],
              [
                -63.06280675221684,
                3.705401333016885
              ],
              [
                -63.066271246098786,
                3.701123373005192
              ],
              [
                -63.069810224633336,
                3.696753407545653
              ],
              [
                -63.077370169121046,
                3.694890405406182
              ],
              [
                -63.0806096464307,
                3.693681898067921
              ],
              [
                -63.0721061829109,
                3.687092457962045
              ],
              [
                -63.063681745819345,
                3.684219469114759
              ],
              [
                -63.05867879213845,
                3.681722952165682
              ],
              [
                -63.05571831965199,
                3.678940997010152
              ],
              [
                -63.05229780677001,
                3.675929497873265
              ],
              [
                -63.0454623584604,
                3.672535003893416
              ],
              [
                -63.0452683805585,
                3.672438533320538
              ],
              [
                -63.04114837181509,
                3.670507535733049
              ],
              [
                -63.031699921068,
                3.666234044166584
              ],
              [
                -63.030054449712004,
                3.659636080161053
              ],
              [
                -63.02905548039642,
                3.657351087377446
              ],
              [
                -63.026439974895965,
                3.651365616379484
              ],
              [
                -63.02001250424904,
                3.648244139139634
              ],
              [
                -63.01506205325163,
                3.645839654743373
              ],
              [
                -63.01410554942494,
                3.642296164037409
              ],
              [
                -63.013855555299976,
                3.641369199084802
              ],
              [
                -63.01155857909297,
                3.638855192839306
              ],
              [
                -63.00366413483742,
                3.624350769528792
              ],
              [
                -63.00020615922859,
                3.622971274268236
              ],
              [
                -62.99370118427354,
                3.615865811334158
              ],
              [
                -62.98968120490933,
                3.612342845764499
              ],
              [
                -62.984277247295,
                3.610056370479251
              ],
              [
                -62.97753227626563,
                3.61066183122644
              ],
              [
                -62.96998579974105,
                3.610947829304247
              ],
              [
                -62.96518382647444,
                3.611176816272151
              ],
              [
                -62.95955535853098,
                3.608111339235983
              ],
              [
                -62.95180292693744,
                3.612279326431193
              ],
              [
                -62.94682894375439,
                3.617543270059994
              ],
              [
                -62.944547452054735,
                3.621055772048732
              ],
              [
                -62.94154848224357,
                3.624529262840515
              ],
              [
                -62.93535000530547,
                3.62866069609615
              ],
              [
                -62.93206100604826,
                3.63565867792699
              ],
              [
                -62.9293760191003,
                3.639906131536048
              ],
              [
                -62.925674553640576,
                3.645887112818807
              ],
              [
                -62.92154305879614,
                3.653090556976267
              ],
              [
                -62.92144606989351,
                3.653833064749728
              ],
              [
                -62.92102357631396,
                3.657072048312082
              ],
              [
                -62.91992556407599,
                3.666051495232068
              ],
              [
                -62.918239564821384,
                3.671866968577301
              ],
              [
                -62.90816463441979,
                3.671441433108622
              ],
              [
                -62.8986666945371,
                3.676883909985464
              ],
              [
                -62.89553667653821,
                3.678431413460646
              ],
              [
                -62.89405271204105,
                3.67931039065309
              ],
              [
                -62.88704623746886,
                3.683459872229685
              ],
              [
                -62.87175281376174,
                3.699512263959753
              ],
              [
                -62.852320904325744,
                3.712293178583593
              ],
              [
                -62.850128418350955,
                3.715426173643436
              ],
              [
                -62.84664194355995,
                3.719948126111446
              ],
              [
                -62.84520695462745,
                3.722843112951284
              ],
              [
                -62.84459143822902,
                3.724086097583153
              ],
              [
                -62.842017452911335,
                3.727907602525388
              ],
              [
                -62.83889999167936,
                3.733184063939309
              ],
              [
                -62.834928486435295,
                3.737825537420531
              ],
              [
                -62.82934804177138,
                3.735852017249156
              ],
              [
                -62.82206308468518,
                3.733275559474729
              ],
              [
                -62.818643606891335,
                3.732067540268851
              ],
              [
                -62.8095511364799,
                3.733109546019409
              ],
              [
                -62.803722699810855,
                3.730055048813714
              ],
              [
                -62.79985422016111,
                3.724217591473628
              ],
              [
                -62.797332712592194,
                3.721165085214179
              ],
              [
                -62.789279282977574,
                3.718106636735539
              ],
              [
                -62.78762279084071,
                3.713138625801335
              ],
              [
                -62.78729580035443,
                3.711861637816464
              ],
              [
                -62.784274816572676,
                3.700053709682721
              ],
              [
                -62.77530686955267,
                3.697623714149394
              ],
              [
                -62.77273739185274,
                3.693512250853431
              ],
              [
                -62.77003239427171,
                3.68873228325322
              ],
              [
                -62.76665291806395,
                3.686166275765423
              ],
              [
                -62.76097995679459,
                3.682716817930679
              ],
              [
                -62.755356970932354,
                3.678096323741167
              ],
              [
                -62.74658703709302,
                3.67354133935985
              ],
              [
                -62.74390106086589,
                3.674911831060563
              ],
              [
                -62.74337404032984,
                3.684267790916942
              ],
              [
                -62.73907758606233,
                3.68697527811991
              ],
              [
                -62.736218592106496,
                3.689500758185516
              ],
              [
                -62.73588258315327,
                3.692571224641921
              ],
              [
                -62.73479457221266,
                3.697479204274701
              ],
              [
                -62.73202409801742,
                3.704758661515479
              ],
              [
                -62.730309095334114,
                3.708177637185907
              ],
              [
                -62.73253110750583,
                3.714673101773614
              ],
              [
                -62.73427558507357,
                3.719775083958023
              ],
              [
                -62.733938572437175,
                3.730850994140801
              ],
              [
                -62.73371806538645,
                3.738109474966985
              ],
              [
                -62.734841559466055,
                3.741227968996082
              ],
              [
                -62.73487455223321,
                3.741319940495498
              ],
              [
                -62.73623504557961,
                3.745199434361416
              ],
              [
                -62.73715854936597,
                3.748955917664141
              ],
              [
                -62.738090032130174,
                3.752672866194817
              ],
              [
                -62.73826255316889,
                3.757815371211195
              ],
              [
                -62.73817251316182,
                3.767137317425749
              ],
              [
                -62.737500536327296,
                3.769641801856444
              ],
              [
                -62.736629024133926,
                3.77289076995466
              ],
              [
                -62.73547604751386,
                3.776058249343942
              ],
              [
                -62.73611905056303,
                3.780219744179279
              ],
              [
                -62.738928519640794,
                3.783064712760038
              ],
              [
                -62.7403890178443,
                3.784542719270253
              ],
              [
                -62.742816506908575,
                3.787530195818567
              ],
              [
                -62.74041702046446,
                3.789982187505461
              ],
              [
                -62.73971500598907,
                3.790457656393919
              ],
              [
                -62.73485052241222,
                3.793754127819396
              ],
              [
                -62.73121955934488,
                3.796717135867263
              ],
              [
                -62.72844157745098,
                3.805051580008233
              ],
              [
                -62.74261348043233,
                3.808662567094251
              ],
              [
                -62.744807464945886,
                3.811325045344423
              ],
              [
                -62.7474129588997,
                3.814536531736315
              ],
              [
                -62.74881745178771,
                3.817040009412505
              ],
              [
                -62.74908342521677,
                3.817255497353281
              ],
              [
                -62.75441440873116,
                3.82157398712872
              ],
              [
                -62.7594888668977,
                3.82545796121376
              ],
              [
                -62.76324136260649,
                3.828714473054203
              ],
              [
                -62.77085379261816,
                3.834936421456022
              ],
              [
                -62.770906303718945,
                3.839283381887497
              ],
              [
                -62.76489733471937,
                3.843536889161232
              ],
              [
                -62.76234684287549,
                3.844642872349521
              ],
              [
                -62.760810848798684,
                3.845309352642222
              ],
              [
                -62.75737086299226,
                3.847717853912555
              ],
              [
                -62.75348289748059,
                3.850349804641543
              ],
              [
                -62.7578873869343,
                3.854771777282586
              ],
              [
                -62.75687388101495,
                3.858763753056438
              ],
              [
                -62.76844679896774,
                3.867555723336829
              ],
              [
                -62.774853758594176,
                3.87523018580269
              ],
              [
                -62.77548774869778,
                3.875989679890714
              ],
              [
                -62.77548425324083,
                3.876772666454907
              ],
              [
                -62.775468256842494,
                3.880859144304429
              ],
              [
                -62.77651574352842,
                3.881909139001504
              ],
              [
                -62.78040924005893,
                3.885812107291787
              ],
              [
                -62.78390520868723,
                3.8898056148281
              ],
              [
                -62.788085689646834,
                3.893734591154359
              ],
              [
                -62.78767368201304,
                3.898424568343203
              ],
              [
                -62.787481178871815,
                3.906895518251118
              ],
              [
                -62.78725618620919,
                3.909964995726809
              ],
              [
                -62.786740689866846,
                3.915073952468805
              ],
              [
                -62.78260469891188,
                3.917285948375379
              ],
              [
                -62.78264519834962,
                3.921098428800002
              ],
              [
                -62.782660703332986,
                3.922614934217101
              ],
              [
                -62.78266721192499,
                3.923220893787706
              ],
              [
                -62.78448819376492,
                3.92857486258153
              ],
              [
                -62.78171118924712,
                3.93272984830931
              ],
              [
                -62.77757321973219,
                3.935862848977105
              ],
              [
                -62.76884128060731,
                3.939814813134333
              ],
              [
                -62.76303930643055,
                3.942440279629919
              ],
              [
                -62.757231837309995,
                3.948262757981563
              ],
              [
                -62.753311824728684,
                3.956064709267905
              ],
              [
                -62.74733837818837,
                3.96561616132135
              ],
              [
                -62.744988372438456,
                3.97123309896214
              ],
              [
                -62.74316938851418,
                3.97410659350595
              ],
              [
                -62.74451689823471,
                3.977269579394805
              ],
              [
                -62.749842858717884,
                3.978286582970906
              ],
              [
                -62.759457297327124,
                3.983514523347237
              ],
              [
                -62.761958790917156,
                3.984874546204136
              ],
              [
                -62.76173878318054,
                3.991158497397511
              ],
              [
                -62.7653062660835,
                3.99556548963505
              ],
              [
                -62.76607173457332,
                3.999332467736163
              ],
              [
                -62.76786724662234,
                4.006054907711457
              ],
              [
                -62.76591073295853,
                4.012843865927078
              ],
              [
                -62.75359530365684,
                4.019302342426436
              ],
              [
                -62.749565309505435,
                4.022481793801672
              ],
              [
                -62.751335307304345,
                4.026606787491704
              ],
              [
                -62.75255931306279,
                4.030729759845816
              ],
              [
                -62.74722182737576,
                4.034892220789143
              ],
              [
                -62.74176334547769,
                4.037268704798585
              ],
              [
                -62.73659439468126,
                4.038589698989228
              ],
              [
                -62.72824342121663,
                4.038233713681667
              ],
              [
                -62.72051748654837,
                4.036016213361846
              ],
              [
                -62.7212219779505,
                4.032496234829899
              ],
              [
                -62.716332015051215,
                4.033194213743017
              ],
              [
                -62.71521452466972,
                4.032320232001775
              ],
              [
                -62.713584024326565,
                4.031045232214853
              ],
              [
                -62.704869080040126,
                4.030416243240833
              ],
              [
                -62.70141459213945,
                4.02829325811969
              ],
              [
                -62.69631962094911,
                4.031240229105994
              ],
              [
                -62.68871967022692,
                4.032830705211402
              ],
              [
                -62.683434674327245,
                4.035382682314837
              ],
              [
                -62.67678172344655,
                4.038596183773755
              ],
              [
                -62.67275073604562,
                4.037503166426526
              ],
              [
                -62.67124623935666,
                4.037291689671251
              ],
              [
                -62.66912028110142,
                4.036992694018156
              ],
              [
                -62.66192229251355,
                4.036546680968338
              ],
              [
                -62.65850631415279,
                4.038023168378
              ],
              [
                -62.65738532435865,
                4.039429167652014
              ],
              [
                -62.65600685275983,
                4.041158625917871
              ],
              [
                -62.64347992104528,
                4.045652125307144
              ],
              [
                -62.63966793864143,
                4.044296622599208
              ],
              [
                -62.63563893754026,
                4.042751622834673
              ],
              [
                -62.63180096135584,
                4.041279112780577
              ],
              [
                -62.6276075071291,
                4.039773153692047
              ],
              [
                -62.623675523157445,
                4.04036061683332
              ],
              [
                -62.619815534803266,
                4.040937132082251
              ],
              [
                -62.61355558553525,
                4.043065620945153
              ],
              [
                -62.60857709835248,
                4.043523615153799
              ],
              [
                -62.603702647810614,
                4.038918128322652
              ],
              [
                -62.60122316370588,
                4.036716638304255
              ],
              [
                -62.59417570925678,
                4.034874124328911
              ],
              [
                -62.59252220861234,
                4.034323149420392
              ],
              [
                -62.58781923722348,
                4.032756670366941
              ],
              [
                -62.585851732807185,
                4.029803186973519
              ],
              [
                -62.5816272810985,
                4.026199193859864
              ],
              [
                -62.57966277486389,
                4.018161221494106
              ],
              [
                -62.57205081330503,
                4.015052239415356
              ],
              [
                -62.56170589829557,
                4.01728972308319
              ],
              [
                -62.55639089876338,
                4.018107725041729
              ],
              [
                -62.55723941070835,
                4.021967686533342
              ],
              [
                -62.560109895940634,
                4.025327181167529
              ],
              [
                -62.5610163918443,
                4.031916123108437
              ],
              [
                -62.56163736960858,
                4.036430627715333
              ],
              [
                -62.559409865440905,
                4.038510112416385
              ],
              [
                -62.55635141625972,
                4.039870097696824
              ],
              [
                -62.552310926426806,
                4.042825572232021
              ],
              [
                -62.54763294619031,
                4.045595044418843
              ],
              [
                -62.54214097478504,
                4.044592560643893
              ],
              [
                -62.53705002614925,
                4.043664043703777
              ],
              [
                -62.53314904712913,
                4.047309542370415
              ],
              [
                -62.534004513661394,
                4.051728508648009
              ],
              [
                -62.53773051117675,
                4.056699481605234
              ],
              [
                -62.5411779744455,
                4.061299442508886
              ],
              [
                -62.54177848096817,
                4.064761947093853
              ],
              [
                -62.54222645993131,
                4.070407400096181
              ],
              [
                -62.54216844574246,
                4.071200897520803
              ],
              [
                -62.54158044782808,
                4.079234867191755
              ],
              [
                -62.544381927054665,
                4.078434344573434
              ],
              [
                -62.54844043665422,
                4.077274847816024
              ],
              [
                -62.55177841566719,
                4.078496371978472
              ],
              [
                -62.55708837654288,
                4.080319371972547
              ],
              [
                -62.553435404027525,
                4.082571338742923
              ],
              [
                -62.55179337920879,
                4.08691281085626
              ],
              [
                -62.55086491164794,
                4.091015791790281
              ],
              [
                -62.5491789177055,
                4.095916736667076
              ],
              [
                -62.54526591075432,
                4.099500224635947
              ],
              [
                -62.54764990473372,
                4.102640199213162
              ],
              [
                -62.550256402107294,
                4.106389175451627
              ],
              [
                -62.55199736579138,
                4.109219685385487
              ],
              [
                -62.541144438289784,
                4.11032265203645
              ],
              [
                -62.534669976759595,
                4.113214150285788
              ],
              [
                -62.53237200378119,
                4.114021625171853
              ],
              [
                -62.52960050428085,
                4.114995643796387
              ],
              [
                -62.525654028983915,
                4.116298110776382
              ],
              [
                -62.52317404005837,
                4.118570622151833
              ],
              [
                -62.52205305471468,
                4.123942578656277
              ],
              [
                -62.52275254539689,
                4.124716060681128
              ],
              [
                -62.52467153955114,
                4.12683804877614
              ],
              [
                -62.5312195027602,
                4.133104019154666
              ],
              [
                -62.53090748413392,
                4.136704493329947
              ],
              [
                -62.52425902813626,
                4.134060030140281
              ],
              [
                -62.51844154564681,
                4.131354005849892
              ],
              [
                -62.51430408307391,
                4.130061538559094
              ],
              [
                -62.51177811318445,
                4.129571527721369
              ],
              [
                -62.507038138665045,
                4.128652524900602
              ],
              [
                -62.50566113684673,
                4.132273505252487
              ],
              [
                -62.50566261383643,
                4.132276996233473
              ],
              [
                -62.50779962572472,
                4.137594989148637
              ],
              [
                -62.50351262866823,
                4.139869468313707
              ],
              [
                -62.50065765124813,
                4.141437949458092
              ],
              [
                -62.497532165618914,
                4.142524942184766
              ],
              [
                -62.49145772052239,
                4.141423460532949
              ],
              [
                -62.49063219903976,
                4.139869446905042
              ],
              [
                -62.48997622442376,
                4.13863446255851
              ],
              [
                -62.48788721833966,
                4.134769993355457
              ],
              [
                -62.481835283946126,
                4.132401989990312
              ],
              [
                -62.47757978298572,
                4.131197003128822
              ],
              [
                -62.47093133896587,
                4.139496934183044
              ],
              [
                -62.46329585929455,
                4.14169543058405
              ],
              [
                -62.46036139159484,
                4.142850931036513
              ],
              [
                -62.46162887108788,
                4.146824384059446
              ],
              [
                -62.46201835280561,
                4.151683369425011
              ],
              [
                -62.45527340431857,
                4.156885853142187
              ],
              [
                -62.45789440737908,
                4.159351328704681
              ],
              [
                -62.46228234969388,
                4.163479291883485
              ],
              [
                -62.46687184183742,
                4.163107320030948
              ],
              [
                -62.469709301801956,
                4.163905789309995
              ],
              [
                -62.467308829616336,
                4.167454770361286
              ],
              [
                -62.46525034062932,
                4.170896751247899
              ],
              [
                -62.463824328104174,
                4.174458244861019
              ],
              [
                -62.462286838010066,
                4.178290721841694
              ],
              [
                -62.45772586410407,
                4.176533719928543
              ],
              [
                -62.45202039829652,
                4.173497752610773
              ],
              [
                -62.44906994079635,
                4.174481732017731
              ],
              [
                -62.44487744036704,
                4.179640685620154
              ],
              [
                -62.43709450552262,
                4.183368667069259
              ],
              [
                -62.427308553710105,
                4.182420660067698
              ],
              [
                -62.42293705977885,
                4.182214655371149
              ],
              [
                -62.41697411771345,
                4.184841631336
              ],
              [
                -62.41316462770885,
                4.180049684381489
              ],
              [
                -62.40558667299273,
                4.180393165415443
              ],
              [
                -62.40172370099152,
                4.17758169204351
              ],
              [
                -62.39810921251208,
                4.174950691799614
              ],
              [
                -62.39230673697866,
                4.17392220390072
              ],
              [
                -62.3921972294832,
                4.174068706985908
              ],
              [
                -62.38898878425365,
                4.178380151068795
              ],
              [
                -62.38529778245859,
                4.175354184052715
              ],
              [
                -62.38195830341575,
                4.172821202433323
              ],
              [
                -62.37917731223326,
                4.170408705489482
              ],
              [
                -62.37688683508399,
                4.168422737414549
              ],
              [
                -62.37593083586506,
                4.167593723558031
              ],
              [
                -62.372696378082914,
                4.165314245554598
              ],
              [
                -62.36955239176047,
                4.16310374321531
              ],
              [
                -62.36480291078487,
                4.163137256908708
              ],
              [
                -62.364283410219116,
                4.163775254895522
              ],
              [
                -62.36120994962136,
                4.167548229158595
              ],
              [
                -62.35288648954415,
                4.165606702877082
              ],
              [
                -62.352445498888535,
                4.1626467326326
              ],
              [
                -62.35189146928429,
                4.159020777148336
              ],
              [
                -62.35136098650314,
                4.157005752403419
              ],
              [
                -62.350816011836955,
                4.154935273968229
              ],
              [
                -62.34983250368236,
                4.15115881906856
              ],
              [
                -62.34121805047227,
                4.147269829833893
              ],
              [
                -62.33515510691052,
                4.140837371920576
              ],
              [
                -62.330335120251455,
                4.136375900473641
              ],
              [
                -62.32646365536189,
                4.135049401592968
              ],
              [
                -62.3224661668178,
                4.140455859847439
              ],
              [
                -62.31724970296387,
                4.141227829000099
              ],
              [
                -62.31371920908821,
                4.136994849089207
              ],
              [
                -62.306181268206956,
                4.137437343631268
              ],
              [
                -62.30197578655417,
                4.137060354065874
              ],
              [
                -62.29946882053087,
                4.138294863317962
              ],
              [
                -62.295046827422134,
                4.140472340885319
              ],
              [
                -62.291496830125375,
                4.141718810385747
              ],
              [
                -62.28840834864151,
                4.14088032620784
              ],
              [
                -62.28521689951774,
                4.135716841902978
              ],
              [
                -62.28480088423905,
                4.135043362515628
              ],
              [
                -62.27969743708389,
                4.130716399147604
              ],
              [
                -62.27445345873155,
                4.131000874099037
              ],
              [
                -62.26925097081365,
                4.131815388019766
              ],
              [
                -62.26813049686228,
                4.131990889279147
              ],
              [
                -62.26194400164256,
                4.130818380022524
              ],
              [
                -62.25132657338837,
                4.130293860150844
              ],
              [
                -62.24771411056917,
                4.126715876453602
              ],
              [
                -62.24620661806582,
                4.123294401220977
              ],
              [
                -62.24567962973513,
                4.122097912784155
              ],
              [
                -62.24246064862991,
                4.118704924279119
              ],
              [
                -62.23804264319122,
                4.118321433630863
              ],
              [
                -62.234799670455395,
                4.119233430266236
              ],
              [
                -62.23401517763204,
                4.119453909485337
              ],
              [
                -62.232503685576724,
                4.118481932117089
              ],
              [
                -62.2294297067693,
                4.116503949545208
              ],
              [
                -62.22706121193429,
                4.113191971132511
              ],
              [
                -62.218501768428624,
                4.109505484882813
              ],
              [
                -62.214829820657044,
                4.109495974565244
              ],
              [
                -62.20946681926923,
                4.110942454746204
              ],
              [
                -62.20878181792426,
                4.111126967237831
              ],
              [
                -62.20435535506602,
                4.111505963187764
              ],
              [
                -62.199925372803584,
                4.112550452290395
              ],
              [
                -62.19649439517564,
                4.113381940268251
              ],
              [
                -62.192881930862036,
                4.110606455969756
              ],
              [
                -62.19305441859785,
                4.103286515373222
              ],
              [
                -62.193163423954175,
                4.098661031165079
              ],
              [
                -62.176771018450886,
                4.090607551145658
              ],
              [
                -62.172039041372294,
                4.089304570633835
              ],
              [
                -62.16528808466053,
                4.087642081904886
              ],
              [
                -62.161557135720486,
                4.086885084884308
              ],
              [
                -62.147332698894196,
                4.079497606467549
              ],
              [
                -62.14505972812971,
                4.075258118524139
              ],
              [
                -62.14150874737348,
                4.082107581641139
              ],
              [
                -62.139057755888274,
                4.083699104864468
              ],
              [
                -62.137063273072634,
                4.08499408109464
              ],
              [
                -62.13369279846538,
                4.086897551920525
              ],
              [
                -62.13366828959943,
                4.086899557893175
              ],
              [
                -62.1306202842917,
                4.087109567721392
              ],
              [
                -62.1258858360719,
                4.087672538424372
              ],
              [
                -62.11296288974671,
                4.091895503683038
              ],
              [
                -62.114654892213686,
                4.098460980764514
              ],
              [
                -62.11232788198731,
                4.101716954415823
              ],
              [
                -62.11598286228662,
                4.104084946061286
              ],
              [
                -62.11704985895193,
                4.10477596274852
              ],
              [
                -62.116680840893956,
                4.111177406448014
              ],
              [
                -62.115895846248115,
                4.114270399031724
              ],
              [
                -62.106173921291834,
                4.115297887798668
              ],
              [
                -62.10185993663031,
                4.117631353493223
              ],
              [
                -62.09824295325255,
                4.119757355837049
              ],
              [
                -62.099271438171606,
                4.125049791838449
              ],
              [
                -62.09649195074538,
                4.127828295937316
              ],
              [
                -62.09035849782778,
                4.129096284612765
              ],
              [
                -62.0883625157729,
                4.124563824589218
              ],
              [
                -62.088347525859916,
                4.124557309617274
              ],
              [
                -62.07784158298378,
                4.119814319310444
              ],
              [
                -62.071219104882765,
                4.125610779417418
              ],
              [
                -62.07035912557558,
                4.130376757222423
              ],
              [
                -62.077326566146304,
                4.136438220592944
              ],
              [
                -62.077434063381865,
                4.136702233131779
              ],
              [
                -62.080564054134356,
                4.144367170717896
              ],
              [
                -62.077407075649894,
                4.150467645281794
              ],
              [
                -62.076184055978885,
                4.154231621651669
              ],
              [
                -62.069315084261056,
                4.156865096892797
              ],
              [
                -62.06543311677403,
                4.158345092771658
              ],
              [
                -62.058418652223814,
                4.155800098964012
              ],
              [
                -62.055062193959955,
                4.153997117239478
              ],
              [
                -62.052968706270654,
                4.152872119404419
              ],
              [
                -62.04942871372092,
                4.151538611837873
              ],
              [
                -62.04407626356934,
                4.154281091428632
              ],
              [
                -62.04185277406708,
                4.155531600718342
              ],
              [
                -62.03983277951312,
                4.156667593804777
              ],
              [
                -62.03624330000428,
                4.15725259906615
              ],
              [
                -62.032763296747476,
                4.15806005610635
              ],
              [
                -62.032628325437415,
                4.156603102480118
              ],
              [
                -62.03237682758266,
                4.153892600058002
              ],
              [
                -62.027355845451844,
                4.152238607392262
              ],
              [
                -62.021132889484754,
                4.157540075539452
              ],
              [
                -62.01777239967988,
                4.156638580585385
              ],
              [
                -62.01194342306001,
                4.156495576615256
              ],
              [
                -62.00876844242141,
                4.159938537535448
              ],
              [
                -62.00852445055304,
                4.160278559400053
              ],
              [
                -62.005196965984275,
                4.164917523263358
              ],
              [
                -62.000221485776315,
                4.164574517006951
              ],
              [
                -61.99257402917091,
                4.175346964364754
              ],
              [
                -61.98373359237254,
                4.17966339846435
              ],
              [
                -61.98233059313412,
                4.171992456171564
              ],
              [
                -61.98180858855281,
                4.16655796944288
              ],
              [
                -61.98169308225141,
                4.164925016865825
              ],
              [
                -61.98147011952402,
                4.16178051601028
              ],
              [
                -61.97915511955738,
                4.156838040247167
              ],
              [
                -61.9762306500954,
                4.151697080242796
              ],
              [
                -61.96533019992045,
                4.141818141615791
              ],
              [
                -61.962721225722206,
                4.139753134933944
              ],
              [
                -61.96011173352749,
                4.13768764225591
              ],
              [
                -61.953830772956586,
                4.130560191647982
              ],
              [
                -61.95010929460814,
                4.125883702445197
              ],
              [
                -61.94836133181598,
                4.121972250070252
              ],
              [
                -61.94700082144977,
                4.117157770199229
              ],
              [
                -61.94483485760417,
                4.112258811300069
              ],
              [
                -61.93714391350567,
                4.107228819606814
              ],
              [
                -61.93333192601389,
                4.105118821429133
              ],
              [
                -61.93003044723051,
                4.10372783039834
              ],
              [
                -61.92933495628794,
                4.109686802276082
              ],
              [
                -61.92947693656164,
                4.112719272720489
              ],
              [
                -61.93003441955451,
                4.115708760682277
              ],
              [
                -61.926426435657476,
                4.121847213625194
              ],
              [
                -61.9248009383957,
                4.128534187646647
              ],
              [
                -61.924109958847914,
                4.132248666573232
              ],
              [
                -61.92315094398118,
                4.137215646030684
              ],
              [
                -61.92272293870543,
                4.13978460779359
              ],
              [
                -61.921336478046854,
                4.148103076447482
              ],
              [
                -61.92079546816672,
                4.15102505507634
              ],
              [
                -61.922503967551044,
                4.155868522975897
              ],
              [
                -61.91526400077903,
                4.157391504387197
              ],
              [
                -61.911188496779836,
                4.158380015971969
              ],
              [
                -61.90660304571842,
                4.157663500159583
              ],
              [
                -61.90202806186911,
                4.159628498726103
              ],
              [
                -61.8992740831809,
                4.161162966986581
              ],
              [
                -61.89481360355431,
                4.158712995361097
              ],
              [
                -61.888564623506156,
                4.155280515302276
              ],
              [
                -61.88564817405896,
                4.154753994473685
              ],
              [
                -61.880816173607215,
                4.155243992471258
              ],
              [
                -61.87123773146052,
                4.154331488559057
              ],
              [
                -61.86961775316734,
                4.159445979670218
              ],
              [
                -61.87099624360667,
                4.162375447479101
              ],
              [
                -61.87119624721916,
                4.162800932127321
              ],
              [
                -61.873088204601736,
                4.167583929458579
              ],
              [
                -61.874231719273425,
                4.170623400473177
              ],
              [
                -61.86864124171334,
                4.173172885944662
              ],
              [
                -61.86068079908458,
                4.171492397549374
              ],
              [
                -61.857376822918994,
                4.172337377458797
              ],
              [
                -61.85084135301792,
                4.174291860778099
              ],
              [
                -61.84822485583782,
                4.169262904427889
              ],
              [
                -61.84697635437334,
                4.166811423924569
              ],
              [
                -61.845937377475494,
                4.164771906153279
              ],
              [
                -61.84127892749984,
                4.163077936005442
              ],
              [
                -61.83595695368013,
                4.160673439576401
              ],
              [
                -61.832324948315566,
                4.161579437919632
              ],
              [
                -61.82340801042284,
                4.163970913140645
              ],
              [
                -61.8181080348004,
                4.170370370297101
              ],
              [
                -61.81668853489344,
                4.177797303971889
              ],
              [
                -61.81599403155158,
                4.181203309612885
              ],
              [
                -61.8157735443609,
                4.184927296703949
              ],
              [
                -61.81512102947878,
                4.188798745648104
              ],
              [
                -61.81554805556467,
                4.189621767117509
              ],
              [
                -61.82097402252978,
                4.200078211560232
              ],
              [
                -61.81499652301178,
                4.201326185333875
              ],
              [
                -61.80825657759002,
                4.202815187194335
              ],
              [
                -61.807406076357594,
                4.21042613512501
              ],
              [
                -61.8072880646992,
                4.21396709733715
              ],
              [
                -61.805072068531935,
                4.216282091334303
              ],
              [
                -61.802085086373026,
                4.219037053246308
              ],
              [
                -61.800980606023316,
                4.224384014233686
              ],
              [
                -61.79999312039023,
                4.227640002529166
              ],
              [
                -61.795802624380926,
                4.229719518586806
              ],
              [
                -61.78823566255374,
                4.231255979584092
              ],
              [
                -61.787340166815426,
                4.229323499303228
              ],
              [
                -61.785104189447694,
                4.224499007064332
              ],
              [
                -61.78197272886604,
                4.224563011619525
              ],
              [
                -61.76786078049907,
                4.229243975877813
              ],
              [
                -61.76313333452109,
                4.232823453782919
              ],
              [
                -61.763275317659755,
                4.235971931433671
              ],
              [
                -61.767870791912785,
                4.239180423685695
              ],
              [
                -61.7704267649811,
                4.24172391148291
              ],
              [
                -61.77078125777203,
                4.242075896656436
              ],
              [
                -61.77299674674816,
                4.246123372419683
              ],
              [
                -61.77439973246141,
                4.249856356485892
              ],
              [
                -61.76963726652826,
                4.249093854786136
              ],
              [
                -61.764198799177706,
                4.25013885804777
              ],
              [
                -61.76052681943773,
                4.251998338709688
              ],
              [
                -61.74952286428235,
                4.255879300640246
              ],
              [
                -61.73813445862407,
                4.25774227913458
              ],
              [
                -61.73604297040662,
                4.260609268928074
              ],
              [
                -61.73534494527005,
                4.263684760425191
              ],
              [
                -61.72294501648944,
                4.269770226451058
              ],
              [
                -61.7211895569505,
                4.26727021594863
              ],
              [
                -61.72280804464094,
                4.260118775987029
              ],
              [
                -61.72763450118262,
                4.256337804380905
              ],
              [
                -61.72865549521367,
                4.25522780957113
              ],
              [
                -61.73075298400639,
                4.252946323347645
              ],
              [
                -61.728540999365244,
                4.24990482171478
              ],
              [
                -61.724445013830284,
                4.24749336413653
              ],
              [
                -61.71563759763143,
                4.24809382576391
              ],
              [
                -61.71326357783127,
                4.24970682272331
              ],
              [
                -61.70885513510899,
                4.251759292750086
              ],
              [
                -61.705275129809564,
                4.253736285767707
              ],
              [
                -61.70296664686882,
                4.255011287749107
              ],
              [
                -61.69654769992056,
                4.254744770043558
              ],
              [
                -61.69086272218358,
                4.254011290568651
              ],
              [
                -61.68473674164832,
                4.256716249610834
              ],
              [
                -61.679721771994785,
                4.254740781876043
              ],
              [
                -61.67287334368892,
                4.261718236333608
              ],
              [
                -61.66968985396456,
                4.262522205489709
              ],
              [
                -61.66558536349561,
                4.263172715933052
              ],
              [
                -61.65659141320538,
                4.261073206280529
              ],
              [
                -61.65535740747149,
                4.257498236469653
              ],
              [
                -61.652067930930826,
                4.254390245548
              ],
              [
                -61.645539973196094,
                4.251768753617848
              ],
              [
                -61.645291493002794,
                4.251137757161757
              ],
              [
                -61.644345484881605,
                4.248734281660986
              ],
              [
                -61.644111514137734,
                4.245777792883083
              ],
              [
                -61.643183495737595,
                4.242121819755607
              ],
              [
                -61.64210800757884,
                4.239351865322832
              ],
              [
                -61.6379635382481,
                4.238640329760056
              ],
              [
                -61.63516553754397,
                4.239202833600633
              ],
              [
                -61.6312440830408,
                4.241425328937354
              ],
              [
                -61.6269345966289,
                4.252887731678366
              ],
              [
                -61.62647408640077,
                4.254112239170996
              ],
              [
                -61.624905603864875,
                4.253081245192294
              ],
              [
                -61.622611626706586,
                4.251573760534675
              ],
              [
                -61.61877565681456,
                4.253665729681646
              ],
              [
                -61.617338633347586,
                4.254449748114829
              ],
              [
                -61.613304669802595,
                4.257700714307312
              ],
              [
                -61.60760670326276,
                4.261436189536123
              ],
              [
                -61.604204713019286,
                4.25432322613196
              ],
              [
                -61.60391022826571,
                4.253418731258904
              ],
              [
                -61.60038673623126,
                4.250050272741745
              ],
              [
                -61.59001480300321,
                4.244527266367305
              ],
              [
                -61.58694182372765,
                4.245293261821595
              ],
              [
                -61.58274386700679,
                4.247206266776645
              ],
              [
                -61.57771839914728,
                4.247267747082051
              ],
              [
                -61.57150491525596,
                4.25143022344785
              ],
              [
                -61.566716439839446,
                4.251850734792737
              ],
              [
                -61.563148455239855,
                4.252786206913735
              ],
              [
                -61.55907498089899,
                4.254601207516122
              ],
              [
                -61.55380302439347,
                4.259286171407387
              ],
              [
                -61.55287251050878,
                4.265507613122812
              ],
              [
                -61.54119657600915,
                4.273125083436857
              ],
              [
                -61.52958214340553,
                4.284923002098275
              ],
              [
                -61.523382163563525,
                4.288934963279988
              ],
              [
                -61.52347867250692,
                4.295728434255702
              ],
              [
                -61.518918204100785,
                4.301773395518858
              ],
              [
                -61.515887216158866,
                4.306229848176093
              ],
              [
                -61.51227621685801,
                4.310111315832156
              ],
              [
                -61.510805738688546,
                4.313754300331362
              ],
              [
                -61.50766523520412,
                4.321431764873918
              ],
              [
                -61.507629220347596,
                4.32151977332984
              ],
              [
                -61.516516680398816,
                4.324526249567845
              ],
              [
                -61.52042167957673,
                4.325847258402259
              ],
              [
                -61.520420663740055,
                4.333071214741839
              ],
              [
                -61.52231563522314,
                4.335860683549722
              ],
              [
                -61.524268615524726,
                4.338434178673222
              ],
              [
                -61.526204608600274,
                4.341485149966336
              ],
              [
                -61.52500512231213,
                4.347469116811727
              ],
              [
                -61.5206866328547,
                4.348532102603153
              ],
              [
                -61.51810114295399,
                4.349164087115406
              ],
              [
                -61.51633865256573,
                4.349595112372937
              ],
              [
                -61.51696116770928,
                4.353096586108487
              ],
              [
                -61.518122171440424,
                4.357169058835749
              ],
              [
                -61.51880766131859,
                4.360533511422753
              ],
              [
                -61.51884763693167,
                4.360774007028924
              ],
              [
                -61.51969514974403,
                4.365845487638945
              ],
              [
                -61.52129563450904,
                4.373595437932845
              ],
              [
                -61.518690643089926,
                4.378102421517723
              ],
              [
                -61.51901514508803,
                4.379840907464033
              ],
              [
                -61.52018063930053,
                4.386087862336209
              ],
              [
                -61.51488063993616,
                4.392182329677624
              ],
              [
                -61.5160701445492,
                4.397624310316472
              ],
              [
                -61.51286665648982,
                4.403582246885321
              ],
              [
                -61.51276464502186,
                4.406574749117179
              ],
              [
                -61.50591269391909,
                4.40893322645535
              ],
              [
                -61.49822274264891,
                4.409658700874048
              ],
              [
                -61.49166477908691,
                4.410290714623191
              ],
              [
                -61.485687329305584,
                4.410866215105191
              ],
              [
                -61.48144182634628,
                4.415017180611724
              ],
              [
                -61.47932484674064,
                4.417229661055244
              ],
              [
                -61.47859483538482,
                4.417905139553053
              ],
              [
                -61.47609038388756,
                4.420221635110958
              ],
              [
                -61.47183038578644,
                4.424700097651945
              ],
              [
                -61.468082408849874,
                4.424733620867404
              ],
              [
                -61.46464094437178,
                4.425963078210121
              ],
              [
                -61.46445740931167,
                4.426098611280574
              ],
              [
                -61.461746934192675,
                4.428100096552611
              ],
              [
                -61.46034094651866,
                4.433330064594905
              ],
              [
                -61.459762948297005,
                4.435478523464997
              ],
              [
                -61.45972993443852,
                4.435543040059875
              ],
              [
                -61.45338399170333,
                4.437657515139822
              ],
              [
                -61.447682501582534,
                4.439427503703485
              ],
              [
                -61.44453001709751,
                4.435398014408634
              ],
              [
                -61.43935006259623,
                4.436370022064409
              ],
              [
                -61.43518058555667,
                4.432754034620948
              ],
              [
                -61.43681859731083,
                4.431327046212899
              ],
              [
                -61.43817356448969,
                4.430147043144319
              ],
              [
                -61.44145205679301,
                4.427573050666085
              ],
              [
                -61.43838107288581,
                4.424943578133687
              ],
              [
                -61.43107510637909,
                4.425894084459044
              ],
              [
                -61.427557145459936,
                4.426965580678751
              ],
              [
                -61.42701915010334,
                4.430449558647983
              ],
              [
                -61.426788154380716,
                4.431943038631891
              ],
              [
                -61.419777189868306,
                4.433413017070174
              ],
              [
                -61.41633419083743,
                4.43258103362664
              ],
              [
                -61.41328272333435,
                4.432139509138087
              ],
              [
                -61.406449268263756,
                4.428608551368475
              ],
              [
                -61.406251274449865,
                4.428506034840064
              ],
              [
                -61.403510295278174,
                4.425030555569782
              ],
              [
                -61.398497801249654,
                4.425444062660005
              ],
              [
                -61.3952968252016,
                4.425580544642896
              ],
              [
                -61.39047284127918,
                4.425756539272149
              ],
              [
                -61.386823398506266,
                4.426269531710762
              ],
              [
                -61.3819224098633,
                4.426681048888251
              ],
              [
                -61.38061040718757,
                4.426791048330444
              ],
              [
                -61.376813419481806,
                4.426969039655246
              ],
              [
                -61.371928456507916,
                4.426282017366318
              ],
              [
                -61.366986977883116,
                4.425556017271076
              ],
              [
                -61.36694001416316,
                4.425534023369313
              ],
              [
                -61.363754508144176,
                4.424061531919141
              ],
              [
                -61.360851033243875,
                4.42301456113719
              ],
              [
                -61.35101310641044,
                4.419146566339927
              ],
              [
                -61.347426121333164,
                4.419622573043318
              ],
              [
                -61.34401514328503,
                4.420068066048758
              ],
              [
                -61.34078713093439,
                4.420910552820564
              ],
              [
                -61.33812467804022,
                4.422267531923287
              ],
              [
                -61.33640915048998,
                4.428462994918551
              ],
              [
                -61.33200721400817,
                4.428960477925999
              ],
              [
                -61.325464224345005,
                4.429863978042331
              ],
              [
                -61.318965766607626,
                4.430517984671495
              ],
              [
                -61.32028926104798,
                4.433558951500332
              ],
              [
                -61.32194825306929,
                4.4373619350295
              ],
              [
                -61.32327824295921,
                4.441147434435689
              ],
              [
                -61.32338026018391,
                4.441437405781702
              ],
              [
                -61.319880266893485,
                4.44589787683068
              ],
              [
                -61.316908794629654,
                4.448883874010843
              ],
              [
                -61.31379427551509,
                4.451374339036564
              ],
              [
                -61.31263329249222,
                4.458032811612418
              ],
              [
                -61.30925331359885,
                4.458023789320655
              ],
              [
                -61.30511083010538,
                4.457886293264537
              ],
              [
                -61.30151185953021,
                4.457766279481794
              ],
              [
                -61.30060436962063,
                4.457823292512793
              ],
              [
                -61.29762188535112,
                4.458010789327359
              ],
              [
                -61.28893294033686,
                4.45821379409036
              ],
              [
                -61.287801949973364,
                4.463668247825742
              ],
              [
                -61.28405846529806,
                4.466342714297049
              ],
              [
                -61.28028146071269,
                4.46916321287147
              ],
              [
                -61.27883897524988,
                4.474406191634698
              ],
              [
                -61.27826849568044,
                4.478450151144479
              ],
              [
                -61.28512394995561,
                4.479826157724991
              ],
              [
                -61.29469436645947,
                4.483264132921239
              ],
              [
                -61.295853850553605,
                4.489540601347212
              ],
              [
                -61.29764036855897,
                4.49371156375194
              ],
              [
                -61.30266931283489,
                4.494137075734871
              ],
              [
                -61.30538380371706,
                4.496691573420292
              ],
              [
                -61.30869427232804,
                4.500544524791823
              ],
              [
                -61.30977576234017,
                4.50180351505163
              ],
              [
                -61.31287275892356,
                4.504554533839211
              ],
              [
                -61.317722723951384,
                4.503931534949556
              ],
              [
                -61.32100870036897,
                4.503955016113617
              ],
              [
                -61.322302711279896,
                4.507944496267858
              ],
              [
                -61.3237627044519,
                4.511645485256432
              ],
              [
                -61.32815915786327,
                4.518455443798383
              ],
              [
                -61.324600677291215,
                4.529430876932347
              ],
              [
                -61.321407697342,
                4.53512482876373
              ],
              [
                -61.314888229670785,
                4.53585182882976
              ],
              [
                -61.307755777095785,
                4.537113807273649
              ],
              [
                -61.30504128619891,
                4.538882292816919
              ],
              [
                -61.300891317611,
                4.537523811982488
              ],
              [
                -61.292997363785744,
                4.536748307031327
              ],
              [
                -61.28743289106322,
                4.537017814495907
              ],
              [
                -61.283308894051956,
                4.537495779734225
              ],
              [
                -61.2815434354768,
                4.535442291733345
              ],
              [
                -61.27542846304697,
                4.528329333838381
              ],
              [
                -61.270175007539,
                4.532943811613972
              ],
              [
                -61.26993549685029,
                4.534434318554403
              ],
              [
                -61.26903999301222,
                4.54000575668717
              ],
              [
                -61.26468650964494,
                4.538581788107924
              ],
              [
                -61.25807907228848,
                4.536798771082192
              ],
              [
                -61.25562555941974,
                4.533594817469341
              ],
              [
                -61.25540507363387,
                4.532214803548446
              ],
              [
                -61.25502107348556,
                4.529816809586291
              ],
              [
                -61.2516110919329,
                4.522456373102734
              ],
              [
                -61.25118310735086,
                4.525325864680053
              ],
              [
                -61.24960411811592,
                4.528434841016324
              ],
              [
                -61.24807862789095,
                4.53109532906442
              ],
              [
                -61.24726214451647,
                4.532519791987527
              ],
              [
                -61.243358152431114,
                4.534218306096982
              ],
              [
                -61.23921616983167,
                4.532865798249893
              ],
              [
                -61.23779467079837,
                4.530126287601609
              ],
              [
                -61.23775018211014,
                4.530040815080479
              ],
              [
                -61.234978679525696,
                4.52901129927396
              ],
              [
                -61.22698873984355,
                4.52606033926629
              ],
              [
                -61.22033927328617,
                4.523522858739262
              ],
              [
                -61.21886529527916,
                4.526898317544507
              ],
              [
                -61.21856078191865,
                4.528451806052413
              ],
              [
                -61.218170296735764,
                4.530447313859361
              ],
              [
                -61.2165173211965,
                4.534599271895186
              ],
              [
                -61.21345933679051,
                4.531361293081296
              ],
              [
                -61.21160634584859,
                4.529134316078733
              ],
              [
                -61.20728685076195,
                4.524220846389468
              ],
              [
                -61.20414337007475,
                4.520645360538054
              ],
              [
                -61.20068490529133,
                4.51689935348644
              ],
              [
                -61.19721593351834,
                4.520395345163322
              ],
              [
                -61.19698643365793,
                4.520626854618794
              ],
              [
                -61.1875099962374,
                4.520843853662632
              ],
              [
                -61.18365749622144,
                4.517780337265453
              ],
              [
                -61.179877046750384,
                4.514784375517882
              ],
              [
                -61.17576156332209,
                4.511584873754747
              ],
              [
                -61.17469857056122,
                4.509847409394718
              ],
              [
                -61.17088558452564,
                4.503614934976861
              ],
              [
                -61.163793141581195,
                4.501001461636694
              ],
              [
                -61.16230865253434,
                4.497356956385465
              ],
              [
                -61.15789116725702,
                4.496413460938993
              ],
              [
                -61.15223720860008,
                4.496047466177107
              ],
              [
                -61.14978723211381,
                4.490842988585394
              ],
              [
                -61.1484752452044,
                4.487025521495309
              ],
              [
                -61.14614675526737,
                4.482913029461145
              ],
              [
                -61.1386227902399,
                4.491212984582746
              ],
              [
                -61.13539378698518,
                4.49495046997352
              ],
              [
                -61.132539308585855,
                4.498712925471547
              ],
              [
                -61.12777586453295,
                4.500307441601929
              ],
              [
                -61.124934865688076,
                4.504601882917131
              ],
              [
                -61.124138369375565,
                4.505806395151287
              ],
              [
                -61.118044381708025,
                4.507657865803981
              ],
              [
                -61.10973843704747,
                4.510325833015354
              ],
              [
                -61.10691894805236,
                4.511789332277121
              ],
              [
                -61.10430497552004,
                4.515221318893105
              ],
              [
                -61.09983599422326,
                4.51765980115432
              ],
              [
                -61.09593052201938,
                4.519676270335208
              ],
              [
                -61.092062057859216,
                4.522765251113691
              ],
              [
                -61.08722506438642,
                4.519860272123348
              ],
              [
                -61.08487158854216,
                4.518750760120077
              ],
              [
                -61.08364458892295,
                4.518171764728654
              ],
              [
                -61.07700713293523,
                4.517793772338143
              ],
              [
                -61.07297464863892,
                4.51956476789475
              ],
              [
                -61.07191216634256,
                4.520053258221171
              ],
              [
                -61.069791181728704,
                4.521028746885313
              ],
              [
                -61.065046196999695,
                4.523503239719514
              ],
              [
                -61.047862805550444,
                4.523847720588691
              ],
              [
                -61.03635735905435,
                4.522411729127326
              ],
              [
                -61.03340037821046,
                4.521955220791434
              ],
              [
                -61.02943592309924,
                4.521507731695047
              ],
              [
                -61.024655955687315,
                4.520985710474577
              ],
              [
                -61.02262395941634,
                4.520763742381447
              ],
              [
                -61.01960148802505,
                4.518765244256986
              ],
              [
                -61.0164589765925,
                4.516372761414819
              ],
              [
                -61.00881953017112,
                4.519173713246035
              ],
              [
                -61.00343807018951,
                4.518669726499694
              ],
              [
                -60.99924759550839,
                4.518044212318911
              ],
              [
                -60.995552123581014,
                4.518032705798267
              ],
              [
                -60.992915133667196,
                4.520829228153412
              ],
              [
                -60.99518010408959,
                4.526091689133187
              ],
              [
                -60.992964135564975,
                4.529185638991623
              ],
              [
                -60.99071762218411,
                4.532322141569961
              ],
              [
                -60.98631916051728,
                4.53768511425239
              ],
              [
                -60.984233159591284,
                4.540290060129507
              ],
              [
                -60.98121269426083,
                4.542513574638629
              ],
              [
                -60.971742748581576,
                4.543502053025112
              ],
              [
                -60.971413741439456,
                4.543529060630962
              ],
              [
                -60.96346878830017,
                4.544193551037886
              ],
              [
                -60.9571413181749,
                4.551664008646942
              ],
              [
                -60.955904305216684,
                4.555968481880365
              ],
              [
                -60.95358032385743,
                4.562554418614966
              ],
              [
                -60.95322031330525,
                4.567686880477884
              ],
              [
                -60.95286084074496,
                4.572820874328688
              ],
              [
                -60.9557378034339,
                4.584017288987911
              ],
              [
                -60.94971334072928,
                4.582726786474741
              ],
              [
                -60.94688336341557,
                4.581972283006282
              ],
              [
                -60.94525936599164,
                4.581539296459364
              ],
              [
                -60.942460895494186,
                4.580792816965976
              ],
              [
                -60.93907039342634,
                4.579977805465556
              ],
              [
                -60.93435743010085,
                4.584566766486431
              ],
              [
                -60.93191142853248,
                4.58674374663758
              ],
              [
                -60.934131916023205,
                4.59216423057142
              ],
              [
                -60.936893899732524,
                4.597140204569118
              ],
              [
                -60.93987388943794,
                4.598947685807108
              ],
              [
                -60.94163287063919,
                4.60001520263205
              ],
              [
                -60.93932289881854,
                4.604263657396789
              ],
              [
                -60.938383407958355,
                4.605991126321075
              ],
              [
                -60.93591490600699,
                4.610286618682342
              ],
              [
                -60.93212593512753,
                4.616647562648946
              ],
              [
                -60.9358788976711,
                4.620901550703426
              ],
              [
                -60.93740039862579,
                4.622809031300154
              ],
              [
                -60.93772487648369,
                4.6232155440833
              ],
              [
                -60.93850988701438,
                4.634424462103644
              ],
              [
                -60.94187035636501,
                4.636472475176477
              ],
              [
                -60.94743529816728,
                4.639870432702214
              ],
              [
                -60.95016730210412,
                4.641923928294554
              ],
              [
                -60.95049878973423,
                4.645389402652259
              ],
              [
                -60.949733783726955,
                4.64870790010462
              ],
              [
                -60.949067303436124,
                4.653030850572812
              ],
              [
                -60.947921298985484,
                4.655811838985586
              ],
              [
                -60.944416809596675,
                4.656364841123138
              ],
              [
                -60.940163343582796,
                4.656783325530405
              ],
              [
                -60.9342993905349,
                4.657528311806229
              ],
              [
                -60.92988941117059,
                4.662478789770446
              ],
              [
                -60.92946290753848,
                4.662573782889448
              ],
              [
                -60.925802927859124,
                4.663389261326468
              ],
              [
                -60.91972798273532,
                4.66454378097195
              ],
              [
                -60.91695099806138,
                4.665296245425285
              ],
              [
                -60.914963969872524,
                4.670227237238652
              ],
              [
                -60.9122670153471,
                4.677090183873828
              ],
              [
                -60.9093075257886,
                4.68607161452115
              ],
              [
                -60.90985551793618,
                4.691227607796481
              ],
              [
                -60.91012350905954,
                4.693090076748494
              ],
              [
                -60.91069297794492,
                4.697048070269386
              ],
              [
                -60.91109249122284,
                4.703184039516448
              ],
              [
                -60.90805549793051,
                4.706842481213264
              ],
              [
                -60.90015954160309,
                4.715753439633409
              ],
              [
                -60.89411057281826,
                4.716260434151319
              ],
              [
                -60.88872812571202,
                4.716099926188753
              ],
              [
                -60.88588913020253,
                4.715748429238728
              ],
              [
                -60.88272912532049,
                4.715685925308202
              ],
              [
                -60.874625702982975,
                4.715257906976514
              ],
              [
                -60.87183521485701,
                4.711244943195142
              ],
              [
                -60.87018372202651,
                4.708869958345576
              ],
              [
                -60.862458249786826,
                4.706721475700325
              ],
              [
                -60.85793928847409,
                4.705580476305063
              ],
              [
                -60.85317632667152,
                4.704286473658801
              ],
              [
                -60.84674984290055,
                4.707821438599368
              ],
              [
                -60.84556686781174,
                4.71177142084666
              ],
              [
                -60.838972398189036,
                4.716433873829605
              ],
              [
                -60.83420944259722,
                4.72451585344729
              ],
              [
                -60.83112995072222,
                4.727971820279107
              ],
              [
                -60.82844946014726,
                4.727872820916478
              ],
              [
                -60.825446998426166,
                4.727762302388093
              ],
              [
                -60.81990351359507,
                4.727196298757895
              ],
              [
                -60.811577064057396,
                4.731661787625238
              ],
              [
                -60.81270755365888,
                4.737047237840019
              ],
              [
                -60.812921525689745,
                4.738067254819636
              ],
              [
                -60.80877754495753,
                4.744213207554494
              ],
              [
                -60.80597608881756,
                4.748592653966863
              ],
              [
                -60.79886462383813,
                4.74824065187384
              ],
              [
                -60.79026316691555,
                4.745825164193162
              ],
              [
                -60.7897541689385,
                4.745682161686049
              ],
              [
                -60.78968517308132,
                4.746028654535445
              ],
              [
                -60.789050162406504,
                4.749202157835305
              ],
              [
                -60.78774916682773,
                4.7533631196584
              ],
              [
                -60.776194733274906,
                4.754486597349008
              ],
              [
                -60.76563881480243,
                4.757266583723943
              ],
              [
                -60.76350682646032,
                4.759421064086498
              ],
              [
                -60.75836035013536,
                4.757860075767659
              ],
              [
                -60.75706287784815,
                4.757495556024269
              ],
              [
                -60.75306538761217,
                4.756373073893103
              ],
              [
                -60.74645441147618,
                4.7592250647231
              ],
              [
                -60.742665456437464,
                4.761674548067996
              ],
              [
                -60.73913595697232,
                4.77086546767725
              ],
              [
                -60.73824044240432,
                4.773197975855787
              ],
              [
                -60.735380490182735,
                4.774076973407896
              ],
              [
                -60.733138970549675,
                4.774766466566557
              ],
              [
                -60.72694051411011,
                4.774821440656414
              ],
              [
                -60.7205825545435,
                4.779031930387146
              ],
              [
                -60.726294511224815,
                4.781082925150597
              ],
              [
                -60.72297902469193,
                4.785952361120145
              ],
              [
                -60.72314652451428,
                4.786245390464016
              ],
              [
                -60.72625402766639,
                4.791678342760723
              ],
              [
                -60.72258403829532,
                4.79365231404892
              ],
              [
                -60.719027550042185,
                4.800010765776129
              ],
              [
                -60.704627134440294,
                4.809975216511601
              ],
              [
                -60.700917651499,
                4.810331719585871
              ],
              [
                -60.695409186774334,
                4.810861200270248
              ],
              [
                -60.6866707345946,
                4.82089964370541
              ],
              [
                -60.685787249506475,
                4.823828131229858
              ],
              [
                -60.685239761311685,
                4.825641113790645
              ],
              [
                -60.680452257045964,
                4.829417587238741
              ],
              [
                -60.68001927338241,
                4.829625086652795
              ],
              [
                -60.6757222885703,
                4.831685546904856
              ],
              [
                -60.67073883126452,
                4.839731502202055
              ],
              [
                -60.66968983104102,
                4.841424993106893
              ],
              [
                -60.66762184867411,
                4.843126462199788
              ],
              [
                -60.66486133486866,
                4.845397477304588
              ],
              [
                -60.66122488789193,
                4.847953955012414
              ],
              [
                -60.658402376492624,
                4.850160443458615
              ],
              [
                -60.65857287405569,
                4.852114919681182
              ],
              [
                -60.65897336432758,
                4.856707907003276
              ],
              [
                -60.65582187133155,
                4.864927348560805
              ],
              [
                -60.649952928709794,
                4.868145318539496
              ],
              [
                -60.64932440899278,
                4.872605787882233
              ],
              [
                -60.65078189916936,
                4.875857266162347
              ],
              [
                -60.65323540371318,
                4.881641729755229
              ],
              [
                -60.65180089247302,
                4.884973192366591
              ],
              [
                -60.648569396678674,
                4.885502704700111
              ],
              [
                -60.63893698291092,
                4.887140678443245
              ],
              [
                -60.6300125090289,
                4.888846161304749
              ],
              [
                -60.62822705405759,
                4.889186686879632
              ],
              [
                -60.62732005088402,
                4.893712648586128
              ],
              [
                -60.624251540266,
                4.898096588520811
              ],
              [
                -60.624388049774296,
                4.89903461090099
              ],
              [
                -60.625176036182566,
                4.904420566635169
              ],
              [
                -60.615382091092414,
                4.918373972047724
              ],
              [
                -60.61104559776162,
                4.921447447989692
              ],
              [
                -60.608280124609756,
                4.924213930995783
              ],
              [
                -60.60483313693746,
                4.9249284350118
              ],
              [
                -60.601346179491394,
                4.925650925947883
              ],
              [
                -60.59127973632336,
                4.927244892555639
              ],
              [
                -60.589875722858174,
                4.931952865723773
              ],
              [
                -60.58886223130608,
                4.93643883054183
              ],
              [
                -60.58869572900765,
                4.943376286021202
              ],
              [
                -60.587584748675866,
                4.946985262117103
              ],
              [
                -60.584599265200424,
                4.951848236112837
              ],
              [
                -60.58348026340511,
                4.956122698127504
              ],
              [
                -60.592125682147916,
                4.971088127262608
              ],
              [
                -60.59230667578046,
                4.98198706076608
              ],
              [
                -60.59480664250055,
                4.992261977861571
              ],
              [
                -60.598654116599505,
                4.9997174382373
              ],
              [
                -60.60374358912517,
                5.002494933182271
              ],
              [
                -60.606173092183205,
                5.00371443360201
              ],
              [
                -60.608620562131016,
                5.004943427978438
              ],
              [
                -60.611355564140894,
                5.007839392385999
              ],
              [
                -60.611886034661794,
                5.010988397542491
              ],
              [
                -60.61142505868993,
                5.013105376241481
              ],
              [
                -60.61092955079438,
                5.015380868943753
              ],
              [
                -60.61562502916097,
                5.020016332236439
              ],
              [
                -60.61605752033896,
                5.020443820957764
              ],
              [
                -60.62319947873093,
                5.027511280284425
              ],
              [
                -60.62602494366735,
                5.034997754704168
              ],
              [
                -60.62622343132444,
                5.036548732374837
              ],
              [
                -60.62711141583884,
                5.043486696640541
              ],
              [
                -60.62727193172353,
                5.043732692261212
              ],
              [
                -60.63139340409122,
                5.050038640910918
              ],
              [
                -60.632231390908004,
                5.050497153760559
              ],
              [
                -60.63676438487127,
                5.052977120132167
              ],
              [
                -60.641591836994415,
                5.056933139711768
              ],
              [
                -60.64274880391154,
                5.065191571118477
              ],
              [
                -60.641775309566746,
                5.070268531309276
              ],
              [
                -60.64139582258802,
                5.074330503169459
              ],
              [
                -60.641045340693935,
                5.078066494044113
              ],
              [
                -60.64100482958682,
                5.078502976342592
              ],
              [
                -60.64024781232625,
                5.08186196620231
              ],
              [
                -60.64233632028362,
                5.089559929752282
              ],
              [
                -60.64333029725182,
                5.092509889487246
              ],
              [
                -60.64431277982786,
                5.096043889640222
              ],
              [
                -60.64025781112778,
                5.110148791138776
              ],
              [
                -60.64204279902892,
                5.117554250226536
              ],
              [
                -60.64495576056332,
                5.118428722177157
              ],
              [
                -60.6487332554479,
                5.118886234363671
              ],
              [
                -60.650130742311234,
                5.117727232560679
              ],
              [
                -60.654557205707334,
                5.114055760522547
              ],
              [
                -60.65546321134743,
                5.118054743772072
              ],
              [
                -60.65654720810151,
                5.125685210942007
              ],
              [
                -60.65715218046656,
                5.129685689942076
              ],
              [
                -60.65773817049866,
                5.134243659514695
              ],
              [
                -60.65647167777757,
                5.141861111890494
              ],
              [
                -60.65583117441983,
                5.14571507879242
              ],
              [
                -60.65541717797431,
                5.149292540564915
              ],
              [
                -60.65608216129544,
                5.156681008864078
              ],
              [
                -60.66062163228654,
                5.164085465152033
              ],
              [
                -60.66791160617194,
                5.169307442002578
              ],
              [
                -60.67344304795669,
                5.17606987602738
              ],
              [
                -60.67724554720051,
                5.17716490036576
              ],
              [
                -60.67847803082505,
                5.177519899178772
              ],
              [
                -60.67879500686834,
                5.178942884722686
              ],
              [
                -60.67949101837825,
                5.182063350179828
              ],
              [
                -60.680204996785974,
                5.188943338637785
              ],
              [
                -60.68607245945624,
                5.194665281318463
              ],
              [
                -60.69406840723047,
                5.197447756707555
              ],
              [
                -60.69750538954754,
                5.196085799802622
              ],
              [
                -60.70101038749661,
                5.194696796116324
              ],
              [
                -60.717311290526986,
                5.196240820759496
              ],
              [
                -60.720528763698574,
                5.196655808805589
              ],
              [
                -60.72769721559651,
                5.200279771402826
              ],
              [
                -60.73204318531176,
                5.200664262545545
              ],
              [
                -60.73558716160954,
                5.202334782180912
              ],
              [
                -60.72415472764257,
                5.218149166252283
              ],
              [
                -60.72258822490808,
                5.220316148731173
              ],
              [
                -60.71842375325111,
                5.219815137436428
              ],
              [
                -60.71473980405341,
                5.219040646203559
              ],
              [
                -60.709600824561,
                5.219093132737062
              ],
              [
                -60.706001349509464,
                5.221275124478568
              ],
              [
                -60.701681853212946,
                5.225184608060784
              ],
              [
                -60.6964948758159,
                5.229208064237683
              ],
              [
                -60.67780800360601,
                5.232610044298617
              ],
              [
                -60.67472652596,
                5.231842046512856
              ],
              [
                -60.66801654732721,
                5.229676530359028
              ],
              [
                -60.66413508168142,
                5.228423061904617
              ],
              [
                -60.6581546217485,
                5.227637574888832
              ],
              [
                -60.65730711541004,
                5.227910035534668
              ],
              [
                -60.6551361393511,
                5.228608555500225
              ],
              [
                -60.65037915003906,
                5.230735533619941
              ],
              [
                -60.64714818352063,
                5.228407025285084
              ],
              [
                -60.64079371164253,
                5.222783060636006
              ],
              [
                -60.63808673416025,
                5.220387603139867
              ],
              [
                -60.6320282595698,
                5.219763588082459
              ],
              [
                -60.62934177204504,
                5.217276608168065
              ],
              [
                -60.62427332160293,
                5.215595610394413
              ],
              [
                -60.61326190336834,
                5.217729580436529
              ],
              [
                -60.612316400672874,
                5.217912586552165
              ],
              [
                -60.61078890486732,
                5.217429087288054
              ],
              [
                -60.60828543793678,
                5.216637072130959
              ],
              [
                -60.60398744836386,
                5.215834588593856
              ],
              [
                -60.60281243663849,
                5.216460585806648
              ],
              [
                -60.60087897796373,
                5.217490561929277
              ],
              [
                -60.598816483934364,
                5.219892042530315
              ],
              [
                -60.595470982980636,
                5.220204046919886
              ],
              [
                -60.5911955113326,
                5.218096576411188
              ],
              [
                -60.590362533807735,
                5.214763593198502
              ],
              [
                -60.59028454182407,
                5.211557608813284
              ],
              [
                -60.590215527496746,
                5.208714611206625
              ],
              [
                -60.585259054003046,
                5.205073145573214
              ],
              [
                -60.575797127151226,
                5.199376163946
              ],
              [
                -60.57009167607509,
                5.196875691719736
              ],
              [
                -60.564887206617044,
                5.196248200399148
              ],
              [
                -60.54508230534978,
                5.194522189124775
              ],
              [
                -60.54067084188745,
                5.200719127516678
              ],
              [
                -60.538974322784696,
                5.203102627510154
              ],
              [
                -60.53608884854272,
                5.204243606182871
              ],
              [
                -60.53220436373906,
                5.205803599477137
              ],
              [
                -60.52756440069924,
                5.208119068518913
              ],
              [
                -60.52263443562912,
                5.207568063946351
              ],
              [
                -60.51912995327612,
                5.205824601850859
              ],
              [
                -60.509694000187984,
                5.204112108806549
              ],
              [
                -60.50656851751023,
                5.202543084947487
              ],
              [
                -60.501260572326984,
                5.199878618076747
              ],
              [
                -60.481845166865746,
                5.19957659137065
              ],
              [
                -60.475757210637916,
                5.197635116438969
              ],
              [
                -60.47442523022189,
                5.20029860498022
              ],
              [
                -60.47399671801847,
                5.201155079365614
              ],
              [
                -60.46772027215314,
                5.202644566165517
              ],
              [
                -60.46367227981659,
                5.199843580162661
              ],
              [
                -60.45307536244512,
                5.203251567685449
              ],
              [
                -60.448696379696145,
                5.200874555813058
              ],
              [
                -60.44901487467199,
                5.194825608317577
              ],
              [
                -60.44861787103146,
                5.193493600201855
              ],
              [
                -60.445981401337434,
                5.184644675579842
              ],
              [
                -60.433662986978085,
                5.18175567953237
              ],
              [
                -60.42972500442629,
                5.184732166054961
              ],
              [
                -60.425650517282264,
                5.189449630738555
              ],
              [
                -60.41819756669426,
                5.19545007980473
              ],
              [
                -60.41750254391386,
                5.203994035592068
              ],
              [
                -60.41732707720781,
                5.206154018138216
              ],
              [
                -60.41189959354749,
                5.21381594455096
              ],
              [
                -60.40522061409132,
                5.211528980289355
              ],
              [
                -60.39725769107722,
                5.21551343432413
              ],
              [
                -60.38822523297161,
                5.219876390153415
              ],
              [
                -60.38447123171472,
                5.221165908179612
              ],
              [
                -60.37867627475877,
                5.219381389533222
              ],
              [
                -60.37421830957711,
                5.216936902036897
              ],
              [
                -60.370463352093175,
                5.214773908370598
              ],
              [
                -60.37044933518088,
                5.214765922408676
              ],
              [
                -60.37086334604769,
                5.20816495647776
              ],
              [
                -60.36636037584238,
                5.20482399148614
              ],
              [
                -60.36148890709785,
                5.207353474970346
              ],
              [
                -60.358343909021926,
                5.20902142915318
              ],
              [
                -60.35743693103761,
                5.209333450499758
              ],
              [
                -60.35538493038511,
                5.21004043848976
              ],
              [
                -60.35139196936381,
                5.207785952206936
              ],
              [
                -60.351232467368796,
                5.207686962690271
              ],
              [
                -60.34805145841033,
                5.20571398032238
              ],
              [
                -60.345210995911316,
                5.205703475111411
              ],
              [
                -60.33759654552812,
                5.20771193060683
              ],
              [
                -60.333413567837376,
                5.20769393036568
              ],
              [
                -60.32760460402809,
                5.205877929928865
              ],
              [
                -60.323733628537425,
                5.207106432238243
              ],
              [
                -60.31905115355372,
                5.20859893526456
              ],
              [
                -60.31496316216612,
                5.211285410386701
              ],
              [
                -60.310575194112204,
                5.214487382078084
              ],
              [
                -60.30937770804466,
                5.217026872420407
              ],
              [
                -60.308579701332654,
                5.218717363330088
              ],
              [
                -60.305848728004534,
                5.222724310365675
              ],
              [
                -60.29620928267556,
                5.226935778577118
              ],
              [
                -60.28760282828731,
                5.228728265569917
              ],
              [
                -60.28745231013747,
                5.228759759254038
              ],
              [
                -60.2826038547563,
                5.229484281872635
              ],
              [
                -60.277329872650874,
                5.234209743391305
              ],
              [
                -60.27037691663137,
                5.234796722161925
              ],
              [
                -60.26964243631254,
                5.24052719910217
              ],
              [
                -60.26919743311854,
                5.243997153264648
              ],
              [
                -60.26342693372892,
                5.251430619588016
              ],
              [
                -60.25616949056042,
                5.251937595588515
              ],
              [
                -60.25367399710273,
                5.251917105700386
              ],
              [
                -60.24937904213409,
                5.251881106455503
              ],
              [
                -60.24627654194834,
                5.252190104036511
              ],
              [
                -60.24102008462445,
                5.257079569499736
              ],
              [
                -60.23454862946816,
                5.263099032967263
              ],
              [
                -60.22593918072145,
                5.264209997116157
              ],
              [
                -60.21999470001747,
                5.267110467324406
              ],
              [
                -60.214436215294036,
                5.270971956880013
              ],
              [
                -60.209706250952635,
                5.270854948774535
              ],
              [
                -60.20654127160099,
                5.269362958464963
              ],
              [
                -60.20141881831708,
                5.267055971627626
              ],
              [
                -60.19695684626725,
                5.259675501798824
              ],
              [
                -60.195448833988166,
                5.257275541466867
              ],
              [
                -60.192635377676055,
                5.251941539280873
              ],
              [
                -60.19336635579452,
                5.247729589994642
              ],
              [
                -60.19419988178306,
                5.24293063042875
              ],
              [
                -60.1944793587389,
                5.239704151662352
              ],
              [
                -60.19143339079095,
                5.236840145971462
              ],
              [
                -60.18646543308018,
                5.233938646969619
              ],
              [
                -60.18212443188801,
                5.232071684058896
              ],
              [
                -60.178131987545626,
                5.230354183503454
              ],
              [
                -60.170264519520046,
                5.227747185356105
              ],
              [
                -60.164496063369626,
                5.230004655717461
              ],
              [
                -60.16368858193661,
                5.2301851809487
              ],
              [
                -60.162582059195074,
                5.230432657524232
              ],
              [
                -60.16166856136218,
                5.230636662524545
              ],
              [
                -60.15913810438595,
                5.231564653732919
              ],
              [
                -60.15780810275699,
                5.232052644635879
              ],
              [
                -60.138513711012116,
                5.247501552200476
              ],
              [
                -60.134957704295715,
                5.249347029872967
              ],
              [
                -60.13480923230578,
                5.24417806585779
              ],
              [
                -60.13419921535056,
                5.238579580136449
              ],
              [
                -60.1333267568469,
                5.230573651151786
              ],
              [
                -60.13220076683429,
                5.226301191777804
              ],
              [
                -60.12492429447113,
                5.217346215550346
              ],
              [
                -60.12267183730588,
                5.213983242629663
              ],
              [
                -60.118459849078626,
                5.208743784795435
              ],
              [
                -60.11589688533824,
                5.20586679461228
              ],
              [
                -60.11401736499494,
                5.203757287210487
              ],
              [
                -60.11011891210146,
                5.199564328136213
              ],
              [
                -60.10621593293374,
                5.194488872542287
              ],
              [
                -60.10310096756147,
                5.175002994060684
              ],
              [
                -60.102313472873206,
                5.171571998737265
              ],
              [
                -60.10202147309325,
                5.16965449441024
              ],
              [
                -60.100758981007026,
                5.161366561861442
              ],
              [
                -60.09857802918159,
                5.148113635400509
              ],
              [
                -60.096347038855356,
                5.144758675461894
              ],
              [
                -60.09442304936217,
                5.141877182828061
              ],
              [
                -60.09019807288019,
                5.140827201011772
              ],
              [
                -60.08362462240853,
                5.139421707551727
              ],
              [
                -60.08011062971293,
                5.139727192883825
              ],
              [
                -60.07923212689041,
                5.139674693516962
              ],
              [
                -60.07423666933208,
                5.139378191418569
              ],
              [
                -60.06677021526741,
                5.133929722368937
              ],
              [
                -60.065380210965436,
                5.128095250507101
              ],
              [
                -60.06260474872917,
                5.127417756530997
              ],
              [
                -60.05872526005889,
                5.125325281475184
              ],
              [
                -60.05953778170642,
                5.122254795182776
              ],
              [
                -60.05982976086128,
                5.121150791268181
              ],
              [
                -60.057114792713975,
                5.117347332755733
              ],
              [
                -60.056203312043074,
                5.11607032897057
              ],
              [
                -60.053864807280235,
                5.112794353480033
              ],
              [
                -60.047462849069966,
                5.112367838108312
              ],
              [
                -60.046975366947784,
                5.112335319928005
              ],
              [
                -60.043529882265936,
                5.109569866403879
              ],
              [
                -60.04024840352188,
                5.106936370190803
              ],
              [
                -60.02755748327106,
                5.09734442284661
              ],
              [
                -60.02635650904265,
                5.093817938816485
              ],
              [
                -60.0198750294692,
                5.091914954577572
              ],
              [
                -60.01654556250613,
                5.08993044430904
              ],
              [
                -60.01509606445111,
                5.08934197683439
              ],
              [
                -60.010855090623494,
                5.087620955219894
              ],
              [
                -60.00798760450631,
                5.087507458688473
              ],
              [
                -60.00320161542216,
                5.088948939996022
              ],
              [
                -60.00017267385279,
                5.088063957133878
              ],
              [
                -59.996158663806554,
                5.084921495537518
              ],
              [
                -59.99932265436465,
                5.079636497852452
              ],
              [
                -60.00280113226566,
                5.07410904391089
              ],
              [
                -60.00269815496554,
                5.073984068607394
              ],
              [
                -59.9975231901225,
                5.067722586494352
              ],
              [
                -60.00444814603775,
                5.061918140937001
              ],
              [
                -60.007502633122435,
                5.059357629213527
              ],
              [
                -60.003711664504294,
                5.055237182870288
              ],
              [
                -60.00319064981512,
                5.054901162553886
              ],
              [
                -59.999848670055435,
                5.052742173376712
              ],
              [
                -59.995220213130416,
                5.048898723670593
              ],
              [
                -59.9952137118119,
                5.044162726087944
              ],
              [
                -59.99988369833264,
                5.039115254050633
              ],
              [
                -60.0025796871532,
                5.034409799356198
              ],
              [
                -60.000339700643195,
                5.031322330801633
              ],
              [
                -60.00262466568244,
                5.025551372399357
              ],
              [
                -60.00232467784691,
                5.02110187079568
              ],
              [
                -60.00177418531208,
                5.012941946033371
              ],
              [
                -59.98970329709779,
                4.986604606277589
              ],
              [
                -59.9911917778765,
                4.981997620010672
              ],
              [
                -59.99122828774843,
                4.981884622739874
              ],
              [
                -59.99024929066849,
                4.976370650215037
              ],
              [
                -59.99000978709388,
                4.975022674398268
              ],
              [
                -59.98899231419558,
                4.97087918633734
              ],
              [
                -59.99134379320572,
                4.967198727967933
              ],
              [
                -59.99538276432735,
                4.960876261269881
              ],
              [
                -59.99372627609499,
                4.956822303160931
              ],
              [
                -59.992938779963715,
                4.953835795090141
              ],
              [
                -59.99115779249292,
                4.947081831636295
              ],
              [
                -59.9938497783851,
                4.946202852130064
              ],
              [
                -59.994745281359656,
                4.945910377623192
              ],
              [
                -59.99463630571957,
                4.94362588871005
              ],
              [
                -59.99453281096862,
                4.941444894158749
              ],
              [
                -59.99409628945458,
                4.936224936202066
              ],
              [
                -59.99363181312126,
                4.932307444145223
              ],
              [
                -59.9980642903831,
                4.926682473374166
              ],
              [
                -59.99968327066932,
                4.92432601220663
              ],
              [
                -60.00225074500665,
                4.921161032765138
              ],
              [
                -60.00232424964405,
                4.921070505381788
              ],
              [
                -60.00624273494504,
                4.910060101578576
              ],
              [
                -60.00725525226061,
                4.907059601934833
              ],
              [
                -60.00816674601384,
                4.900248157826443
              ],
              [
                -60.00908224268222,
                4.893509724262327
              ],
              [
                -60.01030221846241,
                4.886903746099401
              ],
              [
                -60.01108421620065,
                4.882448292282404
              ],
              [
                -60.011180740420116,
                4.881393768886078
              ],
              [
                -60.01136322183187,
                4.879403777347648
              ],
              [
                -60.01175773859543,
                4.876614820915421
              ],
              [
                -60.012165710350025,
                4.873730325083833
              ],
              [
                -60.012644742057375,
                4.87034734039086
              ],
              [
                -60.012999228840535,
                4.867842864163637
              ],
              [
                -60.01389674299377,
                4.86324739128714
              ],
              [
                -60.01417274011703,
                4.859550423381546
              ],
              [
                -60.0143292134419,
                4.85517243560122
              ],
              [
                -60.016492210316535,
                4.842266547093537
              ],
              [
                -60.01813023272787,
                4.838200057475231
              ],
              [
                -60.019767709140325,
                4.834133596852713
              ],
              [
                -60.02044969564404,
                4.833461108519298
              ],
              [
                -60.029199161787254,
                4.824836150356512
              ],
              [
                -60.03040116271148,
                4.819451191550837
              ],
              [
                -60.03308715623538,
                4.810948739112846
              ],
              [
                -60.032015637326325,
                4.809828241250558
              ],
              [
                -60.02847716074272,
                4.806127787522482
              ],
              [
                -60.032162677190655,
                4.802012793681163
              ],
              [
                -60.03506813156574,
                4.798767818881331
              ],
              [
                -60.03756513744382,
                4.795367342738406
              ],
              [
                -60.039197629189154,
                4.784004437164258
              ],
              [
                -60.03649764187774,
                4.781406450238231
              ],
              [
                -60.03171317521089,
                4.778191942595948
              ],
              [
                -60.02817871062009,
                4.775817461681322
              ],
              [
                -60.02658421366957,
                4.773454463118155
              ],
              [
                -60.03045668955992,
                4.758930573699308
              ],
              [
                -60.02954222577324,
                4.746693647631496
              ],
              [
                -60.028719210401,
                4.738651211711245
              ],
              [
                -60.03261718812016,
                4.732380744269642
              ],
              [
                -60.035642189290186,
                4.728404263019628
              ],
              [
                -60.03571869791541,
                4.728303264698761
              ],
              [
                -60.038782669780105,
                4.723043826392199
              ],
              [
                -60.04074867780903,
                4.719529831509025
              ],
              [
                -60.04114664396461,
                4.716075870117451
              ],
              [
                -60.03879418869003,
                4.713339863304284
              ],
              [
                -60.03639367715014,
                4.711364389763011
              ],
              [
                -60.0338667214248,
                4.709284885773711
              ],
              [
                -60.03029523218669,
                4.705286435877479
              ],
              [
                -60.02955022570349,
                4.700879442544275
              ],
              [
                -60.03334920429705,
                4.696942961579721
              ],
              [
                -60.038556710089615,
                4.692394508399377
              ],
              [
                -60.04122668414426,
                4.690057031743745
              ],
              [
                -60.043993155954595,
                4.68727205491582
              ],
              [
                -60.05517061402523,
                4.676861119158321
              ],
              [
                -60.06274206092714,
                4.669612176281261
              ],
              [
                -60.06312306896025,
                4.669246653807539
              ],
              [
                -60.06565855846117,
                4.665984699730793
              ],
              [
                -60.07808100191619,
                4.64901431318134
              ],
              [
                -60.08010697544507,
                4.641436867300217
              ],
              [
                -60.07810948832797,
                4.638308370146482
              ],
              [
                -60.077923979603064,
                4.638037865680499
              ],
              [
                -60.07454901180949,
                4.633102884674496
              ],
              [
                -60.07553850006283,
                4.630220429130674
              ],
              [
                -60.07721802387731,
                4.626495943260537
              ],
              [
                -60.07824401194678,
                4.623568446015891
              ],
              [
                -60.07554303609069,
                4.613108513506251
              ],
              [
                -60.07455653877719,
                4.608950043411163
              ],
              [
                -60.079834499828245,
                4.606262572688163
              ],
              [
                -60.083258472020965,
                4.604560581584768
              ],
              [
                -60.086398945688934,
                4.603118106681966
              ],
              [
                -60.09189093195984,
                4.599771613154562
              ],
              [
                -60.096385413791594,
                4.598233149793405
              ],
              [
                -60.1025608911546,
                4.596205136627647
              ],
              [
                -60.10511835593512,
                4.598043146127011
              ],
              [
                -60.106483332376754,
                4.5988841339179
              ],
              [
                -60.10967433237024,
                4.600849610082571
              ],
              [
                -60.116772767616816,
                4.595602172364576
              ],
              [
                -60.12633473611075,
                4.596610162915915
              ],
              [
                -60.13478719173369,
                4.588134741971292
              ],
              [
                -60.1323407027013,
                4.58141926551691
              ],
              [
                -60.13136621055995,
                4.578307287954609
              ],
              [
                -60.13115222228517,
                4.57762428900113
              ],
              [
                -60.12939520991635,
                4.57421279272959
              ],
              [
                -60.12880172081882,
                4.56927883463023
              ],
              [
                -60.13300869799584,
                4.569680332115743
              ],
              [
                -60.13762168960197,
                4.570376322077775
              ],
              [
                -60.14146464835694,
                4.568698351082479
              ],
              [
                -60.14265566577302,
                4.56817836811211
              ],
              [
                -60.142619165403204,
                4.567579364765675
              ],
              [
                -60.142328665435386,
                4.56278539100621
              ],
              [
                -60.13983168539033,
                4.556469931044331
              ],
              [
                -60.147657630923064,
                4.557660437211543
              ],
              [
                -60.149199114771115,
                4.557895447847785
              ],
              [
                -60.15139909249765,
                4.562848886973654
              ],
              [
                -60.15677407705043,
                4.565878374141284
              ],
              [
                -60.15833154894817,
                4.563038392669553
              ],
              [
                -60.15998503315605,
                4.557508448775645
              ],
              [
                -60.16319154613055,
                4.547911003925072
              ],
              [
                -60.158401065264215,
                4.540396039700011
              ],
              [
                -60.15854206032419,
                4.536233057345624
              ],
              [
                -60.158592059060105,
                4.534748072493029
              ],
              [
                -60.15940408200715,
                4.530871088848218
              ],
              [
                -60.159764572095014,
                4.529150099658728
              ],
              [
                -60.16038807597179,
                4.525513641403634
              ],
              [
                -60.16140108154291,
                4.519025164912884
              ],
              [
                -60.16302707179435,
                4.512159747152927
              ],
              [
                -60.1619460687218,
                4.508341741820962
              ],
              [
                -60.15780860612836,
                4.50501125138343
              ],
              [
                -60.15471310623743,
                4.503462769739281
              ],
              [
                -60.138718697033205,
                4.505598730567348
              ],
              [
                -60.13343123125846,
                4.502125255225503
              ],
              [
                -60.104255928952284,
                4.501470745074405
              ],
              [
                -60.103202932747536,
                4.504896730305169
              ],
              [
                -60.10488891295232,
                4.512104176203266
              ],
              [
                -60.10523590347349,
                4.512763165395925
              ],
              [
                -60.10750290327966,
                4.51706516554276
              ],
              [
                -60.103700917878186,
                4.519381150678504
              ],
              [
                -60.09904245009689,
                4.520800126727844
              ],
              [
                -60.09432898652234,
                4.522389606689286
              ],
              [
                -60.090939501438704,
                4.523660593525102
              ],
              [
                -60.08904550242433,
                4.519416117295297
              ],
              [
                -60.0874520091887,
                4.515845670132242
              ],
              [
                -60.08814650072605,
                4.512076191777513
              ],
              [
                -60.08846152105989,
                4.510369205484785
              ],
              [
                -60.08612804000204,
                4.511366666739668
              ],
              [
                -60.083029046191115,
                4.512691168453616
              ],
              [
                -60.08074954420388,
                4.514583669242898
              ],
              [
                -60.07601607524783,
                4.516918653609369
              ],
              [
                -60.07241809786284,
                4.516054132429925
              ],
              [
                -60.066974628813746,
                4.51148068277609
              ],
              [
                -60.06731013417585,
                4.508100698788126
              ],
              [
                -60.0694391415242,
                4.50549671726849
              ],
              [
                -60.07005361144984,
                4.504745226312775
              ],
              [
                -60.070384120740606,
                4.494211271286944
              ],
              [
                -60.063201171158305,
                4.496479258391128
              ],
              [
                -60.05895418609155,
                4.496313276481025
              ],
              [
                -60.0533827179131,
                4.495937259948843
              ],
              [
                -60.04494928343929,
                4.494147272139111
              ],
              [
                -60.04040082275714,
                4.493089779506803
              ],
              [
                -60.04007479771519,
                4.493014264746511
              ],
              [
                -60.039599313589534,
                4.492876777264343
              ],
              [
                -60.03464434154708,
                4.491440776681922
              ],
              [
                -60.02823538279551,
                4.49324527616451
              ],
              [
                -60.02501291602362,
                4.494152742359953
              ],
              [
                -60.0236084137578,
                4.493395256052541
              ],
              [
                -60.01805095332048,
                4.490397262670632
              ],
              [
                -60.01228049278129,
                4.493763245980279
              ],
              [
                -60.01222649107607,
                4.493794236752337
              ],
              [
                -60.00871001065578,
                4.496713233361105
              ],
              [
                -60.00280953133107,
                4.496504725575657
              ],
              [
                -60.00012554132164,
                4.493171744244934
              ],
              [
                -59.998606070506604,
                4.491284272818467
              ],
              [
                -59.99408759371231,
                4.485788770542021
              ],
              [
                -59.99177412990006,
                4.483380813776304
              ],
              [
                -59.984414141719874,
                4.480850295299112
              ],
              [
                -59.97786519107626,
                4.482309277814482
              ],
              [
                -59.97326572975941,
                4.47828481744176
              ],
              [
                -59.96955275643732,
                4.478389327248941
              ],
              [
                -59.97177421888178,
                4.485049761747886
              ],
              [
                -59.97081274418771,
                4.491383213069708
              ],
              [
                -59.96902824359609,
                4.503137666414037
              ],
              [
                -59.96517925696811,
                4.503396176163926
              ],
              [
                -59.95855781802854,
                4.500091684961128
              ],
              [
                -59.9508333319652,
                4.497654170659612
              ],
              [
                -59.946361386224474,
                4.494660208034889
              ],
              [
                -59.939318909299615,
                4.492039694341596
              ],
              [
                -59.93374243832485,
                4.49448271043776
              ],
              [
                -59.92954346979978,
                4.487378747356611
              ],
              [
                -59.926321513498394,
                4.486200724406294
              ],
              [
                -59.92273954108122,
                4.483891763184759
              ],
              [
                -59.92373553421344,
                4.480996765722733
              ],
              [
                -59.92391150853065,
                4.480484264004082
              ],
              [
                -59.921477047314426,
                4.475337298074241
              ],
              [
                -59.915615074175165,
                4.474637313372125
              ],
              [
                -59.91162560390362,
                4.474160311579554
              ],
              [
                -59.908434112348125,
                4.473101822921411
              ],
              [
                -59.90970610193033,
                4.470094335343685
              ],
              [
                -59.90972110189266,
                4.470020319810501
              ],
              [
                -59.91126760498211,
                4.462419364747509
              ],
              [
                -59.90898461759488,
                4.459148418359344
              ],
              [
                -59.904962640256706,
                4.456103928388832
              ],
              [
                -59.89920766709045,
                4.46338386955369
              ],
              [
                -59.8975636933397,
                4.466916830633622
              ],
              [
                -59.89717519905289,
                4.46775135321909
              ],
              [
                -59.89483922209606,
                4.471675311408807
              ],
              [
                -59.88643124285784,
                4.472546303274836
              ],
              [
                -59.880126292955666,
                4.472426817693835
              ],
              [
                -59.8762793172782,
                4.47575876248926
              ],
              [
                -59.874990308716534,
                4.478889750274985
              ],
              [
                -59.87244081665788,
                4.480403253181025
              ],
              [
                -59.87091985665513,
                4.474430789015582
              ],
              [
                -59.867043360540684,
                4.474257275431151
              ],
              [
                -59.862311386443444,
                4.471156807336439
              ],
              [
                -59.8609989058504,
                4.467186300957267
              ],
              [
                -59.86217240238776,
                4.465078333776325
              ],
              [
                -59.86651739716292,
                4.45726638197558
              ],
              [
                -59.86468090048146,
                4.455004888683503
              ],
              [
                -59.85924642707682,
                4.449577438485401
              ],
              [
                -59.84939947708788,
                4.452301411956222
              ],
              [
                -59.84335102387852,
                4.448978936354107
              ],
              [
                -59.84004355446566,
                4.450238418322396
              ],
              [
                -59.83650007615779,
                4.451917399537082
              ],
              [
                -59.83510155992993,
                4.45258039248839
              ],
              [
                -59.830643113264465,
                4.455350880332642
              ],
              [
                -59.827590125549655,
                4.453376373451274
              ],
              [
                -59.82746360821889,
                4.452008380817631
              ],
              [
                -59.82723162528168,
                4.449495904183332
              ],
              [
                -59.82183967166619,
                4.447008412882463
              ],
              [
                -59.81562769005297,
                4.445740910488158
              ],
              [
                -59.812064725980576,
                4.445781927810806
              ],
              [
                -59.80569824680553,
                4.451145397324449
              ],
              [
                -59.80527726627358,
                4.452636879817147
              ],
              [
                -59.803815269369984,
                4.457815859801116
              ],
              [
                -59.80264677537493,
                4.463527321687763
              ],
              [
                -59.79786780989958,
                4.465876768893919
              ],
              [
                -59.79359430507951,
                4.465827790277082
              ],
              [
                -59.79249332297633,
                4.462261307584694
              ],
              [
                -59.79272682498445,
                4.457267331631049
              ],
              [
                -59.79329482072698,
                4.452707380218344
              ],
              [
                -59.793698811396354,
                4.448199383369896
              ],
              [
                -59.79385184564934,
                4.446491391035299
              ],
              [
                -59.79109633841724,
                4.447153885064146
              ],
              [
                -59.78370087274215,
                4.448931887036023
              ],
              [
                -59.77829391253835,
                4.44601690538798
              ],
              [
                -59.77881592702671,
                4.442220944222487
              ],
              [
                -59.779063427784585,
                4.440419437533133
              ],
              [
                -59.77513944824879,
                4.438395935384343
              ],
              [
                -59.76844597423318,
                4.437542963117336
              ],
              [
                -59.76483001783678,
                4.437081462518515
              ],
              [
                -59.763117027836465,
                4.434241951924012
              ],
              [
                -59.76272303059621,
                4.433588969696681
              ],
              [
                -59.76022154794396,
                4.431187497858701
              ],
              [
                -59.75732355902767,
                4.426702023647179
              ],
              [
                -59.750673110806126,
                4.419843029586117
              ],
              [
                -59.74759211694982,
                4.419853042441928
              ],
              [
                -59.741784664377,
                4.421029040239431
              ],
              [
                -59.738423687724605,
                4.421710015752885
              ],
              [
                -59.73331172040828,
                4.417895558908259
              ],
              [
                -59.72893474272841,
                4.414130064261204
              ],
              [
                -59.725531753169214,
                4.410571587993045
              ],
              [
                -59.72287678925342,
                4.408428616468993
              ],
              [
                -59.719715821272736,
                4.404861627420908
              ],
              [
                -59.7196653038686,
                4.399923637954178
              ],
              [
                -59.71963281350964,
                4.399250173101048
              ],
              [
                -59.71949730015566,
                4.396481691146258
              ],
              [
                -59.715479836393875,
                4.392653191145191
              ],
              [
                -59.7047504073821,
                4.385565228824575
              ],
              [
                -59.6958654427805,
                4.379558274063977
              ],
              [
                -59.69316050070315,
                4.378103763259882
              ],
              [
                -59.69039501402397,
                4.379600785139727
              ],
              [
                -59.69009100970833,
                4.379765260917769
              ],
              [
                -59.68682851301318,
                4.381186240934251
              ],
              [
                -59.68398953934813,
                4.380307744474673
              ],
              [
                -59.68001455203702,
                4.376945280641071
              ],
              [
                -59.67506660301447,
                4.372759800258279
              ],
              [
                -59.67751908112101,
                4.368865828013927
              ],
              [
                -59.68169054857414,
                4.364228861512456
              ],
              [
                -59.675385600347305,
                4.346715462755974
              ],
              [
                -59.677871083505494,
                4.340905496374465
              ],
              [
                -59.68122557776553,
                4.339302003524593
              ],
              [
                -59.683135570327465,
                4.338037029620369
              ],
              [
                -59.684831568058705,
                4.336914522691784
              ],
              [
                -59.68968354606636,
                4.33442955128435
              ],
              [
                -59.697076000899656,
                4.324619617868493
              ],
              [
                -59.702093979078604,
                4.318860656802938
              ],
              [
                -59.70234245327372,
                4.314796673099716
              ],
              [
                -59.70244096374407,
                4.313189700102157
              ],
              [
                -59.702012479189115,
                4.310256211959783
              ],
              [
                -59.70248446191125,
                4.304889748453213
              ],
              [
                -59.70734093339997,
                4.304695253850946
              ],
              [
                -59.71037140377609,
                4.304320262162943
              ],
              [
                -59.715664392998185,
                4.3036647646969
              ],
              [
                -59.71735886372747,
                4.301031300087757
              ],
              [
                -59.719359355838634,
                4.296577811924918
              ],
              [
                -59.72049337092448,
                4.293860322461976
              ],
              [
                -59.72204137354881,
                4.291174348074247
              ],
              [
                -59.73097430823344,
                4.28451539006666
              ],
              [
                -59.72870233315546,
                4.282155426155627
              ],
              [
                -59.72650232422218,
                4.278918916707072
              ],
              [
                -59.727122851518345,
                4.271280474290216
              ],
              [
                -59.72313335943538,
                4.272199453009041
              ],
              [
                -59.72203337373202,
                4.272487465511682
              ],
              [
                -59.717591902157224,
                4.273651962417166
              ],
              [
                -59.71155093484859,
                4.271987447754967
              ],
              [
                -59.70733446003812,
                4.268390469226849
              ],
              [
                -59.72897036055386,
                4.243525167864018
              ],
              [
                -59.72960283629504,
                4.242797668764979
              ],
              [
                -59.73510231155342,
                4.235546195084494
              ],
              [
                -59.7327418525805,
                4.229129252155586
              ],
              [
                -59.73121534101174,
                4.225328784620618
              ],
              [
                -59.73127133990273,
                4.221861810049647
              ],
              [
                -59.73137883750787,
                4.213672860643684
              ],
              [
                -59.73102336007992,
                4.208227870006068
              ],
              [
                -59.73036234516974,
                4.2047874048046
              ],
              [
                -59.72905588456362,
                4.202127419373587
              ],
              [
                -59.727198882419614,
                4.199123927708357
              ],
              [
                -59.726591897503276,
                4.198327953228818
              ],
              [
                -59.724279887056085,
                4.195298444433416
              ],
              [
                -59.720873425955176,
                4.190857969641772
              ],
              [
                -59.722767429851174,
                4.186043020554872
              ],
              [
                -59.72404741037404,
                4.182787028455704
              ],
              [
                -59.721072418343994,
                4.177364054000246
              ],
              [
                -59.71942844532864,
                4.17531808456894
              ],
              [
                -59.71798296698709,
                4.173518603744686
              ],
              [
                -59.71457197541226,
                4.169850617191787
              ],
              [
                -59.71010549577562,
                4.165048122966793
              ],
              [
                -59.702584040036136,
                4.162011647909813
              ],
              [
                -59.69869159397958,
                4.16043967313153
              ],
              [
                -59.690831124112094,
                4.15444718910699
              ],
              [
                -59.689781153813854,
                4.153638716428454
              ],
              [
                -59.686250661349526,
                4.150919213967199
              ],
              [
                -59.68315219487415,
                4.148936220237629
              ],
              [
                -59.68010868504981,
                4.146993738294508
              ],
              [
                -59.66148382212546,
                4.146720718179216
              ],
              [
                -59.658890333313636,
                4.146911727357006
              ],
              [
                -59.65815884059757,
                4.14696570956037
              ],
              [
                -59.64680639100942,
                4.146344709204086
              ],
              [
                -59.63604897452959,
                4.143169746003424
              ],
              [
                -59.629739505629026,
                4.14025025104695
              ],
              [
                -59.6289240017527,
                4.139761769548985
              ],
              [
                -59.624482528658014,
                4.13710226917202
              ],
              [
                -59.61586858044912,
                4.132478779299467
              ],
              [
                -59.61710109679954,
                4.129867811209172
              ],
              [
                -59.61847509430853,
                4.127264335160107
              ],
              [
                -59.62114657870491,
                4.11896688409808
              ],
              [
                -59.62913054189788,
                4.111972449311423
              ],
              [
                -59.629787020378174,
                4.11139744527411
              ],
              [
                -59.630485518464916,
                4.109449462740307
              ],
              [
                -59.63154550804332,
                4.106492958659043
              ],
              [
                -59.634550521382266,
                4.099915527146561
              ],
              [
                -59.6371874843891,
                4.094145539410328
              ],
              [
                -59.640136994377784,
                4.088648097179509
              ],
              [
                -59.64203694838449,
                4.085052103554257
              ],
              [
                -59.64404144776642,
                4.081466132928993
              ],
              [
                -59.64528095130803,
                4.078456160270441
              ],
              [
                -59.648756433878376,
                4.071830711301826
              ],
              [
                -59.64214097542813,
                4.069413201099978
              ],
              [
                -59.63796652234502,
                4.06735772918563
              ],
              [
                -59.636246012195784,
                4.064612744050782
              ],
              [
                -59.63322904217068,
                4.061106769806987
              ],
              [
                -59.63025654378736,
                4.057755263639805
              ],
              [
                -59.626408563651644,
                4.054278795703491
              ],
              [
                -59.622607115143495,
                4.054220799707785
              ],
              [
                -59.621958592980924,
                4.054210777368098
              ],
              [
                -59.61495266148059,
                4.054036294107703
              ],
              [
                -59.61677364499552,
                4.050329327109218
              ],
              [
                -59.61528815295271,
                4.045140350208547
              ],
              [
                -59.61525216328171,
                4.044950355358592
              ],
              [
                -59.61430616992634,
                4.039958871571853
              ],
              [
                -59.61056569243134,
                4.037665393415787
              ],
              [
                -59.604952238707675,
                4.034224406188832
              ],
              [
                -59.60270624022282,
                4.032192439344451
              ],
              [
                -59.59718479064839,
                4.027620441161008
              ],
              [
                -59.596686281302425,
                4.021534988407122
              ],
              [
                -59.59621779015184,
                4.018116506213204
              ],
              [
                -59.59806375662731,
                4.017479494275927
              ],
              [
                -59.60059824017871,
                4.016604025231602
              ],
              [
                -59.6066917188585,
                4.020395483579719
              ],
              [
                -59.60812821991927,
                4.016203529319646
              ],
              [
                -59.60523321056208,
                4.013679536113958
              ],
              [
                -59.60205824909798,
                4.010763038159762
              ],
              [
                -59.59923228051715,
                4.009583545705718
              ],
              [
                -59.59612226978801,
                4.013063052334741
              ],
              [
                -59.59049481122934,
                4.019358488038256
              ],
              [
                -59.58862833873877,
                4.016934518852508
              ],
              [
                -59.58725583847659,
                4.012618031649438
              ],
              [
                -59.58886584337384,
                4.008637047200961
              ],
              [
                -59.59169231609082,
                4.002983608810815
              ],
              [
                -59.59315882519742,
                4.000050629800776
              ],
              [
                -59.58927733862547,
                3.995950627724933
              ],
              [
                -59.588202344529016,
                3.995044642658476
              ],
              [
                -59.58697534140908,
                3.994010153292324
              ],
              [
                -59.58484037078107,
                3.996080143219511
              ],
              [
                -59.583181876164474,
                3.998433128689503
              ],
              [
                -59.58090989340418,
                4.000408127115691
              ],
              [
                -59.57732489112959,
                4.00449557670068
              ],
              [
                -59.57426993870993,
                4.001999081242264
              ],
              [
                -59.57830239106282,
                3.996708628353412
              ],
              [
                -59.57580441004587,
                3.993053168387773
              ],
              [
                -59.57268343384502,
                3.987521189624025
              ],
              [
                -59.57523892377988,
                3.985781680912586
              ],
              [
                -59.57983541388999,
                3.981977236179715
              ],
              [
                -59.58619587344997,
                3.976713251513393
              ],
              [
                -59.58508037235253,
                3.971214309751224
              ],
              [
                -59.58427488914672,
                3.967832329120311
              ],
              [
                -59.57444342041668,
                3.969279304240378
              ],
              [
                -59.57038446029447,
                3.969494809451962
              ],
              [
                -59.564772482755096,
                3.96738980104033
              ],
              [
                -59.56268500548427,
                3.971752780854684
              ],
              [
                -59.56075202208069,
                3.974813245796504
              ],
              [
                -59.56074951209311,
                3.9748172577702
              ],
              [
                -59.55805752641184,
                3.977491736630832
              ],
              [
                -59.547837587871776,
                3.979183222985692
              ],
              [
                -59.54508362479076,
                3.978154217666889
              ],
              [
                -59.54392260682385,
                3.972897277418399
              ],
              [
                -59.53683818718801,
                3.970520274801622
              ],
              [
                -59.52564225349145,
                3.967165293741028
              ],
              [
                -59.523443261801496,
                3.961680816282245
              ],
              [
                -59.52135425958106,
                3.957658838858876
              ],
              [
                -59.519774290915805,
                3.954352867321658
              ],
              [
                -59.5223267643731,
                3.951719872119888
              ],
              [
                -59.517455299137424,
                3.943488450014079
              ],
              [
                -59.522283286188326,
                3.940729948948723
              ],
              [
                -59.52609923668293,
                3.938897466554491
              ],
              [
                -59.524271282117724,
                3.932830990904192
              ],
              [
                -59.52612174127836,
                3.92758405439886
              ],
              [
                -59.5278692609039,
                3.924260555956442
              ],
              [
                -59.534477695278696,
                3.9267865533262
              ],
              [
                -59.535965696184945,
                3.928949537871244
              ],
              [
                -59.538441690387806,
                3.932549522142497
              ],
              [
                -59.543247658135066,
                3.930540535420585
              ],
              [
                -59.54209316548007,
                3.926313072809515
              ],
              [
                -59.54122064854085,
                3.9231180899974
              ],
              [
                -59.54769065022947,
                3.919777100477019
              ],
              [
                -59.55262608513205,
                3.919593617560437
              ],
              [
                -59.55734157413725,
                3.919751602406533
              ],
              [
                -59.56427252399564,
                3.919984117118887
              ],
              [
                -59.5651985429994,
                3.915797132485142
              ],
              [
                -59.565270031087984,
                3.91493363685096
              ],
              [
                -59.565679034577904,
                3.910018169395565
              ],
              [
                -59.56779603576329,
                3.896965751101334
              ],
              [
                -59.56876701613721,
                3.891674788281449
              ],
              [
                -59.57578146457674,
                3.898592262829474
              ],
              [
                -59.5768714494972,
                3.899666724858097
              ],
              [
                -59.57666397516736,
                3.897219780810643
              ],
              [
                -59.576111958279775,
                3.890687814724416
              ],
              [
                -59.58062645253393,
                3.890774301872641
              ],
              [
                -59.5818219434518,
                3.890797312440905
              ],
              [
                -59.586679912597766,
                3.889323330404045
              ],
              [
                -59.590451886444455,
                3.889076832161535
              ],
              [
                -59.59393586766288,
                3.884294845674333
              ],
              [
                -59.592875385583326,
                3.872960422827989
              ],
              [
                -59.59281437934243,
                3.868865439000327
              ],
              [
                -59.59239189483635,
                3.863742995282053
              ],
              [
                -59.5910624025439,
                3.862290479438646
              ],
              [
                -59.58813092574265,
                3.859088993417261
              ],
              [
                -59.5842749604359,
                3.857551033613319
              ],
              [
                -59.58866040607007,
                3.853163046203693
              ],
              [
                -59.58947741171736,
                3.852345039718728
              ],
              [
                -59.5863484611398,
                3.84044363312486
              ],
              [
                -59.585622442401935,
                3.835328153178116
              ],
              [
                -59.583756962894135,
                3.834528664005725
              ],
              [
                -59.58228695195005,
                3.833899184018962
              ],
              [
                -59.578165014422915,
                3.83199867430303
              ],
              [
                -59.57906298502575,
                3.816593272622076
              ],
              [
                -59.58413345057705,
                3.822600742617898
              ],
              [
                -59.59030941365651,
                3.818123767760462
              ],
              [
                -59.59270240451438,
                3.816388786826279
              ],
              [
                -59.59746539615843,
                3.815013780057213
              ],
              [
                -59.59638040657654,
                3.811536827806876
              ],
              [
                -59.59578540021979,
                3.809582344479403
              ],
              [
                -59.59460242145133,
                3.805693845702597
              ],
              [
                -59.59508092376999,
                3.801963853915415
              ],
              [
                -59.59522341959445,
                3.799065390819059
              ],
              [
                -59.59538042632586,
                3.796177399665739
              ],
              [
                -59.600629378221484,
                3.796326417791966
              ],
              [
                -59.607669836004725,
                3.800880405882742
              ],
              [
                -59.61187380460369,
                3.803600360605721
              ],
              [
                -59.614597277922364,
                3.797676902589995
              ],
              [
                -59.615881784939425,
                3.794883920497313
              ],
              [
                -59.61977376864785,
                3.791928940908058
              ],
              [
                -59.62554424892599,
                3.787819476493027
              ],
              [
                -59.62555172088451,
                3.787814960525043
              ],
              [
                -59.626805725991204,
                3.786921973735467
              ],
              [
                -59.62949818819135,
                3.785004495069307
              ],
              [
                -59.632844184473356,
                3.788427492982176
              ],
              [
                -59.631428176978154,
                3.789919482999399
              ],
              [
                -59.62732870870581,
                3.794237954099565
              ],
              [
                -59.62704371984637,
                3.798662405754357
              ],
              [
                -59.6328576916262,
                3.796732425982211
              ],
              [
                -59.63578367918456,
                3.791798972978298
              ],
              [
                -59.637101645055246,
                3.788920469418494
              ],
              [
                -59.638728164773696,
                3.786617485501275
              ],
              [
                -59.6398031332999,
                3.785095982465261
              ],
              [
                -59.64135362195599,
                3.785430021310788
              ],
              [
                -59.64720358170471,
                3.786690502955559
              ],
              [
                -59.65358853835043,
                3.78612099014498
              ],
              [
                -59.65660504838171,
                3.784671009789121
              ],
              [
                -59.662188514052076,
                3.781854529300969
              ],
              [
                -59.66455550101554,
                3.780234541608731
              ],
              [
                -59.66897798620715,
                3.772750581075261
              ],
              [
                -59.67058998683378,
                3.769011621938983
              ],
              [
                -59.6721999434651,
                3.76528266173695
              ],
              [
                -59.67401046537986,
                3.762487681919431
              ],
              [
                -59.678771418382226,
                3.75883619704635
              ],
              [
                -59.672299476138726,
                3.752526213022167
              ],
              [
                -59.67383345638661,
                3.746722765484158
              ],
              [
                -59.66899898326501,
                3.742552270287455
              ],
              [
                -59.6670495136648,
                3.739196299749348
              ],
              [
                -59.66375402831382,
                3.733522852652818
              ],
              [
                -59.66679300022972,
                3.732032346524195
              ],
              [
                -59.66752149024002,
                3.731488353274562
              ],
              [
                -59.670901502728256,
                3.728966374660908
              ],
              [
                -59.66953949541985,
                3.724254917763917
              ],
              [
                -59.669139020562156,
                3.722870923018939
              ],
              [
                -59.666021041441695,
                3.720229422436472
              ],
              [
                -59.66286954478886,
                3.715331458667922
              ],
              [
                -59.66464852802749,
                3.712249479567298
              ],
              [
                -59.66685803205039,
                3.706808016167196
              ],
              [
                -59.66785002607059,
                3.703472038170737
              ],
              [
                -59.67727198035996,
                3.698375569722933
              ],
              [
                -59.68056592395912,
                3.696550073765722
              ],
              [
                -59.68715642128018,
                3.698019571490159
              ],
              [
                -59.68985338114964,
                3.694860608358085
              ],
              [
                -59.693421383561336,
                3.688701144083721
              ],
              [
                -59.69779483061048,
                3.689012657642795
              ],
              [
                -59.70401431071066,
                3.68945565443884
              ],
              [
                -59.70668628123117,
                3.685400176765047
              ],
              [
                -59.70916477823817,
                3.680720693795656
              ],
              [
                -59.709298267592494,
                3.680468186415813
              ],
              [
                -59.710662787021306,
                3.679628219323012
              ],
              [
                -59.71895823699912,
                3.674518756244454
              ],
              [
                -59.719068239919395,
                3.673497743551426
              ],
              [
                -59.719828710361554,
                3.666443805121301
              ],
              [
                -59.72454919927619,
                3.663350820681027
              ],
              [
                -59.729728177325306,
                3.66236332861967
              ],
              [
                -59.73603862086499,
                3.661108339825515
              ],
              [
                -59.74312260394254,
                3.659699339403303
              ],
              [
                -59.752297529049336,
                3.652282904851608
              ],
              [
                -59.75425351181361,
                3.648169929069926
              ],
              [
                -59.756191009096334,
                3.643311466827158
              ],
              [
                -59.75715752474599,
                3.640888988161275
              ],
              [
                -59.758284990473804,
                3.636605489948834
              ],
              [
                -59.760583479216514,
                3.632490011357864
              ],
              [
                -59.7636889669202,
                3.63253604380257
              ],
              [
                -59.767852963904694,
                3.631662526449403
              ],
              [
                -59.76569947238383,
                3.626552053452108
              ],
              [
                -59.7706454535521,
                3.626073566116988
              ],
              [
                -59.77399591594741,
                3.625887562111039
              ],
              [
                -59.779695887895656,
                3.623210607609079
              ],
              [
                -59.782159850427774,
                3.62135011132742
              ],
              [
                -59.786146341143926,
                3.619242132397142
              ],
              [
                -59.79038280546554,
                3.61700065041672
              ],
              [
                -59.79808977790516,
                3.619289109725893
              ],
              [
                -59.80755322925366,
                3.617527130711817
              ],
              [
                -59.809019190376965,
                3.61800512660914
              ],
              [
                -59.81397269138376,
                3.619622134494373
              ],
              [
                -59.81724666602383,
                3.619849150924535
              ],
              [
                -59.81628266275649,
                3.616077643381782
              ],
              [
                -59.816232179161375,
                3.615880174557596
              ],
              [
                -59.81699166059972,
                3.612412689114312
              ],
              [
                -59.82037062404226,
                3.613703184117427
              ],
              [
                -59.82690209013755,
                3.611036700980151
              ],
              [
                -59.83816405151636,
                3.606440731204491
              ],
              [
                -59.85001345914657,
                3.604254758060248
              ],
              [
                -59.849975489134295,
                3.59919428185394
              ],
              [
                -59.84988097011574,
                3.5965802837182
              ],
              [
                -59.849863473482976,
                3.596095292661575
              ],
              [
                -59.8549524613186,
                3.587459875032407
              ],
              [
                -59.857801424371104,
                3.582361876625976
              ],
              [
                -59.85839994404535,
                3.582018396044793
              ],
              [
                -59.86265040942561,
                3.57958390519115
              ],
              [
                -59.86522939055053,
                3.577267919696937
              ],
              [
                -59.86202990511253,
                3.575814448787999
              ],
              [
                -59.859359410441066,
                3.57460143770675
              ],
              [
                -59.85857692617878,
                3.570641963371756
              ],
              [
                -59.86035340518081,
                3.569853981137736
              ],
              [
                -59.86196241812614,
                3.569139462364763
              ],
              [
                -59.86670640100131,
                3.565979492181069
              ],
              [
                -59.869973853411686,
                3.563058013735483
              ],
              [
                -59.85763695873373,
                3.557623063068198
              ],
              [
                -59.84998350678872,
                3.555515564721575
              ],
              [
                -59.849148983711345,
                3.555470541541136
              ],
              [
                -59.84242605137283,
                3.555106048098826
              ],
              [
                -59.841201047541524,
                3.551437566757159
              ],
              [
                -59.841524556602096,
                3.545938089398309
              ],
              [
                -59.84158754279688,
                3.544888105821455
              ],
              [
                -59.84181205249575,
                3.541134627781495
              ],
              [
                -59.836746104496555,
                3.531329685693865
              ],
              [
                -59.83459460527355,
                3.527323226906749
              ],
              [
                -59.83174760661752,
                3.526151730496995
              ],
              [
                -59.828862122138986,
                3.525072721504595
              ],
              [
                -59.82740912791518,
                3.524617721488987
              ],
              [
                -59.82302218541365,
                3.523245259473729
              ],
              [
                -59.817762702221955,
                3.521532250062394
              ],
              [
                -59.80628379308947,
                3.511350807851444
              ],
              [
                -59.80314531255431,
                3.507548816307419
              ],
              [
                -59.80216479850603,
                3.503427846864379
              ],
              [
                -59.80206680330118,
                3.503014861325494
              ],
              [
                -59.80175729880962,
                3.501715865065922
              ],
              [
                -59.80165329657411,
                3.49954239523864
              ],
              [
                -59.801307824428804,
                3.492324934978507
              ],
              [
                -59.81002228302372,
                3.488922471337743
              ],
              [
                -59.81536074076313,
                3.486838462865901
              ],
              [
                -59.82137318127343,
                3.48338050610583
              ],
              [
                -59.82108120740334,
                3.478804518778453
              ],
              [
                -59.816798734331535,
                3.479233513888595
              ],
              [
                -59.81568575404982,
                3.478891499375923
              ],
              [
                -59.81187076007964,
                3.477719023475751
              ],
              [
                -59.81054025788084,
                3.470152564788612
              ],
              [
                -59.81447523653394,
                3.470589558221459
              ],
              [
                -59.81943371149248,
                3.470428089837854
              ],
              [
                -59.82096021671691,
                3.470066087849983
              ],
              [
                -59.82344917640921,
                3.469475565762542
              ],
              [
                -59.826270194094626,
                3.461381111467177
              ],
              [
                -59.82819668467963,
                3.455854160080957
              ],
              [
                -59.832176149739354,
                3.451219187352769
              ],
              [
                -59.83327915194364,
                3.449918200841015
              ],
              [
                -59.839501624249046,
                3.442574761739611
              ],
              [
                -59.83986409688542,
                3.439193291471075
              ],
              [
                -59.84111311916411,
                3.426492834263718
              ],
              [
                -59.83338865526953,
                3.423108361767078
              ],
              [
                -59.82808917721728,
                3.425360351309739
              ],
              [
                -59.82700919331089,
                3.425838336839646
              ],
              [
                -59.821187748158444,
                3.428417819113656
              ],
              [
                -59.81519777449381,
                3.426199354450782
              ],
              [
                -59.817007268125316,
                3.417986375301718
              ],
              [
                -59.818593747178085,
                3.415338928215591
              ],
              [
                -59.82136672133133,
                3.410623463309076
              ],
              [
                -59.822014743095195,
                3.409521458341024
              ],
              [
                -59.8205297406154,
                3.404101995482957
              ],
              [
                -59.81895474582945,
                3.398353505575799
              ],
              [
                -59.81629075080131,
                3.399664015230128
              ],
              [
                -59.8102343313608,
                3.380234146089666
              ],
              [
                -59.80886280667042,
                3.375834669096711
              ],
              [
                -59.80582885003399,
                3.364990227380567
              ],
              [
                -59.80623134485073,
                3.354814284368653
              ],
              [
                -59.81112031225391,
                3.352602790302951
              ],
              [
                -59.81930028378755,
                3.355396792545626
              ],
              [
                -59.82379425861529,
                3.35693130054264
              ],
              [
                -59.829175210915594,
                3.355168311005104
              ],
              [
                -59.8328316965926,
                3.352916313545603
              ],
              [
                -59.83202518393406,
                3.349754325313509
              ],
              [
                -59.8288217257252,
                3.339926893286121
              ],
              [
                -59.828505235105126,
                3.338896382375086
              ],
              [
                -59.82708974874836,
                3.33428541862006
              ],
              [
                -59.832782705074685,
                3.326855477583208
              ],
              [
                -59.83634369430048,
                3.322628001028766
              ],
              [
                -59.8394611826975,
                3.3192580400443
              ],
              [
                -59.841390659421364,
                3.317172538666606
              ],
              [
                -59.841464676743875,
                3.31566704682962
              ],
              [
                -59.84161617540685,
                3.312577577632301
              ],
              [
                -59.84184665803375,
                3.308633094655762
              ],
              [
                -59.84844261418988,
                3.304910112556941
              ],
              [
                -59.849996618510154,
                3.304033117657159
              ],
              [
                -59.85359858954345,
                3.301699151619538
              ],
              [
                -59.85411658087205,
                3.296965685555556
              ],
              [
                -59.85425810668964,
                3.295669166480534
              ],
              [
                -59.85976358677324,
                3.278848779117689
              ],
              [
                -59.86639204095039,
                3.278730285164288
              ],
              [
                -59.867236510064195,
                3.278595791402616
              ],
              [
                -59.873075492282744,
                3.277664301973361
              ],
              [
                -59.87450549049635,
                3.272430318368148
              ],
              [
                -59.881094453488465,
                3.259049432628097
              ],
              [
                -59.879952958587594,
                3.254176956533645
              ],
              [
                -59.87849496709596,
                3.246143003403722
              ],
              [
                -59.887482918329916,
                3.242226024560015
              ],
              [
                -59.88486894612661,
                3.239228547387169
              ],
              [
                -59.881149457548325,
                3.235937554442537
              ],
              [
                -59.87714899173992,
                3.232397582867196
              ],
              [
                -59.87375198709309,
                3.229470603886399
              ],
              [
                -59.874892507127036,
                3.223907649090433
              ],
              [
                -59.883075466456305,
                3.219185150681418
              ],
              [
                -59.88807240309156,
                3.21728416963622
              ],
              [
                -59.894956376993534,
                3.216771202129992
              ],
              [
                -59.89741836765485,
                3.216587192455527
              ],
              [
                -59.90321731564071,
                3.216655188903354
              ],
              [
                -59.90768128746304,
                3.210548223994633
              ],
              [
                -59.90840628808112,
                3.20671525950293
              ],
              [
                -59.91083179215092,
                3.193780355807425
              ],
              [
                -59.91145479191159,
                3.188801374174786
              ],
              [
                -59.90822630683348,
                3.178429413218332
              ],
              [
                -59.9082153379149,
                3.17839392142726
              ],
              [
                -59.90288935393771,
                3.164217511417456
              ],
              [
                -59.899730881040824,
                3.154625058789555
              ],
              [
                -59.90342787563753,
                3.149901093086453
              ],
              [
                -59.90971981852172,
                3.148340104542822
              ],
              [
                -59.912556815239014,
                3.147636622156158
              ],
              [
                -59.920370750985754,
                3.148656627773601
              ],
              [
                -59.91635177883274,
                3.145914658374978
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 23,
        "nome": "Rio Grande do Sul",
        "sigla": "RS",
        "regiao_id": 1,
        "codigo_ibg": 43
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -52.04366899795454,
                -31.57373961190372
              ],
              [
                -52.0362665918479,
                -31.56907808668701
              ],
              [
                -52.0375825774079,
                -31.564927095076346
              ],
              [
                -52.03931708793906,
                -31.559459133671805
              ],
              [
                -52.04499152351697,
                -31.562468658114703
              ],
              [
                -52.045446024955645,
                -31.559238637519584
              ],
              [
                -52.05955796790772,
                -31.555936704419302
              ],
              [
                -52.08778674688591,
                -31.549332327250955
              ],
              [
                -52.10210661024006,
                -31.55207638507957
              ],
              [
                -52.109927564802355,
                -31.553575412395354
              ],
              [
                -52.1103490098138,
                -31.560245917562842
              ],
              [
                -52.10456151477245,
                -31.56387138213046
              ],
              [
                -52.111886981674424,
                -31.56514039102182
              ],
              [
                -52.114794916946515,
                -31.565660891654247
              ],
              [
                -52.11520140237638,
                -31.57067637313163
              ],
              [
                -52.115251897321826,
                -31.57132289963875
              ],
              [
                -52.11557189674654,
                -31.57539838223674
              ],
              [
                -52.11609933754176,
                -31.582122855727533
              ],
              [
                -52.11186578676039,
                -31.604724773835272
              ],
              [
                -52.10854477925054,
                -31.609280277146432
              ],
              [
                -52.105903289428774,
                -31.61290474171121
              ],
              [
                -52.1042482621661,
                -31.615175721907452
              ],
              [
                -52.10315377866084,
                -31.616676239443635
              ],
              [
                -52.1030422647106,
                -31.61682924158097
              ],
              [
                -52.100778760726286,
                -31.619933703073844
              ],
              [
                -52.10220825212629,
                -31.62540567561692
              ],
              [
                -52.09444179400703,
                -31.629786670011793
              ],
              [
                -52.0742829920917,
                -31.62395659901215
              ],
              [
                -52.068638548860605,
                -31.61925809204492
              ],
              [
                -52.06584112625602,
                -31.6122605838962
              ],
              [
                -52.06521163829654,
                -31.610685582440684
              ],
              [
                -52.06459963893617,
                -31.609156111938166
              ],
              [
                -52.060528739767555,
                -31.599138090504198
              ],
              [
                -52.05692327103719,
                -31.598477603360664
              ],
              [
                -52.05681870673815,
                -31.606135057762373
              ],
              [
                -52.05677768228083,
                -31.60911406574989
              ],
              [
                -52.056774198846426,
                -31.609373558052372
              ],
              [
                -52.056670147793255,
                -31.61698255159607
              ],
              [
                -52.056623618898726,
                -31.620400024412
              ],
              [
                -52.05655411099837,
                -31.625446503850036
              ],
              [
                -52.06041810569052,
                -31.6236090166446
              ],
              [
                -52.05930405008873,
                -31.631358993663834
              ],
              [
                -52.05399058140359,
                -31.637071996690604
              ],
              [
                -52.061006464599465,
                -31.642966000196342
              ],
              [
                -52.06426545944677,
                -31.64569847849033
              ],
              [
                -52.06670542688764,
                -31.647744007205688
              ],
              [
                -52.06617188392248,
                -31.650812991942523
              ],
              [
                -52.06910434773815,
                -31.652254009284704
              ],
              [
                -52.07040984441024,
                -31.65289550799921
              ],
              [
                -52.07304184592426,
                -31.654117999674916
              ],
              [
                -52.07692229392574,
                -31.655920507993645
              ],
              [
                -52.08145421801063,
                -31.665782996878768
              ],
              [
                -52.08027418885891,
                -31.666282501687448
              ],
              [
                -52.06996777469264,
                -31.67064345861654
              ],
              [
                -52.06855576626872,
                -31.67612391440797
              ],
              [
                -52.07635265514688,
                -31.681458464694703
              ],
              [
                -52.10359241066171,
                -31.684140561439143
              ],
              [
                -52.13376613531793,
                -31.69316264784816
              ],
              [
                -52.14150702578925,
                -31.695477664884475
              ],
              [
                -52.149350963248835,
                -31.697823156277323
              ],
              [
                -52.15648287375751,
                -31.702070196769537
              ],
              [
                -52.1734851810928,
                -31.712194738908096
              ],
              [
                -52.193727953930086,
                -31.724248764555984
              ],
              [
                -52.20027990913787,
                -31.728149785620257
              ],
              [
                -52.202958356163606,
                -31.730622277319576
              ],
              [
                -52.22302909247843,
                -31.749335807143943
              ],
              [
                -52.225173071178865,
                -31.75140134070131
              ],
              [
                -52.22594347128923,
                -31.763796798792992
              ],
              [
                -52.2261554749832,
                -31.767407795324687
              ],
              [
                -52.225305972177864,
                -31.769120299358573
              ],
              [
                -52.222552968073465,
                -31.774671763539235
              ],
              [
                -52.221215473643326,
                -31.777369752993565
              ],
              [
                -52.21843596243798,
                -31.782975723925215
              ],
              [
                -52.22263939838677,
                -31.788525204056068
              ],
              [
                -52.21496536953539,
                -31.798811672525297
              ],
              [
                -52.2128868799917,
                -31.809249106895237
              ],
              [
                -52.21654682218074,
                -31.81062264858084
              ],
              [
                -52.21873184388225,
                -31.804036656693874
              ],
              [
                -52.22284676457535,
                -31.81272816736922
              ],
              [
                -52.22292276543579,
                -31.81264363390396
              ],
              [
                -52.228851740668205,
                -31.806027693674512
              ],
              [
                -52.23105769624682,
                -31.809244184551915
              ],
              [
                -52.22470221507706,
                -31.816284155912502
              ],
              [
                -52.2221762077767,
                -31.819082642169196
              ],
              [
                -52.22245459066136,
                -31.842850562010643
              ],
              [
                -52.22605004882561,
                -31.847814058200655
              ],
              [
                -52.23213953912263,
                -31.840441597577886
              ],
              [
                -52.23591653114374,
                -31.839226634448845
              ],
              [
                -52.23990546482121,
                -31.837943663378308
              ],
              [
                -52.25299688415085,
                -31.84222718691173
              ],
              [
                -52.256169305622386,
                -31.848616673692238
              ],
              [
                -52.25485878492438,
                -31.854281676651766
              ],
              [
                -52.25251126729325,
                -31.863337125569977
              ],
              [
                -52.25076720603346,
                -31.870065598995204
              ],
              [
                -52.24951023853589,
                -31.874916085283406
              ],
              [
                -52.233931228829285,
                -31.894160981822008
              ],
              [
                -52.22839478637597,
                -31.895000930557778
              ],
              [
                -52.22827884596536,
                -31.884422497263987
              ],
              [
                -52.228271354098595,
                -31.883711975059615
              ],
              [
                -52.23321479744116,
                -31.881296518894246
              ],
              [
                -52.237502274324754,
                -31.87920154116796
              ],
              [
                -52.23455932708163,
                -31.876295519362493
              ],
              [
                -52.23161438185494,
                -31.87338903055379
              ],
              [
                -52.2208649779453,
                -31.871987483328706
              ],
              [
                -52.20957100858771,
                -31.882970404961156
              ],
              [
                -52.20518153110541,
                -31.88723989766608
              ],
              [
                -52.20154454465312,
                -31.890776846394367
              ],
              [
                -52.20147754682983,
                -31.89084185394761
              ],
              [
                -52.195950063344775,
                -31.896216832060258
              ],
              [
                -52.192744563819794,
                -31.89757933018299
              ],
              [
                -52.16020126048204,
                -31.911413651830618
              ],
              [
                -52.13246793056061,
                -31.923203511797944
              ],
              [
                -52.127200924589324,
                -31.931439476613964
              ],
              [
                -52.12639341698384,
                -31.932701472995227
              ],
              [
                -52.120343954247964,
                -31.936960449791872
              ],
              [
                -52.11237597653665,
                -31.94256936310756
              ],
              [
                -52.11246939269926,
                -31.960874846370885
              ],
              [
                -52.112555303761965,
                -31.977625801649584
              ],
              [
                -52.10904533017745,
                -31.981355765405787
              ],
              [
                -52.10665383022664,
                -31.983897759885814
              ],
              [
                -52.10393985595842,
                -31.986782249138876
              ],
              [
                -52.10351732684857,
                -31.987231718219167
              ],
              [
                -52.095588852595135,
                -31.995658194452613
              ],
              [
                -52.09440435482709,
                -32.00061517676853
              ],
              [
                -52.09276781586281,
                -32.007468128328796
              ],
              [
                -52.09189528601642,
                -32.01112162530101
              ],
              [
                -52.091158273321405,
                -32.0142071062991
              ],
              [
                -52.089256791141004,
                -32.022167102920136
              ],
              [
                -52.09341675073632,
                -32.01785610436912
              ],
              [
                -52.09511070534548,
                -32.02019310845058
              ],
              [
                -52.09753569612821,
                -32.023539110001
              ],
              [
                -52.10840161699329,
                -32.018982185108165
              ],
              [
                -52.10827410923151,
                -32.0269356321811
              ],
              [
                -52.09605069879973,
                -32.02918960130009
              ],
              [
                -52.08369928830541,
                -32.029385057170025
              ],
              [
                -52.06788289904775,
                -32.029634493431644
              ],
              [
                -52.06678992355438,
                -32.03336646428201
              ],
              [
                -52.06437939197697,
                -32.04159943007404
              ],
              [
                -52.06312288937738,
                -32.04610241126641
              ],
              [
                -52.065707303035786,
                -32.05027743535975
              ],
              [
                -52.070172304070645,
                -32.0470159263927
              ],
              [
                -52.071315754516505,
                -32.05155094354323
              ],
              [
                -52.07593168756195,
                -32.05362394853068
              ],
              [
                -52.08610261248448,
                -32.05819299736292
              ],
              [
                -52.085135579021696,
                -32.06485346026005
              ],
              [
                -52.08489508842904,
                -32.06651247100606
              ],
              [
                -52.088968011075394,
                -32.07150696026433
              ],
              [
                -52.09126046810373,
                -32.07429597772201
              ],
              [
                -52.0931794358316,
                -32.07662993878327
              ],
              [
                -52.09817786857283,
                -32.082709979160505
              ],
              [
                -52.10116227219564,
                -32.09409994755287
              ],
              [
                -52.10313922626382,
                -32.10165194254909
              ],
              [
                -52.104518215461844,
                -32.106917400886864
              ],
              [
                -52.103826680848066,
                -32.11284839385598
              ],
              [
                -52.1032796422251,
                -32.11753688160404
              ],
              [
                -52.1029316238166,
                -32.12052189453274
              ],
              [
                -52.101972611094816,
                -32.12874835753934
              ],
              [
                -52.1006695711619,
                -32.13991434162293
              ],
              [
                -52.10005404765115,
                -32.14519278859707
              ],
              [
                -52.09942849371974,
                -32.150556798314874
              ],
              [
                -52.09853998087955,
                -32.1581747861965
              ],
              [
                -52.09815146075473,
                -32.161507780085046
              ],
              [
                -52.103296398359866,
                -32.16271628259906
              ],
              [
                -52.11496327597876,
                -32.1663843202371
              ],
              [
                -52.11911276455949,
                -32.16797732560703
              ],
              [
                -52.12326218914442,
                -32.169569844981005
              ],
              [
                -52.141765019262486,
                -32.18119937916698
              ],
              [
                -52.149242916379826,
                -32.18590138966262
              ],
              [
                -52.15005387274289,
                -32.186410906778825
              ],
              [
                -52.15591284610611,
                -32.1900938940683
              ],
              [
                -52.18528042181318,
                -32.2159774670899
              ],
              [
                -52.21202559539425,
                -32.23954799622497
              ],
              [
                -52.21957146952144,
                -32.2461985222656
              ],
              [
                -52.22097047281838,
                -32.24743151305661
              ],
              [
                -52.22097649973959,
                -32.24743651506938
              ],
              [
                -52.23131384052259,
                -32.25654802471358
              ],
              [
                -52.261920900218435,
                -32.29462356026587
              ],
              [
                -52.30639015625802,
                -32.36100704843515
              ],
              [
                -52.341631492534425,
                -32.426058000229624
              ],
              [
                -52.37050091573327,
                -32.48908595116474
              ],
              [
                -52.37254738723766,
                -32.49443497169185
              ],
              [
                -52.37459384473855,
                -32.499782964228565
              ],
              [
                -52.4155238976135,
                -32.60675783842576
              ],
              [
                -52.42135027545103,
                -32.62742931589197
              ],
              [
                -52.42332370984256,
                -32.634531801019136
              ],
              [
                -52.42529912520402,
                -32.64163377116719
              ],
              [
                -52.44218112574068,
                -32.71161767046748
              ],
              [
                -52.447892938695695,
                -32.73514016902168
              ],
              [
                -52.44999487100303,
                -32.741871148762485
              ],
              [
                -52.45209732228456,
                -32.7486031575134
              ],
              [
                -52.46746942082402,
                -32.79782157683416
              ],
              [
                -52.51147499914094,
                -32.90270349889313
              ],
              [
                -52.517738869292685,
                -32.913309987605444
              ],
              [
                -52.52400374039954,
                -32.92391597335813
              ],
              [
                -52.540867446279194,
                -32.952458973263894
              ],
              [
                -52.54746886002117,
                -32.96363199325393
              ],
              [
                -52.54892382107276,
                -32.96609398369909
              ],
              [
                -52.55261525868174,
                -32.97234197683317
              ],
              [
                -52.56094361459515,
                -32.989024475316704
              ],
              [
                -52.56364503739931,
                -32.99443645119119
              ],
              [
                -52.566346985188446,
                -32.99984848507607
              ],
              [
                -52.57934175250404,
                -33.02587845382564
              ],
              [
                -52.59522293847824,
                -33.057690429668426
              ],
              [
                -52.59847636885213,
                -33.06420692944361
              ],
              [
                -52.59847835781739,
                -33.064209931445134
              ],
              [
                -52.60172928221817,
                -33.070722945229626
              ],
              [
                -52.60851465609724,
                -33.082039458075116
              ],
              [
                -52.6106091394733,
                -33.08553143296425
              ],
              [
                -52.61460505175254,
                -33.09219693566316
              ],
              [
                -52.61667354359011,
                -33.09564645555018
              ],
              [
                -52.62262592374841,
                -33.1055729581234
              ],
              [
                -52.628669813176785,
                -33.11565294276566
              ],
              [
                -52.632783744114576,
                -33.12122544059505
              ],
              [
                -52.63279225798003,
                -33.121235943607466
              ],
              [
                -52.63689868503313,
                -33.126797928439885
              ],
              [
                -52.65365142073919,
                -33.149485954275654
              ],
              [
                -52.66182278742496,
                -33.160551455019615
              ],
              [
                -52.66732071059402,
                -33.166982453960024
              ],
              [
                -52.67994451476092,
                -33.18174845655089
              ],
              [
                -52.682054994178245,
                -33.18421750600073
              ],
              [
                -52.6898323930934,
                -33.1933155067366
              ],
              [
                -52.698665261684724,
                -33.203646981243416
              ],
              [
                -52.71957992153748,
                -33.228111529758515
              ],
              [
                -52.73810415616004,
                -33.249778539530055
              ],
              [
                -52.746136055568385,
                -33.257131077635066
              ],
              [
                -52.75416792696027,
                -33.26448356276619
              ],
              [
                -52.789486962974244,
                -33.29681563280312
              ],
              [
                -52.79232992443873,
                -33.29941814426756
              ],
              [
                -52.79233489137299,
                -33.29942215128007
              ],
              [
                -52.7951738808897,
                -33.30202117673838
              ],
              [
                -52.81601211043155,
                -33.318454677286816
              ],
              [
                -52.88497373692201,
                -33.37283836256056
              ],
              [
                -53.04314518889488,
                -33.497573187598576
              ],
              [
                -53.04919409020671,
                -33.50267021091112
              ],
              [
                -53.05524203452502,
                -33.5077661982352
              ],
              [
                -53.1460478312088,
                -33.584275929940986
              ],
              [
                -53.14880730388485,
                -33.58660092102979
              ],
              [
                -53.1488128018112,
                -33.586605432044166
              ],
              [
                -53.151567273555166,
                -33.588925903123666
              ],
              [
                -53.17904389409858,
                -33.609504506549726
              ],
              [
                -53.186761290243226,
                -33.61528402506054
              ],
              [
                -53.18683582128407,
                -33.615339018270184
              ],
              [
                -53.18996776175411,
                -33.61768501400436
              ],
              [
                -53.19358071399961,
                -33.620391012084575
              ],
              [
                -53.19988515041545,
                -33.625112046685935
              ],
              [
                -53.24056115287043,
                -33.655575625551066
              ],
              [
                -53.246586557851785,
                -33.660088150463885
              ],
              [
                -53.26575530369069,
                -33.67301721545955
              ],
              [
                -53.289067545783006,
                -33.68874076991979
              ],
              [
                -53.32930656650899,
                -33.715882363183376
              ],
              [
                -53.33731595989924,
                -33.72122436930831
              ],
              [
                -53.34532234231701,
                -33.72656491944141
              ],
              [
                -53.37267650552002,
                -33.74457998753124
              ],
              [
                -53.3813184332329,
                -33.74733848715154
              ],
              [
                -53.39207130122552,
                -33.75017106004877
              ],
              [
                -53.393714817023486,
                -33.75060406245378
              ],
              [
                -53.39667825598442,
                -33.751357574062425
              ],
              [
                -53.40159277983395,
                -33.748231076743615
              ],
              [
                -53.40490324652057,
                -33.748272587436745
              ],
              [
                -53.41597715358835,
                -33.74834563873431
              ],
              [
                -53.42240611861048,
                -33.743887702044574
              ],
              [
                -53.42508011823168,
                -33.74240972016882
              ],
              [
                -53.43236506765947,
                -33.73773524876675
              ],
              [
                -53.43134361553313,
                -33.73046126186732
              ],
              [
                -53.43297114287871,
                -33.726140743440524
              ],
              [
                -53.43431962716484,
                -33.723436280287444
              ],
              [
                -53.437957164602814,
                -33.712153330841424
              ],
              [
                -53.4376901736976,
                -33.70934983169399
              ],
              [
                -53.43710622553878,
                -33.70323381527819
              ],
              [
                -53.44030571191167,
                -33.69830533118759
              ],
              [
                -53.441921734910345,
                -33.695144867217465
              ],
              [
                -53.44934669679648,
                -33.69350691088118
              ],
              [
                -53.45437412446996,
                -33.69308691422073
              ],
              [
                -53.47345299263429,
                -33.692029985647224
              ],
              [
                -53.52268161089419,
                -33.6893031983204
              ],
              [
                -53.52904204396035,
                -33.68729025905557
              ],
              [
                -53.53032759883908,
                -33.67815977242295
              ],
              [
                -53.52480065655803,
                -33.6739777517383
              ],
              [
                -53.5245101683286,
                -33.67375775791459
              ],
              [
                -53.524693660980795,
                -33.673578274120004
              ],
              [
                -53.52750066872309,
                -33.67082277458082
              ],
              [
                -53.531022696154345,
                -33.6662647901121
              ],
              [
                -53.53133571218998,
                -33.659281299539956
              ],
              [
                -53.53230721668012,
                -33.65483184045618
              ],
              [
                -53.532511260376126,
                -33.64709634702847
              ],
              [
                -53.52916480542313,
                -33.64320683547895
              ],
              [
                -53.52818435016641,
                -33.63681434388723
              ],
              [
                -53.527271923346525,
                -33.63089185959257
              ],
              [
                -53.52570646391997,
                -33.62558287906816
              ],
              [
                -53.527948946472804,
                -33.622945877759896
              ],
              [
                -53.53167542544897,
                -33.618503900965315
              ],
              [
                -53.535301922358585,
                -33.61325791146088
              ],
              [
                -53.530030041595126,
                -33.60016645024595
              ],
              [
                -53.52998352128202,
                -33.608286925488585
              ],
              [
                -53.528017025079514,
                -33.61208792451071
              ],
              [
                -53.5258740442506,
                -33.613071900832026
              ],
              [
                -53.52463103573541,
                -33.613641895059516
              ],
              [
                -53.51907408065289,
                -33.60944887535157
              ],
              [
                -53.512185214936146,
                -33.59762887522117
              ],
              [
                -53.51421825648303,
                -33.5888103953681
              ],
              [
                -53.515268779905,
                -33.58425443191369
              ],
              [
                -53.510217906484456,
                -33.572849928136854
              ],
              [
                -53.5114778961284,
                -33.567199920998526
              ],
              [
                -53.51450444568142,
                -33.55362999291255
              ],
              [
                -53.512330049171425,
                -33.544522009014635
              ],
              [
                -53.51017462156075,
                -33.53540100624668
              ],
              [
                -53.504092139027335,
                -33.534770468565824
              ],
              [
                -53.502156680068886,
                -33.53740397823152
              ],
              [
                -53.49829917789974,
                -33.54285092423159
              ],
              [
                -53.49114420542145,
                -33.54437841808722
              ],
              [
                -53.48141127335876,
                -33.545523367311766
              ],
              [
                -53.48217875514442,
                -33.55040537010546
              ],
              [
                -53.4818302309715,
                -33.5565533331871
              ],
              [
                -53.48079371760259,
                -33.560619336737766
              ],
              [
                -53.48051471992951,
                -33.56171333311888
              ],
              [
                -53.48473917118593,
                -33.559721856240884
              ],
              [
                -53.48864513508872,
                -33.55787987462086
              ],
              [
                -53.48853913675013,
                -33.55885687002637
              ],
              [
                -53.48788762212761,
                -33.56483086914947
              ],
              [
                -53.48545714216924,
                -33.56778233692402
              ],
              [
                -53.48239864086359,
                -33.56954633448282
              ],
              [
                -53.47889162690105,
                -33.57277629286162
              ],
              [
                -53.46552976859779,
                -33.574051721634994
              ],
              [
                -53.46041328829123,
                -33.57452020885275
              ],
              [
                -53.44975241138125,
                -33.56959668513657
              ],
              [
                -53.448617937023215,
                -33.5629151846322
              ],
              [
                -53.45158547629734,
                -33.558904705558874
              ],
              [
                -53.45638046534949,
                -33.551636763704195
              ],
              [
                -53.45715945249628,
                -33.55045575873952
              ],
              [
                -53.46121091117432,
                -33.55334628645997
              ],
              [
                -53.4645023625967,
                -33.55488777174132
              ],
              [
                -53.46155443814535,
                -33.548652785205945
              ],
              [
                -53.45995846590282,
                -33.54569378855469
              ],
              [
                -53.455499531084264,
                -33.537667775223234
              ],
              [
                -53.4533100903341,
                -33.5327087843056
              ],
              [
                -53.452644121793945,
                -33.52796730668881
              ],
              [
                -53.45214814435656,
                -33.524726797555715
              ],
              [
                -53.4515601599957,
                -33.52080231750883
              ],
              [
                -53.45030423969745,
                -33.512670804685804
              ],
              [
                -53.44736426668405,
                -33.50823780131479
              ],
              [
                -53.442890861329516,
                -33.50361778655125
              ],
              [
                -53.443756313045846,
                -33.50823927729104
              ],
              [
                -53.4450352911381,
                -33.5127422891303
              ],
              [
                -53.44796475733965,
                -33.51431430570596
              ],
              [
                -53.44599273509585,
                -33.51546730042945
              ],
              [
                -53.4431212548463,
                -33.517146292009166
              ],
              [
                -53.439990792913875,
                -33.51784475158055
              ],
              [
                -53.438341841852875,
                -33.512865779137506
              ],
              [
                -53.434016460397935,
                -33.499377282432164
              ],
              [
                -53.43337794976196,
                -33.4953972813082
              ],
              [
                -53.43244400710878,
                -33.48926830658172
              ],
              [
                -53.432129050232646,
                -33.48617331696236
              ],
              [
                -53.43194904987001,
                -33.48440532003644
              ],
              [
                -53.431830557192704,
                -33.48324081006178
              ],
              [
                -53.431644565308964,
                -33.48187930621951
              ],
              [
                -53.4306206090986,
                -33.4743668121418
              ],
              [
                -53.429182656909376,
                -33.46548085325149
              ],
              [
                -53.42797372939053,
                -33.45777382980349
              ],
              [
                -53.426809270864254,
                -33.44969034439058
              ],
              [
                -53.426331832965055,
                -33.44600936236049
              ],
              [
                -53.42697138878606,
                -33.43410889434975
              ],
              [
                -53.42802996046426,
                -33.42009544286398
              ],
              [
                -53.434434486661075,
                -33.40162400689171
              ],
              [
                -53.438335037848006,
                -33.391149029237226
              ],
              [
                -53.439313515424985,
                -33.388520564361066
              ],
              [
                -53.44205158210221,
                -33.37978407973878
              ],
              [
                -53.44545764201409,
                -33.36536912160735
              ],
              [
                -53.44782365511816,
                -33.35560515162595
              ],
              [
                -53.45019017219694,
                -33.34584067565718
              ],
              [
                -53.461958906682966,
                -33.296069860771006
              ],
              [
                -53.46581844060662,
                -33.280216397949516
              ],
              [
                -53.46711295952617,
                -33.273755936991364
              ],
              [
                -53.46957349665837,
                -33.263188479297646
              ],
              [
                -53.46967453896211,
                -33.259170478662014
              ],
              [
                -53.46660413953782,
                -33.25010498124701
              ],
              [
                -53.46491065062292,
                -33.24572846550141
              ],
              [
                -53.46299667775888,
                -33.240668472307775
              ],
              [
                -53.46016977613944,
                -33.233710993316215
              ],
              [
                -53.44450662304459,
                -33.19252552509399
              ],
              [
                -53.44274064766794,
                -33.18798203046505
              ],
              [
                -53.44097471828047,
                -33.18343904184032
              ],
              [
                -53.43623382877438,
                -33.17073954536829
              ],
              [
                -53.433629380652704,
                -33.164188041569254
              ],
              [
                -53.43042097435437,
                -33.15555506078678
              ],
              [
                -53.42583853137696,
                -33.15252153643028
              ],
              [
                -53.42286450675219,
                -33.1566640260686
              ],
              [
                -53.41730954104109,
                -33.16064947770798
              ],
              [
                -53.41728203718027,
                -33.16066298255672
              ],
              [
                -53.412406036852715,
                -33.16305796974545
              ],
              [
                -53.407507096352006,
                -33.16229443591264
              ],
              [
                -53.39961771718432,
                -33.157015905060696
              ],
              [
                -53.39590673900197,
                -33.153845401861005
              ],
              [
                -53.391704779602904,
                -33.15070689643674
              ],
              [
                -53.384807940071155,
                -33.13548791932582
              ],
              [
                -53.38179399939571,
                -33.13357640938483
              ],
              [
                -53.37740404339124,
                -33.130294406486264
              ],
              [
                -53.37327307069327,
                -33.12613989969522
              ],
              [
                -53.37066014723234,
                -33.12350689015035
              ],
              [
                -53.366882177606406,
                -33.119844869812
              ],
              [
                -53.35973830153446,
                -33.11194986824962
              ],
              [
                -53.354356850189774,
                -33.106628859637134
              ],
              [
                -53.35271840512321,
                -33.103459354600886
              ],
              [
                -53.35237591559483,
                -33.1028633704435
              ],
              [
                -53.348179469867546,
                -33.095562847519275
              ],
              [
                -53.33643565673428,
                -33.07575936780161
              ],
              [
                -53.332411769306525,
                -33.0684958636105
              ],
              [
                -53.326333861784036,
                -33.061677870427914
              ],
              [
                -53.323129390977044,
                -33.058573848427386
              ],
              [
                -53.319513940320675,
                -33.05468985740045
              ],
              [
                -53.315950472429016,
                -33.055957322920555
              ],
              [
                -53.31740587950646,
                -33.06439281216667
              ],
              [
                -53.32146637296844,
                -33.06318932468073
              ],
              [
                -53.32354580637092,
                -33.07366080806592
              ],
              [
                -53.32489573136349,
                -33.08046130958407
              ],
              [
                -53.32058576428767,
                -33.08532529368517
              ],
              [
                -53.31811077114628,
                -33.08795174689296
              ],
              [
                -53.31509075182945,
                -33.089220732784135
              ],
              [
                -53.31143679202746,
                -33.09156270946796
              ],
              [
                -53.29784135897129,
                -33.09843765931648
              ],
              [
                -53.287381940618495,
                -33.10350811199329
              ],
              [
                -53.276923503281076,
                -33.1085780396742
              ],
              [
                -53.261018139106014,
                -33.10720948607587
              ],
              [
                -53.25567270703888,
                -33.10212448418849
              ],
              [
                -53.25332825672086,
                -33.098952985114444
              ],
              [
                -53.25187776206106,
                -33.09627747783337
              ],
              [
                -53.249823272787694,
                -33.09239244865554
              ],
              [
                -53.24479738519071,
                -33.0829214901641
              ],
              [
                -53.24427641049458,
                -33.08193946611531
              ],
              [
                -53.23605008415679,
                -33.06691796027836
              ],
              [
                -53.231570127267815,
                -33.063082479410774
              ],
              [
                -53.229661134356974,
                -33.06137094895793
              ],
              [
                -53.22353571206167,
                -33.05587944667897
              ],
              [
                -53.217311782339905,
                -33.05042342290323
              ],
              [
                -53.21232239695462,
                -33.046039925091236
              ],
              [
                -53.209819401479024,
                -33.043742414393954
              ],
              [
                -53.20638895760538,
                -33.04078642014951
              ],
              [
                -53.20226902337513,
                -33.03701292676405
              ],
              [
                -53.19822405855362,
                -33.0309619259185
              ],
              [
                -53.1965971152835,
                -33.02812091830619
              ],
              [
                -53.19411365874761,
                -33.02434289809857
              ],
              [
                -53.189426729711464,
                -33.0168958886704
              ],
              [
                -53.18714677016703,
                -33.01349790149495
              ],
              [
                -53.1826543439997,
                -33.00613541874828
              ],
              [
                -53.16079720839604,
                -32.971695395454724
              ],
              [
                -53.15937525147146,
                -32.96916841606669
              ],
              [
                -53.157544853928755,
                -32.95668393081171
              ],
              [
                -53.160242398182966,
                -32.94114895533518
              ],
              [
                -53.16638552380761,
                -32.905764068677115
              ],
              [
                -53.16257956456176,
                -32.90890254886941
              ],
              [
                -53.161402077799295,
                -32.90470655643225
              ],
              [
                -53.16085108828955,
                -32.90274255057001
              ],
              [
                -53.16006614477645,
                -32.8999450836158
              ],
              [
                -53.16166165079585,
                -32.89307510142862
              ],
              [
                -53.16486269197101,
                -32.879293632207904
              ],
              [
                -53.16299077050573,
                -32.87170863861315
              ],
              [
                -53.16100433776824,
                -32.86366215060129
              ],
              [
                -53.15974184824085,
                -32.858547166958736
              ],
              [
                -53.157956430887936,
                -32.8513116639632
              ],
              [
                -53.155120491979524,
                -32.83982321327858
              ],
              [
                -53.14960713172264,
                -32.82538970283544
              ],
              [
                -53.1473696899407,
                -32.819471716869295
              ],
              [
                -53.1440492315696,
                -32.81147970703361
              ],
              [
                -53.142862782068484,
                -32.80887370894502
              ],
              [
                -53.140698791443846,
                -32.80512772126894
              ],
              [
                -53.136884846389385,
                -32.80168322173825
              ],
              [
                -53.12619301210195,
                -32.793160695213466
              ],
              [
                -53.12314504904625,
                -32.79099368204734
              ],
              [
                -53.11419205289046,
                -32.799374134698375
              ],
              [
                -53.11282709230725,
                -32.8012956303022
              ],
              [
                -53.11241257013352,
                -32.80187860514627
              ],
              [
                -53.110192081178305,
                -32.80385408791446
              ],
              [
                -53.10866805956866,
                -32.80780160010825
              ],
              [
                -53.10397009430031,
                -32.81215506259215
              ],
              [
                -53.0934130956747,
                -32.82193700501844
              ],
              [
                -53.08367816893592,
                -32.8293249415843
              ],
              [
                -53.07824819734789,
                -32.83076792367032
              ],
              [
                -53.072341207941854,
                -32.8324913950342
              ],
              [
                -53.06828079529422,
                -32.829648362050676
              ],
              [
                -53.06358680991733,
                -32.82612888090259
              ],
              [
                -53.05799391224699,
                -32.821722842933376
              ],
              [
                -53.05547095226562,
                -32.818519329473645
              ],
              [
                -53.04201264707284,
                -32.80037784554522
              ],
              [
                -53.041673133621785,
                -32.799919320146955
              ],
              [
                -53.038247720829645,
                -32.7965733421378
              ],
              [
                -53.033187795325674,
                -32.791912314134336
              ],
              [
                -53.021297929561925,
                -32.78113130091729
              ],
              [
                -52.99986571289561,
                -32.7613707655706
              ],
              [
                -52.997623764622226,
                -32.75914776809969
              ],
              [
                -52.99134232926002,
                -32.753785763547704
              ],
              [
                -52.98757593679869,
                -32.74340025270087
              ],
              [
                -52.982168488916656,
                -32.73413124216125
              ],
              [
                -52.98584289945633,
                -32.75190371520297
              ],
              [
                -52.98854735245172,
                -32.75520274013363
              ],
              [
                -52.99402124918286,
                -32.76010325427784
              ],
              [
                -52.99687171841624,
                -32.76679675387704
              ],
              [
                -53.000869632957055,
                -32.77618423412803
              ],
              [
                -53.002888048947426,
                -32.78452422129303
              ],
              [
                -53.000532021845714,
                -32.79298167526431
              ],
              [
                -52.996351025985284,
                -32.79989265596708
              ],
              [
                -52.99430253998324,
                -32.80297363987864
              ],
              [
                -52.98471703040848,
                -32.81626957422493
              ],
              [
                -52.97208408911032,
                -32.829000008701954
              ],
              [
                -52.96835510591649,
                -32.8328864424506
              ],
              [
                -52.960081121410205,
                -32.84095189972795
              ],
              [
                -52.952114160110035,
                -32.847749363303286
              ],
              [
                -52.93437970567902,
                -32.86242175112676
              ],
              [
                -52.92836475305046,
                -32.864353717547594
              ],
              [
                -52.92823776284114,
                -32.86439423990261
              ],
              [
                -52.924515752065226,
                -32.86524519178757
              ],
              [
                -52.91815926122399,
                -32.871618691324684
              ],
              [
                -52.91054828954684,
                -32.878664144852834
              ],
              [
                -52.90756479010337,
                -32.881429117452214
              ],
              [
                -52.906714323258655,
                -32.8822171079223
              ],
              [
                -52.90291983986787,
                -32.88573409224673
              ],
              [
                -52.8978713285433,
                -32.890103062142465
              ],
              [
                -52.89416733709267,
                -32.892194533196665
              ],
              [
                -52.86965348688695,
                -32.90478991348652
              ],
              [
                -52.86198902525159,
                -32.90829485006878
              ],
              [
                -52.846882144198304,
                -32.911034273204855
              ],
              [
                -52.840595192432396,
                -32.91189278295986
              ],
              [
                -52.833251767529326,
                -32.91321024406406
              ],
              [
                -52.81021699111176,
                -32.90730217719596
              ],
              [
                -52.80421306476222,
                -32.90541613664005
              ],
              [
                -52.79709712387933,
                -32.90116960782867
              ],
              [
                -52.787633209104726,
                -32.89513409508345
              ],
              [
                -52.779800337663886,
                -32.88997006936355
              ],
              [
                -52.77043592494575,
                -32.883459549213676
              ],
              [
                -52.76748245762935,
                -32.88074556084505
              ],
              [
                -52.75566665780716,
                -32.86783754424372
              ],
              [
                -52.7500147448429,
                -32.86162004651594
              ],
              [
                -52.74205034516496,
                -32.84957102061324
              ],
              [
                -52.73410001347213,
                -32.8374970218787
              ],
              [
                -52.734093490580115,
                -32.8374880118719
              ],
              [
                -52.72970306293803,
                -32.83082000972668
              ],
              [
                -52.72925256518492,
                -32.830398032783165
              ],
              [
                -52.72652608999768,
                -32.82784303407762
              ],
              [
                -52.71773274644359,
                -32.81273951398995
              ],
              [
                -52.71688730315612,
                -32.81128703379374
              ],
              [
                -52.71592979858094,
                -32.809868537034326
              ],
              [
                -52.715927297622805,
                -32.80986500503195
              ],
              [
                -52.70752195877246,
                -32.797413526393164
              ],
              [
                -52.70411500560043,
                -32.791635500456415
              ],
              [
                -52.69997606992598,
                -32.78295701728227
              ],
              [
                -52.69850662123402,
                -32.7797655135534
              ],
              [
                -52.68879132307643,
                -32.758675055002705
              ],
              [
                -52.68676086476748,
                -32.75501703099422
              ],
              [
                -52.68431037058977,
                -32.750912049249685
              ],
              [
                -52.67178909874808,
                -32.72906902860364
              ],
              [
                -52.65753539767472,
                -32.70377554493399
              ],
              [
                -52.644947093879814,
                -32.68162305105708
              ],
              [
                -52.638249703311885,
                -32.67019856078216
              ],
              [
                -52.6359562475683,
                -32.66628656835432
              ],
              [
                -52.634538772680486,
                -32.66386905309151
              ],
              [
                -52.634021299215355,
                -32.662986047997116
              ],
              [
                -52.63399478299605,
                -32.662892062109634
              ],
              [
                -52.633399282003275,
                -32.66187008301531
              ],
              [
                -52.627123423921084,
                -32.651110082016196
              ],
              [
                -52.62347599154151,
                -32.64514556275518
              ],
              [
                -52.61918155799933,
                -32.637252049385935
              ],
              [
                -52.61633860406227,
                -32.63127656362352
              ],
              [
                -52.613149677308826,
                -32.62457208814949
              ],
              [
                -52.610957709836,
                -32.619963586893086
              ],
              [
                -52.61019025156837,
                -32.616544590867505
              ],
              [
                -52.609362268380565,
                -32.61319460941833
              ],
              [
                -52.60802580683571,
                -32.608593099820716
              ],
              [
                -52.602454976755695,
                -32.587787101326505
              ],
              [
                -52.59398070129954,
                -32.55691918892922
              ],
              [
                -52.58561695761787,
                -32.5262197338029
              ],
              [
                -52.5862929479748,
                -32.52186672328086
              ],
              [
                -52.58693845795222,
                -32.5187957205106
              ],
              [
                -52.59262106206481,
                -32.49176582859576
              ],
              [
                -52.60856810851018,
                -32.45874846713297
              ],
              [
                -52.61851515023951,
                -32.43815358281351
              ],
              [
                -52.62541466444732,
                -32.42386861511193
              ],
              [
                -52.62859968845787,
                -32.417274144797894
              ],
              [
                -52.63641117013881,
                -32.4053587182017
              ],
              [
                -52.637152659647526,
                -32.404248738073896
              ],
              [
                -52.64433766458989,
                -32.393489791985104
              ],
              [
                -52.66237418092914,
                -32.366145901683154
              ],
              [
                -52.67133367643681,
                -32.35249298524252
              ],
              [
                -52.675899185253996,
                -32.34553601170854
              ],
              [
                -52.678891179754125,
                -32.34097654760769
              ],
              [
                -52.682896690097806,
                -32.334872597603294
              ],
              [
                -52.6857111779462,
                -32.33083760746327
              ],
              [
                -52.689522666326596,
                -32.32482513741095
              ],
              [
                -52.69183916787311,
                -32.32085115300443
              ],
              [
                -52.69313516233238,
                -32.317624671426
              ],
              [
                -52.692109752296936,
                -32.3095516838643
              ],
              [
                -52.68985881716454,
                -32.29911469990841
              ],
              [
                -52.684915435051025,
                -32.278808738791255
              ],
              [
                -52.68118458360756,
                -32.2646777547079
              ],
              [
                -52.678509104282035,
                -32.25806875761991
              ],
              [
                -52.6712707690763,
                -32.241598774487514
              ],
              [
                -52.667999850952086,
                -32.23355926944296
              ],
              [
                -52.65591610468246,
                -32.20522380121213
              ],
              [
                -52.651536182303616,
                -32.198583287083494
              ],
              [
                -52.6513861610294,
                -32.19832679608269
              ],
              [
                -52.649563187131065,
                -32.1952133110667
              ],
              [
                -52.64721873648111,
                -32.19141678653647
              ],
              [
                -52.63205101175844,
                -32.16773078604316
              ],
              [
                -52.6280445798508,
                -32.161671782132395
              ],
              [
                -52.62769408617197,
                -32.158513314492524
              ],
              [
                -52.62955910620387,
                -32.154537812253984
              ],
              [
                -52.62767610769967,
                -32.15160382157852
              ],
              [
                -52.6251971821363,
                -32.148312818273226
              ],
              [
                -52.62186121254647,
                -32.14331132960086
              ],
              [
                -52.632597623033895,
                -32.146734353488746
              ],
              [
                -52.634806085113304,
                -32.14740534916101
              ],
              [
                -52.64516797127357,
                -32.150552898169884
              ],
              [
                -52.65340440458343,
                -32.15287543224904
              ],
              [
                -52.656139390915015,
                -32.15362541695989
              ],
              [
                -52.65889534555016,
                -32.15446541453721
              ],
              [
                -52.6630387709822,
                -32.157340434941126
              ],
              [
                -52.66562625584342,
                -32.15923145720368
              ],
              [
                -52.67271618478224,
                -32.164411966377024
              ],
              [
                -52.6766016300088,
                -32.16409147361986
              ],
              [
                -52.68202513830303,
                -32.16264451716472
              ],
              [
                -52.683024591722926,
                -32.16237800705613
              ],
              [
                -52.688684061029186,
                -32.160943551569574
              ],
              [
                -52.6952095130328,
                -32.16194557671965
              ],
              [
                -52.69668846233373,
                -32.16547504324879
              ],
              [
                -52.69847595163358,
                -32.167037559953506
              ],
              [
                -52.70006091862104,
                -32.168422539240744
              ],
              [
                -52.70336588462886,
                -32.16843708220366
              ],
              [
                -52.705609896301944,
                -32.16844707568436
              ],
              [
                -52.708805343115316,
                -32.16864758372478
              ],
              [
                -52.71272831706966,
                -32.16883763187394
              ],
              [
                -52.715564309617626,
                -32.17094962166936
              ],
              [
                -52.71720625076356,
                -32.1723991340446
              ],
              [
                -52.71860926408389,
                -32.1736386119273
              ],
              [
                -52.719658216691926,
                -32.17310163969866
              ],
              [
                -52.72288321411612,
                -32.17145063344289
              ],
              [
                -52.72541273472424,
                -32.16953967888196
              ],
              [
                -52.73726462887424,
                -32.16653772651937
              ],
              [
                -52.73678061489701,
                -32.17198569201748
              ],
              [
                -52.74144607050506,
                -32.17274269692389
              ],
              [
                -52.74478402492626,
                -32.174692215262915
              ],
              [
                -52.741542067454546,
                -32.17568971805857
              ],
              [
                -52.73701258652418,
                -32.17531118779242
              ],
              [
                -52.739760514971564,
                -32.181316170624726
              ],
              [
                -52.735554090182944,
                -32.18046568488993
              ],
              [
                -52.735962547125375,
                -32.1848556737912
              ],
              [
                -52.731075572057314,
                -32.18670415851152
              ],
              [
                -52.73100558147168,
                -32.186730642149485
              ],
              [
                -52.726119107529456,
                -32.18821661076628
              ],
              [
                -52.73049152981491,
                -32.19540063458622
              ],
              [
                -52.73159154536546,
                -32.19207510845146
              ],
              [
                -52.7332435228407,
                -32.19106564394597
              ],
              [
                -52.73505504358702,
                -32.18995913935622
              ],
              [
                -52.738143002426625,
                -32.190646639755464
              ],
              [
                -52.743301929926176,
                -32.19158818431276
              ],
              [
                -52.75032389439696,
                -32.1933607137278
              ],
              [
                -52.75645529421122,
                -32.1963102074728
              ],
              [
                -52.75954326607833,
                -32.19852772111416
              ],
              [
                -52.761772221408705,
                -32.20168620679734
              ],
              [
                -52.76648166349126,
                -32.2043452252401
              ],
              [
                -52.76400522686106,
                -32.205839706053126
              ],
              [
                -52.761368240793935,
                -32.207631210453805
              ],
              [
                -52.7575307323914,
                -32.20961068329666
              ],
              [
                -52.75465775211564,
                -32.207166669118365
              ],
              [
                -52.7515508081928,
                -32.20771216559116
              ],
              [
                -52.746402333423255,
                -32.209017623530436
              ],
              [
                -52.74221735891144,
                -32.20911462453392
              ],
              [
                -52.74217036728506,
                -32.20911611833086
              ],
              [
                -52.736309979929345,
                -32.201627621914504
              ],
              [
                -52.73193297324134,
                -32.20577107313772
              ],
              [
                -52.72844048671496,
                -32.21240856796846
              ],
              [
                -52.73318937857525,
                -32.21640807026752
              ],
              [
                -52.73620236970354,
                -32.218340099296725
              ],
              [
                -52.73961683805492,
                -32.21967760849547
              ],
              [
                -52.74414930720367,
                -32.21643561171039
              ],
              [
                -52.74651480386548,
                -32.21338511325701
              ],
              [
                -52.74652379185379,
                -32.21337464432089
              ],
              [
                -52.749682276606244,
                -32.21193463926911
              ],
              [
                -52.753830235617166,
                -32.21180464819298
              ],
              [
                -52.760218691391955,
                -32.218146174711684
              ],
              [
                -52.7646226079418,
                -32.22250668768993
              ],
              [
                -52.7675465816344,
                -32.22548768178088
              ],
              [
                -52.771769990366046,
                -32.22972519530891
              ],
              [
                -52.77419348085678,
                -32.23309169633697
              ],
              [
                -52.776160906450116,
                -32.24064116716666
              ],
              [
                -52.77720290377587,
                -32.24628365075254
              ],
              [
                -52.78003229925863,
                -32.25690165574631
              ],
              [
                -52.78265269928732,
                -32.267539617826934
              ],
              [
                -52.78583069499378,
                -32.26880163225183
              ],
              [
                -52.78761218116839,
                -32.26919716686008
              ],
              [
                -52.7947871064108,
                -32.27078966147992
              ],
              [
                -52.79582206481456,
                -32.2742131645065
              ],
              [
                -52.7939655552189,
                -32.27687665909304
              ],
              [
                -52.79145109821872,
                -32.27780264213064
              ],
              [
                -52.78718059670098,
                -32.27937461111733
              ],
              [
                -52.79453701862538,
                -32.28687363207865
              ],
              [
                -52.79616696696746,
                -32.29134862407804
              ],
              [
                -52.79909138890151,
                -32.298682095611014
              ],
              [
                -52.80241432966713,
                -32.30701611741253
              ],
              [
                -52.80390230303565,
                -32.31066112185458
              ],
              [
                -52.8067542279186,
                -32.316910091759254
              ],
              [
                -52.80766073665885,
                -32.31739812143076
              ],
              [
                -52.809610729587774,
                -32.31844812717772
              ],
              [
                -52.81304618819127,
                -32.32072311026858
              ],
              [
                -52.813502647863984,
                -32.325564588415304
              ],
              [
                -52.81683108781521,
                -32.32792710684408
              ],
              [
                -52.818788055900704,
                -32.33029360842102
              ],
              [
                -52.81959055766136,
                -32.33328860954899
              ],
              [
                -52.817256014047416,
                -32.340493099059515
              ],
              [
                -52.8130375165548,
                -32.34688653351483
              ],
              [
                -52.80790356424727,
                -32.35247953201501
              ],
              [
                -52.800495123841266,
                -32.3510800138333
              ],
              [
                -52.80007161776287,
                -32.3509999732226
              ],
              [
                -52.7917056987649,
                -32.34831494609702
              ],
              [
                -52.793021191761405,
                -32.352251944117
              ],
              [
                -52.795594661267764,
                -32.354201963340834
              ],
              [
                -52.800760563028724,
                -32.35811796383709
              ],
              [
                -52.79933306045371,
                -32.362712468571736
              ],
              [
                -52.79681007096961,
                -32.36592645399361
              ],
              [
                -52.78796810024064,
                -32.37804039881835
              ],
              [
                -52.782798122855695,
                -32.37305985788103
              ],
              [
                -52.772539200324836,
                -32.37662530445379
              ],
              [
                -52.76459279871629,
                -32.37263331428762
              ],
              [
                -52.76508434263007,
                -32.367916801870244
              ],
              [
                -52.760421858673695,
                -32.367401815249075
              ],
              [
                -52.76018888172644,
                -32.3673757743194
              ],
              [
                -52.75538088653777,
                -32.36879075537683
              ],
              [
                -52.753226419962616,
                -32.367968769196686
              ],
              [
                -52.751522471247455,
                -32.36418378616057
              ],
              [
                -52.74991349614456,
                -32.360670768869696
              ],
              [
                -52.74614702648702,
                -32.362083740373954
              ],
              [
                -52.74233055610416,
                -32.36385774178456
              ],
              [
                -52.73665255516069,
                -32.36491169901949
              ],
              [
                -52.7291400948894,
                -32.367705685193386
              ],
              [
                -52.721681147412305,
                -32.37096961844833
              ],
              [
                -52.71901665004305,
                -32.38062010354879
              ],
              [
                -52.721632597893645,
                -32.38464259688759
              ],
              [
                -52.72377706578302,
                -32.38711161200867
              ],
              [
                -52.73910034469493,
                -32.40384011560522
              ],
              [
                -52.741902322262874,
                -32.40680561734282
              ],
              [
                -52.743940756163475,
                -32.408909607921686
              ],
              [
                -52.74397177871761,
                -32.40894213597495
              ],
              [
                -52.74524925348072,
                -32.41026064921895
              ],
              [
                -52.749557196428405,
                -32.41479961056659
              ],
              [
                -52.770107435107654,
                -32.43211117626639
              ],
              [
                -52.77730285422806,
                -32.43753469085065
              ],
              [
                -52.78875919570428,
                -32.4449607008104
              ],
              [
                -52.8051020515022,
                -32.45084674342302
              ],
              [
                -52.822228897255734,
                -32.45432483125135
              ],
              [
                -52.838833718926004,
                -32.457696875137
              ],
              [
                -52.86658399768542,
                -32.459759477915505
              ],
              [
                -52.87280940322653,
                -32.460226991437445
              ],
              [
                -52.8790353697716,
                -32.46069403996608
              ],
              [
                -52.89513323181931,
                -32.46404409983231
              ],
              [
                -52.911808089290055,
                -32.46771512143009
              ],
              [
                -52.91573952761414,
                -32.46867114997627
              ],
              [
                -52.92144747365156,
                -32.47060667978386
              ],
              [
                -52.93813927071742,
                -32.47650923715574
              ],
              [
                -52.94244523415715,
                -32.47811024277551
              ],
              [
                -52.9462112275985,
                -32.480781736516356
              ],
              [
                -52.94741717268896,
                -32.48163723463641
              ],
              [
                -52.953420605546505,
                -32.48554726701104
              ],
              [
                -52.9557391191381,
                -32.487483750316
              ],
              [
                -52.96236752173058,
                -32.492930772706686
              ],
              [
                -52.969324420034795,
                -32.49864729687811
              ],
              [
                -52.98199825535244,
                -32.509468327411085
              ],
              [
                -52.9840092174224,
                -32.5179257928461
              ],
              [
                -52.98614860599192,
                -32.53013077590643
              ],
              [
                -52.9890939777788,
                -32.54787425626102
              ],
              [
                -52.992160859106946,
                -32.56634619146482
              ],
              [
                -52.994132793611136,
                -32.57759067520183
              ],
              [
                -52.99531374861764,
                -32.58198770483051
              ],
              [
                -52.9997181500997,
                -32.594095667022295
              ],
              [
                -53.00023364035705,
                -32.597350162514566
              ],
              [
                -53.00872250526934,
                -32.60717868974712
              ],
              [
                -53.013450959815046,
                -32.60950918158739
              ],
              [
                -53.019084375327765,
                -32.61228471027645
              ],
              [
                -53.036799743046764,
                -32.61366377234075
              ],
              [
                -53.04803712947605,
                -32.61453830970182
              ],
              [
                -53.05339459707864,
                -32.61518182627251
              ],
              [
                -53.06079100650531,
                -32.618253871895234
              ],
              [
                -53.066048946259265,
                -32.62415635162
              ],
              [
                -53.06988937009893,
                -32.628467848000945
              ],
              [
                -53.072224316921734,
                -32.63680184940226
              ],
              [
                -53.07250879346307,
                -32.641140358394054
              ],
              [
                -53.0728327440547,
                -32.64607981914217
              ],
              [
                -53.07375574051824,
                -32.649237323680694
              ],
              [
                -53.075779688303825,
                -32.65616333309287
              ],
              [
                -53.08251864093142,
                -32.65112883904591
              ],
              [
                -53.08277914298562,
                -32.650934849627575
              ],
              [
                -53.08850161618799,
                -32.648003889235504
              ],
              [
                -53.101530020646,
                -32.64133094119568
              ],
              [
                -53.113386974838065,
                -32.63810802897195
              ],
              [
                -53.12497739925616,
                -32.6383610684187
              ],
              [
                -53.12782137417857,
                -32.63786405686855
              ],
              [
                -53.13965575764713,
                -32.63927710865216
              ],
              [
                -53.14609418237798,
                -32.64027915610582
              ],
              [
                -53.150605138944094,
                -32.64191115675687
              ],
              [
                -53.16332698357532,
                -32.64724618219538
              ],
              [
                -53.16549999345537,
                -32.648157175448006
              ],
              [
                -53.17394938595114,
                -32.65610423432725
              ],
              [
                -53.177444377397904,
                -32.65282974111462
              ],
              [
                -53.1780383807886,
                -32.65014672697424
              ],
              [
                -53.17836035996317,
                -32.648692230777286
              ],
              [
                -53.18058337206338,
                -32.645526257812016
              ],
              [
                -53.185712843058454,
                -32.64427277592394
              ],
              [
                -53.20054072273259,
                -32.64132084994396
              ],
              [
                -53.21596961930258,
                -32.63824939684782
              ],
              [
                -53.23226701214892,
                -32.630829515694685
              ],
              [
                -53.235047508568904,
                -32.629516519792226
              ],
              [
                -53.24151398028574,
                -32.62586355730682
              ],
              [
                -53.2427954821617,
                -32.62251008870028
              ],
              [
                -53.24839604197597,
                -32.60608413839155
              ],
              [
                -53.24914254314502,
                -32.60389461674954
              ],
              [
                -53.268348404442484,
                -32.60511269091569
              ],
              [
                -53.27128333625166,
                -32.60601673648819
              ],
              [
                -53.272183337127785,
                -32.607316714336655
              ],
              [
                -53.27422978373893,
                -32.610272232268926
              ],
              [
                -53.27683124466404,
                -32.6149162226551
              ],
              [
                -53.280041226794864,
                -32.61887570528041
              ],
              [
                -53.282787656803784,
                -32.62032971476206
              ],
              [
                -53.28665213274674,
                -32.620960263006815
              ],
              [
                -53.28960109696996,
                -32.619293778659305
              ],
              [
                -53.294303104407746,
                -32.614252294782325
              ],
              [
                -53.29747508473822,
                -32.61077980061949
              ],
              [
                -53.29954559526066,
                -32.607259349804046
              ],
              [
                -53.30339458457083,
                -32.60470583242149
              ],
              [
                -53.305605059385954,
                -32.6032683693427
              ],
              [
                -53.30620759888061,
                -32.60287684686408
              ],
              [
                -53.31113104526773,
                -32.59970138358192
              ],
              [
                -53.31872150333466,
                -32.594666914179534
              ],
              [
                -53.32666151077774,
                -32.589269473135786
              ],
              [
                -53.33167498600312,
                -32.58567148922782
              ],
              [
                -53.335341952674334,
                -32.58496203774583
              ],
              [
                -53.34185634621083,
                -32.58808805362269
              ],
              [
                -53.34280686272206,
                -32.58872004225894
              ],
              [
                -53.345096311047705,
                -32.590242542609055
              ],
              [
                -53.34851728208277,
                -32.59212204402341
              ],
              [
                -53.35402226250558,
                -32.59082006988205
              ],
              [
                -53.35665074680706,
                -32.58841558686654
              ],
              [
                -53.35943574859911,
                -32.58456110491192
              ],
              [
                -53.36960622949508,
                -32.570482682773914
              ],
              [
                -53.37825513947977,
                -32.57135221516021
              ],
              [
                -53.38094611356442,
                -32.57619222451174
              ],
              [
                -53.38269609105333,
                -32.57874272313629
              ],
              [
                -53.38785950396513,
                -32.58626824594218
              ],
              [
                -53.39976392088698,
                -32.58098177379136
              ],
              [
                -53.40238040180039,
                -32.579655313207894
              ],
              [
                -53.41621138138076,
                -32.56821489972839
              ],
              [
                -53.41665137162972,
                -32.56576389034288
              ],
              [
                -53.41757089914975,
                -32.560638920264545
              ],
              [
                -53.4235838787978,
                -32.55846791734627
              ],
              [
                -53.42469131693651,
                -32.56110893498608
              ],
              [
                -53.42593431803106,
                -32.56407291946228
              ],
              [
                -53.43130476266205,
                -32.56541444358632
              ],
              [
                -53.44127681743733,
                -32.539543558011125
              ],
              [
                -53.45355328827272,
                -32.525830128091
              ],
              [
                -53.45689131218617,
                -32.520556666826145
              ],
              [
                -53.45640084031417,
                -32.515215672161546
              ],
              [
                -53.45819033827942,
                -32.51183468978788
              ],
              [
                -53.46248636091974,
                -32.50167072908195
              ],
              [
                -53.46432590044471,
                -32.49696574601771
              ],
              [
                -53.46620689526947,
                -32.49215479038157
              ],
              [
                -53.462996428092985,
                -32.4848427705843
              ],
              [
                -53.467290420919646,
                -32.482288284109714
              ],
              [
                -53.471404931555085,
                -32.48017230883465
              ],
              [
                -53.47919783998044,
                -32.48397934362536
              ],
              [
                -53.5022091518389,
                -32.48034694858249
              ],
              [
                -53.508117079735094,
                -32.483606952526316
              ],
              [
                -53.51097304229567,
                -32.48518294020342
              ],
              [
                -53.5145010006432,
                -32.48705998708746
              ],
              [
                -53.516942526785016,
                -32.484940501586074
              ],
              [
                -53.52076398434889,
                -32.4812415307331
              ],
              [
                -53.52363796539118,
                -32.4847074950888
              ],
              [
                -53.52592592468916,
                -32.486919533833884
              ],
              [
                -53.5287968937005,
                -32.485910549603126
              ],
              [
                -53.54402881225473,
                -32.47729212956223
              ],
              [
                -53.543202334226535,
                -32.47424660507807
              ],
              [
                -53.54248185600543,
                -32.47158962814945
              ],
              [
                -53.55041728925228,
                -32.47142964984598
              ],
              [
                -53.551389800558745,
                -32.47141064709701
              ],
              [
                -53.55707325097495,
                -32.47090517585948
              ],
              [
                -53.56644776445121,
                -32.458490740308584
              ],
              [
                -53.582143141655465,
                -32.451820812721344
              ],
              [
                -53.583690163806615,
                -32.447738327914735
              ],
              [
                -53.5856037112168,
                -32.44129437119271
              ],
              [
                -53.58728023470257,
                -32.43565138358367
              ],
              [
                -53.59090369201197,
                -32.4342598854917
              ],
              [
                -53.59736914509659,
                -32.4317774212288
              ],
              [
                -53.61225111079996,
                -32.41697150705161
              ],
              [
                -53.61149115917555,
                -32.40693605014717
              ],
              [
                -53.611457647131594,
                -32.40648855804531
              ],
              [
                -53.610904184108186,
                -32.40206854395655
              ],
              [
                -53.61386218979258,
                -32.39800709720789
              ],
              [
                -53.61622617675886,
                -32.39535760760039
              ],
              [
                -53.621004170036045,
                -32.393266629120944
              ],
              [
                -53.63195258810779,
                -32.388475695597606
              ],
              [
                -53.643715555831925,
                -32.38315974081613
              ],
              [
                -53.637716178962826,
                -32.365971762980614
              ],
              [
                -53.63219628964391,
                -32.349870786745456
              ],
              [
                -53.642588822004825,
                -32.336257322415165
              ],
              [
                -53.64300982279152,
                -32.33138735261682
              ],
              [
                -53.64337434303789,
                -32.32400837244638
              ],
              [
                -53.64798486144018,
                -32.322122394748405
              ],
              [
                -53.652396812952944,
                -32.32031693400529
              ],
              [
                -53.655218804646616,
                -32.31793295275535
              ],
              [
                -53.65464884525356,
                -32.3119069333925
              ],
              [
                -53.65062384447311,
                -32.31223645126032
              ],
              [
                -53.646342424615455,
                -32.3125878979716
              ],
              [
                -53.64233600486039,
                -32.29649593638303
              ],
              [
                -53.650350006802725,
                -32.29146999670695
              ],
              [
                -53.6552559619703,
                -32.28805652385334
              ],
              [
                -53.65600150743051,
                -32.279782543458495
              ],
              [
                -53.66071446787117,
                -32.27674905988101
              ],
              [
                -53.66429345601653,
                -32.274446080703626
              ],
              [
                -53.66668849180504,
                -32.27127558345721
              ],
              [
                -53.67062646677547,
                -32.26667913320272
              ],
              [
                -53.67622796103677,
                -32.2593041586308
              ],
              [
                -53.67619348107932,
                -32.254818188003
              ],
              [
                -53.676209986591736,
                -32.24968120012537
              ],
              [
                -53.67641555775211,
                -32.2423361982487
              ],
              [
                -53.67642853944169,
                -32.24186772240437
              ],
              [
                -53.676654049608146,
                -32.23734423199578
              ],
              [
                -53.67676007446366,
                -32.23521322645657
              ],
              [
                -53.6845436283787,
                -32.21942530005364
              ],
              [
                -53.69197757068065,
                -32.21192833066838
              ],
              [
                -53.69313058144587,
                -32.20807086269697
              ],
              [
                -53.69468510822656,
                -32.20350388212272
              ],
              [
                -53.69798708412621,
                -32.20463737666752
              ],
              [
                -53.70369051695353,
                -32.20659538261241
              ],
              [
                -53.707427029700455,
                -32.20246994438729
              ],
              [
                -53.709874516026275,
                -32.19896694315185
              ],
              [
                -53.70788353105866,
                -32.19591793375003
              ],
              [
                -53.70473311664517,
                -32.19164092025044
              ],
              [
                -53.70400211237954,
                -32.19064893143836
              ],
              [
                -53.705379613308004,
                -32.1903264481231
              ],
              [
                -53.70624811958661,
                -32.19012346533697
              ],
              [
                -53.70801808692624,
                -32.189709957924315
              ],
              [
                -53.71213654080134,
                -32.18874697390785
              ],
              [
                -53.712059562695586,
                -32.18516999298773
              ],
              [
                -53.71217058891024,
                -32.18145449920415
              ],
              [
                -53.71218161816514,
                -32.177697521089975
              ],
              [
                -53.72258755140616,
                -32.17311855762041
              ],
              [
                -53.72171157751227,
                -32.16956604421038
              ],
              [
                -53.71939661888834,
                -32.16184056643815
              ],
              [
                -53.716829219276434,
                -32.153270083584836
              ],
              [
                -53.723778653861615,
                -32.15293512224784
              ],
              [
                -53.72840459731058,
                -32.15271211366037
              ],
              [
                -53.7337655596701,
                -32.15202364732993
              ],
              [
                -53.726895136641694,
                -32.146600643564874
              ],
              [
                -53.72592869387327,
                -32.145838118207145
              ],
              [
                -53.72586618666529,
                -32.14578864805505
              ],
              [
                -53.72587968254178,
                -32.14090713561533
              ],
              [
                -53.72617572418703,
                -32.13787416203591
              ],
              [
                -53.726905729313536,
                -32.136872655533594
              ],
              [
                -53.728402214569336,
                -32.1348201628024
              ],
              [
                -53.731171199847395,
                -32.13172916598726
              ],
              [
                -53.73424820622796,
                -32.127870206305985
              ],
              [
                -53.73604271097398,
                -32.12550322559676
              ],
              [
                -53.743013184308595,
                -32.115411282340204
              ],
              [
                -53.740282249729006,
                -32.11091426721795
              ],
              [
                -53.738944785810624,
                -32.107637288206654
              ],
              [
                -53.738228304343195,
                -32.10479880682954
              ],
              [
                -53.7391712901227,
                -32.100700282554534
              ],
              [
                -53.73152035657795,
                -32.10533874975102
              ],
              [
                -53.72819741296218,
                -32.10164126421656
              ],
              [
                -53.72726341491324,
                -32.10037376720121
              ],
              [
                -53.72496494069999,
                -32.09725526670323
              ],
              [
                -53.73646785930994,
                -32.093669791552315
              ],
              [
                -53.74379681947786,
                -32.09138484941161
              ],
              [
                -53.74426185886338,
                -32.08799335641903
              ],
              [
                -53.744592335516764,
                -32.08558135653597
              ],
              [
                -53.745048891657596,
                -32.081228365781485
              ],
              [
                -53.74526239640216,
                -32.07828186566174
              ],
              [
                -53.74911988420998,
                -32.07768739062128
              ],
              [
                -53.75482129512503,
                -32.077467427610316
              ],
              [
                -53.760273764980965,
                -32.077761913317396
              ],
              [
                -53.76213824719371,
                -32.077921922942615
              ],
              [
                -53.76511023303004,
                -32.078176956097806
              ],
              [
                -53.76616475142819,
                -32.07669247112986
              ],
              [
                -53.76832471423903,
                -32.07365247758043
              ],
              [
                -53.77239569193512,
                -32.07245497888261
              ],
              [
                -53.78078261831603,
                -32.0702410411113
              ],
              [
                -53.784641584821735,
                -32.07105106676547
              ],
              [
                -53.784743628882865,
                -32.07107254215291
              ],
              [
                -53.788900093566895,
                -32.07094655129469
              ],
              [
                -53.78879208930943,
                -32.06978408557409
              ],
              [
                -53.7886236083992,
                -32.06796856113525
              ],
              [
                -53.79269657231306,
                -32.068592089149554
              ],
              [
                -53.79931450668105,
                -32.069605614172985
              ],
              [
                -53.80253145754896,
                -32.06919213396137
              ],
              [
                -53.806538932584935,
                -32.067858143315185
              ],
              [
                -53.80961394443793,
                -32.06493668240796
              ],
              [
                -53.81400494003068,
                -32.059954683009906
              ],
              [
                -53.82057785217748,
                -32.059888710388144
              ],
              [
                -53.8256078553565,
                -32.059018251039284
              ],
              [
                -53.83271029067141,
                -32.05416227698324
              ],
              [
                -53.833286833053144,
                -32.05062130980523
              ],
              [
                -53.83350185276064,
                -32.046158286250595
              ],
              [
                -53.833641380174406,
                -32.04370430163643
              ],
              [
                -53.833741362888986,
                -32.04192832125416
              ],
              [
                -53.8378333674477,
                -32.03972035002724
              ],
              [
                -53.841262822153624,
                -32.03787036011163
              ],
              [
                -53.83965236492793,
                -32.036013848577326
              ],
              [
                -53.837113411404665,
                -32.03308735258385
              ],
              [
                -53.84164885214017,
                -32.031944858744986
              ],
              [
                -53.851359812495765,
                -32.02949840619625
              ],
              [
                -53.85236735477558,
                -32.02082693897552
              ],
              [
                -53.85264139158598,
                -32.01389346851418
              ],
              [
                -53.852928417368894,
                -32.00982198035739
              ],
              [
                -53.85275139474632,
                -32.00931898778557
              ],
              [
                -53.85105996057081,
                -32.004512472880954
              ],
              [
                -53.84946546704761,
                -32.000818462768954
              ],
              [
                -53.85848393330061,
                -31.995936515981168
              ],
              [
                -53.863196908973926,
                -31.993384070223648
              ],
              [
                -53.869095306451,
                -31.997739058238313
              ],
              [
                -53.8719073295677,
                -31.99732807727126
              ],
              [
                -53.87799027356695,
                -31.996438090467056
              ],
              [
                -53.88421823187306,
                -31.99530260686543
              ],
              [
                -53.890317177833886,
                -31.99355364515837
              ],
              [
                -53.894877696274555,
                -31.984308712535874
              ],
              [
                -53.89504067546262,
                -31.98388868822622
              ],
              [
                -53.89602421860625,
                -31.98135823041703
              ],
              [
                -53.89867519401221,
                -31.9797622105547
              ],
              [
                -53.902272660276886,
                -31.977935722295943
              ],
              [
                -53.908511617188,
                -31.973808765795567
              ],
              [
                -53.9120776646419,
                -31.966571824169876
              ],
              [
                -53.91508862024907,
                -31.966153805069474
              ],
              [
                -53.92058308888372,
                -31.9649388515015
              ],
              [
                -53.92226208264098,
                -31.96458985552615
              ],
              [
                -53.92443155811791,
                -31.964138355896374
              ],
              [
                -53.93171901043586,
                -31.96294439997046
              ],
              [
                -53.940983450936024,
                -31.957712953047093
              ],
              [
                -53.94699893817869,
                -31.955869986195893
              ],
              [
                -53.950531911030865,
                -31.95776347688752
              ],
              [
                -53.958370330841205,
                -31.955075533849655
              ],
              [
                -53.96334982911113,
                -31.950554062867187
              ],
              [
                -53.97134280218923,
                -31.941176087256498
              ],
              [
                -53.968864822322075,
                -31.937018098431373
              ],
              [
                -53.966365395101874,
                -31.935491584312675
              ],
              [
                -53.96549288264324,
                -31.934535607826014
              ],
              [
                -53.96407339105065,
                -31.932979586409918
              ],
              [
                -53.962423426474665,
                -31.930542599085506
              ],
              [
                -53.96284048037488,
                -31.923043607573202
              ],
              [
                -53.96843592269869,
                -31.922433656999008
              ],
              [
                -53.971665403655805,
                -31.918275170658287
              ],
              [
                -53.97571238255731,
                -31.917780657173484
              ],
              [
                -53.978622368590955,
                -31.917432700469185
              ],
              [
                -53.98534383743971,
                -31.916752703888797
              ],
              [
                -53.991602791575254,
                -31.91596725657591
              ],
              [
                -53.995635730638746,
                -31.91563626864814
              ],
              [
                -53.9995747224036,
                -31.91531277030063
              ],
              [
                -54.003378218271656,
                -31.914984309384412
              ],
              [
                -54.004653189521356,
                -31.913062318382178
              ],
              [
                -54.00605467189649,
                -31.910949295372795
              ],
              [
                -54.011558667428986,
                -31.90771934558693
              ],
              [
                -54.01437366816147,
                -31.906365339848684
              ],
              [
                -54.0155271641506,
                -31.901595395039074
              ],
              [
                -54.02098860249537,
                -31.90151090665389
              ],
              [
                -54.0251126165262,
                -31.89784241698263
              ],
              [
                -54.02986156758478,
                -31.897235916773415
              ],
              [
                -54.038903502834934,
                -31.897292501411346
              ],
              [
                -54.04099047631137,
                -31.899675487748684
              ],
              [
                -54.043128945003026,
                -31.902291983759422
              ],
              [
                -54.045604896730076,
                -31.90444997929958
              ],
              [
                -54.0501218500565,
                -31.905468504279018
              ],
              [
                -54.0553668224819,
                -31.909735514744035
              ],
              [
                -54.06024225269185,
                -31.915719520597246
              ],
              [
                -54.06098772041086,
                -31.916634035647412
              ],
              [
                -54.06732763275015,
                -31.92114303226762
              ],
              [
                -54.070938640359124,
                -31.92027606580538
              ],
              [
                -54.07768603188032,
                -31.926242052770498
              ],
              [
                -54.080327498226886,
                -31.92891854283409
              ],
              [
                -54.08274947700359,
                -31.930460566618848
              ],
              [
                -54.08539746478618,
                -31.932546052101678
              ],
              [
                -54.09100389926854,
                -31.93089861904605
              ],
              [
                -54.09631136190714,
                -31.928778610814014
              ],
              [
                -54.099898345227245,
                -31.927318630644216
              ],
              [
                -54.10251736872044,
                -31.92153465545187
              ],
              [
                -54.10498584508734,
                -31.916819208109274
              ],
              [
                -54.107035861469804,
                -31.911473723448136
              ],
              [
                -54.107780907060416,
                -31.90953073820448
              ],
              [
                -54.11350735193565,
                -31.90630474435976
              ],
              [
                -54.116540827444176,
                -31.904706780132905
              ],
              [
                -54.119494329313284,
                -31.903493790659624
              ],
              [
                -54.119701806235206,
                -31.90339925477221
              ],
              [
                -54.13248624385514,
                -31.897568349340208
              ],
              [
                -54.13979773560009,
                -31.893946375226676
              ],
              [
                -54.14041373624868,
                -31.890979395826776
              ],
              [
                -54.141192241268136,
                -31.887223385975553
              ],
              [
                -54.141597755739916,
                -31.886092916367364
              ],
              [
                -54.142706239236546,
                -31.883001430375977
              ],
              [
                -54.145730754732476,
                -31.880571926057705
              ],
              [
                -54.14860972162608,
                -31.87877845562268
              ],
              [
                -54.151351188385306,
                -31.876852476907885
              ],
              [
                -54.155189220763276,
                -31.873865513388132
              ],
              [
                -54.16639516323858,
                -31.865599550873476
              ],
              [
                -54.168971617755695,
                -31.863766553230708
              ],
              [
                -54.17154814327284,
                -31.86193360458796
              ],
              [
                -54.45386450220202,
                -31.652141222891576
              ],
              [
                -54.45305651799931,
                -31.64864172362686
              ],
              [
                -54.45214009483687,
                -31.644254198981045
              ],
              [
                -54.45122809082439,
                -31.641201231229868
              ],
              [
                -54.45201961869709,
                -31.637945225252107
              ],
              [
                -54.452886623348085,
                -31.635138771544522
              ],
              [
                -54.45352465335452,
                -31.630525274094744
              ],
              [
                -54.45676659197648,
                -31.62934079475365
              ],
              [
                -54.45967612269275,
                -31.627045289594413
              ],
              [
                -54.463381117675446,
                -31.619709344661718
              ],
              [
                -54.46700565554846,
                -31.61215586489725
              ],
              [
                -54.46400414610287,
                -31.60922385993104
              ],
              [
                -54.459497208051,
                -31.607378817974563
              ],
              [
                -54.45756871771233,
                -31.606589313576023
              ],
              [
                -54.45605828076617,
                -31.60346232645699
              ],
              [
                -54.45229930822126,
                -31.60361282602063
              ],
              [
                -54.45336879142377,
                -31.599923325264665
              ],
              [
                -54.4589977777858,
                -31.59422336773852
              ],
              [
                -54.462366769399196,
                -31.590815379158208
              ],
              [
                -54.46748074397064,
                -31.592448388197592
              ],
              [
                -54.46858173732683,
                -31.592800406080777
              ],
              [
                -54.46927474730286,
                -31.5894809178434
              ],
              [
                -54.46947475732988,
                -31.58852391994741
              ],
              [
                -54.472645793989145,
                -31.575434959271952
              ],
              [
                -54.473756815774195,
                -31.570386986892075
              ],
              [
                -54.4801907331656,
                -31.569948492433728
              ],
              [
                -54.4818247285632,
                -31.568725029296996
              ],
              [
                -54.48661171515924,
                -31.56513954219486
              ],
              [
                -54.488934709139016,
                -31.56323858159642
              ],
              [
                -54.49266373035842,
                -31.559074566329635
              ],
              [
                -54.49156022220872,
                -31.555087095987936
              ],
              [
                -54.490740756852546,
                -31.553156114026386
              ],
              [
                -54.490296242411524,
                -31.552110602585586
              ],
              [
                -54.49748425730509,
                -31.539943666933297
              ],
              [
                -54.49921576701565,
                -31.53750615506895
              ],
              [
                -54.50175576608638,
                -31.532723693178585
              ],
              [
                -54.50909027235062,
                -31.520978763164514
              ],
              [
                -54.51314879807988,
                -31.513877789223084
              ],
              [
                -54.51765825411023,
                -31.510588782230137
              ],
              [
                -54.52012225349117,
                -31.50894532162477
              ],
              [
                -54.52363471360285,
                -31.50921082600208
              ],
              [
                -54.52368622117855,
                -31.50921484121264
              ],
              [
                -54.52370022330528,
                -31.509174333367774
              ],
              [
                -54.52470822143603,
                -31.506256830543208
              ],
              [
                -54.53292071503244,
                -31.50152787475464
              ],
              [
                -54.53533467973252,
                -31.497490907570413
              ],
              [
                -54.53935465248673,
                -31.496797445371506
              ],
              [
                -54.54292016986928,
                -31.49540094488723
              ],
              [
                -54.54585513388439,
                -31.49250447224113
              ],
              [
                -54.55211157891477,
                -31.49341596081639
              ],
              [
                -54.55212110782884,
                -31.493417992852294
              ],
              [
                -54.5565755544745,
                -31.494570987165766
              ],
              [
                -54.55966055778834,
                -31.489877029387692
              ],
              [
                -54.56870900676687,
                -31.483629586734896
              ],
              [
                -54.57386349069463,
                -31.481910112795415
              ],
              [
                -54.57558996629873,
                -31.478466630270667
              ],
              [
                -54.578617980644204,
                -31.471951149533727
              ],
              [
                -54.580617004108376,
                -31.467650162192555
              ],
              [
                -54.582275987876535,
                -31.464095188644222
              ],
              [
                -54.58416700758739,
                -31.460755691579738
              ],
              [
                -54.584899522674164,
                -31.457695708911903
              ],
              [
                -54.62979967628941,
                -31.45592086881465
              ],
              [
                -54.669222892467964,
                -31.454362536906253
              ],
              [
                -54.67237485642741,
                -31.452573562571448
              ],
              [
                -54.6748118322093,
                -31.450393072098937
              ],
              [
                -54.67857834639289,
                -31.446886580417114
              ],
              [
                -54.68247932174753,
                -31.444547600571546
              ],
              [
                -54.68943074860125,
                -31.444764123769993
              ],
              [
                -54.69425823483717,
                -31.444823654262013
              ],
              [
                -54.70056670668016,
                -31.43661369846318
              ],
              [
                -54.71031963200532,
                -31.439556215250363
              ],
              [
                -54.71340460707606,
                -31.439389257827575
              ],
              [
                -54.718413061773845,
                -31.439991789825807
              ],
              [
                -54.72140205863817,
                -31.434313779897117
              ],
              [
                -54.72225857766102,
                -31.43248079085052
              ],
              [
                -54.72298005104923,
                -31.430936785550045
              ],
              [
                -54.73323852684101,
                -31.428660840725303
              ],
              [
                -54.737448962040986,
                -31.427835370654623
              ],
              [
                -54.741029468964,
                -31.428068897413247
              ],
              [
                -54.74801740169123,
                -31.427755922018203
              ],
              [
                -54.757981817981324,
                -31.425105963816602
              ],
              [
                -54.7631717862565,
                -31.426234992366837
              ],
              [
                -54.76384326226152,
                -31.426372456916607
              ],
              [
                -54.774122692512876,
                -31.42847350796466
              ],
              [
                -54.781742637793805,
                -31.431529054420352
              ],
              [
                -54.78853455548466,
                -31.43303757395404
              ],
              [
                -54.79757851123012,
                -31.431373604529732
              ],
              [
                -54.79792700994584,
                -31.431303077185348
              ],
              [
                -54.80432493096318,
                -31.430017135559275
              ],
              [
                -54.806274420008755,
                -31.431371646742168
              ],
              [
                -54.81059788209724,
                -31.434376165239303
              ],
              [
                -54.82658122728263,
                -31.436313189139494
              ],
              [
                -54.831328687683786,
                -31.43921720570342
              ],
              [
                -54.83525612745724,
                -31.441428209374305
              ],
              [
                -54.843482132126795,
                -31.432805795659743
              ],
              [
                -54.845697610222636,
                -31.430022303622533
              ],
              [
                -54.8531580980079,
                -31.42222385971051
              ],
              [
                -54.855994091136516,
                -31.417752374258033
              ],
              [
                -54.85956409135516,
                -31.417617863852428
              ],
              [
                -54.85797813950311,
                -31.412135880825495
              ],
              [
                -54.85673714903235,
                -31.407847893500247
              ],
              [
                -54.86291962720064,
                -31.40408390672263
              ],
              [
                -54.86709059401197,
                -31.402019920377406
              ],
              [
                -54.867602585302,
                -31.401806443065393
              ],
              [
                -54.877071532153515,
                -31.397864970762146
              ],
              [
                -54.879817524522906,
                -31.39329852214439
              ],
              [
                -54.88266254209376,
                -31.38856555233853
              ],
              [
                -54.88363253330727,
                -31.38517753137906
              ],
              [
                -54.8849270757234,
                -31.38166757209296
              ],
              [
                -54.889533053255306,
                -31.375635078848976
              ],
              [
                -54.898484488974255,
                -31.376538641035015
              ],
              [
                -54.89684398454188,
                -31.381198123167525
              ],
              [
                -54.89705947735401,
                -31.381288628878778
              ],
              [
                -54.903243925534895,
                -31.383891116283156
              ],
              [
                -54.91329534346595,
                -31.380894165268135
              ],
              [
                -54.91492583014124,
                -31.380508195143356
              ],
              [
                -54.919931325454385,
                -31.379322212322645
              ],
              [
                -54.92248627458991,
                -31.38072770598882
              ],
              [
                -54.92650923471231,
                -31.382941236061846
              ],
              [
                -54.93011671891841,
                -31.38362774690836
              ],
              [
                -54.93494315368132,
                -31.383291743350092
              ],
              [
                -54.93792315484307,
                -31.380691757147456
              ],
              [
                -54.937400209882945,
                -31.374671292898825
              ],
              [
                -54.93679671903861,
                -31.37355226891687
              ],
              [
                -54.93431023898545,
                -31.368943802990177
              ],
              [
                -54.93383628329976,
                -31.365904788029876
              ],
              [
                -54.93401981549585,
                -31.358915804074254
              ],
              [
                -54.93341034162988,
                -31.35539731665533
              ],
              [
                -54.93702131333538,
                -31.35289732891951
              ],
              [
                -54.94065830317015,
                -31.35378435141264
              ],
              [
                -54.9468462152852,
                -31.35227437839622
              ],
              [
                -54.94760225253782,
                -31.352089371060856
              ],
              [
                -54.95018570066472,
                -31.350536393725047
              ],
              [
                -54.9515872019951,
                -31.353441894963016
              ],
              [
                -54.95494369127056,
                -31.351699898374008
              ],
              [
                -54.95843364847743,
                -31.351342434136534
              ],
              [
                -54.95915169482696,
                -31.34585445097022
              ],
              [
                -54.958806685131215,
                -31.34195443456608
              ],
              [
                -54.95467674417849,
                -31.340188437010976
              ],
              [
                -54.95187028375139,
                -31.338988426801414
              ],
              [
                -54.95319080997379,
                -31.330651459858082
              ],
              [
                -54.95728883136437,
                -31.32325150061289
              ],
              [
                -54.9627008090965,
                -31.31991000353171
              ],
              [
                -54.96953923338775,
                -31.317511556351267
              ],
              [
                -54.97390922365789,
                -31.31597856060266
              ],
              [
                -54.97392119954836,
                -31.315981559646882
              ],
              [
                -54.97792469500125,
                -31.316800072857248
              ],
              [
                -54.98022767676931,
                -31.311372116339065
              ],
              [
                -54.978831746898635,
                -31.304061126398622
              ],
              [
                -54.97849227480226,
                -31.302285630084093
              ],
              [
                -54.98156827336933,
                -31.295863133193873
              ],
              [
                -54.9869732685914,
                -31.2922761986481
              ],
              [
                -54.98751326051954,
                -31.29191719679201
              ],
              [
                -54.99372422731297,
                -31.28837119759345
              ],
              [
                -54.99364421294613,
                -31.286124240487272
              ],
              [
                -54.99352526416512,
                -31.28279174474571
              ],
              [
                -54.99798269607436,
                -31.282071235468315
              ],
              [
                -54.99989572361927,
                -31.274776284643796
              ],
              [
                -55.00592120279003,
                -31.2677853336791
              ],
              [
                -55.01033417301749,
                -31.26845283097206
              ],
              [
                -55.01482866763839,
                -31.26790836643949
              ],
              [
                -55.01893664116054,
                -31.267030854032384
              ],
              [
                -55.02228858619337,
                -31.268104876849566
              ],
              [
                -55.026040576335674,
                -31.268456877059165
              ],
              [
                -55.03057352622256,
                -31.26954439089419
              ],
              [
                -55.035255452241536,
                -31.27507292103346
              ],
              [
                -55.030477994267365,
                -31.278467364717006
              ],
              [
                -55.034523401707816,
                -31.28355588517077
              ],
              [
                -55.03273191001845,
                -31.28656637751122
              ],
              [
                -55.03238740429689,
                -31.287664893483953
              ],
              [
                -55.031778895745056,
                -31.289601363379695
              ],
              [
                -55.034542868607296,
                -31.29234484981923
              ],
              [
                -55.03790280115327,
                -31.29900837570327
              ],
              [
                -55.04128275489975,
                -31.305608341833167
              ],
              [
                -55.046385177843334,
                -31.30765288191618
              ],
              [
                -55.0510061407815,
                -31.31188590886714
              ],
              [
                -55.057365062473615,
                -31.31455090390044
              ],
              [
                -55.05663403941022,
                -31.32207139633996
              ],
              [
                -55.060507010383716,
                -31.324357874622628
              ],
              [
                -55.05934044509717,
                -31.33111185098475
              ],
              [
                -55.0634049522384,
                -31.33070639132833
              ],
              [
                -55.07324038493047,
                -31.331873436748246
              ],
              [
                -55.07960585625423,
                -31.32685096663185
              ],
              [
                -55.08929428675214,
                -31.3201640145955
              ],
              [
                -55.097706254779084,
                -31.31455758158954
              ],
              [
                -55.09776626546104,
                -31.314533075903235
              ],
              [
                -55.10164073797917,
                -31.312942069175453
              ],
              [
                -55.106923717729636,
                -31.309279622270406
              ],
              [
                -55.112470688231696,
                -31.309615626247613
              ],
              [
                -55.11291368695962,
                -31.30976614279673
              ],
              [
                -55.117514598615664,
                -31.311325157897514
              ],
              [
                -55.120920588810435,
                -31.312475672827237
              ],
              [
                -55.122433104962774,
                -31.30976515456986
              ],
              [
                -55.12583860531722,
                -31.303660688264944
              ],
              [
                -55.12615010417044,
                -31.30310268988888
              ],
              [
                -55.12674562780269,
                -31.302035694905758
              ],
              [
                -55.130038599967975,
                -31.301620737967372
              ],
              [
                -55.13535453810994,
                -31.300950764282717
              ],
              [
                -55.14479647445419,
                -31.295319803732117
              ],
              [
                -55.150998967758575,
                -31.292766328195423
              ],
              [
                -55.15482098900608,
                -31.285959380289928
              ],
              [
                -55.155359449452426,
                -31.284999849812827
              ],
              [
                -55.155966452683444,
                -31.28502035336478
              ],
              [
                -55.162155419077436,
                -31.285226900391027
              ],
              [
                -55.164001944552915,
                -31.280642398888972
              ],
              [
                -55.162597430443014,
                -31.279585907315866
              ],
              [
                -55.16171344507662,
                -31.27892039306859
              ],
              [
                -55.16361344861819,
                -31.275286414601663
              ],
              [
                -55.16777296129779,
                -31.270886925577855
              ],
              [
                -55.173250895861685,
                -31.26838697280739
              ],
              [
                -55.178298359982506,
                -31.26877247852651
              ],
              [
                -55.18187839111002,
                -31.26592352643613
              ],
              [
                -55.192622786058486,
                -31.263652059685406
              ],
              [
                -55.19856825009683,
                -31.26269860034462
              ],
              [
                -55.19993025949114,
                -31.26255807350114
              ],
              [
                -55.21076466509303,
                -31.26144014647741
              ],
              [
                -55.22626154684347,
                -31.259326224724607
              ],
              [
                -55.23332147147346,
                -31.259565721413253
              ],
              [
                -55.23789244522063,
                -31.259963250078314
              ],
              [
                -55.23835146529348,
                -31.25676226142135
              ],
              [
                -55.23887899631593,
                -31.25347926724792
              ],
              [
                -55.238963515728344,
                -31.25295227182472
              ],
              [
                -55.23917000664912,
                -31.252866254907442
              ],
              [
                -55.24274295100651,
                -31.251372811653567
              ],
              [
                -55.24718743217158,
                -31.246524799864304
              ],
              [
                -55.24739001099527,
                -31.24111732119872
              ],
              [
                -55.2470305637587,
                -31.22749086509447
              ],
              [
                -55.246969599406754,
                -31.22366385566701
              ],
              [
                -55.24696909161628,
                -31.223628366746745
              ],
              [
                -55.24712708759733,
                -31.219281873458105
              ],
              [
                -55.24727261430884,
                -31.215687879380603
              ],
              [
                -55.2510876099983,
                -31.212264402611982
              ],
              [
                -55.24908063966562,
                -31.20599043251946
              ],
              [
                -55.254678645694185,
                -31.200846461353926
              ],
              [
                -55.26176214095959,
                -31.194393503566086
              ],
              [
                -55.2657560631055,
                -31.19462851810703
              ],
              [
                -55.26587960720278,
                -31.19462152265169
              ],
              [
                -55.27112307188926,
                -31.194325040766888
              ],
              [
                -55.27693750004588,
                -31.194059073255666
              ],
              [
                -55.280993972461836,
                -31.193648564558394
              ],
              [
                -55.283950997485505,
                -31.18473612278863
              ],
              [
                -55.27973806711838,
                -31.182406101151475
              ],
              [
                -55.275007581702404,
                -31.17921157230357
              ],
              [
                -55.27482810565642,
                -31.179110575769577
              ],
              [
                -55.27005364064773,
                -31.17642206057017
              ],
              [
                -55.27341465637178,
                -31.170132119487132
              ],
              [
                -55.27985410437671,
                -31.167686141673748
              ],
              [
                -55.281307633669044,
                -31.166928139640724
              ],
              [
                -55.284235108156665,
                -31.165402177684868
              ],
              [
                -55.28800658188416,
                -31.163413198407618
              ],
              [
                -55.28758259130592,
                -31.15940218487087
              ],
              [
                -55.28618411246753,
                -31.157759699692345
              ],
              [
                -55.28431165146036,
                -31.155560679776798
              ],
              [
                -55.286540639526464,
                -31.151049219742642
              ],
              [
                -55.288010646557474,
                -31.14823770153138
              ],
              [
                -55.28961419548612,
                -31.143016719468605
              ],
              [
                -55.29533063640581,
                -31.14292777810008
              ],
              [
                -55.299034088387764,
                -31.14202377601729
              ],
              [
                -55.30465657626348,
                -31.142414308140516
              ],
              [
                -55.30661953455979,
                -31.143753800431274
              ],
              [
                -55.30996051391904,
                -31.146033297439523
              ],
              [
                -55.316941464912524,
                -31.144439836962576
              ],
              [
                -55.31809547974696,
                -31.140003846149934
              ],
              [
                -55.321566479935804,
                -31.136084390046555
              ],
              [
                -55.325353946858144,
                -31.13716041274639
              ],
              [
                -55.33766384980936,
                -31.132185466858576
              ],
              [
                -55.33703090323763,
                -31.128271466198807
              ],
              [
                -55.33655241702318,
                -31.125476473614867
              ],
              [
                -55.33534794272805,
                -31.11932246869851
              ],
              [
                -55.331543975379454,
                -31.117472963725316
              ],
              [
                -55.329735517418555,
                -31.112575456333353
              ],
              [
                -55.33196555499687,
                -31.103290519353003
              ],
              [
                -55.33094411959322,
                -31.097252991975324
              ],
              [
                -55.33058861242553,
                -31.095150011329785
              ],
              [
                -55.328999154455566,
                -31.092734520141654
              ],
              [
                -55.3264336837487,
                -31.087919026353543
              ],
              [
                -55.330922645293,
                -31.086230549427697
              ],
              [
                -55.338044624168134,
                -31.08465056448865
              ],
              [
                -55.34503011613242,
                -31.073864630303873
              ],
              [
                -55.347252135180284,
                -31.071093648210322
              ],
              [
                -55.349060132668136,
                -31.0674041604798
              ],
              [
                -55.34939162016447,
                -31.06774813409664
              ],
              [
                -55.351466079982956,
                -31.069900158959523
              ],
              [
                -55.3569735670184,
                -31.066784169684773
              ],
              [
                -55.355891599272866,
                -31.06313367053066
              ],
              [
                -55.35353011577148,
                -31.061131174098584
              ],
              [
                -55.353186601307804,
                -31.060276158616
              ],
              [
                -55.35182565219787,
                -31.056882687681806
              ],
              [
                -55.351264181627585,
                -31.051121212655957
              ],
              [
                -55.34981879167343,
                -31.03737872340235
              ],
              [
                -55.358000203097575,
                -31.039697750899503
              ],
              [
                -55.361896174901624,
                -31.037882760725843
              ],
              [
                -55.36291915593383,
                -31.03721976562707
              ],
              [
                -55.36740165954319,
                -31.034315789481752
              ],
              [
                -55.369656644423344,
                -31.030658822586155
              ],
              [
                -55.369934147565125,
                -31.029919302486164
              ],
              [
                -55.370816165363294,
                -31.027570312700508
              ],
              [
                -55.37318620493512,
                -31.01894235684127
              ],
              [
                -55.37603663560081,
                -31.021319881469708
              ],
              [
                -55.379381608423095,
                -31.02318987338768
              ],
              [
                -55.385431606104326,
                -31.02063789010464
              ],
              [
                -55.390392017029704,
                -31.0238339148375
              ],
              [
                -55.39253999119932,
                -31.02496541137191
              ],
              [
                -55.39618146848739,
                -31.02688493644914
              ],
              [
                -55.399340959687,
                -31.027717964992448
              ],
              [
                -55.40394592039184,
                -31.0269014745258
              ],
              [
                -55.40557542804371,
                -31.018200489648173
              ],
              [
                -55.41471790721641,
                -31.01426553775804
              ],
              [
                -55.42038082229056,
                -31.015642566715627
              ],
              [
                -55.42441833768651,
                -31.011284093035446
              ],
              [
                -55.428729316096856,
                -31.008997582718642
              ],
              [
                -55.43603129788035,
                -31.003914155637183
              ],
              [
                -55.43498684687411,
                -30.99328615182181
              ],
              [
                -55.430732402132854,
                -30.992247646095134
              ],
              [
                -55.43063389936877,
                -30.992162646872423
              ],
              [
                -55.428525400826956,
                -30.99034264010777
              ],
              [
                -55.42517196688319,
                -30.98518765378024
              ],
              [
                -55.42924348097144,
                -30.977349180327547
              ],
              [
                -55.432377457780994,
                -30.972366223250955
              ],
              [
                -55.43712792612852,
                -30.97212274805459
              ],
              [
                -55.437960986139394,
                -30.965267252523944
              ],
              [
                -55.4399429669945,
                -30.96040426526247
              ],
              [
                -55.45086340141739,
                -30.959660794503627
              ],
              [
                -55.45624390266659,
                -30.95075785109584
              ],
              [
                -55.46142137015095,
                -30.951674353018795
              ],
              [
                -55.46616183739934,
                -30.952512865264154
              ],
              [
                -55.47708823323169,
                -30.95309442446155
              ],
              [
                -55.48551971913154,
                -30.94685199586191
              ],
              [
                -55.48870522595303,
                -30.938487517836624
              ],
              [
                -55.487784744153174,
                -30.934959026104874
              ],
              [
                -55.48744574643625,
                -30.933612998785506
              ],
              [
                -55.4856983063997,
                -30.926680535440983
              ],
              [
                -55.488281290678025,
                -30.924787042835852
              ],
              [
                -55.49242578325508,
                -30.924617045876488
              ],
              [
                -55.4958107206692,
                -30.924956570500903
              ],
              [
                -55.49715974715573,
                -30.92500808412558
              ],
              [
                -55.499110231952436,
                -30.925083096256937
              ],
              [
                -55.50369221592209,
                -30.921824125331902
              ],
              [
                -55.50535370162929,
                -30.916711134274706
              ],
              [
                -55.507660745814796,
                -30.90961217357921
              ],
              [
                -55.508330222629255,
                -30.90596467890019
              ],
              [
                -55.50840526531581,
                -30.902251650844843
              ],
              [
                -55.52274965618278,
                -30.898330224004486
              ],
              [
                -55.52416716269491,
                -30.898637744323914
              ],
              [
                -55.52615914114442,
                -30.8990702717986
              ],
              [
                -55.531872610179505,
                -30.90111929136442
              ],
              [
                -55.532894605521456,
                -30.897263300663734
              ],
              [
                -55.53683258400419,
                -30.894070305837175
              ],
              [
                -55.544584025190034,
                -30.890082849085694
              ],
              [
                -55.54876105882781,
                -30.882877862585605
              ],
              [
                -55.54964056077353,
                -30.880850875033616
              ],
              [
                -55.549938569447136,
                -30.880164398895396
              ],
              [
                -55.55270554630842,
                -30.873908416193814
              ],
              [
                -55.556020039018044,
                -30.87185042207536
              ],
              [
                -55.56228003892769,
                -30.867962461737804
              ],
              [
                -55.56218255615144,
                -30.863154990485747
              ],
              [
                -55.56217807137155,
                -30.862947492948514
              ],
              [
                -55.56221407699208,
                -30.85701797587453
              ],
              [
                -55.56262310436712,
                -30.853261526342127
              ],
              [
                -55.56382260589173,
                -30.85053300478406
              ],
              [
                -55.56582763509196,
                -30.845745050438587
              ],
              [
                -55.569940611150265,
                -30.84449555983254
              ],
              [
                -55.5765495722156,
                -30.840119089106828
              ],
              [
                -55.576617575559894,
                -30.836992101663405
              ],
              [
                -55.576558107484104,
                -30.833231116153367
              ],
              [
                -55.582384554356615,
                -30.835178151399976
              ],
              [
                -55.58620899039394,
                -30.841319628395492
              ],
              [
                -55.59508740691305,
                -30.845420169639624
              ],
              [
                -55.59950533798179,
                -30.847269652142984
              ],
              [
                -55.60362129704886,
                -30.84899366165615
              ],
              [
                -55.60838077252498,
                -30.84752220232001
              ],
              [
                -55.609522255922194,
                -30.847169690994484
              ],
              [
                -55.62081218231514,
                -30.84373826399018
              ],
              [
                -55.62510965167858,
                -30.846572767709716
              ],
              [
                -55.627441122742006,
                -30.84890626522691
              ],
              [
                -55.6361110573636,
                -30.84714982419779
              ],
              [
                -55.6440324861823,
                -30.85439134316124
              ],
              [
                -55.64371897018202,
                -30.85882482357089
              ],
              [
                -55.648020907720834,
                -30.864067832769724
              ],
              [
                -55.654865852844054,
                -30.864633854618454
              ],
              [
                -55.659603794050916,
                -30.86883535109773
              ],
              [
                -55.6622327523059,
                -30.872836828060368
              ],
              [
                -55.6584752265104,
                -30.876352805931383
              ],
              [
                -55.65358475197626,
                -30.88131529763429
              ],
              [
                -55.651885749917696,
                -30.8830392904138
              ],
              [
                -55.65425873749631,
                -30.885049267888768
              ],
              [
                -55.654113222488014,
                -30.887859760783172
              ],
              [
                -55.65402072289513,
                -30.889655281246263
              ],
              [
                -55.6529516731844,
                -30.895198761901277
              ],
              [
                -55.652456704817304,
                -30.8959142621407
              ],
              [
                -55.65094869369233,
                -30.898095252681003
              ],
              [
                -55.64904166379168,
                -30.90145970979081
              ],
              [
                -55.64739267248695,
                -30.904031698828437
              ],
              [
                -55.64159419973857,
                -30.910746695618034
              ],
              [
                -55.64509161254999,
                -30.918377684947977
              ],
              [
                -55.64918657703263,
                -30.922141677747963
              ],
              [
                -55.64993956529054,
                -30.922500183135167
              ],
              [
                -55.65492801203222,
                -30.92486971096549
              ],
              [
                -55.65916796017578,
                -30.93163518649301
              ],
              [
                -55.660807431128404,
                -30.93645217141298
              ],
              [
                -55.66001541066902,
                -30.93809466925503
              ],
              [
                -55.65887990771037,
                -30.940449173993684
              ],
              [
                -55.65825286026539,
                -30.94448217404516
              ],
              [
                -55.66099837436117,
                -30.947644647501136
              ],
              [
                -55.664713267135504,
                -30.95202466530503
              ],
              [
                -55.670073252188246,
                -30.95454416641528
              ],
              [
                -55.675826701297844,
                -30.95549471581279
              ],
              [
                -55.686700639056006,
                -30.952050738172616
              ],
              [
                -55.687351148534106,
                -30.94868026968421
              ],
              [
                -55.68812963985937,
                -30.94464626626637
              ],
              [
                -55.691607651150264,
                -30.94403279253133
              ],
              [
                -55.694526081953974,
                -30.944412316127917
              ],
              [
                -55.697939056501184,
                -30.948534820254135
              ],
              [
                -55.698272556587234,
                -30.948951804722917
              ],
              [
                -55.702349014494395,
                -30.954052827449303
              ],
              [
                -55.70867744802034,
                -30.954008823596993
              ],
              [
                -55.71064846831573,
                -30.951026829852893
              ],
              [
                -55.71625547083437,
                -30.942544883249713
              ],
              [
                -55.72169240574881,
                -30.942735923045362
              ],
              [
                -55.72072137078676,
                -30.947825887235958
              ],
              [
                -55.72161535873295,
                -30.953441397198215
              ],
              [
                -55.722875280859554,
                -30.960976377338508
              ],
              [
                -55.723938274678304,
                -30.965867888693346
              ],
              [
                -55.724115239667796,
                -30.966679850592936
              ],
              [
                -55.72648422256453,
                -30.968805374863546
              ],
              [
                -55.72879068921124,
                -30.972097379202527
              ],
              [
                -55.72774117074848,
                -30.979104355699096
              ],
              [
                -55.73216012497603,
                -30.979703866235678
              ],
              [
                -55.73765659496553,
                -30.97997689513024
              ],
              [
                -55.73962858353237,
                -30.980075416343446
              ],
              [
                -55.74360303495713,
                -30.980355924710565
              ],
              [
                -55.74538997514721,
                -30.985444901743445
              ],
              [
                -55.74607397241086,
                -30.986932893275963
              ],
              [
                -55.7487689532864,
                -30.992794893441534
              ],
              [
                -55.75279039482692,
                -30.99784690714256
              ],
              [
                -55.757096838548776,
                -30.997815917654098
              ],
              [
                -55.75939080256239,
                -30.997936939201978
              ],
              [
                -55.76120233035665,
                -30.99803295774109
              ],
              [
                -55.76556575282396,
                -30.997452938749294
              ],
              [
                -55.77022823203991,
                -30.997392981854677
              ],
              [
                -55.77055571598008,
                -31.000613974923894
              ],
              [
                -55.7693491948382,
                -31.0044649379896
              ],
              [
                -55.767585178640346,
                -31.010091416627493
              ],
              [
                -55.77419511513644,
                -31.01350143919265
              ],
              [
                -55.77889358778181,
                -31.015397487016727
              ],
              [
                -55.79450297000135,
                -31.01525852223729
              ],
              [
                -55.79623493079828,
                -31.015286031599203
              ],
              [
                -55.806769878503516,
                -31.015452592384914
              ],
              [
                -55.81083782771044,
                -31.01530811415415
              ],
              [
                -55.81148028856359,
                -31.02072410762028
              ],
              [
                -55.81366975288893,
                -31.024561591306142
              ],
              [
                -55.814448243874764,
                -31.025452595624646
              ],
              [
                -55.81739020624472,
                -31.02882056760844
              ],
              [
                -55.824270130072236,
                -31.03787260461837
              ],
              [
                -55.82919155957588,
                -31.043305089441635
              ],
              [
                -55.83678500937995,
                -31.04244813297396
              ],
              [
                -55.84027746038131,
                -31.04239866507744
              ],
              [
                -55.84704594000159,
                -31.04230269335061
              ],
              [
                -55.85114636266004,
                -31.043595667031806
              ],
              [
                -55.85426833106846,
                -31.044649200055243
              ],
              [
                -55.856996297261844,
                -31.049186194519265
              ],
              [
                -55.860645224171556,
                -31.055336199304023
              ],
              [
                -55.86070526013151,
                -31.055437711332758
              ],
              [
                -55.86923561497444,
                -31.069749192700165
              ],
              [
                -55.88179897084037,
                -31.076406729704654
              ],
              [
                -55.88990390072382,
                -31.07643426349011
              ],
              [
                -55.89316841049125,
                -31.072416804568817
              ],
              [
                -55.89396939018744,
                -31.07143130023084
              ],
              [
                -55.898229407660985,
                -31.066174340379213
              ],
              [
                -55.909809750118875,
                -31.074529352375315
              ],
              [
                -55.92371815557853,
                -31.0762534023234
              ],
              [
                -55.92614913471973,
                -31.079110417363953
              ],
              [
                -55.92719208176524,
                -31.08033587909905
              ],
              [
                -55.93065905948552,
                -31.08467889126673
              ],
              [
                -55.93568400264235,
                -31.08538243331203
              ],
              [
                -55.9524789217187,
                -31.080178994264433
              ],
              [
                -55.961254338815614,
                -31.077598536819202
              ],
              [
                -55.96341385128824,
                -31.076964040536243
              ],
              [
                -55.97766773158391,
                -31.073127116487527
              ],
              [
                -55.980789697952126,
                -31.07453864376466
              ],
              [
                -55.98489020141208,
                -31.076391623268147
              ],
              [
                -55.990953607815634,
                -31.083461626553543
              ],
              [
                -56.00941646885916,
                -31.080786710052973
              ],
              [
                -56.00856600977165,
                -31.074330721824502
              ],
              [
                -56.00828653472233,
                -31.07091476626108
              ],
              [
                -56.00995604720359,
                -31.06712875491906
              ],
              [
                -56.0122225320777,
                -31.06198777417963
              ],
              [
                -56.012384562003454,
                -31.061619280701777
              ],
              [
                -56.0131575745887,
                -31.058799298340205
              ],
              [
                -56.01468503948967,
                -31.054606293300107
              ],
              [
                -56.01807406379028,
                -31.047251322352313
              ],
              [
                -56.01823607961114,
                -31.04397232739218
              ],
              [
                -56.01802913311893,
                -31.03794936199299
              ],
              [
                -56.01759267112377,
                -31.033514372053098
              ],
              [
                -56.01712117344588,
                -31.028206882921847
              ],
              [
                -56.01645327604016,
                -31.00895840623176
              ],
              [
                -56.015888330597875,
                -31.005277932066452
              ],
              [
                -56.015208371126185,
                -30.99985142433128
              ],
              [
                -56.01183292946903,
                -30.993280433591774
              ],
              [
                -56.010875933581524,
                -30.991735929951915
              ],
              [
                -56.009421467057365,
                -30.989390432976663
              ],
              [
                -56.006336521057264,
                -30.983189941671437
              ],
              [
                -56.00928351109838,
                -30.977983460042722
              ],
              [
                -56.004963081326494,
                -30.972821445109464
              ],
              [
                -56.009361045258295,
                -30.968691497290752
              ],
              [
                -56.00947154345752,
                -30.96816799394387
              ],
              [
                -56.010683070624125,
                -30.96241800309367
              ],
              [
                -56.01190310821841,
                -30.953466544496706
              ],
              [
                -56.00904417451379,
                -30.947428522838667
              ],
              [
                -56.00886117812249,
                -30.94704104992714
              ],
              [
                -56.00904066349725,
                -30.94691654597794
              ],
              [
                -56.01398917226033,
                -30.943484075949012
              ],
              [
                -56.012514198343624,
                -30.93984105283606
              ],
              [
                -56.00941826472596,
                -30.932531555047042
              ],
              [
                -56.008836771317526,
                -30.930584576947425
              ],
              [
                -56.00858679801123,
                -30.929749080761276
              ],
              [
                -56.006607799784895,
                -30.924904563212927
              ],
              [
                -56.00482632528522,
                -30.921930078292924
              ],
              [
                -56.00626786837346,
                -30.918136081043944
              ],
              [
                -56.00812285356722,
                -30.914480106256132
              ],
              [
                -56.00919287971915,
                -30.912370617609497
              ],
              [
                -56.00894887650882,
                -30.9089081253889
              ],
              [
                -56.008106946629056,
                -30.902173661006202
              ],
              [
                -56.00708848624307,
                -30.894018170090487
              ],
              [
                -55.99954258849941,
                -30.881729162594354
              ],
              [
                -55.99920609487943,
                -30.881232171279382
              ],
              [
                -55.99392968722969,
                -30.873439163351772
              ],
              [
                -55.989674256790934,
                -30.866189665580677
              ],
              [
                -55.988976787488625,
                -30.85845464915066
              ],
              [
                -55.99116080826184,
                -30.85251819897224
              ],
              [
                -55.99279483729306,
                -30.846033718688336
              ],
              [
                -55.997141804453044,
                -30.843998253904104
              ],
              [
                -56.00283330090197,
                -30.840161760960115
              ],
              [
                -56.00300928426588,
                -30.84004326198186
              ],
              [
                -56.0034978062289,
                -30.838194756269395
              ],
              [
                -56.00443630459354,
                -30.834645306345223
              ],
              [
                -56.0070953666956,
                -30.825067304477663
              ],
              [
                -56.00785438454621,
                -30.820619827831447
              ],
              [
                -56.01012035883048,
                -30.820056328799144
              ],
              [
                -56.015965792443694,
                -30.81860435108838
              ],
              [
                -56.0159473323305,
                -30.814988898226556
              ],
              [
                -56.01550634269149,
                -30.811840878497957
              ],
              [
                -56.01502883091324,
                -30.811378875507394
              ],
              [
                -56.01199590441524,
                -30.808446888212607
              ],
              [
                -56.011264906423676,
                -30.805039868838954
              ],
              [
                -56.01562689763955,
                -30.80192990855321
              ],
              [
                -56.02143637798272,
                -30.80004992265377
              ],
              [
                -56.02271686178627,
                -30.799635425068175
              ],
              [
                -56.02249137078959,
                -30.798184940403818
              ],
              [
                -56.02151790299652,
                -30.791917966503117
              ],
              [
                -56.02236044028679,
                -30.78605747644099
              ],
              [
                -56.029772900242605,
                -30.78494751962999
              ],
              [
                -56.03126486281121,
                -30.784822022288793
              ],
              [
                -56.034105848954795,
                -30.78458202697108
              ],
              [
                -56.03559385370891,
                -30.781812058629985
              ],
              [
                -56.03680984459256,
                -30.779548548974667
              ],
              [
                -56.037553886754665,
                -30.776804534397304
              ],
              [
                -56.0448278314952,
                -30.77539309567786
              ],
              [
                -56.0461068183671,
                -30.77514409570743
              ],
              [
                -56.04999728544403,
                -30.77669058380824
              ],
              [
                -56.06171471841945,
                -30.772940144392397
              ],
              [
                -56.06542770544553,
                -30.767572702456878
              ],
              [
                -56.065583746036225,
                -30.76307619134871
              ],
              [
                -56.06532475137701,
                -30.762306684992776
              ],
              [
                -56.06403674585684,
                -30.75848872317756
              ],
              [
                -56.06412477928359,
                -30.752535201101658
              ],
              [
                -56.065873819517414,
                -30.748291219229507
              ],
              [
                -56.069153292123666,
                -30.746878261446714
              ],
              [
                -56.07393424509915,
                -30.745889270109018
              ],
              [
                -56.079803190947516,
                -30.750318272091715
              ],
              [
                -56.084201655481415,
                -30.754098277280505
              ],
              [
                -56.085266602297594,
                -30.755012804750038
              ],
              [
                -56.08978106725436,
                -30.756122808510195
              ],
              [
                -56.094578062693024,
                -30.753812818249667
              ],
              [
                -56.09974856888427,
                -30.74599537509787
              ],
              [
                -56.10111557651804,
                -30.74392889863246
              ],
              [
                -56.102176576872715,
                -30.741086885622998
              ],
              [
                -56.10734806974656,
                -30.73262241094757
              ],
              [
                -56.11060506399712,
                -30.732703436669883
              ],
              [
                -56.11515549507908,
                -30.734973454941617
              ],
              [
                -56.11802995144414,
                -30.736406973960168
              ],
              [
                -56.12411138424569,
                -30.739177997641054
              ],
              [
                -56.1277203974178,
                -30.738117014465665
              ],
              [
                -56.12977686722914,
                -30.73424954103019
              ],
              [
                -56.13171989554254,
                -30.731490024594684
              ],
              [
                -56.130553434785526,
                -30.726850549150043
              ],
              [
                -56.12961642580811,
                -30.723697546312042
              ],
              [
                -56.128859977497235,
                -30.722290063280354
              ],
              [
                -56.12788748109541,
                -30.720480540240665
              ],
              [
                -56.12982399060794,
                -30.71699356943345
              ],
              [
                -56.13168097874897,
                -30.71262556229006
              ],
              [
                -56.136054957040635,
                -30.710722586286607
              ],
              [
                -56.13684947885084,
                -30.710420098365663
              ],
              [
                -56.14978991761812,
                -30.7054951757963
              ],
              [
                -56.14880345214747,
                -30.693390685101498
              ],
              [
                -56.14813444482338,
                -30.692919185319102
              ],
              [
                -56.14169856066538,
                -30.688383160179374
              ],
              [
                -56.13684010283223,
                -30.686110174627856
              ],
              [
                -56.14261906640764,
                -30.680048198078296
              ],
              [
                -56.14658402038182,
                -30.68048621099266
              ],
              [
                -56.1505445189218,
                -30.679955729093436
              ],
              [
                -56.156633489955645,
                -30.67393677776338
              ],
              [
                -56.161973011334005,
                -30.668659795546574
              ],
              [
                -56.16611301426586,
                -30.66309783086188
              ],
              [
                -56.16936552995912,
                -30.654352362641408
              ],
              [
                -56.16833751488172,
                -30.65009635095208
              ],
              [
                -56.167862534276665,
                -30.648717856067663
              ],
              [
                -56.16508312794635,
                -30.64065087360585
              ],
              [
                -56.17437156125449,
                -30.633736930947794
              ],
              [
                -56.174833075779134,
                -30.633393451697323
              ],
              [
                -56.179903063113066,
                -30.630578478717496
              ],
              [
                -56.18189207974036,
                -30.62506398576292
              ],
              [
                -56.17917861553483,
                -30.62267346365373
              ],
              [
                -56.17674860979901,
                -30.620532471185633
              ],
              [
                -56.16957323655966,
                -30.614842454609914
              ],
              [
                -56.17655315987512,
                -30.61164149264064
              ],
              [
                -56.19050410040698,
                -30.60386757979077
              ],
              [
                -56.20800847742899,
                -30.60648414437448
              ],
              [
                -56.20890795998287,
                -30.606618144900565
              ],
              [
                -56.2135409139697,
                -30.607424665798643
              ],
              [
                -56.21919439503839,
                -30.607061654710716
              ],
              [
                -56.221648872667494,
                -30.601290212314638
              ],
              [
                -56.2230348733288,
                -30.598838720804086
              ],
              [
                -56.22371641213193,
                -30.59763270445503
              ],
              [
                -56.225240912463,
                -30.596815243811143
              ],
              [
                -56.23185584653585,
                -30.593267270070353
              ],
              [
                -56.232583361271395,
                -30.590080251430237
              ],
              [
                -56.23324536484901,
                -30.587181786854455
              ],
              [
                -56.22659842223173,
                -30.58506724636944
              ],
              [
                -56.22583100510146,
                -30.578004276209935
              ],
              [
                -56.23751290817577,
                -30.57364331888807
              ],
              [
                -56.24713683153407,
                -30.575966862566663
              ],
              [
                -56.247203852945624,
                -30.575982872815565
              ],
              [
                -56.25122528006855,
                -30.58178986771204
              ],
              [
                -56.25477772254698,
                -30.587089842776365
              ],
              [
                -56.259528168195466,
                -30.585526396571403
              ],
              [
                -56.261772665471725,
                -30.58237589330302
              ],
              [
                -56.2603447695627,
                -30.569983430439645
              ],
              [
                -56.25623883260282,
                -30.56689192400153
              ],
              [
                -56.25741732781852,
                -30.56320543042074
              ],
              [
                -56.25796684150797,
                -30.56148744967608
              ],
              [
                -56.26015729782567,
                -30.55980443183692
              ],
              [
                -56.26620977336244,
                -30.555154475199707
              ],
              [
                -56.27574467994646,
                -30.56073249708234
              ],
              [
                -56.28033514064652,
                -30.560251046724435
              ],
              [
                -56.27904019146542,
                -30.55616704151132
              ],
              [
                -56.27866721512381,
                -30.55301903709441
              ],
              [
                -56.28025319562794,
                -30.549981537765937
              ],
              [
                -56.284397201429286,
                -30.54443409804493
              ],
              [
                -56.287920220076046,
                -30.53937160757556
              ],
              [
                -56.28822672837937,
                -30.534692605544716
              ],
              [
                -56.288449750050475,
                -30.531289610251992
              ],
              [
                -56.28758877092226,
                -30.52627111703362
              ],
              [
                -56.29189728260331,
                -30.520878661662096
              ],
              [
                -56.29604922533713,
                -30.52466715068471
              ],
              [
                -56.3002491905653,
                -30.527424675268286
              ],
              [
                -56.304323644225136,
                -30.529213194530403
              ],
              [
                -56.30700859264295,
                -30.530391707271708
              ],
              [
                -56.31056507499493,
                -30.53044523828577
              ],
              [
                -56.31395108468456,
                -30.52995773680693
              ],
              [
                -56.31786402227947,
                -30.530208726889857
              ],
              [
                -56.32092149691006,
                -30.53033675961246
              ],
              [
                -56.32755995907975,
                -30.529399272003833
              ],
              [
                -56.33558295457061,
                -30.523609329329368
              ],
              [
                -56.335467457558025,
                -30.519535860107297
              ],
              [
                -56.335120975326355,
                -30.51650586452943
              ],
              [
                -56.33494898566841,
                -30.51543684423105
              ],
              [
                -56.33427102455267,
                -30.51123236891935
              ],
              [
                -56.338578473743084,
                -30.509281879688658
              ],
              [
                -56.34658894473249,
                -30.505182395105923
              ],
              [
                -56.34899897377233,
                -30.499036919351873
              ],
              [
                -56.34992749282174,
                -30.49623093169187
              ],
              [
                -56.35251698882011,
                -30.493030476996125
              ],
              [
                -56.35769191982822,
                -30.492274991729584
              ],
              [
                -56.36267640203232,
                -30.49226300096091
              ],
              [
                -56.36195537966488,
                -30.495030512593914
              ],
              [
                -56.36175337275062,
                -30.495807514966003
              ],
              [
                -56.36087587906384,
                -30.499716003337536
              ],
              [
                -56.36511330709111,
                -30.502294504502572
              ],
              [
                -56.3750857612802,
                -30.50019204472372
              ],
              [
                -56.37961122258527,
                -30.499176563292355
              ],
              [
                -56.3828007141907,
                -30.497734581144737
              ],
              [
                -56.3844252236075,
                -30.49501658823692
              ],
              [
                -56.38722472310312,
                -30.492340105235048
              ],
              [
                -56.383575241777784,
                -30.49133558698896
              ],
              [
                -56.3851387571903,
                -30.488344621443403
              ],
              [
                -56.38544925126793,
                -30.48775012611653
              ],
              [
                -56.388650756968076,
                -30.48504211789688
              ],
              [
                -56.389356225502056,
                -30.48508661479728
              ],
              [
                -56.39161220801879,
                -30.485229646070845
              ],
              [
                -56.394109189667795,
                -30.485388133335164
              ],
              [
                -56.39428071804607,
                -30.481572152741457
              ],
              [
                -56.39533124886359,
                -30.476532669671965
              ],
              [
                -56.395324258633444,
                -30.473584179349466
              ],
              [
                -56.39531825382202,
                -30.4712656995989
              ],
              [
                -56.3962267508304,
                -30.47184418714693
              ],
              [
                -56.399584208895625,
                -30.4739836785617
              ],
              [
                -56.404898182063555,
                -30.468617719370837
              ],
              [
                -56.40588175286957,
                -30.460665762645096
              ],
              [
                -56.40281824951978,
                -30.45924975984025
              ],
              [
                -56.40276424695272,
                -30.459243745624303
              ],
              [
                -56.39947031048133,
                -30.458855719501283
              ],
              [
                -56.39672635212437,
                -30.45049223288242
              ],
              [
                -56.40373282759497,
                -30.44656877860315
              ],
              [
                -56.40837730596758,
                -30.444331321441908
              ],
              [
                -56.41150279149265,
                -30.439102811636165
              ],
              [
                -56.41488681008808,
                -30.430696840169333
              ],
              [
                -56.419098310814434,
                -30.43125989378337
              ],
              [
                -56.42398474904839,
                -30.434210389830724
              ],
              [
                -56.42461223427177,
                -30.434589393634752
              ],
              [
                -56.43710016662682,
                -30.43184992695206
              ],
              [
                -56.44499414013874,
                -30.426594490466417
              ],
              [
                -56.45181610652506,
                -30.422053007796944
              ],
              [
                -56.44847012076528,
                -30.41877251104198
              ],
              [
                -56.44727466249169,
                -30.417728038339284
              ],
              [
                -56.44360817465965,
                -30.41452400505496
              ],
              [
                -56.446268696240026,
                -30.41006803750792
              ],
              [
                -56.45236719702373,
                -30.39985356968481
              ],
              [
                -56.4540972458118,
                -30.39126460760679
              ],
              [
                -56.45815175796959,
                -30.38523866056101
              ],
              [
                -56.46096073166857,
                -30.384459164265397
              ],
              [
                -56.46537566189502,
                -30.38573967909425
              ],
              [
                -56.47252360681758,
                -30.388642205838696
              ],
              [
                -56.47418057484357,
                -30.389485186956772
              ],
              [
                -56.477901543723426,
                -30.391378688451343
              ],
              [
                -56.482002475144704,
                -30.398164185432258
              ],
              [
                -56.488168961150876,
                -30.39861721660494
              ],
              [
                -56.490198457119696,
                -30.396155745827
              ],
              [
                -56.49486296398852,
                -30.386387291865578
              ],
              [
                -56.50479243192486,
                -30.381246323743614
              ],
              [
                -56.50413694280748,
                -30.37663534244888
              ],
              [
                -56.503109480636944,
                -30.371986367664686
              ],
              [
                -56.50284596729911,
                -30.370796355254342
              ],
              [
                -56.50584748261258,
                -30.36806788521187
              ],
              [
                -56.510297460016375,
                -30.365383383219704
              ],
              [
                -56.51389545195199,
                -30.363184409503763
              ],
              [
                -56.52036491774503,
                -30.358508458617745
              ],
              [
                -56.52235441486033,
                -30.358728963563973
              ],
              [
                -56.52721886501619,
                -30.359269457991445
              ],
              [
                -56.53384631075488,
                -30.359004502740227
              ],
              [
                -56.54024723771422,
                -30.35970503033408
              ],
              [
                -56.5445267126747,
                -30.35838854950387
              ],
              [
                -56.54339923846807,
                -30.35511256316195
              ],
              [
                -56.54388377149584,
                -30.350934558719477
              ],
              [
                -56.54873324913973,
                -30.345566568519754
              ],
              [
                -56.55056223950374,
                -30.34311961185085
              ],
              [
                -56.545067331940615,
                -30.339569583597463
              ],
              [
                -56.542185339262176,
                -30.337597060852328
              ],
              [
                -56.54124688079054,
                -30.336954101332356
              ],
              [
                -56.53578793668275,
                -30.33363058573015
              ],
              [
                -56.53640292333032,
                -30.33023159808206
              ],
              [
                -56.53760442355111,
                -30.326497599687027
              ],
              [
                -56.538875989979715,
                -30.319619090756433
              ],
              [
                -56.539373973865246,
                -30.314021606628586
              ],
              [
                -56.54521198940121,
                -30.310924155453122
              ],
              [
                -56.546548928319055,
                -30.311856666998416
              ],
              [
                -56.548195436746994,
                -30.313004669366432
              ],
              [
                -56.54867295136049,
                -30.313337654633276
              ],
              [
                -56.550762408793496,
                -30.314794174178562
              ],
              [
                -56.55238887630886,
                -30.315952695439687
              ],
              [
                -56.55610133111863,
                -30.318596669169704
              ],
              [
                -56.57049931866905,
                -30.30593876508198
              ],
              [
                -56.57405131544542,
                -30.301525305201057
              ],
              [
                -56.57458632157499,
                -30.29547929324163
              ],
              [
                -56.57521285781343,
                -30.288404825018738
              ],
              [
                -56.590311740676,
                -30.293601396220442
              ],
              [
                -56.59412668227125,
                -30.294914402415667
              ],
              [
                -56.60300614733607,
                -30.293450920417076
              ],
              [
                -56.61057653569324,
                -30.300385922771543
              ],
              [
                -56.61619947500506,
                -30.300815947659125
              ],
              [
                -56.61880251955548,
                -30.293780982693182
              ],
              [
                -56.61649504011906,
                -30.292071481787332
              ],
              [
                -56.609954129648344,
                -30.286296490169278
              ],
              [
                -56.60528969006911,
                -30.282178469754662
              ],
              [
                -56.61274969624846,
                -30.261050533500438
              ],
              [
                -56.619084161341355,
                -30.264506068485332
              ],
              [
                -56.620015666460745,
                -30.264870052606135
              ],
              [
                -56.623075596286974,
                -30.266066066858112
              ],
              [
                -56.62648058184123,
                -30.267038582083643
              ],
              [
                -56.63662356267601,
                -30.25580563765714
              ],
              [
                -56.631529128978315,
                -30.254392122276514
              ],
              [
                -56.62811065045649,
                -30.252718621595932
              ],
              [
                -56.62748913794217,
                -30.252020136552304
              ],
              [
                -56.624140223648695,
                -30.248259115926242
              ],
              [
                -56.64081566557445,
                -30.234175218685056
              ],
              [
                -56.64785368346506,
                -30.221455768491957
              ],
              [
                -56.65953259249385,
                -30.22117334461249
              ],
              [
                -56.66452203422158,
                -30.22076885267344
              ],
              [
                -56.66690302504698,
                -30.2175178661658
              ],
              [
                -56.663382589886844,
                -30.216083323519424
              ],
              [
                -56.660664605319326,
                -30.214921357653235
              ],
              [
                -56.65538967386295,
                -30.2124448049552
              ],
              [
                -56.65251116955679,
                -30.21126780044773
              ],
              [
                -56.64568774821445,
                -30.208476311919945
              ],
              [
                -56.64486777933692,
                -30.204357799846704
              ],
              [
                -56.64987522083567,
                -30.20681983043059
              ],
              [
                -56.65374273522838,
                -30.20366233501031
              ],
              [
                -56.65823569390658,
                -30.199994389401823
              ],
              [
                -56.662317175131726,
                -30.197657396013888
              ],
              [
                -56.672457606547816,
                -30.199499417751316
              ],
              [
                -56.67676154065058,
                -30.20028144419556
              ],
              [
                -56.679104539582276,
                -30.197237938054244
              ],
              [
                -56.68039605554336,
                -30.19556045534668
              ],
              [
                -56.68240954070262,
                -30.196113449613033
              ],
              [
                -56.68362900989981,
                -30.196447976015087
              ],
              [
                -56.687491461451806,
                -30.199330477806676
              ],
              [
                -56.690861956062385,
                -30.201952977111162
              ],
              [
                -56.69486889819364,
                -30.204365479593854
              ],
              [
                -56.704346899427556,
                -30.192863072926833
              ],
              [
                -56.70740141111364,
                -30.18840360501676
              ],
              [
                -56.70693542904217,
                -30.185816095935053
              ],
              [
                -56.70518448642661,
                -30.176087116683963
              ],
              [
                -56.71741583799953,
                -30.180005636548238
              ],
              [
                -56.71819486190352,
                -30.180255137279243
              ],
              [
                -56.7212063340748,
                -30.17790416138429
              ],
              [
                -56.729344811219875,
                -30.17155072031057
              ],
              [
                -56.7341987936078,
                -30.170326746650407
              ],
              [
                -56.73624125793252,
                -30.169568747024385
              ],
              [
                -56.741435234790956,
                -30.167640768405782
              ],
              [
                -56.74833518974385,
                -30.165079284452112
              ],
              [
                -56.75821165638751,
                -30.160659364328797
              ],
              [
                -56.761084128029644,
                -30.161261861122732
              ],
              [
                -56.761604622617,
                -30.161370874079076
              ],
              [
                -56.767619077297326,
                -30.162689881552456
              ],
              [
                -56.771734061989704,
                -30.16344937925568
              ],
              [
                -56.77545302365548,
                -30.163659908530217
              ],
              [
                -56.78085451639436,
                -30.157746425843722
              ],
              [
                -56.78333952531696,
                -30.152564474142245
              ],
              [
                -56.78198452700506,
                -30.148319992050777
              ],
              [
                -56.78045504950561,
                -30.14469199082301
              ],
              [
                -56.779865098749774,
                -30.14256196316903
              ],
              [
                -56.77713513390554,
                -30.13270447104024
              ],
              [
                -56.77893816542998,
                -30.127047525546978
              ],
              [
                -56.7886121140253,
                -30.12415753606112
              ],
              [
                -56.79105358729832,
                -30.12342804805269
              ],
              [
                -56.795911091507634,
                -30.122420103901987
              ],
              [
                -56.80048356326133,
                -30.119722608388518
              ],
              [
                -56.80066906140075,
                -30.115348125126182
              ],
              [
                -56.80070655509564,
                -30.114460637304305
              ],
              [
                -56.8022085781583,
                -30.10874063767731
              ],
              [
                -56.806613602064935,
                -30.10373216771258
              ],
              [
                -56.81053252844197,
                -30.10606421098331
              ],
              [
                -56.81290602221554,
                -30.10640768124327
              ],
              [
                -56.8146675319519,
                -30.10666170611801
              ],
              [
                -56.818571469056096,
                -30.104595703334905
              ],
              [
                -56.819545022966516,
                -30.101556713367135
              ],
              [
                -56.81969800752049,
                -30.10107972309951
              ],
              [
                -56.821893487763724,
                -30.096925232839588
              ],
              [
                -56.83419844610989,
                -30.092096828875434
              ],
              [
                -56.853572824407564,
                -30.088982909917917
              ],
              [
                -56.86167924616717,
                -30.089378410318144
              ],
              [
                -56.86382724937269,
                -30.090478433908856
              ],
              [
                -56.86940365580805,
                -30.093331459029923
              ],
              [
                -56.87208466350488,
                -30.091327960924175
              ],
              [
                -56.87209813878636,
                -30.091081464540753
              ],
              [
                -56.87230417346523,
                -30.087296975004083
              ],
              [
                -56.8775396558494,
                -30.08738798695292
              ],
              [
                -56.884295091533495,
                -30.08759404107526
              ],
              [
                -56.897718416754614,
                -30.106363535364174
              ],
              [
                -56.90334384360341,
                -30.111935025581126
              ],
              [
                -56.903790357035106,
                -30.108066542227803
              ],
              [
                -56.90988479845518,
                -30.109894565908956
              ],
              [
                -56.91315128766774,
                -30.10954258654423
              ],
              [
                -56.91217280572251,
                -30.10580660385112
              ],
              [
                -56.91163831392618,
                -30.103965604752947
              ],
              [
                -56.91120430847078,
                -30.102468576302613
              ],
              [
                -56.913207332110176,
                -30.100111094122482
              ],
              [
                -56.916772799724676,
                -30.096265132928725
              ],
              [
                -56.92581025742731,
                -30.095119669798343
              ],
              [
                -56.93199718355763,
                -30.093850690874884
              ],
              [
                -56.938081635439154,
                -30.096151723445654
              ],
              [
                -56.94119110672849,
                -30.09724222315404
              ],
              [
                -56.94497459010341,
                -30.098335736712915
              ],
              [
                -56.946175572430725,
                -30.09868275101758
              ],
              [
                -56.95530151524394,
                -30.101157774099256
              ],
              [
                -56.97677985305819,
                -30.0965498465302
              ],
              [
                -56.97975237136315,
                -30.09228789474367
              ],
              [
                -56.98022835098829,
                -30.09120288420886
              ],
              [
                -56.98142686158856,
                -30.088472413448827
              ],
              [
                -56.99228729610392,
                -30.08608894385185
              ],
              [
                -56.999309229448315,
                -30.086747483128416
              ],
              [
                -57.022234065991,
                -30.087662071252826
              ],
              [
                -57.02771952551363,
                -30.092065080603668
              ],
              [
                -57.02947146516961,
                -30.098240052143787
              ],
              [
                -57.029490453779445,
                -30.098285050123152
              ],
              [
                -57.030804938333176,
                -30.10147753852058
              ],
              [
                -57.03621986348482,
                -30.10336207725717
              ],
              [
                -57.039269377998345,
                -30.104319077045446
              ],
              [
                -57.04363182879493,
                -30.105133077727658
              ],
              [
                -57.055856775697926,
                -30.099158679019247
              ],
              [
                -57.06408318399067,
                -30.10077670629688
              ],
              [
                -57.066922152967585,
                -30.10032219536536
              ],
              [
                -57.06601872193542,
                -30.093120230695835
              ],
              [
                -57.06578224224031,
                -30.091232698930202
              ],
              [
                -57.062448236185034,
                -30.09055822729961
              ],
              [
                -57.06307577671405,
                -30.086801209400278
              ],
              [
                -57.068388221586765,
                -30.086379225884684
              ],
              [
                -57.07160868567233,
                -30.088621733512685
              ],
              [
                -57.07506114788656,
                -30.091076270652433
              ],
              [
                -57.078623100652194,
                -30.094003780199568
              ],
              [
                -57.08449453258136,
                -30.099621778522035
              ],
              [
                -57.08642850120243,
                -30.104317751207425
              ],
              [
                -57.08853044311659,
                -30.10945575462167
              ],
              [
                -57.08716548445955,
                -30.11151075922273
              ],
              [
                -57.08552145646397,
                -30.11398575969802
              ],
              [
                -57.08797541448974,
                -30.120070760496738
              ],
              [
                -57.0933198646745,
                -30.1209777601698
              ],
              [
                -57.094126884662245,
                -30.117271261892103
              ],
              [
                -57.09442040612876,
                -30.115920296162408
              ],
              [
                -57.10332533721214,
                -30.114927815208944
              ],
              [
                -57.106134330889425,
                -30.115280326353005
              ],
              [
                -57.10977829085352,
                -30.1146843121662
              ],
              [
                -57.114364753948976,
                -30.113918851362627
              ],
              [
                -57.112701696713074,
                -30.12321731651146
              ],
              [
                -57.110189205180944,
                -30.127613822009764
              ],
              [
                -57.10686725199526,
                -30.126412298577446
              ],
              [
                -57.105153253926694,
                -30.126142299897264
              ],
              [
                -57.103979740463416,
                -30.125957768323058
              ],
              [
                -57.104071746285264,
                -30.12889376915137
              ],
              [
                -57.10491824315612,
                -30.131915778995445
              ],
              [
                -57.10469672400027,
                -30.135072777000335
              ],
              [
                -57.107173694994216,
                -30.13810576475468
              ],
              [
                -57.11090862074684,
                -30.143115785447744
              ],
              [
                -57.11372008286909,
                -30.144806282629474
              ],
              [
                -57.11702408090928,
                -30.144456806459047
              ],
              [
                -57.12213504916087,
                -30.142811828863067
              ],
              [
                -57.12748149213785,
                -30.14109132343665
              ],
              [
                -57.13446837425545,
                -30.154107359144188
              ],
              [
                -57.13790835203095,
                -30.16055335042727
              ],
              [
                -57.1389283077612,
                -30.162484848468505
              ],
              [
                -57.1425757474045,
                -30.16939432661971
              ],
              [
                -57.14421973053334,
                -30.172758344137005
              ],
              [
                -57.14723019334065,
                -30.175843352096322
              ],
              [
                -57.14845064944933,
                -30.177094332512734
              ],
              [
                -57.15704457381246,
                -30.18514584022947
              ],
              [
                -57.15885999249865,
                -30.191553837740013
              ],
              [
                -57.161394996720674,
                -30.195171858514524
              ],
              [
                -57.16732790959005,
                -30.198746866870877
              ],
              [
                -57.16744888647311,
                -30.202329855444088
              ],
              [
                -57.16757089159288,
                -30.20657683255227
              ],
              [
                -57.16436887203242,
                -30.21307680649951
              ],
              [
                -57.16542784041189,
                -30.21642980958805
              ],
              [
                -57.16364481099174,
                -30.220732312456512
              ],
              [
                -57.16313384370979,
                -30.221966811543833
              ],
              [
                -57.155931788453074,
                -30.234236259662406
              ],
              [
                -57.16048024938246,
                -30.242211221424437
              ],
              [
                -57.16438519485557,
                -30.241902275771352
              ],
              [
                -57.165642698311075,
                -30.241803256356636
              ],
              [
                -57.16610921902173,
                -30.24320077225778
              ],
              [
                -57.167316670794484,
                -30.24681874941381
              ],
              [
                -57.1683031334281,
                -30.249860243903335
              ],
              [
                -57.17486857460203,
                -30.251500761306914
              ],
              [
                -57.1818764912157,
                -30.255146773178733
              ],
              [
                -57.184471488450534,
                -30.25708477698334
              ],
              [
                -57.186686451311175,
                -30.25869179689723
              ],
              [
                -57.18912240465652,
                -30.260460321400362
              ],
              [
                -57.194119904189776,
                -30.264217310463938
              ],
              [
                -57.19562083554787,
                -30.268234795022405
              ],
              [
                -57.19883079757962,
                -30.276827298813032
              ],
              [
                -57.200423237834464,
                -30.2798623039376
              ],
              [
                -57.20172671837885,
                -30.28462380903063
              ],
              [
                -57.212650119789586,
                -30.288418334387746
              ],
              [
                -57.2175175819783,
                -30.289403349036945
              ],
              [
                -57.221323525910435,
                -30.289849355333153
              ],
              [
                -57.23074146375784,
                -30.28967790498948
              ],
              [
                -57.24693885103316,
                -30.290134481274016
              ],
              [
                -57.25039284097834,
                -30.287508508811083
              ],
              [
                -57.25346436196562,
                -30.2837775231355
              ],
              [
                -57.25323785394158,
                -30.28019602902483
              ],
              [
                -57.25293587426047,
                -30.27541652822317
              ],
              [
                -57.25567688621696,
                -30.26735305764768
              ],
              [
                -57.25927238605222,
                -30.267032575724933
              ],
              [
                -57.267749267341735,
                -30.272927079040258
              ],
              [
                -57.26779023941253,
                -30.2804350837501
              ],
              [
                -57.26792925748824,
                -30.282005551901733
              ],
              [
                -57.26808674227369,
                -30.283779554686674
              ],
              [
                -57.2770021351321,
                -30.292002569822497
              ],
              [
                -57.27957856783228,
                -30.293706571119422
              ],
              [
                -57.283491590358686,
                -30.292159118255984
              ],
              [
                -57.28778304262108,
                -30.290353622576824
              ],
              [
                -57.294813052077764,
                -30.283274656159364
              ],
              [
                -57.29896355924816,
                -30.275505182928544
              ],
              [
                -57.30070156080302,
                -30.268796239055106
              ],
              [
                -57.30617508391754,
                -30.259879261001267
              ],
              [
                -57.30956505521581,
                -30.25710180758584
              ],
              [
                -57.31467751281315,
                -30.259305294629378
              ],
              [
                -57.317467942046534,
                -30.265052300987833
              ],
              [
                -57.31769494312552,
                -30.265454315079374
              ],
              [
                -57.320364432966436,
                -30.27018978614257
              ],
              [
                -57.33892275615242,
                -30.27510387883968
              ],
              [
                -57.34389667776238,
                -30.277657364559825
              ],
              [
                -57.34962362278612,
                -30.283579903159414
              ],
              [
                -57.3537796125867,
                -30.283619886872554
              ],
              [
                -57.356118084722524,
                -30.27922344147807
              ],
              [
                -57.35792411795638,
                -30.275660432985525
              ],
              [
                -57.35971212411896,
                -30.272132454339694
              ],
              [
                -57.36855403817865,
                -30.272695495975444
              ],
              [
                -57.38250484782206,
                -30.288134516101827
              ],
              [
                -57.385647306044646,
                -30.291672013870418
              ],
              [
                -57.386225280265556,
                -30.295117995850603
              ],
              [
                -57.38692276252508,
                -30.299283488780606
              ],
              [
                -57.38836275628788,
                -30.30241998513443
              ],
              [
                -57.393939186263644,
                -30.30432301390851
              ],
              [
                -57.409409597743725,
                -30.300295587997688
              ],
              [
                -57.41511952554871,
                -30.301887619028875
              ],
              [
                -57.41790152590725,
                -30.30094061401618
              ],
              [
                -57.42010809187035,
                -30.29043063629659
              ],
              [
                -57.418860615704894,
                -30.288173161851113
              ],
              [
                -57.41759110893285,
                -30.285874155402862
              ],
              [
                -57.41498567765061,
                -30.27938014834808
              ],
              [
                -57.42800060045509,
                -30.27489773087326
              ],
              [
                -57.440091002039374,
                -30.27549527840117
              ],
              [
                -57.44538747509372,
                -30.275993286026456
              ],
              [
                -57.44544244770114,
                -30.27599277026325
              ],
              [
                -57.451216422323874,
                -30.27596382008146
              ],
              [
                -57.45467689456747,
                -30.27312833607052
              ],
              [
                -57.4564373942513,
                -30.271134342944897
              ],
              [
                -57.46019188944974,
                -30.26688087727015
              ],
              [
                -57.46481238223752,
                -30.264768875660366
              ],
              [
                -57.47678581198395,
                -30.265324929787454
              ],
              [
                -57.48548669388861,
                -30.267354468697945
              ],
              [
                -57.488461174279045,
                -30.269327476180063
              ],
              [
                -57.49009112755092,
                -30.272315978698824
              ],
              [
                -57.49169963336487,
                -30.27541147089665
              ],
              [
                -57.49297511214794,
                -30.278580952508413
              ],
              [
                -57.49301209135805,
                -30.278672978467917
              ],
              [
                -57.4954655410507,
                -30.2847599828257
              ],
              [
                -57.497705507217425,
                -30.287170461225283
              ],
              [
                -57.5010174707025,
                -30.287470989789412
              ],
              [
                -57.51896638080162,
                -30.286056534885496
              ],
              [
                -57.52330785041575,
                -30.28521457934005
              ],
              [
                -57.534811266603356,
                -30.27981061738727
              ],
              [
                -57.54843973325368,
                -30.273407685707568
              ],
              [
                -57.55472569608457,
                -30.263763247500773
              ],
              [
                -57.56107618323612,
                -30.26082825708908
              ],
              [
                -57.5660171529401,
                -30.256763308065242
              ],
              [
                -57.56578322285523,
                -30.25004731855703
              ],
              [
                -57.56352377208344,
                -30.24494832787425
              ],
              [
                -57.55961178922614,
                -30.241594818338818
              ],
              [
                -57.55881230186131,
                -30.240909296391173
              ],
              [
                -57.556785369737014,
                -30.236631316945118
              ],
              [
                -57.5550554495543,
                -30.219674330210772
              ],
              [
                -57.556547504300234,
                -30.212027882159877
              ],
              [
                -57.55880595529348,
                -30.210938379196953
              ],
              [
                -57.562781971681105,
                -30.20901988738871
              ],
              [
                -57.56591943052179,
                -30.20772843462917
              ],
              [
                -57.56693992059125,
                -30.207308407911405
              ],
              [
                -57.608814733991586,
                -30.1890946177546
              ],
              [
                -57.62559311094528,
                -30.18679170063387
              ],
              [
                -57.6292945764664,
                -30.18696521012302
              ],
              [
                -57.634181051325115,
                -30.188755738201092
              ],
              [
                -57.63734999904943,
                -30.18991725627791
              ],
              [
                -57.64188947862446,
                -30.191667248962446
              ],
              [
                -57.64272096619722,
                -30.188406772561972
              ],
              [
                -57.64142098519482,
                -30.18546727933318
              ],
              [
                -57.63546107749108,
                -30.1756657479574
              ],
              [
                -57.632426621667165,
                -30.174174761177593
              ],
              [
                -57.624954660547445,
                -30.173926726701946
              ],
              [
                -57.61190975591232,
                -30.175162668147358
              ],
              [
                -57.60839030300764,
                -30.176590651982007
              ],
              [
                -57.59622336883767,
                -30.17681961237337
              ],
              [
                -57.59518186060092,
                -30.176766092028693
              ],
              [
                -57.59285842291775,
                -30.176647107335743
              ],
              [
                -57.58257201150858,
                -30.174725057451326
              ],
              [
                -57.57474706786927,
                -30.17326252311947
              ],
              [
                -57.565641628909546,
                -30.1701254909469
              ],
              [
                -57.56015715687151,
                -30.16806998593517
              ],
              [
                -57.54756027833836,
                -30.163276961444236
              ],
              [
                -57.54202486753683,
                -30.160362946098545
              ],
              [
                -57.52957700828476,
                -30.153038410797627
              ],
              [
                -57.50730522556287,
                -30.1389878452201
              ],
              [
                -57.50484227596441,
                -30.13744434706454
              ],
              [
                -57.496222353364324,
                -30.13204231003433
              ],
              [
                -57.49119840021687,
                -30.12889380545531
              ],
              [
                -57.481598486994876,
                -30.12325326079433
              ],
              [
                -57.46579066267963,
                -30.110291728700144
              ],
              [
                -57.46366019664975,
                -30.10822372514216
              ],
              [
                -57.46136374513383,
                -30.10592125439212
              ],
              [
                -57.459871238740284,
                -30.103282744811153
              ],
              [
                -57.458345775981854,
                -30.099864251803044
              ],
              [
                -57.45118645081632,
                -30.083828759481676
              ],
              [
                -57.44827148144406,
                -30.07696223614848
              ],
              [
                -57.44587402261314,
                -30.071822759233665
              ],
              [
                -57.43446819785382,
                -30.05737676144615
              ],
              [
                -57.43165220314753,
                -30.05434325013874
              ],
              [
                -57.429621245104435,
                -30.052322240945575
              ],
              [
                -57.40804598067815,
                -30.033541218573642
              ],
              [
                -57.40539751266246,
                -30.031235206403853
              ],
              [
                -57.40065558637451,
                -30.02827267878451
              ],
              [
                -57.38181328112798,
                -30.017881116669273
              ],
              [
                -57.37323836857428,
                -30.01506408846561
              ],
              [
                -57.36821439395264,
                -30.01341360577481
              ],
              [
                -57.3452341319631,
                -30.002758005806584
              ],
              [
                -57.33536924832558,
                -29.989658998053258
              ],
              [
                -57.32992635814714,
                -29.980075523291678
              ],
              [
                -57.327763418300144,
                -29.971572506062323
              ],
              [
                -57.32685542996873,
                -29.96672252902366
              ],
              [
                -57.32595648428278,
                -29.961923030915255
              ],
              [
                -57.32459256545794,
                -29.950054063618833
              ],
              [
                -57.32357360949556,
                -29.941338072764417
              ],
              [
                -57.32258715673992,
                -29.936299095842923
              ],
              [
                -57.322119183683014,
                -29.92885412750184
              ],
              [
                -57.322714687060234,
                -29.924258124301623
              ],
              [
                -57.324012230629684,
                -29.914242160203038
              ],
              [
                -57.32638127686976,
                -29.904513190008917
              ],
              [
                -57.32789679065995,
                -29.898293216351302
              ],
              [
                -57.32823628420302,
                -29.894708720816393
              ],
              [
                -57.32757985392181,
                -29.88268571398038
              ],
              [
                -57.32714090787158,
                -29.87985026048046
              ],
              [
                -57.32526089910507,
                -29.87648876106652
              ],
              [
                -57.317648553691996,
                -29.86573373601177
              ],
              [
                -57.294187860121696,
                -29.831237729484567
              ],
              [
                -57.28994841481131,
                -29.827598226789295
              ],
              [
                -57.28727896371822,
                -29.825308227684197
              ],
              [
                -57.27770058621317,
                -29.81708818680759
              ],
              [
                -57.26202973887254,
                -29.80465517579465
              ],
              [
                -57.2427319822687,
                -29.788851117219327
              ],
              [
                -57.2377115222807,
                -29.785620595387083
              ],
              [
                -57.23374809323424,
                -29.78319412019114
              ],
              [
                -57.23011711866622,
                -29.780971577937052
              ],
              [
                -57.226799151782195,
                -29.77894111456885
              ],
              [
                -57.220345200786866,
                -29.776853076127736
              ],
              [
                -57.21372374159582,
                -29.77773006125256
              ],
              [
                -57.207700791549165,
                -29.778526537081493
              ],
              [
                -57.204368832096684,
                -29.778968001048625
              ],
              [
                -57.19955234425192,
                -29.779605491319895
              ],
              [
                -57.16917106251484,
                -29.78025587894579
              ],
              [
                -57.16505907916631,
                -29.77916783511721
              ],
              [
                -57.16094665582809,
                -29.778078842291105
              ],
              [
                -57.13319689278018,
                -29.770139764654914
              ],
              [
                -57.128121406689836,
                -29.76827471356883
              ],
              [
                -57.1213314920812,
                -29.764397698875648
              ],
              [
                -57.111358627650134,
                -29.75685918618925
              ],
              [
                -57.100972721010486,
                -29.748990679555888
              ],
              [
                -57.0703090674199,
                -29.72230613901623
              ],
              [
                -57.050538332291055,
                -29.70538857099906
              ],
              [
                -57.04783335683507,
                -29.703387072267063
              ],
              [
                -57.04542688757982,
                -29.70186609168189
              ],
              [
                -57.04437687205057,
                -29.701341066489654
              ],
              [
                -57.03656694992873,
                -29.697436540752573
              ],
              [
                -57.025409089490786,
                -29.68634505158752
              ],
              [
                -57.01859870188134,
                -29.67957500569693
              ],
              [
                -57.01725522195411,
                -29.67623052679867
              ],
              [
                -57.01351130017732,
                -29.66690852967487
              ],
              [
                -57.009262370823684,
                -29.65999254489151
              ],
              [
                -57.00650540344987,
                -29.656764530826642
              ],
              [
                -57.004540443272376,
                -29.654462544935072
              ],
              [
                -56.99112454518867,
                -29.645712491092418
              ],
              [
                -56.98460509860182,
                -29.64456395949338
              ],
              [
                -56.97426268434516,
                -29.64274244947742
              ],
              [
                -56.96984874806615,
                -29.640769411605802
              ],
              [
                -56.967513783812805,
                -29.63860340887581
              ],
              [
                -56.96508331794182,
                -29.63265843252946
              ],
              [
                -56.966345824765774,
                -29.625908467493037
              ],
              [
                -56.9681063651159,
                -29.62260544352705
              ],
              [
                -56.97084635644101,
                -29.61746449792628
              ],
              [
                -56.969262938702265,
                -29.605797503452454
              ],
              [
                -56.9670659561664,
                -29.59965452557833
              ],
              [
                -56.95368115580044,
                -29.583019485483234
              ],
              [
                -56.94710525938384,
                -29.577149999747224
              ],
              [
                -56.93595182639329,
                -29.57121898008554
              ],
              [
                -56.93556486316105,
                -29.570857987315495
              ],
              [
                -56.93331637684428,
                -29.568763987834114
              ],
              [
                -56.92564845159003,
                -29.5604589712898
              ],
              [
                -56.92164152228178,
                -29.555558937057352
              ],
              [
                -56.91906305964632,
                -29.552491930493435
              ],
              [
                -56.91860606677435,
                -29.55197395280223
              ],
              [
                -56.90611524531387,
                -29.53780293598388
              ],
              [
                -56.903558750543084,
                -29.535279926257328
              ],
              [
                -56.89933182658173,
                -29.532255946755832
              ],
              [
                -56.89724584129644,
                -29.531038925929295
              ],
              [
                -56.896841353507085,
                -29.53080293979958
              ],
              [
                -56.890054428004234,
                -29.52687141678449
              ],
              [
                -56.887107942957314,
                -29.524916890114657
              ],
              [
                -56.8827829848305,
                -29.520949904441803
              ],
              [
                -56.87565255899983,
                -29.514408397162835
              ],
              [
                -56.86607014950033,
                -29.509045846934693
              ],
              [
                -56.86342019356954,
                -29.507962330468217
              ],
              [
                -56.85763374630979,
                -29.505596833984214
              ],
              [
                -56.84999531057286,
                -29.502474316593496
              ],
              [
                -56.84322439674755,
                -29.49876429720376
              ],
              [
                -56.81586169388141,
                -29.48200474313324
              ],
              [
                -56.81005075815938,
                -29.476070230048208
              ],
              [
                -56.80695981681691,
                -29.46937974005501
              ],
              [
                -56.80150093261877,
                -29.452902266443093
              ],
              [
                -56.79738299735711,
                -29.448117246712773
              ],
              [
                -56.782911139392674,
                -29.43755121780086
              ],
              [
                -56.78179166150642,
                -29.436441707796973
              ],
              [
                -56.77946366684601,
                -29.43413618962745
              ],
              [
                -56.77698673559658,
                -29.43121421930834
              ],
              [
                -56.7762092661574,
                -29.427158208728567
              ],
              [
                -56.777735262624574,
                -29.419659238879866
              ],
              [
                -56.7777802614763,
                -29.41944125958462
              ],
              [
                -56.77794326292879,
                -29.418768739859107
              ],
              [
                -56.77861026393384,
                -29.416022240153765
              ],
              [
                -56.779036303612024,
                -29.410511777027097
              ],
              [
                -56.775224876736566,
                -29.3978227804556
              ],
              [
                -56.77355242844596,
                -29.392709800714318
              ],
              [
                -56.7691400489148,
                -29.380937325522527
              ],
              [
                -56.765593095522654,
                -29.377059320103488
              ],
              [
                -56.762847118817476,
                -29.374388289121978
              ],
              [
                -56.754993709129415,
                -29.37004578301968
              ],
              [
                -56.75194119194882,
                -29.369545251596524
              ],
              [
                -56.71966749495049,
                -29.36545812999482
              ],
              [
                -56.71851698487107,
                -29.36535112549768
              ],
              [
                -56.711076553573655,
                -29.364657639420557
              ],
              [
                -56.70514111054359,
                -29.36133011787418
              ],
              [
                -56.70278712989225,
                -29.357935097287687
              ],
              [
                -56.695168233353755,
                -29.34544512078317
              ],
              [
                -56.69316027881571,
                -29.34155908982139
              ],
              [
                -56.69151781906092,
                -29.338893610441716
              ],
              [
                -56.67588653872895,
                -29.31352660594211
              ],
              [
                -56.6674162035494,
                -29.299294141304294
              ],
              [
                -56.65415738916537,
                -29.277015140435154
              ],
              [
                -56.64646455286127,
                -29.258919129500487
              ],
              [
                -56.64847256386055,
                -29.249464680625927
              ],
              [
                -56.65056613373947,
                -29.239912699347425
              ],
              [
                -56.65132762200449,
                -29.234900238610745
              ],
              [
                -56.65212117659988,
                -29.226786235519388
              ],
              [
                -56.65229967004001,
                -29.22496376666699
              ],
              [
                -56.65215868121717,
                -29.219879274404587
              ],
              [
                -56.643408373055514,
                -29.19657429297902
              ],
              [
                -56.64146389404279,
                -29.19340828968189
              ],
              [
                -56.6377479246769,
                -29.190092292480823
              ],
              [
                -56.63568797242036,
                -29.18825378649194
              ],
              [
                -56.626002100753105,
                -29.179609287758538
              ],
              [
                -56.62218114598982,
                -29.17557026590872
              ],
              [
                -56.60691181093476,
                -29.157883260370873
              ],
              [
                -56.60454338013705,
                -29.15487273000291
              ],
              [
                -56.60222189472902,
                -29.149467766671087
              ],
              [
                -56.592329607930424,
                -29.126431756398887
              ],
              [
                -56.58885363131607,
                -29.119621270818733
              ],
              [
                -56.581767240024206,
                -29.116177774239823
              ],
              [
                -56.5754532945926,
                -29.11434274389465
              ],
              [
                -56.57032132926815,
                -29.112944239321685
              ],
              [
                -56.555494944458516,
                -29.113597660069644
              ],
              [
                -56.54966444344453,
                -29.11385413259274
              ],
              [
                -56.54124503320222,
                -29.112987111284244
              ],
              [
                -56.53776455575571,
                -29.11067362972576
              ],
              [
                -56.53287911291748,
                -29.106964115852463
              ],
              [
                -56.52600270057025,
                -29.101740068568247
              ],
              [
                -56.524271200120374,
                -29.100425078721067
              ],
              [
                -56.515753825976056,
                -29.093826051131497
              ],
              [
                -56.51246088089061,
                -29.09213006784782
              ],
              [
                -56.50683792081216,
                -29.08928755186924
              ],
              [
                -56.500011977863544,
                -29.087506036373174
              ],
              [
                -56.46542474336612,
                -29.083166401849702
              ],
              [
                -56.43097251848809,
                -29.07893479371581
              ],
              [
                -56.42231110093747,
                -29.076592246177803
              ],
              [
                -56.416872173985986,
                -29.074238232818843
              ],
              [
                -56.41346669226944,
                -29.07074026058746
              ],
              [
                -56.4104107928882,
                -29.05665877499481
              ],
              [
                -56.41007032758515,
                -29.049986270139154
              ],
              [
                -56.409970308640105,
                -29.048029302581437
              ],
              [
                -56.407573382266115,
                -29.040035307635275
              ],
              [
                -56.40112601607274,
                -29.027923821431038
              ],
              [
                -56.39778205651466,
                -29.021644298411356
              ],
              [
                -56.39571506912825,
                -29.017741301694592
              ],
              [
                -56.397521110041666,
                -29.01374182198155
              ],
              [
                -56.39892760698794,
                -29.012566859624354
              ],
              [
                -56.41422053095525,
                -28.99978541760558
              ],
              [
                -56.41298410466673,
                -28.990338973055568
              ],
              [
                -56.408985176916026,
                -28.976620980919638
              ],
              [
                -56.402589264507675,
                -28.968240973788866
              ],
              [
                -56.39420237862212,
                -28.95813745789349
              ],
              [
                -56.386357939458854,
                -28.953425937785635
              ],
              [
                -56.38048005224106,
                -28.94942644190021
              ],
              [
                -56.37682703063985,
                -28.949463911975723
              ],
              [
                -56.37020860503554,
                -28.94732840544129
              ],
              [
                -56.36242767881941,
                -28.94425536453858
              ],
              [
                -56.35562924530994,
                -28.942353360704903
              ],
              [
                -56.34953878361263,
                -28.940648826255945
              ],
              [
                -56.34622132875051,
                -28.939700830168455
              ],
              [
                -56.33761342135208,
                -28.934953327134263
              ],
              [
                -56.33312898161548,
                -28.93274230948425
              ],
              [
                -56.3323814744827,
                -28.931898781565863
              ],
              [
                -56.32876554222431,
                -28.92781581813362
              ],
              [
                -56.32409456766397,
                -28.923539297916722
              ],
              [
                -56.321523613521485,
                -28.921033788778225
              ],
              [
                -56.31633615341932,
                -28.915477296694064
              ],
              [
                -56.312991226311134,
                -28.911749286497454
              ],
              [
                -56.312571732812586,
                -28.911282298970193
              ],
              [
                -56.30969372955791,
                -28.908077795356807
              ],
              [
                -56.30338881964005,
                -28.899642259012943
              ],
              [
                -56.297570445051086,
                -28.890078761501645
              ],
              [
                -56.29729395941717,
                -28.884270791994314
              ],
              [
                -56.296796027284834,
                -28.873504321079263
              ],
              [
                -56.2955895577779,
                -28.86570883483163
              ],
              [
                -56.295001094465526,
                -28.859309335576107
              ],
              [
                -56.29750570922318,
                -28.832843936442707
              ],
              [
                -56.29782370712063,
                -28.830499961643202
              ],
              [
                -56.29901124197189,
                -28.821765467490504
              ],
              [
                -56.29970929349256,
                -28.813199493943834
              ],
              [
                -56.296488324454835,
                -28.80733999374377
              ],
              [
                -56.29137439994652,
                -28.799413997139627
              ],
              [
                -56.28692446086395,
                -28.793549501018145
              ],
              [
                -56.28247401978077,
                -28.787684005909824
              ],
              [
                -56.26249275414013,
                -28.77752893106042
              ],
              [
                -56.24500084682092,
                -28.776146367055684
              ],
              [
                -56.23847790436101,
                -28.7757648497343
              ],
              [
                -56.23687493353489,
                -28.775671342511565
              ],
              [
                -56.22500700415804,
                -28.77488681768003
              ],
              [
                -56.218158570499405,
                -28.77423430974523
              ],
              [
                -56.205900685140385,
                -28.773037747407262
              ],
              [
                -56.196639751099035,
                -28.772579714271444
              ],
              [
                -56.192145761180655,
                -28.772332700801137
              ],
              [
                -56.18882982873986,
                -28.77187868660331
              ],
              [
                -56.182857872070514,
                -28.768073659247474
              ],
              [
                -56.17741093305891,
                -28.762630684189737
              ],
              [
                -56.16966505280639,
                -28.751208681147812
              ],
              [
                -56.161698642142795,
                -28.74248767836494
              ],
              [
                -56.15768673440515,
                -28.734380659943568
              ],
              [
                -56.14773086815898,
                -28.714263671383147
              ],
              [
                -56.14381892120991,
                -28.70952669079685
              ],
              [
                -56.13645703587937,
                -28.701222205508017
              ],
              [
                -56.12444567448024,
                -28.686720183490618
              ],
              [
                -56.12154372706428,
                -28.68431967500881
              ],
              [
                -56.11615230238744,
                -28.679873158798273
              ],
              [
                -56.114124319050276,
                -28.678351650572253
              ],
              [
                -56.10865988711781,
                -28.674251157193787
              ],
              [
                -56.080248180545375,
                -28.651191586672816
              ],
              [
                -56.07304525987109,
                -28.64484311519213
              ],
              [
                -56.07064731859694,
                -28.642667116202897
              ],
              [
                -56.06930429940612,
                -28.641502596829827
              ],
              [
                -56.063491903816,
                -28.636464103563906
              ],
              [
                -56.060608917341376,
                -28.6342380897715
              ],
              [
                -56.05573795625949,
                -28.629705551984603
              ],
              [
                -56.04505509975914,
                -28.622821063037414
              ],
              [
                -56.03962411588092,
                -28.621185560543452
              ],
              [
                -56.03281271446902,
                -28.618700031733024
              ],
              [
                -56.02419530565985,
                -28.615554991417152
              ],
              [
                -56.019456814399135,
                -28.613593484553306
              ],
              [
                -56.014017870885134,
                -28.60956298125593
              ],
              [
                -56.00199851174532,
                -28.598861969979158
              ],
              [
                -55.99997756471816,
                -28.591610974726933
              ],
              [
                -55.99901159295099,
                -28.5845560051894
              ],
              [
                -56.00185714071262,
                -28.574879027689107
              ],
              [
                -56.007130123555896,
                -28.5683540486916
              ],
              [
                -56.01208361675346,
                -28.562020612920147
              ],
              [
                -56.01907162638443,
                -28.553086131026443
              ],
              [
                -56.0248066511045,
                -28.538691227330904
              ],
              [
                -56.02434466979318,
                -28.533155242887904
              ],
              [
                -56.023427678355084,
                -28.52770621640954
              ],
              [
                -56.022473237606675,
                -28.52452222489129
              ],
              [
                -56.02026326003016,
                -28.520751730897842
              ],
              [
                -56.015391818216024,
                -28.5128092631712
              ],
              [
                -56.010431907753535,
                -28.507075728353335
              ],
              [
                -56.00215048014681,
                -28.503971736460848
              ],
              [
                -55.99112109222156,
                -28.49938220151582
              ],
              [
                -55.98505712189798,
                -28.497162180169852
              ],
              [
                -55.95606187930576,
                -28.494953560538146
              ],
              [
                -55.94871039396672,
                -28.49439406275066
              ],
              [
                -55.943977948413526,
                -28.492667026442533
              ],
              [
                -55.940129969804076,
                -28.491250521843345
              ],
              [
                -55.940075473302365,
                -28.49123103467757
              ],
              [
                -55.93557004347747,
                -28.489428003478235
              ],
              [
                -55.91556972649312,
                -28.482056956268085
              ],
              [
                -55.912698740297294,
                -28.481708453769226
              ],
              [
                -55.909826771109806,
                -28.48135996026712
              ],
              [
                -55.89283587711105,
                -28.480473880063222
              ],
              [
                -55.88932644494739,
                -28.480254365693774
              ],
              [
                -55.88550294915912,
                -28.47962784514641
              ],
              [
                -55.87993353711676,
                -28.47193687423034
              ],
              [
                -55.881581589045375,
                -28.45935138386874
              ],
              [
                -55.883664605693504,
                -28.455434406442606
              ],
              [
                -55.88497007287179,
                -28.451549921844556
              ],
              [
                -55.88625808491324,
                -28.447716960042634
              ],
              [
                -55.8965111613295,
                -28.422213036867713
              ],
              [
                -55.89874012879611,
                -28.416540104651162
              ],
              [
                -55.90079166343763,
                -28.409510616307077
              ],
              [
                -55.90104470335688,
                -28.406401126504583
              ],
              [
                -55.89918624426186,
                -28.39746764568731
              ],
              [
                -55.89766727994615,
                -28.394594618243776
              ],
              [
                -55.890249865026576,
                -28.384179649335643
              ],
              [
                -55.880551999848485,
                -28.370194158872575
              ],
              [
                -55.87164414515953,
                -28.358516633495867
              ],
              [
                -55.86927712087763,
                -28.35824313285288
              ],
              [
                -55.86141868502942,
                -28.35733460816309
              ],
              [
                -55.849033790790344,
                -28.355350561421705
              ],
              [
                -55.84027634899068,
                -28.35732304355449
              ],
              [
                -55.82314247225398,
                -28.362926936928933
              ],
              [
                -55.820572976865286,
                -28.363565450072915
              ],
              [
                -55.81839147512338,
                -28.364106936008753
              ],
              [
                -55.811237017945544,
                -28.36482740580204
              ],
              [
                -55.80238061650894,
                -28.365300880521993
              ],
              [
                -55.79154819105291,
                -28.365879337153316
              ],
              [
                -55.759917401910506,
                -28.367522190746584
              ],
              [
                -55.75486393523053,
                -28.3680737033071
              ],
              [
                -55.745307024556865,
                -28.371213160186024
              ],
              [
                -55.7403890373741,
                -28.37404511921526
              ],
              [
                -55.73293056874302,
                -28.38125405555683
              ],
              [
                -55.72889755726311,
                -28.38384903571137
              ],
              [
                -55.727602092899325,
                -28.38699155223
              ],
              [
                -55.72475105789077,
                -28.393907002563598
              ],
              [
                -55.71850997663423,
                -28.416862432854604
              ],
              [
                -55.71474702796374,
                -28.419102409014435
              ],
              [
                -55.711108512512546,
                -28.422106361635798
              ],
              [
                -55.70704504151701,
                -28.425835368648176
              ],
              [
                -55.70362808711312,
                -28.42574383437098
              ],
              [
                -55.699502583310476,
                -28.425575836494332
              ],
              [
                -55.69556913730166,
                -28.42082383756812
              ],
              [
                -55.69061821078345,
                -28.416267314106666
              ],
              [
                -55.68985121847456,
                -28.41309534451421
              ],
              [
                -55.68932073633955,
                -28.409939842815174
              ],
              [
                -55.68936327309843,
                -28.407355357863327
              ],
              [
                -55.6894247542129,
                -28.40368386588158
              ],
              [
                -55.68942779087117,
                -28.40078036062545
              ],
              [
                -55.68899880832676,
                -28.39241186022175
              ],
              [
                -55.68782086088282,
                -28.388277380584615
              ],
              [
                -55.67199967145829,
                -28.351781412515837
              ],
              [
                -55.667245714928264,
                -28.340571435732432
              ],
              [
                -55.66747276505118,
                -28.333267982165612
              ],
              [
                -55.672065756532135,
                -28.327458020821236
              ],
              [
                -55.680528746079744,
                -28.31896206591977
              ],
              [
                -55.68572372942412,
                -28.316074592124668
              ],
              [
                -55.69348365275273,
                -28.313220617349433
              ],
              [
                -55.6968911579865,
                -28.310781657306066
              ],
              [
                -55.71134557184737,
                -28.30043622795255
              ],
              [
                -55.71416156364535,
                -28.29835274462386
              ],
              [
                -55.716364047595825,
                -28.29673725762506
              ],
              [
                -55.7310930227732,
                -28.28593532055896
              ],
              [
                -55.74409796093271,
                -28.280244413021872
              ],
              [
                -55.74953589254397,
                -28.280340406194036
              ],
              [
                -55.760335831275626,
                -28.277164472242312
              ],
              [
                -55.76484581002615,
                -28.275805486646863
              ],
              [
                -55.77253324923003,
                -28.27230802328582
              ],
              [
                -55.77414478389199,
                -28.26962702879805
              ],
              [
                -55.780069289468635,
                -28.25689057017022
              ],
              [
                -55.780714301197044,
                -28.253416117996668
              ],
              [
                -55.77890530756558,
                -28.25080709684546
              ],
              [
                -55.77334587072614,
                -28.244753617140063
              ],
              [
                -55.77021440899483,
                -28.24190359443552
              ],
              [
                -55.76705994999594,
                -28.240933101614047
              ],
              [
                -55.748558099469676,
                -28.233004561029464
              ],
              [
                -55.74003870281451,
                -28.23064201684436
              ],
              [
                -55.73151776318078,
                -28.228278996664308
              ],
              [
                -55.710203417750144,
                -28.22545790641975
              ],
              [
                -55.706700976628404,
                -28.224426927419135
              ],
              [
                -55.69817854433424,
                -28.221605871498408
              ],
              [
                -55.695128608682346,
                -28.219419402388876
              ],
              [
                -55.69108613868741,
                -28.216160896265094
              ],
              [
                -55.669921871680415,
                -28.197468850461306
              ],
              [
                -55.666735409158576,
                -28.194655365535468
              ],
              [
                -55.657414531249465,
                -28.18653134086403
              ],
              [
                -55.654569556003885,
                -28.185360346860822
              ],
              [
                -55.648715583049594,
                -28.182952307393805
              ],
              [
                -55.63861517467449,
                -28.179469299181456
              ],
              [
                -55.630739248601344,
                -28.175857266056397
              ],
              [
                -55.62826130143193,
                -28.17272976079565
              ],
              [
                -55.62556934847041,
                -28.166669782631416
              ],
              [
                -55.62634289045258,
                -28.16052431828896
              ],
              [
                -55.62667886829705,
                -28.157855808826408
              ],
              [
                -55.622879420604725,
                -28.15316830362712
              ],
              [
                -55.61935848235391,
                -28.14878278970932
              ],
              [
                -55.62043500113848,
                -28.141020313952918
              ],
              [
                -55.61978905322594,
                -28.13573483574591
              ],
              [
                -55.61296214958567,
                -28.12365187478512
              ],
              [
                -55.60779470701643,
                -28.115726845071716
              ],
              [
                -55.60373123989462,
                -28.11532284927194
              ],
              [
                -55.59421980354777,
                -28.115979814281655
              ],
              [
                -55.59155281010216,
                -28.11840227626934
              ],
              [
                -55.58413987336417,
                -28.122069259310788
              ],
              [
                -55.585216801451665,
                -28.130705723067546
              ],
              [
                -55.58670827229397,
                -28.13536222226226
              ],
              [
                -55.5878352824821,
                -28.138880244124838
              ],
              [
                -55.58753075325726,
                -28.14218519196462
              ],
              [
                -55.58580125015393,
                -28.146565210310435
              ],
              [
                -55.5691568005172,
                -28.157419121695174
              ],
              [
                -55.56511732195392,
                -28.16028607209891
              ],
              [
                -55.56235083234747,
                -28.16228558784041
              ],
              [
                -55.556956391551736,
                -28.161985556532578
              ],
              [
                -55.54937994606554,
                -28.15521652525318
              ],
              [
                -55.547554999133766,
                -28.152079030634127
              ],
              [
                -55.547068508346335,
                -28.148339033893045
              ],
              [
                -55.545980574091885,
                -28.139355576056516
              ],
              [
                -55.545351600555314,
                -28.134166087708806
              ],
              [
                -55.540147696957916,
                -28.124098615752196
              ],
              [
                -55.53674973890304,
                -28.12170009699785
              ],
              [
                -55.52965730177528,
                -28.119356563657135
              ],
              [
                -55.51821487167734,
                -28.115405552707507
              ],
              [
                -55.51473391228845,
                -28.114203531383854
              ],
              [
                -55.51108348094409,
                -28.112439032934105
              ],
              [
                -55.5093860001583,
                -28.108472048124174
              ],
              [
                -55.50791753602637,
                -28.105039526754247
              ],
              [
                -55.50171264848903,
                -28.087564547244117
              ],
              [
                -55.49836268303666,
                -28.082954535768355
              ],
              [
                -55.495351740666614,
                -28.079033051740268
              ],
              [
                -55.49295824619433,
                -28.07723904230805
              ],
              [
                -55.48987678223577,
                -28.076565032130556
              ],
              [
                -55.4831933163463,
                -28.07699403388644
              ],
              [
                -55.47128839442516,
                -28.085795441365416
              ],
              [
                -55.468970383051,
                -28.087702461104428
              ],
              [
                -55.46533239938734,
                -28.09183092961497
              ],
              [
                -55.46289240463704,
                -28.09459989751883
              ],
              [
                -55.456211446612485,
                -28.09747835957514
              ],
              [
                -55.45289043967974,
                -28.09836936617915
              ],
              [
                -55.44448804615773,
                -28.096250343199408
              ],
              [
                -55.4415250740778,
                -28.09321281096016
              ],
              [
                -55.44021309144024,
                -28.091259817192725
              ],
              [
                -55.4366761606309,
                -28.085995355824533
              ],
              [
                -55.430385725329984,
                -28.077258844253574
              ],
              [
                -55.42458178394648,
                -28.07003135046362
              ],
              [
                -55.41912886077676,
                -28.062840848959464
              ],
              [
                -55.414589956009955,
                -28.05622432845648
              ],
              [
                -55.41121495434892,
                -28.05371031222295
              ],
              [
                -55.3998410672703,
                -28.047847798063092
              ],
              [
                -55.391160181201016,
                -28.043829780333745
              ],
              [
                -55.38907967138618,
                -28.04285277392721
              ],
              [
                -55.378635282729654,
                -28.037946265804063
              ],
              [
                -55.37462033685405,
                -28.03582424603363
              ],
              [
                -55.37391584386992,
                -28.034647235537246
              ],
              [
                -55.371928886928856,
                -28.031329254955324
              ],
              [
                -55.369416913561956,
                -28.027261773402252
              ],
              [
                -55.36939942506894,
                -28.02718524854528
              ],
              [
                -55.36875444209553,
                -28.02429624900517
              ],
              [
                -55.36715994975078,
                -28.019660752594085
              ],
              [
                -55.36710149644954,
                -28.01555428369791
              ],
              [
                -55.36946999872844,
                -28.011143803074702
              ],
              [
                -55.371560963695245,
                -28.007211322054083
              ],
              [
                -55.371885999058726,
                -28.006651310862885
              ],
              [
                -55.37369399807449,
                -28.003537338483753
              ],
              [
                -55.37975696875306,
                -28.001221390433574
              ],
              [
                -55.382003943390124,
                -27.996107912278124
              ],
              [
                -55.383726513861845,
                -27.98653740239436
              ],
              [
                -55.383098019205505,
                -27.982944449877266
              ],
              [
                -55.37736357883029,
                -27.977805928809424
              ],
              [
                -55.37324263945158,
                -27.975122901228172
              ],
              [
                -55.36137722181885,
                -27.9737408699963
              ],
              [
                -55.35068127793603,
                -27.97269135739375
              ],
              [
                -55.34653784952593,
                -27.972284828442124
              ],
              [
                -55.34236485360725,
                -27.97060430890505
              ],
              [
                -55.3375549516788,
                -27.965004308758896
              ],
              [
                -55.33717395867643,
                -27.964560307511412
              ],
              [
                -55.335678466939314,
                -27.960043323229055
              ],
              [
                -55.33457751422785,
                -27.954520828268965
              ],
              [
                -55.33425351362237,
                -27.952893361525256
              ],
              [
                -55.33098609121198,
                -27.93937035537387
              ],
              [
                -55.329047603502886,
                -27.93632135532541
              ],
              [
                -55.32716166577843,
                -27.93299235826095
              ],
              [
                -55.31991671846378,
                -27.926436365408293
              ],
              [
                -55.3160352847549,
                -27.924678348262656
              ],
              [
                -55.31225932065087,
                -27.92447232821404
              ],
              [
                -55.30376888259138,
                -27.92462883790525
              ],
              [
                -55.29677740974003,
                -27.927379292187965
              ],
              [
                -55.29153792554341,
                -27.92919424385466
              ],
              [
                -55.28513397156894,
                -27.93231071739174
              ],
              [
                -55.280736481864395,
                -27.933574694849888
              ],
              [
                -55.276530026021184,
                -27.934783687200508
              ],
              [
                -55.26994759799677,
                -27.93329267286863
              ],
              [
                -55.263702650712496,
                -27.93134714011384
              ],
              [
                -55.25892069503951,
                -27.926868645083005
              ],
              [
                -55.25470925733593,
                -27.921739153083394
              ],
              [
                -55.251282811536726,
                -27.91687163539963
              ],
              [
                -55.248110345472966,
                -27.911877637059145
              ],
              [
                -55.2475373682108,
                -27.910959177405786
              ],
              [
                -55.243513937534864,
                -27.904515153832413
              ],
              [
                -55.23225409697492,
                -27.885279182054898
              ],
              [
                -55.22831260543616,
                -27.881536186296618
              ],
              [
                -55.225849669082514,
                -27.879403665749347
              ],
              [
                -55.217459747814836,
                -27.87292566048187
              ],
              [
                -55.20671535743664,
                -27.86471264201268
              ],
              [
                -55.1994279485169,
                -27.858537637202453
              ],
              [
                -55.195809470622464,
                -27.85621714475107
              ],
              [
                -55.19048802493281,
                -27.856026118761978
              ],
              [
                -55.1819021039119,
                -27.85634656875019
              ],
              [
                -55.17319466850456,
                -27.859391033626874
              ],
              [
                -55.16892766935218,
                -27.861591542995228
              ],
              [
                -55.1670016966217,
                -27.863842526249364
              ],
              [
                -55.16411217503187,
                -27.86732100534184
              ],
              [
                -55.16194865976108,
                -27.870247489783463
              ],
              [
                -55.161538169968296,
                -27.870827466572386
              ],
              [
                -55.15714270518275,
                -27.877038431186662
              ],
              [
                -55.15447919003485,
                -27.880083418366418
              ],
              [
                -55.14542472767449,
                -27.887131366654877
              ],
              [
                -55.13967776314214,
                -27.89186131720651
              ],
              [
                -55.13614027310494,
                -27.894777288373056
              ],
              [
                -55.13189626879515,
                -27.89575230425994
              ],
              [
                -55.12656386396567,
                -27.892961276520445
              ],
              [
                -55.123275916236764,
                -27.883772798644948
              ],
              [
                -55.12322142159261,
                -27.876821322968837
              ],
              [
                -55.12376197021199,
                -27.87341631862373
              ],
              [
                -55.12457947629391,
                -27.8682663372535
              ],
              [
                -55.125620002009285,
                -27.863261369337906
              ],
              [
                -55.12547201426919,
                -27.860358870930636
              ],
              [
                -55.123071525537625,
                -27.85595635906499
              ],
              [
                -55.12000006890672,
                -27.852961870657346
              ],
              [
                -55.117553084453114,
                -27.851442345507294
              ],
              [
                -55.10662569436041,
                -27.84633333480488
              ],
              [
                -55.087562367288456,
                -27.840878292782094
              ],
              [
                -55.08066392776544,
                -27.842866286620346
              ],
              [
                -55.079940922080844,
                -27.84308124623094
              ],
              [
                -55.07883190270399,
                -27.8434102460339
              ],
              [
                -55.073091984978454,
                -27.84511473613097
              ],
              [
                -55.06266503963125,
                -27.848339184897636
              ],
              [
                -55.051654568199766,
                -27.853089615109102
              ],
              [
                -55.044791109320435,
                -27.85469612215636
              ],
              [
                -55.04175966673431,
                -27.855741105537994
              ],
              [
                -55.03963163896235,
                -27.85647409427763
              ],
              [
                -55.03579268490734,
                -27.855152080236923
              ],
              [
                -55.03132724912538,
                -27.85493456466709
              ],
              [
                -55.025784783261265,
                -27.849190560574915
              ],
              [
                -55.02323083455648,
                -27.845015557554618
              ],
              [
                -55.024450357958045,
                -27.83752555642876
              ],
              [
                -55.025003361065394,
                -27.833371607306848
              ],
              [
                -55.02737188028985,
                -27.827655106588367
              ],
              [
                -55.03067486233141,
                -27.825072143595616
              ],
              [
                -55.03487735289081,
                -27.82306614542767
              ],
              [
                -55.05283474063445,
                -27.813604734226384
              ],
              [
                -55.056112735437594,
                -27.811522752716144
              ],
              [
                -55.05850673207241,
                -27.81000179222054
              ],
              [
                -55.080369144729424,
                -27.79749587661958
              ],
              [
                -55.081924142968276,
                -27.793016936260877
              ],
              [
                -55.08303915215181,
                -27.789837415537562
              ],
              [
                -55.08205467759949,
                -27.785483929069112
              ],
              [
                -55.08034270830726,
                -27.779097427563222
              ],
              [
                -55.07120780968007,
                -27.772783444355998
              ],
              [
                -55.06731636569513,
                -27.771101411178506
              ],
              [
                -55.05813490961133,
                -27.76886842025762
              ],
              [
                -55.05474642984299,
                -27.768228876063628
              ],
              [
                -55.04917048918513,
                -27.768025379238573
              ],
              [
                -55.04131252736108,
                -27.77361430822887
              ],
              [
                -55.04076552637693,
                -27.77424133535107
              ],
              [
                -55.03364805060603,
                -27.7823977888995
              ],
              [
                -55.029804562754954,
                -27.786801237657464
              ],
              [
                -55.028313060528085,
                -27.788510239085156
              ],
              [
                -55.02368556910791,
                -27.792787723188706
              ],
              [
                -55.01963607801789,
                -27.795084204127576
              ],
              [
                -55.0102211589611,
                -27.7969291517438
              ],
              [
                -55.00428468604076,
                -27.794664129377185
              ],
              [
                -55.00082526600097,
                -27.79275460751591
              ],
              [
                -54.99797679521214,
                -27.790682641463555
              ],
              [
                -54.99633578514247,
                -27.78764460779091
              ],
              [
                -54.99566730410682,
                -27.78640811773671
              ],
              [
                -54.99382282441,
                -27.78236711713618
              ],
              [
                -54.99191486935215,
                -27.778290647397274
              ],
              [
                -54.98901891440431,
                -27.772610144432836
              ],
              [
                -54.97724499209404,
                -27.77111811052174
              ],
              [
                -54.97242904995391,
                -27.772752089406573
              ],
              [
                -54.960952113794875,
                -27.777176025810977
              ],
              [
                -54.960130100365085,
                -27.77749353275545
              ],
              [
                -54.94865471671033,
                -27.777689974197003
              ],
              [
                -54.941986259964295,
                -27.774867489670303
              ],
              [
                -54.93607981646762,
                -27.772062978021093
              ],
              [
                -54.93379888820008,
                -27.767361474678804
              ],
              [
                -54.93226289278283,
                -27.76361095448528
              ],
              [
                -54.92937642634499,
                -27.75656696751591
              ],
              [
                -54.928944948885544,
                -27.755051970185008
              ],
              [
                -54.927720009791386,
                -27.750753993756586
              ],
              [
                -54.924247032024596,
                -27.745501517449885
              ],
              [
                -54.91364067400416,
                -27.735758968678116
              ],
              [
                -54.912507649116435,
                -27.734789467112893
              ],
              [
                -54.90650021363188,
                -27.72964995581929
              ],
              [
                -54.90367326060246,
                -27.725314488403942
              ],
              [
                -54.90248231210183,
                -27.719176995407125
              ],
              [
                -54.90142291034856,
                -27.701282528582713
              ],
              [
                -54.90126343754917,
                -27.69858456770087
              ],
              [
                -54.902729499085794,
                -27.681694102702206
              ],
              [
                -54.904786597238115,
                -27.654817188651734
              ],
              [
                -54.905479627598,
                -27.647229740078156
              ],
              [
                -54.90501462022274,
                -27.64282922594103
              ],
              [
                -54.904024658878434,
                -27.640064236106767
              ],
              [
                -54.90341467516527,
                -27.636788236191713
              ],
              [
                -54.89967870907667,
                -27.63176574737854
              ],
              [
                -54.89623674207673,
                -27.628557752477985
              ],
              [
                -54.89012083422985,
                -27.62570772436072
              ],
              [
                -54.884524894278286,
                -27.62457320029644
              ],
              [
                -54.876907445139956,
                -27.62306818559886
              ],
              [
                -54.87309447701217,
                -27.62400067238534
              ],
              [
                -54.85713957993054,
                -27.62790260535749
              ],
              [
                -54.854054581603286,
                -27.626086112836532
              ],
              [
                -54.849745146505995,
                -27.623203092727202
              ],
              [
                -54.84777817385643,
                -27.62013761007063
              ],
              [
                -54.83918480495796,
                -27.605305622129688
              ],
              [
                -54.838565310853795,
                -27.602535124767513
              ],
              [
                -54.83565239931058,
                -27.588811673309447
              ],
              [
                -54.832824988515746,
                -27.572282182326965
              ],
              [
                -54.83081455029431,
                -27.56626070209823
              ],
              [
                -54.82907007926889,
                -27.561036730581122
              ],
              [
                -54.827768090401854,
                -27.557807704977762
              ],
              [
                -54.816479790032574,
                -27.535077768941765
              ],
              [
                -54.814945290720225,
                -27.532299268172025
              ],
              [
                -54.811593829257305,
                -27.529241236319503
              ],
              [
                -54.807186894293075,
                -27.528351245176793
              ],
              [
                -54.79954591994462,
                -27.529231208628804
              ],
              [
                -54.79559547395201,
                -27.531616677716308
              ],
              [
                -54.79428094110136,
                -27.53635617097537
              ],
              [
                -54.79240242735055,
                -27.544045625538427
              ],
              [
                -54.79113241304171,
                -27.5504831210472
              ],
              [
                -54.790351384949204,
                -27.554438615612547
              ],
              [
                -54.787908865733,
                -27.565606535964392
              ],
              [
                -54.785480347396096,
                -27.57092053834034
              ],
              [
                -54.78391433871388,
                -27.574545521887817
              ],
              [
                -54.78036086121082,
                -27.578926997692452
              ],
              [
                -54.774409380410226,
                -27.585221934840767
              ],
              [
                -54.76665042091948,
                -27.584029401812334
              ],
              [
                -54.76203147094423,
                -27.58306590205526
              ],
              [
                -54.758559019691404,
                -27.582465416603608
              ],
              [
                -54.75455357382627,
                -27.577806922897256
              ],
              [
                -54.752568071533936,
                -27.57489841179328
              ],
              [
                -54.75186609678636,
                -27.57363939478015
              ],
              [
                -54.7467901801587,
                -27.5645314119423
              ],
              [
                -54.741670222811386,
                -27.56128892092385
              ],
              [
                -54.73318878295435,
                -27.561931885866642
              ],
              [
                -54.72263885595635,
                -27.563113816396235
              ],
              [
                -54.710176445079846,
                -27.56663176987969
              ],
              [
                -54.69953051642564,
                -27.569365749534693
              ],
              [
                -54.697846539924456,
                -27.569797722888833
              ],
              [
                -54.68665560254088,
                -27.57333118414962
              ],
              [
                -54.681963123135354,
                -27.57425716365202
              ],
              [
                -54.67755464255774,
                -27.572055155336894
              ],
              [
                -54.67258521512302,
                -27.569653138480863
              ],
              [
                -54.67143626284889,
                -27.56585263219174
              ],
              [
                -54.67106378954011,
                -27.557911655913582
              ],
              [
                -54.67140380108008,
                -27.550351683235235
              ],
              [
                -54.67408183673119,
                -27.542671724774188
              ],
              [
                -54.67894282593412,
                -27.528730294849932
              ],
              [
                -54.67850590754107,
                -27.51732480847587
              ],
              [
                -54.67812292509276,
                -27.513819844257057
              ],
              [
                -54.674486976318015,
                -27.50770834533868
              ],
              [
                -54.67238998062152,
                -27.504628826397326
              ],
              [
                -54.66381704668284,
                -27.505237799174612
              ],
              [
                -54.656648092780316,
                -27.51141375399566
              ],
              [
                -54.653179091630896,
                -27.51457274363467
              ],
              [
                -54.65050857429212,
                -27.52176969949635
              ],
              [
                -54.65058509191374,
                -27.52382870177057
              ],
              [
                -54.65065308669362,
                -27.525657707684996
              ],
              [
                -54.648186565737355,
                -27.53161214595382
              ],
              [
                -54.64484705778234,
                -27.538932620929288
              ],
              [
                -54.64306054453168,
                -27.54220312561345
              ],
              [
                -54.640211058916606,
                -27.543229588823255
              ],
              [
                -54.63241161266375,
                -27.54501305907364
              ],
              [
                -54.62817968723156,
                -27.54397304508361
              ],
              [
                -54.622521222695354,
                -27.54141454513127
              ],
              [
                -54.62129424350411,
                -27.5408600421059
              ],
              [
                -54.61534233283811,
                -27.533751544354864
              ],
              [
                -54.613204854327996,
                -27.52776305678111
              ],
              [
                -54.60890395896726,
                -27.514421065558242
              ],
              [
                -54.607805951295084,
                -27.51124208371441
              ],
              [
                -54.60644248613008,
                -27.508583076346053
              ],
              [
                -54.60486702934686,
                -27.50554558028706
              ],
              [
                -54.603953036554266,
                -27.503783601993916
              ],
              [
                -54.6021525357225,
                -27.50047812187382
              ],
              [
                -54.60142656719357,
                -27.499206088857864
              ],
              [
                -54.60024907480214,
                -27.4971420974574
              ],
              [
                -54.5973136081664,
                -27.49200112343739
              ],
              [
                -54.59105173760911,
                -27.480284119156988
              ],
              [
                -54.59090173146673,
                -27.478444137991342
              ],
              [
                -54.58969029899184,
                -27.463595685021737
              ],
              [
                -54.58831481231349,
                -27.460845666909385
              ],
              [
                -54.58664584979208,
                -27.457470164529905
              ],
              [
                -54.58470788084086,
                -27.454186674868044
              ],
              [
                -54.57771395255262,
                -27.453457147622984
              ],
              [
                -54.5738299783774,
                -27.45328762446758
              ],
              [
                -54.56671449964849,
                -27.457485628273435
              ],
              [
                -54.56018002520157,
                -27.46387306182876
              ],
              [
                -54.54969554490796,
                -27.47434648745599
              ],
              [
                -54.547266596556256,
                -27.476773498150003
              ],
              [
                -54.54548508769516,
                -27.478708990733125
              ],
              [
                -54.54359711108479,
                -27.480759467576
              ],
              [
                -54.538650078357975,
                -27.49439539680325
              ],
              [
                -54.535499029685056,
                -27.50307987737637
              ],
              [
                -54.52971910317969,
                -27.505343357892766
              ],
              [
                -54.524182615385044,
                -27.50433833293704
              ],
              [
                -54.518653177125195,
                -27.50131532194633
              ],
              [
                -54.51606621586442,
                -27.497866295324968
              ],
              [
                -54.51417526604999,
                -27.494067820363597
              ],
              [
                -54.510336321382056,
                -27.486355814576683
              ],
              [
                -54.502851419264466,
                -27.480483322632917
              ],
              [
                -54.49228897141311,
                -27.47920226856595
              ],
              [
                -54.488109510810474,
                -27.47939126024551
              ],
              [
                -54.47713511828224,
                -27.480174214549262
              ],
              [
                -54.473789608209024,
                -27.480412708230034
              ],
              [
                -54.46626620207971,
                -27.479882194427336
              ],
              [
                -54.45576625691736,
                -27.478158152931496
              ],
              [
                -54.45220728786128,
                -27.47662265605043
              ],
              [
                -54.447177866292925,
                -27.47414412641582
              ],
              [
                -54.444201902345114,
                -27.472508126034626
              ],
              [
                -54.440278920578905,
                -27.467264633750506
              ],
              [
                -54.441356935882006,
                -27.46021364565796
              ],
              [
                -54.443722470554086,
                -27.457594196313483
              ],
              [
                -54.446375920573665,
                -27.455290687118847
              ],
              [
                -54.45004841214923,
                -27.45210174037986
              ],
              [
                -54.45048693866686,
                -27.451720718158043
              ],
              [
                -54.45712338758821,
                -27.44609128179634
              ],
              [
                -54.46568137020674,
                -27.436215811223395
              ],
              [
                -54.46850737162507,
                -27.428902862491718
              ],
              [
                -54.46188744824984,
                -27.422967344140353
              ],
              [
                -54.45820697084286,
                -27.422537357564032
              ],
              [
                -54.44803654828571,
                -27.42290329121444
              ],
              [
                -54.44512708411789,
                -27.423106784665844
              ],
              [
                -54.4410801352155,
                -27.42336477067699
              ],
              [
                -54.44065662926288,
                -27.4233912920052
              ],
              [
                -54.43595116254646,
                -27.422114764086803
              ],
              [
                -54.43567368514274,
                -27.422005241367618
              ],
              [
                -54.42978121727481,
                -27.41968026009589
              ],
              [
                -54.414695895640406,
                -27.40626724816748
              ],
              [
                -54.41054241199869,
                -27.404735214106037
              ],
              [
                -54.40641847388367,
                -27.40452872322217
              ],
              [
                -54.401939476259535,
                -27.406902704341633
              ],
              [
                -54.39105451509682,
                -27.415120607030957
              ],
              [
                -54.38111055229469,
                -27.429183534893994
              ],
              [
                -54.378268570677236,
                -27.43340554767431
              ],
              [
                -54.37779855886689,
                -27.434112014809788
              ],
              [
                -54.37409856936291,
                -27.43967247539052
              ],
              [
                -54.36399556407025,
                -27.453874904239694
              ],
              [
                -54.359419599362674,
                -27.459386907668577
              ],
              [
                -54.356560112210815,
                -27.461421371873687
              ],
              [
                -54.35029364850137,
                -27.464965833779267
              ],
              [
                -54.346180159479474,
                -27.46663883233788
              ],
              [
                -54.34297816935134,
                -27.464772301832387
              ],
              [
                -54.33808372364396,
                -27.461274818806913
              ],
              [
                -54.33543325539609,
                -27.45919180802555
              ],
              [
                -54.333098806483264,
                -27.456323798767173
              ],
              [
                -54.33284732284551,
                -27.44754485291128
              ],
              [
                -54.335822879311465,
                -27.435512894867934
              ],
              [
                -54.337199388956165,
                -27.430643901521176
              ],
              [
                -54.33757435895323,
                -27.42898839385429
              ],
              [
                -54.33793038570718,
                -27.42741441087396
              ],
              [
                -54.338271399606555,
                -27.42265393631785
              ],
              [
                -54.337962478934124,
                -27.411625957979595
              ],
              [
                -54.338456507710944,
                -27.403036981409272
              ],
              [
                -54.3355435104558,
                -27.40115299108655
              ],
              [
                -54.3319305229807,
                -27.401279987147344
              ],
              [
                -54.32793954971642,
                -27.40323842633309
              ],
              [
                -54.31920710320334,
                -27.410259388636238
              ],
              [
                -54.30468315641047,
                -27.423388323992548
              ],
              [
                -54.30208413286708,
                -27.42870476539427
              ],
              [
                -54.30065566755456,
                -27.431258773421643
              ],
              [
                -54.29620666346194,
                -27.439213223019237
              ],
              [
                -54.29204615784466,
                -27.44363968920807
              ],
              [
                -54.288160200734616,
                -27.44561468872852
              ],
              [
                -54.28414671406623,
                -27.447064159334648
              ],
              [
                -54.27992976428903,
                -27.445471166240424
              ],
              [
                -54.27640278113514,
                -27.44328262851526
              ],
              [
                -54.271985827710935,
                -27.44045014737117
              ],
              [
                -54.26695989970288,
                -27.437228135107866
              ],
              [
                -54.265183405330674,
                -27.434626641197884
              ],
              [
                -54.26457142955298,
                -27.43110163741641
              ],
              [
                -54.266443914195456,
                -27.42500817662553
              ],
              [
                -54.26728743578693,
                -27.42226368998119
              ],
              [
                -54.266379508902666,
                -27.414349210199678
              ],
              [
                -54.2654730084742,
                -27.40803120666615
              ],
              [
                -54.262784063537396,
                -27.40169174652044
              ],
              [
                -54.26019161835185,
                -27.3971612363399
              ],
              [
                -54.25515014124332,
                -27.39503924478485
              ],
              [
                -54.24894372632502,
                -27.392826202140895
              ],
              [
                -54.24402774663848,
                -27.39254071457182
              ],
              [
                -54.24243328663291,
                -27.392540178598335
              ],
              [
                -54.23384985520768,
                -27.39253766444271
              ],
              [
                -54.22734039730329,
                -27.391873142043124
              ],
              [
                -54.2200009425583,
                -27.387612144309635
              ],
              [
                -54.21605398502751,
                -27.385108116028842
              ],
              [
                -54.214042522546904,
                -27.382473126392206
              ],
              [
                -54.211182049677895,
                -27.37872612691289
              ],
              [
                -54.21075959981885,
                -27.373412148263704
              ],
              [
                -54.21068510206015,
                -27.367730638023822
              ],
              [
                -54.21058365557942,
                -27.35670470872464
              ],
              [
                -54.21044219009854,
                -27.353624184440935
              ],
              [
                -54.210336710689916,
                -27.351322684954784
              ],
              [
                -54.209967732448234,
                -27.345996731564696
              ],
              [
                -54.20750924703688,
                -27.34000573936423
              ],
              [
                -54.20439130427405,
                -27.332585749002405
              ],
              [
                -54.202378838895726,
                -27.328214757619175
              ],
              [
                -54.2009668958885,
                -27.325147268565413
              ],
              [
                -54.190189541608916,
                -27.3066417789903
              ],
              [
                -54.19066504983763,
                -27.298132786389996
              ],
              [
                -54.190872563137646,
                -27.296024293515774
              ],
              [
                -54.19140057124747,
                -27.290660315646363
              ],
              [
                -54.18941464233005,
                -27.28356983360476
              ],
              [
                -54.18551023089542,
                -27.26918588141857
              ],
              [
                -54.184821737760956,
                -27.267111382110446
              ],
              [
                -54.18404827444798,
                -27.26478338225149
              ],
              [
                -54.17207087905232,
                -27.254389855990183
              ],
              [
                -54.16781944781747,
                -27.253793873949622
              ],
              [
                -54.163412455665785,
                -27.252788351568856
              ],
              [
                -54.15946200631013,
                -27.255592830375626
              ],
              [
                -54.154913013570614,
                -27.261803307658344
              ],
              [
                -54.15365696855609,
                -27.265546758666478
              ],
              [
                -54.15171299044522,
                -27.2744897474051
              ],
              [
                -54.15231195275607,
                -27.279668220999145
              ],
              [
                -54.15485288937788,
                -27.284860203798885
              ],
              [
                -54.156115880814674,
                -27.2874407067204
              ],
              [
                -54.15689235001876,
                -27.292780219504458
              ],
              [
                -54.15437785216077,
                -27.29594419957883
              ],
              [
                -54.151142395968,
                -27.296704156216325
              ],
              [
                -54.11948308287168,
                -27.30110105685919
              ],
              [
                -54.11078618331869,
                -27.30240349849304
              ],
              [
                -54.104177699584056,
                -27.302732970855857
              ],
              [
                -54.102497721884205,
                -27.30281048535769
              ],
              [
                -54.09940024355899,
                -27.302954454373705
              ],
              [
                -54.08967282310978,
                -27.300338439020088
              ],
              [
                -54.08143540856503,
                -27.29812291501995
              ],
              [
                -54.077485956505264,
                -27.296803922293282
              ],
              [
                -54.0754389433825,
                -27.294353438082695
              ],
              [
                -54.06349465222393,
                -27.27704391302688
              ],
              [
                -54.06226267384128,
                -27.275258426852538
              ],
              [
                -54.05930017572145,
                -27.270853442180403
              ],
              [
                -54.05423175339294,
                -27.264750448829485
              ],
              [
                -54.04560233872061,
                -27.258689437118644
              ],
              [
                -54.02986097562566,
                -27.255437398448898
              ],
              [
                -54.02609801306452,
                -27.253924391054113
              ],
              [
                -54.02369452717469,
                -27.25049288654817
              ],
              [
                -54.02183259461804,
                -27.243101904099973
              ],
              [
                -54.01849316544094,
                -27.229851410004986
              ],
              [
                -54.01874117225906,
                -27.226691432544627
              ],
              [
                -54.01882217743769,
                -27.22566094298244
              ],
              [
                -54.019272766795766,
                -27.21172351009723
              ],
              [
                -54.018347305863585,
                -27.203952526338096
              ],
              [
                -54.017221820408544,
                -27.201140520730977
              ],
              [
                -54.01127135424184,
                -27.19598202337721
              ],
              [
                -54.008324410452154,
                -27.19447851802918
              ],
              [
                -53.99942246621682,
                -27.19413498305445
              ],
              [
                -53.98871203932005,
                -27.194292436848258
              ],
              [
                -53.9847005939028,
                -27.194873933195783
              ],
              [
                -53.980615598174026,
                -27.195642907698478
              ],
              [
                -53.9703096819869,
                -27.197583344554634
              ],
              [
                -53.966808204432716,
                -27.196719845208566
              ],
              [
                -53.96300323348121,
                -27.19535833526053
              ],
              [
                -53.95962079311926,
                -27.194044351735958
              ],
              [
                -53.95707484542793,
                -27.189281337857636
              ],
              [
                -53.955325855931044,
                -27.181449375325215
              ],
              [
                -53.9564928728232,
                -27.173093408210498
              ],
              [
                -53.957163426361745,
                -27.170803914699686
              ],
              [
                -53.9586943870117,
                -27.16557491437173
              ],
              [
                -53.958479410029284,
                -27.160736933383728
              ],
              [
                -53.952960990059665,
                -27.152229968002487
              ],
              [
                -53.94939203066181,
                -27.151007959535807
              ],
              [
                -53.9425741140461,
                -27.151030422233468
              ],
              [
                -53.93568864410717,
                -27.15231589481208
              ],
              [
                -53.93274764532038,
                -27.153181394275364
              ],
              [
                -53.92986968642926,
                -27.15548035957743
              ],
              [
                -53.92397968233978,
                -27.161012840819154
              ],
              [
                -53.92285221296247,
                -27.162071324401456
              ],
              [
                -53.913860200472705,
                -27.172757252361553
              ],
              [
                -53.90267782415989,
                -27.174236216432142
              ],
              [
                -53.891212962655885,
                -27.160657230665784
              ],
              [
                -53.88884047241968,
                -27.157450691733874
              ],
              [
                -53.88797001916977,
                -27.15330374124867
              ],
              [
                -53.886982009526974,
                -27.14791823713699
              ],
              [
                -53.88501860080467,
                -27.137216278764786
              ],
              [
                -53.87900718707123,
                -27.1294582534041
              ],
              [
                -53.87434122554892,
                -27.12724875596245
              ],
              [
                -53.866433291816435,
                -27.128014723393196
              ],
              [
                -53.86017880672049,
                -27.127668206357548
              ],
              [
                -53.85798631131933,
                -27.13156617126056
              ],
              [
                -53.85014633005265,
                -27.147506115241743
              ],
              [
                -53.848270824670244,
                -27.151311604602927
              ],
              [
                -53.84812531771141,
                -27.151541097359154
              ],
              [
                -53.84157980956584,
                -27.161863022414607
              ],
              [
                -53.835949845088216,
                -27.16642800256059
              ],
              [
                -53.83383332565324,
                -27.169339999779222
              ],
              [
                -53.82351290716426,
                -27.172505952883917
              ],
              [
                -53.816889965609455,
                -27.174390905598933
              ],
              [
                -53.81393548543478,
                -27.173644921972045
              ],
              [
                -53.810298542225475,
                -27.172726916352982
              ],
              [
                -53.80237307721844,
                -27.168840890024594
              ],
              [
                -53.778704855542564,
                -27.149564857018223
              ],
              [
                -53.77261691186227,
                -27.147791357016747
              ],
              [
                -53.76373444166995,
                -27.153757297813996
              ],
              [
                -53.756232918789074,
                -27.171688229784362
              ],
              [
                -53.74836592159401,
                -27.190492643727296
              ],
              [
                -53.74236045561227,
                -27.190826088507727
              ],
              [
                -53.73635603463289,
                -27.19115858729641
              ],
              [
                -53.73462053271502,
                -27.190311602497015
              ],
              [
                -53.73320351966452,
                -27.189619600396696
              ],
              [
                -53.7173201780199,
                -27.181865030667904
              ],
              [
                -53.71356421833403,
                -27.17952455602865
              ],
              [
                -53.706039313066135,
                -27.173542046736422
              ],
              [
                -53.68783049488661,
                -27.164746511749744
              ],
              [
                -53.675386622826785,
                -27.160747473232643
              ],
              [
                -53.66612568857364,
                -27.161745441992288
              ],
              [
                -53.65037374348503,
                -27.176448321359825
              ],
              [
                -53.64883472407557,
                -27.184493797766276
              ],
              [
                -53.64729517465658,
                -27.192539296177248
              ],
              [
                -53.64739867738318,
                -27.193294786220633
              ],
              [
                -53.64990060634552,
                -27.211490740160315
              ],
              [
                -53.647727085303984,
                -27.21767370101538
              ],
              [
                -53.641969106523376,
                -27.22039266634263
              ],
              [
                -53.63450968475546,
                -27.21855265648557
              ],
              [
                -53.62138788024477,
                -27.193711681961542
              ],
              [
                -53.61856944229532,
                -27.18837568810267
              ],
              [
                -53.61498345706717,
                -27.186967167244536
              ],
              [
                -53.61139700884563,
                -27.185558169388326
              ],
              [
                -53.59917208288115,
                -27.188805618240895
              ],
              [
                -53.58978217293297,
                -27.186941102408504
              ],
              [
                -53.563465446271174,
                -27.17304704859533
              ],
              [
                -53.56332591722295,
                -27.173071060006762
              ],
              [
                -53.548094014921475,
                -27.175741015606935
              ],
              [
                -53.526159606762555,
                -27.195774846506502
              ],
              [
                -53.51760766270126,
                -27.199517332361054
              ],
              [
                -53.509056219648734,
                -27.203259798215523
              ],
              [
                -53.49154086261506,
                -27.201653710698576
              ],
              [
                -53.490441393673336,
                -27.19477676706575
              ],
              [
                -53.490292406271344,
                -27.19315626556482
              ],
              [
                -53.48926246891673,
                -27.18190475983169
              ],
              [
                -53.50355342381739,
                -27.161064418379514
              ],
              [
                -53.50721497119716,
                -27.146859455147784
              ],
              [
                -53.50602653039179,
                -27.13790348472453
              ],
              [
                -53.49416410983704,
                -27.13266743547809
              ],
              [
                -53.48053223199527,
                -27.134793422751493
              ],
              [
                -53.46850276842212,
                -27.147711297189026
              ],
              [
                -53.46297979351701,
                -27.15163779862469
              ],
              [
                -53.45745682161835,
                -27.155563770060496
              ],
              [
                -53.41067774352043,
                -27.14034164286494
              ],
              [
                -53.400164857344286,
                -27.128007131943775
              ],
              [
                -53.39670890653932,
                -27.119974151462586
              ],
              [
                -53.3932369740107,
                -27.11190369905737
              ],
              [
                -53.382654663888836,
                -27.095937206419727
              ],
              [
                -53.37769718767756,
                -27.092673187517757
              ],
              [
                -53.37198424533483,
                -27.09048116148474
              ],
              [
                -53.36292078300964,
                -27.0936661192739
              ],
              [
                -53.36147582410695,
                -27.095840614142414
              ],
              [
                -53.351680819555725,
                -27.11057655792054
              ],
              [
                -53.347425816760754,
                -27.11698349718575
              ],
              [
                -53.34228387154143,
                -27.118628000173263
              ],
              [
                -53.310097635478215,
                -27.11823487449764
              ],
              [
                -53.29881115598504,
                -27.12607033048878
              ],
              [
                -53.294053144399065,
                -27.13393679129292
              ],
              [
                -53.29254064095024,
                -27.13940375954273
              ],
              [
                -53.293457642915016,
                -27.142246733955776
              ],
              [
                -53.296059567060546,
                -27.150312217117207
              ],
              [
                -53.30112799287961,
                -27.16348921527182
              ],
              [
                -53.31825470738691,
                -27.19474064601506
              ],
              [
                -53.320114654141385,
                -27.208889121492128
              ],
              [
                -53.31679115658799,
                -27.216066079790135
              ],
              [
                -53.3149291399233,
                -27.21626606832054
              ],
              [
                -53.30948421091419,
                -27.216852545473266
              ],
              [
                -53.291703938686695,
                -27.196040584445758
              ],
              [
                -53.252111334470364,
                -27.17740798174511
              ],
              [
                -53.249179860675646,
                -27.175282976684684
              ],
              [
                -53.24235489864859,
                -27.17033649523882
              ],
              [
                -53.2370579785692,
                -27.169392949796727
              ],
              [
                -53.23021900084567,
                -27.171389416443592
              ],
              [
                -53.2197980650893,
                -27.177424881236288
              ],
              [
                -53.196032214292096,
                -27.191190748749218
              ],
              [
                -53.188155737413524,
                -27.191041722350526
              ],
              [
                -53.18405630626306,
                -27.186592213359113
              ],
              [
                -53.18002536723632,
                -27.182218258388524
              ],
              [
                -53.17667289863014,
                -27.178579735589953
              ],
              [
                -53.17602639721336,
                -27.173857775126802
              ],
              [
                -53.172822033956194,
                -27.150438806371636
              ],
              [
                -53.16547111373857,
                -27.142263327327985
              ],
              [
                -53.15789421750305,
                -27.138408293813285
              ],
              [
                -53.14934725827489,
                -27.141873755592794
              ],
              [
                -53.14528422368477,
                -27.15561072326768
              ],
              [
                -53.14106719086165,
                -27.16986814774813
              ],
              [
                -53.13259925944987,
                -27.177834588568132
              ],
              [
                -53.12949677834251,
                -27.178040076562766
              ],
              [
                -53.11228194481049,
                -27.16968904499166
              ],
              [
                -53.07175229993999,
                -27.157090443727984
              ],
              [
                -53.070982354720975,
                -27.146827473479355
              ],
              [
                -53.07021241848269,
                -27.13656301625286
              ],
              [
                -53.070647891273694,
                -27.135436534420208
              ],
              [
                -53.078205908272594,
                -27.115897103078133
              ],
              [
                -53.075750983793796,
                -27.10346312864216
              ],
              [
                -53.07019308368195,
                -27.097935664949166
              ],
              [
                -53.058026221277274,
                -27.08583463210462
              ],
              [
                -53.04933430287318,
                -27.082806143074336
              ],
              [
                -53.02846245669235,
                -27.080096578670787
              ],
              [
                -53.022021983447836,
                -27.083128545542614
              ],
              [
                -53.01797402141012,
                -27.090205476228938
              ],
              [
                -53.02002794663269,
                -27.095037001308597
              ],
              [
                -53.02438940240006,
                -27.10529497951536
              ],
              [
                -53.026668376430436,
                -27.10802696212128
              ],
              [
                -53.04180918228449,
                -27.126175439583594
              ],
              [
                -53.04258659413806,
                -27.145509385958967
              ],
              [
                -53.03825455401708,
                -27.154154859671525
              ],
              [
                -53.02610217089699,
                -27.156705799186085
              ],
              [
                -53.01195183611092,
                -27.14217378273998
              ],
              [
                -53.00295991642736,
                -27.13293929332748
              ],
              [
                -52.99334698327027,
                -27.137805275767438
              ],
              [
                -52.98878952353158,
                -27.144790212898336
              ],
              [
                -52.98806848004765,
                -27.145895211749142
              ],
              [
                -52.98427751775783,
                -27.15170517641834
              ],
              [
                -52.98312798245927,
                -27.164235121340422
              ],
              [
                -52.99330284771068,
                -27.17570713100392
              ],
              [
                -52.997694263267356,
                -27.180659123304537
              ],
              [
                -53.00214672239603,
                -27.189539609359947
              ],
              [
                -52.99970017623855,
                -27.206382048942007
              ],
              [
                -52.98966018959155,
                -27.2173134865335
              ],
              [
                -52.97716577245059,
                -27.22068893212558
              ],
              [
                -52.97380479045455,
                -27.21870141114542
              ],
              [
                -52.96971082608553,
                -27.216281916860236
              ],
              [
                -52.965383020370496,
                -27.17997354147535
              ],
              [
                -52.95798564571047,
                -27.163028571422288
              ],
              [
                -52.95125321311461,
                -27.161601541405023
              ],
              [
                -52.93750430828339,
                -27.1707754500085
              ],
              [
                -52.93693919018476,
                -27.19366286704547
              ],
              [
                -52.936834701664914,
                -27.19789435319415
              ],
              [
                -52.9309827407205,
                -27.199883824490346
              ],
              [
                -52.92513074778451,
                -27.201872833787483
              ],
              [
                -52.90321344708609,
                -27.197896251434972
              ],
              [
                -52.89633003977454,
                -27.19229774816081
              ],
              [
                -52.88944561746542,
                -27.18669925489963
              ],
              [
                -52.862322391190034,
                -27.169087707244387
              ],
              [
                -52.85141996256983,
                -27.168611668037926
              ],
              [
                -52.85001796768038,
                -27.168550175126203
              ],
              [
                -52.843610001989845,
                -27.17759364285774
              ],
              [
                -52.845248433037234,
                -27.192795060243792
              ],
              [
                -52.84629488466233,
                -27.202504562046116
              ],
              [
                -52.84370135243287,
                -27.20607104119673
              ],
              [
                -52.83318396698198,
                -27.2090835062111
              ],
              [
                -52.822348561696295,
                -27.203615978163715
              ],
              [
                -52.817558621755985,
                -27.201198973425527
              ],
              [
                -52.808886667240166,
                -27.201983451302333
              ],
              [
                -52.800214732741374,
                -27.20276690518507
              ],
              [
                -52.786288807064196,
                -27.20461235252299
              ],
              [
                -52.77355242971336,
                -27.206786809147165
              ],
              [
                -52.7589080198368,
                -27.215174731921632
              ],
              [
                -52.763029862083734,
                -27.24297213499771
              ],
              [
                -52.75421088458378,
                -27.256721550860554
              ],
              [
                -52.742650966267725,
                -27.256255530170723
              ],
              [
                -52.73504754416497,
                -27.245309533459142
              ],
              [
                -52.73108062696826,
                -27.239598529278645
              ],
              [
                -52.721159203814004,
                -27.235223045127874
              ],
              [
                -52.71456376662145,
                -27.236387005360612
              ],
              [
                -52.70936578424759,
                -27.246615449639787
              ],
              [
                -52.707417224448015,
                -27.263898903181445
              ],
              [
                -52.70645466190936,
                -27.2724363433422
              ],
              [
                -52.699174690183796,
                -27.28122930764008
              ],
              [
                -52.69855218657702,
                -27.281980811963674
              ],
              [
                -52.69328775250383,
                -27.282617275716486
              ],
              [
                -52.691453256720266,
                -27.282839285311972
              ],
              [
                -52.684803833700215,
                -27.2753127761505
              ],
              [
                -52.6785944312001,
                -27.26801676687793
              ],
              [
                -52.67713299663663,
                -27.25660430913142
              ],
              [
                -52.67579601465529,
                -27.246160851752943
              ],
              [
                -52.66652160829511,
                -27.23789534249416
              ],
              [
                -52.6592122101052,
                -27.2376548386472
              ],
              [
                -52.652136725349955,
                -27.24854874589851
              ],
              [
                -52.644979208649254,
                -27.259568192449244
              ],
              [
                -52.630853314085996,
                -27.264444141330046
              ],
              [
                -52.613208948429275,
                -27.26328305378609
              ],
              [
                -52.60030008515142,
                -27.2548540738771
              ],
              [
                -52.58983119764743,
                -27.248018535764555
              ],
              [
                -52.57923226848185,
                -27.248356511110252
              ],
              [
                -52.570542825767134,
                -27.248633477604386
              ],
              [
                -52.56032641361818,
                -27.246784444397687
              ],
              [
                -52.54900555665738,
                -27.238214927854308
              ],
              [
                -52.54480760356965,
                -27.239914924110327
              ],
              [
                -52.53580156429354,
                -27.25953783009368
              ],
              [
                -52.53432059223082,
                -27.259666337292625
              ],
              [
                -52.496977368139376,
                -27.262906201044462
              ],
              [
                -52.47920348554244,
                -27.26444810745399
              ],
              [
                -52.48056704903592,
                -27.25277565111415
              ],
              [
                -52.48150606628966,
                -27.24473569350704
              ],
              [
                -52.47814662228339,
                -27.234873732189808
              ],
              [
                -52.44540195775559,
                -27.21716766267285
              ],
              [
                -52.44106349495918,
                -27.217740654079936
              ],
              [
                -52.43398552887667,
                -27.218674640381714
              ],
              [
                -52.43153404430413,
                -27.221909114005573
              ],
              [
                -52.43048356820564,
                -27.22329411570592
              ],
              [
                -52.43480600185726,
                -27.23313108951025
              ],
              [
                -52.441641346908945,
                -27.248686541952743
              ],
              [
                -52.440943840363,
                -27.25418204163649
              ],
              [
                -52.440246804807124,
                -27.259677501325566
              ],
              [
                -52.43451634607413,
                -27.26570296401939
              ],
              [
                -52.41880387942557,
                -27.2822243694963
              ],
              [
                -52.39534406125181,
                -27.29215626512327
              ],
              [
                -52.393336086212884,
                -27.284622291210574
              ],
              [
                -52.39935116118449,
                -27.258035403155855
              ],
              [
                -52.39199174160787,
                -27.25209137371315
              ],
              [
                -52.38062480002067,
                -27.25941779271392
              ],
              [
                -52.383342182271925,
                -27.284389755663145
              ],
              [
                -52.377309665516364,
                -27.29819917803976
              ],
              [
                -52.374868134141316,
                -27.303786150084917
              ],
              [
                -52.36460421538067,
                -27.303327631274854
              ],
              [
                -52.35579232525859,
                -27.296065616785917
              ],
              [
                -52.34602193720756,
                -27.288013597406305
              ],
              [
                -52.33739448928244,
                -27.294241552891542
              ],
              [
                -52.32876953635681,
                -27.300467518389034
              ],
              [
                -52.305927648890034,
                -27.316955385978748
              ],
              [
                -52.29763519857105,
                -27.316553360169944
              ],
              [
                -52.29300725949775,
                -27.313536341155615
              ],
              [
                -52.29167483479952,
                -27.30564936391754
              ],
              [
                -52.29677432588417,
                -27.293121422100192
              ],
              [
                -52.3024543358868,
                -27.27916700903165
              ],
              [
                -52.301251929263685,
                -27.259570548369382
              ],
              [
                -52.29327951786503,
                -27.257305010846498
              ],
              [
                -52.27670710933416,
                -27.26151747409078
              ],
              [
                -52.26070924847994,
                -27.25842741919841
              ],
              [
                -52.253418834897296,
                -27.25701991638031
              ],
              [
                -52.24939285373221,
                -27.258710394292763
              ],
              [
                -52.24739336357336,
                -27.25954937631817
              ],
              [
                -52.235543433756234,
                -27.264523315196588
              ],
              [
                -52.23449788956493,
                -27.27033877445811
              ],
              [
                -52.26339362995697,
                -27.281901331513094
              ],
              [
                -52.26462409509348,
                -27.28239384337134
              ],
              [
                -52.2772049425439,
                -27.296508827101604
              ],
              [
                -52.273886467385545,
                -27.30247482267812
              ],
              [
                -52.249599648295494,
                -27.303608234895304
              ],
              [
                -52.24471066326779,
                -27.305214215941433
              ],
              [
                -52.239822235244496,
                -27.306819181992665
              ],
              [
                -52.2237202653833,
                -27.32858355080711
              ],
              [
                -52.2125538461613,
                -27.330277021786124
              ],
              [
                -52.212186826784496,
                -27.33033252727406
              ],
              [
                -52.20681637548225,
                -27.326019026824344
              ],
              [
                -52.199913999422506,
                -27.313071040941665
              ],
              [
                -52.18373625594701,
                -27.282725602247666
              ],
              [
                -52.17334040426016,
                -27.272393581344847
              ],
              [
                -52.16601694007151,
                -27.273406063537394
              ],
              [
                -52.15913794652669,
                -27.280569994244644
              ],
              [
                -52.1652938026614,
                -27.307374953045816
              ],
              [
                -52.16621227530063,
                -27.311374422330402
              ],
              [
                -52.16356231320968,
                -27.312446912232076
              ],
              [
                -52.16091229212044,
                -27.31351939413346
              ],
              [
                -52.15047340853022,
                -27.31075739732711
              ],
              [
                -52.12093868070569,
                -27.30294229390458
              ],
              [
                -52.114164210682034,
                -27.306070766161103
              ],
              [
                -52.107389235672514,
                -27.309198718416333
              ],
              [
                -52.1064786381296,
                -27.341069639028273
              ],
              [
                -52.100181152765984,
                -27.34587110453879
              ],
              [
                -52.09749216856899,
                -27.34792108510423
              ],
              [
                -52.07256641755936,
                -27.33797553529611
              ],
              [
                -52.04835557845384,
                -27.34444842047199
              ],
              [
                -52.04027064816119,
                -27.344637378584174
              ],
              [
                -52.039395667399425,
                -27.34411788411615
              ],
              [
                -52.017761886826584,
                -27.331269865893155
              ],
              [
                -52.00876942646521,
                -27.333937330627254
              ],
              [
                -52.008236903008886,
                -27.34289780234615
              ],
              [
                -52.01903378606793,
                -27.35215180412122
              ],
              [
                -52.02193325597627,
                -27.354636783485653
              ],
              [
                -52.02007925024883,
                -27.361531295216988
              ],
              [
                -51.99681185125657,
                -27.37739914804814
              ],
              [
                -51.98680694461211,
                -27.37506813244663
              ],
              [
                -51.96147513445141,
                -27.374331037159386
              ],
              [
                -51.95841764728921,
                -27.376147026143435
              ],
              [
                -51.950503187292234,
                -27.380847978094803
              ],
              [
                -51.94935668251184,
                -27.391812451024254
              ],
              [
                -51.95392612090419,
                -27.394364945211013
              ],
              [
                -51.95811359113945,
                -27.39387444798118
              ],
              [
                -51.98809537194186,
                -27.39036508406091
              ],
              [
                -51.991388351507645,
                -27.389816569784593
              ],
              [
                -52.002335214788914,
                -27.394112099404893
              ],
              [
                -52.00732214343954,
                -27.401605084995616
              ],
              [
                -52.004630169735464,
                -27.40290057500458
              ],
              [
                -51.991026240028816,
                -27.409452515280982
              ],
              [
                -51.97407337422274,
                -27.40996494715651
              ],
              [
                -51.95539654836709,
                -27.414363398086433
              ],
              [
                -51.94469806623848,
                -27.423032798955525
              ],
              [
                -51.93372663426431,
                -27.431924261103923
              ],
              [
                -51.93600657397314,
                -27.437702723469233
              ],
              [
                -51.93828604369005,
                -27.44347824285087
              ],
              [
                -51.944383964431864,
                -27.44812522177897
              ],
              [
                -51.962039296888825,
                -27.461579731906276
              ],
              [
                -51.96328425535125,
                -27.467436750314658
              ],
              [
                -51.954712275395885,
                -27.47369016178994
              ],
              [
                -51.93522796774259,
                -27.466307141163647
              ],
              [
                -51.91557565272731,
                -27.458861095198518
              ],
              [
                -51.90338123774275,
                -27.461540565210825
              ],
              [
                -51.90010523317403,
                -27.464266538414538
              ],
              [
                -51.90046819498734,
                -27.472233524679304
              ],
              [
                -51.90056619737967,
                -27.474389993985
              ],
              [
                -51.901886610463826,
                -27.49553641968389
              ],
              [
                -51.88903760596274,
                -27.519828323714453
              ],
              [
                -51.87669923136775,
                -27.51852878913935
              ],
              [
                -51.872733777825495,
                -27.51302826570369
              ],
              [
                -51.879092312510004,
                -27.487855403051867
              ],
              [
                -51.87580188197279,
                -27.48270890694444
              ],
              [
                -51.85686755660813,
                -27.475235853733544
              ],
              [
                -51.845513114972675,
                -27.48013978750957
              ],
              [
                -51.844137628132835,
                -27.48073427957589
              ],
              [
                -51.84320363409098,
                -27.483240290982895
              ],
              [
                -51.842270128049044,
                -27.485745260395532
              ],
              [
                -51.84264062142494,
                -27.48677526936643
              ],
              [
                -51.85384342074303,
                -27.51792318412262
              ],
              [
                -51.846890948368454,
                -27.52543863631821
              ],
              [
                -51.837468034211575,
                -27.520546646099593
              ],
              [
                -51.837273511183525,
                -27.520451628704134
              ],
              [
                -51.82050771910873,
                -27.512270602625982
              ],
              [
                -51.803517269286054,
                -27.52393051781504
              ],
              [
                -51.79501881090636,
                -27.52976247088708
              ],
              [
                -51.78682138677977,
                -27.528010955879132
              ],
              [
                -51.78585094314849,
                -27.523259475920998
              ],
              [
                -51.78542494904671,
                -27.521171947214278
              ],
              [
                -51.790265415559794,
                -27.514855492463813
              ],
              [
                -51.8012083896867,
                -27.50220309160193
              ],
              [
                -51.79955243831889,
                -27.496383599671862
              ],
              [
                -51.79789698693593,
                -27.490564600749455
              ],
              [
                -51.75292585569123,
                -27.486862470989067
              ],
              [
                -51.72378744041929,
                -27.513000293595603
              ],
              [
                -51.7199649944629,
                -27.511078762061747
              ],
              [
                -51.716142048510484,
                -27.509157271529116
              ],
              [
                -51.698627815036545,
                -27.480214796340938
              ],
              [
                -51.689580885886116,
                -27.47930278462292
              ],
              [
                -51.686631887609416,
                -27.479006286934702
              ],
              [
                -51.67480145390036,
                -27.48725369198022
              ],
              [
                -51.67251298810894,
                -27.49163768226119
              ],
              [
                -51.6735714319487,
                -27.495983178777298
              ],
              [
                -51.676060870722154,
                -27.506198162153375
              ],
              [
                -51.669065902410175,
                -27.51427911223958
              ],
              [
                -51.656474980793035,
                -27.51966003395464
              ],
              [
                -51.645762613147554,
                -27.512333520315316
              ],
              [
                -51.64323619729104,
                -27.49780507805478
              ],
              [
                -51.642158217032595,
                -27.491605597598028
              ],
              [
                -51.631122291579786,
                -27.48875103412095
              ],
              [
                -51.62881683084084,
                -27.490079042406364
              ],
              [
                -51.62148883983072,
                -27.494297526000135
              ],
              [
                -51.62108236380293,
                -27.49545350871643
              ],
              [
                -51.6192813538084,
                -27.500576977301503
              ],
              [
                -51.620915815094726,
                -27.505577455715798
              ],
              [
                -51.63143958893883,
                -27.537784408663107
              ],
              [
                -51.62231164018029,
                -27.545956328712187
              ],
              [
                -51.607209267607836,
                -27.541242314565707
              ],
              [
                -51.602407842949724,
                -27.53548931214798
              ],
              [
                -51.59352646295029,
                -27.524846308113545
              ],
              [
                -51.57976257357784,
                -27.524127791712292
              ],
              [
                -51.57931000098549,
                -27.538671711089584
              ],
              [
                -51.58600985829551,
                -27.56050068138096
              ],
              [
                -51.57757339269916,
                -27.57621607007076
              ],
              [
                -51.568575910862094,
                -27.583533047387018
              ],
              [
                -51.56487544593703,
                -27.583610509728423
              ],
              [
                -51.561983482661276,
                -27.583670998054664
              ],
              [
                -51.55888151084993,
                -27.57610300975413
              ],
              [
                -51.553537605790986,
                -27.563063049396693
              ],
              [
                -51.54770819235159,
                -27.55972753630472
              ],
              [
                -51.54608820064028,
                -27.56007804528706
              ],
              [
                -51.53337826922563,
                -27.562827475231376
              ],
              [
                -51.51268096634468,
                -27.557684939353496
              ],
              [
                -51.50785750119208,
                -27.556486410873116
              ],
              [
                -51.48986114116839,
                -27.559627359432223
              ],
              [
                -51.477348714291935,
                -27.56962875713428
              ],
              [
                -51.475589119206276,
                -27.5900687172248
              ],
              [
                -51.474916605942475,
                -27.59788367198152
              ],
              [
                -51.46999965260511,
                -27.60282215488137
              ],
              [
                -51.46590814757147,
                -27.603839631715044
              ],
              [
                -51.456880222287886,
                -27.60608508663014
              ],
              [
                -51.435131805661115,
                -27.63261744329158
              ],
              [
                -51.435654759569616,
                -27.638952427271903
              ],
              [
                -51.43653318496753,
                -27.649592377342916
              ],
              [
                -51.43221271378761,
                -27.654549345338214
              ],
              [
                -51.42021683504064,
                -27.65443229426838
              ],
              [
                -51.40701700948098,
                -27.637327817959
              ],
              [
                -51.40177458331643,
                -27.63053433844145
              ],
              [
                -51.38621719988199,
                -27.62343280865221
              ],
              [
                -51.369831796007226,
                -27.63696669460508
              ],
              [
                -51.37358822983972,
                -27.650373160851785
              ],
              [
                -51.376744616198366,
                -27.661638115041587
              ],
              [
                -51.369166151587486,
                -27.671960062495145
              ],
              [
                -51.353830271937646,
                -27.679006016677008
              ],
              [
                -51.33632939814608,
                -27.672587469536246
              ],
              [
                -51.331033962348904,
                -27.67064545779485
              ],
              [
                -51.323568021855266,
                -27.672438927841746
              ],
              [
                -51.31610307236336,
                -27.674232402892727
              ],
              [
                -51.31074355767908,
                -27.69383829762349
              ],
              [
                -51.30150405479145,
                -27.70211126080569
              ],
              [
                -51.30567351774257,
                -27.707948245604285
              ],
              [
                -51.307258492917256,
                -27.710166249013167
              ],
              [
                -51.30825846851306,
                -27.71156623400808
              ],
              [
                -51.30822842495134,
                -27.718955216481177
              ],
              [
                -51.307449935956924,
                -27.719222718776457
              ],
              [
                -51.29116208374997,
                -27.724810651293115
              ],
              [
                -51.28694960510576,
                -27.724453642211078
              ],
              [
                -51.27554570182811,
                -27.723488084092565
              ],
              [
                -51.272747657680675,
                -27.733007556477144
              ],
              [
                -51.27210515082219,
                -27.735193521922028
              ],
              [
                -51.27227064421906,
                -27.737439036098397
              ],
              [
                -51.273128601102464,
                -27.74906798077718
              ],
              [
                -51.246458296714344,
                -27.760654370809135
              ],
              [
                -51.24183275464547,
                -27.77312530882698
              ],
              [
                -51.23484578326728,
                -27.77888277646759
              ],
              [
                -51.227450884603066,
                -27.773418747712142
              ],
              [
                -51.22348191866234,
                -27.770748745151668
              ],
              [
                -51.221579439777535,
                -27.769469744485114
              ],
              [
                -51.213789983163814,
                -27.772980225642293
              ],
              [
                -51.206086047050846,
                -27.77645220223562
              ],
              [
                -51.19816157604717,
                -27.77657815509863
              ],
              [
                -51.19799459837536,
                -27.77658065248525
              ],
              [
                -51.19040366372808,
                -27.776701620576883
              ],
              [
                -51.15761829972814,
                -27.802711923671676
              ],
              [
                -51.133349985948136,
                -27.805925363077083
              ],
              [
                -51.131994453642434,
                -27.818401311853385
              ],
              [
                -51.131776973185545,
                -27.820408276421436
              ],
              [
                -51.13078443120648,
                -27.829553772555695
              ],
              [
                -51.12408497165422,
                -27.8324742205968
              ],
              [
                -51.10288616554021,
                -27.829106654887855
              ],
              [
                -51.0984186817205,
                -27.828397138045375
              ],
              [
                -51.0815952855861,
                -27.834383554236865
              ],
              [
                -51.07673132605285,
                -27.840851517174357
              ],
              [
                -51.07841925915119,
                -27.85181752096662
              ],
              [
                -51.079378234815245,
                -27.858051486802037
              ],
              [
                -51.072265229276844,
                -27.86519045636826
              ],
              [
                -51.064650309112764,
                -27.866596920091244
              ],
              [
                -51.053798889607364,
                -27.867439374945864
              ],
              [
                -51.052157400782036,
                -27.87245686637422
              ],
              [
                -51.05316834905865,
                -27.877155328482647
              ],
              [
                -51.05515929349161,
                -27.886411310067064
              ],
              [
                -51.05578423132192,
                -27.901011746033344
              ],
              [
                -51.01713147048138,
                -27.925198571687964
              ],
              [
                -51.01880831032743,
                -27.954859974725
              ],
              [
                -51.010067841294735,
                -27.962563918503555
              ],
              [
                -51.00999034990595,
                -27.962566907211976
              ],
              [
                -51.000711439381924,
                -27.962863871502137
              ],
              [
                -50.99674046385355,
                -27.954524887634058
              ],
              [
                -50.99197505739256,
                -27.944518919414143
              ],
              [
                -50.98443416570711,
                -27.941808890995407
              ],
              [
                -50.9799401732782,
                -27.946735881370223
              ],
              [
                -50.97919364853479,
                -27.94755386995219
              ],
              [
                -50.97198717848952,
                -27.955630317052876
              ],
              [
                -50.94483887010539,
                -27.96855765966161
              ],
              [
                -50.94169788543292,
                -27.968566169225305
              ],
              [
                -50.928444988308165,
                -27.968603615972555
              ],
              [
                -50.924105451580765,
                -27.980493568838238
              ],
              [
                -50.927743405611594,
                -27.98777653194669
              ],
              [
                -50.92894089688468,
                -27.990173053367418
              ],
              [
                -50.92522587621703,
                -27.994875019309546
              ],
              [
                -50.91994742205404,
                -28.00155597702215
              ],
              [
                -50.92114690413679,
                -28.006371973451667
              ],
              [
                -50.92234735321019,
                -28.0111869608929
              ],
              [
                -50.892718041865265,
                -28.020263343153065
              ],
              [
                -50.87653011962879,
                -28.04087619510473
              ],
              [
                -50.87510903986869,
                -28.053266668964174
              ],
              [
                -50.8749365483281,
                -28.05476715037639
              ],
              [
                -50.88144545942254,
                -28.06757412174553
              ],
              [
                -50.8943653245335,
                -28.074994157288238
              ],
              [
                -50.89903178036839,
                -28.07767416144032
              ],
              [
                -50.90021724183159,
                -28.08219712083761
              ],
              [
                -50.901402227299876,
                -28.08671862224234
              ],
              [
                -50.88967128168221,
                -28.08877956970445
              ],
              [
                -50.88111333498751,
                -28.090282563569183
              ],
              [
                -50.875574902145296,
                -28.094314510089234
              ],
              [
                -50.86881488929376,
                -28.10889945334295
              ],
              [
                -50.870807820921414,
                -28.116067448969503
              ],
              [
                -50.87424675270035,
                -28.12843488674724
              ],
              [
                -50.86872626658249,
                -28.137231835631887
              ],
              [
                -50.85645787462082,
                -28.13687579505867
              ],
              [
                -50.85076142558195,
                -28.132391799067026
              ],
              [
                -50.83347964470041,
                -28.1187892769537
              ],
              [
                -50.827987173519574,
                -28.118723290161185
              ],
              [
                -50.82257213976828,
                -28.130294698232728
              ],
              [
                -50.81801916555903,
                -28.140023190534226
              ],
              [
                -50.80434079117898,
                -28.13944060960536
              ],
              [
                -50.79187540074743,
                -28.138909581938503
              ],
              [
                -50.78603491886747,
                -28.14284753365256
              ],
              [
                -50.788893877628865,
                -28.15169601185162
              ],
              [
                -50.79113277829552,
                -28.158628038132377
              ],
              [
                -50.786308819969875,
                -28.167775974359397
              ],
              [
                -50.78213032507886,
                -28.17570041697969
              ],
              [
                -50.78431025644216,
                -28.18020892706151
              ],
              [
                -50.788581688725095,
                -28.18904192052583
              ],
              [
                -50.788200634557896,
                -28.197915890894325
              ],
              [
                -50.77742673861954,
                -28.199936839911892
              ],
              [
                -50.7722327831219,
                -28.19491834449956
              ],
              [
                -50.76553489057938,
                -28.188447329403765
              ],
              [
                -50.759370353495854,
                -28.202032281111823
              ],
              [
                -50.7554013505749,
                -28.21324770465379
              ],
              [
                -50.75491385126064,
                -28.215671212886715
              ],
              [
                -50.75543784150826,
                -28.21900168282401
              ],
              [
                -50.748913773442844,
                -28.247151571286533
              ],
              [
                -50.711327017246376,
                -28.265129897766887
              ],
              [
                -50.69781408963847,
                -28.266316867492314
              ],
              [
                -50.69638160699624,
                -28.270242838324574
              ],
              [
                -50.69543708644808,
                -28.27758628768443
              ],
              [
                -50.6953375836604,
                -28.27835779777994
              ],
              [
                -50.694102540921314,
                -28.287961245644603
              ],
              [
                -50.673612129087225,
                -28.30309813691051
              ],
              [
                -50.66082220603065,
                -28.319209551111804
              ],
              [
                -50.658910185355104,
                -28.3216185351911
              ],
              [
                -50.65949867170769,
                -28.323300050809337
              ],
              [
                -50.66446609390924,
                -28.337495991283483
              ],
              [
                -50.64968217730719,
                -28.344994932526024
              ],
              [
                -50.64798167913038,
                -28.345857899465436
              ],
              [
                -50.62783975562301,
                -28.36354076259944
              ],
              [
                -50.630357220986724,
                -28.3723262779304
              ],
              [
                -50.63218018633585,
                -28.37868923969461
              ],
              [
                -50.62462519439841,
                -28.39097820065057
              ],
              [
                -50.61077626545138,
                -28.39288263466742
              ],
              [
                -50.602019400594976,
                -28.38483214606589
              ],
              [
                -50.59824993957048,
                -28.38136711366304
              ],
              [
                -50.59117651895068,
                -28.38165359783032
              ],
              [
                -50.58036955701894,
                -28.39001605576087
              ],
              [
                -50.57631005845609,
                -28.393156994567097
              ],
              [
                -50.562393095271005,
                -28.41404640889873
              ],
              [
                -50.54921270146611,
                -28.419696832047098
              ],
              [
                -50.54362917455959,
                -28.42663831176714
              ],
              [
                -50.536378770571034,
                -28.42860977072886
              ],
              [
                -50.52851483446901,
                -28.425915724796173
              ],
              [
                -50.52990186519217,
                -28.413733811973163
              ],
              [
                -50.530618397813164,
                -28.407437819328045
              ],
              [
                -50.527084445680146,
                -28.40359481305546
              ],
              [
                -50.51699799326975,
                -28.403775787558196
              ],
              [
                -50.49874364009282,
                -28.41219869801957
              ],
              [
                -50.470354832150925,
                -28.414367087040556
              ],
              [
                -50.45544400863294,
                -28.410417561563264
              ],
              [
                -50.44884351860359,
                -28.419195021455753
              ],
              [
                -50.44553200011132,
                -28.423598453812453
              ],
              [
                -50.433289093781546,
                -28.43055889807165
              ],
              [
                -50.422711682949576,
                -28.431083389668697
              ],
              [
                -50.4103762941369,
                -28.43169333056334
              ],
              [
                -50.39618739311065,
                -28.43239428639125
              ],
              [
                -50.393681905981225,
                -28.43574275916741
              ],
              [
                -50.38780091041765,
                -28.4436011997015
              ],
              [
                -50.379195959094716,
                -28.445494676992805
              ],
              [
                -50.37183761333751,
                -28.429045694507188
              ],
              [
                -50.369598643077275,
                -28.424041730889968
              ],
              [
                -50.35671627666142,
                -28.420409698880235
              ],
              [
                -50.34786927905312,
                -28.42814963594949
              ],
              [
                -50.34883075277781,
                -28.43722060242626
              ],
              [
                -50.35073464811847,
                -28.455192048917546
              ],
              [
                -50.345290169201206,
                -28.462795993873904
              ],
              [
                -50.33629476182248,
                -28.457492500545595
              ],
              [
                -50.337563287568834,
                -28.44847955000603
              ],
              [
                -50.33878233706333,
                -28.439817547133476
              ],
              [
                -50.32776346979575,
                -28.433754559985065
              ],
              [
                -50.30033862826333,
                -28.43987895658987
              ],
              [
                -50.29612364658549,
                -28.445290885264384
              ],
              [
                -50.291908683904104,
                -28.45070336493733
              ],
              [
                -50.2711442995623,
                -28.456242784783715
              ],
              [
                -50.251248528694575,
                -28.448393762239505
              ],
              [
                -50.262142452850135,
                -28.445681287980243
              ],
              [
                -50.26256843346972,
                -28.44437079088389
              ],
              [
                -50.26318696228282,
                -28.442465821463358
              ],
              [
                -50.246586642718924,
                -28.428740812523916
              ],
              [
                -50.23457612320503,
                -28.45642866375001
              ],
              [
                -50.2341090980192,
                -28.457504674473494
              ],
              [
                -50.22835765252759,
                -28.460026113122428
              ],
              [
                -50.2226061980439,
                -28.46254709277244
              ],
              [
                -50.2155712733739,
                -28.45666208366802
              ],
              [
                -50.20031044045357,
                -28.443896085486134
              ],
              [
                -50.19136001823469,
                -28.450293529589366
              ],
              [
                -50.18843601754204,
                -28.452383039982912
              ],
              [
                -50.18500204594223,
                -28.452351502574466
              ],
              [
                -50.176481104424724,
                -28.452272485790452
              ],
              [
                -50.16855312849041,
                -28.466028388127675
              ],
              [
                -50.1641066250738,
                -28.47374486225084
              ],
              [
                -50.161204545777885,
                -28.494551804486864
              ],
              [
                -50.156500578140125,
                -28.4971997625289
              ],
              [
                -50.13904375221172,
                -28.493714715457646
              ],
              [
                -50.145179260067756,
                -28.476927295677196
              ],
              [
                -50.14917275064879,
                -28.471899321964425
              ],
              [
                -50.15896222956221,
                -28.459572919664435
              ],
              [
                -50.14730083480647,
                -28.459093878779886
              ],
              [
                -50.14515390856114,
                -28.445993398607914
              ],
              [
                -50.12743861240973,
                -28.43784236130827
              ],
              [
                -50.1297051228426,
                -28.43094940455263
              ],
              [
                -50.12629461968462,
                -28.428853416140605
              ],
              [
                -50.11111821576961,
                -28.44472779395155
              ],
              [
                -50.115533111450915,
                -28.454589782119523
              ],
              [
                -50.11882507957409,
                -28.457326774976362
              ],
              [
                -50.12581998083788,
                -28.463142292060063
              ],
              [
                -50.12405896754847,
                -28.47381823407695
              ],
              [
                -50.10736662574502,
                -28.472606172320187
              ],
              [
                -50.09955364332614,
                -28.484784622278134
              ],
              [
                -50.09142620391911,
                -28.477369089397524
              ],
              [
                -50.08593327357223,
                -28.47235760311283
              ],
              [
                -50.0750283385451,
                -28.477278078002577
              ],
              [
                -50.072044353704015,
                -28.479304543379587
              ],
              [
                -50.0682389212426,
                -28.47798453193218
              ],
              [
                -50.061418485525905,
                -28.4756185270061
              ],
              [
                -50.05687702053942,
                -28.4759290054433
              ],
              [
                -50.035727691293225,
                -28.477375412664976
              ],
              [
                -50.02629277512672,
                -28.47244491282478
              ],
              [
                -50.02465232864133,
                -28.464074917843902
              ],
              [
                -50.023265367023676,
                -28.456998978469123
              ],
              [
                -50.018071927851125,
                -28.45184796083064
              ],
              [
                -50.01357198849086,
                -28.45088043371837
              ],
              [
                -50.00469202259611,
                -28.455436406203386
              ],
              [
                -50.0020110763274,
                -28.456812405856095
              ],
              [
                -49.9871251816399,
                -28.455435842388802
              ],
              [
                -49.98782970575133,
                -28.44813888139432
              ],
              [
                -49.988101740602445,
                -28.445321385823483
              ],
              [
                -49.97993032979456,
                -28.43978736470715
              ],
              [
                -49.97104837963394,
                -28.4386058434311
              ],
              [
                -49.966601875184494,
                -28.454137802207168
              ],
              [
                -49.95753596958097,
                -28.449296763528455
              ],
              [
                -49.950906020570635,
                -28.45011226172896
              ],
              [
                -49.9501539562509,
                -28.465716667667063
              ],
              [
                -49.950127944005835,
                -28.466245682799123
              ],
              [
                -49.94303441371763,
                -28.481491116937836
              ],
              [
                -49.92872109043298,
                -28.470229087716387
              ],
              [
                -49.92963413645355,
                -28.46558960459458
              ],
              [
                -49.930142139255544,
                -28.463007605101385
              ],
              [
                -49.93752309983605,
                -28.4579311659238
              ],
              [
                -49.93123414673797,
                -28.452998680653753
              ],
              [
                -49.9273002021181,
                -28.451091163783637
              ],
              [
                -49.90923731621784,
                -28.45975555115729
              ],
              [
                -49.90285187179898,
                -28.462818554704295
              ],
              [
                -49.89807689873884,
                -28.462723021702363
              ],
              [
                -49.88813598887371,
                -28.457577021936356
              ],
              [
                -49.88992450261142,
                -28.454231508666744
              ],
              [
                -49.895881477076045,
                -28.443085089736627
              ],
              [
                -49.89070705129284,
                -28.441055088805655
              ],
              [
                -49.882565101485824,
                -28.44690801160812
              ],
              [
                -49.87251421752956,
                -28.441543502228186
              ],
              [
                -49.857543322133054,
                -28.44311393569804
              ],
              [
                -49.855067321621824,
                -28.445055456191028
              ],
              [
                -49.87509812116022,
                -28.45339497272324
              ],
              [
                -49.87691158964801,
                -28.454150488756866
              ],
              [
                -49.87321213642162,
                -28.45626997221737
              ],
              [
                -49.86306918639417,
                -28.462079889905603
              ],
              [
                -49.86346164411874,
                -28.471130384896572
              ],
              [
                -49.86356263244843,
                -28.473462869421958
              ],
              [
                -49.868968550774476,
                -28.480118846663288
              ],
              [
                -49.866018074127226,
                -28.48680383250042
              ],
              [
                -49.85422515917842,
                -28.485208794073948
              ],
              [
                -49.85323267602143,
                -28.48507430692561
              ],
              [
                -49.84177076714096,
                -28.483524287562588
              ],
              [
                -49.84493669582992,
                -28.49279674387669
              ],
              [
                -49.83931174875068,
                -28.495737225587472
              ],
              [
                -49.82541394148075,
                -28.477741729706686
              ],
              [
                -49.81053102081472,
                -28.483335637930853
              ],
              [
                -49.81552902868228,
                -28.477890675375413
              ],
              [
                -49.81624603980713,
                -28.470767205957277
              ],
              [
                -49.816469071245294,
                -28.468550737229325
              ],
              [
                -49.81204108688638,
                -28.467913705331203
              ],
              [
                -49.80942411531332,
                -28.46753773011651
              ],
              [
                -49.80720909952658,
                -28.472524677293595
              ],
              [
                -49.80236509205563,
                -28.483428619020405
              ],
              [
                -49.79476712645364,
                -28.48786061856009
              ],
              [
                -49.79034614682568,
                -28.494811034134216
              ],
              [
                -49.78287522528841,
                -28.49577550880705
              ],
              [
                -49.78057523535075,
                -28.493624022716762
              ],
              [
                -49.78406570964918,
                -28.489265058047618
              ],
              [
                -49.78534822945585,
                -28.487662560092897
              ],
              [
                -49.77793331903116,
                -28.47535858669102
              ],
              [
                -49.78132233202835,
                -28.47167359938778
              ],
              [
                -49.77245092392439,
                -28.46663908224095
              ],
              [
                -49.768414991567305,
                -28.459481110780555
              ],
              [
                -49.76468450366858,
                -28.45966007967477
              ],
              [
                -49.76497740202712,
                -28.482350020196563
              ],
              [
                -49.769718826557906,
                -28.493801009779087
              ],
              [
                -49.764533873619534,
                -28.49917295289201
              ],
              [
                -49.76207734917552,
                -28.501718452414213
              ],
              [
                -49.760059389132024,
                -28.50081043616073
              ],
              [
                -49.74450902662604,
                -28.49381341840954
              ],
              [
                -49.73980803594529,
                -28.500640890360046
              ],
              [
                -49.744898010581906,
                -28.504544898639445
              ],
              [
                -49.74358099585157,
                -28.506840385130864
              ],
              [
                -49.7430564879551,
                -28.50775537014663
              ],
              [
                -49.73589754413528,
                -28.51254282707102
              ],
              [
                -49.72780856615698,
                -28.517951761529268
              ],
              [
                -49.738347447343926,
                -28.527060787038657
              ],
              [
                -49.74621235345742,
                -28.533858274671033
              ],
              [
                -49.74751177848059,
                -28.546152225016854
              ],
              [
                -49.73757188500924,
                -28.549158708839894
              ],
              [
                -49.72981443950548,
                -28.551504658803417
              ],
              [
                -49.725741406048634,
                -28.56660310323017
              ],
              [
                -49.724971883154204,
                -28.56945607184236
              ],
              [
                -49.719443878462684,
                -28.576457532232954
              ],
              [
                -49.719144913537875,
                -28.576330547574685
              ],
              [
                -49.71107597654858,
                -28.572901047822967
              ],
              [
                -49.707675498516515,
                -28.58262349877931
              ],
              [
                -49.71024644680219,
                -28.588921983935233
              ],
              [
                -49.71164940811864,
                -28.592359969473154
              ],
              [
                -49.70936984989345,
                -28.604191403437103
              ],
              [
                -49.69843493347662,
                -28.60618736001119
              ],
              [
                -49.69113148602072,
                -28.61844379529933
              ],
              [
                -49.69131496398802,
                -28.620266809841837
              ],
              [
                -49.69175742378936,
                -28.624663284682658
              ],
              [
                -49.704177312113934,
                -28.629034309147457
              ],
              [
                -49.7078927796758,
                -28.63000833138545
              ],
              [
                -49.70637527399869,
                -28.626644821157296
              ],
              [
                -49.709895255531244,
                -28.625403831117676
              ],
              [
                -49.71508624592428,
                -28.623574382127313
              ],
              [
                -49.727774642799034,
                -28.622861913646673
              ],
              [
                -49.73868053020065,
                -28.625212422421125
              ],
              [
                -49.75414037719914,
                -28.62854448048752
              ],
              [
                -49.75555389026744,
                -28.623235503419902
              ],
              [
                -49.757684433980174,
                -28.615228047008216
              ],
              [
                -49.765419304738884,
                -28.622079043183774
              ],
              [
                -49.771570736189574,
                -28.627528050314623
              ],
              [
                -49.780402678362634,
                -28.629772563943572
              ],
              [
                -49.781241183566586,
                -28.628240096125456
              ],
              [
                -49.78455864737056,
                -28.622177098497183
              ],
              [
                -49.782076720079864,
                -28.610812648527094
              ],
              [
                -49.794660089896375,
                -28.61653517414753
              ],
              [
                -49.80219399115147,
                -28.628772626620787
              ],
              [
                -49.807630441753716,
                -28.62947414407031
              ],
              [
                -49.808689416699934,
                -28.635301662440934
              ],
              [
                -49.816823834518736,
                -28.640147145877076
              ],
              [
                -49.81425531857111,
                -28.648959599074722
              ],
              [
                -49.814202777166464,
                -28.649139107283613
              ],
              [
                -49.81459479466984,
                -28.649618100112146
              ],
              [
                -49.81787073471189,
                -28.653625593666852
              ],
              [
                -49.81666625765859,
                -28.653636087241598
              ],
              [
                -49.81055931809221,
                -28.653689607804903
              ],
              [
                -49.81225726471493,
                -28.65801956453211
              ],
              [
                -49.82040721161151,
                -28.660892600650648
              ],
              [
                -49.82210215774506,
                -28.66149009383606
              ],
              [
                -49.82898961777207,
                -28.668277092305445
              ],
              [
                -49.83729353559456,
                -28.666629624089154
              ],
              [
                -49.83956598012578,
                -28.676780592532968
              ],
              [
                -49.8348935154779,
                -28.6787881007913
              ],
              [
                -49.83393495771551,
                -28.68469456160537
              ],
              [
                -49.83342096611623,
                -28.68786154717493
              ],
              [
                -49.82268307683446,
                -28.687735018408386
              ],
              [
                -49.82065105218052,
                -28.693172483867148
              ],
              [
                -49.83161544023328,
                -28.694436532675994
              ],
              [
                -49.838646860567906,
                -28.701740998019655
              ],
              [
                -49.84052134406995,
                -28.707261991484867
              ],
              [
                -49.83630035064698,
                -28.70927448536836
              ],
              [
                -49.836318340431845,
                -28.70928849038743
              ],
              [
                -49.84160177900696,
                -28.71345247282861
              ],
              [
                -49.84471229002933,
                -28.707317017609945
              ],
              [
                -49.84590831218944,
                -28.704958041830103
              ],
              [
                -49.852094239990606,
                -28.70407855535285
              ],
              [
                -49.86277463751613,
                -28.702559604423815
              ],
              [
                -49.859954700053414,
                -28.699550085135666
              ],
              [
                -49.87158306858229,
                -28.70402012874942
              ],
              [
                -49.86929956648155,
                -28.707007114471807
              ],
              [
                -49.873956484557006,
                -28.72195207881689
              ],
              [
                -49.87240447960438,
                -28.72234054385159
              ],
              [
                -49.86903599858135,
                -28.723184049732918
              ],
              [
                -49.87966537405943,
                -28.735789525738504
              ],
              [
                -49.875156391014464,
                -28.744378491730988
              ],
              [
                -49.88811277101772,
                -28.746183537155115
              ],
              [
                -49.88687131485879,
                -28.740125555711327
              ],
              [
                -49.891341249508606,
                -28.739122053397868
              ],
              [
                -49.88603333918941,
                -28.728366084678875
              ],
              [
                -49.88172944293498,
                -28.719645092902642
              ],
              [
                -49.88449490348625,
                -28.717841117007392
              ],
              [
                -49.89242631018767,
                -28.723263632003864
              ],
              [
                -49.89386981557274,
                -28.724250147008398
              ],
              [
                -49.90194676900899,
                -28.715664691055125
              ],
              [
                -49.90481675157469,
                -28.712613202683368
              ],
              [
                -49.90358829294807,
                -28.706621700088835
              ],
              [
                -49.9132651817211,
                -28.715124207247687
              ],
              [
                -49.92500706284321,
                -28.718428258241683
              ],
              [
                -49.934881979774126,
                -28.727606733947024
              ],
              [
                -49.93082994685054,
                -28.739172180783036
              ],
              [
                -49.93052043225803,
                -28.740054197727193
              ],
              [
                -49.92149948968129,
                -28.745644647169758
              ],
              [
                -49.927883922514255,
                -28.751208650120585
              ],
              [
                -49.927521377648496,
                -28.755633129140318
              ],
              [
                -49.93641431615687,
                -28.75855165306153
              ],
              [
                -49.94269174471625,
                -28.756731184089983
              ],
              [
                -49.94570672885288,
                -28.755857209030886
              ],
              [
                -49.94519925028414,
                -28.758987696812408
              ],
              [
                -49.95307466673203,
                -28.759683719375236
              ],
              [
                -49.95136465768514,
                -28.768836661836243
              ],
              [
                -49.956084080314724,
                -28.771220176261195
              ],
              [
                -49.953813591635644,
                -28.776193654495415
              ],
              [
                -49.958514523328326,
                -28.778806646102314
              ],
              [
                -49.95238858650561,
                -28.78358761783406
              ],
              [
                -49.95238555651891,
                -28.783590126814605
              ],
              [
                -49.952390051439245,
                -28.78359909480149
              ],
              [
                -49.956179989183404,
                -28.790969112359804
              ],
              [
                -49.95398852011115,
                -28.79546007148711
              ],
              [
                -49.95297199868593,
                -28.797543065878333
              ],
              [
                -49.9469665603678,
                -28.799679565784068
              ],
              [
                -49.94688153857699,
                -28.80063003833331
              ],
              [
                -49.94642652626327,
                -28.805721510851846
              ],
              [
                -49.95493395508344,
                -28.80247656279115
              ],
              [
                -49.95936643933825,
                -28.80078608864137
              ],
              [
                -49.963461304288145,
                -28.82172154161259
              ],
              [
                -49.962790784952105,
                -28.826132512607114
              ],
              [
                -49.955412351151296,
                -28.82591647421397
              ],
              [
                -49.95370635753381,
                -28.825866496112145
              ],
              [
                -49.95500681618901,
                -28.833372475142454
              ],
              [
                -49.958257717261596,
                -28.852137898263482
              ],
              [
                -49.95404476943632,
                -28.851034395409386
              ],
              [
                -49.94500636563261,
                -28.848666870986094
              ],
              [
                -49.94796027182722,
                -28.856321344627073
              ],
              [
                -49.95361376617201,
                -28.856270867578914
              ],
              [
                -49.95368022668213,
                -28.85773339200731
              ],
              [
                -49.95391320030083,
                -28.86288584489999
              ],
              [
                -49.96388711782006,
                -28.867468879502123
              ],
              [
                -49.96615153781717,
                -28.87341238228148
              ],
              [
                -49.95302520535346,
                -28.86990433953234
              ],
              [
                -49.94856625700033,
                -28.86871282305366
              ],
              [
                -49.949319670624696,
                -28.87905930777557
              ],
              [
                -49.93812629344074,
                -28.87170028682295
              ],
              [
                -49.92858739419834,
                -28.87094226224212
              ],
              [
                -49.93561379533157,
                -28.87590476574001
              ],
              [
                -49.93544026219083,
                -28.88213472259451
              ],
              [
                -49.941202740145364,
                -28.88007625756749
              ],
              [
                -49.94238522415259,
                -28.88453576224402
              ],
              [
                -49.94835115666622,
                -28.88747875651589
              ],
              [
                -49.948149645538884,
                -28.891014256147184
              ],
              [
                -49.94762810755622,
                -28.900126231269383
              ],
              [
                -49.951459581190406,
                -28.9029802249958
              ],
              [
                -49.95847045317452,
                -28.90820272965569
              ],
              [
                -49.955677498981466,
                -28.910172212899862
              ],
              [
                -49.959156439941026,
                -28.914411683791535
              ],
              [
                -49.96515488037223,
                -28.921721702895912
              ],
              [
                -49.96171287842982,
                -28.924138162281373
              ],
              [
                -49.951048966511244,
                -28.92359665076482
              ],
              [
                -49.94345755836258,
                -28.923210621064467
              ],
              [
                -49.947630012228096,
                -28.92646661474531
              ],
              [
                -49.94433445272024,
                -28.940927588136297
              ],
              [
                -49.95320187537675,
                -28.940450620388695
              ],
              [
                -49.95560239106733,
                -28.940321085663236
              ],
              [
                -49.955869767646725,
                -28.961340049738855
              ],
              [
                -49.93730541169437,
                -28.964927456501886
              ],
              [
                -49.93387041300195,
                -28.973988914127563
              ],
              [
                -49.92004799369991,
                -28.97820984630046
              ],
              [
                -49.934527827273875,
                -28.98431236872654
              ],
              [
                -49.93800435536637,
                -28.978242421441458
              ],
              [
                -49.94149430349329,
                -28.98198940204554
              ],
              [
                -49.94236630277958,
                -28.982925926196383
              ],
              [
                -49.941459733619325,
                -28.9998213495349
              ],
              [
                -49.94140571723016,
                -29.000833875021776
              ],
              [
                -49.94168872060396,
                -29.00047287024827
              ],
              [
                -49.94557268400769,
                -28.995518866803224
              ],
              [
                -49.95099966530897,
                -28.99899741246094
              ],
              [
                -49.95610004015847,
                -29.010876879449288
              ],
              [
                -49.96430395575889,
                -29.022010347384192
              ],
              [
                -49.94875204866983,
                -29.020605827492933
              ],
              [
                -49.94856557829802,
                -29.020589322857802
              ],
              [
                -49.946694089686396,
                -29.017486821084546
              ],
              [
                -49.93891716559548,
                -29.019307793395647
              ],
              [
                -49.93809964416141,
                -29.023118773913776
              ],
              [
                -49.94659757803742,
                -29.02740579130582
              ],
              [
                -49.94543806099251,
                -29.032771787485807
              ],
              [
                -49.953162974116765,
                -29.03458678211614
              ],
              [
                -49.95499593682648,
                -29.03501729248748
              ],
              [
                -49.95368597143998,
                -29.03737128395621
              ],
              [
                -49.95289745520455,
                -29.038788767411553
              ],
              [
                -49.95414644489685,
                -29.039818290668517
              ],
              [
                -49.95815489275361,
                -29.043125272696926
              ],
              [
                -49.95872083932842,
                -29.048912279902993
              ],
              [
                -50.00698235146363,
                -29.071638335517704
              ],
              [
                -49.99099348081613,
                -29.06964429077986
              ],
              [
                -49.98812699993106,
                -29.075206287051667
              ],
              [
                -49.98487905825498,
                -29.071175751146548
              ],
              [
                -49.986963063182664,
                -29.068625297171366
              ],
              [
                -49.96740524602467,
                -29.064171720269435
              ],
              [
                -49.96754819328553,
                -29.067622718549398
              ],
              [
                -49.963637765022305,
                -29.064235736115126
              ],
              [
                -49.95904828617864,
                -29.06590018170055
              ],
              [
                -49.956069809752385,
                -29.066980170154565
              ],
              [
                -49.959790269372895,
                -29.070412173736717
              ],
              [
                -49.95764175527557,
                -29.072278680698368
              ],
              [
                -49.95359329504552,
                -29.075797163240818
              ],
              [
                -49.95399775243156,
                -29.085019125683296
              ],
              [
                -49.96217820612072,
                -29.084043170159017
              ],
              [
                -49.96697665499194,
                -29.08347015379795
              ],
              [
                -49.966716092386726,
                -29.094876612690477
              ],
              [
                -49.964418621857135,
                -29.09750510262109
              ],
              [
                -49.96235010768301,
                -29.0998716042529
              ],
              [
                -49.96488707344502,
                -29.100319108197304
              ],
              [
                -49.96902055358305,
                -29.101048618141355
              ],
              [
                -49.96945654283719,
                -29.10419563351712
              ],
              [
                -49.96854753987249,
                -29.109921573519543
              ],
              [
                -49.967652520212155,
                -29.11555856186693
              ],
              [
                -49.98189937077243,
                -29.11972110616655
              ],
              [
                -49.98248187748663,
                -29.1187740854048
              ],
              [
                -49.98345934500855,
                -29.117184603195927
              ],
              [
                -49.99057079794692,
                -29.118840136193572
              ],
              [
                -49.9882927754321,
                -29.12246609696277
              ],
              [
                -49.983829316341584,
                -29.129570098326425
              ],
              [
                -49.9863612666504,
                -29.13614407937275
              ],
              [
                -49.99069873553233,
                -29.13449656481916
              ],
              [
                -49.989828769818,
                -29.128253113864176
              ],
              [
                -49.99652665865027,
                -29.132720593214188
              ],
              [
                -50.000144138657745,
                -29.13166810605683
              ],
              [
                -50.01354157811021,
                -29.127771679430108
              ],
              [
                -50.005372089806855,
                -29.134245616096944
              ],
              [
                -50.00512357271274,
                -29.14050709385899
              ],
              [
                -50.004731028379645,
                -29.15038455636563
              ],
              [
                -50.009709976019,
                -29.151419079504294
              ],
              [
                -50.00936448784915,
                -29.156820551712222
              ],
              [
                -50.01356942427226,
                -29.15550058961174
              ],
              [
                -50.01879892173638,
                -29.15385911035968
              ],
              [
                -50.019257825688264,
                -29.164482097650723
              ],
              [
                -50.0193103303637,
                -29.165702596893023
              ],
              [
                -50.0208798392404,
                -29.16530509201371
              ],
              [
                -50.024999798046515,
                -29.16426109670743
              ],
              [
                -50.02665377912424,
                -29.168236078985547
              ],
              [
                -50.027634755598534,
                -29.170593098002485
              ],
              [
                -50.01954678990019,
                -29.174302044927774
              ],
              [
                -50.02111529380103,
                -29.17845855730541
              ],
              [
                -50.01643879789382,
                -29.18089653902653
              ],
              [
                -50.0298722033238,
                -29.180263071030005
              ],
              [
                -50.03420216433899,
                -29.17653757418542
              ],
              [
                -50.04181358939089,
                -29.18351860191756
              ],
              [
                -50.05143001158709,
                -29.18598661871802
              ],
              [
                -50.05551096217135,
                -29.183404149249025
              ],
              [
                -50.0653473317749,
                -29.19099915932084
              ],
              [
                -50.0625319007816,
                -29.182800686324867
              ],
              [
                -50.060382438880566,
                -29.17653966555998
              ],
              [
                -50.06653488889195,
                -29.181486674466818
              ],
              [
                -50.07631976061554,
                -29.189355171476002
              ],
              [
                -50.07869625131847,
                -29.185335708300208
              ],
              [
                -50.07570229160471,
                -29.182557204127715
              ],
              [
                -50.08575274921851,
                -29.178410263928725
              ],
              [
                -50.081486259478375,
                -29.17657974196019
              ],
              [
                -50.07889778432844,
                -29.175469729912162
              ],
              [
                -50.0856077518045,
                -29.175227283667525
              ],
              [
                -50.087910210095856,
                -29.17514428350518
              ],
              [
                -50.08975469910905,
                -29.175077798585136
              ],
              [
                -50.09191921789901,
                -29.16705733554279
              ],
              [
                -50.07655287634351,
                -29.16086279938925
              ],
              [
                -50.07884840858425,
                -29.156037811526094
              ],
              [
                -50.08159835142609,
                -29.15983581947679
              ],
              [
                -50.09894717597897,
                -29.16324936499521
              ],
              [
                -50.09558921895268,
                -29.165710343550774
              ],
              [
                -50.098432133147604,
                -29.17237782660188
              ],
              [
                -50.09388669052225,
                -29.176587299092073
              ],
              [
                -50.09707160487098,
                -29.18014980344982
              ],
              [
                -50.09089366986401,
                -29.180207254264193
              ],
              [
                -50.09032517636696,
                -29.18107925033295
              ],
              [
                -50.088258202199825,
                -29.184248759406813
              ],
              [
                -50.09269760395533,
                -29.195082714980394
              ],
              [
                -50.085187150523545,
                -29.19317571016192
              ],
              [
                -50.0923440873528,
                -29.199120706639714
              ],
              [
                -50.086427637635566,
                -29.200119200380858
              ],
              [
                -50.09269797735683,
                -29.221852646686173
              ],
              [
                -50.110588281491644,
                -29.237190646022903
              ],
              [
                -50.10813580449761,
                -29.230044682869654
              ],
              [
                -50.11101281784922,
                -29.223394713004485
              ],
              [
                -50.100914909277506,
                -29.224713680104863
              ],
              [
                -50.09329551196602,
                -29.21285668390027
              ],
              [
                -50.10256194628228,
                -29.211155727916516
              ],
              [
                -50.11491287375803,
                -29.208888782255542
              ],
              [
                -50.11556383698324,
                -29.205248792407048
              ],
              [
                -50.129344784192064,
                -29.201419313108772
              ],
              [
                -50.122771354358356,
                -29.197570316006395
              ],
              [
                -50.13722772347315,
                -29.199009849253
              ],
              [
                -50.139617722736126,
                -29.19341589617532
              ],
              [
                -50.151447564259165,
                -29.202579402781605
              ],
              [
                -50.14018466821959,
                -29.201431852478983
              ],
              [
                -50.13705817262286,
                -29.204637858501716
              ],
              [
                -50.13337871086953,
                -29.208410340639066
              ],
              [
                -50.13279369174593,
                -29.21542281088855
              ],
              [
                -50.145280071952946,
                -29.21897484303667
              ],
              [
                -50.14728602654708,
                -29.219545816684203
              ],
              [
                -50.146023552152506,
                -29.223436797462945
              ],
              [
                -50.14522351082541,
                -29.225903786546272
              ],
              [
                -50.14657299661594,
                -29.226731809920043
              ],
              [
                -50.14993696170458,
                -29.22879530784122
              ],
              [
                -50.146973010650036,
                -29.229133307693488
              ],
              [
                -50.141912032833936,
                -29.229710266952324
              ],
              [
                -50.13847652880019,
                -29.233943754522873
              ],
              [
                -50.14080600863846,
                -29.238444244752696
              ],
              [
                -50.14871192948834,
                -29.241238773287346
              ],
              [
                -50.16596673775358,
                -29.24733831513846
              ],
              [
                -50.155166858145336,
                -29.248406251373844
              ],
              [
                -50.156471310916636,
                -29.251990747739736
              ],
              [
                -50.14982038121267,
                -29.249288726573965
              ],
              [
                -50.150270842089014,
                -29.257604206562444
              ],
              [
                -50.145287868546006,
                -29.256345705989744
              ],
              [
                -50.144196803748805,
                -29.275453139600028
              ],
              [
                -50.14818074535099,
                -29.282925109530332
              ],
              [
                -50.14711175043065,
                -29.287610605514082
              ],
              [
                -50.147082247045454,
                -29.287740112988637
              ],
              [
                -50.141356802017256,
                -29.287853109221665
              ],
              [
                -50.142258765510604,
                -29.29176256306159
              ],
              [
                -50.12876332912366,
                -29.302741501411305
              ],
              [
                -50.13050637044949,
                -29.28839604692111
              ],
              [
                -50.130823889512016,
                -29.28578504648212
              ],
              [
                -50.12303996168925,
                -29.28135254161441
              ],
              [
                -50.116646489951684,
                -29.290366003801456
              ],
              [
                -50.11584446511176,
                -29.29149699517551
              ],
              [
                -50.11788191877677,
                -29.29713298371155
              ],
              [
                -50.10571404430641,
                -29.29909293394436
              ],
              [
                -50.10861495915095,
                -29.305065421629408
              ],
              [
                -50.10099701711817,
                -29.310062873122956
              ],
              [
                -50.08944261083324,
                -29.310328830078443
              ],
              [
                -50.084040613780836,
                -29.31607179546041
              ],
              [
                -50.08559009222506,
                -29.32155230867266
              ],
              [
                -50.085933592462105,
                -29.322765791065162
              ],
              [
                -50.07458869201193,
                -29.320455763062846
              ],
              [
                -50.07226322464923,
                -29.319982248889993
              ],
              [
                -50.069223220091864,
                -29.324262225792516
              ],
              [
                -50.072591684838216,
                -29.327394216336625
              ],
              [
                -50.05918180007653,
                -29.327243676690312
              ],
              [
                -50.057257324185855,
                -29.327222155566204
              ],
              [
                -50.057450819937465,
                -29.328380673571047
              ],
              [
                -50.05877625037522,
                -29.33629615612651
              ],
              [
                -50.06451617343749,
                -29.340854149969996
              ],
              [
                -50.049414813709674,
                -29.338869592870164
              ],
              [
                -50.04873884619664,
                -29.33951359927653
              ],
              [
                -50.03693787962094,
                -29.35075651007972
              ],
              [
                -50.034925428495036,
                -29.34703505849713
              ],
              [
                -50.03653892309932,
                -29.344975546138905
              ],
              [
                -50.0433843756947,
                -29.336237090775853
              ],
              [
                -50.06271235300529,
                -29.311565761217445
              ],
              [
                -50.07343725870054,
                -29.309128295116846
              ],
              [
                -50.09240166412636,
                -29.29637388892388
              ],
              [
                -50.101529614835776,
                -29.283303958989148
              ],
              [
                -50.10395262617062,
                -29.28218048564764
              ],
              [
                -50.10797807781152,
                -29.280313497679355
              ],
              [
                -50.11416912011591,
                -29.258757075043047
              ],
              [
                -50.10635270528463,
                -29.25396306527543
              ],
              [
                -50.09725877356085,
                -29.254012046179458
              ],
              [
                -50.08942785139105,
                -29.25405452682196
              ],
              [
                -50.07306304814372,
                -29.24770148622275
              ],
              [
                -50.068721055386696,
                -29.246016489456608
              ],
              [
                -50.068215589547414,
                -29.24643699321741
              ],
              [
                -50.065291586035606,
                -29.248871949471358
              ],
              [
                -50.06424109806995,
                -29.245302461051455
              ],
              [
                -50.06342962817416,
                -29.242545987906116
              ],
              [
                -50.05195025746763,
                -29.241327455039507
              ],
              [
                -50.04172580070347,
                -29.240241928432184
              ],
              [
                -50.03798036646563,
                -29.236808889547486
              ],
              [
                -50.034233924232936,
                -29.233375923664425
              ],
              [
                -50.006724665702,
                -29.224956323403525
              ],
              [
                -50.003411706869784,
                -29.22586230214308
              ],
              [
                -50.00009825804342,
                -29.226768316880808
              ],
              [
                -49.972964533210195,
                -29.213231740698113
              ],
              [
                -49.970932075298876,
                -29.206571794700313
              ],
              [
                -49.97007310404535,
                -29.20375629962374
              ],
              [
                -49.962461147280614,
                -29.198392275702627
              ],
              [
                -49.946315798041816,
                -29.199965697599044
              ],
              [
                -49.942947318668956,
                -29.200879210120142
              ],
              [
                -49.938806844932856,
                -29.202578192225936
              ],
              [
                -49.9328448939345,
                -29.20312316630563
              ],
              [
                -49.93113339742807,
                -29.203279664438938
              ],
              [
                -49.92869591918075,
                -29.206913616597422
              ],
              [
                -49.914922545756816,
                -29.207352601000128
              ],
              [
                -49.91327856619554,
                -29.207405059722664
              ],
              [
                -49.90084663336952,
                -29.214735509754117
              ],
              [
                -49.88148474540332,
                -29.219563407162497
              ],
              [
                -49.87984775107754,
                -29.21997143575762
              ],
              [
                -49.879335273238176,
                -29.220615910761907
              ],
              [
                -49.876301791131496,
                -29.224425876125952
              ],
              [
                -49.87349031837347,
                -29.224258891224963
              ],
              [
                -49.86854935697998,
                -29.223965354826376
              ],
              [
                -49.85651888947143,
                -29.232835290341242
              ],
              [
                -49.856702392720884,
                -29.24178528696673
              ],
              [
                -49.856710386717225,
                -29.242186292694843
              ],
              [
                -49.85383940568037,
                -29.24528776096145
              ],
              [
                -49.853582405553524,
                -29.245343746824837
              ],
              [
                -49.84841290517579,
                -29.246481740924104
              ],
              [
                -49.85079689499342,
                -29.250682718147498
              ],
              [
                -49.85416181727371,
                -29.256611225417984
              ],
              [
                -49.84652139805911,
                -29.258877179669273
              ],
              [
                -49.84162992085694,
                -29.26032815478185
              ],
              [
                -49.84219340406878,
                -29.267172156673087
              ],
              [
                -49.84254235765837,
                -29.271411112222186
              ],
              [
                -49.84694884159466,
                -29.272712622385548
              ],
              [
                -49.840533335068216,
                -29.277066613413716
              ],
              [
                -49.82756548333162,
                -29.276853570890562
              ],
              [
                -49.817143006944136,
                -29.285825509039658
              ],
              [
                -49.81142054639578,
                -29.283412999592382
              ],
              [
                -49.809217089118064,
                -29.286447446555705
              ],
              [
                -49.796835139277555,
                -29.28856693165114
              ],
              [
                -49.795227688372094,
                -29.28884241878218
              ],
              [
                -49.79158522450744,
                -29.28538739646039
              ],
              [
                -49.773739345523445,
                -29.295951298838993
              ],
              [
                -49.7722973634459,
                -29.29680481670853
              ],
              [
                -49.76912585408349,
                -29.29863828696825
              ],
              [
                -49.7671943647916,
                -29.298314776840776
              ],
              [
                -49.76334140611317,
                -29.29767027861605
              ],
              [
                -49.74865553100369,
                -29.296611231492868
              ],
              [
                -49.74008761262525,
                -29.300338162551437
              ],
              [
                -49.74006404519697,
                -29.306257644226164
              ],
              [
                -49.74292153895181,
                -29.310257652848072
              ],
              [
                -49.737721545739085,
                -29.314536624623752
              ],
              [
                -49.735683595921365,
                -29.310374134574307
              ],
              [
                -49.729885110824796,
                -29.317319116462663
              ],
              [
                -49.73628752634761,
                -29.31874959760958
              ],
              [
                -49.742991985021476,
                -29.320247638664913
              ],
              [
                -49.74427045305412,
                -29.327947102422957
              ],
              [
                -49.735881000456686,
                -29.325008113773784
              ],
              [
                -49.73087454105748,
                -29.327751549254632
              ],
              [
                -49.72919804663684,
                -29.328670035039945
              ],
              [
                -49.711304733954485,
                -29.325447990004502
              ],
              [
                -49.722896041561825,
                -29.341020479511563
              ],
              [
                -49.72821447544047,
                -29.348165505102937
              ],
              [
                -49.72872297023965,
                -29.350011462005604
              ],
              [
                -49.72942247008284,
                -29.35254996337314
              ],
              [
                -49.73094643049981,
                -29.358084465093892
              ],
              [
                -49.74818270516634,
                -29.37562645741292
              ],
              [
                -49.75004916237159,
                -29.377516990242746
              ],
              [
                -49.750912676900924,
                -29.378391455627856
              ],
              [
                -49.75271012078619,
                -29.380672475939082
              ],
              [
                -49.776995292811016,
                -29.411490460825323
              ],
              [
                -49.782954732152504,
                -29.41905292664315
              ],
              [
                -49.783372697076004,
                -29.41958293949214
              ],
              [
                -49.78891410147833,
                -29.42661543448181
              ],
              [
                -49.801915419459554,
                -29.443087416926986
              ],
              [
                -49.8465492936476,
                -29.50358486339377
              ],
              [
                -49.87338292314406,
                -29.53993787352442
              ],
              [
                -49.905272438299264,
                -29.583142340270566
              ],
              [
                -49.91859176014461,
                -29.601186811845707
              ],
              [
                -49.92257070456724,
                -29.606529814801323
              ],
              [
                -49.934167503497555,
                -29.62420777920775
              ],
              [
                -49.958786131382645,
                -29.66741122982532
              ],
              [
                -49.990018608153306,
                -29.723174145326453
              ],
              [
                -49.992226058313044,
                -29.72708214233209
              ],
              [
                -49.99443201649561,
                -29.73098715034615
              ],
              [
                -50.008179286715105,
                -29.755323642606868
              ],
              [
                -50.01748665398511,
                -29.771800086959658
              ],
              [
                -50.03871227806991,
                -29.812853562384934
              ],
              [
                -50.043975171821266,
                -29.823128021117178
              ],
              [
                -50.049958585931954,
                -29.834721504481116
              ],
              [
                -50.0604218921616,
                -29.85499648372455
              ],
              [
                -50.06374286236007,
                -29.86140395829556
              ],
              [
                -50.06742879166192,
                -29.86877747224527
              ],
              [
                -50.075039660101,
                -29.883947443778826
              ],
              [
                -50.0769355997688,
                -29.888036960219196
              ],
              [
                -50.07883206842496,
                -29.892126947664728
              ],
              [
                -50.09639374260999,
                -29.930006377428604
              ],
              [
                -50.10710709093182,
                -29.953113834366018
              ],
              [
                -50.112795435661006,
                -29.96538283093247
              ],
              [
                -50.11335945382413,
                -29.96659931130537
              ],
              [
                -50.11422395623088,
                -29.968460332823206
              ],
              [
                -50.11737439272749,
                -29.97524729077014
              ],
              [
                -50.136399015272325,
                -30.02114523015816
              ],
              [
                -50.139164962447715,
                -30.02781922507967
              ],
              [
                -50.14193042361647,
                -30.034492703012393
              ],
              [
                -50.16795741578709,
                -30.097164585244528
              ],
              [
                -50.18240111136271,
                -30.132951043552524
              ],
              [
                -50.191984380262014,
                -30.156937503701304
              ],
              [
                -50.19406984247453,
                -30.162114980831127
              ],
              [
                -50.19615580767728,
                -30.167291967971227
              ],
              [
                -50.22379175160677,
                -30.235891857446113
              ],
              [
                -50.23524005785071,
                -30.264628323940894
              ],
              [
                -50.25076074601817,
                -30.303260740285857
              ],
              [
                -50.25364318739827,
                -30.31115375243809
              ],
              [
                -50.25391016241772,
                -30.311883723252194
              ],
              [
                -50.25505516426404,
                -30.3147227170535
              ],
              [
                -50.255578618220405,
                -30.31602071513437
              ],
              [
                -50.25781758198536,
                -30.321571210934724
              ],
              [
                -50.25920505441759,
                -30.325651696912455
              ],
              [
                -50.259793047615595,
                -30.327380709938453
              ],
              [
                -50.27666367442059,
                -30.371904148295553
              ],
              [
                -50.27803713026511,
                -30.37552714265269
              ],
              [
                -50.27806115274224,
                -30.375589613556706
              ],
              [
                -50.27941115410361,
                -30.379149616016832
              ],
              [
                -50.3065105623746,
                -30.45060497749739
              ],
              [
                -50.30832351046209,
                -30.455385961147037
              ],
              [
                -50.31157149065371,
                -30.461301970961784
              ],
              [
                -50.327248695434754,
                -30.489864955056778
              ],
              [
                -50.33299762731888,
                -30.50027395418042
              ],
              [
                -50.34285145879196,
                -30.515885406722163
              ],
              [
                -50.35729320741631,
                -30.53868389271191
              ],
              [
                -50.36011866856437,
                -30.543144410381508
              ],
              [
                -50.36329961981413,
                -30.54816540476778
              ],
              [
                -50.36683008825711,
                -30.553738400875528
              ],
              [
                -50.3689565139814,
                -30.557095398137314
              ],
              [
                -50.381540353615016,
                -30.573611371918215
              ],
              [
                -50.38466178817678,
                -30.57769989090397
              ],
              [
                -50.39161020155008,
                -30.586802888888705
              ],
              [
                -50.40011957516546,
                -30.5979498649142
              ],
              [
                -50.47394948800993,
                -30.694662834042404
              ],
              [
                -50.47640946839805,
                -30.698286339987646
              ],
              [
                -50.479067909584934,
                -30.70220235885214
              ],
              [
                -50.48684477331557,
                -30.713657858560897
              ],
              [
                -50.54357641927403,
                -30.79722127090215
              ],
              [
                -50.55145528502325,
                -30.80882628294243
              ],
              [
                -50.55146127093364,
                -30.80883376394413
              ],
              [
                -50.55656318950408,
                -30.81634978504818
              ],
              [
                -50.562105602942715,
                -30.82451377701298
              ],
              [
                -50.591558697937046,
                -30.863133754461344
              ],
              [
                -50.63832498987475,
                -30.924455773053175
              ],
              [
                -50.665209618134924,
                -30.959707226465888
              ],
              [
                -50.66893855785908,
                -30.96459625211484
              ],
              [
                -50.672667510573255,
                -30.969485735771293
              ],
              [
                -50.68665874464307,
                -30.989874234418593
              ],
              [
                -50.69250565319305,
                -30.99839373206171
              ],
              [
                -50.69835258871726,
                -31.00691373172821
              ],
              [
                -50.76879247173753,
                -31.109557664600413
              ],
              [
                -50.84759891207702,
                -31.195310207427415
              ],
              [
                -50.86947112105872,
                -31.217878760113482
              ],
              [
                -50.88695536513517,
                -31.235917759212516
              ],
              [
                -50.889853834315616,
                -31.23842476875733
              ],
              [
                -50.88991584451006,
                -31.238477747856486
              ],
              [
                -50.89275280849293,
                -31.24093126930883
              ],
              [
                -50.93956919637462,
                -31.2814238132105
              ],
              [
                -50.94861659722299,
                -31.289249316586936
              ],
              [
                -50.965181879649876,
                -31.302514856970024
              ],
              [
                -50.98192518576742,
                -31.315923357764316
              ],
              [
                -50.98840710634043,
                -31.3211143943244
              ],
              [
                -50.99488852091397,
                -31.32630488889851
              ],
              [
                -51.02269113126561,
                -31.348569426713457
              ],
              [
                -51.04188490304403,
                -31.363940963256997
              ],
              [
                -51.046145366293516,
                -31.367814970654948
              ],
              [
                -51.05291578695749,
                -31.373997455764
              ],
              [
                -51.05945367071471,
                -31.37996747654374
              ],
              [
                -51.11918090024303,
                -31.43450703068102
              ],
              [
                -51.173340279906654,
                -31.472282638827355
              ],
              [
                -51.191258498272596,
                -31.495531130543405
              ],
              [
                -51.221434047901475,
                -31.536383643011284
              ],
              [
                -51.249787170461765,
                -31.566743153314782
              ],
              [
                -51.25170414478003,
                -31.56813465031903
              ],
              [
                -51.25362057510227,
                -31.569526164322234
              ],
              [
                -51.45839010833384,
                -31.717556525417645
              ],
              [
                -51.461685083603456,
                -31.719938517541422
              ],
              [
                -51.46986897243816,
                -31.725854019251788
              ],
              [
                -51.47985985047923,
                -31.731376056457066
              ],
              [
                -51.485423831015154,
                -31.73445105706197
              ],
              [
                -51.51382949299678,
                -31.750150643736927
              ],
              [
                -51.521179412155604,
                -31.754212652091002
              ],
              [
                -51.52286537792209,
                -31.755144662531677
              ],
              [
                -51.53358724661417,
                -31.761070178792384
              ],
              [
                -51.55158605828996,
                -31.77101723029573
              ],
              [
                -51.570553364907546,
                -31.781499754438205
              ],
              [
                -51.580357250057894,
                -31.78691829138895
              ],
              [
                -51.58303473051271,
                -31.788397796481092
              ],
              [
                -51.587483664754494,
                -31.790856789267476
              ],
              [
                -51.61025991789495,
                -31.80344483868384
              ],
              [
                -51.61919882090185,
                -31.8083858494274
              ],
              [
                -51.6192107887715,
                -31.80839136346214
              ],
              [
                -51.620659770238035,
                -31.809192357312583
              ],
              [
                -51.6266782305699,
                -31.81251838331351
              ],
              [
                -51.65733291040228,
                -31.8267044762366
              ],
              [
                -51.662749334359596,
                -31.829210995968623
              ],
              [
                -51.668165793314365,
                -31.831717990705584
              ],
              [
                -51.71770376834565,
                -31.85464212792241
              ],
              [
                -51.81723060946761,
                -31.910374343877706
              ],
              [
                -51.81905511009031,
                -31.911655332097514
              ],
              [
                -51.820878573721195,
                -31.912936328313965
              ],
              [
                -51.88456029441867,
                -31.957651476254746
              ],
              [
                -51.978602593280385,
                -32.04325710251687
              ],
              [
                -51.98208753081296,
                -32.04685861878911
              ],
              [
                -51.98557148735271,
                -32.050459602064265
              ],
              [
                -52.006718191087614,
                -32.07231112121851
              ],
              [
                -52.0315868317819,
                -32.098009168297104
              ],
              [
                -52.039970234639625,
                -32.106671169201164
              ],
              [
                -52.06005844103874,
                -32.127430208246444
              ],
              [
                -52.07104182183154,
                -32.13877969430191
              ],
              [
                -52.074437241536714,
                -32.142287707587315
              ],
              [
                -52.08114412043354,
                -32.156930190392416
              ],
              [
                -52.078252247918705,
                -32.137310753271876
              ],
              [
                -52.081654238326,
                -32.13239877005992
              ],
              [
                -52.08829370801465,
                -32.12870431831176
              ],
              [
                -52.085025745034834,
                -32.12661380994781
              ],
              [
                -52.08406779130142,
                -32.122747308799
              ],
              [
                -52.083186301000396,
                -32.119906318356094
              ],
              [
                -52.081079853159174,
                -32.11734680106433
              ],
              [
                -52.07636038167329,
                -32.11170380870477
              ],
              [
                -52.06325656806603,
                -32.096228797395305
              ],
              [
                -52.05914769759622,
                -32.084509317194446
              ],
              [
                -52.05737972102828,
                -32.079508326604994
              ],
              [
                -52.05414975175927,
                -32.07706130936168
              ],
              [
                -52.048527842657855,
                -32.072800787765985
              ],
              [
                -52.04774736841089,
                -32.06962430264599
              ],
              [
                -52.04415498617139,
                -32.054852832522315
              ],
              [
                -52.042712533236276,
                -32.048920814722884
              ],
              [
                -52.04115857234398,
                -32.04174984764791
              ],
              [
                -52.040031095533415,
                -32.036383376727045
              ],
              [
                -52.04074212114855,
                -32.03195888006975
              ],
              [
                -52.041584642334485,
                -32.02707239715187
              ],
              [
                -52.04224868618686,
                -32.02197690802883
              ],
              [
                -52.042661681460146,
                -32.01542794660027
              ],
              [
                -52.042170732014185,
                -32.007165950813494
              ],
              [
                -52.044918272602345,
                -32.001214954602894
              ],
              [
                -52.0489127687421,
                -31.993032509345106
              ],
              [
                -52.05255328861153,
                -31.985576542742365
              ],
              [
                -52.054113271262366,
                -31.98183508289088
              ],
              [
                -52.04792135696173,
                -31.974391559247543
              ],
              [
                -52.03276101412165,
                -31.97136498983291
              ],
              [
                -52.02226912352287,
                -31.96926998949944
              ],
              [
                -52.019613123304445,
                -31.96597298394332
              ],
              [
                -52.01817365140318,
                -31.96418648555872
              ],
              [
                -52.01264076234455,
                -31.957317947252683
              ],
              [
                -52.01332182656775,
                -31.938848025862708
              ],
              [
                -52.01920486378951,
                -31.928081572227676
              ],
              [
                -52.026172844416976,
                -31.91533162425097
              ],
              [
                -52.02916886306542,
                -31.913356640837304
              ],
              [
                -52.03818530206831,
                -31.907906691483085
              ],
              [
                -52.041244781482575,
                -31.906057198007815
              ],
              [
                -52.04548774525751,
                -31.903492249311288
              ],
              [
                -52.046743283964545,
                -31.899465266965972
              ],
              [
                -52.04825726328594,
                -31.89460876884644
              ],
              [
                -52.053033277266806,
                -31.89089181035116
              ],
              [
                -52.061485741388566,
                -31.884312859568386
              ],
              [
                -52.06655369528418,
                -31.880367367878325
              ],
              [
                -52.08832509423327,
                -31.86342102036747
              ],
              [
                -52.09160011289743,
                -31.86087153559808
              ],
              [
                -52.09104764239428,
                -31.85667651216857
              ],
              [
                -52.090447159366576,
                -31.85211604948982
              ],
              [
                -52.09043714012832,
                -31.851997041756597
              ],
              [
                -52.09018119534555,
                -31.8489990674637
              ],
              [
                -52.09076614754139,
                -31.847948064621338
              ],
              [
                -52.09399166912176,
                -31.842154583057138
              ],
              [
                -52.09567467555951,
                -31.839130606897694
              ],
              [
                -52.09762766068212,
                -31.83542261463526
              ],
              [
                -52.08438483921666,
                -31.827918075057998
              ],
              [
                -52.07978387555635,
                -31.825364588567577
              ],
              [
                -52.074565438536965,
                -31.82253555423263
              ],
              [
                -52.06202207126169,
                -31.815743014782797
              ],
              [
                -52.04790070990553,
                -31.81352399991301
              ],
              [
                -52.00355004081474,
                -31.818554321705975
              ],
              [
                -51.93009594151762,
                -31.85875193002747
              ],
              [
                -51.92498349575419,
                -31.861013898908553
              ],
              [
                -51.92033451334131,
                -31.86307136724652
              ],
              [
                -51.907930588303955,
                -31.868559811447636
              ],
              [
                -51.90303961901423,
                -31.868842285415507
              ],
              [
                -51.868359898577594,
                -31.87085063830707
              ],
              [
                -51.86388746623056,
                -31.869840121402483
              ],
              [
                -51.85831899451762,
                -31.86855063168573
              ],
              [
                -51.85171904467771,
                -31.86702260032092
              ],
              [
                -51.84784709708821,
                -31.86448258091942
              ],
              [
                -51.807789589196695,
                -31.83819851391922
              ],
              [
                -51.807312626781425,
                -31.834739509032367
              ],
              [
                -51.8066161221976,
                -31.82969150841531
              ],
              [
                -51.8079776411041,
                -31.82631903391426
              ],
              [
                -51.81018216789815,
                -31.82085905539048
              ],
              [
                -51.81041262323091,
                -31.8202890588427
              ],
              [
                -51.812405680738806,
                -31.816772076336722
              ],
              [
                -51.805488202488725,
                -31.819653562139344
              ],
              [
                -51.80313922478162,
                -31.81491256189289
              ],
              [
                -51.79786330005152,
                -31.814258025792192
              ],
              [
                -51.791267331599144,
                -31.81441901408806
              ],
              [
                -51.78663694188812,
                -31.8057685037207
              ],
              [
                -51.79557738051727,
                -31.804808059215883
              ],
              [
                -51.79786330005152,
                -31.814258025792192
              ],
              [
                -51.81263667393392,
                -31.808470121128245
              ],
              [
                -51.80965170764307,
                -31.81304059475735
              ],
              [
                -51.812405680738806,
                -31.816772076336722
              ],
              [
                -51.82905606009911,
                -31.805886682846538
              ],
              [
                -51.83850649592069,
                -31.805572210774095
              ],
              [
                -51.84943990081052,
                -31.80520774497515
              ],
              [
                -51.8549418434792,
                -31.805024288227347
              ],
              [
                -51.859327841141784,
                -31.804878272763958
              ],
              [
                -51.86177480326942,
                -31.80218179597282
              ],
              [
                -51.86460730954945,
                -31.79889833631886
              ],
              [
                -51.85848783924174,
                -31.798679797567697
              ],
              [
                -51.85034141977632,
                -31.79843975448871
              ],
              [
                -51.83790403142189,
                -31.798074711991507
              ],
              [
                -51.797370925118734,
                -31.787091589305977
              ],
              [
                -51.74279735650046,
                -31.78802739138026
              ],
              [
                -51.6928293084491,
                -31.782036226983337
              ],
              [
                -51.664142608228005,
                -31.7704486414011
              ],
              [
                -51.64941431005366,
                -31.756994629432718
              ],
              [
                -51.643887389280586,
                -31.751945119087726
              ],
              [
                -51.64157590931993,
                -31.74983362118222
              ],
              [
                -51.63142606059951,
                -31.740562117057845
              ],
              [
                -51.609359853800576,
                -31.720404092975336
              ],
              [
                -51.59647601769262,
                -31.708634047415394
              ],
              [
                -51.59290704357631,
                -31.705374054453618
              ],
              [
                -51.58732215426333,
                -31.698536065777287
              ],
              [
                -51.57284387874071,
                -31.680808551739787
              ],
              [
                -51.55490412554756,
                -31.65884353098924
              ],
              [
                -51.55265465561735,
                -31.656089558152097
              ],
              [
                -51.54921272698493,
                -31.6518325524625
              ],
              [
                -51.5452547706895,
                -31.645243042929636
              ],
              [
                -51.5425348055895,
                -31.640713523948705
              ],
              [
                -51.53906339270791,
                -31.634933556256176
              ],
              [
                -51.5349889354265,
                -31.628150061802614
              ],
              [
                -51.52722609204184,
                -31.615223061796463
              ],
              [
                -51.52411461978781,
                -31.61004104701289
              ],
              [
                -51.524077102439556,
                -31.60998005002362
              ],
              [
                -51.5209281948359,
                -31.604735548263807
              ],
              [
                -51.51135185622243,
                -31.588791061075057
              ],
              [
                -51.50603491212518,
                -31.583620582281583
              ],
              [
                -51.5022054476252,
                -31.579897050674816
              ],
              [
                -51.49865401030603,
                -31.57644456047886
              ],
              [
                -51.49077710227386,
                -31.568785566978125
              ],
              [
                -51.48390118934918,
                -31.56481902260313
              ],
              [
                -51.4740908141725,
                -31.559159012835025
              ],
              [
                -51.469807214499006,
                -31.58268492666375
              ],
              [
                -51.466594257791456,
                -31.583103933395815
              ],
              [
                -51.46882419891093,
                -31.58550542301699
              ],
              [
                -51.46294272427939,
                -31.59023390018387
              ],
              [
                -51.469584184876965,
                -31.58922140907632
              ],
              [
                -51.44762826978004,
                -31.606265275216096
              ],
              [
                -51.43578781201201,
                -31.62434668891574
              ],
              [
                -51.43633983817373,
                -31.61615822330563
              ],
              [
                -51.45478631692198,
                -31.59022985284938
              ],
              [
                -51.464549374689305,
                -31.564101952467464
              ],
              [
                -51.46230541040757,
                -31.560311463566542
              ],
              [
                -51.449894122988646,
                -31.539348978719303
              ],
              [
                -51.448347680861275,
                -31.527294994128546
              ],
              [
                -51.455728587288114,
                -31.536177498139825
              ],
              [
                -51.46210946767075,
                -31.54385752235867
              ],
              [
                -51.46037655997059,
                -31.533986049057894
              ],
              [
                -51.45880157634715,
                -31.528355035909982
              ],
              [
                -51.445671322525996,
                -31.501296558866056
              ],
              [
                -51.44249290722624,
                -31.49474707872608
              ],
              [
                -51.43270505226179,
                -31.48430655126203
              ],
              [
                -51.42886458047601,
                -31.48026954561419
              ],
              [
                -51.415793259704024,
                -31.47289053049432
              ],
              [
                -51.4155981289968,
                -31.489538483313304
              ],
              [
                -51.41548160302978,
                -31.499437450871348
              ],
              [
                -51.41006811470156,
                -31.510821921809644
              ],
              [
                -51.40944607759781,
                -31.512129413714817
              ],
              [
                -51.40772960368989,
                -31.512874877689047
              ],
              [
                -51.40601413277684,
                -31.513619874668606
              ],
              [
                -51.377228310132566,
                -31.52611824130922
              ],
              [
                -51.37345729132907,
                -31.52775570447996
              ],
              [
                -51.37032681476176,
                -31.529114221020976
              ],
              [
                -51.36357991433615,
                -31.52883017529981
              ],
              [
                -51.3600459387776,
                -31.528630676443054
              ],
              [
                -51.34673803174122,
                -31.523500123215747
              ],
              [
                -51.34081109963143,
                -31.521215625308418
              ],
              [
                -51.317050356769606,
                -31.512056047584316
              ],
              [
                -51.31343139421665,
                -31.509281061441097
              ],
              [
                -51.30981145067299,
                -31.506505537299628
              ],
              [
                -51.295361141110156,
                -31.495426031835244
              ],
              [
                -51.292574684088216,
                -31.493290500351815
              ],
              [
                -51.28908469590636,
                -31.491697498524402
              ],
              [
                -51.27931480976794,
                -31.48723947702258
              ],
              [
                -51.27334587444745,
                -31.48451545123079
              ],
              [
                -51.263604966993846,
                -31.48007143583931
              ],
              [
                -51.25618406633838,
                -31.473623440392533
              ],
              [
                -51.24989563482161,
                -31.468158936862924
              ],
              [
                -51.24238222835922,
                -31.461630436300606
              ],
              [
                -51.23776828086168,
                -31.45762192459505
              ],
              [
                -51.2142161733383,
                -31.4266954152386
              ],
              [
                -51.2029293198547,
                -31.41187489539816
              ],
              [
                -51.20030839892312,
                -31.406558911463478
              ],
              [
                -51.199485402958345,
                -31.404889437742238
              ],
              [
                -51.195217482093724,
                -31.396232945383066
              ],
              [
                -51.18608312395281,
                -31.377703968672343
              ],
              [
                -51.180834243098836,
                -31.367057938923498
              ],
              [
                -51.17878979687673,
                -31.362910974146846
              ],
              [
                -51.17759980117975,
                -31.35810197366522
              ],
              [
                -51.17411990933265,
                -31.344033010620308
              ],
              [
                -51.162021776910684,
                -31.295120573707788
              ],
              [
                -51.15860232879103,
                -31.281142613841546
              ],
              [
                -51.157976392825965,
                -31.270746165292756
              ],
              [
                -51.15716196747858,
                -31.257222174727318
              ],
              [
                -51.16096617506859,
                -31.211543819641108
              ],
              [
                -51.161089690115496,
                -31.21006235428136
              ],
              [
                -51.16108970513458,
                -31.210058830291246
              ],
              [
                -51.16121371719605,
                -31.20857382294372
              ],
              [
                -51.173011862753945,
                -31.161078495382213
              ],
              [
                -51.17824137678278,
                -31.1400226004425
              ],
              [
                -51.17966640507218,
                -31.133925608274502
              ],
              [
                -51.17931243310484,
                -31.126171137617145
              ],
              [
                -51.17696419107562,
                -31.084580765085303
              ],
              [
                -51.17673670214924,
                -31.081591772584137
              ],
              [
                -51.168122861422546,
                -31.066838265586906
              ],
              [
                -51.15564386197952,
                -31.081236168990397
              ],
              [
                -51.14292343048163,
                -31.08973561303911
              ],
              [
                -51.14073497372528,
                -31.091197096146196
              ],
              [
                -51.13716195310603,
                -31.092169579078554
              ],
              [
                -51.13359199547468,
                -31.093140043029027
              ],
              [
                -51.12151457092313,
                -31.09642500534549
              ],
              [
                -51.1152661335926,
                -31.098123985504294
              ],
              [
                -51.11022515802282,
                -31.099494468430606
              ],
              [
                -51.10307821082671,
                -31.09885494758307
              ],
              [
                -51.09939526708522,
                -31.096328454947187
              ],
              [
                -51.094390309229354,
                -31.092894921577106
              ],
              [
                -51.0824294519477,
                -31.08469042282177
              ],
              [
                -51.073229567484375,
                -31.078379402810604
              ],
              [
                -51.07322358655302,
                -31.078375890796607
              ],
              [
                -51.07109458413488,
                -31.07691488040217
              ],
              [
                -51.06614664857647,
                -31.07352135618884
              ],
              [
                -51.05306381074201,
                -31.06454734322187
              ],
              [
                -51.04799188167003,
                -31.061067844782258
              ],
              [
                -51.04400140168646,
                -31.059797321417513
              ],
              [
                -51.00967225396873,
                -31.048870746137847
              ],
              [
                -51.00204382187842,
                -31.04822473348499
              ],
              [
                -50.99441537980199,
                -31.04757767984004
              ],
              [
                -50.980021559522804,
                -31.040524648384284
              ],
              [
                -50.97185564820674,
                -31.032399653887037
              ],
              [
                -50.96974719970713,
                -31.027205663251927
              ],
              [
                -50.9688627187925,
                -31.025026673924465
              ],
              [
                -50.96659125981051,
                -31.01942866979932
              ],
              [
                -50.961728815214116,
                -31.012930185910843
              ],
              [
                -50.96108536019168,
                -31.012070182794528
              ],
              [
                -50.959427856893335,
                -31.009854653496753
              ],
              [
                -50.95404494434525,
                -31.002660670538603
              ],
              [
                -50.95378599882486,
                -30.999064665763004
              ],
              [
                -50.9526720604189,
                -30.98355171459396
              ],
              [
                -50.95239705223665,
                -30.97972773641692
              ],
              [
                -50.9517326434399,
                -30.970471750208755
              ],
              [
                -50.95147015021674,
                -30.966819779598435
              ],
              [
                -50.95091020968331,
                -30.95902479064932
              ],
              [
                -50.952276698074975,
                -30.954408301291085
              ],
              [
                -50.95435320740718,
                -30.947394857617706
              ],
              [
                -50.96163728666015,
                -30.922790941022978
              ],
              [
                -50.96591833648297,
                -30.908330007472852
              ],
              [
                -50.96603835172073,
                -30.903426018001845
              ],
              [
                -50.96606182876762,
                -30.90244601990365
              ],
              [
                -50.96622985107419,
                -30.895583029243635
              ],
              [
                -50.94099356970273,
                -30.903452924336825
              ],
              [
                -50.934493128682995,
                -30.903837375391987
              ],
              [
                -50.93020614896489,
                -30.904091374621974
              ],
              [
                -50.92559519484198,
                -30.90436437451071
              ],
              [
                -50.91974071667309,
                -30.90471033624878
              ],
              [
                -50.91075478056194,
                -30.905241792011687
              ],
              [
                -50.90444183959282,
                -30.90214979880885
              ],
              [
                -50.90119541009182,
                -30.900559785479697
              ],
              [
                -50.897334438313685,
                -30.898668252578375
              ],
              [
                -50.89722491251324,
                -30.89861425029852
              ],
              [
                -50.88858454299817,
                -30.894381744150866
              ],
              [
                -50.88180559312731,
                -30.89106124378518
              ],
              [
                -50.845804560934695,
                -30.85839071410913
              ],
              [
                -50.82278482068412,
                -30.851419647012957
              ],
              [
                -50.821294344915124,
                -30.847131659502605
              ],
              [
                -50.82033584077588,
                -30.844523129247513
              ],
              [
                -50.8186693617929,
                -30.839985168770284
              ],
              [
                -50.814984445939004,
                -30.83876963769324
              ],
              [
                -50.8072599868038,
                -30.836222603477168
              ],
              [
                -50.77958827978341,
                -30.827097047389202
              ],
              [
                -50.77796879011202,
                -30.826563042530033
              ],
              [
                -50.776347295462884,
                -30.826028028666197
              ],
              [
                -50.75812950516224,
                -30.820020489038775
              ],
              [
                -50.75311202228538,
                -30.81826697128986
              ],
              [
                -50.750298096754925,
                -30.815248978928103
              ],
              [
                -50.74675562243242,
                -30.811450980956298
              ],
              [
                -50.74333466140345,
                -30.80778297909304
              ],
              [
                -50.74076321145856,
                -30.805025999944085
              ],
              [
                -50.738041764620945,
                -30.80210697467684
              ],
              [
                -50.73604625158686,
                -30.79996946100829
              ],
              [
                -50.72955538355881,
                -30.789805469938823
              ],
              [
                -50.724247466830995,
                -30.781493480179133
              ],
              [
                -50.720752009950886,
                -30.776019505325888
              ],
              [
                -50.72074952598477,
                -30.77601701732332
              ],
              [
                -50.716666091867886,
                -30.769622516842727
              ],
              [
                -50.712529630622136,
                -30.763144486409857
              ],
              [
                -50.70815673428505,
                -30.756297511134687
              ],
              [
                -50.70306780682952,
                -30.748329016326
              ],
              [
                -50.7014553436173,
                -30.745803508343332
              ],
              [
                -50.69736440444039,
                -30.732225527886815
              ],
              [
                -50.68956963335243,
                -30.706272106102734
              ],
              [
                -50.68840067303997,
                -30.695469106136862
              ],
              [
                -50.68779323570071,
                -30.68985161320424
              ],
              [
                -50.686671287674436,
                -30.67948015918947
              ],
              [
                -50.679009163773635,
                -30.608648343166948
              ],
              [
                -50.68262344458225,
                -30.55178400695606
              ],
              [
                -50.68339944095194,
                -30.53957958003129
              ],
              [
                -50.683439462928334,
                -30.53894658305765
              ],
              [
                -50.68429603020321,
                -30.525476109211674
              ],
              [
                -50.68579712330066,
                -30.500275173615524
              ],
              [
                -50.69115472772704,
                -30.471897802597045
              ],
              [
                -50.69794334710577,
                -30.435251423792756
              ],
              [
                -50.69908635870304,
                -30.42908094557137
              ],
              [
                -50.69933237757023,
                -30.42775194947734
              ],
              [
                -50.699982856435156,
                -30.4242384834485
              ],
              [
                -50.70693538141963,
                -30.41255303029497
              ],
              [
                -50.71994037296743,
                -30.39069414799708
              ],
              [
                -50.7242493697965,
                -30.383451162338176
              ],
              [
                -50.725761900615,
                -30.37836968134534
              ],
              [
                -50.72891838900928,
                -30.36765473252086
              ],
              [
                -50.72117697228229,
                -30.362830246685494
              ],
              [
                -50.71700856580961,
                -30.351461769280178
              ],
              [
                -50.707925655478086,
                -30.353338714290025
              ],
              [
                -50.70511418536786,
                -30.347248718479825
              ],
              [
                -50.69856176396918,
                -30.345664690672976
              ],
              [
                -50.698395253452546,
                -30.349506703571635
              ],
              [
                -50.68616880672331,
                -30.35631261164848
              ],
              [
                -50.67929933744273,
                -30.36795706617448
              ],
              [
                -50.67910032135186,
                -30.368293566396115
              ],
              [
                -50.677220827867316,
                -30.36966304599046
              ],
              [
                -50.648219987305936,
                -30.390797877978475
              ],
              [
                -50.64754297918141,
                -30.394027883714376
              ],
              [
                -50.64735145304044,
                -30.394940382248798
              ],
              [
                -50.646324978123765,
                -30.395027381988264
              ],
              [
                -50.64285748943304,
                -30.395320859597476
              ],
              [
                -50.64070451656059,
                -30.392385364954848
              ],
              [
                -50.64414302763573,
                -30.387628892534355
              ],
              [
                -50.64068753989734,
                -30.386176377399128
              ],
              [
                -50.63489456938653,
                -30.39190085375656
              ],
              [
                -50.63406856402435,
                -30.392716844104747
              ],
              [
                -50.623464695130856,
                -30.391576302186532
              ],
              [
                -50.627463168531584,
                -30.38990634274676
              ],
              [
                -50.63161062809668,
                -30.387773349268397
              ],
              [
                -50.63424660020055,
                -30.38641833958032
              ],
              [
                -50.62965315233413,
                -30.3825723661568
              ],
              [
                -50.63725962115299,
                -30.378313911493223
              ],
              [
                -50.6315876807939,
                -30.374361362194122
              ],
              [
                -50.630926233505335,
                -30.371231872958994
              ],
              [
                -50.63005375013702,
                -30.367104385883955
              ],
              [
                -50.631802292483336,
                -30.35785591331156
              ],
              [
                -50.63405781246723,
                -30.345926461733505
              ],
              [
                -50.634946799425435,
                -30.34025300415059
              ],
              [
                -50.626833441718034,
                -30.3273215202645
              ],
              [
                -50.62589038862807,
                -30.343168447196575
              ],
              [
                -50.62013536079517,
                -30.356250872694492
              ],
              [
                -50.60638691069004,
                -30.375075784928704
              ],
              [
                -50.59282843632432,
                -30.393642204680738
              ],
              [
                -50.59263140306717,
                -30.39979614453984
              ],
              [
                -50.59871686450036,
                -30.39939468744346
              ],
              [
                -50.6036278224871,
                -30.39922970206755
              ],
              [
                -50.60816876860149,
                -30.402527226921816
              ],
              [
                -50.61122170669724,
                -30.4047447152044
              ],
              [
                -50.61557717631538,
                -30.403808216970752
              ],
              [
                -50.62116309425254,
                -30.411053231145463
              ],
              [
                -50.62563555750538,
                -30.409422767439725
              ],
              [
                -50.63276454673093,
                -30.405659807444568
              ],
              [
                -50.641229442163855,
                -30.409159816022438
              ],
              [
                -50.64285293317409,
                -30.40983179735208
              ],
              [
                -50.64479039890358,
                -30.410632818522377
              ],
              [
                -50.647781893601945,
                -30.411870318504793
              ],
              [
                -50.655830252298955,
                -30.42561230601381
              ],
              [
                -50.653876173098446,
                -30.442435770373788
              ],
              [
                -50.64765120158492,
                -30.45152870605115
              ],
              [
                -50.64485220160325,
                -30.45561668697672
              ],
              [
                -50.639885186176514,
                -30.462872127027538
              ],
              [
                -50.61128681591666,
                -30.48463999667249
              ],
              [
                -50.610570329045174,
                -30.485184980255045
              ],
              [
                -50.60412390105444,
                -30.486659445694904
              ],
              [
                -50.59443197823796,
                -30.48887692125202
              ],
              [
                -50.59330997224946,
                -30.48913340110782
              ],
              [
                -50.59106450528245,
                -30.489646886812196
              ],
              [
                -50.5821300659261,
                -30.489607866037467
              ],
              [
                -50.57435617421748,
                -30.48144035797548
              ],
              [
                -50.57083575964768,
                -30.470657381325438
              ],
              [
                -50.57297676351208,
                -30.465714399395342
              ],
              [
                -50.57385623274427,
                -30.463683409873923
              ],
              [
                -50.5699727867066,
                -30.459719395529966
              ],
              [
                -50.568135329766385,
                -30.457843416951356
              ],
              [
                -50.56764883825522,
                -30.453718429344534
              ],
              [
                -50.56703636333954,
                -30.448524932434392
              ],
              [
                -50.564990485462104,
                -30.431175983196713
              ],
              [
                -50.5641615398319,
                -30.424148502937957
              ],
              [
                -50.562394595275286,
                -30.409160515809347
              ],
              [
                -50.56285360876652,
                -30.407749521812043
              ],
              [
                -50.56425262342797,
                -30.403444547121627
              ],
              [
                -50.56693715385927,
                -30.395187095250012
              ],
              [
                -50.56545316394637,
                -30.392536596395782
              ],
              [
                -50.5527963963381,
                -30.36993361777383
              ],
              [
                -50.55201891216862,
                -30.36425761174867
              ],
              [
                -50.549620436161625,
                -30.36309312291067
              ],
              [
                -50.548851463496135,
                -30.362719609039704
              ],
              [
                -50.544791504254775,
                -30.355378148255916
              ],
              [
                -50.545542587357666,
                -30.335757683014084
              ],
              [
                -50.54573561386348,
                -30.33069971094141
              ],
              [
                -50.54825712350058,
                -30.327672225821395
              ],
              [
                -50.5483846162574,
                -30.32751373679225
              ],
              [
                -50.55464157547647,
                -30.319705275530175
              ],
              [
                -50.54406078081185,
                -30.30200627861518
              ],
              [
                -50.542485772495624,
                -30.29937229342068
              ],
              [
                -50.54272532651622,
                -30.294129828112094
              ],
              [
                -50.54276432555298,
                -30.29328333280887
              ],
              [
                -50.54321889841967,
                -30.281313851576176
              ],
              [
                -50.54247587680783,
                -30.280302833736638
              ],
              [
                -50.5374269581916,
                -30.273431366840914
              ],
              [
                -50.53840245987679,
                -30.268222864305983
              ],
              [
                -50.542133008161024,
                -30.252022920565803
              ],
              [
                -50.5492699843842,
                -30.24957447559968
              ],
              [
                -50.55691642040729,
                -30.250427008664744
              ],
              [
                -50.559261396995936,
                -30.2509929870489
              ],
              [
                -50.55994037950369,
                -30.251157484185185
              ],
              [
                -50.56331137818495,
                -30.247713041624127
              ],
              [
                -50.56742235659668,
                -30.24351353120485
              ],
              [
                -50.571631833613274,
                -30.24176055879863
              ],
              [
                -50.57409131323869,
                -30.23941259440177
              ],
              [
                -50.57604982812949,
                -30.235488627810163
              ],
              [
                -50.579693827366,
                -30.228187151925066
              ],
              [
                -50.58275233343247,
                -30.22205916523845
              ],
              [
                -50.58307929908012,
                -30.221404175478817
              ],
              [
                -50.585484804851724,
                -30.21658368032427
              ],
              [
                -50.59073332125287,
                -30.206065749356963
              ],
              [
                -50.59390933453329,
                -30.199702304842415
              ],
              [
                -50.596895814647375,
                -30.194066785400793
              ],
              [
                -50.60583423921567,
                -30.195859843605124
              ],
              [
                -50.60598825477504,
                -30.195891345106553
              ],
              [
                -50.61089618696826,
                -30.196875832146358
              ],
              [
                -50.61744064889263,
                -30.19818838553945
              ],
              [
                -50.61977009950715,
                -30.198586859362376
              ],
              [
                -50.622534615127925,
                -30.19905985964685
              ],
              [
                -50.62391660102199,
                -30.19859889738909
              ],
              [
                -50.64408143919734,
                -30.191872477768708
              ],
              [
                -50.64824441838386,
                -30.194067492291353
              ],
              [
                -50.658358779082825,
                -30.19939898944858
              ],
              [
                -50.66017770965754,
                -30.20938846846077
              ],
              [
                -50.661599680695666,
                -30.21720047049556
              ],
              [
                -50.66223417150568,
                -30.220685965484
              ],
              [
                -50.66279765091311,
                -30.223780432373935
              ],
              [
                -50.664372100367075,
                -30.232425413521494
              ],
              [
                -50.66498008512821,
                -30.235765940852634
              ],
              [
                -50.66641002584095,
                -30.243616892841818
              ],
              [
                -50.66702250343571,
                -30.24698041812875
              ],
              [
                -50.66762298746192,
                -30.25027889056643
              ],
              [
                -50.668339967223176,
                -30.254216868540954
              ],
              [
                -50.66585096351512,
                -30.25913936911593
              ],
              [
                -50.66561243905738,
                -30.259612339771596
              ],
              [
                -50.656443427944666,
                -30.277113747699797
              ],
              [
                -50.654436927855905,
                -30.280942733431363
              ],
              [
                -50.655552423520746,
                -30.285221229948817
              ],
              [
                -50.66165083824631,
                -30.28922023467425
              ],
              [
                -50.67271619675861,
                -30.296119265044382
              ],
              [
                -50.681087629545644,
                -30.296258272182
              ],
              [
                -50.70562193973072,
                -30.29668536933647
              ],
              [
                -50.70755292133764,
                -30.296950896055836
              ],
              [
                -50.70948491193942,
                -30.297215878781085
              ],
              [
                -50.72096880452804,
                -30.298791934761148
              ],
              [
                -50.72629225206648,
                -30.297496962345132
              ],
              [
                -50.729753755710064,
                -30.296654978330654
              ],
              [
                -50.732364700336774,
                -30.296020495386465
              ],
              [
                -50.73596119003819,
                -30.295144978997786
              ],
              [
                -50.75020257958126,
                -30.296185561331455
              ],
              [
                -50.76316598359842,
                -30.290872580648003
              ],
              [
                -50.77152490464443,
                -30.295839102392037
              ],
              [
                -50.77279491397658,
                -30.28813163131205
              ],
              [
                -50.78116286899552,
                -30.28438919805193
              ],
              [
                -50.787860785565506,
                -30.290760211150804
              ],
              [
                -50.79204365552219,
                -30.30436516493303
              ],
              [
                -50.79431912538523,
                -30.31195365721874
              ],
              [
                -50.79316360295074,
                -30.319871606160845
              ],
              [
                -50.792977577900125,
                -30.321144117651095
              ],
              [
                -50.79228856416788,
                -30.325861605936367
              ],
              [
                -50.79060905357546,
                -30.328854585488394
              ],
              [
                -50.788502565578746,
                -30.332608078114806
              ],
              [
                -50.781289670414246,
                -30.32854457207124
              ],
              [
                -50.78646360617215,
                -30.3253335727942
              ],
              [
                -50.7761316856386,
                -30.326716054400844
              ],
              [
                -50.77533619734963,
                -30.33344849827303
              ],
              [
                -50.78558759228546,
                -30.33558906388491
              ],
              [
                -50.7933004940141,
                -30.337200071180366
              ],
              [
                -50.79645047849963,
                -30.33785809251294
              ],
              [
                -50.79998246409567,
                -30.33653459122347
              ],
              [
                -50.80321541358812,
                -30.335323617433083
              ],
              [
                -50.80711189787663,
                -30.33386360997103
              ],
              [
                -50.81904734720046,
                -30.329391173818838
              ],
              [
                -50.83036074649019,
                -30.325152248545965
              ],
              [
                -50.83489370476232,
                -30.323454245273634
              ],
              [
                -50.838435191319824,
                -30.322127762029794
              ],
              [
                -50.843486189318526,
                -30.320235312355916
              ],
              [
                -50.85264658358792,
                -30.320603834008626
              ],
              [
                -50.857715036584345,
                -30.320807828184336
              ],
              [
                -50.86460101016392,
                -30.321085390238135
              ],
              [
                -50.884310329994086,
                -30.32187893983219
              ],
              [
                -50.89538121611463,
                -30.322325465742367
              ],
              [
                -50.906452133258185,
                -30.32277101666445
              ],
              [
                -50.92158796703302,
                -30.331648554534823
              ],
              [
                -50.925841410719876,
                -30.338800031015893
              ],
              [
                -50.930094349400385,
                -30.345950519513917
              ],
              [
                -50.93666118175333,
                -30.369665489193334
              ],
              [
                -50.93802110993905,
                -30.374577482027927
              ],
              [
                -50.93815512357041,
                -30.37846845307898
              ],
              [
                -50.93831356724541,
                -30.38307943210559
              ],
              [
                -50.93867603938466,
                -30.393609432493786
              ],
              [
                -50.93782453319608,
                -30.397298919289906
              ],
              [
                -50.93681802379089,
                -30.401658885505576
              ],
              [
                -50.93452198990922,
                -30.411605838221423
              ],
              [
                -50.931407946282995,
                -30.4250957843162
              ],
              [
                -50.92803392695401,
                -30.429740285426252
              ],
              [
                -50.92799644699534,
                -30.429791768127863
              ],
              [
                -50.9257244764692,
                -30.43291874202269
              ],
              [
                -50.922801966644485,
                -30.436942240730087
              ],
              [
                -50.92950440301765,
                -30.43525073798276
              ],
              [
                -50.9538397712281,
                -30.419448897859617
              ],
              [
                -50.95655477597852,
                -30.417686401686105
              ],
              [
                -50.95945074284703,
                -30.415806433567237
              ],
              [
                -50.96257023662591,
                -30.413781429750937
              ],
              [
                -50.97013520572882,
                -30.408868967851618
              ],
              [
                -50.972871686384146,
                -30.407092000803758
              ],
              [
                -51.01247543588341,
                -30.38913969283236
              ],
              [
                -51.01401640027856,
                -30.38892522250414
              ],
              [
                -51.01555888666689,
                -30.38870972518495
              ],
              [
                -51.03813523973799,
                -30.385560279963432
              ],
              [
                -51.04155218008169,
                -30.389288813420894
              ],
              [
                -51.046698142296975,
                -30.390116339178626
              ],
              [
                -51.05470107654136,
                -30.391403860799063
              ],
              [
                -51.05842104708447,
                -30.384845870629952
              ],
              [
                -51.052370152585446,
                -30.382818345842768
              ],
              [
                -51.046869663122216,
                -30.38087233998061
              ],
              [
                -51.04691721692407,
                -30.378918355903277
              ],
              [
                -51.047072201558464,
                -30.372608384038664
              ],
              [
                -51.041887752961934,
                -30.371720866318714
              ],
              [
                -51.040761292850384,
                -30.365852882142768
              ],
              [
                -51.044420278723344,
                -30.362004381793618
              ],
              [
                -51.04903576395468,
                -30.35714890815228
              ],
              [
                -51.054609714020124,
                -30.35733293845817
              ],
              [
                -51.0675051104528,
                -30.3577589917571
              ],
              [
                -51.068203112700985,
                -30.354496995073312
              ],
              [
                -51.0548567494466,
                -30.350143442553918
              ],
              [
                -51.054602776970874,
                -30.35006096180108
              ],
              [
                -51.05040230366832,
                -30.34851494688604
              ],
              [
                -51.049286829929315,
                -30.34408744953405
              ],
              [
                -51.03930142801666,
                -30.342411920346585
              ],
              [
                -51.034143526566474,
                -30.336264421237953
              ],
              [
                -51.0319750639784,
                -30.332128419924196
              ],
              [
                -51.02920008556301,
                -30.326805429737593
              ],
              [
                -51.033485048698,
                -30.325943969048687
              ],
              [
                -51.03971051886071,
                -30.3246924731056
              ],
              [
                -51.030071617956764,
                -30.322078968073196
              ],
              [
                -51.02530265601754,
                -30.320786436217414
              ],
              [
                -51.02411516741042,
                -30.31605548051507
              ],
              [
                -51.02029077922935,
                -30.300222480251637
              ],
              [
                -51.019267825663604,
                -30.295987010750842
              ],
              [
                -51.02894530782599,
                -30.27653507901476
              ],
              [
                -51.030230359626934,
                -30.273953101663185
              ],
              [
                -51.0421942400913,
                -30.268153176552072
              ],
              [
                -51.04788872905421,
                -30.267402164027114
              ],
              [
                -51.05192418066235,
                -30.268970208172366
              ],
              [
                -51.05610513377936,
                -30.2705947097227
              ],
              [
                -51.058606595950316,
                -30.27603121946286
              ],
              [
                -51.06418653262781,
                -30.27571221222457
              ],
              [
                -51.06866604411129,
                -30.270628239737118
              ],
              [
                -51.062848617671285,
                -30.263183263958695
              ],
              [
                -51.06722115513388,
                -30.250171798460745
              ],
              [
                -51.07839807747865,
                -30.24469486531126
              ],
              [
                -51.08592504062375,
                -30.24100638661614
              ],
              [
                -51.09887590343179,
                -30.242853420784463
              ],
              [
                -51.101332858643154,
                -30.24508342581089
              ],
              [
                -51.104093354884014,
                -30.24759695819046
              ],
              [
                -51.10364534315881,
                -30.24902595122476
              ],
              [
                -51.10212830951747,
                -30.253869405011635
              ],
              [
                -51.109020281607634,
                -30.250627939519447
              ],
              [
                -51.119084231342725,
                -30.245895008827475
              ],
              [
                -51.123613690358525,
                -30.24699651029553
              ],
              [
                -51.12773115328751,
                -30.24799751645071
              ],
              [
                -51.13282953589146,
                -30.25656100517502
              ],
              [
                -51.13623048923885,
                -30.262275016663803
              ],
              [
                -51.13343847888249,
                -30.26888349428932
              ],
              [
                -51.13692595999094,
                -30.268344000527843
              ],
              [
                -51.157841345123394,
                -30.261958607186955
              ],
              [
                -51.15530087605791,
                -30.25540510851724
              ],
              [
                -51.15438791880605,
                -30.25304812087841
              ],
              [
                -51.151263952333,
                -30.246145605967598
              ],
              [
                -51.155334975278706,
                -30.240091652717922
              ],
              [
                -51.16902785022506,
                -30.2376797163921
              ],
              [
                -51.173522340387414,
                -30.23688874030656
              ],
              [
                -51.17527980755178,
                -30.23657872609589
              ],
              [
                -51.17889777312581,
                -30.23594126512931
              ],
              [
                -51.18336176849338,
                -30.234197264729055
              ],
              [
                -51.180746302973404,
                -30.228033297631647
              ],
              [
                -51.179791798490506,
                -30.22578327251963
              ],
              [
                -51.1769388548917,
                -30.21942927706648
              ],
              [
                -51.18204435275277,
                -30.212908853236414
              ],
              [
                -51.18955182105879,
                -30.21349084288495
              ],
              [
                -51.19244777410817,
                -30.213714854552837
              ],
              [
                -51.1958172684516,
                -30.211975368853363
              ],
              [
                -51.195898735000185,
                -30.21193338729564
              ],
              [
                -51.19874223516605,
                -30.210097394822554
              ],
              [
                -51.1984797271792,
                -30.20954638241775
              ],
              [
                -51.19367283190971,
                -30.19946640130249
              ],
              [
                -51.19766683781254,
                -30.193926952237298
              ],
              [
                -51.21395817974697,
                -30.190126001129865
              ],
              [
                -51.22175063517247,
                -30.188314061935888
              ],
              [
                -51.22437762877771,
                -30.1877030440076
              ],
              [
                -51.227567614799355,
                -30.188243061892816
              ],
              [
                -51.23932848197697,
                -30.19023308203661
              ],
              [
                -51.24805792653575,
                -30.185761669337616
              ],
              [
                -51.24222851829979,
                -30.181913651858117
              ],
              [
                -51.23663006251661,
                -30.18224463598955
              ],
              [
                -51.232294087762554,
                -30.182501624277158
              ],
              [
                -51.23029508744136,
                -30.180518104300297
              ],
              [
                -51.22610119841304,
                -30.176356088160446
              ],
              [
                -51.22590571821573,
                -30.168785625699567
              ],
              [
                -51.230089679444625,
                -30.165200623615736
              ],
              [
                -51.232371687844434,
                -30.163245138296624
              ],
              [
                -51.22974620182444,
                -30.16103465455076
              ],
              [
                -51.22652477028752,
                -30.15832267095738
              ],
              [
                -51.2252838334742,
                -30.144401178168437
              ],
              [
                -51.22804682728481,
                -30.140440727644467
              ],
              [
                -51.22849084693072,
                -30.139804696255176
              ],
              [
                -51.23036780343783,
                -30.137115217521053
              ],
              [
                -51.233098276961236,
                -30.1359702525622
              ],
              [
                -51.244055754857065,
                -30.131374821910896
              ],
              [
                -51.24468476309786,
                -30.126653823296476
              ],
              [
                -51.24761174214372,
                -30.125484333176246
              ],
              [
                -51.25664919524526,
                -30.12273886256596
              ],
              [
                -51.26115364296146,
                -30.120099908940354
              ],
              [
                -51.25812670662861,
                -30.111222422317347
              ],
              [
                -51.26236665145632,
                -30.111691906483927
              ],
              [
                -51.26057818596991,
                -30.10726592256537
              ],
              [
                -51.26573119183913,
                -30.102599444454082
              ],
              [
                -51.260732778963934,
                -30.096367462348617
              ],
              [
                -51.25009534467505,
                -30.09471242373406
              ],
              [
                -51.2489699479197,
                -30.08055446619748
              ],
              [
                -51.24660346862059,
                -30.077584971752568
              ],
              [
                -51.242044023464175,
                -30.071864980900997
              ],
              [
                -51.2381350787665,
                -30.066960493182453
              ],
              [
                -51.23096317019371,
                -30.057707472641777
              ],
              [
                -51.22986578072752,
                -30.04322352229776
              ],
              [
                -51.24200114413567,
                -30.04306054204023
              ],
              [
                -51.241633199014785,
                -30.034922592731323
              ],
              [
                -51.250111623587614,
                -30.034529621911176
              ],
              [
                -51.25272158756566,
                -30.0404860924242
              ],
              [
                -51.25498153151758,
                -30.045642596952305
              ],
              [
                -51.26239644674546,
                -30.053808114668456
              ],
              [
                -51.26209846115357,
                -30.04915360528509
              ],
              [
                -51.261157017770394,
                -30.043344116818606
              ],
              [
                -51.260274047005346,
                -30.03790263049617
              ],
              [
                -51.259639053345744,
                -30.033988154620356
              ],
              [
                -51.2629640274907,
                -30.034110161209174
              ],
              [
                -51.26905247745493,
                -30.034340181243426
              ],
              [
                -51.271364936361024,
                -30.038452177074575
              ],
              [
                -51.27910287884015,
                -30.037319693575906
              ],
              [
                -51.26727453382984,
                -30.028544191488546
              ],
              [
                -51.267627072650015,
                -30.015960723157438
              ],
              [
                -51.27711204660225,
                -30.010398789572914
              ],
              [
                -51.282081919311224,
                -30.02141025329326
              ],
              [
                -51.28885595449739,
                -30.01137131341552
              ],
              [
                -51.288856425534675,
                -30.01136330344111
              ],
              [
                -51.2897479918779,
                -30.00170586953414
              ],
              [
                -51.29515193146275,
                -30.001161874184096
              ],
              [
                -51.301752305214634,
                -30.00938185453027
              ],
              [
                -51.30366073798251,
                -30.02699832581316
              ],
              [
                -51.2996787367516,
                -30.033761802284626
              ],
              [
                -51.29184074833802,
                -30.047076216352007
              ],
              [
                -51.29970720991057,
                -30.041260285216378
              ],
              [
                -51.29867766604644,
                -30.050635251484415
              ],
              [
                -51.30064712796506,
                -30.052180744595855
              ],
              [
                -51.30229359683031,
                -30.05347322619664
              ],
              [
                -51.303023607215614,
                -30.054567732809257
              ],
              [
                -51.30464256245013,
                -30.05699623994801
              ],
              [
                -51.30902251477279,
                -30.062783211940477
              ],
              [
                -51.311464439512505,
                -30.072143700835515
              ],
              [
                -51.313787872865845,
                -30.082343194812296
              ],
              [
                -51.30870643413255,
                -30.084924684361347
              ],
              [
                -51.308476890029134,
                -30.0856961721897
              ],
              [
                -51.30775593543552,
                -30.088116641236148
              ],
              [
                -51.30631141906257,
                -30.093771619920194
              ],
              [
                -51.309278334149646,
                -30.101443097892368
              ],
              [
                -51.31028430448084,
                -30.104043103159857
              ],
              [
                -51.31032082169344,
                -30.10413763702397
              ],
              [
                -51.31313474806388,
                -30.111411603589076
              ],
              [
                -51.31356215297387,
                -30.128156057966525
              ],
              [
                -51.310713187410265,
                -30.13064804349665
              ],
              [
                -51.309937184257215,
                -30.13132601646811
              ],
              [
                -51.30558168722328,
                -30.135134022236013
              ],
              [
                -51.31024867586562,
                -30.1357400096993
              ],
              [
                -51.31117413275009,
                -30.13700301660247
              ],
              [
                -51.31453659847228,
                -30.141589529262312
              ],
              [
                -51.32090549745874,
                -30.150637995577544
              ],
              [
                -51.322268960347735,
                -30.160470488029777
              ],
              [
                -51.322728445223945,
                -30.163819969992705
              ],
              [
                -51.323482384354385,
                -30.16993348499699
              ],
              [
                -51.319135433563495,
                -30.174637937172154
              ],
              [
                -51.316989942875395,
                -30.174881447058198
              ],
              [
                -51.30950198381634,
                -30.175730383253565
              ],
              [
                -51.30904496333442,
                -30.182522399527382
              ],
              [
                -51.31121140808277,
                -30.18551036524168
              ],
              [
                -51.315400881155014,
                -30.190636890609316
              ],
              [
                -51.31664580038505,
                -30.197681845827635
              ],
              [
                -51.319069218493944,
                -30.21232082142456
              ],
              [
                -51.32480919380184,
                -30.214564342352503
              ],
              [
                -51.32633015655736,
                -30.219909339666465
              ],
              [
                -51.32714912166083,
                -30.222830314327926
              ],
              [
                -51.32801006248212,
                -30.226315819504027
              ],
              [
                -51.32698756236824,
                -30.229294786778
              ],
              [
                -51.32530906267471,
                -30.23418726388213
              ],
              [
                -51.31883460582744,
                -30.23436227397684
              ],
              [
                -51.31840812596672,
                -30.233661775353916
              ],
              [
                -51.31622018459717,
                -30.230066744294756
              ],
              [
                -51.31402666727849,
                -30.23589221964428
              ],
              [
                -51.31275166612132,
                -30.239277200739103
              ],
              [
                -51.30966419048982,
                -30.238112716037804
              ],
              [
                -51.30258923037078,
                -30.235444683103747
              ],
              [
                -51.291648340957146,
                -30.231045189095692
              ],
              [
                -51.2980782485103,
                -30.23945317468051
              ],
              [
                -51.294692795604554,
                -30.24573312702136
              ],
              [
                -51.29431778206417,
                -30.246428126516378
              ],
              [
                -51.28951978739262,
                -30.24893109837353
              ],
              [
                -51.28681680922706,
                -30.250341082645875
              ],
              [
                -51.27291393058321,
                -30.249322042113143
              ],
              [
                -51.279640868094305,
                -30.253608074940036
              ],
              [
                -51.282639324735776,
                -30.255483545209596
              ],
              [
                -51.28700578769868,
                -30.258215072342693
              ],
              [
                -51.29036678721021,
                -30.254549091253526
              ],
              [
                -51.29083475402347,
                -30.25403960157992
              ],
              [
                -51.297366670145465,
                -30.259092620421704
              ],
              [
                -51.29899955168902,
                -30.283482524544663
              ],
              [
                -51.296420023564814,
                -30.292359988490364
              ],
              [
                -51.29312649051855,
                -30.303492456056755
              ],
              [
                -51.2753886188373,
                -30.307951883363682
              ],
              [
                -51.27043569223156,
                -30.30723637200027
              ],
              [
                -51.267444180048436,
                -30.30680386551518
              ],
              [
                -51.267336706011825,
                -30.30678834113848
              ],
              [
                -51.25995629717181,
                -30.305722344271327
              ],
              [
                -51.2565558231035,
                -30.305337307044898
              ],
              [
                -51.249294312231804,
                -30.311570276317866
              ],
              [
                -51.24910031895925,
                -30.31173628107099
              ],
              [
                -51.24590884832412,
                -30.314774742659893
              ],
              [
                -51.24840229539538,
                -30.3208017578428
              ],
              [
                -51.24948376856768,
                -30.32385676216746
              ],
              [
                -51.24675229589808,
                -30.327900711628086
              ],
              [
                -51.24070385328615,
                -30.325085195093834
              ],
              [
                -51.23324695559234,
                -30.30993371910141
              ],
              [
                -51.22752204885758,
                -30.30682120073568
              ],
              [
                -51.210189703394235,
                -30.297397687318067
              ],
              [
                -51.20975417806946,
                -30.302945677361237
              ],
              [
                -51.20966272174813,
                -30.30411116158962
              ],
              [
                -51.20940970106062,
                -30.30732863817646
              ],
              [
                -51.207195053287144,
                -30.335511045030866
              ],
              [
                -51.20681905240599,
                -30.340295544567553
              ],
              [
                -51.20459204586605,
                -30.345439027788018
              ],
              [
                -51.199698509071055,
                -30.35674394753326
              ],
              [
                -51.19593051557836,
                -30.365449926299103
              ],
              [
                -51.19354002638695,
                -30.370971387781424
              ],
              [
                -51.190780508591665,
                -30.376996891343715
              ],
              [
                -51.18422852450237,
                -30.381970327071585
              ],
              [
                -51.178116579375946,
                -30.385303317317174
              ],
              [
                -51.155330325769725,
                -30.37596576106171
              ],
              [
                -51.12862760361431,
                -30.35893220206702
              ],
              [
                -51.1255156532223,
                -30.358745179402494
              ],
              [
                -51.12188516625941,
                -30.35852819679177
              ],
              [
                -51.11784368682012,
                -30.3582851686458
              ],
              [
                -51.097181906528725,
                -30.357046082222947
              ],
              [
                -51.09412439436999,
                -30.364208039224515
              ],
              [
                -51.09430483589811,
                -30.368996556891805
              ],
              [
                -51.09476080389866,
                -30.381112000172863
              ],
              [
                -51.10277124470702,
                -30.375972055671724
              ],
              [
                -51.11127760522348,
                -30.385235576935948
              ],
              [
                -51.11793752023309,
                -30.39271954618885
              ],
              [
                -51.121400003365764,
                -30.39660957941158
              ],
              [
                -51.12037796013816,
                -30.401133063168956
              ],
              [
                -51.12019100141219,
                -30.401949047048625
              ],
              [
                -51.118780942901644,
                -30.40809301054691
              ],
              [
                -51.12568335151347,
                -30.415069542288617
              ],
              [
                -51.12509783372977,
                -30.42416548342202
              ],
              [
                -51.12508081280889,
                -30.42818797061128
              ],
              [
                -51.131221232542494,
                -30.43461348000846
              ],
              [
                -51.14225944418978,
                -30.47053493077095
              ],
              [
                -51.135223177913446,
                -30.436263515937203
              ],
              [
                -51.139097188569146,
                -30.434243513068974
              ],
              [
                -51.143312154505466,
                -30.431825030703923
              ],
              [
                -51.14143819792687,
                -30.4261015650288
              ],
              [
                -51.14620768361393,
                -30.420566572936732
              ],
              [
                -51.14873717214444,
                -30.417631111450007
              ],
              [
                -51.15222166734666,
                -30.413637642806254
              ],
              [
                -51.15611461956264,
                -30.41281963550189
              ],
              [
                -51.1624770741549,
                -30.41148364242077
              ],
              [
                -51.181995962898895,
                -30.407384762144968
              ],
              [
                -51.19992129364979,
                -30.40790230415574
              ],
              [
                -51.20635019574035,
                -30.411339816440435
              ],
              [
                -51.21085564203821,
                -30.413750354153464
              ],
              [
                -51.21361913755535,
                -30.415228349728444
              ],
              [
                -51.22864145271788,
                -30.42328786942742
              ],
              [
                -51.24443272459257,
                -30.442580881586032
              ],
              [
                -51.24676021634306,
                -30.4504083750421
              ],
              [
                -51.24718268555646,
                -30.45180034166881
              ],
              [
                -51.24954913214087,
                -30.459594338391945
              ],
              [
                -51.25795051864358,
                -30.46632734601171
              ],
              [
                -51.26122195746973,
                -30.47341381838796
              ],
              [
                -51.264381400982664,
                -30.480351841765604
              ],
              [
                -51.26796035702418,
                -30.48824081806085
              ],
              [
                -51.28709697751188,
                -30.53322223972644
              ],
              [
                -51.29175737633775,
                -30.544177254572773
              ],
              [
                -51.293889848309504,
                -30.54919172255942
              ],
              [
                -51.297915237478016,
                -30.558789739844908
              ],
              [
                -51.29876019585017,
                -30.56383621166119
              ],
              [
                -51.30281105854469,
                -30.588027137120132
              ],
              [
                -51.30203588851174,
                -30.6198160517475
              ],
              [
                -51.30183088957061,
                -30.628231546789664
              ],
              [
                -51.30169183351147,
                -30.633925016908897
              ],
              [
                -51.293208073783155,
                -30.701965806420436
              ],
              [
                -51.29256159449538,
                -30.707152775026465
              ],
              [
                -51.293247486327424,
                -30.720204220471
              ],
              [
                -51.293442493935544,
                -30.723917196644997
              ],
              [
                -51.29415089419901,
                -30.73740420297226
              ],
              [
                -51.29548282036717,
                -30.750498642930317
              ],
              [
                -51.299123413277606,
                -30.73082872051675
              ],
              [
                -51.30268540208508,
                -30.722544262328064
              ],
              [
                -51.305940416619634,
                -30.71497130089157
              ],
              [
                -51.316024415110746,
                -30.703414850988377
              ],
              [
                -51.31499842592373,
                -30.69733689626754
              ],
              [
                -51.31352600275148,
                -30.687810919633254
              ],
              [
                -51.31888046663251,
                -30.679357948074486
              ],
              [
                -51.31606151379081,
                -30.67579545905933
              ],
              [
                -51.31686761299354,
                -30.654566493001017
              ],
              [
                -51.31699561983616,
                -30.65119752215454
              ],
              [
                -51.317130171612554,
                -30.647642017869924
              ],
              [
                -51.32894810459197,
                -30.637615603497917
              ],
              [
                -51.3340750935002,
                -30.633265640307183
              ],
              [
                -51.363559332896976,
                -30.632718730932442
              ],
              [
                -51.37649571561401,
                -30.639857271904177
              ],
              [
                -51.38642551813797,
                -30.65450026253962
              ],
              [
                -51.386995000138015,
                -30.657568260046258
              ],
              [
                -51.38768848353284,
                -30.66130973812598
              ],
              [
                -51.391744349757374,
                -30.683180713890774
              ],
              [
                -51.39343731821766,
                -30.692306194630095
              ],
              [
                -51.39512976766331,
                -30.70143063338661
              ],
              [
                -51.405259391705044,
                -30.756051030527267
              ],
              [
                -51.404064294056184,
                -30.776888983671117
              ],
              [
                -51.403736293220625,
                -30.782405959728578
              ],
              [
                -51.39003612345539,
                -30.83977174767696
              ],
              [
                -51.38117709653486,
                -30.860420153924085
              ],
              [
                -51.36861911179832,
                -30.873672559279825
              ],
              [
                -51.37474005363513,
                -30.87547957066094
              ],
              [
                -51.38954543771111,
                -30.879369603918537
              ],
              [
                -51.40845178212192,
                -30.877109174954004
              ],
              [
                -51.42884509830789,
                -30.874671262455852
              ],
              [
                -51.43044960906417,
                -30.873934264955118
              ],
              [
                -51.43467359673031,
                -30.871994306329302
              ],
              [
                -51.43804906728823,
                -30.870443827209325
              ],
              [
                -51.444720516648516,
                -30.87225182083041
              ],
              [
                -51.447084485745556,
                -30.872892365493446
              ],
              [
                -51.46481877573722,
                -30.882378406845397
              ],
              [
                -51.47609363962149,
                -30.888569409630954
              ],
              [
                -51.4960933712429,
                -30.914871928036842
              ],
              [
                -51.4994486302542,
                -30.951405829225912
              ],
              [
                -51.499414128848414,
                -30.95523130538987
              ],
              [
                -51.49902956673611,
                -30.97228426218448
              ],
              [
                -51.493406488199255,
                -30.994222693320534
              ],
              [
                -51.487473934821516,
                -31.01695109305487
              ],
              [
                -51.485175909964994,
                -31.025758033256814
              ],
              [
                -51.477307873448666,
                -31.040740960837645
              ],
              [
                -51.47613840099093,
                -31.04296798392573
              ],
              [
                -51.472591909537016,
                -31.049720952837895
              ],
              [
                -51.4649928685395,
                -31.064191378945555
              ],
              [
                -51.45946435387828,
                -31.074719824373314
              ],
              [
                -51.451373916822995,
                -31.082400253407105
              ],
              [
                -51.44888140051838,
                -31.08476473678561
              ],
              [
                -51.44514242366814,
                -31.08581071981058
              ],
              [
                -51.440198968923426,
                -31.087193689045183
              ],
              [
                -51.44817539341126,
                -31.090633731613377
              ],
              [
                -51.46071076260925,
                -31.096038279109727
              ],
              [
                -51.466598215185044,
                -31.0973007843363
              ],
              [
                -51.47891209795885,
                -31.099815797003366
              ],
              [
                -51.488333982807305,
                -31.1017398466627
              ],
              [
                -51.48834246572658,
                -31.101741837691375
              ],
              [
                -51.49013545395324,
                -31.10210782990808
              ],
              [
                -51.495954429515436,
                -31.103296381084302
              ],
              [
                -51.52300917246721,
                -31.108821465946455
              ],
              [
                -51.52748314563015,
                -31.109734959477024
              ],
              [
                -51.537227046569456,
                -31.111715501334025
              ],
              [
                -51.54503095242286,
                -31.112609533373462
              ],
              [
                -51.55394990139973,
                -31.113632546565626
              ],
              [
                -51.55731485321554,
                -31.11738506278136
              ],
              [
                -51.559810288178554,
                -31.12022305101751
              ],
              [
                -51.566653703343775,
                -31.12800505516874
              ],
              [
                -51.57544815212812,
                -31.128887088280077
              ],
              [
                -51.57693112419445,
                -31.129020109907568
              ],
              [
                -51.588322019847276,
                -31.130044128133072
              ],
              [
                -51.601421382627876,
                -31.131220682858416
              ],
              [
                -51.606893871017434,
                -31.133889188634996
              ],
              [
                -51.614262247761296,
                -31.137481204543505
              ],
              [
                -51.61757021321757,
                -31.139187719226303
              ],
              [
                -51.625561104615194,
                -31.14921772394975
              ],
              [
                -51.628123088943184,
                -31.152433232472834
              ],
              [
                -51.63887985231806,
                -31.180207165701777
              ],
              [
                -51.63904432598219,
                -31.18257519886639
              ],
              [
                -51.63920830365183,
                -31.184942683031387
              ],
              [
                -51.64199958469098,
                -31.22520256796044
              ],
              [
                -51.64107759217506,
                -31.227951047692155
              ],
              [
                -51.640156591654936,
                -31.230698546431153
              ],
              [
                -51.63020951775684,
                -31.258115929018775
              ],
              [
                -51.629207001798214,
                -31.26088041838673
              ],
              [
                -51.62791253781963,
                -31.26444839737236
              ],
              [
                -51.627739519616036,
                -31.26492589836399
              ],
              [
                -51.62349052633774,
                -31.2658833894888
              ],
              [
                -51.61617512153411,
                -31.267165372273453
              ],
              [
                -51.63056497930556,
                -31.26820339287966
              ],
              [
                -51.650504778212394,
                -31.269641987959726
              ],
              [
                -51.65738472485219,
                -31.27013750056494
              ],
              [
                -51.66368417349922,
                -31.27059252792514
              ],
              [
                -51.6699526533876,
                -31.269780563619857
              ],
              [
                -51.6714931168111,
                -31.269581030425986
              ],
              [
                -51.676457610502794,
                -31.268938566359274
              ],
              [
                -51.68823151704599,
                -31.2674136203838
              ],
              [
                -51.691429495022874,
                -31.26699963051473
              ],
              [
                -51.69629394748328,
                -31.266370155009465
              ],
              [
                -51.70612583764979,
                -31.26509670145977
              ],
              [
                -51.720023235247844,
                -31.268750718028738
              ],
              [
                -51.72496767195586,
                -31.272132228937252
              ],
              [
                -51.725479664816916,
                -31.272482237067642
              ],
              [
                -51.73335204439777,
                -31.277868244446204
              ],
              [
                -51.73714252214119,
                -31.280242779415257
              ],
              [
                -51.742576485562594,
                -31.278597311007683
              ],
              [
                -51.74624545514315,
                -31.277558822765677
              ],
              [
                -51.74846196639267,
                -31.276931324494047
              ],
              [
                -51.74782696701469,
                -31.277945333152605
              ],
              [
                -51.74536444955799,
                -31.28187580944379
              ],
              [
                -51.75268388940883,
                -31.279521321641127
              ],
              [
                -51.75650735823303,
                -31.28545583508479
              ],
              [
                -51.767012251085994,
                -31.282167862793457
              ],
              [
                -51.76824030287053,
                -31.277843899537594
              ],
              [
                -51.789929153905774,
                -31.27274647470341
              ],
              [
                -51.81123287597527,
                -31.2852485515906
              ],
              [
                -51.81784081270537,
                -31.291406560842564
              ],
              [
                -51.81788130816028,
                -31.291445041903422
              ],
              [
                -51.81972276456023,
                -31.29316105376379
              ],
              [
                -51.82256772557856,
                -31.295807543198286
              ],
              [
                -51.83322259130258,
                -31.305721053830467
              ],
              [
                -51.833976577176045,
                -31.306975055515235
              ],
              [
                -51.83882751373316,
                -31.315039063509474
              ],
              [
                -51.843390446729146,
                -31.32272456137072
              ],
              [
                -51.84900984684501,
                -31.32629404267364
              ],
              [
                -51.85868274923863,
                -31.331571094932077
              ],
              [
                -51.85859728175016,
                -31.32492908750313
              ],
              [
                -51.85693083843302,
                -31.315540104043432
              ],
              [
                -51.85968584984824,
                -31.312648626096717
              ],
              [
                -51.86686580568499,
                -31.305113671750032
              ],
              [
                -51.87180881098034,
                -31.29972570447491
              ],
              [
                -51.866653373715934,
                -31.296028207424992
              ],
              [
                -51.86945637514593,
                -31.294327211458157
              ],
              [
                -51.87552631269876,
                -31.298244746646198
              ],
              [
                -51.874734262236665,
                -31.30799020610185
              ],
              [
                -51.87467973495679,
                -31.308663205062732
              ],
              [
                -51.88206667306637,
                -31.31449872847769
              ],
              [
                -51.87845562442022,
                -31.323748170785986
              ],
              [
                -51.883990085964214,
                -31.320153204083738
              ],
              [
                -51.89154056874168,
                -31.3151587523878
              ],
              [
                -51.89754552549652,
                -31.31408026482145
              ],
              [
                -51.901306987410834,
                -31.313404806005884
              ],
              [
                -51.90426895508448,
                -31.31287282153835
              ],
              [
                -51.90786944716759,
                -31.312225307990666
              ],
              [
                -51.91885688607608,
                -31.310252368191254
              ],
              [
                -51.921220844111986,
                -31.312164358699555
              ],
              [
                -51.92790124349968,
                -31.317735368998584
              ],
              [
                -51.93114423193616,
                -31.320439858974876
              ],
              [
                -51.929830661839326,
                -31.327805352790353
              ],
              [
                -51.9298216816245,
                -31.327857339613804
              ],
              [
                -51.92089874856784,
                -31.32734583951472
              ],
              [
                -51.92242573411171,
                -31.33130133311443
              ],
              [
                -51.9317886261007,
                -31.334058333980174
              ],
              [
                -51.93614410438992,
                -31.335324839385848
              ],
              [
                -51.94209651802103,
                -31.33705587607733
              ],
              [
                -51.943210541097145,
                -31.33737987276317
              ],
              [
                -51.94802546070032,
                -31.33881339160676
              ],
              [
                -51.95179541427416,
                -31.34323388715846
              ],
              [
                -51.95526086181648,
                -31.347387887187065
              ],
              [
                -51.95763782152241,
                -31.350236406270238
              ],
              [
                -51.95971331003981,
                -31.352842914771824
              ],
              [
                -51.96087824819985,
                -31.36171137675726
              ],
              [
                -51.961751709156985,
                -31.369423350662462
              ],
              [
                -51.95997822634902,
                -31.37038237383007
              ],
              [
                -51.956722735229214,
                -31.372141832783676
              ],
              [
                -51.95135927189897,
                -31.374886798463695
              ],
              [
                -51.95677571918345,
                -31.375846809072495
              ],
              [
                -51.96619913735254,
                -31.377399376510333
              ],
              [
                -51.96731413419601,
                -31.377583362584904
              ],
              [
                -51.97027759844765,
                -31.37910634764679
              ],
              [
                -51.98181596446681,
                -31.385480367866336
              ],
              [
                -51.983064422254216,
                -31.38835137930381
              ],
              [
                -51.985180391030156,
                -31.39353887810606
              ],
              [
                -51.98875280699268,
                -31.402292881357315
              ],
              [
                -51.993139727123086,
                -31.413043848637944
              ],
              [
                -51.99510267921133,
                -31.42341082002572
              ],
              [
                -51.995360158504646,
                -31.4247723204503
              ],
              [
                -51.99784004911573,
                -31.437862284725995
              ],
              [
                -52.004567317437505,
                -31.473385253790045
              ],
              [
                -52.007105742632746,
                -31.48678620960564
              ],
              [
                -52.00964413277019,
                -31.500187702455086
              ],
              [
                -52.01441195290569,
                -31.52458564536176
              ],
              [
                -52.01738786104367,
                -31.539814100275745
              ],
              [
                -52.01643983302892,
                -31.54867907690537
              ],
              [
                -52.01336672757139,
                -31.577437484192593
              ],
              [
                -52.012426192841765,
                -31.586225463098696
              ],
              [
                -52.01242567276442,
                -31.586239973058202
              ],
              [
                -52.011488122074674,
                -31.595012942026028
              ],
              [
                -52.00950908101105,
                -31.6135308729984
              ],
              [
                -52.01083251265708,
                -31.61840984659426
              ],
              [
                -52.0119099750471,
                -31.622271842860517
              ],
              [
                -52.01629188858884,
                -31.637964331612253
              ],
              [
                -52.03221347266773,
                -31.694637226503744
              ],
              [
                -52.04048489173224,
                -31.692879270336878
              ],
              [
                -52.04830786665088,
                -31.68667532597116
              ],
              [
                -52.05272186685351,
                -31.683067371693415
              ],
              [
                -52.05810631957921,
                -31.678667386506817
              ],
              [
                -52.057807870688116,
                -31.66935691669037
              ],
              [
                -52.04909044936274,
                -31.673040375970945
              ],
              [
                -52.04897843851526,
                -31.66840085668267
              ],
              [
                -52.04526199065814,
                -31.666515879262395
              ],
              [
                -52.04205100991381,
                -31.664938848126297
              ],
              [
                -52.0455490174468,
                -31.661005882916534
              ],
              [
                -52.04372508028805,
                -31.65589488480071
              ],
              [
                -52.04697354083177,
                -31.653029395756267
              ],
              [
                -52.04870553615518,
                -31.65010340860244
              ],
              [
                -52.0456450814917,
                -31.64652043736899
              ],
              [
                -52.04325013065503,
                -31.643948920231416
              ],
              [
                -52.039023682973834,
                -31.64489589727758
              ],
              [
                -52.03365621721067,
                -31.646098876936314
              ],
              [
                -52.028182237922344,
                -31.647196356433074
              ],
              [
                -52.03317324106261,
                -31.641512879002367
              ],
              [
                -52.03797021454671,
                -31.636050436188555
              ],
              [
                -52.03924523836642,
                -31.63298892350984
              ],
              [
                -52.05049726746628,
                -31.60597306109121
              ],
              [
                -52.05436979511776,
                -31.596675594568218
              ],
              [
                -52.05248785497767,
                -31.587188600805348
              ],
              [
                -52.03962751817701,
                -31.574966098999262
              ],
              [
                -52.04366899795454,
                -31.57373961190372
              ]
            ]
          ],
          [
            [
              [
                -51.29548282036717,
                -30.750498642930317
              ],
              [
                -51.281652202192156,
                -30.797339963441956
              ],
              [
                -51.28060321450701,
                -30.80114294044192
              ],
              [
                -51.28139616535283,
                -30.807799945668307
              ],
              [
                -51.28189712465947,
                -30.81201593667791
              ],
              [
                -51.28981403965938,
                -30.817000923077256
              ],
              [
                -51.29781748656207,
                -30.81570748667024
              ],
              [
                -51.28463263459907,
                -30.806501457262353
              ],
              [
                -51.28675967793992,
                -30.796988465796474
              ],
              [
                -51.2873736852977,
                -30.79672248900015
              ],
              [
                -51.30916552810111,
                -30.787277609714714
              ],
              [
                -51.31808049326997,
                -30.7834141402326
              ],
              [
                -51.323411452704235,
                -30.78035214218607
              ],
              [
                -51.31440652391063,
                -30.781664112565863
              ],
              [
                -51.31420802739165,
                -30.78169313569227
              ],
              [
                -51.30494760750124,
                -30.783794086812282
              ],
              [
                -51.29345273935241,
                -30.77011907294168
              ],
              [
                -51.29426077559798,
                -30.764519113110353
              ],
              [
                -51.29427127517893,
                -30.764394109508224
              ],
              [
                -51.29548282036717,
                -30.750498642930317
              ]
            ]
          ],
          [
            [
              [
                -50.098154376102705,
                -29.23608009721643
              ],
              [
                -50.09269797735683,
                -29.221852646686173
              ],
              [
                -50.08494705089276,
                -29.215208154209858
              ],
              [
                -50.087578028806256,
                -29.22248460356503
              ],
              [
                -50.083469521523284,
                -29.227521106022863
              ],
              [
                -50.093363395407565,
                -29.237209593712084
              ],
              [
                -50.098154376102705,
                -29.23608009721643
              ]
            ]
          ],
          [
            [
              [
                -51.148110905163776,
                -30.472971443726575
              ],
              [
                -51.14225944418978,
                -30.47053493077095
              ],
              [
                -51.15205423199832,
                -30.499743859423763
              ],
              [
                -51.15655119501938,
                -30.49839439907286
              ],
              [
                -51.148110905163776,
                -30.472971443726575
              ]
            ]
          ],
          [
            [
              [
                -49.956069809752385,
                -29.066980170154565
              ],
              [
                -49.95625933160896,
                -29.06167618515359
              ],
              [
                -49.94563596518481,
                -29.055148698144556
              ],
              [
                -49.93955648934777,
                -29.056686674635245
              ],
              [
                -49.942020424583426,
                -29.064257154043784
              ],
              [
                -49.951665346701375,
                -29.062528701372244
              ],
              [
                -49.94769337552886,
                -29.06924164977675
              ],
              [
                -49.956069809752385,
                -29.066980170154565
              ]
            ]
          ],
          [
            [
              [
                -51.29450067201935,
                -30.053588210468646
              ],
              [
                -51.28922871311871,
                -30.056898161127535
              ],
              [
                -51.268428879234506,
                -30.05538210052219
              ],
              [
                -51.291823634491095,
                -30.06434567923664
              ],
              [
                -51.29450067201935,
                -30.053588210468646
              ]
            ]
          ],
          [
            [
              [
                -52.10169283990944,
                -31.801462734691565
              ],
              [
                -52.106174294417734,
                -31.801588730982125
              ],
              [
                -52.110469785575546,
                -31.79273927881941
              ],
              [
                -52.093480964359614,
                -31.792671709435616
              ],
              [
                -52.10169283990944,
                -31.801462734691565
              ]
            ]
          ],
          [
            [
              [
                -53.41855619304469,
                -33.12956255391746
              ],
              [
                -53.42136263961999,
                -33.13144159003234
              ],
              [
                -53.420107693464864,
                -33.1245675669516
              ],
              [
                -53.41669578844394,
                -33.11823208619444
              ],
              [
                -53.41344481380302,
                -33.1125900675966
              ],
              [
                -53.4104239007125,
                -33.107685573359625
              ],
              [
                -53.406055905115494,
                -33.107527554534485
              ],
              [
                -53.40936935674369,
                -33.11341405384433
              ],
              [
                -53.41277782868431,
                -33.11799557551501
              ],
              [
                -53.41516228791613,
                -33.122578063692195
              ],
              [
                -53.41855619304469,
                -33.12956255391746
              ]
            ]
          ],
          [
            [
              [
                -49.96854897613144,
                -29.12047204821364
              ],
              [
                -49.967652520212155,
                -29.11555856186693
              ],
              [
                -49.96383300766202,
                -29.114165555237008
              ],
              [
                -49.96235404908903,
                -29.117466044018585
              ],
              [
                -49.96854897613144,
                -29.12047204821364
              ]
            ]
          ],
          [
            [
              [
                -51.464723949442636,
                -31.549706012180916
              ],
              [
                -51.46210946767075,
                -31.54385752235867
              ],
              [
                -51.46268346430467,
                -31.548098000206426
              ],
              [
                -51.46392588686562,
                -31.55728047439701
              ],
              [
                -51.46601841088716,
                -31.554163989387632
              ],
              [
                -51.46485093580887,
                -31.549990008929836
              ],
              [
                -51.464723949442636,
                -31.549706012180916
              ]
            ]
          ],
          [
            [
              [
                -51.16902423090897,
                -30.264125146604336
              ],
              [
                -51.157841345123394,
                -30.261958607186955
              ],
              [
                -51.16346525958603,
                -30.266055602162204
              ],
              [
                -51.16902423090897,
                -30.264125146604336
              ]
            ]
          ],
          [
            [
              [
                -51.104953265197864,
                -30.25927739411893
              ],
              [
                -51.10212830951747,
                -30.253869405011635
              ],
              [
                -51.10029182569931,
                -30.258867408095444
              ],
              [
                -51.104953265197864,
                -30.25927739411893
              ]
            ]
          ],
          [
            [
              [
                -52.04446152013695,
                -31.567575648443785
              ],
              [
                -52.04366899795454,
                -31.57373961190372
              ],
              [
                -52.04696898854754,
                -31.571849122502737
              ],
              [
                -52.04446152013695,
                -31.567575648443785
              ]
            ]
          ],
          [
            [
              [
                -51.191485686760025,
                -30.23457878840109
              ],
              [
                -51.18779270275689,
                -30.23368229358424
              ],
              [
                -51.190905153954986,
                -30.23834927304168
              ],
              [
                -51.191485686760025,
                -30.23457878840109
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 24,
        "nome": "Santa Catarina",
        "sigla": "SC",
        "regiao_id": 1,
        "codigo_ibg": 42
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -53.72045427494163,
                -26.559911585912136
              ],
              [
                -53.724071733584125,
                -26.561713073264205
              ],
              [
                -53.72978868327225,
                -26.56510457615939
              ],
              [
                -53.733971636101856,
                -26.56389761811846
              ],
              [
                -53.73408866537636,
                -26.560507615309255
              ],
              [
                -53.7252582302865,
                -26.5584341079733
              ],
              [
                -53.72357225463991,
                -26.55359458826127
              ],
              [
                -53.72615777020964,
                -26.54954764047053
              ],
              [
                -53.73439170222423,
                -26.547052650157838
              ],
              [
                -53.73999765394333,
                -26.54481119454131
              ],
              [
                -53.73507622343394,
                -26.54182015826449
              ],
              [
                -53.72888376708162,
                -26.53870217480594
              ],
              [
                -53.72518129414692,
                -26.54068916311081
              ],
              [
                -53.722444843619535,
                -26.5418486425372
              ],
              [
                -53.719110381033374,
                -26.537089653631934
              ],
              [
                -53.72212535978005,
                -26.533841676855875
              ],
              [
                -53.7185669297603,
                -26.526194662344373
              ],
              [
                -53.724739355210815,
                -26.525068188215986
              ],
              [
                -53.7263024116012,
                -26.51987172840028
              ],
              [
                -53.72686390052086,
                -26.51624972295834
              ],
              [
                -53.727548410862624,
                -26.511427259784618
              ],
              [
                -53.72745094091568,
                -26.5046267530924
              ],
              [
                -53.72483047968952,
                -26.500137787939934
              ],
              [
                -53.72212547635876,
                -26.502322776218985
              ],
              [
                -53.71815852353811,
                -26.506529749501745
              ],
              [
                -53.715264516238825,
                -26.504540737399253
              ],
              [
                -53.71265957335845,
                -26.5030902286254
              ],
              [
                -53.70967404813216,
                -26.504381726738977
              ],
              [
                -53.70866306301453,
                -26.504773731842523
              ],
              [
                -53.70387809385995,
                -26.506628694668432
              ],
              [
                -53.7071261157881,
                -26.50002122945115
              ],
              [
                -53.7050276599405,
                -26.496133744281305
              ],
              [
                -53.70105369120548,
                -26.490008715493524
              ],
              [
                -53.69424325600538,
                -26.48969420394716
              ],
              [
                -53.69618826217146,
                -26.48523572118927
              ],
              [
                -53.69884879293662,
                -26.478304747904794
              ],
              [
                -53.69455482678493,
                -26.474833249519357
              ],
              [
                -53.70010125576219,
                -26.472847790842778
              ],
              [
                -53.699147795798034,
                -26.467262291248204
              ],
              [
                -53.696411857578425,
                -26.46475779039537
              ],
              [
                -53.696119347659206,
                -26.460062319346527
              ],
              [
                -53.695959907743635,
                -26.454913844228408
              ],
              [
                -53.69703236047769,
                -26.4538278475611
              ],
              [
                -53.701048844247886,
                -26.4497603600234
              ],
              [
                -53.70285838400933,
                -26.44133788146563
              ],
              [
                -53.69945290497354,
                -26.440600371568497
              ],
              [
                -53.695361966154444,
                -26.44030337579703
              ],
              [
                -53.69571291640315,
                -26.444830381580303
              ],
              [
                -53.69526740421071,
                -26.444729880298354
              ],
              [
                -53.69194296061742,
                -26.4439813707283
              ],
              [
                -53.687353518858764,
                -26.44249233697683
              ],
              [
                -53.687358520668454,
                -26.43554384123038
              ],
              [
                -53.687429037950764,
                -26.42837489043242
              ],
              [
                -53.692157523708104,
                -26.429629896421865
              ],
              [
                -53.6926050416236,
                -26.428437396848558
              ],
              [
                -53.694826538139424,
                -26.422515446795625
              ],
              [
                -53.69734904826396,
                -26.416393974465816
              ],
              [
                -53.70379548363266,
                -26.412337977630784
              ],
              [
                -53.704505487993984,
                -26.408568027255054
              ],
              [
                -53.70004802592523,
                -26.408271011182695
              ],
              [
                -53.6958325847637,
                -26.40261998213525
              ],
              [
                -53.70579703678595,
                -26.395361564199895
              ],
              [
                -53.70735958322577,
                -26.387184590017487
              ],
              [
                -53.70010264303232,
                -26.38738954629213
              ],
              [
                -53.69979611174206,
                -26.3866220836516
              ],
              [
                -53.69902517112731,
                -26.38468607308993
              ],
              [
                -53.69692769798845,
                -26.379706072528105
              ],
              [
                -53.69795020385973,
                -26.37663758704209
              ],
              [
                -53.69815820205954,
                -26.37601211579536
              ],
              [
                -53.700375673658634,
                -26.372776623136073
              ],
              [
                -53.696583764611944,
                -26.362521129435173
              ],
              [
                -53.69357429242775,
                -26.361135641083383
              ],
              [
                -53.69248531264574,
                -26.36068160663355
              ],
              [
                -53.689121833210905,
                -26.35927812307181
              ],
              [
                -53.691151336923085,
                -26.356786656354465
              ],
              [
                -53.69273533557606,
                -26.35484216828208
              ],
              [
                -53.69006334980204,
                -26.354145143935256
              ],
              [
                -53.68850687033184,
                -26.353739130655956
              ],
              [
                -53.68764488518991,
                -26.350263664729333
              ],
              [
                -53.68761737689884,
                -26.350150164995338
              ],
              [
                -53.68678638262495,
                -26.345519161895883
              ],
              [
                -53.68399344176297,
                -26.338255689229413
              ],
              [
                -53.680625491631716,
                -26.33543018324171
              ],
              [
                -53.67658004307112,
                -26.3324996611679
              ],
              [
                -53.676255042561216,
                -26.332264157760733
              ],
              [
                -53.6728300910226,
                -26.329401174698454
              ],
              [
                -53.67198408228525,
                -26.325121659439322
              ],
              [
                -53.67196261092799,
                -26.325012671713214
              ],
              [
                -53.671894614595054,
                -26.324977694582145
              ],
              [
                -53.66876114035297,
                -26.323361663558284
              ],
              [
                -53.668351654050795,
                -26.320276174024947
              ],
              [
                -53.66532369528358,
                -26.315877200345685
              ],
              [
                -53.6611582197779,
                -26.30950720195091
              ],
              [
                -53.66015224478605,
                -26.306688695431905
              ],
              [
                -53.65865278896746,
                -26.303540219212127
              ],
              [
                -53.65788681351366,
                -26.301776694155276
              ],
              [
                -53.655916318359196,
                -26.29723970173137
              ],
              [
                -53.654394866327145,
                -26.29599221531009
              ],
              [
                -53.652869363340955,
                -26.29474120688652
              ],
              [
                -53.64940292761139,
                -26.29153269483503
              ],
              [
                -53.646888424342336,
                -26.289314217998466
              ],
              [
                -53.644524959025745,
                -26.287074703772426
              ],
              [
                -53.643746469052836,
                -26.28204020823932
              ],
              [
                -53.64326700698666,
                -26.277876758967302
              ],
              [
                -53.6428250411444,
                -26.274279230004666
              ],
              [
                -53.64202055645413,
                -26.269676263996413
              ],
              [
                -53.641316058142685,
                -26.265724298246077
              ],
              [
                -53.64085358936767,
                -26.259186289721097
              ],
              [
                -53.64256561490082,
                -26.252257829232576
              ],
              [
                -53.625149721818104,
                -26.260322751946145
              ],
              [
                -53.62402620388339,
                -26.259505740575104
              ],
              [
                -53.62264773568457,
                -26.258503746892135
              ],
              [
                -53.62014578255264,
                -26.25668422184187
              ],
              [
                -53.60722887944259,
                -26.257497188084102
              ],
              [
                -53.60160739636911,
                -26.2573826663825
              ],
              [
                -53.5955434383168,
                -26.25620316855149
              ],
              [
                -53.58602602158576,
                -26.254967614579083
              ],
              [
                -53.582961545720416,
                -26.26430508039505
              ],
              [
                -53.58158750868615,
                -26.26849609191709
              ],
              [
                -53.57984848951702,
                -26.273799543535198
              ],
              [
                -53.571697581176,
                -26.275142025079173
              ],
              [
                -53.56997806792034,
                -26.275424987022227
              ],
              [
                -53.55490013649629,
                -26.285513430499908
              ],
              [
                -53.55409366718184,
                -26.286971433897076
              ],
              [
                -53.55116766304084,
                -26.29226087831471
              ],
              [
                -53.54402921709561,
                -26.291327366840104
              ],
              [
                -53.521649884050895,
                -26.292290796796934
              ],
              [
                -53.49668304271939,
                -26.302344200046065
              ],
              [
                -53.48739563991177,
                -26.297129645810013
              ],
              [
                -53.48197767327749,
                -26.294087658347927
              ],
              [
                -53.47264928447958,
                -26.28962513455521
              ],
              [
                -53.47121978962834,
                -26.29023362047832
              ],
              [
                -53.460687340682334,
                -26.29471607733705
              ],
              [
                -53.440534071673476,
                -26.280503550679956
              ],
              [
                -53.43184962268761,
                -26.27817002831917
              ],
              [
                -53.42004974125186,
                -26.274710035536298
              ],
              [
                -53.38937754610468,
                -26.249501525532967
              ],
              [
                -53.38479260234777,
                -26.245732990526356
              ],
              [
                -53.38313560358803,
                -26.246720490406027
              ],
              [
                -53.379965149353495,
                -26.248610494953365
              ],
              [
                -53.37792265673524,
                -26.245662501317355
              ],
              [
                -53.37507521329758,
                -26.241552972617114
              ],
              [
                -53.36820273977298,
                -26.247413437021653
              ],
              [
                -53.3635312597725,
                -26.251396897387085
              ],
              [
                -53.35770530411144,
                -26.249304420503965
              ],
              [
                -53.3560558312161,
                -26.24871139857628
              ],
              [
                -53.3550023124833,
                -26.249791376298678
              ],
              [
                -53.35038985078302,
                -26.254520867431722
              ],
              [
                -53.34783937417459,
                -26.251081880604698
              ],
              [
                -53.34493292736509,
                -26.247162362087977
              ],
              [
                -53.34037095594455,
                -26.248084359851447
              ],
              [
                -53.335820486451325,
                -26.24900384266414
              ],
              [
                -53.335432457629004,
                -26.253295309244862
              ],
              [
                -53.33538646573646,
                -26.25380783240474
              ],
              [
                -53.331655986346234,
                -26.25396781461614
              ],
              [
                -53.31864161468126,
                -26.254526761514246
              ],
              [
                -53.31406363317718,
                -26.257101233811515
              ],
              [
                -53.31112861524045,
                -26.258752230972178
              ],
              [
                -53.30543366022133,
                -26.259269178032948
              ],
              [
                -53.299718730204155,
                -26.256162186892226
              ],
              [
                -53.28754434959635,
                -26.249543151315798
              ],
              [
                -53.28096939595848,
                -26.246700646276533
              ],
              [
                -53.28027786991411,
                -26.25409261758514
              ],
              [
                -53.279781847528575,
                -26.259390129459103
              ],
              [
                -53.27618990270793,
                -26.259873100111506
              ],
              [
                -53.27313392713593,
                -26.260283077907992
              ],
              [
                -53.25924895844193,
                -26.27954347643028
              ],
              [
                -53.260138905446716,
                -26.2825674676858
              ],
              [
                -53.26168492170897,
                -26.28781993897678
              ],
              [
                -53.25545294507922,
                -26.28919291931663
              ],
              [
                -53.254267949946644,
                -26.28945393824743
              ],
              [
                -53.253822435917684,
                -26.29114843611183
              ],
              [
                -53.25186544023068,
                -26.298587885783213
              ],
              [
                -53.24682296135366,
                -26.297496887420685
              ],
              [
                -53.24549348272319,
                -26.297209374634
              ],
              [
                -53.23072055489312,
                -26.312099800303333
              ],
              [
                -53.20478516120516,
                -26.329008611625785
              ],
              [
                -53.2009336847476,
                -26.329187138335634
              ],
              [
                -53.197082756288566,
                -26.32936559504834
              ],
              [
                -53.194936252603554,
                -26.331654576908612
              ],
              [
                -53.1839512739221,
                -26.343369018424873
              ],
              [
                -53.16875688099281,
                -26.347633461369657
              ],
              [
                -53.164699414909556,
                -26.349002945439768
              ],
              [
                -53.160521425570124,
                -26.350412930947837
              ],
              [
                -53.15749244869578,
                -26.347666914174177
              ],
              [
                -53.15302300752359,
                -26.343614934564002
              ],
              [
                -53.14529157246172,
                -26.345212387818
              ],
              [
                -53.140030067961696,
                -26.356759810209404
              ],
              [
                -53.134870610077506,
                -26.35863680269148
              ],
              [
                -53.13326008721083,
                -26.36368777838924
              ],
              [
                -53.132270581136964,
                -26.366793285680426
              ],
              [
                -53.12402860036608,
                -26.373778221431028
              ],
              [
                -53.108998729105316,
                -26.376272652747552
              ],
              [
                -53.10706670223116,
                -26.380972133449447
              ],
              [
                -53.1002457480542,
                -26.383533610763163
              ],
              [
                -53.09384079890689,
                -26.38263157492523
              ],
              [
                -53.09010082008684,
                -26.390009070397102
              ],
              [
                -53.07403243854727,
                -26.38641899199108
              ],
              [
                -53.0462987075842,
                -26.380225446682427
              ],
              [
                -53.02727986344106,
                -26.369156901490715
              ],
              [
                -53.005155578982745,
                -26.356273908342338
              ],
              [
                -52.989824274277424,
                -26.34734588336596
              ],
              [
                -52.987725259179754,
                -26.34715185855643
              ],
              [
                -52.985626782080594,
                -26.346957371750662
              ],
              [
                -52.94995699500548,
                -26.361827187050554
              ],
              [
                -52.936888640817685,
                -26.361196648759393
              ],
              [
                -52.9309996530454,
                -26.36070911247658
              ],
              [
                -52.926176223729875,
                -26.360310132683644
              ],
              [
                -52.91624931865939,
                -26.355071587801078
              ],
              [
                -52.907859375585915,
                -26.35904156291042
              ],
              [
                -52.87747206218687,
                -26.360022963917682
              ],
              [
                -52.874224106815795,
                -26.359169440232208
              ],
              [
                -52.87097664144414,
                -26.35831544055144
              ],
              [
                -52.8666316657309,
                -26.355573953238885
              ],
              [
                -52.84747838037077,
                -26.34348593648297
              ],
              [
                -52.84436642050036,
                -26.345030931334758
              ],
              [
                -52.84351441219068,
                -26.345453879913727
              ],
              [
                -52.84166040429438,
                -26.345939392736604
              ],
              [
                -52.81416463348978,
                -26.344284326887177
              ],
              [
                -52.790487781567855,
                -26.352011707399736
              ],
              [
                -52.78292936029014,
                -26.3497926740304
              ],
              [
                -52.77537094601762,
                -26.347573697668803
              ],
              [
                -52.76887495507163,
                -26.34960366793201
              ],
              [
                -52.76395197619127,
                -26.351141624397293
              ],
              [
                -52.75921403513267,
                -26.352624626699793
              ],
              [
                -52.7562005462005,
                -26.349432596666368
              ],
              [
                -52.751194134773115,
                -26.34413059461701
              ],
              [
                -52.741458691878464,
                -26.344984091349442
              ],
              [
                -52.737252238594834,
                -26.341681581483186
              ],
              [
                -52.725103805159215,
                -26.347843014998684
              ],
              [
                -52.72128333428623,
                -26.35295147046303
              ],
              [
                -52.71835032475831,
                -26.356872961021395
              ],
              [
                -52.708267924308835,
                -26.35457694602882
              ],
              [
                -52.69976096567244,
                -26.356431911769867
              ],
              [
                -52.67807816699371,
                -26.352190339295316
              ],
              [
                -52.65753821625881,
                -26.374204721212685
              ],
              [
                -52.64673781460089,
                -26.379448158529694
              ],
              [
                -52.64455731478848,
                -26.380506126289482
              ],
              [
                -52.63556287305932,
                -26.384872610914556
              ],
              [
                -52.63320433726047,
                -26.39060256844428
              ],
              [
                -52.620605952103794,
                -26.39501998915182
              ],
              [
                -52.61379845719612,
                -26.4086399306022
              ],
              [
                -52.61342344681399,
                -26.409390439770355
              ],
              [
                -52.61267444349873,
                -26.40964043328662
              ],
              [
                -52.601021008369074,
                -26.4135368610613
              ],
              [
                -52.59594407558883,
                -26.41443985609048
              ],
              [
                -52.59361756972893,
                -26.41485383448011
              ],
              [
                -52.590040589636175,
                -26.413176858432745
              ],
              [
                -52.57849173034643,
                -26.407761821698905
              ],
              [
                -52.57074576451128,
                -26.409047784020263
              ],
              [
                -52.564489833266904,
                -26.410085777438095
              ],
              [
                -52.55896188232409,
                -26.407472278644182
              ],
              [
                -52.55486139991671,
                -26.411157223826304
              ],
              [
                -52.538515545416836,
                -26.403514208657093
              ],
              [
                -52.523643147009324,
                -26.40081869416389
              ],
              [
                -52.522456675111634,
                -26.400603183696695
              ],
              [
                -52.518182720916904,
                -26.403636665440494
              ],
              [
                -52.495767816840775,
                -26.419542517980442
              ],
              [
                -52.48598440137385,
                -26.420252487050064
              ],
              [
                -52.476201977919054,
                -26.420961469130024
              ],
              [
                -52.46765546244939,
                -26.43279736625927
              ],
              [
                -52.46505599486936,
                -26.436396873009095
              ],
              [
                -52.46075652718784,
                -26.43936585486777
              ],
              [
                -52.458035554172305,
                -26.43938084920637
              ],
              [
                -52.44606414350223,
                -26.439448813697123
              ],
              [
                -52.44345764360924,
                -26.439463290443793
              ],
              [
                -52.4213543349452,
                -26.43733973553217
              ],
              [
                -52.41185189562192,
                -26.43249322527381
              ],
              [
                -52.4025380174187,
                -26.427743703374272
              ],
              [
                -52.400588559529346,
                -26.42268470150751
              ],
              [
                -52.39398505769432,
                -26.43049314895831
              ],
              [
                -52.38634361596249,
                -26.434611602153254
              ],
              [
                -52.37624616850524,
                -26.440054573227215
              ],
              [
                -52.37154173701834,
                -26.436135575815204
              ],
              [
                -52.36683776852536,
                -26.43221655441288
              ],
              [
                -52.35810130814428,
                -26.43752452973935
              ],
              [
                -52.35261784815368,
                -26.440856472186415
              ],
              [
                -52.34882589687772,
                -26.4384835008029
              ],
              [
                -52.34143144533667,
                -26.433856960313
              ],
              [
                -52.33799597446121,
                -26.439262928993003
              ],
              [
                -52.33597846629358,
                -26.442437946186452
              ],
              [
                -52.32299509172537,
                -26.44020137594875
              ],
              [
                -52.31376514821709,
                -26.44022735232834
              ],
              [
                -52.304536740714205,
                -26.440252346721213
              ],
              [
                -52.283906336081394,
                -26.45790172349595
              ],
              [
                -52.283728823442964,
                -26.45836522330733
              ],
              [
                -52.28233131438962,
                -26.46202019205715
              ],
              [
                -52.27408237168697,
                -26.461735151939205
              ],
              [
                -52.27064343727993,
                -26.45946313548143
              ],
              [
                -52.262875007938824,
                -26.4543311534223
              ],
              [
                -52.248451632783926,
                -26.454607591674645
              ],
              [
                -52.24574512539255,
                -26.45792405994131
              ],
              [
                -52.24185813787902,
                -26.462688042161044
              ],
              [
                -52.22893824819692,
                -26.464320005132123
              ],
              [
                -52.219902799478504,
                -26.465460452450724
              ],
              [
                -52.2050064290738,
                -26.45999345846863
              ],
              [
                -52.20363244895361,
                -26.45948844233866
              ],
              [
                -52.19001512573507,
                -26.445704931046837
              ],
              [
                -52.18514916099859,
                -26.444918424590085
              ],
              [
                -52.17656867236754,
                -26.453067373809272
              ],
              [
                -52.157277778909396,
                -26.471388738889893
              ],
              [
                -52.14395438772542,
                -26.473440692049248
              ],
              [
                -52.13779292554555,
                -26.47140618425874
              ],
              [
                -52.133535975801706,
                -26.47000116204123
              ],
              [
                -52.12995596992449,
                -26.474089652590806
              ],
              [
                -52.12863549266285,
                -26.475596630838087
              ],
              [
                -52.12241050219127,
                -26.475588114963855
              ],
              [
                -52.11176059223091,
                -26.47557308954731
              ],
              [
                -52.10547214855866,
                -26.47895157397013
              ],
              [
                -52.09918368389305,
                -26.482330020391668
              ],
              [
                -52.09798213020522,
                -26.493158499451265
              ],
              [
                -52.09674211128792,
                -26.50433293021748
              ],
              [
                -52.092896633782146,
                -26.512491395038612
              ],
              [
                -52.089139646218115,
                -26.5099303824864
              ],
              [
                -52.08753814545384,
                -26.508838876546545
              ],
              [
                -52.08235317474384,
                -26.518296857244184
              ],
              [
                -52.07841019619519,
                -26.525489816991083
              ],
              [
                -52.068237258167656,
                -26.52420027253341
              ],
              [
                -52.06407780491579,
                -26.52367275767466
              ],
              [
                -52.0619072740379,
                -26.526847265272576
              ],
              [
                -52.059766783116544,
                -26.529977751125507
              ],
              [
                -52.056944314731716,
                -26.528923236760072
              ],
              [
                -52.05469784091872,
                -26.528083245696966
              ],
              [
                -52.04698738743944,
                -26.534898170444567
              ],
              [
                -52.03230998892021,
                -26.540436611981466
              ],
              [
                -52.02957045908245,
                -26.546232067684194
              ],
              [
                -52.02072302854708,
                -26.5482150318012
              ],
              [
                -52.01984001680838,
                -26.554791020394575
              ],
              [
                -52.0148705312326,
                -26.557177994796934
              ],
              [
                -52.01368253740859,
                -26.559727503968396
              ],
              [
                -52.01073106888447,
                -26.56605895410707
              ],
              [
                -51.986052727427,
                -26.56544539225413
              ],
              [
                -51.98471626001849,
                -26.56541235365929
              ],
              [
                -51.98431675143429,
                -26.565804352922264
              ],
              [
                -51.98145376657285,
                -26.56861534230154
              ],
              [
                -51.974435303784006,
                -26.567849337182853
              ],
              [
                -51.97113388116697,
                -26.567489330778734
              ],
              [
                -51.96822988071424,
                -26.56913380597072
              ],
              [
                -51.95937641912196,
                -26.574147246774892
              ],
              [
                -51.956563462099425,
                -26.5732347429672
              ],
              [
                -51.946315043750744,
                -26.569909735176523
              ],
              [
                -51.92777261994954,
                -26.584354599824835
              ],
              [
                -51.899060874209546,
                -26.579655023734485
              ],
              [
                -51.88361197366449,
                -26.58552546840201
              ],
              [
                -51.883175976269925,
                -26.586630465108286
              ],
              [
                -51.87921346658798,
                -26.596672946001046
              ],
              [
                -51.8731055130758,
                -26.59974388805611
              ],
              [
                -51.86398209111626,
                -26.595716882648084
              ],
              [
                -51.854605658786156,
                -26.59200184830363
              ],
              [
                -51.843294736252396,
                -26.594577793741696
              ],
              [
                -51.831767872531444,
                -26.58724878717386
              ],
              [
                -51.823141466035985,
                -26.58176480554385
              ],
              [
                -51.818149511798715,
                -26.579554801536247
              ],
              [
                -51.802440596647635,
                -26.585625208618538
              ],
              [
                -51.798157163870826,
                -26.58663468712064
              ],
              [
                -51.79003919426416,
                -26.588547661064368
              ],
              [
                -51.78503076322118,
                -26.588169140757664
              ],
              [
                -51.77995828264807,
                -26.586648621127484
              ],
              [
                -51.757176981201006,
                -26.58073759031027
              ],
              [
                -51.745668612498676,
                -26.57753407886174
              ],
              [
                -51.745658578588426,
                -26.57753155983511
              ],
              [
                -51.73414021499056,
                -26.57432502737961
              ],
              [
                -51.69455547070521,
                -26.582943368982832
              ],
              [
                -51.66456378484261,
                -26.565587344338844
              ],
              [
                -51.655111841996856,
                -26.5704802824227
              ],
              [
                -51.645660400161546,
                -26.575373236505463
              ],
              [
                -51.64365192295907,
                -26.576990231915918
              ],
              [
                -51.63094149664868,
                -26.587222145243782
              ],
              [
                -51.62278403884333,
                -26.588844110083855
              ],
              [
                -51.61462608205758,
                -26.590465071926445
              ],
              [
                -51.609537126261316,
                -26.589052588944742
              ],
              [
                -51.59746022491802,
                -26.585701056149226
              ],
              [
                -51.5871008104832,
                -26.589405485126484
              ],
              [
                -51.58394985142825,
                -26.590532504214394
              ],
              [
                -51.56936546370433,
                -26.59091743080914
              ],
              [
                -51.529703254922914,
                -26.591964828343954
              ],
              [
                -51.523190816684796,
                -26.588642290999374
              ],
              [
                -51.51740636009794,
                -26.585690802931882
              ],
              [
                -51.509563954293945,
                -26.581689297294115
              ],
              [
                -51.49375654078974,
                -26.5891452012904
              ],
              [
                -51.49039551674208,
                -26.605218618105557
              ],
              [
                -51.4835975438281,
                -26.61158909135425
              ],
              [
                -51.483884535018404,
                -26.613099613151835
              ],
              [
                -51.48562701455558,
                -26.622262086676276
              ],
              [
                -51.476310572473864,
                -26.61941504387235
              ],
              [
                -51.47486107584015,
                -26.618972542323903
              ],
              [
                -51.467712633116655,
                -26.62155550939818
              ],
              [
                -51.463744652411286,
                -26.62664497796265
              ],
              [
                -51.45424015693477,
                -26.640158922103975
              ],
              [
                -51.454720132340086,
                -26.646799878899998
              ],
              [
                -51.45520009173708,
                -26.65344036870485
              ],
              [
                -51.44678813420251,
                -26.660360305397674
              ],
              [
                -51.43905769072203,
                -26.666719259407788
              ],
              [
                -51.43695115397591,
                -26.67717572901496
              ],
              [
                -51.43484665819261,
                -26.68763216564047
              ],
              [
                -51.40664831798377,
                -26.700130023741437
              ],
              [
                -51.41102570888885,
                -26.71679047275039
              ],
              [
                -51.398224335926514,
                -26.710420967767263
              ],
              [
                -51.398220846962886,
                -26.710418956761952
              ],
              [
                -51.39253545096928,
                -26.697766020407606
              ],
              [
                -51.39241098430613,
                -26.685022534891363
              ],
              [
                -51.39662048014656,
                -26.681991583088667
              ],
              [
                -51.405401448105444,
                -26.67566813565086
              ],
              [
                -51.39076807120008,
                -26.663386626761962
              ],
              [
                -51.38103768038529,
                -26.658966107959753
              ],
              [
                -51.37275577097185,
                -26.658499091588716
              ],
              [
                -51.36461283943534,
                -26.658039544682964
              ],
              [
                -51.36231886689114,
                -26.65681854287551
              ],
              [
                -51.35263745388164,
                -26.651666024817906
              ],
              [
                -51.33463811633682,
                -26.647535513165526
              ],
              [
                -51.33084814580574,
                -26.647201966070707
              ],
              [
                -51.32830765279195,
                -26.64908247768235
              ],
              [
                -51.326009161418824,
                -26.652450940990892
              ],
              [
                -51.323297668695304,
                -26.656424926760383
              ],
              [
                -51.31059677984143,
                -26.65146139954116
              ],
              [
                -51.30534728958073,
                -26.654665378100205
              ],
              [
                -51.28291799545845,
                -26.653499312760943
              ],
              [
                -51.28228705038869,
                -26.643114343552725
              ],
              [
                -51.282225036300396,
                -26.642092335879504
              ],
              [
                -51.27489662436897,
                -26.640252830667258
              ],
              [
                -51.27707763232732,
                -26.63579934772901
              ],
              [
                -51.26833218710854,
                -26.637320304921023
              ],
              [
                -51.26694166409335,
                -26.637561805228856
              ],
              [
                -51.26579419108664,
                -26.635967302755887
              ],
              [
                -51.258143813711925,
                -26.625333811968794
              ],
              [
                -51.248299373164755,
                -26.626639807087933
              ],
              [
                -51.24603587382717,
                -26.626939778149655
              ],
              [
                -51.23886497261762,
                -26.627890741831276
              ],
              [
                -51.237331508667374,
                -26.61865327458548
              ],
              [
                -51.23250154349577,
                -26.614365796651146
              ],
              [
                -51.22816756692149,
                -26.615516749539847
              ],
              [
                -51.23597157222189,
                -26.605729322781606
              ],
              [
                -51.237331570794524,
                -26.604022319458824
              ],
              [
                -51.23336757012827,
                -26.600802824843996
              ],
              [
                -51.22357870807053,
                -26.59285233641095
              ],
              [
                -51.22602871420644,
                -26.586916878609877
              ],
              [
                -51.22678220409506,
                -26.585090852592376
              ],
              [
                -51.224292281236025,
                -26.576410371157518
              ],
              [
                -51.224142268680225,
                -26.575886877459112
              ],
              [
                -51.216482857107465,
                -26.56899737680998
              ],
              [
                -51.22822027359677,
                -26.561652940253595
              ],
              [
                -51.22943783448795,
                -26.55478449743299
              ],
              [
                -51.223282895587154,
                -26.550301499656374
              ],
              [
                -51.23581627415446,
                -26.546122518821814
              ],
              [
                -51.24011027426018,
                -26.544691064748335
              ],
              [
                -51.23938129482269,
                -26.540787585824177
              ],
              [
                -51.23911279712669,
                -26.539351088900084
              ],
              [
                -51.24048277417795,
                -26.539138562405434
              ],
              [
                -51.246560243048094,
                -26.53819560082913
              ],
              [
                -51.24970326938529,
                -26.525338664597022
              ],
              [
                -51.25091675910325,
                -26.52037166114325
              ],
              [
                -51.2531797701852,
                -26.520134703835996
              ],
              [
                -51.25648723546546,
                -26.519787204545107
              ],
              [
                -51.26155069250259,
                -26.51276424064465
              ],
              [
                -51.26546766816738,
                -26.51236824064002
              ],
              [
                -51.26256372015904,
                -26.507015270223935
              ],
              [
                -51.27154218701995,
                -26.504454807357792
              ],
              [
                -51.268303705425204,
                -26.499853299161
              ],
              [
                -51.273780196085944,
                -26.496581345606316
              ],
              [
                -51.268084754777725,
                -26.490153342258814
              ],
              [
                -51.2607542915766,
                -26.49068282395133
              ],
              [
                -51.26609079395809,
                -26.481901856148973
              ],
              [
                -51.255667411545446,
                -26.476531837726483
              ],
              [
                -51.25045543434221,
                -26.473846838023135
              ],
              [
                -51.25508243108931,
                -26.472118862120418
              ],
              [
                -51.260080914495326,
                -26.470251368994177
              ],
              [
                -51.25712095085143,
                -26.4670043940732
              ],
              [
                -51.25348248976623,
                -26.463013374404756
              ],
              [
                -51.2643474053169,
                -26.461003454125667
              ],
              [
                -51.26730335924772,
                -26.460456953291786
              ],
              [
                -51.271039835850864,
                -26.456675984472756
              ],
              [
                -51.277412316426,
                -26.457835998527493
              ],
              [
                -51.27985428865891,
                -26.45373401333697
              ],
              [
                -51.27737234249075,
                -26.450188032122394
              ],
              [
                -51.27406337992841,
                -26.445459499179343
              ],
              [
                -51.28272482642641,
                -26.441004556796535
              ],
              [
                -51.29119727101517,
                -26.4366470984152
              ],
              [
                -51.29730531329063,
                -26.41897019241435
              ],
              [
                -51.28714288559895,
                -26.4138471871226
              ],
              [
                -51.29025441237537,
                -26.40790370270837
              ],
              [
                -51.292230900529134,
                -26.403973205315843
              ],
              [
                -51.2887129530568,
                -26.399596232457352
              ],
              [
                -51.28041152439145,
                -26.40084918731941
              ],
              [
                -51.276262021553485,
                -26.401475675755176
              ],
              [
                -51.27599705391936,
                -26.39860220490529
              ],
              [
                -51.27656604233396,
                -26.395810695656913
              ],
              [
                -51.277757054841885,
                -26.389969245251837
              ],
              [
                -51.27031365397285,
                -26.38499372793283
              ],
              [
                -51.26705866673878,
                -26.383351199429534
              ],
              [
                -51.252208804234854,
                -26.375855692358673
              ],
              [
                -51.25664681031073,
                -26.369707741287282
              ],
              [
                -51.261085810381275,
                -26.363558777222988
              ],
              [
                -51.25633389295783,
                -26.356287765329764
              ],
              [
                -51.251670444248404,
                -26.3491513032868
              ],
              [
                -51.256023915021444,
                -26.342989840981062
              ],
              [
                -51.25628639952539,
                -26.342618338193475
              ],
              [
                -51.25600194218274,
                -26.34205283019838
              ],
              [
                -51.252338500164875,
                -26.334767834154587
              ],
              [
                -51.25115498754186,
                -26.332413858345138
              ],
              [
                -51.24492158058211,
                -26.329659337510957
              ],
              [
                -51.24277059749129,
                -26.323569869511005
              ],
              [
                -51.22728523822473,
                -26.32117632449657
              ],
              [
                -51.223668274231734,
                -26.320617313985515
              ],
              [
                -51.21969381781787,
                -26.31705729781354
              ],
              [
                -51.21571787441535,
                -26.313496816644303
              ],
              [
                -51.207890981021755,
                -26.296616824170226
              ],
              [
                -51.20285802870954,
                -26.29830482287071
              ],
              [
                -51.19815106328262,
                -26.29988381007899
              ],
              [
                -51.1933330745798,
                -26.301500295772183
              ],
              [
                -51.18131618748899,
                -26.298970238294764
              ],
              [
                -51.16935829857297,
                -26.291102245891977
              ],
              [
                -51.16333135462912,
                -26.29103023239313
              ],
              [
                -51.163348362983506,
                -26.286902744037132
              ],
              [
                -51.16336540730025,
                -26.283019258821323
              ],
              [
                -51.149220505952634,
                -26.27910620297515
              ],
              [
                -51.14549303948153,
                -26.28312368093923
              ],
              [
                -51.143469568284864,
                -26.285304187263815
              ],
              [
                -51.13755260585123,
                -26.285619167787665
              ],
              [
                -51.13634311828278,
                -26.279987665549413
              ],
              [
                -51.13616765013426,
                -26.279170190838283
              ],
              [
                -51.13445416477177,
                -26.279157174989543
              ],
              [
                -51.12178326962069,
                -26.279063634734563
              ],
              [
                -51.10913185525054,
                -26.27355460273838
              ],
              [
                -51.10446791539714,
                -26.274935613810733
              ],
              [
                -51.10138744408778,
                -26.265179132797513
              ],
              [
                -51.09325156587583,
                -26.258493106549054
              ],
              [
                -51.09338857353095,
                -26.256897614678643
              ],
              [
                -51.094025082851786,
                -26.249472148206515
              ],
              [
                -51.08906266182481,
                -26.24648513176461
              ],
              [
                -51.084195213735235,
                -26.23433117520427
              ],
              [
                -51.08266976201241,
                -26.22788918085134
              ],
              [
                -51.07953427643816,
                -26.22775716956233
              ],
              [
                -51.06929385003709,
                -26.23667862472824
              ],
              [
                -51.062385378461094,
                -26.24269608064187
              ],
              [
                -51.05469793591845,
                -26.244485026904382
              ],
              [
                -50.99997491655237,
                -26.229988407823907
              ],
              [
                -50.99101397014574,
                -26.234639878545426
              ],
              [
                -50.984054498510076,
                -26.23913386068467
              ],
              [
                -50.97439405799979,
                -26.24537180135145
              ],
              [
                -50.97206002927068,
                -26.25421576484406
              ],
              [
                -50.96972601753208,
                -26.263058704347287
              ],
              [
                -50.95277513471649,
                -26.2691296325998
              ],
              [
                -50.9441971535811,
                -26.282014578289292
              ],
              [
                -50.93721825237006,
                -26.27887153554941
              ],
              [
                -50.931329335751904,
                -26.26784158566762
              ],
              [
                -50.9402268090038,
                -26.248378669557727
              ],
              [
                -50.94326933383635,
                -26.24172418671652
              ],
              [
                -50.94129436186956,
                -26.233809722192277
              ],
              [
                -50.9345184165584,
                -26.233382704516682
              ],
              [
                -50.90761256406807,
                -26.251092555169127
              ],
              [
                -50.908718454926465,
                -26.284386442174547
              ],
              [
                -50.90248946540318,
                -26.287258402579802
              ],
              [
                -50.900533503171815,
                -26.288160402657923
              ],
              [
                -50.899508966338466,
                -26.288632412462444
              ],
              [
                -50.8904855864517,
                -26.285252875584288
              ],
              [
                -50.88153767410424,
                -26.277571893330823
              ],
              [
                -50.880115756623994,
                -26.268034406511727
              ],
              [
                -50.87869278813657,
                -26.25849545171109
              ],
              [
                -50.86855093718483,
                -26.2417979866881
              ],
              [
                -50.86559396320777,
                -26.241923467123783
              ],
              [
                -50.86263744322842,
                -26.242048965561374
              ],
              [
                -50.8614949810956,
                -26.243768422502832
              ],
              [
                -50.8461694921438,
                -26.266829327593275
              ],
              [
                -50.83851505837616,
                -26.270812759150964
              ],
              [
                -50.83166561776243,
                -26.266350260669533
              ],
              [
                -50.82787022609953,
                -26.253735788824113
              ],
              [
                -50.825527766526896,
                -26.245947300698145
              ],
              [
                -50.79280260701661,
                -26.227244777909434
              ],
              [
                -50.75794287737297,
                -26.22122068353644
              ],
              [
                -50.751944425396225,
                -26.22523618964015
              ],
              [
                -50.7459474404173,
                -26.22925062875124
              ],
              [
                -50.74590793685222,
                -26.229937149149816
              ],
              [
                -50.74499887830899,
                -26.245851582885614
              ],
              [
                -50.731806504246606,
                -26.249279008532298
              ],
              [
                -50.7180051234263,
                -26.244286000359246
              ],
              [
                -50.714694185004404,
                -26.238058017449045
              ],
              [
                -50.731306668666576,
                -26.202918675501376
              ],
              [
                -50.70930292936627,
                -26.189055675450124
              ],
              [
                -50.70473944083475,
                -26.18618016627204
              ],
              [
                -50.69896903025967,
                -26.180837146887455
              ],
              [
                -50.67779279522942,
                -26.147356236705743
              ],
              [
                -50.65668854527488,
                -26.13525421175441
              ],
              [
                -50.652134573922964,
                -26.130631695014696
              ],
              [
                -50.64758013356814,
                -26.12600970428095
              ],
              [
                -50.651271142313014,
                -26.11659076891173
              ],
              [
                -50.6567551397643,
                -26.102597324201202
              ],
              [
                -50.64995874563341,
                -26.095245306788623
              ],
              [
                -50.64537430887319,
                -26.090286351220268
              ],
              [
                -50.640515394067506,
                -26.07840537384164
              ],
              [
                -50.62791905318978,
                -26.062631876430522
              ],
              [
                -50.59694627927126,
                -26.069958246949174
              ],
              [
                -50.586149346604216,
                -26.069229736051017
              ],
              [
                -50.55633714895723,
                -26.059354189124328
              ],
              [
                -50.55258967020972,
                -26.05494418751203
              ],
              [
                -50.55067122593565,
                -26.044955727414557
              ],
              [
                -50.55052425528997,
                -26.044188204714597
              ],
              [
                -50.55490323050915,
                -26.04147375140463
              ],
              [
                -50.556194184303116,
                -26.041445220871363
              ],
              [
                -50.566866626949,
                -26.041206758811175
              ],
              [
                -50.57972798440001,
                -26.04765677480796
              ],
              [
                -50.58287247575826,
                -26.04753729887654
              ],
              [
                -50.58934540974568,
                -26.043501332454777
              ],
              [
                -50.591657898239255,
                -26.042059345522603
              ],
              [
                -50.59118999154214,
                -26.021641421279508
              ],
              [
                -50.57130474132995,
                -26.003299943971783
              ],
              [
                -50.56290878710675,
                -26.00604139462902
              ],
              [
                -50.5548012873292,
                -26.020936322926246
              ],
              [
                -50.54912024828624,
                -26.038931740126934
              ],
              [
                -50.521381046819286,
                -26.030324183829237
              ],
              [
                -50.5130756103983,
                -26.025701651665706
              ],
              [
                -50.50886013763075,
                -26.02848365627939
              ],
              [
                -50.50417765832615,
                -26.031574613187942
              ],
              [
                -50.50218616853583,
                -26.027882126950363
              ],
              [
                -50.49971122231995,
                -26.02329261636238
              ],
              [
                -50.49356727164606,
                -26.02664461638527
              ],
              [
                -50.479528369827385,
                -26.026214562591075
              ],
              [
                -50.48113234333305,
                -26.02975906504805
              ],
              [
                -50.4816053212217,
                -26.030805042821594
              ],
              [
                -50.475590870149276,
                -26.034007005826794
              ],
              [
                -50.47602240136874,
                -26.030215033141335
              ],
              [
                -50.47676090228865,
                -26.023722072366976
              ],
              [
                -50.47103597701753,
                -26.02084603657449
              ],
              [
                -50.46805945119891,
                -26.02705602782784
              ],
              [
                -50.45923406084081,
                -26.02658399478894
              ],
              [
                -50.458705021706706,
                -26.035869441048895
              ],
              [
                -50.458656025566995,
                -26.03672395475938
              ],
              [
                -50.45825151367609,
                -26.036271964047305
              ],
              [
                -50.45392104036723,
                -26.03143345613377
              ],
              [
                -50.44300212051047,
                -26.03538939683838
              ],
              [
                -50.44915957187647,
                -26.039331402189323
              ],
              [
                -50.44969305944922,
                -26.043480397818318
              ],
              [
                -50.4393545941884,
                -26.05100533542288
              ],
              [
                -50.43624613026699,
                -26.05580180839714
              ],
              [
                -50.433137638348335,
                -26.060597280375326
              ],
              [
                -50.41790176015092,
                -26.061409216990718
              ],
              [
                -50.40813831820196,
                -26.065338211675734
              ],
              [
                -50.40779481665937,
                -26.061058201174074
              ],
              [
                -50.40762534678332,
                -26.058938723357006
              ],
              [
                -50.40292289750107,
                -26.057245688690134
              ],
              [
                -50.39910190251026,
                -26.062963187877834
              ],
              [
                -50.37108755417439,
                -26.079679505189315
              ],
              [
                -50.36603754963643,
                -26.086949001543626
              ],
              [
                -50.36098707610386,
                -26.094217450900576
              ],
              [
                -50.36589899231,
                -26.108548894060366
              ],
              [
                -50.36697796159804,
                -26.1116978841908
              ],
              [
                -50.36588046460658,
                -26.11140940253883
              ],
              [
                -50.360599000785655,
                -26.11001788479067
              ],
              [
                -50.3596509952991,
                -26.117281829028467
              ],
              [
                -50.35471853465002,
                -26.118311315604267
              ],
              [
                -50.35227855414199,
                -26.118819823503724
              ],
              [
                -50.340393600655496,
                -26.13015125592433
              ],
              [
                -50.33202767359889,
                -26.13407921829952
              ],
              [
                -50.32824471665404,
                -26.12861519650589
              ],
              [
                -50.32311073976022,
                -26.13444315384725
              ],
              [
                -50.3216612685563,
                -26.125481204740094
              ],
              [
                -50.333499167366966,
                -26.125251732555306
              ],
              [
                -50.33268972070189,
                -26.122155738147573
              ],
              [
                -50.32482776785062,
                -26.12337072415489
              ],
              [
                -50.32610881011933,
                -26.114785742899265
              ],
              [
                -50.32843577292651,
                -26.112007273924295
              ],
              [
                -50.33197226400234,
                -26.107782794324944
              ],
              [
                -50.33085580937582,
                -26.098747316640576
              ],
              [
                -50.326552317898944,
                -26.098277334842855
              ],
              [
                -50.325146354831595,
                -26.098123814662127
              ],
              [
                -50.32619687247834,
                -26.095685811136537
              ],
              [
                -50.33157433688549,
                -26.083208402988028
              ],
              [
                -50.32419442194079,
                -26.081088392874655
              ],
              [
                -50.32169543998745,
                -26.080370352081964
              ],
              [
                -50.31985800524162,
                -26.074686888727104
              ],
              [
                -50.31771900876498,
                -26.06807041678606
              ],
              [
                -50.29836023776883,
                -26.05488337598486
              ],
              [
                -50.28736732453787,
                -26.05308586658085
              ],
              [
                -50.27637493431926,
                -26.051287851192402
              ],
              [
                -50.27286298514647,
                -26.04750684227512
              ],
              [
                -50.267815515123914,
                -26.042074334272797
              ],
              [
                -50.26402854818463,
                -26.04410933004965
              ],
              [
                -50.25152463204045,
                -26.050828779272173
              ],
              [
                -50.25393913731031,
                -26.04022882839474
              ],
              [
                -50.254635642302695,
                -26.037172303985585
              ],
              [
                -50.25183719958483,
                -26.03139984282641
              ],
              [
                -50.25103021893131,
                -26.029734817244204
              ],
              [
                -50.23544332127229,
                -26.035811763476183
              ],
              [
                -50.23359030151103,
                -26.04665521431837
              ],
              [
                -50.23144031971068,
                -26.049591193280538
              ],
              [
                -50.223618322625704,
                -26.060272631656634
              ],
              [
                -50.21843886250117,
                -26.056587625804
              ],
              [
                -50.218232365771016,
                -26.0564401416527
              ],
              [
                -50.20936294220348,
                -26.05667263097451
              ],
              [
                -50.207541464880535,
                -26.061036575778648
              ],
              [
                -50.193739528528816,
                -26.06479155354148
              ],
              [
                -50.1930995521105,
                -26.068084022261992
              ],
              [
                -50.19250354827206,
                -26.07114949196753
              ],
              [
                -50.182038096348705,
                -26.078476924785317
              ],
              [
                -50.181940082833066,
                -26.078308436076192
              ],
              [
                -50.174497206561085,
                -26.06550648121993
              ],
              [
                -50.17075275208866,
                -26.064932950748624
              ],
              [
                -50.16172685430269,
                -26.054128990263095
              ],
              [
                -50.16116933734594,
                -26.05302348046879
              ],
              [
                -50.15538444112284,
                -26.041562008340748
              ],
              [
                -50.16314238662157,
                -26.036215042124613
              ],
              [
                -50.16416939075257,
                -26.042679535712274
              ],
              [
                -50.17104180882344,
                -26.042089564967437
              ],
              [
                -50.17601231165853,
                -26.03361561492719
              ],
              [
                -50.18204027776791,
                -26.033943621956816
              ],
              [
                -50.17926678447184,
                -26.02826211761097
              ],
              [
                -50.17137186382448,
                -26.024668607376487
              ],
              [
                -50.16369344004652,
                -26.026521587764815
              ],
              [
                -50.15100606681513,
                -26.022734052184905
              ],
              [
                -50.141516129283914,
                -26.027111494172303
              ],
              [
                -50.14784850438275,
                -26.04143046852766
              ],
              [
                -50.15198693653489,
                -26.05078745887975
              ],
              [
                -50.14907991661791,
                -26.057915427811597
              ],
              [
                -50.13591161331173,
                -26.044655914547054
              ],
              [
                -50.13425763149084,
                -26.042990952145573
              ],
              [
                -50.13105862420277,
                -26.04787090738245
              ],
              [
                -50.12057814791197,
                -26.063856793158465
              ],
              [
                -50.118889179136325,
                -26.05593435074708
              ],
              [
                -50.11790170415725,
                -26.05130184854634
              ],
              [
                -50.111193251704115,
                -26.053136336252468
              ],
              [
                -50.11089077870292,
                -26.054458824350437
              ],
              [
                -50.109897761848906,
                -26.058800304976327
              ],
              [
                -50.09335193328227,
                -26.0447007935767
              ],
              [
                -50.09157297886077,
                -26.043185290215572
              ],
              [
                -50.084327601015794,
                -26.023048838450187
              ],
              [
                -50.080748612420294,
                -26.024136320429683
              ],
              [
                -50.078972064157874,
                -26.03670028395881
              ],
              [
                -50.077670563841636,
                -26.045904240531783
              ],
              [
                -50.072620628891436,
                -26.034876761418484
              ],
              [
                -50.0724561536969,
                -26.034516786200065
              ],
              [
                -50.06443172505944,
                -26.032906254270294
              ],
              [
                -50.063844221839446,
                -26.033140267434693
              ],
              [
                -50.056142766395205,
                -26.036208231261956
              ],
              [
                -50.04404641170369,
                -26.02922369761644
              ],
              [
                -50.039338938857625,
                -26.026505719926007
              ],
              [
                -50.035516454489056,
                -26.027536199303647
              ],
              [
                -50.03169449912058,
                -26.028566192684867
              ],
              [
                -50.00584515940785,
                -26.04476755602706
              ],
              [
                -50.0027456895483,
                -26.038407557241083
              ],
              [
                -50.01018017779025,
                -26.02663310482366
              ],
              [
                -50.011060662395096,
                -26.02523910994447
              ],
              [
                -50.01105620951882,
                -26.013508671475382
              ],
              [
                -49.99803033016553,
                -26.01535460304025
              ],
              [
                -49.99306289759564,
                -26.01113162811243
              ],
              [
                -49.990552919261795,
                -26.011769110348943
              ],
              [
                -49.97150304039001,
                -26.016604039671233
              ],
              [
                -49.96407357191031,
                -26.02478900139973
              ],
              [
                -49.96584246771844,
                -26.040131921307253
              ],
              [
                -49.97710135658295,
                -26.05283542183091
              ],
              [
                -49.97220589492331,
                -26.057765378140758
              ],
              [
                -49.94751618045962,
                -26.03048040286565
              ],
              [
                -49.95903461801881,
                -26.020303995222086
              ],
              [
                -49.952696668468505,
                -26.019031960761243
              ],
              [
                -49.951780192520914,
                -26.018142478005572
              ],
              [
                -49.941727306757905,
                -26.008382487720898
              ],
              [
                -49.93841979843193,
                -26.012309452052477
              ],
              [
                -49.933920847953445,
                -26.01765043313954
              ],
              [
                -49.938312762308335,
                -26.022809916621046
              ],
              [
                -49.94019078408349,
                -26.025016412691166
              ],
              [
                -49.934832759166156,
                -26.031669363029806
              ],
              [
                -49.91836594024883,
                -26.025840333670548
              ],
              [
                -49.91482944598781,
                -26.024588321512322
              ],
              [
                -49.903661559898325,
                -26.0264102704095
              ],
              [
                -49.90216506226397,
                -26.028302292363303
              ],
              [
                -49.898106059098076,
                -26.033435730683664
              ],
              [
                -49.895076085910226,
                -26.031411755095345
              ],
              [
                -49.89129965880696,
                -26.028888766874474
              ],
              [
                -49.865029337931325,
                -26.034650631645235
              ],
              [
                -49.86989024377232,
                -26.042660649036048
              ],
              [
                -49.87027676703359,
                -26.043297639954474
              ],
              [
                -49.864186802816796,
                -26.046116100104218
              ],
              [
                -49.86176529904917,
                -26.054754538831574
              ],
              [
                -49.8421019180373,
                -26.063867470160506
              ],
              [
                -49.83954143373908,
                -26.059485949929385
              ],
              [
                -49.83516552187368,
                -26.051997001223434
              ],
              [
                -49.83208654193563,
                -26.052703493303746
              ],
              [
                -49.833170965121326,
                -26.064917440398858
              ],
              [
                -49.83332800062502,
                -26.06668042935398
              ],
              [
                -49.84312939050899,
                -26.0724564256016
              ],
              [
                -49.83971836803284,
                -26.075901414366506
              ],
              [
                -49.830369982316284,
                -26.0691909041125
              ],
              [
                -49.83017300049286,
                -26.06904942298135
              ],
              [
                -49.83017601267779,
                -26.06948640636306
              ],
              [
                -49.83026545654327,
                -26.082759348216776
              ],
              [
                -49.824193467896855,
                -26.09248231570206
              ],
              [
                -49.81560350734154,
                -26.093510284143626
              ],
              [
                -49.81405252370558,
                -26.092343751301517
              ],
              [
                -49.80793861115834,
                -26.08774377899498
              ],
              [
                -49.80607759006181,
                -26.097056242077468
              ],
              [
                -49.8040500548775,
                -26.10720269450632
              ],
              [
                -49.79920007984246,
                -26.10924563767349
              ],
              [
                -49.792476632411585,
                -26.11265361048894
              ],
              [
                -49.787662692594246,
                -26.110676120751123
              ],
              [
                -49.78754717449026,
                -26.108005618313364
              ],
              [
                -49.7872167154464,
                -26.100382163614565
              ],
              [
                -49.78176274401236,
                -26.111277116778027
              ],
              [
                -49.77794724770489,
                -26.11889804862381
              ],
              [
                -49.77330875094164,
                -26.118269064450462
              ],
              [
                -49.77215979551115,
                -26.1165045491818
              ],
              [
                -49.76785784453926,
                -26.109899077412962
              ],
              [
                -49.77827525315052,
                -26.106931097309392
              ],
              [
                -49.77561279265431,
                -26.1013496302118
              ],
              [
                -49.767551393008716,
                -26.10429607827673
              ],
              [
                -49.7641618603907,
                -26.113349554192244
              ],
              [
                -49.75064943339408,
                -26.12010648681869
              ],
              [
                -49.74950390686454,
                -26.12883743044232
              ],
              [
                -49.748109394536925,
                -26.139468895161176
              ],
              [
                -49.739807468801175,
                -26.138051855664802
              ],
              [
                -49.73535799149758,
                -26.141815365753097
              ],
              [
                -49.73503498594773,
                -26.14185783451419
              ],
              [
                -49.72440907258094,
                -26.1432497967776
              ],
              [
                -49.72917653124412,
                -26.148506797134356
              ],
              [
                -49.73651693833278,
                -26.156600811547648
              ],
              [
                -49.72726344181633,
                -26.16991120299974
              ],
              [
                -49.71798654701177,
                -26.161207214362566
              ],
              [
                -49.7101901384504,
                -26.15389220253552
              ],
              [
                -49.698490239248954,
                -26.161032659770207
              ],
              [
                -49.68833073554312,
                -26.176200566230325
              ],
              [
                -49.6762703525555,
                -26.17549654949235
              ],
              [
                -49.68290976751499,
                -26.181826053118357
              ],
              [
                -49.68934570191802,
                -26.187962011802448
              ],
              [
                -49.68012626640422,
                -26.185657990519296
              ],
              [
                -49.67379631027223,
                -26.190919482719377
              ],
              [
                -49.672116821718525,
                -26.186353493114996
              ],
              [
                -49.67161133247583,
                -26.184980994539217
              ],
              [
                -49.667352367094956,
                -26.188152464460636
              ],
              [
                -49.66334191545648,
                -26.19113844290483
              ],
              [
                -49.66251592594802,
                -26.189978937462623
              ],
              [
                -49.66004746628161,
                -26.18651542911461
              ],
              [
                -49.65271149519998,
                -26.18670941584126
              ],
              [
                -49.65324547181664,
                -26.192555397826624
              ],
              [
                -49.64822000434839,
                -26.19532339668433
              ],
              [
                -49.6482220061163,
                -26.195375865495397
              ],
              [
                -49.64854794537462,
                -26.20779430828937
              ],
              [
                -49.64520099625444,
                -26.209100327214088
              ],
              [
                -49.63679458380831,
                -26.20348731899646
              ],
              [
                -49.63357959082119,
                -26.201340811240797
              ],
              [
                -49.6363856084474,
                -26.190523347985735
              ],
              [
                -49.629911662309866,
                -26.190489342452985
              ],
              [
                -49.62549718359015,
                -26.200869802948397
              ],
              [
                -49.63323807053315,
                -26.20694925918096
              ],
              [
                -49.634747591362846,
                -26.208134298815512
              ],
              [
                -49.628924104394336,
                -26.211148747078933
              ],
              [
                -49.62109717848843,
                -26.215199719768755
              ],
              [
                -49.616741680494734,
                -26.213615216097597
              ],
              [
                -49.614266748098615,
                -26.20694474369948
              ],
              [
                -49.61163326306337,
                -26.213385700854356
              ],
              [
                -49.605656304164796,
                -26.211344694465758
              ],
              [
                -49.601766772684186,
                -26.227186102345804
              ],
              [
                -49.59822884652476,
                -26.220867611072627
              ],
              [
                -49.59214385786713,
                -26.22287508221428
              ],
              [
                -49.5871049362866,
                -26.216958112434643
              ],
              [
                -49.59034384061164,
                -26.229662069865313
              ],
              [
                -49.57970846363653,
                -26.227643031798205
              ],
              [
                -49.57742551138803,
                -26.21350860993022
              ],
              [
                -49.56676752100207,
                -26.232788473279037
              ],
              [
                -49.56068106932901,
                -26.234810454360055
              ],
              [
                -49.5545951306621,
                -26.236831451446207
              ],
              [
                -49.54886167264314,
                -26.23070542114016
              ],
              [
                -49.54748418693648,
                -26.229233426030696
              ],
              [
                -49.551395193163046,
                -26.224580482497263
              ],
              [
                -49.54518574388759,
                -26.224564932785555
              ],
              [
                -49.540481246993046,
                -26.227794898980093
              ],
              [
                -49.537852797021415,
                -26.22959942944492
              ],
              [
                -49.536146788714774,
                -26.226493415347047
              ],
              [
                -49.53638232129228,
                -26.226371897588997
              ],
              [
                -49.54236376925547,
                -26.223265453203673
              ],
              [
                -49.53968082220718,
                -26.21327497258002
              ],
              [
                -49.53571484973533,
                -26.214450970924258
              ],
              [
                -49.529091368202096,
                -26.223839914672823
              ],
              [
                -49.5289293605811,
                -26.22406938927316
              ],
              [
                -49.528918891856456,
                -26.22377989932053
              ],
              [
                -49.52866942664036,
                -26.217027924734932
              ],
              [
                -49.524347406190756,
                -26.22158441424574
              ],
              [
                -49.519461950904216,
                -26.226733863654836
              ],
              [
                -49.51136504384735,
                -26.220931361291715
              ],
              [
                -49.503882576720436,
                -26.226095297961407
              ],
              [
                -49.50263760280151,
                -26.221491333028077
              ],
              [
                -49.49607216619918,
                -26.22081280662802
              ],
              [
                -49.497920641785576,
                -26.218212325538833
              ],
              [
                -49.4904317574155,
                -26.213712812366992
              ],
              [
                -49.49059773403847,
                -26.209576829411652
              ],
              [
                -49.49444520757218,
                -26.211050336743696
              ],
              [
                -49.497163217271755,
                -26.20669137914642
              ],
              [
                -49.499471729619174,
                -26.202988882723286
              ],
              [
                -49.49326173030247,
                -26.203237373054833
              ],
              [
                -49.490182770320175,
                -26.199123874186423
              ],
              [
                -49.486431859501344,
                -26.194112882445143
              ],
              [
                -49.489405855945,
                -26.19076541491909
              ],
              [
                -49.485401897579514,
                -26.184437440282494
              ],
              [
                -49.48642788714513,
                -26.180059449123842
              ],
              [
                -49.478996457924254,
                -26.185403400288603
              ],
              [
                -49.46407759715104,
                -26.172859375601334
              ],
              [
                -49.45867070980621,
                -26.16223391748055
              ],
              [
                -49.43933433884208,
                -26.163680854615624
              ],
              [
                -49.43699385499857,
                -26.16953835380423
              ],
              [
                -49.43607984509772,
                -26.17182780615544
              ],
              [
                -49.43280586964041,
                -26.170168806480646
              ],
              [
                -49.42804791938732,
                -26.16775779869124
              ],
              [
                -49.42122650361449,
                -26.16617430092216
              ],
              [
                -49.41992850807822,
                -26.163520295579435
              ],
              [
                -49.41675850506192,
                -26.164969304572605
              ],
              [
                -49.416156079089866,
                -26.15271434266838
              ],
              [
                -49.40626711246901,
                -26.161142782032535
              ],
              [
                -49.39956273271424,
                -26.150269797642274
              ],
              [
                -49.395252257879605,
                -26.151267290542812
              ],
              [
                -49.393022762572784,
                -26.15360125333547
              ],
              [
                -49.38978327219766,
                -26.156994227779325
              ],
              [
                -49.386979811381934,
                -26.155462238218988
              ],
              [
                -49.37242691402734,
                -26.153105695678075
              ],
              [
                -49.36979944790462,
                -26.15545968806817
              ],
              [
                -49.37127597067705,
                -26.1483952306005
              ],
              [
                -49.36822746341566,
                -26.14893318143433
              ],
              [
                -49.36728099895689,
                -26.144083227568604
              ],
              [
                -49.36650001699409,
                -26.143679204494948
              ],
              [
                -49.36288755417135,
                -26.141810204531062
              ],
              [
                -49.359803601909434,
                -26.140214698295456
              ],
              [
                -49.35967261123364,
                -26.137211722185953
              ],
              [
                -49.35403864670603,
                -26.134666221063636
              ],
              [
                -49.35043519721279,
                -26.133433706739524
              ],
              [
                -49.34680324767888,
                -26.127608739656583
              ],
              [
                -49.3416907721335,
                -26.128681711622875
              ],
              [
                -49.33379086626338,
                -26.124391177585363
              ],
              [
                -49.33425235636758,
                -26.119757719618793
              ],
              [
                -49.31755103864399,
                -26.109297182714574
              ],
              [
                -49.30427015039948,
                -26.10953116676605
              ],
              [
                -49.30289664039744,
                -26.11303115597133
              ],
              [
                -49.297595731757696,
                -26.10935513329115
              ],
              [
                -49.30025269842172,
                -26.103846171947154
              ],
              [
                -49.289294317822,
                -26.10220362882374
              ],
              [
                -49.2936747792642,
                -26.096964674179272
              ],
              [
                -49.293218310028756,
                -26.096220184484345
              ],
              [
                -49.29127731178052,
                -26.093057180025312
              ],
              [
                -49.29487031331769,
                -26.091593176478632
              ],
              [
                -49.29403584585196,
                -26.084531719453445
              ],
              [
                -49.28653443597995,
                -26.07820173757866
              ],
              [
                -49.28230843857493,
                -26.078448717643422
              ],
              [
                -49.27792195573968,
                -26.081587681216845
              ],
              [
                -49.27661497264337,
                -26.08252267834383
              ],
              [
                -49.273640015452195,
                -26.08142765863918
              ],
              [
                -49.27448702421078,
                -26.078302176291718
              ],
              [
                -49.27464053625647,
                -26.077735175949464
              ],
              [
                -49.27395603962175,
                -26.077794189458714
              ],
              [
                -49.26548359203307,
                -26.078525674626373
              ],
              [
                -49.26848309806674,
                -26.07591567945564
              ],
              [
                -49.26456263699679,
                -26.071525164124544
              ],
              [
                -49.26533213073381,
                -26.069544698183844
              ],
              [
                -49.26645361525261,
                -26.066661207834013
              ],
              [
                -49.26267166252326,
                -26.066833703660706
              ],
              [
                -49.2555407196042,
                -26.067159174823153
              ],
              [
                -49.256847221079525,
                -26.063665208405727
              ],
              [
                -49.25306677909247,
                -26.061680674427503
              ],
              [
                -49.254254751725846,
                -26.057646211672505
              ],
              [
                -49.25483425096064,
                -26.055678196060768
              ],
              [
                -49.252738308480104,
                -26.04967121093557
              ],
              [
                -49.242183402463034,
                -26.043616714030545
              ],
              [
                -49.23648197845026,
                -26.036880207785636
              ],
              [
                -49.23752348632563,
                -26.030852741142205
              ],
              [
                -49.23406503606363,
                -26.030257259978733
              ],
              [
                -49.229278085767206,
                -26.030382722685363
              ],
              [
                -49.22493462189155,
                -26.030495704905828
              ],
              [
                -49.21711271409329,
                -26.023546716507937
              ],
              [
                -49.21069874370946,
                -26.025014178742087
              ],
              [
                -49.20710380825945,
                -26.017573713197788
              ],
              [
                -49.20707531773147,
                -26.017514719328403
              ],
              [
                -49.20681730502965,
                -26.017469215649907
              ],
              [
                -49.19517988175509,
                -26.01540169411306
              ],
              [
                -49.19128793285236,
                -26.01287820688383
              ],
              [
                -49.191334954813556,
                -26.01180268513702
              ],
              [
                -49.191489965853904,
                -26.008261187143827
              ],
              [
                -49.182215551049715,
                -26.008464199774654
              ],
              [
                -49.18070752527587,
                -26.00849769267257
              ],
              [
                -49.18008508042026,
                -26.00774465249019
              ],
              [
                -49.17479511161803,
                -26.00134820043458
              ],
              [
                -49.16669020982717,
                -26.000663641322888
              ],
              [
                -49.16164120189582,
                -26.007184603799992
              ],
              [
                -49.160260223097275,
                -26.00746259718603
              ],
              [
                -49.150756781307095,
                -26.00937607455183
              ],
              [
                -49.147500797948574,
                -26.013354064638865
              ],
              [
                -49.14632284751851,
                -26.0094145767869
              ],
              [
                -49.142390844849444,
                -26.010633546172542
              ],
              [
                -49.14076936027257,
                -26.01506101393124
              ],
              [
                -49.13207243225084,
                -26.006731029058418
              ],
              [
                -49.12912799979035,
                -26.003910548125408
              ],
              [
                -49.13120749530935,
                -25.99980153966972
              ],
              [
                -49.125071567018615,
                -25.99574355695698
              ],
              [
                -49.112409626503485,
                -26.004770992756978
              ],
              [
                -49.106570182276194,
                -25.998610493065737
              ],
              [
                -49.10183571182473,
                -26.00488644548568
              ],
              [
                -49.099180741425556,
                -26.008404938293694
              ],
              [
                -49.093843261157055,
                -26.009417909841325
              ],
              [
                -49.0914507845624,
                -26.00476592474495
              ],
              [
                -49.09044682867434,
                -26.002814441900195
              ],
              [
                -49.081626883390335,
                -26.004724379555746
              ],
              [
                -49.076445420413286,
                -26.00584687020316
              ],
              [
                -49.075433443354434,
                -26.004698371264382
              ],
              [
                -49.07059999415627,
                -25.999213888336392
              ],
              [
                -49.06905699538046,
                -26.005527325101955
              ],
              [
                -49.06821047238129,
                -26.008987833077104
              ],
              [
                -49.0547380484518,
                -26.02004826441049
              ],
              [
                -49.051332073465666,
                -26.01681775355504
              ],
              [
                -49.046138607803286,
                -26.018637206495693
              ],
              [
                -49.04413965461909,
                -26.01333224721721
              ],
              [
                -49.04044772445051,
                -26.003532773584226
              ],
              [
                -49.02918130937294,
                -26.00344972083914
              ],
              [
                -49.0219758499277,
                -26.013048180369488
              ],
              [
                -49.02006285338379,
                -26.015596646314794
              ],
              [
                -49.006638463427244,
                -26.01518262373626
              ],
              [
                -49.00307299764402,
                -26.01098813508255
              ],
              [
                -49.002702027452806,
                -26.01055162253552
              ],
              [
                -49.001196527898514,
                -26.0107836256952
              ],
              [
                -48.997662557771726,
                -26.011327124990203
              ],
              [
                -48.9962835524605,
                -26.0102476135936
              ],
              [
                -48.98841565795833,
                -26.00409111633279
              ],
              [
                -48.98467917483823,
                -26.00405459319094
              ],
              [
                -48.98371420281171,
                -26.004045592053707
              ],
              [
                -48.98399617140427,
                -26.003570608802534
              ],
              [
                -48.985976712544776,
                -26.000233616112848
              ],
              [
                -48.97990472703917,
                -25.997363116172522
              ],
              [
                -48.97801777995648,
                -25.98881214679279
              ],
              [
                -48.96770340049814,
                -25.98640062117646
              ],
              [
                -48.961517963210625,
                -25.983707624210034
              ],
              [
                -48.96139992077036,
                -25.987541586095162
              ],
              [
                -48.96131143385935,
                -25.990436582685557
              ],
              [
                -48.961091426199054,
                -25.99055358451372
              ],
              [
                -48.95649694317072,
                -25.99299905803315
              ],
              [
                -48.95022653216764,
                -25.989666575244627
              ],
              [
                -48.95344902405298,
                -25.986332075635865
              ],
              [
                -48.96027699626379,
                -25.9792676191943
              ],
              [
                -48.88442808773755,
                -25.980753374652387
              ],
              [
                -48.71045255000056,
                -25.982795358233563
              ],
              [
                -48.70613858992267,
                -25.982477334363853
              ],
              [
                -48.680178806171284,
                -25.980560290961048
              ],
              [
                -48.67565883138874,
                -25.977097264617306
              ],
              [
                -48.67209635426038,
                -25.977993777506946
              ],
              [
                -48.66900187573413,
                -25.97877275438077
              ],
              [
                -48.66468995654134,
                -25.97523276702405
              ],
              [
                -48.66270395548328,
                -25.973601739864105
              ],
              [
                -48.6617824791097,
                -25.968094269216216
              ],
              [
                -48.653396585969325,
                -25.962208298683557
              ],
              [
                -48.6429206882811,
                -25.955778786464652
              ],
              [
                -48.63845722494652,
                -25.963608742503478
              ],
              [
                -48.63771471739369,
                -25.96703871376551
              ],
              [
                -48.632521214154046,
                -25.969845182925397
              ],
              [
                -48.63255271715627,
                -25.97028018833951
              ],
              [
                -48.633035686488896,
                -25.976957638997376
              ],
              [
                -48.62337030364882,
                -25.976912622629897
              ],
              [
                -48.62272577036133,
                -25.97631710883859
              ],
              [
                -48.620254316956014,
                -25.97402813866162
              ],
              [
                -48.62020682002877,
                -25.977864127613216
              ],
              [
                -48.620161313671325,
                -25.981552604146014
              ],
              [
                -48.61522881401499,
                -25.980922087497355
              ],
              [
                -48.61322137065359,
                -25.980666072940913
              ],
              [
                -48.6097508692559,
                -25.98370154883074
              ],
              [
                -48.61054187943717,
                -25.979012561618713
              ],
              [
                -48.60119344696407,
                -25.9802300713914
              ],
              [
                -48.599356956121916,
                -25.976735551975267
              ],
              [
                -48.597390004624216,
                -25.976681065771402
              ],
              [
                -48.59394752250978,
                -25.97658504491669
              ],
              [
                -48.59523600311568,
                -25.981275040898286
              ],
              [
                -48.59948791878697,
                -25.998826478618906
              ],
              [
                -48.602867794814586,
                -26.02582087193769
              ],
              [
                -48.606633615122895,
                -26.05589725071579
              ],
              [
                -48.60603909326868,
                -26.06631374444426
              ],
              [
                -48.60308212118028,
                -26.06895221755471
              ],
              [
                -48.60179214224767,
                -26.07010371487601
              ],
              [
                -48.599169657797525,
                -26.07239119443932
              ],
              [
                -48.596588476987534,
                -26.124206484625823
              ],
              [
                -48.58512043970898,
                -26.153108834402513
              ],
              [
                -48.58049445010297,
                -26.164765266233236
              ],
              [
                -48.56760355423473,
                -26.170176219037604
              ],
              [
                -48.55653511870373,
                -26.17002166530665
              ],
              [
                -48.54222426928739,
                -26.169821640120386
              ],
              [
                -48.53713379783266,
                -26.16975011469605
              ],
              [
                -48.535897305392425,
                -26.168684096757982
              ],
              [
                -48.5298528775664,
                -26.16347212807235
              ],
              [
                -48.52208042790131,
                -26.169480594360017
              ],
              [
                -48.52304486501989,
                -26.1768955358703
              ],
              [
                -48.52592827365852,
                -26.19907195069269
              ],
              [
                -48.51562128689182,
                -26.22245434661207
              ],
              [
                -48.512531309902315,
                -26.223361346961156
              ],
              [
                -48.506194875068616,
                -26.224967303947963
              ],
              [
                -48.50209537693203,
                -26.225760797418747
              ],
              [
                -48.50048390223978,
                -26.219837819983816
              ],
              [
                -48.495940949338525,
                -26.216779799869624
              ],
              [
                -48.49381346036647,
                -26.217932800432116
              ],
              [
                -48.49242801785013,
                -26.21868379698323
              ],
              [
                -48.49271348327463,
                -26.219477309857012
              ],
              [
                -48.49594094115621,
                -26.228466272760965
              ],
              [
                -48.49779488362556,
                -26.233447775630605
              ],
              [
                -48.50065038194289,
                -26.23541527142345
              ],
              [
                -48.50529729938016,
                -26.238617753343547
              ],
              [
                -48.5232185415119,
                -26.268758675151084
              ],
              [
                -48.53180893652424,
                -26.283223673769687
              ],
              [
                -48.54034578593618,
                -26.300398616861504
              ],
              [
                -48.55007513952308,
                -26.31999658866703
              ],
              [
                -48.55299207941393,
                -26.325871562740492
              ],
              [
                -48.55595101559712,
                -26.334784535307588
              ],
              [
                -48.55671453290249,
                -26.33708403000747
              ],
              [
                -48.56454483687312,
                -26.36066596347046
              ],
              [
                -48.566442823161516,
                -26.363842968572012
              ],
              [
                -48.56647432767985,
                -26.36389695546935
              ],
              [
                -48.56834030144564,
                -26.36702145166943
              ],
              [
                -48.58152111694589,
                -26.389089920794618
              ],
              [
                -48.58916550218571,
                -26.401889389155684
              ],
              [
                -48.591795455352596,
                -26.406292369036695
              ],
              [
                -48.59190847767166,
                -26.40671887178127
              ],
              [
                -48.59275994444542,
                -26.409938857298133
              ],
              [
                -48.59544790357559,
                -26.42010433637422
              ],
              [
                -48.59729036278753,
                -26.427070815881283
              ],
              [
                -48.59815532822667,
                -26.430341296264363
              ],
              [
                -48.597163801275165,
                -26.437968245871414
              ],
              [
                -48.59620478508756,
                -26.445339709567982
              ],
              [
                -48.595604274175855,
                -26.44995872095777
              ],
              [
                -48.59314676430205,
                -26.453453208479214
              ],
              [
                -48.60721561320762,
                -26.47033216782308
              ],
              [
                -48.60725058074485,
                -26.47037416377917
              ],
              [
                -48.610720034842416,
                -26.474536663441768
              ],
              [
                -48.613825026759514,
                -26.478261656566502
              ],
              [
                -48.61899743613269,
                -26.487192627748797
              ],
              [
                -48.623453366517104,
                -26.49488712227767
              ],
              [
                -48.63208523855956,
                -26.509791572300088
              ],
              [
                -48.64634850901483,
                -26.538726498873032
              ],
              [
                -48.65055643280748,
                -26.547283983420346
              ],
              [
                -48.65291489877632,
                -26.55345748686465
              ],
              [
                -48.657391307269855,
                -26.565177433356038
              ],
              [
                -48.659316790925786,
                -26.5701104250837
              ],
              [
                -48.66247174715516,
                -26.575334438815307
              ],
              [
                -48.66426269515737,
                -26.579724402163695
              ],
              [
                -48.66644218225424,
                -26.585972915777653
              ],
              [
                -48.668999112736174,
                -26.59330339256637
              ],
              [
                -48.67124308551187,
                -26.599736847716308
              ],
              [
                -48.67532052331628,
                -26.611425342129515
              ],
              [
                -48.675941464791876,
                -26.6132063354707
              ],
              [
                -48.677757947272326,
                -26.622738805512572
              ],
              [
                -48.67863543394178,
                -26.627340270059854
              ],
              [
                -48.679061915133985,
                -26.629578792029097
              ],
              [
                -48.6797139035606,
                -26.632998776290872
              ],
              [
                -48.680866843925784,
                -26.639050246293454
              ],
              [
                -48.68145582323296,
                -26.642140722594824
              ],
              [
                -48.686570672500984,
                -26.66897612446064
              ],
              [
                -48.68650118530569,
                -26.675190128800846
              ],
              [
                -48.685481645736644,
                -26.68797405920812
              ],
              [
                -48.68295165803708,
                -26.691525530353353
              ],
              [
                -48.67841864731709,
                -26.697890487189756
              ],
              [
                -48.67888612742019,
                -26.70174498223684
              ],
              [
                -48.68046659290916,
                -26.715550436551403
              ],
              [
                -48.68084154815254,
                -26.71883093746082
              ],
              [
                -48.679778519497994,
                -26.72640638138888
              ],
              [
                -48.679153008600245,
                -26.730277873725043
              ],
              [
                -48.67865900098359,
                -26.733337362558593
              ],
              [
                -48.677449490926335,
                -26.74081683136915
              ],
              [
                -48.67590594601583,
                -26.750367286279058
              ],
              [
                -48.67088547767898,
                -26.761922731992193
              ],
              [
                -48.66118800677602,
                -26.769997171089955
              ],
              [
                -48.65833002132643,
                -26.770296684369136
              ],
              [
                -48.651890592141875,
                -26.77097116621607
              ],
              [
                -48.65122661606096,
                -26.76967764084991
              ],
              [
                -48.64692217781536,
                -26.761297164916666
              ],
              [
                -48.64013471017761,
                -26.765180117536058
              ],
              [
                -48.63231973598214,
                -26.77965507087094
              ],
              [
                -48.62603525268762,
                -26.784132513938953
              ],
              [
                -48.61975180738978,
                -26.78860997500928
              ],
              [
                -48.60568039721936,
                -26.78847995426511
              ],
              [
                -48.60468093292,
                -26.785991433272844
              ],
              [
                -48.60331446649186,
                -26.782587467495457
              ],
              [
                -48.60141998994715,
                -26.77786645990668
              ],
              [
                -48.598848049056656,
                -26.771460970393434
              ],
              [
                -48.595082524467415,
                -26.782743928286802
              ],
              [
                -48.58495613574415,
                -26.78302189726952
              ],
              [
                -48.58739854303788,
                -26.790065883947214
              ],
              [
                -48.58400858291142,
                -26.794721367050894
              ],
              [
                -48.59098852822546,
                -26.7962758496262
              ],
              [
                -48.594950451818946,
                -26.801402335640695
              ],
              [
                -48.59779091999907,
                -26.810546827792397
              ],
              [
                -48.59856587860607,
                -26.813406808643972
              ],
              [
                -48.60016833455564,
                -26.81932331679041
              ],
              [
                -48.600919842713026,
                -26.822096803893707
              ],
              [
                -48.602135291848064,
                -26.826583279122538
              ],
              [
                -48.609865748223456,
                -26.826382311856843
              ],
              [
                -48.620521171752905,
                -26.825814830802344
              ],
              [
                -48.62770257586235,
                -26.83515584788732
              ],
              [
                -48.62999098907876,
                -26.842980801228826
              ],
              [
                -48.63271042853787,
                -26.852275773523083
              ],
              [
                -48.63623635070612,
                -26.864329227215453
              ],
              [
                -48.637583856189224,
                -26.86893523149655
              ],
              [
                -48.638587824717185,
                -26.875168229532196
              ],
              [
                -48.639055278649536,
                -26.87807372122661
              ],
              [
                -48.64031623633512,
                -26.88590218216676
              ],
              [
                -48.641346725910495,
                -26.89230365068143
              ],
              [
                -48.64270469510023,
                -26.90148164892173
              ],
              [
                -48.640018164322804,
                -26.912727567805117
              ],
              [
                -48.64014315155091,
                -26.915284070668168
              ],
              [
                -48.64035311278547,
                -26.919575538333305
              ],
              [
                -48.634730141677416,
                -26.924580020657206
              ],
              [
                -48.63057415781587,
                -26.92827900780946
              ],
              [
                -48.6211862515124,
                -26.925332979154145
              ],
              [
                -48.62674966106825,
                -26.936530452065416
              ],
              [
                -48.62675913031807,
                -26.94930239438052
              ],
              [
                -48.626756608981225,
                -26.95580938107091
              ],
              [
                -48.626672610620304,
                -26.95939087641448
              ],
              [
                -48.623400106183155,
                -26.960757859258642
              ],
              [
                -48.62667658221723,
                -26.964708865574845
              ],
              [
                -48.62557005828163,
                -26.971166322735233
              ],
              [
                -48.62926852282804,
                -26.97154282082608
              ],
              [
                -48.63288646608179,
                -26.971911334675795
              ],
              [
                -48.63350194491598,
                -26.977619312462057
              ],
              [
                -48.63099445964099,
                -26.98265478020555
              ],
              [
                -48.62877748700757,
                -26.986249287305984
              ],
              [
                -48.627975992220435,
                -26.987549743746804
              ],
              [
                -48.62576497558037,
                -26.991133717907303
              ],
              [
                -48.62075350017957,
                -26.999260201662494
              ],
              [
                -48.61559953430314,
                -27.001612168465616
              ],
              [
                -48.610427561811214,
                -27.0039096304106
              ],
              [
                -48.60036316798641,
                -27.002220608681952
              ],
              [
                -48.60192117015446,
                -26.99505615667605
              ],
              [
                -48.59677018837014,
                -26.9928731524137
              ],
              [
                -48.58890227392195,
                -26.99671308549443
              ],
              [
                -48.58692578960831,
                -26.99144662947473
              ],
              [
                -48.580214345229315,
                -26.99369107642032
              ],
              [
                -48.582042297038825,
                -26.998184082822707
              ],
              [
                -48.57916829599578,
                -27.003896066790034
              ],
              [
                -48.57643430347775,
                -27.008967518622537
              ],
              [
                -48.566900884606945,
                -27.00743499212363
              ],
              [
                -48.570257377053686,
                -27.012776009531375
              ],
              [
                -48.573236337803415,
                -27.01736847446465
              ],
              [
                -48.57088581226237,
                -27.023580935340902
              ],
              [
                -48.57861323629504,
                -27.025094447827275
              ],
              [
                -48.57800018335723,
                -27.039095910553634
              ],
              [
                -48.58076617214117,
                -27.041251412879838
              ],
              [
                -48.585393130247255,
                -27.04485739010312
              ],
              [
                -48.58560608170551,
                -27.046695915974865
              ],
              [
                -48.58606607611877,
                -27.050660908763206
              ],
              [
                -48.586249570889436,
                -27.052246388479567
              ],
              [
                -48.58629056504959,
                -27.052600893298134
              ],
              [
                -48.586568553631714,
                -27.05499086333918
              ],
              [
                -48.5873735394864,
                -27.060533876426817
              ],
              [
                -48.591718509760774,
                -27.061340373139895
              ],
              [
                -48.592500451782385,
                -27.065940854666415
              ],
              [
                -48.5935239446662,
                -27.07222383575565
              ],
              [
                -48.59266842096718,
                -27.075026335431
              ],
              [
                -48.59105394299014,
                -27.079630802832337
              ],
              [
                -48.58678347106519,
                -27.080251773050392
              ],
              [
                -48.59437336028771,
                -27.085529269147624
              ],
              [
                -48.595561856357776,
                -27.089545257245874
              ],
              [
                -48.59943932067835,
                -27.09220677050002
              ],
              [
                -48.60255527604985,
                -27.09541478114523
              ],
              [
                -48.603771775614085,
                -27.09193479820237
              ],
              [
                -48.60780172809517,
                -27.092604299381357
              ],
              [
                -48.60793874590415,
                -27.092851311928293
              ],
              [
                -48.610223681326765,
                -27.096979277342484
              ],
              [
                -48.61179367219112,
                -27.09981576813261
              ],
              [
                -48.60884118294511,
                -27.107587748252747
              ],
              [
                -48.60706115864621,
                -27.112113720407404
              ],
              [
                -48.605933182925114,
                -27.114980693935347
              ],
              [
                -48.60527567833278,
                -27.116653217494807
              ],
              [
                -48.60214466576591,
                -27.12461268532241
              ],
              [
                -48.59660768335596,
                -27.130911108149117
              ],
              [
                -48.596412660047434,
                -27.131132610664917
              ],
              [
                -48.584044728816835,
                -27.145199521468765
              ],
              [
                -48.566024866242635,
                -27.155414429366353
              ],
              [
                -48.56230289920301,
                -27.154045422807123
              ],
              [
                -48.55855093346187,
                -27.152665427189138
              ],
              [
                -48.554254942922576,
                -27.157731381796495
              ],
              [
                -48.54882096924039,
                -27.1570648678193
              ],
              [
                -48.54239855153047,
                -27.153170871475027
              ],
              [
                -48.53900408267809,
                -27.151113387595224
              ],
              [
                -48.53607962868362,
                -27.1493403742566
              ],
              [
                -48.53307762794877,
                -27.147423369192005
              ],
              [
                -48.53468514121669,
                -27.14560086441911
              ],
              [
                -48.5363446433015,
                -27.143718897043545
              ],
              [
                -48.53722914714643,
                -27.14271638377023
              ],
              [
                -48.52542928272757,
                -27.13026040805393
              ],
              [
                -48.526085765249135,
                -27.126242427225407
              ],
              [
                -48.529593251746945,
                -27.12839941909521
              ],
              [
                -48.527339808552206,
                -27.12345541484492
              ],
              [
                -48.52104538689323,
                -27.118343918528048
              ],
              [
                -48.51467242517931,
                -27.121669403551532
              ],
              [
                -48.51070496966037,
                -27.11617441556194
              ],
              [
                -48.50691901917336,
                -27.111019935929594
              ],
              [
                -48.50427849243089,
                -27.115476870385383
              ],
              [
                -48.51102542133019,
                -27.129555882839668
              ],
              [
                -48.51206889737365,
                -27.13173236127861
              ],
              [
                -48.501470932163315,
                -27.1454192874244
              ],
              [
                -48.49209150538569,
                -27.147156731154627
              ],
              [
                -48.483980543987684,
                -27.148659207062074
              ],
              [
                -48.47998010170301,
                -27.145073720844415
              ],
              [
                -48.479107636728145,
                -27.138293719128793
              ],
              [
                -48.47865766529414,
                -27.134799226616686
              ],
              [
                -48.476701168444436,
                -27.141234224024146
              ],
              [
                -48.47510714359711,
                -27.146477682099583
              ],
              [
                -48.46515221465619,
                -27.144679662053544
              ],
              [
                -48.47585759962545,
                -27.155135668418623
              ],
              [
                -48.482649561708726,
                -27.154897193337494
              ],
              [
                -48.48348350426344,
                -27.158543666599922
              ],
              [
                -48.48421752570426,
                -27.16170617332616
              ],
              [
                -48.48827494181254,
                -27.16333018805027
              ],
              [
                -48.49550338909912,
                -27.16619167493634
              ],
              [
                -48.49650340203928,
                -27.166587684857642
              ],
              [
                -48.49999127163191,
                -27.18359563450002
              ],
              [
                -48.49983928992703,
                -27.18406164825309
              ],
              [
                -48.4950232625949,
                -27.198823541080582
              ],
              [
                -48.49096479570088,
                -27.202334046261136
              ],
              [
                -48.488335823562295,
                -27.200964004417127
              ],
              [
                -48.48413984501515,
                -27.198781002271485
              ],
              [
                -48.47650441191209,
                -27.20092899036225
              ],
              [
                -48.48027188458502,
                -27.20745695991651
              ],
              [
                -48.48482629696347,
                -27.20897050677021
              ],
              [
                -48.49336171655498,
                -27.211757016431086
              ],
              [
                -48.497595700859975,
                -27.216977501452966
              ],
              [
                -48.50316512371727,
                -27.21496803583856
              ],
              [
                -48.50702260504725,
                -27.216457530427913
              ],
              [
                -48.51072258368757,
                -27.217949515475024
              ],
              [
                -48.51418301474883,
                -27.219344536069418
              ],
              [
                -48.51698500945885,
                -27.22047404941091
              ],
              [
                -48.51742099480058,
                -27.216479550701024
              ],
              [
                -48.50934061326986,
                -27.21113156406043
              ],
              [
                -48.5060806517697,
                -27.208973550984556
              ],
              [
                -48.50259115283484,
                -27.206664560691806
              ],
              [
                -48.499878715799795,
                -27.200258057250387
              ],
              [
                -48.5029687309185,
                -27.19695906899339
              ],
              [
                -48.51448917362348,
                -27.184660174782024
              ],
              [
                -48.52968755836282,
                -27.18087821944502
              ],
              [
                -48.54286393244841,
                -27.18524624800732
              ],
              [
                -48.54737087900598,
                -27.18682276347733
              ],
              [
                -48.5521878046433,
                -27.192945256151575
              ],
              [
                -48.55236480733415,
                -27.193139745032365
              ],
              [
                -48.55479129565573,
                -27.195811234380656
              ],
              [
                -48.560811236856324,
                -27.198254774796876
              ],
              [
                -48.56450169262299,
                -27.19974377183191
              ],
              [
                -48.571376620266655,
                -27.202516784946994
              ],
              [
                -48.57732704561953,
                -27.208586273721764
              ],
              [
                -48.57914450219252,
                -27.21145475629004
              ],
              [
                -48.583095507063796,
                -27.209588785642776
              ],
              [
                -48.586232943809684,
                -27.211465801375844
              ],
              [
                -48.59338886561167,
                -27.21576331483893
              ],
              [
                -48.59787182562357,
                -27.21845530614835
              ],
              [
                -48.60302029584988,
                -27.21885132721746
              ],
              [
                -48.60319179735733,
                -27.21886479875173
              ],
              [
                -48.60663526151615,
                -27.219574832854903
              ],
              [
                -48.6129605643346,
                -27.248237223532076
              ],
              [
                -48.61613156221175,
                -27.250716724192937
              ],
              [
                -48.61588854204674,
                -27.258257215538453
              ],
              [
                -48.61564349914161,
                -27.264567678422353
              ],
              [
                -48.615412489670206,
                -27.270513161705775
              ],
              [
                -48.61527799000153,
                -27.27397466648238
              ],
              [
                -48.61373146294647,
                -27.278185618678048
              ],
              [
                -48.61221895799935,
                -27.28230411333197
              ],
              [
                -48.60863994326838,
                -27.29205307717997
              ],
              [
                -48.60559194927791,
                -27.300353016187284
              ],
              [
                -48.6006499658587,
                -27.30825148220901
              ],
              [
                -48.59575199736839,
                -27.316079419642737
              ],
              [
                -48.5895305070215,
                -27.318523420385386
              ],
              [
                -48.58515253755365,
                -27.31972539200121
              ],
              [
                -48.58080061559706,
                -27.31211540217656
              ],
              [
                -48.57367116837228,
                -27.313742354832915
              ],
              [
                -48.57085817831601,
                -27.311786370437872
              ],
              [
                -48.56961271192889,
                -27.309408360952997
              ],
              [
                -48.56895873375322,
                -27.308159379325637
              ],
              [
                -48.564679775890404,
                -27.30762688468184
              ],
              [
                -48.56457626181511,
                -27.309950874757966
              ],
              [
                -48.56442525457945,
                -27.313339858745813
              ],
              [
                -48.55944576199247,
                -27.3148173242968
              ],
              [
                -48.55896781576155,
                -27.311600842526843
              ],
              [
                -48.554309838186626,
                -27.313147797920422
              ],
              [
                -48.55402686336974,
                -27.311088302550992
              ],
              [
                -48.55386436279258,
                -27.309905311360133
              ],
              [
                -48.55313636970031,
                -27.303871831135517
              ],
              [
                -48.54789338716499,
                -27.30515431551168
              ],
              [
                -48.54348497532598,
                -27.29715333300428
              ],
              [
                -48.53968302781982,
                -27.297943839116773
              ],
              [
                -48.53945547679935,
                -27.302839796264696
              ],
              [
                -48.53916395022057,
                -27.309159781941858
              ],
              [
                -48.538493424839,
                -27.323653211177646
              ],
              [
                -48.532350947160445,
                -27.33257566729739
              ],
              [
                -48.523820015315735,
                -27.33360365137809
              ],
              [
                -48.52752795918423,
                -27.339449111480636
              ],
              [
                -48.526273473334875,
                -27.343038628960812
              ],
              [
                -48.52855741832845,
                -27.344870601007745
              ],
              [
                -48.531243877923295,
                -27.346707632412823
              ],
              [
                -48.533310868528645,
                -27.34812113826448
              ],
              [
                -48.533160868723556,
                -27.351403089657236
              ],
              [
                -48.5330038373449,
                -27.354832071485735
              ],
              [
                -48.53500380391922,
                -27.355916598324495
              ],
              [
                -48.53683582229685,
                -27.35690908392922
              ],
              [
                -48.536177317756625,
                -27.358790101749637
              ],
              [
                -48.53087529328301,
                -27.373935531848858
              ],
              [
                -48.525799799423396,
                -27.375671508103007
              ],
              [
                -48.52875528239221,
                -27.37805599342962
              ],
              [
                -48.53461022170741,
                -27.38098200468265
              ],
              [
                -48.536682238786,
                -27.377845032314706
              ],
              [
                -48.54219471959079,
                -27.373405532507068
              ],
              [
                -48.54405715840364,
                -27.371905572397022
              ],
              [
                -48.542213687426745,
                -27.36851208658462
              ],
              [
                -48.546877675410826,
                -27.364757597353456
              ],
              [
                -48.55607259229495,
                -27.36638912579028
              ],
              [
                -48.55716009252362,
                -27.36980360194518
              ],
              [
                -48.559524549815784,
                -27.377230592711527
              ],
              [
                -48.56219250244891,
                -27.38561206502011
              ],
              [
                -48.5641939117272,
                -27.392347566112097
              ],
              [
                -48.56333644430989,
                -27.39737600569753
              ],
              [
                -48.562619905262615,
                -27.40157701080838
              ],
              [
                -48.56065195310536,
                -27.402429509937438
              ],
              [
                -48.558238430854885,
                -27.40347546482986
              ],
              [
                -48.562850879793075,
                -27.405887511764096
              ],
              [
                -48.567370823714,
                -27.408251506562586
              ],
              [
                -48.566922831422794,
                -27.41105297274033
              ],
              [
                -48.56650285561755,
                -27.413686480631593
              ],
              [
                -48.56157137872771,
                -27.414676461101998
              ],
              [
                -48.564328354365166,
                -27.419611964426558
              ],
              [
                -48.56623879762927,
                -27.42302996042669
              ],
              [
                -48.57003626044292,
                -27.427055948667263
              ],
              [
                -48.57485222599792,
                -27.426834458988136
              ],
              [
                -48.579277629490775,
                -27.43342643598098
              ],
              [
                -48.58870455580473,
                -27.43217748188726
              ],
              [
                -48.59008709141027,
                -27.43050597875683
              ],
              [
                -48.59091107479233,
                -27.429509507236045
              ],
              [
                -48.59431956795856,
                -27.42538852403415
              ],
              [
                -48.59961703300655,
                -27.424351539001563
              ],
              [
                -48.60582745839123,
                -27.42355005723711
              ],
              [
                -48.6258822696031,
                -27.43368809289384
              ],
              [
                -48.627118726724134,
                -27.44007005577127
              ],
              [
                -48.62792819330181,
                -27.444391562731735
              ],
              [
                -48.628006684841054,
                -27.44480954947138
              ],
              [
                -48.63174415704163,
                -27.449787029090118
              ],
              [
                -48.6321986426174,
                -27.450393062433555
              ],
              [
                -48.637086076555555,
                -27.456903519405852
              ],
              [
                -48.63819404420421,
                -27.464330004058002
              ],
              [
                -48.63881801418004,
                -27.468681484295615
              ],
              [
                -48.638928530914576,
                -27.46945449385003
              ],
              [
                -48.63928546714657,
                -27.471948973960146
              ],
              [
                -48.64006645907028,
                -27.47739449274637
              ],
              [
                -48.64053644055349,
                -27.477759457028643
              ],
              [
                -48.64666341063012,
                -27.482517480714364
              ],
              [
                -48.64396290602347,
                -27.488586922263377
              ],
              [
                -48.63496389681077,
                -27.508618343182242
              ],
              [
                -48.631756896803545,
                -27.515756786097214
              ],
              [
                -48.63133733923589,
                -27.522473777147844
              ],
              [
                -48.63077533340217,
                -27.52952574149264
              ],
              [
                -48.62695885337419,
                -27.53299721369225
              ],
              [
                -48.62617236887139,
                -27.533712717375
              ],
              [
                -48.62211938889762,
                -27.53739917897593
              ],
              [
                -48.62095486207599,
                -27.538458698102286
              ],
              [
                -48.61786487681876,
                -27.54126967421136
              ],
              [
                -48.61519491769384,
                -27.54369863715967
              ],
              [
                -48.61185692485562,
                -27.547124129173532
              ],
              [
                -48.612813891814625,
                -27.549433126050772
              ],
              [
                -48.616480323672405,
                -27.558281578429426
              ],
              [
                -48.60995735521448,
                -27.564091045783844
              ],
              [
                -48.607452408705505,
                -27.566288540144754
              ],
              [
                -48.604954415164904,
                -27.568480028551317
              ],
              [
                -48.60042744393832,
                -27.57245051148339
              ],
              [
                -48.59599444296874,
                -27.5736459868505
              ],
              [
                -48.59358596637042,
                -27.574295964182234
              ],
              [
                -48.592399463944346,
                -27.574615960927392
              ],
              [
                -48.58934202622852,
                -27.575396936545538
              ],
              [
                -48.576120120056295,
                -27.579133377367707
              ],
              [
                -48.578117580762786,
                -27.584781879662142
              ],
              [
                -48.5672236031486,
                -27.595407300394076
              ],
              [
                -48.568723590456145,
                -27.596579312293755
              ],
              [
                -48.57275655088424,
                -27.59973080471513
              ],
              [
                -48.57518001690686,
                -27.60171432684591
              ],
              [
                -48.573433035029055,
                -27.60345328948866
              ],
              [
                -48.57218953509998,
                -27.60469078269379
              ],
              [
                -48.57485797772241,
                -27.610433769983164
              ],
              [
                -48.58409541038791,
                -27.613591309353495
              ],
              [
                -48.5878173589576,
                -27.61891778481381
              ],
              [
                -48.59420681262888,
                -27.616769329678654
              ],
              [
                -48.5980632844547,
                -27.615109819023665
              ],
              [
                -48.59763677831062,
                -27.609987848185387
              ],
              [
                -48.597316312723116,
                -27.60614184607403
              ],
              [
                -48.59703833957141,
                -27.602802882266463
              ],
              [
                -48.59739683194328,
                -27.60271486782338
              ],
              [
                -48.60041531682146,
                -27.60197237693792
              ],
              [
                -48.60530128110409,
                -27.600750395239682
              ],
              [
                -48.62038411857597,
                -27.602796431836932
              ],
              [
                -48.62734355357574,
                -27.608447961307828
              ],
              [
                -48.62481606301975,
                -27.612533421731918
              ],
              [
                -48.62732102536642,
                -27.617043394989672
              ],
              [
                -48.62944449322703,
                -27.621250907034746
              ],
              [
                -48.6291374643105,
                -27.624120883548372
              ],
              [
                -48.628566974863695,
                -27.62945736319452
              ],
              [
                -48.62845995039862,
                -27.630459368185736
              ],
              [
                -48.62786542713158,
                -27.636028855907135
              ],
              [
                -48.63067290905342,
                -27.637981343519193
              ],
              [
                -48.634618374174075,
                -27.6406788363624
              ],
              [
                -48.64113281916542,
                -27.63409690876531
              ],
              [
                -48.64348479878107,
                -27.64273436554986
              ],
              [
                -48.63586532010413,
                -27.650681336377414
              ],
              [
                -48.62180589677093,
                -27.65742325330997
              ],
              [
                -48.614059461559606,
                -27.658862726306523
              ],
              [
                -48.60106606218651,
                -27.661278153625513
              ],
              [
                -48.60470751451756,
                -27.673217626885744
              ],
              [
                -48.597066927906575,
                -27.709132467235968
              ],
              [
                -48.599817839829015,
                -27.716899455623324
              ],
              [
                -48.601071814803454,
                -27.720440444142017
              ],
              [
                -48.59882079723668,
                -27.731982893556303
              ],
              [
                -48.595305796390505,
                -27.738244352715313
              ],
              [
                -48.588173837751945,
                -27.750948306029947
              ],
              [
                -48.59187477990056,
                -27.754766786044833
              ],
              [
                -48.59620121795232,
                -27.759231788896994
              ],
              [
                -48.592797726946216,
                -27.767590767873592
              ],
              [
                -48.60132210344059,
                -27.776897263812074
              ],
              [
                -48.59101615607283,
                -27.786941703766544
              ],
              [
                -48.59336156232716,
                -27.80562463245027
              ],
              [
                -48.579476115608415,
                -27.817593518033988
              ],
              [
                -48.57661857174796,
                -27.835774939514437
              ],
              [
                -48.576105049684095,
                -27.83904644693208
              ],
              [
                -48.575272060386865,
                -27.844345924928938
              ],
              [
                -48.57991846078562,
                -27.852635888201373
              ],
              [
                -48.579914000337,
                -27.848201916165213
              ],
              [
                -48.584862443328696,
                -27.847598938542287
              ],
              [
                -48.5863714624323,
                -27.84741542845014
              ],
              [
                -48.59745182159526,
                -27.852768441697403
              ],
              [
                -48.59893981541603,
                -27.857685932165296
              ],
              [
                -48.60072573708807,
                -27.86365543988856
              ],
              [
                -48.60198323380223,
                -27.866991899256035
              ],
              [
                -48.60133122957255,
                -27.87123938170221
              ],
              [
                -48.59925422506264,
                -27.878270877174565
              ],
              [
                -48.59366523291547,
                -27.882853317233206
              ],
              [
                -48.59132023950596,
                -27.884702823416152
              ],
              [
                -48.58744780888278,
                -27.88089983261855
              ],
              [
                -48.584258315624155,
                -27.88138030378803
              ],
              [
                -48.57771038941992,
                -27.882367299446226
              ],
              [
                -48.57441495667839,
                -27.87680778699193
              ],
              [
                -48.57418740494899,
                -27.879904294051965
              ],
              [
                -48.57397390888602,
                -27.882822286804817
              ],
              [
                -48.5735108999245,
                -27.889595737814897
              ],
              [
                -48.57549336933049,
                -27.89297324956297
              ],
              [
                -48.57801730617138,
                -27.89725772793755
              ],
              [
                -48.580631785569516,
                -27.901695207082863
              ],
              [
                -48.582830237585014,
                -27.905426215324983
              ],
              [
                -48.58608470610423,
                -27.90453523586433
              ],
              [
                -48.589853669950585,
                -27.90779924826172
              ],
              [
                -48.59337762743678,
                -27.91093221728159
              ],
              [
                -48.59639010223368,
                -27.913611235154374
              ],
              [
                -48.59909208461583,
                -27.916070250738585
              ],
              [
                -48.60674794979804,
                -27.925426213835877
              ],
              [
                -48.613058883463694,
                -27.933158709098567
              ],
              [
                -48.61651134830867,
                -27.939281205183008
              ],
              [
                -48.617030309861505,
                -27.940202705689284
              ],
              [
                -48.619123765148,
                -27.943837709961056
              ],
              [
                -48.62298773065422,
                -27.95083117538031
              ],
              [
                -48.62408519487934,
                -27.958178172887433
              ],
              [
                -48.6191312139934,
                -27.962379627537562
              ],
              [
                -48.62416064417163,
                -27.97173711159135
              ],
              [
                -48.62503159337693,
                -27.97477361376322
              ],
              [
                -48.629515003349596,
                -27.990392051543846
              ],
              [
                -48.63058947319528,
                -27.994472046708104
              ],
              [
                -48.630280475030474,
                -28.00050303306259
              ],
              [
                -48.62995794484153,
                -28.006788522465236
              ],
              [
                -48.629759925370344,
                -28.010647993978605
              ],
              [
                -48.627107946281335,
                -28.013221970502713
              ],
              [
                -48.623433976395546,
                -28.01678844690457
              ],
              [
                -48.61690048865297,
                -28.022820881489736
              ],
              [
                -48.6120410402821,
                -28.018842400708557
              ],
              [
                -48.61138055035835,
                -28.018277406417848
              ],
              [
                -48.60914507769791,
                -28.016363907439587
              ],
              [
                -48.60076709085082,
                -28.027050318931224
              ],
              [
                -48.598315619309425,
                -28.030248322923875
              ],
              [
                -48.59961708037919,
                -28.034514303230804
              ],
              [
                -48.60170556690227,
                -28.034259831447894
              ],
              [
                -48.60449455990059,
                -28.033919821422042
              ],
              [
                -48.60956849000329,
                -28.040245826580925
              ],
              [
                -48.60862396452465,
                -28.044392788464528
              ],
              [
                -48.607974477622456,
                -28.04717578425302
              ],
              [
                -48.60685497370633,
                -28.051976275193685
              ],
              [
                -48.60949786786032,
                -28.061989718709498
              ],
              [
                -48.61709928402055,
                -28.065976255116862
              ],
              [
                -48.61719030104163,
                -28.066023768266263
              ],
              [
                -48.618541778623445,
                -28.068948241571626
              ],
              [
                -48.62122921705336,
                -28.074765241248837
              ],
              [
                -48.621272234278656,
                -28.07485771806993
              ],
              [
                -48.62652370127419,
                -28.075021736892296
              ],
              [
                -48.62773663593593,
                -28.08222724846602
              ],
              [
                -48.631217128447155,
                -28.08338172155672
              ],
              [
                -48.63085711222127,
                -28.09039271932083
              ],
              [
                -48.632864042815854,
                -28.095209710206973
              ],
              [
                -48.63686949147814,
                -28.103853162492726
              ],
              [
                -48.636252973276775,
                -28.10707915884919
              ],
              [
                -48.632647506411615,
                -28.107362643187184
              ],
              [
                -48.630501506754946,
                -28.107531124056592
              ],
              [
                -48.6336689667904,
                -28.110276645402745
              ],
              [
                -48.63454096206027,
                -28.11103315077158
              ],
              [
                -48.634919490458394,
                -28.11358862100859
              ],
              [
                -48.63498843980052,
                -28.114053640596282
              ],
              [
                -48.635393965151685,
                -28.116792127278504
              ],
              [
                -48.63040549031562,
                -28.118561613471854
              ],
              [
                -48.631738940262395,
                -28.121653085157014
              ],
              [
                -48.637761907298476,
                -28.12213960657438
              ],
              [
                -48.63876239850234,
                -28.12222011180119
              ],
              [
                -48.64268033238566,
                -28.13186008929314
              ],
              [
                -48.64237532828503,
                -28.135589574968638
              ],
              [
                -48.63631084473863,
                -28.13865153978255
              ],
              [
                -48.64498325641148,
                -28.143166062215915
              ],
              [
                -48.646172237616156,
                -28.143785059195846
              ],
              [
                -48.64729069913767,
                -28.149607059452258
              ],
              [
                -48.653326161028005,
                -28.154570562050317
              ],
              [
                -48.65726455965403,
                -28.1653270357353
              ],
              [
                -48.66132448817833,
                -28.176539999257965
              ],
              [
                -48.663021443862846,
                -28.18122599762079
              ],
              [
                -48.66206043023775,
                -28.189863430631952
              ],
              [
                -48.65976292728548,
                -28.190605946912804
              ],
              [
                -48.65760193438013,
                -28.191304445830166
              ],
              [
                -48.657397921447036,
                -28.195017422957513
              ],
              [
                -48.659534906513294,
                -28.197448915869064
              ],
              [
                -48.660864873502014,
                -28.19896292519026
              ],
              [
                -48.66520434264512,
                -28.20436740733712
              ],
              [
                -48.66243034482956,
                -28.208647906412182
              ],
              [
                -48.66699780021123,
                -28.21512637557627
              ],
              [
                -48.66589226518906,
                -28.21832087637762
              ],
              [
                -48.664403278718304,
                -28.222112347716447
              ],
              [
                -48.661321283459465,
                -28.22995980109506
              ],
              [
                -48.66126625382033,
                -28.229982819819682
              ],
              [
                -48.656603324372654,
                -28.231939272462622
              ],
              [
                -48.65370033335501,
                -28.232943788676465
              ],
              [
                -48.650045386743415,
                -28.227258776907068
              ],
              [
                -48.64596340737357,
                -28.23345023361384
              ],
              [
                -48.65216332604112,
                -28.23812425193537
              ],
              [
                -48.65850171618999,
                -28.242875256485366
              ],
              [
                -48.676686011397734,
                -28.2565117664566
              ],
              [
                -48.682289926718624,
                -28.265927753999375
              ],
              [
                -48.6878938450177,
                -28.275343220571532
              ],
              [
                -48.69977917466302,
                -28.295318687161377
              ],
              [
                -48.70125812796933,
                -28.29780417963532
              ],
              [
                -48.70209760946404,
                -28.300778192130018
              ],
              [
                -48.70291861042174,
                -28.303683684802525
              ],
              [
                -48.70956494203777,
                -28.32721610151291
              ],
              [
                -48.7081599409903,
                -28.330955101392842
              ],
              [
                -48.705468945911605,
                -28.33643707559387
              ],
              [
                -48.69802302212037,
                -28.337019521188967
              ],
              [
                -48.703898428277846,
                -28.34216103291306
              ],
              [
                -48.70715889886507,
                -28.3410325574233
              ],
              [
                -48.71179687259869,
                -28.346365042108573
              ],
              [
                -48.726987163186564,
                -28.36364252526422
              ],
              [
                -48.74058242979321,
                -28.38629101805974
              ],
              [
                -48.744603362761616,
                -28.401736960229712
              ],
              [
                -48.74741778990494,
                -28.412548913379318
              ],
              [
                -48.74708927969499,
                -28.414525919295073
              ],
              [
                -48.74617828546072,
                -28.42001039987194
              ],
              [
                -48.73969080740638,
                -28.423049387196972
              ],
              [
                -48.744385730972006,
                -28.426306391482182
              ],
              [
                -48.74840219596589,
                -28.428968856441887
              ],
              [
                -48.75241415363655,
                -28.434348853891024
              ],
              [
                -48.753029620668734,
                -28.43517835517923
              ],
              [
                -48.75503663141209,
                -28.437885871850938
              ],
              [
                -48.75940604006514,
                -28.443922346295004
              ],
              [
                -48.765226424006826,
                -28.460158811307977
              ],
              [
                -48.76670989962518,
                -28.46429833014163
              ],
              [
                -48.76617039635163,
                -28.467990803339458
              ],
              [
                -48.76549039288278,
                -28.47264378168861
              ],
              [
                -48.76241479698286,
                -28.49256670427566
              ],
              [
                -48.757057862069985,
                -28.49347517703205
              ],
              [
                -48.75217740542709,
                -28.49410114847072
              ],
              [
                -48.746532961795545,
                -28.49482463584998
              ],
              [
                -48.74762942447741,
                -28.500316118609845
              ],
              [
                -48.7420629584013,
                -28.502966098548743
              ],
              [
                -48.742511937601115,
                -28.507568576105417
              ],
              [
                -48.747360386775355,
                -28.506883103764025
              ],
              [
                -48.75703974528588,
                -28.518430067029406
              ],
              [
                -48.75824971789849,
                -28.52503207135834
              ],
              [
                -48.75471573144418,
                -28.52706807367357
              ],
              [
                -48.75714166745389,
                -28.5376760074085
              ],
              [
                -48.76048216251203,
                -28.536303528095953
              ],
              [
                -48.76935405735247,
                -28.541599068322682
              ],
              [
                -48.774720519372195,
                -28.5448020663336
              ],
              [
                -48.78548385610616,
                -28.559297531390694
              ],
              [
                -48.783182875549194,
                -28.561915028086265
              ],
              [
                -48.78129036067031,
                -28.56406801785316
              ],
              [
                -48.78317482629323,
                -28.56897000656433
              ],
              [
                -48.78799580649921,
                -28.568332019996596
              ],
              [
                -48.78823579971072,
                -28.56828000803424
              ],
              [
                -48.790778745778205,
                -28.56772502204283
              ],
              [
                -48.81020202876184,
                -28.584952550699686
              ],
              [
                -48.81376945730906,
                -28.594828994235602
              ],
              [
                -48.814517454484466,
                -28.596899990737576
              ],
              [
                -48.81234593838321,
                -28.600261993327518
              ],
              [
                -48.80928093647045,
                -28.60500794292938
              ],
              [
                -48.817883342569345,
                -28.609416982441406
              ],
              [
                -48.82154284457077,
                -28.608349978228485
              ],
              [
                -48.82599978890757,
                -28.60705101667195
              ],
              [
                -48.83446469860027,
                -28.611803513524546
              ],
              [
                -48.83940366094059,
                -28.611143521486284
              ],
              [
                -48.842493645448236,
                -28.611015040991887
              ],
              [
                -48.85409653278988,
                -28.614653060957586
              ],
              [
                -48.854681545008646,
                -28.614836589418193
              ],
              [
                -48.907992490066185,
                -28.637685183625557
              ],
              [
                -48.99608102803712,
                -28.68436630058257
              ],
              [
                -49.08143159159193,
                -28.734154914810482
              ],
              [
                -49.10694732770996,
                -28.75054593005248
              ],
              [
                -49.151381319073685,
                -28.77909049158608
              ],
              [
                -49.18366594988684,
                -28.79983301307557
              ],
              [
                -49.198548273019554,
                -28.8113475069875
              ],
              [
                -49.21536057096104,
                -28.82434752394731
              ],
              [
                -49.28702024234112,
                -28.879759096279333
              ],
              [
                -49.288814217858025,
                -28.881126073205042
              ],
              [
                -49.29060871637006,
                -28.88249306913367
              ],
              [
                -49.31218894704892,
                -28.900821097061407
              ],
              [
                -49.320293364710935,
                -28.90768858451496
              ],
              [
                -49.32581578133632,
                -28.91236761492806
              ],
              [
                -49.3309797153996,
                -28.916742106067467
              ],
              [
                -49.34842050941759,
                -28.931518589113423
              ],
              [
                -49.37678566037583,
                -28.955481135427316
              ],
              [
                -49.4208816175587,
                -28.996078615100213
              ],
              [
                -49.422460096539325,
                -28.9977221163996
              ],
              [
                -49.424039577506086,
                -28.999366630700447
              ],
              [
                -49.44103187818505,
                -29.017058118012283
              ],
              [
                -49.44719478508101,
                -29.02347462924666
              ],
              [
                -49.45335768896647,
                -29.029891123498796
              ],
              [
                -49.481920331263474,
                -29.05962810453624
              ],
              [
                -49.489669208939084,
                -29.067696096237995
              ],
              [
                -49.489673723882156,
                -29.067700107241325
              ],
              [
                -49.493386687366424,
                -29.07156611906617
              ],
              [
                -49.50145057581767,
                -29.07996160488247
              ],
              [
                -49.513501903051186,
                -29.09250909865143
              ],
              [
                -49.51517540110302,
                -29.094381613610935
              ],
              [
                -49.516848895154084,
                -29.096254111571913
              ],
              [
                -49.5382110670871,
                -29.12016511117089
              ],
              [
                -49.570867136788415,
                -29.156716595010796
              ],
              [
                -49.64434566782859,
                -29.23894857367726
              ],
              [
                -49.65247404216083,
                -29.249464051393876
              ],
              [
                -49.710033766533535,
                -29.323806495616836
              ],
              [
                -49.711304733954485,
                -29.325447990004502
              ],
              [
                -49.72919804663684,
                -29.328670035039945
              ],
              [
                -49.73087454105748,
                -29.327751549254632
              ],
              [
                -49.735881000456686,
                -29.325008113773784
              ],
              [
                -49.74427045305412,
                -29.327947102422957
              ],
              [
                -49.742991985021476,
                -29.320247638664913
              ],
              [
                -49.73628752634761,
                -29.31874959760958
              ],
              [
                -49.729885110824796,
                -29.317319116462663
              ],
              [
                -49.735683595921365,
                -29.310374134574307
              ],
              [
                -49.737721545739085,
                -29.314536624623752
              ],
              [
                -49.74292153895181,
                -29.310257652848072
              ],
              [
                -49.74006404519697,
                -29.306257644226164
              ],
              [
                -49.74008761262525,
                -29.300338162551437
              ],
              [
                -49.74865553100369,
                -29.296611231492868
              ],
              [
                -49.76334140611317,
                -29.29767027861605
              ],
              [
                -49.7671943647916,
                -29.298314776840776
              ],
              [
                -49.76912585408349,
                -29.29863828696825
              ],
              [
                -49.7722973634459,
                -29.29680481670853
              ],
              [
                -49.773739345523445,
                -29.295951298838993
              ],
              [
                -49.79158522450744,
                -29.28538739646039
              ],
              [
                -49.795227688372094,
                -29.28884241878218
              ],
              [
                -49.796835139277555,
                -29.28856693165114
              ],
              [
                -49.809217089118064,
                -29.286447446555705
              ],
              [
                -49.81142054639578,
                -29.283412999592382
              ],
              [
                -49.817143006944136,
                -29.285825509039658
              ],
              [
                -49.82756548333162,
                -29.276853570890562
              ],
              [
                -49.840533335068216,
                -29.277066613413716
              ],
              [
                -49.84694884159466,
                -29.272712622385548
              ],
              [
                -49.84254235765837,
                -29.271411112222186
              ],
              [
                -49.84219340406878,
                -29.267172156673087
              ],
              [
                -49.84162992085694,
                -29.26032815478185
              ],
              [
                -49.84652139805911,
                -29.258877179669273
              ],
              [
                -49.85416181727371,
                -29.256611225417984
              ],
              [
                -49.85079689499342,
                -29.250682718147498
              ],
              [
                -49.84841290517579,
                -29.246481740924104
              ],
              [
                -49.853582405553524,
                -29.245343746824837
              ],
              [
                -49.85383940568037,
                -29.24528776096145
              ],
              [
                -49.856710386717225,
                -29.242186292694843
              ],
              [
                -49.856702392720884,
                -29.24178528696673
              ],
              [
                -49.85651888947143,
                -29.232835290341242
              ],
              [
                -49.86854935697998,
                -29.223965354826376
              ],
              [
                -49.87349031837347,
                -29.224258891224963
              ],
              [
                -49.876301791131496,
                -29.224425876125952
              ],
              [
                -49.879335273238176,
                -29.220615910761907
              ],
              [
                -49.87984775107754,
                -29.21997143575762
              ],
              [
                -49.88148474540332,
                -29.219563407162497
              ],
              [
                -49.90084663336952,
                -29.214735509754117
              ],
              [
                -49.91327856619554,
                -29.207405059722664
              ],
              [
                -49.914922545756816,
                -29.207352601000128
              ],
              [
                -49.92869591918075,
                -29.206913616597422
              ],
              [
                -49.93113339742807,
                -29.203279664438938
              ],
              [
                -49.9328448939345,
                -29.20312316630563
              ],
              [
                -49.938806844932856,
                -29.202578192225936
              ],
              [
                -49.942947318668956,
                -29.200879210120142
              ],
              [
                -49.946315798041816,
                -29.199965697599044
              ],
              [
                -49.962461147280614,
                -29.198392275702627
              ],
              [
                -49.97007310404535,
                -29.20375629962374
              ],
              [
                -49.970932075298876,
                -29.206571794700313
              ],
              [
                -49.972964533210195,
                -29.213231740698113
              ],
              [
                -50.00009825804342,
                -29.226768316880808
              ],
              [
                -50.003411706869784,
                -29.22586230214308
              ],
              [
                -50.006724665702,
                -29.224956323403525
              ],
              [
                -50.034233924232936,
                -29.233375923664425
              ],
              [
                -50.03798036646563,
                -29.236808889547486
              ],
              [
                -50.04172580070347,
                -29.240241928432184
              ],
              [
                -50.05195025746763,
                -29.241327455039507
              ],
              [
                -50.06342962817416,
                -29.242545987906116
              ],
              [
                -50.06424109806995,
                -29.245302461051455
              ],
              [
                -50.065291586035606,
                -29.248871949471358
              ],
              [
                -50.068215589547414,
                -29.24643699321741
              ],
              [
                -50.068721055386696,
                -29.246016489456608
              ],
              [
                -50.07306304814372,
                -29.24770148622275
              ],
              [
                -50.08942785139105,
                -29.25405452682196
              ],
              [
                -50.09725877356085,
                -29.254012046179458
              ],
              [
                -50.10635270528463,
                -29.25396306527543
              ],
              [
                -50.11416912011591,
                -29.258757075043047
              ],
              [
                -50.10797807781152,
                -29.280313497679355
              ],
              [
                -50.10395262617062,
                -29.28218048564764
              ],
              [
                -50.101529614835776,
                -29.283303958989148
              ],
              [
                -50.09240166412636,
                -29.29637388892388
              ],
              [
                -50.07343725870054,
                -29.309128295116846
              ],
              [
                -50.06271235300529,
                -29.311565761217445
              ],
              [
                -50.0433843756947,
                -29.336237090775853
              ],
              [
                -50.03653892309932,
                -29.344975546138905
              ],
              [
                -50.034925428495036,
                -29.34703505849713
              ],
              [
                -50.03693787962094,
                -29.35075651007972
              ],
              [
                -50.04873884619664,
                -29.33951359927653
              ],
              [
                -50.049414813709674,
                -29.338869592870164
              ],
              [
                -50.06451617343749,
                -29.340854149969996
              ],
              [
                -50.05877625037522,
                -29.33629615612651
              ],
              [
                -50.057450819937465,
                -29.328380673571047
              ],
              [
                -50.057257324185855,
                -29.327222155566204
              ],
              [
                -50.05918180007653,
                -29.327243676690312
              ],
              [
                -50.072591684838216,
                -29.327394216336625
              ],
              [
                -50.069223220091864,
                -29.324262225792516
              ],
              [
                -50.07226322464923,
                -29.319982248889993
              ],
              [
                -50.07458869201193,
                -29.320455763062846
              ],
              [
                -50.085933592462105,
                -29.322765791065162
              ],
              [
                -50.08559009222506,
                -29.32155230867266
              ],
              [
                -50.084040613780836,
                -29.31607179546041
              ],
              [
                -50.08944261083324,
                -29.310328830078443
              ],
              [
                -50.10099701711817,
                -29.310062873122956
              ],
              [
                -50.10861495915095,
                -29.305065421629408
              ],
              [
                -50.10571404430641,
                -29.29909293394436
              ],
              [
                -50.11788191877677,
                -29.29713298371155
              ],
              [
                -50.11584446511176,
                -29.29149699517551
              ],
              [
                -50.116646489951684,
                -29.290366003801456
              ],
              [
                -50.12303996168925,
                -29.28135254161441
              ],
              [
                -50.130823889512016,
                -29.28578504648212
              ],
              [
                -50.13050637044949,
                -29.28839604692111
              ],
              [
                -50.12876332912366,
                -29.302741501411305
              ],
              [
                -50.142258765510604,
                -29.29176256306159
              ],
              [
                -50.141356802017256,
                -29.287853109221665
              ],
              [
                -50.147082247045454,
                -29.287740112988637
              ],
              [
                -50.14711175043065,
                -29.287610605514082
              ],
              [
                -50.14818074535099,
                -29.282925109530332
              ],
              [
                -50.144196803748805,
                -29.275453139600028
              ],
              [
                -50.145287868546006,
                -29.256345705989744
              ],
              [
                -50.150270842089014,
                -29.257604206562444
              ],
              [
                -50.14982038121267,
                -29.249288726573965
              ],
              [
                -50.156471310916636,
                -29.251990747739736
              ],
              [
                -50.155166858145336,
                -29.248406251373844
              ],
              [
                -50.16596673775358,
                -29.24733831513846
              ],
              [
                -50.14871192948834,
                -29.241238773287346
              ],
              [
                -50.14080600863846,
                -29.238444244752696
              ],
              [
                -50.13847652880019,
                -29.233943754522873
              ],
              [
                -50.141912032833936,
                -29.229710266952324
              ],
              [
                -50.146973010650036,
                -29.229133307693488
              ],
              [
                -50.14993696170458,
                -29.22879530784122
              ],
              [
                -50.14657299661594,
                -29.226731809920043
              ],
              [
                -50.14522351082541,
                -29.225903786546272
              ],
              [
                -50.146023552152506,
                -29.223436797462945
              ],
              [
                -50.14728602654708,
                -29.219545816684203
              ],
              [
                -50.145280071952946,
                -29.21897484303667
              ],
              [
                -50.13279369174593,
                -29.21542281088855
              ],
              [
                -50.13337871086953,
                -29.208410340639066
              ],
              [
                -50.13705817262286,
                -29.204637858501716
              ],
              [
                -50.14018466821959,
                -29.201431852478983
              ],
              [
                -50.151447564259165,
                -29.202579402781605
              ],
              [
                -50.139617722736126,
                -29.19341589617532
              ],
              [
                -50.13722772347315,
                -29.199009849253
              ],
              [
                -50.122771354358356,
                -29.197570316006395
              ],
              [
                -50.129344784192064,
                -29.201419313108772
              ],
              [
                -50.11556383698324,
                -29.205248792407048
              ],
              [
                -50.11491287375803,
                -29.208888782255542
              ],
              [
                -50.10256194628228,
                -29.211155727916516
              ],
              [
                -50.09329551196602,
                -29.21285668390027
              ],
              [
                -50.100914909277506,
                -29.224713680104863
              ],
              [
                -50.11101281784922,
                -29.223394713004485
              ],
              [
                -50.10813580449761,
                -29.230044682869654
              ],
              [
                -50.110588281491644,
                -29.237190646022903
              ],
              [
                -50.09269797735683,
                -29.221852646686173
              ],
              [
                -50.098154376102705,
                -29.23608009721643
              ],
              [
                -50.093363395407565,
                -29.237209593712084
              ],
              [
                -50.083469521523284,
                -29.227521106022863
              ],
              [
                -50.087578028806256,
                -29.22248460356503
              ],
              [
                -50.08494705089276,
                -29.215208154209858
              ],
              [
                -50.09269797735683,
                -29.221852646686173
              ],
              [
                -50.086427637635566,
                -29.200119200380858
              ],
              [
                -50.0923440873528,
                -29.199120706639714
              ],
              [
                -50.085187150523545,
                -29.19317571016192
              ],
              [
                -50.09269760395533,
                -29.195082714980394
              ],
              [
                -50.088258202199825,
                -29.184248759406813
              ],
              [
                -50.09032517636696,
                -29.18107925033295
              ],
              [
                -50.09089366986401,
                -29.180207254264193
              ],
              [
                -50.09707160487098,
                -29.18014980344982
              ],
              [
                -50.09388669052225,
                -29.176587299092073
              ],
              [
                -50.098432133147604,
                -29.17237782660188
              ],
              [
                -50.09558921895268,
                -29.165710343550774
              ],
              [
                -50.09894717597897,
                -29.16324936499521
              ],
              [
                -50.08159835142609,
                -29.15983581947679
              ],
              [
                -50.07884840858425,
                -29.156037811526094
              ],
              [
                -50.07655287634351,
                -29.16086279938925
              ],
              [
                -50.09191921789901,
                -29.16705733554279
              ],
              [
                -50.08975469910905,
                -29.175077798585136
              ],
              [
                -50.087910210095856,
                -29.17514428350518
              ],
              [
                -50.0856077518045,
                -29.175227283667525
              ],
              [
                -50.07889778432844,
                -29.175469729912162
              ],
              [
                -50.081486259478375,
                -29.17657974196019
              ],
              [
                -50.08575274921851,
                -29.178410263928725
              ],
              [
                -50.07570229160471,
                -29.182557204127715
              ],
              [
                -50.07869625131847,
                -29.185335708300208
              ],
              [
                -50.07631976061554,
                -29.189355171476002
              ],
              [
                -50.06653488889195,
                -29.181486674466818
              ],
              [
                -50.060382438880566,
                -29.17653966555998
              ],
              [
                -50.0625319007816,
                -29.182800686324867
              ],
              [
                -50.0653473317749,
                -29.19099915932084
              ],
              [
                -50.05551096217135,
                -29.183404149249025
              ],
              [
                -50.05143001158709,
                -29.18598661871802
              ],
              [
                -50.04181358939089,
                -29.18351860191756
              ],
              [
                -50.03420216433899,
                -29.17653757418542
              ],
              [
                -50.0298722033238,
                -29.180263071030005
              ],
              [
                -50.01643879789382,
                -29.18089653902653
              ],
              [
                -50.02111529380103,
                -29.17845855730541
              ],
              [
                -50.01954678990019,
                -29.174302044927774
              ],
              [
                -50.027634755598534,
                -29.170593098002485
              ],
              [
                -50.02665377912424,
                -29.168236078985547
              ],
              [
                -50.024999798046515,
                -29.16426109670743
              ],
              [
                -50.0208798392404,
                -29.16530509201371
              ],
              [
                -50.0193103303637,
                -29.165702596893023
              ],
              [
                -50.019257825688264,
                -29.164482097650723
              ],
              [
                -50.01879892173638,
                -29.15385911035968
              ],
              [
                -50.01356942427226,
                -29.15550058961174
              ],
              [
                -50.00936448784915,
                -29.156820551712222
              ],
              [
                -50.009709976019,
                -29.151419079504294
              ],
              [
                -50.004731028379645,
                -29.15038455636563
              ],
              [
                -50.00512357271274,
                -29.14050709385899
              ],
              [
                -50.005372089806855,
                -29.134245616096944
              ],
              [
                -50.01354157811021,
                -29.127771679430108
              ],
              [
                -50.000144138657745,
                -29.13166810605683
              ],
              [
                -49.99652665865027,
                -29.132720593214188
              ],
              [
                -49.989828769818,
                -29.128253113864176
              ],
              [
                -49.99069873553233,
                -29.13449656481916
              ],
              [
                -49.9863612666504,
                -29.13614407937275
              ],
              [
                -49.983829316341584,
                -29.129570098326425
              ],
              [
                -49.9882927754321,
                -29.12246609696277
              ],
              [
                -49.99057079794692,
                -29.118840136193572
              ],
              [
                -49.98345934500855,
                -29.117184603195927
              ],
              [
                -49.98248187748663,
                -29.1187740854048
              ],
              [
                -49.98189937077243,
                -29.11972110616655
              ],
              [
                -49.967652520212155,
                -29.11555856186693
              ],
              [
                -49.96854897613144,
                -29.12047204821364
              ],
              [
                -49.96235404908903,
                -29.117466044018585
              ],
              [
                -49.96383300766202,
                -29.114165555237008
              ],
              [
                -49.967652520212155,
                -29.11555856186693
              ],
              [
                -49.96854753987249,
                -29.109921573519543
              ],
              [
                -49.96945654283719,
                -29.10419563351712
              ],
              [
                -49.96902055358305,
                -29.101048618141355
              ],
              [
                -49.96488707344502,
                -29.100319108197304
              ],
              [
                -49.96235010768301,
                -29.0998716042529
              ],
              [
                -49.964418621857135,
                -29.09750510262109
              ],
              [
                -49.966716092386726,
                -29.094876612690477
              ],
              [
                -49.96697665499194,
                -29.08347015379795
              ],
              [
                -49.96217820612072,
                -29.084043170159017
              ],
              [
                -49.95399775243156,
                -29.085019125683296
              ],
              [
                -49.95359329504552,
                -29.075797163240818
              ],
              [
                -49.95764175527557,
                -29.072278680698368
              ],
              [
                -49.959790269372895,
                -29.070412173736717
              ],
              [
                -49.956069809752385,
                -29.066980170154565
              ],
              [
                -49.94769337552886,
                -29.06924164977675
              ],
              [
                -49.951665346701375,
                -29.062528701372244
              ],
              [
                -49.942020424583426,
                -29.064257154043784
              ],
              [
                -49.93955648934777,
                -29.056686674635245
              ],
              [
                -49.94563596518481,
                -29.055148698144556
              ],
              [
                -49.95625933160896,
                -29.06167618515359
              ],
              [
                -49.956069809752385,
                -29.066980170154565
              ],
              [
                -49.95904828617864,
                -29.06590018170055
              ],
              [
                -49.963637765022305,
                -29.064235736115126
              ],
              [
                -49.96754819328553,
                -29.067622718549398
              ],
              [
                -49.96740524602467,
                -29.064171720269435
              ],
              [
                -49.986963063182664,
                -29.068625297171366
              ],
              [
                -49.98487905825498,
                -29.071175751146548
              ],
              [
                -49.98812699993106,
                -29.075206287051667
              ],
              [
                -49.99099348081613,
                -29.06964429077986
              ],
              [
                -50.00698235146363,
                -29.071638335517704
              ],
              [
                -49.95872083932842,
                -29.048912279902993
              ],
              [
                -49.95815489275361,
                -29.043125272696926
              ],
              [
                -49.95414644489685,
                -29.039818290668517
              ],
              [
                -49.95289745520455,
                -29.038788767411553
              ],
              [
                -49.95368597143998,
                -29.03737128395621
              ],
              [
                -49.95499593682648,
                -29.03501729248748
              ],
              [
                -49.953162974116765,
                -29.03458678211614
              ],
              [
                -49.94543806099251,
                -29.032771787485807
              ],
              [
                -49.94659757803742,
                -29.02740579130582
              ],
              [
                -49.93809964416141,
                -29.023118773913776
              ],
              [
                -49.93891716559548,
                -29.019307793395647
              ],
              [
                -49.946694089686396,
                -29.017486821084546
              ],
              [
                -49.94856557829802,
                -29.020589322857802
              ],
              [
                -49.94875204866983,
                -29.020605827492933
              ],
              [
                -49.96430395575889,
                -29.022010347384192
              ],
              [
                -49.95610004015847,
                -29.010876879449288
              ],
              [
                -49.95099966530897,
                -28.99899741246094
              ],
              [
                -49.94557268400769,
                -28.995518866803224
              ],
              [
                -49.94168872060396,
                -29.00047287024827
              ],
              [
                -49.94140571723016,
                -29.000833875021776
              ],
              [
                -49.941459733619325,
                -28.9998213495349
              ],
              [
                -49.94236630277958,
                -28.982925926196383
              ],
              [
                -49.94149430349329,
                -28.98198940204554
              ],
              [
                -49.93800435536637,
                -28.978242421441458
              ],
              [
                -49.934527827273875,
                -28.98431236872654
              ],
              [
                -49.92004799369991,
                -28.97820984630046
              ],
              [
                -49.93387041300195,
                -28.973988914127563
              ],
              [
                -49.93730541169437,
                -28.964927456501886
              ],
              [
                -49.955869767646725,
                -28.961340049738855
              ],
              [
                -49.95560239106733,
                -28.940321085663236
              ],
              [
                -49.95320187537675,
                -28.940450620388695
              ],
              [
                -49.94433445272024,
                -28.940927588136297
              ],
              [
                -49.947630012228096,
                -28.92646661474531
              ],
              [
                -49.94345755836258,
                -28.923210621064467
              ],
              [
                -49.951048966511244,
                -28.92359665076482
              ],
              [
                -49.96171287842982,
                -28.924138162281373
              ],
              [
                -49.96515488037223,
                -28.921721702895912
              ],
              [
                -49.959156439941026,
                -28.914411683791535
              ],
              [
                -49.955677498981466,
                -28.910172212899862
              ],
              [
                -49.95847045317452,
                -28.90820272965569
              ],
              [
                -49.951459581190406,
                -28.9029802249958
              ],
              [
                -49.94762810755622,
                -28.900126231269383
              ],
              [
                -49.948149645538884,
                -28.891014256147184
              ],
              [
                -49.94835115666622,
                -28.88747875651589
              ],
              [
                -49.94238522415259,
                -28.88453576224402
              ],
              [
                -49.941202740145364,
                -28.88007625756749
              ],
              [
                -49.93544026219083,
                -28.88213472259451
              ],
              [
                -49.93561379533157,
                -28.87590476574001
              ],
              [
                -49.92858739419834,
                -28.87094226224212
              ],
              [
                -49.93812629344074,
                -28.87170028682295
              ],
              [
                -49.949319670624696,
                -28.87905930777557
              ],
              [
                -49.94856625700033,
                -28.86871282305366
              ],
              [
                -49.95302520535346,
                -28.86990433953234
              ],
              [
                -49.96615153781717,
                -28.87341238228148
              ],
              [
                -49.96388711782006,
                -28.867468879502123
              ],
              [
                -49.95391320030083,
                -28.86288584489999
              ],
              [
                -49.95368022668213,
                -28.85773339200731
              ],
              [
                -49.95361376617201,
                -28.856270867578914
              ],
              [
                -49.94796027182722,
                -28.856321344627073
              ],
              [
                -49.94500636563261,
                -28.848666870986094
              ],
              [
                -49.95404476943632,
                -28.851034395409386
              ],
              [
                -49.958257717261596,
                -28.852137898263482
              ],
              [
                -49.95500681618901,
                -28.833372475142454
              ],
              [
                -49.95370635753381,
                -28.825866496112145
              ],
              [
                -49.955412351151296,
                -28.82591647421397
              ],
              [
                -49.962790784952105,
                -28.826132512607114
              ],
              [
                -49.963461304288145,
                -28.82172154161259
              ],
              [
                -49.95936643933825,
                -28.80078608864137
              ],
              [
                -49.95493395508344,
                -28.80247656279115
              ],
              [
                -49.94642652626327,
                -28.805721510851846
              ],
              [
                -49.94688153857699,
                -28.80063003833331
              ],
              [
                -49.9469665603678,
                -28.799679565784068
              ],
              [
                -49.95297199868593,
                -28.797543065878333
              ],
              [
                -49.95398852011115,
                -28.79546007148711
              ],
              [
                -49.956179989183404,
                -28.790969112359804
              ],
              [
                -49.952390051439245,
                -28.78359909480149
              ],
              [
                -49.95238555651891,
                -28.783590126814605
              ],
              [
                -49.95238858650561,
                -28.78358761783406
              ],
              [
                -49.958514523328326,
                -28.778806646102314
              ],
              [
                -49.953813591635644,
                -28.776193654495415
              ],
              [
                -49.956084080314724,
                -28.771220176261195
              ],
              [
                -49.95136465768514,
                -28.768836661836243
              ],
              [
                -49.95307466673203,
                -28.759683719375236
              ],
              [
                -49.94519925028414,
                -28.758987696812408
              ],
              [
                -49.94570672885288,
                -28.755857209030886
              ],
              [
                -49.94269174471625,
                -28.756731184089983
              ],
              [
                -49.93641431615687,
                -28.75855165306153
              ],
              [
                -49.927521377648496,
                -28.755633129140318
              ],
              [
                -49.927883922514255,
                -28.751208650120585
              ],
              [
                -49.92149948968129,
                -28.745644647169758
              ],
              [
                -49.93052043225803,
                -28.740054197727193
              ],
              [
                -49.93082994685054,
                -28.739172180783036
              ],
              [
                -49.934881979774126,
                -28.727606733947024
              ],
              [
                -49.92500706284321,
                -28.718428258241683
              ],
              [
                -49.9132651817211,
                -28.715124207247687
              ],
              [
                -49.90358829294807,
                -28.706621700088835
              ],
              [
                -49.90481675157469,
                -28.712613202683368
              ],
              [
                -49.90194676900899,
                -28.715664691055125
              ],
              [
                -49.89386981557274,
                -28.724250147008398
              ],
              [
                -49.89242631018767,
                -28.723263632003864
              ],
              [
                -49.88449490348625,
                -28.717841117007392
              ],
              [
                -49.88172944293498,
                -28.719645092902642
              ],
              [
                -49.88603333918941,
                -28.728366084678875
              ],
              [
                -49.891341249508606,
                -28.739122053397868
              ],
              [
                -49.88687131485879,
                -28.740125555711327
              ],
              [
                -49.88811277101772,
                -28.746183537155115
              ],
              [
                -49.875156391014464,
                -28.744378491730988
              ],
              [
                -49.87966537405943,
                -28.735789525738504
              ],
              [
                -49.86903599858135,
                -28.723184049732918
              ],
              [
                -49.87240447960438,
                -28.72234054385159
              ],
              [
                -49.873956484557006,
                -28.72195207881689
              ],
              [
                -49.86929956648155,
                -28.707007114471807
              ],
              [
                -49.87158306858229,
                -28.70402012874942
              ],
              [
                -49.859954700053414,
                -28.699550085135666
              ],
              [
                -49.86277463751613,
                -28.702559604423815
              ],
              [
                -49.852094239990606,
                -28.70407855535285
              ],
              [
                -49.84590831218944,
                -28.704958041830103
              ],
              [
                -49.84471229002933,
                -28.707317017609945
              ],
              [
                -49.84160177900696,
                -28.71345247282861
              ],
              [
                -49.836318340431845,
                -28.70928849038743
              ],
              [
                -49.83630035064698,
                -28.70927448536836
              ],
              [
                -49.84052134406995,
                -28.707261991484867
              ],
              [
                -49.838646860567906,
                -28.701740998019655
              ],
              [
                -49.83161544023328,
                -28.694436532675994
              ],
              [
                -49.82065105218052,
                -28.693172483867148
              ],
              [
                -49.82268307683446,
                -28.687735018408386
              ],
              [
                -49.83342096611623,
                -28.68786154717493
              ],
              [
                -49.83393495771551,
                -28.68469456160537
              ],
              [
                -49.8348935154779,
                -28.6787881007913
              ],
              [
                -49.83956598012578,
                -28.676780592532968
              ],
              [
                -49.83729353559456,
                -28.666629624089154
              ],
              [
                -49.82898961777207,
                -28.668277092305445
              ],
              [
                -49.82210215774506,
                -28.66149009383606
              ],
              [
                -49.82040721161151,
                -28.660892600650648
              ],
              [
                -49.81225726471493,
                -28.65801956453211
              ],
              [
                -49.81055931809221,
                -28.653689607804903
              ],
              [
                -49.81666625765859,
                -28.653636087241598
              ],
              [
                -49.81787073471189,
                -28.653625593666852
              ],
              [
                -49.81459479466984,
                -28.649618100112146
              ],
              [
                -49.814202777166464,
                -28.649139107283613
              ],
              [
                -49.81425531857111,
                -28.648959599074722
              ],
              [
                -49.816823834518736,
                -28.640147145877076
              ],
              [
                -49.808689416699934,
                -28.635301662440934
              ],
              [
                -49.807630441753716,
                -28.62947414407031
              ],
              [
                -49.80219399115147,
                -28.628772626620787
              ],
              [
                -49.794660089896375,
                -28.61653517414753
              ],
              [
                -49.782076720079864,
                -28.610812648527094
              ],
              [
                -49.78455864737056,
                -28.622177098497183
              ],
              [
                -49.781241183566586,
                -28.628240096125456
              ],
              [
                -49.780402678362634,
                -28.629772563943572
              ],
              [
                -49.771570736189574,
                -28.627528050314623
              ],
              [
                -49.765419304738884,
                -28.622079043183774
              ],
              [
                -49.757684433980174,
                -28.615228047008216
              ],
              [
                -49.75555389026744,
                -28.623235503419902
              ],
              [
                -49.75414037719914,
                -28.62854448048752
              ],
              [
                -49.73868053020065,
                -28.625212422421125
              ],
              [
                -49.727774642799034,
                -28.622861913646673
              ],
              [
                -49.71508624592428,
                -28.623574382127313
              ],
              [
                -49.709895255531244,
                -28.625403831117676
              ],
              [
                -49.70637527399869,
                -28.626644821157296
              ],
              [
                -49.7078927796758,
                -28.63000833138545
              ],
              [
                -49.704177312113934,
                -28.629034309147457
              ],
              [
                -49.69175742378936,
                -28.624663284682658
              ],
              [
                -49.69131496398802,
                -28.620266809841837
              ],
              [
                -49.69113148602072,
                -28.61844379529933
              ],
              [
                -49.69843493347662,
                -28.60618736001119
              ],
              [
                -49.70936984989345,
                -28.604191403437103
              ],
              [
                -49.71164940811864,
                -28.592359969473154
              ],
              [
                -49.71024644680219,
                -28.588921983935233
              ],
              [
                -49.707675498516515,
                -28.58262349877931
              ],
              [
                -49.71107597654858,
                -28.572901047822967
              ],
              [
                -49.719144913537875,
                -28.576330547574685
              ],
              [
                -49.719443878462684,
                -28.576457532232954
              ],
              [
                -49.724971883154204,
                -28.56945607184236
              ],
              [
                -49.725741406048634,
                -28.56660310323017
              ],
              [
                -49.72981443950548,
                -28.551504658803417
              ],
              [
                -49.73757188500924,
                -28.549158708839894
              ],
              [
                -49.74751177848059,
                -28.546152225016854
              ],
              [
                -49.74621235345742,
                -28.533858274671033
              ],
              [
                -49.738347447343926,
                -28.527060787038657
              ],
              [
                -49.72780856615698,
                -28.517951761529268
              ],
              [
                -49.73589754413528,
                -28.51254282707102
              ],
              [
                -49.7430564879551,
                -28.50775537014663
              ],
              [
                -49.74358099585157,
                -28.506840385130864
              ],
              [
                -49.744898010581906,
                -28.504544898639445
              ],
              [
                -49.73980803594529,
                -28.500640890360046
              ],
              [
                -49.74450902662604,
                -28.49381341840954
              ],
              [
                -49.760059389132024,
                -28.50081043616073
              ],
              [
                -49.76207734917552,
                -28.501718452414213
              ],
              [
                -49.764533873619534,
                -28.49917295289201
              ],
              [
                -49.769718826557906,
                -28.493801009779087
              ],
              [
                -49.76497740202712,
                -28.482350020196563
              ],
              [
                -49.76468450366858,
                -28.45966007967477
              ],
              [
                -49.768414991567305,
                -28.459481110780555
              ],
              [
                -49.77245092392439,
                -28.46663908224095
              ],
              [
                -49.78132233202835,
                -28.47167359938778
              ],
              [
                -49.77793331903116,
                -28.47535858669102
              ],
              [
                -49.78534822945585,
                -28.487662560092897
              ],
              [
                -49.78406570964918,
                -28.489265058047618
              ],
              [
                -49.78057523535075,
                -28.493624022716762
              ],
              [
                -49.78287522528841,
                -28.49577550880705
              ],
              [
                -49.79034614682568,
                -28.494811034134216
              ],
              [
                -49.79476712645364,
                -28.48786061856009
              ],
              [
                -49.80236509205563,
                -28.483428619020405
              ],
              [
                -49.80720909952658,
                -28.472524677293595
              ],
              [
                -49.80942411531332,
                -28.46753773011651
              ],
              [
                -49.81204108688638,
                -28.467913705331203
              ],
              [
                -49.816469071245294,
                -28.468550737229325
              ],
              [
                -49.81624603980713,
                -28.470767205957277
              ],
              [
                -49.81552902868228,
                -28.477890675375413
              ],
              [
                -49.81053102081472,
                -28.483335637930853
              ],
              [
                -49.82541394148075,
                -28.477741729706686
              ],
              [
                -49.83931174875068,
                -28.495737225587472
              ],
              [
                -49.84493669582992,
                -28.49279674387669
              ],
              [
                -49.84177076714096,
                -28.483524287562588
              ],
              [
                -49.85323267602143,
                -28.48507430692561
              ],
              [
                -49.85422515917842,
                -28.485208794073948
              ],
              [
                -49.866018074127226,
                -28.48680383250042
              ],
              [
                -49.868968550774476,
                -28.480118846663288
              ],
              [
                -49.86356263244843,
                -28.473462869421958
              ],
              [
                -49.86346164411874,
                -28.471130384896572
              ],
              [
                -49.86306918639417,
                -28.462079889905603
              ],
              [
                -49.87321213642162,
                -28.45626997221737
              ],
              [
                -49.87691158964801,
                -28.454150488756866
              ],
              [
                -49.87509812116022,
                -28.45339497272324
              ],
              [
                -49.855067321621824,
                -28.445055456191028
              ],
              [
                -49.857543322133054,
                -28.44311393569804
              ],
              [
                -49.87251421752956,
                -28.441543502228186
              ],
              [
                -49.882565101485824,
                -28.44690801160812
              ],
              [
                -49.89070705129284,
                -28.441055088805655
              ],
              [
                -49.895881477076045,
                -28.443085089736627
              ],
              [
                -49.88992450261142,
                -28.454231508666744
              ],
              [
                -49.88813598887371,
                -28.457577021936356
              ],
              [
                -49.89807689873884,
                -28.462723021702363
              ],
              [
                -49.90285187179898,
                -28.462818554704295
              ],
              [
                -49.90923731621784,
                -28.45975555115729
              ],
              [
                -49.9273002021181,
                -28.451091163783637
              ],
              [
                -49.93123414673797,
                -28.452998680653753
              ],
              [
                -49.93752309983605,
                -28.4579311659238
              ],
              [
                -49.930142139255544,
                -28.463007605101385
              ],
              [
                -49.92963413645355,
                -28.46558960459458
              ],
              [
                -49.92872109043298,
                -28.470229087716387
              ],
              [
                -49.94303441371763,
                -28.481491116937836
              ],
              [
                -49.950127944005835,
                -28.466245682799123
              ],
              [
                -49.9501539562509,
                -28.465716667667063
              ],
              [
                -49.950906020570635,
                -28.45011226172896
              ],
              [
                -49.95753596958097,
                -28.449296763528455
              ],
              [
                -49.966601875184494,
                -28.454137802207168
              ],
              [
                -49.97104837963394,
                -28.4386058434311
              ],
              [
                -49.97993032979456,
                -28.43978736470715
              ],
              [
                -49.988101740602445,
                -28.445321385823483
              ],
              [
                -49.98782970575133,
                -28.44813888139432
              ],
              [
                -49.9871251816399,
                -28.455435842388802
              ],
              [
                -50.0020110763274,
                -28.456812405856095
              ],
              [
                -50.00469202259611,
                -28.455436406203386
              ],
              [
                -50.01357198849086,
                -28.45088043371837
              ],
              [
                -50.018071927851125,
                -28.45184796083064
              ],
              [
                -50.023265367023676,
                -28.456998978469123
              ],
              [
                -50.02465232864133,
                -28.464074917843902
              ],
              [
                -50.02629277512672,
                -28.47244491282478
              ],
              [
                -50.035727691293225,
                -28.477375412664976
              ],
              [
                -50.05687702053942,
                -28.4759290054433
              ],
              [
                -50.061418485525905,
                -28.4756185270061
              ],
              [
                -50.0682389212426,
                -28.47798453193218
              ],
              [
                -50.072044353704015,
                -28.479304543379587
              ],
              [
                -50.0750283385451,
                -28.477278078002577
              ],
              [
                -50.08593327357223,
                -28.47235760311283
              ],
              [
                -50.09142620391911,
                -28.477369089397524
              ],
              [
                -50.09955364332614,
                -28.484784622278134
              ],
              [
                -50.10736662574502,
                -28.472606172320187
              ],
              [
                -50.12405896754847,
                -28.47381823407695
              ],
              [
                -50.12581998083788,
                -28.463142292060063
              ],
              [
                -50.11882507957409,
                -28.457326774976362
              ],
              [
                -50.115533111450915,
                -28.454589782119523
              ],
              [
                -50.11111821576961,
                -28.44472779395155
              ],
              [
                -50.12629461968462,
                -28.428853416140605
              ],
              [
                -50.1297051228426,
                -28.43094940455263
              ],
              [
                -50.12743861240973,
                -28.43784236130827
              ],
              [
                -50.14515390856114,
                -28.445993398607914
              ],
              [
                -50.14730083480647,
                -28.459093878779886
              ],
              [
                -50.15896222956221,
                -28.459572919664435
              ],
              [
                -50.14917275064879,
                -28.471899321964425
              ],
              [
                -50.145179260067756,
                -28.476927295677196
              ],
              [
                -50.13904375221172,
                -28.493714715457646
              ],
              [
                -50.156500578140125,
                -28.4971997625289
              ],
              [
                -50.161204545777885,
                -28.494551804486864
              ],
              [
                -50.1641066250738,
                -28.47374486225084
              ],
              [
                -50.16855312849041,
                -28.466028388127675
              ],
              [
                -50.176481104424724,
                -28.452272485790452
              ],
              [
                -50.18500204594223,
                -28.452351502574466
              ],
              [
                -50.18843601754204,
                -28.452383039982912
              ],
              [
                -50.19136001823469,
                -28.450293529589366
              ],
              [
                -50.20031044045357,
                -28.443896085486134
              ],
              [
                -50.2155712733739,
                -28.45666208366802
              ],
              [
                -50.2226061980439,
                -28.46254709277244
              ],
              [
                -50.22835765252759,
                -28.460026113122428
              ],
              [
                -50.2341090980192,
                -28.457504674473494
              ],
              [
                -50.23457612320503,
                -28.45642866375001
              ],
              [
                -50.246586642718924,
                -28.428740812523916
              ],
              [
                -50.26318696228282,
                -28.442465821463358
              ],
              [
                -50.26256843346972,
                -28.44437079088389
              ],
              [
                -50.262142452850135,
                -28.445681287980243
              ],
              [
                -50.251248528694575,
                -28.448393762239505
              ],
              [
                -50.2711442995623,
                -28.456242784783715
              ],
              [
                -50.291908683904104,
                -28.45070336493733
              ],
              [
                -50.29612364658549,
                -28.445290885264384
              ],
              [
                -50.30033862826333,
                -28.43987895658987
              ],
              [
                -50.32776346979575,
                -28.433754559985065
              ],
              [
                -50.33878233706333,
                -28.439817547133476
              ],
              [
                -50.337563287568834,
                -28.44847955000603
              ],
              [
                -50.33629476182248,
                -28.457492500545595
              ],
              [
                -50.345290169201206,
                -28.462795993873904
              ],
              [
                -50.35073464811847,
                -28.455192048917546
              ],
              [
                -50.34883075277781,
                -28.43722060242626
              ],
              [
                -50.34786927905312,
                -28.42814963594949
              ],
              [
                -50.35671627666142,
                -28.420409698880235
              ],
              [
                -50.369598643077275,
                -28.424041730889968
              ],
              [
                -50.37183761333751,
                -28.429045694507188
              ],
              [
                -50.379195959094716,
                -28.445494676992805
              ],
              [
                -50.38780091041765,
                -28.4436011997015
              ],
              [
                -50.393681905981225,
                -28.43574275916741
              ],
              [
                -50.39618739311065,
                -28.43239428639125
              ],
              [
                -50.4103762941369,
                -28.43169333056334
              ],
              [
                -50.422711682949576,
                -28.431083389668697
              ],
              [
                -50.433289093781546,
                -28.43055889807165
              ],
              [
                -50.44553200011132,
                -28.423598453812453
              ],
              [
                -50.44884351860359,
                -28.419195021455753
              ],
              [
                -50.45544400863294,
                -28.410417561563264
              ],
              [
                -50.470354832150925,
                -28.414367087040556
              ],
              [
                -50.49874364009282,
                -28.41219869801957
              ],
              [
                -50.51699799326975,
                -28.403775787558196
              ],
              [
                -50.527084445680146,
                -28.40359481305546
              ],
              [
                -50.530618397813164,
                -28.407437819328045
              ],
              [
                -50.52990186519217,
                -28.413733811973163
              ],
              [
                -50.52851483446901,
                -28.425915724796173
              ],
              [
                -50.536378770571034,
                -28.42860977072886
              ],
              [
                -50.54362917455959,
                -28.42663831176714
              ],
              [
                -50.54921270146611,
                -28.419696832047098
              ],
              [
                -50.562393095271005,
                -28.41404640889873
              ],
              [
                -50.57631005845609,
                -28.393156994567097
              ],
              [
                -50.58036955701894,
                -28.39001605576087
              ],
              [
                -50.59117651895068,
                -28.38165359783032
              ],
              [
                -50.59824993957048,
                -28.38136711366304
              ],
              [
                -50.602019400594976,
                -28.38483214606589
              ],
              [
                -50.61077626545138,
                -28.39288263466742
              ],
              [
                -50.62462519439841,
                -28.39097820065057
              ],
              [
                -50.63218018633585,
                -28.37868923969461
              ],
              [
                -50.630357220986724,
                -28.3723262779304
              ],
              [
                -50.62783975562301,
                -28.36354076259944
              ],
              [
                -50.64798167913038,
                -28.345857899465436
              ],
              [
                -50.64968217730719,
                -28.344994932526024
              ],
              [
                -50.66446609390924,
                -28.337495991283483
              ],
              [
                -50.65949867170769,
                -28.323300050809337
              ],
              [
                -50.658910185355104,
                -28.3216185351911
              ],
              [
                -50.66082220603065,
                -28.319209551111804
              ],
              [
                -50.673612129087225,
                -28.30309813691051
              ],
              [
                -50.694102540921314,
                -28.287961245644603
              ],
              [
                -50.6953375836604,
                -28.27835779777994
              ],
              [
                -50.69543708644808,
                -28.27758628768443
              ],
              [
                -50.69638160699624,
                -28.270242838324574
              ],
              [
                -50.69781408963847,
                -28.266316867492314
              ],
              [
                -50.711327017246376,
                -28.265129897766887
              ],
              [
                -50.748913773442844,
                -28.247151571286533
              ],
              [
                -50.75543784150826,
                -28.21900168282401
              ],
              [
                -50.75491385126064,
                -28.215671212886715
              ],
              [
                -50.7554013505749,
                -28.21324770465379
              ],
              [
                -50.759370353495854,
                -28.202032281111823
              ],
              [
                -50.76553489057938,
                -28.188447329403765
              ],
              [
                -50.7722327831219,
                -28.19491834449956
              ],
              [
                -50.77742673861954,
                -28.199936839911892
              ],
              [
                -50.788200634557896,
                -28.197915890894325
              ],
              [
                -50.788581688725095,
                -28.18904192052583
              ],
              [
                -50.78431025644216,
                -28.18020892706151
              ],
              [
                -50.78213032507886,
                -28.17570041697969
              ],
              [
                -50.786308819969875,
                -28.167775974359397
              ],
              [
                -50.79113277829552,
                -28.158628038132377
              ],
              [
                -50.788893877628865,
                -28.15169601185162
              ],
              [
                -50.78603491886747,
                -28.14284753365256
              ],
              [
                -50.79187540074743,
                -28.138909581938503
              ],
              [
                -50.80434079117898,
                -28.13944060960536
              ],
              [
                -50.81801916555903,
                -28.140023190534226
              ],
              [
                -50.82257213976828,
                -28.130294698232728
              ],
              [
                -50.827987173519574,
                -28.118723290161185
              ],
              [
                -50.83347964470041,
                -28.1187892769537
              ],
              [
                -50.85076142558195,
                -28.132391799067026
              ],
              [
                -50.85645787462082,
                -28.13687579505867
              ],
              [
                -50.86872626658249,
                -28.137231835631887
              ],
              [
                -50.87424675270035,
                -28.12843488674724
              ],
              [
                -50.870807820921414,
                -28.116067448969503
              ],
              [
                -50.86881488929376,
                -28.10889945334295
              ],
              [
                -50.875574902145296,
                -28.094314510089234
              ],
              [
                -50.88111333498751,
                -28.090282563569183
              ],
              [
                -50.88967128168221,
                -28.08877956970445
              ],
              [
                -50.901402227299876,
                -28.08671862224234
              ],
              [
                -50.90021724183159,
                -28.08219712083761
              ],
              [
                -50.89903178036839,
                -28.07767416144032
              ],
              [
                -50.8943653245335,
                -28.074994157288238
              ],
              [
                -50.88144545942254,
                -28.06757412174553
              ],
              [
                -50.8749365483281,
                -28.05476715037639
              ],
              [
                -50.87510903986869,
                -28.053266668964174
              ],
              [
                -50.87653011962879,
                -28.04087619510473
              ],
              [
                -50.892718041865265,
                -28.020263343153065
              ],
              [
                -50.92234735321019,
                -28.0111869608929
              ],
              [
                -50.92114690413679,
                -28.006371973451667
              ],
              [
                -50.91994742205404,
                -28.00155597702215
              ],
              [
                -50.92522587621703,
                -27.994875019309546
              ],
              [
                -50.92894089688468,
                -27.990173053367418
              ],
              [
                -50.927743405611594,
                -27.98777653194669
              ],
              [
                -50.924105451580765,
                -27.980493568838238
              ],
              [
                -50.928444988308165,
                -27.968603615972555
              ],
              [
                -50.94169788543292,
                -27.968566169225305
              ],
              [
                -50.94483887010539,
                -27.96855765966161
              ],
              [
                -50.97198717848952,
                -27.955630317052876
              ],
              [
                -50.97919364853479,
                -27.94755386995219
              ],
              [
                -50.9799401732782,
                -27.946735881370223
              ],
              [
                -50.98443416570711,
                -27.941808890995407
              ],
              [
                -50.99197505739256,
                -27.944518919414143
              ],
              [
                -50.99674046385355,
                -27.954524887634058
              ],
              [
                -51.000711439381924,
                -27.962863871502137
              ],
              [
                -51.00999034990595,
                -27.962566907211976
              ],
              [
                -51.010067841294735,
                -27.962563918503555
              ],
              [
                -51.01880831032743,
                -27.954859974725
              ],
              [
                -51.01713147048138,
                -27.925198571687964
              ],
              [
                -51.05578423132192,
                -27.901011746033344
              ],
              [
                -51.05515929349161,
                -27.886411310067064
              ],
              [
                -51.05316834905865,
                -27.877155328482647
              ],
              [
                -51.052157400782036,
                -27.87245686637422
              ],
              [
                -51.053798889607364,
                -27.867439374945864
              ],
              [
                -51.064650309112764,
                -27.866596920091244
              ],
              [
                -51.072265229276844,
                -27.86519045636826
              ],
              [
                -51.079378234815245,
                -27.858051486802037
              ],
              [
                -51.07841925915119,
                -27.85181752096662
              ],
              [
                -51.07673132605285,
                -27.840851517174357
              ],
              [
                -51.0815952855861,
                -27.834383554236865
              ],
              [
                -51.0984186817205,
                -27.828397138045375
              ],
              [
                -51.10288616554021,
                -27.829106654887855
              ],
              [
                -51.12408497165422,
                -27.8324742205968
              ],
              [
                -51.13078443120648,
                -27.829553772555695
              ],
              [
                -51.131776973185545,
                -27.820408276421436
              ],
              [
                -51.131994453642434,
                -27.818401311853385
              ],
              [
                -51.133349985948136,
                -27.805925363077083
              ],
              [
                -51.15761829972814,
                -27.802711923671676
              ],
              [
                -51.19040366372808,
                -27.776701620576883
              ],
              [
                -51.19799459837536,
                -27.77658065248525
              ],
              [
                -51.19816157604717,
                -27.77657815509863
              ],
              [
                -51.206086047050846,
                -27.77645220223562
              ],
              [
                -51.213789983163814,
                -27.772980225642293
              ],
              [
                -51.221579439777535,
                -27.769469744485114
              ],
              [
                -51.22348191866234,
                -27.770748745151668
              ],
              [
                -51.227450884603066,
                -27.773418747712142
              ],
              [
                -51.23484578326728,
                -27.77888277646759
              ],
              [
                -51.24183275464547,
                -27.77312530882698
              ],
              [
                -51.246458296714344,
                -27.760654370809135
              ],
              [
                -51.273128601102464,
                -27.74906798077718
              ],
              [
                -51.27227064421906,
                -27.737439036098397
              ],
              [
                -51.27210515082219,
                -27.735193521922028
              ],
              [
                -51.272747657680675,
                -27.733007556477144
              ],
              [
                -51.27554570182811,
                -27.723488084092565
              ],
              [
                -51.28694960510576,
                -27.724453642211078
              ],
              [
                -51.29116208374997,
                -27.724810651293115
              ],
              [
                -51.307449935956924,
                -27.719222718776457
              ],
              [
                -51.30822842495134,
                -27.718955216481177
              ],
              [
                -51.30825846851306,
                -27.71156623400808
              ],
              [
                -51.307258492917256,
                -27.710166249013167
              ],
              [
                -51.30567351774257,
                -27.707948245604285
              ],
              [
                -51.30150405479145,
                -27.70211126080569
              ],
              [
                -51.31074355767908,
                -27.69383829762349
              ],
              [
                -51.31610307236336,
                -27.674232402892727
              ],
              [
                -51.323568021855266,
                -27.672438927841746
              ],
              [
                -51.331033962348904,
                -27.67064545779485
              ],
              [
                -51.33632939814608,
                -27.672587469536246
              ],
              [
                -51.353830271937646,
                -27.679006016677008
              ],
              [
                -51.369166151587486,
                -27.671960062495145
              ],
              [
                -51.376744616198366,
                -27.661638115041587
              ],
              [
                -51.37358822983972,
                -27.650373160851785
              ],
              [
                -51.369831796007226,
                -27.63696669460508
              ],
              [
                -51.38621719988199,
                -27.62343280865221
              ],
              [
                -51.40177458331643,
                -27.63053433844145
              ],
              [
                -51.40701700948098,
                -27.637327817959
              ],
              [
                -51.42021683504064,
                -27.65443229426838
              ],
              [
                -51.43221271378761,
                -27.654549345338214
              ],
              [
                -51.43653318496753,
                -27.649592377342916
              ],
              [
                -51.435654759569616,
                -27.638952427271903
              ],
              [
                -51.435131805661115,
                -27.63261744329158
              ],
              [
                -51.456880222287886,
                -27.60608508663014
              ],
              [
                -51.46590814757147,
                -27.603839631715044
              ],
              [
                -51.46999965260511,
                -27.60282215488137
              ],
              [
                -51.474916605942475,
                -27.59788367198152
              ],
              [
                -51.475589119206276,
                -27.5900687172248
              ],
              [
                -51.477348714291935,
                -27.56962875713428
              ],
              [
                -51.48986114116839,
                -27.559627359432223
              ],
              [
                -51.50785750119208,
                -27.556486410873116
              ],
              [
                -51.51268096634468,
                -27.557684939353496
              ],
              [
                -51.53337826922563,
                -27.562827475231376
              ],
              [
                -51.54608820064028,
                -27.56007804528706
              ],
              [
                -51.54770819235159,
                -27.55972753630472
              ],
              [
                -51.553537605790986,
                -27.563063049396693
              ],
              [
                -51.55888151084993,
                -27.57610300975413
              ],
              [
                -51.561983482661276,
                -27.583670998054664
              ],
              [
                -51.56487544593703,
                -27.583610509728423
              ],
              [
                -51.568575910862094,
                -27.583533047387018
              ],
              [
                -51.57757339269916,
                -27.57621607007076
              ],
              [
                -51.58600985829551,
                -27.56050068138096
              ],
              [
                -51.57931000098549,
                -27.538671711089584
              ],
              [
                -51.57976257357784,
                -27.524127791712292
              ],
              [
                -51.59352646295029,
                -27.524846308113545
              ],
              [
                -51.602407842949724,
                -27.53548931214798
              ],
              [
                -51.607209267607836,
                -27.541242314565707
              ],
              [
                -51.62231164018029,
                -27.545956328712187
              ],
              [
                -51.63143958893883,
                -27.537784408663107
              ],
              [
                -51.620915815094726,
                -27.505577455715798
              ],
              [
                -51.6192813538084,
                -27.500576977301503
              ],
              [
                -51.62108236380293,
                -27.49545350871643
              ],
              [
                -51.62148883983072,
                -27.494297526000135
              ],
              [
                -51.62881683084084,
                -27.490079042406364
              ],
              [
                -51.631122291579786,
                -27.48875103412095
              ],
              [
                -51.642158217032595,
                -27.491605597598028
              ],
              [
                -51.64323619729104,
                -27.49780507805478
              ],
              [
                -51.645762613147554,
                -27.512333520315316
              ],
              [
                -51.656474980793035,
                -27.51966003395464
              ],
              [
                -51.669065902410175,
                -27.51427911223958
              ],
              [
                -51.676060870722154,
                -27.506198162153375
              ],
              [
                -51.6735714319487,
                -27.495983178777298
              ],
              [
                -51.67251298810894,
                -27.49163768226119
              ],
              [
                -51.67480145390036,
                -27.48725369198022
              ],
              [
                -51.686631887609416,
                -27.479006286934702
              ],
              [
                -51.689580885886116,
                -27.47930278462292
              ],
              [
                -51.698627815036545,
                -27.480214796340938
              ],
              [
                -51.716142048510484,
                -27.509157271529116
              ],
              [
                -51.7199649944629,
                -27.511078762061747
              ],
              [
                -51.72378744041929,
                -27.513000293595603
              ],
              [
                -51.75292585569123,
                -27.486862470989067
              ],
              [
                -51.79789698693593,
                -27.490564600749455
              ],
              [
                -51.79955243831889,
                -27.496383599671862
              ],
              [
                -51.8012083896867,
                -27.50220309160193
              ],
              [
                -51.790265415559794,
                -27.514855492463813
              ],
              [
                -51.78542494904671,
                -27.521171947214278
              ],
              [
                -51.78585094314849,
                -27.523259475920998
              ],
              [
                -51.78682138677977,
                -27.528010955879132
              ],
              [
                -51.79501881090636,
                -27.52976247088708
              ],
              [
                -51.803517269286054,
                -27.52393051781504
              ],
              [
                -51.82050771910873,
                -27.512270602625982
              ],
              [
                -51.837273511183525,
                -27.520451628704134
              ],
              [
                -51.837468034211575,
                -27.520546646099593
              ],
              [
                -51.846890948368454,
                -27.52543863631821
              ],
              [
                -51.85384342074303,
                -27.51792318412262
              ],
              [
                -51.84264062142494,
                -27.48677526936643
              ],
              [
                -51.842270128049044,
                -27.485745260395532
              ],
              [
                -51.84320363409098,
                -27.483240290982895
              ],
              [
                -51.844137628132835,
                -27.48073427957589
              ],
              [
                -51.845513114972675,
                -27.48013978750957
              ],
              [
                -51.85686755660813,
                -27.475235853733544
              ],
              [
                -51.87580188197279,
                -27.48270890694444
              ],
              [
                -51.879092312510004,
                -27.487855403051867
              ],
              [
                -51.872733777825495,
                -27.51302826570369
              ],
              [
                -51.87669923136775,
                -27.51852878913935
              ],
              [
                -51.88903760596274,
                -27.519828323714453
              ],
              [
                -51.901886610463826,
                -27.49553641968389
              ],
              [
                -51.90056619737967,
                -27.474389993985
              ],
              [
                -51.90046819498734,
                -27.472233524679304
              ],
              [
                -51.90010523317403,
                -27.464266538414538
              ],
              [
                -51.90338123774275,
                -27.461540565210825
              ],
              [
                -51.91557565272731,
                -27.458861095198518
              ],
              [
                -51.93522796774259,
                -27.466307141163647
              ],
              [
                -51.954712275395885,
                -27.47369016178994
              ],
              [
                -51.96328425535125,
                -27.467436750314658
              ],
              [
                -51.962039296888825,
                -27.461579731906276
              ],
              [
                -51.944383964431864,
                -27.44812522177897
              ],
              [
                -51.93828604369005,
                -27.44347824285087
              ],
              [
                -51.93600657397314,
                -27.437702723469233
              ],
              [
                -51.93372663426431,
                -27.431924261103923
              ],
              [
                -51.94469806623848,
                -27.423032798955525
              ],
              [
                -51.95539654836709,
                -27.414363398086433
              ],
              [
                -51.97407337422274,
                -27.40996494715651
              ],
              [
                -51.991026240028816,
                -27.409452515280982
              ],
              [
                -52.004630169735464,
                -27.40290057500458
              ],
              [
                -52.00732214343954,
                -27.401605084995616
              ],
              [
                -52.002335214788914,
                -27.394112099404893
              ],
              [
                -51.991388351507645,
                -27.389816569784593
              ],
              [
                -51.98809537194186,
                -27.39036508406091
              ],
              [
                -51.95811359113945,
                -27.39387444798118
              ],
              [
                -51.95392612090419,
                -27.394364945211013
              ],
              [
                -51.94935668251184,
                -27.391812451024254
              ],
              [
                -51.950503187292234,
                -27.380847978094803
              ],
              [
                -51.95841764728921,
                -27.376147026143435
              ],
              [
                -51.96147513445141,
                -27.374331037159386
              ],
              [
                -51.98680694461211,
                -27.37506813244663
              ],
              [
                -51.99681185125657,
                -27.37739914804814
              ],
              [
                -52.02007925024883,
                -27.361531295216988
              ],
              [
                -52.02193325597627,
                -27.354636783485653
              ],
              [
                -52.01903378606793,
                -27.35215180412122
              ],
              [
                -52.008236903008886,
                -27.34289780234615
              ],
              [
                -52.00876942646521,
                -27.333937330627254
              ],
              [
                -52.017761886826584,
                -27.331269865893155
              ],
              [
                -52.039395667399425,
                -27.34411788411615
              ],
              [
                -52.04027064816119,
                -27.344637378584174
              ],
              [
                -52.04835557845384,
                -27.34444842047199
              ],
              [
                -52.07256641755936,
                -27.33797553529611
              ],
              [
                -52.09749216856899,
                -27.34792108510423
              ],
              [
                -52.100181152765984,
                -27.34587110453879
              ],
              [
                -52.1064786381296,
                -27.341069639028273
              ],
              [
                -52.107389235672514,
                -27.309198718416333
              ],
              [
                -52.114164210682034,
                -27.306070766161103
              ],
              [
                -52.12093868070569,
                -27.30294229390458
              ],
              [
                -52.15047340853022,
                -27.31075739732711
              ],
              [
                -52.16091229212044,
                -27.31351939413346
              ],
              [
                -52.16356231320968,
                -27.312446912232076
              ],
              [
                -52.16621227530063,
                -27.311374422330402
              ],
              [
                -52.1652938026614,
                -27.307374953045816
              ],
              [
                -52.15913794652669,
                -27.280569994244644
              ],
              [
                -52.16601694007151,
                -27.273406063537394
              ],
              [
                -52.17334040426016,
                -27.272393581344847
              ],
              [
                -52.18373625594701,
                -27.282725602247666
              ],
              [
                -52.199913999422506,
                -27.313071040941665
              ],
              [
                -52.20681637548225,
                -27.326019026824344
              ],
              [
                -52.212186826784496,
                -27.33033252727406
              ],
              [
                -52.2125538461613,
                -27.330277021786124
              ],
              [
                -52.2237202653833,
                -27.32858355080711
              ],
              [
                -52.239822235244496,
                -27.306819181992665
              ],
              [
                -52.24471066326779,
                -27.305214215941433
              ],
              [
                -52.249599648295494,
                -27.303608234895304
              ],
              [
                -52.273886467385545,
                -27.30247482267812
              ],
              [
                -52.2772049425439,
                -27.296508827101604
              ],
              [
                -52.26462409509348,
                -27.28239384337134
              ],
              [
                -52.26339362995697,
                -27.281901331513094
              ],
              [
                -52.23449788956493,
                -27.27033877445811
              ],
              [
                -52.235543433756234,
                -27.264523315196588
              ],
              [
                -52.24739336357336,
                -27.25954937631817
              ],
              [
                -52.24939285373221,
                -27.258710394292763
              ],
              [
                -52.253418834897296,
                -27.25701991638031
              ],
              [
                -52.26070924847994,
                -27.25842741919841
              ],
              [
                -52.27670710933416,
                -27.26151747409078
              ],
              [
                -52.29327951786503,
                -27.257305010846498
              ],
              [
                -52.301251929263685,
                -27.259570548369382
              ],
              [
                -52.3024543358868,
                -27.27916700903165
              ],
              [
                -52.29677432588417,
                -27.293121422100192
              ],
              [
                -52.29167483479952,
                -27.30564936391754
              ],
              [
                -52.29300725949775,
                -27.313536341155615
              ],
              [
                -52.29763519857105,
                -27.316553360169944
              ],
              [
                -52.305927648890034,
                -27.316955385978748
              ],
              [
                -52.32876953635681,
                -27.300467518389034
              ],
              [
                -52.33739448928244,
                -27.294241552891542
              ],
              [
                -52.34602193720756,
                -27.288013597406305
              ],
              [
                -52.35579232525859,
                -27.296065616785917
              ],
              [
                -52.36460421538067,
                -27.303327631274854
              ],
              [
                -52.374868134141316,
                -27.303786150084917
              ],
              [
                -52.377309665516364,
                -27.29819917803976
              ],
              [
                -52.383342182271925,
                -27.284389755663145
              ],
              [
                -52.38062480002067,
                -27.25941779271392
              ],
              [
                -52.39199174160787,
                -27.25209137371315
              ],
              [
                -52.39935116118449,
                -27.258035403155855
              ],
              [
                -52.393336086212884,
                -27.284622291210574
              ],
              [
                -52.39534406125181,
                -27.29215626512327
              ],
              [
                -52.41880387942557,
                -27.2822243694963
              ],
              [
                -52.43451634607413,
                -27.26570296401939
              ],
              [
                -52.440246804807124,
                -27.259677501325566
              ],
              [
                -52.440943840363,
                -27.25418204163649
              ],
              [
                -52.441641346908945,
                -27.248686541952743
              ],
              [
                -52.43480600185726,
                -27.23313108951025
              ],
              [
                -52.43048356820564,
                -27.22329411570592
              ],
              [
                -52.43153404430413,
                -27.221909114005573
              ],
              [
                -52.43398552887667,
                -27.218674640381714
              ],
              [
                -52.44106349495918,
                -27.217740654079936
              ],
              [
                -52.44540195775559,
                -27.21716766267285
              ],
              [
                -52.47814662228339,
                -27.234873732189808
              ],
              [
                -52.48150606628966,
                -27.24473569350704
              ],
              [
                -52.48056704903592,
                -27.25277565111415
              ],
              [
                -52.47920348554244,
                -27.26444810745399
              ],
              [
                -52.496977368139376,
                -27.262906201044462
              ],
              [
                -52.53432059223082,
                -27.259666337292625
              ],
              [
                -52.53580156429354,
                -27.25953783009368
              ],
              [
                -52.54480760356965,
                -27.239914924110327
              ],
              [
                -52.54900555665738,
                -27.238214927854308
              ],
              [
                -52.56032641361818,
                -27.246784444397687
              ],
              [
                -52.570542825767134,
                -27.248633477604386
              ],
              [
                -52.57923226848185,
                -27.248356511110252
              ],
              [
                -52.58983119764743,
                -27.248018535764555
              ],
              [
                -52.60030008515142,
                -27.2548540738771
              ],
              [
                -52.613208948429275,
                -27.26328305378609
              ],
              [
                -52.630853314085996,
                -27.264444141330046
              ],
              [
                -52.644979208649254,
                -27.259568192449244
              ],
              [
                -52.652136725349955,
                -27.24854874589851
              ],
              [
                -52.6592122101052,
                -27.2376548386472
              ],
              [
                -52.66652160829511,
                -27.23789534249416
              ],
              [
                -52.67579601465529,
                -27.246160851752943
              ],
              [
                -52.67713299663663,
                -27.25660430913142
              ],
              [
                -52.6785944312001,
                -27.26801676687793
              ],
              [
                -52.684803833700215,
                -27.2753127761505
              ],
              [
                -52.691453256720266,
                -27.282839285311972
              ],
              [
                -52.69328775250383,
                -27.282617275716486
              ],
              [
                -52.69855218657702,
                -27.281980811963674
              ],
              [
                -52.699174690183796,
                -27.28122930764008
              ],
              [
                -52.70645466190936,
                -27.2724363433422
              ],
              [
                -52.707417224448015,
                -27.263898903181445
              ],
              [
                -52.70936578424759,
                -27.246615449639787
              ],
              [
                -52.71456376662145,
                -27.236387005360612
              ],
              [
                -52.721159203814004,
                -27.235223045127874
              ],
              [
                -52.73108062696826,
                -27.239598529278645
              ],
              [
                -52.73504754416497,
                -27.245309533459142
              ],
              [
                -52.742650966267725,
                -27.256255530170723
              ],
              [
                -52.75421088458378,
                -27.256721550860554
              ],
              [
                -52.763029862083734,
                -27.24297213499771
              ],
              [
                -52.7589080198368,
                -27.215174731921632
              ],
              [
                -52.77355242971336,
                -27.206786809147165
              ],
              [
                -52.786288807064196,
                -27.20461235252299
              ],
              [
                -52.800214732741374,
                -27.20276690518507
              ],
              [
                -52.808886667240166,
                -27.201983451302333
              ],
              [
                -52.817558621755985,
                -27.201198973425527
              ],
              [
                -52.822348561696295,
                -27.203615978163715
              ],
              [
                -52.83318396698198,
                -27.2090835062111
              ],
              [
                -52.84370135243287,
                -27.20607104119673
              ],
              [
                -52.84629488466233,
                -27.202504562046116
              ],
              [
                -52.845248433037234,
                -27.192795060243792
              ],
              [
                -52.843610001989845,
                -27.17759364285774
              ],
              [
                -52.85001796768038,
                -27.168550175126203
              ],
              [
                -52.85141996256983,
                -27.168611668037926
              ],
              [
                -52.862322391190034,
                -27.169087707244387
              ],
              [
                -52.88944561746542,
                -27.18669925489963
              ],
              [
                -52.89633003977454,
                -27.19229774816081
              ],
              [
                -52.90321344708609,
                -27.197896251434972
              ],
              [
                -52.92513074778451,
                -27.201872833787483
              ],
              [
                -52.9309827407205,
                -27.199883824490346
              ],
              [
                -52.936834701664914,
                -27.19789435319415
              ],
              [
                -52.93693919018476,
                -27.19366286704547
              ],
              [
                -52.93750430828339,
                -27.1707754500085
              ],
              [
                -52.95125321311461,
                -27.161601541405023
              ],
              [
                -52.95798564571047,
                -27.163028571422288
              ],
              [
                -52.965383020370496,
                -27.17997354147535
              ],
              [
                -52.96971082608553,
                -27.216281916860236
              ],
              [
                -52.97380479045455,
                -27.21870141114542
              ],
              [
                -52.97716577245059,
                -27.22068893212558
              ],
              [
                -52.98966018959155,
                -27.2173134865335
              ],
              [
                -52.99970017623855,
                -27.206382048942007
              ],
              [
                -53.00214672239603,
                -27.189539609359947
              ],
              [
                -52.997694263267356,
                -27.180659123304537
              ],
              [
                -52.99330284771068,
                -27.17570713100392
              ],
              [
                -52.98312798245927,
                -27.164235121340422
              ],
              [
                -52.98427751775783,
                -27.15170517641834
              ],
              [
                -52.98806848004765,
                -27.145895211749142
              ],
              [
                -52.98878952353158,
                -27.144790212898336
              ],
              [
                -52.99334698327027,
                -27.137805275767438
              ],
              [
                -53.00295991642736,
                -27.13293929332748
              ],
              [
                -53.01195183611092,
                -27.14217378273998
              ],
              [
                -53.02610217089699,
                -27.156705799186085
              ],
              [
                -53.03825455401708,
                -27.154154859671525
              ],
              [
                -53.04258659413806,
                -27.145509385958967
              ],
              [
                -53.04180918228449,
                -27.126175439583594
              ],
              [
                -53.026668376430436,
                -27.10802696212128
              ],
              [
                -53.02438940240006,
                -27.10529497951536
              ],
              [
                -53.02002794663269,
                -27.095037001308597
              ],
              [
                -53.01797402141012,
                -27.090205476228938
              ],
              [
                -53.022021983447836,
                -27.083128545542614
              ],
              [
                -53.02846245669235,
                -27.080096578670787
              ],
              [
                -53.04933430287318,
                -27.082806143074336
              ],
              [
                -53.058026221277274,
                -27.08583463210462
              ],
              [
                -53.07019308368195,
                -27.097935664949166
              ],
              [
                -53.075750983793796,
                -27.10346312864216
              ],
              [
                -53.078205908272594,
                -27.115897103078133
              ],
              [
                -53.070647891273694,
                -27.135436534420208
              ],
              [
                -53.07021241848269,
                -27.13656301625286
              ],
              [
                -53.070982354720975,
                -27.146827473479355
              ],
              [
                -53.07175229993999,
                -27.157090443727984
              ],
              [
                -53.11228194481049,
                -27.16968904499166
              ],
              [
                -53.12949677834251,
                -27.178040076562766
              ],
              [
                -53.13259925944987,
                -27.177834588568132
              ],
              [
                -53.14106719086165,
                -27.16986814774813
              ],
              [
                -53.14528422368477,
                -27.15561072326768
              ],
              [
                -53.14934725827489,
                -27.141873755592794
              ],
              [
                -53.15789421750305,
                -27.138408293813285
              ],
              [
                -53.16547111373857,
                -27.142263327327985
              ],
              [
                -53.172822033956194,
                -27.150438806371636
              ],
              [
                -53.17602639721336,
                -27.173857775126802
              ],
              [
                -53.17667289863014,
                -27.178579735589953
              ],
              [
                -53.18002536723632,
                -27.182218258388524
              ],
              [
                -53.18405630626306,
                -27.186592213359113
              ],
              [
                -53.188155737413524,
                -27.191041722350526
              ],
              [
                -53.196032214292096,
                -27.191190748749218
              ],
              [
                -53.2197980650893,
                -27.177424881236288
              ],
              [
                -53.23021900084567,
                -27.171389416443592
              ],
              [
                -53.2370579785692,
                -27.169392949796727
              ],
              [
                -53.24235489864859,
                -27.17033649523882
              ],
              [
                -53.249179860675646,
                -27.175282976684684
              ],
              [
                -53.252111334470364,
                -27.17740798174511
              ],
              [
                -53.291703938686695,
                -27.196040584445758
              ],
              [
                -53.30948421091419,
                -27.216852545473266
              ],
              [
                -53.3149291399233,
                -27.21626606832054
              ],
              [
                -53.31679115658799,
                -27.216066079790135
              ],
              [
                -53.320114654141385,
                -27.208889121492128
              ],
              [
                -53.31825470738691,
                -27.19474064601506
              ],
              [
                -53.30112799287961,
                -27.16348921527182
              ],
              [
                -53.296059567060546,
                -27.150312217117207
              ],
              [
                -53.293457642915016,
                -27.142246733955776
              ],
              [
                -53.29254064095024,
                -27.13940375954273
              ],
              [
                -53.294053144399065,
                -27.13393679129292
              ],
              [
                -53.29881115598504,
                -27.12607033048878
              ],
              [
                -53.310097635478215,
                -27.11823487449764
              ],
              [
                -53.34228387154143,
                -27.118628000173263
              ],
              [
                -53.347425816760754,
                -27.11698349718575
              ],
              [
                -53.351680819555725,
                -27.11057655792054
              ],
              [
                -53.36147582410695,
                -27.095840614142414
              ],
              [
                -53.36292078300964,
                -27.0936661192739
              ],
              [
                -53.37198424533483,
                -27.09048116148474
              ],
              [
                -53.37769718767756,
                -27.092673187517757
              ],
              [
                -53.382654663888836,
                -27.095937206419727
              ],
              [
                -53.3932369740107,
                -27.11190369905737
              ],
              [
                -53.39670890653932,
                -27.119974151462586
              ],
              [
                -53.400164857344286,
                -27.128007131943775
              ],
              [
                -53.41067774352043,
                -27.14034164286494
              ],
              [
                -53.45745682161835,
                -27.155563770060496
              ],
              [
                -53.46297979351701,
                -27.15163779862469
              ],
              [
                -53.46850276842212,
                -27.147711297189026
              ],
              [
                -53.48053223199527,
                -27.134793422751493
              ],
              [
                -53.49416410983704,
                -27.13266743547809
              ],
              [
                -53.50602653039179,
                -27.13790348472453
              ],
              [
                -53.50721497119716,
                -27.146859455147784
              ],
              [
                -53.50355342381739,
                -27.161064418379514
              ],
              [
                -53.48926246891673,
                -27.18190475983169
              ],
              [
                -53.490292406271344,
                -27.19315626556482
              ],
              [
                -53.490441393673336,
                -27.19477676706575
              ],
              [
                -53.49154086261506,
                -27.201653710698576
              ],
              [
                -53.509056219648734,
                -27.203259798215523
              ],
              [
                -53.51760766270126,
                -27.199517332361054
              ],
              [
                -53.526159606762555,
                -27.195774846506502
              ],
              [
                -53.548094014921475,
                -27.175741015606935
              ],
              [
                -53.56332591722295,
                -27.173071060006762
              ],
              [
                -53.563465446271174,
                -27.17304704859533
              ],
              [
                -53.58978217293297,
                -27.186941102408504
              ],
              [
                -53.59917208288115,
                -27.188805618240895
              ],
              [
                -53.61139700884563,
                -27.185558169388326
              ],
              [
                -53.61498345706717,
                -27.186967167244536
              ],
              [
                -53.61856944229532,
                -27.18837568810267
              ],
              [
                -53.62138788024477,
                -27.193711681961542
              ],
              [
                -53.63450968475546,
                -27.21855265648557
              ],
              [
                -53.641969106523376,
                -27.22039266634263
              ],
              [
                -53.647727085303984,
                -27.21767370101538
              ],
              [
                -53.64990060634552,
                -27.211490740160315
              ],
              [
                -53.64739867738318,
                -27.193294786220633
              ],
              [
                -53.64729517465658,
                -27.192539296177248
              ],
              [
                -53.64883472407557,
                -27.184493797766276
              ],
              [
                -53.65037374348503,
                -27.176448321359825
              ],
              [
                -53.66612568857364,
                -27.161745441992288
              ],
              [
                -53.675386622826785,
                -27.160747473232643
              ],
              [
                -53.68783049488661,
                -27.164746511749744
              ],
              [
                -53.706039313066135,
                -27.173542046736422
              ],
              [
                -53.71356421833403,
                -27.17952455602865
              ],
              [
                -53.7173201780199,
                -27.181865030667904
              ],
              [
                -53.73320351966452,
                -27.189619600396696
              ],
              [
                -53.73462053271502,
                -27.190311602497015
              ],
              [
                -53.73635603463289,
                -27.19115858729641
              ],
              [
                -53.74236045561227,
                -27.190826088507727
              ],
              [
                -53.74836592159401,
                -27.190492643727296
              ],
              [
                -53.756232918789074,
                -27.171688229784362
              ],
              [
                -53.76373444166995,
                -27.153757297813996
              ],
              [
                -53.77261691186227,
                -27.147791357016747
              ],
              [
                -53.778704855542564,
                -27.149564857018223
              ],
              [
                -53.80237307721844,
                -27.168840890024594
              ],
              [
                -53.810298542225475,
                -27.172726916352982
              ],
              [
                -53.81393548543478,
                -27.173644921972045
              ],
              [
                -53.816889965609455,
                -27.174390905598933
              ],
              [
                -53.82351290716426,
                -27.172505952883917
              ],
              [
                -53.83383332565324,
                -27.169339999779222
              ],
              [
                -53.832988864445,
                -27.16514247656842
              ],
              [
                -53.833743381253704,
                -27.161134013689534
              ],
              [
                -53.83476439155686,
                -27.155715018137798
              ],
              [
                -53.82628652342104,
                -27.14582051827548
              ],
              [
                -53.81213814055013,
                -27.144952986690768
              ],
              [
                -53.80586066865094,
                -27.144831491883085
              ],
              [
                -53.797938725022,
                -27.14453492354568
              ],
              [
                -53.79832377203946,
                -27.137611948318536
              ],
              [
                -53.816832106898964,
                -27.13890802165514
              ],
              [
                -53.818952110113635,
                -27.13689954567359
              ],
              [
                -53.81912108257702,
                -27.136738558793688
              ],
              [
                -53.82367907426911,
                -27.13084206279729
              ],
              [
                -53.82350359285783,
                -27.127316084018904
              ],
              [
                -53.814526158716504,
                -27.128423041467084
              ],
              [
                -53.81374518218439,
                -27.128519572286724
              ],
              [
                -53.8063492598062,
                -27.125131015443234
              ],
              [
                -53.80442731554979,
                -27.11749505691693
              ],
              [
                -53.80564679630495,
                -27.111419591171266
              ],
              [
                -53.80591831748117,
                -27.110066570350316
              ],
              [
                -53.817647238467345,
                -27.10208116626389
              ],
              [
                -53.814587820122036,
                -27.09871514737514
              ],
              [
                -53.80934684556175,
                -27.09833112123642
              ],
              [
                -53.804183369555226,
                -27.105085605333624
              ],
              [
                -53.80278483321418,
                -27.105939071540217
              ],
              [
                -53.80126586844669,
                -27.106866071075522
              ],
              [
                -53.797646401158396,
                -27.10832005623678
              ],
              [
                -53.794413390925854,
                -27.109344046151268
              ],
              [
                -53.785852470179265,
                -27.105778511602768
              ],
              [
                -53.780092563315534,
                -27.103712994754115
              ],
              [
                -53.77727559174266,
                -27.100869020165025
              ],
              [
                -53.79458550735538,
                -27.08832610274637
              ],
              [
                -53.799422987854136,
                -27.08482063741192
              ],
              [
                -53.80331444852315,
                -27.081966163577533
              ],
              [
                -53.803355961002666,
                -27.07831416302368
              ],
              [
                -53.802870966062336,
                -27.075402180243245
              ],
              [
                -53.79705807971396,
                -27.065904686220165
              ],
              [
                -53.79449706940738,
                -27.0677966889333
              ],
              [
                -53.79074108513529,
                -27.070779641868643
              ],
              [
                -53.79040608561924,
                -27.071008639926145
              ],
              [
                -53.78662912433451,
                -27.07359411601377
              ],
              [
                -53.78103116291693,
                -27.077251103077142
              ],
              [
                -53.776977703619586,
                -27.075944611194075
              ],
              [
                -53.77112426557876,
                -27.07254408917159
              ],
              [
                -53.77454923438293,
                -27.06977012337365
              ],
              [
                -53.782380146027954,
                -27.069999148496848
              ],
              [
                -53.78887863826062,
                -27.064680177893628
              ],
              [
                -53.796157128530865,
                -27.058721723139808
              ],
              [
                -53.796998097639964,
                -27.05578773432076
              ],
              [
                -53.798347638352254,
                -27.050141250775273
              ],
              [
                -53.79903762522224,
                -27.044239280597154
              ],
              [
                -53.79151871620133,
                -27.042480268382302
              ],
              [
                -53.7882522279336,
                -27.046183236887597
              ],
              [
                -53.78389975448748,
                -27.051716180725137
              ],
              [
                -53.78130421599163,
                -27.055015664948925
              ],
              [
                -53.777991768978744,
                -27.059392143196874
              ],
              [
                -53.771213760212184,
                -27.063550600363975
              ],
              [
                -53.76640728128245,
                -27.06645409167507
              ],
              [
                -53.757975404411695,
                -27.06160705066854
              ],
              [
                -53.76044788176101,
                -27.059411572573957
              ],
              [
                -53.76671587013729,
                -27.055304607371266
              ],
              [
                -53.77124782256467,
                -27.052091171009053
              ],
              [
                -53.77493830305369,
                -27.04938167998753
              ],
              [
                -53.77701479622926,
                -27.043737700126556
              ],
              [
                -53.78123981891091,
                -27.032255247278716
              ],
              [
                -53.7833448298259,
                -27.025526793893253
              ],
              [
                -53.77571890064266,
                -27.028561215440064
              ],
              [
                -53.77137540047127,
                -27.033808199187643
              ],
              [
                -53.76999788965688,
                -27.03560617854327
              ],
              [
                -53.76679389373539,
                -27.039789159781886
              ],
              [
                -53.764140416769756,
                -27.04241864286488
              ],
              [
                -53.75965497813152,
                -27.03731816719966
              ],
              [
                -53.757650019130445,
                -27.034087146858784
              ],
              [
                -53.7497700837223,
                -27.031489627959395
              ],
              [
                -53.74645309517278,
                -27.030546148698992
              ],
              [
                -53.74697613175554,
                -27.026062656551247
              ],
              [
                -53.748958608771034,
                -27.021690148423176
              ],
              [
                -53.75520611999179,
                -27.017643209953647
              ],
              [
                -53.75642560937513,
                -27.01685319788901
              ],
              [
                -53.7588416149592,
                -27.01032873904109
              ],
              [
                -53.753471134373875,
                -27.006015246725017
              ],
              [
                -53.747049689133945,
                -27.007079220834083
              ],
              [
                -53.746892212205324,
                -27.00710572017341
              ],
              [
                -53.740522227029,
                -27.012878188827152
              ],
              [
                -53.731680851211074,
                -27.005186667290946
              ],
              [
                -53.735895309963475,
                -26.998481194632372
              ],
              [
                -53.73825383387675,
                -26.995687714984747
              ],
              [
                -53.73996181419078,
                -26.993665243549195
              ],
              [
                -53.736102873908614,
                -26.992902209761912
              ],
              [
                -53.731814377272904,
                -26.992053717666035
              ],
              [
                -53.72912189237662,
                -26.99211768858659
              ],
              [
                -53.728005909709616,
                -26.992144206408987
              ],
              [
                -53.723501949025746,
                -26.992539170652215
              ],
              [
                -53.719943483864206,
                -26.990378685327105
              ],
              [
                -53.7163225247175,
                -26.987662177508966
              ],
              [
                -53.71652000881309,
                -26.984031698549305
              ],
              [
                -53.71976451836467,
                -26.98225170899789
              ],
              [
                -53.725269485310676,
                -26.979483217817663
              ],
              [
                -53.72627748801203,
                -26.978971740108804
              ],
              [
                -53.73275195397175,
                -26.9756887530865
              ],
              [
                -53.73507542032655,
                -26.973948289031902
              ],
              [
                -53.73248746773779,
                -26.97245727218781
              ],
              [
                -53.7290109823006,
                -26.97131177001014
              ],
              [
                -53.72811503472442,
                -26.967887766400324
              ],
              [
                -53.726490049381376,
                -26.961678801806784
              ],
              [
                -53.72496009770288,
                -26.958012799635377
              ],
              [
                -53.72086559013412,
                -26.9594547811301
              ],
              [
                -53.71596814131293,
                -26.96206624603235
              ],
              [
                -53.71491314664113,
                -26.96221172871183
              ],
              [
                -53.71048569339505,
                -26.962822713578934
              ],
              [
                -53.70442725814449,
                -26.95956970553286
              ],
              [
                -53.70137124563305,
                -26.95555170188364
              ],
              [
                -53.69962378448589,
                -26.952129236172784
              ],
              [
                -53.7012728024612,
                -26.94961424406967
              ],
              [
                -53.70381380189085,
                -26.94573675749026
              ],
              [
                -53.70618077978285,
                -26.942046764687742
              ],
              [
                -53.70823978753535,
                -26.937143818548112
              ],
              [
                -53.70717133142262,
                -26.932361811582325
              ],
              [
                -53.69536538946492,
                -26.93727724599605
              ],
              [
                -53.689527408414634,
                -26.939754250879236
              ],
              [
                -53.68509847841638,
                -26.94163322578998
              ],
              [
                -53.678902004272636,
                -26.94472920642323
              ],
              [
                -53.672497060632715,
                -26.944991174157234
              ],
              [
                -53.67087607659509,
                -26.942429647238086
              ],
              [
                -53.67021208741349,
                -26.941379652093357
              ],
              [
                -53.68006499054405,
                -26.936590726138473
              ],
              [
                -53.68698645436776,
                -26.933017258649603
              ],
              [
                -53.69200842812519,
                -26.93042429114027
              ],
              [
                -53.69497641628034,
                -26.928087786310915
              ],
              [
                -53.69096797466259,
                -26.92347427707697
              ],
              [
                -53.684046057869686,
                -26.918036266775577
              ],
              [
                -53.68040408948941,
                -26.91517526441373
              ],
              [
                -53.67219369070586,
                -26.91231974733442
              ],
              [
                -53.67773463875134,
                -26.907915307393637
              ],
              [
                -53.67532719447478,
                -26.90099381028319
              ],
              [
                -53.67318071871585,
                -26.897260307141995
              ],
              [
                -53.67103874446207,
                -26.89296732077612
              ],
              [
                -53.67830224127969,
                -26.89057887980673
              ],
              [
                -53.678640238118874,
                -26.890490858317722
              ],
              [
                -53.68795466356248,
                -26.888068887947245
              ],
              [
                -53.69333863188287,
                -26.88628641920893
              ],
              [
                -53.690193700021254,
                -26.87565195206081
              ],
              [
                -53.67932479125394,
                -26.869286441337458
              ],
              [
                -53.679377818961406,
                -26.869262404606516
              ],
              [
                -53.69045324629516,
                -26.864367995407164
              ],
              [
                -53.693467208189205,
                -26.86244751243839
              ],
              [
                -53.694937221030486,
                -26.85760650899673
              ],
              [
                -53.68380228777335,
                -26.859717471721176
              ],
              [
                -53.67988935349454,
                -26.860359974420206
              ],
              [
                -53.67160341708564,
                -26.861720945901173
              ],
              [
                -53.66177548198461,
                -26.862285879256266
              ],
              [
                -53.65999947692793,
                -26.859092896807624
              ],
              [
                -53.669243463346234,
                -26.853039938559032
              ],
              [
                -53.67358396442856,
                -26.846653489464245
              ],
              [
                -53.67624543202488,
                -26.84273749448023
              ],
              [
                -53.683585892645574,
                -26.843793536941348
              ],
              [
                -53.68513488206316,
                -26.843981547002187
              ],
              [
                -53.68987933953242,
                -26.844556017508925
              ],
              [
                -53.69379827540202,
                -26.84531855541456
              ],
              [
                -53.69340281159377,
                -26.84086458495951
              ],
              [
                -53.6881828783029,
                -26.837294049132755
              ],
              [
                -53.68731337557892,
                -26.836700066827344
              ],
              [
                -53.68907588442587,
                -26.835334051548866
              ],
              [
                -53.69491085474137,
                -26.830813083038258
              ],
              [
                -53.69703432270794,
                -26.825949128067478
              ],
              [
                -53.69993584222382,
                -26.818117672262364
              ],
              [
                -53.70565483265782,
                -26.813764675796783
              ],
              [
                -53.699049904071074,
                -26.81344918570919
              ],
              [
                -53.69738788400988,
                -26.813369689900345
              ],
              [
                -53.6986484106166,
                -26.802954702250787
              ],
              [
                -53.69963996312005,
                -26.796765206336893
              ],
              [
                -53.70421990379285,
                -26.79691374055684
              ],
              [
                -53.70729537790125,
                -26.796830234025837
              ],
              [
                -53.71225990927651,
                -26.788666307796472
              ],
              [
                -53.71226188927967,
                -26.788662295816337
              ],
              [
                -53.71275288121563,
                -26.78785529814395
              ],
              [
                -53.713238887987565,
                -26.786871797008832
              ],
              [
                -53.713240377987454,
                -26.78686932602201
              ],
              [
                -53.71493838467371,
                -26.783435315012234
              ],
              [
                -53.71481091368119,
                -26.77944484510541
              ],
              [
                -53.70647050666891,
                -26.77415734637916
              ],
              [
                -53.70132053364875,
                -26.77127579170548
              ],
              [
                -53.69892257783153,
                -26.76883182167608
              ],
              [
                -53.70231854620634,
                -26.767267818964534
              ],
              [
                -53.71029597363298,
                -26.76687635423592
              ],
              [
                -53.71338995008506,
                -26.767794348608202
              ],
              [
                -53.71447393590096,
                -26.77199585932565
              ],
              [
                -53.7211168756722,
                -26.7724363551281
              ],
              [
                -53.728571337069646,
                -26.77293089371911
              ],
              [
                -53.73178182693312,
                -26.77068841446709
              ],
              [
                -53.74269123972001,
                -26.762224501824587
              ],
              [
                -53.736351320308664,
                -26.763404469026312
              ],
              [
                -53.72999285802871,
                -26.76458792415003
              ],
              [
                -53.72337390059194,
                -26.762480914685238
              ],
              [
                -53.716771964243236,
                -26.75744840650392
              ],
              [
                -53.714489530172294,
                -26.74821893428003
              ],
              [
                -53.72156897761539,
                -26.743794461979565
              ],
              [
                -53.72457199666433,
                -26.74096898280866
              ],
              [
                -53.72766600871221,
                -26.736440031337022
              ],
              [
                -53.72963650795814,
                -26.731089052370503
              ],
              [
                -53.73537341938295,
                -26.740445536753654
              ],
              [
                -53.741971854340925,
                -26.740484571638767
              ],
              [
                -53.74817628667039,
                -26.739551592153017
              ],
              [
                -53.747097811752184,
                -26.73646459295001
              ],
              [
                -53.745187375925504,
                -26.734306596796355
              ],
              [
                -53.74187339646506,
                -26.730428098959944
              ],
              [
                -53.74813939809081,
                -26.7248931131907
              ],
              [
                -53.75261384433711,
                -26.719561142264343
              ],
              [
                -53.753823400210706,
                -26.713570693541303
              ],
              [
                -53.75113139115421,
                -26.712326191676734
              ],
              [
                -53.74724340570558,
                -26.718496643090358
              ],
              [
                -53.74124195731844,
                -26.7173271389564
              ],
              [
                -53.739559987027306,
                -26.717105116541447
              ],
              [
                -53.73621401230131,
                -26.71666310577114
              ],
              [
                -53.732956507957525,
                -26.715544591457228
              ],
              [
                -53.73264356671875,
                -26.71146259719638
              ],
              [
                -53.7324120485892,
                -26.708550636542277
              ],
              [
                -53.7319375906944,
                -26.704439651790715
              ],
              [
                -53.73134761726426,
                -26.699530166143013
              ],
              [
                -53.733522085613174,
                -26.696914673298963
              ],
              [
                -53.73378912704095,
                -26.69659369128228
              ],
              [
                -53.74085607086947,
                -26.688994191943262
              ],
              [
                -53.7417760977993,
                -26.68413324563221
              ],
              [
                -53.737889131862886,
                -26.68242120592402
              ],
              [
                -53.73512666626044,
                -26.683636695054098
              ],
              [
                -53.73312712961796,
                -26.686247193548173
              ],
              [
                -53.73099817467669,
                -26.688474703781683
              ],
              [
                -53.7306261767341,
                -26.68886419820061
              ],
              [
                -53.72844664148229,
                -26.69124564576403
              ],
              [
                -53.7153237942324,
                -26.682225139620048
              ],
              [
                -53.7173387855937,
                -26.67920417748415
              ],
              [
                -53.72099724831966,
                -26.678010194537556
              ],
              [
                -53.72917622247338,
                -26.676243224810296
              ],
              [
                -53.72532126226694,
                -26.67164672634799
              ],
              [
                -53.72383728244893,
                -26.66653724811903
              ],
              [
                -53.71887834326694,
                -26.6664587303752
              ],
              [
                -53.71976940534934,
                -26.65181075595453
              ],
              [
                -53.7239098934541,
                -26.64771781392327
              ],
              [
                -53.72428585719509,
                -26.656493751515278
              ],
              [
                -53.72751330323387,
                -26.655065274740984
              ],
              [
                -53.73162678944269,
                -26.652332326306777
              ],
              [
                -53.73374683348177,
                -26.645419330867316
              ],
              [
                -53.73387678640224,
                -26.644995830678628
              ],
              [
                -53.742561264984296,
                -26.644737879988273
              ],
              [
                -53.754546175948335,
                -26.642190418511774
              ],
              [
                -53.75427518174292,
                -26.638460908323193
              ],
              [
                -53.749288215876476,
                -26.635010895153126
              ],
              [
                -53.74428625739542,
                -26.639073876162
              ],
              [
                -53.740728255200494,
                -26.64131386317337
              ],
              [
                -53.73953428613598,
                -26.636431398294395
              ],
              [
                -53.739405807631215,
                -26.6294074150621
              ],
              [
                -53.735035369935694,
                -26.62867037855561
              ],
              [
                -53.73252588487251,
                -26.626666907805788
              ],
              [
                -53.73186040028185,
                -26.626135403077633
              ],
              [
                -53.72962641729916,
                -26.62164340821261
              ],
              [
                -53.729655929588304,
                -26.619522416039644
              ],
              [
                -53.72971098408386,
                -26.61552790289699
              ],
              [
                -53.731382486947275,
                -26.61274340777607
              ],
              [
                -53.733196988931766,
                -26.608798463851222
              ],
              [
                -53.72807951367149,
                -26.60654393746625
              ],
              [
                -53.7285325385622,
                -26.60089497601938
              ],
              [
                -53.728161046606225,
                -26.59347396421362
              ],
              [
                -53.7308910779585,
                -26.586112507450622
              ],
              [
                -53.72397411710636,
                -26.588138004993024
              ],
              [
                -53.72287664751761,
                -26.58252451284104
              ],
              [
                -53.72045427494163,
                -26.559911585912136
              ],
              [
                -53.71716277055264,
                -26.560411573425007
              ],
              [
                -53.70769636959928,
                -26.5618480046418
              ],
              [
                -53.70593286746027,
                -26.55926451948365
              ],
              [
                -53.70865786204755,
                -26.555871545122034
              ],
              [
                -53.716847309515,
                -26.556701580078755
              ],
              [
                -53.71793631579862,
                -26.556812576661823
              ],
              [
                -53.72045427494163,
                -26.559911585912136
              ]
            ]
          ],
          [
            [
              [
                -48.41035023824584,
                -27.38942957994102
              ],
              [
                -48.41019570891466,
                -27.4038085133232
              ],
              [
                -48.40475320230014,
                -27.40599848965135
              ],
              [
                -48.39869871358214,
                -27.424100426164884
              ],
              [
                -48.398536198868264,
                -27.424585402820338
              ],
              [
                -48.38910222388801,
                -27.437017329719602
              ],
              [
                -48.38652973991854,
                -27.438093305958326
              ],
              [
                -48.38541326031021,
                -27.43856030141975
              ],
              [
                -48.37941831200163,
                -27.441068274678518
              ],
              [
                -48.37394433824913,
                -27.44335828652164
              ],
              [
                -48.3711493374838,
                -27.44435876227805
              ],
              [
                -48.36893192670944,
                -27.44050225993028
              ],
              [
                -48.36503047371715,
                -27.433474775539434
              ],
              [
                -48.36019100339161,
                -27.439084219298586
              ],
              [
                -48.358310473558774,
                -27.44133671255889
              ],
              [
                -48.35886098589688,
                -27.444837207519253
              ],
              [
                -48.35938945505311,
                -27.448189694952827
              ],
              [
                -48.3645374142451,
                -27.449693718997143
              ],
              [
                -48.369993366459575,
                -27.45128772476606
              ],
              [
                -48.3710033357038,
                -27.454372710840605
              ],
              [
                -48.37242978797736,
                -27.458728702654952
              ],
              [
                -48.37487374431001,
                -27.46619468549036
              ],
              [
                -48.37551017956286,
                -27.48186362393601
              ],
              [
                -48.382916118155464,
                -27.48582413269946
              ],
              [
                -48.385618049240726,
                -27.48725612267702
              ],
              [
                -48.38640605280625,
                -27.487674134835927
              ],
              [
                -48.390330522808085,
                -27.489754162616464
              ],
              [
                -48.39533444017586,
                -27.496372647399454
              ],
              [
                -48.413390204069245,
                -27.520752604658842
              ],
              [
                -48.41455370849211,
                -27.522324578875057
              ],
              [
                -48.415718670904056,
                -27.523896103098732
              ],
              [
                -48.425258521848015,
                -27.54190356673423
              ],
              [
                -48.426301992507916,
                -27.549438539679212
              ],
              [
                -48.42637746253465,
                -27.54997952295444
              ],
              [
                -48.42727095712014,
                -27.55643252936207
              ],
              [
                -48.42848191209296,
                -27.565181463453833
              ],
              [
                -48.42634541496322,
                -27.56937045076485
              ],
              [
                -48.424881423610515,
                -27.572070445842634
              ],
              [
                -48.42259442108157,
                -27.573639438236906
              ],
              [
                -48.42243991792627,
                -27.573744930319577
              ],
              [
                -48.41807347517266,
                -27.572710915108065
              ],
              [
                -48.41276552313201,
                -27.57288342924284
              ],
              [
                -48.41927340226702,
                -27.58329488445432
              ],
              [
                -48.41919387005079,
                -27.592969337752205
              ],
              [
                -48.426711793727414,
                -27.59435485552574
              ],
              [
                -48.43096825931216,
                -27.60094837103306
              ],
              [
                -48.435017671862845,
                -27.607257342882924
              ],
              [
                -48.43328217247293,
                -27.61233331234877
              ],
              [
                -48.43190965949996,
                -27.616837800155306
              ],
              [
                -48.43909012482563,
                -27.617804808330572
              ],
              [
                -48.440297084038384,
                -27.61933129590505
              ],
              [
                -48.440359618168856,
                -27.619410322831474
              ],
              [
                -48.44463702466562,
                -27.624821310782433
              ],
              [
                -48.44466354684983,
                -27.62495781637499
              ],
              [
                -48.445569514030716,
                -27.62960930050155
              ],
              [
                -48.44859098080154,
                -27.630019792406582
              ],
              [
                -48.4492879635212,
                -27.630114321461463
              ],
              [
                -48.45291590802059,
                -27.634247803861584
              ],
              [
                -48.465399275772036,
                -27.64854681469004
              ],
              [
                -48.470395167339376,
                -27.657370294736385
              ],
              [
                -48.475032116051686,
                -27.665701271362718
              ],
              [
                -48.47736555906689,
                -27.67565773114115
              ],
              [
                -48.479495989109495,
                -27.684617208868985
              ],
              [
                -48.480964980818975,
                -27.6907961654488
              ],
              [
                -48.48222145970725,
                -27.69229468333348
              ],
              [
                -48.48347891558247,
                -27.69379418221878
              ],
              [
                -48.50063771029082,
                -27.71415418146476
              ],
              [
                -48.50235864233166,
                -27.72090512788741
              ],
              [
                -48.503225659807676,
                -27.72439164122174
              ],
              [
                -48.50637356401242,
                -27.737048110157772
              ],
              [
                -48.50607102420802,
                -27.744908064587932
              ],
              [
                -48.50064304404259,
                -27.749914041642285
              ],
              [
                -48.49524705741196,
                -27.756337470664334
              ],
              [
                -48.493867087610184,
                -27.75597797019275
              ],
              [
                -48.487964156069744,
                -27.754439968346457
              ],
              [
                -48.48538913300083,
                -27.75896046503211
              ],
              [
                -48.48048618693081,
                -27.76042091776594
              ],
              [
                -48.47672472927637,
                -27.761645398306182
              ],
              [
                -48.475994185857815,
                -27.765206910853685
              ],
              [
                -48.47544620098663,
                -27.76920539444903
              ],
              [
                -48.482373630810805,
                -27.769157403601273
              ],
              [
                -48.48468858473513,
                -27.773171910054955
              ],
              [
                -48.48738955137949,
                -27.777876900346914
              ],
              [
                -48.48388605113363,
                -27.784220834216086
              ],
              [
                -48.488199527082635,
                -27.789631349548117
              ],
              [
                -48.49188749130409,
                -27.79115985678668
              ],
              [
                -48.49907490530629,
                -27.793539352071708
              ],
              [
                -48.50648036609681,
                -27.79030037846464
              ],
              [
                -48.50661187363788,
                -27.78261943074039
              ],
              [
                -48.506783878353026,
                -27.7825819184722
              ],
              [
                -48.51556879576751,
                -27.780658984874695
              ],
              [
                -48.52102226625224,
                -27.78330197620098
              ],
              [
                -48.52616421344004,
                -27.785926990518742
              ],
              [
                -48.528122683806394,
                -27.78846847710858
              ],
              [
                -48.53007164030166,
                -27.79099845370938
              ],
              [
                -48.53214413004874,
                -27.793689464158266
              ],
              [
                -48.53328257910442,
                -27.797191457437147
              ],
              [
                -48.53881447580126,
                -27.814219402059948
              ],
              [
                -48.54139842949781,
                -27.822085898603888
              ],
              [
                -48.543981894188946,
                -27.82995086916618
              ],
              [
                -48.55895219307268,
                -27.838602896930993
              ],
              [
                -48.56025972014311,
                -27.83426240013506
              ],
              [
                -48.56963064485609,
                -27.835712931457206
              ],
              [
                -48.569573125226306,
                -27.835511452984154
              ],
              [
                -48.56549673169033,
                -27.821372490846745
              ],
              [
                -48.5588498409925,
                -27.81150498140196
              ],
              [
                -48.56479581128062,
                -27.8025415434193
              ],
              [
                -48.56637580873922,
                -27.80009003575954
              ],
              [
                -48.566449290620774,
                -27.799976075426265
              ],
              [
                -48.56631079950856,
                -27.799811555540106
              ],
              [
                -48.559733903126855,
                -27.79200407693821
              ],
              [
                -48.56983733266053,
                -27.785716594714
              ],
              [
                -48.574038844029694,
                -27.777796680918808
              ],
              [
                -48.5738103574943,
                -27.774935688471146
              ],
              [
                -48.57349637335272,
                -27.771007679587445
              ],
              [
                -48.573150394178825,
                -27.766691204000615
              ],
              [
                -48.57591887928172,
                -27.764774716530876
              ],
              [
                -48.58023535459118,
                -27.761664729744552
              ],
              [
                -48.56967897011079,
                -27.758536196474108
              ],
              [
                -48.55950657445413,
                -27.75059970895941
              ],
              [
                -48.558637622010195,
                -27.74505971301444
              ],
              [
                -48.55809763160651,
                -27.74161922160785
              ],
              [
                -48.55854914085162,
                -27.7377227502889
              ],
              [
                -48.56081413577796,
                -27.729036805608132
              ],
              [
                -48.56252714007576,
                -27.722469333343895
              ],
              [
                -48.56369915736022,
                -27.71740236177088
              ],
              [
                -48.56204916261102,
                -27.714799833498578
              ],
              [
                -48.56148519080427,
                -27.71401034141039
              ],
              [
                -48.55263980322108,
                -27.701642883684336
              ],
              [
                -48.55033432951244,
                -27.698229884101533
              ],
              [
                -48.55340984832258,
                -27.696698913293226
              ],
              [
                -48.55853931432294,
                -27.69414493330019
              ],
              [
                -48.56230527323987,
                -27.69561344499182
              ],
              [
                -48.564417731876986,
                -27.696437426306503
              ],
              [
                -48.566560759811075,
                -27.689573467629117
              ],
              [
                -48.57091921778209,
                -27.68713945753357
              ],
              [
                -48.56646629648049,
                -27.67905749648214
              ],
              [
                -48.56892725079412,
                -27.67630450398538
              ],
              [
                -48.57110877709926,
                -27.672945558723537
              ],
              [
                -48.5659358096901,
                -27.672235006428007
              ],
              [
                -48.562841848481085,
                -27.665174054392367
              ],
              [
                -48.55943140466874,
                -27.659318562894722
              ],
              [
                -48.55770945702295,
                -27.65636253869486
              ],
              [
                -48.55323353471831,
                -27.649459053346582
              ],
              [
                -48.54463710497556,
                -27.64578954102482
              ],
              [
                -48.54260161819532,
                -27.645076565594763
              ],
              [
                -48.541806648094806,
                -27.644797544866805
              ],
              [
                -48.535611160274804,
                -27.64661851085938
              ],
              [
                -48.52826072769891,
                -27.64877900963094
              ],
              [
                -48.523766779841495,
                -27.65007998538825
              ],
              [
                -48.52562033838852,
                -27.632575037534217
              ],
              [
                -48.52677837424711,
                -27.622544563076897
              ],
              [
                -48.52795938493128,
                -27.614116612868866
              ],
              [
                -48.53515533413481,
                -27.609578657213767
              ],
              [
                -48.5408462504016,
                -27.61346615565998
              ],
              [
                -48.54486123772908,
                -27.616209167506696
              ],
              [
                -48.549742172609655,
                -27.612317180515408
              ],
              [
                -48.549470703426266,
                -27.608971691769646
              ],
              [
                -48.549157245840824,
                -27.6051086967672
              ],
              [
                -48.55624619964203,
                -27.60087626262685
              ],
              [
                -48.559114161699235,
                -27.598767273198145
              ],
              [
                -48.56090063739315,
                -27.597453264144445
              ],
              [
                -48.563923157555806,
                -27.593178826140313
              ],
              [
                -48.56219017728588,
                -27.589454314744703
              ],
              [
                -48.545001851705116,
                -27.583006269047974
              ],
              [
                -48.54287490839943,
                -27.575669791495724
              ],
              [
                -48.5391804239551,
                -27.57278528430557
              ],
              [
                -48.53633196986164,
                -27.570716815052286
              ],
              [
                -48.53265699121627,
                -27.57130126726873
              ],
              [
                -48.52621705790593,
                -27.572326254360387
              ],
              [
                -48.51722712833393,
                -27.57374521523853
              ],
              [
                -48.516519652554905,
                -27.56791274311342
              ],
              [
                -48.5201206440219,
                -27.56451026893561
              ],
              [
                -48.52309761898556,
                -27.561511789134492
              ],
              [
                -48.517875677534725,
                -27.559975796784453
              ],
              [
                -48.51475120396359,
                -27.55624329168231
              ],
              [
                -48.511015256539764,
                -27.55158430887115
              ],
              [
                -48.508109812168776,
                -27.547609300421104
              ],
              [
                -48.521688702358894,
                -27.544528351373952
              ],
              [
                -48.52531618920798,
                -27.542139893580536
              ],
              [
                -48.53012613948917,
                -27.538973397697422
              ],
              [
                -48.52709220085417,
                -27.534842909374117
              ],
              [
                -48.5244802406937,
                -27.530937898682073
              ],
              [
                -48.52695671667661,
                -27.524741922864173
              ],
              [
                -48.523217779077356,
                -27.52266392862198
              ],
              [
                -48.51779083671902,
                -27.519647444020194
              ],
              [
                -48.515045392011366,
                -27.514273466268644
              ],
              [
                -48.51724738739663,
                -27.51128996675779
              ],
              [
                -48.52312132541561,
                -27.504258018684766
              ],
              [
                -48.5240298564013,
                -27.499020045992367
              ],
              [
                -48.52477287265844,
                -27.494735038243014
              ],
              [
                -48.528104324705886,
                -27.492642577345972
              ],
              [
                -48.53005683259733,
                -27.49141657654053
              ],
              [
                -48.53226732102395,
                -27.490028085215844
              ],
              [
                -48.539000751022016,
                -27.49036410009966
              ],
              [
                -48.53881778344587,
                -27.485756611351533
              ],
              [
                -48.53851180838236,
                -27.478023634639367
              ],
              [
                -48.53672334077724,
                -27.475618139319625
              ],
              [
                -48.53298539927683,
                -27.47072313944192
              ],
              [
                -48.53040990808673,
                -27.47021715946259
              ],
              [
                -48.52802792711065,
                -27.469748634010358
              ],
              [
                -48.52661249200786,
                -27.463714672289413
              ],
              [
                -48.5227495160453,
                -27.464504657144204
              ],
              [
                -48.51809552418176,
                -27.465421652820798
              ],
              [
                -48.51953704270389,
                -27.461562667924483
              ],
              [
                -48.52309604886979,
                -27.45203268110377
              ],
              [
                -48.52784750219404,
                -27.451748709433392
              ],
              [
                -48.530453970980446,
                -27.451592705941053
              ],
              [
                -48.536126924752836,
                -27.451499235732474
              ],
              [
                -48.53918790250534,
                -27.45162676375922
              ],
              [
                -48.54717082439839,
                -27.458377735371386
              ],
              [
                -48.540217893705325,
                -27.44887677338094
              ],
              [
                -48.52598755657142,
                -27.444373748132243
              ],
              [
                -48.52400357503936,
                -27.439772242231243
              ],
              [
                -48.52119514764484,
                -27.43326026153024
              ],
              [
                -48.52028967715574,
                -27.431189767036035
              ],
              [
                -48.51938418366578,
                -27.429119257542983
              ],
              [
                -48.511285740860544,
                -27.43209676086511
              ],
              [
                -48.49872883346519,
                -27.43671319489438
              ],
              [
                -48.48311442960041,
                -27.43788563711845
              ],
              [
                -48.47945901066315,
                -27.433153641009927
              ],
              [
                -48.47647854629212,
                -27.429295655003315
              ],
              [
                -48.45266172958351,
                -27.426938562192287
              ],
              [
                -48.450315257440096,
                -27.42667505313665
              ],
              [
                -48.447969786288965,
                -27.42641153408494
              ],
              [
                -48.42817350067871,
                -27.416641533404512
              ],
              [
                -48.42760802791266,
                -27.41233553535025
              ],
              [
                -48.426876038472855,
                -27.406647043829942
              ],
              [
                -48.4264810659555,
                -27.403578559800316
              ],
              [
                -48.429720047966285,
                -27.400257585117792
              ],
              [
                -48.43036652218832,
                -27.404068082269056
              ],
              [
                -48.43484202953711,
                -27.392881140830777
              ],
              [
                -48.43107609015184,
                -27.387525135736198
              ],
              [
                -48.422841134218004,
                -27.389663129724248
              ],
              [
                -48.41825169091857,
                -27.38403513583708
              ],
              [
                -48.41602771129636,
                -27.381308641316206
              ],
              [
                -48.410281752886625,
                -27.38427907473165
              ],
              [
                -48.41035023824584,
                -27.38942957994102
              ]
            ]
          ],
          [
            [
              [
                -48.56536760730909,
                -27.477238248696395
              ],
              [
                -48.56214864888088,
                -27.47386573499846
              ],
              [
                -48.563643106938805,
                -27.47951923942249
              ],
              [
                -48.56536760730909,
                -27.477238248696395
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 25,
        "nome": "Sergipe",
        "sigla": "SE",
        "regiao_id": 4,
        "codigo_ibg": 28
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -37.96006282194677,
                -9.532816335198653
              ],
              [
                -37.91674471190245,
                -9.548536708937501
              ],
              [
                -37.89363791615191,
                -9.539957719833584
              ],
              [
                -37.893396896977514,
                -9.540257234373737
              ],
              [
                -37.88577145572284,
                -9.549747665442357
              ],
              [
                -37.841631815934285,
                -9.579802404905468
              ],
              [
                -37.83691937386963,
                -9.583435372294915
              ],
              [
                -37.83271137293834,
                -9.594466294284949
              ],
              [
                -37.825392935335636,
                -9.591652776934078
              ],
              [
                -37.823374965920394,
                -9.590876806700171
              ],
              [
                -37.817849008085254,
                -9.59247227240315
              ],
              [
                -37.80836509513955,
                -9.607073144675919
              ],
              [
                -37.80666858049138,
                -9.609685135615662
              ],
              [
                -37.79968314887801,
                -9.614936085741492
              ],
              [
                -37.792698733261105,
                -9.620185552876722
              ],
              [
                -37.788971225613345,
                -9.628696966029414
              ],
              [
                -37.7848302466603,
                -9.638153416764366
              ],
              [
                -37.781552801525095,
                -9.638226894970702
              ],
              [
                -37.77827583538679,
                -9.638300411177505
              ],
              [
                -37.75975200868645,
                -9.630629934755442
              ],
              [
                -37.75152357378591,
                -9.627222953904358
              ],
              [
                -37.747830103159615,
                -9.626476482792922
              ],
              [
                -37.74413863451692,
                -9.625729973684729
              ],
              [
                -37.711365421904084,
                -9.630879406040048
              ],
              [
                -37.698983506609665,
                -9.637573838072942
              ],
              [
                -37.68660164033021,
                -9.644268275098826
              ],
              [
                -37.67044625714338,
                -9.656221201319614
              ],
              [
                -37.66699426485742,
                -9.66299011362064
              ],
              [
                -37.665695782777085,
                -9.66553608342338
              ],
              [
                -37.664375315849455,
                -9.6681230728998
              ],
              [
                -37.659924826419505,
                -9.676847022693618
              ],
              [
                -37.656431860890926,
                -9.683216452925508
              ],
              [
                -37.58141744782806,
                -9.73355002154288
              ],
              [
                -37.57675198640369,
                -9.735259521200657
              ],
              [
                -37.57208655498141,
                -9.736968986857955
              ],
              [
                -37.548217743271216,
                -9.741001439137593
              ],
              [
                -37.46645195461728,
                -9.749100802586174
              ],
              [
                -37.41856336108814,
                -9.762173165735469
              ],
              [
                -37.407876939338394,
                -9.769645631512688
              ],
              [
                -37.39719054659836,
                -9.77711804728456
              ],
              [
                -37.365349326543914,
                -9.785666455984314
              ],
              [
                -37.362402317904284,
                -9.78645745516442
              ],
              [
                -37.35644538619391,
                -9.789020432160353
              ],
              [
                -37.35048895548284,
                -9.791583413155488
              ],
              [
                -37.34526296482973,
                -9.796576869786797
              ],
              [
                -37.32070818412243,
                -9.820038690699688
              ],
              [
                -37.302243838641225,
                -9.82546812187012
              ],
              [
                -37.2967963704154,
                -9.827070074511463
              ],
              [
                -37.285004963674915,
                -9.830535072954275
              ],
              [
                -37.26378563849397,
                -9.850010880789267
              ],
              [
                -37.24555628531029,
                -9.885634111424425
              ],
              [
                -37.237491855064,
                -9.89343907826419
              ],
              [
                -37.237060352475545,
                -9.893856538725275
              ],
              [
                -37.22880891686948,
                -9.89776749534901
              ],
              [
                -37.2261654181025,
                -9.899020499373883
              ],
              [
                -37.196209184496404,
                -9.898125992998324
              ],
              [
                -37.15254556073533,
                -9.900427930121115
              ],
              [
                -37.132688260762286,
                -9.913935320622375
              ],
              [
                -37.075106686662416,
                -9.965853871128594
              ],
              [
                -37.04926192119671,
                -9.986185694700401
              ],
              [
                -37.04334745565941,
                -9.987419690554274
              ],
              [
                -37.03743502610779,
                -9.988653674409985
              ],
              [
                -37.0190671953193,
                -9.981399207968519
              ],
              [
                -37.0096047785302,
                -9.977661252308986
              ],
              [
                -37.00091284364804,
                -9.976101257200924
              ],
              [
                -36.991600951018825,
                -9.9767757138186
              ],
              [
                -36.96668461814521,
                -9.993564578624525
              ],
              [
                -36.96390417157347,
                -9.999800018346912
              ],
              [
                -36.96112467299186,
                -10.006034460079585
              ],
              [
                -36.957318181396474,
                -10.02024188636095
              ],
              [
                -36.95725568800574,
                -10.026037826108796
              ],
              [
                -36.95699368688213,
                -10.027461825234157
              ],
              [
                -36.957692182615766,
                -10.036254240431447
              ],
              [
                -36.958516159595824,
                -10.046626171002057
              ],
              [
                -36.954001167598676,
                -10.070214980767242
              ],
              [
                -36.951971187481625,
                -10.074323984117127
              ],
              [
                -36.9474567173582,
                -10.083456408550624
              ],
              [
                -36.944365249498404,
                -10.089711334851376
              ],
              [
                -36.94412625413368,
                -10.0901938640169
              ],
              [
                -36.94302775264604,
                -10.092415319365935
              ],
              [
                -36.94066075532492,
                -10.094790807281152
              ],
              [
                -36.93014586030807,
                -10.105340719076164
              ],
              [
                -36.9223089204099,
                -10.123584570328408
              ],
              [
                -36.92227091265817,
                -10.123672588634763
              ],
              [
                -36.92067643705076,
                -10.127386036378429
              ],
              [
                -36.920639938289696,
                -10.127470047716123
              ],
              [
                -36.910463514774314,
                -10.137513474620954
              ],
              [
                -36.89490815185179,
                -10.141264938863689
              ],
              [
                -36.8915866416981,
                -10.142065927520271
              ],
              [
                -36.891172672293216,
                -10.14216593735439
              ],
              [
                -36.88126827031707,
                -10.14455488348887
              ],
              [
                -36.87655080229233,
                -10.14569437120391
              ],
              [
                -36.864619397396986,
                -10.15776979717023
              ],
              [
                -36.85035352178834,
                -10.161748740014866
              ],
              [
                -36.84714403348091,
                -10.17618611728372
              ],
              [
                -36.84635754627841,
                -10.179296111336217
              ],
              [
                -36.84633354239631,
                -10.179389095619717
              ],
              [
                -36.8449460200935,
                -10.184876067371167
              ],
              [
                -36.843006537452375,
                -10.192543994333471
              ],
              [
                -36.836552586573305,
                -10.201748437252514
              ],
              [
                -36.83283213723938,
                -10.204246921858791
              ],
              [
                -36.81087278926891,
                -10.218991275689618
              ],
              [
                -36.776430083648705,
                -10.225497176022522
              ],
              [
                -36.75186382920791,
                -10.235389112249413
              ],
              [
                -36.75140679753261,
                -10.240914054656686
              ],
              [
                -36.75587075498587,
                -10.243390534797804
              ],
              [
                -36.75632427366342,
                -10.243642057390607
              ],
              [
                -36.75268431021679,
                -10.2484579948194
              ],
              [
                -36.749301301823884,
                -10.249107505518042
              ],
              [
                -36.736136919046935,
                -10.251634487217789
              ],
              [
                -36.72629301404292,
                -10.25943441307027
              ],
              [
                -36.71993955467836,
                -10.264468344086376
              ],
              [
                -36.702752228318694,
                -10.269540302703415
              ],
              [
                -36.68310740433412,
                -10.27334577022374
              ],
              [
                -36.68063292396117,
                -10.270996801176457
              ],
              [
                -36.66885050978611,
                -10.26788927824155
              ],
              [
                -36.657592614777045,
                -10.264919804827771
              ],
              [
                -36.64317477178138,
                -10.252667891360813
              ],
              [
                -36.64268677858745,
                -10.252253388950916
              ],
              [
                -36.628844904931015,
                -10.254879375229217
              ],
              [
                -36.623312427559505,
                -10.25773031032406
              ],
              [
                -36.61140603943511,
                -10.271041228936037
              ],
              [
                -36.59078720978271,
                -10.286913586530524
              ],
              [
                -36.58850369713031,
                -10.301394461278338
              ],
              [
                -36.58287324775427,
                -10.307019412590256
              ],
              [
                -36.571365844961115,
                -10.318515347124448
              ],
              [
                -36.56892787462025,
                -10.325403281303474
              ],
              [
                -36.566489849275314,
                -10.332292229478165
              ],
              [
                -36.567302336518395,
                -10.365104476962918
              ],
              [
                -36.56031484689094,
                -10.39295277346597
              ],
              [
                -36.563759790723516,
                -10.415908125243675
              ],
              [
                -36.55385538922784,
                -10.422907554913834
              ],
              [
                -36.55130791919193,
                -10.424708544876344
              ],
              [
                -36.54510698122556,
                -10.429090998848078
              ],
              [
                -36.54248749058878,
                -10.42640300212716
              ],
              [
                -36.53812603048294,
                -10.421927045905742
              ],
              [
                -36.51073929717554,
                -10.421212012861268
              ],
              [
                -36.51885422006768,
                -10.42426500354387
              ],
              [
                -36.521036188852655,
                -10.430524456227685
              ],
              [
                -36.52178066790578,
                -10.432661461099269
              ],
              [
                -36.51119277425869,
                -10.432415443954552
              ],
              [
                -36.509581788767974,
                -10.432377958563846
              ],
              [
                -36.5011918650699,
                -10.428650976607786
              ],
              [
                -36.48835148344461,
                -10.42294749576934
              ],
              [
                -36.46307772508685,
                -10.406299578623486
              ],
              [
                -36.459410238562185,
                -10.406756102435923
              ],
              [
                -36.45574276803944,
                -10.407212077252554
              ],
              [
                -36.43433047008013,
                -10.420886477261378
              ],
              [
                -36.43111198688499,
                -10.423852432838617
              ],
              [
                -36.420405579304735,
                -10.444539767657497
              ],
              [
                -36.402995189694856,
                -10.486277929797005
              ],
              [
                -36.39358023207438,
                -10.497878334656257
              ],
              [
                -36.393427238250545,
                -10.498066845093957
              ],
              [
                -36.39341274160643,
                -10.503505799593043
              ],
              [
                -36.399428169991964,
                -10.511508253294169
              ],
              [
                -36.39949219424314,
                -10.519193693172388
              ],
              [
                -36.40578661568038,
                -10.517260234115568
              ],
              [
                -36.41955298012597,
                -10.51889269431608
              ],
              [
                -36.42934242785304,
                -10.52005370688591
              ],
              [
                -36.43022441981041,
                -10.520158231028235
              ],
              [
                -36.43516235578434,
                -10.520743203827125
              ],
              [
                -36.4380128113082,
                -10.521536733898982
              ],
              [
                -36.45111319163273,
                -10.525185683436437
              ],
              [
                -36.45624717695218,
                -10.52661567716499
              ],
              [
                -36.460999116821625,
                -10.527939203288451
              ],
              [
                -36.46118812406656,
                -10.527991675095796
              ],
              [
                -36.465002586643415,
                -10.529054188182155
              ],
              [
                -36.4714465428039,
                -10.530849159573664
              ],
              [
                -36.492480315757504,
                -10.535534184751375
              ],
              [
                -36.49722427746086,
                -10.538028636183427
              ],
              [
                -36.50206574791481,
                -10.539937636069983
              ],
              [
                -36.51727710482298,
                -10.5459361154512
              ],
              [
                -36.52810100701257,
                -10.550204093107382
              ],
              [
                -36.534592962952,
                -10.552764092911566
              ],
              [
                -36.54183089587735,
                -10.555619050314736
              ],
              [
                -36.55116381210548,
                -10.559298542805722
              ],
              [
                -36.57872701790636,
                -10.57017548908455
              ],
              [
                -36.601107311991505,
                -10.580390926902341
              ],
              [
                -36.610643743700685,
                -10.584743896688671
              ],
              [
                -36.610835754882864,
                -10.58483140524258
              ],
              [
                -36.61998863922851,
                -10.589008880938202
              ],
              [
                -36.63991294524905,
                -10.603995791958624
              ],
              [
                -36.64670490278299,
                -10.609104265311451
              ],
              [
                -36.64804787964128,
                -10.610113760257432
              ],
              [
                -36.65752480889922,
                -10.617242213513745
              ],
              [
                -36.680347561637525,
                -10.633408615072074
              ],
              [
                -36.684040557938864,
                -10.634857602271971
              ],
              [
                -36.68773451023133,
                -10.636307606467014
              ],
              [
                -36.70443133036425,
                -10.64286009774173
              ],
              [
                -36.714898270036045,
                -10.646967551500017
              ],
              [
                -36.728598119346636,
                -10.6523445403296
              ],
              [
                -36.73995999939588,
                -10.659772461539449
              ],
              [
                -36.75237491325712,
                -10.66788896281411
              ],
              [
                -36.76380077769162,
                -10.67535887884026
              ],
              [
                -36.763804786652926,
                -10.675361411825822
              ],
              [
                -36.77522766411862,
                -10.682829349891465
              ],
              [
                -36.807470403078334,
                -10.703957218496601
              ],
              [
                -36.8098213358925,
                -10.705894230729678
              ],
              [
                -36.81354782103983,
                -10.70905620440563
              ],
              [
                -36.823035710209396,
                -10.71710614522837
              ],
              [
                -36.849238976968415,
                -10.739421496935742
              ],
              [
                -36.85375993338582,
                -10.743137998425613
              ],
              [
                -36.8568903975373,
                -10.746598970305241
              ],
              [
                -36.86799329420368,
                -10.758301389099493
              ],
              [
                -36.874905728262284,
                -10.765586862926106
              ],
              [
                -36.90704539201398,
                -10.799462636471628
              ],
              [
                -36.9140428211894,
                -10.806838584829721
              ],
              [
                -36.92937616298037,
                -10.822999958700894
              ],
              [
                -36.93684609635969,
                -10.830873936974966
              ],
              [
                -36.937005108717784,
                -10.831070413704678
              ],
              [
                -36.94594450141321,
                -10.84210984635486
              ],
              [
                -36.953151420953226,
                -10.85104230062214
              ],
              [
                -36.95732541482573,
                -10.856215284199605
              ],
              [
                -36.95810038681809,
                -10.857175784994707
              ],
              [
                -36.96382132770203,
                -10.86426622419904
              ],
              [
                -36.986228602816105,
                -10.893198007472176
              ],
              [
                -36.98993506228633,
                -10.897983500445836
              ],
              [
                -36.99364202674933,
                -10.902769476423522
              ],
              [
                -36.99553651572178,
                -10.905510951405496
              ],
              [
                -37.0219467594624,
                -10.943714682550395
              ],
              [
                -37.02583619132312,
                -10.949341170640515
              ],
              [
                -37.02576870073935,
                -10.963671071668191
              ],
              [
                -37.05563941104867,
                -10.99768283488718
              ],
              [
                -37.06196183124449,
                -11.005840775253768
              ],
              [
                -37.065882800461075,
                -11.011753745383045
              ],
              [
                -37.067991272990994,
                -11.014933243485821
              ],
              [
                -37.068348264185666,
                -11.015472227943107
              ],
              [
                -37.07118220820995,
                -11.019545692322366
              ],
              [
                -37.07346370507525,
                -11.022826161886567
              ],
              [
                -37.07629118616331,
                -11.026891645325486
              ],
              [
                -37.07849015931026,
                -11.02969264430746
              ],
              [
                -37.08564807331519,
                -11.040213575424554
              ],
              [
                -37.08881404951146,
                -11.045031048769696
              ],
              [
                -37.0923715295243,
                -11.050444485206373
              ],
              [
                -37.09825695014099,
                -11.058990429381263
              ],
              [
                -37.104944875698116,
                -11.068303373875768
              ],
              [
                -37.10765384216046,
                -11.072077372362228
              ],
              [
                -37.10884935952981,
                -11.073774347315895
              ],
              [
                -37.117645274583865,
                -11.086266752017528
              ],
              [
                -37.12643414981983,
                -11.098641662613145
              ],
              [
                -37.13054011101688,
                -11.104823659144166
              ],
              [
                -37.135185573153194,
                -11.112948600129997
              ],
              [
                -37.140701008755926,
                -11.122594023030599
              ],
              [
                -37.14274499154525,
                -11.125148508713743
              ],
              [
                -37.15701334781204,
                -11.157399275884014
              ],
              [
                -37.15142588914227,
                -11.162252268256937
              ],
              [
                -37.15979628879493,
                -11.17135920640741
              ],
              [
                -37.16159177048618,
                -11.173313188212928
              ],
              [
                -37.16159377246536,
                -11.173315689197027
              ],
              [
                -37.16338725417768,
                -11.175266655023554
              ],
              [
                -37.20575583303475,
                -11.221383386620698
              ],
              [
                -37.20967378647528,
                -11.226153846404864
              ],
              [
                -37.209679798412814,
                -11.226161337357283
              ],
              [
                -37.213591739914285,
                -11.230923824199834
              ],
              [
                -37.25551731774487,
                -11.281966999432168
              ],
              [
                -37.26005430164551,
                -11.28757697292764
              ],
              [
                -37.264323723270905,
                -11.294602404863635
              ],
              [
                -37.279398068433814,
                -11.321153215679272
              ],
              [
                -37.281755065495965,
                -11.325305232299987
              ],
              [
                -37.28411251955274,
                -11.329456682929854
              ],
              [
                -37.29270292199769,
                -11.344587063212357
              ],
              [
                -37.29635089892656,
                -11.356013514677077
              ],
              [
                -37.297737868408326,
                -11.360355980838433
              ],
              [
                -37.29861287735306,
                -11.36309745900206
              ],
              [
                -37.3047643116127,
                -11.382365815655673
              ],
              [
                -37.30901023492796,
                -11.39566522235149
              ],
              [
                -37.30969172431186,
                -11.397797712715409
              ],
              [
                -37.312575690832915,
                -11.406831173722413
              ],
              [
                -37.31626763812065,
                -11.418394564391923
              ],
              [
                -37.318753636658485,
                -11.426180036995289
              ],
              [
                -37.32504406844892,
                -11.431255012608476
              ],
              [
                -37.32953501237181,
                -11.444660407961319
              ],
              [
                -37.33947640972439,
                -11.443730402219478
              ],
              [
                -37.342502905042956,
                -11.443447934773356
              ],
              [
                -37.3482323505378,
                -11.442910961303953
              ],
              [
                -37.35836874028793,
                -11.445233431303798
              ],
              [
                -37.37146763854828,
                -11.45513787602142
              ],
              [
                -37.37270361689985,
                -11.45649135768918
              ],
              [
                -37.39405089841355,
                -11.479871713851974
              ],
              [
                -37.411483245126256,
                -11.494665621521007
              ],
              [
                -37.44122195222968,
                -11.514304012785816
              ],
              [
                -37.44387342047313,
                -11.516054504282181
              ],
              [
                -37.45317383741283,
                -11.518639989557363
              ],
              [
                -37.45321433103787,
                -11.518651494521995
              ],
              [
                -37.462473745363155,
                -11.521226022836439
              ],
              [
                -37.50514085892284,
                -11.525422510692119
              ],
              [
                -37.51407779550173,
                -11.53351097099789
              ],
              [
                -37.51473476042972,
                -11.53617244862522
              ],
              [
                -37.51511078923603,
                -11.537696431105346
              ],
              [
                -37.517547760562316,
                -11.547579359893138
              ],
              [
                -37.55258193124528,
                -11.546875399199509
              ],
              [
                -37.55583241378424,
                -11.551418369376714
              ],
              [
                -37.56208984382077,
                -11.54252244168467
              ],
              [
                -37.56985526550423,
                -11.540168986744417
              ],
              [
                -37.57461723781871,
                -11.543696939999744
              ],
              [
                -37.572092751083524,
                -11.548003927075507
              ],
              [
                -37.578166706335516,
                -11.551015930593223
              ],
              [
                -37.585292663396196,
                -11.541510989285035
              ],
              [
                -37.58377965013415,
                -11.539641990932159
              ],
              [
                -37.578545232579025,
                -11.53317552023867
              ],
              [
                -37.588374638081014,
                -11.52819258662092
              ],
              [
                -37.60096103067741,
                -11.538379542246116
              ],
              [
                -37.602734009153714,
                -11.539814001030548
              ],
              [
                -37.605427488310504,
                -11.53805101286979
              ],
              [
                -37.60856243088805,
                -11.535999054304929
              ],
              [
                -37.612383409840895,
                -11.536850525691454
              ],
              [
                -37.6155748735684,
                -11.537562059342333
              ],
              [
                -37.6193878398207,
                -11.534927552759713
              ],
              [
                -37.62776630326037,
                -11.52913960620662
              ],
              [
                -37.63163777124316,
                -11.518043698471066
              ],
              [
                -37.63708319828045,
                -11.51763322083102
              ],
              [
                -37.642938171721845,
                -11.523790677500667
              ],
              [
                -37.64829310469513,
                -11.51997521121164
              ],
              [
                -37.65058259830016,
                -11.518343731625642
              ],
              [
                -37.65266558300211,
                -11.528052653359703
              ],
              [
                -37.656878524292175,
                -11.532362103386705
              ],
              [
                -37.66439194470743,
                -11.532971099878095
              ],
              [
                -37.66150146500127,
                -11.543205541004442
              ],
              [
                -37.66242296111464,
                -11.544595525116366
              ],
              [
                -37.67175235002305,
                -11.558656450244978
              ],
              [
                -37.669876871729095,
                -11.56357139976667
              ],
              [
                -37.674401332061215,
                -11.567198889099473
              ],
              [
                -37.683482740994215,
                -11.561677448450567
              ],
              [
                -37.68816570731115,
                -11.565806905376157
              ],
              [
                -37.69956511853575,
                -11.56201645305371
              ],
              [
                -37.698049632784404,
                -11.554811017934426
              ],
              [
                -37.69597665620771,
                -11.544954550182448
              ],
              [
                -37.702997593537354,
                -11.540209109522277
              ],
              [
                -37.71004752418672,
                -11.549798063079841
              ],
              [
                -37.71142501365718,
                -11.551672518266988
              ],
              [
                -37.71893395206894,
                -11.549881540994305
              ],
              [
                -37.72667638121468,
                -11.552360061385698
              ],
              [
                -37.72907637366799,
                -11.541849139590243
              ],
              [
                -37.73003287525878,
                -11.537660163760325
              ],
              [
                -37.738798788180155,
                -11.537788156207684
              ],
              [
                -37.74756671009156,
                -11.537916178659755
              ],
              [
                -37.75199667687536,
                -11.532854717187252
              ],
              [
                -37.75903914207227,
                -11.5248077952078
              ],
              [
                -37.76497759241841,
                -11.528003285317766
              ],
              [
                -37.770371017965694,
                -11.53090525589334
              ],
              [
                -37.773423492476546,
                -11.526273806702543
              ],
              [
                -37.77496501462301,
                -11.523935813285
              ],
              [
                -37.77878497600577,
                -11.523671810693934
              ],
              [
                -37.78994787194431,
                -11.523584828517956
              ],
              [
                -37.794926833400716,
                -11.518088386799592
              ],
              [
                -37.79932877500807,
                -11.520797340590086
              ],
              [
                -37.813349659904965,
                -11.513873924785763
              ],
              [
                -37.81837762497419,
                -11.503409512721607
              ],
              [
                -37.80874174208963,
                -11.496367534817434
              ],
              [
                -37.81407567409026,
                -11.491946074810416
              ],
              [
                -37.82425308375165,
                -11.49110009088772
              ],
              [
                -37.83169852907383,
                -11.483333650345621
              ],
              [
                -37.8326530538076,
                -11.482338147607917
              ],
              [
                -37.83943097293402,
                -11.481271689249455
              ],
              [
                -37.84759591186046,
                -11.469395772008268
              ],
              [
                -37.85726331089133,
                -11.4695382873858
              ],
              [
                -37.86276830280311,
                -11.464783316958647
              ],
              [
                -37.862477319344976,
                -11.453256886242269
              ],
              [
                -37.85649483046637,
                -11.452401406330404
              ],
              [
                -37.85304687966015,
                -11.451908411805467
              ],
              [
                -37.85111292069396,
                -11.44870843047496
              ],
              [
                -37.84865293042921,
                -11.4446529306638
              ],
              [
                -37.85020942590441,
                -11.440278492868245
              ],
              [
                -37.865105297956056,
                -11.441743965882347
              ],
              [
                -37.8676172493701,
                -11.441990964067202
              ],
              [
                -37.868703763414175,
                -11.437290028053663
              ],
              [
                -37.86888527775389,
                -11.436504031903363
              ],
              [
                -37.86948726963543,
                -11.436320023930977
              ],
              [
                -37.88034864028111,
                -11.43301006344107
              ],
              [
                -37.87666820562908,
                -11.429949074061636
              ],
              [
                -37.87512572286208,
                -11.428666593444834
              ],
              [
                -37.86552830678611,
                -11.428021592788843
              ],
              [
                -37.87574070715567,
                -11.423906634304107
              ],
              [
                -37.88301917204674,
                -11.425185616687012
              ],
              [
                -37.8843906276208,
                -11.42705708587959
              ],
              [
                -37.89103406773681,
                -11.436121558705942
              ],
              [
                -37.893321032859575,
                -11.433304080595025
              ],
              [
                -37.898469512272854,
                -11.436548575396886
              ],
              [
                -37.90170647797453,
                -11.427940633910096
              ],
              [
                -37.906377441802626,
                -11.426906122821089
              ],
              [
                -37.903378491755724,
                -11.422472166079938
              ],
              [
                -37.90367646929969,
                -11.419834664339636
              ],
              [
                -37.9046824961335,
                -11.410927734385716
              ],
              [
                -37.91018144200264,
                -11.410406242585395
              ],
              [
                -37.91550988951217,
                -11.403980800917054
              ],
              [
                -37.92879525492799,
                -11.402989324630115
              ],
              [
                -37.92743528745377,
                -11.410055278377044
              ],
              [
                -37.934795201634394,
                -11.414670757947414
              ],
              [
                -37.93491021900824,
                -11.415172749484913
              ],
              [
                -37.9364827057722,
                -11.42203620714798
              ],
              [
                -37.9434656174408,
                -11.423221718872282
              ],
              [
                -37.94250314590153,
                -11.417491237801416
              ],
              [
                -37.948608570722485,
                -11.41258078217216
              ],
              [
                -37.95224808100367,
                -11.409653791427694
              ],
              [
                -37.955635509273996,
                -11.406930326927696
              ],
              [
                -37.95130107611767,
                -11.404089832187994
              ],
              [
                -37.95245006231516,
                -11.395491400166431
              ],
              [
                -37.95972701683008,
                -11.396319424780543
              ],
              [
                -37.960643991498756,
                -11.401459366021959
              ],
              [
                -37.965111968161686,
                -11.402076894849321
              ],
              [
                -37.96470146762417,
                -11.393110924620233
              ],
              [
                -37.967537442034896,
                -11.390178437968826
              ],
              [
                -37.97135741280912,
                -11.391322452257105
              ],
              [
                -37.977461370524644,
                -11.393149961332991
              ],
              [
                -37.980557350496206,
                -11.385361989779183
              ],
              [
                -37.97278392638274,
                -11.382857510601033
              ],
              [
                -37.968263436189105,
                -11.381401000734837
              ],
              [
                -37.96855544270378,
                -11.377168541417792
              ],
              [
                -37.96923144412413,
                -11.373509086445306
              ],
              [
                -37.97245040574224,
                -11.373396072031204
              ],
              [
                -37.97809889311898,
                -11.365579124695623
              ],
              [
                -37.98206087549383,
                -11.347735265283834
              ],
              [
                -37.98743982517591,
                -11.34164231427514
              ],
              [
                -37.987680825369544,
                -11.34136930051512
              ],
              [
                -37.992918281921554,
                -11.343085812335364
              ],
              [
                -37.999439699724434,
                -11.340128362175639
              ],
              [
                -37.9980482437426,
                -11.33538788554135
              ],
              [
                -37.990584800315524,
                -11.332491397585244
              ],
              [
                -37.988355318098776,
                -11.331626374182878
              ],
              [
                -37.98761581099262,
                -11.331339399376708
              ],
              [
                -37.98786032508888,
                -11.331121393226296
              ],
              [
                -37.99113532058929,
                -11.328202420992865
              ],
              [
                -37.99783627141704,
                -11.322229970665832
              ],
              [
                -37.99203181003239,
                -11.321216979150488
              ],
              [
                -37.99142933249364,
                -11.321111951200091
              ],
              [
                -37.990864804217445,
                -11.31717250980337
              ],
              [
                -37.9930218025193,
                -11.31350003967097
              ],
              [
                -38.003007722459955,
                -11.2965036732799
              ],
              [
                -38.002979730604494,
                -11.289069209601688
              ],
              [
                -37.99693481937063,
                -11.28300925905712
              ],
              [
                -37.99131234888672,
                -11.277373793969925
              ],
              [
                -37.993020332334495,
                -11.272709804445322
              ],
              [
                -37.985974395684025,
                -11.269390323087153
              ],
              [
                -37.99282235907302,
                -11.261829402348353
              ],
              [
                -37.99499485670029,
                -11.259430405102485
              ],
              [
                -37.99169137012439,
                -11.256766405397794
              ],
              [
                -37.98401045951262,
                -11.250572451972005
              ],
              [
                -37.98646292290297,
                -11.246286978607115
              ],
              [
                -37.98973990981824,
                -11.24802599291691
              ],
              [
                -37.989814411112135,
                -11.248064994723197
              ],
              [
                -37.9931778987241,
                -11.24018004427459
              ],
              [
                -37.98802691803208,
                -11.237907065631742
              ],
              [
                -37.986184960306716,
                -11.237094556334197
              ],
              [
                -37.98858592252808,
                -11.231649090249292
              ],
              [
                -37.98947991302525,
                -11.229621122859236
              ],
              [
                -37.98883041462252,
                -11.228908604227156
              ],
              [
                -37.985963465744085,
                -11.22576265551558
              ],
              [
                -37.97738653630239,
                -11.221873662537403
              ],
              [
                -37.97716556561585,
                -11.21483622087579
              ],
              [
                -37.9814505087224,
                -11.209744270443286
              ],
              [
                -37.97885904640257,
                -11.20490827621974
              ],
              [
                -37.9807955336573,
                -11.198668341330785
              ],
              [
                -37.97396361268047,
                -11.194838844981103
              ],
              [
                -37.978852070835835,
                -11.191939884477794
              ],
              [
                -37.9873289967016,
                -11.195392872435015
              ],
              [
                -37.99604641909467,
                -11.190703919224886
              ],
              [
                -38.000339358616,
                -11.193641874058766
              ],
              [
                -38.00271185069315,
                -11.195265381127491
              ],
              [
                -38.01099779602948,
                -11.18879743420581
              ],
              [
                -38.01257175555735,
                -11.18756845586042
              ],
              [
                -38.0276691329331,
                -11.175782548035444
              ],
              [
                -38.03412707983634,
                -11.178284019508771
              ],
              [
                -38.03486706395052,
                -11.17857052330385
              ],
              [
                -38.047639989875464,
                -11.176955051670706
              ],
              [
                -38.06255434503153,
                -11.163393146374073
              ],
              [
                -38.062622858053714,
                -11.158638186633244
              ],
              [
                -38.071998774459466,
                -11.152457251881717
              ],
              [
                -38.06827330586846,
                -11.146987798905956
              ],
              [
                -38.072577789109815,
                -11.139117830451143
              ],
              [
                -38.07160631896778,
                -11.13719737714085
              ],
              [
                -38.06831135079292,
                -11.130682925193321
              ],
              [
                -38.07014385642749,
                -11.12065046845917
              ],
              [
                -38.08391072801902,
                -11.116159004598043
              ],
              [
                -38.086036687044356,
                -11.115465547030544
              ],
              [
                -38.09525114978832,
                -11.102579120308043
              ],
              [
                -38.09536015456308,
                -11.096857659588956
              ],
              [
                -38.0886341973431,
                -11.093174706362092
              ],
              [
                -38.08786369988343,
                -11.091704194057924
              ],
              [
                -38.078600291541875,
                -11.074023830676037
              ],
              [
                -38.08244326188946,
                -11.062680411737228
              ],
              [
                -38.086315737668286,
                -11.061824918328782
              ],
              [
                -38.087666727078876,
                -11.061526404024203
              ],
              [
                -38.088819726428206,
                -11.058509423063839
              ],
              [
                -38.09350219904132,
                -11.05510495093198
              ],
              [
                -38.09404019295263,
                -11.047712507771843
              ],
              [
                -38.094076185214014,
                -11.047218008373541
              ],
              [
                -38.09432421205102,
                -11.047193506833574
              ],
              [
                -38.099073133645696,
                -11.046718039686983
              ],
              [
                -38.105256115279715,
                -11.039664083544501
              ],
              [
                -38.10324313544058,
                -11.03421862945569
              ],
              [
                -38.10836109410177,
                -11.023212720560473
              ],
              [
                -38.10922857361976,
                -11.021347210986281
              ],
              [
                -38.10605814353839,
                -11.01543525895243
              ],
              [
                -38.11121909188224,
                -11.009498822600637
              ],
              [
                -38.12614744720697,
                -11.004205348748808
              ],
              [
                -38.12886993940539,
                -11.010845308030264
              ],
              [
                -38.13017744012865,
                -11.014035794545826
              ],
              [
                -38.137976864178256,
                -11.012386337322937
              ],
              [
                -38.1471317719724,
                -10.999007403106202
              ],
              [
                -38.14722828128493,
                -10.998866403231585
              ],
              [
                -38.14756428551146,
                -10.998700924806343
              ],
              [
                -38.17564655175435,
                -10.984872057391305
              ],
              [
                -38.1793555191773,
                -10.976166110305527
              ],
              [
                -38.18067300115517,
                -10.97307464806812
              ],
              [
                -38.18332549200497,
                -10.96684816392835
              ],
              [
                -38.188357948757336,
                -10.964135726188575
              ],
              [
                -38.1822615415021,
                -10.946998830702231
              ],
              [
                -38.18400602599061,
                -10.941940374129485
              ],
              [
                -38.20029338167783,
                -10.938164423824448
              ],
              [
                -38.20226136468703,
                -10.93182295474511
              ],
              [
                -38.20468237439013,
                -10.924023029691334
              ],
              [
                -38.2098573232651,
                -10.923573018802319
              ],
              [
                -38.21131079838903,
                -10.926204015494918
              ],
              [
                -38.213133290481686,
                -10.929502485799471
              ],
              [
                -38.21815472024092,
                -10.926878519400189
              ],
              [
                -38.229144139544225,
                -10.915495583867745
              ],
              [
                -38.23051016181876,
                -10.905739690705287
              ],
              [
                -38.23187665407885,
                -10.895984244550208
              ],
              [
                -38.23227515963163,
                -10.895063763634832
              ],
              [
                -38.24040810869808,
                -10.876257894375902
              ],
              [
                -38.24120609817656,
                -10.862838485020195
              ],
              [
                -38.23913814307,
                -10.851871064772078
              ],
              [
                -38.238769125592505,
                -10.851637567039932
              ],
              [
                -38.23183318981251,
                -10.847238562949276
              ],
              [
                -38.23937067227417,
                -10.827358216853815
              ],
              [
                -38.240775660278096,
                -10.826152255135463
              ],
              [
                -38.24526160116837,
                -10.822303293951776
              ],
              [
                -38.238918172467024,
                -10.813272839984428
              ],
              [
                -38.24146266203463,
                -10.800221953038585
              ],
              [
                -38.23650121350931,
                -10.791730494774455
              ],
              [
                -38.23082176358693,
                -10.791714994518342
              ],
              [
                -38.23051824677038,
                -10.791271992376814
              ],
              [
                -38.220942862167995,
                -10.777317072434434
              ],
              [
                -38.212633482816784,
                -10.758609693307209
              ],
              [
                -38.21434443295133,
                -10.754043240220744
              ],
              [
                -38.216055924078354,
                -10.749477774129039
              ],
              [
                -38.2166109753355,
                -10.721281454597175
              ],
              [
                -38.21280298164459,
                -10.711839544645887
              ],
              [
                -38.211549030232845,
                -10.708730536739878
              ],
              [
                -38.20307908146631,
                -10.7073455503414
              ],
              [
                -38.19840315866254,
                -10.706486048362203
              ],
              [
                -38.196702145098776,
                -10.706978052911644
              ],
              [
                -38.179298297093396,
                -10.70208907995585
              ],
              [
                -38.171817357868434,
                -10.710181483075328
              ],
              [
                -38.16361494705057,
                -10.710316514985
              ],
              [
                -38.16112244498694,
                -10.710356988923156
              ],
              [
                -38.160846450195024,
                -10.71055599517607
              ],
              [
                -38.15343603246835,
                -10.71591395115847
              ],
              [
                -38.140639614043884,
                -10.717941935259262
              ],
              [
                -38.1251287773941,
                -10.718379887858246
              ],
              [
                -38.120506299100136,
                -10.712285440861468
              ],
              [
                -38.11766033547024,
                -10.708532977882317
              ],
              [
                -38.10571795072464,
                -10.710413961008538
              ],
              [
                -38.10496243325742,
                -10.710532954308476
              ],
              [
                -38.10439647469123,
                -10.710145935484373
              ],
              [
                -38.09234958035473,
                -10.701908009818089
              ],
              [
                -38.08454413565335,
                -10.70508544314216
              ],
              [
                -38.08329965443798,
                -10.706145455080975
              ],
              [
                -38.077082179323526,
                -10.711441877805775
              ],
              [
                -38.05789738044797,
                -10.708145411440302
              ],
              [
                -38.052293404173014,
                -10.709700875956234
              ],
              [
                -38.04309949127863,
                -10.71860783819609
              ],
              [
                -38.02839559745902,
                -10.7377431791742
              ],
              [
                -38.02293113443869,
                -10.762646486624293
              ],
              [
                -38.0188201478041,
                -10.763488996947128
              ],
              [
                -37.99946582531575,
                -10.763949442088704
              ],
              [
                -37.99848233541168,
                -10.763594434568459
              ],
              [
                -37.972213086402874,
                -10.754100510220656
              ],
              [
                -37.84484524372361,
                -10.702307761537288
              ],
              [
                -37.81352903514442,
                -10.690616812133076
              ],
              [
                -37.830028500950796,
                -10.584272058688475
              ],
              [
                -37.825495539895535,
                -10.5799330953851
              ],
              [
                -37.83112897658916,
                -10.578535615702464
              ],
              [
                -37.83024199004485,
                -10.572591177074672
              ],
              [
                -37.83493495561591,
                -10.570697191986376
              ],
              [
                -37.832713963963414,
                -10.560165263364837
              ],
              [
                -37.839262929027704,
                -10.555642297460073
              ],
              [
                -37.8401739271821,
                -10.549135338901204
              ],
              [
                -37.83138850397337,
                -10.55089581052665
              ],
              [
                -37.82869001172962,
                -10.548301337522242
              ],
              [
                -37.82754455108722,
                -10.547199834314261
              ],
              [
                -37.82860954480551,
                -10.542556864338438
              ],
              [
                -37.82900801520106,
                -10.540821388430889
              ],
              [
                -37.827924534504156,
                -10.54017587296544
              ],
              [
                -37.8239465563331,
                -10.537804899951512
              ],
              [
                -37.81963113733285,
                -10.5388823804132
              ],
              [
                -37.82188258970286,
                -10.530480473159388
              ],
              [
                -37.81671614929282,
                -10.517933555139894
              ],
              [
                -37.812650207173554,
                -10.508062117809356
              ],
              [
                -37.81690817650588,
                -10.503515142607624
              ],
              [
                -37.81709618190848,
                -10.502548153871063
              ],
              [
                -37.8198666470859,
                -10.488306262859346
              ],
              [
                -37.820661648550264,
                -10.484219790561458
              ],
              [
                -37.82526564455714,
                -10.477181837927779
              ],
              [
                -37.85871589480932,
                -10.426041756518067
              ],
              [
                -37.85336143689751,
                -10.424456229358357
              ],
              [
                -37.852413942799565,
                -10.42123876785643
              ],
              [
                -37.850771478427724,
                -10.415662297854361
              ],
              [
                -37.85120697864491,
                -10.413771344143536
              ],
              [
                -37.85297794039374,
                -10.406079897267432
              ],
              [
                -37.844351536962584,
                -10.403077897978026
              ],
              [
                -37.837031604485055,
                -10.400530403771338
              ],
              [
                -37.84137757133219,
                -10.398437932724077
              ],
              [
                -37.842133052048084,
                -10.398073931194373
              ],
              [
                -37.84022757528622,
                -10.389473493055535
              ],
              [
                -37.833195131451355,
                -10.384918012861862
              ],
              [
                -37.838582113540994,
                -10.378739548815672
              ],
              [
                -37.83367616388962,
                -10.379694545589576
              ],
              [
                -37.83029766237886,
                -10.37454610265184
              ],
              [
                -37.823682759590135,
                -10.37757456143106
              ],
              [
                -37.82165777268754,
                -10.371893122846311
              ],
              [
                -37.812508330264095,
                -10.365788153773364
              ],
              [
                -37.80989438315062,
                -10.366006631387762
              ],
              [
                -37.807280884033844,
                -10.366225124002614
              ],
              [
                -37.78739408910937,
                -10.354145704274437
              ],
              [
                -37.78720157293987,
                -10.354028677926726
              ],
              [
                -37.78358310487974,
                -10.345805737311126
              ],
              [
                -37.771049713935206,
                -10.344751744711415
              ],
              [
                -37.762128312707205,
                -10.34400123773174
              ],
              [
                -37.73615553503825,
                -10.331607299016182
              ],
              [
                -37.741064523119775,
                -10.317228935656763
              ],
              [
                -37.74244253537774,
                -10.310322962765701
              ],
              [
                -37.76529943104426,
                -10.19578934562961
              ],
              [
                -37.77013937820949,
                -10.192996835216324
              ],
              [
                -37.779760301033214,
                -10.187444909081675
              ],
              [
                -37.782530293244406,
                -10.180214945102705
              ],
              [
                -37.77456189127439,
                -10.146802213377917
              ],
              [
                -37.77398140899329,
                -10.14436821267051
              ],
              [
                -37.77516787608764,
                -10.140047238682005
              ],
              [
                -37.77635540017258,
                -10.135724761707104
              ],
              [
                -37.777468356818524,
                -10.134321791184243
              ],
              [
                -37.79787873360133,
                -10.108605004568847
              ],
              [
                -37.82057656961478,
                -10.056870883768227
              ],
              [
                -37.823331065275205,
                -10.034063539514838
              ],
              [
                -37.822751085246814,
                -10.019927677012575
              ],
              [
                -37.822717580406575,
                -10.019104662039503
              ],
              [
                -37.831385017337695,
                -10.000499826008314
              ],
              [
                -37.875027681078194,
                -9.978361524973527
              ],
              [
                -37.87801864465396,
                -9.979333518890495
              ],
              [
                -37.88101060322167,
                -9.980305518809383
              ],
              [
                -37.91780978762923,
                -9.974367569385892
              ],
              [
                -37.92079478353083,
                -9.973181101190988
              ],
              [
                -37.92703122689144,
                -9.965165666609805
              ],
              [
                -37.936376149872544,
                -9.96571066220826
              ],
              [
                -37.95107952181563,
                -9.970819121742478
              ],
              [
                -37.95969043087156,
                -9.969856659689283
              ],
              [
                -37.96769236037748,
                -9.962143199678732
              ],
              [
                -37.97980529157655,
                -9.937272419148828
              ],
              [
                -37.98750425520095,
                -9.921464025405923
              ],
              [
                -37.99198420818542,
                -9.91908854648523
              ],
              [
                -37.997260162594955,
                -9.916291586482059
              ],
              [
                -37.99733616313544,
                -9.91033410941419
              ],
              [
                -37.987901247374104,
                -9.893935728501267
              ],
              [
                -37.98531376585936,
                -9.889438258980638
              ],
              [
                -37.98373030775826,
                -9.87890731593788
              ],
              [
                -37.98214582565533,
                -9.868374407926627
              ],
              [
                -37.97879187767802,
                -9.863110444298584
              ],
              [
                -37.97357392416036,
                -9.854920006350971
              ],
              [
                -37.97253443978592,
                -9.847710055443702
              ],
              [
                -37.973780950923306,
                -9.84565506586217
              ],
              [
                -37.97619489875713,
                -9.841678098638173
              ],
              [
                -37.97420442676466,
                -9.829617664545438
              ],
              [
                -37.97119647713563,
                -9.82588221204014
              ],
              [
                -37.96603001429754,
                -9.819466754118364
              ],
              [
                -37.96627552508785,
                -9.815631278661721
              ],
              [
                -37.97488392305835,
                -9.812865312794752
              ],
              [
                -37.99725625109711,
                -9.802962878516594
              ],
              [
                -38.016749114796255,
                -9.786997030022476
              ],
              [
                -38.01786960058998,
                -9.783435560426193
              ],
              [
                -38.01972108233508,
                -9.777548112728185
              ],
              [
                -38.023148071973765,
                -9.776745601114406
              ],
              [
                -38.02780453908027,
                -9.775655637864348
              ],
              [
                -38.037526460788726,
                -9.749038827050793
              ],
              [
                -38.04220895274894,
                -9.715636066302954
              ],
              [
                -38.04158697768106,
                -9.690689762889457
              ],
              [
                -38.03950699359627,
                -9.686171777175044
              ],
              [
                -38.03279556838792,
                -9.68450128178753
              ],
              [
                -38.02459064171307,
                -9.682458790655994
              ],
              [
                -38.021818165243296,
                -9.666607911018561
              ],
              [
                -38.021777662837174,
                -9.66637441370383
              ],
              [
                -38.02141717337457,
                -9.666016931985526
              ],
              [
                -38.00661181267517,
                -9.65131151091011
              ],
              [
                -37.996677422524414,
                -9.649974493883077
              ],
              [
                -37.991600958855976,
                -9.65627047525271
              ],
              [
                -37.98747947658702,
                -9.646968030945269
              ],
              [
                -37.98971396869897,
                -9.641245571505138
              ],
              [
                -38.00688984449721,
                -9.636236601677101
              ],
              [
                -38.00769181967162,
                -9.636002648206896
              ],
              [
                -38.00777830952638,
                -9.635393624797858
              ],
              [
                -38.00834781058862,
                -9.631364162171005
              ],
              [
                -38.014022267542835,
                -9.629464668874405
              ],
              [
                -38.01551926786816,
                -9.628963685071346
              ],
              [
                -38.01632677246385,
                -9.627277222350124
              ],
              [
                -38.01830924619677,
                -9.623135217963032
              ],
              [
                -38.02605668913796,
                -9.618038771374637
              ],
              [
                -38.0348941022136,
                -9.612225843167339
              ],
              [
                -38.037500588371955,
                -9.607174360126738
              ],
              [
                -38.04010755252562,
                -9.602121888094686
              ],
              [
                -38.03668363285664,
                -9.58209003391561
              ],
              [
                -38.03812709326139,
                -9.578008069557477
              ],
              [
                -38.0400165790074,
                -9.572665612972004
              ],
              [
                -38.03132766853795,
                -9.567639157572346
              ],
              [
                -38.03034018548309,
                -9.569376612731775
              ],
              [
                -38.027219202437905,
                -9.574862594027955
              ],
              [
                -38.024834746958284,
                -9.567372653116285
              ],
              [
                -38.018575779009375,
                -9.563437180061245
              ],
              [
                -38.01783979234924,
                -9.552617743459853
              ],
              [
                -38.02126627835609,
                -9.54945827124701
              ],
              [
                -38.02149877252465,
                -9.54924377906543
              ],
              [
                -38.02146077354059,
                -9.540583816173742
              ],
              [
                -38.01349084673969,
                -9.534550381007692
              ],
              [
                -38.01273086708372,
                -9.527901408526045
              ],
              [
                -38.00301997588579,
                -9.514579996695483
              ],
              [
                -37.99754653027734,
                -9.519381457714742
              ],
              [
                -37.988931090581666,
                -9.526938923207785
              ],
              [
                -37.96006282194677,
                -9.532816335198653
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 26,
        "nome": "São Paulo",
        "sigla": "SP",
        "regiao_id": 2,
        "codigo_ibg": 35
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -46.137723291145036,
                -23.858882115464983
              ],
              [
                -46.133267325755725,
                -23.85687710262771
              ],
              [
                -46.13299084714955,
                -23.856862595896672
              ],
              [
                -46.12829288883769,
                -23.856613088488334
              ],
              [
                -46.12409789495213,
                -23.859392575936
              ],
              [
                -46.12952933856457,
                -23.862718587653514
              ],
              [
                -46.13500677298956,
                -23.866278588461352
              ],
              [
                -46.13945971667948,
                -23.87436404603314
              ],
              [
                -46.13905571308694,
                -23.8790635187996
              ],
              [
                -46.1387456903914,
                -23.882678504700962
              ],
              [
                -46.13805068942961,
                -23.89084647008919
              ],
              [
                -46.14089265622795,
                -23.892047964901305
              ],
              [
                -46.14622611032515,
                -23.894296471210893
              ],
              [
                -46.15144406815219,
                -23.89661846286318
              ],
              [
                -46.15093804632124,
                -23.901341445250793
              ],
              [
                -46.14988105870708,
                -23.905903922769664
              ],
              [
                -46.1558084654545,
                -23.90824245440883
              ],
              [
                -46.16034893343906,
                -23.905757953638073
              ],
              [
                -46.16107945686759,
                -23.90645396763129
              ],
              [
                -46.168656376955305,
                -23.913674433195006
              ],
              [
                -46.179255718193765,
                -23.923882432155164
              ],
              [
                -46.177638700258385,
                -23.938392874609704
              ],
              [
                -46.17294671852051,
                -23.940590822219143
              ],
              [
                -46.1706892654902,
                -23.93888832530279
              ],
              [
                -46.16788178154055,
                -23.936771820648996
              ],
              [
                -46.16597377586404,
                -23.944327300355774
              ],
              [
                -46.169460757323186,
                -23.951911774542328
              ],
              [
                -46.17169772680518,
                -23.95615874703738
              ],
              [
                -46.177728627525006,
                -23.95911676027336
              ],
              [
                -46.179846604085974,
                -23.96013177786901
              ],
              [
                -46.18259408327745,
                -23.961447764943465
              ],
              [
                -46.18682403858118,
                -23.971897720534024
              ],
              [
                -46.1867870016657,
                -23.972256237826482
              ],
              [
                -46.18532199139709,
                -23.986448658248772
              ],
              [
                -46.18137602162834,
                -23.990013640910732
              ],
              [
                -46.189056457757985,
                -23.98906114141463
              ],
              [
                -46.19953232754278,
                -23.99441318290837
              ],
              [
                -46.202858295762276,
                -23.9998211274419
              ],
              [
                -46.20680875824497,
                -23.997789661952407
              ],
              [
                -46.20492881636593,
                -23.99511719441465
              ],
              [
                -46.20184984625077,
                -23.99110568337413
              ],
              [
                -46.20212284551976,
                -23.99093567992353
              ],
              [
                -46.21136278794946,
                -23.98517875437597
              ],
              [
                -46.2440729591232,
                -23.99088230680956
              ],
              [
                -46.25722833319077,
                -23.998872280405383
              ],
              [
                -46.263277270074994,
                -24.002613808312173
              ],
              [
                -46.26697525422844,
                -24.00799576210233
              ],
              [
                -46.265754729071965,
                -24.00988326916006
              ],
              [
                -46.26320225710526,
                -24.013831757180526
              ],
              [
                -46.268044728114724,
                -24.01207876004448
              ],
              [
                -46.268227228222784,
                -24.012179249127616
              ],
              [
                -46.27372616924565,
                -24.015198253674534
              ],
              [
                -46.27800611111602,
                -24.02125927116098
              ],
              [
                -46.27871709862445,
                -24.02226625075063
              ],
              [
                -46.2783495605356,
                -24.02696872177856
              ],
              [
                -46.27763053091693,
                -24.036168663800957
              ],
              [
                -46.28026800201015,
                -24.038652158436342
              ],
              [
                -46.28702642943453,
                -24.043964656496907
              ],
              [
                -46.28901295356191,
                -24.03904921410864
              ],
              [
                -46.28698998722523,
                -24.033966212805378
              ],
              [
                -46.28423352444253,
                -24.027041729559595
              ],
              [
                -46.28374855574606,
                -24.02582422756073
              ],
              [
                -46.286449014075295,
                -24.02460474583126
              ],
              [
                -46.29124248282281,
                -24.02276326195071
              ],
              [
                -46.294350955472034,
                -24.01609531862192
              ],
              [
                -46.304132871397435,
                -24.01935880955597
              ],
              [
                -46.30808082011502,
                -24.023958841600184
              ],
              [
                -46.31115880968911,
                -24.027545317623215
              ],
              [
                -46.31128428164344,
                -24.023189838332712
              ],
              [
                -46.31135082203093,
                -24.02088332777159
              ],
              [
                -46.31555727469389,
                -24.019933363224315
              ],
              [
                -46.316843760175686,
                -24.021855869427036
              ],
              [
                -46.31817326707021,
                -24.023842850469723
              ],
              [
                -46.3236857246543,
                -24.02174085883903
              ],
              [
                -46.32313524565585,
                -24.01671038057816
              ],
              [
                -46.32224526425209,
                -24.00856690816397
              ],
              [
                -46.32161929226951,
                -24.00284243877912
              ],
              [
                -46.32111580535933,
                -23.998826953160492
              ],
              [
                -46.31472886333277,
                -23.9962374751111
              ],
              [
                -46.314543388160935,
                -23.996162445905725
              ],
              [
                -46.30775442809891,
                -23.993410433418475
              ],
              [
                -46.3049734715303,
                -23.99227994136789
              ],
              [
                -46.31259990377244,
                -23.98071254196224
              ],
              [
                -46.31303742305157,
                -23.98042702550261
              ],
              [
                -46.31833589810215,
                -23.976969563265598
              ],
              [
                -46.324650830835985,
                -23.972849080928018
              ],
              [
                -46.336833243758804,
                -23.970234619927854
              ],
              [
                -46.34504871232652,
                -23.968472183621305
              ],
              [
                -46.345235702882555,
                -23.96843216434223
              ],
              [
                -46.34860467437393,
                -23.969017680523088
              ],
              [
                -46.3542536145196,
                -23.969912693951734
              ],
              [
                -46.35851055489215,
                -23.970385701215072
              ],
              [
                -46.36718250924688,
                -23.971349220127777
              ],
              [
                -46.36990646902941,
                -23.97401821618506
              ],
              [
                -46.36904595204784,
                -23.977579173865507
              ],
              [
                -46.368306961770315,
                -23.980636656136664
              ],
              [
                -46.37158393762168,
                -23.979312172544685
              ],
              [
                -46.37223542402034,
                -23.979048699608594
              ],
              [
                -46.37316193535117,
                -23.974062725448064
              ],
              [
                -46.374152441329485,
                -23.968730225015793
              ],
              [
                -46.384231837187755,
                -23.969873257228947
              ],
              [
                -46.38592182864767,
                -23.972271233491007
              ],
              [
                -46.38788031035953,
                -23.97509824663047
              ],
              [
                -46.38048884360108,
                -23.975749228252983
              ],
              [
                -46.37645289300449,
                -23.98263716493265
              ],
              [
                -46.374621885722775,
                -23.98576216473301
              ],
              [
                -46.37909730537799,
                -23.99229414674964
              ],
              [
                -46.38521477758863,
                -23.994054652731062
              ],
              [
                -46.39204819803969,
                -23.996021178886586
              ],
              [
                -46.39008072302462,
                -24.00084963174032
              ],
              [
                -46.38896919602311,
                -24.003575102415166
              ],
              [
                -46.39158868891564,
                -24.009941613803054
              ],
              [
                -46.39086616486978,
                -24.01056609393024
              ],
              [
                -46.383201707825606,
                -24.017188547247628
              ],
              [
                -46.38949666640665,
                -24.016084571472334
              ],
              [
                -46.38979715253145,
                -24.02255505266095
              ],
              [
                -46.39892203803568,
                -24.03307749961456
              ],
              [
                -46.40154203076039,
                -24.031666012503315
              ],
              [
                -46.39968006541627,
                -24.020978052438032
              ],
              [
                -46.39912209218618,
                -24.01777658900272
              ],
              [
                -46.40186555443558,
                -24.017069085129375
              ],
              [
                -46.41659745403655,
                -24.013267639888756
              ],
              [
                -46.423237893918696,
                -24.01500514453603
              ],
              [
                -46.42325990571361,
                -24.015010663575726
              ],
              [
                -46.42987833480467,
                -24.01674265218888
              ],
              [
                -46.45476460405453,
                -24.023254700907106
              ],
              [
                -46.45819556549874,
                -24.02457822505661
              ],
              [
                -46.46162752993704,
                -24.025901209213337
              ],
              [
                -46.49780165420764,
                -24.03985125912164
              ],
              [
                -46.60475911765219,
                -24.08917982666008
              ],
              [
                -46.7092175412969,
                -24.143442856435744
              ],
              [
                -46.759511531833176,
                -24.171185342322772
              ],
              [
                -46.78347977880351,
                -24.184406883335964
              ],
              [
                -46.78616329603507,
                -24.185886893802298
              ],
              [
                -46.78884625527141,
                -24.187366890268716
              ],
              [
                -46.789298737217344,
                -24.187722382054222
              ],
              [
                -46.79636065936188,
                -24.193263374746913
              ],
              [
                -46.79910762167818,
                -24.195421350453326
              ],
              [
                -46.80529154360286,
                -24.200278849552596
              ],
              [
                -46.80865903785335,
                -24.201254349255006
              ],
              [
                -46.81141751384668,
                -24.20205337692751
              ],
              [
                -46.81615945214005,
                -24.20342738696014
              ],
              [
                -46.82297988083813,
                -24.205404400515683
              ],
              [
                -46.85798204426359,
                -24.227660887240308
              ],
              [
                -46.880592784576315,
                -24.24203689976008
              ],
              [
                -46.887168237603206,
                -24.246210899128684
              ],
              [
                -46.89374418262678,
                -24.250384370512336
              ],
              [
                -46.91512441501548,
                -24.263979375976717
              ],
              [
                -46.94040617191455,
                -24.280038386483078
              ],
              [
                -46.98464819053404,
                -24.31643833766407
              ],
              [
                -46.99225309050363,
                -24.322694813295968
              ],
              [
                -47.00018501457047,
                -24.3292758195217
              ],
              [
                -47.00295596195764,
                -24.335570787611694
              ],
              [
                -47.00207344768339,
                -24.341954266431788
              ],
              [
                -47.00118146984663,
                -24.3479537268853
              ],
              [
                -47.00098491679686,
                -24.34927572659555
              ],
              [
                -47.00019893259645,
                -24.35456271744357
              ],
              [
                -47.005432831368935,
                -24.36532817164355
              ],
              [
                -47.00844032199833,
                -24.367611682780147
              ],
              [
                -47.01075778328416,
                -24.36937115911731
              ],
              [
                -47.018219724283966,
                -24.375373681541745
              ],
              [
                -47.01606070151403,
                -24.386291608061377
              ],
              [
                -47.01316422524338,
                -24.386736114490127
              ],
              [
                -47.008618749917574,
                -24.387433094903468
              ],
              [
                -47.00888022026667,
                -24.3951375545029
              ],
              [
                -47.000887291451726,
                -24.396799546452264
              ],
              [
                -47.00572474325986,
                -24.401449026891942
              ],
              [
                -47.00319674322128,
                -24.408814490622067
              ],
              [
                -47.00878120064639,
                -24.40844548891241
              ],
              [
                -47.009244168493645,
                -24.413565483261415
              ],
              [
                -47.01434311429216,
                -24.410872016105998
              ],
              [
                -47.016563584869665,
                -24.411931531189683
              ],
              [
                -47.0188140851418,
                -24.413006012299785
              ],
              [
                -47.019869581733644,
                -24.416241501538458
              ],
              [
                -47.02164553739183,
                -24.421684976439334
              ],
              [
                -47.028735483067656,
                -24.425830483837597
              ],
              [
                -47.04727930896912,
                -24.429035521595623
              ],
              [
                -47.07750500204088,
                -24.442826026966895
              ],
              [
                -47.08235042328997,
                -24.447605028993923
              ],
              [
                -47.08664989804437,
                -24.451845004704715
              ],
              [
                -47.09137084976821,
                -24.456501517902375
              ],
              [
                -47.11508459375474,
                -24.471604017388042
              ],
              [
                -47.12627195045777,
                -24.47870902858398
              ],
              [
                -47.137459333155796,
                -24.485814508810826
              ],
              [
                -47.15146669483157,
                -24.496643990634244
              ],
              [
                -47.15443218373487,
                -24.498936526758868
              ],
              [
                -47.1573971316422,
                -24.50122899888498
              ],
              [
                -47.17787691104668,
                -24.517062479931752
              ],
              [
                -47.18632733451358,
                -24.52420650690895
              ],
              [
                -47.18646732017442,
                -24.52647395765713
              ],
              [
                -47.186684788680076,
                -24.529991962305267
              ],
              [
                -47.18688626477293,
                -24.533105958630344
              ],
              [
                -47.19148024220884,
                -24.53478995130096
              ],
              [
                -47.19637267789845,
                -24.53654245658297
              ],
              [
                -47.19982363553051,
                -24.537777977724467
              ],
              [
                -47.20059665905529,
                -24.538054964875467
              ],
              [
                -47.21286599027189,
                -24.547203948908244
              ],
              [
                -47.21842147378324,
                -24.551347459029014
              ],
              [
                -47.22757984941538,
                -24.55817744060354
              ],
              [
                -47.2266748686603,
                -24.560208934222942
              ],
              [
                -47.224571380222145,
                -24.564932415764677
              ],
              [
                -47.22633331256347,
                -24.569333902356895
              ],
              [
                -47.232186293224615,
                -24.5708039037885
              ],
              [
                -47.23556974184044,
                -24.5716464134296
              ],
              [
                -47.24064520876474,
                -24.572909908395967
              ],
              [
                -47.24446467133948,
                -24.573860940897152
              ],
              [
                -47.25046560750766,
                -24.575403930478803
              ],
              [
                -47.284226771297085,
                -24.595453968453594
              ],
              [
                -47.28748475001788,
                -24.597388958105864
              ],
              [
                -47.29027920561627,
                -24.599048466525886
              ],
              [
                -47.29415017988927,
                -24.60134695049752
              ],
              [
                -47.2970576182991,
                -24.60307345698019
              ],
              [
                -47.29934411124121,
                -24.604431473146622
              ],
              [
                -47.30804901965222,
                -24.609600940601723
              ],
              [
                -47.36053395341146,
                -24.640770474852975
              ],
              [
                -47.391382648810215,
                -24.65909149318251
              ],
              [
                -47.396680578062416,
                -24.662237977010207
              ],
              [
                -47.39718660283233,
                -24.662237976549385
              ],
              [
                -47.40311803124286,
                -24.662240491581187
              ],
              [
                -47.40211802785664,
                -24.6649154912486
              ],
              [
                -47.40115651651947,
                -24.66748846546425
              ],
              [
                -47.43173573622816,
                -24.683868004478494
              ],
              [
                -47.44054162155439,
                -24.69649494311495
              ],
              [
                -47.44157258953851,
                -24.69797396302898
              ],
              [
                -47.45999591875277,
                -24.7047804805526
              ],
              [
                -47.48429966808277,
                -24.713718994115517
              ],
              [
                -47.48734965050922,
                -24.714840993709533
              ],
              [
                -47.53157618819119,
                -24.738081038251547
              ],
              [
                -47.53684564998854,
                -24.74141554038149
              ],
              [
                -47.54471855701512,
                -24.746398025575086
              ],
              [
                -47.54789802109903,
                -24.748411043865882
              ],
              [
                -47.550473532629525,
                -24.75004052591827
              ],
              [
                -47.56068692068558,
                -24.756505031097426
              ],
              [
                -47.58225219064945,
                -24.77015354301602
              ],
              [
                -47.616292803514696,
                -24.791698019340206
              ],
              [
                -47.61889431365466,
                -24.79364503219242
              ],
              [
                -47.623384255837955,
                -24.797007025935436
              ],
              [
                -47.62361726024872,
                -24.79718255991783
              ],
              [
                -47.626728715728795,
                -24.799511528747185
              ],
              [
                -47.631622655428686,
                -24.803175524481514
              ],
              [
                -47.648553461553576,
                -24.81585351861085
              ],
              [
                -47.65180292649581,
                -24.81828601145715
              ],
              [
                -47.65215444759556,
                -24.818549040441177
              ],
              [
                -47.65453491484965,
                -24.820420540957755
              ],
              [
                -47.67267121506053,
                -24.8346795183346
              ],
              [
                -47.67525319104747,
                -24.83670901782961
              ],
              [
                -47.70385983954935,
                -24.859198013367834
              ],
              [
                -47.74950687139078,
                -24.895083478211443
              ],
              [
                -47.77184761916847,
                -24.912647482467108
              ],
              [
                -47.80975067348786,
                -24.94629994357072
              ],
              [
                -47.84146581843402,
                -24.9745029164848
              ],
              [
                -47.866476510899574,
                -25.004067867342346
              ],
              [
                -47.87306942790137,
                -25.011861328762308
              ],
              [
                -47.874581428010494,
                -25.014616824127273
              ],
              [
                -47.87476690414251,
                -25.014970817247804
              ],
              [
                -47.8805403409522,
                -25.02596380899609
              ],
              [
                -47.88419125552598,
                -25.032918302769037
              ],
              [
                -47.885215755643955,
                -25.038615786545414
              ],
              [
                -47.885728217630515,
                -25.041749277266838
              ],
              [
                -47.88768966952401,
                -25.053155220527508
              ],
              [
                -47.89831509064985,
                -25.053025252153976
              ],
              [
                -47.900931066017684,
                -25.052993271433664
              ],
              [
                -47.909455500564746,
                -25.052880794450296
              ],
              [
                -47.90926993264939,
                -25.062588744040195
              ],
              [
                -47.90214347538201,
                -25.080291640218363
              ],
              [
                -47.90051047963783,
                -25.081708128318137
              ],
              [
                -47.89702598125336,
                -25.08473109605691
              ],
              [
                -47.89501700150914,
                -25.09176558595016
              ],
              [
                -47.89789993557495,
                -25.096803560292642
              ],
              [
                -47.895635440743696,
                -25.10282751054003
              ],
              [
                -47.894297911475,
                -25.10638650778265
              ],
              [
                -47.896607892038716,
                -25.10830402413549
              ],
              [
                -47.90484333277784,
                -25.11514200783647
              ],
              [
                -47.90791328563613,
                -25.117691515983573
              ],
              [
                -47.91843111037934,
                -25.145210411254897
              ],
              [
                -47.91853256909371,
                -25.1530104036839
              ],
              [
                -47.9161480710405,
                -25.154305868930862
              ],
              [
                -47.91525560463262,
                -25.154791367155045
              ],
              [
                -47.91251660013343,
                -25.15804584428521
              ],
              [
                -47.9167150585843,
                -25.159533342338015
              ],
              [
                -47.91808004190401,
                -25.159883871175477
              ],
              [
                -47.920315034139854,
                -25.16045836781969
              ],
              [
                -47.926663985140515,
                -25.162094880997348
              ],
              [
                -47.93284741184059,
                -25.16629339019843
              ],
              [
                -47.942848290986554,
                -25.173084357779498
              ],
              [
                -47.948417727592286,
                -25.17812787963779
              ],
              [
                -47.95398767818549,
                -25.183172388505714
              ],
              [
                -47.96991550112776,
                -25.197596361621166
              ],
              [
                -47.971010458265475,
                -25.198588367012267
              ],
              [
                -47.97102647607555,
                -25.19860334600147
              ],
              [
                -47.97210544740294,
                -25.199580326404146
              ],
              [
                -47.99112525971128,
                -25.213974352458234
              ],
              [
                -48.000116168160325,
                -25.220778351997495
              ],
              [
                -48.003455633812194,
                -25.223305328205203
              ],
              [
                -48.00745510308113,
                -25.226331836458186
              ],
              [
                -48.02856885491453,
                -25.24231035687315
              ],
              [
                -48.04245468741232,
                -25.256016337905557
              ],
              [
                -48.04399864578731,
                -25.257539833581966
              ],
              [
                -48.05084710016494,
                -25.264298323719885
              ],
              [
                -48.064091934346216,
                -25.277369809437538
              ],
              [
                -48.095701554785485,
                -25.308567747809168
              ],
              [
                -48.09851952351851,
                -25.31088874231712
              ],
              [
                -48.09835801444087,
                -25.30654727936182
              ],
              [
                -48.09824554092008,
                -25.303523776233966
              ],
              [
                -48.09786506669035,
                -25.299172784631534
              ],
              [
                -48.08580072238048,
                -25.28514931233647
              ],
              [
                -48.07144185674186,
                -25.27252833520793
              ],
              [
                -48.053409589244644,
                -25.256845353014675
              ],
              [
                -48.03732276311088,
                -25.242853854191118
              ],
              [
                -48.030596333234534,
                -25.237003876800856
              ],
              [
                -48.02307993452627,
                -25.230466855737003
              ],
              [
                -48.02445692199414,
                -25.2294373682477
              ],
              [
                -48.02696192829294,
                -25.227564389730734
              ],
              [
                -48.02946438160615,
                -25.225693421197356
              ],
              [
                -48.02761640763892,
                -25.221300919243816
              ],
              [
                -48.03524536148431,
                -25.218870972087657
              ],
              [
                -48.041175810640496,
                -25.219813977888425
              ],
              [
                -48.04409827241351,
                -25.223224462215256
              ],
              [
                -48.04659573459265,
                -25.22614046321929
              ],
              [
                -48.04914025571766,
                -25.224229468979193
              ],
              [
                -48.05353372287872,
                -25.220635994384043
              ],
              [
                -48.05690417336408,
                -25.21787801417945
              ],
              [
                -48.057619693350524,
                -25.221319505450438
              ],
              [
                -48.05904563351719,
                -25.22894996494884
              ],
              [
                -48.05651313077206,
                -25.232574964268856
              ],
              [
                -48.05880461681845,
                -25.23571144274289
              ],
              [
                -48.06155908860672,
                -25.239481929103782
              ],
              [
                -48.065684548986745,
                -25.239839454630523
              ],
              [
                -48.07522145945609,
                -25.240454469139923
              ],
              [
                -48.081749397729695,
                -25.242695992590548
              ],
              [
                -48.08465440422198,
                -25.240432994909778
              ],
              [
                -48.08539785625278,
                -25.239854009597323
              ],
              [
                -48.08948485474217,
                -25.240533007706212
              ],
              [
                -48.09467879434889,
                -25.24139503955895
              ],
              [
                -48.09510028758409,
                -25.241465015601975
              ],
              [
                -48.10064724203394,
                -25.242387557323692
              ],
              [
                -48.107080178088886,
                -25.245949061144753
              ],
              [
                -48.114404105149326,
                -25.25019354901542
              ],
              [
                -48.150757352555814,
                -25.227618756935986
              ],
              [
                -48.15691335191001,
                -25.21726730624248
              ],
              [
                -48.16171485882734,
                -25.209191839068783
              ],
              [
                -48.16672030687592,
                -25.20826236358979
              ],
              [
                -48.177763736155796,
                -25.20621240665735
              ],
              [
                -48.186718185687745,
                -25.198397447494013
              ],
              [
                -48.19288065700253,
                -25.189359527494023
              ],
              [
                -48.17791231466824,
                -25.181551007038678
              ],
              [
                -48.17514185557304,
                -25.17787753021655
              ],
              [
                -48.16723845483635,
                -25.167398036866953
              ],
              [
                -48.167939472328136,
                -25.159412560468596
              ],
              [
                -48.16823599568122,
                -25.15603559110395
              ],
              [
                -48.16635948378307,
                -25.15536157894377
              ],
              [
                -48.16171702856769,
                -25.153694093127957
              ],
              [
                -48.156403617789316,
                -25.144144609215353
              ],
              [
                -48.16651252627516,
                -25.1432921424195
              ],
              [
                -48.17632447529409,
                -25.136619690322384
              ],
              [
                -48.18116092970704,
                -25.133330223864537
              ],
              [
                -48.181204475164215,
                -25.12941922388152
              ],
              [
                -48.18121296076079,
                -25.128715248766774
              ],
              [
                -48.18390895388292,
                -25.12542974357233
              ],
              [
                -48.19410639452123,
                -25.113002331050936
              ],
              [
                -48.19420440697221,
                -25.108706330808715
              ],
              [
                -48.194231940267215,
                -25.107511345749593
              ],
              [
                -48.19594644542396,
                -25.10556988101622
              ],
              [
                -48.19915689960846,
                -25.101934881856394
              ],
              [
                -48.21171485697595,
                -25.084941517294684
              ],
              [
                -48.209922391949476,
                -25.080407034103292
              ],
              [
                -48.20965938064113,
                -25.07974153398381
              ],
              [
                -48.21061138493875,
                -25.079166526305904
              ],
              [
                -48.21557487821803,
                -25.076166566061648
              ],
              [
                -48.211616936021684,
                -25.05867961876269
              ],
              [
                -48.215054973916395,
                -25.042166171720464
              ],
              [
                -48.21424501228743,
                -25.04119268314638
              ],
              [
                -48.20979605023398,
                -25.035849715968126
              ],
              [
                -48.2091260934302,
                -25.02824971381839
              ],
              [
                -48.21445955086857,
                -25.029166737774922
              ],
              [
                -48.220261477593716,
                -25.026063756567343
              ],
              [
                -48.231074414999085,
                -25.02028181094809
              ],
              [
                -48.23146793721381,
                -25.014851334290242
              ],
              [
                -48.22623198980998,
                -25.011051840382997
              ],
              [
                -48.22287702071213,
                -25.008617842803748
              ],
              [
                -48.227664010536124,
                -25.00745135952844
              ],
              [
                -48.23207897709767,
                -25.006375392720905
              ],
              [
                -48.22975047305389,
                -25.00220391743121
              ],
              [
                -48.22899199644035,
                -25.000844906596168
              ],
              [
                -48.23051198696936,
                -25.000289404612715
              ],
              [
                -48.23267399907572,
                -24.999499428592312
              ],
              [
                -48.23526148125409,
                -24.998553435537996
              ],
              [
                -48.24017844218411,
                -24.99149845365681
              ],
              [
                -48.24529540474145,
                -24.98888547430213
              ],
              [
                -48.251197410022684,
                -24.978246046105767
              ],
              [
                -48.2586063314512,
                -24.986582047307586
              ],
              [
                -48.254423288484915,
                -24.996015490806197
              ],
              [
                -48.259433758815355,
                -24.99715151685447
              ],
              [
                -48.26285122986671,
                -24.99229801930634
              ],
              [
                -48.26910369740646,
                -24.997028524609327
              ],
              [
                -48.277083540908414,
                -25.015489963479304
              ],
              [
                -48.27725205789419,
                -25.019366469244133
              ],
              [
                -48.27782749119876,
                -25.03260038625162
              ],
              [
                -48.28455044607061,
                -25.034877408068336
              ],
              [
                -48.28989242431528,
                -25.02736495833321
              ],
              [
                -48.29234241808698,
                -25.023919975008685
              ],
              [
                -48.29889486407069,
                -25.02389652063641
              ],
              [
                -48.300438319416905,
                -25.0307589725975
              ],
              [
                -48.313435173431046,
                -25.04443694582321
              ],
              [
                -48.3147201102112,
                -25.048301960171464
              ],
              [
                -48.3172925707041,
                -25.056041414848416
              ],
              [
                -48.33318842539416,
                -25.070066892900787
              ],
              [
                -48.333228466280225,
                -25.05706444472322
              ],
              [
                -48.325517508848534,
                -25.054442947146104
              ],
              [
                -48.323867065595245,
                -25.053881943240864
              ],
              [
                -48.323658596008734,
                -25.03624202814662
              ],
              [
                -48.3319885305597,
                -25.035633041746554
              ],
              [
                -48.33641249605133,
                -25.039072038674956
              ],
              [
                -48.33657499323016,
                -25.03919905366965
              ],
              [
                -48.33745347201223,
                -25.037603543897514
              ],
              [
                -48.33859849442391,
                -25.035524062924914
              ],
              [
                -48.338918978735755,
                -25.03553559288361
              ],
              [
                -48.35074539347757,
                -25.035979593190316
              ],
              [
                -48.350009913445604,
                -25.02780465103672
              ],
              [
                -48.35258839328307,
                -25.022747674638275
              ],
              [
                -48.354266422876364,
                -25.019456171254372
              ],
              [
                -48.37061231905739,
                -25.007280773910253
              ],
              [
                -48.377205773241236,
                -25.002369298510324
              ],
              [
                -48.37753526041178,
                -25.00133982771991
              ],
              [
                -48.37943725718382,
                -24.995392341813673
              ],
              [
                -48.394311163017086,
                -24.990510393250005
              ],
              [
                -48.41058858098415,
                -24.979624014439054
              ],
              [
                -48.4165514859892,
                -24.988863480666755
              ],
              [
                -48.417580973034774,
                -24.98898900138655
              ],
              [
                -48.42974688822345,
                -24.99047303353169
              ],
              [
                -48.43443731743642,
                -24.991044534930907
              ],
              [
                -48.473566419872014,
                -25.012048064763636
              ],
              [
                -48.4836802443285,
                -25.040113979134706
              ],
              [
                -48.482810231819236,
                -25.05268643163146
              ],
              [
                -48.49027267625013,
                -25.052722931972276
              ],
              [
                -48.491101180413295,
                -25.05272744256201
              ],
              [
                -48.4979235979715,
                -25.05860094734059
              ],
              [
                -48.50409049087964,
                -25.069488387853607
              ],
              [
                -48.51192739481027,
                -25.08332385978804
              ],
              [
                -48.51636235230512,
                -25.084377388525805
              ],
              [
                -48.52079778379816,
                -25.085430868267682
              ],
              [
                -48.521091768103155,
                -25.086029388784077
              ],
              [
                -48.52810119017188,
                -25.10029035347946
              ],
              [
                -48.53483316246318,
                -25.098533368792612
              ],
              [
                -48.54216909167862,
                -25.09339042563593
              ],
              [
                -48.554793022513365,
                -25.084539977079825
              ],
              [
                -48.55585850159227,
                -25.083793001447066
              ],
              [
                -48.55939252263889,
                -25.077454523102134
              ],
              [
                -48.55936049389623,
                -25.074826527577436
              ],
              [
                -48.559184051110655,
                -25.060156110081113
              ],
              [
                -48.565693013882004,
                -25.056133119799522
              ],
              [
                -48.568574023664155,
                -25.050745670575726
              ],
              [
                -48.57147851853295,
                -25.05081316445868
              ],
              [
                -48.58217842564817,
                -25.051061225189343
              ],
              [
                -48.58122145186409,
                -25.036749774800867
              ],
              [
                -48.5810609862914,
                -25.03435025196059
              ],
              [
                -48.58434493931214,
                -25.032241291802098
              ],
              [
                -48.589100403835026,
                -25.02918633108965
              ],
              [
                -48.588853435967806,
                -25.025733340221286
              ],
              [
                -48.588386983579554,
                -25.019201840068085
              ],
              [
                -48.58851597855199,
                -25.01919336250843
              ],
              [
                -48.598000396045144,
                -25.01855989793149
              ],
              [
                -48.59745446118574,
                -25.002827963608446
              ],
              [
                -48.59464549156911,
                -24.995620438826123
              ],
              [
                -48.58877755371863,
                -24.994312440637202
              ],
              [
                -48.588300563411856,
                -24.9898834590014
              ],
              [
                -48.587874102927145,
                -24.985918972653824
              ],
              [
                -48.58365563820252,
                -24.98522498518641
              ],
              [
                -48.577006180961426,
                -24.984131435688884
              ],
              [
                -48.56703580403161,
                -24.971665983678722
              ],
              [
                -48.567462861466495,
                -24.959514536113137
              ],
              [
                -48.570195815195774,
                -24.956557046648513
              ],
              [
                -48.572162835085315,
                -24.954428064430008
              ],
              [
                -48.568340861361506,
                -24.952606073790676
              ],
              [
                -48.56272843156397,
                -24.949932039977234
              ],
              [
                -48.56890586793731,
                -24.94328309025321
              ],
              [
                -48.56244794371408,
                -24.938850608907703
              ],
              [
                -48.56980640641647,
                -24.930601647364654
              ],
              [
                -48.569261467689806,
                -24.92058320518738
              ],
              [
                -48.565982497754334,
                -24.91630372122309
              ],
              [
                -48.56666105094442,
                -24.903367780721624
              ],
              [
                -48.56693956483853,
                -24.903319772788997
              ],
              [
                -48.57235052896708,
                -24.90237677156826
              ],
              [
                -48.57157603397012,
                -24.89450829000458
              ],
              [
                -48.56324009072979,
                -24.894422793230863
              ],
              [
                -48.562509614831434,
                -24.89545725875131
              ],
              [
                -48.55882362242975,
                -24.90067874304952
              ],
              [
                -48.55583065955597,
                -24.892567280536056
              ],
              [
                -48.558959139306246,
                -24.88873231887664
              ],
              [
                -48.55785919184485,
                -24.88408132928171
              ],
              [
                -48.5548492016438,
                -24.882730305332444
              ],
              [
                -48.548621781686776,
                -24.879299792743534
              ],
              [
                -48.546353822040444,
                -24.8732568391529
              ],
              [
                -48.54921231911147,
                -24.866995878496784
              ],
              [
                -48.552910786100924,
                -24.866369366611842
              ],
              [
                -48.556555741185974,
                -24.86556889826534
              ],
              [
                -48.56619320502961,
                -24.863452940008486
              ],
              [
                -48.56398974838092,
                -24.855667953690467
              ],
              [
                -48.56732974655212,
                -24.846996481321725
              ],
              [
                -48.56180184065047,
                -24.82789154459497
              ],
              [
                -48.5575938703122,
                -24.82492554449206
              ],
              [
                -48.5552728964993,
                -24.823289063888172
              ],
              [
                -48.55770292671755,
                -24.82138005823622
              ],
              [
                -48.558714911392904,
                -24.82058556462571
              ],
              [
                -48.55527047546781,
                -24.805700108362515
              ],
              [
                -48.538034147087465,
                -24.789902630825736
              ],
              [
                -48.53389970890379,
                -24.78726213867651
              ],
              [
                -48.529765766715656,
                -24.784621633533455
              ],
              [
                -48.51628988196436,
                -24.779642136798095
              ],
              [
                -48.51624142941634,
                -24.771871644307
              ],
              [
                -48.5162174428787,
                -24.7680551817853
              ],
              [
                -48.511602462932444,
                -24.76346067513581
              ],
              [
                -48.51100596284058,
                -24.763457661289973
              ],
              [
                -48.506055010592796,
                -24.763429156984955
              ],
              [
                -48.50721504824497,
                -24.751868691743375
              ],
              [
                -48.50185814277414,
                -24.7442192142775
              ],
              [
                -48.5034926404643,
                -24.739634267075257
              ],
              [
                -48.49882867913126,
                -24.73846023135514
              ],
              [
                -48.50455115717359,
                -24.734434272593074
              ],
              [
                -48.50995006533244,
                -24.739132279218595
              ],
              [
                -48.511019558084065,
                -24.740062263752243
              ],
              [
                -48.52200500268552,
                -24.735571325264566
              ],
              [
                -48.52836241754769,
                -24.73851133606015
              ],
              [
                -48.531298403840545,
                -24.73626533535996
              ],
              [
                -48.528746437827685,
                -24.731160360240313
              ],
              [
                -48.528706955448236,
                -24.73108084944185
              ],
              [
                -48.531657435128466,
                -24.72977088296806
              ],
              [
                -48.532038945630894,
                -24.729601382846827
              ],
              [
                -48.53813938369358,
                -24.72689190088479
              ],
              [
                -48.54307233740381,
                -24.7247009491737
              ],
              [
                -48.54382934689473,
                -24.719419471073557
              ],
              [
                -48.54411136291361,
                -24.71745344197176
              ],
              [
                -48.546650335845015,
                -24.716982951790943
              ],
              [
                -48.5509773153488,
                -24.716181491523837
              ],
              [
                -48.55127882879217,
                -24.712986016497588
              ],
              [
                -48.55161084415059,
                -24.70947749384478
              ],
              [
                -48.55498682132728,
                -24.70978354309858
              ],
              [
                -48.559630265684994,
                -24.71020450882797
              ],
              [
                -48.56871524109658,
                -24.700309102457364
              ],
              [
                -48.567994750664354,
                -24.696106606361777
              ],
              [
                -48.56782373078493,
                -24.695108626901863
              ],
              [
                -48.5731012070039,
                -24.690150165546687
              ],
              [
                -48.57854520444374,
                -24.68503519334693
              ],
              [
                -48.57887319578441,
                -24.683416704971258
              ],
              [
                -48.581366222148766,
                -24.671118238917916
              ],
              [
                -48.586578628090265,
                -24.677390732518912
              ],
              [
                -48.58877063730701,
                -24.680028747569978
              ],
              [
                -48.59546259630879,
                -24.674168792286338
              ],
              [
                -48.60172555443258,
                -24.668683838137284
              ],
              [
                -48.608976992872606,
                -24.669267363301266
              ],
              [
                -48.61159647163775,
                -24.669477860587403
              ],
              [
                -48.61284646402756,
                -24.669578366064222
              ],
              [
                -48.61613695232509,
                -24.668170869036743
              ],
              [
                -48.622754351894,
                -24.67494937197611
              ],
              [
                -48.62769178862289,
                -24.680006860721416
              ],
              [
                -48.63017479058123,
                -24.678588349228388
              ],
              [
                -48.63307076904274,
                -24.676932895985807
              ],
              [
                -48.64482963255481,
                -24.681019901927417
              ],
              [
                -48.643556672435345,
                -24.683846361452183
              ],
              [
                -48.6411606832138,
                -24.689164827332036
              ],
              [
                -48.65769350496768,
                -24.691557903070485
              ],
              [
                -48.66084793441352,
                -24.70595384532038
              ],
              [
                -48.67447633422575,
                -24.701311873673273
              ],
              [
                -48.67671033966042,
                -24.69790739848129
              ],
              [
                -48.67102535820342,
                -24.698167409708468
              ],
              [
                -48.67657587672495,
                -24.69202393799361
              ],
              [
                -48.676145857933776,
                -24.6915709524966
              ],
              [
                -48.67162742667921,
                -24.686806940804743
              ],
              [
                -48.67446538661155,
                -24.683550452895844
              ],
              [
                -48.670990930761725,
                -24.678798467416925
              ],
              [
                -48.676499412387436,
                -24.671105519884144
              ],
              [
                -48.68777983796306,
                -24.668735547650634
              ],
              [
                -48.693603783950465,
                -24.67018008090583
              ],
              [
                -48.68779732412622,
                -24.676965527212957
              ],
              [
                -48.68950877829352,
                -24.690051980310944
              ],
              [
                -48.68988024165058,
                -24.692892467917588
              ],
              [
                -48.693295216930046,
                -24.68962200185858
              ],
              [
                -48.70327019601692,
                -24.68006905378695
              ],
              [
                -48.726800947200815,
                -24.68581509476662
              ],
              [
                -48.72962695852567,
                -24.686505124773852
              ],
              [
                -48.73184092414645,
                -24.68524261080557
              ],
              [
                -48.73405443376969,
                -24.683980616833615
              ],
              [
                -48.73654838939349,
                -24.686121120914713
              ],
              [
                -48.74410730538232,
                -24.692609134142057
              ],
              [
                -48.7533472289371,
                -24.693665149680655
              ],
              [
                -48.753066246900225,
                -24.689205661905337
              ],
              [
                -48.75260375704903,
                -24.68188018320403
              ],
              [
                -48.756800228208505,
                -24.67935269255352
              ],
              [
                -48.76519113760395,
                -24.690341687122125
              ],
              [
                -48.76857507039339,
                -24.694773699700104
              ],
              [
                -48.77816451662645,
                -24.696229703725653
              ],
              [
                -48.78772447667754,
                -24.68068630760559
              ],
              [
                -48.799243433317564,
                -24.674886850072294
              ],
              [
                -48.810917330466324,
                -24.673167388473505
              ],
              [
                -48.818689317722985,
                -24.659605496722975
              ],
              [
                -48.82030330207543,
                -24.656788975181865
              ],
              [
                -48.824286779736,
                -24.655943524036804
              ],
              [
                -48.82827124439307,
                -24.655097538897127
              ],
              [
                -48.83268219414302,
                -24.658650022252925
              ],
              [
                -48.839915113379746,
                -24.664475507209932
              ],
              [
                -48.84339009591,
                -24.662102037675073
              ],
              [
                -48.852823530063716,
                -24.65565906522817
              ],
              [
                -48.85801997794607,
                -24.657197602212413
              ],
              [
                -48.85284300570479,
                -24.665076556130217
              ],
              [
                -48.8483350108172,
                -24.671935994267756
              ],
              [
                -48.85097050611097,
                -24.674132013603085
              ],
              [
                -48.86490788080274,
                -24.666823571725853
              ],
              [
                -48.87828929073098,
                -24.667890623199643
              ],
              [
                -48.901610581722856,
                -24.66975066253966
              ],
              [
                -48.90524303593221,
                -24.67087166735852
              ],
              [
                -48.911967995376166,
                -24.67294718898703
              ],
              [
                -48.91075500552761,
                -24.678483140814578
              ],
              [
                -48.91477445407325,
                -24.682410173511265
              ],
              [
                -48.92194288561855,
                -24.67468468915009
              ],
              [
                -48.932251314797774,
                -24.674115228687054
              ],
              [
                -48.9450532352086,
                -24.673407768585268
              ],
              [
                -48.948084213530294,
                -24.676077259285634
              ],
              [
                -48.95796505246649,
                -24.684781276066527
              ],
              [
                -48.96266007213366,
                -24.673074308013675
              ],
              [
                -48.96280409430668,
                -24.6727153309135
              ],
              [
                -48.96352207082101,
                -24.672619840545448
              ],
              [
                -48.9744399654125,
                -24.67116785456942
              ],
              [
                -48.97614198705494,
                -24.665966406889122
              ],
              [
                -48.97637449582721,
                -24.665255378486695
              ],
              [
                -48.977590989127776,
                -24.66520240950656
              ],
              [
                -48.98755441469718,
                -24.66476791743581
              ],
              [
                -48.98907489438764,
                -24.65914747787534
              ],
              [
                -48.98963491958871,
                -24.65707696798413
              ],
              [
                -48.99498886796533,
                -24.656029479961802
              ],
              [
                -48.99637784468552,
                -24.655758007402987
              ],
              [
                -49.00931376014834,
                -24.65771703597715
              ],
              [
                -49.008290780108126,
                -24.653275556693544
              ],
              [
                -49.00491884135266,
                -24.638634575234427
              ],
              [
                -48.999845394795265,
                -24.63617907328505
              ],
              [
                -49.004250385421166,
                -24.633923127166845
              ],
              [
                -49.01788122693129,
                -24.64211709272057
              ],
              [
                -49.03293013513807,
                -24.633966177682748
              ],
              [
                -49.04176406461436,
                -24.64003819782954
              ],
              [
                -49.03935605155694,
                -24.643408189704655
              ],
              [
                -49.032388109552414,
                -24.641440653835282
              ],
              [
                -49.03396106455142,
                -24.648972605382905
              ],
              [
                -49.03408205140404,
                -24.64955012643297
              ],
              [
                -49.03902253583253,
                -24.65286412653781
              ],
              [
                -49.036911506540505,
                -24.65991908549273
              ],
              [
                -49.04502240832365,
                -24.66559807901318
              ],
              [
                -49.05083534277888,
                -24.67900257924745
              ],
              [
                -49.055321814512446,
                -24.68214706265397
              ],
              [
                -49.0696616470482,
                -24.683464596314437
              ],
              [
                -49.07245118019354,
                -24.678191111276803
              ],
              [
                -49.075337171232356,
                -24.672736631270887
              ],
              [
                -49.08269212121434,
                -24.67174567431632
              ],
              [
                -49.08677552132109,
                -24.679128661425253
              ],
              [
                -49.08716401952858,
                -24.67983066182073
              ],
              [
                -49.09664497394696,
                -24.681317663581815
              ],
              [
                -49.097836447778725,
                -24.679852201210615
              ],
              [
                -49.10158343367338,
                -24.675242733494812
              ],
              [
                -49.10845737281839,
                -24.676053750797642
              ],
              [
                -49.10954783575252,
                -24.680900209741687
              ],
              [
                -49.111110297944144,
                -24.687849194725285
              ],
              [
                -49.124542208491995,
                -24.682375239872222
              ],
              [
                -49.12876667268038,
                -24.680653260047805
              ],
              [
                -49.13459117160161,
                -24.675017335963894
              ],
              [
                -49.156853000058405,
                -24.671542877790674
              ],
              [
                -49.16328094001813,
                -24.673379413599946
              ],
              [
                -49.16935289225207,
                -24.675113937706083
              ],
              [
                -49.1703548827482,
                -24.67441691764925
              ],
              [
                -49.17435534077363,
                -24.671634458375166
              ],
              [
                -49.185163270007,
                -24.67220296603694
              ],
              [
                -49.19419464602437,
                -24.682770961039346
              ],
              [
                -49.204522538173514,
                -24.685462973712358
              ],
              [
                -49.20440254472632,
                -24.693804947918398
              ],
              [
                -49.20437153901126,
                -24.695919432352238
              ],
              [
                -49.20610950677023,
                -24.697817924215
              ],
              [
                -49.20842096851701,
                -24.700343406372696
              ],
              [
                -49.240231205633435,
                -24.697988005878813
              ],
              [
                -49.25627962488029,
                -24.695274597237905
              ],
              [
                -49.26233704948592,
                -24.691387604845854
              ],
              [
                -49.27545194477987,
                -24.693117635211653
              ],
              [
                -49.293948350482324,
                -24.6741802701263
              ],
              [
                -49.3048242811761,
                -24.67214081750562
              ],
              [
                -49.30468778435573,
                -24.668406309002027
              ],
              [
                -49.31140724190837,
                -24.663123857260306
              ],
              [
                -49.30357434410593,
                -24.65759185873447
              ],
              [
                -49.299134906825614,
                -24.654456372311444
              ],
              [
                -49.3011668865973,
                -24.649354411109346
              ],
              [
                -49.30609884393022,
                -24.646903912419603
              ],
              [
                -49.30928184314015,
                -24.64532243375387
              ],
              [
                -49.30650239577084,
                -24.64289096074162
              ],
              [
                -49.3058868807757,
                -24.642352434961072
              ],
              [
                -49.30658336814947,
                -24.64208492122089
              ],
              [
                -49.31593882301295,
                -24.638492501004077
              ],
              [
                -49.3083514140222,
                -24.624494024175185
              ],
              [
                -49.306585962540915,
                -24.621237529666278
              ],
              [
                -49.308907935349,
                -24.618954028102912
              ],
              [
                -49.31418589318354,
                -24.613763580463267
              ],
              [
                -49.31263893860751,
                -24.60420361389156
              ],
              [
                -49.3105659929143,
                -24.602460121367926
              ],
              [
                -49.30327705785853,
                -24.596330627051096
              ],
              [
                -49.31154351951261,
                -24.59060717693019
              ],
              [
                -49.316047997807836,
                -24.587488208564587
              ],
              [
                -49.311936014120505,
                -24.585602671220386
              ],
              [
                -49.31186203784407,
                -24.585569196122478
              ],
              [
                -49.310556061580854,
                -24.580456683521433
              ],
              [
                -49.31270256660231,
                -24.57582524182406
              ],
              [
                -49.317190570646254,
                -24.566139767738775
              ],
              [
                -49.31410160640914,
                -24.561924274964294
              ],
              [
                -49.31549912303844,
                -24.55500583510228
              ],
              [
                -49.30929667300694,
                -24.552911309071913
              ],
              [
                -49.30508473633661,
                -24.54589034206185
              ],
              [
                -49.300478750811294,
                -24.548227823249164
              ],
              [
                -49.2920713127946,
                -24.5524947787804
              ],
              [
                -49.29177182974889,
                -24.543803787749066
              ],
              [
                -49.28087295538736,
                -24.536712282124952
              ],
              [
                -49.28743994610023,
                -24.526336849382755
              ],
              [
                -49.28658448476225,
                -24.513560911080916
              ],
              [
                -49.29079895133071,
                -24.507235450703455
              ],
              [
                -49.295013445898746,
                -24.500909464328693
              ],
              [
                -49.27968360693752,
                -24.495509949143436
              ],
              [
                -49.26907817059805,
                -24.4917739380516
              ],
              [
                -49.27021971467304,
                -24.48552648378119
              ],
              [
                -49.270692723502414,
                -24.482937980685996
              ],
              [
                -49.263360794354675,
                -24.473926978106284
              ],
              [
                -49.25906434915187,
                -24.472566970178523
              ],
              [
                -49.246692976753955,
                -24.46865096936565
              ],
              [
                -49.247979947817996,
                -24.464228505220053
              ],
              [
                -49.24483447882838,
                -24.46460996886924
              ],
              [
                -49.24484601457489,
                -24.45793752482776
              ],
              [
                -49.24742097196987,
                -24.457074521340324
              ],
              [
                -49.24923095259332,
                -24.456468528430122
              ],
              [
                -49.2530444265233,
                -24.455190524479466
              ],
              [
                -49.25182146291284,
                -24.451024560476295
              ],
              [
                -49.25002949110529,
                -24.44492109352394
              ],
              [
                -49.252155053004,
                -24.43360562183359
              ],
              [
                -49.24019265461929,
                -24.421515125413325
              ],
              [
                -49.23897670494301,
                -24.41895863195949
              ],
              [
                -49.2355227528176,
                -24.411698673556316
              ],
              [
                -49.234972750556246,
                -24.410542180520675
              ],
              [
                -49.23532773930951,
                -24.40956618757205
              ],
              [
                -49.2361722331016,
                -24.407244209589837
              ],
              [
                -49.23727623044523,
                -24.40420820030314
              ],
              [
                -49.24260619784038,
                -24.401925223120987
              ],
              [
                -49.249744620693,
                -24.413363716097944
              ],
              [
                -49.253376606025874,
                -24.40725476409513
              ],
              [
                -49.2518786272335,
                -24.40345523479103
              ],
              [
                -49.24091228362084,
                -24.384644283758735
              ],
              [
                -49.224428005553705,
                -24.364743838106623
              ],
              [
                -49.20027926757264,
                -24.34397384235235
              ],
              [
                -49.205379235528,
                -24.337701904623824
              ],
              [
                -49.21538613858107,
                -24.338803432200102
              ],
              [
                -49.22379758518906,
                -24.33972892054713
              ],
              [
                -49.23072605655882,
                -24.329894977928937
              ],
              [
                -49.24838790645489,
                -24.330015042246135
              ],
              [
                -49.249949957038744,
                -24.31886660029554
              ],
              [
                -49.25398640247209,
                -24.317776616237666
              ],
              [
                -49.26911130577227,
                -24.306838196510057
              ],
              [
                -49.28296369638168,
                -24.307540243636627
              ],
              [
                -49.289514705139005,
                -24.294917786124635
              ],
              [
                -49.28826219944901,
                -24.29134081474325
              ],
              [
                -49.288255216577376,
                -24.291321302800718
              ],
              [
                -49.28827772942891,
                -24.291312323906904
              ],
              [
                -49.298172631345025,
                -24.28731936678026
              ],
              [
                -49.294714662081255,
                -24.284923354772953
              ],
              [
                -49.30074467708984,
                -24.275804414447087
              ],
              [
                -49.29936367687531,
                -24.2712909194738
              ],
              [
                -49.29822119985471,
                -24.267557945077435
              ],
              [
                -49.30213872713495,
                -24.25864048139816
              ],
              [
                -49.30655817376473,
                -24.258297005482724
              ],
              [
                -49.30818517011244,
                -24.25817050803643
              ],
              [
                -49.307481701467665,
                -24.256628521113758
              ],
              [
                -49.304885225384396,
                -24.25093450517917
              ],
              [
                -49.312214186898174,
                -24.242898595492456
              ],
              [
                -49.317294621834186,
                -24.24320010500064
              ],
              [
                -49.319136120589214,
                -24.24330910326097
              ],
              [
                -49.32082564393776,
                -24.238974137086142
              ],
              [
                -49.321609635032054,
                -24.236961137683913
              ],
              [
                -49.323359112686326,
                -24.235941646238636
              ],
              [
                -49.35018846209868,
                -24.220303259767558
              ],
              [
                -49.355377435872775,
                -24.211454347740496
              ],
              [
                -49.34774801062192,
                -24.2067503142272
              ],
              [
                -49.34105508770202,
                -24.202624310277763
              ],
              [
                -49.34462857275231,
                -24.193692860609715
              ],
              [
                -49.3456900789494,
                -24.191040882666123
              ],
              [
                -49.33936869805946,
                -24.174377422854878
              ],
              [
                -49.339857237054034,
                -24.15668199733617
              ],
              [
                -49.33792027301137,
                -24.15494752141328
              ],
              [
                -49.33197233543152,
                -24.149622008727082
              ],
              [
                -49.336306801626755,
                -24.144411549313812
              ],
              [
                -49.336789341194226,
                -24.13719210119525
              ],
              [
                -49.341022790748006,
                -24.137837113630184
              ],
              [
                -49.361611684466986,
                -24.12679919408511
              ],
              [
                -49.362340702590124,
                -24.119156230447572
              ],
              [
                -49.36832065351104,
                -24.11614224916188
              ],
              [
                -49.37035762838235,
                -24.11388626862864
              ],
              [
                -49.3770075706539,
                -24.11722726554006
              ],
              [
                -49.37752058575598,
                -24.117164762376976
              ],
              [
                -49.38170106400024,
                -24.116653802352754
              ],
              [
                -49.38665602270109,
                -24.115683308588142
              ],
              [
                -49.38883796346882,
                -24.118873318341244
              ],
              [
                -49.393091452452346,
                -24.114452843449264
              ],
              [
                -49.408572370362194,
                -24.107794896297204
              ],
              [
                -49.40450991223696,
                -24.103092916893033
              ],
              [
                -49.40535389865856,
                -24.101397415394956
              ],
              [
                -49.40732889629968,
                -24.09743095562596
              ],
              [
                -49.40439294750552,
                -24.095352956028574
              ],
              [
                -49.41720984103325,
                -24.091103985842814
              ],
              [
                -49.429772256323005,
                -24.086939567526546
              ],
              [
                -49.42893630104956,
                -24.080650063538293
              ],
              [
                -49.428523307742424,
                -24.0775400859207
              ],
              [
                -49.43403328484912,
                -24.074977121336364
              ],
              [
                -49.43472275048246,
                -24.074656634765976
              ],
              [
                -49.43447675652643,
                -24.07436463619589
              ],
              [
                -49.42929729463157,
                -24.06821162027551
              ],
              [
                -49.43849524459471,
                -24.068252665455674
              ],
              [
                -49.43735078621906,
                -24.05828468450457
              ],
              [
                -49.44807072395444,
                -24.055595218481567
              ],
              [
                -49.45036068066528,
                -24.05502075787557
              ],
              [
                -49.45295566736427,
                -24.052684747379352
              ],
              [
                -49.46243364893396,
                -24.03744585261115
              ],
              [
                -49.46243767018107,
                -24.037369333935068
              ],
              [
                -49.462954695102724,
                -24.026939405999837
              ],
              [
                -49.47224512493594,
                -24.026831427042126
              ],
              [
                -49.47326958675863,
                -24.02681942724523
              ],
              [
                -49.47337261530788,
                -24.025620415444603
              ],
              [
                -49.473892606238806,
                -24.01956297071311
              ],
              [
                -49.4790291032502,
                -24.018952957008835
              ],
              [
                -49.4792060663745,
                -24.019351974928774
              ],
              [
                -49.48105652434522,
                -24.02351246368553
              ],
              [
                -49.48666598218146,
                -24.02410397655015
              ],
              [
                -49.4896654788065,
                -24.01816148597642
              ],
              [
                -49.485665527286706,
                -24.0161355039108
              ],
              [
                -49.49090151692959,
                -24.014040011561267
              ],
              [
                -49.48433706851165,
                -24.011868003199837
              ],
              [
                -49.48301356172859,
                -24.011429513912294
              ],
              [
                -49.48008310722805,
                -24.005673546341
              ],
              [
                -49.485829080649545,
                -24.006080558363326
              ],
              [
                -49.48590409946784,
                -23.999931064645416
              ],
              [
                -49.49168907135385,
                -23.992993605706417
              ],
              [
                -49.49200105178241,
                -23.993012629588524
              ],
              [
                -49.497452527870415,
                -23.99334012602343
              ],
              [
                -49.49535803781452,
                -23.985340651174987
              ],
              [
                -49.49320109410632,
                -23.977104672112336
              ],
              [
                -49.50305856699211,
                -23.96806172427761
              ],
              [
                -49.5038610641863,
                -23.968438761207075
              ],
              [
                -49.510276993752264,
                -23.971460741611487
              ],
              [
                -49.51570543554959,
                -23.96945328648106
              ],
              [
                -49.51324399727838,
                -23.96395928930618
              ],
              [
                -49.50721203781576,
                -23.96170928594112
              ],
              [
                -49.49942909276839,
                -23.95880627586574
              ],
              [
                -49.50497958951778,
                -23.954340829129972
              ],
              [
                -49.50290161377575,
                -23.94812581709951
              ],
              [
                -49.51338703700293,
                -23.943927368428668
              ],
              [
                -49.5141900478896,
                -23.94411389713397
              ],
              [
                -49.52061148200574,
                -23.945606369770086
              ],
              [
                -49.52397800783728,
                -23.9346574607653
              ],
              [
                -49.517401039589,
                -23.936017937020818
              ],
              [
                -49.511723065821144,
                -23.93719191779779
              ],
              [
                -49.50755664762897,
                -23.933223913200152
              ],
              [
                -49.513889082693694,
                -23.930523932656996
              ],
              [
                -49.511140157431726,
                -23.925372955244146
              ],
              [
                -49.513836612423304,
                -23.92328097805847
              ],
              [
                -49.522519071331395,
                -23.916542527207344
              ],
              [
                -49.53019998810593,
                -23.921966507642598
              ],
              [
                -49.531522473490575,
                -23.928578488727155
              ],
              [
                -49.53539441729255,
                -23.92780349777629
              ],
              [
                -49.54594437865556,
                -23.922614546331417
              ],
              [
                -49.55518280588826,
                -23.922282571051564
              ],
              [
                -49.55650126650464,
                -23.922235100293783
              ],
              [
                -49.55605929476184,
                -23.920936604230878
              ],
              [
                -49.553973845744004,
                -23.914802613842216
              ],
              [
                -49.561781745868586,
                -23.912151161624
              ],
              [
                -49.5626902397151,
                -23.911843142669
              ],
              [
                -49.56488127534116,
                -23.907392681544057
              ],
              [
                -49.568701739932436,
                -23.89963472291107
              ],
              [
                -49.57052571962787,
                -23.898727720207894
              ],
              [
                -49.576643198714955,
                -23.89568623338564
              ],
              [
                -49.57569471506535,
                -23.890792773435884
              ],
              [
                -49.57311128711,
                -23.877457826926335
              ],
              [
                -49.58171720865311,
                -23.885067796266647
              ],
              [
                -49.58557166144654,
                -23.8884767911862
              ],
              [
                -49.591754157819175,
                -23.87584537167697
              ],
              [
                -49.59293864611858,
                -23.873426387178657
              ],
              [
                -49.597452597231474,
                -23.870609895512573
              ],
              [
                -49.59745610621422,
                -23.870606917535476
              ],
              [
                -49.60197557329495,
                -23.867787931895638
              ],
              [
                -49.59922613785813,
                -23.856593485146742
              ],
              [
                -49.610245056310156,
                -23.851198040940915
              ],
              [
                -49.60760561646389,
                -23.848147053304213
              ],
              [
                -49.59993465986607,
                -23.848678997626333
              ],
              [
                -49.598084695194125,
                -23.84880699643519
              ],
              [
                -49.59499270897895,
                -23.84630498417824
              ],
              [
                -49.60057616828743,
                -23.843004521761156
              ],
              [
                -49.60298867316312,
                -23.841578553974646
              ],
              [
                -49.60754812346986,
                -23.838884050944614
              ],
              [
                -49.59808221723448,
                -23.836373048205594
              ],
              [
                -49.5952587261796,
                -23.837652060333912
              ],
              [
                -49.586576309374024,
                -23.841583986680753
              ],
              [
                -49.59208676276926,
                -23.836484038391532
              ],
              [
                -49.59213275939808,
                -23.83565604491517
              ],
              [
                -49.59246280903913,
                -23.829775088955113
              ],
              [
                -49.586671859541795,
                -23.826679047881502
              ],
              [
                -49.577935408735584,
                -23.826587551516845
              ],
              [
                -49.575252960289895,
                -23.826559537422327
              ],
              [
                -49.561876073954856,
                -23.81807501420243
              ],
              [
                -49.55958010631033,
                -23.81143505435476
              ],
              [
                -49.5604451446959,
                -23.808582055677462
              ],
              [
                -49.56261013417312,
                -23.801439118536997
              ],
              [
                -49.561084665737745,
                -23.793581125052174
              ],
              [
                -49.560473674970915,
                -23.790436650064425
              ],
              [
                -49.56533366275157,
                -23.780277691525715
              ],
              [
                -49.56957414863257,
                -23.778118233323525
              ],
              [
                -49.56601017902323,
                -23.77731571772731
              ],
              [
                -49.56333423148263,
                -23.763703767345742
              ],
              [
                -49.56018277287083,
                -23.760875247325174
              ],
              [
                -49.571502700993065,
                -23.75459433162005
              ],
              [
                -49.5634607919584,
                -23.749407836354532
              ],
              [
                -49.5669342990187,
                -23.740009383502226
              ],
              [
                -49.56355683783516,
                -23.731720914216623
              ],
              [
                -49.56032887869027,
                -23.733374876588766
              ],
              [
                -49.551870427720374,
                -23.737710344009837
              ],
              [
                -49.548605467881714,
                -23.736026846968905
              ],
              [
                -49.554140433205085,
                -23.722794394169025
              ],
              [
                -49.55404695679732,
                -23.72088990770576
              ],
              [
                -49.54935753473079,
                -23.7032269899955
              ],
              [
                -49.554759046382046,
                -23.700768004544152
              ],
              [
                -49.55677652344546,
                -23.690685049130327
              ],
              [
                -49.56004502768838,
                -23.69141957205688
              ],
              [
                -49.5608340067463,
                -23.691597043728343
              ],
              [
                -49.56090301911328,
                -23.690505066427026
              ],
              [
                -49.56124951511478,
                -23.684994581135147
              ],
              [
                -49.56475648206319,
                -23.68639607304372
              ],
              [
                -49.56763197096217,
                -23.68754559806841
              ],
              [
                -49.5753634375862,
                -23.679909656980282
              ],
              [
                -49.574377936986316,
                -23.676138161486
              ],
              [
                -49.57419146604012,
                -23.675423170858206
              ],
              [
                -49.57478895127193,
                -23.675700659534925
              ],
              [
                -49.57924438825986,
                -23.677769642584362
              ],
              [
                -49.582629389753755,
                -23.669813704586254
              ],
              [
                -49.58194892641848,
                -23.667797207806952
              ],
              [
                -49.58065794944988,
                -23.663969746618708
              ],
              [
                -49.58524938003064,
                -23.66334172616344
              ],
              [
                -49.584983430167256,
                -23.65831576602202
              ],
              [
                -49.5920293854549,
                -23.655370785555245
              ],
              [
                -49.60140031067359,
                -23.65145333515463
              ],
              [
                -49.60288932585639,
                -23.643059370197488
              ],
              [
                -49.61081474276031,
                -23.64077988466112
              ],
              [
                -49.61664871521013,
                -23.639101926293833
              ],
              [
                -49.608473822646204,
                -23.63402091534347
              ],
              [
                -49.60775330799887,
                -23.63357341199476
              ],
              [
                -49.60580986785455,
                -23.624535938272075
              ],
              [
                -49.61181929499105,
                -23.623111970384738
              ],
              [
                -49.61315278848222,
                -23.622795492737406
              ],
              [
                -49.613438309955974,
                -23.617833516021378
              ],
              [
                -49.61365883490958,
                -23.613999540468853
              ],
              [
                -49.61477933889304,
                -23.613461534085477
              ],
              [
                -49.617552823529195,
                -23.61212905699094
              ],
              [
                -49.62063828081576,
                -23.60656307426628
              ],
              [
                -49.61896284662706,
                -23.6016831092673
              ],
              [
                -49.615926881834774,
                -23.59105163983108
              ],
              [
                -49.60992443489663,
                -23.58736263181854
              ],
              [
                -49.60153254926181,
                -23.58220614061797
              ],
              [
                -49.60068904963899,
                -23.57473915584164
              ],
              [
                -49.60313304294006,
                -23.57115118604242
              ],
              [
                -49.60750251996111,
                -23.564736692733188
              ],
              [
                -49.602141122874286,
                -23.55768471757446
              ],
              [
                -49.59874516688465,
                -23.553218245716256
              ],
              [
                -49.60216512952324,
                -23.546785265618137
              ],
              [
                -49.6046491253532,
                -23.54211280542182
              ],
              [
                -49.60218367845233,
                -23.538621325369938
              ],
              [
                -49.59971771355322,
                -23.535129799320885
              ],
              [
                -49.6118351110585,
                -23.52890837167611
              ],
              [
                -49.623719569016366,
                -23.522806923826252
              ],
              [
                -49.625252056918484,
                -23.519401432520716
              ],
              [
                -49.62870054668525,
                -23.511740491580586
              ],
              [
                -49.621223617530504,
                -23.505485520798228
              ],
              [
                -49.618865148745314,
                -23.50621298166178
              ],
              [
                -49.614100176508806,
                -23.507682457183492
              ],
              [
                -49.616962664943465,
                -23.501422011702132
              ],
              [
                -49.62088916317146,
                -23.492831541066842
              ],
              [
                -49.6135367552836,
                -23.48649606803679
              ],
              [
                -49.61439231196229,
                -23.471707117777235
              ],
              [
                -49.60677335229491,
                -23.47576506498674
              ],
              [
                -49.6027768796021,
                -23.477893568114332
              ],
              [
                -49.60130937066833,
                -23.47750857827589
              ],
              [
                -49.59808991333574,
                -23.476663554051562
              ],
              [
                -49.60208389662142,
                -23.470144575083005
              ],
              [
                -49.599818957462595,
                -23.461464124174224
              ],
              [
                -49.59872346307992,
                -23.45726561725058
              ],
              [
                -49.602587965667404,
                -23.450947184069886
              ],
              [
                -49.595151520114094,
                -23.43988968844665
              ],
              [
                -49.589615091330984,
                -23.435347188583155
              ],
              [
                -49.58711312661497,
                -23.435534212267896
              ],
              [
                -49.57911967923652,
                -23.43613016870971
              ],
              [
                -49.57252422215575,
                -23.44338311776573
              ],
              [
                -49.56853025866104,
                -23.43980862154991
              ],
              [
                -49.571163750422755,
                -23.43450663247054
              ],
              [
                -49.56661283618578,
                -23.426741181972684
              ],
              [
                -49.580301193644736,
                -23.431092175157623
              ],
              [
                -49.57983023581756,
                -23.414574751247446
              ],
              [
                -49.58909419176772,
                -23.415197764556527
              ],
              [
                -49.59317116179508,
                -23.406280830819565
              ],
              [
                -49.594088653147324,
                -23.40637581518756
              ],
              [
                -49.60318559131557,
                -23.407320368658226
              ],
              [
                -49.603849091205575,
                -23.401894892157767
              ],
              [
                -49.601791137699266,
                -23.400735869770457
              ],
              [
                -49.59818313841872,
                -23.399046376919554
              ],
              [
                -49.60494263656838,
                -23.393948933411824
              ],
              [
                -49.61887248663639,
                -23.396485422819673
              ],
              [
                -49.62044998451997,
                -23.3898489920907
              ],
              [
                -49.60515963305578,
                -23.384260463267598
              ],
              [
                -49.602834166581566,
                -23.383410944798012
              ],
              [
                -49.591060234847966,
                -23.388097909925246
              ],
              [
                -49.58991278046403,
                -23.38229841855295
              ],
              [
                -49.595449729146324,
                -23.374703457741383
              ],
              [
                -49.594789271544684,
                -23.366443514063366
              ],
              [
                -49.61167917320351,
                -23.354192621713185
              ],
              [
                -49.62464960118756,
                -23.35525116129853
              ],
              [
                -49.63294949272012,
                -23.355928148437922
              ],
              [
                -49.63337399975114,
                -23.355244671551247
              ],
              [
                -49.63710650326126,
                -23.349226201755528
              ],
              [
                -49.63572405232382,
                -23.339279742902125
              ],
              [
                -49.628950072196375,
                -23.343266690990905
              ],
              [
                -49.62565864026487,
                -23.33819623016528
              ],
              [
                -49.6212471963191,
                -23.33140074215369
              ],
              [
                -49.62278573126264,
                -23.317994271502197
              ],
              [
                -49.625987723279174,
                -23.31245082416109
              ],
              [
                -49.630900185102625,
                -23.30394386827674
              ],
              [
                -49.62532231209701,
                -23.286130937661927
              ],
              [
                -49.62779030384793,
                -23.28089494285341
              ],
              [
                -49.63959379388612,
                -23.255838085544557
              ],
              [
                -49.64661073037072,
                -23.251192651903516
              ],
              [
                -49.64538579191838,
                -23.244118153703706
              ],
              [
                -49.64460878581308,
                -23.23963467905748
              ],
              [
                -49.64774427295806,
                -23.2379002136681
              ],
              [
                -49.64922975402675,
                -23.2370781975387
              ],
              [
                -49.6540817348256,
                -23.227266748940288
              ],
              [
                -49.653402799735005,
                -23.21141230299925
              ],
              [
                -49.674440728588905,
                -23.17794450845799
              ],
              [
                -49.674568244832635,
                -23.17787102914545
              ],
              [
                -49.682775174235026,
                -23.1731215584718
              ],
              [
                -49.68047370841495,
                -23.169143069197798
              ],
              [
                -49.67782974637921,
                -23.16457259137677
              ],
              [
                -49.68425573638524,
                -23.164273103811524
              ],
              [
                -49.69777062426365,
                -23.163642143798942
              ],
              [
                -49.70305810563066,
                -23.157816196890135
              ],
              [
                -49.70090712712806,
                -23.152540688478148
              ],
              [
                -49.70933453294301,
                -23.156344681768964
              ],
              [
                -49.71600449748244,
                -23.147901273902058
              ],
              [
                -49.73000092466042,
                -23.140955332656237
              ],
              [
                -49.73441740548729,
                -23.13239737753413
              ],
              [
                -49.73033148071153,
                -23.122130924179498
              ],
              [
                -49.725946572903396,
                -23.11111293809797
              ],
              [
                -49.726584045034166,
                -23.10790194240678
              ],
              [
                -49.740866949466515,
                -23.098796531002634
              ],
              [
                -49.74230095156862,
                -23.098947552646244
              ],
              [
                -49.75089339128549,
                -23.09726805827007
              ],
              [
                -49.75704834599324,
                -23.10142806925634
              ],
              [
                -49.76245928235163,
                -23.10508506413277
              ],
              [
                -49.77786568284993,
                -23.100892135573282
              ],
              [
                -49.78991406012107,
                -23.101825642621282
              ],
              [
                -49.79458406344743,
                -23.094847206648037
              ],
              [
                -49.79508355909386,
                -23.09410019925267
              ],
              [
                -49.795886058800804,
                -23.094081700723432
              ],
              [
                -49.80611447060176,
                -23.093845240220475
              ],
              [
                -49.80861496226314,
                -23.087973274157267
              ],
              [
                -49.80939396447584,
                -23.086141772116218
              ],
              [
                -49.8122234582313,
                -23.079493323274047
              ],
              [
                -49.81486344947126,
                -23.0783033231078
              ],
              [
                -49.81905543085676,
                -23.076414849481164
              ],
              [
                -49.8196219189113,
                -23.0762598578165
              ],
              [
                -49.82650036800572,
                -23.074378867168665
              ],
              [
                -49.83317034828741,
                -23.065454445260777
              ],
              [
                -49.84961118842503,
                -23.0725594626755
              ],
              [
                -49.850088685927204,
                -23.07276591923969
              ],
              [
                -49.86692454417429,
                -23.07120199297833
              ],
              [
                -49.87105902317103,
                -23.06927901932112
              ],
              [
                -49.87514802236161,
                -23.065395556691023
              ],
              [
                -49.88120996248168,
                -23.059637587750945
              ],
              [
                -49.882412943490436,
                -23.061210080791497
              ],
              [
                -49.88487393681695,
                -23.064428090735507
              ],
              [
                -49.89620789281311,
                -23.05535612631509
              ],
              [
                -49.91111174247973,
                -23.050628184455043
              ],
              [
                -49.913509290076526,
                -23.04329422513232
              ],
              [
                -49.91590725966071,
                -23.03596075981265
              ],
              [
                -49.90897389292145,
                -23.013688351925136
              ],
              [
                -49.90387043018037,
                -23.009679362435758
              ],
              [
                -49.901729973937044,
                -23.007997848072264
              ],
              [
                -49.89964351997037,
                -23.00409885511777
              ],
              [
                -49.90104401278001,
                -22.99866440795016
              ],
              [
                -49.93027133689633,
                -22.982542038165594
              ],
              [
                -49.954058158194115,
                -22.977909627268883
              ],
              [
                -49.96470811040228,
                -22.966313205290795
              ],
              [
                -49.971662066810495,
                -22.95874125353893
              ],
              [
                -49.973554573452645,
                -22.954252272879188
              ],
              [
                -49.965918670073464,
                -22.94577828550229
              ],
              [
                -49.96311171798932,
                -22.937187297993702
              ],
              [
                -49.960888260103005,
                -22.930381840783767
              ],
              [
                -49.96276274118161,
                -22.923200357325676
              ],
              [
                -49.96463779224945,
                -22.91601840487565
              ],
              [
                -49.98567165610688,
                -22.89702054663828
              ],
              [
                -49.99728804183481,
                -22.90098256458583
              ],
              [
                -49.99355653052492,
                -22.913039517196097
              ],
              [
                -49.99019202240834,
                -22.92391045685416
              ],
              [
                -49.99575998571977,
                -22.92904596198805
              ],
              [
                -50.0052264306503,
                -22.926865491218894
              ],
              [
                -50.01703286872043,
                -22.91037557205318
              ],
              [
                -50.02416281684932,
                -22.909420604220955
              ],
              [
                -50.02418682067057,
                -22.90941760930478
              ],
              [
                -50.03134126662652,
                -22.90845913956092
              ],
              [
                -50.04614817034676,
                -22.902006684454587
              ],
              [
                -50.051574597449715,
                -22.90696321093259
              ],
              [
                -50.066978425620206,
                -22.927025625223152
              ],
              [
                -50.071059398373926,
                -22.92763565883015
              ],
              [
                -50.08031534236111,
                -22.921336183582433
              ],
              [
                -50.080359316117224,
                -22.921306703835956
              ],
              [
                -50.094202698729,
                -22.923450713121234
              ],
              [
                -50.10193161737533,
                -22.929368233533072
              ],
              [
                -50.11296151627107,
                -22.937812728301463
              ],
              [
                -50.13302485534677,
                -22.939282254989635
              ],
              [
                -50.13977181325056,
                -22.93756631822265
              ],
              [
                -50.150974247065875,
                -22.934718317434672
              ],
              [
                -50.158149156532886,
                -22.94050932680239
              ],
              [
                -50.165323080003795,
                -22.946300328184847
              ],
              [
                -50.18284392305333,
                -22.953269849582163
              ],
              [
                -50.19637379195916,
                -22.952633866604806
              ],
              [
                -50.20338924999771,
                -22.952303431918335
              ],
              [
                -50.20742524958002,
                -22.950785429253724
              ],
              [
                -50.216390163911505,
                -22.94741296198237
              ],
              [
                -50.223616095399635,
                -22.949644482323553
              ],
              [
                -50.23373103710385,
                -22.952767982611878
              ],
              [
                -50.23815449987863,
                -22.952624022422
              ],
              [
                -50.24124345558488,
                -22.950627537907724
              ],
              [
                -50.24269146030241,
                -22.946871041770883
              ],
              [
                -50.24611449035272,
                -22.937991569726865
              ],
              [
                -50.2676648012048,
                -22.931974174971867
              ],
              [
                -50.27027980523231,
                -22.931244195800144
              ],
              [
                -50.27289677724904,
                -22.93051321163845
              ],
              [
                -50.28876961709593,
                -22.93702122815349
              ],
              [
                -50.29991954718436,
                -22.93744671671585
              ],
              [
                -50.30441748546296,
                -22.943890715547298
              ],
              [
                -50.30569846102363,
                -22.94572569480056
              ],
              [
                -50.31000642579375,
                -22.94729073622099
              ],
              [
                -50.31800286598089,
                -22.95019523814851
              ],
              [
                -50.33752170220299,
                -22.947846279229662
              ],
              [
                -50.339693178100696,
                -22.947584782804963
              ],
              [
                -50.344908706040485,
                -22.93416385776744
              ],
              [
                -50.358557620424,
                -22.92203044063815
              ],
              [
                -50.3580896450721,
                -22.916581496722678
              ],
              [
                -50.36862908798065,
                -22.910715545429145
              ],
              [
                -50.37989351563623,
                -22.904446094962303
              ],
              [
                -50.38762096241564,
                -22.905520620625715
              ],
              [
                -50.395349393191324,
                -22.906596125293177
              ],
              [
                -50.41638471910428,
                -22.9127706577493
              ],
              [
                -50.418452661147384,
                -22.915002657740526
              ],
              [
                -50.418556148876156,
                -22.925457593989872
              ],
              [
                -50.41864011981967,
                -22.93388308855118
              ],
              [
                -50.42747348762525,
                -22.944507037285668
              ],
              [
                -50.44604986047095,
                -22.941034135226662
              ],
              [
                -50.45346832541855,
                -22.94276511033984
              ],
              [
                -50.46088626537592,
                -22.94449614745749
              ],
              [
                -50.47053619470676,
                -22.941702704863403
              ],
              [
                -50.48723754208477,
                -22.93686873578975
              ],
              [
                -50.49376898221143,
                -22.9402572624553
              ],
              [
                -50.50439540092177,
                -22.945770254692803
              ],
              [
                -50.51242285153043,
                -22.942822803870214
              ],
              [
                -50.52003529085522,
                -22.94002830217362
              ],
              [
                -50.526399271699475,
                -22.934441872186135
              ],
              [
                -50.52842428571216,
                -22.92419392731563
              ],
              [
                -50.52952728159166,
                -22.918615938519313
              ],
              [
                -50.54088373941945,
                -22.91161200231184
              ],
              [
                -50.54442819114159,
                -22.911502531385533
              ],
              [
                -50.55390462121998,
                -22.91121005699372
              ],
              [
                -50.576513478848526,
                -22.90348561146507
              ],
              [
                -50.608379165099,
                -22.92330815882928
              ],
              [
                -50.61226414948153,
                -22.919402660484334
              ],
              [
                -50.62001308333619,
                -22.91161370263866
              ],
              [
                -50.63674948565826,
                -22.906261273785244
              ],
              [
                -50.648317426477554,
                -22.896560847212903
              ],
              [
                -50.66179380727087,
                -22.895208394191513
              ],
              [
                -50.675533683434644,
                -22.900033422697827
              ],
              [
                -50.70933676490484,
                -22.952950285222325
              ],
              [
                -50.72935006535429,
                -22.96201380255441
              ],
              [
                -50.74109501387824,
                -22.960908346445954
              ],
              [
                -50.74630945707326,
                -22.955002887210433
              ],
              [
                -50.7498334536873,
                -22.95101240208114
              ],
              [
                -50.75816541741446,
                -22.947165936847682
              ],
              [
                -50.7774517361682,
                -22.94583701436482
              ],
              [
                -50.78876665408597,
                -22.948158034017784
              ],
              [
                -50.79577761756105,
                -22.94959552430278
              ],
              [
                -50.80251207869752,
                -22.942922068749326
              ],
              [
                -50.80565005724726,
                -22.939813106854206
              ],
              [
                -50.79284374928195,
                -22.905176216306675
              ],
              [
                -50.792642268755735,
                -22.90463174391893
              ],
              [
                -50.792452295956494,
                -22.893867785215974
              ],
              [
                -50.796020271527055,
                -22.889791796561287
              ],
              [
                -50.804232224830436,
                -22.8854898347913
              ],
              [
                -50.81651564800949,
                -22.879054884954062
              ],
              [
                -50.82225516051397,
                -22.86732994999866
              ],
              [
                -50.82325216890438,
                -22.86529347029699
              ],
              [
                -50.82398363725253,
                -22.86471498385363
              ],
              [
                -50.825686659750936,
                -22.86336799846298
              ],
              [
                -50.83272458361861,
                -22.857802046303064
              ],
              [
                -50.84188560153811,
                -22.830923165427983
              ],
              [
                -50.89039236285991,
                -22.79532593319596
              ],
              [
                -50.91855861251579,
                -22.795385515472468
              ],
              [
                -50.92627007203789,
                -22.798757520882724
              ],
              [
                -50.9391344448163,
                -22.804379530618668
              ],
              [
                -50.94463042404177,
                -22.80358906934426
              ],
              [
                -50.961116821819566,
                -22.785743175498812
              ],
              [
                -50.976339232508344,
                -22.783329751027367
              ],
              [
                -50.978093706913235,
                -22.783051223429084
              ],
              [
                -50.9940865361612,
                -22.79166424243184
              ],
              [
                -51.00361995663895,
                -22.79218576995849
              ],
              [
                -51.05711415238079,
                -22.76557304683467
              ],
              [
                -51.11308820566337,
                -22.760364697202046
              ],
              [
                -51.15439644611524,
                -22.75238586575921
              ],
              [
                -51.162071395748406,
                -22.74701738159159
              ],
              [
                -51.17470281633087,
                -22.738181461371322
              ],
              [
                -51.17553082897538,
                -22.73760294520462
              ],
              [
                -51.186515245024886,
                -22.729407047430996
              ],
              [
                -51.211556555115855,
                -22.72372512772661
              ],
              [
                -51.234846934167415,
                -22.70819772760446
              ],
              [
                -51.24071945099834,
                -22.696455326792506
              ],
              [
                -51.24659143781907,
                -22.684712888983455
              ],
              [
                -51.263286855777245,
                -22.668569983497985
              ],
              [
                -51.280928217208654,
                -22.665993541899162
              ],
              [
                -51.283421686272916,
                -22.66679104015832
              ],
              [
                -51.29611609360571,
                -22.670851084856068
              ],
              [
                -51.305796012482695,
                -22.673942083436703
              ],
              [
                -51.3215463856403,
                -22.669905134091035
              ],
              [
                -51.33278481742894,
                -22.667024694509333
              ],
              [
                -51.37321001989146,
                -22.657686851742067
              ],
              [
                -51.382529444001975,
                -22.6623938397598
              ],
              [
                -51.38603941212254,
                -22.66496233994986
              ],
              [
                -51.38697388343786,
                -22.66511133915921
              ],
              [
                -51.40144627044406,
                -22.66741686539461
              ],
              [
                -51.424222664172945,
                -22.653027496942418
              ],
              [
                -51.44579499682372,
                -22.65277757685031
              ],
              [
                -51.471434189077506,
                -22.676647031946864
              ],
              [
                -51.4984044447477,
                -22.690047538379858
              ],
              [
                -51.516731809349714,
                -22.68953009174458
              ],
              [
                -51.52519377887273,
                -22.686321651156824
              ],
              [
                -51.54779606420764,
                -22.691983694646435
              ],
              [
                -51.553431001871154,
                -22.694339662209764
              ],
              [
                -51.559065981536335,
                -22.696695670778713
              ],
              [
                -51.58954078199071,
                -22.685206335860077
              ],
              [
                -51.615338648976206,
                -22.66211147428679
              ],
              [
                -51.61978259407697,
                -22.660679016439506
              ],
              [
                -51.628084063104495,
                -22.65800201422098
              ],
              [
                -51.65882334156981,
                -22.656871623549634
              ],
              [
                -51.66183131101995,
                -22.65676064407706
              ],
              [
                -51.67122921916034,
                -22.6585286713858
              ],
              [
                -51.68062712131143,
                -22.66029665770355
              ],
              [
                -51.68152362697946,
                -22.660723675704432
              ],
              [
                -51.69866949964383,
                -22.6688881968868
              ],
              [
                -51.710238907240914,
                -22.668672206728015
              ],
              [
                -51.7176643251562,
                -22.66853322973587
              ],
              [
                -51.725371281072896,
                -22.660949280323642
              ],
              [
                -51.7308818385406,
                -22.63973141273209
              ],
              [
                -51.73473083417509,
                -22.62491147558423
              ],
              [
                -51.74344579725825,
                -22.62336248008906
              ],
              [
                -51.74676476406066,
                -22.62277301146641
              ],
              [
                -51.74933523573314,
                -22.62042200762277
              ],
              [
                -51.752781218545415,
                -22.61727105461877
              ],
              [
                -51.77292609344148,
                -22.614082111184896
              ],
              [
                -51.84658947050312,
                -22.631743260402036
              ],
              [
                -51.85527089219141,
                -22.630992294658498
              ],
              [
                -51.868864817637494,
                -22.628517833642164
              ],
              [
                -51.87301078080515,
                -22.627763337190988
              ],
              [
                -51.88511419380753,
                -22.616401418027678
              ],
              [
                -51.889053693884726,
                -22.61270345666164
              ],
              [
                -51.89216620233032,
                -22.60983447749409
              ],
              [
                -51.90121016395916,
                -22.601495524033563
              ],
              [
                -51.940007976141025,
                -22.566862257145832
              ],
              [
                -51.95018739879806,
                -22.564919304636973
              ],
              [
                -51.960367803464436,
                -22.562976330131583
              ],
              [
                -51.99770408722918,
                -22.545126515938435
              ],
              [
                -52.02863185520385,
                -22.5394091101221
              ],
              [
                -52.03342382065929,
                -22.53920762340585
              ],
              [
                -52.040272796864535,
                -22.53891916225368
              ],
              [
                -52.04763373927889,
                -22.53860968073441
              ],
              [
                -52.05053818457647,
                -22.538249687944244
              ],
              [
                -52.0555581623148,
                -22.538336209777892
              ],
              [
                -52.06415660907534,
                -22.53383576363634
              ],
              [
                -52.08475849414761,
                -22.523051364712497
              ],
              [
                -52.08912646715304,
                -22.520764863988052
              ],
              [
                -52.09812339315516,
                -22.51848942121144
              ],
              [
                -52.098129394116924,
                -22.518487408237586
              ],
              [
                -52.107132819086765,
                -22.516210940483997
              ],
              [
                -52.110181311537325,
                -22.51664692897113
              ],
              [
                -52.12516669887845,
                -22.51878995578793
              ],
              [
                -52.1348950901524,
                -22.52743047998355
              ],
              [
                -52.148253910439145,
                -22.548771920951122
              ],
              [
                -52.15008684665267,
                -22.558723888143557
              ],
              [
                -52.15191880885586,
                -22.568674854356
              ],
              [
                -52.15618327889629,
                -22.582258792614198
              ],
              [
                -52.16091067620981,
                -22.597317271498934
              ],
              [
                -52.16113213728926,
                -22.6013632552016
              ],
              [
                -52.161353611365286,
                -22.605409232906872
              ],
              [
                -52.15617006656587,
                -22.644238553021502
              ],
              [
                -52.18105284885076,
                -22.65297058733817
              ],
              [
                -52.196672201594474,
                -22.658449616330774
              ],
              [
                -52.21058158446493,
                -22.667545616931832
              ],
              [
                -52.21860198739612,
                -22.67278961476588
              ],
              [
                -52.222988936635744,
                -22.673825138070413
              ],
              [
                -52.22774891660277,
                -22.66481968396044
              ],
              [
                -52.228655950043596,
                -22.6537147413659
              ],
              [
                -52.2299760192458,
                -22.637544294964197
              ],
              [
                -52.23962247558421,
                -22.61940287268117
              ],
              [
                -52.2499039118166,
                -22.61494742548809
              ],
              [
                -52.27817521831114,
                -22.61990250604572
              ],
              [
                -52.289751090917456,
                -22.62703651627191
              ],
              [
                -52.29946798856445,
                -22.63302750335407
              ],
              [
                -52.304275420883634,
                -22.635991520372283
              ],
              [
                -52.36918547396296,
                -22.628503206610908
              ],
              [
                -52.42085516507182,
                -22.61086544023049
              ],
              [
                -52.4338980542395,
                -22.607158490510606
              ],
              [
                -52.43478104649749,
                -22.606907501185354
              ],
              [
                -52.43572053602426,
                -22.607025982587366
              ],
              [
                -52.446364478354454,
                -22.608371009795768
              ],
              [
                -52.45824435442279,
                -22.612258531849662
              ],
              [
                -52.483406131734256,
                -22.62662104959228
              ],
              [
                -52.489622547519026,
                -22.630169536739754
              ],
              [
                -52.49620798405186,
                -22.631881037187878
              ],
              [
                -52.50279293759286,
                -22.633592563639702
              ],
              [
                -52.520369330568556,
                -22.621251661466214
              ],
              [
                -52.52298081349291,
                -22.619418180593442
              ],
              [
                -52.53312626085072,
                -22.615638740325846
              ],
              [
                -52.536486238111436,
                -22.61438624047414
              ],
              [
                -52.53991272495352,
                -22.61311176591093
              ],
              [
                -52.56662212716218,
                -22.588763446383876
              ],
              [
                -52.579758090474535,
                -22.56852256337504
              ],
              [
                -52.5862185319086,
                -22.566039576769594
              ],
              [
                -52.60273989569578,
                -22.570727579935177
              ],
              [
                -52.6624363021408,
                -22.61138059137103
              ],
              [
                -52.70116793625208,
                -22.626990663474437
              ],
              [
                -52.71154637001468,
                -22.62663116967868
              ],
              [
                -52.721924809791766,
                -22.626272227884908
              ],
              [
                -52.7497866459872,
                -22.611888829974472
              ],
              [
                -52.77007603255313,
                -22.60544243111181
              ],
              [
                -52.7794789370526,
                -22.60245547248966
              ],
              [
                -52.782441921897295,
                -22.601910456680564
              ],
              [
                -52.81492471078672,
                -22.596566573928715
              ],
              [
                -52.84434843027644,
                -22.608742131181774
              ],
              [
                -52.857848327091645,
                -22.607523667320333
              ],
              [
                -52.86570781676743,
                -22.60297821797689
              ],
              [
                -52.88490067648048,
                -22.587654818973807
              ],
              [
                -52.9048480897612,
                -22.57172996157942
              ],
              [
                -52.91453753042191,
                -22.56843849599094
              ],
              [
                -52.92422646310178,
                -22.565147015398708
              ],
              [
                -52.97190912255129,
                -22.57000715300999
              ],
              [
                -52.978129541911606,
                -22.572942149818875
              ],
              [
                -52.98435100626628,
                -22.575877148638693
              ],
              [
                -53.011938221273454,
                -22.597539146206035
              ],
              [
                -53.024000611700224,
                -22.603721647588504
              ],
              [
                -53.03801092695976,
                -22.617945157178223
              ],
              [
                -53.04548789553326,
                -22.624090134663188
              ],
              [
                -53.052964780101306,
                -22.630235653165574
              ],
              [
                -53.07420507955795,
                -22.640865127972454
              ],
              [
                -53.08730143536724,
                -22.65792361817674
              ],
              [
                -53.094071456373875,
                -22.637489702151605
              ],
              [
                -53.10466893923559,
                -22.621452309139393
              ],
              [
                -53.10818994505108,
                -22.613091876960183
              ],
              [
                -53.10819293805582,
                -22.613084350998136
              ],
              [
                -53.107750515453226,
                -22.59682239984016
              ],
              [
                -53.080933328428195,
                -22.557773976919183
              ],
              [
                -53.055428087170924,
                -22.53537549555591
              ],
              [
                -53.01314943885502,
                -22.5192789707651
              ],
              [
                -53.007272544202,
                -22.50892647661813
              ],
              [
                -53.004139072033055,
                -22.503406512283764
              ],
              [
                -52.99806618902217,
                -22.49270899691678
              ],
              [
                -52.947675090241106,
                -22.472875463325487
              ],
              [
                -52.88532264883146,
                -22.452396345166637
              ],
              [
                -52.85732838616359,
                -22.44100481897017
              ],
              [
                -52.85578841979378,
                -22.439820322872958
              ],
              [
                -52.8542464024426,
                -22.438634827774205
              ],
              [
                -52.83937160594418,
                -22.414835389905818
              ],
              [
                -52.79781702935674,
                -22.37779592570741
              ],
              [
                -52.74531954879486,
                -22.34283741888079
              ],
              [
                -52.734538623460594,
                -22.338735397253163
              ],
              [
                -52.723755232155774,
                -22.33463289363902
              ],
              [
                -52.69462004795947,
                -22.314132894745807
              ],
              [
                -52.65637732986404,
                -22.303322319547302
              ],
              [
                -52.624427168155094,
                -22.27982631942043
              ],
              [
                -52.612952767339024,
                -22.275649316243854
              ],
              [
                -52.60147887652856,
                -22.27147279408776
              ],
              [
                -52.578674091353975,
                -22.25147479918107
              ],
              [
                -52.550027823664536,
                -22.242455759545464
              ],
              [
                -52.5227231139893,
                -22.228545230397405
              ],
              [
                -52.50292776659336,
                -22.224909190294195
              ],
              [
                -52.49249935138132,
                -22.219483714444173
              ],
              [
                -52.48624840664231,
                -22.21471771254701
              ],
              [
                -52.48258144181526,
                -22.206992705141538
              ],
              [
                -52.479454488000634,
                -22.200405251863675
              ],
              [
                -52.45669475730055,
                -22.184490233737115
              ],
              [
                -52.4076782406828,
                -22.141157791265616
              ],
              [
                -52.39750789148185,
                -22.121505332158147
              ],
              [
                -52.39076846186298,
                -22.115133331435658
              ],
              [
                -52.3767080788678,
                -22.10620133910143
              ],
              [
                -52.34492397235452,
                -22.061492418016567
              ],
              [
                -52.31857894380703,
                -21.97189023295158
              ],
              [
                -52.31344601308159,
                -21.961665263824614
              ],
              [
                -52.308313084335836,
                -21.951439788729296
              ],
              [
                -52.27537747334152,
                -21.915474343105313
              ],
              [
                -52.268133003010234,
                -21.913303330455726
              ],
              [
                -52.263188543100476,
                -21.911822331823313
              ],
              [
                -52.25892508690836,
                -21.90626535764477
              ],
              [
                -52.247899222696574,
                -21.901805822090232
              ],
              [
                -52.23169140066946,
                -21.88219234648232
              ],
              [
                -52.190057808460274,
                -21.84566588765644
              ],
              [
                -52.18362191943786,
                -21.827379970841605
              ],
              [
                -52.183449420914755,
                -21.823172487395066
              ],
              [
                -52.183038956806485,
                -21.813137509889312
              ],
              [
                -52.17500709906252,
                -21.79769607897258
              ],
              [
                -52.160755785124906,
                -21.770296623492637
              ],
              [
                -52.153415857709774,
                -21.765979627539732
              ],
              [
                -52.13954350483608,
                -21.755254618548648
              ],
              [
                -52.10239630486307,
                -21.74479510045537
              ],
              [
                -52.09785086483668,
                -21.73738410938163
              ],
              [
                -52.093305398797675,
                -21.729973613323214
              ],
              [
                -52.07597809816367,
                -21.71437764337426
              ],
              [
                -52.04932747807039,
                -21.660860775545665
              ],
              [
                -52.04916848862141,
                -21.651747844409176
              ],
              [
                -52.04901001714925,
                -21.642635872282487
              ],
              [
                -52.05897298151141,
                -21.62573897551073
              ],
              [
                -52.062284483312325,
                -21.6201225161615
              ],
              [
                -52.065330473818555,
                -21.608875063129336
              ],
              [
                -52.0683759953173,
                -21.597625090115926
              ],
              [
                -52.09948285578163,
                -21.57021781984912
              ],
              [
                -52.102786845558406,
                -21.55858787214698
              ],
              [
                -52.09274452548409,
                -21.53758340425781
              ],
              [
                -52.07728468002393,
                -21.514746986342427
              ],
              [
                -52.06595082319553,
                -21.504117996278747
              ],
              [
                -52.05656186691141,
                -21.50313496117195
              ],
              [
                -51.99766578958545,
                -21.515155277371054
              ],
              [
                -51.96739856886785,
                -21.501287727138294
              ],
              [
                -51.9630096197654,
                -21.49555574413551
              ],
              [
                -51.94986026153019,
                -21.471602329187935
              ],
              [
                -51.939018906810254,
                -21.463472327568713
              ],
              [
                -51.92817797608262,
                -21.45534233898787
              ],
              [
                -51.9206951135684,
                -21.43183489082242
              ],
              [
                -51.919201649743,
                -21.421793464150447
              ],
              [
                -51.91770819489723,
                -21.411751990496942
              ],
              [
                -51.905629409163495,
                -21.38475355685853
              ],
              [
                -51.86719126906518,
                -21.351186119227567
              ],
              [
                -51.864196805050106,
                -21.343692620851424
              ],
              [
                -51.86120188302988,
                -21.33619817149204
              ],
              [
                -51.857158498130765,
                -21.29729830308699
              ],
              [
                -51.848012663117274,
                -21.266164406945443
              ],
              [
                -51.85089863966897,
                -21.26330944410201
              ],
              [
                -51.85378565921344,
                -21.260454447261143
              ],
              [
                -51.86356817300753,
                -21.22548614427372
              ],
              [
                -51.87880862631622,
                -21.197405788082357
              ],
              [
                -51.875259723417805,
                -21.180571350432896
              ],
              [
                -51.87766779675407,
                -21.15597147328424
              ],
              [
                -51.87848029044535,
                -21.14766502539111
              ],
              [
                -51.875361831532736,
                -21.135675564791924
              ],
              [
                -51.864975965647616,
                -21.130068053849943
              ],
              [
                -51.84409211466556,
                -21.13098847277958
              ],
              [
                -51.82309077847036,
                -21.126367459663786
              ],
              [
                -51.813953366743476,
                -21.120915456685335
              ],
              [
                -51.811588406940125,
                -21.11674746250458
              ],
              [
                -51.8075049268004,
                -21.10954998420129
              ],
              [
                -51.78917111114132,
                -21.101645464575167
              ],
              [
                -51.775145784820204,
                -21.072622560149178
              ],
              [
                -51.77163540321124,
                -21.048282177857185
              ],
              [
                -51.7528331387527,
                -21.013864754158483
              ],
              [
                -51.72469196562676,
                -20.97967683859176
              ],
              [
                -51.718944507740574,
                -20.975330849844898
              ],
              [
                -51.71319756584768,
                -20.970985339108033
              ],
              [
                -51.66556391761472,
                -20.970060717300733
              ],
              [
                -51.62618728732007,
                -20.94587573459966
              ],
              [
                -51.622338338446355,
                -20.938912241425403
              ],
              [
                -51.61848990755599,
                -20.931949751262717
              ],
              [
                -51.612983564213174,
                -20.889602429146887
              ],
              [
                -51.61882303455466,
                -20.881265469980622
              ],
              [
                -51.62466301488907,
                -20.87292853281469
              ],
              [
                -51.62987607356388,
                -20.851414158072277
              ],
              [
                -51.6324786056205,
                -20.82108579470367
              ],
              [
                -51.626211772748235,
                -20.790940375990303
              ],
              [
                -51.626390269289985,
                -20.789386909124804
              ],
              [
                -51.62698378902465,
                -20.784233923796485
              ],
              [
                -51.62602427661677,
                -20.78284592706045
              ],
              [
                -51.62326282497303,
                -20.778852424452968
              ],
              [
                -51.6343988169773,
                -20.75437905604571
              ],
              [
                -51.62771643469589,
                -20.724598687947225
              ],
              [
                -51.59571038519678,
                -20.6482939376769
              ],
              [
                -51.591088953760085,
                -20.64131244991925
              ],
              [
                -51.586467497317514,
                -20.634329977181842
              ],
              [
                -51.57621662002642,
                -20.624854475656715
              ],
              [
                -51.56972769548074,
                -20.619848990302582
              ],
              [
                -51.56717918779481,
                -20.617882993738675
              ],
              [
                -51.522989137105725,
                -20.58817150708335
              ],
              [
                -51.499729352801815,
                -20.567745038325842
              ],
              [
                -51.49707337264977,
                -20.560377075868946
              ],
              [
                -51.4944174424849,
                -20.553009616423036
              ],
              [
                -51.46328583600732,
                -20.50098075331013
              ],
              [
                -51.45600990161331,
                -20.49166325879648
              ],
              [
                -51.44873496019828,
                -20.482345280319468
              ],
              [
                -51.42098625125,
                -20.46620381657142
              ],
              [
                -51.42013826116757,
                -20.465710308399625
              ],
              [
                -51.4105223549566,
                -20.460112284472054
              ],
              [
                -51.40878487618214,
                -20.459100310128214
              ],
              [
                -51.403458393726886,
                -20.452156323786024
              ],
              [
                -51.390780566129465,
                -20.42749490853715
              ],
              [
                -51.37461930845628,
                -20.396056978529973
              ],
              [
                -51.3656818835387,
                -20.388343509869074
              ],
              [
                -51.36072993200103,
                -20.384070022054857
              ],
              [
                -51.35938645961775,
                -20.381299031497665
              ],
              [
                -51.350374052467735,
                -20.36270959719269
              ],
              [
                -51.34290266238201,
                -20.355342114105618
              ],
              [
                -51.322473328662475,
                -20.340271093598954
              ],
              [
                -51.32100437606401,
                -20.339187106359816
              ],
              [
                -51.30809846160079,
                -20.33360058383421
              ],
              [
                -51.29519209815524,
                -20.328013107341626
              ],
              [
                -51.23948454652467,
                -20.31647349217733
              ],
              [
                -51.166874111372096,
                -20.305894882459903
              ],
              [
                -51.15874019673943,
                -20.303672350282156
              ],
              [
                -51.15060575411748,
                -20.301449875110965
              ],
              [
                -51.127236469684846,
                -20.29206533840889
              ],
              [
                -51.10052623025113,
                -20.273551360080972
              ],
              [
                -51.06867102532342,
                -20.249579388246698
              ],
              [
                -51.06327611977768,
                -20.241122420138307
              ],
              [
                -51.057881641212425,
                -20.232666456049547
              ],
              [
                -51.049854309533075,
                -20.20364754396863
              ],
              [
                -51.04832184783418,
                -20.198106092407667
              ],
              [
                -51.0445253777247,
                -20.188767621613344
              ],
              [
                -51.04452241076833,
                -20.188760596636538
              ],
              [
                -51.0407239446718,
                -20.179417147880937
              ],
              [
                -51.03953497332478,
                -20.17654412843651
              ],
              [
                -51.02874959835925,
                -20.1504907541758
              ],
              [
                -51.00136297886235,
                -20.09580790912849
              ],
              [
                -50.99998499885325,
                -20.084914443950506
              ],
              [
                -50.99689354111299,
                -20.080291968298283
              ],
              [
                -50.96597241357196,
                -20.03405912618301
              ],
              [
                -50.94905554877991,
                -20.031112584405246
              ],
              [
                -50.9486745488724,
                -20.031045594692706
              ],
              [
                -50.94479708350691,
                -20.02862760319792
              ],
              [
                -50.935375676168476,
                -20.0171486274903
              ],
              [
                -50.93317022590231,
                -20.014460622656326
              ],
              [
                -50.927489292234704,
                -20.011368136415637
              ],
              [
                -50.91138940555201,
                -20.00260411193798
              ],
              [
                -50.90951692985254,
                -19.998196147705347
              ],
              [
                -50.90764399115436,
                -19.9937876404789
              ],
              [
                -50.89649859001184,
                -19.989682643341368
              ],
              [
                -50.88561317447478,
                -19.98943561962417
              ],
              [
                -50.873436763421736,
                -19.98829311050903
              ],
              [
                -50.87127077249209,
                -19.98808957968806
              ],
              [
                -50.86874627364852,
                -19.985738587545907
              ],
              [
                -50.852890442917634,
                -19.970972137836178
              ],
              [
                -50.84359254904009,
                -19.96711413857284
              ],
              [
                -50.82123920794023,
                -19.95938211123843
              ],
              [
                -50.816847272844,
                -19.95786361746283
              ],
              [
                -50.80304638540249,
                -19.94819959947405
              ],
              [
                -50.79355097529286,
                -19.941550603329446
              ],
              [
                -50.7883345130634,
                -19.93915612934556
              ],
              [
                -50.78311459786339,
                -19.936761129360082
              ],
              [
                -50.72462905901647,
                -19.926350520172697
              ],
              [
                -50.68292590380475,
                -19.91483995539601
              ],
              [
                -50.656408618264315,
                -19.904905938593018
              ],
              [
                -50.63569837920243,
                -19.874595544388566
              ],
              [
                -50.61603058235335,
                -19.861263552238757
              ],
              [
                -50.61023612249694,
                -19.85821854086399
              ],
              [
                -50.605758181566536,
                -19.85586555480564
              ],
              [
                -50.59082133623811,
                -19.833755629037103
              ],
              [
                -50.58583039392161,
                -19.826367134536383
              ],
              [
                -50.5806664481363,
                -19.82095965760159
              ],
              [
                -50.57550100736276,
                -19.815550646682205
              ],
              [
                -50.56154163408505,
                -19.813114657293735
              ],
              [
                -50.54667573834558,
                -19.810133623035714
              ],
              [
                -50.51732401696836,
                -19.796295116445922
              ],
              [
                -50.5165595098602,
                -19.79593511109218
              ],
              [
                -50.50763960393816,
                -19.795630585223368
              ],
              [
                -50.507632093996975,
                -19.79563010620598
              ],
              [
                -50.49870463614363,
                -19.79532559032149
              ],
              [
                -50.495939671562326,
                -19.793168600945094
              ],
              [
                -50.485699264112036,
                -19.78517858068964
              ],
              [
                -50.471423904724105,
                -19.779184579856892
              ],
              [
                -50.45400700743973,
                -19.785567504385966
              ],
              [
                -50.414026275012425,
                -19.803293333364895
              ],
              [
                -50.37241949176999,
                -19.844907548931207
              ],
              [
                -50.36772550673436,
                -19.848693534404944
              ],
              [
                -50.36566103864334,
                -19.84969651644182
              ],
              [
                -50.3566060802615,
                -19.854098956725387
              ],
              [
                -50.35587707989877,
                -19.85579946905875
              ],
              [
                -50.3523140929892,
                -19.86411741178444
              ],
              [
                -50.33599023285086,
                -19.867677359941883
              ],
              [
                -50.323680815143824,
                -19.870138299590533
              ],
              [
                -50.31137188145781,
                -19.87259875724234
              ],
              [
                -50.285148130116276,
                -19.86734922786657
              ],
              [
                -50.244674382069924,
                -19.876288104485003
              ],
              [
                -50.22040862685484,
                -19.867964079398316
              ],
              [
                -50.21020768972052,
                -19.867477045071954
              ],
              [
                -50.2000082525888,
                -19.86699004475509
              ],
              [
                -50.173101474694576,
                -19.87418792272039
              ],
              [
                -50.15921253915992,
                -19.877780881093276
              ],
              [
                -50.13841070576247,
                -19.87453885885279
              ],
              [
                -50.10232998311135,
                -19.875441246886698
              ],
              [
                -50.08992457888563,
                -19.878829706065122
              ],
              [
                -50.082617117576355,
                -19.883526684478163
              ],
              [
                -50.07633918925881,
                -19.88756163960816
              ],
              [
                -50.04341585247594,
                -19.91602544812409
              ],
              [
                -50.01170508353777,
                -19.926244316620988
              ],
              [
                -49.95963350673255,
                -19.92042571699217
              ],
              [
                -49.90451286525327,
                -19.938576017080024
              ],
              [
                -49.890803976756956,
                -19.94325045789497
              ],
              [
                -49.881072525628994,
                -19.944094901710383
              ],
              [
                -49.85509476622481,
                -19.946349827810614
              ],
              [
                -49.84495784431575,
                -19.93960985360529
              ],
              [
                -49.83478094979596,
                -19.93284235145148
              ],
              [
                -49.82964298832445,
                -19.93224884786114
              ],
              [
                -49.79845771445471,
                -19.928647279620808
              ],
              [
                -49.792643792472134,
                -19.927976279650284
              ],
              [
                -49.78731184658551,
                -19.925984305044565
              ],
              [
                -49.78197739572512,
                -19.923990771444032
              ],
              [
                -49.74908561336511,
                -19.92484821224868
              ],
              [
                -49.71172441814378,
                -19.930788589954414
              ],
              [
                -49.64073996686934,
                -19.931401911400343
              ],
              [
                -49.62618857794669,
                -19.9272733859539
              ],
              [
                -49.60547928253976,
                -19.91601391578864
              ],
              [
                -49.58167397734142,
                -19.909571358301502
              ],
              [
                -49.570091050788555,
                -19.910158829670877
              ],
              [
                -49.55872814357247,
                -19.910735814661056
              ],
              [
                -49.55777414990718,
                -19.910074301281007
              ],
              [
                -49.550991246269874,
                -19.90537334368515
              ],
              [
                -49.5235794153723,
                -19.91139873590175
              ],
              [
                -49.51329302390498,
                -19.915423682606626
              ],
              [
                -49.50300506146435,
                -19.919449145301716
              ],
              [
                -49.487009111252156,
                -19.954757932398156
              ],
              [
                -49.45770675482488,
                -19.97551077097003
              ],
              [
                -49.4488063393042,
                -19.977921229735234
              ],
              [
                -49.4399048948021,
                -19.98033221949433
              ],
              [
                -49.411333616040885,
                -19.98234311730783
              ],
              [
                -49.355993552148675,
                -19.98623897824217
              ],
              [
                -49.34498163510207,
                -19.983167468182057
              ],
              [
                -49.33396973006293,
                -19.98009744513008
              ],
              [
                -49.320258382586005,
                -19.970633952046764
              ],
              [
                -49.313729913636685,
                -19.966127968311326
              ],
              [
                -49.30539851053065,
                -19.96310046502188
              ],
              [
                -49.29706456745153,
                -19.96007246673887
              ],
              [
                -49.264696850457426,
                -19.961566895411792
              ],
              [
                -49.25797937968904,
                -19.964817371886078
              ],
              [
                -49.255898411081404,
                -19.96582482780731
              ],
              [
                -49.253649901554404,
                -19.966912814919656
              ],
              [
                -49.250249946338094,
                -19.970329799149717
              ],
              [
                -49.24654590453659,
                -19.982066221601283
              ],
              [
                -49.24786838287569,
                -19.999166664887735
              ],
              [
                -49.25496329479713,
                -20.00280968011686
              ],
              [
                -49.26205924371119,
                -20.006453180356946
              ],
              [
                -49.26876467007008,
                -20.005807172675674
              ],
              [
                -49.28354707345229,
                -20.00438320447177
              ],
              [
                -49.28981698505666,
                -20.010379703598243
              ],
              [
                -49.29608694765459,
                -20.016376713738673
              ],
              [
                -49.307236703608105,
                -20.07328697274824
              ],
              [
                -49.308058602251414,
                -20.101505818264375
              ],
              [
                -49.30532658301499,
                -20.116514248223048
              ],
              [
                -49.29877350250028,
                -20.166589983999494
              ],
              [
                -49.265649101661,
                -20.227338110190374
              ],
              [
                -49.26536963135295,
                -20.227850635079115
              ],
              [
                -49.26139910762722,
                -20.23942106694139
              ],
              [
                -49.25758762430252,
                -20.245984530495225
              ],
              [
                -49.259026599450856,
                -20.257782985427372
              ],
              [
                -49.25879158133167,
                -20.258111466287964
              ],
              [
                -49.238354193230165,
                -20.286567279747242
              ],
              [
                -49.21718680148711,
                -20.303227152062746
              ],
              [
                -49.21706831239693,
                -20.303234156720514
              ],
              [
                -49.21706028845841,
                -20.30323467369712
              ],
              [
                -49.190687006109606,
                -20.307826072444307
              ],
              [
                -49.17909107271511,
                -20.309699532438323
              ],
              [
                -49.13029556330664,
                -20.27966857883022
              ],
              [
                -49.12892507341299,
                -20.278189588147573
              ],
              [
                -49.12399412877574,
                -20.272863596112483
              ],
              [
                -49.120261175926586,
                -20.268832084175795
              ],
              [
                -49.08920806918368,
                -20.211279270018473
              ],
              [
                -49.06743938506586,
                -20.153973496692572
              ],
              [
                -49.067007395611604,
                -20.154266991199517
              ],
              [
                -49.06287791036628,
                -20.152450017022144
              ],
              [
                -49.05874897511783,
                -20.150633001849442
              ],
              [
                -49.02812318098456,
                -20.15067092743798
              ],
              [
                -49.023381743862934,
                -20.151857395610495
              ],
              [
                -49.018643782720225,
                -20.153042890796407
              ],
              [
                -48.99177794182177,
                -20.16491778160382
              ],
              [
                -48.9876194948389,
                -20.169308247247972
              ],
              [
                -48.98346099585586,
                -20.173699223888715
              ],
              [
                -48.97170602177187,
                -20.206993032310134
              ],
              [
                -48.96131744411173,
                -20.262817254833593
              ],
              [
                -48.96252241186596,
                -20.273012222244603
              ],
              [
                -48.9637273796083,
                -20.283204638685568
              ],
              [
                -48.96842503006169,
                -20.393266150935165
              ],
              [
                -48.96548753446629,
                -20.397951628414837
              ],
              [
                -48.96255104185891,
                -20.402638081892807
              ],
              [
                -48.89886144678271,
                -20.440722736759547
              ],
              [
                -48.89635649443049,
                -20.439754740720982
              ],
              [
                -48.886319580181876,
                -20.435875734564913
              ],
              [
                -48.88353758785778,
                -20.43360425488956
              ],
              [
                -48.87812967659928,
                -20.42918877935967
              ],
              [
                -48.86864977924874,
                -20.406494839576297
              ],
              [
                -48.86710031787118,
                -20.398194382319126
              ],
              [
                -48.86801933160089,
                -20.389677935523828
              ],
              [
                -48.869355363385445,
                -20.377286484944015
              ],
              [
                -48.87954784019402,
                -20.347810652382545
              ],
              [
                -48.87519346194645,
                -20.314520804914523
              ],
              [
                -48.8816339620152,
                -20.304610357084357
              ],
              [
                -48.888074439080555,
                -20.29469941725689
              ],
              [
                -48.88498550339509,
                -20.26866403928952
              ],
              [
                -48.8717427054019,
                -20.241376609010246
              ],
              [
                -48.857871339826985,
                -20.229378670447094
              ],
              [
                -48.853690408619144,
                -20.216717725136267
              ],
              [
                -48.85337741109345,
                -20.211893721007435
              ],
              [
                -48.85541440709327,
                -20.205315775206444
              ],
              [
                -48.857416425034614,
                -20.198851292784482
              ],
              [
                -48.85517498470758,
                -20.18161038910767
              ],
              [
                -48.8495860465394,
                -20.177225876313248
              ],
              [
                -48.843996603376546,
                -20.17284038453235
              ],
              [
                -48.82274281625553,
                -20.16143188344645
              ],
              [
                -48.780667647137626,
                -20.163374793824826
              ],
              [
                -48.753254871168096,
                -20.15289327869375
              ],
              [
                -48.732281046882896,
                -20.150187227495312
              ],
              [
                -48.729070082482906,
                -20.149772748192103
              ],
              [
                -48.71625769242964,
                -20.154083188852955
              ],
              [
                -48.69218582661014,
                -20.16218160563045
              ],
              [
                -48.685028382739105,
                -20.161677078603088
              ],
              [
                -48.67380298792135,
                -20.160885055476523
              ],
              [
                -48.667779027529576,
                -20.16369503968845
              ],
              [
                -48.66237306007836,
                -20.166216995851418
              ],
              [
                -48.6464031870364,
                -20.166068462486212
              ],
              [
                -48.643986216431166,
                -20.165290946952663
              ],
              [
                -48.62632436779154,
                -20.159607951469876
              ],
              [
                -48.59569017061783,
                -20.137392986130816
              ],
              [
                -48.588212725152445,
                -20.13452846354195
              ],
              [
                -48.58073579268409,
                -20.131664991958303
              ],
              [
                -48.58025730957554,
                -20.131631455890624
              ],
              [
                -48.55416102480514,
                -20.12982142959139
              ],
              [
                -48.536882145731205,
                -20.133025340092626
              ],
              [
                -48.51382131955336,
                -20.13729478771758
              ],
              [
                -48.50223494305941,
                -20.13747228119502
              ],
              [
                -48.4906480115902,
                -20.13764921567925
              ],
              [
                -48.45837279902139,
                -20.12755268912884
              ],
              [
                -48.45072735764806,
                -20.125161211964073
              ],
              [
                -48.438559974892804,
                -20.119746698652463
              ],
              [
                -48.40530875354183,
                -20.113035652693366
              ],
              [
                -48.37646047739792,
                -20.12200852920254
              ],
              [
                -48.36875402488766,
                -20.122305535106022
              ],
              [
                -48.35762260475397,
                -20.121169504110853
              ],
              [
                -48.35100067340902,
                -20.120494486431742
              ],
              [
                -48.34509174196715,
                -20.117170995227987
              ],
              [
                -48.339181776538695,
                -20.113845985036754
              ],
              [
                -48.31927542863236,
                -20.11295796950977
              ],
              [
                -48.30715254411022,
                -20.118244416317804
              ],
              [
                -48.295031125604076,
                -20.123528830134436
              ],
              [
                -48.289188637638404,
                -20.13071128188293
              ],
              [
                -48.28355718873246,
                -20.137634260409527
              ],
              [
                -48.27576970498669,
                -20.140024228111148
              ],
              [
                -48.267984289233596,
                -20.142414189817092
              ],
              [
                -48.24591044285241,
                -20.140473651799173
              ],
              [
                -48.22426466756794,
                -20.13312511480671
              ],
              [
                -48.21821473748685,
                -20.1258991710116
              ],
              [
                -48.218527749354664,
                -20.114909728474522
              ],
              [
                -48.24601207575517,
                -20.08872741191736
              ],
              [
                -48.24959705589929,
                -20.08531191040899
              ],
              [
                -48.25128856970987,
                -20.081346444720936
              ],
              [
                -48.25298007351887,
                -20.07738097403364
              ],
              [
                -48.24368723143862,
                -20.046870115789122
              ],
              [
                -48.247046728126364,
                -20.035443660168585
              ],
              [
                -48.24048429610952,
                -20.028872694074767
              ],
              [
                -48.22515789945269,
                -20.02882662742936
              ],
              [
                -48.213633986209885,
                -20.03487007116937
              ],
              [
                -48.21099800518174,
                -20.037355067543796
              ],
              [
                -48.20799450810224,
                -20.04018654232081
              ],
              [
                -48.20292156399785,
                -20.044967496480705
              ],
              [
                -48.18200167101055,
                -20.074150815184336
              ],
              [
                -48.178354127871174,
                -20.095324202240434
              ],
              [
                -48.16727417452297,
                -20.103339656596123
              ],
              [
                -48.1654666927412,
                -20.103416137634355
              ],
              [
                -48.157827760841364,
                -20.103737616674398
              ],
              [
                -48.147262303463336,
                -20.123665495130563
              ],
              [
                -48.12608294044054,
                -20.130781909092967
              ],
              [
                -48.12165396281402,
                -20.134650874248553
              ],
              [
                -48.11161704605684,
                -20.143418313613125
              ],
              [
                -48.094083175046336,
                -20.14595626281637
              ],
              [
                -48.069977347422224,
                -20.146125714694165
              ],
              [
                -48.04892453761031,
                -20.136758219228533
              ],
              [
                -48.04625059078236,
                -20.13192374669475
              ],
              [
                -48.037730722820505,
                -20.116519773206143
              ],
              [
                -48.025249796624514,
                -20.11867075417723
              ],
              [
                -48.019392855651816,
                -20.11968021445447
              ],
              [
                -48.002891020925055,
                -20.104157249406533
              ],
              [
                -47.990095186524144,
                -20.074514384988003
              ],
              [
                -47.99014475346528,
                -20.044382020615092
              ],
              [
                -47.98675280183394,
                -20.040067559890336
              ],
              [
                -47.98559379928743,
                -20.038592556090034
              ],
              [
                -47.980931362479986,
                -20.036169040026383
              ],
              [
                -47.97746239956848,
                -20.035353539207886
              ],
              [
                -47.97550838951782,
                -20.03489402349502
              ],
              [
                -47.94801306598755,
                -20.05533689122567
              ],
              [
                -47.94303354442072,
                -20.09152017279409
              ],
              [
                -47.9383355426107,
                -20.099445633628353
              ],
              [
                -47.933638058794,
                -20.107370593467465
              ],
              [
                -47.91136221189855,
                -20.118139496059143
              ],
              [
                -47.90132977062462,
                -20.12298944222933
              ],
              [
                -47.8939208540386,
                -20.123355399701047
              ],
              [
                -47.8781585144969,
                -20.102566488301076
              ],
              [
                -47.871441650628675,
                -20.07635210616076
              ],
              [
                -47.86950416631808,
                -20.063218147859928
              ],
              [
                -47.863073734299675,
                -20.056362176843084
              ],
              [
                -47.85984528693361,
                -20.052920199376306
              ],
              [
                -47.85964980059016,
                -20.04934819019203
              ],
              [
                -47.86058781650635,
                -20.04600322976711
              ],
              [
                -47.870721752891626,
                -20.037604783015063
              ],
              [
                -47.873175754940654,
                -20.035571315055282
              ],
              [
                -47.87535871643106,
                -20.02954485576443
              ],
              [
                -47.87493826382281,
                -20.01996488514509
              ],
              [
                -47.85932495639884,
                -19.992516481009833
              ],
              [
                -47.85127603376874,
                -19.990458964760574
              ],
              [
                -47.84530655562098,
                -19.99225393203733
              ],
              [
                -47.83422016221833,
                -19.995587916975722
              ],
              [
                -47.801101437568775,
                -19.984755898402646
              ],
              [
                -47.77346564838007,
                -19.984816796727085
              ],
              [
                -47.76452074730271,
                -19.98551081590084
              ],
              [
                -47.750648336715265,
                -19.989810758160036
              ],
              [
                -47.73322297841494,
                -19.987452231924816
              ],
              [
                -47.72272658453266,
                -19.982562219421016
              ],
              [
                -47.71222817167506,
                -19.977671215937697
              ],
              [
                -47.703778718711824,
                -19.97942620120773
              ],
              [
                -47.67569642992507,
                -20.00143453652577
              ],
              [
                -47.6737319112,
                -20.01088847151694
              ],
              [
                -47.67231339244486,
                -20.01771591268535
              ],
              [
                -47.66177095976831,
                -20.02858983424085
              ],
              [
                -47.6355156309374,
                -20.047902185217556
              ],
              [
                -47.62610570735682,
                -20.04270671891515
              ],
              [
                -47.62447672300887,
                -20.04180768520923
              ],
              [
                -47.622269256895265,
                -20.041772213841632
              ],
              [
                -47.61353231869744,
                -20.041630683605295
              ],
              [
                -47.59427602381178,
                -20.0252797290854
              ],
              [
                -47.59314203796279,
                -20.0156512702498
              ],
              [
                -47.592067091226994,
                -20.006520277148407
              ],
              [
                -47.58063721555671,
                -19.995383811945356
              ],
              [
                -47.55368591752127,
                -19.995504269930375
              ],
              [
                -47.54919743635337,
                -19.992776776044874
              ],
              [
                -47.53947855275,
                -19.98383630449057
              ],
              [
                -47.512368287795816,
                -19.98264574758554
              ],
              [
                -47.508071328998234,
                -19.982457212776616
              ],
              [
                -47.499636412495136,
                -19.973714247546834
              ],
              [
                -47.49946443538161,
                -19.97353573999222
              ],
              [
                -47.49714444182133,
                -19.971130737987203
              ],
              [
                -47.48949898485479,
                -19.969940257743243
              ],
              [
                -47.48078457998791,
                -19.96858371665185
              ],
              [
                -47.477549595535706,
                -19.965562223398216
              ],
              [
                -47.47325065444348,
                -19.961547247368546
              ],
              [
                -47.46958667196526,
                -19.962644751849307
              ],
              [
                -47.46592320848501,
                -19.9637422163314
              ],
              [
                -47.456959250277556,
                -19.976859646633795
              ],
              [
                -47.4497472870706,
                -19.98741305488252
              ],
              [
                -47.44355481142811,
                -19.991950012179185
              ],
              [
                -47.43736184979403,
                -19.996486996472456
              ],
              [
                -47.425214929638756,
                -20.012348404365447
              ],
              [
                -47.42506041327121,
                -20.017218861099764
              ],
              [
                -47.43808228221393,
                -20.025730843918268
              ],
              [
                -47.44344923902476,
                -20.02923835515057
              ],
              [
                -47.4386737017515,
                -20.049186762507293
              ],
              [
                -47.417777328710976,
                -20.064163605873592
              ],
              [
                -47.41416836080374,
                -20.068476081722487
              ],
              [
                -47.405498892211135,
                -20.080850495426468
              ],
              [
                -47.40265139313699,
                -20.082314516126953
              ],
              [
                -47.37411566158872,
                -20.08318443541162
              ],
              [
                -47.35822170523701,
                -20.10350180908046
              ],
              [
                -47.35134227857154,
                -20.104345273715587
              ],
              [
                -47.34708131861034,
                -20.10486728048516
              ],
              [
                -47.3309214335098,
                -20.113767180399538
              ],
              [
                -47.30921759057829,
                -20.124018085792425
              ],
              [
                -47.292554147749634,
                -20.148182409615934
              ],
              [
                -47.267364815743356,
                -20.15681783713993
              ],
              [
                -47.261976359935325,
                -20.161269784808567
              ],
              [
                -47.256588364126564,
                -20.165721751476745
              ],
              [
                -47.25441435702246,
                -20.191565105401754
              ],
              [
                -47.23065196264198,
                -20.2186229572048
              ],
              [
                -47.23211241904153,
                -20.22703889667148
              ],
              [
                -47.24350927776965,
                -20.255267274236694
              ],
              [
                -47.24580874999437,
                -20.260962266183043
              ],
              [
                -47.24911320305806,
                -20.262060266138874
              ],
              [
                -47.25241717612793,
                -20.263157758097545
              ],
              [
                -47.25444213544956,
                -20.266422765242428
              ],
              [
                -47.26416654411216,
                -20.28210071116668
              ],
              [
                -47.26947046435518,
                -20.28234768333821
              ],
              [
                -47.27627442014744,
                -20.291160674481503
              ],
              [
                -47.281368314386626,
                -20.312102609169845
              ],
              [
                -47.28417878815679,
                -20.314714591532926
              ],
              [
                -47.28536425040331,
                -20.31581709815242
              ],
              [
                -47.285297756149376,
                -20.31926556117024
              ],
              [
                -47.28505770979708,
                -20.331689495000283
              ],
              [
                -47.287568226087174,
                -20.3325090133554
              ],
              [
                -47.289832176605486,
                -20.333248995475355
              ],
              [
                -47.28898169171515,
                -20.337045478824468
              ],
              [
                -47.29775560142672,
                -20.347965950831078
              ],
              [
                -47.29289657160962,
                -20.363863845118377
              ],
              [
                -47.29497404682529,
                -20.368749336600175
              ],
              [
                -47.29208159620608,
                -20.36983835197075
              ],
              [
                -47.2939745570573,
                -20.380286283922537
              ],
              [
                -47.29205506311735,
                -20.38152278103987
              ],
              [
                -47.29017007096054,
                -20.382737250351685
              ],
              [
                -47.292072550232064,
                -20.384238263871843
              ],
              [
                -47.29330851141708,
                -20.385212749264614
              ],
              [
                -47.29208103118912,
                -20.385631764113576
              ],
              [
                -47.28678107606905,
                -20.38744025587587
              ],
              [
                -47.29009601810084,
                -20.39937370323392
              ],
              [
                -47.28537755886572,
                -20.40482312675911
              ],
              [
                -47.28912902354202,
                -20.409325122388694
              ],
              [
                -47.28778549416296,
                -20.415378592547604
              ],
              [
                -47.29173094946258,
                -20.417008099644782
              ],
              [
                -47.293025453423134,
                -20.417542121336403
              ],
              [
                -47.291481450661294,
                -20.418697585799332
              ],
              [
                -47.28830796665093,
                -20.421072062198817
              ],
              [
                -47.29083994046517,
                -20.423038554072193
              ],
              [
                -47.29490440242744,
                -20.42619604305364
              ],
              [
                -47.29245090298296,
                -20.42772505439077
              ],
              [
                -47.293833900322596,
                -20.431371542187733
              ],
              [
                -47.28929191110188,
                -20.433509034260783
              ],
              [
                -47.28683447175479,
                -20.434666024394527
              ],
              [
                -47.28602795841339,
                -20.44196944786316
              ],
              [
                -47.28685542762378,
                -20.443041447759416
              ],
              [
                -47.29132788372515,
                -20.44882944902034
              ],
              [
                -47.26944502427608,
                -20.466029326833297
              ],
              [
                -47.26247604428522,
                -20.467646804248393
              ],
              [
                -47.25902856801665,
                -20.46844679359262
              ],
              [
                -47.25751157667383,
                -20.470776764416645
              ],
              [
                -47.253149112942054,
                -20.477476722776956
              ],
              [
                -47.250138662892205,
                -20.47626420900544
              ],
              [
                -47.22914577050974,
                -20.486418604262614
              ],
              [
                -47.21103090274037,
                -20.501816015357104
              ],
              [
                -47.20160147045772,
                -20.502867994243587
              ],
              [
                -47.1991689674763,
                -20.503139485732966
              ],
              [
                -47.18433708629388,
                -20.513221891985012
              ],
              [
                -47.15391782873813,
                -20.5192448042798
              ],
              [
                -47.147965337501645,
                -20.529260236434876
              ],
              [
                -47.14553532556423,
                -20.533350196365394
              ],
              [
                -47.14299884838414,
                -20.541658630521248
              ],
              [
                -47.141957849080526,
                -20.545067113304192
              ],
              [
                -47.13720085313219,
                -20.56063302154731
              ],
              [
                -47.14203477083582,
                -20.565469048380685
              ],
              [
                -47.13195180767341,
                -20.59027088322829
              ],
              [
                -47.125549878694564,
                -20.58998337028486
              ],
              [
                -47.12232887767173,
                -20.594251354342482
              ],
              [
                -47.12496881700647,
                -20.603675817334544
              ],
              [
                -47.12410882725411,
                -20.608800799266653
              ],
              [
                -47.10844140679322,
                -20.62102867890453
              ],
              [
                -47.109899875460805,
                -20.627422666606567
              ],
              [
                -47.111480367603406,
                -20.634344139070247
              ],
              [
                -47.11220335256195,
                -20.637513107548735
              ],
              [
                -47.10843539378848,
                -20.63907660833081
              ],
              [
                -47.09692244993738,
                -20.643853559720903
              ],
              [
                -47.096056932466325,
                -20.65858548806055
              ],
              [
                -47.101587856471774,
                -20.668918918124064
              ],
              [
                -47.101986833062305,
                -20.669663937534864
              ],
              [
                -47.10165632454725,
                -20.673196422569426
              ],
              [
                -47.10077329842857,
                -20.682644858527006
              ],
              [
                -47.10182833225099,
                -20.683877858256626
              ],
              [
                -47.11233867091489,
                -20.696163830695575
              ],
              [
                -47.11308369373267,
                -20.699306832390416
              ],
              [
                -47.11450713670603,
                -20.70531676895145
              ],
              [
                -47.11829911557389,
                -20.7067617856862
              ],
              [
                -47.148657852872375,
                -20.707114837887286
              ],
              [
                -47.15068033439473,
                -20.706418846444436
              ],
              [
                -47.16535374036815,
                -20.70137192451325
              ],
              [
                -47.168938693002055,
                -20.705211897155575
              ],
              [
                -47.17266666856064,
                -20.711432406668113
              ],
              [
                -47.16905368224498,
                -20.717212859456694
              ],
              [
                -47.18580199466971,
                -20.730649340612924
              ],
              [
                -47.182538529320134,
                -20.733043293663304
              ],
              [
                -47.17472308138959,
                -20.738774739893344
              ],
              [
                -47.181852999154394,
                -20.740872248108428
              ],
              [
                -47.188361867914416,
                -20.770140149673608
              ],
              [
                -47.19483832046493,
                -20.774479159443928
              ],
              [
                -47.197997798259756,
                -20.774631637851794
              ],
              [
                -47.2066147037974,
                -20.77504716205993
              ],
              [
                -47.21230364942428,
                -20.78910711899842
              ],
              [
                -47.21339963458925,
                -20.791815117784942
              ],
              [
                -47.218848563749916,
                -20.7957286010028
              ],
              [
                -47.224298022903895,
                -20.799642584228252
              ],
              [
                -47.21456749923941,
                -20.825310922586045
              ],
              [
                -47.22653534234425,
                -20.8551463191007
              ],
              [
                -47.227262841572895,
                -20.856798305046688
              ],
              [
                -47.23958764397349,
                -20.884797710530588
              ],
              [
                -47.22411670081259,
                -20.91112756007973
              ],
              [
                -47.21706023696771,
                -20.917390999724297
              ],
              [
                -47.21000379612878,
                -20.923653940368943
              ],
              [
                -47.20647883182104,
                -20.924358423840676
              ],
              [
                -47.18958243179783,
                -20.927733367798353
              ],
              [
                -47.19084643413719,
                -20.934651349887105
              ],
              [
                -47.19211089146521,
                -20.941568842988556
              ],
              [
                -47.18152446777112,
                -20.953162727889456
              ],
              [
                -47.17484550705389,
                -20.954608216607678
              ],
              [
                -47.17057351021949,
                -20.955532713077037
              ],
              [
                -47.166441030196964,
                -20.96190916375767
              ],
              [
                -47.16407156478576,
                -20.965564628069465
              ],
              [
                -47.15919158288137,
                -20.966843645255718
              ],
              [
                -47.14912064738736,
                -20.96948208443424
              ],
              [
                -47.14619415401234,
                -20.975690558048825
              ],
              [
                -47.14326766763522,
                -20.98189851266762
              ],
              [
                -47.1488220917069,
                -20.99395348930755
              ],
              [
                -47.1501911007552,
                -20.996924962625176
              ],
              [
                -47.150517046105826,
                -21.005731922266857
              ],
              [
                -47.15066604598393,
                -21.00976489333094
              ],
              [
                -47.150668047780115,
                -21.00982838003195
              ],
              [
                -47.1509585251383,
                -21.01025788573028
              ],
              [
                -47.15651845843452,
                -21.01847536584335
              ],
              [
                -47.152723001967274,
                -21.025409340752145
              ],
              [
                -47.14786200732969,
                -21.035007782128904
              ],
              [
                -47.14564495176561,
                -21.058564664601434
              ],
              [
                -47.14296796006821,
                -21.062477622896935
              ],
              [
                -47.13864449570973,
                -21.068796066388874
              ],
              [
                -47.14009693723961,
                -21.077435065857312
              ],
              [
                -47.141311422901104,
                -21.084657507491134
              ],
              [
                -47.14182990351293,
                -21.087743006093202
              ],
              [
                -47.13658697603408,
                -21.08752601543878
              ],
              [
                -47.13531895768023,
                -21.089119489508438
              ],
              [
                -47.12766751376449,
                -21.098729915575586
              ],
              [
                -47.11269608533969,
                -21.1044318422043
              ],
              [
                -47.11233457925845,
                -21.10815281846793
              ],
              [
                -47.119974026814326,
                -21.111500318426735
              ],
              [
                -47.12310897273344,
                -21.118718817122577
              ],
              [
                -47.12577643566256,
                -21.12486177574732
              ],
              [
                -47.13304638407225,
                -21.132974769015053
              ],
              [
                -47.11685092442177,
                -21.15321113500377
              ],
              [
                -47.123195360221516,
                -21.167412577840697
              ],
              [
                -47.12121185382866,
                -21.170309532821367
              ],
              [
                -47.11810687473583,
                -21.17484453604806
              ],
              [
                -47.11833383875971,
                -21.18528696183183
              ],
              [
                -47.1031359351747,
                -21.19938035976239
              ],
              [
                -47.10199991203314,
                -21.202546862681928
              ],
              [
                -47.09824844755787,
                -21.213003284981156
              ],
              [
                -47.09519544396084,
                -21.209184301179306
              ],
              [
                -47.09298950677346,
                -21.20859982817903
              ],
              [
                -47.086119054479425,
                -21.20678078083204
              ],
              [
                -47.06511673387432,
                -21.207010251659465
              ],
              [
                -47.06230024554402,
                -21.209495744412834
              ],
              [
                -47.0675247006912,
                -21.213867205254548
              ],
              [
                -47.07035464516948,
                -21.22135117597716
              ],
              [
                -47.07186410188074,
                -21.225344192894404
              ],
              [
                -47.078431531885045,
                -21.230092654491663
              ],
              [
                -47.07690802587921,
                -21.239295104624375
              ],
              [
                -47.062646659156265,
                -21.238829103396117
              ],
              [
                -47.06019466132059,
                -21.24048109208129
              ],
              [
                -47.0518207051129,
                -21.246123543189007
              ],
              [
                -47.05086968015655,
                -21.256747995038975
              ],
              [
                -47.04440574413478,
                -21.26545190856767
              ],
              [
                -47.03908222794023,
                -21.280443846130463
              ],
              [
                -47.0403396906281,
                -21.28445179834184
              ],
              [
                -47.04191319570308,
                -21.289467308589078
              ],
              [
                -47.0364227127171,
                -21.29381578143348
              ],
              [
                -47.029464278295805,
                -21.29932670587745
              ],
              [
                -47.03192322140312,
                -21.304572224366048
              ],
              [
                -47.03472069556297,
                -21.310539689316126
              ],
              [
                -47.03008774245788,
                -21.31124617775587
              ],
              [
                -47.02839674806556,
                -21.31300313793892
              ],
              [
                -47.02053976206695,
                -21.32116511139248
              ],
              [
                -47.02133874844927,
                -21.323185604877487
              ],
              [
                -47.02325075626669,
                -21.32801557992235
              ],
              [
                -47.021207247412384,
                -21.332223021514114
              ],
              [
                -47.01745074403781,
                -21.33995449682291
              ],
              [
                -46.9975998818177,
                -21.350344887060924
              ],
              [
                -46.996435868620374,
                -21.356922373690953
              ],
              [
                -47.00908124368099,
                -21.37026283558307
              ],
              [
                -47.00681023050964,
                -21.376187777418778
              ],
              [
                -47.00414875647156,
                -21.38313276837691
              ],
              [
                -47.008270166772476,
                -21.392385219283298
              ],
              [
                -47.00947164405477,
                -21.392999736536346
              ],
              [
                -47.014962120516245,
                -21.39580522770955
              ],
              [
                -47.00983214079425,
                -21.405382151662725
              ],
              [
                -47.007260126449125,
                -21.410183619060206
              ],
              [
                -47.010062099017205,
                -21.41330761863054
              ],
              [
                -47.012379095199954,
                -21.41589010949352
              ],
              [
                -47.01077908550319,
                -21.4218050921804
              ],
              [
                -46.99924166409713,
                -21.420539579682032
              ],
              [
                -46.99219623744718,
                -21.424696542313328
              ],
              [
                -46.986417305623476,
                -21.421151051875935
              ],
              [
                -46.98350680801278,
                -21.426632514144735
              ],
              [
                -46.97956984637514,
                -21.424308512776747
              ],
              [
                -46.97647186933042,
                -21.422486005246967
              ],
              [
                -46.975489897401836,
                -21.420827017534364
              ],
              [
                -46.97447838986612,
                -21.41911801898216
              ],
              [
                -46.97040989300446,
                -21.421540470719627
              ],
              [
                -46.9699694168352,
                -21.421802479310575
              ],
              [
                -46.96957290852985,
                -21.421657981949465
              ],
              [
                -46.96248749154394,
                -21.419078989485868
              ],
              [
                -46.958988516619144,
                -21.421962454535585
              ],
              [
                -46.95620453759391,
                -21.424256432275925
              ],
              [
                -46.95306007290889,
                -21.42211196616257
              ],
              [
                -46.94864959523159,
                -21.419104934809436
              ],
              [
                -46.93975316231306,
                -21.42244889941103
              ],
              [
                -46.93368119437653,
                -21.424730405523984
              ],
              [
                -46.92708328855271,
                -21.42276886643002
              ],
              [
                -46.92499927556053,
                -21.422149365874326
              ],
              [
                -46.9242792904048,
                -21.42283938469063
              ],
              [
                -46.92031732954515,
                -21.426641359141854
              ],
              [
                -46.91706184791155,
                -21.421466375167892
              ],
              [
                -46.9164158493182,
                -21.420439375349527
              ],
              [
                -46.9146573976265,
                -21.420491891457797
              ],
              [
                -46.90796693406799,
                -21.420692338844223
              ],
              [
                -46.902930988407036,
                -21.415740854124596
              ],
              [
                -46.89983901899731,
                -21.412700387443522
              ],
              [
                -46.897462516070775,
                -21.41352536724592
              ],
              [
                -46.89284956020904,
                -21.415128342443623
              ],
              [
                -46.88574916906823,
                -21.408005373644304
              ],
              [
                -46.88422868117036,
                -21.406479385905342
              ],
              [
                -46.881999183833905,
                -21.4060423441097
              ],
              [
                -46.86766932022725,
                -21.403234833718056
              ],
              [
                -46.865740344237324,
                -21.397533368816113
              ],
              [
                -46.86490886846689,
                -21.395074878348005
              ],
              [
                -46.859427899097,
                -21.393906845480842
              ],
              [
                -46.85780091553241,
                -21.393560344848254
              ],
              [
                -46.8570814142395,
                -21.392300354962952
              ],
              [
                -46.85532995786579,
                -21.389233894977746
              ],
              [
                -46.8524149612533,
                -21.389104872917947
              ],
              [
                -46.84166558020272,
                -21.388626323901885
              ],
              [
                -46.83478262268291,
                -21.382027864280964
              ],
              [
                -46.8351176392283,
                -21.37725835893252
              ],
              [
                -46.835414646460855,
                -21.37302989490355
              ],
              [
                -46.832307204173276,
                -21.367317405012166
              ],
              [
                -46.818348832335694,
                -21.365914917015715
              ],
              [
                -46.80007095017223,
                -21.370835318460617
              ],
              [
                -46.7913080343139,
                -21.37319431015472
              ],
              [
                -46.78803054071341,
                -21.370393295932377
              ],
              [
                -46.785747077376,
                -21.368441822263197
              ],
              [
                -46.78117509896793,
                -21.370141292125197
              ],
              [
                -46.78083414239933,
                -21.370267779624612
              ],
              [
                -46.780745631554055,
                -21.370125785071025
              ],
              [
                -46.77594370045135,
                -21.362336311712774
              ],
              [
                -46.76883375908117,
                -21.36065730707602
              ],
              [
                -46.76425129109385,
                -21.359575291222935
              ],
              [
                -46.75817633319876,
                -21.363913253515822
              ],
              [
                -46.761573285383676,
                -21.374206232190375
              ],
              [
                -46.76167225867242,
                -21.374505237020056
              ],
              [
                -46.755085282227505,
                -21.379767667544133
              ],
              [
                -46.73651041576141,
                -21.392009081183613
              ],
              [
                -46.72682650056261,
                -21.391371539791216
              ],
              [
                -46.724189039843075,
                -21.39119807469276
              ],
              [
                -46.719993553592055,
                -21.389150558471492
              ],
              [
                -46.71728059774699,
                -21.39089754798454
              ],
              [
                -46.71425561986589,
                -21.392181001896418
              ],
              [
                -46.711390635576464,
                -21.396165514300222
              ],
              [
                -46.70550417363235,
                -21.404353431649238
              ],
              [
                -46.693438266999756,
                -21.39007146633241
              ],
              [
                -46.692270822835226,
                -21.38465851319371
              ],
              [
                -46.69175332428877,
                -21.382258038334484
              ],
              [
                -46.68295543431915,
                -21.37577054933621
              ],
              [
                -46.673470469906434,
                -21.374005023922116
              ],
              [
                -46.665247598179505,
                -21.361160067960682
              ],
              [
                -46.65917463703225,
                -21.36326802093523
              ],
              [
                -46.64672822634023,
                -21.363765510936876
              ],
              [
                -46.64609225240902,
                -21.36617148772608
              ],
              [
                -46.64452775767193,
                -21.372084475257125
              ],
              [
                -46.64557723050269,
                -21.375264460752863
              ],
              [
                -46.64696271851515,
                -21.37946190925189
              ],
              [
                -46.64710114048771,
                -21.39246786824975
              ],
              [
                -46.64062321703991,
                -21.399143317252683
              ],
              [
                -46.63833822487617,
                -21.401497801970223
              ],
              [
                -46.63804020998138,
                -21.403288295605133
              ],
              [
                -46.63689019680745,
                -21.41019723147117
              ],
              [
                -46.6327447391479,
                -21.410503727121462
              ],
              [
                -46.626120299431804,
                -21.410993216389244
              ],
              [
                -46.61797682871916,
                -21.42068964153359
              ],
              [
                -46.62009682355485,
                -21.425244137270028
              ],
              [
                -46.6156098074087,
                -21.428997119501776
              ],
              [
                -46.617330795668735,
                -21.431504591005048
              ],
              [
                -46.61940277350053,
                -21.434521082993527
              ],
              [
                -46.6148072881421,
                -21.44097853098336
              ],
              [
                -46.60226340986455,
                -21.443316006829328
              ],
              [
                -46.58504557484897,
                -21.433110026540366
              ],
              [
                -46.5813115993627,
                -21.430897029352945
              ],
              [
                -46.576101161732296,
                -21.426465050939594
              ],
              [
                -46.57131468687209,
                -21.433968482384653
              ],
              [
                -46.569799201299055,
                -21.436343945012712
              ],
              [
                -46.560982757360186,
                -21.44131791101358
              ],
              [
                -46.549638842507996,
                -21.440228414470386
              ],
              [
                -46.54836986537676,
                -21.44010639672616
              ],
              [
                -46.54702636987345,
                -21.44098288599497
              ],
              [
                -46.53530142604569,
                -21.448628824536076
              ],
              [
                -46.53450044903975,
                -21.448173823617697
              ],
              [
                -46.528043995470114,
                -21.44450881426829
              ],
              [
                -46.51978557337728,
                -21.448009781796245
              ],
              [
                -46.519108558462776,
                -21.461541711068246
              ],
              [
                -46.50880208826534,
                -21.469408171261513
              ],
              [
                -46.51177755279988,
                -21.47668713214849
              ],
              [
                -46.511336047755044,
                -21.478255614464214
              ],
              [
                -46.51094606782723,
                -21.479642621786073
              ],
              [
                -46.50841503982895,
                -21.48863456700528
              ],
              [
                -46.51348249861765,
                -21.49437603778985
              ],
              [
                -46.51879744650514,
                -21.500399031888694
              ],
              [
                -46.51598094734074,
                -21.508889993786447
              ],
              [
                -46.51538291887673,
                -21.510693986568246
              ],
              [
                -46.516552404644536,
                -21.512205987399298
              ],
              [
                -46.523697316671246,
                -21.521447950925666
              ],
              [
                -46.52009034377687,
                -21.53301339804348
              ],
              [
                -46.51452086839884,
                -21.534851863573994
              ],
              [
                -46.51013741837515,
                -21.536298325104582
              ],
              [
                -46.499705453281805,
                -21.554096747403793
              ],
              [
                -46.50293138037577,
                -21.56145618167673
              ],
              [
                -46.50922033552415,
                -21.56468169781945
              ],
              [
                -46.51186583250406,
                -21.566039223299313
              ],
              [
                -46.511688777131226,
                -21.571626696102093
              ],
              [
                -46.52143918648916,
                -21.584166663859957
              ],
              [
                -46.51799968475818,
                -21.592531097797114
              ],
              [
                -46.52400162796987,
                -21.600491074607366
              ],
              [
                -46.51819315875809,
                -21.603922541869366
              ],
              [
                -46.51552416812115,
                -21.605500034281842
              ],
              [
                -46.51710164122956,
                -21.61139602328064
              ],
              [
                -46.52539406839712,
                -21.61605548694058
              ],
              [
                -46.53383148611726,
                -21.620796489610278
              ],
              [
                -46.53441645389699,
                -21.628191948857413
              ],
              [
                -46.54691633698564,
                -21.629943473567987
              ],
              [
                -46.552525772382694,
                -21.637577474999052
              ],
              [
                -46.54787077264065,
                -21.649238907066398
              ],
              [
                -46.55580669350077,
                -21.652327895359313
              ],
              [
                -46.56547661643719,
                -21.65757939195316
              ],
              [
                -46.56542659374838,
                -21.673763803726434
              ],
              [
                -46.5654110634292,
                -21.678852291454263
              ],
              [
                -46.571236984649346,
                -21.681649285592915
              ],
              [
                -46.57706244387303,
                -21.684446789734487
              ],
              [
                -46.61852861022672,
                -21.67560395186751
              ],
              [
                -46.62113861830446,
                -21.675047428443282
              ],
              [
                -46.623413528984706,
                -21.69263735685927
              ],
              [
                -46.62849999845412,
                -21.696058348118402
              ],
              [
                -46.62929295934051,
                -21.7024808507118
              ],
              [
                -46.62733149130833,
                -21.705856304461914
              ],
              [
                -46.62199298653494,
                -21.715047246609007
              ],
              [
                -46.62801688175,
                -21.72912171580653
              ],
              [
                -46.625136415941014,
                -21.733604656860503
              ],
              [
                -46.63038883068014,
                -21.74040865555448
              ],
              [
                -46.625579314266545,
                -21.76571452370621
              ],
              [
                -46.62966626641558,
                -21.768329521202933
              ],
              [
                -46.63371025548288,
                -21.768470048318925
              ],
              [
                -46.636500240736815,
                -21.76769555642938
              ],
              [
                -46.63795723153564,
                -21.768393037008323
              ],
              [
                -46.64105269312283,
                -21.771262545663138
              ],
              [
                -46.645182643206006,
                -21.775091524535085
              ],
              [
                -46.65354156579676,
                -21.7787450275297
              ],
              [
                -46.651342039563716,
                -21.787063496262103
              ],
              [
                -46.65082255785462,
                -21.789027957572635
              ],
              [
                -46.66112492406537,
                -21.799954459358982
              ],
              [
                -46.667186349166585,
                -21.806382947136484
              ],
              [
                -46.668101346114234,
                -21.809147904501806
              ],
              [
                -46.670367292360346,
                -21.815993371181698
              ],
              [
                -46.68025268841887,
                -21.822863366136904
              ],
              [
                -46.690505588788895,
                -21.836455830344494
              ],
              [
                -46.683110637008866,
                -21.843624806681802
              ],
              [
                -46.676689140069065,
                -21.849850238080784
              ],
              [
                -46.67149719608239,
                -21.849197726260133
              ],
              [
                -46.66630474110304,
                -21.848545235440277
              ],
              [
                -46.65689229176921,
                -21.86882310544159
              ],
              [
                -46.656705236844324,
                -21.876780561362477
              ],
              [
                -46.64708682675726,
                -21.87996854753095
              ],
              [
                -46.65272074146867,
                -21.895429465645254
              ],
              [
                -46.66086718212611,
                -21.897643477049545
              ],
              [
                -46.664207121647344,
                -21.898551493726092
              ],
              [
                -46.67201655176241,
                -21.908663450991853
              ],
              [
                -46.66456306128669,
                -21.92009486808123
              ],
              [
                -46.66684800942294,
                -21.932060850818083
              ],
              [
                -46.656591567234486,
                -21.938933763868647
              ],
              [
                -46.65318607442375,
                -21.94121627496025
              ],
              [
                -46.65327108875825,
                -21.94703221778263
              ],
              [
                -46.65335604608703,
                -21.952848208609744
              ],
              [
                -46.64341458972133,
                -21.974462576067715
              ],
              [
                -46.637003124815884,
                -21.980311549273004
              ],
              [
                -46.63054666123089,
                -21.980684010144927
              ],
              [
                -46.623449194969865,
                -21.992045931539632
              ],
              [
                -46.62038122039983,
                -21.993348415148276
              ],
              [
                -46.61779075449632,
                -21.994447905998005
              ],
              [
                -46.61375277253224,
                -22.001386881454213
              ],
              [
                -46.613022737665595,
                -22.009113358113336
              ],
              [
                -46.61421871540187,
                -22.014709306995652
              ],
              [
                -46.616716175839855,
                -22.016590809869605
              ],
              [
                -46.63290158525079,
                -22.010863876425788
              ],
              [
                -46.639890506921375,
                -22.008390418893697
              ],
              [
                -46.647918433253466,
                -22.009377921882187
              ],
              [
                -46.65033242758193,
                -22.00990842589318
              ],
              [
                -46.6518504129564,
                -22.010241423418563
              ],
              [
                -46.6536483833126,
                -22.015314387411376
              ],
              [
                -46.6541883551085,
                -22.016837912704325
              ],
              [
                -46.66175027463019,
                -22.023203907173794
              ],
              [
                -46.66339327656262,
                -22.023022892456243
              ],
              [
                -46.674116683284346,
                -22.021841413935334
              ],
              [
                -46.676609189442416,
                -22.02350740983308
              ],
              [
                -46.681516103448885,
                -22.028113914436847
              ],
              [
                -46.684953552778964,
                -22.036860364645385
              ],
              [
                -46.68005907944102,
                -22.044884334759107
              ],
              [
                -46.67593859255593,
                -22.05487778572182
              ],
              [
                -46.66409769373602,
                -22.058111750552953
              ],
              [
                -46.65786471616332,
                -22.059813690725324
              ],
              [
                -46.661115173829735,
                -22.064110695336506
              ],
              [
                -46.66436515049669,
                -22.068407701952857
              ],
              [
                -46.668813636970626,
                -22.06956968252434
              ],
              [
                -46.68348998127496,
                -22.073405205212694
              ],
              [
                -46.69579638519213,
                -22.072443748003874
              ],
              [
                -46.7056492977071,
                -22.078471761404383
              ],
              [
                -46.71290073449345,
                -22.074325294457413
              ],
              [
                -46.72005717675751,
                -22.07445429621862
              ],
              [
                -46.722808126514416,
                -22.07652029298483
              ],
              [
                -46.71976867428332,
                -22.082113743555542
              ],
              [
                -46.71966165055819,
                -22.08231026634532
              ],
              [
                -46.71580016643966,
                -22.089411720628537
              ],
              [
                -46.700015277918595,
                -22.094681144206017
              ],
              [
                -46.69306836786858,
                -22.090405654431688
              ],
              [
                -46.68721737059936,
                -22.093873101345523
              ],
              [
                -46.68005545707734,
                -22.090645109091206
              ],
              [
                -46.67046903329692,
                -22.09684708707595
              ],
              [
                -46.659123109466606,
                -22.0933140649408
              ],
              [
                -46.6510997178466,
                -22.09081506095839
              ],
              [
                -46.6443822310035,
                -22.09582650828127
              ],
              [
                -46.64064575044264,
                -22.09986598522566
              ],
              [
                -46.633886275803974,
                -22.107172916664933
              ],
              [
                -46.63258781079824,
                -22.107425927964993
              ],
              [
                -46.62283537586555,
                -22.109327881657904
              ],
              [
                -46.61693690348451,
                -22.126451786376713
              ],
              [
                -46.61567137353977,
                -22.130125789722054
              ],
              [
                -46.613425913342525,
                -22.130719758857495
              ],
              [
                -46.600063016353644,
                -22.134254217105294
              ],
              [
                -46.60085599134631,
                -22.13855569907836
              ],
              [
                -46.612588335890294,
                -22.149757685340248
              ],
              [
                -46.6241067373885,
                -22.152895207853582
              ],
              [
                -46.639773123913905,
                -22.15716220034215
              ],
              [
                -46.64279156526194,
                -22.169182180260897
              ],
              [
                -46.6454855053189,
                -22.179910633370923
              ],
              [
                -46.65022144674955,
                -22.17710862433581
              ],
              [
                -46.66196886789276,
                -22.17975766984031
              ],
              [
                -46.66813329584507,
                -22.174601210692895
              ],
              [
                -46.668562299405046,
                -22.17424269753432
              ],
              [
                -46.67320076955459,
                -22.17562819665087
              ],
              [
                -46.67318175854808,
                -22.175680691353822
              ],
              [
                -46.670334272907155,
                -22.183755653867188
              ],
              [
                -46.6795290982381,
                -22.216392007389064
              ],
              [
                -46.67772508291725,
                -22.223497476308047
              ],
              [
                -46.68420100258652,
                -22.229404979362574
              ],
              [
                -46.68479153899108,
                -22.22994348145736
              ],
              [
                -46.692667923012586,
                -22.237127961387323
              ],
              [
                -46.690029454831716,
                -22.243519911387565
              ],
              [
                -46.68811694014053,
                -22.24815539955918
              ],
              [
                -46.69171690034395,
                -22.246851428448903
              ],
              [
                -46.69358491195852,
                -22.24617494469335
              ],
              [
                -46.71043619966638,
                -22.263453899102373
              ],
              [
                -46.7101676840793,
                -22.269429337536227
              ],
              [
                -46.7054487209976,
                -22.273958820564395
              ],
              [
                -46.70188123383602,
                -22.277383291880405
              ],
              [
                -46.70960668056913,
                -22.28408227077337
              ],
              [
                -46.71007417841033,
                -22.284487274164217
              ],
              [
                -46.70988665969552,
                -22.284890798773677
              ],
              [
                -46.70499964312429,
                -22.29540669847933
              ],
              [
                -46.71516004240671,
                -22.305971697078782
              ],
              [
                -46.71720001933588,
                -22.306012724463024
              ],
              [
                -46.722803993444565,
                -22.306125225256114
              ],
              [
                -46.722709982647594,
                -22.30630820414816
              ],
              [
                -46.71749751291292,
                -22.316449136741042
              ],
              [
                -46.713762030966976,
                -22.314223145879577
              ],
              [
                -46.71052754891901,
                -22.31229516500477
              ],
              [
                -46.71036407062172,
                -22.318836125137686
              ],
              [
                -46.700741628735805,
                -22.320995106789468
              ],
              [
                -46.698490621175296,
                -22.3318405521989
              ],
              [
                -46.69531114445582,
                -22.33441552053005
              ],
              [
                -46.69610660109312,
                -22.337184987829414
              ],
              [
                -46.6965446359995,
                -22.33859402047699
              ],
              [
                -46.69760259645661,
                -22.34199497056177
              ],
              [
                -46.69349112770766,
                -22.34291648202886
              ],
              [
                -46.6931846340381,
                -22.342984952871994
              ],
              [
                -46.69323263970008,
                -22.343281984622585
              ],
              [
                -46.69399612448835,
                -22.34798346785818
              ],
              [
                -46.69092012356698,
                -22.346555425078492
              ],
              [
                -46.69027662885863,
                -22.346256926705255
              ],
              [
                -46.687160161347435,
                -22.348916905812676
              ],
              [
                -46.68694565899797,
                -22.351804420803713
              ],
              [
                -46.68657413828329,
                -22.356799903569176
              ],
              [
                -46.68201117207677,
                -22.357474373944733
              ],
              [
                -46.67510520792007,
                -22.368713797805146
              ],
              [
                -46.66883826931855,
                -22.36735729895154
              ],
              [
                -46.66047433659676,
                -22.365546289475645
              ],
              [
                -46.66294627729587,
                -22.372417742300673
              ],
              [
                -46.665851260571294,
                -22.380493243728562
              ],
              [
                -46.662963747148545,
                -22.391048654781365
              ],
              [
                -46.6677591697274,
                -22.407305616435767
              ],
              [
                -46.66610217260084,
                -22.413896591298197
              ],
              [
                -46.650255249439056,
                -22.42303396741922
              ],
              [
                -46.64662528521866,
                -22.42820893944101
              ],
              [
                -46.64174580045871,
                -22.426118455759163
              ],
              [
                -46.639594332289136,
                -22.4251964411384
              ],
              [
                -46.63627537128966,
                -22.429173408571334
              ],
              [
                -46.63113987850127,
                -22.43532687291674
              ],
              [
                -46.62222644633282,
                -22.437017845613337
              ],
              [
                -46.61616399915564,
                -22.438167827643465
              ],
              [
                -46.611466041490026,
                -22.4349483287094
              ],
              [
                -46.60566956017171,
                -22.440174298540185
              ],
              [
                -46.602994106796366,
                -22.442586284999685
              ],
              [
                -46.59936961985883,
                -22.44104979719519
              ],
              [
                -46.59127769014293,
                -22.43761879594553
              ],
              [
                -46.581455768280726,
                -22.440669750840197
              ],
              [
                -46.57559883317102,
                -22.444422228344198
              ],
              [
                -46.573898832902906,
                -22.444873688585204
              ],
              [
                -46.57232784624042,
                -22.45204315696151
              ],
              [
                -46.572196325430305,
                -22.452643644810685
              ],
              [
                -46.57184130821857,
                -22.452382168052015
              ],
              [
                -46.56437442387002,
                -22.446890166101564
              ],
              [
                -46.554357481679844,
                -22.446156660053997
              ],
              [
                -46.54811354233578,
                -22.454790083800333
              ],
              [
                -46.54901897811861,
                -22.45815205665439
              ],
              [
                -46.55110098252854,
                -22.459486054162856
              ],
              [
                -46.549183978002,
                -22.468150028641077
              ],
              [
                -46.54910347852038,
                -22.46851302373354
              ],
              [
                -46.54875645761985,
                -22.468448024083642
              ],
              [
                -46.54249852055061,
                -22.46726501341674
              ],
              [
                -46.53021213719438,
                -22.470353969363522
              ],
              [
                -46.53357104974604,
                -22.480881914646865
              ],
              [
                -46.535607036492955,
                -22.487261920591767
              ],
              [
                -46.54169148378219,
                -22.49385788665828
              ],
              [
                -46.50653269501318,
                -22.50645825060312
              ],
              [
                -46.504828183130456,
                -22.51655219603283
              ],
              [
                -46.496751794673045,
                -22.512737203577935
              ],
              [
                -46.490723850041526,
                -22.509890169259606
              ],
              [
                -46.48410937908314,
                -22.518749632931197
              ],
              [
                -46.466403513197676,
                -22.518034585651247
              ],
              [
                -46.464486519747645,
                -22.52226707571399
              ],
              [
                -46.4623970454319,
                -22.522193564320347
              ],
              [
                -46.454056127034406,
                -22.52189905579731
              ],
              [
                -46.448741629594416,
                -22.5303369849789
              ],
              [
                -46.43906969797769,
                -22.534242938268957
              ],
              [
                -46.43898771484544,
                -22.537328943695726
              ],
              [
                -46.438914696643884,
                -22.540098398620042
              ],
              [
                -46.42585229060688,
                -22.547074861320485
              ],
              [
                -46.41749036386811,
                -22.54695981489724
              ],
              [
                -46.415976360813794,
                -22.544354358857845
              ],
              [
                -46.41123991032885,
                -22.5362043567706
              ],
              [
                -46.40668845515694,
                -22.540047843399986
              ],
              [
                -46.410961857624805,
                -22.553816765087216
              ],
              [
                -46.41620882013065,
                -22.55841179713244
              ],
              [
                -46.433299109591516,
                -22.573376268560715
              ],
              [
                -46.428243145802035,
                -22.58342968496997
              ],
              [
                -46.41957671320254,
                -22.584388171686722
              ],
              [
                -46.41219078935865,
                -22.585204646585968
              ],
              [
                -46.414935746513,
                -22.588620144269658
              ],
              [
                -46.419052687743886,
                -22.593743599800895
              ],
              [
                -46.422573147330155,
                -22.59812358615166
              ],
              [
                -46.41708220088206,
                -22.60481704497179
              ],
              [
                -46.404303736024,
                -22.620392932506533
              ],
              [
                -46.41393816175869,
                -22.622481968341127
              ],
              [
                -46.42255057575963,
                -22.6243489684005
              ],
              [
                -46.424165055284625,
                -22.63309845927331
              ],
              [
                -46.420212079134956,
                -22.63754240077518
              ],
              [
                -46.40800565546334,
                -22.63966287230527
              ],
              [
                -46.40559517129183,
                -22.640081389722347
              ],
              [
                -46.395863736443175,
                -22.64548282785597
              ],
              [
                -46.39191026560502,
                -22.65264879572647
              ],
              [
                -46.392503713710276,
                -22.661063217346193
              ],
              [
                -46.392603218354154,
                -22.662476247069637
              ],
              [
                -46.40541208546171,
                -22.672060702979945
              ],
              [
                -46.40792006490032,
                -22.668806221977892
              ],
              [
                -46.40538461825188,
                -22.66251926711695
              ],
              [
                -46.407447610749415,
                -22.661657269801566
              ],
              [
                -46.41296353198064,
                -22.659351297700887
              ],
              [
                -46.41715252946161,
                -22.662621312103603
              ],
              [
                -46.418512500682304,
                -22.66368328793531
              ],
              [
                -46.420678989834684,
                -22.662972301206064
              ],
              [
                -46.42718992421629,
                -22.66083434307301
              ],
              [
                -46.43345237691759,
                -22.664241835570785
              ],
              [
                -46.43971430462379,
                -22.66764934207581
              ],
              [
                -46.44793174670136,
                -22.669271340253058
              ],
              [
                -46.45470716675914,
                -22.67060783677621
              ],
              [
                -46.45695816217134,
                -22.67240583567275
              ],
              [
                -46.460852116640744,
                -22.675514866043923
              ],
              [
                -46.46355008371175,
                -22.674695354287966
              ],
              [
                -46.469271042457486,
                -22.672957368131545
              ],
              [
                -46.48018195264928,
                -22.677819381794603
              ],
              [
                -46.47811392763681,
                -22.6853893620813
              ],
              [
                -46.47254698393564,
                -22.691070280430242
              ],
              [
                -46.469919471293984,
                -22.693751287772653
              ],
              [
                -46.47401494909707,
                -22.696539275211595
              ],
              [
                -46.47747891455931,
                -22.698897756893
              ],
              [
                -46.47530391488748,
                -22.701342772579167
              ],
              [
                -46.472311432102735,
                -22.704705236764728
              ],
              [
                -46.468185479111455,
                -22.700812257341912
              ],
              [
                -46.45772855650954,
                -22.706741179018874
              ],
              [
                -46.45344501303469,
                -22.722701113418022
              ],
              [
                -46.45227751580435,
                -22.72705308208177
              ],
              [
                -46.44602507736285,
                -22.726664083574168
              ],
              [
                -46.4389971215697,
                -22.72622705614521
              ],
              [
                -46.43678766735277,
                -22.73048854333897
              ],
              [
                -46.436297153411466,
                -22.73143553560566
              ],
              [
                -46.42636270499967,
                -22.735337489081406
              ],
              [
                -46.42411523956056,
                -22.73386049868782
              ],
              [
                -46.42363073963847,
                -22.73354246281702
              ],
              [
                -46.422609256953855,
                -22.732060475840317
              ],
              [
                -46.41310131832876,
                -22.736790945666797
              ],
              [
                -46.406150363400805,
                -22.74024890944881
              ],
              [
                -46.392288502634855,
                -22.742997349389142
              ],
              [
                -46.392228994219934,
                -22.742970367349265
              ],
              [
                -46.38534807001083,
                -22.739809359933894
              ],
              [
                -46.38106959790083,
                -22.743524817915056
              ],
              [
                -46.37588963494569,
                -22.74549828845918
              ],
              [
                -46.372681660625,
                -22.746180796429083
              ],
              [
                -46.36000323076354,
                -22.760822705630403
              ],
              [
                -46.3544332670486,
                -22.756592204790007
              ],
              [
                -46.337779396177424,
                -22.759222664556603
              ],
              [
                -46.33476343192852,
                -22.75969913501085
              ],
              [
                -46.332124909090645,
                -22.765594585430215
              ],
              [
                -46.34119036664515,
                -22.76884160448577
              ],
              [
                -46.342986308167966,
                -22.776741071918043
              ],
              [
                -46.34759925714737,
                -22.777160580746656
              ],
              [
                -46.35188021523362,
                -22.78462257810049
              ],
              [
                -46.36017211082732,
                -22.788007067423738
              ],
              [
                -46.3612416244567,
                -22.78844356536938
              ],
              [
                -46.37362041888278,
                -22.8178434609191
              ],
              [
                -46.376357883294915,
                -22.82733292472917
              ],
              [
                -46.375245390367326,
                -22.828649397568274
              ],
              [
                -46.370243420164854,
                -22.834571385365763
              ],
              [
                -46.373885837086874,
                -22.843402842747878
              ],
              [
                -46.37215233406442,
                -22.859343254445477
              ],
              [
                -46.37639425449179,
                -22.86150878825405
              ],
              [
                -46.38038472944372,
                -22.86354576995973
              ],
              [
                -46.38373467818333,
                -22.869468740987283
              ],
              [
                -46.37663972890982,
                -22.87032024133744
              ],
              [
                -46.37504825263524,
                -22.870511231032786
              ],
              [
                -46.37560873028769,
                -22.871946716978016
              ],
              [
                -46.377409222251195,
                -22.876559706736064
              ],
              [
                -46.375331247524024,
                -22.877223695898767
              ],
              [
                -46.372508746595926,
                -22.8789511650924
              ],
              [
                -46.3591783093357,
                -22.895635068172016
              ],
              [
                -46.34798240273033,
                -22.902277014432713
              ],
              [
                -46.346993879121335,
                -22.902864008978803
              ],
              [
                -46.34477142349609,
                -22.904182005724795
              ],
              [
                -46.34200843316081,
                -22.901565990086144
              ],
              [
                -46.3355805298207,
                -22.895480510242184
              ],
              [
                -46.31516373228086,
                -22.88680099549228
              ],
              [
                -46.30508177424106,
                -22.89195142872237
              ],
              [
                -46.29499985421444,
                -22.897102422947388
              ],
              [
                -46.28852194418922,
                -22.889509412976274
              ],
              [
                -46.278573556454674,
                -22.877847937173826
              ],
              [
                -46.26641315718709,
                -22.875187915705514
              ],
              [
                -46.26218269973742,
                -22.877967894125277
              ],
              [
                -46.25970966994016,
                -22.885259360586193
              ],
              [
                -46.25807567509532,
                -22.890074846813317
              ],
              [
                -46.24631529835644,
                -22.889627800230873
              ],
              [
                -46.23771682859397,
                -22.89365528776088
              ],
              [
                -46.23368942830401,
                -22.88132332056445
              ],
              [
                -46.19937721786401,
                -22.870447288727455
              ],
              [
                -46.19174432350079,
                -22.86472730505277
              ],
              [
                -46.18504786102677,
                -22.868691770152264
              ],
              [
                -46.16354156646167,
                -22.86511472015909
              ],
              [
                -46.15488361594564,
                -22.863674722863227
              ],
              [
                -46.14418973685099,
                -22.857802180485425
              ],
              [
                -46.141657702141664,
                -22.88045509150853
              ],
              [
                -46.13643518717987,
                -22.89004002964907
              ],
              [
                -46.14602457794409,
                -22.905376972216104
              ],
              [
                -46.14725807719078,
                -22.907348977509674
              ],
              [
                -46.15354502445686,
                -22.911933960728557
              ],
              [
                -46.13863260262678,
                -22.922257408609774
              ],
              [
                -46.123158267607124,
                -22.909047420845134
              ],
              [
                -46.11568834710917,
                -22.907004901603713
              ],
              [
                -46.108218382617984,
                -22.904961905371444
              ],
              [
                -46.103675445905594,
                -22.901236386048545
              ],
              [
                -46.09476704951411,
                -22.89392989624507
              ],
              [
                -46.08485313562081,
                -22.892196891840133
              ],
              [
                -46.074939734732176,
                -22.890464374443916
              ],
              [
                -46.05082289771379,
                -22.895824802859046
              ],
              [
                -46.02688066010751,
                -22.8841703037715
              ],
              [
                -46.0079912987581,
                -22.88872373842343
              ],
              [
                -46.001204385377505,
                -22.879284760542422
              ],
              [
                -45.99170497583553,
                -22.877008226897168
              ],
              [
                -45.99180096133476,
                -22.87317974996504
              ],
              [
                -45.991903980601194,
                -22.86909328425432
              ],
              [
                -45.97671014759669,
                -22.86510776187223
              ],
              [
                -45.964387241807955,
                -22.85507177660456
              ],
              [
                -45.96187028245699,
                -22.856190752455475
              ],
              [
                -45.9597447973615,
                -22.85713574519569
              ],
              [
                -45.9559428270998,
                -22.852570740975025
              ],
              [
                -45.95468585655191,
                -22.85106124854172
              ],
              [
                -45.949966898554,
                -22.845395773932463
              ],
              [
                -45.94828894597612,
                -22.84531478268725
              ],
              [
                -45.943733991123885,
                -22.845096259157934
              ],
              [
                -45.93701800985044,
                -22.844773745162584
              ],
              [
                -45.92831364571421,
                -22.83560728693024
              ],
              [
                -45.924541159291785,
                -22.831634264076214
              ],
              [
                -45.922134705486506,
                -22.823553293152532
              ],
              [
                -45.909432833393424,
                -22.816784806807366
              ],
              [
                -45.9038823748928,
                -22.81877576325182
              ],
              [
                -45.90759932537321,
                -22.83006423878555
              ],
              [
                -45.90549232592718,
                -22.833262201066315
              ],
              [
                -45.89355592433952,
                -22.83869768086247
              ],
              [
                -45.890568934044964,
                -22.844170136106822
              ],
              [
                -45.89185539751863,
                -22.84936963038741
              ],
              [
                -45.89364737116414,
                -22.856613101531746
              ],
              [
                -45.889510884157815,
                -22.858158554934914
              ],
              [
                -45.888464841966695,
                -22.875316487045477
              ],
              [
                -45.87840143435424,
                -22.87489797527416
              ],
              [
                -45.875442953586514,
                -22.87357993627187
              ],
              [
                -45.87014353395029,
                -22.8712189596894
              ],
              [
                -45.86860805212405,
                -22.87053446065431
              ],
              [
                -45.863411084479175,
                -22.868218434150847
              ],
              [
                -45.85713368122412,
                -22.86020599226511
              ],
              [
                -45.85696166991689,
                -22.854824981910735
              ],
              [
                -45.85682817632373,
                -22.850679035900075
              ],
              [
                -45.85163371896585,
                -22.847639500807333
              ],
              [
                -45.84997927405226,
                -22.846671005780976
              ],
              [
                -45.849647254172446,
                -22.845323522161593
              ],
              [
                -45.84767732240738,
                -22.837329556089344
              ],
              [
                -45.838528380235466,
                -22.832488559569367
              ],
              [
                -45.829056965246295,
                -22.834386526675708
              ],
              [
                -45.82293551500517,
                -22.829383046254478
              ],
              [
                -45.81728009286575,
                -22.832500493144426
              ],
              [
                -45.815314072027654,
                -22.833584489675737
              ],
              [
                -45.81465960321227,
                -22.83306849829127
              ],
              [
                -45.8093531324651,
                -22.828884499288964
              ],
              [
                -45.80239068007879,
                -22.83335246827194
              ],
              [
                -45.800194681755436,
                -22.84677140448032
              ],
              [
                -45.79551018201548,
                -22.852203373210564
              ],
              [
                -45.79082471828289,
                -22.85763582993577
              ],
              [
                -45.77522489869276,
                -22.849384799673622
              ],
              [
                -45.77044491205594,
                -22.845660320976034
              ],
              [
                -45.76814846721264,
                -22.843870833046353
              ],
              [
                -45.76566649439949,
                -22.841937338283124
              ],
              [
                -45.76715649876238,
                -22.835997857175858
              ],
              [
                -45.77049455208951,
                -22.805424525523183
              ],
              [
                -45.75857017660287,
                -22.80052949478854
              ],
              [
                -45.754468224175405,
                -22.792414520600584
              ],
              [
                -45.74620330079853,
                -22.79408700212408
              ],
              [
                -45.742457826089094,
                -22.794844480315227
              ],
              [
                -45.741787321492346,
                -22.801835466691603
              ],
              [
                -45.741251802231396,
                -22.807418932043387
              ],
              [
                -45.72833489935371,
                -22.809690396008065
              ],
              [
                -45.72608691693977,
                -22.812341359417985
              ],
              [
                -45.72232741669962,
                -22.815816335822834
              ],
              [
                -45.71313500343411,
                -22.814284819826327
              ],
              [
                -45.7138170175768,
                -22.808661341075094
              ],
              [
                -45.71983896540463,
                -22.80777389972863
              ],
              [
                -45.721423471559504,
                -22.804141378108557
              ],
              [
                -45.72953190616719,
                -22.798407441209836
              ],
              [
                -45.72949542487735,
                -22.79419947685241
              ],
              [
                -45.7290174606127,
                -22.7892719736659
              ],
              [
                -45.72877895945455,
                -22.78681450554608
              ],
              [
                -45.722047040447194,
                -22.778958507005218
              ],
              [
                -45.721397542756854,
                -22.779018003949275
              ],
              [
                -45.71635356199084,
                -22.779481021979013
              ],
              [
                -45.712070634970786,
                -22.76856354054467
              ],
              [
                -45.71221114584417,
                -22.766656040890098
              ],
              [
                -45.71287667888525,
                -22.757631599112194
              ],
              [
                -45.712956161627346,
                -22.7565565833813
              ],
              [
                -45.71349669078803,
                -22.756324597948396
              ],
              [
                -45.71730512373509,
                -22.754691125030487
              ],
              [
                -45.71802018877429,
                -22.746790145117274
              ],
              [
                -45.718132151747696,
                -22.745555164228048
              ],
              [
                -45.72065065910743,
                -22.741246683359467
              ],
              [
                -45.7280651286199,
                -22.728558788255622
              ],
              [
                -45.72794762216047,
                -22.725864789602543
              ],
              [
                -45.72783013470114,
                -22.723170277953063
              ],
              [
                -45.75665789721447,
                -22.72083637262319
              ],
              [
                -45.796378517923664,
                -22.738283413226718
              ],
              [
                -45.79869002386213,
                -22.73781788873253
              ],
              [
                -45.805383465563864,
                -22.736470449360343
              ],
              [
                -45.81347038659581,
                -22.728169495424776
              ],
              [
                -45.819423360704874,
                -22.72205901837449
              ],
              [
                -45.81531196724721,
                -22.710156056005918
              ],
              [
                -45.81120053776066,
                -22.698253118670312
              ],
              [
                -45.79137669455085,
                -22.693755592681043
              ],
              [
                -45.787408213947934,
                -22.692855087083032
              ],
              [
                -45.7829217773771,
                -22.68796208984416
              ],
              [
                -45.77843431681371,
                -22.68306811161677
              ],
              [
                -45.76678795937272,
                -22.678930105323786
              ],
              [
                -45.76062999036302,
                -22.676742096832502
              ],
              [
                -45.758313030044405,
                -22.674185611549202
              ],
              [
                -45.755044585090424,
                -22.670579615616305
              ],
              [
                -45.750787612006384,
                -22.669972595875425
              ],
              [
                -45.74878260586391,
                -22.66968710475711
              ],
              [
                -45.74834613897417,
                -22.66860609465804
              ],
              [
                -45.747426663949604,
                -22.666329601872956
              ],
              [
                -45.74235970102574,
                -22.65866665116593
              ],
              [
                -45.73178381382978,
                -22.656336610352913
              ],
              [
                -45.72678032606979,
                -22.657944116158692
              ],
              [
                -45.71627740125011,
                -22.661319572663768
              ],
              [
                -45.693546145516265,
                -22.65120856750073
              ],
              [
                -45.69752409799885,
                -22.649262066500388
              ],
              [
                -45.69921564083297,
                -22.641927611701487
              ],
              [
                -45.70229910892473,
                -22.64091964283988
              ],
              [
                -45.708269559571455,
                -22.638968157278196
              ],
              [
                -45.71713001388321,
                -22.62049676251664
              ],
              [
                -45.72868044438472,
                -22.61790279714252
              ],
              [
                -45.729353970981286,
                -22.612734814359072
              ],
              [
                -45.73698841414254,
                -22.60806836312131
              ],
              [
                -45.73659290736767,
                -22.60651937135664
              ],
              [
                -45.734152476145944,
                -22.596949906897127
              ],
              [
                -45.72714550722056,
                -22.593825892623812
              ],
              [
                -45.726596544304975,
                -22.588602445802007
              ],
              [
                -45.71674762659812,
                -22.58160295214525
              ],
              [
                -45.71646713798617,
                -22.578100445935377
              ],
              [
                -45.70103574926845,
                -22.58420740022028
              ],
              [
                -45.69679430486198,
                -22.585885845783555
              ],
              [
                -45.68753939470372,
                -22.5790288951079
              ],
              [
                -45.67929697361936,
                -22.572921904653683
              ],
              [
                -45.6604501106084,
                -22.578483332274622
              ],
              [
                -45.6626270919407,
                -22.586198800669415
              ],
              [
                -45.658419094673924,
                -22.59761073228339
              ],
              [
                -45.66346802013149,
                -22.599458239237407
              ],
              [
                -45.66388153815688,
                -22.59960922864482
              ],
              [
                -45.6676869919385,
                -22.609147200875906
              ],
              [
                -45.66565898949872,
                -22.612198170952823
              ],
              [
                -45.67509238112907,
                -22.62570214575467
              ],
              [
                -45.67397388642726,
                -22.630198118482443
              ],
              [
                -45.680630777315685,
                -22.63583557894525
              ],
              [
                -45.66932686132153,
                -22.648098023339234
              ],
              [
                -45.66455938818514,
                -22.65015748266054
              ],
              [
                -45.647246053429704,
                -22.64773245506992
              ],
              [
                -45.617681812350106,
                -22.63741543955397
              ],
              [
                -45.61115692814559,
                -22.628675454183703
              ],
              [
                -45.60463147893408,
                -22.619934488843157
              ],
              [
                -45.5895466220823,
                -22.618557430471835
              ],
              [
                -45.584331187439496,
                -22.615007951144584
              ],
              [
                -45.58750866359438,
                -22.6098914909817
              ],
              [
                -45.576270764083645,
                -22.60185301062327
              ],
              [
                -45.57227478308882,
                -22.608812961828033
              ],
              [
                -45.568046333893996,
                -22.610415936782697
              ],
              [
                -45.563212341112674,
                -22.61224889800764
              ],
              [
                -45.55491039847097,
                -22.62594034665851
              ],
              [
                -45.57238570781311,
                -22.640721321039102
              ],
              [
                -45.57629866354195,
                -22.64011732550867
              ],
              [
                -45.57688214388047,
                -22.640026837519233
              ],
              [
                -45.58154811428496,
                -22.64416982357099
              ],
              [
                -45.58392508003166,
                -22.6511887868166
              ],
              [
                -45.58140507434035,
                -22.651840781894688
              ],
              [
                -45.579995115143525,
                -22.65220575834245
              ],
              [
                -45.5737771577936,
                -22.656036255351054
              ],
              [
                -45.5730726333999,
                -22.656470229386464
              ],
              [
                -45.55113684248037,
                -22.652639216007667
              ],
              [
                -45.54965584873341,
                -22.652101713535643
              ],
              [
                -45.53946145827511,
                -22.652079179999745
              ],
              [
                -45.533056528715626,
                -22.646642680381877
              ],
              [
                -45.532600037807306,
                -22.64625517198002
              ],
              [
                -45.53230004433969,
                -22.64625917114801
              ],
              [
                -45.52448557627585,
                -22.646374139425884
              ],
              [
                -45.51253572150732,
                -22.63624818903841
              ],
              [
                -45.49996837560433,
                -22.625600199506138
              ],
              [
                -45.49416291166068,
                -22.626942171430336
              ],
              [
                -45.4883569397249,
                -22.62828464035114
              ],
              [
                -45.4745471582984,
                -22.598524746284337
              ],
              [
                -45.47686666065271,
                -22.59563927825737
              ],
              [
                -45.48084109440758,
                -22.590696338474853
              ],
              [
                -45.473862184190494,
                -22.590201296976534
              ],
              [
                -45.471343719126075,
                -22.590022792023053
              ],
              [
                -45.45136980936842,
                -22.60576965724919
              ],
              [
                -45.44864084330092,
                -22.604570163577403
              ],
              [
                -45.44760537051978,
                -22.60411516694279
              ],
              [
                -45.43859891795752,
                -22.61019562271903
              ],
              [
                -45.43704841684766,
                -22.611242117557396
              ],
              [
                -45.435107449685965,
                -22.61783008100749
              ],
              [
                -45.42044204938304,
                -22.62034854541841
              ],
              [
                -45.41248662470555,
                -22.62171298444322
              ],
              [
                -45.41265509558958,
                -22.627992484050957
              ],
              [
                -45.40835212549367,
                -22.63298842868089
              ],
              [
                -45.4100475650258,
                -22.65117386686604
              ],
              [
                -45.401848124157816,
                -22.652310308295398
              ],
              [
                -45.39954561472902,
                -22.65262983255163
              ],
              [
                -45.39489269807647,
                -22.651422311708085
              ],
              [
                -45.370154929239064,
                -22.63681434030626
              ],
              [
                -45.365334469967905,
                -22.637537821847495
              ],
              [
                -45.36051452469421,
                -22.638261799388275
              ],
              [
                -45.35213162478922,
                -22.629710336447264
              ],
              [
                -45.348506652202936,
                -22.626012834264227
              ],
              [
                -45.34346369661515,
                -22.624571323520733
              ],
              [
                -45.33535075989305,
                -22.622252800676442
              ],
              [
                -45.33466677557559,
                -22.621018304710844
              ],
              [
                -45.332517291998506,
                -22.61713983338726
              ],
              [
                -45.293365666601986,
                -22.61317323577332
              ],
              [
                -45.274161800242716,
                -22.615690715041666
              ],
              [
                -45.26573388476779,
                -22.610007687462673
              ],
              [
                -45.26099444566282,
                -22.606635183800485
              ],
              [
                -45.263772920002936,
                -22.604071704513675
              ],
              [
                -45.258119521664035,
                -22.59269125563574
              ],
              [
                -45.25668303491836,
                -22.589799292583297
              ],
              [
                -45.25805550781436,
                -22.58608478001508
              ],
              [
                -45.25942851070579,
                -22.582369800451545
              ],
              [
                -45.226189848155634,
                -22.566208331395035
              ],
              [
                -45.22265187887146,
                -22.563131826612487
              ],
              [
                -45.2170809255628,
                -22.562736794548183
              ],
              [
                -45.211909475766696,
                -22.56236981042408
              ],
              [
                -45.20329655548608,
                -22.556798812975973
              ],
              [
                -45.177603327530576,
                -22.54937827661214
              ],
              [
                -45.164074469967176,
                -22.537429786624937
              ],
              [
                -45.16547246601138,
                -22.530675342753934
              ],
              [
                -45.16380248475995,
                -22.526642339622494
              ],
              [
                -45.159567566564824,
                -22.516418895327586
              ],
              [
                -45.1496916994547,
                -22.506835399901075
              ],
              [
                -45.14337220549651,
                -22.506136914355537
              ],
              [
                -45.12801688992524,
                -22.496609432070137
              ],
              [
                -45.12123145448154,
                -22.494770378777844
              ],
              [
                -45.102935644729705,
                -22.488239883308974
              ],
              [
                -45.091938741192074,
                -22.48317687930672
              ],
              [
                -45.09068072571395,
                -22.482597878734968
              ],
              [
                -45.08767574779847,
                -22.483747884184094
              ],
              [
                -45.082180796187515,
                -22.485850343406636
              ],
              [
                -45.060247027975876,
                -22.47205285432153
              ],
              [
                -45.04010421104231,
                -22.468771320449502
              ],
              [
                -45.027964349419484,
                -22.46269785238548
              ],
              [
                -45.02470985705494,
                -22.464369832658967
              ],
              [
                -45.025957839058016,
                -22.467723786815004
              ],
              [
                -45.02670235353032,
                -22.469724799153305
              ],
              [
                -45.01367546470618,
                -22.466829293419654
              ],
              [
                -45.009387472268735,
                -22.46587575760105
              ],
              [
                -45.005832020842156,
                -22.466458263326636
              ],
              [
                -45.000577573017175,
                -22.467986231969864
              ],
              [
                -44.99137562476281,
                -22.465671246644035
              ],
              [
                -44.96543335467345,
                -22.47468809910208
              ],
              [
                -44.95852543731736,
                -22.465402664545664
              ],
              [
                -44.93963161255427,
                -22.452169190239125
              ],
              [
                -44.89854448745597,
                -22.452326078355245
              ],
              [
                -44.889294111166265,
                -22.431622654988164
              ],
              [
                -44.87485073665254,
                -22.428065127916888
              ],
              [
                -44.87104729540345,
                -22.42375865469401
              ],
              [
                -44.859393906783836,
                -22.422565625594245
              ],
              [
                -44.85552639807835,
                -22.42609058126698
              ],
              [
                -44.85398092898653,
                -22.427499562344934
              ],
              [
                -44.844209982296505,
                -22.42728006052081
              ],
              [
                -44.832911076526024,
                -22.430380530561614
              ],
              [
                -44.82374920513028,
                -22.418905064941107
              ],
              [
                -44.80755387617267,
                -22.413563035997058
              ],
              [
                -44.807660356556006,
                -22.41281305891816
              ],
              [
                -44.80777037499938,
                -22.412033531992147
              ],
              [
                -44.80838238100129,
                -22.4077055686153
              ],
              [
                -44.80879685742615,
                -22.40477210494952
              ],
              [
                -44.80100542756408,
                -22.40561856423504
              ],
              [
                -44.77899662578411,
                -22.408009485433613
              ],
              [
                -44.77210764603191,
                -22.41284244274921
              ],
              [
                -44.7695581924072,
                -22.414631446320307
              ],
              [
                -44.76364824130324,
                -22.414549926092363
              ],
              [
                -44.760459765764104,
                -22.414505910877715
              ],
              [
                -44.75466081771092,
                -22.414425926938666
              ],
              [
                -44.73997939979992,
                -22.42612283030386
              ],
              [
                -44.72977844097287,
                -22.43962225175403
              ],
              [
                -44.72693144576726,
                -22.44668668591201
              ],
              [
                -44.718183477095586,
                -22.46487755743434
              ],
              [
                -44.71814646730622,
                -22.468828547155823
              ],
              [
                -44.719403955860734,
                -22.4703480741087
              ],
              [
                -44.722272934624236,
                -22.47381355088484
              ],
              [
                -44.72345086955755,
                -22.483583512597484
              ],
              [
                -44.72012736776933,
                -22.491830941782975
              ],
              [
                -44.716976905549075,
                -22.492239450453482
              ],
              [
                -44.71207344967715,
                -22.492874938378492
              ],
              [
                -44.70965195186243,
                -22.499911876825685
              ],
              [
                -44.71210843117972,
                -22.505970364952834
              ],
              [
                -44.7152633962018,
                -22.513751820588332
              ],
              [
                -44.705329964028856,
                -22.511613812615217
              ],
              [
                -44.7007105227345,
                -22.510619820189458
              ],
              [
                -44.69868950153883,
                -22.51340230533698
              ],
              [
                -44.69552754730668,
                -22.51775776283141
              ],
              [
                -44.687899584297604,
                -22.52150224344729
              ],
              [
                -44.67998412240533,
                -22.52568969315128
              ],
              [
                -44.6704971714052,
                -22.539188578528748
              ],
              [
                -44.67539063715433,
                -22.544138103512765
              ],
              [
                -44.675829109025756,
                -22.544581104528593
              ],
              [
                -44.67695256045012,
                -22.560483530440433
              ],
              [
                -44.66587268024023,
                -22.553462511055518
              ],
              [
                -44.661093738613815,
                -22.550434017056727
              ],
              [
                -44.64991433798458,
                -22.55651147192815
              ],
              [
                -44.647335357004955,
                -22.552330490360802
              ],
              [
                -44.641293393829535,
                -22.558867446631133
              ],
              [
                -44.641932321129616,
                -22.577133863771703
              ],
              [
                -44.646682260729456,
                -22.584138326443707
              ],
              [
                -44.6449187126541,
                -22.602061752941292
              ],
              [
                -44.633016799426294,
                -22.608730158011966
              ],
              [
                -44.62743586239254,
                -22.607117161990498
              ],
              [
                -44.62185493736122,
                -22.605504150973115
              ],
              [
                -44.61632594578873,
                -22.61041110650917
              ],
              [
                -44.60984148382546,
                -22.616165579399446
              ],
              [
                -44.604366063743555,
                -22.613955567555628
              ],
              [
                -44.604328029118484,
                -22.61394009352958
              ],
              [
                -44.601873567209886,
                -22.60713460898715
              ],
              [
                -44.59673414545187,
                -22.610047060219742
              ],
              [
                -44.59448563269826,
                -22.614946533501552
              ],
              [
                -44.59103564621099,
                -22.62246351290884
              ],
              [
                -44.583695673166815,
                -22.62545096892762
              ],
              [
                -44.57663230309037,
                -22.61015803829483
              ],
              [
                -44.57261087451435,
                -22.601451063845712
              ],
              [
                -44.56647990185949,
                -22.6074350335471
              ],
              [
                -44.562811416413275,
                -22.611014998444936
              ],
              [
                -44.55699398918041,
                -22.608843497536753
              ],
              [
                -44.548519533876224,
                -22.605680986392553
              ],
              [
                -44.53665561280064,
                -22.616801918946543
              ],
              [
                -44.53272866534373,
                -22.615267908969642
              ],
              [
                -44.525634195901134,
                -22.61901887094061
              ],
              [
                -44.53132813006267,
                -22.62544636386386
              ],
              [
                -44.53701755526956,
                -22.63186931781323
              ],
              [
                -44.53484958602077,
                -22.6370353020019
              ],
              [
                -44.530794611948075,
                -22.6393067692162
              ],
              [
                -44.534245099115374,
                -22.63252931824979
              ],
              [
                -44.53097415230454,
                -22.630252832606775
              ],
              [
                -44.524235189027905,
                -22.630034331775672
              ],
              [
                -44.51951974112061,
                -22.629881814998285
              ],
              [
                -44.51448628598837,
                -22.629718792429227
              ],
              [
                -44.508152288522155,
                -22.64044472756921
              ],
              [
                -44.48509605166298,
                -22.61041132623358
              ],
              [
                -44.485482085157074,
                -22.609493832714815
              ],
              [
                -44.48709758403858,
                -22.605654331653298
              ],
              [
                -44.48296312362297,
                -22.604042851529375
              ],
              [
                -44.48031611237442,
                -22.6082922928632
              ],
              [
                -44.47772613691458,
                -22.61245029779313
              ],
              [
                -44.4643967435381,
                -22.61546921979764
              ],
              [
                -44.45543931780597,
                -22.60960024259854
              ],
              [
                -44.4539578445616,
                -22.608629745393728
              ],
              [
                -44.447461430982614,
                -22.609800209496555
              ],
              [
                -44.44629190842104,
                -22.6100107123756
              ],
              [
                -44.44559993767845,
                -22.60893972775176
              ],
              [
                -44.4422979508484,
                -22.603829741864583
              ],
              [
                -44.42979059052185,
                -22.598029246985273
              ],
              [
                -44.42468164077102,
                -22.599272710414887
              ],
              [
                -44.41629021688975,
                -22.601314699273065
              ],
              [
                -44.405913269588275,
                -22.60712411753751
              ],
              [
                -44.3980753603588,
                -22.60251663423757
              ],
              [
                -44.40375680256926,
                -22.600158660746796
              ],
              [
                -44.404965822965025,
                -22.59141222054012
              ],
              [
                -44.40587631265654,
                -22.590581699992654
              ],
              [
                -44.40896778162959,
                -22.587762760896513
              ],
              [
                -44.395258931469826,
                -22.58105275435458
              ],
              [
                -44.391237504125336,
                -22.57290528844292
              ],
              [
                -44.38397906878099,
                -22.573223736720593
              ],
              [
                -44.37120565647534,
                -22.579605695869315
              ],
              [
                -44.363605734254676,
                -22.57791417405026
              ],
              [
                -44.359935735669744,
                -22.581267144006826
              ],
              [
                -44.361735711631326,
                -22.582736638593076
              ],
              [
                -44.36984865629997,
                -22.589363154712085
              ],
              [
                -44.36593564486037,
                -22.59335060493585
              ],
              [
                -44.36115121336768,
                -22.592631620800713
              ],
              [
                -44.36081466564479,
                -22.6052345564793
              ],
              [
                -44.36065466869404,
                -22.61121250992548
              ],
              [
                -44.35596569635399,
                -22.613764992091195
              ],
              [
                -44.34929323930636,
                -22.608765509818305
              ],
              [
                -44.35137376596872,
                -22.599099536436164
              ],
              [
                -44.34984877541158,
                -22.595093559940146
              ],
              [
                -44.345736326788895,
                -22.596769559904686
              ],
              [
                -44.34725534120893,
                -22.590065069613335
              ],
              [
                -44.343205857698145,
                -22.587935576307533
              ],
              [
                -44.33975789638037,
                -22.59057855431115
              ],
              [
                -44.33054345277887,
                -22.58900505266096
              ],
              [
                -44.32597249364911,
                -22.58822453740418
              ],
              [
                -44.322131047972256,
                -22.592098520360274
              ],
              [
                -44.317579550237134,
                -22.596688956945727
              ],
              [
                -44.31312162080437,
                -22.595038500232334
              ],
              [
                -44.312325611583326,
                -22.594743963568757
              ],
              [
                -44.31114112566955,
                -22.595445494018655
              ],
              [
                -44.305815137021966,
                -22.598602940551118
              ],
              [
                -44.298637719842695,
                -22.598015424475886
              ],
              [
                -44.29534773509795,
                -22.597746434107016
              ],
              [
                -44.295194772429454,
                -22.598723424933922
              ],
              [
                -44.29471071813861,
                -22.601818415548248
              ],
              [
                -44.285257813154224,
                -22.609228838426183
              ],
              [
                -44.28265531852731,
                -22.60371638446846
              ],
              [
                -44.277261886806016,
                -22.602195344664462
              ],
              [
                -44.27107143466292,
                -22.600450349855617
              ],
              [
                -44.26581749903338,
                -22.603736335947307
              ],
              [
                -44.253023561178615,
                -22.611738743111026
              ],
              [
                -44.24763263079899,
                -22.606184261026144
              ],
              [
                -44.24758413336989,
                -22.60613475214013
              ],
              [
                -44.2323372594387,
                -22.608035713647244
              ],
              [
                -44.23065827582933,
                -22.60742570620195
              ],
              [
                -44.22637282615566,
                -22.604073230292098
              ],
              [
                -44.22004433795837,
                -22.61484814694063
              ],
              [
                -44.218684375644344,
                -22.617163142040308
              ],
              [
                -44.21606338140815,
                -22.616482147457905
              ],
              [
                -44.20756497371159,
                -22.614272612855093
              ],
              [
                -44.20375001276284,
                -22.614915626655648
              ],
              [
                -44.2034434561098,
                -22.62351608680217
              ],
              [
                -44.203349973919444,
                -22.626130570776272
              ],
              [
                -44.198060518880744,
                -22.62999504593925
              ],
              [
                -44.1973775038685,
                -22.629970521257967
              ],
              [
                -44.19039958203393,
                -22.629723503065552
              ],
              [
                -44.18384110517992,
                -22.636794964203204
              ],
              [
                -44.18656354827477,
                -22.641476963498977
              ],
              [
                -44.18988251005641,
                -22.647185418359776
              ],
              [
                -44.17619863546671,
                -22.655703843630352
              ],
              [
                -44.173492629745766,
                -22.65738783826128
              ],
              [
                -44.161748694159535,
                -22.67413522444183
              ],
              [
                -44.161287175930624,
                -22.675514224487003
              ],
              [
                -44.16051420854711,
                -22.677823717164824
              ],
              [
                -44.16366666637483,
                -22.680060195565385
              ],
              [
                -44.16564813689488,
                -22.681466202931716
              ],
              [
                -44.16801311322241,
                -22.68706719182899
              ],
              [
                -44.16842061472783,
                -22.688033173189286
              ],
              [
                -44.16872359455187,
                -22.68821270311326
              ],
              [
                -44.176209505050366,
                -22.692658674191158
              ],
              [
                -44.17493850295211,
                -22.698231653633783
              ],
              [
                -44.17416952526281,
                -22.70160264515124
              ],
              [
                -44.1779144736327,
                -22.70719659176079
              ],
              [
                -44.184473373666066,
                -22.712449115489523
              ],
              [
                -44.18920234617979,
                -22.713268104006875
              ],
              [
                -44.20328170672293,
                -22.71570663037699
              ],
              [
                -44.20155219650297,
                -22.723249094038437
              ],
              [
                -44.200740738672124,
                -22.723528576528057
              ],
              [
                -44.19798421863064,
                -22.72447758960516
              ],
              [
                -44.20154468986448,
                -22.73192655073648
              ],
              [
                -44.21658708069275,
                -22.732861569028916
              ],
              [
                -44.22676196362724,
                -22.73349460590717
              ],
              [
                -44.24166681003097,
                -22.74891907335383
              ],
              [
                -44.239083314302405,
                -22.75381504668255
              ],
              [
                -44.238344791379454,
                -22.75521503391304
              ],
              [
                -44.23972228677891,
                -22.755433010507407
              ],
              [
                -44.25003820942553,
                -22.757064055948508
              ],
              [
                -44.25713412029122,
                -22.767981497709293
              ],
              [
                -44.257042567194205,
                -22.778305952292765
              ],
              [
                -44.25340860967162,
                -22.781214929504078
              ],
              [
                -44.25606158110049,
                -22.786909408888032
              ],
              [
                -44.25263559895921,
                -22.78814137879882
              ],
              [
                -44.24757011419752,
                -22.78996235049125
              ],
              [
                -44.241787186326846,
                -22.78768287320055
              ],
              [
                -44.24127417736295,
                -22.792667837616406
              ],
              [
                -44.245072599345065,
                -22.802084815969156
              ],
              [
                -44.25993299413984,
                -22.80451081270529
              ],
              [
                -44.2609789925527,
                -22.80468181765718
              ],
              [
                -44.26324345135142,
                -22.811334804388416
              ],
              [
                -44.26952733474297,
                -22.829797756541918
              ],
              [
                -44.272882301326625,
                -22.83120524365115
              ],
              [
                -44.28288920865553,
                -22.835404241949718
              ],
              [
                -44.28628669207109,
                -22.8280407786973
              ],
              [
                -44.2962060883616,
                -22.82734279750548
              ],
              [
                -44.31698640693853,
                -22.839449307235526
              ],
              [
                -44.3183448791031,
                -22.841085299935312
              ],
              [
                -44.32146983137961,
                -22.844846775056208
              ],
              [
                -44.32047538254113,
                -22.841447285864348
              ],
              [
                -44.32004386085785,
                -22.839971303861002
              ],
              [
                -44.325383810612074,
                -22.838049828406767
              ],
              [
                -44.35031806163544,
                -22.848108828247437
              ],
              [
                -44.373799366079865,
                -22.85758136114541
              ],
              [
                -44.38691823568784,
                -22.857242870835705
              ],
              [
                -44.389157704837025,
                -22.854828416488704
              ],
              [
                -44.391652210638746,
                -22.852138437154704
              ],
              [
                -44.39788715882313,
                -22.85339594273958
              ],
              [
                -44.40124261171099,
                -22.854072422439017
              ],
              [
                -44.401440146922745,
                -22.852812930052472
              ],
              [
                -44.40240614773136,
                -22.846654983390152
              ],
              [
                -44.41296004211977,
                -22.847744983327644
              ],
              [
                -44.42156846860031,
                -22.85099799262565
              ],
              [
                -44.426283381779,
                -22.85519848894787
              ],
              [
                -44.43670224546321,
                -22.870696937013324
              ],
              [
                -44.46656248424902,
                -22.884557933139238
              ],
              [
                -44.47126794266165,
                -22.875568019080042
              ],
              [
                -44.47645891541512,
                -22.873778023615422
              ],
              [
                -44.47602593191937,
                -22.87032754008889
              ],
              [
                -44.47566794789767,
                -22.867478536865374
              ],
              [
                -44.47836890546467,
                -22.866551566568656
              ],
              [
                -44.480516886215185,
                -22.865814566874985
              ],
              [
                -44.47980190629339,
                -22.864242090541286
              ],
              [
                -44.47598349845683,
                -22.85584158982603
              ],
              [
                -44.47934695092171,
                -22.850866117827618
              ],
              [
                -44.483948955480905,
                -22.84595865781647
              ],
              [
                -44.49026485990002,
                -22.84597670963446
              ],
              [
                -44.49428886012964,
                -22.84598769135317
              ],
              [
                -44.50543771013462,
                -22.85136569927487
              ],
              [
                -44.51550210931337,
                -22.86129117540469
              ],
              [
                -44.51907007553931,
                -22.864810155014844
              ],
              [
                -44.525827036114194,
                -22.866769183692572
              ],
              [
                -44.53258494168453,
                -22.868728190378974
              ],
              [
                -44.545235791968324,
                -22.880751667443626
              ],
              [
                -44.5447393133133,
                -22.882011641048827
              ],
              [
                -44.54378683096914,
                -22.884430612853887
              ],
              [
                -44.55479020804121,
                -22.888587140397416
              ],
              [
                -44.575516067047104,
                -22.878109254433973
              ],
              [
                -44.60018181750737,
                -22.88829426473515
              ],
              [
                -44.60437176671287,
                -22.886273773702413
              ],
              [
                -44.60662724867641,
                -22.885185773990756
              ],
              [
                -44.60962771275164,
                -22.887760763685026
              ],
              [
                -44.62868353227011,
                -22.90411574940794
              ],
              [
                -44.63464046600749,
                -22.906643753307726
              ],
              [
                -44.638577912972316,
                -22.913690726101404
              ],
              [
                -44.64604283098218,
                -22.918682199265703
              ],
              [
                -44.6590476996206,
                -22.92737922561353
              ],
              [
                -44.670487113975035,
                -22.91976925190726
              ],
              [
                -44.673455615608255,
                -22.917794272370266
              ],
              [
                -44.67755204421077,
                -22.922924767828682
              ],
              [
                -44.687923429060696,
                -22.93591374858889
              ],
              [
                -44.69572089098601,
                -22.93175526353032
              ],
              [
                -44.70428328194614,
                -22.93439829997842
              ],
              [
                -44.70435027651084,
                -22.93435330137454
              ],
              [
                -44.71148524742285,
                -22.92947534097076
              ],
              [
                -44.74340891518147,
                -22.947096334881717
              ],
              [
                -44.75182927090631,
                -22.961949760668254
              ],
              [
                -44.7510663031879,
                -22.963942766085864
              ],
              [
                -44.748936285968625,
                -22.969507723747483
              ],
              [
                -44.75610370779825,
                -22.97536671315931
              ],
              [
                -44.75485023658734,
                -22.97980216859389
              ],
              [
                -44.75903566883568,
                -22.97844970737537
              ],
              [
                -44.764877631519425,
                -22.982897685957724
              ],
              [
                -44.770860085734526,
                -22.98141120524473
              ],
              [
                -44.78188447253153,
                -22.97867277315443
              ],
              [
                -44.786682950871885,
                -22.982275768960015
              ],
              [
                -44.792038907735716,
                -22.981662768387785
              ],
              [
                -44.79287583504762,
                -22.993209225599244
              ],
              [
                -44.80220325763617,
                -22.9986417009839
              ],
              [
                -44.79497579906784,
                -23.007095140109012
              ],
              [
                -44.7927078031859,
                -23.009748134318762
              ],
              [
                -44.79740523992292,
                -23.018231118645698
              ],
              [
                -44.798230755101706,
                -23.01972160478608
              ],
              [
                -44.79796574096526,
                -23.02080058092838
              ],
              [
                -44.797152214468575,
                -23.02411507193654
              ],
              [
                -44.800031699396975,
                -23.02894454375389
              ],
              [
                -44.801209666004794,
                -23.03091956654802
              ],
              [
                -44.801453676632214,
                -23.03384606127897
              ],
              [
                -44.80271811869514,
                -23.04900449857351
              ],
              [
                -44.806504076977305,
                -23.05125595514677
              ],
              [
                -44.81112802946738,
                -23.054005951629346
              ],
              [
                -44.8086125207767,
                -23.05852295131132
              ],
              [
                -44.80688354479868,
                -23.061627932846534
              ],
              [
                -44.80967000054913,
                -23.063918898526516
              ],
              [
                -44.81357046480296,
                -23.067126411880597
              ],
              [
                -44.8127139678607,
                -23.074465889664523
              ],
              [
                -44.81798434223055,
                -23.0896553368319
              ],
              [
                -44.81241892052494,
                -23.09141629232074
              ],
              [
                -44.811926398267495,
                -23.091572314239244
              ],
              [
                -44.8124478893562,
                -23.09799377115776
              ],
              [
                -44.808596401560656,
                -23.099233751784226
              ],
              [
                -44.813959860491465,
                -23.107061224217194
              ],
              [
                -44.81576884251868,
                -23.109701207610588
              ],
              [
                -44.814355324267744,
                -23.111088714173867
              ],
              [
                -44.81076434795113,
                -23.114614684654562
              ],
              [
                -44.81317582248775,
                -23.121116664363395
              ],
              [
                -44.81067383296968,
                -23.12521511809606
              ],
              [
                -44.81877873590617,
                -23.132211608993348
              ],
              [
                -44.81937474113745,
                -23.132725612180142
              ],
              [
                -44.81923573519601,
                -23.133082613107813
              ],
              [
                -44.81643272440943,
                -23.14027007537523
              ],
              [
                -44.8244481736257,
                -23.143024586175674
              ],
              [
                -44.825011635156685,
                -23.14321860479285
              ],
              [
                -44.82498514273552,
                -23.14404907478328
              ],
              [
                -44.82443612343749,
                -23.160976485053382
              ],
              [
                -44.83601099569344,
                -23.16507951921885
              ],
              [
                -44.84014196045767,
                -23.166544011564813
              ],
              [
                -44.843931891854666,
                -23.168103504531935
              ],
              [
                -44.845044904911646,
                -23.170720527180922
              ],
              [
                -44.84581838443733,
                -23.17254050267737
              ],
              [
                -44.8486893430156,
                -23.172450498947992
              ],
              [
                -44.85376232686503,
                -23.172291523562823
              ],
              [
                -44.85325731176989,
                -23.17461901916577
              ],
              [
                -44.85290279960909,
                -23.176249001481906
              ],
              [
                -44.86029772981171,
                -23.180988478267874
              ],
              [
                -44.87261362382286,
                -23.183805507619606
              ],
              [
                -44.87592956476921,
                -23.197399956410102
              ],
              [
                -44.87729003597289,
                -23.19867696109688
              ],
              [
                -44.88259798733321,
                -23.203661433068525
              ],
              [
                -44.881452960734244,
                -23.211914414937453
              ],
              [
                -44.88095944058843,
                -23.215467857798455
              ],
              [
                -44.88335490627426,
                -23.218181380542312
              ],
              [
                -44.888491859410145,
                -23.223999350140886
              ],
              [
                -44.885069880399826,
                -23.23193233130689
              ],
              [
                -44.877844947326125,
                -23.23232228166324
              ],
              [
                -44.87461839963981,
                -23.248661702683037
              ],
              [
                -44.864304517702834,
                -23.248576203805573
              ],
              [
                -44.85399258476143,
                -23.24849117793731
              ],
              [
                -44.83651271120968,
                -23.25630557311995
              ],
              [
                -44.83970218225836,
                -23.26330504181197
              ],
              [
                -44.83011223344654,
                -23.269351018969473
              ],
              [
                -44.82923773750343,
                -23.271989464114238
              ],
              [
                -44.82682821890146,
                -23.27925894018761
              ],
              [
                -44.82362126997104,
                -23.28036741515816
              ],
              [
                -44.82616822167075,
                -23.290740887186864
              ],
              [
                -44.81403283222372,
                -23.28577289233861
              ],
              [
                -44.806952873559645,
                -23.282874394605113
              ],
              [
                -44.80491488771637,
                -23.289535354564585
              ],
              [
                -44.787030036624046,
                -23.299576259096362
              ],
              [
                -44.78733049982324,
                -23.305549698718355
              ],
              [
                -44.78006055720829,
                -23.315282145826444
              ],
              [
                -44.78052550500361,
                -23.318450156149922
              ],
              [
                -44.78162447743095,
                -23.325935100845495
              ],
              [
                -44.776319504486395,
                -23.333296053548022
              ],
              [
                -44.77787749123194,
                -23.33851606020249
              ],
              [
                -44.760980634600514,
                -23.341747985545304
              ],
              [
                -44.759702633840824,
                -23.34199247688184
              ],
              [
                -44.758061627578655,
                -23.345227961109053
              ],
              [
                -44.756421635306936,
                -23.348463451339633
              ],
              [
                -44.74970416945754,
                -23.3580283877634
              ],
              [
                -44.74237722990744,
                -23.357964868933177
              ],
              [
                -44.736460763786695,
                -23.362059329361994
              ],
              [
                -44.7334732739154,
                -23.364126822400095
              ],
              [
                -44.72801535550386,
                -23.366154787837303
              ],
              [
                -44.72399936052122,
                -23.36760076298173
              ],
              [
                -44.72996279618129,
                -23.370034277885193
              ],
              [
                -44.7355437661579,
                -23.369052792851097
              ],
              [
                -44.74562416729513,
                -23.367141329569534
              ],
              [
                -44.75024516772604,
                -23.36642182766321
              ],
              [
                -44.76254100651147,
                -23.370788860876598
              ],
              [
                -44.76709499898991,
                -23.372406345775932
              ],
              [
                -44.772976938486586,
                -23.374495860106848
              ],
              [
                -44.783985837616754,
                -23.37817438306455
              ],
              [
                -44.78503831558465,
                -23.373823405455678
              ],
              [
                -44.78569534168405,
                -23.371107422058092
              ],
              [
                -44.791652769982456,
                -23.369653449297044
              ],
              [
                -44.79714120550497,
                -23.370933443368827
              ],
              [
                -44.80013772308413,
                -23.37173042586099
              ],
              [
                -44.8005207003023,
                -23.375903948261552
              ],
              [
                -44.80136067925287,
                -23.376462434943846
              ],
              [
                -44.80604263581656,
                -23.379573938183015
              ],
              [
                -44.820100011791475,
                -23.381983450550674
              ],
              [
                -44.825630950700564,
                -23.386126932290452
              ],
              [
                -44.8282809281034,
                -23.38790392423203
              ],
              [
                -44.83131988684037,
                -23.385757448701625
              ],
              [
                -44.83735435786166,
                -23.38697847558592
              ],
              [
                -44.843778293596756,
                -23.386941986460567
              ],
              [
                -44.83932536292995,
                -23.378918996924128
              ],
              [
                -44.839011362844204,
                -23.376689514547042
              ],
              [
                -44.83847437379601,
                -23.372877029004467
              ],
              [
                -44.83820387674493,
                -23.369282038174966
              ],
              [
                -44.853510265576574,
                -23.360999141297494
              ],
              [
                -44.87127361690515,
                -23.359330672067134
              ],
              [
                -44.8777310564524,
                -23.36360668574746
              ],
              [
                -44.884738477975176,
                -23.368280158086485
              ],
              [
                -44.891114927427346,
                -23.372491181872284
              ],
              [
                -44.88812348276208,
                -23.365204682872424
              ],
              [
                -44.890645964247454,
                -23.358643744666573
              ],
              [
                -44.88667750243631,
                -23.353381259477427
              ],
              [
                -44.88487550906567,
                -23.35087175431584
              ],
              [
                -44.883021075381315,
                -23.34828924535543
              ],
              [
                -44.88544405828544,
                -23.341547804739793
              ],
              [
                -44.887443051513856,
                -23.337074334289593
              ],
              [
                -44.89175650492816,
                -23.336281325895087
              ],
              [
                -44.89748146964478,
                -23.33534786704781
              ],
              [
                -44.907363382513665,
                -23.33319538038091
              ],
              [
                -44.910943338623156,
                -23.338480859383502
              ],
              [
                -44.917183256091214,
                -23.347397839654413
              ],
              [
                -44.92209918830698,
                -23.35032583844192
              ],
              [
                -44.92211121018085,
                -23.35033285044213
              ],
              [
                -44.922546189599025,
                -23.350591852423968
              ],
              [
                -44.926581646118976,
                -23.352986326297025
              ],
              [
                -44.94191049775301,
                -23.35713835406276
              ],
              [
                -44.94830193229454,
                -23.363767368570787
              ],
              [
                -44.94839843074472,
                -23.368931320598623
              ],
              [
                -44.94895142325938,
                -23.37471328399065
              ],
              [
                -44.94900739488106,
                -23.375298787397735
              ],
              [
                -44.94931287489966,
                -23.37849427324754
              ],
              [
                -44.95630982570423,
                -23.379722270805843
              ],
              [
                -44.96092380537397,
                -23.380532296748104
              ],
              [
                -44.962340771497054,
                -23.3838283092036
              ],
              [
                -44.962880765783424,
                -23.385082778812407
              ],
              [
                -44.96619623809054,
                -23.38667527150416
              ],
              [
                -44.9670397096929,
                -23.387026774186364
              ],
              [
                -44.97064365781175,
                -23.388528778102103
              ],
              [
                -44.97067318040687,
                -23.38950029462932
              ],
              [
                -44.9707896629344,
                -23.393344769930234
              ],
              [
                -44.973513637771774,
                -23.39603376285609
              ],
              [
                -44.97859309597736,
                -23.400067256997588
              ],
              [
                -44.98301302828846,
                -23.396881791142768
              ],
              [
                -44.984747513952634,
                -23.397009297340873
              ],
              [
                -44.98646450277261,
                -23.397135279497938
              ],
              [
                -44.9932609536855,
                -23.397634785951425
              ],
              [
                -45.007972290825116,
                -23.406048786247293
              ],
              [
                -45.009488284892576,
                -23.408786298628634
              ],
              [
                -45.014598228950796,
                -23.418014270590643
              ],
              [
                -45.022258158640625,
                -23.415995291245906
              ],
              [
                -45.02446413559598,
                -23.415403280123805
              ],
              [
                -45.03171559657674,
                -23.41345583931384
              ],
              [
                -45.03730252225794,
                -23.418770337933786
              ],
              [
                -45.039636468397376,
                -23.421166322196804
              ],
              [
                -45.04028046571175,
                -23.421826803893126
              ],
              [
                -45.04362344881003,
                -23.425256787122112
              ],
              [
                -45.04712089320332,
                -23.42511532447583
              ],
              [
                -45.0464989079196,
                -23.423772810027124
              ],
              [
                -45.045206926100384,
                -23.420984300481862
              ],
              [
                -45.04844044088879,
                -23.418345829775518
              ],
              [
                -45.05170390049745,
                -23.415683861261765
              ],
              [
                -45.06092433434812,
                -23.419648357282213
              ],
              [
                -45.06422876525874,
                -23.42661835587486
              ],
              [
                -45.05999226517543,
                -23.430762800766853
              ],
              [
                -45.06537074750468,
                -23.430898822045453
              ],
              [
                -45.068745716522606,
                -23.43518930936682
              ],
              [
                -45.06698968338308,
                -23.446614272149098
              ],
              [
                -45.06012622749845,
                -23.455473193746464
              ],
              [
                -45.059260700295184,
                -23.45659067712788
              ],
              [
                -45.05498774456514,
                -23.458880151580857
              ],
              [
                -45.049648284824734,
                -23.455399175013515
              ],
              [
                -45.03792741551711,
                -23.44775717718828
              ],
              [
                -45.03505945286294,
                -23.449389161610256
              ],
              [
                -45.03470192600876,
                -23.456118148186576
              ],
              [
                -45.03452943990782,
                -23.45936511654338
              ],
              [
                -45.02773799565608,
                -23.460182596883268
              ],
              [
                -45.01865653907127,
                -23.461319068382075
              ],
              [
                -45.03279541556209,
                -23.464352083443334
              ],
              [
                -45.03771938654782,
                -23.463022607315516
              ],
              [
                -45.044726830456455,
                -23.46113012059861
              ],
              [
                -45.04903729701627,
                -23.465230615420857
              ],
              [
                -45.05215573282997,
                -23.468409614241192
              ],
              [
                -45.05635822982895,
                -23.46499714083475
              ],
              [
                -45.05973568910309,
                -23.466740160078462
              ],
              [
                -45.061977177987835,
                -23.467918129806343
              ],
              [
                -45.06337114361444,
                -23.46865014726215
              ],
              [
                -45.06844209351129,
                -23.477688642187257
              ],
              [
                -45.073579523376644,
                -23.4868625906897
              ],
              [
                -45.07358898242957,
                -23.491105554938066
              ],
              [
                -45.07348147422307,
                -23.491145578452606
              ],
              [
                -45.07008602127199,
                -23.49241355410243
              ],
              [
                -45.06696904126973,
                -23.493577057013336
              ],
              [
                -45.0721899953833,
                -23.49969350502414
              ],
              [
                -45.076516921632084,
                -23.504753518483525
              ],
              [
                -45.08024388832956,
                -23.509111996550544
              ],
              [
                -45.08162282869656,
                -23.521264434799726
              ],
              [
                -45.08419531293317,
                -23.51657445779668
              ],
              [
                -45.08605530620229,
                -23.51313448399103
              ],
              [
                -45.08507384697225,
                -23.50600453646323
              ],
              [
                -45.08460685609178,
                -23.502610031974815
              ],
              [
                -45.084125375673665,
                -23.499111560932526
              ],
              [
                -45.08400286563743,
                -23.49822156773797
              ],
              [
                -45.08359487983209,
                -23.4952590514057
              ],
              [
                -45.09771029514403,
                -23.492617142975195
              ],
              [
                -45.10355374825963,
                -23.49587811004391
              ],
              [
                -45.10309475610687,
                -23.489792662109693
              ],
              [
                -45.10645470111892,
                -23.488604683989585
              ],
              [
                -45.11120164133625,
                -23.49594015303615
              ],
              [
                -45.11819608904635,
                -23.499360116581617
              ],
              [
                -45.123969530169376,
                -23.502193142468638
              ],
              [
                -45.11994404754407,
                -23.503479630273773
              ],
              [
                -45.11722807503742,
                -23.50434812767015
              ],
              [
                -45.112628102811385,
                -23.505800082105694
              ],
              [
                -45.10787663773315,
                -23.507300092895882
              ],
              [
                -45.11000409578038,
                -23.512391565122154
              ],
              [
                -45.106746127407874,
                -23.514337557993674
              ],
              [
                -45.1062606227061,
                -23.523152993621565
              ],
              [
                -45.11363905291591,
                -23.525403524707816
              ],
              [
                -45.11337356582014,
                -23.519720033406877
              ],
              [
                -45.11641705486716,
                -23.51996352675233
              ],
              [
                -45.12112348118795,
                -23.52034054811041
              ],
              [
                -45.12200600580898,
                -23.51600507873593
              ],
              [
                -45.132981424297235,
                -23.50904911766647
              ],
              [
                -45.13760538373524,
                -23.51113715083555
              ],
              [
                -45.13695941121059,
                -23.50780165554996
              ],
              [
                -45.13632440839791,
                -23.504524658024962
              ],
              [
                -45.136009422426525,
                -23.502899172709768
              ],
              [
                -45.14412833967465,
                -23.5010716868231
              ],
              [
                -45.14635134336455,
                -23.496557209015105
              ],
              [
                -45.153957765831485,
                -23.49814872779691
              ],
              [
                -45.159909716867844,
                -23.499325741903213
              ],
              [
                -45.16542621293089,
                -23.49186678395748
              ],
              [
                -45.171243153981,
                -23.4930917904645
              ],
              [
                -45.17488561413205,
                -23.499984793567034
              ],
              [
                -45.17197959504895,
                -23.502435766075312
              ],
              [
                -45.17304606880708,
                -23.50978473089181
              ],
              [
                -45.16380513128871,
                -23.517063690302653
              ],
              [
                -45.16605657325703,
                -23.52825212059881
              ],
              [
                -45.16559957084529,
                -23.528354627847914
              ],
              [
                -45.15841014829587,
                -23.52996808729898
              ],
              [
                -45.161966571795425,
                -23.541837069094306
              ],
              [
                -45.16489256802349,
                -23.54081404901039
              ],
              [
                -45.169188052745326,
                -23.539104596935733
              ],
              [
                -45.17430295869496,
                -23.542797103069574
              ],
              [
                -45.17787744300306,
                -23.537924109668594
              ],
              [
                -45.185489873436865,
                -23.54103113249735
              ],
              [
                -45.18966739381115,
                -23.53334564283078
              ],
              [
                -45.18838788138143,
                -23.53103516098463
              ],
              [
                -45.186861411539546,
                -23.528277194528453
              ],
              [
                -45.19511084474357,
                -23.519740225184368
              ],
              [
                -45.21303020460334,
                -23.525331264243487
              ],
              [
                -45.22778251182056,
                -23.53756775168382
              ],
              [
                -45.231155488086856,
                -23.548454194642225
              ],
              [
                -45.227962988216454,
                -23.550358692891127
              ],
              [
                -45.22783347407286,
                -23.550436196169866
              ],
              [
                -45.21809655945747,
                -23.55626561293201
              ],
              [
                -45.21705655871346,
                -23.55890110380921
              ],
              [
                -45.21568303464364,
                -23.564632599406504
              ],
              [
                -45.21240706156105,
                -23.564694069961472
              ],
              [
                -45.20945962210709,
                -23.564619592065633
              ],
              [
                -45.214879548772934,
                -23.568208056591335
              ],
              [
                -45.21020907130917,
                -23.570503548893146
              ],
              [
                -45.20844358349545,
                -23.578695520999094
              ],
              [
                -45.211058537413685,
                -23.582530994547636
              ],
              [
                -45.21655599008361,
                -23.580447031582118
              ],
              [
                -45.221974966342174,
                -23.581122516617395
              ],
              [
                -45.2288813702279,
                -23.581983045961515
              ],
              [
                -45.23426835878331,
                -23.582654045933687
              ],
              [
                -45.24447373830714,
                -23.592936009925555
              ],
              [
                -45.252722652809915,
                -23.593152552030922
              ],
              [
                -45.25500314704539,
                -23.591696572154504
              ],
              [
                -45.25785110460539,
                -23.58987907353986
              ],
              [
                -45.259569107520285,
                -23.592106050052468
              ],
              [
                -45.263100553120594,
                -23.596683539779143
              ],
              [
                -45.26338254240498,
                -23.591014570784875
              ],
              [
                -45.26348204611551,
                -23.589024601267198
              ],
              [
                -45.26942903259121,
                -23.58736013462669
              ],
              [
                -45.27288750611924,
                -23.586392111793348
              ],
              [
                -45.2758894749255,
                -23.587436640374676
              ],
              [
                -45.27826494882495,
                -23.588263121209817
              ],
              [
                -45.27906645667626,
                -23.586527658480033
              ],
              [
                -45.28018192507298,
                -23.58411367376758
              ],
              [
                -45.27712447949148,
                -23.57768618292677
              ],
              [
                -45.28117597420646,
                -23.57299720096326
              ],
              [
                -45.28596640339217,
                -23.573827218542164
              ],
              [
                -45.29305283858516,
                -23.575054253719905
              ],
              [
                -45.29718132943463,
                -23.571644270053856
              ],
              [
                -45.30817071257926,
                -23.57487328191816
              ],
              [
                -45.31452915691203,
                -23.576742299101344
              ],
              [
                -45.31910411415694,
                -23.57781629696321
              ],
              [
                -45.32873152654552,
                -23.585723789431537
              ],
              [
                -45.333412995280554,
                -23.58940175257989
              ],
              [
                -45.3364554429968,
                -23.59188774563869
              ],
              [
                -45.33821692913854,
                -23.59454024833718
              ],
              [
                -45.33897838382339,
                -23.59568724717983
              ],
              [
                -45.35109775661047,
                -23.61394268298611
              ],
              [
                -45.355006187640384,
                -23.619829168825568
              ],
              [
                -45.35796913747597,
                -23.624292664577276
              ],
              [
                -45.364144617117965,
                -23.627145163790942
              ],
              [
                -45.370264016574545,
                -23.62989716431713
              ],
              [
                -45.37569647810384,
                -23.62534871754792
              ],
              [
                -45.37769399853297,
                -23.623676227869904
              ],
              [
                -45.380033446278304,
                -23.625552723803615
              ],
              [
                -45.38076793896286,
                -23.626163731054568
              ],
              [
                -45.38264542970643,
                -23.627725709140066
              ],
              [
                -45.38949237819521,
                -23.63341870218367
              ],
              [
                -45.39628831029631,
                -23.62940522978291
              ],
              [
                -45.39655779832181,
                -23.628701247782406
              ],
              [
                -45.39859181649579,
                -23.623392762947454
              ],
              [
                -45.406127753774044,
                -23.6228482946537
              ],
              [
                -45.410341217341326,
                -23.627180283566457
              ],
              [
                -45.416084632585296,
                -23.633086796548398
              ],
              [
                -45.41835161244609,
                -23.635417790205143
              ],
              [
                -45.419922565912785,
                -23.638156246029983
              ],
              [
                -45.426123512935554,
                -23.64897073775874
              ],
              [
                -45.42974242699607,
                -23.655282217940766
              ],
              [
                -45.430717407893894,
                -23.66604416023087
              ],
              [
                -45.430966913325285,
                -23.66888213189513
              ],
              [
                -45.43165434340089,
                -23.676714100856124
              ],
              [
                -45.432266339978156,
                -23.683684100572762
              ],
              [
                -45.430919836487895,
                -23.68940058052542
              ],
              [
                -45.42998933530714,
                -23.6933515217605
              ],
              [
                -45.42925930524001,
                -23.69645151046922
              ],
              [
                -45.42820583937168,
                -23.700924009966123
              ],
              [
                -45.426540835085206,
                -23.707994480815806
              ],
              [
                -45.42041831787031,
                -23.72589786938184
              ],
              [
                -45.414338879084966,
                -23.72375833302061
              ],
              [
                -45.412171376197946,
                -23.722995358398205
              ],
              [
                -45.40440894336693,
                -23.723697819248933
              ],
              [
                -45.40035251489517,
                -23.72406732309636
              ],
              [
                -45.39684002186698,
                -23.725006295866812
              ],
              [
                -45.397533977367694,
                -23.730573272278807
              ],
              [
                -45.398234494647646,
                -23.736187751496935
              ],
              [
                -45.40312643956696,
                -23.7413322527224
              ],
              [
                -45.40475439190485,
                -23.743044249472053
              ],
              [
                -45.407179858610114,
                -23.745594748632502
              ],
              [
                -45.41095533270262,
                -23.74965622268572
              ],
              [
                -45.40578330992936,
                -23.761967166627628
              ],
              [
                -45.40058332214122,
                -23.774948086430793
              ],
              [
                -45.39940784675401,
                -23.77628406798313
              ],
              [
                -45.39630634655885,
                -23.779810536048608
              ],
              [
                -45.39693083238992,
                -23.78364455325204
              ],
              [
                -45.3975933331099,
                -23.787712511494348
              ],
              [
                -45.39873127797692,
                -23.79470250670558
              ],
              [
                -45.396548297724514,
                -23.79768297187584
              ],
              [
                -45.399330243332734,
                -23.808899947408555
              ],
              [
                -45.402704236313504,
                -23.80653647178629
              ],
              [
                -45.40705365867113,
                -23.81052392386503
              ],
              [
                -45.40570169796799,
                -23.815389923769214
              ],
              [
                -45.40462868210427,
                -23.819588881517863
              ],
              [
                -45.40989113973216,
                -23.821155421273513
              ],
              [
                -45.41350806520516,
                -23.822204400732968
              ],
              [
                -45.420066524598006,
                -23.82410739863267
              ],
              [
                -45.420734522268894,
                -23.824585427341944
              ],
              [
                -45.43081389767528,
                -23.831798923968293
              ],
              [
                -45.431342409535944,
                -23.828700433628146
              ],
              [
                -45.434122378934234,
                -23.827737912915676
              ],
              [
                -45.439423842649525,
                -23.825902436347928
              ],
              [
                -45.44075434171558,
                -23.827253443950287
              ],
              [
                -45.44488029029824,
                -23.83144394351581
              ],
              [
                -45.44640280916587,
                -23.826400962876566
              ],
              [
                -45.44644329187918,
                -23.82638298907358
              ],
              [
                -45.464673162964964,
                -23.8182470519842
              ],
              [
                -45.467919136709845,
                -23.820169532416795
              ],
              [
                -45.47184606326591,
                -23.82249605094018
              ],
              [
                -45.47411406333048,
                -23.82383955224492
              ],
              [
                -45.48067847205444,
                -23.827727555136704
              ],
              [
                -45.4899304177217,
                -23.829840070776832
              ],
              [
                -45.49122085853709,
                -23.83583555508656
              ],
              [
                -45.50052427769973,
                -23.83886104672777
              ],
              [
                -45.50250427542815,
                -23.841336025069207
              ],
              [
                -45.51295166952118,
                -23.83998209194764
              ],
              [
                -45.51305772067473,
                -23.83103510404757
              ],
              [
                -45.52168813105675,
                -23.830361137654542
              ],
              [
                -45.53704999473734,
                -23.829122182980594
              ],
              [
                -45.53554201513087,
                -23.824214710064854
              ],
              [
                -45.53376906041647,
                -23.819119736256443
              ],
              [
                -45.533557067272575,
                -23.818511713426673
              ],
              [
                -45.543506991075255,
                -23.808402785805253
              ],
              [
                -45.54949794215544,
                -23.808898812568522
              ],
              [
                -45.55423492531597,
                -23.809290832244415
              ],
              [
                -45.554346975095996,
                -23.797154369908277
              ],
              [
                -45.56120490466957,
                -23.791125427889963
              ],
              [
                -45.58593318417911,
                -23.7920339600106
              ],
              [
                -45.59067265553504,
                -23.794454995447218
              ],
              [
                -45.59403813499562,
                -23.796173467183475
              ],
              [
                -45.59676508100758,
                -23.797566471589384
              ],
              [
                -45.6057624892394,
                -23.79961002286591
              ],
              [
                -45.617707902040124,
                -23.80232202249474
              ],
              [
                -45.623122854634936,
                -23.803552015301786
              ],
              [
                -45.626127342071115,
                -23.799652060605737
              ],
              [
                -45.62588932222476,
                -23.79664055763987
              ],
              [
                -45.6251878731072,
                -23.78774310116319
              ],
              [
                -45.635998285277445,
                -23.783122160952328
              ],
              [
                -45.641653738840105,
                -23.784650147079425
              ],
              [
                -45.642636737696115,
                -23.777726699402603
              ],
              [
                -45.646463218440665,
                -23.778196181146438
              ],
              [
                -45.65738063872638,
                -23.779532226116196
              ],
              [
                -45.65746511396987,
                -23.779542703308785
              ],
              [
                -45.66453804318936,
                -23.785313713166605
              ],
              [
                -45.66431505050311,
                -23.77894173153156
              ],
              [
                -45.66429807230586,
                -23.778448251729362
              ],
              [
                -45.667572042153964,
                -23.776424269256044
              ],
              [
                -45.670874034468795,
                -23.774488284461665
              ],
              [
                -45.67755446445675,
                -23.774782306129893
              ],
              [
                -45.6832054313896,
                -23.77503080407873
              ],
              [
                -45.686035366308026,
                -23.77709282875059
              ],
              [
                -45.68691489764922,
                -23.777733313339994
              ],
              [
                -45.68984684142769,
                -23.7798753049416
              ],
              [
                -45.69282029693529,
                -23.78199231777758
              ],
              [
                -45.695078314006246,
                -23.777726311607882
              ],
              [
                -45.69613229989393,
                -23.775678821920877
              ],
              [
                -45.69770578273915,
                -23.772622377302117
              ],
              [
                -45.70329326531891,
                -23.77373686213712
              ],
              [
                -45.71082670760455,
                -23.77178590445491
              ],
              [
                -45.71301318059927,
                -23.772485902496474
              ],
              [
                -45.71715661379636,
                -23.773811887264646
              ],
              [
                -45.72598908789308,
                -23.770410943875696
              ],
              [
                -45.73301449107562,
                -23.767704974181253
              ],
              [
                -45.74170093335887,
                -23.768492004341812
              ],
              [
                -45.75570982365387,
                -23.769761509479192
              ],
              [
                -45.76129027325773,
                -23.767331536416513
              ],
              [
                -45.761425257304744,
                -23.766083533470155
              ],
              [
                -45.76173929139766,
                -23.76317858556138
              ],
              [
                -45.7682072192241,
                -23.762003088326814
              ],
              [
                -45.77816966221001,
                -23.764118089106507
              ],
              [
                -45.78211159880576,
                -23.76495460054187
              ],
              [
                -45.78340258788338,
                -23.765228636977003
              ],
              [
                -45.79044903481107,
                -23.76672461727037
              ],
              [
                -45.796143957223464,
                -23.7679336460181
              ],
              [
                -45.80239791569176,
                -23.76919762711415
              ],
              [
                -45.80216842354495,
                -23.76447465989213
              ],
              [
                -45.802144943372284,
                -23.763991164019433
              ],
              [
                -45.802019427093775,
                -23.761420178330088
              ],
              [
                -45.81031541068873,
                -23.75748120085639
              ],
              [
                -45.82061079489708,
                -23.757515756051298
              ],
              [
                -45.82151876915374,
                -23.757519240624372
              ],
              [
                -45.844005107448034,
                -23.75759781039708
              ],
              [
                -45.86835988677568,
                -23.76068484589719
              ],
              [
                -45.87802480446781,
                -23.761893859008598
              ],
              [
                -45.88490623702062,
                -23.76275539975355
              ],
              [
                -45.89172914610549,
                -23.76360841537398
              ],
              [
                -45.89181868030513,
                -23.763619908577677
              ],
              [
                -45.90268107633325,
                -23.764978918450115
              ],
              [
                -45.97795437424755,
                -23.78696149734341
              ],
              [
                -45.988650740138226,
                -23.791171523970693
              ],
              [
                -45.99066171682113,
                -23.79390100241332
              ],
              [
                -45.99153870734141,
                -23.795092008551425
              ],
              [
                -45.99687566275551,
                -23.80225701351969
              ],
              [
                -46.00009312326127,
                -23.8011240058514
              ],
              [
                -46.003346624636805,
                -23.79993952751854
              ],
              [
                -46.00654156943686,
                -23.798777039918587
              ],
              [
                -46.019730470697404,
                -23.804014563113043
              ],
              [
                -46.03173533561465,
                -23.808781542060284
              ],
              [
                -46.0352318163919,
                -23.812185551755665
              ],
              [
                -46.03811974378806,
                -23.815160564641893
              ],
              [
                -46.037840269092115,
                -23.81636055443523
              ],
              [
                -46.03677624664903,
                -23.8209244918274
              ],
              [
                -46.03874672589345,
                -23.823585520501172
              ],
              [
                -46.044075157570916,
                -23.8303889861723
              ],
              [
                -46.05014261837791,
                -23.829117000322213
              ],
              [
                -46.04398819715403,
                -23.82454051224933
              ],
              [
                -46.04797215401833,
                -23.819622060830223
              ],
              [
                -46.048899183032866,
                -23.818477058646593
              ],
              [
                -46.05126317286492,
                -23.81555859757258
              ],
              [
                -46.059542104166724,
                -23.8130321157126
              ],
              [
                -46.07544992849626,
                -23.81647513189261
              ],
              [
                -46.08448586154362,
                -23.820390653232444
              ],
              [
                -46.093521762593134,
                -23.824306659584753
              ],
              [
                -46.11832105574334,
                -23.835053165588974
              ],
              [
                -46.13004489416047,
                -23.845100139205822
              ],
              [
                -46.133267325755725,
                -23.85687710262771
              ],
              [
                -46.13848680703307,
                -23.85554513863477
              ],
              [
                -46.14286671841574,
                -23.86009159885451
              ],
              [
                -46.137723291145036,
                -23.858882115464983
              ]
            ]
          ],
          [
            [
              [
                -45.32093461552112,
                -23.914938258041378
              ],
              [
                -45.323803070773934,
                -23.911696236005447
              ],
              [
                -45.33184649049958,
                -23.915910755604454
              ],
              [
                -45.33406695384088,
                -23.918462756258187
              ],
              [
                -45.337889446219606,
                -23.92285723805478
              ],
              [
                -45.33974288508157,
                -23.925001758532243
              ],
              [
                -45.342712414473674,
                -23.922059268410504
              ],
              [
                -45.34337089201285,
                -23.921406779261897
              ],
              [
                -45.34785336244725,
                -23.91696730027663
              ],
              [
                -45.35016531721615,
                -23.91945628048536
              ],
              [
                -45.35555875335244,
                -23.92526277531761
              ],
              [
                -45.35824221258168,
                -23.928151277768364
              ],
              [
                -45.36184872211035,
                -23.928596284997717
              ],
              [
                -45.36860864452598,
                -23.929348777797802
              ],
              [
                -45.37391508997236,
                -23.929939310204045
              ],
              [
                -45.37939055490537,
                -23.930549298004905
              ],
              [
                -45.38990294871297,
                -23.93171981859118
              ],
              [
                -45.395377889660466,
                -23.93232985139757
              ],
              [
                -45.39920385638654,
                -23.9327553793519
              ],
              [
                -45.400158850872515,
                -23.933442876937733
              ],
              [
                -45.40690329161216,
                -23.938299854012858
              ],
              [
                -45.42737359589124,
                -23.940393416801584
              ],
              [
                -45.43232255718745,
                -23.93839089702467
              ],
              [
                -45.43716102028513,
                -23.9364329187298
              ],
              [
                -45.443179479874225,
                -23.93399796496946
              ],
              [
                -45.44530699589778,
                -23.931545987196856
              ],
              [
                -45.45048945058693,
                -23.925576042148254
              ],
              [
                -45.45551794899546,
                -23.919784584848205
              ],
              [
                -45.45968242246236,
                -23.914987081560145
              ],
              [
                -45.46013643612859,
                -23.908887615632963
              ],
              [
                -45.46112746745469,
                -23.895582706069526
              ],
              [
                -45.46165698826513,
                -23.887756729242195
              ],
              [
                -45.456924531569044,
                -23.88367522938079
              ],
              [
                -45.45136108109228,
                -23.88372572632203
              ],
              [
                -45.44809660960838,
                -23.879704239374277
              ],
              [
                -45.44745461277311,
                -23.878913212155886
              ],
              [
                -45.446035121822014,
                -23.877165222091406
              ],
              [
                -45.44501314681777,
                -23.875906219927877
              ],
              [
                -45.44162617481804,
                -23.871753761240996
              ],
              [
                -45.437017741849345,
                -23.86981424298995
              ],
              [
                -45.43407925820016,
                -23.8685777572803
              ],
              [
                -45.43323728761995,
                -23.86673274530795
              ],
              [
                -45.432608286650364,
                -23.865352760819132
              ],
              [
                -45.430054322338826,
                -23.859759760094565
              ],
              [
                -45.4240193899031,
                -23.859218745420325
              ],
              [
                -45.417856446609875,
                -23.858665734440066
              ],
              [
                -45.41457147467511,
                -23.852442267538976
              ],
              [
                -45.413876509642876,
                -23.850950255382177
              ],
              [
                -45.41184201574329,
                -23.846583796557752
              ],
              [
                -45.409641066607215,
                -23.84479879746784
              ],
              [
                -45.407685581692995,
                -23.84321430115971
              ],
              [
                -45.39282873079726,
                -23.831379314125876
              ],
              [
                -45.39007224679965,
                -23.829183310354917
              ],
              [
                -45.38551980400213,
                -23.825555830044863
              ],
              [
                -45.379990891186296,
                -23.821149817537567
              ],
              [
                -45.37657740357588,
                -23.818429841315204
              ],
              [
                -45.370597985823764,
                -23.813664322194334
              ],
              [
                -45.365933518284315,
                -23.809646835382598
              ],
              [
                -45.364693080779034,
                -23.800721379758937
              ],
              [
                -45.364233094777084,
                -23.797415886603005
              ],
              [
                -45.36264014212926,
                -23.786139417779257
              ],
              [
                -45.35843268300438,
                -23.784408919810513
              ],
              [
                -45.35837917636128,
                -23.779948458111953
              ],
              [
                -45.3583521769879,
                -23.77773396119096
              ],
              [
                -45.35550719871721,
                -23.77524097757996
              ],
              [
                -45.353137761975695,
                -23.773164483405704
              ],
              [
                -45.351711275895845,
                -23.77026347968179
              ],
              [
                -45.34881178928382,
                -23.764115489697172
              ],
              [
                -45.35004783058119,
                -23.75934854406742
              ],
              [
                -45.35059181890848,
                -23.757251531229333
              ],
              [
                -45.348566375826586,
                -23.74779008894398
              ],
              [
                -45.3477234021912,
                -23.74384908766064
              ],
              [
                -45.34662440771957,
                -23.738716094132602
              ],
              [
                -45.34100099679598,
                -23.727051660854027
              ],
              [
                -45.33506504183044,
                -23.724913159937323
              ],
              [
                -45.328824601409515,
                -23.720992155362943
              ],
              [
                -45.31586971058132,
                -23.7236901164271
              ],
              [
                -45.311022735046095,
                -23.727636059613456
              ],
              [
                -45.30699676369505,
                -23.730912544192986
              ],
              [
                -45.30698678775063,
                -23.73092156712339
              ],
              [
                -45.299190844556804,
                -23.731854537850342
              ],
              [
                -45.29753487011204,
                -23.732052500270786
              ],
              [
                -45.292652875075284,
                -23.732636519820243
              ],
              [
                -45.29041641044354,
                -23.735591478921865
              ],
              [
                -45.29001641211736,
                -23.736120492360605
              ],
              [
                -45.289306397400736,
                -23.736063972618723
              ],
              [
                -45.27518155340012,
                -23.73494344795633
              ],
              [
                -45.271699042329594,
                -23.73794242833998
              ],
              [
                -45.26606307283013,
                -23.742722375457724
              ],
              [
                -45.26116010765697,
                -23.74250686962923
              ],
              [
                -45.25687367855909,
                -23.747591828145538
              ],
              [
                -45.2551301814924,
                -23.750009348105603
              ],
              [
                -45.25721761810444,
                -23.75555528747313
              ],
              [
                -45.25822159307408,
                -23.758681305923567
              ],
              [
                -45.25471362573698,
                -23.760325288467705
              ],
              [
                -45.25438362905607,
                -23.760480269824058
              ],
              [
                -45.24919168718227,
                -23.762426759227214
              ],
              [
                -45.245421210213884,
                -23.76133473061871
              ],
              [
                -45.233871759809645,
                -23.77594717381896
              ],
              [
                -45.22959330244115,
                -23.776290127173
              ],
              [
                -45.23205074930684,
                -23.779596123896752
              ],
              [
                -45.23415074384195,
                -23.782422141823307
              ],
              [
                -45.23489721671972,
                -23.791035601321067
              ],
              [
                -45.23520170712549,
                -23.794928055285077
              ],
              [
                -45.2354452079701,
                -23.797046044236147
              ],
              [
                -45.23573419412542,
                -23.799553046528303
              ],
              [
                -45.239844118187065,
                -23.805431520118812
              ],
              [
                -45.241875584027994,
                -23.808337534504613
              ],
              [
                -45.241687087254014,
                -23.81145199666373
              ],
              [
                -45.23750610852305,
                -23.81075452605662
              ],
              [
                -45.23836510321814,
                -23.81642297044241
              ],
              [
                -45.23867158583108,
                -23.81844546301833
              ],
              [
                -45.238846575967735,
                -23.818552975018992
              ],
              [
                -45.24454805623635,
                -23.82206195201705
              ],
              [
                -45.248183507269204,
                -23.8243719876868
              ],
              [
                -45.244017540360176,
                -23.82758143217395
              ],
              [
                -45.24459651914908,
                -23.831242930001196
              ],
              [
                -45.24168852554896,
                -23.83112343932747
              ],
              [
                -45.23829305420817,
                -23.830983893368188
              ],
              [
                -45.24334051708493,
                -23.83598941866144
              ],
              [
                -45.24116801832214,
                -23.838514902925485
              ],
              [
                -45.23701757091584,
                -23.83734586355417
              ],
              [
                -45.232402098334816,
                -23.835929877004084
              ],
              [
                -45.22785865290013,
                -23.838175862845755
              ],
              [
                -45.238373009876625,
                -23.843144863769965
              ],
              [
                -45.24731195194736,
                -23.839577391426754
              ],
              [
                -45.250772444898246,
                -23.838171408667364
              ],
              [
                -45.25135893759695,
                -23.837949890342877
              ],
              [
                -45.25563189727312,
                -23.836332420852084
              ],
              [
                -45.26418433242,
                -23.837331934461417
              ],
              [
                -45.26872827458273,
                -23.83786547287101
              ],
              [
                -45.27216873166922,
                -23.83826796776054
              ],
              [
                -45.275734208451695,
                -23.841429941350448
              ],
              [
                -45.27864718345915,
                -23.844107951316712
              ],
              [
                -45.2850065971307,
                -23.84998845636599
              ],
              [
                -45.28533009065478,
                -23.85437792816543
              ],
              [
                -45.285524568313065,
                -23.857862932746908
              ],
              [
                -45.28574756297217,
                -23.861870896015215
              ],
              [
                -45.28974251217848,
                -23.86588937292715
              ],
              [
                -45.287772993683475,
                -23.8722808590721
              ],
              [
                -45.27188766497075,
                -23.874145304509927
              ],
              [
                -45.268924171280005,
                -23.87449330951766
              ],
              [
                -45.26875416269009,
                -23.878984286462494
              ],
              [
                -45.27053264025988,
                -23.879921282211292
              ],
              [
                -45.27692354702712,
                -23.8832897719013
              ],
              [
                -45.27184757803553,
                -23.891527703788434
              ],
              [
                -45.267796136762314,
                -23.89126119652015
              ],
              [
                -45.26151666150766,
                -23.891633195011902
              ],
              [
                -45.25144672981193,
                -23.898578129654794
              ],
              [
                -45.247532756391735,
                -23.901286129154965
              ],
              [
                -45.24522730470961,
                -23.89696664040251
              ],
              [
                -45.24512380725212,
                -23.896772141000028
              ],
              [
                -45.24257133040601,
                -23.891642661264097
              ],
              [
                -45.235122372223955,
                -23.898877596003434
              ],
              [
                -45.22912442037659,
                -23.89752009722323
              ],
              [
                -45.22443545111303,
                -23.90247303423819
              ],
              [
                -45.22607642240413,
                -23.906525051324603
              ],
              [
                -45.23597086775312,
                -23.90548806912688
              ],
              [
                -45.237134866114744,
                -23.906866542113672
              ],
              [
                -45.23812231569937,
                -23.908035033562207
              ],
              [
                -45.23769131107704,
                -23.923922980595574
              ],
              [
                -45.23585877594762,
                -23.925678967357893
              ],
              [
                -45.23274431972837,
                -23.92866195286617
              ],
              [
                -45.235431770499744,
                -23.931967423364625
              ],
              [
                -45.2367877832292,
                -23.933634941582593
              ],
              [
                -45.235744221856024,
                -23.94177440937815
              ],
              [
                -45.235732261632904,
                -23.941871409900457
              ],
              [
                -45.22676931139352,
                -23.9407928693888
              ],
              [
                -45.23413474147636,
                -23.947197384
              ],
              [
                -45.23660721372403,
                -23.953880353457237
              ],
              [
                -45.23809467240957,
                -23.957897332315145
              ],
              [
                -45.25046803841764,
                -23.96547734183334
              ],
              [
                -45.254863524305655,
                -23.964969341650647
              ],
              [
                -45.26231793262588,
                -23.96394238253701
              ],
              [
                -45.267292415136474,
                -23.963257396810864
              ],
              [
                -45.270255885561326,
                -23.95971789640655
              ],
              [
                -45.27565088484427,
                -23.95327545617753
              ],
              [
                -45.27771936236754,
                -23.950805471342683
              ],
              [
                -45.27997682502618,
                -23.948109484077655
              ],
              [
                -45.28542083647792,
                -23.94149204278796
              ],
              [
                -45.28323487012933,
                -23.936779044113788
              ],
              [
                -45.28316536723317,
                -23.936628538604158
              ],
              [
                -45.2819563663886,
                -23.934024052073276
              ],
              [
                -45.27957940268999,
                -23.928711586606756
              ],
              [
                -45.28391937899276,
                -23.921089604782853
              ],
              [
                -45.28564489023721,
                -23.91809012839867
              ],
              [
                -45.29018285573114,
                -23.910200685363588
              ],
              [
                -45.296296822775574,
                -23.91240368564776
              ],
              [
                -45.30173977669545,
                -23.91457517717612
              ],
              [
                -45.303998247269206,
                -23.90862772778136
              ],
              [
                -45.309169692432455,
                -23.911125727046695
              ],
              [
                -45.31334114646687,
                -23.91354124185377
              ],
              [
                -45.31710611190077,
                -23.91449721218001
              ],
              [
                -45.32093461552112,
                -23.914938258041378
              ]
            ]
          ],
          [
            [
              [
                -45.07784783323138,
                -23.535285851021477
              ],
              [
                -45.07211388852922,
                -23.538342360825386
              ],
              [
                -45.06481744135374,
                -23.53801681401878
              ],
              [
                -45.06127545341043,
                -23.532952322733646
              ],
              [
                -45.059144030749934,
                -23.526231852095574
              ],
              [
                -45.043699612864756,
                -23.532570291587223
              ],
              [
                -45.039396174956046,
                -23.534383785162447
              ],
              [
                -45.04199863444674,
                -23.541442736528694
              ],
              [
                -45.04677705776898,
                -23.542835256348816
              ],
              [
                -45.05726195480715,
                -23.54581175869186
              ],
              [
                -45.06981534291671,
                -23.556151271558676
              ],
              [
                -45.07557678153392,
                -23.56097476418583
              ],
              [
                -45.07919773899976,
                -23.563835751979163
              ],
              [
                -45.08062172402431,
                -23.558267269841945
              ],
              [
                -45.081898720032704,
                -23.55308780348875
              ],
              [
                -45.078276288745435,
                -23.545598823227962
              ],
              [
                -45.08148728336296,
                -23.540952341792526
              ],
              [
                -45.0835312587512,
                -23.53760687905288
              ],
              [
                -45.080148810300855,
                -23.533498362749235
              ],
              [
                -45.08031430991386,
                -23.529824421309357
              ],
              [
                -45.0805018067558,
                -23.525411424372958
              ],
              [
                -45.07667783755775,
                -23.530015891291278
              ],
              [
                -45.07784783323138,
                -23.535285851021477
              ]
            ]
          ],
          [
            [
              [
                -45.139774507574764,
                -23.799865795397814
              ],
              [
                -45.11491617830741,
                -23.80717071468845
              ],
              [
                -45.118344131481344,
                -23.81447018371334
              ],
              [
                -45.11968110761298,
                -23.81727867654356
              ],
              [
                -45.13229550774763,
                -23.812545211908944
              ],
              [
                -45.146153401487545,
                -23.82093174737301
              ],
              [
                -45.15639031914855,
                -23.81289478324452
              ],
              [
                -45.15834784200873,
                -23.805178345289296
              ],
              [
                -45.148576457513364,
                -23.79454633970975
              ],
              [
                -45.13833304892801,
                -23.79267135748564
              ],
              [
                -45.13915150228307,
                -23.796757825961578
              ],
              [
                -45.139774507574764,
                -23.799865795397814
              ]
            ]
          ],
          [
            [
              [
                -45.163376497965,
                -23.566709431583934
              ],
              [
                -45.15631309910101,
                -23.559268941384175
              ],
              [
                -45.15165710548646,
                -23.563107908563968
              ],
              [
                -45.147614128273396,
                -23.56643988580824
              ],
              [
                -45.145799148573836,
                -23.570180390378738
              ],
              [
                -45.15005811308003,
                -23.56954090824216
              ],
              [
                -45.1526770773021,
                -23.568407904815196
              ],
              [
                -45.15762003234783,
                -23.57281338618247
              ],
              [
                -45.161130000306976,
                -23.569129427080263
              ],
              [
                -45.163376497965,
                -23.566709431583934
              ]
            ]
          ],
          [
            [
              [
                -45.01966018815581,
                -23.758692189656696
              ],
              [
                -45.01859517608594,
                -23.754240705264568
              ],
              [
                -45.00929129004479,
                -23.752206675571202
              ],
              [
                -45.008732252026135,
                -23.75940416069101
              ],
              [
                -45.011856210182835,
                -23.76209164702298
              ],
              [
                -45.0159126724945,
                -23.765323132459834
              ],
              [
                -45.020337633532755,
                -23.762851162317986
              ],
              [
                -45.01966018815581,
                -23.758692189656696
              ]
            ]
          ],
          [
            [
              [
                -45.77901180687681,
                -23.867610633361938
              ],
              [
                -45.781749797724665,
                -23.870507640088093
              ],
              [
                -45.786864259011054,
                -23.867286640298033
              ],
              [
                -45.78736726062652,
                -23.86199466868282
              ],
              [
                -45.7846008004696,
                -23.858371688164674
              ],
              [
                -45.77883733839403,
                -23.857254186737293
              ],
              [
                -45.77549935210173,
                -23.858661160820823
              ],
              [
                -45.77901180687681,
                -23.867610633361938
              ]
            ]
          ],
          [
            [
              [
                -45.29357626866086,
                -23.596097152920727
              ],
              [
                -45.292306786960665,
                -23.59324565853786
              ],
              [
                -45.28596487666609,
                -23.5931486532067
              ],
              [
                -45.28083042495818,
                -23.593070609174735
              ],
              [
                -45.28281539047375,
                -23.60108608469346
              ],
              [
                -45.288561339169384,
                -23.600790617171306
              ],
              [
                -45.295269790113714,
                -23.60004160444547
              ],
              [
                -45.29357626866086,
                -23.596097152920727
              ]
            ]
          ],
          [
            [
              [
                -45.01859517608594,
                -23.754240705264568
              ],
              [
                -45.024285623678054,
                -23.7519307189233
              ],
              [
                -45.022745176669794,
                -23.743562783326276
              ],
              [
                -45.01843973038309,
                -23.743986741285607
              ],
              [
                -45.01463074213278,
                -23.74673224889135
              ],
              [
                -45.016814193821894,
                -23.750804733167694
              ],
              [
                -45.01859517608594,
                -23.754240705264568
              ]
            ]
          ],
          [
            [
              [
                -45.72593397766197,
                -23.800637313380232
              ],
              [
                -45.71912255022598,
                -23.797829283718126
              ],
              [
                -45.71454755591747,
                -23.80298025927981
              ],
              [
                -45.71744904220946,
                -23.804153766220793
              ],
              [
                -45.724011495263376,
                -23.80295329538174
              ],
              [
                -45.72593397766197,
                -23.800637313380232
              ]
            ]
          ],
          [
            [
              [
                -45.5260330281059,
                -23.849328061557657
              ],
              [
                -45.52275208352186,
                -23.845662094929093
              ],
              [
                -45.520239072057755,
                -23.848629545256628
              ],
              [
                -45.52220553974066,
                -23.854421545459356
              ],
              [
                -45.525206518397155,
                -23.854020543826113
              ],
              [
                -45.5260330281059,
                -23.849328061557657
              ]
            ]
          ],
          [
            [
              [
                -46.90665161563684,
                -24.389684316739384
              ],
              [
                -46.90312314097171,
                -24.3872483287359
              ],
              [
                -46.90177715768929,
                -24.394165799843343
              ],
              [
                -46.90339561932679,
                -24.396625772048903
              ],
              [
                -46.90626010311172,
                -24.394412282153798
              ],
              [
                -46.90665161563684,
                -24.389684316739384
              ]
            ]
          ],
          [
            [
              [
                -46.909408639813726,
                -24.373831371443778
              ],
              [
                -46.90453668377733,
                -24.373831391918845
              ],
              [
                -46.90445916774075,
                -24.377401859259187
              ],
              [
                -46.90858960389703,
                -24.380528363067736
              ],
              [
                -46.91065112151922,
                -24.37744638452969
              ],
              [
                -46.909408639813726,
                -24.373831371443778
              ]
            ]
          ],
          [
            [
              [
                -45.03331815033668,
                -23.54871171150943
              ],
              [
                -45.02801072591977,
                -23.545077717658263
              ],
              [
                -45.0246542504691,
                -23.543960211520975
              ],
              [
                -45.02582271806608,
                -23.5492561931048
              ],
              [
                -45.03331815033668,
                -23.54871171150943
              ]
            ]
          ],
          [
            [
              [
                -44.851378690481134,
                -23.39917742589579
              ],
              [
                -44.853381156089256,
                -23.40323244236342
              ],
              [
                -44.85504263255293,
                -23.398047946308115
              ],
              [
                -44.84942919100344,
                -23.394939972436244
              ],
              [
                -44.851378690481134,
                -23.39917742589579
              ]
            ]
          ],
          [
            [
              [
                -44.94853736157143,
                -23.386209728910586
              ],
              [
                -44.94504590178693,
                -23.38338225053344
              ],
              [
                -44.94053744615533,
                -23.383517721449827
              ],
              [
                -44.944500402620925,
                -23.3873507094099
              ],
              [
                -44.94853736157143,
                -23.386209728910586
              ]
            ]
          ],
          [
            [
              [
                -46.979876050903506,
                -24.37095957406239
              ],
              [
                -46.97899558819564,
                -24.36789608864877
              ],
              [
                -46.9748821086049,
                -24.36962108093019
              ],
              [
                -46.97701607822012,
                -24.372281056825397
              ],
              [
                -46.979876050903506,
                -24.37095957406239
              ]
            ]
          ],
          [
            [
              [
                -47.91350557566232,
                -25.166847307408514
              ],
              [
                -47.911410589933666,
                -25.16291884090824
              ],
              [
                -47.9084080846983,
                -25.166948301047338
              ],
              [
                -47.91350557566232,
                -25.166847307408514
              ]
            ]
          ],
          [
            [
              [
                -45.22860717283195,
                -23.813210983632107
              ],
              [
                -45.2252422452042,
                -23.813678451979378
              ],
              [
                -45.22822518018204,
                -23.81708644174582
              ],
              [
                -45.22860717283195,
                -23.813210983632107
              ]
            ]
          ],
          [
            [
              [
                -45.325171567860295,
                -23.915906754654703
              ],
              [
                -45.32093461552112,
                -23.914938258041378
              ],
              [
                -45.32299158871376,
                -23.917357214833327
              ],
              [
                -45.324072048162904,
                -23.918626213105878
              ],
              [
                -45.324568583027656,
                -23.917397733129352
              ],
              [
                -45.325171567860295,
                -23.915906754654703
              ]
            ]
          ],
          [
            [
              [
                -45.71404262330769,
                -23.790180837992608
              ],
              [
                -45.71256414074067,
                -23.787262351044017
              ],
              [
                -45.7090836432438,
                -23.78821132881642
              ],
              [
                -45.71404262330769,
                -23.790180837992608
              ]
            ]
          ],
          [
            [
              [
                -45.27682264399015,
                -23.853115913855465
              ],
              [
                -45.278634649287596,
                -23.85494689659469
              ],
              [
                -45.27975812121917,
                -23.852083437900717
              ],
              [
                -45.2770056460285,
                -23.85233591894904
              ],
              [
                -45.276129168293394,
                -23.852415890100495
              ],
              [
                -45.27682264399015,
                -23.853115913855465
              ]
            ]
          ],
          [
            [
              [
                -45.29718927160935,
                -23.92058513977718
              ],
              [
                -45.29700281837247,
                -23.91696715178745
              ],
              [
                -45.294480800040674,
                -23.918755660447967
              ],
              [
                -45.29718927160935,
                -23.92058513977718
              ]
            ]
          ],
          [
            [
              [
                -45.15635626683216,
                -23.83077420886965
              ],
              [
                -45.153855317523295,
                -23.828627199688963
              ],
              [
                -45.15307777021135,
                -23.831627207691604
              ],
              [
                -45.15635626683216,
                -23.83077420886965
              ]
            ]
          ],
          [
            [
              [
                -45.67172541371207,
                -23.804676625552563
              ],
              [
                -45.67032694564715,
                -23.802003164726166
              ],
              [
                -45.66882945654597,
                -23.80494213109689
              ],
              [
                -45.67172541371207,
                -23.804676625552563
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 27,
        "nome": "Tocantins",
        "sigla": "TO",
        "regiao_id": 3,
        "codigo_ibg": 17
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -47.03295839935254,
                -8.982324800928529
              ],
              [
                -47.03102090672557,
                -8.985490274307995
              ],
              [
                -47.03844385598232,
                -9.000555724528766
              ],
              [
                -47.0436038092518,
                -9.006466176267082
              ],
              [
                -47.04806627347565,
                -9.006670683015503
              ],
              [
                -47.06165814856996,
                -9.0072926994421
              ],
              [
                -47.06807161796529,
                -9.011621683826665
              ],
              [
                -47.05604968111862,
                -9.018470124019233
              ],
              [
                -47.05247820979297,
                -9.017572600743382
              ],
              [
                -47.048218733992975,
                -9.016502104801598
              ],
              [
                -47.04832873749885,
                -9.021406076313534
              ],
              [
                -47.04357727000676,
                -9.02251105778599
              ],
              [
                -47.043052767878784,
                -9.026149057737339
              ],
              [
                -47.05402218794343,
                -9.033983499732942
              ],
              [
                -47.051607705391426,
                -9.03852847269564
              ],
              [
                -47.05382265758932,
                -9.047764435715527
              ],
              [
                -47.05888263344979,
                -9.047743423623665
              ],
              [
                -47.06129962375457,
                -9.047733931442437
              ],
              [
                -47.05937264698901,
                -9.0500539043003
              ],
              [
                -47.057296151173325,
                -9.052553386830896
              ],
              [
                -47.05742716211425,
                -9.052592381729292
              ],
              [
                -47.06756054119816,
                -9.05561339084362
              ],
              [
                -47.06830254236416,
                -9.063475340069632
              ],
              [
                -47.047990204981666,
                -9.07090874698144
              ],
              [
                -47.04524370348279,
                -9.068110250864068
              ],
              [
                -47.04016875827768,
                -9.068538759302717
              ],
              [
                -47.03394282287549,
                -9.069064769797585
              ],
              [
                -47.0285033501631,
                -9.072377720237101
              ],
              [
                -47.02313588694646,
                -9.075647215036431
              ],
              [
                -47.01903990470927,
                -9.073885205704892
              ],
              [
                -47.0097734890976,
                -9.069898216805374
              ],
              [
                -47.012011976849834,
                -9.07500470545607
              ],
              [
                -47.01318646765181,
                -9.077683198539962
              ],
              [
                -47.00691201049691,
                -9.081794137873887
              ],
              [
                -46.999994540917704,
                -9.081078631571362
              ],
              [
                -46.99865708084906,
                -9.07713168547967
              ],
              [
                -46.99827156115295,
                -9.075993660373864
              ],
              [
                -46.986284664964515,
                -9.076055162275882
              ],
              [
                -46.98625817315416,
                -9.076068680158457
              ],
              [
                -46.97549723746599,
                -9.08132962293846
              ],
              [
                -46.94257403166212,
                -9.064071686669509
              ],
              [
                -46.92223016187822,
                -9.066300158084053
              ],
              [
                -46.91172173889528,
                -9.082323554647614
              ],
              [
                -46.91854418479845,
                -9.086788524621845
              ],
              [
                -46.927433628745504,
                -9.08427406500773
              ],
              [
                -46.92941012006048,
                -9.083715079873453
              ],
              [
                -46.92788010513707,
                -9.092176511526029
              ],
              [
                -46.930162103860646,
                -9.095263486219745
              ],
              [
                -46.92347864125805,
                -9.09737797993039
              ],
              [
                -46.92312962083771,
                -9.09748846281819
              ],
              [
                -46.92344263897148,
                -9.097866463737468
              ],
              [
                -46.92982756749872,
                -9.105580896280598
              ],
              [
                -46.92576510848223,
                -9.117242847408152
              ],
              [
                -46.93105306980617,
                -9.120388825186025
              ],
              [
                -46.925878064500445,
                -9.134542234988874
              ],
              [
                -46.91724865576887,
                -9.131693223446353
              ],
              [
                -46.90630673673424,
                -9.135086197998609
              ],
              [
                -46.90051479956536,
                -9.129169222513946
              ],
              [
                -46.89406832225259,
                -9.122582748612261
              ],
              [
                -46.889883361477004,
                -9.126252216134912
              ],
              [
                -46.88989135118588,
                -9.130753205102387
              ],
              [
                -46.88990837070621,
                -9.140512666159935
              ],
              [
                -46.86920899404242,
                -9.14513960952504
              ],
              [
                -46.860272071125436,
                -9.147137578362713
              ],
              [
                -46.85199165440026,
                -9.154048506243925
              ],
              [
                -46.8473806514216,
                -9.16760841043412
              ],
              [
                -46.85142609921779,
                -9.183694336320517
              ],
              [
                -46.851939087848365,
                -9.185734799751515
              ],
              [
                -46.85110711498045,
                -9.188585309406855
              ],
              [
                -46.85027510411087,
                -9.191436784056418
              ],
              [
                -46.838858183099354,
                -9.197183725808886
              ],
              [
                -46.83840969573655,
                -9.197053731129396
              ],
              [
                -46.827383794150954,
                -9.193867247955307
              ],
              [
                -46.82235583464574,
                -9.198643683334383
              ],
              [
                -46.81918632257119,
                -9.211213622581468
              ],
              [
                -46.826438756467695,
                -9.222926526431644
              ],
              [
                -46.8284637250493,
                -9.239687460721004
              ],
              [
                -46.81851827287275,
                -9.259656279472196
              ],
              [
                -46.822329736855345,
                -9.265924776510309
              ],
              [
                -46.826225196347366,
                -9.266963282350416
              ],
              [
                -46.831322152122766,
                -9.268322770522165
              ],
              [
                -46.83230313867943,
                -9.279187194686314
              ],
              [
                -46.84421453668306,
                -9.283770186056172
              ],
              [
                -46.846635017117634,
                -9.292022101757231
              ],
              [
                -46.84313905994109,
                -9.300768577379488
              ],
              [
                -46.834884100764434,
                -9.307815490380529
              ],
              [
                -46.82505167756774,
                -9.304975501175019
              ],
              [
                -46.81832373917995,
                -9.30303200983127
              ],
              [
                -46.812490269565345,
                -9.305547007825181
              ],
              [
                -46.79874136050091,
                -9.338441252966236
              ],
              [
                -46.78290948412583,
                -9.35068516859381
              ],
              [
                -46.781356964476366,
                -9.353822164579439
              ],
              [
                -46.77924097485587,
                -9.358098123572548
              ],
              [
                -46.77392200934583,
                -9.361378592213468
              ],
              [
                -46.76788557389633,
                -9.365101578161024
              ],
              [
                -46.75824509822013,
                -9.380813961693654
              ],
              [
                -46.76541306236968,
                -9.401795321123439
              ],
              [
                -46.76293855455613,
                -9.408550285753458
              ],
              [
                -46.74560116703985,
                -9.41399170529655
              ],
              [
                -46.740607743766596,
                -9.411618245677975
              ],
              [
                -46.73754224115595,
                -9.404496270896827
              ],
              [
                -46.73752226336648,
                -9.404450293169194
              ],
              [
                -46.72288486451631,
                -9.395961812575065
              ],
              [
                -46.710008454794526,
                -9.396831817817656
              ],
              [
                -46.69713059310553,
                -9.397701762059867
              ],
              [
                -46.68145020537027,
                -9.392629807296379
              ],
              [
                -46.6744952892687,
                -9.390380294619282
              ],
              [
                -46.670020785475266,
                -9.390948792696038
              ],
              [
                -46.665546318684775,
                -9.391516776776045
              ],
              [
                -46.6575288708055,
                -9.39939872653856
              ],
              [
                -46.65115741902097,
                -9.405663668655919
              ],
              [
                -46.646053957037424,
                -9.40716214699025
              ],
              [
                -46.64152400478467,
                -9.408492156084657
              ],
              [
                -46.63235556409499,
                -9.411184632934061
              ],
              [
                -46.64332946490205,
                -9.419210094115774
              ],
              [
                -46.644610459143124,
                -9.421525065703756
              ],
              [
                -46.64749692939037,
                -9.42674202748375
              ],
              [
                -46.64913791830591,
                -9.443685937247716
              ],
              [
                -46.627163055805624,
                -9.465317772912384
              ],
              [
                -46.61971361122004,
                -9.465085748591417
              ],
              [
                -46.613027162657026,
                -9.46487774302005
              ],
              [
                -46.600053754718644,
                -9.470052201322913
              ],
              [
                -46.59762277084336,
                -9.474094683393153
              ],
              [
                -46.59280379583842,
                -9.482109632035073
              ],
              [
                -46.58092091702835,
                -9.479283653172905
              ],
              [
                -46.57282797954851,
                -9.477358643000446
              ],
              [
                -46.56054805494118,
                -9.483629094031746
              ],
              [
                -46.55877056305736,
                -9.492588526160702
              ],
              [
                -46.57230894630158,
                -9.505043450945449
              ],
              [
                -46.575355406128125,
                -9.507845947505515
              ],
              [
                -46.57530839689188,
                -9.51249438750149
              ],
              [
                -46.57526240664358,
                -9.51714387749461
              ],
              [
                -46.55761052546998,
                -9.533777272347322
              ],
              [
                -46.5568620394636,
                -9.53862221124724
              ],
              [
                -46.54870408468073,
                -9.54402716971338
              ],
              [
                -46.540053623358936,
                -9.549758628483438
              ],
              [
                -46.53739967587584,
                -9.55738306720514
              ],
              [
                -46.57887830984166,
                -9.583672937542962
              ],
              [
                -46.58122627981762,
                -9.584233437748336
              ],
              [
                -46.59113271255849,
                -9.586598446400016
              ],
              [
                -46.59132370222815,
                -9.593864392905806
              ],
              [
                -46.5913321886848,
                -9.599185348704438
              ],
              [
                -46.59134266170696,
                -9.60566782504222
              ],
              [
                -46.60569155723608,
                -9.629758204481302
              ],
              [
                -46.60643802582928,
                -9.65148052987731
              ],
              [
                -46.60953396211764,
                -9.658442507916577
              ],
              [
                -46.612630961390835,
                -9.665404950965879
              ],
              [
                -46.65290958693712,
                -9.69303883046807
              ],
              [
                -46.645193628637145,
                -9.716180184800374
              ],
              [
                -46.646990618603716,
                -9.729944579802513
              ],
              [
                -46.64235711603092,
                -9.74105949396907
              ],
              [
                -46.627082262399156,
                -9.7482594392431
              ],
              [
                -46.59570999423219,
                -9.74684440809606
              ],
              [
                -46.57571914515631,
                -9.757598836806533
              ],
              [
                -46.568914153835664,
                -9.769315749398704
              ],
              [
                -46.570380630252,
                -9.78069717824081
              ],
              [
                -46.572068110452925,
                -9.783602138686268
              ],
              [
                -46.579238064070076,
                -9.79594008840559
              ],
              [
                -46.57657858187298,
                -9.80477201258155
              ],
              [
                -46.5620501816088,
                -9.810281490501612
              ],
              [
                -46.54196385017175,
                -9.80990594431296
              ],
              [
                -46.51172557884365,
                -9.798577480952634
              ],
              [
                -46.493219712319075,
                -9.827015782938915
              ],
              [
                -46.49437816114068,
                -9.835929725207377
              ],
              [
                -46.495536662951004,
                -9.844843695488564
              ],
              [
                -46.490173201657655,
                -9.848277170897086
              ],
              [
                -46.47990879782803,
                -9.854847598175995
              ],
              [
                -46.48657070165553,
                -9.858853087687802
              ],
              [
                -46.48971317468755,
                -9.860742569441454
              ],
              [
                -46.491015661844536,
                -9.868454525627808
              ],
              [
                -46.4912196707363,
                -9.869662031142413
              ],
              [
                -46.48169723361892,
                -9.87340596443665
              ],
              [
                -46.472475326306515,
                -9.877031944851828
              ],
              [
                -46.47041279863556,
                -9.897641294265668
              ],
              [
                -46.472631762766405,
                -9.902014285992307
              ],
              [
                -46.47485124389018,
                -9.906387774721342
              ],
              [
                -46.478186191305795,
                -9.923462166517169
              ],
              [
                -46.47364973799697,
                -9.92810660217424
              ],
              [
                -46.46529380569046,
                -9.936660052078029
              ],
              [
                -46.474187718437086,
                -9.949014996024035
              ],
              [
                -46.47658117949099,
                -9.952340460714371
              ],
              [
                -46.47423217682171,
                -9.961901917629683
              ],
              [
                -46.47143672533986,
                -9.97328231736259
              ],
              [
                -46.47429715768547,
                -9.98058828119473
              ],
              [
                -46.477158159020206,
                -9.987893759042874
              ],
              [
                -46.45666028001216,
                -10.017565526814238
              ],
              [
                -46.45598575570509,
                -10.049472322084968
              ],
              [
                -46.45485326087497,
                -10.052381302084846
              ],
              [
                -46.45372175203563,
                -10.055290787083102
              ],
              [
                -46.443458312578024,
                -10.07716763249378
              ],
              [
                -46.43095892425316,
                -10.079784612095068
              ],
              [
                -46.41845999894505,
                -10.082401569693687
              ],
              [
                -46.38346824245484,
                -10.119958812795234
              ],
              [
                -46.367462817863654,
                -10.16840296225409
              ],
              [
                -46.35764539721768,
                -10.170535935225104
              ],
              [
                -46.347828935576075,
                -10.172669403191978
              ],
              [
                -46.32709709478613,
                -10.183283817188071
              ],
              [
                -46.32065815963413,
                -10.17843685800346
              ],
              [
                -46.31265720827522,
                -10.17241288637752
              ],
              [
                -46.301018317676736,
                -10.177918341489487
              ],
              [
                -46.272578015080256,
                -10.181205252539351
              ],
              [
                -46.240404794704304,
                -10.171477286139854
              ],
              [
                -46.21049553790104,
                -10.16930927229813
              ],
              [
                -46.201657603087135,
                -10.173511218250527
              ],
              [
                -46.19411913828018,
                -10.182201162098751
              ],
              [
                -46.16421583884302,
                -10.212491944372324
              ],
              [
                -46.135490068676084,
                -10.20594293000815
              ],
              [
                -46.099302372601095,
                -10.212489858334914
              ],
              [
                -46.050435278304676,
                -10.18126000909038
              ],
              [
                -46.0385838721836,
                -10.17632352188516
              ],
              [
                -46.0278539847226,
                -10.176460014518717
              ],
              [
                -46.02279702231135,
                -10.182970972333342
              ],
              [
                -46.01926947518394,
                -10.21956425189533
              ],
              [
                -46.01730146243776,
                -10.239980577368673
              ],
              [
                -46.003127579912025,
                -10.260517455649364
              ],
              [
                -45.99582463131209,
                -10.249904490511417
              ],
              [
                -45.97352481905622,
                -10.247959011794652
              ],
              [
                -45.945953038898466,
                -10.258155386435625
              ],
              [
                -45.93565511469375,
                -10.254003926082783
              ],
              [
                -45.92535768549344,
                -10.249852942742695
              ],
              [
                -45.877436070773264,
                -10.23892545952282
              ],
              [
                -45.84279683944587,
                -10.258843757634644
              ],
              [
                -45.79332471063631,
                -10.26726268896825
              ],
              [
                -45.74360563652391,
                -10.23954427798043
              ],
              [
                -45.73851869215962,
                -10.2338643412265
              ],
              [
                -45.73343322878056,
                -10.228184868483357
              ],
              [
                -45.72640182905542,
                -10.206692478395887
              ],
              [
                -45.7345787757382,
                -10.171755220278596
              ],
              [
                -45.726219397524645,
                -10.156444300781585
              ],
              [
                -45.722469905237816,
                -10.154895801114307
              ],
              [
                -45.71695994041489,
                -10.16397375656142
              ],
              [
                -45.71543297533095,
                -10.166489736393341
              ],
              [
                -45.70458402946178,
                -10.16645721706198
              ],
              [
                -45.69885907991835,
                -10.166440206027332
              ],
              [
                -45.705496014971125,
                -10.187865569831402
              ],
              [
                -45.69772102801192,
                -10.215034406567462
              ],
              [
                -45.697110489217714,
                -10.263154579123961
              ],
              [
                -45.705186433616696,
                -10.269761037673938
              ],
              [
                -45.707595878504485,
                -10.273639043695194
              ],
              [
                -45.714080836597475,
                -10.28407746955595
              ],
              [
                -45.72363172857526,
                -10.316809762652273
              ],
              [
                -45.738020557744974,
                -10.342031121466603
              ],
              [
                -45.74436450122266,
                -10.346798102840546
              ],
              [
                -45.750709445693545,
                -10.351564575230366
              ],
              [
                -45.792481616493674,
                -10.352222604140552
              ],
              [
                -45.82429835692894,
                -10.365556074976864
              ],
              [
                -45.82663781679594,
                -10.370621026423805
              ],
              [
                -45.82897781065473,
                -10.375686523874297
              ],
              [
                -45.8304372906654,
                -10.39365438936214
              ],
              [
                -45.82506079268124,
                -10.401775843346343
              ],
              [
                -45.81968533168718,
                -10.409896287337078
              ],
              [
                -45.827298727772984,
                -10.435544640510221
              ],
              [
                -45.830591233636504,
                -10.437861591875244
              ],
              [
                -45.83388371450038,
                -10.440178605242691
              ],
              [
                -45.84399609909334,
                -10.439611122872035
              ],
              [
                -45.86437897107629,
                -10.43846814638878
              ],
              [
                -45.87598285917046,
                -10.44378662421931
              ],
              [
                -45.8875872572712,
                -10.44910461307561
              ],
              [
                -45.90026365898669,
                -10.46043506283535
              ],
              [
                -45.90326510777395,
                -10.466853013614895
              ],
              [
                -45.90626757354716,
                -10.473271462402906
              ],
              [
                -45.961662084514636,
                -10.509293288688367
              ],
              [
                -45.970765005495274,
                -10.528161182984231
              ],
              [
                -45.98091893966584,
                -10.529292206950759
              ],
              [
                -45.98371189816059,
                -10.529602726596602
              ],
              [
                -45.98821286383824,
                -10.53240617254979
              ],
              [
                -45.993900787147005,
                -10.535949188330946
              ],
              [
                -46.00501470214784,
                -10.536029175271668
              ],
              [
                -46.01514613594115,
                -10.53610220298215
              ],
              [
                -46.019874107401215,
                -10.539233184161862
              ],
              [
                -46.0246030668535,
                -10.542364673345094
              ],
              [
                -46.02735151376157,
                -10.566678535918888
              ],
              [
                -46.059458263129365,
                -10.574078513690313
              ],
              [
                -46.059221236254146,
                -10.585123437046907
              ],
              [
                -46.05905124100401,
                -10.593049394362003
              ],
              [
                -46.06573464598507,
                -10.602920833279745
              ],
              [
                -46.076626071879325,
                -10.606693302547427
              ],
              [
                -46.09550243783025,
                -10.60440837082812
              ],
              [
                -46.11485875500908,
                -10.61855280929291
              ],
              [
                -46.1258826607458,
                -10.620211294232348
              ],
              [
                -46.1369065844964,
                -10.621869782179692
              ],
              [
                -46.15593890800287,
                -10.629525269582963
              ],
              [
                -46.16190987709337,
                -10.62490882674972
              ],
              [
                -46.16880683384418,
                -10.619575864680275
              ],
              [
                -46.187463689879095,
                -10.626120813674513
              ],
              [
                -46.18876464456031,
                -10.631367799090617
              ],
              [
                -46.210548448682,
                -10.649254717347377
              ],
              [
                -46.20895245211312,
                -10.655299156918643
              ],
              [
                -46.20735745653154,
                -10.661344118490428
              ],
              [
                -46.18764860261226,
                -10.68455297831709
              ],
              [
                -46.19213802812961,
                -10.719288265182003
              ],
              [
                -46.204259910884055,
                -10.725640220057445
              ],
              [
                -46.216382296643,
                -10.731992695958137
              ],
              [
                -46.25100351608668,
                -10.756660059034937
              ],
              [
                -46.24953501060766,
                -10.76183101938949
              ],
              [
                -46.23847011076283,
                -10.764084034384737
              ],
              [
                -46.22719269455134,
                -10.766379981822693
              ],
              [
                -46.22909314277436,
                -10.794034308553655
              ],
              [
                -46.23126360058481,
                -10.79920178880537
              ],
              [
                -46.23343360839599,
                -10.804368746066157
              ],
              [
                -46.27546324722461,
                -10.82320069751625
              ],
              [
                -46.28956057772973,
                -10.847486071287568
              ],
              [
                -46.292438065274936,
                -10.858937989969553
              ],
              [
                -46.29531552980191,
                -10.870389924676825
              ],
              [
                -46.284948066175154,
                -10.8930632758325
              ],
              [
                -46.283103091135565,
                -10.897097733924863
              ],
              [
                -46.28304908117002,
                -10.901715718763727
              ],
              [
                -46.28299456520422,
                -10.906334678598478
              ],
              [
                -46.30426988941474,
                -10.919404597114685
              ],
              [
                -46.31692577390459,
                -10.927179588346462
              ],
              [
                -46.32260973568827,
                -10.934692037802447
              ],
              [
                -46.32829414646256,
                -10.942204504277539
              ],
              [
                -46.360112874204006,
                -10.961348930232344
              ],
              [
                -46.37750621360675,
                -10.980445338029677
              ],
              [
                -46.388005630500594,
                -10.98721030390469
              ],
              [
                -46.39850653638533,
                -10.993976244801813
              ],
              [
                -46.403552976832685,
                -11.01170364653654
              ],
              [
                -46.40570845524685,
                -11.019275617020702
              ],
              [
                -46.41351139244711,
                -11.027391557849299
              ],
              [
                -46.421314328642126,
                -11.035508014700397
              ],
              [
                -46.44605854876869,
                -11.089550210488028
              ],
              [
                -46.45295042660332,
                -11.155774827168424
              ],
              [
                -46.47193219738922,
                -11.190765133179031
              ],
              [
                -46.48770958545387,
                -11.204645564611472
              ],
              [
                -46.49935499432149,
                -11.20643253771718
              ],
              [
                -46.49988146706594,
                -11.206513551946847
              ],
              [
                -46.581634292462105,
                -11.246351387325067
              ],
              [
                -46.59657014403734,
                -11.251310389452867
              ],
              [
                -46.609202036822424,
                -11.25550439112508
              ],
              [
                -46.599225637456975,
                -11.265001801113133
              ],
              [
                -46.608729022981386,
                -11.284302687612394
              ],
              [
                -46.616667928819865,
                -11.289032165431156
              ],
              [
                -46.615517456408014,
                -11.301781591685337
              ],
              [
                -46.609496965821286,
                -11.307897571243334
              ],
              [
                -46.60495498389801,
                -11.32141546395882
              ],
              [
                -46.60124799421164,
                -11.346766291476332
              ],
              [
                -46.59356405054271,
                -11.356411240790328
              ],
              [
                -46.5858805808682,
                -11.366056672097477
              ],
              [
                -46.56182525972896,
                -11.374412593186102
              ],
              [
                -46.55535283731637,
                -11.377356056754046
              ],
              [
                -46.548880360909365,
                -11.380299532319398
              ],
              [
                -46.54845981885258,
                -11.407978375073412
              ],
              [
                -46.52774648812638,
                -11.42208624515467
              ],
              [
                -46.5262859710818,
                -11.42723870713106
              ],
              [
                -46.5248259890308,
                -11.432390685112923
              ],
              [
                -46.52527597768555,
                -11.441824111278784
              ],
              [
                -46.5264119368924,
                -11.465604979542691
              ],
              [
                -46.52302344568704,
                -11.474366921428205
              ],
              [
                -46.51963496447357,
                -11.483129353313812
              ],
              [
                -46.50182358414539,
                -11.499104220898612
              ],
              [
                -46.49560211692631,
                -11.501271231572945
              ],
              [
                -46.48584919570234,
                -11.504669200565703
              ],
              [
                -46.484365718838404,
                -11.507076670528873
              ],
              [
                -46.47888572088876,
                -11.515971079586885
              ],
              [
                -46.468048801026335,
                -11.518143581604692
              ],
              [
                -46.459589401785436,
                -11.514657590087342
              ],
              [
                -46.45788640623183,
                -11.513956084995964
              ],
              [
                -46.445105484498896,
                -11.514237076957176
              ],
              [
                -46.42660963146567,
                -11.525628961857697
              ],
              [
                -46.41090522381562,
                -11.541530345778376
              ],
              [
                -46.40143781469592,
                -11.540689324419022
              ],
              [
                -46.398844817738066,
                -11.538927346627466
              ],
              [
                -46.39219637582212,
                -11.534407368131724
              ],
              [
                -46.35963313350848,
                -11.535194328615642
              ],
              [
                -46.352729193164045,
                -11.539824306995513
              ],
              [
                -46.34582523982467,
                -11.54445423137201
              ],
              [
                -46.31302550566413,
                -11.539068742850997
              ],
              [
                -46.28945967930177,
                -11.550936630398736
              ],
              [
                -46.26990635688389,
                -11.556977058870531
              ],
              [
                -46.26415589980032,
                -11.556653559647277
              ],
              [
                -46.25840691870913,
                -11.556330054427553
              ],
              [
                -46.23585609331952,
                -11.564916972760289
              ],
              [
                -46.21788269922149,
                -11.580675355035282
              ],
              [
                -46.213062225868434,
                -11.595233774616249
              ],
              [
                -46.20234682339159,
                -11.602170195117807
              ],
              [
                -46.18662443155652,
                -11.605716654497979
              ],
              [
                -46.16597160198677,
                -11.598964181860746
              ],
              [
                -46.156647160114225,
                -11.606885096205632
              ],
              [
                -46.14074377377424,
                -11.60774458304026
              ],
              [
                -46.12663491725927,
                -11.603854591018536
              ],
              [
                -46.090642193473535,
                -11.61806944489005
              ],
              [
                -46.08624971090899,
                -11.621714439887604
              ],
              [
                -46.08633319083466,
                -11.621993938266654
              ],
              [
                -46.087092676058006,
                -11.624537912513325
              ],
              [
                -46.08596169270958,
                -11.627458375703103
              ],
              [
                -46.0827687108383,
                -11.635702857780512
              ],
              [
                -46.0865696740814,
                -11.642212289714797
              ],
              [
                -46.130966295167056,
                -11.646330848937174
              ],
              [
                -46.133262302662914,
                -11.64654433291357
              ],
              [
                -46.143416696812515,
                -11.651551823394497
              ],
              [
                -46.15357162696396,
                -11.656559785891867
              ],
              [
                -46.16940249294064,
                -11.652967844024278
              ],
              [
                -46.17499244356803,
                -11.651699346960498
              ],
              [
                -46.179706408539495,
                -11.653528367390232
              ],
              [
                -46.18442186250028,
                -11.6553578458224
              ],
              [
                -46.22132109592853,
                -11.644301955667567
              ],
              [
                -46.255850373786785,
                -11.62075116760309
              ],
              [
                -46.265346796106456,
                -11.623866155940867
              ],
              [
                -46.2769331930119,
                -11.622869682809347
              ],
              [
                -46.29104804642299,
                -11.6283041494324
              ],
              [
                -46.30419147456204,
                -11.624950701171198
              ],
              [
                -46.31453085968573,
                -11.631832146413423
              ],
              [
                -46.31467335815463,
                -11.644226073801534
              ],
              [
                -46.30808288895141,
                -11.652475028164453
              ],
              [
                -46.30735741333221,
                -11.65338302248968
              ],
              [
                -46.294462972176994,
                -11.678261864694226
              ],
              [
                -46.29496992699686,
                -11.69447423000841
              ],
              [
                -46.30625784041442,
                -11.702102707142584
              ],
              [
                -46.30854630602394,
                -11.703648728892219
              ],
              [
                -46.32894013018556,
                -11.736879022945242
              ],
              [
                -46.36574580586303,
                -11.745323515364076
              ],
              [
                -46.373041237169176,
                -11.750717995680327
              ],
              [
                -46.367307806758895,
                -11.760482435013644
              ],
              [
                -46.35479787502588,
                -11.756162939440348
              ],
              [
                -46.34696046198143,
                -11.753457450725808
              ],
              [
                -46.338664994483075,
                -11.76611436342497
              ],
              [
                -46.32229312349692,
                -11.770320307544088
              ],
              [
                -46.32427358882278,
                -11.777652266131414
              ],
              [
                -46.33108353579346,
                -11.783118759309634
              ],
              [
                -46.335131003927074,
                -11.786367249161122
              ],
              [
                -46.33271500008549,
                -11.796276157436926
              ],
              [
                -46.33118853070713,
                -11.802536649547672
              ],
              [
                -46.33432650375352,
                -11.809272597556827
              ],
              [
                -46.33746644977589,
                -11.816010032571164
              ],
              [
                -46.34498937810513,
                -11.820141044105839
              ],
              [
                -46.35123281460506,
                -11.82357001010603
              ],
              [
                -46.350482320253,
                -11.825740019618609
              ],
              [
                -46.34709884917885,
                -11.835526933284099
              ],
              [
                -46.3549642795178,
                -11.841822936007413
              ],
              [
                -46.35968473233794,
                -11.839111461660577
              ],
              [
                -46.368075674365635,
                -11.834290996705843
              ],
              [
                -46.37299211957721,
                -11.836249453852544
              ],
              [
                -46.37091164006103,
                -11.854034865172009
              ],
              [
                -46.3741560719807,
                -11.868177768807099
              ],
              [
                -46.36504761585335,
                -11.884019166804523
              ],
              [
                -46.35085223786114,
                -11.882132156502456
              ],
              [
                -46.32629244043394,
                -11.896281058108242
              ],
              [
                -46.32191943964429,
                -11.897172522164716
              ],
              [
                -46.31754746784903,
                -11.898064036222129
              ],
              [
                -46.31061403167291,
                -11.895426515255176
              ],
              [
                -46.28457178665537,
                -11.885519036980833
              ],
              [
                -46.274760822619825,
                -11.889891500558818
              ],
              [
                -46.26494892960283,
                -11.894264490126956
              ],
              [
                -46.237708625146745,
                -11.902183396130722
              ],
              [
                -46.212991805907244,
                -11.898181357443189
              ],
              [
                -46.20705986486901,
                -11.892916918230835
              ],
              [
                -46.201127929828786,
                -11.887652953027754
              ],
              [
                -46.18894854962662,
                -11.883474945944343
              ],
              [
                -46.179744611989264,
                -11.886477400859818
              ],
              [
                -46.17084114917816,
                -11.900563805697962
              ],
              [
                -46.17468759698595,
                -11.909600753510665
              ],
              [
                -46.192133460171554,
                -11.929072643947276
              ],
              [
                -46.20110935938405,
                -11.929522682410918
              ],
              [
                -46.21008577960246,
                -11.92997266487897
              ],
              [
                -46.247638489167045,
                -11.94710460967494
              ],
              [
                -46.28733766480585,
                -11.948558158453977
              ],
              [
                -46.32527587025631,
                -11.958062153133378
              ],
              [
                -46.342624679217394,
                -11.974979073798039
              ],
              [
                -46.34919462463813,
                -11.978014054892821
              ],
              [
                -46.35576405606597,
                -11.98104906599399
              ],
              [
                -46.37310691994455,
                -12.003899433379788
              ],
              [
                -46.373147915049636,
                -12.009474890196076
              ],
              [
                -46.37318788216094,
                -12.0150488550242
              ],
              [
                -46.38384377783887,
                -12.020331370479974
              ],
              [
                -46.39499419023973,
                -12.025859321188408
              ],
              [
                -46.39621518976283,
                -12.032888313879432
              ],
              [
                -46.39743666827513,
                -12.03991723758034
              ],
              [
                -46.3906877022308,
                -12.045335696251893
              ],
              [
                -46.3816752542687,
                -12.052572163381388
              ],
              [
                -46.37999077660458,
                -12.06162061635416
              ],
              [
                -46.37996225559587,
                -12.061772106382243
              ],
              [
                -46.38269375958745,
                -12.065282089942452
              ],
              [
                -46.38801767294247,
                -12.072123046964645
              ],
              [
                -46.381078706780535,
                -12.082459469189876
              ],
              [
                -46.38369569468709,
                -12.08853842484746
              ],
              [
                -46.385187664545555,
                -12.091992934911012
              ],
              [
                -46.37776174109784,
                -12.094660884414926
              ],
              [
                -46.36814130378361,
                -12.098116334795398
              ],
              [
                -46.365942809453806,
                -12.110827278578535
              ],
              [
                -46.37080324352102,
                -12.122107711756415
              ],
              [
                -46.37029223435694,
                -12.127388696632806
              ],
              [
                -46.37024123792955,
                -12.12792318128162
              ],
              [
                -46.36963025188837,
                -12.13427413245673
              ],
              [
                -46.37888066215475,
                -12.160358997672102
              ],
              [
                -46.380243125133525,
                -12.161161481815844
              ],
              [
                -46.38927503944836,
                -12.166479451902307
              ],
              [
                -46.38403756947489,
                -12.173768920391284
              ],
              [
                -46.3836320678009,
                -12.17433241933103
              ],
              [
                -46.380297591912644,
                -12.178975878882984
              ],
              [
                -46.38348408571072,
                -12.183465840229884
              ],
              [
                -46.385007556312765,
                -12.18561332939665
              ],
              [
                -46.39056849810401,
                -12.193451314870332
              ],
              [
                -46.37664508740914,
                -12.197140264242975
              ],
              [
                -46.36820866762148,
                -12.199375718804383
              ],
              [
                -46.36799864413243,
                -12.209060193251961
              ],
              [
                -46.367884169825864,
                -12.214306125997295
              ],
              [
                -46.366201153758944,
                -12.216462629260288
              ],
              [
                -46.359818704424946,
                -12.22464205457125
              ],
              [
                -46.36607312421714,
                -12.241055985716788
              ],
              [
                -46.373041585574015,
                -12.244579466765531
              ],
              [
                -46.38000999093434,
                -12.248102948823217
              ],
              [
                -46.37173905887504,
                -12.258583356227891
              ],
              [
                -46.36571759050521,
                -12.266212815464916
              ],
              [
                -46.36641105061002,
                -12.275370761585027
              ],
              [
                -46.36956105699718,
                -12.279623738409672
              ],
              [
                -46.37460247840254,
                -12.286427685548553
              ],
              [
                -46.372457508979814,
                -12.289846676407862
              ],
              [
                -46.34999416552586,
                -12.296932107905993
              ],
              [
                -46.34405620257137,
                -12.296896106074112
              ],
              [
                -46.335892302259225,
                -12.296847080930283
              ],
              [
                -46.32800285337245,
                -12.298327561863157
              ],
              [
                -46.32405986057797,
                -12.302138533572855
              ],
              [
                -46.32473887693231,
                -12.308017034700276
              ],
              [
                -46.342136699862465,
                -12.313314015897616
              ],
              [
                -46.345829661860435,
                -12.314438487669774
              ],
              [
                -46.351106641745126,
                -12.320361483574711
              ],
              [
                -46.351420133683725,
                -12.32642395005667
              ],
              [
                -46.35196410369046,
                -12.33697638450289
              ],
              [
                -46.34000071902867,
                -12.336563842725415
              ],
              [
                -46.33247925584899,
                -12.343970788023677
              ],
              [
                -46.32429830705877,
                -12.355693712972315
              ],
              [
                -46.31838135972571,
                -12.355810715200608
              ],
              [
                -46.312466376382346,
                -12.355927202435938
              ],
              [
                -46.28524156207162,
                -12.378625519207787
              ],
              [
                -46.289571538275496,
                -12.381454995576794
              ],
              [
                -46.293901518480645,
                -12.384283985953394
              ],
              [
                -46.295492953658986,
                -12.396383935578081
              ],
              [
                -46.30427089224947,
                -12.405108373851395
              ],
              [
                -46.30059789805473,
                -12.415350307761296
              ],
              [
                -46.29242194681453,
                -12.417248289619188
              ],
              [
                -46.285179500134355,
                -12.412264819876688
              ],
              [
                -46.282990550876434,
                -12.410758801698595
              ],
              [
                -46.27340711568653,
                -12.41399280824777
              ],
              [
                -46.264520682919816,
                -12.4122362973117
              ],
              [
                -46.256004254871414,
                -12.423908226013673
              ],
              [
                -46.248552791811704,
                -12.436561101366182
              ],
              [
                -46.24735078211999,
                -12.446742034418131
              ],
              [
                -46.24973224969709,
                -12.452095534438367
              ],
              [
                -46.25315419828756,
                -12.459786956816039
              ],
              [
                -46.25433263323958,
                -12.492756299715937
              ],
              [
                -46.25099666708522,
                -12.494388266618078
              ],
              [
                -46.2476281939943,
                -12.494893270335142
              ],
              [
                -46.245192224732214,
                -12.495258248346511
              ],
              [
                -46.22740534559204,
                -12.497913218679349
              ],
              [
                -46.18260772186574,
                -12.476824284149581
              ],
              [
                -46.158733453505086,
                -12.474911736033587
              ],
              [
                -46.153544465138246,
                -12.482796212897844
              ],
              [
                -46.16162437826249,
                -12.495126635092667
              ],
              [
                -46.1515549399785,
                -12.513428496863629
              ],
              [
                -46.156224900191425,
                -12.521351955742267
              ],
              [
                -46.15688490715161,
                -12.52247345892182
              ],
              [
                -46.16023633742427,
                -12.528154434456335
              ],
              [
                -46.19431355552902,
                -12.529563485605813
              ],
              [
                -46.20129251765756,
                -12.529852474650713
              ],
              [
                -46.21364642136519,
                -12.533731955150555
              ],
              [
                -46.22600179107547,
                -12.537611464670702
              ],
              [
                -46.25477559025894,
                -12.546644439319335
              ],
              [
                -46.265908944268276,
                -12.571906826966805
              ],
              [
                -46.27951479208752,
                -12.584471261774803
              ],
              [
                -46.27520332792547,
                -12.59026571084938
              ],
              [
                -46.272775371913355,
                -12.593528187242738
              ],
              [
                -46.289768666967134,
                -12.631327483228398
              ],
              [
                -46.27318379631483,
                -12.637619414107213
              ],
              [
                -46.27113030007323,
                -12.642880397961582
              ],
              [
                -46.261008382369134,
                -12.645970373371076
              ],
              [
                -46.25639639619047,
                -12.655691293146122
              ],
              [
                -46.26514131643477,
                -12.667610232513116
              ],
              [
                -46.275389707246816,
                -12.67368222574542
              ],
              [
                -46.28425816134148,
                -12.678936174795576
              ],
              [
                -46.29282154150833,
                -12.692385119714173
              ],
              [
                -46.28578611893135,
                -12.698144089757829
              ],
              [
                -46.28626208425288,
                -12.704829541991606
              ],
              [
                -46.27624067087461,
                -12.705374509933794
              ],
              [
                -46.264491732926636,
                -12.716518919915035
              ],
              [
                -46.26713470150354,
                -12.738826792912276
              ],
              [
                -46.272838654843184,
                -12.743955768838424
              ],
              [
                -46.27990155839477,
                -12.75030776651527
              ],
              [
                -46.2793480633244,
                -12.751811754536597
              ],
              [
                -46.27655260306585,
                -12.759408209137309
              ],
              [
                -46.27554606952695,
                -12.767051157285053
              ],
              [
                -46.27456156708378,
                -12.774524603499312
              ],
              [
                -46.276080569146295,
                -12.77628058027387
              ],
              [
                -46.282495493065696,
                -12.783693042568975
              ],
              [
                -46.27765102397047,
                -12.792547494341733
              ],
              [
                -46.2804174738812,
                -12.805399936992602
              ],
              [
                -46.274004517042314,
                -12.810106392371695
              ],
              [
                -46.27201452543551,
                -12.8115673793834
              ],
              [
                -46.26188059249011,
                -12.831243242345765
              ],
              [
                -46.267394560952546,
                -12.838979676334
              ],
              [
                -46.272908965403516,
                -12.846716656338964
              ],
              [
                -46.29229179426968,
                -12.858984083062962
              ],
              [
                -46.28410233777574,
                -12.87802995903544
              ],
              [
                -46.28577028877931,
                -12.89478435857489
              ],
              [
                -46.28978775180536,
                -12.899053349227813
              ],
              [
                -46.297129675577295,
                -12.906854307893083
              ],
              [
                -46.294025213924506,
                -12.914261793259813
              ],
              [
                -46.29198922502121,
                -12.919118734716692
              ],
              [
                -46.30435760037893,
                -12.949443064736302
              ],
              [
                -46.279268799090076,
                -12.942537077169739
              ],
              [
                -46.27678030379548,
                -12.937763609966057
              ],
              [
                -46.275238828955956,
                -12.9348066193296
              ],
              [
                -46.26632142558544,
                -12.93191814248547
              ],
              [
                -46.22008827631836,
                -12.94012600603104
              ],
              [
                -46.19770695859122,
                -12.924870578394142
              ],
              [
                -46.18396458835473,
                -12.92337654548762
              ],
              [
                -46.138535444677096,
                -12.908956082258765
              ],
              [
                -46.125266560306976,
                -12.909550043534992
              ],
              [
                -46.11388862666913,
                -12.917537000079117
              ],
              [
                -46.119495100998385,
                -12.925406935390836
              ],
              [
                -46.124005530219854,
                -12.927138951102847
              ],
              [
                -46.1486133506845,
                -12.936587443221377
              ],
              [
                -46.16090124990114,
                -12.933576479036269
              ],
              [
                -46.17657310089542,
                -12.939195434107402
              ],
              [
                -46.17946958102165,
                -12.946921398064875
              ],
              [
                -46.187435508720085,
                -12.949291909483485
              ],
              [
                -46.19412446796653,
                -12.956240383228039
              ],
              [
                -46.22326169182613,
                -12.956519923174438
              ],
              [
                -46.23748808623942,
                -12.962230417511003
              ],
              [
                -46.24199607169878,
                -12.96025744263157
              ],
              [
                -46.27674026385228,
                -12.970938407947896
              ],
              [
                -46.278338232511715,
                -12.969528406006804
              ],
              [
                -46.2799367351679,
                -12.968117910069331
              ],
              [
                -46.29391462902537,
                -12.973592900518138
              ],
              [
                -46.31117998164861,
                -12.980355398525855
              ],
              [
                -46.319974412772666,
                -12.980392917429603
              ],
              [
                -46.32882683944542,
                -12.980430912426158
              ],
              [
                -46.33366082104612,
                -12.980451939068553
              ],
              [
                -46.33512726657382,
                -12.98334439802298
              ],
              [
                -46.363443555454424,
                -12.990787925803716
              ],
              [
                -46.37293599895258,
                -12.984338990847489
              ],
              [
                -46.36936253063671,
                -12.975763032661964
              ],
              [
                -46.367826512981075,
                -12.972076554361475
              ],
              [
                -46.36959554555303,
                -12.969920546170464
              ],
              [
                -46.37581400089791,
                -12.962341088744603
              ],
              [
                -46.37109954802019,
                -12.95710913064286
              ],
              [
                -46.37181705966272,
                -12.944321696726858
              ],
              [
                -46.36798658808591,
                -12.93769425246697
              ],
              [
                -46.364156143502285,
                -12.931067283216766
              ],
              [
                -46.365101667425925,
                -12.912784910808004
              ],
              [
                -46.360269680025354,
                -12.908404926458157
              ],
              [
                -46.36553715475717,
                -12.90388993207632
              ],
              [
                -46.36755912960407,
                -12.902156467750158
              ],
              [
                -46.36504066406035,
                -12.897046986502927
              ],
              [
                -46.36159522798509,
                -12.890056534115661
              ],
              [
                -46.366547676940414,
                -12.887510553365413
              ],
              [
                -46.36678266127722,
                -12.887390042466759
              ],
              [
                -46.36664668879584,
                -12.887124553849345
              ],
              [
                -46.36382572229638,
                -12.881618577524272
              ],
              [
                -46.36871716597503,
                -12.877118590449621
              ],
              [
                -46.3657647154191,
                -12.86442269726537
              ],
              [
                -46.37371616165785,
                -12.859424723068877
              ],
              [
                -46.37382515490726,
                -12.859356234655438
              ],
              [
                -46.374278168847916,
                -12.857222740238772
              ],
              [
                -46.375223141720966,
                -12.852773256568929
              ],
              [
                -46.38326808795398,
                -12.848543295884372
              ],
              [
                -46.38777157613996,
                -12.84922181496719
              ],
              [
                -46.39762249562144,
                -12.850705798716687
              ],
              [
                -46.40691639901642,
                -12.845852350772008
              ],
              [
                -46.40706442125607,
                -12.838953387584173
              ],
              [
                -46.407069432622315,
                -12.838707904071669
              ],
              [
                -46.407333936358164,
                -12.838809915878008
              ],
              [
                -46.41066039687992,
                -12.840095407427702
              ],
              [
                -46.41075540626474,
                -12.836988916300353
              ],
              [
                -46.41104391362572,
                -12.827522958814225
              ],
              [
                -46.417186364374565,
                -12.82304202459605
              ],
              [
                -46.41863684494741,
                -12.833291940127383
              ],
              [
                -46.4226417763719,
                -12.861596295000686
              ],
              [
                -46.432267092056144,
                -12.929616397132511
              ],
              [
                -46.43695603783733,
                -12.935057878946632
              ],
              [
                -46.44245051751599,
                -12.935536847886343
              ],
              [
                -46.445588497204184,
                -12.935810874276479
              ],
              [
                -46.45090688215462,
                -12.95732223159204
              ],
              [
                -46.45256338683174,
                -12.964021717024957
              ],
              [
                -46.45422035549698,
                -12.970722153461727
              ],
              [
                -46.51080892316562,
                -12.970324239987463
              ],
              [
                -46.55461703912756,
                -12.970026813390023
              ],
              [
                -46.6334319330883,
                -12.96955394441553
              ],
              [
                -46.75017651837675,
                -12.968724106117614
              ],
              [
                -46.81926389889591,
                -13.002039005550458
              ],
              [
                -46.82270836921098,
                -13.007696493462683
              ],
              [
                -46.81846088569698,
                -13.013587949483627
              ],
              [
                -46.8277698272427,
                -13.017223921980701
              ],
              [
                -46.82982128282187,
                -13.022699898730648
              ],
              [
                -46.83380977138812,
                -13.033344831891117
              ],
              [
                -46.83560522286502,
                -13.033572868459343
              ],
              [
                -46.83973369647418,
                -13.034095877074028
              ],
              [
                -46.847071619145915,
                -13.045040789948803
              ],
              [
                -46.844465636502946,
                -13.049259769615192
              ],
              [
                -46.84702713323659,
                -13.053442748927258
              ],
              [
                -46.84826613054216,
                -13.050462261666256
              ],
              [
                -46.85454705901404,
                -13.0552237576138
              ],
              [
                -46.854547050000896,
                -13.05935272207571
              ],
              [
                -46.8545470488383,
                -13.06297972052259
              ],
              [
                -46.85454703879976,
                -13.066532679411225
              ],
              [
                -46.858241518005826,
                -13.066951202960217
              ],
              [
                -46.868688922524704,
                -13.067686197664779
              ],
              [
                -46.87115440158631,
                -13.06035375724848
              ],
              [
                -46.87544886848054,
                -13.061124753683506
              ],
              [
                -46.87982284882846,
                -13.0589087619908
              ],
              [
                -46.8793838631182,
                -13.056651786679744
              ],
              [
                -46.878076875815246,
                -13.049933330456732
              ],
              [
                -46.89248676558809,
                -13.052791334007079
              ],
              [
                -46.90190465156118,
                -13.066872765790414
              ],
              [
                -46.91035210212702,
                -13.068630262173658
              ],
              [
                -46.91719653164767,
                -13.075473238767856
              ],
              [
                -46.92845994183711,
                -13.08265619660203
              ],
              [
                -46.928537420757515,
                -13.086451687221004
              ],
              [
                -46.9286599225574,
                -13.092454132830172
              ],
              [
                -46.94233429236664,
                -13.097537634115701
              ],
              [
                -46.94590124506111,
                -13.098863608107994
              ],
              [
                -46.94795275748891,
                -13.102052094579411
              ],
              [
                -46.95352018793675,
                -13.110706574444393
              ],
              [
                -46.96326711559529,
                -13.111718063460406
              ],
              [
                -46.97814799449549,
                -13.121162030998823
              ],
              [
                -46.97843496614981,
                -13.131143483395809
              ],
              [
                -46.98798538399761,
                -13.12770450245863
              ],
              [
                -46.987974372805574,
                -13.123779539665886
              ],
              [
                -46.98796891004489,
                -13.121912562705146
              ],
              [
                -46.999966800053386,
                -13.121175084800859
              ],
              [
                -47.004966276764605,
                -13.124689549233903
              ],
              [
                -47.00462827347053,
                -13.131663519427
              ],
              [
                -47.00563973128625,
                -13.13355350591421
              ],
              [
                -47.00930069380472,
                -13.14039198650462
              ],
              [
                -47.01895463847629,
                -13.145349944102374
              ],
              [
                -47.02148508837224,
                -13.142571485696292
              ],
              [
                -47.02240259989581,
                -13.141563999163543
              ],
              [
                -47.0351234747643,
                -13.14753296885862
              ],
              [
                -47.035358483698495,
                -13.14940845516495
              ],
              [
                -47.03548549595124,
                -13.150427458353917
              ],
              [
                -47.036752977814665,
                -13.149952971247846
              ],
              [
                -47.041433916074695,
                -13.148200474322136
              ],
              [
                -47.044415398522986,
                -13.152942440228658
              ],
              [
                -47.04709639114877,
                -13.157206440468785
              ],
              [
                -47.050880875413554,
                -13.155476956929213
              ],
              [
                -47.061342244908694,
                -13.160940435941548
              ],
              [
                -47.062510271355606,
                -13.160001940411014
              ],
              [
                -47.06865519561451,
                -13.155068466682593
              ],
              [
                -47.071964159059775,
                -13.160062973664685
              ],
              [
                -47.08040759828444,
                -13.16152996595505
              ],
              [
                -47.07979961835404,
                -13.166004933544107
              ],
              [
                -47.08089909803108,
                -13.166990428546368
              ],
              [
                -47.08437655538189,
                -13.170106904908002
              ],
              [
                -47.07972061286841,
                -13.17768085752725
              ],
              [
                -47.08691101802729,
                -13.181988864013835
              ],
              [
                -47.089548515825875,
                -13.173578905979369
              ],
              [
                -47.09321249999753,
                -13.172466901598256
              ],
              [
                -47.10049144347327,
                -13.173875411331641
              ],
              [
                -47.10212743509482,
                -13.174192411168844
              ],
              [
                -47.10224641364472,
                -13.174492416596644
              ],
              [
                -47.10493189991069,
                -13.181263372118595
              ],
              [
                -47.10829536929213,
                -13.179661411130349
              ],
              [
                -47.10837084389397,
                -13.176648400018427
              ],
              [
                -47.10852235111682,
                -13.170606457892875
              ],
              [
                -47.11782180340479,
                -13.177444423968021
              ],
              [
                -47.132154198947696,
                -13.17807093500353
              ],
              [
                -47.139931099317764,
                -13.187149402400294
              ],
              [
                -47.134841157190145,
                -13.188904865624593
              ],
              [
                -47.13419512986999,
                -13.1891278602404
              ],
              [
                -47.134765136080986,
                -13.202051318067378
              ],
              [
                -47.152610477242526,
                -13.209538774601196
              ],
              [
                -47.16168192843104,
                -13.205325813457
              ],
              [
                -47.16729334198577,
                -13.210800775557
              ],
              [
                -47.17204382502641,
                -13.206023348555778
              ],
              [
                -47.1836972342819,
                -13.20396685400545
              ],
              [
                -47.180507779008956,
                -13.200647889235865
              ],
              [
                -47.174797298019676,
                -13.194706403720268
              ],
              [
                -47.18241377350327,
                -13.187672975746938
              ],
              [
                -47.19643464414032,
                -13.193193433527137
              ],
              [
                -47.21166305401122,
                -13.192542949629535
              ],
              [
                -47.217123470105186,
                -13.196100436776723
              ],
              [
                -47.223075927932925,
                -13.192961474116379
              ],
              [
                -47.22871591522714,
                -13.194438482798915
              ],
              [
                -47.230795365406046,
                -13.20341993446322
              ],
              [
                -47.25018521553979,
                -13.21156391285573
              ],
              [
                -47.2486096763745,
                -13.223384341814656
              ],
              [
                -47.24776867001645,
                -13.229689302393092
              ],
              [
                -47.25235616241682,
                -13.234698800626784
              ],
              [
                -47.26174556442312,
                -13.236723808390856
              ],
              [
                -47.26740950398498,
                -13.237944288671477
              ],
              [
                -47.26726502621528,
                -13.24133976150724
              ],
              [
                -47.26702803138227,
                -13.246912742415148
              ],
              [
                -47.27491892642336,
                -13.250489238603665
              ],
              [
                -47.28171288192555,
                -13.264476665929923
              ],
              [
                -47.2894888107618,
                -13.261798174625289
              ],
              [
                -47.286073342712285,
                -13.257069196642636
              ],
              [
                -47.28521337451332,
                -13.25587769719218
              ],
              [
                -47.29312527624273,
                -13.256098213114033
              ],
              [
                -47.29409830885936,
                -13.248497750297814
              ],
              [
                -47.311348157562406,
                -13.251027769272158
              ],
              [
                -47.31695011580498,
                -13.254449783577538
              ],
              [
                -47.32255254304428,
                -13.257872253888266
              ],
              [
                -47.331415996373124,
                -13.250629286131842
              ],
              [
                -47.33721497530048,
                -13.245889850584277
              ],
              [
                -47.3445998864011,
                -13.247145838564757
              ],
              [
                -47.35198483950578,
                -13.248402864543397
              ],
              [
                -47.355947305156775,
                -13.245180885032648
              ],
              [
                -47.367599248623925,
                -13.23570645397802
              ],
              [
                -47.37089172484129,
                -13.228847008644438
              ],
              [
                -47.377295649506216,
                -13.2301804995275
              ],
              [
                -47.383305107690184,
                -13.241029932051799
              ],
              [
                -47.389212543300694,
                -13.240604476415085
              ],
              [
                -47.39332051963165,
                -13.245536924418877
              ],
              [
                -47.392972024992076,
                -13.24976039612468
              ],
              [
                -47.391969504651456,
                -13.261898823437908
              ],
              [
                -47.39707644992803,
                -13.26066433220798
              ],
              [
                -47.415712818317104,
                -13.269895820447156
              ],
              [
                -47.42653417443928,
                -13.289006716927124
              ],
              [
                -47.43732514242556,
                -13.278600792818995
              ],
              [
                -47.43440517810095,
                -13.267793376062151
              ],
              [
                -47.45413055726005,
                -13.249434500381886
              ],
              [
                -47.44740412789943,
                -13.240738032946627
              ],
              [
                -47.44696360632883,
                -13.240168541538393
              ],
              [
                -47.43084625654411,
                -13.237605048566477
              ],
              [
                -47.43532020368565,
                -13.226668609002822
              ],
              [
                -47.44600064936668,
                -13.222646632370802
              ],
              [
                -47.46301052514184,
                -13.226411632788924
              ],
              [
                -47.46546897902152,
                -13.226956135716765
              ],
              [
                -47.48668082807768,
                -13.21981624490601
              ],
              [
                -47.4870223419742,
                -13.2137527499088
              ],
              [
                -47.48736434186177,
                -13.207689289916578
              ],
              [
                -47.478447937310115,
                -13.184708931397614
              ],
              [
                -47.49046437083865,
                -13.182872966169105
              ],
              [
                -47.49630182222117,
                -13.186399457284402
              ],
              [
                -47.5001557761422,
                -13.18872840809823
              ],
              [
                -47.50943070164234,
                -13.18943692843233
              ],
              [
                -47.515553178957674,
                -13.185976483871753
              ],
              [
                -47.523464584098924,
                -13.181505493198266
              ],
              [
                -47.53262454149938,
                -13.183379523530826
              ],
              [
                -47.53466100700682,
                -13.185469509719583
              ],
              [
                -47.54096197169155,
                -13.191936985462837
              ],
              [
                -47.562631786131796,
                -13.183799063166814
              ],
              [
                -47.56804330629144,
                -13.149088264918985
              ],
              [
                -47.560907378867874,
                -13.124241410265762
              ],
              [
                -47.56254440957279,
                -13.123406392868835
              ],
              [
                -47.57343582644309,
                -13.117845452480521
              ],
              [
                -47.59248967300001,
                -13.116236479551148
              ],
              [
                -47.617540473887885,
                -13.104275593068058
              ],
              [
                -47.625189419525164,
                -13.108842582100769
              ],
              [
                -47.62717641202714,
                -13.110028587476776
              ],
              [
                -47.628944904827314,
                -13.10855657901366
              ],
              [
                -47.63434036166147,
                -13.104064629215621
              ],
              [
                -47.635518840521264,
                -13.113877085874641
              ],
              [
                -47.64623325334638,
                -13.120173537943169
              ],
              [
                -47.6450687265798,
                -13.128145997475915
              ],
              [
                -47.65026917188289,
                -13.132922466266633
              ],
              [
                -47.64629669755644,
                -13.137071961438135
              ],
              [
                -47.64513171249906,
                -13.138288936396542
              ],
              [
                -47.647174190909574,
                -13.138701462393609
              ],
              [
                -47.64862719910902,
                -13.138994444106011
              ],
              [
                -47.64779219183265,
                -13.14001194077844
              ],
              [
                -47.64340621164313,
                -13.145356902286023
              ],
              [
                -47.64406572744687,
                -13.149428875632776
              ],
              [
                -47.65146165256073,
                -13.149662394606745
              ],
              [
                -47.65407259997287,
                -13.157890829980847
              ],
              [
                -47.65649911509987,
                -13.15847535062325
              ],
              [
                -47.66109554846016,
                -13.159580826852046
              ],
              [
                -47.66954597837953,
                -13.167871309645824
              ],
              [
                -47.66066605227756,
                -13.173675760981073
              ],
              [
                -47.65490358850168,
                -13.17744322739328
              ],
              [
                -47.657461559248716,
                -13.192007639808601
              ],
              [
                -47.662685019735235,
                -13.194828632118933
              ],
              [
                -47.66543295679232,
                -13.19631264307611
              ],
              [
                -47.66290049511858,
                -13.197089110313733
              ],
              [
                -47.660625490582476,
                -13.197786627442015
              ],
              [
                -47.663293455878666,
                -13.20120861197997
              ],
              [
                -47.66498247897309,
                -13.203374615195601
              ],
              [
                -47.66360895966494,
                -13.204510599279647
              ],
              [
                -47.66153748777601,
                -13.20622258984001
              ],
              [
                -47.662766986367956,
                -13.206711054055608
              ],
              [
                -47.668456943200034,
                -13.208973061420037
              ],
              [
                -47.66459695110469,
                -13.213607534968505
              ],
              [
                -47.665816456568685,
                -13.218220512159107
              ],
              [
                -47.657771003066514,
                -13.219384474891033
              ],
              [
                -47.65418451903772,
                -13.219903488855042
              ],
              [
                -47.653180002648384,
                -13.228599405544504
              ],
              [
                -47.65378501137689,
                -13.22949640733815
              ],
              [
                -47.65701297691146,
                -13.234283914890298
              ],
              [
                -47.65137401007719,
                -13.238096885231045
              ],
              [
                -47.646235062950964,
                -13.241571363379283
              ],
              [
                -47.647654026879906,
                -13.250161801769265
              ],
              [
                -47.64004258496662,
                -13.262774698583302
              ],
              [
                -47.6490739885847,
                -13.273695671252431
              ],
              [
                -47.649089468453816,
                -13.273701676243562
              ],
              [
                -47.65637593894279,
                -13.27646914141892
              ],
              [
                -47.65656687906683,
                -13.299458032109465
              ],
              [
                -47.661764816685896,
                -13.30341800686908
              ],
              [
                -47.656871839026586,
                -13.310636949668542
              ],
              [
                -47.65376487611896,
                -13.31521941379989
              ],
              [
                -47.65521685959946,
                -13.319891413112074
              ],
              [
                -47.655844339159586,
                -13.321912390433202
              ],
              [
                -47.649685852071926,
                -13.350816723174605
              ],
              [
                -47.63681594025703,
                -13.364363133737903
              ],
              [
                -47.62228955422327,
                -13.367229570484179
              ],
              [
                -47.626735491035234,
                -13.383959493903191
              ],
              [
                -47.63340289265789,
                -13.390450945548778
              ],
              [
                -47.638110363745845,
                -13.390610446614584
              ],
              [
                -47.638496883716165,
                -13.39062298219803
              ],
              [
                -47.63924135150221,
                -13.392554464257165
              ],
              [
                -47.64344632043453,
                -13.40347191507906
              ],
              [
                -47.64886427629139,
                -13.409103878633115
              ],
              [
                -47.65415220639852,
                -13.414599838767016
              ],
              [
                -47.65531067146212,
                -13.42018881034613
              ],
              [
                -47.656880154798685,
                -13.42776126013822
              ],
              [
                -47.6618431333588,
                -13.431604262326601
              ],
              [
                -47.66728859049882,
                -13.435822256172814
              ],
              [
                -47.67538597042694,
                -13.448795161862725
              ],
              [
                -47.67696396468753,
                -13.458037618058665
              ],
              [
                -47.67854192993624,
                -13.467279570272657
              ],
              [
                -47.80037871050418,
                -13.333271054200976
              ],
              [
                -47.80086169179949,
                -13.330419069529965
              ],
              [
                -47.80134469409355,
                -13.327567072859843
              ],
              [
                -47.823547044395674,
                -13.311434698866895
              ],
              [
                -47.8440194057179,
                -13.318204682372029
              ],
              [
                -47.85036235937687,
                -13.31660671036732
              ],
              [
                -47.860899776404324,
                -13.313951758584611
              ],
              [
                -47.86428571453182,
                -13.31600824043032
              ],
              [
                -47.86990416396465,
                -13.319420220226633
              ],
              [
                -47.88313257331638,
                -13.320077754862163
              ],
              [
                -47.888459518422884,
                -13.317607281177828
              ],
              [
                -47.89066253618761,
                -13.312049296040163
              ],
              [
                -47.89273800937232,
                -13.306810860840267
              ],
              [
                -47.89778648773448,
                -13.309379325551877
              ],
              [
                -47.90173543260187,
                -13.311388328636848
              ],
              [
                -47.902487967965826,
                -13.306754869691405
              ],
              [
                -47.90326695040665,
                -13.30196039172358
              ],
              [
                -47.9179043508414,
                -13.297001440189284
              ],
              [
                -47.92442879641521,
                -13.291295982215429
              ],
              [
                -47.929458762488814,
                -13.294123965396066
              ],
              [
                -47.93543473001129,
                -13.290290484670926
              ],
              [
                -47.93916318361229,
                -13.297957454697622
              ],
              [
                -47.9433796166503,
                -13.306629895761311
              ],
              [
                -47.947632597791426,
                -13.304905426934159
              ],
              [
                -47.9516740849859,
                -13.309729902936668
              ],
              [
                -47.956043554747374,
                -13.308278917728638
              ],
              [
                -47.96587746408334,
                -13.314825903636011
              ],
              [
                -47.97056691442627,
                -13.311088953163427
              ],
              [
                -47.97162642312959,
                -13.30040948059528
              ],
              [
                -47.97843240328252,
                -13.294309042351202
              ],
              [
                -47.98222684707214,
                -13.285748607197243
              ],
              [
                -47.98570733403483,
                -13.277894652434865
              ],
              [
                -47.99226780431458,
                -13.276195681357446
              ],
              [
                -47.998828257601346,
                -13.274496187281876
              ],
              [
                -48.02212254590105,
                -13.279405179436242
              ],
              [
                -48.0286510142303,
                -13.271045251726825
              ],
              [
                -48.03229200164155,
                -13.273569753335785
              ],
              [
                -48.03382001324739,
                -13.265411280985798
              ],
              [
                -48.028539030582444,
                -13.263973797333366
              ],
              [
                -48.02900603205229,
                -13.262796814915099
              ],
              [
                -48.032678541006305,
                -13.253545357515016
              ],
              [
                -48.04331845503128,
                -13.254560881666963
              ],
              [
                -48.05201738838629,
                -13.255390880608232
              ],
              [
                -48.05615836455969,
                -13.247937938608015
              ],
              [
                -48.055975339847784,
                -13.24687493243005
              ],
              [
                -48.05454288662734,
                -13.238526491169734
              ],
              [
                -48.05843182558783,
                -13.240235467888144
              ],
              [
                -48.06201331022125,
                -13.234967515337715
              ],
              [
                -48.07138272903757,
                -13.239990507212353
              ],
              [
                -48.075907220296735,
                -13.238185017280655
              ],
              [
                -48.07868270122543,
                -13.24252250296459
              ],
              [
                -48.07270270126239,
                -13.264118850329318
              ],
              [
                -48.07185370040004,
                -13.267184359220694
              ],
              [
                -48.07378270231157,
                -13.266729841110939
              ],
              [
                -48.07692965132886,
                -13.265988347720093
              ],
              [
                -48.07761815131496,
                -13.275999814195993
              ],
              [
                -48.077708655292305,
                -13.277313788778743
              ],
              [
                -48.080403115627725,
                -13.27893328802078
              ],
              [
                -48.077621614438385,
                -13.281567764122117
              ],
              [
                -48.08257257021976,
                -13.288347253476331
              ],
              [
                -48.09282349748465,
                -13.285869271158036
              ],
              [
                -48.097097960571965,
                -13.291472744154985
              ],
              [
                -48.10364342823387,
                -13.288552262090676
              ],
              [
                -48.10631991269607,
                -13.287358298511649
              ],
              [
                -48.11270685590165,
                -13.29159978095147
              ],
              [
                -48.117906787074205,
                -13.290307286228606
              ],
              [
                -48.12142778104965,
                -13.29699923770817
              ],
              [
                -48.15109401608823,
                -13.306105736819916
              ],
              [
                -48.15587400347909,
                -13.30258675318746
              ],
              [
                -48.157097464707434,
                -13.310099221089953
              ],
              [
                -48.160349951555325,
                -13.303947255987932
              ],
              [
                -48.16092493505218,
                -13.30286025721475
              ],
              [
                -48.164957401533684,
                -13.305356250736038
              ],
              [
                -48.169362876001856,
                -13.298918791234394
              ],
              [
                -48.15329105163384,
                -13.278764907775484
              ],
              [
                -48.15604256958716,
                -13.249847556830117
              ],
              [
                -48.16212554258628,
                -13.246516587316165
              ],
              [
                -48.168207980592236,
                -13.243186634792899
              ],
              [
                -48.16327854148022,
                -13.229084683613248
              ],
              [
                -48.160947055342504,
                -13.226877722372413
              ],
              [
                -48.152880634981926,
                -13.219240245704176
              ],
              [
                -48.159849104865735,
                -13.20982482272563
              ],
              [
                -48.16141409352976,
                -13.207710311552264
              ],
              [
                -48.16376107360991,
                -13.199664890867943
              ],
              [
                -48.16344810918044,
                -13.183656948583009
              ],
              [
                -48.15730719537233,
                -13.176901003165089
              ],
              [
                -48.15510270461886,
                -13.17447651742533
              ],
              [
                -48.15476571844228,
                -13.169791523871114
              ],
              [
                -48.15439522334629,
                -13.164636052977064
              ],
              [
                -48.15156825170704,
                -13.160888088830752
              ],
              [
                -48.14544231315673,
                -13.152767108364333
              ],
              [
                -48.14905479334405,
                -13.15389262095213
              ],
              [
                -48.15751019057175,
                -13.156525633001314
              ],
              [
                -48.162044678600914,
                -13.153693147974632
              ],
              [
                -48.164504155982954,
                -13.156925625474344
              ],
              [
                -48.168151149470965,
                -13.153171153268493
              ],
              [
                -48.17349158496873,
                -13.147674685958647
              ],
              [
                -48.196454888943755,
                -13.16316115337627
              ],
              [
                -48.19629938295582,
                -13.164405647943326
              ],
              [
                -48.19530837565506,
                -13.172336595578065
              ],
              [
                -48.2110677749149,
                -13.173700085320283
              ],
              [
                -48.21313175775341,
                -13.1715791270181
              ],
              [
                -48.216460229143635,
                -13.168157630338966
              ],
              [
                -48.224707665637055,
                -13.173290619703591
              ],
              [
                -48.23143561161375,
                -13.167740188014955
              ],
              [
                -48.23843604214619,
                -13.180019124161623
              ],
              [
                -48.24501198340654,
                -13.18407458493988
              ],
              [
                -48.24298349498082,
                -13.186366064327837
              ],
              [
                -48.24243600010379,
                -13.186985087840952
              ],
              [
                -48.25044392361866,
                -13.196559538312886
              ],
              [
                -48.261406855871144,
                -13.196788548547184
              ],
              [
                -48.26229281902303,
                -13.196807056940138
              ],
              [
                -48.262503840329586,
                -13.197414061807509
              ],
              [
                -48.26499080259688,
                -13.204559513941962
              ],
              [
                -48.26821178966631,
                -13.202379007929412
              ],
              [
                -48.2753852262195,
                -13.204800038163645
              ],
              [
                -48.279594699336165,
                -13.2062215191261
              ],
              [
                -48.276073732025154,
                -13.199074048236204
              ],
              [
                -48.280124203587626,
                -13.199236556160338
              ],
              [
                -48.284592141232835,
                -13.20546853091863
              ],
              [
                -48.287558612582465,
                -13.204810516724667
              ],
              [
                -48.28829861975279,
                -13.214517993215942
              ],
              [
                -48.28834708325846,
                -13.215152987650981
              ],
              [
                -48.289096602555766,
                -13.215118479119937
              ],
              [
                -48.29267707031873,
                -13.214951483149823
              ],
              [
                -48.29235157719998,
                -13.21756747757457
              ],
              [
                -48.292158577935474,
                -13.219122963314682
              ],
              [
                -48.299886979239304,
                -13.223237460801155
              ],
              [
                -48.30744541701239,
                -13.227261927527838
              ],
              [
                -48.30927539956645,
                -13.230301915187715
              ],
              [
                -48.31024542925701,
                -13.231913941583944
              ],
              [
                -48.30693543054583,
                -13.232002926452312
              ],
              [
                -48.307420428611834,
                -13.234944399395696
              ],
              [
                -48.31630734482255,
                -13.233516410684679
              ],
              [
                -48.3164183599966,
                -13.232935931203777
              ],
              [
                -48.317070875009165,
                -13.229529434857826
              ],
              [
                -48.32968076643354,
                -13.23159746241082
              ],
              [
                -48.34167866366094,
                -13.227970488594979
              ],
              [
                -48.34024317875786,
                -13.23640442979026
              ],
              [
                -48.3423836427069,
                -13.239568418287197
              ],
              [
                -48.3434811533996,
                -13.24119090185109
              ],
              [
                -48.33648769545618,
                -13.246653360640423
              ],
              [
                -48.34132362467351,
                -13.24926085591876
              ],
              [
                -48.34611961510768,
                -13.247972369464101
              ],
              [
                -48.34872509745458,
                -13.246074373775713
              ],
              [
                -48.350363564785376,
                -13.241606904170121
              ],
              [
                -48.350429579596266,
                -13.241427445311059
              ],
              [
                -48.3607105162327,
                -13.242774446077439
              ],
              [
                -48.370544895581254,
                -13.249043900890262
              ],
              [
                -48.37307688579033,
                -13.254310866031265
              ],
              [
                -48.37362989950126,
                -13.255461387383425
              ],
              [
                -48.37507286443032,
                -13.25545539887532
              ],
              [
                -48.37899136136624,
                -13.25543987663825
              ],
              [
                -48.38112329580786,
                -13.258924850313834
              ],
              [
                -48.38523478530474,
                -13.265642329861357
              ],
              [
                -48.38745426240085,
                -13.261624345646258
              ],
              [
                -48.38799175900205,
                -13.260651368132468
              ],
              [
                -48.388564761036676,
                -13.262095864839637
              ],
              [
                -48.39071473489363,
                -13.26751881746313
              ],
              [
                -48.40146114996513,
                -13.270008325543863
              ],
              [
                -48.4039136186084,
                -13.268619341674723
              ],
              [
                -48.40679160741459,
                -13.266989349909862
              ],
              [
                -48.412777539266436,
                -13.271602847727769
              ],
              [
                -48.41626651670643,
                -13.274291338303934
              ],
              [
                -48.419911007286196,
                -13.27342036550561
              ],
              [
                -48.429406444459445,
                -13.271150867690784
              ],
              [
                -48.43209540508219,
                -13.274941867604577
              ],
              [
                -48.43157842546654,
                -13.276392838426176
              ],
              [
                -48.43037991067723,
                -13.279755339155557
              ],
              [
                -48.43640586523618,
                -13.283801823318157
              ],
              [
                -48.43477184752656,
                -13.288431287066269
              ],
              [
                -48.44126628644904,
                -13.291938269126922
              ],
              [
                -48.446407785394655,
                -13.283301848265813
              ],
              [
                -48.45990168223715,
                -13.27917986687631
              ],
              [
                -48.46145668357426,
                -13.262739952475181
              ],
              [
                -48.45820174225668,
                -13.260647496880487
              ],
              [
                -48.455991741730976,
                -13.259226976229881
              ],
              [
                -48.4593247324867,
                -13.258486981146248
              ],
              [
                -48.46096318956941,
                -13.258123005022139
              ],
              [
                -48.46065772082408,
                -13.255922534079325
              ],
              [
                -48.45943821477918,
                -13.247142041198092
              ],
              [
                -48.465191716782655,
                -13.244822589266855
              ],
              [
                -48.46756965825396,
                -13.2438635758029
              ],
              [
                -48.47001665099407,
                -13.235697142664064
              ],
              [
                -48.47482714181386,
                -13.235304175105897
              ],
              [
                -48.47207166025083,
                -13.222727223333267
              ],
              [
                -48.4749041618609,
                -13.215776251909945
              ],
              [
                -48.47218768703255,
                -13.212193266785462
              ],
              [
                -48.47137820512878,
                -13.21112577451589
              ],
              [
                -48.47237369474115,
                -13.201953351688553
              ],
              [
                -48.47392020591766,
                -13.200241367112586
              ],
              [
                -48.477291170686456,
                -13.196508895197605
              ],
              [
                -48.47468719093491,
                -13.191257383827885
              ],
              [
                -48.47712520318161,
                -13.178285497216162
              ],
              [
                -48.46817828661545,
                -13.16193704366473
              ],
              [
                -48.47210628704963,
                -13.142345191556993
              ],
              [
                -48.479180248681146,
                -13.132783746310851
              ],
              [
                -48.48017422372992,
                -13.141492189094128
              ],
              [
                -48.48234122568496,
                -13.14174271632536
              ],
              [
                -48.487574192524654,
                -13.142348725708722
              ],
              [
                -48.49454763102382,
                -13.136149759015845
              ],
              [
                -48.50362958572756,
                -13.133727293254433
              ],
              [
                -48.50607756812942,
                -13.133074295135344
              ],
              [
                -48.508419034390364,
                -13.128421841741117
              ],
              [
                -48.52154442668511,
                -13.140455776047112
              ],
              [
                -48.52116991372194,
                -13.15340069532571
              ],
              [
                -48.5207948967393,
                -13.166345130627372
              ],
              [
                -48.52970929050698,
                -13.18442953133598
              ],
              [
                -48.529935284144045,
                -13.19213148869051
              ],
              [
                -48.53939318559662,
                -13.193900498658902
              ],
              [
                -48.54387765377989,
                -13.194738997492918
              ],
              [
                -48.55415858471385,
                -13.204538446002633
              ],
              [
                -48.55326252742329,
                -13.224908837159187
              ],
              [
                -48.551326039544904,
                -13.227556335743941
              ],
              [
                -48.54943554842709,
                -13.230140295769647
              ],
              [
                -48.55280103249293,
                -13.234737271269715
              ],
              [
                -48.554741520925056,
                -13.237388264450463
              ],
              [
                -48.55451749005325,
                -13.255702643570853
              ],
              [
                -48.55449046135488,
                -13.257895629015032
              ],
              [
                -48.55477198056904,
                -13.258811132273934
              ],
              [
                -48.55929041277927,
                -13.273510078174953
              ],
              [
                -48.55700042675085,
                -13.276067031678002
              ],
              [
                -48.553407458100516,
                -13.280080505646264
              ],
              [
                -48.55315791767574,
                -13.301547891854792
              ],
              [
                -48.56463482538061,
                -13.309611365597753
              ],
              [
                -48.56723277922641,
                -13.311435849663628
              ],
              [
                -48.577796232558335,
                -13.314677854332572
              ],
              [
                -48.585959636591724,
                -13.317181866093922
              ],
              [
                -48.60009559259122,
                -13.294729484172665
              ],
              [
                -48.58912571530538,
                -13.263485657359332
              ],
              [
                -48.59122619761757,
                -13.255976735748444
              ],
              [
                -48.58719022645087,
                -13.254319216297018
              ],
              [
                -48.59319121291604,
                -13.249615255368756
              ],
              [
                -48.59748968703124,
                -13.246246298918521
              ],
              [
                -48.60012215122162,
                -13.238197332300272
              ],
              [
                -48.593320237820066,
                -13.23506884852428
              ],
              [
                -48.58568780653783,
                -13.225323902080726
              ],
              [
                -48.582862336562556,
                -13.221717913834569
              ],
              [
                -48.58302032304738,
                -13.216823414031008
              ],
              [
                -48.58323683033183,
                -13.210097456782007
              ],
              [
                -48.58207935481566,
                -13.209179984046232
              ],
              [
                -48.57929636302169,
                -13.206974467894053
              ],
              [
                -48.580042888719746,
                -13.190952047635633
              ],
              [
                -48.57748394423387,
                -13.187036577641999
              ],
              [
                -48.583266386646926,
                -13.180458133042237
              ],
              [
                -48.57749193775064,
                -13.173718171726172
              ],
              [
                -48.58490889602426,
                -13.168059218651655
              ],
              [
                -48.58679089317059,
                -13.166622223056578
              ],
              [
                -48.58601640419864,
                -13.158644755316352
              ],
              [
                -48.59209636342663,
                -13.158232259992602
              ],
              [
                -48.59631633181755,
                -13.15219830962561
              ],
              [
                -48.58466243154903,
                -13.143361367358404
              ],
              [
                -48.581778943461565,
                -13.141175358965917
              ],
              [
                -48.581349982991135,
                -13.127993939601163
              ],
              [
                -48.57821603519294,
                -13.126160423780199
              ],
              [
                -48.58365100972405,
                -13.110207550219274
              ],
              [
                -48.58520500986892,
                -13.10564558394334
              ],
              [
                -48.58907747376218,
                -13.105926573882705
              ],
              [
                -48.59618391551892,
                -13.106441575952632
              ],
              [
                -48.59077796687242,
                -13.101315600133624
              ],
              [
                -48.588484484233135,
                -13.099141638694649
              ],
              [
                -48.59676692263234,
                -13.085076717156921
              ],
              [
                -48.59783041667404,
                -13.083271241283208
              ],
              [
                -48.5976359551251,
                -13.082722225096491
              ],
              [
                -48.596020468457546,
                -13.078177235379135
              ],
              [
                -48.59994943477076,
                -13.07797875614467
              ],
              [
                -48.60232391983146,
                -13.077858240841175
              ],
              [
                -48.602204901318714,
                -13.074681765817594
              ],
              [
                -48.60165244748644,
                -13.059894378524312
              ],
              [
                -48.61231787291166,
                -13.059358865564334
              ],
              [
                -48.6136013684451,
                -13.058023375371114
              ],
              [
                -48.61940730167838,
                -13.051977940755089
              ],
              [
                -48.62727625699635,
                -13.051726945449333
              ],
              [
                -48.6367052226075,
                -13.034238079418017
              ],
              [
                -48.6372942233639,
                -13.026093117032241
              ],
              [
                -48.63788372810711,
                -13.017948162654914
              ],
              [
                -48.647405157582796,
                -13.010403726853205
              ],
              [
                -48.65279860329634,
                -13.011607712023613
              ],
              [
                -48.65610458776403,
                -13.012346199352528
              ],
              [
                -48.65641610658419,
                -13.00822775345165
              ],
              [
                -48.6566595853386,
                -13.00501527225164
              ],
              [
                -48.663935055660495,
                -13.001086789962406
              ],
              [
                -48.667712545153066,
                -13.003189274272527
              ],
              [
                -48.67411998082523,
                -12.994843853803417
              ],
              [
                -48.682961926764605,
                -12.993460855566122
              ],
              [
                -48.684686898582605,
                -12.99805232118703
              ],
              [
                -48.686185397174,
                -13.00204380886515
              ],
              [
                -48.690919847315904,
                -13.002463343420866
              ],
              [
                -48.693533351915605,
                -12.997576345771726
              ],
              [
                -48.69448983874453,
                -12.995786879616828
              ],
              [
                -48.697076293999466,
                -12.99751134409597
              ],
              [
                -48.7070572342307,
                -13.004165326810945
              ],
              [
                -48.71195920330016,
                -12.999326857721268
              ],
              [
                -48.71735965763003,
                -13.001890883137738
              ],
              [
                -48.72477260836442,
                -12.990869429617282
              ],
              [
                -48.73029557888815,
                -12.989361479518697
              ],
              [
                -48.7339930676689,
                -12.975061048504445
              ],
              [
                -48.73213056505132,
                -12.968077086326259
              ],
              [
                -48.73026810442503,
                -12.961093627155194
              ],
              [
                -48.73371759701388,
                -12.9548471726775
              ],
              [
                -48.738760593480016,
                -12.94571421238664
              ],
              [
                -48.735855598746106,
                -12.937023280263878
              ],
              [
                -48.73420210695271,
                -12.932076318815929
              ],
              [
                -48.736125128245575,
                -12.920597856752085
              ],
              [
                -48.74843603929083,
                -12.917223912647053
              ],
              [
                -48.7540980267926,
                -12.907640457976232
              ],
              [
                -48.75856648481173,
                -12.906452973238938
              ],
              [
                -48.76011046976236,
                -12.90604249116797
              ],
              [
                -48.76337145258631,
                -12.902202527598508
              ],
              [
                -48.77055240156047,
                -12.893747061997049
              ],
              [
                -48.78557431273279,
                -12.880155686875106
              ],
              [
                -48.785984792195485,
                -12.882701651983941
              ],
              [
                -48.786304300331736,
                -12.884681150184814
              ],
              [
                -48.79090778480585,
                -12.88380318389101
              ],
              [
                -48.79937773510866,
                -12.872966224051467
              ],
              [
                -48.80558668083233,
                -12.86951325816847
              ],
              [
                -48.805033663986634,
                -12.867234799289381
              ],
              [
                -48.804849169706614,
                -12.866474799332549
              ],
              [
                -48.80662568110841,
                -12.865942290341676
              ],
              [
                -48.81409961511449,
                -12.863700314630785
              ],
              [
                -48.812812648469084,
                -12.860487320877905
              ],
              [
                -48.812083147173254,
                -12.858665343092712
              ],
              [
                -48.817572119009846,
                -12.853115373007176
              ],
              [
                -48.820490125783024,
                -12.843877433754232
              ],
              [
                -48.82545705091551,
                -12.845081452127449
              ],
              [
                -48.829690523614474,
                -12.84224545840892
              ],
              [
                -48.83219551794674,
                -12.842600470545719
              ],
              [
                -48.84190742370161,
                -12.843976966833432
              ],
              [
                -48.84487641252197,
                -12.84027599195623
              ],
              [
                -48.84171195728289,
                -12.830034544312605
              ],
              [
                -48.847097945431706,
                -12.817809635256722
              ],
              [
                -48.84657745509724,
                -12.812182136615922
              ],
              [
                -48.84631848190168,
                -12.80937816624347
              ],
              [
                -48.84812545943612,
                -12.808639689473141
              ],
              [
                -48.85631388294873,
                -12.805295704218558
              ],
              [
                -48.857052879850684,
                -12.804994228171848
              ],
              [
                -48.86212832689962,
                -12.809268218129246
              ],
              [
                -48.86240783590032,
                -12.80455621557085
              ],
              [
                -48.869439307455025,
                -12.80397723056099
              ],
              [
                -48.87356774494552,
                -12.806954240376152
              ],
              [
                -48.86812077467877,
                -12.815454191675945
              ],
              [
                -48.87563773056527,
                -12.81486520153995
              ],
              [
                -48.87925370304571,
                -12.81836418652967
              ],
              [
                -48.93351766711346,
                -12.896661821756581
              ],
              [
                -48.97517575095268,
                -12.956770514209138
              ],
              [
                -48.97745621347867,
                -12.952291569536838
              ],
              [
                -48.979736705002836,
                -12.947812580865072
              ],
              [
                -48.987298654013586,
                -12.943008125927195
              ],
              [
                -49.00443256703798,
                -12.932121713685927
              ],
              [
                -49.00680057418833,
                -12.924613262394281
              ],
              [
                -49.009430550792636,
                -12.916275331266966
              ],
              [
                -49.020353476059206,
                -12.911628860049522
              ],
              [
                -49.02406845539693,
                -12.906177392310404
              ],
              [
                -49.03309988440599,
                -12.903031919365766
              ],
              [
                -49.04693227361842,
                -12.911018415147222
              ],
              [
                -49.0495912287849,
                -12.912553889879591
              ],
              [
                -49.05145922748506,
                -12.910343413588611
              ],
              [
                -49.05332673818979,
                -12.90813242629937
              ],
              [
                -49.05569369807702,
                -12.907659419963887
              ],
              [
                -49.07762703653479,
                -12.903279002701002
              ],
              [
                -49.0847525056909,
                -12.894032570229843
              ],
              [
                -49.082136051940054,
                -12.892064562028516
              ],
              [
                -49.08723598100151,
                -12.891096092092099
              ],
              [
                -49.08073855192699,
                -12.883801117674773
              ],
              [
                -49.08048605033344,
                -12.883517608863727
              ],
              [
                -49.080606063655246,
                -12.883385118818474
              ],
              [
                -49.08485254462609,
                -12.878715641494303
              ],
              [
                -49.07704608215859,
                -12.872164169679117
              ],
              [
                -49.07343365379755,
                -12.869132161879406
              ],
              [
                -49.070154662944304,
                -12.859179242031471
              ],
              [
                -49.07187915779067,
                -12.85232627492443
              ],
              [
                -49.06706770564146,
                -12.846911310707325
              ],
              [
                -49.08265308783748,
                -12.845045812383102
              ],
              [
                -49.09626752520795,
                -12.835566389094723
              ],
              [
                -49.101016953154435,
                -12.835622899710131
              ],
              [
                -49.10576743409591,
                -12.835679407328012
              ],
              [
                -49.10968293936643,
                -12.820746000856113
              ],
              [
                -49.11458993565944,
                -12.802028142599433
              ],
              [
                -49.118631395011306,
                -12.789930701220555
              ],
              [
                -49.136488270673766,
                -12.80047214597299
              ],
              [
                -49.14445818604646,
                -12.817403597943626
              ],
              [
                -49.14548666147653,
                -12.81958905423146
              ],
              [
                -49.14724162145926,
                -12.820017581725981
              ],
              [
                -49.15091611111358,
                -12.820913573769488
              ],
              [
                -49.159516021297044,
                -12.831155032918927
              ],
              [
                -49.16370550494905,
                -12.836143984572207
              ],
              [
                -49.170188952194344,
                -12.840016496417444
              ],
              [
                -49.18462883502711,
                -12.848639956616045
              ],
              [
                -49.19265375834543,
                -12.856694428336114
              ],
              [
                -49.19348221377619,
                -12.859167418687939
              ],
              [
                -49.196047204048774,
                -12.86682285954332
              ],
              [
                -49.20837809994879,
                -12.873077845753759
              ],
              [
                -49.210571087913365,
                -12.8779283439258
              ],
              [
                -49.21714604395612,
                -12.876939856576271
              ],
              [
                -49.22216100138241,
                -12.876185375279022
              ],
              [
                -49.22491846048519,
                -12.876795364452983
              ],
              [
                -49.22812891599212,
                -12.877505358821807
              ],
              [
                -49.23100091424266,
                -12.883262861011842
              ],
              [
                -49.23696284772105,
                -12.883541346456552
              ],
              [
                -49.23492387318403,
                -12.89323427410043
              ],
              [
                -49.24906724040455,
                -12.902354750242816
              ],
              [
                -49.24754224156379,
                -12.909414700695
              ],
              [
                -49.246017745712,
                -12.916475180148991
              ],
              [
                -49.256800139392325,
                -12.93465808042866
              ],
              [
                -49.257370605299485,
                -12.941371035414257
              ],
              [
                -49.259888081624176,
                -12.940609536974605
              ],
              [
                -49.262683074000556,
                -12.939764568475617
              ],
              [
                -49.27893391260545,
                -12.972566393528844
              ],
              [
                -49.27930240007394,
                -12.976397868506295
              ],
              [
                -49.2773999172173,
                -12.978226354952643
              ],
              [
                -49.27526589771706,
                -12.980277359749044
              ],
              [
                -49.278405889356044,
                -12.98858778812938
              ],
              [
                -49.28343632471259,
                -12.991229816741214
              ],
              [
                -49.30041165360258,
                -13.023464145656266
              ],
              [
                -49.32176393548651,
                -13.051654020109513
              ],
              [
                -49.32938787433896,
                -13.058760011086484
              ],
              [
                -49.33701229018402,
                -13.065866484083825
              ],
              [
                -49.35393761317901,
                -13.102559795568776
              ],
              [
                -49.353522132485004,
                -13.110314223256681
              ],
              [
                -49.348316638973756,
                -13.114118220948
              ],
              [
                -49.3443766875653,
                -13.116997682004738
              ],
              [
                -49.34149019288976,
                -13.126776611077933
              ],
              [
                -49.352267584557566,
                -13.143563024245513
              ],
              [
                -49.35332006786061,
                -13.148456507564926
              ],
              [
                -49.35437356015135,
                -13.153350492887832
              ],
              [
                -49.35134253320643,
                -13.16050795147886
              ],
              [
                -49.34440305221183,
                -13.176892827532857
              ],
              [
                -49.347088024839195,
                -13.18642728363515
              ],
              [
                -49.34721603705893,
                -13.186880792310292
              ],
              [
                -49.34662155525812,
                -13.189269288879906
              ],
              [
                -49.343769546201734,
                -13.20072920264966
              ],
              [
                -49.34483754879749,
                -13.20370171481319
              ],
              [
                -49.34788199658801,
                -13.212170661556689
              ],
              [
                -49.34235298703984,
                -13.227886536860584
              ],
              [
                -49.33949951897307,
                -13.235996512449754
              ],
              [
                -49.34055897611572,
                -13.245347439856484
              ],
              [
                -49.34161847724368,
                -13.254698912274494
              ],
              [
                -49.36903723307815,
                -13.27416933974513
              ],
              [
                -49.3707222170357,
                -13.273243355852797
              ],
              [
                -49.456555635017956,
                -13.226060764664128
              ],
              [
                -49.608157152586905,
                -13.142727469483116
              ],
              [
                -49.81514176469907,
                -13.026272425432092
              ],
              [
                -49.81757723552142,
                -13.024570444098506
              ],
              [
                -49.82001374233751,
                -13.022867963768892
              ],
              [
                -49.827496684755495,
                -13.018734018648999
              ],
              [
                -49.90842164079913,
                -12.97402835640656
              ],
              [
                -49.91066612057571,
                -12.969796879813416
              ],
              [
                -49.912912108340564,
                -12.96556492122562
              ],
              [
                -49.9389634370533,
                -12.956499515278656
              ],
              [
                -49.94563437073022,
                -12.957936027696604
              ],
              [
                -49.9523063514045,
                -12.959372508120493
              ],
              [
                -49.96356726792383,
                -12.950656571892479
              ],
              [
                -49.97807369272255,
                -12.939429166176641
              ],
              [
                -49.9836946293855,
                -12.942486653777866
              ],
              [
                -49.98931606904559,
                -12.945544663383643
              ],
              [
                -50.00054799078168,
                -12.935170218296097
              ],
              [
                -50.00255000614928,
                -12.9333212480028
              ],
              [
                -50.010018939519064,
                -12.931058765354747
              ],
              [
                -50.017489882882536,
                -12.928795813710583
              ],
              [
                -50.023861837153916,
                -12.930632786391325
              ],
              [
                -50.03846221693607,
                -12.934840277977802
              ],
              [
                -50.04399617988522,
                -12.93306530630869
              ],
              [
                -50.046396683960346,
                -12.928594830255745
              ],
              [
                -50.04987365557884,
                -12.922121374174083
              ],
              [
                -50.06227308822883,
                -12.918772924977219
              ],
              [
                -50.07163200806852,
                -12.916244931020369
              ],
              [
                -50.07503201129912,
                -12.910879005640593
              ],
              [
                -50.07843196752857,
                -12.905513013260471
              ],
              [
                -50.10348477243274,
                -12.907617051382891
              ],
              [
                -50.104906765384946,
                -12.908446049210891
              ],
              [
                -50.10925372755827,
                -12.910979026580776
              ],
              [
                -50.125771123357254,
                -12.905008601957299
              ],
              [
                -50.12649361345088,
                -12.904747119643497
              ],
              [
                -50.12735511019602,
                -12.904663101582448
              ],
              [
                -50.14937244039414,
                -12.902505162197418
              ],
              [
                -50.15352194188068,
                -12.898948665018962
              ],
              [
                -50.160187874506136,
                -12.893234200109102
              ],
              [
                -50.168215805823415,
                -12.895513731188249
              ],
              [
                -50.17251879269195,
                -12.896735701768865
              ],
              [
                -50.17502177866664,
                -12.893040248537947
              ],
              [
                -50.17604474575209,
                -12.89152975666185
              ],
              [
                -50.17824476181154,
                -12.891868758542527
              ],
              [
                -50.18262370509259,
                -12.892544747280022
              ],
              [
                -50.18296119783455,
                -12.89634522378885
              ],
              [
                -50.19532514890865,
                -12.890223774858969
              ],
              [
                -50.199298615078234,
                -12.884217310942049
              ],
              [
                -50.19954809889383,
                -12.883841316452443
              ],
              [
                -50.20002158317756,
                -12.883954822632413
              ],
              [
                -50.20426356388133,
                -12.884973315237103
              ],
              [
                -50.20669156397214,
                -12.881530839464874
              ],
              [
                -50.21070803127025,
                -12.87583639888686
              ],
              [
                -50.21229750291057,
                -12.877280885596445
              ],
              [
                -50.21287849766082,
                -12.877809393660035
              ],
              [
                -50.224657442242595,
                -12.873873437592916
              ],
              [
                -50.2250324449857,
                -12.866249951477414
              ],
              [
                -50.22529093935329,
                -12.861013482939779
              ],
              [
                -50.23131791993659,
                -12.858026528779183
              ],
              [
                -50.23107590844018,
                -12.853677054472616
              ],
              [
                -50.235536859191406,
                -12.857152017824683
              ],
              [
                -50.235658862118036,
                -12.857247025506387
              ],
              [
                -50.2388158601673,
                -12.854727575854765
              ],
              [
                -50.24187585279967,
                -12.852285571607522
              ],
              [
                -50.245006792922204,
                -12.85265659089297
              ],
              [
                -50.250395277831906,
                -12.853294577551878
              ],
              [
                -50.25054227615473,
                -12.850804588598086
              ],
              [
                -50.250626781056575,
                -12.849377617648472
              ],
              [
                -50.25879572885204,
                -12.848042618979441
              ],
              [
                -50.26306670742293,
                -12.84734562512624
              ],
              [
                -50.26279967554093,
                -12.846703157229555
              ],
              [
                -50.2618251759118,
                -12.84436265453306
              ],
              [
                -50.27121163193984,
                -12.840631694207909
              ],
              [
                -50.273674604373625,
                -12.843065179929663
              ],
              [
                -50.274359110101656,
                -12.843741691350427
              ],
              [
                -50.282849559397974,
                -12.840230211278463
              ],
              [
                -50.28418204358166,
                -12.843019674104864
              ],
              [
                -50.28870902047346,
                -12.835998724702872
              ],
              [
                -50.29218396253719,
                -12.839492741282601
              ],
              [
                -50.30137394411827,
                -12.819911330374493
              ],
              [
                -50.30526542834148,
                -12.821579851764191
              ],
              [
                -50.30726989036399,
                -12.81769685568027
              ],
              [
                -50.30613045108151,
                -12.804528459672538
              ],
              [
                -50.310817414685225,
                -12.798122499131457
              ],
              [
                -50.30459097487301,
                -12.784480037139101
              ],
              [
                -50.30292648163139,
                -12.780833057525424
              ],
              [
                -50.30398401626429,
                -12.773761096522968
              ],
              [
                -50.305041482892555,
                -12.766688156530494
              ],
              [
                -50.29897654535496,
                -12.758424189072226
              ],
              [
                -50.29504161492954,
                -12.753062699143056
              ],
              [
                -50.29662858239253,
                -12.75123373097843
              ],
              [
                -50.30179457660276,
                -12.745279777760073
              ],
              [
                -50.29621411439655,
                -12.742168752571246
              ],
              [
                -50.29792910350277,
                -12.739432782656317
              ],
              [
                -50.2918216638665,
                -12.736510799535369
              ],
              [
                -50.288293198964254,
                -12.734822796934308
              ],
              [
                -50.28377025239918,
                -12.717760886020066
              ],
              [
                -50.287765216069,
                -12.71476093142994
              ],
              [
                -50.298515649603495,
                -12.706688472416078
              ],
              [
                -50.295131152238184,
                -12.704648496031147
              ],
              [
                -50.293823215418044,
                -12.695227040157116
              ],
              [
                -50.29964218615105,
                -12.680465610982006
              ],
              [
                -50.294875740054,
                -12.677254135797702
              ],
              [
                -50.2952632212723,
                -12.684069590576202
              ],
              [
                -50.29226671444018,
                -12.688084069218217
              ],
              [
                -50.290072270312656,
                -12.68258059910236
              ],
              [
                -50.28554778447652,
                -12.681631613752911
              ],
              [
                -50.28349931047487,
                -12.684590593854532
              ],
              [
                -50.28074181093276,
                -12.688574038066804
              ],
              [
                -50.277927828883996,
                -12.677640611102955
              ],
              [
                -50.27246685435669,
                -12.682778082341176
              ],
              [
                -50.2663549379406,
                -12.682029551207448
              ],
              [
                -50.27007042088153,
                -12.67718411040929
              ],
              [
                -50.263730486782976,
                -12.663471180013609
              ],
              [
                -50.266542957833956,
                -12.658950211896057
              ],
              [
                -50.269354447890144,
                -12.654429744773712
              ],
              [
                -50.259101021591135,
                -12.64539175884473
              ],
              [
                -50.26196201649959,
                -12.63729181373911
              ],
              [
                -50.256653592623884,
                -12.634533341188957
              ],
              [
                -50.254708111333734,
                -12.624587356315153
              ],
              [
                -50.248127160555015,
                -12.630646344532149
              ],
              [
                -50.246882658088,
                -12.629891828647603
              ],
              [
                -50.236514728835914,
                -12.623608868269395
              ],
              [
                -50.24134422566307,
                -12.608859932554154
              ],
              [
                -50.24524471843404,
                -12.596945025496732
              ],
              [
                -50.234495352958554,
                -12.577387112599242
              ],
              [
                -50.2316218258687,
                -12.581074061232243
              ],
              [
                -50.227938361981366,
                -12.585800065715114
              ],
              [
                -50.20788252246087,
                -12.572608590845068
              ],
              [
                -50.2035940845271,
                -12.563771645034524
              ],
              [
                -50.20296359818993,
                -12.563778137947097
              ],
              [
                -50.19271064202295,
                -12.563884625259876
              ],
              [
                -50.19745366214667,
                -12.555030673623268
              ],
              [
                -50.20562158408329,
                -12.553589221282467
              ],
              [
                -50.205908588109445,
                -12.559870185680838
              ],
              [
                -50.21158454300372,
                -12.561046651572639
              ],
              [
                -50.21231152205037,
                -12.555584203287166
              ],
              [
                -50.213323545678115,
                -12.54797924644561
              ],
              [
                -50.222489485066525,
                -12.54315976462602
              ],
              [
                -50.22926343722653,
                -12.531938350572501
              ],
              [
                -50.224346491373595,
                -12.524558871617074
              ],
              [
                -50.2194295345138,
                -12.517179404678854
              ],
              [
                -50.21557460016029,
                -12.485134608440807
              ],
              [
                -50.204547221753124,
                -12.475296135216375
              ],
              [
                -50.204794222828525,
                -12.465432707826995
              ],
              [
                -50.19614528954393,
                -12.458064199774245
              ],
              [
                -50.18379240855566,
                -12.454879734190836
              ],
              [
                -50.179239462690255,
                -12.439449790121248
              ],
              [
                -50.16499058495154,
                -12.43674580376709
              ],
              [
                -50.15901112980588,
                -12.429415314009447
              ],
              [
                -50.15776215684792,
                -12.41195190590751
              ],
              [
                -50.14221580713353,
                -12.395510480613217
              ],
              [
                -50.17093959048963,
                -12.407641976776437
              ],
              [
                -50.170795560651015,
                -12.417560923907661
              ],
              [
                -50.17076654441972,
                -12.419581904526495
              ],
              [
                -50.17463003341927,
                -12.423309389992003
              ],
              [
                -50.17740901688812,
                -12.422682390087358
              ],
              [
                -50.1881814441897,
                -12.420250917471954
              ],
              [
                -50.18857491418938,
                -12.413264487284689
              ],
              [
                -50.19612239584623,
                -12.412919486651363
              ],
              [
                -50.19749033985953,
                -12.418694443537808
              ],
              [
                -50.1842964524872,
                -12.425230374156829
              ],
              [
                -50.18375195339206,
                -12.42940786784429
              ],
              [
                -50.18368992404086,
                -12.429882865079769
              ],
              [
                -50.184267943633564,
                -12.429954841629668
              ],
              [
                -50.19821931726482,
                -12.43169486589273
              ],
              [
                -50.21187272255033,
                -12.436698870396762
              ],
              [
                -50.21527119907993,
                -12.440964848120581
              ],
              [
                -50.20771325923376,
                -12.442041818608187
              ],
              [
                -50.207148750566155,
                -12.448385799152176
              ],
              [
                -50.22209762042921,
                -12.445689828945271
              ],
              [
                -50.22973206140396,
                -12.455315793694226
              ],
              [
                -50.252404871752745,
                -12.461592283099636
              ],
              [
                -50.25828581600051,
                -12.469880768499944
              ],
              [
                -50.26416726123462,
                -12.478170219918422
              ],
              [
                -50.27684265108723,
                -12.478942719617706
              ],
              [
                -50.28302162071197,
                -12.47931971275844
              ],
              [
                -50.286146569428105,
                -12.483487202667474
              ],
              [
                -50.28927256913278,
                -12.487655686578718
              ],
              [
                -50.29639751412366,
                -12.490733177225296
              ],
              [
                -50.31172638833598,
                -12.497353687739004
              ],
              [
                -50.323929259367326,
                -12.507641631642576
              ],
              [
                -50.32020379956356,
                -12.508428616057845
              ],
              [
                -50.318353306576135,
                -12.50881962079958
              ],
              [
                -50.31367985094596,
                -12.52111704645015
              ],
              [
                -50.32699925572461,
                -12.51572910365949
              ],
              [
                -50.32978020409922,
                -12.514604125559144
              ],
              [
                -50.33406716312872,
                -12.51997957473379
              ],
              [
                -50.33363266737887,
                -12.522856070979415
              ],
              [
                -50.33169468554734,
                -12.53569351121802
              ],
              [
                -50.3431665712025,
                -12.533099004796293
              ],
              [
                -50.34437506673913,
                -12.5328260213315
              ],
              [
                -50.34714756932662,
                -12.534513542554626
              ],
              [
                -50.346766049909924,
                -12.53696601725955
              ],
              [
                -50.345875567605596,
                -12.542686972914304
              ],
              [
                -50.35152799286248,
                -12.54208246171112
              ],
              [
                -50.359260939130856,
                -12.541254996220765
              ],
              [
                -50.36546038071721,
                -12.545645482128137
              ],
              [
                -50.35891093633689,
                -12.552459445268234
              ],
              [
                -50.358066939055334,
                -12.553337914969383
              ],
              [
                -50.359619933272775,
                -12.55351295858895
              ],
              [
                -50.366122384943765,
                -12.554244958376643
              ],
              [
                -50.36931534013455,
                -12.559564424114459
              ],
              [
                -50.36764185434881,
                -12.565425894709122
              ],
              [
                -50.36604334345728,
                -12.57102632888316
              ],
              [
                -50.370303327829696,
                -12.5751448111041
              ],
              [
                -50.37461279688021,
                -12.575778809790108
              ],
              [
                -50.3817997329349,
                -12.576836353938859
              ],
              [
                -50.38684717709186,
                -12.589824251201726
              ],
              [
                -50.3979925606467,
                -12.6019627304524
              ],
              [
                -50.39407308844284,
                -12.604355689585859
              ],
              [
                -50.39093963626855,
                -12.606268202705568
              ],
              [
                -50.39195212165864,
                -12.611514667266055
              ],
              [
                -50.40067604438247,
                -12.613134660917018
              ],
              [
                -50.406706984878035,
                -12.614255172823865
              ],
              [
                -50.41818589279755,
                -12.62418114002332
              ],
              [
                -50.415777381545546,
                -12.631661088473528
              ],
              [
                -50.41124092246485,
                -12.645752985669173
              ],
              [
                -50.41804834364938,
                -12.651208959876389
              ],
              [
                -50.42460931905429,
                -12.644416040593068
              ],
              [
                -50.42690577224636,
                -12.642038056645669
              ],
              [
                -50.44040466068048,
                -12.652848513458364
              ],
              [
                -50.43774670065807,
                -12.657223476733202
              ],
              [
                -50.43356573687353,
                -12.65130699547679
              ],
              [
                -50.429527746752164,
                -12.651845507687671
              ],
              [
                -50.42838378493493,
                -12.651997480917709
              ],
              [
                -50.4328412275352,
                -12.656849958533442
              ],
              [
                -50.43653919918804,
                -12.660875460460076
              ],
              [
                -50.43508571354055,
                -12.663379919131076
              ],
              [
                -50.4319367003001,
                -12.668806917748064
              ],
              [
                -50.43543467428117,
                -12.675767852087487
              ],
              [
                -50.436378678019814,
                -12.677646349275888
              ],
              [
                -50.43555214809052,
                -12.679948833126755
              ],
              [
                -50.43209666774109,
                -12.689574757976253
              ],
              [
                -50.434908627408085,
                -12.693888769840166
              ],
              [
                -50.44080260885503,
                -12.697323244791654
              ],
              [
                -50.44334958180182,
                -12.696892763482296
              ],
              [
                -50.46131646247937,
                -12.693854780684218
              ],
              [
                -50.46393141699225,
                -12.70307424209857
              ],
              [
                -50.47759729178788,
                -12.709527217548569
              ],
              [
                -50.47832331229127,
                -12.71586320474698
              ],
              [
                -50.47071534315011,
                -12.719442185065073
              ],
              [
                -50.46765585820132,
                -12.720882173917234
              ],
              [
                -50.467842332802284,
                -12.735667568495623
              ],
              [
                -50.47223128665518,
                -12.73666007345887
              ],
              [
                -50.48192523824206,
                -12.738852093802238
              ],
              [
                -50.48554668212735,
                -12.748148554597893
              ],
              [
                -50.48273870075058,
                -12.752174519589605
              ],
              [
                -50.47572675386146,
                -12.75197348578328
              ],
              [
                -50.471460791568454,
                -12.75185100521066
              ],
              [
                -50.46806881509686,
                -12.754976469179061
              ],
              [
                -50.477012739458196,
                -12.759857449422162
              ],
              [
                -50.475295718280975,
                -12.771665877292158
              ],
              [
                -50.48355814693342,
                -12.775262882498982
              ],
              [
                -50.48420862763096,
                -12.77554639104157
              ],
              [
                -50.49340855459856,
                -12.785233348254359
              ],
              [
                -50.48764606942556,
                -12.802011231869509
              ],
              [
                -50.48437410169645,
                -12.811536675746698
              ],
              [
                -50.48916054753972,
                -12.813129164137925
              ],
              [
                -50.49068252940791,
                -12.813635164947044
              ],
              [
                -50.49371202361625,
                -12.818951157812588
              ],
              [
                -50.49277200948215,
                -12.821809638448034
              ],
              [
                -50.49059951479495,
                -12.828413573330883
              ],
              [
                -50.49522545776692,
                -12.841854015175793
              ],
              [
                -50.5106062994593,
                -12.86027795605533
              ],
              [
                -50.51164683452079,
                -12.851998988420757
              ],
              [
                -50.51237382745172,
                -12.846213026526875
              ],
              [
                -50.51262483014838,
                -12.844218028943233
              ],
              [
                -50.51987427532299,
                -12.835970582774994
              ],
              [
                -50.53151917455589,
                -12.843201093902717
              ],
              [
                -50.56648694705441,
                -12.842999140926786
              ],
              [
                -50.59737920901442,
                -12.826352268397363
              ],
              [
                -50.59855070524335,
                -12.823057312520707
              ],
              [
                -50.60018421238021,
                -12.81846130558976
              ],
              [
                -50.57905639571708,
                -12.801751889384628
              ],
              [
                -50.602843247510066,
                -12.800040444454082
              ],
              [
                -50.60983615116294,
                -12.812340868762409
              ],
              [
                -50.6130416145334,
                -12.817979830245392
              ],
              [
                -50.61765009790476,
                -12.82117985554558
              ],
              [
                -50.6222630352567,
                -12.8193123457088
              ],
              [
                -50.62610353194718,
                -12.81145439147287
              ],
              [
                -50.6248905799379,
                -12.799597452580688
              ],
              [
                -50.61807713755692,
                -12.787506519431636
              ],
              [
                -50.61129670780876,
                -12.775474069059275
              ],
              [
                -50.61437569765362,
                -12.771229604670717
              ],
              [
                -50.622687641237185,
                -12.773611587743991
              ],
              [
                -50.62978856435835,
                -12.775646084669479
              ],
              [
                -50.635523556601065,
                -12.771437124609044
              ],
              [
                -50.62674161465582,
                -12.760471668961863
              ],
              [
                -50.62281368156684,
                -12.733880320663888
              ],
              [
                -50.63674160067808,
                -12.719361444305438
              ],
              [
                -50.63333564567109,
                -12.712027474914096
              ],
              [
                -50.629658701679716,
                -12.704109023297281
              ],
              [
                -50.63273768827798,
                -12.701124042970996
              ],
              [
                -50.63765763857101,
                -12.701410523742538
              ],
              [
                -50.644654090378694,
                -12.70181702737876
              ],
              [
                -50.65429603602538,
                -12.678758184774445
              ],
              [
                -50.65440905218307,
                -12.673074710285874
              ],
              [
                -50.64900909569018,
                -12.666932234997187
              ],
              [
                -50.64587910896424,
                -12.6633717743278
              ],
              [
                -50.646460162925365,
                -12.650698333195468
              ],
              [
                -50.65573709868782,
                -12.645570373158977
              ],
              [
                -50.68208836905503,
                -12.646651391755961
              ],
              [
                -50.69922028391777,
                -12.647353916865246
              ],
              [
                -50.70059977292143,
                -12.625025046227991
              ],
              [
                -50.70067679553298,
                -12.623781573211327
              ],
              [
                -50.70464276623963,
                -12.613015632247524
              ],
              [
                -50.70602176064552,
                -12.609279134171787
              ],
              [
                -50.702172827130866,
                -12.598289688273468
              ],
              [
                -50.69395787502609,
                -12.592253722723154
              ],
              [
                -50.68060347470913,
                -12.597090674540478
              ],
              [
                -50.67340500806113,
                -12.599698137024637
              ],
              [
                -50.667513062548686,
                -12.595587145787553
              ],
              [
                -50.668960601504004,
                -12.580343779374214
              ],
              [
                -50.675921032577406,
                -12.575622790147289
              ],
              [
                -50.6860074913814,
                -12.56878134987799
              ],
              [
                -50.68156552490558,
                -12.560234400604894
              ],
              [
                -50.67712459641092,
                -12.551687912351134
              ],
              [
                -50.6645451977445,
                -12.536794487547763
              ],
              [
                -50.65912829054867,
                -12.505666657617063
              ],
              [
                -50.65233985519563,
                -12.493678198612264
              ],
              [
                -50.64555241781458,
                -12.48169025664777
              ],
              [
                -50.64606791193062,
                -12.47943029302242
              ],
              [
                -50.65085493697174,
                -12.458446409225479
              ],
              [
                -50.64179497553341,
                -12.452082455400284
              ],
              [
                -50.63523352355013,
                -12.454527896924759
              ],
              [
                -50.62652908995376,
                -12.45777088745829
              ],
              [
                -50.62280165097677,
                -12.453352399747738
              ],
              [
                -50.617789196491536,
                -12.428570524855754
              ],
              [
                -50.62536867254704,
                -12.421134577695987
              ],
              [
                -50.63187210870348,
                -12.414753144894293
              ],
              [
                -50.6295926697268,
                -12.401782197089048
              ],
              [
                -50.62805317510901,
                -12.392997727297487
              ],
              [
                -50.629660716094,
                -12.373265373522434
              ],
              [
                -50.64358064378791,
                -12.346369529917286
              ],
              [
                -50.64088516847858,
                -12.337063598174845
              ],
              [
                -50.637353212220866,
                -12.32487214211209
              ],
              [
                -50.642616188441586,
                -12.319070159036261
              ],
              [
                -50.64844418536111,
                -12.29866832006379
              ],
              [
                -50.629926857817026,
                -12.28135289296731
              ],
              [
                -50.630899343816715,
                -12.274786912110327
              ],
              [
                -50.63187237080739,
                -12.268220446260933
              ],
              [
                -50.64948675049933,
                -12.248706104775168
              ],
              [
                -50.6438363396507,
                -12.22230423157505
              ],
              [
                -50.64939981261214,
                -12.220045745250781
              ],
              [
                -50.65496376457426,
                -12.217787271925447
              ],
              [
                -50.67820907485467,
                -12.218432286327952
              ],
              [
                -50.686406056900644,
                -12.20173489469428
              ],
              [
                -50.67899662312911,
                -12.18817848511305
              ],
              [
                -50.67871363007783,
                -12.187660966534878
              ],
              [
                -50.67869862896383,
                -12.184232980610341
              ],
              [
                -50.67857017023264,
                -12.15491565281379
              ],
              [
                -50.67616069812329,
                -12.150622670834434
              ],
              [
                -50.67040177860244,
                -12.14036323470031
              ],
              [
                -50.67409474877737,
                -12.128275813136582
              ],
              [
                -50.67556074805846,
                -12.123480321274803
              ],
              [
                -50.670065841276525,
                -12.096647465164239
              ],
              [
                -50.66681039471206,
                -12.080750054698857
              ],
              [
                -50.66698240930479,
                -12.072445587379066
              ],
              [
                -50.66715489588304,
                -12.06414164106571
              ],
              [
                -50.6794538256431,
                -12.051550258287197
              ],
              [
                -50.68612928306394,
                -12.044716274248541
              ],
              [
                -50.686368316214775,
                -12.039965834186637
              ],
              [
                -50.6866073133611,
                -12.035216329122091
              ],
              [
                -50.68530632067899,
                -12.032943857731125
              ],
              [
                -50.67737940181182,
                -12.01909642941121
              ],
              [
                -50.682195858647766,
                -12.012374965756088
              ],
              [
                -50.683601876594075,
                -12.010412465994822
              ],
              [
                -50.6833573691631,
                -12.001502031452794
              ],
              [
                -50.68250990964324,
                -11.99409956151453
              ],
              [
                -50.67635644625508,
                -11.985347125629747
              ],
              [
                -50.66545604124956,
                -11.9750346408998
              ],
              [
                -50.66407908150219,
                -11.973731643991824
              ],
              [
                -50.65636465514165,
                -11.966434204518995
              ],
              [
                -50.65514268525184,
                -11.95544173916723
              ],
              [
                -50.654268677649405,
                -11.94756828090471
              ],
              [
                -50.66093617263139,
                -11.937325356984616
              ],
              [
                -50.66742810645204,
                -11.92735143927284
              ],
              [
                -50.663940139821044,
                -11.922008444668057
              ],
              [
                -50.66333865174766,
                -11.921086953876442
              ],
              [
                -50.65864618997285,
                -11.913902481691844
              ],
              [
                -50.655488714384205,
                -11.911921497772491
              ],
              [
                -50.64010434807216,
                -11.902272030419569
              ],
              [
                -50.639463375641284,
                -11.893212558264098
              ],
              [
                -50.6388218822042,
                -11.884151638128131
              ],
              [
                -50.65929225749633,
                -11.866806750066576
              ],
              [
                -50.67054719746418,
                -11.864681797872464
              ],
              [
                -50.682182082782624,
                -11.862485322680838
              ],
              [
                -50.68583857737979,
                -11.858579359413158
              ],
              [
                -50.68738661145099,
                -11.828343532655033
              ],
              [
                -50.6875766031252,
                -11.824626027839063
              ],
              [
                -50.69384561078242,
                -11.813842617342038
              ],
              [
                -50.69384760677439,
                -11.813838597367782
              ],
              [
                -50.700119548407635,
                -11.803051666893422
              ],
              [
                -50.70165207127255,
                -11.79562625104077
              ],
              [
                -50.70739303943226,
                -11.767819872402619
              ],
              [
                -50.71351054620345,
                -11.756997482893857
              ],
              [
                -50.71888600399373,
                -11.745190554753108
              ],
              [
                -50.7215745048823,
                -11.73928456119889
              ],
              [
                -50.72134850100578,
                -11.735290081311087
              ],
              [
                -50.72112299311993,
                -11.731297640414825
              ],
              [
                -50.689695303352394,
                -11.699653240241531
              ],
              [
                -50.68165634718211,
                -11.69596125845147
              ],
              [
                -50.67361690501984,
                -11.692269775669098
              ],
              [
                -50.66305153294121,
                -11.672069383052019
              ],
              [
                -50.66415607247256,
                -11.626198125660217
              ],
              [
                -50.661258613923536,
                -11.621684647639627
              ],
              [
                -50.65836165136759,
                -11.61717166162342
              ],
              [
                -50.656060165014836,
                -11.600417274706384
              ],
              [
                -50.65711518928916,
                -11.596033301119444
              ],
              [
                -50.660806185296096,
                -11.590369856855387
              ],
              [
                -50.664456124500795,
                -11.584769861168025
              ],
              [
                -50.67530856606224,
                -11.58224189628634
              ],
              [
                -50.71601477121016,
                -11.577798481526415
              ],
              [
                -50.73970615701509,
                -11.538198242852104
              ],
              [
                -50.73435274776799,
                -11.511376916127283
              ],
              [
                -50.7366157262197,
                -11.50507291125201
              ],
              [
                -50.738878735667555,
                -11.498768458380782
              ],
              [
                -50.741593749845556,
                -11.454491733987945
              ],
              [
                -50.74018678854475,
                -11.444720787126165
              ],
              [
                -50.73878081722143,
                -11.43495034327906
              ],
              [
                -50.72693645261358,
                -11.411558440630394
              ],
              [
                -50.728042966674685,
                -11.38215411798559
              ],
              [
                -50.719917541868405,
                -11.37079369802099
              ],
              [
                -50.71179214104736,
                -11.359433237099449
              ],
              [
                -50.70528971026595,
                -11.341077334407007
              ],
              [
                -50.70559172364268,
                -11.331728359956573
              ],
              [
                -50.705778693270695,
                -11.325958431012026
              ],
              [
                -50.70287922798989,
                -11.319848442321168
              ],
              [
                -50.6982643038748,
                -11.31012249858833
              ],
              [
                -50.696351808986364,
                -11.304255047039032
              ],
              [
                -50.67873993971788,
                -11.294647570003878
              ],
              [
                -50.678510978586786,
                -11.294522545368565
              ],
              [
                -50.674113517701095,
                -11.289403563835538
              ],
              [
                -50.67408550395567,
                -11.289371604974978
              ],
              [
                -50.66966054331771,
                -11.284221093589666
              ],
              [
                -50.65798168173908,
                -11.247425792458328
              ],
              [
                -50.65395826766825,
                -11.217952958363512
              ],
              [
                -50.657255714408564,
                -11.210903524505625
              ],
              [
                -50.66129820514929,
                -11.202259570857988
              ],
              [
                -50.659573752682704,
                -11.188009157542233
              ],
              [
                -50.65944177231965,
                -11.186921648547736
              ],
              [
                -50.663454247443404,
                -11.158602321135287
              ],
              [
                -50.65863082196405,
                -11.130351484231824
              ],
              [
                -50.65101739045026,
                -11.126552492585134
              ],
              [
                -50.64340347194436,
                -11.122753508948673
              ],
              [
                -50.63657753837763,
                -11.100136617081827
              ],
              [
                -50.61627222331107,
                -11.083469186280677
              ],
              [
                -50.61263224643,
                -11.07525771688502
              ],
              [
                -50.60899280953391,
                -11.067047288501131
              ],
              [
                -50.610997307992996,
                -11.042564437699195
              ],
              [
                -50.61398030547409,
                -11.037078461538583
              ],
              [
                -50.61696326395383,
                -11.031592008380356
              ],
              [
                -50.61707981290001,
                -11.01055713510718
              ],
              [
                -50.61712683844844,
                -11.002016654139776
              ],
              [
                -50.61848479576712,
                -10.998535193101446
              ],
              [
                -50.624376299449224,
                -10.983426269412876
              ],
              [
                -50.621414328526974,
                -10.973853345968148
              ],
              [
                -50.619767330583564,
                -10.968529368093378
              ],
              [
                -50.62308483426287,
                -10.959920921358114
              ],
              [
                -50.6324842991483,
                -10.935528572130396
              ],
              [
                -50.62707136001903,
                -10.92666113611573
              ],
              [
                -50.62165938987138,
                -10.917794674120453
              ],
              [
                -50.60032107520674,
                -10.900227248907045
              ],
              [
                -50.59954859800318,
                -10.88270833051725
              ],
              [
                -50.60448758674592,
                -10.872061389946452
              ],
              [
                -50.61117054578823,
                -10.86808843752349
              ],
              [
                -50.618578495175605,
                -10.863684957627237
              ],
              [
                -50.621542500206544,
                -10.839004618911394
              ],
              [
                -50.61124709894101,
                -10.8211721906228
              ],
              [
                -50.611247630957095,
                -10.821158713701134
              ],
              [
                -50.611733630066475,
                -10.809912769163441
              ],
              [
                -50.60671265412566,
                -10.80427429837477
              ],
              [
                -50.60061170203329,
                -10.802723298486114
              ],
              [
                -50.592570769863585,
                -10.800678812640246
              ],
              [
                -50.589735833229966,
                -10.786042382908
              ],
              [
                -50.5701259937181,
                -10.752093545481213
              ],
              [
                -50.57431447549838,
                -10.740928642953303
              ],
              [
                -50.57729798336215,
                -10.732975175157259
              ],
              [
                -50.57694646960863,
                -10.727067199764699
              ],
              [
                -50.57646199210481,
                -10.718921771108354
              ],
              [
                -50.581986491410554,
                -10.709571338035186
              ],
              [
                -50.585406967877994,
                -10.703783340368664
              ],
              [
                -50.58628148683641,
                -10.694663424304375
              ],
              [
                -50.58715595178374,
                -10.685543462248834
              ],
              [
                -50.602360860671595,
                -10.673196552328907
              ],
              [
                -50.60294438157763,
                -10.661435124126948
              ],
              [
                -50.60298938557279,
                -10.660527634435931
              ],
              [
                -50.58210205882668,
                -10.643675700085803
              ],
              [
                -50.562709245252506,
                -10.61091384912055
              ],
              [
                -50.55191183877626,
                -10.609844846982996
              ],
              [
                -50.54252189381497,
                -10.608915841037577
              ],
              [
                -50.53695793847545,
                -10.604809862936103
              ],
              [
                -50.53111751555238,
                -10.5803929962122
              ],
              [
                -50.5251905721582,
                -10.5712320419641
              ],
              [
                -50.51926463574524,
                -10.562072091737484
              ],
              [
                -50.521287650063165,
                -10.548809673566115
              ],
              [
                -50.522571636796364,
                -10.540388739260443
              ],
              [
                -50.51853019704274,
                -10.532971756630191
              ],
              [
                -50.51448872728291,
                -10.525554303017959
              ],
              [
                -50.51561573194716,
                -10.504722913626773
              ],
              [
                -50.49991186234548,
                -10.495323480214118
              ],
              [
                -50.48871396018336,
                -10.494715957036199
              ],
              [
                -50.48215651647169,
                -10.489630993416865
              ],
              [
                -50.486756978461855,
                -10.470282580435036
              ],
              [
                -50.48740299361489,
                -10.467565108152879
              ],
              [
                -50.47326117792231,
                -10.404499444747504
              ],
              [
                -50.44782288309326,
                -10.391163497178033
              ],
              [
                -50.44421092587626,
                -10.383161571858746
              ],
              [
                -50.44059894665065,
                -10.375159599555873
              ],
              [
                -50.438248984678566,
                -10.373168118925108
              ],
              [
                -50.41782714872144,
                -10.355864199719342
              ],
              [
                -50.42028216979384,
                -10.343053757971289
              ],
              [
                -50.42273666684876,
                -10.330243348235149
              ],
              [
                -50.40476886667901,
                -10.283796605205753
              ],
              [
                -50.39762693063703,
                -10.254015746818066
              ],
              [
                -50.38189408944489,
                -10.221341942842477
              ],
              [
                -50.38893858377089,
                -10.174524704127371
              ],
              [
                -50.39401508436863,
                -10.171785745099134
              ],
              [
                -50.396441044406195,
                -10.170477765070721
              ],
              [
                -50.3982940472128,
                -10.16369529639761
              ],
              [
                -50.39168714111633,
                -10.133627478016068
              ],
              [
                -50.38451719869739,
                -10.12197004282062
              ],
              [
                -50.377347262262816,
                -10.110312086668726
              ],
              [
                -50.37199932091954,
                -10.105602601146574
              ],
              [
                -50.35893441445524,
                -10.0940981662116
              ],
              [
                -50.356572967644496,
                -10.085432225029939
              ],
              [
                -50.354211488824525,
                -10.076765269869224
              ],
              [
                -50.33937960974634,
                -10.060533358534
              ],
              [
                -50.31199031443442,
                -10.045257387842883
              ],
              [
                -50.307086866385184,
                -10.04013291349661
              ],
              [
                -50.302183896330064,
                -10.03500895915849
              ],
              [
                -50.293818007965484,
                -10.015704062893855
              ],
              [
                -50.29201354137654,
                -10.003059624134332
              ],
              [
                -50.29020906576483,
                -9.990414716404034
              ],
              [
                -50.28451761365088,
                -9.981459232731185
              ],
              [
                -50.27747066335914,
                -9.970369819893607
              ],
              [
                -50.277976690137706,
                -9.962608364444655
              ],
              [
                -50.27848219291096,
                -9.95484741599863
              ],
              [
                -50.27224573143553,
                -9.943902457452817
              ],
              [
                -50.266745788798005,
                -9.934251496259835
              ],
              [
                -50.26528181814239,
                -9.921173568712828
              ],
              [
                -50.263817843461176,
                -9.908096143189743
              ],
              [
                -50.24332704876506,
                -9.870841858887388
              ],
              [
                -50.224988203475824,
                -9.853556948297873
              ],
              [
                -50.22628272506632,
                -9.847489454993076
              ],
              [
                -50.22676771253741,
                -9.845216009117765
              ],
              [
                -50.225704724953545,
                -9.843256497350733
              ],
              [
                -50.22434624526551,
                -9.84075151043438
              ],
              [
                -50.22143825916464,
                -9.838087513433198
              ],
              [
                -50.21715428884292,
                -9.834164546105928
              ],
              [
                -50.21497234110629,
                -9.819701624116915
              ],
              [
                -50.21393384087694,
                -9.812819657628399
              ],
              [
                -50.208630381625035,
                -9.80166623295525
              ],
              [
                -50.203325423368305,
                -9.790512299315166
              ],
              [
                -50.19743402406165,
                -9.758432959383294
              ],
              [
                -50.160818868589665,
                -9.712425201844963
              ],
              [
                -50.15988888401522,
                -9.706640750114461
              ],
              [
                -50.158658387093176,
                -9.698988279136417
              ],
              [
                -50.15513740811421,
                -9.697347777372263
              ],
              [
                -50.1505474486452,
                -9.69520828420645
              ],
              [
                -50.149978492371474,
                -9.681044366936769
              ],
              [
                -50.14970898413925,
                -9.674325445665913
              ],
              [
                -50.14465402149388,
                -9.664215463480483
              ],
              [
                -50.139598077844305,
                -9.654104038329294
              ],
              [
                -50.13941508531703,
                -9.649404563147076
              ],
              [
                -50.13854763078539,
                -9.627059704458558
              ],
              [
                -50.12943920134013,
                -9.621200210800442
              ],
              [
                -50.12627271925884,
                -9.619163725916655
              ],
              [
                -50.119683804012446,
                -9.606588775646514
              ],
              [
                -50.10691140237847,
                -9.593879342335399
              ],
              [
                -50.10689340960166,
                -9.582992436434134
              ],
              [
                -50.1068764218001,
                -9.572105980546493
              ],
              [
                -50.099935485348226,
                -9.561635529381892
              ],
              [
                -50.08910307506152,
                -9.545294622469278
              ],
              [
                -50.09140956175302,
                -9.535465688268987
              ],
              [
                -50.09371606843502,
                -9.525634762087076
              ],
              [
                -50.09834403692192,
                -9.519788286974665
              ],
              [
                -50.10749249751017,
                -9.508230377797862
              ],
              [
                -50.10741148634727,
                -9.504143913181515
              ],
              [
                -50.10527151289013,
                -9.50272942595362
              ],
              [
                -50.10096705317778,
                -9.499883938569235
              ],
              [
                -50.10488003325358,
                -9.47501008262337
              ],
              [
                -50.089656201013305,
                -9.431197325368297
              ],
              [
                -50.06507645081669,
                -9.400401473608955
              ],
              [
                -50.05237856677354,
                -9.372272634926277
              ],
              [
                -50.05535006619098,
                -9.341912325326465
              ],
              [
                -50.05029764108269,
                -9.312669516159456
              ],
              [
                -50.043813694594306,
                -9.30083754343141
              ],
              [
                -50.0373297510886,
                -9.289005633741693
              ],
              [
                -50.01992541465973,
                -9.281368654329908
              ],
              [
                -50.01866441562844,
                -9.280815144114403
              ],
              [
                -50.011605962336645,
                -9.274588176985452
              ],
              [
                -50.00454854603535,
                -9.268362200869259
              ],
              [
                -49.99359963974707,
                -9.233699919730347
              ],
              [
                -49.96569135720629,
                -9.230466407074946
              ],
              [
                -49.95768843369463,
                -9.220340467506853
              ],
              [
                -49.943017057319445,
                -9.20048906568202
              ],
              [
                -49.90723786632755,
                -9.173426693991127
              ],
              [
                -49.9047948658223,
                -9.164905746667626
              ],
              [
                -49.90701287116565,
                -9.159467267329658
              ],
              [
                -49.90959834590713,
                -9.15312631690961
              ],
              [
                -49.89670495776962,
                -9.143486369730404
              ],
              [
                -49.89642196611502,
                -9.143274865670334
              ],
              [
                -49.87082622840899,
                -9.103575089691734
              ],
              [
                -49.859743322679485,
                -9.079343713577323
              ],
              [
                -49.850212410310235,
                -9.058502841848435
              ],
              [
                -49.84992393927619,
                -9.047924881848664
              ],
              [
                -49.84988044054351,
                -9.04631991656085
              ],
              [
                -49.84213550715006,
                -9.026931524281828
              ],
              [
                -49.79320742592565,
                -8.979036256816935
              ],
              [
                -49.77042715318883,
                -8.938095998708507
              ],
              [
                -49.76226219835705,
                -8.929831533546428
              ],
              [
                -49.754098286513724,
                -8.921567566410118
              ],
              [
                -49.753241268550084,
                -8.920137058142705
              ],
              [
                -49.74463386425175,
                -8.905768134847511
              ],
              [
                -49.73669194498327,
                -8.902352676385771
              ],
              [
                -49.72874900772925,
                -8.898936195938981
              ],
              [
                -49.68390286001912,
                -8.85607888477978
              ],
              [
                -49.64104270054842,
                -8.84632139052068
              ],
              [
                -49.61221442747483,
                -8.845834383850386
              ],
              [
                -49.59195806839951,
                -8.839095895606766
              ],
              [
                -49.58804511890015,
                -8.831011949718842
              ],
              [
                -49.58413317138451,
                -8.822928508845715
              ],
              [
                -49.56949976187196,
                -8.806672600788964
              ],
              [
                -49.56890027792128,
                -8.806154075282205
              ],
              [
                -49.56759178494218,
                -8.805022589722725
              ],
              [
                -49.56373931637344,
                -8.799147113396701
              ],
              [
                -49.542993005708986,
                -8.761000330223938
              ],
              [
                -49.48935000493469,
                -8.691641210345074
              ],
              [
                -49.48132558158555,
                -8.673337310240125
              ],
              [
                -49.476188145263706,
                -8.660692903521719
              ],
              [
                -49.470910171359904,
                -8.647703460814409
              ],
              [
                -49.46616124100195,
                -8.641251487310745
              ],
              [
                -49.46141228164026,
                -8.634799550820421
              ],
              [
                -49.451019358759176,
                -8.612103661478576
              ],
              [
                -49.40943670849016,
                -8.579607306775836
              ],
              [
                -49.40012430075534,
                -8.558403943786262
              ],
              [
                -49.399211316798564,
                -8.546720529253294
              ],
              [
                -49.39104493224765,
                -8.489387380130832
              ],
              [
                -49.38713348479216,
                -8.481836913689403
              ],
              [
                -49.383222503325705,
                -8.474286967260406
              ],
              [
                -49.37656209856392,
                -8.45489155645699
              ],
              [
                -49.34895883387961,
                -8.417985782555004
              ],
              [
                -49.31284763549449,
                -8.402504345128685
              ],
              [
                -49.294739745879056,
                -8.391034896694784
              ],
              [
                -49.2888053046003,
                -8.385100433187162
              ],
              [
                -49.28287136831573,
                -8.379166443691426
              ],
              [
                -49.269574017309786,
                -8.342938685336382
              ],
              [
                -49.26261457390929,
                -8.310415373018348
              ],
              [
                -49.25329518685629,
                -8.270637601732393
              ],
              [
                -49.23602187642568,
                -8.232088834451426
              ],
              [
                -49.221712505759164,
                -8.205528506526598
              ],
              [
                -49.21826051939459,
                -8.199121016000978
              ],
              [
                -49.21540454739073,
                -8.19382054618171
              ],
              [
                -49.20913163332187,
                -8.17509967992724
              ],
              [
                -49.2034131871162,
                -8.139616882621826
              ],
              [
                -49.20466368759572,
                -8.133300439637866
              ],
              [
                -49.20687869412081,
                -8.122110511306978
              ],
              [
                -49.20090524181424,
                -8.105091616918426
              ],
              [
                -49.19891078979317,
                -8.087191716353422
              ],
              [
                -49.1927133327832,
                -8.07607478071428
              ],
              [
                -49.18750486834071,
                -8.072522283552626
              ],
              [
                -49.181210920769146,
                -8.068229302910092
              ],
              [
                -49.175680989871886,
                -8.054193423422928
              ],
              [
                -49.17470851130619,
                -8.042711456699234
              ],
              [
                -49.172835543694674,
                -8.02058810508906
              ],
              [
                -49.17516702275214,
                -8.008846684525382
              ],
              [
                -49.18157901805243,
                -7.976573385679426
              ],
              [
                -49.17538656680314,
                -7.965487470119021
              ],
              [
                -49.16919462753594,
                -7.954401531593195
              ],
              [
                -49.16386920637257,
                -7.926524215000554
              ],
              [
                -49.16309870288424,
                -7.913332790498256
              ],
              [
                -49.16232873736735,
                -7.900141869017559
              ],
              [
                -49.166639745574074,
                -7.848802208934974
              ],
              [
                -49.165551778110455,
                -7.84064174241902
              ],
              [
                -49.16446477562935,
                -7.832482295908214
              ],
              [
                -49.161804301645944,
                -7.82839881500841
              ],
              [
                -49.14970791882173,
                -7.809831440107745
              ],
              [
                -49.150135933885615,
                -7.801809987324855
              ],
              [
                -49.16029335040494,
                -7.790980064266664
              ],
              [
                -49.19501308825499,
                -7.777164185147837
              ],
              [
                -49.20269205227691,
                -7.774108199316719
              ],
              [
                -49.21170247588253,
                -7.767659239305996
              ],
              [
                -49.2207134314907,
                -7.761210823283844
              ],
              [
                -49.227707383430314,
                -7.753460364438197
              ],
              [
                -49.23954728984219,
                -7.740339476489312
              ],
              [
                -49.244445267344915,
                -7.738478962235769
              ],
              [
                -49.24611275533022,
                -7.737131490454163
              ],
              [
                -49.263513146402076,
                -7.723071101061254
              ],
              [
                -49.29607842308094,
                -7.696056309917543
              ],
              [
                -49.322774248886624,
                -7.681360401830216
              ],
              [
                -49.34119564604047,
                -7.656879078213838
              ],
              [
                -49.36041902031024,
                -7.614447865907531
              ],
              [
                -49.381363431399826,
                -7.566278188653053
              ],
              [
                -49.38248341467698,
                -7.554693271105696
              ],
              [
                -49.3836039239326,
                -7.543108348572073
              ],
              [
                -49.37754602256142,
                -7.496061649652638
              ],
              [
                -49.31926399778554,
                -7.430889988075082
              ],
              [
                -49.26533246742687,
                -7.366788363463374
              ],
              [
                -49.25960901851362,
                -7.357916924444597
              ],
              [
                -49.25388457160224,
                -7.34904297746431
              ],
              [
                -49.23431270804939,
                -7.329239596865748
              ],
              [
                -49.23338573083776,
                -7.328301585956853
              ],
              [
                -49.232205737998854,
                -7.326851605070727
              ],
              [
                -49.231026269155905,
                -7.325401589186032
              ],
              [
                -49.215475897645156,
                -7.297347761299763
              ],
              [
                -49.20634199437656,
                -7.270345457030229
              ],
              [
                -49.18483618307441,
                -7.234788630872305
              ],
              [
                -49.1827567000906,
                -7.214200786617495
              ],
              [
                -49.18541919348436,
                -7.192908415410756
              ],
              [
                -49.18730321274335,
                -7.177825015754045
              ],
              [
                -49.19334770637741,
                -7.129431835414628
              ],
              [
                -49.19085726913359,
                -7.074546671001261
              ],
              [
                -49.19291576563286,
                -7.040291903726986
              ],
              [
                -49.19387329118828,
                -7.033086943863658
              ],
              [
                -49.194831271732745,
                -7.025883016998675
              ],
              [
                -49.20882121372331,
                -6.982607809790192
              ],
              [
                -49.21146621397414,
                -6.979466324395394
              ],
              [
                -49.21493115203219,
                -6.975351343004557
              ],
              [
                -49.21699467478682,
                -6.961087958534114
              ],
              [
                -49.21413619620034,
                -6.937887596042262
              ],
              [
                -49.21159272494284,
                -6.931453625093942
              ],
              [
                -49.209049735676516,
                -6.925019667155389
              ],
              [
                -49.194482874872804,
                -6.915048223068297
              ],
              [
                -49.18346794949891,
                -6.907508261601713
              ],
              [
                -49.18107597208194,
                -6.902192792650908
              ],
              [
                -49.17904250327588,
                -6.897675831890266
              ],
              [
                -49.17149354997298,
                -6.892553844043117
              ],
              [
                -49.15001623419569,
                -6.888470348937023
              ],
              [
                -49.11992497840004,
                -6.872307924980662
              ],
              [
                -49.11737549958516,
                -6.870938434516979
              ],
              [
                -49.114826020770785,
                -6.869568939054564
              ],
              [
                -49.082832275568144,
                -6.842980582912747
              ],
              [
                -49.043326107176384,
                -6.81539123086365
              ],
              [
                -49.03810712792489,
                -6.80877276901094
              ],
              [
                -49.03288815267,
                -6.802154330172893
              ],
              [
                -49.01843781354094,
                -6.783831438898093
              ],
              [
                -48.968035174793876,
                -6.779491901297162
              ],
              [
                -48.939061393416836,
                -6.766310473103773
              ],
              [
                -48.926235017168956,
                -6.7616494910032
              ],
              [
                -48.913408113941834,
                -6.756988514922891
              ],
              [
                -48.84932761658461,
                -6.74853402434494
              ],
              [
                -48.83943917688253,
                -6.750281003282332
              ],
              [
                -48.82511129866158,
                -6.752812464206878
              ],
              [
                -48.81942880273882,
                -6.752098493845621
              ],
              [
                -48.81374484683065,
                -6.751384964481816
              ],
              [
                -48.77716214105243,
                -6.735666068610425
              ],
              [
                -48.76894321794006,
                -6.728326608191684
              ],
              [
                -48.76072429382745,
                -6.720987116796378
              ],
              [
                -48.73643448747162,
                -6.692402295886906
              ],
              [
                -48.686159375994094,
                -6.679433848754639
              ],
              [
                -48.66441205763846,
                -6.662382938252496
              ],
              [
                -48.66026460028873,
                -6.657087494264003
              ],
              [
                -48.6549161332594,
                -6.650258532270149
              ],
              [
                -48.65151868594379,
                -6.639572579195155
              ],
              [
                -48.65177671105068,
                -6.611675781867377
              ],
              [
                -48.663912636724426,
                -6.570193028716241
              ],
              [
                -48.66291013313176,
                -6.557424125447895
              ],
              [
                -48.65766569704723,
                -6.539052261289188
              ],
              [
                -48.64627277661295,
                -6.523454340804398
              ],
              [
                -48.64582529262338,
                -6.508509960374424
              ],
              [
                -48.63143845212055,
                -6.48750755394085
              ],
              [
                -48.629599451723344,
                -6.484153596392064
              ],
              [
                -48.626095981351796,
                -6.477761626377293
              ],
              [
                -48.61271262312658,
                -6.453350768359128
              ],
              [
                -48.56450701683646,
                -6.414593022770201
              ],
              [
                -48.56185152381081,
                -6.413317489948243
              ],
              [
                -48.55805454004408,
                -6.411203027695811
              ],
              [
                -48.546855127223374,
                -6.404968074401153
              ],
              [
                -48.53035576699802,
                -6.389297621853506
              ],
              [
                -48.52693781532167,
                -6.381230688144524
              ],
              [
                -48.52351982963773,
                -6.373163731450934
              ],
              [
                -48.50717948558773,
                -6.354582878399834
              ],
              [
                -48.50347651303993,
                -6.352566871637703
              ],
              [
                -48.49977404148944,
                -6.350550865878527
              ],
              [
                -48.44726543059243,
                -6.350801819988476
              ],
              [
                -48.43559950784962,
                -6.353715315155249
              ],
              [
                -48.42393359212476,
                -6.356628285319718
              ],
              [
                -48.40722520930667,
                -6.368065673033653
              ],
              [
                -48.39899729652874,
                -6.37369765605641
              ],
              [
                -48.390617838260226,
                -6.376464618867602
              ],
              [
                -48.38223938099298,
                -6.37923160767538
              ],
              [
                -48.37486995641844,
                -6.346427817354318
              ],
              [
                -48.378747983110976,
                -6.334398877052482
              ],
              [
                -48.38015494810286,
                -6.330033939062339
              ],
              [
                -48.38443793472564,
                -6.324124974635917
              ],
              [
                -48.389612371683796,
                -6.320613495086298
              ],
              [
                -48.41160873249654,
                -6.305687602737055
              ],
              [
                -48.41668271345425,
                -6.298211173299406
              ],
              [
                -48.41685221561431,
                -6.289081713791375
              ],
              [
                -48.41446271602136,
                -6.284843247869572
              ],
              [
                -48.41226923566994,
                -6.280953280807487
              ],
              [
                -48.41734720009999,
                -6.270365862953056
              ],
              [
                -48.41854470412543,
                -6.267868848440007
              ],
              [
                -48.42471268664663,
                -6.23340309644151
              ],
              [
                -48.43599164436682,
                -6.19302388778643
              ],
              [
                -48.433744651694795,
                -6.184970950304749
              ],
              [
                -48.43149818101054,
                -6.176917959836482
              ],
              [
                -48.41938275719074,
                -6.162939564755234
              ],
              [
                -48.40878487507109,
                -6.153844113582242
              ],
              [
                -48.40783135688771,
                -6.153026119244748
              ],
              [
                -48.390850529843135,
                -6.146329156126163
              ],
              [
                -48.36504919330889,
                -6.146999115151771
              ],
              [
                -48.33404945771678,
                -6.136648164226144
              ],
              [
                -48.33146195649675,
                -6.134994702152904
              ],
              [
                -48.32887499827305,
                -6.133341210081794
              ],
              [
                -48.305072193456375,
                -6.118126268315938
              ],
              [
                -48.29837824154524,
                -6.110909325998393
              ],
              [
                -48.29168379563549,
                -6.103692366699948
              ],
              [
                -48.28271937682837,
                -6.081182505375422
              ],
              [
                -48.284034871302474,
                -6.075596048581846
              ],
              [
                -48.285349872776685,
                -6.070009589789884
              ],
              [
                -48.30154525188482,
                -6.046931756062061
              ],
              [
                -48.33227203370263,
                -6.037893338215422
              ],
              [
                -48.336935986857696,
                -6.03116437162301
              ],
              [
                -48.3381594919757,
                -6.020466472755404
              ],
              [
                -48.33427702603666,
                -6.0039020859772
              ],
              [
                -48.327649106167684,
                -5.994513643337944
              ],
              [
                -48.321021666287095,
                -5.98512567172317
              ],
              [
                -48.306640266729325,
                -5.974466757613061
              ],
              [
                -48.29907831407926,
                -5.971303768852607
              ],
              [
                -48.29151537844203,
                -5.968140772099567
              ],
              [
                -48.27533101182237,
                -5.964847281578477
              ],
              [
                -48.25151970687449,
                -5.960000790564338
              ],
              [
                -48.247721202531615,
                -5.95733981639715
              ],
              [
                -48.23928329963326,
                -5.951430860345682
              ],
              [
                -48.23119284193449,
                -5.94576439495988
              ],
              [
                -48.2299103849776,
                -5.925649532307281
              ],
              [
                -48.23233137555824,
                -5.916704564909431
              ],
              [
                -48.245164785736044,
                -5.895859737956193
              ],
              [
                -48.273167081214005,
                -5.86636344130053
              ],
              [
                -48.28845749107748,
                -5.842561123905954
              ],
              [
                -48.289897008756554,
                -5.837894657174718
              ],
              [
                -48.29133696043003,
                -5.833228179444802
              ],
              [
                -48.29889445323012,
                -5.781574042443559
              ],
              [
                -48.29411201400719,
                -5.756302208939006
              ],
              [
                -48.28872704744088,
                -5.741572777518062
              ],
              [
                -48.280844143402454,
                -5.731237868866636
              ],
              [
                -48.27210270570774,
                -5.725121404358014
              ],
              [
                -48.219603583073216,
                -5.727791323628736
              ],
              [
                -48.19347181027459,
                -5.719251364596629
              ],
              [
                -48.17387845762108,
                -5.708844455988993
              ],
              [
                -48.16012806916304,
                -5.687193072269626
              ],
              [
                -48.155573109128596,
                -5.678136121775344
              ],
              [
                -48.151017131093404,
                -5.669078186307683
              ],
              [
                -48.13505079658544,
                -5.637329887125534
              ],
              [
                -48.13181333615758,
                -5.617758018450948
              ],
              [
                -48.13771480776846,
                -5.602320141611583
              ],
              [
                -48.174384518199076,
                -5.575972820350849
              ],
              [
                -48.18366947969208,
                -5.572521348252095
              ],
              [
                -48.18928893084051,
                -5.570432394346562
              ],
              [
                -48.19163093377309,
                -5.566828396275641
              ],
              [
                -48.194299913600254,
                -5.562722442815998
              ],
              [
                -48.212163745349116,
                -5.553869508188908
              ],
              [
                -48.22010822164567,
                -5.552282543559601
              ],
              [
                -48.22805263495056,
                -5.550696036925069
              ],
              [
                -48.23106961742793,
                -5.550422038930485
              ],
              [
                -48.26107538548774,
                -5.547701601731135
              ],
              [
                -48.26967182353098,
                -5.543870603639149
              ],
              [
                -48.27826928457528,
                -5.540039166545041
              ],
              [
                -48.30026909448697,
                -5.522635796216411
              ],
              [
                -48.325083450674086,
                -5.49337801347939
              ],
              [
                -48.32582893547628,
                -5.49421696637229
              ],
              [
                -48.327647918354685,
                -5.496262479922399
              ],
              [
                -48.32741895020504,
                -5.493255487962601
              ],
              [
                -48.327332441654775,
                -5.492126021489958
              ],
              [
                -48.32690694579712,
                -5.486550557651382
              ],
              [
                -48.33316090917626,
                -5.477511617828228
              ],
              [
                -48.340645356557296,
                -5.466694670820224
              ],
              [
                -48.341271842549894,
                -5.457176736231886
              ],
              [
                -48.341796371212034,
                -5.449203789195016
              ],
              [
                -48.34592682840659,
                -5.437471863975516
              ],
              [
                -48.35760676615369,
                -5.421044509631875
              ],
              [
                -48.36347269782534,
                -5.417131047059515
              ],
              [
                -48.36791318354202,
                -5.414168556090813
              ],
              [
                -48.3694636613843,
                -5.410604589138794
              ],
              [
                -48.37304714721353,
                -5.402368646022811
              ],
              [
                -48.38430256363504,
                -5.393838209247393
              ],
              [
                -48.39307001080795,
                -5.393844699329575
              ],
              [
                -48.39563750346263,
                -5.396821172116497
              ],
              [
                -48.401001939862866,
                -5.40304014507248
              ],
              [
                -48.41912627894367,
                -5.40145765939332
              ],
              [
                -48.44134711081825,
                -5.40778914343788
              ],
              [
                -48.464148949928884,
                -5.411921628694807
              ],
              [
                -48.4911082362759,
                -5.425572082075273
              ],
              [
                -48.500710174018394,
                -5.427425045413108
              ],
              [
                -48.51031211777194,
                -5.429278034756936
              ],
              [
                -48.557096736465034,
                -5.416342184383057
              ],
              [
                -48.59091748582503,
                -5.42137514350038
              ],
              [
                -48.59707944838538,
                -5.420434664137639
              ],
              [
                -48.603241389950135,
                -5.41949518076733
              ],
              [
                -48.62868172257309,
                -5.409689266293303
              ],
              [
                -48.643245129941896,
                -5.389331393721638
              ],
              [
                -48.64664559719443,
                -5.386940923098914
              ],
              [
                -48.65004707644077,
                -5.384549928479092
              ],
              [
                -48.68011085811235,
                -5.378390001739777
              ],
              [
                -48.71632558979987,
                -5.376796034759926
              ],
              [
                -48.71985755289416,
                -5.373269051802293
              ],
              [
                -48.73068047904596,
                -5.374803044131703
              ],
              [
                -48.745051862606616,
                -5.369351612610942
              ],
              [
                -48.74922832750559,
                -5.363524159458205
              ],
              [
                -48.75429332163624,
                -5.356457698200488
              ],
              [
                -48.75471282282593,
                -5.348801238641357
              ],
              [
                -48.740479925476855,
                -5.352449695336596
              ],
              [
                -48.7299934770757,
                -5.347626231260838
              ],
              [
                -48.719013087938215,
                -5.350637228479556
              ],
              [
                -48.71313211071744,
                -5.3522507025923
              ],
              [
                -48.704026200171675,
                -5.344358741020492
              ],
              [
                -48.69876724744417,
                -5.319758406937863
              ],
              [
                -48.69182081014523,
                -5.311293950777245
              ],
              [
                -48.68640883625609,
                -5.308829458531912
              ],
              [
                -48.67899840692163,
                -5.305454505006624
              ],
              [
                -48.65101962708026,
                -5.311070934058379
              ],
              [
                -48.644617671972746,
                -5.315356419923623
              ],
              [
                -48.638215717868825,
                -5.319641855784732
              ],
              [
                -48.637373693284275,
                -5.320916350663542
              ],
              [
                -48.62966076288549,
                -5.332590291112473
              ],
              [
                -48.611907407845294,
                -5.338059725147887
              ],
              [
                -48.6056909436664,
                -5.33613172575313
              ],
              [
                -48.5836930975768,
                -5.310925890522975
              ],
              [
                -48.56813124144474,
                -5.282567093103836
              ],
              [
                -48.56812026540162,
                -5.272495647711342
              ],
              [
                -48.56811025733785,
                -5.262424224331424
              ],
              [
                -48.56344230505212,
                -5.248028322833625
              ],
              [
                -48.561816302620365,
                -5.243015347402724
              ],
              [
                -48.5612248295079,
                -5.236430889240354
              ],
              [
                -48.560632821395785,
                -5.229843428103909
              ],
              [
                -48.53857851752572,
                -5.204546566229315
              ],
              [
                -48.52879958603066,
                -5.198157124605298
              ],
              [
                -48.519020659541155,
                -5.191767672003484
              ],
              [
                -48.485882937627096,
                -5.193453125319802
              ],
              [
                -48.46571508937456,
                -5.185075679171054
              ],
              [
                -48.363470356768424,
                -5.168012222671055
              ],
              [
                -48.35809239731355,
                -5.170768193459408
              ],
              [
                -48.352715457853726,
                -5.173524693242264
              ],
              [
                -48.32046468474872,
                -5.20102098687263
              ],
              [
                -48.293749869803555,
                -5.203603952442594
              ],
              [
                -48.28316494536614,
                -5.209601386784014
              ],
              [
                -48.27258000394011,
                -5.215598858114433
              ],
              [
                -48.24226176541246,
                -5.225422758243035
              ],
              [
                -48.2245298852936,
                -5.235184166250618
              ],
              [
                -48.20876047428075,
                -5.24665209001548
              ],
              [
                -48.207342499261394,
                -5.247683581078042
              ],
              [
                -48.206061512277856,
                -5.248615565906245
              ],
              [
                -48.18985662833171,
                -5.25302852706877
              ],
              [
                -48.17810520586526,
                -5.260407484171925
              ],
              [
                -48.10812372036964,
                -5.266324381604518
              ],
              [
                -48.10789023308542,
                -5.266391397992023
              ],
              [
                -48.078838465669115,
                -5.274660341232674
              ],
              [
                -48.066969025633945,
                -5.271128838106621
              ],
              [
                -48.06687406136959,
                -5.271100834231977
              ],
              [
                -48.05360666218473,
                -5.267152856995907
              ],
              [
                -48.04882919921171,
                -5.265731858392522
              ],
              [
                -48.04033827190062,
                -5.258941424697994
              ],
              [
                -48.01399945498565,
                -5.237877024863608
              ],
              [
                -48.00581254175082,
                -5.235018555757261
              ],
              [
                -47.99022614936923,
                -5.235247522704523
              ],
              [
                -47.98093921475203,
                -5.236448509291242
              ],
              [
                -47.95833137170284,
                -5.239370997204902
              ],
              [
                -47.946128993577865,
                -5.239966960935136
              ],
              [
                -47.937791553363894,
                -5.240373977552903
              ],
              [
                -47.93750355653293,
                -5.2403879542639
              ],
              [
                -47.93355307828666,
                -5.240579463301339
              ],
              [
                -47.88513593443315,
                -5.260332318297274
              ],
              [
                -47.8793114723428,
                -5.266561253982665
              ],
              [
                -47.873487514249476,
                -5.272789192670846
              ],
              [
                -47.86663603467065,
                -5.304561493901895
              ],
              [
                -47.86687953743165,
                -5.321349879913353
              ],
              [
                -47.867185016742624,
                -5.342453235676296
              ],
              [
                -47.84332516064685,
                -5.37584000738712
              ],
              [
                -47.84236770148132,
                -5.376452985561526
              ],
              [
                -47.827104280448445,
                -5.386219409658155
              ],
              [
                -47.79255604768535,
                -5.384341894568954
              ],
              [
                -47.76790425815618,
                -5.377833931841614
              ],
              [
                -47.75484935515922,
                -5.379378923333157
              ],
              [
                -47.74179393318758,
                -5.38092488081498
              ],
              [
                -47.68533336136699,
                -5.412351141831957
              ],
              [
                -47.66633799046876,
                -5.421419048905602
              ],
              [
                -47.6181873313454,
                -5.459356273956127
              ],
              [
                -47.58009514107949,
                -5.4696392012875
              ],
              [
                -47.57244718853408,
                -5.466429706831642
              ],
              [
                -47.56371923753229,
                -5.462767218720476
              ],
              [
                -47.55493832059492,
                -5.465365691873791
              ],
              [
                -47.51083412628431,
                -5.513158817990932
              ],
              [
                -47.505261175533,
                -5.519196795886339
              ],
              [
                -47.499690202766814,
                -5.525234729779613
              ],
              [
                -47.48282780067624,
                -5.564527450897444
              ],
              [
                -47.48397126920121,
                -5.595236244383866
              ],
              [
                -47.479225786826575,
                -5.621312085329298
              ],
              [
                -47.47730880717481,
                -5.63429050055344
              ],
              [
                -47.47721630943251,
                -5.634916475223705
              ],
              [
                -47.47327233737606,
                -5.661608308632073
              ],
              [
                -47.492667137739225,
                -5.709665490709826
              ],
              [
                -47.492804621252624,
                -5.719911415146901
              ],
              [
                -47.49294260374786,
                -5.730158339590541
              ],
              [
                -47.48327467961588,
                -5.75603267066581
              ],
              [
                -47.47665173747501,
                -5.763779110166003
              ],
              [
                -47.470029787326894,
                -5.771524049671973
              ],
              [
                -47.46298980825463,
                -5.786232433550531
              ],
              [
                -47.44185594601038,
                -5.830385630988199
              ],
              [
                -47.438277943097994,
                -5.852272486703891
              ],
              [
                -47.43742395073503,
                -5.857497426594257
              ],
              [
                -47.43063352314821,
                -5.86883684954657
              ],
              [
                -47.43009447863421,
                -5.935073885759962
              ],
              [
                -47.447532793930876,
                -5.992542041600577
              ],
              [
                -47.4383183551454,
                -6.012420394968831
              ],
              [
                -47.42812593374941,
                -6.037578194854039
              ],
              [
                -47.430054885614496,
                -6.057626068674819
              ],
              [
                -47.43089039154748,
                -6.066307001592207
              ],
              [
                -47.43404483403645,
                -6.092738838282342
              ],
              [
                -47.43100583420285,
                -6.119875160493848
              ],
              [
                -47.41616741762118,
                -6.177447726955384
              ],
              [
                -47.401045487496404,
                -6.208870019879932
              ],
              [
                -47.38493358598132,
                -6.230031883278069
              ],
              [
                -47.38273010102985,
                -6.239888279000436
              ],
              [
                -47.38052663806685,
                -6.249744722728815
              ],
              [
                -47.37838861093872,
                -6.27047858712515
              ],
              [
                -47.40894985929577,
                -6.331601208601882
              ],
              [
                -47.41017082887773,
                -6.337914633067169
              ],
              [
                -47.41139233945075,
                -6.344227602542817
              ],
              [
                -47.411440822570555,
                -6.34981808794175
              ],
              [
                -47.4116682945397,
                -6.376145895914994
              ],
              [
                -47.41558774638974,
                -6.388695333660631
              ],
              [
                -47.41950720222032,
                -6.401244224441794
              ],
              [
                -47.43096012630767,
                -6.424977574224985
              ],
              [
                -47.43239159611275,
                -6.435069008579481
              ],
              [
                -47.41636668291025,
                -6.484649672350326
              ],
              [
                -47.41827915108497,
                -6.487317146015499
              ],
              [
                -47.42489910383917,
                -6.496551590483107
              ],
              [
                -47.42881606617025,
                -6.499841057640778
              ],
              [
                -47.45605583338215,
                -6.537880862066625
              ],
              [
                -47.45685832122401,
                -6.542715796323018
              ],
              [
                -47.45766079806279,
                -6.5475507555831
              ],
              [
                -47.46389822797386,
                -6.585136043978629
              ],
              [
                -47.46293275155528,
                -6.603952389171187
              ],
              [
                -47.46541519307057,
                -6.611691346451637
              ],
              [
                -47.483313058041,
                -6.643975649551431
              ],
              [
                -47.49821191541097,
                -6.670849967529561
              ],
              [
                -47.495334437930524,
                -6.680800422594064
              ],
              [
                -47.49285745155882,
                -6.689366863251003
              ],
              [
                -47.49471591547248,
                -6.69509679455715
              ],
              [
                -47.495675422411736,
                -6.702979757722803
              ],
              [
                -47.49622088241123,
                -6.707465235232466
              ],
              [
                -47.49368738346367,
                -6.754451430405847
              ],
              [
                -47.51627019133124,
                -6.789879693610913
              ],
              [
                -47.528236053064504,
                -6.830014935599747
              ],
              [
                -47.52610955774294,
                -6.837545886600942
              ],
              [
                -47.52005910174434,
                -6.840090357385747
              ],
              [
                -47.506605682961656,
                -6.871628148662068
              ],
              [
                -47.50546617224414,
                -6.897168957682179
              ],
              [
                -47.50632816641567,
                -6.901987921377163
              ],
              [
                -47.50719063958083,
                -6.906806390079782
              ],
              [
                -47.52913796256706,
                -6.945629168329509
              ],
              [
                -47.529477427861984,
                -6.976145958910054
              ],
              [
                -47.53507436434002,
                -6.987096878175331
              ],
              [
                -47.545931772961914,
                -7.014430735458548
              ],
              [
                -47.57435002200232,
                -7.049956518391332
              ],
              [
                -47.584302933786866,
                -7.055747992973381
              ],
              [
                -47.594256361573414,
                -7.061539950573074
              ],
              [
                -47.617990142976005,
                -7.090773772502163
              ],
              [
                -47.62702005041596,
                -7.121198058487788
              ],
              [
                -47.63552048451329,
                -7.132212995416201
              ],
              [
                -47.644021406597126,
                -7.143227949384836
              ],
              [
                -47.65994079507253,
                -7.150983400692471
              ],
              [
                -47.66734572927335,
                -7.1491554245239
              ],
              [
                -47.67475068048061,
                -7.147327947349878
              ],
              [
                -47.67563167422217,
                -7.146826457464814
              ],
              [
                -47.693627019407415,
                -7.136579023527868
              ],
              [
                -47.70762092322197,
                -7.137161532447575
              ],
              [
                -47.715905877242704,
                -7.141368004254844
              ],
              [
                -47.74469311692328,
                -7.16211938174744
              ],
              [
                -47.74683560092438,
                -7.16721537012635
              ],
              [
                -47.748979102914994,
                -7.172310829515752
              ],
              [
                -47.74931858307772,
                -7.191042699425008
              ],
              [
                -47.74578508995131,
                -7.200837141663771
              ],
              [
                -47.736374167276544,
                -7.216373035675407
              ],
              [
                -47.69056998039904,
                -7.23792135442764
              ],
              [
                -47.673934591313326,
                -7.259393217564606
              ],
              [
                -47.655672217135034,
                -7.292108453101961
              ],
              [
                -47.65133821794949,
                -7.299871891935477
              ],
              [
                -47.648083733887,
                -7.302969393502513
              ],
              [
                -47.637402324928274,
                -7.304546369157352
              ],
              [
                -47.626576411402134,
                -7.300438865127178
              ],
              [
                -47.6118150342219,
                -7.278867997571167
              ],
              [
                -47.60693657610803,
                -7.27173857105501
              ],
              [
                -47.5978016695988,
                -7.267723067068583
              ],
              [
                -47.58866723009238,
                -7.263708597088495
              ],
              [
                -47.541471591185555,
                -7.265348531557618
              ],
              [
                -47.49935489709107,
                -7.293471835758201
              ],
              [
                -47.48254352022362,
                -7.321861141606056
              ],
              [
                -47.479971498289224,
                -7.328935580487755
              ],
              [
                -47.477399022353175,
                -7.336010041370571
              ],
              [
                -47.48544395095918,
                -7.366715844212716
              ],
              [
                -47.51225222337168,
                -7.38631573311658
              ],
              [
                -47.56347332767356,
                -7.406439637802589
              ],
              [
                -47.5702632613991,
                -7.41105912080487
              ],
              [
                -47.577053698122334,
                -7.41567907481593
              ],
              [
                -47.59043655354637,
                -7.439154454056822
              ],
              [
                -47.56283275954279,
                -7.449614843058348
              ],
              [
                -47.55639082475434,
                -7.4444033892313
              ],
              [
                -47.54994988295796,
                -7.43919291241136
              ],
              [
                -47.52879204110777,
                -7.43251093635608
              ],
              [
                -47.51985361524513,
                -7.431702940216126
              ],
              [
                -47.50427722909346,
                -7.436249391524862
              ],
              [
                -47.50010323933071,
                -7.446451820365986
              ],
              [
                -47.49856424271553,
                -7.465754690783301
              ],
              [
                -47.497524239219054,
                -7.478798096918764
              ],
              [
                -47.488685805677875,
                -7.485271036888534
              ],
              [
                -47.47984787614022,
                -7.491743006857213
              ],
              [
                -47.47977735477529,
                -7.506255412257618
              ],
              [
                -47.4796848479671,
                -7.525536277287179
              ],
              [
                -47.47478090235687,
                -7.53297670463549
              ],
              [
                -47.46821841053012,
                -7.530980221482985
              ],
              [
                -47.46668843672403,
                -7.528404761965312
              ],
              [
                -47.461039988122415,
                -7.518893783158103
              ],
              [
                -47.4385416653766,
                -7.521063282356477
              ],
              [
                -47.427257267714154,
                -7.525359216270806
              ],
              [
                -47.423910290211474,
                -7.530719686763358
              ],
              [
                -47.424916744219225,
                -7.541404618364949
              ],
              [
                -47.42816823109746,
                -7.544732082574619
              ],
              [
                -47.428198220838745,
                -7.544762103405724
              ],
              [
                -47.43150220526166,
                -7.548144096311159
              ],
              [
                -47.438188655291384,
                -7.556165533935284
              ],
              [
                -47.42054577336721,
                -7.562513961196511
              ],
              [
                -47.41556930420878,
                -7.562826957352542
              ],
              [
                -47.41551682760764,
                -7.562830479278897
              ],
              [
                -47.40683987959561,
                -7.563376448344366
              ],
              [
                -47.39780195665976,
                -7.571896900554051
              ],
              [
                -47.4015254091061,
                -7.581209324831939
              ],
              [
                -47.40154041195436,
                -7.581247328596208
              ],
              [
                -47.40374388672511,
                -7.586758790443098
              ],
              [
                -47.38767698489079,
                -7.596088240540623
              ],
              [
                -47.38756700667224,
                -7.596152223013302
              ],
              [
                -47.3816635226271,
                -7.599580180751056
              ],
              [
                -47.38168452068318,
                -7.602449673882751
              ],
              [
                -47.38168504062778,
                -7.602502683534315
              ],
              [
                -47.38180450907595,
                -7.618604076680729
              ],
              [
                -47.38615698615816,
                -7.632511466396758
              ],
              [
                -47.38147448443702,
                -7.653671354681166
              ],
              [
                -47.373120574664924,
                -7.654552335766742
              ],
              [
                -47.373113565717716,
                -7.654553337753372
              ],
              [
                -47.37307005304721,
                -7.654557816681604
              ],
              [
                -47.36466612366314,
                -7.655443800685142
              ],
              [
                -47.35177673635343,
                -7.64645087433251
              ],
              [
                -47.35279972247932,
                -7.642371902168376
              ],
              [
                -47.35280623545468,
                -7.642345897345839
              ],
              [
                -47.35299321874532,
                -7.641601385426952
              ],
              [
                -47.36432914812462,
                -7.635064944435189
              ],
              [
                -47.37244408768473,
                -7.624975513692373
              ],
              [
                -47.37169560736364,
                -7.620927548607587
              ],
              [
                -47.35779572611836,
                -7.616702551971864
              ],
              [
                -47.35448973857022,
                -7.618676036781793
              ],
              [
                -47.34807825383489,
                -7.630954964738502
              ],
              [
                -47.33535337797718,
                -7.636637909995789
              ],
              [
                -47.322968927522375,
                -7.654630793522404
              ],
              [
                -47.313776994638474,
                -7.660235734692667
              ],
              [
                -47.31977295251366,
                -7.664411713029912
              ],
              [
                -47.32913636927292,
                -7.662715738297142
              ],
              [
                -47.33006239772773,
                -7.66316123726554
              ],
              [
                -47.33010138741031,
                -7.6631797341817
              ],
              [
                -47.33728983384391,
                -7.666635694431073
              ],
              [
                -47.33055384567005,
                -7.673679665525476
              ],
              [
                -47.33310685215994,
                -7.677673141736747
              ],
              [
                -47.33108736465737,
                -7.684828082697147
              ],
              [
                -47.31738995206363,
                -7.686640582415615
              ],
              [
                -47.3173359654714,
                -7.686647574316979
              ],
              [
                -47.30210708246817,
                -7.688662553206941
              ],
              [
                -47.30224705768496,
                -7.701619948072406
              ],
              [
                -47.30552201091504,
                -7.703261964467528
              ],
              [
                -47.31007648617598,
                -7.697917989076505
              ],
              [
                -47.31103850124164,
                -7.698473505360913
              ],
              [
                -47.311076973924536,
                -7.698495470253936
              ],
              [
                -47.31560646856929,
                -7.701109481480579
              ],
              [
                -47.31364647876869,
                -7.706052427049213
              ],
              [
                -47.30688652899993,
                -7.711845895329089
              ],
              [
                -47.306822495437764,
                -7.711900885904728
              ],
              [
                -47.305491510541366,
                -7.713040883103659
              ],
              [
                -47.306445027808124,
                -7.713456374303271
              ],
              [
                -47.30649052243897,
                -7.713476359216308
              ],
              [
                -47.31506293291808,
                -7.717211858035887
              ],
              [
                -47.31269645906657,
                -7.730442278716152
              ],
              [
                -47.30107102040971,
                -7.734463721871526
              ],
              [
                -47.2986905717011,
                -7.732432256896772
              ],
              [
                -47.29867255685432,
                -7.732417228977993
              ],
              [
                -47.2929350897627,
                -7.727519772566525
              ],
              [
                -47.28202217268878,
                -7.734558223569174
              ],
              [
                -47.27312576192584,
                -7.730639235633352
              ],
              [
                -47.27046929259947,
                -7.734441191012729
              ],
              [
                -47.27187323803402,
                -7.744353624173149
              ],
              [
                -47.26082836547788,
                -7.740767149932716
              ],
              [
                -47.26088731899018,
                -7.747898106069051
              ],
              [
                -47.262211813685454,
                -7.751060588562995
              ],
              [
                -47.26223533244982,
                -7.751116075221045
              ],
              [
                -47.26632926331634,
                -7.760892542934489
              ],
              [
                -47.25965085120434,
                -7.756295537607061
              ],
              [
                -47.25961284752175,
                -7.756270040737423
              ],
              [
                -47.258528346597515,
                -7.755523544582619
              ],
              [
                -47.25524286955398,
                -7.758868549346926
              ],
              [
                -47.245801446887526,
                -7.758128014938779
              ],
              [
                -47.25460737961828,
                -7.764757503981228
              ],
              [
                -47.245136920979895,
                -7.781389895250668
              ],
              [
                -47.246077931753035,
                -7.783397373974917
              ],
              [
                -47.24610891445133,
                -7.783461366584559
              ],
              [
                -47.24701992151867,
                -7.785404344704441
              ],
              [
                -47.24450493572499,
                -7.788566318428593
              ],
              [
                -47.24442191425982,
                -7.788670843659243
              ],
              [
                -47.24046947172778,
                -7.793640798073763
              ],
              [
                -47.24223945614884,
                -7.795620276803954
              ],
              [
                -47.242276446822615,
                -7.795662288564207
              ],
              [
                -47.24663491446172,
                -7.800534261834764
              ],
              [
                -47.24021292416715,
                -7.802233737314208
              ],
              [
                -47.24015796557549,
                -7.802248219164708
              ],
              [
                -47.234734999862916,
                -7.803684227362445
              ],
              [
                -47.23910396634727,
                -7.805604208056454
              ],
              [
                -47.239147964989435,
                -7.805623726971589
              ],
              [
                -47.24577141515028,
                -7.808534203386818
              ],
              [
                -47.243877405366156,
                -7.814917655550337
              ],
              [
                -47.238772931069455,
                -7.813470653010167
              ],
              [
                -47.23385245924437,
                -7.81917262265366
              ],
              [
                -47.23035299736503,
                -7.816964139708251
              ],
              [
                -47.23032402560636,
                -7.816945617801344
              ],
              [
                -47.22924102061924,
                -7.816261634226393
              ],
              [
                -47.22992502434654,
                -7.817276625230072
              ],
              [
                -47.2299430231817,
                -7.81730314407355
              ],
              [
                -47.23413748471575,
                -7.823525576320708
              ],
              [
                -47.223133064609456,
                -7.826306556134061
              ],
              [
                -47.215016626710245,
                -7.834687010989755
              ],
              [
                -47.20928517510468,
                -7.834400990185677
              ],
              [
                -47.20923715447678,
                -7.83439847015461
              ],
              [
                -47.206188181095406,
                -7.834245993133497
              ],
              [
                -47.206570194801216,
                -7.836605966994433
              ],
              [
                -47.2065756797221,
                -7.836642973756544
              ],
              [
                -47.20702215453085,
                -7.839403946045844
              ],
              [
                -47.20285020032057,
                -7.839726452785323
              ],
              [
                -47.20279420274706,
                -7.839730945700221
              ],
              [
                -47.20010973020355,
                -7.839938467671843
              ],
              [
                -47.2002927258273,
                -7.841909942889902
              ],
              [
                -47.20029672975459,
                -7.841951919617828
              ],
              [
                -47.20054770012367,
                -7.844655435090663
              ],
              [
                -47.196082258259274,
                -7.848899888748333
              ],
              [
                -47.19409274890183,
                -7.847571884504315
              ],
              [
                -47.194063243148484,
                -7.847552401603162
              ],
              [
                -47.186822825717876,
                -7.842718934201319
              ],
              [
                -47.18674331788062,
                -7.854182325735369
              ],
              [
                -47.18674279183816,
                -7.854228851428915
              ],
              [
                -47.18672082084567,
                -7.857391304612659
              ],
              [
                -47.183645818880116,
                -7.857033812906209
              ],
              [
                -47.18360084223147,
                -7.857028824894296
              ],
              [
                -47.17407389970106,
                -7.855922328701411
              ],
              [
                -47.179184367239564,
                -7.861028804200171
              ],
              [
                -47.17920837003024,
                -7.861053310062943
              ],
              [
                -47.1796073405598,
                -7.861451297842676
              ],
              [
                -47.17485840588498,
                -7.870689224374369
              ],
              [
                -47.1630244645229,
                -7.879182672739713
              ],
              [
                -47.153606028682844,
                -7.89353405698337
              ],
              [
                -47.15000955710059,
                -7.893825043480251
              ],
              [
                -47.14995606650826,
                -7.893829534397359
              ],
              [
                -47.14236811536007,
                -7.894444059782803
              ],
              [
                -47.143649091163596,
                -7.903726483025397
              ],
              [
                -47.14365810002837,
                -7.90379196260387
              ],
              [
                -47.14485656405643,
                -7.912477898698823
              ],
              [
                -47.14066459486371,
                -7.924886319940417
              ],
              [
                -47.131977656884715,
                -7.921841341255192
              ],
              [
                -47.13193919019482,
                -7.921827851305397
              ],
              [
                -47.13129468939021,
                -7.921601827145954
              ],
              [
                -47.12510373093583,
                -7.924754329220467
              ],
              [
                -47.12110376897548,
                -7.921573831126987
              ],
              [
                -47.11607827064511,
                -7.938509718742318
              ],
              [
                -47.106802862101475,
                -7.936590724056606
              ],
              [
                -47.111648319957965,
                -7.945288157736563
              ],
              [
                -47.11166781877175,
                -7.945323685522598
              ],
              [
                -47.11549476232235,
                -7.952192124219946
              ],
              [
                -47.111855312705,
                -7.958796592155963
              ],
              [
                -47.10698435279161,
                -7.95432311766153
              ],
              [
                -47.10694634012064,
                -7.954287610856717
              ],
              [
                -47.105333858051964,
                -7.95280763996479
              ],
              [
                -47.101584864606444,
                -7.954150624369563
              ],
              [
                -47.10437235741563,
                -7.960767560676969
              ],
              [
                -47.10127437890033,
                -7.966109548450055
              ],
              [
                -47.10257785110051,
                -7.96684703491489
              ],
              [
                -47.10262037474876,
                -7.966870520803307
              ],
              [
                -47.10626485455279,
                -7.968932999917734
              ],
              [
                -47.09873986837095,
                -7.978027434575786
              ],
              [
                -47.098652892935334,
                -7.978132436798129
              ],
              [
                -47.09736087231766,
                -7.97969343523838
              ],
              [
                -47.09788487662059,
                -7.9803394395216
              ],
              [
                -47.09792039230204,
                -7.980383436268276
              ],
              [
                -47.1060528164036,
                -7.990405863625676
              ],
              [
                -47.09107293169412,
                -7.988686851801496
              ],
              [
                -47.09103144701922,
                -7.988681867792404
              ],
              [
                -47.084331990484124,
                -7.987912865087032
              ],
              [
                -47.061857628878336,
                -8.00467671722309
              ],
              [
                -47.06181813790449,
                -8.004950729382257
              ],
              [
                -47.0585836309589,
                -8.027447565276656
              ],
              [
                -47.05235719871563,
                -8.033631041340005
              ],
              [
                -47.05228220621907,
                -8.033705027777886
              ],
              [
                -47.05055369781093,
                -8.035422025744467
              ],
              [
                -47.05121268416656,
                -8.036947005393715
              ],
              [
                -47.05124119387855,
                -8.037013501985802
              ],
              [
                -47.05310066212749,
                -8.041317499597579
              ],
              [
                -47.04444073571419,
                -8.04647843490364
              ],
              [
                -47.04307424039651,
                -8.053195911387665
              ],
              [
                -47.0241383794079,
                -8.05634987942492
              ],
              [
                -47.02038741557559,
                -8.060069327172833
              ],
              [
                -47.016259932353755,
                -8.058218320140817
              ],
              [
                -47.016233446570645,
                -8.058206346192627
              ],
              [
                -47.01412197885045,
                -8.057259853267041
              ],
              [
                -47.00954100071423,
                -8.060707327954782
              ],
              [
                -47.008109013674684,
                -8.061785297414618
              ],
              [
                -47.00164107630337,
                -8.057236349741116
              ],
              [
                -47.00082754268302,
                -8.065940264703514
              ],
              [
                -46.996997597360725,
                -8.066836781916098
              ],
              [
                -46.990324644056244,
                -8.051068867799232
              ],
              [
                -46.988805169466175,
                -8.047478895865073
              ],
              [
                -46.985540708479476,
                -8.048669865721147
              ],
              [
                -46.98154471887609,
                -8.05012735408153
              ],
              [
                -46.97982423623576,
                -8.045170404939798
              ],
              [
                -46.96238787222588,
                -8.039094427244793
              ],
              [
                -46.96565835180149,
                -8.034325944936656
              ],
              [
                -46.95388546050335,
                -8.025805006112028
              ],
              [
                -46.95546793553349,
                -8.018662028738747
              ],
              [
                -46.95601995079774,
                -8.01617106169736
              ],
              [
                -46.95297098944742,
                -8.006252609934565
              ],
              [
                -46.949066523840344,
                -8.008023621332336
              ],
              [
                -46.945220037718116,
                -8.004936614786228
              ],
              [
                -46.940966082185994,
                -7.996485689173169
              ],
              [
                -46.92324174025496,
                -7.99157069372109
              ],
              [
                -46.922192234795766,
                -7.993128704395468
              ],
              [
                -46.92097173440199,
                -7.994940661223766
              ],
              [
                -46.92132725314291,
                -7.992481191793118
              ],
              [
                -46.92240774135453,
                -7.985002719178169
              ],
              [
                -46.9178607612024,
                -7.981371240550216
              ],
              [
                -46.90896484833267,
                -7.980079752129217
              ],
              [
                -46.907029347306086,
                -7.983049244601922
              ],
              [
                -46.90476989228143,
                -7.986517201461676
              ],
              [
                -46.895785430811,
                -7.986513209457562
              ],
              [
                -46.88888000624313,
                -7.974650258779692
              ],
              [
                -46.88224157455469,
                -7.972738773732527
              ],
              [
                -46.88224155291491,
                -7.96940932270422
              ],
              [
                -46.88224156468745,
                -7.963685839477749
              ],
              [
                -46.87232867900223,
                -7.955169392771275
              ],
              [
                -46.86898168705485,
                -7.956021895793219
              ],
              [
                -46.87069066448356,
                -7.961323840499057
              ],
              [
                -46.869149672955544,
                -7.96376284785385
              ],
              [
                -46.86588418702478,
                -7.968932780451966
              ],
              [
                -46.861344731924945,
                -7.963226824575504
              ],
              [
                -46.85853777021332,
                -7.959699353057535
              ],
              [
                -46.85035683130707,
                -7.956975371860398
              ],
              [
                -46.845898866604855,
                -7.962165824116638
              ],
              [
                -46.843512363288106,
                -7.964945285369414
              ],
              [
                -46.839515893060174,
                -7.965126308171928
              ],
              [
                -46.83833440216752,
                -7.961199309929344
              ],
              [
                -46.83771641002304,
                -7.959144819883041
              ],
              [
                -46.835385431864566,
                -7.960350803583577
              ],
              [
                -46.83076697723648,
                -7.962738801185377
              ],
              [
                -46.815780603429914,
                -7.954706357274993
              ],
              [
                -46.81376361714599,
                -7.953625348404259
              ],
              [
                -46.81057962682637,
                -7.951628374423151
              ],
              [
                -46.80712264880476,
                -7.950445339790558
              ],
              [
                -46.80644968525157,
                -7.950214868642059
              ],
              [
                -46.80159870950397,
                -7.949560350124605
              ],
              [
                -46.80127722805292,
                -7.950498358601489
              ],
              [
                -46.79972372953488,
                -7.955032825069041
              ],
              [
                -46.79191929709569,
                -7.952975332875941
              ],
              [
                -46.78886182495433,
                -7.946845875359016
              ],
              [
                -46.78879932056485,
                -7.946720387126572
              ],
              [
                -46.78874829886801,
                -7.946812370467272
              ],
              [
                -46.78616783823031,
                -7.951432840332415
              ],
              [
                -46.78299435929656,
                -7.950970825219267
              ],
              [
                -46.7813593722943,
                -7.944675396229048
              ],
              [
                -46.773438937662824,
                -7.942722388249103
              ],
              [
                -46.772431433829745,
                -7.941371880180011
              ],
              [
                -46.76784297458169,
                -7.935215942342356
              ],
              [
                -46.76699398108475,
                -7.938288424163299
              ],
              [
                -46.76675248795186,
                -7.938293919886284
              ],
              [
                -46.762910514661804,
                -7.938376911508241
              ],
              [
                -46.76167155311385,
                -7.935539933050129
              ],
              [
                -46.75574460366538,
                -7.92197151297268
              ],
              [
                -46.746279663734605,
                -7.92631099082582
              ],
              [
                -46.741575183016344,
                -7.922523974226128
              ],
              [
                -46.73920523856179,
                -7.923363000313108
              ],
              [
                -46.73703722361251,
                -7.924130961071318
              ],
              [
                -46.73414926652615,
                -7.920622483439192
              ],
              [
                -46.73243375591786,
                -7.918539491532756
              ],
              [
                -46.73097679916359,
                -7.919598001073108
              ],
              [
                -46.726106803412684,
                -7.9231354527993
              ],
              [
                -46.723797848798704,
                -7.91866397912885
              ],
              [
                -46.72166037551874,
                -7.914526036423737
              ],
              [
                -46.71260544054998,
                -7.913762009498304
              ],
              [
                -46.70802649430623,
                -7.909535556968055
              ],
              [
                -46.69834105067777,
                -7.915352489798591
              ],
              [
                -46.69657555632388,
                -7.916413012015965
              ],
              [
                -46.69079861321063,
                -7.91437051182408
              ],
              [
                -46.68502263309268,
                -7.912328488634025
              ],
              [
                -46.68345115207413,
                -7.912549002613082
              ],
              [
                -46.67280724723413,
                -7.914043499144753
              ],
              [
                -46.67050327782021,
                -7.909338007071232
              ],
              [
                -46.66773980790592,
                -7.903695543764269
              ],
              [
                -46.66013987144297,
                -7.900171081624045
              ],
              [
                -46.65407189750508,
                -7.90526252983011
              ],
              [
                -46.6502119132629,
                -7.908501511513008
              ],
              [
                -46.64033451035547,
                -7.906139516405434
              ],
              [
                -46.63731102817191,
                -7.903806013901296
              ],
              [
                -46.63014008240313,
                -7.898271045551901
              ],
              [
                -46.60424630613344,
                -7.895786522452112
              ],
              [
                -46.595788837838604,
                -7.899858496039969
              ],
              [
                -46.59333437321065,
                -7.902585483495589
              ],
              [
                -46.59041737766732,
                -7.905826949074954
              ],
              [
                -46.58632394152685,
                -7.906803932534309
              ],
              [
                -46.58198944520546,
                -7.907838933369117
              ],
              [
                -46.57959697581302,
                -7.910851928981931
              ],
              [
                -46.57560800683582,
                -7.915874879658667
              ],
              [
                -46.57320251671721,
                -7.914699381087472
              ],
              [
                -46.57188454161042,
                -7.914055380061984
              ],
              [
                -46.561961596740076,
                -7.920141330793366
              ],
              [
                -46.55769561735192,
                -7.92573728537931
              ],
              [
                -46.55742261575583,
                -7.934477250033688
              ],
              [
                -46.557304638887764,
                -7.938268714726094
              ],
              [
                -46.549027187465065,
                -7.949108155491308
              ],
              [
                -46.54339923207306,
                -7.948299137273299
              ],
              [
                -46.51830142305532,
                -7.958686055301305
              ],
              [
                -46.49170659751505,
                -7.981302861504273
              ],
              [
                -46.48550661094068,
                -7.994127277087103
              ],
              [
                -46.48585361674468,
                -8.000586733508879
              ],
              [
                -46.48596811371506,
                -8.002712229499991
              ],
              [
                -46.485439601319705,
                -8.003222233583047
              ],
              [
                -46.47653618203618,
                -8.011824139524956
              ],
              [
                -46.47932463572572,
                -8.04213845295629
              ],
              [
                -46.47934913427203,
                -8.042398440254432
              ],
              [
                -46.47855814976696,
                -8.047015931799063
              ],
              [
                -46.47776762925685,
                -8.051632386352761
              ],
              [
                -46.47608767326795,
                -8.053688364013269
              ],
              [
                -46.46618969614166,
                -8.06580228362713
              ],
              [
                -46.4670832043802,
                -8.078416703789596
              ],
              [
                -46.4699941576626,
                -8.084351144340001
              ],
              [
                -46.474963634397675,
                -8.08585814435543
              ],
              [
                -46.48196408368152,
                -8.087980653340695
              ],
              [
                -46.48874250734569,
                -8.097330085172034
              ],
              [
                -46.49154697123812,
                -8.115237467265914
              ],
              [
                -46.51421472994778,
                -8.166671654556357
              ],
              [
                -46.511769769577896,
                -8.178746599125246
              ],
              [
                -46.50970874888451,
                -8.181419554326283
              ],
              [
                -46.504453295177925,
                -8.183028022308012
              ],
              [
                -46.49809786269978,
                -8.184973505933005
              ],
              [
                -46.487135439153256,
                -8.199514412458248
              ],
              [
                -46.48834042564189,
                -8.20350690626423
              ],
              [
                -46.489544907134935,
                -8.207496860089698
              ],
              [
                -46.494190375226054,
                -8.21204832973091
              ],
              [
                -46.5020748007924,
                -8.219773300709628
              ],
              [
                -46.50538126910904,
                -8.228388231121551
              ],
              [
                -46.50868773141684,
                -8.237002681553198
              ],
              [
                -46.50716369962405,
                -8.27037496637936
              ],
              [
                -46.52053106810469,
                -8.290853829917573
              ],
              [
                -46.52698652893171,
                -8.295519809794595
              ],
              [
                -46.53344048177226,
                -8.300185279684436
              ],
              [
                -46.53699895957194,
                -8.306378746495167
              ],
              [
                -46.5442103692496,
                -8.318930701157676
              ],
              [
                -46.55129931293062,
                -8.32188815501937
              ],
              [
                -46.55838927160805,
                -8.324845648889115
              ],
              [
                -46.56841468170564,
                -8.323730157679117
              ],
              [
                -46.578429102900664,
                -8.322615686450263
              ],
              [
                -46.58496303299401,
                -8.325511165164466
              ],
              [
                -46.59017598798841,
                -8.327821155367943
              ],
              [
                -46.60638389161167,
                -8.328038194824181
              ],
              [
                -46.61157784594234,
                -8.32637319020622
              ],
              [
                -46.63480966302149,
                -8.318925771465828
              ],
              [
                -46.64702506677645,
                -8.325815715822666
              ],
              [
                -46.65924096253576,
                -8.332706686203029
              ],
              [
                -46.667065406179375,
                -8.334151706860624
              ],
              [
                -46.67785380021225,
                -8.336145198009497
              ],
              [
                -46.681425281258186,
                -8.340125675555493
              ],
              [
                -46.68499625930706,
                -8.344106142106751
              ],
              [
                -46.69053169336614,
                -8.345925621929563
              ],
              [
                -46.70638309968821,
                -8.351136620255195
              ],
              [
                -46.71045702413547,
                -8.35861658235835
              ],
              [
                -46.71230551518679,
                -8.362010554991638
              ],
              [
                -46.72122993036604,
                -8.368145022045667
              ],
              [
                -46.72639940404207,
                -8.382465940411372
              ],
              [
                -46.73218932409454,
                -8.385269923086561
              ],
              [
                -46.738302785247676,
                -8.377122969006534
              ],
              [
                -46.740346763900526,
                -8.376625481420742
              ],
              [
                -46.75080819335742,
                -8.374081505114646
              ],
              [
                -46.760789617658524,
                -8.378045473584399
              ],
              [
                -46.78200397896205,
                -8.367689579839496
              ],
              [
                -46.7861039344049,
                -8.374049542418314
              ],
              [
                -46.78622491420134,
                -8.381841980425962
              ],
              [
                -46.79129889767229,
                -8.385755476097646
              ],
              [
                -46.78930238690747,
                -8.388823460873981
              ],
              [
                -46.785291405488096,
                -8.394985406236652
              ],
              [
                -46.78658888946501,
                -8.398704405215886
              ],
              [
                -46.79184737762327,
                -8.402496341898347
              ],
              [
                -46.79457586035655,
                -8.404463851878091
              ],
              [
                -46.80640824581273,
                -8.39857590595906
              ],
              [
                -46.79829481208175,
                -8.412368318990321
              ],
              [
                -46.79918228458151,
                -8.42232473167769
              ],
              [
                -46.79643631574445,
                -8.427147219172083
              ],
              [
                -46.795054821874594,
                -8.429574167807235
              ],
              [
                -46.80154823849006,
                -8.443607598749178
              ],
              [
                -46.796133273701585,
                -8.44623408780343
              ],
              [
                -46.815106123056474,
                -8.4699614495972
              ],
              [
                -46.820358564544144,
                -8.465080458129263
              ],
              [
                -46.824692522550485,
                -8.473812403597424
              ],
              [
                -46.82981501687142,
                -8.46723949405744
              ],
              [
                -46.83190397254469,
                -8.464558979819245
              ],
              [
                -46.83470545940815,
                -8.468665986926759
              ],
              [
                -46.84011191416564,
                -8.46522951116191
              ],
              [
                -46.84069243120903,
                -8.473103947265892
              ],
              [
                -46.850437314489675,
                -8.47696543238851
              ],
              [
                -46.84591336567772,
                -8.501021258274486
              ],
              [
                -46.850575313044885,
                -8.503320762224398
              ],
              [
                -46.848453317454606,
                -8.50707221943583
              ],
              [
                -46.84649833988636,
                -8.510528723753549
              ],
              [
                -46.85107029973013,
                -8.514882192193069
              ],
              [
                -46.85173527788918,
                -8.515515670765106
              ],
              [
                -46.85137177939917,
                -8.515801672507186
              ],
              [
                -46.84292482872052,
                -8.522456139978578
              ],
              [
                -46.85040779647553,
                -8.525425090594332
              ],
              [
                -46.84665330868269,
                -8.532738541788753
              ],
              [
                -46.8516587576404,
                -8.53663903767716
              ],
              [
                -46.852390261402846,
                -8.542699498877916
              ],
              [
                -46.85965820558317,
                -8.544966988874547
              ],
              [
                -46.85786071482858,
                -8.551139455639067
              ],
              [
                -46.862413645327116,
                -8.558690404237398
              ],
              [
                -46.872628095460456,
                -8.555620455264274
              ],
              [
                -46.87502406466595,
                -8.55490093653764
              ],
              [
                -46.87416655259852,
                -8.557401935291944
              ],
              [
                -46.87246859738204,
                -8.56235540413647
              ],
              [
                -46.87736903543716,
                -8.564151889689045
              ],
              [
                -46.87886200991173,
                -8.571463848588524
              ],
              [
                -46.88244199503289,
                -8.566982353684491
              ],
              [
                -46.88291350901977,
                -8.566391879044838
              ],
              [
                -46.88553847058138,
                -8.571071831341735
              ],
              [
                -46.88860893782434,
                -8.576544318955836
              ],
              [
                -46.88882292370673,
                -8.57604732442825
              ],
              [
                -46.8913314426061,
                -8.570218865149645
              ],
              [
                -46.89315938593605,
                -8.578029319179311
              ],
              [
                -46.89740886583879,
                -8.576340819775037
              ],
              [
                -46.89713934562089,
                -8.583048265745708
              ],
              [
                -46.90182632020944,
                -8.5858797683452
              ],
              [
                -46.899647326131166,
                -8.590433240305474
              ],
              [
                -46.908524275543776,
                -8.590243737133257
              ],
              [
                -46.91339024026552,
                -8.586184768847604
              ],
              [
                -46.91227125019165,
                -8.59145074531589
              ],
              [
                -46.9159712152311,
                -8.595382234694558
              ],
              [
                -46.91012975069912,
                -8.595195220596345
              ],
              [
                -46.90935626542293,
                -8.598190202240946
              ],
              [
                -46.903535287611334,
                -8.595370712322056
              ],
              [
                -46.902276797208636,
                -8.596429701058678
              ],
              [
                -46.89729784122456,
                -8.600618146372641
              ],
              [
                -46.902118817209974,
                -8.603043643779806
              ],
              [
                -46.89735835530187,
                -8.604695150864286
              ],
              [
                -46.89830382562356,
                -8.607760613908678
              ],
              [
                -46.90493877450626,
                -8.608395133957757
              ],
              [
                -46.901471295571895,
                -8.612792100550363
              ],
              [
                -46.90460676475291,
                -8.616007584996494
              ],
              [
                -46.90548727673478,
                -8.615271072749437
              ],
              [
                -46.907469744184255,
                -8.61361359369682
              ],
              [
                -46.906825751934335,
                -8.619316061847107
              ],
              [
                -46.911466710313704,
                -8.619000574934521
              ],
              [
                -46.91146419320721,
                -8.626419517607138
              ],
              [
                -46.91666618521224,
                -8.632515964551766
              ],
              [
                -46.91126571563428,
                -8.639286933593622
              ],
              [
                -46.91596414564606,
                -8.648008359919675
              ],
              [
                -46.90574773030025,
                -8.653944824153523
              ],
              [
                -46.904493732456295,
                -8.653547834373123
              ],
              [
                -46.90219026611147,
                -8.652819323609226
              ],
              [
                -46.90298576312272,
                -8.658115277995144
              ],
              [
                -46.90346621687851,
                -8.661315281686282
              ],
              [
                -46.90204175629684,
                -8.66097278136413
              ],
              [
                -46.895265806614994,
                -8.659343769589501
              ],
              [
                -46.895804817360116,
                -8.664866738218302
              ],
              [
                -46.888745357110395,
                -8.669391201067485
              ],
              [
                -46.886522347942126,
                -8.686091085930393
              ],
              [
                -46.89032731420267,
                -8.690836582202525
              ],
              [
                -46.8940392920867,
                -8.692046551385882
              ],
              [
                -46.9001927351649,
                -8.694053072057692
              ],
              [
                -46.913941102968245,
                -8.708185488183167
              ],
              [
                -46.9138445902948,
                -8.712179973103973
              ],
              [
                -46.91360710220483,
                -8.722046901693853
              ],
              [
                -46.91682557319086,
                -8.725715371379495
              ],
              [
                -46.91760004893516,
                -8.725943371747693
              ],
              [
                -46.92213151733476,
                -8.72727688105578
              ],
              [
                -46.92816846226367,
                -8.723026389307554
              ],
              [
                -46.93085343776479,
                -8.726355379621706
              ],
              [
                -46.92443698009006,
                -8.743825775055727
              ],
              [
                -46.91752153383899,
                -8.750886692572022
              ],
              [
                -46.916884047363,
                -8.762042609400115
              ],
              [
                -46.91426056190834,
                -8.762753139892986
              ],
              [
                -46.90916209889514,
                -8.7641340863022
              ],
              [
                -46.910852091069486,
                -8.768373603626937
              ],
              [
                -46.90591259248712,
                -8.773646030931356
              ],
              [
                -46.90223713217104,
                -8.79050192640169
              ],
              [
                -46.90515509481013,
                -8.79473891911689
              ],
              [
                -46.90613157165397,
                -8.796156386994095
              ],
              [
                -46.904527592498646,
                -8.803837853350398
              ],
              [
                -46.90237709964106,
                -8.81413775910982
              ],
              [
                -46.90849003001943,
                -8.818795735658087
              ],
              [
                -46.91324698807062,
                -8.82242073041411
              ],
              [
                -46.9101660269267,
                -8.825123709457026
              ],
              [
                -46.90685553033768,
                -8.828027700939932
              ],
              [
                -46.90938053806653,
                -8.834878151300884
              ],
              [
                -46.91273798208158,
                -8.834835145310816
              ],
              [
                -46.91385347844869,
                -8.834820652644442
              ],
              [
                -46.91765745592204,
                -8.83929962482223
              ],
              [
                -46.91777494170833,
                -8.841348108667633
              ],
              [
                -46.91788546468063,
                -8.843278615270979
              ],
              [
                -46.9130219927237,
                -8.847427069956746
              ],
              [
                -46.917131927825146,
                -8.855450532506097
              ],
              [
                -46.941127767717816,
                -8.859080529718625
              ],
              [
                -46.95929708889345,
                -8.875049430551282
              ],
              [
                -46.964211547215626,
                -8.86849600647969
              ],
              [
                -46.96513153147285,
                -8.867268988453084
              ],
              [
                -46.97904392234497,
                -8.868465515240459
              ],
              [
                -46.992123315133675,
                -8.879003457649802
              ],
              [
                -46.98564035917159,
                -8.887994896211813
              ],
              [
                -46.988517858100586,
                -8.892219869096419
              ],
              [
                -46.984430853825614,
                -8.895690337064192
              ],
              [
                -46.98596835292908,
                -8.89832682172952
              ],
              [
                -46.98235689812559,
                -8.902541766412748
              ],
              [
                -46.97977841106053,
                -8.9025762732986
              ],
              [
                -46.97519144952603,
                -8.90263726876159
              ],
              [
                -46.9698594618414,
                -8.91409319066164
              ],
              [
                -46.981958865882795,
                -8.91957068681636
              ],
              [
                -46.98349335843042,
                -8.931503094380515
              ],
              [
                -46.995668237352845,
                -8.93218109269395
              ],
              [
                -46.996631249832696,
                -8.932235106428369
              ],
              [
                -46.99642174116478,
                -8.933369095862647
              ],
              [
                -46.99424575162798,
                -8.945126511422284
              ],
              [
                -47.004291679077205,
                -8.945780010520057
              ],
              [
                -47.01082412000565,
                -8.94620501013713
              ],
              [
                -47.012580094904756,
                -8.951907008282909
              ],
              [
                -47.017851048372755,
                -8.952536507162975
              ],
              [
                -47.015212057618484,
                -8.959708948862284
              ],
              [
                -47.01284006162078,
                -8.966155877550193
              ],
              [
                -47.01494004119835,
                -8.97591583290573
              ],
              [
                -47.02113400746841,
                -8.980081800020152
              ],
              [
                -47.02113098514497,
                -8.987369282981064
              ],
              [
                -47.03102090672557,
                -8.985490274307995
              ],
              [
                -47.03132492651205,
                -8.980129832244042
              ],
              [
                -47.031358430933956,
                -8.979534846121753
              ],
              [
                -47.03527240076964,
                -8.978543363949969
              ],
              [
                -47.03384389215482,
                -8.980877834268352
              ],
              [
                -47.03295839935254,
                -8.982324800928529
              ]
            ]
          ]
        ]
      }
    }
  ]
}