import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Form from '../elements/Form';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Gerador(props) {
    let abrir = props ? true : false;

    const [open, setOpen] = React.useState(abrir);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    function abrirMapa() {
        window.open('https://monir.provedor.space', '_blank');
    }
    return (
        <div >
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Montagem de carga
            </Button> */}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Montagem de carga
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => abrirMapa()}>
                            Abrir mapa
                        </Button>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Form veiculos={props} fecharModal={handleClose} />
            </Dialog>
        </div>
    );
}