import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { logout, getUser } from "../../../../services/contaService";
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';

export default function NavBar() {
    const navigate = useNavigate();
    const [user, setUser] = useState('');

    useEffect(() => {
        setUser(getUser());
    }, [])

    function acessarPagina(rota) {
        navigate(rota);
    }

    function deslogar() {
        logout();
        navigate('/login');
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Visão de micro
                    </Typography>
                    <MenuItem onClick={() => acessarPagina('/')}>
                        <Typography textAlign="start">Mapa</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => acessarPagina('/cargas')}>
                        <Typography textAlign="start">Cargas</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => acessarPagina('/roterizacao/gestao')}>
                        <Typography textAlign="start">Roterizações</Typography>
                    </MenuItem>
                    <Button color="inherit" onClick={() => deslogar()}>
                        <Tooltip title={user}><div>Sair</div></Tooltip>
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    )
} 