import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import toast from 'react-hot-toast';
import { useMap } from "react-leaflet";
import { maxZoom } from "../../plugins/leafletUtils";
import { listaSelectRapido } from "../../services/mapaService";

export default function FormBuscaRapida(props) {
    let abrir = props ? true : false;
    const veiculosRemocao = props.veiculosRemocao;
    const veiculosPositron = props.veiculosPositron;
    const [open, setOpen] = useState(abrir);
    const map = useMap();




    function buscarRapidaRemoca(event) {
        event.preventDefault();

        let termo = event.target.termo?.value

        let tipoBusca = event.target?.tipoBusca?.value

        if (!termo) {
            toast('informe texto');
            return;
        }
        termo = termo.toUpperCase().toString();
        let remocaoCodEstoque = 0
        switch (tipoBusca) {
            case 'chassi':
                remocaoCodEstoque = veiculosRemocao.find(r => r.chassi == termo)?.estoque ?? 0;
                break;

            case 'codEstoque':
                remocaoCodEstoque = veiculosRemocao.find(r => r.estoque == termo)?.estoque ?? 0;
                break;
            default: //placa
                remocaoCodEstoque = veiculosRemocao.find(r => r.placa == termo)?.estoque ?? 0;
                break;
        }

        if (remocaoCodEstoque === 0) {
            toast(`Veiculo não encontrado de acordo com ${termo} do tipo ${tipoBusca}`)
            return;
        }

        props.upDialogMarker(remocaoCodEstoque);

        fecharBuscaRapida();
    }

    function buscarRapidaPositron(event) {
        event.preventDefault();

        let termo = event.target.termo?.value

        if (!termo) {
            toast('informe placa');
            return;
        }

        termo = termo.toUpperCase().toString();

        let positron = veiculosPositron.find(r => r.placa == termo) ?? null;



        if (!positron) {
            toast(`Veiculo ${termo} não encontrado na tela`)
            return;
        }


        map.flyTo([positron.lat, positron.lng], maxZoom);

        fecharBuscaRapida();
    }


    function fecharBuscaRapida() {
        setOpen(false);
        props.fecharFormBuscaRapida(false);
    }

    return (
        <Dialog
            open={open}
            onClose={(e) => { fecharBuscaRapida() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title-busca-rapida">
                {"Busca rápida"}
            </DialogTitle>
            <DialogContent>
                <Typography>Remoção</Typography>
                <form method='post' onSubmit={buscarRapidaRemoca} >
                    <Box
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="filled-select-filtro-rapido"
                            select
                            label="Por"
                            defaultValue="placa"
                            variant="filled"
                            name="tipoBusca"

                        >
                            {listaSelectRapido.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="standard-basic"
                            name="termo"
                            label="placa, chassi, cod estoque"
                            variant="standard"
                        />
                    </Box>
                    <Button variant='contained' type='submit'>Buscar remoção</Button>
                </form>
                <br />
                <Divider />
                <Typography>Motorista</Typography>
                <form method='post' onSubmit={buscarRapidaPositron} >
                    <Box
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="standard-basic"
                            name="termo"
                            label="Placa do motorista"
                            variant="standard"
                        />
                    </Box>
                    <Button variant='contained' type='submit'>Buscar motorista</Button>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => { fecharBuscaRapida() }}>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}