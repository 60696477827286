import { TileLayer, LayersControl } from "react-leaflet";

const { BaseLayer } = LayersControl;

export default function Tiles() {
    return (
        <LayersControl>
            <BaseLayer checked name='OpenStreetMap'>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
            </BaseLayer>

            <BaseLayer name="NASA Gibs Blue Marble">
                <TileLayer
                    url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg"
                    attribution="&copy; NASA Blue Marble, https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}"
                />
            </BaseLayer>

            <BaseLayer name="Eris World Street Map">
                <TileLayer
                    url="http://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}.jpeg"
                    attribution="&copy; http://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                />
            </BaseLayer>

            <BaseLayer name="Eris DeLorme World Base Map">
                <TileLayer
                    url="http://services.arcgisonline.com/arcgis/rest/services/Specialty/DeLorme_World_Base_Map/MapServer/tile/{z}/{y}/{x}.jpeg"
                    attribution="&copy; http://services.arcgisonline.com/arcgis/rest/services/Specialty/DeLorme_World_Base_Map/MapServer/tile/{z}/{y}/{x}"
                />
            </BaseLayer>

            <BaseLayer name="Eris World Imagery">
                <TileLayer
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                    attribution="&copy; http://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
            </BaseLayer>

            <BaseLayer name="Eris World Topo Map">
                <TileLayer
                    url="http://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.jpeg"
                    attribution="&copy; http://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                />
            </BaseLayer>
        </LayersControl>
    )
}