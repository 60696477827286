import { useEffect, useState, useRef } from 'react';
import { MapContainer, Marker, Popup, ZoomControl, GeoJSON } from "react-leaflet";
import { HubConnectionBuilder } from '@microsoft/signalr';
import MarkerClusterGroup from 'react-leaflet-cluster'
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import toast, { Toaster } from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import {
    carregarVeiculosRemocao,
    carregaPontosVips,
    carregaIconTelemetria,
    carregaIconRemocao,
    calculator,
    carregarRemocoesPorSituacoes,
    atualizarCacheListaRemocoes
} from "../../services/mapaService";
import './index.css'
import { CardTelemetria } from "../../components/Card";
import Icon from "../../components/Icon";
import Apresentacao from "../../components/Apresentacao";
import FormBuscaRapida from "../../components/FormBuscaRapida"
// import FormBuscaAvancada from "../../components/FormBuscaAvancada"
import AlertDialogMarker from "../../components/DialogMarker";
import { urlApi } from "../../plugins/urlApi";
import DialogAlertaProximos from "../../components/DialogAlertas";
import ResponsiveAppBar from "../../components/Bar";
import FullscreenControl from "../../plugins/LeafletCustom/FullscreenControl"
import Tiles from '../../components/Tiles'
import PainelLateralForm from '../../components/PainelLateralForm';
import { CamadaEstados } from "../../data_sets/estados";
import { marginLeftMapaPainel } from "../../plugins/leafletUtils"
import { useNavigate } from 'react-router-dom';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';






export default function Home() {
    const navigate = useNavigate();
    const mapRef = useRef();
    const posicaoMap = [-10.217234, -48.330526];
    const [loading, setloading] = useState(false);
    const [connection, setConnection] = useState(null);
    const [veiculosRemocao, setVeiculosRemocao] = useState([]);
    const [veiculosRemocaoOriginais, setVeiculosRemocaoOriginais] = useState([]);
    const [veiculosRemocaoAgendados, setVeiculosRemocaoAgendados] = useState([]);
    const [veiculosRemocaoVencidos, setVeiculosRemocaoVencidos] = useState([]);
    const [veiculosRemocaoAguardandos, setVeiculosRemocaoAguardandos] = useState([]);
    const [veiculoRemocao, setVeiculoRemocao] = useState(null);
    const [veiculosPositron, setVeiculosPositron] = useState([]);
    const vips = carregaPontosVips();
    const isCloseBanner = localStorage.getItem('banner') ? JSON.parse(localStorage.getItem('banner')) : true;
    const [showDialogMarker, setShowDialogMarker] = useState(false);
    const [showDialogAlertProximo, setShowDialogAlertProximo] = useState(false);
    const [listaAlertaProximoOriginar, setListaAlertaProximoOriginar] = useState([]);
    const [dialogFerramentasFiltroRapido, setDialogFerramentasFiltroRapido] = useState(false);
    const [dialogFerramentasFiltroAvancado, setDialogFerramentasFiltroAvancado] = useState(false);




    // inicializa conexao tracker
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${urlApi}hubs/tracker-positron`)
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
    }, []);

    // tracker positron
    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection.on('tracker', carPositron => {
                        let possuiVeiculos = veiculosPositron.length;   
                        if (possuiVeiculos) {
                            // let indexMotorista = veiculosPositron.findIndex(motorista => motorista.placa == carPositron.placa && (motorista.lat !== carPositron.lat && motorista.lng !== carPositron.lng));
                            let indexMotorista = veiculosPositron.findIndex(motorista => motorista.placa == carPositron.placa);
                            if (indexMotorista !== -1) {
                                if (indexMotorista) {
                                    veiculosPositron[indexMotorista] = carPositron;
                                }
                            } else {
                                // console.log('novo veiculo => ' + carPositron.placa)
                                veiculosPositron.push(carPositron);
                            }

                        } else {
                            // console.log('inicializou mapa')
                            veiculosPositron.push(carPositron);
                        }
                        // let totalMotoristaAtivos = veiculosPositron.filter(ps => ps.igniciao);
                        // setContadorMotoristaVipPositronAtivos(totalMotoristaAtivos)
                        setVeiculosPositron([...veiculosPositron]);
                    });
                })
                .catch(e => {
                    console.error('Connection failed : ' + e)
                    toast.error('Erro ao conectar servidor, positron.');
                });
        }
    }, [connection]);


    // carrega veiculos de remocao
    useEffect(() => {
        setloading(true);
        carregarMapa();

    }, []);

    function carregarMapa(){
        let promisseRemocao = carregarVeiculosRemocao()
        .then(body => {
            let veiculosRemocao = body.data.estoqueRemocaoMapaGeral;
            if (veiculosRemocao) {
                let novaLista = veiculosRemocao.filter(remocao => remocao.coordenada !== null)
                setVeiculosRemocaoOriginais([...novaLista]);
                montarVeiculosRemocoesMapa(novaLista);

            }
        })
        .catch(e => {
            console.error('Connection failed : ' + e)
            toast.error('Erro ao conectar servidor, remoçoes.');
            setloading(false);

        }).finally(() => setloading(false));

    toast.promise(promisseRemocao, { loading: 'Carregando lotes..' })
    }
    function montarVeiculosRemocoesMapa(novaLista) {
        if (novaLista.length) {
            setVeiculosRemocao([...novaLista]);
            let { remocoesAgendado, remocoesAguardando, remocoesVencido } = carregarRemocoesPorSituacoes(novaLista);
            setVeiculosRemocaoAguardandos([...remocoesAguardando])
            setVeiculosRemocaoVencidos([...remocoesVencido])
            setVeiculosRemocaoAgendados([...remocoesAgendado])
        }
    }

    function upDialogMarker(codigoEstoque) {
        let indexRemocao = veiculosRemocao.findIndex(e => e.estoque === codigoEstoque)
        let remocao = veiculosRemocao[indexRemocao];
        setShowDialogMarker(true);
        setVeiculoRemocao({ remocao })
    }

    function downDialogMarker(close) {
        setShowDialogMarker(false);
        setVeiculoRemocao(null);
    }


    function downDialogListaAlertas() {
        setShowDialogAlertProximo(false);
    }

    function limparDialogListaAlertas() {
        downDialogListaAlertas();
        setListaAlertaProximoOriginar([]);
    }

    function centralizarMapa(event) {
        if (mapRef.current) {
            let map = mapRef.current;
            map.setView(posicaoMap, 5);

            let promisseRemoverCache = atualizarCacheListaRemocoes().then(e => {
                carregarMapa();
            }).catch(e => {
                console.error('Connection failed : ' + e)
                toast.error('Erro ao atualizar remoçoes.');
            })
            toast.promise(promisseRemoverCache, { loading: 'Atualizando lotes..' });
        }
    }

    function carregarRemocoesOriginais() {
        montarVeiculosRemocoesMapa(veiculosRemocaoOriginais)
        centralizarMapa();
    }


    function carregarRemocoesFiltradasMapa(remocoes) {
        montarVeiculosRemocoesMapa(remocoes)

        // setVeiculosRemocao([...remocoes]);
        //  centralizarMapa();
    }

    function fecharCaixaFerramentas() {
        // carregarRemocoesOriginais();

        setDialogFerramentasFiltroAvancado(false)
    }

    function abrirPagina(path) {
        navigate(path);
    }


    return (
        <div className="Home">
            <Toaster
                position="bottom-left"
            />

            {
                !isCloseBanner &&
                <ResponsiveAppBar
                    remocao={veiculosRemocao}
                    motoristasVipPositron={veiculosPositron}
                    alertasMotoristaProximos={listaAlertaProximoOriginar}
                    abertoForm={dialogFerramentasFiltroAvancado}
                    listarRemocoesOriginais={(e) => carregarRemocoesOriginais()}
                />
            }


            {
                isCloseBanner &&
                <Apresentacao props={isCloseBanner} />
            }


            {/* mapa */}
            <div id='raiz-container-mapa'
                style={{
                    position: 'relative',
                    marginLeft: dialogFerramentasFiltroAvancado ? marginLeftMapaPainel : "",
                    width: 'auto'
                }}
            >
                <MapContainer
                    ref={mapRef}
                    center={posicaoMap}
                    zoom={5}
                    scrollWheelZoom={true}
                    zoomControl={false}
                >


                    <Tiles />

                    <ZoomControl position='bottomright' />

                    <GeoJSON attribution="Camadas de estados Qgis" data={CamadaEstados} style={{
                        fillColor: 'none',
                        weight: 3,
                        opacity: 1,
                        color: 'white',
                        fillOpacity: 0.7
                    }} />

                    {/* Ferramentas */}
                    <div className='leaflet-control-container'>
                        <div className="leaflet-top leaflet-left">
                            <Tooltip title="Busca rápida 🔍" placement="right-start">
                                <Button
                                    disabled={loading || dialogFerramentasFiltroAvancado || dialogFerramentasFiltroRapido}
                                    color='inherit'
                                    style={{
                                        backgroundColor: "#fff",
                                    }}
                                    className='leaflet-bar easy-button-container leaflet-control'
                                    variant="contained"
                                    onClick={(e) => setDialogFerramentasFiltroRapido(true)}
                                >
                                    <SearchIcon />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Ferramentas ⚒️" placement="left">
                                <Button
                                    loading={loading.toString()}
                                    disabled={loading || dialogFerramentasFiltroAvancado || dialogFerramentasFiltroRapido}
                                    color='inherit'
                                    style={{
                                        backgroundColor: "#fff",
                                    }}
                                    className='leaflet-bar easy-button-container leaflet-control'
                                    variant="contained"
                                    onClick={(e) => setDialogFerramentasFiltroAvancado(true)}
                                >
                                    <FilterAltIcon />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Centralizar mapa 🗺️" placement="left">
                                <Button
                                    color='inherit'
                                    style={{
                                        backgroundColor: "#fff",
                                    }}
                                    className='leaflet-bar leaflet-control'
                                    variant="contained"
                                    onClick={(e) => centralizarMapa(e)}
                                >
                                    <CrisisAlertIcon />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Abrir cargas do reboquista 🚙🧑🏻" placement="left">
                                <Button
                                    color='inherit'
                                    style={{
                                        backgroundColor: "#fff",
                                    }}
                                    className='leaflet-bar leaflet-control'
                                    variant="contained"
                                    onClick={(e) =>  abrirPagina('/cargas')}
                                >
                                    <RvHookupIcon/> 
                                </Button>
                            </Tooltip>

                            <Tooltip title="Abrir roterizações 📈" placement="left">
                                <Button
                                    color='inherit'
                                    style={{
                                        backgroundColor: "#fff",
                                    }}
                                    className='leaflet-bar leaflet-control'
                                    variant="contained"
                                    onClick={(e) => abrirPagina('/roterizacao/gestao')}
                                >
                                    <CorporateFareIcon/>
                                </Button>
                            </Tooltip>
                        </div>

                        <FullscreenControl />


                    </div>

                    {/* forms de Ferramentas */}
                    {/* busca rápida */}
                    {
                        dialogFerramentasFiltroRapido &&
                        <FormBuscaRapida
                            veiculosRemocao={veiculosRemocao}
                            veiculosPositron={veiculosPositron}
                            fecharFormBuscaRapida={(e) => setDialogFerramentasFiltroRapido(false)}
                            upDialogMarker={(codEstoque) => upDialogMarker(codEstoque)}
                        />
                    }

                    {
                        dialogFerramentasFiltroAvancado &&
                        <PainelLateralForm
                            props={veiculosRemocao}
                            fecharMenuLateral={(e) => fecharCaixaFerramentas()}
                            abertoPainelLateral={dialogFerramentasFiltroAvancado}
                            exibirSomenteFiltradosMapa={(remocoesFiltradas) => carregarRemocoesFiltradasMapa(remocoesFiltradas)}
                        />
                    }


                    {/* vips */}
                    <MarkerClusterGroup
                        chunkedLoading
                        key={1}
                    >
                        {
                            vips.map((vip, index) => (
                                <Marker
                                    key={index}
                                    icon={Icon({ props: { url: `${urlApi}images/svg/vip2.svg`, size1: 45, size2: 45 } })}
                                    position={vip.position}
                                >
                                    <Popup>
                                        {vip.nome}
                                    </Popup>
                                </Marker>
                            ))
                        }
                    </MarkerClusterGroup>

                    {/* remocoes */}

                    <MarkerClusterGroup
                        key={2}
                        chunkedLoading
                        iconCreateFunction={calculator}
                    >
                        {
                            veiculosRemocaoAgendados.length > 0 &&
                            veiculosRemocaoAgendados.map((remocao, index) => (
                                <Marker
                                    title={remocao?.situacao}
                                    key={index}
                                    posicao={index}
                                    icon={Icon({ props: { url: carregaIconRemocao(remocao), size1: 25, size2: 25 } })}
                                    position={[remocao?.coordenada.lat, remocao?.coordenada?.lng]}
                                    eventHandlers={{
                                        click: (e) => {
                                            upDialogMarker(remocao.estoque)
                                        },
                                    }}

                                >
                                </Marker>
                            ))
                        }
                    </MarkerClusterGroup>



                    <MarkerClusterGroup
                        key={3}
                        chunkedLoading
                        iconCreateFunction={calculator}
                    >
                        {
                            veiculosRemocaoAguardandos.length > 0 &&
                            veiculosRemocaoAguardandos.map((remocao, index) => (
                                <Marker
                                    title={remocao?.situacao}
                                    key={index}
                                    posicao={index}
                                    icon={Icon({ props: { url: carregaIconRemocao(remocao), size1: 25, size2: 25 } })}
                                    position={[remocao?.coordenada.lat, remocao?.coordenada?.lng]}
                                    eventHandlers={{
                                        click: (e) => {
                                            upDialogMarker(remocao.estoque)
                                        },
                                    }}

                                >
                                </Marker>
                            ))
                        }
                    </MarkerClusterGroup>

                    <MarkerClusterGroup
                        key={4}
                        chunkedLoading
                        iconCreateFunction={calculator}
                    >
                        {
                            veiculosRemocaoVencidos.length > 0 &&
                            veiculosRemocaoVencidos.map((remocao, index) => (
                                <Marker
                                    title={remocao?.situacao}
                                    key={index}
                                    posicao={index}
                                    icon={Icon({ props: { url: carregaIconRemocao(remocao), size1: 25, size2: 25 } })}
                                    position={[remocao?.coordenada.lat, remocao?.coordenada?.lng]}
                                    eventHandlers={{
                                        click: (e) => {
                                            upDialogMarker(remocao.estoque)
                                        },
                                    }}

                                >
                                </Marker>
                            ))
                        }
                    </MarkerClusterGroup>


                    {/* Dialog marker veiculos de remocao */}
                    {
                        showDialogMarker && veiculoRemocao &&
                        <AlertDialogMarker props={veiculoRemocao} handleCloseDialog={downDialogMarker} />
                    }


                    {/* <MarkerClusterGroup
                    chunkedLoading
                    key={3}
                > */}
                    {/* positron */}
                    {
                        veiculosPositron.length &&
                        veiculosPositron.map((positron, index) => (
                            <Marker
                                title={positron?.placa}
                                icon={Icon({ props: carregaIconTelemetria(positron) })}
                                key={index}
                                position={[positron?.lat, positron?.lng]}
                            >
                                <Popup>
                                    <CardTelemetria props={positron} />
                                </Popup>
                            </Marker>
                        ))}


                    {/* </MarkerClusterGroup> */}

                    {/* Dialog de lista de alerta de veiculos proximos          */}
                    {
                        showDialogAlertProximo && listaAlertaProximoOriginar.length &&
                        <DialogAlertaProximos
                            props={listaAlertaProximoOriginar}
                            limparListaAlertas={limparDialogListaAlertas}
                            handleCloseDialog={downDialogListaAlertas}
                        />
                    }
                    
                </MapContainer>
            </div>

        </div >

    );
}


