import { useState } from 'react';
import { useMap } from "react-leaflet";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import toast from 'react-hot-toast';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { filtrarBuscaAvancadaItems } from "../../services/mapaService";
import Gerador from "../../views/roterizacao/gerador"
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';


const drawerMinWidth = 400;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));




export default function PainelLateralForm(props) {
    let { abertoPainelLateral } = props
    const theme = useTheme();
    const [open, setOpen] = useState(abertoPainelLateral);
    const [veiculosSelecionados, setVeiculosSelecionados] = useState([]);
    const [modalCargas, setModalCargas] = useState(false);
    const [cidadeSelecionadas, setCidadeSelecionadas] = useState([]);
    const [statusSelecionadas, setStatusSelecionadas] = useState([]);
    let map = useMap()

    const veiculosRemocao = props.props;
    const dropDownUf = [...new Set(veiculosRemocao.map(p => p.uf))].sort();
    const dropDownCidade = [...new Set(veiculosRemocao.map(p => p.cidade))].sort();
    const dropDownComitente = [...new Set(veiculosRemocao.map(p => p.comitente))].sort();
    const dropDownStatus = [...new Set(veiculosRemocao.map(p => p.status))].sort();
    const dropDownDestinos = [...new Set(veiculosRemocao.map(p => p.destino))].sort();
    const dropDownSituacoes = [...new Set(veiculosRemocao.map(p => p.situacao))].sort();
    const [totalFiltros, setTotalFiltros] = useState(0);

    const handleChange = (event) => {

        const {
            target: { value },
        } = event;

        setCidadeSelecionadas(
            typeof value === 'array' ? value.split(',') : value,
        );
    };


    const handleChangeStatus = (event) => {

        const {
            target: { value },
        } = event;

        setStatusSelecionadas(
            typeof value === 'array' ? value.split(',') : value,
        );
    };

    const handleDrawerClose = () => {
        fecharBuscaAvancada()
    };


    function buscarAvancada(event) {
        event.preventDefault();
        limparFiltros();

        let retorno = filtrarBuscaAvancadaItems(veiculosRemocao, event.target, cidadeSelecionadas, statusSelecionadas);

        if (retorno === undefined) {
            toast('Informe Uf ou Cidade.');
            return;
        }

        if (retorno.length) {
            LocalizarVeiculosFiltrados(retorno);
            setTotalFiltros(retorno.length);
            if (retorno.length) {
                setVeiculosSelecionados(retorno);
            } else {
                setVeiculosSelecionados([]);
            }
        } else {
            toast('Não encontrado nenhuma remoção de acordo com filtros selecionadados e associados.');
            return;
        }

    }


    function fecharBuscaAvancada() {
        setOpen(false);
        fecharStepsMontagemCarga();
        props.fecharMenuLateral(false);

    }

    function limparFiltros() {
        setTotalFiltros(0);
        fecharStepsMontagemCarga();
    }

    function LocalizarVeiculosFiltrados(remocoes) {

        if (map && remocoes.length) {
            let coordenadas = [...new Set(remocoes.map(r => [r.coordenada.lat, r.coordenada.lng]))];
            map.fitBounds([coordenadas], { maxZoom: 20 });
            props.exibirSomenteFiltradosMapa(remocoes);

        }


    }
    function abrirStepsMontagemCarga() {
        setModalCargas(true);
    }

    function fecharStepsMontagemCarga() {
        setModalCargas(false);
        setVeiculosSelecionados([]);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                sx={{
                    minWidth: drawerMinWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        minWidth: drawerMinWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{
                    display: 'flex',
                    justifyContent: 'left'
                }}>
                    <Typography sx={{ p: 2, color: 'text.primary' }}>Fechar</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Typography sx={{ p: 2, color: 'text.secondary' }}>Busca avançada</Typography>


                <form method='post' onSubmit={buscarAvancada} >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 1 },
                        }}
                        autoComplete="off"
                    >
                        <label>Total de <b>{totalFiltros}</b>  de veiculo(s) encontrado(s) de acordo com filtro</label>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 1 },
                        }}
                    >
                        <TextField
                            autoFocus
                            style={{ width: '30%' }}
                            id="filled-select-filtro-avancado-uf"
                            select
                            label="1 - Uf"
                            variant="filled"
                            name="uf"
                            defaultValue=""

                        >
                            <MenuItem value="">
                                N/D
                            </MenuItem>
                            {dropDownUf.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel id="demo-multiple-checkbox-label">Cidades</InputLabel>
                            <Select
                                style={{ width: '100%' }}
                                labelId="filled-select-filtro-avancado-cidade"
                                id="veiculos-carga"
                                label="Veiculos"
                                multiple
                                value={cidadeSelecionadas}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-veiuclos-carga" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value, index) => (
                                            <Chip key={index} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {dropDownCidade.map((option, index) => (
                                    <MenuItem
                                        key={index}
                                        value={option}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            style={{ width: '30%' }}
                            id="filled-select-filtro-avancado-comitente"
                            select
                            label="Comitente"
                            variant="filled"
                            name="comitente"
                            defaultValue=""
                        >
                            <MenuItem value="">
                                N/D
                            </MenuItem>
                            {dropDownComitente.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 1 },
                        }}
                    >
                        <FormControl
                          style={{ width: '30%' }}>
                            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                            <Select
                            style={{ width: '100%' }}
                                labelId="filled-select-filtro-avancado-status"
                                id="veiculos-carga-status"
                                label="Status"
                                multiple
                                value={statusSelecionadas}
                                onChange={handleChangeStatus}
                                input={<OutlinedInput id="select-multiple-veiuclos-carga-status" label="Status" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value, index) => (
                                            <Chip key={index} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value="">
                                    N/D
                                </MenuItem>
                                {dropDownStatus.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            style={{ width: '30%' }}
                            id="filled-select-filtro-avancado-situacao"
                            select
                            label="Situacoes"
                            variant="filled"
                            name="situacao"
                            defaultValue=""

                        >
                            <MenuItem value="">
                                N/D
                            </MenuItem>
                            {dropDownSituacoes.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            style={{ width: '30%' }}
                            id="filled-select-filtro-avancado-destinos"
                            select
                            label="Destinos"
                            variant="filled"
                            name="destinos"
                            defaultValue=""

                        >
                            <MenuItem value="">
                                N/D
                            </MenuItem>
                            {dropDownDestinos.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 1 },
                        }}
                    >
                        <Button variant='contained' type='submit'>Filtrar</Button>
                        <Button variant='contained' onClick={handleDrawerClose}>Limpar</Button>
                    </Box>


                </form>


                <Divider />
                {
                    totalFiltros > 0 &&
                    <Box
                        sx={{
                            display: 'flex',
                            margin: 1,
                        }}
                    >
                        <Button
                            variant='contained'
                            onClick={(e) => abrirStepsMontagemCarga()}>
                            Montar carga do(s) {totalFiltros} veiculo(s)
                        </Button>
                    </Box>


                }
            </Drawer>

            {modalCargas && <Gerador veiculos={veiculosSelecionados} />}

        </Box>
    );
}