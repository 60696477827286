import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export function CardRemocao({ props }) {
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Cod Estoque:  <b>{props?.estoque}</b> <br />
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Placa:  {props?.placa} <br />
                        Chassi: {props?.chassi}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Comitente: {props?.comitente}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Destino: {props?.destino}
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Marca: {props?.marca}
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Modelo: {props?.modelo}
                    </Typography>

                    {
                        props?.vencimento &&
                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            Vencimento <br />
                            Dias: {props?.vencimento?.dias}<br />
                            Prazo: {props?.vencimento?.prazo}<br />
                            Status: {props?.vencimento?.status}
                        </Typography>
                    }
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Situação: {props?.situacao}
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Status: {props?.status}
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Acionado: {props?.acionado}
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Endereço: {props?.endereco}
                        <br />
                        uf: {props?.uf}
                        <br />
                        Cidade: {props?.cidade}
                        <br />
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Descrição: {props?.descricao}
                        <br />
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Tipo: {props?.tipo}
                        <br />
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Data cadastro: {props?.dataCadastro}
                        <br />
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
}


export function CardTelemetria({ props }) {
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Placa:  {props?.placa}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} component="div">
                        Velocidade: {props?.velocidade}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Situação: {props?.igniciao ? 'Ligado' : 'Desligado'}
                    </Typography>
                    <Typography variant="body2">
                        Rpm: {props?.rpm}
                        <br />
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
}