import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { autenticarControle, isAuthenticated, setLogin } from "../../services/contaService";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

const theme = createTheme();

export default function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [labelLogin, setLabelLogin] = React.useState('Acessar');
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setLoading(true);
            toast('processando login, aguarde 👀...', { duration: 2000 });
            const data = new FormData(event.currentTarget);
            let login = data.get('login');
            let password = data.get('password');
            setLabelLogin('carregando ...');
            const response = await autenticarControle(login, password);
            let body = response.data;

            setLogin(body);
            toast.success(`Bem vindo ${body.name} 😄`);
            setLoading(false);
            setLabelLogin('bem vindo (a)');

            navigate('/');
            // window.location.href='cargas'

        } catch (error) {
            let code = error.response.status;
            switch (code) {
                case 401:
                case 400:
                    toast.error('Login ou senha estão incorretos');
                    break;
                case 403:
                    toast.error('Você não tem permissão ;/');
                    break;
                default:
                    toast.error('erro interno, verique seu login')
                    break;
            }
        }
            setLoading(false);
            setLabelLogin('Acessar');

        }

        return (
            <ThemeProvider theme={theme}>
                <Toaster />
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="login"
                                label="Usuário do controle"
                                name="login"
                                autoComplete="login"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha do controle"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={loading}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {labelLogin}
                            </Button>
                            <Grid container>
                            </Grid>
                        </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {'Credenciais do controle © '}
                        <Link color="inherit" href="https://monir.provedor.space/">
                            Monir
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Container>
            </ThemeProvider>
        );
    }

