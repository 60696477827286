import { createControlComponent } from '@react-leaflet/core';   
import L from "leaflet";
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css'

const FullscreenControl = createControlComponent(props => L.control.fullscreen({
    title: 'Exibir mapa em tela cheia',
    titleCancel:'Sair do modo de tela cheia',
    position: props.position ?? 'bottomright'
}))

export default FullscreenControl