import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import toast, { Toaster } from 'react-hot-toast';
import { carregarCargas, deletarCarga } from '../../../../services/cargaService'
import TableRemoesMotoristaCarga from '../TableRemoesMotoristaCarga';
import Excel from '../Excel';
import Divider from '@mui/material/Divider';
import MapMicro from '../MapMicro';
import Card from '@mui/material/Card';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function TableCargas() {
    const [listaCargas, setListaCargas] = useState([]);
    const [pagina, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [labelMapa, setLabelMapa] = useState('');
    const [locationMap, setLocationMap] = useState(null);
    const [cargaAtual, setCargaAtual] = useState(null);
    const [remocaoReboquistaCarga, setRemocaoReboquistaCarga] = useState('');


    useEffect(() => {
        atualizar();
    }, []);

    const selecionarCarregamento = (load) => {
        setRemocaoReboquistaCarga('');
        setCargaAtual(null);
        setRemocaoReboquistaCarga(load.id);
        setCargaAtual(load);

        setLocationMap({
            locationStart: load?.locationStart?.coordinates ?? null,
            locationEnd: load?.locationEnd?.coordinates ?? null
        });
        setLabelMapa('carregamento');
    };

    function selecionarRemocaoMotorista(remocao) {
        setLabelMapa('remoção do motorista');
        setLocationMap({
            locationStart: remocao?.locationStart?.coordinates ?? null,
            locationEnd: remocao?.locationEnd?.coordinates ?? null
        });
    }

    function atualizar() {
        setPage(1);
        setSearch('');
        setLocationMap(null);
        setCargaAtual(null);
        setRemocaoReboquistaCarga('');
        carregaEndPointsCargas(1, search)
    }

    function buscarCargas(page, search = '') {
        setPage(page++);
        carregaEndPointsCargas(page, search);
    }

    function deletar(loadId) {
        let confirma = window.confirm('Tem certeza que deseja deletar ? ')

        if (confirma) {
            deletarCarga(loadId).then(() => {
                toast.success('Carga deletada com sucesso');
                atualizar();
            }).catch((e) => { toast.error('Erro ao deletar cargas .'); console.error(e) });
        }

    }

    function carregaEndPointsCargas(page, search) {
        let promisseCargas = carregarCargas(page, search)
            .then(response => {
                let body = response.data;
                setListaCargas(body);
            })
            .catch(erros => {
                console.error(erros)
                toast.error('Erro ao conectar cargas .');
            });

        toast.promise(promisseCargas, { loading: 'Carregando cargas..' })
    }

    return (
        <Card elevation={10} sx={{ m: 2, p: 4 }}>
            <Box
                sx={{ m: 2 }}>
                <Toaster />
                <Excel />
                <Box
                    sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                        sx={{
                            position: 'relative',
                            top: '10px',
                            width: '50%',
                            margin: 2,
                            display: {
                                xs: 'none',
                                md: 'flex',
                                justifyContent: 'space-between'
                            },
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow hover>
                                        <TableCell>Carga</TableCell>
                                        <TableCell align="right">Motorista</TableCell>
                                        <TableCell align="right">Placa reboque</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Criado em</TableCell>
                                        <TableCell align="right">Atualizado em</TableCell>
                                        <TableCell align="right">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        listaCargas.map((row) => (
                                            <TableRow
                                                hover
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                style={{ cursor: 'pointer' }}
                                                data-item={row.id}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title={row.driver}>
                                                        <div>
                                                            {
                                                                row.driver.split(' ')[0] ?? row.driver
                                                            }
                                                        </div>
                                                    </Tooltip>

                                                </TableCell>
                                                <TableCell align="right">{row.plateDriverCurrent}</TableCell>
                                                <TableCell align="right">{row.status}</TableCell>
                                                <TableCell align="right">{row.createdAt}</TableCell>
                                                <TableCell align="right">{row.updatedAt}</TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        color='primary'
                                                        variant="contained"
                                                        onClick={() => selecionarCarregamento(row)}
                                                    >
                                                        <OpenInNewIcon />
                                                    </Button>
                                                    <Button
                                                        sx={{ marginTop: 2 }}
                                                        color='error'
                                                        variant="contained"
                                                        onClick={(e) => deletar(row.id)}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                            <Box sx={{ display: 'flex' }}>
                                <Button sx={{ margin: 1 }} variant="contained" onClick={() => { atualizar() }} >Atualizar {pagina}</Button>
                                <Button sx={{ margin: 1 }} variant="contained" onClick={() => { buscarCargas(pagina - 1) }}> Voltar</Button>
                                <Button sx={{ margin: 1 }} variant="contained" onClick={() => { buscarCargas(pagina + 1) }}> Próximo</Button>
                            </Box>
                        </TableContainer>
                    </Box>

                    {
                        locationMap &&
                        <MapMicro
                            labelMapPopUp={labelMapa}
                            point={locationMap}
                            localizacaoMotoristaGeoJson={cargaAtual?.hasLastLocation ? cargaAtual.lastLocation : null}
                        />
                    }

                </Box>


                {
                    remocaoReboquistaCarga &&
                    <Box sx={{
                        margin: 2
                    }}>
                        <Divider />
                        <TableRemoesMotoristaCarga
                            props={{ carregamentoId: remocaoReboquistaCarga }}
                            carregaMaparRemocao={(remocaoReboquista) => selecionarRemocaoMotorista(remocaoReboquista)} />
                    </Box>
                }
            </Box>
        </Card>

    );
}