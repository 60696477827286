import NavBar from './elements/NavBar';
import TableCargas from './elements/TableCargas';

export default function Carga() {

    return (
        <div>
           <NavBar />
           <TableCargas/>
        </div>
    )
}