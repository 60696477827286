import { Marker, Popup, useMap } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
import { useEffect } from "react";

export default function MoveMaker({ props }) {
    const map = useMap();

    const { cords, label } = props;

    useEffect(() => {
        if (cords.length) {
            map.flyTo(cords, map.getZoom());
        }
    }, [props])


    return (
        <div>
            {
            cords.length &&
                <Marker
                    icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
                    position={cords}>
                    <Popup>{label}</Popup>
                </Marker>
                }

        </div>
    )
}
