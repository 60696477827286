import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { atualizarVeiculosRoterizacao, carregarDocumentoRequista, carregarMotoristas, alterarMotorista } from '../../../../services/roterizacaoService';
import { toast } from 'react-hot-toast';
import { Box, MenuItem, MenuList, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


export default function ModalAlterarVeiculoRoterizacao(props) {
    let abrir = props ? true : false;
    const { carga, cargaVeiculos, documentoMotorista, roterizacaoId, id, veiculosMapa, cargaId } = props.props
    const [open, setOpen] = useState(abrir);
    const [load, setLoad] = useState(false);
    const [motorista, setMotorista] = useState(null);
    const [veiculoOld, setVeiculoNovoOld] = useState("");
    const [veiculoNovo, setVeiculoNovo] = useState("");
    const [novoMotoristaSelecionado, setNovoMotoristaSelecionado] = useState(null);
    const [placaNovo, setPlacaNovo] = useState("");

    const [listaMotoristas, setListaMotoristas] = useState([]);


    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        listarMotorista();

        carregarDocumentoRequista(documentoMotorista)
            .then((jsau) => setMotorista(jsau.data))
            .catch(e => toast.error("Não foi possivel carregar nome do motorista"));

    }, [])


    const handleClose = () => {
        setOpen(false);
        props.fecharModal();
        setMotorista();
    };

    const handleCopyStock = (code) => {
        try {
            navigator.clipboard.writeText(code);
            toast.success("Copiado");
        } catch (error) {
            toast.error("Não foi possivel copiar codigo estoque")
        }
    };

    function atualizar() {
        if (!veiculoOld || !veiculoNovo) {
            toast.error("Informe codigo de estoque");
            return
        }

        let veiculoAntigoInformadoExisteNaCarga = cargaVeiculos.find(v => v.stockCode === veiculoOld);

        if (!veiculoAntigoInformadoExisteNaCarga) {
            toast.error("Veiculo antigo não faz parte da carga atual");
            return
        }

        let veiculoNoInformadoExisteNaCargaAtual = cargaVeiculos.find(v => v.stockCode === veiculoNovo);

        if (veiculoNoInformadoExisteNaCargaAtual) {
            toast.error("Veiculo novo já faz parte da carga atual");
            return
        }

        let veiculoNovoInformadoExisteNoMapa = veiculosMapa.find(v => v.estoque === veiculoNovo);
        if (!veiculoNovoInformadoExisteNoMapa) {
            toast.error("Veiculo novo não encontrado no mapa");
            return
        }

        setLoad(true);
        atualizarVeiculosRoterizacao(roterizacaoId, carga, veiculoOld, veiculoNovo).then(resp => {
            toast.success("Atualização feita com sucesso");
            setLoad(false);
            props.fecharModal(true)
        }).catch(e => { toast.error(e); setLoad(false); })

        setNovoMotoristaSelecionado(null);
        setPlacaNovo("");
    }

    function listarMotorista() {
        carregarMotoristas().then(response => setListaMotoristas(response.data)).catch((err) => { console.error(err); toast.error('Erro ao carregar lista de motoristas'); })
    }

    function substituirMotorista() {
        if (window.confirm('Tem certeza que deseja realizar substituição do motorista')) {

            if (novoMotoristaSelecionado && novoMotoristaSelecionado.id) {
                alterarMotorista(cargaId, novoMotoristaSelecionado.id, placaNovo).then(() => {
                    toast.success('Realizado substituição do motorista');
                    setLoad(false);
            props.fecharModal(true)
                }).catch(e => {
                    console.error(e);
                    toast.error('Não foi possivel substituir motorista');
                });
            } else {
                toast.error('Informe novo motorista');
            }

        }

    }

    const valueChangeHandle = (event) => {
        setNovoMotoristaSelecionado(event.target.value);
    }


    return (
        <Dialog
            key={id}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="700"
        >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Alterar carga" {...a11yProps(0)} />
                <Tab label="Alterar motorista" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
                <DialogTitle id="alert-dialog-title">
                    {`Atualizar carga ${carga} do motorista`} <br /> {motorista?.nome}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <MenuList>
                            {cargaVeiculos.map((veiculoCarga, i) => (
                                <MenuItem onClick={() => handleCopyStock(veiculoCarga.stockCode)} key={i}>{veiculoCarga.stockCode} - {veiculoCarga.name}</MenuItem>
                            ))}
                        </MenuList>
                        <Box
                            sx={{ display: "flex", alignItems: "center", justifyItems: "center", m: 1 }}
                        >
                            <TextField
                                sx={{ m: 1, minWidth: 200 }}
                                size='medium'
                                name="estoqueCodeAntigo"
                                label="Codigo estoque antigo"
                                type="number"
                                id="estoqueCodeAntigo"
                                variant="outlined"
                                onChange={(e) => setVeiculoNovoOld(e.target.value)}
                            />

                            <TextField
                                sx={{ m: 1, minWidth: 200 }}
                                name="estoqueCodeNovo"
                                label="Codigo estoque novo"
                                type="number"
                                id="estoqueCodeNovo"
                                variant="outlined"
                                onChange={(e) => setVeiculoNovo(e.target.value)}
                            />

                            <Button sx={{ margin: 1, }} disabled={load} variant="contained" onClick={() => { atualizar() }} >{load ? 'Processando' : 'Substituir'}</Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <DialogTitle id="alert-dialog-title">
                    {`Alterar motorista`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                            style={{ width: '100%' }}
                            id="filled-select-filtro-lista-motorista-substituir"
                            select
                            label="Motoristas *"
                            variant="filled"
                            name="motorista"
                            defaultValue=""
                            onChange={valueChangeHandle}
                        >
                            <MenuItem value="">
                                N/D
                            </MenuItem>
                            {listaMotoristas?.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            sx={{ mt: 2, mb: 5, width: '100%' }}
                            name="novaPlaca"
                            label="Placa do novo motorista"
                            id="novaPlaca"
                            variant="outlined"
                            onChange={(e) => setPlacaNovo(e.target.value)}
                        />

                        <Button sx={{ width: '100%' }} disabled={load} variant="contained" onClick={() => { substituirMotorista() }} >{load ? 'Processando' : 'Substituir'}</Button>
                    </DialogContentText>
                </DialogContent>
            </CustomTabPanel>

            <DialogActions>
                <Button onClick={handleClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    );
}
