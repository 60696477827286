import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMap } from "react-leaflet";
import { maxZoom } from "../../plugins/leafletUtils";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import IconButton from '@mui/material/IconButton';

export default function DialogAlertaProximos(props) {
    const map = useMap();
    let abrir = props ? true : false;
    const styleDialog = { sx: { position: 'fixed', bottom: 0, right: 10, m: 2 } };
    const [open, setOpen] = React.useState(abrir);
    const [scroll, setScroll] = React.useState('paper');

    const handleClose = () => {
        setOpen(false);
        props.handleCloseDialog();
    };

    function localizaVeiculo(latLng) {
        if (latLng) {
            map.flyTo([latLng.lat, latLng.lng], maxZoom);
            handleClose()
        }
    };

    function limparLista() {
        props.limparListaAlertas();
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={styleDialog}
                scroll={scroll}
            >
                <DialogTitle id="alert-dialog-title">
                    Alerta de motorista(vip) próximo de remoções (prevista, liberados)
                </DialogTitle>
                <DialogContent>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Placa/Chassi/CodEstoque</th>
                                <th>Mensagem</th>
                                <th>Quando</th>
                                <th>Localizar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props?.props?.map((remocao, index) => (
                                    <tr key={index}>
                                        <td >{index + 1}</td>
                                        <td >{remocao.placaChassiCodEstoque}</td>
                                        <td>{remocao.msg}</td>
                                        <td>{remocao.data}</td>
                                        <td>
                                            <IconButton onClick={() => localizaVeiculo(remocao.coordenada)} aria-label="delete">
                                                <MyLocationIcon />
                                            </IconButton>
                                        </td>
                                    </tr>
                                ))
                                ||
                                <tr>
                                    <td>Nenhum veiculo</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant="contained" onClick={limparLista} autoFocus>
                        Limpar
                    </Button>
                    <Button color='primary' variant="contained" onClick={handleClose} autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}