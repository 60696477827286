import { httpFleet } from "../plugins/http";

export function autenticarControle(login, password) {
    let device = navigator.userAgent;
    return httpFleet.post(`Accounts/UserAuthControleVip`, { login, password, device });
}

export const TOKEN_KEY = "@vipmais.fleet.auth";

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const getUser = () => sessionStorage.getItem('name');

export const setLogin = payload => {
    sessionStorage.setItem('name', payload.name);
    sessionStorage.setItem(TOKEN_KEY, payload.token)
};
export const logout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem('name');
};