import axios from 'axios';
import { httpFleet } from "../plugins/http";

export function carregarDocumentoRequista(documento) {
  let token = sessionStorage.getItem("@vipmais.fleet.auth")
  return axios.get(`https://api-biv.provedor.space/api/v2/solicitante/buscarFornecedorByDoc?documentoFornecedor=${documento}`, { headers: { Authorization: 'Bearer ' + token } });
}

export async function carregarPatios() {
  let resp = await httpFleet.get('scriptscars/getbranchaddress');
  return resp.data;
}

export function montarUrlkmGoogleMaps(filialOrigem,  veiculosFiltrados, veiculosSelecionados, filialDestino) {
  
  let codigosEstoques = veiculosSelecionados.map(v => v.split("-")[0]);
  let cargaVeiculos = [];
  codigosEstoques.forEach(codEstoque => {
    let veiculoItem = veiculosFiltrados.find(vf => vf.estoque === codEstoque);
    cargaVeiculos.push(veiculoItem);    
  });

  let enderecosVeiculos = cargaVeiculos.map(v => `${v.coordenada.lat},${v.coordenada.lng}`).join("/");
  let url = `https://www.google.com/maps/dir/${filialOrigem}/${enderecosVeiculos}/${filialDestino}`
  console.log(url);
  return url;
}

export function formatarPayloadControle(veiculo, event, motorista) {
  let {
    dataRetorno,
    placaReboquista,
  } = event.target;

  let bodyControle = {
    estoqueId: parseInt(veiculo.estoque),
    descricao: `INTEGRACAO - INICIADO PROCESSO DE ROTERIZACAO VIA MONIR (MOTORISTA:${motorista.nome} | PLACA REBOQUISTA: ${placaReboquista.value}| PREVISÃO: ${dataRetorno.value})`
  };
  return httpFleet.post('/ScriptsCars/AddObsControle', bodyControle);
}

export function formatarPayloadRoterizacao(event, veiculosFiltrados, veiculosSelecionados, valorDiaria, valorAdicional, bivId, filialOrigem, filialDestino, motorista) {

  let codigosEstoques = veiculosSelecionados.map(v => v.split("-")[0]);
  let cargaVeiculos = [];
  codigosEstoques.forEach(codEstoque => {
    let veiculoItem = veiculosFiltrados.find(vf => vf.estoque === codEstoque);
    cargaVeiculos.push({
      state: veiculoItem.uf,
      city: veiculoItem.cidade,
      stockCode: veiculoItem.estoque,
      action: veiculoItem.acionado,
      destination: veiculoItem.destino,
      address: veiculoItem.destino,
      description: veiculoItem.descricao,
      client: veiculoItem.comitente,
      type: veiculoItem.tipo,
      plate: veiculoItem.placa,
      chassis: veiculoItem.chassi,
      status: veiculoItem.status,
      clientGroup: veiculoItem.clienteGrupo,
      truck: veiculoItem.caminhao,
      dateCreated: veiculoItem.dataCadastro,
      brand: veiculoItem.marca,
      model: veiculoItem.modelo,
      expired: veiculoItem.vencido,
      situation: veiculoItem.situacao,
      locationAddress: {
        latitude: veiculoItem.coordenada.lat,
        longiture: veiculoItem.coordenada.lng
      }
    });
  });

  let {
    km,
    dataSaida,
    dataRetorno,
    numeroAcionamento,
    placaReboquista,
  } = event.target;

  let bodyRoterizacao = {
    driverDocument: motorista.cnpjcpf,
    driverPlate: placaReboquista.value,
    slugVehicles: JSON.stringify(codigosEstoques),
    slugAddressVehicles: JSON.stringify(codigosEstoques),
    km: parseInt(km.value),
    returnDriverDate: dataSaida.value,
    leftDriverDate: dataRetorno.value,
    valueDaily: valorDiaria,
    valueAdditional: valorAdicional,
    numberPhone: 11111,
    triggerDriverId: parseInt(numeroAcionamento.value) ?? 0,
    bivDailyDriver: bivId,
    jsonVechicleMonir: JSON.stringify(codigosEstoques),
    originBranchId: filialOrigem,
    destinationBranchId: filialDestino,
    type: 1,
    vehicles: cargaVeiculos
  }
  return bodyRoterizacao;
}

export function criarRoterizacao(payload) {
  return httpFleet.post('/scriptscars/new', payload);
}

export function formatarPayloadBiv(event, motorista, valorDiaria, valorAdicional) {

  let {
    observacao,
    documento,
    dataSolicitacao,
    dias,
    docbiv
  } = event.target;
  debugger;

  let bodyBiv = {
    observavao: "INTEGRACAO MONIR - " + observacao.value,
    idServico: 1111,
    codidoEstoque: 10101010,
    valorHubObs: null,
    cPFCNPJForncedor: documento.value,
    dataVencimento: dataSolicitacao.value,
    valorVencimento: valorDiaria,
    valorDiaria: valorDiaria,
    nomeFornecedor: motorista.nome,
    centroResultado: "040004 ",
    despesas: 3000604,
    documento: docbiv.value,
    mMMAA: dataSolicitacao.value,
    obsFornecedor: docbiv.value + " - " + observacao.value,
    dias: parseInt(dias.value),
    valorAdicional: valorAdicional
  };
  return bodyBiv;

}

export function enviarDiariaBiv(payload) {
  return httpFleet.post('/scriptscars/createdailydriver', payload);
}

export function carregarRoterizacoes(somenteMeus = false, page = 1, limit = 15, busca = null){
  return httpFleet.get(`/scriptscars/getscriptcars?myscripts=${somenteMeus}&page=${page}&limit=${limit}&qSearch=${busca}`);
}

export function atualizarStatusRoterizacao(idRoterizacao){
  let payload = {
    id: idRoterizacao,
    status: 1
  };
  return httpFleet.post(`/scriptscars/updatestatus`, payload);
}

export function excluirRoterizacao(idRoterizacao){
  return httpFleet.get(`/scriptscars/remove?scriptId=${idRoterizacao}`);
}

export function alterarMotorista(cargaId, novoMotoristaId, novaPlaca){
  return httpFleet.get(`/scriptscars/ReplaceDriver?loadId=${cargaId}&newDriverId=${novoMotoristaId}&newPlate=${novaPlaca}`);
}

export function carregarMotoristas(){
  return httpFleet.get(`/Accounts/GetDrivers`);
}

export function atualizarVeiculosRoterizacao(roterizacaoId, carga, codigoEstoqueAntigo, codigoEstoqueNovo){
  return httpFleet.get(`/scriptscars/replacevehicle?scriptId=${roterizacaoId}&loadName=${carga}&stockCodeOld=${codigoEstoqueAntigo}&stockCodeNew=${codigoEstoqueNovo}`);
}