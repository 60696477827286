import axios from 'axios';
import { urlApi, urlApiFleet } from "./urlApi";

import { getToken } from "../services/contaService";

export const http = axios.create({
  baseURL: `${urlApi}api/mapa/`
});

export const httpFleet = axios.create({
  baseURL: `${urlApiFleet}`
});

httpFleet.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    let bearer = 'Bearer ' + token;
    config.headers.Authorization = bearer;
  }
  return config;
});