import { http } from "../plugins/http";
import { urlApi } from "../plugins/urlApi";
import * as L from 'leaflet';

export function carregarVeiculosRemocao() {

    return http.get("veiculos-remocao");
}

export function carregarFiltroFiliais() {
    return http.get("destinos");
}

export function carregaPontosVips() {
    const vips = [
        { nome: "MA - SÃO LUÍS", position: [-2.634775, -44.266529] },
        { nome: "MA - IMPERATRIZ", position: [-5.478014, -47.472186] },
        { nome: "MA - CAXIAS", position: [-4.870324, -43.406077] },
        { nome: "MA - PRESIDENTE DUTRA", position: [-5.289844, -44.489942] },
        { nome: "PA - BELÉM", position: [-1.43353, -48.31716] },
        { nome: "PA - SANTAREM", position: [-2.421357, -54.741071] },
        { nome: "PA - PARAUAPEBAS", position: [-6.034602, -49.900066] },
        { nome: "PA - MARABÁ", position: [-5.277212, -49.072776] },
        { nome: "PA - MARITUBA", position: [-1.372634, -48.361669] },
        { nome: "PA - BENGUI", position: [-1.387424, -48.363401] },
        { nome: "PA - TAPANÃ", position: [-1.347261, -48.472284] },
        { nome: "AP - MACAPÁ", position: [0.024034, -51.153286] },
        { nome: "BA - CAMAÇARI", position: [-12.676887, -38.308112] },
        { nome: "PE - RECIFE", position: [-8.074004, -34.981343] },
        { nome: "RO - PORTO VELHO", position: [-8.772203, -63.871875] },
        { nome: "PI - TERESINA", position: [-5.024648, -42.781786] },
        { nome: "PI - FLORIANO", position: [-6.765025, -43.031172] },
        { nome: "PI - PARNAÍBA", position: [-2.923012, -41.742175] },
        { nome: "PI - PICOS", position: [-7.063664, -41.452193] },
        { nome: "AM - MANAUS", position: [-3.047591, -59.990992] },
        { nome: "AM - SANTA ETELVINA", position: [-2.986196, -59.98638] },
        { nome: "RJ - RIO DE JANEIRO", position: [-22.817755, -43.285917] },
        { nome: "RR - BOA VISTA", position: [2.773102, -60.715729] },
        { nome: "GO - GOIANIA", position: [-16.668287, -49.315574] },
        { nome: "PR - CURITIBA", position: [-25.474125, -49.306667] },
        { nome: "PR - CASCAVEL", position: [-24.965411, -53.450717] },
    ]
    return vips;
}

export const listaSelectRapido =
    [
        {
            value: 'placa',
            label: 'Placa',
        },
        {
            value: 'codEstoque',
            label: 'Cod.Estoque',
        },
        {
            value: 'chassi',
            label: 'Chassi',
        }
    ];


export function filtrarBuscaAvancadaItems(remocoes, inputs, cidades, listStatus) {

    let {
        uf,
        comitente,
        destinos,
        situacao
    } = inputs;

    let retorno = remocoes;

    let ufNaoPreenchida = !uf.value;

    let cidadeNaoPreenchida = cidades.length <= 0;

    if (ufNaoPreenchida && cidadeNaoPreenchida) {
        return undefined;
    }

    if (uf.value) {
        retorno = retorno.filter(u => u.uf == uf.value);
    }

    if (cidades.length) {
        retorno = retorno.filter((item) => cidades.includes(item.cidade));    
    }

    if (comitente.value) {
        retorno = retorno.filter(u => u.comitente == comitente.value);
    }


    if (listStatus.length) {
        retorno = retorno.filter((item) => listStatus.includes(item.status));
    }

console.log(destinos)
    if (destinos.value) {
        retorno = retorno.filter(u => u.destino == destinos.value);
    }


    if (situacao.value) {
        retorno = retorno.filter(u => u.situacao == situacao.value);
    }

    return retorno

}

export function carregaIconTelemetria(point) {

    let eCegonha = ['NNG5107', '', 'PTB6166'].includes(point.placa);

    let veiculo = eCegonha ? 'cegonha' : 'plataforma';

    let sizeIconTelemetria = {
        size1: eCegonha ? 60 : 45,
        size2: eCegonha ? 75 : 65,
    }
    let icon = { url: `${urlApi}images/svg/${veiculo}.svg`, size1: sizeIconTelemetria.size1, size2: sizeIconTelemetria.size2 };

    if (!Boolean(point.igniciao)) {
        icon.url = `${urlApi}images/switch-off.png`;
        icon.size1 = 45;
        icon.size2 = 45;
    }


    return icon;
}

export function carregaIconRemocao(point) {
    let extra = ''; //aguardando
    if (Boolean(point.acionado)) {
        extra = '_AGENDADO'
    }
    else if (point.vencido) {
        extra = '_VENCIDO'
    }
    return `${urlApi}images/${point.tipo}${extra}.png`
}


export const calculator = function (cluster) {
    const markers = cluster.getAllChildMarkers();
    const totalMarkers = markers.length;
    const vencidoMarkers = markers.filter(marker => marker.options.title === 'VENCIDO').length;
    const aguardandoMarkers = markers.filter(marker => marker.options.title === 'AGUARDANDO').length;
    const agendadosMarkers = markers.filter(marker => marker.options.title === 'AGENDADO').length;

    let iconHtml = '';
    let iconClassName = '';

    if (vencidoMarkers === totalMarkers) {
        // somente vencidos e ativos
        iconHtml = `${vencidoMarkers}`;
        iconClassName = 'marcador-cluster-vencido';
    } else if (agendadosMarkers === totalMarkers) {
        //somente os agendados
        iconHtml = `${agendadosMarkers}`;
        iconClassName = 'marcador-cluster-agendado';
    } else if (aguardandoMarkers === totalMarkers) {
        //somenteos aguardandos
        iconHtml = `${aguardandoMarkers}`;
        iconClassName = 'marcador-cluster-aguardando';
    }
    else {
        iconHtml = `${totalMarkers}`;
        iconClassName = 'marcador-cluster-tres';
    }


    return L.divIcon({
        html: iconHtml,
        className: iconClassName
    });
}

export function carregarRemocoesPorSituacoes(remocoes) {

    let remocoesAgendado = remocoes?.filter(r => Boolean(r.acionado)) ?? []

    let remocoesVencido = remocoes?.filter(r => r.vencido) ?? []

    let remocoesAguardando = remocoes?.filter(r => !Boolean(r.acionado) && !r.vencido) ?? []
    
    return { remocoesAgendado, remocoesVencido, remocoesAguardando }
}


export function atualizarCacheListaRemocoes() {
    return http.delete("delete-cache?key=remocao-vip-mais-mapa");
}