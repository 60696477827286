import L from 'leaflet';

export default function Icon({ props }) {

    const iconCustom = new L.Icon({
        iconUrl: props.url,
        iconRetinaUrl: props.url,
        iconSize: [props.size1, props.size2],
        shadowSize: props?.shadowSize ?  props.shadowSize : null, 
        iconAnchor: props?.iconAnchor ?   props.iconAnchor : null, 
      
    });

    return iconCustom;
}