import { httpFleet } from "../plugins/http";


export function carregarCargas(page = 1, search = '') {
    return httpFleet.get(`Loads/ListReport?page=${page}&search=${search}`);
}

export function deletarCarga(cargaId, habilitar = false) {
    return httpFleet.get(`Loads/UpdateStatusLoad?loadId=${cargaId}&enable=${habilitar}`);
}

export function gerarExcelCargas(dateFrom, dateTo) {
    return httpFleet.get(`Loads/ReportFile?from=${dateFrom}&to=${dateTo}`, { responseType: 'blob' });
}


export function carregarRemocoesMotoristasCarga(loadId, page = 1, search = '') {
    return httpFleet.get(`Loads/ListDriverRemoveFromLoad?loadid=${loadId}&page=${page}&search=${search}`);
}

export function gerarExcelRemocoesCarga(loadId, dateFrom, dateTo) {
    return httpFleet.get(`Loads/ReportFileDriverRemoveFromLoad?loadid=${loadId}&from=${dateFrom}&to=${dateTo}`, { responseType: 'blob' });
}