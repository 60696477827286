import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  carregarDocumentoRequista,
  carregarPatios,
  montarUrlkmGoogleMaps,
  formatarPayloadBiv,
  formatarPayloadControle,
  formatarPayloadRoterizacao,
  enviarDiariaBiv,
  criarRoterizacao
} from "../../../../services/roterizacaoService"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import IntlCurrencyInput from "react-intl-currency-input"
import toast from 'react-hot-toast';
import { Checkbox, FormControlLabel } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default function Form(props) {
  const { veiculos } = props;
  const [motorista, setMotorista] = useState(null);
  const [veiculosCarga, setVeiculosCarga] = useState([]);
  const [gerarDiarias, setGerarDiarias] = useState(false);
  const [patios, setPatios] = useState([]);
  const [patiosOrigemSelecionado, setPatiosOrigemSelecionado] = useState("");
  const [valorDiaria, setValorDiaria] = useState(0);
  const [valorAdicional, setAdicional] = useState(0);
  const [idDiariaBiv, setIdDiariaBiv] = useState(0);
  const [patiosDestinoSelecionado, setDestinoOrigemSelecionado] = useState("");
  const veiculosMapa = veiculos.veiculos;


  function submitRoterização(event) {
    event.preventDefault();
    let acionament = event.target.numeroAcionamento.value
    if (!acionament || acionament <= 0) {
      toast.error("Informe o número de acionamento", { duration: 5000 });
      return
    }

    //controle
    veiculosCarga.forEach(item => {
      let veiculo = veiculosMapa.find(v => v.estoque == item.split("-")[0]);
      let promisseControle = formatarPayloadControle(veiculo, event, motorista).then(event => console.log("✅")).catch(error => {
        toast.error("Não foi possivel enviar atualizar observaçoes no controle");
        console.error(error);
      });
      toast.promise(promisseControle, { loading: `Processando intregracao controle veiculo ${veiculo.placa || veiculo.chassi || veiculo.estoque} ..` })
    });

    //biv
    if (gerarDiarias) {
      let payloadApiBiv = formatarPayloadBiv(event, motorista, valorDiaria, valorAdicional);
      let promisseBiv = enviarDiariaBiv(payloadApiBiv).then(response => {
        let id = response.data.id;
        setIdDiariaBiv(id);
      }).catch(error => {
        toast.error("Não foi possivel enviar diárias ao biv");
      });
      toast.promise(promisseBiv, { loading: `Processando intregracao com envio de diaria(s) para biv ..` })
    }

    // roterizacao
    let payloadRoterizacao = formatarPayloadRoterizacao(event, veiculosMapa, veiculosCarga, valorDiaria, valorAdicional, idDiariaBiv, patiosOrigemSelecionado, patiosDestinoSelecionado, motorista)



    let promisseRoterizacao = criarRoterizacao(payloadRoterizacao).then(response => {
      toast.success("Criado roterização")
    }).catch(error => {
      toast.error("Não foi possivel criar roterização");
    });
    toast.promise(promisseRoterizacao, { loading: `Processando roterização ..` })
    props.fecharModal(true);

  }


  function buscaDocumentoReboquista(texto) {
    if (!texto) {
      setMotorista(null)
      return;
    }
    let promisseBuscaReboquista = carregarDocumentoRequista(texto)
      .then(response => {
        let body = response.data;
        if (!body) {

          toast.error("Motorista não encontrado");
          return
        }
        setMotorista(body);
      })
      .catch(e => console.error(e))

    toast.promise(promisseBuscaReboquista, { loading: `Buscando reboquista....` })

  }

  async function listarPatio() {
    setPatios(await carregarPatios());
  }


  const handleChange = (event) => {

    const {
      target: { value },
    } = event;

    setVeiculosCarga(
      typeof value === 'array' ? value.placa.split(',') : value,
    );
  };
  const handleChangeValorDiaras = (event, value, maskedValue) => {
    event.preventDefault();
    setValorDiaria(value)
  };

  const handleChangeValorAdicional = (event, value, maskedValue) => {
    event.preventDefault();
    setAdicional(value)
  };

  const handlePatiosDestino = (event) => {
    setDestinoOrigemSelecionado(event.target.value);
  };

  const handlePatiosOrigem = (event) => {
    setPatiosOrigemSelecionado(event.target.value)
  };

  function abrirKmGoogleMaps() {
    let patioOrigem = patios.find(p => p.id == patiosOrigemSelecionado);
    let patioDestino = patios.find(p => p.id == patiosDestinoSelecionado);
    console.log(patioOrigem)
    let url = montarUrlkmGoogleMaps(`${patioOrigem.position.coordinates[0]}, ${patioOrigem.position.coordinates[1]}`, veiculosMapa, veiculosCarga, `${patioDestino.position.coordinates[0]}, ${patioDestino.position.coordinates[1]}`);
    window.open(url, '_blank');
  }

  useEffect(() => {
    listarPatio();
  }, [])


  return (
    <Box sx={{ m: 2 }}>
      <form method='post' onSubmit={submitRoterização}>

        <Typography m={2} variant="h4">
          Identificação motorista
        </Typography>

        <TextField
          onBlur={(event) => buscaDocumentoReboquista(event.target.value)}
          sx={{ m: 2 }}
          id="documento"
          label="Documento"
          variant="outlined"
        />

        <TextField
          sx={{ m: 2 }}
          fullWidth
          id="nome"
          variant="outlined"
          value={motorista?.nome || ''}
          disabled
        />

        {
          motorista && <Box sx={{ m: 2 }}>
            <Typography m={2} variant="h4">
              Montar carga
            </Typography>

            <TextField
              sx={{ m: 2 }}
              name="placaReboquista"
              id="placa-reboquista"
              label="Placa do reboquista"
              variant="outlined"
            />

            <Typography m={2}>
              Veiculos
            </Typography>
            <Select
              labelId="veiculosCarga"
              id="veiculos-carga"
              label="Veiculos"
              multiple
              value={veiculosCarga}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-veiuclos-carga" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value, index) => (
                    <Chip key={index} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {veiculosMapa.map((name, index) => (
                <MenuItem
                  key={index}
                  value={`${name.estoque}-${name.marca}-${name.placa || name.chassi || name.estoque}`}
                >
                  {name.marca} - {name.placa || name.chassi || name.estoque}
                </MenuItem>
              ))}
            </Select>

            <Typography m={2}>
              Filial origem
            </Typography>
            <Select
              name="filialOrigem"
              labelId="demo-simple-select-autowidth-label-origem"
              id="demo-simple-select-autowidth-origen"
              value={patiosOrigemSelecionado}
              onChange={handlePatiosOrigem}
              autoWidth
              label="Destino"
            >
              {patios?.map((item, idx) => (
                <MenuItem
                  key={idx}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>

            <Typography m={2}>
              Filial destino
            </Typography>

            <Select
              name="filialDestino"
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={patiosDestinoSelecionado}
              onChange={handlePatiosDestino}
              autoWidth
              label="Destino"
            >
              {patios?.map((item, idx) => (
                <MenuItem
                  key={idx}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>

            {
              patiosOrigemSelecionado && patiosDestinoSelecionado && veiculosMapa.length &&
              <Box
              >
                <Button
                  sx={{ mt: 2 }}
                  variant="contained" onClick={() => abrirKmGoogleMaps()}>Abrir KM google maps</Button>
                <TextField
                  sx={{ m: 2 }}
                  label="km"
                  name="km"
                  id="km"
                  variant="outlined"
                />
                <TextField
                  sx={{ m: 2 }}
                  name="numeroAcionamento"
                  label="Número do acionamento"
                  type="text"
                  id="numeroAcionamento"
                  variant="outlined"
                />
                <TextField
                  sx={{ m: 2 }}
                  name="dataSaida"
                  label="data Saida"
                  type="date"
                  id="dataSaida"
                  variant="outlined"
                />

                <TextField
                  sx={{ m: 2 }}
                  name="dataRetorno"
                  label="data Retorno"
                  type="date"
                  id="dataretorno"
                  variant="outlined"
                />

                <hr />
                <FormControlLabel
                  control={
                    <Checkbox checked={gerarDiarias} onChange={(event) => setGerarDiarias(event.target.checked)} />
                  }
                  label="Criar diarias" />
                {
                  gerarDiarias &&
                  <div>
                    <TextField
                      sx={{ m: 2 }}
                      name="dataSolicitacao"
                      label="data Solicitacao"
                      type="date"
                      id="dataSolicitacao"
                      variant="outlined"
                    />
                    <br />
                    <label>Valor da diaria</label>
                    <IntlCurrencyInput name="valorDiaria" currency="BRL" config={currencyConfig}
                      onChange={handleChangeValorDiaras} />
                    <label>Valor adicional</label>
                    <IntlCurrencyInput name="valorAdicional" currency="BRL" config={currencyConfig}
                      onChange={handleChangeValorAdicional} />
                    <br />
                    <TextField
                      sx={{ m: 2 }}
                      name="dias"
                      label="Dias"
                      type="number"
                      id="dias"
                      variant="outlined"
                    />

                    <TextField
                      sx={{ m: 2 }}
                      name="docbiv"
                      label="documento"
                      type="text"
                      id="docbiv"
                      variant="outlined"
                    />


                    <TextField
                      sx={{ m: 2 }}
                      name="observacao"
                      label="Obs"
                      type="text"
                      id="observacao"
                      variant="outlined"
                    />

                  </div>
                }


              </Box>
            }
            {
              motorista && patiosOrigemSelecionado && patiosDestinoSelecionado && <Button sx={{ mt: 10 }} variant='contained' type='submit' color="success">Processar roterização</Button>
            }

          </Box>

        }
      </form>
    </Box>
  )
}
