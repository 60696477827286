import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Navigate, Route, Routes } from "react-router-dom"
import Home from "./views/home";
import Carga from "./views/carga";
import Login from './views/login';
import { isAuthenticated } from './services/contaService';
import NotFound from './views/Shared/NotFound';
import Gerador from './views/roterizacao/gerador';
import Gestao from './views/roterizacao/gestao';

function Protected({ children }) {
  let isSignedIn = isAuthenticated();
  if (!isSignedIn) {
    return <Navigate to="/login" replace />
  }
  return children
}

function App() {
  return (
    
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route path="/" element=
        {
          <Protected>
            <Home />
          </Protected>
        }
      />
      <Route path="/cargas" element=
        {
          <Protected>
            <Carga />
          </Protected>
        }
      />

<Route path="/roterizacao/gestao" element=
        {
          <Protected>
            <Gestao />
          </Protected>
        }
      />



      <Route path="*" element={<NotFound />} />

    </Routes>
  );
}

export default App;
